// "ἡλικίαν" or ἡλικίαν
// const gText =  "ὁδηγήσει"
// const gLex = "ὁδηγήσει"
export const greekText = `40001001 Βίβλος γενέσεως ˚Ἰησοῦ ˚Χριστοῦ, υἱοῦ Δαυὶδ, υἱοῦ Ἀβραάμ:
40001002 Ἀβραὰμ ἐγέννησεν τὸν Ἰσαάκ, Ἰσαὰκ δὲ ἐγέννησεν τὸν Ἰακώβ, Ἰακὼβ δὲ ἐγέννησεν τὸν Ἰούδαν καὶ τοὺς ἀδελφοὺς αὐτοῦ,
40001003 Ἰούδας δὲ ἐγέννησεν τὸν Φαρὲς καὶ τὸν Ζάρα ἐκ τῆς Θαμάρ, Φαρὲς δὲ ἐγέννησεν τὸν Ἑσρώμ, Ἑσρὼμ δὲ ἐγέννησεν τὸν Ἀράμ,
40001004 Ἀρὰμ δὲ ἐγέννησεν τὸν Ἀμιναδάβ, Ἀμιναδὰβ δὲ ἐγέννησεν τὸν Ναασσών, Ναασσὼν δὲ ἐγέννησεν τὸν Σαλμών,
40001005 Σαλμὼν δὲ ἐγέννησεν τὸν Βόες ἐκ τῆς Ῥαχάβ, Βόες δὲ ἐγέννησεν τὸν Ἰωβὴδ ἐκ τῆς Ῥούθ, Ἰωβὴδ δὲ ἐγέννησεν τὸν Ἰεσσαί,
40001006 Ἰεσσαὶ δὲ ἐγέννησεν τὸν Δαυὶδ τὸν βασιλέα. Δαυὶδ δὲ ἐγέννησεν τὸν Σολομῶνα ἐκ τῆς τοῦ Οὐρίου,
40001007 Σολομὼν δὲ ἐγέννησεν τὸν Ῥοβοάμ, Ῥοβοὰμ δὲ ἐγέννησεν τὸν Ἀβιά, Ἀβιὰ δὲ ἐγέννησεν τὸν Ἀσάφ,
40001008 Ἀσὰφ δὲ ἐγέννησεν τὸν Ἰωσαφάτ, Ἰωσαφὰτ δὲ ἐγέννησεν τὸν Ἰωράμ, Ἰωρὰμ δὲ ἐγέννησεν τὸν Ὀζίαν,
40001009 Ὀζίας δὲ ἐγέννησεν τὸν Ἰωαθάμ, Ἰωαθὰμ δὲ ἐγέννησεν τὸν Ἀχάζ, Ἀχὰζ δὲ ἐγέννησεν τὸν Ἑζεκίαν,
40001010 Ἑζεκίας δὲ ἐγέννησεν τὸν Μανασσῆ, Μανασσῆς δὲ ἐγέννησεν τὸν Ἀμώς, Ἀμὼς δὲ ἐγέννησεν τὸν Ἰωσίαν,
40001011 Ἰωσίας δὲ ἐγέννησεν τὸν Ἰεχονίαν καὶ τοὺς ἀδελφοὺς αὐτοῦ ἐπὶ τῆς μετοικεσίας Βαβυλῶνος.
40001012 Μετὰ δὲ τὴν μετοικεσίαν Βαβυλῶνος, Ἰεχονίας ἐγέννησεν τὸν Σαλαθιήλ, Σαλαθιὴλ δὲ ἐγέννησεν τὸν Ζοροβαβέλ,
40001013 Ζοροβαβὲλ δὲ ἐγέννησεν τὸν Ἀβιούδ, Ἀβιοὺδ δὲ ἐγέννησεν τὸν Ἐλιακίμ, Ἐλιακὶμ δὲ ἐγέννησεν τὸν Ἀζώρ,
40001014 Ἀζὼρ δὲ ἐγέννησεν τὸν Σαδώκ, Σαδὼκ δὲ ἐγέννησεν τὸν Ἀχίμ, Ἀχὶμ δὲ ἐγέννησεν τὸν Ἐλιούδ,
40001015 Ἐλιοὺδ δὲ ἐγέννησεν τὸν Ἐλεάζαρ, Ἐλεάζαρ δὲ ἐγέννησεν τὸν Ματθάν, Ματθὰν δὲ ἐγέννησεν τὸν Ἰακώβ,
40001016 Ἰακὼβ δὲ ἐγέννησεν τὸν Ἰωσὴφ τὸν ἄνδρα Μαρίας, ἐξ ἧς ἐγεννήθη ˚Ἰησοῦς, ὁ λεγόμενος ˚Χριστός.
40001017 Πᾶσαι οὖν αἱ γενεαὶ ἀπὸ Ἀβραὰμ ἕως Δαυὶδ γενεαὶ δεκατέσσαρες, καὶ ἀπὸ Δαυὶδ ἕως τῆς μετοικεσίας Βαβυλῶνος γενεαὶ δεκατέσσαρες, καὶ ἀπὸ τῆς μετοικεσίας Βαβυλῶνος ἕως τοῦ ˚Χριστοῦ γενεαὶ δεκατέσσαρες.
40001018 Τοῦ δὲ ˚Ἰησοῦ ˚Χριστοῦ ἡ γένεσις οὕτως ἦν: μνηστευθείσης τῆς μητρὸς αὐτοῦ Μαρίας τῷ Ἰωσήφ, πρὶν ἢ συνελθεῖν αὐτοὺς, εὑρέθη ἐν γαστρὶ ἔχουσα ἐκ ˚Πνεύματος Ἁγίου.
40001019 Ἰωσὴφ δὲ ὁ ἀνὴρ αὐτῆς, δίκαιος ὢν καὶ μὴ θέλων αὐτὴν δειγματίσαι, ἐβουλήθη λάθρᾳ ἀπολῦσαι αὐτήν.
40001020 Ταῦτα δὲ αὐτοῦ ἐνθυμηθέντος, ἰδοὺ, ἄγγελος ˚Κυρίου κατʼ ὄναρ ἐφάνη αὐτῷ λέγων, “Ἰωσὴφ, υἱὸς Δαυίδ, μὴ φοβηθῇς παραλαβεῖν Μαριὰμ τὴν γυναῖκά σου, τὸ γὰρ ἐν αὐτῇ γεννηθὲν ἐκ ˚Πνεύματός ἐστιν Ἁγίου.
40001021 Τέξεται δὲ υἱὸν, καὶ καλέσεις τὸ ὄνομα αὐτοῦ ˚Ἰησοῦν, αὐτὸς γὰρ σώσει τὸν λαὸν αὐτοῦ ἀπὸ τῶν ἁμαρτιῶν αὐτῶν.”
40001022 Τοῦτο δὲ ὅλον γέγονεν, ἵνα πληρωθῇ τὸ ῥηθὲν ὑπὸ ˚Κυρίου διὰ τοῦ προφήτου λέγοντος,
40001023 “Ἰδοὺ, ἡ παρθένος ἐν γαστρὶ ἕξει καὶ τέξεται υἱόν, καὶ καλέσουσιν τὸ ὄνομα αὐτοῦ Ἐμμανουήλ”, ὅ ἐστιν μεθερμηνευόμενον, “Μεθʼ ἡμῶν ὁ ˚Θεός”.
40001024 Ἐγερθεὶς δὲ ὁ Ἰωσὴφ ἀπὸ τοῦ ὕπνου, ἐποίησεν ὡς προσέταξεν αὐτῷ ὁ ἄγγελος ˚Κυρίου, καὶ παρέλαβεν τὴν γυναῖκα αὐτοῦ,
40001025 καὶ οὐκ ἐγίνωσκεν αὐτὴν ἕως οὗ ἔτεκεν υἱόν· καὶ ἐκάλεσεν τὸ ὄνομα αὐτοῦ, ˚Ἰησοῦν.
40002001 Τοῦ δὲ ˚Ἰησοῦ γεννηθέντος ἐν Βηθλέεμ τῆς Ἰουδαίας ἐν ἡμέραις Ἡρῴδου τοῦ βασιλέως, ἰδοὺ, μάγοι ἀπὸ ἀνατολῶν παρεγένοντο εἰς Ἱεροσόλυμα
40002002 λέγοντες, “Ποῦ ἐστιν ὁ, τεχθεὶς Βασιλεὺς τῶν Ἰουδαίων; Εἴδομεν γὰρ αὐτοῦ τὸν ἀστέρα ἐν τῇ ἀνατολῇ καὶ ἤλθομεν προσκυνῆσαι αὐτῷ.”
40002003 Ἀκούσας δὲ ὁ βασιλεὺς Ἡρῴδης ἐταράχθη, καὶ πᾶσα Ἱεροσόλυμα μετʼ αὐτοῦ.
40002004 Καὶ συναγαγὼν πάντας τοὺς ἀρχιερεῖς καὶ γραμματεῖς τοῦ λαοῦ, ἐπυνθάνετο παρʼ αὐτῶν ποῦ ὁ ˚Χριστὸς γεννᾶται.
40002005 Οἱ δὲ εἶπαν αὐτῷ, “Ἐν Βηθλέεμ τῆς Ἰουδαίας”· οὕτως γὰρ γέγραπται διὰ τοῦ προφήτου:
40002006 “Καὶ σύ Βηθλέεμ, γῆ Ἰούδα, οὐδαμῶς ἐλαχίστη εἶ ἐν τοῖς ἡγεμόσιν Ἰούδα· ἐξ σοῦ γὰρ ἐξελεύσεται ἡγούμενος, ὅστις ποιμανεῖ τὸν λαόν μου τὸν Ἰσραήλ.”
40002007 Τότε Ἡρῴδης λάθρᾳ καλέσας τοὺς μάγους, ἠκρίβωσεν παρʼ αὐτῶν τὸν χρόνον τοῦ φαινομένου ἀστέρος.
40002008 Καὶ πέμψας αὐτοὺς εἰς Βηθλέεμ εἶπεν, “Πορευθέντες, ἐξετάσατε ἀκριβῶς περὶ τοῦ παιδίου· ἐπὰν δὲ εὕρητε, ἀπαγγείλατέ μοι ὅπως κἀγὼ ἐλθὼν προσκυνήσω αὐτῷ.”
40002009 Οἱ δὲ ἀκούσαντες τοῦ βασιλέως, ἐπορεύθησαν, καὶ ἰδοὺ, ὁ ἀστὴρ, ὃν εἶδον ἐν τῇ ἀνατολῇ, προῆγεν αὐτούς ἕως ἐλθὼν ἐστάθη ἐπάνω οὗ ἦν τὸ παιδίον.
40002010 Ἰδόντες δὲ τὸν ἀστέρα, ἐχάρησαν χαρὰν μεγάλην σφόδρα.
40002011 Καὶ ἐλθόντες εἰς τὴν οἰκίαν, εἶδον τὸ παιδίον μετὰ Μαρίας τῆς μητρὸς αὐτοῦ, καὶ πεσόντες προσεκύνησαν αὐτῷ, καὶ ἀνοίξαντες τοὺς θησαυροὺς αὐτῶν, προσήνεγκαν αὐτῷ δῶρα: χρυσὸν, καὶ λίβανον, καὶ σμύρναν.
40002012 Καὶ χρηματισθέντες κατʼ ὄναρ μὴ ἀνακάμψαι πρὸς Ἡρῴδην, διʼ ἄλλης ὁδοῦ ἀνεχώρησαν εἰς τὴν χώραν αὐτῶν.
40002013 Ἀναχωρησάντων δὲ αὐτῶν, ἰδοὺ, ἄγγελος ˚Κυρίου φαίνεται κατʼ ὄναρ τῷ Ἰωσὴφ λέγων, “Ἐγερθεὶς, παράλαβε τὸ παιδίον καὶ τὴν μητέρα αὐτοῦ καὶ φεῦγε εἰς Αἴγυπτον, καὶ ἴσθι ἐκεῖ ἕως ἂν εἴπω σοι· μέλλει γὰρ Ἡρῴδης ζητεῖν τὸ παιδίον, τοῦ ἀπολέσαι αὐτό.”
40002014 Ὁ δὲ ἐγερθεὶς, παρέλαβε τὸ παιδίον καὶ τὴν μητέρα αὐτοῦ νυκτὸς καὶ ἀνεχώρησεν εἰς Αἴγυπτον,
40002015 καὶ ἦν ἐκεῖ ἕως τῆς τελευτῆς Ἡρῴδου, ἵνα πληρωθῇ τὸ ῥηθὲν ὑπὸ ˚Κυρίου διὰ τοῦ προφήτου λέγοντος, “Ἐξ Αἰγύπτου ἐκάλεσα τὸν Υἱόν μου.”
40002016 Τότε Ἡρῴδης, ἰδὼν ὅτι ἐνεπαίχθη ὑπὸ τῶν μάγων, ἐθυμώθη λίαν καὶ ἀποστείλας, ἀνεῖλεν πάντας τοὺς παῖδας τοὺς ἐν Βηθλέεμ καὶ ἐν πᾶσι τοῖς ὁρίοις αὐτῆς ἀπὸ διετοῦς καὶ κατωτέρω, κατὰ τὸν χρόνον ὃν ἠκρίβωσεν παρὰ τῶν μάγων.
40002017 Τότε ἐπληρώθη τὸ ῥηθὲν διὰ Ἰερεμίου τοῦ προφήτου λέγοντος,
40002018 “Φωνὴ ἐν Ῥαμὰ ἠκούσθη, κλαυθμὸς καὶ ὀδυρμὸς πολύς, Ῥαχὴλ κλαίουσα τὰ τέκνα αὐτῆς, καὶ οὐκ ἤθελεν παρακληθῆναι, ὅτι οὐκ εἰσίν.”
40002019 Τελευτήσαντος δὲ τοῦ Ἡρῴδου, ἰδοὺ, ἄγγελος ˚Κυρίου φαίνεται κατʼ ὄναρ τῷ Ἰωσὴφ ἐν Αἰγύπτῳ
40002020 λέγων, “Ἐγερθεὶς, παράλαβε τὸ παιδίον καὶ τὴν μητέρα αὐτοῦ, καὶ πορεύου εἰς γῆν Ἰσραήλ, τεθνήκασιν γὰρ οἱ ζητοῦντες τὴν ψυχὴν τοῦ παιδίου.”
40002021 Ὁ δὲ ἐγερθεὶς, παρέλαβεν τὸ παιδίον καὶ τὴν μητέρα αὐτοῦ, καὶ εἰσῆλθεν εἰς γῆν Ἰσραήλ.
40002022 Ἀκούσας δὲ ὅτι Ἀρχέλαος βασιλεύει τῆς Ἰουδαίας ἀντὶ τοῦ πατρὸς αὐτοῦ Ἡρῴδου, ἐφοβήθη ἐκεῖ ἀπελθεῖν· χρηματισθεὶς δὲ κατʼ ὄναρ, ἀνεχώρησεν εἰς τὰ μέρη τῆς Γαλιλαίας,
40002023 καὶ ἐλθὼν, κατῴκησεν εἰς πόλιν λεγομένην Ναζαρέτ, ὅπως πληρωθῇ τὸ ῥηθὲν διὰ τῶν προφητῶν, ὅτι “Ναζωραῖος κληθήσεται.”
40003001 Ἐν δὲ ταῖς ἡμέραις ἐκείναις παραγίνεται Ἰωάννης ὁ Βαπτιστὴς, κηρύσσων ἐν τῇ ἐρήμῳ τῆς Ἰουδαίας
40003002 λέγων, “Μετανοεῖτε, ἤγγικεν γὰρ ἡ Βασιλεία τῶν Οὐρανῶν.”
40003003 Οὗτος γάρ ἐστιν ὁ ῥηθεὶς διὰ Ἠσαΐου τοῦ προφήτου λέγοντος, “Φωνὴ βοῶντος ἐν τῇ ἐρήμῳ: ‘Ἑτοιμάσατε τὴν ὁδὸν ˚Κυρίου, εὐθείας ποιεῖτε τὰς τρίβους αὐτοῦ.’”
40003004 Αὐτὸς δὲ ὁ Ἰωάννης εἶχεν τὸ ἔνδυμα αὐτοῦ ἀπὸ τριχῶν καμήλου καὶ ζώνην δερματίνην περὶ τὴν ὀσφὺν αὐτοῦ, ἡ δὲ τροφὴ ἦν αὐτοῦ ἀκρίδες καὶ μέλι ἄγριον.
40003005 Τότε ἐξεπορεύετο πρὸς αὐτὸν Ἱεροσόλυμα, καὶ πᾶσα ἡ Ἰουδαία, καὶ πᾶσα ἡ περίχωρος τοῦ Ἰορδάνου,
40003006 καὶ ἐβαπτίζοντο ἐν τῷ Ἰορδάνῃ Ποταμῷ ὑπʼ αὐτοῦ, ἐξομολογούμενοι τὰς ἁμαρτίας αὐτῶν.
40003007 Ἰδὼν δὲ πολλοὺς τῶν Φαρισαίων καὶ Σαδδουκαίων ἐρχομένους ἐπὶ τὸ βάπτισμα αὐτοῦ, εἶπεν αὐτοῖς, “Γεννήματα ἐχιδνῶν, τίς ὑπέδειξεν ὑμῖν φυγεῖν ἀπὸ τῆς μελλούσης ὀργῆς;
40003008 Ποιήσατε οὖν καρπὸν ἄξιον τῆς μετανοίας.
40003009 Καὶ μὴ δόξητε λέγειν ἐν ἑαυτοῖς, πατέρα ἔχομεν τὸν Ἀβραάμ, λέγω γὰρ ὑμῖν ὅτι δύναται ὁ ˚Θεὸς ἐκ τῶν λίθων τούτων ἐγεῖραι τέκνα τῷ Ἀβραάμ.
40003010 Ἤδη δὲ ἡ ἀξίνη πρὸς τὴν ῥίζαν τῶν δένδρων κεῖται· πᾶν οὖν δένδρον μὴ ποιοῦν καρπὸν καλὸν ἐκκόπτεται καὶ εἰς πῦρ βάλλεται.
40003011 Ἐγὼ μὲν ὑμᾶς βαπτίζω ἐν ὕδατι εἰς μετάνοιαν, ὁ δὲ ὀπίσω μου ἐρχόμενος ἰσχυρότερός μού ἐστιν, οὗ οὐκ εἰμὶ ἱκανὸς τὰ ὑποδήματα βαστάσαι. Αὐτὸς ὑμᾶς βαπτίσει ἐν ˚Πνεύματι Ἁγίῳ καὶ πυρί.
40003012 Οὗ τὸ πτύον ἐν τῇ χειρὶ αὐτοῦ, καὶ διακαθαριεῖ τὴν ἅλωνα αὐτοῦ, καὶ συνάξει τὸν σῖτον αὐτοῦ εἰς τὴν ἀποθήκην, τὸ δὲ ἄχυρον κατακαύσει πυρὶ ἀσβέστῳ.”
40003013 Τότε παραγίνεται ὁ ˚Ἰησοῦς ἀπὸ τῆς Γαλιλαίας ἐπὶ τὸν Ἰορδάνην πρὸς τὸν Ἰωάννην, τοῦ βαπτισθῆναι ὑπʼ αὐτοῦ.
40003014 Ὁ δὲ διεκώλυεν αὐτὸν λέγων, “Ἐγὼ χρείαν ἔχω ὑπὸ σοῦ βαπτισθῆναι, καὶ σὺ ἔρχῃ πρός με;”
40003015 Ἀποκριθεὶς δὲ ὁ ˚Ἰησοῦς εἶπεν πρὸς αὐτόν, “Ἄφες ἄρτι, οὕτως γὰρ πρέπον ἐστὶν ἡμῖν πληρῶσαι πᾶσαν δικαιοσύνην.” Τότε ἀφίησιν αὐτόν.
40003016 Βαπτισθεὶς δὲ, ὁ ˚Ἰησοῦς εὐθὺς ἀνέβη ἀπὸ τοῦ ὕδατος· καὶ ἰδοὺ, ἀνεῴχθησαν οἱ οὐρανοί, καὶ εἶδεν τὸ ˚Πνεῦμα τοῦ ˚Θεοῦ καταβαῖνον ὡσεὶ περιστερὰν καὶ ἐρχόμενον ἐπʼ αὐτόν.
40003017 Καὶ ἰδοὺ, φωνὴ ἐκ τῶν οὐρανῶν λέγουσα, “Οὗτός ἐστιν ὁ Υἱός μου, ὁ ἀγαπητός ἐν ᾧ εὐδόκησα.”
40004001 Τότε ὁ ˚Ἰησοῦς ἀνήχθη εἰς τὴν ἔρημον ὑπὸ τοῦ ˚Πνεύματος πειρασθῆναι ὑπὸ τοῦ διαβόλου.
40004002 Καὶ νηστεύσας ἡμέρας τεσσεράκοντα καὶ νύκτας τεσσεράκοντα, ὕστερον ἐπείνασεν.
40004003 Καὶ προσελθὼν ὁ πειράζων, εἶπεν αὐτῷ, “Εἰ Υἱὸς εἶ τοῦ ˚Θεοῦ, εἰπὲ ἵνα οἱ λίθοι οὗτοι ἄρτοι γένωνται.”
40004004 Ὁ δὲ ἀποκριθεὶς εἶπεν, “Γέγραπται, ‘Οὐκ ἐπʼ ἄρτῳ μόνῳ ζήσεται ὁ ἄνθρωπος, ἀλλʼ ἐπὶ παντὶ ῥήματι ἐκπορευομένῳ διὰ στόματος ˚Θεοῦ.’”
40004005 Τότε παραλαμβάνει αὐτὸν ὁ διάβολος εἰς τὴν ἁγίαν πόλιν, καὶ ἔστησεν αὐτὸν ἐπὶ τὸ πτερύγιον τοῦ ἱεροῦ,
40004006 καὶ λέγει αὐτῷ, “Εἰ Υἱὸς εἶ τοῦ ˚Θεοῦ, βάλε σεαυτὸν κάτω· γέγραπται γὰρ, ὅτι ‘Τοῖς ἀγγέλοις αὐτοῦ ἐντελεῖται περὶ σοῦ’, καὶ, ‘Ἐπὶ χειρῶν ἀροῦσίν σε, μήποτε προσκόψῃς πρὸς λίθον τὸν πόδα σου.’”
40004007 Ἔφη αὐτῷ ὁ ˚Ἰησοῦς, “Πάλιν γέγραπται, ‘Οὐκ ἐκπειράσεις ˚Κύριον τὸν ˚Θεόν σου.’”
40004008 Πάλιν παραλαμβάνει αὐτὸν ὁ διάβολος εἰς ὄρος ὑψηλὸν λίαν, καὶ δείκνυσιν αὐτῷ πάσας τὰς βασιλείας τοῦ κόσμου καὶ τὴν δόξαν αὐτῶν,
40004009 καὶ εἶπεν αὐτῷ, “Ταῦτά σοι πάντα δώσω, ἐὰν πεσὼν προσκυνήσῃς μοι.”
40004010 Τότε λέγει αὐτῷ ὁ ˚Ἰησοῦς, “Ὕπαγε, Σατανᾶ· γέγραπται γάρ, ‘˚Κύριον τὸν ˚Θεόν σου προσκυνήσεις, καὶ αὐτῷ μόνῳ λατρεύσεις.’”
40004011 Τότε ἀφίησιν αὐτὸν ὁ διάβολος, καὶ ἰδοὺ, ἄγγελοι προσῆλθον καὶ διηκόνουν αὐτῷ.
40004012 Ἀκούσας δὲ ὅτι Ἰωάννης παρεδόθη, ἀνεχώρησεν εἰς τὴν Γαλιλαίαν.
40004013 Καὶ καταλιπὼν τὴν Ναζαρὰ, ἐλθὼν κατῴκησεν εἰς Καφαρναοὺμ, τὴν παραθαλασσίαν ἐν ὁρίοις Ζαβουλὼν καὶ Νεφθαλείμ·
40004014 ἵνα πληρωθῇ τὸ ῥηθὲν διὰ Ἠσαΐου τοῦ προφήτου λέγοντος,
40004015 “Γῆ Ζαβουλὼν καὶ γῆ Νεφθαλείμ, ὁδὸν θαλάσσης, πέραν τοῦ Ἰορδάνου, Γαλιλαία τῶν ἐθνῶν.
40004016 Ὁ λαὸς ὁ καθήμενος ἐν σκοτίᾳ φῶς εἶδεν μέγα, καὶ τοῖς καθημένοις ἐν χώρᾳ καὶ σκιᾷ θανάτου, φῶς ἀνέτειλεν αὐτοῖς.”
40004017 Ἀπὸ τότε ἤρξατο ὁ ˚Ἰησοῦς κηρύσσειν καὶ λέγειν, “Μετανοεῖτε, ἤγγικεν γὰρ ἡ Βασιλεία τῶν Οὐρανῶν.”
40004018 Περιπατῶν δὲ παρὰ τὴν θάλασσαν τῆς Γαλιλαίας, εἶδεν δύο ἀδελφούς, Σίμωνα τὸν λεγόμενον Πέτρον καὶ Ἀνδρέαν τὸν ἀδελφὸν αὐτοῦ, βάλλοντας ἀμφίβληστρον εἰς τὴν θάλασσαν, ἦσαν γὰρ ἁλιεῖς.
40004019 Καὶ λέγει αὐτοῖς, “Δεῦτε ὀπίσω μου, καὶ ποιήσω ὑμᾶς ἁλιεῖς ἀνθρώπων.”
40004020 Οἱ δὲ εὐθέως ἀφέντες τὰ δίκτυα, ἠκολούθησαν αὐτῷ.
40004021 Καὶ προβὰς ἐκεῖθεν, εἶδεν ἄλλους δύο ἀδελφούς, Ἰάκωβον τὸν τοῦ Ζεβεδαίου καὶ Ἰωάννην τὸν ἀδελφὸν αὐτοῦ, ἐν τῷ πλοίῳ μετὰ Ζεβεδαίου τοῦ πατρὸς αὐτῶν, καταρτίζοντας τὰ δίκτυα αὐτῶν, καὶ ἐκάλεσεν αὐτούς.
40004022 Οἱ δὲ εὐθέως ἀφέντες τὸ πλοῖον καὶ τὸν πατέρα αὐτῶν, ἠκολούθησαν αὐτῷ.
40004023 Καὶ περιῆγεν ὁ ˚Ἰησοῦς ἐν ὅλῃ τῇ Γαλιλαίᾳ, διδάσκων ἐν ταῖς συναγωγαῖς αὐτῶν, καὶ κηρύσσων τὸ εὐαγγέλιον τῆς βασιλείας, καὶ θεραπεύων πᾶσαν νόσον καὶ πᾶσαν μαλακίαν ἐν τῷ λαῷ.
40004024 Καὶ ἀπῆλθεν ἡ ἀκοὴ αὐτοῦ εἰς ὅλην τὴν Συρίαν. Καὶ προσήνεγκαν αὐτῷ πάντας τοὺς κακῶς, ἔχοντας ποικίλαις νόσοις, καὶ βασάνοις συνεχομένους, δαιμονιζομένους, καὶ σεληνιαζομένους, καὶ παραλυτικούς, καὶ ἐθεράπευσεν αὐτούς.
40004025 Καὶ ἠκολούθησαν αὐτῷ ὄχλοι πολλοὶ ἀπὸ τῆς Γαλιλαίας, καὶ Δεκαπόλεως, καὶ Ἱεροσολύμων, καὶ Ἰουδαίας, καὶ πέραν τοῦ Ἰορδάνου.
40005001 Ἰδὼν δὲ τοὺς ὄχλους, ἀνέβη εἰς τὸ ὄρος· καὶ καθίσαντος αὐτοῦ, προσῆλθον αὐτῷ οἱ μαθηταὶ αὐτοῦ.
40005002 Καὶ ἀνοίξας τὸ στόμα αὐτοῦ, ἐδίδασκεν αὐτοὺς λέγων,
40005003 “Μακάριοι οἱ πτωχοὶ τῷ πνεύματι, ὅτι αὐτῶν ἐστιν ἡ Βασιλεία τῶν Οὐρανῶν.
40005004 Μακάριοι οἱ πενθοῦντες, ὅτι αὐτοὶ παρακληθήσονται.
40005005 Μακάριοι οἱ πραεῖς, ὅτι αὐτοὶ κληρονομήσουσιν τὴν γῆν.
40005006 Μακάριοι οἱ πεινῶντες καὶ διψῶντες τὴν δικαιοσύνην, ὅτι αὐτοὶ χορτασθήσονται.
40005007 Μακάριοι οἱ ἐλεήμονες, ὅτι αὐτοὶ ἐλεηθήσονται.
40005008 Μακάριοι οἱ καθαροὶ τῇ καρδίᾳ, ὅτι αὐτοὶ τὸν ˚Θεὸν ὄψονται.
40005009 Μακάριοι οἱ εἰρηνοποιοί, ὅτι αὐτοὶ υἱοὶ ˚Θεοῦ κληθήσονται.
40005010 Μακάριοι οἱ δεδιωγμένοι ἕνεκεν δικαιοσύνης, ὅτι αὐτῶν ἐστιν ἡ Βασιλεία τῶν Οὐρανῶν.
40005011 Μακάριοί ἐστε ὅταν ὀνειδίσωσιν ὑμᾶς καὶ διώξωσιν καὶ εἴπωσιν πᾶν πονηρὸν καθʼ ὑμῶν ψευδόμενοι ἕνεκεν ἐμοῦ.
40005012 Χαίρετε καὶ ἀγαλλιᾶσθε, ὅτι ὁ μισθὸς ὑμῶν πολὺς ἐν τοῖς οὐρανοῖς· οὕτως γὰρ ἐδίωξαν τοὺς προφήτας τοὺς πρὸ ὑμῶν.
40005013 Ὑμεῖς ἐστε τὸ ἅλας τῆς γῆς· ἐὰν δὲ τὸ ἅλας μωρανθῇ, ἐν τίνι ἁλισθήσεται; Εἰς οὐδὲν ἰσχύει ἔτι, εἰ μὴ βληθὲν ἔξω, καταπατεῖσθαι ὑπὸ τῶν ἀνθρώπων.
40005014 Ὑμεῖς ἐστε τὸ φῶς τοῦ κόσμου. Οὐ δύναται πόλις κρυβῆναι ἐπάνω ὄρους κειμένη.
40005015 Οὐδὲ καίουσιν λύχνον καὶ τιθέασιν αὐτὸν ὑπὸ τὸν μόδιον, ἀλλʼ ἐπὶ τὴν λυχνίαν, καὶ λάμπει πᾶσιν τοῖς ἐν τῇ οἰκίᾳ.
40005016 Οὕτως λαμψάτω τὸ φῶς ὑμῶν ἔμπροσθεν τῶν ἀνθρώπων, ὅπως ἴδωσιν ὑμῶν τὰ καλὰ ἔργα, καὶ δοξάσωσιν τὸν Πατέρα ὑμῶν τὸν ἐν τοῖς οὐρανοῖς.
40005017 Μὴ νομίσητε ὅτι ἦλθον καταλῦσαι τὸν νόμον ἢ τοὺς προφήτας· οὐκ ἦλθον καταλῦσαι, ἀλλὰ πληρῶσαι.
40005018 Ἀμὴν, γὰρ λέγω ὑμῖν, ἕως ἂν παρέλθῃ ὁ οὐρανὸς καὶ ἡ γῆ, ἰῶτα ἓν ἢ μία κεραία οὐ μὴ παρέλθῃ ἀπὸ τοῦ νόμου, ἕως ἂν πάντα γένηται.
40005019 Ὃς ἐὰν οὖν λύσῃ μίαν τῶν ἐντολῶν τούτων τῶν ἐλαχίστων, καὶ διδάξῃ οὕτως τοὺς ἀνθρώπους, ἐλάχιστος κληθήσεται ἐν τῇ Βασιλείᾳ τῶν Οὐρανῶν· ὃς δʼ ἂν ποιήσῃ καὶ διδάξῃ, οὗτος μέγας κληθήσεται ἐν τῇ Βασιλείᾳ τῶν Οὐρανῶν.
40005020 Λέγω γὰρ ὑμῖν ὅτι ἐὰν μὴ περισσεύσῃ ὑμῶν ἡ δικαιοσύνη πλεῖον τῶν γραμματέων καὶ Φαρισαίων, οὐ μὴ εἰσέλθητε εἰς τὴν Βασιλείαν τῶν Οὐρανῶν.
40005021 Ἠκούσατε ὅτι ἐρρέθη τοῖς ἀρχαίοις, ‘“Οὐ φονεύσεις”, ὃς δʼ, ἂν φονεύσῃ, ἔνοχος ἔσται τῇ κρίσει.’
40005022 Ἐγὼ δὲ λέγω ὑμῖν ὅτι πᾶς ὁ ὀργιζόμενος τῷ ἀδελφῷ αὐτοῦ, ἔνοχος ἔσται τῇ κρίσει· ὃς δʼ ἂν εἴπῃ τῷ ἀδελφῷ αὐτοῦ, ‘Ῥακά’, ἔνοχος ἔσται τῷ Συνεδρίῳ· ὃς δʼ ἂν εἴπῃ, ‘Μωρέ’, ἔνοχος ἔσται εἰς τὴν Γέενναν τοῦ πυρός.
40005023 Ἐὰν οὖν προσφέρῃς τὸ δῶρόν σου ἐπὶ τὸ θυσιαστήριον, κἀκεῖ μνησθῇς ὅτι ὁ ἀδελφός σου ἔχει τι κατὰ σοῦ,
40005024 ἄφες ἐκεῖ τὸ δῶρόν σου ἔμπροσθεν τοῦ θυσιαστηρίου. Καὶ ὕπαγε πρῶτον διαλλάγηθι τῷ ἀδελφῷ σου, καὶ τότε ἐλθὼν, πρόσφερε τὸ δῶρόν σου.
40005025 Ἴσθι εὐνοῶν τῷ ἀντιδίκῳ σου ταχὺ, ἕως ὅτου εἶ μετʼ αὐτοῦ ἐν τῇ ὁδῷ, μήποτέ σε παραδῷ ὁ ἀντίδικος τῷ κριτῇ, καὶ ὁ κριτὴς τῷ ὑπηρέτῃ, καὶ εἰς φυλακὴν βληθήσῃ.
40005026 Ἀμὴν, λέγω σοι, οὐ μὴ ἐξέλθῃς ἐκεῖθεν, ἕως ἂν ἀποδῷς τὸν ἔσχατον κοδράντην.
40005027 Ἠκούσατε ὅτι ἐρρέθη, ‘Οὐ μοιχεύσεις.’
40005028 Ἐγὼ δὲ λέγω ὑμῖν ὅτι πᾶς ὁ βλέπων γυναῖκα πρὸς τὸ ἐπιθυμῆσαι αὐτὴν, ἤδη ἐμοίχευσεν αὐτὴν ἐν τῇ καρδίᾳ αὐτοῦ.
40005029 Εἰ δὲ ὁ ὀφθαλμός σου ὁ δεξιὸς σκανδαλίζει σε, ἔξελε αὐτὸν καὶ βάλε ἀπὸ σοῦ, συμφέρει γάρ σοι ἵνα ἀπόληται ἓν τῶν μελῶν σου, καὶ μὴ ὅλον τὸ σῶμά σου βληθῇ εἰς Γέενναν.
40005030 Καὶ εἰ ἡ δεξιά σου χεὶρ σκανδαλίζει σε, ἔκκοψον αὐτὴν καὶ βάλε ἀπὸ σοῦ, συμφέρει γάρ σοι ἵνα ἀπόληται ἓν τῶν μελῶν σου, καὶ μὴ ὅλον τὸ σῶμά σου εἰς Γέενναν ἀπέλθῃ.
40005031 Ἐρρέθη δέ, ‘Ὃς ἂν ἀπολύσῃ τὴν γυναῖκα αὐτοῦ, δότω αὐτῇ ἀποστάσιον.’
40005032 Ἐγὼ δὲ λέγω ὑμῖν ὅτι πᾶς ὁ ἀπολύων τὴν γυναῖκα αὐτοῦ, παρεκτὸς λόγου πορνείας, ποιεῖ αὐτὴν μοιχευθῆναι, καὶ ὃς ἐὰν ἀπολελυμένην γαμήσῃ, μοιχᾶται.
40005033 Πάλιν ἠκούσατε ὅτι ἐρρέθη τοῖς ἀρχαίοις, ‘Οὐκ ἐπιορκήσεις, ἀποδώσεις δὲ τῷ ˚Κυρίῳ τοὺς ὅρκους σου.’
40005034 Ἐγὼ δὲ λέγω ὑμῖν μὴ ὀμόσαι ὅλως· μήτε ἐν τῷ οὐρανῷ, ὅτι θρόνος ἐστὶν τοῦ ˚Θεοῦ·
40005035 μήτε ἐν τῇ γῇ, ὅτι ὑποπόδιόν ἐστιν τῶν ποδῶν αὐτοῦ· μήτε εἰς Ἱεροσόλυμα, ὅτι πόλις ἐστὶν τοῦ μεγάλου Βασιλέως·
40005036 μήτε ἐν τῇ κεφαλῇ σου ὀμόσῃς, ὅτι οὐ δύνασαι μίαν τρίχα λευκὴν ποιῆσαι ἢ μέλαιναν.
40005037 Ἔστω δὲ ὁ λόγος ὑμῶν, ναὶ ναί, οὒ οὔ· τὸ δὲ περισσὸν τούτων ἐκ τοῦ πονηροῦ ἐστιν.
40005038 Ἠκούσατε ὅτι ἐρρέθη, ‘Ὀφθαλμὸν ἀντὶ ὀφθαλμοῦ καὶ ὀδόντα ἀντὶ ὀδόντος.’
40005039 Ἐγὼ δὲ λέγω ὑμῖν, μὴ ἀντιστῆναι τῷ πονηρῷ· ἀλλʼ ὅστις σε ῥαπίζει εἰς τὴν δεξιὰν σιαγόνα, σου στρέψον αὐτῷ καὶ τὴν ἄλλην·
40005040 καὶ τῷ θέλοντί σοι κριθῆναι καὶ τὸν χιτῶνά σου λαβεῖν, ἄφες αὐτῷ καὶ τὸ ἱμάτιον·
40005041 καὶ ὅστις σε ἀγγαρεύσει μίλιον ἕν, ὕπαγε μετʼ αὐτοῦ δύο.
40005042 Τῷ αἰτοῦντί σε, δός, καὶ τὸν θέλοντα ἀπὸ σοῦ δανίσασθαι, μὴ ἀποστραφῇς.
40005043 Ἠκούσατε ὅτι ἐρρέθη, ‘“Ἀγαπήσεις τὸν πλησίον σου”, καὶ μισήσεις τὸν ἐχθρόν σου.’
40005044 Ἐγὼ δὲ λέγω ὑμῖν, ἀγαπᾶτε τοὺς ἐχθροὺς ὑμῶν, καὶ προσεύχεσθε ὑπὲρ τῶν διωκόντων ὑμᾶς·
40005045 ὅπως γένησθε υἱοὶ τοῦ Πατρὸς ὑμῶν τοῦ ἐν οὐρανοῖς, ὅτι τὸν ἥλιον αὐτοῦ ἀνατέλλει ἐπὶ πονηροὺς καὶ ἀγαθοὺς, καὶ βρέχει ἐπὶ δικαίους καὶ ἀδίκους.
40005046 Ἐὰν γὰρ ἀγαπήσητε τοὺς ἀγαπῶντας ὑμᾶς, τίνα μισθὸν ἔχετε; Οὐχὶ καὶ οἱ τελῶναι τὸ αὐτὸ ποιοῦσιν;
40005047 Καὶ ἐὰν ἀσπάσησθε τοὺς ἀδελφοὺς ὑμῶν μόνον, τί περισσὸν ποιεῖτε; Οὐχὶ καὶ οἱ ἐθνικοὶ τὸ αὐτὸ ποιοῦσιν;
40005048 Ἔσεσθε οὖν ὑμεῖς τέλειοι ὡς ὁ Πατὴρ ὑμῶν ὁ οὐράνιος τέλειός ἐστιν.
40006001 Προσέχετε τὴν δικαιοσύνην ὑμῶν μὴ ποιεῖν ἔμπροσθεν τῶν ἀνθρώπων, πρὸς τὸ θεαθῆναι αὐτοῖς· εἰ δὲ μή γε, μισθὸν οὐκ ἔχετε παρὰ τῷ Πατρὶ ὑμῶν, τῷ ἐν τοῖς οὐρανοῖς.
40006002 Ὅταν οὖν ποιῇς ἐλεημοσύνην, μὴ σαλπίσῃς ἔμπροσθέν σου, ὥσπερ οἱ ὑποκριταὶ ποιοῦσιν ἐν ταῖς συναγωγαῖς καὶ ἐν ταῖς ῥύμαις, ὅπως δοξασθῶσιν ὑπὸ τῶν ἀνθρώπων. Ἀμὴν, λέγω ὑμῖν, ἀπέχουσιν τὸν μισθὸν αὐτῶν.
40006003 Σοῦ δὲ ποιοῦντος ἐλεημοσύνην, μὴ γνώτω ἡ ἀριστερά σου τί ποιεῖ ἡ δεξιά σου,
40006004 ὅπως ᾖ σου ἡ ἐλεημοσύνη ἐν τῷ κρυπτῷ· καὶ ὁ Πατήρ σου, ὁ βλέπων ἐν τῷ κρυπτῷ, ἀποδώσει σοι.
40006005 Καὶ ὅταν προσεύχῃ, οὐκ ἔσεσθε ὡς οἱ ὑποκριταί, ὅτι φιλοῦσιν ἐν ταῖς συναγωγαῖς καὶ ἐν ταῖς γωνίαις τῶν πλατειῶν ἑστῶτες προσεύχεσθαι, ὅπως φανῶσιν τοῖς ἀνθρώποις. Ἀμὴν, λέγω ὑμῖν, ἀπέχουσι τὸν μισθὸν αὐτῶν.
40006006 Σὺ δὲ ὅταν προσεύχῃ, εἴσελθε εἰς τὸ ταμεῖόν σου καὶ κλείσας τὴν θύραν σου, πρόσευξαι τῷ Πατρί σου, τῷ ἐν τῷ κρυπτῷ, καὶ ὁ Πατήρ σου, ὁ βλέπων ἐν τῷ κρυπτῷ, ἀποδώσει σοι.
40006007 Προσευχόμενοι δὲ μὴ βατταλογήσητε ὥσπερ οἱ ἐθνικοί, δοκοῦσιν γὰρ ὅτι ἐν τῇ πολυλογίᾳ αὐτῶν εἰσακουσθήσονται.
40006008 Μὴ οὖν ὁμοιωθῆτε αὐτοῖς, οἶδεν γὰρ ὁ ˚Θεὸς ὁ Πατὴρ ὑμῶν ὧν χρείαν ἔχετε πρὸ τοῦ ὑμᾶς αἰτῆσαι αὐτόν.
40006009 Οὕτως οὖν προσεύχεσθε ὑμεῖς: ‘Πάτερ ἡμῶν, ὁ ἐν τοῖς οὐρανοῖς, ἁγιασθήτω τὸ ὄνομά σου.
40006010 ἐλθέτω ἡ βασιλεία σου, γενηθήτω τὸ θέλημά σου, ὡς ἐν οὐρανῷ καὶ ἐπὶ γῆς.
40006011 Τὸν ἄρτον ἡμῶν τὸν ἐπιούσιον δὸς ἡμῖν σήμερον.
40006012 Καὶ ἄφες ἡμῖν τὰ ὀφειλήματα ἡμῶν, ὡς καὶ ἡμεῖς ἀφήκαμεν τοῖς ὀφειλέταις ἡμῶν.
40006013 Καὶ μὴ εἰσενέγκῃς ἡμᾶς εἰς πειρασμόν, ἀλλὰ ῥῦσαι ἡμᾶς ἀπὸ τοῦ πονηροῦ.’
40006014 Ἐὰν γὰρ ἀφῆτε τοῖς ἀνθρώποις τὰ παραπτώματα αὐτῶν, ἀφήσει καὶ ὑμῖν ὁ Πατὴρ ὑμῶν ὁ οὐράνιος.
40006015 Ἐὰν δὲ μὴ ἀφῆτε τοῖς ἀνθρώποις τὰ παραπτώματα αὐτῶν, οὐδὲ ὁ Πατὴρ ὑμῶν ἀφήσει τὰ παραπτώματα ὑμῶν.
40006016 Ὅταν δὲ νηστεύητε, μὴ γίνεσθε ὡς οἱ ὑποκριταὶ σκυθρωποί, ἀφανίζουσιν γὰρ τὰ πρόσωπα αὐτῶν, ὅπως φανῶσιν τοῖς ἀνθρώποις νηστεύοντες. Ἀμὴν, λέγω ὑμῖν, ἀπέχουσιν τὸν μισθὸν αὐτῶν.
40006017 Σὺ δὲ νηστεύων, ἄλειψαί σου τὴν κεφαλὴν καὶ τὸ πρόσωπόν σου νίψαι,
40006018 ὅπως μὴ φανῇς τοῖς ἀνθρώποις νηστεύων, ἀλλὰ τῷ Πατρί σου τῷ ἐν τῷ κρυφαίῳ· καὶ ὁ Πατήρ σου, ὁ βλέπων ἐν τῷ κρυφαίῳ, ἀποδώσει σοι.
40006019 Μὴ θησαυρίζετε ὑμῖν θησαυροὺς ἐπὶ τῆς γῆς, ὅπου σὴς καὶ βρῶσις ἀφανίζει, καὶ ὅπου κλέπται διορύσσουσιν καὶ κλέπτουσιν·
40006020 θησαυρίζετε δὲ ὑμῖν θησαυροὺς ἐν οὐρανῷ, ὅπου οὔτε σὴς οὔτε βρῶσις ἀφανίζει, καὶ ὅπου κλέπται οὐ διορύσσουσιν οὐδὲ κλέπτουσιν.
40006021 Ὅπου γάρ ἐστιν ὁ θησαυρός σου, ἐκεῖ ἔσται καὶ ἡ καρδία σου.
40006022 Ὁ λύχνος τοῦ σώματός ἐστιν ὁ ὀφθαλμός. Ἐὰν οὖν ᾖ ὁ ὀφθαλμός σου ἁπλοῦς, ὅλον τὸ σῶμά σου φωτεινὸν ἔσται.
40006023 Ἐὰν δὲ ὁ ὀφθαλμός σου πονηρὸς ᾖ, ὅλον τὸ σῶμά σου σκοτεινὸν ἔσται. Εἰ οὖν τὸ φῶς τὸ ἐν σοὶ σκότος, ἐστίν τὸ σκότος πόσον!
40006024 Οὐδεὶς δύναται δυσὶ κυρίοις δουλεύειν· ἢ γὰρ τὸν ἕνα μισήσει καὶ τὸν ἕτερον ἀγαπήσει, ἢ ἑνὸς ἀνθέξεται καὶ τοῦ ἑτέρου καταφρονήσει. Οὐ δύνασθε ˚Θεῷ δουλεύειν καὶ μαμωνᾷ.
40006025 Διὰ τοῦτο λέγω ὑμῖν, μὴ μεριμνᾶτε τῇ ψυχῇ ὑμῶν τί φάγητε ἢ τί πίητε, μηδὲ τῷ σώματι ὑμῶν τί ἐνδύσησθε. Οὐχὶ ἡ ψυχὴ πλεῖόν ἐστι τῆς τροφῆς, καὶ τὸ σῶμα τοῦ ἐνδύματος;
40006026 Ἐμβλέψατε εἰς τὰ πετεινὰ τοῦ οὐρανοῦ, ὅτι οὐ σπείρουσιν, οὐδὲ θερίζουσιν, οὐδὲ συνάγουσιν εἰς ἀποθήκας, καὶ ὁ Πατὴρ ὑμῶν ὁ οὐράνιος τρέφει αὐτά· οὐχ ὑμεῖς μᾶλλον διαφέρετε αὐτῶν;
40006027 Τίς δὲ ἐξ ὑμῶν μεριμνῶν δύναται προσθεῖναι ἐπὶ τὴν ἡλικίαν αὐτοῦ πῆχυν ἕνα;
40006028 Καὶ περὶ ἐνδύματος τί μεριμνᾶτε; Καταμάθετε τὰ κρίνα τοῦ ἀγροῦ πῶς αὐξάνουσιν· οὐ κοπιοῦσιν οὐδὲ νήθουσιν·
40006029 λέγω δὲ ὑμῖν ὅτι οὐδὲ Σολομὼν ἐν πάσῃ τῇ δόξῃ αὐτοῦ περιεβάλετο ὡς ἓν τούτων.
40006030 Εἰ δὲ τὸν χόρτον τοῦ ἀγροῦ σήμερον ὄντα, καὶ αὔριον εἰς κλίβανον βαλλόμενον, ὁ ˚Θεὸς οὕτως ἀμφιέννυσιν, οὐ πολλῷ μᾶλλον ὑμᾶς, ὀλιγόπιστοι;
40006031 Μὴ οὖν μεριμνήσητε λέγοντες, ‘Τί φάγωμεν’, ἤ, ‘Τί πίωμεν’, ἤ, ‘Τί περιβαλώμεθα;’
40006032 Πάντα γὰρ ταῦτα τὰ ἔθνη ἐπιζητοῦσιν· οἶδεν γὰρ ὁ Πατὴρ ὑμῶν ὁ οὐράνιος ὅτι χρῄζετε τούτων ἁπάντων.
40006033 Ζητεῖτε δὲ πρῶτον τὴν βασιλείαν καὶ τὴν δικαιοσύνην αὐτοῦ, καὶ ταῦτα πάντα προστεθήσεται ὑμῖν.
40006034 Μὴ οὖν μεριμνήσητε εἰς τὴν αὔριον, ἡ γὰρ αὔριον μεριμνήσει ἑαυτῆς. Ἀρκετὸν τῇ ἡμέρᾳ ἡ κακία αὐτῆς.
40007001 Μὴ κρίνετε, ἵνα μὴ κριθῆτε·
40007002 ἐν ᾧ γὰρ κρίματι κρίνετε, κριθήσεσθε, καὶ ἐν ᾧ μέτρῳ μετρεῖτε, μετρηθήσεται ὑμῖν.
40007003 Τί δὲ βλέπεις τὸ κάρφος τὸ ἐν τῷ ὀφθαλμῷ τοῦ ἀδελφοῦ σου, τὴν δὲ ἐν τῷ σῷ ὀφθαλμῷ δοκὸν οὐ κατανοεῖς;
40007004 Ἢ πῶς ἐρεῖς τῷ ἀδελφῷ σου, ‘Ἄφες ἐκβάλω τὸ κάρφος ἐκ τοῦ ὀφθαλμοῦ σου’, καὶ ἰδοὺ, ἡ δοκὸς ἐν τῷ ὀφθαλμῷ σοῦ;
40007005 Ὑποκριτά, ἔκβαλε πρῶτον ἐκ τοῦ ὀφθαλμοῦ σοῦ τὴν δοκόν, καὶ τότε διαβλέψεις ἐκβαλεῖν τὸ κάρφος ἐκ τοῦ ὀφθαλμοῦ τοῦ ἀδελφοῦ σου.
40007006 Μὴ δῶτε τὸ ἅγιον τοῖς κυσίν, μηδὲ βάλητε τοὺς μαργαρίτας ὑμῶν ἔμπροσθεν τῶν χοίρων, μήποτε καταπατήσουσιν αὐτοὺς ἐν τοῖς ποσὶν αὐτῶν, καὶ στραφέντες ῥήξωσιν ὑμᾶς.
40007007 Αἰτεῖτε καὶ δοθήσεται ὑμῖν· ζητεῖτε καὶ εὑρήσετε· κρούετε καὶ ἀνοιγήσεται ὑμῖν.
40007008 Πᾶς γὰρ ὁ αἰτῶν λαμβάνει, καὶ ὁ ζητῶν εὑρίσκει, καὶ τῷ κρούοντι ἀνοιγήσεται.
40007009 Ἢ τίς ἐστιν ἐξ ὑμῶν ἄνθρωπος, ὃν αἰτήσει ὁ υἱὸς αὐτοῦ ἄρτον, μὴ λίθον ἐπιδώσει αὐτῷ;
40007010 Ἢ καὶ ἰχθὺν αἰτήσει, μὴ ὄφιν ἐπιδώσει αὐτῷ;
40007011 Εἰ οὖν ὑμεῖς πονηροὶ ὄντες, οἴδατε δόματα ἀγαθὰ διδόναι τοῖς τέκνοις ὑμῶν, πόσῳ μᾶλλον ὁ Πατὴρ ὑμῶν ὁ ἐν τοῖς οὐρανοῖς δώσει ἀγαθὰ τοῖς αἰτοῦσιν αὐτόν;
40007012 Πάντα οὖν, ὅσα ἐὰν θέλητε ἵνα ποιῶσιν ὑμῖν οἱ ἄνθρωποι, οὕτως καὶ ὑμεῖς ποιεῖτε αὐτοῖς. Οὗτος γάρ ἐστιν ὁ νόμος καὶ οἱ προφῆται.
40007013 Εἰσέλθατε διὰ τῆς στενῆς πύλης· ὅτι πλατεῖα ἡ πύλη καὶ εὐρύχωρος ἡ ὁδὸς ἡ ἀπάγουσα εἰς τὴν ἀπώλειαν, καὶ πολλοί εἰσιν οἱ εἰσερχόμενοι διʼ αὐτῆς·
40007014 τί στενὴ ἡ πύλη καὶ τεθλιμμένη ἡ ὁδὸς ἡ ἀπάγουσα εἰς τὴν ζωήν, καὶ ὀλίγοι εἰσὶν οἱ εὑρίσκοντες αὐτήν.
40007015 Προσέχετε ἀπὸ τῶν ψευδοπροφητῶν, οἵτινες ἔρχονται πρὸς ὑμᾶς ἐν ἐνδύμασι προβάτων, ἔσωθεν δέ εἰσιν λύκοι ἅρπαγες.
40007016 Ἀπὸ τῶν καρπῶν αὐτῶν ἐπιγνώσεσθε αὐτούς. Μήτι συλλέγουσιν ἀπὸ ἀκανθῶν σταφυλὰς, ἢ ἀπὸ τριβόλων σῦκα;
40007017 Οὕτως πᾶν δένδρον ἀγαθὸν καρποὺς καλοὺς ποιεῖ, τὸ δὲ σαπρὸν δένδρον καρποὺς πονηροὺς ποιεῖ.
40007018 Οὐ δύναται δένδρον ἀγαθὸν καρποὺς πονηροὺς ποιεῖν, οὐδὲ δένδρον σαπρὸν καρποὺς καλοὺς ποιεῖν.
40007019 Πᾶν δένδρον μὴ ποιοῦν καρπὸν καλὸν ἐκκόπτεται καὶ εἰς πῦρ βάλλεται.
40007020 Ἄρα γε ἀπὸ τῶν καρπῶν αὐτῶν ἐπιγνώσεσθε αὐτούς.
40007021 Οὐ πᾶς ὁ λέγων μοι, ‘˚Κύριε, ˚Κύριε’, εἰσελεύσεται εἰς τὴν Βασιλείαν τῶν Οὐρανῶν, ἀλλʼ ὁ ποιῶν τὸ θέλημα τοῦ Πατρός μου τοῦ ἐν τοῖς οὐρανοῖς.
40007022 Πολλοὶ ἐροῦσίν μοι ἐν ἐκείνῃ τῇ ἡμέρᾳ, ‘˚Κύριε, ˚Κύριε, οὐ τῷ σῷ ὀνόματι ἐπροφητεύσαμεν, καὶ τῷ σῷ ὀνόματι δαιμόνια ἐξεβάλομεν, καὶ τῷ σῷ ὀνόματι δυνάμεις πολλὰς ἐποιήσαμεν;’
40007023 Καὶ τότε ὁμολογήσω αὐτοῖς, ὅτι ‘Οὐδέποτε ἔγνων ὑμᾶς, “ἀποχωρεῖτε ἀπʼ ἐμοῦ, οἱ ἐργαζόμενοι τὴν ἀνομίαν”.’
40007024 Πᾶς οὖν ὅστις ἀκούει μου τοὺς λόγους τούτους καὶ ποιεῖ αὐτούς, ὁμοιωθήσεται ἀνδρὶ φρονίμῳ, ὅστις ᾠκοδόμησεν αὐτοῦ τὴν οἰκίαν ἐπὶ τὴν πέτραν.
40007025 Καὶ κατέβη ἡ βροχὴ, καὶ ἦλθον οἱ ποταμοὶ, καὶ ἔπνευσαν οἱ ἄνεμοι, καὶ προσέπεσαν τῇ οἰκίᾳ ἐκείνῃ, καὶ οὐκ ἔπεσεν, τεθεμελίωτο γὰρ ἐπὶ τὴν πέτραν.
40007026 Καὶ πᾶς ὁ ἀκούων μου τοὺς λόγους τούτους καὶ μὴ ποιῶν αὐτοὺς, ὁμοιωθήσεται ἀνδρὶ μωρῷ, ὅστις ᾠκοδόμησεν αὐτοῦ τὴν οἰκίαν ἐπὶ τὴν ἄμμον.
40007027 Καὶ κατέβη ἡ βροχὴ, καὶ ἦλθον οἱ ποταμοὶ, καὶ ἔπνευσαν οἱ ἄνεμοι, καὶ προσέκοψαν τῇ οἰκίᾳ ἐκείνῃ, καὶ ἔπεσεν καὶ ἦν ἡ πτῶσις αὐτῆς μεγάλη.”
40007028 Καὶ ἐγένετο, ὅτε ἐτέλεσεν ὁ ˚Ἰησοῦς τοὺς λόγους τούτους, ἐξεπλήσσοντο οἱ ὄχλοι ἐπὶ τῇ διδαχῇ αὐτοῦ·
40007029 ἦν γὰρ διδάσκων αὐτοὺς ὡς ἐξουσίαν ἔχων, καὶ οὐχ ὡς οἱ γραμματεῖς αὐτῶν.
40008001 Καταβάντος δὲ αὐτοῦ ἀπὸ τοῦ ὄρους, ἠκολούθησαν αὐτῷ ὄχλοι πολλοί.
40008002 Καὶ ἰδοὺ, λεπρὸς προσελθὼν, προσεκύνει αὐτῷ λέγων, “˚Κύριε, ἐὰν θέλῃς, δύνασαί με καθαρίσαι.”
40008003 Καὶ ἐκτείνας τὴν χεῖρα, ἥψατο αὐτοῦ λέγων, “Θέλω, καθαρίσθητι.” Καὶ εὐθέως ἐκαθαρίσθη αὐτοῦ ἡ λέπρα.
40008004 Καὶ λέγει αὐτῷ ὁ ˚Ἰησοῦς, “Ὅρα μηδενὶ εἴπῃς, ἀλλὰ ὕπαγε σεαυτὸν, δεῖξον τῷ ἱερεῖ, καὶ προσένεγκον τὸ δῶρον ὃ προσέταξεν Μωϋσῆς, εἰς μαρτύριον αὐτοῖς.”
40008005 Εἰσελθόντος δὲ αὐτοῦ εἰς Καφαρναοὺμ, προσῆλθεν αὐτῷ ἑκατόνταρχος παρακαλῶν αὐτὸν
40008006 καὶ λέγων, ˚Κύριε, ὁ παῖς μου βέβληται ἐν τῇ οἰκίᾳ παραλυτικός, δεινῶς βασανιζόμενος.
40008007 Καὶ λέγει αὐτῷ, “Ἐγὼ ἐλθὼν, θεραπεύσω αὐτόν.”
40008008 Ἀποκριθεὶς δὲ ὁ ἑκατόνταρχος ἔφη, “˚Κύριε, οὐκ εἰμὶ ἱκανὸς ἵνα μου ὑπὸ τὴν στέγην εἰσέλθῃς, ἀλλὰ μόνον εἰπὲ λόγῳ καὶ ἰαθήσεται ὁ παῖς μου.
40008009 Καὶ γὰρ ἐγὼ ἄνθρωπός εἰμι ὑπὸ ἐξουσίαν τασσόμενος, ἔχων ὑπʼ ἐμαυτὸν στρατιώτας, καὶ λέγω τούτῳ, ‘Πορεύθητι’, καὶ πορεύεται, καὶ ἄλλῳ, ‘Ἔρχου’, καὶ ἔρχεται, καὶ τῷ δούλῳ μου, ‘Ποίησον τοῦτο’, καὶ ποιεῖ.”
40008010 Ἀκούσας δὲ, ὁ ˚Ἰησοῦς ἐθαύμασεν καὶ εἶπεν τοῖς ἀκολουθοῦσιν, “Ἀμὴν, λέγω ὑμῖν, παρʼ οὐδενὶ τοσαύτην πίστιν ἐν τῷ Ἰσραὴλ εὗρον.
40008011 Λέγω δὲ ὑμῖν ὅτι πολλοὶ ἀπὸ ἀνατολῶν καὶ δυσμῶν ἥξουσιν καὶ ἀνακλιθήσονται μετὰ Ἀβραὰμ, καὶ Ἰσαὰκ, καὶ Ἰακὼβ, ἐν τῇ Βασιλείᾳ τῶν Οὐρανῶν·
40008012 οἱ δὲ υἱοὶ τῆς βασιλείας ἐκβληθήσονται εἰς τὸ σκότος τὸ ἐξώτερον, ἐκεῖ ἔσται ὁ κλαυθμὸς καὶ ὁ βρυγμὸς τῶν ὀδόντων.”
40008013 Καὶ εἶπεν ὁ ˚Ἰησοῦς τῷ ἑκατοντάρχῃ, “Ὕπαγε, ὡς ἐπίστευσας, γενηθήτω σοι.” Καὶ ἰάθη ὁ παῖς ἐν τῇ ὥρᾳ ἐκείνῃ.
40008014 Καὶ ἐλθὼν ὁ ˚Ἰησοῦς εἰς τὴν οἰκίαν Πέτρου, εἶδεν τὴν πενθερὰν αὐτοῦ βεβλημένην καὶ πυρέσσουσαν,
40008015 καὶ ἥψατο τῆς χειρὸς αὐτῆς, καὶ ἀφῆκεν αὐτὴν ὁ πυρετός, καὶ ἠγέρθη, καὶ διηκόνει αὐτῷ.
40008016 Ὀψίας δὲ γενομένης, προσήνεγκαν αὐτῷ δαιμονιζομένους πολλούς, καὶ ἐξέβαλεν τὰ πνεύματα λόγῳ, καὶ πάντας τοὺς κακῶς ἔχοντας, ἐθεράπευσεν·
40008017 ὅπως πληρωθῇ τὸ ῥηθὲν διὰ Ἠσαΐου τοῦ προφήτου λέγοντος, “Αὐτὸς τὰς ἀσθενείας ἡμῶν ἔλαβεν καὶ τὰς νόσους ἐβάστασεν.”
40008018 Ἰδὼν δὲ ὁ ˚Ἰησοῦς ὄχλον περὶ αὐτὸν, ἐκέλευσεν ἀπελθεῖν εἰς τὸ πέραν.
40008019 Καὶ προσελθὼν, εἷς γραμματεὺς εἶπεν αὐτῷ, “Διδάσκαλε, ἀκολουθήσω σοι ὅπου ἐὰν ἀπέρχῃ.”
40008020 Καὶ λέγει αὐτῷ ὁ ˚Ἰησοῦς, “Αἱ ἀλώπεκες φωλεοὺς ἔχουσιν καὶ τὰ πετεινὰ τοῦ οὐρανοῦ κατασκηνώσεις, ὁ δὲ Υἱὸς τοῦ Ἀνθρώπου οὐκ ἔχει ποῦ τὴν κεφαλὴν κλίνῃ.”
40008021 Ἕτερος δὲ τῶν μαθητῶν εἶπεν αὐτῷ, “˚Κύριε, ἐπίτρεψόν μοι πρῶτον ἀπελθεῖν καὶ θάψαι τὸν πατέρα μου.”
40008022 Ὁ δὲ ˚Ἰησοῦς λέγει αὐτῷ, “Ἀκολούθει μοι καὶ ἄφες τοὺς νεκροὺς θάψαι τοὺς ἑαυτῶν νεκρούς.”
40008023 Καὶ ἐμβάντι αὐτῷ εἰς πλοῖον, ἠκολούθησαν αὐτῷ οἱ μαθηταὶ αὐτοῦ.
40008024 Καὶ ἰδοὺ, σεισμὸς μέγας ἐγένετο ἐν τῇ θαλάσσῃ, ὥστε τὸ πλοῖον καλύπτεσθαι ὑπὸ τῶν κυμάτων, αὐτὸς δὲ ἐκάθευδεν.
40008025 Καὶ προσελθόντες, ἤγειραν αὐτὸν λέγοντες, “˚Κύριε, σῶσον, ἀπολλύμεθα!”
40008026 Καὶ λέγει αὐτοῖς, “Τί δειλοί ἐστε, ὀλιγόπιστοι;” Τότε ἐγερθεὶς, ἐπετίμησεν τοῖς ἀνέμοις καὶ τῇ θαλάσσῃ, καὶ ἐγένετο γαλήνη μεγάλη.
40008027 Οἱ δὲ ἄνθρωποι ἐθαύμασαν λέγοντες, “Ποταπός ἐστιν οὗτος, ὅτι καὶ οἱ ἄνεμοι καὶ ἡ θάλασσα αὐτῷ ὑπακούουσιν;”
40008028 Καὶ ἐλθόντος αὐτοῦ εἰς τὸ πέραν εἰς τὴν χώραν τῶν Γαδαρηνῶν, ὑπήντησαν αὐτῷ δύο δαιμονιζόμενοι ἐκ τῶν μνημείων ἐξερχόμενοι, χαλεποὶ λίαν ὥστε μὴ ἰσχύειν τινὰ παρελθεῖν διὰ τῆς ὁδοῦ ἐκείνης.
40008029 Καὶ ἰδοὺ, ἔκραξαν λέγοντες, “Τί ἡμῖν καὶ σοί, Υἱὲ τοῦ ˚Θεοῦ; Ἦλθες ὧδε πρὸ καιροῦ βασανίσαι ἡμᾶς;”
40008030 Ἦν δὲ μακρὰν ἀπʼ αὐτῶν ἀγέλη χοίρων πολλῶν βοσκομένη.
40008031 Οἱ δὲ δαίμονες παρεκάλουν αὐτὸν λέγοντες, “Εἰ ἐκβάλλεις ἡμᾶς, ἀπόστειλον ἡμᾶς εἰς τὴν ἀγέλην τῶν χοίρων.”
40008032 Καὶ εἶπεν αὐτοῖς, “Ὑπάγετε.” Οἱ δὲ ἐξελθόντες ἀπῆλθον εἰς τοὺς χοίρους· καὶ ἰδοὺ, ὥρμησεν πᾶσα ἡ ἀγέλη κατὰ τοῦ κρημνοῦ εἰς τὴν θάλασσαν, καὶ ἀπέθανον ἐν τοῖς ὕδασιν.
40008033 Οἱ δὲ βόσκοντες ἔφυγον, καὶ ἀπελθόντες εἰς τὴν πόλιν, ἀπήγγειλαν πάντα καὶ τὰ τῶν δαιμονιζομένων.
40008034 Καὶ ἰδοὺ, πᾶσα ἡ πόλις ἐξῆλθεν εἰς ὑπάντησιν τῷ ˚Ἰησοῦ, καὶ ἰδόντες αὐτὸν, παρεκάλεσαν ὅπως μεταβῇ ἀπὸ τῶν ὁρίων αὐτῶν.
40009001 Καὶ ἐμβὰς εἰς πλοῖον, διεπέρασεν καὶ ἦλθεν εἰς τὴν ἰδίαν πόλιν.
40009002 Καὶ ἰδοὺ, προσέφερον αὐτῷ παραλυτικὸν ἐπὶ κλίνης βεβλημένον. Καὶ ἰδὼν ὁ ˚Ἰησοῦς τὴν πίστιν αὐτῶν, εἶπεν τῷ παραλυτικῷ, “Θάρσει, τέκνον, ἀφίενταί σου αἱ ἁμαρτίαι.”
40009003 Καὶ ἰδού, τινες τῶν γραμματέων εἶπον ἐν ἑαυτοῖς, “Οὗτος βλασφημεῖ!”
40009004 Καὶ ἰδὼν ὁ ˚Ἰησοῦς τὰς ἐνθυμήσεις αὐτῶν εἶπεν, “Ἵνα τί ἐνθυμεῖσθε πονηρὰ ἐν ταῖς καρδίαις ὑμῶν;
40009005 Τί γάρ ἐστιν εὐκοπώτερον εἰπεῖν, 'Ἀφίενταί σου αἱ ἁμαρτίαι', ἢ εἰπεῖν, 'Ἔγειρε καὶ περιπάτει';
40009006 Ἵνα δὲ εἰδῆτε ὅτι ἐξουσίαν ἔχει ὁ Υἱὸς τοῦ Ἀνθρώπου ἐπὶ τῆς γῆς ἀφιέναι ἁμαρτίας” ( τότε λέγει τῷ παραλυτικῷ ), “Ἐγερθεὶς, ἆρόν σου τὴν κλίνην καὶ ὕπαγε εἰς τὸν οἶκόν σου.”
40009007 Καὶ ἐγερθεὶς, ἀπῆλθεν εἰς τὸν οἶκον αὐτοῦ.
40009008 Ἰδόντες δὲ, οἱ ὄχλοι ἐφοβήθησαν καὶ ἐδόξασαν τὸν ˚Θεὸν, τὸν δόντα ἐξουσίαν τοιαύτην τοῖς ἀνθρώποις.
40009009 Καὶ παράγων ὁ ˚Ἰησοῦς ἐκεῖθεν, εἶδεν ἄνθρωπον καθήμενον ἐπὶ τὸ τελώνιον Μαθθαῖον λεγόμενον, καὶ λέγει αὐτῷ. “Ἀκολούθει μοι.” Καὶ ἀναστὰς, ἠκολούθησεν αὐτῷ.
40009010 Καὶ ἐγένετο αὐτοῦ ἀνακειμένου ἐν τῇ οἰκίᾳ, καὶ ἰδοὺ, πολλοὶ τελῶναι καὶ ἁμαρτωλοὶ ἐλθόντες συνανέκειντο τῷ ˚Ἰησοῦ καὶ τοῖς μαθηταῖς αὐτοῦ.
40009011 Καὶ ἰδόντες, οἱ Φαρισαῖοι ἔλεγον τοῖς μαθηταῖς αὐτοῦ, “Διὰ τί μετὰ τῶν τελωνῶν καὶ ἁμαρτωλῶν ἐσθίει ὁ διδάσκαλος ὑμῶν;”
40009012 Ὁ δὲ ἀκούσας εἶπεν, “Οὐ χρείαν ἔχουσιν οἱ ἰσχύοντες ἰατροῦ, ἀλλὰ οἱ κακῶς ἔχοντες.
40009013 Πορευθέντες δὲ, μάθετε τί ἐστιν: ‘Ἔλεος θέλω καὶ οὐ θυσίαν’, οὐ γὰρ ἦλθον καλέσαι δικαίους, ἀλλὰ ἁμαρτωλούς.”
40009014 Τότε προσέρχονται αὐτῷ οἱ μαθηταὶ Ἰωάννου λέγοντες, “Διὰ τί ἡμεῖς καὶ οἱ Φαρισαῖοι νηστεύομεν, οἱ δὲ μαθηταί σου οὐ νηστεύουσιν;”
40009015 Καὶ εἶπεν αὐτοῖς ὁ ˚Ἰησοῦς, “Μὴ δύνανται οἱ υἱοὶ τοῦ νυμφῶνος πενθεῖν, ἐφʼ ὅσον μετʼ αὐτῶν ἐστιν ὁ νυμφίος; Ἐλεύσονται δὲ ἡμέραι ὅταν ἀπαρθῇ ἀπʼ αὐτῶν ὁ νυμφίος, καὶ τότε νηστεύσουσιν.
40009016 Οὐδεὶς δὲ ἐπιβάλλει ἐπίβλημα ῥάκους ἀγνάφου ἐπὶ ἱματίῳ παλαιῷ· αἴρει γὰρ τὸ πλήρωμα αὐτοῦ ἀπὸ τοῦ ἱματίου, καὶ χεῖρον σχίσμα γίνεται.
40009017 Οὐδὲ βάλλουσιν οἶνον νέον εἰς ἀσκοὺς παλαιούς· εἰ δὲ μή γε, ῥήγνυνται οἱ ἀσκοί, καὶ ὁ οἶνος ἐκχεῖται καὶ οἱ ἀσκοὶ ἀπόλλυνται. Ἀλλὰ βάλλουσιν οἶνον νέον εἰς ἀσκοὺς καινούς, καὶ ἀμφότεροι συντηροῦνται.”
40009018 Ταῦτα αὐτοῦ λαλοῦντος αὐτοῖς, ἰδοὺ, ἄρχων εἷς προσελθὼν, προσεκύνει αὐτῷ λέγων, ὅτι “Ἡ θυγάτηρ μου ἄρτι ἐτελεύτησεν· ἀλλὰ ἐλθὼν ἐπίθες τὴν χεῖρά σου ἐπʼ αὐτήν καὶ ζήσεται.”
40009019 Καὶ ἐγερθεὶς, ὁ ˚Ἰησοῦς ἠκολούθησεν αὐτῷ καὶ οἱ μαθηταὶ αὐτοῦ.
40009020 Καὶ ἰδοὺ, γυνὴ αἱμορροοῦσα δώδεκα ἔτη προσελθοῦσα ὄπισθεν, ἥψατο τοῦ κρασπέδου τοῦ ἱματίου αὐτοῦ.
40009021 Ἔλεγεν γὰρ ἐν ἑαυτῇ, “Ἐὰν μόνον ἅψωμαι τοῦ ἱματίου αὐτοῦ, σωθήσομαι.”
40009022 Ὁ δὲ ˚Ἰησοῦς στραφεὶς καὶ ἰδὼν αὐτὴν εἶπεν, “Θάρσει, θύγατερ· ἡ πίστις σου σέσωκέν σε.” Καὶ ἐσώθη ἡ γυνὴ ἀπὸ τῆς ὥρας ἐκείνης.
40009023 Καὶ ἐλθὼν ὁ ˚Ἰησοῦς εἰς τὴν οἰκίαν τοῦ ἄρχοντος, καὶ ἰδὼν τοὺς αὐλητὰς καὶ τὸν ὄχλον θορυβούμενον
40009024 ἔλεγεν, “Ἀναχωρεῖτε, οὐ γὰρ ἀπέθανεν τὸ κοράσιον, ἀλλὰ καθεύδει.” Καὶ κατεγέλων αὐτοῦ.
40009025 Ὅτε δὲ ἐξεβλήθη ὁ ὄχλος, εἰσελθὼν ἐκράτησεν τῆς χειρὸς αὐτῆς, καὶ ἠγέρθη τὸ κοράσιον.
40009026 Καὶ ἐξῆλθεν ἡ φήμη αὕτη εἰς ὅλην τὴν γῆν ἐκείνην.
40009027 Καὶ παράγοντι ἐκεῖθεν τῷ ˚Ἰησοῦ, ἠκολούθησαν αὐτῷ δύο τυφλοὶ κράζοντες καὶ λέγοντες, “Ἐλέησον ἡμᾶς, υἱὸς Δαυίδ.”
40009028 Ἐλθόντι δὲ εἰς τὴν οἰκίαν, προσῆλθον αὐτῷ οἱ τυφλοί, καὶ λέγει αὐτοῖς ὁ ˚Ἰησοῦς, “Πιστεύετε ὅτι δύναμαι τοῦτο ποιῆσαι;” Λέγουσιν αὐτῷ, “Ναί, ˚Κύριε.”
40009029 Τότε ἥψατο τῶν ὀφθαλμῶν αὐτῶν λέγων, “Κατὰ τὴν πίστιν ὑμῶν γενηθήτω ὑμῖν.”
40009030 Καὶ ἠνεῴχθησαν αὐτῶν οἱ ὀφθαλμοί. Καὶ ἐνεβριμήθη αὐτοῖς ὁ ˚Ἰησοῦς λέγων, “Ὁρᾶτε μηδεὶς γινωσκέτω.”
40009031 Οἱ δὲ ἐξελθόντες, διεφήμισαν αὐτὸν ἐν ὅλῃ τῇ γῇ ἐκείνῃ.
40009032 Αὐτῶν δὲ ἐξερχομένων, ἰδοὺ, προσήνεγκαν αὐτῷ κωφὸν δαιμονιζόμενον.
40009033 Καὶ ἐκβληθέντος τοῦ δαιμονίου, ἐλάλησεν ὁ κωφός. Καὶ ἐθαύμασαν οἱ ὄχλοι λέγοντες, “Οὐδέποτε ἐφάνη οὕτως ἐν τῷ Ἰσραήλ.”
40009034 Οἱ δὲ Φαρισαῖοι ἔλεγον, “Ἐν τῷ ἄρχοντι τῶν δαιμονίων ἐκβάλλει τὰ δαιμόνια.”
40009035 Καὶ περιῆγεν ὁ ˚Ἰησοῦς τὰς πόλεις πάσας καὶ τὰς κώμας, διδάσκων ἐν ταῖς συναγωγαῖς αὐτῶν, καὶ κηρύσσων τὸ εὐαγγέλιον τῆς βασιλείας, καὶ θεραπεύων πᾶσαν νόσον καὶ πᾶσαν μαλακίαν.
40009036 Ἰδὼν δὲ τοὺς ὄχλους, ἐσπλαγχνίσθη περὶ αὐτῶν, ὅτι ἦσαν ἐσκυλμένοι καὶ ἐρριμμένοι, ὡσεὶ πρόβατα μὴ ἔχοντα ποιμένα.
40009037 Τότε λέγει τοῖς μαθηταῖς αὐτοῦ, “Ὁ μὲν θερισμὸς πολύς, οἱ δὲ ἐργάται ὀλίγοι·
40009038 δεήθητε οὖν τοῦ ˚Κυρίου τοῦ θερισμοῦ, ὅπως ἐκβάλῃ ἐργάτας εἰς τὸν θερισμὸν αὐτοῦ.”
40010001 Καὶ προσκαλεσάμενος τοὺς δώδεκα μαθητὰς αὐτοῦ, ἔδωκεν αὐτοῖς ἐξουσίαν πνευμάτων ἀκαθάρτων, ὥστε ἐκβάλλειν αὐτὰ καὶ θεραπεύειν πᾶσαν νόσον καὶ πᾶσαν μαλακίαν.
40010002 Τῶν δὲ δώδεκα ἀποστόλων τὰ ὀνόματά ἐστιν ταῦτα: πρῶτος Σίμων ὁ λεγόμενος Πέτρος, καὶ Ἀνδρέας ὁ ἀδελφὸς αὐτοῦ, καὶ Ἰάκωβος ὁ τοῦ Ζεβεδαίου, καὶ Ἰωάννης ὁ ἀδελφὸς αὐτοῦ,
40010003 Φίλιππος καὶ Βαρθολομαῖος, Θωμᾶς καὶ Μαθθαῖος ὁ τελώνης, Ἰάκωβος ὁ τοῦ Ἁλφαίου καὶ Θαδδαῖος,
40010004 Σίμων ὁ Καναναῖος καὶ Ἰούδας ὁ Ἰσκαριώτης, ὁ καὶ παραδοὺς αὐτόν.
40010005 Τούτους τοὺς δώδεκα ἀπέστειλεν ὁ ˚Ἰησοῦς παραγγείλας αὐτοῖς λέγων, “Εἰς ὁδὸν ἐθνῶν μὴ ἀπέλθητε, καὶ εἰς πόλιν Σαμαριτῶν μὴ εἰσέλθητε·
40010006 πορεύεσθε δὲ μᾶλλον πρὸς τὰ πρόβατα τὰ ἀπολωλότα οἴκου Ἰσραήλ.
40010007 Πορευόμενοι δὲ κηρύσσετε λέγοντες, ὅτι ‘Ἤγγικεν ἡ Βασιλεία τῶν Οὐρανῶν.’
40010008 Ἀσθενοῦντας θεραπεύετε, νεκροὺς ἐγείρετε, λεπροὺς καθαρίζετε, δαιμόνια ἐκβάλλετε· δωρεὰν ἐλάβετε, δωρεὰν δότε.
40010009 Μὴ κτήσησθε χρυσὸν, μηδὲ ἄργυρον, μηδὲ χαλκὸν εἰς τὰς ζώνας ὑμῶν,
40010010 μὴ πήραν εἰς ὁδὸν, μηδὲ δύο χιτῶνας, μηδὲ ὑποδήματα, μηδὲ ῥάβδον· ἄξιος γὰρ ὁ ἐργάτης τῆς τροφῆς αὐτοῦ.
40010011 Εἰς ἣν δʼ ἂν πόλιν ἢ κώμην εἰσέλθητε, ἐξετάσατε τίς ἐν αὐτῇ ἄξιός ἐστιν, κἀκεῖ μείνατε ἕως ἂν ἐξέλθητε.
40010012 Εἰσερχόμενοι δὲ εἰς τὴν οἰκίαν, ἀσπάσασθε αὐτήν.
40010013 Καὶ ἐὰν μὲν ᾖ ἡ οἰκία ἀξία, ἐλθάτω ἡ εἰρήνη ὑμῶν ἐπʼ αὐτήν· ἐὰν δὲ μὴ ᾖ ἀξία, ἡ εἰρήνη ὑμῶν πρὸς ὑμᾶς ἐπιστραφήτω.
40010014 Καὶ ὃς ἂν μὴ δέξηται ὑμᾶς, μηδὲ ἀκούσῃ τοὺς λόγους ὑμῶν, ἐξερχόμενοι ἔξω τῆς οἰκίας ἢ τῆς πόλεως ἐκείνης, ἐκτινάξατε τὸν κονιορτὸν τῶν ποδῶν ὑμῶν.
40010015 Ἀμὴν, λέγω ὑμῖν, ἀνεκτότερον ἔσται γῇ Σοδόμων καὶ Γομόρρων ἐν ἡμέρᾳ κρίσεως, ἢ τῇ πόλει ἐκείνῃ.
40010016 Ἰδοὺ, ἐγὼ ἀποστέλλω ὑμᾶς ὡς πρόβατα ἐν μέσῳ λύκων· γίνεσθε οὖν φρόνιμοι ὡς οἱ ὄφεις καὶ ἀκέραιοι ὡς αἱ περιστεραί.
40010017 Προσέχετε δὲ ἀπὸ τῶν ἀνθρώπων· παραδώσουσιν γὰρ ὑμᾶς εἰς συνέδρια, καὶ ἐν ταῖς συναγωγαῖς αὐτῶν μαστιγώσουσιν ὑμᾶς·
40010018 καὶ ἐπὶ ἡγεμόνας δὲ καὶ βασιλεῖς ἀχθήσεσθε ἕνεκεν ἐμοῦ, εἰς μαρτύριον αὐτοῖς καὶ τοῖς ἔθνεσιν.
40010019 Ὅταν δὲ παραδῶσιν ὑμᾶς, μὴ μεριμνήσητε πῶς ἢ τί λαλήσητε· δοθήσεται γὰρ ὑμῖν ἐν ἐκείνῃ τῇ ὥρᾳ τί λαλήσητε.
40010020 Οὐ γὰρ ὑμεῖς ἐστε οἱ λαλοῦντες, ἀλλὰ τὸ ˚Πνεῦμα τοῦ Πατρὸς ὑμῶν τὸ λαλοῦν ἐν ὑμῖν.
40010021 Παραδώσει δὲ ἀδελφὸς ἀδελφὸν εἰς θάνατον, καὶ πατὴρ τέκνον, καὶ ἐπαναστήσονται τέκνα ἐπὶ γονεῖς, καὶ θανατώσουσιν αὐτούς.
40010022 Καὶ ἔσεσθε μισούμενοι ὑπὸ πάντων διὰ τὸ ὄνομά μου· ὁ δὲ ὑπομείνας εἰς τέλος, οὗτος σωθήσεται.
40010023 Ὅταν δὲ διώκωσιν ὑμᾶς ἐν τῇ πόλει ταύτῃ, φεύγετε εἰς τὴν ἑτέραν· ἀμὴν, γὰρ λέγω ὑμῖν, οὐ μὴ τελέσητε τὰς πόλεις τοῦ Ἰσραὴλ, ἕως ἔλθῃ ὁ Υἱὸς τοῦ Ἀνθρώπου.
40010024 Οὐκ ἔστιν μαθητὴς ὑπὲρ τὸν διδάσκαλον, οὐδὲ δοῦλος ὑπὲρ τὸν κύριον αὐτοῦ.
40010025 Ἀρκετὸν τῷ μαθητῇ ἵνα γένηται ὡς ὁ διδάσκαλος αὐτοῦ, καὶ ὁ δοῦλος ὡς ὁ κύριος αὐτοῦ. Εἰ τὸν οἰκοδεσπότην Βεελζεβοὺλ ἐπεκάλεσαν, πόσῳ μᾶλλον τοὺς οἰκιακοὺς αὐτοῦ;
40010026 Μὴ οὖν φοβηθῆτε αὐτούς· οὐδὲν γάρ ἐστιν κεκαλυμμένον ὃ οὐκ ἀποκαλυφθήσεται, καὶ κρυπτὸν ὃ οὐ γνωσθήσεται.
40010027 Ὃ λέγω ὑμῖν ἐν τῇ σκοτίᾳ, εἴπατε ἐν τῷ φωτί· καὶ ὃ εἰς τὸ οὖς ἀκούετε, κηρύξατε ἐπὶ τῶν δωμάτων.
40010028 Καὶ μὴ φοβηθῆτε ἀπὸ τῶν ἀποκτεννόντων τὸ σῶμα, τὴν δὲ ψυχὴν μὴ δυναμένων ἀποκτεῖναι· φοβεῖσθε δὲ μᾶλλον τὸν δυνάμενον καὶ ψυχὴν καὶ σῶμα ἀπολέσαι ἐν Γεέννῃ.
40010029 Οὐχὶ δύο στρουθία ἀσσαρίου πωλεῖται; Καὶ ἓν ἐξ αὐτῶν οὐ πεσεῖται ἐπὶ τὴν γῆν, ἄνευ τοῦ Πατρὸς ὑμῶν.
40010030 Ὑμῶν δὲ καὶ αἱ τρίχες τῆς κεφαλῆς πᾶσαι ἠριθμημέναι εἰσίν.
40010031 Μὴ οὖν φοβεῖσθε· πολλῶν στρουθίων διαφέρετε ὑμεῖς.
40010032 Πᾶς οὖν ὅστις ὁμολογήσει ἐν ἐμοὶ ἔμπροσθεν τῶν ἀνθρώπων, ὁμολογήσω κἀγὼ ἐν αὐτῷ ἔμπροσθεν τοῦ Πατρός μου τοῦ ἐν οὐρανοῖς.
40010033 Ὅστις δʼ ἀρνήσηταί με ἔμπροσθεν τῶν ἀνθρώπων, ἀρνήσομαι κἀγὼ αὐτὸν ἔμπροσθεν τοῦ Πατρός μου τοῦ ἐν οὐρανοῖς.
40010034 Μὴ νομίσητε ὅτι ἦλθον βαλεῖν εἰρήνην ἐπὶ τὴν γῆν· οὐκ ἦλθον βαλεῖν εἰρήνην, ἀλλὰ μάχαιραν.
40010035 Ἦλθον γὰρ ‘διχάσαι ἄνθρωπον κατὰ τοῦ πατρὸς αὐτοῦ, καὶ θυγατέρα κατὰ τῆς μητρὸς αὐτῆς, καὶ νύμφην κατὰ τῆς πενθερᾶς αὐτῆς
40010036 καὶ ἐχθροὶ τοῦ ἀνθρώπου οἱ οἰκιακοὶ αὐτοῦ’.
40010037 Ὁ φιλῶν πατέρα ἢ μητέρα ὑπὲρ ἐμὲ, οὐκ ἔστιν μου ἄξιος· καὶ ὁ φιλῶν υἱὸν ἢ θυγατέρα ὑπὲρ ἐμὲ, οὐκ ἔστιν μου ἄξιος.
40010038 Καὶ ὃς οὐ λαμβάνει τὸν σταυρὸν αὐτοῦ καὶ ἀκολουθεῖ ὀπίσω μου, οὐκ ἔστιν μου ἄξιος.
40010039 Ὁ εὑρὼν τὴν ψυχὴν αὐτοῦ ἀπολέσει αὐτήν, καὶ ὁ ἀπολέσας τὴν ψυχὴν αὐτοῦ ἕνεκεν ἐμοῦ εὑρήσει αὐτήν.
40010040 Ὁ δεχόμενος ὑμᾶς ἐμὲ δέχεται, καὶ ὁ ἐμὲ δεχόμενος δέχεται τὸν ἀποστείλαντά με.
40010041 Ὁ δεχόμενος προφήτην εἰς ὄνομα προφήτου, μισθὸν προφήτου λήμψεται, καὶ ὁ δεχόμενος δίκαιον εἰς ὄνομα δικαίου, μισθὸν δικαίου λήμψεται.
40010042 Καὶ ὃς ἐὰν ποτίσῃ ἕνα τῶν μικρῶν τούτων ποτήριον ψυχροῦ, μόνον εἰς ὄνομα μαθητοῦ, ἀμὴν, λέγω ὑμῖν, οὐ μὴ ἀπολέσῃ τὸν μισθὸν αὐτοῦ.”
40011001 Καὶ ἐγένετο ὅτε ἐτέλεσεν ὁ ˚Ἰησοῦς διατάσσων τοῖς δώδεκα μαθηταῖς αὐτοῦ, μετέβη ἐκεῖθεν τοῦ διδάσκειν καὶ κηρύσσειν ἐν ταῖς πόλεσιν αὐτῶν.
40011002 Ὁ δὲ Ἰωάννης, ἀκούσας ἐν τῷ δεσμωτηρίῳ τὰ ἔργα τοῦ ˚Χριστοῦ, πέμψας διὰ τῶν μαθητῶν αὐτοῦ,
40011003 εἶπεν αὐτῷ, “Σὺ εἶ ὁ ἐρχόμενος, ἢ ἕτερον προσδοκῶμεν;”
40011004 Καὶ ἀποκριθεὶς ὁ ˚Ἰησοῦς εἶπεν αὐτοῖς, “Πορευθέντες ἀπαγγείλατε Ἰωάννῃ ἃ ἀκούετε καὶ βλέπετε:
40011005 ‘τυφλοὶ ἀναβλέπουσιν’, καὶ χωλοὶ περιπατοῦσιν, λεπροὶ καθαρίζονται, καὶ κωφοὶ ἀκούουσιν, καὶ νεκροὶ ἐγείρονται, καὶ ‘πτωχοὶ εὐαγγελίζονται’.
40011006 Καὶ μακάριός ἐστιν ὃς ἐὰν μὴ σκανδαλισθῇ ἐν ἐμοί.”
40011007 Τούτων δὲ πορευομένων, ἤρξατο ὁ ˚Ἰησοῦς λέγειν τοῖς ὄχλοις περὶ Ἰωάννου, “Τί ἐξήλθατε εἰς τὴν ἔρημον θεάσασθαι; Κάλαμον ὑπὸ ἀνέμου σαλευόμενον;
40011008 Ἀλλὰ τί ἐξήλθατε ἰδεῖν; Ἄνθρωπον ἐν μαλακοῖς ἠμφιεσμένον; Ἰδοὺ, οἱ τὰ μαλακὰ φοροῦντες ἐν τοῖς οἴκοις τῶν βασιλέων
40011009 ἀλλὰ τί ἐξήλθατε; Προφήτην ἰδεῖν; Ναί, λέγω ὑμῖν, καὶ περισσότερον προφήτου.
40011010 Οὗτός ἐστιν περὶ οὗ γέγραπται, ‘Ἰδοὺ, ἐγὼ ἀποστέλλω τὸν ἄγγελόν μου πρὸ προσώπου σου, ὃς κατασκευάσει τὴν ὁδόν σου ἔμπροσθέν σου.’
40011011 Ἀμὴν, λέγω ὑμῖν, οὐκ ἐγήγερται ἐν γεννητοῖς γυναικῶν μείζων Ἰωάννου τοῦ Βαπτιστοῦ. Ὁ δὲ μικρότερος ἐν τῇ Βασιλείᾳ τῶν Οὐρανῶν, μείζων αὐτοῦ ἐστιν.
40011012 Ἀπὸ δὲ τῶν ἡμερῶν Ἰωάννου τοῦ Βαπτιστοῦ ἕως ἄρτι, ἡ Βασιλεία τῶν Οὐρανῶν βιάζεται, καὶ βιασταὶ ἁρπάζουσιν αὐτήν.
40011013 Πάντες γὰρ οἱ προφῆται καὶ ὁ νόμος ἕως Ἰωάννου ἐπροφήτευσαν.
40011014 Καὶ εἰ θέλετε δέξασθαι, αὐτός ἐστιν Ἠλίας, ὁ μέλλων ἔρχεσθαι.
40011015 Ὁ ἔχων ὦτα ἀκούειν, ἀκουέτω.
40011016 Τίνι δὲ ὁμοιώσω τὴν γενεὰν ταύτην; Ὁμοία ἐστὶν παιδίοις καθημένοις ἐν ταῖς ἀγοραῖς, ἃ προσφωνοῦντα τοῖς ἑτέροις
40011017 λέγουσιν, ‘Ηὐλήσαμεν ὑμῖν καὶ οὐκ ὠρχήσασθε· ἐθρηνήσαμεν καὶ οὐκ ἐκόψασθε.’
40011018 Ἦλθεν γὰρ Ἰωάννης μήτε ἐσθίων μήτε πίνων, καὶ λέγουσιν, ‘Δαιμόνιον ἔχει.’
40011019 Ἦλθεν ὁ Υἱὸς τοῦ Ἀνθρώπου ἐσθίων καὶ πίνων, καὶ λέγουσιν, ‘Ἰδοὺ, ἄνθρωπος, φάγος καὶ οἰνοπότης, τελωνῶν φίλος καὶ ἁμαρτωλῶν!’ Καὶ ἐδικαιώθη ἡ σοφία ἀπὸ τῶν τέκνων αὐτῆς.”
40011020 Τότε ἤρξατο ὀνειδίζειν τὰς πόλεις ἐν αἷς ἐγένοντο αἱ πλεῖσται δυνάμεις αὐτοῦ, ὅτι οὐ μετενόησαν.
40011021 Οὐαί σοι, Χοραζίν! Οὐαί σοι, Βηθσαϊδάν! Ὅτι εἰ ἐν Τύρῳ καὶ Σιδῶνι ἐγένοντο αἱ δυνάμεις αἱ γενόμεναι ἐν ὑμῖν, πάλαι ἂν ἐν σάκκῳ καὶ σποδῷ μετενόησαν.
40011022 Πλὴν λέγω ὑμῖν, Τύρῳ καὶ Σιδῶνι ἀνεκτότερον ἔσται ἐν ἡμέρᾳ κρίσεως ἢ ὑμῖν.
40011023 Καὶ σύ, Καφαρναούμ, μὴ ἕως οὐρανοῦ ὑψωθήσῃ; ἕως ᾅδου καταβήσῃ. Ὅτι εἰ ἐν Σοδόμοις ἐγενήθησαν αἱ δυνάμεις αἱ γενόμεναι ἐν σοί, ἔμεινεν ἂν μέχρι τῆς σήμερον.
40011024 Πλὴν λέγω ὑμῖν ὅτι γῇ Σοδόμων ἀνεκτότερον ἔσται ἐν ἡμέρᾳ κρίσεως ἢ σοί.
40011025 Ἐν ἐκείνῳ τῷ καιρῷ ἀποκριθεὶς ὁ ˚Ἰησοῦς εἶπεν, “Ἐξομολογοῦμαί σοι, Πάτερ, ˚Κύριε τοῦ οὐρανοῦ καὶ τῆς γῆς, ὅτι ἔκρυψας ταῦτα ἀπὸ σοφῶν καὶ συνετῶν, καὶ ἀπεκάλυψας αὐτὰ νηπίοις.
40011026 Ναί, ὁ Πατήρ, ὅτι οὕτως εὐδοκία ἐγένετο ἔμπροσθέν σου.
40011027 Πάντα μοι παρεδόθη ὑπὸ τοῦ Πατρός μου, καὶ οὐδεὶς ἐπιγινώσκει τὸν Υἱὸν, εἰ μὴ ὁ Πατήρ, οὐδὲ τὸν Πατέρα τις ἐπιγινώσκει, εἰ μὴ ὁ Υἱὸς, καὶ ᾧ ἐὰν βούληται ὁ Υἱὸς ἀποκαλύψαι.
40011028 Δεῦτε πρός με, πάντες οἱ κοπιῶντες καὶ πεφορτισμένοι, κἀγὼ ἀναπαύσω ὑμᾶς.
40011029 Ἄρατε τὸν ζυγόν μου ἐφʼ ὑμᾶς, καὶ μάθετε ἀπʼ ἐμοῦ, ὅτι πραῢς εἰμι καὶ ταπεινὸς τῇ καρδίᾳ, ‘καὶ εὑρήσετε ἀνάπαυσιν ταῖς ψυχαῖς ὑμῶν’.
40011030 Ὁ γὰρ ζυγός μου χρηστὸς καὶ τὸ φορτίον μου ἐλαφρόν ἐστιν.”
40012001 Ἐν ἐκείνῳ τῷ καιρῷ, ἐπορεύθη ὁ ˚Ἰησοῦς τοῖς Σάββασιν διὰ τῶν σπορίμων· οἱ δὲ μαθηταὶ αὐτοῦ ἐπείνασαν, καὶ ἤρξαντο τίλλειν στάχυας καὶ ἐσθίειν.
40012002 Οἱ δὲ Φαρισαῖοι ἰδόντες, εἶπαν αὐτῷ, “Ἰδοὺ, οἱ μαθηταί σου ποιοῦσιν ὃ οὐκ ἔξεστιν ποιεῖν ἐν Σαββάτῳ.”
40012003 Ὁ δὲ εἶπεν αὐτοῖς, “Οὐκ ἀνέγνωτε τί ἐποίησεν Δαυὶδ, ὅτε ἐπείνασεν καὶ οἱ μετʼ αὐτοῦ;
40012004 Πῶς εἰσῆλθεν εἰς τὸν οἶκον τοῦ ˚Θεοῦ, καὶ τοὺς ἄρτους τῆς Προθέσεως ἔφαγον, ὃ οὐκ ἐξὸν ἦν αὐτῷ φαγεῖν, οὐδὲ τοῖς μετʼ αὐτοῦ, εἰ μὴ τοῖς ἱερεῦσιν μόνοις;
40012005 Ἢ οὐκ ἀνέγνωτε ἐν τῷ νόμῳ ὅτι τοῖς Σάββασιν οἱ ἱερεῖς ἐν τῷ ἱερῷ τὸ Σάββατον βεβηλοῦσιν, καὶ ἀναίτιοί εἰσιν;
40012006 Λέγω δὲ ὑμῖν ὅτι τοῦ ἱεροῦ μεῖζόν ἐστιν ὧδε.
40012007 Εἰ δὲ ἐγνώκειτε τί ἐστιν: ‘Ἔλεος θέλω καὶ οὐ θυσίαν’, οὐκ ἂν κατεδικάσατε τοὺς ἀναιτίους.
40012008 ˚Κύριος γάρ ἐστιν τοῦ Σαββάτου ὁ Υἱὸς τοῦ Ἀνθρώπου.”
40012009 Καὶ μεταβὰς ἐκεῖθεν, ἦλθεν εἰς τὴν συναγωγὴν αὐτῶν.
40012010 Καὶ ἰδοὺ, ἄνθρωπος χεῖρα ἔχων ξηράν. Καὶ ἐπηρώτησαν αὐτὸν λέγοντες, “Εἰ ἔξεστιν τοῖς Σάββασιν θεραπεύειν;” Ἵνα κατηγορήσωσιν αὐτοῦ.
40012011 Ὁ δὲ εἶπεν αὐτοῖς, “Τίς ἔσται ἐξ ὑμῶν ἄνθρωπος, ὃς ἕξει πρόβατον ἕν, καὶ ἐὰν ἐμπέσῃ τοῦτο τοῖς Σάββασιν εἰς βόθυνον, οὐχὶ κρατήσει αὐτὸ καὶ ἐγερεῖ;
40012012 Πόσῳ οὖν διαφέρει ἄνθρωπος προβάτου; Ὥστε ἔξεστιν τοῖς Σάββασιν καλῶς ποιεῖν.”
40012013 Τότε λέγει τῷ ἀνθρώπῳ, “Ἔκτεινόν σου τὴν χεῖρα.” Καὶ ἐξέτεινεν, καὶ ἀπεκατεστάθη, ὑγιὴς ὡς ἡ ἄλλη.
40012014 Ἐξελθόντες δὲ, οἱ Φαρισαῖοι συμβούλιον ἔλαβον κατʼ αὐτοῦ, ὅπως αὐτὸν ἀπολέσωσιν.
40012015 Ὁ δὲ ˚Ἰησοῦς γνοὺς, ἀνεχώρησεν ἐκεῖθεν. Καὶ ἠκολούθησαν αὐτῷ πολλοί, καὶ ἐθεράπευσεν αὐτοὺς πάντας,
40012016 καὶ ἐπετίμησεν αὐτοῖς ἵνα μὴ φανερὸν αὐτὸν ποιήσωσιν.
40012017 Ἵνα πληρωθῇ τὸ ῥηθὲν διὰ Ἠσαΐου τοῦ προφήτου λέγοντος,
40012018 “Ἰδοὺ, ὁ παῖς μου ὃν ᾑρέτισα, ὁ ἀγαπητός μου ὃν εὐδόκησεν ἡ ψυχή μου. Θήσω τὸ ˚Πνεῦμά μου ἐπʼ αὐτόν, καὶ κρίσιν τοῖς ἔθνεσιν ἀπαγγελεῖ.
40012019 Οὐκ ἐρίσει, οὐδὲ κραυγάσει, οὐδὲ ἀκούσει τις ἐν ταῖς πλατείαις τὴν φωνὴν αὐτοῦ.
40012020 Κάλαμον συντετριμμένον οὐ κατεάξει, καὶ λίνον τυφόμενον οὐ σβέσει, ἕως ἂν ἐκβάλῃ εἰς νῖκος τὴν κρίσιν.
40012021 Καὶ τῷ ὀνόματι αὐτοῦ ἔθνη ἐλπιοῦσιν.”
40012022 Τότε προσηνέχθη αὐτῷ δαιμονιζόμενος, τυφλὸς καὶ κωφός, καὶ ἐθεράπευσεν αὐτόν, ὥστε τὸν κωφὸν λαλεῖν καὶ βλέπειν.
40012023 Καὶ ἐξίσταντο πάντες οἱ ὄχλοι καὶ ἔλεγον, “Μήτι οὗτός ἐστιν ὁ υἱὸς Δαυίδ;”
40012024 Οἱ δὲ Φαρισαῖοι ἀκούσαντες εἶπον, “Οὗτος οὐκ ἐκβάλλει τὰ δαιμόνια, εἰ μὴ ἐν τῷ Βεελζεβοὺλ, ἄρχοντι τῶν δαιμονίων.”
40012025 Εἰδὼς δὲ τὰς ἐνθυμήσεις αὐτῶν, εἶπεν αὐτοῖς, “Πᾶσα βασιλεία μερισθεῖσα καθʼ ἑαυτῆς ἐρημοῦται, καὶ πᾶσα πόλις ἢ οἰκία μερισθεῖσα καθʼ ἑαυτῆς οὐ σταθήσεται.
40012026 Καὶ εἰ ὁ Σατανᾶς τὸν Σατανᾶν ἐκβάλλει, ἐφʼ ἑαυτὸν ἐμερίσθη. Πῶς οὖν σταθήσεται ἡ βασιλεία αὐτοῦ;
40012027 Καὶ εἰ ἐγὼ ἐν Βεελζεβοὺλ ἐκβάλλω τὰ δαιμόνια, οἱ υἱοὶ ὑμῶν ἐν τίνι ἐκβάλλουσιν; Διὰ τοῦτο, αὐτοὶ κριταὶ ἔσονται ὑμῶν.
40012028 Εἰ δὲ ἐν ˚Πνεύματι ˚Θεοῦ ἐγὼ ἐκβάλλω τὰ δαιμόνια, ἄρα ἔφθασεν ἐφʼ ὑμᾶς ἡ Βασιλεία τοῦ ˚Θεοῦ.
40012029 Ἢ πῶς δύναταί τις εἰσελθεῖν εἰς τὴν οἰκίαν τοῦ ἰσχυροῦ καὶ τὰ σκεύη αὐτοῦ ἁρπάσαι, ἐὰν μὴ πρῶτον δήσῃ τὸν ἰσχυρόν; Καὶ τότε τὴν οἰκίαν αὐτοῦ διαρπάσει.
40012030 Ὁ μὴ ὢν μετʼ ἐμοῦ κατʼ ἐμοῦ ἐστιν, καὶ ὁ μὴ συνάγων μετʼ ἐμοῦ σκορπίζει.
40012031 Διὰ τοῦτο λέγω ὑμῖν, πᾶσα ἁμαρτία καὶ βλασφημία ἀφεθήσεται τοῖς ἀνθρώποις, ἡ δὲ τοῦ ˚Πνεύματος βλασφημία οὐκ ἀφεθήσεται.
40012032 Καὶ ὃς ἐὰν εἴπῃ λόγον κατὰ τοῦ Υἱοῦ τοῦ Ἀνθρώπου, ἀφεθήσεται αὐτῷ· ὃς δʼ ἂν εἴπῃ κατὰ τοῦ ˚Πνεύματος τοῦ Ἁγίου, οὐκ ἀφεθήσεται αὐτῷ, οὔτε ἐν τούτῳ τῷ αἰῶνι οὔτε ἐν τῷ μέλλοντι.
40012033 Ἢ ποιήσατε τὸ δένδρον καλὸν καὶ τὸν καρπὸν αὐτοῦ καλόν, ἢ ποιήσατε τὸ δένδρον σαπρὸν καὶ τὸν καρπὸν αὐτοῦ σαπρόν· ἐκ γὰρ τοῦ καρποῦ, τὸ δένδρον γινώσκεται.
40012034 Γεννήματα ἐχιδνῶν, πῶς δύνασθε ἀγαθὰ λαλεῖν, πονηροὶ ὄντες; Ἐκ γὰρ τοῦ περισσεύματος τῆς καρδίας, τὸ στόμα λαλεῖ.
40012035 Ὁ ἀγαθὸς ἄνθρωπος ἐκ τοῦ ἀγαθοῦ θησαυροῦ ἐκβάλλει ἀγαθά, καὶ ὁ πονηρὸς ἄνθρωπος ἐκ τοῦ πονηροῦ θησαυροῦ ἐκβάλλει πονηρά.
40012036 Λέγω δὲ ὑμῖν ὅτι πᾶν ῥῆμα ἀργὸν ὃ λαλήσουσιν οἱ ἄνθρωποι, ἀποδώσουσιν περὶ αὐτοῦ λόγον ἐν ἡμέρᾳ κρίσεως.
40012037 Ἐκ γὰρ τῶν λόγων σου δικαιωθήσῃ, καὶ ἐκ τῶν λόγων σου καταδικασθήσῃ.”
40012038 Τότε ἀπεκρίθησαν αὐτῷ τινες τῶν γραμματέων καὶ Φαρισαίων λέγοντες, “Διδάσκαλε, θέλομεν ἀπὸ σοῦ σημεῖον ἰδεῖν.”
40012039 Ὁ δὲ ἀποκριθεὶς εἶπεν αὐτοῖς, “Γενεὰ πονηρὰ καὶ μοιχαλὶς σημεῖον ἐπιζητεῖ, καὶ σημεῖον οὐ δοθήσεται αὐτῇ, εἰ μὴ τὸ σημεῖον Ἰωνᾶ τοῦ προφήτου.
40012040 Ὥσπερ γὰρ ‘ἦν Ἰωνᾶς ἐν τῇ κοιλίᾳ τοῦ κήτους τρεῖς ἡμέρας καὶ τρεῖς νύκτας’, οὕτως ἔσται ὁ Υἱὸς τοῦ Ἀνθρώπου ἐν τῇ καρδίᾳ τῆς γῆς τρεῖς ἡμέρας καὶ τρεῖς νύκτας.
40012041 Ἄνδρες Νινευῖται ἀναστήσονται ἐν τῇ κρίσει μετὰ τῆς γενεᾶς ταύτης, καὶ κατακρινοῦσιν αὐτήν· ὅτι μετενόησαν εἰς τὸ κήρυγμα Ἰωνᾶ, καὶ ἰδοὺ, πλεῖον Ἰωνᾶ ὧδε.
40012042 Βασίλισσα νότου ἐγερθήσεται ἐν τῇ κρίσει μετὰ τῆς γενεᾶς ταύτης, καὶ κατακρινεῖ αὐτήν· ὅτι ἦλθεν ἐκ τῶν περάτων τῆς γῆς ἀκοῦσαι τὴν σοφίαν Σολομῶνος, καὶ ἰδοὺ, πλεῖον Σολομῶνος ὧδε.
40012043 Ὅταν δὲ τὸ ἀκάθαρτον πνεῦμα ἐξέλθῃ ἀπὸ τοῦ ἀνθρώπου, διέρχεται διʼ ἀνύδρων τόπων ζητοῦν ἀνάπαυσιν καὶ οὐχ εὑρίσκει.
40012044 Τότε λέγει, ‘Εἰς τὸν οἶκόν μου ἐπιστρέψω ὅθεν ἐξῆλθον.’ Καὶ ἐλθὸν, εὑρίσκει σχολάζοντα σεσαρωμένον καὶ κεκοσμημένον.
40012045 Τότε πορεύεται καὶ παραλαμβάνει μεθʼ ἑαυτοῦ ἑπτὰ ἕτερα πνεύματα πονηρότερα ἑαυτοῦ, καὶ εἰσελθόντα κατοικεῖ ἐκεῖ· καὶ γίνεται τὰ ἔσχατα τοῦ ἀνθρώπου ἐκείνου χείρονα τῶν πρώτων. Οὕτως ἔσται καὶ τῇ γενεᾷ ταύτῃ τῇ πονηρᾷ.”
40012046 Ἔτι αὐτοῦ λαλοῦντος τοῖς ὄχλοις, ἰδοὺ, ἡ μήτηρ καὶ οἱ ἀδελφοὶ αὐτοῦ εἱστήκεισαν ἔξω, ζητοῦντες αὐτῷ λαλῆσαι.
40012047 Εἶπεν δέ τις αὐτῷ, “Ἰδοὺ, ἡ μήτηρ σου καὶ οἱ ἀδελφοί σου ἔξω ἑστήκασιν ζητοῦντές σοι λαλῆσαι.”
40012048 Ὁ δὲ ἀποκριθεὶς εἶπεν τῷ λέγοντι αὐτῷ, “Τίς ἐστιν ἡ μήτηρ μου καὶ τίνες εἰσὶν οἱ ἀδελφοί μου;”
40012049 Καὶ ἐκτείνας τὴν χεῖρα αὐτοῦ ἐπὶ τοὺς μαθητὰς αὐτοῦ εἶπεν, “Ἰδοὺ, ἡ μήτηρ μου καὶ οἱ ἀδελφοί μου.
40012050 Ὅστις γὰρ ἂν ποιήσῃ τὸ θέλημα τοῦ Πατρός μου τοῦ ἐν οὐρανοῖς, αὐτός μου ἀδελφὸς, καὶ ἀδελφὴ, καὶ μήτηρ ἐστίν.”
40013001 Ἐν τῇ ἡμέρᾳ ἐκείνῃ, ἐξελθὼν ὁ ˚Ἰησοῦς τῆς οἰκίας, ἐκάθητο παρὰ τὴν θάλασσαν.
40013002 Καὶ συνήχθησαν πρὸς αὐτὸν ὄχλοι πολλοί, ὥστε αὐτὸν εἰς πλοῖον ἐμβάντα καθῆσθαι, καὶ πᾶς ὁ ὄχλος ἐπὶ τὸν αἰγιαλὸν εἱστήκει.
40013003 Καὶ ἐλάλησεν αὐτοῖς πολλὰ ἐν παραβολαῖς λέγων, “Ἰδοὺ, ἐξῆλθεν ὁ σπείρων τοῦ σπείρειν.
40013004 Καὶ ἐν τῷ σπείρειν αὐτὸν, ἃ μὲν ἔπεσεν παρὰ τὴν ὁδόν, καὶ ἦλθεν τὰ πετεινὰ καὶ κατέφαγεν αὐτά.
40013005 Ἄλλα δὲ ἔπεσεν ἐπὶ τὰ πετρώδη, ὅπου οὐκ εἶχεν γῆν πολλήν, καὶ εὐθέως ἐξανέτειλεν διὰ τὸ μὴ ἔχειν βάθος γῆς,
40013006 ἡλίου δὲ ἀνατείλαντος, ἐκαυματίσθη καὶ διὰ τὸ μὴ ἔχειν ῥίζαν ἐξηράνθη.
40013007 Ἄλλα δὲ ἔπεσεν ἐπὶ τὰς ἀκάνθας, καὶ ἀνέβησαν αἱ ἄκανθαι καὶ ἀπέπνιξαν αὐτά.
40013008 Ἄλλα δὲ ἔπεσεν ἐπὶ τὴν γῆν τὴν καλὴν, καὶ ἐδίδου καρπόν– ὃ μὲν ἑκατὸν, ὃ δὲ ἑξήκοντα, ὃ δὲ τριάκοντα.
40013009 Ὁ ἔχων ὦτα, ἀκουέτω.”
40013010 Καὶ προσελθόντες οἱ μαθηταὶ εἶπαν αὐτῷ, “Διὰ τί ἐν παραβολαῖς λαλεῖς αὐτοῖς;”
40013011 Ὁ δὲ ἀποκριθεὶς εἶπεν αὐτοῖς, “Ὅτι ὑμῖν δέδοται γνῶναι τὰ μυστήρια τῆς Βασιλείας τῶν Οὐρανῶν, ἐκείνοις δὲ οὐ δέδοται.
40013012 Ὅστις γὰρ ἔχει, δοθήσεται αὐτῷ καὶ περισσευθήσεται· ὅστις δὲ οὐκ ἔχει, καὶ ὃ ἔχει ἀρθήσεται ἀπʼ αὐτοῦ.
40013013 Διὰ τοῦτο ἐν παραβολαῖς αὐτοῖς λαλῶ, ὅτι βλέποντες οὐ βλέπουσιν, καὶ ἀκούοντες οὐκ ἀκούουσιν, οὐδὲ συνίουσιν.
40013014 Καὶ ἀναπληροῦται αὐτοῖς ἡ προφητεία Ἠσαΐου ἡ λέγουσα, ‘Ἀκοῇ ἀκούσετε καὶ οὐ μὴ συνῆτε, καὶ βλέποντες βλέψετε καὶ οὐ μὴ ἴδητε.
40013015 Ἐπαχύνθη γὰρ ἡ καρδία τοῦ λαοῦ τούτου, καὶ τοῖς ὠσὶν βαρέως ἤκουσαν, καὶ τοὺς ὀφθαλμοὺς αὐτῶν ἐκάμμυσαν· μήποτε ἴδωσιν τοῖς ὀφθαλμοῖς, καὶ τοῖς ὠσὶν ἀκούσωσιν, καὶ τῇ καρδίᾳ συνῶσιν, καὶ ἐπιστρέψωσιν καὶ ἰάσομαι αὐτούς.’
40013016 Ὑμῶν δὲ μακάριοι οἱ ὀφθαλμοὶ ὅτι βλέπουσιν, καὶ τὰ ὦτα ὑμῶν ὅτι ἀκούουσιν.
40013017 Ἀμὴν, γὰρ λέγω ὑμῖν ὅτι πολλοὶ προφῆται καὶ δίκαιοι ἐπεθύμησαν ἰδεῖν ἃ βλέπετε, καὶ οὐκ εἶδαν, καὶ ἀκοῦσαι ἃ ἀκούετε, καὶ οὐκ ἤκουσαν.
40013018 Ὑμεῖς οὖν ἀκούσατε τὴν παραβολὴν τοῦ σπείραντος.
40013019 Παντὸς ἀκούοντος τὸν λόγον τῆς βασιλείας καὶ μὴ συνιέντος, ἔρχεται ὁ πονηρὸς καὶ ἁρπάζει τὸ ἐσπαρμένον ἐν τῇ καρδίᾳ αὐτοῦ. Οὗτός ἐστιν ὁ παρὰ τὴν ὁδὸν σπαρείς.
40013020 Ὁ δὲ ἐπὶ τὰ πετρώδη σπαρείς, οὗτός ἐστιν ὁ τὸν λόγον ἀκούων καὶ εὐθὺς μετὰ χαρᾶς λαμβάνων αὐτόν,
40013021 οὐκ ἔχει δὲ ῥίζαν ἐν ἑαυτῷ, ἀλλὰ πρόσκαιρός ἐστιν, γενομένης δὲ θλίψεως ἢ διωγμοῦ διὰ τὸν λόγον, εὐθὺς σκανδαλίζεται.
40013022 Ὁ δὲ εἰς τὰς ἀκάνθας σπαρείς, οὗτός ἐστιν ὁ τὸν λόγον ἀκούων, καὶ ἡ μέριμνα τοῦ αἰῶνος καὶ ἡ ἀπάτη τοῦ πλούτου συμπνίγει τὸν λόγον, καὶ ἄκαρπος γίνεται.
40013023 Ὁ δὲ ἐπὶ τὴν καλὴν γῆν σπαρείς, οὗτός ἐστιν ὁ τὸν λόγον ἀκούων καὶ συνιείς, ὃς δὴ καρποφορεῖ καὶ ποιεῖ, ὃ μὲν ἑκατὸν, ὃ δὲ ἑξήκοντα, ὃ δὲ τριάκοντα.”
40013024 Ἄλλην παραβολὴν παρέθηκεν αὐτοῖς λέγων, “Ὡμοιώθη ἡ Βασιλεία τῶν Οὐρανῶν, ἀνθρώπῳ σπείραντι καλὸν σπέρμα ἐν τῷ ἀγρῷ αὐτοῦ.
40013025 Ἐν δὲ τῷ καθεύδειν τοὺς ἀνθρώπους, ἦλθεν αὐτοῦ ὁ ἐχθρὸς καὶ ἐπέσπειρεν ζιζάνια ἀνὰ μέσον τοῦ σίτου καὶ ἀπῆλθεν.
40013026 Ὅτε δὲ ἐβλάστησεν ὁ χόρτος καὶ καρπὸν ἐποίησεν, τότε ἐφάνη καὶ τὰ ζιζάνια.
40013027 Προσελθόντες δὲ οἱ δοῦλοι τοῦ οἰκοδεσπότου εἶπον αὐτῷ, ‘Κύριε, οὐχὶ καλὸν σπέρμα ἔσπειρας ἐν τῷ σῷ ἀγρῷ; Πόθεν οὖν ἔχει ζιζάνια;’
40013028 Ὁ δὲ ἔφη αὐτοῖς, ‘Ἐχθρὸς ἄνθρωπος τοῦτο ἐποίησεν.’ Οἱ δὲ δοῦλοι λέγουσιν αὐτῷ, ‘Θέλεις οὖν ἀπελθόντες, συλλέξωμεν αὐτά;’
40013029 Ὁ δέ φησιν, ‘Οὔ, μήποτε συλλέγοντες τὰ ζιζάνια, ἐκριζώσητε ἅμα αὐτοῖς τὸν σῖτον.
40013030 Ἄφετε συναυξάνεσθαι ἀμφότερα μέχρι τοῦ θερισμοῦ· καὶ ἐν καιρῷ τοῦ θερισμοῦ ἐρῶ τοῖς θερισταῖς, “Συλλέξατε πρῶτον τὰ ζιζάνια καὶ δήσατε αὐτὰ εἰς δέσμας πρὸς τὸ κατακαῦσαι αὐτά, τὸν δὲ σῖτον συναγάγετε εἰς τὴν ἀποθήκην μου.”’”
40013031 Ἄλλην παραβολὴν παρέθηκεν αὐτοῖς λέγων, “Ὁμοία ἐστὶν ἡ Βασιλεία τῶν Οὐρανῶν κόκκῳ σινάπεως, ὃν λαβὼν ἄνθρωπος ἔσπειρεν ἐν τῷ ἀγρῷ αὐτοῦ·
40013032 ὃ μικρότερον μέν ἐστιν πάντων τῶν σπερμάτων, ὅταν δὲ αὐξηθῇ μεῖζον τῶν λαχάνων ἐστὶν καὶ γίνεται δένδρον, ὥστε ἐλθεῖν ‘τὰ πετεινὰ τοῦ οὐρανοῦ καὶ κατασκηνοῦν ἐν τοῖς κλάδοις αὐτοῦ’.”
40013033 Ἄλλην παραβολὴν ἐλάλησεν αὐτοῖς: “Ὁμοία ἐστὶν ἡ Βασιλεία τῶν Οὐρανῶν ζύμῃ, ἣν λαβοῦσα, γυνὴ ἐνέκρυψεν εἰς ἀλεύρου σάτα τρία, ἕως οὗ ἐζυμώθη ὅλον.”
40013034 Ταῦτα πάντα ἐλάλησεν ὁ ˚Ἰησοῦς ἐν παραβολαῖς τοῖς ὄχλοις, καὶ χωρὶς παραβολῆς οὐδὲν ἐλάλει αὐτοῖς·
40013035 ὅπως πληρωθῇ τὸ ῥηθὲν διὰ τοῦ προφήτου λέγοντος, “Ἀνοίξω ἐν παραβολαῖς τὸ στόμα μου, ἐρεύξομαι κεκρυμμένα ἀπὸ καταβολῆς κόσμου.”
40013036 Τότε ἀφεὶς τοὺς ὄχλους, ἦλθεν εἰς τὴν οἰκίαν. Καὶ προσῆλθον αὐτῷ οἱ μαθηταὶ αὐτοῦ λέγοντες, “Διασάφησον ἡμῖν τὴν παραβολὴν τῶν ζιζανίων τοῦ ἀγροῦ.”
40013037 Ὁ δὲ ἀποκριθεὶς εἶπεν, “Ὁ σπείρων τὸ καλὸν σπέρμα ἐστὶν ὁ Υἱὸς τοῦ Ἀνθρώπου·
40013038 ὁ δὲ ἀγρός ἐστιν ὁ κόσμος· τὸ δὲ καλὸν σπέρμα, οὗτοί εἰσιν οἱ υἱοὶ τῆς βασιλείας· τὰ δὲ ζιζάνιά εἰσιν οἱ υἱοὶ τοῦ πονηροῦ,
40013039 ὁ δὲ ἐχθρὸς, ὁ σπείρας αὐτά, ἐστιν ὁ διάβολος· ὁ δὲ θερισμὸς συντέλεια αἰῶνός ἐστιν, οἱ δὲ θερισταὶ ἄγγελοί εἰσιν.
40013040 Ὥσπερ οὖν συλλέγεται τὰ ζιζάνια καὶ πυρὶ κατακαίεται, οὕτως ἔσται ἐν τῇ συντελείᾳ τοῦ αἰῶνος.
40013041 Ἀποστελεῖ ὁ Υἱὸς τοῦ Ἀνθρώπου τοὺς ἀγγέλους αὐτοῦ, καὶ συλλέξουσιν ἐκ τῆς βασιλείας αὐτοῦ πάντα τὰ σκάνδαλα καὶ τοὺς ποιοῦντας τὴν ἀνομίαν,
40013042 καὶ βαλοῦσιν αὐτοὺς εἰς τὴν κάμινον τοῦ πυρός· ἐκεῖ ἔσται ὁ κλαυθμὸς καὶ ὁ βρυγμὸς τῶν ὀδόντων.
40013043 Τότε ‘οἱ δίκαιοι ἐκλάμψουσιν ὡς ὁ ἥλιος’ ἐν τῇ βασιλείᾳ τοῦ Πατρὸς αὐτῶν. Ὁ ἔχων ὦτα, ἀκουέτω.
40013044 Ὁμοία ἐστὶν ἡ Βασιλεία τῶν Οὐρανῶν θησαυρῷ κεκρυμμένῳ ἐν τῷ ἀγρῷ, ὃν εὑρὼν ἄνθρωπος ἔκρυψεν, καὶ ἀπὸ τῆς χαρᾶς αὐτοῦ ὑπάγει καὶ πωλεῖ πάντα ὅσα ἔχει, καὶ ἀγοράζει τὸν ἀγρὸν ἐκεῖνον.
40013045 Πάλιν ὁμοία ἐστὶν ἡ Βασιλεία τῶν Οὐρανῶν ἀνθρώπῳ ἐμπόρῳ ζητοῦντι καλοὺς μαργαρίτας·
40013046 εὑρὼν δὲ ἕνα πολύτιμον μαργαρίτην ἀπελθὼν, πέπρακεν πάντα ὅσα εἶχεν, καὶ ἠγόρασεν αὐτόν.
40013047 Πάλιν ὁμοία ἐστὶν ἡ Βασιλεία τῶν Οὐρανῶν σαγήνῃ βληθείσῃ εἰς τὴν θάλασσαν καὶ ἐκ παντὸς γένους συναγαγούσῃ·
40013048 ἣν ὅτε ἐπληρώθη, ἀναβιβάσαντες ἐπὶ τὸν αἰγιαλὸν καὶ καθίσαντες, συνέλεξαν τὰ καλὰ εἰς ἄγγη, τὰ δὲ σαπρὰ ἔξω ἔβαλον.
40013049 Οὕτως ἔσται ἐν τῇ συντελείᾳ τοῦ αἰῶνος· ἐξελεύσονται οἱ ἄγγελοι, καὶ ἀφοριοῦσιν τοὺς πονηροὺς ἐκ μέσου τῶν δικαίων,
40013050 καὶ βαλοῦσιν αὐτοὺς εἰς τὴν κάμινον τοῦ πυρός. Ἐκεῖ ἔσται ὁ κλαυθμὸς καὶ ὁ βρυγμὸς τῶν ὀδόντων.
40013051 Συνήκατε ταῦτα πάντα;” Λέγουσιν αὐτῷ, “Ναί.”
40013052 Ὁ δὲ εἶπεν αὐτοῖς, “Διὰ τοῦτο, πᾶς γραμματεὺς μαθητευθεὶς τῇ Βασιλείᾳ τῶν Οὐρανῶν ὅμοιός ἐστιν ἀνθρώπῳ, οἰκοδεσπότῃ, ὅστις ἐκβάλλει ἐκ τοῦ θησαυροῦ αὐτοῦ καινὰ καὶ παλαιά.”
40013053 Καὶ ἐγένετο ὅτε ἐτέλεσεν ὁ ˚Ἰησοῦς τὰς παραβολὰς ταύτας, μετῆρεν ἐκεῖθεν.
40013054 Καὶ ἐλθὼν εἰς τὴν πατρίδα αὐτοῦ, ἐδίδασκεν αὐτοὺς ἐν τῇ συναγωγῇ αὐτῶν, ὥστε ἐκπλήσσεσθαι αὐτοὺς καὶ λέγειν, “Πόθεν τούτῳ ἡ σοφία αὕτη καὶ αἱ δυνάμεις;
40013055 Οὐχ οὗτός ἐστιν ὁ τοῦ τέκτονος υἱός; Οὐχ ἡ μήτηρ αὐτοῦ λέγεται Μαριὰμ, καὶ οἱ ἀδελφοὶ αὐτοῦ, Ἰάκωβος, καὶ Ἰωσὴφ, καὶ Σίμων, καὶ Ἰούδας;
40013056 Καὶ αἱ ἀδελφαὶ αὐτοῦ οὐχὶ πᾶσαι πρὸς ἡμᾶς εἰσιν; Πόθεν οὖν τούτῳ ταῦτα πάντα;”
40013057 Καὶ ἐσκανδαλίζοντο ἐν αὐτῷ. Ὁ δὲ ˚Ἰησοῦς εἶπεν αὐτοῖς, “Οὐκ ἔστιν προφήτης ἄτιμος, εἰ μὴ ἐν τῇ πατρίδι καὶ ἐν τῇ οἰκίᾳ αὐτοῦ.”
40013058 Καὶ οὐκ ἐποίησεν ἐκεῖ δυνάμεις πολλὰς, διὰ τὴν ἀπιστίαν αὐτῶν.
40014001 Ἐν ἐκείνῳ τῷ καιρῷ ἤκουσεν Ἡρῴδης ὁ τετραάρχης τὴν ἀκοὴν ˚Ἰησοῦ,
40014002 καὶ εἶπεν τοῖς παισὶν αὐτοῦ, “Οὗτός ἐστιν Ἰωάννης ὁ Βαπτιστής· αὐτὸς ἠγέρθη ἀπὸ τῶν νεκρῶν, καὶ διὰ τοῦτο αἱ δυνάμεις ἐνεργοῦσιν ἐν αὐτῷ.”
40014003 Ὁ γὰρ Ἡρῴδης κρατήσας τὸν Ἰωάννην, ἔδησεν αὐτὸν καὶ ἐν φυλακῇ ἀπέθετο, διὰ Ἡρῳδιάδα, τὴν γυναῖκα Φιλίππου, τοῦ ἀδελφοῦ αὐτοῦ.
40014004 Ἔλεγεν γὰρ αὐτῷ ὁ Ἰωάννης, “Οὐκ ἔξεστίν σοι ἔχειν αὐτήν.”
40014005 Καὶ θέλων αὐτὸν ἀποκτεῖναι, ἐφοβήθη τὸν ὄχλον, ὅτι ὡς προφήτην αὐτὸν εἶχον.
40014006 Γενεσίοις δὲ γενομένοις τοῦ Ἡρῴδου, ὠρχήσατο ἡ θυγάτηρ τῆς Ἡρῳδιάδος ἐν τῷ μέσῳ καὶ ἤρεσεν τῷ Ἡρῴδῃ,
40014007 ὅθεν μεθʼ ὅρκου, ὡμολόγησεν αὐτῇ δοῦναι ὃ ἐὰν αἰτήσηται.
40014008 Ἡ δὲ προβιβασθεῖσα ὑπὸ τῆς μητρὸς αὐτῆς, “Δός μοι”, φησίν, “ὧδε ἐπὶ πίνακι, τὴν κεφαλὴν Ἰωάννου τοῦ Βαπτιστοῦ.”
40014009 Καὶ ἐλυπήθη ὁ βασιλεὺς διὰ δὲ τοὺς ὅρκους καὶ τοὺς συνανακειμένους, ἐκέλευσεν δοθῆναι.
40014010 Καὶ πέμψας, ἀπεκεφάλισεν Ἰωάννην ἐν τῇ φυλακῇ.
40014011 Καὶ ἠνέχθη ἡ κεφαλὴ αὐτοῦ ἐπὶ πίνακι, καὶ ἐδόθη τῷ κορασίῳ, καὶ ἤνεγκεν τῇ μητρὶ αὐτῆς.
40014012 Καὶ προσελθόντες, οἱ μαθηταὶ αὐτοῦ ἦραν τὸ πτῶμα καὶ ἔθαψαν αὐτόν, καὶ ἐλθόντες, ἀπήγγειλαν τῷ ˚Ἰησοῦ.
40014013 Ἀκούσας δὲ, ὁ ˚Ἰησοῦς ἀνεχώρησεν ἐκεῖθεν ἐν πλοίῳ εἰς ἔρημον τόπον κατʼ ἰδίαν. Καὶ ἀκούσαντες, οἱ ὄχλοι ἠκολούθησαν αὐτῷ πεζῇ ἀπὸ τῶν πόλεων.
40014014 Καὶ ἐξελθὼν, εἶδεν πολὺν ὄχλον, καὶ ἐσπλαγχνίσθη ἐπʼ αὐτοῖς καὶ ἐθεράπευσεν τοὺς ἀρρώστους αὐτῶν.
40014015 Ὀψίας δὲ γενομένης, προσῆλθον αὐτῷ οἱ μαθηταὶ λέγοντες, “Ἔρημός ἐστιν ὁ τόπος καὶ ἡ ὥρα ἤδη παρῆλθεν· ἀπόλυσον τοὺς ὄχλους, ἵνα ἀπελθόντες εἰς τὰς κώμας, ἀγοράσωσιν ἑαυτοῖς βρώματα.”
40014016 Ὁ δὲ ˚Ἰησοῦς εἶπεν αὐτοῖς, “Οὐ χρείαν ἔχουσιν ἀπελθεῖν. Δότε αὐτοῖς ὑμεῖς φαγεῖν.”
40014017 Οἱ δὲ λέγουσιν αὐτῷ, “Οὐκ ἔχομεν ὧδε, εἰ μὴ πέντε ἄρτους καὶ δύο ἰχθύας.”
40014018 Ὁ δὲ εἶπεν, “Φέρετέ μοι ὧδε αὐτούς.”
40014019 Καὶ κελεύσας τοὺς ὄχλους ἀνακλιθῆναι ἐπὶ τοῦ χόρτου, καὶ λαβὼν τοὺς πέντε ἄρτους καὶ τοὺς δύο ἰχθύας, ἀναβλέψας εἰς τὸν οὐρανὸν, εὐλόγησεν καὶ κλάσας, ἔδωκεν τοῖς μαθηταῖς τοὺς ἄρτους, οἱ δὲ μαθηταὶ τοῖς ὄχλοις.
40014020 Καὶ ἔφαγον πάντες καὶ ἐχορτάσθησαν, καὶ ἦραν τὸ περισσεῦον τῶν κλασμάτων, δώδεκα κοφίνους πλήρεις.
40014021 Οἱ δὲ ἐσθίοντες ἦσαν ἄνδρες ὡσεὶ πεντακισχίλιοι, χωρὶς γυναικῶν καὶ παιδίων.
40014022 Καὶ εὐθέως ἠνάγκασεν τοὺς μαθητὰς ἐμβῆναι εἰς τὸ πλοῖον, καὶ προσαγάγῃ αὐτὸν εἰς τὸ πέραν, ἕως οὗ ἀπολύσῃ τοὺς ὄχλους.
40014023 Καὶ ἀπολύσας τοὺς ὄχλους, ἀνέβη εἰς τὸ ὄρος κατʼ ἰδίαν προσεύξασθαι. Ὀψίας δὲ γενομένης, μόνος ἦν ἐκεῖ.
40014024 Τὸ δὲ πλοῖον ἤδη μέσον τῆς θαλάσσης ἦν, βασανιζόμενον ὑπὸ τῶν κυμάτων, ἦν γὰρ ἐναντίος ὁ ἄνεμος.
40014025 Τετάρτῃ δὲ φυλακῇ τῆς νυκτὸς, ἦλθεν πρὸς αὐτοὺς περιπατῶν ἐπὶ τὴν θάλασσαν.
40014026 Οἱ δὲ μαθηταὶ ἰδόντες, αὐτὸν ἐπὶ τῆς θαλάσσης περιπατοῦντα, ἐταράχθησαν λέγοντες, ὅτι “Φάντασμά ἐστιν”, καὶ ἀπὸ τοῦ φόβου ἔκραξαν.
40014027 Εὐθὺς δὲ ἐλάλησεν ὁ ˚Ἰησοῦς αὐτοῖς λέγων, “Θαρσεῖτε, ἐγώ εἰμι, μὴ φοβεῖσθε.”
40014028 Ἀποκριθεὶς δὲ αὐτῷ, ὁ Πέτρος εἶπεν, “˚Κύριε, εἰ σὺ εἶ, κέλευσόν με ἐλθεῖν πρὸς σὲ ἐπὶ τὰ ὕδατα.”
40014029 Ὁ δὲ εἶπεν, “Ἐλθέ.” Καὶ καταβὰς ἀπὸ τοῦ πλοίου, Πέτρος περιεπάτησεν ἐπὶ τὰ ὕδατα καὶ ἦλθεν πρὸς τὸν ˚Ἰησοῦν.
40014030 Βλέπων δὲ τὸν ἄνεμον ἰσχυρὸν, ἐφοβήθη καὶ ἀρξάμενος καταποντίζεσθαι, ἔκραξεν λέγων, “˚Κύριε, σῶσόν με.”
40014031 Εὐθέως δὲ ὁ ˚Ἰησοῦς ἐκτείνας τὴν χεῖρα, ἐπελάβετο αὐτοῦ καὶ λέγει αὐτῷ, “Ὀλιγόπιστε, εἰς τί ἐδίστασας;”
40014032 Καὶ ἀναβάντων αὐτῶν εἰς τὸ πλοῖον, ἐκόπασεν ὁ ἄνεμος.
40014033 Οἱ δὲ ἐν τῷ πλοίῳ προσεκύνησαν αὐτῷ λέγοντες, “Ἀληθῶς ˚Θεοῦ Υἱὸς εἶ!”
40014034 Καὶ διαπεράσαντες, ἦλθον ἐπὶ τὴν γῆν εἰς Γεννησαρέτ.
40014035 Καὶ ἐπιγνόντες αὐτὸν, οἱ ἄνδρες τοῦ τόπου ἐκείνου ἀπέστειλαν εἰς ὅλην τὴν περίχωρον ἐκείνην, καὶ προσήνεγκαν αὐτῷ πάντας τοὺς κακῶς ἔχοντας,
40014036 καὶ παρεκάλουν αὐτὸν ἵνα μόνον ἅψωνται τοῦ κρασπέδου τοῦ ἱματίου αὐτοῦ· καὶ ὅσοι ἥψαντο διεσώθησαν.
40015001 Τότε προσέρχονται τῷ ˚Ἰησοῦ ἀπὸ Ἱεροσολύμων, Φαρισαῖοι καὶ γραμματεῖς λέγοντες,
40015002 “Διὰ τί οἱ μαθηταί σου παραβαίνουσιν τὴν παράδοσιν τῶν πρεσβυτέρων; Οὐ γὰρ νίπτονται τὰς χεῖρας ὅταν ἄρτον ἐσθίωσιν.”
40015003 Ὁ δὲ ἀποκριθεὶς εἶπεν αὐτοῖς, “Διὰ τί καὶ ὑμεῖς παραβαίνετε τὴν ἐντολὴν τοῦ ˚Θεοῦ διὰ τὴν παράδοσιν ὑμῶν;
40015004 Ὁ γὰρ ˚Θεὸς ἐνετείλατο λέγων, ‘Τίμα τὸν πατέρα καὶ τὴν μητέρα’, καί, ‘Ὁ κακολογῶν πατέρα ἢ μητέρα, θανάτῳ τελευτάτω.’
40015005 Ὑμεῖς δὲ λέγετε, ‘Ὃς ἂν εἴπῃ τῷ πατρὶ ἢ τῇ μητρί, “Δῶρον ὃ ἐὰν ἐξ ἐμοῦ ὠφεληθῇς”,
40015006 οὐ μὴ τιμήσει τὸν πατέρα αὐτοῦ.’ Καὶ ἠκυρώσατε τὸν λόγον τοῦ ˚Θεοῦ διὰ τὴν παράδοσιν ὑμῶν.
40015007 Ὑποκριταί! Καλῶς ἐπροφήτευσεν περὶ ὑμῶν Ἠσαΐας λέγων,
40015008 ‘Ὁ λαὸς οὗτος τοῖς χείλεσίν με τιμᾷ, ἡ δὲ καρδία αὐτῶν πόρρω ἀπέχει ἀπʼ ἐμοῦ.
40015009 Μάτην δὲ σέβονταί με, διδάσκοντες διδασκαλίας ἐντάλματα ἀνθρώπων.’”
40015010 Καὶ προσκαλεσάμενος τὸν ὄχλον, εἶπεν αὐτοῖς, “Ἀκούετε καὶ συνίετε!
40015011 Οὐ τὸ εἰσερχόμενον εἰς τὸ στόμα κοινοῖ τὸν ἄνθρωπον, ἀλλὰ τὸ ἐκπορευόμενον ἐκ τοῦ στόματος, τοῦτο κοινοῖ τὸν ἄνθρωπον.”
40015012 Τότε προσελθόντες οἱ μαθηταὶ λέγουσιν αὐτῷ, “Οἶδας ὅτι οἱ Φαρισαῖοι ἀκούσαντες τὸν λόγον ἐσκανδαλίσθησαν;”
40015013 Ὁ δὲ ἀποκριθεὶς εἶπεν, “Πᾶσα φυτεία ἣν οὐκ ἐφύτευσεν ὁ Πατήρ μου ὁ οὐράνιος ἐκριζωθήσεται.
40015014 Ἄφετε αὐτούς· ὁδηγοί εἰσιν τυφλοί τυφλὸς, δὲ τυφλὸν ἐὰν ὁδηγῇ, ἀμφότεροι εἰς βόθυνον πεσοῦνται.”
40015015 Ἀποκριθεὶς δὲ ὁ Πέτρος εἶπεν αὐτῷ, “Φράσον ἡμῖν τὴν παραβολήν.”
40015016 Ὁ δὲ εἶπεν, “Ἀκμὴν καὶ ὑμεῖς ἀσύνετοί ἐστε;
40015017 Οὔπω νοεῖτε ὅτι πᾶν τὸ εἰσπορευόμενον εἰς τὸ στόμα, εἰς τὴν κοιλίαν χωρεῖ, καὶ εἰς ἀφεδρῶνα ἐκβάλλεται;
40015018 Τὰ δὲ ἐκπορευόμενα ἐκ τοῦ στόματος, ἐκ τῆς καρδίας ἐξέρχεται, κἀκεῖνα κοινοῖ τὸν ἄνθρωπον.
40015019 Ἐκ γὰρ τῆς καρδίας ἐξέρχονται διαλογισμοὶ πονηροί, φόνοι, μοιχεῖαι, πορνεῖαι, κλοπαί, ψευδομαρτυρίαι, βλασφημίαι.
40015020 Ταῦτά ἐστιν τὰ κοινοῦντα τὸν ἄνθρωπον, τὸ δὲ ἀνίπτοις χερσὶν φαγεῖν, οὐ κοινοῖ τὸν ἄνθρωπον.”
40015021 Καὶ ἐξελθὼν ἐκεῖθεν, ὁ ˚Ἰησοῦς ἀνεχώρησεν εἰς τὰ μέρη Τύρου καὶ Σιδῶνος.
40015022 Καὶ ἰδοὺ, γυνὴ Χαναναία ἀπὸ τῶν ὁρίων ἐκείνων ἐξελθοῦσα, ἔκραζεν λέγουσα, “Ἐλέησόν με, ˚Κύριε, Υἱὸς Δαυίδ· ἡ θυγάτηρ μου κακῶς δαιμονίζεται.”
40015023 Ὁ δὲ οὐκ ἀπεκρίθη αὐτῇ λόγον. Καὶ προσελθόντες, οἱ μαθηταὶ αὐτοῦ ἠρώτουν αὐτὸν λέγοντες, “Ἀπόλυσον αὐτήν, ὅτι κράζει ὄπισθεν ἡμῶν.”
40015024 Ὁ δὲ ἀποκριθεὶς εἶπεν, “Οὐκ ἀπεστάλην εἰ μὴ εἰς τὰ πρόβατα τὰ ἀπολωλότα οἴκου Ἰσραήλ.”
40015025 Ἡ δὲ ἐλθοῦσα προσεκύνει αὐτῷ λέγουσα, “˚Κύριε, βοήθει μοι!”
40015026 Ὁ δὲ ἀποκριθεὶς εἶπεν, “Οὐκ ἔστιν καλὸν λαβεῖν τὸν ἄρτον τῶν τέκνων καὶ βαλεῖν τοῖς κυναρίοις.”
40015027 Ἡ δὲ εἶπεν, “Ναί, ˚Κύριε, καὶ γὰρ τὰ κυνάρια ἐσθίει ἀπὸ τῶν ψιχίων τῶν πιπτόντων ἀπὸ τῆς τραπέζης τῶν κυρίων αὐτῶν.”
40015028 Τότε ἀποκριθεὶς ὁ ˚Ἰησοῦς εἶπεν αὐτῇ, “Ὦ γύναι, μεγάλη σου ἡ πίστις· γενηθήτω σοι ὡς θέλεις.” Καὶ ἰάθη ἡ θυγάτηρ αὐτῆς ἀπὸ τῆς ὥρας ἐκείνης.
40015029 Καὶ μεταβὰς ἐκεῖθεν, ὁ ˚Ἰησοῦς ἦλθεν παρὰ τὴν θάλασσαν τῆς Γαλιλαίας, καὶ ἀναβὰς εἰς τὸ ὄρος, ἐκάθητο ἐκεῖ.
40015030 Καὶ προσῆλθον αὐτῷ ὄχλοι πολλοὶ, ἔχοντες μεθʼ ἑαυτῶν χωλούς, κυλλούς, τυφλούς, κωφούς, καὶ ἑτέρους πολλούς, καὶ ἔρριψαν αὐτοὺς παρὰ τοὺς πόδας αὐτοῦ, καὶ ἐθεράπευσεν αὐτούς·
40015031 ὥστε τοὺς ὄχλους θαυμάσαι, βλέποντας κωφοὺς λαλοῦντας, κυλλοὺς ὑγιεῖς, καὶ χωλοὺς περιπατοῦντας, καὶ τυφλοὺς βλέποντας· καὶ ἐδόξασαν τὸν ˚Θεὸν Ἰσραήλ.
40015032 Ὁ δὲ ˚Ἰησοῦς προσκαλεσάμενος τοὺς μαθητὰς αὐτοῦ εἶπεν, “Σπλαγχνίζομαι ἐπὶ τὸν ὄχλον, ὅτι ἤδη ἡμέραι τρεῖς προσμένουσίν μοι, καὶ οὐκ ἔχουσιν τί φάγωσιν· καὶ ἀπολῦσαι αὐτοὺς νήστεις οὐ θέλω, μήποτε ἐκλυθῶσιν ἐν τῇ ὁδῷ.”
40015033 Καὶ λέγουσιν αὐτῷ οἱ μαθηταί, “Πόθεν ἡμῖν ἐν ἐρημίᾳ, ἄρτοι τοσοῦτοι ὥστε χορτάσαι ὄχλον τοσοῦτον;”
40015034 Καὶ λέγει αὐτοῖς ὁ ˚Ἰησοῦς, “Πόσους ἄρτους ἔχετε;” Οἱ δὲ εἶπον, “Ἑπτά, καὶ ὀλίγα ἰχθύδια.”
40015035 Καὶ παραγγείλας τῷ ὄχλῳ ἀναπεσεῖν ἐπὶ τὴν γῆν,
40015036 ἔλαβεν τοὺς ἑπτὰ ἄρτους καὶ τοὺς ἰχθύας, καὶ εὐχαριστήσας ἔκλασεν καὶ ἐδίδου τοῖς μαθηταῖς, οἱ δὲ μαθηταὶ τοῖς ὄχλοις.
40015037 Καὶ ἔφαγον πάντες καὶ ἐχορτάσθησαν, καὶ ἦραν τὸ περισσεῦον τῶν κλασμάτων, ἑπτὰ σπυρίδας πλήρεις.
40015038 Οἱ δὲ ἐσθίοντες ἦσαν τετρακισχίλιοι ἄνδρες, χωρὶς γυναικῶν καὶ παιδίων.
40015039 Καὶ ἀπολύσας τοὺς ὄχλους, ἐνέβη εἰς τὸ πλοῖον καὶ ἦλθεν εἰς τὰ ὅρια Μαγαδάν.
40016001 Καὶ προσελθόντες οἱ Φαρισαῖοι καὶ Σαδδουκαῖοι, πειράζοντες ἐπηρώτησαν αὐτὸν σημεῖον ἐκ τοῦ οὐρανοῦ ἐπιδεῖξαι αὐτοῖς.
40016002 Ὁ δὲ ἀποκριθεὶς εἶπεν αὐτοῖς,
40016004 “Γενεὰ πονηρὰ καὶ μοιχαλὶς σημεῖον ἐπιζητεῖ, καὶ σημεῖον οὐ δοθήσεται αὐτῇ, εἰ μὴ τὸ σημεῖον Ἰωνᾶ.” Καὶ καταλιπὼν αὐτοὺς ἀπῆλθεν.
40016005 Καὶ ἐλθόντες οἱ μαθηταὶ εἰς τὸ πέραν, ἐπελάθοντο ἄρτους λαβεῖν.
40016006 Ὁ δὲ ˚Ἰησοῦς εἶπεν αὐτοῖς, “Ὁρᾶτε καὶ προσέχετε ἀπὸ τῆς ζύμης τῶν Φαρισαίων καὶ Σαδδουκαίων.”
40016007 Οἱ δὲ διελογίζοντο ἐν ἑαυτοῖς λέγοντες, ὅτι “Ἄρτους οὐκ ἐλάβομεν.”
40016008 Γνοὺς δὲ, ὁ ˚Ἰησοῦς εἶπεν, “Τί διαλογίζεσθε ἐν ἑαυτοῖς, ὀλιγόπιστοι, ὅτι ἄρτους οὐκ ἔχετε;
40016009 Οὔπω νοεῖτε, οὐδὲ μνημονεύετε τοὺς πέντε ἄρτους τῶν πεντακισχιλίων, καὶ πόσους κοφίνους ἐλάβετε;
40016010 Οὐδὲ τοὺς ἑπτὰ ἄρτους τῶν τετρακισχιλίων, καὶ πόσας σπυρίδας ἐλάβετε;
40016011 Πῶς οὐ νοεῖτε ὅτι οὐ περὶ ἄρτων εἶπον ὑμῖν; Προσέχετε δὲ ἀπὸ τῆς ζύμης τῶν Φαρισαίων καὶ Σαδδουκαίων.”
40016012 Τότε συνῆκαν ὅτι οὐκ εἶπεν προσέχειν ἀπὸ τῆς ζύμης τῶν ἄρτων, ἀλλὰ ἀπὸ τῆς διδαχῆς τῶν Φαρισαίων καὶ Σαδδουκαίων.
40016013 Ἐλθὼν δὲ ὁ ˚Ἰησοῦς εἰς τὰ μέρη Καισαρείας τῆς Φιλίππου, ἠρώτα τοὺς μαθητὰς αὐτοῦ λέγων, “Τίνα λέγουσιν οἱ ἄνθρωποι εἶναι τὸν Υἱὸν τοῦ Ἀνθρώπου;”
40016014 Οἱ δὲ εἶπον, “Οἱ μὲν Ἰωάννην τὸν Βαπτιστήν, ἄλλοι δὲ Ἠλίαν, ἕτεροι δὲ Ἰερεμίαν ἢ ἕνα τῶν προφητῶν.”
40016015 Λέγει αὐτοῖς, “Ὑμεῖς δὲ, τίνα με λέγετε εἶναι;”
40016016 Ἀποκριθεὶς δὲ Σίμων Πέτρος εἶπεν, “Σὺ εἶ ὁ ˚Χριστὸς, ὁ Υἱὸς τοῦ ˚Θεοῦ τοῦ ζῶντος.”
40016017 Ἀποκριθεὶς δὲ ὁ ˚Ἰησοῦς εἶπεν αὐτῷ, “Μακάριος εἶ, Σίμων Βαριωνᾶ, ὅτι σὰρξ καὶ αἷμα οὐκ ἀπεκάλυψέν σοι, ἀλλʼ ὁ Πατήρ μου, ὁ ἐν τοῖς οὐρανοῖς.
40016018 Κἀγὼ δέ σοι λέγω ὅτι σὺ εἶ Πέτρος, καὶ ἐπὶ ταύτῃ τῇ πέτρᾳ οἰκοδομήσω μου τὴν ἐκκλησίαν, καὶ πύλαι ᾅδου οὐ κατισχύσουσιν αὐτῆς.
40016019 Δώσω σοι τὰς κλεῖδας τῆς Βασιλείας τῶν Οὐρανῶν, καὶ ὃ ἐὰν δήσῃς ἐπὶ τῆς γῆς, ἔσται δεδεμένον ἐν τοῖς οὐρανοῖς, καὶ ὃ ἐὰν λύσῃς ἐπὶ τῆς γῆς, ἔσται λελυμένον ἐν τοῖς οὐρανοῖς.”
40016020 Τότε διεστείλατο τοῖς μαθηταῖς ἵνα μηδενὶ εἴπωσιν ὅτι αὐτός ἐστιν ὁ ˚Χριστός.
40016021 Ἀπὸ τότε ἤρξατο ὁ ˚Ἰησοῦς ˚Χριστὸς δεικνύειν τοῖς μαθηταῖς αὐτοῦ ὅτι δεῖ αὐτὸν εἰς Ἱεροσόλυμα ἀπελθεῖν, καὶ πολλὰ παθεῖν ἀπὸ τῶν πρεσβυτέρων, καὶ ἀρχιερέων, καὶ γραμματέων, καὶ ἀποκτανθῆναι καὶ τῇ τρίτῃ ἡμέρᾳ ἐγερθῆναι.
40016022 Καὶ προσλαβόμενος αὐτὸν, ὁ Πέτρος ἤρξατο ἐπιτιμᾶν αὐτῷ λέγων, “Ἵλεώς σοι, ˚Κύριε· οὐ μὴ ἔσται σοι τοῦτο.”
40016023 Ὁ δὲ στραφεὶς εἶπεν τῷ Πέτρῳ, “Ὕπαγε ὀπίσω μου, Σατανᾶ! Σκάνδαλον εἶ ἐμοῦ, ὅτι οὐ φρονεῖς τὰ τοῦ ˚Θεοῦ, ἀλλὰ τὰ τῶν ἀνθρώπων.”
40016024 Τότε ὁ ˚Ἰησοῦς εἶπεν τοῖς μαθηταῖς αὐτοῦ, “Εἴ τις θέλει ὀπίσω μου ἐλθεῖν, ἀπαρνησάσθω ἑαυτὸν, καὶ ἀράτω τὸν σταυρὸν αὐτοῦ, καὶ ἀκολουθείτω μοι.
40016025 Ὃς γὰρ ἐὰν θέλῃ τὴν ψυχὴν αὐτοῦ σῶσαι, ἀπολέσει αὐτήν· ὃς δʼ ἂν ἀπολέσῃ τὴν ψυχὴν αὐτοῦ ἕνεκεν ἐμοῦ, εὑρήσει αὐτήν.
40016026 Τί γὰρ ὠφεληθήσεται ἄνθρωπος, ἐὰν τὸν κόσμον ὅλον κερδήσῃ, τὴν δὲ ψυχὴν αὐτοῦ ζημιωθῇ; Ἢ τί δώσει ἄνθρωπος ἀντάλλαγμα τῆς ψυχῆς αὐτοῦ;
40016027 Μέλλει γὰρ ὁ Υἱὸς τοῦ Ἀνθρώπου ἔρχεσθαι ἐν τῇ δόξῃ τοῦ Πατρὸς αὐτοῦ μετὰ τῶν ἀγγέλων αὐτοῦ, καὶ ‘τότε ἀποδώσει ἑκάστῳ κατὰ τὴν πρᾶξιν αὐτοῦ’.
40016028 Ἀμὴν, λέγω ὑμῖν ὅτι εἰσίν τινες τῶν ὧδε ἑστώτων οἵτινες οὐ μὴ γεύσωνται θανάτου, ἕως ἂν ἴδωσιν τὸν Υἱὸν τοῦ Ἀνθρώπου ἐρχόμενον ἐν τῇ βασιλείᾳ αὐτοῦ.”
40017001 Καὶ μεθʼ ἡμέρας ἓξ, παραλαμβάνει ὁ ˚Ἰησοῦς τὸν Πέτρον, καὶ Ἰάκωβον, καὶ Ἰωάννην, τὸν ἀδελφὸν αὐτοῦ, καὶ ἀναφέρει αὐτοὺς εἰς ὄρος ὑψηλὸν κατʼ ἰδίαν.
40017002 Καὶ μετεμορφώθη ἔμπροσθεν αὐτῶν, καὶ ἔλαμψεν τὸ πρόσωπον αὐτοῦ ὡς ὁ ἥλιος, τὰ δὲ ἱμάτια αὐτοῦ ἐγένετο λευκὰ ὡς τὸ φῶς.
40017003 Καὶ ἰδοὺ, ὤφθη αὐτοῖς Μωϋσῆς καὶ Ἠλίας συλλαλοῦντες μετʼ αὐτοῦ.
40017004 Ἀποκριθεὶς δὲ, ὁ Πέτρος εἶπεν τῷ ˚Ἰησοῦ, “˚Κύριε, καλόν ἐστιν ἡμᾶς ὧδε εἶναι. Εἰ θέλεις, ποιήσω ὧδε τρεῖς σκηνάς, σοὶ μίαν, καὶ Μωϋσεῖ μίαν, καὶ Ἠλίᾳ μίαν.”
40017005 Ἔτι αὐτοῦ λαλοῦντος, ἰδοὺ, νεφέλη φωτεινὴ ἐπεσκίασεν αὐτούς, καὶ ἰδοὺ, φωνὴ ἐκ τῆς νεφέλης λέγουσα, “Οὗτός ἐστιν ὁ Υἱός μου, ὁ ἀγαπητός ἐν ᾧ εὐδόκησα· ἀκούετε αὐτοῦ!”
40017006 Καὶ ἀκούσαντες, οἱ μαθηταὶ ἔπεσαν ἐπὶ πρόσωπον αὐτῶν καὶ ἐφοβήθησαν σφόδρα.
40017007 Καὶ προσῆλθεν ὁ ˚Ἰησοῦς καὶ ἁψάμενος αὐτῶν εἶπεν, “Ἐγέρθητε καὶ μὴ φοβεῖσθε.”
40017008 Ἐπάραντες δὲ τοὺς ὀφθαλμοὺς αὐτῶν, οὐδένα εἶδον εἰ μὴ αὐτὸν ˚Ἰησοῦν μόνον.
40017009 Καὶ καταβαινόντων αὐτῶν ἐκ τοῦ ὄρους, ἐνετείλατο αὐτοῖς ὁ ˚Ἰησοῦς λέγων, “Μηδενὶ εἴπητε τὸ ὅραμα, ἕως οὗ ὁ Υἱὸς τοῦ Ἀνθρώπου ἐκ νεκρῶν ἀναστῇ.”
40017010 Καὶ ἐπηρώτησαν αὐτὸν οἱ μαθηταὶ αὐτοῦ λέγοντες, “Τί οὖν οἱ γραμματεῖς λέγουσιν ὅτι Ἠλίαν δεῖ ἐλθεῖν πρῶτον;”
40017011 Ὁ δὲ ἀποκριθεὶς εἶπεν, “Ἠλίας μὲν ἔρχεται καὶ ἀποκαταστήσει πάντα·
40017012 λέγω δὲ ὑμῖν ὅτι Ἠλίας ἤδη ἦλθεν καὶ οὐκ ἐπέγνωσαν αὐτὸν, ἀλλὰ ἐποίησαν ἐν αὐτῷ ὅσα ἠθέλησαν. Οὕτως καὶ ὁ Υἱὸς τοῦ Ἀνθρώπου μέλλει πάσχειν ὑπʼ αὐτῶν.”
40017013 Τότε συνῆκαν οἱ μαθηταὶ ὅτι περὶ Ἰωάννου τοῦ Βαπτιστοῦ εἶπεν αὐτοῖς.
40017014 Καὶ ἐλθόντων πρὸς τὸν ὄχλον, προσῆλθεν αὐτῷ ἄνθρωπος γονυπετῶν αὐτὸν
40017015 καὶ λέγων, “˚Κύριε, ἐλέησόν μου τὸν υἱόν, ὅτι σεληνιάζεται καὶ κακῶς ἔχει, πολλάκις γὰρ πίπτει εἰς τὸ πῦρ, καὶ πολλάκις εἰς τὸ ὕδωρ.
40017016 Καὶ προσήνεγκα αὐτὸν τοῖς μαθηταῖς σου, καὶ οὐκ ἠδυνήθησαν αὐτὸν θεραπεῦσαι.”
40017017 Ἀποκριθεὶς δὲ ὁ ˚Ἰησοῦς εἶπεν, “Ὦ γενεὰ ἄπιστος καὶ διεστραμμένη, ἕως πότε μεθʼ ὑμῶν ἔσομαι; Ἕως πότε ἀνέξομαι ὑμῶν; Φέρετέ μοι αὐτὸν ὧδε.”
40017018 Καὶ ἐπετίμησεν αὐτῷ ὁ ˚Ἰησοῦς, καὶ ἐξῆλθεν ἀπʼ αὐτοῦ τὸ δαιμόνιον, καὶ ἐθεραπεύθη ὁ παῖς ἀπὸ τῆς ὥρας ἐκείνης.
40017019 Τότε προσελθόντες οἱ μαθηταὶ τῷ ˚Ἰησοῦ κατʼ ἰδίαν εἶπον, “Διὰ τί ἡμεῖς οὐκ ἠδυνήθημεν ἐκβαλεῖν αὐτό;”
40017020 Ὁ δὲ λέγει αὐτοῖς, “Διὰ τὴν ὀλιγοπιστίαν ὑμῶν. Ἀμὴν, γὰρ λέγω ὑμῖν, ἐὰν ἔχητε πίστιν ὡς κόκκον σινάπεως, ἐρεῖτε τῷ ὄρει τούτῳ, ‘Μετάβα ἔνθεν ἐκεῖ’, καὶ μεταβήσεται, καὶ οὐδὲν ἀδυνατήσει ὑμῖν.”
40017022 Συστρεφομένων δὲ αὐτῶν ἐν τῇ Γαλιλαίᾳ, εἶπεν αὐτοῖς ὁ ˚Ἰησοῦς, “Μέλλει ὁ Υἱὸς τοῦ Ἀνθρώπου παραδίδοσθαι εἰς χεῖρας ἀνθρώπων,
40017023 καὶ ἀποκτενοῦσιν αὐτόν, καὶ τῇ τρίτῃ ἡμέρᾳ ἐγερθήσεται.” Καὶ ἐλυπήθησαν σφόδρα.
40017024 Ἐλθόντων δὲ αὐτῶν εἰς Καφαρναοὺμ, προσῆλθον οἱ τὰ δίδραχμα λαμβάνοντες τῷ Πέτρῳ καὶ εἶπον, “Ὁ διδάσκαλος ὑμῶν οὐ τελεῖ τὰ δίδραχμα;”
40017025 Λέγει, “Ναί.” Καὶ ἐλθόντα εἰς τὴν οἰκίαν, προέφθασεν αὐτὸν ὁ ˚Ἰησοῦς λέγων, “Τί σοι δοκεῖ, Σίμων; Οἱ βασιλεῖς τῆς γῆς, ἀπὸ τίνων λαμβάνουσιν τέλη ἢ κῆνσον; Ἀπὸ τῶν υἱῶν αὐτῶν ἢ ἀπὸ τῶν ἀλλοτρίων;”
40017026 λέγει αὐτῷ ὁ Πέτρος “Ἀπὸ τῶν ἀλλοτρίων.” Εἰπόντος δέ, “Ἀπὸ τῶν ἀλλοτρίων”, ἔφη αὐτῷ ὁ ˚Ἰησοῦς, “Ἄρα γε ἐλεύθεροί εἰσιν οἱ υἱοί.
40017027 Ἵνα δὲ μὴ σκανδαλίσωμεν αὐτούς, πορευθεὶς εἰς θάλασσαν, βάλε ἄγκιστρον καὶ τὸν ἀναβάντα πρῶτον ἰχθὺν ἆρον, καὶ ἀνοίξας τὸ στόμα αὐτοῦ, εὑρήσεις στατῆρα· ἐκεῖνον λαβὼν, δὸς αὐτοῖς ἀντὶ ἐμοῦ καὶ σοῦ.”
40018001 Ἐν ἐκείνῃ τῇ ὥρᾳ προσῆλθον οἱ μαθηταὶ τῷ ˚Ἰησοῦ λέγοντες, “Τίς ἄρα μείζων ἐστὶν ἐν τῇ Βασιλείᾳ τῶν Οὐρανῶν;”
40018002 Καὶ προσκαλεσάμενος παιδίον, ἔστησεν αὐτὸ ἐν μέσῳ αὐτῶν
40018003 καὶ εἶπεν, “Ἀμὴν, λέγω ὑμῖν, ἐὰν μὴ στραφῆτε καὶ γένησθε ὡς τὰ παιδία, οὐ μὴ εἰσέλθητε εἰς τὴν Βασιλείαν τῶν Οὐρανῶν.
40018004 Ὅστις οὖν ταπεινώσει ἑαυτὸν ὡς τὸ παιδίον τοῦτο, οὗτός ἐστιν ὁ μείζων ἐν τῇ Βασιλείᾳ τῶν Οὐρανῶν.
40018005 Καὶ ὃς ἐὰν δέξηται ἓν παιδίον τοιοῦτο ἐπὶ τῷ ὀνόματί μου, ἐμὲ δέχεται.
40018006 Ὃς δʼ ἂν σκανδαλίσῃ ἕνα τῶν μικρῶν τούτων τῶν πιστευόντων εἰς ἐμέ, συμφέρει αὐτῷ ἵνα κρεμασθῇ μύλος ὀνικὸς περὶ τὸν τράχηλον αὐτοῦ, καὶ καταποντισθῇ ἐν τῷ πελάγει τῆς θαλάσσης.
40018007 Οὐαὶ τῷ κόσμῳ ἀπὸ τῶν σκανδάλων! Ἀνάγκη γὰρ ἐστιν ἐλθεῖν τὰ σκάνδαλα, πλὴν οὐαὶ τῷ ἀνθρώπῳ ἐκείνῳ διʼ οὗ τὸ σκάνδαλον ἔρχεται!
40018008 Εἰ δὲ ἡ χείρ σου ἢ ὁ πούς σου σκανδαλίζει σε, ἔκκοψον αὐτὸν καὶ βάλε ἀπὸ σοῦ· καλόν σοί ἐστιν εἰσελθεῖν εἰς τὴν ζωὴν κυλλὸν ἢ χωλόν, ἢ δύο χεῖρας ἢ δύο πόδας ἔχοντα, βληθῆναι εἰς τὸ πῦρ τὸ αἰώνιον.
40018009 Καὶ εἰ ὁ ὀφθαλμός σου σκανδαλίζει σε, ἔξελε αὐτὸν καὶ βάλε ἀπὸ σοῦ· καλόν σοί ἐστιν μονόφθαλμον εἰς τὴν ζωὴν εἰσελθεῖν, ἢ δύο ὀφθαλμοὺς ἔχοντα βληθῆναι εἰς τὴν Γέενναν τοῦ πυρός.
40018010 Ὁρᾶτε μὴ καταφρονήσητε ἑνὸς τῶν μικρῶν τούτων, λέγω γὰρ ὑμῖν ὅτι οἱ ἄγγελοι αὐτῶν ἐν οὐρανοῖς, διὰ παντὸς βλέπουσι τὸ πρόσωπον τοῦ Πατρός μου, τοῦ ἐν οὐρανοῖς.
40018012 Τί ὑμῖν δοκεῖ; Ἐὰν γένηταί τινι ἀνθρώπῳ ἑκατὸν πρόβατα, καὶ πλανηθῇ ἓν ἐξ αὐτῶν, οὐχὶ ἀφεὶς τὰ ἐνενήκοντα ἐννέα ἐπὶ τὰ ὄρη, καὶ πορευθεὶς ζητεῖ τὸ πλανώμενον;
40018013 Καὶ ἐὰν γένηται εὑρεῖν αὐτό, ἀμὴν, λέγω ὑμῖν ὅτι χαίρει ἐπʼ αὐτῷ μᾶλλον ἢ ἐπὶ τοῖς ἐνενήκοντα ἐννέα τοῖς μὴ πεπλανημένοις.
40018014 Οὕτως οὐκ ἔστιν θέλημα ἔμπροσθεν τοῦ Πατρὸς ὑμῶν, τοῦ ἐν οὐρανοῖς, ἵνα ἀπόληται ἓν τῶν μικρῶν τούτων.
40018015 Ἐὰν δὲ ἁμαρτήσῃ ὁ ἀδελφός σου, ὕπαγε ἔλεγξον αὐτὸν μεταξὺ σοῦ καὶ αὐτοῦ μόνου. Ἐάν σου ἀκούσῃ, ἐκέρδησας τὸν ἀδελφόν σου.
40018016 Ἐὰν δὲ μὴ ἀκούσῃ, παράλαβε μετὰ σοῦ ἔτι, ἕνα ἢ δύο, ἵνα ‘ἐπὶ στόματος δύο μαρτύρων ἢ τριῶν, σταθῇ πᾶν ῥῆμα’.
40018017 Ἐὰν δὲ παρακούσῃ αὐτῶν, εἰπὲ τῇ ἐκκλησίᾳ. Ἐὰν δὲ καὶ τῆς ἐκκλησίας παρακούσῃ, ἔστω σοι ὥσπερ ὁ ἐθνικὸς καὶ ὁ τελώνης.
40018018 Ἀμὴν, λέγω ὑμῖν, ὅσα ἂν δήσητε ἐπὶ τῆς γῆς, ἔσται δεδεμένα ἐν οὐρανῷ, καὶ ὅσα ἐὰν λύσητε ἐπὶ τῆς γῆς, ἔσται λελυμένα ἐν οὐρανῷ.
40018019 Πάλιν ἀμὴν, λέγω ὑμῖν ὅτι ἐὰν δύο συμφωνήσωσιν ἐξ ὑμῶν ἐπὶ τῆς γῆς περὶ παντὸς πράγματος, οὗ ἐὰν αἰτήσωνται, γενήσεται αὐτοῖς παρὰ τοῦ Πατρός μου, τοῦ ἐν οὐρανοῖς.
40018020 Οὗ γάρ εἰσιν δύο ἢ τρεῖς συνηγμένοι εἰς τὸ ἐμὸν ὄνομα, ἐκεῖ εἰμι ἐν μέσῳ αὐτῶν.”
40018021 Τότε προσελθὼν, ὁ Πέτρος εἶπεν αὐτῷ, “˚Κύριε, ποσάκις ἁμαρτήσει εἰς ἐμὲ ὁ ἀδελφός μου, καὶ ἀφήσω αὐτῷ; Ἕως ἑπτάκις;”
40018022 Λέγει αὐτῷ ὁ ˚Ἰησοῦς, “Οὐ λέγω σοι ἕως ἑπτάκις, ἀλλὰ ἕως ἑβδομηκοντάκις ἑπτά!
40018023 Διὰ τοῦτο ὡμοιώθη ἡ Βασιλεία τῶν Οὐρανῶν ἀνθρώπῳ βασιλεῖ, ὃς ἠθέλησεν συνᾶραι λόγον μετὰ τῶν δούλων αὐτοῦ.
40018024 Ἀρξαμένου δὲ αὐτοῦ συναίρειν, προσήχθη εἷς αὐτῷ ὀφειλέτης μυρίων ταλάντων.
40018025 Μὴ ἔχοντος δὲ αὐτοῦ ἀποδοῦναι, ἐκέλευσεν αὐτὸν ὁ κύριος πραθῆναι, καὶ τὴν γυναῖκα, καὶ τὰ τέκνα, καὶ πάντα ὅσα εἶχεν, καὶ ἀποδοθῆναι.
40018026 Πεσὼν οὖν ὁ δοῦλος προσεκύνει αὐτῷ λέγων, ‘Κύριε, μακροθύμησον ἐπʼ ἐμοί, καὶ πάντα ἀποδώσω σοι.’
40018027 Σπλαγχνισθεὶς δὲ, ὁ κύριος τοῦ δούλου ἐκείνου ἀπέλυσεν αὐτόν, καὶ τὸ δάνειον ἀφῆκεν αὐτῷ.
40018028 Ἐξελθὼν δὲ, ὁ δοῦλος ἐκεῖνος εὗρεν ἕνα τῶν συνδούλων αὐτοῦ, ὃς ὤφειλεν αὐτῷ ἑκατὸν δηνάρια, καὶ κρατήσας αὐτὸν ἔπνιγεν λέγων, ‘Ἀπόδος εἴ τι ὀφείλεις.’
40018029 Πεσὼν οὖν, ὁ σύνδουλος αὐτοῦ παρεκάλει αὐτὸν λέγων, ‘Μακροθύμησον ἐπʼ ἐμοί, καὶ ἀποδώσω σοι.’
40018030 Ὁ δὲ οὐκ ἤθελεν, ἀλλὰ ἀπελθὼν, ἔβαλεν αὐτὸν εἰς φυλακὴν, ἕως ἀποδῷ τὸ ὀφειλόμενον.
40018031 Ἰδόντες οὖν οἱ σύνδουλοι αὐτοῦ τὰ γενόμενα, ἐλυπήθησαν σφόδρα, καὶ ἐλθόντες διεσάφησαν τῷ κυρίῳ ἑαυτῶν πάντα τὰ γενόμενα.
40018032 Τότε προσκαλεσάμενος αὐτὸν ὁ κύριος αὐτοῦ λέγει αὐτῷ, ‘Δοῦλε πονηρέ, πᾶσαν τὴν ὀφειλὴν ἐκείνην ἀφῆκά σοι, ἐπεὶ παρεκάλεσάς με.
40018033 Οὐκ ἔδει καὶ σὲ ἐλεῆσαι τὸν σύνδουλόν σου, ὡς κἀγὼ σὲ ἠλέησα;’
40018034 Καὶ ὀργισθεὶς, ὁ κύριος αὐτοῦ παρέδωκεν αὐτὸν τοῖς βασανισταῖς, ἕως οὗ ἀποδῷ πᾶν τὸ ὀφειλόμενον αὑτῷ.
40018035 Οὕτως καὶ ὁ Πατήρ μου ὁ οὐράνιος ποιήσει ὑμῖν, ἐὰν μὴ ἀφῆτε ἕκαστος τῷ ἀδελφῷ αὐτοῦ ἀπὸ τῶν καρδιῶν ὑμῶν.”
40019001 Καὶ ἐγένετο, ὅτε ἐτέλεσεν ὁ ˚Ἰησοῦς τοὺς λόγους τούτους, μετῆρεν ἀπὸ τῆς Γαλιλαίας καὶ ἦλθεν εἰς τὰ ὅρια τῆς Ἰουδαίας πέραν τοῦ Ἰορδάνου.
40019002 Καὶ ἠκολούθησαν αὐτῷ ὄχλοι πολλοί, καὶ ἐθεράπευσεν αὐτοὺς ἐκεῖ.
40019003 Καὶ προσῆλθον αὐτῷ Φαρισαῖοι, πειράζοντες αὐτὸν καὶ λέγοντες, “Εἰ ἔξεστιν ἀπολῦσαι τὴν γυναῖκα αὐτοῦ κατὰ πᾶσαν αἰτίαν;”
40019004 Ὁ δὲ ἀποκριθεὶς εἶπεν, “Οὐκ ἀνέγνωτε ὅτι ὁ ποιήσας ἀπʼ ἀρχῆς, ‘ἄρσεν καὶ θῆλυ, ἐποίησεν αὐτοὺς’;
40019005 Καὶ εἶπεν, ‘Ἕνεκα τούτου καταλείψει ἄνθρωπος τὸν πατέρα καὶ τὴν μητέρα, καὶ κολληθήσεται τῇ γυναικὶ αὐτοῦ, καὶ ἔσονται οἱ δύο εἰς σάρκα μίαν’;
40019006 Ὥστε οὐκέτι εἰσὶν δύο, ἀλλὰ σὰρξ μία. Ὃ οὖν ὁ ˚Θεὸς συνέζευξεν, ἄνθρωπος μὴ χωριζέτω.”
40019007 Λέγουσιν αὐτῷ, “Τί οὖν Μωϋσῆς ἐνετείλατο ‘δοῦναι βιβλίον ἀποστασίου καὶ ἀπολῦσαι αὐτήν’;”
40019008 Λέγει αὐτοῖς, ὅτι “Μωϋσῆς πρὸς τὴν σκληροκαρδίαν ὑμῶν, ἐπέτρεψεν ὑμῖν ἀπολῦσαι τὰς γυναῖκας ὑμῶν, ἀπʼ ἀρχῆς δὲ οὐ γέγονεν οὕτως.
40019009 Λέγω δὲ ὑμῖν ὅτι ὃς ἂν ἀπολύσῃ τὴν γυναῖκα αὐτοῦ μὴ ἐπὶ πορνείᾳ, καὶ γαμήσῃ ἄλλην, μοιχᾶται. Καὶ ὁ ἀπολελυμένην γαμῶν μοιχᾶται.”
40019010 Λέγουσιν αὐτῷ οἱ μαθηταί, “Εἰ οὕτως ἐστὶν ἡ αἰτία τοῦ ἀνθρώπου μετὰ τῆς γυναικός, οὐ συμφέρει γαμῆσαι.”
40019011 Ὁ δὲ εἶπεν αὐτοῖς, “Οὐ πάντες χωροῦσιν τὸν λόγον τοῦτον, ἀλλʼ οἷς δέδοται.
40019012 εἰσὶν γὰρ εὐνοῦχοι, οἵτινες ἐκ κοιλίας μητρὸς ἐγεννήθησαν οὕτως, καὶ εἰσὶν εὐνοῦχοι οἵτινες εὐνουχίσθησαν ὑπὸ τῶν ἀνθρώπων, καὶ εἰσὶν εὐνοῦχοι οἵτινες εὐνούχισαν ἑαυτοὺς διὰ τὴν Βασιλείαν τῶν Οὐρανῶν. Ὁ δυνάμενος χωρεῖν, χωρείτω.”
40019013 Τότε προσηνέχθησαν αὐτῷ παιδία, ἵνα τὰς χεῖρας ἐπιθῇ αὐτοῖς καὶ προσεύξηται· οἱ δὲ μαθηταὶ ἐπετίμησαν αὐτοῖς.
40019014 Ὁ δὲ ˚Ἰησοῦς εἶπεν, “Ἄφετε τὰ παιδία καὶ μὴ κωλύετε αὐτὰ ἐλθεῖν πρός με, τῶν γὰρ τοιούτων ἐστὶν ἡ Βασιλεία τῶν Οὐρανῶν.”
40019015 Καὶ ἐπιθεὶς τὰς χεῖρας αὐτοῖς, ἐπορεύθη ἐκεῖθεν.
40019016 Καὶ ἰδοὺ, εἷς προσελθὼν αὐτῷ εἶπεν, “Διδάσκαλε, τί ἀγαθὸν ποιήσω, ἵνα σχῶ ζωὴν αἰώνιον;”
40019017 Ὁ δὲ εἶπεν αὐτῷ, “Τί με ἐρωτᾷς περὶ τοῦ ἀγαθοῦ; Εἷς ἐστιν ὁ ἀγαθός. Εἰ δὲ θέλεις εἰς τὴν ζωὴν εἰσελθεῖν, τήρει τὰς ἐντολάς.”
40019018 Λέγει αὐτῷ, “Ποίας;” Ὁ δὲ ˚Ἰησοῦς εἶπεν, τὸ “Οὐ φονεύσεις, οὐ μοιχεύσεις, οὐ κλέψεις, οὐ ψευδομαρτυρήσεις”,
40019019 “Τίμα τὸν πατέρα καὶ τὴν μητέρα”, καὶ, “Ἀγαπήσεις τὸν πλησίον σου ὡς σεαυτόν.”
40019020 Λέγει αὐτῷ ὁ νεανίσκος, “Πάντα Ταῦτα ἐφύλαξα· τί ἔτι ὑστερῶ;”
40019021 Ἔφη αὐτῷ ὁ ˚Ἰησοῦς, “Εἰ θέλεις τέλειος εἶναι, ὕπαγε πώλησόν σου τὰ ὑπάρχοντα, καὶ δὸς πτωχοῖς, καὶ ἕξεις θησαυρὸν ἐν οὐρανοῖς, καὶ δεῦρο, ἀκολούθει μοι.”
40019022 Ἀκούσας δὲ, ὁ νεανίσκος τὸν λόγον, ἀπῆλθεν λυπούμενος, ἦν γὰρ ἔχων κτήματα πολλά.
40019023 Ὁ δὲ ˚Ἰησοῦς εἶπεν τοῖς μαθηταῖς αὐτοῦ, “Ἀμὴν, λέγω ὑμῖν ὅτι πλούσιος δυσκόλως εἰσελεύσεται εἰς τὴν Βασιλείαν τῶν Οὐρανῶν.
40019024 Πάλιν δὲ λέγω ὑμῖν, εὐκοπώτερόν ἐστιν κάμηλον διὰ τρήματος ῥαφίδος διελθεῖν ἢ πλούσιον εἰσελθεῖν εἰς τὴν Βασιλείαν τοῦ ˚Θεοῦ.”
40019025 Ἀκούσαντες δὲ, οἱ μαθηταὶ ἐξεπλήσσοντο σφόδρα λέγοντες, “Τίς ἄρα δύναται σωθῆναι;”
40019026 Ἐμβλέψας δὲ ὁ ˚Ἰησοῦς εἶπεν αὐτοῖς, “Παρὰ ἀνθρώποις τοῦτο ἀδύνατόν ἐστιν, παρὰ δὲ ˚Θεῷ πάντα δυνατά.”
40019027 Τότε ἀποκριθεὶς ὁ Πέτρος εἶπεν αὐτῷ, “Ἰδοὺ, ἡμεῖς ἀφήκαμεν πάντα καὶ ἠκολουθήσαμέν σοι· τί ἄρα ἔσται ἡμῖν;”
40019028 Ὁ δὲ ˚Ἰησοῦς εἶπεν αὐτοῖς, “Ἀμὴν, λέγω ὑμῖν ὅτι ὑμεῖς οἱ ἀκολουθήσαντές μοι ἐν τῇ παλιγγενεσίᾳ, ὅταν καθίσῃ ὁ Υἱὸς τοῦ Ἀνθρώπου ἐπὶ θρόνου δόξης αὐτοῦ, καθήσεσθε καὶ ὑμεῖς ἐπὶ δώδεκα θρόνους, κρίνοντες τὰς δώδεκα φυλὰς τοῦ Ἰσραήλ.
40019029 Καὶ πᾶς ὅστις ἀφῆκεν οἰκίας, ἢ ἀδελφοὺς, ἢ ἀδελφὰς, ἢ πατέρα, ἢ μητέρα, ἢ γυναῖκα, ἢ τέκνα, ἢ ἀγροὺς, ἕνεκεν τοῦ ἐμοῦ ὀνόματος, ἑκατονταπλασίονα λήμψεται καὶ ζωὴν αἰώνιον κληρονομήσει.
40019030 Πολλοὶ δὲ ἔσονται πρῶτοι ἔσχατοι, καὶ ἔσχατοι πρῶτοι.
40020001 Ὁμοία γάρ ἐστιν ἡ Βασιλεία τῶν Οὐρανῶν ἀνθρώπῳ, οἰκοδεσπότῃ, ὅστις ἐξῆλθεν ἅμα πρωῒ μισθώσασθαι ἐργάτας εἰς τὸν ἀμπελῶνα αὐτοῦ.
40020002 Συμφωνήσας δὲ μετὰ τῶν ἐργατῶν ἐκ δηναρίου τὴν ἡμέραν, ἀπέστειλεν αὐτοὺς εἰς τὸν ἀμπελῶνα αὐτοῦ.
40020003 Καὶ ἐξελθὼν περὶ τρίτην ὥραν, εἶδεν ἄλλους ἑστῶτας ἐν τῇ ἀγορᾷ ἀργούς·
40020004 καὶ ἐκείνοις εἶπεν, ‘Ὑπάγετε καὶ ὑμεῖς εἰς τὸν ἀμπελῶνα, καὶ ὃ ἐὰν ᾖ δίκαιον, δώσω ὑμῖν.’
40020005 Οἱ δὲ ἀπῆλθον. Πάλιν ἐξελθὼν περὶ ἕκτην καὶ ἐνάτην ὥραν, ἐποίησεν ὡσαύτως.
40020006 Περὶ δὲ τὴν ἑνδεκάτην ἐξελθὼν, εὗρεν ἄλλους ἑστῶτας, καὶ λέγει αὐτοῖς, ‘Τί ὧδε ἑστήκατε ὅλην τὴν ἡμέραν ἀργοί;’
40020007 Λέγουσιν αὐτῷ, ‘Ὅτι οὐδεὶς ἡμᾶς ἐμισθώσατο.’ Λέγει αὐτοῖς, ‘Ὑπάγετε καὶ ὑμεῖς εἰς τὸν ἀμπελῶνα.’
40020008 Ὀψίας δὲ γενομένης λέγει ὁ κύριος τοῦ ἀμπελῶνος τῷ ἐπιτρόπῳ αὐτοῦ, ‘Κάλεσον τοὺς ἐργάτας καὶ ἀπόδος αὐτοῖς τὸν μισθὸν, ἀρξάμενος ἀπὸ τῶν ἐσχάτων ἕως τῶν πρώτων.’
40020009 Καὶ ἐλθόντες οἱ περὶ τὴν ἑνδεκάτην ὥραν, ἔλαβον ἀνὰ δηνάριον.
40020010 Καὶ ἐλθόντες οἱ πρῶτοι ἐνόμισαν ὅτι πλεῖον λήμψονται, καὶ ἔλαβον ἀνὰ δηνάριον καὶ αὐτοί.
40020011 Λαβόντες δὲ, ἐγόγγυζον κατὰ τοῦ οἰκοδεσπότου
40020012 λέγοντες, ‘Οὗτοι οἱ ἔσχατοι μίαν ὥραν ἐποίησαν, καὶ ἴσους ἡμῖν αὐτοὺς ἐποίησας, τοῖς βαστάσασιν τὸ βάρος τῆς ἡμέρας καὶ τὸν καύσωνα.’
40020013 Ὁ δὲ ἀποκριθεὶς ἑνὶ αὐτῶν εἶπεν, ‘Ἑταῖρε, οὐκ ἀδικῶ σε. Οὐχὶ δηναρίου συνεφώνησάς μοι;
40020014 Ἆρον τὸ σὸν καὶ ὕπαγε. Θέλω δὲ τούτῳ τῷ ἐσχάτῳ δοῦναι ὡς καὶ σοί.
40020015 Ἢ οὐκ ἔξεστίν μοι, ὃ θέλω ποιῆσαι ἐν τοῖς ἐμοῖς; Ἢ ὁ ὀφθαλμός σου πονηρός ἐστιν, ὅτι ἐγὼ ἀγαθός εἰμι;’
40020016 Οὕτως ἔσονται οἱ ἔσχατοι πρῶτοι, καὶ οἱ πρῶτοι ἔσχατοι.”
40020017 Καὶ ἀναβαίνων ὁ ˚Ἰησοῦς εἰς Ἱεροσόλυμα, παρέλαβεν τοὺς δώδεκα μαθητὰς κατʼ ἰδίαν, καὶ ἐν τῇ ὁδῷ εἶπεν αὐτοῖς,
40020018 “Ἰδοὺ, ἀναβαίνομεν εἰς Ἱεροσόλυμα, καὶ ὁ Υἱὸς τοῦ Ἀνθρώπου παραδοθήσεται τοῖς ἀρχιερεῦσιν καὶ γραμματεῦσιν, καὶ κατακρινοῦσιν αὐτὸν θανάτῳ,
40020019 καὶ παραδώσουσιν αὐτὸν τοῖς ἔθνεσιν, εἰς τὸ ἐμπαῖξαι, καὶ μαστιγῶσαι, καὶ σταυρῶσαι, καὶ τῇ τρίτῃ ἡμέρᾳ ἀναστήσεται.”
40020020 Τότε προσῆλθεν αὐτῷ ἡ μήτηρ τῶν υἱῶν Ζεβεδαίου μετὰ τῶν υἱῶν αὐτῆς, προσκυνοῦσα καὶ αἰτοῦσά τι παρʼ αὐτοῦ.
40020021 Ὁ δὲ εἶπεν αὐτῇ, “Τί θέλεις;” Λέγει αὐτῷ, “Εἰπὲ ἵνα καθίσωσιν οὗτοι οἱ δύο υἱοί μου, εἷς ἐκ δεξιῶν καὶ εἷς ἐξ εὐωνύμων σου ἐν τῇ βασιλείᾳ σου.”
40020022 Ἀποκριθεὶς δὲ ὁ ˚Ἰησοῦς εἶπεν, “Οὐκ οἴδατε τί αἰτεῖσθε. Δύνασθε πιεῖν τὸ ποτήριον ὃ ἐγὼ μέλλω πίνειν;” Λέγουσιν αὐτῷ, “Δυνάμεθα.”
40020023 Λέγει αὐτοῖς, “Τὸ μὲν ποτήριόν μου πίεσθε, τὸ δὲ καθίσαι ἐκ δεξιῶν μου καὶ ἐξ εὐωνύμων, οὐκ ἔστιν ἐμὸν δοῦναι, ἀλλʼ οἷς ἡτοίμασται ὑπὸ τοῦ Πατρός μου.”
40020024 Καὶ ἀκούσαντες, οἱ δέκα ἠγανάκτησαν περὶ τῶν δύο ἀδελφῶν.
40020025 Ὁ δὲ ˚Ἰησοῦς προσκαλεσάμενος αὐτοὺς εἶπεν, “Οἴδατε ὅτι οἱ ἄρχοντες τῶν ἐθνῶν κατακυριεύουσιν αὐτῶν, καὶ οἱ μεγάλοι κατεξουσιάζουσιν αὐτῶν.
40020026 Οὐχ οὕτως ἔσται ἐν ὑμῖν· ἀλλʼ ὃς ἐὰν θέλῃ ἐν ὑμῖν μέγας γενέσθαι, ἔσται ὑμῶν διάκονος,
40020027 καὶ ὃς ἂν θέλῃ ἐν ὑμῖν εἶναι πρῶτος, ἔσται ὑμῶν δοῦλος·
40020028 ὥσπερ ὁ Υἱὸς τοῦ Ἀνθρώπου οὐκ ἦλθεν διακονηθῆναι, ἀλλὰ διακονῆσαι καὶ δοῦναι τὴν ψυχὴν αὐτοῦ λύτρον ἀντὶ πολλῶν.”
40020029 Καὶ ἐκπορευομένων αὐτῶν ἀπὸ Ἰεριχὼ, ἠκολούθησεν αὐτῷ ὄχλος πολύς.
40020030 Καὶ ἰδοὺ, δύο τυφλοὶ καθήμενοι παρὰ τὴν ὁδόν, ἀκούσαντες ὅτι ˚Ἰησοῦς παράγει, ἔκραξαν λέγοντες, ἐλέησον ἡμᾶς, ˚Κύριε, Υἱὲ Δαυίδ.
40020031 Ὁ δὲ ὄχλος ἐπετίμησεν αὐτοῖς ἵνα σιωπήσωσιν. Οἱ δὲ μεῖζον ἔκραξαν λέγοντες, “˚Κύριε, ἐλέησον ἡμᾶς, Υἱὸς Δαυίδ.”
40020032 Καὶ στὰς, ὁ ˚Ἰησοῦς ἐφώνησεν αὐτοὺς καὶ εἶπεν, “Τί θέλετε ποιήσω ὑμῖν;”
40020033 Λέγουσιν αὐτῷ, “˚Κύριε, ἵνα ἀνοιγῶσιν οἱ ὀφθαλμοὶ ἡμῶν.”
40020034 Σπλαγχνισθεὶς δὲ, ὁ ˚Ἰησοῦς ἥψατο τῶν ὀφθαλμῶν αὐτῶν, καὶ εὐθέως ἀνέβλεψαν καὶ ἠκολούθησαν αὐτῷ.
40021001 Καὶ ὅτε ἤγγισαν εἰς Ἱεροσόλυμα καὶ ἦλθον εἰς Βηθφαγὴ πρὸς τὸ Ὄρος τῶν Ἐλαιῶν, τότε ˚Ἰησοῦς ἀπέστειλεν δύο μαθητὰς
40021002 λέγων αὐτοῖς, “Πορεύεσθε εἰς τὴν κώμην, τὴν κατέναντι ὑμῶν, καὶ εὐθέως εὑρήσετε ὄνον δεδεμένην καὶ πῶλον μετʼ αὐτῆς· λύσαντες ἀγάγετέ μοι.
40021003 Καὶ ἐάν τις ὑμῖν εἴπῃ τι, ἐρεῖτε, ὅτι ‘Ὁ ˚Κύριος αὐτῶν χρείαν ἔχει’, εὐθὺς δὲ ἀποστελεῖ αὐτούς.”
40021004 Τοῦτο δὲ ὅλον γέγονεν, ἵνα πληρωθῇ τὸ ῥηθὲν διὰ τοῦ προφήτου λέγοντος,
40021005 “Εἴπατε τῇ θυγατρὶ Σιών, ‘Ἰδοὺ, ὁ βασιλεύς σου ἔρχεταί σοι, πραῢς καὶ ἐπιβεβηκὼς ἐπὶ ὄνον καὶ ἐπὶ πῶλον, υἱὸν ὑποζυγίου.’”
40021006 Πορευθέντες δὲ οἱ μαθηταὶ, καὶ ποιήσαντες καθὼς συνέταξεν αὐτοῖς ὁ ˚Ἰησοῦς,
40021007 ἤγαγον τὴν ὄνον καὶ τὸν πῶλον, καὶ ἐπέθηκαν ἐπʼ αὐτῶν τὰ ἱμάτια, καὶ ἐπεκάθισεν ἐπάνω αὐτῶν.
40021008 Ὁ δὲ πλεῖστος ὄχλος ἔστρωσαν ἑαυτῶν τὰ ἱμάτια ἐν τῇ ὁδῷ, ἄλλοι δὲ ἔκοπτον κλάδους ἀπὸ τῶν δένδρων, καὶ ἐστρώννυον ἐν τῇ ὁδῷ.
40021009 Οἱ δὲ ὄχλοι οἱ προάγοντες αὐτὸν καὶ οἱ ἀκολουθοῦντες ἔκραζον λέγοντες, “Ὡσαννὰ τῷ Υἱῷ Δαυίδ! ‘Εὐλογημένος ὁ ἐρχόμενος ἐν ὀνόματι ˚Κυρίου!’ Ὡσαννὰ ἐν τοῖς ὑψίστοις!”
40021010 Καὶ εἰσελθόντος αὐτοῦ εἰς Ἱεροσόλυμα, ἐσείσθη πᾶσα ἡ πόλις λέγουσα, “Τίς ἐστιν οὗτος;”
40021011 Οἱ δὲ ὄχλοι ἔλεγον, “Οὗτός ἐστιν ὁ προφήτης ˚Ἰησοῦς, ὁ ἀπὸ Ναζαρὲθ τῆς Γαλιλαίας.”
40021012 Καὶ εἰσῆλθεν ˚Ἰησοῦς εἰς τὸ ἱερόν, καὶ ἐξέβαλεν πάντας τοὺς πωλοῦντας καὶ ἀγοράζοντας ἐν τῷ ἱερῷ, καὶ τὰς τραπέζας τῶν κολλυβιστῶν κατέστρεψεν, καὶ τὰς καθέδρας τῶν πωλούντων τὰς περιστεράς.
40021013 Καὶ λέγει αὐτοῖς, “Γέγραπται, ‘Ὁ οἶκός μου οἶκος προσευχῆς κληθήσεται’, ὑμεῖς δὲ αὐτὸν ποιεῖτε ‘σπήλαιον λῃστῶν’.”
40021014 Καὶ προσῆλθον αὐτῷ, τυφλοὶ καὶ χωλοὶ ἐν τῷ ἱερῷ, καὶ ἐθεράπευσεν αὐτούς.
40021015 Ἰδόντες δὲ οἱ ἀρχιερεῖς καὶ οἱ γραμματεῖς, τὰ θαυμάσια ἃ ἐποίησεν, καὶ τοὺς παῖδας τοὺς κράζοντας ἐν τῷ ἱερῷ καὶ λέγοντας, “Ὡσαννὰ τῷ Υἱῷ Δαυίδ”, ἠγανάκτησαν
40021016 καὶ εἶπαν αὐτῷ, “Ἀκούεις τί οὗτοι λέγουσιν;” Ὁ δὲ ˚Ἰησοῦς λέγει αὐτοῖς, “Ναί, οὐδέποτε ἀνέγνωτε, ὅτι ‘Ἐκ στόματος νηπίων καὶ θηλαζόντων, κατηρτίσω αἶνον’;”
40021017 Καὶ καταλιπὼν αὐτοὺς, ἐξῆλθεν ἔξω τῆς πόλεως εἰς Βηθανίαν, καὶ ηὐλίσθη ἐκεῖ.
40021018 Πρωῒ δὲ ἐπανάγων εἰς τὴν πόλιν, ἐπείνασεν.
40021019 Καὶ ἰδὼν συκῆν μίαν ἐπὶ τῆς ὁδοῦ, ἦλθεν ἐπʼ αὐτήν καὶ οὐδὲν εὗρεν ἐν αὐτῇ, εἰ μὴ φύλλα μόνον, καὶ λέγει αὐτῇ, “Μηκέτι ἐκ σοῦ καρπὸς γένηται εἰς τὸν αἰῶνα.” Καὶ ἐξηράνθη παραχρῆμα ἡ συκῆ.
40021020 Καὶ ἰδόντες οἱ μαθηταὶ ἐθαύμασαν λέγοντες, “Πῶς παραχρῆμα ἐξηράνθη ἡ συκῆ;”
40021021 Ἀποκριθεὶς δὲ, ὁ ˚Ἰησοῦς εἶπεν αὐτοῖς, “Ἀμὴν, λέγω ὑμῖν, ἐὰν ἔχητε πίστιν καὶ μὴ διακριθῆτε, οὐ μόνον τὸ τῆς συκῆς ποιήσετε, ἀλλὰ κἂν τῷ ὄρει τούτῳ εἴπητε, ‘Ἄρθητι καὶ βλήθητι εἰς τὴν θάλασσαν’, γενήσεται.
40021022 Καὶ πάντα ὅσα ἂν αἰτήσητε ἐν τῇ προσευχῇ πιστεύοντες, λήμψεσθε.”
40021023 Καὶ ἐλθόντος αὐτοῦ εἰς τὸ ἱερὸν, προσῆλθον αὐτῷ διδάσκοντι οἱ ἀρχιερεῖς καὶ οἱ πρεσβύτεροι τοῦ λαοῦ λέγοντες, “Ἐν ποίᾳ ἐξουσίᾳ ταῦτα ποιεῖς; Καὶ τίς σοι ἔδωκεν τὴν ἐξουσίαν ταύτην;”
40021024 Ἀποκριθεὶς δὲ, ὁ ˚Ἰησοῦς εἶπεν αὐτοῖς, “Ἐρωτήσω ὑμᾶς κἀγὼ λόγον ἕνα, ὃν ἐὰν εἴπητέ μοι, κἀγὼ ὑμῖν ἐρῶ ἐν ποίᾳ ἐξουσίᾳ ταῦτα ποιῶ.
40021025 Τὸ βάπτισμα τὸ Ἰωάννου, πόθεν ἦν; Ἐξ οὐρανοῦ ἢ ἐξ ἀνθρώπων;” Οἱ δὲ διελογίζοντο παρʼ ἑαυτοῖς λέγοντες, “Ἐὰν εἴπωμεν, ‘Ἐξ οὐρανοῦ’, ἐρεῖ ἡμῖν, ‘Διὰ τί οὖν οὐκ ἐπιστεύσατε αὐτῷ;’
40021026 Ἐὰν δὲ εἴπωμεν, ‘Ἐξ ἀνθρώπων’, φοβούμεθα τὸν ὄχλον, πάντες γὰρ ὡς προφήτην ἔχουσιν τὸν Ἰωάννην.”
40021027 Καὶ ἀποκριθέντες τῷ ˚Ἰησοῦ, εἶπον, “Οὐκ οἴδαμεν.” Ἔφη αὐτοῖς καὶ αὐτός, “Οὐδὲ ἐγὼ λέγω ὑμῖν ἐν ποίᾳ ἐξουσίᾳ ταῦτα ποιῶ.
40021028 Τί δὲ ὑμῖν δοκεῖ; Ἄνθρωπος εἶχεν τέκνα δύο. Καὶ προσελθὼν τῷ πρώτῳ εἶπεν, ‘Τέκνον, ὕπαγε σήμερον, ἐργάζου ἐν τῷ ἀμπελῶνι.’ μου.’
40021029 Ὁ δὲ ἀποκριθεὶς εἶπεν, ‘Οὐ θέλω’· ὕστερον μεταμεληθεὶς, ἀπῆλθεν.
40021030 Προσελθὼν δὲ τῷ ἑτέρῳ εἶπεν ὡσαύτως. Ὁ δὲ ἀποκριθεὶς εἶπεν, ‘Ἐγώ, κύριε’· καὶ οὐκ ἀπῆλθεν.
40021031 Τίς ἐκ τῶν δύο ἐποίησεν τὸ θέλημα τοῦ πατρός;” Λέγουσιν, “Ὁ πρῶτος.” Λέγει αὐτοῖς ὁ ˚Ἰησοῦς, “Ἀμὴν, λέγω ὑμῖν ὅτι οἱ τελῶναι καὶ αἱ πόρναι προάγουσιν ὑμᾶς εἰς τὴν Βασιλείαν τοῦ ˚Θεοῦ.
40021032 Ἦλθεν γὰρ Ἰωάννης πρὸς ὑμᾶς ἐν ὁδῷ δικαιοσύνης, καὶ οὐκ ἐπιστεύσατε αὐτῷ, οἱ δὲ τελῶναι καὶ αἱ πόρναι ἐπίστευσαν αὐτῷ· ὑμεῖς δὲ ἰδόντες, οὐ μετεμελήθητε ὕστερον τοῦ πιστεῦσαι αὐτῷ.
40021033 Ἄλλην παραβολὴν ἀκούσατε: ἄνθρωπος ἦν οἰκοδεσπότης, ὅστις ἐφύτευσεν ἀμπελῶνα, καὶ φραγμὸν αὐτῷ περιέθηκεν, ‘καὶ ὤρυξεν ἐν αὐτῷ ληνὸν, καὶ ᾠκοδόμησεν πύργον’, καὶ ἐξέδετο αὐτὸν γεωργοῖς, καὶ ἀπεδήμησεν.
40021034 Ὅτε δὲ ἤγγισεν ὁ καιρὸς τῶν καρπῶν, ἀπέστειλεν τοὺς δούλους αὐτοῦ πρὸς τοὺς γεωργοὺς, λαβεῖν τοὺς καρποὺς αὐτοῦ.
40021035 Καὶ λαβόντες οἱ γεωργοὶ τοὺς δούλους αὐτοῦ, ὃν μὲν ἔδειραν, ὃν δὲ ἀπέκτειναν, ὃν δὲ ἐλιθοβόλησαν.
40021036 Πάλιν ἀπέστειλεν ἄλλους δούλους, πλείονας τῶν πρώτων, καὶ ἐποίησαν αὐτοῖς ὡσαύτως.
40021037 Ὕστερον δὲ ἀπέστειλεν πρὸς αὐτοὺς τὸν υἱὸν αὐτοῦ λέγων, ‘Ἐντραπήσονται τὸν υἱόν μου.’
40021038 Οἱ δὲ γεωργοὶ ἰδόντες τὸν υἱὸν, εἶπον ἐν ἑαυτοῖς, ‘Οὗτός ἐστιν ὁ κληρονόμος· δεῦτε, ἀποκτείνωμεν αὐτὸν καὶ σχῶμεν τὴν κληρονομίαν αὐτοῦ.’
40021039 Καὶ λαβόντες αὐτὸν, ἐξέβαλον ἔξω τοῦ ἀμπελῶνος καὶ ἀπέκτειναν.
40021040 Ὅταν οὖν ἔλθῃ ὁ κύριος τοῦ ἀμπελῶνος, τί ποιήσει τοῖς γεωργοῖς ἐκείνοις;”
40021041 Λέγουσιν αὐτῷ, “Κακοὺς κακῶς ἀπολέσει αὐτούς, καὶ τὸν ἀμπελῶνα ἐκδώσεται ἄλλοις γεωργοῖς, οἵτινες ἀποδώσουσιν αὐτῷ τοὺς καρποὺς ἐν τοῖς καιροῖς αὐτῶν.”
40021042 Λέγει αὐτοῖς ὁ ˚Ἰησοῦς, “Οὐδέποτε ἀνέγνωτε ἐν ταῖς Γραφαῖς, ‘Λίθον ὃν ἀπεδοκίμασαν οἱ οἰκοδομοῦντες, οὗτος ἐγενήθη εἰς κεφαλὴν γωνίας· παρὰ ˚Κυρίου ἐγένετο αὕτη, καὶ ἔστιν θαυμαστὴ ἐν ὀφθαλμοῖς ἡμῶν’;
40021043 Διὰ τοῦτο λέγω ὑμῖν ὅτι ἀρθήσεται ἀφʼ ὑμῶν ἡ Βασιλεία τοῦ ˚Θεοῦ, καὶ δοθήσεται ἔθνει ποιοῦντι τοὺς καρποὺς αὐτῆς.
40021044 Καὶ ὁ πεσὼν ἐπὶ τὸν λίθον τοῦτον, συνθλασθήσεται· ἐφʼ ὃν δʼ ἂν πέσῃ, λικμήσει αὐτόν.”
40021045 Καὶ ἀκούσαντες οἱ ἀρχιερεῖς καὶ οἱ Φαρισαῖοι τὰς παραβολὰς αὐτοῦ, ἔγνωσαν ὅτι περὶ αὐτῶν λέγει.
40021046 Καὶ ζητοῦντες αὐτὸν κρατῆσαι, ἐφοβήθησαν τοὺς ὄχλους, ἐπεὶ εἰς προφήτην αὐτὸν εἶχον.
40022001 Καὶ ἀποκριθεὶς ὁ ˚Ἰησοῦς πάλιν εἶπεν ἐν παραβολαῖς αὐτοῖς λέγων,
40022002 “Ὡμοιώθη ἡ Βασιλεία τῶν Οὐρανῶν ἀνθρώπῳ, βασιλεῖ, ὅστις ἐποίησεν γάμους τῷ υἱῷ αὐτοῦ.
40022003 Καὶ ἀπέστειλεν τοὺς δούλους αὐτοῦ, καλέσαι τοὺς κεκλημένους εἰς τοὺς γάμους, καὶ οὐκ ἤθελον ἐλθεῖν.
40022004 Πάλιν ἀπέστειλεν ἄλλους δούλους λέγων, ‘Εἴπατε τοῖς κεκλημένοις, “Ἰδοὺ, τὸ ἄριστόν μου ἡτοίμακα, οἱ ταῦροί μου καὶ τὰ σιτιστὰ τεθυμένα, καὶ πάντα ἕτοιμα· δεῦτε εἰς τοὺς γάμους.”’
40022005 Οἱ δὲ ἀμελήσαντες ἀπῆλθον, ὃς μὲν εἰς τὸν ἴδιον ἀγρόν, ὃς δὲ ἐπὶ τὴν ἐμπορίαν αὐτοῦ.
40022006 Οἱ δὲ λοιποὶ κρατήσαντες τοὺς δούλους αὐτοῦ, ὕβρισαν καὶ ἀπέκτειναν.
40022007 Ὁ δὲ βασιλεὺς ὠργίσθη, καὶ πέμψας τὰ στρατεύματα αὐτοῦ, ἀπώλεσεν τοὺς φονεῖς ἐκείνους, καὶ τὴν πόλιν αὐτῶν ἐνέπρησεν.
40022008 Τότε λέγει τοῖς δούλοις αὐτοῦ, ‘Ὁ μὲν γάμος ἕτοιμός ἐστιν, οἱ δὲ κεκλημένοι οὐκ ἦσαν ἄξιοι.
40022009 Πορεύεσθε οὖν ἐπὶ τὰς διεξόδους τῶν ὁδῶν, καὶ ὅσους ἐὰν εὕρητε, καλέσατε εἰς τοὺς γάμους.’
40022010 Καὶ ἐξελθόντες, οἱ δοῦλοι ἐκεῖνοι εἰς τὰς ὁδοὺς, συνήγαγον πάντας οὓς εὗρον, πονηρούς τε καὶ ἀγαθούς· καὶ ἐπλήσθη ὁ γάμος ἀνακειμένων.
40022011 Εἰσελθὼν δὲ ὁ βασιλεὺς θεάσασθαι τοὺς ἀνακειμένους, εἶδεν ἐκεῖ ἄνθρωπον οὐκ ἐνδεδυμένον ἔνδυμα γάμου·
40022012 καὶ λέγει αὐτῷ, ‘Ἑταῖρε, πῶς εἰσῆλθες ὧδε μὴ ἔχων ἔνδυμα γάμου;’ Ὁ δὲ ἐφιμώθη.
40022013 Τότε ὁ βασιλεὺς εἶπεν τοῖς διακόνοις, ‘Δήσαντες αὐτοῦ πόδας καὶ χεῖρας, ἐκβάλετε αὐτὸν εἰς τὸ σκότος τὸ ἐξώτερον· ἐκεῖ ἔσται ὁ κλαυθμὸς καὶ ὁ βρυγμὸς τῶν ὀδόντων.’
40022014 Πολλοὶ γάρ εἰσιν κλητοὶ, ὀλίγοι δὲ ἐκλεκτοί.”
40022015 Τότε πορευθέντες, οἱ Φαρισαῖοι συμβούλιον ἔλαβον ὅπως αὐτὸν παγιδεύσωσιν ἐν λόγῳ.
40022016 Καὶ ἀποστέλλουσιν αὐτῷ τοὺς μαθητὰς αὐτῶν μετὰ τῶν Ἡρῳδιανῶν λέγοντας, “Διδάσκαλε, οἴδαμεν ὅτι ἀληθὴς εἶ, καὶ τὴν ὁδὸν τοῦ ˚Θεοῦ ἐν ἀληθείᾳ διδάσκεις, καὶ οὐ μέλει σοι περὶ οὐδενός, οὐ γὰρ βλέπεις εἰς πρόσωπον ἀνθρώπων.
40022017 Εἰπὲ οὖν ἡμῖν, τί σοι δοκεῖ; Ἔξεστιν δοῦναι κῆνσον Καίσαρι ἢ οὔ;”
40022018 Γνοὺς δὲ ὁ ˚Ἰησοῦς τὴν πονηρίαν αὐτῶν εἶπεν, “Τί με πειράζετε, ὑποκριταί;
40022019 Ἐπιδείξατέ μοι τὸ νόμισμα τοῦ κήνσου.” Οἱ δὲ προσήνεγκαν αὐτῷ δηνάριον.
40022020 Καὶ λέγει αὐτοῖς, “Τίνος ἡ εἰκὼν αὕτη καὶ ἡ ἐπιγραφή;”
40022021 Λέγουσιν, “Καίσαρος.” Τότε λέγει αὐτοῖς, “Ἀπόδοτε οὖν τὰ Καίσαρος Καίσαρι, καὶ τὰ τοῦ ˚Θεοῦ τῷ ˚Θεῷ.”
40022022 Καὶ ἀκούσαντες ἐθαύμασαν, καὶ ἀφέντες αὐτὸν ἀπῆλθαν.
40022023 Ἐν ἐκείνῃ τῇ ἡμέρᾳ προσῆλθον αὐτῷ Σαδδουκαῖοι λέγοντες, μὴ εἶναι ἀνάστασιν, καὶ ἐπηρώτησαν αὐτὸν
40022024 λέγοντες, “Διδάσκαλε, Μωϋσῆς εἶπεν, ‘Ἐάν τις ἀποθάνῃ μὴ ἔχων τέκνα, ἐπιγαμβρεύσει ὁ ἀδελφὸς αὐτοῦ τὴν γυναῖκα αὐτοῦ, καὶ ἀναστήσει σπέρμα τῷ ἀδελφῷ αὐτοῦ.’
40022025 Ἦσαν δὲ παρʼ ἡμῖν ἑπτὰ ἀδελφοί· καὶ ὁ πρῶτος γήμας ἐτελεύτησεν, καὶ μὴ ἔχων σπέρμα, ἀφῆκεν τὴν γυναῖκα αὐτοῦ τῷ ἀδελφῷ αὐτοῦ.
40022026 Ὁμοίως καὶ ὁ δεύτερος, καὶ ὁ τρίτος, ἕως τῶν ἑπτά,
40022027 ὕστερον δὲ πάντων, ἀπέθανεν ἡ γυνή.
40022028 Ἐν τῇ ἀναστάσει οὖν, τίνος τῶν ἑπτὰ ἔσται γυνή; Πάντες γὰρ ἔσχον αὐτήν.”
40022029 Ἀποκριθεὶς δὲ, ὁ ˚Ἰησοῦς εἶπεν αὐτοῖς, “Πλανᾶσθε, μὴ εἰδότες τὰς Γραφὰς, μηδὲ τὴν δύναμιν τοῦ ˚Θεοῦ.
40022030 Ἐν γὰρ τῇ ἀναστάσει, οὔτε γαμοῦσιν οὔτε γαμίζονται, ἀλλʼ ὡς ἄγγελοι ἐν τῷ οὐρανῷ εἰσιν.
40022031 Περὶ δὲ τῆς ἀναστάσεως τῶν νεκρῶν, οὐκ ἀνέγνωτε τὸ ῥηθὲν ὑμῖν ὑπὸ τοῦ ˚Θεοῦ λέγοντος,
40022032 ‘Ἐγώ εἰμι ὁ ˚Θεὸς Ἀβραὰμ, καὶ ὁ ˚Θεὸς Ἰσαὰκ, καὶ ὁ ˚Θεὸς Ἰακώβ’; Οὐκ ἔστιν ὁ ˚Θεὸς νεκρῶν, ἀλλὰ ζώντων.”
40022033 Καὶ ἀκούσαντες, οἱ ὄχλοι ἐξεπλήσσοντο ἐπὶ τῇ διδαχῇ αὐτοῦ.
40022034 Οἱ δὲ Φαρισαῖοι ἀκούσαντες ὅτι ἐφίμωσεν τοὺς Σαδδουκαίους, συνήχθησαν ἐπὶ τὸ αὐτό.
40022035 Καὶ ἐπηρώτησεν εἷς ἐξ αὐτῶν, νομικὸς πειράζων αὐτόν,
40022036 “Διδάσκαλε, ποία ἐντολὴ μεγάλη ἐν τῷ νόμῳ;”
40022037 Ὁ δὲ ἔφη αὐτῷ, “‘Ἀγαπήσεις ˚Κύριον τὸν ˚Θεόν σου ἐν ὅλῃ καρδίᾳ σου, καὶ ἐν ὅλῃ ψυχῇ σου, καὶ ἐν ὅλῃ τῇ διανοίᾳ σου.’
40022038 Αὕτη ἐστὶν ἡ μεγάλη καὶ πρώτη ἐντολή.
40022039 Δευτέρα ὁμοία αὐτῇ, ‘Ἀγαπήσεις τὸν πλησίον σου ὡς σεαυτόν.’
40022040 Ἐν ταύταις ταῖς δυσὶν ἐντολαῖς, ὅλος ὁ νόμος κρέμαται καὶ οἱ προφῆται.”
40022041 Συνηγμένων δὲ τῶν Φαρισαίων, ἐπηρώτησεν αὐτοὺς ὁ ˚Ἰησοῦς
40022042 λέγων, “Τί ὑμῖν δοκεῖ περὶ τοῦ ˚Χριστοῦ; Τίνος υἱός ἐστιν;” Λέγουσιν αὐτῷ, “Τοῦ Δαυίδ.”
40022043 Λέγει αὐτοῖς, “Πῶς οὖν Δαυὶδ ἐν ˚Πνεύματι καλεῖ ˚Κύριον αὐτὸν λέγων,
40022044 ‘Εἶπεν ˚Κύριος τῷ ˚Κυρίῳ μου, “Κάθου ἐκ δεξιῶν μου, ἕως ἂν θῶ τοὺς ἐχθρούς σου ὑποκάτω τῶν ποδῶν σου”’;
40022045 Εἰ οὖν Δαυὶδ καλεῖ αὐτὸν, ‘˚Κύριον’, πῶς υἱὸς αὐτοῦ ἐστιν;”
40022046 Καὶ οὐδεὶς ἐδύνατο ἀποκριθῆναι αὐτῷ λόγον, οὐδὲ ἐτόλμησέν τις ἀπʼ ἐκείνης τῆς ἡμέρας ἐπερωτῆσαι αὐτὸν οὐκέτι.
40023001 Τότε ὁ ˚Ἰησοῦς ἐλάλησεν τοῖς ὄχλοις καὶ τοῖς μαθηταῖς αὐτοῦ
40023002 λέγων, “Ἐπὶ τῆς Μωϋσέως καθέδρας ἐκάθισαν οἱ γραμματεῖς καὶ οἱ Φαρισαῖοι.
40023003 Πάντα οὖν ὅσα ἂν εἴπωσιν ὑμῖν, ποιήσατε καὶ τηρεῖτε, κατὰ δὲ τὰ ἔργα αὐτῶν, μὴ ποιεῖτε, λέγουσιν γὰρ καὶ οὐ ποιοῦσιν.
40023004 Δεσμεύουσιν δὲ φορτία βαρέα καὶ δυσβάστακτα, καὶ ἐπιτιθέασιν ἐπὶ τοὺς ὤμους τῶν ἀνθρώπων, αὐτοὶ δὲ τῷ δακτύλῳ αὐτῶν οὐ θέλουσιν κινῆσαι αὐτά.
40023005 Πάντα δὲ τὰ ἔργα αὐτῶν, ποιοῦσιν πρὸς τὸ θεαθῆναι τοῖς ἀνθρώποις. Πλατύνουσι γὰρ τὰ φυλακτήρια αὐτῶν καὶ μεγαλύνουσι τὰ κράσπεδα.
40023006 Φιλοῦσι δὲ τὴν πρωτοκλισίαν ἐν τοῖς δείπνοις, καὶ τὰς πρωτοκαθεδρίας ἐν ταῖς συναγωγαῖς,
40023007 καὶ τοὺς ἀσπασμοὺς ἐν ταῖς ἀγοραῖς, καὶ καλεῖσθαι ὑπὸ τῶν ἀνθρώπων, ‘Ῥαββί’.
40023008 Ὑμεῖς δὲ μὴ κληθῆτε ‘Ῥαββί’, εἷς γάρ ἐστιν ὑμῶν ὁ καθηγητὴς, πάντες δὲ ὑμεῖς ἀδελφοί ἐστε.
40023009 Καὶ ‘Πατέρα’ μὴ καλέσητε ὑμῶν ἐπὶ τῆς γῆς, εἷς γάρ ἐστιν ὑμῶν ὁ Πατὴρ ὁ οὐράνιος.
40023010 Μηδὲ κληθῆτε ‘Καθηγηταί’, ὅτι καθηγητὴς ὑμῶν ἐστιν εἷς, ὁ ˚Χριστός.
40023011 Ὁ δὲ μείζων ὑμῶν ἔσται ὑμῶν διάκονος.
40023012 Ὅστις δὲ ὑψώσει ἑαυτὸν, ταπεινωθήσεται, καὶ ὅστις ταπεινώσει ἑαυτὸν, ὑψωθήσεται.
40023013 Οὐαὶ δὲ ὑμῖν, γραμματεῖς καὶ Φαρισαῖοι, ὑποκριταί! Ὅτι κλείετε τὴν Βασιλείαν τῶν Οὐρανῶν ἔμπροσθεν τῶν ἀνθρώπων· ὑμεῖς γὰρ οὐκ εἰσέρχεσθε, οὐδὲ τοὺς εἰσερχομένους ἀφίετε εἰσελθεῖν.
40023015 Οὐαὶ ὑμῖν, γραμματεῖς καὶ Φαρισαῖοι, ὑποκριταί! Ὅτι περιάγετε τὴν θάλασσαν καὶ τὴν ξηρὰν ποιῆσαι ἕνα προσήλυτον, καὶ ὅταν γένηται, ποιεῖτε αὐτὸν υἱὸν Γεέννης διπλότερον ὑμῶν.
40023016 Οὐαὶ ὑμῖν, ὁδηγοὶ τυφλοὶ, οἱ λέγοντες, ‘Ὃς ἂν ὀμόσῃ ἐν τῷ ναῷ, οὐδέν ἐστιν, ὃς δʼ ἂν ὀμόσῃ ἐν τῷ χρυσῷ τοῦ ναοῦ ὀφείλει.’
40023017 Μωροὶ καὶ τυφλοί! Τίς γὰρ μείζων ἐστίν, ὁ χρυσὸς ἢ ὁ ναὸς ὁ ἁγιάσας τὸν χρυσόν;
40023018 Καί, ‘Ὃς ἂν ὀμόσῃ ἐν τῷ θυσιαστηρίῳ, οὐδέν ἐστιν, ὃς δʼ ἂν ὀμόσῃ ἐν τῷ δώρῳ τῷ ἐπάνω αὐτοῦ ὀφείλει.’
40023019 Μωροὶ καὶ τυφλοί! Τί γὰρ μεῖζον, τὸ δῶρον, ἢ τὸ θυσιαστήριον τὸ ἁγιάζον τὸ δῶρον;
40023020 Ὁ οὖν ὀμόσας ἐν τῷ θυσιαστηρίῳ, ὀμνύει ἐν αὐτῷ καὶ ἐν πᾶσι τοῖς ἐπάνω αὐτοῦ.
40023021 Καὶ ὁ ὀμόσας ἐν τῷ ναῷ, ὀμνύει ἐν αὐτῷ καὶ ἐν τῷ κατοικοῦντι αὐτόν.
40023022 Καὶ ὁ ὀμόσας ἐν τῷ οὐρανῷ, ὀμνύει ἐν τῷ θρόνῳ τοῦ ˚Θεοῦ καὶ ἐν τῷ καθημένῳ ἐπάνω αὐτοῦ.
40023023 Οὐαὶ ὑμῖν, γραμματεῖς καὶ Φαρισαῖοι, ὑποκριταί! Ὅτι ἀποδεκατοῦτε τὸ ἡδύοσμον, καὶ τὸ ἄνηθον, καὶ τὸ κύμινον, καὶ ἀφήκατε τὰ βαρύτερα τοῦ νόμου: τὴν κρίσιν, καὶ τὸ ἔλεος, καὶ τὴν πίστιν. Ταῦτα δὲ ἔδει ποιῆσαι, κἀκεῖνα μὴ ἀφεῖναι.
40023024 Ὁδηγοὶ τυφλοί! Οἱ διϋλίζοντες τὸν κώνωπα τὴν δὲ κάμηλον καταπίνοντες!
40023025 Οὐαὶ ὑμῖν, γραμματεῖς καὶ Φαρισαῖοι, ὑποκριταί! Ὅτι καθαρίζετε τὸ ἔξωθεν τοῦ ποτηρίου καὶ τῆς παροψίδος, ἔσωθεν δὲ γέμουσιν ἐξ ἁρπαγῆς καὶ ἀκρασίας.
40023026 Φαρισαῖε τυφλέ! Καθάρισον πρῶτον τὸ ἐντὸς τοῦ ποτηρίου καὶ τῆς παροψίδος, ἵνα γένηται καὶ τὸ ἐκτὸς αὐτῶν καθαρόν.
40023027 Οὐαὶ ὑμῖν, γραμματεῖς καὶ Φαρισαῖοι, ὑποκριταί! Ὅτι παρομοιάζετε τάφοις κεκονιαμένοις, οἵτινες ἔξωθεν μὲν φαίνονται ὡραῖοι, ἔσωθεν δὲ γέμουσιν ὀστέων νεκρῶν καὶ πάσης ἀκαθαρσίας.
40023028 Οὕτως καὶ ὑμεῖς ἔξωθεν μὲν φαίνεσθε τοῖς ἀνθρώποις δίκαιοι, ἔσωθεν δέ ἐστε μεστοὶ ὑποκρίσεως καὶ ἀνομίας.
40023029 Οὐαὶ ὑμῖν, γραμματεῖς καὶ Φαρισαῖοι, ὑποκριταί! Ὅτι οἰκοδομεῖτε τοὺς τάφους τῶν προφητῶν, καὶ κοσμεῖτε τὰ μνημεῖα τῶν δικαίων
40023030 καὶ λέγετε, ‘Εἰ ἤμεθα ἐν ταῖς ἡμέραις τῶν πατέρων ἡμῶν, οὐκ ἂν ἤμεθα κοινωνοὶ αὐτῶν ἐν τῷ αἵματι τῶν προφητῶν.’
40023031 Ὥστε μαρτυρεῖτε ἑαυτοῖς ὅτι υἱοί ἐστε τῶν φονευσάντων τοὺς προφήτας.
40023032 Καὶ ὑμεῖς πληρώσατε τὸ μέτρον τῶν πατέρων ὑμῶν.
40023033 Ὄφεις, γεννήματα ἐχιδνῶν, πῶς φύγητε ἀπὸ τῆς κρίσεως τῆς Γεέννης;
40023034 Διὰ τοῦτο, ἰδοὺ, ἐγὼ ἀποστέλλω πρὸς ὑμᾶς προφήτας, καὶ σοφοὺς, καὶ γραμματεῖς. Ἐξ αὐτῶν ἀποκτενεῖτε καὶ σταυρώσετε, καὶ ἐξ αὐτῶν μαστιγώσετε ἐν ταῖς συναγωγαῖς ὑμῶν, καὶ διώξετε ἀπὸ πόλεως εἰς πόλιν·
40023035 ὅπως ἔλθῃ ἐφʼ ὑμᾶς πᾶν αἷμα δίκαιον ἐκχυννόμενον ἐπὶ τῆς γῆς, ἀπὸ τοῦ αἵματος Ἅβελ τοῦ δικαίου, ἕως τοῦ αἵματος Ζαχαρίου υἱοῦ Βαραχίου, ὃν ἐφονεύσατε μεταξὺ τοῦ ναοῦ καὶ τοῦ θυσιαστηρίου.
40023036 Ἀμὴν, λέγω ὑμῖν, ἥξει πάντα ταῦτα ἐπὶ τὴν γενεὰν ταύτην.
40023037 Ἰερουσαλὴμ, Ἰερουσαλήμ, ἡ ἀποκτείνουσα τοὺς προφήτας καὶ λιθοβολοῦσα, τοὺς ἀπεσταλμένους πρὸς αὐτήν! Ποσάκις ἠθέλησα ἐπισυναγαγεῖν τὰ τέκνα σου, ὃν τρόπον ὄρνις ἐπισυνάγει τὰ νοσσία αὐτῆς ὑπὸ τὰς πτέρυγας, καὶ οὐκ ἠθελήσατε.
40023038 Ἰδοὺ, ἀφίεται ὑμῖν ὁ οἶκος ὑμῶν ἔρημος!
40023039 Λέγω γὰρ ὑμῖν, οὐ μή με ἴδητε ἀπʼ ἄρτι ἕως ἂν εἴπητε, ‘Εὐλογημένος ὁ ἐρχόμενος ἐν ὀνόματι ˚Κυρίου!’”
40024001 Καὶ ἐξελθὼν ὁ ˚Ἰησοῦς ἀπὸ τοῦ ἱεροῦ ἐπορεύετο, καὶ προσῆλθον οἱ μαθηταὶ αὐτοῦ, ἐπιδεῖξαι αὐτῷ τὰς οἰκοδομὰς τοῦ ἱεροῦ.
40024002 Ὁ δὲ ἀποκριθεὶς εἶπεν αὐτοῖς, “Οὐ βλέπετε ταῦτα πάντα; Ἀμὴν, λέγω ὑμῖν, οὐ μὴ ἀφεθῇ ὧδε λίθος ἐπὶ λίθον, ὃς οὐ καταλυθήσεται.”
40024003 Καθημένου δὲ αὐτοῦ ἐπὶ τοῦ Ὄρους τῶν Ἐλαιῶν, προσῆλθον αὐτῷ οἱ μαθηταὶ κατʼ ἰδίαν λέγοντες, “Εἰπὲ ἡμῖν πότε ταῦτα ἔσται, καὶ τί τὸ σημεῖον τῆς σῆς παρουσίας καὶ συντελείας τοῦ αἰῶνος;”
40024004 Καὶ ἀποκριθεὶς ὁ ˚Ἰησοῦς εἶπεν αὐτοῖς, “Βλέπετε, μή τις ὑμᾶς πλανήσῃ.
40024005 Πολλοὶ γὰρ ἐλεύσονται ἐπὶ τῷ ὀνόματί μου λέγοντες, ‘Ἐγώ εἰμι ὁ ˚Χριστός’, καὶ πολλοὺς πλανήσουσιν.
40024006 Μελλήσετε δὲ ἀκούειν πολέμους, καὶ ἀκοὰς πολέμων. Ὁρᾶτε, μὴ θροεῖσθε· δεῖ γὰρ γενέσθαι, ἀλλʼ οὔπω ἐστὶν τὸ τέλος.
40024007 Ἐγερθήσεται γὰρ ἔθνος ἐπὶ ἔθνος, καὶ βασιλεία ἐπὶ βασιλείαν, καὶ ἔσονται λιμοὶ καὶ σεισμοὶ κατὰ τόπους.
40024008 Πάντα δὲ ταῦτα ἀρχὴ ὠδίνων.
40024009 Τότε παραδώσουσιν ὑμᾶς εἰς θλῖψιν, καὶ ἀποκτενοῦσιν ὑμᾶς. Καὶ ἔσεσθε μισούμενοι ὑπὸ πάντων τῶν ἐθνῶν, διὰ τὸ ὄνομά μου.
40024010 Καὶ τότε σκανδαλισθήσονται πολλοὶ, καὶ ἀλλήλους παραδώσουσιν καὶ μισήσουσιν ἀλλήλους.
40024011 Καὶ πολλοὶ ψευδοπροφῆται ἐγερθήσονται καὶ πλανήσουσιν πολλούς.
40024012 Καὶ διὰ τὸ πληθυνθῆναι τὴν ἀνομίαν, ψυγήσεται ἡ ἀγάπη τῶν πολλῶν.
40024013 Ὁ δὲ ὑπομείνας εἰς τέλος, οὗτος σωθήσεται.
40024014 Καὶ κηρυχθήσεται τοῦτο τὸ εὐαγγέλιον τῆς βασιλείας ἐν ὅλῃ τῇ οἰκουμένῃ, εἰς μαρτύριον πᾶσιν τοῖς ἔθνεσιν, καὶ τότε ἥξει τὸ τέλος.
40024015 Ὅταν οὖν ἴδητε ‘τὸ βδέλυγμα τῆς ἐρημώσεως’, τὸ ῥηθὲν διὰ Δανιὴλ τοῦ προφήτου, ἑστὸς ἐν τόπῳ ἁγίῳ ( ὁ ἀναγινώσκων νοείτω ),
40024016 τότε οἱ ἐν τῇ Ἰουδαίᾳ, φευγέτωσαν εἰς τὰ ὄρη,
40024017 ὁ ἐπὶ τοῦ δώματος, μὴ καταβάτω ἆραι τὰ ἐκ τῆς οἰκίας αὐτοῦ,
40024018 καὶ ὁ ἐν τῷ ἀγρῷ, μὴ ἐπιστρεψάτω ὀπίσω ἆραι τὸ ἱμάτιον αὐτοῦ.
40024019 Οὐαὶ δὲ ταῖς ἐν γαστρὶ ἐχούσαις, καὶ ταῖς θηλαζούσαις ἐν ἐκείναις ταῖς ἡμέραις.
40024020 Προσεύχεσθε δὲ ἵνα μὴ γένηται ἡ φυγὴ ὑμῶν χειμῶνος, μηδὲ Σαββάτῳ.
40024021 Ἔσται γὰρ τότε θλῖψις μεγάλη, οἵα οὐ γέγονεν ἀπʼ ἀρχῆς κόσμου ἕως τοῦ νῦν, οὐδʼ οὐ μὴ γένηται.
40024022 Καὶ εἰ μὴ ἐκολοβώθησαν αἱ ἡμέραι ἐκεῖναι, οὐκ ἂν ἐσώθη πᾶσα σάρξ, διὰ δὲ τοὺς ἐκλεκτοὺς, κολοβωθήσονται αἱ ἡμέραι ἐκεῖναι.
40024023 Τότε ἐάν τις ὑμῖν εἴπῃ, ‘Ἰδοὺ, ὧδε ὁ ˚Χριστός’, ἤ ‘Ὧδε’, μὴ πιστεύσητε.
40024024 Ἐγερθήσονται γὰρ ψευδόχριστοι καὶ ψευδοπροφῆται, καὶ δώσουσιν σημεῖα μεγάλα καὶ τέρατα, ὥστε πλανῆσαι εἰ δυνατὸν καὶ τοὺς ἐκλεκτούς.
40024025 Ἰδοὺ, προείρηκα ὑμῖν.
40024026 Ἐὰν οὖν εἴπωσιν ὑμῖν, ‘Ἰδοὺ, ἐν τῇ ἐρήμῳ ἐστίν’, μὴ ἐξέλθητε. ‘Ἰδοὺ, ἐν τοῖς ταμείοις’, μὴ πιστεύσητε.
40024027 Ὥσπερ γὰρ ἡ ἀστραπὴ ἐξέρχεται ἀπὸ ἀνατολῶν καὶ φαίνεται ἕως δυσμῶν, οὕτως ἔσται ἡ παρουσία τοῦ Υἱοῦ τοῦ Ἀνθρώπου.
40024028 Ὅπου ἐὰν ᾖ τὸ πτῶμα, ἐκεῖ συναχθήσονται οἱ ἀετοί.
40024029 Εὐθέως δὲ μετὰ τὴν θλῖψιν τῶν ἡμερῶν ἐκείνων, ‘ὁ ἥλιος σκοτισθήσεται καὶ ἡ σελήνη οὐ δώσει τὸ φέγγος αὐτῆς’, ‘καὶ οἱ ἀστέρες πεσοῦνται ἀπὸ τοῦ οὐρανοῦ’, καὶ αἱ δυνάμεις τῶν οὐρανῶν σαλευθήσονται.
40024030 Καὶ τότε φανήσεται τὸ σημεῖον τοῦ Υἱοῦ τοῦ Ἀνθρώπου ἐν οὐρανῷ, καὶ τότε κόψονται πᾶσαι αἱ φυλαὶ τῆς γῆς, καὶ ὄψονται ‘τὸν Υἱὸν τοῦ Ἀνθρώπου, ἐρχόμενον ἐπὶ τῶν νεφελῶν τοῦ οὐρανοῦ’ μετὰ δυνάμεως καὶ δόξης πολλῆς.
40024031 Καὶ ἀποστελεῖ τοὺς ἀγγέλους αὐτοῦ μετὰ ‘σάλπιγγος φωνῆς μεγάλης, καὶ ἐπισυνάξουσιν’ τοὺς ἐκλεκτοὺς αὐτοῦ ἐκ τῶν τεσσάρων ἀνέμων, ἀπʼ ἄκρων οὐρανῶν ἕως ἄκρων αὐτῶν.
40024032 Ἀπὸ δὲ τῆς συκῆς, μάθετε τὴν παραβολήν: ὅταν ἤδη ὁ κλάδος αὐτῆς γένηται ἁπαλὸς καὶ τὰ φύλλα ἐκφύῃ, γινώσκετε ὅτι ἐγγὺς τὸ θέρος.
40024033 Οὕτως καὶ ὑμεῖς ὅταν ἴδητε ταῦτα πάντα, γινώσκετε ὅτι ἐγγύς ἐστιν ἐπὶ θύραις.
40024034 Ἀμὴν, λέγω ὑμῖν ὅτι οὐ μὴ παρέλθῃ ἡ γενεὰ αὕτη, ἕως ἂν πάντα ταῦτα γένηται.
40024035 Ὁ οὐρανὸς καὶ ἡ γῆ παρελεύσεται, οἱ δὲ λόγοι μου οὐ μὴ παρέλθωσιν.
40024036 Περὶ δὲ τῆς ἡμέρας ἐκείνης καὶ ὥρας οὐδεὶς οἶδεν, οὐδὲ οἱ ἄγγελοι τῶν οὐρανῶν, οὐδὲ ὁ Υἱός, εἰ μὴ ὁ Πατὴρ μόνος.
40024037 Ὥσπερ γὰρ αἱ ἡμέραι τοῦ Νῶε, οὕτως ἔσται ἡ παρουσία τοῦ Υἱοῦ τοῦ Ἀνθρώπου.
40024038 Ὡς γὰρ ἦσαν ἐν ταῖς ἡμέραις ταῖς πρὸ τοῦ κατακλυσμοῦ τρώγοντες καὶ πίνοντες, γαμοῦντες καὶ γαμίζοντες, ἄχρι ἧς ἡμέρας εἰσῆλθεν, Νῶε εἰς τὴν κιβωτόν.
40024039 Καὶ οὐκ ἔγνωσαν, ἕως ἦλθεν ὁ κατακλυσμὸς καὶ ἦρεν ἅπαντας, οὕτως ἔσται ἡ παρουσία τοῦ Υἱοῦ τοῦ Ἀνθρώπου.
40024040 Τότε ἔσονται δύο ἐν τῷ ἀγρῷ, εἷς παραλαμβάνεται, καὶ εἷς ἀφίεται.
40024041 Δύο ἀλήθουσαι ἐν τῷ μύλῳ, μία παραλαμβάνεται, καὶ μία ἀφίεται.
40024042 Γρηγορεῖτε οὖν, ὅτι οὐκ οἴδατε ποίᾳ ἡμέρᾳ ὁ ˚Κύριος ὑμῶν ἔρχεται.
40024043 Ἐκεῖνο δὲ γινώσκετε ὅτι εἰ ᾔδει ὁ οἰκοδεσπότης, ποίᾳ φυλακῇ ὁ κλέπτης ἔρχεται, ἐγρηγόρησεν ἂν, καὶ οὐκ ἂν εἴασεν διορυγῆναι τὴν οἰκίαν αὐτοῦ.
40024044 Διὰ τοῦτο καὶ, ὑμεῖς γίνεσθε ἕτοιμοι, ὅτι ᾗ οὐ δοκεῖτε ὥρᾳ, ὁ Υἱὸς τοῦ Ἀνθρώπου ἔρχεται.
40024045 Τίς ἄρα ἐστὶν ὁ πιστὸς δοῦλος καὶ φρόνιμος, ὃν κατέστησεν ὁ κύριος ἐπὶ τῆς οἰκετείας αὐτοῦ, τοῦ δοῦναι αὐτοῖς τὴν τροφὴν ἐν καιρῷ;
40024046 Μακάριος ὁ δοῦλος ἐκεῖνος, ὃν ἐλθὼν ὁ κύριος αὐτοῦ εὑρήσει οὕτως ποιοῦντα.
40024047 Ἀμὴν, λέγω ὑμῖν ὅτι ἐπὶ πᾶσιν τοῖς ὑπάρχουσιν αὐτοῦ, καταστήσει αὐτόν.
40024048 Ἐὰν δὲ εἴπῃ ὁ κακὸς δοῦλος ἐκεῖνος ἐν τῇ καρδίᾳ αὐτοῦ, ‘Χρονίζει μου ὁ κύριος’,
40024049 καὶ ἄρξηται τύπτειν τοὺς συνδούλους αὐτοῦ, ἐσθίῃ δὲ καὶ πίνῃ μετὰ τῶν μεθυόντων,
40024050 ἥξει ὁ κύριος τοῦ δούλου ἐκείνου ἐν ἡμέρᾳ ᾗ οὐ προσδοκᾷ, καὶ ἐν ὥρᾳ ᾗ οὐ γινώσκει,
40024051 καὶ διχοτομήσει αὐτὸν, καὶ τὸ μέρος αὐτοῦ μετὰ τῶν ὑποκριτῶν θήσει· ἐκεῖ ἔσται ὁ κλαυθμὸς καὶ ὁ βρυγμὸς τῶν ὀδόντων.
40025001 Τότε ὁμοιωθήσεται ἡ Βασιλεία τῶν Οὐρανῶν δέκα παρθένοις, αἵτινες λαβοῦσαι τὰς λαμπάδας αὐτῶν ἐξῆλθον εἰς ὑπάντησιν τοῦ νυμφίου.
40025002 Πέντε δὲ ἐξ αὐτῶν ἦσαν μωραὶ, καὶ πέντε φρόνιμοι.
40025003 Αἱ γὰρ μωραὶ λαβοῦσαι τὰς λαμπάδας αὐτῶν, οὐκ ἔλαβον μεθʼ ἑαυτῶν ἔλαιον·
40025004 αἱ δὲ φρόνιμοι ἔλαβον ἔλαιον ἐν τοῖς ἀγγείοις, μετὰ τῶν λαμπάδων ἑαυτῶν.
40025005 Χρονίζοντος δὲ τοῦ νυμφίου, ἐνύσταξαν πᾶσαι καὶ ἐκάθευδον.
40025006 Μέσης δὲ νυκτὸς, κραυγὴ γέγονεν, ‘Ἰδοὺ, ὁ νυμφίος!’ Ἐξέρχεσθε εἰς ἀπάντησιν.
40025007 Τότε ἠγέρθησαν πᾶσαι αἱ παρθένοι ἐκεῖναι, καὶ ἐκόσμησαν τὰς λαμπάδας ἑαυτῶν.
40025008 Αἱ δὲ μωραὶ ταῖς φρονίμοις εἶπον, ‘Δότε ἡμῖν ἐκ τοῦ ἐλαίου ὑμῶν, ὅτι αἱ λαμπάδες ἡμῶν σβέννυνται.’
40025009 Ἀπεκρίθησαν δὲ αἱ φρόνιμοι λέγουσαι, ‘Μήποτε οὐ μὴ ἀρκέσῃ ἡμῖν καὶ ὑμῖν· πορεύεσθε μᾶλλον πρὸς τοὺς πωλοῦντας, καὶ ἀγοράσατε ἑαυταῖς.’
40025010 Ἀπερχομένων δὲ αὐτῶν ἀγοράσαι, ἦλθεν ὁ νυμφίος, καὶ αἱ ἕτοιμοι εἰσῆλθον μετʼ αὐτοῦ εἰς τοὺς γάμους, καὶ ἐκλείσθη ἡ θύρα.
40025011 Ὕστερον δὲ ἔρχονται καὶ αἱ λοιπαὶ παρθένοι λέγουσαι, ‘Κύριε, κύριε, ἄνοιξον ἡμῖν!’
40025012 Ὁ δὲ ἀποκριθεὶς εἶπεν, ‘Ἀμὴν, λέγω ὑμῖν, οὐκ οἶδα ὑμᾶς.’
40025013 Γρηγορεῖτε οὖν, ὅτι οὐκ οἴδατε τὴν ἡμέραν, οὐδὲ τὴν ὥραν.
40025014 Ὥσπερ γὰρ ἄνθρωπος ἀποδημῶν, ἐκάλεσεν τοὺς ἰδίους δούλους, καὶ παρέδωκεν αὐτοῖς τὰ ὑπάρχοντα αὐτοῦ.
40025015 Καὶ ᾧ μὲν ἔδωκεν πέντε τάλαντα, ᾧ δὲ δύο, ᾧ δὲ ἕν, ἑκάστῳ κατὰ τὴν ἰδίαν δύναμιν, καὶ ἀπεδήμησεν εὐθέως.
40025016 Πορευθεὶς, ὁ τὰ πέντε τάλαντα λαβὼν, ἠργάσατο ἐν αὐτοῖς καὶ ἐκέρδησεν ἄλλα πέντε τάλαντα.
40025017 Ὡσαύτως καὶ ὁ τὰ δύο, ἐκέρδησεν ἄλλα δύο.
40025018 Ὁ δὲ τὸ ἓν λαβὼν, ἀπελθὼν ὤρυξεν γῆν καὶ ἔκρυψεν τὸ ἀργύριον τοῦ κυρίου αὐτοῦ.
40025019 Μετὰ δὲ πολὺν χρόνον, ἔρχεται ὁ κύριος τῶν δούλων ἐκείνων, καὶ συναίρει λόγον μετʼ αὐτῶν.
40025020 Καὶ προσελθὼν ὁ, τὰ πέντε τάλαντα λαβὼν, προσήνεγκεν ἄλλα πέντε τάλαντα λέγων, ‘Κύριε, πέντε τάλαντά μοι παρέδωκας, ἴδε, ἄλλα πέντε τάλαντα ἐκέρδησα.’
40025021 Ἔφη αὐτῷ ὁ κύριος αὐτοῦ, ‘Εὖ, δοῦλε ἀγαθὲ καὶ πιστέ! Ἐπὶ ὀλίγα ἦς πιστός, ἐπὶ πολλῶν σε καταστήσω· εἴσελθε εἰς τὴν χαρὰν τοῦ κυρίου σου.’
40025022 Προσελθὼν καὶ ὁ τὰ δύο τάλαντα εἶπεν, ‘Κύριε, δύο τάλαντά μοι παρέδωκας. Ἴδε, ἄλλα δύο τάλαντα ἐκέρδησα.’
40025023 Ἔφη αὐτῷ ὁ κύριος αὐτοῦ, ‘Εὖ, δοῦλε ἀγαθὲ καὶ πιστέ! Ἐπὶ ὀλίγα ἦς πιστός, ἐπὶ πολλῶν σε καταστήσω· εἴσελθε εἰς τὴν χαρὰν τοῦ κυρίου σου.’
40025024 Προσελθὼν δὲ καὶ ὁ τὸ ἓν τάλαντον εἰληφὼς εἶπεν, ‘Κύριε, ἔγνων σε, ὅτι σκληρὸς εἶ ἄνθρωπος, θερίζων ὅπου οὐκ ἔσπειρας, καὶ συνάγων ὅθεν οὐ διεσκόρπισας.
40025025 Καὶ φοβηθεὶς ἀπελθὼν, ἔκρυψα τὸ τάλαντόν σου ἐν τῇ γῇ. Ἴδε, ἔχεις τὸ σόν.’
40025026 Ἀποκριθεὶς δὲ ὁ κύριος αὐτοῦ εἶπεν αὐτῷ, ‘Πονηρὲ Δοῦλε καὶ ὀκνηρέ! Ἤιδεις ὅτι θερίζω ὅπου οὐκ ἔσπειρα, καὶ συνάγω ὅθεν οὐ διεσκόρπισα;
40025027 Ἔδει σε οὖν βαλεῖν τὰ ἀργύριά μου τοῖς τραπεζίταις, καὶ ἐλθὼν, ἐγὼ ἐκομισάμην ἂν τὸ ἐμὸν σὺν τόκῳ.
40025028 Ἄρατε οὖν ἀπʼ αὐτοῦ τὸ τάλαντον, καὶ δότε τῷ ἔχοντι τὰ δέκα τάλαντα.
40025029 Τῷ γὰρ ἔχοντι παντὶ δοθήσεται καὶ περισσευθήσεται, τοῦ δὲ μὴ ἔχοντος, καὶ ὃ ἔχει ἀρθήσεται ἀπʼ αὐτοῦ.
40025030 Καὶ τὸν ἀχρεῖον δοῦλον, ἐκβάλετε εἰς τὸ σκότος τὸ ἐξώτερον. Ἐκεῖ ἔσται ὁ κλαυθμὸς καὶ ὁ βρυγμὸς τῶν ὀδόντων.’
40025031 Ὅταν δὲ ἔλθῃ ὁ Υἱὸς τοῦ Ἀνθρώπου ἐν τῇ δόξῃ αὐτοῦ, καὶ πάντες οἱ ἄγγελοι μετʼ αὐτοῦ, τότε καθίσει ἐπὶ θρόνου δόξης αὐτοῦ.
40025032 Καὶ συναχθήσονται ἔμπροσθεν αὐτοῦ πάντα τὰ ἔθνη, καὶ ἀφορίσει αὐτοὺς ἀπʼ ἀλλήλων, ὥσπερ ὁ ποιμὴν ἀφορίζει τὰ πρόβατα ἀπὸ τῶν ἐρίφων.
40025033 Καὶ στήσει τὰ μὲν πρόβατα ἐκ δεξιῶν αὐτοῦ, τὰ δὲ ἐρίφια ἐξ εὐωνύμων.
40025034 Τότε ἐρεῖ ὁ Βασιλεὺς τοῖς ἐκ δεξιῶν αὐτοῦ, ‘Δεῦτε οἱ εὐλογημένοι τοῦ Πατρός μου, κληρονομήσατε τὴν ἡτοιμασμένην ὑμῖν βασιλείαν ἀπὸ καταβολῆς κόσμου.
40025035 Ἐπείνασα γὰρ καὶ ἐδώκατέ μοι φαγεῖν, ἐδίψησα καὶ ἐποτίσατέ με, ξένος ἤμην καὶ συνηγάγετέ με,
40025036 γυμνὸς καὶ περιεβάλετέ με, ἠσθένησα καὶ ἐπεσκέψασθέ με, ἐν φυλακῇ ἤμην καὶ ἤλθατε πρός με.’
40025037 Τότε ἀποκριθήσονται αὐτῷ οἱ δίκαιοι λέγοντες, ‘˚Κύριε, πότε σε εἴδομεν πεινῶντα καὶ ἐθρέψαμεν, ἢ διψῶντα καὶ ἐποτίσαμεν;
40025038 Πότε δέ σε εἴδομεν ξένον καὶ συνηγάγομεν, ἢ γυμνὸν καὶ περιεβάλομεν;
40025039 Πότε δέ σε εἴδομεν ἀσθενῆ ἢ ἐν φυλακῇ, καὶ ἤλθομεν πρός σε;’
40025040 Καὶ ἀποκριθεὶς ὁ Βασιλεὺς ἐρεῖ αὐτοῖς, ‘Ἀμὴν, λέγω ὑμῖν, ἐφʼ ὅσον ἐποιήσατε ἑνὶ τούτων τῶν ἀδελφῶν μου τῶν ἐλαχίστων, ἐμοὶ ἐποιήσατε.’
40025041 Τότε ἐρεῖ καὶ τοῖς ἐξ εὐωνύμων, ‘Πορεύεσθε ἀπʼ ἐμοῦ κατηραμένοι εἰς τὸ πῦρ τὸ αἰώνιον, τὸ ἡτοιμασμένον τῷ διαβόλῳ καὶ τοῖς ἀγγέλοις αὐτοῦ.
40025042 Ἐπείνασα γὰρ καὶ οὐκ ἐδώκατέ μοι φαγεῖν, καὶ ἐδίψησα καὶ οὐκ ἐποτίσατέ με,
40025043 ξένος ἤμην καὶ οὐ συνηγάγετέ με, γυμνὸς καὶ οὐ περιεβάλετέ με, ἀσθενὴς καὶ ἐν φυλακῇ καὶ οὐκ ἐπεσκέψασθέ με.’
40025044 Τότε ἀποκριθήσονται καὶ αὐτοὶ λέγοντες, ‘˚Κύριε, πότε σε εἴδομεν πεινῶντα, ἢ διψῶντα, ἢ ξένον, ἢ γυμνὸν, ἢ ἀσθενῆ, ἢ ἐν φυλακῇ, καὶ οὐ διηκονήσαμέν σοι;’
40025045 Τότε ἀποκριθήσεται αὐτοῖς λέγων, ‘Ἀμὴν, λέγω ὑμῖν, ἐφʼ ὅσον οὐκ ἐποιήσατε ἑνὶ τούτων τῶν ἐλαχίστων, οὐδὲ ἐμοὶ ἐποιήσατε.’
40025046 Καὶ ἀπελεύσονται οὗτοι εἰς κόλασιν αἰώνιον, οἱ δὲ δίκαιοι εἰς ζωὴν αἰώνιον.”
40026001 Καὶ ἐγένετο ὅτε ἐτέλεσεν ὁ ˚Ἰησοῦς πάντας τοὺς λόγους τούτους, εἶπεν τοῖς μαθηταῖς αὐτοῦ,
40026002 “Οἴδατε ὅτι μετὰ δύο ἡμέρας τὸ Πάσχα γίνεται, καὶ ὁ Υἱὸς τοῦ Ἀνθρώπου παραδίδοται εἰς τὸ σταυρωθῆναι.”
40026003 Τότε συνήχθησαν οἱ ἀρχιερεῖς καὶ οἱ πρεσβύτεροι τοῦ λαοῦ εἰς τὴν αὐλὴν τοῦ ἀρχιερέως, τοῦ λεγομένου Καϊάφα,
40026004 καὶ συνεβουλεύσαντο ἵνα τὸν ˚Ἰησοῦν δόλῳ κρατήσωσιν καὶ ἀποκτείνωσιν.
40026005 Ἔλεγον δέ, “Μὴ ἐν τῇ ἑορτῇ, ἵνα μὴ θόρυβος γένηται ἐν τῷ λαῷ.”
40026006 Τοῦ δὲ ˚Ἰησοῦ γενομένου ἐν Βηθανίᾳ ἐν οἰκίᾳ Σίμωνος τοῦ λεπροῦ,
40026007 προσῆλθεν αὐτῷ γυνὴ ἔχουσα ἀλάβαστρον μύρου βαρυτίμου, καὶ κατέχεεν ἐπὶ τῆς κεφαλῆς αὐτοῦ ἀνακειμένου.
40026008 Ἰδόντες δὲ, οἱ μαθηταὶ ἠγανάκτησαν λέγοντες, “Εἰς τί ἡ ἀπώλεια αὕτη;
40026009 Ἐδύνατο γὰρ τοῦτο πραθῆναι πολλοῦ καὶ δοθῆναι πτωχοῖς.”
40026010 Γνοὺς δὲ, ὁ ˚Ἰησοῦς εἶπεν αὐτοῖς, “Τί κόπους παρέχετε τῇ γυναικί; Ἔργον γὰρ καλὸν ἠργάσατο εἰς ἐμέ.
40026011 Πάντοτε γὰρ τοὺς πτωχοὺς ἔχετε μεθʼ ἑαυτῶν, ἐμὲ δὲ οὐ πάντοτε ἔχετε.
40026012 Βαλοῦσα γὰρ αὕτη τὸ μύρον τοῦτο ἐπὶ τοῦ σώματός μου, πρὸς τὸ ἐνταφιάσαι με ἐποίησεν.
40026013 Ἀμὴν, λέγω ὑμῖν, ὅπου ἐὰν κηρυχθῇ τὸ εὐαγγέλιον τοῦτο ἐν ὅλῳ τῷ κόσμῳ, λαληθήσεται καὶ ὃ ἐποίησεν αὕτη εἰς μνημόσυνον αὐτῆς.”
40026014 Τότε πορευθεὶς εἷς τῶν δώδεκα, ὁ λεγόμενος Ἰούδας Ἰσκαριώτης πρὸς τοὺς ἀρχιερεῖς
40026015 εἶπεν, “Τί θέλετέ μοι δοῦναι, κἀγὼ ὑμῖν παραδώσω αὐτόν;” Οἱ δὲ ἔστησαν αὐτῷ τριάκοντα ἀργύρια.
40026016 Καὶ ἀπὸ τότε ἐζήτει εὐκαιρίαν, ἵνα αὐτὸν παραδῷ.
40026017 Τῇ δὲ πρώτῃ τῶν Ἀζύμων, προσῆλθον οἱ μαθηταὶ τῷ ˚Ἰησοῦ λέγοντες, “Ποῦ θέλεις ἑτοιμάσωμέν σοι φαγεῖν τὸ Πάσχα;”
40026018 Ὁ δὲ εἶπεν, “Ὑπάγετε εἰς τὴν πόλιν πρὸς τὸν δεῖνα καὶ εἴπατε αὐτῷ, ‘Ὁ διδάσκαλος λέγει, “Ὁ καιρός μου ἐγγύς ἐστιν· πρὸς σὲ ποιῶ τὸ Πάσχα μετὰ τῶν μαθητῶν μου.”’”
40026019 Καὶ ἐποίησαν οἱ μαθηταὶ ὡς συνέταξεν αὐτοῖς ὁ ˚Ἰησοῦς, καὶ ἡτοίμασαν τὸ Πάσχα.
40026020 Ὀψίας δὲ γενομένης, ἀνέκειτο μετὰ τῶν δώδεκα,
40026021 καὶ ἐσθιόντων αὐτῶν εἶπεν, “Ἀμὴν, λέγω ὑμῖν ὅτι εἷς ἐξ ὑμῶν παραδώσει με.”
40026022 Καὶ λυπούμενοι σφόδρα, ἤρξαντο λέγειν αὐτῷ εἷς ἕκαστος, αὐτῶν, “Μήτι ἐγώ εἰμι, ˚Κύριε;”
40026023 Ὁ δὲ ἀποκριθεὶς εἶπεν, “Ὁ ἐμβάψας μετʼ ἐμοῦ τὴν χεῖρα ἐν τῷ τρυβλίῳ, οὗτός με παραδώσει.
40026024 Ὁ μὲν Υἱὸς τοῦ Ἀνθρώπου ὑπάγει καθὼς γέγραπται περὶ αὐτοῦ, οὐαὶ δὲ τῷ ἀνθρώπῳ ἐκείνῳ διʼ οὗ ὁ Υἱὸς τοῦ Ἀνθρώπου παραδίδοται. Καλὸν ἦν αὐτῷ, εἰ οὐκ ἐγεννήθη ὁ ἄνθρωπος ἐκεῖνος.”
40026025 Ἀποκριθεὶς δὲ Ἰούδας, ὁ παραδιδοὺς αὐτὸν εἶπεν, “Μήτι ἐγώ εἰμι, Ῥαββί;” Λέγει αὐτῷ, “Σὺ εἶπας.”
40026026 Ἐσθιόντων δὲ αὐτῶν, λαβὼν ὁ ˚Ἰησοῦς ἄρτον, καὶ εὐλογήσας ἔκλασεν καὶ δοὺς τοῖς μαθηταῖς εἶπεν, “Λάβετε, φάγετε, τοῦτό ἐστιν τὸ σῶμά μου.”
40026027 Καὶ λαβὼν ποτήριον καὶ εὐχαριστήσας, ἔδωκεν αὐτοῖς λέγων, “Πίετε ἐξ αὐτοῦ πάντες,
40026028 τοῦτο γάρ ἐστιν τὸ αἷμά μου τῆς διαθήκης, τὸ περὶ πολλῶν ἐκχυννόμενον εἰς ἄφεσιν ἁμαρτιῶν.
40026029 Λέγω δὲ ὑμῖν, οὐ μὴ πίω ἀπʼ ἄρτι ἐκ τούτου τοῦ γενήματος τῆς ἀμπέλου ἕως τῆς ἡμέρας ἐκείνης, ὅταν αὐτὸ πίνω μεθʼ ὑμῶν καινὸν ἐν τῇ βασιλείᾳ τοῦ Πατρός μου.”
40026030 Καὶ ὑμνήσαντες, ἐξῆλθον εἰς τὸ Ὄρος τῶν Ἐλαιῶν.
40026031 Τότε λέγει αὐτοῖς ὁ ˚Ἰησοῦς, “Πάντες ὑμεῖς σκανδαλισθήσεσθε ἐν ἐμοὶ ἐν τῇ νυκτὶ ταύτῃ, γέγραπται γάρ, ‘Πατάξω τὸν ποιμένα, καὶ διασκορπισθήσονται τὰ πρόβατα τῆς ποίμνης.’
40026032 Μετὰ δὲ τὸ ἐγερθῆναί με, προάξω ὑμᾶς εἰς τὴν Γαλιλαίαν.”
40026033 Ἀποκριθεὶς δὲ ὁ Πέτρος εἶπεν αὐτῷ, “Εἰ πάντες σκανδαλισθήσονται ἐν σοί, ἐγὼ οὐδέποτε σκανδαλισθήσομαι.”
40026034 Ἔφη αὐτῷ ὁ ˚Ἰησοῦς, “Ἀμὴν, λέγω σοι ὅτι ἐν ταύτῃ τῇ νυκτὶ πρὶν ἀλέκτορα φωνῆσαι, τρὶς ἀπαρνήσῃ με.”
40026035 Λέγει αὐτῷ ὁ Πέτρος, “Κἂν δέῃ με σὺν σοὶ ἀποθανεῖν, οὐ μή σε ἀπαρνήσομαι.” Ὁμοίως καὶ πάντες οἱ μαθηταὶ εἶπον.
40026036 Τότε ἔρχεται μετʼ αὐτῶν ὁ ˚Ἰησοῦς εἰς χωρίον λεγόμενον Γεθσημανὶ, καὶ λέγει τοῖς μαθηταῖς, “Καθίσατε αὐτοῦ ἕως οὗ ἀπελθὼν ἐκεῖ προσεύξωμαι.”
40026037 Καὶ παραλαβὼν τὸν Πέτρον καὶ τοὺς δύο υἱοὺς Ζεβεδαίου, ἤρξατο λυπεῖσθαι καὶ ἀδημονεῖν.
40026038 Τότε λέγει αὐτοῖς, “Περίλυπός ἐστιν ἡ ψυχή μου ἕως θανάτου· μείνατε ὧδε καὶ γρηγορεῖτε μετʼ ἐμοῦ.”
40026039 Καὶ προσελθὼν μικρὸν, ἔπεσεν ἐπὶ πρόσωπον αὐτοῦ προσευχόμενος καὶ λέγων, “Πάτερ μου, εἰ δυνατόν ἐστιν, παρελθάτω ἀπʼ ἐμοῦ τὸ ποτήριον τοῦτο. Πλὴν οὐχ ὡς ἐγὼ θέλω, ἀλλʼ ὡς σύ.”
40026040 Καὶ ἔρχεται πρὸς τοὺς μαθητὰς καὶ εὑρίσκει αὐτοὺς καθεύδοντας, καὶ λέγει τῷ Πέτρῳ, “Οὕτως οὐκ ἰσχύσατε μίαν ὥραν γρηγορῆσαι μετʼ ἐμοῦ;
40026041 Γρηγορεῖτε καὶ προσεύχεσθε, ἵνα μὴ εἰσέλθητε εἰς πειρασμόν. Τὸ μὲν πνεῦμα πρόθυμον, ἡ δὲ σὰρξ ἀσθενής.”
40026042 Πάλιν ἐκ δευτέρου ἀπελθὼν προσηύξατο λέγων, “Πάτερ μου, εἰ οὐ δύναται τοῦτο παρελθεῖν, ἐὰν μὴ αὐτὸ πίω, γενηθήτω τὸ θέλημά σου.”
40026043 Καὶ ἐλθὼν πάλιν, εὗρεν αὐτοὺς καθεύδοντας, ἦσαν γὰρ αὐτῶν οἱ ὀφθαλμοὶ βεβαρημένοι.
40026044 Καὶ ἀφεὶς αὐτοὺς πάλιν ἀπελθὼν, προσηύξατο ἐκ τρίτου τὸν αὐτὸν λόγον εἰπὼν πάλιν.
40026045 Τότε ἔρχεται πρὸς τοὺς μαθητὰς καὶ λέγει αὐτοῖς, “Καθεύδετε τὸ λοιπὸν καὶ ἀναπαύεσθε; Ἰδοὺ, ἤγγικεν ἡ ὥρα, καὶ ὁ Υἱὸς τοῦ Ἀνθρώπου παραδίδοται εἰς χεῖρας ἁμαρτωλῶν.
40026046 Ἐγείρεσθε, ἄγωμεν! Ἰδοὺ, ἤγγικεν ὁ παραδιδούς με!”
40026047 Καὶ ἔτι αὐτοῦ λαλοῦντος, ἰδοὺ, Ἰούδας εἷς τῶν δώδεκα ἦλθεν, καὶ μετʼ αὐτοῦ ὄχλος πολὺς μετὰ μαχαιρῶν καὶ ξύλων ἀπὸ τῶν ἀρχιερέων καὶ πρεσβυτέρων τοῦ λαοῦ.
40026048 Ὁ δὲ παραδιδοὺς αὐτὸν, ἔδωκεν αὐτοῖς σημεῖον λέγων, “Ὃν ἂν φιλήσω, αὐτός ἐστιν· κρατήσατε αὐτόν.”
40026049 Καὶ εὐθέως προσελθὼν τῷ ˚Ἰησοῦ εἶπεν, “Χαῖρε, Ῥαββί, καὶ κατεφίλησεν αὐτόν.”
40026050 Ὁ δὲ ˚Ἰησοῦς εἶπεν αὐτῷ, “Ἑταῖρε, Ἐφʼ ὃ πάρει;” Τότε προσελθόντες, ἐπέβαλον τὰς χεῖρας ἐπὶ τὸν ˚Ἰησοῦν, καὶ ἐκράτησαν αὐτόν.
40026051 Καὶ ἰδοὺ, εἷς τῶν μετὰ ˚Ἰησοῦ, ἐκτείνας τὴν χεῖρα, ἀπέσπασεν τὴν μάχαιραν αὐτοῦ καὶ πατάξας τὸν δοῦλον τοῦ ἀρχιερέως, ἀφεῖλεν αὐτοῦ τὸ ὠτίον.
40026052 Τότε λέγει αὐτῷ ὁ ˚Ἰησοῦς, “Ἀπόστρεψον τὴν μάχαιράν σου εἰς τὸν τόπον αὐτῆς, πάντες γὰρ οἱ λαβόντες μάχαιραν, ἐν μαχαίρῃ ἀπολοῦνται.
40026053 Ἢ δοκεῖς ὅτι οὐ δύναμαι ἄρτι παρακαλέσαι τὸν Πατέρα μου, καὶ παραστήσει μοι πλείω δώδεκα λεγιῶνας ἀγγέλων;
40026054 Πῶς οὖν πληρωθῶσιν αἱ Γραφαὶ, ὅτι οὕτως δεῖ γενέσθαι;”
40026055 Ἐν ἐκείνῃ τῇ ὥρᾳ, εἶπεν ὁ ˚Ἰησοῦς τοῖς ὄχλοις, “Ὡς ἐπὶ λῃστὴν ἐξήλθατε μετὰ μαχαιρῶν καὶ ξύλων συλλαβεῖν με; Καθʼ ἡμέραν ἐν τῷ ἱερῷ, ἐκαθεζόμην διδάσκων, καὶ οὐκ ἐκρατήσατέ με.
40026056 Τοῦτο δὲ ὅλον γέγονεν, ἵνα πληρωθῶσιν αἱ Γραφαὶ τῶν προφητῶν.” Τότε οἱ μαθηταὶ πάντες, ἀφέντες αὐτὸν ἔφυγον.
40026057 Οἱ δὲ κρατήσαντες τὸν ˚Ἰησοῦν, ἀπήγαγον πρὸς Καϊάφαν τὸν ἀρχιερέα, ὅπου οἱ γραμματεῖς καὶ οἱ πρεσβύτεροι συνήχθησαν.
40026058 Ὁ δὲ Πέτρος ἠκολούθει αὐτῷ ἀπὸ μακρόθεν ἕως τῆς αὐλῆς τοῦ ἀρχιερέως, καὶ εἰσελθὼν ἔσω, ἐκάθητο μετὰ τῶν ὑπηρετῶν ἰδεῖν τὸ τέλος.
40026059 Οἱ δὲ ἀρχιερεῖς καὶ τὸ Συνέδριον ὅλον ἐζήτουν ψευδομαρτυρίαν κατὰ τοῦ ˚Ἰησοῦ, ὅπως αὐτὸν θανατώσωσιν,
40026060 καὶ οὐχ εὗρον, πολλῶν προσελθόντων ψευδομαρτύρων. Ὕστερον δὲ προσελθόντες δύο
40026061 εἶπον, “Οὗτος ἔφη, ‘Δύναμαι καταλῦσαι τὸν ναὸν τοῦ ˚Θεοῦ, καὶ διὰ τριῶν ἡμερῶν αὑτόν οἰκοδομῆσαι.’”
40026062 Καὶ ἀναστὰς, ὁ ἀρχιερεὺς εἶπεν αὐτῷ, “Οὐδὲν ἀποκρίνῃ, τί οὗτοί σου καταμαρτυροῦσιν;”
40026063 Ὁ δὲ ˚Ἰησοῦς ἐσιώπα. Καὶ ὁ ἀρχιερεὺς εἶπεν αὐτῷ, “Ἐξορκίζω σε κατὰ τοῦ ˚Θεοῦ τοῦ ζῶντος ἵνα ἡμῖν εἴπῃς εἰ σὺ εἶ ὁ ˚Χριστὸς, ὁ Υἱὸς τοῦ ˚Θεοῦ.”
40026064 Λέγει αὐτῷ ὁ ˚Ἰησοῦς, “Σὺ εἶπας. Πλὴν λέγω ὑμῖν, ἀπʼ ἄρτι ὄψεσθε τὸν Υἱὸν τοῦ Ἀνθρώπου, ‘καθήμενον ἐκ δεξιῶν’ τῆς δυνάμεως, καὶ ‘ἐρχόμενον ἐπὶ τῶν νεφελῶν τοῦ οὐρανοῦ’.”
40026065 Τότε ὁ ἀρχιερεὺς διέρρηξεν τὰ ἱμάτια αὐτοῦ λέγων, “Ἐβλασφήμησεν· τί ἔτι χρείαν ἔχομεν μαρτύρων; Ἴδε, νῦν ἠκούσατε τὴν βλασφημίαν.
40026066 Τί ὑμῖν δοκεῖ;” Οἱ δὲ ἀποκριθέντες εἶπον, “Ἔνοχος θανάτου ἐστίν.”
40026067 Τότε ἐνέπτυσαν εἰς τὸ πρόσωπον αὐτοῦ καὶ ἐκολάφισαν αὐτόν, οἱ δὲ ἐράπισαν
40026068 λέγοντες, “Προφήτευσον ἡμῖν, ˚Χριστέ, τίς ἐστιν ὁ παίσας σε;”
40026069 Ὁ δὲ Πέτρος ἐκάθητο ἔξω ἐν τῇ αὐλῇ, καὶ προσῆλθεν αὐτῷ μία παιδίσκη λέγουσα, “Καὶ σὺ ἦσθα μετὰ ˚Ἰησοῦ τοῦ Γαλιλαίου.”
40026070 Ὁ δὲ ἠρνήσατο ἔμπροσθεν πάντων λέγων, “Οὐκ οἶδα τί λέγεις.”
40026071 Ἐξελθόντα δὲ εἰς τὸν πυλῶνα, εἶδεν αὐτὸν ἄλλη καὶ λέγει τοῖς ἐκεῖ, “Οὗτος ἦν μετὰ ˚Ἰησοῦ τοῦ Ναζωραίου.”
40026072 Καὶ πάλιν ἠρνήσατο μετὰ ὅρκου, ὅτι “Οὐκ οἶδα τὸν ἄνθρωπον.”
40026073 Μετὰ μικρὸν δὲ προσελθόντες οἱ ἑστῶτες εἶπον τῷ Πέτρῳ, “Ἀληθῶς καὶ σὺ ἐξ αὐτῶν εἶ, καὶ γὰρ ἡ λαλιά σου δῆλόν σε ποιεῖ.”
40026074 Τότε ἤρξατο καταθεματίζειν καὶ ὀμνύειν, ὅτι “Οὐκ οἶδα τὸν ἄνθρωπον!” Καὶ εὐθέως ἀλέκτωρ ἐφώνησεν.
40026075 Καὶ ἐμνήσθη ὁ Πέτρος τοῦ ῥήματος ˚Ἰησοῦ εἰρηκότος, ὅτι “Πρὶν ἀλέκτορα φωνῆσαι, τρὶς ἀπαρνήσῃ με”. Καὶ ἐξελθὼν ἔξω, ἔκλαυσεν πικρῶς.
40027001 Πρωΐας δὲ γενομένης, συμβούλιον ἔλαβον πάντες οἱ ἀρχιερεῖς καὶ οἱ πρεσβύτεροι τοῦ λαοῦ κατὰ τοῦ ˚Ἰησοῦ, ὥστε θανατῶσαι αὐτόν·
40027002 καὶ δήσαντες αὐτὸν ἀπήγαγον καὶ παρέδωκαν Πιλάτῳ τῷ ἡγεμόνι.
40027003 Τότε ἰδὼν Ἰούδας ὁ παραδιδοὺς αὐτὸν, ὅτι κατεκρίθη, μεταμεληθεὶς ἔστρεψεν τὰ τριάκοντα ἀργύρια τοῖς ἀρχιερεῦσιν καὶ πρεσβυτέροις
40027004 λέγων, “Ἥμαρτον παραδοὺς αἷμα ἀθῷον.” Οἱ δὲ εἶπον, “Τί πρὸς ἡμᾶς; Σὺ ὄψῃ.”
40027005 Καὶ ῥίψας τὰ ἀργύρια εἰς τὸν ναὸν, ἀνεχώρησεν καὶ ἀπελθὼν ἀπήγξατο.
40027006 Οἱ δὲ ἀρχιερεῖς λαβόντες τὰ ἀργύρια εἶπον, “Οὐκ ἔξεστιν βαλεῖν αὐτὰ εἰς τὸν κορβανᾶν, ἐπεὶ τιμὴ αἵματός ἐστιν.”
40027007 Συμβούλιον δὲ λαβόντες, ἠγόρασαν ἐξ αὐτῶν τὸν Ἀγρὸν τοῦ Κεραμέως, εἰς ταφὴν τοῖς ξένοις.
40027008 Διὸ ἐκλήθη ὁ ἀγρὸς ἐκεῖνος, Ἀγρὸς Αἵματος ἕως τῆς σήμερον.
40027009 Τότε ἐπληρώθη τὸ ῥηθὲν διὰ Ἰερεμίου τοῦ προφήτου λέγοντος, “Καὶ ἔλαβον τὰ τριάκοντα ἀργύρια, τὴν τιμὴν τοῦ τετιμημένου, ὃν ἐτιμήσαντο” ἀπὸ υἱῶν Ἰσραήλ,
40027010 “καὶ ἔδωκαν αὐτὰ εἰς τὸν Ἀγρὸν τοῦ Κεραμέως, καθὰ συνέταξέν μοι ˚Κύριος”.
40027011 Ὁ δὲ ˚Ἰησοῦς ἐστάθη ἔμπροσθεν τοῦ ἡγεμόνος· καὶ ἐπηρώτησεν αὐτὸν ὁ ἡγεμὼν λέγων, “Σὺ εἶ ὁ βασιλεὺς τῶν Ἰουδαίων;” Ὁ δὲ ˚Ἰησοῦς ἔφη αὐτῷ, “Σὺ λέγεις.”
40027012 Καὶ ἐν τῷ κατηγορεῖσθαι αὐτὸν ὑπὸ τῶν ἀρχιερέων καὶ τῶν πρεσβυτέρων, οὐδὲν ἀπεκρίνατο.
40027013 Τότε λέγει αὐτῷ ὁ Πιλᾶτος, “Οὐκ ἀκούεις πόσα σου καταμαρτυροῦσιν;”
40027014 Καὶ οὐκ ἀπεκρίθη αὐτῷ πρὸς οὐδὲ ἓν ῥῆμα, ὥστε θαυμάζειν τὸν ἡγεμόνα λίαν.
40027015 Κατὰ δὲ ἑορτὴν, εἰώθει ὁ ἡγεμὼν ἀπολύειν ἕνα τῷ ὄχλῳ δέσμιον, ὃν ἤθελον.
40027016 Εἶχον δὲ τότε δέσμιον ἐπίσημον, λεγόμενον Βαραββᾶν.
40027017 Συνηγμένων οὖν αὐτῶν, εἶπεν αὐτοῖς ὁ Πιλᾶτος, “Τίνα θέλετε ἀπολύσω ὑμῖν, Βαραββᾶν ἢ ˚Ἰησοῦν, τὸν λεγόμενον ˚Χριστόν;”
40027018 ᾔδει γὰρ ὅτι διὰ φθόνον παρέδωκαν αὐτόν.
40027019 Καθημένου δὲ αὐτοῦ ἐπὶ τοῦ βήματος, ἀπέστειλεν πρὸς αὐτὸν ἡ γυνὴ αὐτοῦ λέγουσα, “Μηδὲν σοὶ καὶ τῷ δικαίῳ ἐκείνῳ, πολλὰ γὰρ ἔπαθον σήμερον κατʼ ὄναρ διʼ αὐτόν.”
40027020 Οἱ δὲ ἀρχιερεῖς καὶ οἱ πρεσβύτεροι ἔπεισαν τοὺς ὄχλους, ἵνα αἰτήσωνται τὸν Βαραββᾶν, τὸν δὲ ˚Ἰησοῦν ἀπολέσωσιν.
40027021 Ἀποκριθεὶς δὲ ὁ ἡγεμὼν εἶπεν αὐτοῖς, “Τίνα θέλετε ἀπὸ τῶν δύο, ἀπολύσω ὑμῖν;” Οἱ δὲ εἶπον, τὸν “Βαραββᾶν.”
40027022 Λέγει αὐτοῖς ὁ Πιλᾶτος, “Τί οὖν ποιήσω ˚Ἰησοῦν, τὸν λεγόμενον ˚Χριστόν;” Λέγουσιν πάντες, “Σταυρωθήτω.”
40027023 Ὁ δὲ ἔφη, “Τί γὰρ κακὸν ἐποίησεν;” Οἱ δὲ περισσῶς ἔκραζον λέγοντες, “Σταυρωθήτω.”
40027024 Ἰδὼν δὲ ὁ Πιλᾶτος ὅτι οὐδὲν ὠφελεῖ, ἀλλὰ μᾶλλον θόρυβος γίνεται, λαβὼν ὕδωρ, ἀπενίψατο τὰς χεῖρας ἀπέναντι τοῦ ὄχλου λέγων, “Ἀθῷός εἰμι ἀπὸ τοῦ αἵματος τοῦ δικαίου τούτου. Ὑμεῖς ὄψεσθε.”
40027025 Καὶ ἀποκριθεὶς πᾶς ὁ λαὸς εἶπεν, “Τὸ αἷμα αὐτοῦ ἐφʼ ἡμᾶς καὶ ἐπὶ τὰ τέκνα ἡμῶν.”
40027026 Τότε ἀπέλυσεν αὐτοῖς τὸν Βαραββᾶν, τὸν δὲ ˚Ἰησοῦν φραγελλώσας, παρέδωκεν, ἵνα σταυρωθῇ.
40027027 Τότε οἱ στρατιῶται τοῦ ἡγεμόνος, παραλαβόντες τὸν ˚Ἰησοῦν εἰς τὸ πραιτώριον, συνήγαγον ἐπʼ αὐτὸν ὅλην τὴν σπεῖραν.
40027028 Καὶ ἐκδύσαντες αὐτὸν, χλαμύδα κοκκίνην περιέθηκαν αὐτῷ,
40027029 καὶ πλέξαντες στέφανον ἐξ ἀκανθῶν, ἐπέθηκαν ἐπὶ τῆς κεφαλῆς αὐτοῦ, καὶ κάλαμον ἐν τῇ δεξιᾷ αὐτοῦ, καὶ γονυπετήσαντες ἔμπροσθεν αὐτοῦ, ἐνέπαιξαν αὐτῷ λέγοντες, “Χαῖρε, ὁ Βασιλεῦς τῶν Ἰουδαίων!”
40027030 Καὶ ἐμπτύσαντες εἰς αὐτὸν, ἔλαβον τὸν κάλαμον, καὶ ἔτυπτον εἰς τὴν κεφαλὴν αὐτοῦ.
40027031 Καὶ ὅτε ἐνέπαιξαν αὐτῷ, ἐξέδυσαν αὐτὸν τὴν χλαμύδα καὶ ἐνέδυσαν αὐτὸν τὰ ἱμάτια αὐτοῦ, καὶ ἀπήγαγον αὐτὸν εἰς τὸ σταυρῶσαι.
40027032 Ἐξερχόμενοι δὲ, εὗρον ἄνθρωπον Κυρηναῖον, ὀνόματι Σίμωνα. Τοῦτον ἠγγάρευσαν ἵνα ἄρῃ τὸν σταυρὸν αὐτοῦ.
40027033 Καὶ ἐλθόντες εἰς τόπον λεγόμενον Γολγοθᾶ, ὅ ἐστιν “Κρανίου Τόπος” λεγόμενος.
40027034 Ἔδωκαν αὐτῷ πιεῖν οἶνον μετὰ χολῆς μεμιγμένον· καὶ γευσάμενος, οὐκ ἠθέλησεν πιεῖν.
40027035 Σταυρώσαντες δὲ αὐτὸν, διεμερίσαντο τὰ ἱμάτια αὐτοῦ βάλλοντες κλῆρον,
40027036 καὶ καθήμενοι ἐτήρουν αὐτὸν ἐκεῖ.
40027037 Καὶ ἐπέθηκαν ἐπάνω τῆς κεφαλῆς αὐτοῦ τὴν αἰτίαν αὐτοῦ γεγραμμένην, “Οὗτός ἐστιν ˚Ἰησοῦς, ὁ Βασιλεὺς τῶν Ἰουδαίων.”
40027038 Τότε σταυροῦνται σὺν αὐτῷ δύο λῃσταί, εἷς ἐκ δεξιῶν, καὶ εἷς ἐξ εὐωνύμων.
40027039 Οἱ δὲ παραπορευόμενοι ἐβλασφήμουν αὐτὸν, κινοῦντες τὰς κεφαλὰς αὐτῶν
40027040 καὶ λέγοντες, “Ὁ καταλύων τὸν ναὸν καὶ ἐν τρισὶν ἡμέραις οἰκοδομῶν, σῶσον σεαυτόν, εἰ υἱὸς εἶ τοῦ ˚Θεοῦ, κατάβηθι ἀπὸ τοῦ σταυροῦ!”
40027041 Ὁμοίως οἱ ἀρχιερεῖς ἐμπαίζοντες μετὰ τῶν γραμματέων καὶ πρεσβυτέρων ἔλεγον,
40027042 “Ἄλλους ἔσωσεν, ἑαυτὸν οὐ δύναται σῶσαι. Βασιλεὺς Ἰσραήλ ἐστιν, καταβάτω νῦν ἀπὸ τοῦ σταυροῦ, καὶ πιστεύσομεν ἐπʼ αὐτόν.
40027043 ‘Πέποιθεν ἐπὶ τὸν ˚Θεόν, ῥυσάσθω νῦν εἰ θέλει αὐτόν.’ Εἶπεν γὰρ, ὅτι ‘˚Θεοῦ εἰμι Υἱός.’”
40027044 Τὸ δʼ αὐτὸ καὶ οἱ λῃσταὶ, οἱ συσταυρωθέντες σὺν αὐτῷ, ὠνείδιζον αὐτόν.
40027045 Ἀπὸ δὲ ἕκτης ὥρας, σκότος ἐγένετο ἐπὶ πᾶσαν τὴν γῆν ἕως ὥρας ἐνάτης.
40027046 Περὶ δὲ τὴν ἐνάτην ὥραν, ἐβόησεν ὁ ˚Ἰησοῦς φωνῇ μεγάλῃ λέγων, “Ἐλωῒ, ἐλωῒ, λεμὰ σαβαχθάνι;” Τοῦτʼ ἔστιν, “˚Θεέ μου, ˚Θεέ μου, ἵνα τί με ἐγκατέλιπες;”
40027047 Τινὲς δὲ τῶν ἐκεῖ ἑστηκότων ἀκούσαντες ἔλεγον, ὅτι “Ἠλίαν φωνεῖ οὗτος.”
40027048 Καὶ εὐθέως δραμὼν εἷς ἐξ αὐτῶν καὶ λαβὼν σπόγγον, πλήσας τε ὄξους καὶ περιθεὶς καλάμῳ, ἐπότιζεν αὐτόν.
40027049 Οἱ δὲ λοιποὶ ἔλεγον, “Ἄφες, ἴδωμεν εἰ ἔρχεται Ἠλίας σώσων αὐτόν.”
40027050 Ὁ δὲ ˚Ἰησοῦς πάλιν κράξας φωνῇ μεγάλῃ, ἀφῆκεν τὸ πνεῦμα.
40027051 Καὶ ἰδοὺ, τὸ καταπέτασμα τοῦ ναοῦ ἐσχίσθη ἀπʼ ἄνωθεν ἕως κάτω, εἰς δύο καὶ ἡ γῆ ἐσείσθη, καὶ αἱ πέτραι ἐσχίσθησαν,
40027052 καὶ τὰ μνημεῖα ἀνεῴχθησαν, καὶ πολλὰ σώματα τῶν κεκοιμημένων ἁγίων ἠγέρθη·
40027053 καὶ ἐξελθόντες ἐκ τῶν μνημείων μετὰ τὴν ἔγερσιν αὐτοῦ, εἰσῆλθον εἰς τὴν ἁγίαν πόλιν καὶ ἐνεφανίσθησαν πολλοῖς.
40027054 Ὁ δὲ ἑκατόνταρχος καὶ οἱ μετʼ αὐτοῦ τηροῦντες τὸν ˚Ἰησοῦν, ἰδόντες τὸν σεισμὸν καὶ τὰ γενόμενα, ἐφοβήθησαν σφόδρα λέγοντες, “Ἀληθῶς Υἱὸς ˚Θεοῦ ἦν οὗτος.”
40027055 Ἦσαν δὲ ἐκεῖ γυναῖκες πολλαὶ ἀπὸ μακρόθεν θεωροῦσαι, αἵτινες ἠκολούθησαν τῷ ˚Ἰησοῦ ἀπὸ τῆς Γαλιλαίας διακονοῦσαι αὐτῷ,
40027056 ἐν αἷς ἦν Μαρία ἡ Μαγδαληνὴ, καὶ Μαρία ἡ τοῦ Ἰακώβου καὶ Ἰωσὴφ μήτηρ, καὶ ἡ μήτηρ τῶν υἱῶν Ζεβεδαίου.
40027057 Ὀψίας δὲ γενομένης, ἦλθεν ἄνθρωπος πλούσιος ἀπὸ Ἁριμαθαίας, τοὔνομα Ἰωσήφ, ὃς καὶ αὐτὸς ἐμαθητεύθη τῷ ˚Ἰησοῦ.
40027058 Οὗτος προσελθὼν τῷ Πιλάτῳ, ᾐτήσατο τὸ σῶμα τοῦ ˚Ἰησοῦ. Τότε ὁ Πιλᾶτος ἐκέλευσεν ἀποδοθῆναι.
40027059 καὶ λαβὼν τὸ σῶμα, ὁ Ἰωσὴφ ἐνετύλιξεν αὐτὸ σινδόνι καθαρᾷ,
40027060 καὶ ἔθηκεν αὐτὸ ἐν τῷ καινῷ αὐτοῦ μνημείῳ, ὃ ἐλατόμησεν ἐν τῇ πέτρᾳ, καὶ προσκυλίσας λίθον μέγαν τῇ θύρᾳ τοῦ μνημείου, ἀπῆλθεν.
40027061 Ἦν δὲ ἐκεῖ Μαριὰμ ἡ Μαγδαληνὴ καὶ ἡ ἄλλη Μαρία, καθήμεναι ἀπέναντι τοῦ τάφου.
40027062 Τῇ δὲ ἐπαύριον, ἥτις ἐστὶν μετὰ τὴν παρασκευήν, συνήχθησαν οἱ ἀρχιερεῖς καὶ οἱ Φαρισαῖοι πρὸς Πιλᾶτον
40027063 λέγοντες, “Κύριε, ἐμνήσθημεν ὅτι ἐκεῖνος ὁ πλάνος εἶπεν ἔτι ζῶν, ‘Μετὰ τρεῖς ἡμέρας ἐγείρομαι.’
40027064 Κέλευσον οὖν ἀσφαλισθῆναι τὸν τάφον ἕως τῆς τρίτης ἡμέρας, μήποτε ἐλθόντες οἱ μαθηταὶ κλέψωσιν αὐτὸν καὶ εἴπωσιν τῷ λαῷ, ‘Ἠγέρθη ἀπὸ τῶν νεκρῶν’, καὶ ἔσται ἡ ἐσχάτη πλάνη χείρων τῆς πρώτης.”
40027065 Ἔφη δὲ αὐτοῖς ὁ Πιλᾶτος, “Ἔχετε κουστωδίαν, ὑπάγετε ἀσφαλίσασθε ὡς οἴδατε.”
40027066 Οἱ δὲ πορευθέντες ἠσφαλίσαντο τὸν τάφον, σφραγίσαντες τὸν λίθον μετὰ τῆς κουστωδίας.
40028001 Ὀψὲ δὲ Σαββάτων, τῇ ἐπιφωσκούσῃ εἰς μίαν σαββάτων, ἦλθεν Μαρία ἡ Μαγδαληνὴ καὶ ἡ ἄλλη Μαρία, θεωρῆσαι τὸν τάφον.
40028002 Καὶ ἰδοὺ, σεισμὸς ἐγένετο μέγας· ἄγγελος γὰρ ˚Κυρίου καταβὰς ἐξ οὐρανοῦ καὶ προσελθὼν, ἀπεκύλισεν τὸν λίθον καὶ ἐκάθητο ἐπάνω αὐτοῦ.
40028003 Ἦν δὲ ἡ εἰδέα αὐτοῦ ὡς ἀστραπὴ, καὶ τὸ ἔνδυμα αὐτοῦ λευκὸν ὡς χιών.
40028004 Ἀπὸ δὲ τοῦ φόβου αὐτοῦ, ἐσείσθησαν οἱ τηροῦντες, καὶ ἐγενήθησαν ὡς νεκροί.
40028005 Ἀποκριθεὶς δὲ ὁ ἄγγελος εἶπεν ταῖς γυναιξίν, “Μὴ φοβεῖσθε ὑμεῖς, οἶδα γὰρ ὅτι ˚Ἰησοῦν, τὸν ἐσταυρωμένον, ζητεῖτε.
40028006 Οὐκ ἔστιν ὧδε, ἠγέρθη γὰρ καθὼς εἶπεν. Δεῦτε ἴδετε τὸν τόπον ὅπου ἔκειτο.
40028007 Καὶ ταχὺ πορευθεῖσαι, εἴπατε τοῖς μαθηταῖς αὐτοῦ ὅτι ἠγέρθη ἀπὸ τῶν νεκρῶν, καὶ ἰδοὺ, προάγει ὑμᾶς εἰς τὴν Γαλιλαίαν, ἐκεῖ αὐτὸν ὄψεσθε. Ἰδοὺ, εἶπον ὑμῖν.”
40028008 Καὶ ἀπελθοῦσαι ταχὺ ἀπὸ τοῦ μνημείου, μετὰ φόβου καὶ χαρᾶς μεγάλης, ἔδραμον ἀπαγγεῖλαι τοῖς μαθηταῖς αὐτοῦ.
40028009 Καὶ ἰδοὺ, ˚Ἰησοῦς ὑπήντησεν αὐταῖς λέγων, “Χαίρετε!” Αἱ δὲ προσελθοῦσαι, ἐκράτησαν αὐτοῦ τοὺς πόδας καὶ προσεκύνησαν αὐτῷ.
40028010 Τότε λέγει αὐταῖς ὁ ˚Ἰησοῦς, “Μὴ φοβεῖσθε. Ὑπάγετε ἀπαγγείλατε τοῖς ἀδελφοῖς μου ἵνα ἀπέλθωσιν εἰς τὴν Γαλιλαίαν, καὶ ἐκεῖ με ὄψονται.”
40028011 Πορευομένων δὲ αὐτῶν, ἰδού, τινες τῆς κουστωδίας ἐλθόντες εἰς τὴν πόλιν, ἀπήγγειλαν τοῖς ἀρχιερεῦσιν ἅπαντα τὰ γενόμενα.
40028012 Καὶ συναχθέντες μετὰ τῶν πρεσβυτέρων, συμβούλιόν τε λαβόντες, ἀργύρια ἱκανὰ ἔδωκαν τοῖς στρατιώταις
40028013 λέγοντες, “Εἴπατε ὅτι, ‘Οἱ μαθηταὶ αὐτοῦ νυκτὸς ἐλθόντες ἔκλεψαν αὐτὸν, ἡμῶν κοιμωμένων.’
40028014 Καὶ ἐὰν ἀκουσθῇ τοῦτο ἐπὶ τοῦ ἡγεμόνος, ἡμεῖς πείσομεν καὶ ὑμᾶς ἀμερίμνους ποιήσομεν.”
40028015 Οἱ δὲ λαβόντες ἀργύρια ἐποίησαν ὡς ἐδιδάχθησαν. Καὶ διεφημίσθη ὁ λόγος οὗτος παρὰ Ἰουδαίοις μέχρι τῆς σήμερον
40028016 Οἱ δὲ ἕνδεκα μαθηταὶ ἐπορεύθησαν εἰς τὴν Γαλιλαίαν, εἰς τὸ ὄρος οὗ ἐτάξατο αὐτοῖς ὁ ˚Ἰησοῦς.
40028017 Καὶ ἰδόντες αὐτὸν προσεκύνησαν, οἱ δὲ ἐδίστασαν.
40028018 Καὶ προσελθὼν, ὁ ˚Ἰησοῦς ἐλάλησεν αὐτοῖς λέγων, “Ἐδόθη μοι πᾶσα ἐξουσία ἐν οὐρανῷ καὶ ἐπὶ γῆς.
40028019 Πορευθέντες οὖν, μαθητεύσατε πάντα τὰ ἔθνη, βαπτίζοντες αὐτοὺς εἰς τὸ ὄνομα τοῦ Πατρὸς, καὶ τοῦ Υἱοῦ, καὶ τοῦ Ἁγίου ˚Πνεύματος,
40028020 διδάσκοντες αὐτοὺς τηρεῖν πάντα ὅσα ἐνετειλάμην ὑμῖν. Καὶ ἰδοὺ, ἐγὼ μεθʼ ὑμῶν εἰμι πάσας τὰς ἡμέρας, ἕως τῆς συντελείας τοῦ αἰῶνος.”
41001001 Ἀρχὴ τοῦ εὐαγγελίου ˚Ἰησοῦ ˚Χριστοῦ, Υἱοῦ ˚Θεοῦ.
41001002 Καθὼς γέγραπται ἐν τῷ Ἠσαΐᾳ τῷ προφήτῃ: “Ἰδοὺ, ἀποστέλλω τὸν ἄγγελόν μου πρὸ προσώπου σου, ὃς κατασκευάσει τὴν ὁδόν σου.”
41001003 “Φωνὴ βοῶντος ἐν τῇ ἐρήμῳ: ‘Ἑτοιμάσατε τὴν ὁδὸν ˚Κυρίου, εὐθείας ποιεῖτε τὰς τρίβους αὐτοῦ.’”
41001004 Ἐγένετο Ἰωάννης, ὁ βαπτίζων ἐν τῇ ἐρήμῳ, καὶ κηρύσσων βάπτισμα μετανοίας εἰς ἄφεσιν ἁμαρτιῶν.
41001005 Καὶ ἐξεπορεύετο πρὸς αὐτὸν πᾶσα ἡ Ἰουδαία χώρα καὶ οἱ Ἱεροσολυμῖται πάντες, καὶ ἐβαπτίζοντο ὑπʼ αὐτοῦ ἐν τῷ Ἰορδάνῃ ποταμῷ, ἐξομολογούμενοι τὰς ἁμαρτίας αὐτῶν.
41001006 Καὶ ἦν ὁ Ἰωάννης ἐνδεδυμένος τρίχας καμήλου, καὶ ζώνην δερματίνην περὶ τὴν ὀσφὺν αὐτοῦ, καὶ ἐσθίων ἀκρίδας καὶ μέλι ἄγριον.
41001007 Καὶ ἐκήρυσσεν λέγων, “Ἔρχεται ὁ ἰσχυρότερός μου ὀπίσω μου, οὗ οὐκ εἰμὶ ἱκανὸς, κύψας λῦσαι τὸν ἱμάντα τῶν ὑποδημάτων αὐτοῦ.
41001008 Ἐγὼ ἐβάπτισα ὑμᾶς ὕδατι, αὐτὸς δὲ βαπτίσει ὑμᾶς ˚Πνεύματι Ἁγίῳ.”
41001009 Καὶ ἐγένετο ἐν ἐκείναις ταῖς ἡμέραις, ἦλθεν ˚Ἰησοῦς ἀπὸ Ναζαρὲτ τῆς Γαλιλαίας, καὶ ἐβαπτίσθη εἰς τὸν Ἰορδάνην ὑπὸ Ἰωάννου.
41001010 Καὶ εὐθὺς ἀναβαίνων ἐκ τοῦ ὕδατος, εἶδεν σχιζομένους τοὺς οὐρανοὺς, καὶ τὸ ˚Πνεῦμα ὡς περιστερὰν καταβαῖνον ἐπʼ αὐτόν.
41001011 Καὶ φωνὴ ἐγένετο ἐκ τῶν οὐρανῶν: “Σὺ εἶ ὁ Υἱός μου ὁ ἀγαπητός, ἐν σοὶ εὐδόκησα.”
41001012 Καὶ εὐθὺς τὸ ˚Πνεῦμα αὐτὸν ἐκβάλλει εἰς τὴν ἔρημον.
41001013 Καὶ ἦν ἐν τῇ ἐρήμῳ τεσσεράκοντα ἡμέρας, πειραζόμενος ὑπὸ τοῦ Σατανᾶ, καὶ ἦν μετὰ τῶν θηρίων, καὶ οἱ ἄγγελοι διηκόνουν αὐτῷ.
41001014 Καὶ Μετὰ τὸ παραδοθῆναι τὸν Ἰωάννην, ἦλθεν ὁ ˚Ἰησοῦς εἰς τὴν Γαλιλαίαν, κηρύσσων τὸ εὐαγγέλιον τοῦ ˚Θεοῦ
41001015 καὶ λέγων, ὅτι “Πεπλήρωται ὁ καιρὸς καὶ ἤγγικεν ἡ Βασιλεία τοῦ ˚Θεοῦ· μετανοεῖτε καὶ πιστεύετε ἐν τῷ εὐαγγελίῳ.”
41001016 Καὶ παράγων παρὰ τὴν θάλασσαν τῆς Γαλιλαίας, εἶδεν Σίμωνα καὶ Ἀνδρέαν, τὸν ἀδελφὸν Σίμωνος, ἀμφιβάλλοντας ἐν τῇ θαλάσσῃ, ἦσαν γὰρ ἁλιεῖς.
41001017 Καὶ εἶπεν αὐτοῖς ὁ ˚Ἰησοῦς, “Δεῦτε ὀπίσω μου, καὶ ποιήσω ὑμᾶς γενέσθαι ἁλιεῖς ἀνθρώπων.”
41001018 Καὶ εὐθέως ἀφέντες τὰ δίκτυα, ἠκολούθησαν αὐτῷ.
41001019 Καὶ προβὰς ὀλίγον, εἶδεν Ἰάκωβον τὸν τοῦ Ζεβεδαίου, καὶ Ἰωάννην τὸν ἀδελφὸν αὐτοῦ, καὶ αὐτοὺς ἐν τῷ πλοίῳ καταρτίζοντας τὰ δίκτυα.
41001020 Καὶ εὐθὺς ἐκάλεσεν αὐτούς. Καὶ ἀφέντες τὸν πατέρα αὐτῶν Ζεβεδαῖον ἐν τῷ πλοίῳ μετὰ τῶν μισθωτῶν, ἀπῆλθον ὀπίσω αὐτοῦ.
41001021 Καὶ εἰσπορεύονται εἰς Καφαρναούμ. Καὶ εὐθέως τοῖς Σάββασιν, εἰσελθὼν εἰς τὴν συναγωγὴν, ἐδίδασκεν.
41001022 Καὶ ἐξεπλήσσοντο ἐπὶ τῇ διδαχῇ αὐτοῦ, ἦν γὰρ διδάσκων αὐτοὺς ὡς ἐξουσίαν ἔχων, καὶ οὐχ ὡς οἱ γραμματεῖς.
41001023 Καὶ εὐθὺς ἦν ἐν τῇ συναγωγῇ αὐτῶν ἄνθρωπος ἐν πνεύματι ἀκαθάρτῳ, καὶ ἀνέκραξεν
41001024 λέγων, “Τί ἡμῖν καὶ σοί, ˚Ἰησοῦ Ναζαρηνέ; Ἦλθες ἀπολέσαι ἡμᾶς; Οἶδά σε τίς εἶ, ὁ Ἅγιος τοῦ ˚Θεοῦ!”
41001025 Καὶ ἐπετίμησεν αὐτῷ ὁ ˚Ἰησοῦς λέγων, “Φιμώθητι καὶ ἔξελθε ἐξ αὐτοῦ!”
41001026 Καὶ σπαράξαν αὐτὸν, τὸ πνεῦμα τὸ ἀκάθαρτον, καὶ φωνῆσαν φωνῇ μεγάλῃ, ἐξῆλθεν ἐξ αὐτοῦ.
41001027 Καὶ ἐθαμβήθησαν ἅπαντες, ὥστε συζητεῖν αὐτοὺς λέγοντας, “Τί ἐστιν τοῦτο; διδαχὴ καινή, κατʼ ἐξουσίαν καὶ τοῖς πνεύμασιν τοῖς ἀκαθάρτοις ἐπιτάσσει, καὶ ὑπακούουσιν αὐτῷ!”
41001028 Καὶ ἐξῆλθεν ἡ ἀκοὴ αὐτοῦ εὐθὺς πανταχοῦ εἰς ὅλην τὴν περίχωρον τῆς Γαλιλαίας.
41001029 Καὶ εὐθὺς ἐκ τῆς συναγωγῆς ἐξελθὼν, ἦλθεν εἰς τὴν οἰκίαν Σίμωνος καὶ Ἀνδρέου, μετὰ Ἰακώβου καὶ Ἰωάννου.
41001030 Ἡ δὲ πενθερὰ Σίμωνος κατέκειτο πυρέσσουσα, καὶ εὐθὺς λέγουσιν αὐτῷ περὶ αὐτῆς.
41001031 Καὶ προσελθὼν, ἤγειρεν αὐτὴν, κρατήσας τῆς χειρός. Καὶ ἀφῆκεν αὐτὴν ὁ πυρετός, καὶ διηκόνει αὐτοῖς.
41001032 Ὀψίας δὲ γενομένης, ὅτε ἔδυ ὁ ἥλιος, ἔφερον πρὸς αὐτὸν πάντας τοὺς κακῶς ἔχοντας καὶ τοὺς δαιμονιζομένους.
41001033 Καὶ ἦν ὅλη ἡ πόλις ἐπισυνηγμένη πρὸς τὴν θύραν.
41001034 Καὶ ἐθεράπευσεν πολλοὺς κακῶς ἔχοντας ποικίλαις νόσοις, καὶ δαιμόνια πολλὰ ἐξέβαλεν, καὶ οὐκ ἤφιεν τὰ δαιμόνια, λαλεῖν ὅτι ᾔδεισαν αὐτόν. ˚Χριστὸν εἶναι.
41001035 Καὶ πρωῒ ἔννυχα λίαν, ἀναστὰς ἐξῆλθεν καὶ ἀπῆλθεν εἰς ἔρημον τόπον, κἀκεῖ προσηύχετο.
41001036 Καὶ κατεδίωξεν αὐτὸν Σίμων καὶ οἱ μετʼ αὐτοῦ,
41001037 καὶ εὗρον αὐτὸν καὶ λέγουσιν αὐτῷ, ὅτι “Πάντες ζητοῦσίν σε!”
41001038 Καὶ λέγει αὐτοῖς, “Ἄγωμεν ἀλλαχοῦ εἰς τὰς ἐχομένας κωμοπόλεις, ἵνα καὶ ἐκεῖ κηρύξω, εἰς τοῦτο γὰρ ἐξῆλθον.”
41001039 Καὶ ἦλθεν κηρύσσων, εἰς τὰς συναγωγὰς αὐτῶν εἰς ὅλην τὴν Γαλιλαίαν, καὶ τὰ δαιμόνια ἐκβάλλων.
41001040 Καὶ ἔρχεται πρὸς αὐτὸν λεπρὸς, παρακαλῶν αὐτὸν λέγων αὐτῷ, ὅτι “Ἐὰν θέλῃς, δύνασαί με καθαρίσαι.”
41001041 Καὶ σπλαγχνισθεὶς, ἐκτείνας τὴν χεῖρα αὐτοῦ, ἥψατο καὶ λέγει αὐτῷ, “Θέλω, καθαρίσθητι.”
41001042 Καὶ εὐθὺς ἀπῆλθεν ἀπʼ αὐτοῦ ἡ λέπρα, καὶ ἐκαθαρίσθη.
41001043 Καὶ ἐμβριμησάμενος αὐτῷ, εὐθὺς ἐξέβαλεν αὐτόν,
41001044 καὶ λέγει αὐτῷ, “Ὅρα, μηδενὶ μηδὲν εἴπῃς, ἀλλὰ ὕπαγε, σεαυτὸν δεῖξον τῷ ἱερεῖ, καὶ προσένεγκε περὶ τοῦ καθαρισμοῦ σου ἃ προσέταξεν Μωϋσῆς, εἰς μαρτύριον αὐτοῖς.”
41001045 Ὁ δὲ ἐξελθὼν ἤρξατο κηρύσσειν πολλὰ καὶ διαφημίζειν τὸν λόγον, ὥστε μηκέτι αὐτὸν δύνασθαι φανερῶς εἰς πόλιν εἰσελθεῖν, ἀλλʼ ἔξω ἐπʼ ἐρήμοις τόποις ἦν, καὶ ἤρχοντο πρὸς αὐτὸν πάντοθεν.
41002001 Καὶ εἰσελθὼν πάλιν εἰς Καφαρναοὺμ διʼ ἡμερῶν, ἠκούσθη ὅτι ἐν οἴκῳ ἐστίν.
41002002 Καὶ συνήχθησαν πολλοὶ, ὥστε μηκέτι χωρεῖν, μηδὲ τὰ πρὸς τὴν θύραν, καὶ ἐλάλει αὐτοῖς τὸν λόγον.
41002003 Καὶ ἔρχονται φέροντες πρὸς αὐτὸν παραλυτικὸν, αἰρόμενον ὑπὸ τεσσάρων.
41002004 Καὶ μὴ δυνάμενοι προσενέγκαι αὐτῷ διὰ τὸν ὄχλον, ἀπεστέγασαν τὴν στέγην ὅπου ἦν, καὶ ἐξορύξαντες, χαλῶσι τὸν κράβαττον ὅπου ὁ παραλυτικὸς κατέκειτο.
41002005 Καὶ ἰδὼν ὁ ˚Ἰησοῦς τὴν πίστιν αὐτῶν, λέγει τῷ παραλυτικῷ, “Τέκνον, ἀφέωνται σου αἱ ἁμαρτίαι.”
41002006 Ἦσαν δέ τινες τῶν γραμματέων ἐκεῖ, καθήμενοι καὶ διαλογιζόμενοι ἐν ταῖς καρδίαις αὐτῶν,
41002007 “Τί οὗτος οὕτως λαλεῖ; Βλασφημεῖ! Τίς δύναται ἀφιέναι ἁμαρτίας, εἰ μὴ εἷς ὁ ˚Θεός;”
41002008 Καὶ εὐθὺς ἐπιγνοὺς ὁ ˚Ἰησοῦς τῷ πνεύματι αὐτοῦ ὅτι οὕτως διαλογίζονται ἐν ἑαυτοῖς, λέγει αὐτοῖς, “Τί ταῦτα διαλογίζεσθε ἐν ταῖς καρδίαις ὑμῶν;
41002009 Τί ἐστιν εὐκοπώτερον, εἰπεῖν τῷ παραλυτικῷ, ἀφέωνταί σοι αἱ ἁμαρτίαι’, ἢ εἰπεῖν, ‘Ἔγειρε καὶ ἆρον τὸν κράβαττόν σου καὶ περιπάτει’;
41002010 Ἵνα δὲ εἰδῆτε ὅτι ἐξουσίαν ἔχει ὁ Υἱὸς τοῦ Ἀνθρώπου ἐπὶ τῆς γῆς ἀφιέναι ἁμαρτίας”, ( λέγει τῷ παραλυτικῷ ),
41002011 “Σοὶ λέγω, ἔγειρε ἆρον τὸν κράβαττόν σου, καὶ ὕπαγε εἰς τὸν οἶκόν σου.”
41002012 Καὶ ἠγέρθη, καὶ εὐθὺς ἄρας τὸν κράβαττον, ἐξῆλθεν ἔμπροσθεν πάντων, ὥστε ἐξίστασθαι πάντας, καὶ δοξάζειν τὸν ˚Θεὸν λέγοντας, ὅτι “Οὕτως οὐδέποτε εἴδομεν.”
41002013 Καὶ ἐξῆλθεν πάλιν παρὰ τὴν θάλασσαν, καὶ πᾶς ὁ ὄχλος ἤρχετο πρὸς αὐτόν, καὶ ἐδίδασκεν αὐτούς.
41002014 Καὶ παράγων εἶδεν Λευὶν τὸν τοῦ Ἁλφαίου, καθήμενον ἐπὶ τὸ τελώνιον, καὶ λέγει αὐτῷ, “Ἀκολούθει μοι.” Καὶ ἀναστὰς, ἠκολούθησεν αὐτῷ.
41002015 Καὶ γίνεται κατακεῖσθαι αὐτὸν ἐν τῇ οἰκίᾳ αὐτοῦ, καὶ πολλοὶ τελῶναι καὶ ἁμαρτωλοὶ συνανέκειντο τῷ ˚Ἰησοῦ καὶ τοῖς μαθηταῖς αὐτοῦ, ἦσαν γὰρ πολλοὶ, καὶ ἠκολούθουν αὐτῷ.
41002016 Καὶ οἱ γραμματεῖς τῶν Φαρισαίων, ἰδόντες ὅτι ἐσθίει μετὰ τῶν ἁμαρτωλῶν καὶ τῶν τελωνῶν, ἔλεγον τοῖς μαθηταῖς αὐτοῦ, ὅτι “Μετὰ τῶν τελωνῶν καὶ ἁμαρτωλῶν ἐσθίει;”
41002017 Καὶ ἀκούσας, ὁ ˚Ἰησοῦς λέγει αὐτοῖς, “Οὐ χρείαν ἔχουσιν οἱ ἰσχύοντες ἰατροῦ, ἀλλʼ οἱ κακῶς ἔχοντες. Οὐκ ἦλθον καλέσαι δικαίους, ἀλλὰ ἁμαρτωλούς.”
41002018 Καὶ ἦσαν οἱ μαθηταὶ Ἰωάννου καὶ οἱ Φαρισαῖοι νηστεύοντες. Καὶ ἔρχονται καὶ λέγουσιν αὐτῷ, “Διὰ τί οἱ μαθηταὶ Ἰωάννου καὶ οἱ μαθηταὶ τῶν Φαρισαίων νηστεύουσιν, οἱ δὲ σοὶ μαθηταὶ οὐ νηστεύουσιν;”
41002019 Καὶ εἶπεν αὐτοῖς ὁ ˚Ἰησοῦς, “Μὴ δύνανται οἱ υἱοὶ τοῦ νυμφῶνος ἐν ᾧ ὁ νυμφίος μετʼ αὐτῶν ἐστιν νηστεύειν; Ὅσον χρόνον ἔχουσιν τὸν νυμφίον μετʼ αὐτῶν, οὐ δύνανται νηστεύειν.
41002020 Ἐλεύσονται δὲ ἡμέραι ὅταν ἀπαρθῇ ἀπʼ αὐτῶν ὁ νυμφίος, καὶ τότε νηστεύσουσιν ἐν ἐκείνῃ τῇ ἡμέρᾳ.
41002021 Οὐδεὶς ἐπίβλημα ῥάκους ἀγνάφου ἐπιράπτει ἐπὶ ἱμάτιον παλαιόν· εἰ δὲ μή αἴρει ἀπʼ αὐτοῦ τὸ πλήρωμα τὸ καινὸν τοῦ παλαιοῦ, καὶ χεῖρον σχίσμα γίνεται.
41002022 Καὶ οὐδεὶς βάλλει οἶνον νέον εἰς ἀσκοὺς παλαιούς· εἰ δὲ μή ῥήξει ὁ οἶνος τοὺς ἀσκούς, καὶ ὁ οἶνος ἀπόλλυται καὶ οἱ ἀσκοί. Ἀλλὰ οἶνον νέον εἰς ἀσκοὺς καινοὺς βλητέον.”
41002023 Καὶ ἐγένετο, αὐτὸν ἐν τοῖς Σάββασιν παραπορεύεσθαι διὰ τῶν σπορίμων, καὶ οἱ μαθηταὶ αὐτοῦ ἤρξαντο ὁδὸν ποιεῖν, τίλλοντες τοὺς στάχυας.
41002024 Καὶ οἱ Φαρισαῖοι ἔλεγον αὐτῷ, “Ἴδε, τί ποιοῦσιν τοῖς Σάββασιν ὃ οὐκ ἔξεστιν;”
41002025 Καὶ λέγει αὐτοῖς, “Οὐδέποτε ἀνέγνωτε τί ἐποίησεν Δαυὶδ, ὅτε χρείαν ἔσχεν καὶ ἐπείνασεν, αὐτὸς καὶ οἱ μετʼ αὐτοῦ;
41002026 Πῶς εἰσῆλθεν εἰς τὸν οἶκον τοῦ ˚Θεοῦ ἐπὶ Ἀβιαθὰρ ἀρχιερέως, καὶ τοὺς ἄρτους τῆς Προθέσεως ἔφαγεν, οὓς οὐκ ἔξεστιν φαγεῖν, εἰ μὴ τοὺς ἱερεῖς, καὶ ἔδωκεν καὶ τοῖς σὺν αὐτῷ οὖσιν;”
41002027 Καὶ ἔλεγεν αὐτοῖς, “Τὸ Σάββατον διὰ τὸν ἄνθρωπον ἐγένετο, καὶ οὐχ ὁ ἄνθρωπος διὰ τὸ Σάββατον.
41002028 Ὥστε ˚Κύριός ἐστιν ὁ Υἱὸς τοῦ Ἀνθρώπου καὶ τοῦ Σαββάτου.”
41003001 Καὶ εἰσῆλθεν πάλιν εἰς συναγωγήν, καὶ ἦν ἐκεῖ ἄνθρωπος, ἐξηραμμένην ἔχων τὴν χεῖρα.
41003002 Καὶ παρετήρουν αὐτὸν, εἰ τοῖς Σάββασιν θεραπεύσει αὐτόν, ἵνα κατηγορήσωσιν αὐτοῦ.
41003003 Καὶ λέγει τῷ ἀνθρώπῳ τῷ τὴν χεῖρα ἔχοντι ξηράν, “Ἔγειρε εἰς τὸ μέσον.”
41003004 Καὶ λέγει αὐτοῖς, “Ἔξεστιν τοῖς Σάββασιν ἀγαθοποιῆσαι ἢ κακοποιῆσαι, ψυχὴν σῶσαι ἢ ἀποκτεῖναι;” Οἱ δὲ ἐσιώπων.
41003005 Καὶ περιβλεψάμενος αὐτοὺς μετʼ ὀργῆς, συλλυπούμενος ἐπὶ τῇ πωρώσει τῆς καρδίας αὐτῶν, λέγει τῷ ἀνθρώπῳ, “Ἔκτεινον τὴν χεῖρα σου.” Καὶ ἐξέτεινεν, καὶ ἀπεκατεστάθη ἡ χεὶρ αὐτοῦ.
41003006 Καὶ ἐξελθόντες, οἱ Φαρισαῖοι εὐθὺς μετὰ τῶν Ἡρῳδιανῶν συμβούλιον ἐποίησαν κατʼ αὐτοῦ, ὅπως αὐτὸν ἀπολέσωσιν.
41003007 Καὶ ὁ ˚Ἰησοῦς μετὰ τῶν μαθητῶν αὐτοῦ, ἀνεχώρησεν πρὸς τὴν θάλασσαν· καὶ πολὺ πλῆθος ἀπὸ τῆς Γαλιλαίας ἠκολούθησεν, καὶ ἀπὸ τῆς Ἰουδαίας,
41003008 καὶ ἀπὸ Ἱεροσολύμων, καὶ ἀπὸ τῆς Ἰδουμαίας, καὶ πέραν τοῦ Ἰορδάνου, καὶ περὶ Τύρον καὶ Σιδῶνα, πλῆθος πολύ ἀκούοντες ὅσα ἐποίει, ἦλθον πρὸς αὐτόν.
41003009 Καὶ εἶπεν τοῖς μαθηταῖς αὐτοῦ ἵνα πλοιάριον προσκαρτερῇ αὐτῷ διὰ τὸν ὄχλον, ἵνα μὴ θλίβωσιν αὐτόν.
41003010 Πολλοὺς γὰρ ἐθεράπευσεν, ὥστε ἐπιπίπτειν αὐτῷ, ἵνα αὐτοῦ ἅψωνται ὅσοι εἶχον μάστιγας.
41003011 Καὶ τὰ πνεύματα τὰ ἀκάθαρτα, ὅταν αὐτὸν ἐθεώρουν, προσέπιπτον αὐτῷ καὶ ἔκραζον λέγοντα, ὅτι “Σὺ εἶ ὁ Υἱὸς τοῦ ˚Θεοῦ.”
41003012 Καὶ πολλὰ ἐπετίμα αὐτοῖς ἵνα μὴ αὐτὸν φανερὸν ποιήσωσιν.
41003013 Καὶ ἀναβαίνει εἰς τὸ ὄρος, καὶ προσκαλεῖται οὓς ἤθελεν αὐτός, καὶ ἀπῆλθον πρὸς αὐτόν.
41003014 Καὶ ἐποίησεν δώδεκα, οὓς καὶ ἀποστόλους ὠνόμασεν, ἵνα ὦσιν μετʼ αὐτοῦ, καὶ ἵνα ἀποστέλλῃ αὐτοὺς κηρύσσειν
41003015 καὶ ἔχειν ἐξουσίαν ἐκβάλλειν τὰ δαιμόνια.
41003016 Καὶ ἐποίησεν τοὺς δώδεκα: καὶ ἐπέθηκεν ὄνομα τῷ Σίμωνι, Πέτρον,
41003017 καὶ Ἰάκωβον τὸν τοῦ Ζεβεδαίου, καὶ Ἰωάννην τὸν ἀδελφὸν τοῦ Ἰακώβου, καὶ ἐπέθηκεν αὐτοῖς ὀνόματα Βοανηργές, ὅ ἐστιν “Υἱοὶ βροντῆς”,
41003018 καὶ Ἀνδρέαν, καὶ Φίλιππον, καὶ Βαρθολομαῖον, καὶ Μαθθαῖον, καὶ Θωμᾶν, καὶ Ἰάκωβον τὸν τοῦ Ἁλφαίου, καὶ Θαδδαῖον, καὶ Σίμωνα τὸν Καναναῖον,
41003019 καὶ Ἰούδαν Ἰσκαριώθ, ὃς καὶ παρέδωκεν αὐτόν.
41003020 Καὶ ἔρχεται εἰς οἶκον, καὶ συνέρχεται πάλιν ὁ ὄχλος, ὥστε μὴ δύνασθαι αὐτοὺς μηδὲ ἄρτον φαγεῖν.
41003021 Καὶ ἀκούσαντες, οἱ παρʼ αὐτοῦ ἐξῆλθον κρατῆσαι αὐτόν, ἔλεγον γὰρ, ὅτι “Ἐξέστη.”
41003022 Καὶ οἱ γραμματεῖς, οἱ ἀπὸ Ἱεροσολύμων καταβάντες ἔλεγον, ὅτι “Βεελζεβοὺλ ἔχει”, καὶ, ὅτι “Ἐν τῷ ἄρχοντι τῶν δαιμονίων, ἐκβάλλει τὰ δαιμόνια.”
41003023 Καὶ προσκαλεσάμενος αὐτοὺς, ἐν παραβολαῖς ἔλεγεν αὐτοῖς, “Πῶς δύναται Σατανᾶς Σατανᾶν ἐκβάλλειν;
41003024 Καὶ ἐὰν βασιλεία ἐφʼ ἑαυτὴν μερισθῇ, οὐ δύναται σταθῆναι ἡ βασιλεία ἐκείνη.
41003025 Καὶ ἐὰν οἰκία ἐφʼ ἑαυτὴν μερισθῇ, οὐ δυνήσεται ἡ οἰκία ἐκείνη σταθῆναι.
41003026 Καὶ εἰ ὁ Σατανᾶς ἀνέστη ἐφʼ ἑαυτὸν καὶ ἐμερίσθη, οὐ δύναται στῆναι, ἀλλὰ τέλος ἔχει.
41003027 Ἀλλʼ οὐ δύναται οὐδεὶς εἰς τὴν οἰκίαν τοῦ ἰσχυροῦ εἰσελθὼν τὰ σκεύη αὐτοῦ διαρπάσαι, ἐὰν μὴ πρῶτον τὸν ἰσχυρὸν δήσῃ, καὶ τότε τὴν οἰκίαν αὐτοῦ διαρπάσει.
41003028 Ἀμὴν, λέγω ὑμῖν ὅτι πάντα ἀφεθήσεται τοῖς υἱοῖς τῶν ἀνθρώπων τὰ ἁμαρτήματα καὶ αἱ βλασφημίαι, ὅσα ἐὰν βλασφημήσωσιν.
41003029 Ὃς δʼ ἂν βλασφημήσῃ εἰς τὸ ˚Πνεῦμα τὸ Ἅγιον, οὐκ ἔχει ἄφεσιν εἰς τὸν αἰῶνα, ἀλλὰ ἔνοχός ἐστιν αἰωνίου ἁμαρτήματος”–
41003030 ὅτι ἔλεγον, “Πνεῦμα ἀκάθαρτον ἔχει.”
41003031 Καὶ ἔρχονται ἡ μήτηρ αὐτοῦ καὶ οἱ ἀδελφοὶ αὐτοῦ, καὶ ἔξω στήκοντες, ἀπέστειλαν πρὸς αὐτὸν καλοῦντες αὐτόν.
41003032 Καὶ ἐκάθητο περὶ αὐτὸν ὄχλος, καὶ λέγουσιν αὐτῷ, “Ἰδοὺ, ἡ μήτηρ σου καὶ οἱ ἀδελφοί σου, ἔξω ζητοῦσίν σε.”
41003033 Καὶ ἀποκριθεὶς αὐτοῖς λέγει, “Τίς ἐστιν ἡ μήτηρ μου, καὶ οἱ ἀδελφοί μου;”
41003034 Καὶ περιβλεψάμενος τοὺς περὶ αὐτὸν, κύκλῳ καθημένους λέγει, “Ἴδε, ἡ μήτηρ μου καὶ οἱ ἀδελφοί μου!
41003035 Ὃς γὰρ ἂν ποιήσῃ τὸ θέλημα τοῦ ˚Θεοῦ, οὗτος ἀδελφός μου καὶ ἀδελφὴ καὶ μήτηρ ἐστίν.”
41004001 Καὶ πάλιν ἤρξατο διδάσκειν παρὰ τὴν θάλασσαν. Καὶ συνάγεται πρὸς αὐτὸν ὄχλος πλεῖστος, ὥστε αὐτὸν εἰς τὸ πλοῖον ἐμβάντα, καθῆσθαι ἐν τῇ θαλάσσῃ, καὶ πᾶς ὁ ὄχλος πρὸς τὴν θάλασσαν ἐπὶ τῆς γῆς ἦσαν.
41004002 Καὶ ἐδίδασκεν αὐτοὺς ἐν παραβολαῖς πολλά, καὶ ἔλεγεν αὐτοῖς ἐν τῇ διδαχῇ αὐτοῦ,
41004003 “Ἀκούετε! Ἰδοὺ, ἐξῆλθεν ὁ σπείρων σπεῖραι.
41004004 Καὶ ἐγένετο ἐν τῷ σπείρειν, ὃ μὲν ἔπεσεν παρὰ τὴν ὁδόν, καὶ ἦλθεν τὰ πετεινὰ καὶ κατέφαγεν αὐτό.
41004005 Καὶ ἄλλο ἔπεσεν ἐπὶ τὸ πετρῶδες, καὶ ὅπου οὐκ εἶχεν γῆν πολλήν, καὶ εὐθὺς ἐξανέτειλεν διὰ τὸ μὴ ἔχειν βάθος γῆς·
41004006 καὶ ὅτε ἀνέτειλεν ὁ ἥλιος ἐκαυματίσθη, καὶ διὰ τὸ μὴ ἔχειν ῥίζαν ἐξηράνθη.
41004007 Καὶ ἄλλο ἔπεσεν εἰς τὰς ἀκάνθας, καὶ ἀνέβησαν αἱ ἄκανθαι καὶ συνέπνιξαν αὐτό, καὶ καρπὸν οὐκ ἔδωκεν.
41004008 Καὶ ἄλλα ἔπεσεν εἰς τὴν γῆν τὴν καλήν, καὶ ἐδίδου καρπὸν, ἀναβαίνοντα καὶ αὐξανόμενα, καὶ ἔφερεν εἰς τριάκοντα, καὶ ἓν ἑξήκοντα, καὶ ἓν ἑκατόν.”
41004009 Καὶ ἔλεγεν, “Ὃς ἔχει ὦτα ἀκούειν, ἀκουέτω.”
41004010 Καὶ ὅτε ἐγένετο κατὰ μόνας, ἠρώτων αὐτὸν οἱ περὶ αὐτὸν σὺν τοῖς δώδεκα τὰς παραβολάς.
41004011 Καὶ ἔλεγεν αὐτοῖς, “Ὑμῖν τὸ μυστήριον δέδοται τῆς Βασιλείας τοῦ ˚Θεοῦ· ἐκείνοις δὲ τοῖς ἔξω, ἐν παραβολαῖς τὰ πάντα γίνεται,
41004012 ἵνα ‘βλέποντες, βλέπωσιν καὶ μὴ ἴδωσιν, καὶ ἀκούοντες, ἀκούωσιν καὶ μὴ συνιῶσιν, μήποτε ἐπιστρέψωσιν καὶ ἀφεθῇ αὐτοῖς.’”
41004013 Καὶ λέγει αὐτοῖς, “Οὐκ οἴδατε τὴν παραβολὴν ταύτην, καὶ πῶς πάσας τὰς παραβολὰς γνώσεσθε;
41004014 Ὁ σπείρων τὸν λόγον σπείρει.
41004015 Οὗτοι δέ εἰσιν οἱ παρὰ τὴν ὁδὸν, ὅπου σπείρεται ὁ λόγος, καὶ ὅταν ἀκούσωσιν, εὐθὺς ἔρχεται ὁ Σατανᾶς, καὶ αἴρει τὸν λόγον τὸν ἐσπαρμένον εἰς αὐτούς.
41004016 Καὶ οὗτοί εἰσιν ὁμοίως οἱ ἐπὶ τὰ πετρώδη σπειρόμενοι, οἳ ὅταν ἀκούσωσιν τὸν λόγον, εὐθὺς μετὰ χαρᾶς λαμβάνουσιν αὐτόν,
41004017 καὶ οὐκ ἔχουσιν ῥίζαν ἐν ἑαυτοῖς, ἀλλὰ πρόσκαιροί εἰσιν, εἶτα γενομένης θλίψεως ἢ διωγμοῦ διὰ τὸν λόγον, εὐθὺς σκανδαλίζονται.
41004018 Καὶ ἄλλοι εἰσὶν οἱ εἰς τὰς ἀκάνθας σπειρόμενοι, οὗτοί εἰσιν οἱ τὸν λόγον ἀκούσαντες,
41004019 καὶ αἱ μέριμναι τοῦ αἰῶνος, καὶ ἡ ἀπάτη τοῦ πλούτου, καὶ αἱ περὶ τὰ λοιπὰ ἐπιθυμίαι εἰσπορευόμεναι, συμπνίγουσιν τὸν λόγον, καὶ ἄκαρπος γίνεται.
41004020 Καὶ ἐκεῖνοί εἰσιν οἱ ἐπὶ τὴν γῆν τὴν καλὴν σπαρέντες, οἵτινες ἀκούουσιν τὸν λόγον καὶ παραδέχονται καὶ καρποφοροῦσιν, ἓν τριάκοντα, καὶ ἓν ἑξήκοντα, καὶ ἓν ἑκατόν.”
41004021 Καὶ ἔλεγεν αὐτοῖς, “Μήτι ἔρχεται ὁ λύχνος, ἵνα ὑπὸ τὸν μόδιον τεθῇ, ἢ ὑπὸ τὴν κλίνην; Οὐχ ἵνα ἐπὶ τὴν λυχνίαν τεθῇ;
41004022 Οὐ γάρ ἐστιν κρυπτὸν, ἐὰν μὴ ἵνα φανερωθῇ, οὐδὲ ἐγένετο ἀπόκρυφον, ἀλλʼ ἵνα ἔλθῃ εἰς φανερόν.
41004023 Εἴ τις ἔχει ὦτα ἀκούειν, ἀκουέτω.”
41004024 Καὶ ἔλεγεν αὐτοῖς, “Βλέπετε τί ἀκούετε. Ἐν ᾧ μέτρῳ μετρεῖτε μετρηθήσεται ὑμῖν, καὶ προστεθήσεται ὑμῖν.
41004025 Ὃς γὰρ ἔχει, δοθήσεται αὐτῷ· καὶ ὃς οὐκ ἔχει, καὶ ὃ ἔχει ἀρθήσεται ἀπʼ αὐτοῦ.”
41004026 Καὶ ἔλεγεν, “Οὕτως ἐστὶν ἡ Βασιλεία τοῦ ˚Θεοῦ: ὡς ἄνθρωπος βάλῃ τὸν σπόρον ἐπὶ τῆς γῆς,
41004027 καὶ καθεύδῃ καὶ ἐγείρηται νύκτα καὶ ἡμέραν, καὶ ὁ σπόρος βλαστᾷ καὶ μηκύνηται– ὡς οὐκ οἶδεν αὐτός.
41004028 Αὐτομάτη ἡ γῆ καρποφορεῖ: πρῶτον χόρτον, εἶτα στάχυν, εἶτα πλήρης σῖτον ἐν τῷ στάχυϊ.
41004029 Ὅταν δὲ παραδοῖ ὁ καρπός, εὐθὺς ἀποστέλλει τὸ δρέπανον, ὅτι παρέστηκεν ὁ θερισμός.”
41004030 Καὶ ἔλεγεν, “Πῶς ὁμοιώσωμεν τὴν Βασιλείαν τοῦ ˚Θεοῦ, ἢ ἐν τίνι αὐτὴν παραβολῇ θῶμεν;
41004031 Ὡς κόκκῳ σινάπεως, ὃς ὅταν σπαρῇ ἐπὶ τῆς γῆς, μικρότερον ὂν πάντων τῶν σπερμάτων τῶν ἐπὶ τῆς γῆς,
41004032 καὶ ὅταν σπαρῇ, ἀναβαίνει καὶ γίνεται μεῖζον πάντων τῶν λαχάνων, καὶ ποιεῖ κλάδους μεγάλους, ὥστε ‘δύνασθαι ὑπὸ τὴν σκιὰν αὐτοῦ τὰ πετεινὰ τοῦ οὐρανοῦ κατασκηνοῦν’.”
41004033 Καὶ τοιαύταις παραβολαῖς πολλαῖς, ἐλάλει αὐτοῖς τὸν λόγον, καθὼς ἠδύναντο ἀκούειν·
41004034 χωρὶς δὲ παραβολῆς οὐκ ἐλάλει αὐτοῖς, κατʼ ἰδίαν δὲ τοῖς ἰδίοις μαθηταῖς, ἐπέλυεν πάντα.
41004035 Καὶ λέγει αὐτοῖς ἐν ἐκείνῃ τῇ ἡμέρᾳ ὀψίας γενομένης, “Διέλθωμεν εἰς τὸ πέραν.”
41004036 Καὶ ἀφέντες τὸν ὄχλον, παραλαμβάνουσιν αὐτὸν ὡς ἦν ἐν τῷ πλοίῳ. Καὶ ἄλλα πλοῖα ἦν μετʼ αὐτοῦ.
41004037 Καὶ γίνεται λαῖλαψ μεγάλη ἀνέμου, καὶ τὰ κύματα ἐπέβαλλεν εἰς τὸ πλοῖον, ὥστε ἤδη γεμίζεσθαι τὸ πλοῖον.
41004038 Καὶ αὐτὸς ἦν ἐν τῇ πρύμνῃ, ἐπὶ τὸ προσκεφάλαιον καθεύδων. Καὶ ἐγείρουσιν αὐτὸν καὶ λέγουσιν αὐτῷ, “Διδάσκαλε, οὐ μέλει σοι ὅτι ἀπολλύμεθα;”
41004039 Καὶ διεγερθεὶς, ἐπετίμησεν τῷ ἀνέμῳ καὶ εἶπεν τῇ θαλάσσῃ, “Σιώπα, πεφίμωσο.” Καὶ ἐκόπασεν ὁ ἄνεμος, καὶ ἐγένετο γαλήνη μεγάλη.
41004040 Καὶ εἶπεν αὐτοῖς, “Τί δειλοί ἐστε; Οὔπω ἔχετε πίστιν;”
41004041 Καὶ ἐφοβήθησαν φόβον μέγαν, καὶ ἔλεγον πρὸς ἀλλήλους, “Τίς ἄρα οὗτός ἐστιν, ὅτι καὶ ὁ ἄνεμος καὶ ἡ θάλασσα αὐτῷ ὑπακούει;”
41005001 Καὶ ἦλθον εἰς τὸ πέραν τῆς θαλάσσης, εἰς τὴν χώραν τῶν Γερασηνῶν.
41005002 Καὶ ἐξελθόντος αὐτοῦ ἐκ τοῦ πλοίου, ὑπήντησεν αὐτῷ ἐκ τῶν μνημείων ἄνθρωπος ἐν πνεύματι ἀκαθάρτῳ,
41005003 ὃς τὴν κατοίκησιν εἶχεν ἐν τοῖς μνήμασιν, καὶ οὐδὲ ἁλύσει οὐκέτι οὐδεὶς ἐδύνατο αὐτὸν δῆσαι,
41005004 διὰ τὸ αὐτὸν πολλάκις πέδαις καὶ ἁλύσεσι δεδέσθαι, καὶ διεσπάσθαι ὑπʼ αὐτοῦ τὰς ἁλύσεις καὶ τὰς πέδας συντετρῖφθαι, καὶ οὐδεὶς ἴσχυεν αὐτὸν δαμάσαι.
41005005 Καὶ διὰ παντὸς νυκτὸς καὶ ἡμέρας ἐν τοῖς μνήμασιν καὶ ἐν τοῖς ὄρεσιν, ἦν κράζων καὶ κατακόπτων ἑαυτὸν λίθοις.
41005006 Καὶ ἰδὼν τὸν ˚Ἰησοῦν ἀπὸ μακρόθεν, ἔδραμεν καὶ προσεκύνησεν αὐτόν,
41005007 καὶ κράξας φωνῇ μεγάλῃ λέγει, “Τί ἐμοὶ καὶ σοί ˚Ἰησοῦ, Υἱὲ τοῦ ˚Θεοῦ τοῦ Ὑψίστου; Ὁρκίζω σε τὸν ˚Θεόν, μή με βασανίσῃς.”
41005008 Ἔλεγεν γὰρ αὐτῷ, “Ἔξελθε, τὸ πνεῦμα τὸ ἀκάθαρτον ἐκ τοῦ ἀνθρώπου!”
41005009 Καὶ ἐπηρώτα αὐτόν, “Τί ὄνομά σοι;” Καὶ λέγει αὐτῷ, “Λεγιὼν ὄνομά μοι, ὅτι πολλοί ἐσμεν.”
41005010 Καὶ παρεκάλει αὐτὸν πολλὰ ἵνα μὴ αὐτὰ ἀποστείλῃ ἔξω τῆς χώρας.
41005011 Ἦν δὲ ἐκεῖ πρὸς τῷ ὄρει, ἀγέλη χοίρων μεγάλη βοσκομένη·
41005012 καὶ παρεκάλεσαν αὐτὸν λέγοντες, “Πέμψον ἡμᾶς εἰς τοὺς χοίρους, ἵνα εἰς αὐτοὺς εἰσέλθωμεν.”
41005013 Καὶ ἐπέτρεψεν αὐτοῖς. Καὶ ἐξελθόντα, τὰ πνεύματα τὰ ἀκάθαρτα εἰσῆλθον εἰς τοὺς χοίρους, καὶ ὥρμησεν ἡ ἀγέλη κατὰ τοῦ κρημνοῦ εἰς τὴν θάλασσαν, ὡς δισχίλιοι, καὶ ἐπνίγοντο ἐν τῇ θαλάσσῃ.
41005014 Καὶ οἱ βόσκοντες αὐτοὺς ἔφυγον, καὶ ἀπήγγειλαν εἰς τὴν πόλιν καὶ εἰς τοὺς ἀγρούς. Καὶ ἐξῆλθον ἰδεῖν τί ἐστιν τὸ γεγονός.
41005015 Καὶ ἔρχονται πρὸς τὸν ˚Ἰησοῦν, καὶ θεωροῦσιν τὸν δαιμονιζόμενον καθήμενον, ἱματισμένον καὶ σωφρονοῦντα, τὸν ἐσχηκότα τὸν λεγιῶνα, καὶ ἐφοβήθησαν.
41005016 Καὶ διηγήσαντο αὐτοῖς οἱ ἰδόντες, πῶς ἐγένετο τῷ δαιμονιζομένῳ, καὶ περὶ τῶν χοίρων.
41005017 Καὶ ἤρξαντο παρακαλεῖν αὐτὸν ἀπελθεῖν ἀπὸ τῶν ὁρίων αὐτῶν.
41005018 Καὶ ἐμβαίνοντος αὐτοῦ εἰς τὸ πλοῖον, παρεκάλει αὐτὸν ὁ δαιμονισθεὶς ἵνα μετʼ αὐτοῦ ᾖ.
41005019 Καὶ οὐκ ἀφῆκεν αὐτόν, ἀλλὰ λέγει αὐτῷ, “Ὕπαγε εἰς τὸν οἶκόν σου, πρὸς τοὺς σούς, καὶ ἀπάγγειλον αὐτοῖς ὅσα ὁ ˚Κύριός σοι πεποίηκεν, καὶ ἠλέησέν σε.”
41005020 Καὶ ἀπῆλθεν καὶ ἤρξατο κηρύσσειν ἐν τῇ Δεκαπόλει, ὅσα ἐποίησεν αὐτῷ ὁ ˚Ἰησοῦς, καὶ πάντες ἐθαύμαζον.
41005021 Καὶ διαπεράσαντος τοῦ ˚Ἰησοῦ ἐν τῷ πλοίῳ πάλιν εἰς τὸ πέραν, συνήχθη ὄχλος πολὺς ἐπʼ αὐτόν, καὶ ἦν παρὰ τὴν θάλασσαν.
41005022 Καὶ ἰδοὺ, ἔρχεται εἷς τῶν ἀρχισυναγώγων ὀνόματι Ἰάειρος, καὶ ἰδὼν αὐτὸν, πίπτει πρὸς τοὺς πόδας αὐτοῦ,
41005023 καὶ παρακαλεῖ αὐτὸν πολλὰ λέγων, ὅτι “Τὸ θυγάτριόν μου ἐσχάτως ἔχει, ἵνα ἐλθὼν, ἐπιθῇς τὰς χεῖρας αὐτῇ, ἵνα σωθῇ καὶ ζήσῃ.”
41005024 Καὶ ἀπῆλθεν μετʼ αὐτοῦ. Καὶ ἠκολούθει αὐτῷ ὄχλος πολύς, καὶ συνέθλιβον αὐτόν.
41005025 Καὶ γυνὴ οὖσα ἐν ῥύσει αἵματος δώδεκα ἔτη,
41005026 καὶ πολλὰ παθοῦσα ὑπὸ πολλῶν ἰατρῶν, καὶ δαπανήσασα τὰ παρʼ ἑαυτῆς πάντα, καὶ μηδὲν ὠφεληθεῖσα, ἀλλὰ μᾶλλον εἰς τὸ χεῖρον ἐλθοῦσα,
41005027 ἀκούσασα τὰ περὶ τοῦ ˚Ἰησοῦ, ἐλθοῦσα ἐν τῷ ὄχλῳ ὄπισθεν, ἥψατο τοῦ ἱματίου αὐτοῦ.
41005028 Ἔλεγεν γὰρ, ὅτι “Ἐὰν ἅψωμαι κἂν τῶν ἱματίων αὐτοῦ, σωθήσομαι.”
41005029 Καὶ εὐθὺς ἐξηράνθη ἡ πηγὴ τοῦ αἵματος αὐτῆς, καὶ ἔγνω τῷ σώματι ὅτι ἴαται ἀπὸ τῆς μάστιγος.
41005030 Καὶ εὐθὺς ὁ ˚Ἰησοῦς, ἐπιγνοὺς ἐν ἑαυτῷ τὴν ἐξ αὐτοῦ δύναμιν ἐξελθοῦσαν, ἐπιστραφεὶς ἐν τῷ ὄχλῳ ἔλεγεν, “Τίς μου ἥψατο τῶν ἱματίων;”
41005031 Καὶ ἔλεγον αὐτῷ οἱ μαθηταὶ αὐτοῦ, “Βλέπεις τὸν ὄχλον συνθλίβοντά σε, καὶ λέγεις, ‘Τίς μου ἥψατο;’”
41005032 Καὶ περιεβλέπετο ἰδεῖν τὴν τοῦτο ποιήσασαν.
41005033 Ἡ δὲ γυνὴ, φοβηθεῖσα καὶ τρέμουσα, εἰδυῖα ὃ γέγονεν αὐτῇ, ἦλθεν καὶ προσέπεσεν αὐτῷ, καὶ εἶπεν αὐτῷ πᾶσαν τὴν ἀλήθειαν.
41005034 Ὁ δὲ εἶπεν αὐτῇ, “Θυγάτηρ, ἡ πίστις σου σέσωκέν σε· ὕπαγε εἰς εἰρήνην, καὶ ἴσθι ὑγιὴς ἀπὸ τῆς μάστιγός σου.”
41005035 Ἔτι αὐτοῦ λαλοῦντος, ἔρχονται ἀπὸ τοῦ ἀρχισυναγώγου λέγοντες, ὅτι “Ἡ θυγάτηρ σου ἀπέθανεν· τί ἔτι σκύλλεις τὸν διδάσκαλον;”
41005036 Ὁ δὲ ˚Ἰησοῦς παρακούσας τὸν λόγον λαλούμενον λέγει τῷ ἀρχισυναγώγῳ, “Μὴ φοβοῦ, μόνον πίστευε.”
41005037 Καὶ οὐκ ἀφῆκεν οὐδένα μετʼ αὐτοῦ συνακολουθῆσαι, εἰ μὴ τὸν Πέτρον, καὶ Ἰάκωβον, καὶ Ἰωάννην τὸν ἀδελφὸν Ἰακώβου.
41005038 Καὶ ἔρχονται εἰς τὸν οἶκον τοῦ ἀρχισυναγώγου, καὶ θεωρεῖ θόρυβον, καὶ κλαίοντας καὶ ἀλαλάζοντας πολλά.
41005039 Καὶ εἰσελθὼν λέγει αὐτοῖς, “Τί θορυβεῖσθε καὶ κλαίετε; Τὸ παιδίον οὐκ ἀπέθανεν, ἀλλὰ καθεύδει.”
41005040 Καὶ κατεγέλων αὐτοῦ. Αὐτὸς δὲ ἐκβαλὼν πάντας, παραλαμβάνει τὸν πατέρα τοῦ παιδίου, καὶ τὴν μητέρα, καὶ τοὺς μετʼ αὐτοῦ, καὶ εἰσπορεύεται ὅπου ἦν τὸ παιδίον.
41005041 Καὶ κρατήσας τῆς χειρὸς τοῦ παιδίου, λέγει αὐτῇ, “Ταλιθά, κούμ!” Ὅ ἐστιν μεθερμηνευόμενον “Τὸ κοράσιον, σοὶ λέγω, ἔγειρε!”
41005042 Καὶ εὐθὺς ἀνέστη τὸ κοράσιον καὶ περιεπάτει, ἦν γὰρ ἐτῶν δώδεκα. Καὶ ἐξέστησαν εὐθὺς ἐκστάσει μεγάλῃ.
41005043 Καὶ διεστείλατο αὐτοῖς πολλὰ ἵνα μηδεὶς γνοῖ τοῦτο, καὶ εἶπεν δοθῆναι αὐτῇ φαγεῖν.
41006001 Καὶ ἐξῆλθεν ἐκεῖθεν, καὶ ἔρχεται εἰς τὴν πατρίδα αὐτοῦ, καὶ ἀκολουθοῦσιν αὐτῷ οἱ μαθηταὶ αὐτοῦ.
41006002 Καὶ γενομένου Σαββάτου, ἤρξατο διδάσκειν ἐν τῇ συναγωγῇ· καὶ πολλοὶ ἀκούοντες ἐξεπλήσσοντο λέγοντες, “Πόθεν τούτῳ ταῦτα, καὶ τίς ἡ σοφία ἡ δοθεῖσα τούτῳ, καὶ αἱ δυνάμεις τοιαῦται διὰ τῶν χειρῶν αὐτοῦ γινόμεναι;
41006003 Οὐχ οὗτός ἐστιν ὁ τέκτων, ὁ υἱὸς τῆς Μαρίας, καὶ ἀδελφὸς Ἰακώβου, καὶ Ἰωσῆτος, καὶ Ἰούδα, καὶ Σίμωνος; Καὶ οὐκ εἰσὶν αἱ ἀδελφαὶ αὐτοῦ ὧδε πρὸς ἡμᾶς;” Καὶ ἐσκανδαλίζοντο ἐν αὐτῷ.
41006004 Καὶ ἔλεγεν αὐτοῖς ὁ ˚Ἰησοῦς, ὅτι “Οὐκ ἔστιν προφήτης ἄτιμος, εἰ μὴ ἐν τῇ πατρίδι αὐτοῦ, καὶ ἐν τοῖς συγγενεῦσιν αὐτοῦ, καὶ ἐν τῇ οἰκίᾳ αὐτοῦ.”
41006005 Καὶ οὐκ ἐδύνατο ἐκεῖ ποιῆσαι οὐδεμίαν δύναμιν, εἰ μὴ ὀλίγοις ἀρρώστοις, ἐπιθεὶς τὰς χεῖρας, ἐθεράπευσεν.
41006006 Καὶ ἐθαύμασεν διὰ τὴν ἀπιστίαν αὐτῶν. Καὶ περιῆγεν τὰς κώμας, κύκλῳ διδάσκων.
41006007 Καὶ προσκαλεῖται τοὺς δώδεκα, καὶ ἤρξατο αὐτοὺς ἀποστέλλειν δύο δύο, καὶ ἐδίδου αὐτοῖς ἐξουσίαν τῶν πνευμάτων τῶν ἀκαθάρτων.
41006008 Καὶ παρήγγειλεν αὐτοῖς ἵνα μηδὲν αἴρωσιν εἰς ὁδὸν, εἰ μὴ ῥάβδον μόνον, μὴ ἄρτον, μὴ πήραν, μὴ εἰς τὴν ζώνην χαλκόν,
41006009 ἀλλὰ ὑποδεδεμένους σανδάλια, καὶ μὴ ἐνδύσησθε δύο χιτῶνας.
41006010 Καὶ ἔλεγεν αὐτοῖς, “Ὅπου ἐὰν εἰσέλθητε εἰς οἰκίαν, ἐκεῖ μένετε ἕως ἂν ἐξέλθητε ἐκεῖθεν.
41006011 Καὶ ὃς ἂν τόπος μὴ δέξηται ὑμᾶς, μηδὲ ἀκούσωσιν ὑμῶν, ἐκπορευόμενοι ἐκεῖθεν, ἐκτινάξατε τὸν χοῦν τὸν ὑποκάτω τῶν ποδῶν ὑμῶν, εἰς μαρτύριον αὐτοῖς.”
41006012 Καὶ ἐξελθόντες, ἐκήρυξαν ἵνα μετανοῶσιν.
41006013 Καὶ δαιμόνια πολλὰ ἐξέβαλλον, καὶ ἤλειφον ἐλαίῳ πολλοὺς ἀρρώστους καὶ ἐθεράπευον.
41006014 Καὶ ἤκουσεν ὁ βασιλεὺς Ἡρῴδης, φανερὸν γὰρ ἐγένετο τὸ ὄνομα αὐτοῦ, καὶ ἔλεγεν, ὅτι “Ἰωάννης ὁ βαπτίζων ἐγήγερται ἐκ νεκρῶν, καὶ διὰ τοῦτο ἐνεργοῦσιν αἱ δυνάμεις ἐν αὐτῷ.”
41006015 Ἄλλοι δὲ ἔλεγον, ὅτι “Ἠλίας ἐστίν”· ἄλλοι δὲ ἔλεγον, ὅτι “Προφήτης, ὡς εἷς τῶν προφητῶν.”
41006016 Ἀκούσας δὲ, ὁ Ἡρῴδης ἔλεγεν, ὅτι “Ὃν ἐγὼ ἀπεκεφάλισα Ἰωάννην, οὗτος ἠγέρθη!”
41006017 Αὐτὸς γὰρ ὁ Ἡρῴδης, ἀποστείλας ἐκράτησεν τὸν Ἰωάννην, καὶ ἔδησεν αὐτὸν ἐν φυλακῇ διὰ Ἡρῳδιάδα, τὴν γυναῖκα Φιλίππου, τοῦ ἀδελφοῦ αὐτοῦ, ὅτι αὐτὴν ἐγάμησεν.
41006018 Ἔλεγεν γὰρ ὁ Ἰωάννης τῷ Ἡρῴδῃ, ὅτι “Οὐκ ἔξεστίν σοι ἔχειν τὴν γυναῖκα τοῦ ἀδελφοῦ σου.”
41006019 Ἡ δὲ Ἡρῳδιὰς ἐνεῖχεν αὐτῷ, καὶ ἤθελεν αὐτὸν ἀποκτεῖναι, καὶ οὐκ ἠδύνατο·
41006020 ὁ γὰρ Ἡρῴδης ἐφοβεῖτο τὸν Ἰωάννην, εἰδὼς αὐτὸν ἄνδρα δίκαιον καὶ ἅγιον, καὶ συνετήρει αὐτόν, καὶ ἀκούσας αὐτοῦ, πολλὰ ἠπόρει, καὶ ἡδέως αὐτοῦ ἤκουεν.
41006021 Καὶ γενομένης ἡμέρας εὐκαίρου, ὅτε Ἡρῴδης τοῖς γενεσίοις αὐτοῦ δεῖπνον ἐποίησεν, τοῖς μεγιστᾶσιν αὐτοῦ, καὶ τοῖς χιλιάρχοις, καὶ τοῖς πρώτοις τῆς Γαλιλαίας.
41006022 Καὶ εἰσελθούσης τῆς θυγατρὸς αὐτοῦ Ἡρῳδιάδος, καὶ ὀρχησαμένης ἤρεσεν τῷ Ἡρῴδῃ καὶ τοῖς συνανακειμένοις. Ὁ δὲ βασιλεὺς εἶπεν τῷ κορασίῳ, “Αἴτησόν με ὃ ἐὰν θέλῃς, καὶ δώσω σοι.”
41006023 Καὶ ὤμοσεν αὐτῇ, ὅτι “Ὃ ἐάν αἰτήσῃς, με, δώσω σοι, ἕως ἡμίσους τῆς βασιλείας μου.”
41006024 Καὶ ἐξελθοῦσα, εἶπεν τῇ μητρὶ αὐτῆς, “Τί αἰτήσωμαι;” Ἡ δὲ εἶπεν, “Τὴν κεφαλὴν Ἰωάννου τοῦ βαπτίζοντος.”
41006025 Καὶ εἰσελθοῦσα εὐθὺς μετὰ σπουδῆς πρὸς τὸν βασιλέα, ᾐτήσατο λέγουσα, “Θέλω ἵνα ἐξαυτῆς δῷς μοι ἐπὶ πίνακι τὴν κεφαλὴν Ἰωάννου τοῦ Βαπτιστοῦ.”
41006026 Καὶ περίλυπος γενόμενος ὁ βασιλεὺς, διὰ τοὺς ὅρκους καὶ τοὺς συνανακειμένους οὐκ ἠθέλησεν ἀθετῆσαι αὐτήν.
41006027 Καὶ εὐθὺς ἀποστείλας ὁ βασιλεὺς σπεκουλάτορα, ἐπέταξεν ἐνέγκαι τὴν κεφαλὴν αὐτοῦ. Καὶ ἀπελθὼν, ἀπεκεφάλισεν αὐτὸν ἐν τῇ φυλακῇ,
41006028 καὶ ἤνεγκεν τὴν κεφαλὴν αὐτοῦ ἐπὶ πίνακι, καὶ ἔδωκεν αὐτὴν τῷ κορασίῳ, καὶ τὸ κοράσιον ἔδωκεν αὐτὴν τῇ μητρὶ αὐτῆς.
41006029 Καὶ ἀκούσαντες, οἱ μαθηταὶ αὐτοῦ ἦλθον, καὶ ἦραν τὸ πτῶμα αὐτοῦ, καὶ ἔθηκαν αὐτὸ ἐν μνημείῳ.
41006030 Καὶ συνάγονται οἱ ἀπόστολοι πρὸς τὸν ˚Ἰησοῦν καὶ ἀπήγγειλαν αὐτῷ, πάντα ὅσα ἐποίησαν καὶ ὅσα ἐδίδαξαν.
41006031 Καὶ λέγει αὐτοῖς, “Δεῦτε ὑμεῖς αὐτοὶ κατʼ ἰδίαν εἰς ἔρημον τόπον, καὶ ἀναπαύσασθε ὀλίγον.” Ἦσαν γὰρ οἱ ἐρχόμενοι καὶ οἱ ὑπάγοντες πολλοί, καὶ οὐδὲ φαγεῖν εὐκαίρουν.
41006032 Καὶ ἀπῆλθον ἐν τῷ πλοίῳ εἰς ἔρημον τόπον κατʼ ἰδίαν.
41006033 Καὶ εἶδον αὐτοὺς ὑπάγοντας, καὶ ἐπέγνωσαν πολλοί, καὶ πεζῇ ἀπὸ πασῶν τῶν πόλεων συνέδραμον ἐκεῖ, καὶ προῆλθον αὐτούς.
41006034 Καὶ ἐξελθὼν, εἶδεν πολὺν ὄχλον καὶ ἐσπλαγχνίσθη ἐπʼ αὐτοὺς, ὅτι ἦσαν ὡς πρόβατα μὴ ἔχοντα ποιμένα, καὶ ἤρξατο διδάσκειν αὐτοὺς πολλά.
41006035 Καὶ ἤδη ὥρας πολλῆς γενομένης, προσελθόντες αὐτῷ οἱ μαθηταὶ αὐτοῦ ἔλεγον, ὅτι “Ἔρημός ἐστιν ὁ τόπος, καὶ ἤδη ὥρα πολλή·
41006036 ἀπόλυσον αὐτούς, ἵνα ἀπελθόντες εἰς τοὺς κύκλῳ ἀγροὺς καὶ κώμας, ἀγοράσωσιν ἑαυτοῖς τί φάγωσιν.”
41006037 Ὁ δὲ ἀποκριθεὶς εἶπεν αὐτοῖς, “Δότε αὐτοῖς ὑμεῖς φαγεῖν.” Καὶ λέγουσιν αὐτῷ, “Ἀπελθόντες, ἀγοράσωμεν δηναρίων διακοσίων ἄρτους, καὶ δώσομεν αὐτοῖς φαγεῖν;”
41006038 Ὁ δὲ λέγει αὐτοῖς, “Πόσους ἄρτους ἔχετε; Ὑπάγετε, ἴδετε.” Καὶ γνόντες λέγουσιν, “Πέντε καὶ δύο ἰχθύας.”
41006039 Καὶ ἐπέταξεν αὐτοῖς ἀνακλῖναι πάντας, συμπόσια συμπόσια ἐπὶ τῷ χλωρῷ χόρτῳ.
41006040 Καὶ ἀνέπεσαν πρασιαὶ πρασιαὶ, κατὰ ἑκατὸν καὶ κατὰ πεντήκοντα.
41006041 Καὶ λαβὼν τοὺς πέντε ἄρτους καὶ τοὺς δύο ἰχθύας, ἀναβλέψας εἰς τὸν οὐρανὸν, εὐλόγησεν καὶ κατέκλασεν τοὺς ἄρτους, καὶ ἐδίδου τοῖς μαθηταῖς αὐτοῦ, ἵνα παρατιθῶσιν αὐτοῖς, καὶ τοὺς δύο ἰχθύας ἐμέρισεν πᾶσιν.
41006042 Καὶ ἔφαγον πάντες καὶ ἐχορτάσθησαν.
41006043 Καὶ ἦραν κλάσματα δώδεκα κοφίνων πληρώματα, καὶ ἀπὸ τῶν ἰχθύων.
41006044 Καὶ ἦσαν οἱ φαγόντες τοὺς ἄρτους, πεντακισχίλιοι ἄνδρες.
41006045 Καὶ εὐθὺς ἠνάγκασεν τοὺς μαθητὰς αὐτοῦ ἐμβῆναι εἰς τὸ πλοῖον, καὶ προάγειν εἰς τὸ πέραν πρὸς Βηθσαϊδάν, ἕως αὐτὸς ἀπολύει τὸν ὄχλον.
41006046 Καὶ ἀποταξάμενος αὐτοῖς, ἀπῆλθεν εἰς τὸ ὄρος προσεύξασθαι.
41006047 Καὶ ὀψίας γενομένης, ἦν τὸ πλοῖον ἐν μέσῳ τῆς θαλάσσης, καὶ αὐτὸς μόνος ἐπὶ τῆς γῆς.
41006048 Καὶ ἰδὼν αὐτοὺς βασανιζομένους ἐν τῷ ἐλαύνειν, ἦν γὰρ ὁ ἄνεμος ἐναντίος αὐτοῖς, περὶ τετάρτην φυλακὴν τῆς νυκτὸς, ἔρχεται πρὸς αὐτοὺς, περιπατῶν ἐπὶ τῆς θαλάσσης, καὶ ἤθελεν παρελθεῖν αὐτούς.
41006049 Οἱ, δὲ ἰδόντες αὐτὸν ἐπὶ τῆς θαλάσσης περιπατοῦντα, ἔδοξαν ὅτι φάντασμά ἐστιν, καὶ ἀνέκραξαν.
41006050 Πάντες γὰρ αὐτὸν εἶδον, καὶ ἐταράχθησαν. Ὁ δὲ εὐθὺς ἐλάλησεν μετʼ αὐτῶν, καὶ λέγει αὐτοῖς, “Θαρσεῖτε, ἐγώ εἰμι, μὴ φοβεῖσθε.”
41006051 Καὶ ἀνέβη πρὸς αὐτοὺς εἰς τὸ πλοῖον, καὶ ἐκόπασεν ὁ ἄνεμος. Καὶ λίαν ἐν ἑαυτοῖς ἐξίσταντο,
41006052 οὐ γὰρ συνῆκαν ἐπὶ τοῖς ἄρτοις, ἀλλʼ ἦν αὐτῶν ἡ καρδία πεπωρωμένη.
41006053 Καὶ διαπεράσαντες ἐπὶ τὴν γῆν ἦλθον εἰς Γεννησαρὲτ, καὶ προσωρμίσθησαν.
41006054 Καὶ ἐξελθόντων αὐτῶν ἐκ τοῦ πλοίου, εὐθὺς ἐπιγνόντες αὐτὸν,
41006055 περιέδραμον ὅλην τὴν χώραν ἐκείνην, καὶ ἤρξαντο ἐπὶ τοῖς κραβάττοις τοὺς κακῶς ἔχοντας περιφέρειν, ὅπου ἤκουον ὅτι ἐστίν.
41006056 Καὶ ὅπου ἂν εἰσεπορεύετο εἰς κώμας, ἢ εἰς πόλεις, ἢ εἰς ἀγροὺς, ἐν ταῖς ἀγοραῖς ἐτίθεσαν τοὺς ἀσθενοῦντας, καὶ παρεκάλουν αὐτὸν ἵνα κἂν τοῦ κρασπέδου τοῦ ἱματίου αὐτοῦ ἅψωνται· καὶ ὅσοι ἂν ἥψαντο αὐτοῦ ἐσῴζοντο.
41007001 Καὶ συνάγονται πρὸς αὐτὸν οἱ Φαρισαῖοι καί τινες τῶν γραμματέων, ἐλθόντες ἀπὸ Ἱεροσολύμων,
41007002 καὶ ἰδόντες τινὰς τῶν μαθητῶν αὐτοῦ, ὅτι κοιναῖς χερσίν, τοῦτʼ ἔστιν ἀνίπτοις, ἐσθίουσιν τοὺς ἄρτους.
41007003 ( Οἱ γὰρ Φαρισαῖοι καὶ πάντες οἱ Ἰουδαῖοι, ἐὰν μὴ πυγμῇ νίψωνται τὰς χεῖρας οὐκ ἐσθίουσιν, κρατοῦντες τὴν παράδοσιν τῶν πρεσβυτέρων,
41007004 καὶ ἀπʼ ἀγορᾶς ἐὰν μὴ βαπτίσωνται οὐκ ἐσθίουσιν, καὶ ἄλλα πολλά ἐστιν ἃ παρέλαβον κρατεῖν, βαπτισμοὺς ποτηρίων καὶ ξεστῶν καὶ χαλκίων. )
41007005 Καὶ ἐπερωτῶσιν αὐτὸν οἱ Φαρισαῖοι καὶ οἱ γραμματεῖς, “Διὰ τί οἱ μαθηταί σου οὐ περιπατοῦσιν κατὰ τὴν παράδοσιν τῶν πρεσβυτέρων, ἀλλὰ κοιναῖς χερσὶν ἐσθίουσιν τὸν ἄρτον;”
41007006 Ὁ δὲ ἀποκριθεὶς εἶπεν αὐτοῖς, “Καλῶς ἐπροφήτευσεν Ἠσαΐας περὶ ὑμῶν τῶν ὑποκριτῶν, ὡς γέγραπται, ὅτι ‘Οὗτος ὁ λαὸς τοῖς χείλεσίν με τιμᾷ, ἡ δὲ καρδία αὐτῶν πόρρω ἀπέχει ἀπʼ ἐμοῦ.
41007007 Μάτην δὲ σέβονταί με, διδάσκοντες διδασκαλίας ἐντάλματα ἀνθρώπων.’
41007008 Ἀφέντες τὴν ἐντολὴν τοῦ ˚Θεοῦ, κρατεῖτε τὴν παράδοσιν τῶν ἀνθρώπων.”
41007009 Καὶ ἔλεγεν αὐτοῖς, “Καλῶς ἀθετεῖτε τὴν ἐντολὴν τοῦ ˚Θεοῦ, ἵνα τὴν παράδοσιν ὑμῶν τηρήσητε.
41007010 Μωϋσῆς γὰρ εἶπεν, ‘Τίμα τὸν πατέρα σου καὶ τὴν μητέρα σου’, καί, ‘Ὁ κακολογῶν πατέρα ἢ μητέρα θανάτῳ τελευτάτω.’
41007011 Ὑμεῖς δὲ λέγετε, ‘Ἐὰν εἴπῃ ἄνθρωπος τῷ πατρὶ ἢ τῇ μητρί, “Κορβᾶν”’, ( ὅ ἐστιν “Δῶρον” ), ‘“ὃ ἐὰν ἐξ ἐμοῦ ὠφεληθῇς”’,
41007012 οὐκέτι ἀφίετε αὐτὸν οὐδὲν ποιῆσαι τῷ πατρὶ ἢ τῇ μητρί,
41007013 ἀκυροῦντες τὸν λόγον τοῦ ˚Θεοῦ τῇ παραδόσει ὑμῶν ᾗ παρεδώκατε. Καὶ παρόμοια τοιαῦτα πολλὰ ποιεῖτε.”
41007014 Καὶ προσκαλεσάμενος πάλιν τὸν ὄχλον, ἔλεγεν αὐτοῖς, “Ἀκούσατέ μου πάντες καὶ συνίετε:
41007015 οὐδέν ἐστιν ἔξωθεν τοῦ ἀνθρώπου εἰσπορευόμενον εἰς αὐτὸν, ὃ δύναται αὐτὸν κοινῶσαι ἀλλὰ τὰ ἐκ τοῦ ἀνθρώπου ἐκπορευόμενά, ἐστιν τὰ κοινοῦντα τὸν ἄνθρωπον.”
41007017 Καὶ ὅτε εἰσῆλθεν εἰς οἶκον ἀπὸ τοῦ ὄχλου, ἐπηρώτων αὐτὸν οἱ μαθηταὶ αὐτοῦ τὴν παραβολήν.
41007018 Καὶ λέγει αὐτοῖς, “Οὕτως καὶ ὑμεῖς ἀσύνετοί ἐστε; Οὐ νοεῖτε ὅτι πᾶν τὸ ἔξωθεν εἰσπορευόμενον εἰς τὸν ἄνθρωπον, οὐ δύναται αὐτὸν κοινῶσαι,
41007019 ὅτι οὐκ εἰσπορεύεται αὐτοῦ εἰς τὴν καρδίαν, ἀλλʼ εἰς τὴν κοιλίαν, καὶ εἰς τὸν ἀφεδρῶνα ἐκπορεύεται;” ( Καθαρίζων πάντα τὰ βρώματα. )
41007020 Ἔλεγεν δὲ, ὅτι “Τὸ ἐκ τοῦ ἀνθρώπου ἐκπορευόμενον, ἐκεῖνο κοινοῖ τὸν ἄνθρωπον.
41007021 Ἔσωθεν γὰρ ἐκ τῆς καρδίας τῶν ἀνθρώπων οἱ διαλογισμοὶ οἱ κακοὶ ἐκπορεύονται, πορνεῖαι, κλοπαί, φόνοι, μοιχεῖαι,
41007022 πλεονεξίαι, πονηρίαι, δόλος, ἀσέλγεια, ὀφθαλμὸς πονηρός, βλασφημία, ὑπερηφανία, ἀφροσύνη.
41007023 Πάντα ταῦτα τὰ πονηρὰ ἔσωθεν ἐκπορεύεται καὶ κοινοῖ τὸν ἄνθρωπον.”
41007024 Ἐκεῖθεν δὲ ἀναστὰς, ἀπῆλθεν εἰς τὰ ὅρια Τύρου καὶ Σιδῶνος. Καὶ εἰσελθὼν εἰς οἰκίαν, οὐδένα ἤθελεν γνῶναι, καὶ οὐκ ἠδυνήθη λαθεῖν.
41007025 Ἀλλʼ εὐθὺς ἀκούσασα γυνὴ περὶ αὐτοῦ, ἧς εἶχεν τὸ θυγάτριον αὐτῆς πνεῦμα ἀκάθαρτον ἐλθοῦσα, προσέπεσεν πρὸς τοὺς πόδας αὐτοῦ.
41007026 Ἡ δὲ γυνὴ ἦν Ἑλληνίς, Συροφοινίκισσα τῷ γένει, καὶ ἠρώτα αὐτὸν ἵνα τὸ δαιμόνιον ἐκβάλῃ ἐκ τῆς θυγατρὸς αὐτῆς.
41007027 Καὶ ἔλεγεν αὐτῇ, “Ἄφες πρῶτον χορτασθῆναι τὰ τέκνα, οὐ γάρ ἐστιν καλὸν λαβεῖν τὸν ἄρτον τῶν τέκνων, καὶ τοῖς κυναρίοις βαλεῖν.”
41007028 Ἡ δὲ ἀπεκρίθη καὶ λέγει αὐτῷ, “Ναί, ˚Κύριε, καὶ τὰ κυνάρια ὑποκάτω τῆς τραπέζης ἐσθίουσιν ἀπὸ τῶν ψιχίων τῶν παιδίων.”
41007029 Καὶ εἶπεν αὐτῇ, “Διὰ τοῦτον τὸν λόγον ὕπαγε, ἐξελήλυθεν τὸ δαιμόνιον, ἐκ τῆς θυγατρός σου.”
41007030 Καὶ ἀπελθοῦσα εἰς τὸν οἶκον αὐτῆς, εὗρεν τὸ παιδίον βεβλημένον ἐπὶ τὴν κλίνην, καὶ τὸ δαιμόνιον ἐξεληλυθός.
41007031 Καὶ πάλιν ἐξελθὼν ἐκ τῶν ὁρίων Τύρου, ἦλθεν διὰ Σιδῶνος εἰς τὴν θάλασσαν τῆς Γαλιλαίας, ἀνὰ μέσον τῶν ὁρίων Δεκαπόλεως.
41007032 Καὶ φέρουσιν αὐτῷ κωφὸν καὶ μογιλάλον, καὶ παρακαλοῦσιν αὐτὸν ἵνα ἐπιθῇ αὐτῷ τὴν χεῖρα.
41007033 Καὶ ἀπολαβόμενος αὐτὸν ἀπὸ τοῦ ὄχλου κατʼ ἰδίαν, ἔβαλεν τοὺς δακτύλους αὐτοῦ εἰς τὰ ὦτα αὐτοῦ, καὶ πτύσας, ἥψατο τῆς γλώσσης αὐτοῦ,
41007034 καὶ ἀναβλέψας εἰς τὸν οὐρανὸν ἐστέναξεν, καὶ λέγει αὐτῷ, “Ἐφφαθά”, ὅ ἐστιν, “Διανοίχθητι”.
41007035 Καὶ ἠνοίγησαν αὐτοῦ αἱ ἀκοαί, καὶ ἐλύθη ὁ δεσμὸς τῆς γλώσσης αὐτοῦ, καὶ ἐλάλει ὀρθῶς.
41007036 Καὶ διεστείλατο αὐτοῖς ἵνα μηδενὶ λέγωσιν. Ὅσον δὲ αὐτοῖς διεστέλλετο, αὐτοὶ μᾶλλον περισσότερον ἐκήρυσσον.
41007037 Καὶ ὑπερπερισσῶς ἐξεπλήσσοντο λέγοντες, “Καλῶς πάντα πεποίηκεν, καὶ τοὺς κωφοὺς ποιεῖ ἀκούειν, καὶ ἀλάλους λαλεῖν.”
41008001 Ἐν ἐκείναις ταῖς ἡμέραις πάλιν, πολλοῦ ὄχλου ὄντος, καὶ μὴ ἐχόντων τί φάγωσιν, προσκαλεσάμενος τοὺς μαθητὰς αὐτοῦ, λέγει αὐτοῖς,
41008002 “Σπλαγχνίζομαι ἐπὶ τὸν ὄχλον, ὅτι ἤδη ἡμέραι τρεῖς προσμένουσίν μοι, καὶ οὐκ ἔχουσιν τί φάγωσιν.
41008003 Καὶ ἐὰν ἀπολύσω αὐτοὺς νήστεις εἰς οἶκον αὐτῶν, ἐκλυθήσονται ἐν τῇ ὁδῷ. Καί τινες αὐτῶν ἀπὸ μακρόθεν ἥκασιν.”
41008004 Καὶ ἀπεκρίθησαν αὐτῷ οἱ μαθηταὶ αὐτοῦ, “Πόθεν τούτους δυνήσεταί τις ὧδε χορτάσαι ἄρτων ἐπʼ ἐρημίας;”
41008005 Καὶ ἠρώτα αὐτούς, “Πόσους ἔχετε ἄρτους;” Οἱ δὲ εἶπαν, “Ἑπτά.”
41008006 Καὶ παραγγέλλει τῷ ὄχλῳ ἀναπεσεῖν ἐπὶ τῆς γῆς. Καὶ λαβὼν τοὺς ἑπτὰ ἄρτους, εὐχαριστήσας ἔκλασεν καὶ ἐδίδου τοῖς μαθηταῖς αὐτοῦ, ἵνα παρατιθῶσιν, καὶ παρέθηκαν τῷ ὄχλῳ.
41008007 Καὶ εἶχον ἰχθύδια ὀλίγα· καὶ εὐλογήσας αὐτὰ, εἶπεν καὶ ταῦτα παρατιθέναι.
41008008 Καὶ ἔφαγον καὶ ἐχορτάσθησαν, καὶ ἦραν περισσεύματα κλασμάτων ἑπτὰ σπυρίδας.
41008009 Ἦσαν δὲ οἱ φαγόντες ὡς τετρακισχίλιοι. Καὶ ἀπέλυσεν αὐτούς.
41008010 Καὶ εὐθὺς ἐμβὰς εἰς τὸ πλοῖον μετὰ τῶν μαθητῶν αὐτοῦ, ἦλθεν εἰς τὰ μέρη Δαλμανουθά.
41008011 Καὶ ἐξῆλθον οἱ Φαρισαῖοι καὶ, ἤρξαντο συζητεῖν αὐτῷ, ζητοῦντες παρʼ αὐτοῦ σημεῖον ἀπὸ τοῦ οὐρανοῦ, πειράζοντες αὐτόν.
41008012 Καὶ ἀναστενάξας τῷ πνεύματι αὐτοῦ λέγει, “Τί ἡ γενεὰ αὕτη ζητεῖ σημεῖον; Ἀμὴν, λέγω ὑμῖν, εἰ δοθήσεται τῇ γενεᾷ ταύτῃ σημεῖον.”
41008013 Καὶ ἀφεὶς αὐτοὺς, πάλιν ἐμβὰς ἀπῆλθεν εἰς τὸ πέραν.
41008014 Καὶ ἐπελάθοντο λαβεῖν ἄρτους, καὶ εἰ μὴ ἕνα ἄρτον οὐκ εἶχον μεθʼ ἑαυτῶν ἐν τῷ πλοίῳ.
41008015 Καὶ διεστέλλετο αὐτοῖς λέγων, “Ὁρᾶτε, βλέπετε ἀπὸ τῆς ζύμης τῶν Φαρισαίων καὶ τῆς ζύμης Ἡρῴδου.”
41008016 Καὶ διελογίζοντο πρὸς ἀλλήλους, Ὅτι ἄρτους οὐκ ἔχουσιν.
41008017 Καὶ γνοὺς, ὁ ˚Ἰησοῦς λέγει αὐτοῖς, “Τί διαλογίζεσθε ὅτι ἄρτους οὐκ ἔχετε; Οὔπω νοεῖτε, οὐδὲ συνίετε; Πεπωρωμένην ἔχετε τὴν καρδίαν ὑμῶν;
41008018 ‘Ὀφθαλμοὺς ἔχοντες, οὐ βλέπετε; Καὶ ὦτα ἔχοντες, οὐκ ἀκούετε;’ Καὶ οὐ μνημονεύετε;
41008019 Ὅτε τοὺς πέντε ἄρτους ἔκλασα εἰς τοὺς πεντακισχιλίους, πόσους κοφίνους κλασμάτων πλήρεις ἤρατε;” Λέγουσιν αὐτῷ, “Δώδεκα.”
41008020 “Ὅτε δὲ τοὺς ἑπτὰ εἰς τοὺς τετρακισχιλίους, πόσων σπυρίδων πληρώματα κλασμάτων ἤρατε;” καὶ λέγουσιν “Ἑπτά.”
41008021 Καὶ ἔλεγεν αὐτοῖς, “Πῶς οὔπω συνίετε;”
41008022 Καὶ ἔρχονται εἰς Βηθσαϊδάν, καὶ φέρουσιν αὐτῷ τυφλὸν, καὶ παρακαλοῦσιν αὐτὸν ἵνα αὐτοῦ ἅψηται.
41008023 Καὶ ἐπιλαβόμενος τῆς χειρὸς τοῦ τυφλοῦ, ἐξήνεγκεν αὐτὸν ἔξω τῆς κώμης, καὶ πτύσας εἰς τὰ ὄμματα αὐτοῦ, ἐπιθεὶς τὰς χεῖρας αὐτῷ, ἐπηρώτα αὐτόν, “Εἴ τι βλέπεις;”
41008024 Καὶ ἀναβλέψας ἔλεγεν, “Βλέπω τοὺς ἀνθρώπους, ὅτι ὡς δένδρα ὁρῶ περιπατοῦντας.”
41008025 Εἶτα πάλιν ἐπέθηκεν τὰς χεῖρας ἐπὶ τοὺς ὀφθαλμοὺς αὐτοῦ, καὶ διέβλεψεν καὶ ἀπεκατέστη καὶ ἐνέβλεπεν τηλαυγῶς ἅπαντα.
41008026 Καὶ ἀπέστειλεν αὐτὸν εἰς οἶκον αὐτοῦ λέγων, “Μηδὲ εἰς τὴν κώμην εἰσέλθῃς.”
41008027 Καὶ ἐξῆλθεν ὁ ˚Ἰησοῦς καὶ οἱ μαθηταὶ αὐτοῦ εἰς τὰς κώμας Καισαρείας τῆς Φιλίππου. Καὶ ἐν τῇ ὁδῷ ἐπηρώτα τοὺς μαθητὰς αὐτοῦ λέγων αὐτοῖς, “Τίνα με λέγουσιν οἱ ἄνθρωποι εἶναι;”
41008028 Οἱ δὲ εἶπαν αὐτῷ λέγοντες, ὅτι “Ἰωάννην τὸν Βαπτιστήν, καὶ ἄλλοι Ἠλίαν, ἄλλοι δὲ ὅτι εἷς τῶν προφητῶν.”
41008029 Καὶ αὐτὸς ἐπηρώτα αὐτούς, “Ὑμεῖς δὲ τίνα με λέγετε εἶναι;” Ἀποκριθεὶς δὲ ὁ Πέτρος λέγει αὐτῷ, “Σὺ εἶ ὁ ˚Χριστός.”
41008030 Καὶ ἐπετίμησεν αὐτοῖς ἵνα μηδενὶ λέγωσιν περὶ αὐτοῦ.
41008031 Καὶ ἤρξατο διδάσκειν αὐτοὺς ὅτι δεῖ τὸν Υἱὸν τοῦ Ἀνθρώπου πολλὰ παθεῖν, καὶ ἀποδοκιμασθῆναι ὑπὸ τῶν πρεσβυτέρων καὶ τῶν ἀρχιερέων καὶ τῶν γραμματέων, καὶ ἀποκτανθῆναι, καὶ μετὰ τρεῖς ἡμέρας ἀναστῆναι.
41008032 Καὶ παρρησίᾳ τὸν λόγον ἐλάλει. Καὶ προσλαβόμενος αὐτὸν, ὁ Πέτρος ἤρξατο ἐπιτιμᾶν αὐτῷ.
41008033 Ὁ δὲ, ἐπιστραφεὶς καὶ ἰδὼν τοὺς μαθητὰς αὐτοῦ, ἐπετίμησεν τῷ Πέτρῳ καὶ λέγει, “Ὕπαγε ὀπίσω μου, Σατανᾶ, ὅτι οὐ φρονεῖς τὰ τοῦ ˚Θεοῦ, ἀλλὰ τὰ τῶν ἀνθρώπων.”
41008034 Καὶ προσκαλεσάμενος τὸν ὄχλον σὺν τοῖς μαθηταῖς αὐτοῦ, εἶπεν αὐτοῖς, “Εἴ τις θέλει ὀπίσω μου ἀκολουθεῖν ἀπαρνησάσθω ἑαυτὸν, καὶ ἀράτω τὸν σταυρὸν αὐτοῦ, καὶ ἀκολουθείτω μοι.
41008035 Ὃς γὰρ ἐὰν θέλῃ τὴν ψυχὴν αὐτοῦ σῶσαι, ἀπολέσει αὐτήν, ὃς δʼ ἂν ἀπολέσει τὴν ψυχὴν αὐτοῦ ἕνεκεν ἐμοῦ καὶ τοῦ εὐαγγελίου, σώσει αὐτήν.
41008036 Τί γὰρ ὠφελεῖ ἄνθρωπον, κερδήσῃ τὸν κόσμον ὅλον καὶ ζημιωθῆναι τὴν ψυχὴν αὐτοῦ;
41008037 Τί γὰρ δώσει ἄνθρωπος ἀντάλλαγμα τῆς ψυχῆς αὐτοῦ;
41008038 Ὃς γὰρ ἐὰν ἐπαισχυνθῇ με καὶ τοὺς ἐμοὺς λόγους ἐν τῇ γενεᾷ ταύτῃ, τῇ μοιχαλίδι καὶ ἁμαρτωλῷ, καὶ ὁ Υἱὸς τοῦ Ἀνθρώπου ἐπαισχυνθήσεται αὐτὸν ὅταν ἔλθῃ ἐν τῇ δόξῃ τοῦ Πατρὸς αὐτοῦ, μετὰ τῶν ἀγγέλων τῶν ἁγίων.”
41009001 Καὶ ἔλεγεν αὐτοῖς, “Ἀμὴν, λέγω ὑμῖν ὅτι εἰσίν τινες τῶν ὧδε ἑστηκότων, οἵτινες οὐ μὴ γεύσωνται θανάτου, ἕως ἂν ἴδωσιν τὴν Βασιλείαν τοῦ ˚Θεοῦ ἐληλυθυῖαν ἐν δυνάμει.”
41009002 Καὶ μετὰ ἡμέρας ἓξ, παραλαμβάνει ὁ ˚Ἰησοῦς τὸν Πέτρον, καὶ τὸν Ἰάκωβον, καὶ τὸν Ἰωάννην, καὶ ἀναφέρει αὐτοὺς εἰς ὄρος ὑψηλὸν κατʼ ἰδίαν μόνους. Καὶ μετεμορφώθη ἔμπροσθεν αὐτῶν,
41009003 καὶ τὰ ἱμάτια αὐτοῦ ἐγένετο στίλβοντα λευκὰ λίαν, οἷα γναφεὺς ἐπὶ τῆς γῆς οὐ δύναται οὕτως λευκᾶναι.
41009004 Καὶ ὤφθη αὐτοῖς Ἠλίας σὺν Μωϋσεῖ, καὶ ἦσαν συλλαλοῦντες τῷ ˚Ἰησοῦ.
41009005 Καὶ ἀποκριθεὶς ὁ Πέτρος λέγει τῷ ˚Ἰησοῦ, “Ῥαββί, καλόν ἐστιν ἡμᾶς ὧδε εἶναι, καὶ ποιήσωμεν τρεῖς σκηνάς, σοὶ μίαν, καὶ Μωϋσεῖ μίαν, καὶ Ἠλίᾳ μίαν.”
41009006 Οὐ γὰρ ᾔδει τί ἀποκριθῇ· ἔκφοβοι γὰρ ἐγένοντο.
41009007 Καὶ ἐγένετο νεφέλη ἐπισκιάζουσα αὐτοῖς, καὶ ἐγένετο φωνὴ ἐκ τῆς νεφέλης: “Οὗτός ἐστιν ὁ Υἱός μου, ὁ ἀγαπητός, ἀκούετε αὐτοῦ.”
41009008 Καὶ ἐξάπινα περιβλεψάμενοι, οὐκέτι οὐδένα εἶδον, εἰ μὴ τὸν ˚Ἰησοῦν μόνον μεθʼ ἑαυτῶν.
41009009 Καὶ καταβαινόντων αὐτῶν ἀπὸ τοῦ ὄρους, διεστείλατο αὐτοῖς ἵνα μηδενὶ ἃ εἶδον διηγήσωνται, εἰ μὴ ὅταν ὁ Υἱὸς τοῦ Ἀνθρώπου ἐκ νεκρῶν ἀναστῇ.
41009010 Καὶ τὸν λόγον ἐκράτησαν πρὸς ἑαυτοὺς, συζητοῦντες τί ἐστιν τὸ ἐκ νεκρῶν ἀναστῆναι.
41009011 Καὶ ἐπηρώτων αὐτὸν λέγοντες, ὅτι “Λέγουσιν Οἱ γραμματεῖς ὅτι Ἠλίαν δεῖ ἐλθεῖν πρῶτον;”
41009012 Ὁ δὲ ἔφη αὐτοῖς, “Ἠλίας μὲν ἐλθὼν πρῶτον ἀποκαθιστάνει πάντα, καὶ πῶς γέγραπται ἐπὶ τὸν Υἱὸν τοῦ Ἀνθρώπου, ἵνα πολλὰ πάθῃ καὶ ἐξουδενηθῇ;
41009013 Ἀλλὰ λέγω ὑμῖν ὅτι καὶ Ἠλίας ἐλήλυθεν, καὶ ἐποίησαν αὐτῷ ὅσα ἤθελον, καθὼς γέγραπται ἐπʼ αὐτόν.”
41009014 Καὶ ἐλθόντες πρὸς τοὺς μαθητὰς, εἶδεν ὄχλον πολὺν περὶ αὐτοὺς, καὶ γραμματεῖς συζητοῦντας πρὸς αὐτούς.
41009015 Καὶ εὐθὺς πᾶς ὁ ὄχλος ἰδόντες αὐτὸν ἐξεθαμβήθησαν, καὶ προστρέχοντες ἠσπάζοντο αὐτόν.
41009016 Καὶ ἐπηρώτησεν αὐτούς, “Τί συζητεῖτε πρὸς αὑτούς;”
41009017 Καὶ ἀπεκρίθη αὐτῷ εἷς ἐκ τοῦ ὄχλου, “Διδάσκαλε, ἤνεγκα τὸν υἱόν μου πρὸς σέ, ἔχοντα πνεῦμα ἄλαλον.
41009018 Καὶ ὅπου ἐὰν αὐτὸν καταλάβῃ, ῥήσσει αὐτόν, καὶ ἀφρίζει καὶ τρίζει τοὺς ὀδόντας, καὶ ξηραίνεται. Καὶ εἶπα τοῖς μαθηταῖς σου ἵνα αὐτὸ ἐκβάλωσιν, καὶ οὐκ ἴσχυσαν.”
41009019 Ὁ δὲ ἀποκριθεὶς αὐτοῖς λέγει, “Ὦ γενεὰ ἄπιστος! Ἕως πότε πρὸς ὑμᾶς ἔσομαι; Ἕως πότε ἀνέξομαι ὑμῶν; Φέρετε αὐτὸν πρός με.”
41009020 Καὶ ἤνεγκαν αὐτὸν πρὸς αὐτόν. Καὶ ἰδὼν αὐτὸν, τὸ πνεῦμα εὐθὺς συνεσπάραξεν αὐτόν, καὶ πεσὼν ἐπὶ τῆς γῆς, ἐκυλίετο ἀφρίζων.
41009021 Καὶ ἐπηρώτησεν τὸν πατέρα αὐτοῦ, “Πόσος χρόνος ἐστὶν, ὡς τοῦτο γέγονεν αὐτῷ;” Ὁ δὲ εἶπεν, “Ἐκ παιδιόθεν.
41009022 Καὶ πολλάκις καὶ εἰς πῦρ αὐτὸν ἔβαλεν καὶ εἰς ὕδατα, ἵνα ἀπολέσῃ αὐτόν. Ἀλλʼ εἴ τι δύνῃ, βοήθησον ἡμῖν, σπλαγχνισθεὶς ἐφʼ ἡμᾶς.”
41009023 Ὁ δὲ ˚Ἰησοῦς εἶπεν αὐτῷ, τὸ “Εἰ δύνῃ; Πάντα δυνατὰ τῷ πιστεύοντι.”
41009024 Καὶ εὐθέως κράξας, ὁ πατὴρ τοῦ παιδίου ἔλεγεν, “Πιστεύω, βοήθει μου τῇ ἀπιστίᾳ!”
41009025 Ἰδὼν δὲ ὁ ˚Ἰησοῦς ὅτι ἐπισυντρέχει ὄχλος, ἐπετίμησεν τῷ πνεύματι τῷ ἀκαθάρτῳ λέγων αὐτῷ, “τὸ ἄλαλον καὶ κωφὸν πνεῦμα, ἐγὼ ἐπιτάσσω σοι, ἔξελθε ἐξ αὐτοῦ, καὶ μηκέτι εἰσέλθῃς εἰς αὐτόν.”
41009026 Καὶ κράξας, καὶ πολλὰ σπαράξας, ἐξῆλθεν· καὶ ἐγένετο ὡσεὶ νεκρὸς, ὥστε τοὺς πολλοὺς λέγειν ὅτι ἀπέθανεν.
41009027 Ὁ δὲ ˚Ἰησοῦς κρατήσας τῆς χειρὸς αὐτοῦ, ἤγειρεν αὐτόν, καὶ ἀνέστη.
41009028 Καὶ εἰσελθόντος αὐτοῦ εἰς οἶκον, οἱ μαθηταὶ αὐτοῦ κατʼ ἰδίαν ἐπηρώτων αὐτόν, Ὅτι “Ἡμεῖς οὐκ ἠδυνήθημεν ἐκβαλεῖν αὐτό;”
41009029 Καὶ εἶπεν αὐτοῖς, “Τοῦτο τὸ γένος ἐν οὐδενὶ δύναται ἐξελθεῖν, εἰ μὴ ἐν προσευχῇ καὶ νηστείᾳ.”
41009030 Κἀκεῖθεν ἐξελθόντες, παρεπορεύοντο διὰ τῆς Γαλιλαίας, καὶ οὐκ ἤθελεν ἵνα τις γνοῖ·
41009031 ἐδίδασκεν γὰρ τοὺς μαθητὰς αὐτοῦ, καὶ ἔλεγεν αὐτοῖς, ὅτι “Ὁ Υἱὸς τοῦ Ἀνθρώπου παραδίδοται εἰς χεῖρας ἀνθρώπων, καὶ ἀποκτενοῦσιν αὐτόν, καὶ ἀποκτανθεὶς, μετὰ τρεῖς ἡμέρας ἀναστήσεται.”
41009032 Οἱ δὲ ἠγνόουν τὸ ῥῆμα, καὶ ἐφοβοῦντο αὐτὸν ἐπερωτῆσαι.
41009033 Καὶ ἦλθον εἰς Καφαρναούμ. Καὶ ἐν τῇ οἰκίᾳ γενόμενος, ἐπηρώτα αὐτούς, “Τί ἐν τῇ ὁδῷ διελογίζεσθε;”
41009034 Οἱ δὲ ἐσιώπων, πρὸς ἀλλήλους γὰρ διελέχθησαν ἐν τῇ ὁδῷ, τίς μείζων.
41009035 Καὶ καθίσας, ἐφώνησεν τοὺς δώδεκα καὶ λέγει αὐτοῖς, “Εἴ τις θέλει πρῶτος εἶναι, ἔσται πάντων ἔσχατος καὶ πάντων διάκονος.”
41009036 Καὶ λαβὼν παιδίον, ἔστησεν αὐτὸ ἐν μέσῳ αὐτῶν, καὶ ἐναγκαλισάμενος αὐτὸ, εἶπεν αὐτοῖς,
41009037 “Ὃς ἂν ἓν τῶν τοιούτων παιδίων δέξηται ἐπὶ τῷ ὀνόματί μου, ἐμὲ δέχεται· καὶ ὃς ἂν ἐμὲ δέξηται, οὐκ ἐμὲ δέχεται, ἀλλὰ τὸν ἀποστείλαντά με.”
41009038 Ἔφη αὐτῷ ὁ Ἰωάννης, “Διδάσκαλε, εἴδομέν τινα ἐν τῷ ὀνόματί σου ἐκβάλλοντα δαιμόνια, καὶ ἐκωλύομεν αὐτόν, ὅτι οὐκ ἠκολούθει ἡμῖν.”
41009039 Ὁ δὲ ˚Ἰησοῦς εἶπεν, “Μὴ κωλύετε αὐτόν, οὐδεὶς γάρ ἐστιν ὃς ποιήσει δύναμιν ἐπὶ τῷ ὀνόματί μου, καὶ δυνήσεται ταχὺ κακολογῆσαί με.
41009040 Ὃς γὰρ οὐκ ἔστιν καθʼ ἡμῶν, ὑπὲρ ἡμῶν ἐστιν.
41009041 Ὃς γὰρ ἂν ποτίσῃ ὑμᾶς ποτήριον ὕδατος ἐν ὀνόματι, ὅτι ˚Χριστοῦ ἐστε, ἀμὴν, λέγω ὑμῖν ὅτι οὐ μὴ ἀπολέσῃ τὸν μισθὸν αὐτοῦ.
41009042 Καὶ ὃς ἂν σκανδαλίσῃ ἕνα τῶν μικρῶν τούτων τῶν πιστευόντων εἰς ἐμέ, καλόν ἐστιν αὐτῷ μᾶλλον εἰ περίκειται μύλος ὀνικὸς περὶ τὸν τράχηλον αὐτοῦ, καὶ βέβληται εἰς τὴν θάλασσαν.
41009043 Καὶ ἐὰν σκανδαλίζῃ σε ἡ χείρ σου, ἀπόκοψον αὐτήν· καλόν ἐστίν σε κυλλὸν εἰσελθεῖν εἰς τὴν ζωὴν, ἢ τὰς δύο χεῖρας ἔχοντα ἀπελθεῖν εἰς τὴν Γέενναν, εἰς τὸ πῦρ τὸ ἄσβεστον.
41009045 Καὶ ἐὰν ὁ πούς σου σκανδαλίζῃ σε, ἀπόκοψον αὐτόν· καλόν ἐστίν σε εἰσελθεῖν εἰς τὴν ζωὴν χωλὸν, ἢ τοὺς δύο πόδας ἔχοντα βληθῆναι εἰς τὴν Γέενναν.
41009047 Καὶ ἐὰν ὁ ὀφθαλμός σου σκανδαλίζῃ σε, ἔκβαλε αὐτόν· καλόν σέ ἐστιν μονόφθαλμον εἰσελθεῖν εἰς τὴν Βασιλείαν τοῦ ˚Θεοῦ, ἢ δύο ὀφθαλμοὺς ἔχοντα βληθῆναι εἰς τὴν Γέενναν,
41009048 ὅπου ‘ὁ σκώληξ αὐτῶν οὐ τελευτᾷ, καὶ τὸ πῦρ οὐ σβέννυται’.
41009049 Πᾶς γὰρ πυρὶ ἁλισθήσεται.
41009050 Καλὸν τὸ ἅλας, ἐὰν δὲ τὸ ἅλας ἄναλον γένηται, ἐν τίνι αὐτὸ ἀρτύσετε; Ἔχετε ἐν ἑαυτοῖς ἅλα, καὶ εἰρηνεύετε ἐν ἀλλήλοις.”
41010001 Καὶ ἐκεῖθεν ἀναστὰς, ἔρχεται εἰς τὰ ὅρια τῆς Ἰουδαίας, καὶ πέραν τοῦ Ἰορδάνου, καὶ συμπορεύονται πάλιν ὄχλοι πρὸς αὐτόν, καὶ ὡς εἰώθει, πάλιν ἐδίδασκεν αὐτούς.
41010002 Καὶ προσελθόντες, Φαρισαῖοι ἐπηρώτων αὐτὸν, “Εἰ ἔξεστιν ἀνδρὶ γυναῖκα ἀπολῦσαι;” Πειράζοντες αὐτόν.
41010003 Ὁ δὲ ἀποκριθεὶς εἶπεν αὐτοῖς, “Τί ὑμῖν ἐνετείλατο Μωϋσῆς;”
41010004 Οἱ δὲ εἶπαν, “Ἐπέτρεψεν Μωϋσῆς ‘βιβλίον ἀποστασίου γράψαι καὶ ἀπολῦσαι’.”
41010005 Ὁ δὲ ˚Ἰησοῦς εἶπεν αὐτοῖς, “Πρὸς τὴν σκληροκαρδίαν ὑμῶν, ἔγραψεν ὑμῖν τὴν ἐντολὴν ταύτην·
41010006 ἀπὸ δὲ ἀρχῆς κτίσεως, ‘ἄρσεν καὶ θῆλυ ἐποίησεν αὐτούς’.
41010007 ‘Ἕνεκεν τούτου καταλείψει ἄνθρωπος τὸν πατέρα αὐτοῦ καὶ τὴν μητέρα, καὶ προσκολληθήσεται γυναῖκα αὑτοῦ
41010008 καὶ ἔσονται οἱ δύο εἰς σάρκα μίαν’. Ὥστε οὐκέτι εἰσὶν δύο, ἀλλὰ μία σάρξ
41010009 Ὃ οὖν ὁ ˚Θεὸς συνέζευξεν, ἄνθρωπος μὴ χωριζέτω.”
41010010 Καὶ εἰς τὴν οἰκίαν πάλιν, οἱ μαθηταὶ περὶ τούτου ἐπηρώτων αὐτόν.
41010011 Καὶ λέγει αὐτοῖς, “Ὃς ἂν ἀπολύσῃ τὴν γυναῖκα αὐτοῦ, καὶ γαμήσῃ ἄλλην, μοιχᾶται ἐπʼ αὐτήν.
41010012 Καὶ ἐὰν αὐτὴ ἀπολύσασα τὸν ἄνδρα αὐτῆς, γαμήσῃ ἄλλον, μοιχᾶται.”
41010013 Καὶ προσέφερον αὐτῷ παιδία, ἵνα αὐτῶν ἅψηται· οἱ δὲ μαθηταὶ ἐπετίμησαν αὐτοῖς.
41010014 Ἰδὼν δὲ, ὁ ˚Ἰησοῦς ἠγανάκτησεν καὶ εἶπεν αὐτοῖς, “Ἄφετε τὰ παιδία ἔρχεσθαι πρός με, καὶ μὴ κωλύετε αὐτά, τῶν γὰρ τοιούτων ἐστὶν ἡ Βασιλεία τοῦ ˚Θεοῦ.
41010015 Ἀμὴν, λέγω ὑμῖν, ὃς ἂν μὴ δέξηται τὴν Βασιλείαν τοῦ ˚Θεοῦ ὡς παιδίον, οὐ μὴ εἰσέλθῃ εἰς αὐτήν.”
41010016 Καὶ ἐναγκαλισάμενος αὐτὰ, κατευλόγει τιθεὶς τὰς χεῖρας ἐπʼ αὐτά.
41010017 Καὶ ἐκπορευομένου αὐτοῦ εἰς ὁδὸν, προσδραμὼν εἷς καὶ γονυπετήσας αὐτὸν, ἐπηρώτα αὐτόν, “Διδάσκαλε ἀγαθέ, τί ποιήσω, ἵνα ζωὴν αἰώνιον κληρονομήσω;”
41010018 Ὁ δὲ ˚Ἰησοῦς εἶπεν αὐτῷ, “Τί με λέγεις ἀγαθόν; Οὐδεὶς ἀγαθὸς, εἰ μὴ εἷς ὁ ˚Θεός.
41010019 Τὰς ἐντολὰς οἶδας: ‘μὴ φονεύσῃς, μὴ μοιχεύσῃς, μὴ κλέψῃς, μὴ ψευδομαρτυρήσῃς’, μὴ ἀποστερήσῃς, ‘Τίμα τὸν πατέρα σου καὶ τὴν μητέρα.’”
41010020 Ὁ δὲ ἔφη αὐτῷ, “Διδάσκαλε, ταῦτα πάντα ἐφυλαξάμην ἐκ νεότητός μου.”
41010021 Ὁ δὲ ˚Ἰησοῦς ἐμβλέψας αὐτῷ, ἠγάπησεν αὐτὸν καὶ εἶπεν αὐτῷ, “Ἕν σε ὑστερεῖ: ὕπαγε, ὅσα ἔχεις πώλησον, καὶ δὸς πτωχοῖς, καὶ ἕξεις θησαυρὸν ἐν οὐρανῷ, καὶ δεῦρο, ἀκολούθει μοι.”
41010022 Ὁ δὲ, στυγνάσας ἐπὶ τῷ λόγῳ, ἀπῆλθεν λυπούμενος, ἦν γὰρ ἔχων κτήματα πολλά.
41010023 Καὶ περιβλεψάμενος, ὁ ˚Ἰησοῦς λέγει τοῖς μαθηταῖς αὐτοῦ, “Πῶς δυσκόλως οἱ τὰ χρήματα ἔχοντες, εἰς τὴν Βασιλείαν τοῦ ˚Θεοῦ εἰσελεύσονται!”
41010024 Οἱ δὲ μαθηταὶ ἐθαμβοῦντο ἐπὶ τοῖς λόγοις αὐτοῦ. Ὁ δὲ ˚Ἰησοῦς πάλιν ἀποκριθεὶς λέγει αὐτοῖς, “Τέκνα, πῶς δύσκολόν ἐστιν εἰς τὴν Βασιλείαν τοῦ ˚Θεοῦ εἰσελθεῖν.
41010025 Εὐκοπώτερόν ἐστιν κάμηλον διὰ τρυμαλιᾶς ῥαφίδος διελθεῖν, ἢ πλούσιον εἰς τὴν Βασιλείαν τοῦ ˚Θεοῦ εἰσελθεῖν.”
41010026 Οἱ δὲ περισσῶς ἐξεπλήσσοντο, λέγοντες πρὸς αὐτόν, “Καὶ τίς δύναται σωθῆναι;”
41010027 Ἐμβλέψας αὐτοῖς, ὁ ˚Ἰησοῦς λέγει, “Παρὰ ἀνθρώποις ἀδύνατον, ἀλλʼ οὐ παρὰ ˚Θεῷ, πάντα γὰρ δυνατὰ παρὰ τῷ ˚Θεῷ.”
41010028 Ἤρξατο λέγειν ὁ Πέτρος αὐτῷ, “Ἰδοὺ, ἡμεῖς ἀφήκαμεν πάντα καὶ ἠκολουθήκαμέν σοι.”
41010029 Ἔφη ὁ ˚Ἰησοῦς, “Ἀμὴν, λέγω ὑμῖν, οὐδείς ἐστιν ὃς ἀφῆκεν οἰκίαν, ἢ ἀδελφοὺς, ἢ ἀδελφὰς, ἢ μητέρα, ἢ πατέρα, ἢ τέκνα, ἢ ἀγροὺς, ἕνεκεν ἐμοῦ καὶ τοῦ εὐαγγελίου,
41010030 ἐὰν μὴ λάβῃ ἑκατονταπλασίονα νῦν ἐν τῷ καιρῷ τούτῳ, οἰκίας, καὶ ἀδελφοὺς, καὶ ἀδελφὰς, καὶ μητέρας, καὶ τέκνα, καὶ ἀγροὺς, μετὰ διωγμῶν, καὶ ἐν τῷ αἰῶνι τῷ ἐρχομένῳ, ζωὴν αἰώνιον.
41010031 Πολλοὶ δὲ ἔσονται πρῶτοι ἔσχατοι, καὶ ἔσχατοι πρῶτοι.”
41010032 Ἦσαν δὲ ἐν τῇ ὁδῷ ἀναβαίνοντες εἰς Ἱεροσόλυμα, καὶ ἦν προάγων αὐτοὺς ὁ ˚Ἰησοῦς, καὶ ἐθαμβοῦντο, οἱ δὲ ἀκολουθοῦντες ἐφοβοῦντο. Καὶ παραλαβὼν πάλιν τοὺς δώδεκα, ἤρξατο αὐτοῖς λέγειν τὰ μέλλοντα αὐτῷ συμβαίνειν.
41010033 Ὅτι “Ἰδοὺ, ἀναβαίνομεν εἰς Ἱεροσόλυμα, καὶ ὁ Υἱὸς τοῦ Ἀνθρώπου παραδοθήσεται τοῖς ἀρχιερεῦσιν καὶ τοῖς γραμματεῦσιν, καὶ κατακρινοῦσιν αὐτὸν θανάτῳ, καὶ παραδώσουσιν αὐτὸν τοῖς ἔθνεσιν.
41010034 Καὶ ἐμπαίξουσιν αὐτῷ, καὶ ἐμπτύσουσιν αὐτῷ, καὶ μαστιγώσουσιν αὐτὸν, καὶ ἀποκτενοῦσιν, καὶ μετὰ τρεῖς ἡμέρας ἀναστήσεται.”
41010035 Καὶ προσπορεύονται αὐτῷ Ἰάκωβος καὶ Ἰωάννης, οἱ δύο υἱοὶ Ζεβεδαίου, λέγοντες αὐτῷ, “Διδάσκαλε, θέλομεν ἵνα ὃ ἐὰν αἰτήσωμέν σε, ποιήσῃς ἡμῖν.”
41010036 Ὁ δὲ εἶπεν αὐτοῖς, “Τί θέλετε με ποιήσω ὑμῖν;”
41010037 Οἱ δὲ εἶπαν αὐτῷ, “Δὸς ἡμῖν, ἵνα εἷς σου ἐκ δεξιῶν, καὶ εἷς ἐξ εὐωνύμων καθίσωμεν ἐν τῇ δόξῃ σου.”
41010038 Ὁ δὲ ˚Ἰησοῦς εἶπεν αὐτοῖς, “Οὐκ οἴδατε τί αἰτεῖσθε. Δύνασθε πιεῖν τὸ ποτήριον ὃ ἐγὼ πίνω, ἢ τὸ βάπτισμα ὃ ἐγὼ βαπτίζομαι βαπτισθῆναι;”
41010039 Οἱ δὲ εἶπαν αὐτῷ, “Δυνάμεθα.” Ὁ δὲ ˚Ἰησοῦς εἶπεν αὐτοῖς, “Τὸ ποτήριον ὃ ἐγὼ πίνω, πίεσθε, καὶ τὸ βάπτισμα ὃ ἐγὼ βαπτίζομαι, βαπτισθήσεσθε,
41010040 τὸ δὲ καθίσαι ἐκ δεξιῶν μου ἢ ἐξ εὐωνύμων, οὐκ ἔστιν ἐμὸν δοῦναι, ἀλλʼ οἷς ἡτοίμασται.”
41010041 Καὶ ἀκούσαντες, οἱ δέκα ἤρξαντο ἀγανακτεῖν περὶ Ἰακώβου καὶ Ἰωάννου.
41010042 Καὶ προσκαλεσάμενος αὐτοὺς ὁ ˚Ἰησοῦς λέγει αὐτοῖς, “Οἴδατε ὅτι οἱ δοκοῦντες ἄρχειν τῶν ἐθνῶν κατακυριεύουσιν αὐτῶν, καὶ οἱ μεγάλοι αὐτῶν κατεξουσιάζουσιν αὐτῶν.
41010043 Οὐχ οὕτως δέ ἐστιν ἐν ὑμῖν· ἀλλʼ ὃς ἂν θέλῃ μέγας γενέσθαι ἐν ὑμῖν, ἔσται ὑμῶν διάκονος,
41010044 καὶ ὃς ἂν θέλῃ ἐν ὑμῖν εἶναι πρῶτος, ἔσται πάντων δοῦλος.
41010045 Καὶ γὰρ ὁ Υἱὸς τοῦ Ἀνθρώπου οὐκ ἦλθεν διακονηθῆναι, ἀλλὰ διακονῆσαι, καὶ δοῦναι τὴν ψυχὴν αὐτοῦ λύτρον ἀντὶ πολλῶν.”
41010046 Καὶ ἔρχονται εἰς Ἰεριχώ. Καὶ ἐκπορευομένου αὐτοῦ ἀπὸ Ἰεριχὼ, καὶ τῶν μαθητῶν αὐτοῦ, καὶ ὄχλου ἱκανοῦ, ὁ υἱὸς Τιμαίου, Βαρτιμαῖος, τυφλὸς προσαίτης, ἐκάθητο παρὰ τὴν ὁδόν.
41010047 Καὶ ἀκούσας ὅτι ˚Ἰησοῦς ὁ Ναζαρηνός ἐστιν, ἤρξατο κράζειν καὶ λέγειν, “Υἱὲ Δαυὶδ, ˚Ἰησοῦ, ἐλέησόν με.”
41010048 Καὶ ἐπετίμων αὐτῷ πολλοὶ ἵνα σιωπήσῃ· ὁ δὲ πολλῷ μᾶλλον ἔκραζεν, “Υἱὲ Δαυίδ, ἐλέησόν με.”
41010049 Καὶ στὰς, ὁ ˚Ἰησοῦς εἶπεν, “Φωνήσατε αὐτόν.” Καὶ φωνοῦσι τὸν τυφλὸν, λέγοντες αὐτῷ, “Θάρσει, ἔγειρε, φωνεῖ σε.”
41010050 Ὁ δὲ, ἀποβαλὼν τὸ ἱμάτιον αὐτοῦ ἀναπηδήσας, ἦλθεν πρὸς τὸν ˚Ἰησοῦν.
41010051 Καὶ ἀποκριθεὶς αὐτῷ ὁ ˚Ἰησοῦς εἶπεν, “Τί σοι θέλεις ποιήσω;” Ὁ δὲ τυφλὸς εἶπεν αὐτῷ, “Ῥαββουνί, ἵνα ἀναβλέψω.”
41010052 Ὁ δὲ ˚Ἰησοῦς εἶπεν αὐτῷ, “Ὕπαγε, ἡ πίστις σου σέσωκέν σε.” Καὶ εὐθὺς ἀνέβλεψεν, καὶ ἠκολούθει αὐτῷ ἐν τῇ ὁδῷ.
41011001 Καὶ ὅτε ἐγγίζουσιν εἰς Ἱεροσόλυμα, εἰς Βηθφαγὴ καὶ Βηθανίαν πρὸς τὸ Ὄρος τῶν Ἐλαιῶν, ἀποστέλλει δύο τῶν μαθητῶν αὐτοῦ,
41011002 καὶ λέγει αὐτοῖς, “Ὑπάγετε εἰς τὴν κώμην τὴν κατέναντι ὑμῶν, καὶ εὐθὺς εἰσπορευόμενοι εἰς αὐτὴν, εὑρήσετε πῶλον δεδεμένον ἐφʼ ὃν οὐδεὶς ἀνθρώπων οὔπω ἐκάθισεν· λύσατε αὐτὸν καὶ φέρετε.
41011003 Καὶ ἐάν τις ὑμῖν εἴπῃ, ‘Τί ποιεῖτε τοῦτο;’ Εἴπατε, ὅτι ‘Ὁ ˚Κύριος αὐτοῦ χρείαν ἔχει’, καὶ εὐθὺς αὐτὸν ἀποστέλλει πάλιν ὧδε.”
41011004 Καὶ ἀπῆλθον, καὶ εὗρον πῶλον δεδεμένον πρὸς τὴν θύραν ἔξω ἐπὶ τοῦ ἀμφόδου, καὶ λύουσιν αὐτόν.
41011005 Καί τινες τῶν ἐκεῖ ἑστηκότων ἔλεγον αὐτοῖς, “Τί ποιεῖτε λύοντες τὸν πῶλον;”
41011006 Οἱ δὲ εἶπον αὐτοῖς καθὼς εἶπεν ὁ ˚Ἰησοῦς, καὶ ἀφῆκαν αὐτούς.
41011007 Καὶ ἄγουσιν τὸν πῶλον πρὸς τὸν ˚Ἰησοῦν, καὶ ἐπιβάλλουσιν αὐτῷ τὰ ἱμάτια αὐτῶν, καὶ ἐκάθισεν ἐπʼ αὐτόν.
41011008 Καὶ πολλοὶ τὰ ἱμάτια αὐτῶν ἔστρωσαν εἰς τὴν ὁδόν, ἄλλοι δὲ στιβάδας κόψαντες ἐκ τῶν ἀγρῶν.
41011009 Καὶ οἱ προάγοντες καὶ οἱ ἀκολουθοῦντες ἔκραζον, “Ὡσαννά! ‘Εὐλογημένος ὁ ἐρχόμενος ἐν ὀνόματι ˚Κυρίου!’
41011010 Εὐλογημένη ἡ ἐρχομένη βασιλεία τοῦ πατρὸς ἡμῶν, Δαυίδ! Ὡσαννὰ ἐν τοῖς ὑψίστοις!”
41011011 Καὶ εἰσῆλθεν εἰς Ἱεροσόλυμα, εἰς τὸ ἱερόν· καὶ περιβλεψάμενος πάντα, ὀψίας ἤδη οὔσης τῆς ὥρας, ἐξῆλθεν εἰς Βηθανίαν μετὰ τῶν δώδεκα.
41011012 Καὶ τῇ ἐπαύριον, ἐξελθόντων αὐτῶν ἀπὸ Βηθανίας, ἐπείνασεν.
41011013 Καὶ ἰδὼν συκῆν ἀπὸ μακρόθεν ἔχουσαν φύλλα, ἦλθεν εἰ ἄρα τι εὑρήσει ἐν αὐτῇ, καὶ ἐλθὼν ἐπʼ αὐτὴν, οὐδὲν εὗρεν εἰ μὴ φύλλα, ὁ γὰρ καιρὸς οὐκ ἦν σύκων.
41011014 Καὶ ἀποκριθεὶς εἶπεν αὐτῇ, “Μηκέτι εἰς τὸν αἰῶνα, ἐκ σοῦ μηδεὶς καρπὸν φάγοι.” Καὶ ἤκουον οἱ μαθηταὶ αὐτοῦ.
41011015 Καὶ ἔρχονται εἰς Ἱεροσόλυμα, καὶ εἰσελθὼν εἰς τὸ ἱερὸν, ἤρξατο ἐκβάλλειν τοὺς πωλοῦντας καὶ τοὺς ἀγοράζοντας ἐν τῷ ἱερῷ, καὶ τὰς τραπέζας τῶν κολλυβιστῶν καὶ τὰς καθέδρας τῶν πωλούντων τὰς περιστερὰς κατέστρεψεν.
41011016 Καὶ οὐκ ἤφιεν ἵνα τις διενέγκῃ σκεῦος διὰ τοῦ ἱεροῦ.
41011017 Καὶ ἐδίδασκεν καὶ ἔλεγεν αὐτοῖς, “Οὐ γέγραπται, ὅτι ‘Ὁ οἶκός μου, οἶκος προσευχῆς κληθήσεται πᾶσιν τοῖς ἔθνεσιν’; Ὑμεῖς δὲ ἐποιήσατε αὐτὸν ‘σπήλαιον λῃστῶν’.”
41011018 Καὶ ἤκουσαν οἱ ἀρχιερεῖς καὶ οἱ γραμματεῖς, καὶ ἐζήτουν πῶς αὐτὸν ἀπολέσωσιν· ἐφοβοῦντο γὰρ αὐτόν, πᾶς γὰρ ὁ ὄχλος ἐξεπλήσσετο ἐπὶ τῇ διδαχῇ αὐτοῦ.
41011019 Καὶ ὅταν ὀψὲ ἐγένετο, ἐξεπορεύοντο ἔξω τῆς πόλεως.
41011020 Καὶ παραπορευόμενοι πρωῒ, εἶδον τὴν συκῆν ἐξηραμμένην ἐκ ῥιζῶν.
41011021 Καὶ ἀναμνησθεὶς ὁ Πέτρος λέγει αὐτῷ, “Ῥαββί, ἴδε, ἡ συκῆ ἣν κατηράσω ἐξήρανται.”
41011022 Καὶ ἀποκριθεὶς ὁ ˚Ἰησοῦς λέγει αὐτοῖς, “Ἔχετε πίστιν ˚Θεοῦ.
41011023 Ἀμὴν, λέγω ὑμῖν ὅτι ὃς ἂν εἴπῃ τῷ ὄρει τούτῳ, ‘Ἄρθητι καὶ βλήθητι εἰς τὴν θάλασσαν’, καὶ μὴ διακριθῇ ἐν τῇ καρδίᾳ αὐτοῦ, ἀλλὰ πιστεύῃ ὅτι ὃ λαλεῖ γίνεται, ἔσται αὐτῷ.
41011024 Διὰ τοῦτο λέγω ὑμῖν, πάντα ὅσα προσεύχεσθε καὶ αἰτεῖσθε, πιστεύετε ὅτι ἐλάβετε, καὶ ἔσται ὑμῖν.
41011025 Καὶ ὅταν στήκετε προσευχόμενοι, ἀφίετε εἴ τι ἔχετε κατά τινος, ἵνα καὶ ὁ Πατὴρ ὑμῶν, ὁ ἐν τοῖς οὐρανοῖς, ἀφῇ ὑμῖν τὰ παραπτώματα ὑμῶν.”
41011027 Καὶ ἔρχονται πάλιν εἰς Ἱεροσόλυμα, καὶ ἐν τῷ ἱερῷ περιπατοῦντος αὐτοῦ, ἔρχονται πρὸς αὐτὸν οἱ ἀρχιερεῖς, καὶ οἱ γραμματεῖς, καὶ οἱ πρεσβύτεροι.
41011028 Καὶ ἔλεγον αὐτῷ, “Ἐν ποίᾳ ἐξουσίᾳ ταῦτα ποιεῖς; Ἢ τίς σοι ἔδωκεν τὴν ἐξουσίαν ταύτην, ἵνα ταῦτα ποιῇς;”
41011029 Ὁ δὲ ˚Ἰησοῦς εἶπεν αὐτοῖς, “Ἐπερωτήσω ὑμᾶς κἀγὼ ἕνα λόγον, καὶ ἀποκρίθητέ μοι, καὶ ἐρῶ ὑμῖν ἐν ποίᾳ ἐξουσίᾳ ταῦτα ποιῶ.
41011030 Τὸ βάπτισμα τὸ Ἰωάννου, ἐξ οὐρανοῦ ἦν ἢ ἐξ ἀνθρώπων; Ἀποκρίθητέ μοι.”
41011031 Καὶ διελογίζοντο πρὸς ἑαυτοὺς λέγοντες, ἐὰν εἴπωμεν, ‘Ἐξ οὐρανοῦ’, ἐρεῖ, ‘Διὰ τί οὖν οὐκ ἐπιστεύσατε αὐτῷ;’
41011032 Ἀλλὰ εἴπωμεν, ‘Ἐξ ἀνθρώπων’;” ( Ἐφοβοῦντο τὸν ὄχλον, ἅπαντες γὰρ εἶχον τὸν Ἰωάννην ὄντως ὅτι προφήτης ἦν. )
41011033 Καὶ ἀποκριθέντες τῷ ˚Ἰησοῦ λέγουσιν, “Οὐκ οἴδαμεν.” Καὶ ὁ ˚Ἰησοῦς λέγει αὐτοῖς, “Οὐδὲ ἐγὼ λέγω ὑμῖν ἐν ποίᾳ ἐξουσίᾳ ταῦτα ποιῶ.”
41012001 Καὶ ἤρξατο αὐτοῖς ἐν παραβολαῖς λαλεῖν: “Ἀμπελῶνα ἄνθρωπος ἐφύτευσεν, καὶ περιέθηκεν φραγμὸν, καὶ ‘ὤρυξεν ὑπολήνιον, καὶ ᾠκοδόμησεν πύργον’, καὶ ἐξέδετο αὐτὸν γεωργοῖς, καὶ ἀπεδήμησεν.
41012002 Καὶ ἀπέστειλεν πρὸς τοὺς γεωργοὺς τῷ καιρῷ δοῦλον, ἵνα παρὰ τῶν γεωργῶν λάβῃ ἀπὸ τῶν καρπῶν τοῦ ἀμπελῶνος.
41012003 Καὶ λαβόντες αὐτὸν, ἔδειραν καὶ ἀπέστειλαν κενόν.
41012004 Καὶ πάλιν ἀπέστειλεν πρὸς αὐτοὺς ἄλλον δοῦλον, κἀκεῖνον ἐκεφαλίωσαν καὶ ἠτίμασαν.
41012005 Καὶ ἄλλον ἀπέστειλεν, κἀκεῖνον ἀπέκτειναν, καὶ πολλοὺς ἄλλους, οὓς μὲν δέροντες, οὓς δὲ ἀποκτέννοντες.
41012006 Ἔτι ἕνα εἶχεν υἱὸν ἀγαπητόν, ἀπέστειλεν αὐτὸν ἔσχατον πρὸς αὐτοὺς λέγων, ὅτι ‘Ἐντραπήσονται τὸν υἱόν μου.’
41012007 Ἐκεῖνοι δὲ οἱ γεωργοὶ πρὸς ἑαυτοὺς εἶπαν, ὅτι ‘Οὗτός ἐστιν ὁ κληρονόμος· δεῦτε, ἀποκτείνωμεν αὐτόν, καὶ ἡμῶν ἔσται ἡ κληρονομία.’
41012008 Καὶ λαβόντες, ἀπέκτειναν αὐτόν, καὶ ἐξέβαλον αὐτὸν ἔξω τοῦ ἀμπελῶνος.
41012009 Τί οὖν ποιήσει ὁ κύριος τοῦ ἀμπελῶνος; Ἐλεύσεται καὶ ἀπολέσει τοὺς γεωργούς, καὶ δώσει τὸν ἀμπελῶνα ἄλλοις.
41012010 Οὐδὲ τὴν Γραφὴν ταύτην ἀνέγνωτε: ‘Λίθον ὃν ἀπεδοκίμασαν οἱ οἰκοδομοῦντες, οὗτος ἐγενήθη εἰς κεφαλὴν γωνίας·
41012011 παρὰ ˚Κυρίου ἐγένετο αὕτη, καὶ ἔστιν θαυμαστὴ ἐν ὀφθαλμοῖς ἡμῶν’;”
41012012 Καὶ ἐζήτουν αὐτὸν κρατῆσαι, καὶ ἐφοβήθησαν τὸν ὄχλον, ἔγνωσαν γὰρ ὅτι πρὸς αὐτοὺς τὴν παραβολὴν εἶπεν. Καὶ ἀφέντες αὐτὸν, ἀπῆλθον.
41012013 Καὶ ἀποστέλλουσιν πρὸς αὐτόν τινας τῶν Φαρισαίων καὶ τῶν Ἡρῳδιανῶν, ἵνα αὐτὸν ἀγρεύσωσιν λόγῳ.
41012014 Καὶ ἐλθόντες, λέγουσιν αὐτῷ, “Διδάσκαλε, οἴδαμεν ὅτι ἀληθὴς εἶ, καὶ οὐ μέλει σοι περὶ οὐδενός, οὐ γὰρ βλέπεις εἰς πρόσωπον ἀνθρώπων, ἀλλʼ ἐπʼ ἀληθείας τὴν ὁδὸν τοῦ ˚Θεοῦ διδάσκεις. Ἔξεστιν δοῦναι κῆνσον Καίσαρι ἢ οὔ; Δῶμεν ἢ μὴ δῶμεν;”
41012015 Ὁ δὲ εἰδὼς αὐτῶν τὴν ὑπόκρισιν, εἶπεν αὐτοῖς, “Τί με πειράζετε; Φέρετέ μοι δηνάριον, ἵνα ἴδω.”
41012016 Οἱ δὲ ἤνεγκαν. Καὶ λέγει αὐτοῖς, “Τίνος ἡ εἰκὼν αὕτη καὶ ἡ ἐπιγραφή;” Οἱ δὲ εἶπαν αὐτῷ, “Καίσαρος.”
41012017 Ὁ δὲ ˚Ἰησοῦς εἶπεν αὐτοῖς, “Τὰ Καίσαρος ἀπόδοτε Καίσαρι, καὶ τὰ τοῦ ˚Θεοῦ τῷ ˚Θεῷ.” Καὶ ἐξεθαύμαζον ἐπʼ αὐτῷ.
41012018 Καὶ ἔρχονται Σαδδουκαῖοι πρὸς αὐτόν, οἵτινες λέγουσιν ἀνάστασιν μὴ εἶναι, καὶ ἐπηρώτων αὐτὸν λέγοντες,
41012019 “Διδάσκαλε, Μωϋσῆς ἔγραψεν ἡμῖν, ὅτι ἐάν ‘Τινος ἀδελφὸς ἀποθάνῃ, καὶ καταλίπῃ γυναῖκα καὶ μὴ ἀφῇ τέκνα, ἵνα λάβῃ ὁ ἀδελφὸς αὐτοῦ τὴν γυναῖκα, καὶ ἐξαναστήσῃ σπέρμα τῷ ἀδελφῷ αὐτοῦ.’
41012020 Ἑπτὰ ἀδελφοὶ ἦσαν· καὶ ὁ πρῶτος ἔλαβεν γυναῖκα, καὶ ἀποθνῄσκων, οὐκ ἀφῆκεν σπέρμα·
41012021 καὶ ὁ δεύτερος ἔλαβεν αὐτήν, καὶ ἀπέθανεν, μὴ καταλιπὼν σπέρμα, καὶ ὁ τρίτος ὡσαύτως.
41012022 Καὶ οἱ ἑπτὰ, οὐκ ἀφῆκαν σπέρμα. Ἔσχατον πάντων καὶ, ἡ γυνὴ ἀπέθανεν.
41012023 Ἐν τῇ ἀναστάσει, τίνος αὐτῶν ἔσται γυνή; Οἱ γὰρ ἑπτὰ ἔσχον αὐτὴν γυναῖκα.”
41012024 Ἔφη αὐτοῖς ὁ ˚Ἰησοῦς, “Οὐ διὰ τοῦτο πλανᾶσθε, μὴ εἰδότες τὰς Γραφὰς, μηδὲ τὴν δύναμιν τοῦ ˚Θεοῦ;
41012025 Ὅταν γὰρ ἐκ νεκρῶν ἀναστῶσιν, οὔτε γαμοῦσιν οὔτε γαμίζονται, ἀλλʼ εἰσὶν ὡς ἄγγελοι ἐν τοῖς οὐρανοῖς.
41012026 Περὶ δὲ τῶν νεκρῶν, ὅτι ἐγείρονται, οὐκ ἀνέγνωτε ἐν τῇ βίβλῳ Μωϋσέως ἐπὶ τοῦ βάτου, πῶς εἶπεν αὐτῷ ὁ ˚Θεὸς λέγων, ‘Ἐγὼ ὁ ˚Θεὸς Ἀβραὰμ, καὶ ˚Θεὸς Ἰσαὰκ, καὶ ὁ ˚Θεὸς Ἰακώβ’;
41012027 Οὐκ ἔστιν ˚Θεὸς νεκρῶν, ἀλλὰ ζώντων. Πολὺ πλανᾶσθε.”
41012028 Καὶ προσελθὼν εἷς τῶν γραμματέων, ἀκούσας αὐτῶν συζητούντων, ἰδὼν ὅτι καλῶς ἀπεκρίθη αὐτοῖς, ἐπηρώτησεν αὐτόν, “Ποία ἐστὶν ἐντολὴ πρώτη πάντων;”
41012029 ἀπεκρίθη ὁ ˚Ἰησοῦς, ὅτι “Πρώτη ἐστίν: ‘Ἄκουε, Ἰσραήλ, ˚Κύριος ὁ ˚Θεὸς ἡμῶν ˚Κύριος εἷς ἐστιν,
41012030 καὶ ἀγαπήσεις ˚Κύριον τὸν ˚Θεόν σου, ἐξ ὅλης τῆς καρδίας σου, καὶ ἐξ ὅλης τῆς ψυχῆς σου, καὶ ἐξ ὅλης τῆς διανοίας σου, καὶ ἐξ ὅλης τῆς ἰσχύος σου.’
41012031 Δευτέρα αὕτη: ‘Ἀγαπήσεις τὸν πλησίον σου ὡς σεαυτόν.’ Μείζων τούτων ἄλλη ἐντολὴ οὐκ ἔστιν.”
41012032 Καὶ εἶπεν αὐτῷ ὁ γραμματεύς, “Καλῶς, Διδάσκαλε, ἐπʼ ἀληθείας εἶπας, ὅτι ‘Εἷς ἐστιν, καὶ οὐκ ἔστιν ἄλλος πλὴν αὐτοῦ’,
41012033 καὶ, τὸ ‘Ἀγαπᾶν αὐτὸν ἐξ ὅλης τῆς καρδίας, καὶ ἐξ ὅλης τῆς συνέσεως, καὶ ἐξ ὅλης τῆς ἰσχύος’, καὶ, τὸ ‘Ἀγαπᾶν τὸν πλησίον ὡς ἑαυτὸν’, περισσότερόν ἐστιν πάντων τῶν ὁλοκαυτωμάτων καὶ θυσιῶν.”
41012034 Καὶ ὁ ˚Ἰησοῦς, ἰδὼν αὐτὸν, ὅτι νουνεχῶς ἀπεκρίθη, εἶπεν αὐτῷ, “Οὐ μακρὰν εἶ ἀπὸ τῆς Βασιλείας τοῦ ˚Θεοῦ.” Καὶ οὐδεὶς οὐκέτι ἐτόλμα αὐτὸν ἐπερωτῆσαι.
41012035 Καὶ ἀποκριθεὶς ὁ ˚Ἰησοῦς ἔλεγεν διδάσκων ἐν τῷ ἱερῷ, “Πῶς λέγουσιν οἱ γραμματεῖς ὅτι ὁ ˚Χριστὸς, υἱὸς Δαυίδ ἐστιν;
41012036 Αὐτὸς Δαυὶδ εἶπεν ἐν τῷ ˚Πνεύματι τῷ ἁγίῳ, ‘Εἶπεν ὁ ˚Κύριος τῷ ˚Κυρίῳ μου, “Κάθου ἐκ δεξιῶν μου, ἕως ἂν θῶ τοὺς ἐχθρούς σου ὑποκάτω τῶν ποδῶν σου.”’
41012037 Αὐτὸς Δαυὶδ λέγει αὐτὸν, ‘˚Κύριον’, καὶ πόθεν υἱός αὐτοῦ ἐστιν;” Καὶ ὁ πολὺς ὄχλος ἤκουεν αὐτοῦ ἡδέως.
41012038 Καὶ ἐν τῇ διδαχῇ αὐτοῦ, ἔλεγεν, “Βλέπετε ἀπὸ τῶν γραμματέων, τῶν θελόντων ἐν στολαῖς περιπατεῖν, καὶ ἀσπασμοὺς ἐν ταῖς ἀγοραῖς,
41012039 καὶ πρωτοκαθεδρίας ἐν ταῖς συναγωγαῖς, καὶ πρωτοκλισίας ἐν τοῖς δείπνοις,
41012040 οἱ κατεσθίοντες τὰς οἰκίας τῶν χηρῶν, καὶ προφάσει μακρὰ προσευχόμενοι. Οὗτοι λήμψονται περισσότερον κρίμα.”
41012041 Καὶ καθίσας κατέναντι τοῦ γαζοφυλακίου, ἐθεώρει πῶς ὁ ὄχλος βάλλει χαλκὸν εἰς τὸ γαζοφυλάκιον· καὶ πολλοὶ πλούσιοι ἔβαλλον πολλά.
41012042 Καὶ ἐλθοῦσα μία χήρα πτωχὴ ἔβαλεν λεπτὰ δύο, ὅ ἐστιν κοδράντης.
41012043 Καὶ προσκαλεσάμενος τοὺς μαθητὰς αὐτοῦ, εἶπεν αὐτοῖς, “Ἀμὴν, λέγω ὑμῖν ὅτι ἡ χήρα αὕτη ἡ πτωχὴ, πλεῖον πάντων ἔβαλεν τῶν βαλλόντων εἰς τὸ γαζοφυλάκιον.
41012044 Πάντες γὰρ ἐκ τοῦ περισσεύοντος αὐτοῖς ἔβαλον, αὕτη δὲ ἐκ τῆς ὑστερήσεως αὐτῆς πάντα ὅσα εἶχεν ἔβαλεν, ὅλον τὸν βίον αὐτῆς.”
41013001 Καὶ ἐκπορευομένου αὐτοῦ ἐκ τοῦ ἱεροῦ, λέγει αὐτῷ εἷς τῶν μαθητῶν αὐτοῦ, “Διδάσκαλε, ἴδε, ποταποὶ λίθοι καὶ ποταπαὶ οἰκοδομαί!”
41013002 Καὶ ὁ ˚Ἰησοῦς εἶπεν αὐτῷ, “Βλέπεις ταύτας τὰς μεγάλας οἰκοδομάς; Οὐ μὴ ἀφεθῇ ὧδε λίθος ἐπὶ λίθον, ὃς οὐ μὴ καταλυθῇ.”
41013003 Καὶ καθημένου αὐτοῦ εἰς τὸ Ὄρος τῶν Ἐλαιῶν κατέναντι τοῦ ἱεροῦ, ἐπηρώτα αὐτὸν κατʼ ἰδίαν Πέτρος, καὶ Ἰάκωβος, καὶ Ἰωάννης, καὶ Ἀνδρέας,
41013004 “Εἰπὸν ἡμῖν πότε ταῦτα ἔσται, καὶ τί τὸ σημεῖον ὅταν μέλλῃ ταῦτα συντελεῖσθαι πάντα;”
41013005 Ὁ δὲ ˚Ἰησοῦς ἤρξατο λέγειν αὐτοῖς, “Βλέπετε, μή τις ὑμᾶς πλανήσῃ.
41013006 Πολλοὶ ἐλεύσονται ἐπὶ τῷ ὀνόματί μου λέγοντες, ὅτι ‘Ἐγώ εἰμι’, καὶ πολλοὺς πλανήσουσιν.
41013007 Ὅταν δὲ ἀκούσητε πολέμους καὶ ἀκοὰς πολέμων, μὴ θροεῖσθε· δεῖ γενέσθαι, ἀλλʼ οὔπω τὸ τέλος.
41013008 Ἐγερθήσεται γὰρ ἔθνος ἐπὶ ἔθνος, καὶ βασιλεία ἐπὶ βασιλείαν. Ἔσονται σεισμοὶ κατὰ τόπους, ἔσονται λιμοί· ἀρχὴ ὠδίνων ταῦτα.
41013009 Βλέπετε δὲ ὑμεῖς ἑαυτούς· παραδώσουσιν ὑμᾶς εἰς συνέδρια καὶ εἰς συναγωγὰς δαρήσεσθε, καὶ ἐπὶ ἡγεμόνων καὶ βασιλέων σταθήσεσθε ἕνεκεν ἐμοῦ, εἰς μαρτύριον αὐτοῖς.
41013010 Καὶ εἰς πάντα τὰ ἔθνη πρῶτον δεῖ κηρυχθῆναι τὸ εὐαγγέλιον.
41013011 Καὶ ὅταν ἄγωσιν ὑμᾶς παραδιδόντες, μὴ προμεριμνᾶτε τί λαλήσητε, ἀλλʼ ὃ ἐὰν δοθῇ ὑμῖν ἐν ἐκείνῃ τῇ ὥρᾳ, τοῦτο λαλεῖτε, οὐ γάρ ἐστε ὑμεῖς οἱ λαλοῦντες, ἀλλὰ τὸ ˚Πνεῦμα τὸ Ἅγιον.
41013012 Καὶ παραδώσει ἀδελφὸς ἀδελφὸν εἰς θάνατον, καὶ πατὴρ τέκνον, καὶ ἐπαναστήσονται τέκνα ἐπὶ γονεῖς καὶ θανατώσουσιν αὐτούς.
41013013 Καὶ ἔσεσθε μισούμενοι ὑπὸ πάντων διὰ τὸ ὄνομά μου. Ὁ δὲ ὑπομείνας εἰς τέλος, οὗτος σωθήσεται.
41013014 Ὅταν δὲ ἴδητε ‘τὸ βδέλυγμα τῆς ἐρημώσεως’, ἑστηκότα ὅπου οὐ δεῖ, ὁ ἀναγινώσκων νοείτω, τότε οἱ ἐν τῇ Ἰουδαίᾳ, φευγέτωσαν εἰς τὰ ὄρη,
41013015 ὁ δὲ ἐπὶ τοῦ δώματος, μὴ καταβάτω, μηδὲ εἰσελθάτω ἆραι τι ἐκ τῆς οἰκίας αὐτοῦ,
41013016 καὶ ὁ εἰς τὸν ἀγρὸν, μὴ ἐπιστρεψάτω εἰς τὰ ὀπίσω, ἆραι τὸ ἱμάτιον αὐτοῦ.
41013017 Οὐαὶ δὲ ταῖς ἐν γαστρὶ ἐχούσαις καὶ ταῖς θηλαζούσαις ἐν ἐκείναις ταῖς ἡμέραις!
41013018 Προσεύχεσθε δὲ ἵνα μὴ γένηται χειμῶνος.
41013019 Ἔσονται γὰρ αἱ ἡμέραι ἐκεῖναι θλῖψις, οἵα οὐ γέγονεν τοιαύτη, ἀπʼ ἀρχῆς κτίσεως ἣν ἔκτισεν ὁ ˚Θεὸς, ἕως τοῦ νῦν, καὶ οὐ μὴ γένηται.
41013020 Καὶ εἰ μὴ ἐκολόβωσεν ˚Κύριος τὰς ἡμέρας, οὐκ ἂν ἐσώθη πᾶσα σάρξ. Ἀλλὰ διὰ τοὺς ἐκλεκτοὺς, οὓς ἐξελέξατο, ἐκολόβωσεν τὰς ἡμέρας.
41013021 Καὶ τότε ἐάν τις ὑμῖν εἴπῃ, ‘Ἴδε, ὧδε ὁ ˚Χριστός’, ἢ ‘Ἴδε, ἐκεῖ’, μὴ πιστεύετε.
41013022 Ἐγερθήσονται γὰρ ψευδόχριστοι καὶ ψευδοπροφῆται, καὶ δώσουσιν σημεῖα καὶ τέρατα, πρὸς τὸ ἀποπλανᾶν εἰ δυνατὸν τοὺς ἐκλεκτούς.
41013023 Ὑμεῖς δὲ βλέπετε· ἰδοὺ, προείρηκα ὑμῖν πάντα.
41013024 Ἀλλὰ ἐν ἐκείναις ταῖς ἡμέραις, μετὰ τὴν θλῖψιν ἐκείνην, ‘ὁ ἥλιος σκοτισθήσεται, καὶ ἡ σελήνη οὐ δώσει τὸ φέγγος αὐτῆς’,
41013025 ‘καὶ οἱ ἀστέρες ἔσονται ἐκ τοῦ οὐρανοῦ πίπτοντες’, καὶ αἱ δυνάμεις αἱ ἐν τοῖς οὐρανοῖς σαλευθήσονται.
41013026 Καὶ τότε ὄψονται ‘τὸν Υἱὸν τοῦ Ἀνθρώπου ἐρχόμενον ἐν νεφέλαις’, μετὰ δυνάμεως πολλῆς καὶ δόξης.
41013027 Καὶ τότε ἀποστελεῖ τοὺς ἀγγέλους καὶ ἐπισυνάξει τοὺς ἐκλεκτοὺς αὐτοῦ, ἐκ τῶν τεσσάρων ἀνέμων, ἀπʼ ἄκρου γῆς ἕως ἄκρου οὐρανοῦ.
41013028 Ἀπὸ δὲ τῆς συκῆς, μάθετε τὴν παραβολήν: ὅταν ἤδη ὁ κλάδος αὐτῆς ἁπαλὸς γένηται, καὶ ἐκφύῃ τὰ φύλλα, γινώσκετε ὅτι ἐγγὺς τὸ θέρος ἐστίν.
41013029 Οὕτως καὶ ὑμεῖς, ὅταν ἴδητε ταῦτα γινόμενα, γινώσκετε ὅτι ἐγγύς ἐστιν ἐπὶ θύραις.
41013030 Ἀμὴν, λέγω ὑμῖν ὅτι οὐ μὴ παρέλθῃ ἡ γενεὰ αὕτη, μέχρις οὗ ταῦτα πάντα γένηται.
41013031 Ὁ οὐρανὸς καὶ ἡ γῆ παρελεύσονται, οἱ δὲ λόγοι μου οὐ μὴ παρελεύσονται.
41013032 Περὶ δὲ τῆς ἡμέρας ἐκείνης ἢ τῆς ὥρας, οὐδεὶς οἶδεν, οὐδὲ οἱ ἄγγελοι ἐν οὐρανῷ, οὐδὲ ὁ Υἱός, εἰ μὴ ὁ Πατήρ.
41013033 Βλέπετε, ἀγρυπνεῖτε, καὶ προσεύχεσθε, οὐκ οἴδατε γὰρ πότε ὁ καιρός ἐστιν.
41013034 Ὡς ἄνθρωπος ἀπόδημος, ἀφεὶς τὴν οἰκίαν αὐτοῦ, καὶ δοὺς τοῖς δούλοις αὐτοῦ τὴν ἐξουσίαν, ἑκάστῳ τὸ ἔργον αὐτοῦ, καὶ τῷ θυρωρῷ ἐνετείλατο ἵνα γρηγορῇ.
41013035 Γρηγορεῖτε οὖν, οὐκ οἴδατε γὰρ, πότε ὁ κύριος τῆς οἰκίας ἔρχεται, ἢ ὀψὲ, ἢ μεσονύκτιον, ἢ ἀλεκτοροφωνίας, ἢ πρωΐ,
41013036 μὴ ἐλθὼν ἐξαίφνης, εὕρῃ ὑμᾶς καθεύδοντας.
41013037 Ὃ δὲ ὑμῖν λέγω, πᾶσιν λέγω, γρηγορεῖτε!”
41014001 Ἦν δὲ τὸ Πάσχα καὶ τὰ Ἄζυμα μετὰ δύο ἡμέρας. Καὶ ἐζήτουν οἱ ἀρχιερεῖς καὶ οἱ γραμματεῖς, πῶς αὐτὸν ἐν δόλῳ κρατήσαντες, ἀποκτείνωσιν.
41014002 Ἔλεγον γάρ, “Μὴ ἐν τῇ ἑορτῇ, μήποτε ἔσται θόρυβος τοῦ λαοῦ.”
41014003 Καὶ ὄντος αὐτοῦ ἐν Βηθανίᾳ, ἐν τῇ οἰκίᾳ Σίμωνος τοῦ λεπροῦ, κατακειμένου αὐτοῦ, ἦλθεν γυνὴ ἔχουσα ἀλάβαστρον μύρου, νάρδου πιστικῆς πολυτελοῦς· συντρίψασα τὴν ἀλάβαστρον, κατέχεεν αὐτοῦ τῆς κεφαλῆς.
41014004 Ἦσαν δέ τινες ἀγανακτοῦντες πρὸς ἑαυτούς, “Εἰς τί ἡ ἀπώλεια αὕτη τοῦ μύρου γέγονεν;
41014005 Ἠδύνατο γὰρ τοῦτο τὸ μύρον πραθῆναι ἐπάνω δηναρίων τριακοσίων, καὶ δοθῆναι τοῖς πτωχοῖς.” Καὶ ἐνεβριμῶντο αὐτῇ.
41014006 Ὁ δὲ ˚Ἰησοῦς εἶπεν, “Ἄφετε αὐτήν· τί αὐτῇ κόπους παρέχετε; Καλὸν ἔργον ἠργάσατο ἐν ἐμοί.
41014007 Πάντοτε γὰρ τοὺς πτωχοὺς ἔχετε μεθʼ ἑαυτῶν, καὶ ὅταν θέλητε, δύνασθε αὐτοῖς εὖ ποιῆσαι, ἐμὲ δὲ οὐ πάντοτε ἔχετε.
41014008 Ὃ ἔσχεν ἐποίησεν, προέλαβεν μυρίσαι τὸ σῶμά μου εἰς τὸν ἐνταφιασμόν.
41014009 Ἀμὴν, δὲ λέγω ὑμῖν, ὅπου ἐὰν κηρυχθῇ τὸ εὐαγγέλιον εἰς ὅλον τὸν κόσμον, καὶ ὃ ἐποίησεν αὕτη, λαληθήσεται εἰς μνημόσυνον αὐτῆς.”
41014010 Καὶ Ἰούδας Ἰσκαριὼθ, ὁ εἷς τῶν δώδεκα, ἀπῆλθεν πρὸς τοὺς ἀρχιερεῖς, ἵνα αὐτὸν παραδῷ αὐτοῖς.
41014011 Οἱ δὲ ἀκούσαντες ἐχάρησαν, καὶ ἐπηγγείλαντο αὐτῷ ἀργύριον δοῦναι. Καὶ ἐζήτει πῶς αὐτὸν εὐκαίρως παραδοῖ.
41014012 Καὶ τῇ πρώτῃ ἡμέρᾳ τῶν Ἀζύμων, ὅτε τὸ Πάσχα ἔθυον, λέγουσιν αὐτῷ οἱ μαθηταὶ αὐτοῦ, “Ποῦ θέλεις ἀπελθόντες ἑτοιμάσωμεν, ἵνα φάγῃς τὸ Πάσχα;”
41014013 Καὶ ἀποστέλλει δύο τῶν μαθητῶν αὐτοῦ, καὶ λέγει αὐτοῖς, “Ὑπάγετε εἰς τὴν πόλιν, καὶ ἀπαντήσει ὑμῖν ἄνθρωπος, κεράμιον ὕδατος βαστάζων· ἀκολουθήσατε αὐτῷ.
41014014 Καὶ ὅπου ἐὰν εἰσέλθῃ, εἴπατε τῷ οἰκοδεσπότῃ, ὅτι ‘Ὁ διδάσκαλος λέγει, “Ποῦ ἐστιν τὸ κατάλυμά μου, ὅπου τὸ Πάσχα μετὰ τῶν μαθητῶν μου φάγω;”’
41014015 Καὶ αὐτὸς ὑμῖν δείξει ἀνάγαιον μέγα ἐστρωμένον ἕτοιμον· ἐκεῖ ἑτοιμάσατε ἡμῖν.”
41014016 Καὶ ἐξῆλθον οἱ μαθηταὶ, καὶ ἦλθον εἰς τὴν πόλιν, καὶ εὗρον καθὼς εἶπεν αὐτοῖς, καὶ ἡτοίμασαν τὸ Πάσχα.
41014017 Καὶ ὀψίας γενομένης, ἔρχεται μετὰ τῶν δώδεκα.
41014018 Καὶ ἀνακειμένων αὐτῶν καὶ ἐσθιόντων, ὁ ˚Ἰησοῦς εἶπεν, “Ἀμὴν, λέγω ὑμῖν ὅτι εἷς ἐξ ὑμῶν παραδώσει με, ὁ ἐσθίων μετʼ ἐμοῦ.”
41014019 Ἤρξαντο λυπεῖσθαι, καὶ λέγειν αὐτῷ εἷς καθʼ εἷς, “Μήτι ἐγώ;”
41014020 Ὁ δὲ εἶπεν αὐτοῖς, “Εἷς τῶν δώδεκα, ὁ ἐμβαπτόμενος μετʼ ἐμοῦ εἰς τὸ τρύβλιον.
41014021 ὅτι Ὁ μὲν Υἱὸς τοῦ Ἀνθρώπου ὑπάγει, καθὼς γέγραπται περὶ αὐτοῦ, οὐαὶ δὲ τῷ ἀνθρώπῳ ἐκείνῳ διʼ οὗ ὁ Υἱὸς τοῦ Ἀνθρώπου παραδίδοται· καλὸν ἦν αὐτῷ εἰ οὐκ ἐγεννήθη ὁ ἄνθρωπος ἐκεῖνος.”
41014022 Καὶ ἐσθιόντων αὐτῶν, λαβὼν ἄρτον, εὐλογήσας, ἔκλασεν καὶ ἔδωκεν αὐτοῖς, καὶ εἶπεν, “Λάβετε, τοῦτό ἐστιν τὸ σῶμά μου.”
41014023 Καὶ λαβὼν ποτήριον, εὐχαριστήσας, ἔδωκεν αὐτοῖς, καὶ ἔπιον ἐξ αὐτοῦ πάντες.
41014024 Καὶ εἶπεν αὐτοῖς, “Τοῦτό ἐστιν τὸ αἷμά μου τῆς διαθήκης, τὸ ἐκχυννόμενον ὑπὲρ πολλῶν.
41014025 Ἀμὴν, λέγω ὑμῖν ὅτι οὐκέτι οὐ μὴ πίω ἐκ τοῦ γενήματος τῆς ἀμπέλου, ἕως τῆς ἡμέρας ἐκείνης ὅταν αὐτὸ πίνω καινὸν, ἐν τῇ Βασιλείᾳ τοῦ ˚Θεοῦ.”
41014026 Καὶ ὑμνήσαντες, ἐξῆλθον εἰς τὸ Ὄρος τῶν Ἐλαιῶν.
41014027 Καὶ λέγει αὐτοῖς ὁ ˚Ἰησοῦς, ὅτι “Πάντες σκανδαλισθήσεσθε, ὅτι γέγραπται, ‘Πατάξω τὸν ποιμένα καὶ τὰ πρόβατα διασκορπισθήσονται.’
41014028 Ἀλλὰ μετὰ τὸ ἐγερθῆναί με, προάξω ὑμᾶς εἰς τὴν Γαλιλαίαν.”
41014029 Ὁ δὲ Πέτρος ἔφη αὐτῷ, “Εἰ καὶ πάντες σκανδαλισθήσονται, ἀλλʼ οὐκ ἐγώ.”
41014030 Καὶ λέγει αὐτῷ ὁ ˚Ἰησοῦς, “Ἀμὴν, λέγω σοι ὅτι σὺ σήμερον, ταύτῃ τῇ νυκτὶ, πρὶν ἢ δὶς ἀλέκτορα φωνῆσαι, τρίς με ἀπαρνήσῃ.”
41014031 Ὁ δὲ ἐκπερισσῶς ἐλάλει, “Ἐὰν δέῃ με συναποθανεῖν σοι, οὐ μή σε ἀπαρνήσομαι.” Ὡσαύτως δὲ καὶ πάντες ἔλεγον.
41014032 Καὶ ἔρχονται εἰς χωρίον, οὗ τὸ ὄνομα Γεθσημανί, καὶ λέγει τοῖς μαθηταῖς αὐτοῦ, “Καθίσατε ὧδε, ἕως προσεύξωμαι.”
41014033 Καὶ παραλαμβάνει τὸν Πέτρον, καὶ τὸν Ἰάκωβον, καὶ τὸν Ἰωάννην, μετʼ αὐτοῦ, καὶ ἤρξατο ἐκθαμβεῖσθαι καὶ ἀδημονεῖν.
41014034 Καὶ λέγει αὐτοῖς, “Περίλυπός ἐστιν ἡ ψυχή μου ἕως θανάτου· μείνατε ὧδε καὶ γρηγορεῖτε.”
41014035 Καὶ προελθὼν μικρὸν, ἔπιπτεν ἐπὶ τῆς γῆς καὶ προσηύχετο ἵνα εἰ δυνατόν ἐστιν, παρέλθῃ ἀπʼ αὐτοῦ ἡ ὥρα.
41014036 Καὶ ἔλεγεν, “Ἀββά, ὁ Πατήρ, πάντα δυνατά σοι. Παρένεγκε τὸ ποτήριον τοῦτο ἀπʼ ἐμοῦ· ἀλλʼ οὐ τί ἐγὼ θέλω, ἀλλὰ τί σύ.”
41014037 Καὶ ἔρχεται καὶ εὑρίσκει αὐτοὺς καθεύδοντας, καὶ λέγει τῷ Πέτρῳ, “Σίμων, καθεύδεις; Οὐκ ἴσχυσας μίαν ὥραν γρηγορῆσαι;
41014038 Γρηγορεῖτε καὶ προσεύχεσθε ἵνα μὴ ἔλθητε εἰς πειρασμόν. Τὸ μὲν πνεῦμα πρόθυμον, ἡ δὲ σὰρξ ἀσθενής.”
41014039 Καὶ πάλιν ἀπελθὼν, προσηύξατο τὸν αὐτὸν λόγον εἰπών.
41014040 Καὶ πάλιν ἐλθὼν, εὗρεν αὐτοὺς καθεύδοντας, ἦσαν γὰρ αὐτῶν οἱ ὀφθαλμοὶ καταβαρυνόμενοι, καὶ οὐκ ᾔδεισαν τί ἀποκριθῶσιν αὐτῷ.
41014041 Καὶ ἔρχεται τὸ τρίτον, καὶ λέγει αὐτοῖς, “Καθεύδετε τὸ λοιπὸν καὶ ἀναπαύεσθε. Ἀπέχει, ἦλθεν ἡ ὥρα. Ἰδοὺ, παραδίδοται ὁ Υἱὸς τοῦ Ἀνθρώπου εἰς τὰς χεῖρας τῶν ἁμαρτωλῶν.
41014042 Ἐγείρεσθε, ἄγωμεν. Ἰδοὺ, ὁ παραδιδούς με ἤγγικεν!”
41014043 Καὶ εὐθὺς ἔτι αὐτοῦ λαλοῦντος, παραγίνεται Ἰούδας, εἷς τῶν δώδεκα, καὶ μετʼ αὐτοῦ ὄχλος μετὰ μαχαιρῶν καὶ ξύλων, παρὰ τῶν ἀρχιερέων, καὶ τῶν γραμματέων, καὶ τῶν πρεσβυτέρων.
41014044 Δεδώκει δὲ ὁ παραδιδοὺς αὐτὸν σύσσημον αὐτοῖς λέγων, “Ὃν ἂν φιλήσω, αὐτός ἐστιν· κρατήσατε αὐτὸν, καὶ ἀπάγετε ἀσφαλῶς.”
41014045 Καὶ ἐλθὼν εὐθὺς προσελθὼν αὐτῷ, λέγει, “Ῥαββὶ”, καὶ κατεφίλησεν αὐτόν.
41014046 Οἱ δὲ ἐπέβαλαν τὰς χεῖρας αὐτῶν καὶ ἐκράτησαν αὐτόν.
41014047 Εἷς δέ τις τῶν παρεστηκότων, σπασάμενος τὴν μάχαιραν, ἔπαισεν τὸν δοῦλον τοῦ ἀρχιερέως, καὶ ἀφεῖλεν αὐτοῦ τὸ ὠτάριον.
41014048 Καὶ ἀποκριθεὶς ὁ ˚Ἰησοῦς εἶπεν αὐτοῖς, “Ὡς ἐπὶ λῃστὴν ἐξήλθατε μετὰ μαχαιρῶν καὶ ξύλων συλλαβεῖν με;
41014049 Καθʼ ἡμέραν ἤμην πρὸς ὑμᾶς ἐν τῷ ἱερῷ διδάσκων, καὶ οὐκ ἐκρατήσατέ με· ἀλλʼ ἵνα πληρωθῶσιν αἱ Γραφαί.”
41014050 Καὶ ἀφέντες αὐτὸν, ἔφυγον πάντες.
41014051 Καὶ νεανίσκος τις συνηκολούθει αὐτῷ, περιβεβλημένος σινδόνα ἐπὶ γυμνοῦ. Καὶ κρατοῦσιν αὐτόν,
41014052 ὁ δὲ καταλιπὼν τὴν σινδόνα, γυμνὸς ἔφυγεν.
41014053 Καὶ ἀπήγαγον τὸν ˚Ἰησοῦν πρὸς τὸν ἀρχιερέα, καὶ συνέρχονται αὐτῷ πάντες οἱ ἀρχιερεῖς, καὶ οἱ πρεσβύτεροι, καὶ οἱ γραμματεῖς.
41014054 Καὶ ὁ Πέτρος ἀπὸ μακρόθεν ἠκολούθησεν αὐτῷ, ἕως ἔσω εἰς τὴν αὐλὴν τοῦ ἀρχιερέως· καὶ ἦν συγκαθήμενος μετὰ τῶν ὑπηρετῶν, καὶ θερμαινόμενος πρὸς τὸ φῶς.
41014055 Οἱ δὲ ἀρχιερεῖς καὶ ὅλον τὸ Συνέδριον ἐζήτουν κατὰ τοῦ ˚Ἰησοῦ μαρτυρίαν, εἰς τὸ θανατῶσαι αὐτόν, καὶ οὐχ ηὕρισκον.
41014056 Πολλοὶ γὰρ ἐψευδομαρτύρουν κατʼ αὐτοῦ, καὶ ἴσαι αἱ μαρτυρίαι οὐκ ἦσαν.
41014057 Καί τινες ἀναστάντες, ἐψευδομαρτύρουν κατʼ αὐτοῦ λέγοντες,
41014058 ὅτι “Ἡμεῖς ἠκούσαμεν αὐτοῦ λέγοντος, ὅτι ‘Ἐγὼ καταλύσω τὸν ναὸν τοῦτον, τὸν χειροποίητον, καὶ διὰ τριῶν ἡμερῶν ἄλλον ἀχειροποίητον οἰκοδομήσω.’”
41014059 Καὶ οὐδὲ οὕτως ἴση ἦν ἡ μαρτυρία αὐτῶν.
41014060 Καὶ ἀναστὰς ὁ ἀρχιερεὺς εἰς μέσον, ἐπηρώτησεν τὸν ˚Ἰησοῦν λέγων, “Οὐκ ἀποκρίνῃ οὐδέν; Τί οὗτοί σου καταμαρτυροῦσιν;”
41014061 Ὁ δὲ ἐσιώπα, καὶ οὐκ ἀπεκρίνατο οὐδέν. Πάλιν ὁ ἀρχιερεὺς ἐπηρώτα αὐτὸν, καὶ λέγει αὐτῷ, “Σὺ εἶ ὁ ˚Χριστὸς, ὁ Υἱὸς τοῦ εὐλογητοῦ;”
41014062 Ὁ δὲ ˚Ἰησοῦς εἶπεν, “Ἐγώ εἰμι. Καὶ ὄψεσθε τὸν Υἱὸν τοῦ Ἀνθρώπου ‘ἐκ δεξιῶν καθήμενον’ τῆς δυνάμεως, καὶ ‘ἐρχόμενον μετὰ τῶν νεφελῶν τοῦ οὐρανοῦ’.”
41014063 Ὁ δὲ ἀρχιερεὺς διαρρήξας τοὺς χιτῶνας αὐτοῦ λέγει, “Τί ἔτι χρείαν ἔχομεν μαρτύρων;
41014064 Ἠκούσατε τῆς βλασφημίας. Τί ὑμῖν φαίνεται;” Οἱ δὲ πάντες κατέκριναν αὐτὸν, ἔνοχον εἶναι θανάτου.
41014065 Καὶ ἤρξαντό τινες ἐμπτύειν αὐτῷ, καὶ περικαλύπτειν αὐτοῦ τὸ πρόσωπον, καὶ κολαφίζειν αὐτὸν, καὶ λέγειν αὐτῷ, “Προφήτευσον!” Καὶ οἱ ὑπηρέται ῥαπίσμασιν αὐτὸν ἔλαβον.
41014066 Καὶ ὄντος τοῦ Πέτρου κάτω ἐν τῇ αὐλῇ, ἔρχεται μία τῶν παιδισκῶν τοῦ ἀρχιερέως,
41014067 καὶ ἰδοῦσα τὸν Πέτρον θερμαινόμενον, ἐμβλέψασα αὐτῷ λέγει, “Καὶ σὺ μετὰ τοῦ Ναζαρηνοῦ ἦσθα τοῦ ˚Ἰησοῦ.”
41014068 Ὁ δὲ ἠρνήσατο λέγων, “Οὔτε οἶδα, οὔτε ἐπίσταμαι σὺ τί λέγεις”. Καὶ ἐξῆλθεν ἔξω εἰς τὸ προαύλιον.
41014069 Καὶ ἡ παιδίσκη ἰδοῦσα αὐτὸν, ἤρξατο πάλιν λέγειν τοῖς παρεστῶσιν, ὅτι “Οὗτος ἐξ αὐτῶν ἐστιν.”
41014070 Ὁ δὲ πάλιν ἠρνεῖτο. Καὶ μετὰ μικρὸν, πάλιν οἱ παρεστῶτες ἔλεγον τῷ Πέτρῳ, “Ἀληθῶς ἐξ αὐτῶν εἶ, καὶ γὰρ Γαλιλαῖος εἶ”,
41014071 ὁ δὲ ἤρξατο ἀναθεματίζειν καὶ ὀμνύειν, ὅτι “Οὐκ οἶδα τὸν ἄνθρωπον τοῦτον, ὃν λέγετε!”
41014072 Καὶ εὐθὺς ἐκ δευτέρου ἀλέκτωρ ἐφώνησεν. Καὶ ἀνεμνήσθη ὁ Πέτρος τὸ ῥῆμα ὡς εἶπεν αὐτῷ ὁ ˚Ἰησοῦς, ὅτι “Πρὶν ἀλέκτορα φωνῆσαι τρίς με ἀπαρνήσῃ”, καὶ ἐπιβαλὼν, ἔκλαιεν.
41015001 Καὶ εὐθὺς πρωῒ, συμβούλιον ποιήσαντες οἱ ἀρχιερεῖς, μετὰ τῶν πρεσβυτέρων καὶ γραμματέων, καὶ ὅλον τὸ Συνέδριον, δήσαντες τὸν ˚Ἰησοῦν, ἀπήνεγκαν καὶ παρέδωκαν Πιλάτῳ.
41015002 Καὶ ἐπηρώτησεν αὐτὸν ὁ Πιλᾶτος, “Σὺ εἶ ὁ Βασιλεὺς τῶν Ἰουδαίων;” Ὁ δὲ ἀποκριθεὶς αὐτῷ λέγει, “Σὺ λέγεις.”
41015003 Καὶ κατηγόρουν αὐτοῦ οἱ ἀρχιερεῖς πολλά.
41015004 Ὁ δὲ Πιλᾶτος πάλιν ἐπηρώτησεν αὐτὸν λέγων, “Οὐκ ἀποκρίνῃ οὐδέν; Ἴδε, πόσα σου κατηγοροῦσιν!”
41015005 Ὁ δὲ ˚Ἰησοῦς οὐκέτι οὐδὲν ἀπεκρίθη, ὥστε θαυμάζειν τὸν Πιλᾶτον.
41015006 Κατὰ δὲ ἑορτὴν, ἀπέλυεν αὐτοῖς ἕνα δέσμιον, ὃν παρῃτοῦντο.
41015007 Ἦν δὲ ὁ λεγόμενος Βαραββᾶς, μετὰ τῶν στασιαστῶν δεδεμένος, οἵτινες ἐν τῇ στάσει φόνον πεποιήκεισαν.
41015008 Καὶ ἀναβὰς, ὁ ὄχλος ἤρξατο αἰτεῖσθαι καθὼς ἐποίει αὐτοῖς.
41015009 Ὁ δὲ Πιλᾶτος ἀπεκρίθη αὐτοῖς λέγων, “Θέλετε ἀπολύσω ὑμῖν τὸν Βασιλέα τῶν Ἰουδαίων;”
41015010 Ἐγίνωσκεν γὰρ ὅτι διὰ φθόνον παραδεδώκεισαν αὐτὸν οἱ ἀρχιερεῖς.
41015011 Οἱ δὲ ἀρχιερεῖς ἀνέσεισαν τὸν ὄχλον, ἵνα μᾶλλον τὸν Βαραββᾶν ἀπολύσῃ αὐτοῖς.
41015012 Ὁ δὲ Πιλᾶτος πάλιν ἀποκριθεὶς ἔλεγεν αὐτοῖς, “Τί οὖν ποιήσω ὃν λέγετε τὸν Βασιλέα τῶν Ἰουδαίων;”
41015013 Οἱ δὲ πάλιν ἔκραξαν, “Σταύρωσον αὐτόν!”
41015014 Ὁ δὲ Πιλᾶτος ἔλεγεν αὐτοῖς, “Τί γὰρ ἐποίησεν κακόν;” Οἱ δὲ περισσῶς ἔκραξαν, “Σταύρωσον αὐτόν!”
41015015 Ὁ δὲ Πιλᾶτος βουλόμενος τῷ ὄχλῳ τὸ ἱκανὸν ποιῆσαι, ἀπέλυσεν αὐτοῖς τὸν Βαραββᾶν, καὶ παρέδωκεν τὸν ˚Ἰησοῦν φραγελλώσας, ἵνα σταυρωθῇ.
41015016 Οἱ δὲ στρατιῶται ἀπήγαγον αὐτὸν ἔσω τῆς αὐλῆς, ὅ ἐστιν πραιτώριον, καὶ συγκαλοῦσιν ὅλην τὴν σπεῖραν.
41015017 Καὶ ἐνδιδύσκουσιν αὐτὸν πορφύραν, καὶ περιτιθέασιν αὐτῷ πλέξαντες ἀκάνθινον στέφανον,
41015018 καὶ ἤρξαντο ἀσπάζεσθαι αὐτόν, “Χαῖρε, Βασιλεῦ τῶν Ἰουδαίων!”
41015019 Καὶ ἔτυπτον αὐτοῦ τὴν κεφαλὴν καλάμῳ, καὶ ἐνέπτυον αὐτῷ, καὶ τιθέντες τὰ γόνατα, προσεκύνουν αὐτῷ.
41015020 Καὶ ὅτε ἐνέπαιξαν αὐτῷ, ἐξέδυσαν αὐτὸν τὴν πορφύραν, καὶ ἐνέδυσαν αὐτὸν τὰ ἴδια ἱμάτια αὐτοῦ. Καὶ ἐξάγουσιν αὐτὸν, ἵνα σταυρώσωσιν αὐτόν.
41015021 Καὶ ἀγγαρεύουσιν παράγοντά τινα Σίμωνα Κυρηναῖον, ἐρχόμενον ἀπʼ ἀγροῦ, τὸν πατέρα Ἀλεξάνδρου καὶ Ῥούφου, ἵνα ἄρῃ τὸν σταυρὸν αὐτοῦ.
41015022 Καὶ φέρουσιν αὐτὸν ἐπὶ τὸν Γολγοθᾶν, τόπον ὅ ἐστιν μεθερμηνευόμενον, “Κρανίου Τόπος”.
41015023 Καὶ ἐδίδουν αὐτῷ, ἐσμυρνισμένον οἶνον, ὃς δὲ οὐκ ἔλαβεν.
41015024 Καὶ σταυρώσαντες αὐτὸν, διαμερίζονται τὰ ἱμάτια αὐτοῦ, βάλλοντες κλῆρον ἐπʼ αὐτὰ, τίς τί ἄρῃ.
41015025 Ἦν δὲ ὥρα τρίτη, καὶ ἐσταύρωσαν αὐτόν.
41015026 Καὶ ἦν ἡ ἐπιγραφὴ τῆς αἰτίας αὐτοῦ ἐπιγεγραμμένη, “Ὁ Βασιλεὺς τῶν Ἰουδαίων.”
41015027 Καὶ σὺν αὐτῷ σταυροῦσιν δύο λῃστάς, ἕνα ἐκ δεξιῶν καὶ ἕνα ἐξ εὐωνύμων αὐτοῦ.
41015029 Καὶ οἱ παραπορευόμενοι ἐβλασφήμουν αὐτὸν, κινοῦντες τὰς κεφαλὰς αὐτῶν, καὶ λέγοντες, “Οὐὰ, ὁ καταλύων τὸν ναὸν καὶ οἰκοδομῶν ἐν τρισὶν ἡμέραις,
41015030 σῶσον σεαυτὸν, καταβὰς ἀπὸ τοῦ σταυροῦ!”
41015031 Ὁμοίως καὶ οἱ ἀρχιερεῖς, ἐμπαίζοντες πρὸς ἀλλήλους μετὰ τῶν γραμματέων ἔλεγον, “Ἄλλους ἔσωσεν, ἑαυτὸν οὐ δύναται σῶσαι.
41015032 Ὁ ˚Χριστὸς, ὁ Βασιλεὺς Ἰσραὴλ καταβάτω νῦν ἀπὸ τοῦ σταυροῦ, ἵνα ἴδωμεν καὶ πιστεύσωμεν!” Καὶ οἱ συνεσταυρωμένοι σὺν αὐτῷ ὠνείδιζον αὐτόν.
41015033 Καὶ γενομένης ὥρας ἕκτης, σκότος ἐγένετο ἐφʼ ὅλην τὴν γῆν, ἕως ὥρας ἐνάτης.
41015034 Καὶ τῇ ἐνάτῃ ὥρᾳ, ἐβόησεν ὁ ˚Ἰησοῦς φωνῇ μεγάλῃ, “Ἐλωῒ, ἐλωῒ, λεμὰ σαβαχθάνι;” Ὅ ἐστιν μεθερμηνευόμενον, “Ὁ ˚Θεός μου, ὁ ˚Θεός μου, εἰς τί ἐγκατέλιπές με;”
41015035 Καί τινες τῶν παρεστηκότων, ἀκούσαντες ἔλεγον, “Ἴδε, Ἠλίαν φωνεῖ.”
41015036 Δραμὼν δέ τις, καὶ γεμίσας σπόγγον ὄξους περιθεὶς καλάμῳ, ἐπότιζεν αὐτόν λέγων, “Ἄφετε, ἴδωμεν εἰ ἔρχεται Ἠλίας καθελεῖν αὐτόν.”
41015037 Ὁ δὲ ˚Ἰησοῦς ἀφεὶς φωνὴν μεγάλην, ἐξέπνευσεν.
41015038 Καὶ τὸ καταπέτασμα τοῦ ναοῦ ἐσχίσθη εἰς δύο, ἀπʼ ἄνωθεν ἕως κάτω.
41015039 Ἰδὼν δὲ ὁ κεντυρίων ὁ παρεστηκὼς ἐξ ἐναντίας αὐτοῦ ὅτι οὕτως ἐξέπνευσεν εἶπεν, “Ἀληθῶς οὗτος ὁ ἄνθρωπος Υἱὸς ˚Θεοῦ ἦν!”
41015040 Ἦσαν δὲ καὶ γυναῖκες ἀπὸ μακρόθεν θεωροῦσαι, ἐν αἷς καὶ Μαριὰμ ἡ Μαγδαληνὴ, καὶ Μαρία ἡ Ἰακώβου τοῦ μικροῦ καὶ Ἰωσῆτος μήτηρ, καὶ Σαλώμη,
41015041 αἳ ὅτε ἦν ἐν τῇ Γαλιλαίᾳ ἠκολούθουν αὐτῷ καὶ διηκόνουν αὐτῷ, καὶ ἄλλαι πολλαὶ αἱ συναναβᾶσαι αὐτῷ εἰς Ἱεροσόλυμα.
41015042 Καὶ ἤδη ὀψίας γενομένης, ἐπεὶ ἦν παρασκευή, ὅ ἐστιν προσάββατον,
41015043 ἐλθὼν Ἰωσὴφ ὁ ἀπὸ Ἁριμαθαίας, εὐσχήμων βουλευτής, ὃς καὶ αὐτὸς ἦν προσδεχόμενος τὴν Βασιλείαν τοῦ ˚Θεοῦ, τολμήσας, εἰσῆλθεν πρὸς τὸν Πιλᾶτον καὶ ᾐτήσατο τὸ σῶμα τοῦ ˚Ἰησοῦ.
41015044 Ὁ δὲ Πιλᾶτος ἐθαύμασεν εἰ ἤδη τέθνηκεν, καὶ προσκαλεσάμενος τὸν κεντυρίωνα, ἐπηρώτησεν αὐτὸν εἰ ἤδη ἀπέθανεν.
41015045 Καὶ γνοὺς ἀπὸ τοῦ κεντυρίωνος, ἐδωρήσατο τὸ πτῶμα τῷ Ἰωσήφ.
41015046 Καὶ ἀγοράσας σινδόνα, καθελὼν αὐτὸν, ἐνείλησεν τῇ σινδόνι, καὶ ἔθηκεν αὐτὸν ἐν μνήματι ὃ ἦν λελατομημένον ἐκ πέτρας, καὶ προσεκύλισεν λίθον ἐπὶ τὴν θύραν τοῦ μνημείου.
41015047 Ἡ δὲ Μαρία ἡ Μαγδαληνὴ, καὶ Μαρία ἡ Ἰωσῆτος, ἐθεώρουν ποῦ τέθειται.
41016001 Καὶ διαγενομένου τοῦ Σαββάτου, ἡ Μαρία ἡ Μαγδαληνὴ, καὶ Μαρία ἡ τοῦ Ἰακώβου, καὶ Σαλώμη, ἠγόρασαν ἀρώματα, ἵνα ἐλθοῦσαι ἀλείψωσιν αὐτόν.
41016002 Καὶ λίαν πρωῒ μιᾷ τῶν σαββάτων, ἔρχονται ἐπὶ τὸ μνημεῖον, ἀνατείλαντος τοῦ ἡλίου.
41016003 Καὶ ἔλεγον πρὸς ἑαυτάς, “Τίς ἀποκυλίσει ἡμῖν τὸν λίθον ἐκ τῆς θύρας τοῦ μνημείου;”
41016004 Καὶ ἀναβλέψασαι θεωροῦσιν ὅτι ἀποκεκύλισται ὁ λίθος, ἦν γὰρ μέγας σφόδρα.
41016005 Καὶ εἰσελθοῦσαι εἰς τὸ μνημεῖον, εἶδον νεανίσκον καθήμενον ἐν τοῖς δεξιοῖς, περιβεβλημένον στολὴν λευκήν, καὶ ἐξεθαμβήθησαν.
41016006 Ὁ δὲ λέγει αὐταῖς, “Μὴ ἐκθαμβεῖσθε. ˚Ἰησοῦν ζητεῖτε τὸν Ναζαρηνὸν, τὸν ἐσταυρωμένον. Ἠγέρθη! Οὐκ ἔστιν ὧδε· ἴδε, ὁ τόπος ὅπου ἔθηκαν αὐτόν.
41016007 Ἀλλὰ ὑπάγετε, εἴπατε τοῖς μαθηταῖς αὐτοῦ καὶ τῷ Πέτρῳ, ὅτι ‘Προάγει ὑμᾶς εἰς τὴν Γαλιλαίαν· ἐκεῖ αὐτὸν ὄψεσθε, καθὼς εἶπεν ὑμῖν.’”
41016008 Καὶ ἐξελθοῦσαι, ἔφυγον ἀπὸ τοῦ μνημείου, εἶχεν γὰρ αὐτὰς τρόμος καὶ ἔκστασις, καὶ οὐδενὶ οὐδὲν εἶπον, ἐφοβοῦντο γάρ. [This Version does not contain the longer ending of Mark.]
42001001 Ἐπειδήπερ πολλοὶ ἐπεχείρησαν ἀνατάξασθαι διήγησιν, περὶ τῶν πεπληροφορημένων ἐν ἡμῖν πραγμάτων,
42001002 καθὼς παρέδοσαν ἡμῖν οἱ ἀπʼ ἀρχῆς, αὐτόπται καὶ ὑπηρέται γενόμενοι τοῦ λόγου,
42001003 ἔδοξε κἀμοὶ παρηκολουθηκότι ἄνωθεν πᾶσιν ἀκριβῶς, καθεξῆς σοι γράψαι, κράτιστε Θεόφιλε,
42001004 ἵνα ἐπιγνῷς περὶ ὧν κατηχήθης λόγων τὴν ἀσφάλειαν.
42001005 Ἐγένετο ἐν ταῖς ἡμέραις Ἡρῴδου βασιλέως τῆς Ἰουδαίας, ἱερεύς τις ὀνόματι Ζαχαρίας ἐξ ἐφημερίας Ἀβιά, καὶ γυνὴ αὐτῷ ἐκ τῶν θυγατέρων Ἀαρών, καὶ τὸ ὄνομα αὐτῆς, Ἐλισάβετ.
42001006 Ἦσαν δὲ δίκαιοι ἀμφότεροι ἐναντίον τοῦ ˚Θεοῦ, πορευόμενοι ἐν πάσαις ταῖς ἐντολαῖς καὶ δικαιώμασιν τοῦ ˚Κυρίου ἄμεμπτοι.
42001007 Καὶ οὐκ ἦν αὐτοῖς τέκνον, καθότι ἡ Ἐλισάβετ ἦν στεῖρα, καὶ ἀμφότεροι προβεβηκότες ἐν ταῖς ἡμέραις αὐτῶν ἦσαν.
42001008 Ἐγένετο δὲ ἐν τῷ ἱερατεύειν αὐτὸν, ἐν τῇ τάξει τῆς ἐφημερίας αὐτοῦ ἔναντι τοῦ ˚Θεοῦ,
42001009 κατὰ τὸ ἔθος τῆς ἱερατείας, ἔλαχε τοῦ θυμιᾶσαι, εἰσελθὼν εἰς τὸν ναὸν τοῦ ˚Κυρίου.
42001010 Καὶ πᾶν τὸ πλῆθος ἦν τοῦ λαοῦ προσευχόμενον ἔξω τῇ ὥρᾳ τοῦ θυμιάματος.
42001011 Ὤφθη δὲ αὐτῷ ἄγγελος ˚Κυρίου, ἑστὼς ἐκ δεξιῶν τοῦ θυσιαστηρίου τοῦ θυμιάματος.
42001012 Καὶ ἐταράχθη Ζαχαρίας ἰδών, καὶ φόβος ἐπέπεσεν ἐπʼ αὐτόν.
42001013 Εἶπεν δὲ πρὸς αὐτὸν ὁ ἄγγελος, “Μὴ φοβοῦ, Ζαχαρία, διότι εἰσηκούσθη ἡ δέησίς σου, καὶ ἡ γυνή σου Ἐλισάβετ γεννήσει υἱόν σοι, καὶ καλέσεις τὸ ὄνομα αὐτοῦ Ἰωάννην.
42001014 Καὶ ἔσται χαρά σοι καὶ ἀγαλλίασις, καὶ πολλοὶ ἐπὶ τῇ γενέσει αὐτοῦ χαρήσονται.
42001015 Ἔσται γὰρ μέγας ἐνώπιον τοῦ ˚Κυρίου, καὶ οἶνον καὶ σίκερα οὐ μὴ πίῃ, καὶ ˚Πνεύματος Ἁγίου πλησθήσεται ἔτι ἐκ κοιλίας μητρὸς αὐτοῦ.
42001016 Καὶ πολλοὺς τῶν υἱῶν Ἰσραὴλ ἐπιστρέψει ἐπὶ ˚Κύριον, τὸν ˚Θεὸν αὐτῶν.
42001017 Καὶ αὐτὸς προελεύσεται ἐνώπιον αὐτοῦ ἐν πνεύματι καὶ δυνάμει Ἠλίου, ἐπιστρέψαι καρδίας πατέρων ἐπὶ τέκνα, καὶ ἀπειθεῖς ἐν φρονήσει δικαίων, ἑτοιμάσαι ˚Κυρίῳ λαὸν κατεσκευασμένον.”
42001018 Καὶ εἶπεν Ζαχαρίας πρὸς τὸν ἄγγελον, “Κατὰ τί γνώσομαι τοῦτο; Ἐγὼ γάρ εἰμι πρεσβύτης, καὶ ἡ γυνή μου προβεβηκυῖα ἐν ταῖς ἡμέραις αὐτῆς.”
42001019 Καὶ ἀποκριθεὶς ὁ ἄγγελος εἶπεν αὐτῷ, “Ἐγώ εἰμι Γαβριὴλ, ὁ παρεστηκὼς ἐνώπιον τοῦ ˚Θεοῦ, καὶ ἀπεστάλην λαλῆσαι πρὸς σὲ, καὶ εὐαγγελίσασθαί σοι ταῦτα.
42001020 Καὶ ἰδοὺ, ἔσῃ σιωπῶν καὶ μὴ δυνάμενος λαλῆσαι, ἄχρι ἧς ἡμέρας γένηται ταῦτα, ἀνθʼ ὧν οὐκ ἐπίστευσας τοῖς λόγοις μου, οἵτινες πληρωθήσονται εἰς τὸν καιρὸν αὐτῶν.”
42001021 Καὶ ἦν ὁ λαὸς προσδοκῶν τὸν Ζαχαρίαν, καὶ ἐθαύμαζον ἐν τῷ χρονίζειν αὐτόν ἐν τῷ ναῷ.
42001022 Ἐξελθὼν δὲ οὐκ ἐδύνατο λαλῆσαι αὐτοῖς, καὶ ἐπέγνωσαν ὅτι ὀπτασίαν ἑώρακεν ἐν τῷ ναῷ. Καὶ αὐτὸς ἦν διανεύων αὐτοῖς, καὶ διέμενεν κωφός.
42001023 Καὶ ἐγένετο ὡς ἐπλήσθησαν αἱ ἡμέραι τῆς λειτουργίας αὐτοῦ, ἀπῆλθεν εἰς τὸν οἶκον αὐτοῦ.
42001024 Μετὰ δὲ ταύτας τὰς ἡμέρας συνέλαβεν Ἐλισάβετ ἡ γυνὴ αὐτοῦ, καὶ περιέκρυβεν ἑαυτὴν μῆνας πέντε λέγουσα,
42001025 ὅτι “Οὕτως μοι πεποίηκεν ˚Κύριος, ἐν ἡμέραις αἷς ἐπεῖδεν, ἀφελεῖν ὄνειδός μου ἐν ἀνθρώποις.”
42001026 Ἐν δὲ τῷ μηνὶ τῷ ἕκτῳ, ἀπεστάλη ὁ ἄγγελος Γαβριὴλ ἀπὸ τοῦ ˚Θεοῦ εἰς πόλιν τῆς Γαλιλαίας, ᾗ ὄνομα Ναζαρὲτ,
42001027 πρὸς παρθένον ἐμνηστευμένην ἀνδρὶ, ᾧ ὄνομα Ἰωσὴφ, ἐξ οἴκου Δαυὶδ, καὶ τὸ ὄνομα τῆς παρθένου Μαριάμ.
42001028 Καὶ εἰσελθὼν ὁ ἄγγελος πρὸς αὐτὴν, εἶπεν, “Χαῖρε, κεχαριτωμένη! Ὁ ˚Κύριος μετὰ σοῦ.”
42001029 Ἡ δὲ ἐπὶ τῷ λόγῳ διεταράχθη, καὶ διελογίζετο ποταπὸς εἴη ὁ ἀσπασμὸς οὗτος.
42001030 Καὶ εἶπεν ὁ ἄγγελος αὐτῇ, “Μὴ φοβοῦ, Μαριάμ, εὗρες γὰρ χάριν παρὰ τῷ ˚Θεῷ.
42001031 Καὶ ἰδοὺ, συλλήμψῃ ἐν γαστρὶ, καὶ τέξῃ υἱόν, καὶ καλέσεις τὸ ὄνομα αὐτοῦ ˚Ἰησοῦν.
42001032 Οὗτος ἔσται μέγας, καὶ Υἱὸς Ὑψίστου κληθήσεται, καὶ δώσει αὐτῷ ˚Κύριος ὁ ˚Θεὸς τὸν θρόνον Δαυὶδ, τοῦ πατρὸς αὐτοῦ,
42001033 καὶ βασιλεύσει ἐπὶ τὸν οἶκον Ἰακὼβ, εἰς τοὺς αἰῶνας, καὶ τῆς βασιλείας αὐτοῦ, οὐκ ἔσται τέλος.”
42001034 Εἶπεν δὲ Μαριὰμ πρὸς τὸν ἄγγελον, “Πῶς ἔσται τοῦτο, ἐπεὶ ἄνδρα οὐ γινώσκω;”
42001035 Καὶ ἀποκριθεὶς ὁ ἄγγελος εἶπεν αὐτῇ, “˚Πνεῦμα Ἅγιον ἐπελεύσεται ἐπὶ σέ, καὶ δύναμις Ὑψίστου ἐπισκιάσει σοι· διὸ καὶ τὸ γεννώμενον Ἅγιον κληθήσεται, Υἱὸς ˚Θεοῦ.
42001036 Καὶ ἰδοὺ, Ἐλισάβετ, ἡ συγγενίς σου, καὶ αὐτὴ συνείληφεν υἱὸν ἐν γήρει αὐτῆς, καὶ οὗτος μὴν ἕκτος ἐστὶν αὐτῇ τῇ καλουμένῃ στείρᾳ.
42001037 Ὅτι οὐκ ἀδυνατήσει παρὰ τοῦ ˚Θεοῦ πᾶν ῥῆμα.”
42001038 Εἶπεν δὲ Μαριάμ, “Ἰδοὺ, ἡ δούλη ˚Κυρίου· γένοιτό μοι κατὰ τὸ ῥῆμά σου.” Καὶ ἀπῆλθεν ἀπʼ αὐτῆς ὁ ἄγγελος.
42001039 Ἀναστᾶσα δὲ Μαριὰμ ἐν ταῖς ἡμέραις ταύταις, ἐπορεύθη εἰς τὴν ὀρεινὴν μετὰ σπουδῆς, εἰς πόλιν Ἰούδα,
42001040 καὶ εἰσῆλθεν εἰς τὸν οἶκον Ζαχαρίου, καὶ ἠσπάσατο τὴν Ἐλισάβετ.
42001041 Καὶ ἐγένετο, ὡς ἤκουσεν τὸν ἀσπασμὸν τῆς Μαρίας ἡ Ἐλισάβετ, ἐσκίρτησεν τὸ βρέφος ἐν τῇ κοιλίᾳ αὐτῆς, καὶ ἐπλήσθη ˚Πνεύματος Ἁγίου ἡ Ἐλισάβετ,
42001042 καὶ ἀνεφώνησεν φωνῇ μεγάλῃ καὶ εἶπεν, “Εὐλογημένη σὺ ἐν γυναιξίν, καὶ εὐλογημένος ὁ καρπὸς τῆς κοιλίας σου.
42001043 Καὶ πόθεν μοι τοῦτο, ἵνα ἔλθῃ ἡ μήτηρ τοῦ ˚Κυρίου μου πρὸς ἐμέ;
42001044 Ἰδοὺ γὰρ, ὡς ἐγένετο ἡ φωνὴ τοῦ ἀσπασμοῦ σου εἰς τὰ ὦτά μου, ἐσκίρτησεν ἐν ἀγαλλιάσει τὸ βρέφος ἐν τῇ κοιλίᾳ μου.
42001045 Καὶ μακαρία ἡ πιστεύσασα ὅτι ἔσται τελείωσις τοῖς λελαλημένοις αὐτῇ παρὰ ˚Κυρίου.”
42001046 Καὶ εἶπεν Μαριάμ: “Μεγαλύνει ἡ ψυχή μου τὸν ˚Κύριον,
42001047 καὶ ἠγαλλίασεν τὸ πνεῦμά μου ἐπὶ τῷ ˚Θεῷ, τῷ Σωτῆρί μου.
42001048 Ὅτι ἐπέβλεψεν ἐπὶ τὴν ταπείνωσιν τῆς δούλης αὐτοῦ. Ἰδοὺ γὰρ, ἀπὸ τοῦ νῦν μακαριοῦσίν με πᾶσαι αἱ γενεαί.
42001049 Ὅτι ἐποίησέν μοι μεγάλα ὁ δυνατός, καὶ ἅγιον τὸ ὄνομα αὐτοῦ.
42001050 ‘Καὶ τὸ ἔλεος αὐτοῦ εἰς γενεὰς καὶ γενεὰς, τοῖς φοβουμένοις αὐτόν.’
42001051 Ἐποίησεν κράτος ἐν βραχίονι αὐτοῦ, διεσκόρπισεν ὑπερηφάνους διανοίᾳ καρδίας αὐτῶν.
42001052 Καθεῖλεν δυνάστας ἀπὸ θρόνων, καὶ ὕψωσεν ταπεινούς.
42001053 ‘Πεινῶντας ἐνέπλησεν ἀγαθῶν’, καὶ πλουτοῦντας ἐξαπέστειλεν κενούς.
42001054 Ἀντελάβετο Ἰσραὴλ παιδὸς αὐτοῦ, μνησθῆναι ἐλέους,
42001055 καθὼς ἐλάλησεν πρὸς τοὺς πατέρας ἡμῶν, τῷ Ἀβραὰμ καὶ τῷ σπέρματι αὐτοῦ, εἰς τὸν αἰῶνα.”
42001056 Ἔμεινεν δὲ Μαριὰμ σὺν αὐτῇ ὡς μῆνας τρεῖς, καὶ ὑπέστρεψεν εἰς τὸν οἶκον αὐτῆς.
42001057 Τῇ δὲ Ἐλισάβετ ἐπλήσθη ὁ χρόνος, τοῦ τεκεῖν αὐτήν, καὶ ἐγέννησεν υἱόν.
42001058 Καὶ ἤκουσαν οἱ περίοικοι καὶ οἱ συγγενεῖς αὐτῆς ὅτι ἐμεγάλυνεν ˚Κύριος τὸ ἔλεος αὐτοῦ μετʼ αὐτῆς, καὶ συνέχαιρον αὐτῇ.
42001059 Καὶ ἐγένετο ἐν τῇ ἡμέρᾳ τῇ ὀγδόῃ, ἦλθον περιτεμεῖν τὸ παιδίον, καὶ ἐκάλουν αὐτὸ ἐπὶ τῷ ὀνόματι τοῦ πατρὸς αὐτοῦ, Ζαχαρίαν.
42001060 Καὶ ἀποκριθεῖσα ἡ μήτηρ αὐτοῦ εἶπεν, “Οὐχί, ἀλλὰ κληθήσεται Ἰωάννης.”
42001061 Καὶ εἶπον πρὸς αὐτὴν, ὅτι “Οὐδείς ἐστιν ἐκ τῆς συγγενείας σου, ὃς καλεῖται τῷ ὀνόματι τούτῳ.”
42001062 Ἐνένευον δὲ τῷ πατρὶ αὐτοῦ, τὸ τί ἂν θέλοι καλεῖσθαι αὐτό.
42001063 Καὶ αἰτήσας πινακίδιον, ἔγραψεν λέγων, “Ἰωάννης ἐστὶν ὄνομα αὐτοῦ.” Καὶ ἐθαύμασαν πάντες.
42001064 Ἀνεῴχθη δὲ τὸ στόμα αὐτοῦ παραχρῆμα, καὶ ἡ γλῶσσα αὐτοῦ, καὶ ἐλάλει εὐλογῶν τὸν ˚Θεόν.
42001065 Καὶ ἐγένετο ἐπὶ πάντας φόβος, τοὺς περιοικοῦντας αὐτούς, καὶ ἐν ὅλῃ τῇ ὀρεινῇ τῆς Ἰουδαίας διελαλεῖτο πάντα τὰ ῥήματα ταῦτα.
42001066 Καὶ ἔθεντο πάντες οἱ ἀκούσαντες ἐν τῇ καρδίᾳ αὐτῶν, λέγοντες, “Τί ἄρα τὸ παιδίον τοῦτο ἔσται;” Καὶ γὰρ χεὶρ ˚Κυρίου ἦν μετʼ αὐτοῦ.
42001067 Καὶ Ζαχαρίας ὁ πατὴρ αὐτοῦ, ἐπλήσθη ˚Πνεύματος Ἁγίου καὶ ἐπροφήτευσεν λέγων,
42001068 “Εὐλογητὸς ˚Κύριος ὁ ˚Θεὸς τοῦ Ἰσραήλ, ὅτι ἐπεσκέψατο καὶ ἐποίησεν λύτρωσιν τῷ λαῷ αὐτοῦ,
42001069 καὶ ἤγειρεν κέρας σωτηρίας ἡμῖν, ἐν οἴκῳ Δαυὶδ, παιδὸς αὐτοῦ,
42001070 ( καθὼς ἐλάλησεν διὰ στόματος τῶν ἁγίων ἀπʼ αἰῶνος προφητῶν αὐτοῦ ),
42001071 σωτηρίαν ‘ἐξ ἐχθρῶν ἡμῶν, καὶ ἐκ χειρὸς πάντων τῶν μισούντων ἡμᾶς’,
42001072 ποιῆσαι ἔλεος μετὰ τῶν πατέρων ἡμῶν, καὶ μνησθῆναι διαθήκης ἁγίας αὐτοῦ,
42001073 ὅρκον ὃν ὤμοσεν πρὸς Ἀβραὰμ, τὸν πατέρα ἡμῶν, τοῦ δοῦναι ἡμῖν
42001074 ἀφόβως ἐκ χειρὸς ἐχθρῶν ῥυσθέντας, λατρεύειν αὐτῷ
42001075 ἐν ὁσιότητι καὶ δικαιοσύνῃ ἐνώπιον αὐτοῦ, πάσαις ταῖς ἡμέραις ἡμῶν.
42001076 Καὶ σὺ δέ, παιδίον, προφήτης Ὑψίστου κληθήσῃ, ‘προπορεύσῃ γὰρ ἐνώπιον ˚Κυρίου ἑτοιμάσαι ὁδοὺς αὐτοῦ’,
42001077 τοῦ δοῦναι γνῶσιν σωτηρίας τῷ λαῷ αὐτοῦ, ἐν ἀφέσει ἁμαρτιῶν αὐτῶν,
42001078 διὰ σπλάγχνα ἐλέους ˚Θεοῦ ἡμῶν, ἐν οἷς ἐπισκέψεται ἡμᾶς ἀνατολὴ ἐξ ὕψους,
42001079 ‘ἐπιφᾶναι τοῖς ἐν σκότει καὶ σκιᾷ θανάτου καθημένοις’, τοῦ κατευθῦναι τοὺς πόδας ἡμῶν εἰς ὁδὸν εἰρήνης.”
42001080 Τὸ δὲ παιδίον ηὔξανε καὶ ἐκραταιοῦτο πνεύματι, καὶ ἦν ἐν ταῖς ἐρήμοις ἕως ἡμέρας ἀναδείξεως αὐτοῦ πρὸς τὸν Ἰσραήλ.
42002001 Ἐγένετο δὲ ἐν ταῖς ἡμέραις ἐκείναις, ἐξῆλθεν δόγμα παρὰ Καίσαρος Αὐγούστου, ἀπογράφεσθαι πᾶσαν τὴν οἰκουμένην.
42002002 ( Αὕτη ἀπογραφὴ πρώτη ἐγένετο ἡγεμονεύοντος τῆς Συρίας Κυρηνίου. )
42002003 Καὶ ἐπορεύοντο πάντες ἀπογράφεσθαι, ἕκαστος εἰς τὴν ἑαυτοῦ πόλιν.
42002004 Ἀνέβη δὲ καὶ Ἰωσὴφ, ἀπὸ τῆς Γαλιλαίας ἐκ πόλεως Ναζαρὲθ, εἰς τὴν Ἰουδαίαν εἰς πόλιν Δαυὶδ, ἥτις καλεῖται Βηθλέεμ, διὰ τὸ εἶναι αὐτὸν ἐξ οἴκου καὶ πατριᾶς Δαυίδ,
42002005 ἀπογράψασθαι σὺν Μαριὰμ, τῇ ἐμνηστευμένῃ αὐτῷ, οὔσῃ ἐγκύῳ.
42002006 Ἐγένετο δὲ ἐν τῷ εἶναι αὐτοὺς ἐκεῖ, ἐπλήσθησαν αἱ ἡμέραι τοῦ τεκεῖν αὐτήν.
42002007 Καὶ ἔτεκεν τὸν υἱὸν αὐτῆς τὸν πρωτότοκον, καὶ ἐσπαργάνωσεν αὐτὸν, καὶ ἀνέκλινεν αὐτὸν ἐν φάτνῃ, διότι οὐκ ἦν αὐτοῖς τόπος ἐν τῷ καταλύματι.
42002008 Καὶ ποιμένες ἦσαν ἐν τῇ χώρᾳ τῇ αὐτῇ, ἀγραυλοῦντες καὶ φυλάσσοντες φυλακὰς τῆς νυκτὸς ἐπὶ τὴν ποίμνην αὐτῶν.
42002009 Καὶ ἄγγελος ˚Κυρίου ἐπέστη αὐτοῖς, καὶ δόξα ˚Κυρίου περιέλαμψεν αὐτούς, καὶ ἐφοβήθησαν φόβον μέγαν
42002010 Καὶ εἶπεν αὐτοῖς ὁ ἄγγελος, “Μὴ φοβεῖσθε, ἰδοὺ γὰρ, εὐαγγελίζομαι ὑμῖν χαρὰν μεγάλην, ἥτις ἔσται παντὶ τῷ λαῷ,
42002011 ὅτι ἐτέχθη ὑμῖν σήμερον Σωτὴρ, ὅς ἐστιν ˚Χριστὸς, ˚Κύριος, ἐν πόλει Δαυίδ.
42002012 Καὶ τοῦτο ὑμῖν τὸ σημεῖον: εὑρήσετε βρέφος ἐσπαργανωμένον, καὶ κείμενον ἐν φάτνῃ.”
42002013 Καὶ ἐξαίφνης ἐγένετο σὺν τῷ ἀγγέλῳ πλῆθος στρατιᾶς οὐρανίου, αἰνούντων τὸν ˚Θεὸν καὶ λεγόντων,
42002014 “Δόξα ἐν ὑψίστοις ˚Θεῷ, καὶ ἐπὶ γῆς εἰρήνη ἐν ἀνθρώποις εὐδοκίας!”
42002015 Καὶ ἐγένετο ὡς ἀπῆλθον ἀπʼ αὐτῶν εἰς τὸν οὐρανὸν οἱ ἄγγελοι, οἱ ποιμένες ἐλάλουν πρὸς ἀλλήλους, “Διέλθωμεν δὴ ἕως Βηθλέεμ, καὶ ἴδωμεν τὸ ῥῆμα τοῦτο τὸ γεγονὸς, ὃ ὁ ˚Κύριος ἐγνώρισεν ἡμῖν.”
42002016 Καὶ ἦλθον σπεύσαντες, καὶ ἀνεῦραν τήν τε Μαριὰμ καὶ τὸν Ἰωσὴφ, καὶ τὸ βρέφος, κείμενον ἐν τῇ φάτνῃ.
42002017 Ἰδόντες δὲ, ἐγνώρισαν περὶ τοῦ ῥήματος τοῦ λαληθέντος αὐτοῖς, περὶ τοῦ παιδίου τούτου.
42002018 Καὶ πάντες οἱ ἀκούσαντες, ἐθαύμασαν περὶ τῶν λαληθέντων ὑπὸ τῶν ποιμένων πρὸς αὐτούς.
42002019 Ἡ δὲ Μαρία πάντα συνετήρει τὰ ῥήματα ταῦτα, συμβάλλουσα ἐν τῇ καρδίᾳ αὐτῆς.
42002020 Καὶ ὑπέστρεψαν οἱ ποιμένες, δοξάζοντες καὶ αἰνοῦντες τὸν ˚Θεὸν ἐπὶ πᾶσιν οἷς ἤκουσαν καὶ εἶδον, καθὼς ἐλαλήθη πρὸς αὐτούς.
42002021 Καὶ ὅτε ἐπλήσθησαν ἡμέραι ὀκτὼ τοῦ περιτεμεῖν αὐτόν, καὶ ἐκλήθη τὸ ὄνομα αὐτοῦ ˚Ἰησοῦς, τὸ κληθὲν ὑπὸ τοῦ ἀγγέλου πρὸ τοῦ συλλημφθῆναι αὐτὸν ἐν τῇ κοιλίᾳ.
42002022 Καὶ ὅτε ἐπλήσθησαν αἱ ἡμέραι τοῦ καθαρισμοῦ αὐτῶν κατὰ τὸν νόμον Μωϋσέως, ἀνήγαγον αὐτὸν εἰς Ἱεροσόλυμα παραστῆσαι τῷ ˚Κυρίῳ,
42002023 καθὼς γέγραπται ἐν νόμῳ ˚Κυρίου, ὅτι “Πᾶν ἄρσεν διανοῖγον μήτραν, ἅγιον τῷ ˚Κυρίῳ κληθήσεται”,
42002024 καὶ τοῦ δοῦναι θυσίαν, κατὰ τὸ εἰρημένον ἐν τῷ νόμῳ ˚Κυρίου, “Ζεῦγος τρυγόνων ἢ δύο νοσσοὺς περιστερῶν.”
42002025 Καὶ ἰδοὺ, ἄνθρωπος ἦν ἐν Ἰερουσαλὴμ, ᾧ ὄνομα Συμεών, καὶ ὁ ἄνθρωπος οὗτος δίκαιος καὶ εὐλαβής, προσδεχόμενος παράκλησιν τοῦ Ἰσραήλ, καὶ ˚Πνεῦμα ἦν Ἅγιον ἐπʼ αὐτόν.
42002026 Καὶ ἦν αὐτῷ κεχρηματισμένον ὑπὸ τοῦ ˚Πνεύματος τοῦ Ἁγίου, μὴ ἰδεῖν θάνατον πρὶν ἂν ἴδῃ τὸν ˚Χριστὸν ˚Κυρίου.
42002027 Καὶ ἦλθεν ἐν τῷ ˚Πνεύματι εἰς τὸ ἱερόν· καὶ ἐν τῷ εἰσαγαγεῖν τοὺς γονεῖς, τὸ παιδίον ˚Ἰησοῦν, τοῦ ποιῆσαι αὐτοὺς κατὰ τὸ εἰθισμένον τοῦ νόμου περὶ αὐτοῦ,
42002028 καὶ αὐτὸς ἐδέξατο αὐτὸ εἰς τὰς ἀγκάλας, καὶ εὐλόγησεν τὸν ˚Θεὸν καὶ εἶπεν,
42002029 “Νῦν ἀπολύεις τὸν δοῦλόν σου, Δέσποτα, κατὰ τὸ ῥῆμά σου ἐν εἰρήνῃ·
42002030 ὅτι εἶδον οἱ ὀφθαλμοί μου τὸ σωτήριόν σου,
42002031 ὃ ἡτοίμασας κατὰ πρόσωπον πάντων τῶν λαῶν,
42002032 ‘φῶς εἰς ἀποκάλυψιν ἐθνῶν’ καὶ δόξαν λαοῦ σου, Ἰσραήλ.”
42002033 Καὶ ἦν ὁ πατὴρ αὐτοῦ καὶ ἡ μήτηρ θαυμάζοντες ἐπὶ τοῖς λαλουμένοις περὶ αὐτοῦ.
42002034 Καὶ εὐλόγησεν αὐτοὺς Συμεὼν, καὶ εἶπεν πρὸς Μαριὰμ τὴν μητέρα αὐτοῦ, “Ἰδοὺ, οὗτος κεῖται εἰς πτῶσιν καὶ ἀνάστασιν πολλῶν ἐν τῷ Ἰσραὴλ, καὶ εἰς σημεῖον ἀντιλεγόμενον,
42002035 καὶ σοῦ αὐτῆς τὴν ψυχὴν διελεύσεται ῥομφαία, ὅπως ἂν ἀποκαλυφθῶσιν ἐκ πολλῶν καρδιῶν διαλογισμοί.”
42002036 Καὶ ἦν Ἅννα προφῆτις, θυγάτηρ Φανουήλ, ἐκ φυλῆς Ἀσήρ· αὕτη προβεβηκυῖα ἐν ἡμέραις πολλαῖς, ζήσασα μετὰ ἀνδρὸς ἔτη ἑπτὰ ἀπὸ τῆς παρθενίας αὐτῆς,
42002037 καὶ αὐτὴ χήρα ἕως ἐτῶν ὀγδοήκοντα τεσσάρων, ἣ οὐκ ἀφίστατο τοῦ ἱεροῦ, νηστείαις καὶ δεήσεσιν λατρεύουσα νύκτα καὶ ἡμέραν.
42002038 Καὶ αὐτῇ τῇ ὥρᾳ ἐπιστᾶσα, ἀνθωμολογεῖτο τῷ ˚Θεῷ, καὶ ἐλάλει περὶ αὐτοῦ, πᾶσιν τοῖς προσδεχομένοις λύτρωσιν Ἰερουσαλήμ.
42002039 Καὶ ὡς ἐτέλεσαν πάντα τὰ κατὰ τὸν νόμον ˚Κυρίου, ἐπέστρεψαν εἰς τὴν Γαλιλαίαν, εἰς πόλιν ἑαυτῶν Ναζαρέτ.
42002040 Τὸ δὲ παιδίον ηὔξανεν καὶ ἐκραταιοῦτο, πληρούμενον σοφίᾳ, καὶ χάρις ˚Θεοῦ ἦν ἐπʼ αὐτό.
42002041 Καὶ ἐπορεύοντο οἱ γονεῖς αὐτοῦ κατʼ ἔτος εἰς Ἰερουσαλὴμ τῇ ἑορτῇ τοῦ Πάσχα.
42002042 Καὶ ὅτε ἐγένετο ἐτῶν δώδεκα, ἀναβαινόντων αὐτῶν κατὰ τὸ ἔθος τῆς ἑορτῆς,
42002043 καὶ τελειωσάντων τὰς ἡμέρας, ἐν τῷ ὑποστρέφειν αὐτοὺς, ὑπέμεινεν ˚Ἰησοῦς, ὁ παῖς ἐν Ἰερουσαλήμ, καὶ οὐκ ἔγνωσαν οἱ γονεῖς αὐτοῦ.
42002044 Νομίσαντες δὲ αὐτὸν εἶναι ἐν τῇ συνοδίᾳ, ἦλθον ἡμέρας ὁδὸν καὶ ἀνεζήτουν αὐτὸν ἐν τοῖς συγγενεῦσιν καὶ τοῖς γνωστοῖς,
42002045 καὶ μὴ εὑρόντες, ὑπέστρεψαν εἰς Ἰερουσαλὴμ, ἀναζητοῦντες αὐτόν.
42002046 Καὶ ἐγένετο μετὰ ἡμέρας τρεῖς, εὗρον αὐτὸν ἐν τῷ ἱερῷ, καθεζόμενον ἐν μέσῳ τῶν διδασκάλων, καὶ ἀκούοντα αὐτῶν καὶ ἐπερωτῶντα αὐτούς.
42002047 Ἐξίσταντο δὲ πάντες οἱ ἀκούοντες αὐτοῦ ἐπὶ τῇ συνέσει καὶ ταῖς ἀποκρίσεσιν αὐτοῦ.
42002048 Καὶ ἰδόντες αὐτὸν, ἐξεπλάγησαν καὶ εἶπεν πρὸς αὐτὸν ἡ μήτηρ αὐτοῦ, “Τέκνον, τί ἐποίησας ἡμῖν οὕτως; Ἰδοὺ, ὁ πατήρ σου κἀγὼ, ὀδυνώμενοι ἐζητοῦμέν σε.”
42002049 Καὶ εἶπεν πρὸς αὐτούς, “Τί ὅτι ἐζητεῖτέ με; Οὐκ ᾔδειτε ὅτι ἐν τοῖς τοῦ πατρός μου δεῖ εἶναί με;”
42002050 Καὶ αὐτοὶ οὐ συνῆκαν τὸ ῥῆμα ὃ ἐλάλησεν αὐτοῖς.
42002051 Καὶ κατέβη μετʼ αὐτῶν καὶ ἦλθεν εἰς Ναζαρέτ, καὶ ἦν ὑποτασσόμενος αὐτοῖς. Καὶ ἡ μήτηρ αὐτοῦ διετήρει πάντα τὰ ῥήματα ἐν τῇ καρδίᾳ αὐτῆς.
42002052 Καὶ ˚Ἰησοῦς προέκοπτεν τῇ σοφίᾳ, καὶ ἡλικίᾳ, καὶ χάριτι παρὰ ˚Θεῷ καὶ ἀνθρώποις.
42003001 Ἐν ἔτει δὲ πεντεκαιδεκάτῳ τῆς ἡγεμονίας Τιβερίου Καίσαρος, ἡγεμονεύοντος Ποντίου Πιλάτου τῆς Ἰουδαίας, καὶ τετρααρχοῦντος τῆς Γαλιλαίας Ἡρῴδου, Φιλίππου δὲ τοῦ ἀδελφοῦ αὐτοῦ τετρααρχοῦντος τῆς Ἰτουραίας καὶ Τραχωνίτιδος χώρας, καὶ Λυσανίου τῆς Ἀβιληνῆς τετρααρχοῦντος,
42003002 ἐπὶ ἀρχιερέως Ἅννα καὶ Καϊάφα, ἐγένετο ῥῆμα ˚Θεοῦ ἐπὶ Ἰωάννην, τὸν Ζαχαρίου υἱὸν, ἐν τῇ ἐρήμῳ.
42003003 Καὶ ἦλθεν εἰς πᾶσαν περίχωρον τοῦ Ἰορδάνου, κηρύσσων βάπτισμα μετανοίας εἰς ἄφεσιν ἁμαρτιῶν,
42003004 ὡς γέγραπται ἐν βίβλῳ λόγων Ἠσαΐου τοῦ προφήτου, “Φωνὴ βοῶντος ἐν τῇ ἐρήμῳ: ‘Ἑτοιμάσατε τὴν ὁδὸν ˚Κυρίου, εὐθείας ποιεῖτε τὰς τρίβους αὐτοῦ.
42003005 Πᾶσα φάραγξ πληρωθήσεται, καὶ πᾶν ὄρος καὶ βουνὸς ταπεινωθήσεται, καὶ ἔσται τὰ σκολιὰ εἰς εὐθείας, καὶ αἱ τραχεῖαι εἰς ὁδοὺς λείας·
42003006 καὶ ὄψεται πᾶσα σὰρξ τὸ σωτήριον τοῦ ˚Θεοῦ.’”
42003007 Ἔλεγεν οὖν τοῖς ἐκπορευομένοις ὄχλοις βαπτισθῆναι ὑπʼ αὐτοῦ, “Γεννήματα ἐχιδνῶν, τίς ὑπέδειξεν ὑμῖν φυγεῖν ἀπὸ τῆς μελλούσης ὀργῆς;
42003008 Ποιήσατε οὖν καρποὺς ἀξίους τῆς μετανοίας· καὶ μὴ ἄρξησθε λέγειν ἐν ἑαυτοῖς, ‘Πατέρα ἔχομεν τὸν Ἀβραάμ’, λέγω γὰρ ὑμῖν ὅτι δύναται ὁ ˚Θεὸς ἐκ τῶν λίθων τούτων ἐγεῖραι τέκνα τῷ Ἀβραάμ.
42003009 Ἤδη δὲ καὶ ἡ ἀξίνη πρὸς τὴν ῥίζαν τῶν δένδρων κεῖται· πᾶν οὖν δένδρον μὴ ποιοῦν καρπὸν καλὸν, ἐκκόπτεται καὶ εἰς πῦρ βάλλεται.”
42003010 Καὶ ἐπηρώτων αὐτὸν οἱ ὄχλοι λέγοντες, “Τί οὖν ποιήσωμεν;”
42003011 Ἀποκριθεὶς δὲ ἔλεγεν αὐτοῖς, “Ὁ ἔχων δύο χιτῶνας, μεταδότω τῷ μὴ ἔχοντι, καὶ ὁ ἔχων βρώματα, ὁμοίως ποιείτω.”
42003012 Ἦλθον δὲ καὶ τελῶναι βαπτισθῆναι, καὶ εἶπον πρὸς αὐτόν, “Διδάσκαλε, τί ποιήσωμεν;”
42003013 Ὁ δὲ εἶπεν πρὸς αὐτούς, “Μηδὲν πλέον παρὰ τὸ διατεταγμένον ὑμῖν πράσσετε.”
42003014 Ἐπηρώτων δὲ αὐτὸν καὶ στρατευόμενοι λέγοντες, “Τί ποιήσωμεν καὶ ἡμεῖς;” Καὶ εἶπεν αὐτοῖς, “Μηδένα διασείσητε μηδὲ συκοφαντήσητε, καὶ ἀρκεῖσθε τοῖς ὀψωνίοις ὑμῶν.”
42003015 Προσδοκῶντος δὲ τοῦ λαοῦ καὶ διαλογιζομένων πάντων ἐν ταῖς καρδίαις αὐτῶν περὶ τοῦ Ἰωάννου, μήποτε αὐτὸς εἴη ὁ ˚Χριστός,
42003016 ἀπεκρίνατο λέγων πᾶσιν ὁ Ἰωάννης, “Ἐγὼ μὲν ὕδατι βαπτίζω ὑμᾶς, ἔρχεται δὲ ὁ ἰσχυρότερός μου, οὗ οὐκ εἰμὶ ἱκανὸς λῦσαι τὸν ἱμάντα τῶν ὑποδημάτων αὐτοῦ· αὐτὸς ὑμᾶς βαπτίσει ἐν ˚Πνεύματι Ἁγίῳ, καὶ πυρί·
42003017 οὗ τὸ πτύον ἐν τῇ χειρὶ αὐτοῦ διακαθᾶραι τὴν ἅλωνα αὐτοῦ, καὶ συναγαγεῖν τὸν σῖτον εἰς τὴν ἀποθήκην αὐτοῦ, τὸ δὲ ἄχυρον κατακαύσει πυρὶ ἀσβέστῳ.”
42003018 Πολλὰ μὲν οὖν καὶ ἕτερα παρακαλῶν, εὐηγγελίζετο τὸν λαόν.
42003019 Ὁ δὲ Ἡρῴδης ὁ τετραάρχης, ἐλεγχόμενος ὑπʼ αὐτοῦ περὶ Ἡρῳδιάδος, τῆς γυναικὸς τοῦ ἀδελφοῦ αὐτοῦ, καὶ περὶ πάντων ὧν ἐποίησεν πονηρῶν ὁ Ἡρῴδης,
42003020 προσέθηκεν καὶ τοῦτο ἐπὶ πᾶσιν: κατέκλεισεν τὸν Ἰωάννην ἐν φυλακῇ.
42003021 Ἐγένετο δὲ ἐν τῷ βαπτισθῆναι ἅπαντα τὸν λαὸν, καὶ ˚Ἰησοῦ βαπτισθέντος, καὶ προσευχομένου ἀνεῳχθῆναι τὸν οὐρανὸν,
42003022 καὶ καταβῆναι τὸ ˚Πνεῦμα τὸ Ἅγιον σωματικῷ εἴδει, ὡς περιστερὰν ἐπʼ αὐτόν, καὶ φωνὴν ἐξ οὐρανοῦ γενέσθαι: “Σὺ εἶ ὁ Υἱός μου ὁ ἀγαπητός, ἐν σοὶ εὐδόκησα.”
42003023 Καὶ αὐτὸς ἦν ˚Ἰησοῦς ἀρχόμενος ὡσεὶ ἐτῶν τριάκοντα, ὢν υἱός, ὡς ἐνομίζετο, Ἰωσὴφ, τοῦ Ἠλὶ,
42003024 τοῦ Μαθθὰτ, τοῦ Λευὶ, τοῦ Μελχὶ, τοῦ Ἰανναὶ, τοῦ Ἰωσὴφ,
42003025 τοῦ Ματταθίου, τοῦ Ἀμὼς, τοῦ Ναοὺμ, τοῦ Ἑσλὶ, τοῦ Ναγγαὶ,
42003026 τοῦ Μάαθ, τοῦ Ματταθίου, τοῦ Σεμεῒν, τοῦ Ἰωσὴχ, τοῦ Ἰωδὰ,
42003027 τοῦ Ἰωανὰν, τοῦ Ῥησὰ, τοῦ Ζοροβαβὲλ, τοῦ Σαλαθιὴλ, τοῦ Νηρὶ,
42003028 τοῦ Μελχὶ, τοῦ Ἀδδὶ, τοῦ Κωσὰμ, τοῦ Ἐλμαδὰμ, τοῦ Ἢρ,
42003029 τοῦ Ἰησοῦ, τοῦ Ἐλιέζερ, τοῦ Ἰωρὶμ, τοῦ Μαθθὰτ, τοῦ Λευὶ,
42003030 τοῦ Συμεὼν, τοῦ Ἰούδα, τοῦ Ἰωσὴφ, τοῦ Ἰωνὰμ, τοῦ Ἐλιακὶμ,
42003031 τοῦ Μελεὰ, τοῦ Μεννὰ, τοῦ Ματταθὰ, τοῦ Ναθὰμ, τοῦ Δαυὶδ,
42003032 τοῦ Ἰεσσαὶ, τοῦ Ἰωβὴδ, τοῦ Βόος, τοῦ Σαλὰ, τοῦ Ναασσὼν,
42003033 τοῦ Ἀμιναδὰβ, τοῦ Ἀδμὶν, τοῦ Ἀρνὶ, τοῦ Ἑσρὼμ, τοῦ Φαρὲς, τοῦ Ἰούδα,
42003034 τοῦ Ἰακὼβ, τοῦ Ἰσαὰκ, τοῦ Ἀβραὰμ, τοῦ Θάρα, τοῦ Ναχὼρ,
42003035 τοῦ Σεροὺχ, τοῦ Ῥαγαὺ, τοῦ Φάλεκ, τοῦ Ἔβερ, τοῦ Σαλὰ,
42003036 τοῦ Καϊνὰμ, τοῦ Ἀρφαξὰδ, τοῦ Σὴμ, τοῦ Νῶε, τοῦ Λάμεχ,
42003037 τοῦ Μαθουσαλὰ, τοῦ Ἑνὼχ, τοῦ Ἰάρετ, τοῦ Μαλελεὴλ, τοῦ Καϊνὰμ,
42003038 τοῦ Ἐνὼς, τοῦ Σὴθ, τοῦ Ἀδὰμ, τοῦ ˚Θεοῦ.
42004001 ˚Ἰησοῦς δὲ πλήρης ˚Πνεύματος Ἁγίου, ὑπέστρεψεν ἀπὸ τοῦ Ἰορδάνου, καὶ ἤγετο ἐν τῷ ˚Πνεύματι ἐν τῇ ἐρήμῳ,
42004002 ἡμέρας τεσσεράκοντα πειραζόμενος ὑπὸ τοῦ διαβόλου. Καὶ οὐκ ἔφαγεν οὐδὲν ἐν ταῖς ἡμέραις ἐκείναις, καὶ συντελεσθεισῶν αὐτῶν, ἐπείνασεν.
42004003 Εἶπεν δὲ αὐτῷ ὁ διάβολος, “Εἰ Υἱὸς εἶ τοῦ ˚Θεοῦ, εἰπὲ τῷ λίθῳ τούτῳ ἵνα γένηται ἄρτος.”
42004004 Καὶ ἀπεκρίθη πρὸς αὐτὸν ὁ ˚Ἰησοῦς, “Γέγραπται, ὅτι ‘Οὐκ ἐπʼ ἄρτῳ μόνῳ ζήσεται ὁ ἄνθρωπος.’”
42004005 Καὶ ἀναγαγὼν αὐτὸν ἔδειξεν αὐτῷ πάσας τὰς βασιλείας τῆς οἰκουμένης ἐν στιγμῇ χρόνου.
42004006 Καὶ εἶπεν αὐτῷ ὁ διάβολος, “Σοὶ δώσω τὴν ἐξουσίαν ταύτην ἅπασαν καὶ τὴν δόξαν αὐτῶν, ὅτι ἐμοὶ παραδέδοται καὶ ᾧ ἐὰν θέλω, δίδωμι αὐτήν.
42004007 Σὺ οὖν ἐὰν προσκυνήσῃς ἐνώπιον ἐμοῦ, ἔσται σοῦ πᾶσα.”
42004008 Καὶ ἀποκριθεὶς ὁ ˚Ἰησοῦς εἶπεν αὐτῷ, “Γέγραπται, ‘˚Κύριον τὸν ˚Θεόν σου προσκυνήσεις καὶ αὐτῷ μόνῳ λατρεύσεις.’”
42004009 Ἤγαγεν δὲ αὐτὸν εἰς Ἰερουσαλὴμ, καὶ ἔστησεν ἐπὶ τὸ πτερύγιον τοῦ ἱεροῦ, καὶ εἶπεν αὐτῷ, “Εἰ Υἱὸς εἶ τοῦ ˚Θεοῦ, βάλε σεαυτὸν ἐντεῦθεν κάτω·
42004010 γέγραπται γὰρ, ὅτι ‘Τοῖς ἀγγέλοις αὐτοῦ ἐντελεῖται περὶ σοῦ, τοῦ διαφυλάξαι σε’,
42004011 καὶ, ὅτι ‘Ἐπὶ χειρῶν ἀροῦσίν σε, μήποτε προσκόψῃς πρὸς λίθον τὸν πόδα σου.’”
42004012 Καὶ ἀποκριθεὶς εἶπεν αὐτῷ ὁ ˚Ἰησοῦς, ὅτι “Εἴρηται, ‘Οὐκ ἐκπειράσεις ˚Κύριον τὸν ˚Θεόν σου.’”
42004013 Καὶ συντελέσας πάντα πειρασμὸν, ὁ διάβολος ἀπέστη ἀπʼ αὐτοῦ ἄχρι καιροῦ.
42004014 Καὶ ὑπέστρεψεν ὁ ˚Ἰησοῦς ἐν τῇ δυνάμει τοῦ ˚Πνεύματος εἰς τὴν Γαλιλαίαν. Καὶ φήμη ἐξῆλθεν καθʼ ὅλης τῆς περιχώρου περὶ αὐτοῦ.
42004015 Καὶ αὐτὸς ἐδίδασκεν ἐν ταῖς συναγωγαῖς αὐτῶν, δοξαζόμενος ὑπὸ πάντων.
42004016 Καὶ ἦλθεν εἰς Ναζαρά, οὗ ἦν τεθραμμένος, καὶ εἰσῆλθεν κατὰ τὸ εἰωθὸς αὐτῷ, ἐν τῇ ἡμέρᾳ τῶν Σαββάτων εἰς τὴν συναγωγήν, καὶ ἀνέστη ἀναγνῶναι.
42004017 Καὶ ἐπεδόθη αὐτῷ βιβλίον τοῦ προφήτου Ἠσαΐου, καὶ ἀνοίξας τὸ βιβλίον, εὗρεν τὸν τόπον οὗ ἦν γεγραμμένον,
42004018 “˚Πνεῦμα ˚Κυρίου ἐπʼ ἐμέ, οὗ εἵνεκεν ἔχρισέν με εὐαγγελίσασθαι πτωχοῖς, ἀπέσταλκέν με κηρύξαι αἰχμαλώτοις ἄφεσιν, καὶ τυφλοῖς ἀνάβλεψιν, ἀποστεῖλαι τεθραυσμένους ἐν ἀφέσει,
42004019 κηρύξαι ἐνιαυτὸν ˚Κυρίου δεκτόν.”
42004020 Καὶ πτύξας τὸ βιβλίον ἀποδοὺς τῷ ὑπηρέτῃ, ἐκάθισεν καὶ πάντων οἱ ὀφθαλμοὶ ἐν τῇ συναγωγῇ ἦσαν ἀτενίζοντες αὐτῷ.
42004021 Ἤρξατο δὲ λέγειν πρὸς αὐτοὺς, ὅτι “Σήμερον πεπλήρωται ἡ Γραφὴ αὕτη ἐν τοῖς ὠσὶν ὑμῶν.”
42004022 Καὶ πάντες ἐμαρτύρουν αὐτῷ, καὶ ἐθαύμαζον ἐπὶ τοῖς λόγοις τῆς χάριτος τοῖς ἐκπορευομένοις ἐκ τοῦ στόματος αὐτοῦ, καὶ ἔλεγον, “Οὐχὶ υἱός ἐστιν Ἰωσὴφ οὗτος;”
42004023 Καὶ εἶπεν πρὸς αὐτούς, “Πάντως ἐρεῖτέ μοι τὴν παραβολὴν ταύτην: ‘“Ἰατρέ, θεράπευσον σεαυτόν”· ὅσα ἠκούσαμεν γενόμενα εἰς τὴν Καφαρναοὺμ, ποίησον καὶ ὧδε ἐν τῇ πατρίδι σου.’”
42004024 Εἶπεν δέ, “Ἀμὴν, λέγω ὑμῖν ὅτι οὐδεὶς προφήτης δεκτός ἐστιν ἐν τῇ πατρίδι αὐτοῦ.
42004025 Ἐπʼ ἀληθείας δὲ λέγω ὑμῖν, πολλαὶ χῆραι ἦσαν ἐν ταῖς ἡμέραις Ἠλίου ἐν τῷ Ἰσραήλ, ὅτε ἐκλείσθη ὁ οὐρανὸς ἐπὶ ἔτη τρία καὶ μῆνας ἕξ, ὡς ἐγένετο λιμὸς μέγας ἐπὶ πᾶσαν τὴν γῆν,
42004026 καὶ πρὸς οὐδεμίαν αὐτῶν ἐπέμφθη Ἠλίας, εἰ μὴ εἰς Σάρεπτα τῆς Σιδωνίας, πρὸς γυναῖκα χήραν.
42004027 Καὶ πολλοὶ λεπροὶ ἦσαν ἐν τῷ Ἰσραὴλ, ἐπὶ Ἐλισαίου τοῦ προφήτου, καὶ οὐδεὶς αὐτῶν ἐκαθαρίσθη, εἰ μὴ Ναιμὰν ὁ Σύρος.”
42004028 Καὶ ἐπλήσθησαν πάντες θυμοῦ ἐν τῇ συναγωγῇ ἀκούοντες ταῦτα,
42004029 καὶ ἀναστάντες, ἐξέβαλον αὐτὸν ἔξω τῆς πόλεως, καὶ ἤγαγον αὐτὸν ἕως ὀφρύος τοῦ ὄρους ἐφʼ οὗ ἡ πόλις ᾠκοδόμητο αὐτῶν, ὥστε κατακρημνίσαι αὐτόν.
42004030 Αὐτὸς δὲ, διελθὼν διὰ μέσου αὐτῶν, ἐπορεύετο.
42004031 Καὶ κατῆλθεν εἰς Καφαρναοὺμ, πόλιν τῆς Γαλιλαίας. Καὶ ἦν διδάσκων αὐτοὺς ἐν τοῖς Σάββασιν.
42004032 Καὶ ἐξεπλήσσοντο ἐπὶ τῇ διδαχῇ αὐτοῦ, ὅτι ἐν ἐξουσίᾳ ἦν ὁ λόγος αὐτοῦ.
42004033 Καὶ ἐν τῇ συναγωγῇ ἦν ἄνθρωπος ἔχων πνεῦμα δαιμονίου ἀκαθάρτου, καὶ ἀνέκραξεν φωνῇ μεγάλῃ,
42004034 “Ἔα! Τί ἡμῖν καὶ σοί, ˚Ἰησοῦ Ναζαρηνέ; Ἦλθες ἀπολέσαι ἡμᾶς; Οἶδά σε τίς εἶ, ὁ Ἅγιος τοῦ ˚Θεοῦ.”
42004035 Καὶ ἐπετίμησεν αὐτῷ ὁ ˚Ἰησοῦς λέγων, “Φιμώθητι, καὶ ἔξελθε ἐξ αὐτοῦ!” Καὶ ῥίψαν αὐτὸν τὸ δαιμόνιον εἰς τὸ μέσον, ἐξῆλθεν ἀπʼ αὐτοῦ, μηδὲν βλάψαν αὐτόν.
42004036 Καὶ ἐγένετο θάμβος ἐπὶ πάντας, καὶ συνελάλουν πρὸς ἀλλήλους λέγοντες, “Τίς ὁ λόγος οὗτος, ὅτι ἐν ἐξουσίᾳ καὶ δυνάμει ἐπιτάσσει τοῖς ἀκαθάρτοις πνεύμασιν, καὶ ἐξέρχονται;”
42004037 Καὶ ἐξεπορεύετο ἦχος περὶ αὐτοῦ εἰς πάντα τόπον τῆς περιχώρου.
42004038 Ἀναστὰς δὲ ἀπὸ τῆς συναγωγῆς, εἰσῆλθεν εἰς τὴν οἰκίαν Σίμωνος. Πενθερὰ δὲ τοῦ Σίμωνος ἦν συνεχομένη πυρετῷ μεγάλῳ, καὶ ἠρώτησαν αὐτὸν περὶ αὐτῆς.
42004039 Καὶ ἐπιστὰς ἐπάνω αὐτῆς, ἐπετίμησεν τῷ πυρετῷ, καὶ ἀφῆκεν αὐτήν· παραχρῆμα δὲ ἀναστᾶσα, διηκόνει αὐτοῖς.
42004040 Δύνοντος δὲ τοῦ ἡλίου, πάντες ὅσοι εἶχον ἀσθενοῦντας νόσοις ποικίλαις, ἤγαγον αὐτοὺς πρὸς αὐτόν, ὁ δὲ ἑνὶ ἑκάστῳ αὐτῶν, τὰς χεῖρας ἐπιτιθεὶς, ἐθεράπευεν αὐτούς.
42004041 Ἐξήρχετο δὲ καὶ δαιμόνια ἀπὸ πολλῶν, κράζοντα καὶ λέγοντα, ὅτι “Σὺ εἶ ὁ Υἱὸς τοῦ ˚Θεοῦ.” Καὶ ἐπιτιμῶν, οὐκ εἴα αὐτὰ λαλεῖν, ὅτι ᾔδεισαν τὸν ˚Χριστὸν αὐτὸν εἶναι.
42004042 Γενομένης δὲ ἡμέρας, ἐξελθὼν ἐπορεύθη εἰς ἔρημον τόπον, καὶ οἱ ὄχλοι ἐπεζήτουν αὐτόν, καὶ ἦλθον ἕως αὐτοῦ, καὶ κατεῖχον αὐτὸν τοῦ μὴ πορεύεσθαι ἀπʼ αὐτῶν.
42004043 Ὁ δὲ εἶπεν πρὸς αὐτοὺς, ὅτι “Καὶ ταῖς ἑτέραις πόλεσιν εὐαγγελίσασθαί με δεῖ τὴν Βασιλείαν τοῦ ˚Θεοῦ, ὅτι ἐπὶ τοῦτο ἀπεστάλην.”
42004044 Καὶ ἦν κηρύσσων εἰς τὰς συναγωγὰς τῆς Ἰουδαίας.
42005001 Ἐγένετο δὲ ἐν τῷ, τὸν ὄχλον ἐπικεῖσθαι αὐτῷ καὶ ἀκούειν τὸν λόγον τοῦ ˚Θεοῦ, καὶ αὐτὸς ἦν ἑστὼς παρὰ τὴν λίμνην Γεννησαρέτ,
42005002 καὶ εἶδεν δύο πλοῖα ἑστῶτα παρὰ τὴν λίμνην, οἱ δὲ ἁλιεῖς ἀπʼ αὐτῶν ἀποβάντες, ἔπλυνον τὰ δίκτυα.
42005003 Ἐμβὰς δὲ εἰς ἓν τῶν πλοίων, ὃ ἦν Σίμωνος, ἠρώτησεν αὐτὸν ἀπὸ τῆς γῆς ἐπαναγαγεῖν ὀλίγον. Καθίσας δὲ, ἐδίδασκεν ἐκ τοῦ πλοίου τοὺς ὄχλους.
42005004 Ὡς δὲ ἐπαύσατο λαλῶν, εἶπεν πρὸς τὸν Σίμωνα, “Ἐπανάγαγε εἰς τὸ βάθος καὶ χαλάσατε τὰ δίκτυα ὑμῶν εἰς ἄγραν.”
42005005 Καὶ ἀποκριθεὶς Σίμων εἶπεν, “Ἐπιστάτα, διʼ ὅλης νυκτὸς κοπιάσαντες, οὐδὲν ἐλάβομεν, ἐπὶ δὲ τῷ ῥήματί σου, χαλάσω τὰ δίκτυα.”
42005006 Καὶ τοῦτο ποιήσαντες, συνέκλεισαν πλῆθος ἰχθύων πολύ, διερρήσσετο δὲ τὰ δίκτυα αὐτῶν.
42005007 Καὶ κατένευσαν τοῖς μετόχοις ἐν τῷ ἑτέρῳ πλοίῳ, τοῦ ἐλθόντας συλλαβέσθαι αὐτοῖς· καὶ ἦλθον, καὶ ἔπλησαν ἀμφότερα τὰ πλοῖα, ὥστε βυθίζεσθαι αὐτά.
42005008 Ἰδὼν δὲ, Σίμων Πέτρος προσέπεσεν τοῖς γόνασιν ˚Ἰησοῦ λέγων, “Ἔξελθε ἀπʼ ἐμοῦ, ὅτι ἀνὴρ ἁμαρτωλός εἰμι, ˚Κύριε.”
42005009 Θάμβος γὰρ περιέσχεν αὐτὸν καὶ πάντας τοὺς σὺν αὐτῷ, ἐπὶ τῇ ἄγρᾳ τῶν ἰχθύων ὧν συνέλαβον,
42005010 ὁμοίως δὲ καὶ Ἰάκωβον καὶ Ἰωάννην, υἱοὺς Ζεβεδαίου, οἳ ἦσαν κοινωνοὶ τῷ Σίμωνι. Καὶ εἶπεν πρὸς τὸν Σίμωνα ὁ ˚Ἰησοῦς, “Μὴ φοβοῦ· ἀπὸ τοῦ νῦν ἀνθρώπους ἔσῃ ζωγρῶν.”
42005011 Καὶ καταγαγόντες τὰ πλοῖα ἐπὶ τὴν γῆν, ἀφέντες πάντα, ἠκολούθησαν αὐτῷ.
42005012 Καὶ ἐγένετο ἐν τῷ εἶναι αὐτὸν ἐν μιᾷ τῶν πόλεων, καὶ ἰδοὺ, ἀνὴρ πλήρης λέπρας, ἰδὼν δὲ τὸν ˚Ἰησοῦν, πεσὼν ἐπὶ πρόσωπον, ἐδεήθη αὐτοῦ λέγων, “˚Κύριε, ἐὰν θέλῃς, δύνασαί με καθαρίσαι.”
42005013 Καὶ ἐκτείνας τὴν χεῖρα, ἥψατο αὐτοῦ λέγων, “Θέλω, καθαρίσθητι!” Καὶ εὐθέως ἡ λέπρα ἀπῆλθεν ἀπʼ αὐτοῦ.
42005014 Καὶ αὐτὸς παρήγγειλεν αὐτῷ, “Μηδενὶ εἰπεῖν, ἀλλὰ ἀπελθὼν δεῖξον σεαυτὸν τῷ ἱερεῖ, καὶ προσένεγκε περὶ τοῦ καθαρισμοῦ σου καθὼς προσέταξεν Μωϋσῆς, εἰς μαρτύριον αὐτοῖς.”
42005015 Διήρχετο δὲ μᾶλλον ὁ λόγος περὶ αὐτοῦ, καὶ συνήρχοντο ὄχλοι πολλοὶ ἀκούειν, καὶ θεραπεύεσθαι ἀπὸ τῶν ἀσθενειῶν αὐτῶν.
42005016 Αὐτὸς δὲ ἦν ὑποχωρῶν ἐν ταῖς ἐρήμοις καὶ προσευχόμενος.
42005017 Καὶ ἐγένετο ἐν μιᾷ τῶν ἡμερῶν, καὶ αὐτὸς ἦν διδάσκων, καὶ ἦσαν καθήμενοι Φαρισαῖοι καὶ νομοδιδάσκαλοι, οἳ ἦσαν ἐληλυθότες ἐκ πάσης κώμης τῆς Γαλιλαίας, καὶ Ἰουδαίας, καὶ Ἰερουσαλήμ. Καὶ δύναμις ˚Κυρίου ἦν εἰς τὸ ἰᾶσθαι αὐτόν.
42005018 Καὶ ἰδοὺ, ἄνδρες φέροντες ἐπὶ κλίνης ἄνθρωπον ὃς ἦν παραλελυμένος, καὶ ἐζήτουν αὐτὸν εἰσενεγκεῖν καὶ θεῖναι ἐνώπιον αὐτοῦ.
42005019 Καὶ μὴ εὑρόντες ποίας εἰσενέγκωσιν αὐτὸν διὰ τὸν ὄχλον, ἀναβάντες ἐπὶ τὸ δῶμα, διὰ τῶν κεράμων καθῆκαν αὐτὸν σὺν τῷ κλινιδίῳ εἰς τὸ μέσον ἔμπροσθεν τοῦ ˚Ἰησοῦ.
42005020 Καὶ ἰδὼν τὴν πίστιν αὐτῶν εἶπεν, “Ἄνθρωπε, ἀφέωνταί σοι αἱ ἁμαρτίαι σου.”
42005021 Καὶ ἤρξαντο διαλογίζεσθαι οἱ γραμματεῖς καὶ οἱ Φαρισαῖοι λέγοντες, “Τίς ἐστιν οὗτος ὃς λαλεῖ βλασφημίας; Τίς δύναται ἀφιέναι ἁμαρτίας εἰ μὴ μόνος ὁ ˚Θεός;”
42005022 Ἐπιγνοὺς δὲ ὁ ˚Ἰησοῦς τοὺς διαλογισμοὺς αὐτῶν, ἀποκριθεὶς εἶπεν πρὸς αὐτούς, “Τί διαλογίζεσθε ἐν ταῖς καρδίαις ὑμῶν;
42005023 Τί ἐστιν εὐκοπώτερον, εἰπεῖν, ‘Ἀφέωνταί σοι αἱ ἁμαρτίαι σου’, ἢ εἰπεῖν, ‘Ἔγειρε καὶ περιπάτει’;
42005024 Ἵνα δὲ εἰδῆτε ὅτι ὁ Υἱὸς τοῦ Ἀνθρώπου ἐξουσίαν ἔχει ἐπὶ τῆς γῆς ἀφιέναι ἁμαρτίας”, εἶπεν τῷ παραλελυμένῳ, “σοὶ λέγω, ἔγειρε, καὶ ἄρας τὸ κλινίδιόν σου, πορεύου εἰς τὸν οἶκόν σου.”
42005025 Καὶ παραχρῆμα ἀναστὰς ἐνώπιον αὐτῶν, ἄρας ἐφʼ ὃ κατέκειτο, ἀπῆλθεν εἰς τὸν οἶκον αὐτοῦ δοξάζων τὸν ˚Θεόν.
42005026 Καὶ ἔκστασις ἔλαβεν ἅπαντας, καὶ ἐδόξαζον τὸν ˚Θεόν, καὶ ἐπλήσθησαν φόβου λέγοντες, ὅτι “Εἴδομεν παράδοξα σήμερον.”
42005027 Καὶ μετὰ ταῦτα ἐξῆλθεν, καὶ ἐθεάσατο τελώνην ὀνόματι Λευὶν, καθήμενον ἐπὶ τὸ τελώνιον, καὶ εἶπεν αὐτῷ, “Ἀκολούθει μοι.”
42005028 Καὶ καταλιπὼν πάντα, ἀναστὰς ἠκολούθει αὐτῷ.
42005029 Καὶ ἐποίησεν δοχὴν μεγάλην Λευὶς αὐτῷ ἐν τῇ οἰκίᾳ αὐτοῦ, καὶ ἦν ὄχλος πολὺς τελωνῶν καὶ ἄλλων, οἳ ἦσαν μετʼ αὐτῶν κατακείμενοι.
42005030 Καὶ ἐγόγγυζον οἱ Φαρισαῖοι καὶ οἱ γραμματεῖς αὐτῶν πρὸς τοὺς μαθητὰς αὐτοῦ λέγοντες, “Διὰ τί μετὰ τῶν τελωνῶν καὶ ἁμαρτωλῶν ἐσθίετε καὶ πίνετε;”
42005031 Καὶ ἀποκριθεὶς ὁ ˚Ἰησοῦς εἶπεν πρὸς αὐτούς, “Οὐ χρείαν ἔχουσιν οἱ ὑγιαίνοντες ἰατροῦ, ἀλλὰ οἱ κακῶς ἔχοντες.
42005032 Οὐκ ἐλήλυθα καλέσαι δικαίους, ἀλλὰ ἁμαρτωλοὺς εἰς μετάνοιαν.”
42005033 Οἱ δὲ εἶπαν πρὸς αὐτόν, “Οἱ μαθηταὶ Ἰωάννου νηστεύουσιν πυκνὰ, καὶ δεήσεις ποιοῦνται, ὁμοίως καὶ οἱ τῶν Φαρισαίων, οἱ δὲ σοὶ ἐσθίουσιν καὶ πίνουσιν.”
42005034 Ὁ δὲ ˚Ἰησοῦς εἶπεν πρὸς αὐτούς, “Μὴ δύνασθε τοὺς υἱοὺς τοῦ νυμφῶνος ἐν ᾧ ὁ νυμφίος μετʼ αὐτῶν ἐστιν ποιῆσαι νηστεύειν;
42005035 Ἐλεύσονται δὲ ἡμέραι καὶ ὅταν ἀπαρθῇ ἀπʼ αὐτῶν ὁ νυμφίος, τότε νηστεύσουσιν ἐν ἐκείναις ταῖς ἡμέραις.”
42005036 Ἔλεγεν δὲ καὶ παραβολὴν πρὸς αὐτοὺς: ὅτι “Οὐδεὶς ἐπίβλημα ἀπὸ ἱματίου καινοῦ σχίσας, ἐπιβάλλει ἐπὶ ἱμάτιον παλαιόν· εἰ δὲ μή γε καὶ τὸ καινὸν σχίσει, καὶ τῷ παλαιῷ οὐ συμφωνήσει τὸ ἐπίβλημα τὸ ἀπὸ τοῦ καινοῦ.
42005037 Καὶ οὐδεὶς βάλλει οἶνον νέον εἰς ἀσκοὺς παλαιούς· εἰ δὲ μή γε ῥήξει ὁ οἶνος ὁ νέος τοὺς ἀσκούς, καὶ αὐτὸς ἐκχυθήσεται, καὶ οἱ ἀσκοὶ ἀπολοῦνται.
42005038 Ἀλλὰ οἶνον νέον εἰς ἀσκοὺς καινοὺς βλητέον.
42005039 Οὐδεὶς πιὼν παλαιὸν θέλει νέον· λέγει γάρ, ‘Ὁ παλαιὸς χρηστός ἐστιν.’”
42006001 Ἐγένετο δὲ ἐν Σαββάτῳ διαπορεύεσθαι αὐτὸν διὰ σπορίμων, καὶ ἔτιλλον οἱ μαθηταὶ αὐτοῦ, καὶ ἤσθιον τοὺς στάχυας, ψώχοντες ταῖς χερσίν.
42006002 Τινὲς δὲ τῶν Φαρισαίων εἶπον, “Τί ποιεῖτε ὃ οὐκ ἔξεστιν τοῖς Σάββασιν;”
42006003 Καὶ ἀποκριθεὶς πρὸς αὐτοὺς εἶπεν ὁ ˚Ἰησοῦς, “Οὐδὲ τοῦτο ἀνέγνωτε, ὃ ἐποίησεν Δαυὶδ ὅτε ἐπείνασεν αὐτὸς, καὶ οἱ μετʼ αὐτοῦ
42006004 ὡς εἰσῆλθεν εἰς τὸν οἶκον τοῦ ˚Θεοῦ, καὶ τοὺς ἄρτους τῆς Προθέσεως λαβὼν, ἔφαγεν καὶ ἔδωκεν τοῖς μετʼ αὐτοῦ, οὓς οὐκ ἔξεστιν φαγεῖν, εἰ μὴ μόνους τοὺς ἱερεῖς;”
42006005 Καὶ ἔλεγεν αὐτοῖς, “˚Κύριός ἐστιν τοῦ Σαββάτου ὁ Υἱὸς τοῦ Ἀνθρώπου.”
42006006 Ἐγένετο δὲ ἐν ἑτέρῳ Σαββάτῳ, εἰσελθεῖν αὐτὸν εἰς τὴν συναγωγὴν καὶ διδάσκειν· καὶ ἦν ἄνθρωπος ἐκεῖ, καὶ ἡ χεὶρ αὐτοῦ ἡ δεξιὰ ἦν ξηρά.
42006007 Παρετηροῦντο δὲ αὐτὸν οἱ γραμματεῖς καὶ οἱ Φαρισαῖοι, εἰ ἐν τῷ Σαββάτῳ θεραπεύσει, ἵνα εὕρωσιν κατηγορεῖν αὐτοῦ.
42006008 Αὐτὸς δὲ ᾔδει τοὺς διαλογισμοὺς αὐτῶν, εἶπεν δὲ τῷ ἀνδρὶ τῷ ξηρὰν ἔχοντι τὴν χεῖρα, “Ἔγειρε, καὶ στῆθι εἰς τὸ μέσον.” Καὶ ἀναστὰς, ἔστη.
42006009 Εἶπεν δὲ ὁ ˚Ἰησοῦς πρὸς αὐτούς, “Ἐπερωτῶ ὑμᾶς, εἰ ἔξεστιν τῷ Σαββάτῳ ἀγαθοποιῆσαι ἢ κακοποιῆσαι, ψυχὴν σῶσαι ἢ ἀπολέσαι;”
42006010 Καὶ περιβλεψάμενος πάντας αὐτοὺς, εἶπεν αὐτῷ, “Ἔκτεινον τὴν χεῖρά σου.” Ὁ δὲ ἐποίησεν, καὶ ἀπεκατεστάθη ἡ χεὶρ αὐτοῦ.
42006011 Αὐτοὶ δὲ ἐπλήσθησαν ἀνοίας, καὶ διελάλουν πρὸς ἀλλήλους τί ἂν ποιήσαιεν τῷ ˚Ἰησοῦ.
42006012 Ἐγένετο δὲ ἐν ταῖς ἡμέραις ταύταις, ἐξελθεῖν αὐτὸν εἰς τὸ ὄρος προσεύξασθαι, καὶ ἦν διανυκτερεύων ἐν τῇ προσευχῇ τοῦ ˚Θεοῦ.
42006013 Καὶ ὅτε ἐγένετο ἡμέρα, προσεφώνησεν τοὺς μαθητὰς αὐτοῦ, καὶ ἐκλεξάμενος ἀπʼ αὐτῶν δώδεκα, οὓς καὶ ἀποστόλους ὠνόμασεν:
42006014 Σίμωνα ὃν καὶ ὠνόμασεν Πέτρον, καὶ Ἀνδρέαν τὸν ἀδελφὸν αὐτοῦ, καὶ Ἰάκωβον, καὶ Ἰωάννην, καὶ Φίλιππον, καὶ Βαρθολομαῖον,
42006015 καὶ Μαθθαῖον, καὶ Θωμᾶν, Ἰάκωβον Ἁλφαίου, καὶ Σίμωνα τὸν καλούμενον Ζηλωτὴν,
42006016 καὶ Ἰούδαν Ἰακώβου, καὶ Ἰούδαν Ἰσκαριὼθ, ὃς ἐγένετο προδότης.
42006017 Καὶ καταβὰς μετʼ αὐτῶν, ἔστη ἐπὶ τόπου πεδινοῦ, καὶ ὄχλος πολὺς μαθητῶν αὐτοῦ, καὶ πλῆθος πολὺ τοῦ λαοῦ ἀπὸ πάσης τῆς Ἰουδαίας, καὶ Ἰερουσαλὴμ, καὶ τῆς παραλίου Τύρου καὶ Σιδῶνος,
42006018 οἳ ἦλθον ἀκοῦσαι αὐτοῦ καὶ ἰαθῆναι ἀπὸ τῶν νόσων αὐτῶν. Καὶ οἱ ἐνοχλούμενοι ἀπὸ πνευμάτων ἀκαθάρτων ἐθεραπεύοντο.
42006019 Καὶ πᾶς ὁ ὄχλος ἐζήτουν ἅπτεσθαι αὐτοῦ, ὅτι δύναμις παρʼ αὐτοῦ ἐξήρχετο καὶ ἰᾶτο πάντας.
42006020 Καὶ αὐτὸς ἐπάρας τοὺς ὀφθαλμοὺς αὐτοῦ εἰς τοὺς μαθητὰς αὐτοῦ ἔλεγεν, “Μακάριοι οἱ πτωχοί, ὅτι ὑμετέρα ἐστὶν ἡ Βασιλεία τοῦ ˚Θεοῦ.
42006021 Μακάριοι οἱ πεινῶντες νῦν, ὅτι χορτασθήσεσθε. Μακάριοι οἱ κλαίοντες νῦν, ὅτι γελάσετε.
42006022 Μακάριοί ἐστε ὅταν μισήσωσιν ὑμᾶς οἱ ἄνθρωποι, καὶ ὅταν ἀφορίσωσιν ὑμᾶς, καὶ ὀνειδίσωσιν, καὶ ἐκβάλωσιν τὸ ὄνομα ὑμῶν ὡς πονηρὸν, ἕνεκα τοῦ Υἱοῦ τοῦ Ἀνθρώπου.
42006023 Χάρητε ἐν ἐκείνῃ τῇ ἡμέρᾳ, καὶ σκιρτήσατε, ἰδοὺ γὰρ, ὁ μισθὸς ὑμῶν πολὺς ἐν τῷ οὐρανῷ. Κατὰ τὰ αὐτὰ γὰρ ἐποίουν τοῖς προφήταις οἱ πατέρες αὐτῶν.
42006024 Πλὴν οὐαὶ ὑμῖν τοῖς πλουσίοις, ὅτι ἀπέχετε τὴν παράκλησιν ὑμῶν.
42006025 Οὐαὶ ὑμῖν, οἱ ἐμπεπλησμένοι νῦν, ὅτι πεινάσετε. Οὐαί, οἱ γελῶντες νῦν, ὅτι πενθήσετε καὶ κλαύσετε.
42006026 Οὐαὶ ὅταν ὑμᾶς καλῶς εἴπωσιν πάντες οἱ ἄνθρωποι, κατὰ τὰ αὐτὰ γὰρ ἐποίουν τοῖς ψευδοπροφήταις οἱ πατέρες αὐτῶν.
42006027 Ἀλλὰ ὑμῖν λέγω τοῖς ἀκούουσιν, ἀγαπᾶτε τοὺς ἐχθροὺς ὑμῶν, καλῶς ποιεῖτε τοῖς μισοῦσιν ὑμᾶς,
42006028 εὐλογεῖτε τοὺς καταρωμένους ὑμᾶς, προσεύχεσθε περὶ τῶν ἐπηρεαζόντων ὑμᾶς.
42006029 Τῷ τύπτοντί σε ἐπὶ τὴν σιαγόνα, πάρεχε καὶ τὴν ἄλλην, καὶ ἀπὸ τοῦ αἴροντός σου τὸ ἱμάτιον, καὶ τὸν χιτῶνα μὴ κωλύσῃς.
42006030 Παντὶ αἰτοῦντί σε δίδου, καὶ ἀπὸ τοῦ αἴροντος τὰ σὰ, μὴ ἀπαίτει.
42006031 Καὶ καθὼς θέλετε ἵνα ποιῶσιν ὑμῖν οἱ ἄνθρωποι, ποιεῖτε αὐτοῖς ὁμοίως.
42006032 Καὶ εἰ ἀγαπᾶτε τοὺς ἀγαπῶντας ὑμᾶς, ποία ὑμῖν χάρις ἐστίν; Καὶ γὰρ οἱ ἁμαρτωλοὶ τοὺς ἀγαπῶντας αὐτοὺς ἀγαπῶσιν.
42006033 Καὶ γὰρ ἐὰν ἀγαθοποιῆτε τοὺς ἀγαθοποιοῦντας ὑμᾶς, ποία ὑμῖν χάρις ἐστίν; Καὶ οἱ ἁμαρτωλοὶ τὸ αὐτὸ ποιοῦσιν.
42006034 Καὶ ἐὰν δανίσητε παρʼ ὧν ἐλπίζετε λαβεῖν, ποία ὑμῖν χάρις ἐστίν; Καὶ ἁμαρτωλοὶ ἁμαρτωλοῖς δανίζουσιν, ἵνα ἀπολάβωσιν τὰ ἴσα.
42006035 Πλὴν ἀγαπᾶτε τοὺς ἐχθροὺς ὑμῶν, καὶ ἀγαθοποιεῖτε, καὶ δανίζετε μηδὲν ἀπελπίζοντες· καὶ ἔσται ὁ μισθὸς ὑμῶν πολύς, καὶ ἔσεσθε υἱοὶ Ὑψίστου, ὅτι αὐτὸς χρηστός ἐστιν ἐπὶ τοὺς ἀχαρίστους καὶ πονηρούς.
42006036 Γίνεσθε οἰκτίρμονες, καθὼς ὁ Πατὴρ ὑμῶν οἰκτίρμων ἐστίν.
42006037 Καὶ μὴ κρίνετε, καὶ οὐ μὴ κριθῆτε. Καὶ μὴ καταδικάζετε, καὶ οὐ μὴ καταδικασθῆτε. Ἀπολύετε, καὶ ἀπολυθήσεσθε.
42006038 Δίδοτε, καὶ δοθήσεται ὑμῖν· μέτρον καλὸν, πεπιεσμένον σεσαλευμένον ὑπερεκχυννόμενον, δώσουσιν εἰς τὸν κόλπον ὑμῶν, ᾧ γὰρ μέτρῳ μετρεῖτε, ἀντιμετρηθήσεται ὑμῖν.”
42006039 Εἶπεν δὲ καὶ παραβολὴν αὐτοῖς: “Μήτι δύναται τυφλὸς τυφλὸν ὁδηγεῖν; Οὐχὶ ἀμφότεροι εἰς βόθυνον πεσοῦνται;
42006040 Οὐκ ἔστιν μαθητὴς ὑπὲρ τὸν διδάσκαλον, κατηρτισμένος δὲ πᾶς ἔσται ὡς ὁ διδάσκαλος αὐτοῦ.
42006041 Τί δὲ βλέπεις τὸ κάρφος τὸ ἐν τῷ ὀφθαλμῷ τοῦ ἀδελφοῦ σου, τὴν δὲ δοκὸν τὴν ἐν τῷ ἰδίῳ ὀφθαλμῷ οὐ κατανοεῖς;
42006042 Πῶς δύνασαι λέγειν τῷ ἀδελφῷ σου, ‘Ἀδελφέ, ἄφες ἐκβάλω τὸ κάρφος τὸ ἐν τῷ ὀφθαλμῷ σου’, αὐτὸς τὴν ἐν τῷ ὀφθαλμῷ σοῦ δοκὸν οὐ βλέπων; Ὑποκριτά, ἔκβαλε πρῶτον τὴν δοκὸν ἐκ τοῦ ὀφθαλμοῦ σοῦ, καὶ τότε διαβλέψεις τὸ κάρφος τὸ ἐν τῷ ὀφθαλμῷ τοῦ ἀδελφοῦ σου ἐκβαλεῖν.
42006043 Οὐ γάρ ἐστιν δένδρον καλὸν ποιοῦν καρπὸν σαπρόν, οὐδὲ πάλιν δένδρον σαπρὸν ποιοῦν καρπὸν καλόν.
42006044 Ἕκαστον γὰρ δένδρον ἐκ τοῦ ἰδίου καρποῦ γινώσκεται. Οὐ γὰρ ἐξ ἀκανθῶν συλλέγουσιν σῦκα, οὐδὲ ἐκ βάτου σταφυλὴν τρυγῶσιν.
42006045 Ὁ ἀγαθὸς ἄνθρωπος ἐκ τοῦ ἀγαθοῦ θησαυροῦ τῆς καρδίας αὑτοῦ προφέρει τὸ ἀγαθόν, καὶ ὁ πονηρὸς ἐκ τοῦ πονηροῦ προφέρει τὸ πονηρόν. Ἐκ γὰρ περισσεύματος καρδίας λαλεῖ τὸ στόμα αὐτοῦ.
42006046 Τί δέ με καλεῖτε “˚Κύριε, ˚Κύριε”, καὶ οὐ ποιεῖτε ἃ λέγω;
42006047 Πᾶς ὁ ἐρχόμενος πρός με, καὶ ἀκούων μου τῶν λόγων καὶ ποιῶν αὐτούς, ὑποδείξω ὑμῖν τίνι ἐστὶν ὅμοιος:
42006048 ὅμοιός ἐστιν ἀνθρώπῳ οἰκοδομοῦντι οἰκίαν, ὃς ἔσκαψεν καὶ ἐβάθυνεν καὶ ἔθηκεν θεμέλιον ἐπὶ τὴν πέτραν· πλημμύρης δὲ γενομένης προσέρηξεν ὁ ποταμὸς τῇ οἰκίᾳ ἐκείνῃ, καὶ οὐκ ἴσχυσεν σαλεῦσαι αὐτὴν, διὰ τὸ καλῶς οἰκοδομῆσθαι αὐτήν.
42006049 Ὁ δὲ ἀκούσας καὶ μὴ ποιήσας, ὅμοιός ἐστιν ἀνθρώπῳ οἰκοδομήσαντι οἰκίαν ἐπὶ τὴν γῆν χωρὶς θεμελίου, ᾗ προσέρηξεν ὁ ποταμός καὶ εὐθὺς συνέπεσεν, καὶ ἐγένετο τὸ ῥῆγμα τῆς οἰκίας ἐκείνης μέγα.”
42007001 Ἐπειδὴ ἐπλήρωσεν πάντα τὰ ῥήματα αὐτοῦ εἰς τὰς ἀκοὰς τοῦ λαοῦ, εἰσῆλθεν εἰς Καφαρναούμ.
42007002 Ἑκατοντάρχου δέ τινος δοῦλος κακῶς ἔχων, ἤμελλεν τελευτᾶν, ὃς ἦν αὐτῷ ἔντιμος.
42007003 Ἀκούσας δὲ περὶ τοῦ ˚Ἰησοῦ, ἀπέστειλεν πρὸς αὐτὸν πρεσβυτέρους τῶν Ἰουδαίων, ἐρωτῶν αὐτὸν ὅπως ἐλθὼν διασώσῃ τὸν δοῦλον αὐτοῦ.
42007004 Οἱ δὲ παραγενόμενοι πρὸς τὸν ˚Ἰησοῦν, παρεκάλουν αὐτὸν σπουδαίως λέγοντες ὅτι “Ἄξιός ἐστιν ᾧ παρέξῃ τοῦτο,
42007005 ἀγαπᾷ γὰρ τὸ ἔθνος ἡμῶν, καὶ τὴν συναγωγὴν αὐτὸς ᾠκοδόμησεν ἡμῖν.”
42007006 Ὁ δὲ ˚Ἰησοῦς ἐπορεύετο σὺν αὐτοῖς. Ἤδη δὲ αὐτοῦ οὐ μακρὰν ἀπέχοντος ἀπὸ τῆς οἰκίας, ἔπεμψεν ὁ ἑκατόνταρχος φίλους λέγων αὐτῷ, “˚Κύριε, μὴ σκύλλου, οὐ γὰρ ἱκανός εἰμι ἵνα ὑπὸ τὴν στέγην μου εἰσέλθῃς·
42007007 διὸ οὐδὲ ἐμαυτὸν ἠξίωσα πρὸς σὲ ἐλθεῖν· ἀλλὰ εἰπὲ λόγῳ, καὶ ἰαθήτω ὁ παῖς μου.
42007008 Καὶ γὰρ ἐγὼ ἄνθρωπός εἰμι ὑπὸ ἐξουσίαν τασσόμενος, ἔχων ὑπʼ ἐμαυτὸν στρατιώτας, καὶ λέγω τούτῳ, ‘Πορεύθητι’, καὶ πορεύεται, καὶ ἄλλῳ, ‘Ἔρχου’, καὶ ἔρχεται, καὶ τῷ δούλῳ μου, ‘Ποίησον τοῦτο’, καὶ ποιεῖ.”
42007009 Ἀκούσας δὲ ταῦτα ὁ ˚Ἰησοῦς, ἐθαύμασεν αὐτόν, καὶ στραφεὶς τῷ ἀκολουθοῦντι αὐτῷ ὄχλῳ εἶπεν, “Λέγω ὑμῖν, οὐδὲ ἐν τῷ Ἰσραὴλ τοσαύτην πίστιν εὗρον.”
42007010 Καὶ ὑποστρέψαντες εἰς τὸν οἶκον οἱ πεμφθέντες, εὗρον τὸν δοῦλον ὑγιαίνοντα.
42007011 Καὶ ἐγένετο ἐν τῷ ἑξῆς, ἐπορεύθη εἰς πόλιν καλουμένην Ναΐν, καὶ συνεπορεύοντο αὐτῷ οἱ μαθηταὶ αὐτοῦ καὶ ὄχλος πολύς.
42007012 Ὡς δὲ ἤγγισεν τῇ πύλῃ τῆς πόλεως, καὶ ἰδοὺ ἐξεκομίζετο τεθνηκὼς μονογενὴς υἱὸς τῇ μητρὶ αὐτοῦ, καὶ αὐτὴ ἦν χήρα, καὶ ὄχλος τῆς πόλεως ἱκανὸς ἦν σὺν αὐτῇ.
42007013 Καὶ ἰδὼν αὐτὴν, ὁ ˚Κύριος ἐσπλαγχνίσθη ἐπʼ αὐτῇ, καὶ εἶπεν αὐτῇ, “Μὴ κλαῖε.”
42007014 Καὶ προσελθὼν, ἥψατο τῆς σοροῦ, οἱ δὲ βαστάζοντες ἔστησαν, καὶ εἶπεν, “Νεανίσκε, σοὶ λέγω, ‘Ἐγέρθητι!’”
42007015 Καὶ ἀνεκάθισεν ὁ νεκρὸς καὶ ἤρξατο λαλεῖν, καὶ ἔδωκεν αὐτὸν τῇ μητρὶ αὐτοῦ.
42007016 Ἔλαβεν δὲ φόβος πάντας, καὶ ἐδόξαζον τὸν ˚Θεὸν λέγοντες, ὅτι “Προφήτης μέγας ἠγέρθη ἐν ἡμῖν!” Καὶ, ὅτι “Ἐπεσκέψατο ὁ ˚Θεὸς τὸν λαὸν αὐτοῦ!”
42007017 Καὶ ἐξῆλθεν ὁ λόγος οὗτος ἐν ὅλῃ τῇ Ἰουδαίᾳ περὶ αὐτοῦ, καὶ πάσῃ τῇ περιχώρῳ.
42007018 Καὶ ἀπήγγειλαν Ἰωάννῃ οἱ μαθηταὶ αὐτοῦ περὶ πάντων τούτων. Καὶ προσκαλεσάμενος δύο τινὰς τῶν μαθητῶν αὐτοῦ, ὁ Ἰωάννης
42007019 ἔπεμψεν πρὸς τὸν ˚Ἰησοῦν λέγων, “Σὺ εἶ ὁ ἐρχόμενος ἢ ἕτερον προσδοκῶμεν;”
42007020 Παραγενόμενοι δὲ πρὸς αὐτὸν, οἱ ἄνδρες εἶπαν, “Ἰωάννης ὁ Βαπτιστὴς ἀπέστειλεν ἡμᾶς πρὸς σὲ λέγων, ‘Σὺ εἶ ὁ ἐρχόμενος ἢ ἄλλον προσδοκῶμεν;’”
42007021 Ἐν ἐκείνῃ τῇ ὥρᾳ, ἐθεράπευσεν πολλοὺς ἀπὸ νόσων, καὶ μαστίγων, καὶ πνευμάτων πονηρῶν καὶ τυφλοῖς πολλοῖς ἐχαρίσατο βλέπειν.
42007022 Καὶ ἀποκριθεὶς εἶπεν αὐτοῖς, “Πορευθέντες, ἀπαγγείλατε Ἰωάννῃ ἃ εἴδετε καὶ ἠκούσατε: ‘τυφλοὶ ἀναβλέπουσιν’, χωλοὶ περιπατοῦσιν, λεπροὶ καθαρίζονται, καὶ κωφοὶ ἀκούουσιν, νεκροὶ ἐγείρονται, ‘πτωχοὶ εὐαγγελίζονται’.
42007023 Καὶ μακάριός ἐστιν ὃς ἐὰν μὴ σκανδαλισθῇ ἐν ἐμοί.”
42007024 Ἀπελθόντων δὲ τῶν ἀγγέλων Ἰωάννου, ἤρξατο λέγειν πρὸς τοὺς ὄχλους περὶ Ἰωάννου, “Τί ἐξήλθατε εἰς τὴν ἔρημον θεάσασθαι; Κάλαμον ὑπὸ ἀνέμου σαλευόμενον;
42007025 Ἀλλὰ τί ἐξήλθατε ἰδεῖν; Ἄνθρωπον ἐν μαλακοῖς ἱματίοις ἠμφιεσμένον; Ἰδοὺ, οἱ ἐν ἱματισμῷ ἐνδόξῳ καὶ τρυφῇ ὑπάρχοντες ἐν τοῖς βασιλείοις εἰσίν.
42007026 Ἀλλὰ τί ἐξήλθατε ἰδεῖν; Προφήτην; Ναί, λέγω ὑμῖν, καὶ περισσότερον προφήτου.
42007027 Οὗτός ἐστιν περὶ οὗ γέγραπται, ‘Ἰδοὺ, ἀποστέλλω τὸν ἄγγελόν μου πρὸ προσώπου σου, ὃς κατασκευάσει τὴν ὁδόν σου ἔμπροσθέν σου.’
42007028 Λέγω ὑμῖν, μείζων ἐν γεννητοῖς γυναικῶν Ἰωάννου οὐδείς ἐστιν· ὁ δὲ μικρότερος ἐν τῇ Βασιλείᾳ τοῦ ˚Θεοῦ μείζων αὐτοῦ ἐστιν.”
42007029 ( Καὶ πᾶς ὁ λαὸς ἀκούσας καὶ οἱ τελῶναι, ἐδικαίωσαν τὸν ˚Θεόν, βαπτισθέντες τὸ βάπτισμα Ἰωάννου·
42007030 οἱ δὲ Φαρισαῖοι καὶ οἱ νομικοὶ, τὴν βουλὴν τοῦ ˚Θεοῦ ἠθέτησαν εἰς ἑαυτούς, μὴ βαπτισθέντες ὑπʼ αὐτοῦ. )
42007031 “Τίνι οὖν ὁμοιώσω τοὺς ἀνθρώπους τῆς γενεᾶς ταύτης, καὶ τίνι εἰσὶν ὅμοιοι;
42007032 Ὅμοιοί εἰσιν παιδίοις τοῖς ἐν ἀγορᾷ καθημένοις, καὶ προσφωνοῦσιν ἀλλήλοις, ἃ λέγει, ‘Ηὐλήσαμεν ὑμῖν καὶ οὐκ ὠρχήσασθε· ἐθρηνήσαμεν καὶ οὐκ ἐκλαύσατε.’
42007033 Ἐλήλυθεν γὰρ Ἰωάννης ὁ Βαπτιστὴς, μὴ ἐσθίων ἄρτον μήτε πίνων οἶνον, καὶ λέγετε, ‘Δαιμόνιον ἔχει.’
42007034 Ἐλήλυθεν ὁ Υἱὸς τοῦ Ἀνθρώπου, ἐσθίων καὶ πίνων, καὶ λέγετε, ‘Ἰδοὺ, ἄνθρωπος φάγος καὶ οἰνοπότης, φίλος τελωνῶν καὶ ἁμαρτωλῶν.’
42007035 Καὶ ἐδικαιώθη ἡ σοφία ἀπὸ πάντων τῶν τέκνων αὐτῆς.”
42007036 Ἠρώτα δέ τις αὐτὸν τῶν Φαρισαίων ἵνα φάγῃ μετʼ αὐτοῦ· καὶ εἰσελθὼν εἰς τὸν οἶκον τοῦ Φαρισαίου, κατεκλίθη.
42007037 Καὶ ἰδοὺ γυνὴ ἥτις ἦν ἐν τῇ πόλει ἁμαρτωλός, καὶ ἐπιγνοῦσα ὅτι κατάκειται ἐν τῇ οἰκίᾳ τοῦ Φαρισαίου, κομίσασα ἀλάβαστρον μύρου,
42007038 καὶ στᾶσα ὀπίσω παρὰ τοὺς πόδας αὐτοῦ κλαίουσα, τοῖς δάκρυσιν ἤρξατο βρέχειν τοὺς πόδας αὐτοῦ, καὶ ταῖς θριξὶν τῆς κεφαλῆς αὐτῆς ἐξέμασσεν, καὶ κατεφίλει τοὺς πόδας αὐτοῦ, καὶ ἤλειφεν τῷ μύρῳ.
42007039 Ἰδὼν δὲ ὁ Φαρισαῖος, ὁ καλέσας αὐτὸν, εἶπεν ἐν ἑαυτῷ λέγων, “Οὗτος εἰ ἦν προφήτης, ἐγίνωσκεν ἂν τίς καὶ ποταπὴ ἡ γυνὴ, ἥτις ἅπτεται αὐτοῦ, ὅτι ἁμαρτωλός ἐστιν.”
42007040 Καὶ ἀποκριθεὶς ὁ ˚Ἰησοῦς εἶπεν πρὸς αὐτόν, “Σίμων, ἔχω σοί τι εἰπεῖν.” Ὁ δέ, “Διδάσκαλε, εἰπέ”, φησίν.
42007041 “Δύο χρεοφειλέται ἦσαν δανιστῇ τινι: ὁ εἷς ὤφειλεν δηνάρια πεντακόσια, ὁ δὲ ἕτερος πεντήκοντα.
42007042 Μὴ ἐχόντων δὲ αὐτῶν ἀποδοῦναι, ἀμφοτέροις ἐχαρίσατο. Τίς οὖν αὐτῶν, πλεῖον ἀγαπήσει αὐτόν;”
42007043 Ἀποκριθεὶς δὲ Σίμων εἶπεν, “Ὑπολαμβάνω ὅτι ᾧ τὸ πλεῖον ἐχαρίσατο.” Ὁ δὲ εἶπεν αὐτῷ, “Ὀρθῶς ἔκρινας.”
42007044 Καὶ στραφεὶς πρὸς τὴν γυναῖκα, τῷ Σίμωνι ἔφη, “Βλέπεις ταύτην τὴν γυναῖκα; Εἰσῆλθόν σου εἰς τὴν οἰκίαν, ὕδωρ μοι ἐπὶ πόδας οὐκ ἔδωκας· αὕτη δὲ τοῖς δάκρυσιν ἔβρεξέν μου τοὺς πόδας, καὶ ταῖς θριξὶν αὐτῆς ἐξέμαξεν.
42007045 Φίλημά μοι οὐκ ἔδωκας· αὕτη δὲ ἀφʼ ἧς εἰσῆλθον, οὐ διέλιπεν καταφιλοῦσά μου τοὺς πόδας.
42007046 Ἐλαίῳ τὴν κεφαλήν μου οὐκ ἤλειψας· αὕτη δὲ μύρῳ ἤλειψεν τοὺς πόδας μου.
42007047 Οὗ χάριν, λέγω σοι, ἀφέωνται αἱ ἁμαρτίαι αὐτῆς αἱ πολλαί, ὅτι ἠγάπησεν πολύ· ᾧ δὲ ὀλίγον ἀφίεται, ὀλίγον ἀγαπᾷ.”
42007048 Εἶπεν δὲ αὐτῇ, “Ἀφέωνταί σου αἱ ἁμαρτίαι.”
42007049 Καὶ ἤρξαντο οἱ συνανακείμενοι λέγειν ἐν ἑαυτοῖς, “Τίς οὗτός ἐστιν ὃς καὶ ἁμαρτίας ἀφίησιν;”
42007050 Εἶπεν δὲ πρὸς τὴν γυναῖκα, “Ἡ πίστις σου σέσωκέν σε· πορεύου εἰς εἰρήνην.”
42008001 Καὶ ἐγένετο ἐν τῷ καθεξῆς, καὶ αὐτὸς διώδευεν κατὰ πόλιν καὶ κώμην, κηρύσσων καὶ εὐαγγελιζόμενος τὴν Βασιλείαν τοῦ ˚Θεοῦ, καὶ οἱ δώδεκα σὺν αὐτῷ,
42008002 καὶ γυναῖκές τινες αἳ ἦσαν τεθεραπευμέναι ἀπὸ πνευμάτων πονηρῶν καὶ ἀσθενειῶν: Μαρία ἡ καλουμένη Μαγδαληνή, ἀφʼ ἧς δαιμόνια ἑπτὰ ἐξεληλύθει,
42008003 καὶ Ἰωάννα γυνὴ Χουζᾶ ἐπιτρόπου Ἡρῴδου, καὶ Σουσάννα, καὶ ἕτεραι πολλαί, αἵτινες διηκόνουν αὐτοῖς ἐκ τῶν ὑπαρχόντων αὐταῖς.
42008004 Συνιόντος δὲ ὄχλου πολλοῦ καὶ τῶν κατὰ πόλιν ἐπιπορευομένων πρὸς αὐτὸν, εἶπεν διὰ παραβολῆς:
42008005 “Ἐξῆλθεν ὁ σπείρων τοῦ σπεῖραι τὸν σπόρον αὐτοῦ. Καὶ ἐν τῷ σπείρειν αὐτὸν, ὃ μὲν ἔπεσεν παρὰ τὴν ὁδόν, καὶ κατεπατήθη καὶ τὰ πετεινὰ τοῦ οὐρανοῦ κατέφαγεν αὐτό.
42008006 Καὶ ἕτερον κατέπεσεν ἐπὶ τὴν πέτραν, καὶ φυὲν ἐξηράνθη διὰ τὸ μὴ ἔχειν ἰκμάδα.
42008007 Καὶ ἕτερον ἔπεσεν ἐν μέσῳ τῶν ἀκανθῶν, καὶ συμφυεῖσαι αἱ ἄκανθαι ἀπέπνιξαν αὐτό.
42008008 Καὶ ἕτερον ἔπεσεν εἰς τὴν γῆν τὴν ἀγαθήν, καὶ φυὲν ἐποίησεν καρπὸν ἑκατονταπλασίονα.” Ταῦτα λέγων, ἐφώνει, “Ὁ ἔχων ὦτα ἀκούειν, ἀκουέτω.”
42008009 Ἐπηρώτων δὲ αὐτὸν οἱ μαθηταὶ αὐτοῦ, “Τίς αὕτη εἴη ἡ παραβολή;”
42008010 Ὁ δὲ εἶπεν, “Ὑμῖν δέδοται γνῶναι τὰ μυστήρια τῆς Βασιλείας τοῦ ˚Θεοῦ, τοῖς δὲ λοιποῖς ἐν παραβολαῖς, ἵνα ‘βλέποντες μὴ βλέπωσιν, καὶ ἀκούοντες μὴ συνιῶσιν’.
42008011 Ἔστιν δὲ αὕτη ἡ παραβολή: ὁ σπόρος ἐστὶν ὁ λόγος τοῦ ˚Θεοῦ.
42008012 Οἱ δὲ παρὰ τὴν ὁδόν εἰσιν οἱ ἀκούσαντες, εἶτα ἔρχεται ὁ διάβολος καὶ αἴρει τὸν λόγον ἀπὸ τῆς καρδίας αὐτῶν, ἵνα μὴ πιστεύσαντες σωθῶσιν.
42008013 Οἱ δὲ ἐπὶ τῆς πέτρας, οἳ ὅταν ἀκούσωσιν μετὰ χαρᾶς δέχονται τὸν λόγον, καὶ οὗτοι ῥίζαν οὐκ ἔχουσιν, οἳ πρὸς καιρὸν πιστεύουσιν, καὶ ἐν καιρῷ πειρασμοῦ ἀφίστανται.
42008014 Τὸ δὲ εἰς τὰς ἀκάνθας πεσόν, οὗτοί εἰσιν οἱ ἀκούσαντες, καὶ ὑπὸ μεριμνῶν, καὶ πλούτου, καὶ ἡδονῶν τοῦ βίου, πορευόμενοι συμπνίγονται καὶ οὐ τελεσφοροῦσιν.
42008015 Τὸ δὲ ἐν τῇ καλῇ γῇ, οὗτοί εἰσιν οἵτινες ἐν καρδίᾳ καλῇ καὶ ἀγαθῇ, ἀκούσαντες τὸν λόγον κατέχουσιν, καὶ καρποφοροῦσιν ἐν ὑπομονῇ.
42008016 Οὐδεὶς δὲ λύχνον ἅψας, καλύπτει αὐτὸν σκεύει ἢ ὑποκάτω κλίνης τίθησιν, ἀλλʼ ἐπὶ λυχνίας τίθησιν, ἵνα οἱ εἰσπορευόμενοι βλέπωσιν τὸ φῶς.
42008017 Οὐ γάρ ἐστιν κρυπτὸν ὃ οὐ φανερὸν γενήσεται, οὐδὲ ἀπόκρυφον ὃ οὐ μὴ γνωσθῇ καὶ εἰς φανερὸν ἔλθῃ.
42008018 Βλέπετε οὖν πῶς ἀκούετε· ὃς ἂν γὰρ ἔχῃ, δοθήσεται αὐτῷ, καὶ ὃς ἂν μὴ ἔχῃ, καὶ ὃ δοκεῖ ἔχειν ἀρθήσεται ἀπʼ αὐτοῦ.”
42008019 Παρεγένετο δὲ πρὸς αὐτὸν ἡ μήτηρ καὶ οἱ ἀδελφοὶ αὐτοῦ, καὶ οὐκ ἠδύναντο συντυχεῖν αὐτῷ διὰ τὸν ὄχλον.
42008020 Ἀπηγγέλη δὲ αὐτῷ, “Ἡ μήτηρ σου καὶ οἱ ἀδελφοί σου, ἑστήκασιν ἔξω ἰδεῖν θέλοντές σε.”
42008021 Ὁ δὲ ἀποκριθεὶς εἶπεν πρὸς αὐτούς, “Μήτηρ μου καὶ ἀδελφοί μου, οὗτοί εἰσιν οἱ τὸν λόγον τοῦ ˚Θεοῦ ἀκούοντες καὶ ποιοῦντες.”
42008022 Ἐγένετο δὲ ἐν μιᾷ τῶν ἡμερῶν, καὶ αὐτὸς ἐνέβη εἰς πλοῖον καὶ οἱ μαθηταὶ αὐτοῦ, καὶ εἶπεν πρὸς αὐτούς, “Διέλθωμεν εἰς τὸ πέραν τῆς λίμνης”. Καὶ ἀνήχθησαν.
42008023 Πλεόντων δὲ αὐτῶν, ἀφύπνωσεν. Καὶ κατέβη λαῖλαψ ἀνέμου εἰς τὴν λίμνην, καὶ συνεπληροῦντο καὶ ἐκινδύνευον.
42008024 Προσελθόντες δὲ, διήγειραν αὐτὸν λέγοντες, “Ἐπιστάτα, Ἐπιστάτα, ἀπολλύμεθα!” Ὁ δὲ διεγερθεὶς, ἐπετίμησεν τῷ ἀνέμῳ, καὶ τῷ κλύδωνι, τοῦ ὕδατος, καὶ ἐπαύσαντο καὶ ἐγένετο γαλήνη.
42008025 Εἶπεν δὲ αὐτοῖς, “Ποῦ ἡ πίστις ὑμῶν;” Φοβηθέντες δὲ, ἐθαύμασαν λέγοντες πρὸς ἀλλήλους, “Τίς ἄρα οὗτός ἐστιν, ὅτι καὶ τοῖς ἀνέμοις ἐπιτάσσει καὶ τῷ ὕδατι;” καὶ ὑπακούουσιν αὐτῷ;”
42008026 Καὶ κατέπλευσαν εἰς τὴν χώραν τῶν Γερασηνῶν, ἥτις ἐστὶν ἀντιπέρα τῆς Γαλιλαίας.
42008027 Ἐξελθόντι δὲ αὐτῷ ἐπὶ τὴν γῆν, ὑπήντησεν ἀνήρ τις ἐκ τῆς πόλεως ἔχων δαιμόνια, καὶ χρόνῳ ἱκανῷ οὐκ ἐνεδύσατο ἱμάτιον, καὶ ἐν οἰκίᾳ οὐκ ἔμενεν, ἀλλʼ ἐν τοῖς μνήμασιν.
42008028 Ἰδὼν δὲ τὸν ˚Ἰησοῦν, ἀνακράξας προσέπεσεν αὐτῷ, καὶ φωνῇ μεγάλῃ εἶπεν, “Τί ἐμοὶ καὶ σοί, ˚Ἰησοῦ, Υἱὲ τοῦ ˚Θεοῦ τοῦ Ὑψίστου; Δέομαί σου, μή με βασανίσῃς!”
42008029 Παρήγγειλεν γὰρ τῷ πνεύματι τῷ ἀκαθάρτῳ ἐξελθεῖν ἀπὸ τοῦ ἀνθρώπου. Πολλοῖς γὰρ χρόνοις συνηρπάκει αὐτόν, καὶ ἐδεσμεύετο ἁλύσεσιν καὶ πέδαις, φυλασσόμενος, καὶ διαρρήσσων τὰ δεσμὰ, ἠλαύνετο ὑπὸ τοῦ δαιμονίου εἰς τὰς ἐρήμους.
42008030 Ἐπηρώτησεν δὲ αὐτὸν ὁ ˚Ἰησοῦς, “Τί σοι ὄνομά ἐστιν;” Ὁ δὲ εἶπεν, “Λεγιών”, ὅτι εἰσῆλθεν δαιμόνια πολλὰ εἰς αὐτόν.
42008031 Καὶ παρεκάλουν αὐτὸν ἵνα μὴ ἐπιτάξῃ αὐτοῖς εἰς τὴν Ἄβυσσον ἀπελθεῖν.
42008032 Ἦν δὲ ἐκεῖ ἀγέλη χοίρων ἱκανῶν βοσκομένη ἐν τῷ ὄρει, καὶ παρεκάλεσαν αὐτὸν ἵνα ἐπιτρέψῃ αὐτοῖς εἰς ἐκείνους εἰσελθεῖν. Καὶ ἐπέτρεψεν αὐτοῖς.
42008033 Ἐξελθόντα δὲ τὰ δαιμόνια ἀπὸ τοῦ ἀνθρώπου, εἰσῆλθον εἰς τοὺς χοίρους, καὶ ὥρμησεν ἡ ἀγέλη κατὰ τοῦ κρημνοῦ εἰς τὴν λίμνην, καὶ ἀπεπνίγη.
42008034 Ἰδόντες δὲ οἱ βόσκοντες, τὸ γεγονὸς, ἔφυγον καὶ ἀπήγγειλαν εἰς τὴν πόλιν καὶ εἰς τοὺς ἀγρούς.
42008035 Ἐξῆλθον δὲ ἰδεῖν τὸ γεγονὸς, καὶ ἦλθαν πρὸς τὸν ˚Ἰησοῦν, καὶ εὗρον καθήμενον τὸν ἄνθρωπον, ἀφʼ οὗ τὰ δαιμόνια ἐξῆλθεν, ἱματισμένον καὶ σωφρονοῦντα παρὰ τοὺς πόδας τοῦ ˚Ἰησοῦ, καὶ ἐφοβήθησαν.
42008036 Ἀπήγγειλαν δὲ αὐτοῖς οἱ ἰδόντες, πῶς ἐσώθη ὁ δαιμονισθείς.
42008037 Καὶ ἠρώτησεν αὐτὸν ἅπαν τὸ πλῆθος τῆς περιχώρου τῶν Γερασηνῶν, ἀπελθεῖν ἀπʼ αὐτῶν, ὅτι φόβῳ μεγάλῳ συνείχοντο. Αὐτὸς δὲ ἐμβὰς, εἰς πλοῖον, ὑπέστρεψεν.
42008038 Ἐδεῖτο δὲ αὐτοῦ ὁ ἀνὴρ ἀφʼ οὗ ἐξεληλύθει τὰ δαιμόνια, εἶναι σὺν αὐτῷ. Ἀπέλυσεν δὲ αὐτὸν λέγων,
42008039 “Ὑπόστρεφε εἰς τὸν οἶκόν σου, καὶ διηγοῦ ὅσα σοι ἐποίησεν ὁ ˚Θεός.” Καὶ ἀπῆλθεν καθʼ ὅλην τὴν πόλιν, κηρύσσων ὅσα ἐποίησεν αὐτῷ ὁ ˚Ἰησοῦς.
42008040 Ἐν δὲ τῷ ὑποστρέφειν τὸν ˚Ἰησοῦν, ἀπεδέξατο αὐτὸν ὁ ὄχλος, ἦσαν γὰρ πάντες προσδοκῶντες αὐτόν.
42008041 Καὶ ἰδοὺ, ἦλθεν ἀνὴρ ᾧ ὄνομα Ἰάειρος, καὶ οὗτος ἄρχων τῆς συναγωγῆς ὑπῆρχεν, καὶ πεσὼν παρὰ τοὺς πόδας ˚Ἰησοῦ, παρεκάλει αὐτὸν εἰσελθεῖν εἰς τὸν οἶκον αὐτοῦ,
42008042 ὅτι θυγάτηρ μονογενὴς ἦν αὐτῷ, ὡς ἐτῶν δώδεκα, καὶ αὐτὴ ἀπέθνῃσκεν. Ἐν δὲ τῷ ὑπάγειν αὐτὸν, οἱ ὄχλοι συνέπνιγον αὐτόν.
42008043 Καὶ γυνὴ οὖσα ἐν ῥύσει αἵματος ἀπὸ ἐτῶν δώδεκα, ἥτις οὐκ ἴσχυσεν ἀπʼ οὐδενὸς θεραπευθῆναι,
42008044 προσελθοῦσα ὄπισθεν, ἥψατο τοῦ κρασπέδου τοῦ ἱματίου αὐτοῦ, καὶ παραχρῆμα ἔστη ἡ ῥύσις τοῦ αἵματος αὐτῆς.
42008045 Καὶ εἶπεν ὁ ˚Ἰησοῦς, “Τίς ὁ ἁψάμενός μου;” Ἀρνουμένων δὲ πάντων, εἶπεν ὁ Πέτρος, “Ἐπιστάτα, οἱ ὄχλοι συνέχουσίν σε καὶ ἀποθλίβουσιν.”
42008046 Ὁ δὲ ˚Ἰησοῦς εἶπεν, “Ἥψατό μού τις, ἐγὼ γὰρ ἔγνων δύναμιν ἐξεληλυθυῖαν ἀπʼ ἐμοῦ.”
42008047 Ἰδοῦσα δὲ ἡ γυνὴ ὅτι οὐκ ἔλαθεν, τρέμουσα ἦλθεν καὶ προσπεσοῦσα αὐτῷ, διʼ ἣν αἰτίαν ἥψατο αὐτοῦ, ἀπήγγειλεν ἐνώπιον παντὸς τοῦ λαοῦ, καὶ ὡς ἰάθη παραχρῆμα.
42008048 Ὁ δὲ εἶπεν αὐτῇ, “Θύγατερ, ἡ πίστις σου σέσωκέν σε· πορεύου εἰς εἰρήνην.”
42008049 Ἔτι αὐτοῦ λαλοῦντος, ἔρχεταί τις παρὰ τοῦ ἀρχισυναγώγου λέγων, ὅτι “Τέθνηκεν ἡ θυγάτηρ σου, μηκέτι σκύλλε τὸν διδάσκαλον.”
42008050 Ὁ δὲ ˚Ἰησοῦς ἀκούσας, ἀπεκρίθη αὐτῷ, “Μὴ φοβοῦ, μόνον πίστευε, καὶ σωθήσεται.”
42008051 Ἐλθὼν δὲ εἰς τὴν οἰκίαν, οὐκ ἀφῆκεν εἰσελθεῖν τινα σὺν αὐτῷ, εἰ μὴ Πέτρον, καὶ Ἰωάννην, καὶ Ἰάκωβον, καὶ τὸν πατέρα τῆς παιδὸς, καὶ τὴν μητέρα.
42008052 Ἔκλαιον δὲ πάντες καὶ ἐκόπτοντο αὐτήν. Ὁ δὲ εἶπεν, “Μὴ κλαίετε, οὐ γὰρ ἀπέθανεν, ἀλλὰ καθεύδει.”
42008053 Καὶ κατεγέλων αὐτοῦ, εἰδότες ὅτι ἀπέθανεν.
42008054 Αὐτὸς δὲ κρατήσας τῆς χειρὸς αὐτῆς, ἐφώνησεν λέγων, “Ἡ παῖς, ἔγειρε!”
42008055 Καὶ ἐπέστρεψεν τὸ πνεῦμα αὐτῆς, καὶ ἀνέστη παραχρῆμα, καὶ διέταξεν αὐτῇ δοθῆναι φαγεῖν.
42008056 Καὶ ἐξέστησαν οἱ γονεῖς αὐτῆς· ὁ δὲ παρήγγειλεν αὐτοῖς μηδενὶ εἰπεῖν τὸ γεγονός.
42009001 Συγκαλεσάμενος δὲ τοὺς δώδεκα, ἔδωκεν αὐτοῖς δύναμιν καὶ ἐξουσίαν ἐπὶ πάντα τὰ δαιμόνια, καὶ νόσους θεραπεύειν,
42009002 καὶ ἀπέστειλεν αὐτοὺς κηρύσσειν τὴν Βασιλείαν τοῦ ˚Θεοῦ, καὶ ἰᾶσθαι τοὺς ἀσθενεῖς.
42009003 Καὶ εἶπεν πρὸς αὐτούς, “Μηδὲν αἴρετε εἰς τὴν ὁδόν, μήτε ῥάβδον, μήτε πήραν, μήτε ἄρτον, μήτε ἀργύριον, μήτε δύο χιτῶνας ἔχειν.
42009004 Καὶ εἰς ἣν ἂν οἰκίαν εἰσέλθητε, ἐκεῖ μένετε καὶ ἐκεῖθεν ἐξέρχεσθε.
42009005 Καὶ ὅσοι ἂν μὴ δέχωνται ὑμᾶς, ἐξερχόμενοι ἀπὸ τῆς πόλεως ἐκείνης, τὸν κονιορτὸν ἀπὸ τῶν ποδῶν ὑμῶν ἀποτινάσσετε, εἰς μαρτύριον ἐπʼ αὐτούς.”
42009006 Ἐξερχόμενοι δὲ, διήρχοντο κατὰ τὰς κώμας, εὐαγγελιζόμενοι καὶ θεραπεύοντες πανταχοῦ.
42009007 Ἤκουσεν δὲ Ἡρῴδης ὁ τετραάρχης τὰ γινόμενα πάντα, καὶ διηπόρει, διὰ τὸ λέγεσθαι ὑπό τινων ὅτι Ἰωάννης ἠγέρθη ἐκ νεκρῶν,
42009008 ὑπό τινων δὲ ὅτι Ἠλίας ἐφάνη, ἄλλων δὲ ὅτι προφήτης τις τῶν ἀρχαίων ἀνέστη.
42009009 Εἶπεν δὲ Ἡρῴδης, “Ἰωάννην ἐγὼ ἀπεκεφάλισα, τίς δέ ἐστιν οὗτος περὶ οὗ ἀκούω τοιαῦτα;” Καὶ ἐζήτει ἰδεῖν αὐτόν.
42009010 Καὶ ὑποστρέψαντες, οἱ ἀπόστολοι διηγήσαντο αὐτῷ ὅσα ἐποίησαν. Καὶ παραλαβὼν αὐτοὺς, ὑπεχώρησεν κατʼ ἰδίαν εἰς πόλιν καλουμένην Βηθσαϊδά.
42009011 Οἱ δὲ ὄχλοι γνόντες, ἠκολούθησαν αὐτῷ. Καὶ ἀποδεξάμενος αὐτοὺς, ἐλάλει αὐτοῖς περὶ τῆς Βασιλείας τοῦ ˚Θεοῦ, καὶ τοὺς χρείαν ἔχοντας θεραπείας, ἰᾶτο.
42009012 Ἡ δὲ ἡμέρα ἤρξατο κλίνειν, προσελθόντες δὲ οἱ δώδεκα εἶπον αὐτῷ, “Ἀπόλυσον τὸν ὄχλον, ἵνα πορευθέντες εἰς τὰς κύκλῳ κώμας καὶ ἀγροὺς, καταλύσωσιν καὶ εὕρωσιν ἐπισιτισμόν, ὅτι ὧδε ἐν ἐρήμῳ τόπῳ ἐσμέν.”
42009013 Εἶπεν δὲ πρὸς αὐτούς, “Δότε αὐτοῖς ὑμεῖς φαγεῖν.” Οἱ δὲ εἶπαν, “Οὐκ εἰσὶν ἡμῖν πλεῖον ἢ ἄρτοι πέντε καὶ ἰχθύες δύο, εἰ μήτι πορευθέντες, ἡμεῖς ἀγοράσωμεν εἰς πάντα τὸν λαὸν τοῦτον βρώματα.”
42009014 ( Ἦσαν γὰρ ὡσεὶ ἄνδρες πεντακισχίλιοι. ) Εἶπεν δὲ πρὸς τοὺς μαθητὰς αὐτοῦ, “Κατακλίνατε αὐτοὺς κλισίας ὡσεὶ ἀνὰ πεντήκοντα.”
42009015 Καὶ ἐποίησαν οὕτως, καὶ κατέκλιναν ἅπαντας.
42009016 Λαβὼν δὲ τοὺς πέντε ἄρτους καὶ τοὺς δύο ἰχθύας, ἀναβλέψας εἰς τὸν οὐρανὸν, εὐλόγησεν αὐτοὺς καὶ κατέκλασεν, καὶ ἐδίδου τοῖς μαθηταῖς παραθεῖναι τῷ ὄχλῳ.
42009017 Καὶ ἔφαγον καὶ ἐχορτάσθησαν πάντες, καὶ ἤρθη τὸ περισσεῦσαν αὐτοῖς κλασμάτων κόφινοι δώδεκα.
42009018 Καὶ ἐγένετο ἐν τῷ εἶναι αὐτὸν προσευχόμενον κατὰ μόνας, συνῆσαν αὐτῷ οἱ μαθηταί, καὶ ἐπηρώτησεν αὐτοὺς λέγων, “Τίνα με λέγουσιν οἱ ὄχλοι εἶναι;”
42009019 Οἱ δὲ ἀποκριθέντες εἶπαν, “Ἰωάννην τὸν Βαπτιστήν, ἄλλοι δὲ, Ἠλίαν, ἄλλοι δὲ ὅτι προφήτης τις τῶν ἀρχαίων ἀνέστη.”
42009020 Εἶπεν δὲ αὐτοῖς, “Ὑμεῖς δὲ τίνα με λέγετε εἶναι;” Πέτρος δὲ ἀποκριθεὶς εἶπεν, “Τὸν ˚Χριστὸν τοῦ ˚Θεοῦ.”
42009021 Ὁ δὲ, ἐπιτιμήσας αὐτοῖς, παρήγγειλεν μηδενὶ λέγειν τοῦτο,
42009022 εἰπὼν, ὅτι “Δεῖ τὸν Υἱὸν τοῦ Ἀνθρώπου πολλὰ παθεῖν, καὶ ἀποδοκιμασθῆναι ἀπὸ τῶν πρεσβυτέρων, καὶ ἀρχιερέων, καὶ γραμματέων, καὶ ἀποκτανθῆναι καὶ τῇ τρίτῃ ἡμέρᾳ ἐγερθῆναι.”
42009023 Ἔλεγεν δὲ πρὸς πάντας, “Εἴ τις θέλει ὀπίσω μου ἔρχεσθαι, ἀπαρνησάσθω ἑαυτὸν, καὶ ἀράτω τὸν σταυρὸν αὐτοῦ καθʼ ἡμέραν, καὶ ἀκολουθείτω μοι.
42009024 Ὃς γὰρ ἂν θέλῃ τὴν ψυχὴν αὐτοῦ σῶσαι, ἀπολέσει αὐτήν· ὃς δʼ ἂν ἀπολέσῃ τὴν ψυχὴν αὐτοῦ ἕνεκεν ἐμοῦ, οὗτος σώσει αὐτήν.
42009025 Τί γὰρ ὠφελεῖται ἄνθρωπος, κερδήσας τὸν κόσμον ὅλον, ἑαυτὸν δὲ ἀπολέσας ἢ ζημιωθείς;
42009026 Ὃς γὰρ ἂν ἐπαισχυνθῇ με καὶ τοὺς ἐμοὺς λόγους, τοῦτον ὁ Υἱὸς τοῦ Ἀνθρώπου ἐπαισχυνθήσεται, ὅταν ἔλθῃ ἐν τῇ δόξῃ αὐτοῦ, καὶ τοῦ Πατρὸς, καὶ τῶν ἁγίων ἀγγέλων.
42009027 Λέγω δὲ ὑμῖν ἀληθῶς, εἰσίν τινες τῶν αὐτοῦ ἑστηκότων, οἳ οὐ μὴ γεύσωνται θανάτου, ἕως ἂν ἴδωσιν τὴν Βασιλείαν τοῦ ˚Θεοῦ.”
42009028 Ἐγένετο δὲ μετὰ τοὺς λόγους τούτους ὡσεὶ ἡμέραι ὀκτὼ, παραλαβὼν Πέτρον, καὶ Ἰωάννην, καὶ Ἰάκωβον, ἀνέβη εἰς τὸ ὄρος προσεύξασθαι.
42009029 Καὶ ἐγένετο ἐν τῷ προσεύχεσθαι αὐτὸν, τὸ εἶδος τοῦ προσώπου αὐτοῦ ἕτερον, καὶ ὁ ἱματισμὸς αὐτοῦ λευκὸς ἐξαστράπτων.
42009030 Καὶ ἰδοὺ, ἄνδρες δύο συνελάλουν αὐτῷ, οἵτινες ἦσαν Μωϋσῆς καὶ Ἠλίας,
42009031 οἳ ὀφθέντες ἐν δόξῃ, ἔλεγον τὴν ἔξοδον αὐτοῦ, ἣν ἤμελλεν πληροῦν ἐν Ἰερουσαλήμ.
42009032 Ὁ δὲ Πέτρος καὶ οἱ σὺν αὐτῷ ἦσαν βεβαρημένοι ὕπνῳ· διαγρηγορήσαντες δὲ εἶδον τὴν δόξαν αὐτοῦ, καὶ τοὺς δύο ἄνδρας τοὺς συνεστῶτας αὐτῷ.
42009033 Καὶ ἐγένετο ἐν τῷ διαχωρίζεσθαι αὐτοὺς ἀπʼ αὐτοῦ, εἶπεν ὁ Πέτρος πρὸς τὸν ˚Ἰησοῦν, “Ἐπιστάτα, καλόν ἐστιν ἡμᾶς ὧδε εἶναι, καὶ ποιήσωμεν σκηνὰς τρεῖς: μίαν σοὶ, καὶ μίαν Μωϋσεῖ, καὶ μίαν Ἠλίᾳ”, μὴ εἰδὼς ὃ λέγει.
42009034 Ταῦτα δὲ αὐτοῦ λέγοντος, ἐγένετο νεφέλη καὶ ἐπεσκίαζεν αὐτούς· ἐφοβήθησαν δὲ ἐν τῷ εἰσελθεῖν αὐτοὺς εἰς τὴν νεφέλην.
42009035 Καὶ φωνὴ ἐγένετο ἐκ τῆς νεφέλης λέγουσα, “Οὗτός ἐστιν ὁ Υἱός μου, ὁ ἐκλελεγμένος, αὐτοῦ ἀκούετε.”
42009036 Καὶ ἐν τῷ γενέσθαι τὴν φωνὴν, εὑρέθη ˚Ἰησοῦς μόνος. Καὶ αὐτοὶ ἐσίγησαν, καὶ οὐδενὶ ἀπήγγειλαν ἐν ἐκείναις ταῖς ἡμέραις οὐδὲν ὧν ἑώρακαν.
42009037 Ἐγένετο δὲ τῇ ἑξῆς ἡμέρᾳ, κατελθόντων αὐτῶν ἀπὸ τοῦ ὄρους, συνήντησεν αὐτῷ ὄχλος πολύς.
42009038 Καὶ ἰδοὺ, ἀνὴρ ἀπὸ τοῦ ὄχλου ἐβόησεν λέγων, “Διδάσκαλε, δέομαί σου ἐπιβλέψαι ἐπὶ τὸν υἱόν μου, ὅτι μονογενής μοί ἐστιν.”
42009039 Καὶ ἰδοὺ, πνεῦμα λαμβάνει αὐτόν, καὶ ἐξαίφνης κράζει καὶ σπαράσσει αὐτὸν μετὰ ἀφροῦ, καὶ μόγις ἀποχωρεῖ ἀπʼ αὐτοῦ, συντρῖβον αὐτόν.
42009040 Καὶ ἐδεήθην τῶν μαθητῶν σου ἵνα ἐκβάλωσιν αὐτό, καὶ οὐκ ἠδυνήθησαν.
42009041 Ἀποκριθεὶς δὲ ὁ ˚Ἰησοῦς εἶπεν, “Ὦ γενεὰ ἄπιστος καὶ διεστραμμένη, ἕως πότε ἔσομαι πρὸς ὑμᾶς καὶ ἀνέξομαι ὑμῶν; Προσάγαγε ὧδε τὸν υἱόν σου.”
42009042 Ἔτι δὲ προσερχομένου αὐτοῦ, ἔρρηξεν αὐτὸν τὸ δαιμόνιον καὶ συνεσπάραξεν. Ἐπετίμησεν δὲ ὁ ˚Ἰησοῦς τῷ πνεύματι τῷ ἀκαθάρτῳ, καὶ ἰάσατο τὸν παῖδα καὶ ἀπέδωκεν αὐτὸν τῷ πατρὶ αὐτοῦ.
42009043 Ἐξεπλήσσοντο δὲ πάντες ἐπὶ τῇ μεγαλειότητι τοῦ ˚Θεοῦ. Πάντων δὲ θαυμαζόντων ἐπὶ πᾶσιν οἷς ἐποίει, εἶπεν πρὸς τοὺς μαθητὰς αὐτοῦ,
42009044 “Θέσθε ὑμεῖς εἰς τὰ ὦτα ὑμῶν τοὺς λόγους τούτους, ὁ γὰρ Υἱὸς τοῦ Ἀνθρώπου μέλλει παραδίδοσθαι εἰς χεῖρας ἀνθρώπων.”
42009045 Οἱ δὲ ἠγνόουν τὸ ῥῆμα τοῦτο, καὶ ἦν παρακεκαλυμμένον ἀπʼ αὐτῶν, ἵνα μὴ αἴσθωνται αὐτό, καὶ ἐφοβοῦντο ἐρωτῆσαι αὐτὸν περὶ τοῦ ῥήματος τούτου.
42009046 Εἰσῆλθεν δὲ διαλογισμὸς ἐν αὐτοῖς, τὸ τίς ἂν εἴη μείζων αὐτῶν.
42009047 Ὁ δὲ ˚Ἰησοῦς εἰδὼς τὸν διαλογισμὸν τῆς καρδίας αὐτῶν, ἐπιλαβόμενος παιδίον, ἔστησεν αὐτὸ παρʼ ἑαυτῷ,
42009048 καὶ εἶπεν αὐτοῖς, “Ὃς ἐὰν δέξηται τοῦτο τὸ παιδίον ἐπὶ τῷ ὀνόματί μου, ἐμὲ δέχεται, καὶ ὃς ἂν ἐμὲ δέξηται, δέχεται τὸν ἀποστείλαντά με. Ὁ γὰρ μικρότερος ἐν πᾶσιν ὑμῖν ὑπάρχων, οὗτός ἐστιν μέγας.”
42009049 Ἀποκριθεὶς δὲ Ἰωάννης εἶπεν, “Ἐπιστάτα, εἴδομέν τινα ἐν τῷ ὀνόματί σου ἐκβάλλοντα δαιμόνια, καὶ ἐκωλύομεν αὐτὸν, ὅτι οὐκ ἀκολουθεῖ μεθʼ ἡμῶν.”
42009050 Εἶπεν δὲ πρὸς αὐτὸν ὁ ˚Ἰησοῦς, “Μὴ κωλύετε, ὃς γὰρ οὐκ ἔστιν καθʼ ὑμῶν, ὑπὲρ ὑμῶν ἐστιν.”
42009051 Ἐγένετο δὲ ἐν τῷ συμπληροῦσθαι τὰς ἡμέρας τῆς ἀναλήμψεως αὐτοῦ, καὶ αὐτὸς τὸ πρόσωπον ἐστήρισεν, τοῦ πορεύεσθαι εἰς Ἰερουσαλήμ,
42009052 καὶ ἀπέστειλεν ἀγγέλους πρὸ προσώπου αὐτοῦ. Καὶ πορευθέντες, εἰσῆλθον εἰς κώμην Σαμαριτῶν, ὡς ἑτοιμάσαι αὐτῷ.
42009053 Καὶ οὐκ ἐδέξαντο αὐτόν, ὅτι τὸ πρόσωπον αὐτοῦ ἦν πορευόμενον εἰς Ἰερουσαλήμ.
42009054 Ἰδόντες δὲ, οἱ μαθηταὶ Ἰάκωβος καὶ Ἰωάννης εἶπαν, “˚Κύριε, θέλεις εἴπωμεν πῦρ καταβῆναι ἀπὸ τοῦ οὐρανοῦ καὶ ἀναλῶσαι αὐτούς;”
42009055 Στραφεὶς δὲ, ἐπετίμησεν αὐτοῖς.
42009056 Καὶ ἐπορεύθησαν εἰς ἑτέραν κώμην.
42009057 Καὶ πορευομένων αὐτῶν ἐν τῇ ὁδῷ, εἶπέν τις πρὸς αὐτόν, “Ἀκολουθήσω σοι ὅπου ἐὰν ἀπέρχῃ.”
42009058 Καὶ εἶπεν αὐτῷ ὁ ˚Ἰησοῦς, “Αἱ ἀλώπεκες φωλεοὺς ἔχουσιν, καὶ τὰ πετεινὰ τοῦ οὐρανοῦ κατασκηνώσεις, ὁ δὲ Υἱὸς τοῦ Ἀνθρώπου οὐκ ἔχει ποῦ τὴν κεφαλὴν κλίνῃ.”
42009059 Εἶπεν δὲ πρὸς ἕτερον, “Ἀκολούθει μοι.” Ὁ δὲ εἶπεν, “˚Κύριε, ἐπίτρεψόν μοι ἀπελθόντι, πρῶτον θάψαι τὸν πατέρα μου.”
42009060 Εἶπεν δὲ αὐτῷ, “Ἄφες τοὺς νεκροὺς θάψαι τοὺς ἑαυτῶν νεκρούς, σὺ δὲ ἀπελθὼν, διάγγελλε τὴν Βασιλείαν τοῦ ˚Θεοῦ.”
42009061 Εἶπεν δὲ καὶ ἕτερος, “Ἀκολουθήσω σοι, ˚Κύριε, πρῶτον δὲ ἐπίτρεψόν μοι ἀποτάξασθαι τοῖς εἰς τὸν οἶκόν μου.”
42009062 Εἶπεν δὲ ὁ ˚Ἰησοῦς, “Οὐδεὶς ἐπιβαλὼν τὴν χεῖρα αὐτοῦ ἐπʼ ἄροτρον, καὶ βλέπων εἰς τὰ ὀπίσω, εὔθετός ἐστιν τῇ Βασιλείᾳ τοῦ ˚Θεοῦ.”
42010001 Μετὰ δὲ ταῦτα, ἀνέδειξεν ὁ ˚Κύριος ἑτέρους ἑβδομήκοντα δύο, καὶ ἀπέστειλεν αὐτοὺς ἀνὰ δύο πρὸ προσώπου αὐτοῦ, εἰς πᾶσαν πόλιν καὶ τόπον οὗ ἤμελλεν αὐτὸς ἔρχεσθαι.
42010002 Ἔλεγεν δὲ πρὸς αὐτούς, “Ὁ μὲν θερισμὸς πολύς, οἱ δὲ ἐργάται ὀλίγοι. Δεήθητε οὖν τοῦ ˚Κυρίου τοῦ θερισμοῦ, ὅπως ἐργάτας ἐκβάλῃ εἰς τὸν θερισμὸν αὐτοῦ.
42010003 Ὑπάγετε ἰδοὺ, ἀποστέλλω ὑμᾶς ὡς ἄρνας ἐν μέσῳ λύκων.
42010004 Μὴ βαστάζετε βαλλάντιον, μὴ πήραν, μὴ ὑποδήματα, καὶ μηδένα κατὰ τὴν ὁδὸν ἀσπάσησθε.
42010005 Εἰς ἣν δʼ ἂν εἰσέλθητε οἰκίαν, πρῶτον λέγετε, ‘Εἰρήνη τῷ οἴκῳ τούτῳ.’
42010006 Καὶ ἐὰν ἐκεῖ ᾖ υἱὸς εἰρήνης, ἐπαναπαήσεται ἐπʼ αὐτὸν ἡ εἰρήνη ὑμῶν· εἰ δὲ μή γε ἐφʼ ὑμᾶς ἀνακάμψει.
42010007 Ἐν αὐτῇ δὲ τῇ οἰκίᾳ μένετε, ἐσθίοντες καὶ πίνοντες τὰ παρʼ αὐτῶν, ἄξιος γὰρ ὁ ἐργάτης τοῦ μισθοῦ αὐτοῦ. Μὴ μεταβαίνετε ἐξ οἰκίας εἰς οἰκίαν.
42010008 Καὶ εἰς ἣν ἂν πόλιν εἰσέρχησθε, καὶ δέχωνται ὑμᾶς, ἐσθίετε τὰ παρατιθέμενα ὑμῖν.
42010009 Καὶ θεραπεύετε τοὺς ἐν αὐτῇ ἀσθενεῖς, καὶ λέγετε αὐτοῖς, ‘Ἤγγικεν ἐφʼ ὑμᾶς ἡ Βασιλεία τοῦ ˚Θεοῦ.’
42010010 Εἰς ἣν δʼ ἂν πόλιν εἰσέλθητε, καὶ μὴ δέχωνται ὑμᾶς, ἐξελθόντες εἰς τὰς πλατείας αὐτῆς εἴπατε,
42010011 ‘Καὶ τὸν κονιορτὸν τὸν κολληθέντα ἡμῖν ἐκ τῆς πόλεως ὑμῶν εἰς τοὺς πόδας ἀπομασσόμεθα ὑμῖν· πλὴν τοῦτο γινώσκετε ὅτι ἤγγικεν ἡ Βασιλεία τοῦ ˚Θεοῦ.’
42010012 Λέγω ὑμῖν ὅτι Σοδόμοις ἐν τῇ ἡμέρᾳ ἐκείνῃ ἀνεκτότερον ἔσται, ἢ τῇ πόλει ἐκείνῃ.
42010013 Οὐαί σοι, Χοραζίν! Οὐαί σοι, Βηθσαϊδά! Ὅτι εἰ ἐν Τύρῳ καὶ Σιδῶνι ἐγενήθησαν αἱ δυνάμεις, αἱ γενόμεναι ἐν ὑμῖν, πάλαι ἂν ἐν σάκκῳ καὶ σποδῷ καθήμενοι μετενόησαν.
42010014 Πλὴν Τύρῳ καὶ Σιδῶνι, ἀνεκτότερον ἔσται ἐν τῇ κρίσει ἢ ὑμῖν.
42010015 Καὶ σύ, Καφαρναούμ, μὴ ἕως οὐρανοῦ ὑψωθήσῃ; Ἕως ᾅδου καταβήσῃ.
42010016 Ὁ ἀκούων ὑμῶν, ἐμοῦ ἀκούει, καὶ ὁ ἀθετῶν ὑμᾶς, ἐμὲ ἀθετεῖ· ὁ δὲ ἐμὲ ἀθετῶν, ἀθετεῖ τὸν ἀποστείλαντά με.”
42010017 Ὑπέστρεψαν δὲ οἱ ἑβδομήκοντα δύο μετὰ χαρᾶς λέγοντες, “˚Κύριε, καὶ τὰ δαιμόνια ὑποτάσσεται ἡμῖν ἐν τῷ ὀνόματί σου.”
42010018 Εἶπεν δὲ αὐτοῖς, “ἐθεώρουν τὸν Σατανᾶν ὡς ἀστραπὴν ἐκ τοῦ οὐρανοῦ πεσόντα.
42010019 Ἰδοὺ, δέδωκα ὑμῖν τὴν ἐξουσίαν τοῦ πατεῖν ἐπάνω ὄφεων καὶ σκορπίων, καὶ ἐπὶ πᾶσαν τὴν δύναμιν τοῦ ἐχθροῦ, καὶ οὐδὲν ὑμᾶς οὐ μὴ ἀδικήσῃ.
42010020 Πλὴν ἐν τούτῳ μὴ χαίρετε ὅτι τὰ πνεύματα ὑμῖν ὑποτάσσεται, χαίρετε δὲ ὅτι τὰ ὀνόματα ὑμῶν ἐγγέγραπται ἐν τοῖς οὐρανοῖς.”
42010021 Ἐν αὐτῇ τῇ ὥρᾳ, ἠγαλλιάσατο τῷ ˚Πνεύματι τῷ Ἁγίῳ καὶ εἶπεν, “Ἐξομολογοῦμαί σοι, Πάτερ, ˚Κύριε τοῦ οὐρανοῦ καὶ τῆς γῆς, ὅτι ἀπέκρυψας ταῦτα ἀπὸ σοφῶν καὶ συνετῶν, καὶ ἀπεκάλυψας αὐτὰ νηπίοις. Ναί, ὁ Πατήρ, ὅτι οὕτως εὐδοκία ἐγένετο ἔμπροσθέν σου.
42010022 Πάντα μοι παρεδόθη ὑπὸ τοῦ Πατρός μου, καὶ οὐδεὶς γινώσκει τίς ἐστιν ὁ Υἱὸς, εἰ μὴ ὁ Πατήρ, καὶ τίς ἐστιν ὁ Πατὴρ, εἰ μὴ ὁ Υἱὸς καὶ ᾧ ἐὰν βούληται ὁ Υἱὸς ἀποκαλύψαι.”
42010023 Καὶ στραφεὶς πρὸς τοὺς μαθητὰς κατʼ ἰδίαν, εἶπεν, “Μακάριοι οἱ ὀφθαλμοὶ οἱ βλέποντες ἃ βλέπετε!
42010024 Λέγω γὰρ ὑμῖν ὅτι πολλοὶ προφῆται καὶ βασιλεῖς ἠθέλησαν ἰδεῖν ἃ ὑμεῖς βλέπετε, καὶ οὐκ εἶδαν, καὶ ἀκοῦσαι ἃ ἀκούετε, καὶ οὐκ ἤκουσαν.”
42010025 Καὶ ἰδοὺ, νομικός τις ἀνέστη, ἐκπειράζων αὐτὸν λέγων, “Διδάσκαλε, τί ποιήσας, ζωὴν αἰώνιον κληρονομήσω;”
42010026 Ὁ δὲ εἶπεν πρὸς αὐτόν, “Ἐν τῷ νόμῳ τί γέγραπται; Πῶς ἀναγινώσκεις;”
42010027 Ὁ δὲ ἀποκριθεὶς εἶπεν, “‘Ἀγαπήσεις ˚Κύριον τὸν ˚Θεόν σου, ἐξ ὅλης καρδίας σου, καὶ ἐν ὅλῃ τῇ ψυχῇ σου, καὶ ἐν ὅλῃ τῇ ἰσχύϊ σου, καὶ ἐν ὅλῃ τῇ διανοίᾳ σου’, καὶ, ‘Τὸν πλησίον σου ὡς σεαυτόν.’”
42010028 Εἶπεν δὲ αὐτῷ, “Ὀρθῶς ἀπεκρίθης. Τοῦτο ποίει, καὶ ζήσῃ.”
42010029 Ὁ δὲ θέλων δικαιῶσαι ἑαυτὸν, εἶπεν πρὸς τὸν ˚Ἰησοῦν, “Καὶ τίς ἐστίν μου πλησίον;”
42010030 Ὑπολαβὼν ὁ ˚Ἰησοῦς εἶπεν, “Ἄνθρωπός τις κατέβαινεν ἀπὸ Ἰερουσαλὴμ εἰς Ἰεριχὼ, καὶ λῃσταῖς περιέπεσεν, οἳ καὶ ἐκδύσαντες αὐτὸν, καὶ πληγὰς ἐπιθέντες, ἀπῆλθον ἀφέντες ἡμιθανῆ.
42010031 Κατὰ συγκυρίαν δὲ ἱερεύς τις κατέβαινεν ἐν τῇ ὁδῷ ἐκείνῃ, καὶ ἰδὼν αὐτὸν ἀντιπαρῆλθεν.
42010032 Ὁμοίως δὲ καὶ Λευίτης γενόμενος κατὰ τὸν τόπον, ἐλθὼν καὶ ἰδὼν ἀντιπαρῆλθεν.
42010033 Σαμαρείτης δέ τις ὁδεύων ἦλθεν κατʼ αὐτὸν, καὶ ἰδὼν ἐσπλαγχνίσθη
42010034 καὶ προσελθὼν, κατέδησεν τὰ τραύματα αὐτοῦ, ἐπιχέων ἔλαιον καὶ οἶνον, ἐπιβιβάσας δὲ αὐτὸν ἐπὶ τὸ ἴδιον κτῆνος, ἤγαγεν αὐτὸν εἰς πανδοχεῖον καὶ ἐπεμελήθη αὐτοῦ.
42010035 Καὶ ἐπὶ τὴν αὔριον ἐκβαλὼν, ἔδωκεν δύο δηνάρια τῷ πανδοχεῖ, καὶ εἶπεν, ‘Ἐπιμελήθητι αὐτοῦ, καὶ ὅ τι ἂν προσδαπανήσῃς, ἐγὼ ἐν τῷ ἐπανέρχεσθαί με ἀποδώσω σοι.’
42010036 Τίς τούτων τῶν τριῶν πλησίον δοκεῖ σοι γεγονέναι, τοῦ ἐμπεσόντος εἰς τοὺς λῃστάς;”
42010037 Ὁ δὲ εἶπεν, “Ὁ ποιήσας τὸ ἔλεος μετʼ αὐτοῦ.” Εἶπεν δὲ αὐτῷ ὁ ˚Ἰησοῦς, “Πορεύου καὶ σὺ ποίει ὁμοίως.”
42010038 Ἐν δὲ τῷ πορεύεσθαι αὐτοὺς, αὐτὸς εἰσῆλθεν εἰς κώμην τινά· γυνὴ δέ τις ὀνόματι Μάρθα ὑπεδέξατο αὐτὸν,
42010039 καὶ τῇδε ἦν ἀδελφὴ καλουμένη Μαριάμ, ἣ καὶ παρακαθεσθεῖσα πρὸς τοὺς πόδας τοῦ ˚Ἰησοῦ, ἤκουεν τὸν λόγον αὐτοῦ.
42010040 Ἡ δὲ Μάρθα περιεσπᾶτο περὶ πολλὴν διακονίαν· ἐπιστᾶσα δὲ εἶπεν, “˚Κύριε, οὐ μέλει σοι ὅτι ἡ ἀδελφή μου μόνην με κατέλιπεν διακονεῖν; Εἰπὲ οὖν αὐτῇ ἵνα μοι συναντιλάβηται!”
42010041 Ἀποκριθεὶς δὲ εἶπεν αὐτῇ ὁ ˚Κύριος, “Μάρθα, Μάρθα, μεριμνᾷς καὶ θορυβάζῃ περὶ πολλά,
42010042 ἑνὸς δέ ἐστιν χρεία· Μαριὰμ γὰρ τὴν ἀγαθὴν μερίδα ἐξελέξατο, ἥτις οὐκ ἀφαιρεθήσεται ἀπʼ αὐτῆς.”
42011001 Καὶ ἐγένετο ἐν τῷ εἶναι αὐτὸν ἐν τόπῳ τινὶ προσευχόμενον, ὡς ἐπαύσατο, εἶπέν τις τῶν μαθητῶν αὐτοῦ πρὸς αὐτόν, “˚Κύριε, δίδαξον ἡμᾶς προσεύχεσθαι, καθὼς καὶ Ἰωάννης ἐδίδαξεν τοὺς μαθητὰς αὐτοῦ.”
42011002 Εἶπεν δὲ αὐτοῖς, “Ὅταν προσεύχησθε, λέγετε, ‘Πάτερ, ἁγιασθήτω τὸ ὄνομά σου. Ἐλθάτω ἡ βασιλεία σου.
42011003 Τὸν ἄρτον ἡμῶν τὸν ἐπιούσιον, δίδου ἡμῖν τὸ καθʼ ἡμέραν.
42011004 Καὶ ἄφες ἡμῖν τὰς ἁμαρτίας ἡμῶν, καὶ γὰρ αὐτοὶ ἀφίομεν παντὶ ὀφείλοντι ἡμῖν. Καὶ μὴ εἰσενέγκῃς ἡμᾶς εἰς πειρασμόν.’”
42011005 Καὶ εἶπεν πρὸς αὐτούς, “Τίς ἐξ ὑμῶν ἕξει φίλον, καὶ πορεύσεται πρὸς αὐτὸν μεσονυκτίου, καὶ εἴπῃ αὐτῷ, ‘Φίλε, χρῆσόν μοι τρεῖς ἄρτους,
42011006 ἐπειδὴ φίλος μου παρεγένετο ἐξ ὁδοῦ πρός με, καὶ οὐκ ἔχω ὃ παραθήσω αὐτῷ.’
42011007 Κἀκεῖνος ἔσωθεν ἀποκριθεὶς εἴπῃ, ‘Μή μοι κόπους πάρεχε· ἤδη ἡ θύρα κέκλεισται, καὶ τὰ παιδία μου μετʼ ἐμοῦ εἰς τὴν κοίτην εἰσίν· οὐ δύναμαι ἀναστὰς δοῦναί σοι.’
42011008 Λέγω ὑμῖν, εἰ καὶ οὐ δώσει αὐτῷ ἀναστὰς, διὰ τὸ εἶναι φίλον αὐτοῦ, διά γε τὴν ἀναίδειαν αὐτοῦ ἐγερθεὶς, δώσει αὐτῷ ὅσων χρῄζει.
42011009 Κἀγὼ ὑμῖν λέγω, ‘Αἰτεῖτε, καὶ δοθήσεται ὑμῖν· ζητεῖτε, καὶ εὑρήσετε· κρούετε, καὶ ἀνοιγήσεται ὑμῖν.
42011010 Πᾶς γὰρ ὁ αἰτῶν λαμβάνει, καὶ ὁ ζητῶν εὑρίσκει, καὶ τῷ κρούοντι ἀνοιγήσεται.’
42011011 Τίνα δὲ ἐξ ὑμῶν τὸν πατέρα αἰτήσει ὁ υἱὸς ἰχθύν, καὶ ἀντὶ ἰχθύος, ὄφιν αὐτῷ ἐπιδώσει;
42011012 ἢ καὶ αἰτήσει ᾠόν, ἐπιδώσει αὐτῷ σκορπίον;
42011013 Εἰ οὖν ὑμεῖς πονηροὶ ὑπάρχοντες, οἴδατε δόματα ἀγαθὰ διδόναι τοῖς τέκνοις ὑμῶν, πόσῳ μᾶλλον ὁ Πατὴρ ὁ ἐξ οὐρανοῦ δώσει ˚Πνεῦμα Ἅγιον τοῖς αἰτοῦσιν αὐτόν;”
42011014 Καὶ ἦν ἐκβάλλων δαιμόνιον κωφόν. Ἐγένετο δὲ τοῦ δαιμονίου ἐξελθόντος, ἐλάλησεν ὁ κωφός. Καὶ ἐθαύμασαν οἱ ὄχλοι.
42011015 Τινὲς δὲ ἐξ αὐτῶν εἶπον, “Ἐν Βεελζεβοὺλ τῷ ἄρχοντι τῶν δαιμονίων, ἐκβάλλει τὰ δαιμόνια.”
42011016 Ἕτεροι δὲ πειράζοντες, σημεῖον ἐξ οὐρανοῦ ἐζήτουν παρʼ αὐτοῦ.
42011017 Αὐτὸς δὲ εἰδὼς αὐτῶν τὰ διανοήματα εἶπεν αὐτοῖς, “Πᾶσα βασιλεία ἐφʼ ἑαυτὴν διαμερισθεῖσα ἐρημοῦται, καὶ οἶκος ἐπὶ οἶκον πίπτει.
42011018 Εἰ δὲ καὶ ὁ Σατανᾶς ἐφʼ ἑαυτὸν διεμερίσθη, πῶς σταθήσεται ἡ βασιλεία αὐτοῦ; Ὅτι λέγετε, ἐν Βεελζεβοὺλ ἐκβάλλειν με τὰ δαιμόνια.
42011019 Εἰ δὲ ἐγὼ ἐν Βεελζεβοὺλ ἐκβάλλω τὰ δαιμόνια, οἱ υἱοὶ ὑμῶν ἐν τίνι ἐκβάλλουσιν; Διὰ τοῦτο, αὐτοὶ ὑμῶν κριταὶ ἔσονται.
42011020 Εἰ δὲ ἐν δακτύλῳ ˚Θεοῦ, ἐγὼ ἐκβάλλω τὰ δαιμόνια, ἄρα ἔφθασεν ἐφʼ ὑμᾶς ἡ Βασιλεία τοῦ ˚Θεοῦ.
42011021 Ὅταν ὁ ἰσχυρὸς καθωπλισμένος, φυλάσσῃ τὴν ἑαυτοῦ αὐλήν, ἐν εἰρήνῃ ἐστὶν τὰ ὑπάρχοντα αὐτοῦ·
42011022 ἐπὰν δὲ ἰσχυρότερος αὐτοῦ ἐπελθὼν, νικήσῃ αὐτόν, τὴν πανοπλίαν αὐτοῦ αἴρει ἐφʼ ᾗ ἐπεποίθει, καὶ τὰ σκῦλα αὐτοῦ διαδίδωσιν.
42011023 Ὁ μὴ ὢν μετʼ ἐμοῦ, κατʼ ἐμοῦ ἐστιν, καὶ ὁ μὴ συνάγων μετʼ ἐμοῦ, σκορπίζει.
42011024 Ὅταν τὸ ἀκάθαρτον πνεῦμα ἐξέλθῃ ἀπὸ τοῦ ἀνθρώπου, διέρχεται διʼ ἀνύδρων τόπων ζητοῦν ἀνάπαυσιν, καὶ μὴ εὑρίσκον, λέγει, ‘Ὑποστρέψω εἰς τὸν οἶκόν μου, ὅθεν ἐξῆλθον.’
42011025 Καὶ ἐλθὸν, εὑρίσκει σεσαρωμένον καὶ κεκοσμημένον.
42011026 Τότε πορεύεται καὶ παραλαμβάνει ἕτερα πνεύματα πονηρότερα ἑαυτοῦ ἑπτά, καὶ εἰσελθόντα, κατοικεῖ ἐκεῖ, καὶ γίνεται τὰ ἔσχατα τοῦ ἀνθρώπου ἐκείνου, χείρονα τῶν πρώτων.”
42011027 Ἐγένετο δὲ ἐν τῷ λέγειν αὐτὸν ταῦτα, ἐπάρασά τις φωνὴν γυνὴ ἐκ τοῦ ὄχλου, εἶπεν αὐτῷ, “Μακαρία ἡ κοιλία ἡ βαστάσασά σε, καὶ μαστοὶ οὓς ἐθήλασας.”
42011028 Αὐτὸς δὲ εἶπεν, “Μενοῦν, μακάριοι οἱ ἀκούοντες τὸν λόγον τοῦ ˚Θεοῦ καὶ φυλάσσοντες.”
42011029 Τῶν δὲ ὄχλων ἐπαθροιζομένων, ἤρξατο λέγειν, “Ἡ γενεὰ αὕτη γενεὰ πονηρά ἐστιν· σημεῖον ζητεῖ καὶ σημεῖον οὐ δοθήσεται αὐτῇ, εἰ μὴ τὸ σημεῖον Ἰωνᾶ.
42011030 Καθὼς γὰρ ἐγένετο Ἰωνᾶς τοῖς Νινευίταις σημεῖον, οὕτως ἔσται καὶ ὁ Υἱὸς τοῦ Ἀνθρώπου τῇ γενεᾷ ταύτῃ.
42011031 Βασίλισσα νότου ἐγερθήσεται ἐν τῇ κρίσει μετὰ τῶν ἀνδρῶν τῆς γενεᾶς ταύτης, καὶ κατακρινεῖ αὐτούς, ὅτι ἦλθεν ἐκ τῶν περάτων τῆς γῆς ἀκοῦσαι τὴν σοφίαν Σολομῶνος, καὶ ἰδοὺ, πλεῖον Σολομῶνος ὧδε.
42011032 Ἄνδρες Νινευῖται ἀναστήσονται ἐν τῇ κρίσει μετὰ τῆς γενεᾶς ταύτης, καὶ κατακρινοῦσιν αὐτήν, ὅτι μετενόησαν εἰς τὸ κήρυγμα Ἰωνᾶ, καὶ ἰδοὺ, πλεῖον Ἰωνᾶ ὧδε.
42011033 Οὐδεὶς λύχνον ἅψας εἰς κρύπτην τίθησιν, οὐδὲ ὑπὸ τὸν μόδιον, ἀλλʼ ἐπὶ τὴν λυχνίαν, ἵνα οἱ εἰσπορευόμενοι τὸ φῶς βλέπωσιν.
42011034 Ὁ λύχνος τοῦ σώματός ἐστιν ὁ ὀφθαλμός σου. Ὅταν ὁ ὀφθαλμός σου ἁπλοῦς ᾖ, καὶ ὅλον τὸ σῶμά σου φωτεινόν ἐστιν· ἐπὰν δὲ πονηρὸς ᾖ, καὶ τὸ σῶμά σου σκοτεινόν.
42011035 Σκόπει οὖν, μὴ τὸ φῶς τὸ ἐν σοὶ σκότος ἐστίν.
42011036 Εἰ οὖν τὸ σῶμά σου ὅλον φωτεινόν, μὴ ἔχον μέρος τι σκοτεινόν, ἔσται φωτεινὸν ὅλον, ὡς ὅταν ὁ λύχνος τῇ ἀστραπῇ φωτίζῃ σε.”
42011037 Ἐν δὲ τῷ λαλῆσαι, ἐρωτᾷ αὐτὸν Φαρισαῖος ὅπως ἀριστήσῃ παρʼ αὐτῷ, εἰσελθὼν δὲ, ἀνέπεσεν.
42011038 Ὁ δὲ Φαρισαῖος ἰδὼν, ἐθαύμασεν ὅτι οὐ πρῶτον ἐβαπτίσθη πρὸ τοῦ ἀρίστου.
42011039 Εἶπεν δὲ ὁ ˚Κύριος πρὸς αὐτόν, “Νῦν ὑμεῖς οἱ Φαρισαῖοι τὸ ἔξωθεν τοῦ ποτηρίου καὶ τοῦ πίνακος καθαρίζετε, τὸ δὲ ἔσωθεν ὑμῶν γέμει ἁρπαγῆς καὶ πονηρίας.
42011040 Ἄφρονες! Οὐχ ὁ ποιήσας τὸ ἔξωθεν, καὶ τὸ ἔσωθεν ἐποίησεν;
42011041 Πλὴν τὰ ἐνόντα δότε ἐλεημοσύνην, καὶ ἰδοὺ, πάντα καθαρὰ ὑμῖν ἐστιν.
42011042 Ἀλλὰ οὐαὶ ὑμῖν τοῖς Φαρισαίοις, ὅτι ἀποδεκατοῦτε τὸ ἡδύοσμον, καὶ τὸ πήγανον, καὶ πᾶν λάχανον, καὶ παρέρχεσθε τὴν κρίσιν καὶ τὴν ἀγάπην τοῦ ˚Θεοῦ. Ταῦτα δὲ ἔδει ποιῆσαι, κἀκεῖνα μὴ παρεῖναι.
42011043 Οὐαὶ ὑμῖν τοῖς Φαρισαίοις, ὅτι ἀγαπᾶτε τὴν πρωτοκαθεδρίαν ἐν ταῖς συναγωγαῖς, καὶ τοὺς ἀσπασμοὺς ἐν ταῖς ἀγοραῖς.
42011044 Οὐαὶ ὑμῖν, ὅτι ἐστὲ ὡς τὰ μνημεῖα τὰ ἄδηλα, καὶ οἱ ἄνθρωποι περιπατοῦντες ἐπάνω οὐκ οἴδασιν.”
42011045 Ἀποκριθεὶς δέ τις τῶν νομικῶν λέγει αὐτῷ, “Διδάσκαλε, ταῦτα λέγων, καὶ ἡμᾶς ὑβρίζεις.”
42011046 Ὁ δὲ εἶπεν, “Καὶ ὑμῖν τοῖς νομικοῖς οὐαί! Ὅτι φορτίζετε τοὺς ἀνθρώπους φορτία δυσβάστακτα, καὶ αὐτοὶ ἑνὶ τῶν δακτύλων ὑμῶν οὐ προσψαύετε τοῖς φορτίοις.
42011047 Οὐαὶ ὑμῖν, ὅτι οἰκοδομεῖτε τὰ μνημεῖα τῶν προφητῶν, οἱ δὲ πατέρες ὑμῶν ἀπέκτειναν αὐτούς.
42011048 Ἄρα μαρτυρεῖτε καὶ συνευδοκεῖτε τοῖς ἔργοις τῶν πατέρων ὑμῶν, ὅτι αὐτοὶ μὲν ἀπέκτειναν αὐτοὺς, ὑμεῖς δὲ οἰκοδομεῖτε.
42011049 Διὰ τοῦτο καὶ ἡ σοφία τοῦ ˚Θεοῦ εἶπεν, ‘Ἀποστελῶ εἰς αὐτοὺς προφήτας καὶ ἀποστόλους, καὶ ἐξ αὐτῶν ἀποκτενοῦσιν καὶ διώξουσιν,
42011050 ἵνα ἐκζητηθῇ τὸ αἷμα πάντων τῶν προφητῶν, τὸ ἐκχυννόμενον ἀπὸ καταβολῆς κόσμου, ἀπὸ τῆς γενεᾶς ταύτης,
42011051 ἀπὸ αἵματος Ἅβελ ἕως αἵματος Ζαχαρίου, τοῦ ἀπολομένου μεταξὺ τοῦ θυσιαστηρίου καὶ τοῦ οἴκου. Ναί, λέγω ὑμῖν, ἐκζητηθήσεται ἀπὸ τῆς γενεᾶς ταύτης.’
42011052 Οὐαὶ ὑμῖν τοῖς νομικοῖς, ὅτι ἤρατε τὴν κλεῖδα τῆς γνώσεως· αὐτοὶ οὐκ εἰσήλθατε, καὶ τοὺς εἰσερχομένους ἐκωλύσατε.”
42011053 Κἀκεῖθεν ἐξελθόντος αὐτοῦ, ἤρξαντο οἱ γραμματεῖς καὶ οἱ Φαρισαῖοι δεινῶς ἐνέχειν, καὶ ἀποστοματίζειν αὐτὸν περὶ πλειόνων,
42011054 ἐνεδρεύοντες αὐτὸν θηρεῦσαί τι ἐκ τοῦ στόματος αὐτοῦ.
42012001 Ἐν οἷς ἐπισυναχθεισῶν τῶν μυριάδων τοῦ ὄχλου, ὥστε καταπατεῖν ἀλλήλους, ἤρξατο λέγειν πρὸς τοὺς μαθητὰς αὐτοῦ πρῶτον, “Προσέχετε ἑαυτοῖς ἀπὸ τῆς ζύμης, τῶν Φαρισαίων, ἥτις ἐστὶν ὑπόκρισις.
42012002 Οὐδὲν δὲ συγκεκαλυμμένον ἐστὶν, ὃ οὐκ ἀποκαλυφθήσεται, καὶ κρυπτὸν ὃ οὐ γνωσθήσεται.
42012003 Ἀνθʼ ὧν ὅσα ἐν τῇ σκοτίᾳ εἴπατε, ἐν τῷ φωτὶ ἀκουσθήσεται, καὶ ὃ πρὸς τὸ οὖς ἐλαλήσατε ἐν τοῖς ταμείοις, κηρυχθήσεται ἐπὶ τῶν δωμάτων.
42012004 Λέγω δὲ ὑμῖν, τοῖς φίλοις μου, μὴ φοβηθῆτε ἀπὸ τῶν ἀποκτεινόντων τὸ σῶμα, καὶ μετὰ ταῦτα μὴ ἐχόντων περισσότερόν τι ποιῆσαι.
42012005 Ὑποδείξω δὲ ὑμῖν τίνα φοβηθῆτε: φοβήθητε τὸν μετὰ τὸ ἀποκτεῖναι, ἔχοντα ἐξουσίαν ἐμβαλεῖν εἰς τὴν Γέενναν· ναί, λέγω ὑμῖν, τοῦτον φοβήθητε.
42012006 Οὐχὶ πέντε στρουθία πωλοῦνται ἀσσαρίων δύο; Καὶ ἓν ἐξ αὐτῶν οὐκ ἔστιν ἐπιλελησμένον ἐνώπιον τοῦ ˚Θεοῦ.
42012007 Ἀλλὰ καὶ αἱ τρίχες τῆς κεφαλῆς ὑμῶν πᾶσαι ἠρίθμηνται. Μὴ φοβεῖσθε, πολλῶν στρουθίων διαφέρετε.
42012008 Λέγω δὲ ὑμῖν, πᾶς ὃς ἂν ὁμολογήσῃ ἐν ἐμοὶ ἔμπροσθεν τῶν ἀνθρώπων, καὶ ὁ Υἱὸς τοῦ Ἀνθρώπου ὁμολογήσει, ἐν αὐτῷ ἔμπροσθεν τῶν ἀγγέλων τοῦ ˚Θεοῦ·
42012009 ὁ δὲ ἀρνησάμενός με ἐνώπιον τῶν ἀνθρώπων, ἀπαρνηθήσεται ἐνώπιον τῶν ἀγγέλων τοῦ ˚Θεοῦ.
42012010 Καὶ πᾶς ὃς ἐρεῖ λόγον εἰς τὸν Υἱὸν τοῦ Ἀνθρώπου, ἀφεθήσεται αὐτῷ· τῷ δὲ εἰς τὸ Ἅγιον ˚Πνεῦμα βλασφημήσαντι, οὐκ ἀφεθήσεται.
42012011 Ὅταν δὲ εἰσφέρωσιν ὑμᾶς ἐπὶ τὰς συναγωγὰς, καὶ τὰς ἀρχὰς, καὶ τὰς ἐξουσίας, μὴ μεριμνήσητε πῶς ἢ τί ἀπολογήσησθε ἢ τί εἴπητε·
42012012 τὸ γὰρ Ἅγιον ˚Πνεῦμα διδάξει ὑμᾶς ἐν αὐτῇ τῇ ὥρᾳ ἃ δεῖ εἰπεῖν.”
42012013 Εἶπεν δέ τις ἐκ τοῦ ὄχλου αὐτῷ, “Διδάσκαλε, εἰπὲ τῷ ἀδελφῷ μου μερίσασθαι μετʼ ἐμοῦ τὴν κληρονομίαν.”
42012014 Ὁ δὲ εἶπεν αὐτῷ, “Ἄνθρωπε, τίς με κατέστησεν κριτὴν ἢ μεριστὴν ἐφʼ ὑμᾶς;”
42012015 Εἶπεν δὲ πρὸς αὐτούς, “Ὁρᾶτε καὶ φυλάσσεσθε ἀπὸ πάσης πλεονεξίας, ὅτι οὐκ ἐν τῷ περισσεύειν τινὶ, ἡ ζωὴ αὐτοῦ ἐστιν ἐκ τῶν ὑπαρχόντων αὐτῷ.”
42012016 Εἶπεν δὲ παραβολὴν πρὸς αὐτοὺς λέγων, “Ἀνθρώπου τινὸς πλουσίου εὐφόρησεν ἡ χώρα.
42012017 Καὶ διελογίζετο ἐν ἑαυτῷ λέγων, ‘Τί ποιήσω, ὅτι οὐκ ἔχω ποῦ συνάξω τοὺς καρπούς μου;’
42012018 Καὶ εἶπεν, ‘Τοῦτο ποιήσω: καθελῶ μου τὰς ἀποθήκας καὶ μείζονας οἰκοδομήσω, καὶ συνάξω ἐκεῖ πάντα τὸν σῖτον καὶ τὰ ἀγαθά μου.
42012019 Καὶ ἐρῶ τῇ ψυχῇ μου, “Ψυχή, ἔχεις πολλὰ ἀγαθὰ κείμενα εἰς ἔτη πολλά· ἀναπαύου, φάγε, πίε, εὐφραίνου.”’
42012020 Εἶπεν δὲ αὐτῷ ὁ ˚Θεός, ‘Ἄφρων, ταύτῃ τῇ νυκτὶ, τὴν ψυχήν σου αἰτοῦσιν ἀπὸ σοῦ· ἃ δὲ ἡτοίμασας, τίνι ἔσται;’
42012021 Οὕτως ὁ θησαυρίζων ἑαυτῷ καὶ μὴ εἰς ˚Θεὸν πλουτῶν.”
42012022 Εἶπεν δὲ πρὸς τοὺς μαθητὰς “Διὰ τοῦτο λέγω ὑμῖν, μὴ μεριμνᾶτε τῇ ψυχῇ, τί φάγητε μηδὲ τῷ σώματι τί ἐνδύσησθε.
42012023 Ἡ γὰρ ψυχὴ πλεῖόν ἐστιν τῆς τροφῆς, καὶ τὸ σῶμα τοῦ ἐνδύματος.
42012024 Κατανοήσατε τοὺς κόρακας, ὅτι οὐ σπείρουσιν οὐδὲ θερίζουσιν, οἷς οὐκ ἔστιν ταμεῖον οὐδὲ ἀποθήκη, καὶ ὁ ˚Θεὸς τρέφει αὐτούς. Πόσῳ μᾶλλον ὑμεῖς διαφέρετε τῶν πετεινῶν!
42012025 Τίς δὲ ἐξ ὑμῶν μεριμνῶν, δύναται ἐπὶ τὴν ἡλικίαν αὐτοῦ προσθεῖναι πῆχυν;
42012026 Εἰ οὖν οὐδὲ ἐλάχιστον δύνασθε, τί περὶ τῶν λοιπῶν μεριμνᾶτε;
42012027 Κατανοήσατε τὰ κρίνα πῶς αὐξάνει, οὐ κοπιᾷ οὐδὲ νήθει· λέγω δὲ ὑμῖν, οὐδὲ Σολομὼν ἐν πάσῃ τῇ δόξῃ αὐτοῦ, περιεβάλετο ὡς ἓν τούτων.
42012028 Εἰ δὲ ἐν ἀγρῷ τὸν χόρτον ὄντα σήμερον, καὶ αὔριον εἰς κλίβανον βαλλόμενον, ὁ ˚Θεὸς οὕτως ἀμφιέζει, πόσῳ μᾶλλον ὑμᾶς, ὀλιγόπιστοι;
42012029 Καὶ ὑμεῖς μὴ ζητεῖτε τί φάγητε, ἢ τί πίητε, καὶ μὴ μετεωρίζεσθε.
42012030 Ταῦτα γὰρ πάντα τὰ ἔθνη τοῦ κόσμου ἐπιζητοῦσιν, ὑμῶν δὲ ὁ Πατὴρ οἶδεν ὅτι χρῄζετε τούτων.
42012031 Πλὴν ζητεῖτε τὴν βασιλείαν αὐτοῦ, καὶ ταῦτα προστεθήσεται ὑμῖν.
42012032 Μὴ φοβοῦ, τὸ μικρὸν ποίμνιον, ὅτι εὐδόκησεν ὁ Πατὴρ ὑμῶν, δοῦναι ὑμῖν τὴν βασιλείαν.
42012033 Πωλήσατε τὰ ὑπάρχοντα ὑμῶν, καὶ δότε ἐλεημοσύνην. Ποιήσατε ἑαυτοῖς βαλλάντια μὴ παλαιούμενα, θησαυρὸν ἀνέκλειπτον ἐν τοῖς οὐρανοῖς, ὅπου κλέπτης οὐκ ἐγγίζει οὐδὲ σὴς διαφθείρει.
42012034 Ὅπου γάρ ἐστιν ὁ θησαυρὸς ὑμῶν, ἐκεῖ καὶ ἡ καρδία ὑμῶν ἔσται.
42012035 Ἔστωσαν ὑμῶν αἱ ὀσφύες περιεζωσμέναι, καὶ οἱ λύχνοι καιόμενοι,
42012036 καὶ ὑμεῖς ὅμοιοι ἀνθρώποις προσδεχομένοις τὸν κύριον ἑαυτῶν, πότε ἀναλύσῃ ἐκ τῶν γάμων, ἵνα ἐλθόντος καὶ κρούσαντος, εὐθέως ἀνοίξωσιν αὐτῷ.
42012037 Μακάριοι οἱ δοῦλοι ἐκεῖνοι, οὓς ἐλθὼν, ὁ ˚Κύριος εὑρήσει γρηγοροῦντας. Ἀμὴν, λέγω ὑμῖν ὅτι περιζώσεται καὶ ἀνακλινεῖ αὐτοὺς, καὶ παρελθὼν, διακονήσει αὐτοῖς.
42012038 Κἂν ἐν τῇ δευτέρᾳ κἂν ἐν τῇ τρίτῃ φυλακῇ ἔλθῃ, καὶ εὕρῃ οὕτως, μακάριοί εἰσιν ἐκεῖνοι!
42012039 Τοῦτο δὲ γινώσκετε, ὅτι εἰ ᾔδει ὁ οἰκοδεσπότης ποίᾳ ὥρᾳ ὁ κλέπτης ἔρχεται, ἐγρηγόρησεν ἂν καὶ οὐκ ἂν ἀφῆκεν διορυχθῆναι τὸν οἶκον αὐτοῦ.
42012040 Καὶ ὑμεῖς γίνεσθε ἕτοιμοι, ὅτι ᾗ ὥρᾳ οὐ δοκεῖτε, ὁ Υἱὸς τοῦ Ἀνθρώπου ἔρχεται.”
42012041 Εἶπεν δὲ ὁ Πέτρος, “˚Κύριε, πρὸς ἡμᾶς τὴν παραβολὴν ταύτην λέγεις, ἢ καὶ πρὸς πάντας;”
42012042 Καὶ εἶπεν ὁ ˚Κύριος, “Τίς ἄρα ἐστὶν ὁ πιστὸς οἰκονόμος ὁ φρόνιμος, ὃν καταστήσει ὁ ˚Κύριος ἐπὶ τῆς θεραπείας αὐτοῦ, τοῦ διδόναι ἐν καιρῷ σιτομέτριον;
42012043 Μακάριος ὁ δοῦλος ἐκεῖνος, ὃν ἐλθὼν, ὁ κύριος αὐτοῦ εὑρήσει οὕτως ποιοῦντα.
42012044 Ἀληθῶς λέγω ὑμῖν ὅτι ἐπὶ πᾶσιν τοῖς ὑπάρχουσιν αὐτοῦ καταστήσει αὐτόν.
42012045 Ἐὰν δὲ εἴπῃ ὁ δοῦλος ἐκεῖνος ἐν τῇ καρδίᾳ αὐτοῦ, ‘Χρονίζει ὁ κύριός μου ἔρχεσθαι’, καὶ ἄρξηται τύπτειν τοὺς παῖδας καὶ τὰς παιδίσκας, ἐσθίειν τε καὶ πίνειν καὶ μεθύσκεσθαι,
42012046 ἥξει ὁ κύριος τοῦ δούλου ἐκείνου ἐν ἡμέρᾳ ᾗ οὐ προσδοκᾷ, καὶ ἐν ὥρᾳ ᾗ οὐ γινώσκει, καὶ διχοτομήσει αὐτὸν, καὶ τὸ μέρος αὐτοῦ μετὰ τῶν ἀπίστων θήσει.
42012047 Ἐκεῖνος δὲ ὁ δοῦλος, ὁ γνοὺς τὸ θέλημα τοῦ κυρίου αὐτοῦ, καὶ μὴ ἑτοιμάσας ἢ ποιήσας πρὸς τὸ θέλημα αὐτοῦ, δαρήσεται πολλάς·
42012048 ὁ δὲ μὴ γνοὺς, ποιήσας δὲ ἄξια πληγῶν, δαρήσεται ὀλίγας. Παντὶ δὲ ᾧ ἐδόθη πολύ, πολὺ ζητηθήσεται παρʼ αὐτοῦ, καὶ ᾧ παρέθεντο πολύ, περισσότερον αἰτήσουσιν αὐτόν.
42012049 Πῦρ ἦλθον βαλεῖν ἐπὶ τὴν γῆν, καὶ τί θέλω εἰ ἤδη ἀνήφθη;
42012050 Βάπτισμα δὲ ἔχω βαπτισθῆναι, καὶ πῶς συνέχομαι ἕως ὅτου τελεσθῇ!
42012051 Δοκεῖτε ὅτι εἰρήνην παρεγενόμην δοῦναι ἐν τῇ γῇ; Οὐχί, λέγω ὑμῖν, ἀλλʼ ἢ διαμερισμόν.
42012052 Ἔσονται γὰρ ἀπὸ τοῦ νῦν, πέντε ἐν ἑνὶ οἴκῳ διαμεμερισμένοι, τρεῖς ἐπὶ δυσὶν, καὶ δύο ἐπὶ τρισίν.
42012053 Διαμερισθήσονται πατὴρ ἐπὶ υἱῷ, καὶ υἱὸς ἐπὶ πατρί, μήτηρ ἐπὶ θυγατέρα, καὶ θυγάτηρ ἐπὶ τὴν μητέρα, πενθερὰ ἐπὶ τὴν νύμφην αὐτῆς, καὶ νύμφη ἐπὶ τὴν πενθεράν.”
42012054 Ἔλεγεν δὲ καὶ τοῖς ὄχλοις, “Ὅταν ἴδητε νεφέλην ἀνατέλλουσαν ἐπὶ δυσμῶν, εὐθέως λέγετε, ὅτι ‘Ὄμβρος ἔρχεται’, καὶ γίνεται οὕτως.
42012055 Καὶ ὅταν νότον πνέοντα, λέγετε, ὅτι ‘Καύσων ἔσται’, καὶ γίνεται.
42012056 Ὑποκριταί! Τὸ πρόσωπον τῆς γῆς καὶ τοῦ οὐρανοῦ οἴδατε δοκιμάζειν, τὸν καιρὸν δὲ τοῦτον, πῶς οὐκ οἴδατε δοκιμάζειν;
42012057 Τί δὲ καὶ ἀφʼ ἑαυτῶν, οὐ κρίνετε τὸ δίκαιον;
42012058 Ὡς γὰρ ὑπάγεις μετὰ τοῦ ἀντιδίκου σου ἐπʼ ἄρχοντα ἐν τῇ ὁδῷ, δὸς ἐργασίαν ἀπηλλάχθαι ἀπʼ αὐτοῦ, μήποτε κατασύρῃ σε πρὸς τὸν κριτήν, καὶ ὁ κριτής σε παραδώσει τῷ πράκτορι, καὶ ὁ πράκτωρ σε βαλεῖ εἰς φυλακήν.
42012059 Λέγω σοι, οὐ μὴ ἐξέλθῃς ἐκεῖθεν ἕως καὶ τὸ ἔσχατον λεπτὸν ἀποδῷς.”
42013001 Παρῆσαν δέ τινες ἐν αὐτῷ τῷ καιρῷ, ἀπαγγέλλοντες αὐτῷ περὶ τῶν Γαλιλαίων, ὧν τὸ αἷμα Πιλᾶτος ἔμιξεν μετὰ τῶν θυσιῶν αὐτῶν.
42013002 Καὶ ἀποκριθεὶς εἶπεν αὐτοῖς, “Δοκεῖτε ὅτι οἱ Γαλιλαῖοι οὗτοι, ἁμαρτωλοὶ παρὰ πάντας τοὺς Γαλιλαίους ἐγένοντο, ὅτι τοιαῦτα πεπόνθασιν;
42013003 Οὐχί, λέγω ὑμῖν, ἀλλʼ ἐὰν μὴ μετανοῆτε, πάντες ὁμοίως ἀπολεῖσθε.
42013004 Ἢ ἐκεῖνοι οἱ δεκαοκτὼ ἐφʼ οὓς ἔπεσεν ὁ πύργος ἐν τῷ Σιλωὰμ καὶ ἀπέκτεινεν αὐτούς, δοκεῖτε ὅτι αὐτοὶ ὀφειλέται ἐγένοντο παρὰ πάντας τοὺς ἀνθρώπους τοὺς κατοικοῦντας Ἰερουσαλήμ;
42013005 Οὐχί, λέγω ὑμῖν, ἀλλʼ ἐὰν μὴ μετανοῆτε, πάντες ὁμοίως ἀπολεῖσθε.”
42013006 Ἔλεγεν δὲ ταύτην τὴν παραβολήν: “Συκῆν εἶχέν τις πεφυτευμένην ἐν τῷ ἀμπελῶνι αὐτοῦ, καὶ ἦλθεν ζητῶν καρπὸν ἐν αὐτῇ, καὶ οὐχ εὗρεν.
42013007 Εἶπεν δὲ πρὸς τὸν ἀμπελουργόν, ‘Ἰδοὺ, τρία ἔτη ἀφʼ οὗ ἔρχομαι ζητῶν καρπὸν ἐν τῇ συκῇ ταύτῃ, καὶ οὐχ εὑρίσκω. Ἔκκοψον αὐτήν, ἵνα τί καὶ τὴν γῆν καταργεῖ;’
42013008 Ὁ δὲ ἀποκριθεὶς λέγει αὐτῷ, ‘Κύριε, ἄφες αὐτὴν καὶ τοῦτο τὸ ἔτος, ἕως ὅτου σκάψω περὶ αὐτὴν καὶ βάλω κόπρια·
42013009 κἂν μὲν ποιήσῃ καρπὸν εἰς τὸ μέλλον, εἰ δὲ μή γε ἐκκόψεις αὐτήν.’”
42013010 Ἦν δὲ διδάσκων ἐν μιᾷ τῶν συναγωγῶν ἐν τοῖς Σάββασιν.
42013011 Καὶ ἰδοὺ, γυνὴ πνεῦμα ἔχουσα ἀσθενείας ἔτη δεκαοκτώ, καὶ ἦν συγκύπτουσα καὶ μὴ δυναμένη ἀνακύψαι εἰς τὸ παντελές.
42013012 Ἰδὼν δὲ αὐτὴν, ὁ ˚Ἰησοῦς προσεφώνησεν καὶ εἶπεν αὐτῇ, “Γύναι, ἀπολέλυσαι τῆς ἀσθενείας σου.”
42013013 Καὶ ἐπέθηκεν αὐτῇ τὰς χεῖρας, καὶ παραχρῆμα ἀνωρθώθη καὶ ἐδόξαζεν τὸν ˚Θεόν.
42013014 Ἀποκριθεὶς δὲ ὁ ἀρχισυνάγωγος, ἀγανακτῶν ὅτι τῷ Σαββάτῳ ἐθεράπευσεν ὁ ˚Ἰησοῦς, ἔλεγεν τῷ ὄχλῳ, ὅτι “Ἓξ ἡμέραι εἰσὶν ἐν αἷς δεῖ ἐργάζεσθαι· ἐν αὐταῖς οὖν ἐρχόμενοι θεραπεύεσθε, καὶ μὴ τῇ ἡμέρᾳ τοῦ Σαββάτου.”
42013015 Ἀπεκρίθη δὲ αὐτῷ ὁ ˚Κύριος καὶ εἶπεν, “Ὑποκριταί! Ἕκαστος ὑμῶν τῷ Σαββάτῳ οὐ λύει τὸν βοῦν αὐτοῦ, ἢ τὸν ὄνον ἀπὸ τῆς φάτνης, καὶ ἀπαγαγὼν ποτίζει;
42013016 Ταύτην δὲ θυγατέρα Ἀβραὰμ οὖσαν, ἣν ἔδησεν ὁ Σατανᾶς, ἰδοὺ, δέκα καὶ ὀκτὼ ἔτη, οὐκ ἔδει λυθῆναι ἀπὸ τοῦ δεσμοῦ τούτου τῇ ἡμέρᾳ τοῦ Σαββάτου;”
42013017 Καὶ ταῦτα λέγοντος αὐτοῦ, κατῃσχύνοντο πάντες οἱ ἀντικείμενοι αὐτῷ, καὶ πᾶς ὁ ὄχλος ἔχαιρεν ἐπὶ πᾶσιν τοῖς ἐνδόξοις τοῖς γινομένοις ὑπʼ αὐτοῦ.
42013018 Ἔλεγεν οὖν, “Τίνι ὁμοία ἐστὶν ἡ Βασιλεία τοῦ ˚Θεοῦ, καὶ τίνι ὁμοιώσω αὐτήν;
42013019 Ὁμοία ἐστὶν κόκκῳ σινάπεως, ὃν λαβὼν, ἄνθρωπος ἔβαλεν εἰς κῆπον ἑαυτοῦ, καὶ ηὔξησεν καὶ ἐγένετο εἰς δένδρον, καὶ ‘Τὰ πετεινὰ τοῦ οὐρανοῦ κατεσκήνωσεν ἐν τοῖς κλάδοις αὐτοῦ.’”
42013020 Καὶ πάλιν εἶπεν, “Τίνι ὁμοιώσω τὴν Βασιλείαν τοῦ ˚Θεοῦ;
42013021 Ὁμοία ἐστὶν ζύμῃ, ἣν λαβοῦσα, γυνὴ ἐνέκρυψεν εἰς ἀλεύρου σάτα τρία, ἕως οὗ ἐζυμώθη ὅλον.”
42013022 Καὶ διεπορεύετο κατὰ πόλεις καὶ κώμας διδάσκων, καὶ πορείαν ποιούμενος εἰς Ἱεροσόλυμα.
42013023 Εἶπεν δέ τις αὐτῷ, “˚Κύριε, εἰ ὀλίγοι οἱ σῳζόμενοι;” Ὁ δὲ εἶπεν πρὸς αὐτούς,
42013024 “Ἀγωνίζεσθε εἰσελθεῖν διὰ τῆς στενῆς θύρας, ὅτι πολλοί, λέγω ὑμῖν, ζητήσουσιν εἰσελθεῖν καὶ οὐκ ἰσχύσουσιν.
42013025 Ἀφʼ οὗ ἂν ἐγερθῇ ὁ οἰκοδεσπότης καὶ ἀποκλείσῃ τὴν θύραν, καὶ ἄρξησθε ἔξω ἑστάναι καὶ κρούειν τὴν θύραν λέγοντες, ‘Κύριε, ἄνοιξον ἡμῖν’· καὶ ἀποκριθεὶς ἐρεῖ ὑμῖν, ‘Οὐκ οἶδα ὑμᾶς, πόθεν ἐστέ.’
42013026 Τότε ἄρξεσθε λέγειν, ‘Ἐφάγομεν ἐνώπιόν σου καὶ ἐπίομεν, καὶ ἐν ταῖς πλατείαις ἡμῶν ἐδίδαξας.’
42013027 Καὶ ἐρεῖ, λέγων ὑμῖν, ‘Οὐκ οἶδα πόθεν ἐστέ· “ἀπόστητε ἀπʼ ἐμοῦ, πάντες ἐργάται ἀδικίας”.’
42013028 Ἐκεῖ ἔσται ὁ κλαυθμὸς καὶ ὁ βρυγμὸς τῶν ὀδόντων, ὅταν ὄψησθε Ἀβραὰμ, καὶ Ἰσαὰκ, καὶ Ἰακὼβ, καὶ πάντας τοὺς προφήτας, ἐν τῇ Βασιλείᾳ τοῦ ˚Θεοῦ, ὑμᾶς δὲ ἐκβαλλομένους ἔξω.
42013029 Καὶ ἥξουσιν ἀπὸ ἀνατολῶν καὶ δυσμῶν καὶ ἀπὸ βορρᾶ καὶ νότου, καὶ ἀνακλιθήσονται ἐν τῇ Βασιλείᾳ τοῦ ˚Θεοῦ.
42013030 Καὶ ἰδοὺ, εἰσὶν ἔσχατοι οἳ ἔσονται πρῶτοι, καὶ εἰσὶν πρῶτοι οἳ ἔσονται ἔσχατοι.”
42013031 Ἐν αὐτῇ τῇ ὥρᾳ προσῆλθάν τινες Φαρισαῖοι λέγοντες αὐτῷ, “Ἔξελθε καὶ πορεύου ἐντεῦθεν, ὅτι Ἡρῴδης θέλει σε ἀποκτεῖναι.”
42013032 Καὶ εἶπεν αὐτοῖς, “Πορευθέντες εἴπατε τῇ ἀλώπεκι ταύτῃ, ‘Ἰδοὺ, ἐκβάλλω δαιμόνια, καὶ ἰάσεις ἀποτελῶ σήμερον καὶ αὔριον, καὶ τῇ τρίτῃ τελειοῦμαι.’
42013033 Πλὴν δεῖ με, σήμερον καὶ αὔριον καὶ τῇ ἐχομένῃ πορεύεσθαι, ὅτι οὐκ ἐνδέχεται προφήτην ἀπολέσθαι ἔξω Ἰερουσαλήμ.
42013034 Ἰερουσαλὴμ, Ἰερουσαλήμ, ἡ ἀποκτείνουσα τοὺς προφήτας, καὶ λιθοβολοῦσα τοὺς ἀπεσταλμένους πρὸς αὐτήν, ποσάκις ἠθέλησα ἐπισυνάξαι τὰ τέκνα σου, ὃν τρόπον ὄρνις τὴν ἑαυτῆς νοσσιὰν ὑπὸ τὰς πτέρυγας, καὶ οὐκ ἠθελήσατε.
42013035 Ἰδοὺ, ἀφίεται ὑμῖν ὁ οἶκος ὑμῶν. Λέγω δὲ ὑμῖν, οὐ μὴ ἴδητέ με ἕως εἴπητε, ‘Εὐλογημένος ὁ ἐρχόμενος ἐν ὀνόματι ˚Κυρίου.’”
42014001 Καὶ ἐγένετο ἐν τῷ ἐλθεῖν αὐτὸν εἰς οἶκόν τινος τῶν ἀρχόντων Φαρισαίων, Σαββάτῳ φαγεῖν ἄρτον, καὶ αὐτοὶ ἦσαν παρατηρούμενοι αὐτόν.
42014002 Καὶ ἰδοὺ, ἄνθρωπός τις ἦν ὑδρωπικὸς ἔμπροσθεν αὐτοῦ.
42014003 Καὶ ἀποκριθεὶς ὁ ˚Ἰησοῦς εἶπεν πρὸς τοὺς νομικοὺς καὶ Φαρισαίους λέγων, “Ἔξεστιν τῷ Σαββάτῳ θεραπεῦσαι ἢ οὔ;”
42014004 Οἱ δὲ ἡσύχασαν. Καὶ ἐπιλαβόμενος, ἰάσατο αὐτὸν καὶ ἀπέλυσεν.
42014005 Καὶ πρὸς αὐτοὺς εἶπεν, “Τίνος ὑμῶν υἱὸς ἢ βοῦς εἰς φρέαρ πεσεῖται, καὶ οὐκ εὐθέως ἀνασπάσει αὐτὸν ἐν ἡμέρᾳ τοῦ Σαββάτου;”
42014006 Καὶ οὐκ ἴσχυσαν ἀνταποκριθῆναι πρὸς ταῦτα.
42014007 Ἔλεγεν δὲ πρὸς τοὺς κεκλημένους παραβολήν, ἐπέχων πῶς τὰς πρωτοκλισίας ἐξελέγοντο, λέγων πρὸς αὐτούς,
42014008 “Ὅταν κληθῇς ὑπό τινος εἰς γάμους, μὴ κατακλιθῇς εἰς τὴν πρωτοκλισίαν, μήποτε ἐντιμότερός σου ᾖ κεκλημένος ὑπʼ αὐτοῦ,
42014009 καὶ ἐλθὼν, ὁ σὲ καὶ αὐτὸν καλέσας ἐρεῖ σοι, ‘Δὸς τούτῳ τόπον’, καὶ τότε ἄρξῃ μετὰ αἰσχύνης τὸν ἔσχατον τόπον κατέχειν.
42014010 Ἀλλʼ ὅταν κληθῇς, πορευθεὶς ἀνάπεσε εἰς τὸν ἔσχατον τόπον, ἵνα ὅταν ἔλθῃ ὁ κεκληκώς σε ἐρεῖ σοι, ‘Φίλε, προσανάβηθι ἀνώτερον.’ Τότε ἔσται σοι δόξα ἐνώπιον πάντων τῶν συνανακειμένων σοι.
42014011 Ὅτι πᾶς ὁ ὑψῶν ἑαυτὸν ταπεινωθήσεται, καὶ ὁ ταπεινῶν ἑαυτὸν ὑψωθήσεται.”
42014012 Ἔλεγεν δὲ καὶ τῷ κεκληκότι αὐτόν, “Ὅταν ποιῇς ἄριστον ἢ δεῖπνον, μὴ φώνει τοὺς φίλους σου, μηδὲ τοὺς ἀδελφούς σου, μηδὲ τοὺς συγγενεῖς σου, μηδὲ γείτονας πλουσίους, μήποτε καὶ αὐτοὶ ἀντικαλέσωσίν σε καὶ γένηται ἀνταπόδομά σοι.
42014013 Ἀλλʼ ὅταν ποιῇς δοχὴν κάλει πτωχούς, ἀναπείρους, χωλούς, τυφλούς,
42014014 καὶ μακάριος ἔσῃ, ὅτι οὐκ ἔχουσιν ἀνταποδοῦναί σοι, ἀνταποδοθήσεται γάρ σοι ἐν τῇ ἀναστάσει τῶν δικαίων.”
42014015 Ἀκούσας δέ τις τῶν συνανακειμένων ταῦτα, εἶπεν αὐτῷ, “Μακάριος ὅστις φάγεται ἄρτον ἐν τῇ Βασιλείᾳ τοῦ ˚Θεοῦ.”
42014016 Ὁ δὲ εἶπεν αὐτῷ, “Ἄνθρωπός τις ἐποίει δεῖπνον μέγα, καὶ ἐκάλεσεν πολλούς.
42014017 Καὶ ἀπέστειλεν τὸν δοῦλον αὐτοῦ τῇ ὥρᾳ τοῦ δείπνου εἰπεῖν τοῖς κεκλημένοις, ‘Ἔρχεσθε, ὅτι ἤδη ἕτοιμά ἐστιν.’
42014018 Καὶ ἤρξαντο ἀπὸ μιᾶς πάντες παραιτεῖσθαι. Ὁ πρῶτος εἶπεν αὐτῷ, ‘Ἀγρὸν ἠγόρασα καὶ ἔχω ἀνάγκην ἐξελθὼν ἰδεῖν αὐτόν· ἐρωτῶ σε ἔχε με παρῃτημένον.’
42014019 Καὶ ἕτερος εἶπεν, ‘Ζεύγη βοῶν ἠγόρασα πέντε καὶ πορεύομαι δοκιμάσαι αὐτά· ἐρωτῶ σε ἔχε με παρῃτημένον.’
42014020 Καὶ ἕτερος εἶπεν, ‘Γυναῖκα ἔγημα καὶ διὰ τοῦτο οὐ δύναμαι ἐλθεῖν.’
42014021 Καὶ παραγενόμενος, ὁ δοῦλος ἀπήγγειλεν τῷ κυρίῳ αὐτοῦ ταῦτα. Τότε ὀργισθεὶς, ὁ οἰκοδεσπότης εἶπεν τῷ δούλῳ αὐτοῦ, ‘Ἔξελθε ταχέως εἰς τὰς πλατείας καὶ ῥύμας τῆς πόλεως, καὶ τοὺς πτωχοὺς, καὶ ἀναπείρους, καὶ τυφλοὺς, καὶ χωλοὺς, εἰσάγαγε ὧδε.’
42014022 Καὶ εἶπεν ὁ δοῦλος, ‘Κύριε, γέγονεν ὃ ἐπέταξας, καὶ ἔτι τόπος ἐστίν.’
42014023 Καὶ εἶπεν ὁ κύριος πρὸς τὸν δοῦλον, ‘Ἔξελθε εἰς τὰς ὁδοὺς καὶ φραγμοὺς, καὶ ἀνάγκασον εἰσελθεῖν, ἵνα γεμισθῇ μου ὁ οἶκος·
42014024 λέγω γὰρ ὑμῖν ὅτι οὐδεὶς τῶν ἀνδρῶν ἐκείνων τῶν κεκλημένων, γεύσεταί μου τοῦ δείπνου.’”
42014025 Συνεπορεύοντο δὲ αὐτῷ ὄχλοι πολλοί, καὶ στραφεὶς, εἶπεν πρὸς αὐτούς,
42014026 “Εἴ τις ἔρχεται πρός με, καὶ οὐ μισεῖ τὸν πατέρα ἑαυτοῦ, καὶ τὴν μητέρα, καὶ τὴν γυναῖκα, καὶ τὰ τέκνα, καὶ τοὺς ἀδελφοὺς, καὶ τὰς ἀδελφάς, ἔτι δὲ καὶ τὴν ψυχὴν ἑαυτοῦ, οὐ δύναται μου εἶναι μαθητής.
42014027 Ὅστις οὐ βαστάζει τὸν σταυρὸν αὐτοῦ καὶ ἔρχεται ὀπίσω μου, οὐ δύναται εἶναί μου μαθητής.
42014028 Τίς γὰρ ἐξ ὑμῶν θέλων πύργον οἰκοδομῆσαι, οὐχὶ πρῶτον καθίσας, ψηφίζει τὴν δαπάνην, εἰ ἔχει εἰς ἀπαρτισμόν;
42014029 Ἵνα μήποτε θέντος αὐτοῦ θεμέλιον καὶ μὴ ἰσχύοντος ἐκτελέσαι, πάντες οἱ θεωροῦντες ἄρξωνται αὐτῷ ἐμπαίζειν
42014030 λέγοντες, ὅτι ‘Οὗτος ὁ ἄνθρωπος ἤρξατο οἰκοδομεῖν, καὶ οὐκ ἴσχυσεν ἐκτελέσαι.’
42014031 Ἢ τίς βασιλεὺς πορευόμενος ἑτέρῳ βασιλεῖ συμβαλεῖν εἰς πόλεμον, οὐχὶ καθίσας πρῶτον βουλεύσεται εἰ δυνατός ἐστιν ἐν δέκα χιλιάσιν ὑπαντῆσαι τῷ μετὰ εἴκοσι χιλιάδων ἐρχομένῳ ἐπʼ αὐτόν;
42014032 Εἰ δὲ μή γε ἔτι αὐτοῦ πόρρω ὄντος, πρεσβείαν ἀποστείλας ἐρωτᾷ εἰρήνην.
42014033 Οὕτως οὖν πᾶς ἐξ ὑμῶν ὃς οὐκ ἀποτάσσεται πᾶσιν τοῖς ἑαυτοῦ ὑπάρχουσιν, οὐ δύναται εἶναί μου μαθητής.
42014034 Καλὸν οὖν τὸ ἅλας, ἐὰν δὲ τὸ ἅλας μωρανθῇ, ἐν τίνι ἀρτυθήσεται;
42014035 Οὔτε εἰς γῆν οὔτε εἰς κοπρίαν εὔθετόν ἐστιν· ἔξω βάλλουσιν αὐτό. Ὁ ἔχων ὦτα ἀκούειν, ἀκουέτω.”
42015001 Ἦσαν δὲ αὐτῷ ἐγγίζοντες πάντες οἱ τελῶναι καὶ οἱ ἁμαρτωλοὶ ἀκούειν αὐτοῦ.
42015002 Καὶ διεγόγγυζον οἵ τε Φαρισαῖοι καὶ οἱ γραμματεῖς λέγοντες, ὅτι “Οὗτος ἁμαρτωλοὺς προσδέχεται καὶ συνεσθίει αὐτοῖς.”
42015003 Εἶπεν δὲ πρὸς αὐτοὺς τὴν παραβολὴν ταύτην λέγων,
42015004 “Τίς ἄνθρωπος ἐξ ὑμῶν, ἔχων ἑκατὸν πρόβατα καὶ ἀπολέσας ἐξ αὐτῶν ἓν, οὐ καταλείπει τὰ ἐνενήκοντα ἐννέα ἐν τῇ ἐρήμῳ, καὶ πορεύεται ἐπὶ τὸ ἀπολωλὸς, ἕως εὕρῃ αὐτό;
42015005 Καὶ εὑρὼν, ἐπιτίθησιν ἐπὶ τοὺς ὤμους αὐτοῦ χαίρων.
42015006 Καὶ ἐλθὼν εἰς τὸν οἶκον, συγκαλεῖ τοὺς φίλους καὶ τοὺς γείτονας, λέγων αὐτοῖς, ‘Συγχάρητέ μοι, ὅτι εὗρον τὸ πρόβατόν μου τὸ ἀπολωλός!’
42015007 Λέγω ὑμῖν ὅτι οὕτως χαρὰ ἐν τῷ οὐρανῷ ἔσται ἐπὶ ἑνὶ ἁμαρτωλῷ μετανοοῦντι, ἢ ἐπὶ ἐνενήκοντα ἐννέα δικαίοις, οἵτινες οὐ χρείαν ἔχουσιν μετανοίας.
42015008 Ἢ τίς γυνὴ δραχμὰς ἔχουσα δέκα, ἐὰν ἀπολέσῃ δραχμὴν μίαν, οὐχὶ ἅπτει λύχνον, καὶ σαροῖ τὴν οἰκίαν, καὶ ζητεῖ ἐπιμελῶς, ἕως οὗ εὕρῃ;
42015009 Καὶ εὑροῦσα, συγκαλεῖ τὰς φίλας καὶ γείτονας λέγουσα, ‘Συγχάρητέ μοι, ὅτι εὗρον τὴν δραχμὴν ἣν ἀπώλεσα.’
42015010 Οὕτως λέγω ὑμῖν, γίνεται χαρὰ ἐνώπιον τῶν ἀγγέλων τοῦ ˚Θεοῦ ἐπὶ ἑνὶ ἁμαρτωλῷ μετανοοῦντι.”
42015011 Εἶπεν δέ, “Ἄνθρωπός τις εἶχεν δύο υἱούς.
42015012 Καὶ εἶπεν ὁ νεώτερος αὐτῶν τῷ πατρί, ‘Πάτερ, δός μοι τὸ ἐπιβάλλον μέρος τῆς οὐσίας.’ ὁ δὲ διεῖλεν αὐτοῖς τὸν βίον.
42015013 Καὶ μετʼ οὐ πολλὰς ἡμέρας συναγαγὼν πάντα, ὁ νεώτερος υἱὸς ἀπεδήμησεν εἰς χώραν μακράν, καὶ ἐκεῖ διεσκόρπισεν τὴν οὐσίαν αὐτοῦ, ζῶν ἀσώτως.
42015014 Δαπανήσαντος δὲ αὐτοῦ πάντα, ἐγένετο λιμὸς ἰσχυρὰ κατὰ τὴν χώραν ἐκείνην, καὶ αὐτὸς ἤρξατο ὑστερεῖσθαι.
42015015 Καὶ πορευθεὶς, ἐκολλήθη ἑνὶ τῶν πολιτῶν τῆς χώρας ἐκείνης, καὶ ἔπεμψεν αὐτὸν εἰς τοὺς ἀγροὺς αὐτοῦ βόσκειν χοίρους.
42015016 Καὶ ἐπεθύμει χορτασθῆναι ἐκ τῶν κερατίων ὧν ἤσθιον οἱ χοῖροι, καὶ οὐδεὶς ἐδίδου αὐτῷ.
42015017 Εἰς ἑαυτὸν δὲ ἐλθὼν ἔφη, ‘Πόσοι μίσθιοι τοῦ πατρός μου περισσεύονται ἄρτων, ἐγὼ δὲ λιμῷ ὧδε ἀπόλλυμαι;
42015018 Ἀναστὰς, πορεύσομαι πρὸς τὸν πατέρα μου, καὶ ἐρῶ αὐτῷ, “Πάτερ, ἥμαρτον εἰς τὸν οὐρανὸν καὶ ἐνώπιόν σου,
42015019 οὐκέτι εἰμὶ ἄξιος κληθῆναι υἱός σου. Ποίησόν με ὡς ἕνα τῶν μισθίων σου.”’
42015020 Καὶ ἀναστὰς, ἦλθεν πρὸς τὸν πατέρα ἑαυτοῦ. Ἔτι δὲ αὐτοῦ μακρὰν ἀπέχοντος, εἶδεν αὐτὸν ὁ πατὴρ αὐτοῦ, καὶ ἐσπλαγχνίσθη, καὶ δραμὼν, ἐπέπεσεν ἐπὶ τὸν τράχηλον αὐτοῦ καὶ κατεφίλησεν αὐτόν.
42015021 Εἶπεν δὲ ὁ υἱὸς αὐτῷ, ‘Πάτερ, ἥμαρτον εἰς τὸν οὐρανὸν καὶ ἐνώπιόν σου, οὐκέτι εἰμὶ ἄξιος κληθῆναι υἱός σου.’
42015022 Εἶπεν δὲ ὁ πατὴρ πρὸς τοὺς δούλους αὐτοῦ, ‘Ταχὺ ἐξενέγκατε στολὴν τὴν πρώτην, καὶ ἐνδύσατε αὐτόν, καὶ δότε δακτύλιον εἰς τὴν χεῖρα αὐτοῦ, καὶ ὑποδήματα εἰς τοὺς πόδας,
42015023 καὶ φέρετε τὸν μόσχον τὸν σιτευτόν, θύσατε, καὶ φαγόντες εὐφρανθῶμεν.
42015024 Ὅτι οὗτος ὁ υἱός μου νεκρὸς ἦν καὶ ἀνέζησεν, ἦν ἀπολωλὼς καὶ εὑρέθη.’ Καὶ ἤρξαντο εὐφραίνεσθαι.
42015025 Ἦν δὲ ὁ υἱὸς αὐτοῦ ὁ πρεσβύτερος ἐν ἀγρῷ· καὶ ὡς ἐρχόμενος, ἤγγισεν τῇ οἰκίᾳ, ἤκουσεν συμφωνίας καὶ χορῶν.
42015026 Καὶ προσκαλεσάμενος ἕνα τῶν παίδων, ἐπυνθάνετο τί ἂν εἴη ταῦτα.
42015027 Ὁ δὲ εἶπεν αὐτῷ, ὅτι ‘Ὁ ἀδελφός σου ἥκει, καὶ ἔθυσεν ὁ πατήρ σου τὸν μόσχον τὸν σιτευτόν, ὅτι ὑγιαίνοντα αὐτὸν ἀπέλαβεν.’
42015028 Ὠργίσθη δὲ καὶ οὐκ ἤθελεν εἰσελθεῖν. Ὁ δὲ πατὴρ αὐτοῦ ἐξελθὼν, παρεκάλει αὐτόν.
42015029 Ὁ δὲ ἀποκριθεὶς εἶπεν τῷ πατρὶ αὐτοῦ, ‘Ἰδοὺ, τοσαῦτα ἔτη δουλεύω σοι, καὶ οὐδέποτε ἐντολήν σου παρῆλθον, καὶ ἐμοὶ οὐδέποτε ἔδωκας ἔριφον, ἵνα μετὰ τῶν φίλων μου εὐφρανθῶ.
42015030 Ὅτε δὲ ὁ υἱός σου οὗτος, ὁ καταφαγών σου τὸν βίον μετὰ πορνῶν ἦλθεν, ἔθυσας αὐτῷ τὸν σιτευτὸν μόσχον!’
42015031 Ὁ δὲ εἶπεν αὐτῷ, ‘Τέκνον, σὺ πάντοτε μετʼ ἐμοῦ εἶ, καὶ πάντα τὰ ἐμὰ σά ἐστιν.
42015032 Εὐφρανθῆναι δὲ καὶ χαρῆναι ἔδει, ὅτι ὁ ἀδελφός σου οὗτος, νεκρὸς ἦν καὶ ἔζησεν, καὶ ἀπολωλὼς καὶ εὑρέθη.’”
42016001 Ἔλεγεν δὲ καὶ πρὸς τοὺς μαθητάς, “Ἄνθρωπός τις ἦν πλούσιος, ὃς εἶχεν οἰκονόμον, καὶ οὗτος διεβλήθη αὐτῷ ὡς διασκορπίζων τὰ ὑπάρχοντα αὐτοῦ.
42016002 Καὶ φωνήσας αὐτὸν, εἶπεν αὐτῷ, ‘Τί τοῦτο ἀκούω περὶ σοῦ; Ἀπόδος τὸν λόγον τῆς οἰκονομίας σου, οὐ γὰρ δύνῃ ἔτι οἰκονομεῖν.’
42016003 Εἶπεν δὲ ἐν ἑαυτῷ ὁ οἰκονόμος, ‘Τί ποιήσω, ὅτι ὁ κύριός μου ἀφαιρεῖται τὴν οἰκονομίαν ἀπʼ ἐμοῦ; Σκάπτειν οὐκ ἰσχύω, ἐπαιτεῖν αἰσχύνομαι.
42016004 Ἔγνων τί ποιήσω, ἵνα ὅταν μετασταθῶ ἐκ τῆς οἰκονομίας, δέξωνταί με εἰς τοὺς οἴκους αὐτῶν.’
42016005 Καὶ προσκαλεσάμενος ἕνα ἕκαστον τῶν χρεοφειλετῶν τοῦ κυρίου ἑαυτοῦ, ἔλεγεν τῷ πρώτῳ, ‘Πόσον ὀφείλεις τῷ κυρίῳ μου;’
42016006 Ὁ δὲ εἶπεν, ‘Ἑκατὸν βάτους ἐλαίου.’ Ὁ δὲ εἶπεν αὐτῷ, ‘Δέξαι σου τὰ γράμματα καὶ καθίσας ταχέως γράψον πεντήκοντα.’
42016007 Ἔπειτα ἑτέρῳ εἶπεν, ‘Σὺ δὲ πόσον ὀφείλεις;’ Ὁ δὲ εἶπεν, ‘Ἑκατὸν κόρους σίτου.’ Λέγει αὐτῷ, ‘Δέξαι σου τὰ γράμματα καὶ γράψον ὀγδοήκοντα.’
42016008 Καὶ ἐπῄνεσεν ὁ κύριος τὸν οἰκονόμον τῆς ἀδικίας, ὅτι φρονίμως ἐποίησεν. Ὅτι οἱ υἱοὶ τοῦ αἰῶνος τούτου φρονιμώτεροι ὑπὲρ τοὺς υἱοὺς τοῦ φωτὸς εἰς τὴν γενεὰν τὴν ἑαυτῶν εἰσιν.
42016009 Καὶ ἐγὼ ὑμῖν λέγω, ἑαυτοῖς ποιήσατε φίλους ἐκ τοῦ μαμωνᾶ τῆς ἀδικίας, ἵνα ὅταν ἐκλίπῃ, δέξωνται ὑμᾶς εἰς τὰς αἰωνίους σκηνάς.
42016010 Ὁ πιστὸς ἐν ἐλαχίστῳ, καὶ ἐν πολλῷ πιστός ἐστιν, καὶ ὁ ἐν ἐλαχίστῳ ἄδικος, καὶ ἐν πολλῷ ἄδικός ἐστιν.
42016011 Εἰ οὖν ἐν τῷ ἀδίκῳ μαμωνᾷ, πιστοὶ οὐκ ἐγένεσθε, τὸ ἀληθινὸν τίς ὑμῖν πιστεύσει;
42016012 Καὶ εἰ ἐν τῷ ἀλλοτρίῳ, πιστοὶ οὐκ ἐγένεσθε, τὸ ὑμέτερον τίς ὑμῖν δώσει;
42016013 Οὐδεὶς οἰκέτης δύναται δυσὶ κυρίοις δουλεύειν, ἢ γὰρ τὸν ἕνα μισήσει, καὶ τὸν ἕτερον ἀγαπήσει, ἢ ἑνὸς ἀνθέξεται, καὶ τοῦ ἑτέρου καταφρονήσει. Οὐ δύνασθε ˚Θεῷ δουλεύειν καὶ μαμωνᾷ.”
42016014 Ἤκουον δὲ ταῦτα πάντα οἱ Φαρισαῖοι, φιλάργυροι ὑπάρχοντες, καὶ ἐξεμυκτήριζον αὐτόν.
42016015 Καὶ εἶπεν αὐτοῖς, “Ὑμεῖς ἐστε οἱ δικαιοῦντες ἑαυτοὺς ἐνώπιον τῶν ἀνθρώπων, ὁ δὲ ˚Θεὸς γινώσκει τὰς καρδίας ὑμῶν· ὅτι τὸ ἐν ἀνθρώποις ὑψηλὸν, βδέλυγμα ἐνώπιον τοῦ ˚Θεοῦ.
42016016 Ὁ νόμος καὶ οἱ προφῆται μέχρι Ἰωάννου· ἀπὸ τότε ἡ Βασιλεία τοῦ ˚Θεοῦ εὐαγγελίζεται, καὶ πᾶς εἰς αὐτὴν βιάζεται.
42016017 Εὐκοπώτερον δέ ἐστιν τὸν οὐρανὸν καὶ τὴν γῆν παρελθεῖν, ἢ τοῦ νόμου μίαν κεραίαν πεσεῖν.
42016018 Πᾶς ὁ ἀπολύων τὴν γυναῖκα αὐτοῦ καὶ γαμῶν ἑτέραν, μοιχεύει, καὶ πᾶς ὁ ἀπολελυμένην ἀπὸ ἀνδρὸς γαμῶν, μοιχεύει.
42016019 Ἄνθρωπος δέ τις ἦν πλούσιος, καὶ ἐνεδιδύσκετο πορφύραν καὶ βύσσον, εὐφραινόμενος καθʼ ἡμέραν λαμπρῶς.
42016020 Πτωχὸς δέ τις ὀνόματι Λάζαρος, ἐβέβλητο πρὸς τὸν πυλῶνα αὐτοῦ, εἱλκωμένος
42016021 καὶ ἐπιθυμῶν χορτασθῆναι ἀπὸ τῶν πιπτόντων ἀπὸ τῆς τραπέζης τοῦ πλουσίου· ἀλλὰ καὶ οἱ κύνες ἐρχόμενοι ἐπέλειχον τὰ ἕλκη αὐτοῦ.
42016022 Ἐγένετο δὲ ἀποθανεῖν τὸν πτωχὸν, καὶ ἀπενεχθῆναι αὐτὸν ὑπὸ τῶν ἀγγέλων εἰς τὸν κόλπον Ἀβραάμ. Ἀπέθανεν δὲ καὶ ὁ πλούσιος καὶ ἐτάφη.
42016023 Καὶ ἐν τῷ ᾅδῃ ἐπάρας τοὺς ὀφθαλμοὺς αὐτοῦ, ὑπάρχων ἐν βασάνοις, ὁρᾷ Ἀβραὰμ ἀπὸ μακρόθεν, καὶ Λάζαρον ἐν τοῖς κόλποις αὐτοῦ.
42016024 Καὶ αὐτὸς φωνήσας εἶπεν, Πάτερ ‘Ἀβραάμ, ἐλέησόν με καὶ πέμψον Λάζαρον, ἵνα βάψῃ τὸ ἄκρον τοῦ δακτύλου αὐτοῦ ὕδατος, καὶ καταψύξῃ τὴν γλῶσσάν μου, ὅτι ὀδυνῶμαι ἐν τῇ φλογὶ ταύτῃ.’
42016025 Εἶπεν δὲ Ἀβραάμ, ‘Τέκνον, μνήσθητι ὅτι ἀπέλαβες τὰ ἀγαθά σου ἐν τῇ ζωῇ σου, καὶ Λάζαρος ὁμοίως τὰ κακά. Νῦν δὲ ὧδε παρακαλεῖται, σὺ δὲ ὀδυνᾶσαι.
42016026 Καὶ ἐν πᾶσι τούτοις, μεταξὺ ἡμῶν καὶ ὑμῶν χάσμα μέγα ἐστήρικται, ὅπως οἱ θέλοντες διαβῆναι ἔνθεν πρὸς ὑμᾶς μὴ δύνωνται, μηδὲ ἐκεῖθεν πρὸς ἡμᾶς διαπερῶσιν.’
42016027 Εἶπεν δέ, ‘Ἐρωτῶ σε οὖν Πάτερ, ἵνα πέμψῃς αὐτὸν εἰς τὸν οἶκον τοῦ πατρός μου,
42016028 ἔχω γὰρ πέντε ἀδελφούς, ὅπως διαμαρτύρηται αὐτοῖς, ἵνα μὴ καὶ αὐτοὶ ἔλθωσιν εἰς τὸν τόπον τοῦτον τῆς βασάνου.’
42016029 Λέγει δὲ Ἀβραάμ, ‘Ἔχουσι Μωϋσέα καὶ τοὺς προφήτας· ἀκουσάτωσαν αὐτῶν.’
42016030 Ὁ δὲ εἶπεν, ‘Οὐχί, Πάτερ Ἀβραάμ, ἀλλʼ ἐάν τις ἀπὸ νεκρῶν πορευθῇ πρὸς αὐτοὺς, μετανοήσουσιν.’
42016031 Εἶπεν δὲ αὐτῷ, ‘Εἰ Μωϋσέως καὶ τῶν προφητῶν οὐκ ἀκούουσιν, οὐδʼ ἐάν τις ἐκ νεκρῶν ἀναστῇ, πεισθήσονται.’”
42017001 Εἶπεν δὲ πρὸς τοὺς μαθητὰς αὐτοῦ, “Ἀνένδεκτόν ἐστιν τοῦ τὰ σκάνδαλα μὴ ἐλθεῖν, πλὴν οὐαὶ διʼ οὗ ἔρχεται!
42017002 Λυσιτελεῖ αὐτῷ εἰ λίθος μυλικὸς περίκειται περὶ τὸν τράχηλον αὐτοῦ, καὶ ἔρριπται εἰς τὴν θάλασσαν, ἢ ἵνα σκανδαλίσῃ τῶν μικρῶν τούτων ἕνα.
42017003 Προσέχετε ἑαυτοῖς! Ἐὰν ἁμάρτῃ ὁ ἀδελφός σου, ἐπιτίμησον αὐτῷ, καὶ ἐὰν μετανοήσῃ, ἄφες αὐτῷ.
42017004 Καὶ ἐὰν ἑπτάκις τῆς ἡμέρας ἁμαρτήσῃ εἰς σὲ, καὶ ἑπτάκις ἐπιστρέψῃ πρὸς σὲ, λέγων ‘Μετανοῶ’, ἀφήσεις αὐτῷ.”
42017005 Καὶ εἶπαν οἱ ἀπόστολοι τῷ ˚Κυρίῳ, “Πρόσθες ἡμῖν πίστιν!”
42017006 Εἶπεν δὲ ὁ ˚Κύριος, “Εἰ ἔχετε πίστιν ὡς κόκκον σινάπεως, ἐλέγετε ἂν τῇ συκαμίνῳ ταύτῃ, ‘Ἐκριζώθητι καὶ φυτεύθητι ἐν τῇ θαλάσσῃ’, καὶ ὑπήκουσεν ἂν ὑμῖν.
42017007 Τίς δὲ ἐξ ὑμῶν δοῦλον ἔχων, ἀροτριῶντα ἢ ποιμαίνοντα, ὃς εἰσελθόντι ἐκ τοῦ ἀγροῦ ἐρεῖ αὐτῷ, ‘Εὐθέως παρελθὼν ἀνάπεσε’;
42017008 Ἀλλʼ οὐχὶ ἐρεῖ αὐτῷ, ‘Ἑτοίμασον τί δειπνήσω, καὶ περιζωσάμενος διακόνει μοι, ἕως φάγω καὶ πίω, καὶ μετὰ ταῦτα φάγεσαι καὶ πίεσαι σύ’;
42017009 Μὴ ἔχει χάριν τῷ δούλῳ, ὅτι ἐποίησεν τὰ διαταχθέντα;
42017010 Οὕτως καὶ ὑμεῖς ὅταν ποιήσητε πάντα τὰ διαταχθέντα ὑμῖν, λέγετε, ὅτι ‘Δοῦλοι ἀχρεῖοί ἐσμεν, ὃ ὠφείλομεν ποιῆσαι πεποιήκαμεν.’”
42017011 Καὶ ἐγένετο ἐν τῷ πορεύεσθαι εἰς Ἰερουσαλὴμ, καὶ αὐτὸς διήρχετο διὰ μέσον Σαμαρείας καὶ Γαλιλαίας.
42017012 Καὶ εἰσερχομένου αὐτοῦ εἴς τινα κώμην, ἀπήντησαν δέκα λεπροὶ ἄνδρες, οἳ ἔστησαν πόρρωθεν.
42017013 Καὶ αὐτοὶ ἦραν φωνὴν λέγοντες, “˚Ἰησοῦ, Ἐπιστάτα, ἐλέησον ἡμᾶς.”
42017014 Καὶ ἰδὼν, εἶπεν αὐτοῖς, “Πορευθέντες ἐπιδείξατε ἑαυτοὺς τοῖς ἱερεῦσιν.” Καὶ ἐγένετο ἐν τῷ ὑπάγειν αὐτοὺς, ἐκαθαρίσθησαν.
42017015 Εἷς δὲ ἐξ αὐτῶν ἰδὼν ὅτι ἰάθη, ὑπέστρεψεν μετὰ φωνῆς μεγάλης δοξάζων τὸν ˚Θεόν,
42017016 καὶ ἔπεσεν ἐπὶ πρόσωπον παρὰ τοὺς πόδας αὐτοῦ, εὐχαριστῶν αὐτῷ· καὶ αὐτὸς ἦν Σαμαρείτης.
42017017 Ἀποκριθεὶς δὲ ὁ ˚Ἰησοῦς εἶπεν, “Οὐχ οἱ δέκα ἐκαθαρίσθησαν; Οἱ δὲ ἐννέα ποῦ;
42017018 Οὐχ εὑρέθησαν ὑποστρέψαντες δοῦναι δόξαν τῷ ˚Θεῷ, εἰ μὴ ὁ ἀλλογενὴς οὗτος;”
42017019 Καὶ εἶπεν αὐτῷ, “Ἀναστὰς, πορεύου· ἡ πίστις σου σέσωκέν σε!”
42017020 Ἐπερωτηθεὶς δὲ ὑπὸ τῶν Φαρισαίων πότε ἔρχεται ἡ Βασιλεία τοῦ ˚Θεοῦ, ἀπεκρίθη αὐτοῖς καὶ εἶπεν, “Οὐκ ἔρχεται ἡ Βασιλεία τοῦ ˚Θεοῦ μετὰ παρατηρήσεως,
42017021 οὐδὲ ἐροῦσιν, ‘Ἰδοὺ, ὧδε’, ἤ, ‘Ἐκεῖ’. Ἰδοὺ γὰρ, ἡ Βασιλεία τοῦ ˚Θεοῦ ἐντὸς ὑμῶν ἐστιν.”
42017022 Εἶπεν δὲ πρὸς τοὺς μαθητάς, “Ἐλεύσονται ἡμέραι ὅτε ἐπιθυμήσετε μίαν τῶν ἡμερῶν τοῦ Υἱοῦ τοῦ Ἀνθρώπου ἰδεῖν, καὶ οὐκ ὄψεσθε.
42017023 Καὶ ἐροῦσιν ὑμῖν, ‘Ἰδοὺ, ἐκεῖ’, ἤ, ‘Ἰδοὺ, ὧδε’· μὴ ἀπέλθητε μηδὲ διώξητε.
42017024 Ὥσπερ γὰρ ἡ ἀστραπὴ ἀστράπτουσα ἐκ τῆς ὑπὸ τὸν οὐρανὸν εἰς τὴν ὑπʼ οὐρανὸν λάμπει, οὕτως ἔσται ὁ Υἱὸς τοῦ Ἀνθρώπου.
42017025 Πρῶτον δὲ δεῖ αὐτὸν πολλὰ παθεῖν, καὶ ἀποδοκιμασθῆναι ἀπὸ τῆς γενεᾶς ταύτης.
42017026 Καὶ καθὼς ἐγένετο ἐν ταῖς ἡμέραις Νῶε, οὕτως ἔσται καὶ ἐν ταῖς ἡμέραις τοῦ Υἱοῦ τοῦ Ἀνθρώπου:
42017027 ἤσθιον, ἔπινον, ἐγάμουν, ἐγαμίζοντο, ἄχρι ἧς ἡμέρας εἰσῆλθεν Νῶε εἰς τὴν κιβωτόν, καὶ ἦλθεν ὁ κατακλυσμὸς καὶ ἀπώλεσεν πάντας.
42017028 Ὁμοίως καθὼς ἐγένετο ἐν ταῖς ἡμέραις Λώτ: ἤσθιον, ἔπινον, ἠγόραζον, ἐπώλουν, ἐφύτευον, ᾠκοδόμουν·
42017029 ᾗ δὲ ἡμέρᾳ ἐξῆλθεν Λὼτ ἀπὸ Σοδόμων, ἔβρεξεν πῦρ καὶ θεῖον ἀπʼ οὐρανοῦ καὶ ἀπώλεσεν πάντας.
42017030 Κατὰ ταῦτα ἔσται ᾗ ἡμέρᾳ, ὁ Υἱὸς τοῦ Ἀνθρώπου ἀποκαλύπτεται.
42017031 Ἐν ἐκείνῃ τῇ ἡμέρᾳ, ὃς ἔσται ἐπὶ τοῦ δώματος, καὶ τὰ σκεύη αὐτοῦ ἐν τῇ οἰκίᾳ, μὴ καταβάτω ἆραι αὐτά, καὶ ὁ ἐν ἀγρῷ, ὁμοίως μὴ ἐπιστρεψάτω εἰς τὰ ὀπίσω.
42017032 Μνημονεύετε τῆς γυναικὸς Λώτ.
42017033 Ὃς ἐὰν ζητήσῃ τὴν ψυχὴν αὐτοῦ περιποιήσασθαι, ἀπολέσει αὐτήν, ὃς δʼ ἂν ἀπολέσῃ, ζῳογονήσει αὐτήν.
42017034 Λέγω ὑμῖν, ταύτῃ τῇ νυκτὶ ἔσονται δύο ἐπὶ κλίνης μιᾶς, ὁ εἷς παραλημφθήσεται, καὶ ὁ ἕτερος ἀφεθήσεται.
42017035 Ἔσονται δύο ἀλήθουσαι ἐπὶ τὸ αὐτό, ἡ μία παραλημφθήσεται, ἡ δὲ ἑτέρα ἀφεθήσεται.”
42017037 Καὶ ἀποκριθέντες λέγουσιν αὐτῷ, “Ποῦ, ˚Κύριε;” Ὁ δὲ εἶπεν αὐτοῖς, “Ὅπου τὸ σῶμα, ἐκεῖ καὶ οἱ ἀετοὶ ἐπισυναχθήσονται.”
42018001 Ἔλεγεν δὲ παραβολὴν αὐτοῖς, πρὸς τὸ δεῖν πάντοτε προσεύχεσθαι αὐτοὺς καὶ μὴ ἐγκακεῖν
42018002 λέγων, “Κριτής τις ἦν ἔν τινι πόλει, τὸν ˚Θεὸν μὴ φοβούμενος καὶ ἄνθρωπον μὴ ἐντρεπόμενος.
42018003 Χήρα δὲ ἦν ἐν τῇ πόλει ἐκείνῃ, καὶ ἤρχετο πρὸς αὐτὸν λέγουσα, ‘Ἐκδίκησόν με ἀπὸ τοῦ ἀντιδίκου μου.’
42018004 Καὶ οὐκ ἤθελεν ἐπὶ χρόνον, μετὰ ταῦτα δὲ εἶπεν ἐν ἑαυτῷ, ‘Εἰ καὶ τὸν ˚Θεὸν οὐ φοβοῦμαι οὐδὲ ἄνθρωπον ἐντρέπομαι,
42018005 διά γε τὸ παρέχειν μοι κόπον τὴν χήραν ταύτην, ἐκδικήσω αὐτήν, ἵνα μὴ εἰς τέλος ἐρχομένη ὑπωπιάζῃ με.’”
42018006 Εἶπεν δὲ ὁ ˚Κύριος, “Ἀκούσατε τί ὁ κριτὴς τῆς ἀδικίας λέγει.
42018007 Ὁ δὲ ˚Θεὸς οὐ μὴ ποιήσῃ τὴν ἐκδίκησιν τῶν ἐκλεκτῶν αὐτοῦ, τῶν βοώντων αὐτῷ ἡμέρας καὶ νυκτός, καὶ μακροθυμεῖ ἐπʼ αὐτοῖς;
42018008 Λέγω ὑμῖν ὅτι ποιήσει τὴν ἐκδίκησιν αὐτῶν ἐν τάχει. Πλὴν ὁ Υἱὸς τοῦ Ἀνθρώπου ἐλθὼν, ἆρα εὑρήσει τὴν πίστιν ἐπὶ τῆς γῆς;”
42018009 Εἶπεν δὲ καὶ πρός τινας τοὺς πεποιθότας ἐφʼ ἑαυτοῖς ὅτι εἰσὶν δίκαιοι καὶ ἐξουθενοῦντας τοὺς λοιποὺς, τὴν παραβολὴν ταύτην:
42018010 “Ἄνθρωποι δύο ἀνέβησαν εἰς τὸ ἱερὸν προσεύξασθαι, ὁ εἷς Φαρισαῖος, καὶ ὁ ἕτερος τελώνης.
42018011 Ὁ Φαρισαῖος σταθεὶς ταῦτα πρὸς ἑαυτὸν προσηύχετο: ‘Ὁ ˚Θεός, εὐχαριστῶ σοι ὅτι οὐκ εἰμὶ ὥσπερ οἱ λοιποὶ τῶν ἀνθρώπων, ἅρπαγες, ἄδικοι, μοιχοί, ἢ καὶ ὡς οὗτος ὁ τελώνης.
42018012 Νηστεύω δὶς τοῦ σαββάτου, ἀποδεκατῶ πάντα, ὅσα κτῶμαι.’
42018013 Ὁ δὲ τελώνης μακρόθεν ἑστὼς, οὐκ ἤθελεν οὐδὲ τοὺς ὀφθαλμοὺς ἐπᾶραι εἰς τὸν οὐρανόν, ἀλλʼ ἔτυπτεν τὸ στῆθος αὐτοῦ λέγων, ‘Ὁ ˚Θεός, ἱλάσθητί μοι, τῷ ἁμαρτωλῷ!’
42018014 Λέγω ὑμῖν, κατέβη οὗτος δεδικαιωμένος εἰς τὸν οἶκον αὐτοῦ, παρʼ ἐκεῖνον. Ὅτι πᾶς ὁ ὑψῶν ἑαυτὸν ταπεινωθήσεται, ὁ δὲ ταπεινῶν ἑαυτὸν ὑψωθήσεται.”
42018015 Προσέφερον δὲ αὐτῷ καὶ τὰ βρέφη, ἵνα αὐτῶν ἅπτηται· ἰδόντες δὲ, οἱ μαθηταὶ ἐπετίμων αὐτοῖς.
42018016 Ὁ δὲ ˚Ἰησοῦς προσεκαλέσατο αὐτὰ λέγων, “Ἄφετε τὰ παιδία ἔρχεσθαι πρός με, καὶ μὴ κωλύετε αὐτά, τῶν γὰρ τοιούτων ἐστὶν ἡ Βασιλεία τοῦ ˚Θεοῦ.
42018017 Ἀμὴν, λέγω ὑμῖν, ὃς ἂν μὴ δέξηται τὴν Βασιλείαν τοῦ ˚Θεοῦ ὡς παιδίον, οὐ μὴ εἰσέλθῃ εἰς αὐτήν.”
42018018 Καὶ ἐπηρώτησέν τις αὐτὸν ἄρχων λέγων, “Διδάσκαλε ἀγαθέ, τί ποιήσας, ζωὴν αἰώνιον κληρονομήσω;”
42018019 Εἶπεν δὲ αὐτῷ ὁ ˚Ἰησοῦς, “Τί με λέγεις ἀγαθόν; Οὐδεὶς ἀγαθὸς, εἰ μὴ εἷς ὁ ˚Θεός.
42018020 Τὰς ἐντολὰς οἶδας: ‘Μὴ μοιχεύσῃς, μὴ φονεύσῃς, μὴ κλέψῃς, μὴ ψευδομαρτυρήσῃς’, ‘Τίμα τὸν πατέρα σου καὶ τὴν μητέρα.’”
42018021 Ὁ δὲ εἶπεν, “Ταῦτα πάντα ἐφύλαξα ἐκ νεότητος μου.”
42018022 Ἀκούσας δὲ, ὁ ˚Ἰησοῦς εἶπεν αὐτῷ, “Ἔτι ἕν σοι λείπει· πάντα ὅσα ἔχεις, πώλησον καὶ διάδος πτωχοῖς, καὶ ἕξεις θησαυρὸν ἐν οὐρανοῖς, καὶ δεῦρο, ἀκολούθει μοι.”
42018023 Ὁ δὲ ἀκούσας ταῦτα, περίλυπος ἐγενήθη, ἦν γὰρ πλούσιος σφόδρα.
42018024 Ἰδὼν δὲ αὐτὸν ὁ ˚Ἰησοῦς εἶπεν, “Πῶς δυσκόλως οἱ τὰ χρήματα ἔχοντες, εἰς τὴν Βασιλείαν τοῦ ˚Θεοῦ εἰσελεύσονται.
42018025 Εὐκοπώτερον γάρ ἐστιν κάμηλον διὰ τρήματος βελόνης εἰσελθεῖν, ἢ πλούσιον εἰς τὴν Βασιλείαν τοῦ ˚Θεοῦ εἰσελθεῖν.”
42018026 Εἶπον δὲ οἱ ἀκούσαντες, “Καὶ τίς δύναται σωθῆναι;”
42018027 Ὁ δὲ εἶπεν, “Τὰ ἀδύνατα παρὰ ἀνθρώποις, δυνατὰ παρὰ τῷ ˚Θεῷ ἐστιν.”
42018028 Εἶπεν δὲ ὁ Πέτρος, “Ἰδοὺ, ἡμεῖς ἀφήκαμεν πάντα καὶ ἠκολουθήσαμέν σοι.”
42018029 Ὁ δὲ εἶπεν αὐτοῖς, “Ἀμὴν, λέγω ὑμῖν ὅτι οὐδείς ἐστιν ὃς ἀφῆκεν οἰκίαν, ἢ γυναῖκα, ἢ ἀδελφοὺς, ἢ γονεῖς, ἢ τέκνα, εἵνεκεν τῆς Βασιλείας τοῦ ˚Θεοῦ,
42018030 ὃς οὐχὶ μὴ ἀπολάβῃ πολλαπλασίονα ἐν τῷ καιρῷ τούτῳ, καὶ ἐν τῷ αἰῶνι τῷ ἐρχομένῳ, ζωὴν αἰώνιον.”
42018031 Παραλαβὼν δὲ τοὺς δώδεκα, εἶπεν πρὸς αὐτούς, “Ἰδοὺ, ἀναβαίνομεν εἰς Ἰερουσαλήμ, καὶ τελεσθήσεται πάντα τὰ γεγραμμένα διὰ τῶν προφητῶν τῷ Υἱῷ τοῦ Ἀνθρώπου.
42018032 Παραδοθήσεται γὰρ τοῖς ἔθνεσιν, καὶ ἐμπαιχθήσεται, καὶ ὑβρισθήσεται, καὶ ἐμπτυσθήσεται.
42018033 Καὶ μαστιγώσαντες, ἀποκτενοῦσιν αὐτόν, καὶ τῇ ἡμέρᾳ τῇ τρίτῃ, ἀναστήσεται.”
42018034 Καὶ αὐτοὶ οὐδὲν τούτων συνῆκαν, καὶ ἦν τὸ ῥῆμα τοῦτο κεκρυμμένον ἀπʼ αὐτῶν, καὶ οὐκ ἐγίνωσκον τὰ λεγόμενα.
42018035 Ἐγένετο δὲ ἐν τῷ ἐγγίζειν αὐτὸν εἰς Ἰεριχὼ, τυφλός τις ἐκάθητο παρὰ τὴν ὁδὸν ἐπαιτῶν.
42018036 Ἀκούσας δὲ ὄχλου διαπορευομένου, ἐπυνθάνετο τί εἴη τοῦτο.
42018037 Ἀπήγγειλαν δὲ αὐτῷ, ὅτι “˚Ἰησοῦς ὁ Ναζωραῖος παρέρχεται.”
42018038 Καὶ ἐβόησεν λέγων, “˚Ἰησοῦ, Υἱὲ Δαυίδ, ἐλέησόν με.”
42018039 Καὶ οἱ προάγοντες ἐπετίμων αὐτῷ ἵνα σιγήσῃ. Αὐτὸς δὲ πολλῷ μᾶλλον ἔκραζεν, “Υἱὲ Δαυίδ, ἐλέησόν με.”
42018040 Σταθεὶς δὲ, ˚Ἰησοῦς ἐκέλευσεν αὐτὸν ἀχθῆναι πρὸς αὐτόν. Ἐγγίσαντος δὲ αὐτοῦ, ἐπηρώτησεν αὐτόν,
42018041 “Τί σοι θέλεις ποιήσω;” Ὁ δὲ εἶπεν, “˚Κύριε, ἵνα ἀναβλέψω.”
42018042 Καὶ ὁ ˚Ἰησοῦς εἶπεν αὐτῷ, “Ἀνάβλεψον· ἡ πίστις σου σέσωκέν σε.”
42018043 Καὶ παραχρῆμα ἀνέβλεψεν, καὶ ἠκολούθει αὐτῷ, δοξάζων τὸν ˚Θεόν. Καὶ πᾶς ὁ λαὸς ἰδὼν, ἔδωκεν αἶνον τῷ ˚Θεῷ.
42019001 Καὶ εἰσελθὼν, διήρχετο τὴν Ἰεριχώ.
42019002 Καὶ ἰδοὺ, ἀνὴρ ὀνόματι καλούμενος Ζακχαῖος, καὶ αὐτὸς ἦν ἀρχιτελώνης, καὶ αὐτὸς πλούσιος.
42019003 Καὶ ἐζήτει ἰδεῖν τὸν ˚Ἰησοῦν τίς ἐστιν, καὶ οὐκ ἠδύνατο ἀπὸ τοῦ ὄχλου, ὅτι τῇ ἡλικίᾳ μικρὸς ἦν.
42019004 Καὶ προδραμὼν ἔμπροσθεν, ἀνέβη ἐπὶ συκομορέαν, ἵνα ἴδῃ αὐτόν, ὅτι ἐκείνης ἤμελλεν διέρχεσθαι.
42019005 Καὶ ὡς ἦλθεν ἐπὶ τὸν τόπον, ἀναβλέψας, ὁ ˚Ἰησοῦς εἶπεν πρὸς αὐτόν, “Ζακχαῖε, σπεύσας κατάβηθι, σήμερον γὰρ ἐν τῷ οἴκῳ σου δεῖ με μεῖναι.”
42019006 Καὶ σπεύσας, κατέβη καὶ ὑπεδέξατο αὐτὸν χαίρων.
42019007 Καὶ ἰδόντες, πάντες διεγόγγυζον λέγοντες, ὅτι “Παρὰ ἁμαρτωλῷ ἀνδρὶ εἰσῆλθεν καταλῦσαι.”
42019008 Σταθεὶς δὲ, Ζακχαῖος εἶπεν πρὸς τὸν ˚Κύριον, “Ἰδοὺ, τὰ ἡμίσιά μου τῶν ὑπαρχόντων, ˚Κύριε, τοῖς πτωχοῖς δίδωμι, καὶ εἴ τινός τι ἐσυκοφάντησα, ἀποδίδωμι τετραπλοῦν.”
42019009 Εἶπεν δὲ πρὸς αὐτὸν ὁ ˚Ἰησοῦς, ὅτι “Σήμερον σωτηρία τῷ οἴκῳ τούτῳ ἐγένετο, καθότι καὶ αὐτὸς υἱὸς Ἀβραάμ ἐστιν.
42019010 Ἦλθεν γὰρ ὁ Υἱὸς τοῦ Ἀνθρώπου ζητῆσαι καὶ σῶσαι τὸ ἀπολωλός.”
42019011 Ἀκουόντων δὲ αὐτῶν ταῦτα προσθεὶς, εἶπεν παραβολὴν διὰ τὸ ἐγγὺς εἶναι Ἰερουσαλὴμ αὐτὸν, καὶ δοκεῖν αὐτοὺς ὅτι παραχρῆμα μέλλει ἡ Βασιλεία τοῦ ˚Θεοῦ ἀναφαίνεσθαι.
42019012 Εἶπεν οὖν, “Ἄνθρωπός τις εὐγενὴς ἐπορεύθη εἰς χώραν μακρὰν, λαβεῖν ἑαυτῷ βασιλείαν καὶ ὑποστρέψαι.
42019013 Καλέσας δὲ δέκα δούλους ἑαυτοῦ, ἔδωκεν αὐτοῖς δέκα μνᾶς, καὶ εἶπεν πρὸς αὐτούς, ‘Πραγματεύσασθε ἐν ᾧ ἔρχομαι.’
42019014 Οἱ δὲ πολῖται αὐτοῦ ἐμίσουν αὐτόν, καὶ ἀπέστειλαν πρεσβείαν ὀπίσω αὐτοῦ λέγοντες, ‘Οὐ θέλομεν τοῦτον βασιλεῦσαι ἐφʼ ἡμᾶς.’
42019015 Καὶ ἐγένετο ἐν τῷ ἐπανελθεῖν αὐτὸν, λαβόντα τὴν βασιλείαν, καὶ εἶπεν φωνηθῆναι αὐτῷ τοὺς δούλους τούτους οἷς δεδώκει τὸ ἀργύριον, ἵνα γνοῖ τί διεπραγματεύσαντο.
42019016 Παρεγένετο δὲ ὁ πρῶτος λέγων, ‘Κύριε, ἡ μνᾶ σου, δέκα προσηργάσατο μνᾶς.’
42019017 Καὶ εἶπεν αὐτῷ, ‘Εὖ ἀγαθὲ δοῦλε! Ὅτι ἐν ἐλαχίστῳ, πιστὸς ἐγένου, ἴσθι ἐξουσίαν ἔχων ἐπάνω δέκα πόλεων.’
42019018 Καὶ ἦλθεν ὁ δεύτερος λέγων, ‘Ἡ μνᾶ σου, Κύριε, ἐποίησεν πέντε μνᾶς.’
42019019 Εἶπεν δὲ καὶ τούτῳ, ‘Καὶ σὺ ἐπάνω γίνου πέντε πόλεων.’
42019020 Καὶ ὁ ἕτερος ἦλθεν λέγων, ‘Κύριε, ἰδοὺ, ἡ μνᾶ σου, ἣν εἶχον ἀποκειμένην ἐν σουδαρίῳ.
42019021 Ἐφοβούμην γάρ σε, ὅτι ἄνθρωπος αὐστηρὸς εἶ, αἴρεις ὃ οὐκ ἔθηκας, καὶ θερίζεις ὃ οὐκ ἔσπειρας.’
42019022 Λέγει αὐτῷ, ‘Ἐκ τοῦ στόματός σου κρίνω σε, πονηρὲ δοῦλε. Ἤιδεις ὅτι ἐγὼ ἄνθρωπος αὐστηρός εἰμι, αἴρων ὃ οὐκ ἔθηκα, καὶ θερίζων ὃ οὐκ ἔσπειρα;
42019023 Καὶ διὰ τί οὐκ ἔδωκάς μου τὸ ἀργύριον ἐπὶ τράπεζαν, κἀγὼ ἐλθὼν, σὺν τόκῳ ἂν αὐτὸ ἔπραξα;’
42019024 Καὶ τοῖς παρεστῶσιν εἶπεν, ‘Ἄρατε ἀπʼ αὐτοῦ τὴν μνᾶν, καὶ δότε τῷ, τὰς δέκα μνᾶς ἔχοντι.’
42019025 Καὶ εἶπαν αὐτῷ, ‘Κύριε, ἔχει δέκα μνᾶς.’
42019026 Λέγω ὑμῖν ὅτι παντὶ τῷ ἔχοντι, δοθήσεται, ἀπὸ δὲ τοῦ μὴ ἔχοντος, καὶ ὃ ἔχει ἀρθήσεται.
42019027 Πλὴν τοὺς ἐχθρούς μου τούτους, τοὺς μὴ θελήσαντάς με βασιλεῦσαι ἐπʼ αὐτοὺς, ἀγάγετε ὧδε καὶ κατασφάξατε αὐτοὺς ἔμπροσθέν μου.”
42019028 Καὶ εἰπὼν ταῦτα ἐπορεύετο ἔμπροσθεν ἀναβαίνων εἰς Ἱεροσόλυμα.
42019029 Καὶ ἐγένετο ὡς ἤγγισεν εἰς Βηθφαγὴ καὶ Βηθανίαν, πρὸς τὸ ὄρος τὸ καλούμενον Ἐλαιῶν, ἀπέστειλεν δύο τῶν μαθητῶν
42019030 λέγων, “Ὑπάγετε εἰς τὴν κατέναντι κώμην, ἐν ᾗ εἰσπορευόμενοι εὑρήσετε πῶλον δεδεμένον, ἐφʼ ὃν οὐδεὶς πώποτε ἀνθρώπων ἐκάθισεν, καὶ λύσαντες αὐτὸν, ἀγάγετε.
42019031 Καὶ ἐάν τις ὑμᾶς ἐρωτᾷ, ‘Διὰ τί λύετε;’ Οὕτως ἐρεῖτε, ὅτι ‘Ὁ ˚Κύριος αὐτοῦ χρείαν ἔχει.’”
42019032 Ἀπελθόντες δὲ οἱ ἀπεσταλμένοι εὗρον καθὼς εἶπεν αὐτοῖς.
42019033 Λυόντων δὲ αὐτῶν τὸν πῶλον, εἶπαν οἱ κύριοι αὐτοῦ πρὸς αὐτούς, “Τί λύετε τὸν πῶλον;”
42019034 Οἱ δὲ εἶπαν, ὅτι “Ὁ ˚Κύριος αὐτοῦ χρείαν ἔχει.”
42019035 Καὶ ἤγαγον αὐτὸν πρὸς τὸν ˚Ἰησοῦν, καὶ ἐπιρίψαντες αὐτῶν τὰ ἱμάτια ἐπὶ τὸν πῶλον, ἐπεβίβασαν τὸν ˚Ἰησοῦν.
42019036 Πορευομένου δὲ αὐτοῦ, ὑπεστρώννυον τὰ ἱμάτια ἑαυτῶν ἐν τῇ ὁδῷ.
42019037 Ἐγγίζοντος δὲ αὐτοῦ ἤδη πρὸς τῇ καταβάσει τοῦ Ὄρους τῶν Ἐλαιῶν, ἤρξαντο ἅπαν τὸ πλῆθος τῶν μαθητῶν χαίροντες αἰνεῖν τὸν ˚Θεὸν φωνῇ μεγάλῃ περὶ πασῶν ὧν εἶδον δυνάμεων
42019038 λέγοντες, “‘Εὐλογημένος ὁ ἐρχόμενος ὁ βασιλεὺς ἐν ὀνόματι ˚Κυρίου’· ἐν οὐρανῷ εἰρήνη καὶ δόξα ἐν ὑψίστοις.”
42019039 Καί τινες τῶν Φαρισαίων ἀπὸ τοῦ ὄχλου εἶπαν πρὸς αὐτόν, “Διδάσκαλε, ἐπιτίμησον τοῖς μαθηταῖς σου.”
42019040 Καὶ ἀποκριθεὶς εἶπεν, “Λέγω ὑμῖν ὅτι ἐὰν οὗτοι σιωπήσουσιν, οἱ λίθοι κράξουσιν.”
42019041 Καὶ ὡς ἤγγισεν, ἰδὼν τὴν πόλιν, ἔκλαυσεν ἐπʼ αὐτήν
42019042 λέγων, ὅτι “Εἰ ἔγνως ἐν τῇ ἡμέρᾳ ταύτῃ, καὶ σὺ, τὰ πρὸς εἰρήνην· νῦν δὲ ἐκρύβη ἀπὸ ὀφθαλμῶν σου.
42019043 Ὅτι ἥξουσιν ἡμέραι ἐπὶ σὲ, καὶ παρεμβαλοῦσιν οἱ ἐχθροί σου χάρακά σοι, καὶ περικυκλώσουσίν σε καὶ συνέξουσίν σε πάντοθεν,
42019044 καὶ ἐδαφιοῦσίν σε καὶ τὰ τέκνα σου ἐν σοί, καὶ οὐκ ἀφήσουσιν λίθον ἐπὶ λίθον ἐν σοί, ἀνθʼ ὧν οὐκ ἔγνως τὸν καιρὸν τῆς ἐπισκοπῆς σου.”
42019045 Καὶ εἰσελθὼν εἰς τὸ ἱερὸν, ἤρξατο ἐκβάλλειν τοὺς πωλοῦντας,
42019046 λέγων αὐτοῖς, “Γέγραπται, ‘Ὁ οἶκός μου οἶκος προσευχῆς’, ὑμεῖς δὲ αὐτὸν ἐποιήσατε ‘σπήλαιον λῃστῶν’.”
42019047 Καὶ ἦν διδάσκων τὸ καθʼ ἡμέραν ἐν τῷ ἱερῷ· οἱ δὲ ἀρχιερεῖς καὶ οἱ γραμματεῖς ἐζήτουν αὐτὸν ἀπολέσαι καὶ οἱ πρῶτοι τοῦ λαοῦ,
42019048 καὶ οὐχ εὕρισκον τὸ τί ποιήσωσιν, ὁ λαὸς γὰρ ἅπας ἐξεκρέματο αὐτοῦ ἀκούων.
42020001 Καὶ ἐγένετο ἐν μιᾷ τῶν ἡμερῶν, διδάσκοντος αὐτοῦ τὸν λαὸν ἐν τῷ ἱερῷ, καὶ εὐαγγελιζομένου, ἐπέστησαν οἱ ἀρχιερεῖς καὶ οἱ γραμματεῖς σὺν τοῖς πρεσβυτέροις,
42020002 καὶ εἶπαν λέγοντες πρὸς αὐτόν, “Εἰπὸν ἡμῖν ἐν ποίᾳ ἐξουσίᾳ ταῦτα ποιεῖς, ἢ τίς ἐστιν ὁ δούς σοι τὴν ἐξουσίαν ταύτην;”
42020003 Ἀποκριθεὶς δὲ εἶπεν πρὸς αὐτούς, “Ἐρωτήσω ὑμᾶς κἀγὼ λόγον καὶ εἴπατέ μοι:
42020004 τὸ βάπτισμα Ἰωάννου, ἐξ οὐρανοῦ ἦν ἢ ἐξ ἀνθρώπων;”
42020005 Οἱ δὲ συνελογίσαντο πρὸς ἑαυτοὺς λέγοντες, ὅτι “Ἐὰν εἴπωμεν, ‘Ἐξ οὐρανοῦ’, ἐρεῖ, ‘Διὰ τί οὐκ ἐπιστεύσατε αὐτῷ;’
42020006 Ἐὰν δὲ εἴπωμεν, ‘Ἐξ ἀνθρώπων’, ὁ λαὸς ἅπας καταλιθάσει ἡμᾶς, πεπεισμένος γάρ ἐστιν Ἰωάννην προφήτην εἶναι.”
42020007 Καὶ ἀπεκρίθησαν, μὴ εἰδέναι πόθεν.
42020008 Καὶ ὁ ˚Ἰησοῦς εἶπεν αὐτοῖς, “Οὐδὲ ἐγὼ λέγω ὑμῖν ἐν ποίᾳ ἐξουσίᾳ ταῦτα ποιῶ.”
42020009 Ἤρξατο δὲ πρὸς τὸν λαὸν λέγειν τὴν παραβολὴν ταύτην: “Ἄνθρωπος ἐφύτευσεν ἀμπελῶνα, καὶ ἐξέδετο αὐτὸν γεωργοῖς, καὶ ἀπεδήμησεν χρόνους ἱκανούς.
42020010 Καὶ καιρῷ ἀπέστειλεν πρὸς τοὺς γεωργοὺς δοῦλον, ἵνα ἀπὸ τοῦ καρποῦ τοῦ ἀμπελῶνος δώσουσιν αὐτῷ. Οἱ δὲ γεωργοὶ ἐξαπέστειλαν αὐτὸν, δείραντες κενόν.
42020011 Καὶ προσέθετο ἕτερον πέμψαι δοῦλον· οἱ δὲ, κἀκεῖνον δείραντες καὶ ἀτιμάσαντες, ἐξαπέστειλαν κενόν.
42020012 Καὶ προσέθετο τρίτον πέμψαι· οἱ δὲ καὶ τοῦτον τραυματίσαντες, ἐξέβαλον.
42020013 Εἶπεν δὲ ὁ κύριος τοῦ ἀμπελῶνος, ‘Τί ποιήσω; Πέμψω τὸν υἱόν μου τὸν ἀγαπητόν· ἴσως τοῦτον ἐντραπήσονται.’
42020014 Ἰδόντες δὲ αὐτὸν, οἱ γεωργοὶ διελογίζοντο πρὸς ἀλλήλους λέγοντες, ‘Οὗτός ἐστιν ὁ κληρονόμος· ἀποκτείνωμεν αὐτόν, ἵνα ἡμῶν γένηται ἡ κληρονομία.’
42020015 Καὶ ἐκβαλόντες αὐτὸν ἔξω τοῦ ἀμπελῶνος, ἀπέκτειναν. Τί οὖν ποιήσει αὐτοῖς ὁ κύριος τοῦ ἀμπελῶνος;
42020016 Ἐλεύσεται καὶ ἀπολέσει τοὺς γεωργοὺς τούτους, καὶ δώσει τὸν ἀμπελῶνα ἄλλοις.” Ἀκούσαντες δὲ εἶπαν, “Μὴ γένοιτο!”
42020017 Ὁ δὲ ἐμβλέψας αὐτοῖς εἶπεν, “Τί οὖν ἐστιν τὸ γεγραμμένον τοῦτο, ‘Λίθον ὃν ἀπεδοκίμασαν οἱ οἰκοδομοῦντες, οὗτος ἐγενήθη εἰς κεφαλὴν γωνίας’;
42020018 Πᾶς ὁ πεσὼν ἐπʼ ἐκεῖνον τὸν λίθον, συνθλασθήσεται· ἐφʼ ὃν δʼ ἂν πέσῃ, λικμήσει αὐτόν.”
42020019 Καὶ ἐζήτησαν οἱ γραμματεῖς καὶ οἱ ἀρχιερεῖς ἐπιβαλεῖν ἐπʼ αὐτὸν τὰς χεῖρας, ἐν αὐτῇ τῇ ὥρᾳ, καὶ ἐφοβήθησαν τὸν λαόν, ἔγνωσαν γὰρ ὅτι πρὸς αὐτοὺς εἶπεν τὴν παραβολὴν ταύτην.
42020020 Καὶ παρατηρήσαντες, ἀπέστειλαν ἐγκαθέτους ὑποκρινομένους ἑαυτοὺς δικαίους εἶναι, ἵνα ἐπιλάβωνται αὐτοῦ λόγου, ὥστε παραδοῦναι αὐτὸν τῇ ἀρχῇ καὶ τῇ ἐξουσίᾳ τοῦ ἡγεμόνος.
42020021 Καὶ ἐπηρώτησαν αὐτὸν λέγοντες, “Διδάσκαλε, οἴδαμεν ὅτι ὀρθῶς λέγεις, καὶ διδάσκεις, καὶ οὐ λαμβάνεις πρόσωπον, ἀλλʼ ἐπʼ ἀληθείας τὴν ὁδὸν τοῦ ˚Θεοῦ διδάσκεις.
42020022 Ἔξεστιν ἡμᾶς Καίσαρι φόρον δοῦναι ἢ οὔ;”
42020023 Κατανοήσας δὲ αὐτῶν τὴν πανουργίαν, εἶπεν πρὸς αὐτούς,
42020024 “Δείξατέ μοι δηνάριον· τίνος ἔχει εἰκόνα καὶ ἐπιγραφήν;” οἱ δὲ εἶπαν, “Καίσαρος.”
42020025 Ὁ δὲ εἶπεν πρὸς αὐτούς, “Τοίνυν ἀπόδοτε τὰ Καίσαρος Καίσαρι, καὶ τὰ τοῦ ˚Θεοῦ τῷ ˚Θεῷ.”
42020026 Καὶ οὐκ ἴσχυσαν ἐπιλαβέσθαι τοῦ ῥήματος ἐναντίον τοῦ λαοῦ, καὶ θαυμάσαντες ἐπὶ τῇ ἀποκρίσει αὐτοῦ, ἐσίγησαν.
42020027 Προσελθόντες δέ τινες τῶν Σαδδουκαίων, οἱ λέγοντες ἀνάστασιν μὴ εἶναι, ἐπηρώτησαν αὐτὸν
42020028 λέγοντες, “Διδάσκαλε, Μωϋσῆς ἔγραψεν ἡμῖν, ‘Ἐάν τινος ἀδελφὸς ἀποθάνῃ ἔχων γυναῖκα, καὶ οὗτος ἄτεκνος ἵνα λάβῃ ὁ ἀδελφὸς αὐτοῦ τὴν γυναῖκα, καὶ ἐξαναστήσῃ σπέρμα τῷ ἀδελφῷ αὐτοῦ.’
42020029 Ἑπτὰ οὖν ἀδελφοὶ ἦσαν· καὶ ὁ πρῶτος, λαβὼν γυναῖκα, ἀπέθανεν ἄτεκνος·
42020030 καὶ ὁ δεύτερος,
42020031 καὶ ὁ τρίτος ἔλαβεν αὐτήν, ὡσαύτως δὲ καὶ οἱ ἑπτὰ, οὐ κατέλιπον τέκνα, καὶ ἀπέθανον.
42020032 Ὕστερον καὶ ἡ γυνὴ ἀπέθανεν.
42020033 Ἐν τῇ οὖν ἀναστάσει, τίνος αὐτῶν γίνεται γυνή; Οἱ γὰρ ἑπτὰ ἔσχον αὐτὴν γυναῖκα.”
42020034 Καὶ εἶπεν αὐτοῖς ὁ ˚Ἰησοῦς, “Οἱ υἱοὶ τοῦ αἰῶνος τούτου γαμοῦσιν καὶ γαμίσκονται,
42020035 οἱ δὲ καταξιωθέντες τοῦ αἰῶνος ἐκείνου, τυχεῖν καὶ τῆς ἀναστάσεως τῆς ἐκ νεκρῶν, οὔτε γαμοῦσιν οὔτε γαμίζονται·
42020036 οὐδὲ γὰρ ἀποθανεῖν ἔτι δύνανται, ἰσάγγελοι γάρ εἰσιν, καὶ υἱοί εἰσιν ˚Θεοῦ, τῆς ἀναστάσεως υἱοὶ ὄντες.
42020037 Ὅτι δὲ ἐγείρονται οἱ νεκροὶ, καὶ Μωϋσῆς ἐμήνυσεν ἐπὶ τῆς βάτου, ὡς λέγει ˚Κύριον, ‘τὸν ˚Θεὸν Ἀβραὰμ, καὶ ˚Θεὸν Ἰσαὰκ, καὶ ˚Θεὸν Ἰακώβ’.
42020038 ˚Θεὸς δὲ οὐκ ἔστιν νεκρῶν, ἀλλὰ ζώντων, πάντες γὰρ αὐτῷ ζῶσιν.”
42020039 Ἀποκριθέντες δέ τινες τῶν γραμματέων εἶπαν, “Διδάσκαλε, καλῶς εἶπας.”
42020040 Οὐκέτι γὰρ ἐτόλμων ἐπερωτᾶν αὐτὸν οὐδέν.
42020041 Εἶπεν δὲ πρὸς αὐτούς, “Πῶς λέγουσιν τὸν ˚Χριστὸν εἶναι Δαυὶδ Υἱόν;
42020042 Αὐτὸς γὰρ Δαυὶδ λέγει ἐν βίβλῳ Ψαλμῶν, ‘Εἶπεν ὁ ˚Κύριος τῷ ˚Κυρίῳ μου, “Κάθου ἐκ δεξιῶν μου,
42020043 ἕως ἂν θῶ τοὺς ἐχθρούς σου ὑποπόδιον τῶν ποδῶν σου.”’
42020044 Δαυὶδ οὖν, αὐτὸν ‘˚Κύριον’, καλεῖ, καὶ πῶς αὐτοῦ υἱός ἐστιν;”
42020045 Ἀκούοντος δὲ παντὸς τοῦ λαοῦ, εἶπεν τοῖς μαθηταῖς αὐτοῦ,
42020046 “Προσέχετε ἀπὸ τῶν γραμματέων τῶν θελόντων περιπατεῖν ἐν στολαῖς, καὶ φιλούντων ἀσπασμοὺς ἐν ταῖς ἀγοραῖς, καὶ πρωτοκαθεδρίας ἐν ταῖς συναγωγαῖς, καὶ πρωτοκλισίας ἐν τοῖς δείπνοις,
42020047 οἳ κατεσθίουσιν τὰς οἰκίας τῶν χηρῶν, καὶ προφάσει μακρὰ προσεύχονται. Οὗτοι λήμψονται περισσότερον κρίμα.”
42021001 Ἀναβλέψας δὲ, εἶδεν τοὺς βάλλοντας εἰς τὸ γαζοφυλάκιον τὰ δῶρα αὐτῶν πλουσίους.
42021002 Εἶδεν δέ τινα χήραν πενιχρὰν, βάλλουσαν ἐκεῖ λεπτὰ δύο.
42021003 Καὶ εἶπεν, “Ἀληθῶς λέγω ὑμῖν ὅτι ἡ χήρα αὕτη ἡ πτωχὴ, πλεῖον πάντων ἔβαλεν·
42021004 πάντες γὰρ οὗτοι ἐκ τοῦ περισσεύοντος αὐτοῖς ἔβαλον εἰς τὰ δῶρα, αὕτη δὲ ἐκ τοῦ ὑστερήματος αὐτῆς, πάντα τὸν βίον ὃν εἶχεν ἔβαλεν.”
42021005 Καί τινων λεγόντων περὶ τοῦ ἱεροῦ ὅτι λίθοις καλοῖς καὶ ἀναθήμασιν κεκόσμηται, εἶπεν,
42021006 “Ταῦτα ἃ θεωρεῖτε, ἐλεύσονται ἡμέραι ἐν αἷς οὐκ ἀφεθήσεται λίθος ἐπὶ λίθῳ, ὧδε, ὃς οὐ καταλυθήσεται.”
42021007 Ἐπηρώτησαν δὲ αὐτὸν λέγοντες, “Διδάσκαλε, πότε οὖν ταῦτα ἔσται, καὶ τί τὸ σημεῖον ὅταν μέλλῃ ταῦτα γίνεσθαι;”
42021008 Ὁ δὲ εἶπεν, “Βλέπετε, μὴ πλανηθῆτε· πολλοὶ γὰρ ἐλεύσονται ἐπὶ τῷ ὀνόματί μου λέγοντες, ‘Ἐγώ εἰμι’, καί, ‘Ὁ καιρὸς ἤγγικεν.’ Μὴ πορευθῆτε ὀπίσω αὐτῶν.
42021009 Ὅταν δὲ ἀκούσητε πολέμους καὶ ἀκαταστασίας, μὴ πτοηθῆτε· δεῖ γὰρ ταῦτα γενέσθαι πρῶτον, ἀλλʼ οὐκ εὐθέως τὸ τέλος.”
42021010 Τότε ἔλεγεν αὐτοῖς, “Ἐγερθήσεται ἔθνος ἐπʼ ἔθνος, καὶ βασιλεία ἐπὶ βασιλείαν,
42021011 σεισμοί τε μεγάλοι, καὶ κατὰ τόπους, λιμοὶ καὶ λοιμοὶ ἔσονται, φόβητρά τε καὶ σημεῖα ἀπʼ οὐρανοῦ μεγάλα ἔσται.
42021012 Πρὸ δὲ τούτων πάντων, ἐπιβαλοῦσιν ἐφʼ ὑμᾶς τὰς χεῖρας αὐτῶν, καὶ διώξουσιν, παραδιδόντες εἰς τὰς συναγωγὰς καὶ φυλακάς, ἀπαγομένους ἐπὶ βασιλεῖς καὶ ἡγεμόνας, ἕνεκεν τοῦ ὀνόματός μου.
42021013 Ἀποβήσεται ὑμῖν εἰς μαρτύριον.
42021014 Θέτε οὖν ἐν ταῖς καρδίαις ὑμῶν μὴ προμελετᾶν ἀπολογηθῆναι,
42021015 ἐγὼ γὰρ δώσω ὑμῖν στόμα καὶ σοφίαν, ᾗ οὐ δυνήσονται ἀντιστῆναι ἢ ἀντειπεῖν πάντες οἱ ἀντικείμενοι ὑμῖν.
42021016 Παραδοθήσεσθε δὲ καὶ ὑπὸ γονέων, καὶ ἀδελφῶν, καὶ συγγενῶν, καὶ φίλων, καὶ θανατώσουσιν ἐξ ὑμῶν.
42021017 Καὶ ἔσεσθε μισούμενοι ὑπὸ πάντων διὰ τὸ ὄνομά μου.
42021018 Καὶ θρὶξ ἐκ τῆς κεφαλῆς ὑμῶν, οὐ μὴ ἀπόληται.
42021019 Ἐν τῇ ὑπομονῇ ὑμῶν, κτήσασθε τὰς ψυχὰς ὑμῶν.
42021020 Ὅταν δὲ ἴδητε κυκλουμένην ὑπὸ στρατοπέδων Ἰερουσαλήμ, τότε γνῶτε ὅτι ἤγγικεν ἡ ἐρήμωσις αὐτῆς.
42021021 Τότε οἱ ἐν τῇ Ἰουδαίᾳ, φευγέτωσαν εἰς τὰ ὄρη, καὶ οἱ ἐν μέσῳ αὐτῆς, ἐκχωρείτωσαν, καὶ οἱ ἐν ταῖς χώραις, μὴ εἰσερχέσθωσαν εἰς αὐτήν.
42021022 Ὅτι ἡμέραι ἐκδικήσεως αὗταί εἰσιν τοῦ πλησθῆναι πάντα τὰ γεγραμμένα.
42021023 Οὐαὶ δὲ ταῖς ἐν γαστρὶ ἐχούσαις καὶ ταῖς θηλαζούσαις ἐν ἐκείναις ταῖς ἡμέραις· ἔσται γὰρ ἀνάγκη μεγάλη ἐπὶ τῆς γῆς, καὶ ὀργὴ τῷ λαῷ τούτῳ.
42021024 Καὶ πεσοῦνται στόματι μαχαίρης, καὶ αἰχμαλωτισθήσονται εἰς τὰ ἔθνη· πάντα· καὶ Ἰερουσαλὴμ ἔσται πατουμένη ὑπὸ ἐθνῶν, ἄχρι οὗ πληρωθῶσιν καιροὶ ἐθνῶν.
42021025 Καὶ ἔσονται σημεῖα ἐν ἡλίῳ, καὶ σελήνῃ, καὶ ἄστροις, καὶ ἐπὶ τῆς γῆς συνοχὴ ἐθνῶν, ἐν ἀπορίᾳ ἤχους θαλάσσης καὶ σάλου,
42021026 ἀποψυχόντων ἀνθρώπων ἀπὸ φόβου καὶ προσδοκίας τῶν ἐπερχομένων τῇ οἰκουμένῃ, αἱ γὰρ δυνάμεις τῶν οὐρανῶν σαλευθήσονται.
42021027 Καὶ τότε ὄψονται ‘τὸν Υἱὸν τοῦ Ἀνθρώπου, ἐρχόμενον ἐν νεφέλῃ’ μετὰ δυνάμεως καὶ δόξης πολλῆς.
42021028 Ἀρχομένων δὲ τούτων γίνεσθαι, ἀνακύψατε καὶ ἐπάρατε τὰς κεφαλὰς ὑμῶν, διότι ἐγγίζει ἡ ἀπολύτρωσις ὑμῶν.”
42021029 Καὶ εἶπεν παραβολὴν αὐτοῖς: “Ἴδετε τὴν συκῆν καὶ πάντα τὰ δένδρα·
42021030 ὅταν προβάλωσιν ἤδη, βλέποντες ἀφʼ ἑαυτῶν, γινώσκετε ὅτι ἤδη ἐγγὺς τὸ θέρος ἐστίν.
42021031 Οὕτως καὶ ὑμεῖς, ὅταν ἴδητε ταῦτα γινόμενα, γινώσκετε ὅτι ἐγγύς ἐστιν ἡ Βασιλεία τοῦ ˚Θεοῦ.
42021032 Ἀμὴν, λέγω ὑμῖν ὅτι οὐ μὴ παρέλθῃ ἡ γενεὰ αὕτη, ἕως ἂν πάντα γένηται.
42021033 Ὁ οὐρανὸς καὶ ἡ γῆ παρελεύσονται, οἱ δὲ λόγοι μου οὐ μὴ παρελεύσονται.
42021034 Προσέχετε δὲ ἑαυτοῖς, μήποτε βαρηθῶσιν αἱ καρδίαι ὑμῶν ἐν κραιπάλῃ, καὶ μέθῃ, καὶ μερίμναις βιωτικαῖς, καὶ ἐπιστῇ ἐφʼ ὑμᾶς αἰφνίδιος ἡ ἡμέρα ἐκείνη
42021035 ὡς παγίς· ἐπεισελεύσεται γὰρ ἐπὶ πάντας τοὺς καθημένους ἐπὶ πρόσωπον πάσης τῆς γῆς.
42021036 Ἀγρυπνεῖτε δὲ ἐν παντὶ καιρῷ, δεόμενοι ἵνα κατισχύσητε ἐκφυγεῖν ταῦτα πάντα τὰ μέλλοντα γίνεσθαι, καὶ σταθῆναι ἔμπροσθεν τοῦ Υἱοῦ τοῦ Ἀνθρώπου.”
42021037 Ἦν δὲ τὰς ἡμέρας ἐν τῷ ἱερῷ διδάσκων, τὰς δὲ νύκτας ἐξερχόμενος, ηὐλίζετο εἰς τὸ ὄρος τὸ καλούμενον Ἐλαιῶν.
42021038 Καὶ πᾶς ὁ λαὸς ὤρθριζεν πρὸς αὐτὸν ἐν τῷ ἱερῷ ἀκούειν αὐτοῦ.
42022001 Ἤγγιζεν δὲ ἡ ἑορτὴ τῶν Ἀζύμων, ἡ λεγομένη Πάσχα.
42022002 Καὶ ἐζήτουν οἱ ἀρχιερεῖς καὶ οἱ γραμματεῖς, τὸ πῶς ἀνέλωσιν αὐτόν, ἐφοβοῦντο γὰρ τὸν λαόν.
42022003 Εἰσῆλθεν δὲ Σατανᾶς εἰς Ἰούδαν, τὸν καλούμενον Ἰσκαριώτην, ὄντα ἐκ τοῦ ἀριθμοῦ τῶν δώδεκα.
42022004 Καὶ ἀπελθὼν, συνελάλησεν τοῖς ἀρχιερεῦσιν καὶ στρατηγοῖς, τὸ πῶς αὐτοῖς παραδῷ αὐτόν.
42022005 Καὶ ἐχάρησαν καὶ συνέθεντο αὐτῷ ἀργύριον δοῦναι.
42022006 Καὶ ἐξωμολόγησεν, καὶ ἐζήτει εὐκαιρίαν τοῦ παραδοῦναι αὐτὸν ἄτερ ὄχλου αὐτοῖς.
42022007 Ἦλθεν δὲ ἡ ἡμέρα τῶν Ἀζύμων, ᾗ ἔδει θύεσθαι τὸ Πάσχα.
42022008 Καὶ ἀπέστειλεν Πέτρον καὶ Ἰωάννην εἰπών, “Πορευθέντες, ἑτοιμάσατε ἡμῖν τὸ Πάσχα, ἵνα φάγωμεν.”
42022009 Οἱ δὲ εἶπαν αὐτῷ, “Ποῦ θέλεις ἑτοιμάσωμεν;”
42022010 Ὁ δὲ εἶπεν αὐτοῖς, “Ἰδοὺ, εἰσελθόντων ὑμῶν εἰς τὴν πόλιν, συναντήσει ὑμῖν ἄνθρωπος, κεράμιον ὕδατος βαστάζων· ἀκολουθήσατε αὐτῷ εἰς τὴν οἰκίαν εἰς ἣν εἰσπορεύεται.
42022011 Καὶ ἐρεῖτε τῷ οἰκοδεσπότῃ τῆς οἰκίας, ‘Λέγει σοι ὁ διδάσκαλος, “Ποῦ ἐστιν τὸ κατάλυμα, ὅπου τὸ Πάσχα μετὰ τῶν μαθητῶν μου φάγω;”’
42022012 Κἀκεῖνος ὑμῖν δείξει ἀνάγαιον μέγα, ἐστρωμένον· ἐκεῖ ἑτοιμάσατε.”
42022013 Ἀπελθόντες δὲ, εὗρον καθὼς εἰρήκει αὐτοῖς, καὶ ἡτοίμασαν τὸ Πάσχα.
42022014 Καὶ ὅτε ἐγένετο ἡ ὥρα, ἀνέπεσεν καὶ οἱ ἀπόστολοι σὺν αὐτῷ.
42022015 Καὶ εἶπεν πρὸς αὐτούς, “Ἐπιθυμίᾳ ἐπεθύμησα τοῦτο τὸ Πάσχα φαγεῖν μεθʼ ὑμῶν, πρὸ τοῦ με παθεῖν.
42022016 Λέγω γὰρ ὑμῖν ὅτι οὐ μὴ φάγω αὐτὸ, ἕως ὅτου πληρωθῇ ἐν τῇ Βασιλείᾳ τοῦ ˚Θεοῦ.”
42022017 Καὶ δεξάμενος ποτήριον, εὐχαριστήσας εἶπεν, “Λάβετε τοῦτο, καὶ διαμερίσατε εἰς ἑαυτούς.
42022018 Λέγω γὰρ ὑμῖν, οὐ μὴ πίω ἀπὸ τοῦ νῦν ἀπὸ τοῦ γενήματος τῆς ἀμπέλου, ἕως οὗ ἡ Βασιλεία τοῦ ˚Θεοῦ ἔλθῃ.”
42022019 Καὶ λαβὼν ἄρτον, εὐχαριστήσας, ἔκλασεν καὶ ἔδωκεν αὐτοῖς λέγων, “Τοῦτό ἐστιν τὸ σῶμά μου, τὸ ὑπὲρ ὑμῶν διδόμενον· τοῦτο ποιεῖτε εἰς τὴν ἐμὴν ἀνάμνησιν.”
42022020 Καὶ τὸ ποτήριον ὡσαύτως μετὰ τὸ δειπνῆσαι λέγων, “Τοῦτο τὸ ποτήριον ἡ καινὴ διαθήκη ἐν τῷ αἵματί μου, τὸ ὑπὲρ ὑμῶν ἐκχυννόμενον.
42022021 Πλὴν ἰδοὺ, ἡ χεὶρ τοῦ παραδιδόντος με μετʼ ἐμοῦ ἐπὶ τῆς τραπέζης.
42022022 Ὅτι ὁ Υἱὸς μὲν τοῦ Ἀνθρώπου κατὰ τὸ ὡρισμένον πορεύεται, πλὴν οὐαὶ τῷ ἀνθρώπῳ ἐκείνῳ διʼ οὗ παραδίδοται.”
42022023 Καὶ αὐτοὶ ἤρξαντο συζητεῖν πρὸς ἑαυτοὺς, τὸ τίς ἄρα εἴη ἐξ αὐτῶν, ὁ τοῦτο μέλλων πράσσειν.
42022024 Ἐγένετο δὲ καὶ φιλονεικία ἐν αὐτοῖς, τὸ τίς αὐτῶν δοκεῖ εἶναι μείζων.
42022025 Ὁ δὲ εἶπεν αὐτοῖς, “Οἱ βασιλεῖς τῶν ἐθνῶν κυριεύουσιν αὐτῶν, καὶ οἱ ἐξουσιάζοντες αὐτῶν, ‘εὐεργέται’, καλοῦνται.
42022026 Ὑμεῖς δὲ οὐχ οὕτως, ἀλλʼ ὁ μείζων ἐν ὑμῖν, γινέσθω ὡς ὁ νεώτερος, καὶ ὁ ἡγούμενος, ὡς ὁ διακονῶν.
42022027 Τίς γὰρ μείζων, ὁ ἀνακείμενος ἢ ὁ διακονῶν; Οὐχὶ ὁ ἀνακείμενος; Ἐγὼ δὲ ἐν μέσῳ ὑμῶν εἰμι ὡς ὁ διακονῶν.
42022028 Ὑμεῖς δέ ἐστε οἱ διαμεμενηκότες μετʼ ἐμοῦ, ἐν τοῖς πειρασμοῖς μου.
42022029 Κἀγὼ διατίθεμαι ὑμῖν καθὼς διέθετό μοι ὁ Πατήρ μου βασιλείαν,
42022030 ἵνα ἔσθητε καὶ πίνητε ἐπὶ τῆς τραπέζης μου ἐν τῇ βασιλείᾳ μου, καὶ καθήσεσθε ἐπὶ θρόνων, τὰς δώδεκα φυλὰς κρίνοντες τοῦ Ἰσραήλ.
42022031 Σίμων, Σίμων, ἰδοὺ, ὁ Σατανᾶς ἐξῃτήσατο ὑμᾶς τοῦ σινιάσαι ὡς τὸν σῖτον·
42022032 ἐγὼ δὲ ἐδεήθην περὶ σοῦ ἵνα μὴ ἐκλίπῃ ἡ πίστις σου· καὶ σύ ποτε ἐπιστρέψας, στήρισον τοὺς ἀδελφούς σου.”
42022033 Ὁ δὲ εἶπεν αὐτῷ, “˚Κύριε, μετὰ σοῦ ἕτοιμός εἰμι καὶ εἰς φυλακὴν καὶ εἰς θάνατον πορεύεσθαι.”
42022034 Ὁ δὲ εἶπεν, “Λέγω σοι, Πέτρε, οὐ φωνήσει σήμερον ἀλέκτωρ, ἕως τρίς με ἀπαρνήσῃ εἰδέναι.”
42022035 Καὶ εἶπεν αὐτοῖς, “Ὅτε ἀπέστειλα ὑμᾶς ἄτερ βαλλαντίου, καὶ πήρας, καὶ ὑποδημάτων, μή τινος ὑστερήσατε;” Οἱ δὲ εἶπαν, “Οὐθενός.”
42022036 Εἶπεν δὲ αὐτοῖς, “Ἀλλὰ νῦν ὁ ἔχων βαλλάντιον ἀράτω, ὁμοίως καὶ πήραν, καὶ ὁ μὴ ἔχων, πωλησάτω τὸ ἱμάτιον αὐτοῦ, καὶ ἀγορασάτω μάχαιραν.
42022037 Λέγω γὰρ ὑμῖν ὅτι τοῦτο τὸ γεγραμμένον, δεῖ τελεσθῆναι ἐν ἐμοί, τό ‘Καὶ μετὰ ἀνόμων ἐλογίσθη’· καὶ γὰρ τὸ περὶ ἐμοῦ τέλος ἔχει.”
42022038 Οἱ δὲ εἶπαν, “˚Κύριε, ἰδοὺ, μάχαιραι ὧδε δύο.” Ὁ δὲ εἶπεν αὐτοῖς, “Ἱκανόν ἐστιν.”
42022039 Καὶ ἐξελθὼν, ἐπορεύθη κατὰ τὸ ἔθος εἰς τὸ Ὄρος τῶν Ἐλαιῶν, ἠκολούθησαν δὲ αὐτῷ καὶ οἱ μαθηταί.
42022040 Γενόμενος δὲ ἐπὶ τοῦ τόπου, εἶπεν αὐτοῖς, “Προσεύχεσθε μὴ εἰσελθεῖν εἰς πειρασμόν.”
42022041 Καὶ αὐτὸς ἀπεσπάσθη ἀπʼ αὐτῶν ὡσεὶ λίθου βολήν, καὶ θεὶς τὰ γόνατα, προσηύχετο
42022042 λέγων, “Πάτερ, εἰ βούλει, παρένεγκε τοῦτο τὸ ποτήριον ἀπʼ ἐμοῦ· πλὴν μὴ τὸ θέλημά μου, ἀλλὰ τὸ σὸν γινέσθω.”
42022045 Καὶ ἀναστὰς ἀπὸ τῆς προσευχῆς, ἐλθὼν πρὸς τοὺς μαθητὰς, εὗρεν κοιμωμένους αὐτοὺς ἀπὸ τῆς λύπης,
42022046 καὶ εἶπεν αὐτοῖς, “Τί καθεύδετε; Ἀναστάντες προσεύχεσθε ἵνα μὴ εἰσέλθητε εἰς πειρασμόν.”
42022047 Ἔτι αὐτοῦ λαλοῦντος, ἰδοὺ, ὄχλος καὶ ὁ λεγόμενος Ἰούδας, εἷς τῶν δώδεκα, προήρχετο αὐτούς, καὶ ἤγγισεν τῷ ˚Ἰησοῦ φιλῆσαι αὐτόν.
42022048 ˚Ἰησοῦς δὲ εἶπεν αὐτῷ, “Ἰούδα, φιλήματι τὸν Υἱὸν τοῦ Ἀνθρώπου παραδίδως;”
42022049 Ἰδόντες δὲ οἱ περὶ αὐτὸν τὸ ἐσόμενον, εἶπαν, “˚Κύριε, εἰ πατάξομεν ἐν μαχαίρῃ;”
42022050 Καὶ ἐπάταξεν εἷς τις ἐξ αὐτῶν τὸν δοῦλον τοῦ ἀρχιερέως, καὶ ἀφεῖλεν τὸ οὖς αὐτοῦ τὸ δεξιόν.
42022051 Ἀποκριθεὶς δὲ ὁ ˚Ἰησοῦς εἶπεν, “Ἐᾶτε ἕως τούτου!” Καὶ ἁψάμενος τοῦ ὠτίου, ἰάσατο αὐτόν.
42022052 Εἶπεν δὲ ˚Ἰησοῦς πρὸς τοὺς παραγενομένους ἐπʼ αὐτὸν, ἀρχιερεῖς, καὶ στρατηγοὺς τοῦ ἱεροῦ, καὶ πρεσβυτέρους, “Ὡς ἐπὶ λῃστὴν ἐξήλθατε μετὰ μαχαιρῶν καὶ ξύλων;
42022053 Καθʼ ἡμέραν ὄντος μου μεθʼ ὑμῶν ἐν τῷ ἱερῷ, οὐκ ἐξετείνατε τὰς χεῖρας ἐπʼ ἐμέ. Ἀλλʼ αὕτη ἐστὶν ὑμῶν ἡ ὥρα, καὶ ἡ ἐξουσία τοῦ σκότους.”
42022054 Συλλαβόντες δὲ αὐτὸν, ἤγαγον καὶ εἰσήγαγον εἰς τὴν οἰκίαν τοῦ ἀρχιερέως. Ὁ δὲ Πέτρος ἠκολούθει μακρόθεν.
42022055 Περιαψάντων δὲ πῦρ ἐν μέσῳ τῆς αὐλῆς, καὶ συγκαθισάντων, ἐκάθητο ὁ Πέτρος μέσος αὐτῶν.
42022056 Ἰδοῦσα δὲ αὐτὸν, παιδίσκη τις καθήμενον πρὸς τὸ φῶς, καὶ ἀτενίσασα αὐτῷ εἶπεν, “Καὶ οὗτος σὺν αὐτῷ ἦν.”
42022057 Ὁ δὲ ἠρνήσατο λέγων, “Οὐκ οἶδα αὐτόν, γύναι.”
42022058 Καὶ μετὰ βραχὺ, ἕτερος ἰδὼν αὐτὸν ἔφη, “Καὶ σὺ ἐξ αὐτῶν εἶ.” Ὁ δὲ Πέτρος ἔφη, “Ἄνθρωπε, οὐκ εἰμί.”
42022059 Καὶ διαστάσης ὡσεὶ ὥρας μιᾶς, ἄλλος τις διϊσχυρίζετο λέγων, “Ἐπʼ ἀληθείας καὶ οὗτος μετʼ αὐτοῦ ἦν, καὶ γὰρ Γαλιλαῖός ἐστιν.”
42022060 Εἶπεν δὲ ὁ Πέτρος, “Ἄνθρωπε, οὐκ οἶδα ὃ λέγεις.” Καὶ παραχρῆμα ἔτι λαλοῦντος αὐτοῦ, ἐφώνησεν ἀλέκτωρ.
42022061 Καὶ στραφεὶς, ὁ ˚Κύριος ἐνέβλεψεν τῷ Πέτρῳ, καὶ ὑπεμνήσθη ὁ Πέτρος τοῦ ῥήματος τοῦ ˚Κυρίου, ὡς εἶπεν αὐτῷ, ὅτι “Πρὶν ἀλέκτορα φωνῆσαι σήμερον, ἀπαρνήσῃ με τρίς.”
42022062 Καὶ ἐξελθὼν ἔξω, ἔκλαυσεν πικρῶς.
42022063 Καὶ οἱ ἄνδρες οἱ συνέχοντες αὐτὸν, ἐνέπαιζον αὐτῷ δέροντες.
42022064 Καὶ περικαλύψαντες αὐτὸν, ἐπηρώτων λέγοντες, “Προφήτευσον, τίς ἐστιν ὁ παίσας σε;”
42022065 Καὶ ἕτερα πολλὰ βλασφημοῦντες, ἔλεγον εἰς αὐτόν.
42022066 Καὶ ὡς ἐγένετο ἡμέρα, συνήχθη τὸ πρεσβυτέριον τοῦ λαοῦ, ἀρχιερεῖς τε καὶ γραμματεῖς, καὶ ἀπήγαγον αὐτὸν εἰς τὸ Συνέδριον αὐτῶν
42022067 λέγοντες, “Εἰ σὺ εἶ ὁ ˚Χριστός, εἰπὸν ἡμῖν.” Εἶπεν δὲ αὐτοῖς, “Ἐὰν ὑμῖν εἴπω, οὐ μὴ πιστεύσητε·
42022068 ἐὰν δὲ ἐρωτήσω, οὐ μὴ ἀποκριθῆτε.
42022069 Ἀπὸ τοῦ νῦν δὲ ἔσται ὁ Υἱὸς τοῦ Ἀνθρώπου ‘καθήμενος ἐκ δεξιῶν’ τῆς δυνάμεως τοῦ ˚Θεοῦ.”
42022070 Εἶπαν δὲ πάντες, “Σὺ οὖν εἶ ὁ Υἱὸς τοῦ ˚Θεοῦ;” Ὁ δὲ πρὸς αὐτοὺς ἔφη, “Ὑμεῖς λέγετε ὅτι ἐγώ εἰμι.”
42022071 Οἱ δὲ εἶπαν, “Τί ἔτι ἔχομεν μαρτυρίας χρείαν; Αὐτοὶ γὰρ ἠκούσαμεν ἀπὸ τοῦ στόματος αὐτοῦ.”
42023001 Καὶ ἀναστὰν, ἅπαν τὸ πλῆθος αὐτῶν ἤγαγον αὐτὸν ἐπὶ τὸν Πιλᾶτον.
42023002 Ἤρξαντο δὲ κατηγορεῖν αὐτοῦ λέγοντες, “Τοῦτον εὕρομεν διαστρέφοντα τὸ ἔθνος ἡμῶν, καὶ κωλύοντα φόρους Καίσαρι διδόναι, καὶ λέγοντα ἑαυτὸν ˚Χριστὸν, βασιλέα εἶναι.”
42023003 Ὁ δὲ Πιλᾶτος ἠρώτησεν αὐτὸν λέγων, “Σὺ εἶ ὁ βασιλεὺς τῶν Ἰουδαίων;” Ὁ δὲ ἀποκριθεὶς αὐτῷ ἔφη, “Σὺ λέγεις.”
42023004 Ὁ δὲ Πιλᾶτος εἶπεν πρὸς τοὺς ἀρχιερεῖς καὶ τοὺς ὄχλους, “Οὐδὲν εὑρίσκω αἴτιον ἐν τῷ ἀνθρώπῳ τούτῳ.”
42023005 Οἱ δὲ ἐπίσχυον λέγοντες, ὅτι “Ἀνασείει τὸν λαὸν, διδάσκων καθʼ ὅλης τῆς Ἰουδαίας, ἀρξάμενος ἀπὸ τῆς Γαλιλαίας ἕως ὧδε.”
42023006 Πιλᾶτος δὲ ἀκούσας, ἐπηρώτησεν εἰ ὁ ἄνθρωπος Γαλιλαῖός ἐστιν.
42023007 Καὶ ἐπιγνοὺς ὅτι ἐκ τῆς ἐξουσίας Ἡρῴδου ἐστὶν, ἀνέπεμψεν αὐτὸν πρὸς τὸν Ἡρῴδην, ὄντα καὶ αὐτὸν ἐν Ἱεροσολύμοις ἐν ταύταις ταῖς ἡμέραις.
42023008 Ὁ δὲ Ἡρῴδης ἰδὼν τὸν ˚Ἰησοῦν, ἐχάρη λίαν, ἦν γὰρ ἐξ ἱκανῶν χρόνων θέλων ἰδεῖν αὐτὸν, διὰ τὸ ἀκούειν περὶ αὐτοῦ, καὶ ἤλπιζέν τι σημεῖον ἰδεῖν ὑπʼ αὐτοῦ γινόμενον.
42023009 Ἐπηρώτα δὲ αὐτὸν ἐν λόγοις ἱκανοῖς, αὐτὸς δὲ οὐδὲν ἀπεκρίνατο αὐτῷ.
42023010 Εἱστήκεισαν δὲ οἱ ἀρχιερεῖς καὶ οἱ γραμματεῖς εὐτόνως κατηγοροῦντες αὐτοῦ.
42023011 Ἐξουθενήσας δὲ αὐτὸν καὶ ὁ Ἡρῴδης σὺν τοῖς στρατεύμασιν αὐτοῦ, καὶ ἐμπαίξας περιβαλὼν ἐσθῆτα λαμπρὰν, ἀνέπεμψεν αὐτὸν τῷ Πιλάτῳ.
42023012 Ἐγένοντο δὲ φίλοι ὅ τε Ἡρῴδης καὶ ὁ Πιλᾶτος ἐν αὐτῇ τῇ ἡμέρᾳ μετʼ ἀλλήλων· προϋπῆρχον γὰρ ἐν ἔχθρᾳ ὄντες πρὸς αὑτούς.
42023013 Πιλᾶτος δὲ συγκαλεσάμενος τοὺς ἀρχιερεῖς, καὶ τοὺς ἄρχοντας, καὶ τὸν λαὸν,
42023014 εἶπεν πρὸς αὐτούς, “Προσηνέγκατέ μοι τὸν ἄνθρωπον τοῦτον, ὡς ἀποστρέφοντα τὸν λαόν, καὶ ἰδοὺ, ἐγὼ ἐνώπιον ὑμῶν ἀνακρίνας, οὐθὲν εὗρον ἐν τῷ ἀνθρώπῳ τούτῳ αἴτιον ὧν κατηγορεῖτε κατʼ αὐτοῦ.
42023015 Ἀλλʼ οὐδὲ Ἡρῴδης, ἀνέπεμψεν γὰρ αὐτὸν πρὸς ἡμᾶς. Καὶ ἰδοὺ, οὐδὲν ἄξιον θανάτου ἐστὶν πεπραγμένον αὐτῷ.
42023016 Παιδεύσας οὖν αὐτὸν, ἀπολύσω.”
42023018 Ἀνέκραγον δὲ παμπληθεὶ λέγοντες, “Αἶρε τοῦτον, ἀπόλυσον δὲ ἡμῖν τὸν Βαραββᾶν!”
42023019 Ὅστις ἦν, διὰ στάσιν τινὰ γενομένην ἐν τῇ πόλει καὶ φόνον, βληθεὶς ἐν τῇ φυλακῇ.
42023020 Πάλιν δὲ ὁ Πιλᾶτος προσεφώνησεν αὐτοῖς, θέλων ἀπολῦσαι τὸν ˚Ἰησοῦν.
42023021 Οἱ δὲ ἐπεφώνουν λέγοντες, “Σταύρου, σταύρου αὐτόν.”
42023022 Ὁ δὲ τρίτον εἶπεν πρὸς αὐτούς, “Τί γὰρ κακὸν ἐποίησεν οὗτος; Οὐδὲν αἴτιον θανάτου εὗρον ἐν αὐτῷ. Παιδεύσας οὖν αὐτὸν, ἀπολύσω.”
42023023 Οἱ δὲ ἐπέκειντο φωναῖς μεγάλαις, αἰτούμενοι αὐτὸν σταυρωθῆναι, καὶ κατίσχυον αἱ φωναὶ αὐτῶν.
42023024 Καὶ Πιλᾶτος ἐπέκρινεν γενέσθαι τὸ αἴτημα αὐτῶν.
42023025 Ἀπέλυσεν δὲ τὸν διὰ στάσιν καὶ φόνον βεβλημένον εἰς τὴν φυλακὴν, ὃν ᾐτοῦντο, τὸν δὲ ˚Ἰησοῦν παρέδωκεν τῷ θελήματι αὐτῶν.
42023026 Καὶ ὡς ἀπήγαγον αὐτόν, ἐπιλαβόμενοι Σίμωνά, τινα Κυρηναῖον ἐρχόμενον ἀπʼ ἀγροῦ, ἐπέθηκαν αὐτῷ τὸν σταυρὸν, φέρειν ὄπισθεν τοῦ ˚Ἰησοῦ.
42023027 Ἠκολούθει δὲ αὐτῷ πολὺ πλῆθος τοῦ λαοῦ, καὶ γυναικῶν αἳ ἐκόπτοντο καὶ ἐθρήνουν αὐτόν.
42023028 Στραφεὶς δὲ πρὸς αὐτὰς, ˚Ἰησοῦς εἶπεν, “Θυγατέρες Ἰερουσαλήμ, μὴ κλαίετε ἐπʼ ἐμέ, πλὴν ἐφʼ ἑαυτὰς κλαίετε, καὶ ἐπὶ τὰ τέκνα ὑμῶν.
42023029 Ὅτι ἰδοὺ, ἔρχονται ἡμέραι ἐν αἷς ἐροῦσιν, ‘Μακάριαι αἱ στεῖραι, καὶ κοιλίαι αἳ οὐκ ἐγέννησαν, καὶ μαστοὶ οἳ οὐκ ἔθρεψαν.’
42023030 Τότε ‘ἄρξονται λέγειν τοῖς ὄρεσιν, “Πέσετε ἐφʼ ἡμᾶς”, καὶ τοῖς βουνοῖς, “Καλύψατε ἡμᾶς”.’
42023031 Ὅτι εἰ ἐν τῷ ὑγρῷ ξύλῳ, ταῦτα ποιοῦσιν, ἐν τῷ ξηρῷ, τί γένηται;”
42023032 Ἤγοντο δὲ καὶ ἕτεροι κακοῦργοι δύο σὺν αὐτῷ ἀναιρεθῆναι.
42023033 Καὶ ὅτε ἦλθον ἐπὶ τὸν τόπον τὸν καλούμενον Κρανίον, ἐκεῖ ἐσταύρωσαν αὐτὸν καὶ τοὺς κακούργους, ὃν μὲν ἐκ δεξιῶν, ὃν δὲ ἐξ ἀριστερῶν.
42023034 Διαμεριζόμενοι δὲ τὰ ἱμάτια αὐτοῦ, ἔβαλον κλῆρον.
42023035 Καὶ εἱστήκει ὁ λαὸς θεωρῶν. ἐξεμυκτήριζον δὲ καὶ οἱ ἄρχοντες λέγοντες, “Ἄλλους ἔσωσεν, σωσάτω ἑαυτόν, εἰ οὗτός ἐστιν ὁ ˚Χριστὸς, ὁ υἱὸς τοῦ ˚Θεοῦ, ὁ ἐκλεκτός.”
42023036 Ἐνέπαιξαν δὲ αὐτῷ καὶ οἱ στρατιῶται προσερχόμενοι, ὄξος προσφέροντες αὐτῷ,
42023037 καὶ λέγοντες, “Εἰ σὺ εἶ ὁ Βασιλεὺς τῶν Ἰουδαίων, σῶσον σεαυτόν!”
42023038 Ἦν δὲ καὶ ἐπιγραφὴ ἐπʼ αὐτῷ: “Ὁ Βασιλεὺς τῶν Ἰουδαίων οὗτος.”
42023039 Εἷς δὲ τῶν κρεμασθέντων κακούργων ἐβλασφήμει αὐτόν λέγων, “Οὐχὶ σὺ εἶ ὁ ˚Χριστός; Σῶσον σεαυτὸν καὶ ἡμᾶς!”
42023040 Ἀποκριθεὶς δὲ ὁ ἕτερος ἐπιτιμῶν αὐτῷ ἔφη, “Οὐδὲ φοβῇ σὺ τὸν ˚Θεόν, ὅτι ἐν τῷ αὐτῷ κρίματι εἶ;
42023041 Καὶ ἡμεῖς μὲν δικαίως, ἄξια γὰρ ὧν ἐπράξαμεν ἀπολαμβάνομεν· οὗτος δὲ οὐδὲν ἄτοπον ἔπραξεν.”
42023042 Καὶ ἔλεγεν, “˚Ἰησοῦ, Μνήσθητί μου, ὅταν ἔλθῃς εἰς τὴν βασιλείαν σου!”
42023043 Καὶ εἶπεν αὐτῷ, “Ἀμήν, σοι λέγω, σήμερον μετʼ ἐμοῦ ἔσῃ ἐν τῷ Παραδείσῳ.”
42023044 Καὶ ἦν ἤδη ὡσεὶ ὥρα ἕκτη, καὶ σκότος ἐγένετο ἐφʼ ὅλην τὴν γῆν ἕως ὥρας ἐνάτης.
42023045 Τοῦ ἡλίου ἐκλιπόντος, ἐσχίσθη δὲ τὸ καταπέτασμα τοῦ ναοῦ μέσον.
42023046 Καὶ φωνήσας φωνῇ μεγάλῃ, ὁ ˚Ἰησοῦς εἶπεν, “Πάτερ, ‘εἰς χεῖράς σου παρατίθεμαι τὸ ˚Πνεῦμά μου’.” Τοῦτο δὲ εἰπὼν, ἐξέπνευσεν.
42023047 Ἰδὼν δὲ ὁ ἑκατοντάρχης τὸ γενόμενον, ἐδόξασεν τὸν ˚Θεὸν λέγων, “Ὄντως ὁ ἄνθρωπος οὗτος δίκαιος ἦν.”
42023048 Καὶ πάντες οἱ συμπαραγενόμενοι ὄχλοι ἐπὶ τὴν θεωρίαν ταύτην, θεωρήσαντες τὰ γενόμενα, τύπτοντες τὰ στήθη ὑπέστρεφον.
42023049 Εἱστήκεισαν δὲ πάντες οἱ γνωστοὶ αὐτῷ ἀπὸ μακρόθεν, καὶ γυναῖκες αἱ συνακολουθοῦσαι αὐτῷ ἀπὸ τῆς Γαλιλαίας, ὁρῶσαι ταῦτα.
42023050 Καὶ ἰδοὺ, ἀνὴρ ὀνόματι Ἰωσὴφ, βουλευτὴς ὑπάρχων, καὶ ἀνὴρ ἀγαθὸς καὶ δίκαιος
42023051 ( οὗτος οὐκ ἦν συγκατατεθειμένος τῇ βουλῇ καὶ τῇ πράξει αὐτῶν ), ἀπὸ Ἁριμαθαίας, πόλεως τῶν Ἰουδαίων, ὃς προσεδέχετο τὴν Βασιλείαν τοῦ ˚Θεοῦ.
42023052 Οὗτος προσελθὼν τῷ Πιλάτῳ, ᾐτήσατο τὸ σῶμα τοῦ ˚Ἰησοῦ.
42023053 Καὶ καθελὼν, ἐνετύλιξεν αὐτὸ σινδόνι καὶ ἔθηκεν αὐτὸν ἐν μνήματι λαξευτῷ, οὗ οὐκ ἦν οὐδεὶς οὔπω κείμενος.
42023054 Καὶ ἡμέρα ἦν παρασκευῆς, καὶ Σάββατον ἐπέφωσκεν.
42023055 Κατακολουθήσασαι δὲ, αἱ γυναῖκες, αἵτινες ἦσαν συνεληλυθυῖαι ἐκ τῆς Γαλιλαίας αὐτῷ, ἐθεάσαντο τὸ μνημεῖον καὶ ὡς ἐτέθη τὸ σῶμα αὐτοῦ.
42023056 Ὑποστρέψασαι δὲ, ἡτοίμασαν ἀρώματα καὶ μύρα. Καὶ τὸ μὲν Σάββατον ἡσύχασαν κατὰ τὴν ἐντολήν.
42024001 Τῇ δὲ μιᾷ τῶν σαββάτων, ὄρθρου βαθέως, ἐπὶ τὸ μνημεῖον ἦλθαν, φέρουσαι ἃ ἡτοίμασαν ἀρώματα.
42024002 Εὗρον δὲ τὸν λίθον ἀποκεκυλισμένον ἀπὸ τοῦ μνημείου.
42024003 Εἰσελθοῦσαι δὲ, οὐχ εὗρον τὸ σῶμα τοῦ ˚Κυρίου ˚Ἰησοῦ.
42024004 Καὶ ἐγένετο ἐν τῷ ἀπορεῖσθαι αὐτὰς περὶ τούτου, καὶ ἰδοὺ, ἄνδρες δύο ἐπέστησαν αὐταῖς ἐν ἐσθῆτι ἀστραπτούσῃ.
42024005 Ἐμφόβων δὲ γενομένων αὐτῶν, καὶ κλινουσῶν τὰ πρόσωπα εἰς τὴν γῆν, εἶπαν πρὸς αὐτάς, “Τί ζητεῖτε τὸν ζῶντα μετὰ τῶν νεκρῶν;
42024006 Οὐκ ἔστιν ὧδε, ἀλλὰ ἠγέρθη! Μνήσθητε ὡς ἐλάλησεν ὑμῖν, ἔτι ὢν ἐν τῇ Γαλιλαίᾳ
42024007 λέγων, ‘Τὸν Υἱὸν τοῦ Ἀνθρώπου ὅτι δεῖ παραδοθῆναι εἰς χεῖρας ἀνθρώπων ἁμαρτωλῶν, καὶ σταυρωθῆναι, καὶ τῇ τρίτῃ ἡμέρᾳ ἀναστῆναι.’”
42024008 Καὶ ἐμνήσθησαν τῶν ῥημάτων αὐτοῦ.
42024009 Καὶ ὑποστρέψασαι ἀπὸ τοῦ μνημείου, ἀπήγγειλαν ταῦτα πάντα τοῖς ἕνδεκα, καὶ πᾶσιν τοῖς λοιποῖς.
42024010 Ἦσαν δὲ ἡ Μαγδαληνὴ Μαρία, καὶ Ἰωάννα, καὶ Μαρία ἡ Ἰακώβου, καὶ αἱ λοιπαὶ σὺν αὐταῖς, ἔλεγον πρὸς τοὺς ἀποστόλους ταῦτα.
42024011 Καὶ ἐφάνησαν ἐνώπιον αὐτῶν ὡσεὶ λῆρος τὰ ῥήματα ταῦτα, καὶ ἠπίστουν αὐταῖς.
42024012 Ὁ δὲ Πέτρος ἀναστὰς, ἔδραμεν ἐπὶ τὸ μνημεῖον, καὶ παρακύψας, βλέπει τὰ ὀθόνια μόνα, καὶ ἀπῆλθεν πρὸς ἑαυτὸν, θαυμάζων τὸ γεγονός.
42024013 Καὶ ἰδοὺ, δύο ἐξ αὐτῶν ἐν αὐτῇ τῇ ἡμέρᾳ ἦσαν πορευόμενοι εἰς κώμην ἀπέχουσαν σταδίους ἑξήκοντα ἀπὸ Ἰερουσαλήμ, ᾗ ὄνομα Ἐμμαοῦς.
42024014 Καὶ αὐτοὶ ὡμίλουν πρὸς ἀλλήλους περὶ πάντων τῶν συμβεβηκότων τούτων.
42024015 Καὶ ἐγένετο ἐν τῷ, ὁμιλεῖν αὐτοὺς καὶ συζητεῖν, καὶ αὐτὸς ˚Ἰησοῦς ἐγγίσας, συνεπορεύετο αὐτοῖς.
42024016 Οἱ δὲ ὀφθαλμοὶ αὐτῶν ἐκρατοῦντο τοῦ μὴ ἐπιγνῶναι αὐτόν.
42024017 Εἶπεν δὲ πρὸς αὐτούς, “Τίνες οἱ λόγοι οὗτοι οὓς ἀντιβάλλετε πρὸς ἀλλήλους περιπατοῦντες;” Καὶ ἐστάθησαν σκυθρωποί.
42024018 Ἀποκριθεὶς δὲ εἷς ὀνόματι Κλεοπᾶς εἶπεν πρὸς αὐτόν, “Σὺ μόνος παροικεῖς Ἰερουσαλὴμ καὶ οὐκ ἔγνως τὰ γενόμενα ἐν αὐτῇ ἐν ταῖς ἡμέραις ταύταις;”
42024019 Καὶ εἶπεν αὐτοῖς, “Ποῖα;” Οἱ δὲ εἶπαν αὐτῷ, “Τὰ περὶ ˚Ἰησοῦ τοῦ Ναζαρηνοῦ, ὃς ἐγένετο ἀνὴρ, προφήτης, δυνατὸς ἐν ἔργῳ καὶ λόγῳ, ἐναντίον τοῦ ˚Θεοῦ καὶ παντὸς τοῦ λαοῦ,
42024020 ὅπως τε παρέδωκαν αὐτὸν οἱ ἀρχιερεῖς καὶ οἱ ἄρχοντες ἡμῶν, εἰς κρίμα θανάτου, καὶ ἐσταύρωσαν αὐτόν.
42024021 Ἡμεῖς δὲ ἠλπίζομεν ὅτι αὐτός ἐστιν ὁ μέλλων λυτροῦσθαι τὸν Ἰσραήλ. Ἀλλά γε καὶ σὺν πᾶσιν τούτοις, τρίτην ταύτην ἡμέραν ἄγει, ἀφʼ οὗ ταῦτα ἐγένετο.
42024022 Ἀλλὰ καὶ γυναῖκές τινες ἐξ ἡμῶν, ἐξέστησαν ἡμᾶς, γενόμεναι ὀρθριναὶ ἐπὶ τὸ μνημεῖον
42024023 καὶ μὴ εὑροῦσαι τὸ σῶμα αὐτοῦ, ἦλθαν λέγουσαι καὶ ὀπτασίαν ἀγγέλων ἑωρακέναι, οἳ λέγουσιν αὐτὸν ζῆν.
42024024 Καὶ ἀπῆλθόν τινες τῶν σὺν ἡμῖν ἐπὶ τὸ μνημεῖον, καὶ εὗρον οὕτως, καθὼς αἱ γυναῖκες εἶπον, αὐτὸν δὲ οὐκ εἶδον.”
42024025 Καὶ αὐτὸς εἶπεν πρὸς αὐτούς, “Ὦ ἀνόητοι καὶ βραδεῖς τῇ καρδίᾳ, τοῦ πιστεύειν ἐπὶ πᾶσιν οἷς ἐλάλησαν οἱ προφῆται.
42024026 Οὐχὶ ταῦτα ἔδει παθεῖν τὸν ˚Χριστὸν καὶ εἰσελθεῖν εἰς τὴν δόξαν αὐτοῦ;”
42024027 Καὶ ἀρξάμενος ἀπὸ Μωϋσέως καὶ ἀπὸ πάντων τῶν προφητῶν, διερμήνευσεν αὐτοῖς ἐν πάσαις ταῖς Γραφαῖς τὰ περὶ ἑαυτοῦ.
42024028 Καὶ ἤγγισαν εἰς τὴν κώμην οὗ ἐπορεύοντο, καὶ αὐτὸς προσεποιήσατο πορρώτερον πορεύεσθαι.
42024029 Καὶ παρεβιάσαντο αὐτὸν λέγοντες, “Μεῖνον μεθʼ ἡμῶν, ὅτι πρὸς ἑσπέραν ἐστὶν, καὶ κέκλικεν ἤδη ἡ ἡμέρα.” Καὶ εἰσῆλθεν τοῦ μεῖναι σὺν αὐτοῖς.
42024030 Καὶ ἐγένετο ἐν τῷ κατακλιθῆναι αὐτὸν μετʼ αὐτῶν, λαβὼν τὸν ἄρτον, εὐλόγησεν καὶ κλάσας, ἐπεδίδου αὐτοῖς.
42024031 Αὐτῶν δὲ διηνοίχθησαν οἱ ὀφθαλμοὶ, καὶ ἐπέγνωσαν αὐτόν. Καὶ αὐτὸς ἄφαντος ἐγένετο ἀπʼ αὐτῶν.
42024032 Καὶ εἶπαν πρὸς ἀλλήλους, “Οὐχὶ ἡ καρδία ἡμῶν καιομένη ἦν ὡς ἐλάλει ἡμῖν ἐν τῇ ὁδῷ, ὡς διήνοιγεν ἡμῖν τὰς Γραφάς;”
42024033 Καὶ ἀναστάντες αὐτῇ τῇ ὥρᾳ, ὑπέστρεψαν εἰς Ἰερουσαλήμ, καὶ εὗρον ἠθροισμένους τοὺς ἕνδεκα καὶ τοὺς σὺν αὐτοῖς
42024034 λέγοντας, ὅτι “Ὄντως ἠγέρθη ὁ ˚Κύριος, καὶ ὤφθη Σίμωνι.”
42024035 Καὶ αὐτοὶ ἐξηγοῦντο τὰ ἐν τῇ ὁδῷ, καὶ ὡς ἐγνώσθη αὐτοῖς ἐν τῇ κλάσει τοῦ ἄρτου.
42024036 Ταῦτα δὲ αὐτῶν λαλούντων, αὐτὸς ἔστη ἐν μέσῳ αὐτῶν καὶ λέγει αὐτοῖς, “Εἰρήνη ὑμῖν.”
42024037 Πτοηθέντες δὲ καὶ ἔμφοβοι γενόμενοι, ἐδόκουν πνεῦμα θεωρεῖν.
42024038 Καὶ εἶπεν αὐτοῖς, “Τί τεταραγμένοι ἐστέ, καὶ διὰ τί διαλογισμοὶ ἀναβαίνουσιν ἐν τῇ καρδίᾳ ὑμῶν;
42024039 Ἴδετε τὰς χεῖράς μου καὶ τοὺς πόδας μου ὅτι ἐγώ εἰμι αὐτός. Ψηλαφήσατέ με καὶ ἴδετε, ὅτι πνεῦμα σάρκα καὶ ὀστέα οὐκ ἔχει, καθὼς ἐμὲ θεωρεῖτε ἔχοντα.”
42024040 Καὶ τοῦτο εἰπὼν, ἔδειξεν αὐτοῖς τὰς χεῖρας καὶ τοὺς πόδας.
42024041 Ἔτι δὲ ἀπιστούντων αὐτῶν ἀπὸ τῆς χαρᾶς καὶ θαυμαζόντων, εἶπεν αὐτοῖς, “Ἔχετέ τι βρώσιμον ἐνθάδε;”
42024042 Οἱ δὲ ἐπέδωκαν αὐτῷ ἰχθύος ὀπτοῦ μέρος.
42024043 Καὶ λαβὼν, ἐνώπιον αὐτῶν ἔφαγεν.
42024044 Εἶπεν δὲ πρὸς αὐτούς, “Οὗτοι οἱ λόγοι μου, οὓς ἐλάλησα πρὸς ὑμᾶς ἔτι ὢν σὺν ὑμῖν, ὅτι δεῖ πληρωθῆναι πάντα τὰ γεγραμμένα ἐν τῷ νόμῳ Μωϋσέως, καὶ τοῖς προφήταις, καὶ ψαλμοῖς, περὶ ἐμοῦ.”
42024045 Τότε διήνοιξεν αὐτῶν τὸν νοῦν τοῦ συνιέναι τὰς Γραφάς. Καὶ εἶπεν αὐτοῖς,
42024046 ὅτι “Οὕτως γέγραπται, παθεῖν τὸν ˚Χριστὸν, καὶ ἀναστῆναι ἐκ νεκρῶν τῇ τρίτῃ ἡμέρᾳ,
42024047 καὶ κηρυχθῆναι ἐπὶ τῷ ὀνόματι αὐτοῦ μετάνοιαν εἰς ἄφεσιν ἁμαρτιῶν εἰς πάντα τὰ ἔθνη, ἀρξάμενοι ἀπὸ Ἰερουσαλήμ.
42024048 Ὑμεῖς μάρτυρες τούτων.
42024049 Καὶ ἰδοὺ ἐγὼ ἀποστέλλω τὴν ἐπαγγελίαν τοῦ Πατρός μου ἐφʼ ὑμᾶς· ὑμεῖς δὲ καθίσατε ἐν τῇ πόλει, ἕως οὗ ἐνδύσησθε ἐξ ὕψους δύναμιν.”
42024050 Ἐξήγαγεν δὲ αὐτοὺς ἕως πρὸς Βηθανίαν, καὶ ἐπάρας τὰς χεῖρας αὐτοῦ, εὐλόγησεν αὐτούς.
42024051 Καὶ ἐγένετο ἐν τῷ εὐλογεῖν αὐτὸν αὐτοὺς, διέστη ἀπʼ αὐτῶν, καὶ ἀνεφέρετο εἰς τὸν οὐρανόν.
42024052 Καὶ αὐτοὶ προσκυνήσαντες αὐτὸν, ὑπέστρεψαν εἰς Ἰερουσαλὴμ μετὰ χαρᾶς μεγάλης.
42024053 Καὶ ἦσαν διὰ παντὸς ἐν τῷ ἱερῷ εὐλογοῦντες τὸν ˚Θεόν.
43001001 Ἐν ἀρχῇ ἦν ὁ λόγος, καὶ ὁ λόγος ἦν πρὸς τὸν ˚Θεόν, καὶ ˚Θεὸς ἦν ὁ λόγος.
43001002 Οὗτος ἦν ἐν ἀρχῇ πρὸς τὸν ˚Θεόν.
43001003 Πάντα διʼ αὐτοῦ ἐγένετο, καὶ χωρὶς αὐτοῦ ἐγένετο οὐδὲ ἕν ὃ γέγονεν.
43001004 Ἐν αὐτῷ ζωὴ ἦν, καὶ ἡ ζωὴ ἦν τὸ φῶς τῶν ἀνθρώπων.
43001005 Καὶ τὸ φῶς ἐν τῇ σκοτίᾳ φαίνει, καὶ ἡ σκοτία αὐτὸ οὐ κατέλαβεν.
43001006 Ἐγένετο ἄνθρωπος ἀπεσταλμένος παρὰ ˚Θεοῦ, ὄνομα αὐτῷ Ἰωάννης.
43001007 Οὗτος ἦλθεν εἰς μαρτυρίαν, ἵνα μαρτυρήσῃ περὶ τοῦ φωτός, ἵνα πάντες πιστεύσωσιν διʼ αὐτοῦ.
43001008 Οὐκ ἦν ἐκεῖνος τὸ φῶς, ἀλλʼ ἵνα μαρτυρήσῃ περὶ τοῦ φωτός.
43001009 Ἦν τὸ φῶς τὸ ἀληθινὸν, ὃ φωτίζει πάντα ἄνθρωπον, ἐρχόμενον εἰς τὸν κόσμον.
43001010 Ἐν τῷ κόσμῳ ἦν, καὶ ὁ κόσμος διʼ αὐτοῦ ἐγένετο, καὶ ὁ κόσμος αὐτὸν οὐκ ἔγνω.
43001011 Εἰς τὰ ἴδια ἦλθεν, καὶ οἱ ἴδιοι αὐτὸν οὐ παρέλαβον.
43001012 Ὅσοι δὲ ἔλαβον αὐτόν, ἔδωκεν αὐτοῖς ἐξουσίαν τέκνα ˚Θεοῦ γενέσθαι, τοῖς πιστεύουσιν εἰς τὸ ὄνομα αὐτοῦ,
43001013 οἳ οὐκ ἐξ αἱμάτων, οὐδὲ ἐκ θελήματος σαρκὸς, οὐδὲ ἐκ θελήματος ἀνδρὸς, ἀλλʼ ἐκ ˚Θεοῦ ἐγεννήθησαν.
43001014 Καὶ ὁ λόγος σὰρξ ἐγένετο καὶ ἐσκήνωσεν ἐν ἡμῖν, καὶ ἐθεασάμεθα τὴν δόξαν αὐτοῦ, δόξαν ὡς μονογενοῦς παρὰ πατρός, πλήρης χάριτος καὶ ἀληθείας.
43001015 Ἰωάννης μαρτυρεῖ περὶ αὐτοῦ, καὶ κέκραγεν λέγων, “Οὗτος ἦν ὃν εἶπον, ‘Ὁ ὀπίσω μου ἐρχόμενος, ἔμπροσθέν μου γέγονεν, ὅτι πρῶτός μου ἦν.’”
43001016 Ὅτι ἐκ τοῦ πληρώματος αὐτοῦ, ἡμεῖς πάντες ἐλάβομεν καὶ χάριν ἀντὶ χάριτος.
43001017 Ὅτι ὁ νόμος διὰ Μωϋσέως ἐδόθη, ἡ χάρις καὶ ἡ ἀλήθεια διὰ ˚Ἰησοῦ ˚Χριστοῦ ἐγένετο.
43001018 ˚Θεὸν οὐδεὶς ἑώρακεν πώποτε· μονογενὴς ˚Θεὸς, ὁ ὢν εἰς τὸν κόλπον τοῦ Πατρὸς, ἐκεῖνος ἐξηγήσατο.
43001019 Καὶ αὕτη ἐστὶν ἡ μαρτυρία τοῦ Ἰωάννου, ὅτε ἀπέστειλαν οἱ Ἰουδαῖοι ἐξ Ἱεροσολύμων ἱερεῖς καὶ Λευίτας, ἵνα ἐρωτήσωσιν αὐτόν, “Σὺ τίς εἶ;”
43001020 Καὶ ὡμολόγησεν καὶ οὐκ ἠρνήσατο, καὶ ὡμολόγησεν, ὅτι “Ἐγὼ οὐκ εἰμὶ ὁ ˚Χριστός.”
43001021 Καὶ ἠρώτησαν αὐτόν, “Τί οὖν; Σὺ Ἠλίας εἶ;” Καὶ λέγει, “Οὐκ εἰμί.” “Ὁ προφήτης εἶ σύ;” Καὶ ἀπεκρίθη, “Οὔ.”
43001022 Εἶπαν οὖν αὐτῷ, “Τίς εἶ; Ἵνα ἀπόκρισιν δῶμεν τοῖς πέμψασιν ἡμᾶς. Τί λέγεις περὶ σεαυτοῦ;”
43001023 Ἔφη, “Ἐγὼ ‘Φωνὴ βοῶντος ἐν τῇ ἐρήμῳ: “Εὐθύνατε τὴν ὁδὸν ˚Κυρίου”’, καθὼς εἶπεν Ἠσαΐας ὁ προφήτης.”
43001024 Καὶ ἀπεσταλμένοι ἦσαν ἐκ τῶν Φαρισαίων.
43001025 Καὶ ἠρώτησαν αὐτὸν καὶ εἶπαν αὐτῷ, “Τί οὖν βαπτίζεις, εἰ σὺ οὐκ εἶ ὁ ˚Χριστὸς, οὐδὲ Ἠλίας, οὐδὲ ὁ προφήτης;”
43001026 Ἀπεκρίθη αὐτοῖς ὁ Ἰωάννης λέγων, “Ἐγὼ βαπτίζω ἐν ὕδατι· μέσος ὑμῶν ἕστηκεν ὃν ὑμεῖς οὐκ οἴδατε,
43001027 ὁ ὀπίσω μου ἐρχόμενος, οὗ οὐκ εἰμὶ ἐγὼ ἄξιος, ἵνα λύσω αὐτοῦ τὸν ἱμάντα τοῦ ὑποδήματος.”
43001028 Ταῦτα ἐν Βηθανίᾳ ἐγένετο, πέραν τοῦ Ἰορδάνου, ὅπου ἦν ὁ Ἰωάννης βαπτίζων.
43001029 Τῇ ἐπαύριον βλέπει τὸν ˚Ἰησοῦν ἐρχόμενον πρὸς αὐτόν, καὶ λέγει, “Ἴδε, ὁ Ἀμνὸς τοῦ ˚Θεοῦ, ὁ αἴρων τὴν ἁμαρτίαν τοῦ κόσμου.
43001030 Οὗτός ἐστιν ὑπὲρ οὗ ἐγὼ εἶπον, ‘Ὀπίσω μου ἔρχεται ἀνὴρ, ὃς ἔμπροσθέν μου γέγονεν, ὅτι πρῶτός μου ἦν.’
43001031 Κἀγὼ οὐκ ᾔδειν αὐτόν, ἀλλʼ ἵνα φανερωθῇ τῷ Ἰσραὴλ διὰ τοῦτο, ἦλθον ἐγὼ ἐν ὕδατι βαπτίζων.”
43001032 Καὶ ἐμαρτύρησεν Ἰωάννης λέγων, ὅτι “Τεθέαμαι τὸ ˚Πνεῦμα καταβαῖνον ὡς περιστερὰν ἐξ οὐρανοῦ, καὶ ἔμεινεν ἐπʼ αὐτόν.
43001033 Κἀγὼ οὐκ ᾔδειν αὐτόν, ἀλλʼ ὁ πέμψας με βαπτίζειν ἐν ὕδατι, ἐκεῖνός μοι εἶπεν, ‘Ἐφʼ ὃν ἂν ἴδῃς τὸ ˚Πνεῦμα καταβαῖνον καὶ μένον ἐπʼ αὐτόν, οὗτός ἐστιν ὁ βαπτίζων ἐν ˚Πνεύματι Ἁγίῳ.’
43001034 Κἀγὼ ἑώρακα καὶ μεμαρτύρηκα ὅτι οὗτός ἐστιν ὁ Υἱὸς τοῦ ˚Θεοῦ.”
43001035 Τῇ ἐπαύριον πάλιν εἱστήκει ὁ Ἰωάννης, καὶ ἐκ τῶν μαθητῶν αὐτοῦ δύο.
43001036 Καὶ ἐμβλέψας τῷ ˚Ἰησοῦ περιπατοῦντι λέγει, “Ἴδε, ὁ Ἀμνὸς τοῦ ˚Θεοῦ!”
43001037 Καὶ ἤκουσαν οἱ δύο αὐτοῦ μαθηταὶ λαλοῦντος, καὶ ἠκολούθησαν τῷ ˚Ἰησοῦ.
43001038 Στραφεὶς δὲ ὁ ˚Ἰησοῦς, καὶ θεασάμενος αὐτοὺς ἀκολουθοῦντας, λέγει αὐτοῖς, “Τί ζητεῖτε;” Οἱ δὲ εἶπαν αὐτῷ, “Ῥαββί” ( ὃ λέγεται μεθερμηνευόμενον, “Διδάσκαλε” ), “Ποῦ μένεις;”
43001039 Λέγει αὐτοῖς, “Ἔρχεσθε καὶ ὄψεσθε.” Ἦλθαν οὖν καὶ εἶδαν ποῦ μένει, καὶ παρʼ αὐτῷ ἔμειναν τὴν ἡμέραν ἐκείνην· ὥρα ἦν ὡς δεκάτη.
43001040 Ἦν Ἀνδρέας ὁ ἀδελφὸς Σίμωνος Πέτρου, εἷς ἐκ τῶν δύο τῶν ἀκουσάντων παρὰ Ἰωάννου, καὶ ἀκολουθησάντων αὐτῷ.
43001041 Εὑρίσκει οὗτος πρῶτον τὸν ἀδελφὸν τὸν ἴδιον Σίμωνα, καὶ λέγει αὐτῷ, “Εὑρήκαμεν τὸν Μεσσίαν”, ( ὅ ἐστιν μεθερμηνευόμενον, “˚Χριστός” ).
43001042 ἤγαγεν αὐτὸν πρὸς τὸν ˚Ἰησοῦν. Ἐμβλέψας αὐτῷ, ὁ ˚Ἰησοῦς εἶπεν, “Σὺ εἶ Σίμων ὁ υἱὸς Ἰωάννου, σὺ κληθήσῃ Κηφᾶς”, ( ὃ ἑρμηνεύεται, “Πέτρος” ).
43001043 Τῇ ἐπαύριον ἠθέλησεν ἐξελθεῖν εἰς τὴν Γαλιλαίαν. Καὶ εὑρίσκει Φίλιππον, καὶ λέγει αὐτῷ ὁ ˚Ἰησοῦς, “Ἀκολούθει μοι.”
43001044 Ἦν δὲ ὁ Φίλιππος ἀπὸ Βηθσαϊδά, ἐκ τῆς πόλεως Ἀνδρέου καὶ Πέτρου.
43001045 Εὑρίσκει Φίλιππος τὸν Ναθαναὴλ, καὶ λέγει αὐτῷ, “Ὃν ἔγραψεν Μωϋσῆς ἐν τῷ νόμῳ καὶ οἱ προφῆται, εὑρήκαμεν ˚Ἰησοῦν υἱὸν τοῦ Ἰωσὴφ, τὸν ἀπὸ Ναζαρέτ.”
43001046 Καὶ εἶπεν αὐτῷ Ναθαναήλ, “Ἐκ Ναζαρὲτ δύναταί τι ἀγαθὸν εἶναι;” Λέγει αὐτῷ ὁ Φίλιππος, “Ἔρχου καὶ ἴδε.”
43001047 Εἶδεν ὁ ˚Ἰησοῦς τὸν Ναθαναὴλ ἐρχόμενον πρὸς αὐτὸν καὶ λέγει περὶ αὐτοῦ, “Ἴδε, ἀληθῶς Ἰσραηλίτης ἐν ᾧ δόλος οὐκ ἔστιν.”
43001048 Λέγει αὐτῷ Ναθαναήλ, “Πόθεν με γινώσκεις;” Ἀπεκρίθη ˚Ἰησοῦς καὶ εἶπεν αὐτῷ, “Πρὸ τοῦ σε Φίλιππον φωνῆσαι, ὄντα ὑπὸ τὴν συκῆν, εἶδόν σε.”
43001049 Ἀπεκρίθη αὐτῷ Ναθαναήλ, “Ῥαββί, σὺ εἶ ὁ Υἱὸς τοῦ ˚Θεοῦ, σὺ Βασιλεὺς εἶ τοῦ Ἰσραήλ.”
43001050 Ἀπεκρίθη ˚Ἰησοῦς καὶ εἶπεν αὐτῷ, “Ὅτι εἶπόν σοι ὅτι εἶδόν σε ὑποκάτω τῆς συκῆς, πιστεύεις; Μείζω τούτων ὄψῃ.”
43001051 Καὶ λέγει αὐτῷ, “Ἀμὴν, ἀμὴν, λέγω ὑμῖν, ὄψεσθε τὸν οὐρανὸν ἀνεῳγότα, καὶ τοὺς ἀγγέλους τοῦ ˚Θεοῦ ἀναβαίνοντας καὶ καταβαίνοντας ἐπὶ τὸν Υἱὸν τοῦ Ἀνθρώπου.”
43002001 Καὶ τῇ ἡμέρᾳ τῇ τρίτῃ γάμος ἐγένετο ἐν Κανὰ τῆς Γαλιλαίας, καὶ ἦν ἡ μήτηρ τοῦ ˚Ἰησοῦ ἐκεῖ.
43002002 Ἐκλήθη δὲ καὶ ὁ ˚Ἰησοῦς καὶ οἱ μαθηταὶ αὐτοῦ εἰς τὸν γάμον.
43002003 Καὶ ὑστερήσαντος οἴνου, λέγει ἡ μήτηρ τοῦ ˚Ἰησοῦ πρὸς αὐτόν, “Οἶνον οὐκ ἔχουσιν.”
43002004 Καὶ λέγει αὐτῇ ὁ ˚Ἰησοῦς, “Τί ἐμοὶ καὶ σοί, γύναι; Οὔπω ἥκει ἡ ὥρα μου.”
43002005 Λέγει ἡ μήτηρ αὐτοῦ τοῖς διακόνοις, “Ὅ τι ἂν λέγῃ ὑμῖν ποιήσατε.”
43002006 Ἦσαν δὲ ἐκεῖ λίθιναι ὑδρίαι ἓξ κατὰ τὸν καθαρισμὸν τῶν Ἰουδαίων κείμεναι, χωροῦσαι ἀνὰ μετρητὰς δύο ἢ τρεῖς.
43002007 Λέγει αὐτοῖς ὁ ˚Ἰησοῦς, “Γεμίσατε τὰς ὑδρίας ὕδατος.” Καὶ ἐγέμισαν αὐτὰς ἕως ἄνω.
43002008 Καὶ λέγει αὐτοῖς, “Ἀντλήσατε νῦν καὶ φέρετε τῷ ἀρχιτρικλίνῳ.” Οἱ δὲ ἤνεγκαν.
43002009 Ὡς δὲ ἐγεύσατο ὁ ἀρχιτρίκλινος τὸ ὕδωρ οἶνον γεγενημένον, καὶ οὐκ ᾔδει πόθεν ἐστίν, οἱ δὲ διάκονοι ᾔδεισαν οἱ ἠντληκότες τὸ ὕδωρ, φωνεῖ τὸν νυμφίον ὁ ἀρχιτρίκλινος
43002010 καὶ λέγει αὐτῷ, “Πᾶς ἄνθρωπος πρῶτον τὸν καλὸν οἶνον τίθησιν, καὶ ὅταν μεθυσθῶσιν τὸν ἐλάσσω· σὺ τετήρηκας τὸν καλὸν οἶνον ἕως ἄρτι.”
43002011 Ταύτην ἐποίησεν ἀρχὴν τῶν σημείων ὁ ˚Ἰησοῦς ἐν Κανὰ τῆς Γαλιλαίας, καὶ ἐφανέρωσεν τὴν δόξαν αὐτοῦ, καὶ ἐπίστευσαν εἰς αὐτὸν οἱ μαθηταὶ αὐτοῦ.
43002012 Μετὰ τοῦτο κατέβη εἰς Καφαρναοὺμ, αὐτὸς καὶ ἡ μήτηρ αὐτοῦ, καὶ οἱ ἀδελφοὶ καὶ οἱ μαθηταὶ αὐτοῦ, καὶ ἐκεῖ ἔμειναν οὐ πολλὰς ἡμέρας.
43002013 Καὶ ἐγγὺς ἦν τὸ Πάσχα τῶν Ἰουδαίων, καὶ ἀνέβη εἰς Ἱεροσόλυμα ὁ ˚Ἰησοῦς.
43002014 Καὶ εὗρεν ἐν τῷ ἱερῷ τοὺς πωλοῦντας βόας καὶ πρόβατα καὶ περιστερὰς, καὶ τοὺς κερματιστὰς καθημένους.
43002015 Καὶ ποιήσας φραγέλλιον ἐκ σχοινίων, πάντας ἐξέβαλεν ἐκ τοῦ ἱεροῦ, τά τε πρόβατα καὶ τοὺς βόας, καὶ τῶν κολλυβιστῶν ἐξέχεεν τὰ κέρματα, καὶ τὰς τραπέζας ἀνέτρεψεν.
43002016 Καὶ τοῖς τὰς περιστερὰς πωλοῦσιν εἶπεν, “Ἄρατε ταῦτα ἐντεῦθεν, μὴ ποιεῖτε τὸν οἶκον τοῦ πατρός μου οἶκον ἐμπορίου.”
43002017 Ἐμνήσθησαν οἱ μαθηταὶ αὐτοῦ ὅτι γεγραμμένον ἐστίν, “Ὁ ζῆλος τοῦ οἴκου σου καταφάγεταί με.”
43002018 Ἀπεκρίθησαν οὖν οἱ Ἰουδαῖοι καὶ εἶπαν αὐτῷ, “Τί σημεῖον δεικνύεις ἡμῖν, ὅτι ταῦτα ποιεῖς;”
43002019 Ἀπεκρίθη ˚Ἰησοῦς καὶ εἶπεν αὐτοῖς, “Λύσατε τὸν ναὸν τοῦτον, καὶ ἐν τρισὶν ἡμέραις ἐγερῶ αὐτόν.”
43002020 Εἶπαν οὖν οἱ Ἰουδαῖοι, “Τεσσεράκοντα καὶ ἓξ ἔτεσιν οἰκοδομήθη ὁ ναὸς οὗτος, καὶ σὺ ἐν τρισὶν ἡμέραις ἐγερεῖς αὐτόν;”
43002021 Ἐκεῖνος δὲ ἔλεγεν περὶ τοῦ ναοῦ τοῦ σώματος αὐτοῦ.
43002022 Ὅτε οὖν ἠγέρθη ἐκ νεκρῶν, ἐμνήσθησαν οἱ μαθηταὶ αὐτοῦ ὅτι τοῦτο ἔλεγεν, καὶ ἐπίστευσαν τῇ Γραφῇ καὶ τῷ λόγῳ ὃν εἶπεν ὁ ˚Ἰησοῦς.
43002023 Ὡς δὲ ἦν ἐν τοῖς Ἱεροσολύμοις ἐν τῷ Πάσχα ἐν τῇ ἑορτῇ, πολλοὶ ἐπίστευσαν εἰς τὸ ὄνομα αὐτοῦ, θεωροῦντες αὐτοῦ τὰ σημεῖα ἃ ἐποίει.
43002024 Αὐτὸς δὲ ˚Ἰησοῦς οὐκ ἐπίστευεν ἑαυτὸν αὐτοῖς, διὰ τὸ αὐτὸν γινώσκειν πάντας.
43002025 Καὶ ὅτι οὐ χρείαν εἶχεν ἵνα τις μαρτυρήσῃ περὶ τοῦ ἀνθρώπου, αὐτὸς γὰρ ἐγίνωσκεν τί ἦν ἐν τῷ ἀνθρώπῳ.
43003001 Ἦν δὲ ἄνθρωπος ἐκ τῶν Φαρισαίων, Νικόδημος ὄνομα αὐτῷ, ἄρχων τῶν Ἰουδαίων·
43003002 οὗτος ἦλθεν πρὸς αὐτὸν νυκτὸς, καὶ εἶπεν αὐτῷ, “Ῥαββί, οἴδαμεν ὅτι ἀπὸ ˚Θεοῦ ἐλήλυθας διδάσκαλος· οὐδεὶς γὰρ δύναται ταῦτα τὰ σημεῖα ποιεῖν, ἃ σὺ ποιεῖς, ἐὰν μὴ ᾖ ὁ ˚Θεὸς μετʼ αὐτοῦ.”
43003003 Ἀπεκρίθη ˚Ἰησοῦς καὶ εἶπεν αὐτῷ, “Ἀμὴν, ἀμὴν, λέγω σοι, ἐὰν μή τις γεννηθῇ ἄνωθεν, οὐ δύναται ἰδεῖν τὴν Βασιλείαν τοῦ ˚Θεοῦ.”
43003004 Λέγει πρὸς αὐτὸν Νικόδημος, “Πῶς δύναται ἄνθρωπος γεννηθῆναι, γέρων ὤν; Μὴ δύναται εἰς τὴν κοιλίαν τῆς μητρὸς αὐτοῦ δεύτερον εἰσελθεῖν καὶ γεννηθῆναι;”
43003005 Ἀπεκρίθη ˚Ἰησοῦς, “Ἀμὴν, ἀμὴν, λέγω σοι, ἐὰν μή τις γεννηθῇ ἐξ ὕδατος καὶ ˚Πνεύματος, οὐ δύναται εἰσελθεῖν εἰς τὴν Βασιλείαν τοῦ ˚Θεοῦ.
43003006 Τὸ γεγεννημένον ἐκ τῆς σαρκὸς, σάρξ ἐστιν, καὶ τὸ γεγεννημένον ἐκ τοῦ ˚Πνεύματος, πνεῦμά ἐστιν.
43003007 Μὴ θαυμάσῃς ὅτι εἶπόν σοι, ‘Δεῖ ὑμᾶς γεννηθῆναι ἄνωθεν.’
43003008 Τὸ πνεῦμα ὅπου θέλει, πνεῖ, καὶ τὴν φωνὴν αὐτοῦ ἀκούεις, ἀλλʼ οὐκ οἶδας πόθεν ἔρχεται, καὶ ποῦ ὑπάγει· οὕτως ἐστὶν πᾶς ὁ γεγεννημένος ἐκ τοῦ ˚Πνεύματος.”
43003009 Ἀπεκρίθη Νικόδημος καὶ εἶπεν αὐτῷ, “Πῶς δύναται ταῦτα γενέσθαι;”
43003010 Ἀπεκρίθη ˚Ἰησοῦς καὶ εἶπεν αὐτῷ, “Σὺ εἶ ὁ διδάσκαλος τοῦ Ἰσραὴλ, καὶ ταῦτα οὐ γινώσκεις;
43003011 Ἀμὴν, ἀμὴν, λέγω σοι ὅτι ὃ οἴδαμεν λαλοῦμεν, καὶ ὃ ἑωράκαμεν μαρτυροῦμεν, καὶ τὴν μαρτυρίαν ἡμῶν οὐ λαμβάνετε.
43003012 Εἰ τὰ ἐπίγεια εἶπον ὑμῖν, καὶ οὐ πιστεύετε, πῶς ἐὰν εἴπω ὑμῖν τὰ ἐπουράνια, πιστεύσετε;
43003013 Καὶ οὐδεὶς ἀναβέβηκεν εἰς τὸν οὐρανὸν, εἰ μὴ ὁ ἐκ τοῦ οὐρανοῦ καταβάς– ὁ Υἱὸς τοῦ Ἀνθρώπου.
43003014 Καὶ καθὼς Μωϋσῆς ὕψωσεν τὸν ὄφιν ἐν τῇ ἐρήμῳ, οὕτως ὑψωθῆναι δεῖ τὸν Υἱὸν τοῦ Ἀνθρώπου,
43003015 ἵνα πᾶς ὁ πιστεύων ἐν αὐτῷ ἔχῃ ζωὴν αἰώνιον.
43003016 Οὕτως γὰρ ἠγάπησεν ὁ ˚Θεὸς τὸν κόσμον, ὥστε τὸν Υἱὸν τὸν μονογενῆ ἔδωκεν, ἵνα πᾶς ὁ πιστεύων εἰς αὐτὸν μὴ ἀπόληται, ἀλλʼ ἔχῃ ζωὴν αἰώνιον.
43003017 Οὐ γὰρ ἀπέστειλεν ὁ ˚Θεὸς τὸν Υἱὸν εἰς τὸν κόσμον, ἵνα κρίνῃ τὸν κόσμον, ἀλλʼ ἵνα σωθῇ ὁ κόσμος διʼ αὐτοῦ.
43003018 Ὁ πιστεύων εἰς αὐτὸν οὐ κρίνεται. Ὁ δὲ μὴ πιστεύων, ἤδη κέκριται, ὅτι μὴ πεπίστευκεν εἰς τὸ ὄνομα τοῦ μονογενοῦς Υἱοῦ τοῦ ˚Θεοῦ.
43003019 Αὕτη δέ ἐστιν ἡ κρίσις, ὅτι τὸ φῶς ἐλήλυθεν εἰς τὸν κόσμον, καὶ ἠγάπησαν οἱ ἄνθρωποι μᾶλλον τὸ σκότος ἢ τὸ φῶς, ἦν γὰρ αὐτῶν πονηρὰ τὰ ἔργα.
43003020 Πᾶς γὰρ ὁ φαῦλα πράσσων, μισεῖ τὸ φῶς, καὶ οὐκ ἔρχεται πρὸς τὸ φῶς, ἵνα μὴ ἐλεγχθῇ τὰ ἔργα αὐτοῦ.
43003021 Ὁ δὲ ποιῶν τὴν ἀλήθειαν, ἔρχεται πρὸς τὸ φῶς, ἵνα φανερωθῇ αὐτοῦ τὰ ἔργα, ὅτι ἐν ˚Θεῷ ἐστιν εἰργασμένα.”
43003022 Μετὰ ταῦτα ἦλθεν ὁ ˚Ἰησοῦς καὶ οἱ μαθηταὶ αὐτοῦ εἰς τὴν Ἰουδαίαν γῆν, καὶ ἐκεῖ διέτριβεν μετʼ αὐτῶν καὶ ἐβάπτιζεν.
43003023 Ἦν δὲ καὶ ὁ Ἰωάννης βαπτίζων ἐν Αἰνὼν ἐγγὺς τοῦ Σαλείμ, ὅτι ὕδατα πολλὰ ἦν ἐκεῖ, καὶ παρεγίνοντο καὶ ἐβαπτίζοντο.
43003024 Οὔπω γὰρ ἦν βεβλημένος εἰς τὴν φυλακὴν ὁ Ἰωάννης.
43003025 Ἐγένετο οὖν ζήτησις ἐκ τῶν μαθητῶν Ἰωάννου μετὰ Ἰουδαίου περὶ καθαρισμοῦ.
43003026 Καὶ ἦλθαν πρὸς τὸν Ἰωάννην καὶ εἶπαν αὐτῷ, “Ῥαββί, ὃς ἦν μετὰ σοῦ πέραν τοῦ Ἰορδάνου, ᾧ σὺ μεμαρτύρηκας, ἴδε, οὗτος βαπτίζει καὶ πάντες ἔρχονται πρὸς αὐτόν.”
43003027 Ἀπεκρίθη Ἰωάννης καὶ εἶπεν, “Οὐ δύναται ἄνθρωπος λαμβάνειν, οὐδὲ ἓν ἐὰν μὴ ᾖ δεδομένον αὐτῷ ἐκ τοῦ οὐρανοῦ.
43003028 Αὐτοὶ ὑμεῖς μοι μαρτυρεῖτε ὅτι εἶπον, ‘Οὐκ εἰμὶ ἐγὼ ὁ ˚Χριστός’, ἀλλʼ, ὅτι ‘Ἀπεσταλμένος εἰμὶ ἔμπροσθεν ἐκείνου.’
43003029 Ὁ ἔχων τὴν νύμφην, νυμφίος ἐστίν· ὁ δὲ φίλος τοῦ νυμφίου, ὁ ἑστηκὼς καὶ ἀκούων αὐτοῦ, χαρᾷ χαίρει διὰ τὴν φωνὴν τοῦ νυμφίου. Αὕτη οὖν ἡ χαρὰ ἡ ἐμὴ πεπλήρωται.
43003030 Ἐκεῖνον δεῖ αὐξάνειν, ἐμὲ δὲ ἐλαττοῦσθαι.
43003031 Ὁ ἄνωθεν ἐρχόμενος, ἐπάνω πάντων ἐστίν. Ὁ ὢν ἐκ τῆς γῆς, ἐκ τῆς γῆς ἐστιν, καὶ ἐκ τῆς γῆς λαλεῖ. Ὁ ἐκ τοῦ οὐρανοῦ ἐρχόμενος, ἐπάνω πάντων ἐστίν.
43003032 Ὃ ἑώρακεν καὶ ἤκουσεν, τοῦτο μαρτυρεῖ, καὶ τὴν μαρτυρίαν αὐτοῦ, οὐδεὶς λαμβάνει.
43003033 Ὁ λαβὼν αὐτοῦ τὴν μαρτυρίαν, ἐσφράγισεν ὅτι ὁ ˚Θεὸς ἀληθής ἐστιν.
43003034 Ὃν γὰρ ἀπέστειλεν ὁ ˚Θεὸς, τὰ ῥήματα τοῦ ˚Θεοῦ λαλεῖ, οὐ γὰρ ἐκ μέτρου δίδωσιν τὸ ˚Πνεῦμα.
43003035 Ὁ Πατὴρ ἀγαπᾷ τὸν Υἱόν, καὶ πάντα δέδωκεν ἐν τῇ χειρὶ αὐτοῦ.
43003036 Ὁ πιστεύων εἰς τὸν Υἱὸν, ἔχει ζωὴν αἰώνιον· ὁ δὲ ἀπειθῶν τῷ Υἱῷ, οὐκ ὄψεται ζωήν, ἀλλʼ ἡ ὀργὴ τοῦ ˚Θεοῦ μένει ἐπʼ αὐτόν.”
43004001 Ὡς οὖν ἔγνω ὁ ˚Κύριος ὅτι ἤκουσαν οἱ Φαρισαῖοι ὅτι ˚Ἰησοῦς πλείονας μαθητὰς ποιεῖ καὶ βαπτίζει ἢ Ἰωάννης
43004002 ( καίτοιγε ˚Ἰησοῦς αὐτὸς οὐκ ἐβάπτιζεν, ἀλλʼ οἱ μαθηταὶ αὐτοῦ ),
43004003 ἀφῆκεν τὴν Ἰουδαίαν καὶ ἀπῆλθεν πάλιν εἰς τὴν Γαλιλαίαν.
43004004 Ἔδει δὲ αὐτὸν διέρχεσθαι διὰ τῆς Σαμαρείας.
43004005 Ἔρχεται οὖν εἰς πόλιν τῆς Σαμαρείας λεγομένην Συχὰρ, πλησίον τοῦ χωρίου ὃ ἔδωκεν Ἰακὼβ τῷ Ἰωσὴφ, τῷ υἱῷ αὐτοῦ·
43004006 ἦν δὲ ἐκεῖ πηγὴ τοῦ Ἰακώβ. Ὁ οὖν ˚Ἰησοῦς κεκοπιακὼς ἐκ τῆς ὁδοιπορίας, ἐκαθέζετο οὕτως ἐπὶ τῇ πηγῇ. Ὥρα ἦν ὡς ἕκτη.
43004007 Ἔρχεται γυνὴ ἐκ τῆς Σαμαρείας ἀντλῆσαι ὕδωρ. Λέγει αὐτῇ ὁ ˚Ἰησοῦς, “Δός μοι πεῖν”·
43004008 οἱ γὰρ μαθηταὶ αὐτοῦ ἀπεληλύθεισαν εἰς τὴν πόλιν, ἵνα τροφὰς ἀγοράσωσιν.
43004009 Λέγει οὖν αὐτῷ ἡ γυνὴ ἡ Σαμαρῖτις, “Πῶς σὺ Ἰουδαῖος ὢν, παρʼ ἐμοῦ πεῖν αἰτεῖς γυναικὸς Σαμαρίτιδος οὔσης;” Οὐ γὰρ συγχρῶνται Ἰουδαῖοι Σαμαρίταις.
43004010 Ἀπεκρίθη ˚Ἰησοῦς καὶ εἶπεν αὐτῇ, “Εἰ ᾔδεις τὴν δωρεὰν τοῦ ˚Θεοῦ, καὶ τίς ἐστιν ὁ λέγων σοι, ‘Δός μοι πεῖν’, σὺ ἂν ᾔτησας αὐτὸν, καὶ ἔδωκεν ἄν σοι ὕδωρ ζῶν.”
43004011 Λέγει αὐτῷ ἡ γυνή, “Κύριε, οὔτε ἄντλημα ἔχεις καὶ τὸ φρέαρ ἐστὶν βαθύ· πόθεν οὖν ἔχεις τὸ ὕδωρ τὸ ζῶν;
43004012 Μὴ σὺ μείζων εἶ τοῦ πατρὸς ἡμῶν Ἰακώβ, ὃς ἔδωκεν ἡμῖν τὸ φρέαρ, καὶ αὐτὸς ἐξ αὐτοῦ ἔπιεν, καὶ οἱ υἱοὶ αὐτοῦ, καὶ τὰ θρέμματα αὐτοῦ;”
43004013 Ἀπεκρίθη ˚Ἰησοῦς καὶ εἶπεν αὐτῇ, “Πᾶς ὁ πίνων ἐκ τοῦ ὕδατος τούτου, διψήσει πάλιν·
43004014 ὃς δʼ ἂν πίῃ ἐκ τοῦ ὕδατος οὗ ἐγὼ δώσω αὐτῷ, οὐ μὴ διψήσει, εἰς τὸν αἰῶνα, ἀλλὰ τὸ ὕδωρ ὃ δώσω αὐτῷ γενήσεται ἐν αὐτῷ πηγὴ ὕδατος, ἁλλομένου εἰς ζωὴν αἰώνιον.”
43004015 Λέγει πρὸς αὐτὸν ἡ γυνή, “Κύριε, δός μοι τοῦτο τὸ ὕδωρ, ἵνα μὴ διψῶ, μηδὲ διέρχομαι ἐνθάδε ἀντλεῖν.”
43004016 Λέγει αὐτῇ, “Ὕπαγε, φώνησον τὸν ἄνδρα σου καὶ ἐλθὲ ἐνθάδε.”
43004017 Ἀπεκρίθη ἡ γυνὴ καὶ εἶπεν αὐτῷ, “Οὐκ ἔχω ἄνδρα.” Λέγει αὐτῇ ὁ ˚Ἰησοῦς, “Καλῶς εἶπας, ὅτι ‘Ἄνδρα οὐκ ἔχω’·
43004018 πέντε γὰρ ἄνδρας ἔσχες, καὶ νῦν ὃν ἔχεις, οὐκ ἔστιν σου ἀνήρ· τοῦτο ἀληθὲς εἴρηκας.”
43004019 Λέγει αὐτῷ ἡ γυνή, “Κύριε, θεωρῶ ὅτι προφήτης εἶ σύ.
43004020 Οἱ πατέρες ἡμῶν ἐν τῷ ὄρει τούτῳ προσεκύνησαν, καὶ ὑμεῖς λέγετε ὅτι ἐν Ἱεροσολύμοις ἐστὶν ὁ τόπος ὅπου προσκυνεῖν δεῖ.”
43004021 Λέγει αὐτῇ ὁ ˚Ἰησοῦς, “Πίστευέ μοι, γύναι, ὅτι ἔρχεται ὥρα, ὅτε οὔτε ἐν τῷ ὄρει τούτῳ, οὔτε ἐν Ἱεροσολύμοις, προσκυνήσετε τῷ Πατρί.
43004022 Ὑμεῖς προσκυνεῖτε ὃ οὐκ οἴδατε, ἡμεῖς προσκυνοῦμεν ὃ οἴδαμεν, ὅτι ἡ σωτηρία ἐκ τῶν Ἰουδαίων ἐστίν.
43004023 Ἀλλὰ ἔρχεται ὥρα καὶ νῦν ἐστιν, ὅτε οἱ ἀληθινοὶ προσκυνηταὶ προσκυνήσουσιν τῷ Πατρὶ ἐν πνεύματι καὶ ἀληθείᾳ, καὶ γὰρ ὁ Πατὴρ τοιούτους ζητεῖ, τοὺς προσκυνοῦντας αὐτόν.
43004024 ˚Πνεῦμα ὁ ˚Θεός, καὶ τοὺς προσκυνοῦντας αὐτὸν ἐν πνεύματι καὶ ἀληθείᾳ δεῖ προσκυνεῖν.”
43004025 Λέγει αὐτῷ ἡ γυνή, “Οἶδα ὅτι Μεσσίας ἔρχεται, ὁ λεγόμενος ‘˚Χριστός’· ὅταν ἔλθῃ ἐκεῖνος, ἀναγγελεῖ ἡμῖν ἅπαντα.”
43004026 Λέγει αὐτῇ ὁ ˚Ἰησοῦς, “Ἐγώ εἰμι, ὁ λαλῶν σοι.”
43004027 Καὶ ἐπὶ τούτῳ ἦλθον οἱ μαθηταὶ αὐτοῦ, καὶ ἐθαύμαζον ὅτι μετὰ γυναικὸς ἐλάλει· οὐδεὶς μέντοι εἶπεν, “Τί ζητεῖς;” Ἢ, “Τί λαλεῖς μετʼ αὐτῆς;”
43004028 Ἀφῆκεν οὖν τὴν ὑδρίαν αὐτῆς ἡ γυνὴ, καὶ ἀπῆλθεν εἰς τὴν πόλιν, καὶ λέγει τοῖς ἀνθρώποις,
43004029 “Δεῦτε, ἴδετε ἄνθρωπον ὃς εἶπέν μοι πάντα ὅσα ἐποίησα· μήτι οὗτός ἐστιν ὁ ˚Χριστός;”
43004030 Ἐξῆλθον ἐκ τῆς πόλεως, καὶ ἤρχοντο πρὸς αὐτόν.
43004031 Ἐν τῷ μεταξὺ ἠρώτων αὐτὸν οἱ μαθηταὶ λέγοντες, “Ῥαββί, φάγε.”
43004032 Ὁ δὲ εἶπεν αὐτοῖς, “Ἐγὼ βρῶσιν ἔχω φαγεῖν, ἣν ὑμεῖς οὐκ οἴδατε.”
43004033 Ἔλεγον οὖν οἱ μαθηταὶ πρὸς ἀλλήλους, “Μή τις ἤνεγκεν αὐτῷ φαγεῖν;”
43004034 Λέγει αὐτοῖς ὁ ˚Ἰησοῦς, “Ἐμὸν βρῶμά ἐστιν ἵνα ποιήσω τὸ θέλημα τοῦ πέμψαντός με, καὶ τελειώσω αὐτοῦ τὸ ἔργον.
43004035 Οὐχ ὑμεῖς λέγετε, ὅτι ‘Ἔτι τετράμηνός ἐστιν καὶ ὁ θερισμὸς ἔρχεται’; Ἰδοὺ, λέγω ὑμῖν, ἐπάρατε τοὺς ὀφθαλμοὺς ὑμῶν καὶ θεάσασθε τὰς χώρας, ὅτι λευκαί εἰσιν πρὸς θερισμόν ἤδη.
43004036 Ὁ θερίζων μισθὸν, λαμβάνει καὶ συνάγει καρπὸν εἰς ζωὴν αἰώνιον, ἵνα ὁ σπείρων ὁμοῦ χαίρῃ καὶ ὁ θερίζων.
43004037 Ἐν γὰρ τούτῳ ὁ λόγος ἐστὶν ὁ ἀληθινὸς: ὅτι ‘Ἄλλος ἐστὶν ὁ σπείρων, καὶ ἄλλος ὁ θερίζων.’
43004038 Ἐγὼ ἀπέστειλα ὑμᾶς θερίζειν ὃ οὐχ ὑμεῖς κεκοπιάκατε· ἄλλοι κεκοπιάκασιν, καὶ ὑμεῖς εἰς τὸν κόπον αὐτῶν εἰσεληλύθατε.”
43004039 Ἐκ δὲ τῆς πόλεως ἐκείνης, πολλοὶ ἐπίστευσαν εἰς αὐτὸν τῶν Σαμαριτῶν, διὰ τὸν λόγον τῆς γυναικὸς μαρτυρούσης, ὅτι “Εἶπέν μοι πάντα ἃ ἐποίησα.”
43004040 Ὡς οὖν ἦλθον πρὸς αὐτὸν οἱ Σαμαρῖται, ἠρώτων αὐτὸν μεῖναι παρʼ αὐτοῖς, καὶ ἔμεινεν ἐκεῖ δύο ἡμέρας.
43004041 Καὶ πολλῷ πλείους ἐπίστευσαν, διὰ τὸν λόγον αὐτοῦ,
43004042 τῇ τε γυναικὶ ἔλεγον, ὅτι “Οὐκέτι διὰ τὴν σὴν λαλιὰν πιστεύομεν· αὐτοὶ γὰρ ἀκηκόαμεν καὶ οἴδαμεν ὅτι οὗτός ἐστιν ἀληθῶς ὁ Σωτὴρ τοῦ κόσμου.”
43004043 Μετὰ δὲ τὰς δύο ἡμέρας, ἐξῆλθεν ἐκεῖθεν εἰς τὴν Γαλιλαίαν.
43004044 Αὐτὸς γὰρ ˚Ἰησοῦς ἐμαρτύρησεν ὅτι προφήτης ἐν τῇ ἰδίᾳ πατρίδι, τιμὴν οὐκ ἔχει.
43004045 Ὅτε οὖν ἦλθεν εἰς τὴν Γαλιλαίαν, ἐδέξαντο αὐτὸν οἱ Γαλιλαῖοι, πάντα ἑωρακότες ὅσα ἐποίησεν ἐν Ἱεροσολύμοις ἐν τῇ ἑορτῇ, καὶ αὐτοὶ γὰρ ἦλθον εἰς τὴν ἑορτήν.
43004046 Ἦλθεν οὖν πάλιν εἰς τὴν Κανὰ τῆς Γαλιλαίας, ὅπου ἐποίησεν τὸ ὕδωρ οἶνον. Καὶ ἦν τις βασιλικὸς, οὗ ὁ υἱὸς ἠσθένει ἐν Καφαρναούμ.
43004047 Οὗτος ἀκούσας ὅτι ˚Ἰησοῦς ἥκει ἐκ τῆς Ἰουδαίας εἰς τὴν Γαλιλαίαν, ἀπῆλθεν πρὸς αὐτὸν, καὶ ἠρώτα ἵνα καταβῇ, καὶ ἰάσηται αὐτοῦ τὸν υἱόν, ἤμελλεν γὰρ ἀποθνῄσκειν.
43004048 Εἶπεν οὖν ὁ ˚Ἰησοῦς πρὸς αὐτόν, “Ἐὰν μὴ σημεῖα καὶ τέρατα ἴδητε, οὐ μὴ πιστεύσητε.”
43004049 Λέγει πρὸς αὐτὸν ὁ βασιλικός, “Κύριε, κατάβηθι πρὶν ἀποθανεῖν τὸ παιδίον μου.”
43004050 Λέγει αὐτῷ ὁ ˚Ἰησοῦς, “Πορεύου, ὁ υἱός σου ζῇ.” Ἐπίστευσεν ὁ ἄνθρωπος τῷ λόγῳ ὃν εἶπεν αὐτῷ ὁ ˚Ἰησοῦς, καὶ ἐπορεύετο.
43004051 Ἤδη δὲ αὐτοῦ καταβαίνοντος, οἱ δοῦλοι αὐτοῦ ὑπήντησαν αὐτῷ καὶ ἀπήγγειλαν λέγοντες ὅτι ὁ παῖς αὐτοῦ ζῇ.
43004052 Ἐπύθετο οὖν τὴν ὥραν παρʼ αὐτῶν ἐν ᾗ κομψότερον ἔσχεν. Εἶπον οὖν αὐτῷ, ὅτι “Ἐχθὲς ὥραν ἑβδόμην, ἀφῆκεν αὐτὸν ὁ πυρετός.”
43004053 Ἔγνω οὖν ὁ πατὴρ ὅτι ἐκείνῃ τῇ ὥρᾳ ἐν ᾗ εἶπεν αὐτῷ ὁ ˚Ἰησοῦς, “Ὁ υἱός σου ζῇ”, καὶ ἐπίστευσεν αὐτὸς καὶ ἡ οἰκία αὐτοῦ ὅλη.
43004054 Τοῦτο δὲ πάλιν δεύτερον σημεῖον ἐποίησεν ὁ ˚Ἰησοῦς, ἐλθὼν ἐκ τῆς Ἰουδαίας εἰς τὴν Γαλιλαίαν.
43005001 Μετὰ ταῦτα ἦν ἑορτὴ τῶν Ἰουδαίων, καὶ ἀνέβη ˚Ἰησοῦς εἰς Ἱεροσόλυμα.
43005002 Ἔστιν δὲ ἐν τοῖς Ἱεροσολύμοις ἐπὶ τῇ Προβατικῇ κολυμβήθρα, ἡ ἐπιλεγομένη Ἑβραϊστὶ, Βηθσαϊδά, πέντε στοὰς ἔχουσα.
43005003 Ἐν ταύταις κατέκειτο πλῆθος τῶν ἀσθενούντων, τυφλῶν, χωλῶν, ξηρῶν.
43005005 Ἦν δέ τις ἄνθρωπος ἐκεῖ, τριάκοντα ὀκτὼ ἔτη ἔχων ἐν τῇ ἀσθενείᾳ αὐτοῦ.
43005006 Τοῦτον ἰδὼν ὁ ˚Ἰησοῦς κατακείμενον, καὶ γνοὺς ὅτι πολὺν ἤδη χρόνον ἔχει, λέγει αὐτῷ, “Θέλεις ὑγιὴς γενέσθαι;”
43005007 Ἀπεκρίθη αὐτῷ ὁ ἀσθενῶν, “Κύριε, ἄνθρωπον οὐκ ἔχω, ἵνα ὅταν ταραχθῇ τὸ ὕδωρ, βάλῃ με εἰς τὴν κολυμβήθραν· ἐν ᾧ δὲ ἔρχομαι ἐγὼ, ἄλλος πρὸ ἐμοῦ καταβαίνει.”
43005008 Λέγει αὐτῷ ὁ ˚Ἰησοῦς, “Ἔγειρε, ἆρον τὸν κράβαττόν σου, καὶ περιπάτει.”
43005009 Καὶ εὐθέως ἐγένετο ὑγιὴς ὁ ἄνθρωπος, καὶ ἦρεν τὸν κράβαττον αὐτοῦ, καὶ περιεπάτει. Ἦν δὲ Σάββατον ἐν ἐκείνῃ τῇ ἡμέρᾳ.
43005010 Ἔλεγον οὖν οἱ Ἰουδαῖοι τῷ τεθεραπευμένῳ, “Σάββατόν ἐστιν, καὶ οὐκ ἔξεστίν σοι ἆραι τὸν κράβαττον σου.”
43005011 ὃς δὲ ἀπεκρίθη αὐτοῖς, “Ὁ ποιήσας με ὑγιῆ, ἐκεῖνός μοι εἶπεν, ‘Ἆρον τὸν κράβαττόν σου καὶ περιπάτει.’”
43005012 Ἠρώτησαν αὐτόν, “Τίς ἐστιν ὁ ἄνθρωπος ὁ εἰπών σοι, ‘Ἆρον καὶ περιπάτει’;”
43005013 Ὁ δὲ ἰαθεὶς οὐκ ᾔδει τίς ἐστιν, ὁ γὰρ ˚Ἰησοῦς ἐξένευσεν, ὄχλου ὄντος ἐν τῷ τόπῳ.
43005014 Μετὰ ταῦτα εὑρίσκει αὐτὸν ὁ ˚Ἰησοῦς ἐν τῷ ἱερῷ, καὶ εἶπεν αὐτῷ, “Ἴδε, ὑγιὴς γέγονας· μηκέτι ἁμάρτανε, ἵνα μὴ χεῖρόν σοί τι γένηται.”
43005015 Ἀπῆλθεν ὁ ἄνθρωπος, καὶ ἀνήγγειλεν τοῖς Ἰουδαίοις ὅτι ˚Ἰησοῦς ἐστιν ὁ ποιήσας αὐτὸν ὑγιῆ.
43005016 Καὶ διὰ τοῦτο, ἐδίωκον οἱ Ἰουδαῖοι τὸν ˚Ἰησοῦν, ὅτι ταῦτα ἐποίει ἐν Σαββάτῳ.
43005017 Ὁ δὲ ἀπεκρίνατο αὐτοῖς, “Ὁ Πατήρ μου ἕως ἄρτι ἐργάζεται, κἀγὼ ἐργάζομαι.”
43005018 Διὰ τοῦτο οὖν, μᾶλλον ἐζήτουν αὐτὸν οἱ Ἰουδαῖοι ἀποκτεῖναι, ὅτι οὐ μόνον ἔλυεν τὸ Σάββατον, ἀλλὰ καὶ Πατέρα ἴδιον ἔλεγεν τὸν ˚Θεόν, ἴσον ἑαυτὸν ποιῶν τῷ ˚Θεῷ.
43005019 Ἀπεκρίνατο οὖν ὁ ˚Ἰησοῦς καὶ ἔλεγεν αὐτοῖς, “Ἀμὴν, ἀμὴν, λέγω ὑμῖν, οὐ δύναται ὁ Υἱὸς ποιεῖν ἀφʼ ἑαυτοῦ οὐδὲν, ἐὰν μή τι βλέπῃ τὸν Πατέρα ποιοῦντα· ἃ γὰρ ἂν ἐκεῖνος ποιῇ, ταῦτα καὶ ὁ Υἱὸς ὁμοίως ποιεῖ.
43005020 Ὁ γὰρ Πατὴρ φιλεῖ τὸν Υἱὸν, καὶ πάντα δείκνυσιν αὐτῷ ἃ αὐτὸς ποιεῖ, καὶ μείζονα τούτων δείξει αὐτῷ ἔργα, ἵνα ὑμεῖς θαυμάζητε.
43005021 Ὥσπερ γὰρ ὁ Πατὴρ ἐγείρει τοὺς νεκροὺς καὶ ζῳοποιεῖ, οὕτως καὶ ὁ Υἱὸς, οὓς θέλει ζῳοποιεῖ.
43005022 Οὐδὲ γὰρ ὁ Πατὴρ κρίνει οὐδένα, ἀλλὰ τὴν κρίσιν πᾶσαν δέδωκεν τῷ Υἱῷ,
43005023 ἵνα πάντες τιμῶσι τὸν Υἱὸν, καθὼς τιμῶσι τὸν Πατέρα. Ὁ μὴ τιμῶν τὸν Υἱὸν, οὐ τιμᾷ τὸν Πατέρα, τὸν πέμψαντα αὐτόν.
43005024 Ἀμὴν, ἀμὴν, λέγω ὑμῖν ὅτι ὁ τὸν λόγον μου ἀκούων, καὶ πιστεύων τῷ πέμψαντί με, ἔχει ζωὴν αἰώνιον, καὶ εἰς κρίσιν οὐκ ἔρχεται, ἀλλὰ μεταβέβηκεν ἐκ τοῦ θανάτου εἰς τὴν ζωήν.
43005025 Ἀμὴν, ἀμὴν, λέγω ὑμῖν ὅτι ἔρχεται ὥρα καὶ νῦν ἐστιν, ὅτε οἱ νεκροὶ ἀκούσουσιν τῆς φωνῆς τοῦ Υἱοῦ τοῦ ˚Θεοῦ, καὶ οἱ ἀκούσαντες ζήσουσιν.
43005026 Ὥσπερ γὰρ ὁ Πατὴρ ἔχει ζωὴν ἐν ἑαυτῷ, οὕτως καὶ τῷ Υἱῷ ἔδωκεν ζωὴν ἔχειν ἐν ἑαυτῷ·
43005027 καὶ ἐξουσίαν ἔδωκεν αὐτῷ κρίσιν ποιεῖν, ὅτι Υἱὸς Ἀνθρώπου ἐστίν.
43005028 Μὴ θαυμάζετε τοῦτο, ὅτι ἔρχεται ὥρα ἐν ᾗ πάντες οἱ ἐν τοῖς μνημείοις ἀκούσουσιν τῆς φωνῆς αὐτοῦ,
43005029 καὶ ἐκπορεύσονται, οἱ τὰ ἀγαθὰ ποιήσαντες, εἰς ἀνάστασιν ζωῆς, οἱ δὲ τὰ φαῦλα πράξαντες, εἰς ἀνάστασιν κρίσεως.
43005030 Οὐ δύναμαι ἐγὼ ποιεῖν ἀπʼ ἐμαυτοῦ οὐδέν· καθὼς ἀκούω, κρίνω, καὶ ἡ κρίσις ἡ ἐμὴ δικαία ἐστίν, ὅτι οὐ ζητῶ τὸ θέλημα τὸ ἐμὸν, ἀλλὰ τὸ θέλημα τοῦ πέμψαντός με.
43005031 Ἐὰν ἐγὼ μαρτυρῶ περὶ ἐμαυτοῦ, ἡ μαρτυρία μου οὐκ ἔστιν ἀληθής.
43005032 Ἄλλος ἐστὶν ὁ μαρτυρῶν περὶ ἐμοῦ, καὶ οἶδα ὅτι ἀληθής ἐστιν ἡ μαρτυρία ἣν μαρτυρεῖ περὶ ἐμοῦ.
43005033 Ὑμεῖς ἀπεστάλκατε πρὸς Ἰωάννην, καὶ μεμαρτύρηκεν τῇ ἀληθείᾳ.
43005034 Ἐγὼ δὲ οὐ παρὰ ἀνθρώπου τὴν μαρτυρίαν λαμβάνω, ἀλλὰ ταῦτα λέγω, ἵνα ὑμεῖς σωθῆτε.
43005035 Ἐκεῖνος ἦν ὁ λύχνος ὁ καιόμενος καὶ φαίνων, ὑμεῖς δὲ ἠθελήσατε ἀγαλλιαθῆναι πρὸς ὥραν ἐν τῷ φωτὶ αὐτοῦ.
43005036 Ἐγὼ δὲ ἔχω τὴν μαρτυρίαν μείζων τοῦ Ἰωάννου, τὰ γὰρ ἔργα ἃ δέδωκέν μοι ὁ Πατὴρ, ἵνα τελειώσω αὐτά, αὐτὰ τὰ ἔργα ἃ ποιῶ, μαρτυρεῖ περὶ ἐμοῦ ὅτι ὁ Πατήρ με ἀπέσταλκεν.
43005037 Καὶ ὁ πέμψας με Πατὴρ, ἐκεῖνος μεμαρτύρηκεν περὶ ἐμοῦ. Οὔτε φωνὴν αὐτοῦ πώποτε ἀκηκόατε, οὔτε εἶδος αὐτοῦ ἑωράκατε.
43005038 Καὶ τὸν λόγον αὐτοῦ οὐκ ἔχετε ἐν ὑμῖν μένοντα, ὅτι ὃν ἀπέστειλεν ἐκεῖνος, τούτῳ ὑμεῖς οὐ πιστεύετε.
43005039 Ἐραυνᾶτε τὰς Γραφάς, ὅτι ὑμεῖς δοκεῖτε ἐν αὐταῖς ζωὴν αἰώνιον ἔχειν, καὶ ἐκεῖναί εἰσιν αἱ μαρτυροῦσαι περὶ ἐμοῦ·
43005040 καὶ οὐ θέλετε ἐλθεῖν πρός με, ἵνα ζωὴν ἔχητε.
43005041 Δόξαν παρὰ ἀνθρώπων οὐ λαμβάνω,
43005042 ἀλλὰ ἔγνωκα ὑμᾶς ὅτι τὴν ἀγάπην τοῦ ˚Θεοῦ οὐκ ἔχετε ἐν ἑαυτοῖς.
43005043 Ἐγὼ ἐλήλυθα ἐν τῷ ὀνόματι τοῦ Πατρός μου, καὶ οὐ λαμβάνετέ με· ἐὰν ἄλλος ἔλθῃ ἐν τῷ ὀνόματι τῷ ἰδίῳ, ἐκεῖνον λήμψεσθε.
43005044 Πῶς δύνασθε ὑμεῖς πιστεῦσαι, δόξαν παρὰ ἀλλήλων λαμβάνοντες, καὶ τὴν δόξαν τὴν παρὰ τοῦ μόνου ˚Θεοῦ, οὐ ζητεῖτε;
43005045 Μὴ δοκεῖτε ὅτι ἐγὼ κατηγορήσω ὑμῶν πρὸς τὸν Πατέρα. Ἔστιν ὁ κατηγορῶν ὑμῶν Μωϋσῆς, εἰς ὃν ὑμεῖς ἠλπίκατε.
43005046 Εἰ γὰρ ἐπιστεύετε Μωϋσεῖ, ἐπιστεύετε ἂν ἐμοί, περὶ γὰρ ἐμοῦ ἐκεῖνος ἔγραψεν.
43005047 Εἰ δὲ τοῖς ἐκείνου γράμμασιν οὐ πιστεύετε, πῶς τοῖς ἐμοῖς ῥήμασιν πιστεύσετε;”
43006001 Μετὰ ταῦτα, ἀπῆλθεν ὁ ˚Ἰησοῦς πέραν τῆς θαλάσσης τῆς Γαλιλαίας τῆς Τιβεριάδος.
43006002 Ἠκολούθει δὲ αὐτῷ ὄχλος πολύς, ὅτι ἐθεώρουν τὰ σημεῖα ἃ ἐποίει ἐπὶ τῶν ἀσθενούντων.
43006003 Ἀνῆλθεν δὲ εἰς τὸ ὄρος ˚Ἰησοῦς, καὶ ἐκεῖ ἐκάθητο μετὰ τῶν μαθητῶν αὐτοῦ.
43006004 Ἦν δὲ ἐγγὺς τὸ Πάσχα, ἡ ἑορτὴ τῶν Ἰουδαίων.
43006005 Ἐπάρας οὖν τοὺς ὀφθαλμοὺς ὁ ˚Ἰησοῦς, καὶ θεασάμενος ὅτι πολὺς ὄχλος ἔρχεται πρὸς αὐτὸν, λέγει πρὸς Φίλιππον, “Πόθεν ἀγοράσωμεν ἄρτους, ἵνα φάγωσιν οὗτοι;”
43006006 Τοῦτο δὲ ἔλεγεν πειράζων αὐτόν, αὐτὸς γὰρ ᾔδει τί ἔμελλεν ποιεῖν.
43006007 Ἀπεκρίθη αὐτῷ Φίλιππος, “Διακοσίων δηναρίων ἄρτοι οὐκ ἀρκοῦσιν αὐτοῖς, ἵνα ἕκαστος βραχύ λάβῃ.”
43006008 Λέγει αὐτῷ εἷς ἐκ τῶν μαθητῶν αὐτοῦ, Ἀνδρέας ὁ ἀδελφὸς Σίμωνος Πέτρου,
43006009 “Ἔστιν παιδάριον ὧδε, ὃς ἔχει πέντε ἄρτους κριθίνους καὶ δύο ὀψάρια, ἀλλὰ ταῦτα τί ἐστιν εἰς τοσούτους;”
43006010 Εἶπεν ὁ ˚Ἰησοῦς, “Ποιήσατε τοὺς ἀνθρώπους ἀναπεσεῖν.” Ἦν δὲ χόρτος πολὺς ἐν τῷ τόπῳ. Ἀνέπεσαν οὖν οἱ ἄνδρες, τὸν ἀριθμὸν ὡς πεντακισχίλιοι.
43006011 Ἔλαβεν οὖν τοὺς ἄρτους ὁ ˚Ἰησοῦς, καὶ εὐχαριστήσας, διέδωκεν τοῖς ἀνακειμένοις, ὁμοίως καὶ ἐκ τῶν ὀψαρίων, ὅσον ἤθελον.
43006012 Ὡς δὲ ἐνεπλήσθησαν, λέγει τοῖς μαθηταῖς αὐτοῦ, “Συναγάγετε τὰ περισσεύσαντα κλάσματα, ἵνα μή τι ἀπόληται.”
43006013 Συνήγαγον οὖν καὶ ἐγέμισαν δώδεκα κοφίνους κλασμάτων ἐκ τῶν πέντε ἄρτων τῶν κριθίνων, ἃ ἐπερίσσευσαν τοῖς βεβρωκόσιν.
43006014 Οἱ οὖν ἄνθρωποι ἰδόντες ἃ ἐποίησεν σημεῖα ἔλεγον, ὅτι “Οὗτός ἐστιν ἀληθῶς ὁ προφήτης ὁ ἐρχόμενος εἰς τὸν κόσμον.”
43006015 ˚Ἰησοῦς οὖν γνοὺς ὅτι μέλλουσιν ἔρχεσθαι καὶ ἁρπάζειν αὐτὸν, ἵνα ποιήσωσιν βασιλέα, ἀνεχώρησεν πάλιν εἰς τὸ ὄρος αὐτὸς μόνος.
43006016 Ὡς δὲ ὀψία ἐγένετο, κατέβησαν οἱ μαθηταὶ αὐτοῦ ἐπὶ τὴν θάλασσαν,
43006017 καὶ ἐμβάντες εἰς πλοῖον, ἤρχοντο πέραν τῆς θαλάσσης εἰς Καφαρναούμ. Καὶ σκοτία ἤδη ἐγεγόνει, καὶ οὔπω πρὸς αὐτοὺς ἐληλύθει ὁ ˚Ἰησοῦς.
43006018 Ἥ τε θάλασσα ἀνέμου μεγάλου πνέοντος διεγείρετο.
43006019 Ἐληλακότες οὖν ὡς σταδίους εἴκοσι πέντε ἢ τριάκοντα, θεωροῦσιν τὸν ˚Ἰησοῦν περιπατοῦντα ἐπὶ τῆς θαλάσσης, καὶ ἐγγὺς τοῦ πλοίου γινόμενον, καὶ ἐφοβήθησαν.
43006020 Ὁ δὲ λέγει αὐτοῖς, “Ἐγώ εἰμι, μὴ φοβεῖσθε.”
43006021 Ἤθελον οὖν λαβεῖν αὐτὸν εἰς τὸ πλοῖον, καὶ εὐθέως ἐγένετο τὸ πλοῖον ἐπὶ τῆς γῆς εἰς ἣν ὑπῆγον.
43006022 Τῇ ἐπαύριον ὁ ὄχλος ὁ ἑστηκὼς πέραν τῆς θαλάσσης, εἶδον ὅτι πλοιάριον ἄλλο οὐκ ἦν ἐκεῖ εἰ μὴ ἕν, καὶ ὅτι οὐ συνεισῆλθεν τοῖς μαθηταῖς αὐτοῦ ὁ ˚Ἰησοῦς εἰς τὸ πλοῖον, ἀλλὰ μόνοι οἱ μαθηταὶ αὐτοῦ ἀπῆλθον·
43006023 ἄλλα ἦλθεν πλοῖα ἐκ Τιβεριάδος, ἐγγὺς τοῦ τόπου ὅπου ἔφαγον τὸν ἄρτον, εὐχαριστήσαντος τοῦ ˚Κυρίου.
43006024 Ὅτε οὖν εἶδεν ὁ ὄχλος ὅτι ˚Ἰησοῦς οὐκ ἔστιν ἐκεῖ, οὐδὲ οἱ μαθηταὶ αὐτοῦ, ἐνέβησαν αὐτοὶ εἰς τὰ πλοιάρια, καὶ ἦλθον εἰς Καφαρναοὺμ ζητοῦντες τὸν ˚Ἰησοῦν.
43006025 Καὶ εὑρόντες αὐτὸν πέραν τῆς θαλάσσης, εἶπον αὐτῷ, “Ῥαββί, πότε ὧδε γέγονας;”
43006026 Ἀπεκρίθη αὐτοῖς ὁ ˚Ἰησοῦς καὶ εἶπεν, “Ἀμὴν, ἀμὴν, λέγω ὑμῖν, ζητεῖτέ με, οὐχ ὅτι εἴδετε σημεῖα, ἀλλʼ ὅτι ἐφάγετε ἐκ τῶν ἄρτων καὶ ἐχορτάσθητε.
43006027 Ἐργάζεσθε μὴ τὴν βρῶσιν τὴν ἀπολλυμένην, ἀλλὰ τὴν βρῶσιν τὴν μένουσαν εἰς ζωὴν αἰώνιον, ἣν ὁ Υἱὸς τοῦ Ἀνθρώπου ὑμῖν δώσει, τοῦτον γὰρ ὁ Πατὴρ ἐσφράγισεν ὁ ˚Θεός.”
43006028 Εἶπον οὖν πρὸς αὐτόν, “Τί ποιῶμεν, ἵνα ἐργαζώμεθα τὰ ἔργα τοῦ ˚Θεοῦ;”
43006029 Ἀπεκρίθη ὁ ˚Ἰησοῦς καὶ εἶπεν αὐτοῖς, “Τοῦτό ἐστιν τὸ ἔργον τοῦ ˚Θεοῦ, ἵνα πιστεύητε εἰς ὃν ἀπέστειλεν ἐκεῖνος.”
43006030 Εἶπον οὖν αὐτῷ, “Τί οὖν ποιεῖς σὺ σημεῖον, ἵνα ἴδωμεν καὶ πιστεύσωμέν σοι; Τί ἐργάζῃ;
43006031 Οἱ πατέρες ἡμῶν τὸ μάννα ἔφαγον ἐν τῇ ἐρήμῳ, καθώς ἐστιν γεγραμμένον, ‘Ἄρτον ἐκ τοῦ οὐρανοῦ ἔδωκεν αὐτοῖς φαγεῖν.’”
43006032 Εἶπεν οὖν αὐτοῖς ὁ ˚Ἰησοῦς, “Ἀμὴν, ἀμὴν, λέγω ὑμῖν, οὐ Μωϋσῆς δέδωκεν ὑμῖν τὸν ἄρτον ἐκ τοῦ οὐρανοῦ, ἀλλʼ ὁ Πατήρ μου δίδωσιν ὑμῖν τὸν ἄρτον ἐκ τοῦ οὐρανοῦ τὸν ἀληθινόν.
43006033 Ὁ γὰρ ἄρτος τοῦ ˚Θεοῦ ἐστιν ὁ καταβαίνων ἐκ τοῦ οὐρανοῦ, καὶ ζωὴν διδοὺς τῷ κόσμῳ.”
43006034 Εἶπον οὖν πρὸς αὐτόν, “Κύριε, πάντοτε δὸς ἡμῖν τὸν ἄρτον τοῦτον.”
43006035 Εἶπεν αὐτοῖς ὁ ˚Ἰησοῦς, “Ἐγώ εἰμι ὁ ἄρτος τῆς ζωῆς· ὁ ἐρχόμενος πρὸς ἐμὲ, οὐ μὴ πεινάσῃ, καὶ ὁ πιστεύων εἰς ἐμὲ, οὐ μὴ διψήσει πώποτε.
43006036 Ἀλλʼ εἶπον ὑμῖν ὅτι καὶ ἑωράκατέ με καὶ οὐ πιστεύετε.
43006037 Πᾶν ὃ δίδωσίν μοι ὁ Πατὴρ, πρὸς ἐμὲ ἥξει, καὶ τὸν ἐρχόμενον πρός ἐμὲ, οὐ μὴ ἐκβάλω ἔξω.
43006038 Ὅτι καταβέβηκα ἀπὸ τοῦ οὐρανοῦ, οὐχ ἵνα ποιῶ τὸ θέλημα τὸ ἐμὸν, ἀλλὰ τὸ θέλημα τοῦ πέμψαντός με.
43006039 Τοῦτο δέ ἐστιν τὸ θέλημα τοῦ πέμψαντός με, ἵνα πᾶν ὃ δέδωκέν μοι, μὴ ἀπολέσω ἐξ αὐτοῦ, ἀλλὰ ἀναστήσω αὐτὸ τῇ ἐσχάτῃ ἡμέρᾳ.
43006040 Τοῦτο γάρ ἐστιν τὸ θέλημα τοῦ Πατρός μου, ἵνα πᾶς ὁ θεωρῶν τὸν Υἱὸν καὶ πιστεύων εἰς αὐτὸν, ἔχῃ ζωὴν αἰώνιον, καὶ ἀναστήσω αὐτὸν ἐγὼ τῇ ἐσχάτῃ ἡμέρᾳ.”
43006041 Ἐγόγγυζον οὖν οἱ Ἰουδαῖοι περὶ αὐτοῦ, ὅτι εἶπεν, “Ἐγώ εἰμι ὁ ἄρτος ὁ καταβὰς ἐκ τοῦ οὐρανοῦ.”
43006042 Καὶ ἔλεγον, “Οὐχ οὗτός ἐστιν ˚Ἰησοῦς ὁ υἱὸς Ἰωσήφ, οὗ ἡμεῖς οἴδαμεν τὸν πατέρα καὶ τὴν μητέρα; Πῶς νῦν λέγει, ὅτι ‘Ἐκ τοῦ οὐρανοῦ καταβέβηκα’;”
43006043 Ἀπεκρίθη ˚Ἰησοῦς καὶ εἶπεν αὐτοῖς, “Μὴ γογγύζετε μετʼ ἀλλήλων.
43006044 Οὐδεὶς δύναται ἐλθεῖν πρός με, ἐὰν μὴ ὁ Πατὴρ, ὁ πέμψας με, ἑλκύσῃ αὐτόν, κἀγὼ ἀναστήσω αὐτὸν τῇ ἐσχάτῃ ἡμέρᾳ.
43006045 Ἔστιν γεγραμμένον ἐν τοῖς προφήταις, ‘Καὶ ἔσονται πάντες διδακτοὶ ˚Θεοῦ.’ Πᾶς ὁ ἀκούσας παρὰ τοῦ Πατρὸς καὶ μαθὼν, ἔρχεται πρὸς με.
43006046 Οὐχ ὅτι τὸν Πατέρα ἑώρακέν τις, εἰ μὴ ὁ ὢν παρὰ τοῦ ˚Θεοῦ, οὗτος ἑώρακεν τὸν Πατέρα.
43006047 Ἀμὴν, ἀμὴν, λέγω ὑμῖν, ὁ πιστεύων ἔχει ζωὴν αἰώνιον.
43006048 Ἐγώ εἰμι ὁ ἄρτος τῆς ζωῆς.
43006049 Οἱ πατέρες ὑμῶν ἔφαγον ἐν τῇ ἐρήμῳ τὸ μάννα, καὶ ἀπέθανον.
43006050 Οὗτός ἐστιν ὁ ἄρτος ὁ ἐκ τοῦ οὐρανοῦ καταβαίνων, ἵνα τις ἐξ αὐτοῦ φάγῃ, καὶ μὴ ἀποθάνῃ.
43006051 Ἐγώ εἰμι ὁ ἄρτος ὁ ζῶν ὁ ἐκ τοῦ οὐρανοῦ καταβάς· ἐάν τις φάγῃ ἐκ τούτου τοῦ ἄρτου, ζήσεται εἰς τὸν αἰῶνα. Καὶ ὁ ἄρτος δὲ ὃν ἐγὼ δώσω, ἡ σάρξ μού ἐστιν ὑπὲρ τῆς τοῦ κόσμου ζωῆς.”
43006052 Ἐμάχοντο οὖν πρὸς ἀλλήλους οἱ Ἰουδαῖοι λέγοντες, “Πῶς δύναται οὗτος ἡμῖν δοῦναι τὴν σάρκα αὐτοῦ φαγεῖν;”
43006053 Εἶπεν οὖν αὐτοῖς ὁ ˚Ἰησοῦς, “Ἀμὴν, ἀμὴν, λέγω ὑμῖν, ἐὰν μὴ φάγητε τὴν σάρκα τοῦ Υἱοῦ τοῦ Ἀνθρώπου, καὶ πίητε αὐτοῦ τὸ αἷμα, οὐκ ἔχετε ζωὴν ἐν ἑαυτοῖς.
43006054 Ὁ τρώγων μου τὴν σάρκα, καὶ πίνων μου τὸ αἷμα, ἔχει ζωὴν αἰώνιον, κἀγὼ ἀναστήσω αὐτὸν τῇ ἐσχάτῃ ἡμέρᾳ.
43006055 Ἡ γὰρ σάρξ μου ἀληθής ἐστιν βρῶσις, καὶ τὸ αἷμά μου ἀληθής ἐστιν πόσις.
43006056 Ὁ τρώγων μου τὴν σάρκα, καὶ πίνων μου τὸ αἷμα, ἐν ἐμοὶ μένει, κἀγὼ ἐν αὐτῷ.
43006057 Καθὼς ἀπέστειλέν με ὁ ζῶν Πατὴρ, κἀγὼ ζῶ διὰ τὸν Πατέρα, καὶ ὁ τρώγων με, κἀκεῖνος ζήσει διʼ ἐμέ.
43006058 Οὗτός ἐστιν ὁ ἄρτος ὁ ἐξ οὐρανοῦ καταβάς, οὐ καθὼς ἔφαγον οἱ πατέρες καὶ ἀπέθανον· ὁ τρώγων τοῦτον τὸν ἄρτον ζήσει εἰς τὸν αἰῶνα.”
43006059 Ταῦτα εἶπεν ἐν συναγωγῇ, διδάσκων ἐν Καφαρναούμ.
43006060 Πολλοὶ οὖν ἀκούσαντες ἐκ τῶν μαθητῶν αὐτοῦ εἶπον, “Σκληρός ἐστιν ὁ λόγος οὗτος· τίς δύναται αὐτοῦ ἀκούειν;”
43006061 Εἰδὼς δὲ ὁ ˚Ἰησοῦς ἐν ἑαυτῷ ὅτι γογγύζουσιν περὶ τούτου οἱ μαθηταὶ αὐτοῦ, εἶπεν αὐτοῖς, “Τοῦτο ὑμᾶς σκανδαλίζει;
43006062 Ἐὰν οὖν θεωρῆτε τὸν Υἱὸν τοῦ Ἀνθρώπου, ἀναβαίνοντα ὅπου ἦν τὸ πρότερον;
43006063 Τὸ ˚Πνεῦμά ἐστιν τὸ ζῳοποιοῦν, ἡ σὰρξ οὐκ ὠφελεῖ οὐδέν. Τὰ ῥήματα ἃ ἐγὼ λελάληκα ὑμῖν πνεῦμά ἐστιν καὶ ζωή ἐστιν.
43006064 Ἀλλʼ εἰσὶν ἐξ ὑμῶν τινες, οἳ οὐ πιστεύουσιν.” Ἤιδει γὰρ ἐξ ἀρχῆς ὁ ˚Ἰησοῦς, τίνες εἰσὶν οἱ μὴ πιστεύοντες, καὶ τίς ἐστιν ὁ παραδώσων αὐτόν.
43006065 Καὶ ἔλεγεν, “Διὰ τοῦτο εἴρηκα ὑμῖν ὅτι οὐδεὶς δύναται ἐλθεῖν πρός με, ἐὰν μὴ ᾖ δεδομένον αὐτῷ ἐκ τοῦ Πατρός.”
43006066 Ἐκ τούτου πολλοὶ ἐκ τῶν μαθητῶν αὐτοῦ ἀπῆλθον εἰς τὰ ὀπίσω, καὶ οὐκέτι μετʼ αὐτοῦ περιεπάτουν.
43006067 Εἶπεν οὖν ὁ ˚Ἰησοῦς τοῖς δώδεκα, “Μὴ καὶ ὑμεῖς θέλετε ὑπάγειν;”
43006068 Ἀπεκρίθη αὐτῷ Σίμων Πέτρος, “˚Κύριε, πρὸς τίνα ἀπελευσόμεθα; Ῥήματα ζωῆς αἰωνίου ἔχεις.
43006069 Καὶ ἡμεῖς πεπιστεύκαμεν καὶ ἐγνώκαμεν ὅτι σὺ εἶ ὁ Ἅγιος τοῦ ˚Θεοῦ.”
43006070 Ἀπεκρίθη αὐτοῖς ὁ ˚Ἰησοῦς, “Οὐκ ἐγὼ ὑμᾶς τοὺς δώδεκα ἐξελεξάμην, καὶ ἐξ ὑμῶν εἷς διάβολός ἐστιν;”
43006071 Ἔλεγεν δὲ τὸν Ἰούδαν Σίμωνος Ἰσκαριώτου· οὗτος γὰρ ἔμελλεν παραδιδόναι αὐτόν, εἷς ἐκ τῶν δώδεκα.
43007001 Καὶ μετὰ ταῦτα, περιεπάτει ὁ ˚Ἰησοῦς ἐν τῇ Γαλιλαίᾳ, οὐ γὰρ ἤθελεν ἐν τῇ Ἰουδαίᾳ περιπατεῖν, ὅτι ἐζήτουν αὐτὸν οἱ Ἰουδαῖοι ἀποκτεῖναι.
43007002 Ἦν δὲ ἐγγὺς ἡ ἑορτὴ τῶν Ἰουδαίων ἡ σκηνοπηγία.
43007003 Εἶπον οὖν πρὸς αὐτὸν οἱ ἀδελφοὶ αὐτοῦ, “Μετάβηθι ἐντεῦθεν, καὶ ὕπαγε εἰς τὴν Ἰουδαίαν, ἵνα καὶ οἱ μαθηταί σου θεωρήσουσιν σοῦ τὰ ἔργα ἃ ποιεῖς.
43007004 Οὐδεὶς γάρ τι ἐν κρυπτῷ ποιεῖ καὶ ζητεῖ αὐτὸς ἐν παρρησίᾳ εἶναι.” Εἰ ταῦτα ποιεῖς, φανέρωσον σεαυτὸν τῷ κόσμῳ.
43007005 Οὐδὲ γὰρ οἱ ἀδελφοὶ αὐτοῦ ἐπίστευον εἰς αὐτόν.
43007006 Λέγει οὖν αὐτοῖς ὁ ˚Ἰησοῦς, “Ὁ καιρὸς ὁ ἐμὸς οὔπω πάρεστιν, ὁ δὲ καιρὸς ὁ ὑμέτερος πάντοτέ ἐστιν ἕτοιμος.
43007007 Οὐ δύναται ὁ κόσμος μισεῖν ὑμᾶς, ἐμὲ δὲ μισεῖ, ὅτι ἐγὼ μαρτυρῶ περὶ αὐτοῦ ὅτι τὰ ἔργα αὐτοῦ πονηρά ἐστιν.
43007008 Ὑμεῖς ἀνάβητε εἰς τὴν ἑορτήν· ἐγὼ οὔπω ἀναβαίνω εἰς τὴν ἑορτὴν ταύτην, ὅτι ὁ ἐμὸς καιρὸς οὔπω πεπλήρωται.”
43007009 Ταῦτα δὲ εἰπὼν αὐτοῖς ἔμεινεν ἐν τῇ Γαλιλαίᾳ.
43007010 Ὡς δὲ ἀνέβησαν οἱ ἀδελφοὶ αὐτοῦ εἰς τὴν ἑορτήν, τότε καὶ αὐτὸς ἀνέβη, οὐ φανερῶς, ἀλλʼ ὡς ἐν κρυπτῷ.
43007011 Οἱ οὖν Ἰουδαῖοι ἐζήτουν αὐτὸν ἐν τῇ ἑορτῇ, καὶ ἔλεγον, “Ποῦ ἐστιν ἐκεῖνος;”
43007012 Καὶ γογγυσμὸς περὶ αὐτοῦ ἦν πολὺς ἐν τοῖς ὄχλοις. Οἱ μὲν ἔλεγον, ὅτι “Ἀγαθός ἐστιν”, ἄλλοι δὲ ἔλεγον, “Οὔ, ἀλλὰ πλανᾷ τὸν ὄχλον.”
43007013 Οὐδεὶς μέντοι παρρησίᾳ ἐλάλει περὶ αὐτοῦ, διὰ τὸν φόβον τῶν Ἰουδαίων.
43007014 Ἤδη δὲ τῆς ἑορτῆς μεσούσης, ἀνέβη ˚Ἰησοῦς εἰς τὸ ἱερὸν καὶ ἐδίδασκεν.
43007015 Ἐθαύμαζον οὖν οἱ Ἰουδαῖοι λέγοντες, “Πῶς οὗτος γράμματα οἶδεν, μὴ μεμαθηκώς;”
43007016 Ἀπεκρίθη οὖν αὐτοῖς ˚Ἰησοῦς καὶ εἶπεν, “Ἡ ἐμὴ διδαχὴ οὐκ ἔστιν ἐμὴ, ἀλλὰ τοῦ πέμψαντός με.
43007017 Ἐάν τις θέλῃ τὸ θέλημα αὐτοῦ ποιεῖν, γνώσεται περὶ τῆς διδαχῆς, πότερον ἐκ τοῦ ˚Θεοῦ ἐστιν, ἢ ἐγὼ ἀπʼ ἐμαυτοῦ λαλῶ.
43007018 Ὁ ἀφʼ ἑαυτοῦ λαλῶν, τὴν δόξαν τὴν ἰδίαν ζητεῖ· ὁ δὲ ζητῶν τὴν δόξαν τοῦ πέμψαντος αὐτὸν, οὗτος ἀληθής ἐστιν, καὶ ἀδικία ἐν αὐτῷ οὐκ ἔστιν.
43007019 Οὐ Μωϋσῆς δέδωκεν ὑμῖν τὸν νόμον, καὶ οὐδεὶς ἐξ ὑμῶν ποιεῖ τὸν νόμον; Τί με ζητεῖτε ἀποκτεῖναι;”
43007020 Ἀπεκρίθη ὁ ὄχλος, “Δαιμόνιον ἔχεις· τίς σε ζητεῖ ἀποκτεῖναι;”
43007021 Ἀπεκρίθη ˚Ἰησοῦς καὶ εἶπεν αὐτοῖς, “Ἓν ἔργον ἐποίησα καὶ πάντες θαυμάζετε.
43007022 Διὰ τοῦτο Μωϋσῆς δέδωκεν ὑμῖν τὴν περιτομήν ( οὐχ ὅτι ἐκ τοῦ Μωϋσέως ἐστὶν, ἀλλʼ ἐκ τῶν πατέρων ), καὶ ἐν Σαββάτῳ περιτέμνετε ἄνθρωπον.
43007023 Εἰ περιτομὴν λαμβάνει ἄνθρωπος ἐν Σαββάτῳ, ἵνα μὴ λυθῇ ὁ νόμος Μωϋσέως, ἐμοὶ χολᾶτε ὅτι ὅλον ἄνθρωπον ὑγιῆ ἐποίησα ἐν Σαββάτῳ;
43007024 Μὴ κρίνετε κατʼ ὄψιν, ἀλλὰ τὴν δικαίαν κρίσιν κρίνετε.”
43007025 Ἔλεγον οὖν τινες ἐκ τῶν Ἱεροσολυμιτῶν, “Οὐχ οὗτός ἐστιν ὃν ζητοῦσιν ἀποκτεῖναι;
43007026 Καὶ ἴδε, παρρησίᾳ λαλεῖ, καὶ οὐδὲν αὐτῷ λέγουσιν. Μήποτε ἀληθῶς ἔγνωσαν οἱ ἄρχοντες ὅτι οὗτός ἐστιν ὁ ˚Χριστός;
43007027 Ἀλλὰ τοῦτον οἴδαμεν πόθεν ἐστίν. Ὁ δὲ ˚Χριστὸς ὅταν ἔρχηται, οὐδεὶς γινώσκει πόθεν ἐστίν.”
43007028 Ἔκραξεν οὖν ἐν τῷ ἱερῷ διδάσκων ὁ ˚Ἰησοῦς, καὶ λέγων, “Κἀμὲ οἴδατε, καὶ οἴδατε πόθεν εἰμί· καὶ ἀπʼ ἐμαυτοῦ οὐκ ἐλήλυθα, ἀλλʼ ἔστιν ἀληθινὸς ὁ πέμψας με, ὃν ὑμεῖς οὐκ οἴδατε.
43007029 Ἐγὼ οἶδα αὐτόν, ὅτι παρʼ αὐτοῦ εἰμι, κἀκεῖνός με ἀπέστειλεν.”
43007030 Ἐζήτουν οὖν αὐτὸν πιάσαι, καὶ οὐδεὶς ἐπέβαλεν ἐπʼ αὐτὸν τὴν χεῖρα, ὅτι οὔπω ἐληλύθει ἡ ὥρα αὐτοῦ.
43007031 Ἐκ τοῦ ὄχλου δὲ πολλοὶ ἐπίστευσαν εἰς αὐτόν, καὶ ἔλεγον, “Ὁ ˚Χριστὸς, ὅταν ἔλθῃ, μὴ πλείονα σημεῖα ποιήσει ὧν οὗτος ἐποίησεν;”
43007032 Ἤκουσαν οἱ Φαρισαῖοι τοῦ ὄχλου γογγύζοντος περὶ αὐτοῦ ταῦτα, καὶ ἀπέστειλαν οἱ ἀρχιερεῖς καὶ οἱ Φαρισαῖοι ὑπηρέτας, ἵνα πιάσωσιν αὐτόν.
43007033 Εἶπεν οὖν ὁ ˚Ἰησοῦς, “Ἔτι χρόνον μικρὸν μεθʼ ὑμῶν εἰμι, καὶ ὑπάγω πρὸς τὸν πέμψαντά με.
43007034 Ζητήσετέ με, καὶ οὐχ εὑρήσετέ, με καὶ ὅπου εἰμὶ ἐγὼ ὑμεῖς, οὐ δύνασθε ἐλθεῖν.”
43007035 Εἶπον οὖν οἱ Ἰουδαῖοι πρὸς ἑαυτούς, “Ποῦ οὗτος μέλλει πορεύεσθαι, ὅτι ἡμεῖς οὐχ εὑρήσομεν αὐτόν; Μὴ εἰς τὴν διασπορὰν τῶν Ἑλλήνων μέλλει πορεύεσθαι, καὶ διδάσκειν τοὺς Ἕλληνας;
43007036 Τίς ἐστιν ὁ λόγος οὗτος ὃν εἶπεν, ‘Ζητήσετέ με, καὶ οὐχ εὑρήσετέ, με καὶ ὅπου εἰμὶ ἐγὼ, ὑμεῖς οὐ δύνασθε ἐλθεῖν’;”
43007037 Ἐν δὲ τῇ ἐσχάτῃ ἡμέρᾳ τῇ μεγάλῃ τῆς ἑορτῆς εἱστήκει ὁ ˚Ἰησοῦς, καὶ ἔκραξεν λέγων, “Ἐάν τις διψᾷ, ἐρχέσθω πρός με καὶ πινέτω.
43007038 Ὁ πιστεύων εἰς ἐμέ, καθὼς εἶπεν ἡ Γραφή, ‘Ποταμοὶ ἐκ τῆς κοιλίας αὐτοῦ ῥεύσουσιν ὕδατος ζῶντος.’”
43007039 Τοῦτο δὲ εἶπεν περὶ τοῦ ˚Πνεύματος, οὗ ἔμελλον λαμβάνειν, οἱ πιστεύσαντες εἰς αὐτόν· οὔπω γὰρ ἦν ˚Πνεῦμα, ὅτι ˚Ἰησοῦς οὐδέπω ἐδοξάσθη.
43007040 Ἐκ τοῦ ὄχλου οὖν, ἀκούσαντες τῶν λόγων τούτων, ἔλεγον, “Οὗτός ἐστιν ἀληθῶς ὁ προφήτης”.
43007041 Ἄλλοι ἔλεγον, “Οὗτός ἐστιν ὁ ˚Χριστός.” Οἱ δὲ ἔλεγον, “Μὴ γὰρ ἐκ τῆς Γαλιλαίας ὁ ˚Χριστὸς ἔρχεται;
43007042 Οὐχ ἡ Γραφὴ εἶπεν ὅτι ἐκ τοῦ σπέρματος Δαυὶδ, καὶ ἀπὸ Βηθλέεμ, τῆς κώμης ὅπου ἦν Δαυὶδ, ἔρχεται ὁ ˚Χριστός;”
43007043 Σχίσμα οὖν ἐγένετο ἐν τῷ ὄχλῳ διʼ αὐτόν.
43007044 Τινὲς δὲ ἤθελον ἐξ αὐτῶν πιάσαι αὐτόν, ἀλλʼ οὐδεὶς ἔβαλεν ἐπʼ αὐτὸν τὰς χεῖρας.
43007045 Ἦλθον οὖν οἱ ὑπηρέται πρὸς τοὺς ἀρχιερεῖς καὶ Φαρισαίους, καὶ εἶπον αὐτοῖς ἐκεῖνοι, “Διὰ τί οὐκ ἠγάγετε αὐτόν;”
43007046 Ἀπεκρίθησαν οἱ ὑπηρέται, “Οὐδέποτε ἐλάλησεν οὕτως ἄνθρωπος.”
43007047 Ἀπεκρίθησαν οὖν αὐτοῖς οἱ Φαρισαῖοι, “Μὴ καὶ ὑμεῖς πεπλάνησθε;
43007048 Μή τις ἐκ τῶν ἀρχόντων ἐπίστευσεν εἰς αὐτὸν, ἢ ἐκ τῶν Φαρισαίων;
43007049 Ἀλλὰ ὁ ὄχλος οὗτος, ὁ μὴ γινώσκων τὸν νόμον, ἐπάρατοί εἰσιν.”
43007050 Λέγει Νικόδημος πρὸς αὐτούς, ὁ ἐλθὼν πρὸς αὐτὸν πρότερον, εἷς ὢν ἐξ αὐτῶν,
43007051 “Μὴ ὁ νόμος ἡμῶν κρίνει τὸν ἄνθρωπον, ἐὰν μὴ ἀκούσῃ πρῶτον παρʼ αὐτοῦ, καὶ γνῷ τί ποιεῖ;”
43007052 Ἀπεκρίθησαν καὶ εἶπαν αὐτῷ, “Μὴ καὶ σὺ ἐκ τῆς Γαλιλαίας εἶ; Ἐραύνησον καὶ ἴδε ὅτι ἐκ τῆς Γαλιλαίας προφήτης οὐκ ἐγείρεται.”
43008012 Πάλιν οὖν αὐτοῖς ἐλάλησεν ὁ ˚Ἰησοῦς λέγων, “Ἐγώ εἰμι τὸ φῶς τοῦ κόσμου· ὁ ἀκολουθῶν μοι οὐ μὴ περιπατήσῃ ἐν τῇ σκοτίᾳ, ἀλλʼ ἕξει τὸ φῶς τῆς ζωῆς.”
43008013 Εἶπον οὖν αὐτῷ οἱ Φαρισαῖοι, “Σὺ περὶ σεαυτοῦ μαρτυρεῖς· ἡ μαρτυρία σου οὐκ ἔστιν ἀληθής.”
43008014 Ἀπεκρίθη ˚Ἰησοῦς καὶ εἶπεν αὐτοῖς, “Κἂν ἐγὼ μαρτυρῶ περὶ ἐμαυτοῦ, ἀληθής ἐστιν ἡ μαρτυρία μου, ὅτι οἶδα πόθεν ἦλθον καὶ ποῦ ὑπάγω. Ὑμεῖς δὲ οὐκ οἴδατε πόθεν ἔρχομαι ἢ ποῦ ὑπάγω.
43008015 Ὑμεῖς κατὰ τὴν σάρκα κρίνετε, ἐγὼ οὐ κρίνω οὐδένα.
43008016 Καὶ ἐὰν κρίνω δὲ ἐγώ, ἡ κρίσις ἡ ἐμὴ ἀληθινή ἐστιν, ὅτι μόνος οὐκ εἰμί, ἀλλʼ ἐγὼ καὶ ὁ πέμψας με Πατήρ.
43008017 Καὶ ἐν τῷ νόμῳ δὲ τῷ ὑμετέρῳ γέγραπται, ὅτι δύο ἀνθρώπων ἡ μαρτυρία ἀληθής ἐστιν.
43008018 Ἐγώ εἰμι ὁ μαρτυρῶν περὶ ἐμαυτοῦ, καὶ μαρτυρεῖ περὶ ἐμοῦ ὁ πέμψας με Πατήρ.”
43008019 Ἔλεγον οὖν αὐτῷ, “Ποῦ ἐστιν ὁ πατήρ σου;” Ἀπεκρίθη ˚Ἰησοῦς, “Οὔτε ἐμὲ οἴδατε, οὔτε τὸν Πατέρα μου. Εἰ ἐμὲ ᾔδειτε, καὶ τὸν Πατέρα μου ἂν ᾔδειτε.”
43008020 Ταῦτα τὰ ῥήματα ἐλάλησεν ἐν τῷ γαζοφυλακίῳ, διδάσκων ἐν τῷ ἱερῷ· καὶ οὐδεὶς ἐπίασεν αὐτόν, ὅτι οὔπω ἐληλύθει ἡ ὥρα αὐτοῦ.
43008021 Εἶπεν οὖν πάλιν αὐτοῖς, “Ἐγὼ ὑπάγω καὶ ζητήσετέ με, καὶ ἐν τῇ ἁμαρτίᾳ ὑμῶν ἀποθανεῖσθε· ὅπου ἐγὼ ὑπάγω, ὑμεῖς οὐ δύνασθε ἐλθεῖν.”
43008022 Ἔλεγον οὖν οἱ Ἰουδαῖοι, “Μήτι ἀποκτενεῖ ἑαυτὸν, ὅτι λέγει, ‘Ὅπου ἐγὼ ὑπάγω ὑμεῖς, οὐ δύνασθε ἐλθεῖν’;”
43008023 Καὶ ἔλεγεν αὐτοῖς, “Ὑμεῖς ἐκ τῶν κάτω ἐστέ, ἐγὼ ἐκ τῶν ἄνω εἰμί· ὑμεῖς ἐκ τούτου τοῦ κόσμου ἐστέ, ἐγὼ οὐκ εἰμὶ ἐκ τοῦ κόσμου τούτου.
43008024 Εἶπον οὖν ὑμῖν ὅτι ἀποθανεῖσθε ἐν ταῖς ἁμαρτίαις ὑμῶν· ἐὰν γὰρ μὴ πιστεύσητε ὅτι ἐγώ εἰμι, ἀποθανεῖσθε ἐν ταῖς ἁμαρτίαις ὑμῶν.”
43008025 Ἔλεγον οὖν αὐτῷ, “Σὺ τίς εἶ;” Εἶπεν αὐτοῖς ὁ ˚Ἰησοῦς, “Τὴν ἀρχὴν ὅ τι καὶ λαλῶ ὑμῖν;
43008026 Πολλὰ ἔχω περὶ ὑμῶν λαλεῖν καὶ κρίνειν· ἀλλʼ ὁ πέμψας με ἀληθής ἐστιν, κἀγὼ ἃ ἤκουσα παρʼ αὐτοῦ, ταῦτα λαλῶ εἰς τὸν κόσμον.”
43008027 Οὐκ ἔγνωσαν ὅτι τὸν Πατέρα αὐτοῖς ἔλεγεν.
43008028 Εἶπεν οὖν αὐτοῖς ὁ ˚Ἰησοῦς, “Ὅταν ὑψώσητε τὸν Υἱὸν τοῦ Ἀνθρώπου, τότε γνώσεσθε ὅτι ἐγώ εἰμι, καὶ ἀπʼ ἐμαυτοῦ ποιῶ οὐδέν, ἀλλὰ καθὼς ἐδίδαξέν με ὁ Πατὴρ, ταῦτα λαλῶ.
43008029 Καὶ ὁ πέμψας με, μετʼ ἐμοῦ ἐστιν· οὐκ ἀφῆκέν με μόνον, ὅτι ἐγὼ τὰ ἀρεστὰ αὐτῷ ποιῶ πάντοτε.”
43008030 Ταῦτα αὐτοῦ λαλοῦντος, πολλοὶ ἐπίστευσαν εἰς αὐτόν.
43008031 Ἔλεγεν οὖν ὁ ˚Ἰησοῦς πρὸς τοὺς πεπιστευκότας αὐτῷ Ἰουδαίους, “Ἐὰν ὑμεῖς μείνητε ἐν τῷ λόγῳ τῷ ἐμῷ, ἀληθῶς μαθηταί μού ἐστε.
43008032 Καὶ γνώσεσθε τὴν ἀλήθειαν, καὶ ἡ ἀλήθεια ἐλευθερώσει ὑμᾶς.”
43008033 Ἀπεκρίθησαν πρὸς αὐτόν, “Σπέρμα Ἀβραάμ ἐσμεν, καὶ οὐδενὶ δεδουλεύκαμεν πώποτε· πῶς σὺ λέγεις, ὅτι ‘Ἐλεύθεροι γενήσεσθε’;”
43008034 Ἀπεκρίθη αὐτοῖς ὁ ˚Ἰησοῦς, “Ἀμὴν, ἀμὴν, λέγω ὑμῖν ὅτι πᾶς ὁ ποιῶν τὴν ἁμαρτίαν, δοῦλός ἐστιν τῆς ἁμαρτίας.
43008035 Ὁ δὲ δοῦλος οὐ μένει ἐν τῇ οἰκίᾳ εἰς τὸν αἰῶνα, ὁ Υἱὸς μένει εἰς τὸν αἰῶνα.
43008036 Ἐὰν οὖν ὁ Υἱὸς ὑμᾶς ἐλευθερώσῃ, ὄντως ἐλεύθεροι ἔσεσθε.
43008037 Οἶδα ὅτι σπέρμα Ἀβραάμ ἐστε, ἀλλὰ ζητεῖτέ με ἀποκτεῖναι, ὅτι ὁ λόγος ὁ ἐμὸς οὐ χωρεῖ ἐν ὑμῖν.
43008038 Ἃ ἐγὼ ἑώρακα παρὰ τῷ Πατρὶ, λαλῶ· καὶ ὑμεῖς οὖν ἃ ἠκούσατε παρὰ τοῦ πατρὸς, ποιεῖτε.”
43008039 Ἀπεκρίθησαν καὶ εἶπαν αὐτῷ, “Ὁ πατὴρ ἡμῶν Ἀβραάμ ἐστιν.” Λέγει αὐτοῖς ὁ ˚Ἰησοῦς, “Εἰ τέκνα τοῦ Ἀβραάμ ἐστε, τὰ ἔργα τοῦ Ἀβραὰμ ἐποιεῖτε.
43008040 Νῦν δὲ ζητεῖτέ με ἀποκτεῖναι, ἄνθρωπον ὃς τὴν ἀλήθειαν ὑμῖν λελάληκα, ἣν ἤκουσα παρὰ τοῦ ˚Θεοῦ· τοῦτο Ἀβραὰμ οὐκ ἐποίησεν.
43008041 Ὑμεῖς ποιεῖτε τὰ ἔργα τοῦ πατρὸς ὑμῶν.” εἶπον οὖν αὐτῷ, “Ἡμεῖς ἐκ πορνείας οὐ γεγεννήμεθα· ἕνα Πατέρα ἔχομεν, τὸν ˚Θεόν.”
43008042 Εἶπεν αὐτοῖς ὁ ˚Ἰησοῦς, “Εἰ ὁ ˚Θεὸς Πατὴρ ὑμῶν ἦν, ἠγαπᾶτε ἂν ἐμέ, ἐγὼ γὰρ ἐκ τοῦ ˚Θεοῦ ἐξῆλθον καὶ ἥκω· οὐδὲ γὰρ ἀπʼ ἐμαυτοῦ ἐλήλυθα, ἀλλʼ ἐκεῖνός με ἀπέστειλεν.
43008043 Διὰ τί τὴν λαλιὰν τὴν ἐμὴν οὐ γινώσκετε; Ὅτι οὐ δύνασθε ἀκούειν τὸν λόγον τὸν ἐμόν.
43008044 Ὑμεῖς ἐκ τοῦ πατρὸς τοῦ διαβόλου ἐστὲ, καὶ τὰς ἐπιθυμίας τοῦ πατρὸς ὑμῶν θέλετε ποιεῖν. Ἐκεῖνος ἀνθρωποκτόνος ἦν ἀπʼ ἀρχῆς, καὶ ἐν τῇ ἀληθείᾳ οὐκ ἔστηκεν, ὅτι οὐκ ἔστιν ἀλήθεια ἐν αὐτῷ. Ὅταν λαλῇ τὸ ψεῦδος, ἐκ τῶν ἰδίων λαλεῖ, ὅτι ψεύστης ἐστὶν, καὶ ὁ πατὴρ αὐτοῦ.
43008045 Ἐγὼ δὲ ὅτι τὴν ἀλήθειαν λέγω, οὐ πιστεύετέ μοι!
43008046 Τίς ἐξ ὑμῶν ἐλέγχει με περὶ ἁμαρτίας; Εἰ ἀλήθειαν λέγω, διὰ τί ὑμεῖς οὐ πιστεύετέ μοι;
43008047 Ὁ ὢν ἐκ τοῦ ˚Θεοῦ, τὰ ῥήματα τοῦ ˚Θεοῦ ἀκούει· διὰ τοῦτο ὑμεῖς οὐκ ἀκούετε, ὅτι ἐκ τοῦ ˚Θεοῦ οὐκ ἐστέ.”
43008048 Ἀπεκρίθησαν οἱ Ἰουδαῖοι καὶ εἶπαν αὐτῷ, “Οὐ καλῶς λέγομεν ἡμεῖς ὅτι Σαμαρείτης εἶ σὺ, καὶ δαιμόνιον ἔχεις;”
43008049 Ἀπεκρίθη ˚Ἰησοῦς, “Ἐγὼ δαιμόνιον οὐκ ἔχω, ἀλλὰ τιμῶ τὸν Πατέρα μου, καὶ ὑμεῖς ἀτιμάζετέ με.
43008050 Ἐγὼ δὲ οὐ ζητῶ τὴν δόξαν μου· ἔστιν ὁ ζητῶν καὶ κρίνων.
43008051 Ἀμὴν, ἀμὴν, λέγω ὑμῖν, ἐάν τις τὸν ἐμὸν λόγον τηρήσῃ, θάνατον οὐ μὴ θεωρήσῃ εἰς τὸν αἰῶνα.”
43008052 Εἶπον αὐτῷ οἱ Ἰουδαῖοι, “Νῦν ἐγνώκαμεν ὅτι δαιμόνιον ἔχεις. Ἀβραὰμ ἀπέθανεν καὶ οἱ προφῆται, καὶ σὺ λέγεις, ‘Ἐάν τις τὸν λόγον μου τηρήσῃ, οὐ μὴ γεύσηται θανάτου εἰς τὸν αἰῶνα.’
43008053 Μὴ σὺ μείζων εἶ τοῦ πατρὸς ἡμῶν Ἀβραάμ, ὅστις ἀπέθανεν; Καὶ οἱ προφῆται ἀπέθανον! Τίνα σεαυτὸν ποιεῖς;”
43008054 Ἀπεκρίθη ˚Ἰησοῦς, “Ἐὰν ἐγὼ δοξάσω ἐμαυτόν, ἡ δόξα μου οὐδέν ἐστιν· ἔστιν ὁ Πατήρ μου ὁ δοξάζων με, ὃν ὑμεῖς λέγετε, ὅτι ‘˚Θεὸς ἡμῶν ἐστιν’,
43008055 καὶ οὐκ ἐγνώκατε αὐτόν, ἐγὼ δὲ οἶδα αὐτόν, κἂν εἴπω ὅτι οὐκ οἶδα αὐτόν, ἔσομαι ὅμοιος ὑμῖν ψεύστης. Ἀλλʼ οἶδα αὐτὸν, καὶ τὸν λόγον αὐτοῦ τηρῶ.
43008056 Ἀβραὰμ ὁ πατὴρ ὑμῶν ἠγαλλιάσατο, ἵνα ἴδῃ τὴν ἡμέραν τὴν ἐμήν, καὶ εἶδεν καὶ ἐχάρη.”
43008057 Εἶπον οὖν οἱ Ἰουδαῖοι πρὸς αὐτόν, “Πεντήκοντα ἔτη οὔπω ἔχεις, καὶ Ἀβραὰμ ἑώρακας;”
43008058 Εἶπεν αὐτοῖς ˚Ἰησοῦς, “Ἀμὴν, ἀμὴν, λέγω ὑμῖν, πρὶν Ἀβραὰμ γενέσθαι, ἐγὼ εἰμί.”
43008059 Ἦραν οὖν λίθους, ἵνα βάλωσιν ἐπʼ αὐτόν· ˚Ἰησοῦς δὲ ἐκρύβη, καὶ ἐξῆλθεν ἐκ τοῦ ἱεροῦ.
43009001 Καὶ παράγων, εἶδεν ἄνθρωπον τυφλὸν ἐκ γενετῆς.
43009002 Καὶ ἠρώτησαν αὐτὸν οἱ μαθηταὶ αὐτοῦ λέγοντες, “Ῥαββί, τίς ἥμαρτεν, οὗτος ἢ οἱ γονεῖς αὐτοῦ, ἵνα τυφλὸς γεννηθῇ;”
43009003 Ἀπεκρίθη ˚Ἰησοῦς, “Οὔτε οὗτος ἥμαρτεν, οὔτε οἱ γονεῖς αὐτοῦ, ἀλλʼ ἵνα φανερωθῇ τὰ ἔργα τοῦ ˚Θεοῦ ἐν αὐτῷ.
43009004 Ἡμᾶς δεῖ ἐργάζεσθαι τὰ ἔργα τοῦ πέμψαντός με, ἕως ἡμέρα ἐστίν· ἔρχεται νὺξ, ὅτε οὐδεὶς δύναται ἐργάζεσθαι.
43009005 Ὅταν ἐν τῷ κόσμῳ ὦ, φῶς εἰμι τοῦ κόσμου.”
43009006 Ταῦτα εἰπὼν, ἔπτυσεν χαμαὶ καὶ ἐποίησεν πηλὸν ἐκ τοῦ πτύσματος, καὶ ἐπέχρισεν αὐτοῦ τὸν πηλὸν ἐπὶ τοὺς ὀφθαλμούς.
43009007 Καὶ εἶπεν αὐτῷ, “Ὕπαγε, νίψαι εἰς τὴν κολυμβήθραν τοῦ Σιλωάμ” ( ὃ ἑρμηνεύεται, “Ἀπεσταλμένος” ). Ἀπῆλθεν οὖν καὶ ἐνίψατο, καὶ ἦλθεν βλέπων.
43009008 Οἱ οὖν γείτονες καὶ οἱ θεωροῦντες αὐτὸν τὸ πρότερον, ὅτι προσαίτης ἦν, ἔλεγον, “Οὐχ οὗτός ἐστιν ὁ καθήμενος καὶ προσαιτῶν;”
43009009 Ἄλλοι ἔλεγον, ὅτι “Οὗτός ἐστιν”, ἄλλοι ἔλεγον, “Οὐχί, ἀλλὰ ὅμοιος αὐτῷ ἐστιν.” Ἐκεῖνος ἔλεγεν, ὅτι “Ἐγώ εἰμι.”
43009010 Ἔλεγον οὖν αὐτῷ, “Πῶς ἠνεῴχθησάν σου οἱ ὀφθαλμοί;”
43009011 Ἀπεκρίθη ἐκεῖνος, ἄνθρωπος ὁ λεγόμενος ˚Ἰησοῦς πηλὸν ἐποίησεν, καὶ ἐπέχρισέν μου τοὺς ὀφθαλμοὺς, καὶ εἶπέν μοι, ὅτι ‘Ὕπαγε εἰς τὸν Σιλωὰμ καὶ νίψαι.’ Ἀπελθὼν οὖν καὶ νιψάμενος, ἀνέβλεψα.”
43009012 Καὶ εἶπαν αὐτῷ, “Ποῦ ἐστιν ἐκεῖνος;” Λέγει, “Οὐκ οἶδα.”
43009013 Ἄγουσιν αὐτὸν πρὸς τοὺς Φαρισαίους, τόν ποτε τυφλόν.
43009014 Ἦν δὲ Σάββατον ἐν ᾗ ἡμέρᾳ τὸν πηλὸν ἐποίησεν ὁ ˚Ἰησοῦς, καὶ ἀνέῳξεν αὐτοῦ τοὺς ὀφθαλμούς.
43009015 Πάλιν οὖν ἠρώτων αὐτὸν καὶ οἱ Φαρισαῖοι πῶς ἀνέβλεψεν. Ὁ δὲ εἶπεν αὐτοῖς, “Πηλὸν ἐπέθηκέν μου ἐπὶ τοὺς ὀφθαλμούς, καὶ ἐνιψάμην, καὶ βλέπω.”
43009016 Ἔλεγον οὖν ἐκ τῶν Φαρισαίων τινές, “Οὐκ ἔστιν οὗτος παρὰ ˚Θεοῦ ὁ ἄνθρωπος, ὅτι τὸ Σάββατον οὐ τηρεῖ.” Ἄλλοι ἔλεγον, “Πῶς δύναται ἄνθρωπος ἁμαρτωλὸς τοιαῦτα σημεῖα ποιεῖν;” Καὶ σχίσμα ἦν ἐν αὐτοῖς.
43009017 Λέγουσιν οὖν τῷ τυφλῷ πάλιν, “Σὺ τί λέγεις περὶ αὐτοῦ, ὅτι ἠνέῳξέν σου τοὺς ὀφθαλμούς;” Ὁ δὲ εἶπεν, ὅτι “Προφήτης ἐστίν.”
43009018 Οὐκ ἐπίστευσαν οὖν οἱ Ἰουδαῖοι περὶ αὐτοῦ ὅτι ἦν τυφλὸς καὶ ἀνέβλεψεν, ἕως ὅτου ἐφώνησαν τοὺς γονεῖς αὐτοῦ τοῦ ἀναβλέψαντος.
43009019 Καὶ ἠρώτησαν αὐτοὺς λέγοντες, “Οὗτός ἐστιν ὁ υἱὸς ὑμῶν, ὃν ὑμεῖς λέγετε ὅτι τυφλὸς ἐγεννήθη; Πῶς οὖν βλέπει ἄρτι;”
43009020 Ἀπεκρίθησαν οὖν οἱ γονεῖς αὐτοῦ καὶ εἶπαν, “Οἴδαμεν ὅτι οὗτός ἐστιν ὁ υἱὸς ἡμῶν, καὶ ὅτι τυφλὸς ἐγεννήθη.
43009021 Πῶς δὲ νῦν βλέπει, οὐκ οἴδαμεν, ἢ τίς ἤνοιξεν αὐτοῦ τοὺς ὀφθαλμοὺς, ἡμεῖς οὐκ οἴδαμεν· αὐτὸς ἡλικίαν ἔχει, αὐτὸν ἐρωτήσατε, αὐτὸς περὶ ἑαυτοῦ λαλήσει.”
43009022 Ταῦτα εἶπον οἱ γονεῖς αὐτοῦ, ὅτι ἐφοβοῦντο τοὺς Ἰουδαίους, ἤδη γὰρ συνετέθειντο οἱ Ἰουδαῖοι ἵνα ἐάν τις αὐτὸν ὁμολογήσῃ ˚Χριστόν, ἀποσυνάγωγος γένηται.
43009023 Διὰ τοῦτο οἱ γονεῖς αὐτοῦ εἶπαν, ὅτι “Ἡλικίαν ἔχει, αὐτὸν ἐπερωτήσατε.”
43009024 Ἐφώνησαν οὖν τὸν ἄνθρωπον ἐκ δευτέρου ὃς ἦν τυφλὸς, καὶ εἶπαν αὐτῷ, “Δὸς δόξαν τῷ ˚Θεῷ· ἡμεῖς οἴδαμεν ὅτι οὗτος ὁ ἄνθρωπος ἁμαρτωλός ἐστιν.”
43009025 Ἀπεκρίθη οὖν ἐκεῖνος, “Εἰ ἁμαρτωλός ἐστιν οὐκ οἶδα. Ἓν οἶδα, ὅτι τυφλὸς ὢν, ἄρτι βλέπω.”
43009026 Εἶπον οὖν αὐτῷ, “Τί ἐποίησέν σοι; Πῶς ἤνοιξέν σου τοὺς ὀφθαλμούς;”
43009027 Ἀπεκρίθη αὐτοῖς, “Εἶπον ὑμῖν ἤδη, καὶ οὐκ ἠκούσατε. Τί πάλιν θέλετε ἀκούειν; Μὴ καὶ ὑμεῖς θέλετε αὐτοῦ μαθηταὶ γενέσθαι;”
43009028 Καὶ ἐλοιδόρησαν αὐτὸν, καὶ εἶπον, “Σὺ μαθητὴς εἶ ἐκείνου, ἡμεῖς δὲ τοῦ Μωϋσέως ἐσμὲν μαθηταί.
43009029 Ἡμεῖς οἴδαμεν ὅτι Μωϋσεῖ λελάληκεν ὁ ˚Θεός, τοῦτον δὲ οὐκ οἴδαμεν πόθεν ἐστίν.”
43009030 Ἀπεκρίθη ὁ ἄνθρωπος καὶ εἶπεν αὐτοῖς, “Ἐν τούτῳ γὰρ τὸ θαυμαστόν ἐστιν, ὅτι ὑμεῖς οὐκ οἴδατε πόθεν ἐστίν, καὶ ἤνοιξέν μου τοὺς ὀφθαλμούς.
43009031 Οἴδαμεν ὅτι ἁμαρτωλῶν ὁ ˚Θεὸς οὐκ ἀκούει, ἀλλʼ ἐάν τις θεοσεβὴς ᾖ, καὶ τὸ θέλημα αὐτοῦ ποιῇ, τούτου ἀκούει.
43009032 Ἐκ τοῦ αἰῶνος οὐκ ἠκούσθη ὅτι ἤνοιξέν τις ὀφθαλμοὺς τυφλοῦ γεγεννημένου.
43009033 Εἰ μὴ ἦν οὗτος παρὰ ˚Θεοῦ, οὐκ ἠδύνατο ποιεῖν οὐδέν.”
43009034 Ἀπεκρίθησαν καὶ εἶπαν αὐτῷ, “Ἐν ἁμαρτίαις σὺ ἐγεννήθης ὅλος, καὶ σὺ διδάσκεις ἡμᾶς;” Καὶ ἐξέβαλον αὐτὸν ἔξω.
43009035 Ἤκουσεν ˚Ἰησοῦς ὅτι ἐξέβαλον αὐτὸν ἔξω, καὶ εὑρὼν αὐτὸν εἶπεν, “Σὺ πιστεύεις εἰς τὸν Υἱὸν τοῦ Ἀνθρώπου;”
43009036 Ἀπεκρίθη ἐκεῖνος, “Καὶ τίς ἐστιν,” ἔφη, “κύριε, ἵνα πιστεύσω εἰς αὐτόν;”
43009037 Εἶπεν αὐτῷ ὁ ˚Ἰησοῦς, “Καὶ ἑώρακας αὐτὸν, καὶ ὁ λαλῶν μετὰ σοῦ ἐκεῖνός ἐστιν.”
43009038 Ὁ δὲ ἔφη, “Πιστεύω, ˚Κύριε”· καὶ προσεκύνησεν αὐτῷ.
43009039 Καὶ εἶπεν ὁ ˚Ἰησοῦς, “Εἰς κρίμα ἐγὼ εἰς τὸν κόσμον τοῦτον ἦλθον, ἵνα οἱ μὴ βλέποντες, βλέπωσιν, καὶ οἱ βλέποντες, τυφλοὶ γένωνται.”
43009040 Ἤκουσαν ἐκ τῶν Φαρισαίων ταῦτα οἱ μετʼ αὐτοῦ ὄντες, καὶ εἶπον αὐτῷ, “Μὴ καὶ ἡμεῖς τυφλοί ἐσμεν;”
43009041 Εἶπεν αὐτοῖς ὁ ˚Ἰησοῦς, “Εἰ τυφλοὶ ἦτε, οὐκ ἂν εἴχετε ἁμαρτίαν· νῦν δὲ λέγετε, ὅτι ‘Βλέπομεν’, ἡ ἁμαρτία ὑμῶν μένει.
43010001 Ἀμὴν, ἀμὴν, λέγω ὑμῖν, ὁ μὴ εἰσερχόμενος διὰ τῆς θύρας, εἰς τὴν αὐλὴν τῶν προβάτων, ἀλλὰ ἀναβαίνων ἀλλαχόθεν, ἐκεῖνος κλέπτης ἐστὶν καὶ λῃστής.
43010002 Ὁ δὲ εἰσερχόμενος διὰ τῆς θύρας, ποιμήν ἐστιν τῶν προβάτων.
43010003 Τούτῳ ὁ θυρωρὸς ἀνοίγει, καὶ τὰ πρόβατα τῆς φωνῆς αὐτοῦ ἀκούει, καὶ τὰ ἴδια πρόβατα φωνεῖ κατʼ ὄνομα, καὶ ἐξάγει αὐτά.
43010004 Ὅταν τὰ ἴδια πάντα ἐκβάλῃ, ἔμπροσθεν αὐτῶν πορεύεται, καὶ τὰ πρόβατα αὐτῷ ἀκολουθεῖ, ὅτι οἴδασιν τὴν φωνὴν αὐτοῦ.
43010005 Ἀλλοτρίῳ δὲ οὐ μὴ ἀκολουθήσουσιν, ἀλλὰ φεύξονται ἀπʼ αὐτοῦ, ὅτι οὐκ οἴδασι τῶν ἀλλοτρίων τὴν φωνήν.”
43010006 Ταύτην τὴν παροιμίαν εἶπεν αὐτοῖς ὁ ˚Ἰησοῦς, ἐκεῖνοι δὲ οὐκ ἔγνωσαν τίνα ἦν, ἃ ἐλάλει αὐτοῖς.
43010007 Εἶπεν οὖν αὐτοῖς πάλιν ὁ ˚Ἰησοῦς, “Ἀμὴν, ἀμὴν, λέγω ὑμῖν ὅτι ἐγώ εἰμι ἡ θύρα τῶν προβάτων.
43010008 Πάντες ὅσοι ἦλθον πρὸ ἐμοῦ κλέπται εἰσὶν καὶ λῃσταί, ἀλλʼ οὐκ ἤκουσαν αὐτῶν τὰ πρόβατα.
43010009 Ἐγώ εἰμι ἡ θύρα· διʼ ἐμοῦ ἐάν τις εἰσέλθῃ σωθήσεται, καὶ εἰσελεύσεται, καὶ ἐξελεύσεται, καὶ νομὴν εὑρήσει.
43010010 Ὁ κλέπτης οὐκ ἔρχεται, εἰ μὴ ἵνα κλέψῃ, καὶ θύσῃ, καὶ ἀπολέσῃ. Ἐγὼ ἦλθον, ἵνα ζωὴν ἔχωσιν, καὶ περισσὸν ἔχωσιν.
43010011 Ἐγώ εἰμι ὁ ποιμὴν ὁ καλός. Ὁ ποιμὴν ὁ καλὸς τὴν ψυχὴν αὐτοῦ τίθησιν ὑπὲρ τῶν προβάτων.
43010012 Ὁ μισθωτὸς καὶ οὐκ ὢν ποιμήν, οὗ οὐκ ἔστιν τὰ πρόβατα ἴδια, θεωρεῖ τὸν λύκον ἐρχόμενον, καὶ ἀφίησιν τὰ πρόβατα καὶ φεύγει, καὶ ὁ λύκος ἁρπάζει αὐτὰ καὶ σκορπίζει,
43010013 ὅτι μισθωτός ἐστιν καὶ οὐ μέλει αὐτῷ περὶ τῶν προβάτων.
43010014 Ἐγώ εἰμι ὁ ποιμὴν ὁ καλός, καὶ γινώσκω τὰ ἐμὰ, καὶ γινώσκουσί με τὰ ἐμά.
43010015 Καθὼς γινώσκει με ὁ Πατὴρ, κἀγὼ γινώσκω τὸν Πατέρα, καὶ τὴν ψυχήν μου τίθημι ὑπὲρ τῶν προβάτων.
43010016 Καὶ ἄλλα πρόβατα ἔχω, ἃ οὐκ ἔστιν ἐκ τῆς αὐλῆς ταύτης· κἀκεῖνα δεῖ με ἀγαγεῖν, καὶ τῆς φωνῆς μου ἀκούσουσιν, καὶ γενήσονται μία ποίμνη, εἷς ποιμήν.
43010017 Διὰ τοῦτό, με ὁ Πατὴρ ἀγαπᾷ, ὅτι ἐγὼ τίθημι τὴν ψυχήν μου, ἵνα πάλιν λάβω αὐτήν.
43010018 Οὐδεὶς ἦρεν αὐτὴν ἀπʼ ἐμοῦ, ἀλλʼ ἐγὼ τίθημι αὐτὴν ἀπʼ ἐμαυτοῦ. Ἐξουσίαν ἔχω θεῖναι αὐτήν, καὶ ἐξουσίαν ἔχω πάλιν λαβεῖν αὐτήν. Ταύτην τὴν ἐντολὴν ἔλαβον παρὰ τοῦ Πατρός μου.”
43010019 Σχίσμα πάλιν ἐγένετο ἐν τοῖς Ἰουδαίοις, διὰ τοὺς λόγους τούτους.
43010020 Ἔλεγον δὲ πολλοὶ ἐξ αὐτῶν, “Δαιμόνιον ἔχει, καὶ μαίνεται· τί αὐτοῦ ἀκούετε;”
43010021 Ἄλλοι ἔλεγον, “Ταῦτα τὰ ῥήματα οὐκ ἔστιν δαιμονιζομένου. Μὴ δαιμόνιον δύναται τυφλῶν ὀφθαλμοὺς ἀνοῖξαι;”
43010022 Ἐγένετο τότε τὰ ἐγκαίνια ἐν τοῖς Ἱεροσολύμοις. Χειμὼν ἦν,
43010023 καὶ περιεπάτει ὁ ˚Ἰησοῦς ἐν τῷ ἱερῷ, ἐν τῇ στοᾷ τοῦ Σολομῶνος.
43010024 Ἐκύκλωσαν οὖν αὐτὸν οἱ Ἰουδαῖοι, καὶ ἔλεγον αὐτῷ, “Ἕως πότε τὴν ψυχὴν ἡμῶν αἴρεις; Εἰ σὺ εἶ ὁ ˚Χριστός, εἰπὲ ἡμῖν παρρησίᾳ.”
43010025 Ἀπεκρίθη αὐτοῖς ὁ ˚Ἰησοῦς, “Εἶπον ὑμῖν καὶ οὐ πιστεύετε. Τὰ ἔργα ἃ ἐγὼ ποιῶ ἐν τῷ ὀνόματι τοῦ Πατρός μου, ταῦτα μαρτυρεῖ περὶ ἐμοῦ,
43010026 ἀλλὰ ὑμεῖς οὐ πιστεύετε, ὅτι οὐκ ἐστὲ ἐκ τῶν προβάτων τῶν ἐμῶν.
43010027 Τὰ πρόβατα τὰ ἐμὰ τῆς φωνῆς μου ἀκούουσιν, κἀγὼ γινώσκω αὐτά, καὶ ἀκολουθοῦσίν μοι,
43010028 κἀγὼ δίδωμι αὐτοῖς ζωὴν αἰώνιον, καὶ οὐ μὴ ἀπόλωνται, εἰς τὸν αἰῶνα, καὶ οὐχ ἁρπάσει τις αὐτὰ ἐκ τῆς χειρός μου.
43010029 Ὁ Πατήρ μου ὃς δέδωκέν μοι, πάντων μεῖζόν ἐστιν, καὶ οὐδεὶς δύναται ἁρπάζειν ἐκ τῆς χειρὸς τοῦ Πατρός.
43010030 Ἐγὼ καὶ ὁ Πατὴρ ἕν ἐσμεν.”
43010031 Ἐβάστασαν πάλιν λίθους οἱ Ἰουδαῖοι, ἵνα λιθάσωσιν αὐτόν.
43010032 Ἀπεκρίθη αὐτοῖς ὁ ˚Ἰησοῦς, “Πολλὰ ἔργα καλὰ ἔδειξα ὑμῖν ἐκ τοῦ Πατρός· μου διὰ ποῖον αὐτῶν ἔργον, ἐμὲ λιθάζετε;”
43010033 Ἀπεκρίθησαν αὐτῷ οἱ Ἰουδαῖοι, “Περὶ καλοῦ ἔργου οὐ λιθάζομέν σε, ἀλλὰ περὶ βλασφημίας, καὶ ὅτι σὺ, ἄνθρωπος ὢν, ποιεῖς σεαυτὸν ˚Θεόν.”
43010034 Ἀπεκρίθη αὐτοῖς ὁ ˚Ἰησοῦς, “Οὐκ ἔστιν γεγραμμένον ἐν τῷ νόμῳ ὑμῶν, ὅτι ‘Ἐγὼ εἶπα, “Θεοί ἐστε”’;
43010035 Εἰ ἐκείνους εἶπεν ‘θεοὺς’, πρὸς οὓς ὁ λόγος τοῦ ˚Θεοῦ ἐγένετο ( καὶ οὐ δύναται λυθῆναι ἡ Γραφή ),
43010036 ὃν ὁ Πατὴρ ἡγίασεν καὶ ἀπέστειλεν εἰς τὸν κόσμον, ὑμεῖς λέγετε, ὅτι ‘Βλασφημεῖς’, ὅτι εἶπον, ‘Υἱὸς τοῦ ˚Θεοῦ εἰμι’;
43010037 Εἰ οὐ ποιῶ τὰ ἔργα τοῦ Πατρός μου, μὴ πιστεύετέ μοι·
43010038 εἰ δὲ ποιῶ, κἂν ἐμοὶ μὴ πιστεύητε, τοῖς ἔργοις πιστεύετε, ἵνα γνῶτε καὶ γινώσκητε ὅτι ἐν ἐμοὶ ὁ Πατὴρ, κἀγὼ ἐν τῷ Πατρί.”
43010039 Ἐζήτουν οὖν αὐτὸν πάλιν πιάσαι, καὶ ἐξῆλθεν ἐκ τῆς χειρὸς αὐτῶν.
43010040 Καὶ ἀπῆλθεν πάλιν πέραν τοῦ Ἰορδάνου, εἰς τὸν τόπον ὅπου ἦν Ἰωάννης τὸ πρῶτον βαπτίζων, καὶ ἔμεινεν ἐκεῖ.
43010041 Καὶ πολλοὶ ἦλθον πρὸς αὐτὸν, καὶ ἔλεγον, ὅτι “Ἰωάννης μὲν σημεῖον ἐποίησεν οὐδέν, πάντα δὲ ὅσα εἶπεν Ἰωάννης περὶ τούτου ἀληθῆ ἦν.”
43010042 Καὶ πολλοὶ ἐπίστευσαν εἰς αὐτὸν ἐκεῖ.
43011001 Ἦν δέ τις ἀσθενῶν, Λάζαρος ἀπὸ Βηθανίας ἐκ τῆς κώμης Μαρίας, καὶ Μάρθας τῆς ἀδελφῆς αὐτῆς.
43011002 Ἦν δὲ Μαριὰμ ἡ ἀλείψασα τὸν ˚Κύριον μύρῳ, καὶ ἐκμάξασα τοὺς πόδας αὐτοῦ ταῖς θριξὶν αὐτῆς, ἧς ὁ ἀδελφὸς Λάζαρος ἠσθένει.
43011003 Ἀπέστειλαν οὖν αἱ ἀδελφαὶ πρὸς αὐτὸν λέγουσαι, “˚Κύριε, ἴδε, ὃν φιλεῖς ἀσθενεῖ.”
43011004 Ἀκούσας δὲ ὁ ˚Ἰησοῦς εἶπεν, “Αὕτη ἡ ἀσθένεια οὐκ ἔστιν πρὸς θάνατον, ἀλλʼ ὑπὲρ τῆς δόξης τοῦ ˚Θεοῦ, ἵνα δοξασθῇ ὁ Υἱὸς τοῦ ˚Θεοῦ διʼ αὐτῆς.”
43011005 Ἠγάπα δὲ ὁ ˚Ἰησοῦς τὴν Μάρθαν, καὶ τὴν ἀδελφὴν αὐτῆς, καὶ τὸν Λάζαρον.
43011006 Ὡς οὖν ἤκουσεν ὅτι ἀσθενεῖ, τότε μὲν ἔμεινεν ἐν ᾧ ἦν τόπῳ δύο ἡμέρας.
43011007 Ἔπειτα μετὰ τοῦτο λέγει τοῖς μαθηταῖς, “Ἄγωμεν εἰς τὴν Ἰουδαίαν πάλιν.”
43011008 Λέγουσιν αὐτῷ οἱ μαθηταί, “Ῥαββί, νῦν ἐζήτουν σε λιθάσαι οἱ Ἰουδαῖοι, καὶ πάλιν ὑπάγεις ἐκεῖ;”
43011009 Ἀπεκρίθη ˚Ἰησοῦς, “Οὐχὶ δώδεκα ὧραί εἰσιν τῆς ἡμέρας; Ἐάν τις περιπατῇ ἐν τῇ ἡμέρᾳ, οὐ προσκόπτει, ὅτι τὸ φῶς τοῦ κόσμου τούτου βλέπει.
43011010 Ἐὰν δέ τις περιπατῇ ἐν τῇ νυκτί, προσκόπτει, ὅτι τὸ φῶς οὐκ ἔστιν ἐν αὐτῷ.”
43011011 Ταῦτα εἶπεν, καὶ μετὰ τοῦτο λέγει αὐτοῖς, “Λάζαρος ὁ φίλος ἡμῶν κεκοίμηται, ἀλλὰ πορεύομαι, ἵνα ἐξυπνίσω αὐτόν.”
43011012 Εἶπον οὖν οἱ μαθηταὶ αὐτῷ, “˚Κύριε, εἰ κεκοίμηται σωθήσεται.”
43011013 Εἰρήκει δὲ ὁ ˚Ἰησοῦς περὶ τοῦ θανάτου αὐτοῦ. Ἐκεῖνοι δὲ ἔδοξαν ὅτι περὶ τῆς κοιμήσεως τοῦ ὕπνου λέγει.
43011014 Τότε οὖν εἶπεν αὐτοῖς ὁ ˚Ἰησοῦς παρρησίᾳ, “Λάζαρος ἀπέθανεν,
43011015 καὶ χαίρω διʼ ὑμᾶς, ἵνα πιστεύσητε ὅτι οὐκ ἤμην ἐκεῖ· ἀλλὰ ἄγωμεν πρὸς αὐτόν.”
43011016 Εἶπεν οὖν Θωμᾶς, ὁ λεγόμενος Δίδυμος τοῖς συμμαθηταῖς, “Ἄγωμεν καὶ ἡμεῖς, ἵνα ἀποθάνωμεν μετʼ αὐτοῦ.”
43011017 Ἐλθὼν οὖν, ὁ ˚Ἰησοῦς εὗρεν αὐτὸν, τέσσαρας ἤδη ἡμέρας ἔχοντα ἐν τῷ μνημείῳ.
43011018 Ἦν δὲ Βηθανία ἐγγὺς τῶν Ἱεροσολύμων, ὡς ἀπὸ σταδίων δεκαπέντε.
43011019 Πολλοὶ δὲ ἐκ τῶν Ἰουδαίων ἐληλύθεισαν πρὸς τὴν Μάρθαν καὶ Μαριὰμ, ἵνα παραμυθήσωνται αὐτὰς περὶ τοῦ ἀδελφοῦ.
43011020 Ἡ οὖν Μάρθα ὡς, ἤκουσεν ὅτι ˚Ἰησοῦς ἔρχεται, ὑπήντησεν αὐτῷ· Μαρία δὲ ἐν τῷ οἴκῳ ἐκαθέζετο.
43011021 Εἶπεν οὖν ἡ Μάρθα πρὸς τὸν ˚Ἰησοῦν, “˚Κύριε, εἰ ἦς ὧδε, οὐκ ἂν ἀπέθανεν ὁ ἀδελφός μου.
43011022 καὶ νῦν οἶδα ὅτι ὅσα ἂν αἰτήσῃ τὸν ˚Θεὸν, δώσει σοι ὁ ˚Θεός.”
43011023 Λέγει αὐτῇ ὁ ˚Ἰησοῦς, “Ἀναστήσεται ὁ ἀδελφός σου.”
43011024 Λέγει αὐτῷ Μάρθα, “Οἶδα ὅτι ἀναστήσεται ἐν τῇ ἀναστάσει ἐν τῇ ἐσχάτῃ ἡμέρᾳ.”
43011025 Εἶπεν αὐτῇ ὁ ˚Ἰησοῦς, “Ἐγώ εἰμι ἡ ἀνάστασις καὶ ἡ ζωή· ὁ πιστεύων εἰς ἐμὲ, κἂν ἀποθάνῃ, ζήσεται,
43011026 καὶ πᾶς ὁ ζῶν καὶ πιστεύων εἰς ἐμὲ, οὐ μὴ ἀποθάνῃ εἰς τὸν αἰῶνα. Πιστεύεις τοῦτο;”
43011027 Λέγει αὐτῷ, “Ναί, ˚Κύριε· ἐγὼ πεπίστευκα ὅτι σὺ εἶ ὁ ˚Χριστὸς, ὁ Υἱὸς τοῦ ˚Θεοῦ, ὁ εἰς τὸν κόσμον ἐρχόμενος.”
43011028 Καὶ τοῦτο εἰποῦσα ἀπῆλθεν, καὶ ἐφώνησεν Μαριὰμ, τὴν ἀδελφὴν αὐτῆς λάθρᾳ, εἰποῦσα, “Ὁ διδάσκαλος πάρεστιν, καὶ φωνεῖ σε.”
43011029 Ἐκείνη δὲ ὡς ἤκουσεν, ἠγέρθη ταχὺ καὶ ἤρχετο πρὸς αὐτόν.
43011030 Οὔπω δὲ ἐληλύθει ὁ ˚Ἰησοῦς εἰς τὴν κώμην, ἀλλʼ ἦν ἔτι ἐν τῷ τόπῳ ὅπου ὑπήντησεν αὐτῷ ἡ Μάρθα.
43011031 Οἱ οὖν Ἰουδαῖοι οἱ ὄντες μετʼ αὐτῆς ἐν τῇ οἰκίᾳ καὶ παραμυθούμενοι αὐτήν, ἰδόντες τὴν Μαριὰμ ὅτι ταχέως ἀνέστη καὶ ἐξῆλθεν, ἠκολούθησαν αὐτῇ, δόξαντες ὅτι ὑπάγει εἰς τὸ μνημεῖον, ἵνα κλαύσῃ ἐκεῖ.
43011032 Ἡ οὖν Μαριὰμ, ὡς ἦλθεν ὅπου ἦν ˚Ἰησοῦς, ἰδοῦσα αὐτὸν, ἔπεσεν αὐτοῦ πρὸς τοὺς πόδας, λέγουσα αὐτῷ, ˚Κύριε, εἰ ἦς ὧδε, οὐκ ἄν μου ἀπέθανεν ὁ ἀδελφός.
43011033 ˚Ἰησοῦς οὖν ὡς εἶδεν αὐτὴν κλαίουσαν, καὶ τοὺς συνελθόντας αὐτῇ Ἰουδαίους κλαίοντας, ἐνεβριμήσατο τῷ πνεύματι καὶ ἐτάραξεν ἑαυτόν.
43011034 Καὶ εἶπεν, “Ποῦ τεθείκατε αὐτόν;” Λέγουσιν αὐτῷ, “˚Κύριε, ἔρχου καὶ ἴδε.”
43011035 Ἐδάκρυσεν ὁ ˚Ἰησοῦς.
43011036 Ἔλεγον οὖν οἱ Ἰουδαῖοι, “Ἴδε, πῶς ἐφίλει αὐτόν!”
43011037 Τινὲς δὲ ἐξ αὐτῶν εἶπον, “Οὐκ ἐδύνατο οὗτος, ὁ ἀνοίξας τοὺς ὀφθαλμοὺς τοῦ τυφλοῦ, ποιῆσαι ἵνα καὶ οὗτος μὴ ἀποθάνῃ;”
43011038 ˚Ἰησοῦς οὖν πάλιν ἐμβριμώμενος ἐν ἑαυτῷ, ἔρχεται εἰς τὸ μνημεῖον. Ἦν δὲ σπήλαιον, καὶ λίθος ἐπέκειτο ἐπʼ αὐτῷ.
43011039 Λέγει ὁ ˚Ἰησοῦς, “Ἄρατε τὸν λίθον.” Λέγει αὐτῷ ἡ ἀδελφὴ τοῦ τετελευτηκότος Μάρθα, “˚Κύριε, ἤδη ὄζει, τεταρταῖος γάρ ἐστιν.”
43011040 Λέγει αὐτῇ ὁ ˚Ἰησοῦς, “Οὐκ εἶπόν σοι ὅτι ἐὰν πιστεύσῃς, ὄψῃ τὴν δόξαν τοῦ ˚Θεοῦ;”
43011041 Ἦραν οὖν τὸν λίθον. Ὁ δὲ ˚Ἰησοῦς ἦρεν τοὺς ὀφθαλμοὺς ἄνω, καὶ εἶπεν, “Πάτερ, εὐχαριστῶ σοι ὅτι ἤκουσάς μου.
43011042 Ἐγὼ δὲ ᾔδειν ὅτι πάντοτέ μου ἀκούεις, ἀλλὰ διὰ τὸν ὄχλον τὸν περιεστῶτα, εἶπον, ἵνα πιστεύσωσιν ὅτι σύ με ἀπέστειλας.”
43011043 Καὶ ταῦτα εἰπὼν, φωνῇ μεγάλῃ ἐκραύγασεν, “Λάζαρε, δεῦρο ἔξω.”
43011044 Ἐξῆλθεν ὁ τεθνηκὼς, δεδεμένος τοὺς πόδας καὶ τὰς χεῖρας κειρίαις, καὶ ἡ ὄψις αὐτοῦ σουδαρίῳ περιεδέδετο. Λέγει αὐτοῖς ὁ ˚Ἰησοῦς, “Λύσατε αὐτὸν καὶ ἄφετε αὐτὸν ὑπάγειν.”
43011045 Πολλοὶ οὖν ἐκ τῶν Ἰουδαίων, οἱ ἐλθόντες πρὸς τὴν Μαριὰμ, καὶ θεασάμενοι ἃ ἐποίησεν, ἐπίστευσαν εἰς αὐτόν.
43011046 Τινὲς δὲ ἐξ αὐτῶν ἀπῆλθον πρὸς τοὺς Φαρισαίους, καὶ εἶπον αὐτοῖς ἃ ἐποίησεν ˚Ἰησοῦς.
43011047 Συνήγαγον οὖν οἱ ἀρχιερεῖς καὶ οἱ Φαρισαῖοι Συνέδριον, καὶ ἔλεγον, “Τί ποιοῦμεν, ὅτι οὗτος ὁ ἄνθρωπος πολλὰ ποιεῖ σημεῖα;
43011048 Ἐὰν ἀφῶμεν αὐτὸν οὕτως, πάντες πιστεύσουσιν εἰς αὐτόν, καὶ ἐλεύσονται οἱ Ῥωμαῖοι, καὶ ἀροῦσιν ἡμῶν καὶ τὸν τόπον καὶ τὸ ἔθνος.”
43011049 Εἷς δέ τις ἐξ αὐτῶν, Καϊάφας ἀρχιερεὺς ὢν τοῦ ἐνιαυτοῦ ἐκείνου, εἶπεν αὐτοῖς, “Ὑμεῖς οὐκ οἴδατε οὐδέν!
43011050 Οὐδὲ λογίζεσθε ὅτι συμφέρει ὑμῖν ἵνα εἷς ἄνθρωπος ἀποθάνῃ ὑπὲρ τοῦ λαοῦ, καὶ μὴ ὅλον τὸ ἔθνος ἀπόληται.”
43011051 Τοῦτο δὲ ἀφʼ ἑαυτοῦ οὐκ εἶπεν, ἀλλὰ ἀρχιερεὺς ὢν τοῦ ἐνιαυτοῦ ἐκείνου, ἐπροφήτευσεν ὅτι ἤμελλεν ˚Ἰησοῦς ἀποθνῄσκειν ὑπὲρ τοῦ ἔθνους,
43011052 καὶ οὐχ ὑπὲρ τοῦ ἔθνους μόνον, ἀλλʼ ἵνα καὶ τὰ τέκνα τοῦ ˚Θεοῦ, τὰ διεσκορπισμένα συναγάγῃ εἰς ἕν.
43011053 Ἀπʼ ἐκείνης οὖν τῆς ἡμέρας, ἐβουλεύσαντο ἵνα ἀποκτείνωσιν αὐτόν.
43011054 Ὁ οὖν ˚Ἰησοῦς οὐκέτι παρρησίᾳ περιεπάτει ἐν τοῖς Ἰουδαίοις, ἀλλὰ ἀπῆλθεν ἐκεῖθεν εἰς τὴν χώραν ἐγγὺς τῆς ἐρήμου, εἰς Ἐφραὶμ λεγομένην πόλιν, κἀκεῖ ἔμεινεν μετὰ τῶν μαθητῶν.
43011055 Ἦν δὲ ἐγγὺς τὸ Πάσχα τῶν Ἰουδαίων, καὶ ἀνέβησαν πολλοὶ εἰς Ἱεροσόλυμα ἐκ τῆς χώρας πρὸ τοῦ Πάσχα, ἵνα ἁγνίσωσιν ἑαυτούς.
43011056 Ἐζήτουν οὖν τὸν ˚Ἰησοῦν, καὶ ἔλεγον μετʼ ἀλλήλων ἐν τῷ ἱερῷ ἑστηκότες, “Τί δοκεῖ ὑμῖν; Ὅτι οὐ μὴ ἔλθῃ εἰς τὴν ἑορτήν;”
43011057 Δεδώκεισαν δὲ οἱ ἀρχιερεῖς καὶ οἱ Φαρισαῖοι ἐντολὰς, ἵνα ἐάν τις γνῷ ποῦ ἐστιν, μηνύσῃ, ὅπως πιάσωσιν αὐτόν.
43012001 Ὁ οὖν ˚Ἰησοῦς πρὸ ἓξ ἡμερῶν τοῦ Πάσχα, ἦλθεν εἰς Βηθανίαν, ὅπου ἦν Λάζαρος, ὃν ἤγειρεν ἐκ νεκρῶν ˚Ἰησοῦς.
43012002 Ἐποίησαν οὖν αὐτῷ δεῖπνον ἐκεῖ, καὶ ἡ Μάρθα διηκόνει, ὁ δὲ Λάζαρος εἷς ἦν ἐκ τῶν ἀνακειμένων σὺν αὐτῷ.
43012003 Ἡ οὖν Μαρία λαβοῦσα λίτραν μύρου νάρδου πιστικῆς πολυτίμου, ἤλειψεν τοὺς πόδας τοῦ ˚Ἰησοῦ, καὶ ἐξέμαξεν ταῖς θριξὶν αὐτῆς τοὺς πόδας αὐτοῦ· ἡ δὲ οἰκία ἐπληρώθη ἐκ τῆς ὀσμῆς τοῦ μύρου.
43012004 Λέγει δὲ Ἰούδας ὁ Ἰσκαριώτης, εἷς τῶν μαθητῶν αὐτοῦ, ὁ μέλλων αὐτὸν παραδιδόναι,
43012005 “Διὰ τί τοῦτο τὸ μύρον οὐκ ἐπράθη τριακοσίων δηναρίων, καὶ ἐδόθη πτωχοῖς;”
43012006 Εἶπεν δὲ τοῦτο, οὐχ ὅτι περὶ τῶν πτωχῶν ἔμελεν αὐτῷ, ἀλλʼ ὅτι κλέπτης ἦν, καὶ τὸ γλωσσόκομον ἔχων τὰ βαλλόμενα ἐβάσταζεν.
43012007 Εἶπεν οὖν ὁ ˚Ἰησοῦς, “Ἄφες αὐτήν, ἵνα εἰς τὴν ἡμέραν τοῦ ἐνταφιασμοῦ μου, τηρήσῃ αὐτό.
43012008 Τοὺς πτωχοὺς γὰρ πάντοτε ἔχετε μεθʼ ἑαυτῶν, ἐμὲ δὲ οὐ πάντοτε ἔχετε.”
43012009 Ἔγνω οὖν ὁ ὄχλος πολὺς ἐκ τῶν Ἰουδαίων ὅτι ἐκεῖ ἐστιν, καὶ ἦλθον, οὐ διὰ τὸν ˚Ἰησοῦν μόνον, ἀλλʼ ἵνα καὶ τὸν Λάζαρον ἴδωσιν, ὃν ἤγειρεν ἐκ νεκρῶν.
43012010 Ἐβουλεύσαντο δὲ οἱ ἀρχιερεῖς ἵνα καὶ τὸν Λάζαρον ἀποκτείνωσιν,
43012011 ὅτι πολλοὶ διʼ αὐτὸν ὑπῆγον τῶν Ἰουδαίων, καὶ ἐπίστευον εἰς τὸν ˚Ἰησοῦν.
43012012 Τῇ ἐπαύριον ὁ ὄχλος πολὺς, ὁ ἐλθὼν εἰς τὴν ἑορτήν, ἀκούσαντες ὅτι ἔρχεται ὁ ˚Ἰησοῦς εἰς Ἱεροσόλυμα,
43012013 ἔλαβον τὰ βαΐα τῶν φοινίκων καὶ ἐξῆλθον εἰς ὑπάντησιν αὐτῷ, καὶ ἐκραύγαζον, “‘Ὡσαννά! Εὐλογημένος ὁ ἐρχόμενος ἐν ὀνόματι ˚Κυρίου’, καὶ ὁ Βασιλεὺς τοῦ Ἰσραήλ!”
43012014 Εὑρὼν δὲ ὁ ˚Ἰησοῦς ὀνάριον, ἐκάθισεν ἐπʼ αὐτό, καθώς ἐστιν γεγραμμένον,
43012015 “Μὴ φοβοῦ, θυγάτηρ Σιών· ἰδοὺ, ὁ βασιλεύς σου ἔρχεται, καθήμενος ἐπὶ πῶλον ὄνου.”
43012016 Ταῦτα οὐκ ἔγνωσαν αὐτοῦ οἱ μαθηταὶ τὸ πρῶτον, ἀλλʼ ὅτε ἐδοξάσθη ˚Ἰησοῦς, τότε ἐμνήσθησαν ὅτι ταῦτα ἦν ἐπʼ αὐτῷ γεγραμμένα, καὶ ταῦτα ἐποίησαν αὐτῷ.
43012017 Ἐμαρτύρει οὖν ὁ ὄχλος ὁ ὢν μετʼ αὐτοῦ, ὅτε τὸν Λάζαρον ἐφώνησεν ἐκ τοῦ μνημείου, καὶ ἤγειρεν αὐτὸν ἐκ νεκρῶν.
43012018 Διὰ τοῦτο καὶ ὑπήντησεν αὐτῷ ὁ ὄχλος, ὅτι ἤκουσαν τοῦτο αὐτὸν πεποιηκέναι τὸ σημεῖον.
43012019 Οἱ οὖν Φαρισαῖοι εἶπαν πρὸς ἑαυτούς, “Θεωρεῖτε ὅτι οὐκ ὠφελεῖτε οὐδέν· ἴδε, ὁ κόσμος ὀπίσω αὐτοῦ ἀπῆλθεν.”
43012020 Ἦσαν δὲ Ἕλληνές τινες ἐκ τῶν ἀναβαινόντων, ἵνα προσκυνήσωσιν ἐν τῇ ἑορτῇ·
43012021 οὗτοι οὖν προσῆλθον Φιλίππῳ, τῷ ἀπὸ Βηθσαϊδὰ τῆς Γαλιλαίας, καὶ ἠρώτων αὐτὸν λέγοντες, “Κύριε, θέλομεν τὸν ˚Ἰησοῦν ἰδεῖν.”
43012022 Ἔρχεται ὁ Φίλιππος καὶ λέγει τῷ Ἀνδρέᾳ, ἔρχεται Ἀνδρέας καὶ Φίλιππος καὶ λέγουσιν τῷ ˚Ἰησοῦ.
43012023 Ὁ δὲ ˚Ἰησοῦς ἀποκρίνεται αὐτοῖς λέγων, “Ἐλήλυθεν ἡ ὥρα, ἵνα δοξασθῇ ὁ Υἱὸς τοῦ Ἀνθρώπου.
43012024 Ἀμὴν, ἀμὴν, λέγω ὑμῖν, ἐὰν μὴ ὁ κόκκος τοῦ σίτου πεσὼν εἰς τὴν γῆν ἀποθάνῃ, αὐτὸς μόνος μένει· ἐὰν δὲ ἀποθάνῃ, πολὺν καρπὸν φέρει.
43012025 Ὁ φιλῶν τὴν ψυχὴν αὐτοῦ, ἀπολλύει αὐτήν, καὶ ὁ μισῶν τὴν ψυχὴν αὐτοῦ ἐν τῷ κόσμῳ τούτῳ, εἰς ζωὴν αἰώνιον φυλάξει αὐτήν.
43012026 Ἐὰν ἐμοί τις διακονῇ, ἐμοὶ ἀκολουθείτω, καὶ ὅπου εἰμὶ ἐγὼ, ἐκεῖ καὶ ὁ διάκονος ὁ ἐμὸς ἔσται. Ἐάν τις ἐμοὶ διακονῇ, τιμήσει αὐτὸν ὁ Πατήρ.
43012027 Νῦν ἡ ψυχή μου τετάρακται, καὶ τί εἴπω, ‘Πάτερ, σῶσόν με ἐκ τῆς ὥρας ταύτης’; Ἀλλὰ διὰ τοῦτο, ἦλθον εἰς τὴν ὥραν ταύτην.
43012028 Πάτερ, δόξασόν σου τὸ ὄνομα.” Ἦλθεν οὖν φωνὴ ἐκ τοῦ οὐρανοῦ: “Καὶ ἐδόξασα, καὶ πάλιν δοξάσω.”
43012029 Ὁ οὖν ὄχλος ὁ ἑστὼς καὶ ἀκούσας, ἔλεγεν βροντὴν γεγονέναι. Ἄλλοι ἔλεγον, “Ἄγγελος αὐτῷ λελάληκεν.”
43012030 Ἀπεκρίθη καὶ εἶπεν, ˚Ἰησοῦς “Οὐ διʼ ἐμὲ, ἡ φωνὴ αὕτη γέγονεν, ἀλλὰ διʼ ὑμᾶς.
43012031 Νῦν κρίσις ἐστὶν τοῦ κόσμου τούτου, νῦν ὁ ἄρχων τοῦ κόσμου τούτου ἐκβληθήσεται ἔξω.
43012032 Κἀγὼ ἐὰν ὑψωθῶ ἐκ τῆς γῆς, πάντας ἑλκύσω πρὸς ἐμαυτόν.”
43012033 Τοῦτο δὲ ἔλεγεν, σημαίνων ποίῳ θανάτῳ ἤμελλεν ἀποθνῄσκειν.
43012034 Ἀπεκρίθη οὖν αὐτῷ ὁ ὄχλος, “Ἡμεῖς ἠκούσαμεν ἐκ τοῦ νόμου ὅτι ὁ ˚Χριστὸς μένει εἰς τὸν αἰῶνα, καὶ πῶς λέγεις σὺ ὅτι δεῖ ὑψωθῆναι τὸν Υἱὸν τοῦ Ἀνθρώπου; Τίς ἐστιν οὗτος ὁ Υἱὸς τοῦ Ἀνθρώπου;”
43012035 Εἶπεν οὖν αὐτοῖς ὁ ˚Ἰησοῦς, “Ἔτι μικρὸν χρόνον, τὸ φῶς ἐν ὑμῖν ἐστιν. Περιπατεῖτε ὡς τὸ φῶς ἔχετε, ἵνα μὴ σκοτία ὑμᾶς καταλάβῃ, καὶ ὁ περιπατῶν ἐν τῇ σκοτίᾳ, οὐκ οἶδεν ποῦ ὑπάγει.
43012036 Ὡς τὸ φῶς ἔχετε, πιστεύετε εἰς τὸ φῶς, ἵνα υἱοὶ φωτὸς γένησθε.” Ταῦτα ἐλάλησεν ˚Ἰησοῦς, καὶ ἀπελθὼν, ἐκρύβη ἀπʼ αὐτῶν.
43012037 Τοσαῦτα δὲ αὐτοῦ σημεῖα πεποιηκότος ἔμπροσθεν αὐτῶν, οὐκ ἐπίστευον εἰς αὐτόν,
43012038 ἵνα ὁ λόγος Ἠσαΐου τοῦ προφήτου πληρωθῇ ὃν εἶπεν, “˚Κύριε, τίς ἐπίστευσεν τῇ ἀκοῇ ἡμῶν; Καὶ ὁ βραχίων ˚Κυρίου τίνι ἀπεκαλύφθη;”
43012039 Διὰ τοῦτο οὐκ ἠδύναντο πιστεύειν, ὅτι πάλιν εἶπεν Ἠσαΐας,
43012040 “Τετύφλωκεν αὐτῶν τοὺς ὀφθαλμοὺς καὶ ἐπώρωσεν αὐτῶν τὴν καρδίαν, ἵνα μὴ ἴδωσιν τοῖς ὀφθαλμοῖς καὶ νοήσωσιν τῇ καρδίᾳ, καὶ στραφῶσιν καὶ ἰάσομαι αὐτούς.”
43012041 Ταῦτα εἶπεν Ἠσαΐας, ὅτι εἶδεν τὴν δόξαν αὐτοῦ, καὶ ἐλάλησεν περὶ αὐτοῦ.
43012042 Ὅμως μέντοι καὶ ἐκ τῶν ἀρχόντων πολλοὶ ἐπίστευσαν εἰς αὐτόν, ἀλλὰ διὰ τοὺς Φαρισαίους οὐχ ὡμολόγουν, ἵνα μὴ ἀποσυνάγωγοι γένωνται.
43012043 Ἠγάπησαν γὰρ τὴν δόξαν τῶν ἀνθρώπων μᾶλλον ἤπερ τὴν δόξαν τοῦ ˚Θεοῦ.
43012044 ˚Ἰησοῦς δὲ ἔκραξεν καὶ εἶπεν, “Ὁ πιστεύων εἰς ἐμὲ, οὐ πιστεύει εἰς ἐμὲ, ἀλλὰ εἰς τὸν πέμψαντά με,
43012045 καὶ ὁ θεωρῶν ἐμὲ, θεωρεῖ τὸν πέμψαντά με.
43012046 Ἐγὼ φῶς εἰς τὸν κόσμον ἐλήλυθα, ἵνα πᾶς ὁ πιστεύων εἰς ἐμὲ, ἐν τῇ σκοτίᾳ μὴ μείνῃ.
43012047 Καὶ ἐάν τίς μου ἀκούσῃ τῶν ῥημάτων, καὶ μὴ φυλάξῃ, ἐγὼ οὐ κρίνω αὐτόν, οὐ γὰρ ἦλθον, ἵνα κρίνω τὸν κόσμον, ἀλλʼ ἵνα σώσω τὸν κόσμον.
43012048 Ὁ ἀθετῶν ἐμὲ καὶ μὴ λαμβάνων τὰ ῥήματά μου, ἔχει τὸν κρίνοντα αὐτόν· ὁ λόγος ὃν ἐλάλησα, ἐκεῖνος κρινεῖ αὐτὸν ἐν τῇ ἐσχάτῃ ἡμέρᾳ.
43012049 Ὅτι ἐγὼ ἐξ ἐμαυτοῦ οὐκ ἐλάλησα, ἀλλʼ ὁ πέμψας με Πατὴρ αὐτός μοι ἐντολὴν δέδωκεν– τί εἴπω, καὶ τί λαλήσω.
43012050 Καὶ οἶδα ὅτι ἡ ἐντολὴ αὐτοῦ ζωὴ αἰώνιός ἐστιν. Ἃ οὖν ἐγὼ λαλῶ, καθὼς εἴρηκέν μοι ὁ Πατήρ, οὕτως λαλῶ.”
43013001 Πρὸ δὲ τῆς ἑορτῆς τοῦ Πάσχα, εἰδὼς ὁ ˚Ἰησοῦς ὅτι ἦλθεν αὐτοῦ ἡ ὥρα, ἵνα μεταβῇ ἐκ τοῦ κόσμου τούτου πρὸς τὸν Πατέρα, ἀγαπήσας τοὺς ἰδίους τοὺς ἐν τῷ κόσμῳ, εἰς τέλος ἠγάπησεν αὐτούς.
43013002 Καὶ δείπνου γινομένου, τοῦ διαβόλου ἤδη βεβληκότος εἰς τὴν καρδίαν ἵνα παραδῷ αὐτὸν Ἰούδας Σίμωνος Ἰσκαριώτης,
43013003 εἰδὼς ὅτι πάντα δέδωκεν αὐτῷ ὁ Πατὴρ εἰς τὰς χεῖρας, καὶ ὅτι ἀπὸ ˚Θεοῦ ἐξῆλθεν καὶ πρὸς τὸν ˚Θεὸν ὑπάγει,
43013004 ἐγείρεται ἐκ τοῦ δείπνου καὶ τίθησιν τὰ ἱμάτια, καὶ λαβὼν λέντιον διέζωσεν ἑαυτόν.
43013005 Εἶτα βάλλει ὕδωρ εἰς τὸν νιπτῆρα, καὶ ἤρξατο νίπτειν τοὺς πόδας τῶν μαθητῶν, καὶ ἐκμάσσειν τῷ λεντίῳ ᾧ ἦν διεζωσμένος.
43013006 Ἔρχεται οὖν πρὸς Σίμωνα Πέτρον. Λέγει αὐτῷ, “˚Κύριε, σύ μου νίπτεις τοὺς πόδας;”
43013007 Ἀπεκρίθη ˚Ἰησοῦς καὶ εἶπεν αὐτῷ, “Ὃ ἐγὼ ποιῶ, σὺ οὐκ οἶδας ἄρτι, γνώσῃ δὲ μετὰ ταῦτα.”
43013008 Λέγει αὐτῷ Πέτρος, “Οὐ μὴ νίψῃς μου τοὺς πόδας εἰς τὸν αἰῶνα.” Ἀπεκρίθη ˚Ἰησοῦς αὐτῷ, “Ἐὰν μὴ νίψω σε, οὐκ ἔχεις μέρος μετʼ ἐμοῦ.”
43013009 Λέγει αὐτῷ Σίμων Πέτρος, “˚Κύριε, μὴ τοὺς πόδας μου μόνον, ἀλλὰ καὶ τὰς χεῖρας καὶ τὴν κεφαλήν.”
43013010 Λέγει αὐτῷ ὁ ˚Ἰησοῦς, “Ὁ λελουμένος οὐκ ἔχει χρείαν, εἰ μὴ τοὺς πόδας νίψασθαι, ἀλλʼ ἔστιν καθαρὸς ὅλος· καὶ ὑμεῖς καθαροί ἐστε, ἀλλʼ οὐχὶ πάντες.”
43013011 Ἤιδει γὰρ τὸν παραδιδόντα αὐτόν· διὰ τοῦτο εἶπεν, ὅτι “Οὐχὶ πάντες καθαροί ἐστε.”
43013012 Ὅτε οὖν ἔνιψεν τοὺς πόδας αὐτῶν, καὶ ἔλαβεν τὰ ἱμάτια αὐτοῦ, καὶ ἀνέπεσεν, πάλιν εἶπεν αὐτοῖς, “Γινώσκετε τί πεποίηκα ὑμῖν;
43013013 Ὑμεῖς φωνεῖτέ με ὁ ‘Διδάσκαλος’ καὶ, ὁ ‘˚Κύριος’, καὶ καλῶς λέγετε, εἰμὶ γάρ.
43013014 Εἰ οὖν ἐγὼ ἔνιψα ὑμῶν τοὺς πόδας, ὁ ˚Κύριος καὶ ὁ Διδάσκαλος, καὶ ὑμεῖς ὀφείλετε ἀλλήλων νίπτειν τοὺς πόδας.
43013015 Ὑπόδειγμα γὰρ ἔδωκα ὑμῖν ἵνα καθὼς ἐγὼ ἐποίησα ὑμῖν, καὶ ὑμεῖς ποιῆτε.
43013016 Ἀμὴν, ἀμὴν, λέγω ὑμῖν, οὐκ ἔστιν δοῦλος μείζων τοῦ κυρίου αὐτοῦ, οὐδὲ ἀπόστολος μείζων τοῦ πέμψαντος αὐτόν.
43013017 Εἰ ταῦτα οἴδατε, μακάριοί ἐστε ἐὰν ποιῆτε αὐτά.
43013018 Οὐ περὶ πάντων ὑμῶν λέγω. Ἐγὼ οἶδα οὓς ἐξελεξάμην, ἀλλʼ ἵνα ἡ Γραφὴ πληρωθῇ, ‘Ὁ τρώγων μετʼ ἐμοῦ τὸν ἄρτον, ἐπῆρεν ἐπʼ ἐμὲ τὴν πτέρναν αὐτοῦ.’
43013019 Ἀπʼ ἄρτι λέγω ὑμῖν πρὸ τοῦ γενέσθαι, ἵνα πιστεύσητε ὅταν γένηται ὅτι ἐγώ εἰμι.
43013020 Ἀμὴν, ἀμὴν, λέγω ὑμῖν, ὁ λαμβάνων ἄν τινα πέμψω, ἐμὲ λαμβάνει, ὁ δὲ ἐμὲ λαμβάνων, λαμβάνει τὸν πέμψαντά με.”
43013021 Ταῦτα εἰπὼν, ὁ ˚Ἰησοῦς ἐταράχθη τῷ πνεύματι καὶ ἐμαρτύρησεν καὶ εἶπεν, “Ἀμὴν, ἀμὴν, λέγω ὑμῖν ὅτι εἷς ἐξ ὑμῶν παραδώσει με.”
43013022 Ἔβλεπον οὖν εἰς ἀλλήλους οἱ μαθηταὶ, ἀπορούμενοι περὶ τίνος λέγει.
43013023 Ἦν δὲ ἀνακείμενος εἷς ἐκ τῶν μαθητῶν αὐτοῦ ἐν τῷ κόλπῳ τοῦ ˚Ἰησοῦ, ὃν ἠγάπα ὁ ˚Ἰησοῦς.
43013024 Νεύει οὖν τούτῳ Σίμων Πέτρος, πυθέσθαι τίς ἂν εἴη περὶ οὗ λέγει.
43013025 ἀναπεσὼν οὖν ἐκεῖνος οὕτως ἐπὶ τὸ στῆθος τοῦ ˚Ἰησοῦ, λέγει αὐτῷ, “˚Κύριε, τίς ἐστιν;”
43013026 Ἀποκρίνεται ὁ ˚Ἰησοῦς, “Ἐκεῖνός ἐστιν ᾧ ἐγὼ, βάψας τὸ ψωμίον δώσω.” Καὶ βάψας τὸ ψωμίον, δίδωσιν Ἰούδᾳ Σίμωνος Ἰσκαριώτου.
43013027 Καὶ μετὰ τὸ ψωμίον, τότε εἰσῆλθεν εἰς ἐκεῖνον ὁ Σατανᾶς. Λέγει οὖν αὐτῷ ὁ ˚Ἰησοῦς, “Ὃ ποιεῖς, ποίησον τάχιον.”
43013028 Τοῦτο δὲ οὐδεὶς ἔγνω τῶν ἀνακειμένων, πρὸς τί εἶπεν αὐτῷ.
43013029 Τινὲς γὰρ ἐδόκουν, ἐπεὶ τὸ γλωσσόκομον εἶχεν Ἰούδας, ὅτι λέγει αὐτῷ ὁ ˚Ἰησοῦς, “Ἀγόρασον ὧν χρείαν ἔχομεν εἰς τὴν ἑορτήν”, ἢ τοῖς πτωχοῖς, ἵνα τι δῷ.
43013030 Λαβὼν οὖν τὸ ψωμίον, ἐκεῖνος ἐξῆλθεν εὐθύς. Ἦν δὲ νύξ.
43013031 Ὅτε οὖν ἐξῆλθεν, λέγει ˚Ἰησοῦς, “Νῦν ἐδοξάσθη ὁ Υἱὸς τοῦ Ἀνθρώπου, καὶ ὁ ˚Θεὸς ἐδοξάσθη ἐν αὐτῷ.
43013032 Καὶ ὁ ˚Θεὸς δοξάσει αὐτὸν ἐν αὑτῷ, καὶ εὐθὺς δοξάσει αὐτόν.
43013033 Τεκνία, ἔτι μικρὸν μεθʼ ὑμῶν εἰμι. Ζητήσετέ με, καὶ καθὼς εἶπον τοῖς Ἰουδαίοις, ὅτι ‘Ὅπου ἐγὼ ὑπάγω, ὑμεῖς οὐ δύνασθε ἐλθεῖν’, καὶ ὑμῖν λέγω ἄρτι.
43013034 Ἐντολὴν καινὴν δίδωμι ὑμῖν, ἵνα ἀγαπᾶτε ἀλλήλους, καθὼς ἠγάπησα ὑμᾶς, ἵνα καὶ ὑμεῖς ἀγαπᾶτε ἀλλήλους.
43013035 Ἐν τούτῳ γνώσονται πάντες ὅτι ἐμοὶ μαθηταί ἐστε, ἐὰν ἀγάπην ἔχητε ἐν ἀλλήλοις.”
43013036 Λέγει αὐτῷ Σίμων Πέτρος, “˚Κύριε, ποῦ ὑπάγεις;” Ἀπεκρίθη αὐτῷ ˚Ἰησοῦς, “Ὅπου ὑπάγω, οὐ δύνασαί μοι νῦν ἀκολουθῆσαι, ἀκολουθήσεις δὲ ὕστερον.”
43013037 Λέγει αὐτῷ ὁ Πέτρος, “˚Κύριε, διὰ τί οὐ δύναμαί σοι ἀκολουθῆσαι ἄρτι; Τὴν ψυχήν μου ὑπὲρ σοῦ θήσω.”
43013038 Ἀποκρίνεται ˚Ἰησοῦς, “Τὴν ψυχήν σου ὑπὲρ ἐμοῦ θήσεις; Ἀμὴν, ἀμὴν, λέγω σοι, οὐ μὴ ἀλέκτωρ φωνήσῃ, ἕως οὗ ἀρνήσῃ με τρίς.
43014001 Μὴ ταρασσέσθω ὑμῶν ἡ καρδία· πιστεύετε εἰς τὸν ˚Θεόν, καὶ εἰς ἐμὲ πιστεύετε.
43014002 Ἐν τῇ οἰκίᾳ τοῦ Πατρός μου, μοναὶ πολλαί εἰσιν· εἰ δὲ μή, εἶπον ἂν ὑμῖν, ὅτι πορεύομαι ἑτοιμάσαι τόπον ὑμῖν.
43014003 Καὶ ἐὰν πορευθῶ καὶ ἑτοιμάσω ὑμῖν τόπον πάλιν ἔρχομαι καὶ παραλήμψομαι ὑμᾶς πρὸς ἐμαυτόν, ἵνα ὅπου εἰμὶ ἐγὼ, καὶ ὑμεῖς ἦτε.
43014004 Καὶ ὅπου ἐγὼ ὑπάγω οἴδατε, τὴν ὁδόν.”
43014005 Λέγει αὐτῷ Θωμᾶς, “˚Κύριε, οὐκ οἴδαμεν ποῦ ὑπάγεις· πῶς δυνάμεθα τὴν ὁδὸν εἰδέναι;”
43014006 Λέγει αὐτῷ ˚Ἰησοῦς, “Ἐγώ εἰμι ἡ ὁδὸς, καὶ ἡ ἀλήθεια, καὶ ἡ ζωή. Οὐδεὶς ἔρχεται πρὸς τὸν Πατέρα, εἰ μὴ διʼ ἐμοῦ.
43014007 Εἰ ἐγνώκατέ με, καὶ τὸν Πατέρα μου γνώσεσθε, καὶ ἀπʼ ἄρτι γινώσκετε αὐτὸν καὶ ἑωράκατε αὐτόν.”
43014008 Λέγει αὐτῷ Φίλιππος, “˚Κύριε, δεῖξον ἡμῖν τὸν Πατέρα, καὶ ἀρκεῖ ἡμῖν.”
43014009 Λέγει αὐτῷ ὁ ˚Ἰησοῦς, “Τοσοῦτον χρόνον μεθʼ ὑμῶν εἰμι, καὶ οὐκ ἔγνωκάς με, Φίλιππε; Ὁ ἑωρακὼς ἐμὲ, ἑώρακεν τὸν Πατέρα· πῶς σὺ λέγεις, ‘Δεῖξον ἡμῖν τὸν Πατέρα’;
43014010 Οὐ πιστεύεις ὅτι ἐγὼ ἐν τῷ Πατρὶ, καὶ ὁ Πατὴρ ἐν ἐμοί ἐστιν; Τὰ ῥήματα ἃ ἐγὼ λέγω ὑμῖν, ἀπʼ ἐμαυτοῦ οὐ λαλῶ, ὁ δὲ Πατὴρ ἐν ἐμοὶ μένων, ποιεῖ τὰ ἔργα αὐτοῦ.
43014011 Πιστεύετέ μοι ὅτι ἐγὼ ἐν τῷ Πατρὶ, καὶ ὁ Πατὴρ ἐν ἐμοί· εἰ δὲ μή, διὰ τὰ ἔργα αὐτὰ πιστεύετε.
43014012 Ἀμὴν, ἀμὴν, λέγω ὑμῖν, ὁ πιστεύων εἰς ἐμὲ, τὰ ἔργα ἃ ἐγὼ ποιῶ, κἀκεῖνος ποιήσει, καὶ μείζονα τούτων ποιήσει, ὅτι ἐγὼ πρὸς τὸν Πατέρα πορεύομαι.
43014013 Καὶ ὅ τι ἂν αἰτήσητε ἐν τῷ ὀνόματί μου, τοῦτο ποιήσω, ἵνα δοξασθῇ ὁ Πατὴρ ἐν τῷ Υἱῷ.
43014014 Ἐάν τι αἰτήσητέ με ἐν τῷ ὀνόματί μου, τοῦτο ποιήσω.
43014015 Ἐὰν ἀγαπᾶτέ με, τὰς ἐντολὰς τὰς ἐμὰς τηρήσετε.
43014016 Κἀγὼ ἐρωτήσω τὸν Πατέρα, καὶ ἄλλον Παράκλητον δώσει ὑμῖν, ἵνα μεθʼ ὑμῶν εἰς τὸν αἰῶνα ᾖ
43014017 τὸ ˚Πνεῦμα τῆς ἀληθείας, ὃ ὁ κόσμος οὐ δύναται λαβεῖν, ὅτι οὐ θεωρεῖ αὐτὸ οὐδὲ γινώσκει. Ὑμεῖς γινώσκετε αὐτό, ὅτι παρʼ ὑμῖν μένει, καὶ ἐν ὑμῖν ἐστίν.
43014018 Οὐκ ἀφήσω ὑμᾶς ὀρφανούς, ἔρχομαι πρὸς ὑμᾶς.
43014019 Ἔτι μικρὸν καὶ ὁ κόσμος με οὐκέτι θεωρεῖ, ὑμεῖς δὲ θεωρεῖτέ με, ὅτι ἐγὼ ζῶ, καὶ ὑμεῖς ζήσεσθε.
43014020 Ἐν ἐκείνῃ τῇ ἡμέρᾳ ὑμεῖς γνώσεσθε ὅτι ἐγὼ ἐν τῷ Πατρί μου, καὶ ὑμεῖς ἐν ἐμοὶ, κἀγὼ ἐν ὑμῖν.
43014021 Ὁ ἔχων τὰς ἐντολάς μου καὶ τηρῶν αὐτὰς, ἐκεῖνός ἐστιν ὁ ἀγαπῶν με· ὁ δὲ ἀγαπῶν με, ἀγαπηθήσεται ὑπὸ τοῦ Πατρός μου, κἀγὼ ἀγαπήσω αὐτὸν, καὶ ἐμφανίσω αὐτῷ ἐμαυτόν.”
43014022 Λέγει αὐτῷ Ἰούδας, οὐχ ὁ Ἰσκαριώτης, “˚Κύριε, τί γέγονεν, ὅτι ἡμῖν μέλλεις ἐμφανίζειν σεαυτὸν, καὶ οὐχὶ τῷ κόσμῳ;”
43014023 Ἀπεκρίθη ˚Ἰησοῦς καὶ εἶπεν αὐτῷ, “Ἐάν τις ἀγαπᾷ με, τὸν λόγον μου τηρήσει, καὶ ὁ Πατήρ μου ἀγαπήσει αὐτόν, καὶ πρὸς αὐτὸν ἐλευσόμεθα, καὶ μονὴν παρʼ αὐτῷ ποιησόμεθα.
43014024 Ὁ μὴ ἀγαπῶν με, τοὺς λόγους μου οὐ τηρεῖ· καὶ ὁ λόγος ὃν ἀκούετε, οὐκ ἔστιν ἐμὸς, ἀλλὰ τοῦ πέμψαντός με Πατρός.
43014025 Ταῦτα λελάληκα ὑμῖν παρʼ ὑμῖν μένων.
43014026 Ὁ δὲ Παράκλητος, τὸ ˚Πνεῦμα τὸ Ἅγιον, ὃ πέμψει ὁ Πατὴρ ἐν τῷ ὀνόματί μου, ἐκεῖνος ὑμᾶς διδάξει πάντα, καὶ ὑπομνήσει ὑμᾶς πάντα ἃ εἶπον ὑμῖν.
43014027 Εἰρήνην ἀφίημι ὑμῖν, εἰρήνην τὴν ἐμὴν δίδωμι ὑμῖν· οὐ καθὼς ὁ κόσμος δίδωσιν, ἐγὼ δίδωμι ὑμῖν. Μὴ ταρασσέσθω ὑμῶν ἡ καρδία, μηδὲ δειλιάτω.
43014028 Ἠκούσατε ὅτι ἐγὼ εἶπον ὑμῖν, ‘Ὑπάγω καὶ ἔρχομαι πρὸς ὑμᾶς.’ Εἰ ἠγαπᾶτέ με, ἐχάρητε ἄν ὅτι πορεύομαι πρὸς τὸν Πατέρα, μου ὅτι ὁ Πατὴρ μείζων μού ἐστιν.
43014029 Καὶ νῦν εἴρηκα ὑμῖν πρὶν γενέσθαι, ἵνα ὅταν γένηται, πιστεύσητε.
43014030 Οὐκέτι πολλὰ λαλήσω μεθʼ ὑμῶν, ἔρχεται γὰρ ὁ τοῦ κόσμου ἄρχων, καὶ ἐν ἐμοὶ οὐκ ἔχει οὐδέν,
43014031 ἀλλʼ ἵνα γνῷ ὁ κόσμος ὅτι ἀγαπῶ τὸν Πατέρα, καὶ καθὼς ἐντολὴν ἔδωκέν μοι ὁ Πατὴρ, οὕτως ποιῶ. Ἐγείρεσθε, ἄγωμεν ἐντεῦθεν.
43015001 Ἐγώ εἰμι ἡ ἄμπελος ἡ ἀληθινή, καὶ ὁ Πατήρ μου ὁ γεωργός ἐστιν.
43015002 Πᾶν κλῆμα ἐν ἐμοὶ μὴ φέρον καρπὸν, αἴρει αὐτό, καὶ πᾶν τὸ καρπὸν φέρον, καθαίρει αὐτὸ, ἵνα καρπὸν πλείονα φέρῃ.
43015003 Ἤδη ὑμεῖς καθαροί ἐστε, διὰ τὸν λόγον ὃν λελάληκα ὑμῖν.
43015004 Μείνατε ἐν ἐμοί, κἀγὼ ἐν ὑμῖν. Καθὼς τὸ κλῆμα οὐ δύναται καρπὸν φέρειν ἀφʼ ἑαυτοῦ, ἐὰν μὴ μείνῃ ἐν τῇ ἀμπέλῳ, οὕτως οὐδὲ ὑμεῖς, ἐὰν μὴ ἐν ἐμοὶ μένητε.
43015005 Ἐγώ εἰμι ἡ ἄμπελος, ὑμεῖς τὰ κλήματα. Ὁ μένων ἐν ἐμοὶ κἀγὼ ἐν αὐτῷ, οὗτος φέρει καρπὸν πολύν, ὅτι χωρὶς ἐμοῦ, οὐ δύνασθε ποιεῖν οὐδέν.
43015006 Ἐὰν μή τις μένῃ ἐν ἐμοί, ἐβλήθη ἔξω ὡς τὸ κλῆμα καὶ ἐξηράνθη, καὶ συνάγουσιν αὐτὰ καὶ εἰς τὸ πῦρ βάλλουσιν, καὶ καίεται.
43015007 Ἐὰν μείνητε ἐν ἐμοὶ, καὶ τὰ ῥήματά μου ἐν ὑμῖν μείνῃ, ὃ ἐὰν θέλητε, αἰτήσασθε καὶ γενήσεται ὑμῖν.
43015008 Ἐν τούτῳ ἐδοξάσθη ὁ Πατήρ μου, ἵνα καρπὸν πολὺν φέρητε καὶ γένησθε ἐμοὶ μαθηταί.
43015009 Καθὼς ἠγάπησέν με ὁ Πατήρ, κἀγὼ ὑμᾶς ἠγάπησα· μείνατε ἐν τῇ ἀγάπῃ τῇ ἐμῇ.
43015010 Ἐὰν τὰς ἐντολάς μου τηρήσητε, μενεῖτε ἐν τῇ ἀγάπῃ μου, καθὼς ἐγὼ τοῦ Πατρὸς τὰς ἐντολὰς τετήρηκα, καὶ μένω αὐτοῦ ἐν τῇ ἀγάπῃ.
43015011 Ταῦτα λελάληκα ὑμῖν, ἵνα ἡ χαρὰ ἡ ἐμὴ ἐν ὑμῖν ᾖ, καὶ ἡ χαρὰ ὑμῶν πληρωθῇ.
43015012 Αὕτη ἐστὶν ἡ ἐντολὴ ἡ ἐμὴ, ἵνα ἀγαπᾶτε ἀλλήλους καθὼς ἠγάπησα ὑμᾶς.
43015013 Μείζονα ταύτης ἀγάπην οὐδεὶς ἔχει: ἵνα τις τὴν ψυχὴν αὐτοῦ θῇ ὑπὲρ τῶν φίλων αὐτοῦ.
43015014 Ὑμεῖς φίλοι μού ἐστε, ἐὰν ποιῆτε ἃ ἐγὼ ἐντέλλομαι ὑμῖν.
43015015 Οὐκέτι λέγω ὑμᾶς δούλους, ὅτι ὁ δοῦλος οὐκ οἶδεν τί ποιεῖ αὐτοῦ ὁ κύριος. Ὑμᾶς δὲ εἴρηκα φίλους, ὅτι πάντα ἃ ἤκουσα παρὰ τοῦ Πατρός μου, ἐγνώρισα ὑμῖν.
43015016 Οὐχ ὑμεῖς με ἐξελέξασθε, ἀλλʼ ἐγὼ ἐξελεξάμην ὑμᾶς, καὶ ἔθηκα ὑμᾶς ἵνα ὑμεῖς ὑπάγητε καὶ καρπὸν φέρητε, καὶ ὁ καρπὸς ὑμῶν μένῃ, ἵνα ὅ τι ἂν αἰτήσητε τὸν Πατέρα ἐν τῷ ὀνόματί μου, δῷ ὑμῖν.
43015017 Ταῦτα ἐντέλλομαι ὑμῖν, ἵνα ἀγαπᾶτε ἀλλήλους.
43015018 Εἰ ὁ κόσμος ὑμᾶς μισεῖ, γινώσκετε ὅτι ἐμὲ πρῶτον ὑμῶν μεμίσηκεν.
43015019 Εἰ ἐκ τοῦ κόσμου ἦτε, ὁ κόσμος ἂν τὸ ἴδιον ἐφίλει· ὅτι δὲ ἐκ τοῦ κόσμου οὐκ ἐστέ, ἀλλʼ ἐγὼ ἐξελεξάμην ὑμᾶς ἐκ τοῦ κόσμου, διὰ τοῦτο μισεῖ ὑμᾶς ὁ κόσμος.
43015020 Μνημονεύετε τοῦ λόγου οὗ ἐγὼ εἶπον ὑμῖν, ‘Οὐκ ἔστιν δοῦλος μείζων τοῦ κυρίου αὐτοῦ.’ Εἰ ἐμὲ ἐδίωξαν, καὶ ὑμᾶς διώξουσιν· εἰ τὸν λόγον μου ἐτήρησαν, καὶ τὸν ὑμέτερον τηρήσουσιν.
43015021 Ἀλλὰ ταῦτα πάντα ποιήσουσιν εἰς ὑμᾶς διὰ τὸ ὄνομά μου, ὅτι οὐκ οἴδασιν τὸν πέμψαντά με.
43015022 Εἰ μὴ ἦλθον καὶ ἐλάλησα αὐτοῖς, ἁμαρτίαν οὐκ εἴχοσαν· νῦν δὲ πρόφασιν οὐκ ἔχουσιν περὶ τῆς ἁμαρτίας αὐτῶν.
43015023 Ὁ ἐμὲ μισῶν, καὶ τὸν Πατέρα μου μισεῖ.
43015024 Εἰ τὰ ἔργα μὴ ἐποίησα ἐν αὐτοῖς ἃ οὐδεὶς ἄλλος ἐποίησεν, ἁμαρτίαν οὐκ εἴχοσαν· νῦν δὲ καὶ ἑωράκασιν καὶ μεμισήκασιν, καὶ ἐμὲ καὶ τὸν Πατέρα μου.
43015025 Ἀλλʼ ἵνα πληρωθῇ ὁ λόγος ὁ ἐν τῷ νόμῳ αὐτῶν γεγραμμένος, ὅτι ‘Ἐμίσησάν με δωρεάν.’
43015026 Ὅταν ἔλθῃ ὁ Παράκλητος, ὃν ἐγὼ πέμψω ὑμῖν παρὰ τοῦ Πατρός, τὸ ˚Πνεῦμα τῆς ἀληθείας, ὃ παρὰ τοῦ Πατρὸς ἐκπορεύεται, ἐκεῖνος μαρτυρήσει περὶ ἐμοῦ.
43015027 Καὶ ὑμεῖς δὲ μαρτυρεῖτε, ὅτι ἀπʼ ἀρχῆς μετʼ ἐμοῦ ἐστε.
43016001 Ταῦτα λελάληκα ὑμῖν, ἵνα μὴ σκανδαλισθῆτε.
43016002 Ἀποσυναγώγους ποιήσουσιν ὑμᾶς· ἀλλʼ ἔρχεται ὥρα, ἵνα πᾶς ὁ ἀποκτείνας ὑμᾶς, δόξῃ λατρείαν προσφέρειν τῷ ˚Θεῷ.
43016003 Καὶ ταῦτα ποιήσουσιν, ὅτι οὐκ ἔγνωσαν τὸν Πατέρα οὐδὲ ἐμέ.
43016004 Ἀλλὰ ταῦτα λελάληκα ὑμῖν, ἵνα ὅταν ἔλθῃ ἡ ὥρα αὐτῶν, μνημονεύητε αὐτῶν ὅτι ἐγὼ εἶπον ὑμῖν. Ταῦτα δὲ ὑμῖν ἐξ ἀρχῆς οὐκ εἶπον, ὅτι μεθʼ ὑμῶν ἤμην.
43016005 Νῦν δὲ ὑπάγω πρὸς τὸν πέμψαντά με, καὶ οὐδεὶς ἐξ ὑμῶν ἐρωτᾷ με, ‘Ποῦ ὑπάγεις;’
43016006 Ἀλλʼ ὅτι ταῦτα λελάληκα ὑμῖν, ἡ λύπη πεπλήρωκεν ὑμῶν τὴν καρδίαν.
43016007 Ἀλλʼ ἐγὼ τὴν ἀλήθειαν λέγω ὑμῖν, συμφέρει ὑμῖν ἵνα ἐγὼ ἀπέλθω. Ἐὰν γὰρ μὴ ἀπέλθω, ὁ Παράκλητος οὐκ ἐλεύσεται πρὸς ὑμᾶς· ἐὰν δὲ πορευθῶ, πέμψω αὐτὸν πρὸς ὑμᾶς.
43016008 Καὶ ἐλθὼν, ἐκεῖνος ἐλέγξει τὸν κόσμον περὶ ἁμαρτίας, καὶ περὶ δικαιοσύνης, καὶ περὶ κρίσεως·
43016009 περὶ ἁμαρτίας μέν, ὅτι οὐ πιστεύουσιν εἰς ἐμέ·
43016010 περὶ δικαιοσύνης δέ, ὅτι πρὸς τὸν Πατέρα ὑπάγω, καὶ οὐκέτι θεωρεῖτέ με·
43016011 περὶ δὲ κρίσεως, ὅτι ὁ ἄρχων τοῦ κόσμου τούτου κέκριται.
43016012 Ἔτι πολλὰ ἔχω ὑμῖν λέγειν, ἀλλʼ οὐ δύνασθε βαστάζειν ἄρτι.
43016013 Ὅταν δὲ ἔλθῃ ἐκεῖνος, τὸ ˚Πνεῦμα τῆς ἀληθείας, ὁδηγήσει ὑμᾶς εἰς τὴν ἀλήθειαν· πᾶσαν· οὐ γὰρ λαλήσει ἀφʼ ἑαυτοῦ, ἀλλʼ ὅσα ἀκούσει, λαλήσει, καὶ τὰ ἐρχόμενα ἀναγγελεῖ ὑμῖν.
43016014 Ἐκεῖνος ἐμὲ δοξάσει, ὅτι ἐκ τοῦ ἐμοῦ λήμψεται, καὶ ἀναγγελεῖ ὑμῖν.
43016015 Πάντα ὅσα ἔχει ὁ Πατὴρ, ἐμά ἐστιν· διὰ τοῦτο εἶπον ὅτι ἐκ τοῦ ἐμοῦ λαμβάνει, καὶ ἀναγγελεῖ ὑμῖν.
43016016 Μικρὸν καὶ οὐκέτι θεωρεῖτέ με, καὶ πάλιν μικρὸν καὶ ὄψεσθέ με.”
43016017 Εἶπαν οὖν ἐκ τῶν μαθητῶν αὐτοῦ πρὸς ἀλλήλους, “Τί ἐστιν τοῦτο ὃ λέγει ἡμῖν, ‘Μικρὸν καὶ οὐ θεωρεῖτέ με, καὶ πάλιν μικρὸν καὶ ὄψεσθέ με· καί Ὅτι ὑπάγω πρὸς τὸν Πατέρα’;”
43016018 Ἔλεγον οὖν, “Τί ἐστιν τοῦτο, ὃ λέγει ‘Μικρόν’; Οὐκ οἴδαμεν τί λαλεῖ.”
43016019 Ἔγνω ˚Ἰησοῦς ὅτι ἤθελον αὐτὸν ἐρωτᾶν, καὶ εἶπεν αὐτοῖς, “Περὶ τούτου ζητεῖτε μετʼ ἀλλήλων, ὅτι εἶπον, ‘Μικρὸν καὶ οὐ θεωρεῖτέ με, καὶ πάλιν μικρὸν καὶ ὄψεσθέ με’;
43016020 Ἀμὴν, ἀμὴν, λέγω ὑμῖν ὅτι κλαύσετε καὶ θρηνήσετε ὑμεῖς, ὁ δὲ κόσμος χαρήσεται· ὑμεῖς λυπηθήσεσθε, ἀλλʼ ἡ λύπη ὑμῶν εἰς χαρὰν γενήσεται.
43016021 Ἡ γυνὴ ὅταν τίκτῃ, λύπην ἔχει, ὅτι ἦλθεν ἡ ὥρα αὐτῆς· ὅταν δὲ γεννήσῃ τὸ παιδίον, οὐκέτι μνημονεύει τῆς θλίψεως διὰ τὴν χαρὰν ὅτι ἐγεννήθη ἄνθρωπος εἰς τὸν κόσμον.
43016022 Καὶ ὑμεῖς οὖν νῦν μὲν λύπην ἔχετε· πάλιν δὲ ὄψομαι ὑμᾶς, καὶ χαρήσεται ὑμῶν ἡ καρδία, καὶ τὴν χαρὰν ὑμῶν, οὐδεὶς αἴρει ἀφʼ ὑμῶν.
43016023 Καὶ ἐν ἐκείνῃ τῇ ἡμέρᾳ ἐμὲ οὐκ ἐρωτήσετε οὐδέν. Ἀμὴν, ἀμὴν, λέγω ὑμῖν, ἄν τι αἰτήσητε τὸν Πατέρα, ἐν τῷ ὀνόματί μου, δώσει ὑμῖν.
43016024 Ἕως ἄρτι οὐκ ᾐτήσατε οὐδὲν ἐν τῷ ὀνόματί μου. Αἰτεῖτε καὶ λήμψεσθε, ἵνα ἡ χαρὰ ὑμῶν ᾖ πεπληρωμένη.
43016025 Ταῦτα ἐν παροιμίαις λελάληκα ὑμῖν· ἔρχεται ὥρα ὅτε οὐκέτι ἐν παροιμίαις λαλήσω ὑμῖν, ἀλλὰ παρρησίᾳ περὶ τοῦ Πατρὸς ἀπαγγελῶ ὑμῖν.
43016026 Ἐν ἐκείνῃ τῇ ἡμέρᾳ ἐν τῷ ὀνόματί μου αἰτήσεσθε, καὶ οὐ λέγω ὑμῖν ὅτι ἐγὼ ἐρωτήσω τὸν Πατέρα περὶ ὑμῶν·
43016027 αὐτὸς γὰρ ὁ Πατὴρ φιλεῖ ὑμᾶς, ὅτι ὑμεῖς ἐμὲ πεφιλήκατε, καὶ πεπιστεύκατε ὅτι ἐγὼ παρὰ ˚Θεοῦ ἐξῆλθον.
43016028 Ἐξῆλθον παρὰ τοῦ Πατρὸς, καὶ ἐλήλυθα εἰς τὸν κόσμον· πάλιν ἀφίημι τὸν κόσμον, καὶ πορεύομαι πρὸς τὸν Πατέρα.”
43016029 Λέγουσιν οἱ μαθηταὶ αὐτοῦ, “Ἴδε, νῦν ἐν παρρησίᾳ λαλεῖς, καὶ παροιμίαν οὐδεμίαν λέγεις.
43016030 Νῦν οἴδαμεν ὅτι οἶδας πάντα, καὶ οὐ χρείαν ἔχεις ἵνα τίς σε ἐρωτᾷ. Ἐν τούτῳ πιστεύομεν ὅτι ἀπὸ ˚Θεοῦ ἐξῆλθες.”
43016031 Ἀπεκρίθη αὐτοῖς ˚Ἰησοῦς, “Ἄρτι πιστεύετε;
43016032 Ἰδοὺ, ἔρχεται ὥρα καὶ ἐλήλυθεν, ἵνα σκορπισθῆτε ἕκαστος εἰς τὰ ἴδια, κἀμὲ μόνον ἀφῆτε· καὶ οὐκ εἰμὶ μόνος, ὅτι ὁ Πατὴρ μετʼ ἐμοῦ ἐστιν.
43016033 Ταῦτα λελάληκα ὑμῖν, ἵνα ἐν ἐμοὶ εἰρήνην ἔχητε. Ἐν τῷ κόσμῳ θλῖψιν ἔχετε, ἀλλὰ θαρσεῖτε, ἐγὼ νενίκηκα τὸν κόσμον.”
43017001 Ταῦτα ἐλάλησεν ˚Ἰησοῦς, καὶ ἐπάρας τοὺς ὀφθαλμοὺς αὐτοῦ εἰς τὸν οὐρανὸν εἶπεν, “Πάτερ, ἐλήλυθεν ἡ ὥρα· δόξασόν σου τὸν Υἱόν, ἵνα ὁ Υἱὸς δοξάσῃ σέ.
43017002 Καθὼς ἔδωκας αὐτῷ ἐξουσίαν πάσης σαρκός, ἵνα πᾶν ὃ δέδωκας αὐτῷ, δῷς αὐτῷ ζωὴν αἰώνιον.
43017003 Αὕτη δέ ἐστιν ἡ αἰώνιος ζωὴ, ἵνα γινώσκωσιν σὲ, τὸν μόνον ἀληθινὸν ˚Θεὸν, καὶ ὃν ἀπέστειλας, ˚Ἰησοῦν ˚Χριστόν.
43017004 Ἐγώ σε ἐδόξασα ἐπὶ τῆς γῆς, τὸ ἔργον τελειώσας, ὃ δέδωκάς μοι ἵνα ποιήσω.
43017005 Καὶ νῦν δόξασόν με σύ, Πάτερ, παρὰ σεαυτῷ, τῇ δόξῃ ᾗ εἶχον πρὸ τοῦ τὸν κόσμον εἶναι παρὰ σοί.
43017006 Ἐφανέρωσά σου τὸ ὄνομα τοῖς ἀνθρώποις οὓς ἔδωκάς μοι ἐκ τοῦ κόσμου. Σοὶ ἦσαν, κἀμοὶ αὐτοὺς ἔδωκας, καὶ τὸν λόγον σου τετήρηκαν.
43017007 Νῦν ἔγνωκαν ὅτι πάντα ὅσα δέδωκάς μοι, παρὰ σοῦ εἰσιν·
43017008 ὅτι τὰ ῥήματα ἃ ἔδωκάς μοι, δέδωκα αὐτοῖς, καὶ αὐτοὶ ἔλαβον, ἀληθῶς ὅτι παρὰ σοῦ ἐξῆλθον, καὶ ἐπίστευσαν ὅτι σύ με ἀπέστειλας.
43017009 Ἐγὼ περὶ αὐτῶν ἐρωτῶ· οὐ περὶ τοῦ κόσμου ἐρωτῶ, ἀλλὰ περὶ ὧν δέδωκάς μοι, ὅτι σοί εἰσιν.
43017010 Καὶ τὰ ἐμὰ πάντα, σά ἐστιν, καὶ τὰ σὰ, ἐμά, καὶ δεδόξασμαι ἐν αὐτοῖς.
43017011 Καὶ οὐκέτι εἰμὶ ἐν τῷ κόσμῳ, καὶ αὐτοὶ ἐν τῷ κόσμῳ εἰσίν, κἀγὼ πρὸς σὲ ἔρχομαι. Πάτερ Ἅγιε, τήρησον αὐτοὺς ἐν τῷ ὀνόματί σου, ᾧ δέδωκάς μοι, ἵνα ὦσιν ἓν, καθὼς ἡμεῖς.
43017012 Ὅτε ἤμην μετʼ αὐτῶν, ἐγὼ ἐτήρουν αὐτοὺς ἐν τῷ ὀνόματί σου, ᾧ δέδωκάς μοι, καὶ ἐφύλαξα, καὶ οὐδεὶς ἐξ αὐτῶν ἀπώλετο, εἰ μὴ ὁ υἱὸς τῆς ἀπωλείας, ἵνα ἡ Γραφὴ πληρωθῇ.
43017013 Νῦν δὲ πρὸς σὲ ἔρχομαι, καὶ ταῦτα λαλῶ ἐν τῷ κόσμῳ, ἵνα ἔχωσιν τὴν χαρὰν τὴν ἐμὴν, πεπληρωμένην ἐν ἑαυτοῖς.
43017014 Ἐγὼ δέδωκα αὐτοῖς τὸν λόγον σου, καὶ ὁ κόσμος ἐμίσησεν αὐτούς, ὅτι οὐκ εἰσὶν ἐκ τοῦ κόσμου, καθὼς ἐγὼ οὐκ εἰμὶ ἐκ τοῦ κόσμου.
43017015 Οὐκ ἐρωτῶ ἵνα ἄρῃς αὐτοὺς ἐκ τοῦ κόσμου, ἀλλʼ ἵνα τηρήσῃς αὐτοὺς ἐκ τοῦ πονηροῦ.
43017016 Ἐκ τοῦ κόσμου οὐκ εἰσὶν, καθὼς ἐγὼ οὐκ εἰμὶ ἐκ τοῦ κόσμου.
43017017 Ἁγίασον αὐτοὺς ἐν τῇ ἀληθείᾳ· ὁ λόγος ὁ σὸς ἀλήθειά ἐστιν.
43017018 Καθὼς ἐμὲ ἀπέστειλας εἰς τὸν κόσμον, κἀγὼ ἀπέστειλα αὐτοὺς εἰς τὸν κόσμον.
43017019 Καὶ ὑπὲρ αὐτῶν ἐγὼ ἁγιάζω ἐμαυτόν, ἵνα ὦσιν καὶ αὐτοὶ ἡγιασμένοι ἐν ἀληθείᾳ.
43017020 Οὐ περὶ τούτων δὲ ἐρωτῶ μόνον, ἀλλὰ καὶ περὶ τῶν πιστευόντων διὰ τοῦ λόγου αὐτῶν εἰς ἐμέ,
43017021 ἵνα πάντες ἓν ὦσιν, καθὼς σύ, Πάτερ, ἐν ἐμοὶ, κἀγὼ ἐν σοί, ἵνα καὶ αὐτοὶ ἐν ἡμῖν ὦσιν, ἵνα ὁ κόσμος πιστεύῃ ὅτι σύ με ἀπέστειλας.
43017022 Κἀγὼ τὴν δόξαν ἣν δέδωκάς μοι, δέδωκα αὐτοῖς, ἵνα ὦσιν ἓν, καθὼς ἡμεῖς ἕν,
43017023 ἐγὼ ἐν αὐτοῖς, καὶ σὺ ἐν ἐμοί, ἵνα ὦσιν τετελειωμένοι εἰς ἕν, καὶ γινώσκῃ ὁ κόσμος ὅτι σύ με ἀπέστειλας, καὶ ἠγάπησας αὐτοὺς καθὼς ἐμὲ ἠγάπησας.
43017024 Πάτερ, ὃ δέδωκάς μοι θέλω, ἵνα ὅπου εἰμὶ ἐγὼ, κἀκεῖνοι ὦσιν μετʼ ἐμοῦ, ἵνα θεωρῶσιν τὴν δόξαν τὴν ἐμὴν, ἣν ἔδωκάς μοι, ὅτι ἠγάπησάς με πρὸ καταβολῆς κόσμου.
43017025 Πάτερ δίκαιε, καὶ ὁ κόσμος σε οὐκ ἔγνω, ἐγὼ δέ σε ἔγνων, καὶ οὗτοι ἔγνωσαν ὅτι σύ με ἀπέστειλας.
43017026 Καὶ ἐγνώρισα αὐτοῖς τὸ ὄνομά σου, καὶ γνωρίσω, ἵνα ἡ ἀγάπη ἣν ἠγάπησάς με, ἐν αὐτοῖς ᾖ, κἀγὼ ἐν αὐτοῖς.”
43018001 Ταῦτα εἰπὼν, ˚Ἰησοῦς ἐξῆλθεν σὺν τοῖς μαθηταῖς αὐτοῦ πέραν τοῦ χειμάρρου τοῦ Κεδρὼν, ὅπου ἦν κῆπος, εἰς ὃν εἰσῆλθεν αὐτὸς καὶ οἱ μαθηταὶ αὐτοῦ.
43018002 Ἤιδει δὲ καὶ Ἰούδας, ὁ παραδιδοὺς αὐτὸν, τὸν τόπον, ὅτι πολλάκις συνήχθη ˚Ἰησοῦς μετὰ τῶν μαθητῶν αὐτοῦ. Ἐκεῖ
43018003 ὁ οὖν Ἰούδας λαβὼν τὴν σπεῖραν, καὶ ἐκ τῶν ἀρχιερέων καὶ Φαρισαίων ὑπηρέτας, ἔρχεται ἐκεῖ μετὰ φανῶν, καὶ λαμπάδων, καὶ ὅπλων.
43018004 ˚Ἰησοῦς οὖν εἰδὼς πάντα τὰ ἐρχόμενα ἐπʼ αὐτὸν, ἐξελθὼν εἶπεν αὐτοῖς, “Τίνα ζητεῖτε;”
43018005 Ἀπεκρίθησαν αὐτῷ, “˚Ἰησοῦν τὸν Ναζωραῖον.” Λέγει αὐτοῖς, “Ἐγώ εἰμι.” ˚Ἰησοῦς εἱστήκει δὲ καὶ Ἰούδας, ὁ παραδιδοὺς αὐτὸν, μετʼ αὐτῶν.
43018006 Ὡς οὖν εἶπεν αὐτοῖς, “Ἐγώ εἰμι”, ἀπῆλθαν εἰς τὰ ὀπίσω καὶ ἔπεσαν χαμαί.
43018007 Πάλιν οὖν ἐπηρώτησεν αὐτούς, “Τίνα ζητεῖτε;” Οἱ δὲ εἶπον, “˚Ἰησοῦν τὸν Ναζωραῖον.”
43018008 Ἀπεκρίθη ˚Ἰησοῦς, “Εἶπον ὑμῖν ὅτι ἐγώ εἰμι. Εἰ οὖν ἐμὲ ζητεῖτε, ἄφετε τούτους ὑπάγειν”·
43018009 ἵνα πληρωθῇ ὁ λόγος ὃν εἶπεν, ὅτι “Οὓς δέδωκάς μοι, οὐκ ἀπώλεσα ἐξ αὐτῶν οὐδένα.”
43018010 Σίμων οὖν Πέτρος ἔχων μάχαιραν, εἵλκυσεν αὐτὴν καὶ ἔπαισεν τὸν τοῦ ἀρχιερέως δοῦλον, καὶ ἀπέκοψεν αὐτοῦ τὸ ὠτάριον τὸ δεξιόν. Ἦν δὲ ὄνομα τῷ δούλῳ Μάλχος.
43018011 Εἶπεν οὖν ὁ ˚Ἰησοῦς τῷ Πέτρῳ, “Βάλε τὴν μάχαιραν εἰς τὴν θήκην· τὸ ποτήριον ὃ δέδωκέν μοι ὁ Πατὴρ, οὐ μὴ πίω αὐτό;”
43018012 Ἡ οὖν σπεῖρα, καὶ ὁ χιλίαρχος, καὶ οἱ ὑπηρέται τῶν Ἰουδαίων, συνέλαβον τὸν ˚Ἰησοῦν καὶ ἔδησαν αὐτὸν.
43018013 Καὶ ἤγαγον πρὸς Ἅνναν πρῶτον, ἦν γὰρ πενθερὸς τοῦ Καϊάφα, ὃς ἦν ἀρχιερεὺς τοῦ ἐνιαυτοῦ ἐκείνου.
43018014 Ἦν δὲ Καϊάφας ὁ συμβουλεύσας τοῖς Ἰουδαίοις ὅτι συμφέρει ἕνα ἄνθρωπον ἀποθανεῖν ὑπὲρ τοῦ λαοῦ.
43018015 Ἠκολούθει δὲ τῷ ˚Ἰησοῦ Σίμων Πέτρος καὶ ἄλλος μαθητής. Ὁ δὲ μαθητὴς ἐκεῖνος ἦν γνωστὸς τῷ ἀρχιερεῖ, καὶ συνεισῆλθεν τῷ ˚Ἰησοῦ εἰς τὴν αὐλὴν τοῦ ἀρχιερέως.
43018016 Ὁ δὲ Πέτρος εἱστήκει πρὸς τῇ θύρᾳ ἔξω. Ἐξῆλθεν οὖν ὁ μαθητὴς ὁ ἄλλος ὁ ἦν γνωστὸς τοῦ ἀρχιερέως, καὶ εἶπεν τῇ θυρωρῷ καὶ εἰσήγαγεν τὸν Πέτρον.
43018017 Λέγει οὖν ἡ παιδίσκη ἡ θυρωρὸς τῷ Πέτρῳ, “Μὴ καὶ σὺ ἐκ τῶν μαθητῶν εἶ τοῦ ἀνθρώπου τούτου;” Λέγει ἐκεῖνος, “Οὐκ εἰμί.”
43018018 Εἱστήκεισαν δὲ οἱ δοῦλοι καὶ οἱ ὑπηρέται, ἀνθρακιὰν πεποιηκότες, ὅτι ψῦχος ἦν, καὶ ἐθερμαίνοντο· ἦν δὲ καὶ ὁ Πέτρος μετʼ αὐτῶν ἑστὼς καὶ θερμαινόμενος.
43018019 Ὁ οὖν ἀρχιερεὺς ἠρώτησεν τὸν ˚Ἰησοῦν περὶ τῶν μαθητῶν αὐτοῦ, καὶ περὶ τῆς διδαχῆς αὐτοῦ.
43018020 Ἀπεκρίθη αὐτῷ ˚Ἰησοῦς, “Ἐγὼ παρρησίᾳ λελάληκα τῷ κόσμῳ· ἐγὼ πάντοτε ἐδίδαξα ἐν συναγωγῇ καὶ ἐν τῷ ἱερῷ, ὅπου πάντες οἱ Ἰουδαῖοι συνέρχονται, καὶ ἐν κρυπτῷ ἐλάλησα οὐδέν.
43018021 Τί με ἐρωτᾷς; Ἐρώτησον τοὺς ἀκηκοότας τί ἐλάλησα αὐτοῖς· ἴδε, οὗτοι οἴδασιν ἃ εἶπον ἐγώ.”
43018022 Ταῦτα δὲ αὐτοῦ εἰπόντος, εἷς παρεστηκὼς τῶν ὑπηρετῶν ἔδωκεν ῥάπισμα τῷ ˚Ἰησοῦ εἰπών, “Οὕτως ἀποκρίνῃ τῷ ἀρχιερεῖ;”
43018023 Ἀπεκρίθη αὐτῷ ˚Ἰησοῦς, “Εἰ κακῶς ἐλάλησα, μαρτύρησον περὶ τοῦ κακοῦ· εἰ δὲ καλῶς, τί με δέρεις;”
43018024 Ἀπέστειλεν οὖν αὐτὸν ὁ Ἅννας, δεδεμένον πρὸς Καϊάφαν τὸν ἀρχιερέα.
43018025 Ἦν δὲ Σίμων Πέτρος ἑστὼς καὶ θερμαινόμενος. Εἶπον οὖν αὐτῷ, “Μὴ καὶ σὺ ἐκ τῶν μαθητῶν αὐτοῦ εἶ;” Ἠρνήσατο ἐκεῖνος καὶ εἶπεν, “Οὐκ εἰμί.”
43018026 Λέγει εἷς ἐκ τῶν δούλων τοῦ ἀρχιερέως, συγγενὴς ὢν οὗ ἀπέκοψεν Πέτρος τὸ ὠτίον, “Οὐκ ἐγώ σε εἶδον ἐν τῷ κήπῳ μετʼ αὐτοῦ;”
43018027 Πάλιν οὖν ἠρνήσατο Πέτρος, καὶ εὐθέως ἀλέκτωρ ἐφώνησεν.
43018028 Ἄγουσιν οὖν τὸν ˚Ἰησοῦν ἀπὸ τοῦ Καϊάφα εἰς τὸ πραιτώριον· ἦν δὲ πρωΐ. Καὶ αὐτοὶ οὐκ εἰσῆλθον εἰς τὸ πραιτώριον, ἵνα μὴ μιανθῶσιν, ἀλλὰ φάγωσιν τὸ Πάσχα.
43018029 Ἐξῆλθεν οὖν ὁ Πιλᾶτος ἔξω πρὸς αὐτοὺς καὶ φησίν, “Τίνα κατηγορίαν φέρετε κατὰ τοῦ ἀνθρώπου τούτου;”
43018030 Ἀπεκρίθησαν καὶ εἶπαν αὐτῷ, “Εἰ μὴ ἦν οὗτος κακὸν ποιῶν, οὐκ ἄν σοι παρεδώκαμεν αὐτόν.”
43018031 Εἶπεν οὖν αὐτοῖς Πιλᾶτος, “Λάβετε αὐτὸν ὑμεῖς, καὶ κατὰ τὸν νόμον ὑμῶν, κρίνατε αὐτόν.” Εἶπον αὐτῷ οἱ Ἰουδαῖοι, “Ἡμῖν οὐκ ἔξεστιν ἀποκτεῖναι οὐδένα”·
43018032 ἵνα ὁ λόγος τοῦ ˚Ἰησοῦ πληρωθῇ, ὃν εἶπεν, σημαίνων ποίῳ θανάτῳ ἤμελλεν ἀποθνῄσκειν.
43018033 Εἰσῆλθεν οὖν πάλιν εἰς τὸ πραιτώριον ὁ Πιλᾶτος, καὶ ἐφώνησεν τὸν ˚Ἰησοῦν καὶ εἶπεν αὐτῷ, “Σὺ εἶ ὁ βασιλεὺς τῶν Ἰουδαίων;”
43018034 Ἀπεκρίθη ˚Ἰησοῦς, “Ἀπὸ σεαυτοῦ σὺ τοῦτο λέγεις, ἢ ἄλλοι εἶπόν σοι περὶ ἐμοῦ;”
43018035 Ἀπεκρίθη ὁ Πιλᾶτος, “Μήτι ἐγὼ Ἰουδαῖός εἰμι; Τὸ ἔθνος τὸ σὸν καὶ οἱ ἀρχιερεῖς παρέδωκάν σε ἐμοί. Τί ἐποίησας;”
43018036 Ἀπεκρίθη ˚Ἰησοῦς, “Ἡ βασιλεία ἡ ἐμὴ οὐκ ἔστιν ἐκ τοῦ κόσμου τούτου· εἰ ἐκ τοῦ κόσμου τούτου ἦν ἡ βασιλεία ἡ ἐμή, οἱ ὑπηρέται οἱ ἐμοὶ ἠγωνίζοντο ἄν, ἵνα μὴ παραδοθῶ τοῖς Ἰουδαίοις. Νῦν δὲ ἡ βασιλεία ἡ ἐμὴ οὐκ ἔστιν ἐντεῦθεν.”
43018037 Εἶπεν οὖν αὐτῷ ὁ Πιλᾶτος, “Οὐκοῦν βασιλεὺς εἶ σύ;” Ἀπεκρίθη ὁ ˚Ἰησοῦς, “Σὺ λέγεις ὅτι βασιλεύς εἰμι Ἐγὼ. εἰς τοῦτο γεγέννημαι, καὶ εἰς τοῦτο ἐλήλυθα εἰς τὸν κόσμον, ἵνα μαρτυρήσω τῇ ἀληθείᾳ. Πᾶς ὁ ὢν ἐκ τῆς ἀληθείας, ἀκούει μου τῆς φωνῆς.”
43018038 Λέγει αὐτῷ ὁ Πιλᾶτος, “Τί ἐστιν ἀλήθεια;” Καὶ τοῦτο εἰπὼν, πάλιν ἐξῆλθεν πρὸς τοὺς Ἰουδαίους, καὶ λέγει αὐτοῖς, “Ἐγὼ οὐδεμίαν εὑρίσκω ἐν αὐτῷ αἰτίαν.
43018039 Ἔστιν δὲ συνήθεια ὑμῖν, ἵνα ἕνα ἀπολύσω ὑμῖν ἐν τῷ Πάσχα· βούλεσθε οὖν ἀπολύσω ὑμῖν τὸν Βασιλέα τῶν Ἰουδαίων;”
43018040 Ἐκραύγασαν οὖν πάλιν λέγοντες, “Μὴ τοῦτον, ἀλλὰ τὸν Βαραββᾶν!” Ἦν δὲ ὁ Βαραββᾶς λῃστής.
43019001 Τότε οὖν ἔλαβεν ὁ Πιλᾶτος τὸν ˚Ἰησοῦν καὶ ἐμαστίγωσεν.
43019002 Καὶ οἱ στρατιῶται πλέξαντες στέφανον ἐξ ἀκανθῶν, ἐπέθηκαν αὐτοῦ τῇ κεφαλῇ, καὶ ἱμάτιον πορφυροῦν περιέβαλον αὐτόν,
43019003 καὶ ἤρχοντο πρὸς αὐτὸν καὶ ἔλεγον, “Χαῖρε, ὁ Βασιλεὺς τῶν Ἰουδαίων!” Καὶ ἐδίδοσαν αὐτῷ ῥαπίσματα.
43019004 Καὶ ἐξῆλθεν πάλιν ἔξω ὁ Πιλᾶτος, καὶ λέγει αὐτοῖς, “Ἴδε, ἄγω ὑμῖν αὐτὸν ἔξω, ἵνα γνῶτε ὅτι αἰτίαν ἐν αὐτῷ οὐχ εὑρίσκω.”
43019005 Ἐξῆλθεν οὖν ὁ ˚Ἰησοῦς ἔξω, φορῶν τὸν ἀκάνθινον στέφανον καὶ τὸ πορφυροῦν ἱμάτιον. Καὶ λέγει αὐτοῖς, “Ἰδοὺ, ὁ ἄνθρωπος!”
43019006 Ὅτε οὖν εἶδον αὐτὸν οἱ ἀρχιερεῖς καὶ οἱ ὑπηρέται, ἐκραύγασαν λέγοντες, “Σταύρωσον! Σταύρωσον!” Λέγει αὐτοῖς ὁ Πιλᾶτος, “Λάβετε αὐτὸν ὑμεῖς καὶ σταυρώσατε, ἐγὼ γὰρ οὐχ εὑρίσκω ἐν αὐτῷ αἰτίαν.”
43019007 Ἀπεκρίθησαν αὐτῷ οἱ Ἰουδαῖοι, “Ἡμεῖς νόμον ἔχομεν, καὶ κατὰ τὸν νόμον ὀφείλει ἀποθανεῖν, ὅτι Υἱὸν ˚Θεοῦ ἑαυτὸν ἐποίησεν.”
43019008 Ὅτε οὖν ἤκουσεν ὁ Πιλᾶτος τοῦτον τὸν λόγον, μᾶλλον ἐφοβήθη.
43019009 Καὶ εἰσῆλθεν εἰς τὸ πραιτώριον πάλιν, καὶ λέγει τῷ ˚Ἰησοῦ, “Πόθεν εἶ σύ;” Ὁ δὲ ˚Ἰησοῦς ἀπόκρισιν οὐκ ἔδωκεν αὐτῷ.
43019010 Λέγει οὖν αὐτῷ ὁ Πιλᾶτος, “Ἐμοὶ οὐ λαλεῖς; Οὐκ οἶδας ὅτι ἐξουσίαν ἔχω ἀπολῦσαί σε, καὶ ἐξουσίαν ἔχω σταυρῶσαί σε;”
43019011 Ἀπεκρίθη αὐτῷ ˚Ἰησοῦς, “Οὐκ εἶχες ἐξουσίαν κατʼ ἐμοῦ οὐδεμίαν, εἰ μὴ ἦν δεδομένον σοι ἄνωθεν. Διὰ τοῦτο ὁ παραδούς μέ σοι, μείζονα ἁμαρτίαν ἔχει.”
43019012 Ἐκ τούτου ὁ Πιλᾶτος ἐζήτει ἀπολῦσαι αὐτόν. Οἱ δὲ Ἰουδαῖοι ἐκραύγασαν λέγοντες, “Ἐὰν τοῦτον ἀπολύσῃς, οὐκ εἶ φίλος τοῦ Καίσαρος. Πᾶς ὁ βασιλέα ἑαυτὸν ποιῶν, ἀντιλέγει τῷ Καίσαρι.”
43019013 Ὁ οὖν Πιλᾶτος, ἀκούσας τῶν λόγων τούτων, ἤγαγεν ἔξω τὸν ˚Ἰησοῦν, καὶ ἐκάθισεν ἐπὶ βήματος, εἰς τόπον λεγόμενον Λιθόστρωτον, Ἑβραϊστὶ δὲ, Γαββαθα.
43019014 Ἦν δὲ παρασκευὴ τοῦ Πάσχα, ὥρα ἦν ὡς ἕκτη. Καὶ λέγει τοῖς Ἰουδαίοις, “Ἴδε, ὁ βασιλεὺς ὑμῶν!”
43019015 Οἱ δὲ ἐκραύγασαν, “Ἆρον! Ἆρον! Σταύρωσον αὐτόν!” Λέγει αὐτοῖς ὁ Πιλᾶτος, “Τὸν βασιλέα ὑμῶν σταυρώσω;” Ἀπεκρίθησαν οἱ ἀρχιερεῖς, “Οὐκ ἔχομεν βασιλέα, εἰ μὴ Καίσαρα.”
43019016 Τότε οὖν παρέδωκεν αὐτὸν αὐτοῖς, ἵνα σταυρωθῇ. Παρέλαβον Δὲ τὸν ˚Ἰησοῦν· αὑτόν ἀπήγαγον
43019017 Καὶ βαστάζων ἑαυτῷ τὸν σταυρὸν, ἐξῆλθεν εἰς τὸν λεγόμενον, “Κρανίου Τόπον”, ὃ λέγεται Ἑβραϊστὶ, Γολγοθᾶ,
43019018 ὅπου αὐτὸν ἐσταύρωσαν, καὶ μετʼ αὐτοῦ ἄλλους δύο, ἐντεῦθεν καὶ ἐντεῦθεν, μέσον δὲ τὸν ˚Ἰησοῦν.
43019019 Ἔγραψεν δὲ καὶ τίτλον ὁ Πιλᾶτος, καὶ ἔθηκεν ἐπὶ τοῦ σταυροῦ. Ἦν δὲ γεγραμμένον, “˚Ἰησοῦς ὁ Ναζωραῖος, ὁ Βασιλεὺς τῶν Ἰουδαίων.”
43019020 Τοῦτον οὖν τὸν τίτλον πολλοὶ ἀνέγνωσαν τῶν Ἰουδαίων, ὅτι ἐγγὺς ἦν ὁ τόπος τῆς πόλεως, ὅπου ἐσταυρώθη ὁ ˚Ἰησοῦς· καὶ ἦν γεγραμμένον Ἑβραϊστί, Ῥωμαϊστί, Ἑλληνιστί.
43019021 Ἔλεγον οὖν τῷ Πιλάτῳ οἱ ἀρχιερεῖς τῶν Ἰουδαίων, “Μὴ γράφε, ‘Ὁ Βασιλεὺς τῶν Ἰουδαίων’, ἀλλʼ ὅτι ἐκεῖνος εἶπεν, ‘Βασιλεὺς εἰμι τῶν Ἰουδαίων.’”
43019022 Ἀπεκρίθη ὁ Πιλᾶτος, “Ὃ γέγραφα, γέγραφα.”
43019023 Οἱ οὖν στρατιῶται ὅτε ἐσταύρωσαν τὸν ˚Ἰησοῦν, ἔλαβον τὰ ἱμάτια αὐτοῦ, καὶ ἐποίησαν τέσσαρα μέρη, ἑκάστῳ στρατιώτῃ μέρος, καὶ τὸν χιτῶνα. Ἦν δὲ ὁ χιτὼν ἄραφος, ἐκ τῶν ἄνωθεν ὑφαντὸς διʼ ὅλου.
43019024 Εἶπον οὖν πρὸς ἀλλήλους, “Μὴ σχίσωμεν αὐτόν, ἀλλὰ λάχωμεν περὶ αὐτοῦ, τίνος ἔσται”· ἵνα ἡ Γραφὴ πληρωθῇ ἡ λέγουσα, “Διεμερίσαντο τὰ ἱμάτιά μου ἑαυτοῖς, καὶ ἐπὶ τὸν ἱματισμόν μου ἔβαλον κλῆρον.” Οἱ μὲν οὖν στρατιῶται ταῦτα ἐποίησαν.
43019025 Εἱστήκεισαν δὲ παρὰ τῷ σταυρῷ τοῦ ˚Ἰησοῦ ἡ μήτηρ αὐτοῦ, καὶ ἡ ἀδελφὴ τῆς μητρὸς αὐτοῦ, Μαρία ἡ τοῦ Κλωπᾶ, καὶ Μαρία ἡ Μαγδαληνή.
43019026 ˚Ἰησοῦς οὖν ἰδὼν τὴν μητέρα, καὶ τὸν μαθητὴν παρεστῶτα ὃν ἠγάπα, λέγει τῇ μητρί, “Γύναι, ἰδοὺ, ὁ υἱός σου.”
43019027 Εἶτα λέγει τῷ μαθητῇ, “Ἴδε, ἡ μήτηρ σου.” Καὶ ἀπʼ ἐκείνης τῆς ὥρας ἔλαβεν ὁ μαθητὴς αὐτὴν εἰς τὰ ἴδια.
43019028 Μετὰ τοῦτο εἰδὼς ὁ ˚Ἰησοῦς ὅτι ἤδη πάντα τετέλεσται, ἵνα τελειωθῇ ἡ Γραφὴ, λέγει, “Διψῶ.”
43019029 Σκεῦος ἔκειτο ὄξους μεστόν· σπόγγον οὖν μεστὸν τοῦ ὄξους ὑσσώπῳ περιθέντες, προσήνεγκαν αὐτοῦ τῷ στόματι.
43019030 Ὅτε οὖν ἔλαβεν τὸ ὄξος ὁ ˚Ἰησοῦς, εἶπεν, “Τετέλεσται!” Καὶ κλίνας τὴν κεφαλὴν, παρέδωκεν τὸ πνεῦμα.
43019031 Οἱ οὖν Ἰουδαῖοι, ἐπεὶ παρασκευὴ ἦν, ἵνα μὴ μείνῃ ἐπὶ τοῦ σταυροῦ τὰ σώματα ἐν τῷ Σαββάτῳ ( ἦν γὰρ μεγάλη ἡ ἡμέρα ἐκείνου τοῦ Σαββάτου ), ἠρώτησαν τὸν Πιλᾶτον ἵνα κατεαγῶσιν αὐτῶν τὰ σκέλη, καὶ ἀρθῶσιν.
43019032 Ἦλθον οὖν οἱ στρατιῶται, καὶ τοῦ μὲν πρώτου κατέαξαν τὰ σκέλη, καὶ τοῦ ἄλλου τοῦ συσταυρωθέντος αὐτῷ·
43019033 ἐπὶ δὲ τὸν ˚Ἰησοῦν ἐλθόντες, ὡς εἶδον ἤδη αὐτὸν τεθνηκότα, οὐ κατέαξαν αὐτοῦ τὰ σκέλη.
43019034 Ἀλλʼ εἷς τῶν στρατιωτῶν λόγχῃ αὐτοῦ τὴν πλευρὰν ἔνυξεν, καὶ ἐξῆλθεν εὐθὺς αἷμα καὶ ὕδωρ.
43019035 Καὶ ὁ ἑωρακὼς μεμαρτύρηκεν, καὶ ἀληθινὴ αὐτοῦ ἐστιν ἡ μαρτυρία, καὶ ἐκεῖνος οἶδεν ὅτι ἀληθῆ λέγει, ἵνα καὶ ὑμεῖς πιστεύητε.
43019036 Ἐγένετο γὰρ ταῦτα, ἵνα ἡ Γραφὴ πληρωθῇ, “Ὀστοῦν οὐ συντριβήσεται αὐτοῦ.”
43019037 Καὶ πάλιν ἑτέρα Γραφὴ λέγει, “Ὄψονται εἰς ὃν ἐξεκέντησαν.”
43019038 Μετὰ δὲ ταῦτα, ἠρώτησεν τὸν Πιλᾶτον Ἰωσὴφ ὁ ἀπὸ Ἁριμαθαίας, ὢν μαθητὴς τοῦ ˚Ἰησοῦ, κεκρυμμένος δὲ διὰ τὸν φόβον τῶν Ἰουδαίων, ἵνα ἄρῃ τὸ σῶμα τοῦ ˚Ἰησοῦ· καὶ ἐπέτρεψεν ὁ Πιλᾶτος. Ἦλθον οὖν καὶ ἦραν αὑτόν.
43019039 Ἦλθεν δὲ καὶ Νικόδημος, ὁ ἐλθὼν πρὸς αὐτὸν νυκτὸς τὸ πρῶτον, φέρων μίγμα σμύρνης καὶ ἀλόης, ὡσεὶ λίτρας ἑκατόν.
43019040 Ἔλαβον οὖν τὸ σῶμα τοῦ ˚Ἰησοῦ, καὶ ἔδησαν αὐτὸ ὀθονίοις μετὰ τῶν ἀρωμάτων, καθὼς ἔθος ἐστὶν τοῖς Ἰουδαίοις ἐνταφιάζειν.
43019041 Ἦν δὲ ἐν τῷ τόπῳ ὅπου ἐσταυρώθη κῆπος, καὶ ἐν τῷ κήπῳ μνημεῖον καινόν, ἐν ᾧ οὐδέπω οὐδεὶς ἦν τεθειμένος.
43019042 Ἐκεῖ οὖν διὰ τὴν παρασκευὴν τῶν Ἰουδαίων, ὅτι ἐγγὺς ἦν τὸ μνημεῖον, ἔθηκαν τὸν ˚Ἰησοῦν.
43020001 Τῇ δὲ μιᾷ τῶν σαββάτων, Μαρία ἡ Μαγδαληνὴ ἔρχεται πρωῒ, σκοτίας ἔτι οὔσης, εἰς τὸ μνημεῖον, καὶ βλέπει τὸν λίθον ἠρμένον ἐκ τοῦ μνημείου.
43020002 Τρέχει οὖν καὶ ἔρχεται πρὸς Σίμωνα Πέτρον, καὶ πρὸς τὸν ἄλλον μαθητὴν ὃν ἐφίλει ὁ ˚Ἰησοῦς, καὶ λέγει αὐτοῖς, “Ἦραν τὸν ˚Κύριον ἐκ τοῦ μνημείου, καὶ οὐκ οἴδαμεν ποῦ ἔθηκαν αὐτόν.”
43020003 Ἐξῆλθεν οὖν ὁ Πέτρος καὶ ὁ ἄλλος μαθητής, καὶ ἤρχοντο εἰς τὸ μνημεῖον.
43020004 Ἔτρεχον δὲ οἱ δύο ὁμοῦ, καὶ ὁ ἄλλος μαθητὴς προέδραμεν τάχιον τοῦ Πέτρου, καὶ ἦλθεν πρῶτος εἰς τὸ μνημεῖον.
43020005 Καὶ παρακύψας, βλέπει κείμενα τὰ ὀθόνια, οὐ μέντοι εἰσῆλθεν.
43020006 Ἔρχεται οὖν καὶ Σίμων Πέτρος ἀκολουθῶν αὐτῷ, καὶ εἰσῆλθεν εἰς τὸ μνημεῖον, καὶ θεωρεῖ τὰ ὀθόνια κείμενα,
43020007 καὶ τὸ σουδάριον, ὃ ἦν ἐπὶ τῆς κεφαλῆς αὐτοῦ, οὐ μετὰ τῶν ὀθονίων κείμενον, ἀλλὰ χωρὶς ἐντετυλιγμένον εἰς ἕνα τόπον.
43020008 Τότε οὖν εἰσῆλθεν καὶ ὁ ἄλλος μαθητὴς, ὁ ἐλθὼν πρῶτος εἰς τὸ μνημεῖον, καὶ εἶδεν καὶ ἐπίστευσεν·
43020009 οὐδέπω γὰρ ᾔδεισαν τὴν Γραφὴν ὅτι δεῖ αὐτὸν ἐκ νεκρῶν ἀναστῆναι.
43020010 Ἀπῆλθον οὖν πάλιν πρὸς αὑτοὺς οἱ μαθηταί.
43020011 Μαρία δὲ εἱστήκει πρὸς τῷ μνημείῳ ἔξω κλαίουσα. Ὡς οὖν ἔκλαιεν, παρέκυψεν εἰς τὸ μνημεῖον,
43020012 καὶ θεωρεῖ δύο ἀγγέλους ἐν λευκοῖς καθεζομένους, ἕνα πρὸς τῇ κεφαλῇ, καὶ ἕνα πρὸς τοῖς ποσίν, ὅπου ἔκειτο τὸ σῶμα τοῦ ˚Ἰησοῦ.
43020013 Καὶ λέγουσιν αὐτῇ ἐκεῖνοι, “Γύναι, τί κλαίεις;” Λέγει αὐτοῖς, “Ὅτι ἦραν τὸν ˚Κύριόν μου, καὶ οὐκ οἶδα ποῦ ἔθηκαν αὐτόν.”
43020014 Ταῦτα εἰποῦσα, ἐστράφη εἰς τὰ ὀπίσω, καὶ θεωρεῖ τὸν ˚Ἰησοῦν ἑστῶτα, καὶ οὐκ ᾔδει ὅτι ˚Ἰησοῦς ἐστιν.
43020015 Λέγει αὐτῇ ˚Ἰησοῦς, “Γύναι, τί κλαίεις; Τίνα ζητεῖς;” Ἐκείνη δοκοῦσα ὅτι ὁ κηπουρός ἐστιν, λέγει αὐτῷ, “Κύριε, εἰ σὺ ἐβάστασας αὐτόν, εἰπέ μοι ποῦ ἔθηκας αὐτόν, κἀγὼ αὐτὸν ἀρῶ.”
43020016 Λέγει αὐτῇ ὁ ˚Ἰησοῦς, “Μαριάμ!” Στραφεῖσα ἐκείνη λέγει αὐτῷ Ἑβραϊστί, “Ῥαββουνί” ( ὃ λέγεται, “Διδάσκαλε” ).
43020017 Λέγει αὐτῇ ὁ ˚Ἰησοῦς, “Μή μου ἅπτου, οὔπω γὰρ ἀναβέβηκα πρὸς τὸν Πατέρα· πορεύου δὲ πρὸς τοὺς ἀδελφούς μου, καὶ εἰπὲ αὐτοῖς, ‘Ἀναβαίνω πρὸς τὸν Πατέρα μου, καὶ Πατέρα ὑμῶν, καὶ ˚Θεόν μου, καὶ ˚Θεὸν ὑμῶν.’”
43020018 Ἔρχεται Μαριὰμ ἡ Μαγδαληνὴ ἀγγέλλουσα τοῖς μαθηταῖς, ὅτι “Ἑώρακα τὸν ˚Κύριον”, καὶ ταῦτα εἶπεν αὐτῇ.
43020019 Οὔσης οὖν ὀψίας τῇ ἡμέρᾳ ἐκείνῃ τῇ μιᾷ σαββάτων, καὶ τῶν θυρῶν κεκλεισμένων ὅπου ἦσαν οἱ μαθηταὶ διὰ τὸν φόβον τῶν Ἰουδαίων, ἦλθεν ὁ ˚Ἰησοῦς καὶ ἔστη εἰς τὸ μέσον, καὶ λέγει αὐτοῖς, “Εἰρήνη ὑμῖν.”
43020020 Καὶ τοῦτο εἰπὼν, ἔδειξεν τὰς χεῖρας καὶ τὴν πλευρὰν αὐτοῖς. Ἐχάρησαν οὖν οἱ μαθηταὶ, ἰδόντες τὸν ˚Κύριον.
43020021 Εἶπεν οὖν αὐτοῖς ὁ ˚Ἰησοῦς πάλιν, “Εἰρήνη ὑμῖν· καθὼς ἀπέσταλκέν με ὁ Πατήρ, κἀγὼ πέμπω ὑμᾶς.”
43020022 Καὶ τοῦτο εἰπὼν, ἐνεφύσησεν καὶ λέγει αὐτοῖς, “Λάβετε ˚Πνεῦμα Ἅγιον.
43020023 Ἄν τινων ἀφῆτε τὰς ἁμαρτίας, ἀφέωνται αὐτοῖς· ἄν τινων κρατῆτε, κεκράτηνται.”
43020024 Θωμᾶς δὲ, εἷς ἐκ τῶν δώδεκα, ὁ λεγόμενος Δίδυμος, οὐκ ἦν μετʼ αὐτῶν ὅτε ἦλθεν ˚Ἰησοῦς.
43020025 Ἔλεγον οὖν αὐτῷ οἱ ἄλλοι μαθηταί, “Ἑωράκαμεν τὸν ˚Κύριον.” Ὁ δὲ εἶπεν αὐτοῖς, “Ἐὰν μὴ ἴδω ἐν ταῖς χερσὶν αὐτοῦ τὸν τύπον τῶν ἥλων, καὶ βάλω τὸν δάκτυλόν μου εἰς τὸν τύπον τῶν ἥλων, καὶ βάλω μου τὴν χεῖρα εἰς τὴν πλευρὰν αὐτοῦ, οὐ μὴ πιστεύσω.”
43020026 Καὶ μεθʼ ἡμέρας ὀκτὼ πάλιν ἦσαν ἔσω οἱ μαθηταὶ αὐτοῦ, καὶ Θωμᾶς μετʼ αὐτῶν. Ἔρχεται ὁ ˚Ἰησοῦς, τῶν θυρῶν κεκλεισμένων, καὶ ἔστη εἰς τὸ μέσον, καὶ εἶπεν, “Εἰρήνη ὑμῖν.”
43020027 Εἶτα λέγει τῷ Θωμᾷ, “Φέρε τὸν δάκτυλόν σου ὧδε, καὶ ἴδε τὰς χεῖράς μου, καὶ φέρε τὴν χεῖρά σου, καὶ βάλε εἰς τὴν πλευράν μου, καὶ μὴ γίνου ἄπιστος, ἀλλὰ πιστός.”
43020028 Ἀπεκρίθη Θωμᾶς καὶ εἶπεν αὐτῷ, “Ὁ ˚Κύριός μου καὶ ὁ ˚Θεός μου!”
43020029 Λέγει αὐτῷ ὁ ˚Ἰησοῦς, “Ὅτι ἑώρακάς με, πεπίστευκας· μακάριοι οἱ μὴ ἰδόντες, καὶ πιστεύσαντες.”
43020030 Πολλὰ μὲν οὖν καὶ ἄλλα σημεῖα ἐποίησεν ὁ ˚Ἰησοῦς ἐνώπιον τῶν μαθητῶν αὐτοῦ, ἃ οὐκ ἔστιν γεγραμμένα ἐν τῷ βιβλίῳ τούτῳ.
43020031 Ταῦτα δὲ γέγραπται, ἵνα πιστεύητε ὅτι ˚Ἰησοῦς ἐστιν ὁ ˚Χριστὸς, ὁ Υἱὸς τοῦ ˚Θεοῦ, καὶ ἵνα πιστεύοντες, ζωὴν ἔχητε ἐν τῷ ὀνόματι αὐτοῦ.
43021001 Μετὰ ταῦτα, ἐφανέρωσεν ἑαυτὸν πάλιν ˚Ἰησοῦς τοῖς μαθηταῖς ἐπὶ τῆς θαλάσσης τῆς Τιβεριάδος· ἐφανέρωσεν δὲ οὕτως:
43021002 ἦσαν ὁμοῦ Σίμων Πέτρος, καὶ Θωμᾶς ὁ λεγόμενος Δίδυμος, καὶ Ναθαναὴλ ὁ ἀπὸ Κανὰ τῆς Γαλιλαίας, καὶ οἱ τοῦ Ζεβεδαίου, καὶ ἄλλοι ἐκ τῶν μαθητῶν αὐτοῦ δύο.
43021003 Λέγει αὐτοῖς Σίμων Πέτρος, “Ὑπάγω ἁλιεύειν.” Λέγουσιν αὐτῷ, “Ἐρχόμεθα καὶ ἡμεῖς σὺν σοί.” Ἐξῆλθον καὶ ἐνέβησαν εἰς τὸ πλοῖον, καὶ ἐν ἐκείνῃ τῇ νυκτὶ ἐπίασαν οὐδέν.
43021004 Πρωΐας δὲ ἤδη γινομένης, ἔστη ˚Ἰησοῦς εἰς τὸν αἰγιαλόν· οὐ μέντοι ᾔδεισαν οἱ μαθηταὶ ὅτι ˚Ἰησοῦς ἐστιν.
43021005 Λέγει οὖν αὐτοῖς ˚Ἰησοῦς, “Παιδία, μή τι προσφάγιον ἔχετε;” Ἀπεκρίθησαν αὐτῷ, “Οὔ.”
43021006 Ὁ δὲ εἶπεν αὐτοῖς, “Βάλετε εἰς τὰ δεξιὰ μέρη τοῦ πλοίου τὸ δίκτυον, καὶ εὑρήσετε.” Ἔβαλον οὖν, καὶ οὐκέτι αὐτὸ ἑλκύσαι ἴσχυον ἀπὸ τοῦ πλήθους τῶν ἰχθύων.
43021007 Λέγει οὖν ὁ μαθητὴς ἐκεῖνος ὃν ἠγάπα ὁ ˚Ἰησοῦς τῷ Πέτρῳ, “Ὁ ˚Κύριός ἐστιν.” Σίμων οὖν Πέτρος ἀκούσας ὅτι ὁ ˚Κύριός ἐστιν, τὸν ἐπενδύτην διεζώσατο, ἦν γὰρ γυμνός, καὶ ἔβαλεν ἑαυτὸν εἰς τὴν θάλασσαν.
43021008 Οἱ δὲ ἄλλοι μαθηταὶ τῷ πλοιαρίῳ ἦλθον, οὐ γὰρ ἦσαν μακρὰν ἀπὸ τῆς γῆς, ἀλλὰ ὡς ἀπὸ πηχῶν διακοσίων, σύροντες τὸ δίκτυον τῶν ἰχθύων.
43021009 Ὡς οὖν ἀπέβησαν εἰς τὴν γῆν, βλέπουσιν ἀνθρακιὰν κειμένην, καὶ ὀψάριον ἐπικείμενον, καὶ ἄρτον.
43021010 Λέγει αὐτοῖς ὁ ˚Ἰησοῦς, “Ἐνέγκατε ἀπὸ τῶν ὀψαρίων ὧν ἐπιάσατε νῦν.”
43021011 Ἀνέβη οὖν Σίμων Πέτρος, καὶ εἵλκυσεν τὸ δίκτυον εἰς τὴν γῆν μεστὸν ἰχθύων μεγάλων ἑκατὸν πεντήκοντα τριῶν· καὶ τοσούτων ὄντων, οὐκ ἐσχίσθη τὸ δίκτυον.
43021012 Λέγει αὐτοῖς ὁ ˚Ἰησοῦς, “Δεῦτε, ἀριστήσατε.” Οὐδεὶς δὲ ἐτόλμα τῶν μαθητῶν ἐξετάσαι αὐτόν, “Σὺ τίς εἶ;” Εἰδότες ὅτι ὁ ˚Κύριός ἐστιν.
43021013 Ἔρχεται ˚Ἰησοῦς, καὶ λαμβάνει τὸν ἄρτον καὶ δίδωσιν αὐτοῖς, καὶ τὸ ὀψάριον ὁμοίως.
43021014 Τοῦτο ἤδη τρίτον ἐφανερώθη ˚Ἰησοῦς τοῖς μαθηταῖς, ἐγερθεὶς ἐκ νεκρῶν.
43021015 Ὅτε οὖν ἠρίστησαν, λέγει τῷ Σίμωνι Πέτρῳ ὁ ˚Ἰησοῦς, “Σίμων Ἰωάννου, ἀγαπᾷς με πλέον τούτων;” Λέγει αὐτῷ, “Ναί, ˚Κύριε, σὺ οἶδας ὅτι φιλῶ σε.” Λέγει αὐτῷ, “Βόσκε τὰ ἀρνία μου.”
43021016 Λέγει αὐτῷ πάλιν δεύτερον, “Σίμων Ἰωάννου, ἀγαπᾷς με;” Λέγει αὐτῷ, “Ναί, ˚Κύριε, σὺ οἶδας ὅτι φιλῶ σε.” Λέγει αὐτῷ, “Ποίμαινε τὰ πρόβατά μου.”
43021017 Λέγει αὐτῷ τὸ τρίτον, “Σίμων Ἰωάννου, φιλεῖς με;” Ἐλυπήθη ὁ Πέτρος ὅτι εἶπεν αὐτῷ τὸ τρίτον, “Φιλεῖς με;” Καὶ λέγει αὐτῷ, “˚Κύριε, πάντα σὺ οἶδας, σὺ γινώσκεις ὅτι φιλῶ σε.” Λέγει αὐτῷ ˚Ἰησοῦς, “Βόσκε τὰ πρόβατά μου.”
43021018 Ἀμὴν, ἀμὴν, λέγω σοι, ὅτε ἦς νεώτερος, ἐζώννυες σεαυτὸν, καὶ περιεπάτεις ὅπου ἤθελες· ὅταν δὲ γηράσῃς, ἐκτενεῖς τὰς χεῖράς σου, καὶ ἄλλος ζώσει σε, καὶ οἴσει ὅπου οὐ θέλεις.
43021019 Τοῦτο δὲ εἶπεν, σημαίνων ποίῳ θανάτῳ δοξάσει τὸν ˚Θεόν. Καὶ τοῦτο εἰπὼν, λέγει αὐτῷ, “Ἀκολούθει μοι.”
43021020 Ἐπιστραφεὶς, ὁ Πέτρος βλέπει τὸν μαθητὴν ὃν ἠγάπα ὁ ˚Ἰησοῦς ἀκολουθοῦντα, ὃς καὶ ἀνέπεσεν ἐν τῷ δείπνῳ ἐπὶ τὸ στῆθος αὐτοῦ καὶ εἶπεν, “˚Κύριε, τίς ἐστιν, ὁ παραδιδούς σε;”
43021021 Τοῦτον οὖν ἰδὼν, ὁ Πέτρος λέγει τῷ ˚Ἰησοῦ, “˚Κύριε, οὗτος δὲ τί;”
43021022 Λέγει αὐτῷ ὁ ˚Ἰησοῦς, “Ἐὰν αὐτὸν θέλω μένειν ἕως ἔρχομαι, τί πρὸς σέ; Σύ μοι ἀκολούθει!”
43021023 Ἐξῆλθεν οὖν οὗτος ὁ λόγος εἰς τοὺς ἀδελφοὺς, ὅτι ὁ μαθητὴς ἐκεῖνος οὐκ ἀποθνῄσκει. Οὐκ εἶπεν δὲ αὐτῷ ὁ ˚Ἰησοῦς ὅτι Οὐκ ἀποθνῄσκει, ἀλλʼ, “Ἐὰν αὐτὸν θέλω μένειν ἕως ἔρχομαι, τί πρὸς σέ;”
43021024 Οὗτός ἐστιν ὁ μαθητὴς ὁ μαρτυρῶν περὶ τούτων, καὶ ὁ γράψας ταῦτα, καὶ οἴδαμεν ὅτι ἀληθὴς αὐτοῦ ἡ μαρτυρία ἐστίν.
43021025 Ἔστιν δὲ καὶ ἄλλα πολλὰ ἃ ἐποίησεν ὁ ˚Ἰησοῦς, ἅτινα ἐὰν γράφηται καθʼ ἕν, οὐδʼ αὐτὸν οἶμαι τὸν κόσμον χωρήσειν τὰ γραφόμενα βιβλία.
44001001 Τὸν μὲν πρῶτον λόγον ἐποιησάμην περὶ πάντων, ὦ Θεόφιλε, ὧν ἤρξατο ˚Ἰησοῦς ποιεῖν τε καὶ διδάσκειν,
44001002 ἄχρι ἧς ἡμέρας ἐντειλάμενος τοῖς ἀποστόλοις διὰ ˚Πνεύματος Ἁγίου, οὓς ἐξελέξατο, ἀνελήμφθη·
44001003 οἷς καὶ παρέστησεν ἑαυτὸν ζῶντα, μετὰ τὸ παθεῖν αὐτὸν, ἐν πολλοῖς τεκμηρίοις διʼ ἡμερῶν τεσσεράκοντα, ὀπτανόμενος αὐτοῖς, καὶ λέγων τὰ περὶ τῆς Βασιλείας τοῦ ˚Θεοῦ.
44001004 Καὶ συναλιζόμενος, παρήγγειλεν αὐτοῖς, “Ἀπὸ Ἱεροσολύμων μὴ χωρίζεσθαι, ἀλλὰ περιμένειν τὴν ἐπαγγελίαν τοῦ Πατρὸς, ἣν ἠκούσατέ μου.
44001005 Ὅτι Ἰωάννης μὲν ἐβάπτισεν ὕδατι, ὑμεῖς δὲ ἐν ˚Πνεύματι βαπτισθήσεσθε Ἁγίῳ, οὐ μετὰ πολλὰς ταύτας ἡμέρας.”
44001006 Οἱ μὲν οὖν συνελθόντες, ἠρώτων αὐτὸν λέγοντες, “˚Κύριε, εἰ ἐν τῷ χρόνῳ τούτῳ, ἀποκαθιστάνεις τὴν βασιλείαν τῷ Ἰσραήλ;”
44001007 Εἶπεν δὲ πρὸς αὐτούς, “Οὐχ ὑμῶν ἐστιν γνῶναι χρόνους ἢ καιροὺς, οὓς ὁ Πατὴρ ἔθετο ἐν τῇ ἰδίᾳ ἐξουσίᾳ.
44001008 Ἀλλὰ λήμψεσθε δύναμιν, ἐπελθόντος τοῦ Ἁγίου ˚Πνεύματος ἐφʼ ὑμᾶς, καὶ ἔσεσθέ μου μάρτυρες, ἔν τε Ἰερουσαλὴμ, καὶ ἐν πάσῃ τῇ Ἰουδαίᾳ, καὶ Σαμαρείᾳ, καὶ ἕως ἐσχάτου τῆς γῆς.”
44001009 Καὶ ταῦτα εἰπὼν, βλεπόντων αὐτῶν, ἐπήρθη καὶ νεφέλη ὑπέλαβεν αὐτὸν ἀπὸ τῶν ὀφθαλμῶν αὐτῶν.
44001010 Καὶ ὡς ἀτενίζοντες ἦσαν εἰς τὸν οὐρανὸν, πορευομένου αὐτοῦ, καὶ ἰδοὺ ἄνδρες δύο παρειστήκεισαν αὐτοῖς ἐν ἐσθήσεσι λευκαῖς,
44001011 οἳ καὶ εἶπαν, “Ἄνδρες, Γαλιλαῖοι, τί ἑστήκατε βλέποντες εἰς τὸν οὐρανόν; Οὗτος ὁ ˚Ἰησοῦς ὁ ἀναλημφθεὶς ἀφʼ ὑμῶν εἰς τὸν οὐρανὸν, οὕτως ἐλεύσεται ὃν τρόπον ἐθεάσασθε αὐτὸν πορευόμενον εἰς τὸν οὐρανόν.”
44001012 Τότε ὑπέστρεψαν εἰς Ἰερουσαλὴμ ἀπὸ ὄρους τοῦ καλουμένου Ἐλαιῶνος, ὅ ἐστιν ἐγγὺς Ἰερουσαλὴμ, Σαββάτου ἔχον ὁδόν.
44001013 Καὶ ὅτε εἰσῆλθον, εἰς τὸ ὑπερῷον, ἀνέβησαν οὗ ἦσαν καταμένοντες, ὅ τε Πέτρος καὶ Ἰωάννης καὶ Ἰάκωβος καὶ Ἀνδρέας, Φίλιππος καὶ Θωμᾶς, Βαρθολομαῖος καὶ Μαθθαῖος, Ἰάκωβος Ἁλφαίου, καὶ Σίμων ὁ Ζηλωτὴς, καὶ Ἰούδας Ἰακώβου.
44001014 Οὗτοι πάντες ἦσαν προσκαρτεροῦντες ὁμοθυμαδὸν τῇ προσευχῇ, σὺν γυναιξὶν καὶ Μαρίᾳ τῇ μητρὶ τοῦ ˚Ἰησοῦ, καὶ τοῖς ἀδελφοῖς αὐτοῦ.
44001015 Καὶ ἐν ταῖς ἡμέραις ταύταις, ἀναστὰς Πέτρος ἐν μέσῳ τῶν ἀδελφῶν εἶπεν ( ἦν τε ὄχλος ὀνομάτων ἐπὶ τὸ αὐτὸ ὡσεὶ ἑκατὸν εἴκοσι ),
44001016 “Ἄνδρες, ἀδελφοί, ἔδει πληρωθῆναι τὴν Γραφὴν, ἣν προεῖπεν τὸ ˚Πνεῦμα τὸ Ἅγιον διὰ στόματος Δαυὶδ περὶ Ἰούδα, τοῦ γενομένου ὁδηγοῦ τοῖς συλλαβοῦσιν ˚Ἰησοῦν.
44001017 Ὅτι κατηριθμημένος ἦν ἐν ἡμῖν, καὶ ἔλαχεν τὸν κλῆρον τῆς διακονίας ταύτης.”
44001018 ( Οὗτος μὲν οὖν ἐκτήσατο χωρίον ἐκ μισθοῦ τῆς ἀδικίας, καὶ πρηνὴς γενόμενος, ἐλάκησεν μέσος, καὶ ἐξεχύθη πάντα τὰ σπλάγχνα αὐτοῦ.
44001019 Καὶ γνωστὸν ἐγένετο πᾶσι τοῖς κατοικοῦσιν Ἰερουσαλήμ, ὥστε κληθῆναι τὸ χωρίον ἐκεῖνο τῇ διαλέκτῳ αὐτῶν, Ἁκελδαμάχ, τοῦτʼ ἔστιν, “Χωρίον Αἵματος”. )
44001020 “Γέγραπται γὰρ ἐν βίβλῳ Ψαλμῶν, ‘Γενηθήτω ἡ ἔπαυλις αὐτοῦ ἔρημος, καὶ μὴ ἔστω ὁ κατοικῶν ἐν αὐτῇ’, καί, ‘Τὴν ἐπισκοπὴν αὐτοῦ, λαβέτω ἕτερος.’
44001021 Δεῖ οὖν τῶν συνελθόντων ἡμῖν ἀνδρῶν, ἐν παντὶ χρόνῳ ᾧ εἰσῆλθεν καὶ ἐξῆλθεν ἐφʼ ἡμᾶς ὁ ˚Κύριος ˚Ἰησοῦς,
44001022 ἀρξάμενος ἀπὸ τοῦ βαπτίσματος Ἰωάννου ἕως τῆς ἡμέρας ἧς ἀνελήμφθη ἀφʼ ἡμῶν, μάρτυρα τῆς ἀναστάσεως αὐτοῦ σὺν ἡμῖν, γενέσθαι ἕνα τούτων.”
44001023 Καὶ ἔστησαν δύο, Ἰωσὴφ τὸν καλούμενον Βαρσαββᾶν, ὃς ἐπεκλήθη Ἰοῦστος, καὶ Μαθθίαν.
44001024 Καὶ προσευξάμενοι, εἶπαν, “Σὺ ˚Κύριε, καρδιογνῶστα πάντων, ἀνάδειξον ὃν ἐξελέξω ἐκ τούτων τῶν δύο ἕνα,
44001025 λαβεῖν τὸν τόπον τῆς διακονίας ταύτης καὶ ἀποστολῆς, ἀφʼ ἧς παρέβη Ἰούδας, πορευθῆναι εἰς τὸν τόπον τὸν ἴδιον.”
44001026 Καὶ ἔδωκαν κλήρους αὐτοῖς, καὶ ἔπεσεν ὁ κλῆρος ἐπὶ Μαθθίαν, καὶ συγκατεψηφίσθη μετὰ τῶν ἕνδεκα ἀποστόλων.
44002001 Καὶ ἐν τῷ συμπληροῦσθαι τὴν ἡμέραν τῆς Πεντηκοστῆς, ἦσαν πάντες ὁμοῦ ἐπὶ τὸ αὐτό.
44002002 Καὶ ἐγένετο ἄφνω ἐκ τοῦ οὐρανοῦ ἦχος, ὥσπερ φερομένης πνοῆς βιαίας, καὶ ἐπλήρωσεν ὅλον τὸν οἶκον οὗ ἦσαν καθήμενοι.
44002003 Καὶ ὤφθησαν αὐτοῖς διαμεριζόμεναι γλῶσσαι ὡσεὶ πυρός, καὶ ἐκάθισεν ἐφʼ ἕνα ἕκαστον αὐτῶν.
44002004 Καὶ ἐπλήσθησαν πάντες ˚Πνεύματος Ἁγίου, καὶ ἤρξαντο λαλεῖν ἑτέραις γλώσσαις, καθὼς τὸ ˚Πνεῦμα ἐδίδου ἀποφθέγγεσθαι αὐτοῖς.
44002005 Ἦσαν δὲ ἐν Ἰερουσαλὴμ κατοικοῦντες Ἰουδαῖοι, ἄνδρες εὐλαβεῖς ἀπὸ παντὸς ἔθνους τῶν ὑπὸ τὸν οὐρανόν.
44002006 Γενομένης δὲ τῆς φωνῆς ταύτης, συνῆλθε τὸ πλῆθος καὶ συνεχύθη, ὅτι ἤκουσεν εἷς ἕκαστος τῇ ἰδίᾳ διαλέκτῳ λαλούντων αὐτῶν.
44002007 Ἐξίσταντο δὲ καὶ ἐθαύμαζον λέγοντες, “Οὐχ ἰδοὺ, ἅπαντες οὗτοί εἰσιν οἱ λαλοῦντες Γαλιλαῖοι;
44002008 Καὶ πῶς ἡμεῖς ἀκούομεν ἕκαστος τῇ ἰδίᾳ διαλέκτῳ ἡμῶν, ἐν ᾗ ἐγεννήθημεν;
44002009 Πάρθοι, καὶ Μῆδοι, καὶ Ἐλαμῖται, καὶ οἱ κατοικοῦντες τὴν Μεσοποταμίαν, Ἰουδαίαν τε καὶ Καππαδοκίαν, Πόντον καὶ τὴν Ἀσίαν,
44002010 Φρυγίαν τε καὶ Παμφυλίαν, Αἴγυπτον, καὶ τὰ μέρη τῆς Λιβύης τῆς κατὰ Κυρήνην, καὶ οἱ ἐπιδημοῦντες Ῥωμαῖοι,
44002011 Ἰουδαῖοί τε καὶ προσήλυτοι, Κρῆτες καὶ Ἄραβες– ἀκούομεν λαλούντων αὐτῶν, ταῖς ἡμετέραις γλώσσαις, τὰ μεγαλεῖα τοῦ ˚Θεοῦ.”
44002012 Ἐξίσταντο δὲ πάντες καὶ διηποροῦντο, ἄλλος πρὸς ἄλλον λέγοντες, “Τί θέλει τοῦτο εἶναι;”
44002013 Ἕτεροι δὲ διαχλευάζοντες ἔλεγον, ὅτι “Γλεύκους μεμεστωμένοι εἰσίν.”
44002014 Σταθεὶς δὲ ὁ Πέτρος σὺν τοῖς ἕνδεκα, ἐπῆρεν τὴν φωνὴν αὐτοῦ καὶ ἀπεφθέγξατο αὐτοῖς: “Ἄνδρες, Ἰουδαῖοι, καὶ οἱ κατοικοῦντες Ἰερουσαλὴμ πάντες, τοῦτο ὑμῖν γνωστὸν ἔστω καὶ ἐνωτίσασθε τὰ ῥήματά μου.
44002015 Οὐ γὰρ ὡς ὑμεῖς ὑπολαμβάνετε, οὗτοι μεθύουσιν, ἔστιν γὰρ ὥρα τρίτη τῆς ἡμέρας,
44002016 ἀλλὰ τοῦτό ἐστιν τὸ εἰρημένον διὰ τοῦ προφήτου Ἰωήλ:
44002017 ‘Καὶ ἔσται ἐν ταῖς ἐσχάταις ἡμέραις’, λέγει ὁ ˚Θεός, ‘ἐκχεῶ ἀπὸ τοῦ ˚Πνεύματός μου ἐπὶ πᾶσαν σάρκα, καὶ προφητεύσουσιν οἱ υἱοὶ ὑμῶν, καὶ αἱ θυγατέρες ὑμῶν, καὶ οἱ νεανίσκοι ὑμῶν ὁράσεις ὄψονται, καὶ οἱ πρεσβύτεροι ὑμῶν ἐνυπνίοις ἐνυπνιασθήσονται·
44002018 καί γε ἐπὶ τοὺς δούλους μου, καὶ ἐπὶ τὰς δούλας μου, ἐν ταῖς ἡμέραις ἐκείναις ἐκχεῶ ἀπὸ τοῦ ˚Πνεύματός μου, καὶ προφητεύσουσιν.
44002019 Καὶ δώσω τέρατα ἐν τῷ οὐρανῷ ἄνω, καὶ σημεῖα ἐπὶ τῆς γῆς κάτω, αἷμα, καὶ πῦρ, καὶ ἀτμίδα καπνοῦ.
44002020 Ὁ ἥλιος μεταστραφήσεται εἰς σκότος καὶ ἡ σελήνη εἰς αἷμα, πρὶν ἐλθεῖν ἡμέραν ˚Κυρίου τὴν μεγάλην καὶ ἐπιφανῆ.
44002021 Καὶ ἔσται, πᾶς ὃς ἂν ἐπικαλέσηται τὸ ὄνομα ˚Κυρίου σωθήσεται.’
44002022 Ἄνδρες, Ἰσραηλῖται, ἀκούσατε τοὺς λόγους τούτους: ˚Ἰησοῦν τὸν Ναζωραῖον, ἄνδρα ἀποδεδειγμένον ἀπὸ τοῦ ˚Θεοῦ εἰς ὑμᾶς δυνάμεσι, καὶ τέρασι, καὶ σημείοις, οἷς ἐποίησεν διʼ αὐτοῦ ὁ ˚Θεὸς ἐν μέσῳ ὑμῶν, καθὼς αὐτοὶ οἴδατε.
44002023 Τοῦτον τῇ, ὡρισμένῃ βουλῇ καὶ προγνώσει τοῦ ˚Θεοῦ, ἔκδοτον διὰ χειρὸς ἀνόμων, προσπήξαντες ἀνείλατε.
44002024 Ὃν ὁ ˚Θεὸς ἀνέστησεν, λύσας τὰς ὠδῖνας τοῦ θανάτου, καθότι οὐκ ἦν δυνατὸν κρατεῖσθαι αὐτὸν ὑπʼ αὐτοῦ.
44002025 Δαυὶδ γὰρ λέγει εἰς αὐτόν, ‘Προορώμην τὸν ˚Κύριον ἐνώπιόν μου διὰ παντός, ὅτι ἐκ δεξιῶν μού ἐστιν, ἵνα μὴ σαλευθῶ.
44002026 Διὰ τοῦτο ηὐφράνθη μου ἡ καρδία καὶ ἠγαλλιάσατο ἡ γλῶσσά μου, ἔτι δὲ καὶ ἡ σάρξ μου κατασκηνώσει ἐπʼ ἐλπίδι·
44002027 ὅτι οὐκ ἐγκαταλείψεις τὴν ψυχήν μου εἰς ᾅδην, οὐδὲ δώσεις τὸν Ὅσιόν σου ἰδεῖν διαφθοράν.
44002028 Ἐγνώρισάς μοι ὁδοὺς ζωῆς, πληρώσεις με εὐφροσύνης μετὰ τοῦ προσώπου σου.’
44002029 Ἄνδρες, ἀδελφοί, ἐξὸν εἰπεῖν μετὰ παρρησίας πρὸς ὑμᾶς περὶ τοῦ πατριάρχου Δαυὶδ ὅτι καὶ ἐτελεύτησεν καὶ ἐτάφη, καὶ τὸ μνῆμα αὐτοῦ ἔστιν ἐν ἡμῖν ἄχρι τῆς ἡμέρας ταύτης.
44002030 Προφήτης οὖν ὑπάρχων, καὶ εἰδὼς ὅτι ‘ὅρκῳ ὤμοσεν αὐτῷ ὁ ˚Θεὸς’, ‘ἐκ καρποῦ τῆς ὀσφύος αὐτοῦ, καθίσαι ἐπὶ τὸν θρόνον αὐτοῦ’.
44002031 Προϊδὼν, ἐλάλησεν περὶ τῆς ἀναστάσεως τοῦ ˚Χριστοῦ ὅτι ‘οὔτε ἐγκατελείφθη εἰς ᾅδην’, οὔτε ἡ σὰρξ αὐτοῦ ‘εἶδεν διαφθοράν’.
44002032 Τοῦτον τὸν ˚Ἰησοῦν ἀνέστησεν ὁ ˚Θεός, οὗ πάντες ἡμεῖς ἐσμεν μάρτυρες.
44002033 Τῇ δεξιᾷ οὖν τοῦ ˚Θεοῦ ὑψωθεὶς, τήν τε ἐπαγγελίαν τοῦ ˚Πνεύματος τοῦ Ἁγίου, λαβὼν παρὰ τοῦ Πατρὸς, ἐξέχεεν τοῦτο ὃ ὑμεῖς βλέπετε καὶ ἀκούετε.
44002034 Οὐ γὰρ Δαυὶδ ἀνέβη εἰς τοὺς οὐρανούς, λέγει δὲ αὐτός, ‘Εἶπεν ˚Κύριος τῷ ˚Κυρίῳ μου, “Κάθου ἐκ δεξιῶν μου,
44002035 ἕως ἂν θῶ τοὺς ἐχθρούς σου ὑποπόδιον τῶν ποδῶν σου.”’
44002036 Ἀσφαλῶς οὖν γινωσκέτω πᾶς οἶκος Ἰσραὴλ ὅτι καὶ ˚Κύριον αὐτὸν καὶ ˚Χριστὸν ἐποίησεν ὁ ˚Θεός, τοῦτον τὸν ˚Ἰησοῦν ὃν ὑμεῖς ἐσταυρώσατε.”
44002037 Ἀκούσαντες δὲ, κατενύγησαν τὴν καρδίαν, εἶπόν τε πρὸς τὸν Πέτρον καὶ τοὺς λοιποὺς ἀποστόλους, “Τί ποιήσωμεν, ἄνδρες, ἀδελφοί;”
44002038 Πέτρος δὲ πρὸς αὐτούς, “Μετανοήσατε”, φησίν, “καὶ βαπτισθήτω ἕκαστος ὑμῶν ἐπὶ τῷ ὀνόματι ˚Ἰησοῦ ˚Χριστοῦ, εἰς ἄφεσιν τῶν ἁμαρτιῶν ὑμῶν, καὶ λήμψεσθε τὴν δωρεὰν τοῦ Ἁγίου ˚Πνεύματος.
44002039 Ὑμῖν γάρ ἐστιν ἡ ἐπαγγελία, καὶ τοῖς τέκνοις ὑμῶν, καὶ πᾶσι τοῖς εἰς μακρὰν, ὅσους ἂν προσκαλέσηται ˚Κύριος, ὁ ˚Θεὸς ἡμῶν.”
44002040 Ἑτέροις τε λόγοις πλείοσιν διεμαρτύρατο, καὶ παρεκάλει αὐτοὺς λέγων, “Σώθητε ἀπὸ τῆς γενεᾶς τῆς σκολιᾶς ταύτης.”
44002041 Οἱ μὲν οὖν ἀποδεξάμενοι τὸν λόγον αὐτοῦ ἐβαπτίσθησαν, καὶ προσετέθησαν ἐν τῇ ἡμέρᾳ ἐκείνῃ, ψυχαὶ ὡσεὶ τρισχίλιαι.
44002042 Ἦσαν δὲ προσκαρτεροῦντες τῇ διδαχῇ τῶν ἀποστόλων, καὶ τῇ κοινωνίᾳ, τῇ κλάσει τοῦ ἄρτου, καὶ ταῖς προσευχαῖς.
44002043 Ἐγίνετο δὲ πάσῃ ψυχῇ φόβος, πολλά δὲ τέρατα καὶ σημεῖα διὰ τῶν ἀποστόλων ἐγίνετο.
44002044 Πάντες δὲ οἱ πιστεύσαντες ἦσαν ἐπὶ τὸ αὐτὸ, καὶ εἶχον ἅπαντα κοινά,
44002045 καὶ τὰ κτήματα καὶ τὰς ὑπάρξεις ἐπίπρασκον, καὶ διεμέριζον αὐτὰ πᾶσιν, καθότι ἄν τις χρείαν εἶχεν.
44002046 Καθʼ ἡμέραν τε προσκαρτεροῦντες ὁμοθυμαδὸν ἐν τῷ ἱερῷ, κλῶντές τε κατʼ οἶκον ἄρτον, μετελάμβανον τροφῆς ἐν ἀγαλλιάσει καὶ ἀφελότητι καρδίας,
44002047 αἰνοῦντες τὸν ˚Θεὸν καὶ ἔχοντες χάριν πρὸς ὅλον τὸν λαόν. Ὁ δὲ ˚Κύριος προσετίθει τοὺς σῳζομένους καθʼ ἡμέραν ἐπὶ τὸ αὐτό.
44003001 Πέτρος δὲ καὶ Ἰωάννης ἀνέβαινον εἰς τὸ ἱερὸν ἐπὶ τὴν ὥραν τῆς προσευχῆς τὴν ἐνάτην.
44003002 Καί τις ἀνὴρ, χωλὸς ἐκ κοιλίας μητρὸς αὐτοῦ ὑπάρχων, ἐβαστάζετο, ὃν ἐτίθουν καθʼ ἡμέραν πρὸς τὴν θύραν τοῦ ἱεροῦ, τὴν λεγομένην Ὡραίαν, τοῦ αἰτεῖν ἐλεημοσύνην παρὰ τῶν εἰσπορευομένων εἰς τὸ ἱερόν.
44003003 Ὃς ἰδὼν Πέτρον καὶ Ἰωάννην μέλλοντας εἰσιέναι εἰς τὸ ἱερὸν, ἠρώτα ἐλεημοσύνην λαβεῖν.
44003004 Ἀτενίσας δὲ Πέτρος εἰς αὐτὸν σὺν τῷ Ἰωάννῃ εἶπεν, “Βλέψον εἰς ἡμᾶς.”
44003005 Ὁ δὲ ἐπεῖχεν αὐτοῖς, προσδοκῶν τι παρʼ αὐτῶν λαβεῖν.
44003006 Εἶπεν δὲ Πέτρος, “Ἀργύριον καὶ χρυσίον οὐχ ὑπάρχει μοι, ὃ δὲ ἔχω, τοῦτό σοι δίδωμι– ἐν τῷ ὀνόματι ˚Ἰησοῦ ˚Χριστοῦ τοῦ Ναζωραίου, περιπάτει!”
44003007 Καὶ πιάσας αὐτὸν τῆς δεξιᾶς χειρὸς, ἤγειρεν αὐτόν· παραχρῆμα δὲ ἐστερεώθησαν αἱ βάσεις αὐτοῦ καὶ τὰ σφυδρά.
44003008 Καὶ ἐξαλλόμενος, ἔστη καὶ περιεπάτει, καὶ εἰσῆλθεν σὺν αὐτοῖς εἰς τὸ ἱερὸν, περιπατῶν, καὶ ἁλλόμενος, καὶ αἰνῶν τὸν ˚Θεόν.
44003009 Καὶ εἶδεν πᾶς ὁ λαὸς αὐτὸν περιπατοῦντα καὶ αἰνοῦντα τὸν ˚Θεόν.
44003010 Ἐπεγίνωσκον δὲ αὐτὸν ὅτι αὐτὸς ἦν ὁ πρὸς τὴν ἐλεημοσύνην καθήμενος ἐπὶ τῇ Ὡραίᾳ Πύλῃ τοῦ ἱεροῦ, καὶ ἐπλήσθησαν θάμβους καὶ ἐκστάσεως ἐπὶ τῷ συμβεβηκότι αὐτῷ.
44003011 Κρατοῦντος δὲ αὐτοῦ τὸν Πέτρον καὶ τὸν Ἰωάννην, συνέδραμεν πᾶς ὁ λαὸς πρὸς αὐτοὺς ἐπὶ τῇ στοᾷ τῇ καλουμένῃ Σολομῶντος, ἔκθαμβοι.
44003012 Ἰδὼν δὲ, ὁ Πέτρος ἀπεκρίνατο πρὸς τὸν λαόν, “Ἄνδρες, Ἰσραηλῖται, τί θαυμάζετε ἐπὶ τούτῳ, ἢ ἡμῖν τί ἀτενίζετε, ὡς ἰδίᾳ δυνάμει ἢ εὐσεβείᾳ πεποιηκόσιν τοῦ περιπατεῖν αὐτόν;
44003013 Ὁ ˚Θεὸς Ἀβραὰμ, καὶ ὁ ˚Θεὸς Ἰσαὰκ, καὶ ὁ ˚Θεὸς Ἰακώβ, ὁ ˚Θεὸς τῶν πατέρων ἡμῶν, ἐδόξασεν τὸν παῖδα αὐτοῦ, ˚Ἰησοῦν, ὃν ὑμεῖς μὲν παρεδώκατε, καὶ ἠρνήσασθε κατὰ πρόσωπον Πιλάτου, κρίναντος ἐκείνου ἀπολύειν.
44003014 Ὑμεῖς δὲ τὸν Ἅγιον καὶ Δίκαιον ἠρνήσασθε, καὶ ᾐτήσασθε ἄνδρα φονέα χαρισθῆναι ὑμῖν,
44003015 τὸν δὲ Ἀρχηγὸν τῆς ζωῆς ἀπεκτείνατε, ὃν ὁ ˚Θεὸς ἤγειρεν ἐκ νεκρῶν, οὗ ἡμεῖς μάρτυρές ἐσμεν.
44003016 Καὶ τῇ πίστει τοῦ ὀνόματος αὐτοῦ, τοῦτον ὃν θεωρεῖτε καὶ οἴδατε, ἐστερέωσεν τὸ ὄνομα αὐτοῦ, καὶ ἡ πίστις ἡ διʼ αὐτοῦ, ἔδωκεν αὐτῷ τὴν ὁλοκληρίαν ταύτην ἀπέναντι πάντων ὑμῶν.
44003017 Καὶ νῦν, ἀδελφοί, οἶδα ὅτι κατὰ ἄγνοιαν ἐπράξατε, ὥσπερ καὶ οἱ ἄρχοντες ὑμῶν.
44003018 Ὁ δὲ ˚Θεὸς ἃ προκατήγγειλεν διὰ στόματος πάντων τῶν προφητῶν, παθεῖν τὸν ˚Χριστὸν αὐτοῦ, ἐπλήρωσεν οὕτως.
44003019 Μετανοήσατε οὖν καὶ ἐπιστρέψατε, πρὸς τὸ ἐξαλειφθῆναι ὑμῶν τὰς ἁμαρτίας,
44003020 ὅπως ἂν ἔλθωσιν καιροὶ ἀναψύξεως ἀπὸ προσώπου τοῦ ˚Κυρίου, καὶ ἀποστείλῃ τὸν προκεχειρισμένον ὑμῖν ˚Χριστὸν ˚Ἰησοῦν,
44003021 ὃν δεῖ οὐρανὸν μὲν δέξασθαι, ἄχρι χρόνων ἀποκαταστάσεως πάντων, ὧν ἐλάλησεν ὁ ˚Θεὸς διὰ στόματος τῶν ἁγίων ἀπʼ αἰῶνος αὐτοῦ προφητῶν.
44003022 Μωϋσῆς μὲν εἶπεν, ὅτι ‘“Προφήτην ὑμῖν ἀναστήσει ˚Κύριος, ὁ ˚Θεὸς ἡμῶν ἐκ τῶν ἀδελφῶν ὑμῶν, ὡς ἐμέ αὐτοῦ ἀκούσεσθε” κατὰ πάντα, ὅσα ἂν λαλήσῃ πρὸς ὑμᾶς.
44003023 “Ἔσται δὲ πᾶσα ψυχὴ, ἥτις ἐὰν μὴ ἀκούσῃ τοῦ προφήτου ἐκείνου, ἐξολεθρευθήσεται ἐκ τοῦ λαοῦ.”’
44003024 Καὶ πάντες δὲ οἱ προφῆται ἀπὸ Σαμουὴλ καὶ τῶν καθεξῆς, ὅσοι ἐλάλησαν, καὶ κατήγγειλαν τὰς ἡμέρας ταύτας.
44003025 Ὑμεῖς ἐστε οἱ υἱοὶ τῶν προφητῶν, καὶ τῆς διαθήκης ἧς διέθετο ὁ ˚Θεὸς πρὸς τοὺς πατέρας ὑμῶν, λέγων πρὸς Ἀβραάμ, ‘Καὶ ἐν τῷ σπέρματί σου ἐνευλογηθήσονται πᾶσαι αἱ πατριαὶ τῆς γῆς.’
44003026 Ὑμῖν πρῶτον, ἀναστήσας ὁ ˚Θεὸς τὸν παῖδα αὐτοῦ, ἀπέστειλεν αὐτὸν εὐλογοῦντα ὑμᾶς ἐν τῷ ἀποστρέφειν ἕκαστον ἀπὸ τῶν πονηριῶν ὑμῶν.”
44004001 Λαλούντων δὲ αὐτῶν πρὸς τὸν λαὸν, ἐπέστησαν αὐτοῖς οἱ ἀρχιερεῖς, καὶ ὁ στρατηγὸς τοῦ ἱεροῦ, καὶ οἱ Σαδδουκαῖοι,
44004002 διαπονούμενοι διὰ τὸ διδάσκειν αὐτοὺς τὸν λαὸν, καὶ καταγγέλλειν ἐν τῷ ˚Ἰησοῦ τὴν ἀνάστασιν, τὴν ἐκ νεκρῶν.
44004003 Καὶ ἐπέβαλον αὐτοῖς τὰς χεῖρας, καὶ ἔθεντο εἰς τήρησιν εἰς τὴν αὔριον, ἦν γὰρ ἑσπέρα ἤδη.
44004004 Πολλοὶ δὲ τῶν ἀκουσάντων τὸν λόγον, ἐπίστευσαν καὶ ἐγενήθη ἀριθμὸς τῶν ἀνδρῶν ὡς χιλιάδες πέντε.
44004005 Ἐγένετο δὲ ἐπὶ τὴν αὔριον συναχθῆναι αὐτῶν τοὺς ἄρχοντας, καὶ τοὺς πρεσβυτέρους, καὶ τοὺς γραμματεῖς ἐν Ἰερουσαλήμ,
44004006 καὶ Ἅννας ὁ ἀρχιερεὺς, καὶ Καϊάφας, καὶ Ἰωάννης, καὶ Ἀλέξανδρος, καὶ ὅσοι ἦσαν ἐκ γένους ἀρχιερατικοῦ.
44004007 Καὶ στήσαντες αὐτοὺς ἐν τῷ μέσῳ, ἐπυνθάνοντο, “Ἐν ποίᾳ δυνάμει ἢ ἐν ποίῳ ὀνόματι ἐποιήσατε τοῦτο ὑμεῖς;”
44004008 Τότε Πέτρος πλησθεὶς ˚Πνεύματος Ἁγίου, εἶπεν πρὸς αὐτούς, “Ἄρχοντες τοῦ λαοῦ καὶ πρεσβύτεροι,
44004009 εἰ ἡμεῖς σήμερον ἀνακρινόμεθα ἐπὶ εὐεργεσίᾳ ἀνθρώπου ἀσθενοῦς, ἐν τίνι οὗτος σέσωται,
44004010 γνωστὸν ἔστω πᾶσιν ὑμῖν καὶ παντὶ τῷ λαῷ Ἰσραὴλ, ὅτι ἐν τῷ ὀνόματι ˚Ἰησοῦ ˚Χριστοῦ τοῦ Ναζωραίου, ὃν ὑμεῖς ἐσταυρώσατε, ὃν ὁ ˚Θεὸς ἤγειρεν ἐκ νεκρῶν, ἐν τούτῳ οὗτος παρέστηκεν ἐνώπιον ὑμῶν ὑγιής.
44004011 Οὗτός ἐστιν ‘ὁ λίθος ὁ ἐξουθενηθεὶς ὑφʼ ὑμῶν, τῶν οἰκοδόμων, ὁ γενόμενος εἰς κεφαλὴν γωνίας’.
44004012 Καὶ οὐκ ἔστιν ἐν ἄλλῳ οὐδενὶ ἡ σωτηρία, οὐδὲ γὰρ ὄνομά ἐστιν ἕτερον ὑπὸ τὸν οὐρανὸν τὸ δεδομένον ἐν ἀνθρώποις, ἐν ᾧ δεῖ σωθῆναι ἡμᾶς.”
44004013 Θεωροῦντες δὲ τὴν τοῦ Πέτρου παρρησίαν καὶ Ἰωάννου, καὶ καταλαβόμενοι ὅτι ἄνθρωποι ἀγράμματοί εἰσιν καὶ ἰδιῶται, ἐθαύμαζον, ἐπεγίνωσκόν τε αὐτοὺς ὅτι σὺν τῷ ˚Ἰησοῦ ἦσαν.
44004014 Τόν τε ἄνθρωπον βλέποντες σὺν αὐτοῖς ἑστῶτα, τὸν τεθεραπευμένον, οὐδὲν εἶχον ἀντειπεῖν.
44004015 Κελεύσαντες δὲ αὐτοὺς ἔξω τοῦ Συνεδρίου ἀπελθεῖν, συνέβαλλον πρὸς ἀλλήλους
44004016 λέγοντες, “Τί ποιήσωμεν τοῖς ἀνθρώποις τούτοις; Ὅτι μὲν γὰρ γνωστὸν σημεῖον γέγονεν διʼ αὐτῶν, πᾶσιν τοῖς κατοικοῦσιν Ἰερουσαλὴμ φανερόν, καὶ οὐ δυνάμεθα ἀρνεῖσθαι.
44004017 Ἀλλʼ ἵνα μὴ ἐπὶ πλεῖον διανεμηθῇ εἰς τὸν λαόν, ἀπειλησώμεθα αὐτοῖς μηκέτι λαλεῖν ἐπὶ τῷ ὀνόματι τούτῳ μηδενὶ ἀνθρώπων.”
44004018 Καὶ καλέσαντες αὐτοὺς, παρήγγειλαν καθόλου μὴ φθέγγεσθαι μηδὲ διδάσκειν ἐπὶ τῷ ὀνόματι τοῦ ˚Ἰησοῦ.
44004019 Ὁ δὲ Πέτρος καὶ Ἰωάννης ἀποκριθέντες εἶπον πρὸς αὐτούς, “Εἰ δίκαιόν ἐστιν ἐνώπιον τοῦ ˚Θεοῦ, ὑμῶν ἀκούειν μᾶλλον ἢ τοῦ ˚Θεοῦ, κρίνατε.
44004020 Οὐ δυνάμεθα γὰρ ἡμεῖς ἃ εἴδαμεν καὶ ἠκούσαμεν μὴ λαλεῖν.”
44004021 Οἱ δὲ προσαπειλησάμενοι ἀπέλυσαν αὐτούς, μηδὲν εὑρίσκοντες τὸ πῶς κολάσωνται αὐτούς, διὰ τὸν λαόν, ὅτι πάντες ἐδόξαζον τὸν ˚Θεὸν ἐπὶ τῷ γεγονότι.
44004022 Ἐτῶν γὰρ ἦν πλειόνων τεσσεράκοντα ὁ ἄνθρωπος, ἐφʼ ὃν γεγόνει τὸ σημεῖον τοῦτο τῆς ἰάσεως.
44004023 Ἀπολυθέντες δὲ, ἦλθον πρὸς τοὺς ἰδίους, καὶ ἀπήγγειλαν ὅσα πρὸς αὐτοὺς οἱ ἀρχιερεῖς καὶ οἱ πρεσβύτεροι εἶπαν.
44004024 Οἱ δὲ ἀκούσαντες, ὁμοθυμαδὸν ἦραν φωνὴν πρὸς τὸν ˚Θεὸν καὶ εἶπαν, “Δέσποτα, σὺ ὁ ‘ποιήσας τὸν οὐρανὸν, καὶ τὴν γῆν, καὶ τὴν θάλασσαν, καὶ πάντα τὰ ἐν αὐτοῖς’,
44004025 ὁ τοῦ πατρὸς ἡμῶν, διὰ ˚Πνεύματος Ἁγίου στόματος Δαυὶδ παιδός σου εἰπών, ‘Ἵνα τί ἐφρύαξαν ἔθνη, καὶ λαοὶ ἐμελέτησαν κενά;
44004026 Παρέστησαν οἱ βασιλεῖς τῆς γῆς καὶ οἱ ἄρχοντες συνήχθησαν ἐπὶ τὸ αὐτὸ κατὰ τοῦ ˚Κυρίου, καὶ κατὰ τοῦ ˚Χριστοῦ αὐτοῦ.’
44004027 Συνήχθησαν γὰρ ἐπʼ ἀληθείας ἐν τῇ πόλει ταύτῃ ἐπὶ τὸν ἅγιον παῖδά σου ˚Ἰησοῦν, ὃν ἔχρισας, Ἡρῴδης τε καὶ Πόντιος Πιλᾶτος, σὺν ἔθνεσιν καὶ λαοῖς Ἰσραήλ,
44004028 ποιῆσαι ὅσα ἡ χείρ σου, καὶ ἡ βουλὴ σου προώρισεν γενέσθαι.
44004029 Καὶ τὰ νῦν, ˚Κύριε, ἔπιδε ἐπὶ τὰς ἀπειλὰς αὐτῶν, καὶ δὸς τοῖς δούλοις σου, μετὰ παρρησίας πάσης λαλεῖν τὸν λόγον σου,
44004030 ἐν τῷ τὴν χεῖρα ἐκτείνειν σε εἰς ἴασιν, καὶ σημεῖα, καὶ τέρατα, γίνεσθαι διὰ τοῦ ὀνόματος τοῦ ἁγίου παιδός σου, ˚Ἰησοῦ.”
44004031 Καὶ δεηθέντων αὐτῶν, ἐσαλεύθη ὁ τόπος ἐν ᾧ ἦσαν συνηγμένοι, καὶ ἐπλήσθησαν ἅπαντες τοῦ Ἁγίου ˚Πνεύματος, καὶ ἐλάλουν τὸν λόγον τοῦ ˚Θεοῦ μετὰ παρρησίας.
44004032 Τοῦ δὲ πλήθους τῶν πιστευσάντων, ἦν καρδία καὶ ψυχὴ μία, καὶ οὐδὲ εἷς τι τῶν ὑπαρχόντων αὐτῷ ἔλεγεν ἴδιον εἶναι, ἀλλʼ ἦν αὐτοῖς πάντα κοινά.
44004033 Καὶ δυνάμει μεγάλῃ, ἀπεδίδουν τὸ μαρτύριον οἱ ἀπόστολοι τοῦ ˚Κυρίου ˚Ἰησοῦ, τῆς ἀναστάσεως, χάρις τε μεγάλη ἦν ἐπὶ πάντας αὐτούς.
44004034 Οὐδὲ γὰρ ἐνδεής τις ἦν ἐν αὐτοῖς· ὅσοι γὰρ κτήτορες χωρίων ἢ οἰκιῶν ὑπῆρχον, πωλοῦντες ἔφερον τὰς τιμὰς τῶν πιπρασκομένων,
44004035 καὶ ἐτίθουν παρὰ τοὺς πόδας τῶν ἀποστόλων· διεδίδετο δὲ ἑκάστῳ, καθότι ἄν τις χρείαν εἶχεν.
44004036 Ἰωσὴφ δὲ ὁ ἐπικληθεὶς Βαρναβᾶς, ἀπὸ τῶν ἀποστόλων, ὅ ἐστιν μεθερμηνευόμενον, “Υἱὸς παρακλήσεως”, Λευίτης, Κύπριος τῷ γένει,
44004037 ὑπάρχοντος αὐτῷ ἀγροῦ, πωλήσας ἤνεγκεν τὸ χρῆμα καὶ ἔθηκεν παρὰ τοὺς πόδας τῶν ἀποστόλων.
44005001 Ἀνὴρ δέ τις Ἁνανίας ὀνόματι, σὺν Σαπφίρῃ τῇ γυναικὶ αὐτοῦ, ἐπώλησεν κτῆμα
44005002 καὶ ἐνοσφίσατο ἀπὸ τῆς τιμῆς, συνειδυίης καὶ τῆς γυναικός, καὶ ἐνέγκας μέρος τι, παρὰ τοὺς πόδας τῶν ἀποστόλων ἔθηκεν.
44005003 Εἶπεν δὲ ὁ Πέτρος, “Ἁνανία, Διὰ τί ἐπλήρωσεν ὁ Σατανᾶς τὴν καρδίαν σου, ψεύσασθαί σε τὸ ˚Πνεῦμα τὸ Ἅγιον, καὶ νοσφίσασθαι ἀπὸ τῆς τιμῆς τοῦ χωρίου;
44005004 Οὐχὶ μένον σοὶ ἔμενεν, καὶ πραθὲν ἐν τῇ σῇ ἐξουσίᾳ ὑπῆρχεν; Τί ὅτι ἔθου ἐν τῇ καρδίᾳ σου τὸ πρᾶγμα τοῦτο; Οὐκ ἐψεύσω ἀνθρώποις, ἀλλὰ τῷ ˚Θεῷ.”
44005005 Ἀκούων δὲ ὁ Ἁνανίας τοὺς λόγους τούτους, πεσὼν ἐξέψυξεν. Καὶ ἐγένετο φόβος μέγας ἐπὶ πάντας τοὺς ἀκούοντας.
44005006 Ἀναστάντες δὲ, οἱ νεώτεροι συνέστειλαν αὐτὸν, καὶ ἐξενέγκαντες, ἔθαψαν.
44005007 Ἐγένετο δὲ ὡς ὡρῶν τριῶν διάστημα, καὶ ἡ γυνὴ αὐτοῦ, μὴ εἰδυῖα τὸ γεγονὸς, εἰσῆλθεν.
44005008 Ἀπεκρίθη δὲ πρὸς αὐτὴν Πέτρος, “Εἰπέ μοι εἰ τοσούτου τὸ χωρίον ἀπέδοσθε;” Ἡ δὲ εἶπεν, “Ναί, τοσούτου.”
44005009 Ὁ δὲ Πέτρος πρὸς αὐτήν, “Τί ὅτι συνεφωνήθη ὑμῖν πειράσαι τὸ ˚Πνεῦμα ˚Κυρίου; Ἰδοὺ, οἱ πόδες τῶν θαψάντων τὸν ἄνδρα σου ἐπὶ τῇ θύρᾳ, καὶ ἐξοίσουσίν σε.”
44005010 Ἔπεσεν δὲ παραχρῆμα πρὸς τοὺς πόδας αὐτοῦ, καὶ ἐξέψυξεν. Εἰσελθόντες δὲ, οἱ νεανίσκοι εὗρον αὐτὴν νεκράν, καὶ ἐξενέγκαντες, ἔθαψαν πρὸς τὸν ἄνδρα αὐτῆς.
44005011 Καὶ ἐγένετο φόβος μέγας ἐφʼ ὅλην τὴν ἐκκλησίαν, καὶ ἐπὶ πάντας τοὺς ἀκούοντας ταῦτα.
44005012 Διὰ δὲ τῶν χειρῶν τῶν ἀποστόλων, ἐγίνετο σημεῖα καὶ τέρατα πολλὰ ἐν τῷ λαῷ· καὶ ἦσαν ὁμοθυμαδὸν πάντες ἐν τῇ Στοᾷ Σολομῶντος.
44005013 Τῶν δὲ λοιπῶν οὐδεὶς ἐτόλμα κολλᾶσθαι αὐτοῖς, ἀλλʼ ἐμεγάλυνεν αὐτοὺς ὁ λαός,
44005014 μᾶλλον δὲ προσετίθεντο πιστεύοντες τῷ ˚Κυρίῳ, πλήθη ἀνδρῶν τε καὶ γυναικῶν·
44005015 ὥστε καὶ εἰς τὰς πλατείας ἐκφέρειν τοὺς ἀσθενεῖς, καὶ τιθέναι ἐπὶ κλιναρίων καὶ κραβάττων, ἵνα ἐρχομένου Πέτρου, κἂν ἡ σκιὰ ἐπισκιάσῃ τινὶ αὐτῶν.
44005016 Συνήρχετο δὲ καὶ τὸ πλῆθος τῶν πέριξ πόλεων Ἰερουσαλήμ, φέροντες ἀσθενεῖς καὶ ὀχλουμένους ὑπὸ πνευμάτων ἀκαθάρτων, οἵτινες ἐθεραπεύοντο ἅπαντες.
44005017 Ἀναστὰς δὲ ὁ ἀρχιερεὺς καὶ πάντες οἱ σὺν αὐτῷ, ἡ οὖσα αἵρεσις τῶν Σαδδουκαίων, ἐπλήσθησαν ζήλου,
44005018 καὶ ἐπέβαλον τὰς χεῖρας ἐπὶ τοὺς ἀποστόλους, καὶ ἔθεντο αὐτοὺς ἐν τηρήσει δημοσίᾳ.
44005019 Ἄγγελος δὲ ˚Κυρίου διὰ νυκτὸς, ἤνοιξε τὰς θύρας τῆς φυλακῆς, ἐξαγαγών τε αὐτοὺς εἶπεν,
44005020 “Πορεύεσθε, καὶ σταθέντες, λαλεῖτε ἐν τῷ ἱερῷ τῷ λαῷ πάντα τὰ ῥήματα τῆς ζωῆς ταύτης.”
44005021 Ἀκούσαντες δὲ, εἰσῆλθον ὑπὸ τὸν ὄρθρον εἰς τὸ ἱερὸν, καὶ ἐδίδασκον. Παραγενόμενος δὲ ὁ ἀρχιερεὺς καὶ οἱ σὺν αὐτῷ, συνεκάλεσαν τὸ Συνέδριον καὶ πᾶσαν τὴν Γερουσίαν τῶν υἱῶν Ἰσραήλ, καὶ ἀπέστειλαν εἰς τὸ δεσμωτήριον ἀχθῆναι αὐτούς.
44005022 Οἱ δὲ παραγενόμενοι ὑπηρέται, οὐχ εὗρον αὐτοὺς ἐν τῇ φυλακῇ, ἀναστρέψαντες δὲ, ἀπήγγειλαν
44005023 λέγοντες, ὅτι “Τὸ δεσμωτήριον εὕρομεν κεκλεισμένον ἐν πάσῃ ἀσφαλείᾳ, καὶ τοὺς φύλακας ἑστῶτας ἐπὶ τῶν θυρῶν, ἀνοίξαντες δὲ, ἔσω οὐδένα εὕρομεν.”
44005024 Ὡς δὲ ἤκουσαν τοὺς λόγους τούτους, ὅ τε στρατηγὸς τοῦ ἱεροῦ καὶ οἱ ἀρχιερεῖς διηπόρουν περὶ αὐτῶν, τί ἂν γένοιτο τοῦτο.
44005025 Παραγενόμενος δέ, τις ἀπήγγειλεν αὐτοῖς, ὅτι “Ἰδοὺ, οἱ ἄνδρες οὓς ἔθεσθε ἐν τῇ φυλακῇ εἰσὶν ἐν τῷ ἱερῷ, ἑστῶτες καὶ διδάσκοντες τὸν λαόν!”
44005026 Τότε ἀπελθὼν, ὁ στρατηγὸς σὺν τοῖς ὑπηρέταις ἦγεν αὐτούς, οὐ μετὰ βίας, ἐφοβοῦντο γὰρ τὸν λαόν, μὴ λιθασθῶσιν.
44005027 Ἀγαγόντες δὲ αὐτοὺς, ἔστησαν ἐν τῷ Συνεδρίῳ. Καὶ ἐπηρώτησεν αὐτοὺς ὁ ἀρχιερεὺς
44005028 λέγων, “Παραγγελίᾳ παρηγγείλαμεν ὑμῖν μὴ διδάσκειν ἐπὶ τῷ ὀνόματι τούτῳ, καὶ ἰδοὺ, πεπληρώκατε τὴν Ἰερουσαλὴμ τῆς διδαχῆς ὑμῶν, καὶ βούλεσθε ἐπαγαγεῖν ἐφʼ ἡμᾶς τὸ αἷμα τοῦ ἀνθρώπου τούτου.”
44005029 Ἀποκριθεὶς δὲ Πέτρος καὶ οἱ ἀπόστολοι εἶπαν, “Πειθαρχεῖν δεῖ ˚Θεῷ, μᾶλλον ἢ ἀνθρώποις.
44005030 Ὁ ˚Θεὸς τῶν πατέρων ἡμῶν ἤγειρεν ˚Ἰησοῦν, ὃν ὑμεῖς διεχειρίσασθε, κρεμάσαντες ἐπὶ ξύλου.
44005031 Τοῦτον ὁ ˚Θεὸς Ἀρχηγὸν καὶ Σωτῆρα ὕψωσεν, τῇ δεξιᾷ αὐτοῦ, τοῦ δοῦναι μετάνοιαν τῷ Ἰσραὴλ καὶ ἄφεσιν ἁμαρτιῶν.
44005032 Καὶ ἡμεῖς ἐσμεν μάρτυρες τῶν ῥημάτων τούτων, καὶ τὸ ˚Πνεῦμα τὸ Ἅγιον, ὃ ἔδωκεν ὁ ˚Θεὸς τοῖς πειθαρχοῦσιν αὐτῷ.”
44005033 Οἱ δὲ ἀκούσαντες διεπρίοντο, καὶ ἐβούλοντο ἀνελεῖν αὐτούς.
44005034 Ἀναστὰς δέ τις ἐν τῷ Συνεδρίῳ Φαρισαῖος ὀνόματι Γαμαλιήλ, νομοδιδάσκαλος τίμιος παντὶ τῷ λαῷ, ἐκέλευσεν ἔξω βραχὺ τοὺς ἀνθρώπους ποιῆσαι.
44005035 Εἶπέν τε πρὸς αὐτούς, “Ἄνδρες, Ἰσραηλῖται, προσέχετε ἑαυτοῖς ἐπὶ τοῖς ἀνθρώποις τούτοις τί μέλλετε πράσσειν.
44005036 Πρὸ γὰρ τούτων τῶν ἡμερῶν ἀνέστη Θευδᾶς, λέγων εἶναί τινα ἑαυτόν, ᾧ προσεκλίθη ἀνδρῶν ἀριθμὸς ὡς τετρακοσίων· ὃς ἀνῃρέθη καὶ πάντες ὅσοι ἐπείθοντο αὐτῷ διελύθησαν καὶ ἐγένοντο εἰς οὐδέν.
44005037 Μετὰ τοῦτον, ἀνέστη Ἰούδας ὁ Γαλιλαῖος ἐν ταῖς ἡμέραις τῆς ἀπογραφῆς, καὶ ἀπέστησεν λαὸν ὀπίσω αὐτοῦ· κἀκεῖνος ἀπώλετο, καὶ πάντες ὅσοι ἐπείθοντο αὐτῷ διεσκορπίσθησαν.
44005038 Καὶ τὰ νῦν λέγω ὑμῖν, ἀπόστητε ἀπὸ τῶν ἀνθρώπων τούτων καὶ ἄφετε αὐτούς, ὅτι ἐὰν ᾖ ἐξ ἀνθρώπων, ἡ βουλὴ αὕτη ἢ τὸ ἔργον τοῦτο καταλυθήσεται,
44005039 εἰ δὲ ἐκ ˚Θεοῦ ἐστιν, οὐ δυνήσεσθε καταλῦσαι αὐτούς, μήποτε καὶ θεομάχοι εὑρεθῆτε.” Ἐπείσθησαν δὲ αὐτῷ,
44005040 καὶ προσκαλεσάμενοι τοὺς ἀποστόλους, δείραντες παρήγγειλαν μὴ λαλεῖν ἐπὶ τῷ ὀνόματι τοῦ ˚Ἰησοῦ, καὶ ἀπέλυσαν.
44005041 Οἱ μὲν οὖν ἐπορεύοντο, χαίροντες ἀπὸ προσώπου τοῦ Συνεδρίου ὅτι κατηξιώθησαν ὑπὲρ τοῦ ὀνόματος ἀτιμασθῆναι.
44005042 Πᾶσάν τε ἡμέραν ἐν τῷ ἱερῷ καὶ κατʼ οἶκον, οὐκ ἐπαύοντο διδάσκοντες καὶ εὐαγγελιζόμενοι τὸν ˚Χριστὸν, ˚Ἰησοῦν.
44006001 Ἐν δὲ ταῖς ἡμέραις ταύταις πληθυνόντων τῶν μαθητῶν, ἐγένετο γογγυσμὸς τῶν Ἑλληνιστῶν πρὸς τοὺς Ἑβραίους, ὅτι παρεθεωροῦντο ἐν τῇ διακονίᾳ τῇ καθημερινῇ αἱ χῆραι αὐτῶν.
44006002 Προσκαλεσάμενοι δὲ οἱ δώδεκα τὸ πλῆθος τῶν μαθητῶν εἶπαν, “Οὐκ ἀρεστόν ἐστιν ἡμᾶς, καταλείψαντας τὸν λόγον τοῦ ˚Θεοῦ, διακονεῖν τραπέζαις.
44006003 Ἐπισκέψασθε δέ, ἀδελφοί, ἄνδρας ἐξ ὑμῶν μαρτυρουμένους, ἑπτὰ πλήρεις ˚Πνεύματος καὶ σοφίας, οὓς καταστήσομεν ἐπὶ τῆς χρείας ταύτης.
44006004 Ἡμεῖς δὲ τῇ προσευχῇ καὶ τῇ διακονίᾳ τοῦ λόγου προσκαρτερήσομεν.”
44006005 Καὶ ἤρεσεν ὁ λόγος ἐνώπιον παντὸς τοῦ πλήθους, καὶ ἐξελέξαντο Στέφανον, ἄνδρα πλήρης πίστεως καὶ ˚Πνεύματος Ἁγίου, καὶ Φίλιππον, καὶ Πρόχορον, καὶ Νικάνορα, καὶ Τίμωνα, καὶ Παρμενᾶν, καὶ Νικόλαον προσήλυτον Ἀντιοχέα,
44006006 οὓς ἔστησαν ἐνώπιον τῶν ἀποστόλων, καὶ προσευξάμενοι, ἐπέθηκαν αὐτοῖς τὰς χεῖρας.
44006007 Καὶ ὁ λόγος τοῦ ˚Θεοῦ ηὔξανεν, καὶ ἐπληθύνετο ὁ ἀριθμὸς τῶν μαθητῶν ἐν Ἰερουσαλὴμ σφόδρα, πολύς τε ὄχλος τῶν ἱερέων ὑπήκουον τῇ πίστει.
44006008 Στέφανος δὲ πλήρης χάριτος καὶ δυνάμεως, ἐποίει τέρατα καὶ σημεῖα μεγάλα ἐν τῷ λαῷ.
44006009 Ἀνέστησαν δέ τινες τῶν ἐκ τῆς συναγωγῆς, τῆς λεγομένης Λιβερτίνων, καὶ Κυρηναίων, καὶ Ἀλεξανδρέων, καὶ τῶν ἀπὸ Κιλικίας, καὶ Ἀσίας, συζητοῦντες τῷ Στεφάνῳ.
44006010 Καὶ οὐκ ἴσχυον ἀντιστῆναι τῇ σοφίᾳ καὶ τῷ ˚Πνεύματι, ᾧ ἐλάλει.
44006011 Τότε ὑπέβαλον ἄνδρας λέγοντας, ὅτι “Ἀκηκόαμεν αὐτοῦ λαλοῦντος ῥήματα βλάσφημα εἰς Μωϋσῆν καὶ τὸν ˚Θεόν.”
44006012 Συνεκίνησάν τε τὸν λαὸν, καὶ τοὺς πρεσβυτέρους, καὶ τοὺς γραμματεῖς, καὶ ἐπιστάντες, συνήρπασαν αὐτὸν καὶ ἤγαγον εἰς τὸ Συνέδριον,
44006013 ἔστησάν τε μάρτυρας ψευδεῖς λέγοντας, “Ὁ ἄνθρωπος οὗτος οὐ παύεται λαλῶν ῥήματα κατὰ τοῦ τόπου τοῦ ἁγίου τούτου καὶ τοῦ νόμου”,
44006014 ἀκηκόαμεν γὰρ αὐτοῦ λέγοντος ὅτι ˚Ἰησοῦς ὁ Ναζωραῖος οὗτος καταλύσει τὸν τόπον τοῦτον, καὶ ἀλλάξει τὰ ἔθη ἃ παρέδωκεν ἡμῖν Μωϋσῆς.
44006015 Καὶ ἀτενίσαντες εἰς αὐτὸν, πάντες οἱ καθεζόμενοι ἐν τῷ Συνεδρίῳ εἶδον τὸ πρόσωπον αὐτοῦ, ὡσεὶ πρόσωπον ἀγγέλου.
44007001 Εἶπεν δὲ ὁ ἀρχιερεύς, “Εἰ ταῦτα οὕτως ἔχει;”
44007002 Ὁ δὲ ἔφη, “Ἄνδρες, ἀδελφοὶ καὶ πατέρες, ἀκούσατε! Ὁ ˚Θεὸς τῆς δόξης ὤφθη τῷ πατρὶ ἡμῶν Ἀβραὰμ, ὄντι ἐν τῇ Μεσοποταμίᾳ πρὶν ἢ κατοικῆσαι αὐτὸν ἐν Χαρράν,
44007003 καὶ εἶπεν πρὸς αὐτόν, ‘Ἔξελθε ἐκ τῆς γῆς σου καὶ ἐκ τῆς συγγενείας σου, καὶ δεῦρο εἰς τὴν γῆν ἣν ἄν σοι δείξω.’
44007004 Τότε ἐξελθὼν ἐκ γῆς Χαλδαίων, κατῴκησεν ἐν Χαρράν. Κἀκεῖθεν μετὰ τὸ ἀποθανεῖν τὸν πατέρα αὐτοῦ, μετῴκισεν αὐτὸν εἰς τὴν γῆν ταύτην, εἰς ἣν ὑμεῖς νῦν κατοικεῖτε.
44007005 Καὶ οὐκ ἔδωκεν αὐτῷ κληρονομίαν ἐν αὐτῇ, οὐδὲ βῆμα ποδός, καὶ ἐπηγγείλατο ‘δοῦναι αὐτῷ εἰς κατάσχεσιν αὐτὴν, καὶ τῷ σπέρματι αὐτοῦ μετʼ αὐτόν’, οὐκ ὄντος αὐτῷ τέκνου.
44007006 Ἐλάλησεν δὲ οὕτως ὁ ˚Θεὸς ὅτι ἔσται τὸ σπέρμα αὐτοῦ, ‘πάροικον ἐν γῇ ἀλλοτρίᾳ, καὶ δουλώσουσιν αὐτὸ καὶ κακώσουσιν ἔτη τετρακόσια.
44007007 Καὶ τὸ ἔθνος ᾧ ἐὰν δουλεύσουσιν, κρινῶ ἐγώ’, ὁ ˚Θεὸς εἶπεν, ‘Καὶ μετὰ ταῦτα, ἐξελεύσονται καὶ λατρεύσουσίν μοι ἐν τῷ τόπῳ τούτῳ.’
44007008 Καὶ ἔδωκεν αὐτῷ διαθήκην περιτομῆς· καὶ οὕτως ἐγέννησεν τὸν Ἰσαὰκ, καὶ περιέτεμεν αὐτὸν τῇ ἡμέρᾳ τῇ ὀγδόῃ, καὶ Ἰσαὰκ τὸν Ἰακώβ, καὶ Ἰακὼβ τοὺς δώδεκα πατριάρχας.
44007009 Καὶ οἱ πατριάρχαι ζηλώσαντες τὸν Ἰωσὴφ, ἀπέδοντο εἰς Αἴγυπτον· καὶ ἦν ὁ ˚Θεὸς μετʼ αὐτοῦ,
44007010 καὶ ἐξείλατο αὐτὸν ἐκ πασῶν τῶν θλίψεων αὐτοῦ, καὶ ἔδωκεν αὐτῷ χάριν καὶ σοφίαν ἐναντίον Φαραὼ, βασιλέως Αἰγύπτου, καὶ κατέστησεν αὐτὸν ἡγούμενον ἐπʼ Αἴγυπτον, καὶ ἐφʼ ὅλον τὸν οἶκον αὐτοῦ.
44007011 Ἦλθεν δὲ λιμὸς ἐφʼ ὅλην τὴν Αἴγυπτον καὶ Χανάαν, καὶ θλῖψις μεγάλη, καὶ οὐχ εὕρισκον χορτάσματα οἱ πατέρες ἡμῶν.
44007012 Ἀκούσας δὲ Ἰακὼβ, ὄντα σιτία εἰς Αἴγυπτον, ἐξαπέστειλεν τοὺς πατέρας ἡμῶν πρῶτον.
44007013 Καὶ ἐν τῷ δευτέρῳ, ἐγνωρίσθη Ἰωσὴφ τοῖς ἀδελφοῖς αὐτοῦ, καὶ φανερὸν ἐγένετο τῷ Φαραὼ τὸ γένος Ἰωσήφ.
44007014 Ἀποστείλας δὲ, Ἰωσὴφ μετεκαλέσατο Ἰακὼβ, τὸν πατέρα αὐτοῦ, καὶ πᾶσαν τὴν συγγένειαν, ἐν ψυχαῖς ἑβδομήκοντα πέντε.
44007015 Καὶ κατέβη Ἰακὼβ εἰς Αἴγυπτον, καὶ ἐτελεύτησεν αὐτὸς καὶ οἱ πατέρες ἡμῶν,
44007016 καὶ μετετέθησαν εἰς Συχὲμ, καὶ ἐτέθησαν ἐν τῷ μνήματι, ᾧ ὠνήσατο Ἀβραὰμ τιμῆς ἀργυρίου παρὰ τῶν υἱῶν Ἑμμὼρ ἐν Συχέμ.
44007017 Καθὼς δὲ ἤγγιζεν ὁ χρόνος τῆς ἐπαγγελίας, ἧς ὡμολόγησεν ὁ ˚Θεὸς τῷ Ἀβραάμ, ηὔξησεν ὁ λαὸς καὶ ἐπληθύνθη ἐν Αἰγύπτῳ,
44007018 ἄχρι ‘οὗ ἀνέστη βασιλεὺς ἕτερος ἐπʼ Αἴγυπτον, ὃς οὐκ ᾔδει τὸν Ἰωσήφ’.
44007019 Οὗτος, κατασοφισάμενος τὸ γένος ἡμῶν, ἐκάκωσεν τοὺς πατέρας τοῦ ποιεῖν τὰ βρέφη ἔκθετα αὐτῶν, εἰς τὸ μὴ ζῳογονεῖσθαι.
44007020 Ἐν ᾧ καιρῷ ἐγεννήθη Μωϋσῆς, καὶ ἦν ἀστεῖος τῷ ˚Θεῷ· ὃς ἀνετράφη μῆνας τρεῖς ἐν τῷ οἴκῳ τοῦ πατρός.
44007021 Ἐκτεθέντος δὲ αὐτοῦ, ἀνείλατο αὐτὸν ἡ θυγάτηρ Φαραὼ, καὶ ἀνεθρέψατο αὐτὸν ἑαυτῇ εἰς υἱόν.
44007022 Καὶ ἐπαιδεύθη Μωϋσῆς ἐν πάσῃ σοφίᾳ Αἰγυπτίων, ἦν δὲ δυνατὸς ἐν λόγοις καὶ ἔργοις αὐτοῦ.
44007023 Ὡς δὲ ἐπληροῦτο αὐτῷ τεσσερακονταετὴς χρόνος, ἀνέβη ἐπὶ τὴν καρδίαν αὐτοῦ ἐπισκέψασθαι τοὺς ἀδελφοὺς αὐτοῦ, τοὺς υἱοὺς Ἰσραήλ.
44007024 Καὶ ἰδών τινα ἀδικούμενον, ἠμύνατο καὶ ἐποίησεν ἐκδίκησιν τῷ καταπονουμένῳ, πατάξας τὸν Αἰγύπτιον.
44007025 Ἐνόμιζεν δὲ συνιέναι τοὺς ἀδελφοὺς ὅτι ὁ ˚Θεὸς διὰ χειρὸς αὐτοῦ δίδωσιν σωτηρίαν αὐτοῖς, οἱ δὲ οὐ συνῆκαν.
44007026 Τῇ τε ἐπιούσῃ ἡμέρᾳ, ὤφθη αὐτοῖς μαχομένοις, καὶ συνήλλασσεν αὐτοὺς εἰς εἰρήνην, εἰπών, ‘Ἄνδρες, ἀδελφοί ἐστε. Ἵνα τί ἀδικεῖτε ἀλλήλους;’
44007027 Ὁ δὲ ἀδικῶν τὸν πλησίον ἀπώσατο αὐτὸν εἰπών, ‘Τίς σε κατέστησεν ἄρχοντα καὶ δικαστὴν ἐφʼ ἡμῶν;
44007028 Μὴ ἀνελεῖν με σὺ θέλεις, ὃν τρόπον ἀνεῖλες ἐχθὲς τὸν Αἰγύπτιον;’
44007029 ‘Ἔφυγεν δὲ Μωϋσῆς’ ἐν τῷ λόγῳ τούτῳ, ‘καὶ ἐγένετο πάροικος ἐν γῇ Μαδιάμ’, οὗ ἐγέννησεν υἱοὺς δύο.
44007030 Καὶ πληρωθέντων ἐτῶν τεσσεράκοντα, ‘ὤφθη αὐτῷ ἐν τῇ ἐρήμῳ τοῦ Ὄρους Σινᾶ, ἄγγελος ἐν φλογὶ πυρὸς βάτου’.
44007031 Ὁ δὲ Μωϋσῆς ἰδὼν, ἐθαύμασεν τὸ ὅραμα. Προσερχομένου δὲ αὐτοῦ κατανοῆσαι, ἐγένετο φωνὴ ˚Κυρίου:
44007032 ‘Ἐγὼ ὁ ˚Θεὸς τῶν πατέρων σου, ὁ ˚Θεὸς Ἀβραὰμ, καὶ Ἰσαὰκ, καὶ Ἰακώβ.’ Ἔντρομος δὲ γενόμενος, Μωϋσῆς οὐκ ἐτόλμα κατανοῆσαι.
44007033 Εἶπεν δὲ αὐτῷ ὁ ˚Κύριος, ‘Λῦσον τὸ ὑπόδημα τῶν ποδῶν σου, ὁ γὰρ τόπος ἐφʼ ᾧ ἕστηκας γῆ ἁγία ἐστίν.’
44007034 ‘Ἰδὼν, εἶδον τὴν κάκωσιν τοῦ λαοῦ μου τοῦ ἐν Αἰγύπτῳ, καὶ τοῦ στεναγμοῦ αὐτῶν ἤκουσα, καὶ κατέβην ἐξελέσθαι αὐτούς’· ‘Καὶ νῦν δεῦρο, ἀποστείλω σε εἰς Αἴγυπτον.’
44007035 Τοῦτον τὸν Μωϋσῆν, ὃν ἠρνήσαντο εἰπόντες, ‘Τίς σε κατέστησεν ἄρχοντα καὶ δικαστήν;’ Τοῦτον ὁ ˚Θεὸς ἄρχοντα καὶ λυτρωτὴν ἀπέσταλκεν, σὺν χειρὶ ἀγγέλου τοῦ ὀφθέντος αὐτῷ ἐν τῇ βάτῳ.
44007036 Οὗτος ἐξήγαγεν αὐτοὺς, ποιήσας τέρατα καὶ σημεῖα ἐν τῇ Αἰγύπτῳ, καὶ ἐν Ἐρυθρᾷ Θαλάσσῃ, καὶ ἐν τῇ ἐρήμῳ, ἔτη τεσσεράκοντα.
44007037 Οὗτός ἐστιν ὁ Μωϋσῆς ὁ εἴπας τοῖς υἱοῖς Ἰσραήλ, ‘Προφήτην ὑμῖν ἀναστήσει ὁ ˚Θεὸς, ἐκ τῶν ἀδελφῶν ὑμῶν, ὡς ἐμέ.’
44007038 Οὗτός ἐστιν ὁ γενόμενος ἐν τῇ ἐκκλησίᾳ ἐν τῇ ἐρήμῳ, μετὰ τοῦ ἀγγέλου τοῦ λαλοῦντος αὐτῷ ἐν τῷ Ὄρει Σινᾶ, καὶ τῶν πατέρων ἡμῶν, ὃς ἐδέξατο λόγια ζῶντα δοῦναι ὑμῖν·
44007039 ᾧ οὐκ ἠθέλησαν ὑπήκοοι γενέσθαι οἱ πατέρες ἡμῶν, ἀλλὰ ἀπώσαντο καὶ ἐστράφησαν ἐν ταῖς καρδίαις αὐτῶν εἰς Αἴγυπτον,
44007040 εἰπόντες τῷ Ἀαρών, ‘Ποίησον ἡμῖν θεοὺς οἳ προπορεύσονται ἡμῶν. Ὁ γὰρ Μωϋσῆς οὗτος, ὃς ἐξήγαγεν ἡμᾶς ἐκ γῆς Αἰγύπτου, οὐκ οἴδαμεν τί ἐγένετο αὐτῷ.’
44007041 Καὶ ἐμοσχοποίησαν ἐν ταῖς ἡμέραις ἐκείναις, καὶ ἀνήγαγον θυσίαν τῷ εἰδώλῳ, καὶ εὐφραίνοντο ἐν τοῖς ἔργοις τῶν χειρῶν αὐτῶν.
44007042 Ἔστρεψεν δὲ ὁ ˚Θεὸς, καὶ παρέδωκεν αὐτοὺς λατρεύειν τῇ στρατιᾷ τοῦ οὐρανοῦ, καθὼς γέγραπται ἐν βίβλῳ τῶν προφητῶν, ‘Μὴ σφάγια καὶ θυσίας προσηνέγκατέ μοι, ἔτη τεσσεράκοντα ἐν τῇ ἐρήμῳ, οἶκος Ἰσραήλ;
44007043 Καὶ ἀνελάβετε τὴν σκηνὴν τοῦ Μολὸχ, καὶ τὸ ἄστρον τοῦ θεοῦ ὑμῶν Ῥαιφάν, τοὺς τύπους οὓς ἐποιήσατε προσκυνεῖν αὐτοῖς. Καὶ μετοικιῶ ὑμᾶς ἐπέκεινα Βαβυλῶνος.’
44007044 Ἡ σκηνὴ τοῦ μαρτυρίου ἦν τοῖς πατράσιν ἡμῶν ἐν τῇ ἐρήμῳ, καθὼς διετάξατο ὁ λαλῶν τῷ Μωϋσῇ, ποιῆσαι αὐτὴν κατὰ τὸν τύπον ὃν ἑωράκει,
44007045 ἣν καὶ εἰσήγαγον, διαδεξάμενοι οἱ πατέρες ἡμῶν μετὰ ˚Ἰησοῦ ἐν τῇ κατασχέσει τῶν ἐθνῶν, ὧν ἐξῶσεν ὁ ˚Θεὸς ἀπὸ προσώπου τῶν πατέρων ἡμῶν, ἕως τῶν ἡμερῶν Δαυίδ·
44007046 ὃς εὗρεν χάριν ἐνώπιον τοῦ ˚Θεοῦ, καὶ ᾐτήσατο εὑρεῖν σκήνωμα τῷ οἴκῳ Ἰακώβ.
44007047 Σολομῶν δὲ ᾠκοδόμησεν αὐτῷ οἶκον.
44007048 Ἀλλʼ οὐχ ὁ Ὕψιστος ἐν χειροποιήτοις κατοικεῖ, καθὼς ὁ προφήτης λέγει,
44007049 ‘Ὁ οὐρανός μοι θρόνος, ἡ δὲ γῆ ὑποπόδιον τῶν ποδῶν μου. Ποῖον οἶκον οἰκοδομήσετέ μοι; Λέγει ˚Κύριος, ἢ τίς τόπος τῆς καταπαύσεώς μου;
44007050 Οὐχὶ ἡ χείρ μου ἐποίησεν ταῦτα πάντα;’
44007051 Σκληροτράχηλοι καὶ ἀπερίτμητοι καρδίαις καὶ τοῖς ὠσίν, ὑμεῖς ἀεὶ τῷ ˚Πνεύματι τῷ Ἁγίῳ ἀντιπίπτετε, ὡς οἱ πατέρες ὑμῶν, καὶ ὑμεῖς.
44007052 Τίνα τῶν προφητῶν οὐκ ἐδίωξαν οἱ πατέρες ὑμῶν; Καὶ ἀπέκτειναν τοὺς προκαταγγείλαντας περὶ τῆς ἐλεύσεως τοῦ Δικαίου, οὗ νῦν ὑμεῖς προδόται καὶ φονεῖς ἐγένεσθε!
44007053 Οἵτινες ἐλάβετε τὸν νόμον εἰς διαταγὰς ἀγγέλων, καὶ οὐκ ἐφυλάξατε.”
44007054 Ἀκούοντες δὲ ταῦτα, διεπρίοντο ταῖς καρδίαις αὐτῶν, καὶ ἔβρυχον τοὺς ὀδόντας ἐπʼ αὐτόν.
44007055 Ὑπάρχων δὲ πλήρης ˚Πνεύματος Ἁγίου, ἀτενίσας εἰς τὸν οὐρανὸν, εἶδεν δόξαν ˚Θεοῦ, καὶ ˚Ἰησοῦν ἑστῶτα ἐκ δεξιῶν τοῦ ˚Θεοῦ,
44007056 καὶ εἶπεν, “Ἰδοὺ, θεωρῶ τοὺς οὐρανοὺς διηνοιγμένους, καὶ τὸν Υἱὸν τοῦ Ἀνθρώπου ἐκ δεξιῶν ἑστῶτα τοῦ ˚Θεοῦ.”
44007057 Κράξαντες δὲ φωνῇ μεγάλῃ, συνέσχον τὰ ὦτα αὐτῶν, καὶ ὥρμησαν ὁμοθυμαδὸν ἐπʼ αὐτόν.
44007058 Καὶ ἐκβαλόντες ἔξω τῆς πόλεως, ἐλιθοβόλουν. Καὶ οἱ μάρτυρες ἀπέθεντο τὰ ἱμάτια αὐτῶν, παρὰ τοὺς πόδας νεανίου καλουμένου Σαύλου.
44007059 Καὶ ἐλιθοβόλουν τὸν Στέφανον, ἐπικαλούμενον καὶ λέγοντα, “˚Κύριε ˚Ἰησοῦ, δέξαι τὸ πνεῦμά μου.”
44007060 Θεὶς δὲ τὰ γόνατα, ἔκραξεν φωνῇ μεγάλῃ, “˚Κύριε, μὴ στήσῃς αὐτοῖς ταύτην τὴν ἁμαρτίαν.” Καὶ τοῦτο εἰπὼν, ἐκοιμήθη.
44008001 Σαῦλος δὲ ἦν συνευδοκῶν τῇ ἀναιρέσει αὐτοῦ. Ἐγένετο δὲ ἐν ἐκείνῃ τῇ ἡμέρᾳ, διωγμὸς μέγας ἐπὶ τὴν ἐκκλησίαν, τὴν ἐν Ἱεροσολύμοις. Πάντες δὲ διεσπάρησαν κατὰ τὰς χώρας τῆς Ἰουδαίας καὶ Σαμαρείας, πλὴν τῶν ἀποστόλων.
44008002 Συνεκόμισαν δὲ τὸν Στέφανον ἄνδρες εὐλαβεῖς, καὶ ἐποίησαν κοπετὸν μέγαν ἐπʼ αὐτῷ.
44008003 Σαῦλος δὲ ἐλυμαίνετο τὴν ἐκκλησίαν, κατὰ τοὺς οἴκους εἰσπορευόμενος, σύρων τε ἄνδρας καὶ γυναῖκας, παρεδίδου εἰς φυλακήν.
44008004 Οἱ μὲν οὖν διασπαρέντες, διῆλθον εὐαγγελιζόμενοι τὸν λόγον.
44008005 Φίλιππος δὲ κατελθὼν εἰς τὴν πόλιν τῆς Σαμαρείας, ἐκήρυσσεν αὐτοῖς τὸν ˚Χριστόν.
44008006 Προσεῖχον δὲ οἱ ὄχλοι τοῖς λεγομένοις ὑπὸ τοῦ Φιλίππου ὁμοθυμαδὸν, ἐν τῷ ἀκούειν αὐτοὺς καὶ βλέπειν τὰ σημεῖα ἃ ἐποίει.
44008007 Πολλοὶ γὰρ τῶν ἐχόντων πνεύματα ἀκάθαρτα, βοῶντα φωνῇ μεγάλῃ, ἐξήρχοντο, πολλοὶ δὲ παραλελυμένοι καὶ χωλοὶ ἐθεραπεύθησαν.
44008008 Ἐγένετο δὲ πολλὴ χαρὰ ἐν τῇ πόλει ἐκείνῃ.
44008009 Ἀνὴρ δέ τις ὀνόματι Σίμων, προϋπῆρχεν ἐν τῇ πόλει μαγεύων, καὶ ἐξιστάνων τὸ ἔθνος τῆς Σαμαρείας, λέγων εἶναί τινα ἑαυτὸν μέγαν.
44008010 ᾧ προσεῖχον πάντες ἀπὸ μικροῦ ἕως μεγάλου λέγοντες, “Οὗτός ἐστιν ἡ Δύναμις τοῦ ˚Θεοῦ, ἡ καλουμένη Μεγάλη.”
44008011 Προσεῖχον δὲ αὐτῷ, διὰ τὸ ἱκανῷ χρόνῳ ταῖς μαγείαις ἐξεστακέναι αὐτούς.
44008012 Ὅτε δὲ ἐπίστευσαν τῷ Φιλίππῳ, εὐαγγελιζομένῳ περὶ τῆς Βασιλείας τοῦ ˚Θεοῦ καὶ τοῦ ὀνόματος ˚Ἰησοῦ ˚Χριστοῦ, ἐβαπτίζοντο ἄνδρες τε καὶ γυναῖκες.
44008013 Ὁ δὲ Σίμων καὶ αὐτὸς ἐπίστευσεν, καὶ βαπτισθεὶς, ἦν προσκαρτερῶν τῷ Φιλίππῳ, θεωρῶν τε σημεῖα καὶ δυνάμεις μεγάλας γινομένας, ἐξίστατο.
44008014 Ἀκούσαντες δὲ οἱ ἐν Ἱεροσολύμοις ἀπόστολοι ὅτι δέδεκται ἡ Σαμάρεια τὸν λόγον τοῦ ˚Θεοῦ, ἀπέστειλαν πρὸς αὐτοὺς Πέτρον καὶ Ἰωάννην,
44008015 οἵτινες καταβάντες, προσηύξαντο περὶ αὐτῶν, ὅπως λάβωσιν ˚Πνεῦμα Ἅγιον.
44008016 Οὐδέπω γὰρ ἦν ἐπʼ οὐδενὶ αὐτῶν ἐπιπεπτωκός, μόνον δὲ βεβαπτισμένοι ὑπῆρχον εἰς τὸ ὄνομα τοῦ ˚Κυρίου ˚Ἰησοῦ.
44008017 Τότε ἐπετίθεσαν τὰς χεῖρας ἐπʼ αὐτούς, καὶ ἐλάμβανον ˚Πνεῦμα Ἅγιον.
44008018 Ἰδὼν δὲ ὁ Σίμων ὅτι διὰ τῆς ἐπιθέσεως τῶν χειρῶν τῶν ἀποστόλων δίδοται τὸ ˚Πνεῦμα, προσήνεγκεν αὐτοῖς χρήματα
44008019 λέγων, “Δότε κἀμοὶ τὴν ἐξουσίαν ταύτην, ἵνα ᾧ ἐὰν ἐπιθῶ τὰς χεῖρας, λαμβάνῃ ˚Πνεῦμα Ἅγιον.”
44008020 Πέτρος δὲ εἶπεν πρὸς αὐτόν, “Τὸ ἀργύριόν σου, σὺν σοὶ εἴη εἰς ἀπώλειαν, ὅτι τὴν δωρεὰν τοῦ ˚Θεοῦ ἐνόμισας διὰ χρημάτων κτᾶσθαι.
44008021 Οὐκ ἔστιν σοι μερὶς οὐδὲ κλῆρος ἐν τῷ λόγῳ τούτῳ. Ἡ γὰρ καρδία σου οὐκ ἔστιν εὐθεῖα ἔναντι τοῦ ˚Θεοῦ.
44008022 Μετανόησον οὖν ἀπὸ τῆς κακίας σου ταύτης, καὶ δεήθητι τοῦ ˚Κυρίου εἰ ἄρα ἀφεθήσεταί σοι ἡ ἐπίνοια τῆς καρδίας σου.
44008023 Εἰς γὰρ χολὴν πικρίας καὶ σύνδεσμον ἀδικίας, ὁρῶ σε ὄντα.”
44008024 Ἀποκριθεὶς δὲ ὁ Σίμων εἶπεν, “Δεήθητε ὑμεῖς ὑπὲρ ἐμοῦ πρὸς τὸν ˚Κύριον, ὅπως μηδὲν ἐπέλθῃ ἐπʼ ἐμὲ ὧν εἰρήκατε.”
44008025 Οἱ μὲν οὖν, διαμαρτυράμενοι καὶ λαλήσαντες τὸν λόγον τοῦ ˚Κυρίου, ὑπέστρεφον εἰς Ἱεροσόλυμα, πολλάς τε κώμας τῶν Σαμαριτῶν εὐηγγελίζοντο.
44008026 Ἄγγελος δὲ ˚Κυρίου ἐλάλησεν πρὸς Φίλιππον λέγων, “Ἀνάστηθι καὶ πορεύου κατὰ μεσημβρίαν, ἐπὶ τὴν ὁδὸν τὴν καταβαίνουσαν ἀπὸ Ἰερουσαλὴμ εἰς Γάζαν”· αὕτη ἐστὶν ἔρημος.
44008027 Καὶ ἀναστὰς, ἐπορεύθη, καὶ ἰδοὺ, ἀνὴρ, Αἰθίοψ εὐνοῦχος, δυνάστης Κανδάκης βασιλίσσης Αἰθιόπων, ὃς ἦν ἐπὶ πάσης τῆς γάζης αὐτῆς, ὃς ἐληλύθει προσκυνήσων εἰς Ἰερουσαλήμ,
44008028 ἦν δὲ ὑποστρέφων καὶ καθήμενος ἐπὶ τοῦ ἅρματος αὐτοῦ, καὶ ἀνεγίνωσκεν τὸν προφήτην Ἠσαΐαν.
44008029 Εἶπεν δὲ τὸ ˚Πνεῦμα τῷ Φιλίππῳ, “Πρόσελθε καὶ κολλήθητι τῷ ἅρματι τούτῳ.”
44008030 Προσδραμὼν δὲ, ὁ Φίλιππος ἤκουσεν αὐτοῦ ἀναγινώσκοντος Ἠσαΐαν τὸν προφήτην, καὶ εἶπεν, “Ἆρά γε γινώσκεις ἃ ἀναγινώσκεις;”
44008031 Ὁ δὲ εἶπεν, “Πῶς γὰρ ἂν δυναίμην ἐὰν μή τις ὁδηγήσει με;” Παρεκάλεσέν τε τὸν Φίλιππον ἀναβάντα καθίσαι σὺν αὐτῷ.
44008032 Ἡ δὲ περιοχὴ τῆς Γραφῆς ἣν ἀνεγίνωσκεν ἦν αὕτη: “Ὡς πρόβατον ἐπὶ σφαγὴν ἤχθη, καὶ ὡς ἀμνὸς ἐναντίον τοῦ κείραντος αὐτὸν ἄφωνος, οὕτως οὐκ ἀνοίγει τὸ στόμα αὐτοῦ.
44008033 Ἐν τῇ ταπεινώσει, ἡ κρίσις αὐτοῦ ἤρθη, τὴν γενεὰν αὐτοῦ τίς διηγήσεται; Ὅτι αἴρεται ἀπὸ τῆς γῆς ἡ ζωὴ αὐτοῦ.”
44008034 Ἀποκριθεὶς δὲ ὁ εὐνοῦχος τῷ Φιλίππῳ εἶπεν, “Δέομαί σου περὶ τίνος ὁ προφήτης λέγει τοῦτο; Περὶ ἑαυτοῦ ἢ περὶ ἑτέρου τινός;”
44008035 Ἀνοίξας δὲ ὁ Φίλιππος τὸ στόμα αὐτοῦ, καὶ ἀρξάμενος ἀπὸ τῆς Γραφῆς ταύτης, εὐηγγελίσατο αὐτῷ τὸν ˚Ἰησοῦν.
44008036 Ὡς δὲ ἐπορεύοντο κατὰ τὴν ὁδόν, ἦλθον ἐπί τι ὕδωρ, καί φησιν ὁ εὐνοῦχος, “Ἰδοὺ, ὕδωρ, τί κωλύει με βαπτισθῆναι;”
44008038 Καὶ ἐκέλευσεν στῆναι τὸ ἅρμα, καὶ κατέβησαν, ἀμφότεροι εἰς τὸ ὕδωρ, ὅ τε Φίλιππος καὶ ὁ εὐνοῦχος, καὶ ἐβάπτισεν αὐτόν.
44008039 Ὅτε δὲ ἀνέβησαν ἐκ τοῦ ὕδατος, ˚Πνεῦμα ˚Κυρίου ἥρπασεν τὸν Φίλιππον, καὶ οὐκ εἶδεν αὐτὸν οὐκέτι ὁ εὐνοῦχος, ἐπορεύετο γὰρ τὴν ὁδὸν αὐτοῦ χαίρων.
44008040 Φίλιππος δὲ εὑρέθη εἰς Ἄζωτον, καὶ διερχόμενος εὐηγγελίζετο τὰς πόλεις πάσας, ἕως τοῦ ἐλθεῖν αὐτὸν εἰς Καισάρειαν.
44009001 Ὁ δὲ Σαῦλος, ἔτι ἐμπνέων ἀπειλῆς καὶ φόνου εἰς τοὺς μαθητὰς τοῦ ˚Κυρίου, προσελθὼν τῷ ἀρχιερεῖ,
44009002 ᾐτήσατο παρʼ αὐτοῦ ἐπιστολὰς εἰς Δαμασκὸν πρὸς τὰς συναγωγάς, ὅπως ἐάν τινας εὕρῃ τῆς ὁδοῦ, ὄντας ἄνδρας τε καὶ γυναῖκας, δεδεμένους ἀγάγῃ εἰς Ἰερουσαλήμ.
44009003 Ἐν δὲ τῷ πορεύεσθαι, ἐγένετο αὐτὸν ἐγγίζειν τῇ Δαμασκῷ, ἐξαίφνης τε αὐτὸν περιήστραψεν φῶς ἐκ τοῦ οὐρανοῦ.
44009004 Καὶ πεσὼν ἐπὶ τὴν γῆν, ἤκουσεν φωνὴν λέγουσαν αὐτῷ, “Σαοὺλ, Σαούλ, τί με διώκεις;”
44009005 Εἶπεν δέ, “Τίς εἶ, Κύριε;” Ὁ δέ, “Ἐγώ εἰμι ˚Ἰησοῦς, ὃν σὺ διώκεις·
44009006 ἀλλὰ ἀνάστηθι καὶ εἴσελθε εἰς τὴν πόλιν, καὶ λαληθήσεταί σοι ὅ τί σε δεῖ ποιεῖν.”
44009007 Οἱ δὲ ἄνδρες οἱ συνοδεύοντες αὐτῷ εἱστήκεισαν ἐνεοί, ἀκούοντες μὲν τῆς φωνῆς, μηδένα δὲ θεωροῦντες.
44009008 Ἠγέρθη δὲ Σαῦλος ἀπὸ τῆς γῆς, ἀνεῳγμένων δὲ τῶν ὀφθαλμῶν αὐτοῦ, οὐδὲν ἔβλεπεν. Χειραγωγοῦντες δὲ αὐτὸν, εἰσήγαγον εἰς Δαμασκόν.
44009009 Καὶ ἦν ἡμέρας τρεῖς μὴ βλέπων, καὶ οὐκ ἔφαγεν οὐδὲ ἔπιεν.
44009010 Ἦν δέ τις μαθητὴς ἐν Δαμασκῷ ὀνόματι Ἁνανίας, καὶ εἶπεν πρὸς αὐτὸν ἐν ὁράματι ὁ ˚Κύριος, “Ἁνανία.” Ὁ δὲ εἶπεν, “Ἰδοὺ, ἐγώ, ˚Κύριε.”
44009011 Ὁ δὲ ˚Κύριος πρὸς αὐτόν, “Ἀναστὰς, πορεύθητι ἐπὶ τὴν ῥύμην τὴν καλουμένην Εὐθεῖαν, καὶ ζήτησον ἐν οἰκίᾳ Ἰούδα Σαῦλον ὀνόματι Ταρσέα, ἰδοὺ γὰρ, προσεύχεται
44009012 καὶ εἶδεν ἄνδρα ἐν ὁράματι, Ἁνανίαν ὀνόματι, εἰσελθόντα καὶ ἐπιθέντα αὐτῷ χεῖρας, ὅπως ἀναβλέψῃ.”
44009013 Ἀπεκρίθη δὲ Ἁνανίας, “˚Κύριε, ἤκουσα ἀπὸ πολλῶν περὶ τοῦ ἀνδρὸς τούτου, ὅσα κακὰ τοῖς ἁγίοις σου ἐποίησεν ἐν Ἰερουσαλήμ·
44009014 καὶ ὧδε ἔχει ἐξουσίαν παρὰ τῶν ἀρχιερέων, δῆσαι πάντας τοὺς ἐπικαλουμένους τὸ ὄνομά σου.”
44009015 Εἶπεν δὲ πρὸς αὐτὸν ὁ ˚Κύριος, “Πορεύου, ὅτι σκεῦος ἐκλογῆς ἐστίν μοι οὗτος, τοῦ βαστάσαι τὸ ὄνομά μου ἐνώπιον τῶν ἐθνῶν τε καὶ βασιλέων, υἱῶν τε Ἰσραήλ,
44009016 ἐγὼ γὰρ ὑποδείξω αὐτῷ ὅσα δεῖ αὐτὸν ὑπὲρ τοῦ ὀνόματός μου παθεῖν.”
44009017 Ἀπῆλθεν δὲ Ἁνανίας καὶ εἰσῆλθεν εἰς τὴν οἰκίαν, καὶ ἐπιθεὶς ἐπʼ αὐτὸν τὰς χεῖρας εἶπεν, “Σαοὺλ, ἀδελφέ, ὁ ˚Κύριος ἀπέσταλκέν με ˚Ἰησοῦς, ὁ ὀφθείς σοι ἐν τῇ ὁδῷ ᾗ ἤρχου, ὅπως ἀναβλέψῃς καὶ πλησθῇς ˚Πνεύματος Ἁγίου.”
44009018 Καὶ εὐθέως ἀπέπεσαν αὐτοῦ ἀπὸ τῶν ὀφθαλμῶν ὡς λεπίδες, ἀνέβλεψέν τε, καὶ ἀναστὰς ἐβαπτίσθη,
44009019 καὶ λαβὼν τροφὴν, ἐνίσχυσεν. Ἐγένετο δὲ μετὰ τῶν ἐν Δαμασκῷ μαθητῶν ἡμέρας τινὰς.
44009020 Καὶ εὐθέως ἐν ταῖς συναγωγαῖς, ἐκήρυσσεν τὸν ˚Ἰησοῦν ὅτι οὗτός ἐστιν ὁ Υἱὸς τοῦ ˚Θεοῦ.
44009021 Ἐξίσταντο δὲ πάντες οἱ ἀκούοντες, καὶ ἔλεγον, “Οὐχ οὗτός ἐστιν ὁ πορθήσας ἐν Ἰερουσαλὴμ τοὺς ἐπικαλουμένους τὸ ὄνομα τοῦτο, καὶ ὧδε εἰς τοῦτο ἐληλύθει, ἵνα δεδεμένους, αὐτοὺς ἀγάγῃ ἐπὶ τοὺς ἀρχιερεῖς;”
44009022 Σαῦλος δὲ μᾶλλον ἐνεδυναμοῦτο, καὶ συνέχυννεν Ἰουδαίους τοὺς κατοικοῦντας ἐν Δαμασκῷ, συμβιβάζων ὅτι οὗτός ἐστιν ὁ ˚Χριστός.
44009023 Ὡς δὲ ἐπληροῦντο ἡμέραι ἱκαναί, συνεβουλεύσαντο οἱ Ἰουδαῖοι ἀνελεῖν αὐτόν·
44009024 ἐγνώσθη δὲ τῷ Σαύλῳ ἡ ἐπιβουλὴ αὐτῶν. Παρετηροῦντο δὲ καὶ τὰς πύλας ἡμέρας τε καὶ νυκτὸς, ὅπως αὐτὸν ἀνέλωσιν·
44009025 λαβόντες δὲ οἱ μαθηταὶ αὐτοῦ νυκτὸς διὰ τοῦ τείχους, καθῆκαν αὐτὸν, χαλάσαντες ἐν σπυρίδι.
44009026 Παραγενόμενος δὲ εἰς Ἰερουσαλὴμ, ἐπείραζεν κολλᾶσθαι τοῖς μαθηταῖς. Καὶ πάντες ἐφοβοῦντο αὐτόν, μὴ πιστεύοντες ὅτι ἐστὶν μαθητής.
44009027 Βαρναβᾶς δὲ ἐπιλαβόμενος αὐτὸν, ἤγαγεν πρὸς τοὺς ἀποστόλους, καὶ διηγήσατο αὐτοῖς πῶς ἐν τῇ ὁδῷ εἶδεν τὸν ˚Κύριον, καὶ ὅτι ἐλάλησεν αὐτῷ, καὶ πῶς ἐν Δαμασκῷ ἐπαρρησιάσατο ἐν τῷ ὀνόματι ˚Ἰησοῦ.
44009028 Καὶ ἦν μετʼ αὐτῶν, εἰσπορευόμενος καὶ ἐκπορευόμενος εἰς Ἰερουσαλήμ, παρρησιαζόμενος ἐν τῷ ὀνόματι τοῦ ˚Κυρίου.
44009029 Ἐλάλει τε καὶ συνεζήτει πρὸς τοὺς Ἑλληνιστάς· οἱ δὲ ἐπεχείρουν ἀνελεῖν αὐτόν.
44009030 Ἐπιγνόντες δὲ, οἱ ἀδελφοὶ κατήγαγον αὐτὸν εἰς Καισάρειαν, καὶ ἐξαπέστειλαν αὐτὸν εἰς Ταρσόν.
44009031 Ἡ μὲν οὖν ἐκκλησία καθʼ ὅλης τῆς Ἰουδαίας, καὶ Γαλιλαίας, καὶ Σαμαρείας, εἶχεν εἰρήνην, οἰκοδομουμένη καὶ πορευομένη τῷ φόβῳ τοῦ ˚Κυρίου, καὶ τῇ παρακλήσει τοῦ Ἁγίου ˚Πνεύματος, ἐπληθύνετο.
44009032 Ἐγένετο δὲ Πέτρον διερχόμενον διὰ πάντων, κατελθεῖν καὶ πρὸς τοὺς ἁγίους τοὺς κατοικοῦντας Λύδδα.
44009033 Εὗρεν δὲ ἐκεῖ ἄνθρωπόν τινα ὀνόματι Αἰνέαν, ἐξ ἐτῶν ὀκτὼ κατακείμενον ἐπὶ κραβάττου, ὃς ἦν παραλελυμένος.
44009034 Καὶ εἶπεν αὐτῷ ὁ Πέτρος, “Αἰνέα, ἰᾶταί σε ˚Ἰησοῦς ˚Χριστός· ἀνάστηθι, καὶ στρῶσον σεαυτῷ.” Καὶ εὐθέως ἀνέστη.
44009035 Καὶ εἶδαν αὐτὸν πάντες οἱ κατοικοῦντες Λύδδα καὶ τὸν Σαρῶνα, οἵτινες ἐπέστρεψαν ἐπὶ τὸν ˚Κύριον.
44009036 Ἐν Ἰόππῃ δέ τις ἦν μαθήτρια ὀνόματι Ταβιθά, ἣ διερμηνευομένη λέγεται, “Δορκάς”. Αὕτη ἦν πλήρης ἔργων ἀγαθῶν, καὶ ἐλεημοσυνῶν ὧν ἐποίει.
44009037 Ἐγένετο δὲ ἐν ταῖς ἡμέραις ἐκείναις, ἀσθενήσασαν αὐτὴν ἀποθανεῖν. Λούσαντες δὲ αὐτὴν ἔθηκαν ἐν ὑπερῴῳ.
44009038 Ἐγγὺς δὲ οὔσης Λύδδας τῇ Ἰόππῃ, οἱ μαθηταὶ ἀκούσαντες ὅτι Πέτρος ἐστὶν ἐν αὐτῇ, ἀπέστειλαν δύο ἄνδρας πρὸς αὐτὸν παρακαλοῦντες, “Μὴ ὀκνήσῃς διελθεῖν ἕως ἡμῶν.”
44009039 Ἀναστὰς δὲ, Πέτρος συνῆλθεν αὐτοῖς, ὃν παραγενόμενον, ἀνήγαγον εἰς τὸ ὑπερῷον, καὶ παρέστησαν αὐτῷ πᾶσαι αἱ χῆραι, κλαίουσαι καὶ ἐπιδεικνύμεναι χιτῶνας καὶ ἱμάτια, ὅσα ἐποίει μετʼ αὐτῶν οὖσα ἡ Δορκάς.
44009040 Ἐκβαλὼν δὲ ἔξω πάντας ὁ Πέτρος, καὶ θεὶς τὰ γόνατα προσηύξατο, καὶ ἐπιστρέψας πρὸς τὸ σῶμα εἶπεν, “Ταβιθά, ἀνάστηθι!” Ἡ δὲ ἤνοιξεν τοὺς ὀφθαλμοὺς αὐτῆς, καὶ ἰδοῦσα τὸν Πέτρον, ἀνεκάθισεν.
44009041 Δοὺς δὲ αὐτῇ χεῖρα, ἀνέστησεν αὐτήν, φωνήσας δὲ τοὺς ἁγίους καὶ τὰς χήρας, παρέστησεν αὐτὴν ζῶσαν.
44009042 Γνωστὸν δὲ ἐγένετο καθʼ ὅλης Ἰόππης, καὶ ἐπίστευσαν πολλοὶ ἐπὶ τὸν ˚Κύριον.
44009043 Ἐγένετο δὲ ἡμέρας ἱκανὰς μεῖναι ἐν Ἰόππῃ, παρά τινι Σίμωνι, βυρσεῖ.
44010001 Ἀνὴρ δέ τις ἐν Καισαρείᾳ ὀνόματι Κορνήλιος, ἑκατοντάρχης ἐκ Σπείρης τῆς καλουμένης Ἰταλικῆς,
44010002 εὐσεβὴς καὶ φοβούμενος τὸν ˚Θεὸν σὺν παντὶ τῷ οἴκῳ αὐτοῦ, ποιῶν ἐλεημοσύνας πολλὰς τῷ λαῷ, καὶ δεόμενος τοῦ ˚Θεοῦ διὰ παντός.
44010003 Εἶδεν ἐν ὁράματι φανερῶς, ὡσεὶ περὶ ὥραν ἐνάτην τῆς ἡμέρας, ἄγγελον τοῦ ˚Θεοῦ εἰσελθόντα πρὸς αὐτὸν, καὶ εἰπόντα αὐτῷ, “Κορνήλιε!”
44010004 Ὁ δὲ, ἀτενίσας αὐτῷ καὶ ἔμφοβος γενόμενος, εἶπεν, “Τί ἐστιν, κύριε;” Εἶπεν δὲ αὐτῷ, “Αἱ προσευχαί σου, καὶ αἱ ἐλεημοσύναι σου, ἀνέβησαν εἰς μνημόσυνον ἔμπροσθεν τοῦ ˚Θεοῦ.
44010005 Καὶ νῦν πέμψον ἄνδρας εἰς Ἰόππην, καὶ μετάπεμψαι Σίμωνά τινα, ὃς ἐπικαλεῖται Πέτρος.
44010006 Οὗτος ξενίζεται παρά τινι Σίμωνι βυρσεῖ, ᾧ ἐστιν οἰκία παρὰ θάλασσαν.”
44010007 Ὡς δὲ ἀπῆλθεν ὁ ἄγγελος ὁ λαλῶν αὐτῷ, φωνήσας δύο τῶν οἰκετῶν καὶ στρατιώτην εὐσεβῆ τῶν προσκαρτερούντων αὐτῷ,
44010008 καὶ ἐξηγησάμενος ἅπαντα αὐτοῖς, ἀπέστειλεν αὐτοὺς εἰς τὴν Ἰόππην.
44010009 Τῇ δὲ ἐπαύριον, ὁδοιπορούντων ἐκείνων καὶ τῇ πόλει ἐγγιζόντων, ἀνέβη Πέτρος ἐπὶ τὸ δῶμα προσεύξασθαι, περὶ ὥραν ἕκτην.
44010010 Ἐγένετο δὲ πρόσπεινος καὶ ἤθελεν γεύσασθαι, παρασκευαζόντων δὲ αὐτῶν, ἐγένετο ἐπʼ αὐτὸν ἔκστασις,
44010011 καὶ θεωρεῖ τὸν οὐρανὸν ἀνεῳγμένον, καὶ καταβαῖνον σκεῦός τι ὡς ὀθόνην μεγάλην, τέσσαρσιν ἀρχαῖς καθιέμενον ἐπὶ τῆς γῆς,
44010012 ἐν ᾧ ὑπῆρχεν πάντα τὰ τετράποδα, καὶ ἑρπετὰ τῆς γῆς, καὶ πετεινὰ τοῦ οὐρανοῦ.
44010013 Καὶ ἐγένετο φωνὴ πρὸς αὐτόν: “Ἀναστάς, Πέτρε, θῦσον καὶ φάγε!”
44010014 Ὁ δὲ Πέτρος εἶπεν, “Μηδαμῶς, ˚Κύριε, ὅτι οὐδέποτε ἔφαγον πᾶν κοινὸν καὶ ἀκάθαρτον.”
44010015 Καὶ φωνὴ πάλιν ἐκ δευτέρου πρὸς αὐτόν: “Ἃ ὁ ˚Θεὸς ἐκαθάρισεν, σὺ μὴ κοίνου.”
44010016 Τοῦτο δὲ ἐγένετο ἐπὶ τρίς, καὶ εὐθὺς ἀνελήμφθη τὸ σκεῦος εἰς τὸν οὐρανόν.
44010017 Ὡς δὲ ἐν ἑαυτῷ διηπόρει ὁ Πέτρος, τί ἂν εἴη τὸ ὅραμα ὃ εἶδεν, ἰδοὺ, οἱ ἄνδρες οἱ ἀπεσταλμένοι ὑπὸ τοῦ Κορνηλίου, διερωτήσαντες τὴν οἰκίαν τοῦ Σίμωνος, ἐπέστησαν ἐπὶ τὸν πυλῶνα.
44010018 Καὶ φωνήσαντες, ἐπυνθάνοντο εἰ Σίμων, ὁ ἐπικαλούμενος Πέτρος, ἐνθάδε ξενίζεται.
44010019 Τοῦ δὲ Πέτρου διενθυμουμένου περὶ τοῦ ὁράματος, εἶπεν τὸ ˚Πνεῦμα, αὐτῷ, “Ἰδοὺ, ἄνδρες τρεῖς ζητοῦσίν σε.
44010020 Ἀλλὰ ἀναστὰς, κατάβηθι καὶ πορεύου σὺν αὐτοῖς, μηδὲν διακρινόμενος, ὅτι ἐγὼ ἀπέσταλκα αὐτούς.”
44010021 Καταβὰς δὲ, Πέτρος πρὸς τοὺς ἄνδρας εἶπεν, “Ἰδοὺ, ἐγώ εἰμι ὃν ζητεῖτε· τίς ἡ αἰτία διʼ ἣν πάρεστε;”
44010022 Οἱ δὲ εἶπαν, “Κορνήλιος ἑκατοντάρχης, ἀνὴρ δίκαιος καὶ φοβούμενος τὸν ˚Θεὸν, μαρτυρούμενός τε ὑπὸ ὅλου τοῦ ἔθνους τῶν Ἰουδαίων, ἐχρηματίσθη ὑπὸ ἀγγέλου ἁγίου, μεταπέμψασθαί σε εἰς τὸν οἶκον αὐτοῦ, καὶ ἀκοῦσαι ῥήματα παρὰ σοῦ.”
44010023 Εἰσκαλεσάμενος οὖν αὐτοὺς ἐξένισεν. Τῇ δὲ ἐπαύριον ἀναστὰς, ἐξῆλθεν σὺν αὐτοῖς, καί τινες τῶν ἀδελφῶν τῶν ἀπὸ Ἰόππης συνῆλθον αὐτῷ.
44010024 Τῇ δὲ ἐπαύριον, εἰσῆλθεν εἰς τὴν Καισάρειαν. Ὁ δὲ Κορνήλιος ἦν προσδοκῶν αὐτοὺς, συγκαλεσάμενος τοὺς συγγενεῖς αὐτοῦ καὶ τοὺς ἀναγκαίους φίλους.
44010025 Ὡς δὲ ἐγένετο τοῦ εἰσελθεῖν τὸν Πέτρον, συναντήσας αὐτῷ ὁ Κορνήλιος, πεσὼν ἐπὶ τοὺς πόδας, προσεκύνησεν.
44010026 Ὁ δὲ Πέτρος ἤγειρεν αὐτὸν λέγων, “Ἀνάστηθι· καὶ ἐγὼ αὐτὸς ἄνθρωπός εἰμι.”
44010027 Καὶ συνομιλῶν αὐτῷ, εἰσῆλθεν καὶ εὑρίσκει συνεληλυθότας πολλούς.
44010028 Ἔφη τε πρὸς αὐτούς, “Ὑμεῖς ἐπίστασθε ὡς ἀθέμιτόν ἐστιν ἀνδρὶ Ἰουδαίῳ κολλᾶσθαι ἢ προσέρχεσθαι ἀλλοφύλῳ. Κἀμοὶ ὁ ˚Θεὸς ἔδειξεν, μηδένα κοινὸν ἢ ἀκάθαρτον λέγειν ἄνθρωπον.
44010029 Διὸ καὶ ἀναντιρρήτως ἦλθον, μεταπεμφθείς. Πυνθάνομαι οὖν τίνι λόγῳ μετεπέμψασθέ με;”
44010030 Καὶ ὁ Κορνήλιος ἔφη, “Ἀπὸ τετάρτης ἡμέρας μέχρι ταύτης τῆς ὥρας, ἤμην τὴν ἐνάτην προσευχόμενος ἐν τῷ οἴκῳ μου, καὶ ἰδοὺ, ἀνὴρ ἔστη ἐνώπιόν μου ἐν ἐσθῆτι λαμπρᾷ,
44010031 καὶ φησίν, ‘Κορνήλιε, εἰσηκούσθη σου ἡ προσευχὴ καὶ αἱ ἐλεημοσύναι σου ἐμνήσθησαν ἐνώπιον τοῦ ˚Θεοῦ.
44010032 Πέμψον οὖν εἰς Ἰόππην καὶ μετακάλεσαι Σίμωνα, ὃς ἐπικαλεῖται Πέτρος· οὗτος ξενίζεται ἐν οἰκίᾳ Σίμωνος, βυρσέως παρὰ θάλασσαν.’
44010033 Ἐξαυτῆς οὖν ἔπεμψα πρὸς σέ, σύ τε καλῶς ἐποίησας παραγενόμενος. Νῦν οὖν πάντες ἡμεῖς ἐνώπιον τοῦ ˚Θεοῦ πάρεσμεν, ἀκοῦσαι πάντα τὰ προστεταγμένα σοι ὑπὸ τοῦ ˚Κυρίου.”
44010034 Ἀνοίξας δὲ Πέτρος τὸ στόμα εἶπεν, “Ἐπʼ ἀληθείας καταλαμβάνομαι ὅτι οὐκ ἔστιν προσωπολήμπτης ὁ ˚Θεός,
44010035 ἀλλʼ ἐν παντὶ ἔθνει, ὁ φοβούμενος αὐτὸν καὶ ἐργαζόμενος δικαιοσύνην, δεκτὸς αὐτῷ ἐστιν.
44010036 Τὸν λόγον ἀπέστειλεν τοῖς υἱοῖς Ἰσραὴλ, εὐαγγελιζόμενος εἰρήνην διὰ ˚Ἰησοῦ ˚Χριστοῦ· οὗτός ἐστιν πάντων ˚Κύριος.
44010037 Ὑμεῖς οἴδατε τὸ γενόμενον ῥῆμα καθʼ ὅλης τῆς Ἰουδαίας, ἀρξάμενος ἀπὸ τῆς Γαλιλαίας, μετὰ τὸ βάπτισμα ὃ ἐκήρυξεν Ἰωάννης,
44010038 ˚Ἰησοῦν τὸν ἀπὸ Ναζαρέθ, ὡς ἔχρισεν αὐτὸν ὁ ˚Θεὸς ˚Πνεύματι Ἁγίῳ καὶ δυνάμει, ὃς διῆλθεν εὐεργετῶν καὶ ἰώμενος πάντας τοὺς καταδυναστευομένους ὑπὸ τοῦ διαβόλου, ὅτι ὁ ˚Θεὸς ἦν μετʼ αὐτοῦ.
44010039 Καὶ ἡμεῖς μάρτυρες πάντων ὧν ἐποίησεν ἔν τε τῇ χώρᾳ τῶν Ἰουδαίων καὶ ἐν Ἰερουσαλήμ· ὃν καὶ ἀνεῖλαν, κρεμάσαντες ἐπὶ ξύλου.
44010040 Τοῦτον ὁ ˚Θεὸς ἤγειρεν τῇ τρίτῃ ἡμέρᾳ, καὶ ἔδωκεν αὐτὸν ἐμφανῆ γενέσθαι,
44010041 οὐ παντὶ τῷ λαῷ, ἀλλὰ μάρτυσι τοῖς προκεχειροτονημένοις ὑπὸ τοῦ ˚Θεοῦ, ἡμῖν, οἵτινες συνεφάγομεν καὶ συνεπίομεν αὐτῷ μετὰ τὸ ἀναστῆναι αὐτὸν ἐκ νεκρῶν.
44010042 Καὶ παρήγγειλεν ἡμῖν κηρύξαι τῷ λαῷ, καὶ διαμαρτύρασθαι ὅτι οὗτός ἐστιν ὁ ὡρισμένος ὑπὸ τοῦ ˚Θεοῦ, κριτὴς ζώντων καὶ νεκρῶν.
44010043 Τούτῳ πάντες οἱ προφῆται μαρτυροῦσιν, ἄφεσιν ἁμαρτιῶν λαβεῖν διὰ τοῦ ὀνόματος αὐτοῦ, πάντα τὸν πιστεύοντα εἰς αὐτόν.”
44010044 Ἔτι λαλοῦντος τοῦ Πέτρου τὰ ῥήματα ταῦτα, ἐπέπεσε τὸ ˚Πνεῦμα τὸ Ἅγιον ἐπὶ πάντας τοὺς ἀκούοντας τὸν λόγον.
44010045 Καὶ ἐξέστησαν οἱ ἐκ περιτομῆς πιστοὶ, ὅσοι συνῆλθαν τῷ Πέτρῳ, ὅτι καὶ ἐπὶ τὰ ἔθνη, ἡ δωρεὰ τοῦ Ἁγίου ˚Πνεύματος ἐκκέχυται.
44010046 Ἤκουον γὰρ αὐτῶν λαλούντων γλώσσαις, καὶ μεγαλυνόντων τὸν ˚Θεόν. Τότε ἀπεκρίθη Πέτρος,
44010047 “Μήτι τὸ ὕδωρ δύναται κωλῦσαί τις τοῦ μὴ βαπτισθῆναι τούτους, οἵτινες τὸ ˚Πνεῦμα τὸ Ἅγιον ἔλαβον, ὡς καὶ ἡμεῖς;”
44010048 Προσέταξεν δὲ αὐτοὺς ἐν τῷ ὀνόματι ˚Ἰησοῦ ˚Χριστοῦ βαπτισθῆναι. Τότε ἠρώτησαν αὐτὸν ἐπιμεῖναι ἡμέρας τινάς.
44011001 Ἤκουσαν δὲ οἱ ἀπόστολοι καὶ οἱ ἀδελφοὶ οἱ ὄντες κατὰ τὴν Ἰουδαίαν ὅτι καὶ τὰ ἔθνη ἐδέξαντο τὸν λόγον τοῦ ˚Θεοῦ.
44011002 Ὅτε δὲ ἀνέβη Πέτρος εἰς Ἰερουσαλήμ, διεκρίνοντο πρὸς αὐτὸν οἱ ἐκ περιτομῆς,
44011003 λέγοντες, ὅτι “Εἰσῆλθεν πρὸς ἄνδρας, ἀκροβυστίαν ἔχοντας, καὶ συνέφαγες αὐτοῖς.”
44011004 Ἀρξάμενος δὲ, Πέτρος ἐξετίθετο αὐτοῖς καθεξῆς, λέγων,
44011005 “Ἐγὼ ἤμην ἐν πόλει Ἰόππῃ προσευχόμενος, καὶ εἶδον ἐν ἐκστάσει ὅραμα, καταβαῖνον σκεῦός τι ὡς ὀθόνην μεγάλην, τέσσαρσιν ἀρχαῖς καθιεμένην ἐκ τοῦ οὐρανοῦ, καὶ ἦλθεν ἄχρι ἐμοῦ·
44011006 εἰς ἣν ἀτενίσας κατενόουν, καὶ εἶδον τὰ τετράποδα τῆς γῆς, καὶ τὰ θηρία, καὶ τὰ ἑρπετὰ, καὶ τὰ πετεινὰ τοῦ οὐρανοῦ.
44011007 Ἤκουσα δὲ καὶ φωνῆς λεγούσης μοι, ‘Ἀναστάς, Πέτρε, θῦσον καὶ φάγε.’
44011008 Εἶπον δέ, ‘Μηδαμῶς, ˚Κύριε, ὅτι κοινὸν ἢ ἀκάθαρτον οὐδέποτε εἰσῆλθεν εἰς τὸ στόμα μου.’
44011009 Ἀπεκρίθη δὲ φωνὴ ἐκ δευτέρου ἐκ τοῦ οὐρανοῦ, ‘Ἃ ὁ ˚Θεὸς ἐκαθάρισεν, σὺ μὴ κοίνου.’
44011010 Τοῦτο δὲ ἐγένετο ἐπὶ τρίς, καὶ ἀνεσπάσθη πάλιν ἅπαντα εἰς τὸν οὐρανόν.
44011011 Καὶ ἰδοὺ, ἐξαυτῆς τρεῖς ἄνδρες ἐπέστησαν ἐπὶ τὴν οἰκίαν ἐν ᾗ ἦμεν, ἀπεσταλμένοι ἀπὸ Καισαρείας πρός με.
44011012 Εἶπεν δὲ τὸ ˚Πνεῦμά μοι, συνελθεῖν αὐτοῖς, μηδὲν διακρίναντα. Ἦλθον δὲ σὺν ἐμοὶ καὶ οἱ ἓξ ἀδελφοὶ οὗτοι, καὶ εἰσήλθομεν εἰς τὸν οἶκον τοῦ ἀνδρός.
44011013 Ἀπήγγειλεν δὲ ἡμῖν πῶς εἶδεν τὸν ἄγγελον ἐν τῷ οἴκῳ αὐτοῦ, σταθέντα καὶ εἰπόντα, ‘Ἀπόστειλον εἰς Ἰόππην, καὶ μετάπεμψαι Σίμωνα, τὸν ἐπικαλούμενον Πέτρον,
44011014 ὃς λαλήσει ῥήματα πρὸς σὲ, ἐν οἷς σωθήσῃ σὺ καὶ πᾶς ὁ οἶκός σου.’
44011015 Ἐν δὲ τῷ ἄρξασθαί με λαλεῖν, ἐπέπεσεν τὸ ˚Πνεῦμα τὸ Ἅγιον ἐπʼ αὐτοὺς, ὥσπερ καὶ ἐφʼ ἡμᾶς ἐν ἀρχῇ.
44011016 Ἐμνήσθην δὲ τοῦ ῥήματος τοῦ ˚Κυρίου, ὡς ἔλεγεν, ‘Ἰωάννης μὲν ἐβάπτισεν ὕδατι, ὑμεῖς δὲ βαπτισθήσεσθε ἐν ˚Πνεύματι Ἁγίῳ.’
44011017 Εἰ οὖν τὴν ἴσην δωρεὰν ἔδωκεν αὐτοῖς ὁ ˚Θεὸς, ὡς καὶ ἡμῖν πιστεύσασιν ἐπὶ τὸν ˚Κύριον ˚Ἰησοῦν ˚Χριστόν, ἐγὼ τίς ἤμην δυνατὸς κωλῦσαι τὸν ˚Θεόν;”
44011018 Ἀκούσαντες δὲ ταῦτα, ἡσύχασαν καὶ ἐδόξασαν τὸν ˚Θεὸν λέγοντες, “Ἄρα καὶ τοῖς ἔθνεσιν ὁ ˚Θεὸς τὴν μετάνοιαν εἰς ζωὴν ἔδωκεν.”
44011019 Οἱ μὲν οὖν διασπαρέντες ἀπὸ τῆς θλίψεως τῆς γενομένης ἐπὶ Στεφάνῳ, διῆλθον ἕως Φοινίκης, καὶ Κύπρου, καὶ Ἀντιοχείας, μηδενὶ λαλοῦντες τὸν λόγον εἰ μὴ μόνον Ἰουδαίοις.
44011020 Ἦσαν δέ τινες ἐξ αὐτῶν ἄνδρες, Κύπριοι καὶ Κυρηναῖοι, οἵτινες ἐλθόντες εἰς Ἀντιόχειαν, ἐλάλουν καὶ πρὸς τοὺς Ἑλληνιστάς, εὐαγγελιζόμενοι τὸν ˚Κύριον ˚Ἰησοῦν.
44011021 Καὶ ἦν χεὶρ ˚Κυρίου μετʼ αὐτῶν, πολύς τε ἀριθμὸς ὁ πιστεύσας, ἐπέστρεψεν ἐπὶ τὸν ˚Κύριον.
44011022 Ἠκούσθη δὲ ὁ λόγος εἰς τὰ ὦτα τῆς ἐκκλησίας τῆς οὔσης ἐν Ἰερουσαλὴμ περὶ αὐτῶν, καὶ ἐξαπέστειλαν Βαρναβᾶν ἕως Ἀντιοχείας·
44011023 ὃς παραγενόμενος, καὶ ἰδὼν τὴν χάριν τὴν τοῦ ˚Θεοῦ, ἐχάρη καὶ παρεκάλει πάντας τῇ προθέσει τῆς καρδίας προσμένειν τῷ ˚Κυρίῳ,
44011024 ὅτι ἦν ἀνὴρ ἀγαθὸς, καὶ πλήρης ˚Πνεύματος Ἁγίου καὶ πίστεως. Καὶ προσετέθη ὄχλος ἱκανὸς τῷ ˚Κυρίῳ.
44011025 Ἐξῆλθεν δὲ εἰς Ταρσὸν ἀναζητῆσαι Σαῦλον,
44011026 καὶ εὑρὼν, ἤγαγεν εἰς Ἀντιόχειαν. Ἐγένετο δὲ αὐτοῖς καὶ ἐνιαυτὸν ὅλον συναχθῆναι ἐν τῇ ἐκκλησίᾳ, καὶ διδάξαι ὄχλον ἱκανόν, χρηματίσαι τε πρώτως ἐν Ἀντιοχείᾳ τοὺς μαθητὰς, Χριστιανούς.
44011027 Ἐν ταύταις δὲ ταῖς ἡμέραις, κατῆλθον ἀπὸ Ἱεροσολύμων προφῆται εἰς Ἀντιόχειαν.
44011028 Ἀναστὰς δὲ εἷς ἐξ αὐτῶν ὀνόματι Ἅγαβος, ἐσήμανεν διὰ τοῦ ˚Πνεύματος, λιμὸν μεγάλην μέλλειν ἔσεσθαι ἐφʼ ὅλην τὴν οἰκουμένην· ἥτις ἐγένετο ἐπὶ Κλαυδίου.
44011029 Τῶν δὲ μαθητῶν καθὼς εὐπορεῖτό τις, ὥρισαν ἕκαστος αὐτῶν εἰς διακονίαν πέμψαι τοῖς κατοικοῦσιν ἐν τῇ Ἰουδαίᾳ ἀδελφοῖς·
44011030 ὃ καὶ ἐποίησαν, ἀποστείλαντες πρὸς τοὺς πρεσβυτέρους διὰ χειρὸς Βαρναβᾶ καὶ Σαύλου.
44012001 κατʼ ἐκεῖνον δὲ τὸν καιρὸν, ἐπέβαλεν Ἡρῴδης ὁ βασιλεὺς τὰς χεῖρας κακῶσαί τινας τῶν ἀπὸ τῆς ἐκκλησίας.
44012002 Ἀνεῖλεν δὲ Ἰάκωβον, τὸν ἀδελφὸν Ἰωάννου, μαχαίρῃ.
44012003 Ἰδὼν δὲ ὅτι ἀρεστόν ἐστιν τοῖς Ἰουδαίοις, προσέθετο συλλαβεῖν καὶ Πέτρον ( ἦσαν δὲ ἡμέραι τῶν Ἀζύμων ),
44012004 ὃν καὶ πιάσας, ἔθετο εἰς φυλακήν, παραδοὺς τέσσαρσιν τετραδίοις στρατιωτῶν φυλάσσειν αὐτόν, βουλόμενος μετὰ τὸ Πάσχα ἀναγαγεῖν αὐτὸν τῷ λαῷ.
44012005 Ὁ μὲν οὖν Πέτρος ἐτηρεῖτο ἐν τῇ φυλακῇ· προσευχὴ δὲ ἦν ἐκτενῶς γινομένη ὑπὸ τῆς ἐκκλησίας πρὸς τὸν ˚Θεὸν περὶ αὐτοῦ.
44012006 Ὅτε δὲ ἤμελλεν προσάγειν αὐτὸν ὁ Ἡρῴδης τῇ νυκτὶ ἐκείνῃ, ἦν ὁ Πέτρος κοιμώμενος μεταξὺ δύο στρατιωτῶν, δεδεμένος ἁλύσεσιν δυσίν, φύλακές τε πρὸ τῆς θύρας ἐτήρουν τὴν φυλακήν.
44012007 Καὶ ἰδοὺ, ἄγγελος ˚Κυρίου ἐπέστη, καὶ φῶς ἔλαμψεν ἐν τῷ οἰκήματι. Πατάξας δὲ τὴν πλευρὰν τοῦ Πέτρου, ἤγειρεν αὐτὸν λέγων, “Ἀνάστα ἐν τάχει.” Καὶ ἐξέπεσαν αὐτοῦ αἱ ἁλύσεις ἐκ τῶν χειρῶν.
44012008 Εἶπεν δὲ ὁ ἄγγελος πρὸς αὐτόν, “Ζῶσαι καὶ ὑπόδησαι τὰ σανδάλιά σου.” Ἐποίησεν δὲ οὕτως. Καὶ λέγει αὐτῷ, “Περιβαλοῦ τὸ ἱμάτιόν σου, καὶ ἀκολούθει μοι.”
44012009 Καὶ ἐξελθὼν ἠκολούθει, καὶ οὐκ ᾔδει ὅτι ἀληθές ἐστιν τὸ γινόμενον διὰ τοῦ ἀγγέλου, ἐδόκει δὲ ὅραμα βλέπειν.
44012010 Διελθόντες δὲ πρώτην φυλακὴν καὶ δευτέραν, ἦλθαν ἐπὶ τὴν πύλην τὴν σιδηρᾶν, τὴν φέρουσαν εἰς τὴν πόλιν, ἥτις αὐτομάτη ἠνοίγη αὐτοῖς, καὶ ἐξελθόντες προῆλθον ῥύμην μίαν, καὶ εὐθέως ἀπέστη ὁ ἄγγελος ἀπʼ αὐτοῦ.
44012011 Καὶ ὁ Πέτρος ἐν ἑαυτῷ γενόμενος εἶπεν, “Νῦν οἶδα ἀληθῶς ὅτι ἐξαπέστειλεν ˚Κύριος τὸν ἄγγελον αὐτοῦ, καὶ ἐξείλατό με ἐκ χειρὸς Ἡρῴδου καὶ πάσης τῆς προσδοκίας τοῦ λαοῦ τῶν Ἰουδαίων.”
44012012 Συνιδών τε, ἦλθεν ἐπὶ τὴν οἰκίαν τῆς Μαρίας, τῆς μητρὸς Ἰωάννου, τοῦ ἐπικαλουμένου Μάρκου, οὗ ἦσαν ἱκανοὶ συνηθροισμένοι καὶ προσευχόμενοι.
44012013 Κρούσαντος δὲ αὐτοῦ τὴν θύραν τοῦ πυλῶνος, προσῆλθεν παιδίσκη ὑπακοῦσαι, ὀνόματι Ῥόδη.
44012014 Καὶ ἐπιγνοῦσα τὴν φωνὴν τοῦ Πέτρου, ἀπὸ τῆς χαρᾶς οὐκ ἤνοιξεν τὸν πυλῶνα, εἰσδραμοῦσα δὲ ἀπήγγειλεν ἑστάναι τὸν Πέτρον πρὸ τοῦ πυλῶνος.
44012015 Οἱ δὲ πρὸς αὐτὴν εἶπαν, “Μαίνῃ!” Ἡ δὲ διϊσχυρίζετο οὕτως ἔχειν. Οἱ δὲ ἔλεγον, “Ὁ ἄγγελός ἐστιν αὐτοῦ.”
44012016 Ὁ δὲ Πέτρος ἐπέμενεν κρούων, ἀνοίξαντες δὲ εἶδαν αὐτὸν, καὶ ἐξέστησαν.
44012017 Κατασείσας δὲ αὐτοῖς τῇ χειρὶ σιγᾶν, διηγήσατο αὐτοῖς πῶς ὁ ˚Κύριος αὐτὸν ἐξήγαγεν ἐκ τῆς φυλακῆς. Εἶπέν τε, “Ἀπαγγείλατε Ἰακώβῳ καὶ τοῖς ἀδελφοῖς ταῦτα.” Καὶ ἐξελθὼν, ἐπορεύθη εἰς ἕτερον τόπον.
44012018 Γενομένης δὲ ἡμέρας, ἦν τάραχος οὐκ ὀλίγος ἐν τοῖς στρατιώταις, τί ἄρα ὁ Πέτρος ἐγένετο.
44012019 Ἡρῴδης δὲ ἐπιζητήσας αὐτὸν καὶ μὴ εὑρὼν, ἀνακρίνας τοὺς φύλακας, ἐκέλευσεν ἀπαχθῆναι. Καὶ κατελθὼν ἀπὸ τῆς Ἰουδαίας εἰς Καισάρειαν, διέτριβεν.
44012020 Ἦν δὲ θυμομαχῶν Τυρίοις καὶ Σιδωνίοις· ὁμοθυμαδὸν δὲ παρῆσαν πρὸς αὐτόν, καὶ πείσαντες Βλάστον, τὸν ἐπὶ τοῦ κοιτῶνος τοῦ βασιλέως, ᾐτοῦντο εἰρήνην, διὰ τὸ τρέφεσθαι αὐτῶν τὴν χώραν ἀπὸ τῆς βασιλικῆς.
44012021 Τακτῇ δὲ ἡμέρᾳ, ὁ Ἡρῴδης, ἐνδυσάμενος ἐσθῆτα βασιλικὴν, καθίσας ἐπὶ τοῦ βήματος, ἐδημηγόρει πρὸς αὐτούς.
44012022 Ὁ δὲ δῆμος ἐπεφώνει, “˚Θεοῦ φωνὴ, καὶ οὐκ ἀνθρώπου!”
44012023 Παραχρῆμα δὲ ἐπάταξεν αὐτὸν ἄγγελος ˚Κυρίου, ἀνθʼ ὧν οὐκ ἔδωκεν τὴν δόξαν τῷ ˚Θεῷ, καὶ γενόμενος σκωληκόβρωτος, ἐξέψυξεν.
44012024 Ὁ δὲ λόγος τοῦ ˚Θεοῦ ηὔξανεν καὶ ἐπληθύνετο.
44012025 Βαρναβᾶς δὲ καὶ Σαῦλος ὑπέστρεψαν εἰς Ἰερουσαλὴμ, πληρώσαντες τὴν διακονίαν, συμπαραλαβόντες Ἰωάννην, τὸν ἐπικληθέντα Μᾶρκον.
44013001 Ἦσαν δὲ ἐν Ἀντιοχείᾳ, κατὰ τὴν οὖσαν ἐκκλησίαν προφῆται καὶ διδάσκαλοι: ὅ τε Βαρναβᾶς, καὶ Συμεὼν ὁ καλούμενος Νίγερ, καὶ Λούκιος ὁ Κυρηναῖος, Μαναήν τε Ἡρῴδου τοῦ τετραάρχου σύντροφος, καὶ Σαῦλος.
44013002 Λειτουργούντων δὲ αὐτῶν τῷ ˚Κυρίῳ καὶ νηστευόντων, εἶπεν τὸ ˚Πνεῦμα τὸ Ἅγιον, “Ἀφορίσατε δή μοι τὸν Βαρναβᾶν καὶ Σαῦλον, εἰς τὸ ἔργον ὃ προσκέκλημαι αὐτούς.”
44013003 Τότε νηστεύσαντες, καὶ προσευξάμενοι, καὶ ἐπιθέντες τὰς χεῖρας αὐτοῖς, ἀπέλυσαν.
44013004 Αὐτοὶ μὲν οὖν ἐκπεμφθέντες ὑπὸ τοῦ Ἁγίου ˚Πνεύματος, κατῆλθον εἰς Σελεύκειαν, ἐκεῖθέν τε ἀπέπλευσαν εἰς Κύπρον.
44013005 Καὶ γενόμενοι ἐν Σαλαμῖνι, κατήγγελλον τὸν λόγον τοῦ ˚Θεοῦ ἐν ταῖς συναγωγαῖς τῶν Ἰουδαίων. Εἶχον δὲ καὶ Ἰωάννην, ὑπηρέτην.
44013006 Διελθόντες δὲ ὅλην τὴν νῆσον ἄχρι Πάφου, εὗρον ἄνδρα, τινὰ μάγον ψευδοπροφήτην Ἰουδαῖον, ᾧ ὄνομα Βαριησοῦ,
44013007 ὃς ἦν σὺν τῷ ἀνθυπάτῳ Σεργίῳ Παύλῳ, ἀνδρὶ συνετῷ. Οὗτος, προσκαλεσάμενος Βαρναβᾶν καὶ Σαῦλον, ἐπεζήτησεν ἀκοῦσαι τὸν λόγον τοῦ ˚Θεοῦ.
44013008 Ἀνθίστατο δὲ αὐτοῖς Ἐλύμας ὁ μάγος ( οὕτως γὰρ μεθερμηνεύεται τὸ ὄνομα αὐτοῦ ) ζητῶν διαστρέψαι τὸν ἀνθύπατον ἀπὸ τῆς πίστεως.
44013009 Σαῦλος δέ, ὁ καὶ Παῦλος, πλησθεὶς ˚Πνεύματος Ἁγίου, ἀτενίσας εἰς αὐτὸν
44013010 εἶπεν, “Ὦ πλήρης παντὸς δόλου καὶ πάσης ῥᾳδιουργίας, υἱὲ διαβόλου, ἐχθρὲ πάσης δικαιοσύνης, οὐ παύσῃ διαστρέφων τὰς ὁδοὺς τοῦ ˚Κυρίου τὰς εὐθείας;
44013011 Καὶ νῦν ἰδοὺ, χεὶρ ˚Κυρίου ἐπὶ σέ, καὶ ἔσῃ τυφλὸς, μὴ βλέπων τὸν ἥλιον ἄχρι καιροῦ.” Παραχρῆμά τε ἔπεσεν ἐπʼ αὐτὸν ἀχλὺς καὶ σκότος, καὶ περιάγων ἐζήτει χειραγωγούς.
44013012 Τότε ἰδὼν ὁ ἀνθύπατος τὸ γεγονὸς, ἐπίστευσεν ἐκπλησσόμενος ἐπὶ τῇ διδαχῇ τοῦ ˚Κυρίου.
44013013 Ἀναχθέντες δὲ ἀπὸ τῆς Πάφου οἱ περὶ, Παῦλον ἦλθον εἰς Πέργην τῆς Παμφυλίας. Ἰωάννης δὲ ἀποχωρήσας ἀπʼ αὐτῶν, ὑπέστρεψεν εἰς Ἱεροσόλυμα.
44013014 Αὐτοὶ δὲ, διελθόντες ἀπὸ τῆς Πέργης, παρεγένοντο εἰς Ἀντιόχειαν τὴν Πισιδίαν, καὶ ἐλθόντες εἰς τὴν συναγωγὴν τῇ ἡμέρᾳ τῶν Σαββάτων, ἐκάθισαν.
44013015 Μετὰ δὲ τὴν ἀνάγνωσιν τοῦ νόμου καὶ τῶν προφητῶν, ἀπέστειλαν οἱ ἀρχισυνάγωγοι πρὸς αὐτοὺς λέγοντες, “Ἄνδρες, ἀδελφοί, εἴ τίς ἐστιν ἐν ὑμῖν λόγος παρακλήσεως πρὸς τὸν λαόν, λέγετε.”
44013016 Ἀναστὰς δὲ Παῦλος, καὶ κατασείσας τῇ χειρὶ εἶπεν, “Ἄνδρες, Ἰσραηλῖται, καὶ οἱ φοβούμενοι τὸν ˚Θεόν, ἀκούσατε.
44013017 Ὁ ˚Θεὸς τοῦ λαοῦ τούτου Ἰσραὴλ ἐξελέξατο τοὺς πατέρας ἡμῶν, καὶ τὸν λαὸν ὕψωσεν ἐν τῇ παροικίᾳ ἐν γῇ Αἰγύπτου, καὶ μετὰ βραχίονος ὑψηλοῦ, ἐξήγαγεν αὐτοὺς ἐξ αὐτῆς,
44013018 καί ὡς τεσσερακονταετῆ χρόνον ἐτροποφόρησεν αὐτοὺς ἐν τῇ ἐρήμῳ,
44013019 καὶ καθελὼν ἔθνη ἑπτὰ ἐν γῇ Χανάαν, κατεκληρονόμησεν τὴν γῆν αὐτῶν–
44013020 ὡς ἔτεσι τετρακοσίοις καὶ πεντήκοντα. Καὶ μετὰ ταῦτα, ἔδωκεν κριτὰς ἕως Σαμουὴλ προφήτου.
44013021 Κἀκεῖθεν ᾐτήσαντο βασιλέα, καὶ ἔδωκεν αὐτοῖς ὁ ˚Θεὸς τὸν Σαοὺλ, υἱὸν Κίς, ἄνδρα ἐκ φυλῆς Βενιαμίν, ἔτη τεσσεράκοντα.
44013022 Καὶ μεταστήσας αὐτὸν, ἤγειρεν τὸν Δαυὶδ αὐτοῖς εἰς βασιλέα, ᾧ καὶ εἶπεν μαρτυρήσας, ‘“Εὗρον Δαυὶδ” τὸν τοῦ Ἰεσσαί, “ἄνδρα κατὰ τὴν καρδίαν μου”, ὃς ποιήσει πάντα τὰ θελήματά μου.’
44013023 Τούτου ὁ ˚Θεὸς ἀπὸ τοῦ σπέρματος κατʼ ἐπαγγελίαν, ἤγαγεν τῷ Ἰσραὴλ Σωτῆρα, ˚Ἰησοῦν,
44013024 προκηρύξαντος Ἰωάννου πρὸ προσώπου τῆς εἰσόδου αὐτοῦ, βάπτισμα μετανοίας παντὶ τῷ λαῷ Ἰσραήλ.
44013025 Ὡς δὲ ἐπλήρου Ἰωάννης τὸν δρόμον, ἔλεγεν, ‘Τί ἐμὲ ὑπονοεῖτε εἶναι; Οὐκ εἰμὶ ἐγώ, ἀλλʼ ἰδοὺ, ἔρχεται μετʼ ἐμὲ, οὗ οὐκ εἰμὶ ἄξιος τὸ ὑπόδημα τῶν ποδῶν λῦσαι.’
44013026 Ἄνδρες, ἀδελφοί, υἱοὶ γένους Ἀβραὰμ, καὶ οἱ ἐν ὑμῖν φοβούμενοι τὸν ˚Θεόν, ἡμῖν ὁ λόγος τῆς σωτηρίας ταύτης ἐξαπεστάλη.
44013027 Οἱ γὰρ κατοικοῦντες ἐν Ἰερουσαλὴμ καὶ οἱ ἄρχοντες αὐτῶν, τοῦτον ἀγνοήσαντες καὶ τὰς φωνὰς τῶν προφητῶν, τὰς κατὰ πᾶν Σάββατον ἀναγινωσκομένας, κρίναντες ἐπλήρωσαν.
44013028 Καὶ μηδεμίαν αἰτίαν θανάτου εὑρόντες, ᾐτήσαντο Πιλᾶτον ἀναιρεθῆναι αὐτόν.
44013029 Ὡς δὲ ἐτέλεσαν πάντα τὰ περὶ αὐτοῦ γεγραμμένα, καθελόντες ἀπὸ τοῦ ξύλου, ἔθηκαν εἰς μνημεῖον.
44013030 Ὁ δὲ ˚Θεὸς ἤγειρεν αὐτὸν ἐκ νεκρῶν·
44013031 ὃς ὤφθη ἐπὶ ἡμέρας πλείους τοῖς συναναβᾶσιν αὐτῷ ἀπὸ τῆς Γαλιλαίας εἰς Ἰερουσαλήμ, οἵτινες νῦν  μάρτυρες αὐτοῦ πρὸς τὸν λαόν.
44013032 Καὶ ἡμεῖς ὑμᾶς εὐαγγελιζόμεθα τὴν πρὸς τοὺς πατέρας ἐπαγγελίαν γενομένην,
44013033 ὅτι ταύτην ὁ ˚Θεὸς ἐκπεπλήρωκεν τοῖς τέκνοις ἡμῶν, ἀναστήσας ˚Ἰησοῦν, ὡς καὶ ἐν τῷ ψαλμῷ γέγραπται τῷ δευτέρῳ, ‘Υἱός μου εἶ σύ, ἐγὼ σήμερον γεγέννηκά σε.’
44013034 Ὅτι δὲ ἀνέστησεν αὐτὸν ἐκ νεκρῶν, μηκέτι μέλλοντα ὑποστρέφειν εἰς διαφθοράν, οὕτως εἴρηκεν, ὅτι ‘Δώσω ὑμῖν τὰ ὅσια Δαυὶδ τὰ πιστά.’
44013035 Διότι καὶ ἐν ἑτέρῳ λέγει, ‘Οὐ δώσεις τὸν Ὅσιόν σου ἰδεῖν διαφθοράν.’
44013036 Δαυὶδ μὲν γὰρ ἰδίᾳ γενεᾷ ὑπηρετήσας τῇ τοῦ ˚Θεοῦ βουλῇ, ἐκοιμήθη καὶ προσετέθη πρὸς τοὺς πατέρας αὐτοῦ, καὶ εἶδεν διαφθοράν.
44013037 Ὃν δὲ ὁ ˚Θεὸς ἤγειρεν οὐκ εἶδεν διαφθοράν.
44013038 Γνωστὸν οὖν ἔστω ὑμῖν, ἄνδρες, ἀδελφοί, ὅτι διὰ τούτου, ὑμῖν ἄφεσις ἁμαρτιῶν καταγγέλλεται, καὶ ἀπὸ πάντων ὧν οὐκ ἠδυνήθητε ἐν νόμῳ Μωϋσέως δικαιωθῆναι,
44013039 ἐν τούτῳ πᾶς ὁ πιστεύων δικαιοῦται.
44013040 Βλέπετε οὖν, μὴ ἐπέλθῃ τὸ εἰρημένον ἐν τοῖς προφήταις:
44013041 ‘Ἴδετε, οἱ καταφρονηταί, καὶ θαυμάσατε καὶ ἀφανίσθητε, ὅτι ἔργον ἐργάζομαι ἐγὼ ἐν ταῖς ἡμέραις ὑμῶν, ἔργον ὃ οὐ μὴ πιστεύσητε, ἐάν τις ἐκδιηγῆται ὑμῖν.’”
44013042 Ἐξιόντων δὲ, αὐτῶν παρεκάλουν εἰς τὸ μεταξὺ Σάββατον λαληθῆναι αὐτοῖς τὰ ῥήματα ταῦτα.
44013043 Λυθείσης δὲ τῆς συναγωγῆς, ἠκολούθησαν πολλοὶ τῶν Ἰουδαίων καὶ τῶν σεβομένων προσηλύτων τῷ Παύλῳ καὶ τῷ Βαρναβᾷ, οἵτινες προσλαλοῦντες αὐτοῖς, ἔπειθον αὐτοὺς προσμένειν τῇ χάριτι τοῦ ˚Θεοῦ.
44013044 Τῷ δὲ ἐρχομένῳ Σαββάτῳ, σχεδὸν πᾶσα ἡ πόλις συνήχθη ἀκοῦσαι τὸν λόγον τοῦ ˚Θεοῦ.
44013045 Ἰδόντες δὲ οἱ Ἰουδαῖοι τοὺς ὄχλους, ἐπλήσθησαν ζήλου καὶ ἀντέλεγον τοῖς ὑπὸ Παύλου λαλουμένοις βλασφημοῦντες.
44013046 Παρρησιασάμενοί τε, ὁ Παῦλος καὶ ὁ Βαρναβᾶς εἶπαν, “Ὑμῖν ἦν ἀναγκαῖον πρῶτον λαληθῆναι τὸν λόγον τοῦ ˚Θεοῦ. Ἐπειδὴ δὲ ἀπωθεῖσθε αὐτὸν, καὶ οὐκ ἀξίους κρίνετε ἑαυτοὺς τῆς αἰωνίου ζωῆς, ἰδοὺ, στρεφόμεθα εἰς τὰ ἔθνη.
44013047 Οὕτως γὰρ ἐντέταλται ἡμῖν ὁ ˚Κύριος, ‘Τέθεικά σε εἰς φῶς ἐθνῶν, τοῦ εἶναί σε εἰς σωτηρίαν ἕως ἐσχάτου τῆς γῆς.’”
44013048 Ἀκούοντα δὲ τὰ ἔθνη, ἔχαιρον καὶ ἐδόξαζον τὸν λόγον τοῦ ˚Κυρίου, καὶ ἐπίστευσαν ὅσοι ἦσαν τεταγμένοι εἰς ζωὴν αἰώνιον.
44013049 Διεφέρετο δὲ ὁ λόγος τοῦ ˚Κυρίου διʼ ὅλης τῆς χώρας.
44013050 Οἱ δὲ Ἰουδαῖοι παρώτρυναν τὰς σεβομένας γυναῖκας τὰς εὐσχήμονας, καὶ τοὺς πρώτους τῆς πόλεως, καὶ ἐπήγειραν διωγμὸν ἐπὶ τὸν Παῦλον καὶ Βαρναβᾶν, καὶ ἐξέβαλον αὐτοὺς ἀπὸ τῶν ὁρίων αὐτῶν.
44013051 Οἱ δὲ, ἐκτιναξάμενοι τὸν κονιορτὸν τῶν ποδῶν ἐπʼ αὐτοὺς, ἦλθον εἰς Ἰκόνιον.
44013052 Οἵ τε μαθηταὶ ἐπληροῦντο χαρᾶς καὶ ˚Πνεύματος Ἁγίου.
44014001 Ἐγένετο δὲ, ἐν Ἰκονίῳ κατὰ τὸ αὐτὸ εἰσελθεῖν αὐτοὺς εἰς τὴν συναγωγὴν τῶν Ἰουδαίων, καὶ λαλῆσαι οὕτως ὥστε πιστεῦσαι Ἰουδαίων τε καὶ Ἑλλήνων πολὺ πλῆθος.
44014002 Οἱ δὲ ἀπειθήσαντες Ἰουδαῖοι ἐπήγειραν καὶ ἐκάκωσαν τὰς ψυχὰς τῶν ἐθνῶν κατὰ τῶν ἀδελφῶν.
44014003 Ἱκανὸν μὲν οὖν χρόνον διέτριψαν, παρρησιαζόμενοι ἐπὶ τῷ ˚Κυρίῳ, τῷ μαρτυροῦντι τῷ λόγῳ τῆς χάριτος αὐτοῦ, διδόντι σημεῖα καὶ τέρατα γίνεσθαι διὰ τῶν χειρῶν αὐτῶν.
44014004 Ἐσχίσθη δὲ τὸ πλῆθος τῆς πόλεως, καὶ οἱ μὲν ἦσαν σὺν τοῖς Ἰουδαίοις, οἱ δὲ σὺν τοῖς ἀποστόλοις.
44014005 Ὡς δὲ ἐγένετο ὁρμὴ τῶν ἐθνῶν τε καὶ Ἰουδαίων σὺν τοῖς ἄρχουσιν αὐτῶν, ὑβρίσαι καὶ λιθοβολῆσαι αὐτούς,
44014006 συνιδόντες, κατέφυγον εἰς τὰς πόλεις τῆς Λυκαονίας, Λύστραν, καὶ Δέρβην, καὶ τὴν περίχωρον,
44014007 κἀκεῖ εὐαγγελιζόμενοι ἦσαν.
44014008 Καί τις ἀνὴρ, ἀδύνατος ἐν Λύστροις τοῖς ποσὶν, ἐκάθητο χωλὸς ἐκ κοιλίας μητρὸς αὐτοῦ, ὃς οὐδέποτε περιεπάτησεν.
44014009 Οὗτος ἤκουεν τοῦ Παύλου λαλοῦντος, ὃς ἀτενίσας αὐτῷ καὶ ἰδὼν ὅτι ἔχει πίστιν τοῦ σωθῆναι,
44014010 εἶπεν μεγάλῃ φωνῇ, “Ἀνάστηθι ἐπὶ τοὺς πόδας σου ὀρθός!” Καὶ ἥλατο καὶ περιεπάτει.
44014011 Οἵ τε ὄχλοι ἰδόντες ὃ ἐποίησεν Παῦλος, ἐπῆραν τὴν φωνὴν αὐτῶν Λυκαονιστὶ λέγοντες, “Οἱ θεοὶ ὁμοιωθέντες ἀνθρώποις, κατέβησαν πρὸς ἡμᾶς.”
44014012 Ἐκάλουν τε τὸν Βαρναβᾶν, “Δία”, τὸν δὲ Παῦλον, “Ἑρμῆν”, ἐπειδὴ αὐτὸς ἦν ὁ ἡγούμενος τοῦ λόγου.
44014013 Ὅ τε ἱερεὺς τοῦ Διὸς, τοῦ ὄντος πρὸ τῆς πόλεως, ταύρους καὶ στέμματα ἐπὶ τοὺς πυλῶνας ἐνέγκας, σὺν τοῖς ὄχλοις ἤθελεν θύειν.
44014014 Ἀκούσαντες δὲ οἱ ἀπόστολοι Βαρναβᾶς καὶ Παῦλος, διαρρήξαντες τὰ ἱμάτια ἑαυτῶν, ἐξεπήδησαν εἰς τὸν ὄχλον, κράζοντες
44014015 καὶ λέγοντες, “Ἄνδρες, τί ταῦτα ποιεῖτε; Καὶ ἡμεῖς ὁμοιοπαθεῖς ἐσμεν ὑμῖν ἄνθρωποι, εὐαγγελιζόμενοι ὑμᾶς ἀπὸ τούτων τῶν ματαίων ἐπιστρέφειν ἐπὶ ˚Θεὸν ζῶντα, ‘ὃς ἐποίησεν τὸν οὐρανὸν, καὶ τὴν γῆν, καὶ τὴν θάλασσαν, καὶ πάντα τὰ ἐν αὐτοῖς’.
44014016 Ὃς ἐν ταῖς παρῳχημέναις γενεαῖς, εἴασεν πάντα τὰ ἔθνη πορεύεσθαι ταῖς ὁδοῖς αὐτῶν.
44014017 Καίτοι οὐκ ἀμάρτυρον αὑτὸν ἀφῆκεν, ἀγαθουργῶν οὐρανόθεν ὑμῖν ὑετοὺς διδοὺς καὶ καιροὺς καρποφόρους, ἐμπιπλῶν τροφῆς καὶ εὐφροσύνης τὰς καρδίας ὑμῶν.”
44014018 Καὶ ταῦτα λέγοντες, μόλις κατέπαυσαν τοὺς ὄχλους τοῦ μὴ θύειν αὐτοῖς.
44014019 Ἐπῆλθαν δὲ ἀπὸ Ἀντιοχείας καὶ Ἰκονίου Ἰουδαῖοι, καὶ πείσαντες τοὺς ὄχλους, καὶ λιθάσαντες τὸν Παῦλον, ἔσυρον ἔξω τῆς πόλεως, νομίζοντες αὐτὸν τεθνηκέναι.
44014020 Κυκλωσάντων δὲ τῶν μαθητῶν αὐτὸν, ἀναστὰς εἰσῆλθεν εἰς τὴν πόλιν. Καὶ τῇ ἐπαύριον ἐξῆλθεν σὺν τῷ Βαρναβᾷ εἰς Δέρβην.
44014021 Εὐαγγελισάμενοί τε τὴν πόλιν ἐκείνην, καὶ μαθητεύσαντες ἱκανοὺς, ὑπέστρεψαν εἰς τὴν Λύστραν, καὶ εἰς Ἰκόνιον, καὶ εἰς Ἀντιόχειαν,
44014022 ἐπιστηρίζοντες τὰς ψυχὰς τῶν μαθητῶν, παρακαλοῦντες ἐμμένειν τῇ πίστει, καὶ ὅτι διὰ πολλῶν θλίψεων, δεῖ ἡμᾶς εἰσελθεῖν εἰς τὴν Βασιλείαν τοῦ ˚Θεοῦ.
44014023 Χειροτονήσαντες δὲ αὐτοῖς κατʼ ἐκκλησίαν πρεσβυτέρους, προσευξάμενοι μετὰ νηστειῶν, παρέθεντο αὐτοὺς τῷ ˚Κυρίῳ εἰς ὃν πεπιστεύκεισαν.
44014024 Καὶ διελθόντες τὴν Πισιδίαν, ἦλθον εἰς τὴν Παμφυλίαν,
44014025 καὶ λαλήσαντες ἐν Πέργῃ τὸν λόγον, κατέβησαν εἰς Ἀττάλειαν,
44014026 κἀκεῖθεν ἀπέπλευσαν εἰς Ἀντιόχειαν, ὅθεν ἦσαν παραδεδομένοι τῇ χάριτι τοῦ ˚Θεοῦ, εἰς τὸ ἔργον ὃ ἐπλήρωσαν.
44014027 Παραγενόμενοι δὲ καὶ συναγαγόντες τὴν ἐκκλησίαν, ἀνήγγελλον ὅσα ἐποίησεν ὁ ˚Θεὸς μετʼ αὐτῶν, καὶ ὅτι ἤνοιξεν τοῖς ἔθνεσιν θύραν πίστεως.
44014028 Διέτριβον δὲ χρόνον οὐκ ὀλίγον σὺν τοῖς μαθηταῖς.
44015001 Καί τινες κατελθόντες ἀπὸ τῆς Ἰουδαίας, ἐδίδασκον τοὺς ἀδελφοὺς, ὅτι “Ἐὰν μὴ περιτμηθῆτε τῷ ἔθει τῷ Μωϋσέως, οὐ δύνασθε σωθῆναι.”
44015002 Γενομένης δὲ στάσεως καὶ ζητήσεως οὐκ ὀλίγης τῷ Παύλῳ καὶ τῷ Βαρναβᾷ πρὸς αὐτοὺς, ἔταξαν ἀναβαίνειν Παῦλον καὶ Βαρναβᾶν, καί τινας ἄλλους ἐξ αὐτῶν, πρὸς τοὺς ἀποστόλους καὶ πρεσβυτέρους εἰς Ἰερουσαλὴμ περὶ τοῦ ζητήματος τούτου.
44015003 Οἱ μὲν οὖν προπεμφθέντες ὑπὸ τῆς ἐκκλησίας, διήρχοντο τήν τε Φοινίκην καὶ Σαμάρειαν, ἐκδιηγούμενοι τὴν ἐπιστροφὴν τῶν ἐθνῶν, καὶ ἐποίουν χαρὰν μεγάλην πᾶσιν τοῖς ἀδελφοῖς.
44015004 Παραγενόμενοι δὲ εἰς Ἱεροσόλυμα, παρεδέχθησαν ἀπὸ τῆς ἐκκλησίας, καὶ τῶν ἀποστόλων, καὶ τῶν πρεσβυτέρων, ἀνήγγειλάν τε ὅσα ὁ ˚Θεὸς ἐποίησεν μετʼ αὐτῶν.
44015005 Ἐξανέστησαν δέ τινες τῶν ἀπὸ τῆς αἱρέσεως τῶν Φαρισαίων πεπιστευκότες, λέγοντες, ὅτι “Δεῖ περιτέμνειν αὐτοὺς, παραγγέλλειν τε τηρεῖν τὸν νόμον Μωϋσέως.”
44015006 Συνήχθησάν δὲ οἱ ἀπόστολοι καὶ οἱ πρεσβύτεροι, ἰδεῖν περὶ τοῦ λόγου τούτου.
44015007 Πολλῆς δὲ ζητήσεως γενομένης, ἀναστὰς Πέτρος εἶπεν πρὸς αὐτούς, “Ἄνδρες, ἀδελφοί, ὑμεῖς ἐπίστασθε ὅτι ἀφʼ ἡμερῶν ἀρχαίων ἐν ὑμῖν, ἐξελέξατο ὁ ˚Θεὸς διὰ τοῦ στόματός μου, ἀκοῦσαι τὰ ἔθνη τὸν λόγον τοῦ εὐαγγελίου καὶ πιστεῦσαι.
44015008 Καὶ ὁ καρδιογνώστης ˚Θεὸς ἐμαρτύρησεν αὐτοῖς, δοὺς τὸ ˚Πνεῦμα τὸ Ἅγιον καθὼς καὶ ἡμῖν,
44015009 καὶ οὐδὲν διέκρινεν μεταξὺ ἡμῶν τε καὶ αὐτῶν, τῇ πίστει καθαρίσας τὰς καρδίας αὐτῶν.
44015010 Νῦν οὖν τί πειράζετε τὸν ˚Θεόν, ἐπιθεῖναι ζυγὸν ἐπὶ τὸν τράχηλον τῶν μαθητῶν, ὃν οὔτε οἱ πατέρες ἡμῶν οὔτε ἡμεῖς ἰσχύσαμεν βαστάσαι;
44015011 Ἀλλὰ διὰ τῆς χάριτος τοῦ ˚Κυρίου ˚Ἰησοῦ, πιστεύομεν σωθῆναι καθʼ ὃν τρόπον κἀκεῖνοι.”
44015012 Ἐσίγησεν δὲ πᾶν τὸ πλῆθος, καὶ ἤκουον Βαρναβᾶ καὶ Παύλου, ἐξηγουμένων ὅσα ἐποίησεν ὁ ˚Θεὸς, σημεῖα καὶ τέρατα ἐν τοῖς ἔθνεσιν διʼ αὐτῶν.
44015013 Μετὰ δὲ τὸ σιγῆσαι αὐτοὺς, ἀπεκρίθη Ἰάκωβος λέγων, “Ἄνδρες, ἀδελφοί, ἀκούσατέ μου.
44015014 Συμεὼν ἐξηγήσατο καθὼς πρῶτον ὁ ˚Θεὸς ἐπεσκέψατο, λαβεῖν ἐξ ἐθνῶν λαὸν τῷ ὀνόματι αὐτοῦ.
44015015 Καὶ τούτῳ συμφωνοῦσιν οἱ λόγοι τῶν προφητῶν καθὼς γέγραπται,
44015016 ‘“Μετὰ ταῦτα ἀναστρέψω καὶ ἀνοικοδομήσω τὴν σκηνὴν Δαυὶδ τὴν πεπτωκυῖαν, καὶ τὰ κατεσκαμμένα αὐτῆς, ἀνοικοδομήσω καὶ ἀνορθώσω αὐτήν,
44015017 ὅπως ἂν ἐκζητήσωσιν οἱ κατάλοιποι τῶν ἀνθρώπων τὸν ˚Κύριον, καὶ πάντα τὰ ἔθνη, ἐφʼ οὓς ἐπικέκληται τὸ ὄνομά μου ἐπʼ αὐτούς”, λέγει ˚Κύριος ποιῶν ταῦτα’,
44015018 γνωστὰ ἀπʼ αἰῶνος.
44015019 Διὸ ἐγὼ κρίνω μὴ παρενοχλεῖν τοῖς ἀπὸ τῶν ἐθνῶν, ἐπιστρέφουσιν ἐπὶ τὸν ˚Θεόν,
44015020 ἀλλὰ ἐπιστεῖλαι αὐτοῖς τοῦ ἀπέχεσθαι τῶν ἀλισγημάτων τῶν εἰδώλων, καὶ τῆς πορνείας, καὶ τοῦ πνικτοῦ, καὶ τοῦ αἵματος.
44015021 Μωϋσῆς γὰρ ἐκ γενεῶν ἀρχαίων κατὰ πόλιν τοὺς κηρύσσοντας αὐτὸν, ἔχει ἐν ταῖς συναγωγαῖς κατὰ πᾶν Σάββατον ἀναγινωσκόμενος.”
44015022 Τότε ἔδοξε τοῖς ἀποστόλοις καὶ τοῖς πρεσβυτέροις σὺν ὅλῃ τῇ ἐκκλησίᾳ, ἐκλεξαμένους ἄνδρας ἐξ αὐτῶν, πέμψαι εἰς Ἀντιόχειαν σὺν τῷ Παύλῳ καὶ Βαρναβᾷ– Ἰούδαν τὸν καλούμενον Βαρσαββᾶν καὶ Σιλᾶν, ἄνδρας ἡγουμένους ἐν τοῖς ἀδελφοῖς,
44015023 γράψαντες διὰ χειρὸς αὐτῶν, “Οἱ ἀπόστολοι καὶ οἱ πρεσβύτεροι, ἀδελφοὶ, τοῖς κατὰ τὴν Ἀντιόχειαν, καὶ Συρίαν, καὶ Κιλικίαν, ἀδελφοῖς τοῖς ἐξ ἐθνῶν, χαίρειν.
44015024 Ἐπειδὴ ἠκούσαμεν ὅτι τινὲς ἐξ ἡμῶν ἐξελθόντες, ἐτάραξαν ὑμᾶς λόγοις ἀνασκευάζοντες τὰς ψυχὰς ὑμῶν, οἷς οὐ διεστειλάμεθα,
44015025 ἔδοξεν ἡμῖν γενομένοις ὁμοθυμαδὸν, ἐκλεξαμένοις ἄνδρας πέμψαι πρὸς ὑμᾶς, σὺν τοῖς ἀγαπητοῖς ἡμῶν Βαρναβᾷ καὶ Παύλῳ,
44015026 ἀνθρώποις παραδεδωκόσιν τὰς ψυχὰς αὐτῶν ὑπὲρ τοῦ ὀνόματος τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ ˚Χριστοῦ.
44015027 Ἀπεστάλκαμεν οὖν Ἰούδαν καὶ Σιλᾶν, καὶ αὐτοὺς διὰ λόγου ἀπαγγέλλοντας τὰ αὐτά.
44015028 Ἔδοξεν γὰρ τῷ ˚Πνεύματι τῷ Ἁγίῳ καὶ ἡμῖν, μηδὲν πλέον ἐπιτίθεσθαι ὑμῖν βάρος, πλὴν τούτων τῶν ἐπάναγκες:
44015029 ἀπέχεσθαι εἰδωλοθύτων, καὶ αἵματος, καὶ πνικτῶν, καὶ πορνείας· ἐξ ὧν διατηροῦντες ἑαυτοὺς, εὖ πράξετε. Ἔρρωσθε!”
44015030 Οἱ μὲν οὖν ἀπολυθέντες, κατῆλθον εἰς Ἀντιόχειαν, καὶ συναγαγόντες τὸ πλῆθος, ἐπέδωκαν τὴν ἐπιστολήν.
44015031 Ἀναγνόντες δὲ, ἐχάρησαν ἐπὶ τῇ παρακλήσει.
44015032 Ἰούδας τε καὶ Σιλᾶς, καὶ αὐτοὶ προφῆται ὄντες, διὰ λόγου πολλοῦ, παρεκάλεσαν τοὺς ἀδελφοὺς καὶ ἐπεστήριξαν.
44015033 Ποιήσαντες δὲ χρόνον, ἀπελύθησαν μετʼ εἰρήνης ἀπὸ τῶν ἀδελφῶν, πρὸς τοὺς ἀποστείλαντας αὐτούς.
44015035 Παῦλος δὲ καὶ Βαρναβᾶς διέτριβον ἐν Ἀντιοχείᾳ, διδάσκοντες καὶ εὐαγγελιζόμενοι μετὰ καὶ ἑτέρων πολλῶν, τὸν λόγον τοῦ ˚Κυρίου.
44015036 Μετὰ δέ τινας ἡμέρας, εἶπεν πρὸς Βαρναβᾶν Παῦλος, “Ἐπιστρέψαντες δὴ ἐπισκεψώμεθα τοὺς ἀδελφοὺς κατὰ πόλιν πᾶσαν, ἐν αἷς κατηγγείλαμεν τὸν λόγον τοῦ ˚Κυρίου, πῶς ἔχουσιν.”
44015037 Βαρναβᾶς δὲ ἐβούλετο συμπαραλαβεῖν καὶ τὸν Ἰωάννην, τὸν καλούμενον Μᾶρκον.
44015038 Παῦλος δὲ ἠξίου τὸν ἀποστάντα ἀπʼ αὐτῶν ἀπὸ Παμφυλίας καὶ μὴ συνελθόντα αὐτοῖς εἰς τὸ ἔργον, μὴ συμπαραλαμβάνειν τοῦτον.
44015039 Ἐγένετο δὲ παροξυσμὸς, ὥστε ἀποχωρισθῆναι αὐτοὺς ἀπʼ ἀλλήλων, τόν τε Βαρναβᾶν παραλαβόντα τὸν Μᾶρκον, ἐκπλεῦσαι εἰς Κύπρον.
44015040 Παῦλος δὲ ἐπιλεξάμενος Σιλᾶν, ἐξῆλθεν παραδοθεὶς τῇ χάριτι τοῦ ˚Κυρίου ὑπὸ τῶν ἀδελφῶν.
44015041 Διήρχετο δὲ τὴν Συρίαν καὶ Κιλικίαν, ἐπιστηρίζων τὰς ἐκκλησίας.
44016001 Κατήντησεν δὲ καὶ εἰς Δέρβην καὶ εἰς Λύστραν. Καὶ ἰδοὺ, μαθητής τις ἦν ἐκεῖ ὀνόματι Τιμόθεος, υἱὸς γυναικὸς Ἰουδαίας πιστῆς, πατρὸς δὲ Ἕλληνος,
44016002 ὃς ἐμαρτυρεῖτο ὑπὸ τῶν ἐν Λύστροις καὶ Ἰκονίῳ ἀδελφῶν.
44016003 Τοῦτον ἠθέλησεν ὁ Παῦλος σὺν αὐτῷ ἐξελθεῖν, καὶ λαβὼν περιέτεμεν αὐτὸν, διὰ τοὺς Ἰουδαίους τοὺς ὄντας ἐν τοῖς τόποις ἐκείνοις, ᾔδεισαν γὰρ ἅπαντες ὅτι Ἕλλην ὁ πατὴρ αὐτοῦ ὑπῆρχεν.
44016004 Ὡς δὲ διεπορεύοντο τὰς πόλεις, παρεδίδοσαν αὐτοῖς φυλάσσειν τὰ δόγματα τὰ κεκριμένα ὑπὸ τῶν ἀποστόλων καὶ πρεσβυτέρων τῶν ἐν Ἱεροσολύμοις.
44016005 Αἱ μὲν οὖν ἐκκλησίαι ἐστερεοῦντο τῇ πίστει, καὶ ἐπερίσσευον τῷ ἀριθμῷ καθʼ ἡμέραν.
44016006 Διῆλθον δὲ τὴν Φρυγίαν καὶ Γαλατικὴν χώραν, κωλυθέντες ὑπὸ τοῦ Ἁγίου ˚Πνεύματος λαλῆσαι τὸν λόγον ἐν τῇ Ἀσίᾳ,
44016007 ἐλθόντες δὲ κατὰ τὴν Μυσίαν, ἐπείραζον εἰς τὴν Βιθυνίαν πορευθῆναι, καὶ οὐκ εἴασεν αὐτοὺς τὸ ˚Πνεῦμα ˚Ἰησοῦ·
44016008 παρελθόντες δὲ τὴν Μυσίαν, κατέβησαν εἰς Τρῳάδα.
44016009 Καὶ ὅραμα διὰ νυκτὸς τῷ Παύλῳ ὤφθη, ἀνὴρ Μακεδών τις ἦν ἑστὼς καὶ παρακαλῶν αὐτὸν καὶ λέγων, “Διαβὰς εἰς Μακεδονίαν, βοήθησον ἡμῖν.”
44016010 Ὡς δὲ τὸ ὅραμα εἶδεν, εὐθέως ἐζητήσαμεν ἐξελθεῖν εἰς Μακεδονίαν, συμβιβάζοντες ὅτι προσκέκληται ἡμᾶς ὁ ˚Θεὸς εὐαγγελίσασθαι αὐτούς.
44016011 Ἀναχθέντες οὖν ἀπὸ Τρῳάδος, εὐθυδρομήσαμεν εἰς Σαμοθρᾴκην, τῇ δὲ ἐπιούσῃ εἰς Νέαν Πόλιν,
44016012 κἀκεῖθεν εἰς Φιλίππους, ἥτις ἐστὶν πρώτη τῆς μερίδος Μακεδονίας πόλις, κολωνία. Ἦμεν δὲ ἐν ταύτῃ τῇ πόλει διατρίβοντες ἡμέρας τινάς.
44016013 Τῇ τε ἡμέρᾳ τῶν Σαββάτων, ἐξήλθομεν ἔξω τῆς πύλης παρὰ ποταμὸν, οὗ ἐνομίζομεν προσευχὴν εἶναι, καὶ καθίσαντες ἐλαλοῦμεν ταῖς συνελθούσαις γυναιξίν.
44016014 Καί τις γυνὴ ὀνόματι Λυδία, πορφυρόπωλις πόλεως Θυατείρων, σεβομένη τὸν ˚Θεόν ἤκουεν, ἧς ὁ ˚Κύριος διήνοιξεν τὴν καρδίαν, προσέχειν τοῖς λαλουμένοις ὑπὸ τοῦ Παύλου.
44016015 Ὡς δὲ ἐβαπτίσθη καὶ ὁ οἶκος αὐτῆς, παρεκάλεσεν λέγουσα, “Εἰ κεκρίκατέ με πιστὴν τῷ ˚Κυρίῳ εἶναι, εἰσελθόντες εἰς τὸν οἶκόν μου, μένετε.” Καὶ παρεβιάσατο ἡμᾶς.
44016016 Ἐγένετο δὲ, πορευομένων ἡμῶν εἰς τὴν προσευχὴν, παιδίσκην τινὰ ἔχουσαν πνεῦμα Πύθωνα, ὑπαντῆσαι ἡμῖν, ἥτις ἐργασίαν πολλὴν παρεῖχεν τοῖς κυρίοις αὐτῆς μαντευομένη.
44016017 Αὕτη κατακολουθοῦσα τῷ Παύλῳ καὶ ἡμῖν ἔκραζεν λέγουσα, “Οὗτοι οἱ ἄνθρωποι δοῦλοι τοῦ ˚Θεοῦ τοῦ Ὑψίστου εἰσίν, οἵτινες καταγγέλλουσιν ὑμῖν ὁδὸν σωτηρίας.”
44016018 Τοῦτο δὲ ἐποίει ἐπὶ πολλὰς ἡμέρας. Διαπονηθεὶς δὲ Παῦλος, καὶ ἐπιστρέψας τῷ Πνεύματι εἶπεν, “Παραγγέλλω σοι ἐν ὀνόματι ˚Ἰησοῦ ˚Χριστοῦ ἐξελθεῖν ἀπʼ αὐτῆς.” Καὶ ἐξῆλθεν αὐτῇ τῇ ὥρᾳ.
44016019 Ἰδόντες δὲ οἱ κύριοι αὐτῆς ὅτι ἐξῆλθεν ἡ ἐλπὶς τῆς ἐργασίας αὐτῶν, ἐπιλαβόμενοι τὸν Παῦλον καὶ τὸν Σιλᾶν, εἵλκυσαν εἰς τὴν ἀγορὰν ἐπὶ τοὺς ἄρχοντας,
44016020 καὶ προσαγαγόντες αὐτοὺς τοῖς στρατηγοῖς, εἶπαν, “Οὗτοι οἱ ἄνθρωποι ἐκταράσσουσιν ἡμῶν τὴν πόλιν, Ἰουδαῖοι ὑπάρχοντες,
44016021 καὶ καταγγέλλουσιν ἔθη ἃ οὐκ ἔξεστιν ἡμῖν παραδέχεσθαι οὐδὲ ποιεῖν, Ῥωμαίοις οὖσιν.”
44016022 Καὶ συνεπέστη ὁ ὄχλος κατʼ αὐτῶν, καὶ οἱ στρατηγοὶ περιρήξαντες αὐτῶν τὰ ἱμάτια, ἐκέλευον ῥαβδίζειν.
44016023 Πολλάς τε ἐπιθέντες αὐτοῖς πληγὰς, ἔβαλον εἰς φυλακήν, παραγγείλαντες τῷ δεσμοφύλακι ἀσφαλῶς τηρεῖν αὐτούς·
44016024 ὃς παραγγελίαν τοιαύτην λαβὼν, ἔβαλεν αὐτοὺς εἰς τὴν ἐσωτέραν φυλακὴν, καὶ τοὺς πόδας ἠσφαλίσατο αὐτῶν εἰς τὸ ξύλον.
44016025 Κατὰ δὲ τὸ μεσονύκτιον, Παῦλος καὶ Σιλᾶς προσευχόμενοι, ὕμνουν τὸν ˚Θεόν, ἐπηκροῶντο δὲ αὐτῶν οἱ δέσμιοι.
44016026 Ἄφνω δὲ σεισμὸς ἐγένετο μέγας, ὥστε σαλευθῆναι τὰ θεμέλια τοῦ δεσμωτηρίου, ἠνεῴχθησαν δὲ παραχρῆμα αἱ θύραι πᾶσαι, καὶ πάντων τὰ δεσμὰ ἀνέθη.
44016027 Ἔξυπνος δὲ γενόμενος ὁ δεσμοφύλαξ, καὶ ἰδὼν ἀνεῳγμένας τὰς θύρας τῆς φυλακῆς, σπασάμενος τὴν μάχαιραν ἤμελλεν ἑαυτὸν ἀναιρεῖν, νομίζων ἐκπεφευγέναι τοὺς δεσμίους.
44016028 Ἐφώνησεν δὲ φωνῇ μεγάλῃ Παῦλος λέγων, “Μηδὲν πράξῃς σεαυτῷ κακόν, ἅπαντες γάρ ἐσμεν ἐνθάδε.”
44016029 Αἰτήσας δὲ φῶτα, εἰσεπήδησεν, καὶ ἔντρομος γενόμενος, προσέπεσεν τῷ Παύλῳ καὶ Σιλᾷ.
44016030 Καὶ προαγαγὼν αὐτοὺς ἔξω ἔφη, “Κύριοι, τί με δεῖ ποιεῖν, ἵνα σωθῶ;”
44016031 Οἱ δὲ εἶπαν, “Πίστευσον ἐπὶ τὸν ˚Κύριον ˚Ἰησοῦν καὶ σωθήσῃ, σὺ καὶ ὁ οἶκός σου.”
44016032 Καὶ ἐλάλησαν αὐτῷ τὸν λόγον τοῦ ˚Κυρίου, σὺν πᾶσι τοῖς ἐν τῇ οἰκίᾳ αὐτοῦ.
44016033 Καὶ παραλαβὼν αὐτοὺς ἐν ἐκείνῃ τῇ ὥρᾳ τῆς νυκτὸς, ἔλουσεν ἀπὸ τῶν πληγῶν, καὶ ἐβαπτίσθη, αὐτὸς καὶ οἱ αὐτοῦ ἅπαντες παραχρῆμα.
44016034 Ἀναγαγών τε αὐτοὺς εἰς τὸν οἶκον, παρέθηκεν τράπεζαν καὶ ἠγαλλιάσατο πανοικεὶ, πεπιστευκὼς τῷ ˚Θεῷ.
44016035 Ἡμέρας δὲ γενομένης, ἀπέστειλαν οἱ στρατηγοὶ τοὺς ῥαβδούχους λέγοντες, “Ἀπόλυσον τοὺς ἀνθρώπους ἐκείνους.”
44016036 Ἀπήγγειλεν δὲ ὁ δεσμοφύλαξ τοὺς λόγους πρὸς τὸν Παῦλον: ὅτι “Ἀπέσταλκαν οἱ στρατηγοὶ, ἵνα ἀπολυθῆτε. Νῦν οὖν ἐξελθόντες, πορεύεσθε ἐν εἰρήνῃ.”
44016037 Ὁ δὲ Παῦλος ἔφη πρὸς αὐτούς, “Δείραντες ἡμᾶς δημοσίᾳ, ἀκατακρίτους ἀνθρώπους Ῥωμαίους ὑπάρχοντας, ἔβαλαν εἰς φυλακήν καὶ νῦν λάθρᾳ ἡμᾶς ἐκβάλλουσιν; Οὐ γάρ, ἀλλὰ ἐλθόντες αὐτοὶ, ἡμᾶς ἐξαγαγέτωσαν.”
44016038 Ἀπήγγειλαν δὲ τοῖς στρατηγοῖς οἱ ῥαβδοῦχοι τὰ ῥήματα ταῦτα. Ἐφοβήθησαν δὲ, ἀκούσαντες ὅτι Ῥωμαῖοί εἰσιν.
44016039 Καὶ ἐλθόντες, παρεκάλεσαν αὐτούς, καὶ ἐξαγαγόντες, ἠρώτων ἀπελθεῖν Ἀπὸ τῆς πόλεως.
44016040 Ἐξελθόντες δὲ ἀπὸ τῆς φυλακῆς, εἰσῆλθον πρὸς τὴν Λυδίαν, καὶ ἰδόντες, παρεκάλεσαν τοὺς ἀδελφοὺς καὶ ἐξῆλθον.
44017001 Διοδεύσαντες δὲ τὴν Ἀμφίπολιν καὶ τὴν Ἀπολλωνίαν, ἦλθον εἰς Θεσσαλονίκην, ὅπου ἦν συναγωγὴ τῶν Ἰουδαίων.
44017002 Κατὰ δὲ τὸ εἰωθὸς τῷ Παύλῳ, εἰσῆλθεν πρὸς αὐτοὺς, καὶ ἐπὶ Σάββατα τρία, διελέξατο αὐτοῖς ἀπὸ τῶν Γραφῶν,
44017003 διανοίγων καὶ παρατιθέμενος ὅτι τὸν ˚Χριστὸν ἔδει παθεῖν, καὶ ἀναστῆναι ἐκ νεκρῶν, καὶ ὅτι “Οὗτός ἐστιν ˚Χριστὸς, ˚Ἰησοῦς, ὃν ἐγὼ καταγγέλλω ὑμῖν.”
44017004 Καί τινες ἐξ αὐτῶν ἐπείσθησαν καὶ προσεκληρώθησαν τῷ Παύλῳ καὶ τῷ Σιλᾷ, τῶν τε σεβομένων Ἑλλήνων πλῆθος πολὺ, γυναικῶν τε τῶν πρώτων οὐκ ὀλίγαι.
44017005 Ζηλώσαντες δὲ οἱ Ἰουδαῖοι, καὶ προσλαβόμενοι τῶν ἀγοραίων ἄνδρας τινὰς πονηροὺς, καὶ ὀχλοποιήσαντες, ἐθορύβουν τὴν πόλιν, καὶ ἐπιστάντες τῇ οἰκίᾳ Ἰάσονος, ἐζήτουν αὐτοὺς προαγαγεῖν εἰς τὸν δῆμον.
44017006 Μὴ εὑρόντες δὲ αὐτοὺς, ἔσυρον Ἰάσονα καί τινας ἀδελφοὺς ἐπὶ τοὺς πολιτάρχας βοῶντες, ὅτι “Οἱ τὴν οἰκουμένην ἀναστατώσαντες, οὗτοι καὶ ἐνθάδε πάρεισιν,
44017007 οὓς ὑποδέδεκται Ἰάσων· καὶ οὗτοι πάντες ἀπέναντι τῶν δογμάτων Καίσαρος πράσσουσι, βασιλέα ἕτερον λέγοντες εἶναι ˚Ἰησοῦν.”
44017008 Ἐτάραξαν δὲ τὸν ὄχλον καὶ τοὺς πολιτάρχας ἀκούοντας ταῦτα.
44017009 Καὶ λαβόντες τὸ ἱκανὸν παρὰ τοῦ Ἰάσονος καὶ τῶν λοιπῶν, ἀπέλυσαν αὐτούς.
44017010 Οἱ δὲ ἀδελφοὶ εὐθέως διὰ νυκτὸς ἐξέπεμψαν τόν τε Παῦλον καὶ τὸν Σιλᾶν εἰς Βέροιαν, οἵτινες παραγενόμενοι, εἰς τὴν συναγωγὴν τῶν Ἰουδαίων ἀπῄεσαν.
44017011 Οὗτοι δὲ ἦσαν εὐγενέστεροι τῶν ἐν Θεσσαλονίκῃ, οἵτινες ἐδέξαντο τὸν λόγον μετὰ πάσης προθυμίας, καθʼ ἡμέραν ἀνακρίνοντες τὰς Γραφὰς, εἰ ἔχοι ταῦτα οὕτως.
44017012 Πολλοὶ μὲν οὖν ἐξ αὐτῶν ἐπίστευσαν, καὶ τῶν Ἑλληνίδων γυναικῶν τῶν εὐσχημόνων, καὶ ἀνδρῶν οὐκ ὀλίγοι.
44017013 Ὡς δὲ ἔγνωσαν οἱ ἀπὸ τῆς Θεσσαλονίκης Ἰουδαῖοι ὅτι καὶ ἐν τῇ Βεροίᾳ κατηγγέλη ὑπὸ τοῦ Παύλου ὁ λόγος τοῦ ˚Θεοῦ, ἦλθον κἀκεῖ, σαλεύοντες καὶ ταράσσοντες τοὺς ὄχλους.
44017014 Εὐθέως δὲ τότε τὸν Παῦλον ἐξαπέστειλαν οἱ ἀδελφοὶ, πορεύεσθαι ἕως ἐπὶ τὴν θάλασσαν· ὑπέμεινάν τε ὅ τε Σιλᾶς καὶ ὁ Τιμόθεος ἐκεῖ.
44017015 Οἱ δὲ καθιστάνοντες τὸν Παῦλον, ἤγαγον ἕως Ἀθηνῶν, καὶ λαβόντες ἐντολὴν πρὸς τὸν Σιλᾶν καὶ τὸν Τιμόθεον, ἵνα ὡς τάχιστα ἔλθωσιν πρὸς αὐτὸν, ἐξῄεσαν.
44017016 Ἐν δὲ ταῖς Ἀθήναις, ἐκδεχομένου αὐτοὺς τοῦ Παύλου, παρωξύνετο τὸ πνεῦμα αὐτοῦ ἐν αὐτῷ, θεωροῦντος κατείδωλον οὖσαν τὴν πόλιν.
44017017 Διελέγετο μὲν οὖν ἐν τῇ συναγωγῇ τοῖς Ἰουδαίοις, καὶ τοῖς σεβομένοις, καὶ ἐν τῇ ἀγορᾷ κατὰ πᾶσαν ἡμέραν πρὸς τοὺς παρατυγχάνοντας.
44017018 Τινὲς δὲ καὶ τῶν Ἐπικουρείων καὶ Στοϊκῶν φιλοσόφων συνέβαλλον αὐτῷ, καί τινες ἔλεγον, “Τί ἂν θέλοι ὁ σπερμολόγος οὗτος λέγειν;” Οἱ δέ, “Ξένων δαιμονίων δοκεῖ καταγγελεὺς εἶναι”· ὅτι τὸν ˚Ἰησοῦν καὶ τὴν ἀνάστασιν εὐηγγελίζετο.
44017019 Ἐπιλαβόμενοι δὲ αὐτοῦ, ἐπὶ τὸν Ἄρειον Πάγον ἤγαγον λέγοντες, “Δυνάμεθα γνῶναι τίς ἡ καινὴ αὕτη, ἡ ὑπὸ σοῦ λαλουμένη διδαχή;
44017020 Ξενίζοντα γάρ τινα εἰσφέρεις εἰς τὰς ἀκοὰς ἡμῶν. Βουλόμεθα οὖν γνῶναι τίνα θέλει ταῦτα εἶναι.”
44017021 ( Ἀθηναῖοι δὲ πάντες καὶ οἱ ἐπιδημοῦντες ξένοι εἰς οὐδὲν ἕτερον ηὐκαίρουν, ἢ λέγειν τι ἢ ἀκούειν τι καινότερον. )
44017022 Σταθεὶς δὲ Παῦλος ἐν μέσῳ τοῦ Ἀρείου Πάγου ἔφη, “Ἄνδρες, Ἀθηναῖοι, κατὰ πάντα ὡς δεισιδαιμονεστέρους ὑμᾶς θεωρῶ.
44017023 Διερχόμενος γὰρ καὶ ἀναθεωρῶν τὰ σεβάσματα ὑμῶν, εὗρον καὶ βωμὸν ἐν ᾧ ἐπεγέγραπτο, ‘Ἀγνώστῳ ˚Θεῷ.’ Ὃ οὖν ἀγνοοῦντες εὐσεβεῖτε, τοῦτο ἐγὼ καταγγέλλω ὑμῖν.
44017024 Ὁ ˚Θεὸς ὁ ποιήσας τὸν κόσμον καὶ πάντα τὰ ἐν αὐτῷ, οὗτος οὐρανοῦ καὶ γῆς ὑπάρχων ˚Κύριος, οὐκ ἐν χειροποιήτοις ναοῖς κατοικεῖ,
44017025 οὐδὲ ὑπὸ χειρῶν ἀνθρωπίνων θεραπεύεται, προσδεόμενός τινος, αὐτὸς διδοὺς πᾶσι ζωὴν, καὶ πνοὴν, καὶ τὰ πάντα·
44017026 ἐποίησέν τε ἐξ ἑνὸς, πᾶν ἔθνος ἀνθρώπων κατοικεῖν ἐπὶ παντὸς προσώπου τῆς γῆς, ὁρίσας προστεταγμένους καιροὺς καὶ τὰς ὁροθεσίας τῆς κατοικίας αὐτῶν,
44017027 ζητεῖν τὸν ˚Θεὸν, εἰ ἄρα γε ψηλαφήσειαν αὐτὸν καὶ εὕροιεν, καί γε οὐ μακρὰν ἀπὸ ἑνὸς ἑκάστου ἡμῶν ὑπάρχοντα.
44017028 Ἐν αὐτῷ γὰρ ζῶμεν καὶ κινούμεθα καὶ ἐσμέν, ὡς καί τινες τῶν καθʼ ὑμᾶς ποιητῶν εἰρήκασιν, ‘Τοῦ γὰρ καὶ γένος ἐσμέν.’
44017029 Γένος οὖν ὑπάρχοντες τοῦ ˚Θεοῦ, οὐκ ὀφείλομεν νομίζειν χρυσῷ ἢ ἀργύρῳ ἢ λίθῳ χαράγματι τέχνης καὶ ἐνθυμήσεως ἀνθρώπου, τὸ θεῖον εἶναι ὅμοιον.
44017030 Τοὺς μὲν οὖν χρόνους τῆς ἀγνοίας ὑπεριδὼν ὁ ˚Θεὸς, τὰ νῦν παραγγέλλει τοῖς ἀνθρώποις πάντας πανταχοῦ μετανοεῖν,
44017031 καθότι ἔστησεν ἡμέραν ἐν ᾗ μέλλει κρίνειν τὴν οἰκουμένην ἐν δικαιοσύνῃ, ἐν ἀνδρὶ ᾧ ὥρισεν, πίστιν παρασχὼν πᾶσιν, ἀναστήσας αὐτὸν ἐκ νεκρῶν.”
44017032 Ἀκούσαντες δὲ ἀνάστασιν νεκρῶν, οἱ μὲν ἐχλεύαζον, οἱ δὲ εἶπαν, “Ἀκουσόμεθά σου περὶ τούτου καὶ πάλιν.”
44017033 Οὕτως ὁ Παῦλος ἐξῆλθεν ἐκ μέσου αὐτῶν.
44017034 Τινὲς δὲ ἄνδρες κολληθέντες αὐτῷ, ἐπίστευσαν ἐν οἷς καὶ Διονύσιος ὁ Ἀρεοπαγίτης, καὶ γυνὴ ὀνόματι Δάμαρις, καὶ ἕτεροι σὺν αὐτοῖς.
44018001 Μετὰ ταῦτα, χωρισθεὶς ἐκ τῶν Ἀθηνῶν, ἦλθεν εἰς Κόρινθον.
44018002 Καὶ εὑρών τινα Ἰουδαῖον ὀνόματι Ἀκύλαν, Ποντικὸν τῷ γένει, προσφάτως ἐληλυθότα ἀπὸ τῆς Ἰταλίας, καὶ Πρίσκιλλαν γυναῖκα αὐτοῦ, διὰ τὸ διατεταχέναι Κλαύδιον χωρίζεσθαι πάντας τοὺς Ἰουδαίους ἀπὸ τῆς Ῥώμης, προσῆλθεν αὐτοῖς,
44018003 καὶ διὰ τὸ ὁμότεχνον εἶναι, ἔμενεν παρʼ αὐτοῖς καὶ ἠργάζετο, ἦσαν γὰρ σκηνοποιοὶ τῇ τέχνῃ.
44018004 Διελέγετο δὲ ἐν τῇ συναγωγῇ κατὰ πᾶν Σάββατον, ἔπειθέν τε Ἰουδαίους καὶ Ἕλληνας.
44018005 Ὡς δὲ κατῆλθον ἀπὸ τῆς Μακεδονίας ὅ τε Σιλᾶς καὶ ὁ Τιμόθεος, συνείχετο τῷ λόγῳ ὁ Παῦλος, διαμαρτυρόμενος τοῖς Ἰουδαίοις, εἶναι τὸν ˚Χριστὸν, ˚Ἰησοῦν.
44018006 Ἀντιτασσομένων δὲ αὐτῶν καὶ βλασφημούντων, ἐκτιναξάμενος τὰ ἱμάτια, εἶπεν πρὸς αὐτούς, “Τὸ αἷμα ὑμῶν ἐπὶ τὴν κεφαλὴν ὑμῶν· καθαρὸς ἐγώ. Ἀπὸ τοῦ νῦν, εἰς τὰ ἔθνη πορεύσομαι.”
44018007 Καὶ μεταβὰς ἐκεῖθεν, εἰσῆλθεν εἰς οἰκίαν τινὸς ὀνόματι Τιτίου Ἰούστου, σεβομένου τὸν ˚Θεόν, οὗ ἡ οἰκία ἦν συνομοροῦσα τῇ συναγωγῇ.
44018008 Κρίσπος δὲ ὁ ἀρχισυνάγωγος, ἐπίστευσεν τῷ ˚Κυρίῳ σὺν ὅλῳ τῷ οἴκῳ αὐτοῦ, καὶ πολλοὶ τῶν Κορινθίων ἀκούοντες, ἐπίστευον καὶ ἐβαπτίζοντο.
44018009 Εἶπεν δὲ ὁ ˚Κύριος ἐν νυκτὶ διʼ ὁράματος τῷ Παύλῳ, “Μὴ φοβοῦ, ἀλλὰ λάλει καὶ μὴ σιωπήσῃς,
44018010 διότι ἐγώ εἰμι μετὰ σοῦ, καὶ οὐδεὶς ἐπιθήσεταί σοι τοῦ κακῶσαί σε, διότι λαός ἐστί μοι πολὺς ἐν τῇ πόλει ταύτῃ.”
44018011 Ἐκάθισεν δὲ ἐνιαυτὸν καὶ μῆνας ἓξ, διδάσκων ἐν αὐτοῖς τὸν λόγον τοῦ ˚Θεοῦ.
44018012 Γαλλίωνος δὲ ἀνθυπάτου ὄντος τῆς Ἀχαΐας, κατεπέστησαν ὁμοθυμαδὸν οἱ Ἰουδαῖοι τῷ Παύλῳ, καὶ ἤγαγον αὐτὸν ἐπὶ τὸ βῆμα
44018013 λέγοντες, ὅτι “Παρὰ τὸν νόμον, ἀναπείθει οὗτος τοὺς ἀνθρώπους σέβεσθαι τὸν ˚Θεόν.”
44018014 Μέλλοντος δὲ τοῦ Παύλου ἀνοίγειν τὸ στόμα, εἶπεν ὁ Γαλλίων πρὸς τοὺς Ἰουδαίους, “Εἰ μὲν ἦν ἀδίκημά τι ἢ ῥᾳδιούργημα πονηρόν, ὦ Ἰουδαῖοι, κατὰ λόγον ἂν ἀνεσχόμην ὑμῶν·
44018015 εἰ δὲ ζητήματά ἐστιν περὶ λόγου, καὶ ὀνομάτων, καὶ νόμου, τοῦ καθʼ ὑμᾶς, ὄψεσθε αὐτοί· κριτὴς ἐγὼ τούτων οὐ βούλομαι εἶναι.”
44018016 Καὶ ἀπήλασεν αὐτοὺς ἀπὸ τοῦ βήματος.
44018017 Ἐπιλαβόμενοι δὲ πάντες Σωσθένην τὸν ἀρχισυνάγωγον, ἔτυπτον ἔμπροσθεν τοῦ βήματος. Καὶ οὐδὲν τούτων τῷ Γαλλίωνι ἔμελεν.
44018018 Ὁ δὲ Παῦλος ἔτι προσμείνας ἡμέρας ἱκανὰς, τοῖς ἀδελφοῖς ἀποταξάμενος, ἐξέπλει εἰς τὴν Συρίαν, καὶ σὺν αὐτῷ Πρίσκιλλα καὶ Ἀκύλας, κειράμενος ἐν Κεγχρεαῖς τὴν κεφαλήν, εἶχεν γὰρ εὐχήν.
44018019 Κατήντησαν δὲ εἰς Ἔφεσον, κἀκείνους κατέλιπεν αὐτοῦ, αὐτὸς δὲ εἰσελθὼν εἰς τὴν συναγωγὴν, διελέξατο τοῖς Ἰουδαίοις.
44018020 Ἐρωτώντων δὲ αὐτῶν ἐπὶ πλείονα χρόνον μεῖναι, οὐκ ἐπένευσεν,
44018021 ἀλλὰ ἀποταξάμενος καὶ εἰπών, “Πάλιν ἀνακάμψω πρὸς ὑμᾶς, τοῦ ˚Θεοῦ θέλοντος”, ἀνήχθη ἀπὸ τῆς Ἐφέσου.
44018022 Καὶ κατελθὼν εἰς Καισάρειαν, ἀναβὰς καὶ ἀσπασάμενος τὴν ἐκκλησίαν, κατέβη εἰς Ἀντιόχειαν.
44018023 Καὶ ποιήσας χρόνον τινὰ, ἐξῆλθεν διερχόμενος καθεξῆς τὴν Γαλατικὴν χώραν καὶ Φρυγίαν, στηρίζων πάντας τοὺς μαθητάς.
44018024 Ἰουδαῖος δέ τις Ἀπολλῶς ὀνόματι Ἀλεξανδρεὺς τῷ γένει, ἀνὴρ λόγιος, κατήντησεν εἰς Ἔφεσον, δυνατὸς ὢν ἐν ταῖς Γραφαῖς.
44018025 Οὗτος ἦν κατηχημένος τὴν ὁδὸν τοῦ ˚Κυρίου, καὶ ζέων τῷ πνεύματι, ἐλάλει καὶ ἐδίδασκεν ἀκριβῶς τὰ περὶ τοῦ ˚Ἰησοῦ, ἐπιστάμενος μόνον τὸ βάπτισμα Ἰωάννου.
44018026 Οὗτός τε ἤρξατο παρρησιάζεσθαι ἐν τῇ συναγωγῇ. Ἀκούσαντες δὲ αὐτοῦ, Πρίσκιλλα καὶ Ἀκύλας προσελάβοντο αὐτὸν, καὶ ἀκριβέστερον αὐτῷ ἐξέθεντο τὴν ὁδὸν τοῦ ˚Θεοῦ.
44018027 Βουλομένου δὲ αὐτοῦ διελθεῖν εἰς τὴν Ἀχαΐαν, προτρεψάμενοι, οἱ ἀδελφοὶ ἔγραψαν τοῖς μαθηταῖς ἀποδέξασθαι αὐτόν, ὃς παραγενόμενος, συνεβάλετο πολὺ τοῖς πεπιστευκόσιν διὰ τῆς χάριτος.
44018028 Εὐτόνως γὰρ τοῖς Ἰουδαίοις διακατηλέγχετο δημοσίᾳ, ἐπιδεικνὺς διὰ τῶν Γραφῶν εἶναι τὸν ˚Χριστὸν, ˚Ἰησοῦν.
44019001 Ἐγένετο δὲ ἐν τῷ, τὸν Ἀπολλῶ εἶναι ἐν Κορίνθῳ, Παῦλον διελθόντα τὰ ἀνωτερικὰ μέρη, κατελθεῖν εἰς Ἔφεσον καὶ εὑρεῖν τινας μαθητάς,
44019002 εἶπέν τε πρὸς αὐτούς, “Εἰ ˚Πνεῦμα Ἅγιον ἐλάβετε, πιστεύσαντες;” Οἱ δὲ πρὸς αὐτόν, “Ἀλλʼ οὐδʼ εἰ ˚Πνεῦμα Ἅγιον ἔστιν ἠκούσαμεν.”
44019003 Εἶπέν τε, “Εἰς τί οὖν ἐβαπτίσθητε;” Οἱ δὲ εἶπαν, “Εἰς τὸ Ἰωάννου βάπτισμα.”
44019004 Εἶπεν δὲ Παῦλος, “Ἰωάννης ἐβάπτισεν βάπτισμα μετανοίας, τῷ λαῷ λέγων εἰς τὸν ἐρχόμενον μετʼ αὐτὸν ἵνα πιστεύσωσιν τοῦτʼ ἔστιν εἰς τὸν ˚Ἰησοῦν.”
44019005 Ἀκούσαντες δὲ, ἐβαπτίσθησαν εἰς τὸ ὄνομα τοῦ ˚Κυρίου ˚Ἰησοῦ.
44019006 Καὶ ἐπιθέντος αὐτοῖς τοῦ Παύλου χεῖρας, ἦλθε τὸ ˚Πνεῦμα τὸ Ἅγιον ἐπʼ αὐτούς, ἐλάλουν τε γλώσσαις καὶ ἐπροφήτευον.
44019007 Ἦσαν δὲ οἱ πάντες ἄνδρες ὡσεὶ δώδεκα.
44019008 Εἰσελθὼν δὲ εἰς τὴν συναγωγὴν, ἐπαρρησιάζετο ἐπὶ μῆνας τρεῖς, διαλεγόμενος καὶ πείθων περὶ τῆς Βασιλείας τοῦ ˚Θεοῦ.
44019009 Ὡς δέ τινες ἐσκληρύνοντο καὶ ἠπείθουν, κακολογοῦντες τὴν ὁδὸν ἐνώπιον τοῦ πλήθους, ἀποστὰς ἀπʼ αὐτῶν ἀφώρισεν τοὺς μαθητάς, καθʼ ἡμέραν διαλεγόμενος ἐν τῇ σχολῇ Τυράννου.
44019010 Τοῦτο δὲ ἐγένετο ἐπὶ ἔτη δύο, ὥστε πάντας τοὺς κατοικοῦντας τὴν Ἀσίαν ἀκοῦσαι τὸν λόγον τοῦ ˚Κυρίου, Ἰουδαίους τε καὶ Ἕλληνας.
44019011 Δυνάμεις τε οὐ τὰς τυχούσας, ὁ ˚Θεὸς ἐποίει διὰ τῶν χειρῶν Παύλου,
44019012 ὥστε καὶ ἐπὶ τοὺς ἀσθενοῦντας ἀποφέρεσθαι ἀπὸ τοῦ χρωτὸς αὐτοῦ σουδάρια ἢ σιμικίνθια, καὶ ἀπαλλάσσεσθαι ἀπʼ αὐτῶν τὰς νόσους, τά τε πνεύματα τὰ πονηρὰ ἐκπορεύεσθαι.
44019013 Ἐπεχείρησαν δέ τινες καὶ τῶν περιερχομένων Ἰουδαίων ἐξορκιστῶν ὀνομάζειν ἐπὶ τοὺς ἔχοντας τὰ πνεύματα τὰ πονηρὰ τὸ ὄνομα τοῦ ˚Κυρίου ˚Ἰησοῦ λέγοντες, “Ὁρκίζω ὑμᾶς, τὸν ˚Ἰησοῦν, ὃν Παῦλος κηρύσσει.”
44019014 Ἦσαν δέ τινος Σκευᾶ Ἰουδαίου ἀρχιερέως ἑπτὰ υἱοὶ τοῦτο ποιοῦντες.
44019015 Ἀποκριθὲν δὲ τὸ πνεῦμα τὸ πονηρὸν εἶπεν αὐτοῖς, “Τὸν ˚Ἰησοῦν γινώσκω, καὶ τὸν Παῦλον ἐπίσταμαι, ὑμεῖς δὲ τίνες ἐστέ;”
44019016 Καὶ ἐφαλόμενος ὁ ἄνθρωπος ἐπʼ αὐτοὺς, ἐν ᾧ ἦν τὸ πνεῦμα τὸ πονηρὸν, κατακυριεύσας ἀμφοτέρων, ἴσχυσεν κατʼ αὐτῶν, ὥστε γυμνοὺς καὶ τετραυματισμένους ἐκφυγεῖν ἐκ τοῦ οἴκου ἐκείνου.
44019017 Τοῦτο δὲ ἐγένετο γνωστὸν πᾶσιν, Ἰουδαίοις τε καὶ Ἕλλησιν, τοῖς κατοικοῦσιν τὴν Ἔφεσον, καὶ ἐπέπεσεν φόβος ἐπὶ πάντας αὐτούς, καὶ ἐμεγαλύνετο τὸ ὄνομα τοῦ ˚Κυρίου ˚Ἰησοῦ.
44019018 Πολλοί τε τῶν πεπιστευκότων, ἤρχοντο ἐξομολογούμενοι καὶ ἀναγγέλλοντες τὰς πράξεις αὐτῶν.
44019019 Ἱκανοὶ δὲ τῶν τὰ περίεργα πραξάντων, συνενέγκαντες τὰς βίβλους, κατέκαιον ἐνώπιον πάντων. Καὶ συνεψήφισαν τὰς τιμὰς αὐτῶν, καὶ εὗρον ἀργυρίου μυριάδας πέντε.
44019020 Οὕτως κατὰ κράτος τοῦ ˚Κυρίου ὁ λόγος ηὔξανεν καὶ ἴσχυεν.
44019021 Ὡς δὲ ἐπληρώθη ταῦτα, ἔθετο ὁ Παῦλος ἐν τῷ ˚Πνεύματι, διελθὼν τὴν Μακεδονίαν καὶ Ἀχαΐαν, πορεύεσθαι εἰς Ἱεροσόλυμα εἰπὼν, ὅτι “Μετὰ τὸ γενέσθαι με ἐκεῖ, δεῖ με καὶ Ῥώμην ἰδεῖν.”
44019022 Ἀποστείλας δὲ εἰς τὴν Μακεδονίαν, δύο τῶν διακονούντων αὐτῷ, Τιμόθεον καὶ Ἔραστον, αὐτὸς ἐπέσχεν χρόνον εἰς τὴν Ἀσίαν.
44019023 Ἐγένετο δὲ κατὰ τὸν καιρὸν ἐκεῖνον, τάραχος οὐκ ὀλίγος περὶ τῆς ὁδοῦ.
44019024 Δημήτριος γάρ τις ὀνόματι ἀργυροκόπος, ποιῶν ναοὺς ἀργυροῦς Ἀρτέμιδος, παρείχετο τοῖς τεχνίταις οὐκ ὀλίγην ἐργασίαν,
44019025 οὓς συναθροίσας καὶ τοὺς περὶ τὰ τοιαῦτα ἐργάτας, εἶπεν, “Ἄνδρες, ἐπίστασθε ὅτι ἐκ ταύτης τῆς ἐργασίας ἡ εὐπορία ἡμῖν ἐστιν.
44019026 Καὶ θεωρεῖτε καὶ ἀκούετε ὅτι οὐ μόνον Ἐφέσου, ἀλλὰ σχεδὸν πάσης τῆς Ἀσίας, ὁ Παῦλος οὗτος πείσας, μετέστησεν ἱκανὸν ὄχλον, λέγων ὅτι οὐκ εἰσὶν θεοὶ, οἱ διὰ χειρῶν γινόμενοι.
44019027 Οὐ μόνον δὲ τοῦτο κινδυνεύει ἡμῖν, τὸ μέρος εἰς ἀπελεγμὸν ἐλθεῖν, ἀλλὰ καὶ τὸ τῆς μεγάλης θεᾶς Ἀρτέμιδος ἱερὸν, εἰς οὐθὲν λογισθῆναι, μέλλειν τε καὶ καθαιρεῖσθαι τῆς μεγαλειότητος αὐτῆς, ἣν ὅλη ἡ Ἀσία καὶ ἡ οἰκουμένη σέβεται.”
44019028 Ἀκούσαντες δὲ, καὶ γενόμενοι πλήρεις θυμοῦ, ἔκραζον λέγοντες, “Μεγάλη ἡ Ἄρτεμις Ἐφεσίων.”
44019029 Καὶ ἐπλήσθη ἡ πόλις τῆς συγχύσεως, ὥρμησάν τε ὁμοθυμαδὸν εἰς τὸ θέατρον, συναρπάσαντες Γάϊον καὶ Ἀρίσταρχον Μακεδόνας, συνεκδήμους Παύλου.
44019030 Παύλου δὲ βουλομένου εἰσελθεῖν εἰς τὸν δῆμον, οὐκ εἴων αὐτὸν οἱ μαθηταί.
44019031 Τινὲς δὲ καὶ τῶν Ἀσιαρχῶν, ὄντες αὐτῷ φίλοι, πέμψαντες πρὸς αὐτὸν, παρεκάλουν μὴ δοῦναι ἑαυτὸν εἰς τὸ θέατρον.
44019032 Ἄλλοι μὲν οὖν ἄλλο τι ἔκραζον, ἦν γὰρ ἡ ἐκκλησία συγκεχυμένη, καὶ οἱ πλείους οὐκ ᾔδεισαν τίνος ἕνεκα συνεληλύθεισαν.
44019033 Ἐκ δὲ τοῦ ὄχλου συνεβίβασαν Ἀλέξανδρον, προβαλόντων αὐτὸν τῶν Ἰουδαίων. Ὁ δὲ Ἀλέξανδρος κατασείσας τὴν χεῖρα, ἤθελεν ἀπολογεῖσθαι τῷ δήμῳ.
44019034 Ἐπιγνόντες δὲ ὅτι Ἰουδαῖός ἐστιν, φωνὴ ἐγένετο μία ἐκ πάντων, ὡς ἐπὶ ὥρας δύο κραζόντων, “Μεγάλη ἡ Ἄρτεμις Ἐφεσίων!”
44019035 Καταστείλας δὲ ὁ γραμματεὺς τὸν ὄχλον φησίν, “Ἄνδρες, Ἐφέσιοι, τίς γάρ ἐστιν ἀνθρώπων, ὃς οὐ γινώσκει τὴν Ἐφεσίων πόλιν νεωκόρον οὖσαν τῆς μεγάλης Ἀρτέμιδος καὶ τοῦ διοπετοῦς;
44019036 Ἀναντιρρήτων οὖν ὄντων τούτων, δέον ἐστὶν ὑμᾶς κατεσταλμένους ὑπάρχειν, καὶ μηδὲν προπετὲς πράσσειν.
44019037 Ἠγάγετε γὰρ τοὺς ἄνδρας τούτους, οὔτε ἱεροσύλους οὔτε βλασφημοῦντας τὴν θεὸν ἡμῶν.
44019038 Εἰ μὲν οὖν Δημήτριος καὶ οἱ σὺν αὐτῷ τεχνῖται ἔχουσιν πρός τινα λόγον, ἀγοραῖοι ἄγονται καὶ ἀνθύπατοί εἰσιν, ἐγκαλείτωσαν ἀλλήλοις.
44019039 Εἰ δέ τι περὶ ἑτέρων ἐπιζητεῖτε, ἐν τῇ ἐννόμῳ ἐκκλησίᾳ ἐπιλυθήσεται.
44019040 Καὶ γὰρ κινδυνεύομεν ἐγκαλεῖσθαι στάσεως περὶ τῆς σήμερον, μηδενὸς αἰτίου ὑπάρχοντος, περὶ οὗ οὐ δυνησόμεθα ἀποδοῦναι λόγον περὶ τῆς συστροφῆς ταύτης.” Καὶ ταῦτα εἰπὼν, ἀπέλυσεν τὴν ἐκκλησίαν.
44020001 Μετὰ δὲ τὸ παύσασθαι τὸν θόρυβον, μεταπεμψάμενος ὁ Παῦλος τοὺς μαθητὰς καὶ παρακαλέσας ἀσπασάμενος, ἐξῆλθεν πορεύεσθαι εἰς Μακεδονίαν.
44020002 Διελθὼν δὲ τὰ μέρη ἐκεῖνα, καὶ παρακαλέσας αὐτοὺς λόγῳ πολλῷ, ἦλθεν εἰς τὴν Ἑλλάδα.
44020003 Ποιήσας τε μῆνας τρεῖς, γενομένης ἐπιβουλῆς αὐτῷ ὑπὸ τῶν Ἰουδαίων, μέλλοντι ἀνάγεσθαι εἰς τὴν Συρίαν, ἐγένετο γνώμης τοῦ ὑποστρέφειν διὰ Μακεδονίας.
44020004 Συνείπετο δὲ αὐτῷ Σώπατρος Πύρρου Βεροιαῖος, Θεσσαλονικέων δὲ Ἀρίσταρχος καὶ Σεκοῦνδος, καὶ Γάϊος Δερβαῖος, καὶ Τιμόθεος, Ἀσιανοὶ δὲ Τυχικὸς καὶ Τρόφιμος.
44020005 Οὗτοι δὲ προσελθόντες ἔμενον ἡμᾶς ἐν Τρῳάδι.
44020006 Ἡμεῖς δὲ ἐξεπλεύσαμεν μετὰ τὰς ἡμέρας τῶν Ἀζύμων ἀπὸ Φιλίππων, καὶ ἤλθομεν πρὸς αὐτοὺς εἰς τὴν Τρῳάδα ἄχρι ἡμερῶν πέντε, ὅπου διετρίψαμεν ἡμέρας ἑπτά.
44020007 Ἐν δὲ τῇ μιᾷ τῶν σαββάτων, συνηγμένων ἡμῶν κλάσαι ἄρτον, ὁ Παῦλος διελέγετο αὐτοῖς, μέλλων ἐξιέναι τῇ ἐπαύριον, παρέτεινέν τε τὸν λόγον μέχρι μεσονυκτίου.
44020008 Ἦσαν δὲ λαμπάδες ἱκαναὶ ἐν τῷ ὑπερῴῳ, οὗ ἦμεν συνηγμένοι.
44020009 Καθεζόμενος δέ τις νεανίας ὀνόματι Εὔτυχος ἐπὶ τῆς θυρίδος, καταφερόμενος ὕπνῳ βαθεῖ, διαλεγομένου τοῦ Παύλου ἐπὶ πλεῖον, κατενεχθεὶς ἀπὸ τοῦ ὕπνου, ἔπεσεν ἀπὸ τοῦ τριστέγου κάτω, καὶ ἤρθη νεκρός.
44020010 Καταβὰς δὲ, ὁ Παῦλος ἐπέπεσεν αὐτῷ καὶ συμπεριλαβὼν εἶπεν, “Μὴ θορυβεῖσθε, ἡ γὰρ ψυχὴ αὐτοῦ ἐν αὐτῷ ἐστιν.”
44020011 Ἀναβὰς δὲ, καὶ κλάσας τὸν ἄρτον καὶ γευσάμενος, ἐφʼ ἱκανόν τε ὁμιλήσας ἄχρι αὐγῆς, οὕτως ἐξῆλθεν.
44020012 Ἤγαγον δὲ τὸν παῖδα ζῶντα, καὶ παρεκλήθησαν οὐ μετρίως.
44020013 Ἡμεῖς δὲ, προσελθόντες ἐπὶ τὸ πλοῖον, ἀνήχθημεν ἐπὶ τὴν Ἆσσον, ἐκεῖθεν μέλλοντες ἀναλαμβάνειν τὸν Παῦλον, οὕτως γὰρ διατεταγμένος, ἦν μέλλων αὐτὸς πεζεύειν.
44020014 Ὡς δὲ συνέβαλλεν ἡμῖν εἰς τὴν Ἆσσον, ἀναλαβόντες αὐτὸν, ἤλθομεν εἰς Μιτυλήνην.
44020015 Κἀκεῖθεν ἀποπλεύσαντες, τῇ ἐπιούσῃ κατηντήσαμεν ἄντικρυς Χίου, τῇ δὲ ἑτέρᾳ παρεβάλομεν εἰς Σάμον, τῇ δὲ ἐχομένῃ ἤλθομεν εἰς Μίλητον.
44020016 Κεκρίκει γὰρ ὁ Παῦλος παραπλεῦσαι τὴν Ἔφεσον, ὅπως μὴ γένηται αὐτῷ χρονοτριβῆσαι ἐν τῇ Ἀσίᾳ, ἔσπευδεν γὰρ εἰ δυνατὸν εἴη αὐτῷ, τὴν ἡμέραν τῆς Πεντηκοστῆς γενέσθαι εἰς Ἱεροσόλυμα.
44020017 Ἀπὸ δὲ τῆς Μιλήτου, πέμψας εἰς Ἔφεσον, μετεκαλέσατο τοὺς πρεσβυτέρους τῆς ἐκκλησίας.
44020018 Ὡς δὲ παρεγένοντο πρὸς αὐτὸν, εἶπεν αὐτοῖς, “Ὑμεῖς ἐπίστασθε ἀπὸ πρώτης ἡμέρας ἀφʼ ἧς ἐπέβην εἰς τὴν Ἀσίαν, πῶς μεθʼ ὑμῶν τὸν πάντα χρόνον ἐγενόμην,
44020019 δουλεύων τῷ ˚Κυρίῳ μετὰ πάσης ταπεινοφροσύνης, καὶ δακρύων, καὶ πειρασμῶν, τῶν συμβάντων μοι ἐν ταῖς ἐπιβουλαῖς τῶν Ἰουδαίων·
44020020 ὡς οὐδὲν ὑπεστειλάμην τῶν συμφερόντων τοῦ μὴ ἀναγγεῖλαι ὑμῖν, καὶ διδάξαι ὑμᾶς δημοσίᾳ καὶ κατʼ οἴκους,
44020021 διαμαρτυρόμενος Ἰουδαίοις τε καὶ Ἕλλησιν, τὴν εἰς ˚Θεὸν μετάνοιαν καὶ πίστιν εἰς τὸν ˚Κύριον ἡμῶν, ˚Ἰησοῦν. ˚Χριστόν.
44020022 Καὶ νῦν ἰδοὺ, δεδεμένος ἐγὼ τῷ ˚Πνεύματι, πορεύομαι εἰς Ἰερουσαλήμ, τὰ ἐν αὐτῇ συναντήσοντά ἐμοὶ μὴ εἰδώς,
44020023 πλὴν ὅτι τὸ ˚Πνεῦμα τὸ Ἅγιον κατὰ πόλιν διαμαρτύρεταί μοι, λέγον ὅτι δεσμὰ καὶ θλίψεις με μένουσιν.
44020024 Ἀλλʼ οὐδενὸς λόγου ποιοῦμαι τὴν ψυχὴν τιμίαν ἐμαυτῷ, ὡς τελειώσω τὸν δρόμον μου, καὶ τὴν διακονίαν ἣν ἔλαβον παρὰ τοῦ ˚Κυρίου ˚Ἰησοῦ, διαμαρτύρασθαι τὸ εὐαγγέλιον τῆς χάριτος τοῦ ˚Θεοῦ.
44020025 Καὶ νῦν ἰδοὺ, ἐγὼ οἶδα ὅτι οὐκέτι ὄψεσθε τὸ πρόσωπόν μου ὑμεῖς πάντες, ἐν οἷς διῆλθον κηρύσσων τὴν βασιλείαν.
44020026 Διότι μαρτύρομαι ὑμῖν ἐν τῇ σήμερον ἡμέρᾳ ὅτι καθαρός εἰμι ἀπὸ τοῦ αἵματος πάντων,
44020027 οὐ γὰρ ὑπεστειλάμην τοῦ μὴ ἀναγγεῖλαι πᾶσαν τὴν βουλὴν τοῦ ˚Θεοῦ ὑμῖν.
44020028 Προσέχετε ἑαυτοῖς καὶ παντὶ τῷ ποιμνίῳ, ἐν ᾧ ὑμᾶς τὸ ˚Πνεῦμα τὸ Ἅγιον ἔθετο ἐπισκόπους, ποιμαίνειν τὴν ἐκκλησίαν τοῦ ˚Θεοῦ, ἣν περιεποιήσατο διὰ τοῦ αἵματος τοῦ ἰδίου.
44020029 Ἐγὼ οἶδα ὅτι εἰσελεύσονται μετὰ τὴν ἄφιξίν μου λύκοι βαρεῖς εἰς ὑμᾶς, μὴ φειδόμενοι τοῦ ποιμνίου,
44020030 καὶ ἐξ ὑμῶν αὐτῶν ἀναστήσονται ἄνδρες λαλοῦντες διεστραμμένα, τοῦ ἀποσπᾶν τοὺς μαθητὰς ὀπίσω ἑαυτῶν.
44020031 Διὸ γρηγορεῖτε, μνημονεύοντες ὅτι τριετίαν νύκτα καὶ ἡμέραν, οὐκ ἐπαυσάμην μετὰ δακρύων νουθετῶν ἕνα ἕκαστον.
44020032 Καὶ τὰ νῦν παρατίθεμαι ὑμᾶς τῷ ˚Θεῷ, καὶ τῷ λόγῳ τῆς χάριτος αὐτοῦ, τῷ δυναμένῳ οἰκοδομῆσαι καὶ δοῦναι τὴν κληρονομίαν ἐν τοῖς ἡγιασμένοις πᾶσιν.
44020033 Ἀργυρίου, ἢ χρυσίου, ἢ ἱματισμοῦ, οὐδενὸς ἐπεθύμησα.
44020034 Αὐτοὶ γινώσκετε ὅτι ταῖς χρείαις μου καὶ τοῖς οὖσιν μετʼ ἐμοῦ ὑπηρέτησαν αἱ χεῖρες αὗται.
44020035 Πάντα ὑπέδειξα ὑμῖν ὅτι οὕτως κοπιῶντας δεῖ ἀντιλαμβάνεσθαι τῶν ἀσθενούντων, μνημονεύειν τε τῶν λόγων τοῦ ˚Κυρίου ˚Ἰησοῦ ὅτι αὐτὸς εἶπεν, ‘Μακάριόν ἐστιν μᾶλλον, διδόναι ἢ λαμβάνειν.’”
44020036 Καὶ ταῦτα εἰπὼν, θεὶς τὰ γόνατα αὐτοῦ, σὺν πᾶσιν αὐτοῖς προσηύξατο.
44020037 Ἱκανὸς δὲ κλαυθμὸς ἐγένετο πάντων, καὶ ἐπιπεσόντες ἐπὶ τὸν τράχηλον τοῦ Παύλου, κατεφίλουν αὐτόν,
44020038 ὀδυνώμενοι μάλιστα ἐπὶ τῷ λόγῳ ᾧ εἰρήκει ὅτι οὐκέτι μέλλουσιν τὸ πρόσωπον αὐτοῦ θεωρεῖν. Προέπεμπον δὲ αὐτὸν εἰς τὸ πλοῖον.
44021001 Ὡς δὲ ἐγένετο ἀναχθῆναι ἡμᾶς, ἀποσπασθέντας ἀπʼ αὐτῶν, εὐθυδρομήσαντες ἤλθομεν εἰς τὴν Κῶ, τῇ δὲ ἑξῆς εἰς τὴν Ῥόδον, κἀκεῖθεν εἰς Πάταρα.
44021002 Καὶ εὑρόντες πλοῖον διαπερῶν εἰς Φοινίκην, ἐπιβάντες ἀνήχθημεν.
44021003 Ἀναφάναντες δὲ τὴν Κύπρον, καὶ καταλιπόντες αὐτὴν εὐώνυμον, ἐπλέομεν εἰς Συρίαν καὶ κατήλθομεν εἰς Τύρον, ἐκεῖσε γὰρ τὸ πλοῖον ἦν ἀποφορτιζόμενον τὸν γόμον.
44021004 Ἀνευρόντες δὲ τοὺς μαθητὰς, ἐπεμείναμεν αὐτοῦ ἡμέρας ἑπτά, οἵτινες τῷ Παύλῳ ἔλεγον διὰ τοῦ ˚Πνεύματος μὴ ἐπιβαίνειν εἰς Ἱεροσόλυμα.
44021005 Ὅτε δὲ ἐγένετο ἡμᾶς ἐξαρτίσαι τὰς ἡμέρας, ἐξελθόντες ἐπορευόμεθα, προπεμπόντων ἡμᾶς πάντων σὺν γυναιξὶ καὶ τέκνοις, ἕως ἔξω τῆς πόλεως, καὶ θέντες τὰ γόνατα ἐπὶ τὸν αἰγιαλὸν προσευξάμενοι,
44021006 ἀπησπασάμεθα ἀλλήλους καὶ ἀνέβημεν εἰς τὸ πλοῖον, ἐκεῖνοι δὲ ὑπέστρεψαν εἰς τὰ ἴδια.
44021007 Ἡμεῖς δὲ, τὸν πλοῦν διανύσαντες ἀπὸ Τύρου, κατηντήσαμεν εἰς Πτολεμαΐδα, καὶ ἀσπασάμενοι τοὺς ἀδελφοὺς, ἐμείναμεν ἡμέραν μίαν παρʼ αὐτοῖς.
44021008 Τῇ δὲ ἐπαύριον, ἐξελθόντες ἤλθομεν εἰς Καισάρειαν, καὶ εἰσελθόντες εἰς τὸν οἶκον Φιλίππου, τοῦ εὐαγγελιστοῦ, ὄντος ἐκ τῶν ἑπτὰ, ἐμείναμεν παρʼ αὐτῷ.
44021009 Τούτῳ δὲ ἦσαν θυγατέρες τέσσαρες παρθένοι, προφητεύουσαι.
44021010 Ἐπιμενόντων δὲ ἡμέρας πλείους, κατῆλθέν τις ἀπὸ τῆς Ἰουδαίας προφήτης ὀνόματι Ἅγαβος,
44021011 καὶ ἐλθὼν πρὸς ἡμᾶς, καὶ ἄρας τὴν ζώνην τοῦ Παύλου, δήσας ἑαυτοῦ τοὺς πόδας καὶ τὰς χεῖρας, εἶπεν, “Τάδε λέγει τὸ ˚Πνεῦμα τὸ Ἅγιον: ‘Τὸν ἄνδρα οὗ ἐστιν ἡ ζώνη αὕτη, οὕτως δήσουσιν ἐν Ἰερουσαλὴμ οἱ Ἰουδαῖοι, καὶ παραδώσουσιν εἰς χεῖρας ἐθνῶν.’”
44021012 Ὡς δὲ ἠκούσαμεν ταῦτα, παρεκαλοῦμεν ἡμεῖς τε καὶ οἱ ἐντόπιοι, τοῦ μὴ ἀναβαίνειν αὐτὸν εἰς Ἰερουσαλήμ.
44021013 Τότε ἀπεκρίθη ὁ Παῦλος, “Τί ποιεῖτε, κλαίοντες καὶ συνθρύπτοντές μου τὴν καρδίαν; Ἐγὼ γὰρ οὐ μόνον δεθῆναι, ἀλλὰ καὶ ἀποθανεῖν εἰς Ἰερουσαλὴμ, ἑτοίμως ἔχω, ὑπὲρ τοῦ ὀνόματος τοῦ ˚Κυρίου ˚Ἰησοῦ.”
44021014 Μὴ πειθομένου δὲ αὐτοῦ, ἡσυχάσαμεν εἰπόντες, “Τοῦ ˚Κυρίου τὸ θέλημα γινέσθω.”
44021015 Μετὰ δὲ τὰς ἡμέρας ταύτας, ἐπισκευασάμενοι, ἀνεβαίνομεν εἰς Ἱεροσόλυμα.
44021016 Συνῆλθον δὲ καὶ τῶν μαθητῶν ἀπὸ Καισαρείας σὺν ἡμῖν, ἄγοντες παρʼ ᾧ ξενισθῶμεν Μνάσωνί, τινι Κυπρίῳ ἀρχαίῳ μαθητῇ.
44021017 Γενομένων δὲ ἡμῶν εἰς Ἱεροσόλυμα, ἀσμένως ἀπεδέξαντο ἡμᾶς οἱ ἀδελφοί.
44021018 Τῇ δὲ ἐπιούσῃ, εἰσῄει ὁ Παῦλος σὺν ἡμῖν πρὸς Ἰάκωβον, πάντες τε παρεγένοντο οἱ πρεσβύτεροι.
44021019 Καὶ ἀσπασάμενος αὐτοὺς, ἐξηγεῖτο καθʼ ἓν ἕκαστον, ὧν ἐποίησεν ὁ ˚Θεὸς ἐν τοῖς ἔθνεσιν διὰ τῆς διακονίας αὐτοῦ.
44021020 Οἱ δὲ ἀκούσαντες ἐδόξαζον τὸν ˚Θεόν, εἶπόν τε αὐτῷ, “Θεωρεῖς, ἀδελφέ, πόσαι μυριάδες εἰσὶν ἐν τοῖς Ἰουδαίοις τῶν πεπιστευκότων, καὶ πάντες ζηλωταὶ τοῦ νόμου ὑπάρχουσιν.
44021021 Κατηχήθησαν δὲ περὶ σοῦ, ὅτι ἀποστασίαν διδάσκεις ἀπὸ Μωϋσέως τοὺς κατὰ τὰ ἔθνη πάντας Ἰουδαίους, λέγων μὴ περιτέμνειν αὐτοὺς τὰ τέκνα, μηδὲ τοῖς ἔθεσιν περιπατεῖν.
44021022 Τί οὖν ἐστιν; Πάντως ἀκούσονται ὅτι ἐλήλυθας.
44021023 Τοῦτο οὖν ποίησον, ὅ σοι λέγομεν. εἰσὶν ἡμῖν ἄνδρες τέσσαρες, εὐχὴν ἔχοντες ἀφʼ ἑαυτῶν.
44021024 Τούτους παραλαβὼν, ἁγνίσθητι σὺν αὐτοῖς καὶ δαπάνησον ἐπʼ αὐτοῖς, ἵνα ξυρήσονται τὴν κεφαλήν, καὶ γνώσονται πάντες ὅτι ὧν κατήχηνται περὶ σοῦ οὐδέν ἐστιν, ἀλλὰ στοιχεῖς καὶ αὐτὸς, φυλάσσων τὸν νόμον.
44021025 Περὶ δὲ τῶν πεπιστευκότων ἐθνῶν, ἡμεῖς ἐπεστείλαμεν κρίναντες φυλάσσεσθαι αὐτοὺς, τό τε εἰδωλόθυτον, καὶ αἷμα, καὶ πνικτὸν, καὶ πορνείαν.”
44021026 Τότε ὁ Παῦλος παραλαβὼν τοὺς ἄνδρας, τῇ ἐχομένῃ ἡμέρᾳ σὺν αὐτοῖς ἁγνισθεὶς, εἰσῄει εἰς τὸ ἱερόν, διαγγέλλων τὴν ἐκπλήρωσιν τῶν ἡμερῶν τοῦ ἁγνισμοῦ, ἕως οὗ προσηνέχθη ὑπὲρ ἑνὸς ἑκάστου αὐτῶν ἡ προσφορά.
44021027 Ὡς δὲ ἔμελλον αἱ ἑπτὰ ἡμέραι συντελεῖσθαι, οἱ ἀπὸ τῆς Ἀσίας Ἰουδαῖοι, θεασάμενοι αὐτὸν ἐν τῷ ἱερῷ, συνέχεον πάντα τὸν ὄχλον, καὶ ἐπέβαλον ἐπʼ αὐτὸν τὰς χεῖρας
44021028 κράζοντες, “Ἄνδρες, Ἰσραηλῖται, βοηθεῖτε! Οὗτός ἐστιν ὁ ἄνθρωπος, ὁ κατὰ τοῦ λαοῦ, καὶ τοῦ νόμου, καὶ τοῦ τόπου τούτου πάντας πανταχῇ διδάσκων, ἔτι τε καὶ Ἕλληνας εἰσήγαγεν εἰς τὸ ἱερὸν, καὶ κεκοίνωκεν τὸν ἅγιον τόπον τοῦτον.”
44021029 Ἦσαν γὰρ προεωρακότες Τρόφιμον τὸν Ἐφέσιον ἐν τῇ πόλει σὺν αὐτῷ, ὃν ἐνόμιζον ὅτι εἰς τὸ ἱερὸν εἰσήγαγεν ὁ Παῦλος.
44021030 Ἐκινήθη τε ἡ πόλις ὅλη, καὶ ἐγένετο συνδρομὴ τοῦ λαοῦ, καὶ ἐπιλαβόμενοι τοῦ Παύλου, εἷλκον αὐτὸν ἔξω τοῦ ἱεροῦ, καὶ εὐθέως ἐκλείσθησαν αἱ θύραι.
44021031 Ζητούντων τε αὐτὸν ἀποκτεῖναι, ἀνέβη φάσις τῷ χιλιάρχῳ τῆς σπείρης, ὅτι ὅλη συγχύννεται Ἰερουσαλήμ,
44021032 ὃς ἐξαυτῆς παραλαβὼν στρατιώτας καὶ ἑκατοντάρχας, κατέδραμεν ἐπʼ αὐτούς, οἱ δὲ ἰδόντες τὸν χιλίαρχον καὶ τοὺς στρατιώτας, ἐπαύσαντο τύπτοντες τὸν Παῦλον.
44021033 Τότε ἐγγίσας, ὁ χιλίαρχος ἐπελάβετο αὐτοῦ, καὶ ἐκέλευσε δεθῆναι ἁλύσεσι δυσί, καὶ ἐπυνθάνετο τίς εἴη καὶ τί ἐστιν πεποιηκώς.
44021034 Ἄλλοι δὲ, ἄλλο τι ἐπεφώνουν ἐν τῷ ὄχλῳ. Μὴ δυναμένου δὲ αὐτοῦ γνῶναι τὸ ἀσφαλὲς διὰ τὸν θόρυβον, ἐκέλευσεν ἄγεσθαι αὐτὸν εἰς τὴν παρεμβολήν.
44021035 Ὅτε δὲ ἐγένετο ἐπὶ τοὺς ἀναβαθμούς, συνέβη βαστάζεσθαι αὐτὸν ὑπὸ τῶν στρατιωτῶν, διὰ τὴν βίαν τοῦ ὄχλου.
44021036 Ἠκολούθει γὰρ τὸ πλῆθος τοῦ λαοῦ κράζοντες, “Αἶρε αὐτόν.”
44021037 Μέλλων τε εἰσάγεσθαι εἰς τὴν παρεμβολὴν, ὁ Παῦλος λέγει τῷ χιλιάρχῳ, “Εἰ ἔξεστίν μοι εἰπεῖν τι πρὸς σέ;” Ὁ δὲ ἔφη, “Ἑλληνιστὶ γινώσκεις;
44021038 Οὐκ ἄρα σὺ εἶ ὁ Αἰγύπτιος, ὁ πρὸ τούτων τῶν ἡμερῶν, ἀναστατώσας καὶ ἐξαγαγὼν εἰς τὴν ἔρημον τοὺς τετρακισχιλίους ἄνδρας τῶν σικαρίων;”
44021039 Εἶπεν δὲ ὁ Παῦλος, “Ἐγὼ ἄνθρωπος μέν εἰμι Ἰουδαῖος, Ταρσεὺς, τῆς Κιλικίας, οὐκ ἀσήμου πόλεως πολίτης. Δέομαι δέ σου, ἐπίτρεψόν μοι λαλῆσαι πρὸς τὸν λαόν.”
44021040 Ἐπιτρέψαντος δὲ αὐτοῦ, ὁ Παῦλος ἑστὼς ἐπὶ τῶν ἀναβαθμῶν, κατέσεισε τῇ χειρὶ τῷ λαῷ, πολλῆς δὲ σιγῆς γενομένης, προσεφώνησεν τῇ Ἑβραΐδι διαλέκτῳ λέγων,
44022001 “Ἄνδρες, ἀδελφοὶ, καὶ πατέρες, ἀκούσατέ μου τῆς πρὸς ὑμᾶς νυνὶ ἀπολογίας.”
44022002 ( Ἀκούσαντες δὲ ὅτι τῇ Ἑβραΐδι διαλέκτῳ προσεφώνει αὐτοῖς, μᾶλλον παρέσχον ἡσυχίαν. ) Καὶ φησίν:
44022003 “Ἐγώ εἰμι ἀνὴρ Ἰουδαῖος, γεγεννημένος ἐν Ταρσῷ τῆς Κιλικίας, ἀνατεθραμμένος δὲ ἐν τῇ πόλει ταύτῃ, παρὰ τοὺς πόδας Γαμαλιήλ, πεπαιδευμένος κατὰ ἀκρίβειαν τοῦ πατρῴου νόμου, ζηλωτὴς ὑπάρχων τοῦ ˚Θεοῦ, καθὼς πάντες ὑμεῖς ἐστε σήμερον,
44022004 ὃς ταύτην τὴν Ὁδὸν ἐδίωξα ἄχρι θανάτου, δεσμεύων καὶ παραδιδοὺς εἰς φυλακὰς, ἄνδρας τε καὶ γυναῖκας,
44022005 ὡς καὶ ὁ ἀρχιερεὺς μαρτυρεῖ μοι καὶ πᾶν τὸ πρεσβυτέριον· παρʼ ὧν καὶ ἐπιστολὰς δεξάμενος πρὸς τοὺς ἀδελφοὺς, εἰς Δαμασκὸν ἐπορευόμην, ἄξων καὶ τοὺς ἐκεῖσε ὄντας, δεδεμένους εἰς Ἰερουσαλὴμ, ἵνα τιμωρηθῶσιν.
44022006 Ἐγένετο δέ μοι πορευομένῳ, καὶ ἐγγίζοντι τῇ Δαμασκῷ περὶ μεσημβρίαν, ἐξαίφνης ἐκ τοῦ οὐρανοῦ περιαστράψαι φῶς ἱκανὸν περὶ ἐμέ.
44022007 Ἔπεσά τε εἰς τὸ ἔδαφος, καὶ ἤκουσα φωνῆς λεγούσης μοι, ‘Σαοὺλ, Σαούλ, τί με διώκεις;’
44022008 Ἐγὼ δὲ ἀπεκρίθην, ‘Τίς εἶ, κύριε;’ Εἶπέν τε πρὸς ἐμέ, ‘Ἐγώ εἰμι ˚Ἰησοῦς ὁ Ναζωραῖος, ὃν σὺ διώκεις.’
44022009 Οἱ δὲ σὺν ἐμοὶ ὄντες, τὸ μὲν φῶς ἐθεάσαντο, τὴν δὲ φωνὴν οὐκ ἤκουσαν τοῦ λαλοῦντός μοι.
44022010 Εἶπον δέ, ‘Τί ποιήσω, ˚Κύριε;’ Ὁ δὲ ˚Κύριος εἶπεν πρός με, ‘Ἀναστὰς πορεύου εἰς Δαμασκόν, κἀκεῖ σοι λαληθήσεται περὶ πάντων ὧν τέτακταί σοι ποιῆσαι.’
44022011 Ὡς δὲ οὐκ ἐνέβλεπον ἀπὸ τῆς δόξης τοῦ φωτὸς ἐκείνου, χειραγωγούμενος ὑπὸ τῶν συνόντων μοι, ἦλθον εἰς Δαμασκόν.
44022012 Ἁνανίας δέ τις ἀνὴρ εὐλαβὴς κατὰ τὸν νόμον, μαρτυρούμενος ὑπὸ πάντων τῶν κατοικούντων Ἰουδαίων,
44022013 ἐλθὼν πρὸς ἐμὲ καὶ ἐπιστὰς, εἶπέν μοι, ‘Σαοὺλ, ἀδελφέ, ἀνάβλεψον.’ Κἀγὼ αὐτῇ τῇ ὥρᾳ ἀνέβλεψα εἰς αὐτόν.
44022014 Ὁ δὲ εἶπεν, ‘Ὁ ˚Θεὸς τῶν πατέρων ἡμῶν προεχειρίσατό σε γνῶναι τὸ θέλημα αὐτοῦ, καὶ ἰδεῖν τὸν Δίκαιον, καὶ ἀκοῦσαι φωνὴν ἐκ τοῦ στόματος αὐτοῦ.
44022015 Ὅτι ἔσῃ μάρτυς αὐτῷ πρὸς πάντας ἀνθρώπους, ὧν ἑώρακας καὶ ἤκουσας.
44022016 Καὶ νῦν τί μέλλεις; Ἀναστὰς, βάπτισαι καὶ ἀπόλουσαι τὰς ἁμαρτίας σου, ἐπικαλεσάμενος τὸ ὄνομα αὐτοῦ.’
44022017 Ἐγένετο δέ μοι, ὑποστρέψαντι εἰς Ἰερουσαλὴμ, καὶ προσευχομένου μου ἐν τῷ ἱερῷ, γενέσθαι με ἐν ἐκστάσει,
44022018 καὶ ἰδεῖν αὐτὸν λέγοντά μοι, ‘Σπεῦσον καὶ ἔξελθε ἐν τάχει ἐξ Ἰερουσαλήμ, διότι οὐ παραδέξονταί σου μαρτυρίαν περὶ ἐμοῦ.’
44022019 Κἀγὼ εἶπον, ‘˚Κύριε, αὐτοὶ ἐπίστανται ὅτι ἐγὼ ἤμην φυλακίζων καὶ δέρων κατὰ τὰς συναγωγὰς, τοὺς πιστεύοντας ἐπὶ σέ.
44022020 Καὶ ὅτε ἐξεχύννετο τὸ αἷμα Στεφάνου τοῦ μάρτυρός σου, καὶ αὐτὸς ἤμην ἐφεστὼς, καὶ συνευδοκῶν, καὶ φυλάσσων τὰ ἱμάτια τῶν ἀναιρούντων αὐτόν.’
44022021 Καὶ εἶπεν πρός με, ‘Πορεύου, ὅτι ἐγὼ εἰς ἔθνη μακρὰν ἐξαποστελῶ σε.’”
44022022 Ἤκουον δὲ αὐτοῦ ἄχρι τούτου τοῦ λόγου, καὶ ἐπῆραν τὴν φωνὴν αὐτῶν λέγοντες, “Αἶρε ἀπὸ τῆς γῆς τὸν τοιοῦτον, οὐ γὰρ καθῆκεν αὐτὸν ζῆν!”
44022023 Κραυγαζόντων τε αὐτῶν, καὶ ῥιπτούντων τὰ ἱμάτια, καὶ κονιορτὸν βαλλόντων εἰς τὸν ἀέρα,
44022024 ἐκέλευσεν ὁ χιλίαρχος εἰσάγεσθαι αὐτὸν εἰς τὴν παρεμβολήν, εἴπας μάστιξιν ἀνετάζεσθαι αὐτὸν, ἵνα ἐπιγνῷ διʼ ἣν αἰτίαν οὕτως ἐπεφώνουν αὐτῷ.
44022025 Ὡς δὲ προέτειναν αὐτὸν τοῖς ἱμᾶσιν, εἶπεν πρὸς τὸν ἑστῶτα ἑκατόνταρχον ὁ Παῦλος, “Εἰ ἄνθρωπον Ῥωμαῖον καὶ ἀκατάκριτον, ἔξεστιν ὑμῖν μαστίζειν;”
44022026 Ἀκούσας δὲ ὁ ἑκατοντάρχης, προσελθὼν τῷ χιλιάρχῳ, ἀπήγγειλεν λέγων, “Τί μέλλεις ποιεῖν; Ὁ γὰρ ἄνθρωπος οὗτος Ῥωμαῖός ἐστιν.”
44022027 Προσελθὼν δὲ, ὁ χιλίαρχος εἶπεν αὐτῷ, “Λέγε μοι, σὺ Ῥωμαῖος εἶ;” Ὁ δὲ ἔφη, “Ναί.”
44022028 Ἀπεκρίθη δὲ ὁ χιλίαρχος, “Ἐγὼ πολλοῦ κεφαλαίου τὴν πολιτείαν ταύτην ἐκτησάμην.” Ὁ δὲ Παῦλος ἔφη, “Ἐγὼ δὲ καὶ γεγέννημαι.”
44022029 Εὐθέως οὖν ἀπέστησαν ἀπʼ αὐτοῦ οἱ μέλλοντες αὐτὸν ἀνετάζειν, καὶ ὁ χιλίαρχος δὲ ἐφοβήθη, ἐπιγνοὺς ὅτι Ῥωμαῖός ἐστιν, καὶ ὅτι αὐτὸν ἦν δεδεκώς.
44022030 Τῇ δὲ ἐπαύριον, βουλόμενος γνῶναι τὸ ἀσφαλὲς, τὸ τί κατηγορεῖται ὑπὸ τῶν Ἰουδαίων, ἔλυσεν αὐτόν, καὶ ἐκέλευσεν συνελθεῖν τοὺς ἀρχιερεῖς καὶ πᾶν τὸ Συνέδριον, καὶ καταγαγὼν τὸν Παῦλον, ἔστησεν εἰς αὐτούς.
44023001 Ἀτενίσας δὲ τῷ Συνεδρίῳ ὁ Παῦλος εἶπεν, “Ἄνδρες, ἀδελφοί, ἐγὼ πάσῃ συνειδήσει ἀγαθῇ πεπολίτευμαι τῷ ˚Θεῷ ἄχρι ταύτης τῆς ἡμέρας.”
44023002 Ὁ δὲ ἀρχιερεὺς Ἁνανίας ἐπέταξεν τοῖς παρεστῶσιν αὐτῷ, τύπτειν αὐτοῦ τὸ στόμα.
44023003 Τότε ὁ Παῦλος πρὸς αὐτὸν εἶπεν, “Τύπτειν σε μέλλει ὁ ˚Θεός, τοῖχε κεκονιαμένε! Καὶ σὺ κάθῃ κρίνων με κατὰ τὸν νόμον, καὶ παρανομῶν κελεύεις με τύπτεσθαι;”
44023004 Οἱ δὲ παρεστῶτες εἶπαν, “Τὸν ἀρχιερέα τοῦ ˚Θεοῦ λοιδορεῖς;”
44023005 Ἔφη τε ὁ Παῦλος, “Οὐκ ᾔδειν, ἀδελφοί, ὅτι ἐστὶν ἀρχιερεύς· γέγραπται γὰρ, ὅτι ‘Ἄρχοντα τοῦ λαοῦ σου, οὐκ ἐρεῖς κακῶς.’”
44023006 Γνοὺς δὲ ὁ Παῦλος ὅτι τὸ ἓν μέρος ἐστὶν Σαδδουκαίων, τὸ δὲ ἕτερον Φαρισαίων, ἔκραζεν ἐν τῷ Συνεδρίῳ, “Ἄνδρες, ἀδελφοί, ἐγὼ Φαρισαῖός εἰμι, υἱὸς Φαρισαίων· περὶ ἐλπίδος καὶ ἀναστάσεως νεκρῶν ἐγὼ κρίνομαι.”
44023007 Τοῦτο δὲ αὐτοῦ λαλοῦντος, ἐγένετο στάσις τῶν Φαρισαίων καὶ Σαδδουκαίων, καὶ ἐσχίσθη τὸ πλῆθος.
44023008 Σαδδουκαῖοι μὲν γὰρ λέγουσιν μὴ εἶναι ἀνάστασιν, μήτε ἄγγελον, μήτε πνεῦμα, Φαρισαῖοι δὲ ὁμολογοῦσιν τὰ ἀμφότερα.
44023009 Ἐγένετο δὲ κραυγὴ μεγάλη, καὶ ἀναστάντες τινὲς τῶν γραμματέων τοῦ μέρους τῶν Φαρισαίων, διεμάχοντο λέγοντες, “Οὐδὲν κακὸν εὑρίσκομεν ἐν τῷ ἀνθρώπῳ τούτῳ· εἰ δὲ πνεῦμα ἐλάλησεν αὐτῷ, ἢ ἄγγελος;”
44023010 Πολλῆς δὲ γινομένης στάσεως, φοβηθεὶς ὁ χιλίαρχος μὴ διασπασθῇ ὁ Παῦλος ὑπʼ αὐτῶν, ἐκέλευσεν τὸ στράτευμα καταβὰν, ἁρπάσαι αὐτὸν ἐκ μέσου αὐτῶν, ἄγειν τε εἰς τὴν παρεμβολήν.
44023011 Τῇ δὲ ἐπιούσῃ νυκτὶ, ἐπιστὰς αὐτῷ, ὁ ˚Κύριος εἶπεν, “Θάρσει, ὡς γὰρ διεμαρτύρω τὰ περὶ ἐμοῦ εἰς Ἰερουσαλὴμ, οὕτω σε δεῖ καὶ εἰς Ῥώμην μαρτυρῆσαι.”
44023012 Γενομένης δὲ ἡμέρας, ποιήσαντες συστροφὴν, οἱ Ἰουδαῖοι ἀνεθεμάτισαν ἑαυτοὺς, λέγοντες μήτε φαγεῖν μήτε πιεῖν, ἕως οὗ ἀποκτείνωσιν τὸν Παῦλον.
44023013 Ἦσαν δὲ πλείους τεσσεράκοντα οἱ ταύτην τὴν συνωμοσίαν ποιησάμενοι·
44023014 οἵτινες προσελθόντες τοῖς ἀρχιερεῦσιν καὶ τοῖς πρεσβυτέροις εἶπαν, “Ἀναθέματι ἀνεθεματίσαμεν ἑαυτοὺς, μηδενὸς γεύσασθαι ἕως οὗ ἀποκτείνωμεν τὸν Παῦλον.
44023015 Νῦν οὖν ὑμεῖς ἐμφανίσατε τῷ χιλιάρχῳ σὺν τῷ Συνεδρίῳ, ὅπως καταγάγῃ αὐτὸν εἰς ὑμᾶς, ὡς μέλλοντας διαγινώσκειν ἀκριβέστερον τὰ περὶ αὐτοῦ· ἡμεῖς δὲ πρὸ τοῦ ἐγγίσαι αὐτὸν, ἕτοιμοί ἐσμεν τοῦ ἀνελεῖν αὐτόν.”
44023016 Ἀκούσας δὲ ὁ υἱὸς τῆς ἀδελφῆς Παύλου, τὴν ἐνέδραν παραγενόμενος, καὶ εἰσελθὼν εἰς τὴν παρεμβολὴν, ἀπήγγειλεν τῷ Παύλῳ.
44023017 Προσκαλεσάμενος δὲ ὁ Παῦλος ἕνα τῶν ἑκατονταρχῶν, ἔφη, “Τὸν νεανίαν τοῦτον ἄπαγε πρὸς τὸν χιλίαρχον, ἔχει γὰρ ἀπαγγεῖλαί τι αὐτῷ.”
44023018 Ὁ μὲν οὖν παραλαβὼν αὐτὸν, ἤγαγεν πρὸς τὸν χιλίαρχον, καὶ φησίν, “Ὁ δέσμιος, Παῦλος, προσκαλεσάμενός με, ἠρώτησεν τοῦτον τὸν νεανίσκον ἀγαγεῖν πρὸς σέ, ἔχοντά τι λαλῆσαί σοι.”
44023019 Ἐπιλαβόμενος δὲ τῆς χειρὸς αὐτοῦ ὁ χιλίαρχος, καὶ ἀναχωρήσας κατʼ ἰδίαν, ἐπυνθάνετο, “Τί ἐστιν ὃ ἔχεις ἀπαγγεῖλαί μοι;”
44023020 Εἶπεν δὲ, ὅτι “Οἱ Ἰουδαῖοι συνέθεντο τοῦ ἐρωτῆσαί σε, ὅπως αὔριον τὸν Παῦλον καταγάγῃς εἰς τὸ Συνέδριον, ὡς μέλλων τι ἀκριβέστερον πυνθάνεσθαι περὶ αὐτοῦ.
44023021 Σὺ οὖν μὴ πεισθῇς αὐτοῖς, ἐνεδρεύουσιν γὰρ αὐτὸν ἐξ αὐτῶν ἄνδρες πλείους τεσσεράκοντα, οἵτινες ἀνεθεμάτισαν ἑαυτοὺς μήτε φαγεῖν μήτε πιεῖν, ἕως οὗ ἀνέλωσιν αὐτόν, καὶ νῦν εἰσιν ἕτοιμοι, προσδεχόμενοι τὴν ἀπὸ σοῦ ἐπαγγελίαν.”
44023022 Ὁ μὲν οὖν χιλίαρχος ἀπέλυσε τὸν νεανίσκον παραγγείλας, “Μηδενὶ ἐκλαλῆσαι ὅτι ταῦτα ἐνεφάνισας πρὸς ἐμέ.”
44023023 Καὶ προσκαλεσάμενός τινας δύο τῶν ἑκατονταρχῶν, εἶπεν, “Ἑτοιμάσατε στρατιώτας διακοσίους, ὅπως πορευθῶσιν ἕως Καισαρείας, καὶ ἱππεῖς ἑβδομήκοντα, καὶ δεξιολάβους διακοσίους, ἀπὸ τρίτης ὥρας τῆς νυκτός.”
44023024 Κτήνη τε παραστῆσαι, ἵνα ἐπιβιβάσαντες τὸν Παῦλον, διασώσωσι πρὸς Φήλικα τὸν ἡγεμόνα,
44023025 γράψας ἐπιστολὴν, ἔχουσαν τὸν τύπον τοῦτον:
44023026 “Κλαύδιος Λυσίας, τῷ κρατίστῳ ἡγεμόνι Φήλικι, χαίρειν.
44023027 Τὸν ἄνδρα τοῦτον συλλημφθέντα ὑπὸ τῶν Ἰουδαίων, καὶ μέλλοντα ἀναιρεῖσθαι ὑπʼ αὐτῶν, ἐπιστὰς σὺν τῷ στρατεύματι, ἐξειλάμην μαθὼν ὅτι Ῥωμαῖός ἐστιν.
44023028 Βουλόμενός τε ἐπιγνῶναι τὴν αἰτίαν διʼ ἣν ἐνεκάλουν αὐτῷ, κατήγαγον εἰς τὸ Συνέδριον αὐτῶν.
44023029 Ὃν εὗρον ἐγκαλούμενον περὶ ζητημάτων τοῦ νόμου αὐτῶν, μηδὲν δὲ ἄξιον θανάτου ἢ δεσμῶν ἔχοντα ἔγκλημα.
44023030 Μηνυθείσης δέ μοι ἐπιβουλῆς εἰς τὸν ἄνδρα ἔσεσθαι, ἐξαυτῆς ἔπεμψα πρὸς σέ, παραγγείλας καὶ τοῖς κατηγόροις λέγειν αὑτούς ἐπὶ σοῦ.”
44023031 Οἱ μὲν οὖν στρατιῶται κατὰ τὸ διατεταγμένον αὐτοῖς, ἀναλαβόντες τὸν Παῦλον, ἤγαγον διὰ νυκτὸς εἰς τὴν Ἀντιπατρίδα.
44023032 Τῇ δὲ ἐπαύριον, ἐάσαντες τοὺς ἱππεῖς ἀπέρχεσθαι σὺν αὐτῷ, ὑπέστρεψαν εἰς τὴν παρεμβολήν·
44023033 οἵτινες εἰσελθόντες εἰς τὴν Καισάρειαν, καὶ ἀναδόντες τὴν ἐπιστολὴν τῷ ἡγεμόνι, παρέστησαν καὶ τὸν Παῦλον αὐτῷ.
44023034 Ἀναγνοὺς δὲ, καὶ ἐπερωτήσας ἐκ ποίας ἐπαρχείας ἐστὶν, καὶ πυθόμενος ὅτι ἀπὸ Κιλικίας,
44023035 “Διακούσομαί σου”, ἔφη, “ὅταν καὶ οἱ κατήγοροί σου παραγένωνται”, κελεύσας ἐν τῷ πραιτωρίῳ τοῦ Ἡρῴδου, φυλάσσεσθαι αὐτόν.
44024001 Μετὰ δὲ πέντε ἡμέρας, κατέβη ὁ ἀρχιερεὺς Ἁνανίας μετὰ πρεσβυτέρων τινῶν, καὶ ῥήτορος Τερτύλλου τινός, οἵτινες ἐνεφάνισαν τῷ ἡγεμόνι κατὰ τοῦ Παύλου.
44024002 Κληθέντος δὲ αὐτοῦ, ἤρξατο κατηγορεῖν ὁ Τέρτυλλος λέγων, “Πολλῆς εἰρήνης τυγχάνοντες διὰ σοῦ, καὶ διορθωμάτων γινομένων τῷ ἔθνει τούτῳ διὰ τῆς σῆς προνοίας,
44024003 πάντῃ τε καὶ πανταχοῦ, ἀποδεχόμεθα, κράτιστε Φῆλιξ, μετὰ πάσης εὐχαριστίας.
44024004 Ἵνα δὲ μὴ ἐπὶ πλεῖόν σε ἐγκόπτω, παρακαλῶ ἀκοῦσαί σε ἡμῶν συντόμως, τῇ σῇ ἐπιεικείᾳ.
44024005 Εὑρόντες γὰρ τὸν ἄνδρα τοῦτον λοιμὸν, καὶ κινοῦντα στάσεις πᾶσι τοῖς Ἰουδαίοις τοῖς κατὰ τὴν οἰκουμένην, πρωτοστάτην τε τῆς τῶν Ναζωραίων αἱρέσεως,
44024006 ὃς καὶ τὸ ἱερὸν ἐπείρασεν βεβηλῶσαι, ὃν καὶ ἐκρατήσαμεν.
44024008 παρʼ οὗ δυνήσῃ αὐτὸς, ἀνακρίνας περὶ πάντων τούτων, ἐπιγνῶναι ὧν ἡμεῖς κατηγοροῦμεν αὐτοῦ.”
44024009 Συνεπέθεντο δὲ καὶ οἱ Ἰουδαῖοι, φάσκοντες ταῦτα οὕτως ἔχειν.
44024010 Ἀπεκρίθη τε ὁ Παῦλος, νεύσαντος αὐτῷ τοῦ ἡγεμόνος λέγειν, “Ἐκ πολλῶν ἐτῶν ὄντα σε κριτὴν τῷ ἔθνει τούτῳ ἐπιστάμενος, εὐθύμως τὰ περὶ ἐμαυτοῦ ἀπολογοῦμαι.
44024011 Δυναμένου σου ἐπιγνῶναι ὅτι οὐ πλείους εἰσίν μοι ἡμέραι δώδεκα, ἀφʼ ἧς ἀνέβην προσκυνήσων εἰς Ἰερουσαλήμ.
44024012 Καὶ οὔτε ἐν τῷ ἱερῷ εὗρόν με πρός τινα διαλεγόμενον ἢ ἐπίστασιν ποιοῦντα ὄχλου, οὔτε ἐν ταῖς συναγωγαῖς, οὔτε κατὰ τὴν πόλιν,
44024013 οὐδὲ παραστῆσαι δύνανταί σοι, περὶ ὧν νυνὶ κατηγοροῦσίν μου.
44024014 Ὁμολογῶ δὲ τοῦτό σοι ὅτι κατὰ τὴν Ὁδὸν ἣν λέγουσιν αἵρεσιν, οὕτως λατρεύω τῷ πατρῴῳ ˚Θεῷ, πιστεύων πᾶσι τοῖς κατὰ τὸν νόμον καὶ τοῖς ἐν τοῖς προφήταις γεγραμμένοις,
44024015 ἐλπίδα ἔχων εἰς τὸν ˚Θεόν, ἣν καὶ αὐτοὶ οὗτοι προσδέχονται, ἀνάστασιν μέλλειν ἔσεσθαι, δικαίων τε καὶ ἀδίκων.
44024016 Ἐν τούτῳ καὶ αὐτὸς ἀσκῶ, ἀπρόσκοπον συνείδησιν ἔχειν πρὸς τὸν ˚Θεὸν καὶ τοὺς ἀνθρώπους διὰ παντός.
44024017 Διʼ ἐτῶν δὲ πλειόνων ἐλεημοσύνας ποιήσων εἰς τὸ ἔθνος μου παρεγενόμην καὶ προσφοράς,
44024018 ἐν αἷς εὗρόν με ἡγνισμένον ἐν τῷ ἱερῷ, οὐ μετὰ ὄχλου, οὐδὲ μετὰ θορύβου.
44024019 Τινὲς δὲ ἀπὸ τῆς Ἀσίας Ἰουδαῖοι, οὓς ἔδει ἐπὶ σοῦ παρεῖναι καὶ κατηγορεῖν, εἴ τι ἔχοιεν πρὸς ἐμέ.
44024020 Ἢ αὐτοὶ οὗτοι εἰπάτωσαν, τί εὗρον ἀδίκημα, στάντος μου ἐπὶ τοῦ Συνεδρίου,
44024021 ἢ περὶ μιᾶς ταύτης φωνῆς, ἧς ἐκέκραξα ἐν αὐτοῖς ἑστὼς: ὅτι ‘Περὶ ἀναστάσεως νεκρῶν ἐγὼ κρίνομαι σήμερον ἐφʼ ὑμῶν.’”
44024022 Ἀνεβάλετο δὲ αὐτοὺς ὁ Φῆλιξ, ἀκριβέστερον εἰδὼς τὰ περὶ τῆς Ὁδοῦ, εἴπας, “Ὅταν Λυσίας ὁ χιλίαρχος καταβῇ, διαγνώσομαι τὰ καθʼ ὑμᾶς”·
44024023 διαταξάμενος τῷ ἑκατοντάρχῃ τηρεῖσθαι αὐτὸν, ἔχειν τε ἄνεσιν, καὶ μηδένα κωλύειν τῶν ἰδίων αὐτοῦ ὑπηρετεῖν αὐτῷ.
44024024 Μετὰ δὲ ἡμέρας τινὰς, παραγενόμενος ὁ Φῆλιξ σὺν Δρουσίλλῃ, τῇ ἰδίᾳ γυναικὶ οὔσῃ Ἰουδαίᾳ, μετεπέμψατο τὸν Παῦλον, καὶ ἤκουσεν αὐτοῦ περὶ τῆς εἰς ˚Χριστὸν πίστεως.
44024025 Διαλεγομένου δὲ αὐτοῦ περὶ δικαιοσύνης, καὶ ἐγκρατείας, καὶ τοῦ κρίματος τοῦ μέλλοντος, ἔμφοβος γενόμενος, ὁ Φῆλιξ ἀπεκρίθη, “Τὸ νῦν ἔχον πορεύου, καιρὸν δὲ μεταλαβὼν, μετακαλέσομαί σε.”
44024026 Ἅμα καὶ ἐλπίζων ὅτι χρήματα δοθήσεται αὐτῷ ὑπὸ τοῦ Παύλου, διὸ καὶ πυκνότερον αὐτὸν μεταπεμπόμενος, ὡμίλει αὐτῷ.
44024027 Διετίας δὲ πληρωθείσης, ἔλαβεν διάδοχον ὁ Φῆλιξ Πόρκιον Φῆστον· θέλων τε χάριτα καταθέσθαι τοῖς Ἰουδαίοις, ὁ Φῆλιξ κατέλιπεν τὸν Παῦλον δεδεμένον.
44025001 Φῆστος οὖν ἐπιβὰς τῇ ἐπαρχείᾳ, μετὰ τρεῖς ἡμέρας ἀνέβη εἰς Ἱεροσόλυμα ἀπὸ Καισαρείας.
44025002 Ἐνεφάνισάν τε αὐτῷ οἱ ἀρχιερεῖς καὶ οἱ πρῶτοι τῶν Ἰουδαίων κατὰ τοῦ Παύλου, καὶ παρεκάλουν αὐτὸν,
44025003 αἰτούμενοι χάριν κατʼ αὐτοῦ, ὅπως μεταπέμψηται αὐτὸν εἰς Ἰερουσαλήμ ( ἐνέδραν ποιοῦντες ἀνελεῖν αὐτὸν κατὰ τὴν ὁδόν ).
44025004 Ὁ μὲν οὖν Φῆστος ἀπεκρίθη, τηρεῖσθαι τὸν Παῦλον εἰς Καισάρειαν, ἑαυτὸν δὲ μέλλειν ἐν τάχει ἐκπορεύεσθαι.
44025005 “Οἱ οὖν ἐν ὑμῖν”, φησίν, “δυνατοὶ συγκαταβάντες, εἴ τί ἐστιν ἐν τῷ ἀνδρὶ ἄτοπον, κατηγορείτωσαν αὐτοῦ.”
44025006 Διατρίψας δὲ ἐν αὐτοῖς ἡμέρας, οὐ πλείους ὀκτὼ ἢ δέκα, καταβὰς εἰς Καισάρειαν, τῇ ἐπαύριον καθίσας ἐπὶ τοῦ βήματος, ἐκέλευσεν τὸν Παῦλον ἀχθῆναι.
44025007 Παραγενομένου δὲ αὐτοῦ, περιέστησαν αὐτὸν οἱ ἀπὸ Ἱεροσολύμων καταβεβηκότες Ἰουδαῖοι, πολλὰ καὶ βαρέα αἰτιώματα καταφέροντες, ἃ οὐκ ἴσχυον ἀποδεῖξαι,
44025008 τοῦ Παύλου ἀπολογουμένου, ὅτι “Οὔτε εἰς τὸν νόμον τῶν Ἰουδαίων, οὔτε εἰς τὸ ἱερὸν, οὔτε εἰς Καίσαρά, τι ἥμαρτον.”
44025009 Ὁ Φῆστος δὲ θέλων τοῖς Ἰουδαίοις χάριν καταθέσθαι, ἀποκριθεὶς τῷ Παύλῳ εἶπεν, “Θέλεις εἰς Ἱεροσόλυμα ἀναβὰς ἐκεῖ περὶ τούτων κριθῆναι ἐπʼ ἐμοῦ;”
44025010 Εἶπεν δὲ ὁ Παῦλος, “Ἐπὶ τοῦ βήματος Καίσαρος ἑστώς εἰμι, οὗ με δεῖ κρίνεσθαι. Ἰουδαίους οὐδὲν ἠδίκησα, ὡς καὶ σὺ κάλλιον ἐπιγινώσκεις.
44025011 Εἰ μὲν οὖν ἀδικῶ καὶ ἄξιον θανάτου πέπραχά τι, οὐ παραιτοῦμαι τὸ ἀποθανεῖν· εἰ δὲ οὐδέν ἐστιν ὧν οὗτοι κατηγοροῦσίν μου, οὐδείς με δύναται αὐτοῖς χαρίσασθαι. Καίσαρα ἐπικαλοῦμαι!”
44025012 Τότε ὁ Φῆστος συλλαλήσας μετὰ τοῦ συμβουλίου, ἀπεκρίθη, “Καίσαρα ἐπικέκλησαι, ἐπὶ Καίσαρα πορεύσῃ!”
44025013 Ἡμερῶν δὲ διαγενομένων τινῶν, Ἀγρίππας ὁ βασιλεὺς καὶ Βερνίκη κατήντησαν εἰς Καισάρειαν, ἀσπασάμενοι τὸν Φῆστον.
44025014 Ὡς δὲ πλείους ἡμέρας διέτριβον ἐκεῖ, ὁ Φῆστος τῷ βασιλεῖ ἀνέθετο τὰ κατὰ τὸν Παῦλον λέγων, “Ἀνήρ τίς ἐστιν, καταλελειμμένος ὑπὸ Φήλικος δέσμιος,
44025015 περὶ οὗ γενομένου μου εἰς Ἱεροσόλυμα, ἐνεφάνισαν οἱ ἀρχιερεῖς καὶ οἱ πρεσβύτεροι τῶν Ἰουδαίων, αἰτούμενοι κατʼ αὐτοῦ καταδίκην·
44025016 πρὸς οὓς ἀπεκρίθην ὅτι οὐκ ἔστιν ἔθος Ῥωμαίοις χαρίζεσθαί τινα ἄνθρωπον πρὶν ἢ ὁ κατηγορούμενος, κατὰ πρόσωπον ἔχοι τοὺς κατηγόρους τόπον τε ἀπολογίας λάβοι περὶ τοῦ ἐγκλήματος.
44025017 Συνελθόντων οὖν αὐτῶν ἐνθάδε, ἀναβολὴν μηδεμίαν ποιησάμενος, τῇ ἑξῆς καθίσας ἐπὶ τοῦ βήματος, ἐκέλευσα ἀχθῆναι τὸν ἄνδρα·
44025018 περὶ οὗ σταθέντες οἱ κατήγοροι οὐδεμίαν αἰτίαν ἔφερον, ὧν ἐγὼ ὑπενόουν πονηρῶν.
44025019 Ζητήματα δέ τινα περὶ τῆς ἰδίας δεισιδαιμονίας, εἶχον πρὸς αὐτὸν, καὶ περί τινος ˚Ἰησοῦ τεθνηκότος, ὃν ἔφασκεν ὁ Παῦλος ζῆν.
44025020 Ἀπορούμενος δὲ, ἐγὼ τὴν περὶ τούτων ζήτησιν, ἔλεγον εἰ βούλοιτο πορεύεσθαι εἰς Ἱεροσόλυμα, κἀκεῖ κρίνεσθαι περὶ τούτων.
44025021 Τοῦ δὲ Παύλου ἐπικαλεσαμένου τηρηθῆναι αὐτὸν εἰς τὴν τοῦ Σεβαστοῦ διάγνωσιν, ἐκέλευσα τηρεῖσθαι αὐτὸν, ἕως οὗ ἀναπέμψω αὐτὸν πρὸς Καίσαρα.”
44025022 Ἀγρίππας δὲ πρὸς τὸν Φῆστον, “Ἐβουλόμην καὶ αὐτὸς τοῦ ἀνθρώπου ἀκοῦσαι.” “Αὔριον”, φησίν, “ἀκούσῃ αὐτοῦ.”
44025023 Τῇ οὖν ἐπαύριον, ἐλθόντος τοῦ Ἀγρίππα καὶ τῆς Βερνίκης μετὰ πολλῆς φαντασίας, καὶ εἰσελθόντων εἰς τὸ ἀκροατήριον σύν τε χιλιάρχοις καὶ ἀνδράσιν τοῖς κατʼ ἐξοχὴν τῆς πόλεως, καὶ κελεύσαντος τοῦ Φήστου, ἤχθη ὁ Παῦλος.
44025024 Καί φησιν ὁ Φῆστος, “Ἀγρίππα Βασιλεῦ, καὶ πάντες οἱ συμπαρόντες ἡμῖν, ἄνδρες, θεωρεῖτε τοῦτον, περὶ οὗ ἅπαν τὸ πλῆθος τῶν Ἰουδαίων ἐνέτυχόν μοι, ἔν τε Ἱεροσολύμοις καὶ ἐνθάδε, βοῶντες μὴ δεῖν αὐτὸν ζῆν μηκέτι.
44025025 Ἐγὼ δὲ κατελαβόμην μηδὲν ἄξιον αὐτὸν θανάτου πεπραχέναι, αὐτοῦ δὲ τούτου ἐπικαλεσαμένου τὸν Σεβαστὸν, ἔκρινα πέμπειν.
44025026 Περὶ οὗ ἀσφαλές τι γράψαι τῷ κυρίῳ οὐκ ἔχω. Διὸ προήγαγον αὐτὸν ἐφʼ ὑμῶν, καὶ μάλιστα ἐπὶ σοῦ, Βασιλεῦ Ἀγρίππα, ὅπως τῆς ἀνακρίσεως γενομένης, σχῶ τι γράψω.
44025027 Ἄλογον γάρ μοι δοκεῖ πέμποντα δέσμιον, μὴ καὶ τὰς κατʼ αὐτοῦ αἰτίας σημᾶναι.”
44026001 Ἀγρίππας δὲ πρὸς τὸν Παῦλον ἔφη, “Ἐπιτρέπεταί σοι περὶ σεαυτοῦ λέγειν.” Τότε ὁ Παῦλος ἐκτείνας τὴν χεῖρα ἀπελογεῖτο,
44026002 “Περὶ πάντων ὧν ἐγκαλοῦμαι ὑπὸ Ἰουδαίων, Βασιλεῦ Ἀγρίππα, ἥγημαι ἐμαυτὸν μακάριον ἐπὶ σοῦ, μέλλων σήμερον ἀπολογεῖσθαι.
44026003 Μάλιστα γνώστην ὄντα σε πάντων τῶν κατὰ Ἰουδαίους, ἐθῶν τε καὶ ζητημάτων· διὸ δέομαι μακροθύμως ἀκοῦσαί μου.
44026004 Τὴν μὲν οὖν βίωσίν μου, ἐκ νεότητος τὴν ἀπʼ ἀρχῆς γενομένην ἐν τῷ ἔθνει μου ἔν τε Ἱεροσολύμοις, ἴσασιν πάντες Ἰουδαῖοι,
44026005 προγινώσκοντές με ἄνωθεν, ἐὰν θέλωσιν μαρτυρεῖν ὅτι κατὰ τὴν ἀκριβεστάτην αἵρεσιν τῆς ἡμετέρας θρησκείας, ἔζησα Φαρισαῖος.
44026006 Καὶ νῦν ἐπʼ ἐλπίδι τῆς εἰς τοὺς πατέρας ἡμῶν ἐπαγγελίας, γενομένης ὑπὸ τοῦ ˚Θεοῦ, ἕστηκα κρινόμενος,
44026007 εἰς ἣν τὸ δωδεκάφυλον ἡμῶν ἐν ἐκτενείᾳ, νύκτα καὶ ἡμέραν λατρεῦον, ἐλπίζει καταντῆσαι· περὶ ἧς ἐλπίδος ἐγκαλοῦμαι ὑπὸ Ἰουδαίων, Βασιλεῦ.
44026008 Τί ἄπιστον κρίνεται παρʼ ὑμῖν, εἰ ὁ ˚Θεὸς νεκροὺς ἐγείρει;
44026009 Ἐγὼ μὲν οὖν ἔδοξα ἐμαυτῷ πρὸς τὸ ὄνομα ˚Ἰησοῦ τοῦ Ναζωραίου, δεῖν πολλὰ ἐναντία πρᾶξαι.
44026010 Ὃ καὶ ἐποίησα ἐν Ἱεροσολύμοις, καὶ πολλούς τε τῶν ἁγίων ἐγὼ ἐν φυλακαῖς κατέκλεισα, τὴν παρὰ τῶν ἀρχιερέων ἐξουσίαν λαβών, ἀναιρουμένων τε αὐτῶν, κατήνεγκα ψῆφον.
44026011 Καὶ κατὰ πάσας τὰς συναγωγὰς, πολλάκις τιμωρῶν αὐτοὺς, ἠνάγκαζον βλασφημεῖν, περισσῶς τε ἐμμαινόμενος αὐτοῖς, ἐδίωκον ἕως καὶ εἰς τὰς ἔξω πόλεις.
44026012 Ἐν οἷς πορευόμενος εἰς τὴν Δαμασκὸν, μετʼ ἐξουσίας καὶ ἐπιτροπῆς τῆς τῶν ἀρχιερέων,
44026013 ἡμέρας μέσης κατὰ τὴν ὁδὸν εἶδον, Βασιλεῦ, οὐρανόθεν ὑπὲρ τὴν λαμπρότητα τοῦ ἡλίου περιλάμψαν με φῶς, καὶ τοὺς σὺν ἐμοὶ πορευομένους.
44026014 Πάντων τε καταπεσόντων ἡμῶν εἰς τὴν γῆν, ἤκουσα φωνὴν, λέγουσαν πρός με τῇ Ἑβραΐδι διαλέκτῳ, ‘Σαοὺλ, Σαούλ, τί με διώκεις; Σκληρόν σοι πρὸς κέντρα λακτίζειν.’
44026015 Ἐγὼ δὲ εἶπα, ‘Τίς εἶ, κύριε;’ Ὁ δὲ ˚Κύριος εἶπεν, ‘Ἐγώ εἰμι ˚Ἰησοῦς, ὃν σὺ διώκεις.
44026016 Ἀλλὰ ἀνάστηθι, καὶ στῆθι ἐπὶ τοὺς πόδας σου· εἰς τοῦτο γὰρ ὤφθην σοι, προχειρίσασθαί σε ὑπηρέτην καὶ μάρτυρα, ὧν τε εἶδές με ὧν τε ὀφθήσομαί σοι,
44026017 ἐξαιρούμενός σε ἐκ τοῦ λαοῦ καὶ ἐκ τῶν ἐθνῶν, εἰς οὓς ἐγὼ ἀποστέλλω σε,
44026018 ἀνοῖξαι ὀφθαλμοὺς αὐτῶν, τοῦ ἐπιστρέψαι ἀπὸ σκότους εἰς φῶς, καὶ τῆς ἐξουσίας τοῦ Σατανᾶ ἐπὶ τὸν ˚Θεόν, τοῦ λαβεῖν αὐτοὺς ἄφεσιν ἁμαρτιῶν καὶ κλῆρον ἐν τοῖς ἡγιασμένοις πίστει τῇ εἰς ἐμέ.’
44026019 Ὅθεν, Βασιλεῦ Ἀγρίππα, οὐκ ἐγενόμην ἀπειθὴς τῇ οὐρανίῳ ὀπτασίᾳ,
44026020 ἀλλὰ τοῖς ἐν Δαμασκῷ πρῶτόν τε, καὶ Ἱεροσολύμοις, πᾶσάν τε τὴν χώραν τῆς Ἰουδαίας, καὶ τοῖς ἔθνεσιν, ἀπήγγελλον μετανοεῖν καὶ ἐπιστρέφειν ἐπὶ τὸν ˚Θεόν, ἄξια τῆς μετανοίας ἔργα πράσσοντας.
44026021 Ἕνεκα τούτων, με Ἰουδαῖοι συλλαβόμενοι ἐν τῷ ἱερῷ, ἐπειρῶντο διαχειρίσασθαι.
44026022 Ἐπικουρίας οὖν τυχὼν τῆς ἀπὸ τοῦ ˚Θεοῦ ἄχρι τῆς ἡμέρας ταύτης, ἕστηκα μαρτυρόμενος μικρῷ τε καὶ μεγάλῳ, οὐδὲν ἐκτὸς λέγων ὧν τε οἱ προφῆται ἐλάλησαν μελλόντων γίνεσθαι, καὶ Μωϋσῆς,
44026023 εἰ παθητὸς ὁ ˚Χριστός, εἰ πρῶτος ἐξ ἀναστάσεως νεκρῶν, φῶς μέλλει καταγγέλλειν τῷ τε λαῷ καὶ τοῖς ἔθνεσιν.”
44026024 Ταῦτα δὲ αὐτοῦ ἀπολογουμένου, ὁ Φῆστος μεγάλῃ τῇ φωνῇ φησιν, “Μαίνῃ, Παῦλε! Τὰ πολλά σε γράμματα εἰς μανίαν περιτρέπει!”
44026025 Ὁ δὲ Παῦλος, “Οὐ μαίνομαι”, φησίν, “κράτιστε Φῆστε, ἀλλὰ ἀληθείας καὶ σωφροσύνης ῥήματα ἀποφθέγγομαι.
44026026 Ἐπίσταται γὰρ περὶ τούτων ὁ βασιλεύς, πρὸς ὃν καὶ παρρησιαζόμενος λαλῶ· λανθάνειν γὰρ αὐτὸν τι τούτων οὐ πείθομαι οὐθέν, οὐ γάρ ἐστιν ἐν γωνίᾳ πεπραγμένον τοῦτο.
44026027 Πιστεύεις, Βασιλεῦ Ἀγρίππα, τοῖς προφήταις; Οἶδα ὅτι πιστεύεις.”
44026028 Ὁ δὲ Ἀγρίππας πρὸς τὸν Παῦλον, “Ἐν ὀλίγῳ με πείθεις Χριστιανὸν ποιῆσαι;”
44026029 Ὁ δὲ Παῦλος, “Εὐξαίμην ἂν τῷ ˚Θεῷ, καὶ ἐν ὀλίγῳ καὶ ἐν μεγάλῳ, οὐ μόνον σὲ, ἀλλὰ καὶ πάντας τοὺς ἀκούοντάς μου σήμερον, γενέσθαι τοιούτους ὁποῖος κἀγώ εἰμι, παρεκτὸς τῶν δεσμῶν τούτων.”
44026030 Ἀνέστη τε ὁ βασιλεὺς καὶ ὁ ἡγεμὼν, ἥ τε Βερνίκη καὶ οἱ συγκαθήμενοι αὐτοῖς,
44026031 καὶ ἀναχωρήσαντες ἐλάλουν πρὸς ἀλλήλους λέγοντες, ὅτι “Οὐδὲν θανάτου ἢ δεσμῶν ἄξιον τι πράσσει ὁ ἄνθρωπος οὗτος.”
44026032 Ἀγρίππας δὲ τῷ Φήστῳ ἔφη, “Ἀπολελύσθαι ἐδύνατο ὁ ἄνθρωπος οὗτος, εἰ μὴ ἐπεκέκλητο Καίσαρα.”
44027001 Ὡς δὲ ἐκρίθη τοῦ ἀποπλεῖν ἡμᾶς εἰς τὴν Ἰταλίαν, παρεδίδουν τόν τε Παῦλον καί τινας ἑτέρους δεσμώτας, ἑκατοντάρχῃ ὀνόματι Ἰουλίῳ, σπείρης Σεβαστῆς.
44027002 Ἐπιβάντες δὲ πλοίῳ Ἀδραμυττηνῷ, μέλλοντι πλεῖν εἰς τοὺς κατὰ τὴν Ἀσίαν τόπους ἀνήχθημεν, ὄντος σὺν ἡμῖν Ἀριστάρχου, Μακεδόνος Θεσσαλονικέως.
44027003 Τῇ τε ἑτέρᾳ κατήχθημεν εἰς Σιδῶνα, φιλανθρώπως τε ὁ Ἰούλιος τῷ Παύλῳ χρησάμενος, ἐπέτρεψεν πρὸς τοὺς φίλους πορευθέντι, ἐπιμελείας τυχεῖν.
44027004 Κἀκεῖθεν ἀναχθέντες, ὑπεπλεύσαμεν τὴν Κύπρον, διὰ τὸ τοὺς ἀνέμους εἶναι ἐναντίους.
44027005 Τό τε πέλαγος τὸ κατὰ τὴν Κιλικίαν καὶ Παμφυλίαν διαπλεύσαντες, κατήλθομεν εἰς Μύρα τῆς Λυκίας.
44027006 Κἀκεῖ εὑρὼν ὁ ἑκατοντάρχης πλοῖον Ἀλεξανδρῖνον, πλέον εἰς τὴν Ἰταλίαν, ἐνεβίβασεν ἡμᾶς εἰς αὐτό.
44027007 Ἐν ἱκαναῖς δὲ ἡμέραις, βραδυπλοοῦντες καὶ μόλις, γενόμενοι κατὰ τὴν Κνίδον, μὴ προσεῶντος ἡμᾶς τοῦ ἀνέμου, ὑπεπλεύσαμεν τὴν Κρήτην κατὰ Σαλμώνην.
44027008 Μόλις τε παραλεγόμενοι αὐτὴν, ἤλθομεν εἰς τόπον τινὰ καλούμενον Καλοὺς Λιμένας, ᾧ ἐγγὺς πόλις ἦν Λασαία.
44027009 Ἱκανοῦ δὲ χρόνου διαγενομένου, καὶ ὄντος ἤδη ἐπισφαλοῦς τοῦ πλοὸς, διὰ τὸ καὶ τὴν νηστείαν ἤδη παρεληλυθέναι, παρῄνει ὁ Παῦλος
44027010 λέγων αὐτοῖς, “Ἄνδρες, θεωρῶ ὅτι μετὰ ὕβρεως καὶ πολλῆς ζημίας, οὐ μόνον τοῦ φορτίου καὶ τοῦ πλοίου, ἀλλὰ καὶ τῶν ψυχῶν ἡμῶν, μέλλειν ἔσεσθαι τὸν πλοῦν.”
44027011 Ὁ δὲ ἑκατοντάρχης τῷ κυβερνήτῃ καὶ τῷ ναυκλήρῳ μᾶλλον ἐπείθετο, ἢ τοῖς ὑπὸ Παύλου λεγομένοις.
44027012 Ἀνευθέτου δὲ τοῦ λιμένος ὑπάρχοντος πρὸς παραχειμασίαν, οἱ πλείονες ἔθεντο βουλὴν ἀναχθῆναι ἐκεῖθεν, εἴ πως δύναιντο, καταντήσαντες εἰς Φοίνικα, παραχειμάσαι λιμένα τῆς Κρήτης, βλέποντα κατὰ λίβα καὶ κατὰ χῶρον.
44027013 Ὑποπνεύσαντος δὲ νότου, δόξαντες τῆς προθέσεως κεκρατηκέναι, ἄραντες ἆσσον παρελέγοντο τὴν Κρήτην.
44027014 Μετʼ οὐ πολὺ δὲ, ἔβαλεν κατʼ αὐτῆς ἄνεμος τυφωνικὸς, ὁ καλούμενος Εὐρακύλων.
44027015 Συναρπασθέντος δὲ τοῦ πλοίου, καὶ μὴ δυναμένου ἀντοφθαλμεῖν τῷ ἀνέμῳ, ἐπιδόντες ἐφερόμεθα.
44027016 Νησίον δέ τι ὑποδραμόντες, καλούμενον Καῦδα, ἰσχύσαμεν μόλις περικρατεῖς γενέσθαι τῆς σκάφης,
44027017 ἣν ἄραντες, βοηθείαις ἐχρῶντο, ὑποζωννύντες τὸ πλοῖον. Φοβούμενοί τε μὴ εἰς τὴν Σύρτιν ἐκπέσωσιν, χαλάσαντες τὸ σκεῦος, οὕτως ἐφέροντο.
44027018 Σφοδρῶς δὲ χειμαζομένων ἡμῶν, τῇ ἑξῆς ἐκβολὴν ἐποιοῦντο,
44027019 καὶ τῇ τρίτῃ, αὐτόχειρες τὴν σκευὴν τοῦ πλοίου ἔρριψαν.
44027020 Μήτε δὲ ἡλίου μήτε ἄστρων ἐπιφαινόντων ἐπὶ πλείονας ἡμέρας, χειμῶνός τε οὐκ ὀλίγου ἐπικειμένου, λοιπὸν περιῃρεῖτο ἐλπὶς πᾶσα, τοῦ σῴζεσθαι ἡμᾶς.
44027021 Πολλῆς τε ἀσιτίας ὑπαρχούσης, τότε σταθεὶς ὁ Παῦλος ἐν μέσῳ αὐτῶν εἶπεν, “Ἔδει μέν, ὦ ἄνδρες, πειθαρχήσαντάς μοι, μὴ ἀνάγεσθαι ἀπὸ τῆς Κρήτης, κερδῆσαί τε τὴν ὕβριν ταύτην, καὶ τὴν ζημίαν.
44027022 Καὶ τὰ νῦν παραινῶ ὑμᾶς εὐθυμεῖν, ἀποβολὴ γὰρ ψυχῆς οὐδεμία ἔσται ἐξ ὑμῶν, πλὴν τοῦ πλοίου.
44027023 Παρέστη γάρ μοι ταύτῃ τῇ νυκτὶ τοῦ ˚Θεοῦ, οὗ εἰμι, ᾧ καὶ λατρεύω, ἄγγελος
44027024 λέγων, ‘Μὴ φοβοῦ, Παῦλε· Καίσαρί σε δεῖ παραστῆναι, καὶ ἰδοὺ, κεχάρισταί σοι ὁ ˚Θεὸς πάντας τοὺς πλέοντας μετὰ σοῦ.’
44027025 Διὸ εὐθυμεῖτε, ἄνδρες, πιστεύω γὰρ τῷ ˚Θεῷ ὅτι οὕτως ἔσται, καθʼ ὃν τρόπον λελάληταί μοι.
44027026 Εἰς νῆσον δέ τινα, δεῖ ἡμᾶς ἐκπεσεῖν.”
44027027 Ὡς δὲ τεσσαρεσκαιδεκάτη νὺξ ἐγένετο, διαφερομένων ἡμῶν ἐν τῷ Ἀδρίᾳ, κατὰ μέσον τῆς νυκτὸς, ὑπενόουν οἱ ναῦται προσάγειν τινὰ αὐτοῖς χώραν.
44027028 Καὶ βολίσαντες, εὗρον ὀργυιὰς εἴκοσι, βραχὺ δὲ διαστήσαντες, καὶ πάλιν βολίσαντες, εὗρον ὀργυιὰς δεκαπέντε.
44027029 Φοβούμενοί τε μή που κατὰ τραχεῖς τόπους ἐκπέσωμεν, ἐκ πρύμνης ῥίψαντες ἀγκύρας τέσσαρας, ηὔχοντο ἡμέραν γενέσθαι.
44027030 Τῶν δὲ ναυτῶν ζητούντων φυγεῖν ἐκ τοῦ πλοίου, καὶ χαλασάντων τὴν σκάφην εἰς τὴν θάλασσαν, προφάσει ὡς ἐκ πρῴρης, ἀγκύρας μελλόντων ἐκτείνειν,
44027031 εἶπεν ὁ Παῦλος τῷ ἑκατοντάρχῃ καὶ τοῖς στρατιώταις, “Ἐὰν μὴ οὗτοι μείνωσιν ἐν τῷ πλοίῳ, ὑμεῖς σωθῆναι οὐ δύνασθε.”
44027032 Τότε ἀπέκοψαν οἱ στρατιῶται τὰ σχοινία τῆς σκάφης, καὶ εἴασαν αὐτὴν ἐκπεσεῖν.
44027033 Ἄχρι δὲ οὗ ἡμέρα ἤμελλεν γίνεσθαι, παρεκάλει ὁ Παῦλος ἅπαντας, μεταλαβεῖν τροφῆς λέγων, “Τεσσαρεσκαιδεκάτην σήμερον ἡμέραν, προσδοκῶντες ἄσιτοι διατελεῖτε, μηθὲν προσλαβόμενοι.
44027034 Διὸ παρακαλῶ ὑμᾶς μεταλαβεῖν τροφῆς, τοῦτο γὰρ πρὸς τῆς ὑμετέρας σωτηρίας ὑπάρχει· οὐδενὸς γὰρ ὑμῶν θρὶξ ἀπὸ τῆς κεφαλῆς ἀπολεῖται.”
44027035 Εἴπας δὲ ταῦτα, καὶ λαβὼν ἄρτον, εὐχαρίστησεν τῷ ˚Θεῷ ἐνώπιον πάντων, καὶ κλάσας, ἤρξατο ἐσθίειν.
44027036 Εὔθυμοι δὲ γενόμενοι πάντες, καὶ αὐτοὶ προσελάβοντο τροφῆς.
44027037 Ἤμεθα δὲ αἱ πᾶσαι ψυχαὶ ἐν τῷ πλοίῳ, διακόσιαι ἑβδομήκοντα ἕξ.
44027038 Κορεσθέντες δὲ τροφῆς, ἐκούφιζον τὸ πλοῖον, ἐκβαλλόμενοι τὸν σῖτον εἰς τὴν θάλασσαν.
44027039 Ὅτε δὲ ἡμέρα ἐγένετο, τὴν γῆν οὐκ ἐπεγίνωσκον, κόλπον δέ τινα κατενόουν ἔχοντα αἰγιαλὸν, εἰς ὃν ἐβουλεύοντο, εἰ δύναιντο ἐξῶσαι τὸ πλοῖον.
44027040 Καὶ τὰς ἀγκύρας περιελόντες, εἴων εἰς τὴν θάλασσαν, ἅμα ἀνέντες τὰς ζευκτηρίας τῶν πηδαλίων, καὶ ἐπάραντες τὸν ἀρτέμωνα τῇ πνεούσῃ, κατεῖχον εἰς τὸν αἰγιαλόν.
44027041 Περιπεσόντες δὲ εἰς τόπον διθάλασσον, ἐπέκειλαν τὴν ναῦν, καὶ ἡ μὲν πρῷρα ἐρείσασα ἔμεινεν ἀσάλευτος, ἡ δὲ πρύμνα ἐλύετο ὑπὸ τῆς βίας.
44027042 Τῶν δὲ στρατιωτῶν, βουλὴ ἐγένετο ἵνα τοὺς δεσμώτας ἀποκτείνωσιν, μή τις ἐκκολυμβήσας διαφύγῃ.
44027043 Ὁ δὲ ἑκατοντάρχης βουλόμενος διασῶσαι τὸν Παῦλον, ἐκώλυσεν αὐτοὺς τοῦ βουλήματος, ἐκέλευσέν τε τοὺς δυναμένους κολυμβᾶν, ἀπορίψαντας πρώτους, ἐπὶ τὴν γῆν ἐξιέναι,
44027044 καὶ τοὺς λοιποὺς, οὓς μὲν ἐπὶ σανίσιν, οὓς δὲ ἐπί τινων τῶν ἀπὸ τοῦ πλοίου. Καὶ οὕτως ἐγένετο, πάντας διασωθῆναι ἐπὶ τὴν γῆν.
44028001 Καὶ διασωθέντες, τότε ἐπέγνωμεν ὅτι Μελίτη ἡ νῆσος καλεῖται.
44028002 Οἵ τε βάρβαροι παρεῖχον οὐ τὴν τυχοῦσαν φιλανθρωπίαν ἡμῖν, ἅψαντες γὰρ πυρὰν, προσελάβοντο πάντας ἡμᾶς, διὰ τὸν ὑετὸν τὸν ἐφεστῶτα, καὶ διὰ τὸ ψῦχος.
44028003 Συστρέψαντος δὲ τοῦ Παύλου φρυγάνων τι πλῆθος, καὶ ἐπιθέντος ἐπὶ τὴν πυράν, ἔχιδνα ἀπὸ τῆς θέρμης ἐξελθοῦσα, καθῆψε τῆς χειρὸς αὐτοῦ.
44028004 Ὡς δὲ εἶδον οἱ βάρβαροι κρεμάμενον τὸ θηρίον ἐκ τῆς χειρὸς αὐτοῦ, πρὸς ἀλλήλους ἔλεγον, “Πάντως φονεύς ἐστιν ὁ ἄνθρωπος οὗτος, ὃν διασωθέντα ἐκ τῆς θαλάσσης, ἡ δίκη ζῆν οὐκ εἴασεν.”
44028005 Ὁ μὲν οὖν, ἀποτινάξας τὸ θηρίον εἰς τὸ πῦρ, ἔπαθεν οὐδὲν κακόν.
44028006 Οἱ δὲ προσεδόκων αὐτὸν μέλλειν πίμπρασθαι, ἢ καταπίπτειν ἄφνω νεκρόν. Ἐπὶ πολὺ δὲ, αὐτῶν προσδοκώντων καὶ θεωρούντων μηδὲν ἄτοπον εἰς αὐτὸν γινόμενον, μεταβαλόμενοι ἔλεγον αὐτὸν εἶναι θεόν.
44028007 Ἐν δὲ τοῖς περὶ τὸν τόπον ἐκεῖνον, ὑπῆρχεν χωρία τῷ πρώτῳ τῆς νήσου ὀνόματι Ποπλίῳ, ὃς ἀναδεξάμενος ἡμᾶς τρεῖς ἡμέρας φιλοφρόνως ἐξένισεν.
44028008 Ἐγένετο δὲ, τὸν πατέρα τοῦ Ποπλίου πυρετοῖς καὶ δυσεντερίῳ συνεχόμενον κατακεῖσθαι, πρὸς ὃν ὁ Παῦλος εἰσελθὼν, καὶ προσευξάμενος, ἐπιθεὶς τὰς χεῖρας αὐτῷ, ἰάσατο αὐτόν.
44028009 Τούτου δὲ γενομένου, καὶ οἱ λοιποὶ οἱ ἐν τῇ νήσῳ ἔχοντες ἀσθενείας προσήρχοντο καὶ ἐθεραπεύοντο,
44028010 οἳ καὶ πολλαῖς τιμαῖς ἐτίμησαν ἡμᾶς, καὶ ἀναγομένοις, ἐπέθεντο τὰ πρὸς τὰς χρείας.
44028011 Μετὰ δὲ τρεῖς μῆνας, ἀνήχθημεν ἐν πλοίῳ παρακεχειμακότι ἐν τῇ νήσῳ, Ἀλεξανδρίνῳ παρασήμῳ Διοσκούροις.
44028012 Καὶ καταχθέντες εἰς Συρακούσας, ἐπεμείναμεν ἡμέρας τρεῖς,
44028013 ὅθεν περιελόντες, κατηντήσαμεν εἰς Ῥήγιον. Καὶ μετὰ μίαν ἡμέραν, ἐπιγενομένου νότου, δευτεραῖοι ἤλθομεν εἰς Ποτιόλους,
44028014 οὗ εὑρόντες ἀδελφοὺς, παρεκλήθημεν παρʼ αὐτοῖς ἐπιμεῖναι ἡμέρας ἑπτά. Καὶ οὕτως εἰς τὴν Ῥώμην ἤλθαμεν.
44028015 Κἀκεῖθεν οἱ ἀδελφοὶ ἀκούσαντες, τὰ περὶ ἡμῶν, ἦλθαν εἰς ἀπάντησιν ἡμῖν ἄχρι Ἀππίου Φόρου καὶ Τριῶν Ταβερνῶν, οὓς ἰδὼν ὁ Παῦλος, εὐχαριστήσας τῷ ˚Θεῷ, ἔλαβε θάρσος.
44028016 Ὅτε δὲ εἰσήλθομεν εἰς Ῥώμην, ἐπετράπη τῷ Παύλῳ μένειν καθʼ ἑαυτὸν, σὺν τῷ φυλάσσοντι αὐτὸν στρατιώτῃ.
44028017 Ἐγένετο δὲ μετὰ ἡμέρας τρεῖς, συγκαλέσασθαι αὐτὸν τοὺς ὄντας τῶν Ἰουδαίων πρώτους. Συνελθόντων δὲ αὐτῶν, ἔλεγεν πρὸς αὐτούς, “Ἐγώ, Ἄνδρες, ἀδελφοί, οὐδὲν ἐναντίον ποιήσας τῷ λαῷ ἢ τοῖς ἔθεσι τοῖς πατρῴοις, δέσμιος ἐξ Ἱεροσολύμων παρεδόθην εἰς τὰς χεῖρας τῶν Ῥωμαίων,
44028018 οἵτινες ἀνακρίναντές με, ἐβούλοντο ἀπολῦσαι, διὰ τὸ μηδεμίαν αἰτίαν θανάτου ὑπάρχειν ἐν ἐμοί.
44028019 Ἀντιλεγόντων δὲ τῶν Ἰουδαίων, ἠναγκάσθην ἐπικαλέσασθαι Καίσαρα, οὐχ ὡς τοῦ ἔθνους μου ἔχων τι κατηγορεῖν.
44028020 Διὰ ταύτην οὖν τὴν αἰτίαν, παρεκάλεσα ὑμᾶς, ἰδεῖν καὶ προσλαλῆσαι, εἵνεκεν γὰρ τῆς ἐλπίδος τοῦ Ἰσραὴλ, τὴν ἅλυσιν ταύτην περίκειμαι.”
44028021 Οἱ δὲ πρὸς αὐτὸν εἶπαν, “Ἡμεῖς οὔτε γράμματα περὶ σοῦ ἐδεξάμεθα ἀπὸ τῆς Ἰουδαίας, οὔτε παραγενόμενός τις τῶν ἀδελφῶν ἀπήγγειλεν ἢ ἐλάλησέν τι περὶ σοῦ πονηρόν.
44028022 Ἀξιοῦμεν δὲ παρὰ σοῦ ἀκοῦσαι ἃ φρονεῖς, περὶ μὲν γὰρ τῆς αἱρέσεως ταύτης γνωστὸν ἡμῖν ἐστιν, ὅτι πανταχοῦ ἀντιλέγεται.”
44028023 Ταξάμενοι δὲ αὐτῷ ἡμέραν, ἦλθον πρὸς αὐτὸν εἰς τὴν ξενίαν πλείονες, οἷς ἐξετίθετο, διαμαρτυρόμενος τὴν Βασιλείαν τοῦ ˚Θεοῦ, πείθων τε αὐτοὺς περὶ τοῦ ˚Ἰησοῦ, ἀπό τε τοῦ νόμου Μωϋσέως, καὶ τῶν προφητῶν, ἀπὸ πρωῒ ἕως ἑσπέρας.
44028024 Καὶ οἱ μὲν ἐπείθοντο τοῖς λεγομένοις, οἱ δὲ ἠπίστουν.
44028025 Ἀσύμφωνοι δὲ ὄντες πρὸς ἀλλήλους, ἀπελύοντο, εἰπόντος τοῦ Παύλου ῥῆμα ἓν: ὅτι “Καλῶς τὸ ˚Πνεῦμα τὸ Ἅγιον ἐλάλησεν διὰ Ἠσαΐου τοῦ προφήτου πρὸς τοὺς πατέρας ὑμῶν
44028026 λέγων, ‘Πορεύθητι πρὸς τὸν λαὸν τοῦτον, καὶ εἰπόν, “Ἀκοῇ ἀκούσετε, καὶ οὐ μὴ συνῆτε, καὶ βλέποντες βλέψετε, καὶ οὐ μὴ ἴδητε.
44028027 Ἐπαχύνθη γὰρ ἡ καρδία τοῦ λαοῦ τούτου, καὶ τοῖς ὠσὶν βαρέως ἤκουσαν, καὶ τοὺς ὀφθαλμοὺς αὐτῶν ἐκάμμυσαν, μήποτε ἴδωσιν τοῖς ὀφθαλμοῖς, καὶ τοῖς ὠσὶν ἀκούσωσιν, καὶ τῇ καρδίᾳ συνῶσιν, καὶ ἐπιστρέψωσιν, καὶ ἰάσομαι αὐτούς.”’
44028028 Γνωστὸν οὖν ἔστω, ὑμῖν ὅτι τοῖς ἔθνεσιν ἀπεστάλη τοῦτο τὸ σωτήριον τοῦ ˚Θεοῦ, αὐτοὶ καὶ ἀκούσονται!”
44028030 Ἐνέμεινεν δὲ διετίαν ὅλην ἐν ἰδίῳ μισθώματι, καὶ ἀπεδέχετο πάντας τοὺς εἰσπορευομένους πρὸς αὐτόν,
44028031 κηρύσσων τὴν Βασιλείαν τοῦ ˚Θεοῦ, καὶ διδάσκων τὰ περὶ τοῦ ˚Κυρίου ˚Ἰησοῦ ˚Χριστοῦ, μετὰ πάσης παρρησίας ἀκωλύτως.
45001001 Παῦλος, δοῦλος ˚Χριστοῦ ˚Ἰησοῦ, κλητὸς ἀπόστολος, ἀφωρισμένος εἰς εὐαγγέλιον ˚Θεοῦ,
45001002 ὃ προεπηγγείλατο διὰ τῶν προφητῶν αὐτοῦ ἐν Γραφαῖς ἁγίαις,
45001003 περὶ τοῦ Υἱοῦ αὐτοῦ, τοῦ γενομένου ἐκ σπέρματος Δαυὶδ κατὰ σάρκα,
45001004 τοῦ ὁρισθέντος Υἱοῦ ˚Θεοῦ ἐν δυνάμει κατὰ ˚Πνεῦμα ἁγιωσύνης ἐξ ἀναστάσεως νεκρῶν, ˚Ἰησοῦ ˚Χριστοῦ τοῦ ˚Κυρίου ἡμῶν,
45001005 διʼ οὗ ἐλάβομεν χάριν καὶ ἀποστολὴν εἰς ὑπακοὴν πίστεως ἐν πᾶσιν τοῖς ἔθνεσιν ὑπὲρ τοῦ ὀνόματος αὐτοῦ,
45001006 ἐν οἷς ἐστε καὶ ὑμεῖς κλητοὶ ˚Ἰησοῦ ˚Χριστοῦ,
45001007 πᾶσιν τοῖς οὖσιν ἐν Ῥώμῃ, ἀγαπητοῖς ˚Θεοῦ, κλητοῖς ἁγίοις: χάρις ὑμῖν καὶ εἰρήνη ἀπὸ ˚Θεοῦ Πατρὸς ἡμῶν καὶ ˚Κυρίου ˚Ἰησοῦ ˚Χριστοῦ.
45001008 Πρῶτον μὲν εὐχαριστῶ τῷ ˚Θεῷ μου διὰ ˚Ἰησοῦ ˚Χριστοῦ περὶ πάντων ὑμῶν, ὅτι ἡ πίστις ὑμῶν καταγγέλλεται ἐν ὅλῳ τῷ κόσμῳ.
45001009 Μάρτυς γάρ μού ἐστιν ὁ ˚Θεός, ᾧ λατρεύω ἐν τῷ πνεύματί μου ἐν τῷ εὐαγγελίῳ τοῦ Υἱοῦ αὐτοῦ, ὡς ἀδιαλείπτως μνείαν ὑμῶν ποιοῦμαι
45001010 πάντοτε ἐπὶ τῶν προσευχῶν μου, δεόμενος εἴ πως ἤδη ποτὲ εὐοδωθήσομαι ἐν τῷ θελήματι τοῦ ˚Θεοῦ, ἐλθεῖν πρὸς ὑμᾶς.
45001011 Ἐπιποθῶ γὰρ ἰδεῖν ὑμᾶς, ἵνα τι μεταδῶ χάρισμα ὑμῖν πνευματικὸν, εἰς τὸ στηριχθῆναι ὑμᾶς,
45001012 τοῦτο δέ ἐστιν, συμπαρακληθῆναι ἐν ὑμῖν, διὰ τῆς ἐν ἀλλήλοις πίστεως, ὑμῶν τε καὶ ἐμοῦ.
45001013 Οὐ θέλω δὲ ὑμᾶς ἀγνοεῖν, ἀδελφοί, ὅτι πολλάκις προεθέμην ἐλθεῖν πρὸς ὑμᾶς, καὶ ἐκωλύθην ἄχρι τοῦ δεῦρο, ἵνα τινὰ καρπὸν σχῶ καὶ ἐν ὑμῖν, καθὼς καὶ ἐν τοῖς λοιποῖς ἔθνεσιν.
45001014 Ἕλλησίν τε καὶ βαρβάροις, σοφοῖς τε καὶ ἀνοήτοις, ὀφειλέτης εἰμί.
45001015 Οὕτως τὸ κατʼ ἐμὲ, πρόθυμον καὶ ὑμῖν τοῖς ἐν Ῥώμῃ εὐαγγελίσασθαι.
45001016 Οὐ γὰρ ἐπαισχύνομαι τὸ εὐαγγέλιον, δύναμις γὰρ ˚Θεοῦ ἐστιν εἰς σωτηρίαν παντὶ τῷ πιστεύοντι, Ἰουδαίῳ τε πρῶτον καὶ Ἕλληνι.
45001017 Δικαιοσύνη γὰρ ˚Θεοῦ ἐν αὐτῷ ἀποκαλύπτεται, ἐκ πίστεως εἰς πίστιν, καθὼς γέγραπται, “Ὁ δὲ δίκαιος ἐκ πίστεως ζήσεται.”
45001018 Ἀποκαλύπτεται γὰρ ὀργὴ ˚Θεοῦ ἀπʼ οὐρανοῦ, ἐπὶ πᾶσαν ἀσέβειαν καὶ ἀδικίαν ἀνθρώπων, τῶν τὴν ἀλήθειαν ἐν ἀδικίᾳ κατεχόντων,
45001019 διότι τὸ γνωστὸν τοῦ ˚Θεοῦ, φανερόν ἐστιν ἐν αὐτοῖς, ὁ ˚Θεὸς γὰρ αὐτοῖς ἐφανέρωσεν.
45001020 Τὰ γὰρ ἀόρατα αὐτοῦ ἀπὸ κτίσεως κόσμου, τοῖς ποιήμασιν νοούμενα, καθορᾶται, ἥ τε ἀΐδιος αὐτοῦ δύναμις καὶ θειότης, εἰς τὸ εἶναι αὐτοὺς ἀναπολογήτους.
45001021 Διότι γνόντες τὸν ˚Θεὸν, οὐχ ὡς ˚Θεὸν ἐδόξασαν ἢ ηὐχαρίστησαν, ἀλλʼ ἐματαιώθησαν ἐν τοῖς διαλογισμοῖς αὐτῶν, καὶ ἐσκοτίσθη ἡ ἀσύνετος αὐτῶν καρδία.
45001022 Φάσκοντες εἶναι σοφοὶ, ἐμωράνθησαν,
45001023 καὶ ἤλλαξαν τὴν δόξαν τοῦ ἀφθάρτου ˚Θεοῦ ἐν ὁμοιώματι εἰκόνος φθαρτοῦ ἀνθρώπου, καὶ πετεινῶν, καὶ τετραπόδων, καὶ ἑρπετῶν.
45001024 Διὸ παρέδωκεν αὐτοὺς ὁ ˚Θεὸς ἐν ταῖς ἐπιθυμίαις τῶν καρδιῶν αὐτῶν εἰς ἀκαθαρσίαν, τοῦ ἀτιμάζεσθαι τὰ σώματα αὐτῶν ἐν αὐτοῖς,
45001025 οἵτινες μετήλλαξαν τὴν ἀλήθειαν τοῦ ˚Θεοῦ ἐν τῷ ψεύδει, καὶ ἐσεβάσθησαν καὶ ἐλάτρευσαν τῇ κτίσει παρὰ τὸν κτίσαντα, ὅς ἐστιν εὐλογητὸς εἰς τοὺς αἰῶνας. Ἀμήν!
45001026 Διὰ τοῦτο, παρέδωκεν αὐτοὺς ὁ ˚Θεὸς εἰς πάθη ἀτιμίας· αἵ τε γὰρ θήλειαι αὐτῶν μετήλλαξαν τὴν φυσικὴν χρῆσιν εἰς τὴν παρὰ φύσιν,
45001027 ὁμοίως τε καὶ οἱ ἄρσενες ἀφέντες τὴν φυσικὴν χρῆσιν τῆς θηλείας, ἐξεκαύθησαν ἐν τῇ ὀρέξει αὐτῶν εἰς ἀλλήλους, ἄρσενες ἐν ἄρσεσιν τὴν ἀσχημοσύνην κατεργαζόμενοι, καὶ τὴν ἀντιμισθίαν ἣν ἔδει τῆς πλάνης αὐτῶν, ἐν ἑαυτοῖς ἀπολαμβάνοντες.
45001028 Καὶ καθὼς οὐκ ἐδοκίμασαν τὸν ˚Θεὸν ἔχειν ἐν ἐπιγνώσει, παρέδωκεν αὐτοὺς ὁ ˚Θεὸς εἰς ἀδόκιμον νοῦν, ποιεῖν τὰ μὴ καθήκοντα,
45001029 πεπληρωμένους πάσῃ ἀδικίᾳ, πονηρίᾳ, πλεονεξίᾳ, κακίᾳ, μεστοὺς φθόνου, φόνου, ἔριδος, δόλου, κακοηθείας, ψιθυριστάς,
45001030 καταλάλους, θεοστυγεῖς, ὑβριστάς, ὑπερηφάνους, ἀλαζόνας, ἐφευρετὰς κακῶν, γονεῦσιν ἀπειθεῖς,
45001031 ἀσυνέτους, ἀσυνθέτους, ἀστόργους, ἀνελεήμονας·
45001032 οἵτινες τὸ δικαίωμα τοῦ ˚Θεοῦ ἐπιγνόντες ὅτι οἱ τὰ τοιαῦτα πράσσοντες ἄξιοι θανάτου εἰσίν, οὐ μόνον αὐτὰ ποιοῦσιν, ἀλλὰ καὶ συνευδοκοῦσιν τοῖς πράσσουσιν.
45002001 Διὸ ἀναπολόγητος εἶ, ὦ ἄνθρωπε, πᾶς ὁ κρίνων, ἐν ᾧ γὰρ κρίνεις τὸν ἕτερον, σεαυτὸν κατακρίνεις, τὰ γὰρ αὐτὰ πράσσεις, ὁ κρίνων.
45002002 Οἴδαμεν δὲ ὅτι τὸ κρίμα τοῦ ˚Θεοῦ ἐστιν κατὰ ἀλήθειαν ἐπὶ τοὺς τὰ τοιαῦτα πράσσοντας.
45002003 Λογίζῃ δὲ τοῦτο, ὦ ἄνθρωπε, ὁ κρίνων τοὺς τὰ τοιαῦτα πράσσοντας, καὶ ποιῶν αὐτά, ὅτι σὺ ἐκφεύξῃ τὸ κρίμα τοῦ ˚Θεοῦ;
45002004 Ἢ τοῦ πλούτου τῆς χρηστότητος αὐτοῦ, καὶ τῆς ἀνοχῆς, καὶ τῆς μακροθυμίας καταφρονεῖς, ἀγνοῶν ὅτι τὸ χρηστὸν τοῦ ˚Θεοῦ, εἰς μετάνοιάν σε ἄγει;
45002005 Κατὰ δὲ τὴν σκληρότητά σου καὶ ἀμετανόητον καρδίαν, θησαυρίζεις σεαυτῷ ὀργὴν ἐν ἡμέρᾳ ὀργῆς καὶ ἀποκαλύψεως δικαιοκρισίας τοῦ ˚Θεοῦ,
45002006 “ὃς ἀποδώσει ἑκάστῳ κατὰ τὰ ἔργα αὐτοῦ”:
45002007 τοῖς μὲν καθʼ ὑπομονὴν ἔργου ἀγαθοῦ, δόξαν καὶ τιμὴν καὶ ἀφθαρσίαν ζητοῦσιν, ζωὴν αἰώνιον·
45002008 τοῖς δὲ ἐξ ἐριθείας καὶ ἀπειθοῦσι τῇ ἀληθείᾳ, πειθομένοις δὲ τῇ ἀδικίᾳ, ὀργὴ καὶ θυμός.
45002009 Θλῖψις καὶ στενοχωρία, ἐπὶ πᾶσαν ψυχὴν ἀνθρώπου τοῦ κατεργαζομένου τὸ κακόν, Ἰουδαίου τε πρῶτον καὶ Ἕλληνος·
45002010 δόξα δὲ, καὶ τιμὴ, καὶ εἰρήνη, παντὶ τῷ ἐργαζομένῳ τὸ ἀγαθόν, Ἰουδαίῳ τε πρῶτον καὶ Ἕλληνι.
45002011 Οὐ γάρ ἐστιν προσωπολημψία παρὰ τῷ ˚Θεῷ.
45002012 Ὅσοι γὰρ ἀνόμως ἥμαρτον, ἀνόμως καὶ ἀπολοῦνται· καὶ ὅσοι ἐν νόμῳ ἥμαρτον, διὰ νόμου κριθήσονται·
45002013 οὐ γὰρ οἱ ἀκροαταὶ νόμου δίκαιοι παρὰ τῷ ˚Θεῷ, ἀλλʼ οἱ ποιηταὶ νόμου δικαιωθήσονται.
45002014 Ὅταν γὰρ ἔθνη τὰ μὴ νόμον ἔχοντα, φύσει τὰ τοῦ νόμου ποιῶσιν, οὗτοι νόμον μὴ ἔχοντες, ἑαυτοῖς εἰσιν νόμος·
45002015 οἵτινες ἐνδείκνυνται τὸ ἔργον τοῦ νόμου, γραπτὸν ἐν ταῖς καρδίαις αὐτῶν, συμμαρτυρούσης αὐτῶν τῆς συνειδήσεως, καὶ μεταξὺ ἀλλήλων, τῶν λογισμῶν κατηγορούντων ἢ καὶ ἀπολογουμένων,
45002016 ἐν ᾗ ἡμέρᾳ κρίνει ὁ ˚Θεὸς τὰ κρυπτὰ τῶν ἀνθρώπων, κατὰ τὸ εὐαγγέλιόν μου, διὰ ˚Χριστοῦ ˚Ἰησοῦ.
45002017 Εἰ δὲ σὺ Ἰουδαῖος ἐπονομάζῃ, καὶ ἐπαναπαύῃ νόμῳ, καὶ καυχᾶσαι ἐν ˚Θεῷ,
45002018 καὶ γινώσκεις τὸ θέλημα, καὶ δοκιμάζεις τὰ διαφέροντα, κατηχούμενος ἐκ τοῦ νόμου,
45002019 πέποιθάς τε σεαυτὸν ὁδηγὸν εἶναι τυφλῶν, φῶς τῶν ἐν σκότει,
45002020 παιδευτὴν ἀφρόνων, διδάσκαλον νηπίων, ἔχοντα τὴν μόρφωσιν τῆς γνώσεως καὶ τῆς ἀληθείας ἐν τῷ νόμῳ–
45002021 ὁ οὖν διδάσκων ἕτερον, σεαυτὸν οὐ διδάσκεις; Ὁ κηρύσσων μὴ κλέπτειν, κλέπτεις;
45002022 Ὁ λέγων μὴ μοιχεύειν, μοιχεύεις; Ὁ βδελυσσόμενος τὰ εἴδωλα, ἱεροσυλεῖς;
45002023 Ὃς ἐν νόμῳ καυχᾶσαι διὰ τῆς παραβάσεως τοῦ νόμου, τὸν ˚Θεὸν ἀτιμάζεις;
45002024 “Τὸ γὰρ ὄνομα τοῦ ˚Θεοῦ διʼ ὑμᾶς βλασφημεῖται ἐν τοῖς ἔθνεσιν”, καθὼς γέγραπται.
45002025 Περιτομὴ μὲν γὰρ ὠφελεῖ, ἐὰν νόμον πράσσῃς· ἐὰν δὲ παραβάτης νόμου ᾖς, ἡ περιτομή σου, ἀκροβυστία γέγονεν.
45002026 Ἐὰν οὖν ἡ ἀκροβυστία, τὰ δικαιώματα τοῦ νόμου φυλάσσῃ, οὐχ ἡ ἀκροβυστία αὐτοῦ εἰς περιτομὴν λογισθήσεται;
45002027 Καὶ κρινεῖ ἡ ἐκ φύσεως ἀκροβυστία, τὸν νόμον τελοῦσα, σὲ τὸν διὰ γράμματος καὶ περιτομῆς παραβάτην νόμου.
45002028 Οὐ γὰρ ὁ ἐν τῷ φανερῷ Ἰουδαῖός ἐστιν, οὐδὲ ἡ ἐν τῷ φανερῷ ἐν σαρκὶ περιτομή·
45002029 ἀλλʼ ὁ ἐν τῷ κρυπτῷ Ἰουδαῖος, καὶ περιτομὴ καρδίας, ἐν ˚Πνεύματι, οὐ γράμματι, οὗ ὁ ἔπαινος οὐκ ἐξ ἀνθρώπων, ἀλλʼ ἐκ τοῦ ˚Θεοῦ.
45003001 Τί οὖν τὸ περισσὸν τοῦ Ἰουδαίου, ἢ τίς ἡ ὠφέλεια τῆς περιτομῆς;
45003002 Πολὺ κατὰ πάντα τρόπον. Πρῶτον μὲν γὰρ ὅτι ἐπιστεύθησαν τὰ λόγια τοῦ ˚Θεοῦ.
45003003 Τί γάρ εἰ ἠπίστησάν τινες; Μὴ ἡ ἀπιστία αὐτῶν, τὴν πίστιν τοῦ ˚Θεοῦ καταργήσει;
45003004 Μὴ γένοιτο! Γινέσθω δὲ ὁ ˚Θεὸς ἀληθής, πᾶς δὲ ἄνθρωπος ψεύστης, καθάπερ γέγραπται, “Ὅπως ἂν δικαιωθῇς ἐν τοῖς λόγοις σου, καὶ νικήσεις ἐν τῷ κρίνεσθαί σε.”
45003005 Εἰ δὲ ἡ ἀδικία ἡμῶν, ˚Θεοῦ δικαιοσύνην συνίστησιν, τί ἐροῦμεν; Μὴ ἄδικος ὁ ˚Θεὸς, ὁ ἐπιφέρων τὴν ὀργήν; ( Κατὰ ἄνθρωπον λέγω. )
45003006 Μὴ γένοιτο! Ἐπεὶ πῶς κρινεῖ ὁ ˚Θεὸς τὸν κόσμον;
45003007 Εἰ δὲ ἡ ἀλήθεια τοῦ ˚Θεοῦ ἐν τῷ ἐμῷ ψεύσματι ἐπερίσσευσεν εἰς τὴν δόξαν αὐτοῦ, τί ἔτι κἀγὼ ὡς ἁμαρτωλὸς κρίνομαι;
45003008 Καὶ μὴ καθὼς βλασφημούμεθα, καὶ καθώς φασίν τινες ἡμᾶς λέγειν, ὅτι “Ποιήσωμεν τὰ κακὰ, ἵνα ἔλθῃ τὰ ἀγαθά;” Ὧν τὸ κρίμα ἔνδικόν ἐστιν.
45003009 Τί οὖν; Προεχόμεθα; Οὐ πάντως, προῃτιασάμεθα γὰρ, Ἰουδαίους τε καὶ Ἕλληνας, πάντας ὑφʼ ἁμαρτίαν εἶναι.
45003010 Καθὼς γέγραπται, ὅτι “Οὐκ ἔστιν δίκαιος, οὐδὲ εἷς,
45003011 οὐκ ἔστιν συνίων, οὐκ ἔστιν ὁ ἐκζητῶν τὸν ˚Θεόν.
45003012 Πάντες ἐξέκλιναν, ἅμα ἠχρεώθησαν· οὐκ ἔστιν ποιῶν χρηστότητα, οὐκ ἔστιν ἕως ἑνός.”
45003013 “Τάφος ἀνεῳγμένος ὁ λάρυγξ αὐτῶν, ταῖς γλώσσαις αὐτῶν ἐδολιοῦσαν”, “Ἰὸς ἀσπίδων ὑπὸ τὰ χείλη αὐτῶν”,
45003014 “Ὧν τὸ στόμα ἀρᾶς καὶ πικρίας γέμει”·
45003015 “Ὀξεῖς οἱ πόδες αὐτῶν, ἐκχέαι αἷμα…
45003016 σύντριμμα καὶ ταλαιπωρία ἐν ταῖς ὁδοῖς αὐτῶν,
45003017 καὶ ὁδὸν εἰρήνης οὐκ ἔγνωσαν.”
45003018 “Οὐκ ἔστιν φόβος ˚Θεοῦ ἀπέναντι τῶν ὀφθαλμῶν αὐτῶν.”
45003019 Οἴδαμεν δὲ ὅτι ὅσα ὁ νόμος λέγει, τοῖς ἐν τῷ νόμῳ λαλεῖ, ἵνα πᾶν στόμα φραγῇ, καὶ ὑπόδικος γένηται πᾶς ὁ κόσμος τῷ ˚Θεῷ.
45003020 Διότι ἐξ ἔργων νόμου, οὐ δικαιωθήσεται πᾶσα σὰρξ ἐνώπιον αὐτοῦ, διὰ γὰρ νόμου ἐπίγνωσις ἁμαρτίας.
45003021 Νυνὶ δὲ χωρὶς νόμου, δικαιοσύνη ˚Θεοῦ πεφανέρωται, μαρτυρουμένη ὑπὸ τοῦ νόμου καὶ τῶν προφητῶν,
45003022 δικαιοσύνη δὲ ˚Θεοῦ διὰ πίστεως ˚Ἰησοῦ ˚Χριστοῦ, εἰς πάντας τοὺς πιστεύοντας, οὐ γάρ ἐστιν διαστολή.
45003023 Πάντες γὰρ ἥμαρτον καὶ ὑστεροῦνται τῆς δόξης τοῦ ˚Θεοῦ,
45003024 δικαιούμενοι δωρεὰν τῇ αὐτοῦ χάριτι, διὰ τῆς ἀπολυτρώσεως τῆς ἐν ˚Χριστῷ ˚Ἰησοῦ·
45003025 ὃν προέθετο ὁ ˚Θεὸς ἱλαστήριον, διὰ τῆς πίστεως ἐν τῷ αὐτοῦ αἵματι, εἰς ἔνδειξιν τῆς δικαιοσύνης αὐτοῦ, διὰ τὴν πάρεσιν τῶν προγεγονότων ἁμαρτημάτων
45003026 ἐν τῇ ἀνοχῇ τοῦ ˚Θεοῦ, πρὸς τὴν ἔνδειξιν τῆς δικαιοσύνης αὐτοῦ ἐν τῷ νῦν καιρῷ, εἰς τὸ εἶναι αὐτὸν δίκαιον καὶ δικαιοῦντα τὸν ἐκ πίστεως ˚Ἰησοῦ.
45003027 Ποῦ οὖν ἡ καύχησις; Ἐξεκλείσθη. Διὰ ποίου νόμου; Τῶν ἔργων; Οὐχί, ἀλλὰ διὰ νόμου πίστεως.
45003028 Λογιζόμεθα οὖν δικαιοῦσθαι πίστει ἄνθρωπον, χωρὶς ἔργων νόμου.
45003029 Ἢ Ἰουδαίων ὁ ˚Θεὸς μόνον; Οὐχὶ καὶ ἐθνῶν; Ναὶ, καὶ ἐθνῶν,
45003030 εἴπερ εἷς ὁ ˚Θεός, ὃς δικαιώσει περιτομὴν ἐκ πίστεως, καὶ ἀκροβυστίαν διὰ τῆς πίστεως.
45003031 Νόμον οὖν καταργοῦμεν διὰ τῆς πίστεως; Μὴ γένοιτο! Ἀλλὰ νόμον ἱστάνομεν.
45004001 Τί οὖν ἐροῦμεν, εὑρηκέναι Ἀβραὰμ τὸν προπάτορα ἡμῶν κατὰ σάρκα;
45004002 Εἰ γὰρ Ἀβραὰμ ἐξ ἔργων ἐδικαιώθη, ἔχει καύχημα, ἀλλʼ οὐ πρὸς ˚Θεόν.
45004003 Τί γὰρ ἡ Γραφὴ λέγει; “Ἐπίστευσεν δὲ Ἀβραὰμ τῷ ˚Θεῷ, καὶ ἐλογίσθη αὐτῷ εἰς δικαιοσύνην.”
45004004 Τῷ δὲ ἐργαζομένῳ, ὁ μισθὸς οὐ λογίζεται κατὰ χάριν, ἀλλὰ κατὰ ὀφείλημα.
45004005 Τῷ δὲ μὴ ἐργαζομένῳ, πιστεύοντι δὲ ἐπὶ τὸν δικαιοῦντα τὸν ἀσεβῆ, λογίζεται ἡ πίστις αὐτοῦ εἰς δικαιοσύνην,
45004006 καθάπερ καὶ Δαυὶδ λέγει τὸν μακαρισμὸν τοῦ ἀνθρώπου ᾧ ὁ ˚Θεὸς λογίζεται δικαιοσύνην χωρὶς ἔργων:
45004007 “Μακάριοι, ὧν ἀφέθησαν αἱ ἀνομίαι καὶ ὧν ἐπεκαλύφθησαν αἱ ἁμαρτίαι.
45004008 Μακάριος ἀνὴρ, οὗ οὐ μὴ λογίσηται ˚Κύριος ἁμαρτίαν.”
45004009 Ὁ μακαρισμὸς οὖν οὗτος ἐπὶ τὴν περιτομὴν, ἢ καὶ ἐπὶ τὴν ἀκροβυστίαν; Λέγομεν γάρ, “Ἐλογίσθη τῷ Ἀβραὰμ ἡ πίστις εἰς δικαιοσύνην.”
45004010 Πῶς οὖν ἐλογίσθη; Ἐν περιτομῇ ὄντι, ἢ ἐν ἀκροβυστίᾳ; Οὐκ ἐν περιτομῇ, ἀλλʼ ἐν ἀκροβυστίᾳ·
45004011 καὶ σημεῖον ἔλαβεν περιτομῆς, σφραγῖδα τῆς δικαιοσύνης τῆς πίστεως τῆς ἐν τῇ ἀκροβυστίᾳ, εἰς τὸ εἶναι αὐτὸν πατέρα πάντων τῶν πιστευόντων διʼ ἀκροβυστίας, εἰς τὸ λογισθῆναι αὐτοῖς τὴν δικαιοσύνην,
45004012 καὶ πατέρα περιτομῆς τοῖς οὐκ ἐκ περιτομῆς μόνον, ἀλλὰ καὶ τοῖς στοιχοῦσιν τοῖς ἴχνεσιν τῆς ἐν ἀκροβυστίᾳ πίστεως, τοῦ πατρὸς ἡμῶν Ἀβραάμ.
45004013 Οὐ γὰρ διὰ νόμου ἡ ἐπαγγελία τῷ Ἀβραὰμ ἢ τῷ σπέρματι αὐτοῦ, τὸ κληρονόμον αὐτὸν εἶναι κόσμου, ἀλλὰ διὰ δικαιοσύνης πίστεως.
45004014 Εἰ γὰρ οἱ ἐκ νόμου κληρονόμοι, κεκένωται ἡ πίστις καὶ κατήργηται ἡ ἐπαγγελία.
45004015 Ὁ γὰρ νόμος ὀργὴν κατεργάζεται, οὗ δὲ οὐκ ἔστιν νόμος, οὐδὲ παράβασις.
45004016 Διὰ τοῦτο ἐκ πίστεως, ἵνα κατὰ χάριν, εἰς τὸ εἶναι βεβαίαν, τὴν ἐπαγγελίαν παντὶ τῷ σπέρματι, οὐ τῷ ἐκ τοῦ νόμου μόνον, ἀλλὰ καὶ τῷ ἐκ πίστεως Ἀβραάμ, ὅς ἐστιν πατὴρ πάντων ἡμῶν,
45004017 καθὼς γέγραπται, ὅτι “Πατέρα πολλῶν ἐθνῶν τέθεικά σε”, κατέναντι οὗ ἐπίστευσεν, ˚Θεοῦ τοῦ ζῳοποιοῦντος τοὺς νεκροὺς, καὶ καλοῦντος τὰ μὴ ὄντα ὡς ὄντα.
45004018 Ὃς παρʼ ἐλπίδα, ἐπʼ ἐλπίδι ἐπίστευσεν, εἰς τὸ γενέσθαι αὐτὸν πατέρα πολλῶν ἐθνῶν, κατὰ τὸ εἰρημένον, “Οὕτως ἔσται τὸ σπέρμα σου.”
45004019 Καὶ μὴ ἀσθενήσας τῇ πίστει, κατενόησεν τὸ ἑαυτοῦ σῶμα ἤδη νενεκρωμένον, ἑκατονταετής που ὑπάρχων, καὶ τὴν νέκρωσιν τῆς μήτρας Σάρρας,
45004020 εἰς δὲ τὴν ἐπαγγελίαν τοῦ ˚Θεοῦ, οὐ διεκρίθη τῇ ἀπιστίᾳ, ἀλλʼ ἐνεδυναμώθη τῇ πίστει, δοὺς δόξαν τῷ ˚Θεῷ,
45004021 καὶ πληροφορηθεὶς ὅτι ὃ ἐπήγγελται, δυνατός ἐστιν καὶ ποιῆσαι.
45004022 Διὸ καὶ “ἐλογίσθη αὐτῷ εἰς δικαιοσύνην”.
45004023 Οὐκ ἐγράφη δὲ διʼ αὐτὸν μόνον, ὅτι ἐλογίσθη αὐτῷ,
45004024 ἀλλὰ καὶ διʼ ἡμᾶς, οἷς μέλλει λογίζεσθαι, τοῖς πιστεύουσιν ἐπὶ τὸν ἐγείραντα ˚Ἰησοῦν, τὸν ˚Κύριον ἡμῶν, ἐκ νεκρῶν,
45004025 ὃς παρεδόθη διὰ τὰ παραπτώματα ἡμῶν, καὶ ἠγέρθη διὰ τὴν δικαίωσιν ἡμῶν.
45005001 Δικαιωθέντες οὖν ἐκ πίστεως, εἰρήνην ἔχωμεν πρὸς τὸν ˚Θεὸν, διὰ τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ ˚Χριστοῦ,
45005002 διʼ οὗ καὶ τὴν προσαγωγὴν ἐσχήκαμεν, τῇ πίστει εἰς τὴν χάριν ταύτην, ἐν ᾗ ἑστήκαμεν, καὶ καυχώμεθα ἐπʼ ἐλπίδι τῆς δόξης τοῦ ˚Θεοῦ.
45005003 Οὐ μόνον δέ, ἀλλὰ καὶ καυχώμεθα ἐν ταῖς θλίψεσιν, εἰδότες ὅτι ἡ θλῖψις ὑπομονὴν κατεργάζεται,
45005004 ἡ δὲ ὑπομονὴ δοκιμήν, ἡ δὲ δοκιμὴ ἐλπίδα.
45005005 Ἡ δὲ ἐλπὶς οὐ καταισχύνει, ὅτι ἡ ἀγάπη τοῦ ˚Θεοῦ ἐκκέχυται ἐν ταῖς καρδίαις ἡμῶν διὰ ˚Πνεύματος Ἁγίου, τοῦ δοθέντος ἡμῖν.
45005006 Ἔτι γὰρ ˚Χριστὸς, ὄντων ἡμῶν ἀσθενῶν ἔτι, κατὰ καιρὸν ὑπὲρ ἀσεβῶν ἀπέθανεν.
45005007 Μόλις γὰρ ὑπὲρ δικαίου τις ἀποθανεῖται· ὑπὲρ γὰρ τοῦ ἀγαθοῦ, τάχα τις καὶ τολμᾷ ἀποθανεῖν.
45005008 Συνίστησιν δὲ τὴν ἑαυτοῦ ἀγάπην εἰς ἡμᾶς ὁ ˚Θεὸς, ὅτι ἔτι ἁμαρτωλῶν ὄντων ἡμῶν, ˚Χριστὸς ὑπὲρ ἡμῶν ἀπέθανεν.
45005009 Πολλῷ οὖν μᾶλλον δικαιωθέντες νῦν ἐν τῷ αἵματι αὐτοῦ, σωθησόμεθα διʼ αὐτοῦ ἀπὸ τῆς ὀργῆς.
45005010 Εἰ γὰρ ἐχθροὶ ὄντες, κατηλλάγημεν τῷ ˚Θεῷ διὰ τοῦ θανάτου τοῦ Υἱοῦ αὐτοῦ, πολλῷ μᾶλλον καταλλαγέντες, σωθησόμεθα ἐν τῇ ζωῇ αὐτοῦ.
45005011 Οὐ μόνον δέ, ἀλλὰ καὶ καυχώμενοι ἐν τῷ ˚Θεῷ, διὰ τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ ˚Χριστοῦ, διʼ οὗ νῦν τὴν καταλλαγὴν ἐλάβομεν.
45005012 Διὰ τοῦτο, ὥσπερ διʼ ἑνὸς ἀνθρώπου ἡ ἁμαρτία εἰς τὸν κόσμον εἰσῆλθεν, καὶ διὰ τῆς ἁμαρτίας ὁ θάνατος, καὶ οὕτως εἰς πάντας ἀνθρώπους ὁ θάνατος διῆλθεν, ἐφʼ ᾧ πάντες ἥμαρτον–
45005013 ἄχρι γὰρ νόμου, ἁμαρτία ἦν ἐν κόσμῳ, ἁμαρτία δὲ οὐκ ἐλλογεῖται, μὴ ὄντος νόμου.
45005014 Ἀλλʼ ἐβασίλευσεν ὁ θάνατος ἀπὸ Ἀδὰμ μέχρι Μωϋσέως, καὶ ἐπὶ τοὺς μὴ ἁμαρτήσαντας ἐπὶ τῷ ὁμοιώματι τῆς παραβάσεως Ἀδάμ, ὅς ἐστιν τύπος τοῦ μέλλοντος.
45005015 Ἀλλʼ οὐχ ὡς τὸ παράπτωμα, οὕτως καὶ τὸ χάρισμα. Εἰ γὰρ τῷ τοῦ ἑνὸς παραπτώματι, οἱ πολλοὶ ἀπέθανον, πολλῷ μᾶλλον ἡ χάρις τοῦ ˚Θεοῦ καὶ ἡ δωρεὰ ἐν χάριτι, τῇ τοῦ ἑνὸς ἀνθρώπου, ˚Ἰησοῦ ˚Χριστοῦ, εἰς τοὺς πολλοὺς ἐπερίσσευσεν.
45005016 Καὶ οὐχ ὡς διʼ ἑνὸς ἁμαρτήσαντος, τὸ δώρημα· τὸ μὲν γὰρ κρίμα ἐξ ἑνὸς, εἰς κατάκριμα, τὸ δὲ χάρισμα ἐκ πολλῶν παραπτωμάτων, εἰς δικαίωμα.
45005017 Εἰ γὰρ τῷ τοῦ ἑνὸς παραπτώματι, ὁ θάνατος ἐβασίλευσεν διὰ τοῦ ἑνός, πολλῷ μᾶλλον οἱ τὴν περισσείαν τῆς χάριτος καὶ τῆς δωρεᾶς τῆς δικαιοσύνης λαμβάνοντες, ἐν ζωῇ βασιλεύσουσιν, διὰ τοῦ ἑνὸς, ˚Ἰησοῦ ˚Χριστοῦ.
45005018 Ἄρα οὖν ὡς διʼ ἑνὸς παραπτώματος, εἰς πάντας ἀνθρώπους εἰς κατάκριμα, οὕτως καὶ διʼ ἑνὸς δικαιώματος, εἰς πάντας ἀνθρώπους εἰς δικαίωσιν ζωῆς.
45005019 Ὥσπερ γὰρ διὰ τῆς παρακοῆς τοῦ ἑνὸς ἀνθρώπου, ἁμαρτωλοὶ κατεστάθησαν οἱ πολλοί, οὕτως καὶ διὰ τῆς ὑπακοῆς τοῦ ἑνὸς, δίκαιοι κατασταθήσονται οἱ πολλοί.
45005020 Νόμος δὲ παρεισῆλθεν, ἵνα πλεονάσῃ τὸ παράπτωμα· οὗ δὲ ἐπλεόνασεν ἡ ἁμαρτία, ὑπερεπερίσσευσεν ἡ χάρις,
45005021 ἵνα ὥσπερ ἐβασίλευσεν ἡ ἁμαρτία ἐν τῷ θανάτῳ, οὕτως καὶ ἡ χάρις βασιλεύσῃ διὰ δικαιοσύνης, εἰς ζωὴν αἰώνιον διὰ ˚Ἰησοῦ ˚Χριστοῦ τοῦ ˚Κυρίου ἡμῶν.
45006001 Τί οὖν ἐροῦμεν; Ἐπιμένωμεν τῇ ἁμαρτίᾳ, ἵνα ἡ χάρις πλεονάσῃ;
45006002 Μὴ γένοιτο! Οἵτινες ἀπεθάνομεν τῇ ἁμαρτίᾳ, πῶς ἔτι ζήσομεν ἐν αὐτῇ;
45006003 Ἢ ἀγνοεῖτε, ὅτι ὅσοι ἐβαπτίσθημεν εἰς ˚Χριστὸν ˚Ἰησοῦν, εἰς τὸν θάνατον αὐτοῦ ἐβαπτίσθημεν;
45006004 Συνετάφημεν οὖν αὐτῷ διὰ τοῦ βαπτίσματος εἰς τὸν θάνατον, ἵνα ὥσπερ ἠγέρθη ˚Χριστὸς ἐκ νεκρῶν διὰ τῆς δόξης τοῦ Πατρός, οὕτως καὶ ἡμεῖς ἐν καινότητι ζωῆς περιπατήσωμεν.
45006005 Εἰ γὰρ σύμφυτοι γεγόναμεν τῷ ὁμοιώματι τοῦ θανάτου αὐτοῦ, ἀλλὰ καὶ τῆς ἀναστάσεως ἐσόμεθα·
45006006 τοῦτο γινώσκοντες ὅτι ὁ παλαιὸς ἡμῶν ἄνθρωπος συνεσταυρώθη, ἵνα καταργηθῇ τὸ σῶμα τῆς ἁμαρτίας, τοῦ μηκέτι δουλεύειν ἡμᾶς τῇ ἁμαρτίᾳ.
45006007 Ὁ γὰρ ἀποθανὼν, δεδικαίωται ἀπὸ τῆς ἁμαρτίας.
45006008 Εἰ δὲ ἀπεθάνομεν σὺν ˚Χριστῷ, πιστεύομεν ὅτι καὶ συζήσομεν αὐτῷ·
45006009 εἰδότες ὅτι ˚Χριστὸς ἐγερθεὶς ἐκ νεκρῶν, οὐκέτι ἀποθνῄσκει, θάνατος αὐτοῦ οὐκέτι κυριεύει.
45006010 Ὃ γὰρ ἀπέθανεν, τῇ ἁμαρτίᾳ ἀπέθανεν ἐφάπαξ· ὃ δὲ ζῇ, ζῇ τῷ ˚Θεῷ.
45006011 Οὕτως καὶ ὑμεῖς, λογίζεσθε ἑαυτοὺς εἶναι νεκροὺς μὲν τῇ ἁμαρτίᾳ, ζῶντας δὲ τῷ ˚Θεῷ ἐν ˚Χριστῷ ˚Ἰησοῦ.
45006012 Μὴ οὖν βασιλευέτω ἡ ἁμαρτία ἐν τῷ θνητῷ ὑμῶν σώματι, εἰς τὸ ὑπακούειν ταῖς ἐπιθυμίαις αὐτοῦ.
45006013 Μηδὲ παριστάνετε τὰ μέλη ὑμῶν, ὅπλα ἀδικίας τῇ ἁμαρτίᾳ, ἀλλὰ παραστήσατε ἑαυτοὺς τῷ ˚Θεῷ, ὡσεὶ ἐκ νεκρῶν ζῶντας, καὶ τὰ μέλη ὑμῶν, ὅπλα δικαιοσύνης τῷ ˚Θεῷ.
45006014 Ἁμαρτία γὰρ ὑμῶν οὐ κυριεύσει, οὐ γάρ ἐστε ὑπὸ νόμον, ἀλλὰ ὑπὸ χάριν.
45006015 Τί οὖν; Ἁμαρτήσωμεν ὅτι οὐκ ἐσμὲν ὑπὸ νόμον, ἀλλὰ ὑπὸ χάριν; Μὴ γένοιτο!
45006016 Οὐκ οἴδατε ὅτι ᾧ παριστάνετε ἑαυτοὺς δούλους εἰς ὑπακοήν, δοῦλοί ἐστε ᾧ ὑπακούετε– ἤτοι ἁμαρτίας εἰς θάνατον, ἢ ὑπακοῆς εἰς δικαιοσύνην;
45006017 Χάρις δὲ τῷ ˚Θεῷ, ὅτι ἦτε δοῦλοι τῆς ἁμαρτίας, ὑπηκούσατε δὲ ἐκ καρδίας, εἰς ὃν παρεδόθητε τύπον διδαχῆς.
45006018 Ἐλευθερωθέντες δὲ ἀπὸ τῆς ἁμαρτίας, ἐδουλώθητε τῇ δικαιοσύνῃ.
45006019 Ἀνθρώπινον λέγω, διὰ τὴν ἀσθένειαν τῆς σαρκὸς ὑμῶν. Ὥσπερ γὰρ παρεστήσατε τὰ μέλη ὑμῶν δοῦλα τῇ ἀκαθαρσίᾳ, καὶ τῇ ἀνομίᾳ εἰς τὴν ἀνομίαν, οὕτως νῦν παραστήσατε τὰ μέλη ὑμῶν, δοῦλα τῇ δικαιοσύνῃ εἰς ἁγιασμόν.
45006020 Ὅτε γὰρ δοῦλοι ἦτε τῆς ἁμαρτίας, ἐλεύθεροι ἦτε τῇ δικαιοσύνῃ.
45006021 Τίνα οὖν καρπὸν εἴχετε τότε, ἐφʼ οἷς νῦν ἐπαισχύνεσθε; Τὸ γὰρ τέλος ἐκείνων θάνατος.
45006022 Νυνὶ δέ, ἐλευθερωθέντες ἀπὸ τῆς ἁμαρτίας, δουλωθέντες δὲ τῷ ˚Θεῷ, ἔχετε τὸν καρπὸν ὑμῶν εἰς ἁγιασμόν, τὸ δὲ τέλος ζωὴν αἰώνιον.
45006023 Τὰ γὰρ ὀψώνια τῆς ἁμαρτίας θάνατος, τὸ δὲ χάρισμα τοῦ ˚Θεοῦ ζωὴ αἰώνιος ἐν ˚Χριστῷ ˚Ἰησοῦ, τῷ ˚Κυρίῳ ἡμῶν.
45007001 Ἢ ἀγνοεῖτε, ἀδελφοί ( γινώσκουσιν γὰρ νόμον λαλῶ ), ὅτι ὁ νόμος κυριεύει τοῦ ἀνθρώπου ἐφʼ ὅσον χρόνον ζῇ;
45007002 Ἡ γὰρ ὕπανδρος γυνὴ τῷ ζῶντι ἀνδρὶ δέδεται νόμῳ· ἐὰν δὲ ἀποθάνῃ ὁ ἀνήρ, κατήργηται ἀπὸ τοῦ νόμου τοῦ ἀνδρός.
45007003 Ἄρα οὖν ζῶντος τοῦ ἀνδρὸς, μοιχαλὶς χρηματίσει, ἐὰν γένηται ἀνδρὶ ἑτέρῳ· ἐὰν δὲ ἀποθάνῃ ὁ ἀνήρ, ἐλευθέρα ἐστὶν ἀπὸ τοῦ νόμου, τοῦ μὴ εἶναι αὐτὴν μοιχαλίδα γενομένην ἀνδρὶ ἑτέρῳ.
45007004 Ὥστε, ἀδελφοί μου, καὶ ὑμεῖς ἐθανατώθητε τῷ νόμῳ διὰ τοῦ σώματος τοῦ ˚Χριστοῦ, εἰς τὸ γενέσθαι ὑμᾶς ἑτέρῳ, τῷ ἐκ νεκρῶν ἐγερθέντι, ἵνα καρποφορήσωμεν τῷ ˚Θεῷ.
45007005 Ὅτε γὰρ ἦμεν ἐν τῇ σαρκί, τὰ παθήματα τῶν ἁμαρτιῶν τὰ διὰ τοῦ νόμου ἐνηργεῖτο ἐν τοῖς μέλεσιν ἡμῶν εἰς τὸ καρποφορῆσαι τῷ θανάτῳ.
45007006 Νυνὶ δὲ κατηργήθημεν ἀπὸ τοῦ νόμου, ἀποθανόντες ἐν ᾧ κατειχόμεθα, ὥστε δουλεύειν ἡμᾶς ἐν καινότητι ˚Πνεύματος, καὶ οὐ παλαιότητι γράμματος.
45007007 Τί οὖν ἐροῦμεν; Ὁ νόμος ἁμαρτία; Μὴ γένοιτο! Ἀλλὰ τὴν ἁμαρτίαν οὐκ ἔγνων, εἰ μὴ διὰ νόμου, τήν τε γὰρ ἐπιθυμίαν, οὐκ ᾔδειν, εἰ μὴ ὁ νόμος ἔλεγεν, “Οὐκ ἐπιθυμήσεις.”
45007008 Ἀφορμὴν δὲ λαβοῦσα ἡ ἁμαρτία διὰ τῆς ἐντολῆς, κατειργάσατο ἐν ἐμοὶ πᾶσαν ἐπιθυμίαν, χωρὶς γὰρ νόμου, ἁμαρτία νεκρά.
45007009 Ἐγὼ δὲ ἔζων χωρὶς νόμου ποτέ· ἐλθούσης δὲ τῆς ἐντολῆς, ἡ ἁμαρτία ἀνέζησεν,
45007010 ἐγὼ δὲ ἀπέθανον, καὶ εὑρέθη μοι ἡ ἐντολὴ, ἡ εἰς ζωὴν, αὕτη εἰς θάνατον.
45007011 Ἡ γὰρ ἁμαρτία ἀφορμὴν λαβοῦσα διὰ τῆς ἐντολῆς, ἐξηπάτησέν με καὶ διʼ αὐτῆς ἀπέκτεινεν.
45007012 Ὥστε ὁ μὲν νόμος ἅγιος, καὶ ἡ ἐντολὴ ἁγία, καὶ δικαία, καὶ ἀγαθή.
45007013 Τὸ οὖν ἀγαθὸν ἐμοὶ ἐγένετο θάνατος; Μὴ γένοιτο! Ἀλλὰ ἡ ἁμαρτία, ἵνα φανῇ ἁμαρτία διὰ τοῦ ἀγαθοῦ μοι κατεργαζομένη θάνατον· ἵνα γένηται καθʼ ὑπερβολὴν ἁμαρτωλὸς ἡ ἁμαρτία διὰ τῆς ἐντολῆς.
45007014 Οἴδαμεν γὰρ ὅτι ὁ νόμος πνευματικός ἐστιν· ἐγὼ δὲ σάρκινός εἰμι, πεπραμένος ὑπὸ τὴν ἁμαρτίαν.
45007015 Ὃ γὰρ κατεργάζομαι, οὐ γινώσκω· οὐ γὰρ ὃ θέλω, τοῦτο πράσσω, ἀλλʼ ὃ μισῶ, τοῦτο ποιῶ.
45007016 Εἰ δὲ ὃ οὐ θέλω, τοῦτο ποιῶ, σύμφημι τῷ νόμῳ ὅτι καλός.
45007017 Νυνὶ δὲ οὐκέτι ἐγὼ κατεργάζομαι αὐτὸ, ἀλλʼ ἡ οἰκοῦσα ἐν ἐμοὶ ἁμαρτία.
45007018 Οἶδα γὰρ ὅτι οὐκ οἰκεῖ ἐν ἐμοί, τοῦτʼ ἔστιν ἐν τῇ σαρκί μου, ἀγαθόν· τὸ γὰρ θέλειν παράκειταί μοι, τὸ δὲ κατεργάζεσθαι τὸ καλὸν οὔ.
45007019 Οὐ γὰρ ὃ θέλω, ποιῶ ἀγαθόν, ἀλλʼ ὃ οὐ θέλω κακὸν, τοῦτο πράσσω.
45007020 Εἰ δὲ ὃ οὐ θέλω, τοῦτο ποιῶ, οὐκέτι ἐγὼ κατεργάζομαι αὐτὸ, ἀλλʼ ἡ οἰκοῦσα ἐν ἐμοὶ ἁμαρτία.
45007021 Εὑρίσκω ἄρα τὸν νόμον, τῷ θέλοντι ἐμοὶ ποιεῖν τὸ καλὸν, ὅτι ἐμοὶ τὸ κακὸν παράκειται.
45007022 Συνήδομαι γὰρ τῷ νόμῳ τοῦ ˚Θεοῦ, κατὰ τὸν ἔσω ἄνθρωπον,
45007023 βλέπω δὲ ἕτερον νόμον ἐν τοῖς μέλεσίν μου, ἀντιστρατευόμενον τῷ νόμῳ τοῦ νοός μου, καὶ αἰχμαλωτίζοντά με ἐν τῷ νόμῳ τῆς ἁμαρτίας, τῷ ὄντι ἐν τοῖς μέλεσίν μου.
45007024 Ταλαίπωρος ἐγὼ ἄνθρωπος! Τίς με ῥύσεται ἐκ τοῦ σώματος τοῦ θανάτου τούτου;
45007025 Χάρις τῷ ˚Θεῷ διὰ ˚Ἰησοῦ ˚Χριστοῦ τοῦ ˚Κυρίου ἡμῶν! Ἄρα οὖν αὐτὸς ἐγὼ, τῷ μὲν νοῒ δουλεύω νόμῳ ˚Θεοῦ, τῇ δὲ σαρκὶ, νόμῳ ἁμαρτίας.
45008001 Οὐδὲν ἄρα νῦν κατάκριμα τοῖς ἐν ˚Χριστῷ ˚Ἰησοῦ,
45008002 ὁ γὰρ νόμος τοῦ ˚Πνεύματος τῆς ζωῆς ἐν ˚Χριστῷ ˚Ἰησοῦ, ἠλευθέρωσέν σε ἀπὸ τοῦ νόμου τῆς ἁμαρτίας καὶ τοῦ θανάτου.
45008003 Τὸ γὰρ ἀδύνατον τοῦ νόμου, ἐν ᾧ ἠσθένει διὰ τῆς σαρκός, ὁ ˚Θεὸς τὸν ἑαυτοῦ Υἱὸν πέμψας, ἐν ὁμοιώματι σαρκὸς ἁμαρτίας, καὶ περὶ ἁμαρτίας, κατέκρινεν τὴν ἁμαρτίαν ἐν τῇ σαρκί,
45008004 ἵνα τὸ δικαίωμα τοῦ νόμου πληρωθῇ ἐν ἡμῖν, τοῖς μὴ κατὰ σάρκα περιπατοῦσιν, ἀλλὰ κατὰ ˚Πνεῦμα.
45008005 Οἱ γὰρ κατὰ σάρκα ὄντες, τὰ τῆς σαρκὸς φρονοῦσιν, οἱ δὲ κατὰ ˚Πνεῦμα, τὰ τοῦ ˚Πνεύματος.
45008006 Τὸ γὰρ φρόνημα τῆς σαρκὸς θάνατος, τὸ δὲ φρόνημα τοῦ ˚Πνεύματος ζωὴ καὶ εἰρήνη.
45008007 Διότι τὸ φρόνημα τῆς σαρκὸς ἔχθρα εἰς ˚Θεόν, τῷ γὰρ νόμῳ τοῦ ˚Θεοῦ οὐχ ὑποτάσσεται, οὐδὲ γὰρ δύναται.
45008008 Οἱ δὲ ἐν σαρκὶ ὄντες, ˚Θεῷ ἀρέσαι οὐ δύνανται.
45008009 Ὑμεῖς δὲ οὐκ ἐστὲ ἐν σαρκὶ, ἀλλʼ ἐν ˚Πνεύματι, εἴπερ ˚Πνεῦμα ˚Θεοῦ οἰκεῖ ἐν ὑμῖν. Εἰ δέ τις ˚Πνεῦμα ˚Χριστοῦ οὐκ ἔχει, οὗτος οὐκ ἔστιν αὐτοῦ.
45008010 Εἰ δὲ ˚Χριστὸς ἐν ὑμῖν, τὸ μὲν σῶμα νεκρὸν διὰ ἁμαρτίαν, τὸ δὲ ˚Πνεῦμα ζωὴ διὰ δικαιοσύνην.
45008011 Εἰ δὲ τὸ ˚Πνεῦμα τοῦ ἐγείραντος τὸν ˚Ἰησοῦν ἐκ νεκρῶν οἰκεῖ ἐν ὑμῖν, ὁ ἐγείρας ἐκ νεκρῶν ˚Χριστὸν ˚Ἰησοῦν ζῳοποιήσει καὶ τὰ θνητὰ σώματα ὑμῶν, διὰ τοῦ ἐνοικοῦντος αὐτοῦ ˚Πνεύματος ἐν ὑμῖν.
45008012 Ἄρα οὖν, ἀδελφοί, ὀφειλέται ἐσμέν, οὐ τῇ σαρκὶ, τοῦ κατὰ σάρκα ζῆν.
45008013 Εἰ γὰρ κατὰ σάρκα ζῆτε, μέλλετε ἀποθνῄσκειν, εἰ δὲ ˚Πνεύματι τὰς πράξεις τοῦ σώματος θανατοῦτε, ζήσεσθε.
45008014 Ὅσοι γὰρ ˚Πνεύματι ˚Θεοῦ ἄγονται, οὗτοι υἱοὶ ˚Θεοῦ εἰσιν.
45008015 Οὐ γὰρ ἐλάβετε πνεῦμα δουλείας πάλιν εἰς φόβον, ἀλλὰ ἐλάβετε ˚Πνεῦμα υἱοθεσίας, ἐν ᾧ κράζομεν, “Ἀββά, ὁ Πατήρ!”
45008016 Αὐτὸ τὸ ˚Πνεῦμα συμμαρτυρεῖ τῷ ˚Πνεύματι ἡμῶν ὅτι ἐσμὲν τέκνα ˚Θεοῦ.
45008017 Εἰ δὲ τέκνα, καὶ κληρονόμοι· κληρονόμοι μὲν ˚Θεοῦ, συγκληρονόμοι δὲ ˚Χριστοῦ, εἴπερ συμπάσχομεν, ἵνα καὶ συνδοξασθῶμεν.
45008018 Λογίζομαι γὰρ ὅτι οὐκ ἄξια τὰ παθήματα τοῦ νῦν καιροῦ, πρὸς τὴν μέλλουσαν δόξαν ἀποκαλυφθῆναι εἰς ἡμᾶς.
45008019 Ἡ γὰρ ἀποκαραδοκία τῆς κτίσεως, τὴν ἀποκάλυψιν τῶν υἱῶν τοῦ ˚Θεοῦ ἀπεκδέχεται.
45008020 Τῇ γὰρ ματαιότητι, ἡ κτίσις ὑπετάγη, οὐχ ἑκοῦσα, ἀλλὰ διὰ τὸν ὑποτάξαντα, ἐφʼ ἑλπίδι
45008021 ὅτι καὶ αὐτὴ ἡ κτίσις ἐλευθερωθήσεται ἀπὸ τῆς δουλείας τῆς φθορᾶς, εἰς τὴν ἐλευθερίαν τῆς δόξης τῶν τέκνων τοῦ ˚Θεοῦ.
45008022 Οἴδαμεν γὰρ ὅτι πᾶσα ἡ κτίσις συστενάζει καὶ συνωδίνει ἄχρι τοῦ νῦν.
45008023 Οὐ μόνον δέ, ἀλλὰ καὶ αὐτοὶ, τὴν ἀπαρχὴν τοῦ ˚Πνεύματος ἔχοντες, ἡμεῖς καὶ αὐτοὶ ἐν ἑαυτοῖς στενάζομεν, υἱοθεσίαν ἀπεκδεχόμενοι, τὴν ἀπολύτρωσιν τοῦ σώματος ἡμῶν.
45008024 Τῇ γὰρ ἐλπίδι ἐσώθημεν· ἐλπὶς δὲ βλεπομένη, οὐκ ἔστιν ἐλπίς, ὃ γὰρ βλέπει τις, ἐλπίζει;
45008025 Εἰ δὲ ὃ οὐ βλέπομεν ἐλπίζομεν, διʼ ὑπομονῆς ἀπεκδεχόμεθα.
45008026 Ὡσαύτως δὲ καὶ τὸ ˚Πνεῦμα συναντιλαμβάνεται τῇ ἀσθενείᾳ ἡμῶν· τὸ γὰρ τί προσευξώμεθα καθὸ δεῖ, οὐκ οἴδαμεν, ἀλλὰ αὐτὸ τὸ ˚Πνεῦμα ὑπερεντυγχάνει στεναγμοῖς ἀλαλήτοις.
45008027 Ὁ δὲ ἐραυνῶν τὰς καρδίας οἶδεν τί τὸ φρόνημα τοῦ ˚Πνεύματος, ὅτι κατὰ ˚Θεὸν, ἐντυγχάνει ὑπὲρ ἁγίων.
45008028 Οἴδαμεν δὲ ὅτι τοῖς ἀγαπῶσιν τὸν ˚Θεὸν, πάντα συνεργεῖ ὁ ˚Θεὸς εἰς ἀγαθόν, τοῖς κατὰ πρόθεσιν κλητοῖς οὖσιν.
45008029 Ὅτι οὓς προέγνω, καὶ προώρισεν συμμόρφους τῆς εἰκόνος τοῦ Υἱοῦ αὐτοῦ, εἰς τὸ εἶναι αὐτὸν πρωτότοκον ἐν πολλοῖς ἀδελφοῖς.
45008030 Οὓς δὲ προώρισεν, τούτους καὶ ἐκάλεσεν· καὶ οὓς ἐκάλεσεν, τούτους καὶ ἐδικαίωσεν· οὓς δὲ ἐδικαίωσεν, τούτους καὶ ἐδόξασεν.
45008031 Τί οὖν ἐροῦμεν πρὸς ταῦτα; Εἰ ὁ ˚Θεὸς ὑπὲρ ἡμῶν, τίς καθʼ ἡμῶν;
45008032 Ὅς γε τοῦ ἰδίου Υἱοῦ οὐκ ἐφείσατο, ἀλλὰ ὑπὲρ ἡμῶν πάντων παρέδωκεν αὐτόν, πῶς οὐχὶ καὶ σὺν αὐτῷ, τὰ πάντα ἡμῖν χαρίσεται;
45008033 Τίς ἐγκαλέσει κατὰ ἐκλεκτῶν ˚Θεοῦ; ˚Θεὸς ὁ δικαιῶν.
45008034 Τίς ὁ κατακρινῶν; ˚Χριστὸς ˚Ἰησοῦς ὁ ἀποθανών, μᾶλλον δὲ ἐγερθείς ὅς, καί ἐστιν ἐν δεξιᾷ τοῦ ˚Θεοῦ, ὃς καὶ ἐντυγχάνει ὑπὲρ ἡμῶν.
45008035 Τίς ἡμᾶς χωρίσει ἀπὸ τῆς ἀγάπης τοῦ ˚Χριστοῦ; Θλῖψις, ἢ στενοχωρία, ἢ διωγμὸς, ἢ λιμὸς, ἢ γυμνότης, ἢ κίνδυνος, ἢ μάχαιρα;
45008036 Καθὼς γέγραπται, ὅτι “Ἕνεκεν σοῦ, θανατούμεθα ὅλην τὴν ἡμέραν, ἐλογίσθημεν ὡς πρόβατα σφαγῆς.”
45008037 Ἀλλʼ ἐν τούτοις πᾶσιν, ὑπερνικῶμεν διὰ τοῦ ἀγαπήσαντος ἡμᾶς.
45008038 Πέπεισμαι γὰρ ὅτι οὔτε θάνατος, οὔτε ζωὴ, οὔτε ἄγγελοι, οὔτε ἀρχαὶ, οὔτε ἐνεστῶτα, οὔτε μέλλοντα, οὔτε δυνάμεις,
45008039 οὔτε ὕψωμα, οὔτε βάθος, οὔτε τις κτίσις ἑτέρα, δυνήσεται ἡμᾶς χωρίσαι ἀπὸ τῆς ἀγάπης τοῦ ˚Θεοῦ, τῆς ἐν ˚Χριστῷ ˚Ἰησοῦ, τῷ ˚Κυρίῳ ἡμῶν.
45009001 Ἀλήθειαν λέγω ἐν ˚Χριστῷ, οὐ ψεύδομαι, συμμαρτυρούσης μοι τῆς συνειδήσεώς μου ἐν ˚Πνεύματι Ἁγίῳ
45009002 ὅτι λύπη μοί ἐστιν μεγάλη, καὶ ἀδιάλειπτος ὀδύνη τῇ καρδίᾳ μου.
45009003 Ηὐχόμην γὰρ ἀνάθεμα εἶναι αὐτὸς, ἐγὼ ἀπὸ τοῦ ˚Χριστοῦ ὑπὲρ τῶν ἀδελφῶν μου, τῶν συγγενῶν μου, κατὰ σάρκα,
45009004 οἵτινές εἰσιν Ἰσραηλῖται, ὧν ἡ υἱοθεσία, καὶ ἡ δόξα, καὶ αἱ διαθῆκαι, καὶ ἡ νομοθεσία, καὶ ἡ λατρεία, καὶ αἱ ἐπαγγελίαι,
45009005 ὧν οἱ πατέρες, καὶ ἐξ ὧν ὁ ˚Χριστὸς, τὸ κατὰ σάρκα ὁ ὢν ἐπὶ πάντων ˚Θεὸς, εὐλογητὸς εἰς τοὺς αἰῶνας. Ἀμήν!
45009006 Οὐχ οἷον δὲ, ὅτι ἐκπέπτωκεν ὁ λόγος τοῦ ˚Θεοῦ. Οὐ γὰρ πάντες οἱ ἐξ Ἰσραήλ οὗτοι, Ἰσραήλ.
45009007 Οὐδʼ ὅτι εἰσὶν σπέρμα Ἀβραάμ πάντες τέκνα, ἀλλʼ: “Ἐν Ἰσαὰκ κληθήσεταί σοι σπέρμα.”
45009008 Τοῦτʼ ἔστιν οὐ τὰ τέκνα τῆς σαρκὸς, ταῦτα τέκνα τοῦ ˚Θεοῦ, ἀλλὰ τὰ τέκνα τῆς ἐπαγγελίας λογίζεται εἰς σπέρμα.
45009009 Ἐπαγγελίας γὰρ ὁ λόγος οὗτος: “Κατὰ τὸν καιρὸν τοῦτον, ἐλεύσομαι, καὶ ἔσται τῇ Σάρρᾳ υἱός.”
45009010 Οὐ μόνον δέ, ἀλλὰ καὶ Ῥεβέκκα ἐξ ἑνὸς κοίτην, ἔχουσα Ἰσαὰκ, τοῦ πατρὸς ἡμῶν·
45009011 μήπω γὰρ γεννηθέντων, μηδὲ πραξάντων τι ἀγαθὸν ἢ φαῦλον, ἵνα ἡ κατʼ ἐκλογὴν πρόθεσις τοῦ ˚Θεοῦ μένῃ,
45009012 οὐκ ἐξ ἔργων, ἀλλʼ ἐκ τοῦ καλοῦντος, ἐρρέθη αὐτῇ, ὅτι “Ὁ μείζων δουλεύσει τῷ ἐλάσσονι.”
45009013 Καθὼς γέγραπται, “Τὸν Ἰακὼβ ἠγάπησα, τὸν δὲ Ἠσαῦ ἐμίσησα.”
45009014 Τί οὖν ἐροῦμεν; Μὴ ἀδικία παρὰ τῷ ˚Θεῷ; Μὴ γένοιτο!
45009015 Τῷ Μωϋσεῖ γὰρ λέγει, “Ἐλεήσω ὃν ἂν ἐλεῶ, καὶ οἰκτιρήσω ὃν ἂν οἰκτίρω.”
45009016 Ἄρα οὖν οὐ τοῦ θέλοντος, οὐδὲ τοῦ τρέχοντος, ἀλλὰ τοῦ ἐλεῶντος ˚Θεοῦ.
45009017 Λέγει γὰρ ἡ Γραφὴ τῷ Φαραὼ, ὅτι “Εἰς αὐτὸ τοῦτο ἐξήγειρά σε, ὅπως ἐνδείξωμαι ἐν σοὶ τὴν δύναμίν μου, καὶ ὅπως διαγγελῇ τὸ ὄνομά μου ἐν πάσῃ τῇ γῇ.”
45009018 Ἄρα οὖν ὃν θέλει, ἐλεεῖ, ὃν δὲ θέλει, σκληρύνει.
45009019 Ἐρεῖς μοι οὖν, “Τί οὖν ἔτι μέμφεται; Τῷ γὰρ βουλήματι αὐτοῦ τίς ἀνθέστηκεν;”
45009020 Ὦ ἄνθρωπε, μενοῦνγε σὺ τίς εἶ, ὁ ἀνταποκρινόμενος τῷ ˚Θεῷ; Μὴ ἐρεῖ τὸ πλάσμα, τῷ πλάσαντι, “Τί με ἐποίησας οὕτως;”
45009021 Ἢ οὐκ ἔχει ἐξουσίαν ὁ κεραμεὺς τοῦ πηλοῦ, ἐκ τοῦ αὐτοῦ φυράματος ποιῆσαι ὃ μὲν εἰς τιμὴν σκεῦος, ὃ δὲ εἰς ἀτιμίαν;
45009022 Εἰ δὲ θέλων ὁ ˚Θεὸς ἐνδείξασθαι τὴν ὀργὴν, καὶ γνωρίσαι τὸ δυνατὸν αὐτοῦ, ἤνεγκεν ἐν πολλῇ μακροθυμίᾳ, σκεύη ὀργῆς κατηρτισμένα εἰς ἀπώλειαν,
45009023 καὶ ἵνα γνωρίσῃ τὸν πλοῦτον τῆς δόξης αὐτοῦ ἐπὶ σκεύη ἐλέους, ἃ προητοίμασεν εἰς δόξαν,
45009024 οὓς καὶ ἐκάλεσεν, ἡμᾶς οὐ μόνον ἐξ Ἰουδαίων, ἀλλὰ καὶ ἐξ ἐθνῶν;
45009025 Ὡς καὶ ἐν τῷ Ὡσηὲ λέγει, “Καλέσω τὸν οὐ λαόν μου, ‘Λαόν μου’, καὶ τὴν οὐκ ἠγαπημένην, ‘Ἠγαπημένην’.”
45009026 “Καὶ ἔσται ἐν τῷ τόπῳ οὗ ἐρρέθη αὐτοῖς, ‘Οὐ λαός μου ὑμεῖς’, ἐκεῖ κληθήσονται υἱοὶ ˚Θεοῦ ζῶντος.”
45009027 Ἠσαΐας δὲ κράζει ὑπὲρ τοῦ Ἰσραήλ, “Ἐὰν ᾖ ὁ ἀριθμὸς τῶν υἱῶν Ἰσραὴλ, ὡς ἡ ἄμμος τῆς θαλάσσης, τὸ ὑπόλειμμα σωθήσεται.
45009028 Λόγον γὰρ συντελῶν καὶ συντέμνων ποιήσει ˚Κύριος ἐπὶ τῆς γῆς.”
45009029 Καὶ καθὼς προείρηκεν Ἠσαΐας, “Εἰ μὴ ˚Κύριος Σαβαὼθ ἐγκατέλιπεν ἡμῖν σπέρμα, ὡς Σόδομα ἂν ἐγενήθημεν, καὶ ὡς Γόμορρα ἂν ὡμοιώθημεν.”
45009030 Τί οὖν ἐροῦμεν; Ὅτι ἔθνη τὰ μὴ διώκοντα δικαιοσύνην, κατέλαβεν δικαιοσύνην, δικαιοσύνην δὲ τὴν ἐκ πίστεως·
45009031 Ἰσραὴλ δὲ διώκων νόμον δικαιοσύνης εἰς νόμον οὐκ ἔφθασεν.
45009032 Διὰ τί; Ὅτι οὐκ ἐκ πίστεως, ἀλλʼ ὡς ἐξ ἔργων. Προσέκοψαν τῷ λίθῳ τοῦ προσκόμματος,
45009033 καθὼς γέγραπται, “Ἰδοὺ, τίθημι ἐν Σιὼν λίθον προσκόμματος, καὶ πέτραν σκανδάλου, καὶ ὁ πιστεύων ἐπʼ αὐτῷ, οὐ καταισχυνθήσεται.”
45010001 Ἀδελφοί, ἡ μὲν εὐδοκία τῆς ἐμῆς καρδίας, καὶ ἡ δέησις πρὸς τὸν ˚Θεὸν, ὑπὲρ αὐτῶν εἰς σωτηρίαν.
45010002 Μαρτυρῶ γὰρ αὐτοῖς ὅτι ζῆλον ˚Θεοῦ ἔχουσιν, ἀλλʼ οὐ κατʼ ἐπίγνωσιν.
45010003 Ἀγνοοῦντες γὰρ τὴν τοῦ ˚Θεοῦ δικαιοσύνην, καὶ τὴν ἰδίαν ζητοῦντες στῆσαι, τῇ δικαιοσύνῃ τοῦ ˚Θεοῦ οὐχ ὑπετάγησαν.
45010004 Τέλος γὰρ νόμου ˚Χριστὸς, εἰς δικαιοσύνην παντὶ τῷ πιστεύοντι.
45010005 Μωϋσῆς γὰρ γράφει τὴν δικαιοσύνην τὴν ἐκ νόμου, ὅτι “Ὁ ποιήσας αὐτὰ ἄνθρωπος, ζήσεται ἐν αὐτῇ.”
45010006 Ἡ δὲ ἐκ πίστεως δικαιοσύνη οὕτως λέγει, “Μὴ εἴπῃς ἐν τῇ καρδίᾳ σου, ‘Τίς ἀναβήσεται εἰς τὸν οὐρανόν;’” ( Τοῦτʼ ἔστιν ˚Χριστὸν καταγαγεῖν ),
45010007 ἤ, “‘Τίς καταβήσεται εἰς τὴν Ἄβυσσον;’” ( Τοῦτʼ ἔστιν ˚Χριστὸν ἐκ νεκρῶν ἀναγαγεῖν ).
45010008 Ἀλλὰ τί λέγει; “Ἐγγύς σου τὸ ῥῆμά ἐστιν, ἐν τῷ στόματί σου, καὶ ἐν τῇ καρδίᾳ σου”, τοῦτʼ ἔστιν τὸ ῥῆμα τῆς πίστεως ὃ κηρύσσομεν.
45010009 Ὅτι ἐὰν ὁμολογήσῃς ἐν τῷ στόματί σου, “˚Κύριον ˚Ἰησοῦν”, καὶ πιστεύσῃς ἐν τῇ καρδίᾳ σου ὅτι ὁ ˚Θεὸς αὐτὸν ἤγειρεν ἐκ νεκρῶν, σωθήσῃ.
45010010 Καρδίᾳ γὰρ πιστεύεται εἰς δικαιοσύνην, στόματι δὲ ὁμολογεῖται εἰς σωτηρίαν.
45010011 Λέγει γὰρ ἡ Γραφή, “Πᾶς ὁ πιστεύων ἐπʼ αὐτῷ οὐ καταισχυνθήσεται.”
45010012 Οὐ γάρ ἐστιν διαστολὴ Ἰουδαίου τε καὶ Ἕλληνος, ὁ γὰρ αὐτὸς ˚Κύριος πάντων, πλουτῶν εἰς πάντας τοὺς ἐπικαλουμένους αὐτόν.
45010013 “Πᾶς” γὰρ “ὃς ἂν ἐπικαλέσηται τὸ ὄνομα ˚Κυρίου, σωθήσεται.”
45010014 Πῶς οὖν ἐπικαλέσωνται εἰς ὃν οὐκ ἐπίστευσαν; Πῶς δὲ πιστεύσωσιν οὗ οὐκ ἤκουσαν; Πῶς δὲ ἀκούσωσιν χωρὶς κηρύσσοντος;
45010015 Πῶς δὲ κηρύξωσιν ἐὰν μὴ ἀποσταλῶσιν; Καθὼς γέγραπται, “Ὡς ὡραῖοι οἱ πόδες τῶν εὐαγγελιζομένων ἀγαθά!”
45010016 Ἀλλʼ οὐ πάντες ὑπήκουσαν τῷ εὐαγγελίῳ. Ἠσαΐας γὰρ λέγει, “˚Κύριε, τίς ἐπίστευσεν τῇ ἀκοῇ ἡμῶν;”
45010017 Ἄρα ἡ πίστις ἐξ ἀκοῆς, ἡ δὲ ἀκοὴ διὰ ῥήματος ˚Χριστοῦ.
45010018 Ἀλλὰ λέγω, μὴ οὐκ ἤκουσαν; Μενοῦνγε, “Εἰς πᾶσαν τὴν γῆν, ἐξῆλθεν ὁ φθόγγος αὐτῶν, καὶ εἰς τὰ πέρατα τῆς οἰκουμένης, τὰ ῥήματα αὐτῶν.”
45010019 Ἀλλὰ λέγω, μὴ Ἰσραὴλ οὐκ ἔγνω; Πρῶτος Μωϋσῆς λέγει, “Ἐγὼ παραζηλώσω ὑμᾶς, ἐπʼ οὐκ ἔθνει, ἐπʼ ἔθνει ἀσυνέτῳ, παροργιῶ ὑμᾶς.”
45010020 Ἠσαΐας δὲ ἀποτολμᾷ καὶ λέγει, “Εὑρέθην ἐν τοῖς ἐμὲ μὴ ζητοῦσιν, ἐμφανὴς ἐγενόμην τοῖς ἐμὲ μὴ ἐπερωτῶσιν.”
45010021 Πρὸς δὲ τὸν Ἰσραὴλ λέγει, “Ὅλην τὴν ἡμέραν, ἐξεπέτασα τὰς χεῖράς μου πρὸς λαὸν ἀπειθοῦντα καὶ ἀντιλέγοντα.”
45011001 Λέγω οὖν, μὴ ἀπώσατο ὁ ˚Θεὸς τὸν λαὸν αὐτοῦ; Μὴ γένοιτο! Καὶ γὰρ ἐγὼ Ἰσραηλίτης εἰμί, ἐκ σπέρματος Ἀβραάμ, φυλῆς Βενιαμίν.
45011002 Οὐκ ἀπώσατο ὁ ˚Θεὸς τὸν λαὸν αὐτοῦ, ὃν προέγνω. Ἢ οὐκ οἴδατε ἐν Ἠλίᾳ τί λέγει ἡ Γραφή, ὡς ἐντυγχάνει τῷ ˚Θεῷ κατὰ τοῦ Ἰσραήλ;
45011003 “˚Κύριε, τοὺς προφήτας σου ἀπέκτειναν, τὰ θυσιαστήριά σου κατέσκαψαν, κἀγὼ ὑπελείφθην μόνος, καὶ ζητοῦσιν τὴν ψυχήν μου.”
45011004 Ἀλλὰ τί λέγει αὐτῷ ὁ χρηματισμός; “Κατέλιπον ἐμαυτῷ ἑπτακισχιλίους ἄνδρας, οἵτινες οὐκ ἔκαμψαν γόνυ τῇ Βάαλ.”
45011005 Οὕτως οὖν καὶ ἐν τῷ νῦν καιρῷ, λεῖμμα κατʼ ἐκλογὴν χάριτος γέγονεν.
45011006 Εἰ δὲ χάριτι, οὐκέτι ἐξ ἔργων, ἐπεὶ ἡ χάρις οὐκέτι γίνεται χάρις.
45011007 Τί οὖν; Ὃ ἐπιζητεῖ Ἰσραήλ, τοῦτο οὐκ ἐπέτυχεν, ἡ δὲ ἐκλογὴ ἐπέτυχεν, οἱ δὲ λοιποὶ ἐπωρώθησαν,
45011008 καθὼς γέγραπται, “Ἔδωκεν αὐτοῖς ὁ ˚Θεὸς πνεῦμα κατανύξεως”, “Ὀφθαλμοὺς τοῦ μὴ βλέπειν, καὶ ὦτα τοῦ μὴ ἀκούειν, ἕως τῆς σήμερον ἡμέρας.”
45011009 Καὶ Δαυὶδ λέγει, “Γενηθήτω ἡ τράπεζα αὐτῶν εἰς παγίδα, καὶ εἰς θήραν, καὶ εἰς σκάνδαλον, καὶ εἰς ἀνταπόδομα αὐτοῖς.
45011010 Σκοτισθήτωσαν οἱ ὀφθαλμοὶ αὐτῶν, τοῦ μὴ βλέπειν, καὶ τὸν νῶτον αὐτῶν διὰ παντὸς σύγκαμψον.”
45011011 Λέγω οὖν μὴ ἔπταισαν, ἵνα πέσωσιν; Μὴ γένοιτο! Ἀλλὰ τῷ αὐτῶν παραπτώματι, ἡ σωτηρία τοῖς ἔθνεσιν, εἰς τὸ παραζηλῶσαι αὐτούς.
45011012 Εἰ δὲ τὸ παράπτωμα αὐτῶν, πλοῦτος κόσμου, καὶ τὸ ἥττημα αὐτῶν, πλοῦτος ἐθνῶν, πόσῳ μᾶλλον τὸ πλήρωμα αὐτῶν;
45011013 Ὑμῖν δὲ λέγω, τοῖς ἔθνεσιν. Ἐφʼ ὅσον μὲν οὖν εἰμι ἐγὼ ἐθνῶν ἀπόστολος, τὴν διακονίαν μου δοξάζω,
45011014 εἴ πως παραζηλώσω μου τὴν σάρκα, καὶ σώσω τινὰς ἐξ αὐτῶν.
45011015 Εἰ γὰρ ἡ ἀποβολὴ αὐτῶν καταλλαγὴ κόσμου, τίς ἡ πρόσλημψις εἰ μὴ ζωὴ ἐκ νεκρῶν;
45011016 Εἰ δὲ ἡ ἀπαρχὴ ἁγία, καὶ τὸ φύραμα· καὶ εἰ ἡ ῥίζα ἁγία, καὶ οἱ κλάδοι.
45011017 Εἰ δέ τινες τῶν κλάδων ἐξεκλάσθησαν, σὺ δὲ ἀγριέλαιος ὢν ἐνεκεντρίσθης ἐν αὐτοῖς, καὶ συγκοινωνὸς τῆς ῥίζης τῆς πιότητος τῆς ἐλαίας ἐγένου,
45011018 μὴ κατακαυχῶ τῶν κλάδων. Εἰ δὲ κατακαυχᾶσαι, οὐ σὺ τὴν ῥίζαν βαστάζεις, ἀλλʼ ἡ ῥίζα σέ.
45011019 Ἐρεῖς οὖν, “Ἐξεκλάσθησαν κλάδοι, ἵνα ἐγὼ ἐγκεντρισθῶ.”
45011020 Καλῶς, τῇ ἀπιστίᾳ ἐξεκλάσθησαν, σὺ δὲ τῇ πίστει ἕστηκας. Μὴ ὑψηλὰ φρόνει, ἀλλὰ φοβοῦ.
45011021 Εἰ γὰρ ὁ ˚Θεὸς τῶν κατὰ φύσιν κλάδων οὐκ ἐφείσατο, οὐδὲ σοῦ φείσεται.
45011022 Ἴδε οὖν, χρηστότητα καὶ ἀποτομίαν ˚Θεοῦ· ἐπὶ μὲν τοὺς πεσόντας, ἀποτομία, ἐπὶ δὲ σὲ, χρηστότης ˚Θεοῦ, ἐὰν ἐπιμένῃς τῇ χρηστότητι, ἐπεὶ καὶ σὺ ἐκκοπήσῃ.
45011023 Κἀκεῖνοι δέ ἐὰν μὴ ἐπιμένωσιν τῇ ἀπιστίᾳ, ἐγκεντρισθήσονται· δυνατὸς γάρ ἐστιν ὁ ˚Θεὸς πάλιν ἐγκεντρίσαι αὐτούς.
45011024 Εἰ γὰρ σὺ ἐκ τῆς κατὰ φύσιν ἐξεκόπης ἀγριελαίου, καὶ παρὰ φύσιν ἐνεκεντρίσθης εἰς καλλιέλαιον, πόσῳ μᾶλλον οὗτοι, οἱ κατὰ φύσιν ἐγκεντρισθήσονται τῇ ἰδίᾳ ἐλαίᾳ;
45011025 Οὐ γὰρ θέλω ὑμᾶς ἀγνοεῖν, ἀδελφοί, τὸ μυστήριον τοῦτο, ἵνα μὴ ἦτε ἐν ἑαυτοῖς φρόνιμοι, ὅτι πώρωσις ἀπὸ μέρους τῷ Ἰσραὴλ γέγονεν, ἄχρι οὗ τὸ πλήρωμα τῶν ἐθνῶν εἰσέλθῃ,
45011026 καὶ οὕτως πᾶς Ἰσραὴλ σωθήσεται, καθὼς γέγραπται, “Ἥξει ἐκ Σιὼν ὁ ῥυόμενος, ἀποστρέψει ἀσεβείας ἀπὸ Ἰακώβ.
45011027 Καὶ αὕτη αὐτοῖς, ἡ παρʼ ἐμοῦ διαθήκη”, “Ὅταν ἀφέλωμαι τὰς ἁμαρτίας αὐτῶν.”
45011028 Κατὰ μὲν τὸ εὐαγγέλιον, ἐχθροὶ διʼ ὑμᾶς, κατὰ δὲ τὴν ἐκλογὴν, ἀγαπητοὶ διὰ τοὺς πατέρας.
45011029 Ἀμεταμέλητα γὰρ τὰ χαρίσματα καὶ ἡ κλῆσις τοῦ ˚Θεοῦ.
45011030 Ὥσπερ γὰρ ὑμεῖς ποτε ἠπειθήσατε τῷ ˚Θεῷ, νῦν δὲ ἠλεήθητε τῇ τούτων ἀπειθείᾳ,
45011031 οὕτως καὶ οὗτοι νῦν ἠπείθησαν τῷ ὑμετέρῳ ἐλέει, ἵνα καὶ αὐτοὶ νῦν ἐλεηθῶσιν.
45011032 Συνέκλεισεν γὰρ ὁ ˚Θεὸς τοὺς πάντας εἰς ἀπείθειαν, ἵνα τοὺς πάντας ἐλεήσῃ.
45011033 Ὦ βάθος πλούτου, καὶ σοφίας καὶ γνώσεως ˚Θεοῦ! Ὡς ἀνεξεραύνητα τὰ κρίματα αὐτοῦ, καὶ ἀνεξιχνίαστοι αἱ ὁδοὶ αὐτοῦ!
45011034 “Τίς” γὰρ “ἔγνω νοῦν ˚Κυρίου, ἢ τίς σύμβουλος αὐτοῦ ἐγένετο;”
45011035 “Ἢ τίς προέδωκεν αὐτῷ, καὶ ἀνταποδοθήσεται αὐτῷ;”
45011036 Ὅτι ἐξ αὐτοῦ, καὶ διʼ αὐτοῦ, καὶ εἰς αὐτὸν, τὰ πάντα. Αὐτῷ ἡ δόξα εἰς τοὺς αἰῶνας. Ἀμήν!
45012001 Παρακαλῶ οὖν ὑμᾶς, ἀδελφοί, διὰ τῶν οἰκτιρμῶν τοῦ ˚Θεοῦ, παραστῆσαι τὰ σώματα ὑμῶν θυσίαν ζῶσαν, ἁγίαν, τῷ ˚Θεῷ εὐάρεστον, τὴν λογικὴν λατρείαν ὑμῶν.
45012002 Καὶ μὴ συσχηματίζεσθε τῷ αἰῶνι τούτῳ, ἀλλὰ μεταμορφοῦσθε τῇ ἀνακαινώσει τοῦ νοός, εἰς τὸ δοκιμάζειν ὑμᾶς, τί τὸ θέλημα τοῦ ˚Θεοῦ, τὸ ἀγαθὸν, καὶ εὐάρεστον, καὶ τέλειον.
45012003 Λέγω γὰρ διὰ τῆς χάριτος τῆς δοθείσης μοι, παντὶ τῷ ὄντι ἐν ὑμῖν, μὴ ὑπερφρονεῖν παρʼ ὃ δεῖ φρονεῖν, ἀλλὰ φρονεῖν εἰς τὸ σωφρονεῖν, ἑκάστῳ ὡς ὁ ˚Θεὸς ἐμέρισεν μέτρον πίστεως.
45012004 Καθάπερ γὰρ ἐν ἑνὶ σώματι, πολλὰ μέλη ἔχομεν, τὰ δὲ μέλη πάντα οὐ τὴν αὐτὴν ἔχει πρᾶξιν,
45012005 οὕτως οἱ πολλοὶ, ἓν σῶμά ἐσμεν ἐν ˚Χριστῷ, τὸ δὲ καθʼ εἷς ἀλλήλων μέλη.
45012006 Ἔχοντες δὲ χαρίσματα κατὰ τὴν χάριν τὴν δοθεῖσαν ἡμῖν διάφορα: εἴτε προφητείαν, κατὰ τὴν ἀναλογίαν τῆς πίστεως,
45012007 εἴτε διακονίαν, ἐν τῇ διακονίᾳ, εἴτε ὁ διδάσκων, ἐν τῇ διδασκαλίᾳ,
45012008 εἴτε ὁ παρακαλῶν, ἐν τῇ παρακλήσει, ὁ μεταδιδοὺς, ἐν ἁπλότητι, ὁ προϊστάμενος, ἐν σπουδῇ, ὁ ἐλεῶν, ἐν ἱλαρότητι.
45012009 Ἡ ἀγάπη ἀνυπόκριτος. Ἀποστυγοῦντες τὸ πονηρόν, κολλώμενοι τῷ ἀγαθῷ,
45012010 τῇ φιλαδελφίᾳ εἰς ἀλλήλους φιλόστοργοι, τῇ τιμῇ ἀλλήλους προηγούμενοι,
45012011 τῇ σπουδῇ μὴ ὀκνηροί, τῷ πνεύματι ζέοντες, τῷ ˚Κυρίῳ δουλεύοντες,
45012012 τῇ ἐλπίδι χαίροντες, τῇ θλίψει ὑπομένοντες, τῇ προσευχῇ προσκαρτεροῦντες,
45012013 ταῖς χρείαις τῶν ἁγίων κοινωνοῦντες, τὴν φιλοξενίαν διώκοντες.
45012014 Εὐλογεῖτε τοὺς διώκοντας, ὑμᾶς εὐλογεῖτε καὶ μὴ καταρᾶσθε.
45012015 Χαίρειν μετὰ χαιρόντων, κλαίειν μετὰ κλαιόντων.
45012016 Τὸ αὐτὸ εἰς ἀλλήλους φρονοῦντες, μὴ τὰ ὑψηλὰ φρονοῦντες, ἀλλὰ τοῖς ταπεινοῖς συναπαγόμενοι. Μὴ γίνεσθε φρόνιμοι παρʼ ἑαυτοῖς.
45012017 Μηδενὶ κακὸν ἀντὶ κακοῦ ἀποδιδόντες, προνοούμενοι καλὰ ἐνώπιον πάντων ἀνθρώπων.
45012018 Εἰ δυνατόν τὸ ἐξ ὑμῶν, μετὰ πάντων ἀνθρώπων εἰρηνεύοντες.
45012019 Μὴ ἑαυτοὺς ἐκδικοῦντες, ἀγαπητοί, ἀλλὰ δότε τόπον τῇ ὀργῇ, γέγραπται γάρ, “Ἐμοὶ ἐκδίκησις, ἐγὼ ἀνταποδώσω”, λέγει ˚Κύριος.
45012020 “Ἀλλὰ ἐὰν πεινᾷ ὁ ἐχθρός σου, ψώμιζε αὐτόν· ἐὰν διψᾷ, πότιζε αὐτόν· τοῦτο γὰρ ποιῶν, ἄνθρακας πυρὸς σωρεύσεις ἐπὶ τὴν κεφαλὴν αὐτοῦ.”
45012021 Μὴ νικῶ ὑπὸ τοῦ κακοῦ, ἀλλὰ νίκα ἐν τῷ ἀγαθῷ τὸ κακόν.
45013001 Πᾶσα ψυχὴ ἐξουσίαις ὑπερεχούσαις ὑποτασσέσθω, οὐ γὰρ ἔστιν ἐξουσία, εἰ μὴ ὑπὸ ˚Θεοῦ, αἱ δὲ οὖσαι ὑπὸ ˚Θεοῦ τεταγμέναι εἰσίν.
45013002 Ὥστε ὁ ἀντιτασσόμενος τῇ ἐξουσίᾳ, τῇ τοῦ ˚Θεοῦ διαταγῇ ἀνθέστηκεν, οἱ δὲ ἀνθεστηκότες, ἑαυτοῖς κρίμα λήμψονται.
45013003 Οἱ γὰρ ἄρχοντες οὐκ εἰσὶν φόβος τῷ ἀγαθῷ ἔργῳ, ἀλλὰ τῷ κακῷ. Θέλεις δὲ μὴ φοβεῖσθαι τὴν ἐξουσίαν; Τὸ ἀγαθὸν ποίει, καὶ ἕξεις ἔπαινον ἐξ αὐτῆς.
45013004 ˚Θεοῦ γὰρ διάκονός ἐστιν σοὶ εἰς τὸ ἀγαθόν. Ἐὰν δὲ τὸ κακὸν ποιῇς, φοβοῦ· οὐ γὰρ εἰκῇ τὴν μάχαιραν φορεῖ· ˚Θεοῦ γὰρ διάκονός ἐστιν, ἔκδικος εἰς ὀργὴν τῷ τὸ κακὸν πράσσοντι.
45013005 Διὸ ἀνάγκη ὑποτάσσεσθαι, οὐ μόνον διὰ τὴν ὀργὴν, ἀλλὰ καὶ διὰ τὴν συνείδησιν.
45013006 Διὰ τοῦτο γὰρ, καὶ φόρους τελεῖτε, λειτουργοὶ γὰρ ˚Θεοῦ εἰσιν, εἰς αὐτὸ τοῦτο προσκαρτεροῦντες.
45013007 Ἀπόδοτε πᾶσι τὰς ὀφειλάς: τῷ τὸν φόρον, τὸν φόρον, τῷ τὸ τέλος, τὸ τέλος, τῷ τὸν φόβον, τὸν φόβον, τῷ τὴν τιμὴν, τὴν τιμήν.
45013008 Μηδενὶ μηδὲν ὀφείλετε, εἰ μὴ τὸ ἀλλήλους ἀγαπᾶν· ὁ γὰρ ἀγαπῶν τὸν ἕτερον, νόμον πεπλήρωκεν.
45013009 Τὸ γάρ, “Οὐ μοιχεύσεις”, “Οὐ φονεύσεις”, “Οὐ κλέψεις”, “οὐκ ἐπιθυμήσεις”, καὶ εἴ τις ἑτέρα ἐντολή ἐν τῷ λόγῳ τούτῳ, ἀνακεφαλαιοῦται, ἐν τῷ, “Ἀγαπήσεις τὸν πλησίον σου ὡς σεαυτόν.”
45013010 Ἡ ἀγάπη τῷ πλησίον κακὸν οὐκ ἐργάζεται· πλήρωμα οὖν νόμου ἡ ἀγάπη.
45013011 Καὶ τοῦτο εἰδότες τὸν καιρόν, ὅτι ὥρα ἤδη ὑμᾶς ἐξ ὕπνου ἐγερθῆναι, νῦν γὰρ ἐγγύτερον ἡμῶν ἡ σωτηρία, ἢ ὅτε ἐπιστεύσαμεν.
45013012 Ἡ νὺξ προέκοψεν, ἡ δὲ ἡμέρα ἤγγικεν. Ἀποθώμεθα οὖν τὰ ἔργα τοῦ σκότους, ἐνδυσώμεθα δὲ τὰ ὅπλα τοῦ φωτός.
45013013 Ὡς ἐν ἡμέρᾳ εὐσχημόνως περιπατήσωμεν, μὴ κώμοις καὶ μέθαις, μὴ κοίταις καὶ ἀσελγείαις, μὴ ἔριδι καὶ ζήλῳ.
45013014 Ἀλλὰ ἐνδύσασθε τὸν ˚Κύριον ˚Ἰησοῦν ˚Χριστόν, καὶ τῆς σαρκὸς πρόνοιαν μὴ ποιεῖσθε, εἰς ἐπιθυμίας.
45014001 Τὸν δὲ ἀσθενοῦντα τῇ πίστει προσλαμβάνεσθε, μὴ εἰς διακρίσεις διαλογισμῶν.
45014002 Ὃς μὲν πιστεύει φαγεῖν πάντα, ὁ δὲ ἀσθενῶν λάχανα ἐσθίει.
45014003 Ὁ ἐσθίων, τὸν μὴ ἐσθίοντα μὴ ἐξουθενείτω, ὁ δὲ μὴ ἐσθίων, τὸν ἐσθίοντα μὴ κρινέτω, ὁ ˚Θεὸς γὰρ αὐτὸν προσελάβετο.
45014004 Σὺ τίς εἶ, ὁ κρίνων ἀλλότριον οἰκέτην; Τῷ ἰδίῳ κυρίῳ στήκει ἢ πίπτει. Σταθήσεται δέ, δυνατεῖ γὰρ ὁ ˚Κύριος στῆσαι αὐτόν.
45014005 Ὃς μὲν κρίνει ἡμέραν παρʼ ἡμέραν, ὃς δὲ κρίνει πᾶσαν ἡμέραν. Ἕκαστος ἐν τῷ ἰδίῳ νοῒ, πληροφορείσθω.
45014006 Ὁ φρονῶν τὴν ἡμέραν, ˚Κυρίῳ φρονεῖ. Καὶ ὁ ἐσθίων, ˚Κυρίῳ ἐσθίει, εὐχαριστεῖ γὰρ τῷ ˚Θεῷ· καὶ ὁ μὴ ἐσθίων, ˚Κυρίῳ οὐκ ἐσθίει, καὶ εὐχαριστεῖ τῷ ˚Θεῷ.
45014007 Οὐδεὶς γὰρ ἡμῶν ἑαυτῷ ζῇ, καὶ οὐδεὶς ἑαυτῷ ἀποθνῄσκει.
45014008 Ἐάν τε γὰρ ζῶμεν, τῷ ˚Κυρίῳ ζῶμεν, ἐάν τε ἀποθνῄσκωμεν, τῷ ˚Κυρίῳ ἀποθνῄσκομεν. Ἐάν τε οὖν ζῶμεν, ἐάν τε ἀποθνῄσκωμεν, τοῦ ˚Κυρίου ἐσμέν.
45014009 Εἰς τοῦτο γὰρ ˚Χριστὸς ἀπέθανεν καὶ ἔζησεν, ἵνα καὶ νεκρῶν καὶ ζώντων κυριεύσῃ.
45014010 Σὺ δὲ τί κρίνεις τὸν ἀδελφόν σου; Ἢ καὶ σὺ τί ἐξουθενεῖς τὸν ἀδελφόν σου; Πάντες γὰρ παραστησόμεθα τῷ βήματι τοῦ ˚Θεοῦ.
45014011 Γέγραπται γάρ, “Ζῶ ἐγώ, λέγει ˚Κύριος ὅτι ἐμοὶ κάμψει πᾶν γόνυ, καὶ πᾶσα γλῶσσα ἐξομολογήσεται τῷ ˚Θεῷ.”
45014012 Ἄρα οὖν ἕκαστος ἡμῶν περὶ ἑαυτοῦ, λόγον δώσει τῷ ˚Θεῷ.
45014013 Μηκέτι οὖν ἀλλήλους κρίνωμεν, ἀλλὰ τοῦτο κρίνατε μᾶλλον, τὸ μὴ τιθέναι πρόσκομμα τῷ ἀδελφῷ ἢ σκάνδαλον.
45014014 Οἶδα καὶ πέπεισμαι ἐν ˚Κυρίῳ ˚Ἰησοῦ ὅτι οὐδὲν κοινὸν διʼ ἑαυτοῦ· εἰ μὴ τῷ λογιζομένῳ τι κοινὸν εἶναι, ἐκείνῳ κοινόν.
45014015 Εἰ γὰρ διὰ βρῶμα, ὁ ἀδελφός σου λυπεῖται, οὐκέτι κατὰ ἀγάπην περιπατεῖς. Μὴ τῷ βρώματί σου ἐκεῖνον ἀπόλλυε, ὑπὲρ οὗ ˚Χριστὸς ἀπέθανεν.
45014016 Μὴ βλασφημείσθω οὖν ὑμῶν τὸ ἀγαθόν.
45014017 Οὐ γάρ ἐστιν ἡ Βασιλεία τοῦ ˚Θεοῦ βρῶσις καὶ πόσις, ἀλλὰ δικαιοσύνη, καὶ εἰρήνη, καὶ χαρὰ ἐν ˚Πνεύματι Ἁγίῳ.
45014018 Ὁ γὰρ ἐν τούτῳ δουλεύων τῷ ˚Χριστῷ, εὐάρεστος τῷ ˚Θεῷ καὶ δόκιμος τοῖς ἀνθρώποις.
45014019 Ἄρα οὖν τὰ τῆς εἰρήνης διώκωμεν, καὶ τὰ τῆς οἰκοδομῆς τῆς εἰς ἀλλήλους.
45014020 Μὴ ἕνεκεν βρώματος, κατάλυε τὸ ἔργον τοῦ ˚Θεοῦ. Πάντα μὲν καθαρά, ἀλλὰ κακὸν τῷ ἀνθρώπῳ τῷ διὰ προσκόμματος ἐσθίοντι.
45014021 Καλὸν τὸ μὴ φαγεῖν κρέα, μηδὲ πιεῖν οἶνον, μηδὲ ἐν ᾧ ὁ ἀδελφός σου προσκόπτει, ἢ σκανδαλίζεται, ἢ ἀσθενεῖ.
45014022 Σὺ πίστιν ἣν ἔχεις, κατὰ σεαυτὸν ἔχε ἐνώπιον τοῦ ˚Θεοῦ. Μακάριος ὁ μὴ κρίνων ἑαυτὸν ἐν ᾧ δοκιμάζει.
45014023 Ὁ δὲ διακρινόμενος, ἐὰν φάγῃ, κατακέκριται, ὅτι οὐκ ἐκ πίστεως· πᾶν δὲ ὃ οὐκ ἐκ πίστεως, ἁμαρτία ἐστίν.
45015001 Ὀφείλομεν δὲ ἡμεῖς, οἱ δυνατοὶ, τὰ ἀσθενήματα τῶν ἀδυνάτων βαστάζειν, καὶ μὴ ἑαυτοῖς ἀρέσκειν.
45015002 Ἕκαστος ἡμῶν τῷ πλησίον ἀρεσκέτω εἰς τὸ ἀγαθὸν πρὸς οἰκοδομήν.
45015003 Καὶ γὰρ ὁ ˚Χριστὸς οὐχ ἑαυτῷ ἤρεσεν, ἀλλὰ καθὼς γέγραπται, “Οἱ ὀνειδισμοὶ τῶν ὀνειδιζόντων σε, ἐπέπεσαν ἐπʼ ἐμέ.”
45015004 Ὅσα γὰρ προεγράφη, εἰς τὴν ἡμετέραν διδασκαλίαν ἐγράφη, ἵνα διὰ τῆς ὑπομονῆς καὶ διὰ τῆς παρακλήσεως τῶν Γραφῶν, τὴν ἐλπίδα ἔχωμεν.
45015005 Ὁ δὲ ˚Θεὸς τῆς ὑπομονῆς καὶ τῆς παρακλήσεως, δῴη ὑμῖν τὸ αὐτὸ φρονεῖν ἐν ἀλλήλοις κατὰ ˚Χριστὸν ˚Ἰησοῦν,
45015006 ἵνα ὁμοθυμαδὸν, ἐν ἑνὶ στόματι, δοξάζητε τὸν ˚Θεὸν καὶ Πατέρα τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ ˚Χριστοῦ.
45015007 Διὸ προσλαμβάνεσθε ἀλλήλους, καθὼς καὶ ὁ ˚Χριστὸς προσελάβετο ὑμᾶς, εἰς δόξαν τοῦ ˚Θεοῦ.
45015008 Λέγω γὰρ ˚Χριστὸν διάκονον γεγενῆσθαι περιτομῆς ὑπὲρ ἀληθείας ˚Θεοῦ, εἰς τὸ βεβαιῶσαι τὰς ἐπαγγελίας τῶν πατέρων
45015009 τὰ δὲ ἔθνη, ὑπὲρ ἐλέους δοξάσαι τὸν ˚Θεόν, καθὼς γέγραπται, “Διὰ τοῦτο ἐξομολογήσομαί σοι ἐν ἔθνεσιν, καὶ τῷ ὀνόματί σου ψαλῶ.”
45015010 Καὶ πάλιν λέγει, “Εὐφράνθητε, ἔθνη, μετὰ τοῦ λαοῦ αὐτοῦ.”
45015011 Καὶ πάλιν, “Αἰνεῖτε, πάντα τὰ ἔθνη, τὸν ˚Κύριον, καὶ ἐπαινεσάτωσαν αὐτὸν πάντες οἱ λαοί.”
45015012 Καὶ πάλιν Ἠσαΐας λέγει, “Ἔσται ἡ ῥίζα τοῦ Ἰεσσαί, καὶ ὁ ἀνιστάμενος ἄρχειν ἐθνῶν· ἐπʼ αὐτῷ ἔθνη ἐλπιοῦσιν.”
45015013 Ὁ δὲ ˚Θεὸς τῆς ἐλπίδος πληρώσαι ὑμᾶς πάσης χαρᾶς καὶ εἰρήνης ἐν τῷ πιστεύειν, εἰς τὸ περισσεύειν ὑμᾶς ἐν τῇ ἐλπίδι ἐν δυνάμει ˚Πνεύματος Ἁγίου.
45015014 Πέπεισμαι δέ, ἀδελφοί μου, καὶ αὐτὸς ἐγὼ περὶ ὑμῶν, ὅτι καὶ αὐτοὶ μεστοί ἐστε ἀγαθωσύνης, πεπληρωμένοι πάσης γνώσεως, δυνάμενοι καὶ ἀλλήλους νουθετεῖν.
45015015 Τολμηρότερον δὲ ἔγραψα ὑμῖν ἀπὸ μέρους, ὡς ἐπαναμιμνῄσκων ὑμᾶς, διὰ τὴν χάριν τὴν δοθεῖσάν μοι ὑπὸ τοῦ ˚Θεοῦ,
45015016 εἰς τὸ εἶναί με λειτουργὸν ˚Χριστοῦ ˚Ἰησοῦ εἰς τὰ ἔθνη, ἱερουργοῦντα τὸ εὐαγγέλιον τοῦ ˚Θεοῦ, ἵνα γένηται ἡ προσφορὰ τῶν ἐθνῶν εὐπρόσδεκτος, ἡγιασμένη ἐν ˚Πνεύματι Ἁγίῳ.
45015017 Ἔχω οὖν τὴν καύχησιν ἐν ˚Χριστῷ ˚Ἰησοῦ τὰ πρὸς τὸν ˚Θεόν.
45015018 Οὐ γὰρ τολμήσω τι λαλεῖν, ὧν οὐ κατειργάσατο ˚Χριστὸς διʼ ἐμοῦ, εἰς ὑπακοὴν ἐθνῶν λόγῳ καὶ ἔργῳ
45015019 ἐν δυνάμει σημείων καὶ τεράτων ἐν δυνάμει ˚Πνεύματος ˚Θεοῦ· ὥστε με ἀπὸ Ἰερουσαλὴμ καὶ κύκλῳ μέχρι τοῦ Ἰλλυρικοῦ, πεπληρωκέναι τὸ εὐαγγέλιον τοῦ ˚Χριστοῦ,
45015020 οὕτως δὲ φιλοτιμούμενον εὐαγγελίζεσθαι, οὐχ ὅπου ὠνομάσθη ˚Χριστός, ἵνα μὴ ἐπʼ ἀλλότριον θεμέλιον οἰκοδομῶ,
45015021 ἀλλὰ καθὼς γέγραπται, “Οἷς οὐκ ἀνηγγέλη περὶ αὐτοῦ, ὄψονται, καὶ οἳ οὐκ ἀκηκόασιν, συνήσουσιν.”
45015022 Διὸ καὶ ἐνεκοπτόμην τὰ πολλὰ τοῦ ἐλθεῖν πρὸς ὑμᾶς.
45015023 Νυνὶ δὲ μηκέτι τόπον ἔχων ἐν τοῖς κλίμασι τούτοις, ἐπιποθίαν δὲ ἔχων τοῦ ἐλθεῖν πρὸς ὑμᾶς ἀπὸ πολλῶν ἐτῶν,
45015024 ὡς ἂν πορεύωμαι εἰς τὴν Σπανίαν, ἐλπίζω γὰρ διαπορευόμενος θεάσασθαι ὑμᾶς, καὶ ὑφʼ ὑμῶν προπεμφθῆναι ἐκεῖ, ἐὰν ὑμῶν πρῶτον ἀπὸ μέρους ἐμπλησθῶ.
45015025 Νυνὶ δὲ πορεύομαι εἰς Ἰερουσαλὴμ διακονῶν τοῖς ἁγίοις.
45015026 εὐδόκησαν γὰρ Μακεδονία καὶ Ἀχαΐα κοινωνίαν τινὰ ποιήσασθαι εἰς τοὺς πτωχοὺς τῶν ἁγίων τῶν ἐν Ἰερουσαλήμ.
45015027 Εὐδόκησαν γάρ καὶ ὀφειλέται εἰσὶν αὐτῶν· εἰ γὰρ τοῖς πνευματικοῖς αὐτῶν ἐκοινώνησαν τὰ ἔθνη, ὀφείλουσιν καὶ ἐν τοῖς σαρκικοῖς λειτουργῆσαι αὐτοῖς.
45015028 Τοῦτο οὖν ἐπιτελέσας, καὶ σφραγισάμενος αὐτοῖς τὸν καρπὸν τοῦτον, ἀπελεύσομαι διʼ ὑμῶν εἰς Σπανίαν.
45015029 Οἶδα δὲ ὅτι ἐρχόμενος πρὸς ὑμᾶς, ἐν πληρώματι εὐλογίας ˚Χριστοῦ, ἐλεύσομαι.
45015030 Παρακαλῶ δὲ ὑμᾶς, ἀδελφοί, διὰ τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ ˚Χριστοῦ, καὶ διὰ τῆς ἀγάπης τοῦ ˚Πνεύματος, συναγωνίσασθαί μοι ἐν ταῖς προσευχαῖς ὑπὲρ ἐμοῦ πρὸς τὸν ˚Θεόν,
45015031 ἵνα ῥυσθῶ ἀπὸ τῶν ἀπειθούντων ἐν τῇ Ἰουδαίᾳ, καὶ ἡ διακονία μου ἡ εἰς Ἰερουσαλὴμ εὐπρόσδεκτος τοῖς ἁγίοις γένηται,
45015032 ἵνα ἐν χαρᾷ ἔλθω πρὸς ὑμᾶς διὰ θελήματος ˚Θεοῦ, συναναπαύσωμαι ὑμῖν.
45015033 Ὁ δὲ ˚Θεὸς τῆς εἰρήνης μετὰ πάντων ὑμῶν. Ἀμήν!
45016001 Συνίστημι δὲ ὑμῖν Φοίβην, τὴν ἀδελφὴν ἡμῶν, οὖσαν καὶ διάκονον τῆς ἐκκλησίας τῆς ἐν Κεγχρεαῖς,
45016002 ἵνα προσδέξησθε αὐτὴν ἐν ˚Κυρίῳ ἀξίως τῶν ἁγίων, καὶ παραστῆτε αὐτῇ ἐν ᾧ ἂν ὑμῶν χρῄζῃ πράγματι, καὶ γὰρ αὐτὴ προστάτις πολλῶν ἐγενήθη καὶ ἐμοῦ αὐτοῦ.
45016003 Ἀσπάσασθε Πρίσκαν καὶ Ἀκύλαν, τοὺς συνεργούς μου ἐν ˚Χριστῷ ˚Ἰησοῦ,
45016004 οἵτινες ὑπὲρ τῆς ψυχῆς μου, τὸν ἑαυτῶν τράχηλον ὑπέθηκαν, οἷς οὐκ ἐγὼ μόνος εὐχαριστῶ, ἀλλὰ καὶ πᾶσαι αἱ ἐκκλησίαι τῶν ἐθνῶν,
45016005 καὶ τὴν κατʼ οἶκον αὐτῶν ἐκκλησίαν. Ἀσπάσασθε Ἐπαίνετον, τὸν ἀγαπητόν μου, ὅς ἐστιν ἀπαρχὴ τῆς Ἀσίας εἰς ˚Χριστόν.
45016006 Ἀσπάσασθε Μαρίαν, ἥτις πολλὰ ἐκοπίασεν εἰς ὑμᾶς.
45016007 Ἀσπάσασθε Ἀνδρόνικον καὶ Ἰουνίαν, τοὺς συγγενεῖς μου καὶ συναιχμαλώτους μου, οἵτινές εἰσιν ἐπίσημοι ἐν τοῖς ἀποστόλοις, οἳ καὶ πρὸ ἐμοῦ γέγοναν ἐν ˚Χριστῷ.
45016008 Ἀσπάσασθε Ἀμπλιᾶτον, τὸν ἀγαπητόν μου ἐν ˚Κυρίῳ.
45016009 Ἀσπάσασθε Οὐρβανὸν, τὸν συνεργὸν ἡμῶν ἐν ˚Χριστῷ, καὶ Στάχυν τὸν ἀγαπητόν μου.
45016010 Ἀσπάσασθε Ἀπελλῆν, τὸν δόκιμον ἐν ˚Χριστῷ. Ἀσπάσασθε τοὺς ἐκ τῶν Ἀριστοβούλου.
45016011 Ἀσπάσασθε Ἡρῳδίωνα, τὸν συγγενῆ μου. Ἀσπάσασθε τοὺς ἐκ τῶν Ναρκίσσου τοὺς ὄντας ἐν ˚Κυρίῳ.
45016012 Ἀσπάσασθε Τρύφαιναν καὶ Τρυφῶσαν, τὰς κοπιώσας ἐν ˚Κυρίῳ. Ἀσπάσασθε Περσίδα, τὴν ἀγαπητήν, ἥτις πολλὰ ἐκοπίασεν ἐν ˚Κυρίῳ.
45016013 Ἀσπάσασθε Ῥοῦφον, τὸν ἐκλεκτὸν ἐν ˚Κυρίῳ, καὶ τὴν μητέρα αὐτοῦ καὶ ἐμοῦ.
45016014 Ἀσπάσασθε Ἀσύγκριτον, Φλέγοντα, Ἑρμῆν, Πατρόβαν, Ἑρμᾶν, καὶ τοὺς σὺν αὐτοῖς ἀδελφούς.
45016015 Ἀσπάσασθε Φιλόλογον καὶ Ἰουλίαν, Νηρέα καὶ τὴν ἀδελφὴν αὐτοῦ, καὶ Ὀλυμπᾶν, καὶ τοὺς σὺν αὐτοῖς πάντας ἁγίους.
45016016 Ἀσπάσασθε ἀλλήλους ἐν φιλήματι ἁγίῳ. Ἀσπάζονται ὑμᾶς αἱ ἐκκλησίαι πᾶσαι τοῦ ˚Χριστοῦ.
45016017 Παρακαλῶ δὲ ὑμᾶς, ἀδελφοί, σκοπεῖν τοὺς τὰς διχοστασίας καὶ τὰ σκάνδαλα παρὰ τὴν διδαχὴν ἣν ὑμεῖς ἐμάθετε ποιοῦντας, καὶ ἐκκλίνετε ἀπʼ αὐτῶν.
45016018 Οἱ γὰρ τοιοῦτοι τῷ ˚Κυρίῳ ἡμῶν ˚Χριστῷ οὐ δουλεύουσιν, ἀλλὰ τῇ ἑαυτῶν κοιλίᾳ, καὶ διὰ τῆς χρηστολογίας καὶ εὐλογίας, ἐξαπατῶσιν τὰς καρδίας τῶν ἀκάκων.
45016019 Ἡ γὰρ ὑμῶν ὑπακοὴ, εἰς πάντας ἀφίκετο. Ἐφʼ ὑμῖν οὖν χαίρω, θέλω δὲ ὑμᾶς σοφοὺς εἶναι εἰς τὸ ἀγαθόν, ἀκεραίους δὲ εἰς τὸ κακόν.
45016020 Ὁ δὲ ˚Θεὸς τῆς εἰρήνης συντρίψει τὸν Σατανᾶν ὑπὸ τοὺς πόδας ὑμῶν ἐν τάχει. Ἡ χάρις τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ μεθʼ ὑμῶν.
45016021 Ἀσπάζεται ὑμᾶς Τιμόθεος, ὁ συνεργός μου, καὶ Λούκιος, καὶ Ἰάσων, καὶ Σωσίπατρος, οἱ συγγενεῖς μου.
45016022 Ἀσπάζομαι ὑμᾶς ἐγὼ, Τέρτιος, ὁ γράψας τὴν ἐπιστολὴν, ἐν ˚Κυρίῳ.
45016023 Ἀσπάζεται ὑμᾶς Γάϊος, ὁ ξένος μου, καὶ ὅλης τῆς ἐκκλησίας. Ἀσπάζεται ὑμᾶς Ἔραστος, ὁ οἰκονόμος τῆς πόλεως, καὶ Κούαρτος ὁ ἀδελφός.
45016025 Τῷ δὲ δυναμένῳ ὑμᾶς στηρίξαι, κατὰ τὸ εὐαγγέλιόν μου, καὶ τὸ κήρυγμα ˚Ἰησοῦ ˚Χριστοῦ κατὰ ἀποκάλυψιν μυστηρίου χρόνοις αἰωνίοις σεσιγημένου,
45016026 φανερωθέντος δὲ νῦν διά τε Γραφῶν προφητικῶν, κατʼ ἐπιταγὴν τοῦ αἰωνίου ˚Θεοῦ εἰς ὑπακοὴν πίστεως, εἰς πάντα τὰ ἔθνη γνωρισθέντος.
45016027 Μόνῳ σοφῷ ˚Θεῷ διὰ ˚Ἰησοῦ ˚Χριστοῦ, ᾧ ἡ δόξα εἰς τοὺς αἰῶνας. Ἀμήν!
46001001 Παῦλος, κλητὸς ἀπόστολος ˚Χριστοῦ ˚Ἰησοῦ διὰ θελήματος ˚Θεοῦ, καὶ Σωσθένης, ὁ ἀδελφὸς,
46001002 τῇ ἐκκλησίᾳ τοῦ ˚Θεοῦ, ἡγιασμένοις ἐν ˚Χριστῷ ˚Ἰησοῦ, τῇ οὔσῃ ἐν Κορίνθῳ, κλητοῖς ἁγίοις, σὺν πᾶσιν τοῖς ἐπικαλουμένοις τὸ ὄνομα τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ ˚Χριστοῦ, ἐν παντὶ τόπῳ, αὐτῶν καὶ ἡμῶν:
46001003 χάρις ὑμῖν καὶ εἰρήνη ἀπὸ ˚Θεοῦ Πατρὸς ἡμῶν καὶ ˚Κυρίου ˚Ἰησοῦ ˚Χριστοῦ.
46001004 Εὐχαριστῶ τῷ ˚Θεῷ μου πάντοτε περὶ ὑμῶν, ἐπὶ τῇ χάριτι τοῦ ˚Θεοῦ τῇ δοθείσῃ ὑμῖν ἐν ˚Χριστῷ ˚Ἰησοῦ,
46001005 ὅτι ἐν παντὶ ἐπλουτίσθητε ἐν αὐτῷ, ἐν παντὶ λόγῳ καὶ πάσῃ γνώσει,
46001006 καθὼς τὸ μαρτύριον τοῦ ˚Χριστοῦ ἐβεβαιώθη ἐν ὑμῖν,
46001007 ὥστε ὑμᾶς μὴ ὑστερεῖσθαι ἐν μηδενὶ χαρίσματι, ἀπεκδεχομένους τὴν ἀποκάλυψιν τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ ˚Χριστοῦ,
46001008 ὃς καὶ βεβαιώσει ὑμᾶς ἕως τέλους, ἀνεγκλήτους ἐν τῇ ἡμέρᾳ τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ ˚Χριστοῦ.
46001009 Πιστὸς ὁ ˚Θεὸς, διʼ οὗ ἐκλήθητε εἰς κοινωνίαν τοῦ Υἱοῦ αὐτοῦ, ˚Ἰησοῦ ˚Χριστοῦ, τοῦ ˚Κυρίου ἡμῶν.
46001010 Παρακαλῶ δὲ ὑμᾶς, ἀδελφοί, διὰ τοῦ ὀνόματος τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ ˚Χριστοῦ, ἵνα τὸ αὐτὸ λέγητε πάντες, καὶ μὴ ᾖ ἐν ὑμῖν σχίσματα, ἦτε δὲ κατηρτισμένοι ἐν τῷ αὐτῷ νοῒ καὶ ἐν τῇ αὐτῇ γνώμῃ.
46001011 Ἐδηλώθη γάρ μοι περὶ ὑμῶν, ἀδελφοί μου, ὑπὸ τῶν Χλόης, ὅτι ἔριδες ἐν ὑμῖν εἰσιν.
46001012 Λέγω δὲ τοῦτο, ὅτι ἕκαστος ὑμῶν λέγει, “Ἐγὼ” μέν “εἰμι Παύλου”, “Ἐγὼ” δὲ “Ἀπολλῶ”, “Ἐγὼ” δὲ “Κηφᾶ”, “Ἐγὼ” δὲ “˚Χριστοῦ”.
46001013 Μεμέρισται ὁ ˚Χριστός; Μὴ Παῦλος ἐσταυρώθη ὑπὲρ ὑμῶν, ἢ εἰς τὸ ὄνομα Παύλου ἐβαπτίσθητε;
46001014 Εὐχαριστῶ ὅτι οὐδένα ὑμῶν ἐβάπτισα, εἰ μὴ Κρίσπον καὶ Γάϊον,
46001015 ἵνα μή τις εἴπῃ ὅτι εἰς τὸ ἐμὸν ὄνομα ἐβαπτίσθητε.
46001016 Ἐβάπτισα δὲ καὶ τὸν Στεφανᾶ οἶκον· λοιπὸν οὐκ οἶδα εἴ τινα ἄλλον ἐβάπτισα.
46001017 Οὐ γὰρ ἀπέστειλέν με ˚Χριστὸς βαπτίζειν, ἀλλὰ εὐαγγελίζεσθαι, οὐκ ἐν σοφίᾳ λόγου, ἵνα μὴ κενωθῇ ὁ σταυρὸς τοῦ ˚Χριστοῦ.
46001018 Ὁ λόγος γὰρ ὁ τοῦ σταυροῦ, τοῖς μὲν ἀπολλυμένοις μωρία ἐστίν, τοῖς δὲ σῳζομένοις ἡμῖν δύναμις ˚Θεοῦ ἐστιν.
46001019 Γέγραπται γάρ, “Ἀπολῶ τὴν σοφίαν τῶν σοφῶν, καὶ τὴν σύνεσιν τῶν συνετῶν ἀθετήσω.”
46001020 Ποῦ σοφός; Ποῦ γραμματεύς; Ποῦ συζητητὴς τοῦ αἰῶνος τούτου; Οὐχὶ ἐμώρανεν ὁ ˚Θεὸς τὴν σοφίαν τοῦ κόσμου;
46001021 Ἐπειδὴ γὰρ ἐν τῇ σοφίᾳ τοῦ ˚Θεοῦ, οὐκ ἔγνω ὁ κόσμος διὰ τῆς σοφίας τὸν ˚Θεόν, εὐδόκησεν ὁ ˚Θεὸς διὰ τῆς μωρίας τοῦ κηρύγματος σῶσαι τοὺς πιστεύοντας.
46001022 Ἐπειδὴ καὶ Ἰουδαῖοι σημεῖα αἰτοῦσιν, καὶ Ἕλληνες σοφίαν ζητοῦσιν·
46001023 ἡμεῖς δὲ κηρύσσομεν ˚Χριστὸν ἐσταυρωμένον, Ἰουδαίοις μὲν σκάνδαλον, ἔθνεσιν δὲ μωρίαν,
46001024 αὐτοῖς δὲ, τοῖς κλητοῖς, Ἰουδαίοις τε καὶ Ἕλλησιν, ˚Χριστὸν ˚Θεοῦ δύναμιν, καὶ ˚Θεοῦ σοφίαν.
46001025 Ὅτι τὸ μωρὸν τοῦ ˚Θεοῦ, σοφώτερον τῶν ἀνθρώπων ἐστίν, καὶ τὸ ἀσθενὲς τοῦ ˚Θεοῦ, ἰσχυρότερον τῶν ἀνθρώπων.
46001026 Βλέπετε γὰρ τὴν κλῆσιν ὑμῶν, ἀδελφοί, ὅτι οὐ πολλοὶ σοφοὶ κατὰ σάρκα, οὐ πολλοὶ δυνατοί, οὐ πολλοὶ εὐγενεῖς.
46001027 Ἀλλὰ τὰ μωρὰ τοῦ κόσμου ἐξελέξατο ὁ ˚Θεός, ἵνα καταισχύνῃ τοὺς σοφούς, καὶ τὰ ἀσθενῆ τοῦ κόσμου ἐξελέξατο ὁ ˚Θεός, ἵνα καταισχύνῃ τὰ ἰσχυρά,
46001028 καὶ τὰ ἀγενῆ τοῦ κόσμου καὶ τὰ ἐξουθενημένα ἐξελέξατο ὁ ˚Θεός, τὰ μὴ ὄντα, ἵνα τὰ ὄντα καταργήσῃ,
46001029 ὅπως μὴ καυχήσηται πᾶσα σὰρξ ἐνώπιον τοῦ ˚Θεοῦ.
46001030 Ἐξ αὐτοῦ δὲ, ὑμεῖς ἐστε ἐν ˚Χριστῷ ˚Ἰησοῦ, ὃς ἐγενήθη σοφία ἡμῖν ἀπὸ ˚Θεοῦ, δικαιοσύνη τε, καὶ ἁγιασμὸς, καὶ ἀπολύτρωσις,
46001031 ἵνα καθὼς γέγραπται, “Ὁ καυχώμενος, ἐν ˚Κυρίῳ καυχάσθω.”
46002001 Κἀγὼ ἐλθὼν πρὸς ὑμᾶς, ἀδελφοί, ἦλθον οὐ καθʼ ὑπεροχὴν λόγου ἢ σοφίας, καταγγέλλων ὑμῖν τὸ μυστήριον τοῦ ˚Θεοῦ.
46002002 Οὐ γὰρ ἔκρινά τι εἰδέναι ἐν ὑμῖν, εἰ μὴ ˚Ἰησοῦν ˚Χριστὸν, καὶ τοῦτον ἐσταυρωμένον.
46002003 Κἀγὼ ἐν ἀσθενείᾳ, καὶ ἐν φόβῳ, καὶ ἐν τρόμῳ πολλῷ ἐγενόμην πρὸς ὑμᾶς.
46002004 Καὶ ὁ λόγος μου καὶ τὸ κήρυγμά μου, οὐκ ἐν πειθοῖς σοφίας λόγοις, ἀλλʼ ἐν ἀποδείξει ˚Πνεύματος καὶ δυνάμεως,
46002005 ἵνα ἡ πίστις ὑμῶν, μὴ ᾖ ἐν σοφίᾳ ἀνθρώπων, ἀλλʼ ἐν δυνάμει ˚Θεοῦ.
46002006 Σοφίαν δὲ λαλοῦμεν ἐν τοῖς τελείοις, σοφίαν δὲ, οὐ τοῦ αἰῶνος τούτου, οὐδὲ τῶν ἀρχόντων τοῦ αἰῶνος τούτου, τῶν καταργουμένων,
46002007 ἀλλὰ λαλοῦμεν ˚Θεοῦ σοφίαν ἐν μυστηρίῳ τὴν ἀποκεκρυμμένην, ἣν προώρισεν ὁ ˚Θεὸς πρὸ τῶν αἰώνων, εἰς δόξαν ἡμῶν·
46002008 ἣν οὐδεὶς τῶν ἀρχόντων τοῦ αἰῶνος τούτου ἔγνωκεν, εἰ γὰρ ἔγνωσαν, οὐκ ἂν τὸν ˚Κύριον τῆς δόξης ἐσταύρωσαν·
46002009 ἀλλὰ καθὼς γέγραπται, “Ἃ ὀφθαλμὸς οὐκ εἶδεν, καὶ οὖς οὐκ ἤκουσεν, καὶ ἐπὶ καρδίαν ἀνθρώπου οὐκ ἀνέβη, ἃ ἡτοίμασεν ὁ ˚Θεὸς τοῖς ἀγαπῶσιν αὐτόν.”
46002010 Ἡμῖν γὰρ ἀπεκάλυψεν ὁ ˚Θεὸς διὰ τοῦ ˚Πνεύματος, τὸ γὰρ ˚Πνεῦμα πάντα ἐραυνᾷ, καὶ τὰ βάθη τοῦ ˚Θεοῦ.
46002011 Τίς γὰρ οἶδεν ἀνθρώπων τὰ τοῦ ἀνθρώπου, εἰ μὴ τὸ πνεῦμα τοῦ ἀνθρώπου τὸ ἐν αὐτῷ; Οὕτως καὶ τὰ τοῦ ˚Θεοῦ οὐδεὶς ἔγνωκεν, εἰ μὴ τὸ ˚Πνεῦμα τοῦ ˚Θεοῦ.
46002012 Ἡμεῖς δὲ οὐ τὸ πνεῦμα τοῦ κόσμου ἐλάβομεν, ἀλλὰ τὸ ˚Πνεῦμα τὸ ἐκ τοῦ ˚Θεοῦ, ἵνα εἰδῶμεν τὰ ὑπὸ τοῦ ˚Θεοῦ χαρισθέντα ἡμῖν.
46002013 Ἃ καὶ λαλοῦμεν, οὐκ ἐν διδακτοῖς ἀνθρωπίνης σοφίας λόγοις, ἀλλʼ ἐν διδακτοῖς ˚Πνεύματος, πνευματικοῖς πνευματικὰ συγκρίνοντες.
46002014 Ψυχικὸς δὲ ἄνθρωπος, οὐ δέχεται τὰ τοῦ ˚Πνεύματος τοῦ ˚Θεοῦ, μωρία γὰρ αὐτῷ ἐστίν, καὶ οὐ δύναται γνῶναι, ὅτι πνευματικῶς ἀνακρίνεται.
46002015 Ὁ δὲ πνευματικὸς ἀνακρίνει τὰ πάντα, αὐτὸς δὲ ὑπʼ οὐδενὸς ἀνακρίνεται.
46002016 “Τίς” γὰρ “ἔγνω νοῦν ˚Κυρίου, ὃς συμβιβάσει αὐτόν;” Ἡμεῖς δὲ νοῦν ˚Χριστοῦ ἔχομεν.
46003001 Κἀγώ, ἀδελφοί, οὐκ ἠδυνήθην λαλῆσαι ὑμῖν ὡς πνευματικοῖς, ἀλλʼ ὡς σαρκίνοις, ὡς νηπίοις ἐν ˚Χριστῷ.
46003002 Γάλα ὑμᾶς ἐπότισα, οὐ βρῶμα, οὔπω γὰρ ἐδύνασθε. Ἀλλʼ οὐδὲ ἔτι νῦν δύνασθε,
46003003 ἔτι γὰρ σαρκικοί ἐστε. Ὅπου γὰρ ἐν ὑμῖν ζῆλος καὶ ἔρις, οὐχὶ σαρκικοί ἐστε καὶ κατὰ ἄνθρωπον περιπατεῖτε;
46003004 Ὅταν γὰρ λέγῃ τις, “Ἐγὼ” μέν “εἰμι Παύλου”, ἕτερος δέ, “Ἐγὼ Ἀπολλῶ”, οὐκ ἄνθρωποί ἐστε;
46003005 Τί οὖν ἐστιν Ἀπολλῶς; Τί δέ ἐστιν Παῦλος; Διάκονοι διʼ ὧν ἐπιστεύσατε, καὶ ἑκάστῳ ὡς ὁ ˚Κύριος ἔδωκεν.
46003006 Ἐγὼ ἐφύτευσα, Ἀπολλῶς ἐπότισεν, ἀλλὰ ὁ ˚Θεὸς ηὔξανεν.
46003007 Ὥστε οὔτε ὁ φυτεύων ἐστίν τι, οὔτε ὁ ποτίζων, ἀλλʼ ὁ αὐξάνων, ˚Θεός.
46003008 Ὁ φυτεύων δὲ, καὶ ὁ ποτίζων, ἕν εἰσιν, ἕκαστος δὲ τὸν ἴδιον μισθὸν λήμψεται, κατὰ τὸν ἴδιον κόπον.
46003009 ˚Θεοῦ γάρ ἐσμεν συνεργοί· ˚Θεοῦ γεώργιον, ˚Θεοῦ οἰκοδομή ἐστε.
46003010 Κατὰ τὴν χάριν τοῦ ˚Θεοῦ τὴν δοθεῖσάν μοι, ὡς σοφὸς ἀρχιτέκτων θεμέλιον ἔθηκα, ἄλλος δὲ ἐποικοδομεῖ. Ἕκαστος δὲ βλεπέτω, πῶς ἐποικοδομεῖ.
46003011 Θεμέλιον γὰρ ἄλλον οὐδεὶς δύναται θεῖναι, παρὰ τὸν κείμενον, ὅς ἐστιν ˚Ἰησοῦς ˚Χριστός.
46003012 Εἰ δέ τις ἐποικοδομεῖ ἐπὶ τὸν θεμέλιον χρυσίον, ἀργύριον, λίθους τιμίους, ξύλα, χόρτον, καλάμην,
46003013 ἑκάστου τὸ ἔργον φανερὸν γενήσεται, ἡ γὰρ ἡμέρα δηλώσει, ὅτι ἐν πυρὶ ἀποκαλύπτεται, καὶ ἑκάστου τὸ ἔργον, ὁποῖόν ἐστιν, τὸ πῦρ αὐτὸ δοκιμάσει.
46003014 Εἴ τινος τὸ ἔργον μενεῖ, ὃ ἐποικοδόμησεν, μισθὸν λήμψεται.
46003015 Εἴ τινος τὸ ἔργον κατακαήσεται, ζημιωθήσεται, αὐτὸς δὲ σωθήσεται, οὕτως δὲ ὡς διὰ πυρός.
46003016 Οὐκ οἴδατε ὅτι ναὸς ˚Θεοῦ ἐστε, καὶ τὸ ˚Πνεῦμα τοῦ ˚Θεοῦ οἰκεῖ ἐν ὑμῖν;
46003017 Εἴ τις τὸν ναὸν τοῦ ˚Θεοῦ φθείρει, φθερεῖ τοῦτον ὁ ˚Θεός· ὁ γὰρ ναὸς τοῦ ˚Θεοῦ ἅγιός ἐστιν, οἵτινές ἐστε ὑμεῖς.
46003018 Μηδεὶς ἑαυτὸν ἐξαπατάτω· εἴ τις δοκεῖ σοφὸς εἶναι ἐν ὑμῖν ἐν τῷ αἰῶνι τούτῳ, μωρὸς γενέσθω, ἵνα γένηται σοφός.
46003019 Ἡ γὰρ σοφία τοῦ κόσμου τούτου, μωρία παρὰ τῷ ˚Θεῷ ἐστιν. Γέγραπται γάρ, “Ὁ δρασσόμενος τοὺς σοφοὺς ἐν τῇ πανουργίᾳ αὐτῶν.”
46003020 Καὶ πάλιν, “˚Κύριος γινώσκει τοὺς διαλογισμοὺς τῶν σοφῶν, ὅτι εἰσὶν μάταιοι.”
46003021 Ὥστε μηδεὶς καυχάσθω ἐν ἀνθρώποις· πάντα γὰρ ὑμῶν ἐστιν,
46003022 εἴτε Παῦλος, εἴτε Ἀπολλῶς, εἴτε Κηφᾶς, εἴτε κόσμος, εἴτε ζωὴ, εἴτε θάνατος, εἴτε ἐνεστῶτα, εἴτε μέλλοντα, πάντα ὑμῶν,
46003023 ὑμεῖς δὲ ˚Χριστοῦ, ˚Χριστὸς δὲ ˚Θεοῦ.
46004001 Οὕτως ἡμᾶς λογιζέσθω ἄνθρωπος ὡς ὑπηρέτας ˚Χριστοῦ, καὶ οἰκονόμους μυστηρίων ˚Θεοῦ.
46004002 Ὧδε λοιπὸν ζητεῖται ἐν τοῖς οἰκονόμοις ἵνα πιστός τις εὑρεθῇ.
46004003 Ἐμοὶ δὲ εἰς ἐλάχιστόν ἐστιν, ἵνα ὑφʼ ὑμῶν ἀνακριθῶ, ἢ ὑπὸ ἀνθρωπίνης ἡμέρας· ἀλλʼ οὐδὲ ἐμαυτὸν ἀνακρίνω.
46004004 Οὐδὲν γὰρ ἐμαυτῷ σύνοιδα, ἀλλʼ οὐκ ἐν τούτῳ δεδικαίωμαι, ὁ δὲ ἀνακρίνων με ˚Κύριός ἐστιν.
46004005 Ὥστε μὴ πρὸ καιροῦ τι κρίνετε, ἕως ἂν ἔλθῃ ὁ ˚Κύριος, ὃς καὶ φωτίσει τὰ κρυπτὰ τοῦ σκότους, καὶ φανερώσει τὰς βουλὰς τῶν καρδιῶν, καὶ τότε ὁ ἔπαινος γενήσεται ἑκάστῳ ἀπὸ τοῦ ˚Θεοῦ.
46004006 Ταῦτα δέ, ἀδελφοί, μετεσχημάτισα εἰς ἐμαυτὸν καὶ Ἀπολλῶν διʼ ὑμᾶς, ἵνα ἐν ἡμῖν μάθητε, τό “Μὴ ὑπὲρ ἃ γέγραπται”, ἵνα μὴ εἷς ὑπὲρ τοῦ ἑνὸς, φυσιοῦσθε κατὰ τοῦ ἑτέρου.
46004007 Τίς γάρ σε διακρίνει; Τί δὲ ἔχεις ὃ οὐκ ἔλαβες; Εἰ δὲ καὶ ἔλαβες, τί καυχᾶσαι ὡς μὴ λαβών;
46004008 Ἤδη κεκορεσμένοι ἐστέ, ἤδη ἐπλουτήσατε, χωρὶς ἡμῶν ἐβασιλεύσατε, καὶ ὄφελόν γε ἐβασιλεύσατε, ἵνα καὶ ἡμεῖς ὑμῖν συμβασιλεύσωμεν.
46004009 Δοκῶ γάρ ὁ ˚Θεὸς ἡμᾶς τοὺς ἀποστόλους ἐσχάτους ἀπέδειξεν, ὡς ἐπιθανατίους, ὅτι θέατρον ἐγενήθημεν τῷ κόσμῳ, καὶ ἀγγέλοις καὶ ἀνθρώποις.
46004010 Ἡμεῖς μωροὶ διὰ ˚Χριστόν, ὑμεῖς δὲ φρόνιμοι ἐν ˚Χριστῷ· ἡμεῖς ἀσθενεῖς, ὑμεῖς δὲ ἰσχυροί· ὑμεῖς ἔνδοξοι, ἡμεῖς δὲ ἄτιμοι.
46004011 Ἄχρι τῆς ἄρτι ὥρας, καὶ πεινῶμεν καὶ διψῶμεν, καὶ γυμνιτεύομεν, καὶ κολαφιζόμεθα, καὶ ἀστατοῦμεν,
46004012 καὶ κοπιῶμεν, ἐργαζόμενοι ταῖς ἰδίαις χερσίν. Λοιδορούμενοι, εὐλογοῦμεν, διωκόμενοι, ἀνεχόμεθα,
46004013 δυσφημούμενοι, παρακαλοῦμεν· ὡς περικαθάρματα τοῦ κόσμου ἐγενήθημεν, πάντων περίψημα ἕως ἄρτι.
46004014 Οὐκ ἐντρέπων ὑμᾶς γράφω ταῦτα, ἀλλʼ ὡς τέκνα μου ἀγαπητὰ, νουθετῶν.
46004015 Ἐὰν γὰρ μυρίους παιδαγωγοὺς ἔχητε ἐν ˚Χριστῷ, ἀλλʼ οὐ πολλοὺς πατέρας, ἐν γὰρ ˚Χριστῷ ˚Ἰησοῦ διὰ τοῦ εὐαγγελίου, ἐγὼ ὑμᾶς ἐγέννησα.
46004016 Παρακαλῶ οὖν ὑμᾶς, μιμηταί μου γίνεσθε.
46004017 Διὰ τοῦτο ἔπεμψα ὑμῖν Τιμόθεον, ὅς ἐστίν μου τέκνον, ἀγαπητὸν καὶ πιστὸν ἐν ˚Κυρίῳ, ὃς ὑμᾶς ἀναμνήσει τὰς ὁδούς μου τὰς ἐν ˚Χριστῷ ˚Ἰησοῦ, καθὼς πανταχοῦ ἐν πάσῃ ἐκκλησίᾳ διδάσκω.
46004018 Ὡς μὴ ἐρχομένου δέ μου πρὸς ὑμᾶς, ἐφυσιώθησάν τινες.
46004019 Ἐλεύσομαι δὲ ταχέως πρὸς ὑμᾶς, ἐὰν ὁ ˚Κύριος θελήσῃ, καὶ γνώσομαι, οὐ τὸν λόγον τῶν πεφυσιωμένων, ἀλλὰ τὴν δύναμιν.
46004020 Οὐ γὰρ ἐν λόγῳ ἡ Βασιλεία τοῦ ˚Θεοῦ, ἀλλʼ ἐν δυνάμει.
46004021 Τί θέλετε; Ἐν ῥάβδῳ ἔλθω πρὸς ὑμᾶς, ἢ ἐν ἀγάπῃ, πνεύματί τε πραΰτητος;
46005001 Ὅλως ἀκούεται ἐν ὑμῖν πορνεία, καὶ τοιαύτη πορνεία ἥτις οὐδὲ ἐν τοῖς ἔθνεσιν, ὥστε γυναῖκά τινα τοῦ πατρὸς ἔχειν.
46005002 Καὶ ὑμεῖς πεφυσιωμένοι ἐστέ, καὶ οὐχὶ μᾶλλον ἐπενθήσατε, ἵνα ἀρθῇ ἐκ μέσου ὑμῶν ὁ, τὸ ἔργον τοῦτο ποιήσας.
46005003 Ἐγὼ μὲν γάρ ἀπὼν τῷ σώματι, παρὼν δὲ τῷ πνεύματι, ἤδη κέκρικα ὡς παρὼν, τὸν οὕτως τοῦτο κατεργασάμενον.
46005004 Ἐν τῷ ὀνόματι τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ συναχθέντων ὑμῶν καὶ τοῦ ἐμοῦ πνεύματος, σὺν τῇ δυνάμει τοῦ ˚Κυρίου ἡμῶν ˚Ἰησοῦ,
46005005 παραδοῦναι τὸν τοιοῦτον τῷ Σατανᾷ εἰς ὄλεθρον τῆς σαρκός, ἵνα τὸ πνεῦμα σωθῇ ἐν τῇ ἡμέρᾳ τοῦ ˚Κυρίου.
46005006 Οὐ καλὸν τὸ καύχημα ὑμῶν. Οὐκ οἴδατε ὅτι μικρὰ ζύμη, ὅλον τὸ φύραμα ζυμοῖ;
46005007 Ἐκκαθάρατε τὴν παλαιὰν ζύμην, ἵνα ἦτε νέον φύραμα, καθώς ἐστε ἄζυμοι. Καὶ γὰρ τὸ Πάσχα ἡμῶν ἐτύθη, ˚Χριστός.
46005008 Ὥστε ἑορτάζωμεν, μὴ ἐν ζύμῃ παλαιᾷ, μηδὲ ἐν ζύμῃ κακίας καὶ πονηρίας, ἀλλʼ ἐν ἀζύμοις εἰλικρινείας καὶ ἀληθείας.
46005009 Ἔγραψα ὑμῖν ἐν τῇ ἐπιστολῇ, μὴ συναναμίγνυσθαι πόρνοις,
46005010 οὐ πάντως τοῖς πόρνοις τοῦ κόσμου τούτου, ἢ τοῖς πλεονέκταις καὶ ἅρπαξιν, ἢ εἰδωλολάτραις, ἐπεὶ ὠφείλετε ἄρα ἐκ τοῦ κόσμου ἐξελθεῖν.
46005011 Νῦν δὲ ἔγραψα ὑμῖν μὴ συναναμίγνυσθαι ἐάν τις “ἀδελφὸς” ὀνομαζόμενος ᾖ πόρνος, ἢ πλεονέκτης, ἢ εἰδωλολάτρης, ἢ λοίδορος, ἢ μέθυσος, ἢ ἅρπαξ, τῷ τοιούτῳ μηδὲ συνεσθίειν.
46005012 Τί γάρ μοι τοὺς ἔξω κρίνειν; Οὐχὶ τοὺς ἔσω ὑμεῖς κρίνετε;
46005013 Τοὺς δὲ ἔξω ὁ ˚Θεὸς κρίνει. “Ἐξάρατε τὸν πονηρὸν ἐξ ὑμῶν αὐτῶν.”
46006001 Τολμᾷ τις ὑμῶν, πρᾶγμα ἔχων πρὸς τὸν ἕτερον, κρίνεσθαι ἐπὶ τῶν ἀδίκων, καὶ οὐχὶ ἐπὶ τῶν ἁγίων;
46006002 Ἢ οὐκ οἴδατε ὅτι οἱ ἅγιοι τὸν κόσμον κρινοῦσιν; Καὶ εἰ ἐν ὑμῖν κρίνεται ὁ κόσμος, ἀνάξιοί ἐστε κριτηρίων ἐλαχίστων;
46006003 Οὐκ οἴδατε ὅτι ἀγγέλους κρινοῦμεν, μήτι γε βιωτικά;
46006004 Βιωτικὰ μὲν οὖν κριτήρια ἐὰν ἔχητε, τοὺς ἐξουθενημένους ἐν τῇ ἐκκλησίᾳ, τούτους καθίζετε;
46006005 Πρὸς ἐντροπὴν ὑμῖν λέγω. Οὕτως οὐκ ἔνι ἐν ὑμῖν οὐδεὶς σοφὸς, ὃς δυνήσεται διακρῖναι ἀνὰ μέσον τοῦ ἀδελφοῦ αὐτοῦ;
46006006 Ἀλλὰ ἀδελφὸς μετὰ ἀδελφοῦ κρίνεται, καὶ τοῦτο ἐπὶ ἀπίστων;
46006007 Ἤδη μὲν οὖν ὅλως ἥττημα ὑμῖν ἐστιν, ὅτι κρίματα ἔχετε μεθʼ ἑαυτῶν. Διὰ τί οὐχὶ μᾶλλον ἀδικεῖσθε; Διὰ τί οὐχὶ μᾶλλον ἀποστερεῖσθε;
46006008 Ἀλλὰ ὑμεῖς ἀδικεῖτε καὶ ἀποστερεῖτε, καὶ τοῦτο ἀδελφούς!
46006009 Ἢ οὐκ οἴδατε ὅτι ἄδικοι ˚Θεοῦ Βασιλείαν οὐ κληρονομήσουσιν; Μὴ πλανᾶσθε· οὔτε πόρνοι, οὔτε εἰδωλολάτραι, οὔτε μοιχοὶ, οὔτε μαλακοὶ, οὔτε ἀρσενοκοῖται,
46006010 οὔτε κλέπται, οὔτε πλεονέκται, οὐ μέθυσοι, οὐ λοίδοροι, οὐχ ἅρπαγες, Βασιλείαν ˚Θεοῦ κληρονομήσουσιν.
46006011 Καὶ ταῦτά τινες ἦτε· ἀλλὰ ἀπελούσασθε, ἀλλὰ ἡγιάσθητε, ἀλλὰ ἐδικαιώθητε, ἐν τῷ ὀνόματι τοῦ ˚Κυρίου ˚Ἰησοῦ ˚Χριστοῦ, καὶ ἐν τῷ ˚Πνεύματι τοῦ ˚Θεοῦ ἡμῶν.
46006012 Πάντα μοι ἔξεστιν, ἀλλʼ οὐ πάντα συμφέρει. Πάντα μοι ἔξεστιν, ἀλλʼ οὐκ ἐγὼ ἐξουσιασθήσομαι ὑπό τινος.
46006013 Τὰ βρώματα τῇ κοιλίᾳ, καὶ ἡ κοιλία τοῖς βρώμασιν· ὁ δὲ ˚Θεὸς καὶ ταύτην καὶ ταῦτα καταργήσει. Τὸ δὲ σῶμα οὐ τῇ πορνείᾳ, ἀλλὰ τῷ ˚Κυρίῳ, καὶ ὁ ˚Κύριος τῷ σώματι.
46006014 Ὁ δὲ ˚Θεὸς καὶ τὸν ˚Κύριον ἤγειρεν, καὶ ἡμᾶς ἐξεγερεῖ, διὰ τῆς δυνάμεως αὐτοῦ.
46006015 Οὐκ οἴδατε ὅτι τὰ σώματα ὑμῶν μέλη ˚Χριστοῦ ἐστιν; Ἄρας οὖν τὰ μέλη τοῦ ˚Χριστοῦ, ποιήσω πόρνης μέλη; Μὴ γένοιτο!
46006016 Ἢ οὐκ οἴδατε ὅτι ὁ κολλώμενος τῇ πόρνῃ, ἓν σῶμά ἐστιν; “Ἔσονται” γάρ, φησίν, “οἱ δύο εἰς σάρκα μίαν.”
46006017 Ὁ δὲ κολλώμενος τῷ ˚Κυρίῳ, ἓν πνεῦμά ἐστιν.
46006018 Φεύγετε τὴν πορνείαν. Πᾶν ἁμάρτημα ὃ ἐὰν ποιήσῃ ἄνθρωπος ἐκτὸς τοῦ σώματός ἐστιν, ὁ δὲ πορνεύων εἰς τὸ ἴδιον σῶμα ἁμαρτάνει.
46006019 Ἢ οὐκ οἴδατε ὅτι τὸ σῶμα ὑμῶν, ναὸς τοῦ ἐν ὑμῖν Ἁγίου ˚Πνεύματός ἐστιν, οὗ ἔχετε ἀπὸ ˚Θεοῦ; Καὶ οὐκ ἐστὲ ἑαυτῶν,
46006020 ἠγοράσθητε γὰρ τιμῆς· δοξάσατε δὴ τὸν ˚Θεὸν ἐν τῷ σώματι ὑμῶν.
46007001 Περὶ δὲ ὧν ἐγράψατε, καλὸν ἀνθρώπῳ, γυναικὸς μὴ ἅπτεσθαι.
46007002 Διὰ δὲ τὰς πορνείας, ἕκαστος τὴν ἑαυτοῦ γυναῖκα ἐχέτω, καὶ ἑκάστη τὸν ἴδιον ἄνδρα ἐχέτω.
46007003 Τῇ γυναικὶ ὁ ἀνὴρ τὴν ὀφειλὴν ἀποδιδότω, ὁμοίως δὲ καὶ ἡ γυνὴ τῷ ἀνδρί.
46007004 Ἡ γυνὴ τοῦ ἰδίου σώματος οὐκ ἐξουσιάζει, ἀλλὰ ὁ ἀνήρ· ὁμοίως δὲ, καὶ ὁ ἀνὴρ τοῦ ἰδίου σώματος οὐκ ἐξουσιάζει, ἀλλὰ ἡ γυνή.
46007005 Μὴ ἀποστερεῖτε ἀλλήλους, εἰ μήτι ἂν ἐκ συμφώνου πρὸς καιρὸν, ἵνα σχολάσητε τῇ προσευχῇ, καὶ πάλιν ἐπὶ τὸ αὐτὸ ἦτε, ἵνα μὴ πειράζῃ ὑμᾶς ὁ Σατανᾶς, διὰ τὴν ἀκρασίαν ὑμῶν.
46007006 Τοῦτο δὲ λέγω κατὰ συγγνώμην, οὐ κατʼ ἐπιταγήν.
46007007 Θέλω δὲ πάντας ἀνθρώπους εἶναι ὡς καὶ ἐμαυτόν. Ἀλλὰ ἕκαστος ἴδιον ἔχει χάρισμα ἐκ ˚Θεοῦ, ὁ μὲν οὕτως, ὁ δὲ οὕτως.
46007008 Λέγω δὲ τοῖς ἀγάμοις καὶ ταῖς χήραις, καλὸν αὐτοῖς ἐὰν μείνωσιν ὡς κἀγώ.
46007009 Εἰ δὲ οὐκ ἐγκρατεύονται, γαμησάτωσαν, κρεῖττον γάρ ἐστιν γαμῆσαι, ἢ πυροῦσθαι.
46007010 Τοῖς δὲ γεγαμηκόσιν, παραγγέλλω ( οὐκ ἐγὼ, ἀλλὰ ὁ ˚Κύριος ), γυναῖκα ἀπὸ ἀνδρὸς μὴ χωρισθῆναι
46007011 ( ἐὰν δὲ καὶ χωρισθῇ, μενέτω ἄγαμος ἢ τῷ ἀνδρὶ καταλλαγήτω ) καὶ ἄνδρα γυναῖκα μὴ ἀφιέναι.
46007012 Τοῖς δὲ λοιποῖς λέγω ( ἐγώ, οὐχ ὁ ˚Κύριος ), εἴ τις ἀδελφὸς γυναῖκα ἔχει ἄπιστον, καὶ αὕτη συνευδοκεῖ οἰκεῖν μετʼ αὐτοῦ, μὴ ἀφιέτω αὐτήν.
46007013 Καὶ γυνὴ ἥτις ἔχει ἄνδρα ἄπιστον, καὶ οὗτος συνευδοκεῖ οἰκεῖν μετʼ αὐτῆς, μὴ ἀφιέτω τὸν ἄνδρα.
46007014 Ἡγίασται γὰρ ὁ ἀνὴρ ὁ ἄπιστος ἐν τῇ γυναικί, καὶ ἡγίασται ἡ γυνὴ ἡ ἄπιστος ἐν τῷ ἀδελφῷ· ἐπεὶ ἄρα τὰ τέκνα ὑμῶν ἀκάθαρτά ἐστιν, νῦν δὲ ἅγιά ἐστιν.
46007015 Εἰ δὲ ὁ ἄπιστος χωρίζεται, χωριζέσθω. Οὐ δεδούλωται ὁ ἀδελφὸς ἢ ἡ ἀδελφὴ ἐν τοῖς τοιούτοις, ἐν δὲ εἰρήνῃ κέκληκεν ἡμᾶς ὁ ˚Θεός.
46007016 Τί γὰρ οἶδας, γύναι, εἰ τὸν ἄνδρα σώσεις; Ἢ τί οἶδας, ἄνερ, εἰ τὴν γυναῖκα σώσεις;
46007017 Εἰ μὴ ἑκάστῳ ὡς ἐμέρισεν ὁ ˚Κύριος, ἕκαστον ὡς κέκληκεν ὁ ˚Θεός, οὕτως περιπατείτω. Καὶ οὕτως ἐν ταῖς ἐκκλησίαις πάσαις διατάσσομαι.
46007018 Περιτετμημένος τις ἐκλήθη; Μὴ ἐπισπάσθω. Ἐν ἀκροβυστίᾳ κέκληταί τις; Μὴ περιτεμνέσθω.
46007019 Ἡ περιτομὴ οὐδέν ἐστιν, καὶ ἡ ἀκροβυστία οὐδέν ἐστιν, ἀλλὰ τήρησις ἐντολῶν ˚Θεοῦ.
46007020 Ἕκαστος ἐν τῇ κλήσει ᾗ ἐκλήθη, ἐν ταύτῃ μενέτω.
46007021 Δοῦλος ἐκλήθης; Μή σοι μελέτω· ἀλλʼ εἰ καὶ δύνασαι ἐλεύθερος γενέσθαι, μᾶλλον χρῆσαι.
46007022 Ὁ γὰρ ἐν ˚Κυρίῳ κληθεὶς δοῦλος, ἀπελεύθερος ˚Κυρίου ἐστίν· ὁμοίως ὁ ἐλεύθερος κληθεὶς, δοῦλός ἐστιν ˚Χριστοῦ.
46007023 Τιμῆς ἠγοράσθητε· μὴ γίνεσθε δοῦλοι ἀνθρώπων.
46007024 Ἕκαστος ἐν ᾧ ἐκλήθη, ἀδελφοί, ἐν τούτῳ μενέτω παρὰ ˚Θεῷ.
46007025 Περὶ δὲ τῶν παρθένων, ἐπιταγὴν ˚Κυρίου οὐκ ἔχω, γνώμην δὲ δίδωμι, ὡς ἠλεημένος ὑπὸ ˚Κυρίου, πιστὸς εἶναι.
46007026 Νομίζω οὖν τοῦτο καλὸν ὑπάρχειν διὰ τὴν ἐνεστῶσαν ἀνάγκην, ὅτι καλὸν ἀνθρώπῳ τὸ οὕτως εἶναι.
46007027 Δέδεσαι γυναικί; Μὴ ζήτει λύσιν. Λέλυσαι ἀπὸ γυναικός; Μὴ ζήτει γυναῖκα.
46007028 Ἐὰν δὲ καὶ γαμήσῃς, οὐχ ἥμαρτες. Καὶ ἐὰν γήμῃ ἡ παρθένος, οὐχ ἥμαρτεν. Θλῖψιν δὲ τῇ σαρκὶ ἕξουσιν οἱ τοιοῦτοι, ἐγὼ δὲ ὑμῶν φείδομαι.
46007029 Τοῦτο δέ φημι, ἀδελφοί, ὁ καιρὸς συνεσταλμένος ἐστίν. Τὸ λοιπὸν, ἵνα καὶ οἱ ἔχοντες γυναῖκας, ὡς μὴ ἔχοντες ὦσιν,
46007030 καὶ οἱ κλαίοντες, ὡς μὴ κλαίοντες, καὶ οἱ χαίροντες, ὡς μὴ χαίροντες, καὶ οἱ ἀγοράζοντες, ὡς μὴ κατέχοντες,
46007031 καὶ οἱ χρώμενοι τὸν κόσμον, ὡς μὴ καταχρώμενοι· παράγει γὰρ τὸ σχῆμα τοῦ κόσμου τούτου.
46007032 Θέλω δὲ ὑμᾶς ἀμερίμνους εἶναι. Ὁ ἄγαμος μεριμνᾷ τὰ τοῦ ˚Κυρίου, πῶς ἀρέσῃ τῷ ˚Κυρίῳ·
46007033 ὁ δὲ γαμήσας μεριμνᾷ τὰ τοῦ κόσμου, πῶς ἀρέσῃ τῇ γυναικί, καὶ μεμέρισται. Καὶ ἡ γυνὴ ἡ ἄγαμος καὶ ἡ παρθένος
46007034 ἡ ἄγαμος μεριμνᾷ τὰ τοῦ ˚Κυρίου, ἵνα ᾖ ἁγία, καὶ τῷ σώματι καὶ τῷ πνεύματι· ἡ δὲ γαμήσασα μεριμνᾷ τὰ τοῦ κόσμου, πῶς ἀρέσῃ τῷ ἀνδρί.
46007035 Τοῦτο δὲ πρὸς τὸ ὑμῶν αὐτῶν σύμφορον λέγω, οὐχ ἵνα βρόχον ὑμῖν ἐπιβάλω, ἀλλὰ πρὸς τὸ εὔσχημον καὶ εὐπάρεδρον τῷ ˚Κυρίῳ ἀπερισπάστως.
46007036 Εἰ δέ τις ἀσχημονεῖν ἐπὶ τὴν παρθένον αὐτοῦ νομίζει, ἐὰν ᾖ ὑπέρακμος καὶ οὕτως ὀφείλει γίνεσθαι, ὃ θέλει ποιείτω· οὐχ ἁμαρτάνει· γαμείτωσαν.
46007037 Ὃς δὲ ἕστηκεν ἐν τῇ καρδίᾳ αὐτοῦ ἑδραῖος, μὴ ἔχων ἀνάγκην, ἐξουσίαν δὲ ἔχει περὶ τοῦ ἰδίου θελήματος, καὶ τοῦτο κέκρικεν ἐν τῇ ἰδίᾳ καρδίᾳ, τηρεῖν τὴν ἑαυτοῦ παρθένον, καλῶς ποιήσει.
46007038 Ὥστε καὶ ὁ γαμίζων τὴν ἑαυτοῦ παρθένον, καλῶς ποιεῖ, καὶ ὁ μὴ γαμίζων, κρεῖσσον ποιήσει.
46007039 Γυνὴ δέδεται ἐφʼ ὅσον χρόνον ζῇ ὁ ἀνὴρ αὐτῆς· ἐὰν δὲ κοιμηθῇ ὁ ἀνήρ, ἐλευθέρα ἐστὶν ᾧ θέλει γαμηθῆναι, μόνον ἐν ˚Κυρίῳ.
46007040 Μακαριωτέρα δέ ἐστιν, ἐὰν οὕτως μείνῃ, κατὰ τὴν ἐμὴν γνώμην, δοκῶ δὲ κἀγὼ, ˚Πνεῦμα ˚Θεοῦ ἔχειν.
46008001 Περὶ δὲ τῶν εἰδωλοθύτων, οἴδαμεν ὅτι πάντες γνῶσιν ἔχομεν. Ἡ γνῶσις φυσιοῖ, ἡ δὲ ἀγάπη οἰκοδομεῖ.
46008002 Εἴ τις δοκεῖ ἐγνωκέναι τι, οὔπω ἔγνω καθὼς δεῖ γνῶναι.
46008003 Εἰ δέ τις ἀγαπᾷ τὸν ˚Θεόν, οὗτος ἔγνωσται ὑπʼ αὐτοῦ.
46008004 Περὶ τῆς βρώσεως οὖν τῶν εἰδωλοθύτων, οἴδαμεν ὅτι οὐδὲν εἴδωλον ἐν κόσμῳ, καὶ ὅτι οὐδεὶς ˚Θεὸς εἰ μὴ εἷς.
46008005 Καὶ γὰρ εἴπερ εἰσὶν λεγόμενοι θεοὶ, εἴτε ἐν οὐρανῷ εἴτε ἐπὶ γῆς, ὥσπερ εἰσὶν θεοὶ πολλοὶ καὶ κύριοι πολλοί,
46008006 ἀλλʼ ἡμῖν εἷς ˚Θεὸς ὁ Πατήρ, ἐξ οὗ τὰ πάντα, καὶ ἡμεῖς εἰς αὐτόν, καὶ εἷς ˚Κύριος, ˚Ἰησοῦς ˚Χριστός, διʼ οὗ τὰ πάντα, καὶ ἡμεῖς διʼ αὐτοῦ.
46008007 Ἀλλʼ οὐκ ἐν πᾶσιν ἡ γνῶσις· τινὲς δὲ τῇ συνηθείᾳ ἕως ἄρτι τοῦ εἰδώλου, ὡς εἰδωλόθυτον ἐσθίουσιν, καὶ ἡ συνείδησις αὐτῶν ἀσθενὴς οὖσα, μολύνεται.
46008008 Βρῶμα δὲ ἡμᾶς οὐ παραστήσει τῷ ˚Θεῷ· οὔτε ἐὰν μὴ φάγωμεν, περισσεύομεν, οὔτε ἐὰν φάγωμεν, ὑστερούμεθα.
46008009 Βλέπετε δὲ μή πως ἡ ἐξουσία ὑμῶν αὕτη, πρόσκομμα γένηται τοῖς ἀσθενέσιν.
46008010 Ἐὰν γάρ τις ἴδῃ σε τὸν ἔχοντα γνῶσιν ἐν εἰδωλείῳ κατακείμενον, οὐχὶ ἡ συνείδησις αὐτοῦ ἀσθενοῦς ὄντος, οἰκοδομηθήσεται, εἰς τὸ τὰ εἰδωλόθυτα ἐσθίειν;
46008011 Ἀπόλλυται γὰρ ὁ ἀσθενῶν ἐν τῇ σῇ γνώσει, ὁ ἀδελφὸς, διʼ ὃν ˚Χριστὸς ἀπέθανεν.
46008012 Οὕτως δὲ ἁμαρτάνοντες εἰς τοὺς ἀδελφοὺς, καὶ τύπτοντες αὐτῶν τὴν συνείδησιν ἀσθενοῦσαν, εἰς ˚Χριστὸν ἁμαρτάνετε.
46008013 Διόπερ εἰ βρῶμα σκανδαλίζει τὸν ἀδελφόν μου, οὐ μὴ φάγω κρέα εἰς τὸν αἰῶνα, ἵνα μὴ τὸν ἀδελφόν μου σκανδαλίσω.
46009001 Οὐκ εἰμὶ ἐλεύθερος; Οὐκ εἰμὶ ἀπόστολος; Οὐχὶ ˚Ἰησοῦν τὸν ˚Κύριον ἡμῶν ἑόρακα; Οὐ τὸ ἔργον μου ὑμεῖς ἐστε ἐν ˚Κυρίῳ;
46009002 Εἰ ἄλλοις οὐκ εἰμὶ ἀπόστολος, ἀλλά γε ὑμῖν εἰμι, ἡ γὰρ σφραγίς μου τῆς ἀποστολῆς, ὑμεῖς ἐστε ἐν ˚Κυρίῳ.
46009003 Ἡ ἐμὴ ἀπολογία τοῖς ἐμὲ ἀνακρίνουσίν ἐστιν αὕτη:
46009004 μὴ οὐκ ἔχομεν ἐξουσίαν φαγεῖν καὶ πεῖν;
46009005 Μὴ οὐκ ἔχομεν ἐξουσίαν ἀδελφὴν, γυναῖκα περιάγειν, ὡς καὶ οἱ λοιποὶ ἀπόστολοι, καὶ οἱ ἀδελφοὶ τοῦ ˚Κυρίου, καὶ Κηφᾶς;
46009006 Ἢ μόνος ἐγὼ καὶ Βαρναβᾶς, οὐκ ἔχομεν ἐξουσίαν μὴ ἐργάζεσθαι;
46009007 Τίς στρατεύεται ἰδίοις ὀψωνίοις ποτέ; Τίς φυτεύει ἀμπελῶνα, καὶ τὸν καρπὸν αὐτοῦ οὐκ ἐσθίει; Ἢ τίς ποιμαίνει ποίμνην, καὶ ἐκ τοῦ γάλακτος τῆς ποίμνης, οὐκ ἐσθίει;
46009008 Μὴ κατὰ ἄνθρωπον, ταῦτα λαλῶ, ἢ καὶ ὁ νόμος ταῦτα οὐ λέγει;
46009009 Ἐν γὰρ τῷ Μωϋσέως νόμῳ, γέγραπται, “Οὐ φιμώσεις βοῦν ἀλοῶντα.” Μὴ τῶν βοῶν μέλει τῷ ˚Θεῷ;
46009010 Ἢ διʼ ἡμᾶς πάντως λέγει; Διʼ ἡμᾶς γὰρ ἐγράφη ὅτι ὀφείλει ἐπʼ ἐλπίδι ὁ ἀροτριῶν ἀροτριᾶν, καὶ ὁ ἀλοῶν ἐπʼ ἐλπίδι τοῦ μετέχειν.
46009011 Εἰ ἡμεῖς ὑμῖν τὰ πνευματικὰ ἐσπείραμεν, μέγα εἰ ἡμεῖς ὑμῶν τὰ σαρκικὰ θερίσομεν;
46009012 Εἰ ἄλλοι τῆς ὑμῶν ἐξουσίας μετέχουσιν, οὐ μᾶλλον ἡμεῖς; Ἀλλʼ οὐκ ἐχρησάμεθα τῇ ἐξουσίᾳ ταύτῃ, ἀλλὰ πάντα στέγομεν, ἵνα μή τινα ἐγκοπὴν δῶμεν τῷ εὐαγγελίῳ τοῦ ˚Χριστοῦ.
46009013 Οὐκ οἴδατε ὅτι οἱ τὰ ἱερὰ ἐργαζόμενοι, ἐκ τοῦ ἱεροῦ ἐσθίουσιν, οἱ τῷ θυσιαστηρίῳ παρεδρεύοντες, τῷ θυσιαστηρίῳ συμμερίζονται;
46009014 Οὕτως καὶ ὁ ˚Κύριος διέταξεν τοῖς τὸ εὐαγγέλιον καταγγέλλουσιν, ἐκ τοῦ εὐαγγελίου ζῆν.
46009015 Ἐγὼ δὲ οὐ κέχρημαι οὐδενὶ τούτων. Οὐκ ἔγραψα δὲ ταῦτα, ἵνα οὕτως γένηται ἐν ἐμοί, καλὸν γάρ μοι μᾶλλον ἀποθανεῖν, ἢ τὸ καύχημά μου οὐδεὶς κενώσει.
46009016 Ἐὰν γὰρ εὐαγγελίζωμαι, οὐκ ἔστιν μοι καύχημα, ἀνάγκη γάρ μοι ἐπίκειται· οὐαὶ γάρ μοί ἐστιν, ἐὰν μὴ εὐαγγελίζωμαι.
46009017 Εἰ γὰρ ἑκὼν τοῦτο πράσσω, μισθὸν ἔχω· εἰ δὲ ἄκων, οἰκονομίαν πεπίστευμαι.
46009018 Τίς οὖν μού ἐστιν ὁ μισθός; Ἵνα εὐαγγελιζόμενος ἀδάπανον, θήσω τὸ εὐαγγέλιον, εἰς τὸ μὴ καταχρήσασθαι τῇ ἐξουσίᾳ μου ἐν τῷ εὐαγγελίῳ.
46009019 Ἐλεύθερος γὰρ ὢν ἐκ πάντων, πᾶσιν ἐμαυτὸν ἐδούλωσα, ἵνα τοὺς πλείονας κερδήσω.
46009020 Καὶ ἐγενόμην τοῖς Ἰουδαίοις, ὡς Ἰουδαῖος, ἵνα Ἰουδαίους κερδήσω· τοῖς ὑπὸ νόμον, ὡς ὑπὸ νόμον, μὴ ὢν αὐτὸς ὑπὸ νόμον, ἵνα τοὺς ὑπὸ νόμον κερδήσω·
46009021 τοῖς ἀνόμοις, ὡς ἄνομος, μὴ ὢν ἄνομος ˚Θεοῦ, ἀλλʼ ἔννομος ˚Χριστοῦ, ἵνα κερδάνω τοὺς ἀνόμους.
46009022 Ἐγενόμην τοῖς ἀσθενέσιν, ἀσθενής, ἵνα τοὺς ἀσθενεῖς κερδήσω. Τοῖς πᾶσιν γέγονα πάντα, ἵνα πάντως τινὰς σώσω.
46009023 Πάντα δὲ ποιῶ διὰ τὸ εὐαγγέλιον, ἵνα συγκοινωνὸς αὐτοῦ γένωμαι.
46009024 Οὐκ οἴδατε ὅτι οἱ ἐν σταδίῳ τρέχοντες, πάντες μὲν τρέχουσιν, εἷς δὲ λαμβάνει τὸ βραβεῖον; Οὕτως τρέχετε, ἵνα καταλάβητε.
46009025 Πᾶς δὲ ὁ ἀγωνιζόμενος, πάντα ἐγκρατεύεται, ἐκεῖνοι μὲν οὖν ἵνα φθαρτὸν στέφανον λάβωσιν, ἡμεῖς δὲ ἄφθαρτον.
46009026 Ἐγὼ τοίνυν οὕτως τρέχω, ὡς οὐκ ἀδήλως, οὕτως πυκτεύω, ὡς οὐκ ἀέρα δέρων·
46009027 ἀλλὰ ὑπωπιάζω μου τὸ σῶμα, καὶ δουλαγωγῶ, μή πως ἄλλοις κηρύξας, αὐτὸς ἀδόκιμος γένωμαι.
46010001 Οὐ θέλω γὰρ ὑμᾶς ἀγνοεῖν, ἀδελφοί, ὅτι οἱ πατέρες ἡμῶν πάντες ὑπὸ τὴν νεφέλην ἦσαν, καὶ πάντες διὰ τῆς θαλάσσης διῆλθον,
46010002 καὶ πάντες εἰς τὸν Μωϋσῆν ἐβαπτίσθησαν ἐν τῇ νεφέλῃ, καὶ ἐν τῇ θαλάσσῃ,
46010003 καὶ πάντες τὸ αὐτὸ πνευματικὸν βρῶμα ἔφαγον,
46010004 καὶ πάντες τὸ αὐτὸ πνευματικὸν ἔπιον πόμα, ἔπινον γὰρ ἐκ πνευματικῆς ἀκολουθούσης πέτρας, ἡ δὲ πέτρα ἦν ὁ ˚Χριστός.
46010005 Ἀλλʼ οὐκ ἐν τοῖς πλείοσιν αὐτῶν εὐδόκησεν ὁ ˚Θεός, κατεστρώθησαν γὰρ ἐν τῇ ἐρήμῳ.
46010006 Ταῦτα δὲ τύποι ἡμῶν ἐγενήθησαν, εἰς τὸ μὴ εἶναι ἡμᾶς ἐπιθυμητὰς κακῶν, καθὼς κἀκεῖνοι ἐπεθύμησαν.
46010007 Μηδὲ εἰδωλολάτραι γίνεσθε, καθώς τινες αὐτῶν· ὥσπερ γέγραπται, “Ἐκάθισεν ὁ λαὸς φαγεῖν καὶ πεῖν, καὶ ἀνέστησαν παίζειν.”
46010008 Μηδὲ πορνεύωμεν, καθώς τινες αὐτῶν ἐπόρνευσαν, καὶ ἔπεσαν μιᾷ ἡμέρᾳ εἴκοσι τρεῖς χιλιάδες.
46010009 Μηδὲ ἐκπειράζωμεν τὸν ˚Κύριον, καθώς τινες αὐτῶν ἐπείρασαν, καὶ ὑπὸ τῶν ὄφεων ἀπώλλυντο.
46010010 Μηδὲ γογγύζετε, καθάπερ τινὲς αὐτῶν ἐγόγγυσαν, καὶ ἀπώλοντο ὑπὸ τοῦ ὀλοθρευτοῦ.
46010011 Ταῦτα δὲ τυπικῶς συνέβαινεν ἐκείνοις, ἐγράφη δὲ πρὸς νουθεσίαν ἡμῶν, εἰς οὓς τὰ τέλη τῶν αἰώνων κατήντηκεν.
46010012 Ὥστε ὁ δοκῶν ἑστάναι, βλεπέτω μὴ πέσῃ.
46010013 Πειρασμὸς ὑμᾶς οὐκ εἴληφεν, εἰ μὴ ἀνθρώπινος· πιστὸς δὲ ὁ ˚Θεός, ὃς οὐκ ἐάσει ὑμᾶς πειρασθῆναι ὑπὲρ ὃ δύνασθε, ἀλλὰ ποιήσει σὺν τῷ πειρασμῷ καὶ τὴν ἔκβασιν τοῦ δύνασθαι ὑπενεγκεῖν.
46010014 Διόπερ, ἀγαπητοί μου, φεύγετε ἀπὸ τῆς εἰδωλολατρίας.
46010015 Ὡς φρονίμοις λέγω, κρίνατε ὑμεῖς ὅ φημι.
46010016 Τὸ ποτήριον τῆς εὐλογίας, ὃ εὐλογοῦμεν, οὐχὶ κοινωνία ἐστὶν τοῦ αἵματος τοῦ ˚Χριστοῦ; Τὸν ἄρτον ὃν κλῶμεν, οὐχὶ κοινωνία τοῦ σώματος τοῦ ˚Χριστοῦ ἐστιν;
46010017 Ὅτι εἷς ἄρτος, ἓν σῶμα οἱ πολλοί ἐσμεν, οἱ γὰρ πάντες ἐκ τοῦ ἑνὸς ἄρτου μετέχομεν.
46010018 Βλέπετε τὸν Ἰσραὴλ κατὰ σάρκα· οὐχὶ οἱ ἐσθίοντες τὰς θυσίας, κοινωνοὶ τοῦ θυσιαστηρίου εἰσίν;
46010019 Τί οὖν φημι; Ὅτι εἰδωλόθυτόν τί ἐστιν, τί ἐστιν;
46010020 Ἀλλʼ ὅτι ἃ θύουσιν τὰ ἔθνη, δαιμονίοις καὶ οὐ ˚Θεῷ θύουσιν, οὐ θέλω δὲ ὑμᾶς κοινωνοὺς τῶν δαιμονίων γίνεσθαι.
46010021 Οὐ δύνασθε ποτήριον ˚Κυρίου πίνειν, καὶ ποτήριον δαιμονίων. Οὐ δύνασθε τραπέζης ˚Κυρίου μετέχειν, καὶ τραπέζης δαιμονίων.
46010022 Ἢ παραζηλοῦμεν τὸν ˚Κύριον; Μὴ ἰσχυρότεροι αὐτοῦ ἐσμεν;
46010023 Πάντα ἔξεστιν, ἀλλʼ οὐ πάντα συμφέρει. Πάντα ἔξεστιν, ἀλλʼ οὐ πάντα οἰκοδομεῖ.
46010024 Μηδεὶς τὸ ἑαυτοῦ ζητείτω, ἀλλὰ τὸ τοῦ ἑτέρου.
46010025 Πᾶν τὸ ἐν μακέλλῳ πωλούμενον ἐσθίετε, μηδὲν ἀνακρίνοντες διὰ τὴν συνείδησιν.
46010026 “Τοῦ ˚Κυρίου γὰρ ἡ γῆ, καὶ τὸ πλήρωμα αὐτῆς.”
46010027 Εἴ τις καλεῖ ὑμᾶς τῶν ἀπίστων, καὶ θέλετε πορεύεσθαι, πᾶν τὸ παρατιθέμενον ὑμῖν ἐσθίετε, μηδὲν ἀνακρίνοντες διὰ τὴν συνείδησιν.
46010028 Ἐὰν δέ τις ὑμῖν εἴπῃ, “Τοῦτο ἱερόθυτόν ἐστιν”, μὴ ἐσθίετε, διʼ ἐκεῖνον τὸν μηνύσαντα, καὶ τὴν συνείδησιν·
46010029 συνείδησιν δὲ λέγω, οὐχὶ τὴν ἑαυτοῦ, ἀλλὰ τὴν τοῦ ἑτέρου· ἵνα τί γὰρ ἡ ἐλευθερία μου κρίνεται ὑπὸ ἄλλης συνειδήσεως;
46010030 Εἰ ἐγὼ χάριτι μετέχω, τί βλασφημοῦμαι ὑπὲρ οὗ ἐγὼ εὐχαριστῶ;
46010031 Εἴτε οὖν ἐσθίετε, εἴτε πίνετε, εἴτε τι ποιεῖτε, πάντα εἰς δόξαν ˚Θεοῦ ποιεῖτε.
46010032 Ἀπρόσκοποι καὶ Ἰουδαίοις γίνεσθε, καὶ Ἕλλησιν, καὶ τῇ ἐκκλησίᾳ τοῦ ˚Θεοῦ.
46010033 Καθὼς κἀγὼ πάντα, πᾶσιν ἀρέσκω, μὴ ζητῶν τὸ ἐμαυτοῦ σύμφορον, ἀλλὰ τὸ τῶν πολλῶν, ἵνα σωθῶσιν.
46011001 Μιμηταί μου γίνεσθε, καθὼς κἀγὼ ˚Χριστοῦ.
46011002 Ἐπαινῶ δὲ ὑμᾶς ὅτι πάντα μου μέμνησθε, καὶ καθὼς παρέδωκα ὑμῖν τὰς παραδόσεις κατέχετε.
46011003 Θέλω δὲ ὑμᾶς εἰδέναι ὅτι παντὸς ἀνδρὸς ἡ κεφαλὴ ὁ ˚Χριστός ἐστιν, κεφαλὴ δὲ γυναικὸς ὁ ἀνήρ, κεφαλὴ δὲ τοῦ ˚Χριστοῦ ὁ ˚Θεός.
46011004 Πᾶς ἀνὴρ προσευχόμενος ἢ προφητεύων κατὰ κεφαλῆς ἔχων, καταισχύνει τὴν κεφαλὴν αὐτοῦ.
46011005 Πᾶσα δὲ γυνὴ προσευχομένη ἢ προφητεύουσα ἀκατακαλύπτῳ τῇ κεφαλῇ, καταισχύνει τὴν κεφαλὴν αὐτῆς, ἓν γάρ ἐστιν καὶ τὸ αὐτὸ τῇ ἐξυρημένῃ.
46011006 Εἰ γὰρ οὐ κατακαλύπτεται γυνή, καὶ κειράσθω. Εἰ δὲ αἰσχρὸν γυναικὶ τὸ κείρασθαι ἢ ξυρᾶσθαι, κατακαλυπτέσθω.
46011007 Ἀνὴρ μὲν γὰρ οὐκ ὀφείλει κατακαλύπτεσθαι τὴν κεφαλήν, εἰκὼν καὶ δόξα ˚Θεοῦ ὑπάρχων· ἡ γυνὴ δὲ δόξα ἀνδρός ἐστιν.
46011008 Οὐ γάρ ἐστιν ἀνὴρ ἐκ γυναικός, ἀλλὰ γυνὴ ἐξ ἀνδρός.
46011009 Καὶ γὰρ οὐκ ἐκτίσθη ἀνὴρ διὰ τὴν γυναῖκα, ἀλλὰ γυνὴ διὰ τὸν ἄνδρα.
46011010 Διὰ τοῦτο ὀφείλει ἡ γυνὴ ἐξουσίαν ἔχειν ἐπὶ τῆς κεφαλῆς, διὰ τοὺς ἀγγέλους.
46011011 Πλὴν οὔτε γυνὴ χωρὶς ἀνδρὸς, οὔτε ἀνὴρ χωρὶς γυναικὸς ἐν ˚Κυρίῳ.
46011012 Ὥσπερ γὰρ ἡ γυνὴ ἐκ τοῦ ἀνδρός, οὕτως καὶ ὁ ἀνὴρ διὰ τῆς γυναικός, τὰ δὲ πάντα ἐκ τοῦ ˚Θεοῦ.
46011013 Ἐν ὑμῖν αὐτοῖς κρίνατε: πρέπον ἐστὶν γυναῖκα ἀκατακάλυπτον, τῷ ˚Θεῷ προσεύχεσθαι;
46011014 Οὐδὲ ἡ φύσις αὐτὴ διδάσκει ὑμᾶς ὅτι ἀνὴρ μὲν ἐὰν κομᾷ, ἀτιμία αὐτῷ ἐστιν,
46011015 γυνὴ δὲ ἐὰν κομᾷ, δόξα αὐτῇ ἐστιν; Ὅτι ἡ κόμη ἀντὶ περιβολαίου δέδοται αὐτῇ.
46011016 Εἰ δέ τις δοκεῖ φιλόνεικος εἶναι, ἡμεῖς τοιαύτην συνήθειαν οὐκ ἔχομεν, οὐδὲ αἱ ἐκκλησίαι τοῦ ˚Θεοῦ.
46011017 Τοῦτο δὲ παραγγέλλων, οὐκ ἐπαινῶ, ὅτι οὐκ εἰς τὸ κρεῖσσον, ἀλλὰ εἰς τὸ ἧσσον, συνέρχεσθε.
46011018 Πρῶτον μὲν γὰρ συνερχομένων ὑμῶν ἐν ἐκκλησίᾳ, ἀκούω σχίσματα ἐν ὑμῖν ὑπάρχειν, καὶ μέρος τι πιστεύω.
46011019 Δεῖ γὰρ καὶ αἱρέσεις ἐν ὑμῖν εἶναι, ἵνα καὶ οἱ δόκιμοι φανεροὶ γένωνται ἐν ὑμῖν.
46011020 Συνερχομένων οὖν ὑμῶν ἐπὶ τὸ αὐτὸ, οὐκ ἔστιν Κυριακὸν δεῖπνον φαγεῖν.
46011021 Ἕκαστος γὰρ τὸ ἴδιον δεῖπνον προλαμβάνει ἐν τῷ φαγεῖν, καὶ ὃς μὲν πεινᾷ, ὃς δὲ μεθύει.
46011022 Μὴ γὰρ οἰκίας οὐκ ἔχετε εἰς τὸ ἐσθίειν καὶ πίνειν; Ἢ τῆς ἐκκλησίας τοῦ ˚Θεοῦ καταφρονεῖτε, καὶ καταισχύνετε τοὺς μὴ ἔχοντας; Τί εἴπω ὑμῖν; Ἐπαινέσω ὑμᾶς ἐν τούτῳ; Οὐκ ἐπαινῶ!
46011023 Ἐγὼ γὰρ παρέλαβον ἀπὸ τοῦ ˚Κυρίου, ὃ καὶ παρέδωκα ὑμῖν, ὅτι ὁ ˚Κύριος ˚Ἰησοῦς ἐν τῇ νυκτὶ ᾗ παρεδίδετο, ἔλαβεν ἄρτον
46011024 καὶ εὐχαριστήσας ἔκλασεν, καὶ εἶπεν, “Τοῦτό μού ἐστιν τὸ σῶμα, τὸ ὑπὲρ ὑμῶν· τοῦτο ποιεῖτε εἰς τὴν ἐμὴν ἀνάμνησιν.”
46011025 Ὡσαύτως καὶ τὸ ποτήριον μετὰ τὸ δειπνῆσαι λέγων, “‘Τοῦτο τὸ ποτήριον ἡ καινὴ διαθήκη ἐστὶν ἐν τῷ ἐμῷ αἵματι’· τοῦτο ποιεῖτε, ὁσάκις ἐὰν πίνητε, εἰς τὴν ἐμὴν ἀνάμνησιν.”
46011026 Ὁσάκις γὰρ ἐὰν ἐσθίητε τὸν ἄρτον τοῦτον, καὶ τὸ ποτήριον πίνητε, τὸν θάνατον τοῦ ˚Κυρίου καταγγέλλετε, ἄχρι οὗ ἔλθῃ.
46011027 Ὥστε ὃς ἂν ἐσθίῃ τὸν ἄρτον ἢ πίνῃ τὸ ποτήριον τοῦ ˚Κυρίου ἀναξίως, ἔνοχος ἔσται τοῦ σώματος καὶ τοῦ αἵματος τοῦ ˚Κυρίου.
46011028 Δοκιμαζέτω δὲ ἄνθρωπος ἑαυτόν, καὶ οὕτως ἐκ τοῦ ἄρτου ἐσθιέτω, καὶ ἐκ τοῦ ποτηρίου πινέτω.
46011029 Ὁ γὰρ ἐσθίων καὶ πίνων κρίμα ἑαυτῷ, ἐσθίει καὶ πίνει μὴ διακρίνων τὸ σῶμα.
46011030 Διὰ τοῦτο ἐν ὑμῖν πολλοὶ ἀσθενεῖς καὶ ἄρρωστοι, καὶ κοιμῶνται ἱκανοί.
46011031 Εἰ δὲ ἑαυτοὺς διεκρίνομεν, οὐκ ἂν ἐκρινόμεθα.
46011032 Κρινόμενοι δὲ ὑπὸ τοῦ ˚Κυρίου, παιδευόμεθα, ἵνα μὴ σὺν τῷ κόσμῳ κατακριθῶμεν.
46011033 Ὥστε, ἀδελφοί μου, συνερχόμενοι εἰς τὸ φαγεῖν, ἀλλήλους ἐκδέχεσθε.
46011034 Εἴ τις πεινᾷ, ἐν οἴκῳ ἐσθιέτω, ἵνα μὴ εἰς κρίμα συνέρχησθε. Τὰ δὲ λοιπὰ, ὡς ἂν ἔλθω, διατάξομαι.
46012001 Περὶ δὲ τῶν πνευματικῶν, ἀδελφοί, οὐ θέλω ὑμᾶς ἀγνοεῖν.
46012002 Οἴδατε ὅτι ὅτε ἔθνη ἦτε, πρὸς τὰ εἴδωλα τὰ ἄφωνα ὡς ἂν ἤγεσθε, ἀπαγόμενοι.
46012003 Διὸ γνωρίζω ὑμῖν ὅτι οὐδεὶς ἐν ˚Πνεύματι ˚Θεοῦ λαλῶν, λέγει, “Ἀνάθεμα ˚Ἰησοῦς”, καὶ οὐδεὶς δύναται εἰπεῖν, “˚Κύριος ˚Ἰησοῦς”, εἰ μὴ ἐν ˚Πνεύματι Ἁγίῳ.
46012004 Διαιρέσεις δὲ χαρισμάτων εἰσίν, τὸ δὲ αὐτὸ ˚Πνεῦμα.
46012005 Καὶ διαιρέσεις διακονιῶν εἰσιν, καὶ ὁ αὐτὸς ˚Κύριος.
46012006 Καὶ διαιρέσεις ἐνεργημάτων εἰσίν, καὶ ὁ αὐτὸς ˚Θεός, ὁ ἐνεργῶν τὰ πάντα ἐν πᾶσιν.
46012007 Ἑκάστῳ δὲ δίδοται ἡ φανέρωσις τοῦ ˚Πνεύματος πρὸς τὸ συμφέρον.
46012008 ᾧ μὲν γὰρ διὰ τοῦ ˚Πνεύματος δίδοται λόγος σοφίας, ἄλλῳ δὲ λόγος γνώσεως κατὰ τὸ αὐτὸ ˚Πνεῦμα,
46012009 ἑτέρῳ δὲ πίστις ἐν τῷ αὐτῷ ˚Πνεύματι, ἄλλῳ δὲ χαρίσματα ἰαμάτων ἐν τῷ ἑνὶ ˚Πνεύματι,
46012010 ἄλλῳ δὲ ἐνεργήματα δυνάμεων, ἄλλῳ προφητεία, ἄλλῳ διακρίσεις πνευμάτων, ἑτέρῳ γένη γλωσσῶν, ἄλλῳ δὲ ἑρμηνεία γλωσσῶν.
46012011 Πάντα δὲ ταῦτα ἐνεργεῖ τὸ ἓν καὶ τὸ αὐτὸ ˚Πνεῦμα, διαιροῦν ἰδίᾳ ἑκάστῳ καθὼς βούλεται.
46012012 Καθάπερ γὰρ τὸ σῶμα ἕν ἐστιν, καὶ μέλη πολλὰ ἔχει, πάντα δὲ τὰ μέλη τοῦ σώματος, πολλὰ ὄντα, ἕν ἐστιν σῶμα, οὕτως καὶ ὁ ˚Χριστός.
46012013 Καὶ γὰρ ἐν ἑνὶ ˚Πνεύματι ἡμεῖς πάντες εἰς ἓν σῶμα ἐβαπτίσθημεν, εἴτε Ἰουδαῖοι, εἴτε Ἕλληνες, εἴτε δοῦλοι, εἴτε ἐλεύθεροι, καὶ πάντες ἓν ˚Πνεῦμα ἐποτίσθημεν.
46012014 Καὶ γὰρ τὸ σῶμα οὐκ ἔστιν ἓν μέλος, ἀλλὰ πολλά.
46012015 Ἐὰν εἴπῃ ὁ πούς, “Ὅτι οὐκ εἰμὶ χείρ, οὐκ εἰμὶ ἐκ τοῦ σώματος”, οὐ παρὰ τοῦτο, οὐκ ἔστιν ἐκ τοῦ σώματος.
46012016 Καὶ ἐὰν εἴπῃ τὸ οὖς, “Ὅτι οὐκ εἰμὶ ὀφθαλμός, οὐκ εἰμὶ ἐκ τοῦ σώματος”, οὐ παρὰ τοῦτο, οὐκ ἔστιν ἐκ τοῦ σώματος.
46012017 Εἰ ὅλον τὸ σῶμα ὀφθαλμός, ποῦ ἡ ἀκοή; Εἰ ὅλον ἀκοή, ποῦ ἡ ὄσφρησις;
46012018 Νυνὶ δὲ ὁ ˚Θεὸς ἔθετο τὰ μέλη, ἓν ἕκαστον αὐτῶν ἐν τῷ σώματι, καθὼς ἠθέλησεν.
46012019 Εἰ δὲ ἦν τὰ πάντα ἓν μέλος, ποῦ τὸ σῶμα;
46012020 Νῦν δὲ πολλὰ μὲν μέλη, ἓν δὲ σῶμα.
46012021 Οὐ δύναται δὲ ὁ ὀφθαλμὸς εἰπεῖν τῇ χειρί, “Χρείαν σου οὐκ ἔχω”, ἢ πάλιν ἡ κεφαλὴ τοῖς ποσίν, “Χρείαν ὑμῶν οὐκ ἔχω.”
46012022 Ἀλλὰ πολλῷ μᾶλλον τὰ δοκοῦντα μέλη τοῦ σώματος ἀσθενέστερα ὑπάρχειν, ἀναγκαῖά ἐστιν,
46012023 καὶ ἃ δοκοῦμεν ἀτιμότερα εἶναι τοῦ σώματος, τούτοις τιμὴν περισσοτέραν περιτίθεμεν, καὶ τὰ ἀσχήμονα ἡμῶν, εὐσχημοσύνην περισσοτέραν ἔχει,
46012024 τὰ δὲ εὐσχήμονα ἡμῶν, οὐ χρείαν ἔχει. Ἀλλὰ ὁ ˚Θεὸς συνεκέρασεν τὸ σῶμα, τῷ ὑστερουμένῳ, περισσοτέραν δοὺς τιμήν,
46012025 ἵνα μὴ ᾖ σχίσμα ἐν τῷ σώματι, ἀλλὰ τὸ αὐτὸ ὑπὲρ ἀλλήλων μεριμνῶσι τὰ μέλη.
46012026 Καὶ εἴτε πάσχει ἓν μέλος, συμπάσχει πάντα τὰ μέλη· εἴτε δοξάζεται μέλος, συγχαίρει πάντα τὰ μέλη.
46012027 Ὑμεῖς δέ ἐστε σῶμα ˚Χριστοῦ, καὶ μέλη ἐκ μέρους.
46012028 Καὶ οὓς μὲν ἔθετο ὁ ˚Θεὸς ἐν τῇ ἐκκλησίᾳ πρῶτον ἀποστόλους, δεύτερον προφήτας, τρίτον διδασκάλους, ἔπειτα δυνάμεις, ἔπειτα χαρίσματα ἰαμάτων, ἀντιλήμψεις, κυβερνήσεις, γένη γλωσσῶν.
46012029 Μὴ πάντες ἀπόστολοι; Μὴ πάντες προφῆται; Μὴ πάντες διδάσκαλοι; Μὴ πάντες δυνάμεις;
46012030 Μὴ πάντες χαρίσματα ἔχουσιν ἰαμάτων; Μὴ πάντες γλώσσαις λαλοῦσιν; Μὴ πάντες διερμηνεύουσιν;
46012031 Ζηλοῦτε δὲ τὰ χαρίσματα τὰ μείζονα. Καὶ ἔτι καθʼ ὑπερβολὴν ὁδὸν, ὑμῖν δείκνυμι.
46013001 Ἐὰν ταῖς γλώσσαις τῶν ἀνθρώπων λαλῶ καὶ τῶν ἀγγέλων, ἀγάπην δὲ μὴ ἔχω, γέγονα χαλκὸς ἠχῶν ἢ κύμβαλον ἀλαλάζον.
46013002 Κἂν ἔχω προφητείαν, καὶ εἰδῶ τὰ μυστήρια πάντα, καὶ πᾶσαν τὴν γνῶσιν, κἂν ἔχω πᾶσαν τὴν πίστιν, ὥστε ὄρη μεθιστάναι, ἀγάπην δὲ μὴ ἔχω, οὐθέν εἰμι.
46013003 Κἂν ψωμίσω πάντα τὰ ὑπάρχοντά μου, καὶ παραδῶ τὸ σῶμά μου, ἵνα καυχήσωμαι, ἀγάπην δὲ μὴ ἔχω, οὐδὲν ὠφελοῦμαι.
46013004 Ἡ ἀγάπη μακροθυμεῖ, χρηστεύεται· ἡ ἀγάπη οὐ ζηλοῖ· ἡ ἀγάπη οὐ περπερεύεται, οὐ φυσιοῦται,
46013005 οὐκ ἀσχημονεῖ, οὐ ζητεῖ τὰ ἑαυτῆς, οὐ παροξύνεται, οὐ λογίζεται τὸ κακόν,
46013006 οὐ χαίρει ἐπὶ τῇ ἀδικίᾳ, συγχαίρει δὲ τῇ ἀληθείᾳ,
46013007 πάντα στέγει, πάντα πιστεύει, πάντα ἐλπίζει, πάντα ὑπομένει.
46013008 Ἡ ἀγάπη οὐδέποτε πίπτει. Εἴτε δὲ προφητεῖαι, καταργηθήσονται· εἴτε γλῶσσαι, παύσονται· εἴτε γνῶσις, καταργηθήσεται.
46013009 Ἐκ μέρους γὰρ γινώσκομεν, καὶ ἐκ μέρους προφητεύομεν·
46013010 ὅταν δὲ ἔλθῃ τὸ τέλειον, τὸ ἐκ μέρους καταργηθήσεται.
46013011 Ὅτε ἤμην νήπιος, ἐλάλουν ὡς νήπιος, ἐφρόνουν ὡς νήπιος, ἐλογιζόμην ὡς νήπιος· ὅτε γέγονα ἀνήρ, κατήργηκα τὰ τοῦ νηπίου.
46013012 Βλέπομεν γὰρ ἄρτι διʼ ἐσόπτρου ἐν αἰνίγματι, τότε δὲ πρόσωπον πρὸς πρόσωπον· ἄρτι γινώσκω ἐκ μέρους, τότε δὲ ἐπιγνώσομαι, καθὼς καὶ ἐπεγνώσθην.
46013013 Νυνὶ δὲ μένει πίστις, ἐλπίς, ἀγάπη, τὰ τρία ταῦτα, μείζων δὲ τούτων ἡ ἀγάπη.
46014001 Διώκετε τὴν ἀγάπην, ζηλοῦτε δὲ τὰ πνευματικά, μᾶλλον δὲ ἵνα προφητεύητε.
46014002 Ὁ γὰρ λαλῶν γλώσσῃ, οὐκ ἀνθρώποις λαλεῖ, ἀλλὰ ˚Θεῷ, οὐδεὶς γὰρ ἀκούει, πνεύματι δὲ λαλεῖ μυστήρια.
46014003 Ὁ δὲ προφητεύων, ἀνθρώποις λαλεῖ οἰκοδομὴν, καὶ παράκλησιν, καὶ παραμυθίαν.
46014004 Ὁ λαλῶν γλώσσῃ, ἑαυτὸν οἰκοδομεῖ, ὁ δὲ προφητεύων, ἐκκλησίαν οἰκοδομεῖ.
46014005 Θέλω δὲ πάντας ὑμᾶς λαλεῖν γλώσσαις, μᾶλλον δὲ ἵνα προφητεύητε· μείζων δὲ ὁ προφητεύων, ἢ ὁ λαλῶν γλώσσαις, ἐκτὸς εἰ μὴ διερμηνεύῃ, ἵνα ἡ ἐκκλησία οἰκοδομὴν λάβῃ.
46014006 Νῦν δέ, ἀδελφοί, ἐὰν ἔλθω πρὸς ὑμᾶς γλώσσαις λαλῶν, τί ὑμᾶς ὠφελήσω, ἐὰν μὴ ὑμῖν λαλήσω, ἢ ἐν ἀποκαλύψει, ἢ ἐν γνώσει, ἢ ἐν προφητείᾳ, ἢ ἐν διδαχῇ;
46014007 Ὅμως τὰ ἄψυχα φωνὴν διδόντα, εἴτε αὐλὸς, εἴτε κιθάρα, ἐὰν διαστολὴν τοῖς φθόγγοις μὴ δῷ, πῶς γνωσθήσεται τὸ αὐλούμενον ἢ τὸ κιθαριζόμενον;
46014008 Καὶ γὰρ ἐὰν ἄδηλον σάλπιγξ φωνὴν δῷ, τίς παρασκευάσεται εἰς πόλεμον;
46014009 Οὕτως καὶ ὑμεῖς διὰ τῆς γλώσσης, ἐὰν μὴ εὔσημον λόγον δῶτε, πῶς γνωσθήσεται τὸ λαλούμενον; Ἔσεσθε γὰρ εἰς ἀέρα λαλοῦντες.
46014010 Τοσαῦτα εἰ τύχοι, γένη φωνῶν εἰσιν ἐν κόσμῳ, καὶ οὐδὲν ἄφωνον.
46014011 Ἐὰν οὖν μὴ εἰδῶ τὴν δύναμιν τῆς φωνῆς, ἔσομαι τῷ λαλοῦντι βάρβαρος, καὶ ὁ λαλῶν ἐν ἐμοὶ βάρβαρος.
46014012 Οὕτως καὶ ὑμεῖς, ἐπεὶ ζηλωταί ἐστε πνευμάτων, πρὸς τὴν οἰκοδομὴν τῆς ἐκκλησίας, ζητεῖτε ἵνα περισσεύητε.
46014013 Διὸ ὁ λαλῶν γλώσσῃ, προσευχέσθω ἵνα διερμηνεύῃ.
46014014 Ἐὰν γὰρ προσεύχωμαι γλώσσῃ, τὸ πνεῦμά μου προσεύχεται, ὁ δὲ νοῦς μου ἄκαρπός ἐστιν.
46014015 Τί οὖν ἐστιν; Προσεύξομαι τῷ ˚Πνεύματι, προσεύξομαι δὲ καὶ τῷ νοΐ. Ψαλῶ τῷ ˚Πνεύματι, ψαλῶ δὲ καὶ τῷ νοΐ.
46014016 Ἐπεὶ ἐὰν εὐλογῇς πνεύματι, ὁ ἀναπληρῶν τὸν τόπον τοῦ ἰδιώτου, πῶς ἐρεῖ, τὸ “Ἀμήν”, ἐπὶ τῇ σῇ εὐχαριστίᾳ, ἐπειδὴ τί λέγεις, οὐκ οἶδεν;
46014017 Σὺ μὲν γὰρ καλῶς εὐχαριστεῖς, ἀλλʼ ὁ ἕτερος οὐκ οἰκοδομεῖται.
46014018 Εὐχαριστῶ τῷ ˚Θεῷ, πάντων ὑμῶν μᾶλλον, γλώσσαις λαλῶ·
46014019 ἀλλὰ ἐν ἐκκλησίᾳ, θέλω πέντε λόγους τῷ νοΐ μου λαλῆσαι, ἵνα καὶ ἄλλους κατηχήσω, ἢ μυρίους λόγους ἐν γλώσσῃ.
46014020 Ἀδελφοί, μὴ παιδία γίνεσθε ταῖς φρεσίν, ἀλλὰ τῇ κακίᾳ, νηπιάζετε, ταῖς δὲ φρεσὶν, τέλειοι γίνεσθε.
46014021 Ἐν τῷ νόμῳ γέγραπται, ὅτι “Ἐν ἑτερογλώσσοις καὶ ἐν χείλεσιν ἑτέρων, λαλήσω τῷ λαῷ τούτῳ… καὶ οὐδʼ οὕτως εἰσακούσονταί μου”, λέγει ˚Κύριος.
46014022 Ὥστε αἱ γλῶσσαι εἰς σημεῖόν εἰσιν, οὐ τοῖς πιστεύουσιν, ἀλλὰ τοῖς ἀπίστοις, ἡ δὲ προφητεία, οὐ τοῖς ἀπίστοις, ἀλλὰ τοῖς πιστεύουσιν.
46014023 Ἐὰν οὖν συνέλθῃ ἡ ἐκκλησία ὅλη ἐπὶ τὸ αὐτὸ, καὶ πάντες λαλῶσιν γλώσσαις, εἰσέλθωσιν δὲ ἰδιῶται ἢ ἄπιστοι, οὐκ ἐροῦσιν ὅτι μαίνεσθε;
46014024 Ἐὰν δὲ πάντες προφητεύωσιν, εἰσέλθῃ δέ τις ἄπιστος ἢ ἰδιώτης, ἐλέγχεται ὑπὸ πάντων, ἀνακρίνεται ὑπὸ πάντων,
46014025 τὰ κρυπτὰ τῆς καρδίας αὐτοῦ φανερὰ γίνεται, καὶ οὕτως πεσὼν ἐπὶ πρόσωπον, προσκυνήσει τῷ ˚Θεῷ ἀπαγγέλλων, ὅτι “Ὄντως ὁ ˚Θεὸς ἐν ὑμῖν ἐστιν.”
46014026 Τί οὖν ἐστιν, ἀδελφοί; Ὅταν συνέρχησθε, ἕκαστος ψαλμὸν ἔχει, διδαχὴν ἔχει, ἀποκάλυψιν ἔχει, γλῶσσαν ἔχει, ἑρμηνείαν ἔχει. Πάντα πρὸς οἰκοδομὴν γινέσθω.
46014027 Εἴτε γλώσσῃ τις λαλεῖ, κατὰ δύο ἢ τὸ πλεῖστον τρεῖς, καὶ ἀνὰ μέρος, καὶ εἷς διερμηνευέτω.
46014028 Ἐὰν δὲ μὴ ᾖ διερμηνευτής, σιγάτω ἐν ἐκκλησίᾳ, ἑαυτῷ δὲ λαλείτω καὶ τῷ ˚Θεῷ.
46014029 Προφῆται δὲ δύο ἢ τρεῖς λαλείτωσαν, καὶ οἱ ἄλλοι διακρινέτωσαν.
46014030 Ἐὰν δὲ ἄλλῳ ἀποκαλυφθῇ καθημένῳ, ὁ πρῶτος σιγάτω.
46014031 Δύνασθε γὰρ καθʼ ἕνα πάντες προφητεύειν, ἵνα πάντες μανθάνωσιν, καὶ πάντες παρακαλῶνται.
46014032 Καὶ πνεύματα προφητῶν, προφήταις ὑποτάσσεται.
46014033 Οὐ γάρ ἐστιν ἀκαταστασίας ὁ ˚Θεὸς, ἀλλὰ εἰρήνης. Ὡς ἐν πάσαις ταῖς ἐκκλησίαις τῶν ἁγίων,
46014034 αἱ γυναῖκες ἐν ταῖς ἐκκλησίαις σιγάτωσαν, οὐ γὰρ ἐπιτρέπεται αὐταῖς λαλεῖν, ἀλλὰ ὑποτασσέσθωσαν, καθὼς καὶ ὁ νόμος λέγει.
46014035 Εἰ δέ τι μαθεῖν θέλουσιν, ἐν οἴκῳ τοὺς ἰδίους ἄνδρας ἐπερωτάτωσαν, αἰσχρὸν γάρ ἐστιν γυναικὶ λαλεῖν ἐν ἐκκλησίᾳ.
46014036 Ἢ ἀφʼ ὑμῶν ὁ λόγος τοῦ ˚Θεοῦ ἐξῆλθεν, ἢ εἰς ὑμᾶς μόνους κατήντησεν;
46014037 Εἴ τις δοκεῖ προφήτης εἶναι ἢ πνευματικός, ἐπιγινωσκέτω ἃ γράφω ὑμῖν ὅτι ˚Κυρίου ἐστὶν ἐντολή.
46014038 Εἰ δέ τις ἀγνοεῖ, ἀγνοεῖται.
46014039 Ὥστε, ἀδελφοί, ζηλοῦτε τὸ προφητεύειν, καὶ τὸ λαλεῖν μὴ κωλύετε ἐν γλώσσαις.
46014040 Πάντα δὲ εὐσχημόνως καὶ κατὰ τάξιν γινέσθω.
46015001 Γνωρίζω δὲ ὑμῖν, ἀδελφοί, τὸ εὐαγγέλιον ὃ εὐηγγελισάμην ὑμῖν, ὃ καὶ παρελάβετε, ἐν ᾧ καὶ ἑστήκατε,
46015002 διʼ οὗ καὶ σῴζεσθε, τίνι λόγῳ εὐηγγελισάμην ὑμῖν, εἰ κατέχετε, ἐκτὸς εἰ μὴ εἰκῇ ἐπιστεύσατε.
46015003 Παρέδωκα γὰρ ὑμῖν ἐν πρώτοις, ὃ καὶ παρέλαβον: ὅτι ˚Χριστὸς ἀπέθανεν ὑπὲρ τῶν ἁμαρτιῶν ἡμῶν κατὰ τὰς Γραφάς,
46015004 καὶ ὅτι ἐτάφη, καὶ ὅτι ἐγήγερται τῇ ἡμέρᾳ τῇ τρίτῃ κατὰ τὰς Γραφάς,
46015005 καὶ ὅτι ὤφθη Κηφᾷ, εἶτα τοῖς δώδεκα.
46015006 Ἔπειτα ὤφθη ἐπάνω πεντακοσίοις ἀδελφοῖς ἐφάπαξ, ἐξ ὧν οἱ πλείονες μένουσιν ἕως ἄρτι, τινὲς δὲ ἐκοιμήθησαν.
46015007 Ἔπειτα ὤφθη Ἰακώβῳ, εἶτα τοῖς ἀποστόλοις πᾶσιν,
46015008 ἔσχατον δὲ πάντων, ὡσπερεὶ τῷ ἐκτρώματι, ὤφθη κἀμοί.
46015009 Ἐγὼ γάρ εἰμι ὁ ἐλάχιστος τῶν ἀποστόλων, ὃς οὐκ εἰμὶ ἱκανὸς καλεῖσθαι ἀπόστολος, διότι ἐδίωξα τὴν ἐκκλησίαν τοῦ ˚Θεοῦ.
46015010 Χάριτι δὲ ˚Θεοῦ, εἰμι ὅ εἰμι, καὶ ἡ χάρις αὐτοῦ ἡ εἰς ἐμὲ, οὐ κενὴ ἐγενήθη, ἀλλὰ περισσότερον αὐτῶν πάντων ἐκοπίασα, οὐκ ἐγὼ δὲ, ἀλλὰ ἡ χάρις τοῦ ˚Θεοῦ σὺν ἐμοί.
46015011 Εἴτε οὖν ἐγὼ εἴτε ἐκεῖνοι, οὕτως κηρύσσομεν, καὶ οὕτως ἐπιστεύσατε.
46015012 Εἰ δὲ ˚Χριστὸς κηρύσσεται, ὅτι ἐκ νεκρῶν ἐγήγερται, πῶς λέγουσιν ἐν ὑμῖν τινες ὅτι ἀνάστασις νεκρῶν οὐκ ἔστιν;
46015013 Εἰ δὲ ἀνάστασις νεκρῶν οὐκ ἔστιν, οὐδὲ ˚Χριστὸς ἐγήγερται·
46015014 εἰ δὲ ˚Χριστὸς οὐκ ἐγήγερται, κενὸν ἄρα τὸ κήρυγμα ἡμῶν, κενὴ καὶ ἡ πίστις ὑμῶν.
46015015 Εὑρισκόμεθα δὲ καὶ ψευδομάρτυρες τοῦ ˚Θεοῦ, ὅτι ἐμαρτυρήσαμεν κατὰ τοῦ ˚Θεοῦ ὅτι ἤγειρεν τὸν ˚Χριστόν, ὃν οὐκ ἤγειρεν, εἴπερ ἄρα νεκροὶ οὐκ ἐγείρονται.
46015016 Εἰ γὰρ νεκροὶ οὐκ ἐγείρονται, οὐδὲ ˚Χριστὸς ἐγήγερται·
46015017 εἰ δὲ ˚Χριστὸς οὐκ ἐγήγερται, ματαία ἡ πίστις ὑμῶν, καὶ ἔτι ἐστὲ ἐν ταῖς ἁμαρτίαις ὑμῶν.
46015018 Ἄρα καὶ οἱ κοιμηθέντες ἐν ˚Χριστῷ ἀπώλοντο.
46015019 Εἰ ἐν τῇ ζωῇ ταύτῃ, ἐν ˚Χριστῷ ἠλπικότες ἐσμὲν μόνον, ἐλεεινότεροι πάντων ἀνθρώπων ἐσμέν.
46015020 Νυνὶ δὲ ˚Χριστὸς ἐγήγερται ἐκ νεκρῶν, ἀπαρχὴ τῶν κεκοιμημένων.
46015021 Ἐπειδὴ γὰρ διʼ ἀνθρώπου θάνατος, καὶ διʼ ἀνθρώπου ἀνάστασις νεκρῶν.
46015022 Ὥσπερ γὰρ ἐν τῷ Ἀδὰμ, πάντες ἀποθνῄσκουσιν, οὕτως καὶ ἐν τῷ ˚Χριστῷ, πάντες ζῳοποιηθήσονται.
46015023 Ἕκαστος δὲ ἐν τῷ ἰδίῳ τάγματι: ἀπαρχὴ ˚Χριστός, ἔπειτα οἱ τοῦ ˚Χριστοῦ ἐν τῇ παρουσίᾳ αὐτοῦ,
46015024 εἶτα τὸ τέλος, ὅταν παραδιδῷ τὴν βασιλείαν τῷ ˚Θεῷ καὶ Πατρί, ὅταν καταργήσῃ πᾶσαν ἀρχὴν, καὶ πᾶσαν ἐξουσίαν, καὶ δύναμιν.
46015025 Δεῖ γὰρ αὐτὸν βασιλεύειν, ἄχρι οὗ θῇ πάντας τοὺς ἐχθροὺς ὑπὸ τοὺς πόδας αὐτοῦ.
46015026 Ἔσχατος ἐχθρὸς καταργεῖται ὁ θάνατος.
46015027 “Πάντα γὰρ ὑπέταξεν ὑπὸ τοὺς πόδας αὐτοῦ.” Ὅταν δὲ εἴπῃ ὅτι πάντα ὑποτέτακται, δῆλον ὅτι ἐκτὸς τοῦ ὑποτάξαντος αὐτῷ τὰ πάντα.
46015028 Ὅταν δὲ ὑποταγῇ αὐτῷ τὰ πάντα, τότε καὶ αὐτὸς ὁ Υἱὸς, ὑποταγήσεται τῷ ὑποτάξαντι αὐτῷ τὰ πάντα, ἵνα ᾖ ὁ ˚Θεὸς πάντα ἐν πᾶσιν.
46015029 Ἐπεὶ τί ποιήσουσιν, οἱ βαπτιζόμενοι ὑπὲρ τῶν νεκρῶν; Εἰ ὅλως νεκροὶ οὐκ ἐγείρονται, τί καὶ βαπτίζονται ὑπὲρ αὐτῶν;
46015030 Τί καὶ ἡμεῖς κινδυνεύομεν πᾶσαν ὥραν;
46015031 Καθʼ ἡμέραν ἀποθνῄσκω, νὴ τὴν ὑμετέραν καύχησιν, ἀδελφοί, ἣν ἔχω ἐν ˚Χριστῷ ˚Ἰησοῦ, τῷ ˚Κυρίῳ ἡμῶν.
46015032 Εἰ κατὰ ἄνθρωπον, ἐθηριομάχησα ἐν Ἐφέσῳ, τί μοι τὸ ὄφελος; Εἰ νεκροὶ οὐκ ἐγείρονται, “Φάγωμεν καὶ πίωμεν, αὔριον γὰρ ἀποθνῄσκομεν.”
46015033 Μὴ πλανᾶσθε– φθείρουσιν ἤθη χρηστὰ ὁμιλίαι κακαί.
46015034 Ἐκνήψατε δικαίως καὶ μὴ ἁμαρτάνετε, ἀγνωσίαν γὰρ ˚Θεοῦ τινες ἔχουσιν· πρὸς ἐντροπὴν ὑμῖν λαλῶ.
46015035 Ἀλλʼ ἐρεῖ τις, “Πῶς ἐγείρονται οἱ νεκροί;” Ποίῳ δὲ σώματι ἔρχονται;
46015036 Ἄφρων! Σὺ ὃ σπείρεις, οὐ ζῳοποιεῖται, ἐὰν μὴ ἀποθάνῃ.
46015037 Καὶ ὃ σπείρεις, οὐ τὸ σῶμα τὸ γενησόμενον σπείρεις, ἀλλὰ γυμνὸν κόκκον, εἰ τύχοι σίτου, ἤ τινος τῶν λοιπῶν.
46015038 Ὁ δὲ ˚Θεὸς δίδωσιν αὐτῷ σῶμα, καθὼς ἠθέλησεν, καὶ ἑκάστῳ τῶν σπερμάτων, ἴδιον σῶμα.
46015039 Οὐ πᾶσα σὰρξ ἡ αὐτὴ σάρξ, ἀλλὰ ἄλλη μὲν ἀνθρώπων, ἄλλη δὲ σὰρξ κτηνῶν, ἄλλη δὲ σὰρξ πτηνῶν, ἄλλη δὲ ἰχθύων.
46015040 Καὶ σώματα ἐπουράνια, καὶ σώματα ἐπίγεια. Ἀλλὰ ἑτέρα μὲν ἡ τῶν ἐπουρανίων δόξα, ἑτέρα δὲ ἡ τῶν ἐπιγείων.
46015041 Ἄλλη δόξα ἡλίου, καὶ ἄλλη δόξα σελήνης, καὶ ἄλλη δόξα ἀστέρων, ἀστὴρ γὰρ ἀστέρος διαφέρει ἐν δόξῃ.
46015042 Οὕτως καὶ ἡ ἀνάστασις τῶν νεκρῶν. Σπείρεται ἐν φθορᾷ, ἐγείρεται ἐν ἀφθαρσίᾳ·
46015043 σπείρεται ἐν ἀτιμίᾳ, ἐγείρεται ἐν δόξῃ· σπείρεται ἐν ἀσθενείᾳ, ἐγείρεται ἐν δυνάμει·
46015044 σπείρεται σῶμα ψυχικόν, ἐγείρεται σῶμα πνευματικόν. Εἰ ἔστιν σῶμα ψυχικόν, ἔστιν καὶ πνευματικόν.
46015045 Οὕτως καὶ γέγραπται, “Ἐγένετο ὁ πρῶτος ἄνθρωπος”, Ἀδὰμ, “εἰς ψυχὴν ζῶσαν”. Ὁ ἔσχατος Ἀδὰμ εἰς πνεῦμα ζῳοποιοῦν.
46015046 Ἀλλʼ οὐ πρῶτον τὸ πνευματικὸν, ἀλλὰ τὸ ψυχικόν, ἔπειτα τὸ πνευματικόν.
46015047 Ὁ πρῶτος ἄνθρωπος ἐκ γῆς, χοϊκός, ὁ δεύτερος ἄνθρωπος, ἐξ οὐρανοῦ.
46015048 Οἷος ὁ χοϊκός, τοιοῦτοι καὶ οἱ χοϊκοί, καὶ οἷος ὁ ἐπουράνιος, τοιοῦτοι καὶ οἱ ἐπουράνιοι.
46015049 Καὶ καθὼς ἐφορέσαμεν τὴν εἰκόνα τοῦ χοϊκοῦ, φορέσομεν καὶ τὴν εἰκόνα τοῦ ἐπουρανίου.
46015050 Τοῦτο δέ φημι, ἀδελφοί, ὅτι σὰρξ καὶ αἷμα Βασιλείαν ˚Θεοῦ κληρονομῆσαι οὐ δύναται, οὐδὲ ἡ φθορὰ, τὴν ἀφθαρσίαν κληρονομεῖ.
46015051 Ἰδοὺ, μυστήριον ὑμῖν λέγω: πάντες οὐ κοιμηθησόμεθα, πάντες δὲ ἀλλαγησόμεθα,
46015052 ἐν ἀτόμῳ, ἐν ῥιπῇ ὀφθαλμοῦ, ἐν τῇ ἐσχάτῃ σάλπιγγι· σαλπίσει γάρ, καὶ οἱ νεκροὶ ἐγερθήσονται ἄφθαρτοι, καὶ ἡμεῖς ἀλλαγησόμεθα.
46015053 Δεῖ γὰρ τὸ φθαρτὸν τοῦτο ἐνδύσασθαι ἀφθαρσίαν, καὶ τὸ θνητὸν τοῦτο ἐνδύσασθαι ἀθανασίαν.
46015054 Ὅταν δὲ τὸ φθαρτὸν τοῦτο ἐνδύσηται ἀφθαρσίαν, καὶ τὸ θνητὸν τοῦτο ἐνδύσηται ἀθανασίαν, τότε γενήσεται ὁ λόγος ὁ γεγραμμένος, “Κατεπόθη ὁ θάνατος εἰς νῖκος.”
46015055 “Ποῦ σου, θάνατε, τὸ νῖκος; Ποῦ σου, θάνατε, τὸ κέντρον;”
46015056 Τὸ δὲ κέντρον τοῦ θανάτου ἡ ἁμαρτία, ἡ δὲ δύναμις τῆς ἁμαρτίας ὁ νόμος.
46015057 Τῷ δὲ ˚Θεῷ χάρις, τῷ διδόντι ἡμῖν τὸ νῖκος διὰ τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ ˚Χριστοῦ.
46015058 Ὥστε, ἀδελφοί μου ἀγαπητοί, ἑδραῖοι γίνεσθε, ἀμετακίνητοι, περισσεύοντες ἐν τῷ ἔργῳ τοῦ ˚Κυρίου πάντοτε, εἰδότες ὅτι ὁ κόπος ὑμῶν οὐκ ἔστιν κενὸς ἐν ˚Κυρίῳ.
46016001 Περὶ δὲ τῆς λογείας τῆς εἰς τοὺς ἁγίους, ὥσπερ διέταξα ταῖς ἐκκλησίαις τῆς Γαλατίας, οὕτως καὶ ὑμεῖς ποιήσατε.
46016002 Κατὰ μίαν σαββάτου, ἕκαστος ὑμῶν παρʼ ἑαυτῷ τιθέτω, θησαυρίζων ὅ τι ἂν εὐοδῶται, ἵνα μὴ ὅταν ἔλθω, τότε λογεῖαι γίνωνται.
46016003 Ὅταν δὲ παραγένωμαι, οὓς ἐὰν δοκιμάσητε, διʼ ἐπιστολῶν τούτους πέμψω, ἀπενεγκεῖν τὴν χάριν ὑμῶν εἰς Ἰερουσαλήμ.
46016004 Ἐὰν δὲ ἄξιον ᾖ τοῦ κἀμὲ πορεύεσθαι, σὺν ἐμοὶ πορεύσονται.
46016005 Ἐλεύσομαι δὲ πρὸς ὑμᾶς, ὅταν Μακεδονίαν διέλθω, Μακεδονίαν γὰρ διέρχομαι.
46016006 Πρὸς ὑμᾶς δὲ τυχὸν παραμενῶ, ἢ παραχειμάσω, ἵνα ὑμεῖς με προπέμψητε, οὗ ἐὰν πορεύωμαι.
46016007 Οὐ θέλω γὰρ ὑμᾶς ἄρτι ἐν παρόδῳ ἰδεῖν, ἐλπίζω γὰρ χρόνον τινὰ ἐπιμεῖναι πρὸς ὑμᾶς, ἐὰν ὁ ˚Κύριος ἐπιτρέψῃ.
46016008 Ἐπιμενῶ δὲ ἐν Ἐφέσῳ ἕως τῆς Πεντηκοστῆς·
46016009 θύρα γάρ μοι ἀνέῳγεν μεγάλη καὶ ἐνεργής, καὶ ἀντικείμενοι πολλοί.
46016010 Ἐὰν δὲ ἔλθῃ Τιμόθεος, βλέπετε ἵνα ἀφόβως γένηται πρὸς ὑμᾶς, τὸ γὰρ ἔργον ˚Κυρίου ἐργάζεται, ὡς ἐγώ.
46016011 Μή τις οὖν αὐτὸν ἐξουθενήσῃ. Προπέμψατε δὲ αὐτὸν ἐν εἰρήνῃ, ἵνα ἔλθῃ πρός με, ἐκδέχομαι γὰρ αὐτὸν μετὰ τῶν ἀδελφῶν.
46016012 Περὶ δὲ Ἀπολλῶ τοῦ ἀδελφοῦ, πολλὰ παρεκάλεσα αὐτὸν ἵνα ἔλθῃ πρὸς ὑμᾶς μετὰ τῶν ἀδελφῶν· καὶ πάντως οὐκ ἦν θέλημα ἵνα νῦν ἔλθῃ, ἐλεύσεται δὲ ὅταν εὐκαιρήσῃ.
46016013 Γρηγορεῖτε, στήκετε ἐν τῇ πίστει, ἀνδρίζεσθε, κραταιοῦσθε.
46016014 Πάντα ὑμῶν ἐν ἀγάπῃ γινέσθω.
46016015 Παρακαλῶ δὲ ὑμᾶς, ἀδελφοί, οἴδατε τὴν οἰκίαν Στεφανᾶ ὅτι ἐστὶν ἀπαρχὴ τῆς Ἀχαΐας, καὶ εἰς διακονίαν τοῖς ἁγίοις ἔταξαν ἑαυτούς·
46016016 ἵνα καὶ ὑμεῖς ὑποτάσσησθε τοῖς τοιούτοις, καὶ παντὶ τῷ συνεργοῦντι καὶ κοπιῶντι.
46016017 Χαίρω δὲ ἐπὶ τῇ παρουσίᾳ Στεφανᾶ, καὶ Φορτουνάτου, καὶ Ἀχαϊκοῦ, ὅτι τὸ ὑμῶν ὑστέρημα οὗτοι ἀνεπλήρωσαν.
46016018 Ἀνέπαυσαν γὰρ τὸ ἐμὸν πνεῦμα καὶ τὸ ὑμῶν. Ἐπιγινώσκετε οὖν τοὺς τοιούτους.
46016019 Ἀσπάζονται ὑμᾶς αἱ ἐκκλησίαι τῆς Ἀσίας. Ἀσπάζονται ὑμᾶς ἐν ˚Κυρίῳ πολλὰ Ἀκύλας καὶ Πρίσκα, σὺν τῇ κατʼ οἶκον αὐτῶν ἐκκλησίᾳ.
46016020 Ἀσπάζονται ὑμᾶς οἱ ἀδελφοὶ πάντες. Ἀσπάσασθε ἀλλήλους ἐν φιλήματι ἁγίῳ.
46016021 Ὁ ἀσπασμὸς τῇ ἐμῇ χειρὶ, Παύλου.
46016022 Εἴ τις οὐ φιλεῖ τὸν ˚Κύριον, ἤτω ἀνάθεμα. Μαράνα θά!
46016023 Ἡ χάρις τοῦ ˚Κυρίου ˚Ἰησοῦ ˚Χριστοῦ μεθʼ ὑμῶν.
46016024 Ἡ ἀγάπη μου μετὰ πάντων ὑμῶν ἐν ˚Χριστῷ ˚Ἰησοῦ. Ἀμήν!
47001001 Παῦλος, ἀπόστολος ˚Χριστοῦ ˚Ἰησοῦ διὰ θελήματος ˚Θεοῦ, καὶ Τιμόθεος ὁ ἀδελφὸς, τῇ ἐκκλησίᾳ τοῦ ˚Θεοῦ τῇ οὔσῃ ἐν Κορίνθῳ, σὺν τοῖς ἁγίοις πᾶσιν τοῖς οὖσιν ἐν ὅλῃ τῇ Ἀχαΐᾳ:
47001002 χάρις ὑμῖν καὶ εἰρήνη, ἀπὸ ˚Θεοῦ Πατρὸς ἡμῶν καὶ ˚Κυρίου ˚Ἰησοῦ ˚Χριστοῦ.
47001003 Εὐλογητὸς ὁ ˚Θεὸς καὶ Πατὴρ τοῦ ˚Κυρίου ἡμῶν ˚Ἰησοῦ ˚Χριστοῦ, ὁ Πατὴρ τῶν οἰκτιρμῶν καὶ ˚Θεὸς πάσης παρακλήσεως,
47001004 ὁ παρακαλῶν ἡμᾶς ἐπὶ πάσῃ τῇ θλίψει ἡμῶν, εἰς τὸ δύνασθαι ἡμᾶς παρακαλεῖν τοὺς ἐν πάσῃ θλίψει, διὰ τῆς παρακλήσεως ἧς παρακαλούμεθα αὐτοὶ ὑπὸ τοῦ ˚Θεοῦ.
47001005 Ὅτι καθὼς περισσεύει τὰ παθήματα τοῦ ˚Χριστοῦ εἰς ἡμᾶς, οὕτως διὰ τοῦ ˚Χριστοῦ περισσεύει καὶ ἡ παράκλησις ἡμῶν.
47001006 Εἴτε δὲ θλιβόμεθα, ὑπὲρ τῆς ὑμῶν παρακλήσεως καὶ σωτηρίας, εἴτε παρακαλούμεθα, ὑπὲρ τῆς ὑμῶν παρακλήσεως, τῆς ἐνεργουμένης ἐν ὑπομονῇ τῶν αὐτῶν παθημάτων, ὧν καὶ ἡμεῖς πάσχομεν.
47001007 Καὶ ἡ ἐλπὶς ἡμῶν βεβαία ὑπὲρ ὑμῶν, εἰδότες ὅτι ὡς κοινωνοί ἐστε τῶν παθημάτων, οὕτως καὶ τῆς παρακλήσεως.
47001008 Οὐ γὰρ θέλομεν ὑμᾶς ἀγνοεῖν, ἀδελφοί, ὑπὲρ τῆς θλίψεως ἡμῶν τῆς γενομένης ἐν τῇ Ἀσίᾳ, ὅτι καθʼ ὑπερβολὴν ὑπὲρ δύναμιν ἐβαρήθημεν, ὥστε ἐξαπορηθῆναι ἡμᾶς καὶ τοῦ ζῆν.
47001009 Ἀλλὰ αὐτοὶ ἐν ἑαυτοῖς τὸ ἀπόκριμα τοῦ θανάτου ἐσχήκαμεν, ἵνα μὴ πεποιθότες ὦμεν ἐφʼ ἑαυτοῖς, ἀλλʼ ἐπὶ τῷ ˚Θεῷ, τῷ ἐγείροντι τοὺς νεκρούς·
47001010 ὃς ἐκ τηλικούτου θανάτου ἐρρύσατο ἡμᾶς, καὶ ῥύσεται, εἰς ὃν ἠλπίκαμεν ὅτι καὶ ἔτι ῥύσεται,
47001011 συνυπουργούντων καὶ ὑμῶν ὑπὲρ ἡμῶν τῇ δεήσει, ἵνα ἐκ πολλῶν προσώπων, τὸ εἰς ἡμᾶς χάρισμα, διὰ πολλῶν, εὐχαριστηθῇ ὑπὲρ ἡμῶν.
47001012 Ἡ γὰρ καύχησις ἡμῶν αὕτη ἐστίν: τὸ μαρτύριον τῆς συνειδήσεως ἡμῶν, ὅτι ἐν ἁπλότητι καὶ εἰλικρινείᾳ τοῦ ˚Θεοῦ, καὶ οὐκ ἐν σοφίᾳ σαρκικῇ, ἀλλʼ ἐν χάριτι ˚Θεοῦ, ἀνεστράφημεν ἐν τῷ κόσμῳ, περισσοτέρως δὲ πρὸς ὑμᾶς.
47001013 Οὐ γὰρ ἄλλα γράφομεν ὑμῖν, ἀλλʼ ἢ ἃ ἀναγινώσκετε ἢ καὶ ἐπιγινώσκετε, ἐλπίζω δὲ ὅτι ἕως τέλους ἐπιγνώσεσθε,
47001014 καθὼς καὶ ἐπέγνωτε ἡμᾶς ἀπὸ μέρους, ὅτι καύχημα ὑμῶν ἐσμεν, καθάπερ καὶ ὑμεῖς ἡμῶν, ἐν τῇ ἡμέρᾳ τοῦ ˚Κυρίου ˚Ἰησοῦ.
47001015 Καὶ ταύτῃ τῇ πεποιθήσει ἐβουλόμην πρότερον, πρὸς ὑμᾶς ἐλθεῖν, ἵνα δευτέραν χάριν σχῆτε,
47001016 καὶ διʼ ὑμῶν διελθεῖν εἰς Μακεδονίαν, καὶ πάλιν ἀπὸ Μακεδονίας ἐλθεῖν πρὸς ὑμᾶς, καὶ ὑφʼ ὑμῶν προπεμφθῆναι εἰς τὴν Ἰουδαίαν.
47001017 Τοῦτο οὖν βουλόμενος, μήτι ἄρα τῇ ἐλαφρίᾳ ἐχρησάμην; Ἢ ἃ βουλεύομαι, κατὰ σάρκα βουλεύομαι, ἵνα ᾖ παρʼ ἐμοὶ τὸ “Ναὶ, ναὶ”, καὶ τὸ “Οὒ, οὔ;”
47001018 Πιστὸς δὲ ὁ ˚Θεὸς, ὅτι ὁ λόγος ἡμῶν ὁ πρὸς ὑμᾶς, οὐκ ἔστιν “Ναὶ” καὶ “Οὔ”.
47001019 Ὁ τοῦ ˚Θεοῦ γὰρ Υἱὸς, ˚Ἰησοῦς ˚Χριστὸς, ὁ ἐν ὑμῖν διʼ ἡμῶν κηρυχθείς, διʼ ἐμοῦ, καὶ Σιλουανοῦ, καὶ Τιμοθέου, οὐκ ἐγένετο “Ναὶ” καὶ “Οὒ”, ἀλλὰ “Ναὶ” ἐν αὐτῷ γέγονεν.
47001020 Ὅσαι γὰρ ἐπαγγελίαι ˚Θεοῦ, ἐν αὐτῷ τὸ “Ναί”. Διὸ καὶ διʼ αὐτοῦ, τὸ “Ἀμὴν”, τῷ ˚Θεῷ πρὸς δόξαν διʼ ἡμῶν.
47001021 Ὁ δὲ βεβαιῶν ἡμᾶς σὺν ὑμῖν εἰς ˚Χριστὸν καὶ χρίσας ἡμᾶς ˚Θεός,
47001022 ὁ καὶ σφραγισάμενος ἡμᾶς καὶ δοὺς τὸν ἀρραβῶνα τοῦ ˚Πνεύματος ἐν ταῖς καρδίαις ἡμῶν.
47001023 Ἐγὼ δὲ μάρτυρα τὸν ˚Θεὸν ἐπικαλοῦμαι ἐπὶ τὴν ἐμὴν ψυχήν ὅτι φειδόμενος ὑμῶν οὐκέτι ἦλθον εἰς Κόρινθον.
47001024 Οὐχ ὅτι κυριεύομεν ὑμῶν τῆς πίστεως, ἀλλὰ συνεργοί ἐσμεν τῆς χαρᾶς ὑμῶν, τῇ γὰρ πίστει ἑστήκατε.
47002001 Ἔκρινα γὰρ ἐμαυτῷ τοῦτο, τὸ μὴ πάλιν ἐν λύπῃ πρὸς ὑμᾶς ἐλθεῖν.
47002002 Εἰ γὰρ ἐγὼ λυπῶ ὑμᾶς, καὶ τίς ὁ εὐφραίνων με, εἰ μὴ ὁ λυπούμενος ἐξ ἐμοῦ;
47002003 Καὶ ἔγραψα τοῦτο αὐτὸ, ἵνα μὴ ἐλθὼν, λύπην σχῶ ἀφʼ ὧν ἔδει με χαίρειν, πεποιθὼς ἐπὶ πάντας ὑμᾶς ὅτι ἡ ἐμὴ χαρὰ πάντων ὑμῶν ἐστιν.
47002004 Ἐκ γὰρ πολλῆς θλίψεως καὶ συνοχῆς καρδίας, ἔγραψα ὑμῖν διὰ πολλῶν δακρύων, οὐχ ἵνα λυπηθῆτε, ἀλλὰ τὴν ἀγάπην ἵνα γνῶτε ἣν ἔχω περισσοτέρως εἰς ὑμᾶς.
47002005 Εἰ δέ τις λελύπηκεν, οὐκ ἐμὲ λελύπηκεν, ἀλλὰ ἀπὸ μέρους, ἵνα μὴ ἐπιβαρῶ πάντας ὑμᾶς.
47002006 Ἱκανὸν τῷ τοιούτῳ ἡ ἐπιτιμία αὕτη, ἡ ὑπὸ τῶν πλειόνων,
47002007 ὥστε τοὐναντίον μᾶλλον ὑμᾶς χαρίσασθαι καὶ παρακαλέσαι, μή πως τῇ περισσοτέρᾳ λύπῃ, καταποθῇ ὁ τοιοῦτος.
47002008 Διὸ παρακαλῶ ὑμᾶς, κυρῶσαι εἰς αὐτὸν ἀγάπην.
47002009 Εἰς τοῦτο γὰρ καὶ ἔγραψα, ἵνα γνῶ τὴν δοκιμὴν ὑμῶν, εἰ εἰς πάντα ὑπήκοοί ἐστε.
47002010 ᾧ δέ τι χαρίζεσθε, κἀγώ· καὶ γὰρ ἐγὼ ὃ κεχάρισμαι, εἴ τι κεχάρισμαι, διʼ ὑμᾶς ἐν προσώπῳ ˚Χριστοῦ,
47002011 ἵνα μὴ πλεονεκτηθῶμεν ὑπὸ τοῦ Σατανᾶ, οὐ γὰρ αὐτοῦ τὰ νοήματα ἀγνοοῦμεν.
47002012 Ἐλθὼν δὲ εἰς τὴν Τρῳάδα εἰς τὸ εὐαγγέλιον τοῦ ˚Χριστοῦ, καὶ θύρας μοι ἀνεῳγμένης ἐν ˚Κυρίῳ,
47002013 οὐκ ἔσχηκα ἄνεσιν τῷ πνεύματί μου, τῷ μὴ εὑρεῖν με Τίτον τὸν ἀδελφόν μου, ἀλλὰ ἀποταξάμενος αὐτοῖς, ἐξῆλθον εἰς Μακεδονίαν.
47002014 Τῷ δὲ ˚Θεῷ χάρις, τῷ πάντοτε θριαμβεύοντι ἡμᾶς ἐν τῷ ˚Χριστῷ, καὶ τὴν ὀσμὴν τῆς γνώσεως αὐτοῦ, φανεροῦντι διʼ ἡμῶν ἐν παντὶ τόπῳ.
47002015 Ὅτι ˚Χριστοῦ εὐωδία ἐσμὲν τῷ ˚Θεῷ, ἐν τοῖς σῳζομένοις καὶ ἐν τοῖς ἀπολλυμένοις,
47002016 οἷς μὲν ὀσμὴ ἐκ θανάτου εἰς θάνατον, οἷς δὲ ὀσμὴ ἐκ ζωῆς εἰς ζωήν. Καὶ πρὸς ταῦτα τίς ἱκανός;
47002017 Οὐ γάρ ἐσμεν ὡς οἱ πολλοὶ καπηλεύοντες τὸν λόγον τοῦ ˚Θεοῦ, ἀλλʼ ὡς ἐξ εἰλικρινείας, ἀλλʼ ὡς ἐκ ˚Θεοῦ, κατέναντι ˚Θεοῦ ἐν ˚Χριστῷ λαλοῦμεν.
47003001 Ἀρχόμεθα πάλιν ἑαυτοὺς συνιστάνειν; Ἢ μὴ χρῄζομεν, ὥς τινες, συστατικῶν ἐπιστολῶν πρὸς ὑμᾶς ἢ ἐξ ὑμῶν;
47003002 Ἡ ἐπιστολὴ ἡμῶν ὑμεῖς ἐστε, ἐγγεγραμμένη ἐν ταῖς καρδίαις ἡμῶν, γινωσκομένη καὶ ἀναγινωσκομένη ὑπὸ πάντων ἀνθρώπων,
47003003 φανερούμενοι ὅτι ἐστὲ ἐπιστολὴ ˚Χριστοῦ, διακονηθεῖσα ὑφʼ ἡμῶν, ἐγγεγραμμένη οὐ μέλανι, ἀλλὰ ˚Πνεύματι ˚Θεοῦ ζῶντος, οὐκ ἐν πλαξὶν λιθίναις, ἀλλʼ ἐν πλαξὶν καρδίαις σαρκίναις.
47003004 Πεποίθησιν δὲ τοιαύτην ἔχομεν διὰ τοῦ ˚Χριστοῦ πρὸς τὸν ˚Θεόν.
47003005 Οὐχ ὅτι ἀφʼ ἑαυτῶν ἱκανοί ἐσμεν λογίσασθαί τι ὡς ἐξ ἑαυτῶν, ἀλλʼ ἡ ἱκανότης ἡμῶν ἐκ τοῦ ˚Θεοῦ,
47003006 ὃς καὶ ἱκάνωσεν ἡμᾶς διακόνους καινῆς διαθήκης, οὐ γράμματος, ἀλλὰ ˚Πνεύματος, τὸ γὰρ γράμμα ἀποκτέννει, τὸ δὲ ˚Πνεῦμα ζῳοποιεῖ.
47003007 Εἰ δὲ ἡ διακονία τοῦ θανάτου ἐν γράμμασιν ἐντετυπωμένη λίθοις ἐγενήθη ἐν δόξῃ, ὥστε μὴ δύνασθαι ἀτενίσαι τοὺς υἱοὺς Ἰσραὴλ εἰς τὸ πρόσωπον Μωϋσέως, διὰ τὴν δόξαν τοῦ προσώπου αὐτοῦ, τὴν καταργουμένην,
47003008 πῶς οὐχὶ μᾶλλον ἡ διακονία τοῦ ˚Πνεύματος ἔσται ἐν δόξῃ;
47003009 Εἰ γὰρ τῇ διακονίᾳ τῆς κατακρίσεως, δόξα πολλῷ μᾶλλον περισσεύει ἡ διακονία τῆς δικαιοσύνης δόξῃ.
47003010 Καὶ γὰρ οὐ δεδόξασται, τὸ δεδοξασμένον ἐν τούτῳ τῷ μέρει εἵνεκεν τῆς ὑπερβαλλούσης δόξης.
47003011 Εἰ γὰρ τὸ καταργούμενον διὰ δόξης, πολλῷ μᾶλλον τὸ μένον ἐν δόξῃ.
47003012 Ἔχοντες οὖν τοιαύτην ἐλπίδα πολλῇ παρρησίᾳ χρώμεθα,
47003013 καὶ οὐ καθάπερ Μωϋσῆς ἐτίθει κάλυμμα ἐπὶ τὸ πρόσωπον αὐτοῦ, πρὸς τὸ μὴ ἀτενίσαι τοὺς υἱοὺς Ἰσραὴλ, εἰς τὸ τέλος τοῦ καταργουμένου.
47003014 Ἀλλʼ ἐπωρώθη τὰ νοήματα αὐτῶν. Ἄχρι γὰρ τῆς σήμερον ἡμέρας, τὸ αὐτὸ κάλυμμα ἐπὶ τῇ ἀναγνώσει τῆς παλαιᾶς διαθήκης μένει, μὴ ἀνακαλυπτόμενον ὅτι ἐν ˚Χριστῷ καταργεῖται.
47003015 Ἀλλʼ ἕως σήμερον ἡνίκα ἂν ἀναγινώσκηται Μωϋσῆς, κάλυμμα ἐπὶ τὴν καρδίαν αὐτῶν κεῖται.
47003016 Ἡνίκα δὲ ἐὰν ἐπιστρέψῃ πρὸς ˚Κύριον, περιαιρεῖται τὸ κάλυμμα.
47003017 Ὁ δὲ ˚Κύριος τὸ ˚Πνεῦμά ἐστιν· οὗ δὲ τὸ ˚Πνεῦμα ˚Κυρίου, ἐλευθερία.
47003018 Ἡμεῖς δὲ πάντες ἀνακεκαλυμμένῳ προσώπῳ, τὴν δόξαν ˚Κυρίου κατοπτριζόμενοι, τὴν αὐτὴν εἰκόνα μεταμορφούμεθα, ἀπὸ δόξης εἰς δόξαν, καθάπερ ἀπὸ ˚Κυρίου, ˚Πνεύματος.
47004001 Διὰ τοῦτο ἔχοντες τὴν διακονίαν ταύτην, καθὼς ἠλεήθημεν, οὐκ ἐγκακοῦμεν.
47004002 Ἀλλὰ ἀπειπάμεθα τὰ κρυπτὰ τῆς αἰσχύνης, μὴ περιπατοῦντες ἐν πανουργίᾳ, μηδὲ δολοῦντες τὸν λόγον τοῦ ˚Θεοῦ, ἀλλὰ τῇ φανερώσει τῆς ἀληθείας, συνιστάνοντες ἑαυτοὺς πρὸς πᾶσαν συνείδησιν ἀνθρώπων ἐνώπιον τοῦ ˚Θεοῦ.
47004003 Εἰ δὲ καὶ ἔστιν κεκαλυμμένον τὸ εὐαγγέλιον ἡμῶν, ἐν τοῖς ἀπολλυμένοις ἐστὶν κεκαλυμμένον,
47004004 ἐν οἷς ὁ ˚θεὸς τοῦ αἰῶνος τούτου ἐτύφλωσεν τὰ νοήματα τῶν ἀπίστων, εἰς τὸ μὴ αὐγάσαι τὸν φωτισμὸν τοῦ εὐαγγελίου τῆς δόξης τοῦ ˚Χριστοῦ, ὅς ἐστιν εἰκὼν τοῦ ˚Θεοῦ.
47004005 Οὐ γὰρ ἑαυτοὺς κηρύσσομεν, ἀλλὰ ˚Ἰησοῦν ˚Χριστὸν ˚Κύριον, ἑαυτοὺς δὲ δούλους ὑμῶν διὰ ˚Ἰησοῦν.
47004006 Ὅτι ὁ ˚Θεὸς ὁ εἰπών, “Ἐκ σκότους φῶς λάμψει”, ὃς ἔλαμψεν ἐν ταῖς καρδίαις ἡμῶν, πρὸς φωτισμὸν τῆς γνώσεως τῆς δόξης τοῦ ˚Θεοῦ ἐν προσώπῳ ˚Ἰησοῦ ˚Χριστοῦ.
47004007 Ἔχομεν δὲ τὸν θησαυρὸν τοῦτον ἐν ὀστρακίνοις σκεύεσιν, ἵνα ἡ ὑπερβολὴ τῆς δυνάμεως ᾖ τοῦ ˚Θεοῦ, καὶ μὴ ἐξ ἡμῶν·
47004008 ἐν παντὶ θλιβόμενοι, ἀλλʼ οὐ στενοχωρούμενοι, ἀπορούμενοι, ἀλλʼ οὐκ ἐξαπορούμενοι,
47004009 διωκόμενοι, ἀλλʼ οὐκ ἐγκαταλειπόμενοι, καταβαλλόμενοι, ἀλλʼ οὐκ ἀπολλύμενοι,
47004010 πάντοτε τὴν νέκρωσιν τοῦ ˚Ἰησοῦ ἐν τῷ σώματι περιφέροντες, ἵνα καὶ ἡ ζωὴ τοῦ ˚Ἰησοῦ ἐν τῷ σώματι ἡμῶν φανερωθῇ.
47004011 Ἀεὶ γὰρ ἡμεῖς, οἱ ζῶντες, εἰς θάνατον παραδιδόμεθα διὰ ˚Ἰησοῦν, ἵνα καὶ ἡ ζωὴ τοῦ ˚Ἰησοῦ φανερωθῇ ἐν τῇ θνητῇ σαρκὶ ἡμῶν.
47004012 Ὥστε ὁ θάνατος ἐν ἡμῖν ἐνεργεῖται, ἡ δὲ ζωὴ ἐν ὑμῖν.
47004013 Ἔχοντες δὲ τὸ αὐτὸ πνεῦμα τῆς πίστεως, κατὰ τὸ γεγραμμένον, “Ἐπίστευσα, διὸ ἐλάλησα”, καὶ ἡμεῖς πιστεύομεν, διὸ καὶ λαλοῦμεν,
47004014 εἰδότες ὅτι ὁ ἐγείρας τὸν ˚Ἰησοῦν, καὶ ἡμᾶς σὺν ˚Ἰησοῦ ἐγερεῖ, καὶ παραστήσει σὺν ὑμῖν.
47004015 Τὰ γὰρ πάντα διʼ ὑμᾶς, ἵνα ἡ χάρις πλεονάσασα διὰ τῶν πλειόνων, τὴν εὐχαριστίαν περισσεύσῃ εἰς τὴν δόξαν τοῦ ˚Θεοῦ.
47004016 Διὸ οὐκ ἐγκακοῦμεν, ἀλλʼ εἰ καὶ ὁ ἔξω ἡμῶν ἄνθρωπος διαφθείρεται, ἀλλʼ ὁ ἔσω ἡμῶν ἀνακαινοῦται ἡμέρᾳ καὶ ἡμέρᾳ.
47004017 Τὸ γὰρ παραυτίκα ἐλαφρὸν τῆς θλίψεως καθʼ ὑπερβολὴν εἰς ὑπερβολὴν αἰώνιον βάρος δόξης, κατεργάζεται ἡμῖν,
47004018 μὴ σκοπούντων ἡμῶν τὰ βλεπόμενα, ἀλλὰ τὰ μὴ βλεπόμενα, τὰ γὰρ βλεπόμενα πρόσκαιρα, τὰ δὲ μὴ βλεπόμενα αἰώνια.
47005001 Οἴδαμεν γὰρ ὅτι ἐὰν ἡ ἐπίγειος ἡμῶν οἰκία τοῦ σκήνους καταλυθῇ, οἰκοδομὴν ἐκ ˚Θεοῦ ἔχομεν, οἰκίαν ἀχειροποίητον αἰώνιον ἐν τοῖς οὐρανοῖς.
47005002 Καὶ γὰρ ἐν τούτῳ στενάζομεν, τὸ οἰκητήριον ἡμῶν τὸ ἐξ οὐρανοῦ ἐπενδύσασθαι ἐπιποθοῦντες.
47005003 Εἴ γε καὶ ἐνδυσάμενοι, οὐ γυμνοὶ εὑρεθησόμεθα.
47005004 Καὶ γὰρ οἱ ὄντες ἐν τῷ σκήνει, στενάζομεν, βαρούμενοι ἐφʼ ᾧ οὐ θέλομεν ἐκδύσασθαι, ἀλλʼ ἐπενδύσασθαι, ἵνα καταποθῇ τὸ θνητὸν ὑπὸ τῆς ζωῆς.
47005005 Ὁ δὲ κατεργασάμενος ἡμᾶς εἰς αὐτὸ τοῦτο ˚Θεός, ὁ δοὺς ἡμῖν τὸν ἀρραβῶνα τοῦ ˚Πνεύματος.
47005006 Θαρροῦντες οὖν πάντοτε, καὶ εἰδότες ὅτι ἐνδημοῦντες ἐν τῷ σώματι, ἐκδημοῦμεν ἀπὸ τοῦ ˚Κυρίου,
47005007 διὰ πίστεως γὰρ περιπατοῦμεν, οὐ διὰ εἴδους.
47005008 Θαρροῦμεν δὲ καὶ εὐδοκοῦμεν μᾶλλον ἐκδημῆσαι ἐκ τοῦ σώματος καὶ ἐνδημῆσαι πρὸς τὸν ˚Κύριον.
47005009 Διὸ καὶ φιλοτιμούμεθα, εἴτε ἐνδημοῦντες εἴτε ἐκδημοῦντες, εὐάρεστοι αὐτῷ εἶναι.
47005010 Τοὺς γὰρ πάντας ἡμᾶς φανερωθῆναι δεῖ, ἔμπροσθεν τοῦ βήματος τοῦ ˚Χριστοῦ, ἵνα κομίσηται ἕκαστος τὰ διὰ τοῦ σώματος, πρὸς ἃ ἔπραξεν, εἴτε ἀγαθὸν εἴτε κακόν.
47005011 Εἰδότες οὖν τὸν φόβον τοῦ ˚Κυρίου, ἀνθρώπους πείθομεν, ˚Θεῷ δὲ πεφανερώμεθα· ἐλπίζω δὲ καὶ ἐν ταῖς συνειδήσεσιν ὑμῶν πεφανερῶσθαι.
47005012 Οὐ πάλιν ἑαυτοὺς συνιστάνομεν ὑμῖν, ἀλλὰ ἀφορμὴν διδόντες ὑμῖν καυχήματος ὑπὲρ ἡμῶν, ἵνα ἔχητε πρὸς τοὺς ἐν προσώπῳ καυχωμένους, καὶ μὴ ἐν καρδίᾳ.
47005013 Εἴτε γὰρ ἐξέστημεν, ˚Θεῷ· εἴτε σωφρονοῦμεν, ὑμῖν.
47005014 Ἡ γὰρ ἀγάπη τοῦ ˚Χριστοῦ συνέχει ἡμᾶς, κρίναντας τοῦτο, ὅτι εἷς ὑπὲρ πάντων ἀπέθανεν, ἄρα οἱ πάντες ἀπέθανον.
47005015 Καὶ ὑπὲρ πάντων ἀπέθανεν, ἵνα οἱ ζῶντες, μηκέτι ἑαυτοῖς ζῶσιν, ἀλλὰ τῷ ὑπὲρ αὐτῶν ἀποθανόντι καὶ ἐγερθέντι.
47005016 Ὥστε ἡμεῖς ἀπὸ τοῦ νῦν οὐδένα οἴδαμεν κατὰ σάρκα. Εἰ καὶ ἐγνώκαμεν κατὰ σάρκα ˚Χριστόν, ἀλλὰ νῦν οὐκέτι γινώσκομεν.
47005017 Ὥστε εἴ τις ἐν ˚Χριστῷ, καινὴ κτίσις· τὰ ἀρχαῖα παρῆλθεν, ἰδοὺ, γέγονεν καινά.
47005018 Τὰ δὲ πάντα ἐκ τοῦ ˚Θεοῦ, τοῦ καταλλάξαντος ἡμᾶς ἑαυτῷ διὰ ˚Χριστοῦ, καὶ δόντος ἡμῖν τὴν διακονίαν τῆς καταλλαγῆς,
47005019 ὡς ὅτι ˚Θεὸς ἦν ἐν ˚Χριστῷ κόσμον καταλλάσσων ἑαυτῷ, μὴ λογιζόμενος αὐτοῖς τὰ παραπτώματα αὐτῶν, καὶ θέμενος ἐν ἡμῖν τὸν λόγον τῆς καταλλαγῆς.
47005020 Ὑπὲρ ˚Χριστοῦ οὖν πρεσβεύομεν, ὡς τοῦ ˚Θεοῦ παρακαλοῦντος διʼ ἡμῶν· δεόμεθα ὑπὲρ ˚Χριστοῦ, καταλλάγητε τῷ ˚Θεῷ.
47005021 Τὸν μὴ γνόντα ἁμαρτίαν, ὑπὲρ ἡμῶν ἁμαρτίαν ἐποίησεν, ἵνα ἡμεῖς γενώμεθα δικαιοσύνη ˚Θεοῦ ἐν αὐτῷ.
47006001 Συνεργοῦντες δὲ καὶ, παρακαλοῦμεν μὴ εἰς κενὸν τὴν χάριν τοῦ ˚Θεοῦ δέξασθαι ὑμᾶς.
47006002 Λέγει γάρ, “Καιρῷ δεκτῷ ἐπήκουσά σου, καὶ ἐν ἡμέρᾳ σωτηρίας ἐβοήθησά σοι.” Ἰδοὺ, νῦν “καιρὸς εὐπρόσδεκτος”, ἰδοὺ, νῦν “ἡμέρα σωτηρίας”.
47006003 Μηδεμίαν ἐν μηδενὶ διδόντες προσκοπήν, ἵνα μὴ μωμηθῇ ἡ διακονία.
47006004 Ἀλλʼ ἐν παντὶ συνιστάντες ἑαυτοὺς ὡς ˚Θεοῦ διάκονοι: ἐν ὑπομονῇ πολλῇ, ἐν θλίψεσιν, ἐν ἀνάγκαις, ἐν στενοχωρίαις,
47006005 ἐν πληγαῖς, ἐν φυλακαῖς, ἐν ἀκαταστασίαις, ἐν κόποις, ἐν ἀγρυπνίαις, ἐν νηστείαις,
47006006 ἐν ἁγνότητι, ἐν γνώσει, ἐν μακροθυμίᾳ, ἐν χρηστότητι, ἐν ˚Πνεύματι Ἁγίῳ, ἐν ἀγάπῃ ἀνυποκρίτῳ,
47006007 ἐν λόγῳ ἀληθείας, ἐν δυνάμει ˚Θεοῦ, διὰ τῶν ὅπλων τῆς δικαιοσύνης τῶν δεξιῶν καὶ ἀριστερῶν,
47006008 διὰ δόξης καὶ ἀτιμίας, διὰ δυσφημίας καὶ εὐφημίας· ὡς πλάνοι καὶ ἀληθεῖς,
47006009 ὡς ἀγνοούμενοι καὶ ἐπιγινωσκόμενοι, ὡς ἀποθνῄσκοντες καὶ ἰδοὺ, ζῶμεν, ὡς παιδευόμενοι καὶ μὴ θανατούμενοι,
47006010 ὡς λυπούμενοι ἀεὶ δὲ χαίροντες, ὡς πτωχοὶ πολλοὺς δὲ πλουτίζοντες, ὡς μηδὲν ἔχοντες καὶ πάντα κατέχοντες.
47006011 Τὸ στόμα ἡμῶν ἀνέῳγεν πρὸς ὑμᾶς, Κορίνθιοι, ἡ καρδία ἡμῶν πεπλάτυνται.
47006012 Οὐ στενοχωρεῖσθε ἐν ἡμῖν, στενοχωρεῖσθε δὲ ἐν τοῖς σπλάγχνοις ὑμῶν.
47006013 Τὴν δὲ αὐτὴν ἀντιμισθίαν ( ὡς τέκνοις λέγω ) πλατύνθητε καὶ ὑμεῖς.
47006014 Μὴ γίνεσθε ἑτεροζυγοῦντες ἀπίστοις· τίς γὰρ μετοχὴ δικαιοσύνῃ καὶ ἀνομίᾳ, ἢ τίς κοινωνία φωτὶ πρὸς σκότος;
47006015 Τίς δὲ συμφώνησις ˚Χριστοῦ πρὸς Βελιάρ, ἢ τίς μερὶς πιστῷ μετὰ ἀπίστου;
47006016 Τίς δὲ συγκατάθεσις ναῷ ˚Θεοῦ μετὰ εἰδώλων; ἡμεῖς γὰρ ναὸς ˚Θεοῦ ἐσμεν ζῶντος, καθὼς εἶπεν ὁ ˚Θεὸς, ὅτι “Ἐνοικήσω ἐν αὐτοῖς”, καὶ “ἐμπεριπατήσω, καὶ ἔσομαι αὐτῶν ˚Θεός, καὶ αὐτοὶ ἔσονταί μου λαός.”
47006017 “Διὸ ἐξέλθατε ἐκ μέσου αὐτῶν καὶ ἀφορίσθητε”, λέγει ˚Κύριος, “καὶ ἀκαθάρτου μὴ ἅπτεσθε”· “Κἀγὼ εἰσδέξομαι ὑμᾶς”.
47006018 “Καὶ ἔσομαι ὑμῖν εἰς πατέρα, καὶ ὑμεῖς ἔσεσθέ μοι εἰς υἱοὺς καὶ θυγατέρας”, λέγει ˚Κύριος Παντοκράτωρ.
47007001 Ταύτας οὖν ἔχοντες τὰς ἐπαγγελίας, ἀγαπητοί, καθαρίσωμεν ἑαυτοὺς ἀπὸ παντὸς μολυσμοῦ σαρκὸς καὶ πνεύματος, ἐπιτελοῦντες ἁγιωσύνην ἐν φόβῳ ˚Θεοῦ.
47007002 Χωρήσατε ἡμᾶς· οὐδένα ἠδικήσαμεν, οὐδένα ἐφθείραμεν, οὐδένα ἐπλεονεκτήσαμεν.
47007003 Πρὸς κατάκρισιν οὐ λέγω, προείρηκα γὰρ ὅτι ἐν ταῖς καρδίαις ἡμῶν ἐστε, εἰς τὸ συναποθανεῖν καὶ συζῆν.
47007004 Πολλή μοι παρρησία πρὸς ὑμᾶς, πολλή μοι καύχησις ὑπὲρ ὑμῶν· πεπλήρωμαι τῇ παρακλήσει, ὑπερπερισσεύομαι τῇ χαρᾷ ἐπὶ πάσῃ τῇ θλίψει ἡμῶν.
47007005 Καὶ γὰρ ἐλθόντων ἡμῶν εἰς Μακεδονίαν, οὐδεμίαν ἔσχηκεν ἄνεσιν ἡ σὰρξ ἡμῶν, ἀλλʼ ἐν παντὶ θλιβόμενοι– ἔξωθεν μάχαι, ἔσωθεν φόβοι.
47007006 Ἀλλʼ ὁ παρακαλῶν τοὺς ταπεινοὺς, παρεκάλεσεν ἡμᾶς ὁ ˚Θεὸς ἐν τῇ παρουσίᾳ Τίτου·
47007007 οὐ μόνον δὲ ἐν τῇ παρουσίᾳ αὐτοῦ, ἀλλὰ καὶ ἐν τῇ παρακλήσει ᾗ παρεκλήθη ἐφʼ ὑμῖν, ἀναγγέλλων ἡμῖν τὴν ὑμῶν ἐπιπόθησιν, τὸν ὑμῶν ὀδυρμόν, τὸν ὑμῶν ζῆλον ὑπὲρ ἐμοῦ, ὥστε με μᾶλλον χαρῆναι.
47007008 Ὅτι εἰ καὶ ἐλύπησα ὑμᾶς ἐν τῇ ἐπιστολῇ, οὐ μεταμέλομαι. Εἰ καὶ μετεμελόμην ( βλέπω ὅτι ἡ ἐπιστολὴ ἐκείνη, εἰ καὶ πρὸς ὥραν ἐλύπησεν ὑμᾶς )
47007009 νῦν χαίρω οὐχ ὅτι ἐλυπήθητε, ἀλλʼ ὅτι ἐλυπήθητε εἰς μετάνοιαν, ἐλυπήθητε γὰρ κατὰ ˚Θεόν, ἵνα ἐν μηδενὶ ζημιωθῆτε ἐξ ἡμῶν.
47007010 Ἡ γὰρ κατὰ ˚Θεὸν λύπη, μετάνοιαν εἰς σωτηρίαν ἀμεταμέλητον ἐργάζεται· ἡ δὲ τοῦ κόσμου λύπη, θάνατον κατεργάζεται.
47007011 Ἰδοὺ γὰρ αὐτὸ τοῦτο τὸ κατὰ ˚Θεὸν λυπηθῆναι, πόσην κατειργάσατο ὑμῖν σπουδήν: ἀλλὰ ἀπολογίαν, ἀλλὰ ἀγανάκτησιν, ἀλλὰ φόβον, ἀλλὰ ἐπιπόθησιν, ἀλλὰ ζῆλον, ἀλλὰ ἐκδίκησιν! Ἐν παντὶ συνεστήσατε ἑαυτοὺς ἁγνοὺς εἶναι τῷ πράγματι.
47007012 Ἄρα εἰ καὶ ἔγραψα ὑμῖν, οὐχ ἕνεκεν τοῦ ἀδικήσαντος, οὐδὲ ἕνεκεν τοῦ ἀδικηθέντος, ἀλλʼ ἕνεκεν τοῦ φανερωθῆναι τὴν σπουδὴν ὑμῶν, τὴν ὑπὲρ ἡμῶν πρὸς ὑμᾶς ἐνώπιον τοῦ ˚Θεοῦ.
47007013 Διὰ τοῦτο παρακεκλήμεθα. Ἐπὶ δὲ τῇ παρακλήσει ἡμῶν, περισσοτέρως μᾶλλον ἐχάρημεν ἐπὶ τῇ χαρᾷ Τίτου, ὅτι ἀναπέπαυται τὸ πνεῦμα αὐτοῦ ἀπὸ πάντων ὑμῶν.
47007014 Ὅτι εἴ τι αὐτῷ ὑπὲρ ὑμῶν κεκαύχημαι, οὐ κατῃσχύνθην, ἀλλʼ ὡς πάντα ἐν ἀληθείᾳ ἐλαλήσαμεν ὑμῖν, οὕτως καὶ ἡ καύχησις ἡμῶν ἐπὶ Τίτου ἀλήθεια ἐγενήθη.
47007015 Καὶ τὰ σπλάγχνα αὐτοῦ περισσοτέρως εἰς ὑμᾶς ἐστιν, ἀναμιμνῃσκομένου τὴν πάντων ὑμῶν ὑπακοήν, ὡς μετὰ φόβου καὶ τρόμου ἐδέξασθε αὐτόν.
47007016 Χαίρω ὅτι ἐν παντὶ, θαρρῶ ἐν ὑμῖν.
47008001 Γνωρίζομεν δὲ ὑμῖν, ἀδελφοί, τὴν χάριν τοῦ ˚Θεοῦ τὴν δεδομένην ἐν ταῖς ἐκκλησίαις τῆς Μακεδονίας,
47008002 ὅτι ἐν πολλῇ δοκιμῇ θλίψεως, ἡ περισσεία τῆς χαρᾶς αὐτῶν καὶ ἡ κατὰ βάθους πτωχεία αὐτῶν, ἐπερίσσευσεν εἰς τὸ πλοῦτος τῆς ἁπλότητος αὐτῶν.
47008003 Ὅτι κατὰ δύναμιν μαρτυρῶ, καὶ παρὰ δύναμιν, αὐθαίρετοι
47008004 μετὰ πολλῆς παρακλήσεως δεόμενοι ἡμῶν, τὴν χάριν καὶ τὴν κοινωνίαν τῆς διακονίας τῆς εἰς τοὺς ἁγίους.
47008005 Καὶ οὐ καθὼς ἠλπίσαμεν, ἀλλʼ ἑαυτοὺς ἔδωκαν, πρῶτον τῷ ˚Κυρίῳ, καὶ ἡμῖν, διὰ θελήματος ˚Θεοῦ.
47008006 Εἰς τὸ παρακαλέσαι ἡμᾶς Τίτον, ἵνα καθὼς προενήρξατο, οὕτως καὶ ἐπιτελέσῃ εἰς ὑμᾶς καὶ τὴν χάριν ταύτην.
47008007 Ἀλλʼ ὥσπερ ἐν παντὶ περισσεύετε, πίστει, καὶ λόγῳ, καὶ γνώσει, καὶ πάσῃ σπουδῇ, καὶ τῇ ἐξ ἡμῶν ἐν ὑμῖν ἀγάπῃ, ἵνα καὶ ἐν ταύτῃ τῇ χάριτι περισσεύητε.
47008008 Οὐ κατʼ ἐπιταγὴν λέγω, ἀλλὰ διὰ τῆς ἑτέρων σπουδῆς, καὶ τὸ τῆς ὑμετέρας ἀγάπης γνήσιον δοκιμάζων.
47008009 Γινώσκετε γὰρ τὴν χάριν τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ ˚Χριστοῦ, ὅτι διʼ ὑμᾶς ἐπτώχευσεν, πλούσιος ὤν, ἵνα ὑμεῖς τῇ ἐκείνου πτωχείᾳ πλουτήσητε.
47008010 Καὶ γνώμην ἐν τούτῳ δίδωμι, τοῦτο γὰρ ὑμῖν συμφέρει, οἵτινες οὐ μόνον τὸ ποιῆσαι, ἀλλὰ καὶ τὸ θέλειν, προενήρξασθε ἀπὸ πέρυσι.
47008011 Νυνὶ δὲ καὶ τὸ ποιῆσαι ἐπιτελέσατε, ὅπως καθάπερ ἡ προθυμία τοῦ θέλειν, οὕτως καὶ τὸ ἐπιτελέσαι ἐκ τοῦ ἔχειν.
47008012 Εἰ γὰρ ἡ προθυμία πρόκειται, καθὸ ἐὰν ἔχῃ εὐπρόσδεκτος, οὐ καθὸ οὐκ ἔχει.
47008013 Οὐ γὰρ ἵνα ἄλλοις ἄνεσις, ὑμῖν θλῖψις, ἀλλʼ ἐξ ἰσότητος.
47008014 Ἐν τῷ νῦν καιρῷ, τὸ ὑμῶν περίσσευμα εἰς τὸ ἐκείνων ὑστέρημα, ἵνα καὶ τὸ ἐκείνων περίσσευμα γένηται εἰς τὸ ὑμῶν ὑστέρημα, ὅπως γένηται ἰσότης.
47008015 Καθὼς γέγραπται, “Ὁ τὸ πολὺ, οὐκ ἐπλεόνασεν, καὶ ὁ τὸ ὀλίγον, οὐκ ἠλαττόνησεν.”
47008016 Χάρις δὲ τῷ ˚Θεῷ, τῷ διδόντι τὴν αὐτὴν σπουδὴν ὑπὲρ ὑμῶν ἐν τῇ καρδίᾳ Τίτου.
47008017 Ὅτι τὴν μὲν παράκλησιν ἐδέξατο, σπουδαιότερος δὲ ὑπάρχων αὐθαίρετος, ἐξῆλθεν πρὸς ὑμᾶς.
47008018 Συνεπέμψαμεν δὲ μετʼ αὐτοῦ τὸν ἀδελφὸν, οὗ ὁ ἔπαινος ἐν τῷ εὐαγγελίῳ διὰ πασῶν τῶν ἐκκλησιῶν·
47008019 οὐ μόνον δὲ, ἀλλὰ καὶ χειροτονηθεὶς ὑπὸ τῶν ἐκκλησιῶν συνέκδημος ἡμῶν, ἐν τῇ χάριτι ταύτῃ τῇ διακονουμένῃ ὑφʼ ἡμῶν, πρὸς τὴν τοῦ ˚Κυρίου δόξαν, καὶ προθυμίαν ἡμῶν·
47008020 στελλόμενοι τοῦτο, μή τις ἡμᾶς μωμήσηται, ἐν τῇ ἁδρότητι ταύτῃ τῇ διακονουμένῃ ὑφʼ ἡμῶν,
47008021 προνοοῦμεν γὰρ καλὰ, οὐ μόνον ἐνώπιον ˚Κυρίου, ἀλλὰ καὶ ἐνώπιον ἀνθρώπων.
47008022 Συνεπέμψαμεν δὲ αὐτοῖς τὸν ἀδελφὸν ἡμῶν, ὃν ἐδοκιμάσαμεν ἐν πολλοῖς, πολλάκις σπουδαῖον ὄντα, νυνὶ δὲ πολὺ σπουδαιότερον πεποιθήσει πολλῇ τῇ εἰς ὑμᾶς.
47008023 Εἴτε ὑπὲρ Τίτου, κοινωνὸς ἐμὸς καὶ εἰς ὑμᾶς συνεργός, εἴτε ἀδελφοὶ ἡμῶν, ἀπόστολοι ἐκκλησιῶν δόξα ˚Χριστοῦ.
47008024 Τὴν οὖν ἔνδειξιν τῆς ἀγάπης ὑμῶν, καὶ ἡμῶν καυχήσεως ὑπὲρ ὑμῶν, εἰς αὐτοὺς ἐνδεικνύμενοι εἰς πρόσωπον τῶν ἐκκλησιῶν.
47009001 Περὶ μὲν γὰρ τῆς διακονίας τῆς εἰς τοὺς ἁγίους, περισσόν μοί ἐστιν τὸ γράφειν ὑμῖν.
47009002 Οἶδα γὰρ τὴν προθυμίαν ὑμῶν, ἣν ὑπὲρ ὑμῶν καυχῶμαι Μακεδόσιν, ὅτι Ἀχαΐα παρεσκεύασται ἀπὸ πέρυσι, καὶ τὸ ὑμῶν ζῆλος ἠρέθισεν τοὺς πλείονας.
47009003 Ἔπεμψα δὲ τοὺς ἀδελφούς, ἵνα μὴ τὸ καύχημα ἡμῶν, τὸ ὑπὲρ ὑμῶν, κενωθῇ ἐν τῷ μέρει τούτῳ, ἵνα καθὼς ἔλεγον, παρεσκευασμένοι ἦτε.
47009004 Μή πως ἐὰν ἔλθωσιν σὺν ἐμοὶ Μακεδόνες, καὶ εὕρωσιν ὑμᾶς ἀπαρασκευάστους, καταισχυνθῶμεν ἡμεῖς, ἵνα μὴ λέγωμεν ὑμεῖς, ἐν τῇ ὑποστάσει ταύτῃ.
47009005 Ἀναγκαῖον οὖν ἡγησάμην παρακαλέσαι τοὺς ἀδελφοὺς, ἵνα προέλθωσιν εἰς ὑμᾶς καὶ προκαταρτίσωσιν τὴν προεπηγγελμένην εὐλογίαν ὑμῶν, ταύτην ἑτοίμην εἶναι οὕτως ὡς εὐλογίαν, καὶ μὴ ὡς πλεονεξίαν.
47009006 Τοῦτο δέ: ὁ σπείρων φειδομένως, φειδομένως καὶ θερίσει, καὶ ὁ σπείρων ἐπʼ εὐλογίαις, ἐπʼ εὐλογίαις καὶ θερίσει.
47009007 Ἕκαστος καθὼς προῄρηται τῇ καρδίᾳ, μὴ ἐκ λύπης ἢ ἐξ ἀνάγκης, ἱλαρὸν γὰρ δότην ἀγαπᾷ ὁ ˚Θεός.
47009008 Δυνατεῖ δὲ ὁ ˚Θεὸς πᾶσαν χάριν περισσεῦσαι εἰς ὑμᾶς, ἵνα ἐν παντὶ πάντοτε πᾶσαν αὐτάρκειαν ἔχοντες, περισσεύητε εἰς πᾶν ἔργον ἀγαθόν.
47009009 Καθὼς γέγραπται, “Ἐσκόρπισεν, ἔδωκεν τοῖς πένησιν, ἡ δικαιοσύνη αὐτοῦ μένει εἰς τὸν αἰῶνα.”
47009010 Ὁ δὲ ἐπιχορηγῶν σπόρον τῷ σπείροντι, καὶ ἄρτον εἰς βρῶσιν, χορηγήσει καὶ πληθυνεῖ τὸν σπόρον ὑμῶν, καὶ αὐξήσει τὰ γενήματα τῆς δικαιοσύνης ὑμῶν·
47009011 ἐν παντὶ πλουτιζόμενοι εἰς πᾶσαν ἁπλότητα, ἥτις κατεργάζεται διʼ ἡμῶν, εὐχαριστίαν τῷ ˚Θεῷ.
47009012 Ὅτι ἡ διακονία τῆς λειτουργίας ταύτης, οὐ μόνον ἐστὶν προσαναπληροῦσα τὰ ὑστερήματα τῶν ἁγίων, ἀλλὰ καὶ περισσεύουσα διὰ πολλῶν εὐχαριστιῶν τῷ ˚Θεῷ·
47009013 διὰ τῆς δοκιμῆς τῆς διακονίας ταύτης, δοξάζοντες τὸν ˚Θεὸν ἐπὶ τῇ ὑποταγῇ τῆς ὁμολογίας ὑμῶν εἰς τὸ εὐαγγέλιον τοῦ ˚Χριστοῦ, καὶ ἁπλότητι τῆς κοινωνίας εἰς αὐτοὺς καὶ εἰς πάντας,
47009014 καὶ αὐτῶν δεήσει ὑπὲρ ὑμῶν, ἐπιποθούντων ὑμᾶς, διὰ τὴν ὑπερβάλλουσαν χάριν τοῦ ˚Θεοῦ ἐφʼ ὑμῖν.
47009015 Χάρις τῷ ˚Θεῷ ἐπὶ τῇ ἀνεκδιηγήτῳ αὐτοῦ δωρεᾷ!
47010001 Αὐτὸς δὲ ἐγὼ, Παῦλος, παρακαλῶ ὑμᾶς διὰ τῆς πραΰτητος καὶ ἐπιεικείας τοῦ ˚Χριστοῦ, ὃς κατὰ πρόσωπον μὲν ταπεινὸς ἐν ὑμῖν, ἀπὼν δὲ θαρρῶ εἰς ὑμᾶς.
47010002 Δέομαι δὲ τὸ μὴ, παρὼν θαρρῆσαι τῇ πεποιθήσει ᾗ λογίζομαι, τολμῆσαι ἐπί τινας τοὺς λογιζομένους ἡμᾶς, ὡς κατὰ σάρκα περιπατοῦντας.
47010003 Ἐν σαρκὶ γὰρ περιπατοῦντες, οὐ κατὰ σάρκα στρατευόμεθα.
47010004 Τὰ γὰρ ὅπλα τῆς στρατείας ἡμῶν οὐ σαρκικὰ, ἀλλὰ δυνατὰ τῷ ˚Θεῷ πρὸς καθαίρεσιν ὀχυρωμάτων, λογισμοὺς καθαιροῦντες,
47010005 καὶ πᾶν ὕψωμα ἐπαιρόμενον κατὰ τῆς γνώσεως τοῦ ˚Θεοῦ, καὶ αἰχμαλωτίζοντες πᾶν νόημα εἰς τὴν ὑπακοὴν τοῦ ˚Χριστοῦ,
47010006 καὶ ἐν ἑτοίμῳ ἔχοντες ἐκδικῆσαι πᾶσαν παρακοήν, ὅταν πληρωθῇ ὑμῶν ἡ ὑπακοή.
47010007 Τὰ κατὰ πρόσωπον βλέπετε. Εἴ τις πέποιθεν ἑαυτῷ ˚Χριστοῦ εἶναι, τοῦτο λογιζέσθω πάλιν ἐφʼ ἑαυτοῦ, ὅτι καθὼς αὐτὸς ˚Χριστοῦ, οὕτως καὶ ἡμεῖς.
47010008 Ἐάν τε γὰρ περισσότερόν τι καυχήσωμαι περὶ τῆς ἐξουσίας ἡμῶν, ἧς ἔδωκεν ὁ ˚Κύριος εἰς οἰκοδομὴν καὶ οὐκ εἰς καθαίρεσιν ὑμῶν, οὐκ αἰσχυνθήσομαι,
47010009 ἵνα μὴ δόξω ὡς ἂν ἐκφοβεῖν ὑμᾶς διὰ τῶν ἐπιστολῶν.
47010010 Ὅτι, “Αἱ ἐπιστολαὶ μέν”, φησίν, “βαρεῖαι καὶ ἰσχυραί, ἡ δὲ παρουσία τοῦ σώματος ἀσθενὴς, καὶ ὁ λόγος ἐξουθενημένος.”
47010011 Τοῦτο λογιζέσθω ὁ τοιοῦτος, ὅτι οἷοί ἐσμεν τῷ λόγῳ διʼ ἐπιστολῶν ἀπόντες, τοιοῦτοι καὶ παρόντες τῷ ἔργῳ.
47010012 Οὐ γὰρ τολμῶμεν ἐγκρῖναι ἢ συγκρῖναι ἑαυτούς, τισιν τῶν ἑαυτοὺς συνιστανόντων· ἀλλὰ αὐτοὶ ἐν ἑαυτοῖς, ἑαυτοὺς μετροῦντες καὶ συγκρίνοντες ἑαυτοὺς ἑαυτοῖς, οὐ συνιᾶσιν.
47010013 Ἡμεῖς δὲ οὐκ εἰς τὰ ἄμετρα καυχησόμεθα, ἀλλὰ κατὰ τὸ μέτρον τοῦ κανόνος, οὗ ἐμέρισεν ἡμῖν ὁ ˚Θεὸς μέτρου, ἐφικέσθαι ἄχρι καὶ ὑμῶν.
47010014 Οὐ γὰρ ὡς μὴ ἐφικνούμενοι εἰς ὑμᾶς, ὑπερεκτείνομεν ἑαυτούς, ἄχρι γὰρ καὶ ὑμῶν ἐφθάσαμεν ἐν τῷ εὐαγγελίῳ τοῦ ˚Χριστοῦ·
47010015 οὐκ εἰς τὰ ἄμετρα καυχώμενοι ἐν ἀλλοτρίοις κόποις, ἐλπίδα δὲ ἔχοντες, αὐξανομένης τῆς πίστεως ὑμῶν, ἐν ὑμῖν μεγαλυνθῆναι κατὰ τὸν κανόνα ἡμῶν εἰς περισσείαν,
47010016 εἰς τὰ ὑπερέκεινα ὑμῶν εὐαγγελίσασθαι, οὐκ ἐν ἀλλοτρίῳ κανόνι εἰς τὰ ἕτοιμα καυχήσασθαι.
47010017 Ὁ δὲ καυχώμενος, ἐν ˚Κυρίῳ καυχάσθω.
47010018 Οὐ γὰρ ὁ ἑαυτὸν συνιστάνων, ἐκεῖνός ἐστιν δόκιμος, ἀλλὰ ὃν ὁ ˚Κύριος συνίστησιν.
47011001 Ὄφελον ἀνείχεσθέ μου μικρόν τι ἀφροσύνης, ἀλλὰ καὶ ἀνέχεσθέ μου.
47011002 Ζηλῶ γὰρ ὑμᾶς ˚Θεοῦ ζήλῳ, ἡρμοσάμην γὰρ ὑμᾶς ἑνὶ ἀνδρὶ, παρθένον ἁγνὴν παραστῆσαι τῷ ˚Χριστῷ.
47011003 Φοβοῦμαι δὲ, μή πως ὡς ὁ ὄφις ἐξηπάτησεν Εὕαν ἐν τῇ πανουργίᾳ αὐτοῦ, φθαρῇ τὰ νοήματα ὑμῶν ἀπὸ τῆς ἁπλότητος καὶ τῆς ἁγνότητος τῆς εἰς τὸν ˚Χριστόν.
47011004 Εἰ μὲν γὰρ ὁ ἐρχόμενος ἄλλον ˚Ἰησοῦν κηρύσσει ὃν οὐκ ἐκηρύξαμεν, ἢ πνεῦμα ἕτερον λαμβάνετε ὃ οὐκ ἐλάβετε, ἢ εὐαγγέλιον ἕτερον ὃ οὐκ ἐδέξασθε, καλῶς ἀνέχεσθε.
47011005 Λογίζομαι γὰρ μηδὲν ὑστερηκέναι τῶν ὑπερλίαν ἀποστόλων.
47011006 Εἰ δὲ καὶ ἰδιώτης τῷ λόγῳ, ἀλλʼ οὐ τῇ γνώσει, ἀλλʼ ἐν παντὶ φανερώσαντες ἐν πᾶσιν εἰς ὑμᾶς.
47011007 Ἢ ἁμαρτίαν ἐποίησα ἐμαυτὸν ταπεινῶν, ἵνα ὑμεῖς ὑψωθῆτε, ὅτι δωρεὰν τὸ τοῦ ˚Θεοῦ εὐαγγέλιον εὐηγγελισάμην ὑμῖν;
47011008 Ἄλλας ἐκκλησίας ἐσύλησα, λαβὼν ὀψώνιον πρὸς τὴν ὑμῶν διακονίαν,
47011009 καὶ παρὼν πρὸς ὑμᾶς καὶ ὑστερηθεὶς, οὐ κατενάρκησα οὐθενός· τὸ γὰρ ὑστέρημά μου προσανεπλήρωσαν οἱ ἀδελφοὶ ἐλθόντες ἀπὸ Μακεδονίας, καὶ ἐν παντὶ ἀβαρῆ ἐμαυτὸν ὑμῖν ἐτήρησα καὶ τηρήσω.
47011010 Ἔστιν ἀλήθεια ˚Χριστοῦ ἐν ἐμοὶ, ὅτι ἡ καύχησις αὕτη οὐ φραγήσεται εἰς ἐμὲ ἐν τοῖς κλίμασι τῆς Ἀχαΐας.
47011011 Διὰ τί; Ὅτι οὐκ ἀγαπῶ ὑμᾶς; Ὁ ˚Θεὸς οἶδεν!
47011012 Ὃ δὲ ποιῶ, καὶ ποιήσω, ἵνα ἐκκόψω τὴν ἀφορμὴν τῶν θελόντων ἀφορμήν, ἵνα ἐν ᾧ καυχῶνται, εὑρεθῶσιν καθὼς καὶ ἡμεῖς.
47011013 Οἱ γὰρ τοιοῦτοι ψευδαπόστολοι, ἐργάται δόλιοι, μετασχηματιζόμενοι εἰς ἀποστόλους ˚Χριστοῦ.
47011014 Καὶ οὐ θαῦμα, αὐτὸς γὰρ ὁ Σατανᾶς μετασχηματίζεται εἰς ἄγγελον φωτός.
47011015 Οὐ μέγα οὖν, εἰ καὶ οἱ διάκονοι αὐτοῦ μετασχηματίζονται ὡς διάκονοι δικαιοσύνης, ὧν τὸ τέλος ἔσται κατὰ τὰ ἔργα αὐτῶν.
47011016 Πάλιν λέγω, μή τίς με δόξῃ ἄφρονα εἶναι· εἰ δὲ μή γε κἂν ὡς ἄφρονα δέξασθέ με, ἵνα κἀγὼ μικρόν τι καυχήσωμαι.
47011017 Ὃ λαλῶ, οὐ κατὰ ˚Κύριον λαλῶ, ἀλλʼ ὡς ἐν ἀφροσύνῃ, ἐν ταύτῃ τῇ ὑποστάσει τῆς καυχήσεως.
47011018 Ἐπεὶ πολλοὶ καυχῶνται κατὰ σάρκα, κἀγὼ καυχήσομαι.
47011019 Ἡδέως γὰρ ἀνέχεσθε τῶν ἀφρόνων, φρόνιμοι ὄντες!
47011020 Ἀνέχεσθε γὰρ, εἴ τις ὑμᾶς καταδουλοῖ, εἴ τις κατεσθίει, εἴ τις λαμβάνει, εἴ τις ἐπαίρεται, εἴ τις εἰς πρόσωπον ὑμᾶς δέρει.
47011021 Κατὰ ἀτιμίαν λέγω ὡς ὅτι ἡμεῖς ἠσθενήκαμεν! Ἐν ᾧ δʼ ἄν τις τολμᾷ ( ἐν ἀφροσύνῃ λέγω ), τολμῶ κἀγώ.
47011022 Ἑβραῖοί εἰσιν; Κἀγώ. Ἰσραηλῖταί εἰσιν; Κἀγώ. Σπέρμα Ἀβραάμ εἰσιν; Κἀγώ.
47011023 Διάκονοι ˚Χριστοῦ εἰσιν; ( Παραφρονῶν λαλῶ ), ὑπὲρ ἐγώ: ἐν κόποις περισσοτέρως, ἐν φυλακαῖς περισσοτέρως, ἐν πληγαῖς ὑπερβαλλόντως, ἐν θανάτοις πολλάκις.
47011024 Ὑπὸ Ἰουδαίων πεντάκις τεσσεράκοντα παρὰ μίαν ἔλαβον,
47011025 τρὶς ἐραβδίσθην, ἅπαξ ἐλιθάσθην, τρὶς ἐναυάγησα, νυχθήμερον ἐν τῷ βυθῷ πεποίηκα·
47011026 ὁδοιπορίαις πολλάκις, κινδύνοις ποταμῶν, κινδύνοις λῃστῶν, κινδύνοις ἐκ γένους, κινδύνοις ἐξ ἐθνῶν, κινδύνοις ἐν πόλει, κινδύνοις ἐν ἐρημίᾳ, κινδύνοις ἐν θαλάσσῃ, κινδύνοις ἐν ψευδαδέλφοις,
47011027 κόπῳ καὶ μόχθῳ, ἐν ἀγρυπνίαις πολλάκις, ἐν λιμῷ καὶ δίψει, ἐν νηστείαις πολλάκις, ἐν ψύχει καὶ γυμνότητι.
47011028 Χωρὶς τῶν παρεκτὸς, ἡ ἐπίστασίς μοι ἡ καθʼ ἡμέραν, ἡ μέριμνα πασῶν τῶν ἐκκλησιῶν.
47011029 Τίς ἀσθενεῖ, καὶ οὐκ ἀσθενῶ; Τίς σκανδαλίζεται, καὶ οὐκ ἐγὼ πυροῦμαι;
47011030 Εἰ καυχᾶσθαι δεῖ, τὰ τῆς ἀσθενείας μου καυχήσομαι.
47011031 Ὁ ˚Θεὸς καὶ Πατὴρ τοῦ ˚Κυρίου ˚Ἰησοῦ οἶδεν, ὁ ὢν εὐλογητὸς εἰς τοὺς αἰῶνας, ὅτι οὐ ψεύδομαι.
47011032 Ἐν Δαμασκῷ ὁ ἐθνάρχης Ἁρέτα τοῦ βασιλέως ἐφρούρει τὴν πόλιν Δαμασκηνῶν πιάσαι με, θέλων,
47011033 καὶ διὰ θυρίδος ἐν σαργάνῃ, ἐχαλάσθην διὰ τοῦ τείχους καὶ ἐξέφυγον τὰς χεῖρας αὐτοῦ.
47012001 Καυχᾶσθαι δεῖ, οὐ συμφέρον μέν, ἐλεύσομαι δὲ εἰς ὀπτασίας καὶ ἀποκαλύψεις ˚Κυρίου.
47012002 Οἶδα ἄνθρωπον ἐν ˚Χριστῷ πρὸ ἐτῶν δεκατεσσάρων ( εἴτε ἐν σώματι οὐκ οἶδα, εἴτε ἐκτὸς τοῦ σώματος οὐκ οἶδα, ὁ ˚Θεὸς οἶδεν ), ἁρπαγέντα τὸν τοιοῦτον ἕως τρίτου οὐρανοῦ.
47012003 Καὶ οἶδα τὸν τοιοῦτον ἄνθρωπον ( εἴτε ἐν σώματι εἴτε χωρὶς τοῦ σώματος οὐκ οἶδα, ὁ ˚Θεὸς οἶδεν ),
47012004 ὅτι ἡρπάγη εἰς τὸν Παράδεισον, καὶ ἤκουσεν ἄρρητα ῥήματα, ἃ οὐκ ἐξὸν ἀνθρώπῳ λαλῆσαι.
47012005 Ὑπὲρ τοῦ τοιούτου, καυχήσομαι, ὑπὲρ δὲ ἐμαυτοῦ, οὐ καυχήσομαι, εἰ μὴ ἐν ταῖς ἀσθενείαις.
47012006 Ἐὰν γὰρ θελήσω καυχήσασθαι, οὐκ ἔσομαι ἄφρων, ἀλήθειαν γὰρ ἐρῶ· φείδομαι δέ, μή τις εἰς ἐμὲ λογίσηται ὑπὲρ ὃ βλέπει με, ἢ ἀκούει ἐξ ἐμοῦ.
47012007 Καὶ τῇ ὑπερβολῇ τῶν ἀποκαλύψεων, διὸ ἵνα μὴ ὑπεραίρωμαι, ἐδόθη μοι σκόλοψ τῇ σαρκί, ἄγγελος Σατανᾶ, ἵνα με κολαφίζῃ, ἵνα μὴ ὑπεραίρωμαι.
47012008 Ὑπὲρ τούτου τρὶς τὸν ˚Κύριον παρεκάλεσα ἵνα ἀποστῇ ἀπʼ ἐμοῦ.
47012009 Καὶ εἴρηκέν μοι, “Ἀρκεῖ σοι ἡ χάρις μου, ἡ γὰρ δύναμις ἐν ἀσθενείᾳ τελεῖται.” Ἥδιστα οὖν μᾶλλον καυχήσομαι ἐν ταῖς ἀσθενείαις μου, ἵνα ἐπισκηνώσῃ ἐπʼ ἐμὲ ἡ δύναμις τοῦ ˚Χριστοῦ.
47012010 Διὸ εὐδοκῶ ἐν ἀσθενείαις, ἐν ὕβρεσιν, ἐν ἀνάγκαις, ἐν διωγμοῖς, καὶ στενοχωρίαις, ὑπὲρ ˚Χριστοῦ· ὅταν γὰρ ἀσθενῶ, τότε δυνατός εἰμι.
47012011 Γέγονα ἄφρων· ὑμεῖς με ἠναγκάσατε. Ἐγὼ γὰρ ὤφειλον ὑφʼ ὑμῶν συνίστασθαι. Οὐδὲν γὰρ ὑστέρησα τῶν ὑπερλίαν ἀποστόλων, εἰ καὶ οὐδέν εἰμι.
47012012 Τὰ μὲν σημεῖα τοῦ ἀποστόλου κατειργάσθη ἐν ὑμῖν ἐν πάσῃ ὑπομονῇ– σημείοις τε, καὶ τέρασιν, καὶ δυνάμεσιν.
47012013 Τί γάρ ἐστιν ὃ ἡσσώθητε ὑπὲρ τὰς λοιπὰς ἐκκλησίας, εἰ μὴ ὅτι αὐτὸς ἐγὼ οὐ κατενάρκησα ὑμῶν; Χαρίσασθέ μοι τὴν ἀδικίαν ταύτην!
47012014 Ἰδοὺ, τρίτον τοῦτο ἑτοίμως ἔχω ἐλθεῖν πρὸς ὑμᾶς, καὶ οὐ καταναρκήσω· οὐ γὰρ ζητῶ τὰ ὑμῶν, ἀλλὰ ὑμᾶς, οὐ γὰρ ὀφείλει τὰ τέκνα τοῖς γονεῦσιν θησαυρίζειν, ἀλλὰ οἱ γονεῖς τοῖς τέκνοις.
47012015 Ἐγὼ δὲ ἥδιστα δαπανήσω καὶ ἐκδαπανηθήσομαι ὑπὲρ τῶν ψυχῶν ὑμῶν. Εἰ περισσοτέρως ὑμᾶς ἀγαπῶν, ἧσσον ἀγαπῶμαι;
47012016 Ἔστω δέ, ἐγὼ οὐ κατεβάρησα ὑμᾶς· ἀλλὰ ὑπάρχων πανοῦργος δόλῳ, ὑμᾶς ἔλαβον.
47012017 Μή τινα ὧν ἀπέσταλκα πρὸς ὑμᾶς, διʼ αὐτοῦ ἐπλεονέκτησα ὑμᾶς;
47012018 Παρεκάλεσα Τίτον καὶ συναπέστειλα τὸν ἀδελφόν. Μήτι ἐπλεονέκτησεν ὑμᾶς Τίτος; Οὐ τῷ αὐτῷ πνεύματι περιεπατήσαμεν οὐ τοῖς αὐτοῖς ἴχνεσιν;
47012019 Πάλαι δοκεῖτε ὅτι ὑμῖν ἀπολογούμεθα; Κατέναντι ˚Θεοῦ ἐν ˚Χριστῷ λαλοῦμεν. Τὰ δὲ πάντα, ἀγαπητοί, ὑπὲρ τῆς ὑμῶν οἰκοδομῆς.
47012020 Φοβοῦμαι γὰρ, μή πως ἐλθὼν οὐχ οἵους θέλω, εὕρω ὑμᾶς, κἀγὼ εὑρεθῶ ὑμῖν οἷον οὐ θέλετε, μή πως ἔρις, ζῆλος, θυμοί, ἐριθεῖαι, καταλαλιαί, ψιθυρισμοί, φυσιώσεις, ἀκαταστασίαι·
47012021 μὴ πάλιν ἐλθόντος μου, ταπεινώσει με ὁ ˚Θεός μου πρὸς ὑμᾶς, καὶ πενθήσω πολλοὺς τῶν προημαρτηκότων, καὶ μὴ μετανοησάντων ἐπὶ τῇ ἀκαθαρσίᾳ, καὶ πορνείᾳ, καὶ ἀσελγείᾳ, ᾗ ἔπραξαν.
47013001 Τρίτον τοῦτο ἔρχομαι πρὸς ὑμᾶς. “Ἐπὶ στόματος δύο μαρτύρων καὶ τριῶν σταθήσεται πᾶν ῥῆμα.”
47013002 Προείρηκα καὶ προλέγω, ὡς παρὼν τὸ δεύτερον, καὶ ἀπὼν νῦν τοῖς προημαρτηκόσιν, καὶ τοῖς λοιποῖς πᾶσιν, ὅτι ἐὰν ἔλθω εἰς τὸ πάλιν, οὐ φείσομαι.
47013003 Ἐπεὶ δοκιμὴν ζητεῖτε τοῦ ἐν ἐμοὶ λαλοῦντος ˚Χριστοῦ, ὃς εἰς ὑμᾶς οὐκ ἀσθενεῖ, ἀλλὰ δυνατεῖ ἐν ὑμῖν.
47013004 Καὶ γὰρ ἐσταυρώθη ἐξ ἀσθενείας, ἀλλὰ ζῇ ἐκ δυνάμεως ˚Θεοῦ. Καὶ γὰρ ἡμεῖς ἀσθενοῦμεν ἐν αὐτῷ, ἀλλὰ ζήσομεν σὺν αὐτῷ ἐκ δυνάμεως ˚Θεοῦ εἰς ὑμᾶς.
47013005 Ἑαυτοὺς πειράζετε εἰ ἐστὲ ἐν τῇ πίστει, ἑαυτοὺς δοκιμάζετε. Ἢ οὐκ ἐπιγινώσκετε ἑαυτοὺς, ὅτι ˚Χριστὸς ˚Ἰησοῦς ἐν ὑμῖν, εἰ μήτι ἀδόκιμοί ἐστε;
47013006 Ἐλπίζω δὲ ὅτι γνώσεσθε ὅτι ἡμεῖς οὐκ ἐσμὲν ἀδόκιμοι.
47013007 Εὐχόμεθα δὲ πρὸς τὸν ˚Θεὸν μὴ ποιῆσαι ὑμᾶς κακὸν μηδέν, οὐχ ἵνα ἡμεῖς δόκιμοι φανῶμεν, ἀλλʼ ἵνα ὑμεῖς τὸ καλὸν ποιῆτε, ἡμεῖς δὲ ὡς ἀδόκιμοι ὦμεν.
47013008 Οὐ γὰρ δυνάμεθά τι κατὰ τῆς ἀληθείας, ἀλλʼ ὑπὲρ τῆς ἀληθείας.
47013009 Χαίρομεν γὰρ ὅταν ἡμεῖς ἀσθενῶμεν, ὑμεῖς δὲ δυνατοὶ ἦτε. Τοῦτο καὶ εὐχόμεθα, τὴν ὑμῶν κατάρτισιν.
47013010 Διὰ τοῦτο ταῦτα ἀπὼν γράφω, ἵνα παρὼν μὴ ἀποτόμως χρήσωμαι, κατὰ τὴν ἐξουσίαν ἣν ὁ ˚Κύριος ἔδωκέν μοι εἰς οἰκοδομὴν καὶ οὐκ εἰς καθαίρεσιν.
47013011 Λοιπόν ἀδελφοί, χαίρετε, καταρτίζεσθε, παρακαλεῖσθε, τὸ αὐτὸ φρονεῖτε, εἰρηνεύετε, καὶ ὁ ˚Θεὸς τῆς ἀγάπης καὶ εἰρήνης ἔσται μεθʼ ὑμῶν.
47013012 Ἀσπάσασθε ἀλλήλους ἐν ἁγίῳ φιλήματι. Ἀσπάζονται ὑμᾶς οἱ ἅγιοι πάντες.
47013013 Ἡ χάρις τοῦ ˚Κυρίου ˚Ἰησοῦ ˚Χριστοῦ, καὶ ἡ ἀγάπη τοῦ ˚Θεοῦ, καὶ ἡ κοινωνία τοῦ Ἁγίου ˚Πνεύματος, μετὰ πάντων ὑμῶν.
48001001 Παῦλος, ἀπόστολος ( οὐκ ἀπʼ ἀνθρώπων, οὐδὲ διʼ ἀνθρώπου, ἀλλὰ διὰ ˚Ἰησοῦ ˚Χριστοῦ, καὶ ˚Θεοῦ Πατρὸς τοῦ ἐγείραντος αὐτὸν ἐκ νεκρῶν ),
48001002 καὶ οἱ σὺν ἐμοὶ πάντες ἀδελφοί, ταῖς ἐκκλησίαις τῆς Γαλατίας:
48001003 χάρις ὑμῖν καὶ εἰρήνη ἀπὸ ˚Θεοῦ Πατρὸς καὶ ˚Κυρίου ἡμῶν ˚Ἰησοῦ ˚Χριστοῦ,
48001004 τοῦ δόντος ἑαυτὸν περὶ τῶν ἁμαρτιῶν ἡμῶν, ὅπως ἐξέληται ἡμᾶς ἐκ τοῦ αἰῶνος τοῦ ἐνεστῶτος πονηροῦ, κατὰ τὸ θέλημα τοῦ ˚Θεοῦ καὶ Πατρὸς ἡμῶν,
48001005 ᾧ ἡ δόξα εἰς τοὺς αἰῶνας τῶν αἰώνων. Ἀμήν!
48001006 Θαυμάζω ὅτι οὕτως ταχέως, μετατίθεσθε ἀπὸ τοῦ καλέσαντος ὑμᾶς ἐν χάριτι ˚Χριστοῦ, εἰς ἕτερον εὐαγγέλιον,
48001007 ὃ οὐκ ἔστιν ἄλλο, εἰ μή τινές εἰσιν οἱ ταράσσοντες ὑμᾶς, καὶ θέλοντες μεταστρέψαι τὸ εὐαγγέλιον τοῦ ˚Χριστοῦ.
48001008 Ἀλλὰ καὶ ἐὰν ἡμεῖς ἢ ἄγγελος ἐξ οὐρανοῦ ὑμῖν εὐαγγελίζηται παρʼ ὃ εὐηγγελισάμεθα ὑμῖν, ἀνάθεμα ἔστω!
48001009 Ὡς προειρήκαμεν, καὶ ἄρτι πάλιν λέγω, εἴ τις ὑμᾶς εὐαγγελίζεται παρʼ ὃ παρελάβετε, ἀνάθεμα ἔστω!
48001010 Ἄρτι γὰρ ἀνθρώπους πείθω ἢ τὸν ˚Θεόν; Ἢ ζητῶ ἀνθρώποις ἀρέσκειν; Εἰ ἔτι ἀνθρώποις ἤρεσκον, ˚Χριστοῦ δοῦλος οὐκ ἂν ἤμην.
48001011 Γνωρίζω γὰρ ὑμῖν, ἀδελφοί, τὸ εὐαγγέλιον τὸ εὐαγγελισθὲν ὑπʼ ἐμοῦ, ὅτι οὐκ ἔστιν κατὰ ἄνθρωπον.
48001012 Οὐδὲ γὰρ ἐγὼ παρὰ ἀνθρώπου παρέλαβον αὐτό, οὔτε ἐδιδάχθην, ἀλλὰ διʼ ἀποκαλύψεως ˚Ἰησοῦ ˚Χριστοῦ.
48001013 Ἠκούσατε γὰρ τὴν ἐμὴν ἀναστροφήν ποτε ἐν τῷ Ἰουδαϊσμῷ, ὅτι καθʼ ὑπερβολὴν ἐδίωκον τὴν ἐκκλησίαν τοῦ ˚Θεοῦ, καὶ ἐπόρθουν αὐτήν.
48001014 Καὶ προέκοπτον ἐν τῷ Ἰουδαϊσμῷ ὑπὲρ πολλοὺς συνηλικιώτας ἐν τῷ γένει μου, περισσοτέρως ζηλωτὴς ὑπάρχων τῶν πατρικῶν μου παραδόσεων.
48001015 Ὅτε δὲ εὐδόκησεν ὁ, ἀφορίσας με ἐκ κοιλίας μητρός μου, καὶ καλέσας διὰ τῆς χάριτος αὐτοῦ,
48001016 ἀποκαλύψαι τὸν Υἱὸν αὐτοῦ ἐν ἐμοὶ, ἵνα εὐαγγελίζωμαι αὐτὸν ἐν τοῖς ἔθνεσιν, εὐθέως οὐ προσανεθέμην σαρκὶ καὶ αἵματι,
48001017 οὐδὲ ἀνῆλθον εἰς Ἱεροσόλυμα πρὸς τοὺς πρὸ ἐμοῦ ἀποστόλους, ἀλλὰ ἀπῆλθον εἰς Ἀραβίαν, καὶ πάλιν ὑπέστρεψα εἰς Δαμασκόν.
48001018 Ἔπειτα μετὰ ἔτη τρία, ἀνῆλθον εἰς Ἱεροσόλυμα ἱστορῆσαι Κηφᾶν, καὶ ἐπέμεινα πρὸς αὐτὸν ἡμέρας δεκαπέντε.
48001019 Ἕτερον δὲ τῶν ἀποστόλων οὐκ εἶδον, εἰ μὴ Ἰάκωβον, τὸν ἀδελφὸν τοῦ ˚Κυρίου.
48001020 Ἃ δὲ γράφω ὑμῖν, ἰδοὺ, ἐνώπιον τοῦ ˚Θεοῦ, ὅτι οὐ ψεύδομαι.
48001021 Ἔπειτα ἦλθον εἰς τὰ κλίματα τῆς Συρίας καὶ τῆς Κιλικίας.
48001022 Ἤμην δὲ ἀγνοούμενος τῷ προσώπῳ ταῖς ἐκκλησίαις τῆς Ἰουδαίας, ταῖς ἐν ˚Χριστῷ,
48001023 μόνον δὲ ἀκούοντες ἦσαν, ὅτι “Ὁ διώκων ἡμᾶς ποτε, νῦν εὐαγγελίζεται τὴν πίστιν, ἥν ποτε ἐπόρθει.”
48001024 Καὶ ἐδόξαζον ἐν ἐμοὶ τὸν ˚Θεόν.
48002001 Ἔπειτα διὰ δεκατεσσάρων ἐτῶν, πάλιν ἀνέβην εἰς Ἱεροσόλυμα μετὰ Βαρναβᾶ, συμπαραλαβὼν καὶ Τίτον·
48002002 ἀνέβην δὲ κατὰ ἀποκάλυψιν, καὶ ἀνεθέμην αὐτοῖς τὸ εὐαγγέλιον ὃ κηρύσσω ἐν τοῖς ἔθνεσιν, κατʼ ἰδίαν δὲ τοῖς δοκοῦσιν, μή πως εἰς κενὸν τρέχω ἢ ἔδραμον.
48002003 Ἀλλʼ οὐδὲ Τίτος ὁ σὺν ἐμοί, Ἕλλην ὤν, ἠναγκάσθη περιτμηθῆναι.
48002004 Διὰ δὲ τοὺς παρεισάκτους ψευδαδέλφους, οἵτινες παρεισῆλθον κατασκοπῆσαι τὴν ἐλευθερίαν ἡμῶν, ἣν ἔχομεν ἐν ˚Χριστῷ ˚Ἰησοῦ, ἵνα ἡμᾶς καταδουλώσουσιν·
48002005 οἷς οὐδὲ πρὸς ὥραν εἴξαμεν τῇ ὑποταγῇ, ἵνα ἡ ἀλήθεια τοῦ εὐαγγελίου διαμείνῃ πρὸς ὑμᾶς.
48002006 Ἀπὸ δὲ τῶν δοκούντων εἶναί τι ( ὁποῖοί ποτε ἦσαν, οὐδέν μοι διαφέρει, πρόσωπον ὁ ˚Θεὸς ἀνθρώπου οὐ λαμβάνει ), ἐμοὶ γὰρ οἱ δοκοῦντες οὐδὲν προσανέθεντο.
48002007 Ἀλλὰ τοὐναντίον ἰδόντες ὅτι πεπίστευμαι τὸ εὐαγγέλιον τῆς ἀκροβυστίας, καθὼς Πέτρος τῆς περιτομῆς
48002008 ( ὁ γὰρ ἐνεργήσας Πέτρῳ εἰς ἀποστολὴν τῆς περιτομῆς, ἐνήργησεν καὶ ἐμοὶ εἰς τὰ ἔθνη ),
48002009 καὶ γνόντες τὴν χάριν τὴν δοθεῖσάν μοι, Ἰάκωβος καὶ Κηφᾶς, καὶ Ἰωάννης, οἱ δοκοῦντες στῦλοι εἶναι, δεξιὰς ἔδωκαν ἐμοὶ καὶ Βαρναβᾷ κοινωνίας, ἵνα ἡμεῖς εἰς τὰ ἔθνη, αὐτοὶ δὲ εἰς τὴν περιτομήν·
48002010 μόνον τῶν πτωχῶν ἵνα μνημονεύωμεν, ὃ καὶ ἐσπούδασα αὐτὸ τοῦτο ποιῆσαι.
48002011 Ὅτε δὲ ἦλθεν Κηφᾶς εἰς Ἀντιόχειαν, κατὰ πρόσωπον αὐτῷ ἀντέστην, ὅτι κατεγνωσμένος ἦν.
48002012 Πρὸ τοῦ γὰρ ἐλθεῖν τινας ἀπὸ Ἰακώβου, μετὰ τῶν ἐθνῶν συνήσθιεν· ὅτε δὲ ἦλθον, ὑπέστελλεν καὶ ἀφώριζεν ἑαυτόν, φοβούμενος τοὺς ἐκ περιτομῆς.
48002013 Καὶ συνυπεκρίθησαν αὐτῷ καὶ οἱ λοιποὶ Ἰουδαῖοι, ὥστε καὶ Βαρναβᾶς συναπήχθη αὐτῶν τῇ ὑποκρίσει.
48002014 Ἀλλʼ ὅτε εἶδον ὅτι οὐκ ὀρθοποδοῦσιν πρὸς τὴν ἀλήθειαν τοῦ εὐαγγελίου, εἶπον τῷ Κηφᾷ ἔμπροσθεν πάντων, “Εἰ σὺ Ἰουδαῖος, ὑπάρχων ἐθνικῶς καὶ οὐκ Ἰουδαϊκῶς ζῇς, πῶς τὰ ἔθνη ἀναγκάζεις Ἰουδαΐζειν;”
48002015 Ἡμεῖς φύσει Ἰουδαῖοι καὶ οὐκ ἐξ ἐθνῶν ἁμαρτωλοί,
48002016 εἰδότες δὲ ὅτι οὐ δικαιοῦται ἄνθρωπος ἐξ ἔργων νόμου, ἐὰν μὴ διὰ πίστεως ˚Ἰησοῦ ˚Χριστοῦ, καὶ ἡμεῖς εἰς ˚Χριστὸν ˚Ἰησοῦν ἐπιστεύσαμεν, ἵνα δικαιωθῶμεν ἐκ πίστεως ˚Χριστοῦ, καὶ οὐκ ἐξ ἔργων νόμου, ὅτι ἐξ ἔργων νόμου, οὐ δικαιωθήσεται πᾶσα σάρξ.
48002017 Εἰ δὲ ζητοῦντες δικαιωθῆναι ἐν ˚Χριστῷ, εὑρέθημεν καὶ αὐτοὶ ἁμαρτωλοί, ἆρα ˚Χριστὸς ἁμαρτίας διάκονος; Μὴ γένοιτο!
48002018 Εἰ γὰρ ἃ κατέλυσα, ταῦτα πάλιν οἰκοδομῶ, παραβάτην ἐμαυτὸν συνιστάνω.
48002019 Ἐγὼ γὰρ διὰ νόμου νόμῳ ἀπέθανον, ἵνα ˚Θεῷ ζήσω. ˚Χριστῷ συνεσταύρωμαι·
48002020 ζῶ δὲ, οὐκέτι ἐγώ, ζῇ δὲ ἐν ἐμοὶ ˚Χριστός· ὃ δὲ νῦν ζῶ ἐν σαρκί, ἐν πίστει ζῶ τῇ τοῦ Υἱοῦ τοῦ ˚Θεοῦ, τοῦ ἀγαπήσαντός με, καὶ παραδόντος ἑαυτὸν ὑπὲρ ἐμοῦ.
48002021 Οὐκ ἀθετῶ τὴν χάριν τοῦ ˚Θεοῦ· εἰ γὰρ διὰ νόμου δικαιοσύνη, ἄρα ˚Χριστὸς δωρεὰν ἀπέθανεν.
48003001 Ὦ ἀνόητοι Γαλάται! Τίς ὑμᾶς ἐβάσκανεν, οἷς κατʼ ὀφθαλμοὺς ˚Ἰησοῦς ˚Χριστὸς προεγράφη ἐσταυρωμένος;
48003002 Τοῦτο μόνον θέλω μαθεῖν ἀφʼ ὑμῶν: ἐξ ἔργων νόμου τὸ ˚Πνεῦμα ἐλάβετε, ἢ ἐξ ἀκοῆς πίστεως;
48003003 Οὕτως ἀνόητοί ἐστε; Ἐναρξάμενοι ˚Πνεύματι, νῦν σαρκὶ ἐπιτελεῖσθε;
48003004 Τοσαῦτα ἐπάθετε εἰκῇ– εἴ γε καὶ εἰκῇ;
48003005 Ὁ οὖν ἐπιχορηγῶν ὑμῖν τὸ ˚Πνεῦμα καὶ ἐνεργῶν δυνάμεις ἐν ὑμῖν, ἐξ ἔργων νόμου ἢ ἐξ ἀκοῆς πίστεως;
48003006 Καθὼς Ἀβραὰμ “ἐπίστευσεν τῷ ˚Θεῷ καὶ ἐλογίσθη αὐτῷ εἰς δικαιοσύνην”.
48003007 Γινώσκετε ἄρα ὅτι οἱ ἐκ πίστεως, οὗτοι υἱοί εἰσιν Ἀβραάμ.
48003008 Προϊδοῦσα δὲ ἡ Γραφὴ ὅτι ἐκ πίστεως δικαιοῖ τὰ ἔθνη ὁ ˚Θεὸς, προευηγγελίσατο τῷ Ἀβραὰμ, ὅτι “Ἐνευλογηθήσονται ἐν σοὶ πάντα τὰ ἔθνη.”
48003009 Ὥστε οἱ ἐκ πίστεως εὐλογοῦνται σὺν τῷ πιστῷ Ἀβραάμ.
48003010 Ὅσοι γὰρ ἐξ ἔργων νόμου εἰσὶν ὑπὸ κατάραν εἰσίν, γέγραπται γὰρ, ὅτι “Ἐπικατάρατος πᾶς ὃς οὐκ ἐμμένει πᾶσιν τοῖς γεγραμμένοις ἐν τῷ βιβλίῳ τοῦ νόμου, τοῦ ποιῆσαι αὐτά.”
48003011 Ὅτι δὲ ἐν νόμῳ, οὐδεὶς δικαιοῦται παρὰ τῷ ˚Θεῷ δῆλον, ὅτι, “Ὁ δίκαιος ἐκ πίστεως ζήσεται.”
48003012 Ὁ δὲ νόμος οὐκ ἔστιν ἐκ πίστεως, ἀλλʼ, “Ὁ ποιήσας αὐτὰ, ζήσεται ἐν αὐτοῖς.”
48003013 ˚Χριστὸς ἡμᾶς ἐξηγόρασεν ἐκ τῆς κατάρας τοῦ νόμου, γενόμενος ὑπὲρ ἡμῶν κατάρα, ὅτι γέγραπται, “Ἐπικατάρατος πᾶς ὁ κρεμάμενος ἐπὶ ξύλου”,
48003014 ἵνα εἰς τὰ ἔθνη ἡ εὐλογία τοῦ Ἀβραὰμ γένηται ἐν ˚Χριστῷ ˚Ἰησοῦ, ἵνα τὴν ἐπαγγελίαν τοῦ ˚Πνεύματος λάβωμεν διὰ τῆς πίστεως.
48003015 Ἀδελφοί, κατὰ ἄνθρωπον λέγω. Ὅμως ἀνθρώπου κεκυρωμένην διαθήκην, οὐδεὶς ἀθετεῖ ἢ ἐπιδιατάσσεται.
48003016 Τῷ δὲ Ἀβραὰμ ἐρρέθησαν αἱ ἐπαγγελίαι καὶ τῷ σπέρματι αὐτοῦ. Οὐ λέγει, “Καὶ τοῖς σπέρμασιν”, ὡς ἐπὶ πολλῶν, ἀλλʼ ὡς ἐφʼ ἑνός, “Καὶ τῷ σπέρματί σου”, ὅς ἐστιν ˚Χριστός.
48003017 Τοῦτο δὲ λέγω: διαθήκην προκεκυρωμένην ὑπὸ τοῦ ˚Θεοῦ, ὁ μετὰ τετρακόσια καὶ τριάκοντα ἔτη γεγονὼς νόμος οὐκ ἀκυροῖ, εἰς τὸ καταργῆσαι τὴν ἐπαγγελίαν.
48003018 Εἰ γὰρ ἐκ νόμου ἡ κληρονομία, οὐκέτι ἐξ ἐπαγγελίας· τῷ δὲ Ἀβραὰμ διʼ ἐπαγγελίας κεχάρισται ὁ ˚Θεός.
48003019 Τί οὖν ὁ νόμος; Τῶν παραβάσεων χάριν προσετέθη, ἄχρις οὗ ἔλθῃ τὸ σπέρμα ᾧ ἐπήγγελται, διαταγεὶς διʼ ἀγγέλων ἐν χειρὶ μεσίτου.
48003020 Ὁ δὲ μεσίτης ἑνὸς οὐκ ἔστιν, ὁ δὲ ˚Θεὸς εἷς ἐστιν.
48003021 Ὁ οὖν νόμος κατὰ τῶν ἐπαγγελιῶν τοῦ ˚Θεοῦ; Μὴ γένοιτο! Εἰ γὰρ ἐδόθη νόμος ὁ δυνάμενος ζῳοποιῆσαι, ὄντως ἐκ νόμου ἂν ἦν ἡ δικαιοσύνη.
48003022 Ἀλλὰ συνέκλεισεν ἡ Γραφὴ τὰ πάντα ὑπὸ ἁμαρτίαν, ἵνα ἡ ἐπαγγελία ἐκ πίστεως ˚Ἰησοῦ ˚Χριστοῦ δοθῇ τοῖς πιστεύουσιν.
48003023 Πρὸ τοῦ δὲ ἐλθεῖν τὴν πίστιν, ὑπὸ νόμον ἐφρουρούμεθα, συγκλειόμενοι εἰς τὴν μέλλουσαν πίστιν ἀποκαλυφθῆναι.
48003024 Ὥστε ὁ νόμος, παιδαγωγὸς ἡμῶν γέγονεν εἰς ˚Χριστόν, ἵνα ἐκ πίστεως δικαιωθῶμεν.
48003025 Ἐλθούσης δὲ τῆς πίστεως, οὐκέτι ὑπὸ παιδαγωγόν ἐσμεν.
48003026 Πάντες γὰρ υἱοὶ ˚Θεοῦ ἐστε διὰ τῆς πίστεως ἐν ˚Χριστῷ ˚Ἰησοῦ.
48003027 Ὅσοι γὰρ εἰς ˚Χριστὸν ἐβαπτίσθητε, ˚Χριστὸν ἐνεδύσασθε.
48003028 Οὐκ ἔνι Ἰουδαῖος οὐδὲ Ἕλλην, οὐκ ἔνι δοῦλος οὐδὲ ἐλεύθερος, οὐκ ἔνι ἄρσεν καὶ θῆλυ, πάντες γὰρ ὑμεῖς εἷς ἐστε ἐν ˚Χριστῷ ˚Ἰησοῦ.
48003029 Εἰ δὲ ὑμεῖς ˚Χριστοῦ, ἄρα τοῦ Ἀβραὰμ σπέρμα ἐστέ, κατʼ ἐπαγγελίαν κληρονόμοι.
48004001 Λέγω δέ, ἐφʼ ὅσον χρόνον ὁ κληρονόμος νήπιός ἐστιν, οὐδὲν διαφέρει δούλου, κύριος πάντων ὤν,
48004002 ἀλλὰ ὑπὸ ἐπιτρόπους ἐστὶν καὶ οἰκονόμους, ἄχρι τῆς προθεσμίας τοῦ πατρός.
48004003 Οὕτως καὶ ἡμεῖς, ὅτε ἦμεν νήπιοι, ὑπὸ τὰ στοιχεῖα τοῦ κόσμου ἦμεν δεδουλωμένοι.
48004004 Ὅτε δὲ ἦλθεν τὸ πλήρωμα τοῦ χρόνου, ἐξαπέστειλεν ὁ ˚Θεὸς τὸν Υἱὸν αὐτοῦ, γενόμενον ἐκ γυναικός, γενόμενον ὑπὸ νόμον,
48004005 ἵνα τοὺς ὑπὸ νόμον ἐξαγοράσῃ, ἵνα τὴν υἱοθεσίαν ἀπολάβωμεν.
48004006 Ὅτι δέ ἐστε υἱοί, ἐξαπέστειλεν ὁ ˚Θεὸς τὸ ˚Πνεῦμα τοῦ υἱοῦ αὐτοῦ εἰς τὰς καρδίας ἡμῶν κρᾶζον, “Ἀββά, ὁ Πατήρ!”
48004007 Ὥστε οὐκέτι εἶ δοῦλος, ἀλλὰ υἱός· εἰ δὲ υἱός, καὶ κληρονόμος διὰ ˚Θεοῦ.
48004008 Ἀλλὰ τότε μὲν οὐκ εἰδότες ˚Θεὸν, ἐδουλεύσατε τοῖς φύσει μὴ οὖσι θεοῖς·
48004009 νῦν δὲ γνόντες ˚Θεόν, μᾶλλον δὲ γνωσθέντες ὑπὸ ˚Θεοῦ, πῶς ἐπιστρέφετε πάλιν ἐπὶ τὰ ἀσθενῆ καὶ πτωχὰ στοιχεῖα, οἷς πάλιν ἄνωθεν δουλεύειν θέλετε;
48004010 Ἡμέρας παρατηρεῖσθε, καὶ μῆνας, καὶ καιροὺς, καὶ ἐνιαυτούς.
48004011 Φοβοῦμαι ὑμᾶς, μή πως εἰκῇ κεκοπίακα εἰς ὑμᾶς.
48004012 Γίνεσθε ὡς ἐγώ, ὅτι κἀγὼ ὡς ὑμεῖς, ἀδελφοί, δέομαι ὑμῶν. Οὐδέν με ἠδικήσατε·
48004013 οἴδατε δὲ ὅτι διʼ ἀσθένειαν τῆς σαρκὸς, εὐηγγελισάμην ὑμῖν τὸ πρότερον.
48004014 Καὶ τὸν πειρασμὸν ὑμῶν ἐν τῇ σαρκί μου, οὐκ ἐξουθενήσατε οὐδὲ ἐξεπτύσατε, ἀλλʼ ὡς ἄγγελον ˚Θεοῦ ἐδέξασθέ με, ὡς ˚Χριστὸν ˚Ἰησοῦν.
48004015 Ποῦ οὖν ὁ μακαρισμὸς ὑμῶν; Μαρτυρῶ γὰρ ὑμῖν ὅτι εἰ δυνατὸν τοὺς ὀφθαλμοὺς ὑμῶν ἐξορύξαντες, ἐδώκατέ μοι.
48004016 Ὥστε ἐχθρὸς ὑμῶν γέγονα, ἀληθεύων ὑμῖν;
48004017 Ζηλοῦσιν ὑμᾶς οὐ καλῶς, ἀλλὰ ἐκκλεῖσαι ὑμᾶς θέλουσιν, ἵνα αὐτοὺς ζηλοῦτε.
48004018 Καλὸν δὲ ζηλοῦσθαι ἐν καλῷ πάντοτε, καὶ μὴ μόνον ἐν τῷ παρεῖναί με πρὸς ὑμᾶς.
48004019 Τέκνα μου, οὓς πάλιν ὠδίνω, μέχρις οὗ μορφωθῇ ˚Χριστὸς ἐν ὑμῖν–
48004020 ἤθελον δὲ παρεῖναι πρὸς ὑμᾶς ἄρτι, καὶ ἀλλάξαι τὴν φωνήν μου, ὅτι ἀποροῦμαι ἐν ὑμῖν.
48004021 Λέγετέ μοι, οἱ ὑπὸ νόμον θέλοντες εἶναι, τὸν νόμον οὐκ ἀκούετε;
48004022 Γέγραπται γὰρ ὅτι Ἀβραὰμ δύο υἱοὺς ἔσχεν, ἕνα ἐκ τῆς παιδίσκης, καὶ ἕνα ἐκ τῆς ἐλευθέρας.
48004023 Ἀλλʼ ὁ μὲν ἐκ τῆς παιδίσκης, κατὰ σάρκα γεγέννηται, ὁ δὲ ἐκ τῆς ἐλευθέρας, διʼ ἐπαγγελίας.
48004024 Ἅτινά ἐστιν ἀλληγορούμενα, αὗται γάρ εἰσιν δύο διαθῆκαι: μία μὲν ἀπὸ Ὄρους Σινᾶ εἰς δουλείαν γεννῶσα, ἥτις ἐστὶν Ἁγάρ.
48004025 Τὸ δὲ Ἁγὰρ Σινᾶ Ὄρος ἐστὶν ἐν τῇ Ἀραβίᾳ, συστοιχεῖ δὲ τῇ νῦν Ἰερουσαλήμ, δουλεύει γὰρ μετὰ τῶν τέκνων αὐτῆς.
48004026 Ἡ δὲ ἄνω Ἰερουσαλὴμ ἐλευθέρα ἐστίν, ἥτις ἐστὶν μήτηρ ἡμῶν.
48004027 Γέγραπται γάρ, “Εὐφράνθητι, στεῖρα, ἡ οὐ τίκτουσα, ῥῆξον καὶ βόησον, ἡ οὐκ ὠδίνουσα, ὅτι πολλὰ τὰ τέκνα τῆς ἐρήμου μᾶλλον, ἢ τῆς ἐχούσης τὸν ἄνδρα.”
48004028 Ὑμεῖς δέ, ἀδελφοί, κατὰ Ἰσαὰκ, ἐπαγγελίας τέκνα ἐστέ.
48004029 Ἀλλʼ ὥσπερ τότε ὁ κατὰ σάρκα γεννηθεὶς, ἐδίωκε τὸν κατὰ ˚Πνεῦμα, οὕτως καὶ νῦν.
48004030 Ἀλλὰ τί λέγει ἡ Γραφή; “Ἔκβαλε τὴν παιδίσκην καὶ τὸν υἱὸν αὐτῆς, οὐ γὰρ μὴ κληρονομήσει ὁ υἱὸς τῆς παιδίσκης, μετὰ τοῦ υἱοῦ τῆς ἐλευθέρας.”
48004031 Διό ἀδελφοί, οὐκ ἐσμὲν παιδίσκης τέκνα, ἀλλὰ τῆς ἐλευθέρας.
48005001 Τῇ ἐλευθερίᾳ, ἡμᾶς ˚Χριστὸς ἠλευθέρωσεν, στήκετε οὖν, καὶ μὴ πάλιν ζυγῷ δουλείας ἐνέχεσθε.
48005002 Ἴδε, ἐγὼ Παῦλος λέγω ὑμῖν ὅτι ἐὰν περιτέμνησθε, ˚Χριστὸς ὑμᾶς οὐδὲν ὠφελήσει.
48005003 Μαρτύρομαι δὲ πάλιν παντὶ ἀνθρώπῳ περιτεμνομένῳ ὅτι ὀφειλέτης ἐστὶν, ὅλον τὸν νόμον ποιῆσαι.
48005004 Κατηργήθητε ἀπὸ ˚Χριστοῦ, οἵτινες ἐν νόμῳ δικαιοῦσθε, τῆς χάριτος ἐξεπέσατε.
48005005 Ἡμεῖς γὰρ ˚Πνεύματι ἐκ πίστεως ἐλπίδα δικαιοσύνης ἀπεκδεχόμεθα.
48005006 Ἐν γὰρ ˚Χριστῷ ˚Ἰησοῦ, οὔτε περιτομή τι ἰσχύει, οὔτε ἀκροβυστία, ἀλλὰ πίστις διʼ ἀγάπης ἐνεργουμένη.
48005007 Ἐτρέχετε καλῶς· τίς ὑμᾶς ἐνέκοψεν, ἀληθείᾳ μὴ πείθεσθαι;
48005008 Ἡ πεισμονὴ οὐκ ἐκ τοῦ καλοῦντος ὑμᾶς.
48005009 Μικρὰ ζύμη ὅλον τὸ φύραμα ζυμοῖ.
48005010 Ἐγὼ πέποιθα εἰς ὑμᾶς ἐν ˚Κυρίῳ ὅτι οὐδὲν ἄλλο φρονήσετε. Ὁ δὲ ταράσσων ὑμᾶς, βαστάσει τὸ κρίμα, ὅστις ἐὰν ᾖ.
48005011 Ἐγὼ δέ, ἀδελφοί, εἰ περιτομὴν ἔτι κηρύσσω, τί ἔτι διώκομαι; Ἄρα κατήργηται τὸ σκάνδαλον τοῦ σταυροῦ.
48005012 Ὄφελον καὶ ἀποκόψονται οἱ ἀναστατοῦντες ὑμᾶς.
48005013 Ὑμεῖς γὰρ ἐπʼ ἐλευθερίᾳ ἐκλήθητε, ἀδελφοί· μόνον μὴ τὴν ἐλευθερίαν εἰς ἀφορμὴν τῇ σαρκί, ἀλλὰ διὰ τῆς ἀγάπης δουλεύετε ἀλλήλοις.
48005014 Ὁ γὰρ πᾶς νόμος ἐν ἑνὶ λόγῳ πεπλήρωται, ἐν τῷ, “Ἀγαπήσεις τὸν πλησίον σου ὡς σεαυτόν.”
48005015 Εἰ δὲ ἀλλήλους δάκνετε καὶ κατεσθίετε, βλέπετε, μὴ ὑπʼ ἀλλήλων ἀναλωθῆτε.
48005016 Λέγω δέ, ˚Πνεύματι περιπατεῖτε, καὶ ἐπιθυμίαν σαρκὸς οὐ μὴ τελέσητε.
48005017 Ἡ γὰρ σὰρξ ἐπιθυμεῖ κατὰ τοῦ ˚Πνεύματος, τὸ δὲ ˚Πνεῦμα κατὰ τῆς σαρκός, ταῦτα γὰρ ἀλλήλοις ἀντίκειται, ἵνα μὴ ἃ ἐὰν θέλητε, ταῦτα ποιῆτε.
48005018 Εἰ δὲ ˚Πνεύματι ἄγεσθε, οὐκ ἐστὲ ὑπὸ νόμον.
48005019 Φανερὰ δέ ἐστιν τὰ ἔργα τῆς σαρκός, ἅτινά ἐστιν πορνεία, ἀκαθαρσία, ἀσέλγεια,
48005020 εἰδωλολατρία, φαρμακεία, ἔχθραι, ἔρις, ζῆλος, θυμοί, ἐριθεῖαι, διχοστασίαι, αἱρέσεις,
48005021 φθόνοι, μέθαι, κῶμοι, καὶ τὰ ὅμοια τούτοις, ἃ προλέγω ὑμῖν, καθὼς προεῖπον, ὅτι οἱ τὰ τοιαῦτα πράσσοντες, Βασιλείαν ˚Θεοῦ οὐ κληρονομήσουσιν.
48005022 Ὁ δὲ καρπὸς τοῦ ˚Πνεύματός ἐστιν ἀγάπη, χαρά, εἰρήνη, μακροθυμία, χρηστότης, ἀγαθωσύνη, πίστις,
48005023 πραΰτης, ἐγκράτεια· κατὰ τῶν τοιούτων, οὐκ ἔστιν νόμος.
48005024 Οἱ δὲ τοῦ ˚Χριστοῦ ˚Ἰησοῦ, τὴν σάρκα ἐσταύρωσαν σὺν τοῖς παθήμασιν καὶ ταῖς ἐπιθυμίαις.
48005025 Εἰ ζῶμεν ˚Πνεύματι, ˚Πνεύματι καὶ στοιχῶμεν.
48005026 Μὴ γινώμεθα κενόδοξοι, ἀλλήλους προκαλούμενοι, ἀλλήλοις φθονοῦντες.
48006001 Ἀδελφοί, ἐὰν καὶ προλημφθῇ ἄνθρωπος ἔν τινι παραπτώματι, ὑμεῖς, οἱ πνευματικοὶ, καταρτίζετε τὸν τοιοῦτον ἐν πνεύματι πραΰτητος, σκοπῶν σεαυτόν, μὴ καὶ σὺ πειρασθῇς.
48006002 Ἀλλήλων τὰ βάρη βαστάζετε, καὶ οὕτως ἀναπληρώσετε τὸν νόμον τοῦ ˚Χριστοῦ.
48006003 Εἰ γὰρ δοκεῖ τις εἶναί τι, μηδὲν ὤν, φρεναπατᾷ ἑαυτόν.
48006004 Τὸ δὲ ἔργον ἑαυτοῦ δοκιμαζέτω ἕκαστος, καὶ τότε εἰς ἑαυτὸν μόνον τὸ καύχημα ἕξει, καὶ οὐκ εἰς τὸν ἕτερον.
48006005 Ἕκαστος γὰρ τὸ ἴδιον φορτίον βαστάσει.
48006006 Κοινωνείτω δὲ ὁ κατηχούμενος τὸν λόγον, τῷ κατηχοῦντι, ἐν πᾶσιν ἀγαθοῖς.
48006007 Μὴ πλανᾶσθε, ˚Θεὸς οὐ μυκτηρίζεται. Ὃ γὰρ ἐὰν σπείρῃ ἄνθρωπος, τοῦτο καὶ θερίσει.
48006008 Ὅτι ὁ σπείρων εἰς τὴν σάρκα ἑαυτοῦ, ἐκ τῆς σαρκὸς θερίσει φθοράν, ὁ δὲ σπείρων εἰς τὸ ˚Πνεῦμα, ἐκ τοῦ ˚Πνεύματος θερίσει ζωὴν αἰώνιον.
48006009 Τὸ δὲ καλὸν ποιοῦντες, μὴ ἐγκακῶμεν, καιρῷ γὰρ ἰδίῳ, θερίσομεν μὴ ἐκλυόμενοι.
48006010 Ἄρα οὖν, ὡς καιρὸν ἔχομεν, ἐργαζώμεθα τὸ ἀγαθὸν πρὸς πάντας, μάλιστα δὲ πρὸς τοὺς οἰκείους τῆς πίστεως.
48006011 Ἴδετε πηλίκοις ὑμῖν γράμμασιν ἔγραψα τῇ ἐμῇ χειρί.
48006012 Ὅσοι θέλουσιν εὐπροσωπῆσαι ἐν σαρκί, οὗτοι ἀναγκάζουσιν ὑμᾶς περιτέμνεσθαι, μόνον ἵνα τῷ σταυρῷ τοῦ ˚Χριστοῦ ˚Ἰησοῦ μὴ διώκωνται.
48006013 Οὐδὲ γὰρ οἱ περιτεμνόμενοι αὐτοὶ νόμον φυλάσσουσιν, ἀλλὰ θέλουσιν ὑμᾶς περιτέμνεσθαι, ἵνα ἐν τῇ ὑμετέρᾳ σαρκὶ καυχήσωνται.
48006014 Ἐμοὶ δὲ, μὴ γένοιτο καυχᾶσθαι, εἰ μὴ ἐν τῷ σταυρῷ τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ ˚Χριστοῦ, διʼ οὗ ἐμοὶ κόσμος ἐσταύρωται, κἀγὼ κόσμῳ.
48006015 Οὔτε γὰρ περιτομή τί ἐστιν, οὔτε ἀκροβυστία, ἀλλὰ καινὴ κτίσις.
48006016 Καὶ ὅσοι τῷ κανόνι τούτῳ στοιχήσουσιν, εἰρήνη ἐπʼ αὐτοὺς, καὶ ἔλεος, καὶ ἐπὶ τὸν Ἰσραὴλ τοῦ ˚Θεοῦ.
48006017 Τοῦ λοιποῦ, κόπους μοι μηδεὶς παρεχέτω, ἐγὼ γὰρ τὰ στίγματα τοῦ ˚Ἰησοῦ ἐν τῷ σώματί μου βαστάζω.
48006018 Ἡ χάρις τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ ˚Χριστοῦ, μετὰ τοῦ πνεύματος ὑμῶν, ἀδελφοί. Ἀμήν!
49001001 Παῦλος, ἀπόστολος ˚Χριστοῦ ˚Ἰησοῦ διὰ θελήματος ˚Θεοῦ, τοῖς ἁγίοις τοῖς οὖσιν ἐν Ἐφέσῳ καὶ πιστοῖς ἐν ˚Χριστῷ ˚Ἰησοῦ:
49001002 χάρις ὑμῖν καὶ εἰρήνη ἀπὸ ˚Θεοῦ Πατρὸς ἡμῶν καὶ ˚Κυρίου ˚Ἰησοῦ ˚Χριστοῦ.
49001003 Εὐλογητὸς ὁ ˚Θεὸς καὶ Πατὴρ τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ ˚Χριστοῦ, ὁ εὐλογήσας ἡμᾶς ἐν πάσῃ εὐλογίᾳ πνευματικῇ ἐν τοῖς ἐπουρανίοις ἐν ˚Χριστῷ,
49001004 καθὼς ἐξελέξατο ἡμᾶς ἐν αὐτῷ πρὸ καταβολῆς κόσμου, εἶναι ἡμᾶς ἁγίους καὶ ἀμώμους κατενώπιον αὐτοῦ ἐν ἀγάπῃ,
49001005 προορίσας ἡμᾶς εἰς υἱοθεσίαν διὰ ˚Ἰησοῦ ˚Χριστοῦ εἰς αὐτόν, κατὰ τὴν εὐδοκίαν τοῦ θελήματος αὐτοῦ,
49001006 εἰς ἔπαινον δόξης τῆς χάριτος αὐτοῦ, ἧς ἐχαρίτωσεν ἡμᾶς ἐν τῷ ἠγαπημένῳ,
49001007 ἐν ᾧ ἔχομεν τὴν ἀπολύτρωσιν διὰ τοῦ αἵματος αὐτοῦ, τὴν ἄφεσιν τῶν παραπτωμάτων, κατὰ τὸ πλοῦτος τῆς χάριτος αὐτοῦ,
49001008 ἧς ἐπερίσσευσεν εἰς ἡμᾶς ἐν πάσῃ σοφίᾳ καὶ φρονήσει,
49001009 γνωρίσας ἡμῖν τὸ μυστήριον τοῦ θελήματος αὐτοῦ, κατὰ τὴν εὐδοκίαν αὐτοῦ, ἣν προέθετο ἐν αὐτῷ,
49001010 εἰς οἰκονομίαν τοῦ πληρώματος τῶν καιρῶν, ἀνακεφαλαιώσασθαι τὰ πάντα ἐν τῷ ˚Χριστῷ, τὰ ἐπὶ τοῖς οὐρανοῖς, καὶ τὰ ἐπὶ τῆς γῆς· ἐν αὐτῷ,
49001011 ἐν ᾧ καὶ ἐκληρώθημεν, προορισθέντες κατὰ πρόθεσιν τοῦ τὰ πάντα ἐνεργοῦντος, κατὰ τὴν βουλὴν τοῦ θελήματος αὐτοῦ,
49001012 εἰς τὸ εἶναι ἡμᾶς, εἰς ἔπαινον δόξης αὐτοῦ, τοὺς προηλπικότας ἐν τῷ ˚Χριστῷ·
49001013 ἐν ᾧ καὶ ὑμεῖς ἀκούσαντες τὸν λόγον τῆς ἀληθείας, τὸ εὐαγγέλιον τῆς σωτηρίας ὑμῶν, ἐν ᾧ καὶ πιστεύσαντες, ἐσφραγίσθητε τῷ ˚Πνεύματι τῆς ἐπαγγελίας τῷ Ἁγίῳ,
49001014 ὅ ἐστιν ἀρραβὼν τῆς κληρονομίας ἡμῶν, εἰς ἀπολύτρωσιν τῆς περιποιήσεως, εἰς ἔπαινον τῆς δόξης αὐτοῦ.
49001015 Διὰ τοῦτο, κἀγώ ἀκούσας τὴν καθʼ ὑμᾶς πίστιν ἐν τῷ ˚Κυρίῳ ˚Ἰησοῦ, καὶ τὴν εἰς πάντας τοὺς ἁγίους,
49001016 οὐ παύομαι εὐχαριστῶν ὑπὲρ ὑμῶν, μνείαν ποιούμενος ἐπὶ τῶν προσευχῶν μου,
49001017 ἵνα ὁ ˚Θεὸς τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ ˚Χριστοῦ, ὁ Πατὴρ τῆς δόξης, δώῃ ὑμῖν πνεῦμα σοφίας καὶ ἀποκαλύψεως, ἐν ἐπιγνώσει αὐτοῦ,
49001018 πεφωτισμένους τοὺς ὀφθαλμοὺς τῆς καρδίας ὑμῶν εἰς τὸ εἰδέναι ὑμᾶς, τίς ἐστιν ἡ ἐλπὶς τῆς κλήσεως αὐτοῦ, τίς ὁ πλοῦτος τῆς δόξης τῆς κληρονομίας αὐτοῦ, ἐν τοῖς ἁγίοις,
49001019 καὶ τί τὸ ὑπερβάλλον μέγεθος τῆς δυνάμεως αὐτοῦ εἰς ἡμᾶς, τοὺς πιστεύοντας κατὰ τὴν ἐνέργειαν τοῦ κράτους τῆς ἰσχύος αὐτοῦ,
49001020 ἣν ἐνήργησεν ἐν τῷ ˚Χριστῷ, ἐγείρας αὐτὸν ἐκ νεκρῶν, καὶ καθίσας ἐν δεξιᾷ αὐτοῦ, ἐν τοῖς ἐπουρανίοις,
49001021 ὑπεράνω πάσης ἀρχῆς, καὶ ἐξουσίας, καὶ δυνάμεως, καὶ κυριότητος, καὶ παντὸς ὀνόματος ὀνομαζομένου, οὐ μόνον ἐν τῷ αἰῶνι τούτῳ, ἀλλὰ καὶ ἐν τῷ μέλλοντι·
49001022 καὶ πάντα ὑπέταξεν ὑπὸ τοὺς πόδας αὐτοῦ, καὶ αὐτὸν ἔδωκεν κεφαλὴν ὑπὲρ πάντα τῇ ἐκκλησίᾳ,
49001023 ἥτις ἐστὶν τὸ σῶμα αὐτοῦ, τὸ πλήρωμα τοῦ τὰ πάντα ἐν πᾶσιν πληρουμένου.
49002001 Καὶ ὑμᾶς ὄντας νεκροὺς τοῖς παραπτώμασιν καὶ ταῖς ἁμαρτίαις ὑμῶν,
49002002 ἐν αἷς ποτε περιεπατήσατε, κατὰ τὸν αἰῶνα τοῦ κόσμου τούτου, κατὰ τὸν ἄρχοντα τῆς ἐξουσίας τοῦ ἀέρος, τοῦ πνεύματος τοῦ νῦν ἐνεργοῦντος ἐν τοῖς υἱοῖς τῆς ἀπειθείας·
49002003 ἐν οἷς καὶ ἡμεῖς πάντες ἀνεστράφημέν ποτε ἐν ταῖς ἐπιθυμίαις τῆς σαρκὸς ἡμῶν, ποιοῦντες τὰ θελήματα τῆς σαρκὸς καὶ τῶν διανοιῶν, καὶ ἤμεθα τέκνα φύσει ὀργῆς, ὡς καὶ οἱ λοιποί.
49002004 Ὁ δὲ ˚Θεὸς πλούσιος ὢν ἐν ἐλέει, διὰ τὴν πολλὴν ἀγάπην αὐτοῦ, ἣν ἠγάπησεν ἡμᾶς,
49002005 καὶ ὄντας ἡμᾶς νεκροὺς τοῖς παραπτώμασιν, συνεζωοποίησεν τῷ ˚Χριστῷ ( χάριτί ἐστε σεσῳσμένοι ),
49002006 καὶ συνήγειρεν καὶ συνεκάθισεν ἐν τοῖς ἐπουρανίοις ἐν ˚Χριστῷ ˚Ἰησοῦ,
49002007 ἵνα ἐνδείξηται ἐν τοῖς αἰῶσιν, τοῖς ἐπερχομένοις τὸ ὑπερβάλλον πλοῦτος τῆς χάριτος αὐτοῦ, ἐν χρηστότητι ἐφʼ ἡμᾶς ἐν ˚Χριστῷ ˚Ἰησοῦ.
49002008 Τῇ γὰρ χάριτί ἐστε σεσῳσμένοι διὰ πίστεως, καὶ τοῦτο οὐκ ἐξ ὑμῶν, ˚Θεοῦ τὸ δῶρον·
49002009 οὐκ ἐξ ἔργων, ἵνα μή τις καυχήσηται.
49002010 Αὐτοῦ γάρ ἐσμεν ποίημα, κτισθέντες ἐν ˚Χριστῷ ˚Ἰησοῦ ἐπὶ ἔργοις ἀγαθοῖς, οἷς προητοίμασεν ὁ ˚Θεὸς, ἵνα ἐν αὐτοῖς περιπατήσωμεν.
49002011 Διὸ μνημονεύετε ὅτι ποτὲ ὑμεῖς τὰ ἔθνη ἐν σαρκί, οἱ λεγόμενοι ἀκροβυστία ὑπὸ τῆς λεγομένης περιτομῆς ἐν σαρκὶ χειροποιήτου,
49002012 ὅτι ἦτε τῷ καιρῷ ἐκείνῳ χωρὶς ˚Χριστοῦ, ἀπηλλοτριωμένοι τῆς πολιτείας τοῦ Ἰσραὴλ, καὶ ξένοι τῶν διαθηκῶν τῆς ἐπαγγελίας, ἐλπίδα μὴ ἔχοντες, καὶ ἄθεοι ἐν τῷ κόσμῳ.
49002013 Νυνὶ δὲ ἐν ˚Χριστῷ ˚Ἰησοῦ, ὑμεῖς οἵ ποτε ὄντες μακρὰν, ἐγενήθητε ἐγγὺς ἐν τῷ αἵματι τοῦ ˚Χριστοῦ.
49002014 Αὐτὸς γάρ ἐστιν ἡ εἰρήνη ἡμῶν, ὁ ποιήσας τὰ ἀμφότερα ἓν, καὶ τὸ μεσότοιχον τοῦ φραγμοῦ λύσας, τὴν ἔχθραν ἐν τῇ σαρκὶ αὐτοῦ,
49002015 τὸν νόμον τῶν ἐντολῶν ἐν δόγμασιν καταργήσας, ἵνα τοὺς δύο κτίσῃ ἐν αὑτῷ εἰς ἕνα καινὸν ἄνθρωπον, ποιῶν εἰρήνην,
49002016 καὶ ἀποκαταλλάξῃ τοὺς ἀμφοτέρους ἐν ἑνὶ σώματι τῷ ˚Θεῷ διὰ τοῦ σταυροῦ, ἀποκτείνας τὴν ἔχθραν ἐν αὐτῷ·
49002017 καὶ ἐλθὼν, εὐηγγελίσατο, “εἰρήνην ὑμῖν τοῖς μακρὰν, καὶ εἰρήνην τοῖς ἐγγύς”·
49002018 ὅτι διʼ αὐτοῦ ἔχομεν τὴν προσαγωγὴν, οἱ ἀμφότεροι ἐν ἑνὶ ˚Πνεύματι πρὸς τὸν Πατέρα.
49002019 Ἄρα οὖν οὐκέτι ἐστὲ ξένοι καὶ πάροικοι, ἀλλὰ ἐστὲ συμπολῖται τῶν ἁγίων καὶ οἰκεῖοι τοῦ ˚Θεοῦ,
49002020 ἐποικοδομηθέντες ἐπὶ τῷ θεμελίῳ τῶν ἀποστόλων καὶ προφητῶν, ὄντος ἀκρογωνιαίου αὐτοῦ ˚Χριστοῦ ˚Ἰησοῦ,
49002021 ἐν ᾧ πᾶσα ἡ οἰκοδομὴ συναρμολογουμένη, αὔξει εἰς ναὸν ἅγιον ἐν ˚Κυρίῳ,
49002022 ἐν ᾧ καὶ ὑμεῖς συνοικοδομεῖσθε, εἰς κατοικητήριον τοῦ ˚Θεοῦ ἐν ˚Πνεύματι.
49003001 Τούτου χάριν, ἐγὼ Παῦλος, ὁ δέσμιος τοῦ ˚Χριστοῦ ˚Ἰησοῦ ὑπὲρ ὑμῶν τῶν ἐθνῶν–
49003002 εἴ γε ἠκούσατε τὴν οἰκονομίαν τῆς χάριτος τοῦ ˚Θεοῦ, τῆς δοθείσης μοι εἰς ὑμᾶς,
49003003 κατὰ ἀποκάλυψιν ἐγνωρίσθη μοι τὸ μυστήριον, καθὼς προέγραψα ἐν ὀλίγῳ,
49003004 πρὸς ὃ δύνασθε ἀναγινώσκοντες νοῆσαι τὴν σύνεσίν μου ἐν τῷ μυστηρίῳ τοῦ ˚Χριστοῦ,
49003005 ὃ ἑτέραις γενεαῖς οὐκ ἐγνωρίσθη τοῖς υἱοῖς τῶν ἀνθρώπων, ὡς νῦν ἀπεκαλύφθη τοῖς ἁγίοις ἀποστόλοις αὐτοῦ καὶ προφήταις ἐν ˚Πνεύματι,
49003006 εἶναι τὰ ἔθνη, συγκληρονόμα, καὶ σύσσωμα, καὶ συμμέτοχα, τῆς ἐπαγγελίας ἐν ˚Χριστῷ ˚Ἰησοῦ, διὰ τοῦ εὐαγγελίου,
49003007 οὗ ἐγενήθην διάκονος κατὰ τὴν δωρεὰν τῆς χάριτος τοῦ ˚Θεοῦ τῆς δοθείσης μοι, κατὰ τὴν ἐνέργειαν τῆς δυνάμεως αὐτοῦ.
49003008 Ἐμοὶ τῷ, ἐλαχιστοτέρῳ πάντων ἁγίων, ἐδόθη ἡ χάρις αὕτη, τοῖς ἔθνεσιν εὐαγγελίσασθαι, τὸ ἀνεξιχνίαστον πλοῦτος τοῦ ˚Χριστοῦ,
49003009 καὶ φωτίσαι πάντας, τίς ἡ οἰκονομία τοῦ μυστηρίου, τοῦ ἀποκεκρυμμένου ἀπὸ τῶν αἰώνων ἐν τῷ ˚Θεῷ, τῷ τὰ πάντα κτίσαντι,
49003010 ἵνα γνωρισθῇ νῦν ταῖς ἀρχαῖς καὶ ταῖς ἐξουσίαις ἐν τοῖς ἐπουρανίοις διὰ τῆς ἐκκλησίας ἡ πολυποίκιλος σοφία τοῦ ˚Θεοῦ,
49003011 κατὰ πρόθεσιν τῶν αἰώνων, ἣν ἐποίησεν ἐν τῷ ˚Χριστῷ ˚Ἰησοῦ, τῷ ˚Κυρίῳ ἡμῶν,
49003012 ἐν ᾧ ἔχομεν τὴν παρρησίαν καὶ προσαγωγὴν ἐν πεποιθήσει διὰ τῆς πίστεως αὐτοῦ.
49003013 Διὸ αἰτοῦμαι μὴ ἐγκακεῖν ἐν ταῖς θλίψεσίν μου ὑπὲρ ὑμῶν, ἥτις ἐστὶν δόξα ὑμῶν.
49003014 Τούτου χάριν, κάμπτω τὰ γόνατά μου πρὸς τὸν Πατέρα,
49003015 ἐξ οὗ πᾶσα πατριὰ ἐν οὐρανοῖς καὶ ἐπὶ γῆς ὀνομάζεται,
49003016 ἵνα δῷ ὑμῖν κατὰ τὸ πλοῦτος τῆς δόξης αὐτοῦ, δυνάμει κραταιωθῆναι διὰ τοῦ ˚Πνεύματος αὐτοῦ εἰς τὸν ἔσω ἄνθρωπον,
49003017 κατοικῆσαι τὸν ˚Χριστὸν διὰ τῆς πίστεως ἐν ταῖς καρδίαις ὑμῶν ἐν ἀγάπῃ, ἐρριζωμένοι καὶ τεθεμελιωμένοι,
49003018 ἵνα ἐξισχύσητε καταλαβέσθαι σὺν πᾶσιν τοῖς ἁγίοις, τί τὸ πλάτος, καὶ μῆκος, καὶ ὕψος, καὶ βάθος,
49003019 γνῶναί τε τὴν ὑπερβάλλουσαν τῆς γνώσεως ἀγάπην τοῦ ˚Χριστοῦ, ἵνα πληρωθῆτε εἰς πᾶν τὸ πλήρωμα τοῦ ˚Θεοῦ.
49003020 Τῷ δὲ δυναμένῳ ὑπὲρ πάντα, ποιῆσαι ὑπερεκπερισσοῦ ὧν αἰτούμεθα ἢ νοοῦμεν, κατὰ τὴν δύναμιν τὴν ἐνεργουμένην ἐν ἡμῖν,
49003021 αὐτῷ ἡ δόξα ἐν τῇ ἐκκλησίᾳ καὶ ἐν ˚Χριστῷ ˚Ἰησοῦ, εἰς πάσας τὰς γενεὰς τοῦ αἰῶνος τῶν αἰώνων. Ἀμήν!
49004001 Παρακαλῶ οὖν ὑμᾶς, ἐγὼ ὁ δέσμιος ἐν ˚Κυρίῳ, ἀξίως περιπατῆσαι τῆς κλήσεως ἧς ἐκλήθητε,
49004002 μετὰ πάσης ταπεινοφροσύνης καὶ πραΰτητος, μετὰ μακροθυμίας, ἀνεχόμενοι ἀλλήλων ἐν ἀγάπῃ,
49004003 σπουδάζοντες τηρεῖν τὴν ἑνότητα τοῦ ˚Πνεύματος ἐν τῷ συνδέσμῳ τῆς εἰρήνης.
49004004 Ἓν σῶμα καὶ ἓν ˚Πνεῦμα, καθὼς καὶ ἐκλήθητε ἐν μιᾷ ἐλπίδι τῆς κλήσεως ὑμῶν,
49004005 εἷς ˚Κύριος, μία πίστις, ἓν βάπτισμα,
49004006 εἷς ˚Θεὸς καὶ Πατὴρ πάντων, ὁ ἐπὶ πάντων, καὶ διὰ πάντων, καὶ ἐν πᾶσιν.
49004007 Ἑνὶ δὲ ἑκάστῳ ἡμῶν ἐδόθη ἡ χάρις, κατὰ τὸ μέτρον τῆς δωρεᾶς τοῦ ˚Χριστοῦ.
49004008 Διὸ λέγει, “Ἀναβὰς εἰς ὕψος, ᾐχμαλώτευσεν αἰχμαλωσίαν, ἔδωκεν δόματα τοῖς ἀνθρώποις.”
49004009 Τὸ δὲ, “ἀνέβη”, τί ἐστιν, εἰ μὴ ὅτι καὶ κατέβη εἰς τὰ κατώτερα μέρη τῆς γῆς;
49004010 Ὁ καταβὰς αὐτός ἐστιν καὶ ὁ ἀναβὰς ὑπεράνω πάντων τῶν οὐρανῶν, ἵνα πληρώσῃ τὰ πάντα.
49004011 Καὶ αὐτὸς ἔδωκεν τοὺς μὲν ἀποστόλους, τοὺς δὲ προφήτας, τοὺς δὲ εὐαγγελιστάς, τοὺς δὲ ποιμένας καὶ διδασκάλους,
49004012 πρὸς τὸν καταρτισμὸν τῶν ἁγίων εἰς ἔργον διακονίας, εἰς οἰκοδομὴν τοῦ σώματος τοῦ ˚Χριστοῦ,
49004013 μέχρι καταντήσωμεν οἱ πάντες εἰς τὴν ἑνότητα τῆς πίστεως, καὶ τῆς ἐπιγνώσεως τοῦ Υἱοῦ τοῦ ˚Θεοῦ, εἰς ἄνδρα τέλειον, εἰς μέτρον ἡλικίας τοῦ πληρώματος τοῦ ˚Χριστοῦ,
49004014 ἵνα μηκέτι ὦμεν νήπιοι, κλυδωνιζόμενοι καὶ περιφερόμενοι παντὶ ἀνέμῳ τῆς διδασκαλίας ἐν τῇ κυβείᾳ τῶν ἀνθρώπων, ἐν πανουργίᾳ πρὸς τὴν μεθοδείαν τῆς πλάνης,
49004015 ἀληθεύοντες δὲ ἐν ἀγάπῃ, αὐξήσωμεν εἰς αὐτὸν τὰ πάντα, ὅς ἐστιν ἡ κεφαλή ˚Χριστός,
49004016 ἐξ οὗ πᾶν τὸ σῶμα, συναρμολογούμενον καὶ συμβιβαζόμενον διὰ πάσης ἁφῆς τῆς ἐπιχορηγίας κατʼ ἐνέργειαν ἐν μέτρῳ ἑνὸς ἑκάστου μέρους, τὴν αὔξησιν τοῦ σώματος ποιεῖται εἰς οἰκοδομὴν ἑαυτοῦ ἐν ἀγάπῃ.
49004017 Τοῦτο οὖν λέγω καὶ μαρτύρομαι ἐν ˚Κυρίῳ, μηκέτι ὑμᾶς περιπατεῖν, καθὼς καὶ τὰ ἔθνη περιπατεῖ ἐν ματαιότητι τοῦ νοὸς αὐτῶν.
49004018 Ἐσκοτωμένοι τῇ διανοίᾳ ὄντες, ἀπηλλοτριωμένοι τῆς ζωῆς τοῦ ˚Θεοῦ, διὰ τὴν ἄγνοιαν τὴν οὖσαν ἐν αὐτοῖς, διὰ τὴν πώρωσιν τῆς καρδίας αὐτῶν,
49004019 οἵτινες ἀπηλγηκότες, ἑαυτοὺς παρέδωκαν τῇ ἀσελγείᾳ εἰς ἐργασίαν ἀκαθαρσίας πάσης ἐν πλεονεξίᾳ.
49004020 Ὑμεῖς δὲ οὐχ οὕτως ἐμάθετε τὸν ˚Χριστόν,
49004021 εἴ γε αὐτὸν ἠκούσατε καὶ ἐν αὐτῷ ἐδιδάχθητε, καθώς ἐστιν ἀλήθεια ἐν τῷ ˚Ἰησοῦ,
49004022 ἀποθέσθαι ὑμᾶς κατὰ τὴν προτέραν ἀναστροφὴν τὸν παλαιὸν ἄνθρωπον, τὸν φθειρόμενον κατὰ τὰς ἐπιθυμίας τῆς ἀπάτης,
49004023 ἀνανεοῦσθαι δὲ τῷ πνεύματι τοῦ νοὸς ὑμῶν,
49004024 καὶ ἐνδύσασθαι τὸν καινὸν ἄνθρωπον τὸν κατὰ ˚Θεὸν, κτισθέντα ἐν δικαιοσύνῃ καὶ ὁσιότητι τῆς ἀληθείας.
49004025 Διὸ ἀποθέμενοι τὸ ψεῦδος, “λαλεῖτε ἀλήθειαν ἕκαστος μετὰ τοῦ πλησίον αὐτοῦ”, ὅτι ἐσμὲν ἀλλήλων μέλη.
49004026 “Ὀργίζεσθε, καὶ μὴ ἁμαρτάνετε”· ὁ ἥλιος μὴ ἐπιδυέτω ἐπὶ παροργισμῷ ὑμῶν,
49004027 μηδὲ δίδοτε τόπον τῷ διαβόλῳ.
49004028 Ὁ κλέπτων μηκέτι κλεπτέτω, μᾶλλον δὲ κοπιάτω, ἐργαζόμενος ταῖς χερσὶν τὸ ἀγαθόν, ἵνα ἔχῃ μεταδιδόναι τῷ χρείαν ἔχοντι.
49004029 Πᾶς λόγος σαπρὸς ἐκ τοῦ στόματος ὑμῶν μὴ ἐκπορευέσθω, ἀλλʼ εἴ τις ἀγαθὸς πρὸς οἰκοδομὴν τῆς χρείας, ἵνα δῷ χάριν τοῖς ἀκούουσιν.
49004030 Καὶ μὴ λυπεῖτε τὸ ˚Πνεῦμα τὸ Ἅγιον τοῦ ˚Θεοῦ, ἐν ᾧ ἐσφραγίσθητε εἰς ἡμέραν ἀπολυτρώσεως.
49004031 Πᾶσα πικρία, καὶ θυμὸς, καὶ ὀργὴ, καὶ κραυγὴ, καὶ βλασφημία, ἀρθήτω ἀφʼ ὑμῶν σὺν πάσῃ κακίᾳ.
49004032 Γίνεσθε εἰς ἀλλήλους χρηστοί, εὔσπλαγχνοι, χαριζόμενοι ἑαυτοῖς, καθὼς καὶ ὁ ˚Θεὸς ἐν ˚Χριστῷ ἐχαρίσατο ὑμῖν.
49005001 Γίνεσθε οὖν μιμηταὶ τοῦ ˚Θεοῦ ὡς τέκνα ἀγαπητά,
49005002 καὶ περιπατεῖτε ἐν ἀγάπῃ, καθὼς καὶ ὁ ˚Χριστὸς ἠγάπησεν ὑμᾶς, καὶ παρέδωκεν ἑαυτὸν ὑπὲρ ἡμῶν, προσφορὰν καὶ θυσίαν τῷ ˚Θεῷ εἰς ὀσμὴν εὐωδίας.
49005003 Πορνεία δὲ, καὶ ἀκαθαρσία πᾶσα, ἢ πλεονεξία, μηδὲ ὀνομαζέσθω ἐν ὑμῖν, καθὼς πρέπει ἁγίοις,
49005004 καὶ αἰσχρότης, καὶ μωρολογία, ἢ εὐτραπελία, ἃ οὐκ ἀνῆκεν, ἀλλὰ μᾶλλον εὐχαριστία.
49005005 Τοῦτο γὰρ ἴστε γινώσκοντες ὅτι πᾶς πόρνος, ἢ ἀκάθαρτος, ἢ πλεονέκτης, ὅ ἐστιν εἰδωλολάτρης, οὐκ ἔχει κληρονομίαν ἐν τῇ βασιλείᾳ τοῦ ˚Χριστοῦ καὶ ˚Θεοῦ.
49005006 Μηδεὶς ὑμᾶς ἀπατάτω κενοῖς λόγοις διὰ ταῦτα, γὰρ ἔρχεται ἡ ὀργὴ τοῦ ˚Θεοῦ ἐπὶ τοὺς υἱοὺς τῆς ἀπειθείας.
49005007 Μὴ οὖν γίνεσθε συμμέτοχοι αὐτῶν·
49005008 ἦτε γάρ ποτε σκότος, νῦν δὲ φῶς ἐν ˚Κυρίῳ· ὡς τέκνα φωτὸς περιπατεῖτε
49005009 ( ὁ γὰρ καρπὸς τοῦ φωτὸς ἐν πάσῃ ἀγαθωσύνῃ, καὶ δικαιοσύνῃ, καὶ ἀληθείᾳ ),
49005010 δοκιμάζοντες τί ἐστιν εὐάρεστον τῷ ˚Κυρίῳ.
49005011 Καὶ μὴ συγκοινωνεῖτε τοῖς ἔργοις τοῖς ἀκάρποις τοῦ σκότους, μᾶλλον δὲ καὶ ἐλέγχετε,
49005012 τὰ γὰρ κρυφῇ γινόμενα ὑπʼ αὐτῶν, αἰσχρόν ἐστιν καὶ λέγειν.
49005013 Τὰ δὲ πάντα ἐλεγχόμενα ὑπὸ τοῦ φωτὸς φανεροῦται, πᾶν γὰρ τὸ φανερούμενον φῶς ἐστιν.
49005014 Διὸ λέγει, “Ἔγειρε, ὁ καθεύδων, καὶ ἀνάστα ἐκ τῶν νεκρῶν, καὶ ἐπιφαύσει σοι ὁ ˚Χριστός.”
49005015 Βλέπετε οὖν ἀκριβῶς πῶς περιπατεῖτε, μὴ ὡς ἄσοφοι, ἀλλʼ ὡς σοφοί,
49005016 ἐξαγοραζόμενοι τὸν καιρόν, ὅτι αἱ ἡμέραι πονηραί εἰσιν.
49005017 Διὰ τοῦτο, μὴ γίνεσθε ἄφρονες, ἀλλὰ συνίετε τί τὸ θέλημα τοῦ ˚Κυρίου.
49005018 Καὶ μὴ μεθύσκεσθε οἴνῳ, ἐν ᾧ ἐστιν ἀσωτία, ἀλλὰ πληροῦσθε ἐν ˚Πνεύματι,
49005019 λαλοῦντες ἑαυτοῖς ἐν ψαλμοῖς, καὶ ὕμνοις, καὶ ᾠδαῖς πνευματικαῖς, ᾄδοντες καὶ ψάλλοντες τῇ καρδίᾳ ὑμῶν τῷ ˚Κυρίῳ,
49005020 εὐχαριστοῦντες πάντοτε ὑπὲρ πάντων ἐν ὀνόματι τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ ˚Χριστοῦ, τῷ ˚Θεῷ καὶ Πατρί,
49005021 ὑποτασσόμενοι ἀλλήλοις ἐν φόβῳ ˚Χριστοῦ.
49005022 Αἱ γυναῖκες τοῖς ἰδίοις ἀνδράσιν ὡς τῷ ˚Κυρίῳ,
49005023 ὅτι ἀνήρ ἐστιν κεφαλὴ τῆς γυναικὸς, ὡς καὶ ὁ ˚Χριστὸς κεφαλὴ τῆς ἐκκλησίας, αὐτὸς Σωτὴρ τοῦ σώματος.
49005024 Ἀλλʼ ὡς ἡ ἐκκλησία ὑποτάσσεται τῷ ˚Χριστῷ, οὕτως καὶ αἱ γυναῖκες τοῖς ἀνδράσιν ἐν παντί.
49005025 Οἱ ἄνδρες ἀγαπᾶτε τὰς γυναῖκας, καθὼς καὶ ὁ ˚Χριστὸς ἠγάπησεν τὴν ἐκκλησίαν, καὶ ἑαυτὸν παρέδωκεν ὑπὲρ αὐτῆς,
49005026 ἵνα αὐτὴν ἁγιάσῃ, καθαρίσας τῷ λουτρῷ τοῦ ὕδατος ἐν ῥήματι,
49005027 ἵνα παραστήσῃ αὐτὸς ἑαυτῷ ἔνδοξον τὴν ἐκκλησίαν, μὴ ἔχουσαν σπίλον, ἢ ῥυτίδα, ἤ τι τῶν τοιούτων, ἀλλʼ ἵνα ᾖ ἁγία καὶ ἄμωμος.
49005028 Οὕτως ὀφείλουσιν καὶ οἱ ἄνδρες ἀγαπᾶν τὰς ἑαυτῶν γυναῖκας, ὡς τὰ ἑαυτῶν σώματα. Ὁ ἀγαπῶν τὴν ἑαυτοῦ γυναῖκα, ἑαυτὸν ἀγαπᾷ.
49005029 Οὐδεὶς γάρ ποτε τὴν ἑαυτοῦ σάρκα ἐμίσησεν, ἀλλὰ ἐκτρέφει καὶ θάλπει αὐτήν, καθὼς καὶ ὁ ˚Χριστὸς τὴν ἐκκλησίαν,
49005030 ὅτι μέλη ἐσμὲν τοῦ σώματος αὐτοῦ.
49005031 “Ἀντὶ τούτου καταλείψει ἄνθρωπος τὸν πατέρα καὶ τὴν μητέρα, καὶ προσκολληθήσεται τῇ γυναικὶ αὐτοῦ, καὶ ἔσονται οἱ δύο εἰς σάρκα μίαν.”
49005032 Τὸ μυστήριον τοῦτο μέγα ἐστίν, ἐγὼ δὲ λέγω εἰς ˚Χριστὸν καὶ εἰς τὴν ἐκκλησίαν.
49005033 Πλὴν καὶ ὑμεῖς, οἱ καθʼ ἕνα, ἕκαστος τὴν ἑαυτοῦ γυναῖκα οὕτως ἀγαπάτω ὡς ἑαυτόν, ἡ δὲ γυνὴ, ἵνα φοβῆται τὸν ἄνδρα.
49006001 Τὰ τέκνα, ὑπακούετε τοῖς γονεῦσιν ὑμῶν ἐν ˚Κυρίῳ, τοῦτο γάρ ἐστιν δίκαιον.
49006002 “Τίμα τὸν πατέρα σου καὶ τὴν μητέρα” ( ἥτις ἐστὶν ἐντολὴ πρώτη ἐν ἐπαγγελίᾳ ),
49006003 “ἵνα εὖ σοι γένηται, καὶ ἔσῃ μακροχρόνιος ἐπὶ τῆς γῆς.”
49006004 Καὶ, οἱ πατέρες, μὴ παροργίζετε τὰ τέκνα ὑμῶν, ἀλλὰ ἐκτρέφετε αὐτὰ ἐν παιδείᾳ καὶ νουθεσίᾳ ˚Κυρίου.
49006005 Οἱ δοῦλοι, ὑπακούετε τοῖς κατὰ σάρκα κυρίοις μετὰ φόβου καὶ τρόμου, ἐν ἁπλότητι τῆς καρδίας ὑμῶν ὡς τῷ ˚Χριστῷ,
49006006 μὴ κατʼ ὀφθαλμοδουλίαν ὡς ἀνθρωπάρεσκοι, ἀλλʼ ὡς δοῦλοι ˚Χριστοῦ, ποιοῦντες τὸ θέλημα τοῦ ˚Θεοῦ ἐκ ψυχῆς,
49006007 μετʼ εὐνοίας δουλεύοντες ὡς τῷ ˚Κυρίῳ, καὶ οὐκ ἀνθρώποις,
49006008 εἰδότες ὅτι ἐάν ποιήσῃ ἕκαστος ἀγαθόν, τοῦτο κομίσεται παρὰ ˚Κυρίου, εἴτε δοῦλος εἴτε ἐλεύθερος.
49006009 Καὶ, οἱ κύριοι, τὰ αὐτὰ ποιεῖτε πρὸς αὐτούς, ἀνιέντες τὴν ἀπειλήν, εἰδότες ὅτι καὶ αὐτῶν καὶ ὑμῶν ὁ ˚Κύριός ἐστιν ἐν οὐρανοῖς, καὶ προσωπολημψία οὐκ ἔστιν παρʼ αὐτῷ.
49006010 Τοῦ λοιποῦ, ἐνδυναμοῦσθε, ἐν ˚Κυρίῳ καὶ ἐν τῷ κράτει τῆς ἰσχύος αὐτοῦ.
49006011 Ἐνδύσασθε τὴν πανοπλίαν τοῦ ˚Θεοῦ, πρὸς τὸ δύνασθαι ὑμᾶς στῆναι πρὸς τὰς μεθοδείας τοῦ διαβόλου.
49006012 Ὅτι οὐκ ἔστιν ἡμῖν ἡ πάλη πρὸς αἷμα καὶ σάρκα, ἀλλὰ πρὸς τὰς ἀρχάς, πρὸς τὰς ἐξουσίας, πρὸς τοὺς κοσμοκράτορας τοῦ σκότους τούτου, πρὸς τὰ πνευματικὰ τῆς πονηρίας ἐν τοῖς ἐπουρανίοις.
49006013 Διὰ τοῦτο, ἀναλάβετε τὴν πανοπλίαν τοῦ ˚Θεοῦ, ἵνα δυνηθῆτε ἀντιστῆναι ἐν τῇ ἡμέρᾳ τῇ πονηρᾷ, καὶ ἅπαντα κατεργασάμενοι, στῆναι.
49006014 Στῆτε οὖν, “περιζωσάμενοι τὴν ὀσφὺν ὑμῶν ἐν ἀληθείᾳ”, καὶ “ἐνδυσάμενοι τὸν θώρακα τῆς δικαιοσύνης”,
49006015 καὶ ὑποδησάμενοι τοὺς “πόδας ἐν ἑτοιμασίᾳ τοῦ εὐαγγελίου τῆς εἰρήνης”.
49006016 Ἐν πᾶσιν ἀναλαβόντες τὸν θυρεὸν τῆς πίστεως, ἐν ᾧ δυνήσεσθε πάντα τὰ βέλη τοῦ πονηροῦ πεπυρωμένα σβέσαι.
49006017 Καὶ “τὴν περικεφαλαίαν τοῦ σωτηρίου” δέξασθε, καὶ τὴν μάχαιραν τοῦ ˚Πνεύματος, ὅ ἐστιν ῥῆμα ˚Θεοῦ,
49006018 διὰ πάσης προσευχῆς καὶ δεήσεως, προσευχόμενοι ἐν παντὶ καιρῷ ἐν ˚Πνεύματι, καὶ εἰς αὐτὸ, ἀγρυπνοῦντες ἐν πάσῃ προσκαρτερήσει καὶ δεήσει περὶ πάντων τῶν ἁγίων,
49006019 καὶ ὑπὲρ ἐμοῦ, ἵνα μοι δοθῇ λόγος ἐν ἀνοίξει τοῦ στόματός μου, ἐν παρρησίᾳ γνωρίσαι τὸ μυστήριον τοῦ εὐαγγελίου,
49006020 ὑπὲρ οὗ πρεσβεύω ἐν ἁλύσει, ἵνα ἐν αὐτῷ παρρησιάσωμαι, ὡς δεῖ με λαλῆσαι.
49006021 Ἵνα δὲ εἰδῆτε καὶ ὑμεῖς τὰ κατʼ ἐμέ, τί πράσσω, πάντα γνωρίσει ὑμῖν Τυχικὸς, ὁ ἀγαπητὸς ἀδελφὸς καὶ πιστὸς διάκονος ἐν ˚Κυρίῳ,
49006022 ὃν ἔπεμψα πρὸς ὑμᾶς εἰς αὐτὸ τοῦτο, ἵνα γνῶτε τὰ περὶ ἡμῶν, καὶ παρακαλέσῃ τὰς καρδίας ὑμῶν.
49006023 Εἰρήνη τοῖς ἀδελφοῖς καὶ ἀγάπη μετὰ πίστεως ἀπὸ ˚Θεοῦ Πατρὸς καὶ ˚Κυρίου ˚Ἰησοῦ ˚Χριστοῦ.
49006024 Ἡ χάρις μετὰ πάντων τῶν ἀγαπώντων τὸν ˚Κύριον ἡμῶν, ˚Ἰησοῦν ˚Χριστὸν, ἐν ἀφθαρσίᾳ.
50001001 Παῦλος καὶ Τιμόθεος, δοῦλοι ˚Χριστοῦ ˚Ἰησοῦ· πᾶσιν τοῖς ἁγίοις ἐν ˚Χριστῷ ˚Ἰησοῦ τοῖς οὖσιν ἐν Φιλίπποις, σὺν ἐπισκόποις καὶ διακόνοις:
50001002 χάρις ὑμῖν καὶ εἰρήνη ἀπὸ ˚Θεοῦ Πατρὸς ἡμῶν καὶ ˚Κυρίου ˚Ἰησοῦ ˚Χριστοῦ.
50001003 Εὐχαριστῶ τῷ ˚Θεῷ μου ἐπὶ πάσῃ τῇ μνείᾳ ὑμῶν,
50001004 πάντοτε ἐν πάσῃ δεήσει μου ὑπὲρ πάντων ὑμῶν, μετὰ χαρᾶς τὴν δέησιν ποιούμενος,
50001005 ἐπὶ τῇ κοινωνίᾳ ὑμῶν εἰς τὸ εὐαγγέλιον, ἀπὸ τῆς πρώτης ἡμέρας ἄχρι τοῦ νῦν,
50001006 πεποιθὼς αὐτὸ τοῦτο, ὅτι ὁ ἐναρξάμενος ἐν ὑμῖν ἔργον ἀγαθὸν, ἐπιτελέσει ἄχρι ἡμέρας ˚Χριστοῦ ˚Ἰησοῦ·
50001007 καθώς ἐστιν δίκαιον ἐμοὶ τοῦτο φρονεῖν ὑπὲρ πάντων ὑμῶν, διὰ τὸ ἔχειν με ἐν τῇ καρδίᾳ ὑμᾶς, ἔν τε τοῖς δεσμοῖς μου, καὶ ἐν τῇ ἀπολογίᾳ καὶ βεβαιώσει τοῦ εὐαγγελίου, συγκοινωνούς μου τῆς χάριτος πάντας ὑμᾶς ὄντας.
50001008 Μάρτυς γάρ μου ὁ ˚Θεός, ὡς ἐπιποθῶ πάντας ὑμᾶς ἐν σπλάγχνοις ˚Χριστοῦ ˚Ἰησοῦ.
50001009 Καὶ τοῦτο προσεύχομαι, ἵνα ἡ ἀγάπη ὑμῶν ἔτι μᾶλλον καὶ μᾶλλον περισσεύῃ ἐν ἐπιγνώσει καὶ πάσῃ αἰσθήσει,
50001010 εἰς τὸ δοκιμάζειν ὑμᾶς τὰ διαφέροντα, ἵνα ἦτε εἰλικρινεῖς καὶ ἀπρόσκοποι εἰς ἡμέραν ˚Χριστοῦ,
50001011 πεπληρωμένοι καρπὸν δικαιοσύνης τὸν διὰ ˚Ἰησοῦ ˚Χριστοῦ, εἰς δόξαν καὶ ἔπαινον ˚Θεοῦ.
50001012 Γινώσκειν δὲ ὑμᾶς, βούλομαι, ἀδελφοί, ὅτι τὰ κατʼ ἐμὲ, μᾶλλον εἰς προκοπὴν τοῦ εὐαγγελίου ἐλήλυθεν,
50001013 ὥστε τοὺς δεσμούς μου φανεροὺς ἐν ˚Χριστῷ γενέσθαι, ἐν ὅλῳ τῷ πραιτωρίῳ καὶ τοῖς λοιποῖς πᾶσιν,
50001014 καὶ τοὺς πλείονας τῶν ἀδελφῶν ἐν ˚Κυρίῳ πεποιθότας τοῖς δεσμοῖς μου, περισσοτέρως τολμᾶν ἀφόβως τὸν λόγον τοῦ ˚Θεοῦ λαλεῖν.
50001015 Τινὲς μὲν καὶ διὰ φθόνον καὶ ἔριν, τινὲς δὲ καὶ διʼ εὐδοκίαν τὸν ˚Χριστὸν κηρύσσουσιν.
50001016 Οἱ δὲ ἐξ ἐριθείας τὸν ˚Χριστὸν καταγγέλλουσιν, οὐχ ἁγνῶς οἰόμενοι θλῖψιν ἐγείρειν τοῖς δεσμοῖς μου.
50001017 Οἱ μὲν ἐξ ἀγάπης εἰδότες ὅτι εἰς ἀπολογίαν τοῦ εὐαγγελίου κεῖμαι,
50001018 τί γάρ; Πλὴν ὅτι παντὶ τρόπῳ, εἴτε προφάσει εἴτε ἀληθείᾳ, ˚Χριστὸς καταγγέλλεται, καὶ ἐν τούτῳ χαίρω, ἀλλὰ καὶ χαρήσομαι.
50001019 Οἶδα γὰρ ὅτι τοῦτό μοι ἀποβήσεται εἰς σωτηρίαν διὰ τῆς ὑμῶν δεήσεως, καὶ ἐπιχορηγίας τοῦ ˚Πνεύματος ˚Ἰησοῦ ˚Χριστοῦ,
50001020 κατὰ τὴν ἀποκαραδοκίαν καὶ ἐλπίδα μου, ὅτι ἐν οὐδενὶ αἰσχυνθήσομαι, ἀλλʼ ἐν πάσῃ παρρησίᾳ, ὡς πάντοτε καὶ νῦν, μεγαλυνθήσεται ˚Χριστὸς ἐν τῷ σώματί μου, εἴτε διὰ ζωῆς εἴτε διὰ θανάτου.
50001021 Ἐμοὶ γὰρ τὸ ζῆν ˚Χριστὸς, καὶ τὸ ἀποθανεῖν κέρδος.
50001022 Εἰ δὲ τὸ ζῆν ἐν σαρκί, τοῦτό μοι καρπὸς ἔργου. Καὶ τί αἱρήσομαι οὐ γνωρίζω.
50001023 Συνέχομαι δὲ ἐκ τῶν δύο, τὴν ἐπιθυμίαν ἔχων εἰς τὸ ἀναλῦσαι καὶ σὺν ˚Χριστῷ εἶναι, πολλῷ γὰρ μᾶλλον κρεῖσσον,
50001024 τὸ δὲ ἐπιμένειν ἐν τῇ σαρκὶ, ἀναγκαιότερον διʼ ὑμᾶς.
50001025 Καὶ τοῦτο πεποιθὼς, οἶδα ὅτι μενῶ καὶ παραμενῶ πᾶσιν ὑμῖν εἰς τὴν ὑμῶν προκοπὴν καὶ χαρὰν τῆς πίστεως,
50001026 ἵνα τὸ καύχημα ὑμῶν περισσεύῃ ἐν ˚Χριστῷ ˚Ἰησοῦ ἐν ἐμοὶ, διὰ τῆς ἐμῆς παρουσίας πάλιν πρὸς ὑμᾶς.
50001027 Μόνον ἀξίως τοῦ εὐαγγελίου τοῦ ˚Χριστοῦ πολιτεύεσθε, ἵνα εἴτε ἐλθὼν καὶ ἰδὼν ὑμᾶς, εἴτε ἀπὼν, ἀκούω τὰ περὶ ὑμῶν, ὅτι στήκετε ἐν ἑνὶ πνεύματι, μιᾷ ψυχῇ συναθλοῦντες τῇ πίστει τοῦ εὐαγγελίου,
50001028 καὶ μὴ πτυρόμενοι ἐν μηδενὶ ὑπὸ τῶν ἀντικειμένων, ἥτις ἐστὶν αὐτοῖς ἔνδειξις ἀπωλείας, ὑμῶν δὲ σωτηρίας, καὶ τοῦτο ἀπὸ ˚Θεοῦ,
50001029 ὅτι ὑμῖν ἐχαρίσθη τὸ ὑπὲρ ˚Χριστοῦ, οὐ μόνον τὸ εἰς αὐτὸν πιστεύειν, ἀλλὰ καὶ τὸ ὑπὲρ αὐτοῦ πάσχειν,
50001030 τὸν αὐτὸν ἀγῶνα ἔχοντες, οἷον εἴδετε ἐν ἐμοὶ, καὶ νῦν ἀκούετε ἐν ἐμοί.
50002001 Εἴ τις οὖν παράκλησις ἐν ˚Χριστῷ, εἴ τι παραμύθιον ἀγάπης, εἴ τις κοινωνία ˚Πνεύματος, εἴ τις σπλάγχνα καὶ οἰκτιρμοί,
50002002 πληρώσατέ μου τὴν χαρὰν, ἵνα τὸ αὐτὸ φρονῆτε, τὴν αὐτὴν ἀγάπην ἔχοντες, σύμψυχοι, τὸ ἓν φρονοῦντες.
50002003 Μηδὲν κατʼ ἐριθείαν μηδὲ κατὰ κενοδοξίαν, ἀλλὰ τῇ ταπεινοφροσύνῃ ἀλλήλους ἡγούμενοι ὑπερέχοντας ἑαυτῶν,
50002004 μὴ τὰ ἑαυτῶν ἕκαστος σκοποῦντες, ἀλλὰ καὶ τὰ ἑτέρων ἕκαστοι.
50002005 Τοῦτο φρονεῖτε ἐν ὑμῖν, ὃ καὶ ἐν ˚Χριστῷ ˚Ἰησοῦ,
50002006 ὃς ἐν μορφῇ ˚Θεοῦ ὑπάρχων, οὐχ ἁρπαγμὸν ἡγήσατο τὸ εἶναι ἴσα ˚Θεῷ,
50002007 ἀλλὰ ἑαυτὸν ἐκένωσεν, μορφὴν δούλου λαβών, ἐν ὁμοιώματι ἀνθρώπων γενόμενος. Καὶ σχήματι εὑρεθεὶς ὡς ἄνθρωπος,
50002008 ἐταπείνωσεν ἑαυτὸν, γενόμενος ὑπήκοος μέχρι θανάτου, θανάτου δὲ σταυροῦ.
50002009 Διὸ καὶ ὁ ˚Θεὸς αὐτὸν ὑπερύψωσεν, καὶ ἐχαρίσατο αὐτῷ τὸ ὄνομα τὸ ὑπὲρ πᾶν ὄνομα,
50002010 ἵνα ἐν τῷ ὀνόματι ˚Ἰησοῦ, πᾶν γόνυ κάμψῃ ἐπουρανίων καὶ ἐπιγείων καὶ καταχθονίων,
50002011 καὶ πᾶσα γλῶσσα ἐξομολογήσηται ὅτι ˚Κύριος ˚Ἰησοῦς ˚Χριστὸς, εἰς δόξαν ˚Θεοῦ Πατρός.
50002012 Ὥστε, ἀγαπητοί μου, καθὼς πάντοτε ὑπηκούσατε, μὴ ὡς ἐν τῇ παρουσίᾳ μου μόνον, ἀλλὰ νῦν πολλῷ μᾶλλον ἐν τῇ ἀπουσίᾳ μου, μετὰ φόβου καὶ τρόμου τὴν ἑαυτῶν σωτηρίαν κατεργάζεσθε.
50002013 ˚Θεὸς γάρ ἐστιν ὁ ἐνεργῶν ἐν ὑμῖν, καὶ τὸ θέλειν, καὶ τὸ ἐνεργεῖν, ὑπὲρ τῆς εὐδοκίας.
50002014 Πάντα ποιεῖτε χωρὶς γογγυσμῶν καὶ διαλογισμῶν,
50002015 ἵνα γένησθε ἄμεμπτοι καὶ ἀκέραιοι, τέκνα ˚Θεοῦ ἄμωμα μέσον γενεᾶς σκολιᾶς καὶ διεστραμμένης, ἐν οἷς φαίνεσθε ὡς φωστῆρες ἐν κόσμῳ,
50002016 λόγον ζωῆς ἐπέχοντες εἰς καύχημα ἐμοὶ εἰς ἡμέραν ˚Χριστοῦ, ὅτι οὐκ εἰς κενὸν ἔδραμον, οὐδὲ εἰς κενὸν ἐκοπίασα,
50002017 ἀλλʼ εἰ καὶ σπένδομαι ἐπὶ τῇ θυσίᾳ καὶ λειτουργίᾳ τῆς πίστεως ὑμῶν, χαίρω καὶ συγχαίρω πᾶσιν ὑμῖν.
50002018 Τὸ δὲ αὐτὸ καὶ ὑμεῖς χαίρετε καὶ συγχαίρετέ μοι.
50002019 Ἐλπίζω δὲ ἐν ˚Κυρίῳ ˚Ἰησοῦ, Τιμόθεον ταχέως πέμψαι ὑμῖν, ἵνα κἀγὼ εὐψυχῶ, γνοὺς τὰ περὶ ὑμῶν.
50002020 Οὐδένα γὰρ ἔχω ἰσόψυχον, ὅστις γνησίως τὰ περὶ ὑμῶν μεριμνήσει.
50002021 Οἱ πάντες γὰρ τὰ ἑαυτῶν ζητοῦσιν, οὐ τὰ ˚Ἰησοῦ ˚Χριστοῦ.
50002022 Τὴν δὲ δοκιμὴν αὐτοῦ γινώσκετε, ὅτι ὡς πατρὶ τέκνον, σὺν ἐμοὶ ἐδούλευσεν εἰς τὸ εὐαγγέλιον.
50002023 Τοῦτον μὲν οὖν ἐλπίζω πέμψαι, ὡς ἂν ἀφίδω τὰ περὶ ἐμὲ ἐξαυτῆς·
50002024 πέποιθα δὲ ἐν ˚Κυρίῳ ὅτι καὶ αὐτὸς ταχέως ἐλεύσομαι.
50002025 Ἀναγκαῖον δὲ ἡγησάμην, Ἐπαφρόδιτον, τὸν ἀδελφὸν καὶ συνεργὸν καὶ συστρατιώτην μου, ὑμῶν δὲ ἀπόστολον καὶ λειτουργὸν τῆς χρείας μου, πέμψαι πρὸς ὑμᾶς,
50002026 ἐπειδὴ ἐπιποθῶν ἦν πάντας ὑμᾶς, καὶ ἀδημονῶν διότι ἠκούσατε ὅτι ἠσθένησεν.
50002027 Καὶ γὰρ ἠσθένησεν παραπλήσιον θανάτῳ, ἀλλὰ ὁ ˚Θεὸς ἠλέησεν αὐτόν, οὐκ αὐτὸν δὲ μόνον, ἀλλὰ καὶ ἐμέ, ἵνα μὴ λύπην ἐπὶ λύπην σχῶ.
50002028 Σπουδαιοτέρως οὖν ἔπεμψα αὐτὸν, ἵνα ἰδόντες αὐτὸν πάλιν, χαρῆτε κἀγὼ ἀλυπότερος ὦ.
50002029 Προσδέχεσθε οὖν αὐτὸν ἐν ˚Κυρίῳ μετὰ πάσης χαρᾶς, καὶ τοὺς τοιούτους ἐντίμους ἔχετε,
50002030 ὅτι διὰ τὸ ἔργον ˚Χριστοῦ μέχρι θανάτου ἤγγισεν, παραβολευσάμενος τῇ ψυχῇ, ἵνα ἀναπληρώσῃ τὸ ὑμῶν ὑστέρημα, τῆς πρός με λειτουργίας.
50003001 Τὸ λοιπόν, ἀδελφοί μου, χαίρετε ἐν ˚Κυρίῳ. Τὰ αὐτὰ γράφειν ὑμῖν, ἐμοὶ μὲν οὐκ ὀκνηρόν, ὑμῖν δὲ ἀσφαλές.
50003002 Βλέπετε τοὺς κύνας, βλέπετε τοὺς κακοὺς ἐργάτας, βλέπετε τὴν κατατομήν.
50003003 Ἡμεῖς γάρ ἐσμεν ἡ περιτομή, οἱ ˚Πνεύματι ˚Θεοῦ λατρεύοντες καὶ καυχώμενοι ἐν ˚Χριστῷ ˚Ἰησοῦ, καὶ οὐκ ἐν σαρκὶ πεποιθότες,
50003004 καίπερ ἐγὼ ἔχων πεποίθησιν καὶ ἐν σαρκί. Εἴ τις δοκεῖ ἄλλος πεποιθέναι ἐν σαρκί, ἐγὼ μᾶλλον:
50003005 περιτομῇ ὀκταήμερος ἐκ γένους Ἰσραήλ φυλῆς Βενιαμίν, Ἑβραῖος ἐξ Ἑβραίων, κατὰ νόμον Φαρισαῖος,
50003006 κατὰ ζῆλος διώκων τὴν ἐκκλησίαν, κατὰ δικαιοσύνην τὴν ἐν νόμῳ γενόμενος ἄμεμπτος.
50003007 Ἀλλὰ ἅτινα ἦν μοι κέρδη, ταῦτα ἥγημαι διὰ τὸν ˚Χριστὸν ζημίαν.
50003008 Ἀλλὰ μενοῦνγε καὶ ἡγοῦμαι πάντα ζημίαν εἶναι, διὰ τὸ ὑπερέχον τῆς γνώσεως ˚Χριστοῦ ˚Ἰησοῦ τοῦ ˚Κυρίου μου, διʼ ὃν τὰ πάντα ἐζημιώθην καὶ ἡγοῦμαι σκύβαλα, ἵνα ˚Χριστὸν κερδήσω
50003009 καὶ εὑρεθῶ ἐν αὐτῷ, μὴ ἔχων ἐμὴν δικαιοσύνην τὴν ἐκ νόμου, ἀλλὰ τὴν διὰ πίστεως ˚Χριστοῦ, τὴν ἐκ ˚Θεοῦ δικαιοσύνην ἐπὶ τῇ πίστει,
50003010 τοῦ γνῶναι αὐτὸν, καὶ τὴν δύναμιν τῆς ἀναστάσεως αὐτοῦ, καὶ κοινωνίαν παθημάτων αὐτοῦ, συμμορφιζόμενος τῷ θανάτῳ αὐτοῦ,
50003011 εἴ πως καταντήσω εἰς τὴν ἐξανάστασιν τὴν ἐκ νεκρῶν.
50003012 Οὐχ ὅτι ἤδη ἔλαβον, ἢ ἤδη τετελείωμαι, διώκω δὲ, εἰ καὶ καταλάβω, ἐφʼ ᾧ καὶ κατελήμφθην ὑπὸ ˚Χριστοῦ ˚Ἰησοῦ.
50003013 Ἀδελφοί, ἐγὼ ἐμαυτὸν οὐ λογίζομαι κατειληφέναι, ἓν δέ: τὰ μὲν ὀπίσω ἐπιλανθανόμενος, τοῖς δὲ ἔμπροσθεν ἐπεκτεινόμενος,
50003014 κατὰ σκοπὸν διώκω εἰς τὸ βραβεῖον τῆς ἄνω κλήσεως τοῦ ˚Θεοῦ ἐν ˚Χριστῷ ˚Ἰησοῦ.
50003015 Ὅσοι οὖν τέλειοι, τοῦτο φρονῶμεν· καὶ εἴ τι ἑτέρως φρονεῖτε, καὶ τοῦτο ὁ ˚Θεὸς ὑμῖν ἀποκαλύψει.
50003016 Πλὴν εἰς ὃ ἐφθάσαμεν, τῷ αὐτῷ στοιχεῖν.
50003017 Συμμιμηταί μου γίνεσθε, ἀδελφοί, καὶ σκοπεῖτε τοὺς οὕτω περιπατοῦντας, καθὼς ἔχετε τύπον ἡμᾶς.
50003018 Πολλοὶ γὰρ περιπατοῦσιν, οὓς πολλάκις ἔλεγον ὑμῖν, νῦν δὲ καὶ κλαίων, λέγω, τοὺς ἐχθροὺς τοῦ σταυροῦ τοῦ ˚Χριστοῦ,
50003019 ὧν τὸ τέλος ἀπώλεια, ὧν ὁ ˚Θεὸς ἡ κοιλία, καὶ ἡ δόξα ἐν τῇ αἰσχύνῃ αὐτῶν, οἱ τὰ ἐπίγεια φρονοῦντες.
50003020 Ἡμῶν γὰρ τὸ πολίτευμα ἐν οὐρανοῖς ὑπάρχει, ἐξ οὗ καὶ Σωτῆρα ἀπεκδεχόμεθα, ˚Κύριον ˚Ἰησοῦν ˚Χριστόν,
50003021 ὃς μετασχηματίσει τὸ σῶμα τῆς ταπεινώσεως ἡμῶν, σύμμορφον τῷ σώματι τῆς δόξης αὐτοῦ, κατὰ τὴν ἐνέργειαν τοῦ δύνασθαι αὐτὸν, καὶ ὑποτάξαι αὑτῷ τὰ πάντα.
50004001 Ὥστε, ἀδελφοί μου ἀγαπητοὶ καὶ ἐπιπόθητοι, χαρὰ καὶ στέφανός μου, οὕτως στήκετε ἐν ˚Κυρίῳ, ἀγαπητοί.
50004002 Εὐοδίαν παρακαλῶ, καὶ Συντύχην παρακαλῶ, τὸ αὐτὸ φρονεῖν ἐν ˚Κυρίῳ.
50004003 Ναὶ, ἐρωτῶ καὶ σέ, γνήσιε σύζυγε, συλλαμβάνου αὐταῖς, αἵτινες ἐν τῷ εὐαγγελίῳ συνήθλησάν μοι, μετὰ καὶ Κλήμεντος καὶ τῶν λοιπῶν συνεργῶν μου, ὧν τὰ ὀνόματα ἐν βίβλῳ ζωῆς.
50004004 Χαίρετε ἐν ˚Κυρίῳ πάντοτε, πάλιν ἐρῶ, χαίρετε!
50004005 Τὸ ἐπιεικὲς ὑμῶν γνωσθήτω πᾶσιν ἀνθρώποις. Ὁ ˚Κύριος ἐγγύς.
50004006 Μηδὲν μεριμνᾶτε, ἀλλʼ ἐν παντὶ, τῇ προσευχῇ καὶ τῇ δεήσει μετὰ εὐχαριστίας, τὰ αἰτήματα ὑμῶν γνωριζέσθω πρὸς τὸν ˚Θεόν.
50004007 Καὶ ἡ εἰρήνη τοῦ ˚Θεοῦ ἡ ὑπερέχουσα πάντα νοῦν, φρουρήσει τὰς καρδίας ὑμῶν καὶ τὰ νοήματα ὑμῶν ἐν ˚Χριστῷ ˚Ἰησοῦ.
50004008 Τὸ λοιπόν, ἀδελφοί, ὅσα ἐστὶν ἀληθῆ, ὅσα σεμνά, ὅσα δίκαια, ὅσα ἁγνά, ὅσα προσφιλῆ, ὅσα εὔφημα, εἴ τις ἀρετὴ, καὶ εἴ τις ἔπαινος, ταῦτα λογίζεσθε.
50004009 Ἃ καὶ ἐμάθετε καὶ παρελάβετε, καὶ ἠκούσατε καὶ εἴδετε, ἐν ἐμοί, ταῦτα πράσσετε· καὶ ὁ ˚Θεὸς τῆς εἰρήνης ἔσται μεθʼ ὑμῶν.
50004010 Ἐχάρην δὲ ἐν ˚Κυρίῳ μεγάλως, ὅτι ἤδη ποτὲ ἀνεθάλετε τὸ ὑπὲρ ἐμοῦ φρονεῖν, ἐφʼ ᾧ καὶ ἐφρονεῖτε, ἠκαιρεῖσθε δέ.
50004011 Οὐχ ὅτι καθʼ ὑστέρησιν λέγω, ἐγὼ γὰρ ἔμαθον ἐν οἷς εἰμι αὐτάρκης εἶναι.
50004012 Οἶδα καὶ ταπεινοῦσθαι, οἶδα καὶ περισσεύειν. Ἐν παντὶ καὶ ἐν πᾶσιν μεμύημαι, καὶ χορτάζεσθαι καὶ πεινᾶν, καὶ περισσεύειν καὶ ὑστερεῖσθαι.
50004013 Πάντα ἰσχύω ἐν τῷ ἐνδυναμοῦντί με.
50004014 Πλὴν καλῶς ἐποιήσατε, συγκοινωνήσαντές μου τῇ θλίψει.
50004015 Οἴδατε δὲ καὶ ὑμεῖς, Φιλιππήσιοι, ὅτι ἐν ἀρχῇ τοῦ εὐαγγελίου, ὅτε ἐξῆλθον ἀπὸ Μακεδονίας, οὐδεμία μοι ἐκκλησία ἐκοινώνησεν εἰς λόγον δόσεως καὶ λήμψεως, εἰ μὴ ὑμεῖς μόνοι.
50004016 Ὅτι καὶ ἐν Θεσσαλονίκῃ, καὶ ἅπαξ καὶ δὶς, εἰς τὴν χρείαν μοι ἐπέμψατε.
50004017 Οὐχ ὅτι ἐπιζητῶ τὸ δόμα, ἀλλὰ ἐπιζητῶ τὸν καρπὸν τὸν πλεονάζοντα εἰς λόγον ὑμῶν.
50004018 Ἀπέχω δὲ πάντα, καὶ περισσεύω· πεπλήρωμαι, δεξάμενος παρὰ Ἐπαφροδίτου τὰ παρʼ ὑμῶν, ὀσμὴν εὐωδίας, θυσίαν δεκτήν, εὐάρεστον τῷ ˚Θεῷ.
50004019 Ὁ δὲ ˚Θεός μου πληρώσει πᾶσαν χρείαν ὑμῶν, κατὰ τὸ πλοῦτος αὐτοῦ ἐν δόξῃ ἐν ˚Χριστῷ ˚Ἰησοῦ.
50004020 Τῷ δὲ ˚Θεῷ καὶ Πατρὶ ἡμῶν ἡ δόξα εἰς τοὺς αἰῶνας τῶν αἰώνων. Ἀμήν!
50004021 Ἀσπάσασθε πάντα ἅγιον ἐν ˚Χριστῷ ˚Ἰησοῦ, ἀσπάζονται ὑμᾶς οἱ σὺν ἐμοὶ ἀδελφοί,
50004022 ἀσπάζονται ὑμᾶς πάντες οἱ ἅγιοι, μάλιστα δὲ οἱ ἐκ τῆς Καίσαρος οἰκίας.
50004023 Ἡ χάρις τοῦ ˚Κυρίου ˚Ἰησοῦ ˚Χριστοῦ μετὰ τοῦ πνεύματος ὑμῶν. Ἀμήν!
51001001 Παῦλος, ἀπόστολος ˚Χριστοῦ ˚Ἰησοῦ διὰ θελήματος ˚Θεοῦ, καὶ Τιμόθεος ὁ ἀδελφὸς,
51001002 τοῖς ἐν Κολοσσαῖς ἁγίοις, καὶ πιστοῖς ἀδελφοῖς ἐν ˚Χριστῷ: χάρις ὑμῖν καὶ εἰρήνη ἀπὸ ˚Θεοῦ Πατρὸς ἡμῶν καὶ ˚Κυρίου ˚Ἰησοῦ ˚Χριστοῦ.
51001003 Εὐχαριστοῦμεν τῷ ˚Θεῷ Πατρὶ τοῦ ˚Κυρίου ἡμῶν ˚Ἰησοῦ ˚Χριστοῦ, πάντοτε περὶ ὑμῶν προσευχόμενοι,
51001004 ἀκούσαντες τὴν πίστιν ὑμῶν ἐν ˚Χριστῷ ˚Ἰησοῦ, καὶ τὴν ἀγάπην ἣν ἔχετε εἰς πάντας τοὺς ἁγίους,
51001005 διὰ τὴν ἐλπίδα τὴν ἀποκειμένην ὑμῖν ἐν τοῖς οὐρανοῖς, ἣν προηκούσατε ἐν τῷ λόγῳ τῆς ἀληθείας, τοῦ εὐαγγελίου,
51001006 τοῦ παρόντος εἰς ὑμᾶς, καθὼς καὶ ἐν παντὶ τῷ κόσμῳ, ἐστὶν καρποφορούμενον καὶ αὐξανόμενον, καθὼς καὶ ἐν ὑμῖν, ἀφʼ ἧς ἡμέρας ἠκούσατε καὶ ἐπέγνωτε τὴν χάριν τοῦ ˚Θεοῦ ἐν ἀληθείᾳ·
51001007 καθὼς ἐμάθετε ἀπὸ Ἐπαφρᾶ, τοῦ ἀγαπητοῦ συνδούλου ἡμῶν, ὅς ἐστιν πιστὸς ὑπὲρ ἡμῶν διάκονος τοῦ ˚Χριστοῦ,
51001008 ὁ καὶ δηλώσας ἡμῖν τὴν ὑμῶν ἀγάπην ἐν ˚Πνεύματι.
51001009 Διὰ τοῦτο καὶ ἡμεῖς, ἀφʼ ἧς ἡμέρας ἠκούσαμεν, οὐ παυόμεθα ὑπὲρ ὑμῶν προσευχόμενοι καὶ αἰτούμενοι, ἵνα πληρωθῆτε τὴν ἐπίγνωσιν τοῦ θελήματος αὐτοῦ ἐν πάσῃ σοφίᾳ καὶ συνέσει πνευματικῇ,
51001010 περιπατῆσαι ἀξίως τοῦ ˚Κυρίου εἰς πᾶσαν ἀρεσκείαν, ἐν παντὶ ἔργῳ ἀγαθῷ καρποφοροῦντες, καὶ αὐξανόμενοι τῇ ἐπιγνώσει τοῦ ˚Θεοῦ,
51001011 ἐν πάσῃ δυνάμει δυναμούμενοι, κατὰ τὸ κράτος τῆς δόξης αὐτοῦ, εἰς πᾶσαν ὑπομονὴν καὶ μακροθυμίαν μετὰ χαρᾶς,
51001012 εὐχαριστοῦντες τῷ Πατρὶ, τῷ ἱκανώσαντι ὑμᾶς εἰς τὴν μερίδα τοῦ κλήρου τῶν ἁγίων ἐν τῷ φωτί,
51001013 ὃς ἐρρύσατο ἡμᾶς ἐκ τῆς ἐξουσίας τοῦ σκότους, καὶ μετέστησεν εἰς τὴν βασιλείαν τοῦ Υἱοῦ τῆς ἀγάπης αὐτοῦ,
51001014 ἐν ᾧ ἔχομεν τὴν ἀπολύτρωσιν, τὴν ἄφεσιν τῶν ἁμαρτιῶν·
51001015 ὅς ἐστιν εἰκὼν τοῦ ˚Θεοῦ τοῦ ἀοράτου, πρωτότοκος πάσης κτίσεως,
51001016 ὅτι ἐν αὐτῷ ἐκτίσθη τὰ πάντα ἐν τοῖς οὐρανοῖς καὶ ἐπὶ τῆς γῆς, τὰ ὁρατὰ καὶ τὰ ἀόρατα, εἴτε θρόνοι, εἴτε κυριότητες, εἴτε ἀρχαὶ, εἴτε ἐξουσίαι· τὰ πάντα διʼ αὐτοῦ καὶ εἰς αὐτὸν ἔκτισται.
51001017 Καὶ αὐτός ἐστιν πρὸ πάντων, καὶ τὰ πάντα ἐν αὐτῷ συνέστηκεν.
51001018 Καὶ αὐτός ἐστιν ἡ κεφαλὴ τοῦ σώματος, τῆς ἐκκλησίας, ὅς ἐστιν ἡ ἀρχή, πρωτότοκος ἐκ τῶν νεκρῶν, ἵνα γένηται ἐν πᾶσιν αὐτὸς πρωτεύων,
51001019 ὅτι ἐν αὐτῷ εὐδόκησεν πᾶν τὸ πλήρωμα κατοικῆσαι,
51001020 καὶ διʼ αὐτοῦ ἀποκαταλλάξαι τὰ πάντα εἰς αὐτόν, εἰρηνοποιήσας διὰ τοῦ αἵματος τοῦ σταυροῦ αὐτοῦ διʼ αὐτοῦ, εἴτε τὰ ἐπὶ τῆς γῆς, εἴτε τὰ ἐν τοῖς οὐρανοῖς.
51001021 Καὶ ὑμᾶς ποτε ὄντας ἀπηλλοτριωμένους καὶ ἐχθροὺς τῇ διανοίᾳ ἐν τοῖς ἔργοις τοῖς πονηροῖς,
51001022 νυνὶ δὲ ἀποκατήλλαξεν ἐν τῷ σώματι τῆς σαρκὸς αὐτοῦ, διὰ τοῦ θανάτου, παραστῆσαι ὑμᾶς ἁγίους, καὶ ἀμώμους, καὶ ἀνεγκλήτους, κατενώπιον αὐτοῦ–
51001023 εἴ γε ἐπιμένετε τῇ πίστει, τεθεμελιωμένοι καὶ ἑδραῖοι, καὶ μὴ μετακινούμενοι ἀπὸ τῆς ἐλπίδος τοῦ εὐαγγελίου οὗ ἠκούσατε, τοῦ κηρυχθέντος ἐν πάσῃ κτίσει τῇ ὑπὸ τὸν οὐρανόν, οὗ ἐγενόμην ἐγὼ Παῦλος διάκονος.
51001024 Νῦν χαίρω ἐν τοῖς παθήμασιν ὑπὲρ ὑμῶν, καὶ ἀνταναπληρῶ τὰ ὑστερήματα τῶν θλίψεων τοῦ ˚Χριστοῦ ἐν τῇ σαρκί μου ὑπὲρ τοῦ σώματος αὐτοῦ, ὅ ἐστιν ἡ ἐκκλησία,
51001025 ἧς ἐγενόμην ἐγὼ διάκονος, κατὰ τὴν οἰκονομίαν τοῦ ˚Θεοῦ, τὴν δοθεῖσάν μοι εἰς ὑμᾶς πληρῶσαι τὸν λόγον τοῦ ˚Θεοῦ,
51001026 τὸ μυστήριον τὸ ἀποκεκρυμμένον ἀπὸ τῶν αἰώνων καὶ ἀπὸ τῶν γενεῶν, νῦν δὲ ἐφανερώθη τοῖς ἁγίοις αὐτοῦ,
51001027 οἷς ἠθέλησεν ὁ ˚Θεὸς, γνωρίσαι τί τὸ πλοῦτος τῆς δόξης τοῦ μυστηρίου τούτου ἐν τοῖς ἔθνεσιν, ὅ ἐστιν ˚Χριστὸς ἐν ὑμῖν, ἡ ἐλπὶς τῆς δόξης·
51001028 ὃν ἡμεῖς καταγγέλλομεν, νουθετοῦντες πάντα ἄνθρωπον καὶ διδάσκοντες πάντα ἄνθρωπον ἐν πάσῃ σοφίᾳ, ἵνα παραστήσωμεν πάντα ἄνθρωπον τέλειον ἐν ˚Χριστῷ.
51001029 Εἰς ὃ καὶ κοπιῶ, ἀγωνιζόμενος κατὰ τὴν ἐνέργειαν αὐτοῦ, τὴν ἐνεργουμένην ἐν ἐμοὶ ἐν δυνάμει.
51002001 Θέλω γὰρ ὑμᾶς εἰδέναι, ἡλίκον ἀγῶνα ἔχω ὑπὲρ ὑμῶν καὶ τῶν ἐν Λαοδικείᾳ, καὶ ὅσοι οὐχ ἑόρακαν τὸ πρόσωπόν μου ἐν σαρκί,
51002002 ἵνα παρακληθῶσιν αἱ καρδίαι αὐτῶν, συμβιβασθέντες ἐν ἀγάπῃ, καὶ εἰς πᾶν πλοῦτος τῆς πληροφορίας τῆς συνέσεως, εἰς ἐπίγνωσιν τοῦ μυστηρίου τοῦ ˚Θεοῦ, ˚Χριστοῦ,
51002003 ἐν ᾧ εἰσιν πάντες οἱ θησαυροὶ τῆς σοφίας καὶ γνώσεως ἀπόκρυφοι.
51002004 Τοῦτο λέγω, ἵνα μηδεὶς ὑμᾶς παραλογίζηται ἐν πιθανολογίᾳ.
51002005 Εἰ γὰρ καὶ τῇ σαρκὶ ἄπειμι, ἀλλὰ τῷ πνεύματι σὺν ὑμῖν εἰμι, χαίρων καὶ βλέπων ὑμῶν τὴν τάξιν, καὶ τὸ στερέωμα τῆς εἰς ˚Χριστὸν πίστεως ὑμῶν.
51002006 Ὡς οὖν παρελάβετε τὸν ˚Χριστὸν ˚Ἰησοῦν τὸν ˚Κύριον, ἐν αὐτῷ περιπατεῖτε,
51002007 ἐρριζωμένοι καὶ ἐποικοδομούμενοι ἐν αὐτῷ, καὶ βεβαιούμενοι τῇ πίστει, καθὼς ἐδιδάχθητε, περισσεύοντες ἐν εὐχαριστίᾳ.
51002008 Βλέπετε, μή τις ὑμᾶς ἔσται ὁ συλαγωγῶν διὰ τῆς φιλοσοφίας καὶ κενῆς ἀπάτης, κατὰ τὴν παράδοσιν τῶν ἀνθρώπων, κατὰ τὰ στοιχεῖα τοῦ κόσμου, καὶ οὐ κατὰ ˚Χριστόν.
51002009 Ὅτι ἐν αὐτῷ κατοικεῖ πᾶν τὸ πλήρωμα τῆς Θεότητος σωματικῶς,
51002010 καὶ ἐστὲ ἐν αὐτῷ πεπληρωμένοι, ὅς ἐστιν ἡ κεφαλὴ πάσης ἀρχῆς καὶ ἐξουσίας,
51002011 ἐν ᾧ καὶ περιετμήθητε περιτομῇ ἀχειροποιήτῳ, ἐν τῇ ἀπεκδύσει τοῦ σώματος τῆς σαρκός, ἐν τῇ περιτομῇ τοῦ ˚Χριστοῦ,
51002012 συνταφέντες αὐτῷ ἐν τῷ βαπτισμῷ, ἐν ᾧ καὶ συνηγέρθητε, διὰ τῆς πίστεως τῆς ἐνεργείας τοῦ ˚Θεοῦ, τοῦ ἐγείραντος αὐτὸν ἐκ νεκρῶν.
51002013 Καὶ ὑμᾶς νεκροὺς ὄντας ἐν τοῖς παραπτώμασιν καὶ τῇ ἀκροβυστίᾳ τῆς σαρκὸς ὑμῶν, συνεζωοποίησεν ἡμᾶς σὺν αὐτῷ, χαρισάμενος ἡμῖν πάντα τὰ παραπτώματα,
51002014 ἐξαλείψας τὸ καθʼ ἡμῶν χειρόγραφον τοῖς δόγμασιν, ὃ ἦν ὑπεναντίον ἡμῖν, καὶ αὐτὸ ἦρκεν ἐκ τοῦ μέσου, προσηλώσας αὐτὸ τῷ σταυρῷ·
51002015 ἀπεκδυσάμενος τὰς ἀρχὰς καὶ τὰς ἐξουσίας, ἐδειγμάτισεν ἐν παρρησίᾳ, θριαμβεύσας αὐτοὺς ἐν αὐτῷ.
51002016 Μὴ οὖν τις ὑμᾶς κρινέτω ἐν βρώσει, καὶ ἐν πόσει, ἢ ἐν μέρει ἑορτῆς, ἢ νεομηνίας, ἢ Σαββάτων,
51002017 ἅ ἐστιν σκιὰ τῶν μελλόντων, τὸ δὲ σῶμα τοῦ ˚Χριστοῦ.
51002018 Μηδεὶς ὑμᾶς καταβραβευέτω, θέλων ἐν ταπεινοφροσύνῃ, καὶ θρησκείᾳ τῶν ἀγγέλων, ἃ ἑόρακεν ἐμβατεύων, εἰκῇ φυσιούμενος ὑπὸ τοῦ νοὸς τῆς σαρκὸς αὐτοῦ,
51002019 καὶ οὐ κρατῶν τὴν κεφαλήν, ἐξ οὗ πᾶν τὸ σῶμα διὰ τῶν ἁφῶν καὶ συνδέσμων ἐπιχορηγούμενον καὶ συμβιβαζόμενον, αὔξει τὴν αὔξησιν τοῦ ˚Θεοῦ.
51002020 Εἰ ἀπεθάνετε σὺν ˚Χριστῷ ἀπὸ τῶν στοιχείων τοῦ κόσμου, τί ὡς ζῶντες ἐν κόσμῳ δογματίζεσθε:
51002021 “Μὴ ἅψῃ! Μηδὲ γεύσῃ! Μηδὲ θίγῃς!”
51002022 Ἅ ἐστιν πάντα εἰς φθορὰν τῇ ἀποχρήσει, κατὰ τὰ ἐντάλματα καὶ διδασκαλίας τῶν ἀνθρώπων;
51002023 Ἅτινά ἐστιν λόγον μὲν ἔχοντα σοφίας ἐν ἐθελοθρησκίᾳ καὶ ταπεινοφροσύνῃ, καὶ ἀφειδίᾳ σώματος, οὐκ ἐν τιμῇ τινι πρὸς πλησμονὴν τῆς σαρκός.
51003001 Εἰ οὖν συνηγέρθητε τῷ ˚Χριστῷ, τὰ ἄνω ζητεῖτε, οὗ ὁ ˚Χριστός ἐστιν, ἐν δεξιᾷ τοῦ ˚Θεοῦ καθήμενος.
51003002 Τὰ ἄνω φρονεῖτε, μὴ τὰ ἐπὶ τῆς γῆς.
51003003 Ἀπεθάνετε γάρ, καὶ ἡ ζωὴ ὑμῶν κέκρυπται σὺν τῷ ˚Χριστῷ ἐν τῷ ˚Θεῷ.
51003004 Ὅταν ὁ ˚Χριστὸς φανερωθῇ, ἡ ζωὴ ὑμῶν, τότε καὶ ὑμεῖς σὺν αὐτῷ φανερωθήσεσθε ἐν δόξῃ.
51003005 Νεκρώσατε οὖν τὰ μέλη τὰ ἐπὶ τῆς γῆς: πορνείαν, ἀκαθαρσίαν, πάθος, ἐπιθυμίαν κακήν, καὶ τὴν πλεονεξίαν, ἥτις ἐστὶν εἰδωλολατρία,
51003006 διʼ ἃ ἔρχεται ἡ ὀργὴ τοῦ ˚Θεοῦ,
51003007 ἐν οἷς καὶ ὑμεῖς περιεπατήσατέ ποτε ὅτε ἐζῆτε ἐν τούτοις.
51003008 Νυνὶ δὲ ἀπόθεσθε καὶ ὑμεῖς τὰ πάντα: ὀργήν, θυμόν, κακίαν, βλασφημίαν, αἰσχρολογίαν ἐκ τοῦ στόματος ὑμῶν.
51003009 Μὴ ψεύδεσθε εἰς ἀλλήλους, ἀπεκδυσάμενοι τὸν παλαιὸν ἄνθρωπον σὺν ταῖς πράξεσιν αὐτοῦ,
51003010 καὶ ἐνδυσάμενοι τὸν νέον, τὸν ἀνακαινούμενον εἰς ἐπίγνωσιν, κατʼ εἰκόνα τοῦ κτίσαντος αὐτόν,
51003011 ὅπου οὐκ ἔνι Ἕλλην καὶ Ἰουδαῖος, περιτομὴ καὶ ἀκροβυστία, βάρβαρος, Σκύθης, δοῦλος, ἐλεύθερος, ἀλλὰ πάντα καὶ ἐν πᾶσιν ˚Χριστός.
51003012 Ἐνδύσασθε οὖν, ὡς ἐκλεκτοὶ τοῦ ˚Θεοῦ ἅγιοι καὶ ἠγαπημένοι, σπλάγχνα οἰκτιρμοῦ, χρηστότητα, ταπεινοφροσύνην, πραΰτητα, μακροθυμίαν,
51003013 ἀνεχόμενοι ἀλλήλων καὶ χαριζόμενοι ἑαυτοῖς, ἐάν τις πρός τινα ἔχῃ μομφήν· καθὼς καὶ ὁ ˚Κύριος ἐχαρίσατο ὑμῖν, οὕτως καὶ ὑμεῖς.
51003014 Ἐπὶ πᾶσιν δὲ τούτοις, τὴν ἀγάπην, ὅ ἐστιν σύνδεσμος τῆς τελειότητος.
51003015 Καὶ ἡ εἰρήνη τοῦ ˚Χριστοῦ βραβευέτω ἐν ταῖς καρδίαις ὑμῶν, εἰς ἣν καὶ ἐκλήθητε ἐν ἑνὶ σώματι· καὶ εὐχάριστοι γίνεσθε.
51003016 Ὁ λόγος τοῦ ˚Χριστοῦ ἐνοικείτω ἐν ὑμῖν πλουσίως ἐν πάσῃ σοφίᾳ, διδάσκοντες καὶ νουθετοῦντες ἑαυτοὺς ψαλμοῖς, ὕμνοις, ᾠδαῖς πνευματικαῖς, ἐν τῇ χάριτι ᾄδοντες ἐν ταῖς καρδίαις ὑμῶν τῷ ˚Θεῷ.
51003017 Καὶ πᾶν, ὅ τι ἐὰν ποιῆτε ἐν λόγῳ ἢ ἐν ἔργῳ, πάντα ἐν ὀνόματι ˚Κυρίου ˚Ἰησοῦ, εὐχαριστοῦντες τῷ ˚Θεῷ Πατρὶ διʼ αὐτοῦ.
51003018 Αἱ γυναῖκες, ὑποτάσσεσθε τοῖς ἀνδράσιν, ὡς ἀνῆκεν ἐν ˚Κυρίῳ.
51003019 Οἱ ἄνδρες, ἀγαπᾶτε τὰς γυναῖκας, καὶ μὴ πικραίνεσθε πρὸς αὐτάς.
51003020 Τὰ τέκνα, ὑπακούετε τοῖς γονεῦσιν κατὰ πάντα, τοῦτο γὰρ εὐάρεστόν ἐστιν ἐν ˚Κυρίῳ.
51003021 Οἱ πατέρες, μὴ ἐρεθίζετε τὰ τέκνα ὑμῶν, ἵνα μὴ ἀθυμῶσιν.
51003022 Οἱ δοῦλοι, ὑπακούετε κατὰ πάντα τοῖς κατὰ σάρκα κυρίοις, μὴ ἐν ὀφθαλμοδουλίᾳ, ὡς ἀνθρωπάρεσκοι, ἀλλʼ ἐν ἁπλότητι καρδίας, φοβούμενοι τὸν ˚Κύριον.
51003023 Ὃ ἐὰν ποιῆτε, ἐκ ψυχῆς ἐργάζεσθε, ὡς τῷ ˚Κυρίῳ καὶ οὐκ ἀνθρώποις,
51003024 εἰδότες ὅτι ἀπὸ ˚Κυρίου ἀπολήμψεσθε τὴν ἀνταπόδοσιν τῆς κληρονομίας. Τῷ ˚Κυρίῳ ˚Χριστῷ δουλεύετε.
51003025 Ὁ γὰρ ἀδικῶν, κομίσεται ὃ ἠδίκησεν, καὶ οὐκ ἔστιν προσωπολημψία.
51004001 Οἱ κύριοι, τὸ δίκαιον καὶ τὴν ἰσότητα τοῖς δούλοις παρέχεσθε, εἰδότες ὅτι καὶ ὑμεῖς ἔχετε ˚Κύριον ἐν οὐρανῷ.
51004002 Τῇ προσευχῇ προσκαρτερεῖτε, γρηγοροῦντες ἐν αὐτῇ ἐν εὐχαριστίᾳ,
51004003 προσευχόμενοι ἅμα καὶ περὶ ἡμῶν, ἵνα ὁ ˚Θεὸς ἀνοίξῃ ἡμῖν θύραν τοῦ λόγου, λαλῆσαι τὸ μυστήριον τοῦ ˚Χριστοῦ, διʼ ὃ καὶ δέδεμαι,
51004004 ἵνα φανερώσω αὐτὸ, ὡς δεῖ με λαλῆσαι.
51004005 Ἐν σοφίᾳ περιπατεῖτε πρὸς τοὺς ἔξω, τὸν καιρὸν ἐξαγοραζόμενοι.
51004006 Ὁ λόγος ὑμῶν πάντοτε ἐν χάριτι, ἅλατι ἠρτυμένος, εἰδέναι πῶς δεῖ ὑμᾶς ἑνὶ ἑκάστῳ ἀποκρίνεσθαι.
51004007 Τὰ κατʼ ἐμὲ πάντα γνωρίσει ὑμῖν Τυχικὸς, ὁ ἀγαπητὸς ἀδελφὸς, καὶ πιστὸς διάκονος, καὶ σύνδουλος ἐν ˚Κυρίῳ,
51004008 ὃν ἔπεμψα πρὸς ὑμᾶς εἰς αὐτὸ τοῦτο, ἵνα γνῶτε τὰ περὶ ὑμῶν, καὶ παρακαλέσῃ τὰς καρδίας ὑμῶν,
51004009 σὺν Ὀνησίμῳ, τῷ πιστῷ καὶ ἀγαπητῷ ἀδελφῷ, ὅς ἐστιν ἐξ ὑμῶν. Πάντα ὑμῖν γνωρίσουσιν τὰ ὧδε.
51004010 Ἀσπάζεται ὑμᾶς Ἀρίσταρχος, ὁ συναιχμάλωτός μου, καὶ Μᾶρκος, ὁ ἀνεψιὸς Βαρναβᾶ ( περὶ οὗ ἐλάβετε ἐντολάς, ἐὰν ἔλθῃ πρὸς ὑμᾶς, δέξασθε αὐτόν ),
51004011 καὶ Ἰησοῦς, ὁ λεγόμενος Ἰοῦστος, οἱ ὄντες ἐκ περιτομῆς οὗτοι, μόνοι συνεργοὶ εἰς τὴν Βασιλείαν τοῦ ˚Θεοῦ, οἵτινες ἐγενήθησάν μοι παρηγορία.
51004012 Ἀσπάζεται ὑμᾶς Ἐπαφρᾶς, ὁ ἐξ ὑμῶν, δοῦλος ˚Χριστοῦ ˚Ἰησοῦ, πάντοτε ἀγωνιζόμενος ὑπὲρ ὑμῶν ἐν ταῖς προσευχαῖς, ἵνα σταθῆτε τέλειοι καὶ πεπληροφορημένοι ἐν παντὶ θελήματι τοῦ ˚Θεοῦ.
51004013 Μαρτυρῶ γὰρ αὐτῷ ὅτι ἔχει πολὺν πόνον ὑπὲρ ὑμῶν, καὶ τῶν ἐν Λαοδικείᾳ, καὶ τῶν ἐν Ἱεραπόλει.
51004014 Ἀσπάζεται ὑμᾶς Λουκᾶς, ὁ ἰατρὸς ὁ ἀγαπητὸς, καὶ Δημᾶς.
51004015 Ἀσπάσασθε τοὺς ἐν Λαοδικείᾳ ἀδελφοὺς, καὶ Νύμφαν, καὶ τὴν κατʼ οἶκον αὐτῶν ἐκκλησίαν.
51004016 Καὶ ὅταν ἀναγνωσθῇ παρʼ ὑμῖν ἡ ἐπιστολή, ποιήσατε ἵνα καὶ ἐν τῇ Λαοδικέων ἐκκλησίᾳ ἀναγνωσθῇ, καὶ τὴν ἐκ Λαοδικείας ἵνα καὶ ὑμεῖς ἀναγνῶτε.
51004017 Καὶ εἴπατε Ἀρχίππῳ, “Βλέπε τὴν διακονίαν ἣν παρέλαβες ἐν ˚Κυρίῳ, ἵνα αὐτὴν πληροῖς.”
51004018 Ὁ ἀσπασμὸς τῇ ἐμῇ χειρὶ, Παύλου. Μνημονεύετέ μου τῶν δεσμῶν. Ἡ χάρις μεθʼ ὑμῶν.
52001001 Παῦλος, καὶ Σιλουανὸς, καὶ Τιμόθεος, τῇ ἐκκλησίᾳ Θεσσαλονικέων ἐν ˚Θεῷ Πατρὶ καὶ ˚Κυρίῳ ˚Ἰησοῦ ˚Χριστῷ: χάρις ὑμῖν καὶ εἰρήνη.
52001002 Εὐχαριστοῦμεν τῷ ˚Θεῷ πάντοτε περὶ πάντων ὑμῶν, μνείαν ποιούμενοι ἐπὶ τῶν προσευχῶν ἡμῶν, ἀδιαλείπτως
52001003 μνημονεύοντες ὑμῶν τοῦ ἔργου τῆς πίστεως, καὶ τοῦ κόπου τῆς ἀγάπης, καὶ τῆς ὑπομονῆς τῆς ἐλπίδος τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ ˚Χριστοῦ, ἔμπροσθεν τοῦ ˚Θεοῦ καὶ Πατρὸς ἡμῶν,
52001004 εἰδότες, ἀδελφοὶ ἠγαπημένοι ὑπὸ τοῦ ˚Θεοῦ, τὴν ἐκλογὴν ὑμῶν,
52001005 ὅτι τὸ εὐαγγέλιον ἡμῶν οὐκ ἐγενήθη εἰς ὑμᾶς ἐν λόγῳ μόνον, ἀλλὰ καὶ ἐν δυνάμει, καὶ ἐν ˚Πνεύματι Ἁγίῳ, καὶ πληροφορίᾳ πολλῇ, καθὼς οἴδατε οἷοι ἐγενήθημεν ὑμῖν διʼ ὑμᾶς.
52001006 Καὶ ὑμεῖς μιμηταὶ ἡμῶν ἐγενήθητε καὶ τοῦ ˚Κυρίου, δεξάμενοι τὸν λόγον ἐν θλίψει πολλῇ μετὰ χαρᾶς ˚Πνεύματος Ἁγίου,
52001007 ὥστε γενέσθαι ὑμᾶς τύπους πᾶσιν τοῖς πιστεύουσιν ἐν τῇ Μακεδονίᾳ καὶ ἐν τῇ Ἀχαΐᾳ.
52001008 Ἀφʼ ὑμῶν γὰρ ἐξήχηται ὁ λόγος τοῦ ˚Κυρίου, οὐ μόνον ἐν τῇ Μακεδονίᾳ καὶ ἐν τῇ Ἀχαΐᾳ, ἀλλʼ ἐν παντὶ τόπῳ ἡ πίστις ὑμῶν ἡ πρὸς τὸν ˚Θεὸν ἐξελήλυθεν, ὥστε μὴ χρείαν ἔχειν ἡμᾶς λαλεῖν τι.
52001009 Αὐτοὶ γὰρ περὶ ἡμῶν ἀπαγγέλλουσιν ὁποίαν εἴσοδον ἔσχομεν πρὸς ὑμᾶς, καὶ πῶς ἐπεστρέψατε πρὸς τὸν ˚Θεὸν ἀπὸ τῶν εἰδώλων, δουλεύειν ˚Θεῷ ζῶντι καὶ ἀληθινῷ,
52001010 καὶ ἀναμένειν τὸν Υἱὸν αὐτοῦ ἐκ τῶν οὐρανῶν, ὃν ἤγειρεν ἐκ τῶν νεκρῶν, ˚Ἰησοῦν, τὸν ῥυόμενον ἡμᾶς ἐκ τῆς ὀργῆς τῆς ἐρχομένης.
52002001 Αὐτοὶ γὰρ οἴδατε, ἀδελφοί, τὴν εἴσοδον ἡμῶν τὴν πρὸς ὑμᾶς, ὅτι οὐ κενὴ γέγονεν,
52002002 ἀλλὰ προπαθόντες καὶ ὑβρισθέντες, καθὼς οἴδατε, ἐν Φιλίπποις ἐπαρρησιασάμεθα ἐν τῷ ˚Θεῷ ἡμῶν, λαλῆσαι πρὸς ὑμᾶς τὸ εὐαγγέλιον τοῦ ˚Θεοῦ ἐν πολλῷ ἀγῶνι.
52002003 Ἡ γὰρ παράκλησις ἡμῶν οὐκ ἐκ πλάνης, οὐδὲ ἐξ ἀκαθαρσίας, οὐδὲ ἐν δόλῳ,
52002004 ἀλλὰ καθὼς δεδοκιμάσμεθα ὑπὸ τοῦ ˚Θεοῦ, πιστευθῆναι τὸ εὐαγγέλιον, οὕτως λαλοῦμεν, οὐχ ὡς ἀνθρώποις ἀρέσκοντες, ἀλλὰ ˚Θεῷ, τῷ δοκιμάζοντι τὰς καρδίας ἡμῶν.
52002005 Οὔτε γάρ ποτε ἐν λόγῳ κολακείας ἐγενήθημεν, καθὼς οἴδατε, οὔτε ἐν προφάσει πλεονεξίας ( ˚Θεὸς μάρτυς )
52002006 οὔτε ζητοῦντες ἐξ ἀνθρώπων δόξαν, οὔτε ἀφʼ ὑμῶν, οὔτε ἀπʼ ἄλλων,
52002007 δυνάμενοι ἐν βάρει εἶναι, ὡς ˚Χριστοῦ ἀπόστολοι. Ἀλλὰ ἐγενήθημεν νήπιοι ἐν μέσῳ ὑμῶν, ὡς ἐὰν τροφὸς θάλπῃ τὰ ἑαυτῆς τέκνα.
52002008 Οὕτως ὁμειρόμενοι ὑμῶν, εὐδοκοῦμεν μεταδοῦναι ὑμῖν, οὐ μόνον τὸ εὐαγγέλιον τοῦ ˚Θεοῦ, ἀλλὰ καὶ τὰς ἑαυτῶν ψυχάς, διότι ἀγαπητοὶ ἡμῖν ἐγενήθητε.
52002009 Μνημονεύετε γάρ, ἀδελφοί, τὸν κόπον ἡμῶν καὶ τὸν μόχθον· νυκτὸς καὶ ἡμέρας ἐργαζόμενοι, πρὸς τὸ μὴ ἐπιβαρῆσαί τινα ὑμῶν, ἐκηρύξαμεν εἰς ὑμᾶς τὸ εὐαγγέλιον τοῦ ˚Θεοῦ.
52002010 Ὑμεῖς μάρτυρες καὶ ὁ ˚Θεός, ὡς ὁσίως, καὶ δικαίως, καὶ ἀμέμπτως, ὑμῖν τοῖς πιστεύουσιν ἐγενήθημεν,
52002011 καθάπερ οἴδατε ὡς ἕνα ἕκαστον ὑμῶν, ὡς πατὴρ τέκνα ἑαυτοῦ,
52002012 παρακαλοῦντες ὑμᾶς, καὶ παραμυθούμενοι, καὶ μαρτυρόμενοι, εἰς τὸ περιπατεῖν ὑμᾶς ἀξίως τοῦ ˚Θεοῦ, τοῦ καλέσαντος ὑμᾶς εἰς τὴν ἑαυτοῦ βασιλείαν καὶ δόξαν.
52002013 Καὶ διὰ τοῦτο καὶ ἡμεῖς εὐχαριστοῦμεν τῷ ˚Θεῷ ἀδιαλείπτως ὅτι παραλαβόντες λόγον ἀκοῆς παρʼ ἡμῶν τοῦ ˚Θεοῦ, ἐδέξασθε οὐ λόγον ἀνθρώπων, ἀλλὰ καθὼς ἀληθῶς ἐστὶν, λόγον ˚Θεοῦ, ὃς καὶ ἐνεργεῖται ἐν ὑμῖν τοῖς πιστεύουσιν.
52002014 Ὑμεῖς γὰρ μιμηταὶ ἐγενήθητε, ἀδελφοί, τῶν ἐκκλησιῶν τοῦ ˚Θεοῦ, τῶν οὐσῶν ἐν τῇ Ἰουδαίᾳ ἐν ˚Χριστῷ ˚Ἰησοῦ, ὅτι τὰ αὐτὰ ἐπάθετε, καὶ ὑμεῖς ὑπὸ τῶν ἰδίων συμφυλετῶν, καθὼς καὶ αὐτοὶ ὑπὸ τῶν Ἰουδαίων,
52002015 τῶν καὶ τὸν ˚Κύριον ἀποκτεινάντων ˚Ἰησοῦν, καὶ τοὺς προφήτας, καὶ ἡμᾶς ἐκδιωξάντων, καὶ ˚Θεῷ μὴ ἀρεσκόντων, καὶ πᾶσιν ἀνθρώποις ἐναντίων,
52002016 κωλυόντων ἡμᾶς τοῖς ἔθνεσιν λαλῆσαι, ἵνα σωθῶσιν, εἰς τὸ ἀναπληρῶσαι αὐτῶν τὰς ἁμαρτίας πάντοτε. Ἔφθασεν δὲ ἐπʼ αὐτοὺς ἡ ὀργὴ εἰς τέλος.
52002017 Ἡμεῖς δέ, ἀδελφοί, ἀπορφανισθέντες ἀφʼ ὑμῶν, πρὸς καιρὸν ὥρας, προσώπῳ οὐ καρδίᾳ, περισσοτέρως ἐσπουδάσαμεν τὸ πρόσωπον ὑμῶν ἰδεῖν ἐν πολλῇ ἐπιθυμίᾳ.
52002018 Διότι ἠθελήσαμεν ἐλθεῖν πρὸς ὑμᾶς, ἐγὼ μὲν Παῦλος, καὶ ἅπαξ καὶ δίς, καὶ ἐνέκοψεν ἡμᾶς ὁ Σατανᾶς.
52002019 Τίς γὰρ ἡμῶν ἐλπὶς ἢ χαρὰ ἢ στέφανος καυχήσεως; Ἢ οὐχὶ καὶ ὑμεῖς, ἔμπροσθεν τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ, ἐν τῇ αὐτοῦ παρουσίᾳ;
52002020 Ὑμεῖς γάρ ἐστε ἡ δόξα ἡμῶν, καὶ ἡ χαρά.
52003001 Διὸ μηκέτι στέγοντες, εὐδοκήσαμεν καταλειφθῆναι ἐν Ἀθήναις μόνοι,
52003002 καὶ ἐπέμψαμεν Τιμόθεον, τὸν ἀδελφὸν ἡμῶν, καὶ διάκονον τοῦ ˚Θεοῦ ἐν τῷ εὐαγγελίῳ τοῦ ˚Χριστοῦ, εἰς τὸ στηρίξαι ὑμᾶς καὶ παρακαλέσαι, ὑπὲρ τῆς πίστεως ὑμῶν,
52003003 τὸ μηδένα σαίνεσθαι ἐν ταῖς θλίψεσιν ταύταις. Αὐτοὶ γὰρ οἴδατε ὅτι εἰς τοῦτο κείμεθα.
52003004 Καὶ γὰρ ὅτε πρὸς ὑμᾶς ἦμεν, προελέγομεν ὑμῖν ὅτι μέλλομεν θλίβεσθαι, καθὼς καὶ ἐγένετο καὶ οἴδατε.
52003005 Διὰ τοῦτο κἀγὼ μηκέτι στέγων, ἔπεμψα εἰς τὸ γνῶναι τὴν πίστιν ὑμῶν, μή πως ἐπείρασεν ὑμᾶς ὁ πειράζων, καὶ εἰς κενὸν γένηται ὁ κόπος ἡμῶν.
52003006 Ἄρτι δὲ ἐλθόντος Τιμοθέου πρὸς ἡμᾶς ἀφʼ ὑμῶν, καὶ εὐαγγελισαμένου ἡμῖν τὴν πίστιν καὶ τὴν ἀγάπην ὑμῶν, καὶ ὅτι ἔχετε μνείαν ἡμῶν ἀγαθὴν πάντοτε, ἐπιποθοῦντες ἡμᾶς ἰδεῖν, καθάπερ καὶ ἡμεῖς ὑμᾶς.
52003007 Διὰ τοῦτο παρεκλήθημεν, ἀδελφοί, ἐφʼ ὑμῖν ἐπὶ πάσῃ τῇ ἀνάγκῃ καὶ θλίψει ἡμῶν, διὰ τῆς ὑμῶν πίστεως.
52003008 Ὅτι νῦν ζῶμεν, ἐὰν ὑμεῖς στήκετε ἐν ˚Κυρίῳ.
52003009 Τίνα γὰρ εὐχαριστίαν δυνάμεθα τῷ ˚Θεῷ ἀνταποδοῦναι περὶ ὑμῶν, ἐπὶ πάσῃ τῇ χαρᾷ ᾗ χαίρομεν διʼ ὑμᾶς, ἔμπροσθεν τοῦ ˚Θεοῦ ἡμῶν.
52003010 Νυκτὸς καὶ ἡμέρας, ὑπερεκπερισσοῦ δεόμενοι εἰς τὸ ἰδεῖν ὑμῶν τὸ πρόσωπον, καὶ καταρτίσαι τὰ ὑστερήματα τῆς πίστεως ὑμῶν;
52003011 Αὐτὸς δὲ ὁ ˚Θεὸς καὶ Πατὴρ ἡμῶν, καὶ ὁ ˚Κύριος ἡμῶν, ˚Ἰησοῦς, κατευθύναι τὴν ὁδὸν ἡμῶν πρὸς ὑμᾶς.
52003012 Ὑμᾶς δὲ ὁ ˚Κύριος πλεονάσαι καὶ περισσεύσαι τῇ ἀγάπῃ εἰς ἀλλήλους, καὶ εἰς πάντας, καθάπερ καὶ ἡμεῖς εἰς ὑμᾶς,
52003013 εἰς τὸ στηρίξαι ὑμῶν τὰς καρδίας, ἀμέμπτους ἐν ἁγιωσύνῃ ἔμπροσθεν τοῦ ˚Θεοῦ καὶ Πατρὸς ἡμῶν, ἐν τῇ παρουσίᾳ τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ, μετὰ πάντων τῶν ἁγίων αὐτοῦ. Ἀμήν!
52004001 Λοιπὸν οὖν, ἀδελφοί, ἐρωτῶμεν ὑμᾶς καὶ παρακαλοῦμεν ἐν ˚Κυρίῳ ˚Ἰησοῦ, καθὼς παρελάβετε παρʼ ἡμῶν, τὸ πῶς δεῖ ὑμᾶς περιπατεῖν καὶ ἀρέσκειν ˚Θεῷ ( καθὼς καὶ περιπατεῖτε ), ἵνα περισσεύητε μᾶλλον.
52004002 Οἴδατε γὰρ τίνας παραγγελίας ἐδώκαμεν ὑμῖν διὰ τοῦ ˚Κυρίου ˚Ἰησοῦ.
52004003 Τοῦτο γάρ ἐστιν θέλημα τοῦ ˚Θεοῦ, ὁ ἁγιασμὸς ὑμῶν, ἀπέχεσθαι ὑμᾶς ἀπὸ τῆς πορνείας,
52004004 εἰδέναι ἕκαστον ὑμῶν τὸ ἑαυτοῦ σκεῦος, κτᾶσθαι ἐν ἁγιασμῷ καὶ τιμῇ,
52004005 μὴ ἐν πάθει ἐπιθυμίας, καθάπερ καὶ τὰ ἔθνη τὰ μὴ εἰδότα τὸν ˚Θεόν,
52004006 τὸ μὴ ὑπερβαίνειν καὶ πλεονεκτεῖν ἐν τῷ πράγματι τὸν ἀδελφὸν αὐτοῦ, διότι ἔκδικος ˚Κύριος περὶ πάντων τούτων, καθὼς καὶ προείπαμεν ὑμῖν καὶ διεμαρτυράμεθα.
52004007 Οὐ γὰρ ἐκάλεσεν ἡμᾶς ὁ ˚Θεὸς ἐπὶ ἀκαθαρσίᾳ, ἀλλʼ ἐν ἁγιασμῷ.
52004008 Τοιγαροῦν ὁ ἀθετῶν, οὐκ ἄνθρωπον ἀθετεῖ, ἀλλὰ τὸν ˚Θεὸν, τὸν διδόντα τὸ ˚Πνεῦμα αὐτοῦ τὸ Ἅγιον εἰς ὑμᾶς.
52004009 Περὶ δὲ τῆς φιλαδελφίας, οὐ χρείαν ἔχετε γράφειν ὑμῖν, αὐτοὶ γὰρ ὑμεῖς θεοδίδακτοί ἐστε, εἰς τὸ ἀγαπᾶν ἀλλήλους.
52004010 Καὶ γὰρ ποιεῖτε αὐτὸ εἰς πάντας τοὺς ἀδελφοὺς, τοὺς ἐν ὅλῃ τῇ Μακεδονίᾳ. Παρακαλοῦμεν δὲ ὑμᾶς, ἀδελφοί, περισσεύειν μᾶλλον,
52004011 καὶ φιλοτιμεῖσθαι ἡσυχάζειν καὶ πράσσειν τὰ ἴδια, καὶ ἐργάζεσθαι ταῖς ἰδίαις χερσὶν ὑμῶν, καθὼς ὑμῖν παρηγγείλαμεν,
52004012 ἵνα περιπατῆτε εὐσχημόνως πρὸς τοὺς ἔξω, καὶ μηδενὸς χρείαν ἔχητε.
52004013 Οὐ θέλομεν δὲ ὑμᾶς ἀγνοεῖν, ἀδελφοί, περὶ τῶν κοιμωμένων, ἵνα μὴ λυπῆσθε, καθὼς καὶ οἱ λοιποὶ, οἱ μὴ ἔχοντες ἐλπίδα.
52004014 Εἰ γὰρ πιστεύομεν ὅτι ˚Ἰησοῦς ἀπέθανεν καὶ ἀνέστη, οὕτως καὶ ὁ ˚Θεὸς τοὺς κοιμηθέντας διὰ τοῦ ˚Ἰησοῦ ἄξει σὺν αὐτῷ.
52004015 Τοῦτο γὰρ ὑμῖν λέγομεν ἐν λόγῳ ˚Κυρίου, ὅτι ἡμεῖς οἱ ζῶντες, οἱ περιλειπόμενοι εἰς τὴν παρουσίαν τοῦ ˚Κυρίου, οὐ μὴ φθάσωμεν τοὺς κοιμηθέντας.
52004016 Ὅτι αὐτὸς ὁ ˚Κύριος ἐν κελεύσματι, ἐν φωνῇ ἀρχαγγέλου, καὶ ἐν σάλπιγγι ˚Θεοῦ, καταβήσεται ἀπʼ οὐρανοῦ, καὶ οἱ νεκροὶ ἐν ˚Χριστῷ ἀναστήσονται πρῶτον,
52004017 ἔπειτα ἡμεῖς οἱ ζῶντες, οἱ περιλειπόμενοι ἅμα σὺν αὐτοῖς, ἁρπαγησόμεθα ἐν νεφέλαις εἰς ἀπάντησιν τοῦ ˚Κυρίου εἰς ἀέρα· καὶ οὕτως πάντοτε σὺν ˚Κυρίῳ ἐσόμεθα.
52004018 Ὥστε παρακαλεῖτε ἀλλήλους ἐν τοῖς λόγοις τούτοις.
52005001 Περὶ δὲ τῶν χρόνων καὶ τῶν καιρῶν, ἀδελφοί, οὐ χρείαν ἔχετε ὑμῖν γράφεσθαι.
52005002 Αὐτοὶ γὰρ ἀκριβῶς οἴδατε ὅτι ἡμέρα ˚Κυρίου, ὡς κλέπτης ἐν νυκτὶ οὕτως ἔρχεται.
52005003 Ὅταν λέγωσιν, “Εἰρήνη καὶ ἀσφάλεια”, τότε αἰφνίδιος ἐπίσταται αὐτοῖς ὄλεθρος, ὥσπερ ἡ ὠδὶν τῇ ἐν γαστρὶ ἐχούσῃ, καὶ οὐ μὴ ἐκφύγωσιν.
52005004 Ὑμεῖς δέ, ἀδελφοί, οὐκ ἐστὲ ἐν σκότει, ἵνα ἡ ἡμέρα ὑμᾶς ὡς κλέπτης καταλάβῃ.
52005005 Πάντες γὰρ ὑμεῖς υἱοὶ φωτός ἐστε, καὶ υἱοὶ ἡμέρας. Οὐκ ἐσμὲν νυκτὸς οὐδὲ σκότους.
52005006 Ἄρα οὖν μὴ καθεύδωμεν ὡς οἱ λοιποί, ἀλλὰ γρηγορῶμεν καὶ νήφωμεν.
52005007 Οἱ γὰρ καθεύδοντες, νυκτὸς καθεύδουσιν, καὶ οἱ μεθυσκόμενοι, νυκτὸς μεθύουσιν.
52005008 Ἡμεῖς δὲ ἡμέρας ὄντες, νήφωμεν, ἐνδυσάμενοι θώρακα πίστεως καὶ ἀγάπης, καὶ περικεφαλαίαν, ἐλπίδα σωτηρίας.
52005009 Ὅτι οὐκ ἔθετο ἡμᾶς ὁ ˚Θεὸς εἰς ὀργὴν, ἀλλὰ εἰς περιποίησιν σωτηρίας διὰ τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ ˚Χριστοῦ,
52005010 τοῦ ἀποθανόντος περὶ ἡμῶν, ἵνα εἴτε γρηγορῶμεν εἴτε καθεύδωμεν, ἅμα σὺν αὐτῷ ζήσωμεν.
52005011 Διὸ παρακαλεῖτε ἀλλήλους, καὶ οἰκοδομεῖτε εἷς τὸν ἕνα, καθὼς καὶ ποιεῖτε.
52005012 Ἐρωτῶμεν δὲ ὑμᾶς, ἀδελφοί, εἰδέναι τοὺς κοπιῶντας ἐν ὑμῖν, καὶ προϊσταμένους ὑμῶν ἐν ˚Κυρίῳ, καὶ νουθετοῦντας ὑμᾶς,
52005013 καὶ ἡγεῖσθαι αὐτοὺς ὑπερεκπερισσοῦ ἐν ἀγάπῃ, διὰ τὸ ἔργον αὐτῶν. Εἰρηνεύετε ἐν ἑαυτοῖς.
52005014 Παρακαλοῦμεν δὲ ὑμᾶς, ἀδελφοί, νουθετεῖτε τοὺς ἀτάκτους, παραμυθεῖσθε τοὺς ὀλιγοψύχους, ἀντέχεσθε τῶν ἀσθενῶν, μακροθυμεῖτε πρὸς πάντας.
52005015 Ὁρᾶτε μή τις κακὸν ἀντὶ κακοῦ τινι ἀποδῷ, ἀλλὰ πάντοτε τὸ ἀγαθὸν διώκετε, καὶ εἰς ἀλλήλους καὶ εἰς πάντας.
52005016 Πάντοτε χαίρετε.
52005017 Ἀδιαλείπτως προσεύχεσθε.
52005018 Ἐν παντὶ εὐχαριστεῖτε· τοῦτο γὰρ θέλημα ˚Θεοῦ ἐν ˚Χριστῷ ˚Ἰησοῦ εἰς ὑμᾶς.
52005019 Τὸ ˚Πνεῦμα μὴ σβέννυτε.
52005020 Προφητείας μὴ ἐξουθενεῖτε.
52005021 Πάντα δοκιμάζετε, τὸ καλὸν κατέχετε.
52005022 Ἀπὸ παντὸς εἴδους πονηροῦ ἀπέχεσθε.
52005023 Αὐτὸς δὲ ὁ ˚Θεὸς τῆς εἰρήνης ἁγιάσαι ὑμᾶς ὁλοτελεῖς, καὶ ὁλόκληρον ὑμῶν τὸ πνεῦμα, καὶ ἡ ψυχὴ, καὶ τὸ σῶμα, ἀμέμπτως ἐν τῇ παρουσίᾳ τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ ˚Χριστοῦ, τηρηθείη.
52005024 Πιστὸς ὁ καλῶν ὑμᾶς, ὃς καὶ ποιήσει.
52005025 Ἀδελφοί, προσεύχεσθε καὶ περὶ ἡμῶν.
52005026 Ἀσπάσασθε τοὺς ἀδελφοὺς πάντας ἐν φιλήματι ἁγίῳ.
52005027 Ἐνορκίζω ὑμᾶς τὸν ˚Κύριον, ἀναγνωσθῆναι τὴν ἐπιστολὴν πᾶσιν τοῖς ἀδελφοῖς.
52005028 Ἡ χάρις τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ ˚Χριστοῦ, μεθʼ ὑμῶν. Ἀμήν!
53001001 Παῦλος, καὶ Σιλουανὸς, καὶ Τιμόθεος· τῇ ἐκκλησίᾳ Θεσσαλονικέων, ἐν ˚Θεῷ Πατρὶ ἡμῶν καὶ ˚Κυρίῳ ˚Ἰησοῦ ˚Χριστῷ:
53001002 χάρις ὑμῖν καὶ εἰρήνη ἀπὸ ˚Θεοῦ Πατρὸς ἡμῶν καὶ ˚Κυρίου ˚Ἰησοῦ ˚Χριστοῦ.
53001003 Εὐχαριστεῖν ὀφείλομεν τῷ ˚Θεῷ πάντοτε περὶ ὑμῶν, ἀδελφοί, καθὼς ἄξιόν ἐστιν, ὅτι ὑπεραυξάνει ἡ πίστις ὑμῶν, καὶ πλεονάζει ἡ ἀγάπη ἑνὸς ἑκάστου, πάντων ὑμῶν, εἰς ἀλλήλους,
53001004 ὥστε αὐτοὺς ἡμᾶς ἐν ὑμῖν ἐγκαυχᾶσθαι, ἐν ταῖς ἐκκλησίαις τοῦ ˚Θεοῦ, ὑπὲρ τῆς ὑπομονῆς ὑμῶν, καὶ πίστεως ἐν πᾶσιν τοῖς διωγμοῖς ὑμῶν, καὶ ταῖς θλίψεσιν αἷς ἀνέχεσθε,
53001005 ἔνδειγμα τῆς δικαίας κρίσεως τοῦ ˚Θεοῦ, εἰς τὸ καταξιωθῆναι ὑμᾶς τῆς Βασιλείας τοῦ ˚Θεοῦ, ὑπὲρ ἧς καὶ πάσχετε,
53001006 εἴπερ δίκαιον παρὰ ˚Θεῷ, ἀνταποδοῦναι τοῖς θλίβουσιν ὑμᾶς θλῖψιν,
53001007 καὶ ὑμῖν τοῖς θλιβομένοις, ἄνεσιν μεθʼ ἡμῶν ἐν τῇ ἀποκαλύψει τοῦ ˚Κυρίου ˚Ἰησοῦ ἀπʼ οὐρανοῦ, μετʼ ἀγγέλων δυνάμεως αὐτοῦ,
53001008 ἐν πυρὶ φλογός διδόντος ἐκδίκησιν τοῖς μὴ εἰδόσι ˚Θεὸν, καὶ τοῖς μὴ ὑπακούουσιν τῷ εὐαγγελίῳ τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ, ˚Χριστοῦ·
53001009 οἵτινες δίκην τίσουσιν ὄλεθρον αἰώνιον, ἀπὸ προσώπου τοῦ ˚Κυρίου, καὶ ἀπὸ τῆς δόξης τῆς ἰσχύος αὐτοῦ,
53001010 ὅταν ἔλθῃ ἐνδοξασθῆναι ἐν τοῖς ἁγίοις αὐτοῦ, καὶ θαυμασθῆναι ἐν πᾶσιν τοῖς πιστεύσασιν, ὅτι ἐπιστεύθη τὸ μαρτύριον ἡμῶν ἐφʼ ὑμᾶς ἐν τῇ ἡμέρᾳ ἐκείνῃ.
53001011 Εἰς ὃ καὶ προσευχόμεθα πάντοτε περὶ ὑμῶν, ἵνα ὑμᾶς ἀξιώσῃ τῆς κλήσεως ὁ ˚Θεὸς ἡμῶν, καὶ πληρώσῃ πᾶσαν εὐδοκίαν ἀγαθωσύνης, καὶ ἔργον πίστεως ἐν δυνάμει,
53001012 ὅπως ἐνδοξασθῇ τὸ ὄνομα τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ, ἐν ὑμῖν, καὶ ὑμεῖς ἐν αὐτῷ, κατὰ τὴν χάριν τοῦ ˚Θεοῦ ἡμῶν καὶ ˚Κυρίου ˚Ἰησοῦ ˚Χριστοῦ.
53002001 Ἐρωτῶμεν δὲ ὑμᾶς, ἀδελφοί, ὑπὲρ τῆς παρουσίας τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ ˚Χριστοῦ, καὶ ἡμῶν ἐπισυναγωγῆς ἐπʼ αὐτόν,
53002002 εἰς τὸ μὴ ταχέως σαλευθῆναι ὑμᾶς ἀπὸ τοῦ νοὸς, μηδὲ θροεῖσθαι, μήτε διὰ πνεύματος, μήτε διὰ λόγου, μήτε διʼ ἐπιστολῆς, ὡς διʼ ἡμῶν, ὡς ὅτι ἐνέστηκεν ἡ ἡμέρα τοῦ ˚Κυρίου.
53002003 Μή τις ὑμᾶς ἐξαπατήσῃ κατὰ μηδένα τρόπον, ὅτι ἐὰν μὴ ἔλθῃ ἡ ἀποστασία πρῶτον, καὶ ἀποκαλυφθῇ ὁ ἄνθρωπος τῆς ἀνομίας, ὁ υἱὸς τῆς ἀπωλείας,
53002004 ὁ ἀντικείμενος καὶ ὑπεραιρόμενος ἐπὶ πάντα λεγόμενον θεὸν ἢ σέβασμα, ὥστε αὐτὸν εἰς τὸν ναὸν τοῦ ˚Θεοῦ καθίσαι, ἀποδεικνύντα ἑαυτὸν ὅτι ἔστιν ˚Θεός.
53002005 Οὐ μνημονεύετε ὅτι ἔτι ὢν πρὸς ὑμᾶς, ταῦτα ἔλεγον ὑμῖν;
53002006 Καὶ νῦν τὸ κατέχον οἴδατε, εἰς τὸ ἀποκαλυφθῆναι αὐτὸν ἐν τῷ αὐτοῦ καιρῷ.
53002007 Τὸ γὰρ μυστήριον ἤδη ἐνεργεῖται τῆς ἀνομίας· μόνον ὁ κατέχων ἄρτι, ἕως ἐκ μέσου γένηται.
53002008 Καὶ τότε ἀποκαλυφθήσεται ὁ ἄνομος, ὃν ὁ ˚Κύριος ˚Ἰησοῦς ἀνελεῖ τῷ πνεύματι τοῦ στόματος αὐτοῦ, καὶ καταργήσει τῇ ἐπιφανείᾳ τῆς παρουσίας αὐτοῦ,
53002009 οὗ ἐστιν ἡ παρουσία, κατʼ ἐνέργειαν τοῦ Σατανᾶ ἐν πάσῃ δυνάμει, καὶ σημείοις, καὶ τέρασιν ψεύδους,
53002010 καὶ ἐν πάσῃ ἀπάτῃ ἀδικίας, τοῖς ἀπολλυμένοις, ἀνθʼ ὧν τὴν ἀγάπην τῆς ἀληθείας οὐκ ἐδέξαντο, εἰς τὸ σωθῆναι αὐτούς.
53002011 Καὶ διὰ τοῦτο, πέμπει αὐτοῖς ὁ ˚Θεὸς ἐνέργειαν πλάνης, εἰς τὸ πιστεῦσαι αὐτοὺς τῷ ψεύδει,
53002012 ἵνα κριθῶσιν ἅπαντες οἱ μὴ πιστεύσαντες τῇ ἀληθείᾳ, ἀλλὰ εὐδοκήσαντες τῇ ἀδικίᾳ.
53002013 Ἡμεῖς δὲ ὀφείλομεν εὐχαριστεῖν τῷ ˚Θεῷ πάντοτε περὶ ὑμῶν, ἀδελφοὶ ἠγαπημένοι ὑπὸ ˚Κυρίου, ὅτι εἵλατο ὑμᾶς ὁ ˚Θεὸς, ἀπʼ ἀρχῆς εἰς σωτηρίαν ἐν ἁγιασμῷ ˚Πνεύματος καὶ πίστει ἀληθείας,
53002014 εἰς ὃ ἐκάλεσεν ὑμᾶς, διὰ τοῦ εὐαγγελίου ἡμῶν, εἰς περιποίησιν δόξης τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ ˚Χριστοῦ.
53002015 Ἄρα οὖν, ἀδελφοί, στήκετε καὶ κρατεῖτε τὰς παραδόσεις ἃς ἐδιδάχθητε, εἴτε διὰ λόγου, εἴτε διʼ ἐπιστολῆς ἡμῶν.
53002016 Αὐτὸς δὲ ὁ ˚Κύριος ἡμῶν, ˚Ἰησοῦς ˚Χριστὸς, καὶ ὁ ˚Θεὸς ὁ Πατὴρ ἡμῶν, ὁ ἀγαπήσας ἡμᾶς καὶ δοὺς παράκλησιν αἰωνίαν, καὶ ἐλπίδα ἀγαθὴν ἐν χάριτι,
53002017 παρακαλέσαι ὑμῶν τὰς καρδίας, καὶ στηρίξαι ἐν παντὶ ἔργῳ καὶ λόγῳ ἀγαθῷ.
53003001 Τὸ λοιπὸν, προσεύχεσθε, ἀδελφοί, περὶ ἡμῶν, ἵνα ὁ λόγος τοῦ ˚Κυρίου τρέχῃ καὶ δοξάζηται, καθὼς καὶ πρὸς ὑμᾶς,
53003002 καὶ ἵνα ῥυσθῶμεν ἀπὸ τῶν ἀτόπων καὶ πονηρῶν ἀνθρώπων, οὐ γὰρ πάντων ἡ πίστις.
53003003 Πιστὸς δέ ἐστιν ὁ ˚Κύριος, ὃς στηρίξει ὑμᾶς, καὶ φυλάξει ἀπὸ τοῦ πονηροῦ.
53003004 Πεποίθαμεν δὲ ἐν ˚Κυρίῳ ἐφʼ ὑμᾶς ὅτι ἃ παραγγέλλομεν, καὶ ποιεῖτε καὶ ποιήσετε.
53003005 Ὁ δὲ ˚Κύριος κατευθύναι ὑμῶν τὰς καρδίας εἰς τὴν ἀγάπην τοῦ ˚Θεοῦ, καὶ εἰς τὴν ὑπομονὴν τοῦ ˚Χριστοῦ.
53003006 Παραγγέλλομεν δὲ ὑμῖν, ἀδελφοί, ἐν ὀνόματι τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ ˚Χριστοῦ, στέλλεσθαι ὑμᾶς ἀπὸ παντὸς ἀδελφοῦ ἀτάκτως περιπατοῦντος, καὶ μὴ κατὰ τὴν παράδοσιν ἣν παρελάβοσαν παρʼ ἡμῶν.
53003007 Αὐτοὶ γὰρ οἴδατε πῶς δεῖ μιμεῖσθαι ἡμᾶς, ὅτι οὐκ ἠτακτήσαμεν ἐν ὑμῖν,
53003008 οὐδὲ δωρεὰν ἄρτον ἐφάγομεν παρά τινος, ἀλλʼ ἐν κόπῳ καὶ μόχθῳ, νυκτὸς καὶ ἡμέρας ἐργαζόμενοι, πρὸς τὸ μὴ ἐπιβαρῆσαί τινα ὑμῶν.
53003009 Οὐχ ὅτι οὐκ ἔχομεν ἐξουσίαν, ἀλλʼ ἵνα ἑαυτοὺς τύπον δῶμεν ὑμῖν, εἰς τὸ μιμεῖσθαι ἡμᾶς.
53003010 Καὶ γὰρ ὅτε ἦμεν πρὸς ὑμᾶς, τοῦτο παρηγγέλλομεν ὑμῖν, ὅτι εἴ τις οὐ θέλει ἐργάζεσθαι, μηδὲ ἐσθιέτω.
53003011 Ἀκούομεν γάρ τινας περιπατοῦντας ἐν ὑμῖν ἀτάκτως, μηδὲν ἐργαζομένους, ἀλλὰ περιεργαζομένους.
53003012 Τοῖς δὲ τοιούτοις παραγγέλλομεν καὶ παρακαλοῦμεν ἐν ˚Κυρίῳ ˚Ἰησοῦ ˚Χριστῷ, ἵνα μετὰ ἡσυχίας ἐργαζόμενοι τὸν ἑαυτῶν ἄρτον ἐσθίωσιν.
53003013 Ὑμεῖς δέ, ἀδελφοί, μὴ ἐγκακήσητε καλοποιοῦντες.
53003014 Εἰ δέ τις οὐχ ὑπακούει τῷ λόγῳ ἡμῶν διὰ τῆς ἐπιστολῆς, τοῦτον σημειοῦσθε, μὴ συναναμίγνυσθαι αὐτῷ, ἵνα ἐντραπῇ.
53003015 Καὶ μὴ ὡς ἐχθρὸν ἡγεῖσθε, ἀλλὰ νουθετεῖτε ὡς ἀδελφόν.
53003016 Αὐτὸς δὲ ὁ ˚Κύριος τῆς εἰρήνης, δῴη ὑμῖν τὴν εἰρήνην διὰ παντὸς ἐν παντὶ τρόπῳ. Ὁ ˚Κύριος μετὰ πάντων ὑμῶν.
53003017 Ὁ ἀσπασμὸς τῇ ἐμῇ χειρὶ, Παύλου, ὅ ἐστιν σημεῖον ἐν πάσῃ ἐπιστολῇ, οὕτως γράφω.
53003018 Ἡ χάρις τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ ˚Χριστοῦ, μετὰ πάντων ὑμῶν.
54001001 Παῦλος, ἀπόστολος ˚Χριστοῦ ˚Ἰησοῦ, κατʼ ἐπιταγὴν ˚Θεοῦ Σωτῆρος ἡμῶν, καὶ ˚Κυρίου ˚Ἰησοῦ ˚Χριστοῦ τῆς ἐλπίδος ἡμῶν,
54001002 Τιμοθέῳ, γνησίῳ τέκνῳ ἐν πίστει: χάρις, ἔλεος, εἰρήνη ἀπὸ ˚Θεοῦ Πατρὸς καὶ ˚Χριστοῦ ˚Ἰησοῦ τοῦ ˚Κυρίου ἡμῶν.
54001003 Καθὼς παρεκάλεσά σε προσμεῖναι ἐν Ἐφέσῳ, πορευόμενος εἰς Μακεδονίαν, ἵνα παραγγείλῃς τισὶν μὴ ἑτεροδιδασκαλεῖν,
54001004 μηδὲ προσέχειν μύθοις καὶ γενεαλογίαις ἀπεράντοις, αἵτινες ἐκζητήσεις παρέχουσι μᾶλλον ἢ οἰκονομίαν ˚Θεοῦ, τὴν ἐν πίστει.
54001005 Τὸ δὲ τέλος τῆς παραγγελίας ἐστὶν ἀγάπη ἐκ καθαρᾶς καρδίας, καὶ συνειδήσεως ἀγαθῆς, καὶ πίστεως ἀνυποκρίτου,
54001006 ὧν τινες ἀστοχήσαντες, ἐξετράπησαν εἰς ματαιολογίαν,
54001007 θέλοντες εἶναι νομοδιδάσκαλοι, μὴ νοοῦντες μήτε ἃ λέγουσιν, μήτε περὶ τίνων διαβεβαιοῦνται.
54001008 Οἴδαμεν δὲ ὅτι καλὸς ὁ νόμος, ἐάν τις αὐτῷ νομίμως χρῆται,
54001009 εἰδὼς τοῦτο, ὅτι δικαίῳ νόμος οὐ κεῖται, ἀνόμοις δὲ καὶ ἀνυποτάκτοις, ἀσεβέσι καὶ ἁμαρτωλοῖς, ἀνοσίοις καὶ βεβήλοις, πατρολῴαις καὶ μητρολῴαις, ἀνδροφόνοις,
54001010 πόρνοις, ἀρσενοκοίταις, ἀνδραποδισταῖς, ψεύσταις, ἐπιόρκοις, καὶ εἴ τι ἕτερον τῇ ὑγιαινούσῃ διδασκαλίᾳ ἀντίκειται,
54001011 κατὰ τὸ εὐαγγέλιον τῆς δόξης τοῦ μακαρίου ˚Θεοῦ, ὃ ἐπιστεύθην ἐγώ.
54001012 Χάριν ἔχω τῷ ἐνδυναμώσαντί με, ˚Χριστῷ ˚Ἰησοῦ τῷ ˚Κυρίῳ ἡμῶν, ὅτι πιστόν με ἡγήσατο, θέμενος εἰς διακονίαν,
54001013 τὸ πρότερον ὄντα βλάσφημον, καὶ διώκτην, καὶ ὑβριστήν· ἀλλὰ ἠλεήθην, ὅτι ἀγνοῶν, ἐποίησα ἐν ἀπιστίᾳ.
54001014 Ὑπερεπλεόνασεν δὲ ἡ χάρις τοῦ ˚Κυρίου ἡμῶν, μετὰ πίστεως καὶ ἀγάπης τῆς ἐν ˚Χριστῷ ˚Ἰησοῦ.
54001015 Πιστὸς ὁ λόγος, καὶ πάσης ἀποδοχῆς ἄξιος: ὅτι “˚Χριστὸς ˚Ἰησοῦς ἦλθεν εἰς τὸν κόσμον ἁμαρτωλοὺς σῶσαι”, ὧν πρῶτός εἰμι ἐγώ.
54001016 Ἀλλὰ διὰ τοῦτο ἠλεήθην, ἵνα ἐν ἐμοὶ πρώτῳ ἐνδείξηται ˚Ἰησοῦς ˚Χριστὸς τὴν ἅπασαν μακροθυμίαν, πρὸς ὑποτύπωσιν τῶν μελλόντων πιστεύειν ἐπʼ αὐτῷ, εἰς ζωὴν αἰώνιον.
54001017 Τῷ δὲ Βασιλεῖ τῶν αἰώνων, ἀφθάρτῳ, ἀοράτῳ, μόνῳ ˚Θεῷ, τιμὴ καὶ δόξα, εἰς τοὺς αἰῶνας τῶν αἰώνων. Ἀμήν!
54001018 Ταύτην τὴν παραγγελίαν παρατίθεμαί σοι, τέκνον, Τιμόθεε, κατὰ τὰς προαγούσας ἐπὶ σὲ προφητείας, ἵνα στρατεύῃ ἐν αὐταῖς τὴν καλὴν στρατείαν,
54001019 ἔχων πίστιν καὶ ἀγαθὴν συνείδησιν, ἥν τινες ἀπωσάμενοι, περὶ τὴν πίστιν ἐναυάγησαν·
54001020 ὧν ἐστιν Ὑμέναιος καὶ Ἀλέξανδρος, οὓς παρέδωκα τῷ Σατανᾷ, ἵνα παιδευθῶσι μὴ βλασφημεῖν.
54002001 Παρακαλῶ οὖν, πρῶτον πάντων ποιεῖσθαι δεήσεις, προσευχάς, ἐντεύξεις, εὐχαριστίας, ὑπὲρ πάντων ἀνθρώπων,
54002002 ὑπὲρ βασιλέων καὶ πάντων τῶν ἐν ὑπεροχῇ ὄντων, ἵνα ἤρεμον καὶ ἡσύχιον βίον διάγωμεν ἐν πάσῃ εὐσεβείᾳ καὶ σεμνότητι.
54002003 Τοῦτο καλὸν καὶ ἀπόδεκτον ἐνώπιον τοῦ Σωτῆρος ἡμῶν, ˚Θεοῦ,
54002004 ὃς πάντας ἀνθρώπους θέλει σωθῆναι, καὶ εἰς ἐπίγνωσιν ἀληθείας ἐλθεῖν.
54002005 Εἷς γὰρ ˚Θεός, εἷς καὶ μεσίτης ˚Θεοῦ καὶ ἀνθρώπων, ἄνθρωπος ˚Χριστὸς ˚Ἰησοῦς,
54002006 ὁ δοὺς ἑαυτὸν ἀντίλυτρον ὑπὲρ πάντων, καὶ μαρτύριον καιροῖς ἰδίοις,
54002007 εἰς ὃ ἐτέθην ἐγὼ κῆρυξ καὶ ἀπόστολος ( ἀλήθειαν λέγω ἐν ˚Χριστῷ, οὐ ψεύδομαι ), διδάσκαλος ἐθνῶν ἐν γνώσει καὶ ἀληθείᾳ.
54002008 Βούλομαι οὖν, προσεύχεσθαι τοὺς ἄνδρας ἐν παντὶ τόπῳ, ἐπαίροντας ὁσίους χεῖρας χωρὶς ὀργῆς καὶ διαλογισμοῦ.
54002009 Ὡσαύτως γυναῖκας ἐν καταστολῇ κοσμίῳ μετὰ αἰδοῦς καὶ σωφροσύνης κοσμεῖν ἑαυτάς, μὴ ἐν πλέγμασιν, καὶ χρυσῷ, ἢ μαργαρίταις, ἢ ἱματισμῷ πολυτελεῖ,
54002010 ἀλλʼ ὃ πρέπει γυναιξὶν ἐπαγγελλομέναις θεοσέβειαν, διʼ ἔργων ἀγαθῶν.
54002011 Γυνὴ ἐν ἡσυχίᾳ μανθανέτω ἐν πάσῃ ὑποταγῇ.
54002012 Διδάσκειν δὲ γυναικὶ οὐκ ἐπιτρέπω, οὐδὲ αὐθεντεῖν ἀνδρός, ἀλλʼ εἶναι ἐν ἡσυχίᾳ.
54002013 Ἀδὰμ γὰρ πρῶτος ἐπλάσθη, εἶτα Εὕα·
54002014 καὶ Ἀδὰμ οὐκ ἠπατήθη, ἡ δὲ γυνὴ ἐξαπατηθεῖσα, ἐν παραβάσει γέγονεν.
54002015 Σωθήσεται δὲ διὰ τῆς τεκνογονίας, ἐὰν μείνωσιν ἐν πίστει, καὶ ἀγάπῃ, καὶ ἁγιασμῷ, μετὰ σωφροσύνης.
54003001 Πιστὸς ὁ λόγος: “Εἴ τις ἐπισκοπῆς ὀρέγεται, καλοῦ ἔργου ἐπιθυμεῖ.”
54003002 Δεῖ οὖν τὸν ἐπίσκοπον ἀνεπίλημπτον εἶναι, μιᾶς γυναικὸς ἄνδρα, νηφάλιον, σώφρονα, κόσμιον, φιλόξενον, διδακτικόν,
54003003 μὴ πάροινον, μὴ πλήκτην, ἀλλὰ ἐπιεικῆ, ἄμαχον, ἀφιλάργυρον,
54003004 τοῦ ἰδίου οἴκου καλῶς προϊστάμενον, τέκνα ἔχοντα ἐν ὑποταγῇ, μετὰ πάσης σεμνότητος
54003005 ( εἰ δέ τις τοῦ ἰδίου οἴκου προστῆναι οὐκ οἶδεν, πῶς ἐκκλησίας ˚Θεοῦ ἐπιμελήσεται; )
54003006 Μὴ νεόφυτον, ἵνα μὴ τυφωθεὶς, εἰς κρίμα ἐμπέσῃ τοῦ διαβόλου.
54003007 Δεῖ δὲ καὶ, μαρτυρίαν καλὴν ἔχειν ἀπὸ τῶν ἔξωθεν, ἵνα μὴ εἰς ὀνειδισμὸν ἐμπέσῃ, καὶ παγίδα τοῦ διαβόλου.
54003008 Διακόνους ὡσαύτως σεμνούς, μὴ διλόγους, μὴ οἴνῳ πολλῷ προσέχοντας, μὴ αἰσχροκερδεῖς,
54003009 ἔχοντας τὸ μυστήριον τῆς πίστεως ἐν καθαρᾷ συνειδήσει.
54003010 Καὶ οὗτοι δὲ δοκιμαζέσθωσαν πρῶτον, εἶτα διακονείτωσαν, ἀνέγκλητοι ὄντες.
54003011 Γυναῖκας ὡσαύτως σεμνάς, μὴ διαβόλους, νηφαλίους, πιστὰς ἐν πᾶσιν.
54003012 Διάκονοι ἔστωσαν μιᾶς γυναικὸς ἄνδρες, τέκνων καλῶς προϊστάμενοι καὶ τῶν ἰδίων οἴκων.
54003013 Οἱ γὰρ καλῶς διακονήσαντες, βαθμὸν ἑαυτοῖς καλὸν περιποιοῦνται, καὶ πολλὴν παρρησίαν ἐν πίστει τῇ ἐν ˚Χριστῷ ˚Ἰησοῦ.
54003014 Ταῦτά σοι γράφω, ἐλπίζων ἐλθεῖν πρὸς σὲ ἐν τάχει,
54003015 ἐὰν δὲ βραδύνω, ἵνα εἰδῇς πῶς δεῖ ἐν οἴκῳ ˚Θεοῦ ἀναστρέφεσθαι, ἥτις ἐστὶν ἐκκλησία ˚Θεοῦ ζῶντος, στῦλος καὶ ἑδραίωμα τῆς ἀληθείας.
54003016 Καὶ ὁμολογουμένως μέγα ἐστὶν τὸ τῆς εὐσεβείας μυστήριον: “Ὃς ἐφανερώθη ἐν σαρκί, ἐδικαιώθη ἐν ˚Πνεύματι, ὤφθη ἀγγέλοις, ἐκηρύχθη ἐν ἔθνεσιν, ἐπιστεύθη ἐν κόσμῳ, ἀνελήμφθη ἐν δόξῃ.”
54004001 Τὸ δὲ ˚Πνεῦμα ῥητῶς λέγει ὅτι ἐν ὑστέροις καιροῖς, ἀποστήσονταί τινες τῆς πίστεως, προσέχοντες πνεύμασι πλάνοις καὶ διδασκαλίαις δαιμονίων,
54004002 ἐν ὑποκρίσει ψευδολόγων, κεκαυστηριασμένων τὴν ἰδίαν συνείδησιν,
54004003 κωλυόντων γαμεῖν, ἀπέχεσθαι βρωμάτων ἃ ὁ ˚Θεὸς ἔκτισεν εἰς μετάλημψιν μετὰ εὐχαριστίας, τοῖς πιστοῖς καὶ ἐπεγνωκόσι τὴν ἀλήθειαν.
54004004 Ὅτι πᾶν κτίσμα ˚Θεοῦ καλόν, καὶ οὐδὲν ἀπόβλητον μετὰ εὐχαριστίας λαμβανόμενον,
54004005 ἁγιάζεται γὰρ διὰ λόγου ˚Θεοῦ καὶ ἐντεύξεως.
54004006 Ταῦτα ὑποτιθέμενος τοῖς ἀδελφοῖς, καλὸς ἔσῃ διάκονος ˚Χριστοῦ ˚Ἰησοῦ, ἐντρεφόμενος τοῖς λόγοις τῆς πίστεως, καὶ τῆς καλῆς διδασκαλίας ᾗ παρηκολούθηκας.
54004007 Τοὺς δὲ βεβήλους καὶ γραώδεις μύθους παραιτοῦ. Γύμναζε δὲ σεαυτὸν πρὸς εὐσέβειαν·
54004008 “Ἡ” γὰρ “σωματικὴ γυμνασία πρὸς ὀλίγον ἐστὶν ὠφέλιμος, ἡ δὲ εὐσέβεια πρὸς πάντα ὠφέλιμός ἐστιν”, ἐπαγγελίαν ἔχουσα ζωῆς τῆς νῦν, καὶ τῆς μελλούσης.
54004009 Πιστὸς ὁ λόγος, καὶ πάσης ἀποδοχῆς ἄξιος.
54004010 Εἰς τοῦτο γὰρ κοπιῶμεν καὶ ἀγωνιζόμεθα, ὅτι ἠλπίκαμεν ἐπὶ ˚Θεῷ ζῶντι, ὅς ἐστιν Σωτὴρ πάντων ἀνθρώπων, μάλιστα πιστῶν.
54004011 Παράγγελλε ταῦτα καὶ δίδασκε.
54004012 Μηδείς σου τῆς νεότητος καταφρονείτω, ἀλλὰ τύπος γίνου τῶν πιστῶν, ἐν λόγῳ, ἐν ἀναστροφῇ, ἐν ἀγάπῃ, ἐν πίστει, ἐν ἁγνείᾳ.
54004013 Ἕως ἔρχομαι, πρόσεχε τῇ ἀναγνώσει, τῇ παρακλήσει, τῇ διδασκαλίᾳ.
54004014 Μὴ ἀμέλει τοῦ ἐν σοὶ χαρίσματος, ὃ ἐδόθη σοι διὰ προφητείας, μετὰ ἐπιθέσεως τῶν χειρῶν τοῦ πρεσβυτερίου.
54004015 Ταῦτα μελέτα, ἐν τούτοις ἴσθι, ἵνα σου ἡ προκοπὴ φανερὰ ᾖ πᾶσιν.
54004016 Ἔπεχε σεαυτῷ καὶ τῇ διδασκαλίᾳ. Ἐπίμενε αὐτοῖς, τοῦτο γὰρ ποιῶν, καὶ σεαυτὸν σώσεις καὶ τοὺς ἀκούοντάς σου.
54005001 Πρεσβυτέρῳ μὴ ἐπιπλήξῃς, ἀλλὰ παρακάλει ὡς πατέρα, νεωτέρους ὡς ἀδελφούς,
54005002 πρεσβυτέρας ὡς μητέρας, νεωτέρας ὡς ἀδελφὰς, ἐν πάσῃ ἁγνείᾳ.
54005003 Χήρας τίμα, τὰς ὄντως χήρας.
54005004 Εἰ δέ τις χήρα, τέκνα ἢ ἔκγονα ἔχει, μανθανέτωσαν πρῶτον τὸν ἴδιον οἶκον εὐσεβεῖν, καὶ ἀμοιβὰς ἀποδιδόναι τοῖς προγόνοις, τοῦτο γάρ ἐστιν ἀπόδεκτον ἐνώπιον τοῦ ˚Θεοῦ.
54005005 Ἡ δὲ ὄντως χήρα καὶ μεμονωμένη, ἤλπικεν ἐπὶ ˚Θεὸν, καὶ προσμένει ταῖς δεήσεσιν καὶ ταῖς προσευχαῖς, νυκτὸς καὶ ἡμέρας.
54005006 Ἡ δὲ σπαταλῶσα, ζῶσα τέθνηκεν.
54005007 Καὶ ταῦτα παράγγελλε, ἵνα ἀνεπίλημπτοι ὦσιν.
54005008 Εἰ δέ τις τῶν ἰδίων καὶ μάλιστα οἰκείων οὐ προνοεῖ, τὴν πίστιν ἤρνηται, καὶ ἔστιν ἀπίστου χείρων.
54005009 Χήρα καταλεγέσθω μὴ ἔλαττον ἐτῶν ἑξήκοντα γεγονυῖα, ἑνὸς ἀνδρὸς γυνή,
54005010 ἐν ἔργοις καλοῖς μαρτυρουμένη: εἰ ἐτεκνοτρόφησεν, εἰ ἐξενοδόχησεν, εἰ ἁγίων πόδας ἔνιψεν, εἰ θλιβομένοις ἐπήρκεσεν, εἰ παντὶ ἔργῳ ἀγαθῷ ἐπηκολούθησεν.
54005011 Νεωτέρας δὲ χήρας παραιτοῦ· ὅταν γὰρ καταστρηνιάσωσιν τοῦ ˚Χριστοῦ, γαμεῖν θέλουσιν,
54005012 ἔχουσαι κρίμα ὅτι τὴν πρώτην πίστιν ἠθέτησαν.
54005013 Ἅμα δὲ καὶ ἀργαὶ μανθάνουσιν, περιερχόμεναι τὰς οἰκίας, οὐ μόνον δὲ ἀργαὶ, ἀλλὰ καὶ φλύαροι καὶ περίεργοι, λαλοῦσαι τὰ μὴ δέοντα.
54005014 Βούλομαι οὖν νεωτέρας γαμεῖν, τεκνογονεῖν, οἰκοδεσποτεῖν, μηδεμίαν ἀφορμὴν διδόναι τῷ ἀντικειμένῳ λοιδορίας χάριν.
54005015 Ἤδη γάρ τινες ἐξετράπησαν ὀπίσω τοῦ Σατανᾶ.
54005016 Εἴ τις πιστὴ ἔχει χήρας, ἐπαρκείτω αὐταῖς, καὶ μὴ βαρείσθω ἡ ἐκκλησία, ἵνα ταῖς ὄντως χήραις ἐπαρκέσῃ.
54005017 Οἱ καλῶς προεστῶτες πρεσβύτεροι, διπλῆς τιμῆς ἀξιούσθωσαν, μάλιστα οἱ κοπιῶντες ἐν λόγῳ καὶ διδασκαλίᾳ.
54005018 Λέγει γὰρ ἡ Γραφή, “Βοῦν ἀλοῶντα οὐ φιμώσεις”, καί, “Ἄξιος ὁ ἐργάτης τοῦ μισθοῦ αὐτοῦ.”
54005019 Κατὰ πρεσβυτέρου κατηγορίαν μὴ παραδέχου, ἐκτὸς εἰ μὴ ἐπὶ δύο ἢ τριῶν μαρτύρων.
54005020 Τοὺς ἁμαρτάνοντας ἐνώπιον, πάντων ἔλεγχε, ἵνα καὶ οἱ λοιποὶ φόβον ἔχωσιν.
54005021 Διαμαρτύρομαι ἐνώπιον τοῦ ˚Θεοῦ, καὶ ˚Χριστοῦ ˚Ἰησοῦ, καὶ τῶν ἐκλεκτῶν ἀγγέλων, ἵνα ταῦτα φυλάξῃς χωρὶς προκρίματος, μηδὲν ποιῶν κατὰ πρόσκλισιν.
54005022 Χεῖρας ταχέως μηδενὶ ἐπιτίθει, μηδὲ κοινώνει ἁμαρτίαις ἀλλοτρίαις· σεαυτὸν ἁγνὸν τήρει.
54005023 Μηκέτι ὑδροπότει, ἀλλὰ οἴνῳ ὀλίγῳ χρῶ, διὰ τὸν στόμαχον καὶ τὰς πυκνάς σου ἀσθενείας.
54005024 Τινῶν ἀνθρώπων αἱ ἁμαρτίαι πρόδηλοί εἰσιν, προάγουσαι εἰς κρίσιν, τισὶν δὲ καὶ ἐπακολουθοῦσιν.
54005025 Ὡσαύτως καὶ τὰ ἔργα τὰ καλὰ πρόδηλα, καὶ τὰ ἄλλως ἔχοντα, κρυβῆναι οὐ δύνανται.
54006001 Ὅσοι εἰσὶν ὑπὸ ζυγὸν δοῦλοι, τοὺς ἰδίους δεσπότας πάσης τιμῆς ἀξίους ἡγείσθωσαν, ἵνα μὴ τὸ ὄνομα τοῦ ˚Θεοῦ καὶ ἡ διδασκαλία βλασφημῆται.
54006002 Οἱ δὲ πιστοὺς ἔχοντες δεσπότας, μὴ καταφρονείτωσαν, ὅτι ἀδελφοί εἰσιν, ἀλλὰ μᾶλλον δουλευέτωσαν, ὅτι πιστοί εἰσιν καὶ ἀγαπητοὶ, οἱ τῆς εὐεργεσίας ἀντιλαμβανόμενοι. Ταῦτα δίδασκε καὶ παρακάλει.
54006003 Εἴ τις ἑτεροδιδασκαλεῖ, καὶ μὴ προσέρχεται ὑγιαίνουσι λόγοις, τοῖς τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ ˚Χριστοῦ, καὶ τῇ κατʼ εὐσέβειαν διδασκαλίᾳ,
54006004 τετύφωται μηδὲν ἐπιστάμενος, ἀλλὰ νοσῶν περὶ ζητήσεις καὶ λογομαχίας, ἐξ ὧν γίνεται φθόνος, ἔρις, βλασφημίαι, ὑπόνοιαι πονηραί,
54006005 διαπαρατριβαὶ, διεφθαρμένων ἀνθρώπων τὸν νοῦν καὶ ἀπεστερημένων τῆς ἀληθείας, νομιζόντων πορισμὸν εἶναι τὴν εὐσέβειαν.
54006006 Ἔστιν δὲ πορισμὸς μέγας ἡ εὐσέβεια μετὰ αὐταρκείας.
54006007 Οὐδὲν γὰρ εἰσηνέγκαμεν εἰς τὸν κόσμον, ὅτι οὐδὲ ἐξενεγκεῖν τι δυνάμεθα.
54006008 Ἔχοντες δὲ διατροφὰς καὶ σκεπάσματα, τούτοις ἀρκεσθησόμεθα.
54006009 Οἱ δὲ βουλόμενοι πλουτεῖν, ἐμπίπτουσιν εἰς πειρασμὸν, καὶ παγίδα, καὶ ἐπιθυμίας πολλὰς ἀνοήτους καὶ βλαβεράς, αἵτινες βυθίζουσι τοὺς ἀνθρώπους εἰς ὄλεθρον καὶ ἀπώλειαν.
54006010 Ῥίζα γὰρ πάντων τῶν κακῶν ἐστιν ἡ φιλαργυρία, ἧς τινες ὀρεγόμενοι, ἀπεπλανήθησαν ἀπὸ τῆς πίστεως, καὶ ἑαυτοὺς περιέπειραν ὀδύναις πολλαῖς.
54006011 Σὺ δέ, ὦ ἄνθρωπε ˚Θεοῦ, ταῦτα φεῦγε· δίωκε δὲ δικαιοσύνην, εὐσέβειαν, πίστιν, ἀγάπην, ὑπομονήν, πραϋπαθίαν.
54006012 Ἀγωνίζου τὸν καλὸν ἀγῶνα τῆς πίστεως, ἐπιλαβοῦ τῆς αἰωνίου ζωῆς, εἰς ἣν ἐκλήθης καὶ ὡμολόγησας τὴν καλὴν ὁμολογίαν ἐνώπιον πολλῶν μαρτύρων.
54006013 Παραγγέλλω σοι ἐνώπιον τοῦ ˚Θεοῦ, τοῦ ζῳοποιοῦντος τὰ πάντα, καὶ ˚Χριστοῦ ˚Ἰησοῦ, τοῦ μαρτυρήσαντος ἐπὶ Ποντίου Πιλάτου τὴν καλὴν ὁμολογίαν,
54006014 τηρῆσαί σε τὴν ἐντολὴν ἄσπιλον ἀνεπίλημπτον, μέχρι τῆς ἐπιφανείας τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ ˚Χριστοῦ,
54006015 ἣν καιροῖς ἰδίοις δείξει ὁ μακάριος καὶ μόνος Δυνάστης, ὁ Βασιλεὺς τῶν βασιλευόντων, καὶ ˚Κύριος τῶν κυριευόντων,
54006016 ὁ μόνος ἔχων ἀθανασίαν, φῶς οἰκῶν ἀπρόσιτον, ὃν εἶδεν οὐδεὶς ἀνθρώπων, οὐδὲ ἰδεῖν δύναται· ᾧ τιμὴ καὶ κράτος αἰώνιον. Ἀμήν!
54006017 Τοῖς πλουσίοις ἐν τῷ νῦν αἰῶνι, παράγγελλε μὴ ὑψηλοφρονεῖν, μηδὲ ἠλπικέναι ἐπὶ πλούτου ἀδηλότητι, ἀλλʼ ἐπὶ ˚Θεῷ, τῷ παρέχοντι ἡμῖν πάντα πλουσίως εἰς ἀπόλαυσιν,
54006018 ἀγαθοεργεῖν, πλουτεῖν ἐν ἔργοις καλοῖς, εὐμεταδότους εἶναι, κοινωνικούς,
54006019 ἀποθησαυρίζοντας ἑαυτοῖς θεμέλιον καλὸν εἰς τὸ μέλλον, ἵνα ἐπιλάβωνται τῆς ὄντως ζωῆς.
54006020 Ὦ Τιμόθεε, τὴν παραθήκην φύλαξον, ἐκτρεπόμενος τὰς βεβήλους κενοφωνίας καὶ ἀντιθέσεις τῆς ψευδωνύμου γνώσεως,
54006021 ἥν τινες ἐπαγγελλόμενοι περὶ τὴν πίστιν ἠστόχησαν. Ἡ χάρις μεθʼ ὑμῶν.
55001001 Παῦλος, ἀπόστολος ˚Χριστοῦ ˚Ἰησοῦ, διὰ θελήματος ˚Θεοῦ, κατʼ ἐπαγγελίαν ζωῆς τῆς ἐν ˚Χριστῷ ˚Ἰησοῦ,
55001002 Τιμοθέῳ ἀγαπητῷ τέκνῳ: χάρις, ἔλεος, εἰρήνη, ἀπὸ ˚Θεοῦ Πατρὸς καὶ ˚Χριστοῦ ˚Ἰησοῦ τοῦ ˚Κυρίου ἡμῶν.
55001003 Χάριν ἔχω τῷ ˚Θεῷ, ᾧ λατρεύω ἀπὸ προγόνων ἐν καθαρᾷ συνειδήσει, ὡς ἀδιάλειπτον ἔχω τὴν περὶ σοῦ μνείαν, ἐν ταῖς δεήσεσίν μου, νυκτὸς καὶ ἡμέρας,
55001004 ἐπιποθῶν σε ἰδεῖν, μεμνημένος σου τῶν δακρύων, ἵνα χαρᾶς πληρωθῶ,
55001005 ὑπόμνησιν λαβὼν τῆς ἐν σοὶ ἀνυποκρίτου πίστεως, ἥτις ἐνῴκησεν πρῶτον ἐν τῇ μάμμῃ σου, Λωΐδι, καὶ τῇ μητρί σου, Εὐνίκῃ, πέπεισμαι δὲ ὅτι καὶ ἐν σοί.
55001006 Διʼ ἣν αἰτίαν ἀναμιμνῄσκω σε ἀναζωπυρεῖν τὸ χάρισμα τοῦ ˚Θεοῦ, ὅ ἐστιν ἐν σοὶ διὰ τῆς ἐπιθέσεως τῶν χειρῶν μου.
55001007 Οὐ γὰρ ἔδωκεν ἡμῖν ὁ ˚Θεὸς πνεῦμα δειλίας, ἀλλὰ δυνάμεως, καὶ ἀγάπης, καὶ σωφρονισμοῦ.
55001008 Μὴ οὖν ἐπαισχυνθῇς τὸ μαρτύριον τοῦ ˚Κυρίου ἡμῶν, μηδὲ ἐμὲ, τὸν δέσμιον αὐτοῦ, ἀλλὰ συγκακοπάθησον τῷ εὐαγγελίῳ, κατὰ δύναμιν ˚Θεοῦ,
55001009 τοῦ σώσαντος ἡμᾶς, καὶ καλέσαντος κλήσει ἁγίᾳ, οὐ κατὰ τὰ ἔργα ἡμῶν, ἀλλὰ κατὰ ἰδίαν πρόθεσιν καὶ χάριν, τὴν δοθεῖσαν ἡμῖν ἐν ˚Χριστῷ ˚Ἰησοῦ πρὸ χρόνων αἰωνίων,
55001010 φανερωθεῖσαν δὲ νῦν, διὰ τῆς ἐπιφανείας τοῦ Σωτῆρος ἡμῶν, ˚Χριστοῦ ˚Ἰησοῦ, καταργήσαντος μὲν τὸν θάνατον, φωτίσαντος δὲ ζωὴν καὶ ἀφθαρσίαν διὰ τοῦ εὐαγγελίου,
55001011 εἰς ὃ ἐτέθην ἐγὼ κῆρυξ, καὶ ἀπόστολος, καὶ διδάσκαλος.
55001012 Διʼ ἣν αἰτίαν καὶ ταῦτα πάσχω, ἀλλʼ οὐκ ἐπαισχύνομαι, οἶδα γὰρ ᾧ πεπίστευκα, καὶ πέπεισμαι ὅτι δυνατός ἐστιν, τὴν παραθήκην μου φυλάξαι εἰς ἐκείνην τὴν ἡμέραν.
55001013 Ὑποτύπωσιν ἔχε ὑγιαινόντων λόγων, ὧν παρʼ ἐμοῦ ἤκουσας, ἐν πίστει καὶ ἀγάπῃ τῇ ἐν ˚Χριστῷ ˚Ἰησοῦ.
55001014 Τὴν καλὴν παραθήκην φύλαξον, διὰ ˚Πνεύματος Ἁγίου τοῦ ἐνοικοῦντος ἐν ἡμῖν.
55001015 Οἶδας τοῦτο, ὅτι ἀπεστράφησάν με πάντες οἱ ἐν τῇ Ἀσίᾳ, ὧν ἐστιν Φύγελος καὶ Ἑρμογένης.
55001016 Δῴη ἔλεος ὁ ˚Κύριος τῷ Ὀνησιφόρου οἴκῳ, ὅτι πολλάκις με ἀνέψυξεν, καὶ τὴν ἅλυσίν μου οὐκ ἐπαισχύνθη,
55001017 ἀλλὰ γενόμενος ἐν Ῥώμῃ, σπουδαίως ἐζήτησέν με καὶ εὗρεν
55001018 ( δῴη αὐτῷ ὁ ˚Κύριος, εὑρεῖν ἔλεος παρὰ ˚Κυρίου ἐν ἐκείνῃ τῇ ἡμέρᾳ ), καὶ ὅσα ἐν Ἐφέσῳ διηκόνησεν, βέλτιον σὺ γινώσκεις.
55002001 Σὺ οὖν, τέκνον μου, ἐνδυναμοῦ ἐν τῇ χάριτι τῇ ἐν ˚Χριστῷ ˚Ἰησοῦ.
55002002 Καὶ ἃ ἤκουσας παρʼ ἐμοῦ διὰ πολλῶν μαρτύρων, ταῦτα παράθου πιστοῖς ἀνθρώποις, οἵτινες ἱκανοὶ ἔσονται καὶ ἑτέρους διδάξαι.
55002003 Συγκακοπάθησον ὡς καλὸς στρατιώτης ˚Χριστοῦ ˚Ἰησοῦ.
55002004 Οὐδεὶς στρατευόμενος ἐμπλέκεται ταῖς τοῦ βίου πραγματείαις, ἵνα τῷ στρατολογήσαντι ἀρέσῃ.
55002005 Ἐὰν δὲ καὶ ἀθλῇ τις, οὐ στεφανοῦται, ἐὰν μὴ νομίμως ἀθλήσῃ.
55002006 Τὸν κοπιῶντα γεωργὸν δεῖ πρῶτον τῶν καρπῶν μεταλαμβάνειν.
55002007 Νόει ὃ λέγω, δώσει γάρ σοι ὁ ˚Κύριος σύνεσιν ἐν πᾶσιν.
55002008 Μνημόνευε ˚Ἰησοῦν ˚Χριστὸν, ἐγηγερμένον ἐκ νεκρῶν, ἐκ σπέρματος Δαυίδ, κατὰ τὸ εὐαγγέλιόν μου,
55002009 ἐν ᾧ κακοπαθῶ μέχρι δεσμῶν ὡς κακοῦργος. Ἀλλὰ ὁ λόγος τοῦ ˚Θεοῦ οὐ δέδεται!
55002010 Διὰ τοῦτο, πάντα ὑπομένω διὰ τοὺς ἐκλεκτούς, ἵνα καὶ αὐτοὶ σωτηρίας τύχωσιν τῆς ἐν ˚Χριστῷ ˚Ἰησοῦ μετὰ δόξης αἰωνίου.
55002011 Πιστὸς ὁ λόγος: “Εἰ γὰρ συναπεθάνομεν, καὶ συζήσομεν·
55002012 εἰ ὑπομένομεν, καὶ συμβασιλεύσομεν· εἰ ἀρνησόμεθα, κἀκεῖνος ἀρνήσεται ἡμᾶς·
55002013 εἰ ἀπιστοῦμεν, ἐκεῖνος πιστὸς μένει· ἀρνήσασθαι γὰρ ἑαυτὸν οὐ δύναται.”
55002014 Ταῦτα ὑπομίμνῃσκε, διαμαρτυρόμενος ἐνώπιον τοῦ ˚Θεοῦ, μὴ λογομαχεῖν ἐπʼ οὐδὲν χρήσιμον, ἐπὶ καταστροφῇ τῶν ἀκουόντων.
55002015 Σπούδασον σεαυτὸν δόκιμον παραστῆσαι τῷ ˚Θεῷ, ἐργάτην ἀνεπαίσχυντον, ὀρθοτομοῦντα τὸν λόγον τῆς ἀληθείας.
55002016 Τὰς δὲ βεβήλους κενοφωνίας περιΐστασο, ἐπὶ πλεῖον γὰρ προκόψουσιν ἀσεβείας,
55002017 καὶ ὁ λόγος αὐτῶν ὡς γάγγραινα νομὴν ἕξει· ὧν ἐστιν Ὑμέναιος, καὶ Φίλητος,
55002018 οἵτινες περὶ τὴν ἀλήθειαν ἠστόχησαν, λέγοντες τὴν ἀνάστασιν ἤδη γεγονέναι, καὶ ἀνατρέπουσιν τήν τινων πίστιν.
55002019 Ὁ μέντοι στερεὸς θεμέλιος τοῦ ˚Θεοῦ ἕστηκεν, ἔχων τὴν σφραγῖδα ταύτην: “Ἔγνω ˚Κύριος τοὺς ὄντας αὐτοῦ”, καί, “Ἀποστήτω ἀπὸ ἀδικίας πᾶς ὁ ὀνομάζων τὸ ὄνομα ˚Κυρίου.”
55002020 Ἐν μεγάλῃ δὲ οἰκίᾳ, οὐκ ἔστιν μόνον σκεύη χρυσᾶ καὶ ἀργυρᾶ, ἀλλὰ καὶ ξύλινα καὶ ὀστράκινα, καὶ ἃ μὲν εἰς τιμὴν, ἃ δὲ εἰς ἀτιμίαν.
55002021 Ἐὰν οὖν τις ἐκκαθάρῃ ἑαυτὸν ἀπὸ τούτων, ἔσται σκεῦος εἰς τιμήν, ἡγιασμένον εὔχρηστον τῷ Δεσπότῃ, εἰς πᾶν ἔργον ἀγαθὸν ἡτοιμασμένον.
55002022 Τὰς δὲ νεωτερικὰς ἐπιθυμίας φεῦγε, δίωκε δὲ δικαιοσύνην, πίστιν, ἀγάπην, εἰρήνην, μετὰ τῶν ἐπικαλουμένων τὸν ˚Κύριον ἐκ καθαρᾶς καρδίας.
55002023 Τὰς δὲ μωρὰς καὶ ἀπαιδεύτους ζητήσεις παραιτοῦ, εἰδὼς ὅτι γεννῶσι μάχας.
55002024 Δοῦλον δὲ ˚Κυρίου οὐ δεῖ μάχεσθαι, ἀλλὰ ἤπιον εἶναι πρὸς πάντας, διδακτικόν, ἀνεξίκακον,
55002025 ἐν πραΰτητι παιδεύοντα τοὺς ἀντιδιατιθεμένους, μήποτε δώῃ αὐτοῖς ὁ ˚Θεὸς μετάνοιαν εἰς ἐπίγνωσιν ἀληθείας,
55002026 καὶ ἀνανήψωσιν ἐκ τῆς τοῦ διαβόλου παγίδος, ἐζωγρημένοι ὑπʼ αὐτοῦ, εἰς τὸ ἐκείνου θέλημα.
55003001 Τοῦτο δὲ γίνωσκε, ὅτι ἐν ἐσχάταις ἡμέραις ἐνστήσονται καιροὶ χαλεποί.
55003002 Ἔσονται γὰρ οἱ ἄνθρωποι φίλαυτοι, φιλάργυροι, ἀλαζόνες, ὑπερήφανοι, βλάσφημοι, γονεῦσιν ἀπειθεῖς, ἀχάριστοι, ἀνόσιοι,
55003003 ἄστοργοι, ἄσπονδοι, διάβολοι, ἀκρατεῖς, ἀνήμεροι, ἀφιλάγαθοι,
55003004 προδόται, προπετεῖς, τετυφωμένοι, φιλήδονοι μᾶλλον ἢ φιλόθεοι,
55003005 ἔχοντες μόρφωσιν εὐσεβείας, τὴν δὲ δύναμιν αὐτῆς ἠρνημένοι· καὶ τούτους ἀποτρέπου.
55003006 Ἐκ τούτων γάρ, εἰσιν οἱ ἐνδύνοντες εἰς τὰς οἰκίας, καὶ αἰχμαλωτίζοντες γυναικάρια, σεσωρευμένα ἁμαρτίαις, ἀγόμενα ἐπιθυμίαις ποικίλαις,
55003007 πάντοτε μανθάνοντα, καὶ μηδέποτε εἰς ἐπίγνωσιν ἀληθείας ἐλθεῖν δυνάμενα.
55003008 Ὃν τρόπον δὲ Ἰάννης καὶ Ἰαμβρῆς ἀντέστησαν Μωϋσεῖ, οὕτως καὶ οὗτοι ἀνθίστανται τῇ ἀληθείᾳ, ἄνθρωποι κατεφθαρμένοι τὸν νοῦν, ἀδόκιμοι περὶ τὴν πίστιν.
55003009 Ἀλλʼ οὐ προκόψουσιν ἐπὶ πλεῖον, ἡ γὰρ ἄνοια αὐτῶν ἔκδηλος ἔσται πᾶσιν, ὡς καὶ ἡ ἐκείνων ἐγένετο.
55003010 Σὺ δὲ παρηκολούθησάς μου τῇ διδασκαλίᾳ, τῇ ἀγωγῇ, τῇ προθέσει, τῇ πίστει, τῇ μακροθυμίᾳ, τῇ ἀγάπῃ, τῇ ὑπομονῇ,
55003011 τοῖς διωγμοῖς, τοῖς παθήμασιν, οἷά μοι ἐγένετο ἐν Ἀντιοχείᾳ, ἐν Ἰκονίῳ, ἐν Λύστροις, οἵους διωγμοὺς ὑπήνεγκα, καὶ ἐκ πάντων, με ἐρρύσατο ὁ ˚Κύριος.
55003012 Καὶ πάντες δὲ οἱ θέλοντες ζῆν εὐσεβῶς ἐν ˚Χριστῷ ˚Ἰησοῦ διωχθήσονται.
55003013 Πονηροὶ δὲ ἄνθρωποι καὶ γόητες προκόψουσιν ἐπὶ τὸ χεῖρον, πλανῶντες καὶ πλανώμενοι.
55003014 Σὺ δὲ μένε ἐν οἷς ἔμαθες καὶ ἐπιστώθης, εἰδὼς παρὰ τίνων ἔμαθες,
55003015 καὶ ὅτι ἀπὸ βρέφους τὰ ἱερὰ γράμματα οἶδας, τὰ δυνάμενά σε σοφίσαι εἰς σωτηρίαν διὰ πίστεως τῆς ἐν ˚Χριστῷ ˚Ἰησοῦ.
55003016 Πᾶσα Γραφὴ θεόπνευστος καὶ ὠφέλιμος πρὸς διδασκαλίαν, πρὸς ἐλεγμόν, πρὸς ἐπανόρθωσιν, πρὸς παιδείαν τὴν ἐν δικαιοσύνῃ,
55003017 ἵνα ἄρτιος ᾖ ὁ τοῦ ˚Θεοῦ ἄνθρωπος, πρὸς πᾶν ἔργον ἀγαθὸν ἐξηρτισμένος.
55004001 Διαμαρτύρομαι ἐνώπιον τοῦ ˚Θεοῦ καὶ ˚Χριστοῦ ˚Ἰησοῦ, τοῦ μέλλοντος κρίνειν ζῶντας καὶ νεκρούς, καὶ τὴν ἐπιφάνειαν αὐτοῦ, καὶ τὴν βασιλείαν αὐτοῦ:
55004002 κήρυξον τὸν λόγον, ἐπίστηθι εὐκαίρως, ἀκαίρως, ἔλεγξον, ἐπιτίμησον, παρακάλεσον, ἐν πάσῃ μακροθυμίᾳ καὶ διδαχῇ.
55004003 Ἔσται γὰρ καιρὸς ὅτε τῆς ὑγιαινούσης διδασκαλίας οὐκ ἀνέξονται, ἀλλὰ κατὰ τὰς ἰδίας ἐπιθυμίας, ἑαυτοῖς ἐπισωρεύσουσιν διδασκάλους, κνηθόμενοι τὴν ἀκοήν,
55004004 καὶ ἀπὸ μὲν τῆς ἀληθείας τὴν ἀκοὴν ἀποστρέψουσιν, ἐπὶ δὲ τοὺς μύθους ἐκτραπήσονται.
55004005 Σὺ δὲ νῆφε ἐν πᾶσιν, κακοπάθησον, ἔργον ποίησον εὐαγγελιστοῦ, τὴν διακονίαν σου πληροφόρησον.
55004006 Ἐγὼ γὰρ ἤδη σπένδομαι, καὶ ὁ καιρὸς τῆς ἀναλύσεώς μου ἐφέστηκεν.
55004007 Τὸν καλὸν ἀγῶνα ἠγώνισμαι, τὸν δρόμον τετέλεκα, τὴν πίστιν τετήρηκα.
55004008 Λοιπὸν ἀπόκειταί μοι ὁ τῆς δικαιοσύνης στέφανος, ὃν ἀποδώσει μοι ὁ ˚Κύριος ἐν, ἐκείνῃ τῇ ἡμέρᾳ, ὁ δίκαιος κριτής, οὐ μόνον δὲ ἐμοὶ, ἀλλὰ καὶ πᾶσιν τοῖς ἠγαπηκόσι τὴν ἐπιφάνειαν αὐτοῦ.
55004009 Σπούδασον ἐλθεῖν πρός με ταχέως·
55004010 Δημᾶς γάρ με ἐγκατέλιπεν, ἀγαπήσας τὸν νῦν αἰῶνα, καὶ ἐπορεύθη εἰς Θεσσαλονίκην, Κρήσκης εἰς Γαλατίαν, Τίτος εἰς Δαλματίαν.
55004011 Λουκᾶς ἐστιν μόνος μετʼ ἐμοῦ. Μᾶρκον ἀναλαβὼν, ἄγε μετὰ σεαυτοῦ, ἔστιν γάρ μοι εὔχρηστος εἰς διακονίαν.
55004012 Τυχικὸν δὲ ἀπέστειλα εἰς Ἔφεσον.
55004013 Τὸν φαιλόνην ὃν ἀπέλιπον ἐν Τρῳάδι παρὰ Κάρπῳ, ἐρχόμενος φέρε, καὶ τὰ βιβλία, μάλιστα τὰς μεμβράνας.
55004014 Ἀλέξανδρος ὁ χαλκεὺς, πολλά μοι κακὰ ἐνεδείξατο· ἀποδώσει αὐτῷ ὁ ˚Κύριος κατὰ τὰ ἔργα αὐτοῦ·
55004015 ὃν καὶ σὺ φυλάσσου, λίαν γὰρ ἀντέστη τοῖς ἡμετέροις λόγοις.
55004016 Ἐν τῇ πρώτῃ μου ἀπολογίᾳ, οὐδείς μοι παρεγένετο, ἀλλὰ πάντες με ἐγκατέλιπον· μὴ αὐτοῖς λογισθείη.
55004017 Ὁ δὲ ˚Κύριός μοι παρέστη, καὶ ἐνεδυνάμωσέν με, ἵνα διʼ ἐμοῦ τὸ κήρυγμα πληροφορηθῇ, καὶ ἀκούσωσιν πάντα τὰ ἔθνη, καὶ ἐρρύσθην ἐκ στόματος λέοντος.
55004018 Ῥύσεταί με ὁ ˚Κύριος ἀπὸ παντὸς ἔργου πονηροῦ, καὶ σώσει εἰς τὴν βασιλείαν αὐτοῦ τὴν ἐπουράνιον· ᾧ ἡ δόξα εἰς τοὺς αἰῶνας τῶν αἰώνων. Ἀμήν!
55004019 Ἄσπασαι Πρίσκαν καὶ Ἀκύλαν, καὶ τὸν Ὀνησιφόρου οἶκον.
55004020 Ἔραστος ἔμεινεν ἐν Κορίνθῳ, Τρόφιμον δὲ ἀπέλιπον ἐν Μιλήτῳ ἀσθενοῦντα.
55004021 Σπούδασον πρὸ χειμῶνος ἐλθεῖν. Ἀσπάζεταί σε Εὔβουλος, καὶ Πούδης, καὶ Λίνος, καὶ Κλαυδία, καὶ οἱ ἀδελφοὶ πάντες.
55004022 Ὁ ˚Κύριος ˚Ἰησοῦς μετὰ τοῦ πνεύματός σου. Ἡ χάρις μεθʼ ὑμῶν.
56001001 Παῦλος, δοῦλος ˚Θεοῦ, ἀπόστολος δὲ ˚Ἰησοῦ ˚Χριστοῦ, κατὰ πίστιν ἐκλεκτῶν ˚Θεοῦ, καὶ ἐπίγνωσιν ἀληθείας, τῆς κατʼ εὐσέβειαν
56001002 ἐπʼ ἐλπίδι ζωῆς αἰωνίου, ἣν ἐπηγγείλατο ὁ ἀψευδὴς ˚Θεὸς πρὸ χρόνων αἰωνίων,
56001003 ἐφανέρωσεν δὲ καιροῖς ἰδίοις τὸν λόγον αὐτοῦ ἐν κηρύγματι, ὃ ἐπιστεύθην ἐγὼ, κατʼ ἐπιταγὴν τοῦ Σωτῆρος ἡμῶν ˚Θεοῦ,
56001004 Τίτῳ, γνησίῳ τέκνῳ, κατὰ κοινὴν πίστιν: χάρις καὶ εἰρήνη ἀπὸ ˚Θεοῦ Πατρὸς καὶ ˚Χριστοῦ ˚Ἰησοῦ τοῦ Σωτῆρος ἡμῶν.
56001005 Τούτου χάριν, ἀπέλιπόν σε ἐν Κρήτῃ, ἵνα τὰ λείποντα ἐπιδιορθώσῃ, καὶ καταστήσῃς κατὰ πόλιν πρεσβυτέρους, ὡς ἐγώ σοι διεταξάμην,
56001006 εἴ τίς ἐστιν ἀνέγκλητος, μιᾶς γυναικὸς ἀνήρ, τέκνα ἔχων πιστά, μὴ ἐν κατηγορίᾳ ἀσωτίας ἢ ἀνυπότακτα.
56001007 Δεῖ γὰρ τὸν ἐπίσκοπον ἀνέγκλητον εἶναι, ὡς ˚Θεοῦ οἰκονόμον, μὴ αὐθάδη, μὴ ὀργίλον, μὴ πάροινον, μὴ πλήκτην, μὴ αἰσχροκερδῆ,
56001008 ἀλλὰ φιλόξενον, φιλάγαθον, σώφρονα, δίκαιον, ὅσιον, ἐγκρατῆ,
56001009 ἀντεχόμενον τοῦ κατὰ τὴν διδαχὴν πιστοῦ λόγου, ἵνα δυνατὸς ᾖ, καὶ παρακαλεῖν ἐν τῇ διδασκαλίᾳ τῇ ὑγιαινούσῃ, καὶ τοὺς ἀντιλέγοντας ἐλέγχειν.
56001010 εἰσὶν γὰρ πολλοὶ ἀνυπότακτοι, ματαιολόγοι, καὶ φρεναπάται, μάλιστα οἱ ἐκ τῆς περιτομῆς,
56001011 οὓς δεῖ ἐπιστομίζειν, οἵτινες ὅλους οἴκους ἀνατρέπουσιν, διδάσκοντες ἃ μὴ δεῖ, αἰσχροῦ κέρδους χάριν.
56001012 Εἶπέν τις ἐξ αὐτῶν, ἴδιος αὐτῶν προφήτης, “Κρῆτες ἀεὶ ψεῦσται, κακὰ θηρία, γαστέρες ἀργαί.”
56001013 Ἡ μαρτυρία αὕτη ἐστὶν ἀληθής. Διʼ ἣν αἰτίαν ἔλεγχε αὐτοὺς ἀποτόμως, ἵνα ὑγιαίνωσιν ἐν τῇ πίστει,
56001014 μὴ προσέχοντες Ἰουδαϊκοῖς μύθοις καὶ ἐντολαῖς ἀνθρώπων, ἀποστρεφομένων τὴν ἀλήθειαν.
56001015 Πάντα καθαρὰ τοῖς καθαροῖς· τοῖς δὲ μεμιαμμένοις καὶ ἀπίστοις, οὐδὲν καθαρόν, ἀλλὰ μεμίανται αὐτῶν καὶ ὁ νοῦς, καὶ ἡ συνείδησις.
56001016 ˚Θεὸν ὁμολογοῦσιν εἰδέναι, τοῖς δὲ ἔργοις ἀρνοῦνται, βδελυκτοὶ ὄντες καὶ ἀπειθεῖς, καὶ πρὸς πᾶν ἔργον ἀγαθὸν ἀδόκιμοι.
56002001 Σὺ δὲ λάλει ἃ πρέπει τῇ ὑγιαινούσῃ διδασκαλίᾳ.
56002002 Πρεσβύτας νηφαλίους εἶναι, σεμνούς, σώφρονας, ὑγιαίνοντας τῇ πίστει, τῇ ἀγάπῃ, τῇ ὑπομονῇ.
56002003 Πρεσβύτιδας ὡσαύτως ἐν καταστήματι ἱεροπρεπεῖς, μὴ διαβόλους, μηδὲ οἴνῳ πολλῷ δεδουλωμένας, καλοδιδασκάλους,
56002004 ἵνα σωφρονίζωσιν τὰς νέας φιλάνδρους εἶναι, φιλοτέκνους,
56002005 σώφρονας, ἁγνάς, οἰκουργούς, ἀγαθάς, ὑποτασσομένας τοῖς ἰδίοις ἀνδράσιν, ἵνα μὴ ὁ λόγος τοῦ ˚Θεοῦ βλασφημῆται.
56002006 Τοὺς νεωτέρους ὡσαύτως παρακάλει, σωφρονεῖν·
56002007 περὶ πάντα σεαυτὸν παρεχόμενος τύπον καλῶν ἔργων, ἐν τῇ διδασκαλίᾳ ἀφθορίαν, σεμνότητα,
56002008 λόγον ὑγιῆ, ἀκατάγνωστον, ἵνα ὁ ἐξ ἐναντίας ἐντραπῇ, μηδὲν ἔχων λέγειν περὶ ἡμῶν φαῦλον.
56002009 Δούλους ἰδίοις δεσπόταις ὑποτάσσεσθαι ἐν πᾶσιν, εὐαρέστους εἶναι, μὴ ἀντιλέγοντας,
56002010 μὴ νοσφιζομένους, ἀλλὰ πᾶσαν πίστιν ἐνδεικνυμένους ἀγαθήν, ἵνα τὴν διδασκαλίαν τὴν τοῦ Σωτῆρος ἡμῶν, ˚Θεοῦ, κοσμῶσιν ἐν πᾶσιν.
56002011 Ἐπεφάνη γὰρ ἡ χάρις τοῦ ˚Θεοῦ, σωτήριος πᾶσιν ἀνθρώποις,
56002012 παιδεύουσα ἡμᾶς ἵνα ἀρνησάμενοι τὴν ἀσέβειαν καὶ τὰς κοσμικὰς ἐπιθυμίας, σωφρόνως καὶ δικαίως, καὶ εὐσεβῶς, ζήσωμεν ἐν τῷ νῦν αἰῶνι,
56002013 προσδεχόμενοι τὴν μακαρίαν ἐλπίδα, καὶ ἐπιφάνειαν τῆς δόξης τοῦ μεγάλου ˚Θεοῦ καὶ Σωτῆρος ἡμῶν, ˚Ἰησοῦ· ˚Χριστοῦ
56002014 ὃς ἔδωκεν ἑαυτὸν ὑπὲρ ἡμῶν, ἵνα λυτρώσηται ἡμᾶς ἀπὸ πάσης ἀνομίας, καὶ καθαρίσῃ ἑαυτῷ λαὸν περιούσιον, ζηλωτὴν καλῶν ἔργων.
56002015 Ταῦτα λάλει, καὶ παρακάλει, καὶ ἔλεγχε, μετὰ πάσης ἐπιταγῆς. Μηδείς σου περιφρονείτω.
56003001 Ὑπομίμνῃσκε αὐτοὺς ἀρχαῖς, ἐξουσίαις, ὑποτάσσεσθαι, πειθαρχεῖν, πρὸς πᾶν ἔργον ἀγαθὸν ἑτοίμους εἶναι,
56003002 μηδένα βλασφημεῖν, ἀμάχους εἶναι, ἐπιεικεῖς, πᾶσαν ἐνδεικνυμένους πραΰτητα πρὸς πάντας ἀνθρώπους.
56003003 Ἦμεν γάρ ποτε καὶ ἡμεῖς ἀνόητοι, ἀπειθεῖς, πλανώμενοι, δουλεύοντες ἐπιθυμίαις καὶ ἡδοναῖς ποικίλαις, ἐν κακίᾳ καὶ φθόνῳ διάγοντες, στυγητοί, μισοῦντες ἀλλήλους.
56003004 Ὅτε δὲ ἡ χρηστότης καὶ ἡ φιλανθρωπία ἐπεφάνη τοῦ Σωτῆρος ἡμῶν, ˚Θεοῦ,
56003005 οὐκ ἐξ ἔργων τῶν ἐν δικαιοσύνῃ, ἃ ἐποιήσαμεν ἡμεῖς, ἀλλὰ κατὰ τὸ αὐτοῦ ἔλεος, ἔσωσεν ἡμᾶς, διὰ λουτροῦ παλιγγενεσίας καὶ ἀνακαινώσεως ˚Πνεύματος Ἁγίου,
56003006 οὗ ἐξέχεεν ἐφʼ ἡμᾶς πλουσίως, διὰ ˚Ἰησοῦ ˚Χριστοῦ, τοῦ Σωτῆρος ἡμῶν,
56003007 ἵνα δικαιωθέντες τῇ ἐκείνου χάριτι, κληρονόμοι γενηθῶμεν, κατʼ ἐλπίδα ζωῆς αἰωνίου.
56003008 Πιστὸς ὁ λόγος, καὶ περὶ τούτων βούλομαί σε διαβεβαιοῦσθαι, “Ἵνα φροντίζωσιν καλῶν ἔργων προΐστασθαι, οἱ πεπιστευκότες ˚Θεῷ.” Ταῦτά ἐστιν καλὰ καὶ ὠφέλιμα τοῖς ἀνθρώποις.
56003009 Μωρὰς δὲ ζητήσεις, καὶ γενεαλογίας, καὶ ἔρεις, καὶ μάχας νομικὰς, περιΐστασο, εἰσὶν γὰρ ἀνωφελεῖς καὶ μάταιοι.
56003010 Αἱρετικὸν ἄνθρωπον μετὰ μίαν καὶ δευτέραν νουθεσίαν παραιτοῦ,
56003011 εἰδὼς ὅτι ἐξέστραπται ὁ τοιοῦτος καὶ ἁμαρτάνει, ὢν αὐτοκατάκριτος.
56003012 Ὅταν πέμψω Ἀρτεμᾶν πρὸς σὲ ἢ Τυχικόν, σπούδασον ἐλθεῖν πρός με εἰς Νικόπολιν, ἐκεῖ γὰρ κέκρικα παραχειμάσαι.
56003013 Ζηνᾶν τὸν νομικὸν καὶ Ἀπολλῶν σπουδαίως πρόπεμψον, ἵνα μηδὲν αὐτοῖς λείπῃ.
56003014 Μανθανέτωσαν δὲ καὶ οἱ ἡμέτεροι καλῶν ἔργων προΐστασθαι εἰς τὰς ἀναγκαίας χρείας, ἵνα μὴ ὦσιν ἄκαρποι.
56003015 Ἀσπάζονταί σε οἱ μετʼ ἐμοῦ πάντες. Ἄσπασαι τοὺς φιλοῦντας ἡμᾶς ἐν πίστει. Ἡ χάρις μετὰ πάντων ὑμῶν.
57001001 Παῦλος, δέσμιος ˚Χριστοῦ ˚Ἰησοῦ, καὶ Τιμόθεος, ὁ ἀδελφὸς, Φιλήμονι, τῷ ἀγαπητῷ καὶ συνεργῷ ἡμῶν,
57001002 καὶ Ἀπφίᾳ, τῇ ἀδελφῇ, καὶ Ἀρχίππῳ, τῷ συστρατιώτῃ ἡμῶν, καὶ τῇ κατʼ οἶκόν σου ἐκκλησίᾳ:
57001003 χάρις ὑμῖν καὶ εἰρήνη, ἀπὸ ˚Θεοῦ Πατρὸς ἡμῶν καὶ ˚Κυρίου ˚Ἰησοῦ ˚Χριστοῦ.
57001004 Εὐχαριστῶ τῷ ˚Θεῷ μου πάντοτε, μνείαν σου ποιούμενος ἐπὶ τῶν προσευχῶν μου,
57001005 ἀκούων σου τὴν ἀγάπην καὶ τὴν πίστιν, ἣν ἔχεις εἰς τὸν ˚Κύριον ˚Ἰησοῦν, καὶ εἰς πάντας τοὺς ἁγίους,
57001006 ὅπως ἡ κοινωνία τῆς πίστεώς σου, ἐνεργὴς γένηται ἐν ἐπιγνώσει παντὸς ἀγαθοῦ τοῦ ἐν ἡμῖν εἰς ˚Χριστόν.
57001007 Χαρὰν γὰρ πολλὴν ἔσχον καὶ παράκλησιν, ἐπὶ τῇ ἀγάπῃ σου, ὅτι τὰ σπλάγχνα τῶν ἁγίων ἀναπέπαυται διὰ σοῦ, ἀδελφέ.
57001008 Διό, πολλὴν ἐν ˚Χριστῷ παρρησίαν ἔχων, ἐπιτάσσειν σοι τὸ ἀνῆκον,
57001009 διὰ τὴν ἀγάπην μᾶλλον παρακαλῶ τοιοῦτος ὢν, ὡς Παῦλος ( πρεσβύτης νυνὶ δὲ καὶ δέσμιος ˚Χριστοῦ ˚Ἰησοῦ ),
57001010 παρακαλῶ σε περὶ τοῦ ἐμοῦ τέκνου, ὃν ἐγέννησα ἐν τοῖς δεσμοῖς, Ὀνήσιμον,
57001011 τόν ποτέ σοι ἄχρηστον, νυνὶ δὲ σοὶ καὶ ἐμοὶ εὔχρηστον,
57001012 ὃν ἀνέπεμψά σοι αὐτόν, τοῦτʼ ἔστιν τὰ ἐμὰ σπλάγχνα,
57001013 ὃν ἐγὼ ἐβουλόμην πρὸς ἐμαυτὸν κατέχειν, ἵνα ὑπὲρ σοῦ μοι διακονῇ ἐν τοῖς δεσμοῖς τοῦ εὐαγγελίου,
57001014 χωρὶς δὲ τῆς σῆς γνώμης, οὐδὲν ἠθέλησα ποιῆσαι, ἵνα μὴ ὡς κατὰ ἀνάγκην τὸ ἀγαθόν σου ᾖ, ἀλλὰ κατὰ ἑκούσιον.
57001015 Τάχα γὰρ διὰ τοῦτο, ἐχωρίσθη πρὸς ὥραν, ἵνα αἰώνιον αὐτὸν ἀπέχῃς,
57001016 οὐκέτι ὡς δοῦλον, ἀλλʼ ὑπὲρ δοῦλον, ἀδελφὸν ἀγαπητόν, μάλιστα ἐμοί πόσῳ δὲ μᾶλλον σοὶ, καὶ ἐν σαρκὶ καὶ ἐν ˚Κυρίῳ.
57001017 Εἰ οὖν με ἔχεις κοινωνόν, προσλαβοῦ αὐτὸν ὡς ἐμέ.
57001018 Εἰ δέ τι ἠδίκησέν σε ἢ ὀφείλει, τοῦτο ἐμοὶ ἐλλόγα.
57001019 Ἐγὼ Παῦλος ἔγραψα τῇ ἐμῇ χειρί, ἐγὼ ἀποτίσω· ἵνα μὴ λέγω σοι ὅτι καὶ σεαυτόν μοι προσοφείλεις.
57001020 Ναί, ἀδελφέ, ἐγώ σου ὀναίμην ἐν ˚Κυρίῳ· ἀνάπαυσόν μου τὰ σπλάγχνα ἐν ˚Χριστῷ.
57001021 Πεποιθὼς τῇ ὑπακοῇ σου, ἔγραψά σοι, εἰδὼς ὅτι καὶ ὑπὲρ ἃ λέγω, ποιήσεις.
57001022 Ἅμα δὲ καὶ ἑτοίμαζέ μοι ξενίαν, ἐλπίζω γὰρ ὅτι διὰ τῶν προσευχῶν ὑμῶν, χαρισθήσομαι ὑμῖν.
57001023 Ἀσπάζεταί σε Ἐπαφρᾶς, ὁ συναιχμάλωτός μου ἐν ˚Χριστῷ ˚Ἰησοῦ,
57001024 Μᾶρκος, Ἀρίσταρχος, Δημᾶς, Λουκᾶς, οἱ συνεργοί μου.
57001025 Ἡ χάρις τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ ˚Χριστοῦ, μετὰ τοῦ πνεύματος ὑμῶν. Ἀμήν!
58001001 Πολυμερῶς καὶ πολυτρόπως πάλαι, ὁ ˚Θεὸς, λαλήσας τοῖς πατράσιν ἐν τοῖς προφήταις
58001002 ἐπʼ ἐσχάτου τῶν ἡμερῶν τούτων, ἐλάλησεν ἡμῖν ἐν Υἱῷ, ὃν ἔθηκεν κληρονόμον πάντων, διʼ οὗ καὶ ἐποίησεν τοὺς αἰῶνας·
58001003 ὃς ὢν ἀπαύγασμα τῆς δόξης καὶ χαρακτὴρ τῆς ὑποστάσεως αὐτοῦ, φέρων τε τὰ πάντα τῷ ῥήματι τῆς δυνάμεως αὐτοῦ, καθαρισμὸν τῶν ἁμαρτιῶν “ποιησάμενος, ἐκάθισεν ἐν δεξιᾷ” τῆς Μεγαλωσύνης ἐν ὑψηλοῖς.
58001004 Τοσούτῳ κρείττων γενόμενος τῶν ἀγγέλων, ὅσῳ διαφορώτερον παρʼ αὐτοὺς, κεκληρονόμηκεν ὄνομα.
58001005 Τίνι γὰρ εἶπέν ποτε τῶν ἀγγέλων, “Υἱός μου εἶ σύ, ἐγὼ σήμερον γεγέννηκά σε;” Καὶ πάλιν, “Ἐγὼ ἔσομαι αὐτῷ εἰς Πατέρα, καὶ αὐτὸς ἔσται μοι εἰς Υἱόν;”
58001006 Ὅταν δὲ πάλιν εἰσαγάγῃ τὸν πρωτότοκον εἰς τὴν οἰκουμένην, λέγει, “Καὶ προσκυνησάτωσαν αὐτῷ πάντες ἄγγελοι ˚Θεοῦ.”
58001007 Καὶ πρὸς μὲν τοὺς ἀγγέλους λέγει, “Ὁ ποιῶν τοὺς ἀγγέλους αὐτοῦ πνεύματα, καὶ τοὺς λειτουργοὺς αὐτοῦ πυρὸς φλόγα”·
58001008 πρὸς δὲ τὸν Υἱόν, “Ὁ θρόνος σου, ὁ ˚Θεὸς, εἰς τὸν αἰῶνα τοῦ αἰῶνος, καὶ ἡ ῥάβδος τῆς εὐθύτητος ῥάβδος τῆς βασιλείας αὐτοῦ.
58001009 Ἠγάπησας δικαιοσύνην καὶ ἐμίσησας ἀνομίαν· διὰ τοῦτο, ἔχρισέν σε ὁ ˚Θεός, ὁ ˚Θεός σου, ἔλαιον ἀγαλλιάσεως παρὰ τοὺς μετόχους σου.”
58001010 Καί, “Σὺ κατʼ ἀρχάς, ˚Κύριε, τὴν γῆν ἐθεμελίωσας, καὶ ἔργα τῶν χειρῶν σού εἰσιν οἱ οὐρανοί.
58001011 Αὐτοὶ ἀπολοῦνται, σὺ δὲ διαμένεις· καὶ πάντες ὡς ἱμάτιον παλαιωθήσονται,
58001012 καὶ ὡσεὶ περιβόλαιον ἑλίξεις αὐτούς, ὡς ἱμάτιον καὶ ἀλλαγήσονται· σὺ δὲ ὁ αὐτὸς εἶ, καὶ τὰ ἔτη σου οὐκ ἐκλείψουσιν.”
58001013 Πρὸς τίνα δὲ τῶν ἀγγέλων εἴρηκέν ποτε, “Κάθου ἐκ δεξιῶν μου, ἕως ἂν θῶ τοὺς ἐχθρούς σου, ὑποπόδιον τῶν ποδῶν σου;”
58001014 Οὐχὶ πάντες εἰσὶν λειτουργικὰ πνεύματα, εἰς διακονίαν ἀποστελλόμενα, διὰ τοὺς μέλλοντας κληρονομεῖν σωτηρίαν;
58002001 Διὰ τοῦτο, δεῖ περισσοτέρως προσέχειν ἡμᾶς τοῖς ἀκουσθεῖσιν, μήποτε παραρυῶμεν.
58002002 Εἰ γὰρ ὁ διʼ ἀγγέλων λαληθεὶς λόγος ἐγένετο βέβαιος, καὶ πᾶσα παράβασις καὶ παρακοὴ ἔλαβεν ἔνδικον μισθαποδοσίαν,
58002003 πῶς ἡμεῖς ἐκφευξόμεθα τηλικαύτης ἀμελήσαντες σωτηρίας; Ἥτις ἀρχὴν λαβοῦσα, λαλεῖσθαι διὰ τοῦ ˚Κυρίου, ὑπὸ τῶν ἀκουσάντων εἰς ἡμᾶς ἐβεβαιώθη,
58002004 συνεπιμαρτυροῦντος τοῦ ˚Θεοῦ σημείοις τε, καὶ τέρασιν, καὶ ποικίλαις δυνάμεσιν, καὶ ˚Πνεύματος Ἁγίου μερισμοῖς, κατὰ τὴν αὐτοῦ θέλησιν.
58002005 Οὐ γὰρ ἀγγέλοις ὑπέταξεν τὴν οἰκουμένην τὴν μέλλουσαν, περὶ ἧς λαλοῦμεν.
58002006 Διεμαρτύρατο δέ πού τις λέγων, “Τί ἐστιν ἄνθρωπος, ὅτι μιμνῄσκῃ αὐτοῦ, ἢ υἱὸς ἀνθρώπου, ὅτι ἐπισκέπτῃ αὐτόν;
58002007 Ἠλάττωσας αὐτὸν, βραχύ τι παρʼ ἀγγέλους, δόξῃ καὶ τιμῇ ἐστεφάνωσας αὐτόν,
58002008 πάντα ὑπέταξας ὑποκάτω τῶν ποδῶν αὐτοῦ.” Ἐν γὰρ τῷ ὑποτάξαι αὐτῷ τὰ πάντα, οὐδὲν ἀφῆκεν αὐτῷ ἀνυπότακτον. Νῦν δὲ οὔπω ὁρῶμεν αὐτῷ τὰ πάντα ὑποτεταγμένα.
58002009 Τὸν δὲ βραχύ τι παρʼ ἀγγέλους ἠλαττωμένον, βλέπομεν ˚Ἰησοῦν, διὰ τὸ πάθημα τοῦ θανάτου, δόξῃ καὶ τιμῇ ἐστεφανωμένον, ὅπως χάριτι ˚Θεοῦ, ὑπὲρ παντὸς γεύσηται θανάτου.
58002010 Ἔπρεπεν γὰρ αὐτῷ, διʼ ὃν τὰ πάντα, καὶ διʼ οὗ τὰ πάντα, πολλοὺς υἱοὺς εἰς δόξαν ἀγαγόντα, τὸν ἀρχηγὸν τῆς σωτηρίας αὐτῶν, διὰ παθημάτων τελειῶσαι.
58002011 Ὅ τε γὰρ ἁγιάζων, καὶ οἱ ἁγιαζόμενοι, ἐξ ἑνὸς πάντες· διʼ ἣν αἰτίαν, οὐκ ἐπαισχύνεται ἀδελφοὺς αὐτοὺς καλεῖν
58002012 λέγων, “Ἀπαγγελῶ τὸ ὄνομά σου τοῖς ἀδελφοῖς μου, ἐν μέσῳ ἐκκλησίας ὑμνήσω σε.”
58002013 Καὶ πάλιν, “Ἐγὼ ἔσομαι πεποιθὼς ἐπʼ αὐτῷ.” Καὶ πάλιν, “Ἰδοὺ, ἐγὼ καὶ τὰ παιδία, ἅ μοι ἔδωκεν ὁ ˚Θεός.”
58002014 Ἐπεὶ οὖν τὰ παιδία κεκοινώνηκεν αἵματος καὶ σαρκός, καὶ αὐτὸς παραπλησίως μετέσχεν τῶν αὐτῶν, ἵνα διὰ τοῦ θανάτου, καταργήσῃ τὸν τὸ κράτος ἔχοντα τοῦ θανάτου, τοῦτʼ ἔστιν τὸν διάβολον,
58002015 καὶ ἀπαλλάξῃ τούτους, ὅσοι φόβῳ θανάτου, διὰ παντὸς τοῦ ζῆν, ἔνοχοι ἦσαν δουλείας.
58002016 Οὐ γὰρ δήπου ἀγγέλων ἐπιλαμβάνεται, ἀλλὰ σπέρματος Ἀβραὰμ ἐπιλαμβάνεται.
58002017 Ὅθεν ὤφειλεν κατὰ πάντα τοῖς ἀδελφοῖς ὁμοιωθῆναι, ἵνα ἐλεήμων γένηται καὶ πιστὸς ἀρχιερεὺς τὰ πρὸς τὸν ˚Θεόν, εἰς τὸ ἱλάσκεσθαι τὰς ἁμαρτίας τοῦ λαοῦ.
58002018 Ἐν ᾧ γὰρ πέπονθεν αὐτὸς πειρασθείς, δύναται τοῖς πειραζομένοις βοηθῆσαι.
58003001 Ὅθεν, ἀδελφοὶ ἅγιοι κλήσεως ἐπουρανίου, μέτοχοι, κατανοήσατε τὸν ἀπόστολον καὶ ἀρχιερέα τῆς ὁμολογίας ἡμῶν, ˚Ἰησοῦν,
58003002 πιστὸν ὄντα τῷ ποιήσαντι αὐτὸν, ὡς καὶ Μωϋσῆς ἐν τῷ οἴκῳ αὐτοῦ.
58003003 Πλείονος γὰρ οὗτος δόξης παρὰ Μωϋσῆν ἠξίωται, καθʼ ὅσον πλείονα τιμὴν ἔχει τοῦ οἴκου, ὁ κατασκευάσας αὐτόν.
58003004 Πᾶς γὰρ οἶκος κατασκευάζεται ὑπό τινος, ὁ δὲ πάντα κατασκευάσας ˚Θεός.
58003005 Καὶ Μωϋσῆς μὲν πιστὸς ἐν ὅλῳ τῷ οἴκῳ αὐτοῦ, ὡς θεράπων εἰς μαρτύριον τῶν λαληθησομένων,
58003006 ˚Χριστὸς δὲ ὡς Υἱὸς ἐπὶ τὸν οἶκον αὐτοῦ, οὗ οἶκός ἐσμεν ἡμεῖς, ἐὰν τὴν παρρησίαν καὶ τὸ καύχημα τῆς ἐλπίδος κατάσχωμεν.
58003007 Διό καθὼς λέγει τὸ ˚Πνεῦμα τὸ Ἅγιον, “Σήμερον ἐὰν τῆς φωνῆς αὐτοῦ ἀκούσητε,
58003008 μὴ σκληρύνητε τὰς καρδίας ὑμῶν, ὡς ἐν τῷ παραπικρασμῷ, κατὰ τὴν ἡμέραν τοῦ πειρασμοῦ, ἐν τῇ ἐρήμῳ,
58003009 οὗ ἐπείρασαν οἱ πατέρες ὑμῶν ἐν δοκιμασίᾳ, καὶ εἶδον τὰ ἔργα μου,
58003010 τεσσεράκοντα ἔτη. Διὸ προσώχθισα τῇ γενεᾷ ταύτῃ, καὶ εἶπον, ‘Ἀεὶ πλανῶνται τῇ καρδίᾳ· αὐτοὶ δὲ οὐκ ἔγνωσαν τὰς ὁδούς μου’·
58003011 ὡς ὤμοσα ἐν τῇ ὀργῇ μου, ‘Εἰ εἰσελεύσονται εἰς τὴν κατάπαυσίν μου.’”
58003012 Βλέπετε, ἀδελφοί, μήποτε ἔσται ἔν τινι ὑμῶν καρδία πονηρὰ ἀπιστίας, ἐν τῷ ἀποστῆναι ἀπὸ ˚Θεοῦ ζῶντος.
58003013 Ἀλλὰ παρακαλεῖτε ἑαυτοὺς καθʼ ἑκάστην ἡμέραν, ἄχρις οὗ, τὸ “σήμερον” καλεῖται, ἵνα μὴ σκληρυνθῇ τις ἐξ ὑμῶν ἀπάτῃ τῆς ἁμαρτίας.
58003014 Μέτοχοι γὰρ τοῦ ˚Χριστοῦ γεγόναμεν, ἐάνπερ τὴν ἀρχὴν τῆς ὑποστάσεως, μέχρι τέλους βεβαίαν κατάσχωμεν.
58003015 Ἐν τῷ λέγεσθαι, “Σήμερον ἐὰν τῆς φωνῆς αὐτοῦ ἀκούσητε, μὴ σκληρύνητε τὰς καρδίας ὑμῶν, ὡς ἐν τῷ παραπικρασμῷ.”
58003016 Τίνες γὰρ ἀκούσαντες παρεπίκραναν; Ἀλλʼ οὐ πάντες οἱ ἐξελθόντες ἐξ Αἰγύπτου διὰ Μωϋσέως;
58003017 Τίσιν δὲ προσώχθισεν τεσσεράκοντα ἔτη; Οὐχὶ τοῖς ἁμαρτήσασιν, ὧν τὰ κῶλα ἔπεσεν ἐν τῇ ἐρήμῳ;
58003018 Τίσιν δὲ ὤμοσεν μὴ εἰσελεύσεσθαι εἰς τὴν κατάπαυσιν αὐτοῦ, εἰ μὴ τοῖς ἀπειθήσασιν;
58003019 Καὶ βλέπομεν ὅτι οὐκ ἠδυνήθησαν εἰσελθεῖν διʼ ἀπιστίαν.
58004001 Φοβηθῶμεν οὖν, μήποτε καταλειπομένης ἐπαγγελίας εἰσελθεῖν εἰς τὴν κατάπαυσιν αὐτοῦ, δοκῇ τις ἐξ ὑμῶν ὑστερηκέναι.
58004002 Καὶ γάρ ἐσμεν εὐηγγελισμένοι καθάπερ κἀκεῖνοι, ἀλλʼ οὐκ ὠφέλησεν ὁ λόγος τῆς ἀκοῆς ἐκείνους, μὴ συγκεκερασμένους τῇ πίστει τοῖς ἀκούσασιν.
58004003 Εἰσερχόμεθα γὰρ εἰς κατάπαυσιν, οἱ πιστεύσαντες, καθὼς εἴρηκεν, “Ὡς ὤμοσα ἐν τῇ ὀργῇ μου, ‘Εἰ εἰσελεύσονται εἰς τὴν κατάπαυσίν μου’”, καίτοι τῶν ἔργων ἀπὸ καταβολῆς κόσμου γενηθέντων.
58004004 Εἴρηκεν γάρ που περὶ τῆς ἑβδόμης οὕτως: “Καὶ κατέπαυσεν ὁ ˚Θεὸς ἐν τῇ ἡμέρᾳ τῇ ἑβδόμῃ ἀπὸ πάντων τῶν ἔργων αὐτοῦ”,
58004005 καὶ ἐν τούτῳ πάλιν, “Εἰ εἰσελεύσονται εἰς τὴν κατάπαυσίν μου.”
58004006 Ἐπεὶ οὖν ἀπολείπεται τινὰς εἰσελθεῖν εἰς αὐτήν, καὶ οἱ πρότερον εὐαγγελισθέντες, οὐκ εἰσῆλθον διʼ ἀπείθειαν,
58004007 πάλιν τινὰ ὁρίζει ἡμέραν, “σήμερον”, ἐν Δαυὶδ λέγων μετὰ τοσοῦτον χρόνον, καθὼς προείρηται, “Σήμερον ἐὰν τῆς φωνῆς αὐτοῦ ἀκούσητε, μὴ σκληρύνητε τὰς καρδίας ὑμῶν.”
58004008 Εἰ γὰρ αὐτοὺς Ἰησοῦς κατέπαυσεν, οὐκ ἂν περὶ ἄλλης ἐλάλει μετὰ ταῦτα ἡμέρας.
58004009 Ἄρα ἀπολείπεται σαββατισμὸς τῷ λαῷ τοῦ ˚Θεοῦ.
58004010 Ὁ γὰρ εἰσελθὼν εἰς τὴν κατάπαυσιν αὐτοῦ, καὶ αὐτὸς κατέπαυσεν ἀπὸ τῶν ἔργων αὐτοῦ, ὥσπερ ἀπὸ τῶν ἰδίων ὁ ˚Θεός.
58004011 Σπουδάσωμεν οὖν εἰσελθεῖν εἰς ἐκείνην τὴν κατάπαυσιν, ἵνα μὴ ἐν τῷ αὐτῷ τις ὑποδείγματι πέσῃ τῆς ἀπειθείας.
58004012 Ζῶν γὰρ ὁ λόγος τοῦ ˚Θεοῦ, καὶ ἐνεργὴς, καὶ τομώτερος, ὑπὲρ πᾶσαν μάχαιραν δίστομον, καὶ διϊκνούμενος ἄχρι μερισμοῦ ψυχῆς καὶ πνεύματος, ἁρμῶν τε καὶ μυελῶν, καὶ κριτικὸς ἐνθυμήσεων καὶ ἐννοιῶν καρδίας.
58004013 Καὶ οὐκ ἔστιν κτίσις ἀφανὴς ἐνώπιον αὐτοῦ, πάντα δὲ γυμνὰ καὶ τετραχηλισμένα τοῖς ὀφθαλμοῖς αὐτοῦ, πρὸς ὃν ἡμῖν ὁ λόγος.
58004014 Ἔχοντες οὖν ἀρχιερέα μέγαν, διεληλυθότα τοὺς οὐρανούς, ˚Ἰησοῦν, τὸν Υἱὸν τοῦ ˚Θεοῦ, κρατῶμεν τῆς ὁμολογίας.
58004015 Οὐ γὰρ ἔχομεν ἀρχιερέα μὴ δυνάμενον συμπαθῆσαι ταῖς ἀσθενείαις ἡμῶν, πεπειρασμένον δὲ κατὰ πάντα καθʼ ὁμοιότητα χωρὶς ἁμαρτίας.
58004016 Προσερχώμεθα οὖν μετὰ παρρησίας τῷ θρόνῳ τῆς χάριτος, ἵνα λάβωμεν ἔλεος, καὶ χάριν εὕρωμεν, εἰς εὔκαιρον βοήθειαν.
58005001 Πᾶς γὰρ ἀρχιερεὺς ἐξ ἀνθρώπων λαμβανόμενος ὑπὲρ ἀνθρώπων καθίσταται τὰ πρὸς τὸν ˚Θεόν, ἵνα προσφέρῃ δῶρά τε καὶ θυσίας ὑπὲρ ἁμαρτιῶν,
58005002 μετριοπαθεῖν δυνάμενος τοῖς ἀγνοοῦσι καὶ πλανωμένοις, ἐπεὶ καὶ αὐτὸς περίκειται ἀσθένειαν,
58005003 καὶ διʼ αὐτὴν ὀφείλει, καθὼς περὶ τοῦ λαοῦ οὕτως καὶ περὶ αὑτοῦ, προσφέρειν περὶ ἁμαρτιῶν.
58005004 Καὶ οὐχ ἑαυτῷ τις λαμβάνει τὴν τιμήν, ἀλλὰ καλούμενος ὑπὸ τοῦ ˚Θεοῦ, καθώσπερ καὶ Ἀαρών.
58005005 Οὕτως καὶ ὁ ˚Χριστὸς οὐχ ἑαυτὸν ἐδόξασεν γενηθῆναι ἀρχιερέα, ἀλλʼ ὁ λαλήσας πρὸς αὐτόν, “Υἱός μου εἶ σύ, ἐγὼ σήμερον γεγέννηκά σε.”
58005006 Καθὼς καὶ ἐν ἑτέρῳ λέγει, “Σὺ ἱερεὺς εἰς τὸν αἰῶνα κατὰ τὴν τάξιν Μελχισέδεκ.”
58005007 Ὃς ἐν ταῖς ἡμέραις τῆς σαρκὸς αὐτοῦ, δεήσεις τε καὶ ἱκετηρίας, πρὸς τὸν δυνάμενον σῴζειν αὐτὸν ἐκ θανάτου, μετὰ κραυγῆς ἰσχυρᾶς καὶ δακρύων προσενέγκας, καὶ εἰσακουσθεὶς ἀπὸ τῆς εὐλαβείας.
58005008 Καίπερ ὢν υἱός, ἔμαθεν ἀφʼ ὧν ἔπαθεν τὴν ὑπακοήν,
58005009 καὶ τελειωθεὶς, ἐγένετο πᾶσιν τοῖς ὑπακούουσιν αὐτῷ αἴτιος σωτηρίας αἰωνίου,
58005010 προσαγορευθεὶς ὑπὸ τοῦ ˚Θεοῦ ἀρχιερεὺς κατὰ τὴν τάξιν Μελχισέδεκ.
58005011 Περὶ οὗ πολὺς ἡμῖν ὁ λόγος, καὶ δυσερμήνευτος λέγειν, ἐπεὶ νωθροὶ γεγόνατε ταῖς ἀκοαῖς.
58005012 Καὶ γὰρ ὀφείλοντες εἶναι διδάσκαλοι διὰ τὸν χρόνον, πάλιν χρείαν ἔχετε τοῦ διδάσκειν ὑμᾶς, τινὰ τὰ στοιχεῖα τῆς ἀρχῆς τῶν λογίων τοῦ ˚Θεοῦ, καὶ γεγόνατε χρείαν ἔχοντες γάλακτος, οὐ στερεᾶς τροφῆς.
58005013 Πᾶς γὰρ ὁ μετέχων γάλακτος ἄπειρος λόγου δικαιοσύνης, νήπιος γάρ ἐστιν.
58005014 Τελείων δέ ἐστιν ἡ στερεὰ τροφή, τῶν διὰ τὴν ἕξιν τὰ αἰσθητήρια γεγυμνασμένα, ἐχόντων πρὸς διάκρισιν, καλοῦ τε καὶ κακοῦ.
58006001 Διὸ ἀφέντες τὸν τῆς ἀρχῆς τοῦ ˚Χριστοῦ λόγον, ἐπὶ τὴν τελειότητα φερώμεθα, μὴ πάλιν θεμέλιον καταβαλλόμενοι μετανοίας ἀπὸ νεκρῶν ἔργων, καὶ πίστεως ἐπὶ ˚Θεόν,
58006002 βαπτισμῶν διδαχὴν, ἐπιθέσεώς τε χειρῶν, ἀναστάσεώς τε νεκρῶν, καὶ κρίματος αἰωνίου.
58006003 Καὶ τοῦτο ποιήσομεν, ἐάνπερ ἐπιτρέπῃ ὁ ˚Θεός.
58006004 Ἀδύνατον γὰρ τοὺς ἅπαξ φωτισθέντας, γευσαμένους τε τῆς δωρεᾶς τῆς ἐπουρανίου, καὶ μετόχους γενηθέντας ˚Πνεύματος Ἁγίου,
58006005 καὶ καλὸν γευσαμένους ˚Θεοῦ ῥῆμα, δυνάμεις τε μέλλοντος αἰῶνος,
58006006 καὶ παραπεσόντας, πάλιν ἀνακαινίζειν εἰς μετάνοιαν, ἀνασταυροῦντας ἑαυτοῖς τὸν Υἱὸν τοῦ ˚Θεοῦ, καὶ παραδειγματίζοντας.
58006007 Γῆ γὰρ ἡ πιοῦσα τὸν ἐπʼ αὐτῆς ἐρχόμενον πολλάκις ὑετόν, καὶ τίκτουσα βοτάνην εὔθετον, ἐκείνοις διʼ οὓς καὶ γεωργεῖται, μεταλαμβάνει εὐλογίας ἀπὸ τοῦ ˚Θεοῦ·
58006008 ἐκφέρουσα δὲ ἀκάνθας καὶ τριβόλους, ἀδόκιμος καὶ κατάρας ἐγγύς, ἧς τὸ τέλος εἰς καῦσιν.
58006009 Πεπείσμεθα δὲ περὶ ὑμῶν, ἀγαπητοί, τὰ κρείσσονα καὶ ἐχόμενα σωτηρίας, εἰ καὶ οὕτως λαλοῦμεν.
58006010 Οὐ γὰρ ἄδικος ὁ ˚Θεὸς, ἐπιλαθέσθαι τοῦ ἔργου ὑμῶν, καὶ τῆς ἀγάπης ἧς ἐνεδείξασθε εἰς τὸ ὄνομα αὐτοῦ, διακονήσαντες τοῖς ἁγίοις καὶ διακονοῦντες.
58006011 Ἐπιθυμοῦμεν δὲ ἕκαστον ὑμῶν τὴν αὐτὴν ἐνδείκνυσθαι σπουδὴν, πρὸς τὴν πληροφορίαν τῆς ἐλπίδος ἄχρι τέλους,
58006012 ἵνα μὴ νωθροὶ γένησθε, μιμηταὶ δὲ τῶν διὰ πίστεως καὶ μακροθυμίας, κληρονομούντων τὰς ἐπαγγελίας.
58006013 Τῷ γὰρ Ἀβραὰμ ἐπαγγειλάμενος ὁ ˚Θεός, ἐπεὶ κατʼ οὐδενὸς εἶχεν μείζονος ὀμόσαι, ὤμοσεν καθʼ ἑαυτοῦ
58006014 λέγων, “Εἰ μὴν εὐλογῶν, εὐλογήσω σε, καὶ πληθύνων, πληθυνῶ σε”.
58006015 Καὶ οὕτως μακροθυμήσας, ἐπέτυχεν τῆς ἐπαγγελίας.
58006016 Ἄνθρωποι γὰρ κατὰ τοῦ μείζονος ὀμνύουσιν, καὶ πάσης αὐτοῖς ἀντιλογίας πέρας, εἰς βεβαίωσιν ὁ ὅρκος.
58006017 Ἐν ᾧ περισσότερον βουλόμενος ὁ ˚Θεὸς ἐπιδεῖξαι τοῖς κληρονόμοις τῆς ἐπαγγελίας, τὸ ἀμετάθετον τῆς βουλῆς αὐτοῦ, ἐμεσίτευσεν ὅρκῳ,
58006018 ἵνα διὰ δύο πραγμάτων ἀμεταθέτων, ἐν οἷς ἀδύνατον ψεύσασθαι τὸν ˚Θεόν, ἰσχυρὰν παράκλησιν ἔχωμεν, οἱ καταφυγόντες κρατῆσαι τῆς προκειμένης ἐλπίδος·
58006019 ἣν ὡς ἄγκυραν ἔχομεν τῆς ψυχῆς, ἀσφαλῆ τε καὶ βεβαίαν, καὶ εἰσερχομένην εἰς τὸ ἐσώτερον τοῦ καταπετάσματος,
58006020 ὅπου πρόδρομος ὑπὲρ ἡμῶν εἰσῆλθεν, ˚Ἰησοῦς, κατὰ τὴν τάξιν Μελχισέδεκ, ἀρχιερεὺς γενόμενος εἰς τὸν αἰῶνα.
58007001 Οὗτος γὰρ ὁ Μελχισέδεκ, βασιλεὺς Σαλήμ, ἱερεὺς τοῦ ˚Θεοῦ τοῦ Ὑψίστου, ὁ συναντήσας Ἀβραὰμ ὑποστρέφοντι ἀπὸ τῆς κοπῆς τῶν βασιλέων, καὶ εὐλογήσας αὐτόν,
58007002 ᾧ καὶ δεκάτην ἀπὸ πάντων ἐμέρισεν Ἀβραάμ, πρῶτον μὲν ἑρμηνευόμενος “Βασιλεὺς δικαιοσύνης”, ἔπειτα δὲ καὶ “Βασιλεὺς Σαλήμ”, ὅ ἐστιν, “Βασιλεὺς εἰρήνης”,
58007003 ἀπάτωρ, ἀμήτωρ, ἀγενεαλόγητος, μήτε ἀρχὴν ἡμερῶν, μήτε ζωῆς τέλος ἔχων, ἀφωμοιωμένος δὲ τῷ Υἱῷ τοῦ ˚Θεοῦ, μένει ἱερεὺς εἰς τὸ διηνεκές.
58007004 Θεωρεῖτε δὲ πηλίκος οὗτος, ᾧ δεκάτην Ἀβραὰμ, ἔδωκεν ἐκ τῶν ἀκροθινίων, ὁ πατριάρχης.
58007005 Καὶ οἱ μὲν ἐκ τῶν υἱῶν Λευὶ τὴν ἱερατείαν λαμβάνοντες, ἐντολὴν ἔχουσιν ἀποδεκατοῦν τὸν λαὸν κατὰ τὸν νόμον, τοῦτʼ ἔστιν τοὺς ἀδελφοὺς αὐτῶν, καίπερ ἐξεληλυθότας ἐκ τῆς ὀσφύος Ἀβραάμ·
58007006 ὁ δὲ μὴ γενεαλογούμενος ἐξ αὐτῶν, δεδεκάτωκεν Ἀβραάμ, καὶ τὸν ἔχοντα τὰς ἐπαγγελίας, εὐλόγηκεν.
58007007 Χωρὶς δὲ πάσης ἀντιλογίας, τὸ ἔλαττον ὑπὸ τοῦ κρείττονος εὐλογεῖται.
58007008 Καὶ ὧδε μὲν δεκάτας ἀποθνῄσκοντες ἄνθρωποι λαμβάνουσιν, ἐκεῖ δὲ μαρτυρούμενος ὅτι ζῇ.
58007009 Καὶ ὡς ἔπος εἰπεῖν, διʼ Ἀβραὰμ, καὶ Λευὶς, ὁ δεκάτας λαμβάνων, δεδεκάτωται.
58007010 Ἔτι γὰρ ἐν τῇ ὀσφύϊ τοῦ πατρὸς ἦν, ὅτε συνήντησεν αὐτῷ Μελχισέδεκ.
58007011 Εἰ μὲν οὖν τελείωσις διὰ τῆς Λευιτικῆς ἱερωσύνης ἦν, ὁ λαὸς γὰρ ἐπʼ αὐτῆς νενομοθέτηται, τίς ἔτι χρεία κατὰ τὴν τάξιν Μελχισέδεκ, ἕτερον ἀνίστασθαι ἱερέα, καὶ οὐ κατὰ τὴν τάξιν Ἀαρὼν λέγεσθαι;
58007012 Μετατιθεμένης γὰρ τῆς ἱερωσύνης, ἐξ ἀνάγκης καὶ νόμου μετάθεσις γίνεται.
58007013 Ἐφʼ ὃν γὰρ λέγεται ταῦτα, φυλῆς ἑτέρας μετέσχηκεν, ἀφʼ ἧς οὐδεὶς προσέσχηκεν τῷ θυσιαστηρίῳ.
58007014 Πρόδηλον γὰρ, ὅτι ἐξ Ἰούδα ἀνατέταλκεν ὁ ˚Κύριος ἡμῶν, εἰς ἣν φυλὴν περὶ ἱερέων οὐδὲν Μωϋσῆς ἐλάλησεν.
58007015 Καὶ περισσότερον ἔτι κατάδηλόν ἐστιν, εἰ κατὰ τὴν ὁμοιότητα Μελχισέδεκ, ἀνίσταται ἱερεὺς ἕτερος,
58007016 ὃς οὐ κατὰ νόμον ἐντολῆς σαρκίνης γέγονεν, ἀλλὰ κατὰ δύναμιν ζωῆς ἀκαταλύτου.
58007017 Μαρτυρεῖται γὰρ, ὅτι “Σὺ ἱερεὺς εἰς τὸν αἰῶνα κατὰ τὴν τάξιν Μελχισέδεκ.”
58007018 Ἀθέτησις μὲν γὰρ γίνεται προαγούσης ἐντολῆς, διὰ τὸ αὐτῆς ἀσθενὲς καὶ ἀνωφελές,
58007019 ( οὐδὲν γὰρ ἐτελείωσεν ὁ νόμος ), ἐπεισαγωγὴ δὲ κρείττονος ἐλπίδος, διʼ ἧς ἐγγίζομεν τῷ ˚Θεῷ.
58007020 Καὶ καθʼ ὅσον οὐ χωρὶς ὁρκωμοσίας, οἱ μὲν γὰρ χωρὶς ὁρκωμοσίας εἰσὶν ἱερεῖς γεγονότες,
58007021 ὁ δὲ μετὰ ὁρκωμοσίας διὰ τοῦ λέγοντος πρὸς αὐτόν, “Ὤμοσεν ˚Κύριος, καὶ οὐ μεταμεληθήσεται, ‘Σὺ ἱερεὺς εἰς τὸν αἰῶνα’”,
58007022 κατὰ τοσοῦτο καὶ κρείττονος διαθήκης, γέγονεν ἔγγυος, ˚Ἰησοῦς.
58007023 Καὶ οἱ μὲν πλείονές εἰσιν γεγονότες ἱερεῖς, διὰ τὸ θανάτῳ κωλύεσθαι παραμένειν·
58007024 ὁ δὲ διὰ τὸ μένειν αὐτὸν εἰς τὸν αἰῶνα, ἀπαράβατον ἔχει τὴν ἱερωσύνην.
58007025 Ὅθεν καὶ σῴζειν εἰς τὸ παντελὲς δύναται, τοὺς προσερχομένους διʼ αὐτοῦ τῷ ˚Θεῷ, πάντοτε ζῶν εἰς τὸ ἐντυγχάνειν ὑπὲρ αὐτῶν.
58007026 Τοιοῦτος γὰρ ἡμῖν καὶ ἔπρεπεν ἀρχιερεύς, ὅσιος, ἄκακος, ἀμίαντος, κεχωρισμένος ἀπὸ τῶν ἁμαρτωλῶν, καὶ ὑψηλότερος τῶν οὐρανῶν γενόμενος·
58007027 ὃς οὐκ ἔχει καθʼ ἡμέραν ἀνάγκην, ὥσπερ οἱ ἀρχιερεῖς, πρότερον ὑπὲρ τῶν ἰδίων ἁμαρτιῶν θυσίας ἀναφέρειν, ἔπειτα τῶν τοῦ λαοῦ· τοῦτο γὰρ ἐποίησεν ἐφάπαξ, ἑαυτὸν ἀνενέγκας.
58007028 Ὁ νόμος γὰρ ἀνθρώπους καθίστησιν ἀρχιερεῖς ἔχοντας ἀσθένειαν, ὁ λόγος δὲ τῆς ὁρκωμοσίας, τῆς μετὰ τὸν νόμον, Υἱόν, εἰς τὸν αἰῶνα τετελειωμένον.
58008001 Κεφάλαιον δὲ ἐπὶ τοῖς λεγομένοις: τοιοῦτον ἔχομεν ἀρχιερέα, ὃς ἐκάθισεν ἐν δεξιᾷ τοῦ θρόνου τῆς Μεγαλωσύνης ἐν τοῖς οὐρανοῖς,
58008002 τῶν ἁγίων λειτουργὸς καὶ τῆς σκηνῆς τῆς ἀληθινῆς, ἣν ἔπηξεν ὁ ˚Κύριος, οὐκ ἄνθρωπος.
58008003 Πᾶς γὰρ ἀρχιερεὺς εἰς τὸ προσφέρειν, δῶρά τε καὶ θυσίας καθίσταται· ὅθεν ἀναγκαῖον ἔχειν τι καὶ τοῦτον ὃ προσενέγκῃ.
58008004 Εἰ μὲν οὖν ἦν ἐπὶ γῆς, οὐδʼ ἂν ἦν ἱερεύς, ὄντων τῶν προσφερόντων κατὰ νόμον τὰ δῶρα,
58008005 οἵτινες ὑποδείγματι καὶ σκιᾷ λατρεύουσιν τῶν ἐπουρανίων, καθὼς κεχρημάτισται Μωϋσῆς, μέλλων ἐπιτελεῖν τὴν σκηνήν, “Ὅρα”, γάρ φησίν, “ποιήσεις πάντα κατὰ τὸν τύπον τὸν δειχθέντα σοι ἐν τῷ ὄρει”.
58008006 νῦν δὲ διαφορωτέρας τέτυχεν λειτουργίας, ὅσῳ καὶ κρείττονός ἐστιν διαθήκης μεσίτης, ἥτις ἐπὶ κρείττοσιν ἐπαγγελίαις νενομοθέτηται.
58008007 Εἰ γὰρ ἡ πρώτη ἐκείνη ἦν ἄμεμπτος, οὐκ ἂν δευτέρας ἐζητεῖτο τόπος.
58008008 Μεμφόμενος γὰρ αὐτοῖς λέγει, “Ἰδοὺ, ἡμέραι ἔρχονται, λέγει ˚Κύριος, καὶ συντελέσω, ἐπὶ τὸν οἶκον Ἰσραὴλ, καὶ ἐπὶ τὸν οἶκον Ἰούδα, διαθήκην καινήν,
58008009 οὐ κατὰ τὴν διαθήκην ἣν ἐποίησα τοῖς πατράσιν αὐτῶν, ἐν ἡμέρᾳ ἐπιλαβομένου μου τῆς χειρὸς αὐτῶν, ἐξαγαγεῖν αὐτοὺς ἐκ γῆς Αἰγύπτου, ὅτι αὐτοὶ οὐκ ἐνέμειναν ἐν τῇ διαθήκῃ μου, κἀγὼ ἠμέλησα αὐτῶν, λέγει ˚Κύριος.
58008010 Ὅτι αὕτη ἡ διαθήκη, ἣν διαθήσομαι τῷ οἴκῳ Ἰσραὴλ μετὰ τὰς ἡμέρας ἐκείνας, λέγει ˚Κύριος, διδοὺς νόμους μου εἰς τὴν διάνοιαν αὐτῶν, καὶ ἐπὶ καρδίας αὐτῶν ἐπιγράψω αὐτούς, καὶ ἔσομαι αὐτοῖς εἰς ˚Θεόν, καὶ αὐτοὶ ἔσονταί μοι εἰς λαόν.
58008011 Καὶ οὐ μὴ διδάξωσιν ἕκαστος τὸν πολίτην αὐτοῦ, καὶ ἕκαστος τὸν ἀδελφὸν αὐτοῦ λέγων, ‘Γνῶθι τὸν ˚Κύριον’, ὅτι πάντες εἰδήσουσίν με, ἀπὸ μικροῦ ἕως μεγάλου αὐτῶν.
58008012 Ὅτι ἵλεως ἔσομαι ταῖς ἀδικίαις αὐτῶν, καὶ τῶν ἁμαρτιῶν αὐτῶν οὐ μὴ μνησθῶ ἔτι.”
58008013 Ἐν τῷ λέγειν, “Καινὴν”, πεπαλαίωκεν τὴν πρώτην. Τὸ δὲ παλαιούμενον καὶ γηράσκον ἐγγὺς ἀφανισμοῦ.
58009001 Εἶχε μὲν οὖν ἡ πρώτη δικαιώματα λατρείας, τό τε ἅγιον κοσμικόν.
58009002 Σκηνὴ γὰρ κατεσκευάσθη ἡ πρώτη, ἐν ᾗ ἥ τε λυχνία καὶ ἡ τράπεζα, καὶ ἡ Πρόθεσις τῶν ἄρτων, ἥτις λέγεται Ἅγια.
58009003 Μετὰ δὲ τὸ δεύτερον καταπέτασμα σκηνὴ, ἡ λεγομένη Ἅγια Ἁγίων,
58009004 χρυσοῦν ἔχουσα θυμιατήριον καὶ τὴν κιβωτὸν τῆς διαθήκης, περικεκαλυμμένην πάντοθεν χρυσίῳ, ἐν ᾗ στάμνος χρυσῆ ἔχουσα τὸ μάννα, καὶ ἡ ῥάβδος Ἀαρὼν ἡ βλαστήσασα, καὶ αἱ πλάκες τῆς διαθήκης.
58009005 Ὑπεράνω δὲ αὐτῆς, Χερουβὶν δόξης κατασκιάζοντα τὸ ἱλαστήριον· περὶ ὧν οὐκ ἔστιν νῦν λέγειν κατὰ μέρος.
58009006 Τούτων δὲ οὕτως κατεσκευασμένων, εἰς μὲν τὴν πρώτην σκηνὴν, διὰ παντὸς εἰσίασιν οἱ ἱερεῖς, τὰς λατρείας ἐπιτελοῦντες,
58009007 εἰς δὲ τὴν δευτέραν, ἅπαξ τοῦ ἐνιαυτοῦ μόνος ὁ ἀρχιερεύς, οὐ χωρὶς αἵματος, ὃ προσφέρει ὑπὲρ ἑαυτοῦ καὶ τῶν τοῦ λαοῦ ἀγνοημάτων.
58009008 Τοῦτο δηλοῦντος τοῦ ˚Πνεύματος τοῦ Ἁγίου, μήπω πεφανερῶσθαι τὴν τῶν ἁγίων ὁδὸν, ἔτι τῆς πρώτης σκηνῆς ἐχούσης στάσιν,
58009009 ἥτις παραβολὴ εἰς τὸν καιρὸν τὸν ἐνεστηκότα, καθʼ ἣν δῶρά τε καὶ θυσίαι προσφέρονται, μὴ δυνάμεναι κατὰ συνείδησιν τελειῶσαι τὸν λατρεύοντα,
58009010 μόνον ἐπὶ βρώμασιν, καὶ πόμασιν, καὶ διαφόροις βαπτισμοῖς, δικαιώματα σαρκὸς, μέχρι καιροῦ διορθώσεως ἐπικείμενα.
58009011 ˚Χριστὸς δὲ παραγενόμενος ἀρχιερεὺς, τῶν γενομένων ἀγαθῶν, διὰ τῆς μείζονος καὶ τελειοτέρας σκηνῆς, οὐ χειροποιήτου τοῦτʼ ἔστιν, οὐ ταύτης τῆς κτίσεως,
58009012 οὐδὲ διʼ αἵματος τράγων καὶ μόσχων, διὰ δὲ τοῦ ἰδίου αἵματος, εἰσῆλθεν ἐφάπαξ εἰς τὰ ἅγια, αἰωνίαν λύτρωσιν εὑράμενος.
58009013 Εἰ γὰρ τὸ αἷμα τράγων, καὶ ταύρων, καὶ σποδὸς δαμάλεως, ῥαντίζουσα τοὺς κεκοινωμένους, ἁγιάζει πρὸς τὴν τῆς σαρκὸς καθαρότητα,
58009014 πόσῳ μᾶλλον τὸ αἷμα τοῦ ˚Χριστοῦ, ὃς διὰ ˚Πνεύματος αἰωνίου, ἑαυτὸν προσήνεγκεν ἄμωμον τῷ ˚Θεῷ, καθαριεῖ τὴν συνείδησιν ὑμῶν ἀπὸ νεκρῶν ἔργων, εἰς τὸ λατρεύειν ˚Θεῷ ζῶντι!
58009015 Καὶ διὰ τοῦτο, διαθήκης καινῆς μεσίτης ἐστίν, ὅπως θανάτου γενομένου εἰς ἀπολύτρωσιν τῶν ἐπὶ τῇ πρώτῃ διαθήκῃ παραβάσεων, τὴν ἐπαγγελίαν λάβωσιν οἱ κεκλημένοι τῆς αἰωνίου κληρονομίας.
58009016 Ὅπου γὰρ διαθήκη, θάνατον ἀνάγκη φέρεσθαι τοῦ διαθεμένου.
58009017 Διαθήκη γὰρ ἐπὶ νεκροῖς βεβαία, ἐπεὶ μήποτε ἰσχύει, ὅτε ζῇ ὁ διαθέμενος.
58009018 Ὅθεν οὐδὲ ἡ πρώτη χωρὶς αἵματος ἐγκεκαίνισται.
58009019 Λαληθείσης γὰρ πάσης ἐντολῆς κατὰ τὸν νόμον ὑπὸ Μωϋσέως παντὶ τῷ λαῷ, λαβὼν τὸ αἷμα τῶν μόσχων, καὶ τῶν τράγων, μετὰ ὕδατος, καὶ ἐρίου κοκκίνου, καὶ ὑσσώπου, αὐτό τε τὸ βιβλίον καὶ πάντα τὸν λαὸν, ἐρράντισεν
58009020 λέγων, “Τοῦτο τὸ αἷμα τῆς διαθήκης, ἧς ἐνετείλατο πρὸς ὑμᾶς ὁ ˚Θεός.”
58009021 Καὶ τὴν σκηνὴν δὲ, καὶ πάντα τὰ σκεύη τῆς λειτουργίας, τῷ αἵματι ὁμοίως ἐρράντισεν.
58009022 Καὶ σχεδὸν ἐν αἵματι πάντα καθαρίζεται κατὰ τὸν νόμον, καὶ χωρὶς αἱματεκχυσίας, οὐ γίνεται ἄφεσις.
58009023 Ἀνάγκη οὖν τὰ μὲν ὑποδείγματα τῶν ἐν τοῖς οὐρανοῖς τούτοις καθαρίζεσθαι, αὐτὰ δὲ τὰ ἐπουράνια κρείττοσι θυσίαις παρὰ ταύτας.
58009024 Οὐ γὰρ εἰς χειροποίητα εἰσῆλθεν ἅγια ˚Χριστός, ἀντίτυπα τῶν ἀληθινῶν, ἀλλʼ εἰς αὐτὸν τὸν οὐρανόν, νῦν ἐμφανισθῆναι τῷ προσώπῳ τοῦ ˚Θεοῦ ὑπὲρ ἡμῶν.
58009025 Οὐδʼ ἵνα πολλάκις προσφέρῃ ἑαυτόν, ὥσπερ ὁ ἀρχιερεὺς εἰσέρχεται εἰς τὰ ἅγια κατʼ ἐνιαυτὸν ἐν αἵματι ἀλλοτρίῳ,
58009026 ἐπεὶ ἔδει αὐτὸν πολλάκις παθεῖν ἀπὸ καταβολῆς κόσμου. Νυνὶ δὲ ἅπαξ ἐπὶ συντελείᾳ τῶν αἰώνων, εἰς ἀθέτησιν ἁμαρτίας διὰ τῆς θυσίας αὐτοῦ πεφανέρωται.
58009027 Καὶ καθʼ ὅσον ἀπόκειται τοῖς ἀνθρώποις ἅπαξ ἀποθανεῖν, μετὰ δὲ τοῦτο κρίσις,
58009028 οὕτως καὶ ὁ ˚Χριστός ἅπαξ προσενεχθεὶς εἰς τὸ πολλῶν ἀνενεγκεῖν ἁμαρτίας, ἐκ δευτέρου χωρὶς ἁμαρτίας ὀφθήσεται τοῖς αὐτὸν ἀπεκδεχομένοις εἰς σωτηρίαν.
58010001 Σκιὰν γὰρ ἔχων ὁ νόμος τῶν μελλόντων ἀγαθῶν, οὐκ αὐτὴν τὴν εἰκόνα τῶν πραγμάτων, κατʼ ἐνιαυτὸν ταῖς αὐταῖς θυσίαις, ἃς προσφέρουσιν εἰς τὸ διηνεκὲς, οὐδέποτε δύνανται τοὺς προσερχομένους τελειῶσαι.
58010002 Ἐπεὶ οὐκ ἂν ἐπαύσαντο προσφερόμεναι, διὰ τὸ μηδεμίαν ἔχειν ἔτι συνείδησιν ἁμαρτιῶν, τοὺς λατρεύοντας ἅπαξ κεκαθαρισμένους;
58010003 Ἀλλʼ ἐν αὐταῖς ἀνάμνησις ἁμαρτιῶν κατʼ ἐνιαυτόν.
58010004 Ἀδύνατον γὰρ αἷμα ταύρων καὶ τράγων ἀφαιρεῖν ἁμαρτίας.
58010005 Διὸ εἰσερχόμενος εἰς τὸν κόσμον, λέγει, “Θυσίαν καὶ προσφορὰν οὐκ ἠθέλησας, σῶμα δὲ κατηρτίσω μοι.
58010006 Ὁλοκαυτώματα καὶ περὶ ἁμαρτίας οὐκ εὐδόκησας.
58010007 Τότε εἶπον, ‘Ἰδοὺ, ἥκω ( ἐν κεφαλίδι βιβλίου γέγραπται περὶ ἐμοῦ ) τοῦ ποιῆσαι ὁ ˚Θεός τὸ θέλημά σου.’”
58010008 Ἀνώτερον λέγων, ὅτι “Θυσίας, καὶ προσφορὰς, καὶ ὁλοκαυτώματα, καὶ περὶ ἁμαρτίας, οὐκ ἠθέλησας, οὐδὲ εὐδόκησας” ( αἵτινες κατὰ νόμον προσφέρονται ),
58010009 τότε εἴρηκεν, “Ἰδοὺ, ἥκω τοῦ ποιῆσαι τὸ θέλημά σου.” Ἀναιρεῖ τὸ πρῶτον, ἵνα τὸ δεύτερον στήσῃ.
58010010 Ἐν ᾧ θελήματι, ἡγιασμένοι ἐσμὲν διὰ τῆς προσφορᾶς τοῦ σώματος ˚Ἰησοῦ ˚Χριστοῦ ἐφάπαξ.
58010011 Καὶ πᾶς μὲν ἱερεὺς ἕστηκεν καθʼ ἡμέραν λειτουργῶν, καὶ τὰς αὐτὰς πολλάκις προσφέρων θυσίας, αἵτινες οὐδέποτε δύνανται περιελεῖν ἁμαρτίας.
58010012 Οὗτος δὲ, μίαν ὑπὲρ ἁμαρτιῶν προσενέγκας θυσίαν, εἰς τὸ διηνεκὲς, “ἐκάθισεν ἐν δεξιᾷ τοῦ ˚Θεοῦ”,
58010013 τὸ λοιπὸν, ἐκδεχόμενος “ἕως τεθῶσιν οἱ ἐχθροὶ αὐτοῦ ὑποπόδιον τῶν ποδῶν αὐτοῦ”.
58010014 Μιᾷ γὰρ προσφορᾷ, τετελείωκεν εἰς τὸ διηνεκὲς τοὺς ἁγιαζομένους.
58010015 Μαρτυρεῖ δὲ ἡμῖν καὶ τὸ ˚Πνεῦμα τὸ Ἅγιον, μετὰ γὰρ τὸ εἰρηκέναι,
58010016 “Αὕτη ἡ διαθήκη ἣν διαθήσομαι πρὸς αὐτοὺς μετὰ τὰς ἡμέρας ἐκείνας, λέγει ˚Κύριος, διδοὺς νόμους μου ἐπὶ καρδίας αὐτῶν, καὶ ἐπὶ τὴν διάνοιαν αὐτῶν ἐπιγράψω αὐτούς…
58010017 καὶ τῶν ἁμαρτιῶν αὐτῶν, καὶ τῶν ἀνομιῶν αὐτῶν, οὐ μὴ μνησθήσομαι ἔτι.”
58010018 Ὅπου δὲ ἄφεσις τούτων, οὐκέτι προσφορὰ περὶ ἁμαρτίας.
58010019 Ἔχοντες οὖν, ἀδελφοί, παρρησίαν εἰς τὴν εἴσοδον τῶν ἁγίων ἐν τῷ αἵματι ˚Ἰησοῦ,
58010020 ἣν ἐνεκαίνισεν ἡμῖν ὁδὸν πρόσφατον καὶ ζῶσαν διὰ τοῦ καταπετάσματος, τοῦτʼ ἔστιν τῆς σαρκὸς αὐτοῦ,
58010021 καὶ ἱερέα μέγαν ἐπὶ τὸν οἶκον τοῦ ˚Θεοῦ,
58010022 προσερχώμεθα μετὰ ἀληθινῆς καρδίας ἐν πληροφορίᾳ πίστεως, ῥεραντισμένοι τὰς καρδίας ἀπὸ συνειδήσεως πονηρᾶς, καὶ λελουσμένοι τὸ σῶμα ὕδατι καθαρῷ.
58010023 Κατέχωμεν τὴν ὁμολογίαν τῆς ἐλπίδος ἀκλινῆ, πιστὸς γὰρ ὁ ἐπαγγειλάμενος,
58010024 καὶ κατανοῶμεν ἀλλήλους εἰς παροξυσμὸν ἀγάπης καὶ καλῶν ἔργων,
58010025 μὴ ἐγκαταλείποντες τὴν ἐπισυναγωγὴν ἑαυτῶν, καθὼς ἔθος τισίν, ἀλλὰ παρακαλοῦντες καὶ τοσούτῳ μᾶλλον, ὅσῳ βλέπετε ἐγγίζουσαν τὴν ἡμέραν.
58010026 Ἑκουσίως γὰρ ἁμαρτανόντων ἡμῶν μετὰ τὸ λαβεῖν τὴν ἐπίγνωσιν τῆς ἀληθείας, οὐκέτι περὶ ἁμαρτιῶν ἀπολείπεται θυσία,
58010027 φοβερὰ δέ τις ἐκδοχὴ κρίσεως, καὶ “πυρὸς ζῆλος ἐσθίειν μέλλοντος τοὺς ὑπεναντίους”.
58010028 Ἀθετήσας τις νόμον Μωϋσέως χωρὶς οἰκτιρμῶν ἐπὶ δυσὶν ἢ τρισὶν μάρτυσιν ἀποθνῄσκει.
58010029 Πόσῳ δοκεῖτε χείρονος ἀξιωθήσεται τιμωρίας, ὁ τὸν Υἱὸν τοῦ ˚Θεοῦ καταπατήσας, καὶ τὸ αἷμα τῆς διαθήκης κοινὸν ἡγησάμενος, ἐν ᾧ ἡγιάσθη, καὶ τὸ ˚Πνεῦμα τῆς χάριτος ἐνυβρίσας!
58010030 Οἴδαμεν γὰρ τὸν εἰπόντα, “Ἐμοὶ ἐκδίκησις, ἐγὼ ἀνταποδώσω”, καὶ πάλιν, “Κρινεῖ ˚Κύριος τὸν λαὸν αὐτοῦ.”
58010031 Φοβερὸν τὸ ἐμπεσεῖν εἰς χεῖρας ˚Θεοῦ ζῶντος.
58010032 Ἀναμιμνῄσκεσθε δὲ τὰς πρότερον ἡμέρας, ἐν αἷς φωτισθέντες πολλὴν ἄθλησιν ὑπεμείνατε παθημάτων,
58010033 τοῦτο μὲν ὀνειδισμοῖς τε καὶ θλίψεσιν θεατριζόμενοι, τοῦτο δὲ κοινωνοὶ τῶν οὕτως ἀναστρεφομένων γενηθέντες.
58010034 Καὶ γὰρ τοῖς δεσμίοις συνεπαθήσατε, καὶ τὴν ἁρπαγὴν τῶν ὑπαρχόντων ὑμῶν μετὰ χαρᾶς προσεδέξασθε, γινώσκοντες ἔχειν ἑαυτοὺς κρείσσονα ὕπαρξιν, καὶ μένουσαν.
58010035 Μὴ ἀποβάλητε οὖν τὴν παρρησίαν ὑμῶν, ἥτις ἔχει μεγάλην μισθαποδοσίαν.
58010036 Ὑπομονῆς γὰρ ἔχετε χρείαν, ἵνα τὸ θέλημα τοῦ ˚Θεοῦ ποιήσαντες, κομίσησθε τὴν ἐπαγγελίαν.
58010037 “Ἔτι γὰρ μικρὸν ὅσον, ὅσον ὁ ἐρχόμενος ἥξει, καὶ οὐ χρονίσει.
58010038 Ὁ δὲ δίκαιός μου ἐκ πίστεως ζήσεται, καὶ ἐὰν ὑποστείληται, οὐκ εὐδοκεῖ ἡ ψυχή μου ἐν αὐτῷ.”
58010039 Ἡμεῖς δὲ οὐκ ἐσμὲν ὑποστολῆς εἰς ἀπώλειαν, ἀλλὰ πίστεως εἰς περιποίησιν ψυχῆς.
58011001 Ἔστιν δὲ πίστις ἐλπιζομένων ὑπόστασις, πραγμάτων ἔλεγχος οὐ βλεπομένων.
58011002 Ἐν ταύτῃ γὰρ ἐμαρτυρήθησαν οἱ πρεσβύτεροι.
58011003 Πίστει νοοῦμεν κατηρτίσθαι τοὺς αἰῶνας ῥήματι ˚Θεοῦ, εἰς τὸ μὴ ἐκ φαινομένων, τὸ βλεπόμενον γεγονέναι.
58011004 Πίστει πλείονα θυσίαν, Ἅβελ παρὰ Κάϊν προσήνεγκεν τῷ ˚Θεῷ, διʼ ἧς ἐμαρτυρήθη εἶναι δίκαιος, μαρτυροῦντος ἐπὶ τοῖς δώροις αὐτοῦ τοῦ ˚Θεοῦ, καὶ διʼ αὐτῆς, ἀποθανὼν ἔτι λαλεῖ.
58011005 Πίστει Ἑνὼχ μετετέθη, τοῦ μὴ ἰδεῖν θάνατον, “καὶ οὐχ ηὑρίσκετο, διότι μετέθηκεν αὐτὸν ὁ ˚Θεός”· πρὸ γὰρ τῆς μεταθέσεως, μεμαρτύρηται εὐαρεστηκέναι τῷ ˚Θεῷ.
58011006 Χωρὶς δὲ πίστεως, ἀδύνατον εὐαρεστῆσαι, πιστεῦσαι γὰρ δεῖ τὸν προσερχόμενον τῷ ˚Θεῷ ὅτι ἔστιν, καὶ τοῖς ἐκζητοῦσιν αὐτὸν, μισθαποδότης γίνεται.
58011007 Πίστει χρηματισθεὶς, Νῶε, περὶ τῶν μηδέπω βλεπομένων, εὐλαβηθεὶς, κατεσκεύασεν κιβωτὸν εἰς σωτηρίαν τοῦ οἴκου αὐτοῦ, διʼ ἧς κατέκρινεν τὸν κόσμον, καὶ τῆς κατὰ πίστιν δικαιοσύνης, ἐγένετο κληρονόμος.
58011008 Πίστει καλούμενος, Ἀβραὰμ, ὑπήκουσεν ἐξελθεῖν εἰς τόπον ὃν ἤμελλεν λαμβάνειν εἰς κληρονομίαν, καὶ ἐξῆλθεν μὴ ἐπιστάμενος ποῦ ἔρχεται.
58011009 Πίστει παρῴκησεν εἰς γῆν τῆς ἐπαγγελίας ὡς ἀλλοτρίαν, ἐν σκηναῖς κατοικήσας μετὰ Ἰσαὰκ καὶ Ἰακὼβ, τῶν συγκληρονόμων τῆς ἐπαγγελίας τῆς αὐτῆς·
58011010 ἐξεδέχετο γὰρ τὴν τοὺς θεμελίους ἔχουσαν πόλιν, ἧς τεχνίτης καὶ δημιουργὸς ὁ ˚Θεός.
58011011 Πίστει καὶ αὐτὴ Σάρρᾳ δύναμιν εἰς καταβολὴν σπέρματος ἔλαβεν, καὶ παρὰ καιρὸν ἡλικίας, ἐπεὶ πιστὸν ἡγήσατο τὸν ἐπαγγειλάμενον.
58011012 Διὸ καὶ ἀφʼ ἑνὸς ἐγεννήθησαν, καὶ ταῦτα νενεκρωμένου, “καθὼς τὰ ἄστρα τοῦ οὐρανοῦ τῷ πλήθει, καὶ ὡς ἡ ἄμμος, ἡ παρὰ τὸ χεῖλος τῆς θαλάσσης, ἡ ἀναρίθμητος”.
58011013 Κατὰ πίστιν ἀπέθανον οὗτοι πάντες, μὴ προσδεξάμενοι τὰς ἐπαγγελίας, ἀλλὰ πόρρωθεν αὐτὰς ἰδόντες, καὶ ἀσπασάμενοι, καὶ ὁμολογήσαντες, ὅτι ξένοι καὶ παρεπίδημοί εἰσιν ἐπὶ τῆς γῆς.
58011014 Οἱ γὰρ τοιαῦτα λέγοντες, ἐμφανίζουσιν ὅτι πατρίδα ἐπιζητοῦσιν.
58011015 Καὶ εἰ μὲν ἐκείνης ἐμνημόνευον ἀφʼ ἧς ἐξέβησαν, εἶχον ἂν καιρὸν ἀνακάμψαι.
58011016 Νῦν δὲ κρείττονος ὀρέγονται, τοῦτʼ ἔστιν ἐπουρανίου. Διὸ οὐκ ἐπαισχύνεται αὐτοὺς ὁ ˚Θεὸς, ˚Θεὸς ἐπικαλεῖσθαι αὐτῶν, ἡτοίμασεν γὰρ αὐτοῖς πόλιν.
58011017 Πίστει προσενήνοχεν Ἀβραὰμ τὸν Ἰσαὰκ πειραζόμενος, καὶ τὸν μονογενῆ προσέφερεν, ὁ τὰς ἐπαγγελίας ἀναδεξάμενος,
58011018 πρὸς ὃν ἐλαλήθη, ὅτι “Ἐν Ἰσαὰκ κληθήσεταί σοι σπέρμα”,
58011019 λογισάμενος ὅτι καὶ ἐκ νεκρῶν ἐγείρειν δυνατὸς ὁ ˚Θεός, ὅθεν αὐτὸν καὶ ἐν παραβολῇ ἐκομίσατο.
58011020 Πίστει καὶ περὶ μελλόντων, εὐλόγησεν Ἰσαὰκ, τὸν Ἰακὼβ, καὶ τὸν Ἠσαῦ.
58011021 Πίστει Ἰακὼβ, ἀποθνῄσκων, ἕκαστον τῶν υἱῶν Ἰωσὴφ εὐλόγησεν, καὶ προσεκύνησεν ἐπὶ τὸ ἄκρον τῆς ῥάβδου αὐτοῦ.
58011022 Πίστει Ἰωσὴφ, τελευτῶν, περὶ τῆς ἐξόδου τῶν υἱῶν Ἰσραὴλ ἐμνημόνευσεν, καὶ περὶ τῶν ὀστέων αὐτοῦ ἐνετείλατο.
58011023 Πίστει Μωϋσῆς, γεννηθεὶς, ἐκρύβη τρίμηνον ὑπὸ τῶν πατέρων αὐτοῦ, διότι εἶδον ἀστεῖον τὸ παιδίον, καὶ οὐκ ἐφοβήθησαν τὸ διάταγμα τοῦ βασιλέως.
58011024 Πίστει Μωϋσῆς, μέγας γενόμενος, ἠρνήσατο λέγεσθαι υἱὸς θυγατρὸς Φαραώ,
58011025 μᾶλλον ἑλόμενος συγκακουχεῖσθαι τῷ λαῷ τοῦ ˚Θεοῦ, ἢ πρόσκαιρον ἔχειν ἁμαρτίας ἀπόλαυσιν,
58011026 μείζονα πλοῦτον ἡγησάμενος τῶν Αἰγύπτου θησαυρῶν, τὸν ὀνειδισμὸν τοῦ ˚Χριστοῦ, ἀπέβλεπεν γὰρ εἰς τὴν μισθαποδοσίαν.
58011027 Πίστει κατέλιπεν Αἴγυπτον, μὴ φοβηθεὶς τὸν θυμὸν τοῦ βασιλέως, τὸν γὰρ ἀόρατον ὡς ὁρῶν, ἐκαρτέρησεν.
58011028 Πίστει πεποίηκεν τὸ Πάσχα καὶ τὴν πρόσχυσιν τοῦ αἵματος, ἵνα μὴ ὁ ὀλοθρεύων τὰ πρωτότοκα θίγῃ αὐτῶν.
58011029 Πίστει διέβησαν τὴν Ἐρυθρὰν Θάλασσαν, ὡς διὰ ξηρᾶς γῆς, ἧς πεῖραν λαβόντες, οἱ Αἰγύπτιοι κατεπόθησαν.
58011030 Πίστει τὰ τείχη Ἰεριχὼ ἔπεσαν, κυκλωθέντα ἐπὶ ἑπτὰ ἡμέρας.
58011031 Πίστει Ῥαὰβ ἡ πόρνη οὐ συναπώλετο τοῖς ἀπειθήσασιν, δεξαμένη τοὺς κατασκόπους μετʼ εἰρήνης.
58011032 Καὶ τί ἔτι λέγω; Ἐπιλείψει με γὰρ διηγούμενον ὁ χρόνος, περὶ Γεδεών, Βαράκ, Σαμψών, Ἰεφθάε, Δαυίδ τε καὶ Σαμουὴλ, καὶ τῶν προφητῶν,
58011033 οἳ διὰ πίστεως κατηγωνίσαντο βασιλείας, εἰργάσαντο δικαιοσύνην, ἐπέτυχον ἐπαγγελιῶν, ἔφραξαν στόματα λεόντων,
58011034 ἔσβεσαν δύναμιν πυρός, ἔφυγον στόματα μαχαίρης, ἐδυναμώθησαν ἀπὸ ἀσθενείας, ἐγενήθησαν ἰσχυροὶ ἐν πολέμῳ, παρεμβολὰς ἔκλιναν ἀλλοτρίων.
58011035 Ἔλαβον γυναῖκες ἐξ ἀναστάσεως τοὺς νεκροὺς αὐτῶν, ἄλλοι δὲ ἐτυμπανίσθησαν, οὐ προσδεξάμενοι τὴν ἀπολύτρωσιν, ἵνα κρείττονος ἀναστάσεως τύχωσιν·
58011036 ἕτεροι δὲ ἐμπαιγμῶν καὶ μαστίγων πεῖραν ἔλαβον, ἔτι δὲ δεσμῶν καὶ φυλακῆς.
58011037 Ἐλιθάσθησαν, ἐπρίσθησαν, ἐπειράσθησαν, ἐν φόνῳ μαχαίρης ἀπέθανον, περιῆλθον ἐν μηλωταῖς, ἐν αἰγείοις δέρμασιν, ὑστερούμενοι, θλιβόμενοι, κακουχούμενοι,
58011038 ὧν οὐκ ἦν ἄξιος ὁ κόσμος, ἐπὶ ἐρημίαις πλανώμενοι, καὶ ὄρεσιν, καὶ σπηλαίοις, καὶ ταῖς ὀπαῖς τῆς γῆς.
58011039 Καὶ οὗτοι πάντες μαρτυρηθέντες διὰ τῆς πίστεως, οὐκ ἐκομίσαντο τὴν ἐπαγγελίαν,
58011040 τοῦ ˚Θεοῦ περὶ ἡμῶν κρεῖττόν τι προβλεψαμένου, ἵνα μὴ χωρὶς ἡμῶν τελειωθῶσιν.
58012001 Τοιγαροῦν καὶ ἡμεῖς, τοσοῦτον ἔχοντες περικείμενον ἡμῖν νέφος μαρτύρων, ὄγκον ἀποθέμενοι πάντα καὶ τὴν εὐπερίστατον ἁμαρτίαν διʼ ὑπομονῆς, τρέχωμεν τὸν προκείμενον ἡμῖν ἀγῶνα,
58012002 ἀφορῶντες εἰς τὸν τῆς πίστεως ἀρχηγὸν καὶ τελειωτὴν, ˚Ἰησοῦν, ὃς ἀντὶ τῆς προκειμένης αὐτῷ χαρᾶς, ὑπέμεινεν σταυρὸν, αἰσχύνης καταφρονήσας, “ἐν δεξιᾷ τε τοῦ θρόνου τοῦ ˚Θεοῦ κεκάθικεν”.
58012003 Ἀναλογίσασθε γὰρ τὸν τοιαύτην ὑπομεμενηκότα ὑπὸ τῶν ἁμαρτωλῶν εἰς αὑτούς ἀντιλογίαν, ἵνα μὴ κάμητε, ταῖς ψυχαῖς ὑμῶν ἐκλυόμενοι.
58012004 Οὔπω μέχρις αἵματος ἀντικατέστητε, πρὸς τὴν ἁμαρτίαν ἀνταγωνιζόμενοι,
58012005 καὶ ἐκλέλησθε τῆς παρακλήσεως, ἥτις ὑμῖν ὡς υἱοῖς διαλέγεται, “Υἱέ μου, μὴ ὀλιγώρει παιδείας ˚Κυρίου, μηδὲ ἐκλύου ὑπʼ αὐτοῦ ἐλεγχόμενος·
58012006 ὃν γὰρ ἀγαπᾷ ˚Κύριος, παιδεύει, μαστιγοῖ δὲ πάντα υἱὸν ὃν παραδέχεται.”
58012007 Εἰς παιδείαν ὑπομένετε, ὡς υἱοῖς ὑμῖν προσφέρεται ὁ ˚Θεός· τίς γὰρ υἱὸς ὃν οὐ παιδεύει πατήρ;
58012008 Εἰ δὲ χωρίς ἐστε παιδείας, ἧς μέτοχοι γεγόνασι πάντες, ἄρα νόθοι καὶ οὐχ υἱοί ἐστε.
58012009 Εἶτα τοὺς μὲν τῆς σαρκὸς ἡμῶν πατέρας εἴχομεν παιδευτὰς, καὶ ἐνετρεπόμεθα· οὐ πολὺ μᾶλλον ὑποταγησόμεθα τῷ Πατρὶ τῶν πνευμάτων, καὶ ζήσομεν;
58012010 Οἱ μὲν γὰρ πρὸς ὀλίγας ἡμέρας κατὰ τὸ δοκοῦν αὐτοῖς ἐπαίδευον, ὁ δὲ ἐπὶ τὸ συμφέρον, εἰς τὸ μεταλαβεῖν τῆς ἁγιότητος αὐτοῦ.
58012011 Πᾶσα δὲ παιδεία πρὸς μὲν τὸ παρὸν, οὐ δοκεῖ χαρᾶς εἶναι, ἀλλὰ λύπης, ὕστερον δὲ καρπὸν εἰρηνικὸν τοῖς διʼ αὐτῆς γεγυμνασμένοις ἀποδίδωσιν δικαιοσύνης.
58012012 Διὸ τὰς παρειμένας χεῖρας, καὶ τὰ παραλελυμένα γόνατα, ἀνορθώσατε,
58012013 καὶ “τροχιὰς ὀρθὰς ποιεῖτε τοῖς ποσὶν ὑμῶν”, ἵνα μὴ τὸ χωλὸν ἐκτραπῇ, ἰαθῇ δὲ μᾶλλον.
58012014 Εἰρήνην διώκετε μετὰ πάντων, καὶ τὸν ἁγιασμόν, οὗ χωρὶς οὐδεὶς ὄψεται τὸν ˚Κύριον.
58012015 Ἐπισκοποῦντες μή τις ὑστερῶν ἀπὸ τῆς χάριτος τοῦ ˚Θεοῦ, μή τις ῥίζα πικρίας ἄνω φύουσα ἐνοχλῇ, καὶ διʼ αὐτῆς μιανθῶσιν οἱ πολλοί,
58012016 μή τις πόρνος ἢ βέβηλος, ὡς Ἠσαῦ, ὃς ἀντὶ βρώσεως μιᾶς, ἀπέδετο τὰ πρωτοτόκια ἑαυτοῦ.
58012017 ἴστε γὰρ ὅτι καὶ μετέπειτα θέλων κληρονομῆσαι τὴν εὐλογίαν, ἀπεδοκιμάσθη, μετανοίας γὰρ τόπον οὐχ εὗρεν, καίπερ μετὰ δακρύων ἐκζητήσας αὐτήν.
58012018 Οὐ γὰρ προσεληλύθατε, ψηλαφωμένῳ καὶ κεκαυμένῳ πυρὶ, καὶ γνόφῳ, καὶ ζόφῳ, καὶ θυέλλῃ,
58012019 καὶ σάλπιγγος ἤχῳ, καὶ φωνῇ ῥημάτων, ἧς οἱ ἀκούσαντες, παρῃτήσαντο μὴ προστεθῆναι αὐτοῖς λόγον·
58012020 οὐκ ἔφερον γὰρ τὸ διαστελλόμενον, “Κἂν θηρίον θίγῃ τοῦ ὄρους, λιθοβοληθήσεται”·
58012021 καί οὕτω φοβερὸν ἦν τὸ φανταζόμενον, Μωϋσῆς εἶπεν, “Ἔκφοβός εἰμι καὶ ἔντρομος.”
58012022 Ἀλλὰ προσεληλύθατε Σιὼν Ὄρει καὶ πόλει ˚Θεοῦ ζῶντος, Ἰερουσαλὴμ ἐπουρανίῳ, καὶ μυριάσιν ἀγγέλων πανηγύρει,
58012023 καὶ ἐκκλησίᾳ πρωτοτόκων, ἀπογεγραμμένων ἐν οὐρανοῖς, καὶ κριτῇ ˚Θεῷ πάντων, καὶ πνεύμασι δικαίων τετελειωμένων,
58012024 καὶ διαθήκης νέας μεσίτῃ, ˚Ἰησοῦ, καὶ αἵματι ῥαντισμοῦ, κρεῖττον λαλοῦντι παρὰ τὸν Ἅβελ.
58012025 Βλέπετε, μὴ παραιτήσησθε τὸν λαλοῦντα. Εἰ γὰρ ἐκεῖνοι οὐκ ἐξέφυγον ἐπὶ γῆς, παραιτησάμενοι τὸν χρηματίζοντα, πολὺ μᾶλλον ἡμεῖς οἱ τὸν ἀπʼ οὐρανῶν ἀποστρεφόμενοι·
58012026 οὗ ἡ φωνὴ τὴν γῆν ἐσάλευσεν τότε, νῦν δὲ ἐπήγγελται λέγων, “Ἔτι ἅπαξ ἐγὼ σείσω, οὐ μόνον τὴν γῆν, ἀλλὰ καὶ τὸν οὐρανόν.”
58012027 Τὸ δὲ, “ἔτι ἅπαξ”, δηλοῖ τὴν τῶν σαλευομένων μετάθεσιν, ὡς πεποιημένων, ἵνα μείνῃ τὰ μὴ σαλευόμενα.
58012028 Διὸ βασιλείαν ἀσάλευτον παραλαμβάνοντες, ἔχωμεν χάριν, διʼ ἧς λατρεύωμεν εὐαρέστως τῷ ˚Θεῷ μετὰ εὐλαβείας καὶ δέους.
58012029 Καὶ “γὰρ ὁ ˚Θεὸς ἡμῶν πῦρ καταναλίσκον”.
58013001 Ἡ φιλαδελφία μενέτω.
58013002 Τῆς φιλοξενίας μὴ ἐπιλανθάνεσθε, διὰ ταύτης γὰρ, ἔλαθόν τινες ξενίσαντες ἀγγέλους.
58013003 Μιμνῄσκεσθε τῶν δεσμίων, ὡς συνδεδεμένοι, τῶν κακουχουμένων ὡς καὶ αὐτοὶ ὄντες ἐν σώματι.
58013004 Τίμιος ὁ γάμος ἐν πᾶσιν καὶ ἡ κοίτη ἀμίαντος, πόρνους γὰρ καὶ μοιχοὺς κρινεῖ ὁ ˚Θεός.
58013005 Ἀφιλάργυρος ὁ τρόπος, ἀρκούμενοι τοῖς παροῦσιν· αὐτὸς γὰρ εἴρηκεν, “Οὐ μή σε ἀνῶ, οὐδʼ οὐ μή σε ἐγκαταλίπω.”
58013006 Ὥστε θαρροῦντας ἡμᾶς λέγειν, “˚Κύριος ἐμοὶ βοηθός, καὶ οὐ φοβηθήσομαι· τί ποιήσει μοι ἄνθρωπος;”
58013007 Μνημονεύετε τῶν ἡγουμένων ὑμῶν, οἵτινες ἐλάλησαν ὑμῖν τὸν λόγον τοῦ ˚Θεοῦ, ὧν ἀναθεωροῦντες τὴν ἔκβασιν τῆς ἀναστροφῆς, μιμεῖσθε τὴν πίστιν.
58013008 ˚Ἰησοῦς ˚Χριστὸς, ἐχθὲς καὶ σήμερον, ὁ αὐτός, καὶ εἰς τοὺς αἰῶνας.
58013009 Διδαχαῖς ποικίλαις καὶ ξέναις, μὴ παραφέρεσθε· καλὸν γὰρ χάριτι βεβαιοῦσθαι τὴν καρδίαν, οὐ βρώμασιν, ἐν οἷς οὐκ ὠφελήθησαν οἱ περιπατοῦντες.
58013010 Ἔχομεν θυσιαστήριον, ἐξ οὗ φαγεῖν οὐκ ἔχουσιν ἐξουσίαν, οἱ τῇ σκηνῇ λατρεύοντες.
58013011 Ὧν γὰρ εἰσφέρεται ζῴων τὸ αἷμα περὶ ἁμαρτίας εἰς τὰ ἅγια διὰ τοῦ ἀρχιερέως, τούτων τὰ σώματα κατακαίεται ἔξω τῆς παρεμβολῆς.
58013012 Διὸ καὶ ˚Ἰησοῦς, ἵνα ἁγιάσῃ διὰ τοῦ ἰδίου αἵματος τὸν λαόν, ἔξω τῆς πύλης ἔπαθεν.
58013013 Τοίνυν ἐξερχώμεθα πρὸς αὐτὸν ἔξω τῆς παρεμβολῆς, τὸν ὀνειδισμὸν αὐτοῦ φέροντες.
58013014 Οὐ γὰρ ἔχομεν ὧδε μένουσαν πόλιν, ἀλλὰ τὴν μέλλουσαν ἐπιζητοῦμεν.
58013015 Διʼ αὐτοῦ οὖν ἀναφέρωμεν θυσίαν αἰνέσεως διὰ παντὸς τῷ ˚Θεῷ, τοῦτʼ ἔστιν καρπὸν χειλέων ὁμολογούντων τῷ ὀνόματι αὐτοῦ.
58013016 Τῆς δὲ εὐποιΐας καὶ κοινωνίας μὴ ἐπιλανθάνεσθε, τοιαύταις γὰρ θυσίαις εὐαρεστεῖται ὁ ˚Θεός.
58013017 Πείθεσθε τοῖς ἡγουμένοις ὑμῶν, καὶ ὑπείκετε, αὐτοὶ γὰρ ἀγρυπνοῦσιν ὑπὲρ τῶν ψυχῶν ὑμῶν, ὡς λόγον ἀποδώσοντες, ἵνα μετὰ χαρᾶς τοῦτο ποιῶσιν, καὶ μὴ στενάζοντες, ἀλυσιτελὲς γὰρ ὑμῖν τοῦτο.
58013018 Προσεύχεσθε περὶ ἡμῶν, πειθόμεθα γὰρ ὅτι καλὴν συνείδησιν ἔχομεν, ἐν πᾶσιν καλῶς θέλοντες ἀναστρέφεσθαι.
58013019 Περισσοτέρως δὲ παρακαλῶ τοῦτο ποιῆσαι, ἵνα τάχιον ἀποκατασταθῶ ὑμῖν.
58013020 Ὁ δὲ ˚Θεὸς τῆς εἰρήνης, ὁ ἀναγαγὼν ἐκ νεκρῶν τὸν Ποιμένα τῶν προβάτων τὸν μέγαν, ἐν αἵματι διαθήκης αἰωνίου, τὸν ˚Κύριον ἡμῶν, ˚Ἰησοῦν,
58013021 καταρτίσαι ὑμᾶς ἐν παντὶ ἀγαθῷ, εἰς τὸ ποιῆσαι τὸ θέλημα αὐτοῦ, ποιῶν ἐν ἡμῖν τὸ εὐάρεστον ἐνώπιον αὐτοῦ, διὰ ˚Ἰησοῦ ˚Χριστοῦ, ᾧ ἡ δόξα εἰς τοὺς αἰῶνας τῶν αἰώνων. Ἀμήν!
58013022 Παρακαλῶ δὲ ὑμᾶς, ἀδελφοί, ἀνέχεσθε τοῦ λόγου τῆς παρακλήσεως, καὶ γὰρ διὰ βραχέων ἐπέστειλα ὑμῖν.
58013023 Γινώσκετε τὸν ἀδελφὸν ἡμῶν, Τιμόθεον, ἀπολελυμένον, μεθʼ οὗ ἐὰν τάχιον ἔρχηται, ὄψομαι ὑμᾶς.
58013024 Ἀσπάσασθε πάντας τοὺς ἡγουμένους ὑμῶν, καὶ πάντας τοὺς ἁγίους. Ἀσπάζονται ὑμᾶς οἱ ἀπὸ τῆς Ἰταλίας.
58013025 Ἡ χάρις μετὰ πάντων ὑμῶν. ἀμήν!
59001001 Ἰάκωβος, ˚Θεοῦ καὶ ˚Κυρίου ˚Ἰησοῦ ˚Χριστοῦ, δοῦλος, ταῖς δώδεκα φυλαῖς ταῖς ἐν τῇ διασπορᾷ: χαίρειν.
59001002 Πᾶσαν χαρὰν ἡγήσασθε, ἀδελφοί μου, ὅταν πειρασμοῖς περιπέσητε ποικίλοις,
59001003 γινώσκοντες ὅτι τὸ δοκίμιον ὑμῶν τῆς πίστεως κατεργάζεται ὑπομονήν.
59001004 Ἡ δὲ ὑπομονὴ ἔργον τέλειον ἐχέτω, ἵνα ἦτε τέλειοι καὶ ὁλόκληροι, ἐν μηδενὶ λειπόμενοι.
59001005 Εἰ δέ τις ὑμῶν λείπεται σοφίας, αἰτείτω παρὰ τοῦ διδόντος, ˚Θεοῦ, πᾶσιν ἁπλῶς, καὶ μὴ ὀνειδίζοντος, καὶ δοθήσεται αὐτῷ.
59001006 Αἰτείτω δὲ ἐν πίστει, μηδὲν διακρινόμενος, ὁ γὰρ διακρινόμενος ἔοικεν κλύδωνι θαλάσσης, ἀνεμιζομένῳ καὶ ῥιπιζομένῳ.
59001007 Μὴ γὰρ οἰέσθω ὁ ἄνθρωπος ἐκεῖνος ὅτι λήμψεταί τι παρὰ τοῦ ˚Κυρίου,
59001008 ἀνὴρ δίψυχος, ἀκατάστατος ἐν πάσαις ταῖς ὁδοῖς αὐτοῦ.
59001009 Καυχάσθω δὲ ὁ ἀδελφὸς ὁ ταπεινὸς, ἐν τῷ ὕψει αὐτοῦ,
59001010 ὁ δὲ πλούσιος, ἐν τῇ ταπεινώσει αὐτοῦ, ὅτι ὡς ἄνθος χόρτου παρελεύσεται.
59001011 Ἀνέτειλεν γὰρ ὁ ἥλιος σὺν τῷ καύσωνι, καὶ ἐξήρανεν τὸν χόρτον, καὶ τὸ ἄνθος αὐτοῦ ἐξέπεσεν, καὶ ἡ εὐπρέπεια τοῦ προσώπου αὐτοῦ ἀπώλετο. Οὕτως καὶ ὁ πλούσιος ἐν ταῖς πορείαις αὐτοῦ μαρανθήσεται.
59001012 Μακάριος ἀνὴρ ὃς ὑπομένει πειρασμόν, ὅτι δόκιμος γενόμενος, λήμψεται τὸν στέφανον τῆς ζωῆς, ὃν ἐπηγγείλατο τοῖς ἀγαπῶσιν αὐτόν.
59001013 Μηδεὶς πειραζόμενος λεγέτω, ὅτι “Ἀπὸ ˚Θεοῦ πειράζομαι.” Ὁ γὰρ ˚Θεὸς ἀπείραστός ἐστιν κακῶν, πειράζει δὲ αὐτὸς οὐδένα.
59001014 Ἕκαστος δὲ πειράζεται ὑπὸ τῆς ἰδίας ἐπιθυμίας, ἐξελκόμενος καὶ δελεαζόμενος.
59001015 Εἶτα ἡ ἐπιθυμία συλλαβοῦσα τίκτει ἁμαρτίαν, ἡ δὲ ἁμαρτία ἀποτελεσθεῖσα, ἀποκύει θάνατον.
59001016 Μὴ πλανᾶσθε, ἀδελφοί μου ἀγαπητοί.
59001017 Πᾶσα δόσις ἀγαθὴ, καὶ πᾶν δώρημα τέλειον ἄνωθέν ἐστιν, καταβαῖνον ἀπὸ τοῦ Πατρὸς τῶν φώτων, παρʼ ᾧ οὐκ ἔνι παραλλαγὴ ἢ τροπῆς ἀποσκίασμα.
59001018 Βουληθεὶς, ἀπεκύησεν ἡμᾶς λόγῳ ἀληθείας, εἰς τὸ εἶναι ἡμᾶς ἀπαρχήν τινα τῶν αὐτοῦ κτισμάτων.
59001019 Ἴστε, ἀδελφοί μου ἀγαπητοί. Ἔστω δὲ πᾶς ἄνθρωπος ταχὺς εἰς τὸ ἀκοῦσαι, βραδὺς εἰς τὸ λαλῆσαι, βραδὺς εἰς ὀργήν,
59001020 ὀργὴ γὰρ ἀνδρὸς, δικαιοσύνην ˚Θεοῦ οὐκ ἐργάζεται.
59001021 Διὸ ἀποθέμενοι πᾶσαν ῥυπαρίαν καὶ περισσείαν κακίας, ἐν πραΰτητι δέξασθε τὸν ἔμφυτον λόγον, τὸν δυνάμενον σῶσαι τὰς ψυχὰς ὑμῶν.
59001022 Γίνεσθε δὲ ποιηταὶ λόγου, καὶ μὴ μόνον ἀκροαταὶ παραλογιζόμενοι ἑαυτούς.
59001023 Ὅτι εἴ τις ἀκροατὴς λόγου ἐστὶν, καὶ οὐ ποιητής, οὗτος ἔοικεν ἀνδρὶ κατανοοῦντι τὸ πρόσωπον τῆς γενέσεως αὐτοῦ ἐν ἐσόπτρῳ.
59001024 Κατενόησεν γὰρ ἑαυτὸν καὶ ἀπελήλυθεν, καὶ εὐθέως ἐπελάθετο ὁποῖος ἦν.
59001025 Ὁ δὲ παρακύψας εἰς νόμον τέλειον, τὸν τῆς ἐλευθερίας, καὶ παραμείνας, οὐκ ἀκροατὴς ἐπιλησμονῆς γενόμενος, ἀλλὰ ποιητὴς ἔργου, οὗτος μακάριος ἐν τῇ ποιήσει αὐτοῦ ἔσται.
59001026 Εἴ τις δοκεῖ θρησκὸς εἶναι, μὴ χαλιναγωγῶν γλῶσσαν ἑαυτοῦ, ἀλλὰ ἀπατῶν καρδίαν αὐτοῦ, τούτου μάταιος ἡ θρησκεία.
59001027 Θρησκεία καθαρὰ καὶ ἀμίαντος παρὰ τῷ ˚Θεῷ καὶ Πατρὶ αὕτη ἐστίν: ἐπισκέπτεσθαι ὀρφανοὺς καὶ χήρας ἐν τῇ θλίψει αὐτῶν, ἄσπιλον ἑαυτὸν τηρεῖν ἀπὸ τοῦ κόσμου.
59002001 Ἀδελφοί μου, μὴ ἐν προσωπολημψίαις ἔχετε τὴν πίστιν τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ ˚Χριστοῦ, τῆς δόξης.
59002002 Ἐὰν γὰρ εἰσέλθῃ εἰς συναγωγὴν ὑμῶν, ἀνὴρ χρυσοδακτύλιος, ἐν ἐσθῆτι λαμπρᾷ, εἰσέλθῃ δὲ καὶ πτωχὸς, ἐν ῥυπαρᾷ ἐσθῆτι,
59002003 ἐπιβλέψητε δὲ ἐπὶ τὸν φοροῦντα τὴν ἐσθῆτα τὴν λαμπρὰν, καὶ εἴπητε, “Σὺ κάθου ὧδε καλῶς”, καὶ τῷ πτωχῷ εἴπητε, “Σὺ στῆθι ἐκεῖ”, ἢ, “Κάθου ὑπὸ τὸ ὑποπόδιόν μου”,
59002004 οὐ διεκρίθητε ἐν ἑαυτοῖς, καὶ ἐγένεσθε κριταὶ διαλογισμῶν πονηρῶν;
59002005 Ἀκούσατε, ἀδελφοί μου ἀγαπητοί: οὐχ ὁ ˚Θεὸς ἐξελέξατο τοὺς πτωχοὺς τῷ κόσμῳ, πλουσίους ἐν πίστει, καὶ κληρονόμους τῆς βασιλείας ἧς ἐπηγγείλατο τοῖς ἀγαπῶσιν αὐτόν;
59002006 Ὑμεῖς δὲ ἠτιμάσατε τὸν πτωχόν. Οὐχ οἱ πλούσιοι καταδυναστεύουσιν ὑμῶν, καὶ αὐτοὶ ἕλκουσιν ὑμᾶς εἰς κριτήρια;
59002007 Οὐκ αὐτοὶ βλασφημοῦσιν τὸ καλὸν ὄνομα τὸ ἐπικληθὲν ἐφʼ ὑμᾶς;
59002008 Εἰ μέντοι νόμον τελεῖτε βασιλικὸν, κατὰ τὴν Γραφήν, “Ἀγαπήσεις τὸν πλησίον σου ὡς σεαυτόν”, καλῶς ποιεῖτε.
59002009 Εἰ δὲ προσωπολημπτεῖτε, ἁμαρτίαν ἐργάζεσθε, ἐλεγχόμενοι ὑπὸ τοῦ νόμου ὡς παραβάται.
59002010 Ὅστις γὰρ ὅλον τὸν νόμον τηρήσῃ, πταίσῃ δὲ ἐν ἑνί, γέγονεν πάντων ἔνοχος.
59002011 Ὁ γὰρ εἰπών, “Μὴ μοιχεύσῃς”, εἶπεν καί, “Μὴ φονεύσῃς.” Εἰ δὲ οὐ μοιχεύεις, φονεύεις δέ, γέγονας παραβάτης νόμου.
59002012 Οὕτως λαλεῖτε, καὶ οὕτως ποιεῖτε, ὡς διὰ νόμου ἐλευθερίας μέλλοντες κρίνεσθαι.
59002013 Ἡ γὰρ κρίσις ἀνέλεος τῷ μὴ ποιήσαντι ἔλεος. Κατακαυχᾶται ἔλεος κρίσεως.
59002014 Τί ὄφελος, ἀδελφοί μου, ἐὰν πίστιν λέγῃ τις, ἔχειν ἔργα, δὲ μὴ ἔχῃ; Μὴ δύναται ἡ πίστις σῶσαι αὐτόν;
59002015 Ἐὰν ἀδελφὸς ἢ ἀδελφὴ γυμνοὶ ὑπάρχωσιν, καὶ λειπόμενοι τῆς ἐφημέρου τροφῆς,
59002016 εἴπῃ δέ τις αὐτοῖς ἐξ ὑμῶν, “Ὑπάγετε ἐν εἰρήνῃ, θερμαίνεσθε καὶ χορτάζεσθε”, μὴ δῶτε δὲ αὐτοῖς τὰ ἐπιτήδεια τοῦ σώματος, τί ὄφελος;
59002017 Οὕτως καὶ ἡ πίστις, ἐὰν μὴ ἔχῃ ἔργα, νεκρά ἐστιν καθʼ ἑαυτήν.
59002018 Ἀλλʼ ἐρεῖ τις, “Σὺ πίστιν ἔχεις, κἀγὼ ἔργα ἔχω. Δεῖξόν μοι τὴν πίστιν σου χωρὶς τῶν ἔργων, κἀγώ σοι δείξω ἐκ τῶν ἔργων μου τὴν πίστιν.”
59002019 Σὺ πιστεύεις ὅτι εἷς ἐστιν ὁ ˚Θεός. Καλῶς ποιεῖς· καὶ τὰ δαιμόνια πιστεύουσιν καὶ φρίσσουσιν.
59002020 Θέλεις δὲ γνῶναι, ὦ ἄνθρωπε κενέ, ὅτι ἡ πίστις χωρὶς τῶν ἔργων ἀργή ἐστιν;
59002021 Ἀβραὰμ ὁ πατὴρ ἡμῶν οὐκ ἐξ ἔργων ἐδικαιώθη, ἀνενέγκας Ἰσαὰκ τὸν υἱὸν αὐτοῦ ἐπὶ τὸ θυσιαστήριον;
59002022 Βλέπεις ὅτι ἡ πίστις συνήργει τοῖς ἔργοις αὐτοῦ, καὶ ἐκ τῶν ἔργων ἡ πίστις ἐτελειώθη.
59002023 Καὶ ἐπληρώθη ἡ Γραφὴ ἡ λέγουσα, “Ἐπίστευσεν δὲ Ἀβραὰμ τῷ ˚Θεῷ, καὶ ἐλογίσθη αὐτῷ εἰς δικαιοσύνην”, καὶ φίλος ˚Θεοῦ ἐκλήθη.
59002024 Ὁρᾶτε ὅτι ἐξ ἔργων δικαιοῦται ἄνθρωπος, καὶ οὐκ ἐκ πίστεως μόνον.
59002025 Ὁμοίως δὲ καὶ Ῥαὰβ ἡ πόρνη οὐκ ἐξ ἔργων ἐδικαιώθη, ὑποδεξαμένη τοὺς ἀγγέλους καὶ ἑτέρᾳ ὁδῷ ἐκβαλοῦσα;
59002026 Ὥσπερ γὰρ τὸ σῶμα χωρὶς πνεύματος νεκρόν ἐστιν, οὕτως καὶ ἡ πίστις χωρὶς ἔργων νεκρά ἐστιν.
59003001 Μὴ πολλοὶ διδάσκαλοι γίνεσθε, ἀδελφοί μου, εἰδότες ὅτι μεῖζον κρίμα λημψόμεθα.
59003002 Πολλὰ γὰρ πταίομεν ἅπαντες. Εἴ τις ἐν λόγῳ οὐ πταίει, οὗτος τέλειος ἀνήρ, δυνατὸς χαλιναγωγῆσαι καὶ ὅλον τὸ σῶμα.
59003003 Εἰ δὲ τῶν ἵππων τοὺς χαλινοὺς εἰς τὰ στόματα βάλλομεν, εἰς τὸ πείθεσθαι αὐτοὺς ἡμῖν, καὶ ὅλον τὸ σῶμα αὐτῶν μετάγομεν.
59003004 Ἰδοὺ, καὶ τὰ πλοῖα, τηλικαῦτα ὄντα, καὶ ὑπὸ ἀνέμων σκληρῶν ἐλαυνόμενα, μετάγεται ὑπὸ ἐλαχίστου πηδαλίου, ὅπου ἡ ὁρμὴ τοῦ εὐθύνοντος βούλεται.
59003005 Οὕτως καὶ ἡ γλῶσσα μικρὸν μέλος ἐστὶν, καὶ μεγάλα αὐχεῖ. Ἰδοὺ, ἡλίκον πῦρ, ἡλίκην ὕλην ἀνάπτει.
59003006 Καὶ ἡ γλῶσσα πῦρ, ὁ κόσμος τῆς ἀδικίας· ἡ γλῶσσα καθίσταται ἐν τοῖς μέλεσιν ἡμῶν, ἡ σπιλοῦσα ὅλον τὸ σῶμα, καὶ φλογίζουσα τὸν τροχὸν τῆς γενέσεως, καὶ φλογιζομένη ὑπὸ τῆς Γεέννης.
59003007 Πᾶσα γὰρ φύσις θηρίων τε καὶ πετεινῶν, ἑρπετῶν τε καὶ ἐναλίων, δαμάζεται καὶ δεδάμασται τῇ φύσει τῇ ἀνθρωπίνῃ·
59003008 τὴν δὲ γλῶσσαν οὐδεὶς δαμάσαι δύναται ἀνθρώπων, ἀκατάστατον κακόν, μεστὴ ἰοῦ θανατηφόρου.
59003009 Ἐν αὐτῇ εὐλογοῦμεν τὸν ˚Κύριον καὶ Πατέρα, καὶ ἐν αὐτῇ καταρώμεθα τοὺς ἀνθρώπους, τοὺς καθʼ ὁμοίωσιν ˚Θεοῦ γεγονότας.
59003010 Ἐκ τοῦ αὐτοῦ στόματος ἐξέρχεται εὐλογία καὶ κατάρα. Οὐ χρή ἀδελφοί μου ταῦτα οὕτως γίνεσθαι.
59003011 Μήτι ἡ πηγὴ ἐκ τῆς αὐτῆς ὀπῆς βρύει τὸ γλυκὺ καὶ τὸ πικρόν;
59003012 Μὴ δύναται, ἀδελφοί μου, συκῆ ἐλαίας ποιῆσαι ἢ ἄμπελος σῦκα; Οὔτε ἁλυκὸν γλυκὺ ποιῆσαι ὕδωρ.
59003013 Τίς σοφὸς καὶ ἐπιστήμων ἐν ὑμῖν; Δειξάτω ἐκ τῆς καλῆς ἀναστροφῆς τὰ ἔργα αὐτοῦ ἐν πραΰτητι σοφίας.
59003014 Εἰ δὲ ζῆλον πικρὸν ἔχετε, καὶ ἐριθείαν ἐν τῇ καρδίᾳ ὑμῶν, μὴ κατακαυχᾶσθε καὶ ψεύδεσθε κατὰ τῆς ἀληθείας.
59003015 Οὐκ ἔστιν αὕτη ἡ σοφία ἄνωθεν κατερχομένη, ἀλλὰ ἐπίγειος, ψυχική, δαιμονιώδης.
59003016 Ὅπου γὰρ ζῆλος καὶ ἐριθεία, ἐκεῖ ἀκαταστασία καὶ πᾶν φαῦλον πρᾶγμα.
59003017 Ἡ δὲ ἄνωθεν σοφία, πρῶτον μὲν ἁγνή ἐστιν, ἔπειτα εἰρηνική, ἐπιεικής, εὐπειθής, μεστὴ ἐλέους καὶ καρπῶν ἀγαθῶν, ἀδιάκριτος, ἀνυπόκριτος.
59003018 Καρπὸς δὲ δικαιοσύνης ἐν εἰρήνῃ σπείρεται, τοῖς ποιοῦσιν εἰρήνην.
59004001 Πόθεν πόλεμοι καὶ πόθεν μάχαι ἐν ὑμῖν; Οὐκ ἐντεῦθεν ἐκ τῶν ἡδονῶν ὑμῶν, τῶν στρατευομένων ἐν τοῖς μέλεσιν ὑμῶν;
59004002 Ἐπιθυμεῖτε καὶ οὐκ ἔχετε· φονεύετε καὶ ζηλοῦτε, καὶ οὐ δύνασθε ἐπιτυχεῖν· μάχεσθε καὶ πολεμεῖτε. Οὐκ ἔχετε, διὰ τὸ μὴ αἰτεῖσθαι ὑμᾶς·
59004003 αἰτεῖτε καὶ οὐ λαμβάνετε, διότι κακῶς αἰτεῖσθε, ἵνα ἐν ταῖς ἡδοναῖς ὑμῶν δαπανήσητε.
59004004 Μοιχαλίδες! Οὐκ οἴδατε ὅτι ἡ φιλία τοῦ κόσμου, ἔχθρα τοῦ ˚Θεοῦ ἐστιν; Ὃς ἐὰν οὖν βουληθῇ φίλος εἶναι τοῦ κόσμου, ἐχθρὸς τοῦ ˚Θεοῦ καθίσταται.
59004005 Ἢ δοκεῖτε ὅτι κενῶς ἡ Γραφὴ λέγει, “Πρὸς φθόνον ἐπιποθεῖ τὸ ˚Πνεῦμα ὃ κατῴκισεν ἐν ἡμῖν;”
59004006 Μείζονα δὲ δίδωσιν χάριν. Διὸ λέγει, “Ὁ ˚Θεὸς ὑπερηφάνοις ἀντιτάσσεται, ταπεινοῖς δὲ δίδωσιν χάριν.”
59004007 Ὑποτάγητε οὖν τῷ ˚Θεῷ. Ἀντίστητε δὲ τῷ διαβόλῳ, καὶ φεύξεται ἀφʼ ὑμῶν.
59004008 Ἐγγίσατε τῷ ˚Θεῷ, καὶ ἐγγιεῖ ὑμῖν. Καθαρίσατε χεῖρας, ἁμαρτωλοί, καὶ ἁγνίσατε καρδίας, δίψυχοι.
59004009 Ταλαιπωρήσατε, καὶ πενθήσατε, καὶ κλαύσατε. Ὁ γέλως ὑμῶν εἰς πένθος μετατραπήτω, καὶ ἡ χαρὰ εἰς κατήφειαν.
59004010 Ταπεινώθητε ἐνώπιον ˚Κυρίου, καὶ ὑψώσει ὑμᾶς.
59004011 Μὴ καταλαλεῖτε ἀλλήλων, ἀδελφοί. Ὁ καταλαλῶν ἀδελφοῦ ἢ κρίνων τὸν ἀδελφὸν αὐτοῦ, καταλαλεῖ νόμου καὶ κρίνει νόμον. Εἰ δὲ νόμον κρίνεις, οὐκ εἶ ποιητὴς νόμου, ἀλλὰ κριτής.
59004012 Εἷς ἐστιν νομοθέτης καὶ κριτής, ὁ δυνάμενος σῶσαι καὶ ἀπολέσαι. Σὺ δὲ τίς εἶ, ὁ κρίνων τὸν πλησίον;
59004013 Ἄγε νῦν, οἱ λέγοντες, “Σήμερον ἢ αὔριον, πορευσόμεθα εἰς τήνδε τὴν πόλιν, καὶ ποιήσομεν ἐκεῖ ἐνιαυτὸν, καὶ ἐμπορευσόμεθα, καὶ κερδήσομεν.”
59004014 Οἵτινες οὐκ ἐπίστασθε τῆς αὔριον, ποία γὰρ ζωὴ ὑμῶν; Ἀτμὶς γάρ ἐστε, ἡ πρὸς ὀλίγον φαινομένη, ἔπειτα καὶ ἀφανιζομένη.
59004015 Ἀντὶ τοῦ λέγειν ὑμᾶς, “Ἐὰν ὁ ˚Κύριος θελήσῃ, καὶ ζήσομεν καὶ ποιήσομεν, τοῦτο ἢ ἐκεῖνο.”
59004016 Νῦν δὲ καυχᾶσθε ἐν ταῖς ἀλαζονείαις ὑμῶν. Πᾶσα καύχησις τοιαύτη πονηρά ἐστιν.
59004017 Εἰδότι οὖν καλὸν ποιεῖν, καὶ μὴ ποιοῦντι, ἁμαρτία αὐτῷ ἐστιν.
59005001 Ἄγε νῦν, οἱ πλούσιοι, κλαύσατε, ὀλολύζοντες ἐπὶ ταῖς ταλαιπωρίαις ὑμῶν ταῖς ἐπερχομέναις.
59005002 Ὁ πλοῦτος ὑμῶν σέσηπεν, καὶ τὰ ἱμάτια ὑμῶν σητόβρωτα γέγονεν.
59005003 Ὁ χρυσὸς ὑμῶν καὶ ὁ ἄργυρος κατίωται, καὶ ὁ ἰὸς αὐτῶν εἰς μαρτύριον ὑμῖν ἔσται, καὶ φάγεται τὰς σάρκας ὑμῶν ὡς πῦρ. Ἐθησαυρίσατε ἐν ἐσχάταις ἡμέραις.
59005004 Ἰδοὺ, ὁ μισθὸς τῶν ἐργατῶν, τῶν ἀμησάντων τὰς χώρας ὑμῶν, ὁ ἀπεστερημένος ἀφʼ ὑμῶν, κράζει, καὶ αἱ βοαὶ τῶν θερισάντων, εἰς τὰ ὦτα ˚Κυρίου Σαβαὼθ εἰσεληλύθασιν.
59005005 Ἐτρυφήσατε ἐπὶ τῆς γῆς. Καὶ ἐσπαταλήσατε, ἐθρέψατε τὰς καρδίας ὑμῶν ἐν ἡμέρᾳ σφαγῆς.
59005006 Κατεδικάσατε, ἐφονεύσατε τὸν δίκαιον, οὐκ ἀντιτάσσεται ὑμῖν.
59005007 Μακροθυμήσατε οὖν, ἀδελφοί, ἕως τῆς παρουσίας τοῦ ˚Κυρίου. Ἰδοὺ, ὁ γεωργὸς ἐκδέχεται τὸν τίμιον καρπὸν τῆς γῆς, μακροθυμῶν ἐπʼ αὐτῷ ἕως λάβῃ πρόϊμον καὶ ὄψιμον.
59005008 Μακροθυμήσατε καὶ ὑμεῖς, στηρίξατε τὰς καρδίας ὑμῶν, ὅτι ἡ παρουσία τοῦ ˚Κυρίου ἤγγικεν.
59005009 Μὴ στενάζετε, ἀδελφοί, κατʼ ἀλλήλων, ἵνα μὴ κριθῆτε. Ἰδοὺ, ὁ κριτὴς πρὸ τῶν θυρῶν ἕστηκεν.
59005010 Ὑπόδειγμα λάβετε, ἀδελφοί, τῆς κακοπαθείας, καὶ τῆς μακροθυμίας, τοὺς προφήτας, οἳ ἐλάλησαν ἐν ὀνόματι ˚Κυρίου.
59005011 Ἰδοὺ, μακαρίζομεν τοὺς ὑπομείναντας. Τὴν ὑπομονὴν Ἰὼβ ἠκούσατε, καὶ τὸ τέλος ˚Κυρίου εἴδετε, ὅτι πολύσπλαγχνός ἐστιν ὁ ˚Κύριος καὶ οἰκτίρμων.
59005012 Πρὸ πάντων δέ, ἀδελφοί μου, μὴ ὀμνύετε, μήτε τὸν οὐρανὸν, μήτε τὴν γῆν, μήτε ἄλλον τινὰ ὅρκον· ἤτω δὲ ὑμῶν τὸ “Ναὶ”, ναὶ, καὶ τὸ “Οὒ”, οὔ, ἵνα μὴ ὑπὸ κρίσιν πέσητε.
59005013 Κακοπαθεῖ τις ἐν ὑμῖν; Προσευχέσθω. Εὐθυμεῖ τις; Ψαλλέτω.
59005014 Ἀσθενεῖ τις ἐν ὑμῖν; Προσκαλεσάσθω τοὺς πρεσβυτέρους τῆς ἐκκλησίας, καὶ προσευξάσθωσαν ἐπʼ αὐτὸν, ἀλείψαντες αὐτὸν ἐλαίῳ ἐν τῷ ὀνόματι τοῦ ˚Κυρίου.
59005015 Καὶ ἡ εὐχὴ τῆς πίστεως σώσει τὸν κάμνοντα, καὶ ἐγερεῖ αὐτὸν ὁ ˚Κύριος· κἂν ἁμαρτίας ᾖ πεποιηκώς, ἀφεθήσεται αὐτῷ.
59005016 Ἐξομολογεῖσθε οὖν ἀλλήλοις τὰς ἁμαρτίας, καὶ προσεύχεσθε ὑπὲρ ἀλλήλων, ὅπως ἰαθῆτε. Πολὺ ἰσχύει δέησις δικαίου ἐνεργουμένη.
59005017 Ἠλίας ἄνθρωπος ἦν ὁμοιοπαθὴς ἡμῖν, καὶ προσευχῇ προσηύξατο τοῦ μὴ βρέξαι, καὶ οὐκ ἔβρεξεν ἐπὶ τῆς γῆς, ἐνιαυτοὺς τρεῖς καὶ μῆνας ἕξ.
59005018 Καὶ πάλιν προσηύξατο, καὶ ὁ οὐρανὸς ὑετὸν ἔδωκεν, καὶ ἡ γῆ ἐβλάστησεν τὸν καρπὸν αὐτῆς.
59005019 Ἀδελφοί μου, ἐάν τις ἐν ὑμῖν πλανηθῇ ἀπὸ τῆς ἀληθείας, καὶ ἐπιστρέψῃ τις αὐτόν,
59005020 γινώσκετε ὅτι ὁ ἐπιστρέψας ἁμαρτωλὸν ἐκ πλάνης ὁδοῦ αὐτοῦ, σώσει ψυχὴν αὐτοῦ ἐκ θανάτου, καὶ καλύψει πλῆθος ἁμαρτιῶν.
60001001 Πέτρος, ἀπόστολος ˚Ἰησοῦ ˚Χριστοῦ, ἐκλεκτοῖς παρεπιδήμοις διασπορᾶς Πόντου, Γαλατίας, Καππαδοκίας, Ἀσίας, καὶ Βιθυνίας,
60001002 κατὰ πρόγνωσιν ˚Θεοῦ Πατρός ἐν ἁγιασμῷ ˚Πνεύματος, εἰς ὑπακοὴν καὶ ῥαντισμὸν αἵματος ˚Ἰησοῦ ˚Χριστοῦ: χάρις ὑμῖν καὶ εἰρήνη πληθυνθείη.
60001003 Εὐλογητὸς ὁ ˚Θεὸς καὶ Πατὴρ τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ ˚Χριστοῦ, ὁ κατὰ τὸ πολὺ αὐτοῦ ἔλεος, ἀναγεννήσας ἡμᾶς εἰς ἐλπίδα ζῶσαν, διʼ ἀναστάσεως ˚Ἰησοῦ ˚Χριστοῦ ἐκ νεκρῶν,
60001004 εἰς κληρονομίαν ἄφθαρτον, καὶ ἀμίαντον, καὶ ἀμάραντον, τετηρημένην ἐν οὐρανοῖς εἰς ὑμᾶς,
60001005 τοὺς ἐν δυνάμει ˚Θεοῦ φρουρουμένους διὰ πίστεως, εἰς σωτηρίαν ἑτοίμην ἀποκαλυφθῆναι ἐν καιρῷ ἐσχάτῳ.
60001006 Ἐν ᾧ ἀγαλλιᾶσθε ὀλίγον ἄρτι, εἰ δέον λυπηθέντες ἐν ποικίλοις πειρασμοῖς,
60001007 ἵνα τὸ δοκίμιον ὑμῶν τῆς πίστεως πολυτιμότερον χρυσίου τοῦ ἀπολλυμένου, διὰ πυρὸς δὲ δοκιμαζομένου, εὑρεθῇ εἰς ἔπαινον, καὶ δόξαν, καὶ τιμὴν, ἐν ἀποκαλύψει ˚Ἰησοῦ ˚Χριστοῦ.
60001008 Ὃν οὐκ ἰδόντες ἀγαπᾶτε, εἰς ὃν ἄρτι μὴ ὁρῶντες πιστεύοντες δὲ, ἀγαλλιᾶσθε χαρᾷ ἀνεκλαλήτῳ καὶ δεδοξασμένῃ,
60001009 κομιζόμενοι τὸ τέλος τῆς πίστεως ὑμῶν, σωτηρίαν ψυχῶν.
60001010 Περὶ ἧς σωτηρίας, ἐξεζήτησαν καὶ ἐξηραύνησαν προφῆται, οἱ περὶ τῆς εἰς ὑμᾶς χάριτος προφητεύσαντες,
60001011 ἐραυνῶντες εἰς τίνα ἢ ποῖον καιρὸν ἐδήλου τὸ ἐν αὐτοῖς ˚Πνεῦμα ˚Χριστοῦ, προμαρτυρόμενον τὰ εἰς ˚Χριστὸν παθήματα, καὶ τὰς μετὰ ταῦτα δόξας.
60001012 Οἷς ἀπεκαλύφθη ὅτι οὐχ ἑαυτοῖς, ὑμῖν δὲ διηκόνουν αὐτά, ἃ νῦν ἀνηγγέλη ὑμῖν, διὰ τῶν εὐαγγελισαμένων ὑμᾶς ˚Πνεύματι Ἁγίῳ, ἀποσταλέντι ἀπʼ οὐρανοῦ, εἰς ἃ ἐπιθυμοῦσιν ἄγγελοι παρακύψαι.
60001013 Διὸ ἀναζωσάμενοι τὰς ὀσφύας τῆς διανοίας ὑμῶν, νήφοντες, τελείως ἐλπίσατε ἐπὶ τὴν φερομένην ὑμῖν χάριν ἐν ἀποκαλύψει ˚Ἰησοῦ ˚Χριστοῦ.
60001014 Ὡς τέκνα ὑπακοῆς, μὴ συσχηματιζόμενοι ταῖς πρότερον ἐν τῇ ἀγνοίᾳ ὑμῶν ἐπιθυμίαις,
60001015 ἀλλὰ κατὰ τὸν καλέσαντα ὑμᾶς ἅγιον, καὶ αὐτοὶ ἅγιοι ἐν πάσῃ ἀναστροφῇ γενήθητε,
60001016 διότι γέγραπται, “Ἅγιοι ἔσεσθε, ὅτι ἐγὼ ἅγιος.”
60001017 Καὶ εἰ Πατέρα ἐπικαλεῖσθε, τὸν ἀπροσωπολήμπτως κρίνοντα κατὰ τὸ ἑκάστου ἔργον, ἐν φόβῳ τὸν τῆς παροικίας ὑμῶν χρόνον ἀναστράφητε·
60001018 εἰδότες ὅτι οὐ φθαρτοῖς, ἀργυρίῳ ἢ χρυσίῳ, ἐλυτρώθητε ἐκ τῆς ματαίας ὑμῶν ἀναστροφῆς πατροπαραδότου,
60001019 ἀλλὰ τιμίῳ αἵματι, ὡς ἀμνοῦ ἀμώμου καὶ ἀσπίλου ˚Χριστοῦ,
60001020 προεγνωσμένου μὲν πρὸ καταβολῆς κόσμου, φανερωθέντος δὲ ἐπʼ ἐσχάτου τῶν χρόνων διʼ ὑμᾶς,
60001021 τοὺς διʼ αὐτοῦ πιστοὺς εἰς ˚Θεὸν, τὸν ἐγείραντα αὐτὸν ἐκ νεκρῶν, καὶ δόξαν αὐτῷ δόντα, ὥστε τὴν πίστιν ὑμῶν καὶ ἐλπίδα, εἶναι εἰς ˚Θεόν.
60001022 Τὰς ψυχὰς ὑμῶν ἡγνικότες ἐν τῇ ὑπακοῇ τῆς ἀληθείας εἰς φιλαδελφίαν ἀνυπόκριτον ἐκ καρδίας, ἀλλήλους ἀγαπήσατε ἐκτενῶς.
60001023 Ἀναγεγεννημένοι, οὐκ ἐκ σπορᾶς φθαρτῆς, ἀλλὰ ἀφθάρτου, διὰ λόγου ζῶντος ˚Θεοῦ, καὶ μένοντος.
60001024 Διότι, “Πᾶσα σὰρξ ὡς χόρτος, καὶ πᾶσα δόξα αὐτῆς ὡς ἄνθος χόρτου.” “Ἐξηράνθη ὁ χόρτος, καὶ τὸ ἄνθος ἐξέπεσεν,
60001025 τὸ δὲ ῥῆμα ˚Κυρίου μένει εἰς τὸν αἰῶνα.” Τοῦτο δέ ἐστιν τὸ ῥῆμα τὸ εὐαγγελισθὲν εἰς ὑμᾶς.
60002001 Ἀποθέμενοι οὖν πᾶσαν κακίαν, καὶ πάντα δόλον, καὶ ὑποκρίσεις, καὶ φθόνους, καὶ πάσας καταλαλιάς,
60002002 ὡς ἀρτιγέννητα βρέφη τὸ λογικὸν ἄδολον γάλα ἐπιποθήσατε, ἵνα ἐν αὐτῷ αὐξηθῆτε εἰς σωτηρίαν,
60002003 εἰ ἐγεύσασθε ὅτι χρηστὸς ὁ ˚Κύριος.
60002004 Πρὸς ὃν προσερχόμενοι λίθον ζῶντα, ὑπὸ ἀνθρώπων μὲν ἀποδεδοκιμασμένον, παρὰ δὲ ˚Θεῷ ἐκλεκτὸν, ἔντιμον,
60002005 καὶ αὐτοὶ ὡς λίθοι ζῶντες οἰκοδομεῖσθε, οἶκος πνευματικὸς, εἰς ἱεράτευμα ἅγιον ἀνενέγκαι πνευματικὰς θυσίας εὐπροσδέκτους ˚Θεῷ διὰ ˚Ἰησοῦ ˚Χριστοῦ.
60002006 Διότι περιέχει ἐν Γραφῇ: “Ἰδοὺ, τίθημι ἐν Σιὼν λίθον, ἀκρογωνιαῖον ἐκλεκτὸν ἔντιμον, καὶ ὁ πιστεύων ἐπʼ αὐτῷ, οὐ μὴ καταισχυνθῇ.”
60002007 Ὑμῖν οὖν ἡ τιμὴ, τοῖς πιστεύουσιν· ἀπιστοῦσιν δὲ, “Λίθος ὃν ἀπεδοκίμασαν οἱ οἰκοδομοῦντες, οὗτος ἐγενήθη εἰς κεφαλὴν γωνίας”,
60002008 καὶ, “Λίθος προσκόμματος, καὶ πέτρα σκανδάλου”· οἳ προσκόπτουσιν τῷ λόγῳ ἀπειθοῦντες, εἰς ὃ καὶ ἐτέθησαν.
60002009 Ὑμεῖς δὲ “γένος ἐκλεκτόν”, “βασίλειον ἱεράτευμα, ἔθνος ἅγιον, λαὸς εἰς περιποίησιν”, ὅπως τὰς ἀρετὰς ἐξαγγείλητε, τοῦ ἐκ σκότους ὑμᾶς καλέσαντος, εἰς τὸ θαυμαστὸν αὐτοῦ φῶς·
60002010 οἵ ποτε “οὐ λαὸς, νῦν δὲ λαὸς ˚Θεοῦ, οἱ οὐκ ἠλεημένοι, νῦν δὲ ἐλεηθέντες”.
60002011 Ἀγαπητοί, παρακαλῶ ὡς παροίκους καὶ παρεπιδήμους, ἀπέχεσθαι τῶν σαρκικῶν ἐπιθυμιῶν, αἵτινες στρατεύονται κατὰ τῆς ψυχῆς·
60002012 τὴν ἀναστροφὴν ὑμῶν ἐν τοῖς ἔθνεσιν ἔχοντες καλήν, ἵνα ἐν ᾧ καταλαλοῦσιν ὑμῶν ὡς κακοποιῶν, ἐκ τῶν καλῶν ἔργων ἐποπτεύοντες, δοξάσωσι τὸν ˚Θεὸν ἐν ἡμέρᾳ ἐπισκοπῆς.
60002013 Ὑποτάγητε πάσῃ ἀνθρωπίνῃ κτίσει διὰ τὸν ˚Κύριον· εἴτε βασιλεῖ ὡς ὑπερέχοντι,
60002014 εἴτε ἡγεμόσιν ὡς διʼ αὐτοῦ πεμπομένοις, εἰς ἐκδίκησιν κακοποιῶν, ἔπαινον δὲ ἀγαθοποιῶν.
60002015 Ὅτι οὕτως ἐστὶν τὸ θέλημα τοῦ ˚Θεοῦ, ἀγαθοποιοῦντας φιμοῦν τὴν τῶν ἀφρόνων ἀνθρώπων ἀγνωσίαν·
60002016 ὡς ἐλεύθεροι, καὶ μὴ ὡς ἐπικάλυμμα ἔχοντες τῆς κακίας τὴν ἐλευθερίαν, ἀλλʼ ὡς ˚Θεοῦ δοῦλοι.
60002017 Πάντας τιμήσατε, τὴν ἀδελφότητα ἀγαπᾶτε, τὸν ˚Θεὸν φοβεῖσθε, τὸν βασιλέα τιμᾶτε.
60002018 Οἱ οἰκέται, ὑποτασσόμενοι ἐν παντὶ φόβῳ τοῖς δεσπόταις, οὐ μόνον τοῖς ἀγαθοῖς καὶ ἐπιεικέσιν, ἀλλὰ καὶ τοῖς σκολιοῖς.
60002019 Τοῦτο γὰρ χάρις, εἰ διὰ συνείδησιν ˚Θεοῦ, ὑποφέρει τις λύπας πάσχων ἀδίκως.
60002020 Ποῖον γὰρ κλέος, εἰ ἁμαρτάνοντες καὶ κολαφιζόμενοι ὑπομενεῖτε; Ἀλλʼ εἰ ἀγαθοποιοῦντες καὶ πάσχοντες ὑπομενεῖτε, τοῦτο χάρις παρὰ ˚Θεῷ.
60002021 Εἰς τοῦτο γὰρ ἐκλήθητε, ὅτι καὶ ˚Χριστὸς ἔπαθεν ὑπὲρ ὑμῶν, ὑμῖν ὑπολιμπάνων ὑπογραμμὸν, ἵνα ἐπακολουθήσητε τοῖς ἴχνεσιν αὐτοῦ·
60002022 “ὃς ἁμαρτίαν οὐκ ἐποίησεν, οὐδὲ εὑρέθη δόλος ἐν τῷ στόματι αὐτοῦ”·
60002023 ὃς λοιδορούμενος, οὐκ ἀντελοιδόρει, πάσχων, οὐκ ἠπείλει, παρεδίδου δὲ τῷ κρίνοντι δικαίως·
60002024 ὃς τὰς ἁμαρτίας ἡμῶν αὐτὸς ἀνήνεγκεν ἐν τῷ σώματι αὐτοῦ ἐπὶ τὸ ξύλον, ἵνα ταῖς ἁμαρτίαις ἀπογενόμενοι, τῇ δικαιοσύνῃ ζήσωμεν· οὗ τῷ μώλωπι ἰάθητε.
60002025 Ἦτε γὰρ ὡς πρόβατα πλανώμενοι, ἀλλʼ ἐπεστράφητε νῦν ἐπὶ τὸν ποιμένα καὶ ἐπίσκοπον τῶν ψυχῶν ὑμῶν.
60003001 Ὁμοίως, γυναῖκες, ὑποτασσόμεναι τοῖς ἰδίοις ἀνδράσιν, ἵνα καὶ εἴ τινες ἀπειθοῦσιν τῷ λόγῳ, διὰ τῆς τῶν γυναικῶν ἀναστροφῆς ἄνευ λόγου κερδηθήσονται,
60003002 ἐποπτεύσαντες τὴν ἐν φόβῳ ἁγνὴν ἀναστροφὴν ὑμῶν.
60003003 Ὧν ἔστω οὐχ ὁ ἔξωθεν ἐμπλοκῆς τριχῶν καὶ περιθέσεως χρυσίων ἢ ἐνδύσεως ἱματίων κόσμος,
60003004 ἀλλʼ ὁ κρυπτὸς τῆς καρδίας ἄνθρωπος, ἐν τῷ ἀφθάρτῳ τοῦ πραέως καὶ ἡσυχίου πνεύματος, ὅ ἐστιν ἐνώπιον τοῦ ˚Θεοῦ πολυτελές.
60003005 Οὕτως γάρ ποτε καὶ αἱ ἅγιαι γυναῖκες αἱ ἐλπίζουσαι εἰς ˚Θεὸν ἐκόσμουν ἑαυτάς, ὑποτασσόμεναι τοῖς ἰδίοις ἀνδράσιν,
60003006 ὡς Σάρρα ὑπήκουσεν τῷ Ἀβραάμ, “κύριον”, αὐτὸν καλοῦσα, ἧς ἐγενήθητε τέκνα, ἀγαθοποιοῦσαι καὶ μὴ φοβούμεναι μηδεμίαν πτόησιν.
60003007 Οἱ ἄνδρες, ὁμοίως συνοικοῦντες κατὰ γνῶσιν, ὡς ἀσθενεστέρῳ σκεύει τῷ γυναικείῳ, ἀπονέμοντες τιμήν ὡς καὶ συγκληρονόμοις χάριτος ζωῆς, εἰς τὸ μὴ ἐγκόπτεσθαι τὰς προσευχὰς ὑμῶν.
60003008 Τὸ δὲ τέλος, πάντες, ὁμόφρονες, συμπαθεῖς, φιλάδελφοι, εὔσπλαγχνοι, ταπεινόφρονες,
60003009 μὴ ἀποδιδόντες κακὸν ἀντὶ κακοῦ, ἢ λοιδορίαν ἀντὶ λοιδορίας, τοὐναντίον δὲ εὐλογοῦντες, ὅτι εἰς τοῦτο ἐκλήθητε, ἵνα εὐλογίαν κληρονομήσητε.
60003010 “Ὁ” γὰρ “θέλων ζωὴν ἀγαπᾶν, καὶ ἰδεῖν ἡμέρας ἀγαθὰς, παυσάτω τὴν γλῶσσαν ἀπὸ κακοῦ, καὶ χείλη τοῦ μὴ λαλῆσαι δόλον.
60003011 Ἐκκλινάτω δὲ ἀπὸ κακοῦ, καὶ ποιησάτω ἀγαθόν, ζητησάτω εἰρήνην καὶ διωξάτω αὐτήν.
60003012 Ὅτι ὀφθαλμοὶ ˚Κυρίου ἐπὶ δικαίους, καὶ ὦτα αὐτοῦ εἰς δέησιν αὐτῶν, πρόσωπον δὲ ˚Κυρίου ἐπὶ ποιοῦντας κακά.”
60003013 Καὶ τίς ὁ κακώσων ὑμᾶς, ἐὰν τοῦ ἀγαθοῦ ζηλωταὶ γένησθε;
60003014 Ἀλλʼ εἰ καὶ πάσχοιτε διὰ δικαιοσύνην, μακάριοι. “Τὸν δὲ φόβον αὐτῶν, μὴ φοβηθῆτε μηδὲ ταραχθῆτε”,
60003015 ˚Κύριον δὲ τὸν ˚Χριστὸν ἁγιάσατε ἐν ταῖς καρδίαις ὑμῶν, ἕτοιμοι ἀεὶ πρὸς ἀπολογίαν παντὶ τῷ αἰτοῦντι ὑμᾶς λόγον, περὶ τῆς ἐν ὑμῖν ἐλπίδος,
60003016 ἀλλὰ μετὰ πραΰτητος καὶ φόβου, συνείδησιν ἔχοντες ἀγαθήν, ἵνα ἐν ᾧ καταλαλοῦσιν ὑμῶν ὡς κακοποιῶν, καταισχυνθῶσιν οἱ ἐπηρεάζοντες ὑμῶν τὴν ἀγαθὴν ἐν ˚Χριστῷ ἀναστροφήν.
60003017 Κρεῖττον γὰρ ἀγαθοποιοῦντας, εἰ θέλοι τὸ θέλημα τοῦ ˚Θεοῦ, πάσχειν ἢ κακοποιοῦντας.
60003018 Ὅτι καὶ ˚Χριστὸς ἅπαξ περὶ ἁμαρτιῶν ἀπέθανεν, δίκαιος ὑπὲρ ἀδίκων, ἵνα ὑμᾶς προσαγάγῃ τῷ ˚Θεῷ, θανατωθεὶς μὲν σαρκὶ, ζῳοποιηθεὶς δὲ ˚Πνεύματι,
60003019 ἐν ᾧ καὶ τοῖς ἐν φυλακῇ πνεύμασιν πορευθεὶς ἐκήρυξεν,
60003020 ἀπειθήσασίν ποτε ὅτε ἀπεξεδέχετο ἡ τοῦ ˚Θεοῦ μακροθυμία ἐν ἡμέραις Νῶε, κατασκευαζομένης κιβωτοῦ εἰς ἣν ὀλίγοι, τοῦτʼ ἔστιν, ὀκτὼ ψυχαί, διεσώθησαν διʼ ὕδατος.
60003021 Ὃ καὶ ὑμᾶς ἀντίτυπον νῦν σῴζει βάπτισμα, οὐ σαρκὸς ἀπόθεσις ῥύπου, ἀλλὰ συνειδήσεως ἀγαθῆς, ἐπερώτημα εἰς ˚Θεόν διʼ ἀναστάσεως ˚Ἰησοῦ ˚Χριστοῦ,
60003022 ὅς ἐστιν ἐν δεξιᾷ ˚Θεοῦ, πορευθεὶς εἰς οὐρανὸν, ὑποταγέντων αὐτῷ ἀγγέλων, καὶ ἐξουσιῶν, καὶ δυνάμεων.
60004001 ˚Χριστοῦ οὖν παθόντος σαρκὶ, καὶ ὑμεῖς τὴν αὐτὴν ἔννοιαν ὁπλίσασθε, ὅτι ὁ παθὼν σαρκὶ πέπαυται ἁμαρτίας,
60004002 εἰς τὸ μηκέτι ἀνθρώπων ἐπιθυμίαις, ἀλλὰ θελήματι ˚Θεοῦ, τὸν ἐπίλοιπον ἐν σαρκὶ βιῶσαι χρόνον.
60004003 Ἀρκετὸς γὰρ ὁ παρεληλυθὼς χρόνος τὸ βούλημα τῶν ἐθνῶν κατειργάσθαι, πεπορευμένους ἐν ἀσελγείαις, ἐπιθυμίαις, οἰνοφλυγίαις, κώμοις, πότοις, καὶ ἀθεμίτοις εἰδωλολατρίαις.
60004004 Ἐν ᾧ ξενίζονται, μὴ συντρεχόντων ὑμῶν εἰς τὴν αὐτὴν τῆς ἀσωτίας ἀνάχυσιν, βλασφημοῦντες·
60004005 οἳ ἀποδώσουσιν λόγον τῷ ἑτοίμως κρῖναι ζῶντας καὶ νεκρούς.
60004006 Εἰς τοῦτο γὰρ καὶ νεκροῖς εὐηγγελίσθη, ἵνα κριθῶσι μὲν κατὰ ἀνθρώπους σαρκὶ, ζῶσι δὲ κατὰ ˚Θεὸν πνεύματι.
60004007 Πάντων δὲ τὸ τέλος ἤγγικεν. Σωφρονήσατε οὖν καὶ νήψατε εἰς προσευχάς·
60004008 πρὸ πάντων, τὴν εἰς ἑαυτοὺς ἀγάπην ἐκτενῆ ἔχοντες, ὅτι ἀγάπη καλύπτει πλῆθος ἁμαρτιῶν·
60004009 φιλόξενοι εἰς ἀλλήλους ἄνευ γογγυσμοῦ·
60004010 ἕκαστος καθὼς ἔλαβεν χάρισμα, εἰς ἑαυτοὺς αὐτὸ διακονοῦντες, ὡς καλοὶ οἰκονόμοι ποικίλης χάριτος ˚Θεοῦ.
60004011 Εἴ τις λαλεῖ, ὡς λόγια ˚Θεοῦ· εἴ τις διακονεῖ, ὡς ἐξ ἰσχύος ἧς χορηγεῖ ὁ ˚Θεός, ἵνα ἐν πᾶσιν δοξάζηται ὁ ˚Θεὸς διὰ ˚Ἰησοῦ ˚Χριστοῦ, ᾧ ἐστιν ἡ δόξα καὶ τὸ κράτος εἰς τοὺς αἰῶνας τῶν αἰώνων. Ἀμήν!
60004012 Ἀγαπητοί, μὴ ξενίζεσθε τῇ ἐν ὑμῖν πυρώσει πρὸς πειρασμὸν ὑμῖν γινομένῃ, ὡς ξένου ὑμῖν συμβαίνοντος,
60004013 ἀλλὰ καθὸ κοινωνεῖτε τοῖς τοῦ ˚Χριστοῦ παθήμασιν, χαίρετε ἵνα καὶ ἐν τῇ ἀποκαλύψει τῆς δόξης αὐτοῦ χαρῆτε ἀγαλλιώμενοι.
60004014 Εἰ ὀνειδίζεσθε ἐν ὀνόματι ˚Χριστοῦ, μακάριοι, ὅτι τὸ τῆς δόξης καὶ τὸ τοῦ ˚Θεοῦ ˚Πνεῦμα, ἐφʼ ὑμᾶς ἀναπαύεται.
60004015 Μὴ γάρ τις ὑμῶν πασχέτω ὡς φονεὺς, ἢ κλέπτης, ἢ κακοποιὸς, ἢ ὡς ἀλλοτριεπίσκοπος.
60004016 Εἰ δὲ ὡς Χριστιανός, μὴ αἰσχυνέσθω, δοξαζέτω δὲ τὸν ˚Θεὸν ἐν τῷ ὀνόματι τούτῳ.
60004017 Ὅτι ὁ καιρὸς τοῦ ἄρξασθαι τὸ κρίμα ἀπὸ τοῦ οἴκου τοῦ ˚Θεοῦ· εἰ δὲ πρῶτον ἀφʼ ἡμῶν, τί τὸ τέλος τῶν ἀπειθούντων τῷ τοῦ ˚Θεοῦ εὐαγγελίῳ;
60004018 “Καὶ εἰ ὁ δίκαιος μόλις σῴζεται, ὁ ἀσεβὴς καὶ ἁμαρτωλὸς ποῦ φανεῖται;”
60004019 Ὥστε καὶ οἱ πάσχοντες κατὰ τὸ θέλημα τοῦ ˚Θεοῦ, πιστῷ Κτίστῃ παρατιθέσθωσαν τὰς ψυχὰς αὐτῶν ἐν ἀγαθοποιΐᾳ.
60005001 Πρεσβυτέρους οὖν ἐν ὑμῖν παρακαλῶ, ὁ συμπρεσβύτερος καὶ μάρτυς τῶν τοῦ ˚Χριστοῦ παθημάτων, ὁ καὶ τῆς μελλούσης ἀποκαλύπτεσθαι δόξης κοινωνός,
60005002 ποιμάνατε τὸ ἐν ὑμῖν ποίμνιον τοῦ ˚Θεοῦ, μὴ ἀναγκαστῶς, ἀλλὰ ἑκουσίως, κατὰ ˚Θεόν, μηδὲ αἰσχροκερδῶς, ἀλλὰ προθύμως,
60005003 μηδʼ ὡς κατακυριεύοντες τῶν κλήρων, ἀλλὰ τύποι γινόμενοι τοῦ ποιμνίου.
60005004 Καὶ φανερωθέντος τοῦ ἀρχιποίμενος, κομιεῖσθε τὸν ἀμαράντινον τῆς δόξης στέφανον.
60005005 Ὁμοίως, νεώτεροι, ὑποτάγητε πρεσβυτέροις. Πάντες, δὲ ἀλλήλοις τὴν ταπεινοφροσύνην ἐγκομβώσασθε ὅτι, “Ὁ ˚Θεὸς ὑπερηφάνοις ἀντιτάσσεται, ταπεινοῖς δὲ δίδωσιν χάριν.”
60005006 Ταπεινώθητε οὖν ὑπὸ τὴν κραταιὰν χεῖρα τοῦ ˚Θεοῦ, ἵνα ὑμᾶς ὑψώσῃ ἐν καιρῷ,
60005007 πᾶσαν τὴν μέριμναν ὑμῶν ἐπιρίψαντες ἐπʼ αὐτόν, ὅτι αὐτῷ μέλει περὶ ὑμῶν.
60005008 Νήψατε, γρηγορήσατε. Ὁ ἀντίδικος ὑμῶν, διάβολος, ὡς λέων ὠρυόμενος περιπατεῖ, ζητῶν καταπιεῖν·
60005009 ᾧ ἀντίστητε, στερεοὶ τῇ πίστει, εἰδότες τὰ αὐτὰ τῶν παθημάτων, τῇ ἐν τῷ κόσμῳ ὑμῶν ἀδελφότητι ἐπιτελεῖσθαι.
60005010 Ὁ δὲ ˚Θεὸς πάσης χάριτος, ὁ καλέσας ὑμᾶς εἰς τὴν αἰώνιον αὐτοῦ δόξαν ἐν ˚Χριστῷ, ὀλίγον παθόντας, αὐτὸς καταρτίσει, στηρίξει, σθενώσει,
60005011 αὐτῷ τὸ κράτος εἰς τοὺς αἰῶνας τῶν αἰώνων. Ἀμήν!
60005012 Διὰ Σιλουανοῦ, ὑμῖν τοῦ πιστοῦ ἀδελφοῦ ( ὡς λογίζομαι ), διʼ ὀλίγων ἔγραψα, παρακαλῶν καὶ ἐπιμαρτυρῶν ταύτην εἶναι ἀληθῆ χάριν τοῦ ˚Θεοῦ, εἰς ἣν στῆτε.
60005013 Ἀσπάζεται ὑμᾶς ἡ ἐν Βαβυλῶνι συνεκλεκτὴ, καὶ Μᾶρκος, ὁ υἱός μου.
60005014 Ἀσπάσασθε ἀλλήλους ἐν φιλήματι ἀγάπης. Εἰρήνη ὑμῖν πᾶσιν, τοῖς ἐν ˚Χριστῷ.
61001001 Σίμων Πέτρος, δοῦλος καὶ ἀπόστολος ˚Ἰησοῦ ˚Χριστοῦ, τοῖς ἰσότιμον ἡμῖν λαχοῦσιν πίστιν ἐν δικαιοσύνῃ τοῦ ˚Θεοῦ ἡμῶν καὶ Σωτῆρος, ˚Ἰησοῦ ˚Χριστοῦ:
61001002 χάρις ὑμῖν καὶ εἰρήνη πληθυνθείη ἐν ἐπιγνώσει τοῦ ˚Θεοῦ, καὶ ˚Ἰησοῦ τοῦ ˚Κυρίου ἡμῶν.
61001003 Ὡς πάντα ἡμῖν τῆς θείας δυνάμεως αὐτοῦ, τὰ πρὸς ζωὴν καὶ εὐσέβειαν δεδωρημένης, διὰ τῆς ἐπιγνώσεως τοῦ καλέσαντος ἡμᾶς, ἰδίᾳ δόξῃ καὶ ἀρετῇ,
61001004 διʼ ὧν τὰ τίμια καὶ μέγιστα ἡμῖν ἐπαγγέλματα δεδώρηται, ἵνα διὰ τούτων γένησθε θείας κοινωνοὶ φύσεως, ἀποφυγόντες τῆς ἐν τῷ κόσμῳ ἐν ἐπιθυμίᾳ φθορᾶς.
61001005 Καὶ αὐτὸ τοῦτο δὲ σπουδὴν πᾶσαν παρεισενέγκαντες, ἐπιχορηγήσατε ἐν τῇ πίστει ὑμῶν τὴν ἀρετήν, ἐν δὲ τῇ ἀρετῇ τὴν γνῶσιν,
61001006 ἐν δὲ τῇ γνώσει τὴν ἐγκράτειαν, ἐν δὲ τῇ ἐγκρατείᾳ τὴν ὑπομονήν, ἐν δὲ τῇ ὑπομονῇ τὴν εὐσέβειαν,
61001007 ἐν δὲ τῇ εὐσεβείᾳ τὴν φιλαδελφίαν, ἐν δὲ τῇ φιλαδελφίᾳ τὴν ἀγάπην.
61001008 Ταῦτα γὰρ ὑμῖν ὑπάρχοντα καὶ πλεονάζοντα, οὐκ ἀργοὺς οὐδὲ ἀκάρπους καθίστησιν, εἰς τὴν τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ ˚Χριστοῦ, ἐπίγνωσιν.
61001009 ᾧ γὰρ μὴ πάρεστιν ταῦτα, τυφλός ἐστιν, μυωπάζων, λήθην λαβὼν τοῦ καθαρισμοῦ τῶν πάλαι αὐτοῦ ἁμαρτιῶν.
61001010 Διὸ μᾶλλον, ἀδελφοί, σπουδάσατε, βεβαίαν ὑμῶν τὴν κλῆσιν καὶ ἐκλογὴν ποιεῖσθαι, ταῦτα γὰρ ποιοῦντες οὐ μὴ πταίσητέ ποτε.
61001011 Οὕτως γὰρ πλουσίως ἐπιχορηγηθήσεται ὑμῖν ἡ εἴσοδος εἰς τὴν αἰώνιον βασιλείαν τοῦ ˚Κυρίου ἡμῶν καὶ Σωτῆρος, ˚Ἰησοῦ ˚Χριστοῦ.
61001012 Διὸ μελλήσω ἀεὶ ὑμᾶς ὑπομιμνῄσκειν περὶ τούτων, καίπερ εἰδότας καὶ ἐστηριγμένους ἐν τῇ παρούσῃ ἀληθείᾳ.
61001013 Δίκαιον δὲ ἡγοῦμαι ἐφʼ ὅσον εἰμὶ ἐν τούτῳ τῷ σκηνώματι, διεγείρειν ὑμᾶς ἐν ὑπομνήσει,
61001014 εἰδὼς ὅτι ταχινή ἐστιν ἡ ἀπόθεσις τοῦ σκηνώματός μου, καθὼς καὶ ὁ ˚Κύριος ἡμῶν, ˚Ἰησοῦς ˚Χριστὸς, ἐδήλωσέν μοι.
61001015 Σπουδάσω δὲ καὶ ἑκάστοτε ἔχειν ὑμᾶς μετὰ τὴν ἐμὴν ἔξοδον, τὴν τούτων μνήμην ποιεῖσθαι.
61001016 Οὐ γὰρ σεσοφισμένοις μύθοις ἐξακολουθήσαντες, ἐγνωρίσαμεν ὑμῖν τὴν τοῦ ˚Κυρίου ἡμῶν ˚Ἰησοῦ ˚Χριστοῦ δύναμιν καὶ παρουσίαν, ἀλλʼ ἐπόπται γενηθέντες τῆς ἐκείνου μεγαλειότητος.
61001017 Λαβὼν γὰρ παρὰ ˚Θεοῦ Πατρὸς τιμὴν καὶ δόξαν, φωνῆς ἐνεχθείσης αὐτῷ τοιᾶσδε ὑπὸ τῆς Μεγαλοπρεποῦς Δόξης, “Οὗτός ἐστιν ὁ Υἱός μου, ὁ ἀγαπητός εἰς ὃν ἐγὼ εὐδόκησα”·
61001018 καὶ ταύτην τὴν φωνὴν ἡμεῖς ἠκούσαμεν ἐξ οὐρανοῦ, ἐνεχθεῖσαν σὺν αὐτῷ, ὄντες ἐν τῷ ἁγίῳ ὄρει.
61001019 Καὶ ἔχομεν βεβαιότερον τὸν προφητικὸν λόγον, ᾧ καλῶς ποιεῖτε προσέχοντες, ὡς λύχνῳ φαίνοντι ἐν αὐχμηρῷ τόπῳ, ἕως οὗ ἡμέρα διαυγάσῃ, καὶ φωσφόρος ἀνατείλῃ ἐν ταῖς καρδίαις ὑμῶν.
61001020 Τοῦτο πρῶτον γινώσκοντες, ὅτι πᾶσα προφητεία Γραφῆς ἰδίας ἐπιλύσεως οὐ γίνεται.
61001021 Οὐ γὰρ θελήματι ἀνθρώπου ἠνέχθη προφητεία ποτέ, ἀλλὰ ὑπὸ ˚Πνεύματος Ἁγίου φερόμενοι, ἐλάλησαν ἀπὸ ˚Θεοῦ ἅγιοι ἄνθρωποι.
61002001 Ἐγένοντο δὲ καὶ ψευδοπροφῆται ἐν τῷ λαῷ, ὡς καὶ ἐν ὑμῖν ἔσονται ψευδοδιδάσκαλοι, οἵτινες παρεισάξουσιν αἱρέσεις ἀπωλείας, καὶ τὸν ἀγοράσαντα αὐτοὺς Δεσπότην ἀρνούμενοι, ἐπάγοντες ἑαυτοῖς ταχινὴν ἀπώλειαν.
61002002 Καὶ πολλοὶ ἐξακολουθήσουσιν αὐτῶν ταῖς ἀσελγείαις, διʼ οὓς ἡ ὁδὸς τῆς ἀληθείας βλασφημηθήσεται.
61002003 Καὶ ἐν πλεονεξίᾳ πλαστοῖς λόγοις ὑμᾶς ἐμπορεύσονται· οἷς τὸ κρίμα ἔκπαλαι οὐκ ἀργεῖ, καὶ ἡ ἀπώλεια αὐτῶν οὐ νυστάζει.
61002004 Εἰ γὰρ ὁ ˚Θεὸς ἀγγέλων ἁμαρτησάντων οὐκ ἐφείσατο, ἀλλὰ σειραῖς ζόφου ταρταρώσας, παρέδωκεν εἰς κρίσιν τηρουμένους,
61002005 καὶ ἀρχαίου κόσμου οὐκ ἐφείσατο, ἀλλὰ ὄγδοον, Νῶε, δικαιοσύνης κήρυκα ἐφύλαξεν, κατακλυσμὸν κόσμῳ ἀσεβῶν ἐπάξας,
61002006 καὶ πόλεις Σοδόμων καὶ Γομόρρας τεφρώσας κατέκρινεν, ὑπόδειγμα μελλόντων ἀσεβεῖν τεθεικώς,
61002007 καὶ δίκαιον Λὼτ, καταπονούμενον ὑπὸ τῆς τῶν ἀθέσμων ἐν ἀσελγείᾳ ἀναστροφῆς ἐρρύσατο
61002008 ( βλέμματι γὰρ καὶ ἀκοῇ ὁ δίκαιος, ἐγκατοικῶν ἐν αὐτοῖς ἡμέραν ἐξ ἡμέρας, ψυχὴν δικαίαν ἀνόμοις ἔργοις ἐβασάνιζεν )·
61002009 οἶδεν ˚Κύριος εὐσεβεῖς ἐκ πειρασμοῦ ῥύεσθαι, ἀδίκους δὲ εἰς ἡμέραν κρίσεως κολαζομένους τηρεῖν,
61002010 μάλιστα δὲ τοὺς ὀπίσω σαρκὸς ἐν ἐπιθυμίᾳ μιασμοῦ πορευομένους, καὶ κυριότητος καταφρονοῦντας. Τολμηταὶ αὐθάδεις, δόξας οὐ τρέμουσιν, βλασφημοῦντες,
61002011 ὅπου ἄγγελοι ἰσχύϊ καὶ δυνάμει μείζονες ὄντες, οὐ φέρουσιν κατʼ αὐτῶν παρὰ ˚Κυρίῳ βλάσφημον κρίσιν.
61002012 Οὗτοι δέ ὡς ἄλογα ζῷα, γεγεννημένα φυσικὰ εἰς ἅλωσιν καὶ φθοράν, ἐν οἷς ἀγνοοῦσιν βλασφημοῦντες, ἐν τῇ φθορᾷ αὐτῶν καὶ φθαρήσονται,
61002013 ἀδικούμενοι μισθὸν ἀδικίας, ἡδονὴν ἡγούμενοι τὴν ἐν ἡμέρᾳ τρυφήν, σπίλοι καὶ μῶμοι ἐντρυφῶντες ἐν ταῖς ἀπάταις αὐτῶν, συνευωχούμενοι ὑμῖν,
61002014 ὀφθαλμοὺς ἔχοντες μεστοὺς μοιχαλίδος, καὶ ἀκαταπαύστους ἁμαρτίας, δελεάζοντες ψυχὰς ἀστηρίκτους, καρδίαν γεγυμνασμένην πλεονεξίας ἔχοντες, κατάρας τέκνα,
61002015 καταλιπόντες εὐθεῖαν ὁδὸν, ἐπλανήθησαν ἐξακολουθήσαντες τῇ ὁδῷ τοῦ Βαλαὰμ τοῦ Βοσὸρ, ὃς μισθὸν ἀδικίας ἠγάπησεν,
61002016 ἔλεγξιν δὲ ἔσχεν ἰδίας παρανομίας, ὑποζύγιον ἄφωνον ἐν ἀνθρώπου φωνῇ φθεγξάμενον, ἐκώλυσεν τὴν τοῦ προφήτου παραφρονίαν.
61002017 Οὗτοί εἰσιν πηγαὶ ἄνυδροι, καὶ ὁμίχλαι ὑπὸ λαίλαπος ἐλαυνόμεναι, οἷς ὁ ζόφος τοῦ σκότους τετήρηται.
61002018 Ὑπέρογκα γὰρ ματαιότητος φθεγγόμενοι, δελεάζουσιν ἐν ἐπιθυμίαις σαρκὸς ἀσελγείαις, τοὺς ὄντως ἀποφεύγοντας τοὺς ἐν πλάνῃ ἀναστρεφομένους,
61002019 ἐλευθερίαν αὐτοῖς ἐπαγγελλόμενοι, αὐτοὶ δοῦλοι ὑπάρχοντες τῆς φθορᾶς· ᾧ γάρ τις ἥττηται, τούτῳ δεδούλωται.
61002020 Εἰ γὰρ ἀποφυγόντες τὰ μιάσματα τοῦ κόσμου, ἐν ἐπιγνώσει τοῦ ˚Κυρίου καὶ Σωτῆρος, ˚Ἰησοῦ ˚Χριστοῦ, τούτοις δὲ πάλιν ἐμπλακέντες ἡττῶνται, γέγονεν αὐτοῖς τὰ ἔσχατα χείρονα τῶν πρώτων.
61002021 Κρεῖττον γὰρ ἦν αὐτοῖς, μὴ ἐπεγνωκέναι τὴν ὁδὸν τῆς δικαιοσύνης, ἢ ἐπιγνοῦσιν, ὑποστρέψαι ἐκ τῆς παραδοθείσης αὐτοῖς ἁγίας ἐντολῆς.
61002022 Συμβέβηκεν αὐτοῖς τὸ τῆς ἀληθοῦς παροιμίας: “Κύων ἐπιστρέψας ἐπὶ τὸ ἴδιον ἐξέραμα”, καί, “Ὗς λουσαμένη, εἰς κυλισμὸν βορβόρου.”
61003001 Ταύτην ἤδη, ἀγαπητοί, δευτέραν ὑμῖν γράφω ἐπιστολήν, ἐν αἷς διεγείρω ὑμῶν ἐν ὑπομνήσει τὴν εἰλικρινῆ διάνοιαν,
61003002 μνησθῆναι τῶν προειρημένων ῥημάτων, ὑπὸ τῶν ἁγίων προφητῶν, καὶ τῆς τῶν ἀποστόλων ὑμῶν ἐντολῆς τοῦ ˚Κυρίου καὶ Σωτῆρος.
61003003 Τοῦτο πρῶτον γινώσκοντες, ὅτι ἐλεύσονται ἐπʼ ἐσχάτων τῶν ἡμερῶν ἐν ἐμπαιγμονῇ ἐμπαῖκται, κατὰ τὰς ἰδίας ἐπιθυμίας αὐτῶν πορευόμενοι,
61003004 καὶ λέγοντες, “Ποῦ ἐστιν ἡ ἐπαγγελία τῆς παρουσίας αὐτοῦ; Ἀφʼ ἧς γὰρ οἱ πατέρες ἐκοιμήθησαν, πάντα οὕτως διαμένει ἀπʼ ἀρχῆς κτίσεως.”
61003005 Λανθάνει γὰρ αὐτοὺς τοῦτο θέλοντας, ὅτι οὐρανοὶ ἦσαν ἔκπαλαι, καὶ γῆ ἐξ ὕδατος καὶ διʼ ὕδατος συνεστῶσα τῷ τοῦ ˚Θεοῦ λόγῳ,
61003006 διʼ ὧν ὁ τότε κόσμος ὕδατι κατακλυσθεὶς ἀπώλετο.
61003007 Οἱ δὲ νῦν οὐρανοὶ καὶ ἡ γῆ, τῷ αὐτῷ λόγῳ τεθησαυρισμένοι εἰσὶν, πυρὶ τηρούμενοι εἰς ἡμέραν κρίσεως καὶ ἀπωλείας τῶν ἀσεβῶν ἀνθρώπων.
61003008 Ἓν δὲ τοῦτο μὴ λανθανέτω ὑμᾶς, ἀγαπητοί, ὅτι μία ἡμέρα παρὰ ˚Κυρίῳ ὡς χίλια ἔτη, καὶ χίλια ἔτη ὡς ἡμέρα μία.
61003009 Οὐ βραδύνει ˚Κύριος τῆς ἐπαγγελίας, ὥς τινες βραδύτητα ἡγοῦνται, ἀλλὰ μακροθυμεῖ εἰς ὑμᾶς, μὴ βουλόμενός τινας ἀπολέσθαι, ἀλλὰ πάντας εἰς μετάνοιαν χωρῆσαι.
61003010 Ἥξει δὲ ἡμέρα ˚Κυρίου ὡς κλέπτης, ἐν ᾗ οἱ οὐρανοὶ ῥοιζηδὸν παρελεύσονται, στοιχεῖα δὲ καυσούμενα λυθήσεται, καὶ γῆ καὶ τὰ ἐν αὐτῇ ἔργα εὑρεθήσεται.
61003011 Τούτων οὕτως πάντων λυομένων, ποταποὺς δεῖ ὑπάρχειν ὑμᾶς ἐν ἁγίαις ἀναστροφαῖς καὶ εὐσεβείαις,
61003012 προσδοκῶντας καὶ σπεύδοντας τὴν παρουσίαν τῆς τοῦ ˚Θεοῦ ἡμέρας, διʼ ἣν οὐρανοὶ πυρούμενοι, λυθήσονται, καὶ στοιχεῖα καυσούμενα, τήκεται.
61003013 Καινοὺς δὲ οὐρανοὺς καὶ γῆν καινὴν, κατὰ τὸ ἐπάγγελμα αὐτοῦ προσδοκῶμεν, ἐν οἷς δικαιοσύνη κατοικεῖ.
61003014 Διό, ἀγαπητοί, ταῦτα προσδοκῶντες, σπουδάσατε ἄσπιλοι καὶ ἀμώμητοι αὐτῷ εὑρεθῆναι ἐν εἰρήνῃ.
61003015 Καὶ τὴν τοῦ ˚Κυρίου ἡμῶν μακροθυμίαν, σωτηρίαν ἡγεῖσθε, καθὼς καὶ ὁ ἀγαπητὸς ἡμῶν ἀδελφὸς Παῦλος, κατὰ τὴν δοθεῖσαν αὐτῷ σοφίαν, ἔγραψεν ὑμῖν,
61003016 ὡς καὶ ἐν πάσαις ἐπιστολαῖς, λαλῶν ἐν αὐταῖς περὶ τούτων, ἐν αἷς ἐστιν δυσνόητά τινα, ἃ οἱ ἀμαθεῖς καὶ ἀστήρικτοι στρεβλοῦσιν ὡς καὶ τὰς λοιπὰς Γραφὰς, πρὸς τὴν ἰδίαν αὐτῶν ἀπώλειαν.
61003017 Ὑμεῖς οὖν, ἀγαπητοί, προγινώσκοντες φυλάσσεσθε, ἵνα μὴ τῇ τῶν ἀθέσμων πλάνῃ συναπαχθέντες, ἐκπέσητε τοῦ ἰδίου στηριγμοῦ.
61003018 Αὐξάνετε δὲ ἐν χάριτι, καὶ γνώσει τοῦ ˚Κυρίου ἡμῶν καὶ Σωτῆρος, ˚Ἰησοῦ ˚Χριστοῦ. Αὐτῷ ἡ δόξα καὶ νῦν καὶ εἰς ἡμέραν αἰῶνος. Ἀμήν!
62001001 Ὃ ἦν ἀπʼ ἀρχῆς, ὃ ἀκηκόαμεν, ὃ ἑωράκαμεν τοῖς ὀφθαλμοῖς ἡμῶν, ὃ ἐθεασάμεθα, καὶ αἱ χεῖρες ἡμῶν ἐψηλάφησαν, περὶ τοῦ λόγου τῆς ζωῆς–
62001002 καὶ ἡ ζωὴ ἐφανερώθη, καὶ ἑωράκαμεν, καὶ μαρτυροῦμεν, καὶ ἀπαγγέλλομεν ὑμῖν, τὴν ζωὴν τὴν αἰώνιον, ἥτις ἦν πρὸς τὸν Πατέρα, καὶ ἐφανερώθη ἡμῖν–
62001003 ὃ ἑωράκαμεν, καὶ ἀκηκόαμεν, ἀπαγγέλλομεν καὶ ὑμῖν, ἵνα καὶ ὑμεῖς κοινωνίαν ἔχητε μεθʼ ἡμῶν· καὶ ἡ κοινωνία δὲ ἡ ἡμετέρα μετὰ τοῦ Πατρὸς, καὶ μετὰ τοῦ Υἱοῦ αὐτοῦ, ˚Ἰησοῦ ˚Χριστοῦ.
62001004 Καὶ ταῦτα γράφομεν ἡμεῖς, ἵνα ἡ χαρὰ ἡμῶν ᾖ πεπληρωμένη.
62001005 Καὶ ἔστιν αὕτη ἡ ἀγγελία ἣν ἀκηκόαμεν ἀπʼ αὐτοῦ καὶ ἀναγγέλλομεν ὑμῖν, ὅτι ὁ ˚Θεὸς φῶς ἐστιν, καὶ σκοτία ἐν αὐτῷ, οὐκ ἔστιν οὐδεμία.
62001006 Ἐὰν εἴπωμεν ὅτι κοινωνίαν ἔχομεν μετʼ αὐτοῦ, καὶ ἐν τῷ σκότει περιπατῶμεν, ψευδόμεθα καὶ οὐ ποιοῦμεν τὴν ἀλήθειαν.
62001007 Ἐὰν δὲ ἐν τῷ φωτὶ περιπατῶμεν, ὡς αὐτός ἐστιν ἐν τῷ φωτί, κοινωνίαν ἔχομεν μετʼ ἀλλήλων, καὶ τὸ αἷμα ˚Ἰησοῦ τοῦ Υἱοῦ αὐτοῦ, καθαρίζει ἡμᾶς ἀπὸ πάσης ἁμαρτίας.
62001008 Ἐὰν εἴπωμεν ὅτι ἁμαρτίαν οὐκ ἔχομεν, ἑαυτοὺς πλανῶμεν καὶ ἡ ἀλήθεια οὐκ ἔστιν ἐν ἡμῖν.
62001009 Ἐὰν ὁμολογῶμεν τὰς ἁμαρτίας ἡμῶν, πιστός ἐστιν καὶ δίκαιος, ἵνα ἀφῇ ἡμῖν τὰς ἁμαρτίας, καὶ καθαρίσῃ ἡμᾶς ἀπὸ πάσης ἀδικίας.
62001010 Ἐὰν εἴπωμεν ὅτι οὐχ ἡμαρτήκαμεν, ψεύστην ποιοῦμεν αὐτὸν, καὶ ὁ λόγος αὐτοῦ οὐκ ἔστιν ἐν ἡμῖν.
62002001 Τεκνία μου, ταῦτα γράφω ὑμῖν, ἵνα μὴ ἁμάρτητε. Καὶ ἐάν τις ἁμάρτῃ, Παράκλητον ἔχομεν πρὸς τὸν Πατέρα, ˚Ἰησοῦν ˚Χριστὸν δίκαιον,
62002002 καὶ αὐτὸς ἱλασμός ἐστιν περὶ τῶν ἁμαρτιῶν ἡμῶν, οὐ περὶ τῶν ἡμετέρων δὲ μόνον, ἀλλὰ καὶ περὶ ὅλου τοῦ κόσμου.
62002003 Καὶ ἐν τούτῳ γινώσκομεν ὅτι ἐγνώκαμεν αὐτόν, ἐὰν τὰς ἐντολὰς αὐτοῦ τηρῶμεν.
62002004 Ὁ λέγων, ὅτι “Ἔγνωκα αὐτὸν”, καὶ τὰς ἐντολὰς αὐτοῦ μὴ τηρῶν, ψεύστης ἐστίν, καὶ ἐν τούτῳ ἡ ἀλήθεια οὐκ ἔστιν·
62002005 ὃς δʼ ἂν τηρῇ αὐτοῦ τὸν λόγον, ἀληθῶς ἐν τούτῳ ἡ ἀγάπη τοῦ ˚Θεοῦ τετελείωται. Ἐν τούτῳ γινώσκομεν ὅτι ἐν αὐτῷ ἐσμεν:
62002006 ὁ λέγων ἐν αὐτῷ μένειν, ὀφείλει καθὼς ἐκεῖνος περιεπάτησεν, καὶ αὐτὸς περιπατεῖν.
62002007 Ἀγαπητοί, οὐκ ἐντολὴν καινὴν γράφω ὑμῖν, ἀλλʼ ἐντολὴν παλαιὰν, ἣν εἴχετε ἀπʼ ἀρχῆς. Ἡ ἐντολὴ ἡ παλαιά ἐστιν ὁ λόγος ὃν ἠκούσατε.
62002008 Πάλιν ἐντολὴν καινὴν γράφω ὑμῖν, ὅ ἐστιν ἀληθὲς ἐν αὐτῷ καὶ ἐν ὑμῖν, ὅτι ἡ σκοτία παράγεται, καὶ τὸ φῶς τὸ ἀληθινὸν ἤδη φαίνει.
62002009 Ὁ λέγων ἐν τῷ φωτὶ εἶναι, καὶ τὸν ἀδελφὸν αὐτοῦ μισῶν, ἐν τῇ σκοτίᾳ ἐστὶν ἕως ἄρτι.
62002010 Ὁ ἀγαπῶν τὸν ἀδελφὸν αὐτοῦ, ἐν τῷ φωτὶ μένει, καὶ σκάνδαλον ἐν αὐτῷ οὐκ ἔστιν
62002011 Ὁ δὲ μισῶν τὸν ἀδελφὸν αὐτοῦ, ἐν τῇ σκοτίᾳ ἐστὶν, καὶ ἐν τῇ σκοτίᾳ περιπατεῖ, καὶ οὐκ οἶδεν ποῦ ὑπάγει, ὅτι ἡ σκοτία ἐτύφλωσεν τοὺς ὀφθαλμοὺς αὐτοῦ.
62002012 Γράφω ὑμῖν, τεκνία, ὅτι ἀφέωνται ὑμῖν αἱ ἁμαρτίαι, διὰ τὸ ὄνομα αὐτοῦ.
62002013 Γράφω ὑμῖν, πατέρες, ὅτι ἐγνώκατε τὸν ἀπʼ ἀρχῆς. Γράφω ὑμῖν, νεανίσκοι, ὅτι νενικήκατε τὸν πονηρόν.
62002014 Ἔγραψα ὑμῖν, παιδία, ὅτι ἐγνώκατε τὸν Πατέρα. Ἔγραψα ὑμῖν, πατέρες, ὅτι ἐγνώκατε τὸν ἀπʼ ἀρχῆς. Ἔγραψα ὑμῖν, νεανίσκοι, ὅτι ἰσχυροί ἐστε, καὶ ὁ λόγος τοῦ ˚Θεοῦ ἐν ὑμῖν μένει, καὶ νενικήκατε τὸν πονηρόν.
62002015 Μὴ ἀγαπᾶτε τὸν κόσμον, μηδὲ τὰ ἐν τῷ κόσμῳ. Ἐάν τις ἀγαπᾷ τὸν κόσμον, οὐκ ἔστιν ἡ ἀγάπη τοῦ Πατρὸς ἐν αὐτῷ.
62002016 Ὅτι πᾶν τὸ ἐν τῷ κόσμῳ, ἡ ἐπιθυμία τῆς σαρκὸς, καὶ ἡ ἐπιθυμία τῶν ὀφθαλμῶν, καὶ ἡ ἀλαζονεία τοῦ βίου, οὐκ ἔστιν ἐκ τοῦ Πατρός, ἀλλʼ ἐκ τοῦ κόσμου ἐστίν.
62002017 Καὶ ὁ κόσμος παράγεται, καὶ ἡ ἐπιθυμία αὐτοῦ, ὁ δὲ ποιῶν τὸ θέλημα τοῦ ˚Θεοῦ, μένει εἰς τὸν αἰῶνα.
62002018 Παιδία, ἐσχάτη ὥρα ἐστίν, καὶ καθὼς ἠκούσατε ὅτι ἀντίχριστος ἔρχεται, καὶ νῦν ἀντίχριστοι πολλοὶ γεγόνασιν, ὅθεν γινώσκομεν ὅτι ἐσχάτη ὥρα ἐστίν.
62002019 Ἐξ ἡμῶν ἐξῆλθαν, ἀλλʼ οὐκ ἦσαν ἐξ ἡμῶν· εἰ γὰρ ἐξ ἡμῶν ἦσαν, μεμενήκεισαν ἂν μεθʼ ἡμῶν· ἀλλʼ ἵνα φανερωθῶσιν ὅτι οὐκ εἰσὶν πάντες ἐξ ἡμῶν.
62002020 Καὶ ὑμεῖς χρῖσμα ἔχετε ἀπὸ τοῦ Ἁγίου, καὶ οἴδατε πάντες.
62002021 Οὐκ ἔγραψα ὑμῖν ὅτι οὐκ οἴδατε τὴν ἀλήθειαν, ἀλλʼ ὅτι οἴδατε αὐτήν, καὶ ὅτι πᾶν ψεῦδος ἐκ τῆς ἀληθείας οὐκ ἔστιν.
62002022 Τίς ἐστιν ὁ ψεύστης, εἰ μὴ ὁ ἀρνούμενος ὅτι ˚Ἰησοῦς οὐκ ἔστιν ὁ ˚Χριστός; Οὗτός ἐστιν ὁ ἀντίχριστος, ὁ ἀρνούμενος τὸν Πατέρα καὶ τὸν Υἱόν.
62002023 Πᾶς ὁ ἀρνούμενος τὸν Υἱὸν, οὐδὲ τὸν Πατέρα ἔχει. Ὁ ὁμολογῶν τὸν Υἱὸν, καὶ τὸν Πατέρα ἔχει.
62002024 Ὑμεῖς ὃ ἠκούσατε ἀπʼ ἀρχῆς, ἐν ὑμῖν μενέτω. Ἐὰν ἐν ὑμῖν μείνῃ ὃ ἀπʼ ἀρχῆς ἠκούσατε, καὶ ὑμεῖς ἐν τῷ Υἱῷ καὶ ἐν τῷ Πατρὶ μενεῖτε.
62002025 Καὶ αὕτη ἐστὶν ἡ ἐπαγγελία ἣν αὐτὸς ἐπηγγείλατο ἡμῖν– τὴν ζωὴν τὴν αἰώνιον.
62002026 Ταῦτα ἔγραψα ὑμῖν περὶ τῶν πλανώντων ὑμᾶς.
62002027 Καὶ ὑμεῖς, τὸ χρῖσμα ὃ ἐλάβετε ἀπʼ αὐτοῦ, μένει ἐν ὑμῖν, καὶ οὐ χρείαν ἔχετε ἵνα τις διδάσκῃ ὑμᾶς· ἀλλʼ ὡς τὸ αὐτοῦ χρῖσμα διδάσκει ὑμᾶς περὶ πάντων, καὶ ἀληθές ἐστιν καὶ οὐκ ἔστιν ψεῦδος, καὶ καθὼς ἐδίδαξεν ὑμᾶς, μένετε ἐν αὐτῷ.
62002028 Καὶ νῦν, τεκνία, μένετε ἐν αὐτῷ, ἵνα ἐὰν φανερωθῇ, σχῶμεν παρρησίαν, καὶ μὴ αἰσχυνθῶμεν ἀπʼ αὐτοῦ ἐν τῇ παρουσίᾳ αὐτοῦ.
62002029 Ἐὰν εἰδῆτε ὅτι δίκαιός ἐστιν, γινώσκετε ὅτι καὶ πᾶς ὁ ποιῶν τὴν δικαιοσύνην ἐξ αὐτοῦ γεγέννηται.
62003001 Ἴδετε ποταπὴν ἀγάπην δέδωκεν ἡμῖν ὁ Πατὴρ, ἵνα τέκνα ˚Θεοῦ κληθῶμεν, καὶ ἐσμέν. Διὰ τοῦτο, ὁ κόσμος οὐ γινώσκει ἡμᾶς, ὅτι οὐκ ἔγνω αὐτόν.
62003002 Ἀγαπητοί, νῦν τέκνα ˚Θεοῦ ἐσμεν, καὶ οὔπω ἐφανερώθη τί ἐσόμεθα. Οἴδαμεν ὅτι ἐὰν φανερωθῇ, ὅμοιοι αὐτῷ ἐσόμεθα, ὅτι ὀψόμεθα αὐτὸν καθώς ἐστιν.
62003003 Καὶ πᾶς ὁ ἔχων τὴν ἐλπίδα ταύτην ἐπʼ αὐτῷ, ἁγνίζει ἑαυτὸν, καθὼς ἐκεῖνος ἁγνός ἐστιν.
62003004 Πᾶς ὁ ποιῶν τὴν ἁμαρτίαν, καὶ τὴν ἀνομίαν ποιεῖ, καὶ ἡ ἁμαρτία ἐστὶν ἡ ἀνομία.
62003005 Καὶ οἴδατε ὅτι ἐκεῖνος ἐφανερώθη, ἵνα τὰς ἁμαρτίας ἄρῃ, καὶ ἁμαρτία ἐν αὐτῷ οὐκ ἔστιν.
62003006 Πᾶς ὁ ἐν αὐτῷ μένων οὐχ ἁμαρτάνει, πᾶς ὁ ἁμαρτάνων οὐχ ἑώρακεν αὐτὸν, οὐδὲ ἔγνωκεν αὐτόν.
62003007 Τεκνία, μηδεὶς πλανάτω ὑμᾶς· ὁ ποιῶν τὴν δικαιοσύνην, δίκαιός ἐστιν, καθὼς ἐκεῖνος δίκαιός ἐστιν.
62003008 Ὁ ποιῶν τὴν ἁμαρτίαν, ἐκ τοῦ διαβόλου ἐστίν, ὅτι ἀπʼ ἀρχῆς ὁ διάβολος ἁμαρτάνει. Εἰς τοῦτο ἐφανερώθη ὁ Υἱὸς τοῦ ˚Θεοῦ, ἵνα λύσῃ τὰ ἔργα τοῦ διαβόλου.
62003009 Πᾶς ὁ γεγεννημένος ἐκ τοῦ ˚Θεοῦ, ἁμαρτίαν οὐ ποιεῖ, ὅτι σπέρμα αὐτοῦ ἐν αὐτῷ μένει, καὶ οὐ δύναται ἁμαρτάνειν, ὅτι ἐκ τοῦ ˚Θεοῦ γεγέννηται.
62003010 Ἐν τούτῳ φανερά ἐστιν τὰ τέκνα τοῦ ˚Θεοῦ, καὶ τὰ τέκνα τοῦ διαβόλου: πᾶς ὁ μὴ ποιῶν δικαιοσύνην, οὐκ ἔστιν ἐκ τοῦ ˚Θεοῦ, καὶ ὁ μὴ ἀγαπῶν τὸν ἀδελφὸν αὐτοῦ.
62003011 Ὅτι αὕτη ἐστὶν ἡ ἀγγελία ἣν ἠκούσατε ἀπʼ ἀρχῆς, ἵνα ἀγαπῶμεν ἀλλήλους·
62003012 οὐ καθὼς Κάϊν ἐκ τοῦ πονηροῦ ἦν, καὶ ἔσφαξεν τὸν ἀδελφὸν αὐτοῦ. Καὶ χάριν τίνος ἔσφαξεν αὐτόν; Ὅτι τὰ ἔργα αὐτοῦ πονηρὰ ἦν, τὰ δὲ τοῦ ἀδελφοῦ αὐτοῦ, δίκαια.
62003013 Μὴ θαυμάζετε, ἀδελφοί, εἰ μισεῖ ὑμᾶς ὁ κόσμος.
62003014 Ἡμεῖς οἴδαμεν ὅτι μεταβεβήκαμεν ἐκ τοῦ θανάτου εἰς τὴν ζωήν, ὅτι ἀγαπῶμεν τοὺς ἀδελφούς. Ὁ μὴ ἀγαπῶν, μένει ἐν τῷ θανάτῳ.
62003015 Πᾶς ὁ μισῶν τὸν ἀδελφὸν αὐτοῦ, ἀνθρωποκτόνος ἐστίν, καὶ οἴδατε ὅτι πᾶς ἀνθρωποκτόνος οὐκ ἔχει ζωὴν αἰώνιον ἐν ἑαυτῷ μένουσαν.
62003016 Ἐν τούτῳ ἐγνώκαμεν τὴν ἀγάπην, ὅτι ἐκεῖνος ὑπὲρ ἡμῶν τὴν ψυχὴν αὐτοῦ ἔθηκεν· καὶ ἡμεῖς ὀφείλομεν ὑπὲρ τῶν ἀδελφῶν, τὰς ψυχὰς θεῖναι.
62003017 Ὃς δʼ ἂν ἔχῃ τὸν βίον τοῦ κόσμου, καὶ θεωρῇ τὸν ἀδελφὸν αὐτοῦ χρείαν ἔχοντα, καὶ κλείσῃ τὰ σπλάγχνα αὐτοῦ ἀπʼ αὐτοῦ, πῶς ἡ ἀγάπη τοῦ ˚Θεοῦ μένει ἐν αὐτῷ;
62003018 Τεκνία, μὴ ἀγαπῶμεν λόγῳ, μηδὲ τῇ γλώσσῃ, ἀλλὰ ἐν ἔργῳ καὶ ἀληθείᾳ.
62003019 ἐν τούτῳ γνωσόμεθα ὅτι ἐκ τῆς ἀληθείας ἐσμέν, καὶ ἔμπροσθεν αὐτοῦ πείσομεν τὰς καρδίας ἡμῶν,
62003020 ὅτι ἐὰν καταγινώσκῃ ἡμῶν ἡ καρδία, ὅτι μείζων ἐστὶν ὁ ˚Θεὸς τῆς καρδίας ἡμῶν, καὶ γινώσκει πάντα.
62003021 Ἀγαπητοί, ἐὰν ἡ καρδία μὴ καταγινώσκῃ, παρρησίαν ἔχομεν πρὸς τὸν ˚Θεόν,
62003022 καὶ ὃ ἐὰν αἰτῶμεν, λαμβάνομεν ἀπʼ αὐτοῦ, ὅτι τὰς ἐντολὰς αὐτοῦ τηροῦμεν, καὶ τὰ ἀρεστὰ ἐνώπιον αὐτοῦ ποιοῦμεν.
62003023 Καὶ αὕτη ἐστὶν ἡ ἐντολὴ αὐτοῦ, ἵνα πιστεύσωμεν τῷ ὀνόματι τοῦ Υἱοῦ αὐτοῦ, ˚Ἰησοῦ ˚Χριστοῦ, καὶ ἀγαπῶμεν ἀλλήλους, καθὼς ἔδωκεν ἐντολὴν ἡμῖν.
62003024 Καὶ ὁ τηρῶν τὰς ἐντολὰς αὐτοῦ, ἐν αὐτῷ μένει, καὶ αὐτὸς ἐν αὐτῷ. Καὶ ἐν τούτῳ γινώσκομεν ὅτι μένει ἐν ἡμῖν, ἐκ τοῦ ˚Πνεύματος οὗ ἡμῖν ἔδωκεν.
62004001 Ἀγαπητοί, μὴ παντὶ πνεύματι πιστεύετε, ἀλλὰ δοκιμάζετε τὰ πνεύματα, εἰ ἐκ τοῦ ˚Θεοῦ ἐστιν, ὅτι πολλοὶ ψευδοπροφῆται ἐξεληλύθασιν εἰς τὸν κόσμον.
62004002 Ἐν τούτῳ γινώσκετε τὸ ˚Πνεῦμα τοῦ ˚Θεοῦ: πᾶν πνεῦμα ὃ ὁμολογεῖ ˚Ἰησοῦν ˚Χριστὸν ἐν σαρκὶ ἐληλυθότα, ἐκ τοῦ ˚Θεοῦ ἐστιν,
62004003 καὶ πᾶν πνεῦμα ὃ μὴ ὁμολογεῖ τὸν ˚Ἰησοῦν, ἐκ τοῦ ˚Θεοῦ οὐκ ἔστιν. Καὶ τοῦτό ἐστιν τὸ τοῦ ἀντιχρίστου, ὃ ἀκηκόατε ὅτι ἔρχεται, καὶ νῦν ἐν τῷ κόσμῳ ἐστὶν ἤδη.
62004004 Ὑμεῖς ἐκ τοῦ ˚Θεοῦ ἐστε, τεκνία, καὶ νενικήκατε αὐτούς, ὅτι μείζων ἐστὶν ὁ ἐν ὑμῖν, ἢ ὁ ἐν τῷ κόσμῳ.
62004005 Αὐτοὶ ἐκ τοῦ κόσμου εἰσίν· διὰ τοῦτο ἐκ τοῦ κόσμου λαλοῦσιν, καὶ ὁ κόσμος αὐτῶν ἀκούει.
62004006 Ἡμεῖς ἐκ τοῦ ˚Θεοῦ ἐσμεν. Ὁ γινώσκων τὸν ˚Θεὸν, ἀκούει ἡμῶν, ὃς οὐκ ἔστιν ἐκ τοῦ ˚Θεοῦ, οὐκ ἀκούει ἡμῶν. Ἐκ τούτου γινώσκομεν τὸ πνεῦμα τῆς ἀληθείας καὶ τὸ πνεῦμα τῆς πλάνης.
62004007 Ἀγαπητοί, ἀγαπῶμεν ἀλλήλους, ὅτι ἡ ἀγάπη ἐκ τοῦ ˚Θεοῦ ἐστιν, καὶ πᾶς ὁ ἀγαπῶν, ἐκ τοῦ ˚Θεοῦ γεγέννηται, καὶ γινώσκει τὸν ˚Θεόν.
62004008 Ὁ μὴ ἀγαπῶν, οὐκ ἔγνω τὸν ˚Θεόν, ὅτι ὁ ˚Θεὸς ἀγάπη ἐστίν.
62004009 Ἐν τούτῳ ἐφανερώθη ἡ ἀγάπη τοῦ ˚Θεοῦ ἐν ἡμῖν, ὅτι τὸν Υἱὸν αὐτοῦ, τὸν μονογενῆ, ἀπέσταλκεν ὁ ˚Θεὸς εἰς τὸν κόσμον, ἵνα ζήσωμεν διʼ αὐτοῦ.
62004010 Ἐν τούτῳ ἐστὶν ἡ ἀγάπη, οὐχ ὅτι ἡμεῖς ἠγαπήκαμεν τὸν ˚Θεόν, ἀλλʼ ὅτι αὐτὸς ἠγάπησεν ἡμᾶς, καὶ ἀπέστειλεν τὸν Υἱὸν αὐτοῦ, ἱλασμὸν περὶ τῶν ἁμαρτιῶν ἡμῶν.
62004011 Ἀγαπητοί, εἰ οὕτως ὁ ˚Θεὸς ἠγάπησεν ἡμᾶς, καὶ ἡμεῖς ὀφείλομεν ἀλλήλους ἀγαπᾶν.
62004012 ˚Θεὸν οὐδεὶς πώποτε τεθέαται, ἐὰν ἀγαπῶμεν ἀλλήλους, ὁ ˚Θεὸς ἐν ἡμῖν μένει, καὶ ἡ ἀγάπη αὐτοῦ τετελειωμένη ἐν ἡμῖν ἐστίν.
62004013 Ἐν τούτῳ γινώσκομεν, ὅτι ἐν αὐτῷ μένομεν, καὶ αὐτὸς ἐν ἡμῖν, ὅτι ἐκ τοῦ ˚Πνεύματος αὐτοῦ δέδωκεν ἡμῖν.
62004014 Καὶ ἡμεῖς τεθεάμεθα καὶ μαρτυροῦμεν ὅτι ὁ Πατὴρ ἀπέσταλκεν τὸν Υἱὸν, Σωτῆρα τοῦ κόσμου.
62004015 Ὃς ἂν ὁμολογήσῃ ὅτι ˚Ἰησοῦς ἐστιν ὁ Υἱὸς τοῦ ˚Θεοῦ, ὁ ˚Θεὸς ἐν αὐτῷ μένει, καὶ αὐτὸς ἐν τῷ ˚Θεῷ.
62004016 Καὶ ἡμεῖς ἐγνώκαμεν καὶ πεπιστεύκαμεν τὴν ἀγάπην ἣν ἔχει ὁ ˚Θεὸς ἐν ἡμῖν. Ὁ ˚Θεὸς ἀγάπη ἐστίν, καὶ ὁ μένων ἐν τῇ ἀγάπῃ, ἐν τῷ ˚Θεῷ μένει, καὶ ὁ ˚Θεὸς ἐν αὐτῷ μένει.
62004017 Ἐν τούτῳ τετελείωται ἡ ἀγάπη μεθʼ ἡμῶν, ἵνα παρρησίαν ἔχωμεν ἐν τῇ ἡμέρᾳ τῆς κρίσεως, ὅτι καθὼς ἐκεῖνός ἐστιν, καὶ ἡμεῖς ἐσμεν ἐν τῷ κόσμῳ τούτῳ.
62004018 Φόβος οὐκ ἔστιν ἐν τῇ ἀγάπῃ, ἀλλʼ ἡ τελεία ἀγάπη ἔξω βάλλει τὸν φόβον, ὅτι ὁ φόβος κόλασιν ἔχει, ὁ δὲ φοβούμενος, οὐ τετελείωται ἐν τῇ ἀγάπῃ.
62004019 Ἡμεῖς ἀγαπῶμεν, ὅτι αὐτὸς πρῶτος ἠγάπησεν ἡμᾶς.
62004020 Ἐάν τις εἴπῃ, ὅτι “Ἀγαπῶ τὸν ˚Θεόν”, καὶ τὸν ἀδελφὸν αὐτοῦ μισῇ, ψεύστης ἐστίν· ὁ γὰρ μὴ ἀγαπῶν τὸν ἀδελφὸν αὐτοῦ, ὃν ἑώρακεν, τὸν ˚Θεὸν, ὃν οὐχ ἑώρακεν, οὐ δύναται ἀγαπᾶν.
62004021 Καὶ ταύτην τὴν ἐντολὴν ἔχομεν ἀπʼ αὐτοῦ, ἵνα ὁ ἀγαπῶν τὸν ˚Θεὸν, ἀγαπᾷ καὶ τὸν ἀδελφὸν αὐτοῦ.
62005001 Πᾶς ὁ πιστεύων ὅτι ˚Ἰησοῦς ἐστιν ὁ ˚Χριστὸς, ἐκ τοῦ ˚Θεοῦ γεγέννηται, καὶ πᾶς ὁ ἀγαπῶν τὸν γεννήσαντα, ἀγαπᾷ καὶ τὸν γεγεννημένον ἐξ αὐτοῦ.
62005002 Ἐν τούτῳ γινώσκομεν ὅτι ἀγαπῶμεν τὰ τέκνα τοῦ ˚Θεοῦ: ὅταν τὸν ˚Θεὸν ἀγαπῶμεν, καὶ τὰς ἐντολὰς αὐτοῦ τηρῶμεν.
62005003 Αὕτη γάρ ἐστιν ἡ ἀγάπη τοῦ ˚Θεοῦ, ἵνα τὰς ἐντολὰς αὐτοῦ τηρῶμεν, καὶ αἱ ἐντολαὶ αὐτοῦ βαρεῖαι οὐκ εἰσίν,
62005004 ὅτι πᾶν τὸ γεγεννημένον ἐκ τοῦ ˚Θεοῦ, νικᾷ τὸν κόσμον. Καὶ αὕτη ἐστὶν ἡ νίκη ἡ νικήσασα τὸν κόσμον– ἡ πίστις ἡμῶν.
62005005 Τίς ἐστιν δὲ ὁ νικῶν τὸν κόσμον, εἰ μὴ ὁ πιστεύων ὅτι ˚Ἰησοῦς ἐστιν ὁ Υἱὸς τοῦ ˚Θεοῦ;
62005006 Οὗτός ἐστιν ὁ ἐλθὼν διʼ ὕδατος καὶ αἵματος, ˚Ἰησοῦς ˚Χριστός· οὐκ ἐν τῷ ὕδατι μόνον, ἀλλʼ ἐν τῷ ὕδατι καὶ ἐν τῷ αἵματι. Καὶ τὸ ˚Πνεῦμά ἐστιν τὸ μαρτυροῦν, ὅτι τὸ ˚Πνεῦμά ἐστιν ἡ ἀλήθεια.
62005007 Ὅτι τρεῖς εἰσιν οἱ μαρτυροῦντες:
62005008 τὸ ˚Πνεῦμα, καὶ τὸ ὕδωρ, καὶ τὸ αἷμα, καὶ οἱ τρεῖς εἰς τὸ ἕν εἰσιν.
62005009 Εἰ τὴν μαρτυρίαν τῶν ἀνθρώπων λαμβάνομεν, ἡ μαρτυρία τοῦ ˚Θεοῦ μείζων ἐστίν, ὅτι αὕτη ἐστὶν ἡ μαρτυρία τοῦ ˚Θεοῦ, ὅτι μεμαρτύρηκεν περὶ τοῦ Υἱοῦ αὐτοῦ.
62005010 Ὁ πιστεύων εἰς τὸν Υἱὸν τοῦ ˚Θεοῦ, ἔχει τὴν μαρτυρίαν ἐν αὑτῷ· ὁ μὴ πιστεύων τῷ ˚Θεῷ, ψεύστην πεποίηκεν αὐτόν, ὅτι οὐ πεπίστευκεν εἰς τὴν μαρτυρίαν ἣν μεμαρτύρηκεν ὁ ˚Θεὸς περὶ τοῦ Υἱοῦ αὐτοῦ.
62005011 Καὶ αὕτη ἐστὶν ἡ μαρτυρία: ὅτι ζωὴν αἰώνιον ἔδωκεν ἡμῖν ὁ ˚Θεὸς, καὶ αὕτη ἡ ζωὴ ἐν τῷ Υἱῷ αὐτοῦ ἐστιν.
62005012 Ὁ ἔχων τὸν Υἱὸν, ἔχει τὴν ζωήν· ὁ μὴ ἔχων τὸν Υἱὸν τοῦ ˚Θεοῦ, τὴν ζωὴν οὐκ ἔχει.
62005013 Ταῦτα ἔγραψα ὑμῖν, ἵνα εἰδῆτε ὅτι ζωὴν ἔχετε αἰώνιον, τοῖς πιστεύουσιν εἰς τὸ ὄνομα τοῦ υἱοῦ τοῦ ˚Θεοῦ.
62005014 καὶ αὕτη ἐστὶν ἡ παρρησία ἣν ἔχομεν πρὸς αὐτόν: ὅτι ἐάν τι αἰτώμεθα κατὰ τὸ θέλημα αὐτοῦ, ἀκούει ἡμῶν.
62005015 Καὶ ἐὰν οἴδαμεν ὅτι ἀκούει ἡμῶν, ὃ ἂν αἰτώμεθα, οἴδαμεν ὅτι ἔχομεν τὰ αἰτήματα ἃ ᾐτήκαμεν ἀπʼ αὐτοῦ.
62005016 Ἐάν τις ἴδῃ τὸν ἀδελφὸν αὐτοῦ ἁμαρτάνοντα ἁμαρτίαν μὴ πρὸς θάνατον, αἰτήσει καὶ δώσει αὐτῷ ζωήν, τοῖς ἁμαρτάνουσιν μὴ πρὸς θάνατον. Ἔστιν ἁμαρτία πρὸς θάνατον· οὐ περὶ ἐκείνης λέγω ἵνα ἐρωτήσῃ.
62005017 Πᾶσα ἀδικία ἁμαρτία ἐστίν, καὶ ἔστιν ἁμαρτία οὐ πρὸς θάνατον.
62005018 Οἴδαμεν ὅτι πᾶς ὁ γεγεννημένος ἐκ τοῦ ˚Θεοῦ οὐχ ἁμαρτάνει, ἀλλʼ ὁ γεννηθεὶς ἐκ τοῦ ˚Θεοῦ τηρεῖ αὐτόν καὶ ὁ πονηρὸς οὐχ ἅπτεται αὐτοῦ.
62005019 Οἴδαμεν ὅτι ἐκ τοῦ ˚Θεοῦ ἐσμεν, καὶ ὁ κόσμος ὅλος ἐν τῷ πονηρῷ κεῖται.
62005020 Οἴδαμεν δὲ ὅτι ὁ Υἱὸς τοῦ ˚Θεοῦ ἥκει, καὶ δέδωκεν ἡμῖν διάνοιαν, ἵνα γινώσκομεν τὸν Ἀληθινόν· καὶ ἐσμὲν ἐν τῷ Ἀληθινῷ, ἐν τῷ Υἱῷ αὐτοῦ, ˚Ἰησοῦ ˚Χριστῷ. Οὗτός ἐστιν ὁ ἀληθινὸς ˚Θεὸς καὶ ζωὴ αἰώνιος.
62005021 Τεκνία, φυλάξατε ἑαυτὰ ἀπὸ τῶν εἰδώλων.
63001001 Ὁ πρεσβύτερος· ἐκλεκτῇ κυρίᾳ καὶ τοῖς τέκνοις αὐτῆς, οὓς ἐγὼ ἀγαπῶ ἐν ἀληθείᾳ ( καὶ οὐκ ἐγὼ μόνος, ἀλλὰ καὶ πάντες οἱ ἐγνωκότες τὴν ἀλήθειαν ),
63001002 διὰ τὴν ἀλήθειαν τὴν μένουσαν ἐν ἡμῖν, καὶ μεθʼ ἡμῶν ἔσται εἰς τὸν αἰῶνα:
63001003 ἔσται μεθʼ ἡμῶν χάρις, ἔλεος, εἰρήνη, παρὰ ˚Θεοῦ Πατρός καὶ παρὰ ˚Ἰησοῦ ˚Χριστοῦ, τοῦ Υἱοῦ τοῦ Πατρός, ἐν ἀληθείᾳ καὶ ἀγάπῃ.
63001004 Ἐχάρην λίαν ὅτι εὕρηκα ἐκ τῶν τέκνων σου περιπατοῦντας ἐν ἀληθείᾳ, καθὼς ἐντολὴν ἐλάβομεν παρὰ τοῦ Πατρός.
63001005 Καὶ νῦν ἐρωτῶ σε, κυρία, οὐχ ὡς ἐντολὴν καινὴν γράφων σοι, ἀλλὰ ἣν εἴχομεν ἀπʼ ἀρχῆς, ἵνα ἀγαπῶμεν ἀλλήλους.
63001006 Καὶ αὕτη ἐστὶν ἡ ἀγάπη: ἵνα περιπατῶμεν κατὰ τὰς ἐντολὰς αὐτοῦ. Αὕτη ἡ ἐντολή ἐστιν, καθὼς ἠκούσατε ἀπʼ ἀρχῆς, ἵνα ἐν αὐτῇ περιπατῆτε.
63001007 Ὅτι πολλοὶ πλάνοι ἐξῆλθον εἰς τὸν κόσμον, οἱ μὴ ὁμολογοῦντες ˚Ἰησοῦν ˚Χριστὸν ἐρχόμενον ἐν σαρκί. Οὗτός ἐστιν ὁ πλάνος καὶ ὁ ἀντίχριστος.
63001008 Βλέπετε ἑαυτούς ἵνα μὴ ἀπολέσητε ἃ εἰργασάμεθα, ἀλλὰ μισθὸν πλήρη ἀπολάβητε.
63001009 Πᾶς ὁ προάγων καὶ μὴ μένων ἐν τῇ διδαχῇ τοῦ ˚Χριστοῦ, ˚Θεὸν οὐκ ἔχει· ὁ μένων ἐν τῇ διδαχῇ, οὗτος καὶ τὸν Πατέρα καὶ τὸν Υἱὸν ἔχει.
63001010 Εἴ τις ἔρχεται πρὸς ὑμᾶς, καὶ ταύτην τὴν διδαχὴν οὐ φέρει, μὴ λαμβάνετε αὐτὸν εἰς οἰκίαν, καὶ χαίρειν αὐτῷ μὴ λέγετε·
63001011 ὁ λέγων γὰρ αὐτῷ χαίρειν κοινωνεῖ τοῖς ἔργοις αὐτοῦ τοῖς πονηροῖς.
63001012 Πολλὰ ἔχων ὑμῖν γράφειν, οὐκ ἐβουλήθην διὰ χάρτου καὶ μέλανος, ἀλλὰ ἐλπίζω γενέσθαι πρὸς ὑμᾶς, καὶ στόμα πρὸς στόμα λαλῆσαι, ἵνα ἡ χαρὰ ὑμῶν πεπληρωμένη ᾖ.
63001013 Ἀσπάζεταί σε τὰ τέκνα τῆς ἀδελφῆς σου τῆς ἐκλεκτῆς.
64001001 Ὁ πρεσβύτερος· Γαΐῳ τῷ ἀγαπητῷ, ὃν ἐγὼ ἀγαπῶ ἐν ἀληθείᾳ.
64001002 Ἀγαπητέ, περὶ πάντων εὔχομαί σε εὐοδοῦσθαι καὶ ὑγιαίνειν, καθὼς εὐοδοῦταί σου ἡ ψυχή.
64001003 Ἐχάρην γὰρ λίαν ἐρχομένων ἀδελφῶν καὶ μαρτυρούντων σου τῇ ἀληθείᾳ, καθὼς σὺ ἐν ἀληθείᾳ περιπατεῖς.
64001004 Μειζοτέραν τούτων οὐκ ἔχω χαράν, ἵνα ἀκούω τὰ ἐμὰ τέκνα ἐν τῇ ἀληθείᾳ περιπατοῦντα.
64001005 Ἀγαπητέ, πιστὸν ποιεῖς ὃ, ἐὰν ἐργάσῃ εἰς τοὺς ἀδελφοὺς καὶ τοῦτο ξένους,
64001006 οἳ ἐμαρτύρησάν σου τῇ ἀγάπῃ ἐνώπιον ἐκκλησίας, οὓς καλῶς ποιήσεις, προπέμψας ἀξίως τοῦ ˚Θεοῦ.
64001007 Ὑπὲρ γὰρ τοῦ ὀνόματος ἐξῆλθαν, μηδὲν λαμβάνοντες ἀπὸ τῶν ἐθνικῶν.
64001008 Ἡμεῖς οὖν ὀφείλομεν ὑπολαμβάνειν τοὺς τοιούτους, ἵνα συνεργοὶ γινώμεθα τῇ ἀληθείᾳ.
64001009 Ἔγραψά τι τῇ ἐκκλησίᾳ, ἀλλʼ ὁ φιλοπρωτεύων αὐτῶν, Διοτρέφης, οὐκ ἐπιδέχεται ἡμᾶς.
64001010 Διὰ τοῦτο, ἐὰν ἔλθω, ὑπομνήσω αὐτοῦ τὰ ἔργα, ἃ ποιεῖ, λόγοις πονηροῖς φλυαρῶν ἡμᾶς, καὶ μὴ ἀρκούμενος ἐπὶ τούτοις, οὔτε αὐτὸς ἐπιδέχεται τοὺς ἀδελφοὺς, καὶ τοὺς βουλομένους κωλύει, καὶ ἐκ τῆς ἐκκλησίας ἐκβάλλει.
64001011 Ἀγαπητέ, μὴ μιμοῦ τὸ κακὸν, ἀλλὰ τὸ ἀγαθόν. Ὁ ἀγαθοποιῶν ἐκ τοῦ ˚Θεοῦ ἐστιν, ὁ κακοποιῶν οὐχ ἑώρακεν τὸν ˚Θεόν.
64001012 Δημητρίῳ μεμαρτύρηται ὑπὸ πάντων, καὶ ὑπὸ αὐτῆς τῆς ἀληθείας· καὶ ἡμεῖς δὲ μαρτυροῦμεν, καὶ οἶδας ὅτι ἡ μαρτυρία ἡμῶν ἀληθής ἐστιν.
64001013 Πολλὰ εἶχον γράψαι σοι, ἀλλʼ οὐ θέλω διὰ μέλανος καὶ καλάμου σοι γράφειν,
64001014 ἐλπίζω δὲ εὐθέως σε ἰδεῖν, καὶ στόμα πρὸς στόμα λαλήσομεν.
64001015 Εἰρήνη σοι. Ἀσπάζονταί σε οἱ φίλοι. Ἀσπάζου τοὺς φίλους κατʼ ὄνομα.
65001001 Ἰούδας, ˚Ἰησοῦ ˚Χριστοῦ δοῦλος, ἀδελφὸς δὲ Ἰακώβου, τοῖς ἐν ˚Θεῷ Πατρὶ ἠγαπημένοις, καὶ ˚Ἰησοῦ ˚Χριστῷ τετηρημένοις κλητοῖς:
65001002 ἔλεος ὑμῖν, καὶ εἰρήνη, καὶ ἀγάπη πληθυνθείη.
65001003 Ἀγαπητοί, πᾶσαν σπουδὴν ποιούμενος γράφειν ὑμῖν περὶ τῆς κοινῆς ἡμῶν σωτηρίας, ἀνάγκην ἔσχον γράψαι ὑμῖν, παρακαλῶν ἐπαγωνίζεσθαι τῇ ἅπαξ παραδοθείσῃ τοῖς ἁγίοις πίστει.
65001004 Παρεισέδυσαν γάρ τινες ἄνθρωποι οἱ πάλαι προγεγραμμένοι εἰς τοῦτο τὸ κρίμα, ἀσεβεῖς, τὴν τοῦ ˚Θεοῦ ἡμῶν χάριτα μετατιθέντες εἰς ἀσέλγειαν, καὶ τὸν μόνον Δεσπότην καὶ ˚Κύριον ἡμῶν, ˚Ἰησοῦν ˚Χριστὸν, ἀρνούμενοι.
65001005 Ὑπομνῆσαι δὲ ὑμᾶς βούλομαι, εἰδότας ὑμᾶς ἅπαξ πάντα, ὅτι ˚Ἰησοῦς λαὸν ἐκ γῆς Αἰγύπτου σώσας, τὸ δεύτερον τοὺς μὴ πιστεύσαντας ἀπώλεσεν.
65001006 Ἀγγέλους τε τοὺς μὴ τηρήσαντας τὴν ἑαυτῶν ἀρχὴν, ἀλλὰ ἀπολιπόντας τὸ ἴδιον οἰκητήριον, εἰς κρίσιν μεγάλης ἡμέρας, δεσμοῖς ἀϊδίοις ὑπὸ ζόφον τετήρηκεν.
65001007 Ὡς Σόδομα καὶ Γόμορρα, καὶ αἱ περὶ αὐτὰς πόλεις, τὸν ὅμοιον τρόπον τούτοις ἐκπορνεύσασαι, καὶ ἀπελθοῦσαι ὀπίσω σαρκὸς ἑτέρας, πρόκεινται δεῖγμα πυρὸς αἰωνίου δίκην ὑπέχουσαι.
65001008 Ὁμοίως μέντοι καὶ οὗτοι ἐνυπνιαζόμενοι, σάρκα μὲν μιαίνουσιν, κυριότητα δὲ ἀθετοῦσιν, δόξας δὲ βλασφημοῦσιν.
65001009 Ὁ δὲ Μιχαὴλ ὁ ἀρχάγγελος, ὅτε τῷ διαβόλῳ διακρινόμενος, διελέγετο περὶ τοῦ Μωϋσέως σώματος, οὐκ ἐτόλμησεν κρίσιν ἐπενεγκεῖν βλασφημίας, ἀλλὰ εἶπεν, “Ἐπιτιμήσαι σοι ˚Κύριος.”
65001010 Οὗτοι δὲ ὅσα μὲν οὐκ οἴδασιν βλασφημοῦσιν, ὅσα δὲ φυσικῶς ὡς τὰ ἄλογα ζῷα ἐπίστανται, ἐν τούτοις φθείρονται.
65001011 Οὐαὶ αὐτοῖς! Ὅτι τῇ ὁδῷ τοῦ Κάϊν ἐπορεύθησαν, καὶ τῇ πλάνῃ τοῦ Βαλαὰμ μισθοῦ ἐξεχύθησαν, καὶ τῇ ἀντιλογίᾳ τοῦ Κόρε ἀπώλοντο.
65001012 Οὗτοί εἰσιν οἱ ἐν ταῖς ἀγάπαις ὑμῶν, σπιλάδες συνευωχούμενοι ἀφόβως, ἑαυτοὺς ποιμαίνοντες, νεφέλαι ἄνυδροι ὑπὸ ἀνέμων παραφερόμεναι, δένδρα φθινοπωρινὰ ἄκαρπα δὶς ἀποθανόντα ἐκριζωθέντα,
65001013 κύματα ἄγρια θαλάσσης ἐπαφρίζοντα τὰς ἑαυτῶν αἰσχύνας, ἀστέρες πλανῆται, οἷς ὁ ζόφος τοῦ σκότους εἰς αἰῶνα τετήρηται.
65001014 Ἐπροφήτευσεν δὲ καὶ τούτοις ἕβδομος ἀπὸ Ἀδὰμ, Ἑνὼχ, λέγων, “Ἰδοὺ, ἦλθεν ˚Κύριος ἐν ἁγίαις μυριάσιν αὐτοῦ,
65001015 ποιῆσαι κρίσιν κατὰ πάντων, καὶ ἐλέγξαι πάντας τοὺς ἀσεβεῖς περὶ πάντων τῶν ἔργων ἀσεβείας αὐτῶν, ὧν ἠσέβησαν, καὶ περὶ πάντων τῶν σκληρῶν, ὧν ἐλάλησαν κατʼ αὐτοῦ ἁμαρτωλοὶ ἀσεβεῖς.”
65001016 Οὗτοί εἰσιν γογγυσταί μεμψίμοιροι, κατὰ τὰς ἐπιθυμίας αὐτῶν πορευόμενοι, καὶ τὸ στόμα αὐτῶν λαλεῖ ὑπέρογκα, θαυμάζοντες πρόσωπα ὠφελείας χάριν.
65001017 Ὑμεῖς δέ, ἀγαπητοί, μνήσθητε τῶν ῥημάτων τῶν προειρημένων ὑπὸ τῶν ἀποστόλων τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ ˚Χριστοῦ,
65001018 ὅτι ἔλεγον ὑμῖν, “Ἐπʼ ἐσχάτου χρόνου ἔσονται ἐμπαῖκται κατὰ τὰς ἑαυτῶν ἐπιθυμίας πορευόμενοι τῶν ἀσεβειῶν.”
65001019 Οὗτοί εἰσιν οἱ ἀποδιορίζοντες, ψυχικοί, ˚Πνεῦμα μὴ ἔχοντες.
65001020 Ὑμεῖς δέ, ἀγαπητοί, ἐποικοδομοῦντες ἑαυτοὺς τῇ ἁγιωτάτῃ ὑμῶν πίστει, ἐν ˚Πνεύματι Ἁγίῳ προσευχόμενοι.
65001021 Ἑαυτοὺς ἐν ἀγάπῃ ˚Θεοῦ τηρήσατε, προσδεχόμενοι τὸ ἔλεος τοῦ ˚Κυρίου ἡμῶν, ˚Ἰησοῦ ˚Χριστοῦ, εἰς ζωὴν αἰώνιον.
65001022 Καὶ οὓς μὲν ἐλεᾶτε διακρινομένους,
65001023 οὓς δὲ σῴζετε ἐκ πυρὸς ἁρπάζοντες, οὓς δὲ ἐλεᾶτε ἐν φόβῳ, μισοῦντες καὶ τὸν ἀπὸ τῆς σαρκὸς ἐσπιλωμένον χιτῶνα.
65001024 Τῷ δὲ δυναμένῳ φυλάξαι ὑμᾶς ἀπταίστους, καὶ στῆσαι κατενώπιον τῆς δόξης αὐτοῦ ἀμώμους ἐν ἀγαλλιάσει,
65001025 μόνῳ ˚Θεῷ Σωτῆρι ἡμῶν, διὰ ˚Ἰησοῦ ˚Χριστοῦ τοῦ ˚Κυρίου ἡμῶν, δόξα, μεγαλωσύνη, κράτος, καὶ ἐξουσία, πρὸ παντὸς τοῦ αἰῶνος, καὶ νῦν, καὶ εἰς πάντας τοὺς αἰῶνας. Ἀμήν!
66001001 Ἀποκάλυψις ˚Ἰησοῦ ˚Χριστοῦ, ἣν ἔδωκεν αὐτῷ ὁ ˚Θεὸς, δεῖξαι τοῖς δούλοις αὐτοῦ, ἃ δεῖ γενέσθαι ἐν τάχει, καὶ ἐσήμανεν, ἀποστείλας διὰ τοῦ ἀγγέλου αὐτοῦ τῷ δούλῳ αὐτοῦ, Ἰωάννῃ,
66001002 ὃς ἐμαρτύρησεν τὸν λόγον τοῦ ˚Θεοῦ, καὶ τὴν μαρτυρίαν ˚Ἰησοῦ ˚Χριστοῦ, ὅσα εἶδεν.
66001003 Μακάριος ὁ ἀναγινώσκων καὶ οἱ ἀκούοντες τοὺς λόγους τῆς προφητείας, καὶ τηροῦντες τὰ ἐν αὐτῇ γεγραμμένα, ὁ γὰρ καιρὸς ἐγγύς.
66001004 Ἰωάννης ταῖς ἑπτὰ ἐκκλησίαις ταῖς ἐν τῇ Ἀσίᾳ: χάρις ὑμῖν καὶ εἰρήνη, ἀπὸ ὁ ὢν, καὶ ὁ ἦν, καὶ ὁ ἐρχόμενος, καὶ ἀπὸ τῶν ἑπτὰ πνευμάτων, ἃ ἐνώπιον τοῦ θρόνου αὐτοῦ,
66001005 καὶ ἀπὸ ˚Ἰησοῦ ˚Χριστοῦ, ὁ μάρτυς ὁ πιστός, ὁ πρωτότοκος τῶν νεκρῶν, καὶ ὁ ἄρχων τῶν βασιλέων τῆς γῆς. Τῷ ἀγαπῶντι ἡμᾶς, καὶ λύσαντι ἡμᾶς ἐκ τῶν ἁμαρτιῶν ἡμῶν ἐν τῷ αἵματι αὐτοῦ,
66001006 καὶ ἐποίησεν ἡμᾶς βασιλείαν, ἱερεῖς τῷ ˚Θεῷ καὶ Πατρὶ αὐτοῦ– αὐτῷ ἡ δόξα καὶ τὸ κράτος εἰς τοὺς αἰῶνας. Ἀμήν!
66001007 “Ἰδοὺ, ἔρχεται μετὰ τῶν νεφελῶν”, καὶ ὄψεται αὐτὸν πᾶς ὀφθαλμὸς, καὶ οἵτινες αὐτὸν ἐξεκέντησαν, καὶ κόψονται ἐπʼ αὐτὸν πᾶσαι αἱ φυλαὶ τῆς γῆς. Ναί! Ἀμήν!
66001008 “Ἐγώ εἰμι τὸ Ἄλφα καὶ τὸ Ὦ”, λέγει ˚Κύριος, ὁ ˚Θεός, “ὁ ὢν, καὶ ὁ ἦν, καὶ ὁ ἐρχόμενος, ὁ Παντοκράτωρ.”
66001009 Ἐγὼ Ἰωάννης, ὁ ἀδελφὸς ὑμῶν, καὶ συγκοινωνὸς ἐν τῇ θλίψει, καὶ βασιλείᾳ, καὶ ὑπομονῇ, ἐν ˚Ἰησοῦ, ἐγενόμην ἐν τῇ νήσῳ τῇ καλουμένῃ Πάτμῳ, διὰ τὸν λόγον τοῦ ˚Θεοῦ καὶ τὴν μαρτυρίαν ˚Ἰησοῦ.
66001010 Ἐγενόμην ἐν ˚Πνεύματι ἐν τῇ Κυριακῇ ἡμέρᾳ, καὶ ἤκουσα ὀπίσω μου φωνὴν μεγάλην ὡς σάλπιγγος
66001011 λεγούσης, “Ὃ βλέπεις, γράψον εἰς βιβλίον, καὶ πέμψον ταῖς ἑπτὰ ἐκκλησίαις: εἰς Ἔφεσον, καὶ εἰς Σμύρναν, καὶ εἰς Πέργαμον, καὶ εἰς Θυάτειρα, καὶ εἰς Σάρδεις, καὶ εἰς Φιλαδέλφειαν, καὶ εἰς Λαοδίκειαν.”
66001012 Καὶ ἐπέστρεψα βλέπειν τὴν φωνὴν ἥτις ἐλάλει μετʼ ἐμοῦ. Καὶ ἐπιστρέψας, εἶδον ἑπτὰ λυχνίας χρυσᾶς,
66001013 καὶ ἐν μέσῳ τῶν λυχνιῶν, “ὅμοιον υἱὸν ἀνθρώπου”, ἐνδεδυμένον ποδήρη, καὶ περιεζωσμένον πρὸς τοῖς μαστοῖς ζώνην χρυσᾶν.
66001014 Ἡ δὲ κεφαλὴ αὐτοῦ καὶ αἱ τρίχες λευκαὶ, ὡς ἔριον λευκόν ὡς χιών, καὶ οἱ ὀφθαλμοὶ αὐτοῦ ὡς φλὸξ πυρός,
66001015 καὶ οἱ πόδες αὐτοῦ ὅμοιοι χαλκολιβάνῳ ὡς ἐν καμίνῳ πεπυρωμένης, καὶ ἡ φωνὴ αὐτοῦ ὡς φωνὴ ὑδάτων πολλῶν,
66001016 καὶ ἔχων ἐν τῇ δεξιᾷ χειρὶ αὐτοῦ ἀστέρας ἑπτά, καὶ ἐκ τοῦ στόματος αὐτοῦ ῥομφαία δίστομος ὀξεῖα ἐκπορευομένη, καὶ ἡ ὄψις αὐτοῦ ὡς ὁ ἥλιος φαίνει ἐν τῇ δυνάμει αὐτοῦ.
66001017 Καὶ ὅτε εἶδον αὐτόν, ἔπεσα πρὸς τοὺς πόδας αὐτοῦ, ὡς νεκρός. Καὶ ἔθηκεν τὴν δεξιὰν αὐτοῦ ἐπʼ ἐμὲ λέγων, “Μὴ φοβοῦ· ἐγώ εἰμι ὁ πρῶτος καὶ ὁ ἔσχατος,
66001018 καὶ ὁ ζῶν καὶ ἐγενόμην νεκρὸς, καὶ ἰδοὺ, ζῶν εἰμι εἰς τοὺς αἰῶνας τῶν αἰώνων, καὶ ἔχω τὰς κλεῖς τοῦ θανάτου καὶ τοῦ ᾅδου.
66001019 Γράψον οὖν ἃ εἶδες, καὶ ἃ εἰσὶν, καὶ ἃ μέλλει γενέσθαι μετὰ ταῦτα.
66001020 Τὸ μυστήριον τῶν ἑπτὰ ἀστέρων, οὓς εἶδες ἐπὶ τῆς δεξιᾶς μου, καὶ τὰς ἑπτὰ λυχνίας τὰς χρυσᾶς: οἱ ἑπτὰ ἀστέρες, ἄγγελοι τῶν ἑπτὰ ἐκκλησιῶν εἰσίν, καὶ αἱ λυχνίαι ἑπτὰ, ἑπτὰ ἐκκλησίαι εἰσίν.
66002001 Τῷ ἀγγέλῳ τῷ ἐν Ἐφέσῳ ἐκκλησίας γράψον: ‘Τάδε λέγει ὁ κρατῶν τοὺς ἑπτὰ ἀστέρας ἐν τῇ δεξιᾷ αὐτοῦ, ὁ περιπατῶν ἐν μέσῳ τῶν ἑπτὰ λυχνιῶν τῶν χρυσῶν:
66002002 “Οἶδα τὰ ἔργα σου, καὶ τὸν κόπον καὶ τὴν ὑπομονήν σου, καὶ ὅτι οὐ δύνῃ βαστάσαι κακούς, καὶ ἐπείρασας τοὺς λέγοντας ἑαυτοὺς ἀποστόλους, καὶ οὐκ εἰσίν, καὶ εὗρες αὐτοὺς ψευδεῖς,
66002003 καὶ ὑπομονὴν ἔχεις, καὶ ἐβάστασας διὰ τὸ ὄνομά μου, καὶ οὐ κεκοπίακες.
66002004 Ἀλλʼ ἔχω κατὰ σοῦ ὅτι τὴν ἀγάπην σου τὴν πρώτην ἀφῆκες.
66002005 Μνημόνευε οὖν πόθεν πέπτωκας καὶ μετανόησον, καὶ τὰ πρῶτα ἔργα ποίησον. Εἰ δὲ μή, ἔρχομαί σοι καὶ κινήσω τὴν λυχνίαν σου ἐκ τοῦ τόπου αὐτῆς, ἐὰν μὴ μετανοήσῃς.
66002006 Ἀλλὰ τοῦτο ἔχεις, ὅτι μισεῖς τὰ ἔργα τῶν Νικολαϊτῶν, ἃ κἀγὼ μισῶ.
66002007 Ὁ ἔχων οὖς, ἀκουσάτω τί τὸ ˚Πνεῦμα λέγει ταῖς ἐκκλησίαις. Τῷ νικῶντι, δώσω αὐτῷ φαγεῖν ἐκ τοῦ ξύλου τῆς ζωῆς, ὅ ἐστιν ἐν τῷ Παραδείσῳ τοῦ ˚Θεοῦ.”’
66002008 Καὶ τῷ ἀγγέλῳ τῆς ἐν Σμύρνῃ ἐκκλησίας γράψον: ‘Τάδε λέγει ὁ πρῶτος καὶ ὁ ἔσχατος, ὃς ἐγένετο νεκρὸς καὶ ἔζησεν:
66002009 “Οἶδά σου τὴν θλῖψιν καὶ τὴν πτωχείαν ( ἀλλὰ πλούσιος εἶ ), καὶ τὴν βλασφημίαν ἐκ τῶν λεγόντων Ἰουδαίους εἶναι ἑαυτούς, καὶ οὐκ εἰσίν, ἀλλὰ συναγωγὴ τοῦ Σατανᾶ.
66002010 Μὴ φοβοῦ ἃ μέλλεις πάσχειν. Ἰδοὺ, μέλλει βάλλειν ὁ διάβολος ἐξ ὑμῶν εἰς φυλακὴν, ἵνα πειρασθῆτε, καὶ ἕξετε θλῖψιν ἡμερῶν δέκα. Γίνου πιστὸς ἄχρι θανάτου, καὶ δώσω σοι τὸν στέφανον τῆς ζωῆς.
66002011 Ὁ ἔχων οὖς, ἀκουσάτω τί τὸ ˚Πνεῦμα λέγει ταῖς ἐκκλησίαις. Ὁ νικῶν οὐ μὴ ἀδικηθῇ ἐκ τοῦ θανάτου τοῦ δευτέρου.”’
66002012 Καὶ τῷ ἀγγέλῳ τῆς ἐν Περγάμῳ ἐκκλησίας γράψον: ‘Τάδε λέγει ὁ ἔχων τὴν ῥομφαίαν τὴν δίστομον τὴν ὀξεῖαν:
66002013 “Οἶδα ποῦ κατοικεῖς, ὅπου ὁ θρόνος τοῦ Σατανᾶ, καὶ κρατεῖς τὸ ὄνομά μου, καὶ οὐκ ἠρνήσω τὴν πίστιν μου, καὶ ἐν ταῖς ἡμέραις Ἀντιπᾶς, ὁ μάρτυς μου ὁ πιστός μου, ὃς ἀπεκτάνθη παρʼ ὑμῖν, ὅπου ὁ Σατανᾶς κατοικεῖ.
66002014 Ἀλλʼ ἔχω κατὰ σοῦ ὀλίγα, ὅτι ἔχεις ἐκεῖ κρατοῦντας τὴν διδαχὴν Βαλαάμ, ὃς ἐδίδασκεν τῷ Βαλὰκ βαλεῖν σκάνδαλον ἐνώπιον τῶν υἱῶν Ἰσραήλ, φαγεῖν εἰδωλόθυτα καὶ πορνεῦσαι.
66002015 Οὕτως ἔχεις καὶ σὺ, κρατοῦντας τὴν διδαχὴν Νικολαϊτῶν ὁμοίως.
66002016 Μετανόησον οὖν! Εἰ δὲ μή, ἔρχομαί σοι ταχύ, καὶ πολεμήσω μετʼ αὐτῶν ἐν τῇ ῥομφαίᾳ τοῦ στόματός μου.
66002017 Ὁ ἔχων οὖς, ἀκουσάτω τί τὸ ˚Πνεῦμα λέγει ταῖς ἐκκλησίαις. Τῷ νικῶντι, δώσω αὐτῷ τοῦ μάννα τοῦ κεκρυμμένου, καὶ δώσω αὐτῷ ψῆφον λευκήν, καὶ ἐπὶ τὴν ψῆφον ὄνομα καινὸν γεγραμμένον, ὃ οὐδεὶς οἶδεν, εἰ μὴ ὁ λαμβάνων.”’
66002018 Καὶ τῷ ἀγγέλῳ τῆς ἐν Θυατείροις ἐκκλησίας γράψον: ‘Τάδε λέγει ὁ Υἱὸς τοῦ ˚Θεοῦ, ὁ ἔχων τοὺς ὀφθαλμοὺς αὐτοῦ ὡς φλόγα πυρός, καὶ οἱ πόδες αὐτοῦ ὅμοιοι χαλκολιβάνῳ:
66002019 “Οἶδά σου τὰ ἔργα, καὶ τὴν ἀγάπην, καὶ τὴν πίστιν, καὶ τὴν διακονίαν, καὶ τὴν ὑπομονήν σου, καὶ τὰ ἔργα σου, τὰ ἔσχατα πλείονα τῶν πρώτων.
66002020 Ἀλλʼ ἔχω κατὰ σοῦ ὅτι ἀφεῖς τὴν γυναῖκα Ἰεζάβελ, ἡ λέγουσα ἑαυτὴν προφῆτιν, καὶ διδάσκει καὶ πλανᾷ τοὺς ἐμοὺς δούλους πορνεῦσαι, καὶ φαγεῖν εἰδωλόθυτα.
66002021 Καὶ ἔδωκα αὐτῇ χρόνον, ἵνα μετανοήσῃ, καὶ οὐ θέλει μετανοῆσαι ἐκ τῆς πορνείας αὐτῆς.
66002022 ἰδοὺ, βάλλω αὐτὴν εἰς κλίνην, καὶ τοὺς μοιχεύοντας μετʼ αὐτῆς εἰς θλῖψιν μεγάλην, ἐὰν μὴ μετανοήσουσιν ἐκ τῶν ἔργων αὐτῆς.
66002023 Καὶ τὰ τέκνα αὐτῆς ἀποκτενῶ ἐν θανάτῳ· καὶ γνώσονται πᾶσαι αἱ ἐκκλησίαι ὅτι ἐγώ εἰμι ὁ ἐραυνῶν νεφροὺς καὶ καρδίας, καὶ δώσω ὑμῖν ἑκάστῳ κατὰ τὰ ἔργα ὑμῶν.
66002024 Ὑμῖν δὲ λέγω τοῖς λοιποῖς τοῖς ἐν Θυατείροις, ὅσοι οὐκ ἔχουσιν τὴν διδαχὴν ταύτην, οἵτινες οὐκ ἔγνωσαν τὰ βαθέα τοῦ Σατανᾶ, ὡς λέγουσιν, οὐ βάλλω ἐφʼ ὑμᾶς ἄλλο βάρος.
66002025 Πλὴν ὃ ἔχετε, κρατήσατε ἄχρι οὗ ἂν ἥξω.
66002026 Καὶ ὁ νικῶν καὶ ὁ τηρῶν ἄχρι τέλους τὰ ἔργα μου, ‘δώσω αὐτῷ ἐξουσίαν ἐπὶ τῶν ἐθνῶν…
66002027 καὶ ποιμανεῖ αὐτοὺς ἐν ῥάβδῳ σιδηρᾷ, ὡς τὰ σκεύη τὰ κεραμικὰ συντρίβεται’,
66002028 ὡς κἀγὼ εἴληφα παρὰ τοῦ πατρός μου, καὶ δώσω αὐτῷ τὸν ἀστέρα τὸν πρωϊνόν.
66002029 Ὁ ἔχων οὖς, ἀκουσάτω τί τὸ ˚Πνεῦμα λέγει ταῖς ἐκκλησίαις.”’
66003001 Καὶ τῷ ἀγγέλῳ τῆς ἐν Σάρδεσιν ἐκκλησίας γράψον: ‘Τάδε λέγει ὁ ἔχων τὰ ἑπτὰ πνεύματα τοῦ ˚Θεοῦ καὶ τοὺς ἑπτὰ ἀστέρας: “Οἶδά σου τὰ ἔργα, ὅτι ὄνομα ἔχεις, ὅτι ζῇς, καὶ νεκρὸς εἶ.
66003002 Γίνου γρηγορῶν, καὶ στήρισον τὰ λοιπὰ, ἃ ἔμελλον ἀποθανεῖν, οὐ γὰρ εὕρηκά σου ἔργα, πεπληρωμένα ἐνώπιον τοῦ ˚Θεοῦ μου.
66003003 Μνημόνευε οὖν πῶς εἴληφας καὶ ἤκουσας, καὶ τήρει καὶ μετανόησον. Ἐὰν οὖν μὴ γρηγορήσῃς, ἥξω ὡς κλέπτης, καὶ οὐ μὴ γνῷς ποίαν ὥραν ἥξω ἐπὶ σέ.
66003004 Ἀλλὰ ἔχεις ὀλίγα ὀνόματα ἐν Σάρδεσιν, ἃ οὐκ ἐμόλυναν τὰ ἱμάτια αὐτῶν, καὶ περιπατήσουσιν μετʼ ἐμοῦ ἐν λευκοῖς, ὅτι ἄξιοί εἰσιν.
66003005 Ὁ νικῶν, οὕτως περιβαλεῖται ἐν ἱματίοις λευκοῖς, καὶ οὐ μὴ ἐξαλείψω τὸ ὄνομα αὐτοῦ ἐκ τῆς βίβλου τῆς ζωῆς, καὶ ὁμολογήσω τὸ ὄνομα αὐτοῦ ἐνώπιον τοῦ Πατρός μου, καὶ ἐνώπιον τῶν ἀγγέλων αὐτοῦ.
66003006 Ὁ ἔχων οὖς, ἀκουσάτω τί τὸ ˚Πνεῦμα λέγει ταῖς ἐκκλησίαις.”’
66003007 Καὶ τῷ ἀγγέλῳ τῆς ἐν Φιλαδελφείᾳ ἐκκλησίας γράψον: ‘Τάδε λέγει ὁ Ἅγιος, ὁ Ἀληθινός, ὁ ἔχων τὴν κλεῖν Δαυίδ, ὁ ἀνοίγων καὶ οὐδεὶς κλείσει, καὶ κλείων καὶ οὐδεὶς ἀνοίγει:
66003008 “Οἶδά σου τὰ ἔργα. Ἰδοὺ, δέδωκα ἐνώπιόν σου θύραν ἠνεῳγμένην, ἣν οὐδεὶς δύναται κλεῖσαι αὐτήν, ὅτι μικρὰν ἔχεις δύναμιν, καὶ ἐτήρησάς μου τὸν λόγον, καὶ οὐκ ἠρνήσω τὸ ὄνομά μου.
66003009 Ἰδοὺ, διδῶ ἐκ τῆς συναγωγῆς τοῦ Σατανᾶ, τῶν λεγόντων ἑαυτοὺς Ἰουδαίους εἶναι, καὶ οὐκ εἰσὶν, ἀλλὰ ψεύδονται· ἰδοὺ, ποιήσω αὐτοὺς ἵνα ἥξουσιν καὶ προσκυνήσουσιν ἐνώπιον τῶν ποδῶν σου, καὶ γνῶσιν ὅτι ἐγὼ ἠγάπησά σε.
66003010 Ὅτι ἐτήρησας τὸν λόγον τῆς ὑπομονῆς μου, κἀγώ σε τηρήσω ἐκ τῆς ὥρας τοῦ πειρασμοῦ τῆς μελλούσης ἔρχεσθαι ἐπὶ τῆς οἰκουμένης ὅλης, πειράσαι τοὺς κατοικοῦντας ἐπὶ τῆς γῆς.
66003011 Ἔρχομαι ταχύ· κράτει ὃ ἔχεις, ἵνα μηδεὶς λάβῃ τὸν στέφανόν σου.
66003012 Ὁ νικῶν, ποιήσω αὐτὸν στῦλον ἐν τῷ ναῷ τοῦ ˚Θεοῦ μου, καὶ ἔξω οὐ μὴ ἐξέλθῃ ἔτι, καὶ γράψω ἐπʼ αὐτὸν τὸ ὄνομα τοῦ ˚Θεοῦ μου, καὶ τὸ ὄνομα τῆς πόλεως τοῦ ˚Θεοῦ μου, τῆς καινῆς Ἰερουσαλήμ, ἡ καταβαίνουσα ἐκ τοῦ οὐρανοῦ ἀπὸ τοῦ ˚Θεοῦ μου, καὶ τὸ ὄνομά μου τὸ καινόν.
66003013 Ὁ ἔχων οὖς, ἀκουσάτω τί τὸ ˚Πνεῦμα λέγει ταῖς ἐκκλησίαις.”’
66003014 Καὶ τῷ ἀγγέλῳ τῆς ἐν Λαοδικείᾳ ἐκκλησίας γράψον: ‘Τάδε λέγει ὁ Ἀμήν, ὁ μάρτυς ὁ πιστὸς καὶ ὁ ἀληθινός, ἡ ἀρχὴ τῆς κτίσεως τοῦ ˚Θεοῦ:
66003015 “Οἶδά σου τὰ ἔργα, ὅτι οὔτε ψυχρὸς εἶ οὔτε ζεστός. Ὄφελος ψυχρὸς ἦς ἢ ζεστός.
66003016 Οὕτως, ὅτι χλιαρὸς εἶ, καὶ οὔτε ζεστὸς οὔτε ψυχρός, μέλλω σε ἐμέσαι ἐκ τοῦ στόματός μου.
66003017 Ὅτι λέγεις, ὅτι ‘Πλούσιός εἰμι, καὶ πεπλούτηκα, καὶ οὐδὲν χρείαν ἔχω’, καὶ οὐκ οἶδας ὅτι σὺ εἶ ὁ ταλαίπωρος, καὶ ἐλεεινὸς, καὶ πτωχὸς, καὶ τυφλὸς, καὶ γυμνός.
66003018 Συμβουλεύω σοι ἀγοράσαι παρʼ ἐμοῦ χρυσίον πεπυρωμένον ἐκ πυρὸς, ἵνα πλουτήσῃς, καὶ ἱμάτια λευκὰ, ἵνα περιβάλῃ, καὶ μὴ φανερωθῇ ἡ αἰσχύνη τῆς γυμνότητός σου, καὶ κολλούριον ἐγχρῖσαι τοὺς ὀφθαλμούς σου, ἵνα βλέπῃς.
66003019 Ἐγὼ ὅσους ἐὰν φιλῶ, ἐλέγχω καὶ παιδεύω· ζήλευε οὖν καὶ μετανόησον.
66003020 Ἰδοὺ, ἕστηκα ἐπὶ τὴν θύραν καὶ κρούω· ἐάν τις ἀκούσῃ τῆς φωνῆς μου καὶ ἀνοίξῃ τὴν θύραν, εἰσελεύσομαι πρὸς αὐτὸν, καὶ δειπνήσω μετʼ αὐτοῦ, καὶ αὐτὸς μετʼ ἐμοῦ.
66003021 Ὁ νικῶν, δώσω αὐτῷ καθίσαι μετʼ ἐμοῦ ἐν τῷ θρόνῳ μου, ὡς κἀγὼ ἐνίκησα, καὶ ἐκάθισα μετὰ τοῦ Πατρός μου ἐν τῷ θρόνῳ αὐτοῦ.
66003022 Ὁ ἔχων οὖς, ἀκουσάτω τί τὸ ˚Πνεῦμα λέγει ταῖς ἐκκλησίαις.”’”
66004001 Μετὰ ταῦτα εἶδον, καὶ ἰδοὺ, θύρα ἠνεῳγμένη ἐν τῷ οὐρανῷ, καὶ ἡ φωνὴ ἡ πρώτη ἣν ἤκουσα ὡς σάλπιγγος λαλούσης μετʼ ἐμοῦ λέγων, “Ἀνάβα ὧδε, καὶ δείξω σοι ἃ δεῖ γενέσθαι μετὰ ταῦτα.”
66004002 Εὐθέως ἐγενόμην ἐν ˚Πνεύματι, καὶ ἰδοὺ, θρόνος ἔκειτο ἐν τῷ οὐρανῷ, καὶ ἐπὶ τὸν θρόνον καθήμενος,
66004003 καὶ ὁ καθήμενος ὅμοιος ὁράσει λίθῳ, ἰάσπιδι καὶ σαρδίῳ, καὶ ἶρις κυκλόθεν τοῦ θρόνου ὅμοιος ὁράσει σμαραγδίνῳ.
66004004 Καὶ κυκλόθεν τοῦ θρόνου θρόνους εἴκοσι τέσσαρες, καὶ ἐπὶ τοὺς εἴκοσι τέσσαρας θρόνους πρεσβυτέρους καθημένους, περιβεβλημένους ἱματίοις λευκοῖς, καὶ ἐπὶ τὰς κεφαλὰς αὐτῶν στεφάνους χρυσοῦς.
66004005 Καὶ ἐκ τοῦ θρόνου ἐκπορεύονται ἀστραπαὶ, καὶ φωναὶ, καὶ βρονταί. Καὶ ἑπτὰ λαμπάδες πυρὸς καιόμεναι ἐνώπιον τοῦ θρόνου, ἅ εἰσιν τὰ ἑπτὰ πνεύματα τοῦ ˚Θεοῦ·
66004006 καὶ ἐνώπιον τοῦ θρόνου ὡς θάλασσα ὑαλίνη, ὁμοία κρυστάλλῳ. Καὶ ἐν μέσῳ τοῦ θρόνου καὶ κύκλῳ τοῦ θρόνου, τέσσαρα ζῷα, γέμοντα ὀφθαλμῶν ἔμπροσθεν καὶ ὄπισθεν.
66004007 Καὶ τὸ ζῷον τὸ πρῶτον ὅμοιον λέοντι, καὶ τὸ δεύτερον ζῷον ὅμοιον μόσχῳ, καὶ τὸ τρίτον ζῷον ἔχον τὸ πρόσωπον ὡς ἀνθρώπου, καὶ τὸ τέταρτον ζῷον ὅμοιον ἀετῷ πετομένῳ.
66004008 Καὶ τὰ τέσσαρα ζῷα, ἓν καθʼ ἓν αὐτῶν, ἔχων ἀνὰ πτέρυγας ἕξ, κυκλόθεν καὶ ἔσωθεν γέμουσιν ὀφθαλμῶν, καὶ ἀνάπαυσιν οὐκ ἔχουσιν ἡμέρας καὶ νυκτὸς λέγοντες, “‘Ἅγιος, Ἅγιος, Ἅγιος, ˚Κύριος ὁ ˚Θεός ὁ Παντοκράτωρ’, ὁ ἦν, καὶ ὁ ὢν, καὶ ὁ ἐρχόμενος.”
66004009 Καὶ ὅταν δώσουσιν τὰ ζῷα δόξαν, καὶ τιμὴν, καὶ εὐχαριστίαν, τῷ καθημένῳ ἐπὶ τῷ θρόνῳ, τῷ ζῶντι εἰς τοὺς αἰῶνας τῶν αἰώνων,
66004010 πεσοῦνται οἱ εἴκοσι τέσσαρες πρεσβύτεροι ἐνώπιον τοῦ καθημένου ἐπὶ τοῦ θρόνου, καὶ προσκυνήσουσιν τῷ ζῶντι εἰς τοὺς αἰῶνας τῶν αἰώνων, καὶ βαλοῦσιν τοὺς στεφάνους αὐτῶν ἐνώπιον τοῦ θρόνου λέγοντες,
66004011 “Ἄξιος εἶ, ὁ ˚Κύριος καὶ ὁ ˚Θεὸς ἡμῶν, λαβεῖν τὴν δόξαν καὶ τὴν τιμὴν καὶ τὴν δύναμιν, ὅτι σὺ ἔκτισας τὰ πάντα, καὶ διὰ τὸ θέλημά σου ἦσαν, καὶ ἐκτίσθησαν.”
66005001 Καὶ εἶδον ἐπὶ τὴν δεξιὰν τοῦ καθημένου ἐπὶ τοῦ θρόνου βιβλίον, γεγραμμένον ἔσωθεν καὶ ὄπισθεν, κατεσφραγισμένον σφραγῖσιν ἑπτά.
66005002 Καὶ εἶδον ἄγγελον ἰσχυρὸν, κηρύσσοντα ἐν φωνῇ μεγάλῃ, “Τίς ἄξιος ἀνοῖξαι τὸ βιβλίον, καὶ λῦσαι τὰς σφραγῖδας αὐτοῦ;”
66005003 Καὶ οὐδεὶς ἐδύνατο ἐν τῷ οὐρανῷ, οὐδὲ ἐπὶ τῆς γῆς, οὐδὲ ὑποκάτω τῆς γῆς, ἀνοῖξαι τὸ βιβλίον, οὔτε βλέπειν αὐτό.
66005004 Καὶ ἔκλαιον πολὺ, ὅτι οὐδεὶς ἄξιος εὑρεθήσεται ἀνοῖξαι τὸ βιβλίον, οὔτε βλέπειν αὐτό.
66005005 Καὶ εἷς ἐκ τῶν πρεσβυτέρων λέγει μοι, “Μὴ κλαῖε. Ἰδοὺ, ἐνίκησεν ὁ λέων ὁ ἐκ τῆς φυλῆς Ἰούδα, ἡ ῥίζα Δαυίδ, ἀνοῖξαι τὸ βιβλίον, καὶ τὰς ἑπτὰ σφραγῖδας αὐτοῦ.”
66005006 Καὶ εἶδον ἐν μέσῳ τοῦ θρόνου καὶ τῶν τεσσάρων ζῴων καὶ ἐν μέσῳ τῶν πρεσβυτέρων, Ἀρνίον ἑστηκὸς ὡς ἐσφαγμένον, ἔχων κέρατα ἑπτὰ καὶ ὀφθαλμοὺς ἑπτά, οἵ εἰσιν τὰ ἑπτὰ πνεύματα τοῦ ˚Θεοῦ, ἀπεσταλμένοι εἰς πᾶσαν τὴν γῆν.
66005007 Καὶ ἦλθεν καὶ εἴληφεν ἐκ τῆς δεξιᾶς τοῦ καθημένου ἐπὶ τοῦ θρόνου.
66005008 Καὶ ὅτε ἔλαβεν τὸ βιβλίον, τὰ τέσσαρα ζῷα καὶ οἱ εἴκοσι τέσσαρες πρεσβύτεροι ἔπεσαν ἐνώπιον τοῦ Ἀρνίου, ἔχοντες ἕκαστος κιθάραν καὶ φιάλας χρυσᾶς γεμούσας θυμιαμάτων, αἵ εἰσιν αἱ προσευχαὶ τῶν ἁγίων.
66005009 Καὶ ᾄδουσιν ᾠδὴν καινὴν λέγοντες, “Ἄξιος εἶ λαβεῖν τὸ βιβλίον, καὶ ἀνοῖξαι τὰς σφραγῖδας αὐτοῦ, ὅτι ἐσφάγης, καὶ ἠγόρασας τῷ ˚Θεῷ ἡμᾶς ἐν τῷ αἵματί σου, ἐκ πάσης φυλῆς, καὶ γλώσσης, καὶ λαοῦ, καὶ ἔθνους,
66005010 καὶ ἐποίησας αὐτοὺς τῷ ˚Θεῷ ἡμῶν, βασιλείαν καὶ ἱερεῖς, καὶ βασιλεύσουσιν ἐπὶ τῆς γῆς.”
66005011 Καὶ εἶδον, καὶ ἤκουσα φωνὴν ἀγγέλων πολλῶν κύκλῳ τοῦ θρόνου, καὶ τῶν ζῴων, καὶ τῶν πρεσβυτέρων, καὶ ἦν ὁ ἀριθμὸς αὐτῶν μυριάδες μυριάδων καὶ χιλιάδες χιλιάδων,
66005012 λέγοντες φωνῇ μεγάλῃ, “Ἄξιόν ἐστιν τὸ Ἀρνίον τὸ ἐσφαγμένον, λαβεῖν τὴν δύναμιν, καὶ πλοῦτον, καὶ σοφίαν, καὶ ἰσχὺν, καὶ τιμὴν, καὶ δόξαν, καὶ εὐλογίαν!”
66005013 Καὶ πᾶν κτίσμα ὃ ἐν τῷ οὐρανῷ, καὶ ἐπὶ τῆς γῆς, καὶ ὑποκάτω τῆς γῆς, καὶ ἐπὶ τῆς θαλάσσης, καὶ τὰ ἐν αὐτοῖς πάντα, ἤκουσα λέγοντας, “Τῷ καθημένῳ ἐπὶ τοῦ θρόνου καὶ τῷ Ἀρνίῳ ἡ εὐλογία, καὶ ἡ τιμὴ, καὶ ἡ δόξα, καὶ τὸ κράτος, εἰς τοὺς αἰῶνας τῶν αἰώνων.”
66005014 Καὶ τὰ τέσσαρα ζῷα ἔλεγον, “Ἀμήν!” Καὶ οἱ πρεσβύτεροι ἔπεσαν καὶ προσεκύνησαν.
66006001 Καὶ εἶδον, ὅτε ἤνοιξεν τὸ Ἀρνίον μίαν ἐκ τῶν ἑπτὰ σφραγίδων, καὶ ἤκουσα ἑνὸς ἐκ τῶν τεσσάρων ζῴων, λέγοντος ὡς φωνῇ βροντῆς, “Ἔρχου!”
66006002 Καὶ εἶδον, καὶ ἰδοὺ, ἵππος λευκός, καὶ ὁ καθήμενος ἐπʼ αὐτὸν ἔχων τόξον, καὶ ἐδόθη αὐτῷ στέφανος, καὶ ἐξῆλθεν νικῶν, καὶ ἵνα νικήσῃ.
66006003 Καὶ ὅτε ἤνοιξεν τὴν σφραγῖδα τὴν δευτέραν, ἤκουσα τοῦ δευτέρου ζῴου λέγοντος, “Ἔρχου!”
66006004 Καὶ ἐξῆλθεν ἄλλος ἵππος πυρρός, καὶ τῷ καθημένῳ ἐπʼ αὐτὸν, ἐδόθη αὐτῷ λαβεῖν τὴν εἰρήνην ἐκ τῆς γῆς, καὶ ἵνα ἀλλήλους σφάξουσιν, καὶ ἐδόθη αὐτῷ μάχαιρα μεγάλη.
66006005 Καὶ ὅτε ἤνοιξεν τὴν σφραγῖδα τὴν τρίτην, ἤκουσα τοῦ τρίτου ζῴου λέγοντος, “Ἔρχου.” Καὶ εἶδον, καὶ ἰδοὺ, ἵππος μέλας, καὶ ὁ καθήμενος ἐπʼ αὐτὸν, ἔχων ζυγὸν ἐν τῇ χειρὶ αὐτοῦ.
66006006 Καὶ ἤκουσα ὡς φωνὴν ἐν μέσῳ τῶν τεσσάρων ζῴων λέγουσαν, “Χοῖνιξ σίτου δηναρίου, καὶ τρεῖς χοίνικες κριθῶν δηναρίου, καὶ τὸ ἔλαιον καὶ τὸν οἶνον μὴ ἀδικήσῃς.”
66006007 Καὶ ὅτε ἤνοιξεν τὴν σφραγῖδα τὴν τετάρτην, ἤκουσα φωνὴν τοῦ τετάρτου ζῴου λέγοντος, “Ἔρχου!”
66006008 Καὶ εἶδον, καὶ ἰδοὺ, ἵππος χλωρός, καὶ ὁ καθήμενος ἐπάνω αὐτοῦ, ὄνομα αὐτῷ Θάνατος, καὶ ὁ ᾅδης ἠκολούθει μετʼ αὐτοῦ, καὶ ἐδόθη αὐτοῖς ἐξουσία ἐπὶ τὸ τέταρτον τῆς γῆς, ἀποκτεῖναι ἐν ῥομφαίᾳ, καὶ ἐν λιμῷ, καὶ ἐν θανάτῳ, καὶ ὑπὸ τῶν θηρίων τῆς γῆς.
66006009 Καὶ ὅτε ἤνοιξεν τὴν πέμπτην σφραγῖδα, εἶδον ὑποκάτω τοῦ θυσιαστηρίου, τὰς ψυχὰς τῶν ἐσφαγμένων διὰ τὸν λόγον τοῦ ˚Θεοῦ, καὶ διὰ τὴν μαρτυρίαν ἣν εἶχον.
66006010 Καὶ ἔκραξαν φωνῇ μεγάλῃ λέγοντες, “Ἕως πότε ὁ Δεσπότης ὁ ἅγιος καὶ ἀληθινός, οὐ κρίνεις καὶ ἐκδικεῖς τὸ αἷμα ἡμῶν, ἐκ τῶν κατοικούντων ἐπὶ τῆς γῆς;”
66006011 Καὶ ἐδόθη αὐτοῖς ἑκάστῳ στολὴ λευκή, καὶ ἐρρέθη αὐτοῖς ἵνα ἀναπαύσονται ἔτι χρόνον μικρόν, ἕως πληρωθῶσιν καὶ οἱ σύνδουλοι αὐτῶν, καὶ οἱ ἀδελφοὶ αὐτῶν, οἱ μέλλοντες ἀποκτέννεσθαι ὡς καὶ αὐτοί.
66006012 Καὶ εἶδον ὅτε ἤνοιξεν τὴν σφραγῖδα τὴν ἕκτην, καὶ σεισμὸς μέγας ἐγένετο, καὶ ὁ ἥλιος ἐγένετο μέλας ὡς σάκκος τρίχινος, καὶ ἡ σελήνη ὅλη ἐγένετο ὡς αἷμα,
66006013 καὶ οἱ ἀστέρες τοῦ οὐρανοῦ ἔπεσαν εἰς τὴν γῆν, ὡς συκῆ βάλλει τοὺς ὀλύνθους αὐτῆς, ὑπὸ ἀνέμου μεγάλου σειομένη.
66006014 Καὶ ὁ οὐρανὸς ἀπεχωρίσθη ὡς βιβλίον ἑλισσόμενον, καὶ πᾶν ὄρος καὶ νῆσος ἐκ τῶν τόπων αὐτῶν ἐκινήθησαν.
66006015 Καὶ οἱ βασιλεῖς τῆς γῆς, καὶ οἱ μεγιστᾶνες, καὶ οἱ χιλίαρχοι, καὶ οἱ πλούσιοι, καὶ οἱ ἰσχυροὶ, καὶ πᾶς δοῦλος καὶ ἐλεύθερος, ἔκρυψαν ἑαυτοὺς εἰς τὰ σπήλαια καὶ εἰς τὰς πέτρας τῶν ὀρέων,
66006016 καὶ λέγουσιν τοῖς ὄρεσιν καὶ ταῖς πέτραις, “Πέσετε ἐφʼ ἡμᾶς, καὶ κρύψατε ἡμᾶς ἀπὸ προσώπου τοῦ καθημένου ἐπὶ τοῦ θρόνου, καὶ ἀπὸ τῆς ὀργῆς τοῦ Ἀρνίου,
66006017 ὅτι ἦλθεν ἡ ἡμέρα ἡ μεγάλη τῆς ὀργῆς αὐτῶν, καὶ τίς δύναται σταθῆναι;”
66007001 Μετὰ τοῦτο εἶδον τέσσαρας ἀγγέλους ἑστῶτας ἐπὶ τὰς τέσσαρας γωνίας τῆς γῆς, κρατοῦντας τοὺς τέσσαρας ἀνέμους τῆς γῆς, ἵνα μὴ πνέῃ ἄνεμος ἐπὶ τῆς γῆς, μήτε ἐπὶ τῆς θαλάσσης, μήτε ἐπὶ πᾶν δένδρον.
66007002 Καὶ εἶδον ἄλλον ἄγγελον, ἀναβαίνοντα ἀπὸ ἀνατολῆς ἡλίου, ἔχοντα σφραγῖδα ˚Θεοῦ ζῶντος, καὶ ἔκραξεν φωνῇ μεγάλῃ τοῖς τέσσαρσιν ἀγγέλοις οἷς ἐδόθη αὐτοῖς ἀδικῆσαι τὴν γῆν καὶ τὴν θάλασσαν
66007003 λέγων, “Μὴ ἀδικήσητε τὴν γῆν, μήτε τὴν θάλασσαν μήτε τὰ δένδρα, ἄχρι σφραγίσωμεν τοὺς δούλους τοῦ ˚Θεοῦ ἡμῶν ἐπὶ τῶν μετώπων αὐτῶν.”
66007004 Καὶ ἤκουσα τὸν ἀριθμὸν τῶν ἐσφραγισμένων, ἑκατὸν τεσσεράκοντα τέσσαρες χιλιάδες, ἐσφραγισμένοι ἐκ πάσης φυλῆς υἱῶν Ἰσραήλ:
66007005 ἐκ φυλῆς Ἰούδα δώδεκα χιλιάδες ἐσφραγισμένοι, ἐκ φυλῆς Ῥουβὴν δώδεκα χιλιάδες, ἐκ φυλῆς Γὰδ δώδεκα χιλιάδες,
66007006 ἐκ φυλῆς Ἀσὴρ δώδεκα χιλιάδες, ἐκ φυλῆς Νεφθαλὶμ δώδεκα χιλιάδες, ἐκ φυλῆς Μανασσῆ δώδεκα χιλιάδες,
66007007 ἐκ φυλῆς Συμεὼν δώδεκα χιλιάδες, ἐκ φυλῆς Λευὶ δώδεκα χιλιάδες, ἐκ φυλῆς Ἰσσαχὰρ δώδεκα χιλιάδες,
66007008 ἐκ φυλῆς Ζαβουλὼν δώδεκα χιλιάδες, ἐκ φυλῆς Ἰωσὴφ δώδεκα χιλιάδες, ἐκ φυλῆς Βενιαμὶν δώδεκα χιλιάδες ἐσφραγισμένοι.
66007009 Μετὰ ταῦτα εἶδον, καὶ ἰδοὺ, ὄχλος πολύς, ὃν ἀριθμῆσαι αὐτὸν οὐδεὶς ἐδύνατο, ἐκ παντὸς ἔθνους, καὶ φυλῶν, καὶ λαῶν, καὶ γλωσσῶν, ἑστῶτες ἐνώπιον τοῦ θρόνου καὶ ἐνώπιον τοῦ Ἀρνίου, περιβεβλημένους στολὰς λευκάς, καὶ φοίνικες ἐν ταῖς χερσὶν αὐτῶν·
66007010 καὶ κράζουσιν φωνῇ μεγάλῃ λέγοντες, “Ἡ σωτηρία τῷ ˚Θεῷ ἡμῶν, τῷ καθημένῳ ἐπὶ τῷ θρόνῳ καὶ τῷ Ἀρνίῳ!”
66007011 Καὶ πάντες οἱ ἄγγελοι εἱστήκεισαν κύκλῳ τοῦ θρόνου, καὶ τῶν πρεσβυτέρων, καὶ τῶν τεσσάρων ζῴων, καὶ ἔπεσαν ἐνώπιον τοῦ θρόνου ἐπὶ τὰ πρόσωπα αὐτῶν, καὶ προσεκύνησαν τῷ ˚Θεῷ
66007012 λέγοντες, “Ἀμήν! Ἡ εὐλογία, καὶ ἡ δόξα, καὶ ἡ σοφία, καὶ ἡ εὐχαριστία, καὶ ἡ τιμὴ, καὶ ἡ δύναμις, καὶ ἡ ἰσχὺς, τῷ ˚Θεῷ ἡμῶν εἰς τοὺς αἰῶνας τῶν αἰώνων. Ἀμήν!”
66007013 Καὶ ἀπεκρίθη εἷς ἐκ τῶν πρεσβυτέρων, λέγων μοι, “Οὗτοι, οἱ περιβεβλημένοι τὰς στολὰς τὰς λευκὰς, τίνες εἰσὶν καὶ πόθεν ἦλθον;”
66007014 Καὶ εἴρηκα αὐτῷ, “Κύριέ μου, σὺ οἶδας.” Καὶ εἶπέν μοι, “Οὗτοί εἰσιν οἱ ἐρχόμενοι ἐκ τῆς θλίψεως τῆς μεγάλης, καὶ ἔπλυναν τὰς στολὰς αὐτῶν, καὶ ἐλεύκαναν αὐτὰς ἐν τῷ αἵματι τοῦ Ἀρνίου.
66007015 Διὰ τοῦτό, εἰσιν ἐνώπιον τοῦ θρόνου τοῦ ˚Θεοῦ, καὶ λατρεύουσιν αὐτῷ ἡμέρας καὶ νυκτὸς ἐν τῷ ναῷ αὐτοῦ, καὶ ὁ καθήμενος ἐπὶ τοῦ θρόνου σκηνώσει ἐπʼ αὐτούς.
66007016 Οὐ πεινάσουσιν ἔτι, οὐδὲ διψήσουσιν ἔτι, οὐδὲ μὴ πέσῃ ἐπʼ αὐτοὺς ὁ ἥλιος, οὐδὲ πᾶν καῦμα,
66007017 ὅτι τὸ Ἀρνίον τὸ ἀνὰ μέσον τοῦ θρόνου ποιμανεῖ αὐτούς, καὶ ὁδηγήσει αὐτοὺς ἐπὶ ζωῆς πηγὰς ὑδάτων, καὶ ἐξαλείψει ὁ ˚Θεὸς πᾶν δάκρυον ἐκ τῶν ὀφθαλμῶν αὐτῶν.”
66008001 Καὶ ὅταν ἤνοιξεν τὴν σφραγῖδα τὴν ἑβδόμην, ἐγένετο σιγὴ ἐν τῷ οὐρανῷ ὡς ἡμιώριον.
66008002 Καὶ εἶδον τοὺς ἑπτὰ ἀγγέλους, οἳ ἐνώπιον τοῦ ˚Θεοῦ ἑστήκασιν, καὶ ἐδόθησαν αὐτοῖς ἑπτὰ σάλπιγγες.
66008003 Καὶ ἄλλος ἄγγελος ἦλθεν καὶ ἐστάθη ἐπὶ τοῦ θυσιαστήριον, ἔχων λιβανωτὸν χρυσοῦν, καὶ ἐδόθη αὐτῷ θυμιάματα πολλὰ, ἵνα δώσει ταῖς προσευχαῖς τῶν ἁγίων πάντων ἐπὶ τὸ θυσιαστήριον τὸ χρυσοῦν τὸ ἐνώπιον τοῦ θρόνου.
66008004 Καὶ ἀνέβη ὁ καπνὸς τῶν θυμιαμάτων ταῖς προσευχαῖς τῶν ἁγίων ἐκ χειρὸς τοῦ ἀγγέλου ἐνώπιον τοῦ ˚Θεοῦ.
66008005 Καὶ εἴληφεν ὁ ἄγγελος τὸν λιβανωτόν, καὶ ἐγέμισεν αὐτὸν ἐκ τοῦ πυρὸς τοῦ θυσιαστηρίου, καὶ ἔβαλεν εἰς τὴν γῆν· καὶ ἐγένοντο βρονταὶ, καὶ φωναὶ, καὶ ἀστραπαὶ, καὶ σεισμός.
66008006 Καὶ οἱ ἑπτὰ ἄγγελοι οἱ ἔχοντες τὰς ἑπτὰ σάλπιγγας ἡτοίμασαν αὑτοὺς, ἵνα σαλπίσωσιν.
66008007 Καὶ ὁ πρῶτος ἐσάλπισεν, καὶ ἐγένετο χάλαζα καὶ πῦρ μεμιγμένα ἐν αἵματι, καὶ ἐβλήθη εἰς τὴν γῆν· καὶ τὸ τρίτον τῆς γῆς κατεκάη, καὶ τὸ τρίτον τῶν δένδρων κατεκάη, καὶ πᾶς χόρτος χλωρὸς κατεκάη.
66008008 Καὶ ὁ δεύτερος ἄγγελος ἐσάλπισεν, καὶ ὡς ὄρος μέγα πυρὶ καιόμενον, ἐβλήθη εἰς τὴν θάλασσαν· καὶ ἐγένετο τὸ τρίτον τῆς θαλάσσης αἷμα,
66008009 καὶ ἀπέθανε τὸ τρίτον τῶν κτισμάτων τῶν ἐν τῇ θαλάσσῃ τὰ ἔχοντα ψυχὴν, καὶ τὸ τρίτον τῶν πλοίων διεφθάρησαν.
66008010 Καὶ ὁ τρίτος ἄγγελος ἐσάλπισεν, καὶ ἔπεσεν ἐκ τοῦ οὐρανοῦ ἀστὴρ μέγας, καιόμενος ὡς λαμπάς, καὶ ἔπεσεν ἐπὶ τὸ τρίτον τῶν ποταμῶν, καὶ ἐπὶ τὰς πηγὰς τῶν ὑδάτων,
66008011 καὶ τὸ ὄνομα τοῦ ἀστέρος λέγεται ὁ Ἄψινθος. Καὶ ἐγένετο τὸ τρίτον τῶν ὑδάτων εἰς ἄψινθον, καὶ πολλοὶ τῶν ἀνθρώπων ἀπέθανον ἐκ τῶν ὑδάτων, ὅτι ἐπικράνθησαν.
66008012 Καὶ ὁ τέταρτος ἄγγελος ἐσάλπισεν, καὶ ἐπλήγη τὸ τρίτον τοῦ ἡλίου, καὶ τὸ τρίτον τῆς σελήνης, καὶ τὸ τρίτον τῶν ἀστέρων, ἵνα σκοτισθῇ τὸ τρίτον αὐτῶν, καὶ ἡ ἡμέρα μὴ φάνῃ τὸ τρίτον αὐτῆς, καὶ ἡ νὺξ ὁμοίως.
66008013 Καὶ εἶδον, καὶ ἤκουσα ἑνὸς ἀετοῦ πετομένου ἐν μεσουρανήματι, λέγοντος φωνῇ μεγάλῃ, “Οὐαὶ, οὐαὶ, οὐαὶ, τοὺς κατοικοῦντας ἐπὶ τῆς γῆς, ἐκ τῶν λοιπῶν φωνῶν τῆς σάλπιγγος τῶν τριῶν ἀγγέλων τῶν μελλόντων σαλπίζειν!”
66009001 Καὶ ὁ πέμπτος ἄγγελος ἐσάλπισεν, καὶ εἶδον ἀστέρα ἐκ τοῦ οὐρανοῦ πεπτωκότα εἰς τὴν γῆν, καὶ ἐδόθη αὐτῷ ἡ κλεὶς τοῦ φρέατος τῆς Ἀβύσσου,
66009002 καὶ ἤνοιξεν τὸ φρέαρ τῆς Ἀβύσσου, καὶ ἀνέβη καπνὸς ἐκ τοῦ φρέατος, ὡς καπνὸς καμίνου μεγάλης, καὶ ἐσκοτίσθη ὁ ἥλιος καὶ ὁ ἀὴρ ἐκ τοῦ καπνοῦ τοῦ φρέατος.
66009003 Καὶ ἐκ τοῦ καπνοῦ ἐξῆλθον ἀκρίδες εἰς τὴν γῆν, καὶ ἐδόθη αὐταῖς ἐξουσία, ὡς ἔχουσιν ἐξουσίαν οἱ σκορπίοι τῆς γῆς.
66009004 Καὶ ἐρρέθη αὐταῖς ἵνα μὴ ἀδικήσουσιν τὸν χόρτον τῆς γῆς, οὐδὲ πᾶν χλωρὸν, οὐδὲ πᾶν δένδρον, εἰ μὴ τοὺς ἀνθρώπους οἵτινες οὐκ ἔχουσι τὴν σφραγῖδα τοῦ ˚Θεοῦ ἐπὶ τῶν μετώπων.
66009005 Καὶ ἐδόθη αὐτοῖς, ἵνα μὴ ἀποκτείνωσιν αὐτούς, ἀλλʼ ἵνα βασανισθήσονται μῆνας πέντε· καὶ ὁ βασανισμὸς αὐτῶν ὡς βασανισμὸς σκορπίου, ὅταν παίσῃ ἄνθρωπον.
66009006 Καὶ ἐν ταῖς ἡμέραις ἐκείναις, ζητήσουσιν οἱ ἄνθρωποι τὸν θάνατον, καὶ οὐ μὴ εὑρήσουσιν αὐτόν, καὶ ἐπιθυμήσουσιν ἀποθανεῖν, καὶ φεύγει ὁ θάνατος ἀπʼ αὐτῶν.
66009007 Καὶ τὰ ὁμοιώματα τῶν ἀκρίδων ὅμοια ἵπποις ἡτοιμασμένοις εἰς πόλεμον, καὶ ἐπὶ τὰς κεφαλὰς αὐτῶν ὡς στέφανοι ὅμοιοι χρυσῷ, καὶ τὰ πρόσωπα αὐτῶν ὡς πρόσωπα ἀνθρώπων,
66009008 καὶ εἶχον τρίχας ὡς τρίχας γυναικῶν, καὶ οἱ ὀδόντες αὐτῶν ὡς λεόντων ἦσαν,
66009009 καὶ εἶχον θώρακας ὡς θώρακας σιδηροῦς, καὶ ἡ φωνὴ τῶν πτερύγων αὐτῶν ὡς φωνὴ ἁρμάτων ἵππων πολλῶν τρεχόντων εἰς πόλεμον,
66009010 καὶ ἔχουσιν οὐρὰς ὁμοίας σκορπίοις καὶ κέντρα, καὶ ἐν ταῖς οὐραῖς αὐτῶν ἡ ἐξουσία αὐτῶν ἀδικῆσαι τοὺς ἀνθρώπους μῆνας πέντε.
66009011 Ἔχουσιν ἐπʼ αὐτῶν βασιλέα, τὸν ἄγγελον τῆς Ἀβύσσου· ὄνομα αὐτῷ Ἑβραϊστὶ, Ἀβαδδών, καὶ ἐν τῇ Ἑλληνικῇ ὄνομα ἔχει, Ἀπολλύων.
66009012 Ἡ οὐαὶ ἡ μία ἀπῆλθεν· ἰδοὺ, ἔρχεται ἔτι δύο οὐαὶ μετὰ ταῦτα.
66009013 Καὶ ὁ ἕκτος ἄγγελος ἐσάλπισεν, καὶ ἤκουσα φωνὴν μίαν ἐκ τῶν κεράτων τοῦ θυσιαστηρίου τοῦ χρυσοῦ τοῦ ἐνώπιον τοῦ ˚Θεοῦ,
66009014 λέγοντα τῷ ἕκτῳ ἀγγέλῳ, ὁ ἔχων τὴν σάλπιγγα, “Λῦσον τοὺς τέσσαρας ἀγγέλους, τοὺς δεδεμένους ἐπὶ τῷ ποταμῷ τῷ μεγάλῳ Εὐφράτῃ.”
66009015 Καὶ ἐλύθησαν οἱ τέσσαρες ἄγγελοι, οἱ ἡτοιμασμένοι εἰς τὴν ὥραν, καὶ ἡμέραν, καὶ μῆνα, καὶ ἐνιαυτόν, ἵνα ἀποκτείνωσιν τὸ τρίτον τῶν ἀνθρώπων.
66009016 Καὶ ὁ ἀριθμὸς τῶν στρατευμάτων τοῦ ἱππικοῦ δύο μυριάδες μυριάδων· ἤκουσα τὸν ἀριθμὸν αὐτῶν.
66009017 Καὶ οὕτως εἶδον τοὺς ἵππους ἐν τῇ ὁράσει, καὶ τοὺς καθημένους ἐπʼ αὐτῶν: ἔχοντας θώρακας πυρίνους, καὶ ὑακινθίνους, καὶ θειώδεις, καὶ αἱ κεφαλαὶ τῶν ἵππων ὡς κεφαλαὶ λεόντων, καὶ ἐκ τῶν στομάτων αὐτῶν ἐκπορεύεται πῦρ, καὶ καπνὸς, καὶ θεῖον.
66009018 Ἀπὸ τῶν τριῶν πληγῶν τούτων ἀπεκτάνθησαν τὸ τρίτον τῶν ἀνθρώπων ἐκ τοῦ πυρὸς, καὶ τοῦ καπνοῦ, καὶ τοῦ θείου, τοῦ ἐκπορευομένου ἐκ τῶν στομάτων αὐτῶν.
66009019 Ἡ γὰρ ἐξουσία τῶν ἵππων ἐν τῷ στόματι αὐτῶν ἐστιν καὶ ἐν ταῖς οὐραῖς αὐτῶν, αἱ γὰρ οὐραὶ αὐτῶν ὅμοιαι ὄφεσιν, ἔχουσαι κεφαλάς, καὶ ἐν αὐταῖς ἀδικοῦσιν.
66009020 Καὶ οἱ λοιποὶ τῶν ἀνθρώπων, οἳ οὐκ ἀπεκτάνθησαν ἐν ταῖς πληγαῖς ταύταις, οὐδὲ μετενόησαν ἐκ τῶν ἔργων τῶν χειρῶν αὐτῶν, ἵνα μὴ προσκυνήσουσιν τὰ δαιμόνια, καὶ τὰ εἴδωλα τὰ χρυσᾶ, καὶ τὰ ἀργυρᾶ, καὶ τὰ χαλκᾶ, καὶ τὰ λίθινα, καὶ τὰ ξύλινα, ἃ οὔτε βλέπειν δύνανται, οὔτε ἀκούειν, οὔτε περιπατεῖν.
66009021 Καὶ οὐ μετενόησαν ἐκ τῶν φόνων αὐτῶν, οὔτε ἐκ τῶν φαρμάκων αὐτῶν, οὔτε ἐκ τῆς πονηρίας αὐτῶν, οὔτε ἐκ τῶν κλεμμάτων αὐτῶν.
66010001 Καὶ εἶδον ἄλλον ἄγγελον ἰσχυρὸν, καταβαίνοντα ἐκ τοῦ οὐρανοῦ, περιβεβλημένον νεφέλην, καὶ ἡ ἶρις ἐπὶ τὴν κεφαλὴν αὐτοῦ, καὶ τὸ πρόσωπον αὐτοῦ ὡς ὁ ἥλιος, καὶ οἱ πόδες αὐτοῦ ὡς στῦλοι πυρός,
66010002 καὶ ἔχων ἐν τῇ χειρὶ αὐτοῦ βιβλαρίδιον ἠνεῳγμένον. Καὶ ἔθηκεν τὸν πόδα αὐτοῦ τὸν δεξιὸν ἐπὶ τῆς θαλάσσης, τὸν δὲ εὐώνυμον ἐπὶ τῆς γῆς,
66010003 καὶ ἔκραξεν φωνῇ μεγάλῃ ὥσπερ λέων μυκᾶται. Καὶ ὅτε ἔκραξεν, ἐλάλησαν αἱ ἑπτὰ βρονταὶ τὰς ἑαυτῶν φωνάς.
66010004 Καὶ ὅτε ἐλάλησαν αἱ ἑπτὰ βρονταί, ἤμελλον γράφειν, καὶ ἤκουσα φωνὴν ἐκ τοῦ οὐρανοῦ λέγουσαν, “Σφράγισον ἃ ἐλάλησαν αἱ ἑπτὰ βρονταί, καὶ μὴ αὐτὰ γράψῃς.”
66010005 Καὶ ὁ ἄγγελος, ὃν εἶδον ἑστῶτα ἐπὶ τῆς θαλάσσης καὶ ἐπὶ τῆς γῆς, ἦρεν τὴν χεῖρα αὐτοῦ τὴν δεξιὰν εἰς τὸν οὐρανόν,
66010006 καὶ ὤμοσεν ἐν τῷ ζῶντι εἰς τοὺς αἰῶνας τῶν αἰώνων, “ὃς ἔκτισεν τὸν οὐρανὸν καὶ τὰ ἐν αὐτῷ, καὶ τὴν γῆν καὶ τὰ ἐν αὐτῇ, καὶ τὴν θάλασσαν καὶ τὰ ἐν αὐτῇ”, ὅτι χρόνος οὐκέτι ἔσται!
66010007 Ἀλλʼ ἐν ταῖς ἡμέραις τῆς φωνῆς τοῦ ἑβδόμου ἀγγέλου, ὅταν μέλλῃ σαλπίζειν, καὶ ἐτελέσθη τὸ μυστήριον τοῦ ˚Θεοῦ, ὡς εὐηγγέλισεν τοὺς ἑαυτοῦ δούλους, τοὺς προφήτας.
66010008 Καὶ ἡ φωνὴ ἣν ἤκουσα ἐκ τοῦ οὐρανοῦ, πάλιν λαλοῦσαν μετʼ ἐμοῦ, καὶ λέγουσαν, “Ὕπαγε, λάβε τὸ βιβλίον τὸ ἠνεῳγμένον ἐν τῇ χειρὶ τοῦ ἀγγέλου, τοῦ ἑστῶτος ἐπὶ τῆς θαλάσσης καὶ ἐπὶ τῆς γῆς.”
66010009 Καὶ ἀπῆλθα πρὸς τὸν ἄγγελον, λέγων αὐτῷ δοῦναί μοι τὸ βιβλαρίδιον. Καὶ λέγει μοι, “Λάβε καὶ κατάφαγε αὐτό, καὶ πικρανεῖ σου τὴν κοιλίαν, ἀλλʼ ἐν τῷ στόματί σου, ἔσται γλυκὺ ὡς μέλι.”
66010010 Καὶ ἔλαβον τὸ βιβλαρίδιον ἐκ τῆς χειρὸς τοῦ ἀγγέλου, καὶ κατέφαγον αὐτό, καὶ ἦν ἐν τῷ στόματί μου ὡς μέλι γλυκύ· καὶ ὅτε ἔφαγον αὐτό, ἐπικράνθη ἡ κοιλία μου.
66010011 Καὶ λέγουσίν μοι, “Δεῖ σε πάλιν προφητεῦσαι ἐπὶ λαοῖς, καὶ ἔθνεσιν, καὶ γλώσσαις, καὶ βασιλεῦσιν πολλοῖς.”
66011001 Καὶ ἐδόθη μοι κάλαμος ὅμοιος ῥάβδῳ λέγων, “Ἔγειρε, καὶ μέτρησον τὸν ναὸν τοῦ ˚Θεοῦ, καὶ τὸ θυσιαστήριον, καὶ τοὺς προσκυνοῦντας ἐν αὐτῷ.
66011002 Καὶ τὴν αὐλὴν τὴν ἔξωθεν τοῦ ναοῦ, ἔκβαλε ἔξωθεν καὶ μὴ αὐτὴν μετρήσῃς, ὅτι ἐδόθη τοῖς ἔθνεσιν, καὶ τὴν πόλιν τὴν ἁγίαν πατήσουσιν μῆνας τεσσεράκοντα δύο.
66011003 Καὶ δώσω τοῖς δυσὶν μάρτυσίν μου, καὶ προφητεύσουσιν ἡμέρας χιλίας διακοσίας ἑξήκοντα, περιβεβλημένοι σάκκους.”
66011004 Οὗτοί εἰσιν αἱ δύο ἐλαῖαι καὶ αἱ δύο λυχνίαι, αἱ ἐνώπιον τοῦ ˚Κυρίου τῆς γῆς ἑστῶτες.
66011005 Καὶ εἴ τις αὐτοὺς θέλει ἀδικῆσαι, πῦρ ἐκπορεύεται ἐκ τοῦ στόματος αὐτῶν, καὶ κατεσθίει τοὺς ἐχθροὺς αὐτῶν. Καὶ εἴ τις θελήσῃ αὐτοὺς ἀδικῆσαι, οὕτως δεῖ αὐτὸν ἀποκτανθῆναι.
66011006 Οὗτοι ἔχουσιν τὴν ἐξουσίαν κλεῖσαι τὸν οὐρανόν, ἵνα μὴ ὑετὸς βρέχῃ τὰς ἡμέρας τῆς προφητείας αὐτῶν, καὶ ἐξουσίαν ἔχουσιν ἐπὶ τῶν ὑδάτων, στρέφειν αὐτὰ εἰς αἷμα, καὶ πατάξαι τὴν γῆν ἐν πάσῃ πληγῇ, ὁσάκις ἐὰν θελήσωσιν.
66011007 Καὶ ὅταν τελέσωσιν τὴν μαρτυρίαν αὐτῶν, τὸ θηρίον τὸ ἀναβαῖνον ἐκ τῆς Ἀβύσσου ποιήσει μετʼ αὐτῶν πόλεμον, καὶ νικήσει αὐτοὺς, καὶ ἀποκτενεῖ αὐτούς.
66011008 Καὶ τὸ πτῶμα αὐτῶν ἐπὶ τῆς πλατείας τῆς πόλεως τῆς μεγάλης, ἥτις καλεῖται πνευματικῶς, Σόδομα καὶ Αἴγυπτος, ὅπου καὶ ὁ ˚Κύριος αὐτῶν ἐσταυρώθη.
66011009 Καὶ βλέπουσιν ἐκ τῶν λαῶν, καὶ φυλῶν, καὶ γλωσσῶν, καὶ ἐθνῶν, τὸ πτῶμα αὐτῶν ἡμέρας τρεῖς καὶ ἥμισυ, καὶ τὰ πτώματα αὐτῶν οὐκ ἀφίουσιν τεθῆναι εἰς μνῆμα.
66011010 Καὶ οἱ κατοικοῦντες ἐπὶ τῆς γῆς χαίρουσιν ἐπʼ αὐτοῖς, καὶ εὐφραίνονται, καὶ δῶρα πέμψουσιν ἀλλήλοις, ὅτι οὗτοι οἱ δύο προφῆται ἐβασάνισαν τοὺς κατοικοῦντας ἐπὶ τῆς γῆς.
66011011 Καὶ μετὰ τὰς τρεῖς ἡμέρας καὶ ἥμισυ, πνεῦμα ζωῆς ἐκ τοῦ ˚Θεοῦ εἰσῆλθεν εἰς αὐτοῖς, καὶ ἔστησαν ἐπὶ τοὺς πόδας αὐτῶν, καὶ φόβος μέγας ἐπέπεσεν ἐπὶ τοὺς θεωροῦντας αὐτούς.
66011012 Καὶ ἤκουσαν φωνὴν μεγάλην ἐκ τοῦ οὐρανοῦ, λέγουσαν αὐτοῖς, “Ἀνάβατε ὧδε”. Καὶ ἀνέβησαν εἰς τὸν οὐρανὸν ἐν τῇ νεφέλῃ, καὶ ἐθεώρησαν αὐτοὺς οἱ ἐχθροὶ αὐτῶν.
66011013 Καὶ ἐν ἐκείνῃ τῇ ὥρᾳ ἐγένετο σεισμὸς μέγας, καὶ τὸ δέκατον τῆς πόλεως ἔπεσεν, καὶ ἀπεκτάνθησαν ἐν τῷ σεισμῷ, ὀνόματα ἀνθρώπων χιλιάδες ἑπτά, καὶ οἱ λοιποὶ ἔμφοβοι ἐγένοντο, καὶ ἔδωκαν δόξαν τῷ ˚Θεῷ τοῦ οὐρανοῦ.
66011014 Ἡ οὐαὶ ἡ δευτέρα ἀπῆλθεν· ἰδοὺ, ἡ οὐαὶ ἡ τρίτη ἔρχεται ταχύ.
66011015 Καὶ ὁ ἕβδομος ἄγγελος ἐσάλπισεν, καὶ ἐγένοντο φωναὶ μεγάλαι ἐν τῷ οὐρανῷ λέγουσαι, “Ἐγένετο ἡ βασιλεία τοῦ κόσμου τοῦ ˚Κυρίου ἡμῶν καὶ τοῦ ˚Χριστοῦ αὐτοῦ, καὶ βασιλεύσει εἰς τοὺς αἰῶνας τῶν αἰώνων.”
66011016 Καὶ οἱ εἴκοσι τέσσαρες πρεσβύτεροι οἱ ἐνώπιον τοῦ ˚Θεοῦ καθήμενοι ἐπὶ τοὺς θρόνους αὐτῶν, ἔπεσαν ἐπὶ τὰ πρόσωπα αὐτῶν, καὶ προσεκύνησαν τῷ ˚Θεῷ
66011017 λέγοντες, “Εὐχαριστοῦμέν σοι, ˚Κύριε, ὁ ˚Θεός ὁ Παντοκράτωρ, ὁ ὢν, καὶ ὁ ἦν, καὶ ὅτι εἴληφας τὴν δύναμίν σου τὴν μεγάλην, καὶ ἐβασίλευσας.
66011018 Καὶ τὰ ἔθνη ὠργίσθησαν, καὶ ἦλθεν ἡ ὀργή σου, καὶ ὁ καιρὸς τῶν νεκρῶν κριθῆναι, καὶ δοῦναι τὸν μισθὸν τοῖς δούλοις σου, τοῖς προφήταις, καὶ τοῖς ἁγίοις, καὶ τοῖς φοβουμένοις τὸ ὄνομά σου, τοὺς μικροὺς καὶ τοὺς μεγάλους, καὶ διαφθεῖραι τοὺς διαφθείροντας τὴν γῆν.”
66011019 Καὶ ἠνοίγη ὁ ναὸς τοῦ ˚Θεοῦ ὁ ἐν τῷ οὐρανῷ, καὶ ὤφθη ἡ κιβωτὸς τῆς διαθήκης αὐτοῦ ἐν τῷ ναῷ αὐτοῦ, καὶ ἐγένοντο ἀστραπαὶ, καὶ φωναὶ, καὶ βρονταὶ, καὶ σεισμὸς, καὶ χάλαζα μεγάλη.
66012001 Καὶ σημεῖον μέγα ὤφθη ἐν τῷ οὐρανῷ: γυνὴ περιβεβλημένη τὸν ἥλιον, καὶ ἡ σελήνη ὑποκάτω τῶν ποδῶν αὐτῆς, καὶ ἐπὶ τῆς κεφαλῆς αὐτῆς στέφανος ἀστέρων δώδεκα,
66012002 καὶ ἐν γαστρὶ ἔχουσα, καὶ κράζει ὠδίνουσα, καὶ βασανιζομένη τεκεῖν.
66012003 Καὶ ὤφθη ἄλλο σημεῖον ἐν τῷ οὐρανῷ: καὶ ἰδοὺ, δράκων πυρρός μέγας ἔχων κεφαλὰς ἑπτὰ, καὶ κέρατα δέκα, καὶ ἐπὶ τὰς κεφαλὰς αὐτοῦ ἑπτὰ διαδήματα,
66012004 καὶ ἡ οὐρὰ αὐτοῦ σύρει τὸ τρίτον τῶν ἀστέρων τοῦ οὐρανοῦ, καὶ ἔβαλεν αὐτοὺς εἰς τὴν γῆν. Καὶ ὁ δράκων ἕστηκεν ἐνώπιον τῆς γυναικὸς τῆς μελλούσης τεκεῖν, ἵνα ὅταν τέκῃ, τὸ τέκνον αὐτῆς καταφάγῃ.
66012005 Καὶ ἔτεκεν υἱόν ἄρσεν, ὃς μέλλει ποιμαίνειν πάντα τὰ ἔθνη ἐν ῥάβδῳ σιδηρᾷ· καὶ ἡρπάσθη τὸ τέκνον αὐτῆς πρὸς τὸν ˚Θεὸν, καὶ πρὸς τὸν θρόνον αὐτοῦ.
66012006 Καὶ ἡ γυνὴ ἔφυγεν εἰς τὴν ἔρημον, ὅπου ἔχει ἐκεῖ τόπον ἡτοιμασμένον ἀπὸ τοῦ ˚Θεοῦ, ἵνα ἐκεῖ τρέφωσιν αὐτὴν ἡμέρας χιλίας διακοσίας ἑξήκοντα.
66012007 Καὶ ἐγένετο πόλεμος ἐν τῷ οὐρανῷ, ὁ Μιχαὴλ καὶ οἱ ἄγγελοι αὐτοῦ τοῦ πολεμῆσαι μετὰ τοῦ δράκοντος. Καὶ ὁ δράκων ἐπολέμησεν καὶ οἱ ἄγγελοι αὐτοῦ,
66012008 καὶ οὐκ ἴσχυσαν, οὐδὲ τόπος εὑρέθη αὐτῶν ἔτι ἐν τῷ οὐρανῷ.
66012009 Καὶ ἐβλήθη ὁ δράκων ὁ μέγας, ὁ ὄφις ὁ ἀρχαῖος, ὁ καλούμενος, Διάβολος, καὶ ὁ Σατανᾶς, ὁ πλανῶν τὴν οἰκουμένην ὅλην· ἐβλήθη εἰς τὴν γῆν, καὶ οἱ ἄγγελοι αὐτοῦ μετʼ αὐτοῦ ἐβλήθησαν.
66012010 Καὶ ἤκουσα φωνὴν μεγάλην ἐν τῷ οὐρανῷ λέγουσαν, “Ἄρτι ἐγένετο ἡ σωτηρία, καὶ ἡ δύναμις, καὶ ἡ Βασιλεία τοῦ ˚Θεοῦ ἡμῶν, καὶ ἡ ἐξουσία τοῦ ˚Χριστοῦ αὐτοῦ, ὅτι ἐβλήθη ὁ κατήγωρ τῶν ἀδελφῶν ἡμῶν, ὁ κατηγορῶν αὐτοὺς ἐνώπιον τοῦ ˚Θεοῦ ἡμῶν, ἡμέρας καὶ νυκτός.
66012011 Καὶ αὐτοὶ ἐνίκησαν αὐτὸν διὰ τὸ αἷμα τοῦ Ἀρνίου, καὶ διὰ τὸν λόγον τῆς μαρτυρίας αὐτῶν, καὶ οὐκ ἠγάπησαν τὴν ψυχὴν αὐτῶν ἄχρι θανάτου.
66012012 Διὰ τοῦτο εὐφραίνεσθε, οὐρανοὶ, καὶ οἱ ἐν αὐτοῖς σκηνοῦντες! οὐαὶ τὴν γῆν καὶ τὴν θάλασσαν, ὅτι κατέβη ὁ διάβολος πρὸς ὑμᾶς, ἔχων θυμὸν μέγαν, εἰδὼς ὅτι ὀλίγον καιρὸν ἔχει.”
66012013 Καὶ ὅτε εἶδεν ὁ δράκων ὅτι ἐβλήθη εἰς τὴν γῆν, ἐδίωξεν τὴν γυναῖκα, ἥτις ἔτεκεν τὸν ἄρσενα.
66012014 Καὶ ἐδόθησαν τῇ γυναικὶ δύο πτέρυγες τοῦ ἀετοῦ τοῦ μεγάλου, ἵνα πέτηται εἰς τὴν ἔρημον εἰς τὸν τόπον αὐτῆς, ὅπου τρέφεται ἐκεῖ καιρὸν, καὶ καιροὺς, καὶ ἥμισυ καιροῦ, ἀπὸ προσώπου τοῦ ὄφεως.
66012015 Καὶ ἔβαλεν ὁ ὄφις ἐκ τοῦ στόματος αὐτοῦ ὀπίσω τῆς γυναικὸς, ὕδωρ ὡς ποταμόν, ἵνα αὐτὴν ποταμοφόρητον ποιήσῃ.
66012016 Καὶ ἐβοήθησεν ἡ γῆ τῇ γυναικί, καὶ ἤνοιξεν ἡ γῆ τὸ στόμα αὐτῆς, καὶ κατέπιεν τὸν ποταμὸν, ὃν ἔβαλεν ὁ δράκων ἐκ τοῦ στόματος αὐτοῦ.
66012017 Καὶ ὠργίσθη ὁ δράκων ἐπὶ τῇ γυναικί, καὶ ἀπῆλθεν ποιῆσαι πόλεμον μετὰ τῶν λοιπῶν τοῦ σπέρματος αὐτῆς, τῶν τηρούντων τὰς ἐντολὰς τοῦ ˚Θεοῦ, καὶ ἐχόντων τὴν μαρτυρίαν ˚Ἰησοῦ.
66012018 Καὶ ἐστάθη ἐπὶ τὴν ἄμμον τῆς θαλάσσης.
66013001 Καὶ εἶδον ἐκ τῆς θαλάσσης θηρίον ἀναβαῖνον, ἔχον κέρατα δέκα καὶ κεφαλὰς ἑπτά, καὶ ἐπὶ τῶν κεράτων αὐτοῦ δέκα διαδήματα, καὶ ἐπὶ τὰς κεφαλὰς αὐτοῦ ὀνόματα βλασφημίας.
66013002 Καὶ τὸ θηρίον ὃ εἶδον ἦν ὅμοιον παρδάλει, καὶ οἱ πόδες αὐτοῦ ὡς ἄρκου, καὶ τὸ στόμα αὐτοῦ ὡς στόμα λέοντος. Καὶ ἔδωκεν αὐτῷ ὁ δράκων τὴν δύναμιν αὐτοῦ, καὶ τὸν θρόνον αὐτοῦ, καὶ ἐξουσίαν μεγάλην.
66013003 Καὶ μίαν ἐκ τῶν κεφαλῶν αὐτοῦ ὡς ἐσφαγμένην εἰς θάνατον, καὶ ἡ πληγὴ τοῦ θανάτου αὐτοῦ ἐθεραπεύθη. Καὶ ἐθαυμάσθη ὅλη ἡ γῆ ὀπίσω τοῦ θηρίου,
66013004 καὶ προσεκύνησαν τῷ δράκοντι, ὅτι ἔδωκεν τὴν ἐξουσίαν τῷ θηρίῳ, καὶ προσεκύνησαν τῷ θηρίῳ λέγοντες, “Τίς ὅμοιος τῷ θηρίῳ, καὶ τίς δύναται πολεμῆσαι μετʼ αὐτοῦ;”
66013005 Καὶ ἐδόθη αὐτῷ στόμα λαλοῦν μεγάλα καὶ βλασφημίας, καὶ ἐδόθη αὐτῷ ἐξουσία ποιῆσαι μῆνας τεσσεράκοντα δύο.
66013006 Καὶ ἤνοιξε τὸ στόμα αὐτοῦ εἰς βλασφημίας πρὸς τὸν ˚Θεόν, βλασφημῆσαι τὸ ὄνομα αὐτοῦ, καὶ τὴν σκηνὴν αὐτοῦ, τοὺς ἐν τῷ οὐρανῷ σκηνοῦντας.
66013007 Καὶ ἐδόθη αὐτῷ ἐξουσία ἐπὶ πᾶσαν φυλὴν, καὶ λαὸν, καὶ γλῶσσαν, καὶ ἔθνος.
66013008 Καὶ προσκυνήσουσιν αὐτὸν πάντες οἱ κατοικοῦντες ἐπὶ τῆς γῆς, ὧν οὐ γέγραπται τὸ ὄνομα αὐτοῦ ἐν βιβλίῳ τῆς ζωῆς τοῦ Ἀρνίου, τοῦ ἐσφαγμένου ἀπὸ καταβολῆς κόσμου.
66013009 Εἴ τις ἔχει οὖς, ἀκουσάτω.
66013010 Εἴ τις εἰς αἰχμαλωσίαν, ὑπάγει· εἴ τις ἐν μαχαίρῃ ἀποκτανθῆναι, δεῖ αὐτὸν ἐν μαχαίρῃ ἀποκτανθῆναι. Ὧδέ ἐστιν ἡ ὑπομονὴ καὶ ἡ πίστις τῶν ἁγίων.
66013011 Καὶ εἶδον ἄλλο θηρίον ἀναβαῖνον ἐκ τῆς γῆς, καὶ εἶχεν κέρατα δύο ὅμοια ἀρνίῳ, καὶ ἐλάλει ὡς δράκων.
66013012 Καὶ τὴν ἐξουσίαν τοῦ πρώτου θηρίου πᾶσαν ποιεῖ ἐνώπιον αὐτοῦ. Καὶ ποιεῖ τὴν γῆν καὶ τοὺς ἐν αὐτῇ κατοικοῦντας, ἵνα προσκυνήσουσιν τὸ θηρίον τὸ πρῶτον, οὗ ἐθεραπεύθη ἡ πληγὴ τοῦ θανάτου αὐτοῦ.
66013013 Καὶ ποιεῖ σημεῖα μεγάλα, ἵνα καὶ πῦρ ποιῇ ἐκ τοῦ οὐρανοῦ καταβαίνειν εἰς τὴν γῆν ἐνώπιον τῶν ἀνθρώπων.
66013014 Καὶ πλανᾷ τοὺς κατοικοῦντας ἐπὶ τῆς γῆς, διὰ τὰ σημεῖα ἃ ἐδόθη αὐτῷ ποιῆσαι ἐνώπιον τοῦ θηρίου, λέγων τοῖς κατοικοῦσιν ἐπὶ τῆς γῆς, ποιῆσαι εἰκόνα τῷ θηρίῳ, ὃς ἔχει τὴν πληγὴν τῆς μαχαίρης, καὶ ἔζησεν.
66013015 Καὶ ἐδόθη αὐτῷ δοῦναι πνεῦμα τῇ εἰκόνι τοῦ θηρίου, ἵνα καὶ λαλήσῃ ἡ εἰκὼν τοῦ θηρίου, καὶ ποιήσει ὅσοι ἂν μὴ προσκυνήσουσιν τῇ εἰκόνι τοῦ θηρίου ἀποκτανθῶσιν.
66013016 Καὶ ποιεῖ πάντας, τοὺς μικροὺς καὶ τοὺς μεγάλους, καὶ τοὺς πλουσίους καὶ τοὺς πτωχούς, καὶ τοὺς ἐλευθέρους καὶ τοὺς δούλους, ἵνα δῶσιν αὐτοῖς χάραγμα ἐπὶ τῆς χειρὸς αὐτῶν τῆς δεξιᾶς, ἢ ἐπὶ τὸ μέτωπον αὐτῶν,
66013017 καὶ ἵνα μή τις δύνηται ἀγοράσαι ἢ πωλῆσαι, εἰ μὴ ὁ ἔχων τὸ χάραγμα, τὸ ὄνομα τοῦ θηρίου, ἢ τὸν ἀριθμὸν τοῦ ὀνόματος αὐτοῦ.
66013018 Ὧδε ἡ σοφία ἐστίν: ὁ ἔχων νοῦν ψηφισάτω τὸν ἀριθμὸν τοῦ θηρίου, ἀριθμὸς γὰρ ἀνθρώπου ἐστίν, καὶ ὁ ἀριθμὸς αὐτοῦ ἐστὶν ἑξακόσιοι ἑξήκοντα ἕξ.
66014001 Καὶ εἶδον, καὶ ἰδοὺ, τὸ Ἀρνίον ἑστὸς ἐπὶ τὸ Ὄρος Σιών, καὶ μετʼ αὐτοῦ ἑκατὸν τεσσεράκοντα τέσσαρες χιλιάδες ἔχουσαι τὸ ὄνομα αὐτοῦ, καὶ τὸ ὄνομα τοῦ Πατρὸς αὐτοῦ, γεγραμμένον ἐπὶ τῶν μετώπων αὐτῶν.
66014002 Καὶ ἤκουσα φωνὴν ἐκ τοῦ οὐρανοῦ, ὡς φωνὴν ὑδάτων πολλῶν, καὶ ὡς φωνὴν βροντῆς μεγάλης, καὶ ἡ φωνὴ ἣν ἤκουσα ὡς κιθαρῳδῶν κιθαριζόντων ἐν ταῖς κιθάραις αὐτῶν.
66014003 Καὶ ᾄδουσιν ᾠδὴν καινὴν ἐνώπιον τοῦ θρόνου, καὶ ἐνώπιον τῶν τεσσάρων ζῴων, καὶ τῶν πρεσβυτέρων. Καὶ οὐδεὶς ἐδύνατο μαθεῖν τὴν ᾠδὴν, εἰ μὴ αἱ ἑκατὸν τεσσεράκοντα τέσσαρες χιλιάδες, οἱ ἠγορασμένοι ἀπὸ τῆς γῆς.
66014004 Οὗτοί εἰσιν οἳ μετὰ γυναικῶν οὐκ ἐμολύνθησαν, παρθένοι γάρ εἰσιν. Οὗτοι οἱ ἀκολουθοῦντες τῷ Ἀρνίῳ ὅπου ἂν ὑπάγει. Οὗτοι ἠγοράσθησαν ἀπὸ τῶν ἀνθρώπων ἀπαρχὴ τῷ ˚Θεῷ καὶ τῷ Ἀρνίῳ,
66014005 καὶ ἐν τῷ στόματι αὐτῶν οὐχ εὑρέθη ψεῦδος, ἄμωμοί γάρ εἰσιν.
66014006 Καὶ εἶδον ἄλλον ἄγγελον πετόμενον ἐν μεσουρανήματι, ἔχοντα εὐαγγέλιον αἰώνιον εὐαγγελίσαι ἐπὶ τοὺς κατοικοῦντας ἐπὶ τῆς γῆς, καὶ ἐπὶ πᾶν ἔθνος, καὶ φυλὴν, καὶ γλῶσσαν, καὶ λαόν,
66014007 λέγων ἐν φωνῇ μεγάλῃ, “Φοβήθητε τὸν ˚Θεὸν, καὶ δότε αὐτῷ δόξαν, ὅτι ἦλθεν ἡ ὥρα τῆς κρίσεως αὐτοῦ, καὶ προσκυνήσατε τῷ ποιήσαντι τὸν οὐρανὸν, καὶ τὴν γῆν, καὶ θάλασσαν, καὶ πηγὰς ὑδάτων.”
66014008 Καὶ ἄλλος δεύτερος ἄγγελος ἠκολούθησεν λέγων, “Ἔπεσεν, ἔπεσεν Βαβυλὼν ἡ μεγάλη, ἣ ἐκ τοῦ οἴνου τοῦ θυμοῦ τῆς πορνείας αὐτῆς, πεπότικεν πάντα τὰ ἔθνη.”
66014009 Καὶ ἄλλος ἄγγελος τρίτος ἠκολούθησεν αὐτοῖς, λέγων ἐν φωνῇ μεγάλῃ, “Εἴ τις προσκυνεῖ τὸ θηρίον καὶ τὴν εἰκόνα αὐτοῦ, καὶ λαμβάνει χάραγμα ἐπὶ τοῦ μετώπου αὐτοῦ, ἢ ἐπὶ τὴν χεῖρα αὐτοῦ,
66014010 καὶ αὐτὸς πίεται ἐκ τοῦ οἴνου τοῦ θυμοῦ τοῦ ˚Θεοῦ, τοῦ κεκερασμένου ἀκράτου ἐν τῷ ποτηρίῳ τῆς ὀργῆς αὐτοῦ, καὶ βασανισθήσεται ἐν πυρὶ καὶ θείῳ ἐνώπιον τῶν ἀγγέλων ἁγίων καὶ ἐνώπιον τοῦ Ἀρνίου.
66014011 Καὶ ὁ καπνὸς τοῦ βασανισμοῦ αὐτῶν εἰς αἰῶνας αἰώνων ἀναβαίνει, καὶ οὐκ ἔχουσιν ἀνάπαυσιν, ἡμέρας καὶ νυκτός, οἱ προσκυνοῦντες τὸ θηρίον καὶ τὴν εἰκόνα αὐτοῦ, καὶ εἴ τις λαμβάνει τὸ χάραγμα τοῦ ὀνόματος αὐτοῦ.”
66014012 Ὧδε ἡ ὑπομονὴ τῶν ἁγίων ἐστίν, οἱ τηροῦντες τὰς ἐντολὰς τοῦ ˚Θεοῦ, καὶ τὴν πίστιν ˚Ἰησοῦ.
66014013 Καὶ ἤκουσα φωνῆς ἐκ τοῦ οὐρανοῦ λεγούσης, “Γράψον, ‘Μακάριοι οἱ νεκροὶ οἱ ἐν ˚Κυρίῳ ἀποθνῄσκοντες ἀπʼ ἄρτι.’” “Ναί”, Λέγει τὸ ˚Πνεῦμα, “ἵνα ἀναπαήσονται ἐκ τῶν κόπων αὐτῶν, τὰ γὰρ ἔργα αὐτῶν ἀκολουθεῖ μετʼ αὐτῶν.”
66014014 Καὶ εἶδον, καὶ “ἰδοὺ, νεφέλη λευκή, καὶ ἐπὶ τὴν νεφέλην καθήμενον ὅμοιον υἱὸν Ἀνθρώπου”, ἔχων ἐπὶ τῆς κεφαλῆς αὐτοῦ στέφανον χρυσοῦν, καὶ ἐν τῇ χειρὶ αὐτοῦ δρέπανον ὀξύ.
66014015 Καὶ ἄλλος ἄγγελος ἐξῆλθεν ἐκ τοῦ ναοῦ, κράζων ἐν φωνῇ μεγάλῃ τῷ καθημένῳ ἐπὶ τῆς νεφέλης, “Πέμψον τὸ δρέπανόν σου καὶ θέρισον, ὅτι ἦλθεν ἡ ὥρα θερίσαι, ὅτι ἐξηράνθη ὁ θερισμὸς τῆς γῆς.”
66014016 Καὶ ἔβαλεν ὁ καθήμενος ἐπὶ τῆς νεφέλης τὸ δρέπανον αὐτοῦ ἐπὶ τὴν γῆν, καὶ ἐθερίσθη ἡ γῆ.
66014017 Καὶ ἄλλος ἄγγελος ἐξῆλθεν ἐκ τοῦ ναοῦ τοῦ ἐν τῷ οὐρανῷ, ἔχων καὶ αὐτὸς δρέπανον ὀξύ.
66014018 Καὶ ἄλλος ἄγγελος ἐκ τοῦ θυσιαστηρίου, ὁ ἔχων ἐξουσίαν ἐπὶ τοῦ πυρός, καὶ ἐφώνησεν φωνῇ μεγάλῃ τῷ ἔχοντι τὸ δρέπανον τὸ ὀξὺ λέγων, “Πέμψον σου τὸ δρέπανον τὸ ὀξὺ, καὶ τρύγησον τοὺς βότρυας τῆς ἀμπέλου τῆς γῆς, ὅτι ἤκμασαν αἱ σταφυλαὶ αὐτῆς.”
66014019 Καὶ ἔβαλεν ὁ ἄγγελος τὸ δρέπανον αὐτοῦ εἰς τὴν γῆν, καὶ ἐτρύγησεν τὴν ἄμπελον τῆς γῆς, καὶ ἔβαλεν εἰς τὴν ληνὸν τοῦ θυμοῦ τοῦ ˚Θεοῦ τὸν μέγαν.
66014020 Καὶ ἐπατήθη ἡ ληνὸς ἔξωθεν τῆς πόλεως, καὶ ἐξῆλθεν αἷμα ἐκ τῆς ληνοῦ, ἄχρι τῶν χαλινῶν τῶν ἵππων ἀπὸ σταδίων χιλίων ἑξακοσίων.
66015001 Καὶ εἶδον ἄλλο σημεῖον ἐν τῷ οὐρανῷ, μέγα καὶ θαυμαστόν, ἀγγέλους ἑπτὰ ἔχοντας πληγὰς ἑπτὰ τὰς ἐσχάτας, ὅτι ἐν αὐταῖς ἐτελέσθη ὁ θυμὸς τοῦ ˚Θεοῦ.
66015002 Καὶ εἶδον ὡς θάλασσαν ὑαλίνην μεμιγμένην πυρί, καὶ τοὺς νικῶντας ἐκ τοῦ θηρίου, καὶ ἐκ τῆς εἰκόνος αὐτοῦ, καὶ ἐκ τοῦ ἀριθμοῦ τοῦ ὀνόματος αὐτοῦ, ἑστῶτας ἐπὶ τὴν θάλασσαν τὴν ὑαλίνην, ἔχοντας κιθάρας τοῦ ˚Θεοῦ.
66015003 Καὶ ᾄδουσιν τὴν ᾠδὴν Μωϋσέως, τοῦ δούλου τοῦ ˚Θεοῦ, καὶ τὴν ᾠδὴν τοῦ Ἀρνίου λέγοντες, “Μεγάλα καὶ θαυμαστὰ τὰ ἔργα σου, ˚Κύριε ὁ ˚Θεός ὁ Παντοκράτωρ! Δίκαιαι καὶ ἀληθιναὶ αἱ ὁδοί σου, ὁ Βασιλεὺς τῶν αἰώνων.
66015004 Τίς οὐ μὴ φοβηθῇ, ˚Κύριε, καὶ δοξάσει τὸ ὄνομά σου, ὅτι μόνος ὅσιος; ‘Ὅτι πάντα τὰ ἔθνη ἥξουσιν καὶ προσκυνήσουσιν ἐνώπιόν σου’, ὅτι τὰ δικαιώματά σου ἐφανερώθησαν.”
66015005 Καὶ μετὰ ταῦτα εἶδον, καὶ ἠνοίγη ὁ ναὸς τῆς σκηνῆς τοῦ μαρτυρίου ἐν τῷ οὐρανῷ,
66015006 καὶ ἐξῆλθον οἱ ἑπτὰ ἄγγελοι οἱ ἔχοντες τὰς ἑπτὰ πληγὰς ἐκ τοῦ ναοῦ, ἐνδεδυμένοι λίθον καθαρὸν λαμπρὸν, καὶ περιεζωσμένοι περὶ τὰ στήθη ζώνας χρυσᾶς.
66015007 Καὶ ἓν ἐκ τῶν τεσσάρων ζῴων ἔδωκεν τοῖς ἑπτὰ ἀγγέλοις ἑπτὰ φιάλας χρυσᾶς, γεμούσας τοῦ θυμοῦ τοῦ ˚Θεοῦ, τοῦ ζῶντος εἰς τοὺς αἰῶνας τῶν αἰώνων.
66015008 Καὶ ἐγεμίσθη ὁ ναὸς καπνοῦ ἐκ τῆς δόξης τοῦ ˚Θεοῦ, καὶ ἐκ τῆς δυνάμεως αὐτοῦ, καὶ οὐδεὶς ἐδύνατο εἰσελθεῖν εἰς τὸν ναὸν, ἄχρι τελεσθῶσιν αἱ ἑπτὰ πληγαὶ τῶν ἑπτὰ ἀγγέλων.
66016001 Καὶ ἤκουσα μεγάλης φωνῆς ἐκ τοῦ ναοῦ, λεγούσης τοῖς ἑπτὰ ἀγγέλοις, “Ὑπάγετε καὶ ἐκχέετε τὰς ἑπτὰ φιάλας τοῦ θυμοῦ τοῦ ˚Θεοῦ εἰς τὴν γῆν.”
66016002 Καὶ ἀπῆλθεν ὁ πρῶτος, καὶ ἐξέχεεν τὴν φιάλην αὐτοῦ εἰς τὴν γῆν, καὶ ἐγένετο ἕλκος κακὸν καὶ πονηρὸν, ἐπὶ τοὺς ἀνθρώπους τοὺς ἔχοντας τὸ χάραγμα τοῦ θηρίου, καὶ τοὺς προσκυνοῦντας τῇ εἰκόνι αὐτοῦ.
66016003 Καὶ ὁ δεύτερος ἐξέχεεν τὴν φιάλην αὐτοῦ εἰς τὴν θάλασσαν, καὶ ἐγένετο αἷμα ὡς νεκροῦ, καὶ πᾶσα ψυχὴ ζωῆς ἀπέθανεν τὰ ἐν τῇ θαλάσσῃ.
66016004 Καὶ ὁ τρίτος ἐξέχεεν τὴν φιάλην αὐτοῦ εἰς τοὺς ποταμοὺς καὶ τὰς πηγὰς τῶν ὑδάτων, καὶ ἐγένετο αἷμα.
66016005 Καὶ ἤκουσα τοῦ ἀγγέλου τῶν ὑδάτων λέγοντος, “Δίκαιος εἶ, ὁ ὢν, καὶ ὁ ἦν, ὅσιος, ὅτι ταῦτα ἔκρινας.
66016006 Ὅτι αἷμα ἁγίων καὶ προφητῶν ἐξέχεαν, καὶ αἷμα αὐτοῖς δέδωκας πεῖν· ἄξιοί εἰσιν.”
66016007 Καὶ ἤκουσα τοῦ θυσιαστηρίου λέγοντος, “Ναί, ˚Κύριε ὁ ˚Θεός ὁ Παντοκράτωρ, ἀληθιναὶ καὶ δίκαιαι αἱ κρίσεις σου.”
66016008 Καὶ ὁ τέταρτος ἐξέχεεν τὴν φιάλην αὐτοῦ ἐπὶ τὸν ἥλιον, καὶ ἐδόθη αὐτῷ καυματίσαι τοὺς ἀνθρώπους ἐν πυρί.
66016009 Καὶ ἐκαυματίσθησαν οἱ ἄνθρωποι καῦμα μέγα, καὶ ἐβλασφήμησαν τὸ ὄνομα τοῦ ˚Θεοῦ, τοῦ ἔχοντος τὴν ἐξουσίαν ἐπὶ τὰς πληγὰς ταύτας, καὶ οὐ μετενόησαν δοῦναι αὐτῷ δόξαν.
66016010 Καὶ ὁ πέμπτος ἐξέχεεν τὴν φιάλην αὐτοῦ ἐπὶ τὸν θρόνον τοῦ θηρίου, καὶ ἐγένετο ἡ βασιλεία αὐτοῦ ἐσκοτωμένη, καὶ ἐμασῶντο τὰς γλώσσας αὐτῶν ἐκ τοῦ πόνου,
66016011 καὶ ἐβλασφήμησαν τὸν ˚Θεὸν τοῦ οὐρανοῦ ἐκ τῶν πόνων αὐτῶν, καὶ ἐκ τῶν ἑλκῶν αὐτῶν, καὶ οὐ μετενόησαν ἐκ τῶν ἔργων αὐτῶν.
66016012 Καὶ ὁ ἕκτος ἐξέχεεν τὴν φιάλην αὐτοῦ ἐπὶ τὸν ποταμὸν τὸν μέγαν τὸν Εὐφράτην, καὶ ἐξηράνθη τὸ ὕδωρ αὐτοῦ, ἵνα ἑτοιμασθῇ ἡ ὁδὸς τῶν βασιλέων τῶν ἀπὸ ἀνατολῆς ἡλίου.
66016013 Καὶ εἶδον ἐκ τοῦ στόματος τοῦ δράκοντος, καὶ ἐκ τοῦ στόματος τοῦ θηρίου, καὶ ἐκ τοῦ στόματος τοῦ ψευδοπροφήτου, πνεύματα τρία ἀκάθαρτα ὡς βάτραχοι·
66016014 εἰσὶν γὰρ πνεύματα δαιμονίων ποιοῦντα σημεῖα, ἐκπορεύεσθαι ἐπὶ τοὺς βασιλεῖς τῆς οἰκουμένης ὅλης, συναγαγεῖν αὐτοὺς εἰς τὸν πόλεμον τῆς μεγάλης ἡμέρας τοῦ ˚Θεοῦ τοῦ Παντοκράτορος.
66016015 ( “Ἰδοὺ, ἔρχομαι ὡς κλέπτης. Μακάριος ὁ γρηγορῶν καὶ τηρῶν τὰ ἱμάτια αὐτοῦ, ἵνα μὴ γυμνὸς περιπατῇ, καὶ βλέπωσιν τὴν ἀσχημοσύνην αὐτοῦ”. )
66016016 Καὶ συνήγαγεν αὐτοὺς εἰς τὸν τόπον τὸν καλούμενον Ἑβραϊστὶ Ἁρμαγεδών.
66016017 Καὶ ὁ ἕβδομος ἐξέχεεν τὴν φιάλην αὐτοῦ ἐπὶ τὸν ἀέρα, καὶ ἐξῆλθεν φωνὴ μεγάλη ἐκ τοῦ ναοῦ ἀπὸ τοῦ θρόνου λέγουσα, “Γέγονεν!”
66016018 Καὶ ἐγένοντο ἀστραπαὶ, καὶ φωναὶ, καὶ βρονταί, καὶ σεισμὸς ἐγένετο μέγας, οἷος οὐκ ἐγένετο, ἀφʼ οὗ ἄνθρωπος ἐγένοντο ἐπὶ τῆς γῆς, τηλικοῦτος σεισμὸς οὕτω μέγας.
66016019 Καὶ ἐγένετο ἡ πόλις ἡ μεγάλη εἰς τρία μέρη, καὶ αἱ πόλεις τῶν ἐθνῶν ἔπεσαν, καὶ Βαβυλὼν ἡ μεγάλη ἐμνήσθη ἐνώπιον τοῦ ˚Θεοῦ, δοῦναι αὐτῇ τὸ ποτήριον τοῦ οἴνου τοῦ θυμοῦ τῆς ὀργῆς αὐτοῦ.
66016020 Καὶ πᾶσα νῆσος ἔφυγεν, καὶ ὄρη οὐχ εὑρέθησαν.
66016021 Καὶ χάλαζα μεγάλη, ὡς ταλαντιαία, καταβαίνει ἐκ τοῦ οὐρανοῦ ἐπὶ τοὺς ἀνθρώπους, καὶ ἐβλασφήμησαν οἱ ἄνθρωποι τὸν ˚Θεὸν, ἐκ τῆς πληγῆς τῆς χαλάζης, ὅτι μεγάλη ἐστὶν ἡ πληγὴ αὐτῆς σφόδρα.
66017001 Καὶ ἦλθεν εἷς ἐκ τῶν ἑπτὰ ἀγγέλων τῶν ἐχόντων τὰς ἑπτὰ φιάλας, καὶ ἐλάλησεν μετʼ ἐμοῦ λέγων, “Δεῦρο, δείξω σοι τὸ κρίμα τῆς πόρνης τῆς μεγάλης τῆς καθημένης ἐπὶ ὑδάτων πολλῶν,
66017002 μεθʼ ἧς ἐπόρνευσαν οἱ βασιλεῖς τῆς γῆς, καὶ ἐμεθύσθησαν οἱ κατοικοῦντες τὴν γῆν ἐκ τοῦ οἴνου τῆς πορνείας αὐτῆς.”
66017003 Καὶ ἀπήνεγκέν με εἰς ἔρημον ἐν ˚Πνεύματι. Καὶ εἶδον γυναῖκα καθημένην ἐπὶ θηρίον κόκκινον, γέμοντα ὀνόματα βλασφημίας, ἔχων κεφαλὰς ἑπτὰ καὶ κέρατα δέκα.
66017004 Καὶ ἡ γυνὴ ἦν περιβεβλημένη πορφυροῦν καὶ κόκκινον, καὶ κεχρυσωμένη χρυσίῳ, καὶ λίθῳ τιμίῳ, καὶ μαργαρίταις, ἔχουσα ποτήριον χρυσοῦν ἐν τῇ χειρὶ αὐτῆς, γέμον βδελυγμάτων καὶ τὰ ἀκάθαρτα τῆς πορνείας αὐτῆς,
66017005 καὶ ἐπὶ τὸ μέτωπον αὐτῆς ὄνομα γεγραμμένον, “Μυστήριον Βαβυλὼν ἡ Μεγάλη, ἡ μήτηρ τῶν πορνῶν, καὶ τῶν βδελυγμάτων τῆς γῆς.”
66017006 Καὶ εἶδον τὴν γυναῖκα μεθύουσαν ἐκ τοῦ αἵματος τῶν ἁγίων, καὶ ἐκ τοῦ αἵματος τῶν μαρτύρων ˚Ἰησοῦ. Καὶ ἐθαύμασα, ἰδὼν αὐτὴν θαῦμα μέγα.
66017007 Καὶ εἶπέν μοι ὁ ἄγγελος, “Διὰ τί ἐθαύμασας; Ἐγὼ ἐρῶ σοι τὸ μυστήριον τῆς γυναικὸς, καὶ τοῦ θηρίου τοῦ βαστάζοντος αὐτήν, τοῦ ἔχοντος τὰς ἑπτὰ κεφαλὰς καὶ τὰ δέκα κέρατα.
66017008 Τὸ θηρίον ὃ εἶδες ἦν, καὶ οὐκ ἔστιν, καὶ μέλλει ἀναβαίνειν ἐκ τῆς Ἀβύσσου, καὶ εἰς ἀπώλειαν ὑπάγειν. Καὶ θαυμασθήσονται οἱ κατοικοῦντες ἐπὶ τῆς γῆς, ὧν οὐ γέγραπται τὰ ὀνόματα ἐπὶ τὸ βιβλίον τῆς ζωῆς ἀπὸ καταβολῆς κόσμου, βλεπόντων τὸ θηρίον, ὅτι ἦν καὶ οὐκ ἔστιν καὶ παρέσται.
66017009 Ὧδε ὁ νοῦς ὁ ἔχων σοφίαν: αἱ ἑπτὰ κεφαλαὶ ἑπτὰ ὄρη εἰσίν, ὅπου ἡ γυνὴ κάθηται ἐπʼ αὐτῶν. Καὶ βασιλεῖς ἑπτά εἰσιν:
66017010 οἱ πέντε ἔπεσαν, ὁ εἷς ἔστιν, ὁ ἄλλος οὔπω ἦλθεν, καὶ ὅταν ἔλθῃ, ὀλίγον αὐτὸν δεῖ μεῖναι
66017011 καὶ τὸ θηρίον ὃ ἦν, καὶ οὐκ ἔστιν, καὶ αὐτὸς ὄγδοός ἐστιν, καὶ ἐκ τῶν ἑπτά ἐστιν, καὶ εἰς ἀπώλειαν ὑπάγει.
66017012 Καὶ τὰ δέκα κέρατα ἃ εἶδες, δέκα βασιλεῖς εἰσιν, οἵτινες βασιλείαν οὕτω ἔλαβον, ἀλλὰ ἐξουσίαν ὡς βασιλεῖς μίαν ὥραν λαμβάνουσιν μετὰ τοῦ θηρίου.
66017013 Οὗτοι μίαν γνώμην ἔχουσιν, καὶ τὴν δύναμιν καὶ τὴν ἐξουσίαν αὐτῶν τῷ θηρίῳ διδόασιν.
66017014 Οὗτοι μετὰ τοῦ Ἀρνίου πολεμήσουσιν, καὶ τὸ Ἀρνίον νικήσει αὐτούς, ὅτι ˚Κύριος κυρίων ἐστὶν καὶ Βασιλεὺς βασιλέων, καὶ οἱ μετʼ αὐτοῦ, κλητοὶ, καὶ ἐκλεκτοὶ, καὶ πιστοί.”
66017015 Καὶ λέγει μοι, “Τὰ ὕδατα ἃ εἶδες, οὗ ἡ πόρνη κάθηται, λαοὶ καὶ ὄχλοι εἰσὶν, καὶ ἔθνη καὶ γλῶσσαι.
66017016 Καὶ τὰ δέκα κέρατα ἃ εἶδες καὶ τὸ θηρίον, οὗτοι μισήσουσιν τὴν πόρνην, καὶ ἠρημωμένην ποιήσουσιν αὐτὴν καὶ γυμνήν, καὶ τὰς σάρκας αὐτῆς φάγονται, καὶ αὐτὴν κατακαύσουσιν ἐν πυρί.
66017017 Ὁ γὰρ ˚Θεὸς ἔδωκεν εἰς τὰς καρδίας αὐτῶν ποιῆσαι τὴν γνώμην αὐτοῦ, καὶ ποιῆσαι μίαν γνώμην, καὶ δοῦναι τὴν βασιλείαν αὐτῶν τῷ θηρίῳ, ἄχρι τελεσθήσονται οἱ λόγοι τοῦ ˚Θεοῦ.
66017018 Καὶ ἡ γυνὴ ἣν εἶδες ἔστιν ἡ πόλις ἡ μεγάλη, ἡ ἔχουσα βασιλείαν ἐπὶ τῶν βασιλέων τῆς γῆς.”
66018001 Μετὰ ταῦτα εἶδον ἄλλον ἄγγελον καταβαίνοντα ἐκ τοῦ οὐρανοῦ, ἔχοντα ἐξουσίαν μεγάλην, καὶ ἡ γῆ ἐφωτίσθη ἐκ τῆς δόξης αὐτοῦ.
66018002 Καὶ ἔκραξεν ἐν ἰσχυρᾷ φωνῇ λέγων, “Ἔπεσεν, ἔπεσεν Βαβυλὼν ἡ μεγάλη! Καὶ ἐγένετο κατοικητήριον δαιμονίων, καὶ φυλακὴ παντὸς πνεύματος ἀκαθάρτου, καὶ φυλακὴ παντὸς ὀρνέου ἀκαθάρτου, καὶ μεμισημένου.
66018003 Ὅτι ἐκ τοῦ οἴνου τοῦ θυμοῦ τῆς πορνείας αὐτῆς, πέπωκαν πάντα τὰ ἔθνη, καὶ οἱ βασιλεῖς τῆς γῆς μετʼ αὐτῆς ἐπόρνευσαν, καὶ οἱ ἔμποροι τῆς γῆς ἐκ τῆς δυνάμεως τοῦ στρήνους αὐτῆς ἐπλούτησαν.”
66018004 Καὶ ἤκουσα ἄλλην φωνὴν ἐκ τοῦ οὐρανοῦ λέγουσαν, “Ἐξέλθατε ὁ λαός μου ἐξ αὐτῆς, ἵνα μὴ συγκοινωνήσητε ταῖς ἁμαρτίαις αὐτῆς, καὶ ἐκ τῶν πληγῶν αὐτῆς, ἵνα μὴ λάβητε,
66018005 ὅτι ἐκολλήθησαν αὐτῆς αἱ ἁμαρτίαι ἄχρι τοῦ οὐρανοῦ, καὶ ἐμνημόνευσεν ὁ ˚Θεὸς τὰ ἀδικήματα αὐτῆς.
66018006 Ἀπόδοτε αὐτῇ ὡς καὶ αὐτὴ ἀπέδωκεν, καὶ διπλώσατε τὰ διπλᾶ, κατὰ τὰ ἔργα αὐτῆς· ἐν τῷ ποτηρίῳ ᾧ ἐκέρασεν, κεράσατε αὐτῇ διπλοῦν,
66018007 ὅσα ἐδόξασεν αὑτὴν καὶ ἐστρηνίασεν, τοσοῦτον δότε αὐτῇ βασανισμὸν καὶ πένθος. Ὅτι ἐν τῇ καρδίᾳ αὐτῆς λέγει, ὅτι ‘Κάθημαι βασίλισσα… καὶ χήρα οὐκ εἰμί καὶ πένθος οὐ μὴ ἴδω.’
66018008 Διὰ τοῦτο, ἐν μιᾷ ἡμέρᾳ ἥξουσιν αἱ πληγαὶ αὐτῆς, θάνατος, καὶ πένθος, καὶ λιμός, καὶ ἐν πυρὶ κατακαυθήσεται, ὅτι ἰσχυρὸς ˚Κύριος ὁ ˚Θεὸς ὁ κρίνας αὐτήν.
66018009 Καὶ κλαύσουσιν καὶ κόψονται ἐπʼ αὐτὴν οἱ βασιλεῖς τῆς γῆς, οἱ μετʼ αὐτῆς πορνεύσαντες καὶ στρηνιάσαντες, ὅταν βλέπωσιν τὸν καπνὸν τῆς πυρώσεως αὐτῆς,
66018010 ἀπὸ μακρόθεν ἑστηκότες, διὰ τὸν φόβον τοῦ βασανισμοῦ αὐτῆς λέγοντες, ‘Οὐαὶ, οὐαί, ἡ πόλις ἡ μεγάλη Βαβυλὼν, ἡ πόλις ἡ ἰσχυρά! Ὅτι μιᾷ ὥρᾳ ἦλθεν ἡ κρίσις σου.’
66018011 Καὶ οἱ ἔμποροι τῆς γῆς κλαίουσιν καὶ πενθοῦσιν ἐπʼ αὐτήν, ὅτι τὸν γόμον αὐτῶν οὐδεὶς ἀγοράζει οὐκέτι–
66018012 γόμον χρυσοῦ, καὶ ἀργύρου, καὶ λίθου τιμίου, καὶ μαργαριτῶν, καὶ βυσσίνου, καὶ πορφύρας, καὶ σιρικοῦ, καὶ κοκκίνου, καὶ πᾶν ξύλον θύϊνον, καὶ πᾶν σκεῦος ἐλεφάντινον, καὶ πᾶν σκεῦος ἐκ ξύλου τιμιωτάτου, καὶ χαλκοῦ, καὶ σιδήρου, καὶ μαρμάρου,
66018013 καὶ κιννάμωμον, καὶ ἄμωμον, καὶ θυμιάματα, καὶ μύρον, καὶ λίβανον, καὶ οἶνον, καὶ ἔλαιον, καὶ σεμίδαλιν, καὶ σῖτον, καὶ κτήνη, καὶ πρόβατα, καὶ ἵππων, καὶ ῥεδῶν, καὶ σωμάτων, καὶ ψυχὰς ἀνθρώπων.
66018014 Καὶ ἡ ὀπώρα σου τῆς ἐπιθυμίας τῆς ψυχῆς ἀπῆλθεν ἀπὸ σοῦ, καὶ πάντα τὰ λιπαρὰ καὶ τὰ λαμπρὰ ἀπώλετο ἀπὸ σοῦ, καὶ οὐκέτι οὐ μὴ αὐτὰ εὑρήσουσιν.
66018015 Οἱ ἔμποροι τούτων οἱ πλουτήσαντες, ἀπʼ αὐτῆς ἀπὸ μακρόθεν στήσονται, διὰ τὸν φόβον τοῦ βασανισμοῦ αὐτῆς, κλαίοντες καὶ πενθοῦντες
66018016 λέγοντες, ‘Οὐαὶ, οὐαί, ἡ πόλις ἡ μεγάλη, ἡ περιβεβλημένη βύσσινον, καὶ πορφυροῦν, καὶ κόκκινον, καὶ κεχρυσωμένη ἐν χρυσίῳ, καὶ λίθῳ τιμίῳ, καὶ μαργαρίτῃ!
66018017 Ὅτι μιᾷ ὥρᾳ ἠρημώθη ὁ τοσοῦτος πλοῦτος.’ Καὶ πᾶς κυβερνήτης, καὶ πᾶς ὁ ἐπὶ τόπον πλέων, καὶ ναῦται, καὶ ὅσοι τὴν θάλασσαν ἐργάζονται, ἀπὸ μακρόθεν ἔστησαν
66018018 καὶ ἔκραξαν, βλέποντες τὸν καπνὸν τῆς πυρώσεως αὐτῆς λέγοντες, ‘Τίς ὁμοία τῇ πόλει τῇ μεγάλῃ;’
66018019 Καὶ ἔβαλον χοῦν ἐπὶ τὰς κεφαλὰς αὐτῶν, καὶ ἔκραξαν, κλαίοντες καὶ πενθοῦντες λέγοντες, ‘Οὐαὶ, οὐαί, ἡ πόλις ἡ μεγάλη, ἐν ᾗ ἐπλούτησαν πάντες οἱ ἔχοντες τὰ πλοῖα ἐν τῇ θαλάσσῃ ἐκ τῆς τιμιότητος αὐτῆς! Ὅτι μιᾷ ὥρᾳ ἠρημώθη.’
66018020 Εὐφραίνου ἐπʼ αὐτῇ, οὐρανέ, καὶ οἱ ἅγιοι, καὶ οἱ ἀπόστολοι, καὶ οἱ προφῆται, ὅτι ἔκρινεν ὁ ˚Θεὸς τὸ κρίμα ὑμῶν ἐξ αὐτῆς.”
66018021 Καὶ ἦρεν εἷς ἄγγελος ἰσχυρὸς λίθον ὡς λίθον μέγαν, καὶ ἔβαλεν εἰς τὴν θάλασσαν λέγων, “Οὕτως ὁρμήματι βληθήσεται Βαβυλὼν ἡ μεγάλη πόλις, καὶ οὐ μὴ εὑρεθῇ ἔτι.
66018022 Καὶ φωνὴ κιθαρῳδῶν, καὶ μουσικῶν, καὶ αὐλητῶν, καὶ σαλπιστῶν, οὐ μὴ ἀκουσθῇ ἐν σοὶ ἔτι, καὶ πᾶς τεχνίτης πάσης τέχνης οὐ μὴ εὑρεθῇ ἐν σοὶ ἔτι, καὶ φωνὴ μύλου οὐ μὴ ἀκουσθῇ ἐν σοὶ ἔτι,
66018023 καὶ φῶς λύχνου οὐ μὴ φάνῃ ἐν σοὶ ἔτι, καὶ φωνὴ νυμφίου καὶ νύμφης οὐ μὴ ἀκουσθῇ ἐν σοὶ ἔτι· ὅτι οἱ ἔμποροί σου ἦσαν οἱ μεγιστᾶνες τῆς γῆς, ὅτι ἐν τῇ φαρμακείᾳ σου ἐπλανήθησαν πάντα τὰ ἔθνη.
66018024 Καὶ ἐν αὐτῇ αἷμα προφητῶν καὶ ἁγίων εὑρέθη, καὶ πάντων τῶν ἐσφαγμένων ἐπὶ τῆς γῆς.”
66019001 Μετὰ ταῦτα ἤκουσα ὡς φωνὴν μεγάλην ὄχλου πολλοῦ ἐν τῷ οὐρανῷ λεγόντων, “Ἁλληλουϊά! Ἡ σωτηρία, καὶ ἡ δόξα, καὶ ἡ δύναμις, τοῦ ˚Θεοῦ ἡμῶν!
66019002 ‘Ὅτι ἀληθιναὶ καὶ δίκαιαι αἱ κρίσεις αὐτοῦ’, ὅτι ἔκρινεν τὴν πόρνην τὴν μεγάλην, ἥτις ἔφθειρεν τὴν γῆν ἐν τῇ πορνείᾳ αὐτῆς, καὶ ‘ἐξεδίκησεν τὸ αἷμα τῶν δούλων αὐτοῦ ἐκ χειρὸς αὐτῆς’.”
66019003 Καὶ δεύτερον εἴρηκαν, “Ἁλληλουϊά! Καὶ ‘ὁ καπνὸς αὐτῆς ἀναβαίνει εἰς τοὺς αἰῶνας τῶν αἰώνων’.”
66019004 Καὶ ἔπεσαν οἱ πρεσβύτεροι οἱ εἴκοσι τέσσαρες, καὶ τὰ τέσσαρα ζῷα, καὶ προσεκύνησαν τῷ ˚Θεῷ, τῷ καθημένῳ ἐπὶ τῷ θρόνῳ λέγοντες, “Ἀμήν! Ἁλληλουϊά!”
66019005 Καὶ φωνὴ ἀπὸ τοῦ θρόνου ἐξῆλθεν λέγουσα, “Αἰνεῖτε τῷ ˚Θεῷ ἡμῶν, πάντες οἱ δοῦλοι αὐτοῦ, οἱ φοβούμενοι αὐτόν, οἱ μικροὶ καὶ οἱ μεγάλοι!”
66019006 Καὶ ἤκουσα ὡς φωνὴν ὄχλου πολλοῦ, καὶ ὡς φωνὴν ὑδάτων πολλῶν, καὶ ὡς φωνὴν βροντῶν ἰσχυρῶν λεγόντων, “Ἁλληλουϊά, ὅτι ἐβασίλευσεν ˚Κύριος, ὁ ˚Θεὸς ἡμῶν ὁ Παντοκράτωρ.
66019007 Χαίρωμεν καὶ ἀγαλλιῶμεν, καὶ δῶμεν τὴν δόξαν αὐτῷ, ὅτι ἦλθεν ὁ γάμος τοῦ Ἀρνίου, καὶ ἡ γυνὴ αὐτοῦ ἡτοίμασεν ἑαυτήν.”
66019008 Καὶ ἐδόθη αὐτῇ, ἵνα περιβάληται βύσσινον λαμπρὸν καθαρόν, τὸ γὰρ βύσσινον τὰ δικαιώματα τῶν ἁγίων ἐστίν.
66019009 Καὶ λέγει μοι, “Γράψον, ‘Μακάριοι οἱ εἰς τὸ δεῖπνον τοῦ γάμου τοῦ Ἀρνίου κεκλημένοι.’” Καὶ λέγει μοι, “Οὗτοι οἱ λόγοι ἀληθινοὶ τοῦ ˚Θεοῦ εἰσιν.”
66019010 Καὶ ἔπεσα ἔμπροσθεν τῶν ποδῶν αὐτοῦ προσκυνῆσαι αὐτῷ. Καὶ λέγει μοι, “Ὅρα μή· σύνδουλός σού εἰμι, καὶ τῶν ἀδελφῶν σου τῶν ἐχόντων τὴν μαρτυρίαν ˚Ἰησοῦ· τῷ ˚Θεῷ προσκύνησον. Ἡ γὰρ μαρτυρία ˚Ἰησοῦ ἐστιν τὸ πνεῦμα τῆς προφητείας.”
66019011 Καὶ εἶδον τὸν οὐρανὸν ἠνεῳγμένον, καὶ ἰδοὺ, ἵππος λευκός, καὶ ὁ καθήμενος ἐπʼ αὐτὸν, Πιστὸς καλούμενος καὶ Ἀληθινός, καὶ ἐν δικαιοσύνῃ κρίνει καὶ πολεμεῖ.
66019012 Οἱ δὲ ὀφθαλμοὶ αὐτοῦ φλὸξ πυρός, καὶ ἐπὶ τὴν κεφαλὴν αὐτοῦ διαδήματα πολλά, ἔχων ὄνομα γεγραμμένον, ὃ οὐδεὶς οἶδεν, εἰ μὴ αὐτός,
66019013 καὶ περιβεβλημένος ἱμάτιον βεβαμμένον αἵματι, καὶ κέκληται τὸ ὄνομα αὐτοῦ, ὁ Λόγος τοῦ ˚Θεοῦ.
66019014 Καὶ τὰ στρατεύματα ἐν τῷ οὐρανῷ ἠκολούθει αὐτῷ ἐφʼ ἵπποις λευκοῖς, ἐνδεδυμένοι βύσσινον λευκὸν καθαρόν.
66019015 Καὶ ἐκ τοῦ στόματος αὐτοῦ ἐκπορεύεται ῥομφαία ὀξεῖα, ἵνα ἐν αὐτῇ πατάξῃ τὰ ἔθνη, “καὶ αὐτὸς ποιμανεῖ αὐτοὺς ἐν ῥάβδῳ σιδηρᾷ”· καὶ αὐτὸς πατεῖ τὴν ληνὸν τοῦ οἴνου τοῦ θυμοῦ τῆς ὀργῆς τοῦ ˚Θεοῦ τοῦ Παντοκράτορος.
66019016 Καὶ ἔχει ἐπὶ τὸ ἱμάτιον καὶ ἐπὶ τὸν μηρὸν αὐτοῦ, ὄνομα γεγραμμένον, “Βασιλεὺς βασιλέων καὶ ˚Κύριος κυρίων.”
66019017 Καὶ εἶδον ἕνα ἄγγελον ἑστῶτα ἐν τῷ ἡλίῳ, καὶ ἔκραξεν ἐν φωνῇ μεγάλῃ, λέγων πᾶσι τοῖς ὀρνέοις τοῖς πετομένοις ἐν μεσουρανήματι, “Δεῦτε, συνάχθητε εἰς τὸ δεῖπνον τὸ μέγα τοῦ ˚Θεοῦ,
66019018 ἵνα φάγητε σάρκας βασιλέων, καὶ σάρκας χιλιάρχων, καὶ σάρκας ἰσχυρῶν, καὶ σάρκας ἵππων, καὶ τῶν καθημένων ἐπʼ αὐτούς, καὶ σάρκας πάντων, ἐλευθέρων τε καὶ δούλων, καὶ μικρῶν καὶ μεγάλων.”
66019019 Καὶ εἶδον τὸ θηρίον, καὶ τοὺς βασιλεῖς τῆς γῆς, καὶ τὰ στρατεύματα αὐτῶν, συνηγμένα ποιῆσαι τὸν πόλεμον μετὰ τοῦ καθημένου ἐπὶ τοῦ ἵππου, καὶ μετὰ τοῦ στρατεύματος αὐτοῦ.
66019020 Καὶ ἐπιάσθη τὸ θηρίον, καὶ μετʼ αὐτοῦ ὁ ψευδοπροφήτης, ὁ ποιήσας τὰ σημεῖα ἐνώπιον αὐτοῦ, ἐν οἷς ἐπλάνησεν τοὺς λαβόντας τὸ χάραγμα τοῦ θηρίου, καὶ τοὺς προσκυνοῦντας τῇ εἰκόνι αὐτοῦ. Ζῶντες ἐβλήθησαν οἱ δύο εἰς τὴν λίμνην τοῦ πυρὸς, τῆς καιομένης ἐν θείῳ.
66019021 Καὶ οἱ λοιποὶ ἀπεκτάνθησαν ἐν τῇ ῥομφαίᾳ τοῦ καθημένου ἐπὶ τοῦ ἵππου, τῇ ἐξελθούσῃ ἐκ τοῦ στόματος αὐτοῦ, καὶ πάντα τὰ ὄρνεα ἐχορτάσθησαν ἐκ τῶν σαρκῶν αὐτῶν.
66020001 Καὶ εἶδον ἄγγελον καταβαίνοντα ἐκ τοῦ οὐρανοῦ, ἔχοντα τὴν κλεῖν τῆς Ἀβύσσου, καὶ ἅλυσιν μεγάλην ἐπὶ τὴν χεῖρα αὐτοῦ.
66020002 Καὶ ἐκράτησεν τὸν δράκοντα, τὸν ὄφιν τὸν ἀρχαῖον, ὅς ἐστιν Διάβολος καὶ ὁ Σατανᾶς, καὶ ἔδησεν αὐτὸν χίλια ἔτη,
66020003 καὶ ἔβαλεν αὐτὸν εἰς τὴν Ἄβυσσον, καὶ ἔκλεισεν καὶ ἐσφράγισεν ἐπάνω αὐτοῦ, ἵνα μὴ πλανήσῃ ἔτι τὰ ἔθνη, ἄχρι τελεσθῇ τὰ χίλια ἔτη· μετὰ ταῦτα, δεῖ αὐτὸν λυθῆναι μικρὸν χρόνον.
66020004 Καὶ εἶδον θρόνους, καὶ ἐκάθισαν ἐπʼ αὐτούς, καὶ κρίμα ἐδόθη αὐτοῖς, καὶ τὰς ψυχὰς τῶν πεπελεκισμένων διὰ τὴν μαρτυρίαν ˚Ἰησοῦ, καὶ διὰ τὸν λόγον τοῦ ˚Θεοῦ, καὶ οἵτινες οὐ προσεκύνησαν τὸ θηρίον, οὐδὲ τὴν εἰκόνα αὐτοῦ, καὶ οὐκ ἔλαβον τὸ χάραγμα ἐπὶ τὸ μέτωπον, καὶ ἐπὶ τὴν χεῖρα αὐτῶν· καὶ ἔζησαν καὶ ἐβασίλευσαν μετὰ τοῦ ˚Χριστοῦ χίλια ἔτη.
66020005 Οἱ λοιποὶ τῶν νεκρῶν οὐκ ἔζησαν, ἄχρι τελεσθῇ τὰ χίλια ἔτη. Αὕτη ἡ ἀνάστασις ἡ πρώτη.
66020006 Μακάριος καὶ ἅγιος ὁ ἔχων μέρος ἐν τῇ ἀναστάσει τῇ πρώτῃ! Ἐπὶ τούτων ὁ δεύτερος θάνατος οὐκ ἔχει ἐξουσίαν, ἀλλʼ ἔσονται ἱερεῖς τοῦ ˚Θεοῦ καὶ τοῦ ˚Χριστοῦ, καὶ βασιλεύσουσιν μετʼ αὐτοῦ τὰ χίλια ἔτη.
66020007 Καὶ ὅταν τελεσθῇ τὰ χίλια ἔτη, λυθήσεται ὁ Σατανᾶς ἐκ τῆς φυλακῆς αὐτοῦ,
66020008 καὶ ἐξελεύσεται πλανῆσαι τὰ ἔθνη τὰ ἐν ταῖς τέσσαρσιν γωνίαις τῆς γῆς, τὸν Γὼγ καὶ Μαγώγ, συναγαγεῖν αὐτοὺς εἰς τὸν πόλεμον, ὧν ὁ ἀριθμὸς αὐτῶν ὡς ἡ ἄμμος τῆς θαλάσσης.
66020009 Καὶ ἀνέβησαν ἐπὶ τὸ πλάτος τῆς γῆς, καὶ ἐκύκλωσαν τὴν παρεμβολὴν τῶν ἁγίων, καὶ τὴν πόλιν τὴν ἠγαπημένην. Καὶ κατέβη πῦρ ἐκ τοῦ οὐρανοῦ καὶ κατέφαγεν αὐτούς.
66020010 Καὶ ὁ διάβολος, ὁ πλανῶν αὐτοὺς, ἐβλήθη εἰς τὴν λίμνην τοῦ πυρὸς καὶ θείου, ὅπου καὶ τὸ θηρίον καὶ ὁ ψευδοπροφήτης, καὶ βασανισθήσονται ἡμέρας καὶ νυκτὸς εἰς τοὺς αἰῶνας τῶν αἰώνων.
66020011 Καὶ εἶδον θρόνον μέγαν λευκὸν, καὶ τὸν καθήμενον ἐπʼ αὐτοῦ, οὗ ἀπὸ τοῦ προσώπου ἔφυγεν ἡ γῆ καὶ ὁ οὐρανός, καὶ τόπος οὐχ εὑρέθη αὐτοῖς.
66020012 Καὶ εἶδον τοὺς νεκρούς, τοὺς μεγάλους καὶ τοὺς μικρούς, ἑστῶτας ἐνώπιον τοῦ θρόνου, καὶ βιβλία ἠνοίχθησαν· καὶ ἄλλο βιβλίον ἠνοίχθη, ὅ ἐστιν τῆς ζωῆς. Καὶ ἐκρίθησαν οἱ νεκροὶ ἐκ τῶν γεγραμμένων ἐν τοῖς βιβλίοις κατὰ τὰ ἔργα αὐτῶν.
66020013 Καὶ ἔδωκεν ἡ θάλασσα τοὺς νεκροὺς τοὺς ἐν αὐτῇ, καὶ ὁ θάνατος καὶ ὁ ᾅδης ἔδωκαν τοὺς νεκροὺς τοὺς ἐν αὐτοῖς, καὶ ἐκρίθησαν ἕκαστος κατὰ τὰ ἔργα αὐτῶν.
66020014 Καὶ ὁ θάνατος καὶ ὁ ᾅδης ἐβλήθησαν εἰς τὴν λίμνην τοῦ πυρός. Οὗτος ὁ θάνατος ὁ δεύτερός ἐστιν, ἡ λίμνη τοῦ πυρός.
66020015 Καὶ εἴ τις οὐχ εὑρέθη ἐν τῇ βίβλῳ τῆς ζωῆς γεγραμμένος, ἐβλήθη εἰς τὴν λίμνην τοῦ πυρός.
66021001 Καὶ εἶδον οὐρανὸν καινὸν καὶ γῆν καινήν· ὁ γὰρ πρῶτος οὐρανὸς καὶ ἡ πρώτη γῆ ἀπῆλθαν, καὶ ἡ θάλασσα οὐκ ἔστιν ἔτι.
66021002 Καὶ τὴν πόλιν τὴν ἁγίαν, Ἰερουσαλὴμ καινὴν, εἶδον καταβαίνουσαν ἐκ τοῦ οὐρανοῦ ἀπὸ τοῦ ˚Θεοῦ, ἡτοιμασμένην ὡς νύμφην, κεκοσμημένην τῷ ἀνδρὶ αὐτῆς.
66021003 Καὶ ἤκουσα φωνῆς μεγάλης ἐκ τοῦ θρόνου λεγούσης, “Ἰδοὺ, ἡ σκηνὴ τοῦ ˚Θεοῦ μετὰ τῶν ἀνθρώπων, καὶ σκηνώσει μετʼ αὐτῶν, καὶ αὐτοὶ λαοὶ αὐτοῦ ἔσονται, καὶ αὐτὸς ὁ ˚Θεὸς ἔσται μετʼ αὐτῶν,
66021004 καὶ ἐξαλείψει πᾶν δάκρυον ἐκ τῶν ὀφθαλμῶν αὐτῶν, καὶ ὁ θάνατος οὐκ ἔσται ἔτι, οὔτε πένθος, οὔτε κραυγὴ, οὔτε πόνος, οὐκ ἔσται ἔτι. Τὰ πρῶτα ἀπῆλθαν.”
66021005 Καὶ εἶπεν ὁ καθήμενος ἐπὶ τῷ θρόνῳ, “Ἰδοὺ, καινὰ ποιῶ πάντα.” Καὶ λέγει μοι, “Γράψον, ὅτι οὗτοι οἱ λόγοι πιστοὶ καὶ ἀληθινοί εἰσιν.”
66021006 Καὶ εἶπέν μοι, “Γέγοναν! Ἐγὼ τὸ Ἄλφα καὶ τὸ Ὦ, ἡ ἀρχὴ καὶ τὸ τέλος. Ἐγὼ τῷ διψῶντι δώσω ἐκ τῆς πηγῆς τοῦ ὕδατος τῆς ζωῆς δωρεάν.
66021007 Ὁ νικῶν κληρονομήσει ταῦτα, καὶ ἔσομαι αὐτῷ ˚Θεὸς, καὶ αὐτὸς ἔσται μοι υἱός.
66021008 Τοῖς δὲ δειλοῖς, καὶ ἀπίστοις, καὶ ἐβδελυγμένοις, καὶ φονεῦσι, καὶ πόρνοις, καὶ φαρμάκοις, καὶ εἰδωλολάτραις, καὶ πᾶσι τοῖς ψευδέσιν, τὸ μέρος αὐτῶν ἐν τῇ λίμνῃ τῇ καιομένῃ πυρὶ καὶ θείῳ, ὅ ἐστιν ὁ θάνατος ὁ δεύτερος.”
66021009 Καὶ ἦλθεν εἷς ἐκ τῶν ἑπτὰ ἀγγέλων, τῶν ἐχόντων τὰς ἑπτὰ φιάλας, τῶν γεμόντων τῶν ἑπτὰ πληγῶν τῶν ἐσχάτων, καὶ ἐλάλησεν μετʼ ἐμοῦ λέγων, “Δεῦρο, δείξω σοι τὴν νύμφην, τὴν γυναῖκα τοῦ Ἀρνίου.”
66021010 Καὶ ἀπήνεγκέν με ἐν ˚Πνεύματι ἐπὶ ὄρος μέγα καὶ ὑψηλόν, καὶ ἔδειξέν μοι τὴν πόλιν τὴν ἁγίαν Ἰερουσαλὴμ, καταβαίνουσαν ἐκ τοῦ οὐρανοῦ ἀπὸ τοῦ ˚Θεοῦ,
66021011 ἔχουσαν τὴν δόξαν ἀπὸ τοῦ ˚Θεοῦ. Ὁ φωστὴρ αὐτῆς ὅμοιος λίθῳ τιμιωτάτῳ, ὡς λίθῳ ἰάσπιδι κρυσταλλίζοντι,
66021012 ἔχουσα τεῖχος μέγα καὶ ὑψηλόν, ἔχουσα πυλῶνας δώδεκα, καὶ ἐπὶ τοὺς πυλῶνας ἀγγέλους δώδεκα, καὶ ὀνόματα ἐπιγεγραμμένα, ἅ ἐστιν τῶν δώδεκα φυλῶν υἱῶν Ἰσραήλ.
66021013 Ἀπὸ ἀνατολῆς πυλῶνες τρεῖς, καὶ ἀπὸ βορρᾶ πυλῶνες τρεῖς, καὶ ἀπὸ δυσμῶν πυλῶνες τρεῖς, καὶ ἀπὸ νότου πυλῶνες τρεῖς.
66021014 Καὶ τὸ τεῖχος τῆς πόλεως ἔχων θεμελίους δώδεκα, καὶ ἐπʼ αὐτῶν δώδεκα ὀνόματα τῶν δώδεκα ἀποστόλων τοῦ Ἀρνίου.
66021015 Καὶ ὁ λαλῶν μετʼ ἐμοῦ εἶχεν μέτρον κάλαμον χρυσοῦν, ἵνα μετρήσῃ τὴν πόλιν, καὶ τοὺς πυλῶνας αὐτῆς, καὶ τὸ τεῖχος αὐτῆς.
66021016 Καὶ ἡ πόλις τετράγωνος κεῖται, καὶ τὸ μῆκος αὐτῆς ὅσον τὸ πλάτος. Καὶ ἐμέτρησεν τὴν πόλιν τῷ καλάμῳ ἐπὶ σταδίων δώδεκα χιλιάδων– τὸ μῆκος, καὶ τὸ πλάτος, καὶ τὸ ὕψος αὐτῆς, ἴσα ἐστίν.
66021017 Καὶ ἐμέτρησεν τὸ τεῖχος αὐτῆς, ἑκατὸν τεσσεράκοντα τεσσάρων πηχῶν, μέτρον ἀνθρώπου ὅ ἐστιν ἀγγέλου.
66021018 Καὶ ἦν ἡ ἐνδώμησις τοῦ τείχους αὐτῆς ἴασπις, καὶ ἡ πόλις χρυσίον καθαρὸν, ὅμοιον ὑάλῳ καθαρῷ.
66021019 οἱ θεμέλιοι τοῦ τείχους τῆς πόλεως παντὶ λίθῳ τιμίῳ κεκοσμημένοι. Ὁ θεμέλιος ὁ πρῶτος ἴασπις, ὁ δεύτερος σάπφιρος, ὁ τρίτος χαλκηδών, ὁ τέταρτος σμάραγδος,
66021020 ὁ πέμπτος σαρδόνυξ, ὁ ἕκτος σάρδιον, ὁ ἕβδομος χρυσόλιθος, ὁ ὄγδοος βήρυλλος, ὁ ἔνατος τοπάζιον, ὁ δέκατος χρυσόπρασος, ὁ ἑνδέκατος ὑάκινθος, ὁ δωδέκατος ἀμέθυστος.
66021021 Καὶ οἱ δώδεκα πυλῶνες δώδεκα μαργαρῖται, ἀνὰ εἷς ἕκαστος τῶν πυλώνων ἦν ἐξ ἑνὸς μαργαρίτου. Καὶ ἡ πλατεῖα τῆς πόλεως χρυσίον καθαρὸν ὡς ὕαλος διαυγής.
66021022 Καὶ ναὸν οὐκ εἶδον ἐν αὐτῇ, ὁ γὰρ ˚Κύριος ὁ ˚Θεός ὁ Παντοκράτωρ ναὸς αὐτῆς ἐστιν, καὶ τὸ Ἀρνίον.
66021023 Καὶ ἡ πόλις οὐ χρείαν ἔχει τοῦ ἡλίου οὐδὲ τῆς σελήνης ἵνα φαίνωσιν αὐτῇ, ἡ γὰρ δόξα τοῦ ˚Θεοῦ ἐφώτισεν αὐτήν, καὶ ὁ λύχνος αὐτῆς τὸ Ἀρνίον.
66021024 Καὶ περιπατήσουσιν τὰ ἔθνη διὰ τοῦ φωτὸς αὐτῆς, καὶ οἱ βασιλεῖς τῆς γῆς φέρουσιν τὴν δόξαν αὐτῶν εἰς αὐτήν.
66021025 Καὶ οἱ πυλῶνες αὐτῆς οὐ μὴ κλεισθῶσιν ἡμέρας, νὺξ γὰρ οὐκ ἔσται ἐκεῖ.
66021026 Καὶ οἴσουσιν τὴν δόξαν καὶ τὴν τιμὴν τῶν ἐθνῶν εἰς αὐτήν.
66021027 Καὶ οὐ μὴ εἰσέλθῃ εἰς αὐτὴν πᾶν κοινὸν, καὶ ὁ ποιῶν βδέλυγμα καὶ ψεῦδος, εἰ μὴ οἱ γεγραμμένοι ἐν τῷ βιβλίῳ τῆς ζωῆς τοῦ Ἀρνίου.
66022001 Καὶ ἔδειξέν μοι ποταμὸν ὕδατος ζωῆς λαμπρὸν ὡς κρύσταλλον, ἐκπορευόμενον ἐκ τοῦ θρόνου τοῦ ˚Θεοῦ καὶ τοῦ Ἀρνίου.
66022002 Ἐν μέσῳ τῆς πλατείας αὐτῆς καὶ τοῦ ποταμοῦ, ἐντεῦθεν καὶ ἐκεῖθεν, ξύλον ζωῆς ποιοῦν καρποὺς δώδεκα, κατὰ μῆνα ἕκαστον ἀποδιδοῦν τὸν καρπὸν αὐτοῦ, καὶ τὰ φύλλα τοῦ ξύλου εἰς θεραπείαν τῶν ἐθνῶν.
66022003 Καὶ πᾶν κατάθεμα οὐκ ἔσται ἔτι. Καὶ ὁ θρόνος τοῦ ˚Θεοῦ καὶ τοῦ Ἀρνίου ἐν αὐτῇ ἔσται, καὶ οἱ δοῦλοι αὐτοῦ λατρεύσουσιν αὐτῷ.
66022004 Καὶ ὄψονται τὸ πρόσωπον αὐτοῦ, καὶ τὸ ὄνομα αὐτοῦ ἐπὶ τῶν μετώπων αὐτῶν.
66022005 Καὶ νὺξ οὐκ ἔσται ἔτι, καὶ οὐκ ἔχουσιν χρείαν φωτὸς λύχνου καὶ φωτὸς ἡλίου, ὅτι ˚Κύριος ὁ ˚Θεὸς φωτίσει ἐπʼ αὐτούς, καὶ βασιλεύσουσιν εἰς τοὺς αἰῶνας τῶν αἰώνων.
66022006 Καὶ εἶπέν μοι, “Οὗτοι οἱ λόγοι πιστοὶ καὶ ἀληθινοί, καὶ ὁ ˚Κύριος, ὁ ˚Θεὸς τῶν πνευμάτων τῶν προφητῶν, ἀπέστειλεν τὸν ἄγγελον αὐτοῦ, δεῖξαι τοῖς δούλοις αὐτοῦ ἃ δεῖ γενέσθαι ἐν τάχει.”
66022007 “Καὶ ἰδοὺ, ἔρχομαι ταχύ. Μακάριος ὁ τηρῶν τοὺς λόγους τῆς προφητείας τοῦ βιβλίου τούτου.”
66022008 Κἀγὼ Ἰωάννης ὁ ἀκούων καὶ βλέπων ταῦτα. Καὶ ὅτε ἤκουσα καὶ ἔβλεψα, ἔπεσα προσκυνῆσαι ἔμπροσθεν τῶν ποδῶν τοῦ ἀγγέλου τοῦ δεικνύοντός μοι ταῦτα.
66022009 Καὶ λέγει μοι, “Ὅρα μή· σύνδουλός σού εἰμι καὶ τῶν ἀδελφῶν σου, τῶν προφητῶν, καὶ τῶν τηρούντων τοὺς λόγους τοῦ βιβλίου τούτου. Τῷ ˚Θεῷ προσκύνησον!”
66022010 Καὶ λέγει μοι, “Μὴ σφραγίσῃς τοὺς λόγους τῆς προφητείας τοῦ βιβλίου τούτου, ὁ καιρὸς γὰρ ἐγγύς ἐστιν.
66022011 Ὁ ἀδικῶν, ἀδικησάτω ἔτι, καὶ ὁ ῥυπαρὸς, ῥυπανθήτω ἔτι, καὶ ὁ δίκαιος, δικαιοσύνην ποιησάτω ἔτι, καὶ ὁ ἅγιος, ἁγιασθήτω ἔτι.”
66022012 “Ἰδοὺ, ἔρχομαι ταχύ, καὶ ὁ μισθός μου μετʼ ἐμοῦ, ἀποδοῦναι ἑκάστῳ ὡς τὸ ἔργον ἐστὶν αὐτοῦ.
66022013 Ἐγὼ τὸ Ἄλφα καὶ τὸ Ὦ, ὁ πρῶτος καὶ ὁ ἔσχατος, ἡ ἀρχὴ καὶ τὸ τέλος.
66022014 Μακάριοι οἱ πλύνοντες τὰς στολὰς αὐτῶν, ἵνα ἔσται ἡ ἐξουσία αὐτῶν ἐπὶ τὸ ξύλον τῆς ζωῆς, καὶ τοῖς πυλῶσιν εἰσέλθωσιν εἰς τὴν πόλιν.
66022015 Ἔξω οἱ κύνες, καὶ οἱ φάρμακοι, καὶ οἱ πόρνοι, καὶ οἱ φονεῖς, καὶ οἱ εἰδωλολάτραι, καὶ πᾶς φιλῶν καὶ ποιῶν ψεῦδος.
66022016 Ἐγὼ, ˚Ἰησοῦς, ἔπεμψα τὸν ἄγγελόν μου, μαρτυρῆσαι ὑμῖν ταῦτα ἐπὶ ταῖς ἐκκλησίαις. Ἐγώ εἰμι ἡ ῥίζα καὶ τὸ γένος Δαυίδ, ὁ ἀστὴρ ὁ λαμπρός, ὁ πρωϊνός.”
66022017 Καὶ τὸ ˚Πνεῦμα καὶ ἡ νύμφη λέγουσιν, “Ἔρχου!” Καὶ ὁ ἀκούων εἰπάτω, “Ἔρχου!” Καὶ ὁ διψῶν ἐρχέσθω, ὁ θέλων λαβέτω ὕδωρ ζωῆς δωρεάν.
66022018 Μαρτυρῶ ἐγὼ παντὶ τῷ ἀκούοντι τοὺς λόγους τῆς προφητείας τοῦ βιβλίου τούτου: ἐάν τις ἐπιθῇ ἐπʼ αὐτά, ἐπιθήσει ἐπʼ αὐτὸν ὁ ˚Θεὸς τὰς πληγὰς τὰς γεγραμμένας ἐν τῷ βιβλίῳ τούτῳ.
66022019 Καὶ ἐάν τις ἀφέλῃ ἀπὸ τῶν λόγων τοῦ βιβλίου τῆς προφητείας ταύτης, ἀφελεῖ ὁ ˚Θεὸς τὸ μέρος αὐτοῦ ἀπὸ τοῦ ξύλου τῆς ζωῆς καὶ ἐκ τῆς πόλεως τῆς ἁγίας, τῶν γεγραμμένων ἐν τῷ βιβλίῳ τούτῳ.
66022020 Λέγει ὁ μαρτυρῶν ταῦτα, “Ναί, ἔρχομαι ταχύ. Ἀμήν!” Ἔρχου, ˚Κύριε ˚Ἰησοῦ!
66022021 Ἡ χάρις τοῦ ˚Κυρίου ˚Ἰησοῦ μετὰ τῶν ἁγίων. Ἀμήν!`;

/*
# Witness SR
# Produced by the Center for New Testament Restoration ( CNTR ) 11/30/22
# Copyright © 2022 by Alan Bunning released under Creative Commons Attribution 4.0 International License ( CC BY-SA 4.0 )
`# Witness SR
# Produced by the Center for New Testament Restoration ( CNTR ) 11/30/22
# Copyright © 2022 by Alan Bunning released under Creative Commons Attribution 4.0 International License ( CC BY-SA 4.0 )`;
*/

// console.log(gText === gLex )
function removePunctuation(str) {
  //this is from ChatpGPT
  const punctuationRegex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~˚“‘”’·ʼ]/g;
  // const diacriticsRegex = /[\u0300-\u036f]/g;
  // const greekLettersRegex = /[α-ωΑ-Ω]/g;

  const punctuationRemoved = str.replace(punctuationRegex, "");
  // const diacriticsKept = punctuationRemoved.replace(diacriticsRegex, (match) => match);
  // const greekLettersKept = diacriticsKept.replace(greekLettersRegex, (match) => match);

  return punctuationRemoved;
}

let greekTextArray = greekText.split(" ");
let wordCount = {};
for (let i = 0; i < greekTextArray.length; i++) {
  let word = removePunctuation(greekTextArray[i]).toLowerCase();
  if (!(word in wordCount)) {
    wordCount[word] = 0;
  } else {
    wordCount[word] += 1;
  }
}
