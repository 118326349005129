// const fs = require("fs");
export const wordUsages = {
  Ἄλφα: {
    parse: "N-NSN-T｜Noun, nominative, singular, neuter, Title",
    GN: "G1",
    gloss: "alpha",
  },
  Ἀαρών: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2",
    gloss: "Aaron",
  },
  Ἀαρὼν: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2",
    gloss: "Aaron",
  },
  Ἀβαδδών: {
    parse: "N-NSM-T｜Noun, nominative, singular, masculine, Title",
    GN: "G3",
    gloss: "Abaddon",
  },
  ἀβαρῆ: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G4",
    gloss: "not burdensome",
  },
  Ἀββά: {
    parse: "N-VSM｜Noun, Vocative or nominative, singular, masculine",
    GN: "G5",
    gloss: "Abba",
  },
  Ἅβελ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G6",
    gloss: "Abel",
  },
  Ἀβιά: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G7",
    gloss: "Abijah",
  },
  Ἀβιὰ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G7",
    gloss: "Abijah",
  },
  Ἀβιαθὰρ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G8",
    gloss: "Abiathar",
  },
  Ἀβιληνῆς: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G9",
    gloss: "Abilene",
  },
  Ἀβιούδ: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G10",
    gloss: "Abiud",
  },
  Ἀβιοὺδ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G10",
    gloss: "Abiud",
  },
  Ἀβραάμ: {
    parse: "N-VSM-P｜Noun, Vocative or nominative, singular, masculine, Person",
    GN: "G11",
    gloss: "Abraham",
  },
  Ἀβραὰμ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G11",
    gloss: "Abraham",
  },
  ἄβυσσον: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G12",
    gloss: "abyss",
  },
  ἀβύσσου: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G12",
    gloss: "abyss",
  },
  Ἅγαβος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G13",
    gloss: "Agabus",
  },
  ἀγαθοεργεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G14",
    gloss: "to do good",
  },
  ἀγαθουργῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G14",
    gloss: "to do good",
  },
  ἀγαθοποιεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G15",
    gloss: "to do good",
  },
  ἀγαθοποιῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G15",
    gloss: "to do good",
  },
  ἀγαθοποιῆτε: {
    parse: "V-PAS-2P｜Verb, present, active, subjunctive, second, plural",
    GN: "G15",
    gloss: "to do good",
  },
  ἀγαθοποιοῦντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G15",
    gloss: "to do good",
  },
  ἀγαθοποιοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G15",
    gloss: "to do good",
  },
  ἀγαθοποιοῦσαι: {
    parse:
      "V-PAP-NPF｜Verb, present, active, Participle, nominative, plural, feminine",
    GN: "G15",
    gloss: "to do good",
  },
  ἀγαθοποιῶν: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G17",
    gloss: "doing good",
  },
  ἀγαθοποιΐᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G16",
    gloss: "doing good",
  },
  ἀγαθά: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G18",
    gloss: "good-doer",
  },
  ἀγαθὰ: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G18",
    gloss: "good-doer",
  },
  ἀγαθάς: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G18",
    gloss: "good-doer",
  },
  ἀγαθὰς: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G18",
    gloss: "good-doer",
  },
  ἀγαθέ: {
    // or nominative
    parse: "A-VSM｜Adjective, Vocative, singular, masculine",
    GN: "G18",
    gloss: "good-doer",
  },
  ἀγαθὲ: {
    // or nominative
    parse: "A-VSM｜Adjective, Vocative, singular, masculine",
    GN: "G18",
    gloss: "good-doer",
  },
  ἀγαθῇ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G18",
    gloss: "good-doer",
  },
  ἀγαθή: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G18",
    gloss: "good-doer",
  },
  ἀγαθὴ: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G18",
    gloss: "good-doer",
  },
  ἀγαθήν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G18",
    gloss: "good-doer",
  },
  ἀγαθὴν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G18",
    gloss: "good-doer",
  },
  ἀγαθῆς: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G18",
    gloss: "good-doer",
  },
  ἀγαθοῖς: {
    parse: "A-DPN｜Adjective, dative, plural, neuter",
    GN: "G18",
    gloss: "good-doer",
  },
  ἀγαθόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G18",
    gloss: "good-doer",
  },
  ἀγαθὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G18",
    gloss: "good-doer",
  },
  ἀγαθός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G18",
    gloss: "good-doer",
  },
  Ἀγαθός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G18",
    gloss: "good-doer",
  },
  ἀγαθὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G18",
    gloss: "good-doer",
  },
  ἀγαθοῦ: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G18",
    gloss: "good-doer",
  },
  ἀγαθούς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G18",
    gloss: "good-doer",
  },
  ἀγαθοὺς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G18",
    gloss: "good-doer",
  },
  ἀγαθῷ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G18",
    gloss: "good-doer",
  },
  ἀγαθῶν: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G18",
    gloss: "good-doer",
  },
  ἀγαθωσύνῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G19",
    gloss: "goodness",
  },
  ἀγαθωσύνη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G19",
    gloss: "goodness",
  },
  ἀγαθωσύνης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G19",
    gloss: "goodness",
  },
  ἀγαλλιάσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G20",
    gloss: "joy",
  },
  ἀγαλλιάσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G20",
    gloss: "joy",
  },
  ἀγαλλίασις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G20",
    gloss: "joy",
  },
  ἀγαλλιαθῆναι: {
    parse: "V-AON｜Verb, aorist, Passive deponent, Infinitive",
    GN: "G21",
    gloss: "to rejoice",
  },
  ἀγαλλιᾶσθε: {
    parse:
      "V-PNM-2P｜Verb, present, Middle or Passive deponent, imperative, second, plural",
    GN: "G21",
    gloss: "to rejoice",
  },
  ἀγαλλιῶμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G21",
    gloss: "to rejoice",
  },
  ἀγαλλιώμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G21",
    gloss: "to rejoice",
  },
  ἠγαλλιάσατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G21",
    gloss: "to rejoice",
  },
  ἠγαλλίασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G21",
    gloss: "to rejoice",
  },
  ἀγάμοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G22",
    gloss: "unmarried",
  },
  ἄγαμος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G22",
    gloss: "unmarried",
  },
  ἀγανακτεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G23",
    gloss: "be indignant",
  },
  ἀγανακτοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G23",
    gloss: "be indignant",
  },
  ἀγανακτῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G23",
    gloss: "be indignant",
  },
  ἠγανάκτησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G23",
    gloss: "be indignant",
  },
  ἠγανάκτησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G23",
    gloss: "be indignant",
  },
  ἀγανάκτησιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine ",
    GN: "G24",
    gloss: "indignation",
  },
  ἀγαπᾷ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G25",
    gloss: "to love",
  },
  ἀγαπᾶν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G25",
    gloss: "to love",
  },
  ἀγαπᾷς: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G25",
    gloss: "to love",
  },
  ἀγαπᾶτε: {
    parse: "V-PAS-2P｜Verb, present, active, subjunctive, second, plural",
    GN: "G25",
    gloss: "to love",
  },
  ἀγαπᾶτέ: {
    parse: "V-PAS-2P｜Verb, present, active, subjunctive, second, plural",
    GN: "G25",
    gloss: "to love",
  },
  ἀγαπάτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G25",
    gloss: "to love",
  },
  ἀγαπηθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G25",
    gloss: "to love",
  },
  ἀγαπήσαντος: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G25",
    gloss: "to love",
  },
  ἀγαπήσαντός: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G25",
    gloss: "to love",
  },
  ἀγαπήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G25",
    gloss: "to love",
  },
  ἀγαπήσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G25",
    gloss: "to love",
  },
  ἀγαπήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G25",
    gloss: "to love",
  },
  ἀγαπήσεις: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G25",
    gloss: "to love",
  },
  Ἀγαπήσεις: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G25",
    gloss: "to love",
  },
  ἀγαπήσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G25",
    gloss: "to love",
  },
  ἀγαπήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G25",
    gloss: "to love",
  },
  ἀγαπῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G25",
    gloss: "to love",
  },
  Ἀγαπῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G25",
    gloss: "to love",
  },
  ἀγαπῶμαι: {
    parse: "V-PPI-1S｜Verb, present, passive, indicative, first, singular",
    GN: "G25",
    gloss: "to love",
  },
  ἀγαπῶμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G25",
    gloss: "to love",
  },
  ἀγαπῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G25",
    gloss: "to love",
  },
  ἀγαπῶντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G25",
    gloss: "to love",
  },
  ἀγαπῶντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G25",
    gloss: "to love",
  },
  ἀγαπώντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G25",
    gloss: "to love",
  },
  ἀγαπῶσιν: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G25",
    gloss: "to love",
  },
  ἠγάπα: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G25",
    gloss: "to love",
  },
  ἠγαπᾶτε: {
    parse: "V-IAI-2P｜Verb, imperfect, active, indicative, second, plural",
    GN: "G25",
    gloss: "to love",
  },
  ἠγαπᾶτέ: {
    parse: "V-IAI-2P｜Verb, imperfect, active, indicative, second, plural",
    GN: "G25",
    gloss: "to love",
  },
  ἠγαπήκαμεν: {
    parse: "V-RAI-1P｜Verb, perfect, active, indicative, first, plural",
    GN: "G25",
    gloss: "to love",
  },
  ἠγαπηκόσι: {
    parse:
      "V-RAP-DPM｜Verb, perfect, active, Participle, dative, plural, masculine",
    GN: "G25",
    gloss: "to love",
  },
  ἠγαπηκόσιν: {
    parse:
      "V-RAP-DPM｜Verb, perfect, active, Participle, dative, plural, masculine",
    GN: "G25",
    gloss: "to love",
  },
  ἠγαπημένην: {
    parse:
      "V-RPP-ASF｜Verb, perfect, passive, Participle, accusative, singular, feminine",
    GN: "G25",
    gloss: "to love",
  },
  ἠγαπημένοι: {
    parse:
      "V-RPP-VPM｜Verb, perfect, passive, Participle, Vocative or nominative, plural, masculine",
    GN: "G25",
    gloss: "to love",
  },
  ἠγαπημένοις: {
    parse:
      "V-RPP-DPM｜Verb, perfect, passive, Participle, dative, plural, masculine",
    GN: "G25",
    gloss: "to love",
  },
  ἠγαπημένῳ: {
    parse:
      "V-RPP-DSM｜Verb, perfect, passive, Participle, dative, singular, masculine",
    GN: "G25",
    gloss: "to love",
  },
  ἠγάπησα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G25",
    gloss: "to love",
  },
  ἠγάπησά: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G25",
    gloss: "to love",
  },
  ἠγάπησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G25",
    gloss: "to love",
  },
  ἠγάπησας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G25",
    gloss: "to love",
  },
  ἠγάπησάς: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G25",
    gloss: "to love",
  },
  ἠγάπησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G25",
    gloss: "to love",
  },
  ἠγάπησέν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G25",
    gloss: "to love",
  },
  ἀγάπαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G26",
    gloss: "love",
  },
  ἀγάπῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G26",
    gloss: "love",
  },
  ἀγάπη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G26",
    gloss: "love",
  },
  ἀγάπην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G26",
    gloss: "love",
  },
  ἀγάπης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G26",
    gloss: "love",
  },
  ἀγαπητὰ: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G27",
    gloss: "beloved",
  },
  ἀγαπητά: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G27",
    gloss: "beloved",
  },
  Ἀγαπητέ: {
    // or nominative
    parse: "A-VSM｜Adjective, Vocative, singular, masculine",
    GN: "G27",
    gloss: "beloved",
  },
  ἀγαπητήν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G27",
    gloss: "beloved",
  },
  ἀγαπητοὶ: {
    parse: "A-VPM｜Adjective, Vocative or nominative, plural, masculine",
    GN: "G27",
    gloss: "beloved",
  },
  ἀγαπητοί: {
    parse: "A-VPM｜Adjective, Vocative or nominative, plural, masculine",
    GN: "G27",
    gloss: "beloved",
  },
  Ἀγαπητοί: {
    parse: "A-VPM｜Adjective, Vocative or nominative, plural, masculine",
    GN: "G27",
    gloss: "beloved",
  },
  ἀγαπητοῖς: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G27",
    gloss: "beloved",
  },
  ἀγαπητόν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G27",
    gloss: "beloved",
  },
  ἀγαπητὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G27",
    gloss: "beloved",
  },
  ἀγαπητός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G27",
    gloss: "beloved",
  },
  ἀγαπητὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G27",
    gloss: "beloved",
  },
  ἀγαπητοῦ: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G27",
    gloss: "beloved",
  },
  ἀγαπητῷ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G27",
    gloss: "beloved",
  },
  Ἁγάρ: {
    parse: "N-NSF-P｜Noun, nominative, singular, feminine, Person",
    GN: "G28",
    gloss: "Hagar",
  },
  Ἁγὰρ: {
    parse: "N-NSF-P｜Noun, nominative, singular, feminine, Person",
    GN: "G28",
    gloss: "Hagar",
  },
  ἀγγαρεύουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G29",
    gloss: "to force",
  },
  ἀγγαρεύσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G29",
    gloss: "to force",
  },
  ἠγγάρευσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G29",
    gloss: "to force",
  },
  ἀγγείοις: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G30",
    gloss: "jar",
  },
  ἀγγελία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G31",
    gloss: "message",
  },
  ἄγγελοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G32",
    gloss: "angel",
  },
  ἄγγελοί: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G32",
    gloss: "angel",
  },
  ἀγγέλοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G32",
    gloss: "angel",
  },
  ἄγγελον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G32",
    gloss: "angel",
  },
  ἄγγελόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G32",
    gloss: "angel",
  },
  ἄγγελος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G32",
    gloss: "angel",
  },
  Ἄγγελος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G32",
    gloss: "angel",
  },
  ἄγγελός: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G32",
    gloss: "angel",
  },
  ἀγγέλου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G32",
    gloss: "angel",
  },
  ἀγγέλους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G32",
    gloss: "angel",
  },
  ἀγγέλῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G32",
    gloss: "angel",
  },
  ἀγγέλων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G32",
    gloss: "angel",
  },
  ἀγέλη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G34",
    gloss: "herd",
  },
  ἀγέλην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G34",
    gloss: "herd",
  },
  ἀγενεαλόγητος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G35",
    gloss: "without genealogy",
  },
  ἀγενῆ: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G36",
    gloss: "lowly",
  },
  ἁγιάζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G37",
    gloss: "to sanctify",
  },
  ἁγιάζεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G37",
    gloss: "to sanctify",
  },
  ἁγιαζόμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G37",
    gloss: "to sanctify",
  },
  ἁγιαζομένους: {
    parse:
      "V-PPP-APM｜Verb, present, passive, Participle, accusative, plural, masculine",
    GN: "G37",
    gloss: "to sanctify",
  },
  ἁγιάζον: {
    parse:
      "V-PAP-NSN｜Verb, present, active, Participle, nominative, singular, neuter",
    GN: "G37",
    gloss: "to sanctify",
  },
  ἁγιάζω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G37",
    gloss: "to sanctify",
  },
  ἁγιάζων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G37",
    gloss: "to sanctify",
  },
  ἁγιάσαι: {
    parse: "V-AAO-3S｜Verb, aorist, active, Optative, third, singular",
    GN: "G37",
    gloss: "to sanctify",
  },
  ἁγιάσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G37",
    gloss: "to sanctify",
  },
  ἁγιάσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G37",
    gloss: "to sanctify",
  },
  ἁγιάσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G37",
    gloss: "to sanctify",
  },
  ἁγιασθήτω: {
    parse: "V-APM-3S｜Verb, aorist, passive, imperative, third, singular",
    GN: "G37",
    gloss: "to sanctify",
  },
  ἁγίασον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G37",
    gloss: "to sanctify",
  },
  ἡγίασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G37",
    gloss: "to sanctify",
  },
  ἡγιάσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G37",
    gloss: "to sanctify",
  },
  ἡγιάσθητε: {
    parse: "V-API-2P｜Verb, aorist, passive, indicative, second, plural",
    GN: "G37",
    gloss: "to sanctify",
  },
  ἡγιασμένη: {
    parse:
      "V-RPP-NSF｜Verb, perfect, passive, Participle, nominative, singular, feminine",
    GN: "G37",
    gloss: "to sanctify",
  },
  ἡγιασμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G37",
    gloss: "to sanctify",
  },
  ἡγιασμένοις: {
    parse:
      "V-RPP-DPM｜Verb, perfect, passive, Participle, dative, plural, masculine",
    GN: "G37",
    gloss: "to sanctify",
  },
  ἡγιασμένον: {
    parse:
      "V-RPP-NSN｜Verb, perfect, passive, Participle, nominative, singular, neuter",
    GN: "G37",
    gloss: "to sanctify",
  },
  ἡγίασται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G37",
    gloss: "to sanctify",
  },
  ἁγιασμόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G38",
    gloss: "holiness",
  },
  ἁγιασμὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G38",
    gloss: "holiness",
  },
  ἁγιασμῷ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G38",
    gloss: "holiness",
  },
  ἅγια: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G40",
    gloss: "saint",
  },
  ἁγίᾳ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G40",
    gloss: "saint",
  },
  Ἅγια: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G40",
    gloss: "saint",
  },
  ἅγιά: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G40",
    gloss: "saint",
  },
  ἁγία: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G40",
    gloss: "saint",
  },
  ἅγιαι: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G40",
    gloss: "saint",
  },
  ἁγίαις: {
    parse: "A-DPF｜Adjective, dative, plural, feminine",
    GN: "G40",
    gloss: "saint",
  },
  ἁγίαν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G40",
    gloss: "saint",
  },
  ἁγίας: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G40",
    gloss: "saint",
  },
  ἅγιε: {
    //or nominative
    parse: "A-VSM｜Adjective, Vocative, singular, masculine",
    GN: "G40",
    gloss: "saint",
  },
  ἅγιοι: {
    parse: "A-VPM｜Adjective, Vocative or nominative, plural, masculine",
    GN: "G40",
    gloss: "saint",
  },
  Ἅγιοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G40",
    gloss: "saint",
  },
  ἁγίοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G40",
    gloss: "saint",
  },
  ἅγιον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G40",
    gloss: "saint",
  },
  ἅγιος: {
    parse: "A-VSM｜Adjective, Vocative or nominative, singular, masculine",
    GN: "G40",
    gloss: "saint",
  },
  Ἅγιος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G40",
    gloss: "saint",
  },
  ἅγιός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G40",
    gloss: "saint",
  },
  ἁγίου: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G40",
    gloss: "saint",
  },
  Ἁγίου: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G40",
    gloss: "saint",
  },
  ἁγίους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G40",
    gloss: "saint",
  },
  ἁγίῳ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G40",
    gloss: "saint",
  },
  ἁγίων: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G40",
    gloss: "saint",
  },
  Ἁγίων: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G40",
    gloss: "saint",
  },
  ἁγιωτάτῃ: {
    parse: "A-DSF-S｜Adjective, dative, singular, feminine, Superlative",
    GN: "G40",
    gloss: "saint",
  },
  ἁγιότητος: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G41",
    gloss: "holiness",
  },
  ἁγιωσύνῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G42",
    gloss: "holiness",
  },
  ἁγιωσύνην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G42",
    gloss: "holiness",
  },
  ἁγιωσύνης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G42",
    gloss: "holiness",
  },
  ἀγκάλας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G43",
    gloss: "arm",
  },
  ἄγκιστρον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G44",
    gloss: "fishhook",
  },
  ἄγκυραν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G45",
    gloss: "anchor",
  },
  ἀγκύρας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G45",
    gloss: "anchor",
  },
  ἀγνάφου: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G46",
    gloss: "unshrunk",
  },
  ἁγνείᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G47",
    gloss: "purity",
  },
  ἁγνίζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G48",
    gloss: "to purify",
  },
  ἁγνίσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G48",
    gloss: "to purify",
  },
  ἁγνισθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G48",
    gloss: "to purify",
  },
  ἁγνίσθητι: {
    parse: "V-APM-2S｜Verb, aorist, passive, imperative, second, singular",
    GN: "G48",
    gloss: "to purify",
  },
  ἁγνίσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G48",
    gloss: "to purify",
  },
  ἡγνικότες: {
    parse:
      "V-RAP-NPM｜Verb, perfect, active, Participle, nominative, plural, masculine",
    GN: "G48",
    gloss: "to purify",
  },
  ἡγνισμένον: {
    parse:
      "V-RPP-ASM｜Verb, perfect, passive, Participle, accusative, singular, masculine",
    GN: "G48",
    gloss: "to purify",
  },
  ἁγνισμοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G49",
    gloss: "purification",
  },
  ἀγνοεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G50",
    gloss: "be ignorant",
  },
  ἀγνοεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G50",
    gloss: "be ignorant",
  },
  ἀγνοεῖται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G50",
    gloss: "be ignorant",
  },
  ἀγνοεῖτε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G50",
    gloss: "be ignorant",
  },
  ἀγνοήσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G50",
    gloss: "be ignorant",
  },
  ἀγνοοῦμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G50",
    gloss: "be ignorant",
  },
  ἀγνοούμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G50",
    gloss: "be ignorant",
  },
  ἀγνοούμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G50",
    gloss: "be ignorant",
  },
  ἀγνοοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G50",
    gloss: "be ignorant",
  },
  ἀγνοοῦσι: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G50",
    gloss: "be ignorant",
  },
  ἀγνοοῦσιν: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G50",
    gloss: "be ignorant",
  },
  ἀγνοῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G50",
    gloss: "be ignorant",
  },
  ἠγνόουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G50",
    gloss: "be ignorant",
  },
  ἀγνοημάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G51",
    gloss: "error",
  },
  ἀγνοίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G52",
    gloss: "ignorance",
  },
  ἄγνοιαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G52",
    gloss: "ignorance",
  },
  ἀγνοίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G52",
    gloss: "ignorance",
  },
  ἁγνά: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G53",
    gloss: "pure",
  },
  ἁγνάς: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G53",
    gloss: "pure",
  },
  ἁγνή: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G53",
    gloss: "pure",
  },
  ἁγνὴν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G53",
    gloss: "pure",
  },
  ἁγνὸν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G53",
    gloss: "pure",
  },
  ἁγνός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G53",
    gloss: "pure",
  },
  ἁγνοὺς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G53",
    gloss: "pure",
  },
  ἁγνότητι: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G54",
    gloss: "purity",
  },
  ἁγνότητος: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G54",
    gloss: "purity",
  },
  ἁγνῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G55",
    gloss: "purely",
  },
  ἀγνωσίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G56",
    gloss: "ignorance",
  },
  Ἀγνώστῳ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G57",
    gloss: "unknown",
  },
  ἀγορᾷ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G58",
    gloss: "marketplace",
  },
  ἀγοραῖς: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G58",
    gloss: "marketplace",
  },
  ἀγορὰν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G58",
    gloss: "marketplace",
  },
  ἀγορᾶς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G58",
    gloss: "marketplace",
  },
  ἀγοράζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G59",
    gloss: "to buy",
  },
  ἀγοράζοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G59",
    gloss: "to buy",
  },
  ἀγοράζοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G59",
    gloss: "to buy",
  },
  ἀγοράσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G59",
    gloss: "to buy",
  },
  ἀγοράσαντα: {
    parse:
      "V-AAP-ASM｜Verb, aorist, active, Participle, accusative, singular, masculine",
    GN: "G59",
    gloss: "to buy",
  },
  ἀγοράσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G59",
    gloss: "to buy",
  },
  ἀγοράσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G59",
    gloss: "to buy",
  },
  ἀγορασάτω: {
    parse: "V-AAM-3S｜Verb, aorist, active, imperative, third, singular",
    GN: "G59",
    gloss: "to buy",
  },
  Ἀγόρασον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G59",
    gloss: "to buy",
  },
  ἀγοράσωμεν: {
    parse: "V-AAS-1P｜Verb, aorist, active, subjunctive, first, plural",
    GN: "G59",
    gloss: "to buy",
  },
  ἀγοράσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G59",
    gloss: "to buy",
  },
  ἠγόραζον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G59",
    gloss: "to buy",
  },
  ἠγόρασα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G59",
    gloss: "to buy",
  },
  ἠγόρασαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G59",
    gloss: "to buy",
  },
  ἠγόρασας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G59",
    gloss: "to buy",
  },
  ἠγόρασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G59",
    gloss: "to buy",
  },
  ἠγοράσθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G59",
    gloss: "to buy",
  },
  ἠγοράσθητε: {
    parse: "V-API-2P｜Verb, aorist, passive, indicative, second, plural",
    GN: "G59",
    gloss: "to buy",
  },
  ἠγορασμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G59",
    gloss: "to buy",
  },
  ἀγοραῖοι: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G60",
    gloss: "of the marketplace",
  },
  ἀγοραίων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G60",
    gloss: "of the marketplace",
  },
  ἄγρᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G61",
    gloss: "catch",
  },
  ἄγραν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G61",
    gloss: "catch",
  },
  ἀγράμματοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G62",
    gloss: "unschooled",
  },
  ἀγραυλοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G63",
    gloss: "to live outside",
  },
  ἀγρεύσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G64",
    gloss: "to catch",
  },
  ἀγριέλαιος: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G65",
    gloss: "wild olive tree",
  },
  ἀγριελαίου: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G65",
    gloss: "wild olive tree",
  },
  ἄγρια: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G66",
    gloss: "wild",
  },
  ἄγριον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G66",
    gloss: "wild",
  },
  Ἀγρίππα: {
    parse: "N-VSM-P｜Noun, Vocative or nominative, singular, masculine, Person",
    GN: "G67",
    gloss: "Agrippa",
  },
  Ἀγρίππας: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G67",
    gloss: "Agrippa",
  },
  ἀγρόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G68",
    gloss: "Field of",
  },
  ἀγρὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G68",
    gloss: "Field of",
  },
  Ἀγρὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G68",
    gloss: "Field of",
  },
  ἀγρός: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G68",
    gloss: "Field of",
  },
  ἀγρὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G68",
    gloss: "Field of",
  },
  Ἀγρὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G68",
    gloss: "Field of",
  },
  ἀγροῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G68",
    gloss: "Field of",
  },
  ἀγρούς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G68",
    gloss: "Field of",
  },
  ἀγροὺς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G68",
    gloss: "Field of",
  },
  ἀγρῷ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G68",
    gloss: "Field of",
  },
  ἀγρῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G68",
    gloss: "Field of",
  },
  ἀγρυπνεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G69",
    gloss: "be watchful",
  },
  ἀγρυπνοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G69",
    gloss: "be watchful",
  },
  ἀγρυπνοῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G69",
    gloss: "be watchful",
  },
  ἀγρυπνίαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G70",
    gloss: "sleeplessness",
  },
  ἀγαγεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G71",
    gloss: "to bring/lead",
  },
  ἀγάγετε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G71",
    gloss: "to bring/lead",
  },
  ἀγάγετέ: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G71",
    gloss: "to bring/lead",
  },
  ἀγάγῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G71",
    gloss: "to bring/lead",
  },
  ἀγαγόντα: {
    parse:
      "V-2AAP-ASM｜Verb, 2nd aorist, active, Participle, accusative, singular, masculine",
    GN: "G71",
    gloss: "to bring/lead",
  },
  Ἀγαγόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G71",
    gloss: "to bring/lead",
  },
  ἄγε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G71",
    gloss: "to bring/lead",
  },
  Ἄγε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G71",
    gloss: "to bring/lead",
  },
  ἄγει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G71",
    gloss: "to bring/lead",
  },
  ἄγειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G71",
    gloss: "to bring/lead",
  },
  ἄγεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G71",
    gloss: "to bring/lead",
  },
  ἄγεσθε: {
    parse: "V-PPI-2P｜Verb, present, passive, indicative, second, plural",
    GN: "G71",
    gloss: "to bring/lead",
  },
  ἀγόμενα: {
    parse:
      "V-PPP-APN｜Verb, present, passive, Participle, accusative, plural, neuter",
    GN: "G71",
    gloss: "to bring/lead",
  },
  ἄγονται: {
    parse: "V-PPI-3P｜Verb, present, passive, indicative, third, plural",
    GN: "G71",
    gloss: "to bring/lead",
  },
  ἄγοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G71",
    gloss: "to bring/lead",
  },
  ἄγουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G71",
    gloss: "to bring/lead",
  },
  Ἄγουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G71",
    gloss: "to bring/lead",
  },
  ἄγω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G71",
    gloss: "to bring/lead",
  },
  ἄγωμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G71",
    gloss: "to bring/lead",
  },
  Ἄγωμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G71",
    gloss: "to bring/lead",
  },
  ἄγωσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G71",
    gloss: "to bring/lead",
  },
  ἄξει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G71",
    gloss: "to bring/lead",
  },
  ἄξων: {
    parse:
      "V-FAP-NSM｜Verb, future, active, Participle, nominative, singular, masculine",
    GN: "G71",
    gloss: "to bring/lead",
  },
  ἀχθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G71",
    gloss: "to bring/lead",
  },
  ἀχθήσεσθε: {
    parse: "V-FPI-2P｜Verb, future, passive, indicative, second, plural",
    GN: "G71",
    gloss: "to bring/lead",
  },
  ἤγαγεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G71",
    gloss: "to bring/lead",
  },
  Ἤγαγεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G71",
    gloss: "to bring/lead",
  },
  ἠγάγετε: {
    parse: "V-2AAI-2P｜Verb, 2nd aorist, active, indicative, second, plural",
    GN: "G71",
    gloss: "to bring/lead",
  },
  ἤγαγον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G71",
    gloss: "to bring/lead",
  },
  ἦγεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G71",
    gloss: "to bring/lead",
  },
  ἤγεσθε: {
    parse: "V-IPI-2P｜Verb, imperfect, passive, indicative, second, plural",
    GN: "G71",
    gloss: "to bring/lead",
  },
  ἤγετο: {
    parse: "V-IPI-3S｜Verb, imperfect, passive, indicative, third, singular",
    GN: "G71",
    gloss: "to bring/lead",
  },
  Ἤγοντο: {
    parse: "V-IPI-3P｜Verb, imperfect, passive, indicative, third, plural",
    GN: "G71",
    gloss: "to bring/lead",
  },
  ἤχθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G71",
    gloss: "to bring/lead",
  },
  ἀγωγῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G72",
    gloss: "self-conduct",
  },
  ἀγῶνα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G73",
    gloss: "fight",
  },
  ἀγῶνι: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G73",
    gloss: "fight",
  },
  ἀγωνίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G74",
    gloss: "a struggle",
  },
  Ἀγωνίζεσθε: {
    parse:
      "V-PNM-2P｜Verb, present, Middle or Passive deponent, imperative, second, plural",
    GN: "G75",
    gloss: "to struggle",
  },
  ἀγωνιζόμεθα: {
    parse:
      "V-PNI-1P｜Verb, present, Middle or Passive deponent, indicative, first, plural",
    GN: "G75",
    gloss: "to struggle",
  },
  ἀγωνιζόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G75",
    gloss: "to struggle",
  },
  ἀγωνίζου: {
    parse:
      "V-PNM-2S｜Verb, present, Middle or Passive deponent, imperative, second, singular",
    GN: "G75",
    gloss: "to struggle",
  },
  ἠγωνίζοντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G75",
    gloss: "to struggle",
  },
  ἠγώνισμαι: {
    parse:
      "V-RNI-1S｜Verb, perfect, Middle or Passive deponent, indicative, first, singular",
    GN: "G75",
    gloss: "to struggle",
  },
  Ἀδὰμ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G76",
    gloss: "Adam",
  },
  Ἀδάμ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G76",
    gloss: "Adam",
  },
  ἀδάπανον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G77",
    gloss: "free",
  },
  Ἀδδὶ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G78",
    gloss: "Addi",
  },
  ἀδελφαί: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G79",
    gloss: "sister",
  },
  ἀδελφαὶ: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G79",
    gloss: "sister",
  },
  ἀδελφάς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G79",
    gloss: "sister",
  },
  ἀδελφὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G79",
    gloss: "sister",
  },
  ἀδελφῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G79",
    gloss: "sister",
  },
  ἀδελφή: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G79",
    gloss: "sister",
  },
  ἀδελφὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G79",
    gloss: "sister",
  },
  ἀδελφὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G79",
    gloss: "sister",
  },
  ἀδελφῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G79",
    gloss: "sister",
  },
  ἀδελφέ: {
    // or nominative
    parse: "N-VSM｜Noun, Vocative, singular, masculine",
    GN: "G80",
    gloss: "brother",
  },
  Ἀδελφέ: {
    // or nominative
    parse: "N-VSM｜Noun, Vocative, singular, masculine",
    GN: "G80",
    gloss: "brother",
  },
  ἀδελφοί: {
    parse: "N-VPM｜Noun, Vocative or nominative, plural, masculine",
    GN: "G80",
    gloss: "brother",
  },
  ἀδελφοὶ: {
    parse: "N-VPM｜Noun, Vocative or nominative, plural, masculine",
    GN: "G80",
    gloss: "brother",
  },
  Ἀδελφοί: {
    parse: "N-VPM｜Noun, Vocative or nominative, plural, masculine",
    GN: "G80",
    gloss: "brother",
  },
  ἀδελφοῖς: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G80",
    gloss: "brother",
  },
  ἀδελφόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G80",
    gloss: "brother",
  },
  ἀδελφὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G80",
    gloss: "brother",
  },
  ἀδελφός: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G80",
    gloss: "brother",
  },
  ἀδελφὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G80",
    gloss: "brother",
  },
  ἀδελφοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G80",
    gloss: "brother",
  },
  ἀδελφούς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G80",
    gloss: "brother",
  },
  ἀδελφοὺς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G80",
    gloss: "brother",
  },
  ἀδελφῷ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G80",
    gloss: "brother",
  },
  ἀδελφῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G80",
    gloss: "brother",
  },
  ἀδελφότητα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G81",
    gloss: "brotherhood",
  },
  ἀδελφότητι: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G81",
    gloss: "brotherhood",
  },
  ἄδηλα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G82",
    gloss: "unclear",
  },
  ἄδηλον: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G82",
    gloss: "unclear",
  },
  ἀδηλότητι: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G83",
    gloss: "uncertainty",
  },
  ἀδήλως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G84",
    gloss: "uncertainly",
  },
  ἀδημονεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G85",
    gloss: "be distressed",
  },
  ἀδημονῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G85",
    gloss: "be distressed",
  },
  ᾅδῃ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G86",
    gloss: "Hades",
  },
  ᾅδην: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G86",
    gloss: "Hades",
  },
  ᾅδης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G86",
    gloss: "Hades",
  },
  ᾅδου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G86",
    gloss: "Hades",
  },
  ἀδιάκριτος: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G87",
    gloss: "impartial",
  },
  ἀδιάλειπτον: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G88",
    gloss: "continuous",
  },
  ἀδιάλειπτος: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G88",
    gloss: "continuous",
  },
  ἀδιαλείπτως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G89",
    gloss: "unceasingly",
  },
  ἀδικεῖσθε: {
    parse: "V-PPI-2P｜Verb, present, passive, indicative, second, plural",
    GN: "G91",
    gloss: "to harm",
  },
  ἀδικεῖτε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G91",
    gloss: "to harm",
  },
  ἀδικηθέντος: {
    parse:
      "V-APP-GSM｜Verb, aorist, passive, Participle, genitive, singular, masculine",
    GN: "G91",
    gloss: "to harm",
  },
  ἀδικηθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G91",
    gloss: "to harm",
  },
  ἀδικῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G91",
    gloss: "to harm",
  },
  ἀδικήσαντος: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G91",
    gloss: "to harm",
  },
  ἀδικησάτω: {
    parse: "V-AAM-3S｜Verb, aorist, active, imperative, third, singular",
    GN: "G91",
    gloss: "to harm",
  },
  ἀδικήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G91",
    gloss: "to harm",
  },
  ἀδικήσῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G91",
    gloss: "to harm",
  },
  ἀδικήσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G91",
    gloss: "to harm",
  },
  ἀδικήσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G91",
    gloss: "to harm",
  },
  ἀδικούμενοι: {
    parse:
      "V-PMP-NPM｜Verb, present, middle, Participle, nominative, plural, masculine",
    GN: "G91",
    gloss: "to harm",
  },
  ἀδικούμενον: {
    parse:
      "V-PPP-ASM｜Verb, present, passive, Participle, accusative, singular, masculine",
    GN: "G91",
    gloss: "to harm",
  },
  ἀδικοῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G91",
    gloss: "to harm",
  },
  ἀδικῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G91",
    gloss: "to harm",
  },
  ἀδικῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G91",
    gloss: "to harm",
  },
  ἠδίκησα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G91",
    gloss: "to harm",
  },
  ἠδικήσαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G91",
    gloss: "to harm",
  },
  ἠδικήσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G91",
    gloss: "to harm",
  },
  ἠδίκησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G91",
    gloss: "to harm",
  },
  ἠδίκησέν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G91",
    gloss: "to harm",
  },
  ἀδίκημα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G92",
    gloss: "crime",
  },
  ἀδίκημά: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G92",
    gloss: "crime",
  },
  ἀδικήματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G92",
    gloss: "crime",
  },
  ἀδικίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G93",
    gloss: "unrighteousness",
  },
  ἀδικία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G93",
    gloss: "unrighteousness",
  },
  ἀδικίαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G93",
    gloss: "unrighteousness",
  },
  ἀδικίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G93",
    gloss: "unrighteousness",
  },
  ἀδικίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G93",
    gloss: "unrighteousness",
  },
  ἄδικοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G94",
    gloss: "unjust",
  },
  ἄδικος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G94",
    gloss: "unjust",
  },
  ἄδικός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G94",
    gloss: "unjust",
  },
  ἀδίκους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G94",
    gloss: "unjust",
  },
  ἀδίκῳ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G94",
    gloss: "unjust",
  },
  ἀδίκων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G94",
    gloss: "unjust",
  },
  ἀδίκως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G95",
    gloss: "unjustly",
  },
  ἀδόκιμοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G96",
    gloss: "failing",
  },
  ἀδόκιμοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G96",
    gloss: "failing",
  },
  ἀδόκιμον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G96",
    gloss: "failing",
  },
  ἀδόκιμος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G96",
    gloss: "failing",
  },
  ἄδολον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G97",
    gloss: "pure",
  },
  Ἀδραμυττηνῷ: {
    parse: "A-DSN-LG｜Adjective, dative, singular, neuter, Location Gentilic",
    GN: "G98",
    gloss: "Adramyttium",
  },
  Ἀδρίᾳ: {
    parse: "N-DSM-L｜Noun, dative, singular, masculine, Location",
    GN: "G99",
    gloss: "Adriatic Sea",
  },
  ἁδρότητι: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G100",
    gloss: "abundance",
  },
  ἀδυνατήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G101",
    gloss: "be impossible",
  },
  ἀδύνατα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G102",
    gloss: "unable",
  },
  ἀδύνατον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G102",
    gloss: "unable",
  },
  Ἀδύνατον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G102",
    gloss: "unable",
  },
  ἀδύνατόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G102",
    gloss: "unable",
  },
  ἀδύνατος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G102",
    gloss: "unable",
  },
  ἀδυνάτων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G102",
    gloss: "unable",
  },
  ᾄδοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G103",
    gloss: "to sing",
  },
  ᾄδουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G103",
    gloss: "to sing",
  },
  ἀεὶ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G104",
    gloss: "always",
  },
  Ἀεὶ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G104",
    gloss: "always",
  },
  ἀετοί: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G105",
    gloss: "eagle",
  },
  ἀετοὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G105",
    gloss: "eagle",
  },
  ἀετοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G105",
    gloss: "eagle",
  },
  ἀετῷ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G105",
    gloss: "eagle",
  },
  ἄζυμα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G106",
    gloss: "unleavened",
  },
  ἄζυμοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G106",
    gloss: "unleavened",
  },
  ἀζύμοις: {
    parse: "A-DPN｜Adjective, dative, plural, neuter",
    GN: "G106",
    gloss: "unleavened",
  },
  ἀζύμων: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G106",
    gloss: "unleavened",
  },
  Ἀζώρ: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G107",
    gloss: "Azor",
  },
  Ἀζὼρ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G107",
    gloss: "Azor",
  },
  Ἄζωτον: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G108",
    gloss: "Azotus",
  },
  ἀέρα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G109",
    gloss: "air",
  },
  ἀέρος: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G109",
    gloss: "air",
  },
  ἀὴρ: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G109",
    gloss: "air",
  },
  ἀθανασίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G110",
    gloss: "immortality",
  },
  ἀθεμίτοις: {
    parse: "A-DPF｜Adjective, dative, plural, feminine",
    GN: "G111",
    gloss: "unlawful",
  },
  ἀθέμιτόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G111",
    gloss: "unlawful",
  },
  ἄθεοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G112",
    gloss: "without God",
  },
  ἀθέσμων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G113",
    gloss: "lawless",
  },
  ἀθετεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G114",
    gloss: "to reject",
  },
  ἀθετεῖτε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G114",
    gloss: "to reject",
  },
  ἀθετῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G114",
    gloss: "to reject",
  },
  ἀθετήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G114",
    gloss: "to reject",
  },
  ἀθετήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G114",
    gloss: "to reject",
  },
  ἀθετοῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G114",
    gloss: "to reject",
  },
  ἀθετῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G114",
    gloss: "to reject",
  },
  ἀθετῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G114",
    gloss: "to reject",
  },
  ἠθέτησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G114",
    gloss: "to reject",
  },
  ἀθέτησιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G115",
    gloss: "nullification",
  },
  ἀθέτησις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G115",
    gloss: "nullification",
  },
  Ἀθήναις: {
    parse: "N-DPF-L｜Noun, dative, plural, feminine, Location",
    GN: "G116",
    gloss: "Athens",
  },
  Ἀθηνῶν: {
    parse: "N-GPF-L｜Noun, genitive, plural, feminine, Location",
    GN: "G116",
    gloss: "Athens",
  },
  Ἀθηναῖοι: {
    parse: "A-VPM｜Adjective, Vocative or nominative, plural, masculine",
    GN: "G117",
    gloss: "Athenian",
  },
  ἀθλῇ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G118",
    gloss: "to compete",
  },
  ἀθλήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G118",
    gloss: "to compete",
  },
  ἄθλησιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G119",
    gloss: "struggle",
  },
  ἀθυμῶσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G120",
    gloss: "be discouraged",
  },
  ἀθῷον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G121",
    gloss: "innocent",
  },
  Ἀθῷός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G121",
    gloss: "innocent",
  },
  αἰγείοις: {
    parse: "A-DPN｜Adjective, dative, plural, neuter",
    GN: "G122",
    gloss: "goat's",
  },
  αἰγιαλόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G123",
    gloss: "shore",
  },
  αἰγιαλὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G123",
    gloss: "shore",
  },
  Αἰγύπτιοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G124",
    gloss: "Egyptian",
  },
  Αἰγύπτιον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G124",
    gloss: "Egyptian",
  },
  Αἰγύπτιος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G124",
    gloss: "Egyptian",
  },
  Αἰγυπτίων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G124",
    gloss: "Egyptian",
  },
  Αἴγυπτον: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G125",
    gloss: "Egypt",
  },
  Αἴγυπτος: {
    parse: "N-NSF-L｜Noun, nominative, singular, feminine, Location",
    GN: "G125",
    gloss: "Egypt",
  },
  Αἰγύπτου: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G125",
    gloss: "Egypt",
  },
  Αἰγύπτῳ: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G125",
    gloss: "Egypt",
  },
  ἀϊδίοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G126",
    gloss: "eternal",
  },
  ἀΐδιος: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G126",
    gloss: "eternal",
  },
  αἰδοῦς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G127",
    gloss: "modesty",
  },
  Αἰθιόπων: {
    parse: "N-GPM-LG｜Noun, genitive, plural, masculine, Location Gentilic",
    GN: "G128",
    gloss: "Ethiopian",
  },
  Αἰθίοψ: {
    parse: "N-NSM-LG｜Noun, nominative, singular, masculine, Location Gentilic",
    GN: "G128",
    gloss: "Ethiopian",
  },
  αἷμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G129",
    gloss: "blood",
  },
  αἷμά: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G129",
    gloss: "blood",
  },
  αἵματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G129",
    gloss: "blood",
  },
  αἵματί: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G129",
    gloss: "blood",
  },
  αἵματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G129",
    gloss: "blood",
  },
  Αἵματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G129",
    gloss: "blood",
  },
  αἵματός: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G129",
    gloss: "blood",
  },
  αἱμάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G129",
    gloss: "blood",
  },
  αἱματεκχυσίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G130",
    gloss: "bloodshed",
  },
  αἱμορροοῦσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G131",
    gloss: "to bleed",
  },
  Αἰνέα: {
    // or nominative
    parse: "N-VSM-P｜Noun, Vocative, singular, masculine, Person",
    GN: "G132",
    gloss: "Aeneas",
  },
  Αἰνέαν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G132",
    gloss: "Aeneas",
  },
  αἰνέσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G133",
    gloss: "praise",
  },
  αἰνεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G134",
    gloss: "to praise",
  },
  Αἰνεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G134",
    gloss: "to praise",
  },
  αἰνοῦντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G134",
    gloss: "to praise",
  },
  αἰνοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G134",
    gloss: "to praise",
  },
  αἰνούντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G134",
    gloss: "to praise",
  },
  αἰνῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G134",
    gloss: "to praise",
  },
  αἰνίγματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G135",
    gloss: "obscure thing",
  },
  αἶνον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G136",
    gloss: "praise",
  },
  Αἰνὼν: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G137",
    gloss: "Aenon",
  },
  αἱρήσομαι: {
    parse: "V-FMI-1S｜Verb, future, middle, indicative, first, singular",
    GN: "G138",
    gloss: "to choose",
  },
  εἵλατο: {
    parse: "V-AMI-3S｜Verb, aorist, middle, indicative, third, singular",
    GN: "G138",
    gloss: "to choose",
  },
  ἑλόμενος: {
    parse:
      "V-2AMP-NSM｜Verb, 2nd aorist, middle, Participle, nominative, singular, masculine",
    GN: "G138",
    gloss: "to choose",
  },
  αἱρέσεις: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G139",
    gloss: "sect",
  },
  αἱρέσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G139",
    gloss: "sect",
  },
  αἵρεσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G139",
    gloss: "sect",
  },
  αἵρεσις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G139",
    gloss: "sect",
  },
  ᾑρέτισα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G140",
    gloss: "to choose",
  },
  αἱρετικὸν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G141",
    gloss: "schismatic",
  },
  Αἶρε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G142",
    gloss: "to take up",
  },
  αἴρει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G142",
    gloss: "to take up",
  },
  αἴρεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G142",
    gloss: "to take up",
  },
  αἴρεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G142",
    gloss: "to take up",
  },
  αἴρετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G142",
    gloss: "to take up",
  },
  αἰρόμενον: {
    parse:
      "V-PPP-ASM｜Verb, present, passive, Participle, accusative, singular, masculine",
    GN: "G142",
    gloss: "to take up",
  },
  αἴροντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G142",
    gloss: "to take up",
  },
  αἴροντός: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G142",
    gloss: "to take up",
  },
  αἴρων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G142",
    gloss: "to take up",
  },
  αἴρωσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G142",
    gloss: "to take up",
  },
  ἆραι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G142",
    gloss: "to take up",
  },
  ἄραντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G142",
    gloss: "to take up",
  },
  ἄρας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G142",
    gloss: "to take up",
  },
  ἄρατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G142",
    gloss: "to take up",
  },
  Ἄρατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G142",
    gloss: "to take up",
  },
  ἀράτω: {
    parse: "V-AAM-3S｜Verb, aorist, active, imperative, third, singular",
    GN: "G142",
    gloss: "to take up",
  },
  ἄρῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G142",
    gloss: "to take up",
  },
  ἄρῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G142",
    gloss: "to take up",
  },
  ἀρθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G142",
    gloss: "to take up",
  },
  ἀρθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G142",
    gloss: "to take up",
  },
  Ἄρθητι: {
    parse: "V-APM-2S｜Verb, aorist, passive, imperative, second, singular",
    GN: "G142",
    gloss: "to take up",
  },
  ἀρθήτω: {
    parse: "V-APM-3S｜Verb, aorist, passive, imperative, third, singular",
    GN: "G142",
    gloss: "to take up",
  },
  ἀρθῶσιν: {
    parse: "V-APS-3P｜Verb, aorist, passive, subjunctive, third, plural",
    GN: "G142",
    gloss: "to take up",
  },
  ἆρον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G142",
    gloss: "to take up",
  },
  Ἆρον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G142",
    gloss: "to take up",
  },
  ἆρόν: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G142",
    gloss: "to take up",
  },
  ἀροῦσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G142",
    gloss: "to take up",
  },
  ἀροῦσίν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G142",
    gloss: "to take up",
  },
  ἀρῶ: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G142",
    gloss: "to take up",
  },
  ἦραν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G142",
    gloss: "to take up",
  },
  Ἦραν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G142",
    gloss: "to take up",
  },
  ἤρατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G142",
    gloss: "to take up",
  },
  ἦρεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G142",
    gloss: "to take up",
  },
  ἤρθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G142",
    gloss: "to take up",
  },
  ἦρκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G142",
    gloss: "to take up",
  },
  ἠρμένον: {
    parse:
      "V-RPP-ASM｜Verb, perfect, passive, Participle, accusative, singular, masculine",
    GN: "G142",
    gloss: "to take up",
  },
  αἴσθωνται: {
    parse:
      "V-2ADS-3P｜Verb, 2nd aorist, Middle deponent, subjunctive, third, plural",
    GN: "G143",
    gloss: "to perceive",
  },
  αἰσθήσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G144",
    gloss: "insight",
  },
  αἰσθητήρια: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G145",
    gloss: "sense",
  },
  αἰσχροκερδεῖς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G146",
    gloss: "greedy",
  },
  αἰσχροκερδῆ: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G146",
    gloss: "greedy",
  },
  αἰσχροκερδῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G147",
    gloss: "greedily",
  },
  αἰσχρολογίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G148",
    gloss: "obscenity",
  },
  αἰσχρόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G150",
    gloss: "shameful",
  },
  αἰσχρὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G150",
    gloss: "shameful",
  },
  αἰσχροῦ: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G150",
    gloss: "shameful",
  },
  αἰσχρότης: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G151",
    gloss: "obscenity",
  },
  αἰσχύνας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G152",
    gloss: "shame",
  },
  αἰσχύνῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G152",
    gloss: "shame",
  },
  αἰσχύνη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G152",
    gloss: "shame",
  },
  αἰσχύνης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G152",
    gloss: "shame",
  },
  αἰσχυνέσθω: {
    parse: "V-PMM-3S｜Verb, present, middle, imperative, third, singular",
    GN: "G153",
    gloss: "be ashamed",
  },
  αἰσχυνθήσομαι: {
    parse: "V-FPI-1S｜Verb, future, passive, indicative, first, singular",
    GN: "G153",
    gloss: "be ashamed",
  },
  αἰσχυνθῶμεν: {
    parse: "V-APS-1P｜Verb, aorist, passive, subjunctive, first, plural",
    GN: "G153",
    gloss: "be ashamed",
  },
  αἰσχύνομαι: {
    parse: "V-PMI-1S｜Verb, present, middle, indicative, first, singular",
    GN: "G153",
    gloss: "be ashamed",
  },
  αἰτεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G154",
    gloss: "to ask",
  },
  αἰτεῖς: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G154",
    gloss: "to ask",
  },
  αἰτεῖσθαι: {
    parse: "V-PMN｜Verb, present, middle, Infinitive",
    GN: "G154",
    gloss: "to ask",
  },
  αἰτεῖσθε: {
    parse: "V-PMI-2P｜Verb, present, middle, indicative, second, plural",
    GN: "G154",
    gloss: "to ask",
  },
  αἰτεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G154",
    gloss: "to ask",
  },
  Αἰτεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G154",
    gloss: "to ask",
  },
  αἰτείτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G154",
    gloss: "to ask",
  },
  αἰτῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G154",
    gloss: "to ask",
  },
  αἰτήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G154",
    gloss: "to ask",
  },
  αἰτήσασθε: {
    parse: "V-AMM-2P｜Verb, aorist, middle, imperative, second, plural",
    GN: "G154",
    gloss: "to ask",
  },
  αἰτήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G154",
    gloss: "to ask",
  },
  αἰτήσεσθε: {
    parse: "V-FMI-2P｜Verb, future, middle, indicative, second, plural",
    GN: "G154",
    gloss: "to ask",
  },
  αἰτήσῃ: {
    parse: "V-AMS-2S｜Verb, aorist, middle, subjunctive, second, singular",
    GN: "G154",
    gloss: "to ask",
  },
  αἰτήσῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G154",
    gloss: "to ask",
  },
  αἰτήσηται: {
    parse: "V-AMS-3S｜Verb, aorist, middle, subjunctive, third, singular",
    GN: "G154",
    gloss: "to ask",
  },
  αἰτήσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G154",
    gloss: "to ask",
  },
  αἰτήσητέ: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G154",
    gloss: "to ask",
  },
  Αἴτησόν: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G154",
    gloss: "to ask",
  },
  αἰτήσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G154",
    gloss: "to ask",
  },
  αἰτήσωμαι: {
    parse: "V-AMS-1S｜Verb, aorist, middle, subjunctive, first, singular",
    GN: "G154",
    gloss: "to ask",
  },
  αἰτήσωμέν: {
    parse: "V-AAS-1P｜Verb, aorist, active, subjunctive, first, plural",
    GN: "G154",
    gloss: "to ask",
  },
  αἰτήσωνται: {
    parse: "V-AMS-3P｜Verb, aorist, middle, subjunctive, third, plural",
    GN: "G154",
    gloss: "to ask",
  },
  αἰτοῦμαι: {
    parse: "V-PMI-1S｜Verb, present, middle, indicative, first, singular",
    GN: "G154",
    gloss: "to ask",
  },
  αἰτούμεθα: {
    parse: "V-PMI-1P｜Verb, present, middle, indicative, first, plural",
    GN: "G154",
    gloss: "to ask",
  },
  αἰτούμενοι: {
    parse:
      "V-PMP-NPM｜Verb, present, middle, Participle, nominative, plural, masculine",
    GN: "G154",
    gloss: "to ask",
  },
  αἰτοῦντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G154",
    gloss: "to ask",
  },
  αἰτοῦντί: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G154",
    gloss: "to ask",
  },
  αἰτοῦσά: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G154",
    gloss: "to ask",
  },
  αἰτοῦσιν: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G154",
    gloss: "to ask",
  },
  αἰτώμεθα: {
    parse: "V-PMS-1P｜Verb, present, middle, subjunctive, first, plural",
    GN: "G154",
    gloss: "to ask",
  },
  αἰτῶμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G154",
    gloss: "to ask",
  },
  αἰτῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G154",
    gloss: "to ask",
  },
  ᾐτήκαμεν: {
    parse: "V-RAI-1P｜Verb, perfect, active, indicative, first, plural",
    GN: "G154",
    gloss: "to ask",
  },
  ᾐτήσαντο: {
    parse: "V-AMI-3P｜Verb, aorist, middle, indicative, third, plural",
    GN: "G154",
    gloss: "to ask",
  },
  ᾔτησας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G154",
    gloss: "to ask",
  },
  ᾐτήσασθε: {
    parse: "V-AMI-2P｜Verb, aorist, middle, indicative, second, plural",
    GN: "G154",
    gloss: "to ask",
  },
  ᾐτήσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G154",
    gloss: "to ask",
  },
  ᾐτήσατο: {
    parse: "V-AMI-3S｜Verb, aorist, middle, indicative, third, singular",
    GN: "G154",
    gloss: "to ask",
  },
  ᾐτοῦντο: {
    parse: "V-IMI-3P｜Verb, imperfect, middle, indicative, third, plural",
    GN: "G154",
    gloss: "to ask",
  },
  αἴτημα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G155",
    gloss: "request",
  },
  αἰτήματα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G155",
    gloss: "request",
  },
  αἰτία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G156",
    gloss: "cause/charge",
  },
  αἰτίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G156",
    gloss: "cause/charge",
  },
  αἰτίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G156",
    gloss: "cause/charge",
  },
  αἰτιώματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G157",
    gloss: "charge",
  },
  αἴτιον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G159",
    gloss: "causer",
  },
  αἴτιος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G159",
    gloss: "causer",
  },
  αἰτίου: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G159",
    gloss: "causer",
  },
  αἰφνίδιος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G160",
    gloss: "sudden",
  },
  αἰχμαλωσίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G161",
    gloss: "captivity",
  },
  ᾐχμαλώτευσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G162",
    gloss: "to take captive",
  },
  αἰχμαλωτίζοντά: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G163",
    gloss: "to capture",
  },
  αἰχμαλωτίζοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G163",
    gloss: "to capture",
  },
  αἰχμαλωτισθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G163",
    gloss: "to capture",
  },
  αἰχμαλώτοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G164",
    gloss: "captive",
  },
  αἰῶνα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G165",
    gloss: "eternity",
  },
  αἰῶνας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G165",
    gloss: "eternity",
  },
  αἰῶνι: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G165",
    gloss: "eternity",
  },
  αἰῶνος: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G165",
    gloss: "eternity",
  },
  αἰῶνός: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G165",
    gloss: "eternity",
  },
  αἰώνων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G165",
    gloss: "eternity",
  },
  αἰῶσιν: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G165",
    gloss: "eternity",
  },
  αἰώνια: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G166",
    gloss: "eternal",
  },
  αἰωνίαν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G166",
    gloss: "eternal",
  },
  αἰωνίοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G166",
    gloss: "eternal",
  },
  αἰώνιον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G166",
    gloss: "eternal",
  },
  αἰώνιος: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G166",
    gloss: "eternal",
  },
  αἰώνιός: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G166",
    gloss: "eternal",
  },
  αἰωνίου: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G166",
    gloss: "eternal",
  },
  αἰωνίους: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G166",
    gloss: "eternal",
  },
  αἰωνίων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G166",
    gloss: "eternal",
  },
  ἀκαθαρσίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G167",
    gloss: "impurity",
  },
  ἀκαθαρσία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G167",
    gloss: "impurity",
  },
  ἀκαθαρσίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G167",
    gloss: "impurity",
  },
  ἀκαθαρσίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G167",
    gloss: "impurity",
  },
  ἀκάθαρτα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G169",
    gloss: "unclean",
  },
  ἀκάθαρτά: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G169",
    gloss: "unclean",
  },
  ἀκαθάρτοις: {
    parse: "A-DPN｜Adjective, dative, plural, neuter",
    GN: "G169",
    gloss: "unclean",
  },
  ἀκάθαρτον: {
    // or nominative
    parse: "A-VSN｜Adjective, Vocative, singular, neuter",
    GN: "G169",
    gloss: "unclean",
  },
  ἀκάθαρτος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G169",
    gloss: "unclean",
  },
  ἀκαθάρτου: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G169",
    gloss: "unclean",
  },
  ἀκαθάρτῳ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G169",
    gloss: "unclean",
  },
  ἀκαθάρτων: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G169",
    gloss: "unclean",
  },
  ἠκαιρεῖσθε: {
    parse:
      "V-INI-2P｜Verb, imperfect, Middle or Passive deponent, indicative, second, plural",
    GN: "G170",
    gloss: "to lack opportunity",
  },
  ἀκαίρως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G171",
    gloss: "unseasonably",
  },
  ἄκακος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G172",
    gloss: "innocent",
  },
  ἀκάκων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G172",
    gloss: "innocent",
  },
  ἄκανθαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G173",
    gloss: "a thorn",
  },
  ἀκάνθας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G173",
    gloss: "a thorn",
  },
  ἀκανθῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G173",
    gloss: "a thorn",
  },
  ἀκάνθινον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G174",
    gloss: "thorny",
  },
  ἄκαρπα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G175",
    gloss: "unfruitful",
  },
  ἄκαρποι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G175",
    gloss: "unfruitful",
  },
  ἀκάρποις: {
    parse: "A-DPN｜Adjective, dative, plural, neuter",
    GN: "G175",
    gloss: "unfruitful",
  },
  ἄκαρπος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G175",
    gloss: "unfruitful",
  },
  ἄκαρπός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G175",
    gloss: "unfruitful",
  },
  ἀκάρπους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G175",
    gloss: "unfruitful",
  },
  ἀκατάγνωστον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G176",
    gloss: "beyond reproach",
  },
  ἀκατακάλυπτον: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G177",
    gloss: "uncovered",
  },
  ἀκατακαλύπτῳ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G177",
    gloss: "uncovered",
  },
  ἀκατάκριτον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G178",
    gloss: "uncondemned",
  },
  ἀκατακρίτους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G178",
    gloss: "uncondemned",
  },
  ἀκαταλύτου: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G179",
    gloss: "indestructible",
  },
  ἀκαταπαύστους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G180",
    gloss: "unceasing",
  },
  ἀκαταστασία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G181",
    gloss: "disorder",
  },
  ἀκαταστασίαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G181",
    gloss: "disorder",
  },
  ἀκαταστασίαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G181",
    gloss: "disorder",
  },
  ἀκαταστασίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G181",
    gloss: "disorder",
  },
  ἀκατάστατον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G182",
    gloss: "restless",
  },
  ἀκατάστατος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G182",
    gloss: "restless",
  },
  Ἁκελδαμάχ: {
    parse: "N-ASN-L｜Noun, accusative, singular, neuter, Location",
    GN: "G184",
    gloss: "Akeldama",
  },
  ἀκέραιοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G185",
    gloss: "innocent",
  },
  ἀκεραίους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G185",
    gloss: "innocent",
  },
  ἀκλινῆ: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G186",
    gloss: "unwavering",
  },
  ἤκμασαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G187",
    gloss: "to ripen",
  },
  Ἀκμὴν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G188",
    gloss: "still",
  },
  ἀκοαί: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G189",
    gloss: "hearing",
  },
  ἀκοαῖς: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G189",
    gloss: "hearing",
  },
  ἀκοὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G189",
    gloss: "hearing",
  },
  ἀκοῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G189",
    gloss: "hearing",
  },
  Ἀκοῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G189",
    gloss: "hearing",
  },
  ἀκοή: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G189",
    gloss: "hearing",
  },
  ἀκοὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G189",
    gloss: "hearing",
  },
  ἀκοήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G189",
    gloss: "hearing",
  },
  ἀκοὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G189",
    gloss: "hearing",
  },
  ἀκοῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G189",
    gloss: "hearing",
  },
  ἀκολουθεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G190",
    gloss: "to follow",
  },
  ἀκολούθει: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G190",
    gloss: "to follow",
  },
  Ἀκολούθει: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G190",
    gloss: "to follow",
  },
  ἀκολουθεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G190",
    gloss: "to follow",
  },
  ἀκολουθείτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G190",
    gloss: "to follow",
  },
  ἀκολουθῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G190",
    gloss: "to follow",
  },
  ἀκολουθήσαντές: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G190",
    gloss: "to follow",
  },
  ἀκολουθησάντων: {
    parse:
      "V-AAP-GPM｜Verb, aorist, active, Participle, genitive, plural, masculine",
    GN: "G190",
    gloss: "to follow",
  },
  ἀκολουθήσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G190",
    gloss: "to follow",
  },
  ἀκολουθήσεις: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G190",
    gloss: "to follow",
  },
  ἀκολουθήσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G190",
    gloss: "to follow",
  },
  ἀκολουθήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G190",
    gloss: "to follow",
  },
  Ἀκολουθήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G190",
    gloss: "to follow",
  },
  ἀκολουθοῦντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G190",
    gloss: "to follow",
  },
  ἀκολουθοῦντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G190",
    gloss: "to follow",
  },
  ἀκολουθοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G190",
    gloss: "to follow",
  },
  ἀκολουθοῦντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G190",
    gloss: "to follow",
  },
  ἀκολουθούσης: {
    parse:
      "V-PAP-GSF｜Verb, present, active, Participle, genitive, singular, feminine",
    GN: "G190",
    gloss: "to follow",
  },
  ἀκολουθοῦσιν: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G190",
    gloss: "to follow",
  },
  ἀκολουθοῦσίν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G190",
    gloss: "to follow",
  },
  ἀκολουθῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G190",
    gloss: "to follow",
  },
  ἠκολούθει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G190",
    gloss: "to follow",
  },
  Ἠκολούθει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G190",
    gloss: "to follow",
  },
  ἠκολουθήκαμέν: {
    parse: "V-RAI-1P｜Verb, perfect, active, indicative, first, plural",
    GN: "G190",
    gloss: "to follow",
  },
  ἠκολουθήσαμέν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G190",
    gloss: "to follow",
  },
  ἠκολούθησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G190",
    gloss: "to follow",
  },
  ἠκολούθησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G190",
    gloss: "to follow",
  },
  ἠκολούθουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G190",
    gloss: "to follow",
  },
  ἀκηκόαμεν: {
    parse: "V-2RAI-1P｜Verb, 2nd perfect, active, indicative, first, plural",
    GN: "G191",
    gloss: "to hear",
  },
  Ἀκηκόαμεν: {
    parse: "V-2RAI-1P｜Verb, 2nd perfect, active, indicative, first, plural",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκηκόασιν: {
    parse: "V-2RAI-3P｜Verb, 2nd perfect, active, indicative, third, plural",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκηκόατε: {
    parse: "V-2RAI-2P｜Verb, 2nd perfect, active, indicative, second, plural",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκηκοότας: {
    parse:
      "V-2RAP-APM｜Verb, 2nd perfect, active, Participle, accusative, plural, masculine",
    GN: "G191",
    gloss: "to hear",
  },
  Ἄκουε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκούει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκούειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκούεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G191",
    gloss: "to hear",
  },
  Ἀκούεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκούεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκούετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G191",
    gloss: "to hear",
  },
  Ἀκούετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκουέτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκούομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκούοντα: {
    parse:
      "V-PAP-NPN｜Verb, present, active, Participle, nominative, plural, neuter",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκούοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκούοντάς: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκούοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G191",
    gloss: "to hear",
  },
  Ἀκούοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκούοντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκούοντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G191",
    gloss: "to hear",
  },
  Ἀκούοντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκουόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G191",
    gloss: "to hear",
  },
  Ἀκουόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκούουσιν: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκοῦσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκοῦσαί: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκούσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G191",
    gloss: "to hear",
  },
  Ἀκούσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκουσάντων: {
    parse:
      "V-AAP-GPM｜Verb, aorist, active, Participle, genitive, plural, masculine",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκούσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G191",
    gloss: "to hear",
  },
  Ἀκούσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκούσασα: {
    parse:
      "V-AAP-NSF｜Verb, aorist, active, Participle, nominative, singular, feminine",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκούσασιν: {
    parse:
      "V-AAP-DPM｜Verb, aorist, active, Participle, dative, plural, masculine",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκούσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G191",
    gloss: "to hear",
  },
  Ἀκούσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκούσατέ: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G191",
    gloss: "to hear",
  },
  Ἀκούσατέ: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκουσάτω: {
    parse: "V-AAM-3S｜Verb, aorist, active, imperative, third, singular",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκουσάτωσαν: {
    parse: "V-AAM-3P｜Verb, aorist, active, imperative, third, plural",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκούσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκούσεσθε: {
    parse: "V-FDI-2P｜Verb, future, middle, indicative, second, plural",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκούσετε: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκούσῃ: {
    parse: "V-FDI-2S｜Verb, future, middle, indicative, second, singular",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκούσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκουσθεῖσιν: {
    parse:
      "V-APP-DPN｜Verb, aorist, passive, Participle, dative, plural, neuter",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκουσθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκουσθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G191",
    gloss: "to hear",
  },
  Ἀκουσόμεθά: {
    parse: "V-FDI-1P｜Verb, future, middle, indicative, first, plural",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκούσονται: {
    parse: "V-FDI-3P｜Verb, future, middle, indicative, third, plural",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκούσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκούσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκούω: {
    parse: "V-PAS-1S｜Verb, present, active, subjunctive, first, singular",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκούων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκούωσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G191",
    gloss: "to hear",
  },
  ἤκουεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G191",
    gloss: "to hear",
  },
  ἤκουον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G191",
    gloss: "to hear",
  },
  Ἤκουον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G191",
    gloss: "to hear",
  },
  ἤκουσα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G191",
    gloss: "to hear",
  },
  ἠκούσαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G191",
    gloss: "to hear",
  },
  ἤκουσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G191",
    gloss: "to hear",
  },
  Ἤκουσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G191",
    gloss: "to hear",
  },
  ἤκουσας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G191",
    gloss: "to hear",
  },
  ἤκουσάς: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G191",
    gloss: "to hear",
  },
  ἠκούσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G191",
    gloss: "to hear",
  },
  Ἠκούσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G191",
    gloss: "to hear",
  },
  ἠκούσατέ: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G191",
    gloss: "to hear",
  },
  ἤκουσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G191",
    gloss: "to hear",
  },
  Ἤκουσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G191",
    gloss: "to hear",
  },
  ἠκούσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G191",
    gloss: "to hear",
  },
  ἀκρασίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G192",
    gloss: "self-indulgence",
  },
  ἀκρασίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G192",
    gloss: "self-indulgence",
  },
  ἀκρατεῖς: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G193",
    gloss: "intemperate",
  },
  ἀκράτου: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G194",
    gloss: "undiluted",
  },
  ἀκρίβειαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G195",
    gloss: "strictness",
  },
  ἀκριβεστάτην: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G196",
    gloss: "strictest",
  },
  ἀκριβέστερον: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G0197",
  },
  ἠκρίβωσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G198",
    gloss: "be exactly",
  },
  ἀκριβῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G199",
    gloss: "exactly",
  },
  ἀκρίδας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G200",
    gloss: "locust",
  },
  ἀκρίδες: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G200",
    gloss: "locust",
  },
  ἀκρίδων: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G200",
    gloss: "locust",
  },
  ἀκροατήριον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G201",
    gloss: "hall",
  },
  ἀκροαταὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G202",
    gloss: "hearer",
  },
  ἀκροατὴς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G202",
    gloss: "hearer",
  },
  ἀκροβυστίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G203",
    gloss: "uncircumcision",
  },
  ἀκροβυστία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G203",
    gloss: "uncircumcision",
  },
  ἀκροβυστίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G203",
    gloss: "uncircumcision",
  },
  ἀκροβυστίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G203",
    gloss: "uncircumcision",
  },
  ἀκρογωνιαῖον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G204",
    gloss: "cornerstone",
  },
  ἀκρογωνιαίου: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G204",
    gloss: "cornerstone",
  },
  ἀκροθινίων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G205",
    gloss: "fine spoils",
  },
  ἄκρον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G206",
    gloss: "end",
  },
  ἄκρου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G206",
    gloss: "end",
  },
  ἄκρων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G206",
    gloss: "end",
  },
  Ἀκύλαν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G207",
    gloss: "Aquila",
  },
  Ἀκύλας: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G207",
    gloss: "Aquila",
  },
  ἀκυροῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G208",
    gloss: "to nullify",
  },
  ἀκυροῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G208",
    gloss: "to nullify",
  },
  ἠκυρώσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G208",
    gloss: "to nullify",
  },
  ἀκωλύτως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G209",
    gloss: "freely",
  },
  ἄκων: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G210",
    gloss: "unwilling",
  },
  ἀλάβαστρον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G211",
    gloss: "jar",
  },
  ἀλαζονεία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G212",
    gloss: "boasting",
  },
  ἀλαζονείαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G212",
    gloss: "boasting",
  },
  ἀλαζόνας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G213",
    gloss: "braggart",
  },
  ἀλαζόνες: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G213",
    gloss: "braggart",
  },
  ἀλαλάζον: {
    parse:
      "V-PAP-NSN｜Verb, present, active, Participle, nominative, singular, neuter",
    GN: "G214",
    gloss: "to wail",
  },
  ἀλαλάζοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G214",
    gloss: "to wail",
  },
  ἀλαλήτοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G215",
    gloss: "inexpressible",
  },
  ἄλαλον: {
    parse: "A-VSN｜Adjective, Vocative or nominative, singular, neuter",
    GN: "G216",
    gloss: "mute",
  },
  ἀλάλους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G216",
    gloss: "mute",
  },
  ἅλα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G217",
    gloss: "salt",
  },
  ἅλας: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G217",
    gloss: "salt",
  },
  ἅλατι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G217",
    gloss: "salt",
  },
  ἄλειψαί: {
    parse: "V-AMM-2S｜Verb, aorist, middle, imperative, second, singular",
    GN: "G218",
    gloss: "to anoint",
  },
  ἀλείψαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G218",
    gloss: "to anoint",
  },
  ἀλείψασα: {
    parse:
      "V-AAP-NSF｜Verb, aorist, active, Participle, nominative, singular, feminine",
    GN: "G218",
    gloss: "to anoint",
  },
  ἀλείψωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G218",
    gloss: "to anoint",
  },
  ἤλειφεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G218",
    gloss: "to anoint",
  },
  ἤλειφον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G218",
    gloss: "to anoint",
  },
  ἤλειψας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G218",
    gloss: "to anoint",
  },
  ἤλειψεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G218",
    gloss: "to anoint",
  },
  ἀλεκτοροφωνίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G219",
    gloss: "crowing",
  },
  ἀλέκτορα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G220",
    gloss: "rooster",
  },
  ἀλέκτωρ: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G220",
    gloss: "rooster",
  },
  Ἀλεξανδρεὺς: {
    parse: "N-NSM-LG｜Noun, nominative, singular, masculine, Location Gentilic",
    GN: "G0221",
  },
  Ἀλεξανδρέων: {
    parse: "N-GPM-LG｜Noun, genitive, plural, masculine, Location Gentilic",
    GN: "G0221",
  },
  Ἀλεξανδρῖνον: {
    parse:
      "A-ASN-LG｜Adjective, accusative, singular, neuter, Location Gentilic",
    GN: "G222",
    gloss: "Alexandria",
  },
  Ἀλεξανδρίνῳ: {
    parse: "A-DSN-LG｜Adjective, dative, singular, neuter, Location Gentilic",
    GN: "G222",
    gloss: "Alexandria",
  },
  Ἀλέξανδρον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G223",
    gloss: "Alexander",
  },
  Ἀλέξανδρος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G223",
    gloss: "Alexander",
  },
  Ἀλεξάνδρου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G223",
    gloss: "Alexander",
  },
  ἀλεύρου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G224",
    gloss: "flour",
  },
  ἀληθείᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G225",
    gloss: "truth",
  },
  ἀλήθεια: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G225",
    gloss: "truth",
  },
  ἀλήθειά: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G225",
    gloss: "truth",
  },
  ἀλήθειαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G225",
    gloss: "truth",
  },
  Ἀλήθειαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G225",
    gloss: "truth",
  },
  ἀληθείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G225",
    gloss: "truth",
  },
  ἀληθεύοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G226",
    gloss: "be truthful",
  },
  ἀληθεύων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G226",
    gloss: "be truthful",
  },
  ἀληθεῖς: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G227",
    gloss: "true",
  },
  ἀληθὲς: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G227",
    gloss: "true",
  },
  ἀληθές: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G227",
    gloss: "true",
  },
  ἀληθῆ: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G227",
    gloss: "true",
  },
  ἀληθής: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G227",
    gloss: "true",
  },
  ἀληθὴς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G227",
    gloss: "true",
  },
  ἀληθοῦς: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G227",
    gloss: "true",
  },
  ἀληθιναὶ: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G228",
    gloss: "true",
  },
  ἀληθινή: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G228",
    gloss: "true",
  },
  ἀληθινὴ: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G228",
    gloss: "true",
  },
  ἀληθινῆς: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G228",
    gloss: "true",
  },
  ἀληθινοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G228",
    gloss: "true",
  },
  ἀληθινοὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G228",
    gloss: "true",
  },
  ἀληθινόν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G228",
    gloss: "true",
  },
  ἀληθινὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G228",
    gloss: "true",
  },
  ἀληθινός: {
    // or nominative
    parse: "A-VSM｜Adjective, Vocative, singular, masculine",
    GN: "G228",
    gloss: "true",
  },
  ἀληθινὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G228",
    gloss: "true",
  },
  ἀληθινῷ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G228",
    gloss: "true",
  },
  ἀληθινῶν: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G228",
    gloss: "true",
  },
  ἀλήθουσαι: {
    parse:
      "V-PAP-NPF｜Verb, present, active, Participle, nominative, plural, feminine",
    GN: "G229",
    gloss: "to grind",
  },
  ἀληθῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G230",
    gloss: "truly",
  },
  Ἀληθῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G230",
    gloss: "truly",
  },
  ἁλιεῖς: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G231",
    gloss: "fisherman",
  },
  ἁλιεύειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G232",
    gloss: "to fish",
  },
  ἁλισθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G233",
    gloss: "to salt",
  },
  ἀλισγημάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G234",
    gloss: "defilement",
  },
  ἀλλʼ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G235",
    gloss: "but",
  },
  "ἀλλ᾿": {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G235",
    gloss: "but",
  },
  Ἀλλʼ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G235",
    gloss: "but",
  },
  "Ἀλλ᾿": {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G235",
    gloss: "but",
  },
  ἀλλά: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G235",
    gloss: "but",
  },
  ἀλλὰ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G235",
    gloss: "but",
  },
  Ἀλλὰ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G235",
    gloss: "but",
  },
  ἀλλαγησόμεθα: {
    parse: "V-2FPI-1P｜Verb, 2nd future, passive, indicative, first, plural",
    GN: "G236",
    gloss: "to change",
  },
  ἀλλαγήσονται: {
    parse: "V-2FPI-3P｜Verb, 2nd future, passive, indicative, third, plural",
    GN: "G236",
    gloss: "to change",
  },
  ἀλλάξαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G236",
    gloss: "to change",
  },
  ἀλλάξει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G236",
    gloss: "to change",
  },
  ἤλλαξαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G236",
    gloss: "to change",
  },
  ἀλλαχόθεν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G237",
    gloss: "from elsewhere",
  },
  ἀλλαχοῦ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G237",
    gloss: "from elsewhere",
  },
  ἀλληγορούμενα: {
    parse:
      "V-PPP-NPN｜Verb, present, passive, Participle, nominative, plural, neuter",
    GN: "G238",
    gloss: "to use an analogy",
  },
  Ἁλληλουϊά: {
    parse: "PRT｜Particle, disjunctive particle",
    GN: "G239",
    gloss: "hallelujah",
  },
  ἀλλήλοις: {
    parse: "C-DPN｜Reciprocal pronoun, dative, plural, neuter",
    GN: "G240",
    gloss: "one another",
  },
  ἀλλήλους: {
    parse: "C-APM｜Reciprocal pronoun, accusative, plural, masculine",
    GN: "G240",
    gloss: "one another",
  },
  ἀλλήλων: {
    parse: "C-GPM｜Reciprocal pronoun, genitive, plural, masculine",
    GN: "G240",
    gloss: "one another",
  },
  ἀλλογενὴς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G241",
    gloss: "foreign",
  },
  ἁλλόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G242",
    gloss: "to spring",
  },
  ἁλλομένου: {
    parse:
      "V-PNP-GSN｜Verb, present, Middle or Passive deponent, Participle, genitive, singular, neuter",
    GN: "G242",
    gloss: "to spring",
  },
  ἥλατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G242",
    gloss: "to spring",
  },
  ἄλλα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G243",
    gloss: "another",
  },
  ἄλλαι: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G243",
    gloss: "another",
  },
  ἄλλας: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G243",
    gloss: "another",
  },
  ἄλλη: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G243",
    gloss: "another",
  },
  ἄλλην: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G243",
    gloss: "another",
  },
  Ἄλλην: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G243",
    gloss: "another",
  },
  ἄλλης: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G243",
    gloss: "another",
  },
  ἄλλο: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G243",
    gloss: "another",
  },
  ἄλλοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G243",
    gloss: "another",
  },
  ἄλλοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G243",
    gloss: "another",
  },
  ἄλλον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G243",
    gloss: "another",
  },
  ἄλλος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G243",
    gloss: "another",
  },
  Ἄλλος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G243",
    gloss: "another",
  },
  ἄλλου: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G243",
    gloss: "another",
  },
  ἄλλους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G243",
    gloss: "another",
  },
  Ἄλλους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G243",
    gloss: "another",
  },
  ἄλλῳ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G243",
    gloss: "another",
  },
  ἄλλων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G243",
    gloss: "another",
  },
  ἀλλοτριεπίσκοπος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G244",
    gloss: "meddler",
  },
  ἀλλοτρίᾳ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G245",
    gloss: "another’s",
  },
  ἀλλοτρίαις: {
    parse: "A-DPF｜Adjective, dative, plural, feminine",
    GN: "G245",
    gloss: "another’s",
  },
  ἀλλοτρίαν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G245",
    gloss: "another’s",
  },
  ἀλλοτρίοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G245",
    gloss: "another’s",
  },
  ἀλλότριον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G245",
    gloss: "another’s",
  },
  ἀλλοτρίῳ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G245",
    gloss: "another’s",
  },
  ἀλλοτρίων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G245",
    gloss: "another’s",
  },
  ἀλλοφύλῳ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G246",
    gloss: "foreigner",
  },
  ἄλλως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G247",
    gloss: "otherwise",
  },
  ἀλοῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G248",
    gloss: "to thresh",
  },
  ἀλοῶντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G248",
    gloss: "to thresh",
  },
  ἄλογα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G249",
    gloss: "unreasonable",
  },
  ἄλογον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G249",
    gloss: "unreasonable",
  },
  ἀλόης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G250",
    gloss: "aloes",
  },
  ἁλυκὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G252",
    gloss: "salty",
  },
  ἀλυπότερος: {
    parse: "A-NSM-C｜Adjective, nominative, singular, masculine, Comparative",
    GN: "G253",
    gloss: "without anxiety",
  },
  ἁλύσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G254",
    gloss: "chain",
  },
  ἁλύσεις: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G254",
    gloss: "chain",
  },
  ἁλύσεσιν: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G254",
    gloss: "chain",
  },
  ἁλύσεσι: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G254",
    gloss: "chain",
  },
  ἅλυσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G254",
    gloss: "chain",
  },
  ἅλυσίν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G254",
    gloss: "chain",
  },
  ἀλυσιτελὲς: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G255",
    gloss: "unprofitable",
  },
  Ἁλφαίου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G256",
    gloss: "Alphaeus",
  },
  ἅλωνα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G257",
    gloss: "threshing-floor",
  },
  ἀλώπεκες: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G258",
    gloss: "fox",
  },
  ἀλώπεκι: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G258",
    gloss: "fox",
  },
  ἅλωσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G259",
    gloss: "capture",
  },
  ἅμα: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G260",
    gloss: "together",
  },
  ἀμαθεῖς: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G261",
    gloss: "ignorant",
  },
  ἀμαράντινον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G262",
    gloss: "unfading",
  },
  ἀμάραντον: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G263",
    gloss: "unfading",
  },
  ἁμάρτανε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G264",
    gloss: "to sin",
  },
  ἁμαρτάνει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G264",
    gloss: "to sin",
  },
  ἁμαρτάνειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G264",
    gloss: "to sin",
  },
  ἁμαρτάνετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G264",
    gloss: "to sin",
  },
  ἁμαρτάνοντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G264",
    gloss: "to sin",
  },
  ἁμαρτάνοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G264",
    gloss: "to sin",
  },
  ἁμαρτάνοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G264",
    gloss: "to sin",
  },
  ἁμαρτανόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G264",
    gloss: "to sin",
  },
  ἁμαρτάνουσιν: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G264",
    gloss: "to sin",
  },
  ἁμαρτάνων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G264",
    gloss: "to sin",
  },
  ἁμάρτῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G264",
    gloss: "to sin",
  },
  ἁμαρτήσαντας: {
    parse:
      "V-AAP-APM｜Verb, aorist, active, Participle, accusative, plural, masculine",
    GN: "G264",
    gloss: "to sin",
  },
  ἁμαρτήσαντος: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G264",
    gloss: "to sin",
  },
  ἁμαρτησάντων: {
    parse:
      "V-AAP-GPM｜Verb, aorist, active, Participle, genitive, plural, masculine",
    GN: "G264",
    gloss: "to sin",
  },
  ἁμαρτήσασιν: {
    parse:
      "V-AAP-DPM｜Verb, aorist, active, Participle, dative, plural, masculine",
    GN: "G264",
    gloss: "to sin",
  },
  ἁμαρτήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G264",
    gloss: "to sin",
  },
  ἁμαρτήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G264",
    gloss: "to sin",
  },
  ἁμαρτήσωμεν: {
    parse: "V-AAS-1P｜Verb, aorist, active, subjunctive, first, plural",
    GN: "G264",
    gloss: "to sin",
  },
  ἁμάρτητε: {
    parse: "V-2AAS-2P｜Verb, 2nd aorist, active, subjunctive, second, plural",
    GN: "G264",
    gloss: "to sin",
  },
  ἥμαρτεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G264",
    gloss: "to sin",
  },
  ἥμαρτες: {
    parse: "V-2AAI-2S｜Verb, 2nd aorist, active, indicative, second, singular",
    GN: "G264",
    gloss: "to sin",
  },
  ἡμαρτήκαμεν: {
    parse: "V-RAI-1P｜Verb, perfect, active, indicative, first, plural",
    GN: "G264",
    gloss: "to sin",
  },
  ἥμαρτον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural, OR first, singular",
    GN: "G264",
    gloss: "to sin",
  },
  Ἥμαρτον: {
    parse:
      "V-2AAI-1S｜Verb, 2nd aorist, active, indicative, first, singular OR third, plural",
    GN: "G264",
    gloss: "to sin",
  },
  ἁμάρτημα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G265",
    gloss: "sin",
  },
  ἁμαρτήματα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G265",
    gloss: "sin",
  },
  ἁμαρτήματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G265",
    gloss: "sin",
  },
  ἁμαρτημάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G265",
    gloss: "sin",
  },
  ἁμαρτίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G266",
    gloss: "sin",
  },
  ἁμαρτία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G266",
    gloss: "sin",
  },
  ἁμαρτίαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G266",
    gloss: "sin",
  },
  ἁμαρτίαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G266",
    gloss: "sin",
  },
  ἁμαρτίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G266",
    gloss: "sin",
  },
  ἁμαρτίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G266",
    gloss: "sin",
  },
  ἁμαρτιῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G266",
    gloss: "sin",
  },
  ἀμάρτυρον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G267",
    gloss: "without witness",
  },
  ἁμαρτωλοί: {
    parse: "A-VPM｜Adjective, Vocative or nominative, plural, masculine",
    GN: "G268",
    gloss: "sinful",
  },
  ἁμαρτωλοὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G268",
    gloss: "sinful",
  },
  ἁμαρτωλοῖς: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G268",
    gloss: "sinful",
  },
  ἁμαρτωλὸν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G268",
    gloss: "sinful",
  },
  ἁμαρτωλός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G268",
    gloss: "sinful",
  },
  ἁμαρτωλὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G268",
    gloss: "sinful",
  },
  ἁμαρτωλούς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G268",
    gloss: "sinful",
  },
  ἁμαρτωλοὺς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G268",
    gloss: "sinful",
  },
  ἁμαρτωλῷ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G268",
    gloss: "sinful",
  },
  ἁμαρτωλῶν: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G268",
    gloss: "sinful",
  },
  ἄμαχον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G269",
    gloss: "peacable",
  },
  ἀμάχους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G269",
    gloss: "peacable",
  },
  ἀμησάντων: {
    parse:
      "V-AAP-GPM｜Verb, aorist, active, Participle, genitive, plural, masculine",
    GN: "G270",
    gloss: "to mow",
  },
  ἀμέθυστος: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G271",
    gloss: "amethyst",
  },
  ἀμέλει: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G272",
    gloss: "to neglect",
  },
  ἀμελήσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G272",
    gloss: "to neglect",
  },
  ἠμέλησα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G272",
    gloss: "to neglect",
  },
  ἄμεμπτοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G273",
    gloss: "blameless",
  },
  ἄμεμπτος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G273",
    gloss: "blameless",
  },
  ἀμέμπτους: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G273",
    gloss: "blameless",
  },
  ἀμέμπτως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G274",
    gloss: "blamelessly",
  },
  ἀμερίμνους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G275",
    gloss: "untroubled",
  },
  ἀμετάθετον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G276",
    gloss: "unchangeable",
  },
  ἀμεταθέτων: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G276",
    gloss: "unchangeable",
  },
  ἀμετακίνητοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G277",
    gloss: "immovable",
  },
  ἀμεταμέλητα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G278",
    gloss: "irrevocable",
  },
  ἀμεταμέλητον: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G278",
    gloss: "irrevocable",
  },
  ἀμετανόητον: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G279",
    gloss: "unrepentant",
  },
  ἄμετρα: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G280",
    gloss: "immoderate",
  },
  ἀμήν: {
    parse: "HEB｜Hebrew transliterated word (indeclinable)",
    GN: "G281",
    gloss: "amen",
  },
  Ἀμήν: {
    parse: "HEB｜Hebrew transliterated word (indeclinable)",
    GN: "G281",
    gloss: "amen",
  },
  ἀμὴν: {
    parse: "HEB｜Hebrew transliterated word (indeclinable)",
    GN: "G281",
    gloss: "amen",
  },
  Ἀμὴν: {
    parse: "HEB｜Hebrew transliterated word (indeclinable)",
    GN: "G281",
    gloss: "amen",
  },
  ἀμήτωρ: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G282",
    gloss: "motherless",
  },
  ἀμίαντον: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G283",
    gloss: "pure",
  },
  ἀμίαντος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G283",
    gloss: "pure",
  },
  Ἀμιναδάβ: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G284",
    gloss: "Amminadab",
  },
  Ἀμιναδὰβ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G284",
    gloss: "Amminadab",
  },
  ἄμμον: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G285",
    gloss: "sand",
  },
  ἄμμος: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G285",
    gloss: "sand",
  },
  ἀμνὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G286",
    gloss: "lamb",
  },
  ἀμνοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G286",
    gloss: "lamb",
  },
  ἀμοιβὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G287",
    gloss: "repayment",
  },
  ἄμπελον: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G288",
    gloss: "vine",
  },
  ἄμπελος: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G288",
    gloss: "vine",
  },
  ἀμπέλου: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G288",
    gloss: "vine",
  },
  ἀμπέλῳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G288",
    gloss: "vine",
  },
  ἀμπελουργόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G289",
    gloss: "a vine-worker",
  },
  ἀμπελῶνα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G290",
    gloss: "vineyard",
  },
  Ἀμπελῶνα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G290",
    gloss: "vineyard",
  },
  ἀμπελῶνι: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G290",
    gloss: "vineyard",
  },
  ἀμπελῶνος: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G290",
    gloss: "vineyard",
  },
  Ἀμπλιᾶτον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G291",
    gloss: "Ampliatus",
  },
  ἠμύνατο: {
    parse: "V-ADI-3S｜Verb, aorist, middle, indicative, third, singular",
    GN: "G292",
    gloss: "to defend",
  },
  ἀμφίβληστρον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G293",
    gloss: "net",
  },
  ἀμφιέννυσιν: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G294",
    gloss: "to clothe",
  },
  ἠμφιεσμένον: {
    parse:
      "V-RPP-ASM｜Verb, perfect, passive, Participle, accusative, singular, masculine",
    GN: "G294",
    gloss: "to clothe",
  },
  Ἀμφίπολιν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G295",
    gloss: "Amphipolis",
  },
  ἀμφόδου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G296",
    gloss: "street",
  },
  ἀμφότερα: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G297",
    gloss: "both",
  },
  ἀμφότεροι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G297",
    gloss: "both",
  },
  ἀμφοτέροις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G297",
    gloss: "both",
  },
  ἀμφοτέρους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G297",
    gloss: "both",
  },
  ἀμφοτέρων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G297",
    gloss: "both",
  },
  ἀμώμητοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G298",
    gloss: "blameless",
  },
  ἄμωμα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G299",
    gloss: "blameless",
  },
  ἄμωμοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G299",
    gloss: "blameless",
  },
  ἄμωμον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G299",
    gloss: "blameless",
  },
  ἄμωμος: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G299",
    gloss: "blameless",
  },
  ἀμώμου: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G299",
    gloss: "blameless",
  },
  ἀμώμους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G299",
    gloss: "blameless",
  },
  Ἀμώς: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G301",
    gloss: "Amos",
  },
  Ἀμὼς: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G301",
    gloss: "Amos",
  },
  ἄν: { parse: "PRT｜Particle, disjunctive particle", GN: "G302", gloss: "if" },
  ἂν: { parse: "PRT｜Particle, disjunctive particle", GN: "G302", gloss: "if" },
  ἀνὰ: { parse: "PREP｜Preposition", GN: "G303", gloss: "each" },
  ἀναβαθμούς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G304",
    gloss: "stairs",
  },
  ἀναβαθμῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G304",
    gloss: "stairs",
  },
  Ἀνάβα: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G305",
    gloss: "to ascend",
  },
  ἀναβαίνει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G305",
    gloss: "to ascend",
  },
  ἀναβαίνειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G305",
    gloss: "to ascend",
  },
  ἀναβαίνομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G305",
    gloss: "to ascend",
  },
  ἀναβαῖνον: {
    parse:
      "V-PAP-NSN｜Verb, present, active, Participle, nominative, singular, neuter",
    GN: "G305",
    gloss: "to ascend",
  },
  ἀναβαίνοντα: {
    parse:
      "V-PAP-NPN｜Verb, present, active, Participle, nominative, plural, neuter",
    GN: "G305",
    gloss: "to ascend",
  },
  ἀναβαίνοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G305",
    gloss: "to ascend",
  },
  ἀναβαίνοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G305",
    gloss: "to ascend",
  },
  ἀναβαινόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G305",
    gloss: "to ascend",
  },
  ἀναβαίνουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G305",
    gloss: "to ascend",
  },
  ἀναβαίνω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G305",
    gloss: "to ascend",
  },
  Ἀναβαίνω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G305",
    gloss: "to ascend",
  },
  ἀναβαίνων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G305",
    gloss: "to ascend",
  },
  ἀναβάντα: {
    parse:
      "V-2AAP-ASM｜Verb, 2nd aorist, active, Participle, accusative, singular, masculine",
    GN: "G305",
    gloss: "to ascend",
  },
  ἀναβάντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G305",
    gloss: "to ascend",
  },
  ἀναβάντων: {
    parse:
      "V-2AAP-GPM｜Verb, 2nd aorist, active, Participle, genitive, plural, masculine",
    GN: "G305",
    gloss: "to ascend",
  },
  ἀναβὰς: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G305",
    gloss: "to ascend",
  },
  Ἀναβὰς: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G305",
    gloss: "to ascend",
  },
  Ἀνάβατε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G305",
    gloss: "to ascend",
  },
  ἀναβέβηκα: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G305",
    gloss: "to ascend",
  },
  ἀναβέβηκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G305",
    gloss: "to ascend",
  },
  ἀναβήσεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G305",
    gloss: "to ascend",
  },
  ἀνάβητε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G305",
    gloss: "to ascend",
  },
  ἀνεβαίνομεν: {
    parse: "V-IAI-1P｜Verb, imperfect, active, indicative, first, plural",
    GN: "G305",
    gloss: "to ascend",
  },
  ἀνέβαινον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G305",
    gloss: "to ascend",
  },
  ἀνέβη: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G305",
    gloss: "to ascend",
  },
  Ἀνέβη: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G305",
    gloss: "to ascend",
  },
  ἀνέβημεν: {
    parse: "V-2AAI-1P｜Verb, 2nd aorist, active, indicative, first, plural",
    GN: "G305",
    gloss: "to ascend",
  },
  ἀνέβην: {
    parse: "V-2AAI-1S｜Verb, 2nd aorist, active, indicative, first, singular",
    GN: "G305",
    gloss: "to ascend",
  },
  ἀνέβησαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G305",
    gloss: "to ascend",
  },
  ἀνεβάλετο: {
    parse: "V-2AMI-3S｜Verb, 2nd aorist, middle, indicative, third, singular",
    GN: "G306",
    gloss: "to defer",
  },
  ἀναβιβάσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G307",
    gloss: "to pull up",
  },
  ἀναβλέπουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G308",
    gloss: "to look up/again",
  },
  ἀναβλέψαντος: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G308",
    gloss: "to look up/again",
  },
  ἀναβλέψας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G308",
    gloss: "to look up/again",
  },
  Ἀναβλέψας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G308",
    gloss: "to look up/again",
  },
  ἀναβλέψασαι: {
    parse:
      "V-AAP-NPF｜Verb, aorist, active, Participle, nominative, plural, feminine",
    GN: "G308",
    gloss: "to look up/again",
  },
  ἀναβλέψῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G308",
    gloss: "to look up/again",
  },
  ἀναβλέψῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G308",
    gloss: "to look up/again",
  },
  ἀνάβλεψον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G308",
    gloss: "to look up/again",
  },
  Ἀνάβλεψον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G308",
    gloss: "to look up/again",
  },
  ἀναβλέψω: {
    parse: "V-AAS-1S｜Verb, aorist, active, subjunctive, first, singular",
    GN: "G308",
    gloss: "to look up/again",
  },
  ἀνέβλεψα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G308",
    gloss: "to look up/again",
  },
  ἀνέβλεψαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G308",
    gloss: "to look up/again",
  },
  ἀνέβλεψεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G308",
    gloss: "to look up/again",
  },
  ἀνέβλεψέν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G308",
    gloss: "to look up/again",
  },
  ἀνάβλεψιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G309",
    gloss: "recovery of sight",
  },
  ἀνεβόησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G310",
    gloss: "to cry out",
  },
  ἀναβολὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G311",
    gloss: "delay",
  },
  ἀναγγεῖλαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G312",
    gloss: "to report",
  },
  ἀναγγελεῖ: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G312",
    gloss: "to report",
  },
  ἀναγγέλλομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G312",
    gloss: "to report",
  },
  ἀναγγέλλοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G312",
    gloss: "to report",
  },
  ἀναγγέλλων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G312",
    gloss: "to report",
  },
  ἀνήγγειλάν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G312",
    gloss: "to report",
  },
  ἀνήγγειλεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G312",
    gloss: "to report",
  },
  ἀνηγγέλη: {
    parse: "V-2API-3S｜Verb, 2nd aorist, passive, indicative, third, singular",
    GN: "G312",
    gloss: "to report",
  },
  ἀνήγγελλον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G312",
    gloss: "to report",
  },
  ἀναγεγεννημένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G313",
    gloss: "to beget",
  },
  ἀναγεννήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G313",
    gloss: "to beget",
  },
  ἀναγινώσκεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G314",
    gloss: "to read",
  },
  ἀναγινώσκετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G314",
    gloss: "to read",
  },
  ἀναγινώσκηται: {
    parse: "V-PPS-3S｜Verb, present, passive, subjunctive, third, singular",
    GN: "G314",
    gloss: "to read",
  },
  ἀναγινωσκομένας: {
    parse:
      "V-PPP-APF｜Verb, present, passive, Participle, accusative, plural, feminine",
    GN: "G314",
    gloss: "to read",
  },
  ἀναγινωσκομένη: {
    parse:
      "V-PPP-NSF｜Verb, present, passive, Participle, nominative, singular, feminine",
    GN: "G314",
    gloss: "to read",
  },
  ἀναγινωσκόμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G314",
    gloss: "to read",
  },
  ἀναγινώσκοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G314",
    gloss: "to read",
  },
  ἀναγινώσκοντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G314",
    gloss: "to read",
  },
  ἀναγινώσκων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G314",
    gloss: "to read",
  },
  ἀναγνόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G314",
    gloss: "to read",
  },
  ἀναγνοὺς: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G314",
    gloss: "to read",
  },
  ἀναγνῶναι: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G314",
    gloss: "to read",
  },
  ἀναγνωσθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G314",
    gloss: "to read",
  },
  ἀναγνωσθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G314",
    gloss: "to read",
  },
  ἀναγνῶτε: {
    parse: "V-2AAS-2P｜Verb, 2nd aorist, active, subjunctive, second, plural",
    GN: "G314",
    gloss: "to read",
  },
  ἀνεγίνωσκεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G314",
    gloss: "to read",
  },
  ἀνέγνωσαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G314",
    gloss: "to read",
  },
  ἀνέγνωτε: {
    parse: "V-2AAI-2P｜Verb, 2nd aorist, active, indicative, second, plural",
    GN: "G314",
    gloss: "to read",
  },
  ἀναγκάζεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G315",
    gloss: "to compel",
  },
  ἀναγκάζουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G315",
    gloss: "to compel",
  },
  ἀνάγκασον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G315",
    gloss: "to compel",
  },
  ἠνάγκαζον: {
    parse: "V-IAI-1S｜Verb, imperfect, active, indicative, first, singular",
    GN: "G315",
    gloss: "to compel",
  },
  ἠναγκάσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G315",
    gloss: "to compel",
  },
  ἠνάγκασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G315",
    gloss: "to compel",
  },
  ἠναγκάσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G315",
    gloss: "to compel",
  },
  ἠναγκάσθην: {
    parse: "V-API-1S｜Verb, aorist, passive, indicative, first, singular",
    GN: "G315",
    gloss: "to compel",
  },
  ἀναγκαῖά: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G316",
    gloss: "necessary",
  },
  ἀναγκαίας: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G316",
    gloss: "necessary",
  },
  ἀναγκαῖον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G316",
    gloss: "necessary",
  },
  Ἀναγκαῖον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G316",
    gloss: "necessary",
  },
  ἀναγκαιότερον: {
    parse: "A-NSN-C｜Adjective, nominative, singular, neuter, Comparative",
    GN: "G316",
    gloss: "necessary",
  },
  ἀναγκαίους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G316",
    gloss: "necessary",
  },
  ἀναγκαστῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G317",
    gloss: "necessarily",
  },
  ἀνάγκαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G318",
    gloss: "necessity",
  },
  ἀνάγκῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G318",
    gloss: "necessity",
  },
  ἀνάγκη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G318",
    gloss: "necessity",
  },
  Ἀνάγκη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G318",
    gloss: "necessity",
  },
  ἀνάγκην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G318",
    gloss: "necessity",
  },
  ἀνάγκης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G318",
    gloss: "necessity",
  },
  ἀνεγνωρίσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G319",
    gloss: "to recognize",
  },
  ἀναγνώσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G320",
    gloss: "reading",
  },
  ἀνάγνωσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G320",
    gloss: "reading",
  },
  ἀναγαγεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G321",
    gloss: "to lead",
  },
  ἀναγαγών: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G321",
    gloss: "to lead",
  },
  ἀναγαγὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G321",
    gloss: "to lead",
  },
  ἀνάγεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G321",
    gloss: "to lead",
  },
  ἀναγομένοις: {
    parse:
      "V-PPP-DPM｜Verb, present, passive, Participle, dative, plural, masculine",
    GN: "G321",
    gloss: "to lead",
  },
  ἀναχθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G321",
    gloss: "to lead",
  },
  Ἀναχθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G321",
    gloss: "to lead",
  },
  ἀναχθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G321",
    gloss: "to lead",
  },
  ἀνήγαγον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G321",
    gloss: "to lead",
  },
  ἀνήχθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G321",
    gloss: "to lead",
  },
  ἀνήχθημεν: {
    parse: "V-API-1P｜Verb, aorist, passive, indicative, first, plural",
    GN: "G321",
    gloss: "to lead",
  },
  ἀνήχθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G321",
    gloss: "to lead",
  },
  ἀνάδειξον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G322",
    gloss: "to appoint",
  },
  ἀνέδειξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G322",
    gloss: "to appoint",
  },
  ἀναδείξεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G323",
    gloss: "public appearance",
  },
  ἀναδεξάμενος: {
    parse:
      "V-ADP-NSM｜Verb, aorist, Middle deponent, Participle, nominative, singular, masculine",
    GN: "G324",
    gloss: "to receive",
  },
  ἀναδόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G325",
    gloss: "to deliver",
  },
  ἀνέζησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G326",
    gloss: "to revive",
  },
  ἀναζητῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G327",
    gloss: "to search",
  },
  ἀναζητοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G327",
    gloss: "to search",
  },
  ἀνεζήτουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G327",
    gloss: "to search",
  },
  ἀναζωσάμενοι: {
    parse:
      "V-AMP-NPM｜Verb, aorist, middle, Participle, nominative, plural, masculine",
    GN: "G328",
    gloss: "to gird",
  },
  ἀναζωπυρεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G329",
    gloss: "to rekindle",
  },
  ἀνεθάλετε: {
    parse: "V-2AAI-2P｜Verb, 2nd aorist, active, indicative, second, plural",
    GN: "G330",
    gloss: "to renew",
  },
  ἀνάθεμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G331",
    gloss: "devoted",
  },
  Ἀνάθεμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G331",
    gloss: "devoted",
  },
  Ἀναθέματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G331",
    gloss: "devoted",
  },
  ἀναθήμασιν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G331",
    gloss: "devoted",
  },
  ἀναθεματίζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G332",
    gloss: "to take an oath",
  },
  ἀνεθεματίσαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G332",
    gloss: "to take an oath",
  },
  ἀνεθεμάτισαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G332",
    gloss: "to take an oath",
  },
  ἀναθεωροῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G333",
    gloss: "to contemplate",
  },
  ἀναθεωρῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G333",
    gloss: "to contemplate",
  },
  ἀναίδειαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G335",
    gloss: "shamelessnes",
  },
  ἀναιρέσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G336",
    gloss: "murder",
  },
  ἀναιρεθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G337",
    gloss: "to kill",
  },
  ἀναιρεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G337",
    gloss: "to kill",
  },
  ἀναιρεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G337",
    gloss: "to kill",
  },
  ἀναιρεῖσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G337",
    gloss: "to kill",
  },
  ἀναιρουμένων: {
    parse:
      "V-PPP-GPM｜Verb, present, passive, Participle, genitive, plural, masculine",
    GN: "G337",
    gloss: "to kill",
  },
  ἀναιρούντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G337",
    gloss: "to kill",
  },
  ἀνεῖλαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G337",
    gloss: "to kill",
  },
  ἀνείλατε: {
    parse: "V-2AAI-2P｜Verb, 2nd aorist, active, indicative, second, plural",
    GN: "G337",
    gloss: "to kill",
  },
  ἀνείλατο: {
    parse: "V-2AMI-3S｜Verb, 2nd aorist, middle, indicative, third, singular",
    GN: "G337",
    gloss: "to kill",
  },
  ἀνεῖλεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G337",
    gloss: "to kill",
  },
  ἀνεῖλες: {
    parse: "V-2AAI-2S｜Verb, 2nd aorist, active, indicative, second, singular",
    GN: "G337",
    gloss: "to kill",
  },
  ἀνείχεσθέ: {
    parse: "V-IMI-2P｜Verb, imperfect, middle, indicative, second, plural",
    GN: "G337",
    gloss: "to kill",
  },
  ἀνελεῖ: {
    parse: "V-2FAI-3S｜Verb, 2nd future, active, indicative, third, singular",
    GN: "G337",
    gloss: "to kill",
  },
  ἀνελεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G337",
    gloss: "to kill",
  },
  ἀνέλωσιν: {
    parse: "V-2AAS-3P｜Verb, 2nd aorist, active, subjunctive, third, plural",
    GN: "G337",
    gloss: "to kill",
  },
  ἀνῃρέθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G337",
    gloss: "to kill",
  },
  ἀναίτιοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G338",
    gloss: "innocent",
  },
  ἀναιτίους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G338",
    gloss: "innocent",
  },
  ἀνεκάθισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G339",
    gloss: "to sit up",
  },
  ἀνακαινίζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G340",
    gloss: "to restore",
  },
  ἀνακαινούμενον: {
    parse:
      "V-PPP-ASM｜Verb, present, passive, Participle, accusative, singular, masculine",
    GN: "G341",
    gloss: "to renew",
  },
  ἀνακαινοῦται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G341",
    gloss: "to renew",
  },
  ἀνακαινώσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G342",
    gloss: "renewal",
  },
  ἀνακαινώσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G342",
    gloss: "renewal",
  },
  ἀνακαλυπτόμενον: {
    parse:
      "V-PPP-NSN｜Verb, present, passive, Participle, nominative, singular, neuter",
    GN: "G343",
    gloss: "to unveil",
  },
  ἀνακεκαλυμμένῳ: {
    parse:
      "V-RPP-DSN｜Verb, perfect, passive, Participle, dative, singular, neuter",
    GN: "G343",
    gloss: "to unveil",
  },
  ἀνακάμψαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G344",
    gloss: "to return",
  },
  ἀνακάμψει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G344",
    gloss: "to return",
  },
  ἀνακάμψω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G344",
    gloss: "to return",
  },
  ἀνακειμένοις: {
    parse:
      "V-PNP-DPM｜Verb, present, Middle or Passive deponent, Participle, dative, plural, masculine",
    GN: "G345",
    gloss: "to recline",
  },
  ἀνακείμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G345",
    gloss: "to recline",
  },
  ἀνακειμένου: {
    parse:
      "V-PNP-GSM｜Verb, present, Middle or Passive deponent, Participle, genitive, singular, masculine",
    GN: "G345",
    gloss: "to recline",
  },
  ἀνακειμένους: {
    parse:
      "V-PNP-APM｜Verb, present, Middle or Passive deponent, Participle, accusative, plural, masculine",
    GN: "G345",
    gloss: "to recline",
  },
  ἀνακειμένων: {
    parse:
      "V-PNP-GPM｜Verb, present, Middle or Passive deponent, Participle, genitive, plural, masculine",
    GN: "G345",
    gloss: "to recline",
  },
  ἀνέκειτο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G345",
    gloss: "to recline",
  },
  ἀνακεφαλαιοῦται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G346",
    gloss: "to summarise",
  },
  ἀνακεφαλαιώσασθαι: {
    parse: "V-ADN｜Verb, aorist, Middle deponent, Infinitive",
    GN: "G346",
    gloss: "to summarise",
  },
  ἀνακλιθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G347",
    gloss: "to recline",
  },
  ἀνακλιθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G347",
    gloss: "to recline",
  },
  ἀνακλῖναι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G347",
    gloss: "to recline",
  },
  ἀνακλινεῖ: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G347",
    gloss: "to recline",
  },
  ἀνέκλινεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G347",
    gloss: "to recline",
  },
  ἀνακράξας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G349",
    gloss: "to yell",
  },
  Ἀνέκραγον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G349",
    gloss: "to yell",
  },
  ἀνέκραξαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G349",
    gloss: "to yell",
  },
  ἀνέκραξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G349",
    gloss: "to yell",
  },
  ἀνακριθῶ: {
    parse: "V-APS-1S｜Verb, aorist, passive, subjunctive, first, singular",
    GN: "G350",
    gloss: "to investigate",
  },
  ἀνακρίναντές: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G350",
    gloss: "to investigate",
  },
  ἀνακρίνας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G350",
    gloss: "to investigate",
  },
  ἀνακρίνει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G350",
    gloss: "to investigate",
  },
  ἀνακρίνεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G350",
    gloss: "to investigate",
  },
  ἀνακρινόμεθα: {
    parse: "V-PPI-1P｜Verb, present, passive, indicative, first, plural",
    GN: "G350",
    gloss: "to investigate",
  },
  ἀνακρίνοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G350",
    gloss: "to investigate",
  },
  ἀνακρίνουσίν: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G350",
    gloss: "to investigate",
  },
  ἀνακρίνω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G350",
    gloss: "to investigate",
  },
  ἀνακρίνων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G350",
    gloss: "to investigate",
  },
  ἀνακρίσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G351",
    gloss: "investigation",
  },
  ἀνακύψαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G352",
    gloss: "to straighten up",
  },
  ἀνακύψας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G352",
    gloss: "to straighten up",
  },
  ἀνακύψατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G352",
    gloss: "to straighten up",
  },
  ἀνέκυψεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G352",
    gloss: "to straighten up",
  },
  ἀναλάβετε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G353",
    gloss: "to take up",
  },
  ἀναλαβόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G353",
    gloss: "to take up",
  },
  ἀναλαβὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G353",
    gloss: "to take up",
  },
  ἀναλαμβάνειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G353",
    gloss: "to take up",
  },
  ἀναλημφθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G353",
    gloss: "to take up",
  },
  ἀνελάβετε: {
    parse: "V-2AAI-2P｜Verb, 2nd aorist, active, indicative, second, plural",
    GN: "G353",
    gloss: "to take up",
  },
  ἀνελήμφθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G353",
    gloss: "to take up",
  },
  ἀναλήμψεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G354",
    gloss: "ascension",
  },
  ἀναλωθῆτε: {
    parse: "V-APS-2P｜Verb, aorist, passive, subjunctive, second, plural",
    GN: "G355",
    gloss: "to consume",
  },
  ἀναλῶσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G355",
    gloss: "to consume",
  },
  ἀναλογίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G356",
    gloss: "proportion",
  },
  Ἀναλογίσασθε: {
    parse:
      "V-ADM-2P｜Verb, aorist, Middle deponent, imperative, second, plural",
    GN: "G357",
    gloss: "to consider",
  },
  ἄναλον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G358",
    gloss: "unsalty",
  },
  ἀναλύσεώς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G359",
    gloss: "departure",
  },
  ἀναλῦσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G360",
    gloss: "to depart",
  },
  ἀναλύσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G360",
    gloss: "to depart",
  },
  ἀναμάρτητος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G361",
    gloss: "sinless",
  },
  ἀναμένειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G362",
    gloss: "to await",
  },
  Ἀναμιμνῄσκεσθε: {
    parse: "V-PPM-2P｜Verb, present, passive, imperative, second, plural",
    GN: "G363",
    gloss: "to remind",
  },
  ἀναμιμνῃσκομένου: {
    parse:
      "V-PPP-GSM｜Verb, present, passive, Participle, genitive, singular, masculine",
    GN: "G363",
    gloss: "to remind",
  },
  ἀναμιμνῄσκω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G363",
    gloss: "to remind",
  },
  ἀναμνήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G363",
    gloss: "to remind",
  },
  ἀναμνησθεὶς: {
    parse:
      "V-AOP-NSM｜Verb, aorist, Passive deponent, Participle, nominative, singular, masculine",
    GN: "G363",
    gloss: "to remind",
  },
  ἀνεμνήσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G363",
    gloss: "to remind",
  },
  ἀνάμνησιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G364",
    gloss: "remembrance",
  },
  ἀνάμνησις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G364",
    gloss: "remembrance",
  },
  ἀνανεοῦσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G365",
    gloss: "to renew",
  },
  ἀνανήψωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G366",
    gloss: "to regain senses",
  },
  Ἁνανία: {
    // or nominative
    parse: "N-VSM-P｜Noun, Vocative, singular, masculine, Person",
    GN: "G367",
    gloss: "Ananias",
  },
  Ἁνανίαν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G367",
    gloss: "Ananias",
  },
  Ἁνανίας: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G367",
    gloss: "Ananias",
  },
  ἀναντιρρήτων: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G368",
    gloss: "indisputable",
  },
  ἀναντιρρήτως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G369",
    gloss: "without objection",
  },
  ἀνάξιοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G370",
    gloss: "unworthy",
  },
  ἀναξίως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G371",
    gloss: "unworthily",
  },
  ἀνάπαυσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G372",
    gloss: "rest",
  },
  ἀναπαήσονται: {
    parse: "V-2FPI-3P｜Verb, 2nd future, passive, indicative, third, plural",
    GN: "G373",
    gloss: "to give rest",
  },
  ἀναπαύεσθε: {
    parse: "V-PMI-2P｜Verb, present, middle, indicative, second, plural",
    GN: "G373",
    gloss: "to give rest",
  },
  ἀναπαύεται: {
    parse: "V-PMI-3S｜Verb, present, middle, indicative, third, singular",
    GN: "G373",
    gloss: "to give rest",
  },
  ἀναπαύου: {
    parse: "V-PMM-2S｜Verb, present, middle, imperative, second, singular",
    GN: "G373",
    gloss: "to give rest",
  },
  ἀναπαύσασθε: {
    parse: "V-AMM-2P｜Verb, aorist, middle, imperative, second, plural",
    GN: "G373",
    gloss: "to give rest",
  },
  ἀνάπαυσόν: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G373",
    gloss: "to give rest",
  },
  ἀναπαύσονται: {
    parse: "V-FMI-3P｜Verb, future, middle, indicative, third, plural",
    GN: "G373",
    gloss: "to give rest",
  },
  ἀναπαύσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G373",
    gloss: "to give rest",
  },
  ἀναπέπαυται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G373",
    gloss: "to give rest",
  },
  ἀνέπαυσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G373",
    gloss: "to give rest",
  },
  ἀναπείθει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G374",
    gloss: "to persuade",
  },
  ἀναπέμψω: {
    parse: "V-AAS-1S｜Verb, aorist, active, subjunctive, first, singular",
    GN: "G375",
    gloss: "to send back",
  },
  ἀνέπεμψά: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G375",
    gloss: "to send back",
  },
  ἀνέπεμψεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G375",
    gloss: "to send back",
  },
  ἀναπείρους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G376",
    gloss: "crippled",
  },
  ἀνάπεσε: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G377",
    gloss: "to recline",
  },
  ἀναπεσεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G377",
    gloss: "to recline",
  },
  ἀναπεσὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G377",
    gloss: "to recline",
  },
  ἀνέπεσαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G377",
    gloss: "to recline",
  },
  ἀνέπεσεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G377",
    gloss: "to recline",
  },
  ἀναπληροῦται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G378",
    gloss: "to fulfil",
  },
  ἀναπληρῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G378",
    gloss: "to fulfil",
  },
  ἀναπληρῶσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G378",
    gloss: "to fulfil",
  },
  ἀναπληρώσετε: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G378",
    gloss: "to fulfil",
  },
  ἀναπληρώσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G378",
    gloss: "to fulfil",
  },
  ἀνεπλήρωσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G378",
    gloss: "to fulfil",
  },
  ἀναπολόγητος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G379",
    gloss: "inexcusable",
  },
  ἀναπολογήτους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G379",
    gloss: "inexcusable",
  },
  ἀναπτύξας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G380",
    gloss: "to unroll",
  },
  ἀνάπτει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G381",
    gloss: "to set fire",
  },
  ἀνήφθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G381",
    gloss: "to set fire",
  },
  ἀναρίθμητος: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G382",
    gloss: "countless",
  },
  Ἀνασείει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G383",
    gloss: "to incite",
  },
  ἀνέσεισαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G383",
    gloss: "to incite",
  },
  ἀνασκευάζοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G384",
    gloss: "to upset",
  },
  ἀνασπάσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G385",
    gloss: "to pull up",
  },
  ἀνεσπάσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G385",
    gloss: "to pull up",
  },
  ἀναστάσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G386",
    gloss: "resurrection",
  },
  ἀναστάσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G386",
    gloss: "resurrection",
  },
  ἀναστάσεώς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G386",
    gloss: "resurrection",
  },
  ἀνάστασιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G386",
    gloss: "resurrection",
  },
  ἀνάστασις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G386",
    gloss: "resurrection",
  },
  ἀναστατοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G387",
    gloss: "to cause trouble",
  },
  ἀναστατώσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G387",
    gloss: "to cause trouble",
  },
  ἀναστατώσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G387",
    gloss: "to cause trouble",
  },
  ἀνασταυροῦντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G388",
    gloss: "to recrucify",
  },
  ἀναστενάξας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G389",
    gloss: "to sigh deeply",
  },
  ἀναστράφητε: {
    parse: "V-2APM-2P｜Verb, 2nd aorist, passive, imperative, second, plural",
    GN: "G390",
    gloss: "to live/return",
  },
  ἀναστρέφεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G390",
    gloss: "to live/return",
  },
  ἀναστρεφομένους: {
    parse:
      "V-PPP-APM｜Verb, present, passive, Participle, accusative, plural, masculine",
    GN: "G390",
    gloss: "to live/return",
  },
  ἀναστρεφομένων: {
    parse:
      "V-PPP-GPM｜Verb, present, passive, Participle, genitive, plural, masculine",
    GN: "G390",
    gloss: "to live/return",
  },
  ἀναστρέψαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G390",
    gloss: "to live/return",
  },
  ἀναστρέψω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G390",
    gloss: "to live/return",
  },
  ἀνεστράφημεν: {
    parse: "V-2API-1P｜Verb, 2nd aorist, passive, indicative, first, plural",
    GN: "G390",
    gloss: "to live/return",
  },
  ἀνεστράφημέν: {
    parse: "V-2API-1P｜Verb, 2nd aorist, passive, indicative, first, plural",
    GN: "G390",
    gloss: "to live/return",
  },
  ἀναστροφαῖς: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G391",
    gloss: "behaviour",
  },
  ἀναστροφῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G391",
    gloss: "behaviour",
  },
  ἀναστροφήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G391",
    gloss: "behaviour",
  },
  ἀναστροφὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G391",
    gloss: "behaviour",
  },
  ἀναστροφῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G391",
    gloss: "behaviour",
  },
  ἀνατάξασθαι: {
    parse: "V-ADN｜Verb, aorist, Middle deponent, Infinitive",
    GN: "G392",
    gloss: "to compile",
  },
  ἀνατείλαντος: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G393",
    gloss: "to rise",
  },
  ἀνατείλῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G393",
    gloss: "to rise",
  },
  ἀνατέλλει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G393",
    gloss: "to rise",
  },
  ἀνατέλλουσαν: {
    parse:
      "V-PAP-ASF｜Verb, present, active, Participle, accusative, singular, feminine",
    GN: "G393",
    gloss: "to rise",
  },
  ἀνατέταλκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G393",
    gloss: "to rise",
  },
  ἀνέτειλεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G393",
    gloss: "to rise",
  },
  ἀνεθέμην: {
    parse: "V-2AMI-1S｜Verb, 2nd aorist, middle, indicative, first, singular",
    GN: "G394",
    gloss: "to set before",
  },
  ἀνέθετο: {
    parse: "V-2AMI-3S｜Verb, 2nd aorist, middle, indicative, third, singular",
    GN: "G394",
    gloss: "to set before",
  },
  ἀνατολῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G395",
    gloss: "east",
  },
  ἀνατολὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G395",
    gloss: "east",
  },
  ἀνατολῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G395",
    gloss: "east",
  },
  ἀνατολῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G395",
    gloss: "east",
  },
  ἀνατρέπουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G396",
    gloss: "to overturn",
  },
  ἀνέτρεψεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G396",
    gloss: "to overturn",
  },
  ἀνατεθραμμένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G397",
    gloss: "to bring up",
  },
  ἀνεθρέψατο: {
    parse: "V-AMI-3S｜Verb, aorist, middle, indicative, third, singular",
    GN: "G397",
    gloss: "to bring up",
  },
  ἀνετράφη: {
    parse: "V-2API-3S｜Verb, 2nd aorist, passive, indicative, third, singular",
    GN: "G397",
    gloss: "to bring up",
  },
  ἀναφαίνεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G398",
    gloss: "to appear",
  },
  ἀναφάναντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G398",
    gloss: "to appear",
  },
  ἀναφέρει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G399",
    gloss: "to carry up",
  },
  ἀναφέρειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G399",
    gloss: "to carry up",
  },
  ἀναφέρωμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G399",
    gloss: "to carry up",
  },
  ἀνενέγκαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G399",
    gloss: "to carry up",
  },
  ἀνενέγκας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G399",
    gloss: "to carry up",
  },
  ἀνενεγκεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G399",
    gloss: "to carry up",
  },
  ἀνεφέρετο: {
    parse: "V-IPI-3S｜Verb, imperfect, passive, indicative, third, singular",
    GN: "G399",
    gloss: "to carry up",
  },
  ἀνήνεγκεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G399",
    gloss: "to carry up",
  },
  ἀνεφώνησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G400",
    gloss: "to exclaim",
  },
  ἀνάχυσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G401",
    gloss: "outpouring",
  },
  Ἀναχωρεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G402",
    gloss: "to leave",
  },
  ἀναχωρήσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G402",
    gloss: "to leave",
  },
  Ἀναχωρησάντων: {
    parse:
      "V-AAP-GPM｜Verb, aorist, active, Participle, genitive, plural, masculine",
    GN: "G402",
    gloss: "to leave",
  },
  ἀναχωρήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G402",
    gloss: "to leave",
  },
  ἀνεχώρησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G402",
    gloss: "to leave",
  },
  ἀνεχώρησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G402",
    gloss: "to leave",
  },
  ἀναψύξεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G403",
    gloss: "refreshment",
  },
  ἀνέψυξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G404",
    gloss: "to refresh",
  },
  ἀνδραποδισταῖς: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G405",
    gloss: "slave-trader",
  },
  Ἀνδρέᾳ: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G406",
    gloss: "Andrew",
  },
  Ἀνδρέαν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G406",
    gloss: "Andrew",
  },
  Ἀνδρέας: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G406",
    gloss: "Andrew",
  },
  Ἀνδρέου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G406",
    gloss: "Andrew",
  },
  ἀνδρίζεσθε: {
    parse:
      "V-PNM-2P｜Verb, present, Middle or Passive deponent, imperative, second, plural",
    GN: "G407",
    gloss: "to act like a man",
  },
  Ἀνδρόνικον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G408",
    gloss: "Andronicus",
  },
  ἀνδροφόνοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G409",
    gloss: "murderer",
  },
  ἀνέγκλητοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G410",
    gloss: "irreproachable",
  },
  ἀνέγκλητον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G410",
    gloss: "irreproachable",
  },
  ἀνέγκλητος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G410",
    gloss: "irreproachable",
  },
  ἀνεγκλήτους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G410",
    gloss: "irreproachable",
  },
  ἀνεκδιηγήτῳ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G411",
    gloss: "indescribable",
  },
  ἀνεκλαλήτῳ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G412",
    gloss: "inexpressible",
  },
  ἀνέκλειπτον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G413",
    gloss: "inexhaustible",
  },
  ἀνεκτότερον: {
    parse: "A-NSN-C｜Adjective, nominative, singular, neuter, Comparative",
    GN: "G414",
    gloss: "bearable",
  },
  ἀνελεήμονας: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G415",
    gloss: "merciless",
  },
  ἀνεμιζομένῳ: {
    parse:
      "V-PPP-DSM｜Verb, present, passive, Participle, dative, singular, masculine",
    GN: "G416",
    gloss: "be wind-blown",
  },
  ἄνεμοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G417",
    gloss: "wind",
  },
  ἀνέμοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G417",
    gloss: "wind",
  },
  ἄνεμον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G417",
    gloss: "wind",
  },
  ἄνεμος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G417",
    gloss: "wind",
  },
  ἀνέμου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G417",
    gloss: "wind",
  },
  ἀνέμους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G417",
    gloss: "wind",
  },
  ἀνέμῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G417",
    gloss: "wind",
  },
  ἀνέμων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G417",
    gloss: "wind",
  },
  Ἀνένδεκτόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G418",
    gloss: "impossible",
  },
  ἀνεξεραύνητα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G419",
    gloss: "unsearchable",
  },
  ἀνεξίκακον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G420",
    gloss: "not resentful",
  },
  ἀνεξιχνίαστοι: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G421",
    gloss: "unsearchable",
  },
  ἀνεξιχνίαστον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G421",
    gloss: "unsearchable",
  },
  ἀνεπαίσχυντον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G422",
    gloss: "unashamed",
  },
  ἀνεπίλημπτοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G423",
    gloss: "irreproachable",
  },
  ἀνεπίλημπτον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G423",
    gloss: "irreproachable",
  },
  ἀνῆλθεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G424",
    gloss: "to go up",
  },
  ἀνῆλθον: {
    parse:
      "V-2AAI-1S｜Verb, 2nd aorist, active, indicative, first, singular OR third, plural",
    GN: "G424",
    gloss: "to go up",
  },
  ἄνεσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G425",
    gloss: "rest",
  },
  ἄνεσις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G425",
    gloss: "rest",
  },
  ἀνετάζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G426",
    gloss: "to question",
  },
  ἀνετάζεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G426",
    gloss: "to question",
  },
  ἄνευ: { parse: "PREP｜Preposition", GN: "G427", gloss: "without" },
  ἀνευθέτου: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G428",
    gloss: "unsuitable",
  },
  ἀνεῦραν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G429",
    gloss: "to find",
  },
  ἀνευρόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G429",
    gloss: "to find",
  },
  ἀνέξομαι: {
    parse:
      "V-FDI-1S｜Verb, future, Middle deponent, indicative, first, singular",
    GN: "G430",
    gloss: "to endure",
  },
  ἀνέξονται: {
    parse: "V-FDI-3P｜Verb, future, Middle deponent, indicative, third, plural",
    GN: "G430",
    gloss: "to endure",
  },
  ἀνεσχόμην: {
    parse: "V-2AMI-1S｜Verb, 2nd aorist, middle, indicative, first, singular",
    GN: "G430",
    gloss: "to endure",
  },
  ἀνέχεσθέ: {
    parse: "V-PMI-2P｜Verb, present, middle, indicative, second, plural",
    GN: "G430",
    gloss: "to endure",
  },
  ἀνέχεσθε: {
    parse:
      "V-PNM-2P｜Verb, present, Middle or Passive deponent, imperative, second, plural",
    GN: "G430",
    gloss: "to endure",
  },
  ἀνεχόμεθα: {
    parse:
      "V-PNI-1P｜Verb, present, Middle or Passive deponent, indicative, first, plural",
    GN: "G430",
    gloss: "to endure",
  },
  ἀνεχόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G430",
    gloss: "to endure",
  },
  ἀνεψιὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G431",
    gloss: "cousin",
  },
  ἄνηθον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G432",
    gloss: "dill",
  },
  ἀνῆκεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G433",
    gloss: "be fitting",
  },
  ἀνῆκον: {
    parse:
      "V-PAP-ASN｜Verb, present, active, Participle, accusative, singular, neuter",
    GN: "G433",
    gloss: "be fitting",
  },
  ἀνήμεροι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G434",
    gloss: "brutal",
  },
  ἄνδρα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G435",
    gloss: "husband",
  },
  Ἄνδρα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G435",
    gloss: "husband",
  },
  ἄνδρας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G435",
    gloss: "husband",
  },
  ἀνδράσιν: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G435",
    gloss: "husband",
  },
  ἄνδρες: {
    parse: "N-VPM｜Noun, Vocative or nominative, plural, masculine",
    GN: "G435",
    gloss: "husband",
  },
  Ἄνδρες: {
    parse: "N-VPM｜Noun, Vocative or nominative, plural, masculine",
    GN: "G435",
    gloss: "husband",
  },
  ἀνδρί: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G435",
    gloss: "husband",
  },
  ἀνδρὶ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G435",
    gloss: "husband",
  },
  ἀνδρός: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G435",
    gloss: "husband",
  },
  ἀνδρὸς: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G435",
    gloss: "husband",
  },
  ἀνδρῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G435",
    gloss: "husband",
  },
  ἄνερ: {
    // or nominative
    parse: "N-VSM｜Noun, Vocative, singular, masculine",
    GN: "G435",
    gloss: "husband",
  },
  ἀνήρ: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G435",
    gloss: "husband",
  },
  Ἀνήρ: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G435",
    gloss: "husband",
  },
  ἀνὴρ: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G435",
    gloss: "husband",
  },
  Ἀνὴρ: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G435",
    gloss: "husband",
  },
  ἀνθέστηκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G436",
    gloss: "to oppose",
  },
  ἀνθεστηκότες: {
    parse:
      "V-RAP-NPM｜Verb, perfect, active, Participle, nominative, plural, masculine",
    GN: "G436",
    gloss: "to oppose",
  },
  ἀνθίστανται: {
    parse: "V-PMI-3P｜Verb, present, middle, indicative, third, plural",
    GN: "G436",
    gloss: "to oppose",
  },
  ἀνθίστατο: {
    parse: "V-IMI-3S｜Verb, imperfect, middle, indicative, third, singular",
    GN: "G436",
    gloss: "to oppose",
  },
  ἀντέστη: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G436",
    gloss: "to oppose",
  },
  ἀντέστην: {
    parse: "V-2AAI-1S｜Verb, 2nd aorist, active, indicative, first, singular",
    GN: "G436",
    gloss: "to oppose",
  },
  ἀντέστησαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G436",
    gloss: "to oppose",
  },
  ἀντιστῆναι: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G436",
    gloss: "to oppose",
  },
  ἀντίστητε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G436",
    gloss: "to oppose",
  },
  ἀνθωμολογεῖτο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G437",
    gloss: "to praise",
  },
  ἄνθος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G438",
    gloss: "flower",
  },
  ἀνθρακιὰν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G439",
    gloss: "charcoal fire",
  },
  ἄνθρακας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G440",
    gloss: "charcoal",
  },
  ἀνθρωπάρεσκοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G441",
    gloss: "people-pleaser",
  },
  ἀνθρωπίνῃ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G442",
    gloss: "human",
  },
  ἀνθρωπίνης: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G442",
    gloss: "human",
  },
  ἀνθρώπινον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G442",
    gloss: "human",
  },
  ἀνθρώπινος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G442",
    gloss: "human",
  },
  ἀνθρωπίνων: {
    parse: "A-GPF｜Adjective, genitive, plural, feminine",
    GN: "G442",
    gloss: "human",
  },
  ἀνθρωποκτόνος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G443",
    gloss: "murderer",
  },
  ἄνθρωπε: {
    // or nominative
    parse: "N-VSM｜Noun, Vocative, singular, masculine",
    GN: "G444",
    gloss: "a human",
  },
  Ἄνθρωπε: {
    // or nominative
    parse: "N-VSM｜Noun, Vocative, singular, masculine",
    GN: "G444",
    gloss: "a human",
  },
  ἄνθρωποι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G444",
    gloss: "a human",
  },
  Ἄνθρωποι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G444",
    gloss: "a human",
  },
  ἄνθρωποί: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G444",
    gloss: "a human",
  },
  ἀνθρώποις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G444",
    gloss: "a human",
  },
  ἄνθρωπον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G444",
    gloss: "a human",
  },
  ἄνθρωπόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G444",
    gloss: "a human",
  },
  ἄνθρωπος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G444",
    gloss: "a human",
  },
  Ἄνθρωπος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G444",
    gloss: "a human",
  },
  ἄνθρωπός: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G444",
    gloss: "a human",
  },
  Ἄνθρωπός: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G444",
    gloss: "a human",
  },
  ἀνθρώπου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G444",
    gloss: "a human",
  },
  Ἀνθρώπου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G444",
    gloss: "a human",
  },
  ἀνθρώπους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G444",
    gloss: "a human",
  },
  ἀνθρώπῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G444",
    gloss: "a human",
  },
  ἀνθρώπων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G444",
    gloss: "a human",
  },
  ἀνθύπατοί: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G446",
    gloss: "proconsul",
  },
  ἀνθύπατον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G446",
    gloss: "proconsul",
  },
  ἀνθύπατος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G446",
    gloss: "proconsul",
  },
  ἀνθυπάτου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G446",
    gloss: "proconsul",
  },
  ἀνθυπάτῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G446",
    gloss: "proconsul",
  },
  ἀνέθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G447",
    gloss: "to loosen/leave",
  },
  ἀνέντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G447",
    gloss: "to loosen/leave",
  },
  ἀνιέντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G447",
    gloss: "to loosen/leave",
  },
  ἀνῶ: {
    parse: "V-2AAS-1S｜Verb, 2nd aorist, active, subjunctive, first, singular",
    GN: "G447",
    gloss: "to loosen/leave",
  },
  ἀνέλεος: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G448",
    gloss: "merciless",
  },
  ἀνίπτοις: {
    parse: "A-DPF｜Adjective, dative, plural, feminine",
    GN: "G449",
    gloss: "unwashed",
  },
  ἀνάστα: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G450",
    gloss: "to arise",
  },
  Ἀνάστα: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G450",
    gloss: "to arise",
  },
  ἀναστὰν: {
    parse:
      "V-2AAP-NSN｜Verb, 2nd aorist, active, Participle, nominative, singular, neuter",
    GN: "G450",
    gloss: "to arise",
  },
  ἀναστάντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G450",
    gloss: "to arise",
  },
  Ἀναστάς: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G450",
    gloss: "to arise",
  },
  ἀναστὰς: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G450",
    gloss: "to arise",
  },
  Ἀναστὰς: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G450",
    gloss: "to arise",
  },
  ἀναστᾶσα: {
    parse:
      "V-2AAP-NSF｜Verb, 2nd aorist, active, Participle, nominative, singular, feminine",
    GN: "G450",
    gloss: "to arise",
  },
  Ἀναστᾶσα: {
    parse:
      "V-2AAP-NSF｜Verb, 2nd aorist, active, Participle, nominative, singular, feminine",
    GN: "G450",
    gloss: "to arise",
  },
  ἀναστῇ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G450",
    gloss: "to arise",
  },
  ἀνάστηθι: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G450",
    gloss: "to arise",
  },
  Ἀνάστηθι: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G450",
    gloss: "to arise",
  },
  ἀναστῆναι: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G450",
    gloss: "to arise",
  },
  ἀναστήσας: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G450",
    gloss: "to arise",
  },
  ἀναστήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G450",
    gloss: "to arise",
  },
  ἀναστήσεται: {
    parse: "V-FMI-3S｜Verb, future, middle, indicative, third, singular",
    GN: "G450",
    gloss: "to arise",
  },
  Ἀναστήσεται: {
    parse: "V-FMI-3S｜Verb, future, middle, indicative, third, singular",
    GN: "G450",
    gloss: "to arise",
  },
  ἀναστήσονται: {
    parse: "V-FMI-3P｜Verb, future, middle, indicative, third, plural",
    GN: "G450",
    gloss: "to arise",
  },
  ἀναστήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G450",
    gloss: "to arise",
  },
  ἀναστῶσιν: {
    parse: "V-2AAS-3P｜Verb, 2nd aorist, active, subjunctive, third, plural",
    GN: "G450",
    gloss: "to arise",
  },
  ἀνέστη: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G450",
    gloss: "to arise",
  },
  Ἀνέστη: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G450",
    gloss: "to arise",
  },
  ἀνέστησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G450",
    gloss: "to arise",
  },
  ἀνέστησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G450",
    gloss: "to arise",
  },
  ἀνιστάμενος: {
    parse:
      "V-PMP-NSM｜Verb, present, middle, Participle, nominative, singular, masculine",
    GN: "G450",
    gloss: "to arise",
  },
  ἀνίστασθαι: {
    parse: "V-PMN｜Verb, present, middle, Infinitive",
    GN: "G450",
    gloss: "to arise",
  },
  ἀνίσταται: {
    parse: "V-PMI-3S｜Verb, present, middle, indicative, third, singular",
    GN: "G450",
    gloss: "to arise",
  },
  Ἅννα: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G452",
    gloss: "Annas",
  },
  Ἅνναν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G452",
    gloss: "Annas",
  },
  Ἅννας: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G452",
    gloss: "Annas",
  },
  ἀνόητοι: {
    parse: "A-VPM｜Adjective, Vocative or nominative, plural, masculine",
    GN: "G453",
    gloss: "foolish",
  },
  ἀνόητοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G453",
    gloss: "foolish",
  },
  ἀνοήτοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G453",
    gloss: "foolish",
  },
  ἀνοήτους: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G453",
    gloss: "foolish",
  },
  ἄνοια: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G454",
    gloss: "folly",
  },
  ἀνοίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G454",
    gloss: "folly",
  },
  ἀνέῳγεν: {
    parse: "V-2RAI-3S｜Verb, 2nd perfect, active, indicative, third, singular",
    GN: "G455",
    gloss: "to open",
  },
  ἀνεῳγμένας: {
    parse:
      "V-2RPP-APF｜Verb, 2nd perfect, passive, Participle, accusative, plural, feminine",
    GN: "G455",
    gloss: "to open",
  },
  ἀνεῳγμένης: {
    parse:
      "V-2RPP-GSF｜Verb, 2nd perfect, passive, Participle, genitive, singular, feminine",
    GN: "G455",
    gloss: "to open",
  },
  ἀνεῳγμένον: {
    parse:
      "V-2RPP-ASM｜Verb, 2nd perfect, passive, Participle, accusative, singular, masculine",
    GN: "G455",
    gloss: "to open",
  },
  ἀνεῳγμένος: {
    parse:
      "V-2RPP-NSM｜Verb, 2nd perfect, passive, Participle, nominative, singular, masculine",
    GN: "G455",
    gloss: "to open",
  },
  ἀνεῳγμένων: {
    parse:
      "V-RPP-GPM｜Verb, perfect, passive, Participle, genitive, plural, masculine",
    GN: "G455",
    gloss: "to open",
  },
  ἀνεῳγότα: {
    parse:
      "V-2RAP-ASM｜Verb, 2nd perfect, active, Participle, accusative, singular, masculine",
    GN: "G455",
    gloss: "to open",
  },
  ἀνέῳξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G455",
    gloss: "to open",
  },
  ἀνεῴχθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G455",
    gloss: "to open",
  },
  ἀνεῳχθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G455",
    gloss: "to open",
  },
  ἀνεῴχθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G455",
    gloss: "to open",
  },
  ἀνοίγει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G455",
    gloss: "to open",
  },
  ἀνοίγειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G455",
    gloss: "to open",
  },
  ἀνοιγήσεται: {
    parse: "V-2FPI-3S｜Verb, 2nd future, passive, indicative, third, singular",
    GN: "G455",
    gloss: "to open",
  },
  ἀνοίγων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G455",
    gloss: "to open",
  },
  ἀνοιγῶσιν: {
    parse: "V-2APS-3P｜Verb, 2nd aorist, passive, subjunctive, third, plural",
    GN: "G455",
    gloss: "to open",
  },
  ἀνοῖξαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G455",
    gloss: "to open",
  },
  ἀνοίξαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G455",
    gloss: "to open",
  },
  ἀνοίξας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G455",
    gloss: "to open",
  },
  Ἀνοίξας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G455",
    gloss: "to open",
  },
  ἀνοίξῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G455",
    gloss: "to open",
  },
  ἄνοιξον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G455",
    gloss: "to open",
  },
  Ἀνοίξω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G455",
    gloss: "to open",
  },
  ἀνοίξωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G455",
    gloss: "to open",
  },
  ἠνεῳγμένη: {
    parse:
      "V-RPP-NSF｜Verb, perfect, passive, Participle, nominative, singular, feminine",
    GN: "G455",
    gloss: "to open",
  },
  ἠνεῳγμένην: {
    parse:
      "V-RPP-ASF｜Verb, perfect, passive, Participle, accusative, singular, feminine",
    GN: "G455",
    gloss: "to open",
  },
  ἠνεῳγμένον: {
    parse:
      "V-RPP-ASN｜Verb, perfect, passive, Participle, accusative, singular, neuter",
    GN: "G455",
    gloss: "to open",
  },
  ἠνέῳξέν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G455",
    gloss: "to open",
  },
  ἠνεῴχθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G455",
    gloss: "to open",
  },
  ἠνεῴχθησάν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G455",
    gloss: "to open",
  },
  ἠνοίγη: {
    parse: "V-2API-3S｜Verb, 2nd aorist, passive, indicative, third, singular",
    GN: "G455",
    gloss: "to open",
  },
  ἠνοίγησαν: {
    parse: "V-2API-3P｜Verb, 2nd aorist, passive, indicative, third, plural",
    GN: "G455",
    gloss: "to open",
  },
  ἤνοιξε: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G455",
    gloss: "to open",
  },
  ἤνοιξεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G455",
    gloss: "to open",
  },
  ἤνοιξέν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G455",
    gloss: "to open",
  },
  ἠνοίχθη: {
    parse: "V-2API-3S｜Verb, 2nd aorist, passive, indicative, third, singular",
    GN: "G455",
    gloss: "to open",
  },
  ἠνοίχθησαν: {
    parse: "V-2API-3P｜Verb, 2nd aorist, passive, indicative, third, plural",
    GN: "G455",
    gloss: "to open",
  },
  ἀνοικοδομήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G456",
    gloss: "to rebuild",
  },
  ἀνοίξει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G457",
    gloss: "opening",
  },
  ἀνομίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G458",
    gloss: "lawlessness",
  },
  ἀνομία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G458",
    gloss: "lawlessness",
  },
  ἀνομίαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G458",
    gloss: "lawlessness",
  },
  ἀνομίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G458",
    gloss: "lawlessness",
  },
  ἀνομίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G458",
    gloss: "lawlessness",
  },
  ἀνομιῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G458",
    gloss: "lawlessness",
  },
  ἀνόμοις: {
    parse: "A-DPN｜Adjective, dative, plural, neuter",
    GN: "G459",
    gloss: "lawless",
  },
  ἄνομος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G459",
    gloss: "lawless",
  },
  ἀνόμους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G459",
    gloss: "lawless",
  },
  ἀνόμων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G459",
    gloss: "lawless",
  },
  ἀνόμως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G460",
    gloss: "without law",
  },
  ἀνορθώσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G461",
    gloss: "to restore",
  },
  ἀνορθώσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G461",
    gloss: "to restore",
  },
  ἀνωρθώθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G461",
    gloss: "to restore",
  },
  ἀνόσιοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G462",
    gloss: "unholy",
  },
  ἀνοσίοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G462",
    gloss: "unholy",
  },
  ἀνοχῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G463",
    gloss: "tolerance",
  },
  ἀνοχῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G463",
    gloss: "tolerance",
  },
  ἀνταγωνιζόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G464",
    gloss: "to struggle",
  },
  ἀντάλλαγμα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G465",
    gloss: "in exchange",
  },
  ἀνταναπληρῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G466",
    gloss: "to fill again",
  },
  ἀνταποδοθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G467",
    gloss: "to repay",
  },
  ἀνταποδοῦναι: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G467",
    gloss: "to repay",
  },
  ἀνταποδοῦναί: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G467",
    gloss: "to repay",
  },
  ἀνταποδώσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G467",
    gloss: "to repay",
  },
  ἀνταπόδομα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G468",
    gloss: "repayment",
  },
  ἀνταπόδομά: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G468",
    gloss: "repayment",
  },
  ἀνταπόδοσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G469",
    gloss: "reward",
  },
  ἀνταποκριθῆναι: {
    parse: "V-AON｜Verb, aorist, Passive deponent, Infinitive",
    GN: "G470",
    gloss: "to contradict",
  },
  ἀνταποκρινόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G470",
    gloss: "to contradict",
  },
  ἀνθέξεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G472",
    gloss: "to cling to",
  },
  ἀντέχεσθε: {
    parse:
      "V-PNM-2P｜Verb, present, Middle or Passive deponent, imperative, second, plural",
    GN: "G472",
    gloss: "to cling to",
  },
  ἀντεχόμενον: {
    parse:
      "V-PNP-ASM｜Verb, present, Middle or Passive deponent, Participle, accusative, singular, masculine",
    GN: "G472",
    gloss: "to cling to",
  },
  ἀνθʼ: { parse: "PREP｜Preposition", GN: "G473", gloss: "for" },
  ἀντὶ: { parse: "PREP｜Preposition", GN: "G473", gloss: "for" },
  ἀντιβάλλετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G474",
    gloss: "to discuss",
  },
  ἀντιδιατιθεμένους: {
    parse:
      "V-PMP-APM｜Verb, present, middle, Participle, accusative, plural, masculine",
    GN: "G475",
    gloss: "to oppose",
  },
  ἀντίδικος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G476",
    gloss: "opponent",
  },
  ἀντιδίκου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G476",
    gloss: "opponent",
  },
  ἀντιδίκῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G476",
    gloss: "opponent",
  },
  ἀντιθέσεις: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G477",
    gloss: "opposition",
  },
  ἀντικατέστητε: {
    parse: "V-2AAI-2P｜Verb, 2nd aorist, active, indicative, second, plural",
    GN: "G478",
    gloss: "to resist",
  },
  ἀντικαλέσωσίν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G479",
    gloss: "to invite in return",
  },
  ἀντικείμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G480",
    gloss: "be an opponent",
  },
  ἀντικείμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G480",
    gloss: "be an opponent",
  },
  ἀντικειμένῳ: {
    parse:
      "V-PNP-DSM｜Verb, present, Middle or Passive deponent, Participle, dative, singular, masculine",
    GN: "G480",
    gloss: "be an opponent",
  },
  ἀντικειμένων: {
    parse:
      "V-PNP-GPM｜Verb, present, Middle or Passive deponent, Participle, genitive, plural, masculine",
    GN: "G480",
    gloss: "be an opponent",
  },
  ἀντίκειται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G480",
    gloss: "be an opponent",
  },
  ἄντικρυς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G481",
    gloss: "opposite",
  },
  ἀντελάβετο: {
    parse:
      "V-2ADI-3S｜Verb, 2nd aorist, Middle deponent, indicative, third, singular",
    GN: "G482",
    gloss: "to help",
  },
  ἀντιλαμβάνεσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G482",
    gloss: "to help",
  },
  ἀντιλαμβανόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, middle or passive, Participle, nominative, plural, masculine",
    GN: "G482",
    gloss: "to help",
  },
  ἀντειπεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G483",
    gloss: "to dispute",
  },
  ἀντέλεγον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G483",
    gloss: "to dispute",
  },
  ἀντιλέγει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G483",
    gloss: "to dispute",
  },
  ἀντιλέγεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G483",
    gloss: "to dispute",
  },
  ἀντιλεγόμενον: {
    parse:
      "V-PPP-ASN｜Verb, present, passive, Participle, accusative, singular, neuter",
    GN: "G483",
    gloss: "to dispute",
  },
  ἀντιλέγοντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G483",
    gloss: "to dispute",
  },
  ἀντιλέγοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G483",
    gloss: "to dispute",
  },
  ἀντιλέγοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G483",
    gloss: "to dispute",
  },
  ἀντιλεγόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G483",
    gloss: "to dispute",
  },
  ἀντιλήμψεις: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G484",
    gloss: "help",
  },
  ἀντιλογίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G485",
    gloss: "dispute",
  },
  ἀντιλογίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G485",
    gloss: "dispute",
  },
  ἀντιλογίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G485",
    gloss: "dispute",
  },
  ἀντελοιδόρει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G486",
    gloss: "to retaliate",
  },
  ἀντίλυτρον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G487",
    gloss: "ransom",
  },
  ἀντιμετρηθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G488",
    gloss: "to return",
  },
  ἀντιμισθίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G489",
    gloss: "recompense",
  },
  Ἀντιοχείᾳ: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G490",
    gloss: "Antioch",
  },
  Ἀντιόχειαν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G490",
    gloss: "Antioch",
  },
  Ἀντιοχείας: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G490",
    gloss: "Antioch",
  },
  Ἀντιοχέα: {
    parse: "N-ASM-LG｜Noun, accusative, singular, masculine, Location Gentilic",
    GN: "G0491",
  },
  ἀντιπαρῆλθεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G492",
    gloss: "to pass",
  },
  Ἀντιπᾶς: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G493",
    gloss: "Antipas",
  },
  Ἀντιπατρίδα: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G494",
    gloss: "Antipatris",
  },
  ἀντιπέρα: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G495",
    gloss: "opposite",
  },
  ἀντιπίπτετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G496",
    gloss: "to resist",
  },
  ἀντιστρατευόμενον: {
    parse:
      "V-PNP-ASM｜Verb, present, Middle or Passive deponent, Participle, accusative, singular, masculine",
    GN: "G497",
    gloss: "to wage war against",
  },
  ἀντιτάσσεται: {
    parse: "V-PMI-3S｜Verb, present, middle, indicative, third, singular",
    GN: "G498",
    gloss: "to resist",
  },
  ἀντιτασσόμενος: {
    parse:
      "V-PMP-NSM｜Verb, present, middle, Participle, nominative, singular, masculine",
    GN: "G498",
    gloss: "to resist",
  },
  ἀντιτασσομένων: {
    parse:
      "V-PMP-GPM｜Verb, present, middle, Participle, genitive, plural, masculine",
    GN: "G498",
    gloss: "to resist",
  },
  ἀντίτυπα: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G499",
    gloss: "representation",
  },
  ἀντίτυπον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G499",
    gloss: "representation",
  },
  ἀντίχριστοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G500",
    gloss: "antichrist",
  },
  ἀντίχριστος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G500",
    gloss: "antichrist",
  },
  ἀντιχρίστου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G500",
    gloss: "antichrist",
  },
  ἀντλεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G501",
    gloss: "to draw",
  },
  ἀντλῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G501",
    gloss: "to draw",
  },
  Ἀντλήσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G501",
    gloss: "to draw",
  },
  ἠντληκότες: {
    parse:
      "V-RAP-NPM｜Verb, perfect, active, Participle, nominative, plural, masculine",
    GN: "G501",
    gloss: "to draw",
  },
  ἄντλημα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G502",
    gloss: "bucket",
  },
  ἀντοφθαλμεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G503",
    gloss: "to face",
  },
  ἄνυδροι: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G504",
    gloss: "waterless",
  },
  ἀνύδρων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G504",
    gloss: "waterless",
  },
  ἀνυπόκριτον: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G505",
    gloss: "genuine",
  },
  ἀνυπόκριτος: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G505",
    gloss: "genuine",
  },
  ἀνυποκρίτου: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G505",
    gloss: "genuine",
  },
  ἀνυποκρίτῳ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G505",
    gloss: "genuine",
  },
  ἀνυπότακτα: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G506",
    gloss: "insubordinate",
  },
  ἀνυπότακτοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G506",
    gloss: "insubordinate",
  },
  ἀνυποτάκτοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G506",
    gloss: "insubordinate",
  },
  ἀνυπότακτον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G506",
    gloss: "insubordinate",
  },
  ἄνω: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G507",
    gloss: "above",
  },
  ἀνάγαιον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G508",
    gloss: "an upper room",
  },
  ἄνωθεν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G509",
    gloss: "from above/again",
  },
  ἄνωθέν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G509",
    gloss: "from above/again",
  },
  ἀνωτερικὰ: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G510",
    gloss: "interior",
  },
  ἀνώτερον: {
    parse: "A-ASN-C｜Adjective, accusative, singular, neuter, Comparative",
    GN: "G511",
    gloss: "higher",
  },
  ἀνωφελεῖς: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G512",
    gloss: "useless",
  },
  ἀνωφελές: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G512",
    gloss: "useless",
  },
  ἀξίνη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G513",
    gloss: "axe",
  },
  ἄξια: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G514",
    gloss: "worthy",
  },
  ἀξία: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G514",
    gloss: "worthy",
  },
  ἄξιοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G514",
    gloss: "worthy",
  },
  ἄξιοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G514",
    gloss: "worthy",
  },
  ἄξιον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G514",
    gloss: "worthy",
  },
  ἄξιόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G514",
    gloss: "worthy",
  },
  Ἄξιόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G514",
    gloss: "worthy",
  },
  ἄξιος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G514",
    gloss: "worthy",
  },
  Ἄξιος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G514",
    gloss: "worthy",
  },
  ἄξιός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G514",
    gloss: "worthy",
  },
  Ἄξιός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G514",
    gloss: "worthy",
  },
  ἀξίους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G514",
    gloss: "worthy",
  },
  ἀξιοῦμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G515",
    gloss: "to deem worthy",
  },
  ἀξιούσθωσαν: {
    parse: "V-PPM-3P｜Verb, present, passive, imperative, third, plural",
    GN: "G515",
    gloss: "to deem worthy",
  },
  ἀξιωθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G515",
    gloss: "to deem worthy",
  },
  ἀξιώσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G515",
    gloss: "to deem worthy",
  },
  ἠξίου: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G515",
    gloss: "to deem worthy",
  },
  ἠξίωσα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G515",
    gloss: "to deem worthy",
  },
  ἠξίωται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G515",
    gloss: "to deem worthy",
  },
  ἀξίως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G516",
    gloss: "appropriately",
  },
  ἀόρατα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G517",
    gloss: "invisible",
  },
  ἀόρατον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G517",
    gloss: "invisible",
  },
  ἀοράτου: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G517",
    gloss: "invisible",
  },
  ἀοράτῳ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G517",
    gloss: "invisible",
  },
  ἀπαγγεῖλαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G518",
    gloss: "to announce",
  },
  ἀπαγγεῖλαί: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G518",
    gloss: "to announce",
  },
  ἀπαγγείλατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G518",
    gloss: "to announce",
  },
  Ἀπαγγείλατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G518",
    gloss: "to announce",
  },
  ἀπαγγείλατέ: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G518",
    gloss: "to announce",
  },
  ἀπάγγειλον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G518",
    gloss: "to announce",
  },
  ἀπαγγελεῖ: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G518",
    gloss: "to announce",
  },
  ἀπαγγέλλομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G518",
    gloss: "to announce",
  },
  ἀπαγγέλλοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G518",
    gloss: "to announce",
  },
  ἀπαγγέλλοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G518",
    gloss: "to announce",
  },
  ἀπαγγέλλουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G518",
    gloss: "to announce",
  },
  ἀπαγγέλλων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G518",
    gloss: "to announce",
  },
  ἀπαγγελῶ: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G518",
    gloss: "to announce",
  },
  Ἀπαγγελῶ: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G518",
    gloss: "to announce",
  },
  ἀπήγγειλαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G518",
    gloss: "to announce",
  },
  ἀπήγγειλεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G518",
    gloss: "to announce",
  },
  ἀπηγγέλη: {
    parse: "V-2API-3S｜Verb, 2nd aorist, passive, indicative, third, singular",
    GN: "G518",
    gloss: "to announce",
  },
  ἀπήγγελλον: {
    parse: "V-IAI-1S｜Verb, imperfect, active, indicative, first, singular",
    GN: "G518",
    gloss: "to announce",
  },
  ἀπήγξατο: {
    parse: "V-AMI-3S｜Verb, aorist, middle, indicative, third, singular",
    GN: "G519",
    gloss: "to strangle",
  },
  ἄπαγε: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G520",
    gloss: "to lead away",
  },
  ἀπαγαγὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G520",
    gloss: "to lead away",
  },
  ἀπάγετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G520",
    gloss: "to lead away",
  },
  ἀπαγόμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G520",
    gloss: "to lead away",
  },
  ἀπαγομένους: {
    parse:
      "V-PPP-APM｜Verb, present, passive, Participle, accusative, plural, masculine",
    GN: "G520",
    gloss: "to lead away",
  },
  ἀπάγουσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G520",
    gloss: "to lead away",
  },
  ἀπαχθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G520",
    gloss: "to lead away",
  },
  ἀπήγαγον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G520",
    gloss: "to lead away",
  },
  ἀπαιδεύτους: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G521",
    gloss: "uninstructed",
  },
  ἀπαρθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G522",
    gloss: "to take away",
  },
  ἀπαίτει: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G523",
    gloss: "to demand",
  },
  ἀπαιτοῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G523",
    gloss: "to demand",
  },
  ἀπηλγηκότες: {
    parse:
      "V-RAP-NPM｜Verb, perfect, active, Participle, nominative, plural, masculine",
    GN: "G524",
    gloss: "to become callous",
  },
  ἀπαλλάξῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G525",
    gloss: "to release",
  },
  ἀπαλλάσσεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G525",
    gloss: "to release",
  },
  ἀπηλλάχθαι: {
    parse: "V-RPN｜Verb, perfect, passive, Infinitive",
    GN: "G525",
    gloss: "to release",
  },
  ἀπηλλοτριωμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G526",
    gloss: "to alienate",
  },
  ἀπηλλοτριωμένους: {
    parse:
      "V-RPP-APM｜Verb, perfect, passive, Participle, accusative, plural, masculine",
    GN: "G526",
    gloss: "to alienate",
  },
  ἁπαλὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G527",
    gloss: "tender",
  },
  ἀπαντήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G528",
    gloss: "to meet",
  },
  ἀπήντησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G528",
    gloss: "to meet",
  },
  ἀπάντησιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G529",
    gloss: "meeting",
  },
  ἅπαξ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G530",
    gloss: "once",
  },
  ἀπαράβατον: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G531",
    gloss: "permanent",
  },
  ἀπαρασκευάστους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G532",
    gloss: "unprepared",
  },
  ἀπαρνηθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G533",
    gloss: "to deny",
  },
  ἀπαρνησάσθω: {
    parse: "V-ADM-3S｜Verb, aorist, middle, imperative, third, singular",
    GN: "G533",
    gloss: "to deny",
  },
  ἀπαρνήσῃ: {
    parse:
      "V-FDI-2S｜Verb, future, Middle deponent, indicative, second, singular",
    GN: "G533",
    gloss: "to deny",
  },
  ἀπαρνήσομαι: {
    parse:
      "V-FDI-1S｜Verb, future, Middle deponent, indicative, first, singular",
    GN: "G533",
    gloss: "to deny",
  },
  ἀπαρτισμόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G535",
    gloss: "completion",
  },
  ἀπαρχὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G536",
    gloss: "firstfruits",
  },
  ἀπαρχήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G536",
    gloss: "firstfruits",
  },
  ἀπαρχὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G536",
    gloss: "firstfruits",
  },
  ἅπαν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G537",
    gloss: "all",
  },
  ἅπαντα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G537",
    gloss: "all",
  },
  ἅπαντας: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G537",
    gloss: "all",
  },
  ἅπαντες: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G537",
    gloss: "all",
  },
  ἁπάντων: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G537",
    gloss: "all",
  },
  ἅπας: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G537",
    gloss: "all",
  },
  ἅπασαν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G537",
    gloss: "all",
  },
  ἀπατάτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G538",
    gloss: "to trick",
  },
  ἀπατῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G538",
    gloss: "to trick",
  },
  ἠπατήθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G538",
    gloss: "to trick",
  },
  ἀπάταις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G539",
    gloss: "deceit",
  },
  ἀπάτῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G539",
    gloss: "deceit",
  },
  ἀπάτη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G539",
    gloss: "deceit",
  },
  ἀπάτης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G539",
    gloss: "deceit",
  },
  ἀπάτωρ: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G540",
    gloss: "fatherless",
  },
  ἀπαύγασμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G541",
    gloss: "radiance",
  },
  ἀπειθείᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G543",
    gloss: "disobedience",
  },
  ἀπείθειαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G543",
    gloss: "disobedience",
  },
  ἀπειθείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G543",
    gloss: "disobedience",
  },
  ἀπειθήσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G544",
    gloss: "to disobey",
  },
  ἀπειθήσασιν: {
    parse:
      "V-AAP-DPM｜Verb, aorist, active, Participle, dative, plural, masculine",
    GN: "G544",
    gloss: "to disobey",
  },
  ἀπειθήσασίν: {
    parse:
      "V-AAP-DPM｜Verb, aorist, active, Participle, dative, plural, masculine",
    GN: "G544",
    gloss: "to disobey",
  },
  ἀπειθοῦντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G544",
    gloss: "to disobey",
  },
  ἀπειθοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G544",
    gloss: "to disobey",
  },
  ἀπειθούντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G544",
    gloss: "to disobey",
  },
  ἀπειθοῦσι: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G544",
    gloss: "to disobey",
  },
  ἀπειθοῦσιν: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G544",
    gloss: "to disobey",
  },
  ἀπειθῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G544",
    gloss: "to disobey",
  },
  ἠπείθησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G544",
    gloss: "to disobey",
  },
  ἠπειθήσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G544",
    gloss: "to disobey",
  },
  ἠπείθουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G544",
    gloss: "to disobey",
  },
  ἀπειθεῖς: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G545",
    gloss: "disobedient",
  },
  ἀπειθὴς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G545",
    gloss: "disobedient",
  },
  ἀπειλησώμεθα: {
    parse: "V-AMS-1P｜Verb, aorist, middle, subjunctive, first, plural",
    GN: "G546",
    gloss: "to threaten",
  },
  ἠπείλει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G546",
    gloss: "to threaten",
  },
  ἀπειλὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G547",
    gloss: "threat",
  },
  ἀπειλήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G547",
    gloss: "threat",
  },
  ἀπειλῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G547",
    gloss: "threat",
  },
  ἄπειμι: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G548",
    gloss: "be away",
  },
  ἀπόντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G548",
    gloss: "be away",
  },
  ἀπὼν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G548",
    gloss: "be away",
  },
  ἀπῄεσαν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G549",
    gloss: "to go",
  },
  ἀπειπάμεθα: {
    parse: "V-2AMI-1P｜Verb, 2nd aorist, middle, indicative, first, plural",
    GN: "G550",
    gloss: "to renounce",
  },
  ἀπείραστός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G551",
    gloss: "untempted",
  },
  ἄπειρος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G552",
    gloss: "unacquainted",
  },
  ἀπεκδέχεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G553",
    gloss: "to expect",
  },
  ἀπεκδεχόμεθα: {
    parse:
      "V-PNI-1P｜Verb, present, Middle or Passive deponent, indicative, first, plural",
    GN: "G553",
    gloss: "to expect",
  },
  ἀπεκδεχόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G553",
    gloss: "to expect",
  },
  ἀπεκδεχομένοις: {
    parse:
      "V-PNP-DPM｜Verb, present, Middle or Passive deponent, Participle, dative, plural, masculine",
    GN: "G553",
    gloss: "to expect",
  },
  ἀπεκδεχομένους: {
    parse:
      "V-PNP-APM｜Verb, present, Middle or Passive deponent, Participle, accusative, plural, masculine",
    GN: "G553",
    gloss: "to expect",
  },
  ἀπεξεδέχετο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G553",
    gloss: "to expect",
  },
  ἀπεκδυσάμενοι: {
    parse:
      "V-ADP-NPM｜Verb, aorist, Middle deponent, Participle, nominative, plural, masculine",
    GN: "G554",
    gloss: "to take off",
  },
  ἀπεκδυσάμενος: {
    parse:
      "V-ADP-NSM｜Verb, aorist, Middle deponent, Participle, nominative, singular, masculine",
    GN: "G554",
    gloss: "to take off",
  },
  ἀπεκδύσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G555",
    gloss: "removal",
  },
  ἀπήλασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G556",
    gloss: "to eject",
  },
  ἀπελεγμὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G557",
    gloss: "discredit",
  },
  ἀπελεύθερος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G558",
    gloss: "freedman",
  },
  Ἀπελλῆν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G559",
    gloss: "Apelles",
  },
  ἀπελπίζοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G560",
    gloss: "to despair",
  },
  ἀπέναντι: { parse: "PREP｜Preposition", GN: "G561", gloss: "opposite" },
  ἀπεράντοις: {
    parse: "A-DPF｜Adjective, dative, plural, feminine",
    GN: "G562",
    gloss: "endless",
  },
  ἀπερισπάστως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G563",
    gloss: "undistracted",
  },
  ἀπερίτμητοι: {
    // or nominative
    parse: "A-VPM｜Adjective, Vocative, plural, masculine",
    GN: "G564",
    gloss: "uncircumcised",
  },
  ἀπελεύσομαι: {
    parse: "V-FDI-1S｜Verb, future, middle, indicative, first, singular",
    GN: "G565",
    gloss: "to go away",
  },
  ἀπελευσόμεθα: {
    parse: "V-FDI-1P｜Verb, future, middle, indicative, first, plural",
    GN: "G565",
    gloss: "to go away",
  },
  ἀπελεύσονται: {
    parse: "V-FDI-3P｜Verb, future, middle, indicative, third, plural",
    GN: "G565",
    gloss: "to go away",
  },
  ἀπεληλύθεισαν: {
    parse: "V-2LAI-3P｜Verb, 2nd pluperfect, active, indicative, third, plural",
    GN: "G565",
    gloss: "to go away",
  },
  ἀπελήλυθεν: {
    parse: "V-2RAI-3S｜Verb, 2nd perfect, active, indicative, third, singular",
    GN: "G565",
    gloss: "to go away",
  },
  ἀπελθεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G565",
    gloss: "to go away",
  },
  ἀπέλθῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G565",
    gloss: "to go away",
  },
  ἀπέλθητε: {
    parse: "V-2AAS-2P｜Verb, 2nd aorist, active, subjunctive, second, plural",
    GN: "G565",
    gloss: "to go away",
  },
  ἀπελθόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G565",
    gloss: "to go away",
  },
  Ἀπελθόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G565",
    gloss: "to go away",
  },
  ἀπελθόντι: {
    parse:
      "V-2AAP-DSM｜Verb, 2nd aorist, active, Participle, dative, singular, masculine",
    GN: "G565",
    gloss: "to go away",
  },
  Ἀπελθόντων: {
    parse:
      "V-2AAP-GPM｜Verb, 2nd aorist, active, Participle, genitive, plural, masculine",
    GN: "G565",
    gloss: "to go away",
  },
  ἀπελθοῦσα: {
    parse:
      "V-2AAP-NSF｜Verb, 2nd aorist, active, Participle, nominative, singular, feminine",
    GN: "G565",
    gloss: "to go away",
  },
  ἀπελθοῦσαι: {
    parse:
      "V-2AAP-NPF｜Verb, 2nd aorist, active, Participle, nominative, plural, feminine",
    GN: "G565",
    gloss: "to go away",
  },
  ἀπέλθω: {
    parse: "V-2AAS-1S｜Verb, 2nd aorist, active, subjunctive, first, singular",
    GN: "G565",
    gloss: "to go away",
  },
  ἀπελθὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G565",
    gloss: "to go away",
  },
  ἀπέλθωσιν: {
    parse: "V-2AAS-3P｜Verb, 2nd aorist, active, subjunctive, third, plural",
    GN: "G565",
    gloss: "to go away",
  },
  ἀπέρχεσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G565",
    gloss: "to go away",
  },
  ἀπέρχῃ: {
    parse:
      "V-PNS-2S｜Verb, present, Middle or Passive deponent, subjunctive, second, singular",
    GN: "G565",
    gloss: "to go away",
  },
  ἀπερχομένων: {
    parse:
      "V-PNP-GPF｜Verb, present, Middle or Passive deponent, Participle, genitive, plural, feminine",
    GN: "G565",
    gloss: "to go away",
  },
  ἀπῆλθα: {
    parse: "V-2AAI-1S｜Verb, 2nd aorist, active, indicative, first, singular",
    GN: "G565",
    gloss: "to go away",
  },
  ἀπῆλθαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G565",
    gloss: "to go away",
  },
  ἀπῆλθεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G565",
    gloss: "to go away",
  },
  ἀπῆλθον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G565",
    gloss: "to go away",
  },
  ἀπῆλθόν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G565",
    gloss: "to go away",
  },
  ἀπεῖχεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G568",
    gloss: "to have in full, was/is, abstain",
  },
  ἀπέχει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G568",
    gloss: "to have in full, was/is, abstain",
  },
  ἀπέχεσθαι: {
    parse: "V-PMN｜Verb, present, middle, Infinitive",
    GN: "G568",
    gloss: "to have in full, was/is, abstain",
  },
  ἀπέχεσθε: {
    parse: "V-PMM-2P｜Verb, present, middle, imperative, second, plural",
    GN: "G568",
    gloss: "to have in full, was/is, abstain",
  },
  ἀπέχετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G568",
    gloss: "to have in full, was/is, abstain",
  },
  ἀπέχῃς: {
    parse: "V-PAS-2S｜Verb, present, active, subjunctive, second, singular",
    GN: "G568",
    gloss: "to have in full, was/is, abstain",
  },
  ἀπέχοντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G568",
    gloss: "to have in full, was/is, abstain",
  },
  ἀπέχουσαν: {
    parse:
      "V-PAP-ASF｜Verb, present, active, Participle, accusative, singular, feminine",
    GN: "G568",
    gloss: "to have in full, was/is, abstain",
  },
  ἀπέχουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G568",
    gloss: "to have in full, was/is, abstain",
  },
  ἀπέχουσι: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G568",
    gloss: "to have in full, was/is, abstain",
  },
  ἀπέχω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G568",
    gloss: "to have in full, was/is, abstain",
  },
  ἀπιστήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G569",
    gloss: "to disbelieve",
  },
  ἀπιστοῦμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G569",
    gloss: "to disbelieve",
  },
  ἀπιστούντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G569",
    gloss: "to disbelieve",
  },
  ἀπιστοῦσιν: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G569",
    gloss: "to disbelieve",
  },
  ἠπίστησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G569",
    gloss: "to disbelieve",
  },
  ἠπίστησάν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G569",
    gloss: "to disbelieve",
  },
  ἠπίστουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G569",
    gloss: "to disbelieve",
  },
  ἀπιστίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G570",
    gloss: "unbelief",
  },
  ἀπιστία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G570",
    gloss: "unbelief",
  },
  ἀπιστίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G570",
    gloss: "unbelief",
  },
  ἀπιστίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G570",
    gloss: "unbelief",
  },
  ἄπιστοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G571",
    gloss: "unbelieving",
  },
  ἀπίστοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G571",
    gloss: "unbelieving",
  },
  ἄπιστον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G571",
    gloss: "unbelieving",
  },
  ἄπιστος: {
    parse: "A-VSF｜Adjective, Vocative or nominative, singular, feminine",
    GN: "G571",
    gloss: "unbelieving",
  },
  ἀπίστου: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G571",
    gloss: "unbelieving",
  },
  ἀπίστων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G571",
    gloss: "unbelieving",
  },
  ἁπλότητα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G572",
    gloss: "openness",
  },
  ἁπλότητι: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G572",
    gloss: "openness",
  },
  ἁπλότητος: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G572",
    gloss: "openness",
  },
  ἁπλοῦς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G573",
    gloss: "sound",
  },
  ἁπλῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G574",
    gloss: "without reserve",
  },
  ἀπʼ: { parse: "PREP｜Preposition", GN: "G575", gloss: "away from" },
  απο: { parse: "PREP｜Preposition", GN: "G575", gloss: "away from" },
  ἀπό: { parse: "PREP｜Preposition", GN: "G575", gloss: "away from" },
  ἀπὸ: { parse: "PREP｜Preposition", GN: "G575", gloss: "away from" },
  Ἀπὸ: { parse: "PREP｜Preposition", GN: "G575", gloss: "away from" },
  ἀφʼ: { parse: "PREP｜Preposition", GN: "G575", gloss: "away from" },
  ἀπέβησαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G576",
    gloss: "to get out",
  },
  ἀποβάντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G576",
    gloss: "to get out",
  },
  ἀποβήσεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G576",
    gloss: "to get out",
  },
  ἀποβάλητε: {
    parse: "V-2AAS-2P｜Verb, 2nd aorist, active, subjunctive, second, plural",
    GN: "G577",
    gloss: "to throw away",
  },
  ἀποβαλὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G577",
    gloss: "to throw away",
  },
  ἀπέβλεπεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G578",
    gloss: "to look ahead",
  },
  ἀπόβλητον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G579",
    gloss: "rejected",
  },
  ἀποβολὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G580",
    gloss: "deprivation",
  },
  ἀπογενόμενοι: {
    parse:
      "V-2ADP-NPM｜Verb, 2nd aorist, Middle deponent, Participle, nominative, plural, masculine",
    GN: "G581",
    gloss: "to cease to be",
  },
  ἀπογραφὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G582",
    gloss: "census",
  },
  ἀπογραφῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G582",
    gloss: "census",
  },
  ἀπογεγραμμένων: {
    parse:
      "V-RPP-GPM｜Verb, perfect, passive, Participle, genitive, plural, masculine",
    GN: "G583",
    gloss: "to register",
  },
  ἀπογράφεσθαι: {
    parse: "V-PEN｜Verb, present, Either middle or passive, Infinitive",
    GN: "G583",
    gloss: "to register",
  },
  ἀπογράψασθαι: {
    parse: "V-AMN｜Verb, aorist, middle, Infinitive",
    GN: "G583",
    gloss: "to register",
  },
  ἀπέδειξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G584",
    gloss: "to display",
  },
  ἀποδεδειγμένον: {
    parse:
      "V-RPP-ASM｜Verb, perfect, passive, Participle, accusative, singular, masculine",
    GN: "G584",
    gloss: "to display",
  },
  ἀποδεικνύντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G584",
    gloss: "to display",
  },
  ἀποδεῖξαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G584",
    gloss: "to display",
  },
  ἀποδείξει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G585",
    gloss: "demonstration",
  },
  ἀποδεκατοῦν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G586",
    gloss: "to tithe",
  },
  ἀποδεκατοῦτε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G586",
    gloss: "to tithe",
  },
  ἀποδεκατῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G586",
    gloss: "to tithe",
  },
  ἀπόδεκτον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G587",
    gloss: "pleasing",
  },
  ἀπεδέξαντο: {
    parse: "V-ADI-3P｜Verb, aorist, Middle deponent, indicative, third, plural",
    GN: "G588",
    gloss: "to receive",
  },
  ἀπεδέξατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G588",
    gloss: "to receive",
  },
  ἀπεδέχετο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G588",
    gloss: "to receive",
  },
  ἀποδεξάμενοι: {
    parse:
      "V-ADP-NPM｜Verb, aorist, Middle deponent, Participle, nominative, plural, masculine",
    GN: "G588",
    gloss: "to receive",
  },
  ἀποδεξάμενος: {
    parse:
      "V-ADP-NSM｜Verb, aorist, Middle deponent, Participle, nominative, singular, masculine",
    GN: "G588",
    gloss: "to receive",
  },
  ἀποδέξασθαι: {
    parse: "V-ADN｜Verb, aorist, Middle deponent, Infinitive",
    GN: "G588",
    gloss: "to receive",
  },
  ἀποδεχόμεθα: {
    parse:
      "V-PNI-1P｜Verb, present, Middle or Passive deponent, indicative, first, plural",
    GN: "G588",
    gloss: "to receive",
  },
  ἀπεδήμησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G589",
    gloss: "to go abroad",
  },
  ἀποδημῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G589",
    gloss: "to go abroad",
  },
  ἀπόδημος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G590",
    gloss: "absent",
  },
  ἀπέδετο: {
    parse: "V-2AMI-3S｜Verb, 2nd aorist, middle, indicative, third, singular",
    GN: "G591",
    gloss: "to pay",
  },
  ἀπεδίδουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G591",
    gloss: "to pay",
  },
  ἀπέδοντο: {
    parse: "V-2AMI-3P｜Verb, 2nd aorist, middle, indicative, third, plural",
    GN: "G591",
    gloss: "to pay",
  },
  ἀπέδοσθε: {
    parse: "V-2AMI-2P｜Verb, 2nd aorist, middle, indicative, second, plural",
    GN: "G591",
    gloss: "to pay",
  },
  ἀπέδωκεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G591",
    gloss: "to pay",
  },
  ἀποδιδόναι: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G591",
    gloss: "to pay",
  },
  ἀποδιδόντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G591",
    gloss: "to pay",
  },
  ἀποδιδότω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G591",
    gloss: "to pay",
  },
  ἀποδιδοῦν: {
    parse:
      "V-PAP-NSN｜Verb, present, active, Participle, nominative, singular, neuter",
    GN: "G591",
    gloss: "to pay",
  },
  ἀποδίδωμι: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G591",
    gloss: "to pay",
  },
  ἀποδίδωσιν: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G591",
    gloss: "to pay",
  },
  ἀποδοθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G591",
    gloss: "to pay",
  },
  ἀπόδος: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G591",
    gloss: "to pay",
  },
  Ἀπόδος: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G591",
    gloss: "to pay",
  },
  ἀπόδοτε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G591",
    gloss: "to pay",
  },
  Ἀπόδοτε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G591",
    gloss: "to pay",
  },
  ἀποδοῦναι: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G591",
    gloss: "to pay",
  },
  ἀποδοὺς: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G591",
    gloss: "to pay",
  },
  ἀποδῷ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G591",
    gloss: "to pay",
  },
  ἀποδῷς: {
    parse: "V-2AAS-2S｜Verb, 2nd aorist, active, subjunctive, second, singular",
    GN: "G591",
    gloss: "to pay",
  },
  ἀποδώσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G591",
    gloss: "to pay",
  },
  ἀποδώσεις: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G591",
    gloss: "to pay",
  },
  ἀποδώσοντες: {
    parse:
      "V-FAP-NPM｜Verb, future, active, Participle, nominative, plural, masculine",
    GN: "G591",
    gloss: "to pay",
  },
  ἀποδώσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G591",
    gloss: "to pay",
  },
  ἀποδώσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G591",
    gloss: "to pay",
  },
  ἀποδιορίζοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G592",
    gloss: "to divide",
  },
  ἀπεδοκίμασαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G593",
    gloss: "to reject",
  },
  ἀπεδοκιμάσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G593",
    gloss: "to reject",
  },
  ἀποδεδοκιμασμένον: {
    parse:
      "V-RPP-ASM｜Verb, perfect, passive, Participle, accusative, singular, masculine",
    GN: "G593",
    gloss: "to reject",
  },
  ἀποδοκιμασθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G593",
    gloss: "to reject",
  },
  ἀποδοχῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G594",
    gloss: "acceptance",
  },
  ἀπόθεσις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G595",
    gloss: "removal",
  },
  ἀποθήκας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G596",
    gloss: "storehouse",
  },
  ἀποθήκη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G596",
    gloss: "storehouse",
  },
  ἀποθήκην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G596",
    gloss: "storehouse",
  },
  ἀποθησαυρίζοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G597",
    gloss: "to store up",
  },
  ἀποθλίβουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G598",
    gloss: "to crowd up to",
  },
  ἀπέθανε: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G599",
    gloss: "to die",
  },
  ἀπέθανεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G599",
    gloss: "to die",
  },
  ἀπεθάνετε: {
    parse: "V-2AAI-2P｜Verb, 2nd aorist, active, indicative, second, plural",
    GN: "G599",
    gloss: "to die",
  },
  ἀπεθάνομεν: {
    parse: "V-2AAI-1P｜Verb, 2nd aorist, active, indicative, first, plural",
    GN: "G599",
    gloss: "to die",
  },
  ἀπέθανον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G599",
    gloss: "to die",
  },
  ἀπέθνῃσκεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G599",
    gloss: "to die",
  },
  ἀποθανεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G599",
    gloss: "to die",
  },
  ἀποθανεῖσθε: {
    parse: "V-FDI-2P｜Verb, future, middle, indicative, second, plural",
    GN: "G599",
    gloss: "to die",
  },
  ἀποθανεῖται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G599",
    gloss: "to die",
  },
  ἀποθάνῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G599",
    gloss: "to die",
  },
  ἀποθανόντα: {
    parse:
      "V-2AAP-NPN｜Verb, 2nd aorist, active, Participle, nominative, plural, neuter",
    GN: "G599",
    gloss: "to die",
  },
  ἀποθανόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G599",
    gloss: "to die",
  },
  ἀποθανόντι: {
    parse:
      "V-2AAP-DSM｜Verb, 2nd aorist, active, Participle, dative, singular, masculine",
    GN: "G599",
    gloss: "to die",
  },
  ἀποθανόντος: {
    parse:
      "V-2AAP-GSM｜Verb, 2nd aorist, active, Participle, genitive, singular, masculine",
    GN: "G599",
    gloss: "to die",
  },
  ἀποθάνωμεν: {
    parse: "V-2AAS-1P｜Verb, 2nd aorist, active, subjunctive, first, plural",
    GN: "G599",
    gloss: "to die",
  },
  ἀποθανών: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G599",
    gloss: "to die",
  },
  ἀποθανὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G599",
    gloss: "to die",
  },
  ἀποθνῄσκει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G599",
    gloss: "to die",
  },
  ἀποθνῄσκειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G599",
    gloss: "to die",
  },
  ἀποθνῄσκομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G599",
    gloss: "to die",
  },
  ἀποθνῄσκοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G599",
    gloss: "to die",
  },
  ἀποθνῄσκουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G599",
    gloss: "to die",
  },
  ἀποθνῄσκω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G599",
    gloss: "to die",
  },
  ἀποθνῄσκωμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G599",
    gloss: "to die",
  },
  ἀποθνῄσκων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G599",
    gloss: "to die",
  },
  ἀπεκατεστάθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G600",
    gloss: "to restore",
  },
  ἀπεκατέστη: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G600",
    gloss: "to restore",
  },
  ἀποκαθιστάνει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G600",
    gloss: "to restore",
  },
  ἀποκαθιστάνεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G600",
    gloss: "to restore",
  },
  ἀποκατασταθῶ: {
    parse: "V-APS-1S｜Verb, aorist, passive, subjunctive, first, singular",
    GN: "G600",
    gloss: "to restore",
  },
  ἀποκαταστήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G600",
    gloss: "to restore",
  },
  ἀπεκαλύφθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G601",
    gloss: "to reveal",
  },
  ἀπεκάλυψας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G601",
    gloss: "to reveal",
  },
  ἀπεκάλυψεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G601",
    gloss: "to reveal",
  },
  ἀπεκάλυψέν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G601",
    gloss: "to reveal",
  },
  ἀποκαλύπτεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G601",
    gloss: "to reveal",
  },
  ἀποκαλύπτεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G601",
    gloss: "to reveal",
  },
  Ἀποκαλύπτεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G601",
    gloss: "to reveal",
  },
  ἀποκαλυφθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G601",
    gloss: "to reveal",
  },
  ἀποκαλυφθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G601",
    gloss: "to reveal",
  },
  ἀποκαλυφθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G601",
    gloss: "to reveal",
  },
  ἀποκαλυφθῶσιν: {
    parse: "V-APS-3P｜Verb, aorist, passive, subjunctive, third, plural",
    GN: "G601",
    gloss: "to reveal",
  },
  ἀποκαλύψαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G601",
    gloss: "to reveal",
  },
  ἀποκαλύψει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G602",
    gloss: "revelation",
  },
  ἀποκαλύψεις: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G602",
    gloss: "revelation",
  },
  ἀποκαλύψεων: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G602",
    gloss: "revelation",
  },
  ἀποκαλύψεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G602",
    gloss: "revelation",
  },
  ἀποκάλυψιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G602",
    gloss: "revelation",
  },
  Ἀποκάλυψις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G602",
    gloss: "revelation",
  },
  ἀποκαραδοκία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G603",
    gloss: "eager expectation",
  },
  ἀποκαραδοκίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G603",
    gloss: "eager expectation",
  },
  ἀποκαταλλάξαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G604",
    gloss: "to reconcile",
  },
  ἀποκαταλλάξῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G604",
    gloss: "to reconcile",
  },
  ἀποκατήλλαξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G604",
    gloss: "to reconcile",
  },
  ἀποκαταστάσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G605",
    gloss: "restoration",
  },
  ἀποκειμένην: {
    parse:
      "V-PNP-ASF｜Verb, present, Middle or Passive deponent, Participle, accusative, singular, feminine",
    GN: "G606",
    gloss: "to lay up",
  },
  ἀπόκειται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G606",
    gloss: "to lay up",
  },
  ἀπόκειταί: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G606",
    gloss: "to lay up",
  },
  ἀπεκεφάλισα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G607",
    gloss: "to behead",
  },
  ἀπεκεφάλισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G607",
    gloss: "to behead",
  },
  ἀποκλείσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G608",
    gloss: "to shut",
  },
  ἀπέκοψαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G609",
    gloss: "to cut off",
  },
  ἀπέκοψεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G609",
    gloss: "to cut off",
  },
  ἀπόκοψον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G609",
    gloss: "to cut off",
  },
  ἀποκόψονται: {
    parse: "V-FMI-3P｜Verb, future, middle, indicative, third, plural",
    GN: "G609",
    gloss: "to cut off",
  },
  ἀπόκριμα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G610",
    gloss: "verdict",
  },
  ἀπεκρίθη: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G611",
    gloss: "to answer",
  },
  Ἀπεκρίθη: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G611",
    gloss: "to answer",
  },
  ἀπεκρίθην: {
    parse:
      "V-ADI-1S｜Verb, aorist, Middle deponent, indicative, first, singular",
    GN: "G611",
    gloss: "to answer",
  },
  ἀπεκρίθης: {
    parse:
      "V-ADI-2S｜Verb, aorist, Middle deponent, indicative, second, singular",
    GN: "G611",
    gloss: "to answer",
  },
  ἀπεκρίθησαν: {
    parse: "V-ADI-3P｜Verb, aorist, Middle deponent, indicative, third, plural",
    GN: "G611",
    gloss: "to answer",
  },
  Ἀπεκρίθησαν: {
    parse: "V-ADI-3P｜Verb, aorist, Middle deponent, indicative, third, plural",
    GN: "G611",
    gloss: "to answer",
  },
  ἀπεκρίνατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G611",
    gloss: "to answer",
  },
  Ἀπεκρίνατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G611",
    gloss: "to answer",
  },
  ἀποκριθεὶς: {
    parse:
      "V-AOP-NSM｜Verb, aorist, Passive deponent, Participle, nominative, singular, masculine",
    GN: "G611",
    gloss: "to answer",
  },
  Ἀποκριθεὶς: {
    parse:
      "V-AOP-NSM｜Verb, aorist, Passive deponent, Participle, nominative, singular, masculine",
    GN: "G611",
    gloss: "to answer",
  },
  ἀποκριθεῖσα: {
    parse:
      "V-AOP-NSF｜Verb, aorist, Passive deponent, Participle, nominative, singular, feminine",
    GN: "G611",
    gloss: "to answer",
  },
  ἀποκριθὲν: {
    parse:
      "V-AOP-NSN｜Verb, aorist, Passive deponent, Participle, nominative, singular, neuter",
    GN: "G611",
    gloss: "to answer",
  },
  ἀποκριθέντες: {
    parse:
      "V-AOP-NPM｜Verb, aorist, Passive deponent, Participle, nominative, plural, masculine",
    GN: "G611",
    gloss: "to answer",
  },
  ἀποκριθῇ: {
    parse:
      "V-AOS-3S｜Verb, aorist, Passive deponent, subjunctive, third, singular",
    GN: "G611",
    gloss: "to answer",
  },
  ἀποκριθῆναι: {
    parse: "V-AON｜Verb, aorist, Passive deponent, Infinitive",
    GN: "G611",
    gloss: "to answer",
  },
  ἀποκριθήσεται: {
    parse:
      "V-FOI-3S｜Verb, future, Passive deponent, indicative, third, singular",
    GN: "G611",
    gloss: "to answer",
  },
  ἀποκριθήσονται: {
    parse:
      "V-FOI-3P｜Verb, future, Passive deponent, indicative, third, plural",
    GN: "G611",
    gloss: "to answer",
  },
  ἀποκρίθητέ: {
    parse:
      "V-AOM-2P｜Verb, aorist, Passive deponent, imperative, second, plural",
    GN: "G611",
    gloss: "to answer",
  },
  ἀποκριθῆτε: {
    parse:
      "V-AOS-2P｜Verb, aorist, Passive deponent, subjunctive, second, plural",
    GN: "G611",
    gloss: "to answer",
  },
  ἀποκριθῶσιν: {
    parse:
      "V-AOS-3P｜Verb, aorist, Passive deponent, subjunctive, third, plural",
    GN: "G611",
    gloss: "to answer",
  },
  ἀποκρίνεσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G611",
    gloss: "to answer",
  },
  ἀποκρίνεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G611",
    gloss: "to answer",
  },
  ἀποκρίνῃ: {
    parse:
      "V-PNI-2S｜Verb, present, Middle or Passive deponent, indicative, second, singular",
    GN: "G611",
    gloss: "to answer",
  },
  ἀποκρίσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G612",
    gloss: "answer",
  },
  ἀποκρίσεσιν: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G612",
    gloss: "answer",
  },
  ἀπόκρισιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G612",
    gloss: "answer",
  },
  ἀπέκρυψας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G613",
    gloss: "to conceal",
  },
  ἀποκεκρυμμένην: {
    parse:
      "V-RPP-ASF｜Verb, perfect, passive, Participle, accusative, singular, feminine",
    GN: "G613",
    gloss: "to conceal",
  },
  ἀποκεκρυμμένον: {
    parse:
      "V-RPP-ASN｜Verb, perfect, passive, Participle, accusative, singular, neuter",
    GN: "G613",
    gloss: "to conceal",
  },
  ἀποκεκρυμμένου: {
    parse:
      "V-RPP-GSN｜Verb, perfect, passive, Participle, genitive, singular, neuter",
    GN: "G613",
    gloss: "to conceal",
  },
  ἀπόκρυφοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G614",
    gloss: "concealed",
  },
  ἀπόκρυφον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G614",
    gloss: "concealed",
  },
  ἀπεκτάνθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G615",
    gloss: "to kill",
  },
  ἀπεκτάνθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G615",
    gloss: "to kill",
  },
  ἀπέκτειναν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G615",
    gloss: "to kill",
  },
  ἀπεκτείνατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G615",
    gloss: "to kill",
  },
  ἀπέκτεινεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G615",
    gloss: "to kill",
  },
  ἀποκτανθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G615",
    gloss: "to kill",
  },
  ἀποκτανθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G615",
    gloss: "to kill",
  },
  ἀποκτανθῶσιν: {
    parse: "V-APS-3P｜Verb, aorist, passive, subjunctive, third, plural",
    GN: "G615",
    gloss: "to kill",
  },
  ἀποκτεῖναι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G615",
    gloss: "to kill",
  },
  ἀποκτεινάντων: {
    parse:
      "V-AAP-GPM｜Verb, aorist, active, Participle, genitive, plural, masculine",
    GN: "G615",
    gloss: "to kill",
  },
  ἀποκτείνας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G615",
    gloss: "to kill",
  },
  ἀποκτεινόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G615",
    gloss: "to kill",
  },
  ἀποκτείνουσα: {
    parse:
      "V-PAP-VSF｜Verb, present, active, Participle, Vocative or nominative, singular, feminine",
    GN: "G615",
    gloss: "to kill",
  },
  ἀποκτείνωμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G615",
    gloss: "to kill",
  },
  ἀποκτείνωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G615",
    gloss: "to kill",
  },
  ἀποκτενεῖ: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G615",
    gloss: "to kill",
  },
  ἀποκτενεῖτε: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G615",
    gloss: "to kill",
  },
  ἀποκτέννει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G615",
    gloss: "to kill",
  },
  ἀποκτέννεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G615",
    gloss: "to kill",
  },
  ἀποκτέννοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G615",
    gloss: "to kill",
  },
  ἀποκτεννόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G615",
    gloss: "to kill",
  },
  ἀποκτενοῦσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G615",
    gloss: "to kill",
  },
  ἀποκτενῶ: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G615",
    gloss: "to kill",
  },
  ἀπεκύησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G616",
    gloss: "to generate",
  },
  ἀποκύει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G616",
    gloss: "to generate",
  },
  ἀπεκύλισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G617",
    gloss: "to roll away",
  },
  ἀποκεκυλισμένον: {
    parse:
      "V-RPP-ASM｜Verb, perfect, passive, Participle, accusative, singular, masculine",
    GN: "G617",
    gloss: "to roll away",
  },
  ἀποκεκύλισται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G617",
    gloss: "to roll away",
  },
  ἀποκυλίσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G617",
    gloss: "to roll away",
  },
  ἀπέλαβεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G618",
    gloss: "to get back",
  },
  ἀπέλαβες: {
    parse: "V-2AAI-2S｜Verb, 2nd aorist, active, indicative, second, singular",
    GN: "G618",
    gloss: "to get back",
  },
  ἀπολάβῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G618",
    gloss: "to get back",
  },
  ἀπολάβητε: {
    parse: "V-2AAS-2P｜Verb, 2nd aorist, active, subjunctive, second, plural",
    GN: "G618",
    gloss: "to get back",
  },
  ἀπολαβόμενος: {
    parse:
      "V-2AMP-NSM｜Verb, 2nd aorist, middle, Participle, nominative, singular, masculine",
    GN: "G618",
    gloss: "to get back",
  },
  ἀπολάβωμεν: {
    parse: "V-2AAS-1P｜Verb, 2nd aorist, active, subjunctive, first, plural",
    GN: "G618",
    gloss: "to get back",
  },
  ἀπολάβωσιν: {
    parse: "V-2AAS-3P｜Verb, 2nd aorist, active, subjunctive, third, plural",
    GN: "G618",
    gloss: "to get back",
  },
  ἀπολαμβάνομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G618",
    gloss: "to get back",
  },
  ἀπολαμβάνοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G618",
    gloss: "to get back",
  },
  ἀπολήμψεσθε: {
    parse:
      "V-FDI-2P｜Verb, future, Middle deponent, indicative, second, plural",
    GN: "G618",
    gloss: "to get back",
  },
  ἀπόλαυσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G619",
    gloss: "enjoyment",
  },
  ἀπέλιπον: {
    parse:
      "V-2AAI-1S｜Verb, 2nd aorist, active, indicative, first, singular OR third, plural",
    GN: "G620",
    gloss: "to leave",
  },
  ἀπέλιπόν: {
    parse:
      "V-2AAI-1S｜Verb, 2nd aorist, active, indicative, first, singular OR third, plural",
    GN: "G620",
    gloss: "to leave",
  },
  ἀπολείπεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G620",
    gloss: "to leave",
  },
  ἀπολελυμένην: {
    parse:
      "V-RPP-ASF｜Verb, perfect, passive, Participle, accusative, singular, feminine",
    GN: "G620",
    gloss: "to leave",
  },
  ἀπολιπόντας: {
    parse:
      "V-2AAP-APM｜Verb, 2nd aorist, active, Participle, accusative, plural, masculine",
    GN: "G620",
    gloss: "to leave",
  },
  ἐπέλειχον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G621",
    gloss: "to lick",
  },
  ἀπολεῖσθε: {
    parse: "V-FMI-2P｜Verb, future, middle, indicative, second, plural",
    GN: "G622",
    gloss: "to destroy",
  },
  ἀπολεῖται: {
    parse: "V-2FMI-3S｜Verb, 2nd future, middle, indicative, third, singular",
    GN: "G622",
    gloss: "to destroy",
  },
  ἀπολέσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G622",
    gloss: "to destroy",
  },
  ἀπολέσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G622",
    gloss: "to destroy",
  },
  ἀπολέσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G622",
    gloss: "to destroy",
  },
  ἀπολέσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G622",
    gloss: "to destroy",
  },
  ἀπολέσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G622",
    gloss: "to destroy",
  },
  ἀπολέσθαι: {
    parse: "V-2AMN｜Verb, 2nd aorist, middle, Infinitive",
    GN: "G622",
    gloss: "to destroy",
  },
  ἀπολέσω: {
    parse: "V-AAS-1S｜Verb, aorist, active, subjunctive, first, singular",
    GN: "G622",
    gloss: "to destroy",
  },
  ἀπολέσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G622",
    gloss: "to destroy",
  },
  ἀπόληται: {
    parse: "V-2AMS-3S｜Verb, 2nd aorist, middle, subjunctive, third, singular",
    GN: "G622",
    gloss: "to destroy",
  },
  ἀπόλλυε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G622",
    gloss: "to destroy",
  },
  ἀπολλύει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G622",
    gloss: "to destroy",
  },
  ἀπόλλυμαι: {
    parse: "V-PMI-1S｜Verb, present, middle, indicative, first, singular",
    GN: "G622",
    gloss: "to destroy",
  },
  ἀπολλύμεθα: {
    parse: "V-PMI-1P｜Verb, present, middle, indicative, first, plural",
    GN: "G622",
    gloss: "to destroy",
  },
  ἀπολλυμένην: {
    parse:
      "V-PMP-ASF｜Verb, present, middle, Participle, accusative, singular, feminine",
    GN: "G622",
    gloss: "to destroy",
  },
  ἀπολλύμενοι: {
    parse:
      "V-PEP-NPM｜Verb, present, Either middle or passive, Participle, nominative, plural, masculine",
    GN: "G622",
    gloss: "to destroy",
  },
  ἀπολλυμένοις: {
    parse:
      "V-PEP-DPM｜Verb, present, Either middle or passive, Participle, dative, plural, masculine",
    GN: "G622",
    gloss: "to destroy",
  },
  ἀπολλυμένου: {
    parse:
      "V-PMP-GSN｜Verb, present, middle, Participle, genitive, singular, neuter",
    GN: "G622",
    gloss: "to destroy",
  },
  ἀπόλλυνται: {
    parse: "V-PPI-3P｜Verb, present, passive, indicative, third, plural",
    GN: "G622",
    gloss: "to destroy",
  },
  ἀπόλλυται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G622",
    gloss: "to destroy",
  },
  ἀπολομένου: {
    parse:
      "V-2AMP-GSM｜Verb, 2nd aorist, middle, Participle, genitive, singular, masculine",
    GN: "G622",
    gloss: "to destroy",
  },
  ἀπολοῦνται: {
    parse: "V-FMI-3P｜Verb, future, middle, indicative, third, plural",
    GN: "G622",
    gloss: "to destroy",
  },
  Ἀπολῶ: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G622",
    gloss: "to destroy",
  },
  ἀπολωλός: {
    parse:
      "V-2RAP-ASN｜Verb, 2nd perfect, active, Participle, accusative, singular, neuter",
    GN: "G622",
    gloss: "to destroy",
  },
  ἀπολωλὸς: {
    parse:
      "V-2RAP-ASN｜Verb, 2nd perfect, active, Participle, accusative, singular, neuter",
    GN: "G622",
    gloss: "to destroy",
  },
  ἀπολωλότα: {
    parse:
      "V-2RAP-APN｜Verb, 2nd perfect, active, Participle, accusative, plural, neuter",
    GN: "G622",
    gloss: "to destroy",
  },
  ἀπολωλὼς: {
    parse:
      "V-2RAP-NSM｜Verb, 2nd perfect, active, Participle, nominative, singular, masculine",
    GN: "G622",
    gloss: "to destroy",
  },
  ἀπόλωνται: {
    parse: "V-2AMS-3P｜Verb, 2nd aorist, middle, subjunctive, third, plural",
    GN: "G622",
    gloss: "to destroy",
  },
  ἀπώλεσα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G622",
    gloss: "to destroy",
  },
  ἀπώλεσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G622",
    gloss: "to destroy",
  },
  ἀπώλετο: {
    parse: "V-2AMI-3S｜Verb, 2nd aorist, middle, indicative, third, singular",
    GN: "G622",
    gloss: "to destroy",
  },
  ἀπώλλυντο: {
    parse: "V-IPI-3P｜Verb, imperfect, passive, indicative, third, plural",
    GN: "G622",
    gloss: "to destroy",
  },
  ἀπώλοντο: {
    parse: "V-2AMI-3P｜Verb, 2nd aorist, middle, indicative, third, plural",
    GN: "G622",
    gloss: "to destroy",
  },
  Ἀπολλύων: {
    parse: "N-NSM-T｜Noun, nominative, singular, masculine, Title",
    GN: "G623",
    gloss: "Apollyon",
  },
  Ἀπολλωνίαν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G624",
    gloss: "Apollonia",
  },
  Ἀπολλῶ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G625",
    gloss: "Apollos",
  },
  Ἀπολλῶν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G625",
    gloss: "Apollos",
  },
  Ἀπολλῶς: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G625",
    gloss: "Apollos",
  },
  ἀπελογεῖτο: {
    parse:
      "V-INI-3S｜Verb, imperfect, middle or passive, indicative, third, singular",
    GN: "G626",
    gloss: "to defend oneself",
  },
  ἀπολογεῖσθαι: {
    parse: "V-PNN｜Verb, present, middle or passive deponent, Infinitive",
    GN: "G626",
    gloss: "to defend oneself",
  },
  ἀπολογηθῆναι: {
    parse: "V-AON｜Verb, aorist, passive deponent, Infinitive",
    GN: "G626",
    gloss: "to defend oneself",
  },
  ἀπολογήσησθε: {
    parse:
      "V-ADS-2P｜Verb, aorist, middle deponent, subjunctive, second, plural",
    GN: "G626",
    gloss: "to defend oneself",
  },
  ἀπολογοῦμαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G626",
    gloss: "to defend oneself",
  },
  ἀπολογούμεθα: {
    parse:
      "V-PNI-1P｜Verb, present, middle or passive deponent, indicative, first, plural",
    GN: "G626",
    gloss: "to defend oneself",
  },
  ἀπολογουμένου: {
    parse:
      "V-PNP-GSM｜Verb, present, middle or passive, Participle, genitive, singular, masculine",
    GN: "G626",
    gloss: "to defend oneself",
  },
  ἀπολογουμένων: {
    parse:
      "V-PNP-GPM｜Verb, present, Middle or Passive deponent, Participle, genitive, plural, masculine",
    GN: "G626",
    gloss: "to defend oneself",
  },
  ἀπολογίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G627",
    gloss: "defence",
  },
  ἀπολογία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G627",
    gloss: "defence",
  },
  ἀπολογίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G627",
    gloss: "defence",
  },
  ἀπολογίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G627",
    gloss: "defence",
  },
  ἀπελούσασθε: {
    parse: "V-AMI-2P｜Verb, aorist, middle, indicative, second, plural",
    GN: "G628",
    gloss: "to wash off",
  },
  ἀπόλουσαι: {
    parse: "V-AMM-2S｜Verb, aorist, middle, imperative, second, singular",
    GN: "G628",
    gloss: "to wash off",
  },
  ἀπολυτρώσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G629",
    gloss: "redemption",
  },
  ἀπολύτρωσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G629",
    gloss: "redemption",
  },
  ἀπολύτρωσις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G629",
    gloss: "redemption",
  },
  ἀπέλυεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G630",
    gloss: "divorce",
  },
  ἀπελύθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G630",
    gloss: "divorce",
  },
  ἀπελύοντο: {
    parse: "V-IMI-3P｜Verb, imperfect, middle, indicative, third, plural",
    GN: "G630",
    gloss: "divorce",
  },
  ἀπέλυσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G630",
    gloss: "divorce",
  },
  ἀπέλυσε: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G630",
    gloss: "divorce",
  },
  ἀπέλυσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G630",
    gloss: "divorce",
  },
  ἀπολελυμένον: {
    parse:
      "V-RPP-ASM｜Verb, perfect, passive, Participle, accusative, singular, masculine",
    GN: "G630",
    gloss: "divorce",
  },
  ἀπολέλυσαι: {
    parse: "V-RPI-2S｜Verb, perfect, passive, indicative, second, singular",
    GN: "G630",
    gloss: "divorce",
  },
  Ἀπολελύσθαι: {
    parse: "V-RPN｜Verb, perfect, passive, Infinitive",
    GN: "G630",
    gloss: "divorce",
  },
  ἀπολύει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G630",
    gloss: "divorce",
  },
  ἀπολύειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G630",
    gloss: "divorce",
  },
  ἀπολύεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G630",
    gloss: "divorce",
  },
  ἀπολύετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G630",
    gloss: "divorce",
  },
  ἀπολυθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G630",
    gloss: "divorce",
  },
  Ἀπολυθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G630",
    gloss: "divorce",
  },
  ἀπολυθήσεσθε: {
    parse: "V-FPI-2P｜Verb, future, passive, indicative, second, plural",
    GN: "G630",
    gloss: "divorce",
  },
  ἀπολυθῆτε: {
    parse: "V-APS-2P｜Verb, aorist, passive, subjunctive, second, plural",
    GN: "G630",
    gloss: "divorce",
  },
  ἀπολῦσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G630",
    gloss: "divorce",
  },
  ἀπολῦσαί: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G630",
    gloss: "divorce",
  },
  ἀπολύσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G630",
    gloss: "divorce",
  },
  ἀπολύσασα: {
    parse:
      "V-AAP-NSF｜Verb, aorist, active, Participle, nominative, singular, feminine",
    GN: "G630",
    gloss: "divorce",
  },
  ἀπολύσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G630",
    gloss: "divorce",
  },
  ἀπολύσῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G630",
    gloss: "divorce",
  },
  ἀπόλυσον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G630",
    gloss: "divorce",
  },
  Ἀπόλυσον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G630",
    gloss: "divorce",
  },
  ἀπολύσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G630",
    gloss: "divorce",
  },
  ἀπολύων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G630",
    gloss: "divorce",
  },
  ἀπομασσόμεθα: {
    parse: "V-PMI-1P｜Verb, present, middle, indicative, first, plural",
    GN: "G631",
    gloss: "to wipe off",
  },
  ἀπονέμοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G632",
    gloss: "to render as due",
  },
  ἀπενίψατο: {
    parse: "V-AMI-3S｜Verb, aorist, middle, indicative, third, singular",
    GN: "G633",
    gloss: "to wash off",
  },
  ἀπέπεσαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G634",
    gloss: "to fall from",
  },
  ἀπεπλανήθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G635",
    gloss: "to mislead",
  },
  ἀποπλανᾶν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G635",
    gloss: "to mislead",
  },
  ἀπέπλευσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G636",
    gloss: "to set sail",
  },
  ἀποπλεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G636",
    gloss: "to set sail",
  },
  ἀποπλεύσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G636",
    gloss: "to set sail",
  },
  ἀπεπνίγη: {
    parse: "V-2API-3S｜Verb, 2nd aorist, passive, indicative, third, singular",
    GN: "G638",
    gloss: "to choke",
  },
  ἀπέπνιξαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G638",
    gloss: "to choke",
  },
  ἀπορεῖσθαι: {
    parse: "V-PMN｜Verb, present, middle, Infinitive",
    GN: "G639",
    gloss: "be perplexed",
  },
  ἀποροῦμαι: {
    parse: "V-PMI-1S｜Verb, present, middle, indicative, first, singular",
    GN: "G639",
    gloss: "be perplexed",
  },
  ἀπορούμενοι: {
    parse:
      "V-PMP-NPM｜Verb, present, middle, Participle, nominative, plural, masculine",
    GN: "G639",
    gloss: "be perplexed",
  },
  ἀπορούμενος: {
    parse:
      "V-PMP-NSM｜Verb, present, middle, Participle, nominative, singular, masculine",
    GN: "G639",
    gloss: "be perplexed",
  },
  ἠπόρει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G639",
    gloss: "be perplexed",
  },
  ἀπορίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G640",
    gloss: "perplexity",
  },
  ἀπορίψαντας: {
    parse:
      "V-AAP-APM｜Verb, aorist, active, Participle, accusative, plural, masculine",
    GN: "G641",
    gloss: "to throw off",
  },
  ἀπορφανισθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G642",
    gloss: "to orphan",
  },
  ἐπισκευασάμενοι: {
    parse:
      "V-ADP-NPM｜Verb, aorist, Middle deponent, Participle, nominative, plural, masculine",
    GN: "G643",
    gloss: "to pack up",
  },
  ἀποσκίασμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G644",
    gloss: "shadow",
  },
  ἀπέσπασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G645",
    gloss: "to draw away",
  },
  ἀπεσπάσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G645",
    gloss: "to draw away",
  },
  ἀπησπασάμεθα: {
    parse: "V-AMI-1P｜Verb, aorist, middle, indicative, first, plural",
    GN: "G645",
    gloss: "to draw away",
  },
  ἀποσπᾶν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G645",
    gloss: "to draw away",
  },
  ἀποσπασθέντας: {
    parse:
      "V-APP-APM｜Verb, aorist, passive, Participle, accusative, plural, masculine",
    GN: "G645",
    gloss: "to draw away",
  },
  ἀποστασία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G646",
    gloss: "apostasy",
  },
  ἀποστασίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G646",
    gloss: "apostasy",
  },
  ἀποστάσιον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G647",
    gloss: "divorce",
  },
  ἀποστασίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G647",
    gloss: "divorce",
  },
  ἀπεστέγασαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G648",
    gloss: "to unroof",
  },
  ἀπεστάλη: {
    parse: "V-2API-3S｜Verb, 2nd aorist, passive, indicative, third, singular",
    GN: "G649",
    gloss: "to send",
  },
  ἀπεστάλην: {
    parse: "V-2API-1S｜Verb, 2nd aorist, passive, indicative, first, singular",
    GN: "G649",
    gloss: "to send",
  },
  ἀπέσταλκα: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G649",
    gloss: "to send",
  },
  ἀπεστάλκαμεν: {
    parse: "V-RAI-1P｜Verb, perfect, active, indicative, first, plural",
    GN: "G649",
    gloss: "to send",
  },
  Ἀπέσταλκαν: {
    parse: "V-RAI-3P｜Verb, perfect, active, indicative, third, plural",
    GN: "G649",
    gloss: "to send",
  },
  ἀπεστάλκατε: {
    parse: "V-RAI-2P｜Verb, perfect, active, indicative, second, plural",
    GN: "G649",
    gloss: "to send",
  },
  ἀπέσταλκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G649",
    gloss: "to send",
  },
  ἀπέσταλκέν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G649",
    gloss: "to send",
  },
  ἀπεσταλμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G649",
    gloss: "to send",
  },
  ἀπεσταλμένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G649",
    gloss: "to send",
  },
  Ἀπεσταλμένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G649",
    gloss: "to send",
  },
  ἀπεσταλμένους: {
    parse:
      "V-RPP-APM｜Verb, perfect, passive, Participle, accusative, plural, masculine",
    GN: "G649",
    gloss: "to send",
  },
  ἀπέστειλα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G649",
    gloss: "to send",
  },
  ἀπέστειλαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G649",
    gloss: "to send",
  },
  ἀπέστειλας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G649",
    gloss: "to send",
  },
  ἀπέστειλεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G649",
    gloss: "to send",
  },
  ἀπέστειλέν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G649",
    gloss: "to send",
  },
  ἀποσταλέντι: {
    parse:
      "V-2APP-DSN｜Verb, 2nd aorist, passive, Participle, dative, singular, neuter",
    GN: "G649",
    gloss: "to send",
  },
  ἀποσταλῶσιν: {
    parse: "V-2APS-3P｜Verb, 2nd aorist, passive, subjunctive, third, plural",
    GN: "G649",
    gloss: "to send",
  },
  ἀποστεῖλαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G649",
    gloss: "to send",
  },
  ἀποστείλαντά: {
    parse:
      "V-AAP-ASM｜Verb, aorist, active, Participle, accusative, singular, masculine",
    GN: "G649",
    gloss: "to send",
  },
  ἀποστείλαντας: {
    parse:
      "V-AAP-APM｜Verb, aorist, active, Participle, accusative, plural, masculine",
    GN: "G649",
    gloss: "to send",
  },
  ἀποστείλαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G649",
    gloss: "to send",
  },
  ἀποστείλας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G649",
    gloss: "to send",
  },
  ἀποστείλῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G649",
    gloss: "to send",
  },
  ἀπόστειλον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G649",
    gloss: "to send",
  },
  Ἀπόστειλον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G649",
    gloss: "to send",
  },
  ἀποστείλω: {
    parse: "V-AAS-1S｜Verb, aorist, active, subjunctive, first, singular",
    GN: "G649",
    gloss: "to send",
  },
  ἀποστελεῖ: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G649",
    gloss: "to send",
  },
  ἀποστέλλει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G649",
    gloss: "to send",
  },
  ἀποστέλλειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G649",
    gloss: "to send",
  },
  ἀποστέλλῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G649",
    gloss: "to send",
  },
  ἀποστελλόμενα: {
    parse:
      "V-PPP-NPN｜Verb, present, passive, Participle, nominative, plural, neuter",
    GN: "G649",
    gloss: "to send",
  },
  ἀποστέλλουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G649",
    gloss: "to send",
  },
  ἀποστέλλω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G649",
    gloss: "to send",
  },
  Ἀποστελῶ: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G649",
    gloss: "to send",
  },
  ἀπεστερημένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G650",
    gloss: "to defraud",
  },
  ἀπεστερημένων: {
    parse:
      "V-RPP-GPM｜Verb, perfect, passive, Participle, genitive, plural, masculine",
    GN: "G650",
    gloss: "to defraud",
  },
  ἀποστερεῖσθε: {
    parse: "V-PPI-2P｜Verb, present, passive, indicative, second, plural",
    GN: "G650",
    gloss: "to defraud",
  },
  ἀποστερεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G650",
    gloss: "to defraud",
  },
  ἀποστερήσῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G650",
    gloss: "to defraud",
  },
  ἀποστολὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G651",
    gloss: "apostleship",
  },
  ἀποστολῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G651",
    gloss: "apostleship",
  },
  ἀπόστολοι: {
    parse: "N-VPM｜Noun, Vocative or nominative, plural, masculine",
    GN: "G652",
    gloss: "apostle",
  },
  ἀποστόλοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G652",
    gloss: "apostle",
  },
  ἀπόστολον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G652",
    gloss: "apostle",
  },
  ἀπόστολος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G652",
    gloss: "apostle",
  },
  ἀποστόλου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G652",
    gloss: "apostle",
  },
  ἀποστόλους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G652",
    gloss: "apostle",
  },
  ἀποστόλων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G652",
    gloss: "apostle",
  },
  ἀποστοματίζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G653",
    gloss: "to interrogate",
  },
  ἀπεστράφησάν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G654",
    gloss: "to turn away",
  },
  ἀποστραφῇς: {
    parse:
      "V-2APS-2S｜Verb, 2nd aorist, passive, subjunctive, second, singular",
    GN: "G654",
    gloss: "to turn away",
  },
  ἀποστρέφειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G654",
    gloss: "to turn away",
  },
  ἀποστρεφόμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G654",
    gloss: "to turn away",
  },
  ἀποστρεφομένων: {
    parse:
      "V-PPP-GPM｜Verb, present, passive, Participle, genitive, plural, masculine",
    GN: "G654",
    gloss: "to turn away",
  },
  ἀποστρέφοντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G654",
    gloss: "to turn away",
  },
  ἀποστρέψει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G654",
    gloss: "to turn away",
  },
  Ἀπόστρεψον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G654",
    gloss: "to turn away",
  },
  ἀποστρέψουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G654",
    gloss: "to turn away",
  },
  ἀποστυγοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G655",
    gloss: "to abhor",
  },
  ἀποσυνάγωγοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G656",
    gloss: "excommunicated",
  },
  ἀποσυνάγωγος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G656",
    gloss: "excommunicated",
  },
  ἀποσυναγώγους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G656",
    gloss: "excommunicated",
  },
  ἀποταξάμενος: {
    parse:
      "V-AMP-NSM｜Verb, aorist, middle, Participle, nominative, singular, masculine",
    GN: "G657",
    gloss: "to leave",
  },
  ἀποτάξασθαι: {
    parse: "V-AMN｜Verb, aorist, middle, Infinitive",
    GN: "G657",
    gloss: "to leave",
  },
  ἀποτάσσεται: {
    parse: "V-PMI-3S｜Verb, present, middle, indicative, third, singular",
    GN: "G657",
    gloss: "to leave",
  },
  ἀποτελεσθεῖσα: {
    parse:
      "V-APP-NSF｜Verb, aorist, passive, Participle, nominative, singular, feminine",
    GN: "G658",
    gloss: "to complete",
  },
  ἀποτελῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G658",
    gloss: "to complete",
  },
  ἀπέθεντο: {
    parse: "V-2AMI-3P｜Verb, 2nd aorist, middle, indicative, third, plural",
    GN: "G659",
    gloss: "to put aside",
  },
  ἀπέθετο: {
    parse: "V-2AMI-3S｜Verb, 2nd aorist, middle, indicative, third, singular",
    GN: "G659",
    gloss: "to put aside",
  },
  ἀποθέμενοι: {
    parse:
      "V-2AMP-NPM｜Verb, 2nd aorist, middle, Participle, nominative, plural, masculine",
    GN: "G659",
    gloss: "to put aside",
  },
  Ἀποθέμενοι: {
    parse:
      "V-2AMP-NPM｜Verb, 2nd aorist, middle, Participle, nominative, plural, masculine",
    GN: "G659",
    gloss: "to put aside",
  },
  ἀποθέσθαι: {
    parse: "V-2AMN｜Verb, 2nd aorist, middle, Infinitive",
    GN: "G659",
    gloss: "to put aside",
  },
  ἀπόθεσθε: {
    parse: "V-2AMM-2P｜Verb, 2nd aorist, middle, imperative, second, plural",
    GN: "G659",
    gloss: "to put aside",
  },
  ἀποθώμεθα: {
    parse: "V-2AMS-1P｜Verb, 2nd aorist, middle, subjunctive, first, plural",
    GN: "G659",
    gloss: "to put aside",
  },
  ἀποτινάξας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G660",
    gloss: "to shake off",
  },
  ἀποτινάσσετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G660",
    gloss: "to shake off",
  },
  ἀποτίσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G661",
    gloss: "to repay",
  },
  ἀποτολμᾷ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G662",
    gloss: "be bold",
  },
  ἀποτομία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G663",
    gloss: "severity",
  },
  ἀποτομίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G663",
    gloss: "severity",
  },
  ἀποτόμως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G664",
    gloss: "severely",
  },
  ἀποτρέπου: {
    parse: "V-PMM-2S｜Verb, present, middle, imperative, second, singular",
    GN: "G665",
    gloss: "to avoid",
  },
  ἀπουσίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G666",
    gloss: "absence",
  },
  ἀπενεγκεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G667",
    gloss: "to carry off",
  },
  ἀπενεχθῆναι: {
    parse: "V-2APN｜Verb, 2nd aorist, passive, Infinitive",
    GN: "G667",
    gloss: "to carry off",
  },
  ἀπήνεγκαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G667",
    gloss: "to carry off",
  },
  ἀπήνεγκέν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G667",
    gloss: "to carry off",
  },
  ἀποφέρεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G667",
    gloss: "to carry off",
  },
  ἀποφεύγοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G668",
    gloss: "to escape",
  },
  ἀποφυγόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G668",
    gloss: "to escape",
  },
  ἀπεφθέγξατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G669",
    gloss: "to declare",
  },
  ἀποφθέγγεσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G669",
    gloss: "to declare",
  },
  ἀποφθέγγομαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G669",
    gloss: "to declare",
  },
  ἀποφορτιζόμενον: {
    parse:
      "V-PNP-NSN｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, neuter",
    GN: "G670",
    gloss: "to unload",
  },
  ἀποχρήσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G671",
    gloss: "using up",
  },
  ἀποχωρεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G672",
    gloss: "to leave",
  },
  ἀποχωρεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G672",
    gloss: "to leave",
  },
  ἀποχωρήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G672",
    gloss: "to leave",
  },
  ἀπεχωρίσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G673",
    gloss: "to separate from",
  },
  ἀποχωρισθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G673",
    gloss: "to separate from",
  },
  ἀποψυχόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G674",
    gloss: "to faint",
  },
  Ἀππίου: {
    parse: "N-GSM-L｜Noun, genitive, singular, masculine, Location",
    GN: "G675",
    gloss: " of Appius",
  },
  ἀπρόσιτον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G676",
    gloss: "unapproachable",
  },
  ἀπρόσκοποι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G677",
    gloss: "not giving offence",
  },
  ἀπρόσκοπον: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G677",
    gloss: "not giving offence",
  },
  ἀπροσωπολήμπτως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G678",
    gloss: "impartially",
  },
  ἀπταίστους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G679",
    gloss: "without falling",
  },
  ἅπτει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G681",
    gloss: "to touch",
  },
  ἅπτεσθαι: {
    parse: "V-PMN｜Verb, present, middle, Infinitive",
    GN: "G681",
    gloss: "to touch",
  },
  ἅπτεσθε: {
    parse: "V-PMM-2P｜Verb, present, middle, imperative, second, plural",
    GN: "G681",
    gloss: "to touch",
  },
  ἅπτεται: {
    parse: "V-PMI-3S｜Verb, present, middle, indicative, third, singular",
    GN: "G681",
    gloss: "to touch",
  },
  ἅπτηται: {
    parse: "V-PMS-3S｜Verb, present, middle, subjunctive, third, singular",
    GN: "G681",
    gloss: "to touch",
  },
  ἅπτου: {
    parse: "V-PMM-2S｜Verb, present, middle, imperative, second, singular",
    GN: "G681",
    gloss: "to touch",
  },
  ἁψάμενος: {
    parse:
      "V-AMP-NSM｜Verb, aorist, middle, Participle, nominative, singular, masculine",
    GN: "G681",
    gloss: "to touch",
  },
  ἁψάμενός: {
    parse:
      "V-AMP-NSM｜Verb, aorist, middle, Participle, nominative, singular, masculine",
    GN: "G681",
    gloss: "to touch",
  },
  ἅψαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G681",
    gloss: "to touch",
  },
  ἅψας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G681",
    gloss: "to touch",
  },
  ἅψῃ: {
    parse: "V-AMS-2S｜Verb, aorist, middle, subjunctive, second, singular",
    GN: "G681",
    gloss: "to touch",
  },
  ἅψηται: {
    parse: "V-AMS-3S｜Verb, aorist, middle, subjunctive, third, singular",
    GN: "G681",
    gloss: "to touch",
  },
  ἅψωμαι: {
    parse: "V-AMS-1S｜Verb, aorist, middle, subjunctive, first, singular",
    GN: "G681",
    gloss: "to touch",
  },
  ἅψωνται: {
    parse: "V-AMS-3P｜Verb, aorist, middle, subjunctive, third, plural",
    GN: "G681",
    gloss: "to touch",
  },
  ἥψαντο: {
    parse: "V-ADI-3P｜Verb, aorist, Middle deponent, indicative, third, plural",
    GN: "G681",
    gloss: "to touch",
  },
  ἥψατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G681",
    gloss: "to touch",
  },
  Ἥψατό: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G681",
    gloss: "to touch",
  },
  Ἀπφίᾳ: {
    parse: "N-DSF-P｜Noun, dative, singular, feminine, Person",
    GN: "G682",
    gloss: "Apphia",
  },
  ἀπωθεῖσθε: {
    parse:
      "V-PNI-2P｜Verb, present, Middle or Passive deponent, indicative, second, plural",
    GN: "G683",
    gloss: "to reject",
  },
  ἀπωσάμενοι: {
    parse:
      "V-ADP-NPM｜Verb, aorist, Middle deponent, Participle, nominative, plural, masculine",
    GN: "G683",
    gloss: "to reject",
  },
  ἀπώσαντο: {
    parse: "V-ADI-3P｜Verb, aorist, Middle deponent, indicative, third, plural",
    GN: "G683",
    gloss: "to reject",
  },
  ἀπώσατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G683",
    gloss: "to reject",
  },
  ἀπώλεια: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G684",
    gloss: "destruction",
  },
  ἀπώλειαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G684",
    gloss: "destruction",
  },
  ἀπωλείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G684",
    gloss: "destruction",
  },
  ἀρᾶς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G685",
    gloss: "curse",
  },
  ἄρα: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G686",
    gloss: "therefore",
  },
  Ἄρα: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G686",
    gloss: "therefore",
  },
  ἆρα: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G687",
    gloss: "no?",
  },
  Ἆρά: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G687",
    gloss: "no?",
  },
  Ἀραβίᾳ: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G688",
    gloss: "Arabia",
  },
  Ἀραβίαν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G688",
    gloss: "Arabia",
  },
  Ἀράμ: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G689",
    gloss: "Ram",
  },
  Ἀρὰμ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G689",
    gloss: "Ram",
  },
  Ἄραβες: {
    parse: "N-NPM-LG｜Noun, nominative, plural, masculine, Location Gentilic",
    GN: "G0690",
  },
  ἀργεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G691",
    gloss: "be idle",
  },
  ἀργαί: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G692",
    gloss: "idle",
  },
  ἀργαὶ: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G692",
    gloss: "idle",
  },
  ἀργή: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G692",
    gloss: "idle",
  },
  ἀργοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G692",
    gloss: "idle",
  },
  ἀργὸν: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G692",
    gloss: "idle",
  },
  ἀργούς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G692",
    gloss: "idle",
  },
  ἀργοὺς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G692",
    gloss: "idle",
  },
  ἀργυρᾶ: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G693",
    gloss: "silver",
  },
  ἀργυροῦς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G693",
    gloss: "silver",
  },
  ἀργύρια: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G694",
    gloss: "money",
  },
  ἀργύριά: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G694",
    gloss: "money",
  },
  ἀργύριον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G694",
    gloss: "money",
  },
  Ἀργύριον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G694",
    gloss: "money",
  },
  ἀργύριόν: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G694",
    gloss: "money",
  },
  ἀργυρίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G694",
    gloss: "money",
  },
  ἀργυρίῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G694",
    gloss: "money",
  },
  ἀργυροκόπος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G695",
    gloss: "silversmith",
  },
  ἄργυρον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G696",
    gloss: "silver",
  },
  ἄργυρος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G696",
    gloss: "silver",
  },
  ἀργύρου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G696",
    gloss: "silver",
  },
  ἀργύρῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G696",
    gloss: "silver",
  },
  Ἄρειον: {
    parse: "N-ASM-L｜Noun, accusative, singular, masculine, Location",
    GN: "G697",
    gloss: "Areopagus",
  },
  Ἀρείου: {
    parse: "N-GSM-L｜Noun, genitive, singular, masculine, Location",
    GN: "G697",
    gloss: "Areopagus",
  },
  Πάγον: {
    parse: "N-ASM-L｜Noun, accusative, singular, masculine, Location",
    GN: "G697",
    gloss: "Areopagus",
  },
  Πάγου: {
    parse: "N-GSM-L｜Noun, genitive, singular, masculine, Location",
    GN: "G697",
    gloss: "Areopagus",
  },
  Ἀρεοπαγίτης: {
    parse: "N-NSM-LG｜Noun, nominative, singular, masculine, Location Gentilic",
    GN: "G698",
    gloss: "Areopagite",
  },
  ἀρεσκείαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G699",
    gloss: "pleasing",
  },
  ἀρέσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G700",
    gloss: "to please",
  },
  ἀρέσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G700",
    gloss: "to please",
  },
  ἀρέσκειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G700",
    gloss: "to please",
  },
  ἀρεσκέτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G700",
    gloss: "to please",
  },
  ἀρέσκοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G700",
    gloss: "to please",
  },
  ἀρεσκόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G700",
    gloss: "to please",
  },
  ἀρέσκω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G700",
    gloss: "to please",
  },
  ἤρεσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G700",
    gloss: "to please",
  },
  ἤρεσκον: {
    parse: "V-IAI-1S｜Verb, imperfect, active, indicative, first, singular",
    GN: "G700",
    gloss: "to please",
  },
  ἀρεστὰ: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G701",
    gloss: "pleasing",
  },
  ἀρεστόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G701",
    gloss: "pleasing",
  },
  Ἁρέτα: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G702",
    gloss: "Aretas",
  },
  ἀρετὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G703",
    gloss: "virtue",
  },
  ἀρετῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G703",
    gloss: "virtue",
  },
  ἀρετὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G703",
    gloss: "virtue",
  },
  ἀρετήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G703",
    gloss: "virtue",
  },
  ἄρνας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G704",
    gloss: "lamb",
  },
  ἀριθμῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G705",
    gloss: "to number",
  },
  ἠριθμημέναι: {
    parse:
      "V-RPP-NPF｜Verb, perfect, passive, Participle, nominative, plural, feminine",
    GN: "G705",
    gloss: "to number",
  },
  ἠρίθμηνται: {
    parse: "V-RPI-3P｜Verb, perfect, passive, indicative, third, plural",
    GN: "G705",
    gloss: "to number",
  },
  ἀριθμὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G706",
    gloss: "number",
  },
  ἀριθμὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G706",
    gloss: "number",
  },
  ἀριθμοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G706",
    gloss: "number",
  },
  ἀριθμῷ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G706",
    gloss: "number",
  },
  Ἁριμαθαίας: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G707",
    gloss: "Arimathea",
  },
  Ἀρίσταρχον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G708",
    gloss: "Aristarchus",
  },
  Ἀρίσταρχος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G708",
    gloss: "Aristarchus",
  },
  Ἀριστάρχου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G708",
    gloss: "Aristarchus",
  },
  ἀριστήσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G709",
    gloss: "to eat early meal",
  },
  ἀριστήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G709",
    gloss: "to eat early meal",
  },
  ἠρίστησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G709",
    gloss: "to eat early meal",
  },
  ἀριστερά: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G710",
    gloss: "left",
  },
  ἀριστερῶν: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G710",
    gloss: "left",
  },
  Ἀριστοβούλου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G711",
    gloss: "Aristobulus",
  },
  ἄριστον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G712",
    gloss: "early meal",
  },
  ἄριστόν: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G712",
    gloss: "early meal",
  },
  ἀρίστου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G712",
    gloss: "early meal",
  },
  ἀρκετὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G713",
    gloss: "sufficient",
  },
  ἀρκετὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G713",
    gloss: "sufficient",
  },
  ἀρκεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G714",
    gloss: "be sufficient",
  },
  Ἀρκεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G714",
    gloss: "be sufficient",
  },
  ἀρκεῖσθε: {
    parse: "V-PPM-2P｜Verb, present, passive, imperative, second, plural",
    GN: "G714",
    gloss: "be sufficient",
  },
  ἀρκέσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G714",
    gloss: "be sufficient",
  },
  ἀρκεσθησόμεθα: {
    parse: "V-FPI-1P｜Verb, future, passive, indicative, first, plural",
    GN: "G714",
    gloss: "be sufficient",
  },
  ἀρκούμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G714",
    gloss: "be sufficient",
  },
  ἀρκούμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G714",
    gloss: "be sufficient",
  },
  ἀρκοῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G714",
    gloss: "be sufficient",
  },
  ἄρκου: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G715",
    gloss: "bear",
  },
  ἅρμα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G716",
    gloss: "chariot",
  },
  ἅρματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G716",
    gloss: "chariot",
  },
  ἅρματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G716",
    gloss: "chariot",
  },
  ἁρμάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G716",
    gloss: "chariot",
  },
  Ἁρμαγεδών: {
    parse: "N-ASN-L｜Noun, accusative, singular, neuter, Location",
    GN: "G717",
    gloss: "Armageddon",
  },
  ἡρμοσάμην: {
    parse: "V-AMI-1S｜Verb, aorist, middle, indicative, first, singular",
    GN: "G718",
    gloss: "to betroth",
  },
  ἁρμῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G719",
    gloss: "joint",
  },
  ἀρνεῖσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G720",
    gloss: "to deny",
  },
  ἀρνησάμενοι: {
    parse:
      "V-ADP-NPM｜Verb, aorist, Middle deponent, Participle, nominative, plural, masculine",
    GN: "G720",
    gloss: "to deny",
  },
  ἀρνησάμενός: {
    parse:
      "V-ADP-NSM｜Verb, aorist, Middle deponent, Participle, nominative, singular, masculine",
    GN: "G720",
    gloss: "to deny",
  },
  ἀρνήσασθαι: {
    parse: "V-ADN｜Verb, aorist, Middle deponent, Infinitive",
    GN: "G720",
    gloss: "to deny",
  },
  ἀρνησάσθω: {
    parse:
      "V-ADM-3S｜Verb, aorist, Middle deponent, imperative, third, singular",
    GN: "G720",
    gloss: "to deny",
  },
  ἀρνήσεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G720",
    gloss: "to deny",
  },
  ἀρνήσῃ: {
    parse:
      "V-FDI-2S｜Verb, future, Middle deponent, indicative, second, singular",
    GN: "G720",
    gloss: "to deny",
  },
  ἀρνήσηταί: {
    parse:
      "V-ADS-3S｜Verb, aorist, Middle deponent, subjunctive, third, singular",
    GN: "G720",
    gloss: "to deny",
  },
  ἀρνήσομαι: {
    parse:
      "V-FDI-1S｜Verb, future, Middle deponent, indicative, first, singular",
    GN: "G720",
    gloss: "to deny",
  },
  ἀρνησόμεθα: {
    parse: "V-FDI-1P｜Verb, future, Middle deponent, indicative, first, plural",
    GN: "G720",
    gloss: "to deny",
  },
  ἀρνούμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G720",
    gloss: "to deny",
  },
  ἀρνούμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G720",
    gloss: "to deny",
  },
  ἀρνουμένων: {
    parse:
      "V-PNP-GPM｜Verb, present, Middle or Passive deponent, Participle, genitive, plural, masculine",
    GN: "G720",
    gloss: "to deny",
  },
  ἀρνοῦνται: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G720",
    gloss: "to deny",
  },
  ἠρνεῖτο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G720",
    gloss: "to deny",
  },
  ἠρνημένοι: {
    parse:
      "V-RNP-NPM｜Verb, perfect, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G720",
    gloss: "to deny",
  },
  ἠρνήσαντο: {
    parse: "V-ADI-3P｜Verb, aorist, Middle deponent, indicative, third, plural",
    GN: "G720",
    gloss: "to deny",
  },
  ἠρνήσασθε: {
    parse:
      "V-ADI-2P｜Verb, aorist, Middle deponent, indicative, second, plural",
    GN: "G720",
    gloss: "to deny",
  },
  ἠρνήσατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G720",
    gloss: "to deny",
  },
  ἠρνήσω: {
    parse:
      "V-ADI-2S｜Verb, aorist, Middle deponent, indicative, second, singular",
    GN: "G720",
    gloss: "to deny",
  },
  ἤρνηται: {
    parse:
      "V-RDI-3S｜Verb, perfect, Middle deponent, indicative, third, singular",
    GN: "G720",
    gloss: "to deny",
  },
  ἀρνία: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G721",
    gloss: "lamb",
  },
  ἀρνίον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G721",
    gloss: "lamb",
  },
  ἀρνίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G721",
    gloss: "lamb",
  },
  ἀρνίῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G721",
    gloss: "lamb",
  },
  ἀροτριᾶν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G722",
    gloss: "to plow",
  },
  ἀροτριῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G722",
    gloss: "to plow",
  },
  ἀροτριῶντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G722",
    gloss: "to plow",
  },
  ἄροτρον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G723",
    gloss: "plow",
  },
  ἁρπαγὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G724",
    gloss: "plunder",
  },
  ἁρπαγῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G724",
    gloss: "plunder",
  },
  ἁρπαγμὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G725",
    gloss: "something to grasp",
  },
  ἁρπαγέντα: {
    parse:
      "V-2APP-ASM｜Verb, 2nd aorist, passive, Participle, accusative, singular, masculine",
    GN: "G726",
    gloss: "to seize",
  },
  ἁρπαγησόμεθα: {
    parse: "V-2FPI-1P｜Verb, 2nd future, passive, indicative, first, plural",
    GN: "G726",
    gloss: "to seize",
  },
  ἁρπάζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G726",
    gloss: "to seize",
  },
  ἁρπάζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G726",
    gloss: "to seize",
  },
  ἁρπάζοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G726",
    gloss: "to seize",
  },
  ἁρπάζουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G726",
    gloss: "to seize",
  },
  ἁρπάσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G726",
    gloss: "to seize",
  },
  ἁρπάσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G726",
    gloss: "to seize",
  },
  ἡρπάγη: {
    parse: "V-2API-3S｜Verb, 2nd aorist, passive, indicative, third, singular",
    GN: "G726",
    gloss: "to seize",
  },
  ἥρπασεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G726",
    gloss: "to seize",
  },
  ἡρπάσθη: {
    parse: "V-2API-3S｜Verb, 2nd aorist, passive, indicative, third, singular",
    GN: "G726",
    gloss: "to seize",
  },
  ἅρπαγες: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G727",
    gloss: "rapacious",
  },
  ἅρπαξ: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G727",
    gloss: "rapacious",
  },
  ἅρπαξιν: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G727",
    gloss: "rapacious",
  },
  ἀρραβὼν: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G728",
    gloss: "guarantee",
  },
  ἀρραβῶνα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G728",
    gloss: "guarantee",
  },
  ἄραφος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G729",
    gloss: "seamless",
  },
  ἄρσεν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G730",
    gloss: "male",
  },
  ἄρσενα: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G730",
    gloss: "male",
  },
  ἄρσενες: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G730",
    gloss: "male",
  },
  ἄρσεσιν: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G730",
    gloss: "male",
  },
  ἄρρητα: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G731",
    gloss: "inexpressible",
  },
  ἄρρωστοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G732",
    gloss: "ill",
  },
  ἀρρώστοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G732",
    gloss: "ill",
  },
  ἀρρώστους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G732",
    gloss: "ill",
  },
  ἀρσενοκοῖται: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G733",
    gloss: "sodomite",
  },
  ἀρσενοκοίταις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G733",
    gloss: "sodomite",
  },
  Ἀρτεμᾶν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G734",
    gloss: "Artemas",
  },
  Ἀρτέμιδος: {
    parse: "N-GSF-T｜Noun, genitive, singular, feminine, Title",
    GN: "G735",
    gloss: "Artemis",
  },
  Ἄρτεμις: {
    parse: "N-NSF-T｜Noun, nominative, singular, feminine, Title",
    GN: "G735",
    gloss: "Artemis",
  },
  ἀρτέμωνα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G736",
    gloss: "foresail",
  },
  ἄρτι: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G737",
    gloss: "now",
  },
  Ἄρτι: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G737",
    gloss: "now",
  },
  ἀρτιγέννητα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G738",
    gloss: "newborn",
  },
  ἄρτιος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G739",
    gloss: "competent",
  },
  ἄρτοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G740",
    gloss: "bread",
  },
  ἄρτοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G740",
    gloss: "bread",
  },
  ἄρτον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G740",
    gloss: "bread",
  },
  Ἄρτον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G740",
    gloss: "bread",
  },
  ἄρτος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G740",
    gloss: "bread",
  },
  ἄρτου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G740",
    gloss: "bread",
  },
  ἄρτους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G740",
    gloss: "bread",
  },
  Ἄρτους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G740",
    gloss: "bread",
  },
  ἄρτῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G740",
    gloss: "bread",
  },
  ἄρτων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G740",
    gloss: "bread",
  },
  ἀρτυθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G741",
    gloss: "to season",
  },
  ἀρτύσετε: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G741",
    gloss: "to season",
  },
  ἠρτυμένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G741",
    gloss: "to season",
  },
  Ἀρφαξὰδ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G742",
    gloss: "Arphaxad",
  },
  ἀρχάγγελος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G743",
    gloss: "archangel",
  },
  ἀρχαγγέλου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G743",
    gloss: "archangel",
  },
  ἀρχαῖα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G744",
    gloss: "ancient",
  },
  ἀρχαίοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G744",
    gloss: "ancient",
  },
  ἀρχαῖος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G744",
    gloss: "ancient",
  },
  ἀρχαίου: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G744",
    gloss: "ancient",
  },
  ἀρχαίῳ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G744",
    gloss: "ancient",
  },
  ἀρχαίων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G744",
    gloss: "ancient",
  },
  ἀρχαῖον: {
    parse: "A-GPM｜Adjective, accusative, singular, masculine",
    GN: "G744",
    gloss: "ancient",
  },
  Ἀρχέλαος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G745",
    gloss: "Archelaus",
  },
  ἀρχαὶ: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G746",
    gloss: "beginning",
  },
  ἀρχαῖς: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G746",
    gloss: "beginning",
  },
  ἀρχάς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G746",
    gloss: "beginning",
  },
  ἀρχὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G746",
    gloss: "beginning",
  },
  ἀρχῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G746",
    gloss: "beginning",
  },
  ἀρχή: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G746",
    gloss: "beginning",
  },
  ἀρχὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G746",
    gloss: "beginning",
  },
  Ἀρχὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G746",
    gloss: "beginning",
  },
  ἀρχὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G746",
    gloss: "beginning",
  },
  ἀρχῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G746",
    gloss: "beginning",
  },
  ἀρχηγὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G747",
    gloss: "founder",
  },
  ἀρχιερατικοῦ: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G748",
    gloss: "high-priestly",
  },
  ἀρχιερέα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G749",
    gloss: "high-priest",
  },
  ἀρχιερεῖ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G749",
    gloss: "high-priest",
  },
  ἀρχιερεῖς: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G749",
    gloss: "high-priest",
  },
  ἀρχιερεύς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G749",
    gloss: "high-priest",
  },
  ἀρχιερεὺς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G749",
    gloss: "high-priest",
  },
  ἀρχιερεῦσιν: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G749",
    gloss: "high-priest",
  },
  ἀρχιερέων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G749",
    gloss: "high-priest",
  },
  ἀρχιερέως: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G749",
    gloss: "high-priest",
  },
  ἀρχιποίμενος: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G750",
    gloss: "chief shepherd",
  },
  Ἀρχίππῳ: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G751",
    gloss: "Archippus",
  },
  ἀρχισυνάγωγοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G752",
    gloss: "synagogue leader",
  },
  ἀρχισυνάγωγον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G752",
    gloss: "synagogue leader",
  },
  ἀρχισυνάγωγος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G752",
    gloss: "synagogue leader",
  },
  ἀρχισυναγώγου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G752",
    gloss: "synagogue leader",
  },
  ἀρχισυναγώγῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G752",
    gloss: "synagogue leader",
  },
  ἀρχισυναγώγων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G752",
    gloss: "synagogue leader",
  },
  ἀρχιτέκτων: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G753",
    gloss: "master-builder",
  },
  ἀρχιτελώνης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G754",
    gloss: "chief tax collector",
  },
  ἀρχιτρίκλινος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G755",
    gloss: "head waiter",
  },
  ἀρχιτρικλίνῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G755",
    gloss: "head waiter",
  },
  ἀρξάμενοι: {
    parse:
      "V-AMP-NPM｜Verb, aorist, middle, Participle, nominative, plural, masculine",
    GN: "G757",
    gloss: "be first",
  },
  ἀρξάμενος: {
    parse:
      "V-AMP-NSM｜Verb, aorist, middle, Participle, nominative, singular, masculine",
    GN: "G757",
    gloss: "be first",
  },
  ἀρξαμένου: {
    parse:
      "V-AMP-GSM｜Verb, aorist, middle, Participle, genitive, singular, masculine",
    GN: "G757",
    gloss: "be first",
  },
  ἄρξασθαι: {
    parse: "V-AMN｜Verb, aorist, middle, Infinitive",
    GN: "G757",
    gloss: "be first",
  },
  ἄρξασθαί: {
    parse: "V-AMN｜Verb, aorist, middle, Infinitive",
    GN: "G757",
    gloss: "be first",
  },
  ἄρξεσθε: {
    parse: "V-FMI-2P｜Verb, future, middle, indicative, second, plural",
    GN: "G757",
    gloss: "be first",
  },
  ἄρξῃ: {
    parse: "V-FMI-2S｜Verb, future, middle, indicative, second, singular",
    GN: "G757",
    gloss: "be first",
  },
  ἄρξησθε: {
    parse: "V-AMS-2P｜Verb, aorist, middle, subjunctive, second, plural",
    GN: "G757",
    gloss: "be first",
  },
  ἄρξηται: {
    parse: "V-AMS-3S｜Verb, aorist, middle, subjunctive, third, singular",
    GN: "G757",
    gloss: "be first",
  },
  ἄρξονται: {
    parse: "V-FMI-3P｜Verb, future, middle, indicative, third, plural",
    GN: "G757",
    gloss: "be first",
  },
  ἄρξωνται: {
    parse: "V-AMS-3P｜Verb, aorist, middle, subjunctive, third, plural",
    GN: "G757",
    gloss: "be first",
  },
  ἄρχειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G757",
    gloss: "be first",
  },
  Ἀρχόμεθα: {
    parse: "V-PMI-1P｜Verb, present, middle, indicative, first, plural",
    GN: "G757",
    gloss: "be first",
  },
  ἀρχόμενος: {
    parse:
      "V-PMP-NSM｜Verb, present, middle, Participle, nominative, singular, masculine",
    GN: "G757",
    gloss: "be first",
  },
  ἀρχομένων: {
    parse:
      "V-PMP-GPN｜Verb, present, middle, Participle, genitive, plural, neuter",
    GN: "G757",
    gloss: "be first",
  },
  ἤρξαντο: {
    parse: "V-ADI-3P｜Verb, aorist, middle, indicative, third, plural",
    GN: "G757",
    gloss: "be first",
  },
  ἤρξαντό: {
    parse: "V-ADI-3P｜Verb, aorist, middle, indicative, third, plural",
    GN: "G757",
    gloss: "be first",
  },
  ἤρξατο: {
    parse: "V-ADI-3S｜Verb, aorist, middle, indicative, third, singular",
    GN: "G757",
    gloss: "be first",
  },
  Ἤρξατο: {
    parse: "V-ADI-3S｜Verb, aorist, middle, indicative, third, singular",
    GN: "G757",
    gloss: "be first",
  },
  ἄρχοντα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G758",
    gloss: "ruler",
  },
  Ἄρχοντα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G758",
    gloss: "ruler",
  },
  ἄρχοντας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G758",
    gloss: "ruler",
  },
  ἄρχοντες: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G758",
    gloss: "ruler",
  },
  Ἄρχοντες: {
    parse: "N-VPM｜Noun, Vocative or nominative, plural, masculine",
    GN: "G758",
    gloss: "ruler",
  },
  ἄρχοντι: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G758",
    gloss: "ruler",
  },
  ἄρχοντος: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G758",
    gloss: "ruler",
  },
  ἀρχόντων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G758",
    gloss: "ruler",
  },
  ἄρχουσιν: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G758",
    gloss: "ruler",
  },
  ἄρχων: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G758",
    gloss: "ruler",
  },
  ἀρώματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G759",
    gloss: "spices",
  },
  ἀρωμάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G759",
    gloss: "spices",
  },
  Ἀσάφ: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G760",
    gloss: "Asaph",
  },
  Ἀσὰφ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G760",
    gloss: "Asaph",
  },
  ἀσάλευτον: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G761",
    gloss: "unshakable",
  },
  ἀσάλευτος: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G761",
    gloss: "unshakable",
  },
  ἄσβεστον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G762",
    gloss: "unquenchable",
  },
  ἀσβέστῳ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G762",
    gloss: "unquenchable",
  },
  ἀσέβειαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G763",
    gloss: "ungodlinessness",
  },
  ἀσεβείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G763",
    gloss: "ungodlinessness",
  },
  ἀσεβειῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G763",
    gloss: "ungodlinessness",
  },
  ἠσέβησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G764",
    gloss: "be ungodly",
  },
  ἀσεβεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G765",
    gloss: "ungodly",
  },
  ἀσεβεῖς: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G765",
    gloss: "ungodly",
  },
  ἀσεβέσι: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G765",
    gloss: "ungodly",
  },
  ἀσεβέσιν: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G765",
    gloss: "ungodly",
  },
  ἀσεβῆ: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G765",
    gloss: "ungodly",
  },
  ἀσεβὴς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G765",
    gloss: "ungodly",
  },
  ἀσεβῶν: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G765",
    gloss: "ungodly",
  },
  ἀσελγείᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G766",
    gloss: "debauchery",
  },
  ἀσέλγεια: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G766",
    gloss: "debauchery",
  },
  ἀσελγείαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G766",
    gloss: "debauchery",
  },
  ἀσέλγειαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G766",
    gloss: "debauchery",
  },
  ἀσήμου: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G767",
    gloss: "insignificant",
  },
  Ἀσήρ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G768",
    gloss: "Asher",
  },
  Ἀσὴρ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G768",
    gloss: "Asher",
  },
  ἀσθενείᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G769",
    gloss: "ill",
  },
  ἀσθένεια: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G769",
    gloss: "ill",
  },
  ἀσθενείαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G769",
    gloss: "ill",
  },
  ἀσθένειαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G769",
    gloss: "ill",
  },
  ἀσθενείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G769",
    gloss: "ill",
  },
  ἀσθενειῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G769",
    gloss: "ill",
  },
  ἀσθενεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G770",
    gloss: "ill",
  },
  ἀσθενήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G770",
    gloss: "ill",
  },
  ἀσθενήσασαν: {
    parse:
      "V-AAP-ASF｜Verb, aorist, active, Participle, accusative, singular, feminine",
    GN: "G770",
    gloss: "ill",
  },
  ἀσθενοῦμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G770",
    gloss: "ill",
  },
  ἀσθενοῦντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G770",
    gloss: "ill",
  },
  ἀσθενοῦντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G770",
    gloss: "ill",
  },
  ἀσθενούντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G770",
    gloss: "ill",
  },
  ἀσθενοῦσαν: {
    parse:
      "V-PAP-ASF｜Verb, present, active, Participle, accusative, singular, feminine",
    GN: "G770",
    gloss: "ill",
  },
  ἀσθενῶ: {
    parse: "V-PAS-1S｜Verb, present, active, subjunctive, first, singular",
    GN: "G770",
    gloss: "ill",
  },
  ἀσθενῶμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G770",
    gloss: "ill",
  },
  ἀσθενῶν: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G772",
    gloss: "ill",
  },
  ἠσθένει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G770",
    gloss: "ill",
  },
  ἠσθενήκαμεν: {
    parse: "V-RAI-1P｜Verb, perfect, active, indicative, first, plural",
    GN: "G770",
    gloss: "ill",
  },
  ἠσθένησα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G770",
    gloss: "ill",
  },
  ἠσθένησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G770",
    gloss: "ill",
  },
  ἀσθενήματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G771",
    gloss: "weakness",
  },
  ἀσθενεῖς: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G772",
    gloss: "ill",
  },
  ἀσθενὲς: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G772",
    gloss: "ill",
  },
  ἀσθενέσιν: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G772",
    gloss: "ill",
  },
  ἀσθενέστερα: {
    parse: "A-NPN-C｜Adjective, nominative, plural, neuter, Comparative",
    GN: "G772",
    gloss: "ill",
  },
  ἀσθενεστέρῳ: {
    parse: "A-DSN-C｜Adjective, dative, singular, neuter, Comparative",
    GN: "G772",
    gloss: "ill",
  },
  ἀσθενῆ: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G772",
    gloss: "ill",
  },
  ἀσθενὴς: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G772",
    gloss: "ill",
  },
  ἀσθενής: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G772",
    gloss: "ill",
  },
  ἀσθενοῦς: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G772",
    gloss: "ill",
  },
  Ἀσίᾳ: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G773",
    gloss: "Asia",
  },
  Ἀσία: {
    parse: "N-NSF-L｜Noun, nominative, singular, feminine, Location",
    GN: "G773",
    gloss: "Asia",
  },
  Ἀσίαν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G773",
    gloss: "Asia",
  },
  Ἀσίας: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G773",
    gloss: "Asia",
  },
  Ἀσιανοὶ: {
    parse: "N-NPM-LG｜Noun, nominative, plural, masculine, Location Gentilic",
    GN: "G0774",
  },
  Ἀσιαρχῶν: {
    parse: "N-GPM-T｜Noun, genitive, plural, masculine, Title",
    GN: "G775",
    gloss: "Asiarch",
  },
  ἀσιτίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G776",
    gloss: "fasting",
  },
  ἄσιτοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G777",
    gloss: "fasting",
  },
  ἀσκῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G778",
    gloss: "to strive",
  },
  ἀσκοί: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G779",
    gloss: "wineskin",
  },
  ἀσκοὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G779",
    gloss: "wineskin",
  },
  ἀσκούς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G779",
    gloss: "wineskin",
  },
  ἀσκοὺς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G779",
    gloss: "wineskin",
  },
  ἀσμένως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G780",
    gloss: "gladly",
  },
  ἄσοφοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G781",
    gloss: "unwise",
  },
  ἀσπάζεσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G782",
    gloss: "to pay respects to",
  },
  ἀσπάζεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G782",
    gloss: "to pay respects to",
  },
  Ἀσπάζεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G782",
    gloss: "to pay respects to",
  },
  Ἀσπάζεταί: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G782",
    gloss: "to pay respects to",
  },
  ἀσπάζομαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G782",
    gloss: "to pay respects to",
  },
  ἀσπάζονται: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G782",
    gloss: "to pay respects to",
  },
  Ἀσπάζονται: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G782",
    gloss: "to pay respects to",
  },
  ἀσπάζονταί: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G782",
    gloss: "to pay respects to",
  },
  Ἀσπάζονταί: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G782",
    gloss: "to pay respects to",
  },
  ἀσπάζου: {
    parse:
      "V-PNM-2S｜Verb, present, Middle or Passive deponent, imperative, second, singular",
    GN: "G782",
    gloss: "to pay respects to",
  },
  ἄσπασαι: {
    parse:
      "V-ADM-2S｜Verb, aorist, Middle deponent, imperative, second, singular",
    GN: "G782",
    gloss: "to pay respects to",
  },
  Ἄσπασαι: {
    parse:
      "V-ADM-2S｜Verb, aorist, Middle deponent, imperative, second, singular",
    GN: "G782",
    gloss: "to pay respects to",
  },
  ἀσπασάμενοι: {
    parse:
      "V-ADP-NPM｜Verb, aorist, Middle deponent, Participle, nominative, plural, masculine",
    GN: "G782",
    gloss: "to pay respects to",
  },
  ἀσπασάμενος: {
    parse:
      "V-ADP-NSM｜Verb, aorist, Middle deponent, Participle, nominative, singular, masculine",
    GN: "G782",
    gloss: "to pay respects to",
  },
  ἀσπάσασθε: {
    parse:
      "V-ADM-2P｜Verb, aorist, Middle deponent, imperative, second, plural",
    GN: "G782",
    gloss: "to pay respects to",
  },
  Ἀσπάσασθε: {
    parse:
      "V-ADM-2P｜Verb, aorist, Middle deponent, imperative, second, plural",
    GN: "G782",
    gloss: "to pay respects to",
  },
  ἀσπάσησθε: {
    parse:
      "V-ADS-2P｜Verb, aorist, Middle deponent, subjunctive, second, plural",
    GN: "G782",
    gloss: "to pay respects to",
  },
  ἠσπάζοντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G782",
    gloss: "to pay respects to",
  },
  ἠσπάσατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G782",
    gloss: "to pay respects to",
  },
  ἀσπασμὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G783",
    gloss: "salutation",
  },
  ἀσπασμὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G783",
    gloss: "salutation",
  },
  ἀσπασμοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G783",
    gloss: "salutation",
  },
  ἀσπασμοὺς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G783",
    gloss: "salutation",
  },
  ἄσπιλοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G784",
    gloss: "spotless",
  },
  ἄσπιλον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G784",
    gloss: "spotless",
  },
  ἀσπίλου: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G784",
    gloss: "spotless",
  },
  ἀσπίδων: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G785",
    gloss: "asp",
  },
  ἄσπονδοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G786",
    gloss: "irreconcilable",
  },
  ἀσσαρίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G787",
    gloss: "assarion",
  },
  ἀσσαρίων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G787",
    gloss: "assarion",
  },
  ἆσσον: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G788",
    gloss: "nearer",
  },
  Ἆσσον: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G789",
    gloss: "Assos",
  },
  ἀστατοῦμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G790",
    gloss: "be unsettled",
  },
  ἀστεῖον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G791",
    gloss: "beautiful",
  },
  ἀστεῖος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G791",
    gloss: "beautiful",
  },
  ἀστέρα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G792",
    gloss: "star",
  },
  ἀστέρας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G792",
    gloss: "star",
  },
  ἀστέρες: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G792",
    gloss: "star",
  },
  ἀστέρος: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G792",
    gloss: "star",
  },
  ἀστέρων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G792",
    gloss: "star",
  },
  ἀστὴρ: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G792",
    gloss: "star",
  },
  ἀστήρικτοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G793",
    gloss: "unstable",
  },
  ἀστηρίκτους: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G793",
    gloss: "unstable",
  },
  ἄστοργοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G794",
    gloss: "unfeeling",
  },
  ἀστόργους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G794",
    gloss: "unfeeling",
  },
  ἀστοχήσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G795",
    gloss: "to deviate",
  },
  ἠστόχησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G795",
    gloss: "to deviate",
  },
  ἀστραπαὶ: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G796",
    gloss: "lightning",
  },
  ἀστραπῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G796",
    gloss: "lightning",
  },
  ἀστραπὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G796",
    gloss: "lightning",
  },
  ἀστραπὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G796",
    gloss: "lightning",
  },
  ἀστράπτουσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G797",
    gloss: "to flash",
  },
  ἀστραπτούσῃ: {
    parse:
      "V-PAP-DSF｜Verb, present, active, Participle, dative, singular, feminine",
    GN: "G797",
    gloss: "to flash",
  },
  ἄστρα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G798",
    gloss: "star",
  },
  ἄστροις: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G798",
    gloss: "star",
  },
  ἄστρον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G798",
    gloss: "star",
  },
  ἄστρων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G798",
    gloss: "star",
  },
  Ἀσύγκριτον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G799",
    gloss: "Asyncritus",
  },
  ἀσύμφωνοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G800",
    gloss: "discordant",
  },
  ἀσύνετοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G801",
    gloss: "senseless",
  },
  ἀσύνετος: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G801",
    gloss: "senseless",
  },
  ἀσυνέτους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G801",
    gloss: "senseless",
  },
  ἀσυνέτῳ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G801",
    gloss: "senseless",
  },
  ἀσυνθέτους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G802",
    gloss: "untrustworthy",
  },
  ἀσφαλείᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G803",
    gloss: "security",
  },
  ἀσφάλεια: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G803",
    gloss: "security",
  },
  ἀσφάλειαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G803",
    gloss: "security",
  },
  ἀσφαλές: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G804",
    gloss: "secure",
  },
  ἀσφαλὲς: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G804",
    gloss: "secure",
  },
  ἀσφαλῆ: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G804",
    gloss: "secure",
  },
  ἀσφαλίσασθε: {
    parse:
      "V-ADM-2P｜Verb, aorist, Middle deponent, imperative, second, plural",
    GN: "G805",
    gloss: "to secure",
  },
  ἀσφαλισθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G805",
    gloss: "to secure",
  },
  ἠσφαλίσαντο: {
    parse: "V-ADI-3P｜Verb, aorist, Middle deponent, indicative, third, plural",
    GN: "G805",
    gloss: "to secure",
  },
  ἠσφαλίσατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G805",
    gloss: "to secure",
  },
  ἀσφαλῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G806",
    gloss: "securely",
  },
  ἀσχημονεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G807",
    gloss: "to act improperly",
  },
  ἀσχημονεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G807",
    gloss: "to act improperly",
  },
  ἀσχημοσύνην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G808",
    gloss: "indecency",
  },
  ἀσχήμονα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G809",
    gloss: "indecent",
  },
  ἀσωτία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G810",
    gloss: "debauchery",
  },
  ἀσωτίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G810",
    gloss: "debauchery",
  },
  ἀσώτως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G811",
    gloss: "in debauchery",
  },
  ἠτακτήσαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G812",
    gloss: "be idle",
  },
  ἀτάκτους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G813",
    gloss: "disorderly",
  },
  ἀτάκτως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G814",
    gloss: "idly",
  },
  ἄτεκνος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G815",
    gloss: "childless",
  },
  ἀτενίζετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G816",
    gloss: "to gaze",
  },
  ἀτενίζοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G816",
    gloss: "to gaze",
  },
  ἀτενίσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G816",
    gloss: "to gaze",
  },
  ἀτενίσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G816",
    gloss: "to gaze",
  },
  ἀτενίσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G816",
    gloss: "to gaze",
  },
  ἀτενίσασα: {
    parse:
      "V-AAP-NSF｜Verb, aorist, active, Participle, nominative, singular, feminine",
    GN: "G816",
    gloss: "to gaze",
  },
  ἄτερ: { parse: "PREP｜Preposition", GN: "G817", gloss: "without" },
  ἀτιμάζεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G818",
    gloss: "to dishonor",
  },
  ἀτιμάζεσθαι: {
    parse: "V-PEN｜Verb, present, Either middle or passive, Infinitive",
    GN: "G818",
    gloss: "to dishonor",
  },
  ἀτιμάζετέ: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G818",
    gloss: "to dishonor",
  },
  ἀτιμάσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G818",
    gloss: "to dishonor",
  },
  ἀτιμασθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G818",
    gloss: "to dishonor",
  },
  ἠτίμασαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G818",
    gloss: "to dishonor",
  },
  ἠτιμάσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G818",
    gloss: "to dishonor",
  },
  ἀτιμίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G819",
    gloss: "dishonour",
  },
  ἀτιμία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G819",
    gloss: "dishonour",
  },
  ἀτιμίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G819",
    gloss: "dishonour",
  },
  ἀτιμίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G819",
    gloss: "dishonour",
  },
  ἄτιμοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G820",
    gloss: "dishonored",
  },
  ἄτιμος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G820",
    gloss: "dishonored",
  },
  ἀτιμότερα: {
    parse: "A-APN-C｜Adjective, accusative, plural, neuter, Comparative",
    GN: "G820",
    gloss: "dishonored",
  },
  ἀτμίδα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G822",
    gloss: "vapor",
  },
  ἀτμὶς: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G822",
    gloss: "vapor",
  },
  ἀτόμῳ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G823",
    gloss: "instant",
  },
  ἄτοπον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G824",
    gloss: "wrong",
  },
  ἀτόπων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G824",
    gloss: "wrong",
  },
  Ἀττάλειαν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G825",
    gloss: "Attalia",
  },
  αὐγάσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G826",
    gloss: "to see",
  },
  αὐγῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G827",
    gloss: "daybreak",
  },
  Αὐγούστου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G828",
    gloss: "Augustus",
  },
  αὐθάδεις: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G829",
    gloss: "self-willed",
  },
  αὐθάδη: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G829",
    gloss: "self-willed",
  },
  αὐθαίρετοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G830",
    gloss: "self-chosen",
  },
  αὐθαίρετος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G830",
    gloss: "self-chosen",
  },
  αὐθεντεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G831",
    gloss: "to domineer",
  },
  αὐλούμενον: {
    parse:
      "V-PPP-NSN｜Verb, present, passive, Participle, nominative, singular, neuter",
    GN: "G832",
    gloss: "to play the flute",
  },
  Ηὐλήσαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G832",
    gloss: "to play the flute",
  },
  αὐλῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G833",
    gloss: "palace/courtyard",
  },
  αὐλήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G833",
    gloss: "palace/courtyard",
  },
  αὐλὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G833",
    gloss: "palace/courtyard",
  },
  αὐλῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G833",
    gloss: "palace/courtyard",
  },
  αὐλητὰς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G834",
    gloss: "flute player",
  },
  αὐλητῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G834",
    gloss: "flute player",
  },
  ηὐλίζετο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G835",
    gloss: "to spend the night",
  },
  ηὐλίσθη: {
    parse:
      "V-AOI-3S｜Verb, aorist, Passive deponent, indicative, third, singular",
    GN: "G835",
    gloss: "to spend the night",
  },
  αὐλὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G836",
    gloss: "flute",
  },
  αὐξάνει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G837",
    gloss: "to grow",
  },
  αὐξάνειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G837",
    gloss: "to grow",
  },
  αὐξάνετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G837",
    gloss: "to grow",
  },
  αὐξανόμενα: {
    parse:
      "V-PPP-NPN｜Verb, present, passive, Participle, nominative, plural, neuter",
    GN: "G837",
    gloss: "to grow",
  },
  αὐξανομένης: {
    parse:
      "V-PPP-GSF｜Verb, present, passive, Participle, genitive, singular, feminine",
    GN: "G837",
    gloss: "to grow",
  },
  αὐξανόμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G837",
    gloss: "to grow",
  },
  αὐξανόμενον: {
    parse:
      "V-PPP-NSN｜Verb, present, passive, Participle, nominative, singular, neuter",
    GN: "G837",
    gloss: "to grow",
  },
  αὐξάνουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G837",
    gloss: "to grow",
  },
  αὐξάνων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G837",
    gloss: "to grow",
  },
  αὔξει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G837",
    gloss: "to grow",
  },
  αὐξηθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G837",
    gloss: "to grow",
  },
  αὐξηθῆτε: {
    parse: "V-APS-2P｜Verb, aorist, passive, subjunctive, second, plural",
    GN: "G837",
    gloss: "to grow",
  },
  αὐξήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G837",
    gloss: "to grow",
  },
  αὐξήσωμεν: {
    parse: "V-AAS-1P｜Verb, aorist, active, subjunctive, first, plural",
    GN: "G837",
    gloss: "to grow",
  },
  ηὔξανε: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G837",
    gloss: "to grow",
  },
  ηὔξανεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G837",
    gloss: "to grow",
  },
  ηὔξησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G837",
    gloss: "to grow",
  },
  αὔξησιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G838",
    gloss: "growth",
  },
  αὔριον: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G839",
    gloss: "tomorrow",
  },
  Αὔριον: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G839",
    gloss: "tomorrow",
  },
  αὐστηρός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G840",
    gloss: "harsh, severe",
  },
  αὐστηρὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G840",
    gloss: "harsh, severe",
  },
  αὐτάρκειαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G841",
    gloss: "self-sufficiency",
  },
  αὐταρκείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G841",
    gloss: "self-sufficiency",
  },
  αὐτάρκης: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G842",
    gloss: "self-sufficient",
  },
  αὐτοκατάκριτος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G843",
    gloss: "self-condemned",
  },
  αὐτομάτη: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G844",
    gloss: "by itself",
  },
  αὐτόπται: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G845",
    gloss: "eyewitness",
  },
  αὐτά: {
    parse: "P-APN｜Personal pronoun, accusative, plural, neuter",
    GN: "G846",
    gloss: "it/she/he",
  },
  αὐτὰ: {
    parse: "P-NPN｜Personal pronoun, nominative, plural, neuter",
    GN: "G846",
    gloss: "it/she/he",
  },
  αὐταῖς: {
    parse: "P-DPF｜Personal pronoun, dative, plural, feminine",
    GN: "G846",
    gloss: "it/she/he",
  },
  αὐτάς: {
    parse: "P-APF｜Personal pronoun, accusative, plural, feminine",
    GN: "G846",
    gloss: "it/she/he",
  },
  αὐτὰς: {
    parse: "P-APF｜Personal pronoun, accusative, plural, feminine",
    GN: "G846",
    gloss: "it/she/he",
  },
  αὐτῇ: {
    parse: "P-DSF｜Personal pronoun, dative, singular, feminine",
    GN: "G846",
    gloss: "it/she/he",
  },
  αὐτὴ: {
    parse: "P-NSF｜Personal pronoun, nominative, singular, feminine",
    GN: "G846",
    gloss: "it/she/he",
  },
  αὐτήν: {
    parse: "P-ASF｜Personal pronoun, accusative, singular, feminine",
    GN: "G846",
    gloss: "it/she/he",
  },
  αὐτὴν: {
    parse: "P-ASF｜Personal pronoun, accusative, singular, feminine",
    GN: "G846",
    gloss: "it/she/he",
  },
  αὐτῆς: {
    parse: "P-GSF｜Personal pronoun, genitive, singular, feminine",
    GN: "G846",
    gloss: "it/she/he",
  },
  αὐτό: {
    parse: "P-ASN｜Personal pronoun, accusative, singular, neuter",
    GN: "G846",
    gloss: "it/she/he",
  },
  αὐτὸ: {
    parse: "P-NSN｜Personal pronoun, nominative, singular, neuter",
    GN: "G846",
    gloss: "it/she/he",
  },
  αὑτὸν: {
    parse: "P-NSN｜Possessive pronoun, third, accusative, singular, masculine",
    GN: "G846",
    gloss: "it/she/he",
  },
  αὐτοί: {
    parse: "P-NPM｜Personal pronoun, nominative, plural, masculine",
    GN: "G846",
    gloss: "it/she/he",
  },
  αὐτοὶ: {
    parse: "P-NPM｜Personal pronoun, nominative, plural, masculine",
    GN: "G846",
    gloss: "it/she/he",
  },
  Αὐτοὶ: {
    parse: "P-NPM｜Personal pronoun, nominative, plural, masculine",
    GN: "G846",
    gloss: "it/she/he",
  },
  αὐτοῖς: {
    parse: "P-DPN｜Personal pronoun, dative, plural, neuter or masculine",
    GN: "G846",
    gloss: "it/she/he",
  },
  αὐτόν: {
    parse: "P-ASM｜Personal pronoun, accusative, singular, masculine",
    GN: "G846",
    gloss: "it/she/he",
  },
  αὐτὸν: {
    parse: "P-ASM｜Personal pronoun, accusative, singular, masculine",
    GN: "G846",
    gloss: "it/she/he",
  },
  αὑτόν: {
    parse: "P-ASM｜Personal pronoun, accusative, singular, masculine",
    GN: "G846",
    gloss: "it/she/he",
  },
  αὐτός: {
    parse: "P-NSM｜Personal pronoun, nominative, singular, masculine",
    GN: "G846",
    gloss: "it/she/he",
  },
  αὐτὸς: {
    parse: "P-NSM｜Personal pronoun, nominative, singular, masculine",
    GN: "G846",
    gloss: "it/she/he",
  },
  Αὐτὸς: {
    parse: "P-NSM｜Personal pronoun, nominative, singular, masculine",
    GN: "G846",
    gloss: "it/she/he",
  },
  αὐτοῦ: {
    //ADV｜Adverb or Adverb and particle combined this word can have two different uses. going for the most common
    parse: "P-ASM｜Personal pronoun, genitive, singular, masculine or neuter",
    GN: "G846", //G847
    gloss: "there",
  },
  αὐτούς: {
    parse: "P-APM｜Personal pronoun, accusative, plural, masculine",
    GN: "G846",
    gloss: "it/she/he",
  },
  αὐτοὺς: {
    parse: "P-APM｜Personal pronoun, accusative, plural, masculine",
    GN: "G846",
    gloss: "it/she/he",
  },
  αὐτῷ: {
    parse: "P-DSN｜Personal pronoun, dative, singular, neuter",
    GN: "G846",
    gloss: "it/she/he",
  },
  αὐτῶν: {
    parse: "P-GPN｜Personal pronoun, genitive, plural, neuter",
    GN: "G846",
    gloss: "it/she/he",
  },
  Αὐτῶν: {
    parse: "P-GPM｜Personal pronoun, genitive, plural, masculine",
    GN: "G846",
    gloss: "it/she/he",
  },
  αὐτόχειρες: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G849",
    gloss: "with one’s own hand",
  },
  αὐχμηρῷ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G850",
    gloss: "dingy",
  },
  ἀφαιρεθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G851",
    gloss: "to remove",
  },
  ἀφαιρεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G851",
    gloss: "to remove",
  },
  ἀφαιρεῖται: {
    parse: "V-PMI-3S｜Verb, present, middle, indicative, third, singular",
    GN: "G851",
    gloss: "to remove",
  },
  ἀφεῖλεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G851",
    gloss: "to remove",
  },
  ἀφελεῖ: {
    parse: "V-2FAI-3S｜Verb, 2nd future, active, indicative, third, singular",
    GN: "G851",
    gloss: "to remove",
  },
  ἀφελεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G851",
    gloss: "to remove",
  },
  ἀφέλῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G851",
    gloss: "to remove",
  },
  ἀφέλωμαι: {
    parse: "V-2AMS-1S｜Verb, 2nd aorist, middle, subjunctive, first, singular",
    GN: "G851",
    gloss: "to remove",
  },
  ἀφανὴς: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G852",
    gloss: "hidden",
  },
  ἀφανίζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G853",
    gloss: "to destroy",
  },
  ἀφανιζομένη: {
    parse:
      "V-PPP-NSF｜Verb, present, passive, Participle, nominative, singular, feminine",
    GN: "G853",
    gloss: "to destroy",
  },
  ἀφανίζουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G853",
    gloss: "to destroy",
  },
  ἀφανίσθητε: {
    parse: "V-APM-2P｜Verb, aorist, passive, imperative, second, plural",
    GN: "G853",
    gloss: "to destroy",
  },
  ἀφανισμοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G854",
    gloss: "disappearance",
  },
  ἄφαντος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G855",
    gloss: "disappearing",
  },
  ἀφεδρῶνα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G856",
    gloss: "latrine",
  },
  ἀφειδίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G857",
    gloss: "unsparing",
  },
  ἀφελότητι: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G858",
    gloss: "sincerity",
  },
  ἀφέσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G859",
    gloss: "forgiveness",
  },
  ἄφεσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G859",
    gloss: "forgiveness",
  },
  ἄφεσις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G859",
    gloss: "forgiveness",
  },
  ἁφῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G860",
    gloss: "joint",
  },
  ἁφῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G860",
    gloss: "joint",
  },
  ἀφθαρσίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G861",
    gloss: "incorruptibility",
  },
  ἀφθαρσίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G861",
    gloss: "incorruptibility",
  },
  ἀφθορίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G861",
    gloss: "incorruptibility",
  },
  ἄφθαρτοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G862",
    gloss: "incorruptible",
  },
  ἄφθαρτον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G862",
    gloss: "incorruptible",
  },
  ἀφθάρτου: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G862",
    gloss: "incorruptible",
  },
  ἀφθάρτῳ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G862",
    gloss: "incorruptible",
  },
  ἀφεθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G863",
    gloss: "permit",
  },
  ἀφέθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G863",
    gloss: "permit",
  },
  ἀφεθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G863",
    gloss: "permit",
  },
  ἀφεθήσεταί: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G863",
    gloss: "permit",
  },
  ἀφεῖναι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G863",
    gloss: "permit",
  },
  ἀφεὶς: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G863",
    gloss: "permit",
  },
  ἀφεῖς: {
    parse: "V-2PAI-2S｜Verb, 2nd present, active, indicative, second, singular",
    GN: "G863",
    gloss: "permit",
  },
  ἀφέντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G863",
    gloss: "permit",
  },
  ἄφες: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G863",
    gloss: "permit",
  },
  Ἄφες: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G863",
    gloss: "permit",
  },
  ἄφετε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G863",
    gloss: "permit",
  },
  Ἄφετε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G863",
    gloss: "permit",
  },
  ἀφέωνται: {
    parse: "V-RPI-3P｜Verb, perfect, passive, indicative, third, plural",
    GN: "G863",
    gloss: "permit",
  },
  ἀφέωνταί: {
    parse: "V-RPI-3P｜Verb, perfect, passive, indicative, third, plural",
    GN: "G863",
    gloss: "permit",
  },
  Ἀφέωνταί: {
    parse: "V-RPI-3P｜Verb, perfect, passive, indicative, third, plural",
    GN: "G863",
    gloss: "permit",
  },
  ἀφῇ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G863",
    gloss: "permit",
  },
  ἀφῆκά: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G863",
    gloss: "permit",
  },
  ἀφήκαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G863",
    gloss: "permit",
  },
  ἀφῆκαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G863",
    gloss: "permit",
  },
  ἀφήκατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G863",
    gloss: "permit",
  },
  ἀφῆκεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G863",
    gloss: "permit",
  },
  ἀφῆκέν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G863",
    gloss: "permit",
  },
  ἀφῆκες: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G863",
    gloss: "permit",
  },
  ἀφήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G863",
    gloss: "permit",
  },
  ἀφήσεις: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G863",
    gloss: "permit",
  },
  ἀφήσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G863",
    gloss: "permit",
  },
  ἀφήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G863",
    gloss: "permit",
  },
  ἀφῆτε: {
    parse: "V-2AAS-2P｜Verb, 2nd aorist, active, subjunctive, second, plural",
    GN: "G863",
    gloss: "permit",
  },
  ἀφιέναι: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G863",
    gloss: "permit",
  },
  ἀφίενταί: {
    parse: "V-PPI-3P｜Verb, present, passive, indicative, third, plural",
    GN: "G863",
    gloss: "permit",
  },
  Ἀφίενταί: {
    parse: "V-PPI-3P｜Verb, present, passive, indicative, third, plural",
    GN: "G863",
    gloss: "permit",
  },
  ἀφίεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G863",
    gloss: "permit",
  },
  ἀφίετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G863",
    gloss: "permit",
  },
  ἀφιέτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G863",
    gloss: "permit",
  },
  ἀφίημι: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G863",
    gloss: "permit",
  },
  ἀφίησιν: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G863",
    gloss: "permit",
  },
  ἀφίομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G863",
    gloss: "permit",
  },
  ἀφίουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G863",
    gloss: "permit",
  },
  ἀφῶμεν: {
    parse: "V-2AAS-1P｜Verb, 2nd aorist, active, subjunctive, first, plural",
    GN: "G863",
    gloss: "permit",
  },
  ἤφιεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G863",
    gloss: "permit",
  },
  ἀφίκετο: {
    parse:
      "V-2ADI-3S｜Verb, 2nd aorist, Middle deponent, indicative, third, singular",
    GN: "G864",
    gloss: "to reach",
  },
  ἀφιλάγαθοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G865",
    gloss: "hating good",
  },
  ἀφιλάργυρον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G866",
    gloss: "not greedy",
  },
  ἀφιλάργυρος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G866",
    gloss: "not greedy",
  },
  ἄφιξίν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G867",
    gloss: "departure",
  },
  ἀπέστη: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G868",
    gloss: "to leave",
  },
  ἀπέστησαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G868",
    gloss: "to leave",
  },
  ἀπέστησεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G868",
    gloss: "to leave",
  },
  ἀποστάντα: {
    parse:
      "V-2AAP-ASM｜Verb, 2nd aorist, active, Participle, accusative, singular, masculine",
    GN: "G868",
    gloss: "to leave",
  },
  ἀποστὰς: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G868",
    gloss: "to leave",
  },
  ἀποστῇ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G868",
    gloss: "to leave",
  },
  ἀποστῆναι: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G868",
    gloss: "to leave",
  },
  ἀποστήσονταί: {
    parse: "V-FDI-3P｜Verb, future, Middle deponent, indicative, third, plural",
    GN: "G868",
    gloss: "to leave",
  },
  ἀπόστητε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G868",
    gloss: "to leave",
  },
  Ἀποστήτω: {
    parse: "V-2AAM-3S｜Verb, 2nd aorist, active, imperative, third, singular",
    GN: "G868",
    gloss: "to leave",
  },
  ἀφίστανται: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G868",
    gloss: "to leave",
  },
  ἀφίστατο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G868",
    gloss: "to leave",
  },
  ἄφνω: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G869",
    gloss: "suddenly",
  },
  ἀφόβως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G870",
    gloss: "fearlessly",
  },
  ἀφωμοιωμένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G871",
    gloss: "to make like",
  },
  ἀφίδω: {
    parse: "V-2AAS-1S｜Verb, 2nd aorist, active, subjunctive, first, singular",
    GN: "G872",
    gloss: "to consider",
  },
  ἀφορῶντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G872",
    gloss: "to consider",
  },
  ἀφορίζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G873",
    gloss: "to separate",
  },
  ἀφοριοῦσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G873",
    gloss: "to separate",
  },
  ἀφορίσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G873",
    gloss: "to separate",
  },
  Ἀφορίσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G873",
    gloss: "to separate",
  },
  ἀφορίσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G873",
    gloss: "to separate",
  },
  ἀφορίσθητε: {
    parse: "V-APM-2P｜Verb, aorist, passive, imperative, second, plural",
    GN: "G873",
    gloss: "to separate",
  },
  ἀφορίσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G873",
    gloss: "to separate",
  },
  ἀφώριζεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G873",
    gloss: "to separate",
  },
  ἀφώρισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G873",
    gloss: "to separate",
  },
  ἀφωρισμένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G873",
    gloss: "to separate",
  },
  ἀφορμήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G874",
    gloss: "opportunity",
  },
  ἀφορμὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G874",
    gloss: "opportunity",
  },
  ἀφρίζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G875",
    gloss: "to foam",
  },
  ἀφρίζων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G875",
    gloss: "to foam",
  },
  ἀφροῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G876",
    gloss: "foam",
  },
  ἀφροσύνῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G877",
    gloss: "foolishness",
  },
  ἀφροσύνη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G877",
    gloss: "foolishness",
  },
  ἀφροσύνης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G877",
    gloss: "foolishness",
  },
  ἄφρονα: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G878",
    gloss: "foolish",
  },
  ἄφρονες: {
    parse: "A-VPM｜Adjective, Vocative or nominative, plural, masculine",
    GN: "G878",
    gloss: "foolish",
  },
  ἀφρόνων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G878",
    gloss: "foolish",
  },
  ἄφρων: {
    parse: "A-VSM｜Adjective, Vocative or nominative, singular, masculine",
    GN: "G878",
    gloss: "foolish",
  },
  Ἄφρων: {
    parse: "A-VSM｜Adjective, Vocative or nominative, singular, masculine",
    GN: "G878",
    gloss: "foolish",
  },
  ἀφύπνωσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G879",
    gloss: "to fall sleep",
  },
  ἄφωνα: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G880",
    gloss: "mute",
  },
  ἄφωνον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G880",
    gloss: "mute",
  },
  ἄφωνος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G880",
    gloss: "mute",
  },
  Ἀχάζ: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G881",
    gloss: "Ahaz",
  },
  Ἀχὰζ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G881",
    gloss: "Ahaz",
  },
  Ἀχαΐᾳ: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G882",
    gloss: "Achaia",
  },
  Ἀχαΐα: {
    parse: "N-NSF-L｜Noun, nominative, singular, feminine, Location",
    GN: "G882",
    gloss: "Achaia",
  },
  Ἀχαΐαν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G882",
    gloss: "Achaia",
  },
  Ἀχαΐας: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G882",
    gloss: "Achaia",
  },
  Ἀχαϊκοῦ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G883",
    gloss: "Achaicus",
  },
  ἀχάριστοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G884",
    gloss: "ungrateful",
  },
  ἀχαρίστους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G884",
    gloss: "ungrateful",
  },
  Ἀχίμ: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G885",
    gloss: "Achim",
  },
  Ἀχὶμ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G885",
    gloss: "Achim",
  },
  ἀχειροποίητον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G886",
    gloss: "not man-made",
  },
  ἀχειροποιήτῳ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G886",
    gloss: "not man-made",
  },
  ἀχλὺς: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G887",
    gloss: "mist",
  },
  ἀχρεῖοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G888",
    gloss: "worthless",
  },
  ἀχρεῖον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G888",
    gloss: "worthless",
  },
  ἠχρεώθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G889",
    gloss: "to make worthless",
  },
  ἄχρηστον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G890",
    gloss: "useless",
  },
  ἄχρι: { parse: "PREP｜Preposition", GN: "G891", gloss: "until" },
  Ἄχρι: { parse: "PREP｜Preposition", GN: "G891", gloss: "until" },
  ἄχρις: { parse: "PREP｜Preposition", GN: "G891", gloss: "until" },
  ἄχυρον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G892",
    gloss: "chaff",
  },
  ἀψευδὴς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G893",
    gloss: "not a liar",
  },
  ἄψινθον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G894",
    gloss: "wormwood",
  },
  Ἄψινθος: {
    parse: "N-NSM-T｜Noun, nominative, singular, masculine, Title",
    GN: "G894",
    gloss: "wormwood",
  },
  ἄψυχα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G895",
    gloss: "lifeless",
  },
  Βάαλ: {
    parse: "N-GSM-T｜Noun, genitive, singular, masculine, Title",
    GN: "G896",
    gloss: "Baal",
  },
  Βαβυλὼν: {
    parse:
      "N-VSF-L｜Noun, Vocative or nominative, singular, feminine, Location",
    GN: "G897",
    gloss: "Babylon",
  },
  Βαβυλῶνι: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G897",
    gloss: "Babylon",
  },
  Βαβυλῶνος: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G897",
    gloss: "Babylon",
  },
  βαθμὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G898",
    gloss: "standing",
  },
  βάθη: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G899",
    gloss: "depth",
  },
  βάθος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G899",
    gloss: "depth",
  },
  βάθους: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G899",
    gloss: "depth",
  },
  ἐβάθυνεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G900",
    gloss: "to dig deep",
  },
  βαθέα: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G901",
    gloss: "deep",
  },
  βαθεῖ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G901",
    gloss: "deep",
  },
  βαθέως: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G901",
    gloss: "deep",
  },
  βαθύ: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G901",
    gloss: "deep",
  },
  βαΐα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G902",
    gloss: "palm branch",
  },
  Βαλαάμ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G903",
    gloss: "Balaam",
  },
  Βαλαὰμ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G903",
    gloss: "Balaam",
  },
  Βαλὰκ: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G904",
    gloss: "Balak",
  },
  βαλλάντια: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G905",
    gloss: "purse",
  },
  βαλλάντιον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G905",
    gloss: "purse",
  },
  βαλλαντίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G905",
    gloss: "purse",
  },
  βάλε: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G906",
    gloss: "hit",
  },
  Βάλε: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G906",
    gloss: "hit",
  },
  βαλεῖ: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G906",
    gloss: "hit",
  },
  βαλεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G906",
    gloss: "hit",
  },
  Βάλετε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G906",
    gloss: "hit",
  },
  βαλέτω: {
    parse: "V-2AAM-3S｜Verb, 2nd aorist, active, imperative, third, singular",
    GN: "G906",
    gloss: "hit",
  },
  βάλῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G906",
    gloss: "hit",
  },
  βάλητε: {
    parse: "V-2AAS-2P｜Verb, 2nd aorist, active, subjunctive, second, plural",
    GN: "G906",
    gloss: "hit",
  },
  βάλλει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G906",
    gloss: "hit",
  },
  βάλλειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G906",
    gloss: "hit",
  },
  βάλλεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G906",
    gloss: "hit",
  },
  βάλλομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G906",
    gloss: "hit",
  },
  βαλλόμενα: {
    parse:
      "V-PPP-APN｜Verb, present, passive, Participle, accusative, plural, neuter",
    GN: "G906",
    gloss: "hit",
  },
  βαλλόμενον: {
    parse:
      "V-PPP-ASM｜Verb, present, passive, Participle, accusative, singular, masculine",
    GN: "G906",
    gloss: "hit",
  },
  βάλλοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G906",
    gloss: "hit",
  },
  βάλλοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G906",
    gloss: "hit",
  },
  βαλλόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G906",
    gloss: "hit",
  },
  βάλλουσαν: {
    parse:
      "V-PAP-ASF｜Verb, present, active, Participle, accusative, singular, feminine",
    GN: "G906",
    gloss: "hit",
  },
  βάλλουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G906",
    gloss: "hit",
  },
  βάλλω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G906",
    gloss: "hit",
  },
  βαλοῦσα: {
    parse:
      "V-2AAP-NSF｜Verb, 2nd aorist, active, Participle, nominative, singular, feminine",
    GN: "G906",
    gloss: "hit",
  },
  βαλοῦσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G906",
    gloss: "hit",
  },
  βάλω: {
    parse: "V-2AAS-1S｜Verb, 2nd aorist, active, subjunctive, first, singular",
    GN: "G906",
    gloss: "hit",
  },
  βάλωσιν: {
    parse: "V-2AAS-3P｜Verb, 2nd aorist, active, subjunctive, third, plural",
    GN: "G906",
    gloss: "hit",
  },
  βεβληκότος: {
    parse:
      "V-RAP-GSM｜Verb, perfect, active, Participle, genitive, singular, masculine",
    GN: "G906",
    gloss: "hit",
  },
  βεβλημένην: {
    parse:
      "V-RPP-ASF｜Verb, perfect, passive, Participle, accusative, singular, feminine",
    GN: "G906",
    gloss: "hit",
  },
  βεβλημένον: {
    parse:
      "V-RPP-ASN｜Verb, perfect, passive, Participle, accusative, singular, neuter",
    GN: "G906",
    gloss: "hit",
  },
  βεβλημένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G906",
    gloss: "hit",
  },
  βέβληται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G906",
    gloss: "hit",
  },
  βληθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G906",
    gloss: "hit",
  },
  βληθείσῃ: {
    parse:
      "V-APP-DSF｜Verb, aorist, passive, Participle, dative, singular, feminine",
    GN: "G906",
    gloss: "hit",
  },
  βληθὲν: {
    parse:
      "V-APP-NSN｜Verb, aorist, passive, Participle, nominative, singular, neuter",
    GN: "G906",
    gloss: "hit",
  },
  βληθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G906",
    gloss: "hit",
  },
  βληθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G906",
    gloss: "hit",
  },
  βληθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G906",
    gloss: "hit",
  },
  βληθήσῃ: {
    parse: "V-FPI-2S｜Verb, future, passive, indicative, second, singular",
    GN: "G906",
    gloss: "hit",
  },
  βλήθητι: {
    parse: "V-APM-2S｜Verb, aorist, passive, imperative, second, singular",
    GN: "G906",
    gloss: "hit",
  },
  ἔβαλαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G906",
    gloss: "hit",
  },
  ἔβαλεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G906",
    gloss: "hit",
  },
  ἔβαλλον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G906",
    gloss: "hit",
  },
  ἔβαλον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G906",
    gloss: "hit",
  },
  ἐβέβλητο: {
    parse: "V-LPI-3S｜Verb, pluperfect, passive, indicative, third, singular",
    GN: "G906",
    gloss: "hit",
  },
  ἐβλήθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G906",
    gloss: "hit",
  },
  ἐβλήθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G906",
    gloss: "hit",
  },
  βαπτίζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G907",
    gloss: "to baptize",
  },
  βαπτίζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G907",
    gloss: "to baptize",
  },
  βαπτίζεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G907",
    gloss: "to baptize",
  },
  βαπτίζομαι: {
    parse: "V-PPI-1S｜Verb, present, passive, indicative, first, singular",
    GN: "G907",
    gloss: "to baptize",
  },
  βαπτιζόμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G907",
    gloss: "to baptize",
  },
  βαπτίζονται: {
    parse: "V-PPI-3P｜Verb, present, passive, indicative, third, plural",
    GN: "G907",
    gloss: "to baptize",
  },
  βαπτίζοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G907",
    gloss: "to baptize",
  },
  βαπτίζοντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G907",
    gloss: "to baptize",
  },
  βαπτίζω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G907",
    gloss: "to baptize",
  },
  βαπτίζων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G907",
    gloss: "to baptize",
  },
  βάπτισαι: {
    parse: "V-AMM-2S｜Verb, aorist, middle, imperative, second, singular",
    GN: "G907",
    gloss: "to baptize",
  },
  βαπτίσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G907",
    gloss: "to baptize",
  },
  βαπτισθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G907",
    gloss: "to baptize",
  },
  βαπτισθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G907",
    gloss: "to baptize",
  },
  βαπτισθέντος: {
    parse:
      "V-APP-GSM｜Verb, aorist, passive, Participle, genitive, singular, masculine",
    GN: "G907",
    gloss: "to baptize",
  },
  βαπτισθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G907",
    gloss: "to baptize",
  },
  βαπτισθήσεσθε: {
    parse: "V-FPI-2P｜Verb, future, passive, indicative, second, plural",
    GN: "G907",
    gloss: "to baptize",
  },
  βαπτισθήτω: {
    parse: "V-APM-3S｜Verb, aorist, passive, imperative, third, singular",
    GN: "G907",
    gloss: "to baptize",
  },
  βαπτίσωνται: {
    parse: "V-AMS-3P｜Verb, aorist, middle, subjunctive, third, plural",
    GN: "G907",
    gloss: "to baptize",
  },
  βεβαπτισμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G907",
    gloss: "to baptize",
  },
  ἐβάπτιζεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G907",
    gloss: "to baptize",
  },
  ἐβαπτίζοντο: {
    parse: "V-IPI-3P｜Verb, imperfect, passive, indicative, third, plural",
    GN: "G907",
    gloss: "to baptize",
  },
  ἐβάπτισα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G907",
    gloss: "to baptize",
  },
  ἐβάπτισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G907",
    gloss: "to baptize",
  },
  ἐβαπτίσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G907",
    gloss: "to baptize",
  },
  ἐβαπτίσθημεν: {
    parse: "V-API-1P｜Verb, aorist, passive, indicative, first, plural",
    GN: "G907",
    gloss: "to baptize",
  },
  ἐβαπτίσθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G907",
    gloss: "to baptize",
  },
  ἐβαπτίσθητε: {
    parse: "V-API-2P｜Verb, aorist, passive, indicative, second, plural",
    GN: "G907",
    gloss: "to baptize",
  },
  βάπτισμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G908",
    gloss: "baptism",
  },
  βαπτίσματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G908",
    gloss: "baptism",
  },
  βαπτισμοῖς: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G909",
    gloss: "baptism",
  },
  βαπτισμοὺς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G909",
    gloss: "baptism",
  },
  βαπτισμῷ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G909",
    gloss: "baptism",
  },
  βαπτισμῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G909",
    gloss: "baptism",
  },
  βαπτιστήν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G910",
    gloss: "one who baptizes",
  },
  βαπτιστής: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G910",
    gloss: "one who baptizes",
  },
  βαπτιστὴς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G910",
    gloss: "one who baptizes",
  },
  Βαπτιστὴς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G910",
    gloss: "one who baptizes",
  },
  βαπτιστοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G910",
    gloss: "one who baptizes",
  },
  βάψας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G911",
    gloss: "to dip",
  },
  βάψῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G911",
    gloss: "to dip",
  },
  βάψω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G911",
    gloss: "to dip",
  },
  βεβαμμένον: {
    parse:
      "V-RPP-ASN｜Verb, perfect, passive, Participle, accusative, singular, neuter",
    GN: "G911",
    gloss: "to dip",
  },
  Βαραββᾶν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G912",
    gloss: "Barabbas",
  },
  Βαραββᾶς: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G912",
    gloss: "Barabbas",
  },
  Βαράκ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G913",
    gloss: "Barak",
  },
  Βαραχίου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G914",
    gloss: "Barachiah",
  },
  βάρβαροι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G915",
    gloss: "barbarian",
  },
  βαρβάροις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G915",
    gloss: "barbarian",
  },
  βάρβαρος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G915",
    gloss: "barbarian",
  },
  βαρείσθω: {
    parse: "V-PPM-3S｜Verb, present, passive, imperative, third, singular",
    GN: "G916",
    gloss: "to burden",
  },
  βαρηθῶσιν: {
    parse: "V-APS-3P｜Verb, aorist, passive, subjunctive, third, plural",
    GN: "G916",
    gloss: "to burden",
  },
  βαρούμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G916",
    gloss: "to burden",
  },
  βεβαρημένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G916",
    gloss: "to burden",
  },
  ἐβαρήθημεν: {
    parse: "V-API-1P｜Verb, aorist, passive, indicative, first, plural",
    GN: "G916",
    gloss: "to burden",
  },
  βαρέως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G917",
    gloss: "difficultly",
  },
  Βαρθολομαῖον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G918",
    gloss: "Bartholomew",
  },
  Βαρθολομαῖος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G918",
    gloss: "Bartholomew",
  },
  Βαριησοῦ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G919",
    gloss: "Bar-Jesus",
  },
  Βαριωνᾶ: {
    parse: "N-VSM-P｜Noun, Vocative or nominative, singular, masculine, Person",
    GN: "G920",
    gloss: "Bar-Jonah",
  },
  Βαρναβᾷ: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G921",
    gloss: "[father of Barnabus]",
  },
  Βαρναβᾶ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G921",
    gloss: "[father of Barnabus]",
  },
  Βαρναβᾶν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G921",
    gloss: "[father of Barnabus]",
  },
  Βαρναβᾶς: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G921",
    gloss: "[father of Barnabus]",
  },
  βάρει: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G922",
    gloss: "burden",
  },
  βάρη: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G922",
    gloss: "burden",
  },
  βάρος: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G922",
    gloss: "burden",
  },
  Βαρσαββᾶν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G923",
    gloss: "Barsabbas",
  },
  Βαρτιμαῖος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G924",
    gloss: "Bartimaeus",
  },
  βαρέα: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G926",
    gloss: "weighty",
  },
  βαρεῖαι: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G926",
    gloss: "weighty",
  },
  βαρεῖς: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G926",
    gloss: "weighty",
  },
  βαρύτερα: {
    parse: "A-APN-C｜Adjective, accusative, plural, neuter, Comparative",
    GN: "G926",
    gloss: "weighty",
  },
  βαρυτίμου: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G927",
    gloss: "expensive",
  },
  βασανιζομένη: {
    parse:
      "V-PPP-NSF｜Verb, present, passive, Participle, nominative, singular, feminine",
    GN: "G928",
    gloss: "anguish",
  },
  βασανιζόμενον: {
    parse:
      "V-PPP-NSN｜Verb, present, passive, Participle, nominative, singular, neuter",
    GN: "G928",
    gloss: "anguish",
  },
  βασανιζόμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G928",
    gloss: "anguish",
  },
  βασανιζομένους: {
    parse:
      "V-PPP-APM｜Verb, present, passive, Participle, accusative, plural, masculine",
    GN: "G928",
    gloss: "anguish",
  },
  βασανίσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G928",
    gloss: "anguish",
  },
  βασανίσῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G928",
    gloss: "anguish",
  },
  βασανισθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G928",
    gloss: "anguish",
  },
  βασανισθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G928",
    gloss: "anguish",
  },
  ἐβασάνιζεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G928",
    gloss: "anguish",
  },
  ἐβασάνισαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G928",
    gloss: "anguish",
  },
  βασανισμὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G929",
    gloss: "torment",
  },
  βασανισμὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G929",
    gloss: "torment",
  },
  βασανισμοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G929",
    gloss: "torment",
  },
  βασανισταῖς: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G930",
    gloss: "torturer",
  },
  βασάνοις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G931",
    gloss: "torment",
  },
  βασάνου: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G931",
    gloss: "torment",
  },
  βασιλείᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G932",
    gloss: "kingdom",
  },
  βασιλεία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G932",
    gloss: "kingdom",
  },
  Βασιλεία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G932",
    gloss: "kingdom",
  },
  βασιλείαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G932",
    gloss: "kingdom",
  },
  βασιλείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G932",
    gloss: "kingdom",
  },
  βασιλείοις: {
    parse: "A-DPN｜Adjective, dative, plural, neuter",
    GN: "G933",
    gloss: "palace",
  },
  βασίλειον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G934",
    gloss: "kingly",
  },
  βασιλέα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G935",
    gloss: "king",
  },
  βασιλεῖ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G935",
    gloss: "king",
  },
  βασιλεῖς: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G935",
    gloss: "king",
  },
  βασιλεῦ: {
    // or nominative
    parse: "N-VSM｜Noun, Vocative, singular, masculine",
    GN: "G935",
    gloss: "king",
  },
  βασιλεύς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G935",
    gloss: "king",
  },
  βασιλεὺς: {
    parse: "N-VSM｜Noun, Vocative or nominative, singular, masculine",
    GN: "G935",
    gloss: "king",
  },
  Βασιλεὺς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G935",
    gloss: "king",
  },
  Βασιλεῦς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G935",
    gloss: "king",
  },
  βασιλεῦσιν: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G935",
    gloss: "king",
  },
  βασιλέων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G935",
    gloss: "king",
  },
  βασιλέως: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G935",
    gloss: "king",
  },
  βασιλεύει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G936",
    gloss: "to reign",
  },
  βασιλεύειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G936",
    gloss: "to reign",
  },
  βασιλευέτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G936",
    gloss: "to reign",
  },
  βασιλευόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G936",
    gloss: "to reign",
  },
  βασιλεῦσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G936",
    gloss: "to reign",
  },
  βασιλεύσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G936",
    gloss: "to reign",
  },
  βασιλεύσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G936",
    gloss: "to reign",
  },
  βασιλεύσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G936",
    gloss: "to reign",
  },
  ἐβασίλευσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G936",
    gloss: "to reign",
  },
  ἐβασίλευσας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G936",
    gloss: "to reign",
  },
  ἐβασιλεύσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G936",
    gloss: "to reign",
  },
  ἐβασίλευσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G936",
    gloss: "to reign",
  },
  βασιλικὴν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G937",
    gloss: "royal",
  },
  βασιλικῆς: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G937",
    gloss: "royal",
  },
  βασιλικὸν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G937",
    gloss: "royal",
  },
  βασιλικός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G937",
    gloss: "royal",
  },
  βασιλικὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G937",
    gloss: "royal",
  },
  βασίλισσα: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G938",
    gloss: "queen",
  },
  βασιλίσσης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G938",
    gloss: "queen",
  },
  βάσεις: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G939",
    gloss: "foot",
  },
  ἐβάσκανεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G940",
    gloss: "to bewitch",
  },
  βαστάζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G941",
    gloss: "to carry",
  },
  βαστάζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G941",
    gloss: "to carry",
  },
  βαστάζεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G941",
    gloss: "to carry",
  },
  βαστάζεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G941",
    gloss: "to carry",
  },
  βαστάζετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G941",
    gloss: "to carry",
  },
  βαστάζοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G941",
    gloss: "to carry",
  },
  βαστάζοντος: {
    parse:
      "V-PAP-GSN｜Verb, present, active, Participle, genitive, singular, neuter",
    GN: "G941",
    gloss: "to carry",
  },
  βαστάζω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G941",
    gloss: "to carry",
  },
  βαστάζων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G941",
    gloss: "to carry",
  },
  βαστάσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G941",
    gloss: "to carry",
  },
  βαστάσασά: {
    parse:
      "V-AAP-NSF｜Verb, aorist, active, Participle, nominative, singular, feminine",
    GN: "G941",
    gloss: "to carry",
  },
  βαστάσασιν: {
    parse:
      "V-AAP-DPM｜Verb, aorist, active, Participle, dative, plural, masculine",
    GN: "G941",
    gloss: "to carry",
  },
  βαστάσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G941",
    gloss: "to carry",
  },
  ἐβάσταζεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G941",
    gloss: "to carry",
  },
  ἐβαστάζετο: {
    parse: "V-IPI-3S｜Verb, imperfect, passive, indicative, third, singular",
    GN: "G941",
    gloss: "to carry",
  },
  Ἐβάστασαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G941",
    gloss: "to carry",
  },
  ἐβάστασας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G941",
    gloss: "to carry",
  },
  ἐβάστασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G941",
    gloss: "to carry",
  },
  βάτου: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G942",
    gloss: "thorn bush",
  },
  βάτῳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G942",
    gloss: "thorn bush",
  },
  βάτους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G943",
    gloss: "bath",
  },
  βάτραχοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G944",
    gloss: "frog",
  },
  βατταλογήσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G945",
    gloss: "to babble",
  },
  βδέλυγμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G946",
    gloss: "abomination",
  },
  βδελυγμάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G946",
    gloss: "abomination",
  },
  βδελυκτοὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G947",
    gloss: "abominable",
  },
  βδελυσσόμενος: {
    parse:
      "V-PNP-VSM｜Verb, present, Middle or Passive deponent, Participle, Vocative or nominative, singular, masculine",
    GN: "G948",
    gloss: "to abhor",
  },
  ἐβδελυγμένοις: {
    parse:
      "V-RMP-DPM｜Verb, perfect, middle, Participle, dative, plural, masculine",
    GN: "G948",
    gloss: "to abhor",
  },
  βεβαία: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G949",
    gloss: "firm",
  },
  βεβαίαν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G949",
    gloss: "firm",
  },
  βέβαιος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G949",
    gloss: "firm",
  },
  βεβαιότερον: {
    parse: "A-ASM-C｜Adjective, accusative, singular, masculine, Comparative",
    GN: "G949",
    gloss: "firm",
  },
  βεβαιούμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G950",
    gloss: "to confirm",
  },
  βεβαιοῦντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G950",
    gloss: "to confirm",
  },
  βεβαιοῦσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G950",
    gloss: "to confirm",
  },
  βεβαιῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G950",
    gloss: "to confirm",
  },
  βεβαιῶσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G950",
    gloss: "to confirm",
  },
  βεβαιώσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G951",
    gloss: "confirmation",
  },
  ἐβεβαιώθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G950",
    gloss: "to confirm",
  },
  βεβαίωσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G951",
    gloss: "confirmation",
  },
  βεβήλοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G952",
    gloss: "profane",
  },
  βέβηλος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G952",
    gloss: "profane",
  },
  βεβήλους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G952",
    gloss: "profane",
  },
  βεβηλοῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G953",
    gloss: "to profane",
  },
  βεβηλῶσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G953",
    gloss: "to profane",
  },
  Βεελζεβοὺλ: {
    parse: "N-DSM-T｜Noun, dative, singular, masculine, Title",
    GN: "G954",
    gloss: "Beelzebul",
  },
  Βελιάρ: {
    parse: "N-ASM-T｜Noun, accusative, singular, masculine, Title",
    GN: "G955",
    gloss: "Belial",
  },
  βέλη: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G956",
    gloss: "arrow",
  },
  βέλτιον: {
    parse: "A-ASN-C｜Adjective, accusative, singular, neuter, Comparative",
    GN: "G957",
    gloss: "better",
  },
  Βενιαμίν: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G958",
    gloss: "Benjamin",
  },
  Βενιαμὶν: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G958",
    gloss: "Benjamin",
  },
  Βερνίκη: {
    parse: "N-NSF-P｜Noun, nominative, singular, feminine, Person",
    GN: "G959",
    gloss: "Bernice",
  },
  Βερνίκης: {
    parse: "N-GSF-P｜Noun, genitive, singular, feminine, Person",
    GN: "G959",
    gloss: "Bernice",
  },
  Βεροίᾳ: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G960",
    gloss: "Berea",
  },
  Βέροιαν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G960",
    gloss: "Berea",
  },
  Βεροιαῖος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G961",
    gloss: "Berean",
  },
  Βηθανίᾳ: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G963",
    gloss: "Bethany",
  },
  Βηθανία: {
    parse: "N-NSF-L｜Noun, nominative, singular, feminine, Location",
    GN: "G963",
    gloss: "Bethany",
  },
  Βηθανίαν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G963",
    gloss: "Bethany",
  },
  Βηθανίας: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G963",
    gloss: "Bethany",
  },
  Βηθζαθά: {
    parse: "N-NSF-L｜Noun, nominative, singular, feminine, Location",
    GN: "G964",
    gloss: "Bethesda",
  },
  Βηθλέεμ: {
    parse:
      "N-VSF-L｜Noun, Vocative or nominative, singular, feminine, Location",
    GN: "G965",
    gloss: "Bethlehem",
  },
  Βηθσαϊδά: {
    parse:
      "N-VSF-L｜Noun, Vocative or nominative, singular, feminine, Location",
    GN: "G966",
    gloss: "Bethsaida",
  },
  Βηθσαϊδὰ: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G966",
    gloss: "Bethsaida",
  },
  Βηθσαϊδάν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G966",
    gloss: "Bethsaida",
  },
  Βηθφαγὴ: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G967",
    gloss: "Bethphage",
  },
  βῆμα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G968",
    gloss: "judgement seat",
  },
  βήματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G968",
    gloss: "judgement seat",
  },
  βήματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G968",
    gloss: "judgement seat",
  },
  βήρυλλος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G969",
    gloss: "beryl",
  },
  βίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G970",
    gloss: "force",
  },
  βίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G970",
    gloss: "force",
  },
  βιάζεται: {
    parse:
      "V-PEI-3S｜Verb, present, Either middle or passive, indicative, third, singular",
    GN: "G971",
    gloss: "to force",
  },
  βιαίας: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G972",
    gloss: "strong",
  },
  βιασταὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G973",
    gloss: "forceful person",
  },
  βιβλαρίδιον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G974",
    gloss: "little scroll",
  },
  βιβλία: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G975",
    gloss: "scroll",
  },
  βιβλίοις: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G975",
    gloss: "scroll",
  },
  βιβλίον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G975",
    gloss: "scroll",
  },
  βιβλίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G975",
    gloss: "scroll",
  },
  βιβλίῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G975",
    gloss: "scroll",
  },
  Βίβλος: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G976",
    gloss: "book",
  },
  βίβλου: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G976",
    gloss: "book",
  },
  βίβλους: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G976",
    gloss: "book",
  },
  βίβλῳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G976",
    gloss: "book",
  },
  βεβρωκόσιν: {
    parse:
      "V-RAP-DPM｜Verb, perfect, active, Participle, dative, plural, masculine",
    GN: "G977",
    gloss: "to eat",
  },
  Βιθυνίαν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G978",
    gloss: "Bithynia",
  },
  Βιθυνίας: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G978",
    gloss: "Bithynia",
  },
  βίον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G979",
    gloss: "life",
  },
  βίου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G979",
    gloss: "life",
  },
  βιῶσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G980",
    gloss: "to live",
  },
  βίωσίν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G981",
    gloss: "lifestyle",
  },
  βιωτικά: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G982",
    gloss: "of this life",
  },
  βιωτικὰ: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G982",
    gloss: "of this life",
  },
  βιωτικαῖς: {
    parse: "A-DPF｜Adjective, dative, plural, feminine",
    GN: "G982",
    gloss: "of this life",
  },
  βλαβεράς: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G983",
    gloss: "harmful",
  },
  βλάψαν: {
    parse:
      "V-AAP-NSN｜Verb, aorist, active, Participle, nominative, singular, neuter",
    GN: "G984",
    gloss: "to hurt",
  },
  βλάψῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G984",
    gloss: "to hurt",
  },
  βλαστᾷ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G985",
    gloss: "to sprout",
  },
  βλαστήσασα: {
    parse:
      "V-AAP-NSF｜Verb, aorist, active, Participle, nominative, singular, feminine",
    GN: "G985",
    gloss: "to sprout",
  },
  ἐβλάστησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G985",
    gloss: "to sprout",
  },
  Βλάστον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G986",
    gloss: "Blastus",
  },
  βλασφημεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G987",
    gloss: "to blaspheme",
  },
  βλασφημεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G987",
    gloss: "to blaspheme",
  },
  Βλασφημεῖς: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G987",
    gloss: "to blaspheme",
  },
  βλασφημείσθω: {
    parse: "V-PPM-3S｜Verb, present, passive, imperative, third, singular",
    GN: "G987",
    gloss: "to blaspheme",
  },
  βλασφημεῖται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G987",
    gloss: "to blaspheme",
  },
  βλασφημηθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G987",
    gloss: "to blaspheme",
  },
  βλασφημῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G987",
    gloss: "to blaspheme",
  },
  βλασφημήσαντι: {
    parse:
      "V-AAP-DSM｜Verb, aorist, active, Participle, dative, singular, masculine",
    GN: "G987",
    gloss: "to blaspheme",
  },
  βλασφημήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G987",
    gloss: "to blaspheme",
  },
  βλασφημήσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G987",
    gloss: "to blaspheme",
  },
  βλασφημῆται: {
    parse: "V-PPS-3S｜Verb, present, passive, subjunctive, third, singular",
    GN: "G987",
    gloss: "to blaspheme",
  },
  βλασφημοῦμαι: {
    parse: "V-PPI-1S｜Verb, present, passive, indicative, first, singular",
    GN: "G987",
    gloss: "to blaspheme",
  },
  βλασφημούμεθα: {
    parse: "V-PPI-1P｜Verb, present, passive, indicative, first, plural",
    GN: "G987",
    gloss: "to blaspheme",
  },
  βλασφημοῦντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G987",
    gloss: "to blaspheme",
  },
  βλασφημοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G987",
    gloss: "to blaspheme",
  },
  βλασφημούντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G987",
    gloss: "to blaspheme",
  },
  βλασφημοῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G987",
    gloss: "to blaspheme",
  },
  ἐβλασφήμει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G987",
    gloss: "to blaspheme",
  },
  ἐβλασφήμησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G987",
    gloss: "to blaspheme",
  },
  Ἐβλασφήμησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G987",
    gloss: "to blaspheme",
  },
  ἐβλασφήμουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G987",
    gloss: "to blaspheme",
  },
  βλασφημία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G988",
    gloss: "blasphemy",
  },
  βλασφημίαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G988",
    gloss: "blasphemy",
  },
  βλασφημίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G988",
    gloss: "blasphemy",
  },
  βλασφημίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G988",
    gloss: "blasphemy",
  },
  βλάσφημα: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G989",
    gloss: "blasphemous",
  },
  βλάσφημοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G989",
    gloss: "blasphemous",
  },
  βλάσφημον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G989",
    gloss: "blasphemous",
  },
  βλέμματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G990",
    gloss: "a look",
  },
  Βλέπε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G991",
    gloss: "to see",
  },
  βλέπει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G991",
    gloss: "to see",
  },
  βλέπειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G991",
    gloss: "to see",
  },
  βλέπεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G991",
    gloss: "to see",
  },
  Βλέπεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G991",
    gloss: "to see",
  },
  βλέπετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G991",
    gloss: "to see",
  },
  Βλέπετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G991",
    gloss: "to see",
  },
  βλεπέτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G991",
    gloss: "to see",
  },
  βλέπῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G991",
    gloss: "to see",
  },
  βλέπῃς: {
    parse: "V-PAS-2S｜Verb, present, active, subjunctive, second, singular",
    GN: "G991",
    gloss: "to see",
  },
  βλέπομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G991",
    gloss: "to see",
  },
  Βλέπομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G991",
    gloss: "to see",
  },
  βλεπόμενα: {
    parse:
      "V-PPP-NPN｜Verb, present, passive, Participle, nominative, plural, neuter",
    GN: "G991",
    gloss: "to see",
  },
  βλεπομένη: {
    parse:
      "V-PPP-NSF｜Verb, present, passive, Participle, nominative, singular, feminine",
    GN: "G991",
    gloss: "to see",
  },
  βλεπόμενον: {
    parse:
      "V-PPP-ASN｜Verb, present, passive, Participle, accusative, singular, neuter",
    GN: "G991",
    gloss: "to see",
  },
  βλεπομένων: {
    parse:
      "V-PPP-GPN｜Verb, present, passive, Participle, genitive, plural, neuter",
    GN: "G991",
    gloss: "to see",
  },
  βλέποντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G991",
    gloss: "to see",
  },
  βλέποντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G991",
    gloss: "to see",
  },
  βλέποντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G991",
    gloss: "to see",
  },
  βλεπόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G991",
    gloss: "to see",
  },
  βλέπουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G991",
    gloss: "to see",
  },
  βλέπουσι: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G991",
    gloss: "to see",
  },
  βλέπω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G991",
    gloss: "to see",
  },
  Βλέπω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G991",
    gloss: "to see",
  },
  βλέπων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G991",
    gloss: "to see",
  },
  βλέπωσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G991",
    gloss: "to see",
  },
  βλέψετε: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G991",
    gloss: "to see",
  },
  Βλέψον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G991",
    gloss: "to see",
  },
  ἔβλεπεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G991",
    gloss: "to see",
  },
  ἔβλεπον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G991",
    gloss: "to see",
  },
  ἔβλεψα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G991",
    gloss: "to see",
  },
  βλητέον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G992",
    gloss: "must be put",
  },
  Βοανηργές: {
    parse: "N-APM-P｜Noun, accusative, plural, masculine, Person",
    GN: "G993",
    gloss: "Boanerges",
  },
  βόησον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G994",
    gloss: "to cry out",
  },
  βοῶντα: {
    parse:
      "V-PAP-APN｜Verb, present, active, Participle, accusative, plural, neuter",
    GN: "G994",
    gloss: "to cry out",
  },
  βοῶντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G994",
    gloss: "to cry out",
  },
  βοῶντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G994",
    gloss: "to cry out",
  },
  βοώντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G994",
    gloss: "to cry out",
  },
  ἐβόησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G994",
    gloss: "to cry out",
  },
  βοαὶ: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G995",
    gloss: "outcry",
  },
  βοηθείαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G996",
    gloss: "help",
  },
  βοήθειαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G996",
    gloss: "help",
  },
  βοήθει: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G997",
    gloss: "to help",
  },
  βοηθεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G997",
    gloss: "to help",
  },
  βοηθῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G997",
    gloss: "to help",
  },
  βοήθησον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G997",
    gloss: "to help",
  },
  ἐβοήθησά: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G997",
    gloss: "to help",
  },
  ἐβοήθησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G997",
    gloss: "to help",
  },
  βοηθός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G998",
    gloss: "a helper",
  },
  βόθυνον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G999",
    gloss: "pit",
  },
  βολήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1000",
    gloss: "throwing",
  },
  βολίσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G1001",
    gloss: "to sound",
  },
  Βόες: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G1003",
    gloss: "Boaz",
  },
  Βόος: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G1003",
    gloss: "Boaz",
  },
  βορβόρου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G1004",
    gloss: "mire",
  },
  βορρᾶ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G1005",
    gloss: "the north",
  },
  Βόσκε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G1006",
    gloss: "to feed",
  },
  βόσκειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1006",
    gloss: "to feed",
  },
  βοσκομένη: {
    parse:
      "V-PPP-NSF｜Verb, present, passive, Participle, nominative, singular, feminine",
    GN: "G1006",
    gloss: "to feed",
  },
  βόσκοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1006",
    gloss: "to feed",
  },
  Βοσὸρ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G1007",
    gloss: "Beor",
  },
  βοτάνην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1008",
    gloss: "crop",
  },
  βότρυας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G1009",
    gloss: "bunch of grapes",
  },
  βουλευτής: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1010",
    gloss: "member of a council",
  },
  βουλευτὴς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1010",
    gloss: "member of a council",
  },
  βουλεύομαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G1011",
    gloss: "to plan",
  },
  βουλεύσεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G1011",
    gloss: "to plan",
  },
  ἐβουλεύοντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G1011",
    gloss: "to plan",
  },
  ἐβουλεύσαντο: {
    parse: "V-ADI-3P｜Verb, aorist, Middle deponent, indicative, third, plural",
    GN: "G1011",
    gloss: "to plan",
  },
  βουλὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G1012",
    gloss: "plan",
  },
  βουλῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1012",
    gloss: "plan",
  },
  βουλὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1012",
    gloss: "plan",
  },
  βουλὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1012",
    gloss: "plan",
  },
  βουλῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1012",
    gloss: "plan",
  },
  βούλημα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G1013",
    gloss: "plan",
  },
  βουλήματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G1013",
    gloss: "plan",
  },
  βουλήματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G1013",
    gloss: "plan",
  },
  βούλει: {
    parse:
      "V-PNI-2S｜Verb, present, Middle or Passive deponent, indicative, second, singular",
    GN: "G1014",
    gloss: "to plan",
  },
  βούλεσθε: {
    parse:
      "V-PNI-2P｜Verb, present, Middle or Passive deponent, indicative, second, plural",
    GN: "G1014",
    gloss: "to plan",
  },
  βούλεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G1014",
    gloss: "to plan",
  },
  βουληθεὶς: {
    parse:
      "V-AOP-NSM｜Verb, aorist, Passive deponent, Participle, nominative, singular, masculine",
    GN: "G1014",
    gloss: "to plan",
  },
  βουληθῇ: {
    parse:
      "V-AOS-3S｜Verb, aorist, Passive deponent, subjunctive, third, singular",
    GN: "G1014",
    gloss: "to plan",
  },
  βούληται: {
    parse:
      "V-PNS-3S｜Verb, present, Middle or Passive deponent, subjunctive, third, singular",
    GN: "G1014",
    gloss: "to plan",
  },
  βούλοιτο: {
    parse:
      "V-PNO-3S｜Verb, present, Middle or Passive deponent, Optative, third, singular",
    GN: "G1014",
    gloss: "to plan",
  },
  βούλομαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G1014",
    gloss: "to plan",
  },
  Βούλομαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G1014",
    gloss: "to plan",
  },
  βούλομαί: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G1014",
    gloss: "to plan",
  },
  βουλόμεθα: {
    parse:
      "V-PNI-1P｜Verb, present, Middle or Passive deponent, indicative, first, plural",
    GN: "G1014",
    gloss: "to plan",
  },
  βουλόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G1014",
    gloss: "to plan",
  },
  βουλόμενός: {
    parse:
      "V-PMP-NSM｜Verb, present, middle, Participle, nominative, singular, masculine",
    GN: "G1014",
    gloss: "to plan",
  },
  βουλόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G1014",
    gloss: "to plan",
  },
  βουλομένου: {
    parse:
      "V-PNP-GSM｜Verb, present, Middle or Passive deponent, Participle, genitive, singular, masculine",
    GN: "G1014",
    gloss: "to plan",
  },
  βουλομένους: {
    parse:
      "V-PNP-APM｜Verb, present, Middle or Passive deponent, Participle, accusative, plural, masculine",
    GN: "G1014",
    gloss: "to plan",
  },
  ἐβούλετο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G1014",
    gloss: "to plan",
  },
  ἐβουλήθη: {
    parse:
      "V-AOI-3S｜Verb, aorist, Passive deponent, indicative, third, singular",
    GN: "G1014",
    gloss: "to plan",
  },
  ἐβουλήθην: {
    parse:
      "V-AOI-1S｜Verb, aorist, Passive deponent, indicative, first, singular",
    GN: "G1014",
    gloss: "to plan",
  },
  ἐβουλόμην: {
    parse:
      "V-INI-1S｜Verb, imperfect, Middle or Passive deponent, indicative, first, singular",
    GN: "G1014",
    gloss: "to plan",
  },
  Ἐβουλόμην: {
    parse:
      "V-INI-1S｜Verb, imperfect, Middle or Passive deponent, indicative, first, singular",
    GN: "G1014",
    gloss: "to plan",
  },
  ἐβούλοντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G1014",
    gloss: "to plan",
  },
  βουνοῖς: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G1015",
    gloss: "hill",
  },
  βουνὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1015",
    gloss: "hill",
  },
  βόας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G1016",
    gloss: "ox",
  },
  βοῦν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1016",
    gloss: "ox",
  },
  Βοῦν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1016",
    gloss: "ox",
  },
  βοῦς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1016",
    gloss: "ox",
  },
  βοῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G1016",
    gloss: "ox",
  },
  βραβεῖον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G1017",
    gloss: "prize",
  },
  βραβευέτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G1018",
    gloss: "to rule",
  },
  βραδύνει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1019",
    gloss: "to delay",
  },
  βραδύνω: {
    parse: "V-PAS-1S｜Verb, present, active, subjunctive, first, singular",
    GN: "G1019",
    gloss: "to delay",
  },
  βραδυπλοοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1020",
    gloss: "to sail slowly",
  },
  βραδεῖς: {
    // or nominative
    parse: "A-VPM｜Adjective, Vocative, plural, masculine",
    GN: "G1021",
    gloss: "slow",
  },
  βραδὺς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1021",
    gloss: "slow",
  },
  βραδύτητα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1022",
    gloss: "slowness",
  },
  βραχίονι: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G1023",
    gloss: "arm",
  },
  βραχίονος: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G1023",
    gloss: "arm",
  },
  βραχίων: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1023",
    gloss: "arm",
  },
  βραχέων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G1024",
    gloss: "little",
  },
  βραχύ: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G1024",
    gloss: "little",
  },
  βραχὺ: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G1024",
    gloss: "little",
  },
  βρέφη: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G1025",
    gloss: "infant",
  },
  βρέφος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G1025",
    gloss: "infant",
  },
  βρέφους: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G1025",
    gloss: "infant",
  },
  βρέξαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1026",
    gloss: "to rain down",
  },
  βρέχει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1026",
    gloss: "to rain down",
  },
  βρέχειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1026",
    gloss: "to rain down",
  },
  βρέχῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G1026",
    gloss: "to rain down",
  },
  ἔβρεξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1026",
    gloss: "to rain down",
  },
  ἔβρεξέν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1026",
    gloss: "to rain down",
  },
  βρονταί: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G1027",
    gloss: "thunder",
  },
  βρονταὶ: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G1027",
    gloss: "thunder",
  },
  βροντὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1027",
    gloss: "thunder",
  },
  βροντῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1027",
    gloss: "thunder",
  },
  Βροντῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1027",
    gloss: "thunder",
  },
  βροντῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G1027",
    gloss: "thunder",
  },
  βροχὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1028",
    gloss: "rain",
  },
  βρόχον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1029",
    gloss: "restraint",
  },
  βρυγμὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1030",
    gloss: "gnashing",
  },
  ἔβρυχον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1031",
    gloss: "to gnash",
  },
  βρύει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1032",
    gloss: "to pour",
  },
  βρῶμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G1033",
    gloss: "food",
  },
  βρῶμά: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G1033",
    gloss: "food",
  },
  βρώμασιν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G1033",
    gloss: "food",
  },
  βρώματα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G1033",
    gloss: "food",
  },
  βρώματί: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G1033",
    gloss: "food",
  },
  βρώματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G1033",
    gloss: "food",
  },
  βρωμάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G1033",
    gloss: "food",
  },
  βρώσιμον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G1034",
    gloss: "edible",
  },
  βρώσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1035",
    gloss: "eating",
  },
  βρώσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1035",
    gloss: "eating",
  },
  βρῶσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1035",
    gloss: "eating",
  },
  βρῶσις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1035",
    gloss: "eating",
  },
  βυθίζεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G1036",
    gloss: "to sink",
  },
  βυθίζουσι: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1036",
    gloss: "to sink",
  },
  βυθίζουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1036",
    gloss: "to sink",
  },
  βυθῷ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G1037",
    gloss: "the deep",
  },
  βυρσεῖ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G1038",
    gloss: "tanner",
  },
  βυρσέως: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G1038",
    gloss: "tanner",
  },
  βύσσινον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G1039",
    gloss: "fine linen",
  },
  βυσσίνου: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G1039",
    gloss: "fine linen",
  },
  βύσσον: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1040",
    gloss: "fine linen",
  },
  βωμὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1041",
    gloss: "altar",
  },
  Γαββαθα: {
    parse: "N-ASN-L｜Noun, accusative, singular, neuter, Location",
    GN: "G1042",
    gloss: "Gabbatha",
  },
  Γαβριὴλ: {
    parse: "N-NSM-T｜Noun, nominative, singular, masculine, Title",
    GN: "G1043",
    gloss: "Gabriel",
  },
  γάγγραινα: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1044",
    gloss: "gangrene",
  },
  Γὰδ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G1045",
    gloss: "Gad",
  },
  Γαδαρηνῶν: {
    parse: "N-GPM-LG｜Noun, genitive, plural, masculine, Location Gentilic",
    GN: "G1046",
    gloss: "Gadara",
  },
  γάζης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1047",
    gloss: "treasury",
  },
  Γάζαν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G1048",
    gloss: "Gaza",
  },
  γαζοφυλάκιον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G1049",
    gloss: "treasury",
  },
  γαζοφυλακίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G1049",
    gloss: "treasury",
  },
  γαζοφυλακίῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G1049",
    gloss: "treasury",
  },
  Γάϊον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G1050",
    gloss: "Gaius",
  },
  Γάϊος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G1050",
    gloss: "Gaius",
  },
  Γαΐῳ: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G1050",
    gloss: "Gaius",
  },
  γάλα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G1051",
    gloss: "milk",
  },
  γάλακτος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G1051",
    gloss: "milk",
  },
  Γαλάται: {
    parse:
      "N-VPM-LG｜Noun, Vocative or nominative, plural, masculine, Location Gentilic",
    GN: "G1052",
    gloss: "Galatian",
  },
  Γαλατίαν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G1053",
    gloss: "Galatia",
  },
  Γαλατίας: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G1053",
    gloss: "Galatia",
  },
  Γαλατικὴν: {
    parse:
      "A-ASF-LG｜Adjective, accusative, singular, feminine, Location Gentilic",
    GN: "G1054",
    gloss: "Galatia",
  },
  γαλήνη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1055",
    gloss: "calm",
  },
  Γαλιλαίᾳ: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G1056",
    gloss: "(Sea of )Galilee",
  },
  Γαλιλαία: {
    parse: "N-NSF-L｜Noun, nominative, singular, feminine, Location",
    GN: "G1056",
    gloss: "(Sea of )Galilee",
  },
  Γαλιλαίαν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G1056",
    gloss: "(Sea of )Galilee",
  },
  Γαλιλαίας: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G1056",
    gloss: "(Sea of )Galilee",
  },
  Γαλιλαῖοι: {
    parse:
      "N-VPM-LG｜Noun, Vocative or nominative, plural, masculine, Location Gentilic",
    GN: "G1057",
    gloss: "Galilean",
  },
  Γαλιλαῖος: {
    parse: "N-NSM-LG｜Noun, nominative, singular, masculine, Location Gentilic",
    GN: "G1057",
    gloss: "Galilean",
  },
  Γαλιλαῖός: {
    parse: "N-NSM-LG｜Noun, nominative, singular, masculine, Location Gentilic",
    GN: "G1057",
    gloss: "Galilean",
  },
  Γαλιλαίου: {
    parse: "N-GSM-LG｜Noun, genitive, singular, masculine, Location Gentilic",
    GN: "G1057",
    gloss: "Galilean",
  },
  Γαλιλαίους: {
    parse: "N-APM-LG｜Noun, accusative, plural, masculine, Location Gentilic",
    GN: "G1057",
    gloss: "Galilean",
  },
  Γαλιλαίων: {
    parse: "N-GPM-LG｜Noun, genitive, plural, masculine, Location Gentilic",
    GN: "G1057",
    gloss: "Galilean",
  },
  Γαλλίων: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G1058",
    gloss: "Gallio",
  },
  Γαλλίωνι: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G1058",
    gloss: "Gallio",
  },
  Γαλλίωνος: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G1058",
    gloss: "Gallio",
  },
  Γαμαλιήλ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G1059",
    gloss: "Gamaliel",
  },
  γαμεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1060",
    gloss: "to marry",
  },
  γαμείτωσαν: {
    parse: "V-PAM-3P｜Verb, present, active, imperative, third, plural",
    GN: "G1060",
    gloss: "to marry",
  },
  γαμηθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G1060",
    gloss: "to marry",
  },
  γαμῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1060",
    gloss: "to marry",
  },
  γαμήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G1060",
    gloss: "to marry",
  },
  γαμήσασα: {
    parse:
      "V-AAP-NSF｜Verb, aorist, active, Participle, nominative, singular, feminine",
    GN: "G1060",
    gloss: "to marry",
  },
  γαμησάτωσαν: {
    parse: "V-AAM-3P｜Verb, aorist, active, imperative, third, plural",
    GN: "G1060",
    gloss: "to marry",
  },
  γαμήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G1060",
    gloss: "to marry",
  },
  γαμήσῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G1060",
    gloss: "to marry",
  },
  γαμοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1060",
    gloss: "to marry",
  },
  γαμοῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1060",
    gloss: "to marry",
  },
  γαμῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1060",
    gloss: "to marry",
  },
  γεγαμηκόσιν: {
    parse:
      "V-RAP-DPM｜Verb, perfect, active, Participle, dative, plural, masculine",
    GN: "G1060",
    gloss: "to marry",
  },
  γήμας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G1060",
    gloss: "to marry",
  },
  γήμῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G1060",
    gloss: "to marry",
  },
  ἐγάμησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1060",
    gloss: "to marry",
  },
  ἐγάμουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1060",
    gloss: "to marry",
  },
  ἔγημα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G1060",
    gloss: "to marry",
  },
  γαμίζονται: {
    parse: "V-PPI-3P｜Verb, present, passive, indicative, third, plural",
    GN: "G1061",
    gloss: "to give in marriage",
  },
  γαμίζοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1061",
    gloss: "to give in marriage",
  },
  γαμίζων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1061",
    gloss: "to give in marriage",
  },
  γαμίσκονται: {
    parse: "V-PPI-3P｜Verb, present, passive, indicative, third, plural",
    GN: "G1061",
    gloss: "to give in marriage",
  },
  ἐγαμίζοντο: {
    parse: "V-IPI-3P｜Verb, imperfect, passive, indicative, third, plural",
    GN: "G1061",
    gloss: "to give in marriage",
  },
  γάμον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1062",
    gloss: "wedding",
  },
  γάμος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1062",
    gloss: "wedding",
  },
  γάμου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G1062",
    gloss: "wedding",
  },
  γάμους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G1062",
    gloss: "wedding",
  },
  γάμων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G1062",
    gloss: "wedding",
  },
  γάρ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G1063",
    gloss: "for",
  },
  γὰρ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G1063",
    gloss: "for",
  },
  γαστέρες: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G1064",
    gloss: "belly",
  },
  γαστρὶ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1064",
    gloss: "belly",
  },
  γε: { parse: "PRT-N｜Particle, Negative", GN: "G1065", gloss: "indeed" },
  Γεδεών: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G1066",
    gloss: "Gideon",
  },
  γέενναν: {
    parse: "N-ASF-T｜Noun, accusative, singular, feminine, Title",
    GN: "G1067",
    gloss: "hell",
  },
  γεέννῃ: {
    parse: "N-DSF-T｜Noun, dative, singular, feminine, Title",
    GN: "G1067",
    gloss: "hell",
  },
  γεέννης: {
    parse: "N-GSF-T｜Noun, genitive, singular, feminine, Title",
    GN: "G1067",
    gloss: "hell",
  },
  Γεθσημανὶ: {
    parse: "N-ASN-L｜Noun, accusative, singular, neuter, Location",
    GN: "G1068",
    gloss: "Gethsemane",
  },
  Γεθσημανί: {
    parse: "N-NSN-L｜Noun, nominative, singular, neuter, Location",
    GN: "G1068",
    gloss: "Gethsemane",
  },
  γείτονας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G1069",
    gloss: "neighbour",
  },
  γείτονες: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G1069",
    gloss: "neighbour",
  },
  γελάσετε: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G1070",
    gloss: "to laugh",
  },
  γελῶντες: {
    // or nominative
    parse:
      "V-PAP-VPM｜Verb, present, active, Participle, Vocative, plural, masculine",
    GN: "G1070",
    gloss: "to laugh",
  },
  γέλως: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1071",
    gloss: "laughter",
  },
  γεμίζεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G1072",
    gloss: "to fill",
  },
  γεμίσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G1072",
    gloss: "to fill",
  },
  Γεμίσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G1072",
    gloss: "to fill",
  },
  γεμισθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G1072",
    gloss: "to fill",
  },
  ἐγέμισαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1072",
    gloss: "to fill",
  },
  ἐγέμισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1072",
    gloss: "to fill",
  },
  ἐγεμίσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G1072",
    gloss: "to fill",
  },
  γέμει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1073",
    gloss: "be full",
  },
  γέμον: {
    parse:
      "V-PAP-ASN｜Verb, present, active, Participle, accusative, singular, neuter",
    GN: "G1073",
    gloss: "be full",
  },
  γέμοντα: {
    parse:
      "V-PAP-NPN｜Verb, present, active, Participle, nominative, plural, neuter",
    GN: "G1073",
    gloss: "be full",
  },
  γεμόντων: {
    parse:
      "V-PAP-GPF｜Verb, present, active, Participle, genitive, plural, feminine",
    GN: "G1073",
    gloss: "be full",
  },
  γεμούσας: {
    parse:
      "V-PAP-APF｜Verb, present, active, Participle, accusative, plural, feminine",
    GN: "G1073",
    gloss: "be full",
  },
  γέμουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1073",
    gloss: "be full",
  },
  γενεᾷ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1074",
    gloss: "generation",
  },
  γενεὰ: {
    parse: "N-VSF｜Noun, Vocative or nominative, singular, feminine",
    GN: "G1074",
    gloss: "generation",
  },
  Γενεὰ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1074",
    gloss: "generation",
  },
  γενεαί: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G1074",
    gloss: "generation",
  },
  γενεαὶ: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G1074",
    gloss: "generation",
  },
  γενεαῖς: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G1074",
    gloss: "generation",
  },
  γενεὰν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1074",
    gloss: "generation",
  },
  γενεὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G1074",
    gloss: "generation",
  },
  γενεᾶς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1074",
    gloss: "generation",
  },
  γενεῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G1074",
    gloss: "generation",
  },
  γενεαλογούμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G1075",
    gloss: "to trace genealogy",
  },
  γενεαλογίαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G1076",
    gloss: "genealogy",
  },
  γενεαλογίας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G1076",
    gloss: "genealogy",
  },
  γενεσίοις: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G1077",
    gloss: "birthday",
  },
  γενέσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1078",
    gloss: "origin",
  },
  γενέσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1078",
    gloss: "origin",
  },
  γένεσις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1078",
    gloss: "origin",
  },
  γενετῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1079",
    gloss: "birth",
  },
  γεγέννηκά: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G1080",
    gloss: "to beget",
  },
  γεγέννημαι: {
    parse: "V-RPI-1S｜Verb, perfect, passive, indicative, first, singular",
    GN: "G1080",
    gloss: "to beget",
  },
  γεγεννήμεθα: {
    parse: "V-RPI-1P｜Verb, perfect, passive, indicative, first, plural",
    GN: "G1080",
    gloss: "to beget",
  },
  γεγεννημένα: {
    parse:
      "V-RPP-NPN｜Verb, perfect, passive, Participle, nominative, plural, neuter",
    GN: "G1080",
    gloss: "to beget",
  },
  γεγεννημένον: {
    parse:
      "V-RPP-NSN｜Verb, perfect, passive, Participle, nominative, singular, neuter",
    GN: "G1080",
    gloss: "to beget",
  },
  γεγεννημένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G1080",
    gloss: "to beget",
  },
  γεγεννημένου: {
    parse:
      "V-RPP-GSM｜Verb, perfect, passive, Participle, genitive, singular, masculine",
    GN: "G1080",
    gloss: "to beget",
  },
  γεγέννηται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G1080",
    gloss: "to beget",
  },
  γεννᾶται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G1080",
    gloss: "to beget",
  },
  γεννηθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G1080",
    gloss: "to beget",
  },
  γεννηθὲν: {
    parse:
      "V-APP-NSN｜Verb, aorist, passive, Participle, nominative, singular, neuter",
    GN: "G1080",
    gloss: "to beget",
  },
  γεννηθέντος: {
    parse:
      "V-APP-GSM｜Verb, aorist, passive, Participle, genitive, singular, masculine",
    GN: "G1080",
    gloss: "to beget",
  },
  γεννηθέντων: {
    parse:
      "V-APP-GPM｜Verb, aorist, passive, Participle, genitive, plural, masculine",
    GN: "G1080",
    gloss: "to beget",
  },
  γεννηθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G1080",
    gloss: "to beget",
  },
  γεννηθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G1080",
    gloss: "to beget",
  },
  γεννήσαντα: {
    parse:
      "V-AAP-ASM｜Verb, aorist, active, Participle, accusative, singular, masculine",
    GN: "G1080",
    gloss: "to beget",
  },
  γεννήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G1080",
    gloss: "to beget",
  },
  γεννήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G1080",
    gloss: "to beget",
  },
  γεννώμενον: {
    parse:
      "V-PPP-NSN｜Verb, present, passive, Participle, nominative, singular, neuter",
    GN: "G1080",
    gloss: "to beget",
  },
  γεννῶσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G1080",
    gloss: "to beget",
  },
  γεννῶσι: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1080",
    gloss: "to beget",
  },
  γεννῶσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1080",
    gloss: "to beget",
  },
  ἐγεννήθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G1080",
    gloss: "to beget",
  },
  ἐγεννήθημεν: {
    parse: "V-API-1P｜Verb, aorist, passive, indicative, first, plural",
    GN: "G1080",
    gloss: "to beget",
  },
  ἐγεννήθης: {
    parse: "V-API-2S｜Verb, aorist, passive, indicative, second, singular",
    GN: "G1080",
    gloss: "to beget",
  },
  ἐγεννήθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G1080",
    gloss: "to beget",
  },
  ἐγέννησα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G1080",
    gloss: "to beget",
  },
  ἐγέννησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1080",
    gloss: "to beget",
  },
  ἐγέννησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1080",
    gloss: "to beget",
  },
  γενήματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G1081",
    gloss: "fruit",
  },
  γενήματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G1081",
    gloss: "fruit",
  },
  γεννήματα: {
    // or nominative
    parse: "N-VPN｜Noun, Vocative, plural, neuter",
    GN: "G1081",
    gloss: "fruit",
  },
  Γεννήματα: {
    // or nominative
    parse: "N-VPN｜Noun, Vocative, plural, neuter",
    GN: "G1081",
    gloss: "fruit",
  },
  Γεννησαρέτ: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G1082",
    gloss: "Gennesaret",
  },
  Γεννησαρὲτ: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G1082",
    gloss: "Gennesaret",
  },
  γεννητοῖς: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G1084",
    gloss: "born",
  },
  γένει: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G1085",
    gloss: "kind",
  },
  γένη: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G1085",
    gloss: "kind",
  },
  γένος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G1085",
    gloss: "kind",
  },
  γένους: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G1085",
    gloss: "kind",
  },
  Γερασηνῶν: {
    parse: "N-GPM-LG｜Noun, genitive, plural, masculine, Location Gentilic",
    GN: "G1086",
    gloss: "Gergasene",
  },
  γερουσίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1087",
    gloss: "council of elders",
  },
  γέρων: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1088",
    gloss: "an old man",
  },
  γευσάμενος: {
    parse:
      "V-ADP-NSM｜Verb, aorist, Middle deponent, Participle, nominative, singular, masculine",
    GN: "G1089",
    gloss: "to taste",
  },
  γευσαμένους: {
    parse:
      "V-ADP-APM｜Verb, aorist, Middle deponent, Participle, accusative, plural, masculine",
    GN: "G1089",
    gloss: "to taste",
  },
  γεύσασθαι: {
    parse: "V-ADN｜Verb, aorist, Middle deponent, Infinitive",
    GN: "G1089",
    gloss: "to taste",
  },
  γεύσεταί: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G1089",
    gloss: "to taste",
  },
  γεύσῃ: {
    parse:
      "V-ADS-2S｜Verb, aorist, Middle deponent, subjunctive, second, singular",
    GN: "G1089",
    gloss: "to taste",
  },
  γεύσηται: {
    parse:
      "V-ADS-3S｜Verb, aorist, Middle deponent, subjunctive, third, singular",
    GN: "G1089",
    gloss: "to taste",
  },
  γεύσωνται: {
    parse:
      "V-ADS-3P｜Verb, aorist, Middle deponent, subjunctive, third, plural",
    GN: "G1089",
    gloss: "to taste",
  },
  ἐγεύσασθε: {
    parse:
      "V-ADI-2P｜Verb, aorist, Middle deponent, indicative, second, plural",
    GN: "G1089",
    gloss: "to taste",
  },
  ἐγεύσατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G1089",
    gloss: "to taste",
  },
  γεωργεῖται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G1090",
    gloss: "to farm",
  },
  γεώργιον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G1091",
    gloss: "farm field",
  },
  γεωργοὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G1092",
    gloss: "farmer",
  },
  γεωργοῖς: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G1092",
    gloss: "farmer",
  },
  γεωργὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1092",
    gloss: "farmer",
  },
  γεωργός: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1092",
    gloss: "farmer",
  },
  γεωργὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1092",
    gloss: "farmer",
  },
  γεωργούς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G1092",
    gloss: "farmer",
  },
  γεωργοὺς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G1092",
    gloss: "farmer",
  },
  γεωργῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G1092",
    gloss: "farmer",
  },
  γῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1093",
    gloss: "land, earth, planet",
  },
  γῆ: {
    parse: "N-VSF｜Noun, Vocative or nominative, singular, feminine",
    GN: "G1093",
    gloss: "land, earth, planet",
  },
  Γῆ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1093",
    gloss: "land, earth, planet",
  },
  γῆν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1093",
    gloss: "land, earth, planet",
  },
  γῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1093",
    gloss: "land, earth, planet",
  },
  γήρει: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G1094",
    gloss: "old age",
  },
  γηράσῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G1095",
    gloss: "to grow old",
  },
  γηράσκον: {
    parse:
      "V-PAP-NSN｜Verb, present, active, Participle, nominative, singular, neuter",
    GN: "G1095",
    gloss: "to grow old",
  },
  γεγενημένον: {
    parse:
      "V-RPP-ASN｜Verb, perfect, passive, Participle, accusative, singular, neuter",
    GN: "G1096",
    gloss: "to be",
  },
  γεγενῆσθαι: {
    parse: "V-RPN｜Verb, perfect, passive, Infinitive",
    GN: "G1096",
    gloss: "to be",
  },
  γέγονα: {
    parse: "V-2RAI-1S｜Verb, 2nd perfect, active, indicative, first, singular",
    GN: "G1096",
    gloss: "to be",
  },
  Γέγονα: {
    parse: "V-2RAI-1S｜Verb, 2nd perfect, active, indicative, first, singular",
    GN: "G1096",
    gloss: "to be",
  },
  γεγόναμεν: {
    parse: "V-2RAI-1P｜Verb, 2nd perfect, active, indicative, first, plural",
    GN: "G1096",
    gloss: "to be",
  },
  γέγοναν: {
    parse: "V-2RAI-3P｜Verb, 2nd perfect, active, indicative, third, plural",
    GN: "G1096",
    gloss: "to be",
  },
  Γέγοναν: {
    parse: "V-2RAI-3P｜Verb, 2nd perfect, active, indicative, third, plural",
    GN: "G1096",
    gloss: "to be",
  },
  γέγονας: {
    parse: "V-2RAI-2S｜Verb, 2nd perfect, active, indicative, second, singular",
    GN: "G1096",
    gloss: "to be",
  },
  γεγόνασι: {
    parse: "V-2RAI-3P｜Verb, 2nd perfect, active, indicative, third, plural",
    GN: "G1096",
    gloss: "to be",
  },
  γεγόνασιν: {
    parse: "V-2RAI-3P｜Verb, 2nd perfect, active, indicative, third, plural",
    GN: "G1096",
    gloss: "to be",
  },
  γεγόνατε: {
    parse: "V-2RAI-2P｜Verb, 2nd perfect, active, indicative, second, plural",
    GN: "G1096",
    gloss: "to be",
  },
  γεγόνει: {
    parse:
      "V-2LAI-3S｜Verb, 2nd pluperfect, active, indicative, third, singular",
    GN: "G1096",
    gloss: "to be",
  },
  γέγονεν: {
    parse: "V-2RAI-3S｜Verb, 2nd perfect, active, indicative, third, singular",
    GN: "G1096",
    gloss: "to be",
  },
  Γέγονεν: {
    parse: "V-2RAI-3S｜Verb, 2nd perfect, active, indicative, third, singular",
    GN: "G1096",
    gloss: "to be",
  },
  γεγονέναι: {
    parse: "V-2RAN｜Verb, 2nd perfect, active, Infinitive",
    GN: "G1096",
    gloss: "to be",
  },
  γεγονός: {
    parse:
      "V-2RAP-NSN｜Verb, 2nd perfect, active, Participle, nominative, singular, neuter",
    GN: "G1096",
    gloss: "to be",
  },
  γεγονὸς: {
    parse:
      "V-2RAP-ASN｜Verb, 2nd perfect, active, Participle, accusative, singular, neuter",
    GN: "G1096",
    gloss: "to be",
  },
  γεγονότας: {
    parse:
      "V-2RAP-APM｜Verb, 2nd perfect, active, Participle, accusative, plural, masculine",
    GN: "G1096",
    gloss: "to be",
  },
  γεγονότες: {
    parse:
      "V-2RAP-NPM｜Verb, 2nd perfect, active, Participle, nominative, plural, masculine",
    GN: "G1096",
    gloss: "to be",
  },
  γεγονότι: {
    parse:
      "V-2RAP-DSN｜Verb, 2nd perfect, active, Participle, dative, singular, neuter",
    GN: "G1096",
    gloss: "to be",
  },
  γεγονυῖα: {
    parse:
      "V-2RAP-NSF｜Verb, 2nd perfect, active, Participle, nominative, singular, feminine",
    GN: "G1096",
    gloss: "to be",
  },
  γεγονὼς: {
    parse:
      "V-2RAP-NSM｜Verb, 2nd perfect, active, Participle, nominative, singular, masculine",
    GN: "G1096",
    gloss: "to be",
  },
  γενέσθαι: {
    parse: "V-2ADN｜Verb, 2nd aorist, Middle deponent, Infinitive",
    GN: "G1096",
    gloss: "to be",
  },
  γενέσθω: {
    parse:
      "V-2ADM-3S｜Verb, 2nd aorist, Middle deponent, imperative, third, singular",
    GN: "G1096",
    gloss: "to be",
  },
  γενηθέντας: {
    parse:
      "V-AOP-APM｜Verb, aorist, Passive deponent, Participle, accusative, plural, masculine",
    GN: "G1096",
    gloss: "to be",
  },
  γενηθέντες: {
    parse:
      "V-AOP-NPM｜Verb, aorist, Passive deponent, Participle, nominative, plural, masculine",
    GN: "G1096",
    gloss: "to be",
  },
  γενηθέντων: {
    parse:
      "V-AOP-GPN｜Verb, aorist, Passive deponent, Participle, genitive, plural, neuter",
    GN: "G1096",
    gloss: "to be",
  },
  γενηθῆναι: {
    parse: "V-AON｜Verb, aorist, Passive deponent, Infinitive",
    GN: "G1096",
    gloss: "to be",
  },
  γενήθητε: {
    parse:
      "V-AOM-2P｜Verb, aorist, Passive deponent, imperative, second, plural",
    GN: "G1096",
    gloss: "to be",
  },
  γενηθήτω: {
    parse:
      "V-AOM-3S｜Verb, aorist, Passive deponent, imperative, third, singular",
    GN: "G1096",
    gloss: "to be",
  },
  Γενηθήτω: {
    parse:
      "V-AOM-3S｜Verb, aorist, Passive deponent, imperative, third, singular",
    GN: "G1096",
    gloss: "to be",
  },
  γενηθῶμεν: {
    parse:
      "V-AOS-1P｜Verb, aorist, Passive deponent, subjunctive, first, plural",
    GN: "G1096",
    gloss: "to be",
  },
  γενήσεσθε: {
    parse:
      "V-FDI-2P｜Verb, future, Middle deponent, indicative, second, plural",
    GN: "G1096",
    gloss: "to be",
  },
  γενήσεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G1096",
    gloss: "to be",
  },
  γένησθε: {
    parse:
      "V-2ADS-2P｜Verb, 2nd aorist, Middle deponent, subjunctive, second, plural",
    GN: "G1096",
    gloss: "to be",
  },
  γενησόμενον: {
    parse:
      "V-FDP-ASN｜Verb, future, Middle deponent, Participle, accusative, singular, neuter",
    GN: "G1096",
    gloss: "to be",
  },
  γενήσονται: {
    parse: "V-FDI-3P｜Verb, future, Middle deponent, indicative, third, plural",
    GN: "G1096",
    gloss: "to be",
  },
  γένηται: {
    parse:
      "V-2ADS-3S｜Verb, 2nd aorist, Middle deponent, subjunctive, third, singular",
    GN: "G1096",
    gloss: "to be",
  },
  γένηταί: {
    parse:
      "V-2ADS-3S｜Verb, 2nd aorist, Middle deponent, subjunctive, third, singular",
    GN: "G1096",
    gloss: "to be",
  },
  γένοιτο: {
    parse:
      "V-2ADO-3S｜Verb, 2nd aorist, Middle deponent, Optative, third, singular",
    GN: "G1096",
    gloss: "to be",
  },
  γένοιτό: {
    parse:
      "V-2ADO-3S｜Verb, 2nd aorist, Middle deponent, Optative, third, singular",
    GN: "G1096",
    gloss: "to be",
  },
  γενόμενα: {
    parse:
      "V-2ADP-APN｜Verb, 2nd aorist, Middle deponent, Participle, accusative, plural, neuter",
    GN: "G1096",
    gloss: "to be",
  },
  γενόμεναι: {
    parse:
      "V-2ADP-NPF｜Verb, 2nd aorist, Middle deponent, Participle, nominative, plural, feminine",
    GN: "G1096",
    gloss: "to be",
  },
  γενομένην: {
    parse:
      "V-2ADP-ASF｜Verb, 2nd aorist, Middle deponent, Participle, accusative, singular, feminine",
    GN: "G1096",
    gloss: "to be",
  },
  γενομένης: {
    parse:
      "V-2ADP-GSF｜Verb, 2nd aorist, Middle deponent, Participle, genitive, singular, feminine",
    GN: "G1096",
    gloss: "to be",
  },
  Γενομένης: {
    parse:
      "V-2ADP-GSF｜Verb, 2nd aorist, Middle deponent, Participle, genitive, singular, feminine",
    GN: "G1096",
    gloss: "to be",
  },
  γενόμενοι: {
    parse:
      "V-2ADP-NPM｜Verb, 2nd aorist, Middle deponent, Participle, nominative, plural, masculine",
    GN: "G1096",
    gloss: "to be",
  },
  γενομένοις: {
    parse:
      "V-2ADP-DPN｜Verb, 2nd aorist, Middle deponent, Participle, dative, plural, neuter",
    GN: "G1096",
    gloss: "to be",
  },
  γενόμενον: {
    parse:
      "V-2ADP-ASN｜Verb, 2nd aorist, Middle deponent, Participle, accusative, singular, neuter",
    GN: "G1096",
    gloss: "to be",
  },
  γενόμενος: {
    parse:
      "V-2ADP-NSM｜Verb, 2nd aorist, Middle deponent, Participle, nominative, singular, masculine",
    GN: "G1096",
    gloss: "to be",
  },
  γενομένου: {
    parse:
      "V-2ADP-GSN｜Verb, 2nd aorist, Middle deponent, Participle, genitive, singular, neuter",
    GN: "G1096",
    gloss: "to be",
  },
  γενομένων: {
    parse:
      "V-2ADP-GPN｜Verb, 2nd aorist, Middle deponent, Participle, genitive, plural, neuter",
    GN: "G1096",
    gloss: "to be",
  },
  Γενομένων: {
    parse:
      "V-2ADP-GPM｜Verb, 2nd aorist, Middle deponent, Participle, genitive, plural, masculine",
    GN: "G1096",
    gloss: "to be",
  },
  γένωμαι: {
    parse:
      "V-2ADS-1S｜Verb, 2nd aorist, Middle deponent, subjunctive, first, singular",
    GN: "G1096",
    gloss: "to be",
  },
  γενώμεθα: {
    parse:
      "V-2ADS-1P｜Verb, 2nd aorist, Middle deponent, subjunctive, first, plural",
    GN: "G1096",
    gloss: "to be",
  },
  γένωνται: {
    parse:
      "V-2ADS-3P｜Verb, 2nd aorist, Middle deponent, subjunctive, third, plural",
    GN: "G1096",
    gloss: "to be",
  },
  γίνεσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G1096",
    gloss: "to be",
  },
  γίνεσθε: {
    parse:
      "V-PNM-2P｜Verb, present, Middle or Passive deponent, imperative, second, plural",
    GN: "G1096",
    gloss: "to be",
  },
  Γίνεσθε: {
    parse:
      "V-PNM-2P｜Verb, present, Middle or Passive deponent, imperative, second, plural",
    GN: "G1096",
    gloss: "to be",
  },
  γινέσθω: {
    parse:
      "V-PNM-3S｜Verb, present, Middle or Passive deponent, imperative, third, singular",
    GN: "G1096",
    gloss: "to be",
  },
  γίνεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G1096",
    gloss: "to be",
  },
  γινόμενα: {
    parse:
      "V-PNP-APN｜Verb, present, Middle or Passive deponent, Participle, accusative, plural, neuter",
    GN: "G1096",
    gloss: "to be",
  },
  γινόμεναι: {
    parse:
      "V-PNP-NPF｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, feminine",
    GN: "G1096",
    gloss: "to be",
  },
  γινομένας: {
    parse:
      "V-PNP-APF｜Verb, present, Middle or Passive deponent, Participle, accusative, plural, feminine",
    GN: "G1096",
    gloss: "to be",
  },
  γινομένῃ: {
    parse:
      "V-PNP-DSF｜Verb, present, Middle or Passive deponent, Participle, dative, singular, feminine",
    GN: "G1096",
    gloss: "to be",
  },
  γινομένη: {
    parse:
      "V-PNP-NSF｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, feminine",
    GN: "G1096",
    gloss: "to be",
  },
  γινομένης: {
    parse:
      "V-PNP-GSF｜Verb, present, Middle or Passive deponent, Participle, genitive, singular, feminine",
    GN: "G1096",
    gloss: "to be",
  },
  γινόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G1096",
    gloss: "to be",
  },
  γινομένοις: {
    parse:
      "V-PNP-DPN｜Verb, present, Middle or Passive deponent, Participle, dative, plural, neuter",
    GN: "G1096",
    gloss: "to be",
  },
  γινόμενον: {
    parse:
      "V-PNP-NSN｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, neuter",
    GN: "G1096",
    gloss: "to be",
  },
  γινομένου: {
    parse:
      "V-PNP-GSN｜Verb, present, Middle or Passive deponent, Participle, genitive, singular, neuter",
    GN: "G1096",
    gloss: "to be",
  },
  γινομένων: {
    parse:
      "V-PNP-GPN｜Verb, present, Middle or Passive deponent, Participle, genitive, plural, neuter",
    GN: "G1096",
    gloss: "to be",
  },
  γίνου: {
    parse:
      "V-PNM-2S｜Verb, present, Middle or Passive deponent, imperative, second, singular",
    GN: "G1096",
    gloss: "to be",
  },
  γινώμεθα: {
    parse:
      "V-PNS-1P｜Verb, present, Middle or Passive deponent, subjunctive, first, plural",
    GN: "G1096",
    gloss: "to be",
  },
  γίνωνται: {
    parse:
      "V-PNS-3P｜Verb, present, Middle or Passive deponent, subjunctive, third, plural",
    GN: "G1096",
    gloss: "to be",
  },
  ἐγεγόνει: {
    parse:
      "V-2LAI-3S｜Verb, 2nd pluperfect, active, indicative, third, singular",
    GN: "G1096",
    gloss: "to be",
  },
  ἐγένεσθε: {
    parse:
      "V-2ADI-2P｜Verb, 2nd aorist, Middle deponent, indicative, second, plural",
    GN: "G1096",
    gloss: "to be",
  },
  ἐγένετο: {
    parse:
      "V-2ADI-3S｜Verb, 2nd aorist, Middle deponent, indicative, third, singular",
    GN: "G1096",
    gloss: "to be",
  },
  Ἐγένετο: {
    parse:
      "V-2ADI-3S｜Verb, 2nd aorist, Middle deponent, indicative, third, singular",
    GN: "G1096",
    gloss: "to be",
  },
  ἐγενήθη: {
    parse:
      "V-2AOI-3S｜Verb, 2nd aorist, Passive deponent, indicative, third, singular",
    GN: "G1096",
    gloss: "to be",
  },
  ἐγενήθημεν: {
    parse:
      "V-2AOI-1P｜Verb, 2nd aorist, Passive deponent, indicative, first, plural",
    GN: "G1096",
    gloss: "to be",
  },
  ἐγενήθην: {
    parse:
      "V-2AOI-1S｜Verb, 2nd aorist, Passive deponent, indicative, first, singular",
    GN: "G1096",
    gloss: "to be",
  },
  ἐγενήθησαν: {
    parse:
      "V-2AOI-3P｜Verb, 2nd aorist, Passive deponent, indicative, third, plural",
    GN: "G1096",
    gloss: "to be",
  },
  ἐγενήθησάν: {
    parse:
      "V-2AOI-3P｜Verb, 2nd aorist, Passive deponent, indicative, third, plural",
    GN: "G1096",
    gloss: "to be",
  },
  ἐγενήθητε: {
    parse:
      "V-2AOI-2P｜Verb, 2nd aorist, Passive deponent, indicative, second, plural",
    GN: "G1096",
    gloss: "to be",
  },
  ἐγενόμην: {
    parse:
      "V-2ADI-1S｜Verb, 2nd aorist, Middle deponent, indicative, first, singular",
    GN: "G1096",
    gloss: "to be",
  },
  ἐγένοντο: {
    parse:
      "V-2ADI-3P｜Verb, 2nd aorist, Middle deponent, indicative, third, plural",
    GN: "G1096",
    gloss: "to be",
  },
  Ἐγένοντο: {
    parse:
      "V-2ADI-3P｜Verb, 2nd aorist, Middle deponent, indicative, third, plural",
    GN: "G1096",
    gloss: "to be",
  },
  ἐγένου: {
    parse:
      "V-2ADI-2S｜Verb, 2nd aorist, Middle deponent, indicative, second, singular",
    GN: "G1096",
    gloss: "to be",
  },
  ἐγίνετο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G1096",
    gloss: "to be",
  },
  Ἐγίνετο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G1096",
    gloss: "to be",
  },
  γίνωσκε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G1097",
    gloss: "to know",
  },
  γινώσκει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1097",
    gloss: "to know",
  },
  γινώσκειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1097",
    gloss: "to know",
  },
  Γινώσκειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1097",
    gloss: "to know",
  },
  γινώσκεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G1097",
    gloss: "to know",
  },
  γινώσκεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G1097",
    gloss: "to know",
  },
  γινώσκετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G1097",
    gloss: "to know",
  },
  Γινώσκετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G1097",
    gloss: "to know",
  },
  γινωσκέτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G1097",
    gloss: "to know",
  },
  γινώσκῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G1097",
    gloss: "to know",
  },
  γινώσκητε: {
    parse: "V-PAS-2P｜Verb, present, active, subjunctive, second, plural",
    GN: "G1097",
    gloss: "to know",
  },
  γινώσκομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G1097",
    gloss: "to know",
  },
  γινωσκομένη: {
    parse:
      "V-PPP-NSF｜Verb, present, passive, Participle, nominative, singular, feminine",
    GN: "G1097",
    gloss: "to know",
  },
  γινώσκοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1097",
    gloss: "to know",
  },
  γινώσκουσί: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1097",
    gloss: "to know",
  },
  γινώσκουσίν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1097",
    gloss: "to know",
  },
  γινώσκουσιν: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G1097",
    gloss: "to know",
  },
  γινώσκω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G1097",
    gloss: "to know",
  },
  γινώσκωμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G1097",
    gloss: "to know",
  },
  γινώσκων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1097",
    gloss: "to know",
  },
  γινώσκωσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G1097",
    gloss: "to know",
  },
  γνοῖ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G1097",
    gloss: "to know",
  },
  γνόντα: {
    parse:
      "V-2AAP-ASM｜Verb, 2nd aorist, active, Participle, accusative, singular, masculine",
    GN: "G1097",
    gloss: "to know",
  },
  γνόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G1097",
    gloss: "to know",
  },
  γνοὺς: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G1097",
    gloss: "to know",
  },
  Γνοὺς: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G1097",
    gloss: "to know",
  },
  γνῶ: {
    parse: "V-2AAS-1S｜Verb, 2nd aorist, active, subjunctive, first, singular",
    GN: "G1097",
    gloss: "to know",
  },
  γνῷ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G1097",
    gloss: "to know",
  },
  Γνῶθι: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G1097",
    gloss: "to know",
  },
  γνῶναι: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G1097",
    gloss: "to know",
  },
  γνῶναί: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G1097",
    gloss: "to know",
  },
  γνῷς: {
    parse: "V-2AAS-2S｜Verb, 2nd aorist, active, subjunctive, second, singular",
    GN: "G1097",
    gloss: "to know",
  },
  γνώσεσθε: {
    parse:
      "V-FDI-2P｜Verb, future, Middle deponent, indicative, second, plural",
    GN: "G1097",
    gloss: "to know",
  },
  γνώσεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G1097",
    gloss: "to know",
  },
  γνώσῃ: {
    parse:
      "V-FDI-2S｜Verb, future, Middle deponent, indicative, second, singular",
    GN: "G1097",
    gloss: "to know",
  },
  γνωσθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G1097",
    gloss: "to know",
  },
  γνωσθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G1097",
    gloss: "to know",
  },
  γνωσθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G1097",
    gloss: "to know",
  },
  γνωσθήτω: {
    parse: "V-APM-3S｜Verb, aorist, passive, imperative, third, singular",
    GN: "G1097",
    gloss: "to know",
  },
  γνῶσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1108",
    gloss: "knowledge",
  },
  γνώσομαι: {
    parse:
      "V-FDI-1S｜Verb, future, Middle deponent, indicative, first, singular",
    GN: "G1097",
    gloss: "to know",
  },
  γνωσόμεθα: {
    parse: "V-FDI-1P｜Verb, future, Middle deponent, indicative, first, plural",
    GN: "G1097",
    gloss: "to know",
  },
  γνώσονται: {
    parse: "V-FDI-3P｜Verb, future, Middle deponent, indicative, third, plural",
    GN: "G1097",
    gloss: "to know",
  },
  γνῶτε: {
    parse: "V-2AAS-2P｜Verb, 2nd aorist, active, subjunctive, second, plural",
    GN: "G1097",
    gloss: "to know",
  },
  γνώτω: {
    parse: "V-2AAM-3S｜Verb, 2nd aorist, active, imperative, third, singular",
    GN: "G1097",
    gloss: "to know",
  },
  ἐγίνωσκεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1097",
    gloss: "to know",
  },
  ἐγίνωσκον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1097",
    gloss: "to know",
  },
  ἔγνω: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G1097",
    gloss: "to know",
  },
  Ἔγνω: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G1097",
    gloss: "to know",
  },
  ἔγνωκα: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G1097",
    gloss: "to know",
  },
  Ἔγνωκα: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G1097",
    gloss: "to know",
  },
  ἐγνώκαμεν: {
    parse: "V-RAI-1P｜Verb, perfect, active, indicative, first, plural",
    GN: "G1097",
    gloss: "to know",
  },
  ἔγνωκαν: {
    parse: "V-RAI-3P｜Verb, perfect, active, indicative, third, plural",
    GN: "G1097",
    gloss: "to know",
  },
  ἔγνωκάς: {
    parse: "V-RAI-2S｜Verb, perfect, active, indicative, second, singular",
    GN: "G1097",
    gloss: "to know",
  },
  ἐγνώκατε: {
    parse: "V-RAI-2P｜Verb, perfect, active, indicative, second, plural",
    GN: "G1097",
    gloss: "to know",
  },
  ἐγνώκατέ: {
    parse: "V-RAI-2P｜Verb, perfect, active, indicative, second, plural",
    GN: "G1097",
    gloss: "to know",
  },
  ἐγνώκειτε: {
    parse: "V-LAI-2P｜Verb, pluperfect, active, indicative, second, plural",
    GN: "G1097",
    gloss: "to know",
  },
  ἔγνωκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G1097",
    gloss: "to know",
  },
  ἐγνωκέναι: {
    parse: "V-RAN｜Verb, perfect, active, Infinitive",
    GN: "G1097",
    gloss: "to know",
  },
  ἐγνωκότες: {
    parse:
      "V-RAP-NPM｜Verb, perfect, active, Participle, nominative, plural, masculine",
    GN: "G1097",
    gloss: "to know",
  },
  ἔγνων: {
    parse: "V-2AAI-1S｜Verb, 2nd aorist, active, indicative, first, singular",
    GN: "G1097",
    gloss: "to know",
  },
  ἔγνως: {
    parse: "V-2AAI-2S｜Verb, 2nd aorist, active, indicative, second, singular",
    GN: "G1097",
    gloss: "to know",
  },
  ἔγνωσαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G1097",
    gloss: "to know",
  },
  ἐγνώσθη: {
    parse: "V-2API-3S｜Verb, 2nd aorist, passive, indicative, third, singular",
    GN: "G1097",
    gloss: "to know",
  },
  ἔγνωσται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G1097",
    gloss: "to know",
  },
  Γλεύκους: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G1098",
    gloss: "sweet wine",
  },
  γλυκὺ: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G1099",
    gloss: "sweet",
  },
  γλυκύ: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G1099",
    gloss: "sweet",
  },
  γλῶσσα: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1100",
    gloss: "tongue",
  },
  γλῶσσά: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1100",
    gloss: "tongue",
  },
  γλῶσσαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G1100",
    gloss: "tongue",
  },
  γλώσσαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G1100",
    gloss: "tongue",
  },
  γλῶσσαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1100",
    gloss: "tongue",
  },
  γλῶσσάν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1100",
    gloss: "tongue",
  },
  γλώσσας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G1100",
    gloss: "tongue",
  },
  γλώσσῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1100",
    gloss: "tongue",
  },
  γλώσσης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1100",
    gloss: "tongue",
  },
  γλωσσῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G1100",
    gloss: "tongue",
  },
  γλωσσόκομον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G1101",
    gloss: "moneybag",
  },
  γναφεὺς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1102",
    gloss: "one who bleaches",
  },
  γνήσιε: {
    // or nominative
    parse: "A-VSM｜Adjective, Vocative, singular, masculine",
    GN: "G1103",
    gloss: "genuine",
  },
  γνήσιον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G1103",
    gloss: "genuine",
  },
  γνησίῳ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G1103",
    gloss: "genuine",
  },
  γνησίως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1104",
    gloss: "genuinely",
  },
  γνόφῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G1105",
    gloss: "darkness",
  },
  γνώμῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1106",
    gloss: "resolution",
  },
  γνώμην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1106",
    gloss: "resolution",
  },
  γνώμης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1106",
    gloss: "resolution",
  },
  γνωριζέσθω: {
    parse: "V-PPM-3S｜Verb, present, passive, imperative, third, singular",
    GN: "G1107",
    gloss: "to make known",
  },
  Γνωρίζομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G1107",
    gloss: "to make known",
  },
  γνωρίζω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G1107",
    gloss: "to make known",
  },
  Γνωρίζω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G1107",
    gloss: "to make known",
  },
  γνωρίσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1107",
    gloss: "to make known",
  },
  γνωρίσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G1107",
    gloss: "to make known",
  },
  γνωρίσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G1107",
    gloss: "to make known",
  },
  γνωρίσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G1107",
    gloss: "to make known",
  },
  γνωρισθέντος: {
    parse:
      "V-APP-GSN｜Verb, aorist, passive, Participle, genitive, singular, neuter",
    GN: "G1107",
    gloss: "to make known",
  },
  γνωρισθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G1107",
    gloss: "to make known",
  },
  γνωρίσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G1107",
    gloss: "to make known",
  },
  γνωρίσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G1107",
    gloss: "to make known",
  },
  ἐγνώρισα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G1107",
    gloss: "to make known",
  },
  ἐγνωρίσαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G1107",
    gloss: "to make known",
  },
  ἐγνώρισαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1107",
    gloss: "to make known",
  },
  ἐγνώρισάς: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G1107",
    gloss: "to make known",
  },
  ἐγνώρισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1107",
    gloss: "to make known",
  },
  ἐγνωρίσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G1107",
    gloss: "to make known",
  },
  γνώσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1108",
    gloss: "knowledge",
  },
  γνώσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1108",
    gloss: "knowledge",
  },
  γνῶσις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1108",
    gloss: "knowledge",
  },
  γνώστην: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1109",
    gloss: "expert in",
  },
  γνωστὰ: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G1110",
    gloss: "acquainted with",
  },
  γνωστοὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G1110",
    gloss: "acquainted with",
  },
  γνωστοῖς: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G1110",
    gloss: "acquainted with",
  },
  γνωστὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G1110",
    gloss: "acquainted with",
  },
  γνωστὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1110",
    gloss: "acquainted with",
  },
  γογγύζετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G1111",
    gloss: "to murmur",
  },
  γογγύζοντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G1111",
    gloss: "to murmur",
  },
  γογγύζουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1111",
    gloss: "to murmur",
  },
  ἐγόγγυζον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1111",
    gloss: "to murmur",
  },
  Ἐγόγγυζον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1111",
    gloss: "to murmur",
  },
  ἐγόγγυσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1111",
    gloss: "to murmur",
  },
  γογγυσμὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1112",
    gloss: "murmuring",
  },
  γογγυσμοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G1112",
    gloss: "murmuring",
  },
  γογγυσμῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G1112",
    gloss: "murmuring",
  },
  γογγυσταί: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G1113",
    gloss: "a murmurer",
  },
  γόητες: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G1114",
    gloss: "imposter",
  },
  Γολγοθᾶ: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G1115",
    gloss: "Golgotha",
  },
  Γολγοθα: {
    parse: "N-NSF-L｜Noun, nominative, singular, feminine, Location",
    GN: "G1115",
    gloss: "Golgotha",
  },
  Γολγοθᾶν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G1115",
    gloss: "Golgotha",
  },
  Γόμορρα: {
    parse: "N-NSF-L｜Noun, nominative, singular, feminine, Location",
    GN: "G1116",
    gloss: "Gomorrah",
  },
  Γομόρρας: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G1116",
    gloss: "Gomorrah",
  },
  Γομόρρων: {
    parse: "N-GPN-L｜Noun, genitive, plural, neuter, Location",
    GN: "G1116",
    gloss: "Gomorrah",
  },
  γόμον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1117",
    gloss: "cargo",
  },
  γονεῖς: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G1118",
    gloss: "parent",
  },
  γονεῦσιν: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G1118",
    gloss: "parent",
  },
  γονέων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G1118",
    gloss: "parent",
  },
  γόνασιν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G1119",
    gloss: "a knee",
  },
  γόνατα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G1119",
    gloss: "a knee",
  },
  γόνατά: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G1119",
    gloss: "a knee",
  },
  γόνυ: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G1119",
    gloss: "a knee",
  },
  γονυπετήσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G1120",
    gloss: "to kneel",
  },
  γονυπετήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G1120",
    gloss: "to kneel",
  },
  γονυπετῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1120",
    gloss: "to kneel",
  },
  γράμμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G1121",
    gloss: "something written",
  },
  γράμμασιν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G1121",
    gloss: "something written",
  },
  γράμματα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G1121",
    gloss: "something written",
  },
  γράμματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G1121",
    gloss: "something written",
  },
  γράμματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G1121",
    gloss: "something written",
  },
  γραμματεῖς: {
    parse: "N-VPM｜Noun, Vocative or nominative, plural, masculine",
    GN: "G1122",
    gloss: "scribe",
  },
  γραμματεύς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1122",
    gloss: "scribe",
  },
  γραμματεὺς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1122",
    gloss: "scribe",
  },
  γραμματεῦσιν: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G1122",
    gloss: "scribe",
  },
  γραμματέων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G1122",
    gloss: "scribe",
  },
  γραπτὸν: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G1123",
    gloss: "written",
  },
  γραφαί: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G1124",
    gloss: "a writing",
  },
  γραφαὶ: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G1124",
    gloss: "a writing",
  },
  γραφαῖς: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G1124",
    gloss: "a writing",
  },
  γραφάς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G1124",
    gloss: "a writing",
  },
  γραφὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G1124",
    gloss: "a writing",
  },
  γραφῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1124",
    gloss: "a writing",
  },
  γραφή: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1124",
    gloss: "a writing",
  },
  γραφὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1124",
    gloss: "a writing",
  },
  γραφήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1124",
    gloss: "a writing",
  },
  γραφὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1124",
    gloss: "a writing",
  },
  γραφῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1124",
    gloss: "a writing",
  },
  γραφῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G1124",
    gloss: "a writing",
  },
  γεγραμμένα: {
    parse:
      "V-RPP-NPN｜Verb, perfect, passive, Participle, nominative, plural, neuter",
    GN: "G1125",
    gloss: "to write",
  },
  γεγραμμένας: {
    parse:
      "V-RPP-APF｜Verb, perfect, passive, Participle, accusative, plural, feminine",
    GN: "G1125",
    gloss: "to write",
  },
  γεγραμμένην: {
    parse:
      "V-RPP-ASF｜Verb, perfect, passive, Participle, accusative, singular, feminine",
    GN: "G1125",
    gloss: "to write",
  },
  γεγραμμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G1125",
    gloss: "to write",
  },
  γεγραμμένοις: {
    parse:
      "V-RPP-DPN｜Verb, perfect, passive, Participle, dative, plural, neuter",
    GN: "G1125",
    gloss: "to write",
  },
  γεγραμμένον: {
    parse:
      "V-RPP-NSN｜Verb, perfect, passive, Participle, nominative, singular, neuter",
    GN: "G1125",
    gloss: "to write",
  },
  γεγραμμένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G1125",
    gloss: "to write",
  },
  γεγραμμένων: {
    parse:
      "V-RPP-GPN｜Verb, perfect, passive, Participle, genitive, plural, neuter",
    GN: "G1125",
    gloss: "to write",
  },
  γέγραπται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G1125",
    gloss: "to write",
  },
  Γέγραπται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G1125",
    gloss: "to write",
  },
  γέγραφα: {
    parse: "V-2RAI-1S｜Verb, 2nd perfect, active, indicative, first, singular",
    GN: "G1125",
    gloss: "to write",
  },
  γράφε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G1125",
    gloss: "to write",
  },
  γράφει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1125",
    gloss: "to write",
  },
  γράφειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1125",
    gloss: "to write",
  },
  γράφεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G1125",
    gloss: "to write",
  },
  γράφηται: {
    parse: "V-PPS-3S｜Verb, present, passive, subjunctive, third, singular",
    GN: "G1125",
    gloss: "to write",
  },
  γράφομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G1125",
    gloss: "to write",
  },
  γραφόμενα: {
    parse:
      "V-PPP-APN｜Verb, present, passive, Participle, accusative, plural, neuter",
    GN: "G1125",
    gloss: "to write",
  },
  γράφω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G1125",
    gloss: "to write",
  },
  Γράφω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G1125",
    gloss: "to write",
  },
  γράφων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1125",
    gloss: "to write",
  },
  γράψαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1125",
    gloss: "to write",
  },
  γράψαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G1125",
    gloss: "to write",
  },
  γράψας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G1125",
    gloss: "to write",
  },
  γράψῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G1125",
    gloss: "to write",
  },
  γράψον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G1125",
    gloss: "to write",
  },
  Γράψον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G1125",
    gloss: "to write",
  },
  γράψω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G1125",
    gloss: "to write",
  },
  ἔγραφεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1125",
    gloss: "to write",
  },
  ἐγράφη: {
    parse: "V-2API-3S｜Verb, 2nd aorist, passive, indicative, third, singular",
    GN: "G1125",
    gloss: "to write",
  },
  ἔγραψα: {
    parse: "V-2AAI-1S｜Verb, 2nd aorist, active, indicative, first, singular",
    GN: "G1125",
    gloss: "to write",
  },
  Ἔγραψα: {
    parse: "V-2AAI-1S｜Verb, 2nd aorist, active, indicative, first, singular",
    GN: "G1125",
    gloss: "to write",
  },
  ἔγραψά: {
    parse: "V-2AAI-1S｜Verb, 2nd aorist, active, indicative, first, singular",
    GN: "G1125",
    gloss: "to write",
  },
  Ἔγραψά: {
    parse: "V-2AAI-1S｜Verb, 2nd aorist, active, indicative, first, singular",
    GN: "G1125",
    gloss: "to write",
  },
  ἔγραψαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G1125",
    gloss: "to write",
  },
  ἐγράψατε: {
    parse: "V-2AAI-2P｜Verb, 2nd aorist, active, indicative, second, plural",
    GN: "G1125",
    gloss: "to write",
  },
  ἔγραψεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G1125",
    gloss: "to write",
  },
  γραώδεις: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G1126",
    gloss: "old wives’ tale",
  },
  γρηγορεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G1127",
    gloss: "to keep watch",
  },
  Γρηγορεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G1127",
    gloss: "to keep watch",
  },
  γρηγορῇ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G1127",
    gloss: "to keep watch",
  },
  γρηγορῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1127",
    gloss: "to keep watch",
  },
  γρηγορήσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G1127",
    gloss: "to keep watch",
  },
  γρηγορήσῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G1127",
    gloss: "to keep watch",
  },
  γρηγοροῦντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G1127",
    gloss: "to keep watch",
  },
  γρηγοροῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1127",
    gloss: "to keep watch",
  },
  γρηγορῶμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G1127",
    gloss: "to keep watch",
  },
  γρηγορῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1127",
    gloss: "to keep watch",
  },
  ἐγρηγόρησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1127",
    gloss: "to keep watch",
  },
  γεγυμνασμένα: {
    parse:
      "V-RPP-APN｜Verb, perfect, passive, Participle, accusative, plural, neuter",
    GN: "G1128",
    gloss: "to train",
  },
  γεγυμνασμένην: {
    parse:
      "V-RPP-ASF｜Verb, perfect, passive, Participle, accusative, singular, feminine",
    GN: "G1128",
    gloss: "to train",
  },
  γεγυμνασμένοις: {
    parse:
      "V-RPP-DPM｜Verb, perfect, passive, Participle, dative, plural, masculine",
    GN: "G1128",
    gloss: "to train",
  },
  γύμναζε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G1128",
    gloss: "to train",
  },
  γυμνασία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1129",
    gloss: "training",
  },
  γυμνιτεύομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G1130",
    gloss: "be naked",
  },
  γυμνὰ: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G1131",
    gloss: "naked",
  },
  γυμνήν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G1131",
    gloss: "naked",
  },
  γυμνοὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G1131",
    gloss: "naked",
  },
  γυμνὸν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G1131",
    gloss: "naked",
  },
  γυμνός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1131",
    gloss: "naked",
  },
  γυμνὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1131",
    gloss: "naked",
  },
  γυμνοῦ: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G1131",
    gloss: "naked",
  },
  γυμνοὺς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G1131",
    gloss: "naked",
  },
  γυμνότης: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1132",
    gloss: "nakedness",
  },
  γυμνότητι: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1132",
    gloss: "nakedness",
  },
  γυμνότητός: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1132",
    gloss: "nakedness",
  },
  γυναικάρια: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G1133",
    gloss: "weak-willed woman",
  },
  γυναικείῳ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G1134",
    gloss: "feminine",
  },
  γύναι: {
    // or nominative
    parse: "N-VSF｜Noun, Vocative, singular, feminine",
    GN: "G1135",
    gloss: "wife",
  },
  Γύναι: {
    // or nominative
    parse: "N-VSF｜Noun, Vocative, singular, feminine",
    GN: "G1135",
    gloss: "wife",
  },
  γυναῖκα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1135",
    gloss: "wife",
  },
  Γυναῖκα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1135",
    gloss: "wife",
  },
  γυναῖκά: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1135",
    gloss: "wife",
  },
  γυναῖκας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G1135",
    gloss: "wife",
  },
  γυναῖκες: {
    parse: "N-VPF｜Noun, Vocative or nominative, plural, feminine",
    GN: "G1135",
    gloss: "wife",
  },
  γυναῖκές: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G1135",
    gloss: "wife",
  },
  γυναικί: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1135",
    gloss: "wife",
  },
  γυναικὶ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1135",
    gloss: "wife",
  },
  γυναικός: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1135",
    gloss: "wife",
  },
  γυναικὸς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1135",
    gloss: "wife",
  },
  γυναικῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G1135",
    gloss: "wife",
  },
  γυναιξίν: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G1135",
    gloss: "wife",
  },
  γυναιξὶ: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G1135",
    gloss: "wife",
  },
  γυναιξὶν: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G1135",
    gloss: "wife",
  },
  γυνή: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1135",
    gloss: "wife",
  },
  γυνὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1135",
    gloss: "wife",
  },
  Γυνὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1135",
    gloss: "wife",
  },
  Γὼγ: {
    parse: "N-ASM-L｜Noun, accusative, singular, masculine, Location",
    GN: "G1136",
    gloss: "Gog",
  },
  γωνίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1137",
    gloss: "corner",
  },
  γωνίαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G1137",
    gloss: "corner",
  },
  γωνίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1137",
    gloss: "corner",
  },
  Δαυὶδ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G1138",
    gloss: "David",
  },
  Δαυίδ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G1138",
    gloss: "David",
  },
  δαιμονίζεται: {
    parse:
      "V-PNI-3S｜Verb, present, middle or passive, indicative, third, singular",
    GN: "G1139",
    gloss: "be demonised",
  },
  δαιμονιζόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, middle or passive, Participle, nominative, plural, masculine",
    GN: "G1139",
    gloss: "be demonised",
  },
  δαιμονιζόμενον: {
    parse:
      "V-PNP-ASM｜Verb, present, middle or passive, Participle, accusative, singular, masculine",
    GN: "G1139",
    gloss: "be demonised",
  },
  δαιμονιζόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, middle or passive, Participle, nominative, singular, masculine",
    GN: "G1139",
    gloss: "be demonised",
  },
  δαιμονιζομένου: {
    parse:
      "V-PNP-GSM｜Verb, present, middle or passive, Participle, genitive, singular, masculine",
    GN: "G1139",
    gloss: "be demonised",
  },
  δαιμονιζομένους: {
    parse:
      "V-PNP-APM｜Verb, present, middle or passive, Participle, accusative, plural, masculine",
    GN: "G1139",
    gloss: "be demonised",
  },
  δαιμονιζομένῳ: {
    parse:
      "V-PNP-DSM｜Verb, present, middle or passive, Participle, dative, singular, masculine",
    GN: "G1139",
    gloss: "be demonised",
  },
  δαιμονιζομένων: {
    parse:
      "V-PNP-GPM｜Verb, present, middle or passive, Participle, genitive, plural, masculine",
    GN: "G1139",
    gloss: "be demonised",
  },
  δαιμονισθείς: {
    parse:
      "V-AOP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G1139",
    gloss: "be demonised",
  },
  δαιμονισθεὶς: {
    parse:
      "V-AOP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G1139",
    gloss: "be demonised",
  },
  δαιμόνια: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G1140",
    gloss: "demon",
  },
  δαιμονίοις: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G1140",
    gloss: "demon",
  },
  δαιμόνιον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G1140",
    gloss: "demon",
  },
  Δαιμόνιον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G1140",
    gloss: "demon",
  },
  δαιμονίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G1140",
    gloss: "demon",
  },
  δαιμονίων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G1140",
    gloss: "demon",
  },
  δαιμονιώδης: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G1141",
    gloss: "demonic",
  },
  δαίμονες: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G1142",
    gloss: "demon",
  },
  δάκνετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G1143",
    gloss: "to bite",
  },
  δάκρυον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G1144",
    gloss: "teardrop",
  },
  δάκρυσιν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G1144",
    gloss: "teardrop",
  },
  δακρύων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G1144",
    gloss: "teardrop",
  },
  ἐδάκρυσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1145",
    gloss: "to weep",
  },
  δακτύλιον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1146",
    gloss: "ring",
  },
  δάκτυλόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1147",
    gloss: "finger",
  },
  δακτύλου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G1147",
    gloss: "finger",
  },
  δακτύλους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G1147",
    gloss: "finger",
  },
  δακτύλῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G1147",
    gloss: "finger",
  },
  δακτύλων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G1147",
    gloss: "finger",
  },
  Δαλμανουθά: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G1148",
    gloss: "Dalmanutha",
  },
  Δαλματίαν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G1149",
    gloss: "Dalmatia",
  },
  δαμάζεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G1150",
    gloss: "to tame",
  },
  δαμάσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1150",
    gloss: "to tame",
  },
  δεδάμασται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G1150",
    gloss: "to tame",
  },
  δαμάλεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1151",
    gloss: "heifer",
  },
  Δάμαρις: {
    parse: "N-NSF-P｜Noun, nominative, singular, feminine, Person",
    GN: "G1152",
    gloss: "Damaris",
  },
  Δαμασκηνῶν: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G1153",
    gloss: "Damascus",
  },
  Δαμασκόν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G1154",
    gloss: "Damascus",
  },
  Δαμασκὸν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G1154",
    gloss: "Damascus",
  },
  Δαμασκῷ: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G1154",
    gloss: "Damascus",
  },
  δανίζετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G1155",
    gloss: "to lend/borrow",
  },
  δανίζουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1155",
    gloss: "to lend/borrow",
  },
  δανίσασθαι: {
    parse: "V-AMN｜Verb, aorist, middle, Infinitive",
    GN: "G1155",
    gloss: "to lend/borrow",
  },
  δανίσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G1155",
    gloss: "to lend/borrow",
  },
  δάνειον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G1156",
    gloss: "debt",
  },
  δανιστῇ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G1157",
    gloss: "moneylender",
  },
  Δανιὴλ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G1158",
    gloss: "Daniel",
  },
  δαπανήσαντος: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G1159",
    gloss: "to spend",
  },
  δαπανήσασα: {
    parse:
      "V-AAP-NSF｜Verb, aorist, active, Participle, nominative, singular, feminine",
    GN: "G1159",
    gloss: "to spend",
  },
  δαπανήσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G1159",
    gloss: "to spend",
  },
  δαπάνησον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G1159",
    gloss: "to spend",
  },
  δαπανήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G1159",
    gloss: "to spend",
  },
  δαπάνην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1160",
    gloss: "cost",
  },
  δʼ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G1161",
    gloss: "then",
  },
  δέ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G1161",
    gloss: "but, and, then",
  },
  δὲ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G1161",
    gloss: "but, and, then",
  },
  δεήσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1162",
    gloss: "petition",
  },
  δεήσεις: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G1162",
    gloss: "petition",
  },
  δεήσεσιν: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G1162",
    gloss: "petition",
  },
  δεήσεσίν: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G1162",
    gloss: "petition",
  },
  δεήσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1162",
    gloss: "petition",
  },
  δέησιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1162",
    gloss: "petition",
  },
  δέησις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1162",
    gloss: "petition",
  },
  δέησίς: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1162",
    gloss: "petition",
  },
  δεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1163",
    gloss: "be necessary",
  },
  δέον: {
    parse:
      "V-PAP-NSN｜Verb, present, active, Participle, nominative, singular, neuter",
    GN: "G1163",
    gloss: "be necessary",
  },
  δέοντα: {
    parse:
      "V-PAP-APN｜Verb, present, active, Participle, accusative, plural, neuter",
    GN: "G1163",
    gloss: "be necessary",
  },
  ἔδει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1163",
    gloss: "be necessary",
  },
  Ἔδει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1163",
    gloss: "be necessary",
  },
  δεῖγμα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G1164",
    gloss: "example",
  },
  δειγματίσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1165",
    gloss: "to disgrace",
  },
  ἐδειγμάτισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1165",
    gloss: "to disgrace",
  },
  δεικνύειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1166",
    gloss: "to show",
  },
  δεικνύεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G1166",
    gloss: "to show",
  },
  δείκνυμι: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G1166",
    gloss: "to show",
  },
  δεικνύοντός: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G1166",
    gloss: "to show",
  },
  δείκνυσιν: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1166",
    gloss: "to show",
  },
  δεῖξαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1166",
    gloss: "to show",
  },
  Δείξατέ: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G1166",
    gloss: "to show",
  },
  δειξάτω: {
    parse: "V-AAM-3S｜Verb, aorist, active, imperative, third, singular",
    GN: "G1166",
    gloss: "to show",
  },
  δείξει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G1166",
    gloss: "to show",
  },
  δεῖξον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G1166",
    gloss: "to show",
  },
  Δεῖξον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G1166",
    gloss: "to show",
  },
  δεῖξόν: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G1166",
    gloss: "to show",
  },
  δείξω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G1166",
    gloss: "to show",
  },
  δειχθέντα: {
    parse:
      "V-APP-ASM｜Verb, aorist, passive, Participle, accusative, singular, masculine",
    GN: "G1166",
    gloss: "to show",
  },
  ἔδειξα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G1166",
    gloss: "to show",
  },
  ἔδειξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1166",
    gloss: "to show",
  },
  ἔδειξέν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1166",
    gloss: "to show",
  },
  δειλίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1167",
    gloss: "timidity",
  },
  δειλιάτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G1168",
    gloss: "be timid",
  },
  δειλοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G1169",
    gloss: "timid",
  },
  δειλοῖς: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G1169",
    gloss: "timid",
  },
  δεῖνα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1170",
    gloss: "a certain one",
  },
  δεινῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1171",
    gloss: "terribly",
  },
  δειπνῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1172",
    gloss: "to dine",
  },
  δειπνήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G1172",
    gloss: "to dine",
  },
  δείπνοις: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G1173",
    gloss: "dinner",
  },
  δεῖπνον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G1173",
    gloss: "dinner",
  },
  δείπνου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G1173",
    gloss: "dinner",
  },
  δείπνῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G1173",
    gloss: "dinner",
  },
  δεισιδαιμονεστέρους: {
    parse: "A-APM-C｜Adjective, accusative, plural, masculine, Comparative",
    GN: "G1174",
    gloss: "religious",
  },
  δεισιδαιμονίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1175",
    gloss: "religion",
  },
  δέκα: {
    parse:
      "A-NPN-NUI｜Adjective, nominative, plural, neuter, NUmerical Indiclinable (A)",
    GN: "G1176",
    gloss: "ten",
  },
  δεκαοκτώ: {
    parse:
      "A-APN-NUI｜Adjective, accusative, plural, neuter, NUmerical Indiclinable (A)",
    GN: "G1176",
    gloss: "ten",
  },
  δεκαοκτὼ: {
    parse:
      "A-NPM-NUI｜Adjective, nominative, plural, masculine, NUmerical Indiclinable (A)",
    GN: "G1176",
    gloss: "ten",
  },
  δεκαπέντε: {
    parse:
      "A-GPM-NUI｜Adjective, genitive, plural, masculine, NUmerical Indiclinable (A)",
    GN: "G1178",
    gloss: "fifteen",
  },
  Δεκαπόλει: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G1179",
    gloss: "Decapolis",
  },
  Δεκαπόλεως: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G1179",
    gloss: "Decapolis",
  },
  δεκατέσσαρες: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G1180",
    gloss: "fourteen",
  },
  δεκατεσσάρων: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G1180",
    gloss: "fourteen",
  },
  δεκάτας: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G1182",
    gloss: "tenth",
  },
  δεκάτη: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G1182",
    gloss: "tenth",
  },
  δεκάτην: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G1182",
    gloss: "tenth",
  },
  δέκατον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G1182",
    gloss: "tenth",
  },
  δέκατος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1182",
    gloss: "tenth",
  },
  δεδεκάτωκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G1183",
    gloss: "to tithe",
  },
  δεδεκάτωται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G1183",
    gloss: "to tithe",
  },
  δεκτήν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G1184",
    gloss: "acceptable",
  },
  δεκτόν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G1184",
    gloss: "acceptable",
  },
  δεκτός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1184",
    gloss: "acceptable",
  },
  δεκτὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1184",
    gloss: "acceptable",
  },
  δεκτῷ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G1184",
    gloss: "acceptable",
  },
  δελεαζόμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G1185",
    gloss: "to entice",
  },
  δελεάζοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1185",
    gloss: "to entice",
  },
  δελεάζουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1185",
    gloss: "to entice",
  },
  δένδρα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G1186",
    gloss: "tree",
  },
  δένδρον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G1186",
    gloss: "tree",
  },
  δένδρων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G1186",
    gloss: "tree",
  },
  δεξιολάβους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G1187",
    gloss: "slinger",
  },
  δεξιὰ: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G1188",
    gloss: "right",
  },
  δεξιᾷ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G1188",
    gloss: "right",
  },
  δεξιά: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G1188",
    gloss: "right",
  },
  δεξιὰν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G1188",
    gloss: "right",
  },
  δεξιὰς: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G1188",
    gloss: "right",
  },
  δεξιᾶς: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G1188",
    gloss: "right",
  },
  δεξιοῖς: {
    parse: "A-DPN｜Adjective, dative, plural, neuter",
    GN: "G1188",
    gloss: "right",
  },
  δεξιὸν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G1188",
    gloss: "right",
  },
  δεξιόν: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G1188",
    gloss: "right",
  },
  δεξιὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1188",
    gloss: "right",
  },
  δεξιῶν: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G1188",
    gloss: "right",
  },
  δεηθέντων: {
    parse:
      "V-AOP-GPM｜Verb, aorist, Passive deponent, Participle, genitive, plural, masculine",
    GN: "G1189",
    gloss: "to pray",
  },
  δεήθητι: {
    parse:
      "V-AOM-2S｜Verb, aorist, Passive deponent, imperative, second, singular",
    GN: "G1189",
    gloss: "to pray",
  },
  δέομαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G1189",
    gloss: "to pray",
  },
  δέομαί: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G1189",
    gloss: "to pray",
  },
  Δέομαί: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G1189",
    gloss: "to pray",
  },
  δεόμεθα: {
    parse:
      "V-PNI-1P｜Verb, present, Middle or Passive deponent, indicative, first, plural",
    GN: "G1189",
    gloss: "to pray",
  },
  δεόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G1189",
    gloss: "to pray",
  },
  δεόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G1189",
    gloss: "to pray",
  },
  ἐδεήθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G1189",
    gloss: "to pray",
  },
  ἐδεήθην: {
    parse: "V-API-1S｜Verb, aorist, passive, indicative, first, singular",
    GN: "G1189",
    gloss: "to pray",
  },
  ἐδεῖτο: {
    parse: "V-IMI-3S｜Verb, imperfect, middle, indicative, third, singular",
    GN: "G1189",
    gloss: "to pray",
  },
  Δερβαῖος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1190",
    gloss: "Derbe",
  },
  Δέρβην: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G1191",
    gloss: "Derbe",
  },
  δέρμασιν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G1192",
    gloss: "leather",
  },
  δερματίνην: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G1193",
    gloss: "made of leather",
  },
  δαρήσεσθε: {
    parse: "V-2FPI-2P｜Verb, 2nd future, passive, indicative, second, plural",
    GN: "G1194",
    gloss: "to beat up",
  },
  δαρήσεται: {
    parse: "V-2FPI-3S｜Verb, 2nd future, passive, indicative, third, singular",
    GN: "G1194",
    gloss: "to beat up",
  },
  δείραντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G1194",
    gloss: "to beat up",
  },
  Δείραντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G1194",
    gloss: "to beat up",
  },
  δέρει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1194",
    gloss: "to beat up",
  },
  δέρεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G1194",
    gloss: "to beat up",
  },
  δέροντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1194",
    gloss: "to beat up",
  },
  δέρων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1194",
    gloss: "to beat up",
  },
  ἔδειραν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1194",
    gloss: "to beat up",
  },
  δεσμεύουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1195",
    gloss: "to bind",
  },
  δεσμεύων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1195",
    gloss: "to bind",
  },
  ἐδεσμεύετο: {
    parse: "V-IPI-3S｜Verb, imperfect, passive, indicative, third, singular",
    GN: "G1195",
    gloss: "to bind",
  },
  δέσμας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G1197",
    gloss: "bundle",
  },
  δέσμιοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G1198",
    gloss: "prisoner",
  },
  δεσμίοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G1198",
    gloss: "prisoner",
  },
  δέσμιον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1198",
    gloss: "prisoner",
  },
  δέσμιος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1198",
    gloss: "prisoner",
  },
  δεσμίους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G1198",
    gloss: "prisoner",
  },
  δεσμίων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G1198",
    gloss: "prisoner",
  },
  δεσμὰ: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G1199",
    gloss: "chain",
  },
  δεσμοῖς: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G1199",
    gloss: "chain",
  },
  δεσμὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1199",
    gloss: "chain",
  },
  δεσμοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G1199",
    gloss: "chain",
  },
  δεσμούς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G1199",
    gloss: "chain",
  },
  δεσμῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G1199",
    gloss: "chain",
  },
  δεσμοφύλακι: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G1200",
    gloss: "jailer",
  },
  δεσμοφύλαξ: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1200",
    gloss: "jailer",
  },
  δεσμωτήριον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G1201",
    gloss: "prison",
  },
  δεσμωτηρίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G1201",
    gloss: "prison",
  },
  δεσμωτηρίῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G1201",
    gloss: "prison",
  },
  δεσμώτας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G1202",
    gloss: "prisoner",
  },
  δέσποτα: {
    //or nominative
    parse: "N-VSM｜Noun, Vocative, singular, masculine",
    GN: "G1203",
    gloss: "master",
  },
  Δέσποτα: {
    //or nominative
    parse: "N-VSM｜Noun, Vocative, singular, masculine",
    GN: "G1203",
    gloss: "master",
  },
  δεσπόταις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G1203",
    gloss: "master",
  },
  δεσπότας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G1203",
    gloss: "master",
  },
  δεσπότῃ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G1203",
    gloss: "master",
  },
  δεσπότην: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1203",
    gloss: "master",
  },
  δεσπότης: {
    parse: "N-VSM｜Noun, Vocative or nominative, singular, masculine",
    GN: "G1203",
    gloss: "master",
  },
  δεῦρο: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1204",
    gloss: "come",
  },
  Δεῦρο: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1204",
    gloss: "come",
  },
  δεῦτε: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1205",
    gloss: "come",
  },
  Δεῦτε: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1205",
    gloss: "come",
  },
  δευτεραῖοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G1206",
    gloss: "the next day",
  },
  δευτέρᾳ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G1208",
    gloss: "secondly",
  },
  δευτέρα: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G1208",
    gloss: "secondly",
  },
  Δευτέρα: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G1208",
    gloss: "secondly",
  },
  δευτέραν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G1208",
    gloss: "secondly",
  },
  δευτέρας: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G1208",
    gloss: "secondly",
  },
  δεύτερον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G1208",
    gloss: "secondly",
  },
  δεύτερος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1208",
    gloss: "secondly",
  },
  δεύτερός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1208",
    gloss: "secondly",
  },
  δευτέρου: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G1208",
    gloss: "secondly",
  },
  δευτέρῳ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G1208",
    gloss: "secondly",
  },
  δέδεκται: {
    parse:
      "V-RNI-3S｜Verb, perfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G1209",
    gloss: "to receive",
  },
  δέξαι: {
    parse:
      "V-ADM-2S｜Verb, aorist, Middle deponent, imperative, second, singular",
    GN: "G1209",
    gloss: "to receive",
  },
  Δέξαι: {
    parse:
      "V-ADM-2S｜Verb, aorist, Middle deponent, imperative, second, singular",
    GN: "G1209",
    gloss: "to receive",
  },
  δεξαμένη: {
    parse:
      "V-ADP-NSF｜Verb, aorist, Middle deponent, Participle, nominative, singular, feminine",
    GN: "G1209",
    gloss: "to receive",
  },
  δεξάμενοι: {
    parse:
      "V-ADP-NPM｜Verb, aorist, Middle deponent, Participle, nominative, plural, masculine",
    GN: "G1209",
    gloss: "to receive",
  },
  δεξάμενος: {
    parse:
      "V-ADP-NSM｜Verb, aorist, Middle deponent, Participle, nominative, singular, masculine",
    GN: "G1209",
    gloss: "to receive",
  },
  δέξασθαι: {
    parse: "V-ADN｜Verb, aorist, Middle deponent, Infinitive",
    GN: "G1209",
    gloss: "to receive",
  },
  δέξασθε: {
    parse:
      "V-ADM-2P｜Verb, aorist, Middle deponent, imperative, second, plural",
    GN: "G1209",
    gloss: "to receive",
  },
  δέξασθέ: {
    parse: "V-AMM-2P｜Verb, aorist, middle, imperative, second, plural",
    GN: "G1209",
    gloss: "to receive",
  },
  δέξηται: {
    parse:
      "V-ADS-3S｜Verb, aorist, Middle deponent, subjunctive, third, singular",
    GN: "G1209",
    gloss: "to receive",
  },
  δέξωνται: {
    parse:
      "V-ADS-3P｜Verb, aorist, Middle deponent, subjunctive, third, plural",
    GN: "G1209",
    gloss: "to receive",
  },
  δέξωνταί: {
    parse:
      "V-ADS-3P｜Verb, aorist, Middle deponent, subjunctive, third, plural",
    GN: "G1209",
    gloss: "to receive",
  },
  δέχεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G1209",
    gloss: "to receive",
  },
  δέχηται: {
    parse:
      "V-PNS-3S｜Verb, present, Middle or Passive deponent, subjunctive, third, singular",
    GN: "G1209",
    gloss: "to receive",
  },
  δεχόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G1209",
    gloss: "to receive",
  },
  δέχονται: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G1209",
    gloss: "to receive",
  },
  δέχωνται: {
    parse:
      "V-PNS-3P｜Verb, present, Middle or Passive deponent, subjunctive, third, plural",
    GN: "G1209",
    gloss: "to receive",
  },
  ἐδεξάμεθα: {
    parse: "V-ADI-1P｜Verb, aorist, Middle deponent, indicative, first, plural",
    GN: "G1209",
    gloss: "to receive",
  },
  ἐδέξαντο: {
    parse: "V-ADI-3P｜Verb, aorist, Middle deponent, indicative, third, plural",
    GN: "G1209",
    gloss: "to receive",
  },
  ἐδέξασθε: {
    parse:
      "V-ADI-2P｜Verb, aorist, Middle deponent, indicative, second, plural",
    GN: "G1209",
    gloss: "to receive",
  },
  ἐδέξασθέ: {
    parse: "V-AMI-2P｜Verb, aorist, middle, indicative, second, plural",
    GN: "G1209",
    gloss: "to receive",
  },
  ἐδέξατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G1209",
    gloss: "to receive",
  },
  δεδεκώς: {
    parse:
      "V-RAP-NSM｜Verb, perfect, active, Participle, nominative, singular, masculine",
    GN: "G1210",
    gloss: "to bind",
  },
  δέδεμαι: {
    parse: "V-RPI-1S｜Verb, perfect, passive, indicative, first, singular",
    GN: "G1210",
    gloss: "to bind",
  },
  δεδεμένα: {
    parse:
      "V-RPP-NPN｜Verb, perfect, passive, Participle, nominative, plural, neuter",
    GN: "G1210",
    gloss: "to bind",
  },
  δεδεμένην: {
    parse:
      "V-RPP-ASF｜Verb, perfect, passive, Participle, accusative, singular, feminine",
    GN: "G1210",
    gloss: "to bind",
  },
  δεδεμένον: {
    parse:
      "V-RPP-NSN｜Verb, perfect, passive, Participle, nominative, singular, neuter",
    GN: "G1210",
    gloss: "to bind",
  },
  δεδεμένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G1210",
    gloss: "to bind",
  },
  δεδεμένους: {
    parse:
      "V-RPP-APM｜Verb, perfect, passive, Participle, accusative, plural, masculine",
    GN: "G1210",
    gloss: "to bind",
  },
  δέδεσαι: {
    parse: "V-RPI-2S｜Verb, perfect, passive, indicative, second, singular",
    GN: "G1210",
    gloss: "to bind",
  },
  δεδέσθαι: {
    parse: "V-RPN｜Verb, perfect, passive, Infinitive",
    GN: "G1210",
    gloss: "to bind",
  },
  δέδεται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G1210",
    gloss: "to bind",
  },
  δέῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G1210",
    gloss: "to bind",
  },
  δεήθητε: {
    parse:
      "V-AOM-2P｜Verb, aorist, Passive deponent, imperative, second, plural",
    GN: "G1210",
    gloss: "to bind",
  },
  Δεήθητε: {
    parse:
      "V-AOM-2P｜Verb, aorist, Passive deponent, imperative, second, plural",
    GN: "G1210",
    gloss: "to bind",
  },
  δεθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G1210",
    gloss: "to bind",
  },
  δεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1210",
    gloss: "to bind",
  },
  Δεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1210",
    gloss: "to bind",
  },
  δῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1210",
    gloss: "to bind",
  },
  δήσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G1210",
    gloss: "to bind",
  },
  Δήσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G1210",
    gloss: "to bind",
  },
  δήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G1210",
    gloss: "to bind",
  },
  δήσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G1210",
    gloss: "to bind",
  },
  δήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G1210",
    gloss: "to bind",
  },
  δήσῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G1210",
    gloss: "to bind",
  },
  δήσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G1210",
    gloss: "to bind",
  },
  δήσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G1210",
    gloss: "to bind",
  },
  ἔδησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1210",
    gloss: "to bind",
  },
  ἔδησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1210",
    gloss: "to bind",
  },
  δή: {
    parse: "PRT｜Particle, disjunctive particle",
    GN: "G1211",
    gloss: "so",
  },
  δὴ: {
    parse: "PRT｜Particle, disjunctive particle",
    GN: "G1211",
    gloss: "so",
  },
  δῆλόν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G1212",
    gloss: "Urim",
  },
  δῆλον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G1212",
    gloss: "Urim",
  },
  δηλοῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1213",
    gloss: "to make clear",
  },
  δηλοῦντος: {
    parse:
      "V-PAP-GSN｜Verb, present, active, Participle, genitive, singular, neuter",
    GN: "G1213",
    gloss: "to make clear",
  },
  δηλώσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G1213",
    gloss: "to make clear",
  },
  δηλώσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G1213",
    gloss: "to make clear",
  },
  ἐδήλου: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1213",
    gloss: "to make clear",
  },
  ἐδηλώθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G1213",
    gloss: "to make clear",
  },
  ἐδήλωσέν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1213",
    gloss: "to make clear",
  },
  Δημᾶς: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G1214",
    gloss: "Demas",
  },
  ἐδημηγόρει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1215",
    gloss: "to give a speech",
  },
  Δημήτριος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G1216",
    gloss: "Demetrius",
  },
  Δημητρίῳ: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G1216",
    gloss: "Demetrius",
  },
  δημιουργὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1217",
    gloss: "builder",
  },
  δῆμον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1218",
    gloss: "people",
  },
  δῆμος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1218",
    gloss: "people",
  },
  δήμῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G1218",
    gloss: "people",
  },
  δημοσίᾳ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G1219",
    gloss: "public",
  },
  δηνάρια: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G1220",
    gloss: "denarius",
  },
  δηνάριον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G1220",
    gloss: "denarius",
  },
  δηναρίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G1220",
    gloss: "denarius",
  },
  δηναρίων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G1220",
    gloss: "denarius",
  },
  δήπου: {
    parse: "PRT｜Particle, disjunctive particle",
    GN: "G1222",
    gloss: "surely",
  },
  διʼ: { parse: "PREP｜Preposition", GN: "G1223", gloss: "through/because of" },
  διά: { parse: "PREP｜Preposition", GN: "G1223", gloss: "through/because of" },
  διὰ: { parse: "PREP｜Preposition", GN: "G1223", gloss: "through/because of" },
  Διὰ: { parse: "PREP｜Preposition", GN: "G1223", gloss: "through/because of" },
  Διαβὰς: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G1224",
    gloss: "to cross",
  },
  διαβῆναι: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G1224",
    gloss: "to cross",
  },
  διέβησαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G1224",
    gloss: "to cross",
  },
  διεβλήθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G1225",
    gloss: "to accuse",
  },
  διαβεβαιοῦνται: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G1226",
    gloss: "to insist",
  },
  διαβεβαιοῦσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G1226",
    gloss: "to insist",
  },
  διαβλέψεις: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G1227",
    gloss: "to see clearly",
  },
  διέβλεψεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1227",
    gloss: "to see clearly",
  },
  διάβολοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G1228",
    gloss: "devilish/the Devil",
  },
  διάβολον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G1228",
    gloss: "devilish/the Devil",
  },
  διάβολος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1228",
    gloss: "devilish/the Devil",
  },
  Διάβολος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1228",
    gloss: "devilish/the Devil",
  },
  διάβολός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1228",
    gloss: "devilish/the Devil",
  },
  διαβόλου: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G1228",
    gloss: "devilish/the Devil",
  },
  διαβόλους: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G1228",
    gloss: "devilish/the Devil",
  },
  διαβόλῳ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G1228",
    gloss: "devilish/the Devil",
  },
  διαγγελῇ: {
    parse: "V-2APS-3S｜Verb, 2nd aorist, passive, subjunctive, third, singular",
    GN: "G1229",
    gloss: "to proclaim",
  },
  διάγγελλε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G1229",
    gloss: "to proclaim",
  },
  διαγγέλλων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1229",
    gloss: "to proclaim",
  },
  διαγενομένου: {
    parse:
      "V-2ADP-GSN｜Verb, 2nd aorist, Middle deponent, Participle, genitive, singular, neuter",
    GN: "G1230",
    gloss: "to pass",
  },
  διαγενομένων: {
    parse:
      "V-2ADP-GPF｜Verb, 2nd aorist, Middle deponent, Participle, genitive, plural, feminine",
    GN: "G1230",
    gloss: "to pass",
  },
  διαγινώσκειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1231",
    gloss: "to decide",
  },
  διαγνώσομαι: {
    parse:
      "V-FDI-1S｜Verb, future, Middle deponent, indicative, first, singular",
    GN: "G1231",
    gloss: "to decide",
  },
  διάγνωσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1233",
    gloss: "decision",
  },
  διεγόγγυζον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1234",
    gloss: "to murmur",
  },
  διαγρηγορήσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G1235",
    gloss: "to wake",
  },
  διάγοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1236",
    gloss: "to live",
  },
  διάγωμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G1236",
    gloss: "to live",
  },
  διαδεξάμενοι: {
    parse:
      "V-ADP-NPM｜Verb, aorist, Middle deponent, Participle, nominative, plural, masculine",
    GN: "G1237",
    gloss: "to receive in turn",
  },
  διαδήματα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G1238",
    gloss: "diadem",
  },
  διαδίδωσιν: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1239",
    gloss: "to distribute",
  },
  διάδος: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G1239",
    gloss: "to distribute",
  },
  διεδίδετο: {
    parse: "V-IPI-3S｜Verb, imperfect, passive, indicative, third, singular",
    GN: "G1239",
    gloss: "to distribute",
  },
  διέδωκεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1239",
    gloss: "to distribute",
  },
  διάδοχον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1240",
    gloss: "successor",
  },
  διεζώσατο: {
    parse: "V-AMI-3S｜Verb, aorist, middle, indicative, third, singular",
    GN: "G1241",
    gloss: "to tie around",
  },
  διέζωσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1241",
    gloss: "to tie around",
  },
  διεζωσμένος: {
    parse:
      "V-RMP-NSM｜Verb, perfect, middle, Participle, nominative, singular, masculine",
    GN: "G1241",
    gloss: "to tie around",
  },
  διαθῆκαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G1242",
    gloss: "covenant",
  },
  διαθήκῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1242",
    gloss: "covenant",
  },
  διαθήκη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1242",
    gloss: "covenant",
  },
  διαθήκην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1242",
    gloss: "covenant",
  },
  διαθήκης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1242",
    gloss: "covenant",
  },
  διαθηκῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G1242",
    gloss: "covenant",
  },
  διαιρέσεις: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G1243",
    gloss: "variety",
  },
  Διαιρέσεις: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G1243",
    gloss: "variety",
  },
  διαιροῦν: {
    parse:
      "V-PAP-NSN｜Verb, present, active, Participle, nominative, singular, neuter",
    GN: "G1244",
    gloss: "to distribute",
  },
  διεῖλεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G1244",
    gloss: "to distribute",
  },
  διακαθαριεῖ: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G1245",
    gloss: "to clear out",
  },
  διακατηλέγχετο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G1246",
    gloss: "to refute",
  },
  διακονεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1247",
    gloss: "to serve",
  },
  διακόνει: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G1247",
    gloss: "to serve",
  },
  διακονεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1247",
    gloss: "to serve",
  },
  διακονείτωσαν: {
    parse: "V-PAM-3P｜Verb, present, active, imperative, third, plural",
    GN: "G1247",
    gloss: "to serve",
  },
  διακονῇ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G1247",
    gloss: "to serve",
  },
  διακονηθεῖσα: {
    parse:
      "V-APP-NSF｜Verb, aorist, passive, Participle, nominative, singular, feminine",
    GN: "G1247",
    gloss: "to serve",
  },
  διακονηθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G1247",
    gloss: "to serve",
  },
  διακονῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1247",
    gloss: "to serve",
  },
  διακονήσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G1247",
    gloss: "to serve",
  },
  διακονήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G1247",
    gloss: "to serve",
  },
  διακονουμένῃ: {
    parse:
      "V-PPP-DSF｜Verb, present, passive, Participle, dative, singular, feminine",
    GN: "G1247",
    gloss: "to serve",
  },
  διακονοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1247",
    gloss: "to serve",
  },
  διακονούντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G1247",
    gloss: "to serve",
  },
  διακονοῦσαι: {
    parse:
      "V-PAP-NPF｜Verb, present, active, Participle, nominative, plural, feminine",
    GN: "G1247",
    gloss: "to serve",
  },
  διακονῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1247",
    gloss: "to serve",
  },
  διηκόνει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1247",
    gloss: "to serve",
  },
  διηκονήσαμέν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G1247",
    gloss: "to serve",
  },
  διηκόνησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1247",
    gloss: "to serve",
  },
  διηκόνουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1247",
    gloss: "to serve",
  },
  διακονίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1248",
    gloss: "service",
  },
  διακονία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1248",
    gloss: "service",
  },
  διακονίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1248",
    gloss: "service",
  },
  διακονίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1248",
    gloss: "service",
  },
  διακονιῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G1248",
    gloss: "service",
  },
  διάκονοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G1249",
    gloss: "servant",
  },
  διακόνοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G1249",
    gloss: "servant",
  },
  διάκονον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1249",
    gloss: "servant",
  },
  διάκονος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1249",
    gloss: "servant",
  },
  διάκονός: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1249",
    gloss: "servant",
  },
  διακόνους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G1249",
    gloss: "servant",
  },
  Διακόνους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G1249",
    gloss: "servant",
  },
  διακόσιαι: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G1250",
    gloss: "two hundred",
  },
  διακοσίας: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G1250",
    gloss: "two hundred",
  },
  διακοσίους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G1250",
    gloss: "two hundred",
  },
  διακοσίων: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G1250",
    gloss: "two hundred",
  },
  Διακοσίων: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G1250",
    gloss: "two hundred",
  },
  Διακούσομαί: {
    parse:
      "V-FDI-1S｜Verb, future, Middle deponent, indicative, first, singular",
    GN: "G1251",
    gloss: "to give a hearing",
  },
  διακριθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G1252",
    gloss: "to judge/doubt",
  },
  διακριθῆτε: {
    parse: "V-APS-2P｜Verb, aorist, passive, subjunctive, second, plural",
    GN: "G1252",
    gloss: "to judge/doubt",
  },
  διακρῖναι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1252",
    gloss: "to judge/doubt",
  },
  διακρίναντα: {
    parse:
      "V-AAP-ASM｜Verb, aorist, active, Participle, accusative, singular, masculine",
    GN: "G1252",
    gloss: "to judge/doubt",
  },
  διακρίνει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1252",
    gloss: "to judge/doubt",
  },
  διακρίνειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1252",
    gloss: "to judge/doubt",
  },
  διακρινέτωσαν: {
    parse: "V-PAM-3P｜Verb, present, active, imperative, third, plural",
    GN: "G1252",
    gloss: "to judge/doubt",
  },
  διακρινόμενος: {
    parse:
      "V-PMP-NSM｜Verb, present, middle, Participle, nominative, singular, masculine",
    GN: "G1252",
    gloss: "to judge/doubt",
  },
  διακρινομένους: {
    parse:
      "V-PMP-APM｜Verb, present, middle, Participle, accusative, plural, masculine",
    GN: "G1252",
    gloss: "to judge/doubt",
  },
  διακρίνων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1252",
    gloss: "to judge/doubt",
  },
  διεκρίθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G1252",
    gloss: "to judge/doubt",
  },
  διεκρίθητε: {
    parse: "V-API-2P｜Verb, aorist, passive, indicative, second, plural",
    GN: "G1252",
    gloss: "to judge/doubt",
  },
  διέκρινεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1252",
    gloss: "to judge/doubt",
  },
  διεκρίνομεν: {
    parse: "V-IAI-1P｜Verb, imperfect, active, indicative, first, plural",
    GN: "G1252",
    gloss: "to judge/doubt",
  },
  διεκρίνοντο: {
    parse: "V-IMI-3P｜Verb, imperfect, middle, indicative, third, plural",
    GN: "G1252",
    gloss: "to judge/doubt",
  },
  διακρίσεις: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G1253",
    gloss: "discernment",
  },
  διάκρισιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1253",
    gloss: "discernment",
  },
  διεκώλυεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1254",
    gloss: "to hinder",
  },
  διελαλεῖτο: {
    parse: "V-IPI-3S｜Verb, imperfect, passive, indicative, third, singular",
    GN: "G1255",
    gloss: "to discuss",
  },
  διελάλουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1255",
    gloss: "to discuss",
  },
  διαλέγεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G1256",
    gloss: "to dispute",
  },
  διαλεγόμενον: {
    parse:
      "V-PNP-ASM｜Verb, present, Middle or Passive deponent, Participle, accusative, singular, masculine",
    GN: "G1256",
    gloss: "to dispute",
  },
  διαλεγόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G1256",
    gloss: "to dispute",
  },
  διαλεγομένου: {
    parse:
      "V-PNP-GSM｜Verb, present, Middle or Passive deponent, Participle, genitive, singular, masculine",
    GN: "G1256",
    gloss: "to dispute",
  },
  διελέγετο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G1256",
    gloss: "to dispute",
  },
  διελέξατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G1256",
    gloss: "to dispute",
  },
  διελέχθησαν: {
    parse:
      "V-AOI-3P｜Verb, aorist, Passive deponent, indicative, third, plural",
    GN: "G1256",
    gloss: "to dispute",
  },
  διέλιπεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G1257",
    gloss: "to stop",
  },
  διαλέκτῳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1258",
    gloss: "language",
  },
  διαλλάγηθι: {
    parse: "V-2APM-2S｜Verb, 2nd aorist, passive, imperative, second, singular",
    GN: "G1259",
    gloss: "be reconciled",
  },
  διαλογίζεσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G1260",
    gloss: "to discuss",
  },
  διαλογίζεσθε: {
    parse:
      "V-PNI-2P｜Verb, present, Middle or Passive deponent, indicative, second, plural",
    GN: "G1260",
    gloss: "to discuss",
  },
  διαλογιζόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G1260",
    gloss: "to discuss",
  },
  διαλογιζομένων: {
    parse:
      "V-PNP-GPM｜Verb, present, Middle or Passive deponent, Participle, genitive, plural, masculine",
    GN: "G1260",
    gloss: "to discuss",
  },
  διαλογίζονται: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G1260",
    gloss: "to discuss",
  },
  διελογίζεσθε: {
    parse:
      "V-INI-2P｜Verb, imperfect, Middle or Passive deponent, indicative, second, plural",
    GN: "G1260",
    gloss: "to discuss",
  },
  διελογίζετο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G1260",
    gloss: "to discuss",
  },
  διελογίζοντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G1260",
    gloss: "to discuss",
  },
  διαλογισμοί: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G1261",
    gloss: "reasoning",
  },
  διαλογισμοὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G1261",
    gloss: "reasoning",
  },
  διαλογισμοῖς: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G1261",
    gloss: "reasoning",
  },
  διαλογισμὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1261",
    gloss: "reasoning",
  },
  διαλογισμὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1261",
    gloss: "reasoning",
  },
  διαλογισμοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G1261",
    gloss: "reasoning",
  },
  διαλογισμοὺς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G1261",
    gloss: "reasoning",
  },
  διαλογισμῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G1261",
    gloss: "reasoning",
  },
  διελύθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G1262",
    gloss: "to disperse",
  },
  διαμαρτυράμενοι: {
    parse:
      "V-ADP-NPM｜Verb, aorist, Middle deponent, Participle, nominative, plural, masculine",
    GN: "G1263",
    gloss: "to testify solemnly",
  },
  διαμαρτύρασθαι: {
    parse: "V-ADN｜Verb, aorist, Middle deponent, Infinitive",
    GN: "G1263",
    gloss: "to testify solemnly",
  },
  διαμαρτύρεταί: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G1263",
    gloss: "to testify solemnly",
  },
  διαμαρτύρηται: {
    parse:
      "V-PNS-3S｜Verb, present, Middle or Passive deponent, subjunctive, third, singular",
    GN: "G1263",
    gloss: "to testify solemnly",
  },
  διαμαρτύρομαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G1263",
    gloss: "to testify solemnly",
  },
  Διαμαρτύρομαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G1263",
    gloss: "to testify solemnly",
  },
  διαμαρτυρόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G1263",
    gloss: "to testify solemnly",
  },
  διεμαρτυράμεθα: {
    parse: "V-ADI-1P｜Verb, aorist, Middle deponent, indicative, first, plural",
    GN: "G1263",
    gloss: "to testify solemnly",
  },
  διεμαρτύρατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G1263",
    gloss: "to testify solemnly",
  },
  διεμαρτύρω: {
    parse:
      "V-ADI-2S｜Verb, aorist, Middle deponent, indicative, second, singular",
    GN: "G1263",
    gloss: "to testify solemnly",
  },
  διεμάχοντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G1264",
    gloss: "to contend sharply",
  },
  διαμείνῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G1265",
    gloss: "to remain",
  },
  διαμεμενηκότες: {
    parse:
      "V-RAP-NPM｜Verb, perfect, active, Participle, nominative, plural, masculine",
    GN: "G1265",
    gloss: "to remain",
  },
  διαμένει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1265",
    gloss: "to remain",
  },
  διαμένεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G1265",
    gloss: "to remain",
  },
  διέμενεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1265",
    gloss: "to remain",
  },
  διαμεμερισμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G1266",
    gloss: "to divide",
  },
  διαμεριζόμεναι: {
    parse:
      "V-PEP-NPF｜Verb, present, Either middle or passive, Participle, nominative, plural, feminine",
    GN: "G1266",
    gloss: "to divide",
  },
  διαμεριζόμενοι: {
    parse:
      "V-PEP-NPM｜Verb, present, Either middle or passive, Participle, nominative, plural, masculine",
    GN: "G1266",
    gloss: "to divide",
  },
  διαμερίζονται: {
    parse: "V-PMI-3P｜Verb, present, middle, indicative, third, plural",
    GN: "G1266",
    gloss: "to divide",
  },
  διαμερίσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G1266",
    gloss: "to divide",
  },
  διαμερισθεῖσα: {
    parse:
      "V-APP-NSF｜Verb, aorist, passive, Participle, nominative, singular, feminine",
    GN: "G1266",
    gloss: "to divide",
  },
  διαμερισθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G1266",
    gloss: "to divide",
  },
  διεμέριζον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1266",
    gloss: "to divide",
  },
  διεμερίσαντο: {
    parse: "V-AMI-3P｜Verb, aorist, middle, indicative, third, plural",
    GN: "G1266",
    gloss: "to divide",
  },
  Διεμερίσαντο: {
    parse: "V-AMI-3P｜Verb, aorist, middle, indicative, third, plural",
    GN: "G1266",
    gloss: "to divide",
  },
  διεμερίσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G1266",
    gloss: "to divide",
  },
  διαμερισμόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1267",
    gloss: "division",
  },
  διανεμηθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G1268",
    gloss: "to spread",
  },
  διανεύων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1269",
    gloss: "to signify",
  },
  διανοήματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G1270",
    gloss: "thought",
  },
  διανοίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1271",
    gloss: "mind",
  },
  διάνοιαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1271",
    gloss: "mind",
  },
  διανοίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1271",
    gloss: "mind",
  },
  διανοιῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G1271",
    gloss: "mind",
  },
  διανοῖγον: {
    parse:
      "V-PAP-NSN｜Verb, present, active, Participle, nominative, singular, neuter",
    GN: "G1272",
    gloss: "to open up",
  },
  διανοίγων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1272",
    gloss: "to open up",
  },
  Διανοίχθητι: {
    parse: "V-APM-2S｜Verb, aorist, passive, imperative, second, singular",
    GN: "G1272",
    gloss: "to open up",
  },
  διήνοιγεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1272",
    gloss: "to open up",
  },
  διηνοιγμένους: {
    parse:
      "V-RPP-APM｜Verb, perfect, passive, Participle, accusative, plural, masculine",
    GN: "G1272",
    gloss: "to open up",
  },
  διήνοιξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1272",
    gloss: "to open up",
  },
  διηνοίχθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G1272",
    gloss: "to open up",
  },
  διανυκτερεύων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1273",
    gloss: "to spend the night",
  },
  διανύσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G1274",
    gloss: "to continue",
  },
  διαπεράσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G1276",
    gloss: "to cross",
  },
  διαπεράσαντος: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G1276",
    gloss: "to cross",
  },
  διαπερῶν: {
    parse:
      "V-PAP-ASN｜Verb, present, active, Participle, accusative, singular, neuter",
    GN: "G1276",
    gloss: "to cross",
  },
  διαπερῶσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G1276",
    gloss: "to cross",
  },
  διεπέρασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1276",
    gloss: "to cross",
  },
  διαπλεύσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G1277",
    gloss: "to sail across",
  },
  διαπονηθεὶς: {
    parse:
      "V-AOP-NSM｜Verb, aorist, Passive deponent, Participle, nominative, singular, masculine",
    GN: "G1278",
    gloss: "be vexed",
  },
  διαπονούμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G1278",
    gloss: "be vexed",
  },
  διαπορεύεσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G1279",
    gloss: "to go through",
  },
  διαπορευόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G1279",
    gloss: "to go through",
  },
  διαπορευομένου: {
    parse:
      "V-PNP-GSM｜Verb, present, Middle or Passive deponent, Participle, genitive, singular, masculine",
    GN: "G1279",
    gloss: "to go through",
  },
  διεπορεύετο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G1279",
    gloss: "to go through",
  },
  διεπορεύοντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G1279",
    gloss: "to go through",
  },
  διηπόρει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1280",
    gloss: "be perplexed",
  },
  διηπόρουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1280",
    gloss: "be perplexed",
  },
  διηποροῦντο: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1280",
    gloss: "be perplexed",
  },
  διεπραγματεύσαντο: {
    parse: "V-ADI-3P｜Verb, aorist, Middle deponent, indicative, third, plural",
    GN: "G1281",
    gloss: "to gain in trade",
  },
  διεπρίοντο: {
    parse: "V-IPI-3P｜Verb, imperfect, passive, indicative, third, plural",
    GN: "G1282",
    gloss: "be furious",
  },
  διαρπάσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1283",
    gloss: "to rob",
  },
  διαρπάσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G1283",
    gloss: "to rob",
  },
  διαρρήξαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G1284",
    gloss: "to tear",
  },
  διαρρήξας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G1284",
    gloss: "to tear",
  },
  διαρρήσσων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1284",
    gloss: "to tear",
  },
  διέρρηξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1284",
    gloss: "to tear",
  },
  διερρήσσετο: {
    parse: "V-IPI-3S｜Verb, imperfect, passive, indicative, third, singular",
    GN: "G1284",
    gloss: "to tear",
  },
  Διασάφησον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G1285",
    gloss: "to explain",
  },
  διεσάφησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1285",
    gloss: "to explain",
  },
  διασείσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G1286",
    gloss: "to extort",
  },
  διασκορπίζων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1287",
    gloss: "to scatter",
  },
  διασκορπισθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G1287",
    gloss: "to scatter",
  },
  διεσκόρπισα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G1287",
    gloss: "to scatter",
  },
  διεσκόρπισας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G1287",
    gloss: "to scatter",
  },
  διεσκόρπισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1287",
    gloss: "to scatter",
  },
  διεσκορπίσθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G1287",
    gloss: "to scatter",
  },
  διεσκορπισμένα: {
    parse:
      "V-RPP-APN｜Verb, perfect, passive, Participle, accusative, plural, neuter",
    GN: "G1287",
    gloss: "to scatter",
  },
  διασπασθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G1288",
    gloss: "to tear apart",
  },
  διεσπάσθαι: {
    parse: "V-RPN｜Verb, perfect, passive, Infinitive",
    GN: "G1288",
    gloss: "to tear apart",
  },
  διασπαρέντες: {
    parse:
      "V-2APP-NPM｜Verb, 2nd aorist, passive, Participle, nominative, plural, masculine",
    GN: "G1289",
    gloss: "to scatter",
  },
  διεσπάρησαν: {
    parse: "V-2API-3P｜Verb, 2nd aorist, passive, indicative, third, plural",
    GN: "G1289",
    gloss: "to scatter",
  },
  διασπορᾷ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1290",
    gloss: "dispersion",
  },
  διασπορὰν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1290",
    gloss: "dispersion",
  },
  διασπορᾶς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1290",
    gloss: "dispersion",
  },
  διαστελλόμενον: {
    parse:
      "V-PPP-ASN｜Verb, present, passive, Participle, accusative, singular, neuter",
    GN: "G1291",
    gloss: "to give orders",
  },
  διεστειλάμεθα: {
    parse: "V-AMI-1P｜Verb, aorist, middle, indicative, first, plural",
    GN: "G1291",
    gloss: "to give orders",
  },
  διεστείλατο: {
    parse: "V-AMI-3S｜Verb, aorist, middle, indicative, third, singular",
    GN: "G1291",
    gloss: "to give orders",
  },
  διεστέλλετο: {
    parse: "V-IMI-3S｜Verb, imperfect, middle, indicative, third, singular",
    GN: "G1291",
    gloss: "to give orders",
  },
  διάστημα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G1292",
    gloss: "interval",
  },
  διαστολή: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1293",
    gloss: "distinction",
  },
  διαστολὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1293",
    gloss: "distinction",
  },
  διαστολὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1293",
    gloss: "distinction",
  },
  διαστρέφοντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G1294",
    gloss: "to pervert",
  },
  διαστρέφων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1294",
    gloss: "to pervert",
  },
  διαστρέψαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1294",
    gloss: "to pervert",
  },
  διεστραμμένα: {
    parse:
      "V-RPP-APN｜Verb, perfect, passive, Participle, accusative, plural, neuter",
    GN: "G1294",
    gloss: "to pervert",
  },
  διεστραμμένη: {
    parse:
      "V-RPP-VSF｜Verb, perfect, passive, Participle, Vocative or nominative, singular, feminine",
    GN: "G1294",
    gloss: "to pervert",
  },
  διεστραμμένης: {
    parse:
      "V-RPP-GSF｜Verb, perfect, passive, Participle, genitive, singular, feminine",
    GN: "G1294",
    gloss: "to pervert",
  },
  διασωθέντα: {
    parse:
      "V-APP-ASM｜Verb, aorist, passive, Participle, accusative, singular, masculine",
    GN: "G1295",
    gloss: "to save",
  },
  διασωθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G1295",
    gloss: "to save",
  },
  διασωθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G1295",
    gloss: "to save",
  },
  διασῶσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1295",
    gloss: "to save",
  },
  διασώσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G1295",
    gloss: "to save",
  },
  διασώσωσι: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G1295",
    gloss: "to save",
  },
  διασώσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G1295",
    gloss: "to save",
  },
  διεσώθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G1295",
    gloss: "to save",
  },
  διαταγὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G1296",
    gloss: "ordinance",
  },
  διαταγῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1296",
    gloss: "ordinance",
  },
  διάταγμα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G1297",
    gloss: "edict",
  },
  διεταράχθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G1298",
    gloss: "to trouble",
  },
  διαταγεὶς: {
    parse:
      "V-2APP-NSM｜Verb, 2nd aorist, passive, Participle, nominative, singular, masculine",
    GN: "G1299",
    gloss: "to direct",
  },
  διαταξάμενος: {
    parse:
      "V-AMP-NSM｜Verb, aorist, middle, Participle, nominative, singular, masculine",
    GN: "G1299",
    gloss: "to direct",
  },
  διατάξομαι: {
    parse:
      "V-FDI-1S｜Verb, future, Middle deponent, indicative, first, singular",
    GN: "G1299",
    gloss: "to direct",
  },
  διατάσσομαι: {
    parse: "V-PMI-1S｜Verb, present, middle, indicative, first, singular",
    GN: "G1299",
    gloss: "to direct",
  },
  διατάσσων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1299",
    gloss: "to direct",
  },
  διαταχθέντα: {
    parse:
      "V-APP-APN｜Verb, aorist, passive, Participle, accusative, plural, neuter",
    GN: "G1299",
    gloss: "to direct",
  },
  διατεταγμένον: {
    parse:
      "V-RPP-ASN｜Verb, perfect, passive, Participle, accusative, singular, neuter",
    GN: "G1299",
    gloss: "to direct",
  },
  διατεταγμένος: {
    parse:
      "V-RMP-NSM｜Verb, perfect, middle, Participle, nominative, singular, masculine",
    GN: "G1299",
    gloss: "to direct",
  },
  διατεταχέναι: {
    parse: "V-RAN｜Verb, perfect, active, Infinitive",
    GN: "G1299",
    gloss: "to direct",
  },
  διέταξα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G1299",
    gloss: "to direct",
  },
  διεταξάμην: {
    parse: "V-AMI-1S｜Verb, aorist, middle, indicative, first, singular",
    GN: "G1299",
    gloss: "to direct",
  },
  διετάξατο: {
    parse: "V-AMI-3S｜Verb, aorist, middle, indicative, third, singular",
    GN: "G1299",
    gloss: "to direct",
  },
  διέταξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1299",
    gloss: "to direct",
  },
  διατελεῖτε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G1300",
    gloss: "to continue",
  },
  διατηροῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1301",
    gloss: "to keep",
  },
  διετήρει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1301",
    gloss: "to keep",
  },
  διαθέμενος: {
    parse:
      "V-2AMP-NSM｜Verb, 2nd aorist, middle, Participle, nominative, singular, masculine",
    GN: "G1303",
    gloss: "to make a covenant",
  },
  διαθεμένου: {
    parse:
      "V-2AMP-GSM｜Verb, 2nd aorist, middle, Participle, genitive, singular, masculine",
    GN: "G1303",
    gloss: "to make a covenant",
  },
  διαθήσομαι: {
    parse:
      "V-FDI-1S｜Verb, future, Middle deponent, indicative, first, singular",
    GN: "G1303",
    gloss: "to make a covenant",
  },
  διατίθεμαι: {
    parse: "V-PMI-1S｜Verb, present, middle, indicative, first, singular",
    GN: "G1303",
    gloss: "to make a covenant",
  },
  διέθετο: {
    parse: "V-2AMI-3S｜Verb, 2nd aorist, middle, indicative, third, singular",
    GN: "G1303",
    gloss: "to make a covenant",
  },
  διέθετό: {
    parse: "V-2AMI-3S｜Verb, 2nd aorist, middle, indicative, third, singular",
    GN: "G1303",
    gloss: "to make a covenant",
  },
  διατρίβοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1304",
    gloss: "to remain",
  },
  Διατρίψας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G1304",
    gloss: "to remain",
  },
  διέτριβεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1304",
    gloss: "to remain",
  },
  διέτριβον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1304",
    gloss: "to remain",
  },
  διετρίψαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G1304",
    gloss: "to remain",
  },
  διέτριψαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1304",
    gloss: "to remain",
  },
  διατροφὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G1305",
    gloss: "food",
  },
  διαφέρει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1308",
    gloss: "to spread/surpass",
  },
  διαφέρετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G1308",
    gloss: "to spread/surpass",
  },
  διαφερομένων: {
    parse:
      "V-PPP-GPM｜Verb, present, passive, Participle, genitive, plural, masculine",
    GN: "G1308",
    gloss: "to spread/surpass",
  },
  διαφέροντα: {
    parse:
      "V-PAP-APN｜Verb, present, active, Participle, accusative, plural, neuter",
    GN: "G1308",
    gloss: "to spread/surpass",
  },
  διενέγκῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G1308",
    gloss: "to spread/surpass",
  },
  διεφέρετο: {
    parse: "V-IPI-3S｜Verb, imperfect, passive, indicative, third, singular",
    GN: "G1308",
    gloss: "to spread/surpass",
  },
  διαφύγῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G1309",
    gloss: "to escape",
  },
  διαφημίζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1310",
    gloss: "to publish abroad",
  },
  διεφήμισαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1310",
    gloss: "to publish abroad",
  },
  διεφημίσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G1310",
    gloss: "to publish abroad",
  },
  διαφθεῖραι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1311",
    gloss: "to destroy",
  },
  διαφθείρει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1311",
    gloss: "to destroy",
  },
  διαφθείρεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G1311",
    gloss: "to destroy",
  },
  διαφθείροντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G1311",
    gloss: "to destroy",
  },
  διεφθάρησαν: {
    parse: "V-2API-3P｜Verb, 2nd aorist, passive, indicative, third, plural",
    GN: "G1311",
    gloss: "to destroy",
  },
  διεφθαρμένων: {
    parse:
      "V-RPP-GPM｜Verb, perfect, passive, Participle, genitive, plural, masculine",
    GN: "G1311",
    gloss: "to destroy",
  },
  διαφθοράν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1312",
    gloss: "decay",
  },
  διάφορα: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G1313",
    gloss: "different",
  },
  διαφόροις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G1313",
    gloss: "different",
  },
  διαφορωτέρας: {
    parse: "A-GSF-C｜Adjective, genitive, singular, feminine, Comparative",
    GN: "G1313",
    gloss: "different",
  },
  διαφορώτερον: {
    parse: "A-ASN-C｜Adjective, accusative, singular, neuter, Comparative",
    GN: "G1313",
    gloss: "different",
  },
  διαφυλάξαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1314",
    gloss: "to protect",
  },
  διαχειρίσασθαι: {
    parse: "V-AMN｜Verb, aorist, middle, Infinitive",
    GN: "G1315",
    gloss: "to kill",
  },
  διεχειρίσασθε: {
    parse: "V-AMI-2P｜Verb, aorist, middle, indicative, second, plural",
    GN: "G1315",
    gloss: "to kill",
  },
  διαχωρίζεσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G1316",
    gloss: "be separated",
  },
  διδακτικόν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G1317",
    gloss: "able to teach",
  },
  διδακτοὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G1318",
    gloss: "taught",
  },
  διδακτοῖς: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G1318",
    gloss: "taught",
  },
  διδασκαλίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1319",
    gloss: "teaching",
  },
  διδασκαλία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1319",
    gloss: "teaching",
  },
  διδασκαλίαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G1319",
    gloss: "teaching",
  },
  διδασκαλίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1319",
    gloss: "teaching",
  },
  διδασκαλίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1319",
    gloss: "teaching",
  },
  διδάσκαλε: {
    // or nominative
    parse: "N-VSM｜Noun, Vocative, singular, masculine",
    GN: "G1320",
    gloss: "teacher",
  },
  Διδάσκαλε: {
    // or nominative
    parse: "N-VSM｜Noun, Vocative, singular, masculine",
    GN: "G1320",
    gloss: "teacher",
  },
  διδάσκαλοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G1320",
    gloss: "teacher",
  },
  διδάσκαλον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1320",
    gloss: "teacher",
  },
  διδάσκαλος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1320",
    gloss: "teacher",
  },
  διδασκάλους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G1320",
    gloss: "teacher",
  },
  διδασκάλων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G1320",
    gloss: "teacher",
  },
  διδάξαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1321",
    gloss: "to teach",
  },
  διδάξει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G1321",
    gloss: "to teach",
  },
  διδάξῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G1321",
    gloss: "to teach",
  },
  δίδαξον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G1321",
    gloss: "to teach",
  },
  διδάξωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G1321",
    gloss: "to teach",
  },
  δίδασκε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G1321",
    gloss: "to teach",
  },
  διδάσκει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1321",
    gloss: "to teach",
  },
  διδάσκειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1321",
    gloss: "to teach",
  },
  διδάσκεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G1321",
    gloss: "to teach",
  },
  διδάσκῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G1321",
    gloss: "to teach",
  },
  διδάσκοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1321",
    gloss: "to teach",
  },
  διδάσκοντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G1321",
    gloss: "to teach",
  },
  διδάσκοντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G1321",
    gloss: "to teach",
  },
  διδάσκω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G1321",
    gloss: "to teach",
  },
  διδάσκων: {
    parse:
      "V-PAP-VSM｜Verb, present, active, Participle, Vocative or nominative, singular, masculine",
    GN: "G1321",
    gloss: "to teach",
  },
  ἐδίδαξα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G1321",
    gloss: "to teach",
  },
  ἐδίδαξαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1321",
    gloss: "to teach",
  },
  ἐδίδαξας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G1321",
    gloss: "to teach",
  },
  ἐδίδαξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1321",
    gloss: "to teach",
  },
  ἐδίδαξέν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1321",
    gloss: "to teach",
  },
  ἐδίδασκεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1321",
    gloss: "to teach",
  },
  ἐδίδασκον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1321",
    gloss: "to teach",
  },
  ἐδιδάχθην: {
    parse: "V-API-1S｜Verb, aorist, passive, indicative, first, singular",
    GN: "G1321",
    gloss: "to teach",
  },
  ἐδιδάχθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G1321",
    gloss: "to teach",
  },
  ἐδιδάχθητε: {
    parse: "V-API-2P｜Verb, aorist, passive, indicative, second, plural",
    GN: "G1321",
    gloss: "to teach",
  },
  διδαχαῖς: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G1322",
    gloss: "teaching",
  },
  διδαχῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1322",
    gloss: "teaching",
  },
  διδαχή: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1322",
    gloss: "teaching",
  },
  διδαχὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1322",
    gloss: "teaching",
  },
  διδαχὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1322",
    gloss: "teaching",
  },
  διδαχῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1322",
    gloss: "teaching",
  },
  δίδραχμα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G1323",
    gloss: "two-drachma",
  },
  Δίδυμος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G1324",
    gloss: "Twin",
  },
  δεδομένην: {
    parse:
      "V-RPP-ASF｜Verb, perfect, passive, Participle, accusative, singular, feminine",
    GN: "G1325",
    gloss: "to give",
  },
  δεδομένον: {
    parse:
      "V-RPP-NSN｜Verb, perfect, passive, Participle, nominative, singular, neuter",
    GN: "G1325",
    gloss: "to give",
  },
  δέδοται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G1325",
    gloss: "to give",
  },
  δέδωκα: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G1325",
    gloss: "to give",
  },
  δέδωκας: {
    parse: "V-RAI-2S｜Verb, perfect, active, indicative, second, singular",
    GN: "G1325",
    gloss: "to give",
  },
  δέδωκάς: {
    parse: "V-RAI-2S｜Verb, perfect, active, indicative, second, singular",
    GN: "G1325",
    gloss: "to give",
  },
  δεδώκει: {
    parse: "V-LAI-3S｜Verb, pluperfect, active, indicative, third, singular",
    GN: "G1325",
    gloss: "to give",
  },
  δεδώκεισαν: {
    parse: "V-LAI-3P｜Verb, pluperfect, active, indicative, third, plural",
    GN: "G1325",
    gloss: "to give",
  },
  δέδωκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G1325",
    gloss: "to give",
  },
  δέδωκέν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G1325",
    gloss: "to give",
  },
  διδόασιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1325",
    gloss: "to give",
  },
  διδόμενον: {
    parse:
      "V-PPP-NSN｜Verb, present, passive, Participle, nominative, singular, neuter",
    GN: "G1325",
    gloss: "to give",
  },
  διδόναι: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1325",
    gloss: "to give",
  },
  διδόντα: {
    parse:
      "V-PAP-NPN｜Verb, present, active, Participle, nominative, plural, neuter",
    GN: "G1325",
    gloss: "to give",
  },
  διδόντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1325",
    gloss: "to give",
  },
  διδόντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G1325",
    gloss: "to give",
  },
  διδόντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G1325",
    gloss: "to give",
  },
  δίδοται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G1325",
    gloss: "to give",
  },
  δίδοτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G1325",
    gloss: "to give",
  },
  δίδου: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G1325",
    gloss: "to give",
  },
  διδοὺς: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1325",
    gloss: "to give",
  },
  διδῶ: {
    parse: "V-PAS-1S｜Verb, present, active, subjunctive, first, singular",
    GN: "G1325",
    gloss: "to give",
  },
  δίδωμι: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G1325",
    gloss: "to give",
  },
  δίδωσιν: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1325",
    gloss: "to give",
  },
  δίδωσίν: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1325",
    gloss: "to give",
  },
  δοθεῖσα: {
    parse:
      "V-APP-NSF｜Verb, aorist, passive, Participle, nominative, singular, feminine",
    GN: "G1325",
    gloss: "to give",
  },
  δοθεῖσαν: {
    parse:
      "V-APP-ASF｜Verb, aorist, passive, Participle, accusative, singular, feminine",
    GN: "G1325",
    gloss: "to give",
  },
  δοθεῖσάν: {
    parse:
      "V-APP-ASF｜Verb, aorist, passive, Participle, accusative, singular, feminine",
    GN: "G1325",
    gloss: "to give",
  },
  δοθείσῃ: {
    parse:
      "V-APP-DSF｜Verb, aorist, passive, Participle, dative, singular, feminine",
    GN: "G1325",
    gloss: "to give",
  },
  δοθείσης: {
    parse:
      "V-APP-GSF｜Verb, aorist, passive, Participle, genitive, singular, feminine",
    GN: "G1325",
    gloss: "to give",
  },
  δοθέντος: {
    parse:
      "V-APP-GSN｜Verb, aorist, passive, Participle, genitive, singular, neuter",
    GN: "G1325",
    gloss: "to give",
  },
  δοθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G1325",
    gloss: "to give",
  },
  δοθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G1325",
    gloss: "to give",
  },
  δοθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G1325",
    gloss: "to give",
  },
  δοῖ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G1325",
    gloss: "to give",
  },
  δόντα: {
    parse:
      "V-2AAP-ASM｜Verb, 2nd aorist, active, Participle, accusative, singular, masculine",
    GN: "G1325",
    gloss: "to give",
  },
  δόντι: {
    parse:
      "V-2AAP-DSM｜Verb, 2nd aorist, active, Participle, dative, singular, masculine",
    GN: "G1325",
    gloss: "to give",
  },
  δόντος: {
    parse:
      "V-2AAP-GSM｜Verb, 2nd aorist, active, Participle, genitive, singular, masculine",
    GN: "G1325",
    gloss: "to give",
  },
  δός: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G1325",
    gloss: "to give",
  },
  Δός: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G1325",
    gloss: "to give",
  },
  δὸς: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G1325",
    gloss: "to give",
  },
  Δὸς: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G1325",
    gloss: "to give",
  },
  δότε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G1325",
    gloss: "to give",
  },
  Δότε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G1325",
    gloss: "to give",
  },
  δότω: {
    parse: "V-2AAM-3S｜Verb, 2nd aorist, active, imperative, third, singular",
    GN: "G1325",
    gloss: "to give",
  },
  δοῦναι: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G1325",
    gloss: "to give",
  },
  δοῦναί: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G1325",
    gloss: "to give",
  },
  δούς: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G1325",
    gloss: "to give",
  },
  δοὺς: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G1325",
    gloss: "to give",
  },
  δῷ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G1325",
    gloss: "to give",
  },
  δῴη: {
    parse: "V-2AAO-3S｜Verb, 2nd aorist, active, Optative, third, singular",
    GN: "G1325",
    gloss: "to give",
  },
  δώῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G1325",
    gloss: "to give",
  },
  δῶμεν: {
    parse: "V-2AAS-1P｜Verb, 2nd aorist, active, subjunctive, first, plural",
    GN: "G1325",
    gloss: "to give",
  },
  δῷς: {
    parse: "V-2AAS-2S｜Verb, 2nd aorist, active, subjunctive, second, singular",
    GN: "G1325",
    gloss: "to give",
  },
  δώσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G1325",
    gloss: "to give",
  },
  δώσεις: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G1325",
    gloss: "to give",
  },
  δώσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G1325",
    gloss: "to give",
  },
  δῶσιν: {
    parse: "V-2AAS-3P｜Verb, 2nd aorist, active, subjunctive, third, plural",
    GN: "G1325",
    gloss: "to give",
  },
  δώσομεν: {
    parse: "V-FAI-1P｜Verb, future, active, indicative, first, plural",
    GN: "G1325",
    gloss: "to give",
  },
  δώσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G1325",
    gloss: "to give",
  },
  δώσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G1325",
    gloss: "to give",
  },
  Δώσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G1325",
    gloss: "to give",
  },
  δώσωμεν: {
    parse: "V-AAS-1P｜Verb, aorist, active, subjunctive, first, plural",
    GN: "G1325",
    gloss: "to give",
  },
  δῶτε: {
    parse: "V-2AAS-2P｜Verb, 2nd aorist, active, subjunctive, second, plural",
    GN: "G1325",
    gloss: "to give",
  },
  ἐδίδοσαν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1325",
    gloss: "to give",
  },
  ἐδίδου: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1325",
    gloss: "to give",
  },
  ἐδίδουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1325",
    gloss: "to give",
  },
  ἐδόθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G1325",
    gloss: "to give",
  },
  Ἐδόθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G1325",
    gloss: "to give",
  },
  ἐδόθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G1325",
    gloss: "to give",
  },
  ἔδωκα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G1325",
    gloss: "to give",
  },
  ἐδώκαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G1325",
    gloss: "to give",
  },
  ἔδωκαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1325",
    gloss: "to give",
  },
  ἔδωκας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G1325",
    gloss: "to give",
  },
  ἔδωκάς: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G1325",
    gloss: "to give",
  },
  ἐδώκατέ: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G1325",
    gloss: "to give",
  },
  ἔδωκεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1325",
    gloss: "to give",
  },
  Ἔδωκεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1325",
    gloss: "to give",
  },
  ἔδωκέν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1325",
    gloss: "to give",
  },
  διεγείρειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1326",
    gloss: "to arouse",
  },
  διεγείρετο: {
    parse: "V-IPI-3S｜Verb, imperfect, passive, indicative, third, singular",
    GN: "G1326",
    gloss: "to arouse",
  },
  διεγείρω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G1326",
    gloss: "to arouse",
  },
  διεγερθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G1326",
    gloss: "to arouse",
  },
  διήγειραν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1326",
    gloss: "to arouse",
  },
  διεξόδους: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G1327",
    gloss: "thoroughfare",
  },
  διερμηνευτής: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1328",
    gloss: "interpreter",
  },
  διερμηνευέτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G1329",
    gloss: "to interpret",
  },
  διερμηνεύῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G1329",
    gloss: "to interpret",
  },
  διερμηνευομένη: {
    parse:
      "V-PPP-NSF｜Verb, present, passive, Participle, nominative, singular, feminine",
    GN: "G1329",
    gloss: "to interpret",
  },
  διερμηνεύουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1329",
    gloss: "to interpret",
  },
  διερμήνευσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1329",
    gloss: "to interpret",
  },
  διελεύσεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G1330",
    gloss: "to pass through",
  },
  διεληλυθότα: {
    parse:
      "V-2RAP-ASM｜Verb, 2nd perfect, active, Participle, accusative, singular, masculine",
    GN: "G1330",
    gloss: "to pass through",
  },
  διελθεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G1330",
    gloss: "to pass through",
  },
  διελθόντα: {
    parse:
      "V-2AAP-ASM｜Verb, 2nd aorist, active, Participle, accusative, singular, masculine",
    GN: "G1330",
    gloss: "to pass through",
  },
  διελθόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G1330",
    gloss: "to pass through",
  },
  διέλθω: {
    parse: "V-2AAS-1S｜Verb, 2nd aorist, active, subjunctive, first, singular",
    GN: "G1330",
    gloss: "to pass through",
  },
  Διέλθωμεν: {
    parse: "V-2AAS-1P｜Verb, 2nd aorist, active, subjunctive, first, plural",
    GN: "G1330",
    gloss: "to pass through",
  },
  διελθὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G1330",
    gloss: "to pass through",
  },
  διέρχεσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G1330",
    gloss: "to pass through",
  },
  διέρχεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G1330",
    gloss: "to pass through",
  },
  διέρχομαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G1330",
    gloss: "to pass through",
  },
  διερχόμενον: {
    parse:
      "V-PNP-ASM｜Verb, present, Middle or Passive deponent, Participle, accusative, singular, masculine",
    GN: "G1330",
    gloss: "to pass through",
  },
  διερχόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G1330",
    gloss: "to pass through",
  },
  διέρχωμαι: {
    parse:
      "V-PNS-1S｜Verb, present, Middle or Passive deponent, subjunctive, first, singular",
    GN: "G1330",
    gloss: "to pass through",
  },
  διῆλθεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G1330",
    gloss: "to pass through",
  },
  διῆλθον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G1330",
    gloss: "to pass through",
  },
  Διῆλθον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G1330",
    gloss: "to pass through",
  },
  διήρχετο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G1330",
    gloss: "to pass through",
  },
  διήρχοντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G1330",
    gloss: "to pass through",
  },
  διερωτήσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G1331",
    gloss: "to ascertain",
  },
  διετοῦς: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G1332",
    gloss: "two years old",
  },
  διετίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1333",
    gloss: "two years",
  },
  Διετίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1333",
    gloss: "two years",
  },
  διηγήσαντο: {
    parse: "V-ADI-3P｜Verb, aorist, Middle deponent, indicative, third, plural",
    GN: "G1334",
    gloss: "to relate fully",
  },
  διηγήσατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G1334",
    gloss: "to relate fully",
  },
  διηγήσεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G1334",
    gloss: "to relate fully",
  },
  διηγήσωνται: {
    parse:
      "V-ADS-3P｜Verb, aorist, Middle deponent, subjunctive, third, plural",
    GN: "G1334",
    gloss: "to relate fully",
  },
  διηγοῦ: {
    parse:
      "V-PNM-2S｜Verb, present, Middle or Passive deponent, imperative, second, singular",
    GN: "G1334",
    gloss: "to relate fully",
  },
  διηγούμενον: {
    parse:
      "V-PNP-ASM｜Verb, present, Middle or Passive deponent, Participle, accusative, singular, masculine",
    GN: "G1334",
    gloss: "to relate fully",
  },
  διήγησιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1335",
    gloss: "narrative",
  },
  διηνεκές: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G1336",
    gloss: "perpetual",
  },
  διηνεκὲς: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G1336",
    gloss: "perpetual",
  },
  διθάλασσον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G1337",
    gloss: "sandbar",
  },
  διϊκνούμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G1338",
    gloss: "to penetrate",
  },
  διαστάσης: {
    parse:
      "V-2AAP-GSF｜Verb, 2nd aorist, active, Participle, genitive, singular, feminine",
    GN: "G1339",
    gloss: "to pass",
  },
  διαστήσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G1339",
    gloss: "to pass",
  },
  διέστη: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G1339",
    gloss: "to pass",
  },
  διϊσχυρίζετο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G1340",
    gloss: "to insist",
  },
  δικαιοκρισίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1341",
    gloss: "justice",
  },
  δίκαια: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G1342",
    gloss: "just",
  },
  δικαία: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G1342",
    gloss: "just",
  },
  δίκαιαι: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G1342",
    gloss: "just",
  },
  δικαίαν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G1342",
    gloss: "just",
  },
  δικαίας: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G1342",
    gloss: "just",
  },
  δίκαιε: {
    // or nominative
    parse: "A-VSM｜Adjective, Vocative, singular, masculine",
    GN: "G1342",
    gloss: "just",
  },
  δίκαιοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G1342",
    gloss: "just",
  },
  δικαίοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G1342",
    gloss: "just",
  },
  δίκαιον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G1342",
    gloss: "just",
  },
  δίκαιόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G1342",
    gloss: "just",
  },
  δίκαιος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1342",
    gloss: "just",
  },
  Δίκαιος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1342",
    gloss: "just",
  },
  δίκαιός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1342",
    gloss: "just",
  },
  δικαίου: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G1342",
    gloss: "just",
  },
  δικαίους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G1342",
    gloss: "just",
  },
  δικαίῳ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G1342",
    gloss: "just",
  },
  δικαίων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G1342",
    gloss: "just",
  },
  δικαιοσύνῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1343",
    gloss: "righteousness",
  },
  δικαιοσύνη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1343",
    gloss: "righteousness",
  },
  δικαιοσύνην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1343",
    gloss: "righteousness",
  },
  δικαιοσύνης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1343",
    gloss: "righteousness",
  },
  δεδικαίωμαι: {
    parse: "V-RPI-1S｜Verb, perfect, passive, indicative, first, singular",
    GN: "G1344",
    gloss: "to justify",
  },
  δεδικαιωμένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G1344",
    gloss: "to justify",
  },
  δεδικαίωται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G1344",
    gloss: "to justify",
  },
  δικαιοῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1344",
    gloss: "to justify",
  },
  δικαιούμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G1344",
    gloss: "to justify",
  },
  δικαιοῦντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G1344",
    gloss: "to justify",
  },
  δικαιοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1344",
    gloss: "to justify",
  },
  δικαιοῦσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G1344",
    gloss: "to justify",
  },
  δικαιοῦσθε: {
    parse: "V-PPI-2P｜Verb, present, passive, indicative, second, plural",
    GN: "G1344",
    gloss: "to justify",
  },
  δικαιοῦται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G1344",
    gloss: "to justify",
  },
  δικαιωθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G1344",
    gloss: "to justify",
  },
  Δικαιωθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G1344",
    gloss: "to justify",
  },
  δικαιωθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G1344",
    gloss: "to justify",
  },
  δικαιωθῇς: {
    parse: "V-APS-2S｜Verb, aorist, passive, subjunctive, second, singular",
    GN: "G1344",
    gloss: "to justify",
  },
  δικαιωθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G1344",
    gloss: "to justify",
  },
  δικαιωθήσῃ: {
    parse: "V-FPI-2S｜Verb, future, passive, indicative, second, singular",
    GN: "G1344",
    gloss: "to justify",
  },
  δικαιωθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G1344",
    gloss: "to justify",
  },
  δικαιωθῶμεν: {
    parse: "V-APS-1P｜Verb, aorist, passive, subjunctive, first, plural",
    GN: "G1344",
    gloss: "to justify",
  },
  δικαιῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1344",
    gloss: "to justify",
  },
  δικαιῶσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1344",
    gloss: "to justify",
  },
  δικαιώσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G1344",
    gloss: "to justify",
  },
  ἐδικαιώθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G1344",
    gloss: "to justify",
  },
  ἐδικαιώθητε: {
    parse: "V-API-2P｜Verb, aorist, passive, indicative, second, plural",
    GN: "G1344",
    gloss: "to justify",
  },
  ἐδικαίωσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1344",
    gloss: "to justify",
  },
  ἐδικαίωσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1344",
    gloss: "to justify",
  },
  δικαίωμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G1345",
    gloss: "righteous act",
  },
  δικαιώμασιν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G1345",
    gloss: "righteous act",
  },
  δικαιώματα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G1345",
    gloss: "righteous act",
  },
  δικαιώματά: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G1345",
    gloss: "righteous act",
  },
  δικαιώματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G1345",
    gloss: "righteous act",
  },
  δικαίως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1346",
    gloss: "rightly",
  },
  δικαίωσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1347",
    gloss: "justification",
  },
  δικαστήν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1348",
    gloss: "magistrate",
  },
  δικαστὴν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1348",
    gloss: "magistrate",
  },
  δίκη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1349",
    gloss: "condemnation",
  },
  δίκην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1349",
    gloss: "condemnation",
  },
  δίκτυα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G1350",
    gloss: "net",
  },
  δίκτυον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G1350",
    gloss: "net",
  },
  διλόγους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G1351",
    gloss: "insincere",
  },
  διό: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G1352",
    gloss: "therefore",
  },
  Διό: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G1352",
    gloss: "therefore",
  },
  διὸ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G1352",
    gloss: "therefore",
  },
  Διὸ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G1352",
    gloss: "therefore",
  },
  Διοδεύσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G1353",
    gloss: "to go through",
  },
  διώδευεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1353",
    gloss: "to go through",
  },
  Διονύσιος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G1354",
    gloss: "Dionysius",
  },
  διόπερ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G1355",
    gloss: "for",
  },
  Διόπερ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G1355",
    gloss: "for",
  },
  διοπετοῦς: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G1356",
    gloss: "fallen from heaven",
  },
  διορθωμάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G1357",
    gloss: "reformation",
  },
  διορθώσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1357",
    gloss: "reformation",
  },
  διορύσσουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1358",
    gloss: "to break in",
  },
  διορυγῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G1358",
    gloss: "to break in",
  },
  διορυχθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G1358",
    gloss: "to break in",
  },
  Διοσκούροις: {
    parse: "N-DPM-T｜Noun, dative, plural, masculine, Title",
    GN: "G1359",
    gloss: "the twin gods",
  },
  διότι: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G1360",
    gloss: "because",
  },
  Διότι: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G1360",
    gloss: "because",
  },
  Διοτρέφης: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G1361",
    gloss: "Diotrephes",
  },
  διπλᾶ: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G1362",
    gloss: "double",
  },
  διπλῆς: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G1362",
    gloss: "double",
  },
  διπλότερον: {
    parse: "A-ASM-C｜Adjective, accusative, singular, masculine, Comparative",
    GN: "G1362",
    gloss: "double",
  },
  διπλοῦν: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G1362",
    gloss: "double",
  },
  διπλώσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G1363",
    gloss: "to double",
  },
  δίς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1364",
    gloss: "twice",
  },
  δὶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1364",
    gloss: "twice",
  },
  ἐδίστασαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1365",
    gloss: "to doubt",
  },
  ἐδίστασας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G1365",
    gloss: "to doubt",
  },
  δίστομον: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G1366",
    gloss: "double-edged",
  },
  δίστομος: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G1366",
    gloss: "double-edged",
  },
  δισχίλιοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G1367",
    gloss: "two thousand",
  },
  διϋλίζοντες: {
    parse:
      "V-PAP-VPM｜Verb, present, active, Participle, Vocative or nominative, plural, masculine",
    GN: "G1368",
    gloss: "to strain out",
  },
  διχάσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1369",
    gloss: "to disunite",
  },
  διχοστασίαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G1370",
    gloss: "dissension",
  },
  διχοστασίας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G1370",
    gloss: "dissension",
  },
  διχοτομήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G1371",
    gloss: "to cut in two",
  },
  διψᾷ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G1372",
    gloss: "to thirst",
  },
  διψήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G1372",
    gloss: "to thirst",
  },
  διψήσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G1372",
    gloss: "to thirst",
  },
  Διψῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G1372",
    gloss: "to thirst",
  },
  διψῶ: {
    parse: "V-PAS-1S｜Verb, present, active, subjunctive, first, singular",
    GN: "G1372",
    gloss: "to thirst",
  },
  διψῶμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G1372",
    gloss: "to thirst",
  },
  διψῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1372",
    gloss: "to thirst",
  },
  διψῶντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G1372",
    gloss: "to thirst",
  },
  διψῶντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1372",
    gloss: "to thirst",
  },
  διψῶντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G1372",
    gloss: "to thirst",
  },
  ἐδίψησα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G1372",
    gloss: "to thirst",
  },
  δίψει: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G1373",
    gloss: "thirst",
  },
  δίψυχοι: {
    // or nominative
    parse: "A-VPM｜Adjective, Vocative, plural, masculine",
    GN: "G1374",
    gloss: "double-minded",
  },
  δίψυχος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1374",
    gloss: "double-minded",
  },
  διωγμοῖς: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G1375",
    gloss: "persecution",
  },
  διωγμὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1375",
    gloss: "persecution",
  },
  διωγμὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1375",
    gloss: "persecution",
  },
  διωγμοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G1375",
    gloss: "persecution",
  },
  διωγμοὺς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G1375",
    gloss: "persecution",
  },
  διωγμῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G1375",
    gloss: "persecution",
  },
  διώκτην: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1376",
    gloss: "persecutor",
  },
  δεδιωγμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G1377",
    gloss: "to pursue",
  },
  δίωκε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G1377",
    gloss: "to pursue",
  },
  διώκεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G1377",
    gloss: "to pursue",
  },
  διώκετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G1377",
    gloss: "to pursue",
  },
  Διώκετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G1377",
    gloss: "to pursue",
  },
  διώκομαι: {
    parse: "V-PPI-1S｜Verb, present, passive, indicative, first, singular",
    GN: "G1377",
    gloss: "to pursue",
  },
  διωκόμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G1377",
    gloss: "to pursue",
  },
  διώκοντα: {
    parse:
      "V-PAP-NPN｜Verb, present, active, Participle, nominative, plural, neuter",
    GN: "G1377",
    gloss: "to pursue",
  },
  διώκοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G1377",
    gloss: "to pursue",
  },
  διώκοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1377",
    gloss: "to pursue",
  },
  διωκόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G1377",
    gloss: "to pursue",
  },
  διώκω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G1377",
    gloss: "to pursue",
  },
  διώκωμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G1377",
    gloss: "to pursue",
  },
  διώκων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1377",
    gloss: "to pursue",
  },
  διώκωνται: {
    parse: "V-PPS-3P｜Verb, present, passive, subjunctive, third, plural",
    GN: "G1377",
    gloss: "to pursue",
  },
  διώκωσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G1377",
    gloss: "to pursue",
  },
  διωξάτω: {
    parse: "V-AAM-3S｜Verb, aorist, active, imperative, third, singular",
    GN: "G1377",
    gloss: "to pursue",
  },
  διώξετε: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G1377",
    gloss: "to pursue",
  },
  διώξητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G1377",
    gloss: "to pursue",
  },
  διώξουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G1377",
    gloss: "to pursue",
  },
  διώξωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G1377",
    gloss: "to pursue",
  },
  διωχθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G1377",
    gloss: "to pursue",
  },
  ἐδίωκε: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1377",
    gloss: "to pursue",
  },
  ἐδίωκεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1377",
    gloss: "to pursue",
  },
  ἐδίωκον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1377",
    gloss: "to pursue",
  },
  ἐδίωξα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G1377",
    gloss: "to pursue",
  },
  ἐδίωξαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1377",
    gloss: "to pursue",
  },
  ἐδίωξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1377",
    gloss: "to pursue",
  },
  δόγμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G1378",
    gloss: "decree",
  },
  δόγμασιν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G1378",
    gloss: "decree",
  },
  δόγματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G1378",
    gloss: "decree",
  },
  δογμάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G1378",
    gloss: "decree",
  },
  δογματίζεσθε: {
    parse: "V-PPI-2P｜Verb, present, passive, indicative, second, plural",
    GN: "G1379",
    gloss: "to decree",
  },
  δοκεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1380",
    gloss: "to think",
  },
  δοκεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1380",
    gloss: "to think",
  },
  δοκεῖς: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G1380",
    gloss: "to think",
  },
  δοκεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G1380",
    gloss: "to think",
  },
  Δοκεῖτε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G1380",
    gloss: "to think",
  },
  δοκῇ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G1380",
    gloss: "to think",
  },
  δοκοῦμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G1380",
    gloss: "to think",
  },
  δοκοῦν: {
    parse:
      "V-PAP-ASN｜Verb, present, active, Participle, accusative, singular, neuter",
    GN: "G1380",
    gloss: "to think",
  },
  δοκοῦντα: {
    parse:
      "V-PAP-NPN｜Verb, present, active, Participle, nominative, plural, neuter",
    GN: "G1380",
    gloss: "to think",
  },
  δοκοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1380",
    gloss: "to think",
  },
  δοκούντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G1380",
    gloss: "to think",
  },
  δοκοῦσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G1380",
    gloss: "to think",
  },
  δοκοῦσιν: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G1380",
    gloss: "to think",
  },
  δοκῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G1380",
    gloss: "to think",
  },
  δοκῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1380",
    gloss: "to think",
  },
  δόξαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G1380",
    gloss: "to think",
  },
  δόξῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1391",
    gloss: "glory",
  },
  δόξητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G1380",
    gloss: "to think",
  },
  δόξω: {
    parse: "V-AAS-1S｜Verb, aorist, active, subjunctive, first, singular",
    GN: "G1380",
    gloss: "to think",
  },
  ἐδόκει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1380",
    gloss: "to think",
  },
  ἐδόκουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1380",
    gloss: "to think",
  },
  ἔδοξα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G1380",
    gloss: "to think",
  },
  ἔδοξαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1380",
    gloss: "to think",
  },
  ἔδοξε: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1380",
    gloss: "to think",
  },
  ἔδοξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1380",
    gloss: "to think",
  },
  δεδοκιμάσμεθα: {
    parse: "V-RPI-1P｜Verb, perfect, passive, indicative, first, plural",
    GN: "G1381",
    gloss: "to test",
  },
  δοκιμάζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1381",
    gloss: "to test",
  },
  δοκιμάζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1381",
    gloss: "to test",
  },
  δοκιμάζεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G1381",
    gloss: "to test",
  },
  δοκιμαζέσθωσαν: {
    parse: "V-PPM-3P｜Verb, present, passive, imperative, third, plural",
    GN: "G1381",
    gloss: "to test",
  },
  δοκιμάζετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G1381",
    gloss: "to test",
  },
  δοκιμαζέτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G1381",
    gloss: "to test",
  },
  δοκιμαζομένου: {
    parse:
      "V-PPP-GSN｜Verb, present, passive, Participle, genitive, singular, neuter",
    GN: "G1381",
    gloss: "to test",
  },
  δοκιμάζοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1381",
    gloss: "to test",
  },
  δοκιμάζοντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G1381",
    gloss: "to test",
  },
  δοκιμάζων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1381",
    gloss: "to test",
  },
  δοκιμάσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1381",
    gloss: "to test",
  },
  δοκιμάσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G1381",
    gloss: "to test",
  },
  δοκιμάσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G1381",
    gloss: "to test",
  },
  ἐδοκιμάσαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G1381",
    gloss: "to test",
  },
  ἐδοκίμασαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1381",
    gloss: "to test",
  },
  δοκιμῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1382",
    gloss: "test",
  },
  δοκιμὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1382",
    gloss: "test",
  },
  δοκιμήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1382",
    gloss: "test",
  },
  δοκιμὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1382",
    gloss: "test",
  },
  δοκιμῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1382",
    gloss: "test",
  },
  δοκίμιον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G1383",
    gloss: "testing",
  },
  δόκιμοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G1384",
    gloss: "tested",
  },
  δόκιμον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G1384",
    gloss: "tested",
  },
  δόκιμος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1384",
    gloss: "tested",
  },
  δοκόν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1385",
    gloss: "plank",
  },
  δοκὸν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1385",
    gloss: "plank",
  },
  δοκὸς: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1385",
    gloss: "plank",
  },
  δόλιοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G1386",
    gloss: "deceitful",
  },
  ἐδολιοῦσαν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1387",
    gloss: "to deceive",
  },
  δόλον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1388",
    gloss: "deceit",
  },
  δόλος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1388",
    gloss: "deceit",
  },
  δόλου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G1388",
    gloss: "deceit",
  },
  δόλῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G1388",
    gloss: "deceit",
  },
  δολοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1389",
    gloss: "to distort",
  },
  δόμα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G1390",
    gloss: "gift",
  },
  δόματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G1390",
    gloss: "gift",
  },
  δόξα: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1391",
    gloss: "glory",
  },
  Δόξα: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1391",
    gloss: "glory",
  },
  δόξαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1391",
    gloss: "glory",
  },
  δόξας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G1391",
    gloss: "glory",
  },
  δόξης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1391",
    gloss: "glory",
  },
  δεδόξασμαι: {
    parse: "V-RPI-1S｜Verb, perfect, passive, indicative, first, singular",
    GN: "G1392",
    gloss: "to glorify",
  },
  δεδοξασμένῃ: {
    parse:
      "V-RPP-DSF｜Verb, perfect, passive, Participle, dative, singular, feminine",
    GN: "G1392",
    gloss: "to glorify",
  },
  δεδοξασμένον: {
    parse:
      "V-RPP-NSN｜Verb, perfect, passive, Participle, nominative, singular, neuter",
    GN: "G1392",
    gloss: "to glorify",
  },
  δεδόξασται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G1392",
    gloss: "to glorify",
  },
  δοξάζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1392",
    gloss: "to glorify",
  },
  δοξάζεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G1392",
    gloss: "to glorify",
  },
  δοξαζέτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G1392",
    gloss: "to glorify",
  },
  δοξάζηται: {
    parse: "V-PPS-3S｜Verb, present, passive, subjunctive, third, singular",
    GN: "G1392",
    gloss: "to glorify",
  },
  δοξάζητε: {
    parse: "V-PAS-2P｜Verb, present, active, subjunctive, second, plural",
    GN: "G1392",
    gloss: "to glorify",
  },
  δοξαζόμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G1392",
    gloss: "to glorify",
  },
  δοξάζοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1392",
    gloss: "to glorify",
  },
  δοξάζω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G1392",
    gloss: "to glorify",
  },
  δοξάζων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1392",
    gloss: "to glorify",
  },
  δοξάσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1392",
    gloss: "to glorify",
  },
  δοξάσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G1392",
    gloss: "to glorify",
  },
  δοξάσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G1392",
    gloss: "to glorify",
  },
  δοξάσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G1392",
    gloss: "to glorify",
  },
  δοξασθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G1392",
    gloss: "to glorify",
  },
  δοξασθῶσιν: {
    parse: "V-APS-3P｜Verb, aorist, passive, subjunctive, third, plural",
    GN: "G1392",
    gloss: "to glorify",
  },
  δόξασόν: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G1392",
    gloss: "to glorify",
  },
  δοξάσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G1392",
    gloss: "to glorify",
  },
  δοξάσωσι: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G1392",
    gloss: "to glorify",
  },
  δοξάσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G1392",
    gloss: "to glorify",
  },
  ἐδόξαζεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1392",
    gloss: "to glorify",
  },
  ἐδόξαζον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1392",
    gloss: "to glorify",
  },
  ἐδόξασα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G1392",
    gloss: "to glorify",
  },
  ἐδόξασαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1392",
    gloss: "to glorify",
  },
  ἐδόξασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1392",
    gloss: "to glorify",
  },
  ἐδοξάσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G1392",
    gloss: "to glorify",
  },
  Δορκάς: {
    parse: "N-NSF-P｜Noun, nominative, singular, feminine, Person",
    GN: "G1393",
    gloss: "Dorcas",
  },
  δόσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1394",
    gloss: "gift",
  },
  δόσις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1394",
    gloss: "gift",
  },
  δότην: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1395",
    gloss: "giver",
  },
  δουλαγωγῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G1396",
    gloss: "to enslave",
  },
  δουλείαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1397",
    gloss: "slavery",
  },
  δουλείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1397",
    gloss: "slavery",
  },
  δεδουλεύκαμεν: {
    parse: "V-RAI-1P｜Verb, perfect, active, indicative, first, plural",
    GN: "G1398",
    gloss: "be a slave",
  },
  δουλεύει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1398",
    gloss: "be a slave",
  },
  δουλεύειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1398",
    gloss: "be a slave",
  },
  δουλεύετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G1398",
    gloss: "be a slave",
  },
  δουλευέτωσαν: {
    parse: "V-PAM-3P｜Verb, present, active, imperative, third, plural",
    GN: "G1398",
    gloss: "be a slave",
  },
  δουλεύοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1398",
    gloss: "be a slave",
  },
  δουλεύουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1398",
    gloss: "be a slave",
  },
  δουλεύσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G1398",
    gloss: "be a slave",
  },
  δουλεύσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G1398",
    gloss: "be a slave",
  },
  δουλεύω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G1398",
    gloss: "be a slave",
  },
  δουλεύων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1398",
    gloss: "be a slave",
  },
  ἐδουλεύσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G1398",
    gloss: "be a slave",
  },
  ἐδούλευσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1398",
    gloss: "be a slave",
  },
  δοῦλα: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G1401",
    gloss: "slave",
  },
  δούλας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G1401",
    gloss: "slave",
  },
  δοῦλε: {
    // or nominative
    parse: "N-VSM｜Noun, Vocative, singular, masculine",
    GN: "G1401",
    gloss: "slave",
  },
  Δοῦλε: {
    // or nominative
    parse: "N-VSM｜Noun, Vocative, singular, masculine",
    GN: "G1401",
    gloss: "slave",
  },
  δούλη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1401",
    gloss: "slave",
  },
  δούλης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1401",
    gloss: "slave",
  },
  δοῦλοι: {
    parse: "N-VPM｜Noun, Vocative or nominative, plural, masculine",
    GN: "G1401",
    gloss: "slave",
  },
  Δοῦλοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G1401",
    gloss: "slave",
  },
  δοῦλοί: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G1401",
    gloss: "slave",
  },
  δούλοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G1401",
    gloss: "slave",
  },
  δοῦλον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1401",
    gloss: "slave",
  },
  δοῦλόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1401",
    gloss: "slave",
  },
  δοῦλος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1401",
    gloss: "slave",
  },
  Δοῦλος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1401",
    gloss: "slave",
  },
  δοῦλός: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1401",
    gloss: "slave",
  },
  δούλου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G1401",
    gloss: "slave",
  },
  δούλους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G1401",
    gloss: "slave",
  },
  Δούλους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G1401",
    gloss: "slave",
  },
  δούλῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G1401",
    gloss: "slave",
  },
  δούλων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G1401",
    gloss: "slave",
  },
  δεδουλωμένας: {
    parse:
      "V-RPP-APF｜Verb, perfect, passive, Participle, accusative, plural, feminine",
    GN: "G1402",
    gloss: "to enslave",
  },
  δεδουλωμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G1402",
    gloss: "to enslave",
  },
  δεδούλωται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G1402",
    gloss: "to enslave",
  },
  δουλωθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G1402",
    gloss: "to enslave",
  },
  δουλώσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G1402",
    gloss: "to enslave",
  },
  ἐδουλώθητε: {
    parse: "V-API-2P｜Verb, aorist, passive, indicative, second, plural",
    GN: "G1402",
    gloss: "to enslave",
  },
  ἐδούλωσα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G1402",
    gloss: "to enslave",
  },
  δοχὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1403",
    gloss: "banquet",
  },
  δράκοντα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1404",
    gloss: "dragon",
  },
  δράκοντι: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G1404",
    gloss: "dragon",
  },
  δράκοντος: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G1404",
    gloss: "dragon",
  },
  δράκων: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1404",
    gloss: "dragon",
  },
  δρασσόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G1405",
    gloss: "to catch",
  },
  δραχμὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G1406",
    gloss: "drachma",
  },
  δραχμὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1406",
    gloss: "drachma",
  },
  δρέπανον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G1407",
    gloss: "sickle",
  },
  δρέπανόν: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G1407",
    gloss: "sickle",
  },
  δρόμον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1408",
    gloss: "racecourse",
  },
  Δρουσίλλῃ: {
    parse: "N-DSF-P｜Noun, dative, singular, feminine, Person",
    GN: "G1409",
    gloss: "Drusilla",
  },
  δυναίμην: {
    parse:
      "V-PNO-1S｜Verb, present, Middle or Passive deponent, Optative, first, singular",
    GN: "G1410",
    gloss: "be able",
  },
  δύναιντο: {
    parse:
      "V-PNO-3P｜Verb, present, Middle or Passive deponent, Optative, third, plural",
    GN: "G1410",
    gloss: "be able",
  },
  δύναμαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G1410",
    gloss: "be able",
  },
  Δύναμαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G1410",
    gloss: "be able",
  },
  δύναμαί: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G1410",
    gloss: "be able",
  },
  δυνάμεθά: {
    parse: "V-PMI-1P｜Verb, present, middle, indicative, first, plural",
    GN: "G1410",
    gloss: "be able",
  },
  δυνάμεθα: {
    parse:
      "V-PNI-1P｜Verb, present, Middle or Passive deponent, indicative, first, plural",
    GN: "G1410",
    gloss: "be able",
  },
  Δυνάμεθα: {
    parse:
      "V-PNI-1P｜Verb, present, Middle or Passive deponent, indicative, first, plural",
    GN: "G1410",
    gloss: "be able",
  },
  δυνάμενά: {
    parse:
      "V-PMP-APN｜Verb, present, middle, Participle, accusative, plural, neuter",
    GN: "G1410",
    gloss: "be able",
  },
  δυνάμενα: {
    parse:
      "V-PNP-APN｜Verb, present, Middle or Passive deponent, Participle, accusative, plural, neuter",
    GN: "G1410",
    gloss: "be able",
  },
  δυνάμεναι: {
    parse:
      "V-PNP-NPF｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, feminine",
    GN: "G1410",
    gloss: "be able",
  },
  δυναμένη: {
    parse:
      "V-PNP-NSF｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, feminine",
    GN: "G1410",
    gloss: "be able",
  },
  δυνάμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G1410",
    gloss: "be able",
  },
  δυνάμενον: {
    parse:
      "V-PNP-ASM｜Verb, present, Middle or Passive deponent, Participle, accusative, singular, masculine",
    GN: "G1410",
    gloss: "be able",
  },
  δυνάμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G1410",
    gloss: "be able",
  },
  δυναμένου: {
    parse:
      "V-PNP-GSN｜Verb, present, Middle or Passive deponent, Participle, genitive, singular, neuter",
    GN: "G1410",
    gloss: "be able",
  },
  δυναμένους: {
    parse:
      "V-PNP-APM｜Verb, present, Middle or Passive deponent, Participle, accusative, plural, masculine",
    GN: "G1410",
    gloss: "be able",
  },
  δυναμένῳ: {
    parse:
      "V-PNP-DSM｜Verb, present, Middle or Passive deponent, Participle, dative, singular, masculine",
    GN: "G1410",
    gloss: "be able",
  },
  δυναμένων: {
    parse:
      "V-PNP-GPM｜Verb, present, Middle or Passive deponent, Participle, genitive, plural, masculine",
    GN: "G1410",
    gloss: "be able",
  },
  δύνανται: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G1410",
    gloss: "be able",
  },
  δύνανταί: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G1410",
    gloss: "be able",
  },
  δύνασαι: {
    parse:
      "V-PNI-2S｜Verb, present, Middle or Passive deponent, indicative, second, singular",
    GN: "G1410",
    gloss: "be able",
  },
  δύνασαί: {
    parse:
      "V-PNI-2S｜Verb, present, Middle or Passive deponent, indicative, second, singular",
    GN: "G1410",
    gloss: "be able",
  },
  δύνασθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G1410",
    gloss: "be able",
  },
  δύνασθε: {
    parse:
      "V-PNI-2P｜Verb, present, Middle or Passive deponent, indicative, second, plural",
    GN: "G1410",
    gloss: "be able",
  },
  δύναται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G1410",
    gloss: "be able",
  },
  δύναταί: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G1410",
    gloss: "be able",
  },
  δύνῃ: {
    parse:
      "V-PNI-2S｜Verb, present, Middle or Passive deponent, indicative, second, singular",
    GN: "G1410",
    gloss: "be able",
  },
  δυνηθῆτε: {
    parse:
      "V-AOS-2P｜Verb, aorist, Passive deponent, subjunctive, second, plural",
    GN: "G1410",
    gloss: "be able",
  },
  δυνήσεσθε: {
    parse:
      "V-FDI-2P｜Verb, future, Middle deponent, indicative, second, plural",
    GN: "G1410",
    gloss: "be able",
  },
  δυνήσεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G1410",
    gloss: "be able",
  },
  δυνήσεταί: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G1410",
    gloss: "be able",
  },
  δυνήσῃ: {
    parse:
      "V-FDI-2S｜Verb, future, Middle deponent, indicative, second, singular",
    GN: "G1410",
    gloss: "be able",
  },
  δυνησόμεθα: {
    parse: "V-FDI-1P｜Verb, future, Middle deponent, indicative, first, plural",
    GN: "G1410",
    gloss: "be able",
  },
  δυνήσονται: {
    parse: "V-FDI-3P｜Verb, future, Middle deponent, indicative, third, plural",
    GN: "G1410",
    gloss: "be able",
  },
  δύνηται: {
    parse:
      "V-PNS-3S｜Verb, present, Middle or Passive deponent, subjunctive, third, singular",
    GN: "G1410",
    gloss: "be able",
  },
  δύνωνται: {
    parse:
      "V-PNS-3P｜Verb, present, Middle or Passive deponent, subjunctive, third, plural",
    GN: "G1410",
    gloss: "be able",
  },
  ἐδύνασθε: {
    parse:
      "V-INI-2P｜Verb, imperfect, Middle or Passive deponent, indicative, second, plural",
    GN: "G1410",
    gloss: "be able",
  },
  ἐδύνατο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G1410",
    gloss: "be able",
  },
  ἠδύναντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G1410",
    gloss: "be able",
  },
  ἠδύνατο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G1410",
    gloss: "be able",
  },
  ἠδυνήθη: {
    parse:
      "V-AOI-3S｜Verb, aorist, Passive deponent, indicative, third, singular",
    GN: "G1410",
    gloss: "be able",
  },
  ἠδυνήθημεν: {
    parse:
      "V-AOI-1P｜Verb, aorist, Passive deponent, indicative, first, plural",
    GN: "G1410",
    gloss: "be able",
  },
  ἠδυνήθην: {
    parse:
      "V-AOI-1S｜Verb, aorist, Passive deponent, indicative, first, singular",
    GN: "G1410",
    gloss: "be able",
  },
  ἠδυνήθησαν: {
    parse:
      "V-AOI-3P｜Verb, aorist, Passive deponent, indicative, third, plural",
    GN: "G1410",
    gloss: "be able",
  },
  ἠδυνήθητε: {
    parse:
      "V-AOI-2P｜Verb, aorist, Passive deponent, indicative, second, plural",
    GN: "G1410",
    gloss: "be able",
  },
  δυνάμει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1411",
    gloss: "power",
  },
  δυνάμεις: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G1411",
    gloss: "power",
  },
  Δυνάμεις: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G1411",
    gloss: "power",
  },
  δυνάμεσι: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G1411",
    gloss: "power",
  },
  δυνάμεσιν: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G1411",
    gloss: "power",
  },
  δυνάμεων: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G1411",
    gloss: "power",
  },
  δυνάμεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1411",
    gloss: "power",
  },
  δύναμιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1411",
    gloss: "power",
  },
  δύναμίν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1411",
    gloss: "power",
  },
  δύναμις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1411",
    gloss: "power",
  },
  Δύναμις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1411",
    gloss: "power",
  },
  δυναμούμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G1412",
    gloss: "to empower",
  },
  ἐδυναμώθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G1412",
    gloss: "to empower",
  },
  δυνάστας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G1413",
    gloss: "ruler",
  },
  δυνάστης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1413",
    gloss: "ruler",
  },
  δυνατεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1414",
    gloss: "be able",
  },
  δυνατά: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G1415",
    gloss: "able",
  },
  δυνατὰ: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G1415",
    gloss: "able",
  },
  δυνατοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G1415",
    gloss: "able",
  },
  δυνατοὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G1415",
    gloss: "able",
  },
  δυνατὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G1415",
    gloss: "able",
  },
  δυνατόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G1415",
    gloss: "able",
  },
  δυνατός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1415",
    gloss: "able",
  },
  δυνατὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1415",
    gloss: "able",
  },
  Δύνοντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G1416",
    gloss: "to set",
  },
  ἔδυ: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G1416",
    gloss: "to set",
  },
  δύο: {
    parse: "A-NUI｜Indeclinable NUmeral (Adjective)",
    GN: "G1417",
    gloss: "two",
  },
  δυσὶ: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G1417",
    gloss: "two",
  },
  δυσί: {
    parse: "A-DPF｜Adjective, dative, plural, feminine",
    GN: "G1417",
    gloss: "two",
  },
  δυσίν: {
    parse: "A-DPF｜Adjective, dative, plural, feminine",
    GN: "G1417",
    gloss: "two",
  },
  δυσὶν: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G1417",
    gloss: "two",
  },
  δυσβάστακτα: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G1419",
    gloss: "ponderous",
  },
  δυσεντερίῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G1420",
    gloss: "dysentery",
  },
  δυσερμήνευτος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1421",
    gloss: "hard to interpret",
  },
  δύσκολόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G1422",
    gloss: "difficult",
  },
  δυσκόλως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1423",
    gloss: "difficultly",
  },
  δυσμῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G1424",
    gloss: "west",
  },
  δυσνόητά: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G1425",
    gloss: "hard to understand",
  },
  δυσφημίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1426",
    gloss: "slander",
  },
  δώδεκα: {
    parse: "A-NUI｜Indeclinable NUmeral (Adjective)",
    GN: "G1427",
    gloss: "twelve",
  },
  Δώδεκα: {
    parse:
      "A-APM-NUI｜Adjective, accusative, plural, masculine, NUmerical Indiclinable (A)",
    GN: "G1427",
    gloss: "twelve",
  },
  δωδέκατος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1428",
    gloss: "twelfth",
  },
  δωδεκάφυλον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G1429",
    gloss: "twelve tribes",
  },
  δῶμα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G1430",
    gloss: "housetop",
  },
  δώματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G1430",
    gloss: "housetop",
  },
  δωμάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G1430",
    gloss: "housetop",
  },
  δωρεᾷ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1431",
    gloss: "free gift",
  },
  δωρεὰ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1431",
    gloss: "free gift",
  },
  δωρεὰν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1432",
    gloss: "freely",
  },
  δωρεᾶς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1431",
    gloss: "free gift",
  },
  δωρεάν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1432",
    gloss: "freely",
  },
  δεδωρημένης: {
    parse:
      "V-RMP-GSF｜Verb, perfect, middle, Participle, genitive, singular, feminine",
    GN: "G1433",
    gloss: "to give",
  },
  δεδώρηται: {
    parse: "V-RMI-3S｜Verb, perfect, middle, indicative, third, singular",
    GN: "G1433",
    gloss: "to give",
  },
  ἐδωρήσατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G1433",
    gloss: "to give",
  },
  δώρημα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G1434",
    gloss: "free gift",
  },
  δῶρα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G1435",
    gloss: "gift",
  },
  δῶρά: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G1435",
    gloss: "gift",
  },
  δώροις: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G1435",
    gloss: "gift",
  },
  δῶρον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G1435",
    gloss: "gift",
  },
  δῶρόν: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G1435",
    gloss: "gift",
  },
  Δῶρον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G1435",
    gloss: "gift",
  },
  δώρῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G1435",
    gloss: "gift",
  },
  Ἔα: { parse: "INJ｜Interjection", GN: "G1436", gloss: "ha!/aha!" },
  ἐάν: {
    parse: "PRT｜Particle, disjunctive particle",
    GN: "G1437",
    gloss: "if",
  },
  Ἐάν: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G1437",
    gloss: "if",
  },
  ἐὰν: {
    parse: "PRT｜Particle, disjunctive particle",
    GN: "G1437",
    gloss: "if",
  },
  Ἐὰν: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G1437",
    gloss: "if",
  },
  αὑτὴν: {
    parse: "F-3ASF｜Reflexive pronoun, third, accusative, singular, feminine",
    GN: "G1438",
    gloss: "themself",
  },
  αὑτούς: {
    parse: "F-3GSM｜Possessive pronoun, third, accusative, singular, masculine",
    GN: "G1438",
    gloss: "themself",
  },
  αὑτοὺς: {
    parse: "F-3GSM｜Possessive pronoun, third, accusative, singular, masculine",
    GN: "G1438",
    gloss: "themself",
  },
  αὑτοῦ: {
    parse: "F-3GSM｜Reflexive pronoun, third, genitive, singular, masculine",
    GN: "G1438",
    gloss: "themself",
  },
  αὑτῷ: {
    parse: "F-3DSM｜Reflexive pronoun, third, dative, singular, masculine",
    GN: "G1438",
    gloss: "themself",
  },
  ἑαυτὰ: {
    parse: "F-2APN｜Reflexive pronoun, second, accusative, plural, neuter",
    GN: "G1438",
    gloss: "themself",
  },
  ἑαυταῖς: {
    parse: "F-2DPF｜Reflexive pronoun, second, dative, plural, feminine",
    GN: "G1438",
    gloss: "themself",
  },
  ἑαυτὰς: {
    parse: "F-2APF｜Reflexive pronoun, second, accusative, plural, feminine",
    GN: "G1438",
    gloss: "themself",
  },
  ἑαυτάς: {
    parse: "F-3APF｜Reflexive pronoun, third, accusative, plural, feminine",
    GN: "G1438",
    gloss: "themself",
  },
  ἑαυτῇ: {
    parse: "F-3DSF｜Reflexive pronoun, third, dative, singular, feminine",
    GN: "G1438",
    gloss: "themself",
  },
  ἑαυτήν: {
    parse: "F-3ASF｜Reflexive pronoun, third, accusative, singular, feminine",
    GN: "G1438",
    gloss: "themself",
  },
  ἑαυτὴν: {
    parse: "F-3ASF｜Reflexive pronoun, third, accusative, singular, feminine",
    GN: "G1438",
    gloss: "themself",
  },
  ἑαυτῆς: {
    parse: "F-3GSF｜Reflexive pronoun, third, genitive, singular, feminine",
    GN: "G1438",
    gloss: "themself",
  },
  ἑαυτοῖς: {
    parse: "F-3DPM｜Reflexive pronoun, third, dative, plural, masculine",
    GN: "G1438",
    gloss: "themself",
  },
  ἑαυτόν: {
    parse: "F-3ASM｜Reflexive pronoun, third, accusative, singular, masculine",
    GN: "G1438",
    gloss: "themself",
  },
  ἑαυτὸν: {
    parse: "F-3ASM｜Reflexive pronoun, third, accusative, singular, masculine",
    GN: "G1438",
    gloss: "themself",
  },
  ἑαυτοῦ: {
    parse: "F-3GSN｜Reflexive pronoun, third, genitive, singular, neuter",
    GN: "G1438",
    gloss: "themself",
  },
  ἑαυτούς: {
    parse: "F-3APM｜Reflexive pronoun, third, accusative, plural, masculine",
    GN: "G1438",
    gloss: "themself",
  },
  ἑαυτοὺς: {
    parse: "F-3APM｜Reflexive pronoun, third, accusative, plural, masculine",
    GN: "G1438",
    gloss: "themself",
  },
  Ἑαυτοὺς: {
    parse: "F-2APM｜Reflexive pronoun, second, accusative, plural, masculine",
    GN: "G1438",
    gloss: "themself",
  },
  ἑαυτῷ: {
    parse: "F-3DSM｜Reflexive pronoun, third, dative, singular, masculine",
    GN: "G1438",
    gloss: "themself",
  },
  ἑαυτῶν: {
    parse: "F-3GPN｜Reflexive pronoun, third, genitive, plural, neuter",
    GN: "G1438",
    gloss: "themself",
  },
  ἐάσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G1439",
    gloss: "to allow",
  },
  ἐάσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G1439",
    gloss: "to allow",
  },
  Ἐᾶτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G1439",
    gloss: "to allow",
  },
  εἴα: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1439",
    gloss: "to allow",
  },
  εἴασαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1439",
    gloss: "to allow",
  },
  εἴασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1439",
    gloss: "to allow",
  },
  εἴων: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1439",
    gloss: "to allow",
  },
  ἑβδομήκοντα: {
    parse:
      "A-NPM-NUI｜Adjective, nominative, plural, masculine, NUmerical Indiclinable (A)",
    GN: "G1440",
    gloss: "seventy",
  },
  ἑβδομηκοντάκις: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1441",
    gloss: "seventy times",
  },
  ἑβδόμῃ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G1442",
    gloss: "seventh",
  },
  ἑβδόμην: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G1442",
    gloss: "seventh",
  },
  ἑβδόμης: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G1442",
    gloss: "seventh",
  },
  ἕβδομος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1442",
    gloss: "seventh",
  },
  ἑβδόμου: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G1442",
    gloss: "seventh",
  },
  Ἔβερ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G1443",
    gloss: "Eber",
  },
  Ἑβραῖοί: {
    parse: "N-NPM-PG｜Noun, nominative, plural, masculine, Person Gentilic",
    GN: "G1445",
    gloss: "Hebrew",
  },
  Ἑβραῖος: {
    parse: "N-NSM-PG｜Noun, nominative, singular, masculine, Person Gentilic",
    GN: "G1445",
    gloss: "Hebrew",
  },
  Ἑβραίους: {
    parse: "N-APM-PG｜Noun, accusative, plural, masculine, Person Gentilic",
    GN: "G1445",
    gloss: "Hebrew",
  },
  Ἑβραίων: {
    parse: "N-GPM-PG｜Noun, genitive, plural, masculine, Person Gentilic",
    GN: "G1445",
    gloss: "Hebrew",
  },
  Ἑβραΐδι: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G1446",
    gloss: "Hebrew",
  },
  Ἑβραϊστί: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1447",
    gloss: "Hebrew",
  },
  Ἑβραϊστὶ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1447",
    gloss: "Hebrew",
  },
  ἐγγιεῖ: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G1448",
    gloss: "to come near",
  },
  ἐγγίζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1448",
    gloss: "to come near",
  },
  ἐγγίζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1448",
    gloss: "to come near",
  },
  ἐγγίζομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G1448",
    gloss: "to come near",
  },
  ἐγγίζοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1448",
    gloss: "to come near",
  },
  ἐγγίζοντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G1448",
    gloss: "to come near",
  },
  ἐγγίζοντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G1448",
    gloss: "to come near",
  },
  ἐγγιζόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G1448",
    gloss: "to come near",
  },
  ἐγγίζουσαν: {
    parse:
      "V-PAP-ASF｜Verb, present, active, Participle, accusative, singular, feminine",
    GN: "G1448",
    gloss: "to come near",
  },
  ἐγγίζουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1448",
    gloss: "to come near",
  },
  ἐγγίσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1448",
    gloss: "to come near",
  },
  ἐγγίσαντος: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G1448",
    gloss: "to come near",
  },
  ἐγγίσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G1448",
    gloss: "to come near",
  },
  ἐγγίσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G1448",
    gloss: "to come near",
  },
  ἤγγιζεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1448",
    gloss: "to come near",
  },
  Ἤγγιζεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1448",
    gloss: "to come near",
  },
  ἤγγικεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G1448",
    gloss: "to come near",
  },
  Ἤγγικεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G1448",
    gloss: "to come near",
  },
  ἤγγισαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1448",
    gloss: "to come near",
  },
  ἤγγισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1448",
    gloss: "to come near",
  },
  ἐγγεγραμμένη: {
    parse:
      "V-RPP-NSF｜Verb, perfect, passive, Participle, nominative, singular, feminine",
    GN: "G1449",
    gloss: "to write in",
  },
  ἐγγέγραπται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G1449",
    gloss: "to write in",
  },
  ἔγγυος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1450",
    gloss: "guarantor",
  },
  ἐγγύς: { parse: "PREP｜Preposition", GN: "G1451", gloss: "near" },
  ἐγγὺς: { parse: "PREP｜Preposition", GN: "G1451", gloss: "near" },
  Ἐγγύς: { parse: "PREP｜Preposition", GN: "G1451", gloss: "near" },
  ἐγγύτερον: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1452",
    gloss: "nearer",
  },
  ἐγεῖραι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1453",
    gloss: "to arise",
  },
  ἐγείραντα: {
    parse:
      "V-AAP-ASM｜Verb, aorist, active, Participle, accusative, singular, masculine",
    GN: "G1453",
    gloss: "to arise",
  },
  ἐγείραντος: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G1453",
    gloss: "to arise",
  },
  ἐγείρας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G1453",
    gloss: "to arise",
  },
  ἔγειρε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G1453",
    gloss: "to arise",
  },
  Ἔγειρε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G1453",
    gloss: "to arise",
  },
  ἐγείρει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1453",
    gloss: "to arise",
  },
  ἐγείρειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1453",
    gloss: "to arise",
  },
  ἐγείρεσθε: {
    parse:
      "V-PEM-2P｜Verb, present, Either middle or passive, imperative, second, plural",
    GN: "G1453",
    gloss: "to arise",
  },
  Ἐγείρεσθε: {
    parse:
      "V-PEM-2P｜Verb, present, Either middle or passive, imperative, second, plural",
    GN: "G1453",
    gloss: "to arise",
  },
  ἐγείρεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G1453",
    gloss: "to arise",
  },
  ἐγείρετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G1453",
    gloss: "to arise",
  },
  ἐγείρηται: {
    parse: "V-PPS-3S｜Verb, present, passive, subjunctive, third, singular",
    GN: "G1453",
    gloss: "to arise",
  },
  ἐγείρομαι: {
    parse: "V-PPI-1S｜Verb, present, passive, indicative, first, singular",
    GN: "G1453",
    gloss: "to arise",
  },
  ἐγείρονται: {
    parse: "V-PPI-3P｜Verb, present, passive, indicative, third, plural",
    GN: "G1453",
    gloss: "to arise",
  },
  ἐγείροντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G1453",
    gloss: "to arise",
  },
  ἐγείρουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1453",
    gloss: "to arise",
  },
  ἐγερεῖ: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G1453",
    gloss: "to arise",
  },
  ἐγερεῖς: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G1453",
    gloss: "to arise",
  },
  ἐγερθείς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G1453",
    gloss: "to arise",
  },
  ἐγερθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G1453",
    gloss: "to arise",
  },
  Ἐγερθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G1453",
    gloss: "to arise",
  },
  ἐγερθέντι: {
    parse:
      "V-APP-DSM｜Verb, aorist, passive, Participle, dative, singular, masculine",
    GN: "G1453",
    gloss: "to arise",
  },
  ἐγερθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G1453",
    gloss: "to arise",
  },
  ἐγερθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G1453",
    gloss: "to arise",
  },
  ἐγερθῆναί: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G1453",
    gloss: "to arise",
  },
  ἐγερθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G1453",
    gloss: "to arise",
  },
  Ἐγερθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G1453",
    gloss: "to arise",
  },
  ἐγερθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G1453",
    gloss: "to arise",
  },
  Ἐγέρθητε: {
    parse: "V-APM-2P｜Verb, aorist, passive, imperative, second, plural",
    GN: "G1453",
    gloss: "to arise",
  },
  ἐγέρθητι: {
    parse: "V-APM-2S｜Verb, aorist, passive, imperative, second, singular",
    GN: "G1453",
    gloss: "to arise",
  },
  ἐγερῶ: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G1453",
    gloss: "to arise",
  },
  ἐγηγερμένον: {
    parse:
      "V-RPP-ASM｜Verb, perfect, passive, Participle, accusative, singular, masculine",
    GN: "G1453",
    gloss: "to arise",
  },
  ἐγήγερται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G1453",
    gloss: "to arise",
  },
  ἤγειραν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1453",
    gloss: "to arise",
  },
  ἤγειρεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1453",
    gloss: "to arise",
  },
  ἠγέρθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G1453",
    gloss: "to arise",
  },
  Ἠγέρθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G1453",
    gloss: "to arise",
  },
  ἠγέρθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G1453",
    gloss: "to arise",
  },
  ἔγερσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1454",
    gloss: "resurrection",
  },
  ἐγκαθέτους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G1455",
    gloss: "a spy",
  },
  ἐγκαίνια: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G1456",
    gloss: "Feast|Festival of Dedication",
  },
  ἐγκεκαίνισται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G1457",
    gloss: "to inaugurate",
  },
  ἐνεκαίνισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1457",
    gloss: "to inaugurate",
  },
  ἐγκαλεῖσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G1458",
    gloss: "to accuse",
  },
  ἐγκαλείτωσαν: {
    parse: "V-PAM-3P｜Verb, present, active, imperative, third, plural",
    GN: "G1458",
    gloss: "to accuse",
  },
  ἐγκαλέσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G1458",
    gloss: "to accuse",
  },
  ἐγκαλοῦμαι: {
    parse: "V-PPI-1S｜Verb, present, passive, indicative, first, singular",
    GN: "G1458",
    gloss: "to accuse",
  },
  ἐγκαλούμενον: {
    parse:
      "V-PPP-ASM｜Verb, present, passive, Participle, accusative, singular, masculine",
    GN: "G1458",
    gloss: "to accuse",
  },
  ἐνεκάλουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1458",
    gloss: "to accuse",
  },
  ἐγκαταλειπόμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G1459",
    gloss: "to leave behind",
  },
  ἐγκαταλείποντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1459",
    gloss: "to leave behind",
  },
  ἐγκαταλείψεις: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G1459",
    gloss: "to leave behind",
  },
  ἐγκαταλίπω: {
    parse: "V-2AAS-1S｜Verb, 2nd aorist, active, subjunctive, first, singular",
    GN: "G1459",
    gloss: "to leave behind",
  },
  ἐγκατελείφθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G1459",
    gloss: "to leave behind",
  },
  ἐγκατέλιπεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G1459",
    gloss: "to leave behind",
  },
  ἐγκατέλιπες: {
    parse: "V-2AAI-2S｜Verb, 2nd aorist, active, indicative, second, singular",
    GN: "G1459",
    gloss: "to leave behind",
  },
  ἐγκατέλιπές: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G1459",
    gloss: "to leave behind",
  },
  ἐγκατέλιπον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G1459",
    gloss: "to leave behind",
  },
  ἐγκατοικῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1460",
    gloss: "to live among",
  },
  ἐγκεντρίσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1461",
    gloss: "to ingraft",
  },
  ἐγκεντρισθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G1461",
    gloss: "to ingraft",
  },
  ἐγκεντρισθῶ: {
    parse: "V-APS-1S｜Verb, aorist, passive, subjunctive, first, singular",
    GN: "G1461",
    gloss: "to ingraft",
  },
  ἐνεκεντρίσθης: {
    parse: "V-API-2S｜Verb, aorist, passive, indicative, second, singular",
    GN: "G1461",
    gloss: "to ingraft",
  },
  ἔγκλημα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G1462",
    gloss: "accusation",
  },
  ἐγκλήματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G1462",
    gloss: "accusation",
  },
  ἐγκομβώσασθε: {
    parse:
      "V-ADM-2P｜Verb, aorist, Middle deponent, imperative, second, plural",
    GN: "G1463",
    gloss: "to clothe oneself",
  },
  ἐγκοπὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1464",
    gloss: "hindrance",
  },
  ἐγκόπτεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G1465",
    gloss: "to impede",
  },
  ἐγκόπτω: {
    parse: "V-PAS-1S｜Verb, present, active, subjunctive, first, singular",
    GN: "G1465",
    gloss: "to impede",
  },
  ἐνεκοπτόμην: {
    parse: "V-IPI-1S｜Verb, imperfect, passive, indicative, first, singular",
    GN: "G1465",
    gloss: "to impede",
  },
  ἐνέκοψεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1465",
    gloss: "to impede",
  },
  ἐγκρατείᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1466",
    gloss: "self-control",
  },
  ἐγκράτεια: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1466",
    gloss: "self-control",
  },
  ἐγκράτειαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1466",
    gloss: "self-control",
  },
  ἐγκρατείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1466",
    gloss: "self-control",
  },
  ἐγκρατεύεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G1467",
    gloss: "to self-control",
  },
  ἐγκρατεύονται: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G1467",
    gloss: "to self-control",
  },
  ἐγκρατῆ: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G1468",
    gloss: "self-controlled",
  },
  ἐγκρῖναι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1469",
    gloss: "to classify",
  },
  ἐνέκρυψεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1470",
    gloss: "to mix",
  },
  ἐγκύῳ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G1471",
    gloss: "pregnant",
  },
  ἐγχρῖσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1472",
    gloss: "to rub on",
  },
  ἐγώ: {
    parse: "P-1NS｜Personal pronoun, first, nominative, singular",
    GN: "G1473",
    gloss: "I/we",
  },
  Ἐγώ: {
    parse: "P-1NS｜Personal pronoun, first, nominative, singular",
    GN: "G1473",
    gloss: "I/we",
  },
  ἐγὼ: {
    parse: "P-1NS｜Personal pronoun, first, nominative, singular",
    GN: "G1473",
    gloss: "I/we",
  },
  Ἐγὼ: {
    parse: "P-1NS｜Personal pronoun, first, nominative, singular",
    GN: "G1473",
    gloss: "I/we",
  },
  ἐμέ: {
    parse: "P-1AS｜Personal pronoun, first, accusative, singular",
    GN: "G1473",
    gloss: "I/we",
  },
  ἐμὲ: {
    parse: "P-1AS｜Personal pronoun, first, accusative, singular",
    GN: "G1473",
    gloss: "I/we",
  },
  ἐμοί: {
    parse: "P-1DS｜Personal pronoun, first, dative, singular",
    GN: "G1473",
    gloss: "I/we (mine)",
  },
  ἐμοὶ: {
    parse: "S-1SNPM｜Personal pronoun, first, dative, singular",
    GN: "G1473",
    gloss: "I/we (mine)",
  },
  Ἐμοὶ: {
    parse: "P-1DS｜Personal pronoun, first, dative, singular",
    GN: "G1473",
    gloss: "I/we",
  },
  ἐμοῦ: {
    parse: "S-1SGSN｜Personal pronoun, first, genitive, singular",
    GN: "G1473",
    gloss: "I/we (mine)",
  },
  ἡμᾶς: {
    parse: "P-1AP｜Personal pronoun, first, accusative, plural",
    GN: "G1473",
    gloss: "I/we",
  },
  ἡμεῖς: {
    parse: "P-1NP｜Personal pronoun, first, nominative, plural",
    GN: "G1473",
    gloss: "I/we",
  },
  Ἡμεῖς: {
    parse: "P-1NP｜Personal pronoun, first, nominative, plural",
    GN: "G1473",
    gloss: "I/we",
  },
  ἡμῖν: {
    parse: "P-1DP｜Personal pronoun, first, dative, plural",
    GN: "G1473",
    gloss: "I/we",
  },
  Ἡμῖν: {
    parse: "P-1DP｜Personal pronoun, first, dative, plural",
    GN: "G1473",
    gloss: "I/we",
  },
  ἡμῶν: {
    parse: "P-1GP｜Personal pronoun, first, genitive, plural",
    GN: "G1473",
    gloss: "I/we",
  },
  με: {
    parse: "P-1AS｜Personal pronoun, first, accusative, singular",
    GN: "G1473",
    gloss: "I/we",
  },
  μέ: {
    parse: "P-1AS｜Personal pronoun, first, accusative, singular",
    GN: "G1473",
    gloss: "I/we",
  },
  μοι: {
    parse: "P-1DS｜Personal pronoun, first, dative, singular",
    GN: "G1473",
    gloss: "I/we",
  },
  μοί: {
    parse: "P-1DS｜Personal pronoun, first, dative, singular",
    GN: "G1473",
    gloss: "I/we",
  },
  μου: {
    parse: "P-1GS｜Personal pronoun, first, genitive, singular",
    GN: "G1473",
    gloss: "I/we",
  },
  μού: {
    parse: "P-1GS｜Personal pronoun, first, genitive, singular",
    GN: "G1473",
    gloss: "I/we",
  },
  ἐδαφιοῦσίν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G1474",
    gloss: "to raze",
  },
  ἔδαφος: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G1475",
    gloss: "ground",
  },
  ἑδραῖοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G1476",
    gloss: "steadfast",
  },
  ἑδραῖος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1476",
    gloss: "steadfast",
  },
  ἑδραίωμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G1477",
    gloss: "foundation",
  },
  Ἑζεκίαν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G1478",
    gloss: "Hezekiah",
  },
  Ἑζεκίας: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G1478",
    gloss: "Hezekiah",
  },
  ἐθελοθρησκίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1479",
    gloss: "self-made religion",
  },
  εἰθισμένον: {
    parse:
      "V-RPP-ASN｜Verb, perfect, passive, Participle, accusative, singular, neuter",
    GN: "G1480",
    gloss: "be accustomed",
  },
  ἐθνάρχης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1481",
    gloss: "governor",
  },
  ἐθνικοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G1482",
    gloss: "Gentile-like",
  },
  ἐθνικοὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G1482",
    gloss: "Gentile-like",
  },
  ἐθνικὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1482",
    gloss: "Gentile-like",
  },
  ἐθνικῶν: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G1482",
    gloss: "Gentile-like",
  },
  ἐθνικῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1483",
    gloss: "Gentile-way",
  },
  ἔθνει: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G1484",
    gloss: "Gentiles",
  },
  ἔθνεσιν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G1484",
    gloss: "Gentiles",
  },
  ἔθνη: {
    parse: "N-VPN｜Noun, Vocative or nominative, plural, neuter",
    GN: "G1484",
    gloss: "Gentiles",
  },
  ἔθνος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G1484",
    gloss: "Gentiles",
  },
  ἔθνους: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G1484",
    gloss: "Gentiles",
  },
  ἐθνῶν: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G1484",
    gloss: "Gentiles",
  },
  ἔθει: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G1485",
    gloss: "custom",
  },
  ἔθεσι: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G1485",
    gloss: "custom",
  },
  ἔθεσιν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G1485",
    gloss: "custom",
  },
  ἔθη: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G1485",
    gloss: "custom",
  },
  ἔθος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G1485",
    gloss: "custom",
  },
  ἐθῶν: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G1485",
    gloss: "custom",
  },
  εἰώθει: {
    parse: "V-LAI-3S｜Verb, pluperfect, active, indicative, third, singular",
    GN: "G1486",
    gloss: "to have a custom",
  },
  εἰωθὸς: {
    parse:
      "V-2RAP-ASN｜Verb, 2nd perfect, active, Participle, accusative, singular, neuter",
    GN: "G1486",
    gloss: "to have a custom",
  },
  εἰ: {
    parse: "PRT｜Particle, disjunctive particle",
    GN: "G1487",
    gloss: "even though",
  },
  Εἰ: {
    parse: "PRT｜Particle, disjunctive particle",
    GN: "G1487",
    gloss: "even though",
  },
  εἴ: {
    parse: "PRT｜Particle, disjunctive particle",
    GN: "G1487",
    gloss: "even though",
  },
  Εἴ: {
    parse: "PRT｜Particle, disjunctive particle",
    GN: "G1487",
    gloss: "even though",
  },
  εἴδει: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G1491",
    gloss: "appearance",
  },
  εἶδος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G1491",
    gloss: "appearance",
  },
  εἴδους: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G1491",
    gloss: "appearance",
  },
  εἴδαμεν: {
    parse: "V-2AAI-1P｜Verb, 2nd aorist, active, indicative, first, plural",
    GN: "G1492",
    gloss: "see",
  },
  εἶδαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G1492",
    gloss: "see",
  },
  εἶδεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G1492",
    gloss: "see",
  },
  εἰδέναι: {
    parse: "V-RAN｜Verb, perfect, active, Infinitive",
    GN: "G1492",
    gloss: "know",
  },
  εἶδες: {
    parse: "V-2AAI-2S｜Verb, 2nd aorist, active, indicative, second, singular",
    GN: "G1492",
    gloss: "see",
  },
  εἶδές: {
    parse: "V-2AAI-2S｜Verb, 2nd aorist, active, indicative, second, singular",
    GN: "G1492",
    gloss: "see",
  },
  εἴδετε: {
    parse: "V-2AAI-2P｜Verb, 2nd aorist, active, indicative, second, plural",
    GN: "G1492",
    gloss: "see",
  },
  εἰδῇς: {
    parse: "V-RAS-2S｜Verb, perfect, active, subjunctive, second, singular",
    GN: "G1492",
    gloss: "know",
  },
  εἰδήσουσίν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G1492",
    gloss: "know",
  },
  εἰδῆτε: {
    parse: "V-RAS-2P｜Verb, perfect, active, subjunctive, second, plural",
    GN: "G1492",
    gloss: "know",
  },
  εἴδομεν: {
    parse: "V-2AAI-1P｜Verb, 2nd aorist, active, indicative, first, plural",
    GN: "G1492",
    gloss: "see",
  },
  Εἴδομεν: {
    parse: "V-2AAI-1P｜Verb, 2nd aorist, active, indicative, first, plural",
    GN: "G1492",
    gloss: "see",
  },
  εἴδομέν: {
    parse: "V-2AAI-1P｜Verb, 2nd aorist, active, indicative, first, plural",
    GN: "G1492",
    gloss: "see",
  },
  εἶδον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G1492",
    gloss: "see",
  },
  εἶδόν: {
    parse:
      "V-2AAI-1S｜Verb, 2nd aorist, active, indicative, first, singular OR third, plural",
    GN: "G1492",
    gloss: "see",
  },
  εἰδόσι: {
    parse:
      "V-RAP-DPM｜Verb, perfect, active, Participle, dative, plural, masculine",
    GN: "G1492",
    gloss: "know",
  },
  εἰδόσιν: {
    parse:
      "V-RAP-DPM｜Verb, perfect, active, Participle, dative, plural, masculine",
    GN: "G1492",
    gloss: "know",
  },
  εἰδότα: {
    parse:
      "V-RAP-NPN｜Verb, perfect, active, Participle, nominative, plural, neuter",
    GN: "G1492",
    gloss: "know",
  },
  εἰδότας: {
    parse:
      "V-RAP-APM｜Verb, perfect, active, Participle, accusative, plural, masculine",
    GN: "G1492",
    gloss: "know",
  },
  εἰδότες: {
    parse:
      "V-RAP-NPM｜Verb, perfect, active, Participle, nominative, plural, masculine",
    GN: "G1492",
    gloss: "know",
  },
  Εἰδότες: {
    parse:
      "V-RAP-NPM｜Verb, perfect, active, Participle, nominative, plural, masculine",
    GN: "G1492",
    gloss: "know",
  },
  εἰδότι: {
    parse:
      "V-RAP-DSM｜Verb, perfect, active, Participle, dative, singular, masculine",
    GN: "G1492",
    gloss: "know",
  },
  εἰδυῖα: {
    parse:
      "V-RAP-NSF｜Verb, perfect, active, Participle, nominative, singular, feminine",
    GN: "G1492",
    gloss: "know",
  },
  εἰδῶ: {
    parse: "V-RAS-1S｜Verb, perfect, active, subjunctive, first, singular",
    GN: "G1492",
    gloss: "know",
  },
  εἰδῶμεν: {
    parse: "V-RAS-1P｜Verb, perfect, active, subjunctive, first, plural",
    GN: "G1492",
    gloss: "know",
  },
  εἰδώς: {
    parse:
      "V-RAP-NSM｜Verb, perfect, active, Participle, nominative, singular, masculine",
    GN: "G1492",
    gloss: "know",
  },
  εἰδὼς: {
    parse:
      "V-RAP-NSM｜Verb, perfect, active, Participle, nominative, singular, masculine",
    GN: "G1492",
    gloss: "know",
  },
  ᾔδει: {
    parse:
      "V-2LAI-3S｜Verb, 2nd pluperfect, active, indicative, third, singular",
    GN: "G1492",
    gloss: "know",
  },
  ᾔδειν: {
    parse:
      "V-2LAI-1S｜Verb, 2nd pluperfect, active, indicative, first, singular",
    GN: "G1492",
    gloss: "know",
  },
  ᾔδεις: {
    parse:
      "V-2LAI-2S｜Verb, 2nd pluperfect, active, indicative, second, singular",
    GN: "G1492",
    gloss: "know",
  },
  Ἤιδεις: {
    parse:
      "V-2LAI-2S｜Verb, 2nd pluperfect, active, indicative, second, singular",
    GN: "G1492",
    gloss: "know",
  },
  ᾔδεισαν: {
    parse: "V-2LAI-3P｜Verb, 2nd pluperfect, active, indicative, third, plural",
    GN: "G1492",
    gloss: "know",
  },
  ᾔδειτε: {
    parse:
      "V-2LAI-2P｜Verb, 2nd pluperfect, active, indicative, second, plural",
    GN: "G1492",
    gloss: "know",
  },
  Ἤιδει: {
    parse:
      "V-2LAI-3S｜Verb, 2nd pluperfect, active, indicative, third, singular",
    GN: "G1492",
    gloss: "see",
  },
  ἴδε: { parse: "INJ｜Interjection", GN: "G2396", gloss: "look!" },
  ἰδεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G1492",
    gloss: "see",
  },
  ἴδετε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G1492",
    gloss: "see",
  },
  Ἴδετε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G1492",
    gloss: "see",
  },
  ἴδῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G1492",
    gloss: "see",
  },
  ἴδῃς: {
    parse: "V-2AAS-2S｜Verb, 2nd aorist, active, subjunctive, second, singular",
    GN: "G1492",
    gloss: "see",
  },
  ἴδητε: {
    parse: "V-2AAS-2P｜Verb, 2nd aorist, active, subjunctive, second, plural",
    GN: "G1492",
    gloss: "see",
  },
  ἴδητέ: {
    parse: "V-2AAS-2P｜Verb, 2nd aorist, active, subjunctive, second, plural",
    GN: "G1492",
    gloss: "see",
  },
  ἰδόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G1492",
    gloss: "see",
  },
  Ἰδόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G1492",
    gloss: "see",
  },
  ἰδοῦσα: {
    parse:
      "V-2AAP-NSF｜Verb, 2nd aorist, active, Participle, nominative, singular, feminine",
    GN: "G1492",
    gloss: "see",
  },
  ἴδω: {
    parse: "V-2AAS-1S｜Verb, 2nd aorist, active, subjunctive, first, singular",
    GN: "G1492",
    gloss: "see",
  },
  ἴδωμεν: {
    parse: "V-2AAS-1P｜Verb, 2nd aorist, active, subjunctive, first, plural",
    GN: "G1492",
    gloss: "see",
  },
  ἰδών: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G1492",
    gloss: "see",
  },
  ἰδὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G1492",
    gloss: "see",
  },
  Ἰδὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G1492",
    gloss: "see",
  },
  ἴδωσιν: {
    parse: "V-2AAS-3P｜Verb, 2nd aorist, active, subjunctive, third, plural",
    GN: "G1492",
    gloss: "see",
  },
  ἴσασιν: {
    parse: "V-RAI-3P｜Verb, perfect, active, indicative, third, plural",
    GN: "G1492",
    gloss: "see",
  },
  ἴστε: {
    parse: "V-RAM-2P｜Verb, perfect, active, imperative, second, plural",
    GN: "G1492",
    gloss: "see",
  },
  Ἴστε: {
    parse: "V-RAM-2P｜Verb, perfect, active, imperative, second, plural",
    GN: "G1492",
    gloss: "see",
  },
  οἶδα: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G1492",
    gloss: "to know",
  },
  Οἶδα: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G1492",
    gloss: "to know",
  },
  οἶδά: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G1492",
    gloss: "to know",
  },
  Οἶδά: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G1492",
    gloss: "to know",
  },
  οἴδαμεν: {
    parse: "V-RAI-1P｜Verb, perfect, active, indicative, first, plural",
    GN: "G1492",
    gloss: "to know",
  },
  Οἴδαμεν: {
    parse: "V-RAI-1P｜Verb, perfect, active, indicative, first, plural",
    GN: "G1492",
    gloss: "to know",
  },
  οἶδας: {
    parse: "V-RAI-2S｜Verb, perfect, active, indicative, second, singular",
    GN: "G1492",
    gloss: "to know",
  },
  Οἶδας: {
    parse: "V-RAI-2S｜Verb, perfect, active, indicative, second, singular",
    GN: "G1492",
    gloss: "to know",
  },
  οἴδασι: {
    parse: "V-RAI-3P｜Verb, perfect, active, indicative, third, plural",
    GN: "G1492",
    gloss: "to know",
  },
  οἴδασιν: {
    parse: "V-RAI-3P｜Verb, perfect, active, indicative, third, plural",
    GN: "G1492",
    gloss: "to know",
  },
  οἴδατε: {
    parse: "V-RAI-2P｜Verb, perfect, active, indicative, second, plural",
    GN: "G1492",
    gloss: "to know",
  },
  Οἴδατε: {
    parse: "V-RAI-2P｜Verb, perfect, active, indicative, second, plural",
    GN: "G1492",
    gloss: "to know",
  },
  οἶδεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G1492",
    gloss: "to know",
  },
  εἰδωλείῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G1493",
    gloss: "idol's temple",
  },
  εἰδωλόθυτα: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G1494",
    gloss: "sacrificed to idols",
  },
  εἰδωλόθυτον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G1494",
    gloss: "sacrificed to idols",
  },
  εἰδωλόθυτόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G1494",
    gloss: "sacrificed to idols",
  },
  εἰδωλοθύτων: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G1494",
    gloss: "sacrificed to idols",
  },
  ἱερόθυτόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G1494",
    gloss: "sacrificed to idols",
  },
  εἰδωλολατρία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1495",
    gloss: "idolatry",
  },
  εἰδωλολατρίαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G1495",
    gloss: "idolatry",
  },
  εἰδωλολατρίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1495",
    gloss: "idolatry",
  },
  εἰδωλολάτραι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G1496",
    gloss: "idolater",
  },
  εἰδωλολάτραις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G1496",
    gloss: "idolater",
  },
  εἰδωλολάτρης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1496",
    gloss: "idolater",
  },
  εἴδωλα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G1497",
    gloss: "idol",
  },
  εἴδωλον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G1497",
    gloss: "idol",
  },
  εἴδωλόν: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G1497",
    gloss: "idol",
  },
  εἰδώλου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G1497",
    gloss: "idol",
  },
  εἰδώλῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G1497",
    gloss: "idol",
  },
  εἰδώλων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G1497",
    gloss: "idol",
  },
  εἰκῇ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1500",
    gloss: "in vain",
  },
  εἴκοσι: {
    parse:
      "A-NPM-NUI｜Adjective, nominative, plural, masculine, NUmerical Indiclinable (A)",
    GN: "G1501",
    gloss: "twenty",
  },
  εἴξαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G1502",
    gloss: "to yield",
  },
  ἔοικεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G1503",
    gloss: "to resemble",
  },
  εἰκόνα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1504",
    gloss: "image",
  },
  εἰκόνι: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1504",
    gloss: "image",
  },
  εἰκόνος: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1504",
    gloss: "image",
  },
  εἰκὼν: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1504",
    gloss: "image",
  },
  εἰλικρινείᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1505",
    gloss: "sincerity",
  },
  εἰλικρινείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1505",
    gloss: "sincerity",
  },
  εἰλικρινεῖς: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G1506",
    gloss: "pure",
  },
  εἰλικρινῆ: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G1506",
    gloss: "pure",
  },
  εἶ: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G1510",
    gloss: "to be",
  },
  εἴη: {
    parse: "V-PAO-3S｜Verb, present, active, Optative, third, singular",
    GN: "G1510",
    gloss: "to be",
  },
  εἰμι: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G1510",
    gloss: "to be",
  },
  εἰμί: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G1510",
    gloss: "to be",
  },
  εἰμὶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G1510",
    gloss: "to be",
  },
  εἶναι: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1510",
    gloss: "to be",
  },
  εἶναί: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1510",
    gloss: "to be",
  },
  εἰσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1510",
    gloss: "to be",
  },
  εἰσίν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1510",
    gloss: "to be",
  },
  εἰσὶν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1510",
    gloss: "to be",
  },
  Εἰσὶν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1510",
    gloss: "to be",
  },
  ἔσεσθαι: {
    parse: "V-FDN｜Verb, future, Middle deponent, Infinitive",
    GN: "G1510",
    gloss: "to be",
  },
  ἔσεσθε: {
    parse:
      "V-FDI-2P｜Verb, future, Middle deponent, indicative, second, plural",
    GN: "G1510",
    gloss: "to be",
  },
  Ἔσεσθε: {
    parse:
      "V-FDI-2P｜Verb, future, Middle deponent, indicative, second, plural",
    GN: "G1510",
    gloss: "to be",
  },
  ἔσεσθέ: {
    parse:
      "V-FDI-2P｜Verb, future, Middle deponent, indicative, second, plural",
    GN: "G1510",
    gloss: "to be",
  },
  ἔσῃ: {
    parse:
      "V-FDI-2S｜Verb, future, Middle deponent, indicative, second, singular",
    GN: "G1510",
    gloss: "to be",
  },
  ἐσμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G1510",
    gloss: "to be",
  },
  ἐσμέν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G1510",
    gloss: "to be",
  },
  ἐσμὲν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G1510",
    gloss: "to be",
  },
  ἔσομαι: {
    parse:
      "V-FDI-1S｜Verb, future, Middle deponent, indicative, first, singular",
    GN: "G1510",
    gloss: "to be",
  },
  ἐσόμεθα: {
    parse: "V-FDI-1P｜Verb, future, Middle deponent, indicative, first, plural",
    GN: "G1510",
    gloss: "to be",
  },
  ἐσόμενον: {
    parse:
      "V-FDP-ASN｜Verb, future, Middle deponent, Participle, accusative, singular, neuter",
    GN: "G1510",
    gloss: "to be",
  },
  ἔσονται: {
    parse: "V-FDI-3P｜Verb, future, Middle deponent, indicative, third, plural",
    GN: "G1510",
    gloss: "to be",
  },
  Ἔσονται: {
    parse: "V-FDI-3P｜Verb, future, Middle deponent, indicative, third, plural",
    GN: "G1510",
    gloss: "to be",
  },
  ἔσονταί: {
    parse: "V-FDI-3P｜Verb, future, Middle deponent, indicative, third, plural",
    GN: "G1510",
    gloss: "to be",
  },
  ἔσται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G1510",
    gloss: "to be",
  },
  Ἔσται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G1510",
    gloss: "to be",
  },
  ἐστε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G1510",
    gloss: "to be",
  },
  ἐστέ: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G1510",
    gloss: "to be",
  },
  ἐστὲ: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G1510",
    gloss: "to be",
  },
  ἐστιν: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1510",
    gloss: "to be",
  },
  ἐστίν: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1510",
    gloss: "to be",
  },
  ἐστί: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1510",
    gloss: "to be",
  },
  ἐστὶν: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1510",
    gloss: "to be",
  },
  ἐστὶ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1510",
    gloss: "to be",
  },
  ἐστι: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1510",
    gloss: "to be",
  },
  ἔστιν: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1510",
    gloss: "to be",
  },
  Ἔστιν: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1510",
    gloss: "to be",
  },
  ἔστω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G1510",
    gloss: "to be",
  },
  ἔστωσαν: {
    parse: "V-PAM-3P｜Verb, present, active, imperative, third, plural",
    GN: "G1510",
    gloss: "to be",
  },
  Ἔστωσαν: {
    parse: "V-PAM-3P｜Verb, present, active, imperative, third, plural",
    GN: "G1510",
    gloss: "to be",
  },
  ᾖ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G1510",
    gloss: "to be",
  },
  ἤμεθα: {
    parse: "V-IMI-1P｜Verb, imperfect, middle, indicative, first, plural",
    GN: "G1510",
    gloss: "to be",
  },
  ἦμεν: {
    parse: "V-IAI-1P｜Verb, imperfect, active, indicative, first, plural",
    GN: "G1510",
    gloss: "to be",
  },
  ἤμην: {
    parse: "V-IMI-1S｜Verb, imperfect, middle, indicative, first, singular",
    GN: "G1510",
    gloss: "to be",
  },
  ἦν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1510",
    gloss: "to be",
  },
  Ἦν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1510",
    gloss: "to be",
  },
  ἦς: {
    parse: "V-IAI-2S｜Verb, imperfect, active, indicative, second, singular",
    GN: "G1510",
    gloss: "to be",
  },
  ᾖς: {
    parse: "V-PAS-2S｜Verb, present, active, subjunctive, second, singular",
    GN: "G1510",
    gloss: "to be",
  },
  ἦσαν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1510",
    gloss: "to be",
  },
  Ἦσαν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1510",
    gloss: "to be",
  },
  ἦσθα: {
    parse: "V-IMI-2S｜Verb, imperfect, middle, indicative, second, singular",
    GN: "G1510",
    gloss: "to be",
  },
  ἦτε: {
    parse: "V-PAS-2P｜Verb, present, active, subjunctive, second, plural",
    GN: "G1510",
    gloss: "to be",
  },
  ἤτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G1510",
    gloss: "to be",
  },
  ἴσθι: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G1510",
    gloss: "to be",
  },
  ὂν: {
    parse:
      "V-PAP-NSN｜Verb, present, active, Participle, nominative, singular, neuter",
    GN: "G1510",
    gloss: "to be",
  },
  ὄντα: {
    parse:
      "V-PAP-NPN｜Verb, present, active, Participle, nominative, plural, neuter",
    GN: "G1510",
    gloss: "to be",
  },
  ὄντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G1510",
    gloss: "to be",
  },
  ὄντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1510",
    gloss: "to be",
  },
  ὄντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G1510",
    gloss: "to be",
  },
  ὄντος: {
    parse:
      "V-PAP-GSN｜Verb, present, active, Participle, genitive, singular, neuter",
    GN: "G1510",
    gloss: "to be",
  },
  ὄντων: {
    parse:
      "V-PAP-GPN｜Verb, present, active, Participle, genitive, plural, neuter",
    GN: "G1510",
    gloss: "to be",
  },
  οὖσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G1510",
    gloss: "to be",
  },
  οὖσαι: {
    parse:
      "V-PAP-NPF｜Verb, present, active, Participle, nominative, plural, feminine",
    GN: "G1510",
    gloss: "to be",
  },
  οὖσαν: {
    parse:
      "V-PAP-ASF｜Verb, present, active, Participle, accusative, singular, feminine",
    GN: "G1510",
    gloss: "to be",
  },
  οὔσῃ: {
    parse:
      "V-PAP-DSF｜Verb, present, active, Participle, dative, singular, feminine",
    GN: "G1510",
    gloss: "to be",
  },
  οὔσης: {
    parse:
      "V-PAP-GSF｜Verb, present, active, Participle, genitive, singular, feminine",
    GN: "G1510",
    gloss: "to be",
  },
  Οὔσης: {
    parse:
      "V-PAP-GSF｜Verb, present, active, Participle, genitive, singular, feminine",
    GN: "G1510",
    gloss: "to be",
  },
  οὖσι: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G1510",
    gloss: "to be",
  },
  οὖσιν: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G1510",
    gloss: "to be",
  },
  οὐσῶν: {
    parse:
      "V-PAP-GPF｜Verb, present, active, Participle, genitive, plural, feminine",
    GN: "G1510",
    gloss: "to be",
  },
  ὦ: { parse: "INJ｜Interjection", GN: "G5599", gloss: "oh!" },
  ὦμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G1510",
    gloss: "to be",
  },
  ὤν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1510",
    gloss: "to be",
  },
  ὢν: {
    parse:
      "V-PAP-VSM｜Verb, present, active, Participle, Vocative or nominative, singular, masculine",
    GN: "G1510",
    gloss: "to be",
  },
  ὦσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G1510",
    gloss: "to be",
  },
  εἴπερ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G1512",
    gloss: "if so/since",
  },
  εἰρηνεύετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G1514",
    gloss: "be at peace",
  },
  εἰρηνεύοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1514",
    gloss: "be at peace",
  },
  εἰρήνῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1515",
    gloss: "peace",
  },
  εἰρήνη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1515",
    gloss: "peace",
  },
  Εἰρήνη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1515",
    gloss: "peace",
  },
  εἰρήνην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1515",
    gloss: "peace",
  },
  Εἰρήνην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1515",
    gloss: "peace",
  },
  εἰρήνης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1515",
    gloss: "peace",
  },
  εἰρηνική: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G1516",
    gloss: "peaceful",
  },
  εἰρηνικὸν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G1516",
    gloss: "peaceful",
  },
  εἰρηνοποιήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G1517",
    gloss: "to make peace",
  },
  εἰρηνοποιοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G1518",
    gloss: "peacemaker",
  },
  εἰς: { parse: "PREP｜Preposition", GN: "G1519", gloss: "unto/toward" },
  Εἰς: { parse: "PREP｜Preposition", GN: "G1519", gloss: "unto/toward" },
  εἴς: { parse: "PREP｜Preposition", GN: "G1519", gloss: "unto/toward" },
  εἷς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1520",
    gloss: "one",
  },
  Εἷς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1520",
    gloss: "one",
  },
  ἕν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G1520",
    gloss: "one",
  },
  ἓν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G1520",
    gloss: "one",
  },
  Ἓν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G1520",
    gloss: "one",
  },
  Ἕν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G1520",
    gloss: "one",
  },
  ἕνα: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G1520",
    gloss: "one",
  },
  ἑνί: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G1520",
    gloss: "one",
  },
  ἑνὶ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G1520",
    gloss: "one",
  },
  Ἑνὶ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G1520",
    gloss: "one",
  },
  ἑνός: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G1520",
    gloss: "one",
  },
  ἑνὸς: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G1520",
    gloss: "one",
  },
  μιᾷ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G1520",
    gloss: "one",
  },
  μία: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G1520",
    gloss: "one",
  },
  μίαν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G1520",
    gloss: "one",
  },
  μιᾶς: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G1520",
    gloss: "one",
  },
  εἰσάγαγε: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G1521",
    gloss: "to bring in",
  },
  εἰσαγαγεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G1521",
    gloss: "to bring in",
  },
  εἰσαγάγῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G1521",
    gloss: "to bring in",
  },
  εἰσάγεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G1521",
    gloss: "to bring in",
  },
  εἰσήγαγεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G1521",
    gloss: "to bring in",
  },
  εἰσήγαγον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G1521",
    gloss: "to bring in",
  },
  εἰσακουσθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G1522",
    gloss: "to listen to",
  },
  εἰσακουσθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G1522",
    gloss: "to listen to",
  },
  εἰσακούσονταί: {
    parse: "V-FDI-3P｜Verb, future, Middle deponent, indicative, third, plural",
    GN: "G1522",
    gloss: "to listen to",
  },
  εἰσηκούσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G1522",
    gloss: "to listen to",
  },
  εἰσδέξομαι: {
    parse:
      "V-FDI-1S｜Verb, future, Middle deponent, indicative, first, singular",
    GN: "G1523",
    gloss: "to receive",
  },
  εἰσῄει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1524",
    gloss: "to enter",
  },
  εἰσίασιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1524",
    gloss: "to enter",
  },
  εἰσιέναι: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1524",
    gloss: "to enter",
  },
  εἰσελεύσεσθαι: {
    parse: "V-FDN｜Verb, future, Middle deponent, Infinitive",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσελεύσεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσελεύσομαι: {
    parse:
      "V-FDI-1S｜Verb, future, Middle deponent, indicative, first, singular",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσελεύσονται: {
    parse: "V-FDI-3P｜Verb, future, Middle deponent, indicative, third, plural",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσεληλύθασιν: {
    parse: "V-2RAI-3P｜Verb, 2nd perfect, active, indicative, third, plural",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσεληλύθατε: {
    parse: "V-2RAI-2P｜Verb, 2nd perfect, active, indicative, second, plural",
    GN: "G1525",
    gloss: "to enter",
  },
  Εἰσέλθατε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσελθάτω: {
    parse: "V-2AAM-3S｜Verb, 2nd aorist, active, imperative, third, singular",
    GN: "G1525",
    gloss: "to enter",
  },
  εἴσελθε: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσελθεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσέλθῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσέλθῃς: {
    parse: "V-2AAS-2S｜Verb, 2nd aorist, active, subjunctive, second, singular",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσέλθητε: {
    parse: "V-2AAS-2P｜Verb, 2nd aorist, active, subjunctive, second, plural",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσελθόντα: {
    parse:
      "V-2AAP-NPN｜Verb, 2nd aorist, active, Participle, nominative, plural, neuter",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσελθόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσελθόντι: {
    parse:
      "V-2AAP-DSM｜Verb, 2nd aorist, active, Participle, dative, singular, masculine",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσελθόντος: {
    parse:
      "V-2AAP-GSM｜Verb, 2nd aorist, active, Participle, genitive, singular, masculine",
    GN: "G1525",
    gloss: "to enter",
  },
  Εἰσελθόντος: {
    parse:
      "V-2AAP-GSM｜Verb, 2nd aorist, active, Participle, genitive, singular, masculine",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσελθόντων: {
    parse:
      "V-2AAP-GPM｜Verb, 2nd aorist, active, Participle, genitive, plural, masculine",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσελθοῦσα: {
    parse:
      "V-2AAP-NSF｜Verb, 2nd aorist, active, Participle, nominative, singular, feminine",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσελθοῦσαι: {
    parse:
      "V-2AAP-NPF｜Verb, 2nd aorist, active, Participle, nominative, plural, feminine",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσελθούσης: {
    parse:
      "V-2AAP-GSF｜Verb, 2nd aorist, active, Participle, genitive, singular, feminine",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσέλθωμεν: {
    parse: "V-2AAS-1P｜Verb, 2nd aorist, active, subjunctive, first, plural",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσελθὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G1525",
    gloss: "to enter",
  },
  Εἰσελθὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσέλθωσιν: {
    parse: "V-2AAS-3P｜Verb, 2nd aorist, active, subjunctive, third, plural",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσέρχεσθε: {
    parse:
      "V-PNI-2P｜Verb, present, Middle or Passive deponent, indicative, second, plural",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσερχέσθωσαν: {
    parse:
      "V-PNM-3P｜Verb, present, Middle or Passive deponent, imperative, third, plural",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσέρχεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσέρχησθε: {
    parse:
      "V-PNS-2P｜Verb, present, Middle or Passive deponent, subjunctive, second, plural",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσερχόμεθα: {
    parse:
      "V-PNI-1P｜Verb, present, Middle or Passive deponent, indicative, first, plural",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσερχομένην: {
    parse:
      "V-PNP-ASF｜Verb, present, Middle or Passive deponent, Participle, accusative, singular, feminine",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσερχόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσερχόμενον: {
    parse:
      "V-PNP-NSN｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, neuter",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσερχόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσερχομένου: {
    parse:
      "V-PNP-GSM｜Verb, present, Middle or Passive deponent, Participle, genitive, singular, masculine",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσερχομένους: {
    parse:
      "V-PNP-APM｜Verb, present, Middle or Passive deponent, Participle, accusative, plural, masculine",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσήλθατε: {
    parse: "V-2AAI-2P｜Verb, 2nd aorist, active, indicative, second, plural",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσῆλθεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G1525",
    gloss: "to enter",
  },
  Εἰσῆλθεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσῆλθες: {
    parse: "V-2AAI-2S｜Verb, 2nd aorist, active, indicative, second, singular",
    GN: "G1525",
    gloss: "to enter",
  },
  Εἰσῆλθες: {
    parse: "V-2AAI-2S｜Verb, 2nd aorist, active, indicative, second, singular",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσήλθομεν: {
    parse: "V-2AAI-1P｜Verb, 2nd aorist, active, indicative, first, plural",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσῆλθον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσῆλθόν: {
    parse:
      "V-2AAI-1S｜Verb, 2nd aorist, active, indicative, first, singular OR third, plural",
    GN: "G1525",
    gloss: "to enter",
  },
  εἰσκαλεσάμενος: {
    parse:
      "V-ADP-NSM｜Verb, aorist, Middle deponent, Participle, nominative, singular, masculine",
    GN: "G1528",
    gloss: "to invite",
  },
  εἴσοδον: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1529",
    gloss: "entry",
  },
  εἴσοδος: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1529",
    gloss: "entry",
  },
  εἰσόδου: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1529",
    gloss: "entry",
  },
  εἰσεπήδησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1530",
    gloss: "to rush into",
  },
  εἰσεπορεύετο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G1531",
    gloss: "to enter",
  },
  εἰσπορεύεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G1531",
    gloss: "to enter",
  },
  εἰσπορευόμεναι: {
    parse:
      "V-PNP-NPF｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, feminine",
    GN: "G1531",
    gloss: "to enter",
  },
  εἰσπορευόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G1531",
    gloss: "to enter",
  },
  εἰσπορευόμενον: {
    parse:
      "V-PNP-NSN｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, neuter",
    GN: "G1531",
    gloss: "to enter",
  },
  εἰσπορευόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G1531",
    gloss: "to enter",
  },
  εἰσπορευομένους: {
    parse:
      "V-PNP-APM｜Verb, present, Middle or Passive deponent, Participle, accusative, plural, masculine",
    GN: "G1531",
    gloss: "to enter",
  },
  εἰσπορευομένων: {
    parse:
      "V-PNP-GPM｜Verb, present, Middle or Passive deponent, Participle, genitive, plural, masculine",
    GN: "G1531",
    gloss: "to enter",
  },
  εἰσπορεύονται: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G1531",
    gloss: "to enter",
  },
  εἰσδραμοῦσα: {
    parse:
      "V-2AAP-NSF｜Verb, 2nd aorist, active, Participle, nominative, singular, feminine",
    GN: "G1532",
    gloss: "to run in",
  },
  εἰσενεγκεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G1533",
    gloss: "to bring in",
  },
  εἰσενέγκῃς: {
    parse: "V-2AAS-2S｜Verb, 2nd aorist, active, subjunctive, second, singular",
    GN: "G1533",
    gloss: "to bring in",
  },
  εἰσενέγκωσιν: {
    parse: "V-2AAS-3P｜Verb, 2nd aorist, active, subjunctive, third, plural",
    GN: "G1533",
    gloss: "to bring in",
  },
  εἰσηνέγκαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G1533",
    gloss: "to bring in",
  },
  εἰσφέρεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G1533",
    gloss: "to bring in",
  },
  εἰσφέρεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G1533",
    gloss: "to bring in",
  },
  εἰσφέρωσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G1533",
    gloss: "to bring in",
  },
  εἶτα: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1534",
    gloss: "then",
  },
  εἴτε: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G1535",
    gloss: "whether",
  },
  Εἴτε: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G1535",
    gloss: "whether",
  },
  ἐκ: { parse: "PREP｜Preposition", GN: "G1537", gloss: "out from" },
  Ἐκ: { parse: "PREP｜Preposition", GN: "G1537", gloss: "out from" },
  ἐξ: { parse: "PREP｜Preposition", GN: "G1537", gloss: "out from" },
  Ἐξ: { parse: "PREP｜Preposition", GN: "G1537", gloss: "out from" },
  ἑκάστη: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G1538",
    gloss: "each",
  },
  ἑκάστην: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G1538",
    gloss: "each",
  },
  ἕκαστοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G1538",
    gloss: "each",
  },
  ἕκαστον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G1538",
    gloss: "each",
  },
  ἕκαστος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1538",
    gloss: "each",
  },
  ἑκάστου: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G1538",
    gloss: "each",
  },
  ἑκάστῳ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G1538",
    gloss: "each",
  },
  ἑκάστοτε: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1539",
    gloss: "always",
  },
  ἑκατόν: {
    parse:
      "A-DPN-NUI｜Adjective, dative, plural, neuter, NUmerical Indiclinable (A)",
    GN: "G1540",
    gloss: "hundred",
  },
  ἑκατὸν: {
    parse:
      "A-NPN-NUI｜Adjective, nominative, plural, neuter, NUmerical Indiclinable (A)",
    GN: "G1540",
    gloss: "hundred",
  },
  Ἑκατὸν: {
    parse:
      "A-APM-NUI｜Adjective, accusative, plural, masculine, NUmerical Indiclinable (A)",
    GN: "G1540",
    gloss: "hundred",
  },
  ἑκατονταετής: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1541",
    gloss: "a hundred years old",
  },
  ἑκατονταπλασίονα: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G1542",
    gloss: "a hundred times",
  },
  ἑκατοντάρχας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G1543",
    gloss: "centurion",
  },
  ἑκατοντάρχῃ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G1543",
    gloss: "centurion",
  },
  ἑκατοντάρχης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1543",
    gloss: "centurion",
  },
  ἑκατόνταρχον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1543",
    gloss: "centurion",
  },
  ἑκατόνταρχος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1543",
    gloss: "centurion",
  },
  Ἑκατοντάρχου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G1543",
    gloss: "centurion",
  },
  ἑκατονταρχῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G1543",
    gloss: "centurion",
  },
  ἔκβαλε: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G1544",
    gloss: "to expel",
  },
  Ἔκβαλε: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G1544",
    gloss: "to expel",
  },
  ἐκβαλεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G1544",
    gloss: "to expel",
  },
  ἐκβάλετε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G1544",
    gloss: "to expel",
  },
  ἐκβάλῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G1544",
    gloss: "to expel",
  },
  ἐκβάλλει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1544",
    gloss: "to expel",
  },
  ἐκβάλλειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1544",
    gloss: "to expel",
  },
  ἐκβάλλεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G1544",
    gloss: "to expel",
  },
  ἐκβάλλεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G1544",
    gloss: "to expel",
  },
  ἐκβάλλετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G1544",
    gloss: "to expel",
  },
  ἐκβαλλόμενοι: {
    parse:
      "V-PMP-NPM｜Verb, present, middle, Participle, nominative, plural, masculine",
    GN: "G1544",
    gloss: "to expel",
  },
  ἐκβαλλομένους: {
    parse:
      "V-PPP-APM｜Verb, present, passive, Participle, accusative, plural, masculine",
    GN: "G1544",
    gloss: "to expel",
  },
  ἐκβάλλοντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G1544",
    gloss: "to expel",
  },
  ἐκβάλλουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1544",
    gloss: "to expel",
  },
  ἐκβάλλω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G1544",
    gloss: "to expel",
  },
  ἐκβάλλων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1544",
    gloss: "to expel",
  },
  ἐκβαλόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G1544",
    gloss: "to expel",
  },
  ἐκβαλοῦσα: {
    parse:
      "V-2AAP-NSF｜Verb, 2nd aorist, active, Participle, nominative, singular, feminine",
    GN: "G1544",
    gloss: "to expel",
  },
  ἐκβαλοῦσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G1544",
    gloss: "to expel",
  },
  ἐκβάλω: {
    parse: "V-2AAS-1S｜Verb, 2nd aorist, active, subjunctive, first, singular",
    GN: "G1544",
    gloss: "to expel",
  },
  ἐκβαλὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G1544",
    gloss: "to expel",
  },
  ἐκβάλωσιν: {
    parse: "V-2AAS-3P｜Verb, 2nd aorist, active, subjunctive, third, plural",
    GN: "G1544",
    gloss: "to expel",
  },
  ἐκβεβλήκει: {
    parse: "V-LAI-3S｜Verb, pluperfect, active, indicative, third, singular",
    GN: "G1544",
    gloss: "to expel",
  },
  ἐκβληθέντος: {
    parse:
      "V-APP-GSN｜Verb, aorist, passive, Participle, genitive, singular, neuter",
    GN: "G1544",
    gloss: "to expel",
  },
  ἐκβληθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G1544",
    gloss: "to expel",
  },
  ἐκβληθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G1544",
    gloss: "to expel",
  },
  ἐξέβαλεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G1544",
    gloss: "to expel",
  },
  ἐξέβαλλον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1544",
    gloss: "to expel",
  },
  ἐξεβάλομεν: {
    parse: "V-2AAI-1P｜Verb, 2nd aorist, active, indicative, first, plural",
    GN: "G1544",
    gloss: "to expel",
  },
  ἐξέβαλον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G1544",
    gloss: "to expel",
  },
  ἐξεβλήθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G1544",
    gloss: "to expel",
  },
  ἔκβασιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1545",
    gloss: "way out",
  },
  ἐκβολὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1546",
    gloss: "jettisoning",
  },
  ἔκγονα: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G1549",
    gloss: "descendant",
  },
  ἐκδαπανηθήσομαι: {
    parse: "V-FPI-1S｜Verb, future, passive, indicative, first, singular",
    GN: "G1550",
    gloss: "to expend",
  },
  ἐκδέχεσθε: {
    parse:
      "V-PNM-2P｜Verb, present, Middle or Passive deponent, imperative, second, plural",
    GN: "G1551",
    gloss: "to wait for",
  },
  ἐκδέχεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G1551",
    gloss: "to wait for",
  },
  ἐκδέχομαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G1551",
    gloss: "to wait for",
  },
  ἐκδεχόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G1551",
    gloss: "to wait for",
  },
  ἐκδεχομένου: {
    parse:
      "V-PNP-GSM｜Verb, present, Middle or Passive deponent, Participle, genitive, singular, masculine",
    GN: "G1551",
    gloss: "to wait for",
  },
  ἐξεδέχετο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G1551",
    gloss: "to wait for",
  },
  ἔκδηλος: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G1552",
    gloss: "clearly evident",
  },
  ἐκδημῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1553",
    gloss: "be away",
  },
  ἐκδημοῦμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G1553",
    gloss: "be away",
  },
  ἐκδημοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1553",
    gloss: "be away",
  },
  ἐκδώσεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G1554",
    gloss: "to lease",
  },
  ἐξέδετο: {
    parse: "V-2AMI-3S｜Verb, 2nd aorist, middle, indicative, third, singular",
    GN: "G1554",
    gloss: "to lease",
  },
  ἐκδιηγῆται: {
    parse:
      "V-PNS-3S｜Verb, present, Middle or Passive deponent, subjunctive, third, singular",
    GN: "G1555",
    gloss: "to tell in detail",
  },
  ἐκδιηγούμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G1555",
    gloss: "to tell in detail",
  },
  ἐκδικεῖς: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G1556",
    gloss: "to avenge",
  },
  ἐκδικῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1556",
    gloss: "to avenge",
  },
  Ἐκδίκησόν: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G1556",
    gloss: "to avenge",
  },
  ἐκδικήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G1556",
    gloss: "to avenge",
  },
  ἐκδικοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1556",
    gloss: "to avenge",
  },
  ἐξεδίκησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1556",
    gloss: "to avenge",
  },
  ἐκδικήσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1557",
    gloss: "vengeance",
  },
  ἐκδίκησιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1557",
    gloss: "vengeance",
  },
  ἐκδίκησις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1557",
    gloss: "vengeance",
  },
  ἔκδικος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1558",
    gloss: "avenging",
  },
  ἐκδιωξάντων: {
    parse:
      "V-AAP-GPM｜Verb, aorist, active, Participle, genitive, plural, masculine",
    GN: "G1559",
    gloss: "to persecute",
  },
  ἔκδοτον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G1560",
    gloss: "handed over",
  },
  ἐκδοχὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1561",
    gloss: "expectation",
  },
  ἐκδυσάμενοι: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G1562",
    gloss: "to strip",
  },
  ἐκδύσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G1562",
    gloss: "to strip",
  },
  ἐκδύσασθαι: {
    parse: "V-AMN｜Verb, aorist, middle, Infinitive",
    GN: "G1562",
    gloss: "to strip",
  },
  ἐξέδυσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1562",
    gloss: "to strip",
  },
  ἐκεῖ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1563",
    gloss: "there",
  },
  Ἐκεῖ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1563",
    gloss: "there",
  },
  ἐκεῖθεν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1564",
    gloss: "from there",
  },
  Ἐκεῖθεν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1564",
    gloss: "from there",
  },
  ἐκεῖθέν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1564",
    gloss: "from there",
  },
  ἐκεῖνα: {
    parse: "D-APN｜Demonstrative pronoun, accusative, plural, neuter",
    GN: "G1565",
    gloss: "that",
  },
  ἐκεῖναι: {
    parse: "D-NPF｜Demonstrative pronoun, nominative, plural, feminine",
    GN: "G1565",
    gloss: "that",
  },
  ἐκεῖναί: {
    parse: "D-NPF｜Demonstrative pronoun, nominative, plural, feminine",
    GN: "G1565",
    gloss: "that",
  },
  ἐκείναις: {
    parse: "D-DPF｜Demonstrative pronoun, dative, plural, feminine",
    GN: "G1565",
    gloss: "that",
  },
  ἐκείνας: {
    parse: "D-APF｜Demonstrative pronoun, accusative, plural, feminine",
    GN: "G1565",
    gloss: "that",
  },
  ἐκείνῃ: {
    parse: "D-DSF｜Demonstrative pronoun, dative, singular, feminine",
    GN: "G1565",
    gloss: "that",
  },
  ἐκείνη: {
    parse: "D-NSF｜Demonstrative pronoun, nominative, singular, feminine",
    GN: "G1565",
    gloss: "that",
  },
  ἐκείνην: {
    parse: "D-ASF｜Demonstrative pronoun, accusative, singular, feminine",
    GN: "G1565",
    gloss: "that",
  },
  ἐκείνης: {
    parse: "D-GSF｜Demonstrative pronoun, genitive, singular, feminine",
    GN: "G1565",
    gloss: "that",
  },
  ἐκεῖνο: {
    parse: "D-NSN｜Demonstrative pronoun, nominative, singular, neuter",
    GN: "G1565",
    gloss: "that",
  },
  ἐκεῖνοι: {
    parse: "D-NPM｜Demonstrative pronoun, nominative, plural, masculine",
    GN: "G1565",
    gloss: "that",
  },
  ἐκεῖνοί: {
    parse: "D-NPM｜Demonstrative pronoun, nominative, plural, masculine",
    GN: "G1565",
    gloss: "that",
  },
  ἐκείνοις: {
    parse: "D-DPM｜Demonstrative pronoun, dative, plural, masculine",
    GN: "G1565",
    gloss: "that",
  },
  ἐκεῖνον: {
    parse: "D-ASM｜Demonstrative pronoun, accusative, singular, masculine",
    GN: "G1565",
    gloss: "that",
  },
  ἐκεῖνος: {
    parse: "D-NSM｜Demonstrative pronoun, nominative, singular, masculine",
    GN: "G1565",
    gloss: "that",
  },
  ἐκεῖνός: {
    parse: "D-NSM｜Demonstrative pronoun, nominative, singular, masculine",
    GN: "G1565",
    gloss: "that",
  },
  Ἐκεῖνός: {
    parse: "D-NSM｜Demonstrative pronoun, nominative, singular, masculine",
    GN: "G1565",
    gloss: "that",
  },
  ἐκείνου: {
    parse: "D-GSN｜Demonstrative pronoun, genitive, singular, neuter",
    GN: "G1565",
    gloss: "that",
  },
  ἐκείνους: {
    parse: "D-APM｜Demonstrative pronoun, accusative, plural, masculine",
    GN: "G1565",
    gloss: "that",
  },
  ἐκείνῳ: {
    parse: "D-DSM｜Demonstrative pronoun, dative, singular, masculine",
    GN: "G1565",
    gloss: "that",
  },
  ἐκείνων: {
    parse: "D-GPN｜Demonstrative pronoun, genitive, plural, neuter",
    GN: "G1565",
    gloss: "that",
  },
  ἐκεῖσε: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1566",
    gloss: "there",
  },
  ἐκζητηθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G1567",
    gloss: "to seek out",
  },
  ἐκζητηθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G1567",
    gloss: "to seek out",
  },
  ἐκζητήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G1567",
    gloss: "to seek out",
  },
  ἐκζητήσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G1567",
    gloss: "to seek out",
  },
  ἐκζητοῦσιν: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G1567",
    gloss: "to seek out",
  },
  ἐκζητῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1567",
    gloss: "to seek out",
  },
  ἐξεζήτησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1567",
    gloss: "to seek out",
  },
  ἐκθαμβεῖσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G1568",
    gloss: "be awe-struck",
  },
  ἐκθαμβεῖσθε: {
    parse: "V-PPM-2P｜Verb, present, passive, imperative, second, plural",
    GN: "G1568",
    gloss: "be awe-struck",
  },
  ἐξεθαμβήθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G1568",
    gloss: "be awe-struck",
  },
  ἔκθαμβοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G1569",
    gloss: "astonished",
  },
  ἔκθετα: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G1570",
    gloss: "exposed",
  },
  ἐκκαθάρατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G1571",
    gloss: "to cleanse",
  },
  ἐκκαθάρῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G1571",
    gloss: "to cleanse",
  },
  ἐξεκαύθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G1572",
    gloss: "be enflamed",
  },
  ἐγκακεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1573",
    gloss: "to lose heart",
  },
  ἐγκακήσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G1573",
    gloss: "to lose heart",
  },
  ἐγκακοῦμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G1573",
    gloss: "to lose heart",
  },
  ἐγκακῶμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G1573",
    gloss: "to lose heart",
  },
  ἐξεκέντησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1574",
    gloss: "to pierce",
  },
  ἐξεκλάσθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G1575",
    gloss: "to break off",
  },
  Ἐξεκλάσθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G1575",
    gloss: "to break off",
  },
  ἐκκλεῖσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1576",
    gloss: "to exclude",
  },
  ἐξεκλείσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G1576",
    gloss: "to exclude",
  },
  ἐκκλησίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1577",
    gloss: "assembly",
  },
  ἐκκλησία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1577",
    gloss: "assembly",
  },
  ἐκκλησίαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G1577",
    gloss: "assembly",
  },
  ἐκκλησίαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G1577",
    gloss: "assembly",
  },
  ἐκκλησίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1577",
    gloss: "assembly",
  },
  ἐκκλησίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1577",
    gloss: "assembly",
  },
  ἐκκλησιῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G1577",
    gloss: "assembly",
  },
  ἐκκλινάτω: {
    parse: "V-AAM-3S｜Verb, aorist, active, imperative, third, singular",
    GN: "G1578",
    gloss: "to turn from",
  },
  ἐκκλίνετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G1578",
    gloss: "to turn from",
  },
  ἐξέκλιναν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1578",
    gloss: "to turn from",
  },
  ἐκκολυμβήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G1579",
    gloss: "to swim out",
  },
  ἐξεκομίζετο: {
    parse: "V-IPI-3S｜Verb, imperfect, passive, indicative, third, singular",
    GN: "G1580",
    gloss: "to carry out",
  },
  ἐκκοπήσῃ: {
    parse: "V-2FPI-2S｜Verb, 2nd future, passive, indicative, second, singular",
    GN: "G1581",
    gloss: "to prevent",
  },
  ἐκκόπτεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G1581",
    gloss: "to prevent",
  },
  ἐκκόψεις: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G1581",
    gloss: "to prevent",
  },
  ἔκκοψον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G1581",
    gloss: "to prevent",
  },
  ἐκκόψω: {
    parse: "V-AAS-1S｜Verb, aorist, active, subjunctive, first, singular",
    GN: "G1581",
    gloss: "to prevent",
  },
  ἐξεκόπης: {
    parse: "V-2API-2S｜Verb, 2nd aorist, passive, indicative, second, singular",
    GN: "G1581",
    gloss: "to prevent",
  },
  ἐξεκρέματο: {
    parse: "V-IMI-3S｜Verb, imperfect, middle, indicative, third, singular",
    GN: "G1582",
    gloss: "to hang upon",
  },
  ἐκλαλῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1583",
    gloss: "to speak out",
  },
  ἐκλάμψουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G1584",
    gloss: "to shine out",
  },
  ἐκλέλησθε: {
    parse: "V-RMI-2P｜Verb, perfect, middle, indicative, second, plural",
    GN: "G1585",
    gloss: "to forget",
  },
  ἐκλελεγμένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G1586",
    gloss: "to select",
  },
  ἐκλεξαμένοις: {
    parse:
      "V-AMP-DPM｜Verb, aorist, middle, Participle, dative, plural, masculine",
    GN: "G1586",
    gloss: "to select",
  },
  ἐκλεξάμενος: {
    parse:
      "V-AMP-NSM｜Verb, aorist, middle, Participle, nominative, singular, masculine",
    GN: "G1586",
    gloss: "to select",
  },
  ἐκλεξαμένους: {
    parse:
      "V-AMP-APM｜Verb, aorist, middle, Participle, accusative, plural, masculine",
    GN: "G1586",
    gloss: "to select",
  },
  ἐξελέγοντο: {
    parse: "V-IMI-3P｜Verb, imperfect, middle, indicative, third, plural",
    GN: "G1586",
    gloss: "to select",
  },
  ἐξελεξάμην: {
    parse: "V-AMI-1S｜Verb, aorist, middle, indicative, first, singular",
    GN: "G1586",
    gloss: "to select",
  },
  ἐξελέξαντο: {
    parse: "V-AMI-3P｜Verb, aorist, middle, indicative, third, plural",
    GN: "G1586",
    gloss: "to select",
  },
  ἐξελέξασθε: {
    parse: "V-AMI-2P｜Verb, aorist, middle, indicative, second, plural",
    GN: "G1586",
    gloss: "to select",
  },
  ἐξελέξατο: {
    parse: "V-AMI-3S｜Verb, aorist, middle, indicative, third, singular",
    GN: "G1586",
    gloss: "to select",
  },
  ἐξελέξω: {
    parse: "V-AMI-2S｜Verb, aorist, middle, indicative, second, singular",
    GN: "G1586",
    gloss: "to select",
  },
  ἐκλείψουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G1587",
    gloss: "to fail",
  },
  ἐκλίπῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G1587",
    gloss: "to fail",
  },
  ἐκλιπόντος: {
    parse:
      "V-2AAP-GSM｜Verb, 2nd aorist, active, Participle, genitive, singular, masculine",
    GN: "G1587",
    gloss: "to fail",
  },
  ἐκλεκτῇ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G1588",
    gloss: "select",
  },
  ἐκλεκτῆς: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G1588",
    gloss: "select",
  },
  ἐκλεκτοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G1588",
    gloss: "select",
  },
  ἐκλεκτοὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G1588",
    gloss: "select",
  },
  ἐκλεκτοῖς: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G1588",
    gloss: "select",
  },
  ἐκλεκτὸν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G1588",
    gloss: "select",
  },
  ἐκλεκτόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G1588",
    gloss: "select",
  },
  ἐκλεκτός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1588",
    gloss: "select",
  },
  ἐκλεκτούς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G1588",
    gloss: "select",
  },
  ἐκλεκτοὺς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G1588",
    gloss: "select",
  },
  ἐκλεκτῶν: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G1588",
    gloss: "select",
  },
  ἐκλογὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1589",
    gloss: "selecting",
  },
  ἐκλογὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1589",
    gloss: "selecting",
  },
  ἐκλογῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1589",
    gloss: "selecting",
  },
  ἐκλυθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G1590",
    gloss: "to faint",
  },
  ἐκλυθῶσιν: {
    parse: "V-APS-3P｜Verb, aorist, passive, subjunctive, third, plural",
    GN: "G1590",
    gloss: "to faint",
  },
  ἐκλυόμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G1590",
    gloss: "to faint",
  },
  ἐκλύου: {
    parse: "V-PPM-2S｜Verb, present, passive, imperative, second, singular",
    GN: "G1590",
    gloss: "to faint",
  },
  ἐκμάξασα: {
    parse:
      "V-AAP-NSF｜Verb, aorist, active, Participle, nominative, singular, feminine",
    GN: "G1591",
    gloss: "to wipe off",
  },
  ἐκμάσσειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1591",
    gloss: "to wipe off",
  },
  ἐξέμαξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1591",
    gloss: "to wipe off",
  },
  ἐξέμασσεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1591",
    gloss: "to wipe off",
  },
  ἐξεμυκτήριζον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1592",
    gloss: "to ridicule",
  },
  ἐξένευσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1593",
    gloss: "to withdraw",
  },
  ἐκνήψατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G1594",
    gloss: "to wake up",
  },
  ἑκούσιον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G1595",
    gloss: "voluntary",
  },
  ἑκουσίως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1596",
    gloss: "voluntarily",
  },
  Ἑκουσίως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1596",
    gloss: "voluntarily",
  },
  ἔκπαλαι: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1597",
    gloss: "of old",
  },
  ἐκπειράζωμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G1598",
    gloss: "to test/tempt",
  },
  ἐκπειράζων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1598",
    gloss: "to test/tempt",
  },
  ἐκπειράσεις: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G1598",
    gloss: "to test/tempt",
  },
  ἐπειράσθησαν: {
    parse: "V-FAI-2S｜Verb, aorist, passive, indicative, third, plural",
    GN: "G1598",
    gloss: "to test/tempt",
  },
  ἐκπεμφθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G1599",
    gloss: "to send away/out",
  },
  ἐξέπεμψαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1599",
    gloss: "to send away/out",
  },
  ἐξεπέτασα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G1600",
    gloss: "to extend",
  },
  ἐκπέπτωκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G1601",
    gloss: "to fall out",
  },
  ἐκπεσεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G1601",
    gloss: "to fall out",
  },
  ἐκπέσητε: {
    parse: "V-2AAS-2P｜Verb, 2nd aorist, active, subjunctive, second, plural",
    GN: "G1601",
    gloss: "to fall out",
  },
  ἐκπέσωμεν: {
    parse: "V-2AAS-1P｜Verb, 2nd aorist, active, subjunctive, first, plural",
    GN: "G1601",
    gloss: "to fall out",
  },
  ἐκπέσωσιν: {
    parse: "V-2AAS-3P｜Verb, 2nd aorist, active, subjunctive, third, plural",
    GN: "G1601",
    gloss: "to fall out",
  },
  ἐξέπεσαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G1601",
    gloss: "to fall out",
  },
  ἐξεπέσατε: {
    parse: "V-2AAI-2P｜Verb, 2nd aorist, active, indicative, second, plural",
    GN: "G1601",
    gloss: "to fall out",
  },
  ἐξέπεσεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G1601",
    gloss: "to fall out",
  },
  ἐκπλεῦσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1602",
    gloss: "to sail out/away",
  },
  ἐξέπλει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1602",
    gloss: "to sail out/away",
  },
  ἐξεπλεύσαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G1602",
    gloss: "to sail out/away",
  },
  ἐκπεπλήρωκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G1603",
    gloss: "to fulfill",
  },
  ἐκπλήρωσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1604",
    gloss: "fulfillment",
  },
  ἐκπλήσσεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G1605",
    gloss: "be astonished",
  },
  ἐκπλησσόμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G1605",
    gloss: "be astonished",
  },
  ἐξεπλάγησαν: {
    parse: "V-2API-3P｜Verb, 2nd aorist, passive, indicative, third, plural",
    GN: "G1605",
    gloss: "be astonished",
  },
  ἐξεπλήσσετο: {
    parse: "V-IPI-3S｜Verb, imperfect, passive, indicative, third, singular",
    GN: "G1605",
    gloss: "be astonished",
  },
  ἐξεπλήσσοντο: {
    parse: "V-IPI-3P｜Verb, imperfect, passive, indicative, third, plural",
    GN: "G1605",
    gloss: "be astonished",
  },
  ἐξέπνευσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1606",
    gloss: "to expire",
  },
  ἐκπορεύεσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G1607",
    gloss: "to depart",
  },
  ἐκπορευέσθω: {
    parse:
      "V-PNM-3S｜Verb, present, Middle or Passive deponent, imperative, third, singular",
    GN: "G1607",
    gloss: "to depart",
  },
  ἐκπορεύεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G1607",
    gloss: "to depart",
  },
  ἐκπορευόμενα: {
    parse:
      "V-PNP-NPN｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, neuter",
    GN: "G1607",
    gloss: "to depart",
  },
  ἐκπορευόμενά: {
    parse:
      "V-PNP-NPN｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, neuter",
    GN: "G1607",
    gloss: "to depart",
  },
  ἐκπορευομένη: {
    parse:
      "V-PNP-NSF｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, feminine",
    GN: "G1607",
    gloss: "to depart",
  },
  ἐκπορευόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G1607",
    gloss: "to depart",
  },
  ἐκπορευομένοις: {
    parse:
      "V-PNP-DPM｜Verb, present, Middle or Passive deponent, Participle, dative, plural, masculine",
    GN: "G1607",
    gloss: "to depart",
  },
  ἐκπορευόμενον: {
    parse:
      "V-PNP-NSN｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, neuter",
    GN: "G1607",
    gloss: "to depart",
  },
  ἐκπορευόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G1607",
    gloss: "to depart",
  },
  ἐκπορευομένου: {
    parse:
      "V-PNP-GSN｜Verb, present, Middle or Passive deponent, Participle, genitive, singular, neuter",
    GN: "G1607",
    gloss: "to depart",
  },
  ἐκπορευομένῳ: {
    parse:
      "V-PNP-DSN｜Verb, present, Middle or Passive deponent, Participle, dative, singular, neuter",
    GN: "G1607",
    gloss: "to depart",
  },
  ἐκπορευομένων: {
    parse:
      "V-PNP-GPM｜Verb, present, Middle or Passive deponent, Participle, genitive, plural, masculine",
    GN: "G1607",
    gloss: "to depart",
  },
  ἐκπορεύονται: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G1607",
    gloss: "to depart",
  },
  ἐκπορεύσονται: {
    parse: "V-FDI-3P｜Verb, future, Middle deponent, indicative, third, plural",
    GN: "G1607",
    gloss: "to depart",
  },
  ἐξεπορεύετο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G1607",
    gloss: "to depart",
  },
  ἐξεπορεύοντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G1607",
    gloss: "to depart",
  },
  ἐκπορνεύσασαι: {
    parse:
      "V-AAP-NPF｜Verb, aorist, active, Participle, nominative, plural, feminine",
    GN: "G1608",
    gloss: "to fornicate",
  },
  ἐξεπτύσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G1609",
    gloss: "to spit out",
  },
  ἐκριζωθέντα: {
    parse:
      "V-APP-NPN｜Verb, aorist, passive, Participle, nominative, plural, neuter",
    GN: "G1610",
    gloss: "to uproot",
  },
  ἐκριζωθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G1610",
    gloss: "to uproot",
  },
  Ἐκριζώθητι: {
    parse: "V-APM-2S｜Verb, aorist, passive, imperative, second, singular",
    GN: "G1610",
    gloss: "to uproot",
  },
  ἐκριζώσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G1610",
    gloss: "to uproot",
  },
  ἐκστάσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1611",
    gloss: "amazement",
  },
  ἐκστάσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1611",
    gloss: "amazement",
  },
  ἔκστασις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1611",
    gloss: "amazement",
  },
  ἐξέστραπται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G1612",
    gloss: "be warped",
  },
  ἐκταράσσουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1613",
    gloss: "to disturb greatly",
  },
  ἐκτείνας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G1614",
    gloss: "to stretch out",
  },
  ἐκτείνειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1614",
    gloss: "to stretch out",
  },
  Ἔκτεινον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G1614",
    gloss: "to stretch out",
  },
  Ἔκτεινόν: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G1614",
    gloss: "to stretch out",
  },
  ἐκτενεῖς: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G1614",
    gloss: "to stretch out",
  },
  ἐξετείνατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G1614",
    gloss: "to stretch out",
  },
  ἐξέτεινεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1614",
    gloss: "to stretch out",
  },
  ἐκτελέσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1615",
    gloss: "to finish up",
  },
  ἐκτενείᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1616",
    gloss: "earnestness",
  },
  ἐκτενέστερον: {
    parse: "A-ASN-C｜Adjective, accusative, singular, neuter, Comparative",
    GN: "G1617",
    gloss: "earnestly",
  },
  ἐκτενῆ: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G1618",
    gloss: "fervent",
  },
  ἐκτενῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1619",
    gloss: "fervently",
  },
  ἐκτεθέντος: {
    parse:
      "V-APP-GSM｜Verb, aorist, passive, Participle, genitive, singular, masculine",
    GN: "G1620",
    gloss: "to explain/expose",
  },
  ἐξέθεντο: {
    parse: "V-2AMI-3P｜Verb, 2nd aorist, middle, indicative, third, plural",
    GN: "G1620",
    gloss: "to explain/expose",
  },
  ἐξετίθετο: {
    parse: "V-IMI-3S｜Verb, imperfect, middle, indicative, third, singular",
    GN: "G1620",
    gloss: "to explain/expose",
  },
  ἐκτιναξάμενοι: {
    parse:
      "V-AMP-NPM｜Verb, aorist, middle, Participle, nominative, plural, masculine",
    GN: "G1621",
    gloss: "to shake out/off",
  },
  ἐκτιναξάμενος: {
    parse:
      "V-AMP-NSM｜Verb, aorist, middle, Participle, nominative, singular, masculine",
    GN: "G1621",
    gloss: "to shake out/off",
  },
  ἐκτινάξατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G1621",
    gloss: "to shake out/off",
  },
  ἐκτὸς: { parse: "PREP｜Preposition", GN: "G1622", gloss: "outside/except" },
  ἕκτη: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G1623",
    gloss: "sixth",
  },
  ἕκτην: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G1623",
    gloss: "sixth",
  },
  ἕκτης: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G1623",
    gloss: "sixth",
  },
  ἕκτος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1623",
    gloss: "sixth",
  },
  ἕκτῳ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G1623",
    gloss: "sixth",
  },
  ἐκτραπῇ: {
    parse: "V-2APS-3S｜Verb, 2nd aorist, passive, subjunctive, third, singular",
    GN: "G1624",
    gloss: "to turn/wander away",
  },
  ἐκτραπήσονται: {
    parse: "V-2FPI-3P｜Verb, 2nd future, passive, indicative, third, plural",
    GN: "G1624",
    gloss: "to turn/wander away",
  },
  ἐκτρεπόμενος: {
    parse:
      "V-PMP-NSM｜Verb, present, middle, Participle, nominative, singular, masculine",
    GN: "G1624",
    gloss: "to turn/wander away",
  },
  ἐξετράπησαν: {
    parse: "V-2API-3P｜Verb, 2nd aorist, passive, indicative, third, plural",
    GN: "G1624",
    gloss: "to turn/wander away",
  },
  ἐκτρέφει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1625",
    gloss: "to nourish/rear",
  },
  ἐκτρέφετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G1625",
    gloss: "to nourish/rear",
  },
  ἐκτρώματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G1626",
    gloss: "untimely birth",
  },
  ἐκφέρειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1627",
    gloss: "to bring/carry out",
  },
  ἐκφέρουσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G1627",
    gloss: "to bring/carry out",
  },
  ἐξενέγκαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G1627",
    gloss: "to bring/carry out",
  },
  ἐξενέγκατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G1627",
    gloss: "to bring/carry out",
  },
  ἐξενεγκεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G1627",
    gloss: "to bring/carry out",
  },
  ἐξήνεγκεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1627",
    gloss: "to bring/carry out",
  },
  ἐξοίσουσίν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G1627",
    gloss: "to bring/carry out",
  },
  ἐκπεφευγέναι: {
    parse: "V-2RAN｜Verb, 2nd perfect, active, Infinitive",
    GN: "G1628",
    gloss: "to escape",
  },
  ἐκφεύξῃ: {
    parse:
      "V-FDI-2S｜Verb, future, Middle deponent, indicative, second, singular",
    GN: "G1628",
    gloss: "to escape",
  },
  ἐκφευξόμεθα: {
    parse: "V-FDI-1P｜Verb, future, Middle deponent, indicative, first, plural",
    GN: "G1628",
    gloss: "to escape",
  },
  ἐκφυγεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G1628",
    gloss: "to escape",
  },
  ἐκφύγωσιν: {
    parse: "V-2AAS-3P｜Verb, 2nd aorist, active, subjunctive, third, plural",
    GN: "G1628",
    gloss: "to escape",
  },
  ἐξέφυγον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G1628",
    gloss: "to escape",
  },
  ἐκφοβεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1629",
    gloss: "to terrify",
  },
  ἔκφοβοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G1630",
    gloss: "terrified",
  },
  Ἔκφοβός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1630",
    gloss: "terrified",
  },
  ἐκφύῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G1631",
    gloss: "to put out",
  },
  ἐκκεχυμένον: {
    parse:
      "V-RPP-NSN｜Verb, perfect, passive, Participle, nominative, singular, neuter",
    GN: "G1632",
    gloss: "to pour out",
  },
  ἐκκέχυται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G1632",
    gloss: "to pour out",
  },
  ἐκχέαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1632",
    gloss: "to pour out",
  },
  ἐκχέετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G1632",
    gloss: "to pour out",
  },
  ἐκχεῖται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G1632",
    gloss: "to pour out",
  },
  ἐκχεῶ: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G1632",
    gloss: "to pour out",
  },
  ἐκχυθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G1632",
    gloss: "to pour out",
  },
  ἐκχυννόμενον: {
    parse:
      "V-PPP-NSN｜Verb, present, passive, Participle, nominative, singular, neuter",
    GN: "G1632",
    gloss: "to pour out",
  },
  ἐξέχεαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1632",
    gloss: "to pour out",
  },
  ἐξέχεεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1632",
    gloss: "to pour out",
  },
  ἐξεχύθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G1632",
    gloss: "to pour out",
  },
  ἐξεχύθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G1632",
    gloss: "to pour out",
  },
  ἐξεχύννετο: {
    parse: "V-IPI-3S｜Verb, imperfect, passive, indicative, third, singular",
    GN: "G1632",
    gloss: "to pour out",
  },
  ἐκχωρείτωσαν: {
    parse: "V-PAM-3P｜Verb, present, active, imperative, third, plural",
    GN: "G1633",
    gloss: "to go out",
  },
  ἐξέψυξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1634",
    gloss: "to expire",
  },
  ἑκοῦσα: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G1635",
    gloss: "voluntarily",
  },
  ἑκὼν: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1635",
    gloss: "voluntarily",
  },
  ἐλαίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1636",
    gloss: "olive tree",
  },
  ἐλαῖαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G1636",
    gloss: "olive tree",
  },
  ἐλαίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1636",
    gloss: "olive tree",
  },
  ἔλαιον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G1637",
    gloss: "olive oil",
  },
  ἐλαίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G1637",
    gloss: "olive oil",
  },
  ἐλαίῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G1637",
    gloss: "olive oil",
  },
  Ἐλαιῶν: {
    parse: "N-GPF-L｜Noun, genitive, plural, feminine, Location",
    GN: "G1638",
    gloss: "Olivet",
  },
  Ἐλαιῶνος: {
    parse: "N-GSM-L｜Noun, genitive, singular, masculine, Location",
    GN: "G1638",
    gloss: "Olivet",
  },
  Ἐλαμῖται: {
    parse: "N-NPM-LG｜Noun, nominative, plural, masculine, Location Gentilic",
    GN: "G1639",
    gloss: "Elamite",
  },
  ἐλάσσονι: {
    parse: "A-DSM-C｜Adjective, dative, singular, masculine, Comparative",
    GN: "G1640",
    gloss: "lesser",
  },
  ἐλάσσω: {
    parse: "A-ASM-C｜Adjective, accusative, singular, masculine, Comparative",
    GN: "G1640",
    gloss: "lesser",
  },
  ἔλαττον: {
    parse: "A-NSN-C｜Adjective, nominative, singular, neuter, Comparative",
    GN: "G1640",
    gloss: "lesser",
  },
  ἠλαττόνησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1641",
    gloss: "to have little",
  },
  ἐλαττοῦσθαι: {
    parse: "V-PEN｜Verb, present, Either middle or passive, Infinitive",
    GN: "G1642",
    gloss: "to make less",
  },
  ἠλαττωμένον: {
    parse:
      "V-RPP-ASM｜Verb, perfect, passive, Participle, accusative, singular, masculine",
    GN: "G1642",
    gloss: "to make less",
  },
  ἠλάττωσας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G1642",
    gloss: "to make less",
  },
  ἐλαύνειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1643",
    gloss: "to drive",
  },
  ἐλαυνόμενα: {
    parse:
      "V-PPP-NPN｜Verb, present, passive, Participle, nominative, plural, neuter",
    GN: "G1643",
    gloss: "to drive",
  },
  ἐλαυνόμεναι: {
    parse:
      "V-PPP-NPF｜Verb, present, passive, Participle, nominative, plural, feminine",
    GN: "G1643",
    gloss: "to drive",
  },
  ἐληλακότες: {
    parse:
      "V-RAP-NPM｜Verb, perfect, active, Participle, nominative, plural, masculine",
    GN: "G1643",
    gloss: "to drive",
  },
  ἠλαύνετο: {
    parse: "V-IPI-3S｜Verb, imperfect, passive, indicative, third, singular",
    GN: "G1643",
    gloss: "to drive",
  },
  ἐλαφρίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1644",
    gloss: "lightness",
  },
  ἐλαφρόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G1645",
    gloss: "light",
  },
  ἐλαφρὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G1645",
    gloss: "light",
  },
  ἐλαχίστη: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G1646",
    gloss: "least",
  },
  ἐλάχιστον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G1646",
    gloss: "least",
  },
  ἐλάχιστόν: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G1646",
    gloss: "least",
  },
  ἐλάχιστος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1646",
    gloss: "least",
  },
  ἐλαχιστοτέρῳ: {
    parse: "A-DSM-C｜Adjective, dative, singular, masculine, Comparative",
    GN: "G1646",
    gloss: "least",
  },
  ἐλαχίστου: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G1646",
    gloss: "least",
  },
  ἐλαχίστῳ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G1646",
    gloss: "least",
  },
  ἐλαχίστων: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G1646",
    gloss: "least",
  },
  Ἐλεάζαρ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G1648",
    gloss: "Eleazar",
  },
  ἔλεγξιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1649",
    gloss: "rebuke",
  },
  ἔλεγχος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1650",
    gloss: "rebuke",
  },
  ἐλέγξαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1651",
    gloss: "to rebuke",
  },
  ἐλέγξει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G1651",
    gloss: "to rebuke",
  },
  ἔλεγξον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G1651",
    gloss: "to rebuke",
  },
  ἔλεγχε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G1651",
    gloss: "to rebuke",
  },
  ἐλέγχει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1651",
    gloss: "to rebuke",
  },
  ἐλέγχειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1651",
    gloss: "to rebuke",
  },
  ἐλέγχεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G1651",
    gloss: "to rebuke",
  },
  ἐλέγχετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G1651",
    gloss: "to rebuke",
  },
  ἐλεγχθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G1651",
    gloss: "to rebuke",
  },
  ἐλεγχόμενα: {
    parse:
      "V-PPP-NPN｜Verb, present, passive, Participle, nominative, plural, neuter",
    GN: "G1651",
    gloss: "to rebuke",
  },
  ἐλεγχόμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G1651",
    gloss: "to rebuke",
  },
  ἐλεγχόμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G1651",
    gloss: "to rebuke",
  },
  ἐλέγχω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G1651",
    gloss: "to rebuke",
  },
  ἐλεεινὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1652",
    gloss: "pitiful",
  },
  ἐλεεινότεροι: {
    parse: "A-NPM-C｜Adjective, nominative, plural, masculine, Comparative",
    GN: "G1652",
    gloss: "pitiful",
  },
  ἐλεᾶτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G1653",
    gloss: "to have mercy",
  },
  ἐλεεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1653",
    gloss: "to have mercy",
  },
  ἐλεηθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G1653",
    gloss: "to have mercy",
  },
  ἐλεηθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G1653",
    gloss: "to have mercy",
  },
  ἐλεηθῶσιν: {
    parse: "V-APS-3P｜Verb, aorist, passive, subjunctive, third, plural",
    GN: "G1653",
    gloss: "to have mercy",
  },
  ἐλεῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1653",
    gloss: "to have mercy",
  },
  ἐλεήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G1653",
    gloss: "to have mercy",
  },
  ἐλέησον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G1653",
    gloss: "to have mercy",
  },
  Ἐλέησον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G1653",
    gloss: "to have mercy",
  },
  ἐλέησόν: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G1653",
    gloss: "to have mercy",
  },
  Ἐλέησόν: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G1653",
    gloss: "to have mercy",
  },
  Ἐλεήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G1653",
    gloss: "to have mercy",
  },
  ἐλεῶ: {
    parse: "V-PAS-1S｜Verb, present, active, subjunctive, first, singular",
    GN: "G1653",
    gloss: "to have mercy",
  },
  ἐλεῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1653",
    gloss: "to have mercy",
  },
  ἐλεῶντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G1653",
    gloss: "to have mercy",
  },
  ἠλεήθημεν: {
    parse: "V-API-1P｜Verb, aorist, passive, indicative, first, plural",
    GN: "G1653",
    gloss: "to have mercy",
  },
  ἠλεήθην: {
    parse: "V-API-1S｜Verb, aorist, passive, indicative, first, singular",
    GN: "G1653",
    gloss: "to have mercy",
  },
  ἠλεήθητε: {
    parse: "V-API-2P｜Verb, aorist, passive, indicative, second, plural",
    GN: "G1653",
    gloss: "to have mercy",
  },
  ἠλεημένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G1653",
    gloss: "to have mercy",
  },
  ἠλεημένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G1653",
    gloss: "to have mercy",
  },
  ἠλέησα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G1653",
    gloss: "to have mercy",
  },
  ἠλέησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1653",
    gloss: "to have mercy",
  },
  ἠλέησέν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1653",
    gloss: "to have mercy",
  },
  ἐλεημοσύναι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G1654",
    gloss: "charity",
  },
  ἐλεημοσύνας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G1654",
    gloss: "charity",
  },
  ἐλεημοσύνη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1654",
    gloss: "charity",
  },
  ἐλεημοσύνην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1654",
    gloss: "charity",
  },
  ἐλεημοσυνῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G1654",
    gloss: "charity",
  },
  ἐλεήμονες: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G1655",
    gloss: "merciful",
  },
  ἐλεήμων: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1655",
    gloss: "merciful",
  },
  ἐλέει: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G1656",
    gloss: "mercy",
  },
  ἔλεος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G1656",
    gloss: "mercy",
  },
  Ἔλεος: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G1656",
    gloss: "mercy",
  },
  ἐλέους: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G1656",
    gloss: "mercy",
  },
  ἐλευθερίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1657",
    gloss: "freedom",
  },
  ἐλευθερία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1657",
    gloss: "freedom",
  },
  ἐλευθερίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1657",
    gloss: "freedom",
  },
  ἐλευθερίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1657",
    gloss: "freedom",
  },
  ἐλευθέρα: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G1658",
    gloss: "free/freedom",
  },
  ἐλευθέρας: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G1658",
    gloss: "free/freedom",
  },
  ἐλεύθεροι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G1658",
    gloss: "free/freedom",
  },
  Ἐλεύθεροι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G1658",
    gloss: "free/freedom",
  },
  ἐλεύθεροί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G1658",
    gloss: "free/freedom",
  },
  ἐλεύθερος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1658",
    gloss: "free/freedom",
  },
  Ἐλεύθερος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1658",
    gloss: "free/freedom",
  },
  ἐλευθέρους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G1658",
    gloss: "free/freedom",
  },
  ἐλευθέρων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G1658",
    gloss: "free/freedom",
  },
  ἐλευθερωθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G1659",
    gloss: "to set free",
  },
  ἐλευθερωθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G1659",
    gloss: "to set free",
  },
  ἐλευθερώσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G1659",
    gloss: "to set free",
  },
  ἐλευθερώσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G1659",
    gloss: "to set free",
  },
  ἠλευθέρωσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1659",
    gloss: "to set free",
  },
  ἠλευθέρωσέν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1659",
    gloss: "to set free",
  },
  ἐλεύσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1660",
    gloss: "coming",
  },
  ἐλεφάντινον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G1661",
    gloss: "made of ivory",
  },
  Ἐλιακίμ: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G1662",
    gloss: "Eliakim",
  },
  Ἐλιακὶμ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G1662",
    gloss: "Eliakim",
  },
  Ἐλιέζερ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G1663",
    gloss: "Eliezer",
  },
  Ἐλιούδ: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G1664",
    gloss: "Eliud",
  },
  Ἐλιοὺδ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G1664",
    gloss: "Eliud",
  },
  Ἐλισάβετ: {
    parse: "N-NSF-P｜Noun, nominative, singular, feminine, Person",
    GN: "G1665",
    gloss: "Elizabeth",
  },
  Ἐλισαίου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G1666",
    gloss: "Elisha",
  },
  ἑλίξεις: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G1667",
    gloss: "to roll up",
  },
  ἑλισσόμενον: {
    parse:
      "V-PPP-NSN｜Verb, present, passive, Participle, nominative, singular, neuter",
    GN: "G1667",
    gloss: "to roll up",
  },
  ἕλκη: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G1668",
    gloss: "sore",
  },
  ἕλκος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G1668",
    gloss: "sore",
  },
  ἑλκῶν: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G1668",
    gloss: "sore",
  },
  εἱλκωμένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G1669",
    gloss: "to have/cause sores",
  },
  εἷλκον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1670",
    gloss: "to draw/persuade",
  },
  εἵλκυσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1670",
    gloss: "to draw/persuade",
  },
  εἵλκυσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1670",
    gloss: "to draw/persuade",
  },
  ἕλκουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1670",
    gloss: "to draw/persuade",
  },
  ἑλκύσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1670",
    gloss: "to draw/persuade",
  },
  ἑλκύσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G1670",
    gloss: "to draw/persuade",
  },
  ἑλκύσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G1670",
    gloss: "to draw/persuade",
  },
  Ἑλλάδα: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G1671",
    gloss: "Greece",
  },
  Ἕλλην: {
    parse: "N-NSM-LG｜Noun, nominative, singular, masculine, Location Gentilic",
    GN: "G1672",
    gloss: "Greek, Gentile",
  },
  Ἕλληνας: {
    parse: "N-APM-LG｜Noun, accusative, plural, masculine, Location Gentilic",
    GN: "G1672",
    gloss: "Greek, Gentile",
  },
  Ἕλληνες: {
    parse: "N-NPM-LG｜Noun, nominative, plural, masculine, Location Gentilic",
    GN: "G1672",
    gloss: "Greek, Gentile",
  },
  Ἕλληνές: {
    parse: "N-NPM-LG｜Noun, nominative, plural, masculine, Location Gentilic",
    GN: "G1672",
    gloss: "Greek, Gentile",
  },
  Ἕλληνι: {
    parse: "N-DSM-LG｜Noun, dative, singular, masculine, Location Gentilic",
    GN: "G1672",
    gloss: "Greek, Gentile",
  },
  Ἕλληνος: {
    parse: "N-GSM-LG｜Noun, genitive, singular, masculine, Location Gentilic",
    GN: "G1672",
    gloss: "Greek, Gentile",
  },
  Ἑλλήνων: {
    parse: "N-GPM-LG｜Noun, genitive, plural, masculine, Location Gentilic",
    GN: "G1672",
    gloss: "Greek, Gentile",
  },
  Ἕλλησιν: {
    parse: "N-DPM-LG｜Noun, dative, plural, masculine, Location Gentilic",
    GN: "G1672",
    gloss: "Greek, Gentile",
  },
  Ἕλλησίν: {
    parse: "N-DPM-LG｜Noun, dative, plural, masculine, Location Gentilic",
    GN: "G1672",
    gloss: "Greek, Gentile",
  },
  Ἑλληνικῇ: {
    parse: "A-DSF-LG｜Adjective, dative, singular, feminine, Location Gentilic",
    GN: "G1673",
    gloss: "Greek",
  },
  Ἑλληνίδων: {
    parse: "N-GPF-LG｜Noun, genitive, plural, feminine, Location Gentilic",
    GN: "G1674",
    gloss: "Gentile",
  },
  Ἑλληνίς: {
    parse: "N-NSF-LG｜Noun, nominative, singular, feminine, Location Gentilic",
    GN: "G1674",
    gloss: "Gentile",
  },
  Ἑλληνιστάς: {
    parse: "N-APM-LG｜Noun, accusative, plural, masculine, Location Gentilic",
    GN: "G1675",
    gloss: "Hellenist",
  },
  Ἑλληνιστῶν: {
    parse: "N-GPM-LG｜Noun, genitive, plural, masculine, Location Gentilic",
    GN: "G1675",
    gloss: "Hellenist",
  },
  Ἑλληνιστί: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1676",
    gloss: "Greek",
  },
  Ἑλληνιστὶ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1676",
    gloss: "Greek",
  },
  ἐλλόγα: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G1677",
    gloss: "to charge",
  },
  ἐλλογεῖται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G1677",
    gloss: "to charge",
  },
  Ἐλμαδὰμ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G1678",
    gloss: "Elmadam",
  },
  ἐλπίζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1679",
    gloss: "to hope/expect",
  },
  ἐλπίζετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G1679",
    gloss: "to hope/expect",
  },
  ἐλπίζομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G1679",
    gloss: "to hope/expect",
  },
  ἐλπιζομένων: {
    parse:
      "V-PPP-GPN｜Verb, present, passive, Participle, genitive, plural, neuter",
    GN: "G1679",
    gloss: "to hope/expect",
  },
  ἐλπίζουσαι: {
    parse:
      "V-PAP-NPF｜Verb, present, active, Participle, nominative, plural, feminine",
    GN: "G1679",
    gloss: "to hope/expect",
  },
  ἐλπίζω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G1679",
    gloss: "to hope/expect",
  },
  Ἐλπίζω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G1679",
    gloss: "to hope/expect",
  },
  ἐλπίζων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1679",
    gloss: "to hope/expect",
  },
  ἐλπιοῦσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G1679",
    gloss: "to hope/expect",
  },
  ἐλπίσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G1679",
    gloss: "to hope/expect",
  },
  ἤλπιζέν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1679",
    gloss: "to hope/expect",
  },
  ἠλπίζομεν: {
    parse: "V-IAI-1P｜Verb, imperfect, active, indicative, first, plural",
    GN: "G1679",
    gloss: "to hope/expect",
  },
  ἠλπίκαμεν: {
    parse: "V-RAI-1P｜Verb, perfect, active, indicative, first, plural",
    GN: "G1679",
    gloss: "to hope/expect",
  },
  ἠλπίκατε: {
    parse: "V-RAI-2P｜Verb, perfect, active, indicative, second, plural",
    GN: "G1679",
    gloss: "to hope/expect",
  },
  ἤλπικεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G1679",
    gloss: "to hope/expect",
  },
  ἠλπικέναι: {
    parse: "V-RAN｜Verb, perfect, active, Infinitive",
    GN: "G1679",
    gloss: "to hope/expect",
  },
  ἠλπικότες: {
    parse:
      "V-RAP-NPM｜Verb, perfect, active, Participle, nominative, plural, masculine",
    GN: "G1679",
    gloss: "to hope/expect",
  },
  ἠλπίσαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G1679",
    gloss: "to hope/expect",
  },
  ἐλπίδα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1680",
    gloss: "hope",
  },
  ἐλπίδι: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1680",
    gloss: "hope",
  },
  ἑλπίδι: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1680",
    gloss: "hope",
  },
  ἐλπίδος: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1680",
    gloss: "hope",
  },
  ἐλπίς: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1680",
    gloss: "hope",
  },
  ἐλπὶς: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1680",
    gloss: "hope",
  },
  Ἐλύμας: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G1681",
    gloss: "Elymas",
  },
  ἐλωῒ: {
    parse: "ARAM｜Aramaic transliterated word (indeclinable)",
    GN: "G1682",
    gloss: "Eloi",
  },
  Ἐλωῒ: {
    parse: "ARAM｜Aramaic transliterated word (indeclinable)",
    GN: "G1682",
    gloss: "Eloi",
  },
  ἐμαυτόν: {
    parse: "F-1ASM｜Reflexive pronoun, first, accusative, singular, masculine",
    GN: "G1683",
    gloss: "myself",
  },
  ἐμαυτὸν: {
    parse: "F-1ASM｜Reflexive pronoun, first, accusative, singular, masculine",
    GN: "G1683",
    gloss: "myself",
  },
  ἐμαυτοῦ: {
    parse: "F-1GSM｜Reflexive pronoun, first, genitive, singular, masculine",
    GN: "G1683",
    gloss: "myself",
  },
  ἐμαυτῷ: {
    parse: "F-1DSM｜Reflexive pronoun, first, dative, singular, masculine",
    GN: "G1683",
    gloss: "myself",
  },
  ἐμβαίνοντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G1684",
    gloss: "to climb",
  },
  ἐμβάντα: {
    parse:
      "V-2AAP-ASM｜Verb, 2nd aorist, active, Participle, accusative, singular, masculine",
    GN: "G1684",
    gloss: "to climb",
  },
  ἐμβάντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G1684",
    gloss: "to climb",
  },
  ἐμβάντι: {
    parse:
      "V-2AAP-DSM｜Verb, 2nd aorist, active, Participle, dative, singular, masculine",
    GN: "G1684",
    gloss: "to climb",
  },
  ἐμβὰς: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G1684",
    gloss: "to climb",
  },
  ἐμβῆναι: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G1684",
    gloss: "to climb",
  },
  ἐνέβη: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G1684",
    gloss: "to climb",
  },
  ἐνέβησαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G1684",
    gloss: "to climb",
  },
  ἐμβαλεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G1685",
    gloss: "to throw in",
  },
  ἐμβαπτόμενος: {
    parse:
      "V-PMP-NSM｜Verb, present, middle, Participle, nominative, singular, masculine",
    GN: "G1686",
    gloss: "to dip in",
  },
  ἐμβάψας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G1686",
    gloss: "to dip in",
  },
  ἐμβατεύων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1687",
    gloss: "to investigate",
  },
  ἐνεβίβασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1688",
    gloss: "to put on board",
  },
  ἐμβλέποντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1689",
    gloss: "to look into/upon",
  },
  ἐμβλέψας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G1689",
    gloss: "to look into/upon",
  },
  ἐμβλέψασα: {
    parse:
      "V-AAP-NSF｜Verb, aorist, active, Participle, nominative, singular, feminine",
    GN: "G1689",
    gloss: "to look into/upon",
  },
  ἐμβλέψατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G1689",
    gloss: "to look into/upon",
  },
  ἐνέβλεπεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1689",
    gloss: "to look into/upon",
  },
  ἐνέβλεπον: {
    parse: "V-IAI-1S｜Verb, imperfect, active, indicative, first, singular",
    GN: "G1689",
    gloss: "to look into/upon",
  },
  ἐνέβλεψεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1689",
    gloss: "to look into/upon",
  },
  ἐμβριμησάμενος: {
    parse:
      "V-ADP-NSM｜Verb, aorist, Middle deponent, Participle, nominative, singular, masculine",
    GN: "G1690",
    gloss: "be agitated",
  },
  ἐμβριμώμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G1690",
    gloss: "be agitated",
  },
  ἐνεβριμήθη: {
    parse:
      "V-AOI-3S｜Verb, aorist, Passive deponent, indicative, third, singular",
    GN: "G1690",
    gloss: "be agitated",
  },
  ἐνεβριμήσατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G1690",
    gloss: "be agitated",
  },
  ἐνεβριμῶντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G1690",
    gloss: "be agitated",
  },
  ἐμέσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1692",
    gloss: "to vomit",
  },
  ἐμμαινόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G1693",
    gloss: "be enraged",
  },
  Ἐμμανουήλ: {
    parse: "N-ASM-T｜Noun, accusative, singular, masculine, Title",
    GN: "G1694",
    gloss: "Immanuel",
  },
  Ἐμμαοῦς: {
    parse: "N-NSF-L｜Noun, nominative, singular, feminine, Location",
    GN: "G1695",
    gloss: "Emmaus",
  },
  ἐμμένει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1696",
    gloss: "to abide in/by",
  },
  ἐμμένειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1696",
    gloss: "to abide in/by",
  },
  ἐνέμειναν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1696",
    gloss: "to abide in/by",
  },
  Ἐνέμεινεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1696",
    gloss: "to abide in/by",
  },
  Ἑμμὼρ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G1697",
    gloss: "Hamor",
  },
  ἐμὰ: {
    parse:
      "S-1SNPN｜Possessive pronoun, first, singular, nominative, plural, neuter",
    GN: "G1699",
    gloss: "mine",
  },
  ἐμά: {
    parse:
      "S-1SNPN｜Possessive pronoun, first, singular, nominative, plural, neuter",
    GN: "G1699",
    gloss: "mine",
  },
  ἐμὰς: {
    parse:
      "S-1SAPF｜Possessive pronoun, first, singular, accusative, plural, feminine",
    GN: "G1699",
    gloss: "mine",
  },
  ἐμῇ: {
    parse:
      "S-1SDSF｜Possessive pronoun, first, singular, dative, singular, feminine",
    GN: "G1699",
    gloss: "mine",
  },
  ἐμή: {
    parse:
      "S-1SNSF｜Possessive pronoun, first, singular, nominative, singular, feminine",
    GN: "G1699",
    gloss: "mine",
  },
  ἐμὴ: {
    parse:
      "S-1SNSF｜Possessive pronoun, first, singular, nominative, singular, feminine",
    GN: "G1699",
    gloss: "mine",
  },
  ἐμήν: {
    parse:
      "S-1SASF｜Possessive pronoun, first, singular, accusative, singular, feminine",
    GN: "G1699",
    gloss: "mine",
  },
  ἐμὴν: {
    parse:
      "S-1SASF｜Possessive pronoun, first, singular, accusative, singular, feminine",
    GN: "G1699",
    gloss: "mine",
  },
  ἐμῆς: {
    parse:
      "S-1SGSF｜Possessive pronoun, first, singular, genitive, singular, feminine",
    GN: "G1699",
    gloss: "mine",
  },
  ἐμοῖς: {
    parse:
      "S-1SDPN｜Possessive pronoun, first, singular, dative, plural, neuter",
    GN: "G1699",
    gloss: "mine",
  },
  ἐμόν: {
    parse:
      "S-1SASM｜Possessive pronoun, first, singular, accusative, singular, masculine",
    GN: "G1699",
    gloss: "mine",
  },
  ἐμὸν: {
    parse:
      "S-1SNSN｜Possessive pronoun, first, singular, nominative, singular, neuter",
    GN: "G1699",
    gloss: "mine",
  },
  Ἐμὸν: {
    parse:
      "S-1SNSN｜Possessive pronoun, first, singular, nominative, singular, neuter",
    GN: "G1699",
    gloss: "mine",
  },
  ἐμὸς: {
    parse:
      "S-1SNSM｜Possessive pronoun, first, singular, nominative, singular, masculine",
    GN: "G1699",
    gloss: "mine",
  },
  ἐμοὺς: {
    parse:
      "S-1SAPM｜Possessive pronoun, first, singular, accusative, plural, masculine",
    GN: "G1699",
    gloss: "mine",
  },
  ἐμῷ: {
    parse:
      "S-1SDSN｜Possessive pronoun, first, singular, dative, singular, neuter",
    GN: "G1699",
    gloss: "mine",
  },
  ἐμῶν: {
    parse:
      "S-1SGPN｜Possessive pronoun, first, singular, genitive, plural, neuter",
    GN: "G1699",
    gloss: "mine",
  },
  ἐμπαιγμῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G1701",
    gloss: "jeering",
  },
  ἐμπαίζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1702",
    gloss: "to mock",
  },
  ἐμπαίζοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1702",
    gloss: "to mock",
  },
  ἐμπαῖξαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1702",
    gloss: "to mock",
  },
  ἐμπαίξας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G1702",
    gloss: "to mock",
  },
  ἐμπαίξουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G1702",
    gloss: "to mock",
  },
  ἐμπαιχθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G1702",
    gloss: "to mock",
  },
  ἐνέπαιζον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1702",
    gloss: "to mock",
  },
  ἐνέπαιξαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1702",
    gloss: "to mock",
  },
  ἐνεπαίχθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G1702",
    gloss: "to mock",
  },
  ἐμπαῖκται: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G1703",
    gloss: "a mocker",
  },
  ἐμπεριπατήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G1704",
    gloss: "to walk in/among",
  },
  ἐμπεπλησμένοι: {
    parse:
      "V-RPP-VPM｜Verb, perfect, passive, Participle, Vocative or nominative, plural, masculine",
    GN: "G1705",
    gloss: "to fill up",
  },
  ἐμπιπλῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1705",
    gloss: "to fill up",
  },
  ἐμπλησθῶ: {
    parse: "V-APS-1S｜Verb, aorist, passive, subjunctive, first, singular",
    GN: "G1705",
    gloss: "to fill up",
  },
  ἐνέπλησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1705",
    gloss: "to fill up",
  },
  ἐνεπλήσθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G1705",
    gloss: "to fill up",
  },
  ἐμπεσεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G1706",
    gloss: "to fall into",
  },
  ἐμπέσῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G1706",
    gloss: "to fall into",
  },
  ἐμπεσόντος: {
    parse:
      "V-2AAP-GSM｜Verb, 2nd aorist, active, Participle, genitive, singular, masculine",
    GN: "G1706",
    gloss: "to fall into",
  },
  ἐμπεσοῦνται: {
    parse:
      "V-FNI-3P｜Verb, future, Middle or Passive deponent, indicative, third, plural",
    GN: "G1706",
    gloss: "to fall into",
  },
  ἐμπίπτουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1706",
    gloss: "to fall into",
  },
  ἐμπλακέντες: {
    parse:
      "V-2APP-NPM｜Verb, 2nd aorist, passive, Participle, nominative, plural, masculine",
    GN: "G1707",
    gloss: "to entangle/involve",
  },
  ἐμπλέκεται: {
    parse: "V-PMI-3S｜Verb, present, middle, indicative, third, singular",
    GN: "G1707",
    gloss: "to entangle/involve",
  },
  ἐμπλοκῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1708",
    gloss: "braiding",
  },
  ἐμπνέων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1709",
    gloss: "to breath into",
  },
  ἐμπορευσόμεθα: {
    parse: "V-FDI-1P｜Verb, future, Middle deponent, indicative, first, plural",
    GN: "G1710",
    gloss: "to traffic in",
  },
  ἐμπορεύσονται: {
    parse: "V-FDI-3P｜Verb, future, Middle deponent, indicative, third, plural",
    GN: "G1710",
    gloss: "to traffic in",
  },
  ἐμπορίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1711",
    gloss: "trade/business",
  },
  ἐμπορίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G1712",
    gloss: "marketplace/trade",
  },
  ἔμποροι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G1713",
    gloss: "trader/merchant",
  },
  ἔμποροί: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G1713",
    gloss: "trader/merchant",
  },
  ἐμπόρῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G1713",
    gloss: "trader/merchant",
  },
  ἐνέπρησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1714",
    gloss: "to burn",
  },
  ἔμπροσθεν: { parse: "PREP｜Preposition", GN: "G1715", gloss: "before" },
  ἔμπροσθέν: { parse: "PREP｜Preposition", GN: "G1715", gloss: "before" },
  ἐμπτύειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1716",
    gloss: "to spit on/at",
  },
  ἐμπτύσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G1716",
    gloss: "to spit on/at",
  },
  ἐμπτυσθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G1716",
    gloss: "to spit on/at",
  },
  ἐμπτύσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G1716",
    gloss: "to spit on/at",
  },
  ἐνέπτυον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1716",
    gloss: "to spit on/at",
  },
  ἐνέπτυσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1716",
    gloss: "to spit on/at",
  },
  ἐμφανῆ: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G1717",
    gloss: "revealed",
  },
  ἐμφανὴς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1717",
    gloss: "revealed",
  },
  ἐμφανίζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1718",
    gloss: "to show",
  },
  ἐμφανίζουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1718",
    gloss: "to show",
  },
  ἐμφανίσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G1718",
    gloss: "to show",
  },
  ἐμφανισθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G1718",
    gloss: "to show",
  },
  ἐμφανίσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G1718",
    gloss: "to show",
  },
  ἐνεφάνισαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1718",
    gloss: "to show",
  },
  ἐνεφάνισάν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1718",
    gloss: "to show",
  },
  ἐνεφάνισας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G1718",
    gloss: "to show",
  },
  ἐνεφανίσθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G1718",
    gloss: "to show",
  },
  ἔμφοβοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G1719",
    gloss: "afraid",
  },
  ἔμφοβος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1719",
    gloss: "afraid",
  },
  ἐμφόβων: {
    parse: "A-GPF｜Adjective, genitive, plural, feminine",
    GN: "G1719",
    gloss: "afraid",
  },
  ἐνεφύσησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1720",
    gloss: "to breathe into",
  },
  ἔμφυτον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G1721",
    gloss: "implanted/ingrafted",
  },
  ἐν: { parse: "PREP｜Preposition", GN: "G1722", gloss: "in/on/among" },
  Ἐν: { parse: "PREP｜Preposition", GN: "G1722", gloss: "in/on/among" },
  ἔν: { parse: "PREP｜Preposition", GN: "G1722", gloss: "in/on/among" },
  ἐναγκαλισάμενος: {
    parse:
      "V-ADP-NSM｜Verb, aorist, Middle deponent, Participle, nominative, singular, masculine",
    GN: "G1723",
    gloss: "to embrace",
  },
  ἐναλίων: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G1724",
    gloss: "marine",
  },
  ἔναντι: { parse: "PREP｜Preposition", GN: "G1725", gloss: "before" },
  ἐναντίον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G1727",
    gloss: "against",
  },
  ἐναντία: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G1727",
    gloss: "against",
  },
  ἐναντίας: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G1727",
    gloss: "against",
  },
  ἐναντίος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1727",
    gloss: "against",
  },
  ἐναντίους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G1727",
    gloss: "against",
  },
  ἐναντίων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G1727",
    gloss: "against",
  },
  ἐναρξάμενοι: {
    parse:
      "V-ADP-NPM｜Verb, aorist, Middle deponent, Participle, nominative, plural, masculine",
    GN: "G1728",
    gloss: "to begin",
  },
  ἐναρξάμενος: {
    parse:
      "V-ADP-NSM｜Verb, aorist, Middle deponent, Participle, nominative, singular, masculine",
    GN: "G1728",
    gloss: "to begin",
  },
  ἐνδεής: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1729",
    gloss: "impoverished",
  },
  ἔνδειγμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G1730",
    gloss: "evidence",
  },
  ἐνδεικνύμενοι: {
    parse:
      "V-PMP-NPM｜Verb, present, middle, Participle, nominative, plural, masculine",
    GN: "G1731",
    gloss: "to show",
  },
  ἐνδεικνυμένους: {
    parse:
      "V-PMP-APM｜Verb, present, middle, Participle, accusative, plural, masculine",
    GN: "G1731",
    gloss: "to show",
  },
  ἐνδείκνυνται: {
    parse: "V-PMI-3P｜Verb, present, middle, indicative, third, plural",
    GN: "G1731",
    gloss: "to show",
  },
  ἐνδείκνυσθαι: {
    parse: "V-PMN｜Verb, present, middle, Infinitive",
    GN: "G1731",
    gloss: "to show",
  },
  ἐνδείξασθαι: {
    parse: "V-AMN｜Verb, aorist, middle, Infinitive",
    GN: "G1731",
    gloss: "to show",
  },
  ἐνδείξηται: {
    parse: "V-AMS-3S｜Verb, aorist, middle, subjunctive, third, singular",
    GN: "G1731",
    gloss: "to show",
  },
  ἐνδείξωμαι: {
    parse: "V-AMS-1S｜Verb, aorist, middle, subjunctive, first, singular",
    GN: "G1731",
    gloss: "to show",
  },
  ἐνεδείξασθε: {
    parse: "V-AMI-2P｜Verb, aorist, middle, indicative, second, plural",
    GN: "G1731",
    gloss: "to show",
  },
  ἐνεδείξατο: {
    parse: "V-AMI-3S｜Verb, aorist, middle, indicative, third, singular",
    GN: "G1731",
    gloss: "to show",
  },
  ἔνδειξιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1732",
    gloss: "demonstration",
  },
  ἔνδειξις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1732",
    gloss: "demonstration",
  },
  ἕνδεκα: {
    parse:
      "A-NPM-NUI｜Adjective, nominative, plural, masculine, NUmerical Indiclinable (A)",
    GN: "G1733",
    gloss: "eleven",
  },
  ἑνδεκάτην: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G1734",
    gloss: "eleventh",
  },
  ἑνδέκατος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1734",
    gloss: "eleventh",
  },
  ἐνδέχεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G1735",
    gloss: "be possible",
  },
  ἐνδημῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1736",
    gloss: "be home",
  },
  ἐνδημοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1736",
    gloss: "be home",
  },
  ἐνδιδύσκουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1737",
    gloss: "to dress",
  },
  ἐνεδιδύσκετο: {
    parse: "V-IMI-3S｜Verb, imperfect, middle, indicative, third, singular",
    GN: "G1737",
    gloss: "to dress",
  },
  ἔνδικον: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G1738",
    gloss: "just",
  },
  ἔνδικόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G1738",
    gloss: "just",
  },
  ἐνδώμησις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1739",
    gloss: "material",
  },
  ἐνδοξασθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G1740",
    gloss: "be glorified",
  },
  ἐνδοξασθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G1740",
    gloss: "be glorified",
  },
  ἔνδοξοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G1741",
    gloss: "honored",
  },
  ἐνδόξοις: {
    parse: "A-DPN｜Adjective, dative, plural, neuter",
    GN: "G1741",
    gloss: "honored",
  },
  ἔνδοξον: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G1741",
    gloss: "honored",
  },
  ἐνδόξῳ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G1741",
    gloss: "honored",
  },
  ἔνδυμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G1742",
    gloss: "clothing",
  },
  ἐνδύμασιν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G1742",
    gloss: "clothing",
  },
  ἐνδύμασι: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G1742",
    gloss: "clothing",
  },
  ἐνδύματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G1742",
    gloss: "clothing",
  },
  ἐνδυναμοῦ: {
    parse: "V-PPM-2S｜Verb, present, passive, imperative, second, singular",
    GN: "G1743",
    gloss: "to strengthen",
  },
  ἐνδυναμοῦντί: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G1743",
    gloss: "to strengthen",
  },
  ἐνδυναμοῦσθε: {
    parse: "V-PPM-2P｜Verb, present, passive, imperative, second, plural",
    GN: "G1743",
    gloss: "to strengthen",
  },
  ἐνδυναμώσαντί: {
    parse:
      "V-AAP-DSM｜Verb, aorist, active, Participle, dative, singular, masculine",
    GN: "G1743",
    gloss: "to strengthen",
  },
  ἐνεδυναμοῦτο: {
    parse: "V-IPI-3S｜Verb, imperfect, passive, indicative, third, singular",
    GN: "G1743",
    gloss: "to strengthen",
  },
  ἐνεδυναμώθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G1743",
    gloss: "to strengthen",
  },
  ἐνεδυνάμωσέν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1743",
    gloss: "to strengthen",
  },
  ἐνδύνοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1744",
    gloss: "to sneak",
  },
  ἐνδύσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1745",
    gloss: "wearing",
  },
  ἐνδεδυμένοι: {
    parse:
      "V-RMP-NPM｜Verb, perfect, middle, Participle, nominative, plural, masculine",
    GN: "G1746",
    gloss: "to put on",
  },
  ἐνδεδυμένον: {
    parse:
      "V-RMP-ASM｜Verb, perfect, middle, Participle, accusative, singular, masculine",
    GN: "G1746",
    gloss: "to put on",
  },
  ἐνδεδυμένος: {
    parse:
      "V-RMP-NSM｜Verb, perfect, middle, Participle, nominative, singular, masculine",
    GN: "G1746",
    gloss: "to put on",
  },
  ἐνδυσάμενοι: {
    parse:
      "V-AMP-NPM｜Verb, aorist, middle, Participle, nominative, plural, masculine",
    GN: "G1746",
    gloss: "to put on",
  },
  ἐνδυσάμενος: {
    parse:
      "V-AMP-NSM｜Verb, aorist, middle, Participle, nominative, singular, masculine",
    GN: "G1746",
    gloss: "to put on",
  },
  ἐνδύσασθαι: {
    parse: "V-AMN｜Verb, aorist, middle, Infinitive",
    GN: "G1746",
    gloss: "to put on",
  },
  ἐνδύσασθε: {
    parse: "V-AMM-2P｜Verb, aorist, middle, imperative, second, plural",
    GN: "G1746",
    gloss: "to put on",
  },
  Ἐνδύσασθε: {
    parse: "V-AMM-2P｜Verb, aorist, middle, imperative, second, plural",
    GN: "G1746",
    gloss: "to put on",
  },
  ἐνδύσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G1746",
    gloss: "to put on",
  },
  ἐνδύσησθε: {
    parse: "V-AMS-2P｜Verb, aorist, middle, subjunctive, second, plural",
    GN: "G1746",
    gloss: "to put on",
  },
  ἐνδύσηται: {
    parse: "V-AMS-3S｜Verb, aorist, middle, subjunctive, third, singular",
    GN: "G1746",
    gloss: "to put on",
  },
  ἐνδυσώμεθα: {
    parse: "V-AMS-1P｜Verb, aorist, middle, subjunctive, first, plural",
    GN: "G1746",
    gloss: "to put on",
  },
  ἐνέδυσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1746",
    gloss: "to put on",
  },
  ἐνεδύσασθε: {
    parse: "V-AMI-2P｜Verb, aorist, middle, indicative, second, plural",
    GN: "G1746",
    gloss: "to put on",
  },
  ἐνεδύσατο: {
    parse: "V-AMI-3S｜Verb, aorist, middle, indicative, third, singular",
    GN: "G1746",
    gloss: "to put on",
  },
  ἐνέδραν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1747",
    gloss: "plot/ambush",
  },
  ἐνεδρεύοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1748",
    gloss: "to ambush",
  },
  ἐνεδρεύουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1748",
    gloss: "to ambush",
  },
  ἐνείλησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1750",
    gloss: "to enwrap",
  },
  ἐνόντα: {
    parse:
      "V-PAP-APN｜Verb, present, active, Participle, accusative, plural, neuter",
    GN: "G1751",
    gloss: "be in",
  },
  εἵνεκεν: { parse: "PREP｜Preposition", GN: "G1752", gloss: "because of" },
  ἕνεκα: { parse: "PREP｜Preposition", GN: "G1752", gloss: "because of" },
  Ἕνεκα: { parse: "PREP｜Preposition", GN: "G1752", gloss: "because of" },
  ἕνεκεν: { parse: "PREP｜Preposition", GN: "G1752", gloss: "because of" },
  Ἕνεκεν: { parse: "PREP｜Preposition", GN: "G1752", gloss: "because of" },
  ἐνέργειαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1753",
    gloss: "active energy",
  },
  ἐνεργείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1753",
    gloss: "active energy",
  },
  ἐνεργεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1754",
    gloss: "be active",
  },
  ἐνεργεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1754",
    gloss: "be active",
  },
  ἐνεργεῖται: {
    parse: "V-PMI-3S｜Verb, present, middle, indicative, third, singular",
    GN: "G1754",
    gloss: "be active",
  },
  ἐνεργήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G1754",
    gloss: "be active",
  },
  ἐνεργουμένη: {
    parse:
      "V-PMP-NSF｜Verb, present, middle, Participle, nominative, singular, feminine",
    GN: "G1754",
    gloss: "be active",
  },
  ἐνεργουμένην: {
    parse:
      "V-PMP-ASF｜Verb, present, middle, Participle, accusative, singular, feminine",
    GN: "G1754",
    gloss: "be active",
  },
  ἐνεργουμένης: {
    parse:
      "V-PMP-GSF｜Verb, present, middle, Participle, genitive, singular, feminine",
    GN: "G1754",
    gloss: "be active",
  },
  ἐνεργοῦντος: {
    parse:
      "V-PAP-GSN｜Verb, present, active, Participle, genitive, singular, neuter",
    GN: "G1754",
    gloss: "be active",
  },
  ἐνεργοῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1754",
    gloss: "be active",
  },
  ἐνεργῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1754",
    gloss: "be active",
  },
  ἐνηργεῖτο: {
    parse: "V-IMI-3S｜Verb, imperfect, middle, indicative, third, singular",
    GN: "G1754",
    gloss: "be active",
  },
  ἐνήργησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1754",
    gloss: "be active",
  },
  ἐνεργήματα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G1755",
    gloss: "working",
  },
  ἐνεργημάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G1755",
    gloss: "working",
  },
  ἐνεργής: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G1756",
    gloss: "effective",
  },
  ἐνεργὴς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1756",
    gloss: "effective",
  },
  ἐνευλογηθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G1757",
    gloss: "be blessed",
  },
  Ἐνευλογηθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G1757",
    gloss: "be blessed",
  },
  ἐνεῖχεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1758",
    gloss: "to oppose",
  },
  ἐνέχειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1758",
    gloss: "to oppose",
  },
  ἐνέχεσθε: {
    parse: "V-PPM-2P｜Verb, present, passive, imperative, second, plural",
    GN: "G1758",
    gloss: "to oppose",
  },
  ἐνθάδε: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1759",
    gloss: "in/to this place",
  },
  ἐνθυμεῖσθε: {
    parse:
      "V-PNI-2P｜Verb, present, Middle or Passive deponent, indicative, second, plural",
    GN: "G1760",
    gloss: "to reflect on",
  },
  ἐνθυμηθέντος: {
    parse:
      "V-AOP-GSM｜Verb, aorist, Passive deponent, Participle, genitive, singular, masculine",
    GN: "G1760",
    gloss: "to reflect on",
  },
  ἐνθυμήσεις: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G1761",
    gloss: "reflection",
  },
  ἐνθυμήσεων: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G1761",
    gloss: "reflection",
  },
  ἐνθυμήσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1761",
    gloss: "reflection",
  },
  ἔνι: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1762",
    gloss: "there is",
  },
  ἐνιαυτόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1763",
    gloss: "year",
  },
  ἐνιαυτὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1763",
    gloss: "year",
  },
  ἐνιαυτοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G1763",
    gloss: "year",
  },
  ἐνιαυτούς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G1763",
    gloss: "year",
  },
  ἐνιαυτοὺς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G1763",
    gloss: "year",
  },
  ἐνέστηκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G1764",
    gloss: "be present",
  },
  ἐνεστηκότα: {
    parse:
      "V-RAP-ASM｜Verb, perfect, active, Participle, accusative, singular, masculine",
    GN: "G1764",
    gloss: "be present",
  },
  ἐνεστῶσαν: {
    parse:
      "V-RAP-ASF｜Verb, perfect, active, Participle, accusative, singular, feminine",
    GN: "G1764",
    gloss: "be present",
  },
  ἐνεστῶτα: {
    parse:
      "V-RAP-NPN｜Verb, perfect, active, Participle, nominative, plural, neuter",
    GN: "G1764",
    gloss: "be present",
  },
  ἐνεστῶτος: {
    parse:
      "V-RAP-GSM｜Verb, perfect, active, Participle, genitive, singular, masculine",
    GN: "G1764",
    gloss: "be present",
  },
  ἐνστήσονται: {
    parse: "V-FDI-3P｜Verb, future, Middle deponent, indicative, third, plural",
    GN: "G1764",
    gloss: "be present",
  },
  ἐνίσχυσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1765",
    gloss: "to strengthen",
  },
  ἐνισχύων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1765",
    gloss: "to strengthen",
  },
  ἐνάτῃ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G1766",
    gloss: "ninth (hour)",
  },
  ἐνάτην: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G1766",
    gloss: "ninth (hour)",
  },
  ἐνάτης: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G1766",
    gloss: "ninth (hour)",
  },
  ἔνατος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1766",
    gloss: "ninth (hour)",
  },
  ἐννέα: {
    parse:
      "A-NPM-NUI｜Adjective, nominative, plural, masculine, NUmerical Indiclinable (A)",
    GN: "G1767",
    gloss: "nine",
  },
  ἐνενήκοντα: {
    parse:
      "A-DPN-NUI｜Adjective, dative, plural, neuter, NUmerical Indiclinable (A)",
    GN: "G1768",
    gloss: "ninety",
  },
  ἐνεοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G1769",
    gloss: "speechless",
  },
  ἐνένευον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1770",
    gloss: "to signify",
  },
  ἔννοιαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1771",
    gloss: "thought/purpose",
  },
  ἐννοιῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G1771",
    gloss: "thought/purpose",
  },
  ἔννομος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1772",
    gloss: "under law",
  },
  ἐννόμῳ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G1772",
    gloss: "under law",
  },
  ἔννυχα: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1773",
    gloss: "at night",
  },
  ἐνοικείτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G1774",
    gloss: "to dwell in/with",
  },
  Ἐνοικήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G1774",
    gloss: "to dwell in/with",
  },
  ἐνοικοῦντος: {
    parse:
      "V-PAP-GSN｜Verb, present, active, Participle, genitive, singular, neuter",
    GN: "G1774",
    gloss: "to dwell in/with",
  },
  ἐνῴκησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1774",
    gloss: "to dwell in/with",
  },
  ἑνότητα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1775",
    gloss: "unity",
  },
  ἐνοχλῇ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G1776",
    gloss: "to trouble",
  },
  ἐνοχλούμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G1776",
    gloss: "to trouble",
  },
  ἔνοχοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G1777",
    gloss: "liable for",
  },
  ἔνοχον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G1777",
    gloss: "liable for",
  },
  ἔνοχος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1777",
    gloss: "liable for",
  },
  Ἔνοχος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1777",
    gloss: "liable for",
  },
  ἔνοχός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1777",
    gloss: "liable for",
  },
  ἐντάλματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G1778",
    gloss: "precept",
  },
  ἐνταφιάζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1779",
    gloss: "to prepare burial",
  },
  ἐνταφιάσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1779",
    gloss: "to prepare burial",
  },
  ἐνταφιασμόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1780",
    gloss: "burial",
  },
  ἐνταφιασμοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G1780",
    gloss: "burial",
  },
  ἐνετειλάμην: {
    parse:
      "V-ADI-1S｜Verb, aorist, Middle deponent, indicative, first, singular",
    GN: "G1781",
    gloss: "to order",
  },
  ἐνετείλατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G1781",
    gloss: "to order",
  },
  ἐντειλάμενος: {
    parse:
      "V-ANP-NSM｜Verb, aorist, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G1781",
    gloss: "to order",
  },
  ἐντελεῖται: {
    parse:
      "V-FNI-3S｜Verb, future, Middle or Passive deponent, indicative, third, singular",
    GN: "G1781",
    gloss: "to order",
  },
  ἐντέλλομαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G1781",
    gloss: "to order",
  },
  ἐντέταλται: {
    parse:
      "V-RNI-3S｜Verb, perfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G1781",
    gloss: "to order",
  },
  ἐντεῦθεν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1782",
    gloss: "from here",
  },
  ἐντεύξεις: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G1783",
    gloss: "intercession",
  },
  ἐντεύξεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1783",
    gloss: "intercession",
  },
  ἔντιμον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G1784",
    gloss: "valued/honored",
  },
  ἔντιμος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1784",
    gloss: "valued/honored",
  },
  ἐντιμότερός: {
    parse: "A-NSM-C｜Adjective, nominative, singular, masculine, Comparative",
    GN: "G1784",
    gloss: "valued/honored",
  },
  ἐντίμους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G1784",
    gloss: "valued/honored",
  },
  ἐντολαὶ: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G1785",
    gloss: "commandment",
  },
  ἐντολαῖς: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G1785",
    gloss: "commandment",
  },
  ἐντολάς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G1785",
    gloss: "commandment",
  },
  ἐντολὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G1785",
    gloss: "commandment",
  },
  ἐντολή: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1785",
    gloss: "commandment",
  },
  ἐντολὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1785",
    gloss: "commandment",
  },
  ἐντολήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1785",
    gloss: "commandment",
  },
  ἐντολὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1785",
    gloss: "commandment",
  },
  ἐντολῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1785",
    gloss: "commandment",
  },
  ἐντολῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G1785",
    gloss: "commandment",
  },
  ἐντόπιοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G1786",
    gloss: "resident",
  },
  ἐντὸς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1787",
    gloss: "inside",
  },
  ἐνετρεπόμεθα: {
    parse: "V-IPI-1P｜Verb, imperfect, passive, indicative, first, plural",
    GN: "G1788",
    gloss: "to cause shame",
  },
  ἐντραπῇ: {
    parse: "V-2APS-3S｜Verb, 2nd aorist, passive, subjunctive, third, singular",
    GN: "G1788",
    gloss: "to cause shame",
  },
  ἐντραπήσονται: {
    parse: "V-2FPI-3P｜Verb, 2nd future, passive, indicative, third, plural",
    GN: "G1788",
    gloss: "to cause shame",
  },
  Ἐντραπήσονται: {
    parse: "V-2FPI-3P｜Verb, 2nd future, passive, indicative, third, plural",
    GN: "G1788",
    gloss: "to cause shame",
  },
  ἐντρέπομαι: {
    parse: "V-PPI-1S｜Verb, present, passive, indicative, first, singular",
    GN: "G1788",
    gloss: "to cause shame",
  },
  ἐντρεπόμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G1788",
    gloss: "to cause shame",
  },
  ἐντρέπων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1788",
    gloss: "to cause shame",
  },
  ἐντρεφόμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G1789",
    gloss: "be reared",
  },
  ἔντρομος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1790",
    gloss: "trembling",
  },
  ἐντροπὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1791",
    gloss: "shame",
  },
  ἐντρυφῶντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1792",
    gloss: "to revel",
  },
  ἐνέτυχόν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G1793",
    gloss: "to call on",
  },
  ἐντυγχάνει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1793",
    gloss: "to call on",
  },
  ἐντυγχάνειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1793",
    gloss: "to call on",
  },
  ἐνετύλιξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1794",
    gloss: "to wrap up",
  },
  ἐντετυλιγμένον: {
    parse:
      "V-RPP-ASN｜Verb, perfect, passive, Participle, accusative, singular, neuter",
    GN: "G1794",
    gloss: "to wrap up",
  },
  ἐντετυπωμένη: {
    parse:
      "V-RPP-NSF｜Verb, perfect, passive, Participle, nominative, singular, feminine",
    GN: "G1795",
    gloss: "to engrave",
  },
  ἐνυβρίσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G1796",
    gloss: "to insult",
  },
  ἐνυπνιαζόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G1797",
    gloss: "to dream",
  },
  ἐνυπνιασθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G1797",
    gloss: "to dream",
  },
  ἐνυπνίοις: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G1798",
    gloss: "dream",
  },
  ἐνώπιον: { parse: "PREP｜Preposition", GN: "G1799", gloss: "before" },
  ἐνώπιόν: { parse: "PREP｜Preposition", GN: "G1799", gloss: "before" },
  Ἐνὼς: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G1800",
    gloss: "Enos",
  },
  ἐνωτίσασθε: {
    parse:
      "V-ADM-2P｜Verb, aorist, Middle deponent, imperative, second, plural",
    GN: "G1801",
    gloss: "to listen to",
  },
  Ἑνὼχ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G1802",
    gloss: "Enoch",
  },
  ἕξ: {
    parse:
      "A-NPM-NUI｜Adjective, nominative, plural, masculine, NUmerical Indiclinable (A)",
    GN: "G1803",
    gloss: "six",
  },
  ἓξ: {
    parse:
      "A-NPM-NUI｜Adjective, nominative, plural, masculine, NUmerical Indiclinable (A)",
    GN: "G1803",
    gloss: "six",
  },
  Ἓξ: {
    parse:
      "A-NPF-NUI｜Adjective, nominative, plural, feminine, NUmerical Indiclinable (A)",
    GN: "G1803",
    gloss: "six",
  },
  ἐξαγγείλητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G1804",
    gloss: "to proclaim",
  },
  ἐξήγγειλαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1804",
    gloss: "to proclaim",
  },
  ἐξαγοραζόμενοι: {
    parse:
      "V-PMP-NPM｜Verb, present, middle, Participle, nominative, plural, masculine",
    GN: "G1805",
    gloss: "to redeem",
  },
  ἐξαγοράσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G1805",
    gloss: "to redeem",
  },
  ἐξηγόρασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1805",
    gloss: "to redeem",
  },
  ἐξαγαγεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G1806",
    gloss: "to lead out",
  },
  ἐξαγαγέτωσαν: {
    parse: "V-2AAM-3P｜Verb, 2nd aorist, active, imperative, third, plural",
    GN: "G1806",
    gloss: "to lead out",
  },
  ἐξαγαγόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G1806",
    gloss: "to lead out",
  },
  ἐξαγαγών: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G1806",
    gloss: "to lead out",
  },
  ἐξαγαγὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G1806",
    gloss: "to lead out",
  },
  ἐξάγει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1806",
    gloss: "to lead out",
  },
  ἐξάγουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1806",
    gloss: "to lead out",
  },
  ἐξήγαγεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G1806",
    gloss: "to lead out",
  },
  Ἐξήγαγεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G1806",
    gloss: "to lead out",
  },
  ἐξαιρούμενός: {
    parse:
      "V-PMP-NSM｜Verb, present, middle, Participle, nominative, singular, masculine",
    GN: "G1807",
    gloss: "to take out/select",
  },
  ἐξειλάμην: {
    parse: "V-2AMI-1S｜Verb, 2nd aorist, middle, indicative, first, singular",
    GN: "G1807",
    gloss: "to take out/select",
  },
  ἐξείλατο: {
    parse: "V-2AMI-3S｜Verb, 2nd aorist, middle, indicative, third, singular",
    GN: "G1807",
    gloss: "to take out/select",
  },
  ἐξείλατό: {
    parse: "V-2AMI-3S｜Verb, 2nd aorist, middle, indicative, third, singular",
    GN: "G1807",
    gloss: "to take out/select",
  },
  ἔξελε: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G1807",
    gloss: "to take out/select",
  },
  ἐξελέσθαι: {
    parse: "V-2AMN｜Verb, 2nd aorist, middle, Infinitive",
    GN: "G1807",
    gloss: "to take out/select",
  },
  ἐξέληται: {
    parse: "V-2AMS-3S｜Verb, 2nd aorist, middle, subjunctive, third, singular",
    GN: "G1807",
    gloss: "to take out/select",
  },
  ἐξάρατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G1808",
    gloss: "to expel",
  },
  ἐξῃτήσατο: {
    parse: "V-AMI-3S｜Verb, aorist, middle, indicative, third, singular",
    GN: "G1809",
    gloss: "to demand/ask for",
  },
  ἐξαίφνης: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1810",
    gloss: "suddenly",
  },
  ἐξακολουθήσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G1811",
    gloss: "to follow",
  },
  ἐξακολουθήσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G1811",
    gloss: "to follow",
  },
  ἑξακόσιοι: {
    parse:
      "A-NPM-NUI｜Adjective, nominative, plural, masculine, NUmerical Indiclinable (A)",
    GN: "G1812",
    gloss: "six hundred",
  },
  ἑξακοσίων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G1812",
    gloss: "six hundred",
  },
  ἐξαλειφθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G1813",
    gloss: "to blot out",
  },
  ἐξαλείψας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G1813",
    gloss: "to blot out",
  },
  ἐξαλείψει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G1813",
    gloss: "to blot out",
  },
  ἐξαλείψω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G1813",
    gloss: "to blot out",
  },
  ἐξαλλόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G1814",
    gloss: "to leap up",
  },
  ἐξανάστασιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1815",
    gloss: "resurrection",
  },
  ἐξανέτειλεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1816",
    gloss: "to sprout up",
  },
  ἐξαναστήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G1817",
    gloss: "to raise up",
  },
  ἐξανέστησαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G1817",
    gloss: "to raise up",
  },
  ἐξαπατάτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G1818",
    gloss: "to deceive",
  },
  ἐξαπατηθεῖσα: {
    parse:
      "V-APP-NSF｜Verb, aorist, passive, Participle, nominative, singular, feminine",
    GN: "G1818",
    gloss: "to deceive",
  },
  ἐξαπατήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G1818",
    gloss: "to deceive",
  },
  ἐξαπατῶσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1818",
    gloss: "to deceive",
  },
  ἐξηπάτησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1818",
    gloss: "to deceive",
  },
  ἐξηπάτησέν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1818",
    gloss: "to deceive",
  },
  ἐξάπινα: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1819",
    gloss: "suddenly",
  },
  ἐξαπορηθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G1820",
    gloss: "to despair",
  },
  ἐξαπορούμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G1820",
    gloss: "to despair",
  },
  ἐξαπεστάλη: {
    parse: "V-2API-3S｜Verb, 2nd aorist, passive, indicative, third, singular",
    GN: "G1821",
    gloss: "to send out/away",
  },
  ἐξαπέστειλαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G1821",
    gloss: "to send out/away",
  },
  ἐξαπέστειλεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G1821",
    gloss: "to send out/away",
  },
  ἐξαποστελῶ: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G1821",
    gloss: "to send out/away",
  },
  ἐξαρτίσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1822",
    gloss: "to finish/furnish",
  },
  ἐξηρτισμένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G1822",
    gloss: "to finish/furnish",
  },
  ἐξαστράπτων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1823",
    gloss: "to flash forth",
  },
  ἐξαυτῆς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1824",
    gloss: "immediately",
  },
  ἐξεγερεῖ: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G1825",
    gloss: "to raise",
  },
  ἐξήγειρά: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G1825",
    gloss: "to raise",
  },
  ἐξῄεσαν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1826",
    gloss: "to go out/away",
  },
  ἐξιέναι: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1826",
    gloss: "to go out/away",
  },
  Ἐξιόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G1826",
    gloss: "to go out/away",
  },
  ἐξελκόμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G1828",
    gloss: "to drag out/away",
  },
  ἐξέραμα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G1829",
    gloss: "vomit",
  },
  ἐξηραύνησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1830",
    gloss: "to search out",
  },
  ἐξελεύσεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξελεύσονται: {
    parse: "V-FDI-3P｜Verb, future, Middle deponent, indicative, third, plural",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξεληλύθασιν: {
    parse: "V-2RAI-3P｜Verb, 2nd perfect, active, indicative, third, plural",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξεληλύθει: {
    parse:
      "V-2LAI-3S｜Verb, 2nd pluperfect, active, indicative, third, singular",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξελήλυθεν: {
    parse: "V-2RAI-3S｜Verb, 2nd perfect, active, indicative, third, singular",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξεληλυθός: {
    parse:
      "V-2RAP-ASN｜Verb, 2nd perfect, active, Participle, accusative, singular, neuter",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξεληλυθότας: {
    parse:
      "V-2RAP-APM｜Verb, 2nd perfect, active, Participle, accusative, plural, masculine",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξεληλυθυῖαν: {
    parse:
      "V-2RAP-ASF｜Verb, 2nd perfect, active, Participle, accusative, singular, feminine",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξέλθατε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G1831",
    gloss: "to go out",
  },
  Ἐξέλθατε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G1831",
    gloss: "to go out",
  },
  ἔξελθε: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G1831",
    gloss: "to go out",
  },
  Ἔξελθε: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξελθεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξέλθῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξέλθῃς: {
    parse: "V-2AAS-2S｜Verb, 2nd aorist, active, subjunctive, second, singular",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξέλθητε: {
    parse: "V-2AAS-2P｜Verb, 2nd aorist, active, subjunctive, second, plural",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξελθόντα: {
    parse:
      "V-2AAP-NPN｜Verb, 2nd aorist, active, Participle, nominative, plural, neuter",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξελθόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξελθόντι: {
    parse:
      "V-2AAP-DSM｜Verb, 2nd aorist, active, Participle, dative, singular, masculine",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξελθόντος: {
    parse:
      "V-2AAP-GSN｜Verb, 2nd aorist, active, Participle, genitive, singular, neuter",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξελθόντων: {
    parse:
      "V-2AAP-GPM｜Verb, 2nd aorist, active, Participle, genitive, plural, masculine",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξελθοῦσα: {
    parse:
      "V-2AAP-NSF｜Verb, 2nd aorist, active, Participle, nominative, singular, feminine",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξελθοῦσαι: {
    parse:
      "V-2AAP-NPF｜Verb, 2nd aorist, active, Participle, nominative, plural, feminine",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξελθοῦσαν: {
    parse:
      "V-2AAP-ASF｜Verb, 2nd aorist, active, Participle, accusative, singular, feminine",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξελθούσῃ: {
    parse:
      "V-2AAP-DSF｜Verb, 2nd aorist, active, Participle, dative, singular, feminine",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξελθὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξέρχεσθε: {
    parse:
      "V-PNM-2P｜Verb, present, Middle or Passive deponent, imperative, second, plural",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξέρχεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξερχόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G1831",
    gloss: "to go out",
  },
  Ἐξερχόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξερχόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξερχομένων: {
    parse:
      "V-PNP-GPM｜Verb, present, Middle or Passive deponent, Participle, genitive, plural, masculine",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξέρχονται: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξερχώμεθα: {
    parse:
      "V-PNS-1P｜Verb, present, Middle or Passive deponent, subjunctive, first, plural",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξῆλθαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξήλθατε: {
    parse: "V-2AAI-2P｜Verb, 2nd aorist, active, indicative, second, plural",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξῆλθεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G1831",
    gloss: "to go out",
  },
  Ἐξῆλθεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξῆλθες: {
    parse: "V-2AAI-2S｜Verb, 2nd aorist, active, indicative, second, singular",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξήλθομεν: {
    parse: "V-2AAI-1P｜Verb, 2nd aorist, active, indicative, first, plural",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξῆλθον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξήρχετο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G1831",
    gloss: "to go out",
  },
  ἐξήρχοντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G1831",
    gloss: "to go out",
  },
  ἔξεστιν: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1832",
    gloss: "be permitted",
  },
  Ἔξεστιν: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1832",
    gloss: "be permitted",
  },
  ἔξεστίν: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1832",
    gloss: "be permitted",
  },
  ἐξὸν: {
    parse:
      "V-PAP-NSN｜Verb, present, active, Participle, nominative, singular, neuter",
    GN: "G1832",
    gloss: "be permitted",
  },
  ἐξετάσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1833",
    gloss: "to find out",
  },
  ἐξετάσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G1833",
    gloss: "to find out",
  },
  ἐξηγεῖτο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G1834",
    gloss: "to tell",
  },
  ἐξηγησάμενος: {
    parse:
      "V-ADP-NSM｜Verb, aorist, Middle deponent, Participle, nominative, singular, masculine",
    GN: "G1834",
    gloss: "to tell",
  },
  ἐξηγήσατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G1834",
    gloss: "to tell",
  },
  ἐξηγουμένων: {
    parse:
      "V-PNP-GPM｜Verb, present, Middle or Passive deponent, Participle, genitive, plural, masculine",
    GN: "G1834",
    gloss: "to tell",
  },
  ἐξηγοῦντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G1834",
    gloss: "to tell",
  },
  ἑξήκοντα: {
    parse:
      "A-NPM-NUI｜Adjective, nominative, plural, masculine, NUmerical Indiclinable (A)",
    GN: "G1835",
    gloss: "sixty",
  },
  ἑξῆς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1836",
    gloss: "next/afterward",
  },
  ἐξήχηται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G1837",
    gloss: "to ring/sound out",
  },
  ἕξιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1838",
    gloss: "habit/practice",
  },
  ἐξεστακέναι: {
    parse: "V-RAN｜Verb, perfect, active, Infinitive",
    GN: "G1839",
    gloss: "to amaze",
  },
  ἐξέστη: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G1839",
    gloss: "to amaze",
  },
  ἐξέστημεν: {
    parse: "V-2AAI-1P｜Verb, 2nd aorist, active, indicative, first, plural",
    GN: "G1839",
    gloss: "to amaze",
  },
  ἐξέστησαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G1839",
    gloss: "to amaze",
  },
  ἐξίσταντο: {
    parse: "V-IMI-3P｜Verb, imperfect, middle, indicative, third, plural",
    GN: "G1839",
    gloss: "to amaze",
  },
  ἐξιστάνων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1839",
    gloss: "to amaze",
  },
  ἐξίστασθαι: {
    parse: "V-PMN｜Verb, present, middle, Infinitive",
    GN: "G1839",
    gloss: "to amaze",
  },
  ἐξίστατο: {
    parse: "V-IMI-3S｜Verb, imperfect, middle, indicative, third, singular",
    GN: "G1839",
    gloss: "to amaze",
  },
  ἐξισχύσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G1840",
    gloss: "to have power",
  },
  ἔξοδον: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1841",
    gloss: "departure",
  },
  ἐξόδου: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1841",
    gloss: "departure",
  },
  ἐξολεθρευθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G1842",
    gloss: "to root out",
  },
  ἐξομολογεῖσθε: {
    parse: "V-PMM-2P｜Verb, present, middle, imperative, second, plural",
    GN: "G1843",
    gloss: "to agree",
  },
  ἐξομολογήσεται: {
    parse: "V-FMI-3S｜Verb, future, middle, indicative, third, singular",
    GN: "G1843",
    gloss: "to agree",
  },
  ἐξομολογήσηται: {
    parse: "V-AMS-3S｜Verb, aorist, middle, subjunctive, third, singular",
    GN: "G1843",
    gloss: "to agree",
  },
  ἐξομολογήσομαί: {
    parse: "V-FMI-1S｜Verb, future, middle, indicative, first, singular",
    GN: "G1843",
    gloss: "to agree",
  },
  Ἐξομολογοῦμαί: {
    parse: "V-PMI-1S｜Verb, present, middle, indicative, first, singular",
    GN: "G1843",
    gloss: "to agree",
  },
  ἐξομολογούμενοι: {
    parse:
      "V-PMP-NPM｜Verb, present, middle, Participle, nominative, plural, masculine",
    GN: "G1843",
    gloss: "to agree",
  },
  ἐξωμολόγησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1843",
    gloss: "to agree",
  },
  Ἐξορκίζω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G1844",
    gloss: "to adjure",
  },
  ἐξορκιστῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G1845",
    gloss: "exorcist",
  },
  ἐξορύξαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G1846",
    gloss: "to dig through/out",
  },
  ἐξουδενηθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G1847",
    gloss: "be rejected",
  },
  ἐξουθενεῖς: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G1848",
    gloss: "to reject",
  },
  ἐξουθενεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G1848",
    gloss: "to reject",
  },
  ἐξουθενείτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G1848",
    gloss: "to reject",
  },
  ἐξουθενηθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G1848",
    gloss: "to reject",
  },
  ἐξουθενημένα: {
    parse:
      "V-RPP-APN｜Verb, perfect, passive, Participle, accusative, plural, neuter",
    GN: "G1848",
    gloss: "to reject",
  },
  ἐξουθενημένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G1848",
    gloss: "to reject",
  },
  ἐξουθενημένους: {
    parse:
      "V-RPP-APM｜Verb, perfect, passive, Participle, accusative, plural, masculine",
    GN: "G1848",
    gloss: "to reject",
  },
  ἐξουθενήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G1848",
    gloss: "to reject",
  },
  ἐξουθενήσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G1848",
    gloss: "to reject",
  },
  ἐξουθενήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G1848",
    gloss: "to reject",
  },
  ἐξουθενοῦντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G1848",
    gloss: "to reject",
  },
  ἐξουσίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1849",
    gloss: "authority",
  },
  ἐξουσία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1849",
    gloss: "authority",
  },
  ἐξουσίαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G1849",
    gloss: "authority",
  },
  ἐξουσίαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G1849",
    gloss: "authority",
  },
  ἐξουσίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1849",
    gloss: "authority",
  },
  ἐξουσίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1849",
    gloss: "authority",
  },
  ἐξουσιῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G1849",
    gloss: "authority",
  },
  ἐξουσιάζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1850",
    gloss: "to have authority",
  },
  ἐξουσιάζοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1850",
    gloss: "to have authority",
  },
  ἐξουσιασθήσομαι: {
    parse: "V-FPI-1S｜Verb, future, passive, indicative, first, singular",
    GN: "G1850",
    gloss: "to have authority",
  },
  ἐξοχὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1851",
    gloss: "prominent",
  },
  ἐξυπνίσω: {
    parse: "V-AAS-1S｜Verb, aorist, active, subjunctive, first, singular",
    GN: "G1852",
    gloss: "to awake",
  },
  ἔξυπνος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1853",
    gloss: "awake",
  },
  ἔξω: { parse: "PREP｜Preposition", GN: "G1854", gloss: "out/outside(r)" },
  ἔξωθεν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1855",
    gloss: "outside",
  },
  ἐξῶσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1856",
    gloss: "to expel",
  },
  ἐξῶσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1856",
    gloss: "to expel",
  },
  ἐξώτερον: {
    parse: "A-ASN-C｜Adjective, accusative, singular, neuter, Comparative",
    GN: "G1857",
    gloss: "outer",
  },
  ἑορτάζωμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G1858",
    gloss: "to keep a festival",
  },
  ἑορτῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1859",
    gloss: "festival",
  },
  ἑορτὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1859",
    gloss: "festival",
  },
  ἑορτήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1859",
    gloss: "festival",
  },
  ἑορτὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1859",
    gloss: "festival",
  },
  ἑορτῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1859",
    gloss: "festival",
  },
  ἐπαγγελίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1860",
    gloss: "promise",
  },
  ἐπαγγελία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1860",
    gloss: "promise",
  },
  ἐπαγγελίαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G1860",
    gloss: "promise",
  },
  ἐπαγγελίαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G1860",
    gloss: "promise",
  },
  ἐπαγγελίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1860",
    gloss: "promise",
  },
  ἐπαγγελίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1860",
    gloss: "promise",
  },
  ἐπαγγελιῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G1860",
    gloss: "promise",
  },
  ἐπαγγειλάμενον: {
    parse:
      "V-ADP-ASM｜Verb, aorist, Middle deponent, Participle, accusative, singular, masculine",
    GN: "G1861",
    gloss: "to profess",
  },
  ἐπαγγειλάμενος: {
    parse:
      "V-ADP-NSM｜Verb, aorist, Middle deponent, Participle, nominative, singular, masculine",
    GN: "G1861",
    gloss: "to profess",
  },
  ἐπαγγελλομέναις: {
    parse:
      "V-PNP-DPF｜Verb, present, Middle or Passive deponent, Participle, dative, plural, feminine",
    GN: "G1861",
    gloss: "to profess",
  },
  ἐπαγγελλόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G1861",
    gloss: "to profess",
  },
  ἐπηγγείλαντο: {
    parse:
      "V-ANI-3P｜Verb, aorist, Middle or Passive deponent, indicative, third, plural",
    GN: "G1861",
    gloss: "to profess",
  },
  ἐπηγγείλατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G1861",
    gloss: "to profess",
  },
  ἐπήγγελται: {
    parse:
      "V-RNI-3S｜Verb, perfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G1861",
    gloss: "to profess",
  },
  ἐπάγγελμα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G1862",
    gloss: "a promise",
  },
  ἐπαγγέλματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G1862",
    gloss: "a promise",
  },
  ἐπαγαγεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G1863",
    gloss: "to bring upon",
  },
  ἐπάγοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1863",
    gloss: "to bring upon",
  },
  ἐπάξας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G1863",
    gloss: "to bring upon",
  },
  ἐπαγωνίζεσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G1864",
    gloss: "to contend",
  },
  ἐπαθροιζομένων: {
    parse:
      "V-PPP-GPM｜Verb, present, passive, Participle, genitive, plural, masculine",
    GN: "G1865",
    gloss: "to collect/crowd",
  },
  Ἐπαίνετον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G1866",
    gloss: "Epaenetus",
  },
  ἐπαινεσάτωσαν: {
    parse: "V-AAM-3P｜Verb, aorist, active, imperative, third, plural",
    GN: "G1867",
    gloss: "to praise",
  },
  ἐπαινέσω: {
    parse: "V-AAS-1S｜Verb, aorist, active, subjunctive, first, singular",
    GN: "G1867",
    gloss: "to praise",
  },
  ἐπαινῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G1867",
    gloss: "to praise",
  },
  Ἐπαινῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G1867",
    gloss: "to praise",
  },
  ἐπῄνεσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1867",
    gloss: "to praise",
  },
  ἔπαινον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1868",
    gloss: "praise",
  },
  ἔπαινος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G1868",
    gloss: "praise",
  },
  ἐπαίρεται: {
    parse: "V-PMI-3S｜Verb, present, middle, indicative, third, singular",
    GN: "G1869",
    gloss: "to lift up",
  },
  ἐπαιρόμενον: {
    parse:
      "V-PMP-ASN｜Verb, present, middle, Participle, accusative, singular, neuter",
    GN: "G1869",
    gloss: "to lift up",
  },
  ἐπαίροντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G1869",
    gloss: "to lift up",
  },
  ἐπᾶραι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1869",
    gloss: "to lift up",
  },
  ἐπάραντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G1869",
    gloss: "to lift up",
  },
  ἐπάρας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G1869",
    gloss: "to lift up",
  },
  ἐπάρασά: {
    parse:
      "V-AAP-NSF｜Verb, aorist, active, Participle, nominative, singular, feminine",
    GN: "G1869",
    gloss: "to lift up",
  },
  ἐπάρατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G1869",
    gloss: "to lift up",
  },
  ἐπῆραν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1869",
    gloss: "to lift up",
  },
  ἐπῆρεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1869",
    gloss: "to lift up",
  },
  ἐπήρθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G1869",
    gloss: "to lift up",
  },
  ἐπαισχύνεσθε: {
    parse:
      "V-PNI-2P｜Verb, present, Middle or Passive deponent, indicative, second, plural",
    GN: "G1870",
    gloss: "be ashamed of",
  },
  ἐπαισχύνεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G1870",
    gloss: "be ashamed of",
  },
  ἐπαισχύνθη: {
    parse:
      "V-AOI-3S｜Verb, aorist, Passive deponent, indicative, third, singular",
    GN: "G1870",
    gloss: "be ashamed of",
  },
  ἐπαισχυνθῇ: {
    parse:
      "V-AOS-3S｜Verb, aorist, Passive deponent, subjunctive, third, singular",
    GN: "G1870",
    gloss: "be ashamed of",
  },
  ἐπαισχυνθῇς: {
    parse:
      "V-AOS-2S｜Verb, aorist, Passive deponent, subjunctive, second, singular",
    GN: "G1870",
    gloss: "be ashamed of",
  },
  ἐπαισχυνθήσεται: {
    parse:
      "V-FOI-3S｜Verb, future, Passive deponent, indicative, third, singular",
    GN: "G1870",
    gloss: "be ashamed of",
  },
  ἐπαισχύνομαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G1870",
    gloss: "be ashamed of",
  },
  ἐπαιτεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1871",
    gloss: "to ask/beg",
  },
  ἐπαιτῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1871",
    gloss: "to ask/beg",
  },
  ἐπακολουθήσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G1872",
    gloss: "to follow after",
  },
  ἐπακολουθούντων: {
    parse:
      "V-PAP-GPN｜Verb, present, active, Participle, genitive, plural, neuter",
    GN: "G1872",
    gloss: "to follow after",
  },
  ἐπακολουθοῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1872",
    gloss: "to follow after",
  },
  ἐπηκολούθησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1872",
    gloss: "to follow after",
  },
  ἐπήκουσά: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G1873",
    gloss: "to listen to",
  },
  ἐπηκροῶντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G1874",
    gloss: "to listen ro",
  },
  ἐπὰν: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G1875",
    gloss: "when/as soon as",
  },
  ἐπάναγκες: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1876",
    gloss: "necessarily",
  },
  Ἐπανάγαγε: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G1877",
    gloss: "to set off",
  },
  ἐπαναγαγεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G1877",
    gloss: "to set off",
  },
  ἐπανάγων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1877",
    gloss: "to set off",
  },
  ἐπαναμιμνῄσκων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1878",
    gloss: "to remind",
  },
  ἐπαναπαήσεται: {
    parse: "V-2FPI-3S｜Verb, 2nd future, passive, indicative, third, singular",
    GN: "G1879",
    gloss: "to rest/rely on",
  },
  ἐπαναπαύῃ: {
    parse:
      "V-PNI-2S｜Verb, present, Middle or Passive deponent, indicative, second, singular",
    GN: "G1879",
    gloss: "to rest/rely on",
  },
  ἐπανελθεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G1880",
    gloss: "to return",
  },
  ἐπανέρχεσθαί: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G1880",
    gloss: "to return",
  },
  ἐπαναστήσονται: {
    parse: "V-FDI-3P｜Verb, future, Middle deponent, indicative, third, plural",
    GN: "G1881",
    gloss: "to rebel against",
  },
  ἐπανόρθωσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1882",
    gloss: "correcting",
  },
  ἐπάνω: { parse: "PREP｜Preposition", GN: "G1883", gloss: "above" },
  ἐπαρκείτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G1884",
    gloss: "to help",
  },
  ἐπαρκέσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G1884",
    gloss: "to help",
  },
  ἐπήρκεσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1884",
    gloss: "to help",
  },
  ἐπαρχείᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1885",
    gloss: "province",
  },
  ἐπαρχείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1885",
    gloss: "province",
  },
  ἔπαυλις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1886",
    gloss: "residence",
  },
  ἐπαύριον: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1887",
    gloss: "the next day",
  },
  αὐτοφώρῳ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G1888",
    gloss: "in the very act",
  },
  Ἐπαφρᾶ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G1889",
    gloss: "Epaphras",
  },
  Ἐπαφρᾶς: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G1889",
    gloss: "Epaphras",
  },
  ἐπαφρίζοντα: {
    parse:
      "V-PAP-NPN｜Verb, present, active, Participle, nominative, plural, neuter",
    GN: "G1890",
    gloss: "to foam up",
  },
  Ἐπαφρόδιτον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G1891",
    gloss: "Epaphroditus",
  },
  Ἐπαφροδίτου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G1891",
    gloss: "Epaphroditus",
  },
  ἐπήγειραν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1892",
    gloss: "to awaken/rouse",
  },
  ἐπεὶ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G1893",
    gloss: "since",
  },
  Ἐπεὶ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G1893",
    gloss: "since",
  },
  ἐπειδὴ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G1894",
    gloss: "since",
  },
  Ἐπειδὴ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G1894",
    gloss: "since",
  },
  Ἐπειδήπερ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G1895",
    gloss: "since",
  },
  ἐπεῖδεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G1896",
    gloss: "to look upon",
  },
  ἔπιδε: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G1896",
    gloss: "to look upon",
  },
  ἐπεισαγωγὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1898",
    gloss: "introduction",
  },
  ἔπειτα: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1899",
    gloss: "then",
  },
  Ἔπειτα: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1899",
    gloss: "then",
  },
  ἐπέκεινα: { parse: "PREP｜Preposition", GN: "G1900", gloss: "beyond" },
  ἐπεκτεινόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G1901",
    gloss: "to reach out to",
  },
  ἐπενδύσασθαι: {
    parse: "V-AMN｜Verb, aorist, middle, Infinitive",
    GN: "G1902",
    gloss: "be clothed",
  },
  ἐπενδύτην: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1903",
    gloss: "coat",
  },
  ἐπελεύσεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G1904",
    gloss: "to arrive/invade",
  },
  ἐπέλθῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G1904",
    gloss: "to arrive/invade",
  },
  ἐπελθόντος: {
    parse:
      "V-2AAP-GSN｜Verb, 2nd aorist, active, Participle, genitive, singular, neuter",
    GN: "G1904",
    gloss: "to arrive/invade",
  },
  ἐπελθὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G1904",
    gloss: "to arrive/invade",
  },
  ἐπερχομέναις: {
    parse:
      "V-PNP-DPF｜Verb, present, Middle or Passive deponent, Participle, dative, plural, feminine",
    GN: "G1904",
    gloss: "to arrive/invade",
  },
  ἐπερχομένοις: {
    parse:
      "V-PNP-DPM｜Verb, present, Middle or Passive deponent, Participle, dative, plural, masculine",
    GN: "G1904",
    gloss: "to arrive/invade",
  },
  ἐπερχομένων: {
    parse:
      "V-PNP-GPM｜Verb, present, Middle or Passive deponent, Participle, genitive, plural, masculine",
    GN: "G1904",
    gloss: "to arrive/invade",
  },
  Ἐπῆλθαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G1904",
    gloss: "to arrive/invade",
  },
  ἐπερωτᾶν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1905",
    gloss: "to question",
  },
  ἐπερωτάτωσαν: {
    parse: "V-PAM-3P｜Verb, present, active, imperative, third, plural",
    GN: "G1905",
    gloss: "to question",
  },
  Ἐπερωτηθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G1905",
    gloss: "to question",
  },
  ἐπερωτῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1905",
    gloss: "to question",
  },
  ἐπερωτήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G1905",
    gloss: "to question",
  },
  ἐπερωτήσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G1905",
    gloss: "to question",
  },
  Ἐπερωτήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G1905",
    gloss: "to question",
  },
  Ἐπερωτῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G1905",
    gloss: "to question",
  },
  ἐπερωτῶντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G1905",
    gloss: "to question",
  },
  ἐπερωτῶσιν: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G1905",
    gloss: "to question",
  },
  ἐπηρώτα: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1905",
    gloss: "to question",
  },
  ἐπηρώτησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1905",
    gloss: "to question",
  },
  Ἐπηρώτησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1905",
    gloss: "to question",
  },
  ἐπηρώτησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1905",
    gloss: "to question",
  },
  ἐπηρώτησέν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1905",
    gloss: "to question",
  },
  ἐπηρώτων: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1905",
    gloss: "to question",
  },
  Ἐπηρώτων: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1905",
    gloss: "to question",
  },
  ἐπερώτημα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G1906",
    gloss: "pledge",
  },
  ἐπεῖχεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1907",
    gloss: "to hold fast/out",
  },
  ἐπέσχεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G1907",
    gloss: "to hold fast/out",
  },
  ἔπεχε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G1907",
    gloss: "to hold fast/out",
  },
  ἐπέχοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1907",
    gloss: "to hold fast/out",
  },
  ἐπέχων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1907",
    gloss: "to hold fast/out",
  },
  ἐπηρεάζοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1908",
    gloss: "to mistreat",
  },
  ἐπηρεαζόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G1908",
    gloss: "to mistreat",
  },
  ἐπʼ: { parse: "PREP｜Preposition", GN: "G1909", gloss: "upon/to/against" },
  Ἐπʼ: { parse: "PREP｜Preposition", GN: "G1909", gloss: "upon/to/against" },
  ἐπί: { parse: "PREP｜Preposition", GN: "G1909", gloss: "upon/to/against" },
  ἐπὶ: { parse: "PREP｜Preposition", GN: "G1909", gloss: "upon/to/against" },
  Ἐπὶ: { parse: "PREP｜Preposition", GN: "G1909", gloss: "upon/to/against" },
  ἐφʼ: { parse: "PREP｜Preposition", GN: "G1909", gloss: "upon/to/against" },
  Ἐφʼ: { parse: "PREP｜Preposition", GN: "G1909", gloss: "upon/to/against" },
  ἐπέβην: {
    parse: "V-2AAI-1S｜Verb, 2nd aorist, active, indicative, first, singular",
    GN: "G1910",
    gloss: "to mount/board",
  },
  ἐπιβαίνειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1910",
    gloss: "to mount/board",
  },
  ἐπιβάντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G1910",
    gloss: "to mount/board",
  },
  ἐπιβὰς: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G1910",
    gloss: "to mount/board",
  },
  ἐπιβεβηκὼς: {
    parse:
      "V-RAP-NSM｜Verb, perfect, active, Participle, nominative, singular, masculine",
    GN: "G1910",
    gloss: "to mount/board",
  },
  ἐπέβαλαν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G1911",
    gloss: "to put on/seize",
  },
  ἐπέβαλεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G1911",
    gloss: "to put on/seize",
  },
  ἐπέβαλλεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1911",
    gloss: "to put on/seize",
  },
  ἐπέβαλον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G1911",
    gloss: "to put on/seize",
  },
  ἐπιβαλεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G1911",
    gloss: "to put on/seize",
  },
  ἐπιβάλλει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1911",
    gloss: "to put on/seize",
  },
  ἐπιβάλλον: {
    parse:
      "V-PAP-ASN｜Verb, present, active, Participle, accusative, singular, neuter",
    GN: "G1911",
    gloss: "to put on/seize",
  },
  ἐπιβάλλουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1911",
    gloss: "to put on/seize",
  },
  ἐπιβαλοῦσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G1911",
    gloss: "to put on/seize",
  },
  ἐπιβάλω: {
    parse: "V-2AAS-1S｜Verb, 2nd aorist, active, subjunctive, first, singular",
    GN: "G1911",
    gloss: "to put on/seize",
  },
  ἐπιβαλὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G1911",
    gloss: "to put on/seize",
  },
  ἐπιβαρῆσαί: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1912",
    gloss: "to burden",
  },
  ἐπιβαρῶ: {
    parse: "V-PAS-1S｜Verb, present, active, subjunctive, first, singular",
    GN: "G1912",
    gloss: "to burden",
  },
  ἐπεβίβασαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1913",
    gloss: "to mount",
  },
  ἐπιβιβάσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G1913",
    gloss: "to mount",
  },
  ἐπιβιβάσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G1913",
    gloss: "to mount",
  },
  ἐπέβλεψεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1914",
    gloss: "to look upon/at",
  },
  ἐπιβλέψαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1914",
    gloss: "to look upon/at",
  },
  ἐπιβλέψητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G1914",
    gloss: "to look upon/at",
  },
  ἐπίβλημα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G1915",
    gloss: "patch",
  },
  ἐπιβουλαῖς: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G1917",
    gloss: "plot",
  },
  ἐπιβουλὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1917",
    gloss: "plot",
  },
  ἐπιβουλῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1917",
    gloss: "plot",
  },
  ἐπιγαμβρεύσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G1918",
    gloss: "to marry",
  },
  ἐπίγεια: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G1919",
    gloss: "earthly",
  },
  ἐπίγειος: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G1919",
    gloss: "earthly",
  },
  ἐπιγείων: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G1919",
    gloss: "earthly",
  },
  ἐπιγενομένου: {
    parse:
      "V-2ADP-GSM｜Verb, 2nd aorist, Middle deponent, Participle, genitive, singular, masculine",
    GN: "G1920",
    gloss: "to arise/spring up",
  },
  ἐπεγίνωσκον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1921",
    gloss: "to come to know",
  },
  ἐπεγίνωσκόν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1921",
    gloss: "to come to know",
  },
  ἐπεγνωκέναι: {
    parse: "V-RAN｜Verb, perfect, active, Infinitive",
    GN: "G1921",
    gloss: "to come to know",
  },
  ἐπεγνωκόσι: {
    parse:
      "V-RAP-DPM｜Verb, perfect, active, Participle, dative, plural, masculine",
    GN: "G1921",
    gloss: "to come to know",
  },
  ἐπέγνωμεν: {
    parse: "V-2AAI-1P｜Verb, 2nd aorist, active, indicative, first, plural",
    GN: "G1921",
    gloss: "to come to know",
  },
  ἐπέγνωσαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G1921",
    gloss: "to come to know",
  },
  ἐπεγνώσθην: {
    parse: "V-2API-1S｜Verb, 2nd aorist, passive, indicative, first, singular",
    GN: "G1921",
    gloss: "to come to know",
  },
  ἐπέγνωτε: {
    parse: "V-2AAI-2P｜Verb, 2nd aorist, active, indicative, second, plural",
    GN: "G1921",
    gloss: "to come to know",
  },
  ἐπιγινώσκει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1921",
    gloss: "to come to know",
  },
  ἐπιγινώσκεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G1921",
    gloss: "to come to know",
  },
  ἐπιγινώσκετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G1921",
    gloss: "to come to know",
  },
  ἐπιγινωσκέτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G1921",
    gloss: "to come to know",
  },
  ἐπιγινωσκόμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G1921",
    gloss: "to come to know",
  },
  ἐπιγνόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G1921",
    gloss: "to come to know",
  },
  ἐπιγνοὺς: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G1921",
    gloss: "to come to know",
  },
  ἐπιγνοῦσα: {
    parse:
      "V-2AAP-NSF｜Verb, 2nd aorist, active, Participle, nominative, singular, feminine",
    GN: "G1921",
    gloss: "to come to know",
  },
  ἐπιγνοῦσιν: {
    parse:
      "V-2AAP-DPM｜Verb, 2nd aorist, active, Participle, dative, plural, masculine",
    GN: "G1921",
    gloss: "to come to know",
  },
  ἐπιγνῷ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G1921",
    gloss: "to come to know",
  },
  ἐπιγνῶναι: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G1921",
    gloss: "to come to know",
  },
  ἐπιγνῷς: {
    parse: "V-2AAS-2S｜Verb, 2nd aorist, active, subjunctive, second, singular",
    GN: "G1921",
    gloss: "to come to know",
  },
  ἐπιγνώσεσθε: {
    parse:
      "V-FDI-2P｜Verb, future, Middle deponent, indicative, second, plural",
    GN: "G1921",
    gloss: "to come to know",
  },
  ἐπιγνώσομαι: {
    parse:
      "V-FDI-1S｜Verb, future, Middle deponent, indicative, first, singular",
    GN: "G1921",
    gloss: "to come to know",
  },
  ἐπιγνώσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1922",
    gloss: "knowledge",
  },
  ἐπιγνώσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1922",
    gloss: "knowledge",
  },
  ἐπίγνωσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1922",
    gloss: "knowledge",
  },
  ἐπίγνωσις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1922",
    gloss: "knowledge",
  },
  ἐπιγραφή: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1923",
    gloss: "inscription",
  },
  ἐπιγραφὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1923",
    gloss: "inscription",
  },
  ἐπιγραφήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1923",
    gloss: "inscription",
  },
  ἐπεγέγραπτο: {
    parse: "V-LPI-3S｜Verb, pluperfect, passive, indicative, third, singular",
    GN: "G1924",
    gloss: "to write on",
  },
  ἐπιγεγραμμένα: {
    parse:
      "V-RPP-APN｜Verb, perfect, passive, Participle, accusative, plural, neuter",
    GN: "G1924",
    gloss: "to write on",
  },
  ἐπιγεγραμμένη: {
    parse:
      "V-RPP-NSF｜Verb, perfect, passive, Participle, nominative, singular, feminine",
    GN: "G1924",
    gloss: "to write on",
  },
  ἐπιγράψω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G1924",
    gloss: "to write on",
  },
  ἐπιδεικνύμεναι: {
    parse:
      "V-PMP-NPF｜Verb, present, middle, Participle, nominative, plural, feminine",
    GN: "G1925",
    gloss: "to show/prove",
  },
  ἐπιδεικνὺς: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1925",
    gloss: "to show/prove",
  },
  ἐπιδεῖξαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1925",
    gloss: "to show/prove",
  },
  ἐπιδείξατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G1925",
    gloss: "to show/prove",
  },
  ἐπιδείξατέ: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G1925",
    gloss: "to show/prove",
  },
  ἐπιδέχεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G1926",
    gloss: "to welcome",
  },
  ἐπιδημοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1927",
    gloss: "to sojourn",
  },
  ἐπιδιατάσσεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G1928",
    gloss: "to add to",
  },
  ἐπεδίδου: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1929",
    gloss: "to give/deliver",
  },
  ἐπεδόθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G1929",
    gloss: "to give/deliver",
  },
  ἐπέδωκαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1929",
    gloss: "to give/deliver",
  },
  ἐπιδόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G1929",
    gloss: "to give/deliver",
  },
  ἐπιδώσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G1929",
    gloss: "to give/deliver",
  },
  ἐπιδιορθώσῃ: {
    parse: "V-AMS-2S｜Verb, aorist, middle, subjunctive, second, singular",
    GN: "G1930",
    gloss: "to straighten out",
  },
  ἐπιδυέτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G1931",
    gloss: "to set",
  },
  ἐπιεικείᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1932",
    gloss: "gentleness",
  },
  ἐπιεικείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1932",
    gloss: "gentleness",
  },
  ἐπιεικεῖς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G1933",
    gloss: "gentle",
  },
  ἐπιεικὲς: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G1933",
    gloss: "gentle",
  },
  ἐπιεικέσιν: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G1933",
    gloss: "gentle",
  },
  ἐπιεικῆ: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G1933",
    gloss: "gentle",
  },
  ἐπιεικής: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G1933",
    gloss: "gentle",
  },
  ἐπεζήτησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1934",
    gloss: "to seek after",
  },
  ἐπεζήτουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1934",
    gloss: "to seek after",
  },
  ἐπιζητεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1934",
    gloss: "to seek after",
  },
  ἐπιζητεῖτε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G1934",
    gloss: "to seek after",
  },
  ἐπιζητήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G1934",
    gloss: "to seek after",
  },
  ἐπιζητοῦμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G1934",
    gloss: "to seek after",
  },
  ἐπιζητοῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1934",
    gloss: "to seek after",
  },
  ἐπιζητῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G1934",
    gloss: "to seek after",
  },
  ἐπιθανατίους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G1935",
    gloss: "condemned to death",
  },
  ἐπιθέσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1936",
    gloss: "laying on",
  },
  ἐπιθέσεώς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1936",
    gloss: "laying on",
  },
  ἐπεθύμει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1937",
    gloss: "to long for",
  },
  ἐπεθύμησα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G1937",
    gloss: "to long for",
  },
  ἐπεθύμησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1937",
    gloss: "to long for",
  },
  ἐπιθυμεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1937",
    gloss: "to long for",
  },
  ἐπιθυμεῖτε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G1937",
    gloss: "to long for",
  },
  ἐπιθυμῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1937",
    gloss: "to long for",
  },
  ἐπιθυμήσεις: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G1937",
    gloss: "to long for",
  },
  ἐπιθυμήσετε: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G1937",
    gloss: "to long for",
  },
  ἐπιθυμήσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G1937",
    gloss: "to long for",
  },
  ἐπιθυμοῦμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G1937",
    gloss: "to long for",
  },
  ἐπιθυμοῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G1937",
    gloss: "to long for",
  },
  ἐπιθυμῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1937",
    gloss: "to long for",
  },
  ἐπιθυμητὰς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G1938",
    gloss: "one who desires",
  },
  ἐπιθυμίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1939",
    gloss: "desire",
  },
  Ἐπιθυμίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1939",
    gloss: "desire",
  },
  ἐπιθυμία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1939",
    gloss: "desire",
  },
  ἐπιθυμίαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G1939",
    gloss: "desire",
  },
  ἐπιθυμίαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G1939",
    gloss: "desire",
  },
  ἐπιθυμίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1939",
    gloss: "desire",
  },
  ἐπιθυμίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1939",
    gloss: "desire",
  },
  ἐπιθυμιῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G1939",
    gloss: "desire",
  },
  ἐπεκάθισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1940",
    gloss: "to sit on",
  },
  ἐπεκάλεσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1941",
    gloss: "to call (on)/name",
  },
  ἐπεκέκλητο: {
    parse:
      "V-LDI-3S｜Verb, pluperfect, Middle deponent, indicative, third, singular",
    GN: "G1941",
    gloss: "to call (on)/name",
  },
  ἐπεκλήθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G1941",
    gloss: "to call (on)/name",
  },
  ἐπικαλεῖσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G1941",
    gloss: "to call (on)/name",
  },
  ἐπικαλεῖσθε: {
    parse: "V-PMI-2P｜Verb, present, middle, indicative, second, plural",
    GN: "G1941",
    gloss: "to call (on)/name",
  },
  ἐπικαλεῖται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G1941",
    gloss: "to call (on)/name",
  },
  ἐπικαλεσάμενος: {
    parse:
      "V-AMP-NSM｜Verb, aorist, middle, Participle, nominative, singular, masculine",
    GN: "G1941",
    gloss: "to call (on)/name",
  },
  ἐπικαλεσαμένου: {
    parse:
      "V-AMP-GSM｜Verb, aorist, middle, Participle, genitive, singular, masculine",
    GN: "G1941",
    gloss: "to call (on)/name",
  },
  ἐπικαλέσασθαι: {
    parse: "V-AMN｜Verb, aorist, middle, Infinitive",
    GN: "G1941",
    gloss: "to call (on)/name",
  },
  ἐπικαλέσηται: {
    parse: "V-AMS-3S｜Verb, aorist, middle, subjunctive, third, singular",
    GN: "G1941",
    gloss: "to call (on)/name",
  },
  ἐπικαλέσωνται: {
    parse: "V-AMS-3P｜Verb, aorist, middle, subjunctive, third, plural",
    GN: "G1941",
    gloss: "to call (on)/name",
  },
  ἐπικαλοῦμαι: {
    parse: "V-PMI-1S｜Verb, present, middle, indicative, first, singular",
    GN: "G1941",
    gloss: "to call (on)/name",
  },
  ἐπικαλουμένοις: {
    parse:
      "V-PMP-DPM｜Verb, present, middle, Participle, dative, plural, masculine",
    GN: "G1941",
    gloss: "to call (on)/name",
  },
  ἐπικαλούμενον: {
    parse:
      "V-PEP-ASM｜Verb, present, Either middle or passive, Participle, accusative, singular, masculine",
    GN: "G1941",
    gloss: "to call (on)/name",
  },
  ἐπικαλούμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G1941",
    gloss: "to call (on)/name",
  },
  ἐπικαλουμένου: {
    parse:
      "V-PPP-GSM｜Verb, present, passive, Participle, genitive, singular, masculine",
    GN: "G1941",
    gloss: "to call (on)/name",
  },
  ἐπικαλουμένους: {
    parse:
      "V-PMP-APM｜Verb, present, middle, Participle, accusative, plural, masculine",
    GN: "G1941",
    gloss: "to call (on)/name",
  },
  ἐπικαλουμένων: {
    parse:
      "V-PMP-GPM｜Verb, present, middle, Participle, genitive, plural, masculine",
    GN: "G1941",
    gloss: "to call (on)/name",
  },
  ἐπικέκλησαι: {
    parse: "V-RMI-2S｜Verb, perfect, middle, indicative, second, singular",
    GN: "G1941",
    gloss: "to call (on)/name",
  },
  ἐπικέκληται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G1941",
    gloss: "to call (on)/name",
  },
  ἐπικληθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G1941",
    gloss: "to call (on)/name",
  },
  ἐπικληθὲν: {
    parse:
      "V-APP-ASN｜Verb, aorist, passive, Participle, accusative, singular, neuter",
    GN: "G1941",
    gloss: "to call (on)/name",
  },
  ἐπικληθέντα: {
    parse:
      "V-APP-ASM｜Verb, aorist, passive, Participle, accusative, singular, masculine",
    GN: "G1941",
    gloss: "to call (on)/name",
  },
  ἐπικάλυμμα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G1942",
    gloss: "covering",
  },
  ἐπεκαλύφθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G1943",
    gloss: "to cover",
  },
  Ἐπικατάρατος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1944",
    gloss: "cursed",
  },
  ἐπέκειντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G1945",
    gloss: "to lay on",
  },
  ἐπέκειτο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G1945",
    gloss: "to lay on",
  },
  ἐπικείμενα: {
    parse:
      "V-PNP-NPN｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, neuter",
    GN: "G1945",
    gloss: "to lay on",
  },
  ἐπικείμενον: {
    parse:
      "V-PNP-ASN｜Verb, present, Middle or Passive deponent, Participle, accusative, singular, neuter",
    GN: "G1945",
    gloss: "to lay on",
  },
  ἐπικειμένου: {
    parse:
      "V-PNP-GSM｜Verb, present, Middle or Passive deponent, Participle, genitive, singular, masculine",
    GN: "G1945",
    gloss: "to lay on",
  },
  ἐπικεῖσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G1945",
    gloss: "to lay on",
  },
  ἐπίκειται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G1945",
    gloss: "to lay on",
  },
  Ἐπικουρείων: {
    parse: "N-GPM-T｜Noun, genitive, plural, masculine, Title",
    GN: "G1946",
    gloss: "Epicurean",
  },
  ἐπικουρίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1947",
    gloss: "help",
  },
  ἐπέκρινεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1948",
    gloss: "to decide",
  },
  ἐπελάβετο: {
    parse:
      "V-2ADI-3S｜Verb, 2nd aorist, Middle deponent, indicative, third, singular",
    GN: "G1949",
    gloss: "to catch",
  },
  ἐπιλαβέσθαι: {
    parse: "V-2ADN｜Verb, 2nd aorist, Middle deponent, Infinitive",
    GN: "G1949",
    gloss: "to catch",
  },
  ἐπιλαβόμενοι: {
    parse:
      "V-2ADP-NPM｜Verb, 2nd aorist, Middle deponent, Participle, nominative, plural, masculine",
    GN: "G1949",
    gloss: "to catch",
  },
  ἐπιλαβόμενοί: {
    parse:
      "V-2ADP-NPM｜Verb, 2nd aorist, Middle deponent, Participle, nominative, plural, masculine",
    GN: "G1949",
    gloss: "to catch",
  },
  ἐπιλαβόμενος: {
    parse:
      "V-2ADP-NSM｜Verb, 2nd aorist, Middle deponent, Participle, nominative, singular, masculine",
    GN: "G1949",
    gloss: "to catch",
  },
  ἐπιλαβομένου: {
    parse:
      "V-2ADP-GSM｜Verb, 2nd aorist, Middle deponent, Participle, genitive, singular, masculine",
    GN: "G1949",
    gloss: "to catch",
  },
  ἐπιλαβοῦ: {
    parse:
      "V-2ADM-2S｜Verb, 2nd aorist, Middle deponent, imperative, second, singular",
    GN: "G1949",
    gloss: "to catch",
  },
  ἐπιλάβωνται: {
    parse:
      "V-2ADS-3P｜Verb, 2nd aorist, Middle deponent, subjunctive, third, plural",
    GN: "G1949",
    gloss: "to catch",
  },
  ἐπιλαμβάνεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G1949",
    gloss: "to catch",
  },
  ἐπελάθετο: {
    parse:
      "V-2ADI-3S｜Verb, 2nd aorist, Middle deponent, indicative, third, singular",
    GN: "G1950",
    gloss: "to forget",
  },
  ἐπελάθοντο: {
    parse:
      "V-2ADI-3P｜Verb, 2nd aorist, Middle deponent, indicative, third, plural",
    GN: "G1950",
    gloss: "to forget",
  },
  ἐπιλαθέσθαι: {
    parse: "V-2ADN｜Verb, 2nd aorist, Middle deponent, Infinitive",
    GN: "G1950",
    gloss: "to forget",
  },
  ἐπιλανθάνεσθε: {
    parse:
      "V-PNM-2P｜Verb, present, Middle or Passive deponent, imperative, second, plural",
    GN: "G1950",
    gloss: "to forget",
  },
  ἐπιλανθανόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G1950",
    gloss: "to forget",
  },
  ἐπιλελησμένον: {
    parse:
      "V-RPP-NSN｜Verb, perfect, passive, Participle, nominative, singular, neuter",
    GN: "G1950",
    gloss: "to forget",
  },
  ἐπιλεγομένη: {
    parse:
      "V-PPP-NSF｜Verb, present, passive, Participle, nominative, singular, feminine",
    GN: "G1951",
    gloss: "to call/choose",
  },
  ἐπιλεξάμενος: {
    parse:
      "V-AMP-NSM｜Verb, aorist, middle, Participle, nominative, singular, masculine",
    GN: "G1951",
    gloss: "to call/choose",
  },
  ἐπιλείψει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G1952",
    gloss: "be insufficient",
  },
  ἐπιλησμονῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1953",
    gloss: "forgetfulness",
  },
  ἐπίλοιπον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G1954",
    gloss: "remaining",
  },
  ἐπιλύσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1955",
    gloss: "explanation",
  },
  ἐπέλυεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1956",
    gloss: "to explain",
  },
  ἐπιλυθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G1956",
    gloss: "to explain",
  },
  ἐπιμαρτυρῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1957",
    gloss: "to testify to",
  },
  ἐπιμελείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1958",
    gloss: "care",
  },
  ἐπεμελήθη: {
    parse:
      "V-AOI-3S｜Verb, aorist, Passive deponent, indicative, third, singular",
    GN: "G1959",
    gloss: "to care",
  },
  Ἐπιμελήθητι: {
    parse: "V-APM-2S｜Verb, aorist, passive, imperative, second, singular",
    GN: "G1959",
    gloss: "to care",
  },
  ἐπιμελήσεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G1959",
    gloss: "to care",
  },
  ἐπιμελῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G1960",
    gloss: "carefully",
  },
  ἐπέμεινα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G1961",
    gloss: "to remain/keep on",
  },
  ἐπεμείναμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G1961",
    gloss: "to remain/keep on",
  },
  ἐπέμενεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1961",
    gloss: "to remain/keep on",
  },
  ἐπέμενον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G1961",
    gloss: "to remain/keep on",
  },
  ἐπιμεῖναι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1961",
    gloss: "to remain/keep on",
  },
  ἐπίμενε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G1961",
    gloss: "to remain/keep on",
  },
  ἐπιμένειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1961",
    gloss: "to remain/keep on",
  },
  ἐπιμένετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G1961",
    gloss: "to remain/keep on",
  },
  ἐπιμένῃς: {
    parse: "V-PAS-2S｜Verb, present, active, subjunctive, second, singular",
    GN: "G1961",
    gloss: "to remain/keep on",
  },
  ἐπιμενόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G1961",
    gloss: "to remain/keep on",
  },
  ἐπιμενῶ: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G1961",
    gloss: "to remain/keep on",
  },
  ἐπιμένωμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G1961",
    gloss: "to remain/keep on",
  },
  ἐπιμένωσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G1961",
    gloss: "to remain/keep on",
  },
  ἐπένευσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1962",
    gloss: "to accept",
  },
  ἐπίνοια: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1963",
    gloss: "thought",
  },
  ἐπιορκήσεις: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G1964",
    gloss: "to break an oath",
  },
  ἐπιόρκοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G1965",
    gloss: "perjurer",
  },
  ἐπιούσῃ: {
    parse:
      "V-PAP-DSF｜Verb, present, active, Participle, dative, singular, feminine",
    GN: "G1966",
    gloss: "to come later",
  },
  ἐπιούσιον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G1967",
    gloss: "daily",
  },
  ἐπέπεσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1968",
    gloss: "to fall/press upon",
  },
  ἐπέπεσε: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G1968",
    gloss: "to fall/press upon",
  },
  ἐπέπεσεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G1968",
    gloss: "to fall/press upon",
  },
  ἐπιπεπτωκός: {
    parse:
      "V-RAP-NSN｜Verb, perfect, active, Participle, nominative, singular, neuter",
    GN: "G1968",
    gloss: "to fall/press upon",
  },
  ἐπιπεσόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G1968",
    gloss: "to fall/press upon",
  },
  ἐπιπίπτειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1968",
    gloss: "to fall/press upon",
  },
  ἐπιπλήξῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G1969",
    gloss: "to rebuke",
  },
  ἐπιποθεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1971",
    gloss: "to long for",
  },
  ἐπιποθήσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G1971",
    gloss: "to long for",
  },
  ἐπιποθοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1971",
    gloss: "to long for",
  },
  ἐπιποθούντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G1971",
    gloss: "to long for",
  },
  ἐπιποθῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G1971",
    gloss: "to long for",
  },
  ἐπιποθῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1971",
    gloss: "to long for",
  },
  ἐπιπόθησιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1972",
    gloss: "longing",
  },
  ἐπιπόθητοι: {
    // or nominative
    parse: "A-VPM｜Adjective, Vocative, plural, masculine",
    GN: "G1973",
    gloss: "longed for",
  },
  ἐπιποθίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1974",
    gloss: "longing",
  },
  ἐπιπορευομένων: {
    parse:
      "V-PNP-GPM｜Verb, present, Middle or Passive deponent, Participle, genitive, plural, masculine",
    GN: "G1975",
    gloss: "to come/go to",
  },
  ἐπιράπτει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1976",
    gloss: "to sew on",
  },
  ἐπιρίψαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G1977",
    gloss: "to throw on",
  },
  ἐπίσημοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G1978",
    gloss: "notable",
  },
  ἐπίσημον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G1978",
    gloss: "notable",
  },
  ἐπισιτισμόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1979",
    gloss: "food",
  },
  ἐπεσκέψασθέ: {
    parse: "V-AMI-2P｜Verb, aorist, middle, indicative, second, plural",
    GN: "G1980",
    gloss: "to visit/care for",
  },
  ἐπεσκέψατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G1980",
    gloss: "to visit/care for",
  },
  Ἐπεσκέψατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G1980",
    gloss: "to visit/care for",
  },
  ἐπισκέπτεσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G1980",
    gloss: "to visit/care for",
  },
  ἐπισκέπτῃ: {
    parse:
      "V-PNI-2S｜Verb, present, Middle or Passive deponent, indicative, second, singular",
    GN: "G1980",
    gloss: "to visit/care for",
  },
  ἐπισκέψασθαι: {
    parse: "V-ADN｜Verb, aorist, Middle deponent, Infinitive",
    GN: "G1980",
    gloss: "to visit/care for",
  },
  ἐπισκέψασθε: {
    parse:
      "V-ADM-2P｜Verb, aorist, Middle deponent, imperative, second, plural",
    GN: "G1980",
    gloss: "to visit/care for",
  },
  ἐπισκέψεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G1980",
    gloss: "to visit/care for",
  },
  ἐπισκεψώμεθα: {
    parse:
      "V-ADS-1P｜Verb, aorist, Middle deponent, subjunctive, first, plural",
    GN: "G1980",
    gloss: "to visit/care for",
  },
  ἐπισκηνώσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G1981",
    gloss: "to rest upon/dwell",
  },
  ἐπεσκίαζεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G1982",
    gloss: "to overshadow",
  },
  ἐπεσκίασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G1982",
    gloss: "to overshadow",
  },
  ἐπισκιάζουσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G1982",
    gloss: "to overshadow",
  },
  ἐπισκιάσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G1982",
    gloss: "to overshadow",
  },
  ἐπισκιάσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G1982",
    gloss: "to overshadow",
  },
  ἐπισκοποῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1983",
    gloss: "to oversee/care for",
  },
  ἐπισκοπὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1984",
    gloss: "oversight",
  },
  ἐπισκοπῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1984",
    gloss: "oversight",
  },
  ἐπισκόποις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G1985",
    gloss: "overseer",
  },
  ἐπίσκοπον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G1985",
    gloss: "overseer",
  },
  ἐπισκόπους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G1985",
    gloss: "overseer",
  },
  ἐπισπάσθω: {
    parse:
      "V-PNM-3S｜Verb, present, Middle or Passive deponent, imperative, third, singular",
    GN: "G1986",
    gloss: "to uncircumcise",
  },
  ἐπίσταμαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G1987",
    gloss: "to know/understand",
  },
  ἐπιστάμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G1987",
    gloss: "to know/understand",
  },
  ἐπίστανται: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G1987",
    gloss: "to know/understand",
  },
  ἐπίστασθε: {
    parse:
      "V-PNI-2P｜Verb, present, Middle or Passive deponent, indicative, second, plural",
    GN: "G1987",
    gloss: "to know/understand",
  },
  ἐπίσταται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G1987",
    gloss: "to know/understand",
  },
  ἐπιστάτα: {
    // or nominative
    parse: "N-VSM｜Noun, Vocativee, singular, masculine",
    GN: "G1988",
    gloss: "master",
  },
  Ἐπιστάτα: {
    // or nominative
    parse: "N-VSM｜Noun, Vocative, singular, masculine",
    GN: "G1988",
    gloss: "master",
  },
  ἐπέστειλα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G1989",
    gloss: "to write to",
  },
  ἐπεστείλαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G1989",
    gloss: "to write to",
  },
  ἐπιστεῖλαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1989",
    gloss: "to write to",
  },
  ἐπιστήμων: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G1990",
    gloss: "knowing",
  },
  ἐπεστήριξαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G1991",
    gloss: "to strengthen",
  },
  ἐπιστηρίζοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G1991",
    gloss: "to strengthen",
  },
  ἐπιστηρίζων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1991",
    gloss: "to strengthen",
  },
  στηρίζων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G1991",
    gloss: "to strengthen",
  },
  ἐπιστολαὶ: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G1992",
    gloss: "epistle",
  },
  ἐπιστολαῖς: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G1992",
    gloss: "epistle",
  },
  ἐπιστολὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G1992",
    gloss: "epistle",
  },
  ἐπιστολῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G1992",
    gloss: "epistle",
  },
  ἐπιστολή: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1992",
    gloss: "epistle",
  },
  ἐπιστολὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1992",
    gloss: "epistle",
  },
  ἐπιστολήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1992",
    gloss: "epistle",
  },
  ἐπιστολὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1992",
    gloss: "epistle",
  },
  ἐπιστολῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1992",
    gloss: "epistle",
  },
  ἐπιστολῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G1992",
    gloss: "epistle",
  },
  ἐπιστομίζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1993",
    gloss: "to silence",
  },
  ἐπεστράφητε: {
    parse: "V-2API-2P｜Verb, 2nd aorist, passive, indicative, second, plural",
    GN: "G1994",
    gloss: "to turn",
  },
  ἐπέστρεψα: {
    parse: "V-2AAI-1S｜Verb, 2nd aorist, active, indicative, first, singular",
    GN: "G1994",
    gloss: "to turn",
  },
  ἐπέστρεψαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G1994",
    gloss: "to turn",
  },
  ἐπεστρέψατε: {
    parse: "V-2AAI-2P｜Verb, 2nd aorist, active, indicative, second, plural",
    GN: "G1994",
    gloss: "to turn",
  },
  ἐπέστρεψεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G1994",
    gloss: "to turn",
  },
  ἐπιστραφεὶς: {
    parse:
      "V-2APP-NSM｜Verb, 2nd aorist, passive, Participle, nominative, singular, masculine",
    GN: "G1994",
    gloss: "to turn",
  },
  Ἐπιστραφεὶς: {
    parse:
      "V-2APP-NSM｜Verb, 2nd aorist, passive, Participle, nominative, singular, masculine",
    GN: "G1994",
    gloss: "to turn",
  },
  ἐπιστραφήτω: {
    parse: "V-2APM-3S｜Verb, 2nd aorist, passive, imperative, third, singular",
    GN: "G1994",
    gloss: "to turn",
  },
  ἐπιστρέφειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G1994",
    gloss: "to turn",
  },
  ἐπιστρέφετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G1994",
    gloss: "to turn",
  },
  ἐπιστρέφουσιν: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G1994",
    gloss: "to turn",
  },
  ἐπιστρέψαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1994",
    gloss: "to turn",
  },
  Ἐπιστρέψαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G1994",
    gloss: "to turn",
  },
  ἐπιστρέψας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G1994",
    gloss: "to turn",
  },
  ἐπιστρέψατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G1994",
    gloss: "to turn",
  },
  ἐπιστρεψάτω: {
    parse: "V-AAM-3S｜Verb, aorist, active, imperative, third, singular",
    GN: "G1994",
    gloss: "to turn",
  },
  ἐπιστρέψει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G1994",
    gloss: "to turn",
  },
  ἐπιστρέψῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G1994",
    gloss: "to turn",
  },
  ἐπιστρέψω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G1994",
    gloss: "to turn",
  },
  ἐπιστρέψωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G1994",
    gloss: "to turn",
  },
  ἐπιστροφὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1995",
    gloss: "conversion",
  },
  ἐπισυναγαγεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G1996",
    gloss: "to gather",
  },
  ἐπισυνάγει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1996",
    gloss: "to gather",
  },
  ἐπισυνάξαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G1996",
    gloss: "to gather",
  },
  ἐπισυνάξει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G1996",
    gloss: "to gather",
  },
  ἐπισυνάξουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G1996",
    gloss: "to gather",
  },
  ἐπισυναχθεισῶν: {
    parse:
      "V-APP-GPF｜Verb, aorist, passive, Participle, genitive, plural, feminine",
    GN: "G1996",
    gloss: "to gather",
  },
  ἐπισυναχθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G1996",
    gloss: "to gather",
  },
  ἐπισυνηγμένη: {
    parse:
      "V-RPP-NSF｜Verb, perfect, passive, Participle, nominative, singular, feminine",
    GN: "G1996",
    gloss: "to gather",
  },
  ἐπισυναγωγὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1997",
    gloss: "gathering",
  },
  ἐπισυναγωγῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G1997",
    gloss: "gathering",
  },
  ἐπισυντρέχει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G1998",
    gloss: "to run together",
  },
  ἐπίστασιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G1999",
    gloss: "insurrection",
  },
  ἐπίστασίς: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G1999",
    gloss: "insurrection",
  },
  ἐπισφαλοῦς: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G2000",
    gloss: "dangerous/unsteady",
  },
  ἐπίσχυον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2001",
    gloss: "to insist",
  },
  ἐπισωρεύσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G2002",
    gloss: "to accumulate",
  },
  ἐπιταγήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2003",
    gloss: "command",
  },
  ἐπιταγὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2003",
    gloss: "command",
  },
  ἐπιταγῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2003",
    gloss: "command",
  },
  ἐπέταξας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G2004",
    gloss: "to command",
  },
  ἐπέταξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2004",
    gloss: "to command",
  },
  ἐπιτάξῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G2004",
    gloss: "to command",
  },
  ἐπιτάσσει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2004",
    gloss: "to command",
  },
  ἐπιτάσσειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2004",
    gloss: "to command",
  },
  ἐπιτάσσω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G2004",
    gloss: "to command",
  },
  ἐπιτελεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2005",
    gloss: "to complete",
  },
  ἐπιτελεῖσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G2005",
    gloss: "to complete",
  },
  ἐπιτελεῖσθε: {
    parse:
      "V-PEI-2P｜Verb, present, Either middle or passive, indicative, second, plural",
    GN: "G2005",
    gloss: "to complete",
  },
  ἐπιτελέσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2005",
    gloss: "to complete",
  },
  ἐπιτελέσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G2005",
    gloss: "to complete",
  },
  ἐπιτελέσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G2005",
    gloss: "to complete",
  },
  ἐπιτελέσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2005",
    gloss: "to complete",
  },
  ἐπιτελέσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G2005",
    gloss: "to complete",
  },
  ἐπιτελοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2005",
    gloss: "to complete",
  },
  ἐπιτήδεια: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G2006",
    gloss: "necessary",
  },
  ἐπέθεντο: {
    parse: "V-2AMI-3P｜Verb, 2nd aorist, middle, indicative, third, plural",
    GN: "G2007",
    gloss: "to put/lay on",
  },
  ἐπέθηκαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G2007",
    gloss: "to put/lay on",
  },
  ἐπέθηκεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G2007",
    gloss: "to put/lay on",
  },
  ἐπέθηκέν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2007",
    gloss: "to put/lay on",
  },
  ἐπετίθεσαν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2007",
    gloss: "to put/lay on",
  },
  ἐπιθεῖναι: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G2007",
    gloss: "to put/lay on",
  },
  ἐπιθεὶς: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G2007",
    gloss: "to put/lay on",
  },
  ἐπιθέντα: {
    parse:
      "V-2AAP-ASM｜Verb, 2nd aorist, active, Participle, accusative, singular, masculine",
    GN: "G2007",
    gloss: "to put/lay on",
  },
  ἐπιθέντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G2007",
    gloss: "to put/lay on",
  },
  ἐπιθέντος: {
    parse:
      "V-2AAP-GSM｜Verb, 2nd aorist, active, Participle, genitive, singular, masculine",
    GN: "G2007",
    gloss: "to put/lay on",
  },
  ἐπίθες: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G2007",
    gloss: "to put/lay on",
  },
  ἐπιθῇ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G2007",
    gloss: "to put/lay on",
  },
  ἐπιθῇς: {
    parse: "V-2AAS-2S｜Verb, 2nd aorist, active, subjunctive, second, singular",
    GN: "G2007",
    gloss: "to put/lay on",
  },
  ἐπιθήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2007",
    gloss: "to put/lay on",
  },
  ἐπιθήσεταί: {
    parse: "V-FMI-3S｜Verb, future, middle, indicative, third, singular",
    GN: "G2007",
    gloss: "to put/lay on",
  },
  ἐπιθήσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G2007",
    gloss: "to put/lay on",
  },
  ἐπιθῶ: {
    parse: "V-2AAS-1S｜Verb, 2nd aorist, active, subjunctive, first, singular",
    GN: "G2007",
    gloss: "to put/lay on",
  },
  ἐπιτιθέασιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2007",
    gloss: "to put/lay on",
  },
  ἐπιτίθει: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G2007",
    gloss: "to put/lay on",
  },
  ἐπιτιθεὶς: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2007",
    gloss: "to put/lay on",
  },
  ἐπιτίθεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G2007",
    gloss: "to put/lay on",
  },
  ἐπιτίθησιν: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2007",
    gloss: "to put/lay on",
  },
  ἐπετίμα: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G2008",
    gloss: "to rebuke",
  },
  ἐπετίμησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2008",
    gloss: "to rebuke",
  },
  ἐπετίμησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2008",
    gloss: "to rebuke",
  },
  ἐπετίμων: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2008",
    gloss: "to rebuke",
  },
  ἐπιτιμᾶν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2008",
    gloss: "to rebuke",
  },
  Ἐπιτιμήσαι: {
    parse: "V-AAO-3S｜Verb, aorist, active, Optative, third, singular",
    GN: "G2008",
    gloss: "to rebuke",
  },
  ἐπιτιμήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G2008",
    gloss: "to rebuke",
  },
  ἐπιτίμησον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G2008",
    gloss: "to rebuke",
  },
  ἐπιτιμῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2008",
    gloss: "to rebuke",
  },
  ἐπιτιμία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2009",
    gloss: "punishment",
  },
  ἐπετράπη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2010",
    gloss: "to permit",
  },
  ἐπέτρεψεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2010",
    gloss: "to permit",
  },
  Ἐπέτρεψεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2010",
    gloss: "to permit",
  },
  ἐπιτρέπεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G2010",
    gloss: "to permit",
  },
  Ἐπιτρέπεταί: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G2010",
    gloss: "to permit",
  },
  ἐπιτρέπῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G2010",
    gloss: "to permit",
  },
  ἐπιτρέπω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G2010",
    gloss: "to permit",
  },
  ἐπιτρέψαντος: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G2010",
    gloss: "to permit",
  },
  ἐπιτρέψῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G2010",
    gloss: "to permit",
  },
  ἐπίτρεψόν: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G2010",
    gloss: "to permit",
  },
  ἐπιτροπῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2011",
    gloss: "commission",
  },
  ἐπιτρόπου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2012",
    gloss: "manager",
  },
  ἐπιτρόπους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G2012",
    gloss: "manager",
  },
  ἐπιτρόπῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G2012",
    gloss: "manager",
  },
  ἐπέτυχεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G2013",
    gloss: "to obtain",
  },
  ἐπέτυχον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G2013",
    gloss: "to obtain",
  },
  ἐπιτυχεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G2013",
    gloss: "to obtain",
  },
  ἐπεφάνη: {
    parse: "V-2API-3S｜Verb, 2nd aorist, passive, indicative, third, singular",
    GN: "G2014",
    gloss: "to appear",
  },
  Ἐπεφάνη: {
    parse: "V-2API-3S｜Verb, 2nd aorist, passive, indicative, third, singular",
    GN: "G2014",
    gloss: "to appear",
  },
  ἐπιφαινόντων: {
    parse:
      "V-PAP-GPN｜Verb, present, active, Participle, genitive, plural, neuter",
    GN: "G2014",
    gloss: "to appear",
  },
  ἐπιφᾶναι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2014",
    gloss: "to appear",
  },
  ἐπιφανείᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2015",
    gloss: "appearing",
  },
  ἐπιφάνειαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2015",
    gloss: "appearing",
  },
  ἐπιφανείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2015",
    gloss: "appearing",
  },
  ἐπιφανῆ: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G2016",
    gloss: "glorious",
  },
  ἐπιφαύσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2017",
    gloss: "to shine on",
  },
  ἐπενεγκεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G2018",
    gloss: "to inflict",
  },
  ἐπιφέρων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2018",
    gloss: "to inflict",
  },
  ἐπεφώνει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G2019",
    gloss: "to shout",
  },
  ἐπεφώνουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2019",
    gloss: "to shout",
  },
  ἐπέφωσκεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G2020",
    gloss: "to dawn",
  },
  ἐπιφωσκούσῃ: {
    parse:
      "V-PAP-DSF｜Verb, present, active, Participle, dative, singular, feminine",
    GN: "G2020",
    gloss: "to dawn",
  },
  ἐπεχείρησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2021",
    gloss: "to attempt",
  },
  ἐπεχείρουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2021",
    gloss: "to attempt",
  },
  ἐπιχέων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2022",
    gloss: "to pour on/over",
  },
  ἐπιχορηγηθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G2023",
    gloss: "to supply",
  },
  ἐπιχορηγήσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G2023",
    gloss: "to supply",
  },
  ἐπιχορηγούμενον: {
    parse:
      "V-PPP-NSN｜Verb, present, passive, Participle, nominative, singular, neuter",
    GN: "G2023",
    gloss: "to supply",
  },
  ἐπιχορηγῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2023",
    gloss: "to supply",
  },
  ἐπιχορηγίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2024",
    gloss: "supply",
  },
  ἐπέχρισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2025",
    gloss: "to rub on",
  },
  ἐπέχρισέν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2025",
    gloss: "to rub on",
  },
  ἐποικοδομεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2026",
    gloss: "to build up/upon",
  },
  ἐποικοδομηθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G2026",
    gloss: "to build up/upon",
  },
  ἐποικοδόμησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2026",
    gloss: "to build up/upon",
  },
  ἐποικοδομούμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G2026",
    gloss: "to build up/upon",
  },
  ἐποικοδομοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2026",
    gloss: "to build up/upon",
  },
  ἐπέκειλαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2027",
    gloss: "to run aground",
  },
  ἐπονομάζῃ: {
    parse: "V-PMI-2S｜Verb, present, middle, indicative, second, singular",
    GN: "G2028",
    gloss: "to name",
  },
  ἐποπτεύοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2029",
    gloss: "to observe",
  },
  ἐποπτεύσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G2029",
    gloss: "to observe",
  },
  ἐπόπται: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G2030",
    gloss: "eyewitness",
  },
  ἔπος: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G2031",
    gloss: "a word",
  },
  ἐπουράνια: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G2032",
    gloss: "heavenly",
  },
  ἐπουράνιοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G2032",
    gloss: "heavenly",
  },
  ἐπουρανίοις: {
    parse: "A-DPN｜Adjective, dative, plural, neuter",
    GN: "G2032",
    gloss: "heavenly",
  },
  ἐπουράνιον: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G2032",
    gloss: "heavenly",
  },
  ἐπουράνιος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2032",
    gloss: "heavenly",
  },
  ἐπουρανίου: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G2032",
    gloss: "heavenly",
  },
  ἐπουρανίῳ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G2032",
    gloss: "heavenly",
  },
  ἐπουρανίων: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G2032",
    gloss: "heavenly",
  },
  ἑπτά: {
    parse:
      "A-NPM-NUI｜Adjective, nominative, plural, masculine, NUmerical Indiclinable (A)",
    GN: "G2033",
    gloss: "seven",
  },
  Ἑπτά: {
    parse:
      "A-APM-NUI｜Adjective, accusative, plural, masculine, NUmerical Indiclinable (A)",
    GN: "G2033",
    gloss: "seven",
  },
  ἑπτὰ: {
    parse:
      "A-NPN-NUI｜Adjective, nominative, plural, neuter, NUmerical Indiclinable (A)",
    GN: "G2033",
    gloss: "seven",
  },
  ἑπτάκις: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2034",
    gloss: "seven times",
  },
  ἑπτακισχιλίους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2035",
    gloss: "seven thousand",
  },
  εἶπα: {
    parse: "V-2AAI-1S｜Verb, 2nd aorist, active, indicative, first, singular",
    GN: "G2036",
    gloss: "to say",
  },
  εἶπαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G2036",
    gloss: "to say",
  },
  Εἶπαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G2036",
    gloss: "to say",
  },
  εἶπας: {
    parse: "V-2AAI-2S｜Verb, 2nd aorist, active, indicative, second, singular",
    GN: "G2036",
    gloss: "to say",
  },
  εἴπας: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G2036",
    gloss: "to say",
  },
  εἴπατε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G2036",
    gloss: "to say",
  },
  Εἴπατε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G2036",
    gloss: "to say",
  },
  εἴπατέ: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G2036",
    gloss: "to say",
  },
  εἰπάτω: {
    parse: "V-2AAM-3S｜Verb, 2nd aorist, active, imperative, third, singular",
    GN: "G2036",
    gloss: "to say",
  },
  εἰπάτωσαν: {
    parse: "V-2AAM-3P｜Verb, 2nd aorist, active, imperative, third, plural",
    GN: "G2036",
    gloss: "to say",
  },
  εἰπέ: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G2036",
    gloss: "to say",
  },
  Εἰπέ: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G2036",
    gloss: "to say",
  },
  εἰπὲ: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G2036",
    gloss: "to say",
  },
  Εἰπὲ: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G2036",
    gloss: "to say",
  },
  εἰπεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G2036",
    gloss: "to say",
  },
  εἶπεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G2036",
    gloss: "to say",
  },
  Εἶπεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G2036",
    gloss: "to say",
  },
  εἶπέν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G2036",
    gloss: "to say",
  },
  Εἶπέν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G2036",
    gloss: "to say",
  },
  εἶπες: {
    parse: "V-2AAI-2S｜Verb, 2nd aorist, active, indicative, second, singular",
    GN: "G2036",
    gloss: "to say",
  },
  εἴπῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G2036",
    gloss: "to say",
  },
  εἴπῃς: {
    parse: "V-2AAS-2S｜Verb, 2nd aorist, active, subjunctive, second, singular",
    GN: "G2036",
    gloss: "to say",
  },
  εἴπητε: {
    parse: "V-2AAS-2P｜Verb, 2nd aorist, active, subjunctive, second, plural",
    GN: "G2036",
    gloss: "to say",
  },
  εἴπητέ: {
    parse: "V-2AAS-2P｜Verb, 2nd aorist, active, subjunctive, second, plural",
    GN: "G2036",
    gloss: "to say",
  },
  εἶπον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G2036",
    gloss: "to say",
  },
  Εἶπον: {
    parse:
      "V-2AAI-1S｜Verb, 2nd aorist, active, indicative, first, singular OR third, plural",
    GN: "G2036",
    gloss: "to say",
  },
  εἶπόν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G2036",
    gloss: "to say",
  },
  εἰπόν: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G2036",
    gloss: "to say",
  },
  εἰπὸν: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G2036",
    gloss: "to say",
  },
  Εἰπὸν: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G2036",
    gloss: "to say",
  },
  εἰπόντα: {
    parse:
      "V-2AAP-ASM｜Verb, 2nd aorist, active, Participle, accusative, singular, masculine",
    GN: "G2036",
    gloss: "to say",
  },
  εἰπόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G2036",
    gloss: "to say",
  },
  εἰπόντος: {
    parse:
      "V-2AAP-GSM｜Verb, 2nd aorist, active, Participle, genitive, singular, masculine",
    GN: "G2036",
    gloss: "to say",
  },
  εἰποῦσα: {
    parse:
      "V-2AAP-NSF｜Verb, 2nd aorist, active, Participle, nominative, singular, feminine",
    GN: "G2036",
    gloss: "to say",
  },
  εἴπω: {
    parse: "V-2AAS-1S｜Verb, 2nd aorist, active, subjunctive, first, singular",
    GN: "G2036",
    gloss: "to say",
  },
  εἴπωμεν: {
    parse: "V-2AAS-1P｜Verb, 2nd aorist, active, subjunctive, first, plural",
    GN: "G2036",
    gloss: "to say",
  },
  εἰπών: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G2036",
    gloss: "to say",
  },
  εἰπὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G2036",
    gloss: "to say",
  },
  εἴπωσιν: {
    parse: "V-2AAS-3P｜Verb, 2nd aorist, active, subjunctive, third, plural",
    GN: "G2036",
    gloss: "to say",
  },
  εἴρηκα: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G2036",
    gloss: "to say",
  },
  εἴρηκαν: {
    parse: "V-RAI-3P｜Verb, perfect, active, indicative, third, plural",
    GN: "G2036",
    gloss: "to say",
  },
  εἴρηκας: {
    parse: "V-RAI-2S｜Verb, perfect, active, indicative, second, singular",
    GN: "G2036",
    gloss: "to say",
  },
  εἰρήκασιν: {
    parse: "V-RAI-3P｜Verb, perfect, active, indicative, third, plural",
    GN: "G2036",
    gloss: "to say",
  },
  εἰρήκατε: {
    parse: "V-RAI-2P｜Verb, perfect, active, indicative, second, plural",
    GN: "G2036",
    gloss: "to say",
  },
  εἰρήκει: {
    parse: "V-LAI-3S｜Verb, pluperfect, active, indicative, third, singular",
    GN: "G2036",
    gloss: "to say",
  },
  εἴρηκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G2036",
    gloss: "to say",
  },
  εἴρηκέν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G2036",
    gloss: "to say",
  },
  εἰρηκέναι: {
    parse: "V-RAN｜Verb, perfect, active, Infinitive",
    GN: "G2036",
    gloss: "to say",
  },
  εἰρηκότος: {
    parse:
      "V-RAP-GSM｜Verb, perfect, active, Participle, genitive, singular, masculine",
    GN: "G2036",
    gloss: "to say",
  },
  εἰρημένον: {
    parse:
      "V-RPP-NSN｜Verb, perfect, passive, Participle, nominative, singular, neuter",
    GN: "G2036",
    gloss: "to say",
  },
  Εἴρηται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G2036",
    gloss: "to say",
  },
  ἐρεῖ: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2036",
    gloss: "to say",
  },
  ἐρεῖς: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G2036",
    gloss: "to say",
  },
  Ἐρεῖς: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G2036",
    gloss: "to say",
  },
  ἐρεῖτε: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G2036",
    gloss: "to say",
  },
  ἐρεῖτέ: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G2036",
    gloss: "to say",
  },
  ἐροῦμεν: {
    parse: "V-FAI-1P｜Verb, future, active, indicative, first, plural",
    GN: "G2036",
    gloss: "to say",
  },
  ἐροῦσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G2036",
    gloss: "to say",
  },
  ἐροῦσίν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G2036",
    gloss: "to say",
  },
  ἐρρέθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2036",
    gloss: "to say",
  },
  Ἐρρέθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2036",
    gloss: "to say",
  },
  ἐρρέθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G2036",
    gloss: "to say",
  },
  ἐρῶ: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G2036",
    gloss: "to say",
  },
  ῥηθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G2036",
    gloss: "to say",
  },
  ῥηθὲν: {
    parse:
      "V-APP-NSN｜Verb, aorist, passive, Participle, nominative, singular, neuter",
    GN: "G2036",
    gloss: "to say",
  },
  Ἔραστον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2037",
    gloss: "Erastus",
  },
  Ἔραστος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2037",
    gloss: "Erastus",
  },
  εἰργασάμεθα: {
    parse: "V-ADI-1P｜Verb, aorist, Middle deponent, indicative, first, plural",
    GN: "G2038",
    gloss: "to work",
  },
  εἰργάσαντο: {
    parse: "V-ADI-3P｜Verb, aorist, Middle deponent, indicative, third, plural",
    GN: "G2038",
    gloss: "to work",
  },
  εἰργασμένα: {
    parse:
      "V-RPP-NPN｜Verb, perfect, passive, Participle, nominative, plural, neuter",
    GN: "G2038",
    gloss: "to work",
  },
  ἐργάζεσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G2038",
    gloss: "to work",
  },
  ἐργάζεσθε: {
    parse:
      "V-PNM-2P｜Verb, present, Middle or Passive deponent, imperative, second, plural",
    GN: "G2038",
    gloss: "to work",
  },
  ἐργάζεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G2038",
    gloss: "to work",
  },
  ἐργάζῃ: {
    parse:
      "V-PNI-2S｜Verb, present, Middle or Passive deponent, indicative, second, singular",
    GN: "G2038",
    gloss: "to work",
  },
  ἐργάζομαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G2038",
    gloss: "to work",
  },
  ἐργαζόμενοι: {
    parse:
      "V-PNP-VPM｜Verb, present, Middle or Passive deponent, Participle, Vocative or nominative, plural, masculine",
    GN: "G2038",
    gloss: "to work",
  },
  ἐργαζόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G2038",
    gloss: "to work",
  },
  ἐργαζομένους: {
    parse:
      "V-PNP-APM｜Verb, present, Middle or Passive deponent, Participle, accusative, plural, masculine",
    GN: "G2038",
    gloss: "to work",
  },
  ἐργαζομένῳ: {
    parse:
      "V-PNP-DSM｜Verb, present, Middle or Passive deponent, Participle, dative, singular, masculine",
    GN: "G2038",
    gloss: "to work",
  },
  ἐργάζονται: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G2038",
    gloss: "to work",
  },
  ἐργάζου: {
    parse:
      "V-PNM-2S｜Verb, present, Middle or Passive deponent, imperative, second, singular",
    GN: "G2038",
    gloss: "to work",
  },
  ἐργαζώμεθα: {
    parse:
      "V-PNS-1P｜Verb, present, Middle or Passive deponent, subjunctive, first, plural",
    GN: "G2038",
    gloss: "to work",
  },
  ἐργάσῃ: {
    parse:
      "V-ADS-2S｜Verb, aorist, Middle deponent, subjunctive, second, singular",
    GN: "G2038",
    gloss: "to work",
  },
  ἠργάζετο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G2038",
    gloss: "to work",
  },
  ἠργάσατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G2038",
    gloss: "to work",
  },
  ἐργασίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2039",
    gloss: "work",
  },
  ἐργασίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2039",
    gloss: "work",
  },
  ἐργάται: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G2040",
    gloss: "worker",
  },
  ἐργάτας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G2040",
    gloss: "worker",
  },
  ἐργάτην: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2040",
    gloss: "worker",
  },
  ἐργάτης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2040",
    gloss: "worker",
  },
  ἐργατῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G2040",
    gloss: "worker",
  },
  ἔργα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G2041",
    gloss: "work",
  },
  ἔργοις: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G2041",
    gloss: "work",
  },
  ἔργον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2041",
    gloss: "work",
  },
  ἔργου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G2041",
    gloss: "work",
  },
  ἔργῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G2041",
    gloss: "work",
  },
  ἔργων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G2041",
    gloss: "work",
  },
  ἐρεθίζετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G2042",
    gloss: "to provoke/irritate",
  },
  ἠρέθισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2042",
    gloss: "to provoke/irritate",
  },
  ἐρείσασα: {
    parse:
      "V-AAP-NSF｜Verb, aorist, active, Participle, nominative, singular, feminine",
    GN: "G2043",
    gloss: "to immobilise",
  },
  ἐρεύξομαι: {
    parse:
      "V-FDI-1S｜Verb, future, Middle deponent, indicative, first, singular",
    GN: "G2044",
    gloss: "to utter/proclaim",
  },
  ἐραυνᾷ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2045",
    gloss: "to look for/into",
  },
  Ἐραυνᾶτε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G2045",
    gloss: "to look for/into",
  },
  ἐραύνησον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G2045",
    gloss: "to look for/into",
  },
  ἐραυνῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2045",
    gloss: "to look for/into",
  },
  ἐραυνῶντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2045",
    gloss: "to look for/into",
  },
  ἐρημίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2047",
    gloss: "desert",
  },
  ἐρημίαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G2047",
    gloss: "desert",
  },
  ἐρημίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2047",
    gloss: "desert",
  },
  ἐρήμοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G2048",
    gloss: "deserted",
  },
  ἔρημον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G2048",
    gloss: "deserted",
  },
  ἔρημος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2048",
    gloss: "deserted",
  },
  Ἔρημός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2048",
    gloss: "deserted",
  },
  ἐρήμου: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G2048",
    gloss: "deserted",
  },
  ἐρήμους: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G2048",
    gloss: "deserted",
  },
  ἐρήμῳ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G2048",
    gloss: "deserted",
  },
  ἐρημοῦται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G2049",
    gloss: "to lay waste",
  },
  ἠρημώθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2049",
    gloss: "to lay waste",
  },
  ἠρημωμένην: {
    parse:
      "V-RPP-ASF｜Verb, perfect, passive, Participle, accusative, singular, feminine",
    GN: "G2049",
    gloss: "to lay waste",
  },
  ἐρημώσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2050",
    gloss: "devastation",
  },
  ἐρήμωσις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2050",
    gloss: "devastation",
  },
  ἐρίσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2051",
    gloss: "to quarrel",
  },
  ἐριθεία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2052",
    gloss: "rivalry",
  },
  ἐριθεῖαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G2052",
    gloss: "rivalry",
  },
  ἐριθείαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2052",
    gloss: "rivalry",
  },
  ἐριθείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2052",
    gloss: "rivalry",
  },
  ἔριον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2053",
    gloss: "wool",
  },
  ἐρίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G2053",
    gloss: "wool",
  },
  ἔρεις: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G2054",
    gloss: "quarrel",
  },
  ἔριδες: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G2054",
    gloss: "quarrel",
  },
  ἔριδι: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2054",
    gloss: "quarrel",
  },
  ἔριδος: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2054",
    gloss: "quarrel",
  },
  ἔριν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2054",
    gloss: "quarrel",
  },
  ἔρις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2054",
    gloss: "quarrel",
  },
  ἐρίφια: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G2055",
    gloss: "a goat",
  },
  ἔριφον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2056",
    gloss: "a goat",
  },
  ἐρίφων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G2056",
    gloss: "a goat",
  },
  Ἑρμᾶν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2057",
    gloss: "Hermas",
  },
  ἑρμηνεία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2058",
    gloss: "interpretation",
  },
  ἑρμηνείαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2058",
    gloss: "interpretation",
  },
  ἑρμηνεύεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G2059",
    gloss: "to interpret",
  },
  ἑρμηνευόμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G2059",
    gloss: "to interpret",
  },
  Ἑρμῆν: {
    parse: "N-ASM-T｜Noun, accusative, singular, masculine, Title",
    GN: "G2060",
    gloss: "Hermes",
  },
  Ἑρμογένης: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2061",
    gloss: "Hermogenes",
  },
  ἑρπετὰ: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G2062",
    gloss: "reptile",
  },
  ἑρπετῶν: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G2062",
    gloss: "reptile",
  },
  Ἐρυθρᾷ: {
    parse: "A-DSF-L｜Adjective, dative, singular, feminine, Location",
    GN: "G2063",
    gloss: "Red( Sea)",
  },
  Ἐρυθρὰν: {
    parse: "A-ASF-L｜Adjective, accusative, singular, feminine, Location",
    GN: "G2063",
    gloss: "Red( Sea)",
  },
  ἐλεύσεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐλεύσομαι: {
    parse:
      "V-FDI-1S｜Verb, future, Middle deponent, indicative, first, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  Ἐλεύσομαι: {
    parse:
      "V-FDI-1S｜Verb, future, Middle deponent, indicative, first, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐλευσόμεθα: {
    parse: "V-FDI-1P｜Verb, future, Middle deponent, indicative, first, plural",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐλεύσονται: {
    parse: "V-FDI-3P｜Verb, future, Middle deponent, indicative, third, plural",
    GN: "G2064",
    gloss: "to come/go",
  },
  Ἐλεύσονται: {
    parse: "V-FDI-3P｜Verb, future, Middle deponent, indicative, third, plural",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐλήλυθα: {
    parse: "V-2RAI-1S｜Verb, 2nd perfect, active, indicative, first, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐλήλυθας: {
    parse: "V-2RAI-2S｜Verb, 2nd perfect, active, indicative, second, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐληλύθει: {
    parse:
      "V-2LAI-3S｜Verb, 2nd pluperfect, active, indicative, third, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐληλύθεισαν: {
    parse: "V-2LAI-3P｜Verb, 2nd pluperfect, active, indicative, third, plural",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐλήλυθεν: {
    parse: "V-2RAI-3S｜Verb, 2nd perfect, active, indicative, third, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  Ἐλήλυθεν: {
    parse: "V-2RAI-3S｜Verb, 2nd perfect, active, indicative, third, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐληλυθότα: {
    parse:
      "V-2RAP-ASM｜Verb, 2nd perfect, active, Participle, accusative, singular, masculine",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐληλυθότες: {
    parse:
      "V-2RAP-NPM｜Verb, 2nd perfect, active, Participle, nominative, plural, masculine",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐληλυθυῖαν: {
    parse:
      "V-2RAP-ASF｜Verb, 2nd perfect, active, Participle, accusative, singular, feminine",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐλθάτω: {
    parse: "V-2AAM-3S｜Verb, 2nd aorist, active, imperative, third, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  Ἐλθέ: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐλθὲ: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐλθεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐλθέτω: {
    parse: "V-2AAM-3S｜Verb, 2nd aorist, active, imperative, third, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἔλθῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἔλθῃς: {
    parse: "V-2AAS-2S｜Verb, 2nd aorist, active, subjunctive, second, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἔλθητε: {
    parse: "V-2AAS-2P｜Verb, 2nd aorist, active, subjunctive, second, plural",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐλθὸν: {
    parse:
      "V-2AAP-NSN｜Verb, 2nd aorist, active, Participle, nominative, singular, neuter",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐλθόντα: {
    parse:
      "V-2AAP-NPN｜Verb, 2nd aorist, active, Participle, nominative, plural, neuter",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐλθόντας: {
    parse:
      "V-2AAP-APM｜Verb, 2nd aorist, active, Participle, accusative, plural, masculine",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐλθόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐλθόντι: {
    parse:
      "V-2AAP-DSM｜Verb, 2nd aorist, active, Participle, dative, singular, masculine",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐλθόντος: {
    parse:
      "V-2AAP-GSM｜Verb, 2nd aorist, active, Participle, genitive, singular, masculine",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐλθόντων: {
    parse:
      "V-2AAP-GPM｜Verb, 2nd aorist, active, Participle, genitive, plural, masculine",
    GN: "G2064",
    gloss: "to come/go",
  },
  Ἐλθόντων: {
    parse:
      "V-2AAP-GPM｜Verb, 2nd aorist, active, Participle, genitive, plural, masculine",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐλθοῦσα: {
    parse:
      "V-2AAP-NSF｜Verb, 2nd aorist, active, Participle, nominative, singular, feminine",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐλθοῦσαι: {
    parse:
      "V-2AAP-NPF｜Verb, 2nd aorist, active, Participle, nominative, plural, feminine",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐλθούσης: {
    parse:
      "V-2AAP-GSF｜Verb, 2nd aorist, active, Participle, genitive, singular, feminine",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἔλθω: {
    parse: "V-2AAS-1S｜Verb, 2nd aorist, active, subjunctive, first, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐλθὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G2064",
    gloss: "to come/go",
  },
  Ἐλθὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἔλθωσιν: {
    parse: "V-2AAS-3P｜Verb, 2nd aorist, active, subjunctive, third, plural",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἔρχεσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G2064",
    gloss: "to come/go",
  },
  Ἔρχεσθε: {
    parse:
      "V-PNM-2P｜Verb, present, Middle or Passive deponent, imperative, second, plural",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐρχέσθω: {
    parse:
      "V-PNM-3S｜Verb, present, Middle or Passive deponent, imperative, third, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἔρχεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  Ἔρχεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἔρχεταί: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἔρχῃ: {
    parse:
      "V-PNI-2S｜Verb, present, Middle or Passive deponent, indicative, second, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἔρχηται: {
    parse:
      "V-PNS-3S｜Verb, present, Middle or Passive deponent, subjunctive, third, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἔρχομαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἔρχομαί: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  Ἐρχόμεθα: {
    parse:
      "V-PNI-1P｜Verb, present, Middle or Passive deponent, indicative, first, plural",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐρχόμενα: {
    parse:
      "V-PNP-APN｜Verb, present, Middle or Passive deponent, Participle, accusative, plural, neuter",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐρχομένη: {
    parse:
      "V-PNP-NSF｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, feminine",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐρχομένης: {
    parse:
      "V-PNP-GSF｜Verb, present, Middle or Passive deponent, Participle, genitive, singular, feminine",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐρχόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐρχόμενον: {
    parse:
      "V-PNP-ASN｜Verb, present, Middle or Passive deponent, Participle, accusative, singular, neuter",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐρχόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐρχομένου: {
    parse:
      "V-PNP-GSM｜Verb, present, Middle or Passive deponent, Participle, genitive, singular, masculine",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐρχομένους: {
    parse:
      "V-PNP-APM｜Verb, present, Middle or Passive deponent, Participle, accusative, plural, masculine",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐρχομένῳ: {
    parse:
      "V-PNP-DSN｜Verb, present, Middle or Passive deponent, Participle, dative, singular, neuter",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐρχομένων: {
    parse:
      "V-PNP-GPM｜Verb, present, Middle or Passive deponent, Participle, genitive, plural, masculine",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἔρχονται: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἔρχου: {
    parse:
      "V-PNM-2S｜Verb, present, Middle or Passive deponent, imperative, second, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  Ἔρχου: {
    parse:
      "V-PNM-2S｜Verb, present, Middle or Passive deponent, imperative, second, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἤλθαμεν: {
    parse: "V-2AAI-1P｜Verb, 2nd aorist, active, indicative, first, plural",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἦλθαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἤλθατε: {
    parse: "V-2AAI-2P｜Verb, 2nd aorist, active, indicative, second, plural",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἦλθε: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἦλθεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  Ἦλθεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἦλθες: {
    parse: "V-2AAI-2S｜Verb, 2nd aorist, active, indicative, second, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἤλθομεν: {
    parse: "V-2AAI-1P｜Verb, 2nd aorist, active, indicative, first, plural",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἦλθον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  Ἦλθον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἤρχετο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἤρχοντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἤρχου: {
    parse:
      "V-INI-2S｜Verb, imperfect, Middle or Passive deponent, indicative, second, singular",
    GN: "G2064",
    gloss: "to come/go",
  },
  ἐρωτᾷ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G2065",
    gloss: "to ask",
  },
  ἐρωτᾶν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2065",
    gloss: "to ask",
  },
  ἐρωτᾷς: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G2065",
    gloss: "to ask",
  },
  ἐρωτῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2065",
    gloss: "to ask",
  },
  ἐρωτῆσαί: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2065",
    gloss: "to ask",
  },
  ἐρωτήσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G2065",
    gloss: "to ask",
  },
  ἐρωτήσετε: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G2065",
    gloss: "to ask",
  },
  ἐρωτήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G2065",
    gloss: "to ask",
  },
  ἐρώτησον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G2065",
    gloss: "to ask",
  },
  ἐρωτήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G2065",
    gloss: "to ask",
  },
  Ἐρωτήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G2065",
    gloss: "to ask",
  },
  ἐρωτήσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G2065",
    gloss: "to ask",
  },
  ἐρωτῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G2065",
    gloss: "to ask",
  },
  Ἐρωτῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G2065",
    gloss: "to ask",
  },
  ἐρωτῶμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G2065",
    gloss: "to ask",
  },
  Ἐρωτῶμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G2065",
    gloss: "to ask",
  },
  ἐρωτῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2065",
    gloss: "to ask",
  },
  ἐρωτῶντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2065",
    gloss: "to ask",
  },
  ἐρωτώντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G2065",
    gloss: "to ask",
  },
  ἠρώτα: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G2065",
    gloss: "to ask",
  },
  Ἠρώτα: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G2065",
    gloss: "to ask",
  },
  ἠρώτησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2065",
    gloss: "to ask",
  },
  ἠρώτησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2065",
    gloss: "to ask",
  },
  ἠρώτουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2065",
    gloss: "to ask",
  },
  ἠρώτων: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2065",
    gloss: "to ask",
  },
  ἐσθήσεσι: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G2066",
    gloss: "clothing",
  },
  ἐσθῆτα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2066",
    gloss: "clothing",
  },
  ἐσθῆτι: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2066",
    gloss: "clothing",
  },
  ἔσθητε: {
    parse: "V-PAS-2P｜Verb, present, active, subjunctive, second, plural",
    GN: "G2068",
    gloss: "to eat",
  },
  ἐσθίει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2068",
    gloss: "to eat",
  },
  ἐσθίειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2068",
    gloss: "to eat",
  },
  ἐσθίετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G2068",
    gloss: "to eat",
  },
  ἐσθιέτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G2068",
    gloss: "to eat",
  },
  ἐσθίῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G2068",
    gloss: "to eat",
  },
  ἐσθίητε: {
    parse: "V-PAS-2P｜Verb, present, active, subjunctive, second, plural",
    GN: "G2068",
    gloss: "to eat",
  },
  ἐσθίοντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G2068",
    gloss: "to eat",
  },
  ἐσθίοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2068",
    gloss: "to eat",
  },
  ἐσθίοντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G2068",
    gloss: "to eat",
  },
  ἐσθιόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G2068",
    gloss: "to eat",
  },
  Ἐσθιόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G2068",
    gloss: "to eat",
  },
  ἐσθίουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2068",
    gloss: "to eat",
  },
  ἐσθίων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2068",
    gloss: "to eat",
  },
  ἐσθίωσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G2068",
    gloss: "to eat",
  },
  ἤσθιον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2068",
    gloss: "to eat",
  },
  Ἑσλὶ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2069",
    gloss: "Esli",
  },
  ἐσόπτρου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G2072",
    gloss: "mirror",
  },
  ἐσόπτρῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G2072",
    gloss: "mirror",
  },
  ἑσπέρα: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2073",
    gloss: "evening",
  },
  ἑσπέραν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2073",
    gloss: "evening",
  },
  ἑσπέρας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2073",
    gloss: "evening",
  },
  Ἑσρώμ: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2074",
    gloss: "Hezron",
  },
  Ἑσρὼμ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2074",
    gloss: "Hezron",
  },
  ἔσχατα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G2078",
    gloss: "last/least",
  },
  ἐσχάταις: {
    parse: "A-DPF｜Adjective, dative, plural, feminine",
    GN: "G2078",
    gloss: "last/least",
  },
  ἐσχάτας: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G2078",
    gloss: "last/least",
  },
  ἐσχάτῃ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G2078",
    gloss: "last/least",
  },
  ἐσχάτη: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G2078",
    gloss: "last/least",
  },
  ἔσχατοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G2078",
    gloss: "last/least",
  },
  ἔσχατον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G2078",
    gloss: "last/least",
  },
  ἔσχατος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2078",
    gloss: "last/least",
  },
  ἐσχάτου: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G2078",
    gloss: "last/least",
  },
  ἐσχάτους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2078",
    gloss: "last/least",
  },
  ἐσχάτῳ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G2078",
    gloss: "last/least",
  },
  ἐσχάτων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G2078",
    gloss: "last/least",
  },
  ἐσχάτως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2079",
    gloss: "extremely",
  },
  ἔσω: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2080",
    gloss: "in/inner/inwardly",
  },
  ἔσωθεν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2081",
    gloss: "inwardly",
  },
  ἐσωτέραν: {
    parse: "A-ASF-C｜Adjective, accusative, singular, feminine, Comparative",
    GN: "G2082",
    gloss: "inner",
  },
  ἐσώτερον: {
    parse: "A-ASN-C｜Adjective, accusative, singular, neuter, Comparative",
    GN: "G2082",
    gloss: "inner",
  },
  Ἑταῖρε: {
    // or nominative
    parse: "N-VSM｜Noun, Vocative, singular, masculine",
    GN: "G2083",
    gloss: "friend",
  },
  ἑτερογλώσσοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G2084",
    gloss: "in another language",
  },
  ἑτεροδιδασκαλεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2085",
    gloss: "to teach heresy",
  },
  ἑτεροδιδασκαλεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2085",
    gloss: "to teach heresy",
  },
  ἑτεροζυγοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2086",
    gloss: "to yoke a mismatch",
  },
  ἕτερα: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G2087",
    gloss: "other",
  },
  ἑτέρᾳ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G2087",
    gloss: "other",
  },
  ἑτέρα: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G2087",
    gloss: "other",
  },
  ἕτεραι: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G2087",
    gloss: "other",
  },
  ἑτέραις: {
    parse: "A-DPF｜Adjective, dative, plural, feminine",
    GN: "G2087",
    gloss: "other",
  },
  ἑτέραν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G2087",
    gloss: "other",
  },
  ἑτέρας: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G2087",
    gloss: "other",
  },
  ἕτεροι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G2087",
    gloss: "other",
  },
  ἑτέροις: {
    parse: "A-DPN｜Adjective, dative, plural, neuter",
    GN: "G2087",
    gloss: "other",
  },
  ἕτερον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G2087",
    gloss: "other",
  },
  ἕτερος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2087",
    gloss: "other",
  },
  ἑτέρου: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G2087",
    gloss: "other",
  },
  ἑτέρους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2087",
    gloss: "other",
  },
  ἑτέρῳ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G2087",
    gloss: "other",
  },
  ἑτέρων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G2087",
    gloss: "other",
  },
  ἑτέρως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2088",
    gloss: "differently",
  },
  ἔτι: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2089",
    gloss: "still",
  },
  Ἔτι: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2089",
    gloss: "still",
  },
  ἑτοίμαζέ: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G2090",
    gloss: "to make ready",
  },
  ἑτοιμάσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2090",
    gloss: "to make ready",
  },
  ἑτοιμάσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G2090",
    gloss: "to make ready",
  },
  ἑτοιμάσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G2090",
    gloss: "to make ready",
  },
  Ἑτοιμάσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G2090",
    gloss: "to make ready",
  },
  ἑτοιμασθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G2090",
    gloss: "to make ready",
  },
  Ἑτοίμασον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G2090",
    gloss: "to make ready",
  },
  ἑτοιμάσω: {
    parse: "V-AAS-1S｜Verb, aorist, active, subjunctive, first, singular",
    GN: "G2090",
    gloss: "to make ready",
  },
  ἑτοιμάσωμεν: {
    parse: "V-AAS-1P｜Verb, aorist, active, subjunctive, first, plural",
    GN: "G2090",
    gloss: "to make ready",
  },
  ἑτοιμάσωμέν: {
    parse: "V-AAS-1P｜Verb, aorist, active, subjunctive, first, plural",
    GN: "G2090",
    gloss: "to make ready",
  },
  ἡτοίμακα: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G2090",
    gloss: "to make ready",
  },
  ἡτοίμασαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2090",
    gloss: "to make ready",
  },
  ἡτοίμασας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G2090",
    gloss: "to make ready",
  },
  ἡτοίμασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2090",
    gloss: "to make ready",
  },
  ἡτοιμασμένην: {
    parse:
      "V-RPP-ASF｜Verb, perfect, passive, Participle, accusative, singular, feminine",
    GN: "G2090",
    gloss: "to make ready",
  },
  ἡτοιμασμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G2090",
    gloss: "to make ready",
  },
  ἡτοιμασμένοις: {
    parse:
      "V-RPP-DPM｜Verb, perfect, passive, Participle, dative, plural, masculine",
    GN: "G2090",
    gloss: "to make ready",
  },
  ἡτοιμασμένον: {
    parse:
      "V-RPP-NSN｜Verb, perfect, passive, Participle, nominative, singular, neuter",
    GN: "G2090",
    gloss: "to make ready",
  },
  ἡτοίμασται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G2090",
    gloss: "to make ready",
  },
  ἑτοιμασίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2091",
    gloss: "readiness",
  },
  ἕτοιμα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G2092",
    gloss: "ready",
  },
  ἕτοιμά: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G2092",
    gloss: "ready",
  },
  ἑτοίμην: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G2092",
    gloss: "ready",
  },
  ἕτοιμοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G2092",
    gloss: "ready",
  },
  ἕτοιμοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G2092",
    gloss: "ready",
  },
  ἕτοιμον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G2092",
    gloss: "ready",
  },
  ἕτοιμος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2092",
    gloss: "ready",
  },
  ἕτοιμός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2092",
    gloss: "ready",
  },
  ἑτοίμους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2092",
    gloss: "ready",
  },
  ἑτοίμῳ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G2092",
    gloss: "ready",
  },
  ἑτοίμως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2093",
    gloss: "readily",
  },
  ἔτει: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G2094",
    gloss: "year",
  },
  ἔτεσι: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G2094",
    gloss: "year",
  },
  ἔτεσιν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G2094",
    gloss: "year",
  },
  ἔτη: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G2094",
    gloss: "year",
  },
  ἔτος: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G2094",
    gloss: "year",
  },
  ἐτῶν: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G2094",
    gloss: "year",
  },
  εὖ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2095",
    gloss: "well/well done!",
  },
  Εὖ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2095",
    gloss: "well/well done!",
  },
  Εὕα: {
    parse: "N-NSF-P｜Noun, nominative, singular, feminine, Person",
    GN: "G2096",
    gloss: "Eve",
  },
  Εὕαν: {
    parse: "N-ASF-P｜Noun, accusative, singular, feminine, Person",
    GN: "G2096",
    gloss: "Eve",
  },
  εὐαγγελίζεσθαι: {
    parse: "V-PMN｜Verb, present, middle, Infinitive",
    GN: "G2097",
    gloss: "to speak good news",
  },
  εὐαγγελίζεται: {
    parse:
      "V-PEI-3S｜Verb, present, Either middle or passive, indicative, third, singular",
    GN: "G2097",
    gloss: "to speak good news",
  },
  εὐαγγελίζηται: {
    parse: "V-PMS-3S｜Verb, present, middle, subjunctive, third, singular",
    GN: "G2097",
    gloss: "to speak good news",
  },
  εὐαγγελίζομαι: {
    parse: "V-PMI-1S｜Verb, present, middle, indicative, first, singular",
    GN: "G2097",
    gloss: "to speak good news",
  },
  εὐαγγελιζόμεθα: {
    parse: "V-PMI-1P｜Verb, present, middle, indicative, first, plural",
    GN: "G2097",
    gloss: "to speak good news",
  },
  εὐαγγελιζόμενοι: {
    parse:
      "V-PMP-NPM｜Verb, present, middle, Participle, nominative, plural, masculine",
    GN: "G2097",
    gloss: "to speak good news",
  },
  εὐαγγελιζόμενος: {
    parse:
      "V-PMP-NSM｜Verb, present, middle, Participle, nominative, singular, masculine",
    GN: "G2097",
    gloss: "to speak good news",
  },
  εὐαγγελιζομένου: {
    parse:
      "V-PMP-GSM｜Verb, present, middle, Participle, genitive, singular, masculine",
    GN: "G2097",
    gloss: "to speak good news",
  },
  εὐαγγελιζομένῳ: {
    parse:
      "V-PMP-DSM｜Verb, present, middle, Participle, dative, singular, masculine",
    GN: "G2097",
    gloss: "to speak good news",
  },
  εὐαγγελιζομένων: {
    parse:
      "V-PMP-GPM｜Verb, present, middle, Participle, genitive, plural, masculine",
    GN: "G2097",
    gloss: "to speak good news",
  },
  εὐαγγελίζονται: {
    parse: "V-PPI-3P｜Verb, present, passive, indicative, third, plural",
    GN: "G2097",
    gloss: "to speak good news",
  },
  εὐαγγελίζωμαι: {
    parse: "V-PMS-1S｜Verb, present, middle, subjunctive, first, singular",
    GN: "G2097",
    gloss: "to speak good news",
  },
  εὐαγγελίσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2097",
    gloss: "to speak good news",
  },
  Εὐαγγελισάμενοί: {
    parse:
      "V-AMP-NPM｜Verb, aorist, middle, Participle, nominative, plural, masculine",
    GN: "G2097",
    gloss: "to speak good news",
  },
  εὐαγγελισαμένου: {
    parse:
      "V-AMP-GSM｜Verb, aorist, middle, Participle, genitive, singular, masculine",
    GN: "G2097",
    gloss: "to speak good news",
  },
  εὐαγγελισαμένων: {
    parse:
      "V-AMP-GPM｜Verb, aorist, middle, Participle, genitive, plural, masculine",
    GN: "G2097",
    gloss: "to speak good news",
  },
  εὐαγγελίσασθαι: {
    parse: "V-AMN｜Verb, aorist, middle, Infinitive",
    GN: "G2097",
    gloss: "to speak good news",
  },
  εὐαγγελίσασθαί: {
    parse: "V-AMN｜Verb, aorist, middle, Infinitive",
    GN: "G2097",
    gloss: "to speak good news",
  },
  εὐαγγελισθὲν: {
    parse:
      "V-APP-NSN｜Verb, aorist, passive, Participle, nominative, singular, neuter",
    GN: "G2097",
    gloss: "to speak good news",
  },
  εὐαγγελισθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G2097",
    gloss: "to speak good news",
  },
  εὐαγγελίσωμαι: {
    parse: "V-AMS-1S｜Verb, aorist, middle, subjunctive, first, singular",
    GN: "G2097",
    gloss: "to speak good news",
  },
  εὐηγγελίζετο: {
    parse: "V-IMI-3S｜Verb, imperfect, middle, indicative, third, singular",
    GN: "G2097",
    gloss: "to speak good news",
  },
  εὐηγγελίζοντο: {
    parse: "V-IMI-3P｜Verb, imperfect, middle, indicative, third, plural",
    GN: "G2097",
    gloss: "to speak good news",
  },
  εὐηγγελισάμεθα: {
    parse: "V-AMI-1P｜Verb, aorist, middle, indicative, first, plural",
    GN: "G2097",
    gloss: "to speak good news",
  },
  εὐηγγελισάμην: {
    parse: "V-AMI-1S｜Verb, aorist, middle, indicative, first, singular",
    GN: "G2097",
    gloss: "to speak good news",
  },
  εὐηγγελίσατο: {
    parse: "V-AMI-3S｜Verb, aorist, middle, indicative, third, singular",
    GN: "G2097",
    gloss: "to speak good news",
  },
  εὐηγγέλισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2097",
    gloss: "to speak good news",
  },
  εὐηγγελίσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2097",
    gloss: "to speak good news",
  },
  εὐηγγελισμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G2097",
    gloss: "to speak good news",
  },
  εὐαγγέλιον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2098",
    gloss: "gospel",
  },
  εὐαγγέλιόν: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G2098",
    gloss: "gospel",
  },
  εὐαγγελίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G2098",
    gloss: "gospel",
  },
  εὐαγγελίῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G2098",
    gloss: "gospel",
  },
  εὐαγγελιστάς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G2099",
    gloss: "evangelist",
  },
  εὐαγγελιστοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2099",
    gloss: "evangelist",
  },
  εὐαρεστεῖται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G2100",
    gloss: "to please",
  },
  εὐαρεστηκέναι: {
    parse: "V-RAN｜Verb, perfect, active, Infinitive",
    GN: "G2100",
    gloss: "to please",
  },
  εὐαρεστῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2100",
    gloss: "to please",
  },
  εὐάρεστοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G2101",
    gloss: "well-pleasing",
  },
  εὐάρεστον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G2101",
    gloss: "well-pleasing",
  },
  εὐάρεστόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G2101",
    gloss: "well-pleasing",
  },
  εὐάρεστος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2101",
    gloss: "well-pleasing",
  },
  εὐαρέστους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2101",
    gloss: "well-pleasing",
  },
  εὐαρέστως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2102",
    gloss: "acceptably",
  },
  Εὔβουλος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2103",
    gloss: "Eubulus",
  },
  εὐγενεῖς: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G2104",
    gloss: "of noble birth",
  },
  εὐγενέστεροι: {
    parse: "A-NPM-C｜Adjective, nominative, plural, masculine, Comparative",
    GN: "G2104",
    gloss: "of noble birth",
  },
  εὐγενὴς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2104",
    gloss: "of noble birth",
  },
  Εὐδία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2105",
    gloss: "fair weather",
  },
  εὐδοκεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2106",
    gloss: "to delight",
  },
  εὐδόκησα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G2106",
    gloss: "to delight",
  },
  εὐδοκήσαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G2106",
    gloss: "to delight",
  },
  εὐδόκησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2106",
    gloss: "to delight",
  },
  εὐδοκήσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G2106",
    gloss: "to delight",
  },
  εὐδόκησας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G2106",
    gloss: "to delight",
  },
  εὐδόκησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2106",
    gloss: "to delight",
  },
  εὐδοκοῦμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G2106",
    gloss: "to delight",
  },
  εὐδοκῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G2106",
    gloss: "to delight",
  },
  εὐδοκία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2107",
    gloss: "goodwill",
  },
  εὐδοκίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2107",
    gloss: "goodwill",
  },
  εὐδοκίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2107",
    gloss: "goodwill",
  },
  εὐεργεσίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2108",
    gloss: "good deed",
  },
  εὐεργεσίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2108",
    gloss: "good deed",
  },
  εὐεργετῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2109",
    gloss: "to do good",
  },
  εὐεργέται: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G2110",
    gloss: "benefactor",
  },
  εὔθετον: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G2111",
    gloss: "suitable",
  },
  εὔθετόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G2111",
    gloss: "suitable",
  },
  εὔθετός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2111",
    gloss: "suitable",
  },
  εὐθέως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2112",
    gloss: "immediately",
  },
  Εὐθέως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2112",
    gloss: "immediately",
  },
  εὐθύς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2112",
    gloss: "immediately",
  },
  εὐθὺς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2112",
    gloss: "immediately",
  },
  εὐθυδρομήσαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G2113",
    gloss: "to sail straight",
  },
  εὐθυδρομήσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G2113",
    gloss: "to sail straight",
  },
  εὐθυμεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2114",
    gloss: "be cheerful",
  },
  εὐθυμεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2114",
    gloss: "be cheerful",
  },
  εὐθυμεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G2114",
    gloss: "be cheerful",
  },
  εὔθυμοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G2115",
    gloss: "cheerful",
  },
  εὐθύμως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2115",
    gloss: "cheerful",
  },
  Εὐθύνατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G2116",
    gloss: "to straighten",
  },
  εὐθύνοντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G2116",
    gloss: "to straighten",
  },
  εὐθεῖα: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G2117",
    gloss: "Straight",
  },
  εὐθεῖαν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G2117",
    gloss: "Straight",
  },
  Εὐθεῖαν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G2117",
    gloss: "Straight",
  },
  εὐθείας: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G2117",
    gloss: "Straight",
  },
  εὐθύτητος: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2118",
    gloss: "righteousness",
  },
  εὐκαιρήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G2119",
    gloss: "to have opportunity",
  },
  εὐκαίρουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2119",
    gloss: "to have opportunity",
  },
  ηὐκαίρουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2119",
    gloss: "to have opportunity",
  },
  εὐκαιρίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2120",
    gloss: "opportunity",
  },
  εὔκαιρον: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G2121",
    gloss: "opportune",
  },
  εὐκαίρου: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G2121",
    gloss: "opportune",
  },
  εὐκαίρως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2122",
    gloss: "well timed",
  },
  εὐκοπώτερον: {
    parse: "A-NSN-C｜Adjective, nominative, singular, neuter, Comparative",
    GN: "G2123",
    gloss: "easy",
  },
  Εὐκοπώτερον: {
    parse: "A-NSN-C｜Adjective, nominative, singular, neuter, Comparative",
    GN: "G2123",
    gloss: "easy",
  },
  εὐκοπώτερόν: {
    parse: "A-NSN-C｜Adjective, nominative, singular, neuter, Comparative",
    GN: "G2123",
    gloss: "easy",
  },
  εὐλαβείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2124",
    gloss: "reverence",
  },
  εὐλαβηθεὶς: {
    parse:
      "V-AOP-NSM｜Verb, aorist, Passive deponent, Participle, nominative, singular, masculine",
    GN: "G2125",
    gloss: "to revere",
  },
  εὐλαβεῖς: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G2126",
    gloss: "devout",
  },
  εὐλαβής: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2126",
    gloss: "devout",
  },
  εὐλαβὴς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2126",
    gloss: "devout",
  },
  εὐλογεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2127",
    gloss: "to praise/bless",
  },
  εὐλογεῖται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G2127",
    gloss: "to praise/bless",
  },
  εὐλογεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G2127",
    gloss: "to praise/bless",
  },
  εὐλόγηκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G2127",
    gloss: "to praise/bless",
  },
  Εὐλογημένη: {
    parse:
      "V-RPP-NSF｜Verb, perfect, passive, Participle, nominative, singular, feminine",
    GN: "G2127",
    gloss: "to praise/bless",
  },
  εὐλογημένοι: {
    parse:
      "V-RPP-VPM｜Verb, perfect, passive, Participle, Vocative or nominative, plural, masculine",
    GN: "G2127",
    gloss: "to praise/bless",
  },
  εὐλογημένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G2127",
    gloss: "to praise/bless",
  },
  Εὐλογημένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G2127",
    gloss: "to praise/bless",
  },
  εὐλογῇς: {
    parse: "V-PAS-2S｜Verb, present, active, subjunctive, second, singular",
    GN: "G2127",
    gloss: "to praise/bless",
  },
  εὐλογήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G2127",
    gloss: "to praise/bless",
  },
  εὐλόγησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2127",
    gloss: "to praise/bless",
  },
  εὐλογήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G2127",
    gloss: "to praise/bless",
  },
  εὐλογοῦμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G2127",
    gloss: "to praise/bless",
  },
  εὐλογοῦντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G2127",
    gloss: "to praise/bless",
  },
  εὐλογοῦνται: {
    parse: "V-PPI-3P｜Verb, present, passive, indicative, third, plural",
    GN: "G2127",
    gloss: "to praise/bless",
  },
  εὐλογοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2127",
    gloss: "to praise/bless",
  },
  εὐλογῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2127",
    gloss: "to praise/bless",
  },
  εὐλογητὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2128",
    gloss: "praiseworthy",
  },
  Εὐλογητὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2128",
    gloss: "praiseworthy",
  },
  εὐλογητοῦ: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G2128",
    gloss: "praiseworthy",
  },
  εὐλογίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2129",
    gloss: "praise",
  },
  εὐλογία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2129",
    gloss: "praise",
  },
  εὐλογίαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G2129",
    gloss: "praise",
  },
  εὐλογίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2129",
    gloss: "praise",
  },
  εὐλογίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2129",
    gloss: "praise",
  },
  εὐμεταδότους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2130",
    gloss: "generous",
  },
  Εὐνίκῃ: {
    parse: "N-DSF-P｜Noun, dative, singular, feminine, Person",
    GN: "G2131",
    gloss: "Eunice",
  },
  εὐνοῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2132",
    gloss: "to reconcile",
  },
  εὐνοίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2133",
    gloss: "enthusiasm",
  },
  εὐνούχισαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2134",
    gloss: "to become a eunuch",
  },
  εὐνουχίσθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G2134",
    gloss: "to become a eunuch",
  },
  εὐνοῦχοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G2135",
    gloss: "eunuch",
  },
  εὐνοῦχος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2135",
    gloss: "eunuch",
  },
  Εὐοδίαν: {
    parse: "N-ASF-P｜Noun, accusative, singular, feminine, Person",
    GN: "G2136",
    gloss: "Euodia",
  },
  εὐοδοῦσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G2137",
    gloss: "to get along well",
  },
  εὐοδοῦταί: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G2137",
    gloss: "to get along well",
  },
  εὐοδωθήσομαι: {
    parse: "V-FPI-1S｜Verb, future, passive, indicative, first, singular",
    GN: "G2137",
    gloss: "to get along well",
  },
  εὐοδῶται: {
    parse: "V-PPS-3S｜Verb, present, passive, subjunctive, third, singular",
    GN: "G2137",
    gloss: "to get along well",
  },
  εὐπειθής: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G2138",
    gloss: "compliant",
  },
  εὐπερίστατον: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G2139",
    gloss: "thwarting",
  },
  εὐποιΐας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2140",
    gloss: "doing good",
  },
  εὐπορεῖτό: {
    parse: "V-IMI-3S｜Verb, imperfect, middle, indicative, third, singular",
    GN: "G2141",
    gloss: "be prosperous",
  },
  εὐπορία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2142",
    gloss: "prosperity",
  },
  εὐπρέπεια: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2143",
    gloss: "beauty",
  },
  εὐπρόσδεκτος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2144",
    gloss: "acceptable",
  },
  εὐπροσδέκτους: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G2144",
    gloss: "acceptable",
  },
  εὐπάρεδρον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G2145",
    gloss: "devoted",
  },
  εὐπροσωπῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2146",
    gloss: "to impress",
  },
  εὕραμεν: {
    parse: "V-2AAI-1P｜Verb, 2nd aorist, active, indicative, first, plural",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὑράμενος: {
    parse:
      "V-2AMP-NSM｜Verb, 2nd aorist, middle, Participle, nominative, singular, masculine",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὑρεθεὶς: {
    parse:
      "V-2APP-NSM｜Verb, 2nd aorist, passive, Participle, nominative, singular, masculine",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὑρέθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὑρεθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὑρέθημεν: {
    parse: "V-2API-1P｜Verb, 2nd aorist, passive, indicative, first, plural",
    GN: "G2147",
    gloss: "to find/meet",
  },
  Εὑρέθην: {
    parse: "V-2API-1S｜Verb, 2nd aorist, passive, indicative, first, singular",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὑρεθῆναι: {
    parse: "V-2APN｜Verb, 2nd aorist, passive, Infinitive",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὑρέθησαν: {
    parse: "V-2API-3P｜Verb, 2nd aorist, passive, indicative, third, plural",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὑρεθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὑρεθησόμεθα: {
    parse: "V-FPI-1P｜Verb, future, passive, indicative, first, plural",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὑρεθῆτε: {
    parse: "V-APS-2P｜Verb, aorist, passive, subjunctive, second, plural",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὑρεθῶ: {
    parse: "V-APS-1S｜Verb, aorist, passive, subjunctive, first, singular",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὑρεθῶσιν: {
    parse: "V-APS-3P｜Verb, aorist, passive, subjunctive, third, plural",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὑρεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὗρεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὗρες: {
    parse: "V-2AAI-2S｜Verb, 2nd aorist, active, indicative, second, singular",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὕρῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὕρηκα: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὕρηκά: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὑρήκαμεν: {
    parse: "V-RAI-1P｜Verb, perfect, active, indicative, first, plural",
    GN: "G2147",
    gloss: "to find/meet",
  },
  Εὑρήκαμεν: {
    parse: "V-RAI-1P｜Verb, perfect, active, indicative, first, plural",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὑρηκέναι: {
    parse: "V-RAN｜Verb, perfect, active, Infinitive",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὑρήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὑρήσεις: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὑρήσετε: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὑρήσετέ: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὑρήσομεν: {
    parse: "V-FAI-1P｜Verb, future, active, indicative, first, plural",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὑρήσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὕρητε: {
    parse: "V-2AAS-2P｜Verb, 2nd aorist, active, subjunctive, second, plural",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὑρίσκει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὑρισκόμεθα: {
    parse: "V-PPI-1P｜Verb, present, passive, indicative, first, plural",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὑρίσκομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὕρισκον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὑρίσκον: {
    parse:
      "V-PAP-NSN｜Verb, present, active, Participle, nominative, singular, neuter",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὑρίσκοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὑρίσκω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G2147",
    gloss: "to find/meet",
  },
  Εὑρίσκω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὕροιεν: {
    parse: "V-2AAO-3P｜Verb, 2nd aorist, active, Optative, third, plural",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὕρομεν: {
    parse: "V-2AAI-1P｜Verb, 2nd aorist, active, indicative, first, plural",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὗρον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G2147",
    gloss: "to find/meet",
  },
  Εὗρον: {
    parse:
      "V-2AAI-1S｜Verb, 2nd aorist, active, indicative, first, singular OR third, plural",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὗρόν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὑρόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὑροῦσα: {
    parse:
      "V-2AAP-NSF｜Verb, 2nd aorist, active, Participle, nominative, singular, feminine",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὑροῦσαι: {
    parse:
      "V-2AAP-NPF｜Verb, 2nd aorist, active, Participle, nominative, plural, feminine",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὕρω: {
    parse: "V-2AAS-1S｜Verb, 2nd aorist, active, subjunctive, first, singular",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὕρωμεν: {
    parse: "V-2AAS-1P｜Verb, 2nd aorist, active, subjunctive, first, plural",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὑρών: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὑρὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G2147",
    gloss: "to find/meet",
  },
  εὕρωσιν: {
    parse: "V-2AAS-3P｜Verb, 2nd aorist, active, subjunctive, third, plural",
    GN: "G2147",
    gloss: "to find/meet",
  },
  ηὑρίσκετο: {
    parse: "V-IPI-3S｜Verb, imperfect, passive, indicative, third, singular",
    GN: "G2147",
    gloss: "to find/meet",
  },
  ηὕρισκον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2147",
    gloss: "to find/meet",
  },
  Εὐρακύλων: {
    parse: "N-NSM-T｜Noun, nominative, singular, masculine, Title",
    GN: "G2148",
    gloss: "a north wind",
  },
  εὐρύχωρος: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G2149",
    gloss: "broad",
  },
  εὐσεβείᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2150",
    gloss: "piety",
  },
  εὐσέβεια: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2150",
    gloss: "piety",
  },
  εὐσεβείαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G2150",
    gloss: "piety",
  },
  εὐσέβειαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2150",
    gloss: "piety",
  },
  εὐσεβείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2150",
    gloss: "piety",
  },
  εὐσεβεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2151",
    gloss: "to show piety",
  },
  εὐσεβεῖτε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G2151",
    gloss: "to show piety",
  },
  εὐσεβεῖς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2152",
    gloss: "pious",
  },
  εὐσεβῆ: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G2152",
    gloss: "pious",
  },
  εὐσεβὴς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2152",
    gloss: "pious",
  },
  εὐσεβῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2153",
    gloss: "piously",
  },
  εὔσημον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G2154",
    gloss: "intelligible",
  },
  εὔσπλαγχνοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G2155",
    gloss: "compassionate",
  },
  εὐσχημόνως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2156",
    gloss: "properly",
  },
  εὐσχημοσύνην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2157",
    gloss: "propriety",
  },
  εὔσχημον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G2158",
    gloss: "proper",
  },
  εὐσχήμονα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G2158",
    gloss: "proper",
  },
  εὐσχήμονας: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G2158",
    gloss: "proper",
  },
  εὐσχημόνων: {
    parse: "A-GPF｜Adjective, genitive, plural, feminine",
    GN: "G2158",
    gloss: "proper",
  },
  εὐσχήμων: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2158",
    gloss: "proper",
  },
  εὐτόνως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2159",
    gloss: "vehemently",
  },
  εὐτραπελία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2160",
    gloss: "vulgar jesting",
  },
  Εὔτυχος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2161",
    gloss: "Eutychus",
  },
  εὐφημίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2162",
    gloss: "good report",
  },
  εὔφημα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G2163",
    gloss: "commendable",
  },
  εὐφόρησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2164",
    gloss: "be fruitful",
  },
  εὐφραίνεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G2165",
    gloss: "to celebrate",
  },
  εὐφραίνεσθε: {
    parse: "V-PPM-2P｜Verb, present, passive, imperative, second, plural",
    GN: "G2165",
    gloss: "to celebrate",
  },
  εὐφραινόμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G2165",
    gloss: "to celebrate",
  },
  εὐφραίνονται: {
    parse: "V-PPI-3P｜Verb, present, passive, indicative, third, plural",
    GN: "G2165",
    gloss: "to celebrate",
  },
  εὐφραίνοντο: {
    parse: "V-IPI-3P｜Verb, imperfect, passive, indicative, third, plural",
    GN: "G2165",
    gloss: "to celebrate",
  },
  εὐφραίνου: {
    parse: "V-PPM-2S｜Verb, present, passive, imperative, second, singular",
    GN: "G2165",
    gloss: "to celebrate",
  },
  Εὐφραίνου: {
    parse: "V-PPM-2S｜Verb, present, passive, imperative, second, singular",
    GN: "G2165",
    gloss: "to celebrate",
  },
  εὐφραίνων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2165",
    gloss: "to celebrate",
  },
  εὐφρανθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G2165",
    gloss: "to celebrate",
  },
  Εὐφράνθητε: {
    parse: "V-APM-2P｜Verb, aorist, passive, imperative, second, plural",
    GN: "G2165",
    gloss: "to celebrate",
  },
  Εὐφράνθητι: {
    parse: "V-APM-2S｜Verb, aorist, passive, imperative, second, singular",
    GN: "G2165",
    gloss: "to celebrate",
  },
  εὐφρανθῶ: {
    parse: "V-APS-1S｜Verb, aorist, passive, subjunctive, first, singular",
    GN: "G2165",
    gloss: "to celebrate",
  },
  εὐφρανθῶμεν: {
    parse: "V-APS-1P｜Verb, aorist, passive, subjunctive, first, plural",
    GN: "G2165",
    gloss: "to celebrate",
  },
  ηὐφράνθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2165",
    gloss: "to celebrate",
  },
  Εὐφράτῃ: {
    parse: "N-DSM-L｜Noun, dative, singular, masculine, Location",
    GN: "G2166",
    gloss: "Euphrates",
  },
  Εὐφράτην: {
    parse: "N-ASM-L｜Noun, accusative, singular, masculine, Location",
    GN: "G2166",
    gloss: "Euphrates",
  },
  εὐφροσύνης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2167",
    gloss: "joy",
  },
  εὐχαριστεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2168",
    gloss: "to thank",
  },
  εὐχαριστεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2168",
    gloss: "to thank",
  },
  Εὐχαριστεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2168",
    gloss: "to thank",
  },
  εὐχαριστεῖς: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G2168",
    gloss: "to thank",
  },
  εὐχαριστεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G2168",
    gloss: "to thank",
  },
  εὐχαριστηθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G2168",
    gloss: "to thank",
  },
  εὐχαριστήσαντος: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G2168",
    gloss: "to thank",
  },
  εὐχαριστήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G2168",
    gloss: "to thank",
  },
  εὐχαρίστησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2168",
    gloss: "to thank",
  },
  εὐχαριστοῦμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G2168",
    gloss: "to thank",
  },
  Εὐχαριστοῦμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G2168",
    gloss: "to thank",
  },
  Εὐχαριστοῦμέν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G2168",
    gloss: "to thank",
  },
  εὐχαριστοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2168",
    gloss: "to thank",
  },
  εὐχαριστῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G2168",
    gloss: "to thank",
  },
  Εὐχαριστῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G2168",
    gloss: "to thank",
  },
  εὐχαριστῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2168",
    gloss: "to thank",
  },
  ηὐχαρίστησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2168",
    gloss: "to thank",
  },
  εὐχαριστίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2169",
    gloss: "thankfulness",
  },
  εὐχαριστία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2169",
    gloss: "thankfulness",
  },
  εὐχαριστίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2169",
    gloss: "thankfulness",
  },
  εὐχαριστίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2169",
    gloss: "thankfulness",
  },
  εὐχαριστιῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G2169",
    gloss: "thankfulness",
  },
  εὐχάριστοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G2170",
    gloss: "thankful",
  },
  εὐχὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2171",
    gloss: "a vow/prayer",
  },
  εὐχήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2171",
    gloss: "a vow/prayer",
  },
  εὐχὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2171",
    gloss: "a vow/prayer",
  },
  Εὐξαίμην: {
    parse: "V-ADO-1S｜Verb, aorist, Middle deponent, Optative, first, singular",
    GN: "G2172",
    gloss: "to pray/wish for",
  },
  εὔχεσθε: {
    parse:
      "V-PNM-2P｜Verb, present, Middle or Passive deponent, imperative, second, plural",
    GN: "G2172",
    gloss: "to pray/wish for",
  },
  εὔχομαί: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G2172",
    gloss: "to pray/wish for",
  },
  εὐχόμεθα: {
    parse:
      "V-PNI-1P｜Verb, present, Middle or Passive deponent, indicative, first, plural",
    GN: "G2172",
    gloss: "to pray/wish for",
  },
  ηὐχόμην: {
    parse:
      "V-INI-1S｜Verb, imperfect, Middle or Passive deponent, indicative, first, singular",
    GN: "G2172",
    gloss: "to pray/wish for",
  },
  ηὔχοντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G2172",
    gloss: "to pray/wish for",
  },
  εὔχρηστον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G2173",
    gloss: "helpful",
  },
  εὔχρηστος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2173",
    gloss: "helpful",
  },
  εὐψυχῶ: {
    parse: "V-PAS-1S｜Verb, present, active, subjunctive, first, singular",
    GN: "G2174",
    gloss: "be glad",
  },
  εὐωδία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2175",
    gloss: "aroma",
  },
  εὐωδίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2175",
    gloss: "aroma",
  },
  εὐώνυμον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G2176",
    gloss: "left/south",
  },
  εὐωνύμων: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G2176",
    gloss: "left/south",
  },
  ἐφαλόμενος: {
    parse:
      "V-ADP-NSM｜Verb, aorist, Middle deponent, Participle, nominative, singular, masculine",
    GN: "G2177",
    gloss: "to jump on",
  },
  ἐφάπαξ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2178",
    gloss: "once/at once",
  },
  Ἐφέσιοι: {
    parse: "A-VPM｜Adjective, Vocative or nominative, plural, masculine",
    GN: "G2180",
    gloss: "Ephesian",
  },
  Ἐφέσιον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G2180",
    gloss: "Ephesian",
  },
  Ἐφεσίων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G2180",
    gloss: "Ephesian",
  },
  Ἔφεσον: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G2181",
    gloss: "Ephesus",
  },
  Ἐφέσου: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G2181",
    gloss: "Ephesus",
  },
  Ἐφέσῳ: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G2181",
    gloss: "Ephesus",
  },
  ἐφευρετὰς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G2182",
    gloss: "inventor",
  },
  ἐφημερίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2183",
    gloss: "division",
  },
  ἐφημέρου: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G2184",
    gloss: "daily",
  },
  ἐφικέσθαι: {
    parse: "V-2ADN｜Verb, 2nd aorist, Middle deponent, Infinitive",
    GN: "G2185",
    gloss: "to reach",
  },
  ἐφικνούμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G2185",
    gloss: "to reach",
  },
  ἐπέστη: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G2186",
    gloss: "to approach",
  },
  ἐπέστησαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G2186",
    gloss: "to approach",
  },
  ἐπιστάντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G2186",
    gloss: "to approach",
  },
  ἐπιστὰς: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G2186",
    gloss: "to approach",
  },
  ἐπιστᾶσα: {
    parse:
      "V-2AAP-NSF｜Verb, 2nd aorist, active, Participle, nominative, singular, feminine",
    GN: "G2186",
    gloss: "to approach",
  },
  ἐπιστῇ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G2186",
    gloss: "to approach",
  },
  ἐπίστηθι: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G2186",
    gloss: "to approach",
  },
  ἐφέστηκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G2186",
    gloss: "to approach",
  },
  ἐφεστὼς: {
    parse:
      "V-RAP-NSM｜Verb, perfect, active, Participle, nominative, singular, masculine",
    GN: "G2186",
    gloss: "to approach",
  },
  ἐφεστῶτα: {
    parse:
      "V-RAP-ASM｜Verb, perfect, active, Participle, accusative, singular, masculine",
    GN: "G2186",
    gloss: "to approach",
  },
  ἐφίσταται: {
    parse: "V-PMI-3S｜Verb, present, middle, indicative, third, singular",
    GN: "G2186",
    gloss: "to approach",
  },
  Ἐφραὶμ: {
    parse: "N-ASM-L｜Noun, accusative, singular, masculine, Location",
    GN: "G2187",
    gloss: "Ephraim",
  },
  Ἐφφαθά: {
    parse: "V-APM-2S｜Verb, aorist, passive, imperative, second, singular",
    GN: "G2188",
    gloss: "open!",
  },
  ἔχθρᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2189",
    gloss: "hostility",
  },
  ἔχθρα: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2189",
    gloss: "hostility",
  },
  ἔχθραι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G2189",
    gloss: "hostility",
  },
  ἔχθραν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2189",
    gloss: "hostility",
  },
  ἐχθρὲ: {
    // or vocative
    parse: "A-VSM｜Adjective, Vocative, singular, masculine",
    GN: "G2190",
    gloss: "enemy",
  },
  ἐχθροί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G2190",
    gloss: "enemy",
  },
  ἐχθροὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G2190",
    gloss: "enemy",
  },
  ἐχθρόν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G2190",
    gloss: "enemy",
  },
  ἐχθρὸν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G2190",
    gloss: "enemy",
  },
  ἐχθρός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2190",
    gloss: "enemy",
  },
  ἐχθρὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2190",
    gloss: "enemy",
  },
  Ἐχθρὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2190",
    gloss: "enemy",
  },
  ἐχθροῦ: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G2190",
    gloss: "enemy",
  },
  ἐχθρούς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2190",
    gloss: "enemy",
  },
  ἐχθροὺς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2190",
    gloss: "enemy",
  },
  ἐχθρῶν: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G2190",
    gloss: "enemy",
  },
  ἔχιδνα: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2191",
    gloss: "snake",
  },
  ἐχιδνῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G2191",
    gloss: "snake",
  },
  εἶχεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G2192",
    gloss: "to have/be",
  },
  Εἶχεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G2192",
    gloss: "to have/be",
  },
  εἶχέν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G2192",
    gloss: "to have/be",
  },
  εἶχες: {
    parse: "V-IAI-2S｜Verb, imperfect, active, indicative, second, singular",
    GN: "G2192",
    gloss: "to have/be",
  },
  εἴχετε: {
    parse: "V-IAI-2P｜Verb, imperfect, active, indicative, second, plural",
    GN: "G2192",
    gloss: "to have/be",
  },
  εἴχομεν: {
    parse: "V-IAI-1P｜Verb, imperfect, active, indicative, first, plural",
    GN: "G2192",
    gloss: "to have/be",
  },
  εἶχον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2192",
    gloss: "to have/be",
  },
  εἴχοσαν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἕξει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἕξεις: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἕξετε: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἕξουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἔσχεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἔσχες: {
    parse: "V-2AAI-2S｜Verb, 2nd aorist, active, indicative, second, singular",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἔσχηκα: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἐσχήκαμεν: {
    parse: "V-RAI-1P｜Verb, perfect, active, indicative, first, plural",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἔσχηκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἐσχηκότα: {
    parse:
      "V-RAP-ASM｜Verb, perfect, active, Participle, accusative, singular, masculine",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἔσχομεν: {
    parse: "V-2AAI-1P｜Verb, 2nd aorist, active, indicative, first, plural",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἔσχον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἔχε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἔχει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἔχειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἔχεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἔχετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G2192",
    gloss: "to have/be",
  },
  Ἔχετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G2192",
    gloss: "to have/be",
  },
  Ἔχετέ: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἐχέτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἔχῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἔχητε: {
    parse: "V-PAS-2P｜Verb, present, active, subjunctive, second, plural",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἔχοι: {
    parse: "V-PAO-3S｜Verb, present, active, Optative, third, singular",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἔχοιεν: {
    parse: "V-PAO-3P｜Verb, present, active, Optative, third, plural",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἔχομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G2192",
    gloss: "to have/be",
  },
  Ἔχομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἐχόμενα: {
    parse:
      "V-PMP-APN｜Verb, present, middle, Participle, accusative, plural, neuter",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἐχομένας: {
    parse:
      "V-PMP-APF｜Verb, present, middle, Participle, accusative, plural, feminine",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἐχομένῃ: {
    parse:
      "V-PMP-DSF｜Verb, present, middle, Participle, dative, singular, feminine",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἔχον: {
    parse:
      "V-PAP-NSN｜Verb, present, active, Participle, nominative, singular, neuter",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἔχοντα: {
    parse:
      "V-PAP-NPN｜Verb, present, active, Participle, nominative, plural, neuter",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἔχοντά: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἔχοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἔχοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2192",
    gloss: "to have/be",
  },
  Ἔχοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἔχοντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἔχοντος: {
    parse:
      "V-PAP-GSN｜Verb, present, active, Participle, genitive, singular, neuter",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἐχόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἔχουσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἔχουσαι: {
    parse:
      "V-PAP-NPF｜Verb, present, active, Participle, nominative, plural, feminine",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἐχούσαις: {
    parse:
      "V-PAP-DPF｜Verb, present, active, Participle, dative, plural, feminine",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἔχουσαν: {
    parse:
      "V-PAP-ASF｜Verb, present, active, Participle, accusative, singular, feminine",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἐχούσῃ: {
    parse:
      "V-PAP-DSF｜Verb, present, active, Participle, dative, singular, feminine",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἐχούσης: {
    parse:
      "V-PAP-GSF｜Verb, present, active, Participle, genitive, singular, feminine",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἔχουσι: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἔχουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2192",
    gloss: "to have/be",
  },
  Ἔχουσι: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2192",
    gloss: "to have/be",
  },
  Ἔχουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἔχω: {
    parse: "V-PAS-1S｜Verb, present, active, subjunctive, first, singular",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἔχωμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἔχων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἔχωσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G2192",
    gloss: "to have/be",
  },
  Εἶχε: {
    parse: "V-PAS-3P｜Verb, imperfect, active, imperative, third, singular",
    GN: "G2192",
    gloss: "to have/be",
  },
  σχῆτε: {
    parse: "V-2AAS-2P｜Verb, 2nd aorist, active, subjunctive, second, plural",
    GN: "G2192",
    gloss: "to have/be",
  },
  σχῶ: {
    parse: "V-2AAS-1S｜Verb, 2nd aorist, active, subjunctive, first, singular",
    GN: "G2192",
    gloss: "to have/be",
  },
  σχῶμεν: {
    parse: "V-2AAS-1P｜Verb, 2nd aorist, active, subjunctive, first, plural",
    GN: "G2192",
    gloss: "to have/be",
  },
  ἕως: { parse: "PREP｜Preposition", GN: "G2193", gloss: "until" },
  Ἕως: { parse: "PREP｜Preposition", GN: "G2193", gloss: "until" },
  Ζαβουλὼν: {
    parse: "N-GSM-L｜Noun, genitive, singular, masculine, Location",
    GN: "G2194",
    gloss: "Zebulun",
  },
  Ζακχαῖε: {
    parse: "N-VSM-P｜Noun, Vocative or nominative, singular, masculine, Person",
    GN: "G2195",
    gloss: "Zacchaeus",
  },
  Ζακχαῖος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2195",
    gloss: "Zacchaeus",
  },
  Ζάρα: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2196",
    gloss: "Zerah",
  },
  Ζαχαρία: {
    parse: "N-VSM-P｜Noun, Vocative or nominative, singular, masculine, Person",
    GN: "G2197",
    gloss: "Zechariah",
  },
  Ζαχαρίαν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2197",
    gloss: "Zechariah",
  },
  Ζαχαρίας: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2197",
    gloss: "Zechariah",
  },
  Ζαχαρίου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2197",
    gloss: "Zechariah",
  },
  ἔζησα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G2198",
    gloss: "to live",
  },
  ἔζησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2198",
    gloss: "to live",
  },
  ἔζησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2198",
    gloss: "to live",
  },
  ἐζῆτε: {
    parse: "V-IAI-2P｜Verb, imperfect, active, indicative, second, plural",
    GN: "G2198",
    gloss: "to live",
  },
  ἔζων: {
    parse: "V-IAI-1S｜Verb, imperfect, active, indicative, first, singular",
    GN: "G2198",
    gloss: "to live",
  },
  ζῇ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2198",
    gloss: "to live",
  },
  ζῆν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2198",
    gloss: "to live",
  },
  ζῇς: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G2198",
    gloss: "to live",
  },
  ζήσασα: {
    parse:
      "V-AAP-NSF｜Verb, aorist, active, Participle, nominative, singular, feminine",
    GN: "G2198",
    gloss: "to live",
  },
  ζήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2198",
    gloss: "to live",
  },
  ζήσεσθε: {
    parse:
      "V-FDI-2P｜Verb, future, Middle deponent, indicative, second, plural",
    GN: "G2198",
    gloss: "to live",
  },
  ζήσεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G2198",
    gloss: "to live",
  },
  ζήσετε: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G2198",
    gloss: "to live",
  },
  ζήσῃ: {
    parse:
      "V-FDI-2S｜Verb, future, Middle deponent, indicative, second, singular",
    GN: "G2198",
    gloss: "to live",
  },
  ζήσομεν: {
    parse: "V-FAI-1P｜Verb, future, active, indicative, first, plural",
    GN: "G2198",
    gloss: "to live",
  },
  ζήσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G2198",
    gloss: "to live",
  },
  ζήσω: {
    parse: "V-AAS-1S｜Verb, aorist, active, subjunctive, first, singular",
    GN: "G2198",
    gloss: "to live",
  },
  ζήσωμεν: {
    parse: "V-AAS-1P｜Verb, aorist, active, subjunctive, first, plural",
    GN: "G2198",
    gloss: "to live",
  },
  ζῆτε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G2198",
    gloss: "to live",
  },
  ζῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G2198",
    gloss: "to live",
  },
  Ζῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G2198",
    gloss: "to live",
  },
  ζῶμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G2198",
    gloss: "to live",
  },
  ζῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2198",
    gloss: "to live",
  },
  Ζῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2198",
    gloss: "to live",
  },
  ζῶντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G2198",
    gloss: "to live",
  },
  ζῶντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G2198",
    gloss: "to live",
  },
  ζῶντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2198",
    gloss: "to live",
  },
  ζῶντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G2198",
    gloss: "to live",
  },
  ζῶντος: {
    parse:
      "V-PAP-GSN｜Verb, present, active, Participle, genitive, singular, neuter",
    GN: "G2198",
    gloss: "to live",
  },
  ζώντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G2198",
    gloss: "to live",
  },
  ζῶσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G2198",
    gloss: "to live",
  },
  ζῶσαν: {
    parse:
      "V-PAP-ASF｜Verb, present, active, Participle, accusative, singular, feminine",
    GN: "G2198",
    gloss: "to live",
  },
  ζῶσι: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G2198",
    gloss: "to live",
  },
  ζῶσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G2198",
    gloss: "to live",
  },
  Ζεβεδαῖον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2199",
    gloss: "[wife of Zebedee]",
  },
  Ζεβεδαίου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2199",
    gloss: "[wife of Zebedee]",
  },
  ζεστός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2200",
    gloss: "hot",
  },
  ζεστὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2200",
    gloss: "hot",
  },
  Ζεύγη: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G2201",
    gloss: "a yoke/pair",
  },
  ζεῦγος: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G2201",
    gloss: "a yoke/pair",
  },
  ζευκτηρίας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G2202",
    gloss: "rope",
  },
  Δία: {
    parse: "N-ASM-T｜Noun, accusative, singular, masculine, Title",
    GN: "G2203",
    gloss: "Zeus",
  },
  Διὸς: {
    parse: "N-GSM-T｜Noun, genitive, singular, masculine, Title",
    GN: "G2203",
    gloss: "Zeus",
  },
  ζέοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2204",
    gloss: "be fervent",
  },
  ζέων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2204",
    gloss: "be fervent",
  },
  ζῆλον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2205",
    gloss: "zeal",
  },
  ζῆλος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2205",
    gloss: "zeal",
  },
  ζήλου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2205",
    gloss: "zeal",
  },
  ζήλῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G2205",
    gloss: "zeal",
  },
  ζηλοῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2206",
    gloss: "be eager",
  },
  ζηλοῦσθαι: {
    parse: "V-PMN｜Verb, present, middle, Infinitive",
    GN: "G2206",
    gloss: "be eager",
  },
  ζηλοῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2206",
    gloss: "be eager",
  },
  ζηλοῦτε: {
    parse: "V-PAS-2P｜Verb, present, active, subjunctive, second, plural",
    GN: "G2206",
    gloss: "be eager",
  },
  ζηλῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G2206",
    gloss: "be eager",
  },
  ζηλώσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G2206",
    gloss: "be eager",
  },
  ζηλωταί: {
    parse: "N-NPM-T｜Noun, nominative, plural, masculine, Title",
    GN: "G2207",
    gloss: "zealot",
  },
  ζηλωταὶ: {
    parse: "N-NPM-T｜Noun, nominative, plural, masculine, Title",
    GN: "G2207",
    gloss: "zealot",
  },
  ζηλωτὴν: {
    parse: "N-ASM-T｜Noun, accusative, singular, masculine, Title",
    GN: "G2207",
    gloss: "zealot",
  },
  Ζηλωτὴν: {
    parse: "N-ASM-T｜Noun, accusative, singular, masculine, Title",
    GN: "G2207",
    gloss: "zealot",
  },
  ζηλωτὴς: {
    parse: "N-NSM-T｜Noun, nominative, singular, masculine, Title",
    GN: "G2207",
    gloss: "zealot",
  },
  ζημίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2209",
    gloss: "damage/loss",
  },
  ζημίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2209",
    gloss: "damage/loss",
  },
  ἐζημιώθην: {
    parse: "V-API-1S｜Verb, aorist, passive, indicative, first, singular",
    GN: "G2210",
    gloss: "to lose",
  },
  ζημιωθείς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G2210",
    gloss: "to lose",
  },
  ζημιωθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G2210",
    gloss: "to lose",
  },
  ζημιωθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G2210",
    gloss: "to lose",
  },
  ζημιωθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G2210",
    gloss: "to lose",
  },
  ζημιωθῆτε: {
    parse: "V-APS-2P｜Verb, aorist, passive, subjunctive, second, plural",
    GN: "G2210",
    gloss: "to lose",
  },
  ζηνᾶν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2211",
    gloss: "Zenas",
  },
  ἐζήτει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G2212",
    gloss: "to seek",
  },
  ἐζητεῖτέ: {
    parse: "V-IAI-2P｜Verb, imperfect, active, indicative, second, plural",
    GN: "G2212",
    gloss: "to seek",
  },
  ἐζητεῖτο: {
    parse: "V-IPI-3S｜Verb, imperfect, passive, indicative, third, singular",
    GN: "G2212",
    gloss: "to seek",
  },
  ἐζητήσαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G2212",
    gloss: "to seek",
  },
  ἐζήτησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2212",
    gloss: "to seek",
  },
  ἐζήτησέν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2212",
    gloss: "to seek",
  },
  ἐζητοῦμέν: {
    parse: "V-IAI-1P｜Verb, imperfect, active, indicative, first, plural",
    GN: "G2212",
    gloss: "to seek",
  },
  ἐζήτουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2212",
    gloss: "to seek",
  },
  ζητεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2212",
    gloss: "to seek",
  },
  ζήτει: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G2212",
    gloss: "to seek",
  },
  ζητεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2212",
    gloss: "to seek",
  },
  ζητεῖς: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G2212",
    gloss: "to seek",
  },
  ζητεῖται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G2212",
    gloss: "to seek",
  },
  ζητεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G2212",
    gloss: "to seek",
  },
  ζητεῖτέ: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G2212",
    gloss: "to seek",
  },
  ζητείτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G2212",
    gloss: "to seek",
  },
  ζητηθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G2212",
    gloss: "to seek",
  },
  ζητῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2212",
    gloss: "to seek",
  },
  ζητησάτω: {
    parse: "V-AAM-3S｜Verb, aorist, active, imperative, third, singular",
    GN: "G2212",
    gloss: "to seek",
  },
  ζητήσετέ: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G2212",
    gloss: "to seek",
  },
  Ζητήσετέ: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G2212",
    gloss: "to seek",
  },
  ζητήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G2212",
    gloss: "to seek",
  },
  ζήτησον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G2212",
    gloss: "to seek",
  },
  ζητήσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G2212",
    gloss: "to seek",
  },
  ζητοῦν: {
    parse:
      "V-PAP-NSN｜Verb, present, active, Participle, nominative, singular, neuter",
    GN: "G2212",
    gloss: "to seek",
  },
  ζητοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2212",
    gloss: "to seek",
  },
  ζητοῦντές: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2212",
    gloss: "to seek",
  },
  ζητοῦντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G2212",
    gloss: "to seek",
  },
  ζητούντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G2212",
    gloss: "to seek",
  },
  ζητοῦσιν: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G2212",
    gloss: "to seek",
  },
  ζητοῦσίν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2212",
    gloss: "to seek",
  },
  ζητῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G2212",
    gloss: "to seek",
  },
  ζητῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2212",
    gloss: "to seek",
  },
  ζητήματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G2213",
    gloss: "a question/dispute",
  },
  ζητήματά: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G2213",
    gloss: "a question/dispute",
  },
  ζητήματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G2213",
    gloss: "a question/dispute",
  },
  ζητημάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G2213",
    gloss: "a question/dispute",
  },
  ζητήσεις: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G2214",
    gloss: "controversy",
  },
  ζητήσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2214",
    gloss: "controversy",
  },
  ζήτησιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2214",
    gloss: "controversy",
  },
  ζήτησις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2214",
    gloss: "controversy",
  },
  ζιζάνια: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G2215",
    gloss: "weed",
  },
  ζιζάνιά: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G2215",
    gloss: "weed",
  },
  ζιζανίων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G2215",
    gloss: "weed",
  },
  Ζοροβαβέλ: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2216",
    gloss: "Zerubbabel",
  },
  Ζοροβαβὲλ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2216",
    gloss: "Zerubbabel",
  },
  ζόφον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2217",
    gloss: "darkness",
  },
  ζόφος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2217",
    gloss: "darkness",
  },
  ζόφου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2217",
    gloss: "darkness",
  },
  ζόφῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G2217",
    gloss: "darkness",
  },
  ζυγόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2218",
    gloss: "yoke/scales",
  },
  ζυγὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2218",
    gloss: "yoke/scales",
  },
  ζυγός: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2218",
    gloss: "yoke/scales",
  },
  ζυγῷ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G2218",
    gloss: "yoke/scales",
  },
  ζύμῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2219",
    gloss: "leaven",
  },
  ζύμη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2219",
    gloss: "leaven",
  },
  ζύμην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2219",
    gloss: "leaven",
  },
  ζύμης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2219",
    gloss: "leaven",
  },
  ἐζυμώθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2220",
    gloss: "to leaven",
  },
  ζυμοῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2220",
    gloss: "to leaven",
  },
  ἐζωγρημένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G2221",
    gloss: "to capture alive",
  },
  ζωγρῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2221",
    gloss: "to capture alive",
  },
  ζωῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2222",
    gloss: "life",
  },
  ζωή: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2222",
    gloss: "life",
  },
  ζωὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2222",
    gloss: "life",
  },
  ζωήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2222",
    gloss: "life",
  },
  ζωὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2222",
    gloss: "life",
  },
  ζωῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2222",
    gloss: "life",
  },
  ζώνας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G2223",
    gloss: "belt/sash/girdle",
  },
  ζώνη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2223",
    gloss: "belt/sash/girdle",
  },
  ζώνην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2223",
    gloss: "belt/sash/girdle",
  },
  ἐζώννυες: {
    parse: "V-IAI-2S｜Verb, imperfect, active, indicative, second, singular",
    GN: "G2224",
    gloss: "to dress",
  },
  Ζῶσαι: {
    parse: "V-AMM-2S｜Verb, aorist, middle, imperative, second, singular",
    GN: "G2224",
    gloss: "to dress",
  },
  ζώσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2224",
    gloss: "to dress",
  },
  ζῳογονεῖσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G2225",
    gloss: "to give life",
  },
  ζῳογονήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2225",
    gloss: "to give life",
  },
  ζῳογονοῦντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G2225",
    gloss: "to give life",
  },
  ζῷα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G2226",
    gloss: "living thing",
  },
  ζῷον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2226",
    gloss: "living thing",
  },
  ζῴου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G2226",
    gloss: "living thing",
  },
  ζῴων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G2226",
    gloss: "living thing",
  },
  ζῳοποιεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2227",
    gloss: "to make alive",
  },
  ζῳοποιεῖται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G2227",
    gloss: "to make alive",
  },
  ζῳοποιηθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G2227",
    gloss: "to make alive",
  },
  ζῳοποιηθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G2227",
    gloss: "to make alive",
  },
  ζῳοποιῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2227",
    gloss: "to make alive",
  },
  ζῳοποιήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2227",
    gloss: "to make alive",
  },
  ζῳοποιοῦν: {
    parse:
      "V-PAP-NSN｜Verb, present, active, Participle, nominative, singular, neuter",
    GN: "G2227",
    gloss: "to make alive",
  },
  ζῳοποιοῦντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G2227",
    gloss: "to make alive",
  },
  ἤ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G2228",
    gloss: "or",
  },
  ἢ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G2228",
    gloss: "or",
  },
  Ἢ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G2228",
    gloss: "or",
  },
  ἡγεμονεύοντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G2230",
    gloss: "to govern",
  },
  ἡγεμονίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2231",
    gloss: "reign",
  },
  ἡγεμόνα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2232",
    gloss: "ruler",
  },
  ἡγεμόνας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G2232",
    gloss: "ruler",
  },
  ἡγεμόνι: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G2232",
    gloss: "ruler",
  },
  ἡγεμόνος: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2232",
    gloss: "ruler",
  },
  ἡγεμόνων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G2232",
    gloss: "ruler",
  },
  ἡγεμόσιν: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G2232",
    gloss: "ruler",
  },
  ἡγεμὼν: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2232",
    gloss: "ruler",
  },
  ἡγεῖσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G2233",
    gloss: "to govern",
  },
  ἡγεῖσθε: {
    parse:
      "V-PNM-2P｜Verb, present, Middle or Passive deponent, imperative, second, plural",
    GN: "G2233",
    gloss: "to govern",
  },
  ἡγείσθωσαν: {
    parse:
      "V-PNM-3P｜Verb, present, Middle or Passive deponent, imperative, third, plural",
    GN: "G2233",
    gloss: "to govern",
  },
  ἥγημαι: {
    parse:
      "V-RNI-1S｜Verb, perfect, Middle or Passive deponent, indicative, first, singular",
    GN: "G2233",
    gloss: "to govern",
  },
  ἡγησάμενος: {
    parse:
      "V-ADP-NSM｜Verb, aorist, Middle deponent, Participle, nominative, singular, masculine",
    GN: "G2233",
    gloss: "to govern",
  },
  ἡγησάμην: {
    parse:
      "V-ADI-1S｜Verb, aorist, Middle deponent, indicative, first, singular",
    GN: "G2233",
    gloss: "to govern",
  },
  ἡγήσασθε: {
    parse:
      "V-ADM-2P｜Verb, aorist, Middle deponent, imperative, second, plural",
    GN: "G2233",
    gloss: "to govern",
  },
  ἡγήσατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G2233",
    gloss: "to govern",
  },
  ἡγοῦμαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G2233",
    gloss: "to govern",
  },
  ἡγούμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G2233",
    gloss: "to govern",
  },
  ἡγουμένοις: {
    parse:
      "V-PNP-DPM｜Verb, present, Middle or Passive deponent, Participle, dative, plural, masculine",
    GN: "G2233",
    gloss: "to govern",
  },
  ἡγούμενον: {
    parse:
      "V-PNP-ASM｜Verb, present, Middle or Passive deponent, Participle, accusative, singular, masculine",
    GN: "G2233",
    gloss: "to govern",
  },
  ἡγούμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G2233",
    gloss: "to govern",
  },
  ἡγουμένους: {
    parse:
      "V-PNP-APM｜Verb, present, Middle or Passive deponent, Participle, accusative, plural, masculine",
    GN: "G2233",
    gloss: "to govern",
  },
  ἡγουμένων: {
    parse:
      "V-PNP-GPM｜Verb, present, Middle or Passive deponent, Participle, genitive, plural, masculine",
    GN: "G2233",
    gloss: "to govern",
  },
  ἡγοῦνται: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G2233",
    gloss: "to govern",
  },
  ἡδέως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2234",
    gloss: "gladly",
  },
  ἤδη: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2235",
    gloss: "already",
  },
  Ἤδη: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2235",
    gloss: "already",
  },
  ἥδιστα: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G2236",
    gloss: "most gladly",
  },
  ἡδοναῖς: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G2237",
    gloss: "pleasure",
  },
  ἡδονὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2237",
    gloss: "pleasure",
  },
  ἡδονῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G2237",
    gloss: "pleasure",
  },
  ἡδύοσμον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G2238",
    gloss: "mint",
  },
  ἤθη: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G2239",
    gloss: "moral",
  },
  ἥκασιν: {
    parse: "V-RAI-3P｜Verb, perfect, active, indicative, third, plural",
    GN: "G2240",
    gloss: "to come/be present",
  },
  ἥκει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2240",
    gloss: "to come/be present",
  },
  ἥκω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G2240",
    gloss: "to come/be present",
  },
  ἥξει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2240",
    gloss: "to come/be present",
  },
  Ἥξει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2240",
    gloss: "to come/be present",
  },
  ἥξουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G2240",
    gloss: "to come/be present",
  },
  ἥξω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G2240",
    gloss: "to come/be present",
  },
  ἠλὶ: {
    parse: "N-VSM｜Noun, Vocative or nominative, singular, masculine",
    GN: "G2241",
    gloss: "Eli",
  },
  Ἠλὶ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2242",
    gloss: "Heli",
  },
  Ἠλίᾳ: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G2243",
    gloss: "Elijah",
  },
  Ἠλίαν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2243",
    gloss: "Elijah",
  },
  Ἠλίας: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2243",
    gloss: "Elijah",
  },
  Ἠλίου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2243",
    gloss: "Elijah",
  },
  ἡλικίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2244",
    gloss: "age/height",
  },
  ἡλικίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2244",
    gloss: "age/height",
  },
  Ἡλικίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2244",
    gloss: "age/height",
  },
  ἡλικίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2244",
    gloss: "age/height",
  },
  ἡλίκην: {
    parse: "I-ASF｜Interrogative pronoun, accusative, singular, feminine",
    GN: "G2245",
    gloss: "how great",
  },
  ἡλίκον: {
    parse: "I-NSN｜Interrogative pronoun, nominative, singular, neuter",
    GN: "G2245",
    gloss: "how great",
  },
  ἥλιον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2246",
    gloss: "sun",
  },
  ἥλιος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2246",
    gloss: "sun",
  },
  ἡλίου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2246",
    gloss: "sun",
  },
  ἡλίῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G2246",
    gloss: "sun",
  },
  ἥλων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G2247",
    gloss: "nail",
  },
  ἡμέρᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2250",
    gloss: "day",
  },
  ἡμέρα: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2250",
    gloss: "day",
  },
  ἡμέραι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G2250",
    gloss: "day",
  },
  ἡμέραις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G2250",
    gloss: "day",
  },
  ἡμέραν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2250",
    gloss: "day",
  },
  ἡμέρας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2250",
    gloss: "day",
  },
  Ἡμέρας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2250",
    gloss: "day",
  },
  ἡμερῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G2250",
    gloss: "day",
  },
  Ἡμερῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G2250",
    gloss: "day",
  },
  ἡμετέρα: {
    parse:
      "S-1PNSF｜Possessive pronoun, first, plural, nominative, singular, feminine",
    GN: "G2251",
    gloss: "our",
  },
  ἡμετέραις: {
    parse:
      "S-1PDPF｜Possessive pronoun, first, plural, dative, plural, feminine",
    GN: "G2251",
    gloss: "our",
  },
  ἡμετέραν: {
    parse:
      "S-1PASF｜Possessive pronoun, first, plural, accusative, singular, feminine",
    GN: "G2251",
    gloss: "our",
  },
  ἡμετέρας: {
    parse:
      "S-1PGSF｜Possessive pronoun, first, plural, genitive, singular, feminine",
    GN: "G2251",
    gloss: "our",
  },
  ἡμέτεροι: {
    parse:
      "S-1PNPM｜Possessive pronoun, first, plural, nominative, plural, masculine",
    GN: "G2251",
    gloss: "our",
  },
  ἡμετέροις: {
    parse:
      "S-1PDPM｜Possessive pronoun, first, plural, dative, plural, masculine",
    GN: "G2251",
    gloss: "our",
  },
  ἡμετέρων: {
    parse:
      "S-1PGPF｜Possessive pronoun, first, plural, genitive, plural, feminine",
    GN: "G2251",
    gloss: "our",
  },
  ἡμιθανῆ: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G2253",
    gloss: "half-dead",
  },
  ἡμίσιά: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G2255",
    gloss: "half",
  },
  ἡμίσους: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G2255",
    gloss: "half",
  },
  ἥμισυ: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G2255",
    gloss: "half",
  },
  ἡμιώριον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G2256",
    gloss: "half an hour",
  },
  ἡνίκα: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G2259",
    gloss: "when",
  },
  ἤπερ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G2260",
    gloss: "than",
  },
  ἤπιον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G2261",
    gloss: "gentle",
  },
  Ἢρ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2262",
    gloss: "Er",
  },
  ἤρεμον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G2263",
    gloss: "quiet",
  },
  Ἡρῴδῃ: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G2264",
    gloss: "Herod",
  },
  Ἡρῴδην: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2264",
    gloss: "Herod",
  },
  Ἡρῴδης: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2264",
    gloss: "Herod",
  },
  Ἡρῴδου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2264",
    gloss: "Herod",
  },
  Ἡρῳδιανῶν: {
    parse: "N-GPM-T｜Noun, genitive, plural, masculine, Title",
    GN: "G2265",
    gloss: "Herodian",
  },
  Ἡρῳδιάδα: {
    parse: "N-ASF-P｜Noun, accusative, singular, feminine, Person",
    GN: "G2266",
    gloss: "[Salome]",
  },
  Ἡρῳδιάδος: {
    parse: "N-GSF-P｜Noun, genitive, singular, feminine, Person",
    GN: "G2266",
    gloss: "[Salome]",
  },
  Ἡρῳδιὰς: {
    parse: "N-NSF-P｜Noun, nominative, singular, feminine, Person",
    GN: "G2266",
    gloss: "[Salome]",
  },
  Ἡρῳδίωνα: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2267",
    gloss: "Herodion",
  },
  Ἠσαΐᾳ: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G2268",
    gloss: "Isaiah",
  },
  Ἠσαΐαν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2268",
    gloss: "Isaiah",
  },
  Ἠσαΐας: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2268",
    gloss: "Isaiah",
  },
  Ἠσαΐου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2268",
    gloss: "Isaiah",
  },
  Ἠσαῦ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2269",
    gloss: "Esau",
  },
  ἡσυχάζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2270",
    gloss: "be quiet/give up",
  },
  ἡσυχάσαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G2270",
    gloss: "be quiet/give up",
  },
  ἡσύχασαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2270",
    gloss: "be quiet/give up",
  },
  ἡσυχίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2271",
    gloss: "quietness",
  },
  ἡσυχίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2271",
    gloss: "quietness",
  },
  ἡσυχίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2271",
    gloss: "quietness",
  },
  ἡσύχιον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G2272",
    gloss: "quiet",
  },
  ἡσυχίου: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G2272",
    gloss: "quiet",
  },
  ἤτοι: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G2273",
    gloss: "whether",
  },
  ἡσσώθητε: {
    parse: "V-API-2P｜Verb, aorist, passive, indicative, second, plural",
    GN: "G2274",
    gloss: "be lesser",
  },
  ἥττηται: {
    parse:
      "V-RNI-3S｜Verb, perfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G2274",
    gloss: "be lesser",
  },
  ἡττῶνται: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G2274",
    gloss: "be lesser",
  },
  ἥττημα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2275",
    gloss: "loss",
  },
  ἧσσον: {
    parse: "A-ASN-C｜Adjective, accusative, singular, neuter, Comparative",
    GN: "G2276",
    gloss: "less/worse",
  },
  ἠχῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2278",
    gloss: "to resound",
  },
  ἦχος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2279",
    gloss: "sound",
  },
  ἤχους: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G2279",
    gloss: "sound",
  },
  ἤχῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G2279",
    gloss: "sound",
  },
  Θαδδαῖον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2280",
    gloss: "Thaddaeus",
  },
  Θαδδαῖος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2280",
    gloss: "Thaddaeus",
  },
  θάλασσα: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2281",
    gloss: "sea",
  },
  θάλασσαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2281",
    gloss: "sea",
  },
  Θάλασσαν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G2281",
    gloss: "sea",
  },
  θαλάσσῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2281",
    gloss: "sea",
  },
  Θαλάσσῃ: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G2281",
    gloss: "sea",
  },
  θαλάσσης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2281",
    gloss: "sea",
  },
  θάλπει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2282",
    gloss: "to care for",
  },
  θάλπῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G2282",
    gloss: "to care for",
  },
  Θαμάρ: {
    parse: "N-GSF-P｜Noun, genitive, singular, feminine, Person",
    GN: "G2283",
    gloss: "Tamar",
  },
  ἐθαμβήθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G2284",
    gloss: "to astonish",
  },
  ἐθαμβοῦντο: {
    parse: "V-IPI-3P｜Verb, imperfect, passive, indicative, third, plural",
    GN: "G2284",
    gloss: "to astonish",
  },
  θάμβος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2285",
    gloss: "amazement",
  },
  θάμβους: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G2285",
    gloss: "amazement",
  },
  θανάσιμόν: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G2286",
    gloss: "deadly",
  },
  θανατηφόρου: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G2287",
    gloss: "deadly",
  },
  θάνατε: {
    // or nominative
    parse: "N-VSM｜Noun, Vocative, singular, masculine",
    GN: "G2288",
    gloss: "death",
  },
  θανάτοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G2288",
    gloss: "death",
  },
  θάνατον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2288",
    gloss: "death",
  },
  θάνατος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2288",
    gloss: "death",
  },
  Θάνατος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2288",
    gloss: "death",
  },
  θανάτου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2288",
    gloss: "death",
  },
  θανάτῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G2288",
    gloss: "death",
  },
  ἐθανατώθητε: {
    parse: "V-API-2P｜Verb, aorist, passive, indicative, second, plural",
    GN: "G2289",
    gloss: "to kill",
  },
  θανατούμεθα: {
    parse: "V-PPI-1P｜Verb, present, passive, indicative, first, plural",
    GN: "G2289",
    gloss: "to kill",
  },
  θανατούμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G2289",
    gloss: "to kill",
  },
  θανατοῦτε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G2289",
    gloss: "to kill",
  },
  θανατωθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G2289",
    gloss: "to kill",
  },
  θανατῶσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2289",
    gloss: "to kill",
  },
  θανατώσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G2289",
    gloss: "to kill",
  },
  θανατώσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G2289",
    gloss: "to kill",
  },
  ἔθαψαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2290",
    gloss: "to bury",
  },
  ἐτάφη: {
    parse: "V-2API-3S｜Verb, 2nd aorist, passive, indicative, third, singular",
    GN: "G2290",
    gloss: "to bury",
  },
  θάψαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2290",
    gloss: "to bury",
  },
  θαψάντων: {
    parse:
      "V-AAP-GPM｜Verb, aorist, active, Participle, genitive, plural, masculine",
    GN: "G2290",
    gloss: "to bury",
  },
  Θάρα: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2291",
    gloss: "Terah",
  },
  θαρρῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2292",
    gloss: "be confident",
  },
  θαρροῦμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G2292",
    gloss: "be confident",
  },
  θαρροῦντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G2292",
    gloss: "be confident",
  },
  Θαρροῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2292",
    gloss: "be confident",
  },
  θαρρῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G2292",
    gloss: "be confident",
  },
  Θάρσει: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G2293",
    gloss: "take heart",
  },
  θαρσεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G2293",
    gloss: "take heart",
  },
  Θαρσεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G2293",
    gloss: "take heart",
  },
  θάρσος: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G2294",
    gloss: "courage/confidence",
  },
  θαῦμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2295",
    gloss: "marvel",
  },
  ἐθαύμαζεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G2296",
    gloss: "to marvel",
  },
  ἐθαύμαζον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2296",
    gloss: "to marvel",
  },
  ἐθαύμασα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G2296",
    gloss: "to marvel",
  },
  ἐθαύμασαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2296",
    gloss: "to marvel",
  },
  ἐθαύμασας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G2296",
    gloss: "to marvel",
  },
  ἐθαύμασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2296",
    gloss: "to marvel",
  },
  ἐθαυμάσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2296",
    gloss: "to marvel",
  },
  θαυμάζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2296",
    gloss: "to marvel",
  },
  θαυμάζετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G2296",
    gloss: "to marvel",
  },
  θαυμάζητε: {
    parse: "V-PAS-2P｜Verb, present, active, subjunctive, second, plural",
    GN: "G2296",
    gloss: "to marvel",
  },
  θαυμάζοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2296",
    gloss: "to marvel",
  },
  θαυμαζόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G2296",
    gloss: "to marvel",
  },
  Θαυμάζω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G2296",
    gloss: "to marvel",
  },
  θαυμάζων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2296",
    gloss: "to marvel",
  },
  θαυμάσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2296",
    gloss: "to marvel",
  },
  θαυμάσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G2296",
    gloss: "to marvel",
  },
  θαυμάσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G2296",
    gloss: "to marvel",
  },
  θαυμάσῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G2296",
    gloss: "to marvel",
  },
  θαυμασθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G2296",
    gloss: "to marvel",
  },
  θαυμασθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G2296",
    gloss: "to marvel",
  },
  θαυμάσια: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G2297",
    gloss: "marvelous",
  },
  θαυμαστὰ: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G2298",
    gloss: "marvellous",
  },
  θαυμαστὴ: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G2298",
    gloss: "marvellous",
  },
  θαυμαστόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G2298",
    gloss: "marvellous",
  },
  θαυμαστὸν: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G2298",
    gloss: "marvellous",
  },
  θεᾶς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2299",
    gloss: "goddess",
  },
  ἐθεάθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2300",
    gloss: "to look at",
  },
  ἐθεασάμεθα: {
    parse: "V-ADI-1P｜Verb, aorist, Middle deponent, indicative, first, plural",
    GN: "G2300",
    gloss: "to look at",
  },
  ἐθεάσαντο: {
    parse: "V-ADI-3P｜Verb, aorist, Middle deponent, indicative, third, plural",
    GN: "G2300",
    gloss: "to look at",
  },
  ἐθεάσασθε: {
    parse:
      "V-ADI-2P｜Verb, aorist, Middle deponent, indicative, second, plural",
    GN: "G2300",
    gloss: "to look at",
  },
  ἐθεάσατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G2300",
    gloss: "to look at",
  },
  θεαθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G2300",
    gloss: "to look at",
  },
  θεασάμενοι: {
    parse:
      "V-ADP-NPM｜Verb, aorist, Middle deponent, Participle, nominative, plural, masculine",
    GN: "G2300",
    gloss: "to look at",
  },
  θεασαμένοις: {
    parse:
      "V-ADP-DPM｜Verb, aorist, Middle deponent, Participle, dative, plural, masculine",
    GN: "G2300",
    gloss: "to look at",
  },
  θεασάμενος: {
    parse:
      "V-ADP-NSM｜Verb, aorist, Middle deponent, Participle, nominative, singular, masculine",
    GN: "G2300",
    gloss: "to look at",
  },
  θεάσασθαι: {
    parse: "V-ADN｜Verb, aorist, Middle deponent, Infinitive",
    GN: "G2300",
    gloss: "to look at",
  },
  θεάσασθε: {
    parse:
      "V-ADM-2P｜Verb, aorist, Middle deponent, imperative, second, plural",
    GN: "G2300",
    gloss: "to look at",
  },
  Τεθέαμαι: {
    parse:
      "V-RNI-1S｜Verb, perfect, Middle or Passive deponent, indicative, first, singular",
    GN: "G2300",
    gloss: "to look at",
  },
  τεθεάμεθα: {
    parse:
      "V-RNI-1P｜Verb, perfect, Middle or Passive deponent, indicative, first, plural",
    GN: "G2300",
    gloss: "to look at",
  },
  τεθέαται: {
    parse:
      "V-RNI-3S｜Verb, perfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G2300",
    gloss: "to look at",
  },
  θεατριζόμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G2301",
    gloss: "to expose",
  },
  θέατρον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2302",
    gloss: "theatre",
  },
  θεῖον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G2304",
    gloss: "divine",
  },
  θείου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G2303",
    gloss: "sulfur",
  },
  θείῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G2303",
    gloss: "sulfur",
  },
  θείας: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G2304",
    gloss: "divine",
  },
  θειότης: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2305",
    gloss: "divinity",
  },
  θειώδεις: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2306",
    gloss: "sulphurous",
  },
  θέλημα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2307",
    gloss: "will/desire",
  },
  θέλημά: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2307",
    gloss: "will/desire",
  },
  θελήματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G2307",
    gloss: "will/desire",
  },
  θελήματά: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G2307",
    gloss: "will/desire",
  },
  θελήματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G2307",
    gloss: "will/desire",
  },
  θελήματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G2307",
    gloss: "will/desire",
  },
  θέλησιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2308",
    gloss: "will",
  },
  ἤθελεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G2309",
    gloss: "to will/desire",
  },
  ἤθελες: {
    parse: "V-IAI-2S｜Verb, imperfect, active, indicative, second, singular",
    GN: "G2309",
    gloss: "to will/desire",
  },
  ἠθέλησα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G2309",
    gloss: "to will/desire",
  },
  ἠθελήσαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G2309",
    gloss: "to will/desire",
  },
  ἠθέλησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2309",
    gloss: "to will/desire",
  },
  ἠθέλησας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G2309",
    gloss: "to will/desire",
  },
  ἠθελήσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G2309",
    gloss: "to will/desire",
  },
  ἠθέλησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2309",
    gloss: "to will/desire",
  },
  ἤθελον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2309",
    gloss: "to will/desire",
  },
  θέλει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2309",
    gloss: "to will/desire",
  },
  θέλειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2309",
    gloss: "to will/desire",
  },
  θέλεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G2309",
    gloss: "to will/desire",
  },
  Θέλεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G2309",
    gloss: "to will/desire",
  },
  θέλετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G2309",
    gloss: "to will/desire",
  },
  Θέλετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G2309",
    gloss: "to will/desire",
  },
  θέλετέ: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G2309",
    gloss: "to will/desire",
  },
  θέλῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G2309",
    gloss: "to will/desire",
  },
  θέλῃς: {
    parse: "V-PAS-2S｜Verb, present, active, subjunctive, second, singular",
    GN: "G2309",
    gloss: "to will/desire",
  },
  θελήσαντάς: {
    parse:
      "V-AAP-APM｜Verb, aorist, active, Participle, accusative, plural, masculine",
    GN: "G2309",
    gloss: "to will/desire",
  },
  θελήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G2309",
    gloss: "to will/desire",
  },
  θελήσω: {
    parse: "V-AAS-1S｜Verb, aorist, active, subjunctive, first, singular",
    GN: "G2309",
    gloss: "to will/desire",
  },
  θελήσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G2309",
    gloss: "to will/desire",
  },
  θέλητε: {
    parse: "V-PAS-2P｜Verb, present, active, subjunctive, second, plural",
    GN: "G2309",
    gloss: "to will/desire",
  },
  θέλοι: {
    parse: "V-PAO-3S｜Verb, present, active, Optative, third, singular",
    GN: "G2309",
    gloss: "to will/desire",
  },
  θέλομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G2309",
    gloss: "to will/desire",
  },
  θέλοντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G2309",
    gloss: "to will/desire",
  },
  θέλοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G2309",
    gloss: "to will/desire",
  },
  θέλοντες: {
    parse:
      "V-PAP-VPM｜Verb, present, active, Participle, Vocative or nominative, plural, masculine",
    GN: "G2309",
    gloss: "to will/desire",
  },
  θέλοντές: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2309",
    gloss: "to will/desire",
  },
  θέλοντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G2309",
    gloss: "to will/desire",
  },
  θέλοντί: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G2309",
    gloss: "to will/desire",
  },
  θέλοντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G2309",
    gloss: "to will/desire",
  },
  θελόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G2309",
    gloss: "to will/desire",
  },
  θέλουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2309",
    gloss: "to will/desire",
  },
  θέλω: {
    parse: "V-PAS-1S｜Verb, present, active, subjunctive, first, singular",
    GN: "G2309",
    gloss: "to will/desire",
  },
  Θέλω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G2309",
    gloss: "to will/desire",
  },
  θέλων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2309",
    gloss: "to will/desire",
  },
  θέλωσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G2309",
    gloss: "to will/desire",
  },
  θεμέλια: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G2310",
    gloss: "foundation",
  },
  θεμέλιοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G2310",
    gloss: "foundation",
  },
  θεμέλιον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2310",
    gloss: "foundation",
  },
  θεμέλιος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2310",
    gloss: "foundation",
  },
  θεμελίου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2310",
    gloss: "foundation",
  },
  θεμελίους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G2310",
    gloss: "foundation",
  },
  θεμελίῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G2310",
    gloss: "foundation",
  },
  ἐθεμελίωσας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G2311",
    gloss: "to found",
  },
  θεμελιώσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2311",
    gloss: "to found",
  },
  τεθεμελιωμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G2311",
    gloss: "to found",
  },
  τεθεμελίωτο: {
    parse: "V-LPI-3S｜Verb, pluperfect, passive, indicative, third, singular",
    GN: "G2311",
    gloss: "to found",
  },
  θεοδίδακτοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G2312",
    gloss: "divinely instructed",
  },
  θεομάχοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G2314",
    gloss: "opposing God",
  },
  θεόπνευστος: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G2315",
    gloss: "God-breathed",
  },
  θεέ: {
    // or nominative
    parse: "N-VSM｜Noun, Vocative, singular, masculine",
    GN: "G2316",
    gloss: "God",
  },
  Θεέ: {
    // or nominative
    parse: "N-VSM｜Noun, Vocative, singular, masculine",
    GN: "G2316",
    gloss: "God",
  },
  Θεοί: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G2316",
    gloss: "God",
  },
  θεοὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G2316",
    gloss: "God",
  },
  θεοῖς: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G2316",
    gloss: "God",
  },
  θεὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2316",
    gloss: "God",
  },
  θεόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2316",
    gloss: "God",
  },
  Θεόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2316",
    gloss: "God",
  },
  θεός: {
    parse: "N-VSM｜Noun, nominative, singular, masculine",
    GN: "G2316",
    gloss: "God",
  },
  Θεός: {
    parse: "N-VSM｜Noun, nominative, singular, masculine",
    GN: "G2316",
    gloss: "God",
  },
  θεὸς: {
    parse: "N-VSM｜Noun, nominative, singular, masculine",
    GN: "G2316",
    gloss: "God",
  },
  θεοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2316",
    gloss: "God",
  },
  Θεοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2316",
    gloss: "God",
  },
  θεοὺς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G2316",
    gloss: "God",
  },
  θεῷ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G2316",
    gloss: "God",
  },
  θεοσέβειαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2317",
    gloss: "reverence for God",
  },
  θεοσεβὴς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2318",
    gloss: "godly",
  },
  θεοστυγεῖς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2319",
    gloss: "God-hating",
  },
  θεότητος: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2320",
    gloss: "deity",
  },
  Θεόφιλε: {
    parse: "N-VSM-P｜Noun, Vocative or nominative, singular, masculine, Person",
    GN: "G2321",
    gloss: "Theophilus",
  },
  θεραπείαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2322",
    gloss: "service",
  },
  θεραπείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2322",
    gloss: "service",
  },
  ἐθεράπευεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G2323",
    gloss: "to serve/heal",
  },
  ἐθεραπεύθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2323",
    gloss: "to serve/heal",
  },
  ἐθεραπεύθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G2323",
    gloss: "to serve/heal",
  },
  ἐθεράπευον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2323",
    gloss: "to serve/heal",
  },
  ἐθεραπεύοντο: {
    parse: "V-IPI-3P｜Verb, imperfect, passive, indicative, third, plural",
    GN: "G2323",
    gloss: "to serve/heal",
  },
  ἐθεράπευσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2323",
    gloss: "to serve/heal",
  },
  θεραπεύει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2323",
    gloss: "to serve/heal",
  },
  θεραπεύειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2323",
    gloss: "to serve/heal",
  },
  θεραπεύεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G2323",
    gloss: "to serve/heal",
  },
  θεραπεύεσθε: {
    parse: "V-PPM-2P｜Verb, present, passive, imperative, second, plural",
    GN: "G2323",
    gloss: "to serve/heal",
  },
  θεραπεύεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G2323",
    gloss: "to serve/heal",
  },
  θεραπεύετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G2323",
    gloss: "to serve/heal",
  },
  θεραπευθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G2323",
    gloss: "to serve/heal",
  },
  θεραπεύοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2323",
    gloss: "to serve/heal",
  },
  θεραπεῦσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2323",
    gloss: "to serve/heal",
  },
  θεραπεύσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2323",
    gloss: "to serve/heal",
  },
  θεράπευσον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G2323",
    gloss: "to serve/heal",
  },
  θεραπεύσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G2323",
    gloss: "to serve/heal",
  },
  θεραπεύων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2323",
    gloss: "to serve/heal",
  },
  τεθεραπευμέναι: {
    parse:
      "V-RPP-NPF｜Verb, perfect, passive, Participle, nominative, plural, feminine",
    GN: "G2323",
    gloss: "to serve/heal",
  },
  τεθεραπευμένον: {
    parse:
      "V-RPP-ASM｜Verb, perfect, passive, Participle, accusative, singular, masculine",
    GN: "G2323",
    gloss: "to serve/heal",
  },
  τεθεραπευμένῳ: {
    parse:
      "V-RPP-DSM｜Verb, perfect, passive, Participle, dative, singular, masculine",
    GN: "G2323",
    gloss: "to serve/heal",
  },
  θεράπων: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2324",
    gloss: "servant",
  },
  ἐθερίσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2325",
    gloss: "to reap",
  },
  θερίζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2325",
    gloss: "to reap",
  },
  θερίζεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G2325",
    gloss: "to reap",
  },
  θερίζουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2325",
    gloss: "to reap",
  },
  θερίζω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G2325",
    gloss: "to reap",
  },
  θερίζων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2325",
    gloss: "to reap",
  },
  θερίσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2325",
    gloss: "to reap",
  },
  θερισάντων: {
    parse:
      "V-AAP-GPM｜Verb, aorist, active, Participle, genitive, plural, masculine",
    GN: "G2325",
    gloss: "to reap",
  },
  θερίσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2325",
    gloss: "to reap",
  },
  θερίσομεν: {
    parse: "V-FAI-1P｜Verb, future, active, indicative, first, plural",
    GN: "G2325",
    gloss: "to reap",
  },
  θέρισον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G2325",
    gloss: "to reap",
  },
  θερισμόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2326",
    gloss: "harvest",
  },
  θερισμὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2326",
    gloss: "harvest",
  },
  θερισμός: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2326",
    gloss: "harvest",
  },
  θερισμὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2326",
    gloss: "harvest",
  },
  θερισμοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2326",
    gloss: "harvest",
  },
  θερισταὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G2327",
    gloss: "reaper",
  },
  θερισταῖς: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G2327",
    gloss: "reaper",
  },
  ἐθερμαίνοντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G2328",
    gloss: "to warm",
  },
  θερμαίνεσθε: {
    parse:
      "V-PEM-2P｜Verb, present, Either middle or passive, imperative, second, plural",
    GN: "G2328",
    gloss: "to warm",
  },
  θερμαινόμενον: {
    parse:
      "V-PMP-ASM｜Verb, present, middle, Participle, accusative, singular, masculine",
    GN: "G2328",
    gloss: "to warm",
  },
  θερμαινόμενος: {
    parse:
      "V-PMP-NSM｜Verb, present, middle, Participle, nominative, singular, masculine",
    GN: "G2328",
    gloss: "to warm",
  },
  θέρμης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2329",
    gloss: "heat",
  },
  θέρος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2330",
    gloss: "summer",
  },
  Θεσσαλονικέων: {
    parse: "N-GPM-LG｜Noun, genitive, plural, masculine, Location Gentilic",
    GN: "G2331",
    gloss: "Thessalonian",
  },
  Θεσσαλονικέως: {
    parse: "N-GSM-LG｜Noun, genitive, singular, masculine, Location Gentilic",
    GN: "G2331",
    gloss: "Thessalonian",
  },
  Θεσσαλονίκῃ: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G2332",
    gloss: "Thessalonica",
  },
  Θεσσαλονίκην: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G2332",
    gloss: "Thessalonica",
  },
  Θεσσαλονίκης: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G2332",
    gloss: "Thessalonica",
  },
  Θευδᾶς: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2333",
    gloss: "Theudas",
  },
  ἐθεώρει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G2334",
    gloss: "to see/experience",
  },
  ἐθεώρησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2334",
    gloss: "to see/experience",
  },
  Ἐθεώρουν: {
    parse: "V-IAI-1S｜Verb, imperfect, active, indicative, first, singular",
    GN: "G2334",
    gloss: "to see/experience",
  },
  ἐθεώρουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2334",
    gloss: "to see/experience",
  },
  θεωρεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2334",
    gloss: "to see/experience",
  },
  θεωρεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2334",
    gloss: "to see/experience",
  },
  Θεωρεῖς: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G2334",
    gloss: "to see/experience",
  },
  θεωρεῖτε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G2334",
    gloss: "to see/experience",
  },
  Θεωρεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G2334",
    gloss: "to see/experience",
  },
  θεωρεῖτέ: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G2334",
    gloss: "to see/experience",
  },
  θεωρῇ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G2334",
    gloss: "to see/experience",
  },
  θεωρῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2334",
    gloss: "to see/experience",
  },
  θεωρήσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G2334",
    gloss: "to see/experience",
  },
  θεωρήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G2334",
    gloss: "to see/experience",
  },
  θεωρήσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G2334",
    gloss: "to see/experience",
  },
  θεωρῆτε: {
    parse: "V-PAS-2P｜Verb, present, active, subjunctive, second, plural",
    GN: "G2334",
    gloss: "to see/experience",
  },
  θεωροῦντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G2334",
    gloss: "to see/experience",
  },
  θεωροῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2334",
    gloss: "to see/experience",
  },
  Θεωροῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2334",
    gloss: "to see/experience",
  },
  θεωροῦντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G2334",
    gloss: "to see/experience",
  },
  θεωρούντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G2334",
    gloss: "to see/experience",
  },
  θεωροῦσαι: {
    parse:
      "V-PAP-NPF｜Verb, present, active, Participle, nominative, plural, feminine",
    GN: "G2334",
    gloss: "to see/experience",
  },
  θεωροῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2334",
    gloss: "to see/experience",
  },
  θεωρῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G2334",
    gloss: "to see/experience",
  },
  θεωρῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2334",
    gloss: "to see/experience",
  },
  θεωρῶσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G2334",
    gloss: "to see/experience",
  },
  θεωρίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2335",
    gloss: "sight",
  },
  θήκην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2336",
    gloss: "sheath",
  },
  ἐθήλασας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G2337",
    gloss: "to suckle",
  },
  θηλαζόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G2337",
    gloss: "to suckle",
  },
  θηλαζούσαις: {
    parse:
      "V-PAP-DPF｜Verb, present, active, Participle, dative, plural, feminine",
    GN: "G2337",
    gloss: "to suckle",
  },
  θήλειαι: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G2338",
    gloss: "female",
  },
  θηλείας: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G2338",
    gloss: "female",
  },
  θῆλυ: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G2338",
    gloss: "female",
  },
  θήραν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2339",
    gloss: "trap",
  },
  θηρεῦσαί: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2340",
    gloss: "to hunt/catch",
  },
  ἐθηριομάχησα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G2341",
    gloss: "to fight beasts",
  },
  θηρία: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G2342",
    gloss: "wild animal",
  },
  θηρίον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2342",
    gloss: "wild animal",
  },
  θηρίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G2342",
    gloss: "wild animal",
  },
  θηρίῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G2342",
    gloss: "wild animal",
  },
  θηρίων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G2342",
    gloss: "wild animal",
  },
  ἐθησαυρίσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G2343",
    gloss: "to store up",
  },
  θησαυρίζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2343",
    gloss: "to store up",
  },
  θησαυρίζεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G2343",
    gloss: "to store up",
  },
  θησαυρίζετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G2343",
    gloss: "to store up",
  },
  θησαυρίζων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2343",
    gloss: "to store up",
  },
  τεθησαυρισμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G2343",
    gloss: "to store up",
  },
  θησαυροὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G2344",
    gloss: "treasure",
  },
  θησαυρὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2344",
    gloss: "treasure",
  },
  θησαυρός: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2344",
    gloss: "treasure",
  },
  θησαυρὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2344",
    gloss: "treasure",
  },
  θησαυροῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2344",
    gloss: "treasure",
  },
  θησαυροὺς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G2344",
    gloss: "treasure",
  },
  θησαυρῷ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G2344",
    gloss: "treasure",
  },
  θησαυρῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G2344",
    gloss: "treasure",
  },
  θίγῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G2345",
    gloss: "to touch",
  },
  θίγῃς: {
    parse: "V-2AAS-2S｜Verb, 2nd aorist, active, subjunctive, second, singular",
    GN: "G2345",
    gloss: "to touch",
  },
  θλίβεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G2346",
    gloss: "to press on",
  },
  θλιβόμεθα: {
    parse: "V-PPI-1P｜Verb, present, passive, indicative, first, plural",
    GN: "G2346",
    gloss: "to press on",
  },
  θλιβόμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G2346",
    gloss: "to press on",
  },
  θλιβομένοις: {
    parse:
      "V-PPP-DPM｜Verb, present, passive, Participle, dative, plural, masculine",
    GN: "G2346",
    gloss: "to press on",
  },
  θλίβουσιν: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G2346",
    gloss: "to press on",
  },
  θλίβωσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G2346",
    gloss: "to press on",
  },
  τεθλιμμένη: {
    parse:
      "V-RPP-NSF｜Verb, perfect, passive, Participle, nominative, singular, feminine",
    GN: "G2346",
    gloss: "to press on",
  },
  θλίψει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2347",
    gloss: "pressure",
  },
  θλίψεις: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G2347",
    gloss: "pressure",
  },
  θλίψεσιν: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G2347",
    gloss: "pressure",
  },
  θλίψεσίν: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G2347",
    gloss: "pressure",
  },
  θλίψεων: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G2347",
    gloss: "pressure",
  },
  θλίψεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2347",
    gloss: "pressure",
  },
  θλῖψιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2347",
    gloss: "pressure",
  },
  θλῖψις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2347",
    gloss: "pressure",
  },
  τεθνήκασιν: {
    parse: "V-RAI-3P｜Verb, perfect, active, indicative, third, plural",
    GN: "G2348",
    gloss: "to die/be dead",
  },
  τέθνηκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G2348",
    gloss: "to die/be dead",
  },
  Τέθνηκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G2348",
    gloss: "to die/be dead",
  },
  τεθνηκέναι: {
    parse: "V-RAN｜Verb, perfect, active, Infinitive",
    GN: "G2348",
    gloss: "to die/be dead",
  },
  τεθνηκότα: {
    parse:
      "V-RAP-ASM｜Verb, perfect, active, Participle, accusative, singular, masculine",
    GN: "G2348",
    gloss: "to die/be dead",
  },
  τεθνηκότος: {
    parse:
      "V-RAP-GSM｜Verb, perfect, active, Participle, genitive, singular, masculine",
    GN: "G2348",
    gloss: "to die/be dead",
  },
  τεθνηκὼς: {
    parse:
      "V-RAP-NSM｜Verb, perfect, active, Participle, nominative, singular, masculine",
    GN: "G2348",
    gloss: "to die/be dead",
  },
  θνητὰ: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G2349",
    gloss: "mortal",
  },
  θνητῇ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G2349",
    gloss: "mortal",
  },
  θνητὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G2349",
    gloss: "mortal",
  },
  θνητῷ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G2349",
    gloss: "mortal",
  },
  ἐθορύβουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2350",
    gloss: "to make commotion",
  },
  θορυβάζῃ: {
    parse: "V-PPI-2S｜Verb, present, passive, indicative, second, singular",
    GN: "G2350",
    gloss: "to make commotion",
  },
  θορυβεῖσθε: {
    parse: "V-PPM-2P｜Verb, present, passive, imperative, second, plural",
    GN: "G2350",
    gloss: "to make commotion",
  },
  θορυβούμενον: {
    parse:
      "V-PPP-ASM｜Verb, present, passive, Participle, accusative, singular, masculine",
    GN: "G2350",
    gloss: "to make commotion",
  },
  θόρυβον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2351",
    gloss: "commotion",
  },
  θόρυβος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2351",
    gloss: "commotion",
  },
  θορύβου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2351",
    gloss: "commotion",
  },
  τεθραυσμένους: {
    parse:
      "V-RPP-APM｜Verb, perfect, passive, Participle, accusative, plural, masculine",
    GN: "G2352",
    gloss: "to crush",
  },
  θρέμματα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G2353",
    gloss: "livestock",
  },
  ἐθρηνήσαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G2354",
    gloss: "to lament",
  },
  ἐθρήνουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2354",
    gloss: "to lament",
  },
  θρηνήσετε: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G2354",
    gloss: "to lament",
  },
  θρησκείᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2356",
    gloss: "religion",
  },
  θρησκεία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2356",
    gloss: "religion",
  },
  θρησκείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2356",
    gloss: "religion",
  },
  θρησκὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2357",
    gloss: "religious",
  },
  θριαμβεύοντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G2358",
    gloss: "to triumph",
  },
  θριαμβεύσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G2358",
    gloss: "to triumph",
  },
  θρὶξ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2359",
    gloss: "hair",
  },
  θριξὶν: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G2359",
    gloss: "hair",
  },
  τρίχα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2359",
    gloss: "hair",
  },
  τρίχας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G2359",
    gloss: "hair",
  },
  τρίχες: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G2359",
    gloss: "hair",
  },
  τριχῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G2359",
    gloss: "hair",
  },
  θροεῖσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G2360",
    gloss: "to alarm",
  },
  θροεῖσθε: {
    parse: "V-PPM-2P｜Verb, present, passive, imperative, second, plural",
    GN: "G2360",
    gloss: "to alarm",
  },
  θρόμβοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G2361",
    gloss: "drop",
  },
  θρόνοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G2362",
    gloss: "throne",
  },
  θρόνον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2362",
    gloss: "throne",
  },
  θρόνος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2362",
    gloss: "throne",
  },
  θρόνου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2362",
    gloss: "throne",
  },
  θρόνους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G2362",
    gloss: "throne",
  },
  θρόνῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G2362",
    gloss: "throne",
  },
  θρόνων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G2362",
    gloss: "throne",
  },
  Θυάτειρα: {
    parse: "N-APN-L｜Noun, accusative, plural, neuter, Location",
    GN: "G2363",
    gloss: "Thyatira",
  },
  Θυατείροις: {
    parse: "N-DPN-L｜Noun, dative, plural, neuter, Location",
    GN: "G2363",
    gloss: "Thyatira",
  },
  Θυατείρων: {
    parse: "N-GPN-L｜Noun, genitive, plural, neuter, Location",
    GN: "G2363",
    gloss: "Thyatira",
  },
  θύγατερ: {
    // or nominative
    parse: "N-VSF｜Noun, Vocative, singular, feminine",
    GN: "G2364",
    gloss: "daughter",
  },
  θυγατέρα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2364",
    gloss: "daughter",
  },
  θυγατέρας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G2364",
    gloss: "daughter",
  },
  θυγατέρες: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G2364",
    gloss: "daughter",
  },
  Θυγατέρες: {
    parse: "N-VPF｜Noun, Vocative or nominative, plural, feminine",
    GN: "G2364",
    gloss: "daughter",
  },
  θυγατέρων: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G2364",
    gloss: "daughter",
  },
  θυγάτηρ: {
    parse: "N-VSF｜Noun, Vocative or nominative, singular, feminine",
    GN: "G2364",
    gloss: "daughter",
  },
  Θυγάτηρ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2364",
    gloss: "daughter",
  },
  θυγατρὶ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2364",
    gloss: "daughter",
  },
  θυγατρός: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2364",
    gloss: "daughter",
  },
  θυγατρὸς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2364",
    gloss: "daughter",
  },
  θυγάτριον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2365",
    gloss: "little daughter",
  },
  θυγάτριόν: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2365",
    gloss: "little daughter",
  },
  θυέλλῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2366",
    gloss: "storm",
  },
  θύϊνον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G2367",
    gloss: "citron",
  },
  θυμιάματα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G2368",
    gloss: "incense",
  },
  θυμιάματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G2368",
    gloss: "incense",
  },
  θυμιαμάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G2368",
    gloss: "incense",
  },
  θυμιατήριον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G2369",
    gloss: "incense altar",
  },
  θυμιᾶσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2370",
    gloss: "to burn incense",
  },
  θυμομαχῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2371",
    gloss: "to quarrel",
  },
  θυμοί: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G2372",
    gloss: "wrath",
  },
  θυμόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2372",
    gloss: "wrath",
  },
  θυμὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2372",
    gloss: "wrath",
  },
  θυμός: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2372",
    gloss: "wrath",
  },
  θυμὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2372",
    gloss: "wrath",
  },
  θυμοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2372",
    gloss: "wrath",
  },
  ἐθυμώθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2373",
    gloss: "to anger",
  },
  θύρᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2374",
    gloss: "door",
  },
  θύρα: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2374",
    gloss: "door",
  },
  θύραι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G2374",
    gloss: "door",
  },
  θύραις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G2374",
    gloss: "door",
  },
  θύραν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2374",
    gloss: "door",
  },
  θύρας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2374",
    gloss: "door",
  },
  θυρῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G2374",
    gloss: "door",
  },
  θυρεὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2375",
    gloss: "long shield",
  },
  θυρίδος: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2376",
    gloss: "window",
  },
  θυρωρός: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2377",
    gloss: "gatekeeper",
  },
  θυρωρὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2377",
    gloss: "gatekeeper",
  },
  θυρωρῷ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G2377",
    gloss: "gatekeeper",
  },
  θυσίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2378",
    gloss: "sacrifice",
  },
  θυσία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2378",
    gloss: "sacrifice",
  },
  θυσίαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G2378",
    gloss: "sacrifice",
  },
  θυσίαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G2378",
    gloss: "sacrifice",
  },
  θυσίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2378",
    gloss: "sacrifice",
  },
  Θυσίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2378",
    gloss: "sacrifice",
  },
  θυσίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2378",
    gloss: "sacrifice",
  },
  Θυσίας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G2378",
    gloss: "sacrifice",
  },
  θυσιῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G2378",
    gloss: "sacrifice",
  },
  θυσιαστήριά: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G2379",
    gloss: "altar",
  },
  θυσιαστήριον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2379",
    gloss: "altar",
  },
  θυσιαστηρίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G2379",
    gloss: "altar",
  },
  θυσιαστηρίῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G2379",
    gloss: "altar",
  },
  ἔθυον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2380",
    gloss: "to sacrifice",
  },
  ἔθυσας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G2380",
    gloss: "to sacrifice",
  },
  ἔθυσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2380",
    gloss: "to sacrifice",
  },
  ἐτύθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2380",
    gloss: "to sacrifice",
  },
  θύειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2380",
    gloss: "to sacrifice",
  },
  θύεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G2380",
    gloss: "to sacrifice",
  },
  θύουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2380",
    gloss: "to sacrifice",
  },
  θύσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G2380",
    gloss: "to sacrifice",
  },
  θύσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G2380",
    gloss: "to sacrifice",
  },
  θῦσον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G2380",
    gloss: "to sacrifice",
  },
  τεθυμένα: {
    parse:
      "V-RPP-NPN｜Verb, perfect, passive, Participle, nominative, plural, neuter",
    GN: "G2380",
    gloss: "to sacrifice",
  },
  Θωμᾷ: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G2381",
    gloss: "Thomas",
  },
  Θωμᾶν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2381",
    gloss: "Thomas",
  },
  Θωμᾶς: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2381",
    gloss: "Thomas",
  },
  θώρακα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2382",
    gloss: "breastplate",
  },
  θώρακας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G2382",
    gloss: "breastplate",
  },
  Ἰάειρος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2383",
    gloss: "Jairus",
  },
  Ἰακώβ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2384",
    gloss: "Jacob",
  },
  Ἰακὼβ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2384",
    gloss: "Jacob",
  },
  Ἰάκωβον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2385",
    gloss: "James",
  },
  Ἰάκωβος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2385",
    gloss: "James",
  },
  Ἰακώβου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2385",
    gloss: "James",
  },
  Ἰακώβῳ: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G2385",
    gloss: "James",
  },
  ἰαμάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G2386",
    gloss: "healing",
  },
  Ἰαμβρῆς: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2387",
    gloss: "Jambres",
  },
  Ἰανναὶ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2388",
    gloss: "Jannai",
  },
  Ἰάννης: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2389",
    gloss: "Jannes",
  },
  ἰαθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G2390",
    gloss: "to heal",
  },
  ἰάθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2390",
    gloss: "to heal",
  },
  ἰαθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G2390",
    gloss: "to heal",
  },
  ἰαθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G2390",
    gloss: "to heal",
  },
  ἰαθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G2390",
    gloss: "to heal",
  },
  ἰάθητε: {
    parse: "V-API-2P｜Verb, aorist, passive, indicative, second, plural",
    GN: "G2390",
    gloss: "to heal",
  },
  ἰαθῆτε: {
    parse: "V-APS-2P｜Verb, aorist, passive, subjunctive, second, plural",
    GN: "G2390",
    gloss: "to heal",
  },
  ἰαθήτω: {
    parse: "V-APM-3S｜Verb, aorist, passive, imperative, third, singular",
    GN: "G2390",
    gloss: "to heal",
  },
  ἰάσατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G2390",
    gloss: "to heal",
  },
  ἰάσηται: {
    parse:
      "V-ADS-3S｜Verb, aorist, Middle deponent, subjunctive, third, singular",
    GN: "G2390",
    gloss: "to heal",
  },
  ἰᾶσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G2390",
    gloss: "to heal",
  },
  ἰάσομαι: {
    parse:
      "V-FDI-1S｜Verb, future, Middle deponent, indicative, first, singular",
    GN: "G2390",
    gloss: "to heal",
  },
  ἰᾶταί: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G2390",
    gloss: "to heal",
  },
  ἴαται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G2390",
    gloss: "to heal",
  },
  ἰᾶτο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G2390",
    gloss: "to heal",
  },
  ἰώμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G2390",
    gloss: "to heal",
  },
  Ἰάρετ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2391",
    gloss: "Jared",
  },
  ἰάσεις: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G2392",
    gloss: "healing",
  },
  ἰάσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2392",
    gloss: "healing",
  },
  ἴασιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2392",
    gloss: "healing",
  },
  ἰάσπιδι: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2393",
    gloss: "jasper",
  },
  ἴασπις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2393",
    gloss: "jasper",
  },
  Ἰάσονα: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2394",
    gloss: "Jason",
  },
  Ἰάσονος: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2394",
    gloss: "Jason",
  },
  Ἰάσων: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2394",
    gloss: "Jason",
  },
  Ἰατρέ: {
    // or nominative
    parse: "N-VSM｜Noun, Vocative, singular, masculine",
    GN: "G2395",
    gloss: "physician",
  },
  ἰατροῖς: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G2395",
    gloss: "physician",
  },
  ἰατρὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2395",
    gloss: "physician",
  },
  ἰατροῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2395",
    gloss: "physician",
  },
  ἰατρῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G2395",
    gloss: "physician",
  },
  Ἴδε: { parse: "INJ｜Interjection", GN: "G2396", gloss: "look!" },
  εἰδέα: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2397",
    gloss: "appearance",
  },
  ἴδια: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G2398",
    gloss: "one's own/private",
  },
  ἰδίᾳ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G2398",
    gloss: "one's own/private",
  },
  ἰδίαις: {
    parse: "A-DPF｜Adjective, dative, plural, feminine",
    GN: "G2398",
    gloss: "one's own/private",
  },
  ἰδίαν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G2398",
    gloss: "one's own/private",
  },
  ἰδίας: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G2398",
    gloss: "one's own/private",
  },
  ἴδιοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G2398",
    gloss: "one's own/private",
  },
  ἰδίοις: {
    parse: "A-DPN｜Adjective, dative, plural, neuter",
    GN: "G2398",
    gloss: "one's own/private",
  },
  ἴδιον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G2398",
    gloss: "one's own/private",
  },
  ἴδιος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2398",
    gloss: "one's own/private",
  },
  ἰδίου: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G2398",
    gloss: "one's own/private",
  },
  ἰδίους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2398",
    gloss: "one's own/private",
  },
  ἰδίῳ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G2398",
    gloss: "one's own/private",
  },
  ἰδίων: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G2398",
    gloss: "one's own/private",
  },
  ἰδιῶται: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G2399",
    gloss: "unlearned",
  },
  ἰδιώτης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2399",
    gloss: "unlearned",
  },
  ἰδιώτου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2399",
    gloss: "unlearned",
  },
  ἰδού: { parse: "INJ｜Interjection", GN: "G2400", gloss: "look!/behold!" },
  ἰδοὺ: { parse: "INJ｜Interjection", GN: "G2400", gloss: "look!/behold!" },
  Ἰδοὺ: { parse: "INJ｜Interjection", GN: "G2400", gloss: "look!/behold!" },
  Ἰδουμαίας: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G2401",
    gloss: "Idumea",
  },
  ἱδρὼς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2402",
    gloss: "sweat",
  },
  Ἰεζάβελ: {
    parse: "N-ASF-P｜Noun, accusative, singular, feminine, Person",
    GN: "G2403",
    gloss: "Jezebel",
  },
  Ἱεραπόλει: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G2404",
    gloss: "Hierapolis",
  },
  ἱερατείαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2405",
    gloss: "priesthood",
  },
  ἱερατείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2405",
    gloss: "priesthood",
  },
  ἱεράτευμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2406",
    gloss: "priesthood",
  },
  ἱερατεύειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2407",
    gloss: "to serve as priest",
  },
  Ἰερεμίαν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2408",
    gloss: "Jeremiah",
  },
  Ἰερεμίου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2408",
    gloss: "Jeremiah",
  },
  ἱερέα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2409",
    gloss: "priest",
  },
  ἱερεῖ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G2409",
    gloss: "priest",
  },
  ἱερεῖς: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G2409",
    gloss: "priest",
  },
  ἱερεύς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2409",
    gloss: "priest",
  },
  ἱερεὺς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2409",
    gloss: "priest",
  },
  ἱερεῦσιν: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G2409",
    gloss: "priest",
  },
  ἱερέων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G2409",
    gloss: "priest",
  },
  Ἰεριχώ: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G2410",
    gloss: "Jericho",
  },
  Ἰεριχὼ: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G2410",
    gloss: "Jericho",
  },
  ἱερὸν: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G2411",
    gloss: "temple",
  },
  ἱερόν: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G2411",
    gloss: "temple",
  },
  ἱεροῦ: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G2411",
    gloss: "temple",
  },
  ἱερῷ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G2411",
    gloss: "temple",
  },
  ἱεροπρεπεῖς: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G2412",
    gloss: "reverent",
  },
  ἱερὰ: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G2413",
    gloss: "sacred",
  },
  Ἱεροσόλυμα: {
    parse: "N-NSF-L｜Noun, nominative, singular, feminine, Location",
    GN: "G2414",
    gloss: "Jerusalem",
  },
  Ἱεροσολύμοις: {
    parse: "N-DPN-L｜Noun, dative, plural, neuter, Location",
    GN: "G2414",
    gloss: "Jerusalem",
  },
  Ἱεροσολύμων: {
    parse: "N-GPN-L｜Noun, genitive, plural, neuter, Location",
    GN: "G2414",
    gloss: "Jerusalem",
  },
  Ἱεροσολυμῖται: {
    parse: "N-NPM-L｜Noun, nominative, plural, masculine, Location",
    GN: "G2415",
    gloss: "Jerusalem",
  },
  Ἱεροσολυμιτῶν: {
    parse: "N-GPM-L｜Noun, genitive, plural, masculine, Location",
    GN: "G2415",
    gloss: "Jerusalem",
  },
  ἱεροσυλεῖς: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G2416",
    gloss: "to despoil a temple",
  },
  ἱεροσύλους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2417",
    gloss: "temple robber",
  },
  ἱερουργοῦντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G2418",
    gloss: "to minister",
  },
  Ἱερουσαλὴμ: {
    parse: "N-APN-L｜Noun, accusative, plural, neuter, Location",
    GN: "G2419",
    gloss: "Jerusalem",
  },
  Ἰερουσαλήμ: {
    parse:
      "N-VSF-L｜Noun, Vocative or nominative, singular, feminine, Location",
    GN: "G2419",
    gloss: "Jerusalem",
  },
  Ἰερουσαλὴμ: {
    parse:
      "N-VSF-L｜Noun, Vocative or nominative, singular, feminine, Location",
    GN: "G2419",
    gloss: "Jerusalem",
  },
  ἱερωσύνην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2420",
    gloss: "priesthood",
  },
  ἱερωσύνης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2420",
    gloss: "priesthood",
  },
  Ἰεσσαί: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2421",
    gloss: "Jesse",
  },
  Ἰεσσαὶ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2421",
    gloss: "Jesse",
  },
  Ἰεφθάε: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2422",
    gloss: "Jephthah",
  },
  Ἰεχονίαν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2423",
    gloss: "Jechoniah",
  },
  Ἰεχονίας: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2423",
    gloss: "Jechoniah",
  },
  Ἰησοῦ: {
    parse: "N-VSM-P｜Noun, Vocative or nominative, singular, masculine, Person",
    GN: "G2424",
    gloss: "Jesus (or Joshua)",
  },
  Ἰησοῦν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2424",
    gloss: "Jesus (or Joshua)",
  },
  Ἰησοῦς: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2424",
    gloss: "Jesus (or Joshua)",
  },
  ἱκανὰ: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G2425",
    gloss: "sufficient",
  },
  ἱκαναί: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G2425",
    gloss: "sufficient",
  },
  ἱκαναὶ: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G2425",
    gloss: "sufficient",
  },
  ἱκαναῖς: {
    parse: "A-DPF｜Adjective, dative, plural, feminine",
    GN: "G2425",
    gloss: "sufficient",
  },
  ἱκανὰς: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G2425",
    gloss: "sufficient",
  },
  ἱκανοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G2425",
    gloss: "sufficient",
  },
  ἱκανοὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G2425",
    gloss: "sufficient",
  },
  ἱκανοῖς: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G2425",
    gloss: "sufficient",
  },
  ἱκανόν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G2425",
    gloss: "sufficient",
  },
  ἱκανὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G2425",
    gloss: "sufficient",
  },
  Ἱκανόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G2425",
    gloss: "sufficient",
  },
  ἱκανός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2425",
    gloss: "sufficient",
  },
  ἱκανὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2425",
    gloss: "sufficient",
  },
  ἱκανοῦ: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G2425",
    gloss: "sufficient",
  },
  Ἱκανοῦ: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G2425",
    gloss: "sufficient",
  },
  ἱκανούς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2425",
    gloss: "sufficient",
  },
  ἱκανοὺς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2425",
    gloss: "sufficient",
  },
  ἱκανῷ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G2425",
    gloss: "sufficient",
  },
  ἱκανῶν: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G2425",
    gloss: "sufficient",
  },
  ἱκανότης: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2426",
    gloss: "sufficiency",
  },
  ἱκανώσαντι: {
    parse:
      "V-AAP-DSM｜Verb, aorist, active, Participle, dative, singular, masculine",
    GN: "G2427",
    gloss: "to qualify",
  },
  ἱκάνωσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2427",
    gloss: "to qualify",
  },
  ἱκετηρίας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G2428",
    gloss: "supplication",
  },
  ἰκμάδα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2429",
    gloss: "moisture",
  },
  Ἰκόνιον: {
    parse: "N-ASN-L｜Noun, accusative, singular, neuter, Location",
    GN: "G2430",
    gloss: "Iconium",
  },
  Ἰκονίου: {
    parse: "N-GSN-L｜Noun, genitive, singular, neuter, Location",
    GN: "G2430",
    gloss: "Iconium",
  },
  Ἰκονίῳ: {
    parse: "N-DSN-L｜Noun, dative, singular, neuter, Location",
    GN: "G2430",
    gloss: "Iconium",
  },
  ἱλαρὸν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G2431",
    gloss: "cheerful",
  },
  ἱλαρότητι: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2432",
    gloss: "cheerfulness",
  },
  ἱλάσθητί: {
    parse: "V-APM-2S｜Verb, aorist, passive, imperative, second, singular",
    GN: "G2433",
    gloss: "to propitiate",
  },
  ἱλάσκεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G2433",
    gloss: "to propitiate",
  },
  ἱλασμὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2434",
    gloss: "propitiation",
  },
  ἱλασμός: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2434",
    gloss: "propitiation",
  },
  ἱλαστήριον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G2435",
    gloss: "propitiation",
  },
  ἵλεως: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2436",
    gloss: "propitious/gracious",
  },
  Ἵλεώς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2436",
    gloss: "propitious/gracious",
  },
  Ἰλλυρικοῦ: {
    parse: "N-GSN-L｜Noun, genitive, singular, neuter, Location",
    GN: "G2437",
    gloss: "Illyricum",
  },
  ἱμάντα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2438",
    gloss: "leather strap",
  },
  ἱμᾶσιν: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G2438",
    gloss: "leather strap",
  },
  ἱματισμένον: {
    parse:
      "V-RPP-ASM｜Verb, perfect, passive, Participle, accusative, singular, masculine",
    GN: "G2439",
    gloss: "to dress",
  },
  ἱμάτια: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G2440",
    gloss: "clothing",
  },
  ἱμάτιά: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G2440",
    gloss: "clothing",
  },
  ἱματίοις: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G2440",
    gloss: "clothing",
  },
  ἱμάτιον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2440",
    gloss: "clothing",
  },
  ἱμάτιόν: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G2440",
    gloss: "clothing",
  },
  ἱματίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G2440",
    gloss: "clothing",
  },
  ἱματίῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G2440",
    gloss: "clothing",
  },
  ἱματίων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G2440",
    gloss: "clothing",
  },
  ἱματισμόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2441",
    gloss: "clothing",
  },
  ἱματισμὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2441",
    gloss: "clothing",
  },
  ἱματισμοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2441",
    gloss: "clothing",
  },
  ἱματισμῷ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G2441",
    gloss: "clothing",
  },
  ὁμειρόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G2442",
    gloss: "to desire",
  },
  ἵνα: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G2443",
    gloss: "in order that/to",
  },
  Ἵνα: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G2443",
    gloss: "in order that/to",
  },
  ἱνατί: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2444",
    gloss: "why?",
  },
  Ἱνατί: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2444",
    gloss: "why?",
  },
  Ἰόππῃ: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G2445",
    gloss: "Joppa",
  },
  Ἰόππην: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G2445",
    gloss: "Joppa",
  },
  Ἰόππης: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G2445",
    gloss: "Joppa",
  },
  Ἰορδάνῃ: {
    parse: "N-DSM-L｜Noun, dative, singular, masculine, Location",
    GN: "G2446",
    gloss: "Jordan",
  },
  Ἰορδάνην: {
    parse: "N-ASM-L｜Noun, accusative, singular, masculine, Location",
    GN: "G2446",
    gloss: "Jordan",
  },
  Ἰορδάνου: {
    parse: "N-GSM-L｜Noun, genitive, singular, masculine, Location",
    GN: "G2446",
    gloss: "Jordan",
  },
  ἰὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2447",
    gloss: "poison/rust",
  },
  ἰοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2447",
    gloss: "poison/rust",
  },
  Ἰούδα: {
    parse: "N-VSM-P｜Noun, Vocative or nominative, singular, masculine, Person",
    GN: "G2455",
    gloss: "Judah",
  },
  Ἰουδαίᾳ: {
    parse: "A-DSF-PG｜Adjective, dative, singular, feminine, Person Gentilic",
    GN: "G2453",
    gloss: "Jewish",
  },
  Ἰουδαία: {
    parse: "N-NSF-L｜Noun, nominative, singular, feminine, Location",
    GN: "G2449",
    gloss: "Judea",
  },
  Ἰουδαίαν: {
    parse:
      "A-ASF-PG｜Adjective, accusative, singular, feminine, Person Gentilic",
    GN: "G2453",
    gloss: "Jewish",
  },
  Ἰουδαίας: {
    parse: "A-GSF-PG｜Adjective, genitive, singular, feminine, Person Gentilic",
    GN: "G2453",
    gloss: "Jewish",
  },
  Ἰουδαΐζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2450",
    gloss: "Jew",
  },
  Ἰουδαϊκοῖς: {
    parse: "A-DPM-PG｜Adjective, dative, plural, masculine, Person Gentilic",
    GN: "G2451",
    gloss: "Jewish",
  },
  Ἰουδαϊκῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2452",
    gloss: "Jew",
  },
  Ἰουδαῖοι: {
    parse:
      "A-VPM-PG｜Adjective, Vocative or nominative, plural, masculine, Person Gentilic",
    GN: "G2453",
    gloss: "Jewish",
  },
  Ἰουδαῖοί: {
    parse:
      "A-NPM-PG｜Adjective, nominative, plural, masculine, Person Gentilic",
    GN: "G2453",
    gloss: "Jewish",
  },
  Ἰουδαίοις: {
    parse: "A-DPM-PG｜Adjective, dative, plural, masculine, Person Gentilic",
    GN: "G2453",
    gloss: "Jewish",
  },
  Ἰουδαῖον: {
    parse:
      "A-ASM-PG｜Adjective, accusative, singular, masculine, Person Gentilic",
    GN: "G2453",
    gloss: "Jewish",
  },
  Ἰουδαῖος: {
    parse:
      "A-NSM-PG｜Adjective, nominative, singular, masculine, Person Gentilic",
    GN: "G2453",
    gloss: "Jewish",
  },
  Ἰουδαῖός: {
    parse:
      "A-NSM-PG｜Adjective, nominative, singular, masculine, Person Gentilic",
    GN: "G2453",
    gloss: "Jewish",
  },
  Ἰουδαίου: {
    parse:
      "A-GSM-PG｜Adjective, genitive, singular, masculine, Person Gentilic",
    GN: "G2453",
    gloss: "Jewish",
  },
  Ἰουδαίους: {
    parse:
      "A-APM-PG｜Adjective, accusative, plural, masculine, Person Gentilic",
    GN: "G2453",
    gloss: "Jewish",
  },
  Ἰουδαίῳ: {
    parse: "A-DSM-PG｜Adjective, dative, singular, masculine, Person Gentilic",
    GN: "G2453",
    gloss: "Jewish",
  },
  Ἰουδαίων: {
    parse: "A-GPM-PG｜Adjective, genitive, plural, masculine, Person Gentilic",
    GN: "G2453",
    gloss: "Jewish",
  },
  Ἰουδαϊσμῷ: {
    parse: "N-DSM-T｜Noun, dative, singular, masculine, Title",
    GN: "G2454",
    gloss: "Judaism",
  },
  Ἰούδᾳ: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G2455",
    gloss: "Judah",
  },
  Ἰούδαν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2455",
    gloss: "Judah",
  },
  Ἰούδας: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2455",
    gloss: "Judah",
  },
  Ἰουλίαν: {
    parse: "N-ASF-P｜Noun, accusative, singular, feminine, Person",
    GN: "G2456",
    gloss: "Julia",
  },
  Ἰούλιος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2457",
    gloss: "Julius",
  },
  Ἰουλίῳ: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G2457",
    gloss: "Julius",
  },
  Ἰουνίαν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2458",
    gloss: "Junia",
  },
  Ἰοῦστος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2459",
    gloss: "Justus",
  },
  Ἰούστου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2459",
    gloss: "Justus",
  },
  ἱππεῖς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G2460",
    gloss: "horseman",
  },
  ἱππικοῦ: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G2461",
    gloss: "horseman",
  },
  ἵπποις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G2462",
    gloss: "horse",
  },
  ἵππος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2462",
    gloss: "horse",
  },
  ἵππου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2462",
    gloss: "horse",
  },
  ἵππους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G2462",
    gloss: "horse",
  },
  ἵππων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G2462",
    gloss: "horse",
  },
  ἶρις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2463",
    gloss: "rainbow",
  },
  Ἰσαάκ: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2464",
    gloss: "Isaac",
  },
  Ἰσαὰκ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2464",
    gloss: "Isaac",
  },
  ἰσάγγελοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G2465",
    gloss: "like an angel",
  },
  Ἰσσαχὰρ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2466",
    gloss: "Issachar",
  },
  Ἰσκαριώθ: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2469",
    gloss: "Iscariot",
  },
  Ἰσκαριὼθ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2469",
    gloss: "Iscariot",
  },
  Ἰσκαριώτην: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2469",
    gloss: "Iscariot",
  },
  Ἰσκαριώτης: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2469",
    gloss: "Iscariot",
  },
  Ἰσκαριώτου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2469",
    gloss: "Iscariot",
  },
  ἴσα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G2470",
    gloss: "equal",
  },
  ἴσαι: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G2470",
    gloss: "equal",
  },
  ἴση: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G2470",
    gloss: "equal",
  },
  ἴσην: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G2470",
    gloss: "equal",
  },
  ἴσον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G2470",
    gloss: "equal",
  },
  ἴσους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2470",
    gloss: "equal",
  },
  ἰσότης: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2471",
    gloss: "equality",
  },
  ἰσότητα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2471",
    gloss: "equality",
  },
  ἰσότητος: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2471",
    gloss: "equality",
  },
  ἰσότιμον: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G2472",
    gloss: "equally valuable",
  },
  ἰσόψυχον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G2473",
    gloss: "like-minded",
  },
  Ἰσραήλ: {
    parse:
      "N-VSM-L｜Noun, Vocative or nominative, singular, masculine, Location",
    GN: "G2474",
    gloss: "Israel",
  },
  Ἰσραὴλ: {
    parse: "N-NSM-L｜Noun, nominative, singular, masculine, Location",
    GN: "G2474",
    gloss: "Israel",
  },
  Ἰσραηλῖται: {
    parse:
      "N-VPM-PG｜Noun, Vocative or nominative, plural, masculine, Person Gentilic",
    GN: "G2475",
    gloss: "Israelite",
  },
  Ἰσραηλῖταί: {
    parse: "N-NPM-PG｜Noun, nominative, plural, masculine, Person Gentilic",
    GN: "G2475",
    gloss: "Israelite",
  },
  Ἰσραηλίτης: {
    parse: "N-NSM-PG｜Noun, nominative, singular, masculine, Person Gentilic",
    GN: "G2475",
    gloss: "Israelite",
  },
  εἱστήκει: {
    parse: "V-LAI-3S｜Verb, pluperfect, active, indicative, third, singular",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  εἱστήκεισαν: {
    parse: "V-LAI-3P｜Verb, pluperfect, active, indicative, third, plural",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  Εἱστήκεισαν: {
    parse: "V-LAI-3P｜Verb, pluperfect, active, indicative, third, plural",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  ἐστάθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  ἐστάθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  ἑστάναι: {
    parse: "V-RAN｜Verb, perfect, active, Infinitive",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  ἔστη: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  ἕστηκα: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  ἑστήκαμεν: {
    parse: "V-RAI-1P｜Verb, perfect, active, indicative, first, plural",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  ἕστηκας: {
    parse: "V-RAI-2S｜Verb, perfect, active, indicative, second, singular",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  ἑστήκασιν: {
    parse: "V-RAI-3P｜Verb, perfect, active, indicative, third, plural",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  ἑστήκατε: {
    parse: "V-RAI-2P｜Verb, perfect, active, indicative, second, plural",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  ἔστηκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  ἕστηκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  ἑστηκὸς: {
    parse:
      "V-RAP-NSN｜Verb, perfect, active, Participle, nominative, singular, neuter",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  ἑστηκότα: {
    parse:
      "V-RAP-ASM｜Verb, perfect, active, Participle, accusative, singular, masculine",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  ἑστηκότες: {
    parse:
      "V-RAP-NPM｜Verb, perfect, active, Participle, nominative, plural, masculine",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  ἑστηκότων: {
    parse:
      "V-RAP-GPM｜Verb, perfect, active, Participle, genitive, plural, masculine",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  ἑστηκὼς: {
    parse:
      "V-RAP-NSM｜Verb, perfect, active, Participle, nominative, singular, masculine",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  ἔστησαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  ἔστησάν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  ἔστησεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  ἑστὸς: {
    parse:
      "V-2RAP-NSN｜Verb, 2nd perfect, active, Participle, nominative, singular, neuter",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  ἑστώς: {
    parse:
      "V-RAP-NSM｜Verb, perfect, active, Participle, nominative, singular, masculine",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  ἑστὼς: {
    parse:
      "V-RAP-NSM｜Verb, perfect, active, Participle, nominative, singular, masculine",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  ἑστῶτα: {
    parse:
      "V-RAP-ASM｜Verb, perfect, active, Participle, accusative, singular, masculine",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  ἑστῶτας: {
    parse:
      "V-RAP-APM｜Verb, perfect, active, Participle, accusative, plural, masculine",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  ἑστῶτες: {
    parse:
      "V-RAP-NPM｜Verb, perfect, active, Participle, nominative, plural, masculine",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  ἑστῶτος: {
    parse:
      "V-RAP-GSM｜Verb, perfect, active, Participle, genitive, singular, masculine",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  ἑστώτων: {
    parse:
      "V-RAP-GPM｜Verb, perfect, active, Participle, genitive, plural, masculine",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  ἱστάνομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  σταθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  Σταθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  σταθέντα: {
    parse:
      "V-APP-ASM｜Verb, aorist, passive, Participle, accusative, singular, masculine",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  σταθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  σταθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  σταθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  σταθήσεσθε: {
    parse: "V-FPI-2P｜Verb, future, passive, indicative, second, plural",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  σταθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  σταθῆτε: {
    parse: "V-APS-2P｜Verb, aorist, passive, subjunctive, second, plural",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  στάντος: {
    parse:
      "V-2AAP-GSM｜Verb, 2nd aorist, active, Participle, genitive, singular, masculine",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  στὰς: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  στᾶσα: {
    parse:
      "V-2AAP-NSF｜Verb, 2nd aorist, active, Participle, nominative, singular, feminine",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  στῆθι: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  στῆναι: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  στῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  στήσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  στήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  στήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  στήσῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  στήσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  στήσονται: {
    parse: "V-FDI-3P｜Verb, future, Middle deponent, indicative, third, plural",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  στῆτε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G2476",
    gloss: "to stand/make stand",
  },
  ἱστορῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2477",
    gloss: "to get acquainted",
  },
  ἰσχυρά: {
    parse: "A-VSF｜Adjective, Vocative or nominative, singular, feminine",
    GN: "G2478",
    gloss: "strong",
  },
  ἰσχυρᾷ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G2478",
    gloss: "strong",
  },
  ἰσχυρὰ: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G2478",
    gloss: "strong",
  },
  ἰσχυραί: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G2478",
    gloss: "strong",
  },
  ἰσχυρὰν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G2478",
    gloss: "strong",
  },
  ἰσχυρᾶς: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G2478",
    gloss: "strong",
  },
  ἰσχυροί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G2478",
    gloss: "strong",
  },
  ἰσχυροὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G2478",
    gloss: "strong",
  },
  ἰσχυρόν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G2478",
    gloss: "strong",
  },
  ἰσχυρὸν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G2478",
    gloss: "strong",
  },
  ἰσχυρὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2478",
    gloss: "strong",
  },
  ἰσχυρότεροι: {
    parse: "A-NPM-C｜Adjective, nominative, plural, masculine, Comparative",
    GN: "G2478",
    gloss: "strong",
  },
  ἰσχυρότερον: {
    parse: "A-NSN-C｜Adjective, nominative, singular, neuter, Comparative",
    GN: "G2478",
    gloss: "strong",
  },
  ἰσχυρότερος: {
    parse: "A-NSM-C｜Adjective, nominative, singular, masculine, Comparative",
    GN: "G2478",
    gloss: "strong",
  },
  ἰσχυρότερός: {
    parse: "A-NSM-C｜Adjective, nominative, singular, masculine, Comparative",
    GN: "G2478",
    gloss: "strong",
  },
  ἰσχυροῦ: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G2478",
    gloss: "strong",
  },
  ἰσχυρῶν: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G2478",
    gloss: "strong",
  },
  ἰσχύϊ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2479",
    gloss: "strength",
  },
  ἰσχὺν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2479",
    gloss: "strength",
  },
  ἰσχύος: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2479",
    gloss: "strength",
  },
  ἰσχὺς: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2479",
    gloss: "strength",
  },
  ἰσχύει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2480",
    gloss: "be strong",
  },
  ἰσχύειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2480",
    gloss: "be strong",
  },
  ἴσχυεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G2480",
    gloss: "be strong",
  },
  ἴσχυον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2480",
    gloss: "be strong",
  },
  ἰσχύοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2480",
    gloss: "be strong",
  },
  ἰσχύοντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G2480",
    gloss: "be strong",
  },
  ἰσχύσαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G2480",
    gloss: "be strong",
  },
  ἴσχυσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2480",
    gloss: "be strong",
  },
  ἴσχυσας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G2480",
    gloss: "be strong",
  },
  ἰσχύσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G2480",
    gloss: "be strong",
  },
  ἴσχυσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2480",
    gloss: "be strong",
  },
  ἰσχύσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G2480",
    gloss: "be strong",
  },
  ἰσχύω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G2480",
    gloss: "be strong",
  },
  ἴσως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2481",
    gloss: "perhaps",
  },
  Ἰταλίαν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G2482",
    gloss: "Italy",
  },
  Ἰταλίας: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G2482",
    gloss: "Italy",
  },
  Ἰταλικῆς: {
    parse:
      "A-GSF-LG｜Adjective, genitive, singular, feminine, Location Gentilic",
    GN: "G2483",
    gloss: "Italian",
  },
  Ἰτουραίας: {
    parse: "A-GSF-L｜Adjective, genitive, singular, feminine, Location",
    GN: "G2484",
    gloss: "Ituraea",
  },
  ἰχθύδια: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G2485",
    gloss: "little fish",
  },
  ἰχθύας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G2486",
    gloss: "fish",
  },
  ἰχθύες: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G2486",
    gloss: "fish",
  },
  ἰχθύν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2486",
    gloss: "fish",
  },
  ἰχθὺν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2486",
    gloss: "fish",
  },
  ἰχθύος: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2486",
    gloss: "fish",
  },
  ἰχθύων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G2486",
    gloss: "fish",
  },
  ἴχνεσιν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G2487",
    gloss: "track",
  },
  Ἰωαθάμ: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2488",
    gloss: "Jotham",
  },
  Ἰωαθὰμ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2488",
    gloss: "Jotham",
  },
  Ἰωάννα: {
    parse: "N-NSF-P｜Noun, nominative, singular, feminine, Person",
    GN: "G2489",
    gloss: "Joanna",
  },
  Ἰωανὰν: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2490",
    gloss: "Joanan",
  },
  Ἰωάννῃ: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G2491",
    gloss: "John",
  },
  Ἰωάννην: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2491",
    gloss: "John",
  },
  Ἰωάννης: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2491",
    gloss: "John",
  },
  Ἰωάννου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2491",
    gloss: "John",
  },
  Ἰὼβ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2492",
    gloss: "Job",
  },
  Ἰωήλ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2493",
    gloss: "Joel",
  },
  Ἰωνὰμ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2494",
    gloss: "Jonam",
  },
  Ἰωνᾶ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2495",
    gloss: "Jonah",
  },
  Ἰωνᾶς: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2495",
    gloss: "Jonah",
  },
  Ἰωράμ: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2496",
    gloss: "Joram",
  },
  Ἰωρὰμ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2496",
    gloss: "Joram",
  },
  Ἰωρὶμ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2497",
    gloss: "Jorim",
  },
  Ἰωσαφάτ: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2498",
    gloss: "Jehoshaphat",
  },
  Ἰωσαφὰτ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2498",
    gloss: "Jehoshaphat",
  },
  Ἰωσῆτος: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2500",
    gloss: "Joseph",
  },
  Ἰωσήφ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2501",
    gloss: "Joseph",
  },
  Ἰωσὴφ: {
    parse: "N-VSM-P｜Noun, Vocative or nominative, singular, masculine, Person",
    GN: "G2501",
    gloss: "Joseph",
  },
  Ἰωσίαν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2502",
    gloss: "Josiah",
  },
  Ἰωσίας: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2502",
    gloss: "Josiah",
  },
  ἰῶτα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2503",
    gloss: "iota",
  },
  κἀγώ: {
    parse: "P-1NS-K｜Personal pronoun, first, nominative, singular, Kai",
    GN: "G2504",
    gloss: "and I",
  },
  Κἀγώ: {
    parse: "P-1NS｜Personal pronoun, first, nominative, singular",
    GN: "G2504",
    gloss: "and I",
  },
  Κἀγὼ: {
    parse: "P-1NS｜Personal pronoun, first, nominative, singular",
    GN: "G2504",
    gloss: "and I",
  },
  κἀγὼ: {
    parse: "P-1NS-K｜Personal pronoun, first, nominative, singular, Kai",
    GN: "G2504",
    gloss: "and I",
  },
  "καὶ ἐγώ": {
    parse: "P-1NS-K｜Personal pronoun, first, nominative, singular, Kai",
    GN: "G2504",
    gloss: "and I",
  },
  "καὶ ἐγὼ": {
    parse: "P-1NS-K｜Personal pronoun, first, nominative, singular, Kai",
    GN: "G2504",
    gloss: "and I",
  },
  Κἀμὲ: {
    parse: "P-1AS｜Personal pronoun, first, accusative, singular",
    GN: "G2504",
    gloss: "and I",
  },
  κἀμὲ: {
    parse: "P-1AS-K｜Personal pronoun, first, accusative, singular, Kai",
    GN: "G2504",
    gloss: "and I",
  },
  κἀμοί: {
    parse: "P-1DS-K｜Personal pronoun, first, dative, singular, Kai",
    GN: "G2504",
    gloss: "and I",
  },
  κἀμοὶ: {
    parse: "P-1DS-K｜Personal pronoun, first, dative, singular, Kai",
    GN: "G2504",
    gloss: "and I",
  },
  καθὰ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G2505",
    gloss: "as/just as",
  },
  καθαίρεσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2506",
    gloss: "destruction",
  },
  καθαιρεῖσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G2507",
    gloss: "to take down",
  },
  καθαιροῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2507",
    gloss: "to take down",
  },
  καθεῖλεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G2507",
    gloss: "to take down",
  },
  καθελεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G2507",
    gloss: "to take down",
  },
  καθελόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G2507",
    gloss: "to take down",
  },
  καθελῶ: {
    parse: "V-2FAI-1S｜Verb, 2nd future, active, indicative, first, singular",
    GN: "G2507",
    gloss: "to take down",
  },
  καθελὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G2507",
    gloss: "to take down",
  },
  καθαίρει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2508",
    gloss: "to prune",
  },
  καθάπερ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G2509",
    gloss: "just as",
  },
  Καθάπερ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G2509",
    gloss: "just as",
  },
  καθῆψε: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2510",
    gloss: "to attach",
  },
  ἐκαθάρισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2511",
    gloss: "to clean",
  },
  ἐκαθαρίσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2511",
    gloss: "to clean",
  },
  ἐκαθαρίσθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G2511",
    gloss: "to clean",
  },
  καθαριεῖ: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2511",
    gloss: "to clean",
  },
  καθαρίζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2511",
    gloss: "to clean",
  },
  καθαρίζεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G2511",
    gloss: "to clean",
  },
  καθαρίζεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G2511",
    gloss: "to clean",
  },
  καθαρίζετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G2511",
    gloss: "to clean",
  },
  καθαρίζονται: {
    parse: "V-PPI-3P｜Verb, present, passive, indicative, third, plural",
    GN: "G2511",
    gloss: "to clean",
  },
  καθαρίζων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2511",
    gloss: "to clean",
  },
  καθαρίσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2511",
    gloss: "to clean",
  },
  καθαρίσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G2511",
    gloss: "to clean",
  },
  καθαρίσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G2511",
    gloss: "to clean",
  },
  καθαρίσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G2511",
    gloss: "to clean",
  },
  καθαρίσθητι: {
    parse: "V-APM-2S｜Verb, aorist, passive, imperative, second, singular",
    GN: "G2511",
    gloss: "to clean",
  },
  καθάρισον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G2511",
    gloss: "to clean",
  },
  καθαρίσωμεν: {
    parse: "V-AAS-1P｜Verb, aorist, active, subjunctive, first, plural",
    GN: "G2511",
    gloss: "to clean",
  },
  κεκαθαρισμένους: {
    parse:
      "V-RPP-APM｜Verb, perfect, passive, Participle, accusative, plural, masculine",
    GN: "G2511",
    gloss: "to clean",
  },
  καθαρισμὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2512",
    gloss: "cleansing",
  },
  καθαρισμοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2512",
    gloss: "cleansing",
  },
  καθαρᾷ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G2513",
    gloss: "clean",
  },
  καθαρά: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G2513",
    gloss: "clean",
  },
  καθαρὰ: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G2513",
    gloss: "clean",
  },
  καθαρᾶς: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G2513",
    gloss: "clean",
  },
  καθαροί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G2513",
    gloss: "clean",
  },
  καθαροὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G2513",
    gloss: "clean",
  },
  καθαροῖς: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G2513",
    gloss: "clean",
  },
  καθαρόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G2513",
    gloss: "clean",
  },
  καθαρὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G2513",
    gloss: "clean",
  },
  καθαρός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2513",
    gloss: "clean",
  },
  καθαρὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2513",
    gloss: "clean",
  },
  καθαρῷ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G2513",
    gloss: "clean",
  },
  καθαρότητα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2514",
    gloss: "cleanness",
  },
  καθέδρας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2515",
    gloss: "seat",
  },
  ἐκαθέζετο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G2516",
    gloss: "to sit down",
  },
  ἐκαθεζόμην: {
    parse:
      "V-INI-1S｜Verb, imperfect, Middle or Passive deponent, indicative, first, singular",
    GN: "G2516",
    gloss: "to sit down",
  },
  καθεζόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G2516",
    gloss: "to sit down",
  },
  καθεζόμενον: {
    parse:
      "V-PNP-ASM｜Verb, present, Middle or Passive deponent, Participle, accusative, singular, masculine",
    GN: "G2516",
    gloss: "to sit down",
  },
  καθεζόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G2516",
    gloss: "to sit down",
  },
  καθεζομένους: {
    parse:
      "V-PNP-APM｜Verb, present, Middle or Passive deponent, Participle, accusative, plural, masculine",
    GN: "G2516",
    gloss: "to sit down",
  },
  καθεξῆς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2517",
    gloss: "in order",
  },
  ἐκάθευδεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G2518",
    gloss: "to sleep",
  },
  ἐκάθευδον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2518",
    gloss: "to sleep",
  },
  καθεύδει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2518",
    gloss: "to sleep",
  },
  καθεύδειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2518",
    gloss: "to sleep",
  },
  καθεύδεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G2518",
    gloss: "to sleep",
  },
  καθεύδετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G2518",
    gloss: "to sleep",
  },
  Καθεύδετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G2518",
    gloss: "to sleep",
  },
  καθεύδῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G2518",
    gloss: "to sleep",
  },
  καθεύδοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G2518",
    gloss: "to sleep",
  },
  καθεύδοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2518",
    gloss: "to sleep",
  },
  καθεύδουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2518",
    gloss: "to sleep",
  },
  καθεύδωμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G2518",
    gloss: "to sleep",
  },
  καθεύδων: {
    parse:
      "V-PAP-VSM｜Verb, present, active, Participle, Vocative or nominative, singular, masculine",
    GN: "G2518",
    gloss: "to sleep",
  },
  καθηγηταί: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G2519",
    gloss: "teacher",
  },
  καθηγητὴς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2519",
    gloss: "teacher",
  },
  καθῆκεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G2520",
    gloss: "be fit",
  },
  καθήκοντα: {
    parse:
      "V-PAP-APN｜Verb, present, active, Participle, accusative, plural, neuter",
    GN: "G2520",
    gloss: "be fit",
  },
  ἐκάθητο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G2521",
    gloss: "to sit",
  },
  κάθῃ: {
    parse:
      "V-PNI-2S｜Verb, present, Middle or Passive deponent, indicative, second, singular",
    GN: "G2521",
    gloss: "to sit",
  },
  Κάθημαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G2521",
    gloss: "to sit",
  },
  καθήμεναι: {
    parse:
      "V-PNP-NPF｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, feminine",
    GN: "G2521",
    gloss: "to sit",
  },
  καθημένην: {
    parse:
      "V-PNP-ASF｜Verb, present, Middle or Passive deponent, Participle, accusative, singular, feminine",
    GN: "G2521",
    gloss: "to sit",
  },
  καθημένης: {
    parse:
      "V-PNP-GSF｜Verb, present, Middle or Passive deponent, Participle, genitive, singular, feminine",
    GN: "G2521",
    gloss: "to sit",
  },
  καθήμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G2521",
    gloss: "to sit",
  },
  καθημένοις: {
    parse:
      "V-PNP-DPN｜Verb, present, Middle or Passive deponent, Participle, dative, plural, neuter",
    GN: "G2521",
    gloss: "to sit",
  },
  καθήμενον: {
    parse:
      "V-PNP-ASM｜Verb, present, Middle or Passive deponent, Participle, accusative, singular, masculine",
    GN: "G2521",
    gloss: "to sit",
  },
  καθήμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G2521",
    gloss: "to sit",
  },
  καθημένου: {
    parse:
      "V-PNP-GSM｜Verb, present, Middle or Passive deponent, Participle, genitive, singular, masculine",
    GN: "G2521",
    gloss: "to sit",
  },
  Καθημένου: {
    parse:
      "V-PNP-GSM｜Verb, present, Middle or Passive deponent, Participle, genitive, singular, masculine",
    GN: "G2521",
    gloss: "to sit",
  },
  καθημένους: {
    parse:
      "V-PNP-APM｜Verb, present, Middle or Passive deponent, Participle, accusative, plural, masculine",
    GN: "G2521",
    gloss: "to sit",
  },
  καθημένῳ: {
    parse:
      "V-PNP-DSM｜Verb, present, Middle or Passive deponent, Participle, dative, singular, masculine",
    GN: "G2521",
    gloss: "to sit",
  },
  καθημένων: {
    parse:
      "V-PNP-GPM｜Verb, present, Middle or Passive deponent, Participle, genitive, plural, masculine",
    GN: "G2521",
    gloss: "to sit",
  },
  καθήσεσθε: {
    parse:
      "V-FDI-2P｜Verb, future, Middle deponent, indicative, second, plural",
    GN: "G2521",
    gloss: "to sit",
  },
  καθῆσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G2521",
    gloss: "to sit",
  },
  κάθηται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G2521",
    gloss: "to sit",
  },
  κάθου: {
    parse:
      "V-PNM-2S｜Verb, present, Middle or Passive deponent, imperative, second, singular",
    GN: "G2521",
    gloss: "to sit",
  },
  Κάθου: {
    parse:
      "V-PNM-2S｜Verb, present, Middle or Passive deponent, imperative, second, singular",
    GN: "G2521",
    gloss: "to sit",
  },
  καθημερινῇ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G2522",
    gloss: "daily",
  },
  ἐκάθισα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G2523",
    gloss: "to seat",
  },
  ἐκάθισαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2523",
    gloss: "to seat",
  },
  ἐκάθισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2523",
    gloss: "to seat",
  },
  Ἐκάθισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2523",
    gloss: "to seat",
  },
  καθίζετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G2523",
    gloss: "to seat",
  },
  καθίσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2523",
    gloss: "to seat",
  },
  καθίσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G2523",
    gloss: "to seat",
  },
  καθίσαντος: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G2523",
    gloss: "to seat",
  },
  καθίσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G2523",
    gloss: "to seat",
  },
  καθίσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G2523",
    gloss: "to seat",
  },
  Καθίσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G2523",
    gloss: "to seat",
  },
  καθίσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2523",
    gloss: "to seat",
  },
  καθίσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G2523",
    gloss: "to seat",
  },
  καθίσωμεν: {
    parse: "V-AAS-1P｜Verb, aorist, active, subjunctive, first, plural",
    GN: "G2523",
    gloss: "to seat",
  },
  καθίσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G2523",
    gloss: "to seat",
  },
  κεκάθικεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G2523",
    gloss: "to seat",
  },
  καθῆκαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2524",
    gloss: "to lower",
  },
  καθιεμένην: {
    parse:
      "V-PPP-ASF｜Verb, present, passive, Participle, accusative, singular, feminine",
    GN: "G2524",
    gloss: "to lower",
  },
  καθιέμενον: {
    parse:
      "V-PPP-ASN｜Verb, present, passive, Participle, accusative, singular, neuter",
    GN: "G2524",
    gloss: "to lower",
  },
  καθιστάνοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2525",
    gloss: "to appoint/conduct",
  },
  καθίσταται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G2525",
    gloss: "to appoint/conduct",
  },
  καθίστησιν: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2525",
    gloss: "to appoint/conduct",
  },
  κατασταθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G2525",
    gloss: "to appoint/conduct",
  },
  καταστήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2525",
    gloss: "to appoint/conduct",
  },
  καταστήσῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G2525",
    gloss: "to appoint/conduct",
  },
  καταστήσομεν: {
    parse: "V-FAI-1P｜Verb, future, active, indicative, first, plural",
    GN: "G2525",
    gloss: "to appoint/conduct",
  },
  καταστήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G2525",
    gloss: "to appoint/conduct",
  },
  κατεστάθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G2525",
    gloss: "to appoint/conduct",
  },
  κατέστησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2525",
    gloss: "to appoint/conduct",
  },
  καθὸ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G2526",
    gloss: "insofar as",
  },
  καθόλου: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2527",
    gloss: "at all",
  },
  καθωπλισμένος: {
    parse:
      "V-RMP-NSM｜Verb, perfect, middle, Participle, nominative, singular, masculine",
    GN: "G2528",
    gloss: "to arm fully",
  },
  καθορᾶται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G2529",
    gloss: "to perceive",
  },
  καθότι: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G2530",
    gloss: "as/just as",
  },
  καθώς: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G2531",
    gloss: "as/just as",
  },
  καθὼς: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G2531",
    gloss: "as/just as",
  },
  Καθὼς: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G2531",
    gloss: "as/just as",
  },
  καί: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G2532",
    gloss: "and",
  },
  Καί: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G2532",
    gloss: "and",
  },
  καὶ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G2532",
    gloss: "and",
  },
  Καὶ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G2532",
    gloss: "and",
  },
  Καϊάφα: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2533",
    gloss: "[wife of Ciaphas]",
  },
  Καϊάφαν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2533",
    gloss: "[wife of Ciaphas]",
  },
  Καϊάφας: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2533",
    gloss: "[wife of Ciaphas]",
  },
  Κάϊν: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2535",
    gloss: "Cain",
  },
  Καϊνὰμ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2536",
    gloss: "Cainan",
  },
  καινὰ: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G2537",
    gloss: "new",
  },
  καινά: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G2537",
    gloss: "new",
  },
  καιναῖς: {
    parse: "A-DPF｜Adjective, dative, plural, feminine",
    GN: "G2537",
    gloss: "new",
  },
  καινή: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G2537",
    gloss: "new",
  },
  καινὴ: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G2537",
    gloss: "new",
  },
  καινήν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G2537",
    gloss: "new",
  },
  καινὴν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G2537",
    gloss: "new",
  },
  Καινὴν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G2537",
    gloss: "new",
  },
  καινῆς: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G2537",
    gloss: "new",
  },
  καινὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G2537",
    gloss: "new",
  },
  καινόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G2537",
    gloss: "new",
  },
  καινότερον: {
    parse: "A-ASN-C｜Adjective, accusative, singular, neuter, Comparative",
    GN: "G2537",
    gloss: "new",
  },
  καινοῦ: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G2537",
    gloss: "new",
  },
  καινούς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2537",
    gloss: "new",
  },
  καινοὺς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2537",
    gloss: "new",
  },
  καινῷ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G2537",
    gloss: "new",
  },
  καινότητι: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2538",
    gloss: "newness",
  },
  καίπερ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G2539",
    gloss: "although",
  },
  καιροὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G2540",
    gloss: "time/right time",
  },
  καιροῖς: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G2540",
    gloss: "time/right time",
  },
  καιρόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2540",
    gloss: "time/right time",
  },
  καιρὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2540",
    gloss: "time/right time",
  },
  καιρός: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2540",
    gloss: "time/right time",
  },
  καιρὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2540",
    gloss: "time/right time",
  },
  καιροῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2540",
    gloss: "time/right time",
  },
  καιροὺς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G2540",
    gloss: "time/right time",
  },
  καιρῷ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G2540",
    gloss: "time/right time",
  },
  Καιρῷ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G2540",
    gloss: "time/right time",
  },
  καιρῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G2540",
    gloss: "time/right time",
  },
  Καίσαρα: {
    parse: "N-ASM-T｜Noun, accusative, singular, masculine, Title",
    GN: "G2541",
    gloss: "Caesar",
  },
  Καίσαρά: {
    parse: "N-ASM-T｜Noun, accusative, singular, masculine, Title",
    GN: "G2541",
    gloss: "Caesar",
  },
  Καίσαρι: {
    parse: "N-DSM-T｜Noun, dative, singular, masculine, Title",
    GN: "G2541",
    gloss: "Caesar",
  },
  Καίσαρί: {
    parse: "N-DSM-T｜Noun, dative, singular, masculine, Title",
    GN: "G2541",
    gloss: "Caesar",
  },
  Καίσαρος: {
    parse: "N-GSM-T｜Noun, genitive, singular, masculine, Title",
    GN: "G2541",
    gloss: "Caesar",
  },
  Καισαρείᾳ: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G2542",
    gloss: "Caesarea",
  },
  Καισάρειαν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G2542",
    gloss: "Caesarea",
  },
  Καισαρείας: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G2542",
    gloss: "Caesarea",
  },
  καίτοι: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G2543",
    gloss: "and yet/although",
  },
  καίτοιγε: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G2544",
    gloss: "and yet/although",
  },
  καίεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G2545",
    gloss: "to kindle/burn",
  },
  καιόμεναι: {
    parse:
      "V-PPP-NPF｜Verb, present, passive, Participle, nominative, plural, feminine",
    GN: "G2545",
    gloss: "to kindle/burn",
  },
  καιομένῃ: {
    parse:
      "V-PPP-DSF｜Verb, present, passive, Participle, dative, singular, feminine",
    GN: "G2545",
    gloss: "to kindle/burn",
  },
  καιομένη: {
    parse:
      "V-PPP-NSF｜Verb, present, passive, Participle, nominative, singular, feminine",
    GN: "G2545",
    gloss: "to kindle/burn",
  },
  καιομένης: {
    parse:
      "V-PPP-GSF｜Verb, present, passive, Participle, genitive, singular, feminine",
    GN: "G2545",
    gloss: "to kindle/burn",
  },
  καιόμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G2545",
    gloss: "to kindle/burn",
  },
  καιόμενον: {
    parse:
      "V-PPP-NSN｜Verb, present, passive, Participle, nominative, singular, neuter",
    GN: "G2545",
    gloss: "to kindle/burn",
  },
  καιόμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G2545",
    gloss: "to kindle/burn",
  },
  καίουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2545",
    gloss: "to kindle/burn",
  },
  κεκαυμένῳ: {
    parse:
      "V-RPP-DSN｜Verb, perfect, passive, Participle, dative, singular, neuter",
    GN: "G2545",
    gloss: "to kindle/burn",
  },
  κἀκεῖ: { parse: "ADV-K｜Adverb, Kai", GN: "G2546", gloss: "and there" },
  κἀκεῖθεν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2547",
    gloss: "and from there",
  },
  Κἀκεῖθεν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2547",
    gloss: "and from there",
  },
  κἀκεῖνα: {
    parse: "D-NPN｜Demonstrative pronoun, nominative, plural, neuter",
    GN: "G2548",
    gloss: "and that one",
  },
  κἀκεῖνοι: {
    parse: "D-NPM｜Demonstrative pronoun, nominative, plural, masculine",
    GN: "G2548",
    gloss: "and that one",
  },
  κἀκεῖνον: {
    parse: "D-ASM｜Demonstrative pronoun, accusative, singular, masculine",
    GN: "G2548",
    gloss: "and that one",
  },
  κἀκεῖνος: {
    parse: "D-NSM｜Demonstrative pronoun, nominative, singular, masculine",
    GN: "G2548",
    gloss: "and that one",
  },
  κἀκεῖνός: {
    parse: "D-NSM｜Demonstrative pronoun, nominative, singular, masculine",
    GN: "G2548",
    gloss: "and that one",
  },
  κἀκείνους: {
    parse: "D-APM｜Demonstrative pronoun, accusative, plural, masculine",
    GN: "G2548",
    gloss: "and that one",
  },
  κακίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2549",
    gloss: "evil",
  },
  κακία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2549",
    gloss: "evil",
  },
  κακίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2549",
    gloss: "evil",
  },
  κακίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2549",
    gloss: "evil",
  },
  κακοηθείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2550",
    gloss: "malice",
  },
  κακολογῆσαί: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2551",
    gloss: "to curse/revile",
  },
  κακολογοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2551",
    gloss: "to curse/revile",
  },
  κακολογῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2551",
    gloss: "to curse/revile",
  },
  κακοπαθείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2552",
    gloss: "suffering",
  },
  Κακοπαθεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2553",
    gloss: "to endure",
  },
  κακοπάθησον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G2553",
    gloss: "to endure",
  },
  κακοπαθῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G2553",
    gloss: "to endure",
  },
  κακοποιῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2554",
    gloss: "to do evil/harm",
  },
  κακοποιοῦντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G2554",
    gloss: "to do evil/harm",
  },
  κακοποιῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G2555",
    gloss: "wrongdoing",
  },
  κακοποιὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2555",
    gloss: "wrongdoing",
  },
  κακά: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G2556",
    gloss: "harm",
  },
  κακὰ: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G2556",
    gloss: "harm",
  },
  κακαί: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G2556",
    gloss: "harm",
  },
  κακήν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G2556",
    gloss: "harm",
  },
  κακοὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G2556",
    gloss: "harm",
  },
  κακόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G2556",
    gloss: "harm",
  },
  κακὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G2556",
    gloss: "harm",
  },
  κακὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2556",
    gloss: "harm",
  },
  κακοῦ: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G2556",
    gloss: "harm",
  },
  κακούς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2556",
    gloss: "harm",
  },
  κακοὺς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2556",
    gloss: "harm",
  },
  Κακοὺς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2556",
    gloss: "harm",
  },
  κακῷ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G2556",
    gloss: "harm",
  },
  κακῶν: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G2556",
    gloss: "harm",
  },
  κακοῦργοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G2557",
    gloss: "criminal",
  },
  κακοῦργος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2557",
    gloss: "criminal",
  },
  κακούργους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2557",
    gloss: "criminal",
  },
  κακούργων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G2557",
    gloss: "criminal",
  },
  κακουχούμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G2558",
    gloss: "to torment",
  },
  κακουχουμένων: {
    parse:
      "V-PPP-GPM｜Verb, present, passive, Participle, genitive, plural, masculine",
    GN: "G2558",
    gloss: "to torment",
  },
  ἐκάκωσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2559",
    gloss: "to harm",
  },
  ἐκάκωσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2559",
    gloss: "to harm",
  },
  κακῶσαί: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2559",
    gloss: "to harm",
  },
  κακώσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G2559",
    gloss: "to harm",
  },
  κακώσων: {
    parse:
      "V-FAP-NSM｜Verb, future, active, Participle, nominative, singular, masculine",
    GN: "G2559",
    gloss: "to harm",
  },
  κακῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2560",
    gloss: "badly",
  },
  κάκωσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2561",
    gloss: "oppression",
  },
  καλάμην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2562",
    gloss: "straw/stubble",
  },
  κάλαμον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2563",
    gloss: "reed/stick/pen",
  },
  κάλαμος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2563",
    gloss: "reed/stick/pen",
  },
  καλάμου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2563",
    gloss: "reed/stick/pen",
  },
  καλάμῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G2563",
    gloss: "reed/stick/pen",
  },
  ἐκάλεσα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  ἐκάλεσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  ἐκάλουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  ἐκλήθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  ἐκλήθης: {
    parse: "V-API-2S｜Verb, aorist, passive, indicative, second, singular",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  ἐκλήθητε: {
    parse: "V-API-2P｜Verb, aorist, passive, indicative, second, plural",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  καλεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  κάλει: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  καλεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  καλεῖσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  καλεῖται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  καλεῖτε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  καλέσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  καλέσαντα: {
    parse:
      "V-AAP-ASM｜Verb, aorist, active, Participle, accusative, singular, masculine",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  καλέσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  καλέσαντος: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  καλέσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  καλέσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  καλέσεις: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  καλέσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  Κάλεσον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  καλέσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  Καλέσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  καλουμένῃ: {
    parse:
      "V-PPP-DSF｜Verb, present, passive, Participle, dative, singular, feminine",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  καλουμένη: {
    parse:
      "V-PPP-NSF｜Verb, present, passive, Participle, nominative, singular, feminine",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  καλουμένην: {
    parse:
      "V-PPP-ASF｜Verb, present, passive, Participle, accusative, singular, feminine",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  καλουμένης: {
    parse:
      "V-PPP-GSF｜Verb, present, passive, Participle, genitive, singular, feminine",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  καλούμενον: {
    parse:
      "V-PPP-ASN｜Verb, present, passive, Participle, accusative, singular, neuter",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  καλούμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  καλουμένου: {
    parse:
      "V-PPP-GSN｜Verb, present, passive, Participle, genitive, singular, neuter",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  καλοῦνται: {
    parse: "V-PPI-3P｜Verb, present, passive, indicative, third, plural",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  καλοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  καλοῦντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  καλοῦσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  καλῶν: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G2570",
    gloss: "good",
  },
  κέκληκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  κεκληκότι: {
    parse:
      "V-RAP-DSM｜Verb, perfect, active, Participle, dative, singular, masculine",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  κεκληκώς: {
    parse:
      "V-RAP-NSM｜Verb, perfect, active, Participle, nominative, singular, masculine",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  κεκλημένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  κεκλημένοις: {
    parse:
      "V-RPP-DPM｜Verb, perfect, passive, Participle, dative, plural, masculine",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  κεκλημένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  κεκλημένους: {
    parse:
      "V-RPP-APM｜Verb, perfect, passive, Participle, accusative, plural, masculine",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  κεκλημένων: {
    parse:
      "V-RPP-GPM｜Verb, perfect, passive, Participle, genitive, plural, masculine",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  κέκληται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  κέκληταί: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  κληθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  κληθὲν: {
    parse:
      "V-APP-NSN｜Verb, aorist, passive, Participle, nominative, singular, neuter",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  κληθέντος: {
    parse:
      "V-APP-GSM｜Verb, aorist, passive, Participle, genitive, singular, masculine",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  κληθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  κληθῇς: {
    parse: "V-APS-2S｜Verb, aorist, passive, subjunctive, second, singular",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  κληθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  κληθήσεταί: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  κληθήσῃ: {
    parse: "V-FPI-2S｜Verb, future, passive, indicative, second, singular",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  κληθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  κληθῆτε: {
    parse: "V-APS-2P｜Verb, aorist, passive, subjunctive, second, plural",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  κληθῶμεν: {
    parse: "V-APS-1P｜Verb, aorist, passive, subjunctive, first, plural",
    GN: "G2564",
    gloss: "to call out/to name",
  },
  καλλιέλαιον: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2565",
    gloss: "olive tree",
  },
  κάλλιον: {
    parse: "ADV-C｜Adverb, Contracted form",
    GN: "G2566",
    gloss: "good",
  },
  καλοδιδασκάλους: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G2567",
    gloss: "teaching good",
  },
  καλοποιοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2569",
    gloss: "to do good",
  },
  καλὰ: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G2570",
    gloss: "good",
  },
  καλῇ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G2570",
    gloss: "good",
  },
  καλήν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G2570",
    gloss: "good",
  },
  καλὴν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G2570",
    gloss: "good",
  },
  καλῆς: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G2570",
    gloss: "good",
  },
  καλοὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G2570",
    gloss: "good",
  },
  καλοῖς: {
    parse: "A-DPN｜Adjective, dative, plural, neuter",
    GN: "G2570",
    gloss: "good",
  },
  καλόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G2570",
    gloss: "good",
  },
  καλὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G2570",
    gloss: "good",
  },
  Καλὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G2570",
    gloss: "good",
  },
  καλός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2570",
    gloss: "good",
  },
  καλὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2570",
    gloss: "good",
  },
  καλοῦ: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G2570",
    gloss: "good",
  },
  καλοὺς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2570",
    gloss: "good",
  },
  Καλοὺς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2570",
    gloss: "good",
  },
  καλῷ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G2570",
    gloss: "good",
  },
  κάλυμμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2571",
    gloss: "veil",
  },
  καλύπτει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2572",
    gloss: "to cover",
  },
  καλύπτεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G2572",
    gloss: "to cover",
  },
  Καλύψατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G2572",
    gloss: "to cover",
  },
  καλύψει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2572",
    gloss: "to cover",
  },
  κεκαλυμμένον: {
    parse:
      "V-RPP-NSN｜Verb, perfect, passive, Participle, nominative, singular, neuter",
    GN: "G2572",
    gloss: "to cover",
  },
  καλῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2573",
    gloss: "well",
  },
  Καλῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2573",
    gloss: "well",
  },
  κάμηλον: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2574",
    gloss: "camel",
  },
  καμήλου: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2574",
    gloss: "camel",
  },
  κάμινον: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2575",
    gloss: "furnace/oven",
  },
  καμίνου: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2575",
    gloss: "furnace/oven",
  },
  καμίνῳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2575",
    gloss: "furnace/oven",
  },
  ἐκάμμυσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2576",
    gloss: "to close",
  },
  κάμητε: {
    parse: "V-2AAS-2P｜Verb, 2nd aorist, active, subjunctive, second, plural",
    GN: "G2577",
    gloss: "ill",
  },
  κάμνοντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G2577",
    gloss: "ill",
  },
  ἔκαμψαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2578",
    gloss: "to bend/bow",
  },
  κάμπτω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G2578",
    gloss: "to bend/bow",
  },
  κάμψει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2578",
    gloss: "to bend/bow",
  },
  κάμψῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G2578",
    gloss: "to bend/bow",
  },
  κἂν: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G2579",
    gloss: "and/even if",
  },
  Κἂν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2579",
    gloss: "and/even if",
  },
  Κανὰ: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G2580",
    gloss: "Cana",
  },
  Καναναῖον: {
    parse: "N-ASM-T｜Noun, accusative, singular, masculine, Title",
    GN: "G2581",
    gloss: "Zealot",
  },
  Καναναῖος: {
    parse: "N-NSM-T｜Noun, nominative, singular, masculine, Title",
    GN: "G2581",
    gloss: "Zealot",
  },
  Κανδάκης: {
    parse: "N-GSF-T｜Noun, genitive, singular, feminine, Title",
    GN: "G2582",
    gloss: "Candace",
  },
  κανόνα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2583",
    gloss: "rule",
  },
  κανόνι: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G2583",
    gloss: "rule",
  },
  κανόνος: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2583",
    gloss: "rule",
  },
  Καφαρναούμ: {
    parse:
      "N-VSF-L｜Noun, Vocative or nominative, singular, feminine, Location",
    GN: "G2584",
    gloss: "Capernaum",
  },
  Καφαρναοὺμ: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G2584",
    gloss: "Capernaum",
  },
  καπηλεύοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2585",
    gloss: "to peddle",
  },
  καπνὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2586",
    gloss: "smoke",
  },
  καπνὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2586",
    gloss: "smoke",
  },
  καπνοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2586",
    gloss: "smoke",
  },
  Καππαδοκίαν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G2587",
    gloss: "Cappadocia",
  },
  Καππαδοκίας: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G2587",
    gloss: "Cappadocia",
  },
  καρδίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2588",
    gloss: "heart",
  },
  καρδία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2588",
    gloss: "heart",
  },
  καρδίαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G2588",
    gloss: "heart",
  },
  καρδίαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G2588",
    gloss: "heart",
  },
  καρδίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2588",
    gloss: "heart",
  },
  καρδίας: {
    parse: "N-GSF｜Noun, genitive or accusative, singular, feminine",
    GN: "G2588",
    gloss: "heart",
  },
  καρδιῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G2588",
    gloss: "heart",
  },
  καρδιογνῶστα: {
    // or nominative
    parse: "N-VSM｜Noun, Vocative, singular, masculine",
    GN: "G2589",
    gloss: "heart-knower",
  },
  καρδιογνώστης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2589",
    gloss: "heart-knower",
  },
  καρπόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2590",
    gloss: "fruit",
  },
  καρπὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2590",
    gloss: "fruit",
  },
  καρπός: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2590",
    gloss: "fruit",
  },
  καρπὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2590",
    gloss: "fruit",
  },
  καρποῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2590",
    gloss: "fruit",
  },
  καρπούς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G2590",
    gloss: "fruit",
  },
  καρποὺς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G2590",
    gloss: "fruit",
  },
  καρπῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G2590",
    gloss: "fruit",
  },
  Κάρπῳ: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G2591",
    gloss: "Carpus",
  },
  καρποφορεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2592",
    gloss: "to bear fruit",
  },
  καρποφορῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2592",
    gloss: "to bear fruit",
  },
  καρποφορήσωμεν: {
    parse: "V-AAS-1P｜Verb, aorist, active, subjunctive, first, plural",
    GN: "G2592",
    gloss: "to bear fruit",
  },
  καρποφορούμενον: {
    parse:
      "V-PMP-NSN｜Verb, present, middle, Participle, nominative, singular, neuter",
    GN: "G2592",
    gloss: "to bear fruit",
  },
  καρποφοροῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2592",
    gloss: "to bear fruit",
  },
  καρποφοροῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2592",
    gloss: "to bear fruit",
  },
  καρποφόρους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2593",
    gloss: "fruitbearing",
  },
  ἐκαρτέρησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2594",
    gloss: "to endure",
  },
  κάρφος: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G2595",
    gloss: "speck",
  },
  καθʼ: { parse: "PREP｜Preposition", GN: "G2596", gloss: "according to" },
  κατ: { parse: "PREP｜Preposition", GN: "G2596", gloss: "according to" },
  κατʼ: { parse: "PREP｜Preposition", GN: "G2596", gloss: "according to" },
  Κατʼ: { parse: "PREP｜Preposition", GN: "G2596", gloss: "according to" },
  κατά: { parse: "PREP｜Preposition", GN: "G2596", gloss: "according to" },
  κατὰ: { parse: "PREP｜Preposition", GN: "G2596", gloss: "according to" },
  Κατὰ: { parse: "PREP｜Preposition", GN: "G2596", gloss: "according to" },
  καταβαίνει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2597",
    gloss: "to come/go down",
  },
  καταβαίνειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2597",
    gloss: "to come/go down",
  },
  καταβαῖνον: {
    parse:
      "V-PAP-NSN｜Verb, present, active, Participle, nominative, singular, neuter",
    GN: "G2597",
    gloss: "to come/go down",
  },
  καταβαίνοντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G2597",
    gloss: "to come/go down",
  },
  καταβαίνοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G2597",
    gloss: "to come/go down",
  },
  καταβαίνοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2597",
    gloss: "to come/go down",
  },
  καταβαίνοντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G2597",
    gloss: "to come/go down",
  },
  καταβαινόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G2597",
    gloss: "to come/go down",
  },
  καταβαίνουσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G2597",
    gloss: "to come/go down",
  },
  καταβαίνουσαν: {
    parse:
      "V-PAP-ASF｜Verb, present, active, Participle, accusative, singular, feminine",
    GN: "G2597",
    gloss: "to come/go down",
  },
  καταβαίνων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2597",
    gloss: "to come/go down",
  },
  καταβὰν: {
    parse:
      "V-2AAP-ASN｜Verb, 2nd aorist, active, Participle, accusative, singular, neuter",
    GN: "G2597",
    gloss: "to come/go down",
  },
  καταβάντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G2597",
    gloss: "to come/go down",
  },
  Καταβάντος: {
    parse:
      "V-2AAP-GSM｜Verb, 2nd aorist, active, Participle, genitive, singular, masculine",
    GN: "G2597",
    gloss: "to come/go down",
  },
  καταβάς: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G2597",
    gloss: "to come/go down",
  },
  καταβὰς: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G2597",
    gloss: "to come/go down",
  },
  καταβάτω: {
    parse: "V-2AAM-3S｜Verb, 2nd aorist, active, imperative, third, singular",
    GN: "G2597",
    gloss: "to come/go down",
  },
  καταβέβηκα: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G2597",
    gloss: "to come/go down",
  },
  καταβεβηκότες: {
    parse:
      "V-RAP-NPM｜Verb, perfect, active, Participle, nominative, plural, masculine",
    GN: "G2597",
    gloss: "to come/go down",
  },
  καταβῇ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G2597",
    gloss: "to come/go down",
  },
  κατάβηθι: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G2597",
    gloss: "to come/go down",
  },
  καταβῆναι: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G2597",
    gloss: "to come/go down",
  },
  καταβήσεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G2597",
    gloss: "to come/go down",
  },
  καταβήσῃ: {
    parse:
      "V-FDI-2S｜Verb, future, Middle deponent, indicative, second, singular",
    GN: "G2597",
    gloss: "to come/go down",
  },
  κατέβαινεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G2597",
    gloss: "to come/go down",
  },
  κατέβη: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G2597",
    gloss: "to come/go down",
  },
  κατέβην: {
    parse: "V-2AAI-1S｜Verb, 2nd aorist, active, indicative, first, singular",
    GN: "G2597",
    gloss: "to come/go down",
  },
  κατέβησαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G2597",
    gloss: "to come/go down",
  },
  καταβαλλόμενοι: {
    parse:
      "V-PEP-NPM｜Verb, present, Either middle or passive, Participle, nominative, plural, masculine",
    GN: "G2598",
    gloss: "to lay/throw down",
  },
  κατεβάρησα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G2599",
    gloss: "to burden",
  },
  καταβάσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2600",
    gloss: "descent",
  },
  καταβολὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2602",
    gloss: "beginning",
  },
  καταβολῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2602",
    gloss: "beginning",
  },
  καταβραβευέτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G2603",
    gloss: "to disqualify",
  },
  καταγγελεὺς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2604",
    gloss: "proclaimer",
  },
  καταγγέλλειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2605",
    gloss: "to proclaim",
  },
  καταγγέλλεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G2605",
    gloss: "to proclaim",
  },
  καταγγέλλετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G2605",
    gloss: "to proclaim",
  },
  καταγγέλλομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G2605",
    gloss: "to proclaim",
  },
  καταγγέλλουσιν: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G2605",
    gloss: "to proclaim",
  },
  καταγγέλλω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G2605",
    gloss: "to proclaim",
  },
  καταγγέλλων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2605",
    gloss: "to proclaim",
  },
  κατηγγείλαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G2605",
    gloss: "to proclaim",
  },
  κατήγγειλαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2605",
    gloss: "to proclaim",
  },
  κατηγγέλη: {
    parse: "V-2API-3S｜Verb, 2nd aorist, passive, indicative, third, singular",
    GN: "G2605",
    gloss: "to proclaim",
  },
  κατήγγελλον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2605",
    gloss: "to proclaim",
  },
  κατεγέλων: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2606",
    gloss: "to mock",
  },
  καταγινώσκῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G2607",
    gloss: "to condemn",
  },
  κατεγνωσμένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G2607",
    gloss: "to condemn",
  },
  κατεαγῶσιν: {
    parse: "V-2APS-3P｜Verb, 2nd aorist, passive, subjunctive, third, plural",
    GN: "G2608",
    gloss: "to break",
  },
  κατέαξαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G2608",
    gloss: "to break",
  },
  κατεάξει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2608",
    gloss: "to break",
  },
  καταγαγεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G2609",
    gloss: "to bring down",
  },
  καταγάγῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G2609",
    gloss: "to bring down",
  },
  καταγάγῃς: {
    parse: "V-2AAS-2S｜Verb, 2nd aorist, active, subjunctive, second, singular",
    GN: "G2609",
    gloss: "to bring down",
  },
  καταγαγόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G2609",
    gloss: "to bring down",
  },
  καταγαγὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G2609",
    gloss: "to bring down",
  },
  καταχθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G2609",
    gloss: "to bring down",
  },
  κατήγαγον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G2609",
    gloss: "to bring down",
  },
  κατήχθημεν: {
    parse: "V-2API-1P｜Verb, 2nd aorist, passive, indicative, first, plural",
    GN: "G2609",
    gloss: "to bring down",
  },
  κατηγωνίσαντο: {
    parse: "V-ADI-3P｜Verb, aorist, Middle deponent, indicative, third, plural",
    GN: "G2610",
    gloss: "to conquer",
  },
  κατέδησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2611",
    gloss: "to bandage",
  },
  κατάδηλόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G2612",
    gloss: "evident",
  },
  καταδικάζετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G2613",
    gloss: "to condemn",
  },
  καταδικασθήσῃ: {
    parse: "V-FPI-2S｜Verb, future, passive, indicative, second, singular",
    GN: "G2613",
    gloss: "to condemn",
  },
  καταδικασθῆτε: {
    parse: "V-APS-2P｜Verb, aorist, passive, subjunctive, second, plural",
    GN: "G2613",
    gloss: "to condemn",
  },
  κατεδικάσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G2613",
    gloss: "to condemn",
  },
  κατεδίωξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2614",
    gloss: "to seek",
  },
  καταδουλοῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2615",
    gloss: "to enslave",
  },
  καταδουλώσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G2615",
    gloss: "to enslave",
  },
  καταδυναστευομένους: {
    parse:
      "V-PPP-APM｜Verb, present, passive, Participle, accusative, plural, masculine",
    GN: "G2616",
    gloss: "to oppress",
  },
  καταδυναστεύουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2616",
    gloss: "to oppress",
  },
  καταισχύνει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2617",
    gloss: "to dishonor",
  },
  καταισχύνετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G2617",
    gloss: "to dishonor",
  },
  καταισχύνῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G2617",
    gloss: "to dishonor",
  },
  καταισχυνθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G2617",
    gloss: "to dishonor",
  },
  καταισχυνθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G2617",
    gloss: "to dishonor",
  },
  καταισχυνθῶμεν: {
    parse: "V-APS-1P｜Verb, aorist, passive, subjunctive, first, plural",
    GN: "G2617",
    gloss: "to dishonor",
  },
  καταισχυνθῶσιν: {
    parse: "V-APS-3P｜Verb, aorist, passive, subjunctive, third, plural",
    GN: "G2617",
    gloss: "to dishonor",
  },
  κατῃσχύνθην: {
    parse: "V-API-1S｜Verb, aorist, passive, indicative, first, singular",
    GN: "G2617",
    gloss: "to dishonor",
  },
  κατῃσχύνοντο: {
    parse: "V-IPI-3P｜Verb, imperfect, passive, indicative, third, plural",
    GN: "G2617",
    gloss: "to dishonor",
  },
  κατακαήσεται: {
    parse: "V-2FPI-3S｜Verb, 2nd future, passive, indicative, third, singular",
    GN: "G2618",
    gloss: "to burn",
  },
  κατακαίεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G2618",
    gloss: "to burn",
  },
  κατακαυθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G2618",
    gloss: "to burn",
  },
  κατακαῦσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2618",
    gloss: "to burn",
  },
  κατακαύσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2618",
    gloss: "to burn",
  },
  κατακαύσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G2618",
    gloss: "to burn",
  },
  κατεκάη: {
    parse: "V-2API-3S｜Verb, 2nd aorist, passive, indicative, third, singular",
    GN: "G2618",
    gloss: "to burn",
  },
  κατέκαιον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2618",
    gloss: "to burn",
  },
  κατακαλύπτεσθαι: {
    parse: "V-PMN｜Verb, present, middle, Infinitive",
    GN: "G2619",
    gloss: "to cover",
  },
  κατακαλυπτέσθω: {
    parse: "V-PMM-3S｜Verb, present, middle, imperative, third, singular",
    GN: "G2619",
    gloss: "to cover",
  },
  κατακαλύπτεται: {
    parse: "V-PMI-3S｜Verb, present, middle, indicative, third, singular",
    GN: "G2619",
    gloss: "to cover",
  },
  κατακαυχᾶσαι: {
    parse:
      "V-PNI-2S｜Verb, present, Middle or Passive deponent, indicative, second, singular",
    GN: "G2620",
    gloss: "to boast",
  },
  κατακαυχᾶσθε: {
    parse:
      "V-PNM-2P｜Verb, present, Middle or Passive deponent, imperative, second, plural",
    GN: "G2620",
    gloss: "to boast",
  },
  κατακαυχᾶται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G2620",
    gloss: "to boast",
  },
  κατακαυχῶ: {
    parse:
      "V-PNM-2S｜Verb, present, Middle or Passive deponent, imperative, second, singular",
    GN: "G2620",
    gloss: "to boast",
  },
  κατακείμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G2621",
    gloss: "to recline",
  },
  κατακείμενον: {
    parse:
      "V-PNP-ASM｜Verb, present, Middle or Passive deponent, Participle, accusative, singular, masculine",
    GN: "G2621",
    gloss: "to recline",
  },
  κατακειμένου: {
    parse:
      "V-PNP-GSM｜Verb, present, Middle or Passive deponent, Participle, genitive, singular, masculine",
    GN: "G2621",
    gloss: "to recline",
  },
  κατακεῖσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G2621",
    gloss: "to recline",
  },
  κατάκειται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G2621",
    gloss: "to recline",
  },
  κατέκειτο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G2621",
    gloss: "to recline",
  },
  κατέκλασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2622",
    gloss: "to break",
  },
  κατέκλεισα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G2623",
    gloss: "to lock up",
  },
  κατέκλεισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2623",
    gloss: "to lock up",
  },
  κατεκληρονόμησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2624",
    gloss: "to distribute",
  },
  κατακλιθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G2625",
    gloss: "to sit",
  },
  κατακλιθῇς: {
    parse: "V-APS-2S｜Verb, aorist, passive, subjunctive, second, singular",
    GN: "G2625",
    gloss: "to sit",
  },
  Κατακλίνατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G2625",
    gloss: "to sit",
  },
  κατεκλίθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2625",
    gloss: "to sit",
  },
  κατέκλιναν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2625",
    gloss: "to sit",
  },
  κατακλυσθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G2626",
    gloss: "to flood",
  },
  κατακλυσμὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2627",
    gloss: "flood",
  },
  κατακλυσμὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2627",
    gloss: "flood",
  },
  κατακλυσμοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2627",
    gloss: "flood",
  },
  κατακολουθήσασαι: {
    parse:
      "V-AAP-NPF｜Verb, aorist, active, Participle, nominative, plural, feminine",
    GN: "G2628",
    gloss: "to follow",
  },
  κατακολουθοῦσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G2628",
    gloss: "to follow",
  },
  κατακόπτων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2629",
    gloss: "to cut",
  },
  κατακρημνίσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2630",
    gloss: "to cast down",
  },
  κατάκριμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2631",
    gloss: "condemnation",
  },
  κατακέκριται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G2632",
    gloss: "to condemn",
  },
  κατακριθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G2632",
    gloss: "to condemn",
  },
  κατακριθῶμεν: {
    parse: "V-APS-1P｜Verb, aorist, passive, subjunctive, first, plural",
    GN: "G2632",
    gloss: "to condemn",
  },
  κατακρινεῖ: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2632",
    gloss: "to condemn",
  },
  κατακρίνεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G2632",
    gloss: "to condemn",
  },
  κατακρινοῦσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G2632",
    gloss: "to condemn",
  },
  κατακρίνω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G2632",
    gloss: "to condemn",
  },
  κατακρινῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2632",
    gloss: "to condemn",
  },
  κατεκρίθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2632",
    gloss: "to condemn",
  },
  κατέκριναν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2632",
    gloss: "to condemn",
  },
  κατέκρινεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2632",
    gloss: "to condemn",
  },
  κατακρίσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2633",
    gloss: "condemnation",
  },
  κατάκρισιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2633",
    gloss: "condemnation",
  },
  κατακυριεύοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2634",
    gloss: "to master",
  },
  κατακυριεύουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2634",
    gloss: "to master",
  },
  κατακυριεύσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G2634",
    gloss: "to master",
  },
  καταλαλεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2635",
    gloss: "to slander",
  },
  καταλαλεῖσθε: {
    parse: "V-PPI-2P｜Verb, present, passive, indicative, second, plural",
    GN: "G2635",
    gloss: "to slander",
  },
  καταλαλεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G2635",
    gloss: "to slander",
  },
  καταλαλοῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2635",
    gloss: "to slander",
  },
  καταλαλῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2635",
    gloss: "to slander",
  },
  καταλαλιαί: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G2636",
    gloss: "slander",
  },
  καταλαλιάς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G2636",
    gloss: "slander",
  },
  καταλάλους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2637",
    gloss: "slanderous",
  },
  καταλαβέσθαι: {
    parse: "V-2AMN｜Verb, 2nd aorist, middle, Infinitive",
    GN: "G2638",
    gloss: "to grasp",
  },
  καταλάβῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G2638",
    gloss: "to grasp",
  },
  καταλάβητε: {
    parse: "V-2AAS-2P｜Verb, 2nd aorist, active, subjunctive, second, plural",
    GN: "G2638",
    gloss: "to grasp",
  },
  καταλαβόμενοι: {
    parse:
      "V-2AMP-NPM｜Verb, 2nd aorist, middle, Participle, nominative, plural, masculine",
    GN: "G2638",
    gloss: "to grasp",
  },
  καταλάβω: {
    parse: "V-2AAS-1S｜Verb, 2nd aorist, active, subjunctive, first, singular",
    GN: "G2638",
    gloss: "to grasp",
  },
  καταλαμβάνομαι: {
    parse: "V-PMI-1S｜Verb, present, middle, indicative, first, singular",
    GN: "G2638",
    gloss: "to grasp",
  },
  κατειλημμένην: {
    parse:
      "V-RPP-ASF｜Verb, perfect, passive, Participle, accusative, singular, feminine",
    GN: "G2638",
    gloss: "to grasp",
  },
  κατείληπται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G2638",
    gloss: "to grasp",
  },
  κατειληφέναι: {
    parse: "V-2RAN｜Verb, 2nd perfect, active, Infinitive",
    GN: "G2638",
    gloss: "to grasp",
  },
  κατέλαβεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G2638",
    gloss: "to grasp",
  },
  κατελαβόμην: {
    parse: "V-2AMI-1S｜Verb, 2nd aorist, middle, indicative, first, singular",
    GN: "G2638",
    gloss: "to grasp",
  },
  κατελήμφθην: {
    parse: "V-API-1S｜Verb, aorist, passive, indicative, first, singular",
    GN: "G2638",
    gloss: "to grasp",
  },
  καταλεγέσθω: {
    parse: "V-PPM-3S｜Verb, present, passive, imperative, third, singular",
    GN: "G2639",
    gloss: "to enrol",
  },
  καταλείπει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2641",
    gloss: "to leave behind",
  },
  καταλειπομένης: {
    parse:
      "V-PPP-GSF｜Verb, present, passive, Participle, genitive, singular, feminine",
    GN: "G2641",
    gloss: "to leave behind",
  },
  καταλειφθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G2641",
    gloss: "to leave behind",
  },
  καταλείψαντας: {
    parse:
      "V-AAP-APM｜Verb, aorist, active, Participle, accusative, plural, masculine",
    GN: "G2641",
    gloss: "to leave behind",
  },
  καταλείψει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2641",
    gloss: "to leave behind",
  },
  καταλελειμμένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G2641",
    gloss: "to leave behind",
  },
  καταλίπῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G2641",
    gloss: "to leave behind",
  },
  καταλιπόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G2641",
    gloss: "to leave behind",
  },
  καταλιπὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G2641",
    gloss: "to leave behind",
  },
  κατελείφθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2641",
    gloss: "to leave behind",
  },
  κατέλιπεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G2641",
    gloss: "to leave behind",
  },
  Κατέλιπον: {
    parse:
      "V-2AAI-1S｜Verb, 2nd aorist, active, indicative, first, singular OR third, plural",
    GN: "G2641",
    gloss: "to leave behind",
  },
  κατέλιπον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural, OR first, singular",
    GN: "G2641",
    gloss: "to leave behind",
  },
  καταλιθάσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2642",
    gloss: "to stone",
  },
  καταλλαγὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2643",
    gloss: "reconciliation",
  },
  καταλλαγὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2643",
    gloss: "reconciliation",
  },
  καταλλαγῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2643",
    gloss: "reconciliation",
  },
  καταλλαγέντες: {
    parse:
      "V-2APP-NPM｜Verb, 2nd aorist, passive, Participle, nominative, plural, masculine",
    GN: "G2644",
    gloss: "to reconcile",
  },
  καταλλάγητε: {
    parse: "V-2APM-2P｜Verb, 2nd aorist, passive, imperative, second, plural",
    GN: "G2644",
    gloss: "to reconcile",
  },
  καταλλαγήτω: {
    parse: "V-2APM-3S｜Verb, 2nd aorist, passive, imperative, third, singular",
    GN: "G2644",
    gloss: "to reconcile",
  },
  καταλλάξαντος: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G2644",
    gloss: "to reconcile",
  },
  καταλλάσσων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2644",
    gloss: "to reconcile",
  },
  κατηλλάγημεν: {
    parse: "V-2API-1P｜Verb, 2nd aorist, passive, indicative, first, plural",
    GN: "G2644",
    gloss: "to reconcile",
  },
  κατάλοιποι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G2645",
    gloss: "remaining",
  },
  κατάλυμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2646",
    gloss: "guest room/inn",
  },
  κατάλυμά: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2646",
    gloss: "guest room/inn",
  },
  καταλύματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G2646",
    gloss: "guest room/inn",
  },
  κατάλυε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G2647",
    gloss: "to destroy/lodge",
  },
  καταλυθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G2647",
    gloss: "to destroy/lodge",
  },
  καταλυθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G2647",
    gloss: "to destroy/lodge",
  },
  καταλῦσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2647",
    gloss: "to destroy/lodge",
  },
  καταλύσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2647",
    gloss: "to destroy/lodge",
  },
  καταλύσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G2647",
    gloss: "to destroy/lodge",
  },
  καταλύσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G2647",
    gloss: "to destroy/lodge",
  },
  καταλύων: {
    parse:
      "V-PAP-VSM｜Verb, present, active, Participle, Vocative or nominative, singular, masculine",
    GN: "G2647",
    gloss: "to destroy/lodge",
  },
  κατέλυσα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G2647",
    gloss: "to destroy/lodge",
  },
  καταμάθετε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G2648",
    gloss: "to consider",
  },
  καταμαρτυροῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2649",
    gloss: "to testify against",
  },
  καταμένοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2650",
    gloss: "to stay",
  },
  κατάθεμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2652",
    gloss: "curse",
  },
  καταθεματίζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2653",
    gloss: "to curse",
  },
  καταναλίσκον: {
    parse:
      "V-PAP-NSN｜Verb, present, active, Participle, nominative, singular, neuter",
    GN: "G2654",
    gloss: "to consume",
  },
  καταναρκήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G2655",
    gloss: "to burden",
  },
  κατενάρκησα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G2655",
    gloss: "to burden",
  },
  κατένευσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2656",
    gloss: "to signal",
  },
  κατανοεῖς: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G2657",
    gloss: "to observe",
  },
  κατανοῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2657",
    gloss: "to observe",
  },
  κατανοήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G2657",
    gloss: "to observe",
  },
  κατανοήσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G2657",
    gloss: "to observe",
  },
  κατανοοῦντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G2657",
    gloss: "to observe",
  },
  κατανοῶμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G2657",
    gloss: "to observe",
  },
  κατενόησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2657",
    gloss: "to observe",
  },
  κατενόουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2657",
    gloss: "to observe",
  },
  καταντῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2658",
    gloss: "to come to",
  },
  καταντήσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G2658",
    gloss: "to come to",
  },
  καταντήσω: {
    parse: "V-AAS-1S｜Verb, aorist, active, subjunctive, first, singular",
    GN: "G2658",
    gloss: "to come to",
  },
  καταντήσωμεν: {
    parse: "V-AAS-1P｜Verb, aorist, active, subjunctive, first, plural",
    GN: "G2658",
    gloss: "to come to",
  },
  κατήντηκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G2658",
    gloss: "to come to",
  },
  κατηντήσαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G2658",
    gloss: "to come to",
  },
  κατήντησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2658",
    gloss: "to come to",
  },
  κατήντησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2658",
    gloss: "to come to",
  },
  Κατήντησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2658",
    gloss: "to come to",
  },
  κατανύξεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2659",
    gloss: "stupor",
  },
  κατενύγησαν: {
    parse: "V-2API-3P｜Verb, 2nd aorist, passive, indicative, third, plural",
    GN: "G2660",
    gloss: "to pierce",
  },
  καταξιωθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G2661",
    gloss: "to consider worthy",
  },
  καταξιωθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G2661",
    gloss: "to consider worthy",
  },
  κατηξιώθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G2661",
    gloss: "to consider worthy",
  },
  καταπατεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2662",
    gloss: "to trample",
  },
  καταπατεῖσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G2662",
    gloss: "to trample",
  },
  καταπατήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G2662",
    gloss: "to trample",
  },
  καταπατήσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G2662",
    gloss: "to trample",
  },
  κατεπατήθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2662",
    gloss: "to trample",
  },
  καταπαύσεώς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2663",
    gloss: "rest",
  },
  κατάπαυσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2663",
    gloss: "rest",
  },
  κατάπαυσίν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2663",
    gloss: "rest",
  },
  κατέπαυσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2664",
    gloss: "to keep from",
  },
  κατέπαυσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2664",
    gloss: "to keep from",
  },
  καταπέτασμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2665",
    gloss: "curtain",
  },
  καταπετάσματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G2665",
    gloss: "curtain",
  },
  καταπιεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G2666",
    gloss: "to swallow",
  },
  καταπίνοντες: {
    parse:
      "V-PAP-VPM｜Verb, present, active, Participle, Vocative or nominative, plural, masculine",
    GN: "G2666",
    gloss: "to swallow",
  },
  καταποθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G2666",
    gloss: "to swallow",
  },
  κατέπιεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G2666",
    gloss: "to swallow",
  },
  Κατεπόθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2666",
    gloss: "to swallow",
  },
  κατεπόθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G2666",
    gloss: "to swallow",
  },
  καταπεσόντων: {
    parse:
      "V-2AAP-GPM｜Verb, 2nd aorist, active, Participle, genitive, plural, masculine",
    GN: "G2667",
    gloss: "to fall down",
  },
  καταπίπτειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2667",
    gloss: "to fall down",
  },
  κατέπεσεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G2667",
    gloss: "to fall down",
  },
  κατέπλευσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2668",
    gloss: "to sail",
  },
  καταπονούμενον: {
    parse:
      "V-PPP-ASM｜Verb, present, passive, Participle, accusative, singular, masculine",
    GN: "G2669",
    gloss: "to oppress",
  },
  καταπονουμένῳ: {
    parse:
      "V-PPP-DSM｜Verb, present, passive, Participle, dative, singular, masculine",
    GN: "G2669",
    gloss: "to oppress",
  },
  καταποντίζεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G2670",
    gloss: "to sink",
  },
  καταποντισθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G2670",
    gloss: "to sink",
  },
  κατάρα: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2671",
    gloss: "curse",
  },
  κατάραν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2671",
    gloss: "curse",
  },
  κατάρας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2671",
    gloss: "curse",
  },
  καταρᾶσθε: {
    parse:
      "V-PNM-2P｜Verb, present, Middle or Passive deponent, imperative, second, plural",
    GN: "G2672",
    gloss: "to curse",
  },
  καταρώμεθα: {
    parse:
      "V-PNI-1P｜Verb, present, Middle or Passive deponent, indicative, first, plural",
    GN: "G2672",
    gloss: "to curse",
  },
  καταρωμένους: {
    parse:
      "V-PNP-APM｜Verb, present, Middle or Passive deponent, Participle, accusative, plural, masculine",
    GN: "G2672",
    gloss: "to curse",
  },
  κατηραμένοι: {
    parse:
      "V-RPP-VPM｜Verb, perfect, passive, Participle, Vocative or nominative, plural, masculine",
    GN: "G2672",
    gloss: "to curse",
  },
  κατηράσω: {
    parse:
      "V-ADI-2S｜Verb, aorist, Middle deponent, indicative, second, singular",
    GN: "G2672",
    gloss: "to curse",
  },
  καταργεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2673",
    gloss: "to abate",
  },
  καταργεῖται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G2673",
    gloss: "to abate",
  },
  καταργηθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G2673",
    gloss: "to abate",
  },
  καταργηθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G2673",
    gloss: "to abate",
  },
  καταργηθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G2673",
    gloss: "to abate",
  },
  καταργῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2673",
    gloss: "to abate",
  },
  καταργήσαντος: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G2673",
    gloss: "to abate",
  },
  καταργήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G2673",
    gloss: "to abate",
  },
  καταργήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2673",
    gloss: "to abate",
  },
  καταργήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G2673",
    gloss: "to abate",
  },
  καταργοῦμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G2673",
    gloss: "to abate",
  },
  καταργουμένην: {
    parse:
      "V-PPP-ASF｜Verb, present, passive, Participle, accusative, singular, feminine",
    GN: "G2673",
    gloss: "to abate",
  },
  καταργούμενον: {
    parse:
      "V-PPP-NSN｜Verb, present, passive, Participle, nominative, singular, neuter",
    GN: "G2673",
    gloss: "to abate",
  },
  καταργουμένου: {
    parse:
      "V-PPP-GSN｜Verb, present, passive, Participle, genitive, singular, neuter",
    GN: "G2673",
    gloss: "to abate",
  },
  καταργουμένων: {
    parse:
      "V-PPP-GPM｜Verb, present, passive, Participle, genitive, plural, masculine",
    GN: "G2673",
    gloss: "to abate",
  },
  κατηργήθημεν: {
    parse: "V-API-1P｜Verb, aorist, passive, indicative, first, plural",
    GN: "G2673",
    gloss: "to abate",
  },
  κατηργήθητε: {
    parse: "V-API-2P｜Verb, aorist, passive, indicative, second, plural",
    GN: "G2673",
    gloss: "to abate",
  },
  κατήργηκα: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G2673",
    gloss: "to abate",
  },
  κατήργηται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G2673",
    gloss: "to abate",
  },
  κατηριθμημένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G2674",
    gloss: "to number",
  },
  καταρτίζεσθε: {
    parse: "V-PPM-2P｜Verb, present, passive, imperative, second, plural",
    GN: "G2675",
    gloss: "to complete",
  },
  καταρτίζετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G2675",
    gloss: "to complete",
  },
  καταρτίζοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G2675",
    gloss: "to complete",
  },
  καταρτίσαι: {
    parse: "V-AAO-3S｜Verb, aorist, active, Optative, third, singular",
    GN: "G2675",
    gloss: "to complete",
  },
  καταρτίσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2675",
    gloss: "to complete",
  },
  κατηρτίσθαι: {
    parse: "V-RPN｜Verb, perfect, passive, Infinitive",
    GN: "G2675",
    gloss: "to complete",
  },
  κατηρτισμένα: {
    parse:
      "V-RPP-APN｜Verb, perfect, passive, Participle, accusative, plural, neuter",
    GN: "G2675",
    gloss: "to complete",
  },
  κατηρτισμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G2675",
    gloss: "to complete",
  },
  κατηρτισμένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G2675",
    gloss: "to complete",
  },
  κατηρτίσω: {
    parse: "V-AMI-2S｜Verb, aorist, middle, indicative, second, singular",
    GN: "G2675",
    gloss: "to complete",
  },
  κατάρτισιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2676",
    gloss: "perfection",
  },
  καταρτισμὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2677",
    gloss: "preparation",
  },
  κατασείσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G2678",
    gloss: "to signal",
  },
  κατέσεισε: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2678",
    gloss: "to signal",
  },
  κατέσεισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2678",
    gloss: "to signal",
  },
  κατεσκαμμένα: {
    parse:
      "V-RPP-APN｜Verb, perfect, passive, Participle, accusative, plural, neuter",
    GN: "G2679",
    gloss: "to ruin",
  },
  κατέσκαψαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2679",
    gloss: "to ruin",
  },
  κατασκευάζεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G2680",
    gloss: "to prepare",
  },
  κατασκευαζομένης: {
    parse:
      "V-PPP-GSF｜Verb, present, passive, Participle, genitive, singular, feminine",
    GN: "G2680",
    gloss: "to prepare",
  },
  κατασκευάσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G2680",
    gloss: "to prepare",
  },
  κατασκευάσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2680",
    gloss: "to prepare",
  },
  κατεσκεύασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2680",
    gloss: "to prepare",
  },
  κατεσκευάσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2680",
    gloss: "to prepare",
  },
  κατεσκευασμένον: {
    parse:
      "V-RPP-ASM｜Verb, perfect, passive, Participle, accusative, singular, masculine",
    GN: "G2680",
    gloss: "to prepare",
  },
  κατεσκευασμένων: {
    parse:
      "V-RPP-GPN｜Verb, perfect, passive, Participle, genitive, plural, neuter",
    GN: "G2680",
    gloss: "to prepare",
  },
  κατασκηνοῦν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2681",
    gloss: "to dwell",
  },
  κατασκηνώσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2681",
    gloss: "to dwell",
  },
  κατεσκήνωσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2681",
    gloss: "to dwell",
  },
  κατασκηνώσεις: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G2682",
    gloss: "dwelling place",
  },
  κατασκιάζοντα: {
    parse:
      "V-PAP-NPN｜Verb, present, active, Participle, nominative, plural, neuter",
    GN: "G2683",
    gloss: "to overshadow",
  },
  κατασκοπῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2684",
    gloss: "to spy",
  },
  κατασκόπους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G2685",
    gloss: "spy",
  },
  κατασοφισάμενος: {
    parse:
      "V-ADP-NSM｜Verb, aorist, Middle deponent, Participle, nominative, singular, masculine",
    GN: "G2686",
    gloss: "be cunning",
  },
  καταστείλας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G2687",
    gloss: "to quiet",
  },
  κατεσταλμένους: {
    parse:
      "V-RPP-APM｜Verb, perfect, passive, Participle, accusative, plural, masculine",
    GN: "G2687",
    gloss: "to quiet",
  },
  καταστήματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G2688",
    gloss: "behavior",
  },
  καταστολῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2689",
    gloss: "attire/behaviour",
  },
  κατέστρεψεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2690",
    gloss: "to overturn",
  },
  καταστρηνιάσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G2691",
    gloss: "to desire",
  },
  καταστροφῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2692",
    gloss: "ruin",
  },
  κατεστρώθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G2693",
    gloss: "be thrown down",
  },
  κατασύρῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G2694",
    gloss: "to drag away",
  },
  κατασφάξατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G2695",
    gloss: "to slaughter",
  },
  κατεσφραγισμένον: {
    parse:
      "V-RPP-ASN｜Verb, perfect, passive, Participle, accusative, singular, neuter",
    GN: "G2696",
    gloss: "to seal",
  },
  κατασχέσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2697",
    gloss: "possession",
  },
  κατάσχεσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2697",
    gloss: "possession",
  },
  καταθέσθαι: {
    parse: "V-2AMN｜Verb, 2nd aorist, middle, Infinitive",
    GN: "G2698",
    gloss: "to attain",
  },
  κατατομήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2699",
    gloss: "mutilation",
  },
  κατέδραμεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G2701",
    gloss: "to rush down",
  },
  καταφερόμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G2702",
    gloss: "to vote against",
  },
  καταφέροντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2702",
    gloss: "to vote against",
  },
  κατενεχθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G2702",
    gloss: "to vote against",
  },
  κατήνεγκα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G2702",
    gloss: "to vote against",
  },
  καταφυγόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G2703",
    gloss: "to flee",
  },
  κατέφυγον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G2703",
    gloss: "to flee",
  },
  κατεφθαρμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G2704",
    gloss: "to deprave",
  },
  καταφιλοῦσά: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G2705",
    gloss: "to kiss",
  },
  κατεφίλει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G2705",
    gloss: "to kiss",
  },
  κατεφίλησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2705",
    gloss: "to kiss",
  },
  κατεφίλουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2705",
    gloss: "to kiss",
  },
  καταφρονεῖς: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G2706",
    gloss: "to despise",
  },
  καταφρονεῖτε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G2706",
    gloss: "to despise",
  },
  καταφρονείτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G2706",
    gloss: "to despise",
  },
  καταφρονείτωσαν: {
    parse: "V-PAM-3P｜Verb, present, active, imperative, third, plural",
    GN: "G2706",
    gloss: "to despise",
  },
  καταφρονήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G2706",
    gloss: "to despise",
  },
  καταφρονήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2706",
    gloss: "to despise",
  },
  καταφρονήσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G2706",
    gloss: "to despise",
  },
  καταφρονοῦντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G2706",
    gloss: "to despise",
  },
  καταφρονηταί: {
    // or nominative
    parse: "N-VPM｜Noun, Vocative, plural, masculine",
    GN: "G2707",
    gloss: "despiser",
  },
  κατέχεεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2708",
    gloss: "to pour",
  },
  καταχθονίων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G2709",
    gloss: "subterranean",
  },
  καταχρήσασθαι: {
    parse: "V-ADN｜Verb, aorist, Middle deponent, Infinitive",
    GN: "G2710",
    gloss: "to use up",
  },
  καταχρώμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G2710",
    gloss: "to use up",
  },
  καταψύξῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G2711",
    gloss: "to cool",
  },
  κατείδωλον: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G2712",
    gloss: "idolatrous",
  },
  κατέναντι: { parse: "PREP｜Preposition", GN: "G2713", gloss: "before" },
  κατενώπιον: { parse: "PREP｜Preposition", GN: "G2714", gloss: "before" },
  κατεξουσιάζουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2715",
    gloss: "to rule",
  },
  κατειργάσατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G2716",
    gloss: "to workout/produce",
  },
  κατειργάσθαι: {
    parse: "V-RNN｜Verb, perfect, Middle or Passive deponent, Infinitive",
    GN: "G2716",
    gloss: "to workout/produce",
  },
  κατειργάσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2716",
    gloss: "to workout/produce",
  },
  κατεργάζεσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G2716",
    gloss: "to workout/produce",
  },
  κατεργάζεσθε: {
    parse:
      "V-PNM-2P｜Verb, present, Middle or Passive deponent, imperative, second, plural",
    GN: "G2716",
    gloss: "to workout/produce",
  },
  κατεργάζεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G2716",
    gloss: "to workout/produce",
  },
  κατεργάζομαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G2716",
    gloss: "to workout/produce",
  },
  κατεργαζομένη: {
    parse:
      "V-PNP-NSF｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, feminine",
    GN: "G2716",
    gloss: "to workout/produce",
  },
  κατεργαζόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G2716",
    gloss: "to workout/produce",
  },
  κατεργαζομένου: {
    parse:
      "V-PNP-GSM｜Verb, present, Middle or Passive deponent, Participle, genitive, singular, masculine",
    GN: "G2716",
    gloss: "to workout/produce",
  },
  κατεργασάμενοι: {
    parse:
      "V-ADP-NPM｜Verb, aorist, Middle deponent, Participle, nominative, plural, masculine",
    GN: "G2716",
    gloss: "to workout/produce",
  },
  κατεργασάμενον: {
    parse:
      "V-ADP-ASM｜Verb, aorist, Middle deponent, Participle, accusative, singular, masculine",
    GN: "G2716",
    gloss: "to workout/produce",
  },
  κατεργασάμενος: {
    parse:
      "V-ADP-NSM｜Verb, aorist, Middle deponent, Participle, nominative, singular, masculine",
    GN: "G2716",
    gloss: "to workout/produce",
  },
  κατελθεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G2718",
    gloss: "to descend",
  },
  κατελθόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G2718",
    gloss: "to descend",
  },
  κατελθόντων: {
    parse:
      "V-2AAP-GPM｜Verb, 2nd aorist, active, Participle, genitive, plural, masculine",
    GN: "G2718",
    gloss: "to descend",
  },
  κατελθὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G2718",
    gloss: "to descend",
  },
  κατερχομένη: {
    parse:
      "V-PNP-NSF｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, feminine",
    GN: "G2718",
    gloss: "to descend",
  },
  κατῆλθεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G2718",
    gloss: "to descend",
  },
  κατῆλθέν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G2718",
    gloss: "to descend",
  },
  κατήλθομεν: {
    parse: "V-2AAI-1P｜Verb, 2nd aorist, active, indicative, first, plural",
    GN: "G2718",
    gloss: "to descend",
  },
  κατῆλθον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G2718",
    gloss: "to descend",
  },
  κατάφαγε: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G2719",
    gloss: "to devour",
  },
  καταφάγεταί: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G2719",
    gloss: "to devour",
  },
  καταφάγῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G2719",
    gloss: "to devour",
  },
  καταφαγών: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G2719",
    gloss: "to devour",
  },
  κατεσθίει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2719",
    gloss: "to devour",
  },
  κατεσθίετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G2719",
    gloss: "to devour",
  },
  κατεσθίοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2719",
    gloss: "to devour",
  },
  κατεσθίουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2719",
    gloss: "to devour",
  },
  κατέφαγεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G2719",
    gloss: "to devour",
  },
  κατέφαγον: {
    parse:
      "V-2AAI-1S｜Verb, 2nd aorist, active, indicative, first, singular OR third, plural",
    GN: "G2719",
    gloss: "to devour",
  },
  κατευθῦναι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2720",
    gloss: "to guide",
  },
  κατευθύναι: {
    parse: "V-AAO-3S｜Verb, aorist, active, Optative, third, singular",
    GN: "G2720",
    gloss: "to guide",
  },
  κατεπέστησαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G2721",
    gloss: "to attack",
  },
  κατάσχωμεν: {
    parse: "V-2AAS-1P｜Verb, 2nd aorist, active, subjunctive, first, plural",
    GN: "G2722",
    gloss: "to hold back/fast",
  },
  κατειχόμεθα: {
    parse: "V-IPI-1P｜Verb, imperfect, passive, indicative, first, plural",
    GN: "G2722",
    gloss: "to hold back/fast",
  },
  κατεῖχον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2722",
    gloss: "to hold back/fast",
  },
  κατέχειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2722",
    gloss: "to hold back/fast",
  },
  κατέχετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G2722",
    gloss: "to hold back/fast",
  },
  κατέχον: {
    parse:
      "V-PAP-ASN｜Verb, present, active, Participle, accusative, singular, neuter",
    GN: "G2722",
    gloss: "to hold back/fast",
  },
  κατέχοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2722",
    gloss: "to hold back/fast",
  },
  κατεχόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G2722",
    gloss: "to hold back/fast",
  },
  κατέχουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2722",
    gloss: "to hold back/fast",
  },
  κατέχωμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G2722",
    gloss: "to hold back/fast",
  },
  κατέχων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2722",
    gloss: "to hold back/fast",
  },
  κατηγορεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2723",
    gloss: "to accuse",
  },
  κατηγορεῖσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G2723",
    gloss: "to accuse",
  },
  κατηγορεῖται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G2723",
    gloss: "to accuse",
  },
  κατηγορεῖτε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G2723",
    gloss: "to accuse",
  },
  κατηγορείτωσαν: {
    parse: "V-PAM-3P｜Verb, present, active, imperative, third, plural",
    GN: "G2723",
    gloss: "to accuse",
  },
  κατηγορήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G2723",
    gloss: "to accuse",
  },
  κατηγορήσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G2723",
    gloss: "to accuse",
  },
  κατηγοροῦμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G2723",
    gloss: "to accuse",
  },
  κατηγορούμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G2723",
    gloss: "to accuse",
  },
  κατηγόρουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2723",
    gloss: "to accuse",
  },
  κατηγοροῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2723",
    gloss: "to accuse",
  },
  κατηγορούντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G2723",
    gloss: "to accuse",
  },
  κατηγοροῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2723",
    gloss: "to accuse",
  },
  κατηγοροῦσίν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2723",
    gloss: "to accuse",
  },
  κατηγορῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2723",
    gloss: "to accuse",
  },
  κατηγορίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2724",
    gloss: "accusation",
  },
  κατηγορίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2724",
    gloss: "accusation",
  },
  κατήγοροι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G2725",
    gloss: "accuser",
  },
  κατήγοροί: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G2725",
    gloss: "accuser",
  },
  κατηγόροις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G2725",
    gloss: "accuser",
  },
  κατηγόρους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G2725",
    gloss: "accuser",
  },
  κατήφειαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2726",
    gloss: "gloominess",
  },
  κατηχήθης: {
    parse: "V-API-2S｜Verb, aorist, passive, indicative, second, singular",
    GN: "G2727",
    gloss: "to instruct",
  },
  κατηχήθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G2727",
    gloss: "to instruct",
  },
  κατηχημένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G2727",
    gloss: "to instruct",
  },
  κατήχηνται: {
    parse: "V-RPI-3P｜Verb, perfect, passive, indicative, third, plural",
    GN: "G2727",
    gloss: "to instruct",
  },
  κατηχήσω: {
    parse: "V-AAS-1S｜Verb, aorist, active, subjunctive, first, singular",
    GN: "G2727",
    gloss: "to instruct",
  },
  κατηχούμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G2727",
    gloss: "to instruct",
  },
  κατηχοῦντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G2727",
    gloss: "to instruct",
  },
  κατίωται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G2728",
    gloss: "to corrode",
  },
  κατίσχυον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2729",
    gloss: "to prevail",
  },
  κατισχύσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G2729",
    gloss: "to prevail",
  },
  κατισχύσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G2729",
    gloss: "to prevail",
  },
  κατοικεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2730",
    gloss: "to dwell",
  },
  κατοικεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2730",
    gloss: "to dwell",
  },
  κατοικεῖς: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G2730",
    gloss: "to dwell",
  },
  κατοικεῖτε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G2730",
    gloss: "to dwell",
  },
  κατοικῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2730",
    gloss: "to dwell",
  },
  κατοικήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G2730",
    gloss: "to dwell",
  },
  κατοικοῦντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G2730",
    gloss: "to dwell",
  },
  κατοικοῦντες: {
    parse:
      "V-PAP-VPM｜Verb, present, active, Participle, Vocative or nominative, plural, masculine",
    GN: "G2730",
    gloss: "to dwell",
  },
  κατοικοῦντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G2730",
    gloss: "to dwell",
  },
  κατοικούντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G2730",
    gloss: "to dwell",
  },
  κατοικοῦσιν: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G2730",
    gloss: "to dwell",
  },
  κατοικῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2730",
    gloss: "to dwell",
  },
  κατῴκησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2730",
    gloss: "to dwell",
  },
  κατοίκησιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2731",
    gloss: "dwelling",
  },
  κατοικητήριον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2732",
    gloss: "dwelling place",
  },
  κατοικίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2733",
    gloss: "dwelling",
  },
  κατοπτριζόμενοι: {
    parse:
      "V-PMP-NPM｜Verb, present, middle, Participle, nominative, plural, masculine",
    GN: "G2734",
    gloss: "to reflect",
  },
  κάτω: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2736",
    gloss: "under",
  },
  κατώτερα: {
    parse: "A-APN-C｜Adjective, accusative, plural, neuter, Comparative",
    GN: "G2737",
    gloss: "lower",
  },
  καῦμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2738",
    gloss: "heat",
  },
  ἐκαυματίσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2739",
    gloss: "to scorch",
  },
  ἐκαυματίσθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G2739",
    gloss: "to scorch",
  },
  καυματίσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2739",
    gloss: "to scorch",
  },
  καῦσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2740",
    gloss: "burning",
  },
  καυσούμενα: {
    parse:
      "V-PPP-NPN｜Verb, present, passive, Participle, nominative, plural, neuter",
    GN: "G2741",
    gloss: "to burn",
  },
  Καύσων: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2742",
    gloss: "heat",
  },
  καύσωνα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2742",
    gloss: "heat",
  },
  καύσωνι: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G2742",
    gloss: "heat",
  },
  κεκαυστηριασμένων: {
    parse:
      "V-RPP-GPM｜Verb, perfect, passive, Participle, genitive, plural, masculine",
    GN: "G2743",
    gloss: "to brand",
  },
  καυχᾶσαι: {
    parse:
      "V-PNI-2S｜Verb, present, Middle or Passive deponent, indicative, second, singular",
    GN: "G2744",
    gloss: "to boast",
  },
  καυχᾶσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G2744",
    gloss: "to boast",
  },
  Καυχᾶσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G2744",
    gloss: "to boast",
  },
  καυχᾶσθε: {
    parse:
      "V-PNI-2P｜Verb, present, Middle or Passive deponent, indicative, second, plural",
    GN: "G2744",
    gloss: "to boast",
  },
  καυχάσθω: {
    parse:
      "V-PNM-3S｜Verb, present, Middle or Passive deponent, imperative, third, singular",
    GN: "G2744",
    gloss: "to boast",
  },
  Καυχάσθω: {
    parse:
      "V-PNM-3S｜Verb, present, Middle or Passive deponent, imperative, third, singular",
    GN: "G2744",
    gloss: "to boast",
  },
  καυχήσασθαι: {
    parse: "V-ADN｜Verb, aorist, Middle deponent, Infinitive",
    GN: "G2744",
    gloss: "to boast",
  },
  καυχήσηται: {
    parse:
      "V-ADS-3S｜Verb, aorist, Middle deponent, subjunctive, third, singular",
    GN: "G2744",
    gloss: "to boast",
  },
  καυχήσομαι: {
    parse:
      "V-FDI-1S｜Verb, future, Middle deponent, indicative, first, singular",
    GN: "G2744",
    gloss: "to boast",
  },
  καυχησόμεθα: {
    parse: "V-FDI-1P｜Verb, future, Middle deponent, indicative, first, plural",
    GN: "G2744",
    gloss: "to boast",
  },
  καυχήσωμαι: {
    parse:
      "V-ADS-1S｜Verb, aorist, Middle deponent, subjunctive, first, singular",
    GN: "G2744",
    gloss: "to boast",
  },
  καυχήσωνται: {
    parse:
      "V-ADS-3P｜Verb, aorist, Middle deponent, subjunctive, third, plural",
    GN: "G2744",
    gloss: "to boast",
  },
  καυχῶμαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G2744",
    gloss: "to boast",
  },
  καυχώμεθα: {
    parse:
      "V-PNI-1P｜Verb, present, Middle or Passive deponent, indicative, first, plural",
    GN: "G2744",
    gloss: "to boast",
  },
  καυχώμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G2744",
    gloss: "to boast",
  },
  καυχώμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G2744",
    gloss: "to boast",
  },
  καυχωμένους: {
    parse:
      "V-PNP-APM｜Verb, present, Middle or Passive deponent, Participle, accusative, plural, masculine",
    GN: "G2744",
    gloss: "to boast",
  },
  καυχῶνται: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G2744",
    gloss: "to boast",
  },
  κεκαύχημαι: {
    parse:
      "V-RNI-1S｜Verb, perfect, Middle or Passive deponent, indicative, first, singular",
    GN: "G2744",
    gloss: "to boast",
  },
  καύχημα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2745",
    gloss: "pride",
  },
  καύχημά: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G2745",
    gloss: "pride",
  },
  καυχήματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G2745",
    gloss: "pride",
  },
  καυχήσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2746",
    gloss: "pride",
  },
  καύχησιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2746",
    gloss: "pride",
  },
  καύχησις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2746",
    gloss: "pride",
  },
  Κεγχρεαῖς: {
    parse: "N-DPF-L｜Noun, dative, plural, feminine, Location",
    GN: "G2747",
    gloss: "Cenchreae",
  },
  Κεδρὼν: {
    parse: "N-GSM-L｜Noun, genitive, singular, masculine, Location",
    GN: "G2748",
    gloss: "Kidron",
  },
  ἔκειτο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G2749",
    gloss: "to lay/be appointed",
  },
  κεῖμαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G2749",
    gloss: "to lay/be appointed",
  },
  κείμεθα: {
    parse:
      "V-PNI-1P｜Verb, present, Middle or Passive deponent, indicative, first, plural",
    GN: "G2749",
    gloss: "to lay/be appointed",
  },
  κείμενα: {
    parse:
      "V-PNP-APN｜Verb, present, Middle or Passive deponent, Participle, accusative, plural, neuter",
    GN: "G2749",
    gloss: "to lay/be appointed",
  },
  κείμεναι: {
    parse:
      "V-PNP-NPF｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, feminine",
    GN: "G2749",
    gloss: "to lay/be appointed",
  },
  κειμένη: {
    parse:
      "V-PNP-NSF｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, feminine",
    GN: "G2749",
    gloss: "to lay/be appointed",
  },
  κειμένην: {
    parse:
      "V-PNP-ASF｜Verb, present, Middle or Passive deponent, Participle, accusative, singular, feminine",
    GN: "G2749",
    gloss: "to lay/be appointed",
  },
  κείμενον: {
    parse:
      "V-PNP-ASN｜Verb, present, Middle or Passive deponent, Participle, accusative, singular, neuter",
    GN: "G2749",
    gloss: "to lay/be appointed",
  },
  κείμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G2749",
    gloss: "to lay/be appointed",
  },
  κεῖται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G2749",
    gloss: "to lay/be appointed",
  },
  κειρίαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G2750",
    gloss: "graveclothes",
  },
  κειράμενος: {
    parse:
      "V-AMP-NSM｜Verb, aorist, middle, Participle, nominative, singular, masculine",
    GN: "G2751",
    gloss: "to shear",
  },
  κείραντος: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G2751",
    gloss: "to shear",
  },
  κείρασθαι: {
    parse: "V-AMN｜Verb, aorist, middle, Infinitive",
    GN: "G2751",
    gloss: "to shear",
  },
  κειράσθω: {
    parse: "V-AMM-3S｜Verb, aorist, middle, imperative, third, singular",
    GN: "G2751",
    gloss: "to shear",
  },
  κελεύσματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G2752",
    gloss: "command",
  },
  ἐκέλευον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2753",
    gloss: "to order",
  },
  ἐκέλευσα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G2753",
    gloss: "to order",
  },
  ἐκέλευσε: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2753",
    gloss: "to order",
  },
  ἐκέλευσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2753",
    gloss: "to order",
  },
  ἐκέλευσέν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2753",
    gloss: "to order",
  },
  κελεύεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G2753",
    gloss: "to order",
  },
  κελεύσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G2753",
    gloss: "to order",
  },
  κελεύσαντος: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G2753",
    gloss: "to order",
  },
  κελεύσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G2753",
    gloss: "to order",
  },
  κέλευσον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G2753",
    gloss: "to order",
  },
  κέλευσόν: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G2753",
    gloss: "to order",
  },
  κενοδοξίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2754",
    gloss: "empty conceit",
  },
  κενόδοξοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G2755",
    gloss: "conceited",
  },
  κενά: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G2756",
    gloss: "empty",
  },
  κενέ: {
    // or nominative
    parse: "A-VSM｜Adjective, Vocative, singular, masculine",
    GN: "G2756",
    gloss: "empty",
  },
  κενὴ: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G2756",
    gloss: "empty",
  },
  κενῆς: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G2756",
    gloss: "empty",
  },
  κενοῖς: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G2756",
    gloss: "empty",
  },
  κενόν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G2756",
    gloss: "empty",
  },
  κενὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G2756",
    gloss: "empty",
  },
  κενὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2756",
    gloss: "empty",
  },
  κενούς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2756",
    gloss: "empty",
  },
  κενοφωνίας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G2757",
    gloss: "empty talk",
  },
  ἐκένωσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2758",
    gloss: "to empty",
  },
  κεκένωται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G2758",
    gloss: "to empty",
  },
  κενωθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G2758",
    gloss: "to empty",
  },
  κενώσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2758",
    gloss: "to empty",
  },
  κέντρα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G2759",
    gloss: "sting",
  },
  κέντρον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2759",
    gloss: "sting",
  },
  κεντυρίων: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2760",
    gloss: "centurion",
  },
  κεντυρίωνα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2760",
    gloss: "centurion",
  },
  κεντυρίωνος: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2760",
    gloss: "centurion",
  },
  κενῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2761",
    gloss: "vainly",
  },
  κεραία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2762",
    gloss: "tittle",
  },
  κεραίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2762",
    gloss: "tittle",
  },
  κεραμεὺς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2763",
    gloss: "potter",
  },
  κεραμέως: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2763",
    gloss: "potter",
  },
  Κεραμέως: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2763",
    gloss: "potter",
  },
  κεραμικὰ: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G2764",
    gloss: "made of clay",
  },
  κεράμιον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G2765",
    gloss: "clay jar",
  },
  κεράμων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G2766",
    gloss: "clay roof tile",
  },
  ἐκέρασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2767",
    gloss: "to mix",
  },
  κεκερασμένου: {
    parse:
      "V-RPP-GSM｜Verb, perfect, passive, Participle, genitive, singular, masculine",
    GN: "G2767",
    gloss: "to mix",
  },
  κεράσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G2767",
    gloss: "to mix",
  },
  κέρας: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G2768",
    gloss: "horn",
  },
  κέρατα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G2768",
    gloss: "horn",
  },
  κεράτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G2768",
    gloss: "horn",
  },
  κερατίων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G2769",
    gloss: "husk",
  },
  ἐκέρδησα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G2770",
    gloss: "to gain",
  },
  ἐκέρδησας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G2770",
    gloss: "to gain",
  },
  ἐκέρδησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2770",
    gloss: "to gain",
  },
  κερδάνω: {
    parse: "V-AAS-1S｜Verb, aorist, active, subjunctive, first, singular",
    GN: "G2770",
    gloss: "to gain",
  },
  κερδηθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G2770",
    gloss: "to gain",
  },
  κερδῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2770",
    gloss: "to gain",
  },
  κερδῆσαί: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2770",
    gloss: "to gain",
  },
  κερδήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G2770",
    gloss: "to gain",
  },
  κερδήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G2770",
    gloss: "to gain",
  },
  κερδήσομεν: {
    parse: "V-FAI-1P｜Verb, future, active, indicative, first, plural",
    GN: "G2770",
    gloss: "to gain",
  },
  κερδήσω: {
    parse: "V-AAS-1S｜Verb, aorist, active, subjunctive, first, singular",
    GN: "G2770",
    gloss: "to gain",
  },
  κέρδη: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G2771",
    gloss: "gain",
  },
  κέρδος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2771",
    gloss: "gain",
  },
  κέρδους: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G2771",
    gloss: "gain",
  },
  κέρματα: {
    parse: "N-ASN｜Noun, accusative, plural, neuter",
    GN: "G2772",
    gloss: "coin",
  },
  κέρμα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G2772",
    gloss: "coin",
  },
  κερματιστὰς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G2773",
    gloss: "moneychanger",
  },
  Κεφάλαιον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2774",
    gloss: "sum",
  },
  κεφαλαίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G2774",
    gloss: "sum",
  },
  ἐκεφαλίωσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2775",
    gloss: "to strike a head",
  },
  κεφαλαὶ: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G2776",
    gloss: "head",
  },
  κεφαλάς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G2776",
    gloss: "head",
  },
  κεφαλὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G2776",
    gloss: "head",
  },
  κεφαλῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2776",
    gloss: "head",
  },
  κεφαλή: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2776",
    gloss: "head",
  },
  κεφαλὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2776",
    gloss: "head",
  },
  κεφαλήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2776",
    gloss: "head",
  },
  κεφαλὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2776",
    gloss: "head",
  },
  κεφαλῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2776",
    gloss: "head",
  },
  κεφαλῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G2776",
    gloss: "head",
  },
  κεφαλίδι: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2777",
    gloss: "scroll",
  },
  κῆνσον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2778",
    gloss: "tax",
  },
  κήνσου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2778",
    gloss: "tax",
  },
  κῆπον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2779",
    gloss: "garden",
  },
  κῆπος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2779",
    gloss: "garden",
  },
  κήπῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G2779",
    gloss: "garden",
  },
  κηπουρός: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2780",
    gloss: "gardener",
  },
  κήρυγμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2782",
    gloss: "preaching",
  },
  κήρυγμά: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2782",
    gloss: "preaching",
  },
  κηρύγματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G2782",
    gloss: "preaching",
  },
  κηρύγματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G2782",
    gloss: "preaching",
  },
  κήρυκα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2783",
    gloss: "preacher",
  },
  κῆρυξ: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2783",
    gloss: "preacher",
  },
  ἐκηρύξαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G2784",
    gloss: "to preach",
  },
  ἐκήρυξαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2784",
    gloss: "to preach",
  },
  ἐκήρυξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2784",
    gloss: "to preach",
  },
  ἐκήρυσσεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G2784",
    gloss: "to preach",
  },
  ἐκήρυσσον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2784",
    gloss: "to preach",
  },
  ἐκηρύχθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2784",
    gloss: "to preach",
  },
  κηρύξαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2784",
    gloss: "to preach",
  },
  κηρύξας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G2784",
    gloss: "to preach",
  },
  κηρύξατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G2784",
    gloss: "to preach",
  },
  κήρυξον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G2784",
    gloss: "to preach",
  },
  κηρύξω: {
    parse: "V-AAS-1S｜Verb, aorist, active, subjunctive, first, singular",
    GN: "G2784",
    gloss: "to preach",
  },
  κηρύξωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G2784",
    gloss: "to preach",
  },
  κηρύσσει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2784",
    gloss: "to preach",
  },
  κηρύσσειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2784",
    gloss: "to preach",
  },
  κηρύσσεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G2784",
    gloss: "to preach",
  },
  κηρύσσετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G2784",
    gloss: "to preach",
  },
  κηρύσσομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G2784",
    gloss: "to preach",
  },
  κηρύσσοντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G2784",
    gloss: "to preach",
  },
  κηρύσσοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G2784",
    gloss: "to preach",
  },
  κηρύσσοντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G2784",
    gloss: "to preach",
  },
  κηρύσσουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2784",
    gloss: "to preach",
  },
  κηρύσσω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G2784",
    gloss: "to preach",
  },
  κηρύσσων: {
    parse:
      "V-PAP-VSM｜Verb, present, active, Participle, Vocative or nominative, singular, masculine",
    GN: "G2784",
    gloss: "to preach",
  },
  κηρυχθείς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G2784",
    gloss: "to preach",
  },
  κηρυχθέντος: {
    parse:
      "V-APP-GSN｜Verb, aorist, passive, Participle, genitive, singular, neuter",
    GN: "G2784",
    gloss: "to preach",
  },
  κηρυχθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G2784",
    gloss: "to preach",
  },
  κηρυχθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G2784",
    gloss: "to preach",
  },
  κηρυχθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G2784",
    gloss: "to preach",
  },
  κήτους: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G2785",
    gloss: "sea monster",
  },
  Κηφᾷ: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G2786",
    gloss: "Cephas",
  },
  Κηφᾶ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2786",
    gloss: "Cephas",
  },
  Κηφᾶν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2786",
    gloss: "Cephas",
  },
  Κηφᾶς: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2786",
    gloss: "Cephas",
  },
  κιβωτόν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2787",
    gloss: "Noah",
  },
  κιβωτὸν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2787",
    gloss: "Noah",
  },
  κιβωτὸς: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2787",
    gloss: "Noah",
  },
  κιβωτοῦ: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2787",
    gloss: "Noah",
  },
  κιθάρα: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2788",
    gloss: "harp",
  },
  κιθάραις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G2788",
    gloss: "harp",
  },
  κιθάραν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2788",
    gloss: "harp",
  },
  κιθάρας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G2788",
    gloss: "harp",
  },
  κιθαριζόμενον: {
    parse:
      "V-PPP-NSN｜Verb, present, passive, Participle, nominative, singular, neuter",
    GN: "G2789",
    gloss: "to play the harp",
  },
  κιθαριζόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G2789",
    gloss: "to play the harp",
  },
  κιθαρῳδῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G2790",
    gloss: "harpist",
  },
  Κιλικίαν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G2791",
    gloss: "Cilicia",
  },
  Κιλικίας: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G2791",
    gloss: "Cilicia",
  },
  κιννάμωμον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G2792",
    gloss: "cinnamon",
  },
  ἐκινδύνευον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2793",
    gloss: "be in danger",
  },
  κινδυνεύει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2793",
    gloss: "be in danger",
  },
  κινδυνεύομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G2793",
    gloss: "be in danger",
  },
  κινδύνοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G2794",
    gloss: "danger",
  },
  κίνδυνος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2794",
    gloss: "danger",
  },
  ἐκινήθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2795",
    gloss: "to move",
  },
  ἐκινήθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G2795",
    gloss: "to move",
  },
  κινῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2795",
    gloss: "to move",
  },
  κινήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G2795",
    gloss: "to move",
  },
  κινούμεθα: {
    parse: "V-PPI-1P｜Verb, present, passive, indicative, first, plural",
    GN: "G2795",
    gloss: "to move",
  },
  κινοῦντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G2795",
    gloss: "to move",
  },
  κινοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2795",
    gloss: "to move",
  },
  Κίς: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2797",
    gloss: "Kish",
  },
  κλάδοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G2798",
    gloss: "branch",
  },
  κλάδοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G2798",
    gloss: "branch",
  },
  κλάδος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2798",
    gloss: "branch",
  },
  κλάδους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G2798",
    gloss: "branch",
  },
  κλάδων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G2798",
    gloss: "branch",
  },
  ἔκλαιεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G2799",
    gloss: "to weep",
  },
  ἔκλαιον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2799",
    gloss: "to weep",
  },
  ἐκλαύσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G2799",
    gloss: "to weep",
  },
  ἔκλαυσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2799",
    gloss: "to weep",
  },
  κλαῖε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G2799",
    gloss: "to weep",
  },
  κλαίειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2799",
    gloss: "to weep",
  },
  κλαίεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G2799",
    gloss: "to weep",
  },
  κλαίετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G2799",
    gloss: "to weep",
  },
  κλαίοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G2799",
    gloss: "to weep",
  },
  κλαίοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2799",
    gloss: "to weep",
  },
  κλαιόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G2799",
    gloss: "to weep",
  },
  κλαίουσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G2799",
    gloss: "to weep",
  },
  κλαίουσαι: {
    parse:
      "V-PAP-NPF｜Verb, present, active, Participle, nominative, plural, feminine",
    GN: "G2799",
    gloss: "to weep",
  },
  κλαίουσαν: {
    parse:
      "V-PAP-ASF｜Verb, present, active, Participle, accusative, singular, feminine",
    GN: "G2799",
    gloss: "to weep",
  },
  κλαίουσιν: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G2799",
    gloss: "to weep",
  },
  κλαίων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2799",
    gloss: "to weep",
  },
  κλαύσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G2799",
    gloss: "to weep",
  },
  κλαύσετε: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G2799",
    gloss: "to weep",
  },
  κλαύσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G2799",
    gloss: "to weep",
  },
  κλαύσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G2799",
    gloss: "to weep",
  },
  κλάσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2800",
    gloss: "breaking",
  },
  κλάσματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G2801",
    gloss: "fragment",
  },
  κλασμάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G2801",
    gloss: "fragment",
  },
  Καῦδα: {
    parse: "N-ASN-L｜Noun, accusative, singular, neuter, Location",
    GN: "G2802",
    gloss: "Cauda",
  },
  Κλαυδία: {
    parse: "N-NSF-P｜Noun, nominative, singular, feminine, Person",
    GN: "G2803",
    gloss: "Claudia",
  },
  Κλαύδιον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2804",
    gloss: "Claudius",
  },
  Κλαύδιος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2804",
    gloss: "Claudius",
  },
  Κλαυδίου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2804",
    gloss: "Claudius",
  },
  κλαυθμὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2805",
    gloss: "weeping",
  },
  ἔκλασα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G2806",
    gloss: "to break",
  },
  ἔκλασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2806",
    gloss: "to break",
  },
  κλάσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2806",
    gloss: "to break",
  },
  κλάσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G2806",
    gloss: "to break",
  },
  κλῶμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G2806",
    gloss: "to break",
  },
  κλῶντές: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2806",
    gloss: "to break",
  },
  κλεῖδα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2807",
    gloss: "key",
  },
  κλεῖδας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G2807",
    gloss: "key",
  },
  κλεῖν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2807",
    gloss: "key",
  },
  κλεῖς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G2807",
    gloss: "key",
  },
  κλεὶς: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2807",
    gloss: "key",
  },
  ἔκλεισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2808",
    gloss: "to shut",
  },
  ἐκλείσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2808",
    gloss: "to shut",
  },
  ἐκλείσθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G2808",
    gloss: "to shut",
  },
  κεκλεισμένον: {
    parse:
      "V-RPP-ASN｜Verb, perfect, passive, Participle, accusative, singular, neuter",
    GN: "G2808",
    gloss: "to shut",
  },
  κεκλεισμένων: {
    parse:
      "V-RPP-GPF｜Verb, perfect, passive, Participle, genitive, plural, feminine",
    GN: "G2808",
    gloss: "to shut",
  },
  κέκλεισται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G2808",
    gloss: "to shut",
  },
  κλείετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G2808",
    gloss: "to shut",
  },
  κλεῖσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2808",
    gloss: "to shut",
  },
  κλείσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G2808",
    gloss: "to shut",
  },
  κλείσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2808",
    gloss: "to shut",
  },
  κλείσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G2808",
    gloss: "to shut",
  },
  κλεισθῶσιν: {
    parse: "V-APS-3P｜Verb, aorist, passive, subjunctive, third, plural",
    GN: "G2808",
    gloss: "to shut",
  },
  κλείων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2808",
    gloss: "to shut",
  },
  κλεμμάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G2809",
    gloss: "theft",
  },
  Κλεοπᾶς: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2810",
    gloss: "Cleopas",
  },
  κλέος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2811",
    gloss: "credit",
  },
  κλέπται: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G2812",
    gloss: "thief",
  },
  κλέπτης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2812",
    gloss: "thief",
  },
  ἔκλεψαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2813",
    gloss: "to steal",
  },
  κλέπτειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2813",
    gloss: "to steal",
  },
  κλέπτεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G2813",
    gloss: "to steal",
  },
  κλεπτέτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G2813",
    gloss: "to steal",
  },
  κλέπτουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2813",
    gloss: "to steal",
  },
  κλέπτων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2813",
    gloss: "to steal",
  },
  κλέψεις: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G2813",
    gloss: "to steal",
  },
  κλέψῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G2813",
    gloss: "to steal",
  },
  κλέψῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G2813",
    gloss: "to steal",
  },
  κλέψωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G2813",
    gloss: "to steal",
  },
  κλῆμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2814",
    gloss: "branch",
  },
  κλήματα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G2814",
    gloss: "branch",
  },
  Κλήμεντος: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2815",
    gloss: "Clement",
  },
  κεκληρονόμηκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G2816",
    gloss: "to inherit",
  },
  κληρονομεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2816",
    gloss: "to inherit",
  },
  κληρονομεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2816",
    gloss: "to inherit",
  },
  κληρονομῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2816",
    gloss: "to inherit",
  },
  κληρονομήσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G2816",
    gloss: "to inherit",
  },
  κληρονομήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2816",
    gloss: "to inherit",
  },
  κληρονομήσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G2816",
    gloss: "to inherit",
  },
  κληρονομήσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G2816",
    gloss: "to inherit",
  },
  κληρονομήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G2816",
    gloss: "to inherit",
  },
  κληρονομούντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G2816",
    gloss: "to inherit",
  },
  κληρονομία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2817",
    gloss: "inheritance",
  },
  κληρονομίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2817",
    gloss: "inheritance",
  },
  κληρονομίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2817",
    gloss: "inheritance",
  },
  κληρονόμοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G2818",
    gloss: "heir",
  },
  κληρονόμοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G2818",
    gloss: "heir",
  },
  κληρονόμον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2818",
    gloss: "heir",
  },
  κληρονόμος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2818",
    gloss: "heir",
  },
  κληρονόμους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G2818",
    gloss: "heir",
  },
  κλῆρον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2819",
    gloss: "lot",
  },
  κλῆρος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2819",
    gloss: "lot",
  },
  κλήρου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2819",
    gloss: "lot",
  },
  κλήρους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G2819",
    gloss: "lot",
  },
  κλήρων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G2819",
    gloss: "lot",
  },
  ἐκληρώθημεν: {
    parse: "V-API-1P｜Verb, aorist, passive, indicative, first, plural",
    GN: "G2820",
    gloss: "to obtain",
  },
  κλήσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2821",
    gloss: "calling",
  },
  κλήσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2821",
    gloss: "calling",
  },
  κλῆσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2821",
    gloss: "calling",
  },
  κλῆσις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2821",
    gloss: "calling",
  },
  κλητοὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G2822",
    gloss: "called",
  },
  κλητοῖς: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G2822",
    gloss: "called",
  },
  κλητὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2822",
    gloss: "called",
  },
  κλίβανον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2823",
    gloss: "oven",
  },
  κλίμασι: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G2824",
    gloss: "region",
  },
  κλίμασιν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G2824",
    gloss: "region",
  },
  κλίματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G2824",
    gloss: "region",
  },
  κλίνην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2825",
    gloss: "bed",
  },
  κλίνης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2825",
    gloss: "bed",
  },
  κλινῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G2825",
    gloss: "bed",
  },
  κλινίδιόν: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G2826",
    gloss: "bed",
  },
  κλινιδίῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G2826",
    gloss: "bed",
  },
  ἔκλιναν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2827",
    gloss: "to bow/lay down",
  },
  κέκλικεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G2827",
    gloss: "to bow/lay down",
  },
  κλίνας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G2827",
    gloss: "to bow/lay down",
  },
  κλίνειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2827",
    gloss: "to bow/lay down",
  },
  κλίνῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G2827",
    gloss: "to bow/lay down",
  },
  κλινουσῶν: {
    parse:
      "V-PAP-GPF｜Verb, present, active, Participle, genitive, plural, feminine",
    GN: "G2827",
    gloss: "to bow/lay down",
  },
  κλισίας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G2828",
    gloss: "group",
  },
  κλοπαί: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G2829",
    gloss: "theft",
  },
  κλύδωνι: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G2830",
    gloss: "waves",
  },
  κλυδωνιζόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G2831",
    gloss: "to toss",
  },
  Κλωπᾶ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2832",
    gloss: "Clopas",
  },
  κνηθόμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G2833",
    gloss: "to itch",
  },
  Κνίδον: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G2834",
    gloss: "Cnidus",
  },
  κοδράντην: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2835",
    gloss: "penny",
  },
  κοδράντης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2835",
    gloss: "penny",
  },
  κοιλίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2836",
    gloss: "belly/womb/stomach",
  },
  κοιλία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2836",
    gloss: "belly/womb/stomach",
  },
  κοιλίαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G2836",
    gloss: "belly/womb/stomach",
  },
  κοιλίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2836",
    gloss: "belly/womb/stomach",
  },
  κοιλίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2836",
    gloss: "belly/womb/stomach",
  },
  ἐκοιμήθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2837",
    gloss: "to sleep",
  },
  ἐκοιμήθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G2837",
    gloss: "to sleep",
  },
  κεκοιμημένων: {
    parse:
      "V-RMP-GPM｜Verb, perfect, middle, Participle, genitive, plural, masculine",
    GN: "G2837",
    gloss: "to sleep",
  },
  κεκοίμηται: {
    parse: "V-RMI-3S｜Verb, perfect, middle, indicative, third, singular",
    GN: "G2837",
    gloss: "to sleep",
  },
  κοιμηθέντας: {
    parse:
      "V-APP-APM｜Verb, aorist, passive, Participle, accusative, plural, masculine",
    GN: "G2837",
    gloss: "to sleep",
  },
  κοιμηθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G2837",
    gloss: "to sleep",
  },
  κοιμηθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G2837",
    gloss: "to sleep",
  },
  κοιμηθησόμεθα: {
    parse: "V-FPI-1P｜Verb, future, passive, indicative, first, plural",
    GN: "G2837",
    gloss: "to sleep",
  },
  κοιμώμενος: {
    parse:
      "V-PMP-NSM｜Verb, present, middle, Participle, nominative, singular, masculine",
    GN: "G2837",
    gloss: "to sleep",
  },
  κοιμωμένους: {
    parse:
      "V-PMP-APM｜Verb, present, middle, Participle, accusative, plural, masculine",
    GN: "G2837",
    gloss: "to sleep",
  },
  κοιμωμένων: {
    parse:
      "V-PMP-GPM｜Verb, present, middle, Participle, genitive, plural, masculine",
    GN: "G2837",
    gloss: "to sleep",
  },
  κοιμῶνται: {
    parse: "V-PMI-3P｜Verb, present, middle, indicative, third, plural",
    GN: "G2837",
    gloss: "to sleep",
  },
  κοιμήσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2838",
    gloss: "sleep",
  },
  κοινά: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G2839",
    gloss: "shared",
  },
  κοιναῖς: {
    parse: "A-DPF｜Adjective, dative, plural, feminine",
    GN: "G2839",
    gloss: "shared",
  },
  κοινὴν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G2839",
    gloss: "shared",
  },
  κοινῆς: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G2839",
    gloss: "shared",
  },
  κοινὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G2839",
    gloss: "shared",
  },
  κοινόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G2839",
    gloss: "shared",
  },
  κεκοίνωκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G2840",
    gloss: "to profane",
  },
  κεκοινωμένους: {
    parse:
      "V-RPP-APM｜Verb, perfect, passive, Participle, accusative, plural, masculine",
    GN: "G2840",
    gloss: "to profane",
  },
  κοινοῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2840",
    gloss: "to profane",
  },
  κοίνου: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G2840",
    gloss: "to profane",
  },
  κοινοῦντα: {
    parse:
      "V-PAP-NPN｜Verb, present, active, Participle, nominative, plural, neuter",
    GN: "G2840",
    gloss: "to profane",
  },
  κοινῶσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2840",
    gloss: "to profane",
  },
  ἐκοινώνησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2841",
    gloss: "to participate",
  },
  ἐκοινώνησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2841",
    gloss: "to participate",
  },
  κεκοινώνηκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G2841",
    gloss: "to participate",
  },
  κοινωνεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2841",
    gloss: "to participate",
  },
  κοινώνει: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G2841",
    gloss: "to participate",
  },
  κοινωνεῖτε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G2841",
    gloss: "to participate",
  },
  Κοινωνείτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G2841",
    gloss: "to participate",
  },
  κοινωνοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2841",
    gloss: "to participate",
  },
  κοινωνίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2842",
    gloss: "participation",
  },
  κοινωνία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2842",
    gloss: "participation",
  },
  κοινωνίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2842",
    gloss: "participation",
  },
  κοινωνίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2842",
    gloss: "participation",
  },
  κοινωνικούς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2843",
    gloss: "generous",
  },
  κοινωνοί: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G2844",
    gloss: "participant",
  },
  κοινωνοὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G2844",
    gloss: "participant",
  },
  κοινωνόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2844",
    gloss: "participant",
  },
  κοινωνός: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2844",
    gloss: "participant",
  },
  κοινωνὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2844",
    gloss: "participant",
  },
  κοινωνοὺς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G2844",
    gloss: "participant",
  },
  κοίταις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G2845",
    gloss: "bed",
  },
  κοίτη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2845",
    gloss: "bed",
  },
  κοίτην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2845",
    gloss: "bed",
  },
  κοιτῶνος: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2846",
    gloss: "bedroom/chamberlain",
  },
  κοκκίνην: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G2847",
    gloss: "scarlet",
  },
  κόκκινον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G2847",
    gloss: "scarlet",
  },
  κοκκίνου: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G2847",
    gloss: "scarlet",
  },
  κόκκον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2848",
    gloss: "seed",
  },
  κόκκος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2848",
    gloss: "seed",
  },
  κόκκῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G2848",
    gloss: "seed",
  },
  κολαζομένους: {
    parse:
      "V-PPP-APM｜Verb, present, passive, Participle, accusative, plural, masculine",
    GN: "G2849",
    gloss: "to punish",
  },
  κολάσωνται: {
    parse: "V-AMS-3P｜Verb, aorist, middle, subjunctive, third, plural",
    GN: "G2849",
    gloss: "to punish",
  },
  κολακείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2850",
    gloss: "flattery",
  },
  κόλασιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2851",
    gloss: "punishment",
  },
  ἐκολάφισαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2852",
    gloss: "to beat",
  },
  κολαφίζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2852",
    gloss: "to beat",
  },
  κολαφίζῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G2852",
    gloss: "to beat",
  },
  κολαφιζόμεθα: {
    parse: "V-PPI-1P｜Verb, present, passive, indicative, first, plural",
    GN: "G2852",
    gloss: "to beat",
  },
  κολαφιζόμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G2852",
    gloss: "to beat",
  },
  ἐκολλήθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2853",
    gloss: "to join",
  },
  ἐκολλήθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G2853",
    gloss: "to join",
  },
  κολλᾶσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G2853",
    gloss: "to join",
  },
  κολληθέντα: {
    parse:
      "V-APP-ASM｜Verb, aorist, passive, Participle, accusative, singular, masculine",
    GN: "G2853",
    gloss: "to join",
  },
  κολληθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G2853",
    gloss: "to join",
  },
  κολληθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G2853",
    gloss: "to join",
  },
  κολλήθητι: {
    parse: "V-APM-2S｜Verb, aorist, passive, imperative, second, singular",
    GN: "G2853",
    gloss: "to join",
  },
  κολλώμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G2853",
    gloss: "to join",
  },
  κολλώμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G2853",
    gloss: "to join",
  },
  κολλούριον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G2854",
    gloss: "eye salve",
  },
  κολλυβιστῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G2855",
    gloss: "moneychanger",
  },
  ἐκολοβώθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G2856",
    gloss: "to shorten",
  },
  ἐκολόβωσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2856",
    gloss: "to shorten",
  },
  κολοβωθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G2856",
    gloss: "to shorten",
  },
  Κολοσσαῖς: {
    parse: "N-DPF-L｜Noun, dative, plural, feminine, Location",
    GN: "G2857",
    gloss: "Colossae",
  },
  κόλποις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G2859",
    gloss: "bosom",
  },
  κόλπον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2859",
    gloss: "bosom",
  },
  κόλπῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G2859",
    gloss: "bosom",
  },
  κολυμβᾶν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2860",
    gloss: "to swim",
  },
  κολυμβήθρα: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2861",
    gloss: "pool",
  },
  κολυμβήθραν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2861",
    gloss: "pool",
  },
  κολωνία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2862",
    gloss: "Roman colony",
  },
  κομᾷ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G2863",
    gloss: "be long-haired",
  },
  κόμη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2864",
    gloss: "hair",
  },
  ἐκομισάμην: {
    parse: "V-AMI-1S｜Verb, aorist, middle, indicative, first, singular",
    GN: "G2865",
    gloss: "to bring/be repaid",
  },
  ἐκομίσαντο: {
    parse: "V-AMI-3P｜Verb, aorist, middle, indicative, third, plural",
    GN: "G2865",
    gloss: "to bring/be repaid",
  },
  ἐκομίσατο: {
    parse: "V-AMI-3S｜Verb, aorist, middle, indicative, third, singular",
    GN: "G2865",
    gloss: "to bring/be repaid",
  },
  κομιεῖσθε: {
    parse:
      "V-FDI-2P｜Verb, future, Middle deponent, indicative, second, plural",
    GN: "G2865",
    gloss: "to bring/be repaid",
  },
  κομιζόμενοι: {
    parse:
      "V-PMP-NPM｜Verb, present, middle, Participle, nominative, plural, masculine",
    GN: "G2865",
    gloss: "to bring/be repaid",
  },
  κομίσασα: {
    parse:
      "V-AAP-NSF｜Verb, aorist, active, Participle, nominative, singular, feminine",
    GN: "G2865",
    gloss: "to bring/be repaid",
  },
  κομίσεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G2865",
    gloss: "to bring/be repaid",
  },
  κομίσησθε: {
    parse: "V-AMS-2P｜Verb, aorist, middle, subjunctive, second, plural",
    GN: "G2865",
    gloss: "to bring/be repaid",
  },
  κομίσηται: {
    parse: "V-AMS-3S｜Verb, aorist, middle, subjunctive, third, singular",
    GN: "G2865",
    gloss: "to bring/be repaid",
  },
  κομψότερον: {
    parse: "A-ASN-C｜Adjective, accusative, singular, neuter, Comparative",
    GN: "G2866",
    gloss: "better",
  },
  κεκονιαμένε: {
    // or nominative
    parse:
      "V-RPP-VSM｜Verb, perfect, passive, Participle, Vocative, singular, masculine",
    GN: "G2867",
    gloss: "to whitewash",
  },
  κεκονιαμένοις: {
    parse:
      "V-RPP-DPM｜Verb, perfect, passive, Participle, dative, plural, masculine",
    GN: "G2867",
    gloss: "to whitewash",
  },
  κονιορτὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2868",
    gloss: "dust",
  },
  ἐκόπασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2869",
    gloss: "to abate",
  },
  κοπετὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2870",
    gloss: "lamentation",
  },
  κοπῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2871",
    gloss: "slaughter",
  },
  ἐκοπίασα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G2872",
    gloss: "to labor",
  },
  ἐκοπίασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2872",
    gloss: "to labor",
  },
  κεκοπίακα: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G2872",
    gloss: "to labor",
  },
  κεκοπιάκασιν: {
    parse: "V-RAI-3P｜Verb, perfect, active, indicative, third, plural",
    GN: "G2872",
    gloss: "to labor",
  },
  κεκοπιάκατε: {
    parse: "V-RAI-2P｜Verb, perfect, active, indicative, second, plural",
    GN: "G2872",
    gloss: "to labor",
  },
  κεκοπίακες: {
    parse: "V-RAI-2S｜Verb, perfect, active, indicative, second, singular",
    GN: "G2872",
    gloss: "to labor",
  },
  κεκοπιακὼς: {
    parse:
      "V-RAP-NSM｜Verb, perfect, active, Participle, nominative, singular, masculine",
    GN: "G2872",
    gloss: "to labor",
  },
  κοπιᾷ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2872",
    gloss: "to labor",
  },
  κοπιάσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G2872",
    gloss: "to labor",
  },
  κοπιάτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G2872",
    gloss: "to labor",
  },
  κοπιῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G2872",
    gloss: "to labor",
  },
  κοπιῶμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G2872",
    gloss: "to labor",
  },
  κοπιῶντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G2872",
    gloss: "to labor",
  },
  κοπιῶντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G2872",
    gloss: "to labor",
  },
  κοπιῶντες: {
    parse:
      "V-PAP-VPM｜Verb, present, active, Participle, Vocative or nominative, plural, masculine",
    GN: "G2872",
    gloss: "to labor",
  },
  κοπιῶντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G2872",
    gloss: "to labor",
  },
  κοπιώσας: {
    parse:
      "V-PAP-APF｜Verb, present, active, Participle, accusative, plural, feminine",
    GN: "G2872",
    gloss: "to labor",
  },
  κοπιῶσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2872",
    gloss: "to labor",
  },
  κοπιοῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2872",
    gloss: "to labor",
  },
  κόποις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G2873",
    gloss: "labor",
  },
  κόπον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2873",
    gloss: "labor",
  },
  κόπος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2873",
    gloss: "labor",
  },
  κόπου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2873",
    gloss: "labor",
  },
  κόπους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G2873",
    gloss: "labor",
  },
  κόπῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G2873",
    gloss: "labor",
  },
  κόπων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G2873",
    gloss: "labor",
  },
  κόπρια: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G2874",
    gloss: "manure",
  },
  κοπρίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2874",
    gloss: "manure",
  },
  ἔκοπτον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2875",
    gloss: "to cut/mourn",
  },
  ἐκόπτοντο: {
    parse: "V-IMI-3P｜Verb, imperfect, middle, indicative, third, plural",
    GN: "G2875",
    gloss: "to cut/mourn",
  },
  ἐκόψασθε: {
    parse: "V-AMI-2P｜Verb, aorist, middle, indicative, second, plural",
    GN: "G2875",
    gloss: "to cut/mourn",
  },
  κόψαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G2875",
    gloss: "to cut/mourn",
  },
  κόψονται: {
    parse: "V-FDI-3P｜Verb, future, Middle deponent, indicative, third, plural",
    GN: "G2875",
    gloss: "to cut/mourn",
  },
  κόρακας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G2876",
    gloss: "raven",
  },
  κοράσιον: {
    parse: "N-VSN｜Noun, Vocative or nominative, singular, neuter",
    GN: "G2877",
    gloss: "girl",
  },
  κορασίῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G2877",
    gloss: "girl",
  },
  Κορβᾶν: {
    parse: "N-NSM-T｜Noun, nominative, singular, masculine, Title",
    GN: "G2878",
    gloss: "Corban",
  },
  κορβανᾶν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2878",
    gloss: "Corban",
  },
  Κόρε: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2879",
    gloss: "Korah",
  },
  κεκορεσμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G2880",
    gloss: "to satisfy",
  },
  κορεσθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G2880",
    gloss: "to satisfy",
  },
  Κορίνθιοι: {
    parse:
      "N-VPM-LG｜Noun, Vocative or nominative, plural, masculine, Location Gentilic",
    GN: "G2881",
    gloss: "Corinthian",
  },
  Κορινθίων: {
    parse: "N-GPM-LG｜Noun, genitive, plural, masculine, Location Gentilic",
    GN: "G2881",
    gloss: "Corinthian",
  },
  Κόρινθον: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G2882",
    gloss: "Corinth",
  },
  Κορίνθῳ: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G2882",
    gloss: "Corinth",
  },
  Κορνήλιε: {
    parse: "N-VSM-P｜Noun, Vocative or nominative, singular, masculine, Person",
    GN: "G2883",
    gloss: "Cornelius",
  },
  Κορνήλιος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2883",
    gloss: "Cornelius",
  },
  Κορνηλίου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2883",
    gloss: "Cornelius",
  },
  κόρους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G2884",
    gloss: "cor",
  },
  ἐκόσμησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2885",
    gloss: "to arrange",
  },
  ἐκόσμουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2885",
    gloss: "to arrange",
  },
  κεκοσμημένην: {
    parse:
      "V-RPP-ASF｜Verb, perfect, passive, Participle, accusative, singular, feminine",
    GN: "G2885",
    gloss: "to arrange",
  },
  κεκοσμημένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G2885",
    gloss: "to arrange",
  },
  κεκοσμημένον: {
    parse:
      "V-RPP-ASM｜Verb, perfect, passive, Participle, accusative, singular, masculine",
    GN: "G2885",
    gloss: "to arrange",
  },
  κεκόσμηται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G2885",
    gloss: "to arrange",
  },
  κοσμεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2885",
    gloss: "to arrange",
  },
  κοσμεῖτε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G2885",
    gloss: "to arrange",
  },
  κοσμῶσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G2885",
    gloss: "to arrange",
  },
  κοσμικὰς: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G2886",
    gloss: "earthly",
  },
  κοσμικόν: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G2886",
    gloss: "earthly",
  },
  κόσμιον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G2887",
    gloss: "respectable",
  },
  κοσμίῳ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G2887",
    gloss: "respectable",
  },
  κοσμοκράτορας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G2888",
    gloss: "world power",
  },
  κόσμον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2889",
    gloss: "world",
  },
  κόσμος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2889",
    gloss: "world",
  },
  κόσμου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2889",
    gloss: "world",
  },
  κόσμῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G2889",
    gloss: "world",
  },
  Κούαρτος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2890",
    gloss: "Quartus",
  },
  κούμ: {
    parse: "ARAM｜Aramaic transliterated word (indeclinable)",
    GN: "G2891",
    gloss: "stand up!",
  },
  κουστωδίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2892",
    gloss: "guard",
  },
  κουστωδίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2892",
    gloss: "guard",
  },
  ἐκούφιζον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2893",
    gloss: "to lighten",
  },
  κόφινοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G2894",
    gloss: "basket",
  },
  κοφίνους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G2894",
    gloss: "basket",
  },
  κοφίνων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G2894",
    gloss: "basket",
  },
  κραβάττοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G2895",
    gloss: "bed",
  },
  κράβαττον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2895",
    gloss: "bed",
  },
  κράβαττόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2895",
    gloss: "bed",
  },
  κραβάττου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2895",
    gloss: "bed",
  },
  κραβάττων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G2895",
    gloss: "bed",
  },
  ἐκέκραξα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G2896",
    gloss: "to cry",
  },
  ἔκραζεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G2896",
    gloss: "to cry",
  },
  ἔκραζον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2896",
    gloss: "to cry",
  },
  ἔκραξαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2896",
    gloss: "to cry",
  },
  ἔκραξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2896",
    gloss: "to cry",
  },
  κέκραγεν: {
    parse: "V-2RAI-3S｜Verb, 2nd perfect, active, indicative, third, singular",
    GN: "G2896",
    gloss: "to cry",
  },
  κράζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2896",
    gloss: "to cry",
  },
  κράζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2896",
    gloss: "to cry",
  },
  κράζομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G2896",
    gloss: "to cry",
  },
  κρᾶζον: {
    parse:
      "V-PAP-ASN｜Verb, present, active, Participle, accusative, singular, neuter",
    GN: "G2896",
    gloss: "to cry",
  },
  κράζοντα: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G2896",
    gloss: "to cry",
  },
  κράζοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G2896",
    gloss: "to cry",
  },
  κράζοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2896",
    gloss: "to cry",
  },
  κραζόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G2896",
    gloss: "to cry",
  },
  κράζουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2896",
    gloss: "to cry",
  },
  κράζων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2896",
    gloss: "to cry",
  },
  κράξαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G2896",
    gloss: "to cry",
  },
  κράξας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G2896",
    gloss: "to cry",
  },
  κράξουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G2896",
    gloss: "to cry",
  },
  κραιπάλῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2897",
    gloss: "dissipation",
  },
  Κρανίον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G2898",
    gloss: "Skull",
  },
  Κρανίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G2898",
    gloss: "Skull",
  },
  κράσπεδα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G2899",
    gloss: "edge",
  },
  κρασπέδου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G2899",
    gloss: "edge",
  },
  κραταιὰν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G2900",
    gloss: "mighty",
  },
  ἐκραταιοῦτο: {
    parse: "V-IPI-3S｜Verb, imperfect, passive, indicative, third, singular",
    GN: "G2901",
    gloss: "to strengthen",
  },
  κραταιοῦσθε: {
    parse: "V-PPM-2P｜Verb, present, passive, imperative, second, plural",
    GN: "G2901",
    gloss: "to strengthen",
  },
  κραταιωθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G2901",
    gloss: "to strengthen",
  },
  ἐκρατήσαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G2902",
    gloss: "to grasp/seize",
  },
  ἐκράτησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2902",
    gloss: "to grasp/seize",
  },
  ἐκρατήσατέ: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G2902",
    gloss: "to grasp/seize",
  },
  ἐκράτησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2902",
    gloss: "to grasp/seize",
  },
  ἐκρατοῦντο: {
    parse: "V-IPI-3P｜Verb, imperfect, passive, indicative, third, plural",
    GN: "G2902",
    gloss: "to grasp/seize",
  },
  κεκρατηκέναι: {
    parse: "V-RAN｜Verb, perfect, active, Infinitive",
    GN: "G2902",
    gloss: "to grasp/seize",
  },
  κεκράτηνται: {
    parse: "V-RPI-3P｜Verb, perfect, passive, indicative, third, plural",
    GN: "G2902",
    gloss: "to grasp/seize",
  },
  κράτει: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G2904",
    gloss: "power",
  },
  κρατεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2902",
    gloss: "to grasp/seize",
  },
  κρατεῖς: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G2902",
    gloss: "to grasp/seize",
  },
  κρατεῖσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G2902",
    gloss: "to grasp/seize",
  },
  κρατεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G2902",
    gloss: "to grasp/seize",
  },
  κρατῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2902",
    gloss: "to grasp/seize",
  },
  κρατήσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G2902",
    gloss: "to grasp/seize",
  },
  κρατήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G2902",
    gloss: "to grasp/seize",
  },
  κρατήσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G2902",
    gloss: "to grasp/seize",
  },
  κρατήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2902",
    gloss: "to grasp/seize",
  },
  κρατήσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G2902",
    gloss: "to grasp/seize",
  },
  κρατῆτε: {
    parse: "V-PAS-2P｜Verb, present, active, subjunctive, second, plural",
    GN: "G2902",
    gloss: "to grasp/seize",
  },
  κρατοῦντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G2902",
    gloss: "to grasp/seize",
  },
  κρατοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2902",
    gloss: "to grasp/seize",
  },
  Κρατοῦντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G2902",
    gloss: "to grasp/seize",
  },
  κρατοῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2902",
    gloss: "to grasp/seize",
  },
  κρατῶμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G2902",
    gloss: "to grasp/seize",
  },
  κρατῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2902",
    gloss: "to grasp/seize",
  },
  κράτιστε: {
    // or nominative
    parse: "A-VSM｜Adjective, Vocative, singular, masculine",
    GN: "G2903",
    gloss: "excellent",
  },
  κρατίστῳ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G2903",
    gloss: "excellent",
  },
  κράτος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2904",
    gloss: "power",
  },
  κράτους: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G2904",
    gloss: "power",
  },
  ἐκραύγαζον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2905",
    gloss: "to shout",
  },
  ἐκραύγασαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2905",
    gloss: "to shout",
  },
  ἐκραύγασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2905",
    gloss: "to shout",
  },
  κραυγάζοντα: {
    parse:
      "V-PAP-NPN｜Verb, present, active, Participle, nominative, plural, neuter",
    GN: "G2905",
    gloss: "to shout",
  },
  κραυγαζόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G2905",
    gloss: "to shout",
  },
  κραυγάσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2905",
    gloss: "to shout",
  },
  κραυγῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2906",
    gloss: "shouting",
  },
  κραυγὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2906",
    gloss: "shouting",
  },
  κραυγῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2906",
    gloss: "shouting",
  },
  κρέα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G2907",
    gloss: "meat",
  },
  κρεῖσσον: {
    parse: "A-NSN-C｜Adjective, nominative, singular, neuter, Comparative",
    GN: "G2908",
    gloss: "greater",
  },
  κρείσσονα: {
    parse: "A-APN-C｜Adjective, accusative, plural, neuter, Comparative",
    GN: "G2908",
    gloss: "greater",
  },
  κρεῖττον: {
    parse: "A-NSN-C｜Adjective, nominative, singular, neuter, Comparative",
    GN: "G2908",
    gloss: "greater",
  },
  κρεῖττόν: {
    parse: "A-ASN-C｜Adjective, accusative, singular, neuter, Comparative",
    GN: "G2908",
    gloss: "greater",
  },
  κρείττονα: {
    parse: "A-ASF-C｜Adjective, accusative, singular, feminine, Comparative",
    GN: "G2908",
    gloss: "greater",
  },
  κρείττονος: {
    parse: "A-GSM-C｜Adjective, genitive, singular, masculine, Comparative",
    GN: "G2908",
    gloss: "greater",
  },
  κρείττονός: {
    parse: "A-GSF-C｜Adjective, genitive, singular, feminine, Comparative",
    GN: "G2908",
    gloss: "greater",
  },
  κρείττοσι: {
    parse: "A-DPF-C｜Adjective, dative, plural, feminine, Comparative",
    GN: "G2908",
    gloss: "greater",
  },
  κρείττοσιν: {
    parse: "A-DPF-C｜Adjective, dative, plural, feminine, Comparative",
    GN: "G2908",
    gloss: "greater",
  },
  κρείττων: {
    parse: "A-NSM-C｜Adjective, nominative, singular, masculine, Comparative",
    GN: "G2908",
    gloss: "greater",
  },
  κρεμάμενον: {
    parse:
      "V-PMP-ASN｜Verb, present, middle, Participle, accusative, singular, neuter",
    GN: "G2910",
    gloss: "to hang",
  },
  κρεμάμενος: {
    parse:
      "V-PMP-NSM｜Verb, present, middle, Participle, nominative, singular, masculine",
    GN: "G2910",
    gloss: "to hang",
  },
  κρεμάσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G2910",
    gloss: "to hang",
  },
  κρεμασθέντων: {
    parse:
      "V-APP-GPM｜Verb, aorist, passive, Participle, genitive, plural, masculine",
    GN: "G2910",
    gloss: "to hang",
  },
  κρεμασθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G2910",
    gloss: "to hang",
  },
  κρέμαται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G2910",
    gloss: "to hang",
  },
  κρημνοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2911",
    gloss: "cliff",
  },
  Κρῆτες: {
    parse: "N-NPM-LG｜Noun, nominative, plural, masculine, Location Gentilic",
    GN: "G2912",
    gloss: "Cretan",
  },
  Κρήσκης: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2913",
    gloss: "Crescens",
  },
  Κρήτῃ: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G2914",
    gloss: "Crete",
  },
  Κρήτην: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G2914",
    gloss: "Crete",
  },
  Κρήτης: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G2914",
    gloss: "Crete",
  },
  κριθῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G2915",
    gloss: "barley",
  },
  κριθίνους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2916",
    gloss: "barley",
  },
  κριθίνων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G2916",
    gloss: "barley",
  },
  κρίμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2917",
    gloss: "judgment",
  },
  κρίματα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G2917",
    gloss: "judgment",
  },
  κρίματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G2917",
    gloss: "judgment",
  },
  κρίματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G2917",
    gloss: "judgment",
  },
  κρίνα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G2918",
    gloss: "lily",
  },
  ἐκρίθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2919",
    gloss: "to judge",
  },
  ἐκρίθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G2919",
    gloss: "to judge",
  },
  ἔκρινα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G2919",
    gloss: "to judge",
  },
  ἔκρινά: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G2919",
    gloss: "to judge",
  },
  ἔκρινας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G2919",
    gloss: "to judge",
  },
  ἔκρινεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2919",
    gloss: "to judge",
  },
  ἐκρινόμεθα: {
    parse: "V-IPI-1P｜Verb, imperfect, passive, indicative, first, plural",
    GN: "G2919",
    gloss: "to judge",
  },
  κέκρικα: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G2919",
    gloss: "to judge",
  },
  κεκρίκατέ: {
    parse: "V-RAI-2P｜Verb, perfect, active, indicative, second, plural",
    GN: "G2919",
    gloss: "to judge",
  },
  κεκρίκει: {
    parse: "V-LAI-3S｜Verb, pluperfect, active, indicative, third, singular",
    GN: "G2919",
    gloss: "to judge",
  },
  κέκρικεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G2919",
    gloss: "to judge",
  },
  κεκριμένα: {
    parse:
      "V-RPP-APN｜Verb, perfect, passive, Participle, accusative, plural, neuter",
    GN: "G2919",
    gloss: "to judge",
  },
  κέκριται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G2919",
    gloss: "to judge",
  },
  κριθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G2919",
    gloss: "to judge",
  },
  κριθήσεσθε: {
    parse: "V-FPI-2P｜Verb, future, passive, indicative, second, plural",
    GN: "G2919",
    gloss: "to judge",
  },
  κριθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G2919",
    gloss: "to judge",
  },
  κριθῆτε: {
    parse: "V-APS-2P｜Verb, aorist, passive, subjunctive, second, plural",
    GN: "G2919",
    gloss: "to judge",
  },
  κριθῶσι: {
    parse: "V-APS-3P｜Verb, aorist, passive, subjunctive, third, plural",
    GN: "G2919",
    gloss: "to judge",
  },
  κριθῶσιν: {
    parse: "V-APS-3P｜Verb, aorist, passive, subjunctive, third, plural",
    GN: "G2919",
    gloss: "to judge",
  },
  κρῖναι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2919",
    gloss: "to judge",
  },
  κρίναντας: {
    parse:
      "V-AAP-APM｜Verb, aorist, active, Participle, accusative, plural, masculine",
    GN: "G2919",
    gloss: "to judge",
  },
  κρίναντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G2919",
    gloss: "to judge",
  },
  κρίναντος: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G2919",
    gloss: "to judge",
  },
  κρίνας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G2919",
    gloss: "to judge",
  },
  κρίνατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G2919",
    gloss: "to judge",
  },
  κρινεῖ: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2919",
    gloss: "to judge",
  },
  Κρινεῖ: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2919",
    gloss: "to judge",
  },
  κρίνει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2919",
    gloss: "to judge",
  },
  κρίνειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2919",
    gloss: "to judge",
  },
  κρίνεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G2919",
    gloss: "to judge",
  },
  κρίνεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G2919",
    gloss: "to judge",
  },
  κρίνεσθαί: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G2919",
    gloss: "to judge",
  },
  κρίνεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G2919",
    gloss: "to judge",
  },
  κρίνετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G2919",
    gloss: "to judge",
  },
  κρινέτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G2919",
    gloss: "to judge",
  },
  κρίνῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G2919",
    gloss: "to judge",
  },
  κρίνομαι: {
    parse: "V-PPI-1S｜Verb, present, passive, indicative, first, singular",
    GN: "G2919",
    gloss: "to judge",
  },
  κρινόμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G2919",
    gloss: "to judge",
  },
  κρινόμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G2919",
    gloss: "to judge",
  },
  κρίνοντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G2919",
    gloss: "to judge",
  },
  κρίνοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2919",
    gloss: "to judge",
  },
  κρίνοντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G2919",
    gloss: "to judge",
  },
  κρινοῦμεν: {
    parse: "V-FAI-1P｜Verb, future, active, indicative, first, plural",
    GN: "G2919",
    gloss: "to judge",
  },
  κρινοῦσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G2919",
    gloss: "to judge",
  },
  κρίνω: {
    parse: "V-PAS-1S｜Verb, present, active, subjunctive, first, singular",
    GN: "G2919",
    gloss: "to judge",
  },
  κρινῶ: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G2919",
    gloss: "to judge",
  },
  κρίνωμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G2919",
    gloss: "to judge",
  },
  κρίνων: {
    parse:
      "V-PAP-VSM｜Verb, present, active, Participle, Vocative or nominative, singular, masculine",
    GN: "G2919",
    gloss: "to judge",
  },
  κρίσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2920",
    gloss: "judgment",
  },
  κρίσεις: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G2920",
    gloss: "judgment",
  },
  κρίσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2920",
    gloss: "judgment",
  },
  κρίσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2920",
    gloss: "judgment",
  },
  κρίσις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2920",
    gloss: "judgment",
  },
  Κρίσπον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2921",
    gloss: "Crispus",
  },
  Κρίσπος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2921",
    gloss: "Crispus",
  },
  κριτήρια: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G2922",
    gloss: "court/lawsuit",
  },
  κριτηρίων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G2922",
    gloss: "court/lawsuit",
  },
  κριταὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G2923",
    gloss: "judge",
  },
  κριτὰς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G2923",
    gloss: "judge",
  },
  κριτῇ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G2923",
    gloss: "judge",
  },
  κριτήν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2923",
    gloss: "judge",
  },
  κριτὴν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2923",
    gloss: "judge",
  },
  κριτής: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2923",
    gloss: "judge",
  },
  Κριτής: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2923",
    gloss: "judge",
  },
  κριτὴς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2923",
    gloss: "judge",
  },
  κριτικὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2924",
    gloss: "discerning",
  },
  κρούειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2925",
    gloss: "to knock",
  },
  κρούετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G2925",
    gloss: "to knock",
  },
  κρούοντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G2925",
    gloss: "to knock",
  },
  κρούσαντος: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G2925",
    gloss: "to knock",
  },
  κρούω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G2925",
    gloss: "to knock",
  },
  κρούων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2925",
    gloss: "to knock",
  },
  κρύπτην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2926",
    gloss: "cellar",
  },
  κρυπτὰ: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G2927",
    gloss: "hidden",
  },
  κρυπτὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G2927",
    gloss: "hidden",
  },
  κρυπτὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2927",
    gloss: "hidden",
  },
  κρυπτῷ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G2927",
    gloss: "hidden",
  },
  ἐκρύβη: {
    parse: "V-2API-3S｜Verb, 2nd aorist, passive, indicative, third, singular",
    GN: "G2928",
    gloss: "to hide",
  },
  ἔκρυψα: {
    parse: "V-2AAI-1S｜Verb, 2nd aorist, active, indicative, first, singular",
    GN: "G2928",
    gloss: "to hide",
  },
  ἔκρυψαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G2928",
    gloss: "to hide",
  },
  ἔκρυψας: {
    parse: "V-2AAI-2S｜Verb, 2nd aorist, active, indicative, second, singular",
    GN: "G2928",
    gloss: "to hide",
  },
  ἔκρυψεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G2928",
    gloss: "to hide",
  },
  κεκρυμμένα: {
    parse:
      "V-RPP-APN｜Verb, perfect, passive, Participle, accusative, plural, neuter",
    GN: "G2928",
    gloss: "to hide",
  },
  κεκρυμμένον: {
    parse:
      "V-RPP-NSN｜Verb, perfect, passive, Participle, nominative, singular, neuter",
    GN: "G2928",
    gloss: "to hide",
  },
  κεκρυμμένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G2928",
    gloss: "to hide",
  },
  κεκρυμμένου: {
    parse:
      "V-RPP-GSN｜Verb, perfect, passive, Participle, genitive, singular, neuter",
    GN: "G2928",
    gloss: "to hide",
  },
  κεκρυμμένῳ: {
    parse:
      "V-RPP-DSM｜Verb, perfect, passive, Participle, dative, singular, masculine",
    GN: "G2928",
    gloss: "to hide",
  },
  κέκρυπται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G2928",
    gloss: "to hide",
  },
  κρυβῆναι: {
    parse: "V-2APN｜Verb, 2nd aorist, passive, Infinitive",
    GN: "G2928",
    gloss: "to hide",
  },
  κρύψατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G2928",
    gloss: "to hide",
  },
  κρυσταλλίζοντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G2929",
    gloss: "be clear",
  },
  κρύσταλλον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2930",
    gloss: "crystal",
  },
  κρυστάλλῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G2930",
    gloss: "crystal",
  },
  κρυφῇ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2931",
    gloss: "in secret",
  },
  ἐκτησάμην: {
    parse:
      "V-ADI-1S｜Verb, aorist, Middle deponent, indicative, first, singular",
    GN: "G2932",
    gloss: "to posses",
  },
  ἐκτήσατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G2932",
    gloss: "to posses",
  },
  κτᾶσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G2932",
    gloss: "to posses",
  },
  κτήσασθε: {
    parse:
      "V-ADM-2P｜Verb, aorist, Middle deponent, imperative, second, plural",
    GN: "G2932",
    gloss: "to posses",
  },
  κτήσησθε: {
    parse:
      "V-ADS-2P｜Verb, aorist, Middle deponent, subjunctive, second, plural",
    GN: "G2932",
    gloss: "to posses",
  },
  κτῶμαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G2932",
    gloss: "to posses",
  },
  κτῆμα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G2933",
    gloss: "possession",
  },
  κτήματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G2933",
    gloss: "possession",
  },
  κτήνη: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G2934",
    gloss: "animal",
  },
  κτῆνος: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G2934",
    gloss: "animal",
  },
  κτηνῶν: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G2934",
    gloss: "animal",
  },
  κτήτορες: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G2935",
    gloss: "owner",
  },
  ἔκτισας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G2936",
    gloss: "to create",
  },
  ἔκτισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2936",
    gloss: "to create",
  },
  ἐκτίσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2936",
    gloss: "to create",
  },
  ἐκτίσθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G2936",
    gloss: "to create",
  },
  ἔκτισται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G2936",
    gloss: "to create",
  },
  κτίσαντα: {
    parse:
      "V-AAP-ASM｜Verb, aorist, active, Participle, accusative, singular, masculine",
    GN: "G2936",
    gloss: "to create",
  },
  κτίσαντι: {
    parse:
      "V-AAP-DSM｜Verb, aorist, active, Participle, dative, singular, masculine",
    GN: "G2936",
    gloss: "to create",
  },
  κτίσαντος: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G2936",
    gloss: "to create",
  },
  κτίσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G2936",
    gloss: "to create",
  },
  κτίσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G2936",
    gloss: "to create",
  },
  κτισθέντα: {
    parse:
      "V-APP-ASM｜Verb, aorist, passive, Participle, accusative, singular, masculine",
    GN: "G2936",
    gloss: "to create",
  },
  κτισθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G2936",
    gloss: "to create",
  },
  κτίσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2937",
    gloss: "creation",
  },
  κτίσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2937",
    gloss: "creation",
  },
  κτίσις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2937",
    gloss: "creation",
  },
  κτίσμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2938",
    gloss: "creature",
  },
  κτισμάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G2938",
    gloss: "creature",
  },
  κτίστῃ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G2939",
    gloss: "creator",
  },
  κυβείᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2940",
    gloss: "cunning",
  },
  κυβερνήσεις: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G2941",
    gloss: "administration",
  },
  κυβερνήτῃ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G2942",
    gloss: "captain",
  },
  κυβερνήτης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2942",
    gloss: "captain",
  },
  κυκλόθεν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2943",
    gloss: "around",
  },
  ἐκύκλωσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2944",
    gloss: "to surround",
  },
  κυκλουμένην: {
    parse:
      "V-PPP-ASF｜Verb, present, passive, Participle, accusative, singular, feminine",
    GN: "G2944",
    gloss: "to surround",
  },
  κυκλωθέντα: {
    parse:
      "V-APP-NPN｜Verb, aorist, passive, Participle, nominative, plural, neuter",
    GN: "G2944",
    gloss: "to surround",
  },
  κυκλωσάντων: {
    parse:
      "V-AAP-GPM｜Verb, aorist, active, Participle, genitive, plural, masculine",
    GN: "G2944",
    gloss: "to surround",
  },
  κύκλῳ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2945",
    gloss: "surrounding",
  },
  κυλισμὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2946",
    gloss: "wallowing",
  },
  ἐκυλίετο: {
    parse:
      "V-IEI-3S｜Verb, imperfect, Either middle or passive, indicative, third, singular",
    GN: "G2947",
    gloss: "to wallow",
  },
  κυλλὸν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G2948",
    gloss: "crippled",
  },
  κυλλούς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2948",
    gloss: "crippled",
  },
  κυλλοὺς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2948",
    gloss: "crippled",
  },
  κύματα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G2949",
    gloss: "a wave",
  },
  κυμάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G2949",
    gloss: "a wave",
  },
  κύμβαλον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G2950",
    gloss: "cymbal",
  },
  κύμινον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G2951",
    gloss: "cumin",
  },
  κυνάρια: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G2952",
    gloss: "little dog",
  },
  κυναρίοις: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G2952",
    gloss: "little dog",
  },
  Κύπριοι: {
    parse: "N-NPM-LG｜Noun, nominative, plural, masculine, Location Gentilic",
    GN: "G2953",
    gloss: "Cyprus",
  },
  Κύπριος: {
    parse: "N-NSM-LG｜Noun, nominative, singular, masculine, Location Gentilic",
    GN: "G2953",
    gloss: "Cyprus",
  },
  Κυπρίῳ: {
    parse: "N-DSM-LG｜Noun, dative, singular, masculine, Location Gentilic",
    GN: "G2953",
    gloss: "Cyprus",
  },
  Κύπρον: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G2954",
    gloss: "Cyprus",
  },
  Κύπρου: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G2954",
    gloss: "Cyprus",
  },
  κύψας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G2955",
    gloss: "to stoop/bend down",
  },
  Κυρηναῖοι: {
    parse: "N-NPM-LG｜Noun, nominative, plural, masculine, Location Gentilic",
    GN: "G2956",
    gloss: "Cyrene",
  },
  Κυρηναῖον: {
    parse: "N-ASM-LG｜Noun, accusative, singular, masculine, Location Gentilic",
    GN: "G2956",
    gloss: "Cyrene",
  },
  Κυρηναῖος: {
    parse: "N-NSM-LG｜Noun, nominative, singular, masculine, Location Gentilic",
    GN: "G2956",
    gloss: "Cyrene",
  },
  Κυρηναίων: {
    parse: "N-GPM-LG｜Noun, genitive, plural, masculine, Location Gentilic",
    GN: "G2956",
    gloss: "Cyrene",
  },
  Κυρήνην: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G2957",
    gloss: "Cyrene",
  },
  Κυρηνίου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2958",
    gloss: "Quirinius",
  },
  κυρίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G2959",
    gloss: "lady",
  },
  κυρία: {
    parse: "N-VSF｜Noun, Vocative or nominative, singular, feminine",
    GN: "G2959",
    gloss: "lady",
  },
  κυριακῇ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G2960",
    gloss: "the Lord’s",
  },
  κυριακὸν: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G2960",
    gloss: "the Lord’s",
  },
  κυριεύει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2961",
    gloss: "to lord over",
  },
  κυριεύομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G2961",
    gloss: "to lord over",
  },
  κυριευόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G2961",
    gloss: "to lord over",
  },
  κυριεύουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2961",
    gloss: "to lord over",
  },
  κυριεύσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2961",
    gloss: "to lord over",
  },
  κυριεύσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G2961",
    gloss: "to lord over",
  },
  κύριε: {
    // or nominative
    parse: "N-VSM｜Noun, Vocative, singular, masculine",
    GN: "G2962",
    gloss: "master",
  },
  Κύριε: {
    // or nominative
    parse: "N-VSM｜Noun, Vocative, singular, masculine",
    GN: "G2962",
    gloss: "master",
  },
  Κύριέ: {
    // or nominative
    parse: "N-VSM｜Noun, Vocative, singular, masculine",
    GN: "G2962",
    gloss: "master",
  },
  κύριοι: {
    parse: "N-VPM｜Noun, Vocative or nominative, plural, masculine",
    GN: "G2962",
    gloss: "master",
  },
  Κύριοι: {
    parse: "N-VPM｜Noun, Vocative or nominative, plural, masculine",
    GN: "G2962",
    gloss: "master",
  },
  κυρίοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G2962",
    gloss: "master",
  },
  κύριον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2962",
    gloss: "master",
  },
  Κύριον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2962",
    gloss: "master",
  },
  κύριόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2962",
    gloss: "master",
  },
  κύριος: {
    parse: "N-VSM｜Noun, Vocative or nominative, singular, masculine",
    GN: "G2962",
    gloss: "master",
  },
  Κύριος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2962",
    gloss: "master",
  },
  κύριός: {
    parse: "N-VSM｜Noun, Vocative or nominative, singular, masculine",
    GN: "G2962",
    gloss: "master",
  },
  Κύριός: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2962",
    gloss: "master",
  },
  κυρίου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2962",
    gloss: "master",
  },
  Κυρίου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2962",
    gloss: "master",
  },
  κυρίῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G2962",
    gloss: "master",
  },
  κυρίων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G2962",
    gloss: "master",
  },
  κυριότητα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2963",
    gloss: "lordship",
  },
  κυριότητες: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G2963",
    gloss: "lordship",
  },
  κυριότητος: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2963",
    gloss: "lordship",
  },
  κεκυρωμένην: {
    parse:
      "V-RPP-ASF｜Verb, perfect, passive, Participle, accusative, singular, feminine",
    GN: "G2964",
    gloss: "to affirm",
  },
  κυρῶσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2964",
    gloss: "to affirm",
  },
  κύνας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G2965",
    gloss: "dog",
  },
  κύνες: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G2965",
    gloss: "dog",
  },
  κυσίν: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G2965",
    gloss: "dog",
  },
  Κύων: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2965",
    gloss: "dog",
  },
  κῶλα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G2966",
    gloss: "corpse",
  },
  ἐκωλύθην: {
    parse: "V-API-1S｜Verb, aorist, passive, indicative, first, singular",
    GN: "G2967",
    gloss: "to prevent",
  },
  ἐκωλύομεν: {
    parse: "V-IAI-1P｜Verb, imperfect, active, indicative, first, plural",
    GN: "G2967",
    gloss: "to prevent",
  },
  ἐκωλύσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G2967",
    gloss: "to prevent",
  },
  ἐκώλυσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2967",
    gloss: "to prevent",
  },
  κωλύει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2967",
    gloss: "to prevent",
  },
  κωλύειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2967",
    gloss: "to prevent",
  },
  κωλύεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G2967",
    gloss: "to prevent",
  },
  κωλύετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G2967",
    gloss: "to prevent",
  },
  κωλυθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G2967",
    gloss: "to prevent",
  },
  κωλύοντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G2967",
    gloss: "to prevent",
  },
  κωλυόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G2967",
    gloss: "to prevent",
  },
  κωλῦσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2967",
    gloss: "to prevent",
  },
  κωλῦσαί: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2967",
    gloss: "to prevent",
  },
  κωλύσῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G2967",
    gloss: "to prevent",
  },
  κώμας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G2968",
    gloss: "village",
  },
  κώμην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2968",
    gloss: "village",
  },
  κώμης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2968",
    gloss: "village",
  },
  κωμοπόλεις: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G2969",
    gloss: "village",
  },
  κῶμοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G2970",
    gloss: "orgy",
  },
  κώμοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G2970",
    gloss: "orgy",
  },
  κώνωπα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2971",
    gloss: "gnat",
  },
  Κῶ: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G2972",
    gloss: "Cos",
  },
  Κωσὰμ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2973",
    gloss: "Cosam",
  },
  κωφοὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G2974",
    gloss: "deaf/mute",
  },
  κωφὸν: {
    parse: "A-VSN｜Adjective, Vocative or nominative, singular, neuter",
    GN: "G2974",
    gloss: "deaf/mute",
  },
  κωφόν: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G2974",
    gloss: "deaf/mute",
  },
  κωφός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2974",
    gloss: "deaf/mute",
  },
  κωφούς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2974",
    gloss: "deaf/mute",
  },
  κωφοὺς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G2974",
    gloss: "deaf/mute",
  },
  ἔλαχε: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G2975",
    gloss: "to choose by lot",
  },
  ἔλαχεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G2975",
    gloss: "to choose by lot",
  },
  λαχοῦσιν: {
    parse:
      "V-2AAP-DPM｜Verb, 2nd aorist, active, Participle, dative, plural, masculine",
    GN: "G2975",
    gloss: "to choose by lot",
  },
  λάχωμεν: {
    parse: "V-2AAS-1P｜Verb, 2nd aorist, active, subjunctive, first, plural",
    GN: "G2975",
    gloss: "to choose by lot",
  },
  Λάζαρε: {
    parse: "N-VSM-P｜Noun, Vocative or nominative, singular, masculine, Person",
    GN: "G2976",
    gloss: "[father of Lazarus]",
  },
  Λάζαρον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G2976",
    gloss: "[father of Lazarus]",
  },
  Λάζαρος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G2976",
    gloss: "[father of Lazarus]",
  },
  λάθρᾳ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2977",
    gloss: "quietly",
  },
  λαίλαπος: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2978",
    gloss: "storm",
  },
  λαῖλαψ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2978",
    gloss: "storm",
  },
  λακτίζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2979",
    gloss: "to kick",
  },
  ἐλάλει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G2980",
    gloss: "to speak",
  },
  ἐλαλήθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G2980",
    gloss: "to speak",
  },
  ἐλάλησα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G2980",
    gloss: "to speak",
  },
  ἐλαλήσαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G2980",
    gloss: "to speak",
  },
  ἐλάλησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G2980",
    gloss: "to speak",
  },
  ἐλαλήσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G2980",
    gloss: "to speak",
  },
  ἐλάλησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2980",
    gloss: "to speak",
  },
  ἐλάλησέν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2980",
    gloss: "to speak",
  },
  ἐλαλοῦμεν: {
    parse: "V-IAI-1P｜Verb, imperfect, active, indicative, first, plural",
    GN: "G2980",
    gloss: "to speak",
  },
  ἐλάλουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2980",
    gloss: "to speak",
  },
  λάλει: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλεῖς: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλεῖσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλείτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλείτωσαν: {
    parse: "V-PAM-3P｜Verb, present, active, imperative, third, plural",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλῇ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G2980",
    gloss: "to speak",
  },
  λαληθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G2980",
    gloss: "to speak",
  },
  λαληθείσης: {
    parse:
      "V-APP-GSF｜Verb, aorist, passive, Participle, genitive, singular, feminine",
    GN: "G2980",
    gloss: "to speak",
  },
  λαληθέντος: {
    parse:
      "V-APP-GSN｜Verb, aorist, passive, Participle, genitive, singular, neuter",
    GN: "G2980",
    gloss: "to speak",
  },
  λαληθέντων: {
    parse:
      "V-APP-GPN｜Verb, aorist, passive, Participle, genitive, plural, neuter",
    GN: "G2980",
    gloss: "to speak",
  },
  λαληθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G2980",
    gloss: "to speak",
  },
  λαληθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G2980",
    gloss: "to speak",
  },
  λαληθήσεταί: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G2980",
    gloss: "to speak",
  },
  λαληθησομένων: {
    parse:
      "V-FPP-GPN｜Verb, future, passive, Participle, genitive, plural, neuter",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλῆσαί: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλήσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλήσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλήσομεν: {
    parse: "V-FAI-1P｜Verb, future, active, indicative, first, plural",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλήσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλοῦμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλουμένη: {
    parse:
      "V-PPP-NSF｜Verb, present, passive, Participle, nominative, singular, feminine",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλουμένοις: {
    parse:
      "V-PPP-DPN｜Verb, present, passive, Participle, dative, plural, neuter",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλούμενον: {
    parse:
      "V-PPP-NSN｜Verb, present, passive, Participle, nominative, singular, neuter",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλοῦν: {
    parse:
      "V-PAP-NSN｜Verb, present, active, Participle, nominative, singular, neuter",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλοῦντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλοῦντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλοῦντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλοῦντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλοῦντός: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλούντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G2980",
    gloss: "to speak",
  },
  Λαλούντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλοῦσαι: {
    parse:
      "V-PAP-NPF｜Verb, present, active, Participle, nominative, plural, feminine",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλοῦσαν: {
    parse:
      "V-PAP-ASF｜Verb, present, active, Participle, accusative, singular, feminine",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλούσης: {
    parse:
      "V-PAP-GSF｜Verb, present, active, Participle, genitive, singular, feminine",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλοῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλῶ: {
    parse: "V-PAS-1S｜Verb, present, active, subjunctive, first, singular",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλῶσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G2980",
    gloss: "to speak",
  },
  λελάληκα: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G2980",
    gloss: "to speak",
  },
  λελάληκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G2980",
    gloss: "to speak",
  },
  λελαλημένοις: {
    parse:
      "V-RPP-DPN｜Verb, perfect, passive, Participle, dative, plural, neuter",
    GN: "G2980",
    gloss: "to speak",
  },
  λελάληταί: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G2980",
    gloss: "to speak",
  },
  λαλιά: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2981",
    gloss: "speech",
  },
  λαλιὰν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2981",
    gloss: "speech",
  },
  λεμὰ: {
    parse: "ARAM｜Aramaic transliterated word (indeclinable)",
    GN: "G2982",
    gloss: "why?",
  },
  εἴληφα: {
    parse: "V-2RAI-1S｜Verb, 2nd perfect, active, indicative, first, singular",
    GN: "G2983",
    gloss: "to take",
  },
  εἴληφας: {
    parse: "V-2RAI-2S｜Verb, 2nd perfect, active, indicative, second, singular",
    GN: "G2983",
    gloss: "to take",
  },
  εἴληφεν: {
    parse: "V-2RAI-3S｜Verb, 2nd perfect, active, indicative, third, singular",
    GN: "G2983",
    gloss: "to take",
  },
  εἰληφὼς: {
    parse:
      "V-2RAP-NSM｜Verb, 2nd perfect, active, Participle, nominative, singular, masculine",
    GN: "G2983",
    gloss: "to take",
  },
  ἔλαβε: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G2983",
    gloss: "to take",
  },
  ἔλαβεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G2983",
    gloss: "to take",
  },
  ἔλαβες: {
    parse: "V-2AAI-2S｜Verb, 2nd aorist, active, indicative, second, singular",
    GN: "G2983",
    gloss: "to take",
  },
  ἐλάβετε: {
    parse: "V-2AAI-2P｜Verb, 2nd aorist, active, indicative, second, plural",
    GN: "G2983",
    gloss: "to take",
  },
  ἐλάβομεν: {
    parse: "V-2AAI-1P｜Verb, 2nd aorist, active, indicative, first, plural",
    GN: "G2983",
    gloss: "to take",
  },
  ἔλαβον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G2983",
    gloss: "to take",
  },
  ἐλάμβανον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G2983",
    gloss: "to take",
  },
  λάβε: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G2983",
    gloss: "to take",
  },
  Λάβε: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G2983",
    gloss: "to take",
  },
  λαβεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G2983",
    gloss: "to take",
  },
  λάβετε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G2983",
    gloss: "to take",
  },
  Λάβετε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G2983",
    gloss: "to take",
  },
  λαβέτω: {
    parse: "V-2AAM-3S｜Verb, 2nd aorist, active, imperative, third, singular",
    GN: "G2983",
    gloss: "to take",
  },
  λάβῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G2983",
    gloss: "to take",
  },
  λάβητε: {
    parse: "V-2AAS-2P｜Verb, 2nd aorist, active, subjunctive, second, plural",
    GN: "G2983",
    gloss: "to take",
  },
  λάβοι: {
    parse: "V-2AAO-3S｜Verb, 2nd aorist, active, Optative, third, singular",
    GN: "G2983",
    gloss: "to take",
  },
  λαβόντα: {
    parse:
      "V-2AAP-ASM｜Verb, 2nd aorist, active, Participle, accusative, singular, masculine",
    GN: "G2983",
    gloss: "to take",
  },
  λαβόντας: {
    parse:
      "V-2AAP-APM｜Verb, 2nd aorist, active, Participle, accusative, plural, masculine",
    GN: "G2983",
    gloss: "to take",
  },
  λαβόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G2983",
    gloss: "to take",
  },
  λαβοῦσα: {
    parse:
      "V-2AAP-NSF｜Verb, 2nd aorist, active, Participle, nominative, singular, feminine",
    GN: "G2983",
    gloss: "to take",
  },
  λαβοῦσαι: {
    parse:
      "V-2AAP-NPF｜Verb, 2nd aorist, active, Participle, nominative, plural, feminine",
    GN: "G2983",
    gloss: "to take",
  },
  λάβω: {
    parse: "V-2AAS-1S｜Verb, 2nd aorist, active, subjunctive, first, singular",
    GN: "G2983",
    gloss: "to take",
  },
  λάβωμεν: {
    parse: "V-2AAS-1P｜Verb, 2nd aorist, active, subjunctive, first, plural",
    GN: "G2983",
    gloss: "to take",
  },
  λαβών: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G2983",
    gloss: "to take",
  },
  λαβὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G2983",
    gloss: "to take",
  },
  λάβωσιν: {
    parse: "V-2AAS-3P｜Verb, 2nd aorist, active, subjunctive, third, plural",
    GN: "G2983",
    gloss: "to take",
  },
  λαμβάνει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2983",
    gloss: "to take",
  },
  λαμβάνειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2983",
    gloss: "to take",
  },
  λαμβάνεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G2983",
    gloss: "to take",
  },
  λαμβάνετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G2983",
    gloss: "to take",
  },
  λαμβάνετέ: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G2983",
    gloss: "to take",
  },
  λαμβάνῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G2983",
    gloss: "to take",
  },
  λαμβάνομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G2983",
    gloss: "to take",
  },
  λαμβανόμενον: {
    parse:
      "V-PPP-NSN｜Verb, present, passive, Participle, nominative, singular, neuter",
    GN: "G2983",
    gloss: "to take",
  },
  λαμβανόμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G2983",
    gloss: "to take",
  },
  λαμβάνοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G2983",
    gloss: "to take",
  },
  λαμβάνουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G2983",
    gloss: "to take",
  },
  λαμβάνω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G2983",
    gloss: "to take",
  },
  λαμβάνων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G2983",
    gloss: "to take",
  },
  λήμψεσθε: {
    parse:
      "V-FDI-2P｜Verb, future, Middle deponent, indicative, second, plural",
    GN: "G2983",
    gloss: "to take",
  },
  λήμψεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G2983",
    gloss: "to take",
  },
  λήμψεταί: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G2983",
    gloss: "to take",
  },
  λημψόμεθα: {
    parse: "V-FDI-1P｜Verb, future, Middle deponent, indicative, first, plural",
    GN: "G2983",
    gloss: "to take",
  },
  λήμψονται: {
    parse: "V-FDI-3P｜Verb, future, Middle deponent, indicative, third, plural",
    GN: "G2983",
    gloss: "to take",
  },
  Λάμεχ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G2984",
    gloss: "Lamech",
  },
  λαμπάδας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G2985",
    gloss: "window",
  },
  λαμπάδες: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G2985",
    gloss: "window",
  },
  λαμπάδων: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G2985",
    gloss: "window",
  },
  λαμπάς: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2985",
    gloss: "window",
  },
  λαμπρᾷ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G2986",
    gloss: "shining",
  },
  λαμπρὰ: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G2986",
    gloss: "shining",
  },
  λαμπρὰν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G2986",
    gloss: "shining",
  },
  λαμπρὸν: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G2986",
    gloss: "shining",
  },
  λαμπρός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G2986",
    gloss: "shining",
  },
  λαμπρότητα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2987",
    gloss: "brightness",
  },
  λαμπρῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G2988",
    gloss: "magnificently",
  },
  ἔλαμψεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2989",
    gloss: "to shine",
  },
  λάμπει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2989",
    gloss: "to shine",
  },
  λαμψάτω: {
    parse: "V-AAM-3S｜Verb, aorist, active, imperative, third, singular",
    GN: "G2989",
    gloss: "to shine",
  },
  λάμψει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G2989",
    gloss: "to shine",
  },
  ἔλαθεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G2990",
    gloss: "be hidden",
  },
  ἔλαθόν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G2990",
    gloss: "be hidden",
  },
  λαθεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G2990",
    gloss: "be hidden",
  },
  λανθάνει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G2990",
    gloss: "be hidden",
  },
  λανθάνειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G2990",
    gloss: "be hidden",
  },
  λανθανέτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G2990",
    gloss: "be hidden",
  },
  λαξευτῷ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G2991",
    gloss: "engraved",
  },
  λαοί: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G2992",
    gloss: "a people",
  },
  λαοὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G2992",
    gloss: "a people",
  },
  λαοῖς: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G2992",
    gloss: "a people",
  },
  λαόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2992",
    gloss: "a people",
  },
  λαὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G2992",
    gloss: "a people",
  },
  λαός: {
    parse: "N-VSM｜Noun, Vocative or nominative, singular, masculine",
    GN: "G2992",
    gloss: "a people",
  },
  λαὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2992",
    gloss: "a people",
  },
  λαοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G2992",
    gloss: "a people",
  },
  λαῷ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G2992",
    gloss: "a people",
  },
  λαῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G2992",
    gloss: "a people",
  },
  Λαοδικείᾳ: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G2993",
    gloss: "Laodicea",
  },
  Λαοδίκειαν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G2993",
    gloss: "Laodicea",
  },
  Λαοδικείας: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G2993",
    gloss: "Laodicea",
  },
  Λαοδικέων: {
    parse: "N-GPM-LG｜Noun, genitive, plural, masculine, Location Gentilic",
    GN: "G2994",
    gloss: "Laodicean",
  },
  λάρυγξ: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G2995",
    gloss: "throat",
  },
  Λασαία: {
    parse: "N-NSF-L｜Noun, nominative, singular, feminine, Location",
    GN: "G2996",
    gloss: "Lasea",
  },
  ἐλάκησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2997",
    gloss: "to burst",
  },
  ἐλατόμησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G2998",
    gloss: "to hew",
  },
  λελατομημένον: {
    parse:
      "V-RPP-NSN｜Verb, perfect, passive, Participle, nominative, singular, neuter",
    GN: "G2998",
    gloss: "to hew",
  },
  λατρεία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G2999",
    gloss: "ministry",
  },
  λατρείαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G2999",
    gloss: "ministry",
  },
  λατρείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G2999",
    gloss: "ministry",
  },
  ἐλάτρευσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G3000",
    gloss: "to minister",
  },
  λατρεύειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3000",
    gloss: "to minister",
  },
  λατρεῦον: {
    parse:
      "V-PAP-NSN｜Verb, present, active, Participle, nominative, singular, neuter",
    GN: "G3000",
    gloss: "to minister",
  },
  λατρεύοντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G3000",
    gloss: "to minister",
  },
  λατρεύοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G3000",
    gloss: "to minister",
  },
  λατρεύοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G3000",
    gloss: "to minister",
  },
  λατρεύουσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G3000",
    gloss: "to minister",
  },
  λατρεύουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G3000",
    gloss: "to minister",
  },
  λατρεύσεις: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G3000",
    gloss: "to minister",
  },
  λατρεύσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G3000",
    gloss: "to minister",
  },
  λατρεύσουσίν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G3000",
    gloss: "to minister",
  },
  λατρεύω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G3000",
    gloss: "to minister",
  },
  λατρεύωμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G3000",
    gloss: "to minister",
  },
  λάχανα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G3001",
    gloss: "plant",
  },
  λάχανον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G3001",
    gloss: "plant",
  },
  λαχάνων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G3001",
    gloss: "plant",
  },
  Λεγιών: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3003",
    gloss: "Legion",
  },
  Λεγιὼν: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3003",
    gloss: "Legion",
  },
  λεγιῶνα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3003",
    gloss: "Legion",
  },
  λεγιῶνας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G3003",
    gloss: "Legion",
  },
  ἔλεγεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G3004",
    gloss: "call",
  },
  Ἔλεγεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G3004",
    gloss: "call",
  },
  ἐλέγετε: {
    parse: "V-IAI-2P｜Verb, imperfect, active, indicative, second, plural",
    GN: "G3004",
    gloss: "call",
  },
  ἔλεγον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G3004",
    gloss: "call",
  },
  Ἔλεγον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G3004",
    gloss: "call",
  },
  Λέγε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G3004",
    gloss: "call",
  },
  λέγει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3004",
    gloss: "call",
  },
  Λέγει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3004",
    gloss: "call",
  },
  λέγειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3004",
    gloss: "call",
  },
  λέγεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G3004",
    gloss: "call",
  },
  λέγεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G3004",
    gloss: "call",
  },
  λέγεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G3004",
    gloss: "call",
  },
  λέγετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G3004",
    gloss: "call",
  },
  Λέγετέ: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G3004",
    gloss: "call",
  },
  λεγέτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G3004",
    gloss: "call",
  },
  λέγῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G3004",
    gloss: "call",
  },
  λέγητε: {
    parse: "V-PAS-2P｜Verb, present, active, subjunctive, second, plural",
    GN: "G3004",
    gloss: "call",
  },
  λέγομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G3004",
    gloss: "call",
  },
  λεγόμενα: {
    parse:
      "V-PPP-APN｜Verb, present, passive, Participle, accusative, plural, neuter",
    GN: "G3004",
    gloss: "call",
  },
  λεγομένη: {
    parse:
      "V-PPP-NSF｜Verb, present, passive, Participle, nominative, singular, feminine",
    GN: "G3004",
    gloss: "call",
  },
  λεγομένην: {
    parse:
      "V-PPP-ASF｜Verb, present, passive, Participle, accusative, singular, feminine",
    GN: "G3004",
    gloss: "call",
  },
  λεγομένης: {
    parse:
      "V-PPP-GSF｜Verb, present, passive, Participle, genitive, singular, feminine",
    GN: "G3004",
    gloss: "call",
  },
  λεγόμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G3004",
    gloss: "call",
  },
  λεγομένοις: {
    parse:
      "V-PPP-DPN｜Verb, present, passive, Participle, dative, plural, neuter",
    GN: "G3004",
    gloss: "call",
  },
  λεγόμενον: {
    parse:
      "V-PPP-ASN｜Verb, present, passive, Participle, accusative, singular, neuter",
    GN: "G3004",
    gloss: "call",
  },
  λεγόμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G3004",
    gloss: "call",
  },
  λεγομένου: {
    parse:
      "V-PPP-GSM｜Verb, present, passive, Participle, genitive, singular, masculine",
    GN: "G3004",
    gloss: "call",
  },
  λέγον: {
    parse:
      "V-PAP-NSN｜Verb, present, active, Participle, nominative, singular, neuter",
    GN: "G3004",
    gloss: "call",
  },
  λέγοντα: {
    parse:
      "V-PAP-NPN｜Verb, present, active, Participle, nominative, plural, neuter",
    GN: "G3004",
    gloss: "call",
  },
  λέγοντά: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G3004",
    gloss: "call",
  },
  λέγοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G3004",
    gloss: "call",
  },
  λέγοντες: {
    parse:
      "V-PAP-VPM｜Verb, present, active, Participle, Vocative or nominative, plural, masculine",
    GN: "G3004",
    gloss: "call",
  },
  λέγοντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G3004",
    gloss: "call",
  },
  λέγοντος: {
    parse:
      "V-PAP-GSN｜Verb, present, active, Participle, genitive, singular, neuter",
    GN: "G3004",
    gloss: "call",
  },
  λεγόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G3004",
    gloss: "call",
  },
  λέγουσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G3004",
    gloss: "call",
  },
  λέγουσαι: {
    parse:
      "V-PAP-NPF｜Verb, present, active, Participle, nominative, plural, feminine",
    GN: "G3004",
    gloss: "call",
  },
  λέγουσαν: {
    parse:
      "V-PAP-ASF｜Verb, present, active, Participle, accusative, singular, feminine",
    GN: "G3004",
    gloss: "call",
  },
  λεγούσης: {
    parse:
      "V-PAP-GSF｜Verb, present, active, Participle, genitive, singular, feminine",
    GN: "G3004",
    gloss: "call",
  },
  λέγουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G3004",
    gloss: "call",
  },
  Λέγουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G3004",
    gloss: "call",
  },
  λέγουσίν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G3004",
    gloss: "call",
  },
  λέγω: {
    parse: "V-PAS-1S｜Verb, present, active, subjunctive, first, singular",
    GN: "G3004",
    gloss: "call/say",
  },
  Λέγω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G3004",
    gloss: "call/say",
  },
  λέγων: {
    parse:
      "V-PAP-VSM｜Verb, present, active, Participle, Vocative or nominative, singular, masculine",
    GN: "G3004",
    gloss: "call/say",
  },
  λέγωσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G3004",
    gloss: "call/say",
  },
  λέγωμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G3004",
    gloss: "call/say",
  },
  λεῖμμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G3005",
    gloss: "remnant",
  },
  λείας: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G3006",
    gloss: "smooth",
  },
  λείπει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3007",
    gloss: "to lack",
  },
  λείπεται: {
    parse: "V-PMI-3S｜Verb, present, middle, indicative, third, singular",
    GN: "G3007",
    gloss: "to lack",
  },
  λείπῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G3007",
    gloss: "to lack",
  },
  λειπόμενοι: {
    parse:
      "V-PMP-NPM｜Verb, present, middle, Participle, nominative, plural, masculine",
    GN: "G3007",
    gloss: "to lack",
  },
  λείποντα: {
    parse:
      "V-PAP-APN｜Verb, present, active, Participle, accusative, plural, neuter",
    GN: "G3007",
    gloss: "to lack",
  },
  λειτουργῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G3008",
    gloss: "to minister",
  },
  λειτουργούντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G3008",
    gloss: "to minister",
  },
  λειτουργῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3008",
    gloss: "to minister",
  },
  λειτουργίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3009",
    gloss: "ministry",
  },
  λειτουργίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3009",
    gloss: "ministry",
  },
  λειτουργικὰ: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G3010",
    gloss: "ministering",
  },
  λειτουργοὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3011",
    gloss: "minister",
  },
  λειτουργὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3011",
    gloss: "minister",
  },
  λειτουργὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3011",
    gloss: "minister",
  },
  λειτουργοὺς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3011",
    gloss: "minister",
  },
  λέντιον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G3012",
    gloss: "towel",
  },
  λεντίῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G3012",
    gloss: "towel",
  },
  λεπίδες: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G3013",
    gloss: "scale",
  },
  λέπρα: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3014",
    gloss: "leprosy",
  },
  λέπρας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3014",
    gloss: "leprosy",
  },
  λεπροὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3015",
    gloss: "leprous",
  },
  λεπρὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3015",
    gloss: "leprous",
  },
  λεπροῦ: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G3015",
    gloss: "leprous",
  },
  λεπροὺς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G3015",
    gloss: "leprous",
  },
  λεπτὰ: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G3016",
    gloss: "coin",
  },
  λεπτὸν: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G3016",
    gloss: "coin",
  },
  Λευὶ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G3017",
    gloss: "Levi",
  },
  Λευὶν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G3017",
    gloss: "Levi",
  },
  Λευὶς: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G3017",
    gloss: "Levi",
  },
  Λευίτας: {
    parse: "N-APM-PG｜Noun, accusative, plural, masculine, Person Gentilic",
    GN: "G3019",
    gloss: "Levite",
  },
  Λευίτης: {
    parse: "N-NSM-PG｜Noun, nominative, singular, masculine, Person Gentilic",
    GN: "G3019",
    gloss: "Levite",
  },
  Λευιτικῆς: {
    parse: "A-GSF-PG｜Adjective, genitive, singular, feminine, Person Gentilic",
    GN: "G3020",
    gloss: "Levitical",
  },
  ἐλεύκαναν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G3021",
    gloss: "to bleach",
  },
  λευκᾶναι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G3021",
    gloss: "to bleach",
  },
  λευκὰ: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G3022",
    gloss: "white",
  },
  λευκαί: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G3022",
    gloss: "white",
  },
  λευκαὶ: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G3022",
    gloss: "white",
  },
  λευκαῖς: {
    parse: "A-DPF｜Adjective, dative, plural, feminine",
    GN: "G3022",
    gloss: "white",
  },
  λευκάς: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G3022",
    gloss: "white",
  },
  λευκὰς: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G3022",
    gloss: "white",
  },
  λευκή: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G3022",
    gloss: "white",
  },
  λευκήν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G3022",
    gloss: "white",
  },
  λευκὴν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G3022",
    gloss: "white",
  },
  λευκοῖς: {
    parse: "A-DPN｜Adjective, dative, plural, neuter",
    GN: "G3022",
    gloss: "white",
  },
  λευκὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G3022",
    gloss: "white",
  },
  λευκόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G3022",
    gloss: "white",
  },
  λευκός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3022",
    gloss: "white",
  },
  λευκὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3022",
    gloss: "white",
  },
  λέοντι: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3023",
    gloss: "lion",
  },
  λέοντος: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3023",
    gloss: "lion",
  },
  λεόντων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G3023",
    gloss: "lion",
  },
  λέων: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3023",
    gloss: "lion",
  },
  λήθην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3024",
    gloss: "forgetfulness",
  },
  ληνὸν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3025",
    gloss: "winepress",
  },
  ληνὸς: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3025",
    gloss: "winepress",
  },
  ληνοῦ: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3025",
    gloss: "winepress",
  },
  λῆρος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3026",
    gloss: "nonsense",
  },
  λῃσταί: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3027",
    gloss: "robber/rebel",
  },
  λῃσταὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3027",
    gloss: "robber/rebel",
  },
  λῃσταῖς: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G3027",
    gloss: "robber/rebel",
  },
  λῃστάς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3027",
    gloss: "robber/rebel",
  },
  λῃστὴν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3027",
    gloss: "robber/rebel",
  },
  λῃστής: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3027",
    gloss: "robber/rebel",
  },
  λῃστῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G3027",
    gloss: "robber/rebel",
  },
  λήμψεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3028",
    gloss: "receiving",
  },
  λίαν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3029",
    gloss: "greatly",
  },
  λίβανον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3030",
    gloss: "frankincense",
  },
  λιβανωτόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3031",
    gloss: "censer",
  },
  λιβανωτὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3031",
    gloss: "censer",
  },
  Λιβερτίνων: {
    parse: "N-GPM-T｜Noun, genitive, plural, masculine, Title",
    GN: "G3032",
    gloss: "Freedmen",
  },
  Λιβύης: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G3033",
    gloss: "Libya",
  },
  ἐλιθάσθην: {
    parse: "V-API-1S｜Verb, aorist, passive, indicative, first, singular",
    GN: "G3034",
    gloss: "to stone",
  },
  ἐλιθάσθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G3034",
    gloss: "to stone",
  },
  λιθάζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3034",
    gloss: "to stone",
  },
  λιθάζετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G3034",
    gloss: "to stone",
  },
  λιθάζομέν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G3034",
    gloss: "to stone",
  },
  λιθάσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G3034",
    gloss: "to stone",
  },
  λιθάσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G3034",
    gloss: "to stone",
  },
  λιθασθῶσιν: {
    parse: "V-APS-3P｜Verb, aorist, passive, subjunctive, third, plural",
    GN: "G3034",
    gloss: "to stone",
  },
  λιθάσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G3034",
    gloss: "to stone",
  },
  λίθινα: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G3035",
    gloss: "stone",
  },
  λίθιναι: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G3035",
    gloss: "stone",
  },
  λιθίναις: {
    parse: "A-DPF｜Adjective, dative, plural, feminine",
    GN: "G3035",
    gloss: "stone",
  },
  ἐλιθοβόλησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G3036",
    gloss: "to stone",
  },
  ἐλιθοβόλουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G3036",
    gloss: "to stone",
  },
  λιθοβοληθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G3036",
    gloss: "to stone",
  },
  λιθοβολῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G3036",
    gloss: "to stone",
  },
  λιθοβολοῦσα: {
    parse:
      "V-PAP-VSF｜Verb, present, active, Participle, Vocative or nominative, singular, feminine",
    GN: "G3036",
    gloss: "to stone",
  },
  λίθοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3037",
    gloss: "stone",
  },
  λίθοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G3037",
    gloss: "stone",
  },
  λίθον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3037",
    gloss: "stone",
  },
  Λίθον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3037",
    gloss: "stone",
  },
  λίθος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3037",
    gloss: "stone",
  },
  λίθου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3037",
    gloss: "stone",
  },
  λίθους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3037",
    gloss: "stone",
  },
  λίθῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3037",
    gloss: "stone",
  },
  λίθων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G3037",
    gloss: "stone",
  },
  Λιθόστρωτον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G3038",
    gloss: "The Stone Pavement",
  },
  λικμήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G3039",
    gloss: "to crush",
  },
  λιμένα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3040",
    gloss: " Havens",
  },
  Λιμένας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3040",
    gloss: " Havens",
  },
  λιμένος: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3040",
    gloss: " Havens",
  },
  λίμνῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3041",
    gloss: "lake",
  },
  λίμνη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3041",
    gloss: "lake",
  },
  λίμνην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3041",
    gloss: "lake",
  },
  λίμνης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3041",
    gloss: "lake",
  },
  λιμοί: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3042",
    gloss: "hunger",
  },
  λιμοὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3042",
    gloss: "hunger",
  },
  λιμὸν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3042",
    gloss: "hunger",
  },
  λιμὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3042",
    gloss: "hunger",
  },
  λιμός: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3042",
    gloss: "hunger",
  },
  λιμῷ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3042",
    gloss: "hunger",
  },
  λίνον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G3043",
    gloss: "linen/wick",
  },
  Λίνος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G3044",
    gloss: "Linus",
  },
  λιπαρὰ: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G3045",
    gloss: "rich",
  },
  λίτραν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3046",
    gloss: "pound",
  },
  λίτρας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G3046",
    gloss: "pound",
  },
  λίβα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3047",
    gloss: "southwest",
  },
  λογεῖαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G3048",
    gloss: "collection",
  },
  λογείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3048",
    gloss: "collection",
  },
  ἐλογιζόμην: {
    parse:
      "V-INI-1S｜Verb, imperfect, Middle or Passive deponent, indicative, first, singular",
    GN: "G3049",
    gloss: "to count",
  },
  ἐλογίσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G3049",
    gloss: "to count",
  },
  Ἐλογίσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G3049",
    gloss: "to count",
  },
  ἐλογίσθημεν: {
    parse: "V-API-1P｜Verb, aorist, passive, indicative, first, plural",
    GN: "G3049",
    gloss: "to count",
  },
  λογίζεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G3049",
    gloss: "to count",
  },
  λογίζεσθε: {
    parse:
      "V-PNM-2P｜Verb, present, Middle or Passive deponent, imperative, second, plural",
    GN: "G3049",
    gloss: "to count",
  },
  λογιζέσθω: {
    parse:
      "V-PNM-3S｜Verb, present, Middle or Passive deponent, imperative, third, singular",
    GN: "G3049",
    gloss: "to count",
  },
  λογίζεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G3049",
    gloss: "to count",
  },
  λογίζῃ: {
    parse:
      "V-PNI-2S｜Verb, present, Middle or Passive deponent, indicative, second, singular",
    GN: "G3049",
    gloss: "to count",
  },
  λογίζομαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G3049",
    gloss: "to count",
  },
  Λογίζομαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G3049",
    gloss: "to count",
  },
  λογιζόμεθα: {
    parse:
      "V-PNI-1P｜Verb, present, Middle or Passive deponent, indicative, first, plural",
    GN: "G3049",
    gloss: "to count",
  },
  λογιζόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G3049",
    gloss: "to count",
  },
  λογιζομένους: {
    parse:
      "V-PNP-APM｜Verb, present, Middle or Passive deponent, Participle, accusative, plural, masculine",
    GN: "G3049",
    gloss: "to count",
  },
  λογιζομένῳ: {
    parse:
      "V-PNP-DSM｜Verb, present, Middle or Passive deponent, Participle, dative, singular, masculine",
    GN: "G3049",
    gloss: "to count",
  },
  λογισάμενος: {
    parse:
      "V-ADP-NSM｜Verb, aorist, Middle deponent, Participle, nominative, singular, masculine",
    GN: "G3049",
    gloss: "to count",
  },
  λογίσασθαί: {
    parse: "V-AMN｜Verb, aorist, middle, Infinitive",
    GN: "G3049",
    gloss: "to count",
  },
  λογίσηται: {
    parse:
      "V-ADS-3S｜Verb, aorist, Middle deponent, subjunctive, third, singular",
    GN: "G3049",
    gloss: "to count",
  },
  λογισθείη: {
    parse:
      "V-AOO-3S｜Verb, aorist, Passive deponent, Optative, third, singular",
    GN: "G3049",
    gloss: "to count",
  },
  λογισθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G3049",
    gloss: "to count",
  },
  λογισθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G3049",
    gloss: "to count",
  },
  λογικὴν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G3050",
    gloss: "spiritual",
  },
  λογικὸν: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G3050",
    gloss: "spiritual",
  },
  λόγια: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G3051",
    gloss: "oracles",
  },
  λογίων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G3051",
    gloss: "oracles",
  },
  λόγιος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3052",
    gloss: "learned",
  },
  λογισμοὺς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3053",
    gloss: "thought",
  },
  λογισμῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G3053",
    gloss: "thought",
  },
  λογομαχεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3054",
    gloss: "to quarrel",
  },
  λογομαχίας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G3055",
    gloss: "quarrel",
  },
  λόγοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3056",
    gloss: "word",
  },
  λόγοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G3056",
    gloss: "word",
  },
  λόγον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3056",
    gloss: "word",
  },
  λόγος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3056",
    gloss: "word",
  },
  Λόγος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3056",
    gloss: "word",
  },
  λόγου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3056",
    gloss: "word",
  },
  λόγους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3056",
    gloss: "word",
  },
  λόγῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3056",
    gloss: "word",
  },
  λόγων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G3056",
    gloss: "word",
  },
  λόγχῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3057",
    gloss: "spear",
  },
  ἐλοιδόρησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G3058",
    gloss: "to revile",
  },
  λοιδορεῖς: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G3058",
    gloss: "to revile",
  },
  λοιδορούμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G3058",
    gloss: "to revile",
  },
  λοιδορούμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G3058",
    gloss: "to revile",
  },
  λοιδορίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3059",
    gloss: "reviling",
  },
  λοιδορίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3059",
    gloss: "reviling",
  },
  λοίδοροι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3060",
    gloss: "reviler",
  },
  λοίδορος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3060",
    gloss: "reviler",
  },
  λοιμοὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3061",
    gloss: "pestilence",
  },
  λοιμὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3061",
    gloss: "pestilence",
  },
  λοιπὰ: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G3062",
    gloss: "remaining",
  },
  λοιπαὶ: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G3062",
    gloss: "remaining",
  },
  λοιπὰς: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G3062",
    gloss: "remaining",
  },
  λοιποί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3062",
    gloss: "remaining",
  },
  λοιποὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3062",
    gloss: "remaining",
  },
  λοιποῖς: {
    parse: "A-DPN｜Adjective, dative, plural, neuter",
    GN: "G3062",
    gloss: "remaining",
  },
  λοιποὺς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G3062",
    gloss: "remaining",
  },
  λοιπῶν: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G3062",
    gloss: "remaining",
  },
  λοιπόν: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G3063",
    gloss: "henceforth",
  },
  Λοιπόν: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G3063",
    gloss: "henceforth",
  },
  λοιπὸν: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G3063",
    gloss: "henceforth",
  },
  Λοιπὸν: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G3063",
    gloss: "henceforth",
  },
  λοιποῦ: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G3064",
    gloss: "henceforth",
  },
  Λουκᾶς: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G3065",
    gloss: "Luke",
  },
  Λούκιος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G3066",
    gloss: "Lucius",
  },
  λουτροῦ: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G3067",
    gloss: "washing",
  },
  λουτρῷ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G3067",
    gloss: "washing",
  },
  ἔλουσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3068",
    gloss: "to wash",
  },
  λελουμένος: {
    parse:
      "V-RMP-NSM｜Verb, perfect, middle, Participle, nominative, singular, masculine",
    GN: "G3068",
    gloss: "to wash",
  },
  λελουσμένοι: {
    parse:
      "V-RMP-NPM｜Verb, perfect, middle, Participle, nominative, plural, masculine",
    GN: "G3068",
    gloss: "to wash",
  },
  λουσαμένη: {
    parse:
      "V-AMP-NSF｜Verb, aorist, middle, Participle, nominative, singular, feminine",
    GN: "G3068",
    gloss: "to wash",
  },
  λούσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G3068",
    gloss: "to wash",
  },
  Λύδδα: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G3069",
    gloss: "Lydda",
  },
  Λύδδας: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G3069",
    gloss: "Lydda",
  },
  Λυδία: {
    parse: "N-NSF-P｜Noun, nominative, singular, feminine, Person",
    GN: "G3070",
    gloss: "Lydia",
  },
  Λυδίαν: {
    parse: "N-ASF-P｜Noun, accusative, singular, feminine, Person",
    GN: "G3070",
    gloss: "Lydia",
  },
  Λυκαονίας: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G3071",
    gloss: "Lycaonia",
  },
  Λυκαονιστὶ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3072",
    gloss: "Lycaonian",
  },
  Λυκίας: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G3073",
    gloss: "Lycia",
  },
  λύκοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3074",
    gloss: "wolf",
  },
  λύκον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3074",
    gloss: "wolf",
  },
  λύκος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3074",
    gloss: "wolf",
  },
  λύκων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G3074",
    gloss: "wolf",
  },
  ἐλυμαίνετο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G3075",
    gloss: "to ravage",
  },
  ἐλυπήθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G3076",
    gloss: "to grieve",
  },
  ἐλυπήθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G3076",
    gloss: "to grieve",
  },
  ἐλυπήθητε: {
    parse: "V-API-2P｜Verb, aorist, passive, indicative, second, plural",
    GN: "G3076",
    gloss: "to grieve",
  },
  ἐλύπησα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G3076",
    gloss: "to grieve",
  },
  ἐλύπησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3076",
    gloss: "to grieve",
  },
  λελύπηκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G3076",
    gloss: "to grieve",
  },
  λυπεῖσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G3076",
    gloss: "to grieve",
  },
  λυπεῖται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G3076",
    gloss: "to grieve",
  },
  λυπεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G3076",
    gloss: "to grieve",
  },
  λυπηθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G3076",
    gloss: "to grieve",
  },
  λυπηθέντας: {
    parse:
      "V-APP-APM｜Verb, aorist, passive, Participle, accusative, plural, masculine",
    GN: "G3076",
    gloss: "to grieve",
  },
  λυπηθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G3076",
    gloss: "to grieve",
  },
  λυπηθήσεσθε: {
    parse: "V-FPI-2P｜Verb, future, passive, indicative, second, plural",
    GN: "G3076",
    gloss: "to grieve",
  },
  λυπηθῆτε: {
    parse: "V-APS-2P｜Verb, aorist, passive, subjunctive, second, plural",
    GN: "G3076",
    gloss: "to grieve",
  },
  λυπῆσθε: {
    parse: "V-PPS-2P｜Verb, present, passive, subjunctive, second, plural",
    GN: "G3076",
    gloss: "to grieve",
  },
  λυπούμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G3076",
    gloss: "to grieve",
  },
  λυπούμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G3076",
    gloss: "to grieve",
  },
  λυπῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G3076",
    gloss: "to grieve",
  },
  λύπας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G3077",
    gloss: "grief",
  },
  λύπῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3077",
    gloss: "grief",
  },
  λύπη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3077",
    gloss: "grief",
  },
  λύπην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3077",
    gloss: "grief",
  },
  λύπης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3077",
    gloss: "grief",
  },
  λυπηθέντες: {
    parse:
      "N-GSF｜Noun, aorist, participle, passive, nominative, masculine, singular",
    GN: "G3077",
    gloss: "grief",
  },
  Λυσανίου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G3078",
    gloss: "Lysanias",
  },
  Λυσίας: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G3079",
    gloss: "Lysias",
  },
  λύσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3080",
    gloss: "divorce",
  },
  λυσιτελεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3081",
    gloss: "to profit",
  },
  Λύστραν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G3082",
    gloss: "Lystra",
  },
  Λύστροις: {
    parse: "N-DPN-L｜Noun, dative, plural, neuter, Location",
    GN: "G3082",
    gloss: "Lystra",
  },
  λύτρον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G3083",
    gloss: "ransom",
  },
  ἐλυτρώθητε: {
    parse: "V-API-2P｜Verb, aorist, passive, indicative, second, plural",
    GN: "G3084",
    gloss: "to ransom",
  },
  λυτροῦσθαι: {
    parse: "V-PMN｜Verb, present, middle, Infinitive",
    GN: "G3084",
    gloss: "to ransom",
  },
  λυτρώσηται: {
    parse: "V-AMS-3S｜Verb, aorist, middle, subjunctive, third, singular",
    GN: "G3084",
    gloss: "to ransom",
  },
  λύτρωσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3085",
    gloss: "redemption",
  },
  λυτρωτὴν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3086",
    gloss: "redeemer",
  },
  λυχνία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3087",
    gloss: "lampstand",
  },
  λυχνίαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G3087",
    gloss: "lampstand",
  },
  λυχνίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3087",
    gloss: "lampstand",
  },
  λυχνίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3087",
    gloss: "lampstand",
  },
  λυχνιῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G3087",
    gloss: "lampstand",
  },
  λύχνοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3088",
    gloss: "lamp",
  },
  λύχνον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3088",
    gloss: "lamp",
  },
  λύχνος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3088",
    gloss: "lamp",
  },
  λύχνου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3088",
    gloss: "lamp",
  },
  λύχνῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3088",
    gloss: "lamp",
  },
  ἔλυεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G3089",
    gloss: "to loose",
  },
  ἐλύετο: {
    parse: "V-IPI-3S｜Verb, imperfect, passive, indicative, third, singular",
    GN: "G3089",
    gloss: "to loose",
  },
  ἐλύθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G3089",
    gloss: "to loose",
  },
  ἐλύθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G3089",
    gloss: "to loose",
  },
  ἔλυσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3089",
    gloss: "to loose",
  },
  λελυμένα: {
    parse:
      "V-RPP-NPN｜Verb, perfect, passive, Participle, nominative, plural, neuter",
    GN: "G3089",
    gloss: "to loose",
  },
  λελυμένον: {
    parse:
      "V-RPP-NSN｜Verb, perfect, passive, Participle, nominative, singular, neuter",
    GN: "G3089",
    gloss: "to loose",
  },
  λέλυσαι: {
    parse: "V-RPI-2S｜Verb, perfect, passive, indicative, second, singular",
    GN: "G3089",
    gloss: "to loose",
  },
  λύει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3089",
    gloss: "to loose",
  },
  λύετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G3089",
    gloss: "to loose",
  },
  λυθείσης: {
    parse:
      "V-APP-GSF｜Verb, aorist, passive, Participle, genitive, singular, feminine",
    GN: "G3089",
    gloss: "to loose",
  },
  λυθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G3089",
    gloss: "to loose",
  },
  λυθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G3089",
    gloss: "to loose",
  },
  λυθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G3089",
    gloss: "to loose",
  },
  λυθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G3089",
    gloss: "to loose",
  },
  λυομένων: {
    parse:
      "V-PPP-GPN｜Verb, present, passive, Participle, genitive, plural, neuter",
    GN: "G3089",
    gloss: "to loose",
  },
  λύοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G3089",
    gloss: "to loose",
  },
  λυόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G3089",
    gloss: "to loose",
  },
  λύουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G3089",
    gloss: "to loose",
  },
  λῦσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G3089",
    gloss: "to loose",
  },
  λύσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G3089",
    gloss: "to loose",
  },
  λύσαντι: {
    parse:
      "V-AAP-DSM｜Verb, aorist, active, Participle, dative, singular, masculine",
    GN: "G3089",
    gloss: "to loose",
  },
  λύσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G3089",
    gloss: "to loose",
  },
  λύσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G3089",
    gloss: "to loose",
  },
  Λύσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G3089",
    gloss: "to loose",
  },
  λύσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G3089",
    gloss: "to loose",
  },
  λύσῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G3089",
    gloss: "to loose",
  },
  λύσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G3089",
    gloss: "to loose",
  },
  Λῦσον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G3089",
    gloss: "to loose",
  },
  λύσω: {
    parse: "V-AAS-1S｜Verb, aorist, active, subjunctive, first, singular",
    GN: "G3089",
    gloss: "to loose",
  },
  Λωΐδι: {
    parse: "N-DSF-P｜Noun, dative, singular, feminine, Person",
    GN: "G3090",
    gloss: "Lois",
  },
  Λὼτ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G3091",
    gloss: "Lot",
  },
  Λώτ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G3091",
    gloss: "Lot",
  },
  Μάαθ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G3092",
    gloss: "Maath",
  },
  Μαγαδάν: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G3093",
    gloss: "Magadan",
  },
  Μαγδαληνῇ: {
    parse: "N-DSF-LG｜Noun, dative, singular, feminine, Location Gentilic",
    GN: "G3094",
    gloss: "Magdalene",
  },
  Μαγδαληνή: {
    parse: "N-NSF-LG｜Noun, nominative, singular, feminine, Location Gentilic",
    GN: "G3094",
    gloss: "Magdalene",
  },
  Μαγδαληνὴ: {
    parse: "N-NSF-LG｜Noun, nominative, singular, feminine, Location Gentilic",
    GN: "G3094",
    gloss: "Magdalene",
  },
  μαγείαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G3095",
    gloss: "magic",
  },
  μαγεύων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3096",
    gloss: "to practice magic",
  },
  μάγοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3097",
    gloss: "sage",
  },
  μάγον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3097",
    gloss: "sage",
  },
  μάγος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3097",
    gloss: "sage",
  },
  μάγους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3097",
    gloss: "sage",
  },
  μάγων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G3097",
    gloss: "sage",
  },
  Μαγώγ: {
    parse: "N-ASM-L｜Noun, accusative, singular, masculine, Location",
    GN: "G3098",
    gloss: "Magog",
  },
  Μαδιάμ: {
    parse: "N-GSM-L｜Noun, genitive, singular, masculine, Location",
    GN: "G3099",
    gloss: "Midian",
  },
  ἐμαθητεύθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G3100",
    gloss: "to disciple",
  },
  μαθητευθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G3100",
    gloss: "to disciple",
  },
  μαθητεύσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G3100",
    gloss: "to disciple",
  },
  μαθητεύσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G3100",
    gloss: "to disciple",
  },
  μαθηταί: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3101",
    gloss: "disciple",
  },
  μαθηταὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3101",
    gloss: "disciple",
  },
  μαθηταῖς: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G3101",
    gloss: "disciple",
  },
  μαθητάς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3101",
    gloss: "disciple",
  },
  μαθητὰς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3101",
    gloss: "disciple",
  },
  μαθητῇ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3101",
    gloss: "disciple",
  },
  μαθητὴν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3101",
    gloss: "disciple",
  },
  μαθητής: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3101",
    gloss: "disciple",
  },
  μαθητὴς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3101",
    gloss: "disciple",
  },
  μαθητοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3101",
    gloss: "disciple",
  },
  μαθητῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G3101",
    gloss: "disciple",
  },
  μαθήτρια: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3102",
    gloss: "disciple",
  },
  Μαθουσαλὰ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G3103",
    gloss: "Methuselah",
  },
  Μεννὰ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G3104",
    gloss: "Menna",
  },
  μαίνεσθε: {
    parse:
      "V-PNI-2P｜Verb, present, Middle or Passive deponent, indicative, second, plural",
    GN: "G3105",
    gloss: "to rave",
  },
  μαίνεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G3105",
    gloss: "to rave",
  },
  Μαίνῃ: {
    parse:
      "V-PNI-2S｜Verb, present, Middle or Passive deponent, indicative, second, singular",
    GN: "G3105",
    gloss: "to rave",
  },
  μαίνομαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G3105",
    gloss: "to rave",
  },
  μακαρίζομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G3106",
    gloss: "to bless",
  },
  μακαριοῦσίν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G3106",
    gloss: "to bless",
  },
  μακαρία: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G3107",
    gloss: "blessed",
  },
  Μακαρία: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G3107",
    gloss: "blessed",
  },
  Μακάριαι: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G3107",
    gloss: "blessed",
  },
  μακαρίαν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G3107",
    gloss: "blessed",
  },
  μακάριοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3107",
    gloss: "blessed",
  },
  Μακάριοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3107",
    gloss: "blessed",
  },
  μακάριοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3107",
    gloss: "blessed",
  },
  Μακάριοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3107",
    gloss: "blessed",
  },
  μακάριον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G3107",
    gloss: "blessed",
  },
  Μακάριόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G3107",
    gloss: "blessed",
  },
  μακάριος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3107",
    gloss: "blessed",
  },
  Μακάριος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3107",
    gloss: "blessed",
  },
  μακάριός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3107",
    gloss: "blessed",
  },
  μακαρίου: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G3107",
    gloss: "blessed",
  },
  μακαριωτέρα: {
    parse: "A-NSF-C｜Adjective, nominative, singular, feminine, Comparative",
    GN: "G3107",
    gloss: "blessed",
  },
  μακαρισμὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3108",
    gloss: "blessedness",
  },
  μακαρισμὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3108",
    gloss: "blessedness",
  },
  Μακεδονίᾳ: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G3109",
    gloss: "Macedonia",
  },
  Μακεδονία: {
    parse: "N-NSF-L｜Noun, nominative, singular, feminine, Location",
    GN: "G3109",
    gloss: "Macedonia",
  },
  Μακεδονίαν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G3109",
    gloss: "Macedonia",
  },
  Μακεδονίας: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G3109",
    gloss: "Macedonia",
  },
  Μακεδόνας: {
    parse: "N-APM-LG｜Noun, accusative, plural, masculine, Location Gentilic",
    GN: "G3110",
    gloss: "Macedonia",
  },
  Μακεδόνες: {
    parse: "N-NPM-LG｜Noun, nominative, plural, masculine, Location Gentilic",
    GN: "G3110",
    gloss: "Macedonia",
  },
  Μακεδόνος: {
    parse: "N-GSM-LG｜Noun, genitive, singular, masculine, Location Gentilic",
    GN: "G3110",
    gloss: "Macedonia",
  },
  Μακεδόσιν: {
    parse: "N-DPM-LG｜Noun, dative, plural, masculine, Location Gentilic",
    GN: "G3110",
    gloss: "Macedonia",
  },
  Μακεδών: {
    parse: "N-NSM-LG｜Noun, nominative, singular, masculine, Location Gentilic",
    GN: "G3110",
    gloss: "Macedonia",
  },
  μακέλλῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G3111",
    gloss: "meat market",
  },
  μακρόθεν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3113",
    gloss: "from afar",
  },
  μακροθυμεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3114",
    gloss: "to have patience",
  },
  μακροθυμεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G3114",
    gloss: "to have patience",
  },
  μακροθυμήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G3114",
    gloss: "to have patience",
  },
  μακροθυμήσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G3114",
    gloss: "to have patience",
  },
  Μακροθυμήσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G3114",
    gloss: "to have patience",
  },
  μακροθύμησον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G3114",
    gloss: "to have patience",
  },
  Μακροθύμησον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G3114",
    gloss: "to have patience",
  },
  μακροθυμῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3114",
    gloss: "to have patience",
  },
  μακροθυμίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3115",
    gloss: "patience",
  },
  μακροθυμία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3115",
    gloss: "patience",
  },
  μακροθυμίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3115",
    gloss: "patience",
  },
  μακροθυμίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3115",
    gloss: "patience",
  },
  μακροθύμως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3116",
    gloss: "patiently",
  },
  μακρὰ: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G3117",
    gloss: "long/distant",
  },
  μακράν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G3117",
    gloss: "long/distant",
  },
  μακρὰν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G3117",
    gloss: "long/distant",
  },
  μακροχρόνιος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3118",
    gloss: "long-lived",
  },
  μαλακίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3119",
    gloss: "sickness",
  },
  μαλακὰ: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G3120",
    gloss: "soft/effeminate",
  },
  μαλακοὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3120",
    gloss: "soft/effeminate",
  },
  μαλακοῖς: {
    parse: "A-DPN｜Adjective, dative, plural, neuter",
    GN: "G3120",
    gloss: "soft/effeminate",
  },
  Μαλελεὴλ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G3121",
    gloss: "Mahalaleel",
  },
  μάλιστα: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3122",
    gloss: "especially",
  },
  μᾶλλον: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3123",
    gloss: "rather",
  },
  Μάλχος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G3124",
    gloss: "Malchus",
  },
  μάμμῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3125",
    gloss: "grandmother",
  },
  μαμωνᾷ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3126",
    gloss: "wealth",
  },
  μαμωνᾶ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3126",
    gloss: "wealth",
  },
  Μαναήν: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G3127",
    gloss: "Manaen",
  },
  Μανασσῆ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G3128",
    gloss: "Manasseh",
  },
  Μανασσῆς: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G3128",
    gloss: "Manasseh",
  },
  ἔμαθεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G3129",
    gloss: "to learn",
  },
  ἔμαθες: {
    parse: "V-2AAI-2S｜Verb, 2nd aorist, active, indicative, second, singular",
    GN: "G3129",
    gloss: "to learn",
  },
  ἐμάθετε: {
    parse: "V-2AAI-2P｜Verb, 2nd aorist, active, indicative, second, plural",
    GN: "G3129",
    gloss: "to learn",
  },
  ἔμαθον: {
    parse:
      "V-2AAI-1S｜Verb, 2nd aorist, active, indicative, first, singular OR third, plural",
    GN: "G3129",
    gloss: "to learn",
  },
  μαθεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G3129",
    gloss: "to learn",
  },
  μάθετε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G3129",
    gloss: "to learn",
  },
  μάθητε: {
    parse: "V-2AAS-2P｜Verb, 2nd aorist, active, subjunctive, second, plural",
    GN: "G3129",
    gloss: "to learn",
  },
  μαθὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G3129",
    gloss: "to learn",
  },
  μανθανέτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G3129",
    gloss: "to learn",
  },
  μανθανέτωσαν: {
    parse: "V-PAM-3P｜Verb, present, active, imperative, third, plural",
    GN: "G3129",
    gloss: "to learn",
  },
  μανθάνοντα: {
    parse:
      "V-PAP-APN｜Verb, present, active, Participle, accusative, plural, neuter",
    GN: "G3129",
    gloss: "to learn",
  },
  μανθάνουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G3129",
    gloss: "to learn",
  },
  μανθάνωσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G3129",
    gloss: "to learn",
  },
  μεμαθηκώς: {
    parse:
      "V-RAP-NSM｜Verb, perfect, active, Participle, nominative, singular, masculine",
    GN: "G3129",
    gloss: "to learn",
  },
  μανίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3130",
    gloss: "insanity",
  },
  μάννα: {
    parse: "HEB｜Hebrew transliterated word (indeclinable)",
    GN: "G3131",
    gloss: "manna",
  },
  μαντευομένη: {
    parse:
      "V-PNP-NSF｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, feminine",
    GN: "G3132",
    gloss: "to divine",
  },
  μαρανθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G3133",
    gloss: "to fade",
  },
  θά: {
    parse: "ARAM｜Aramaic transliterated word (indeclinable)",
    GN: "G3134",
    gloss: "Come, Lord!",
  },
  Μαράνα: {
    parse: "ARAM｜Aramaic transliterated word (indeclinable)",
    GN: "G3134",
    gloss: "Come, Lord!",
  },
  μαργαρῖται: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3135",
    gloss: "pearl",
  },
  μαργαρίταις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G3135",
    gloss: "pearl",
  },
  μαργαρίτας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3135",
    gloss: "pearl",
  },
  μαργαρίτῃ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3135",
    gloss: "pearl",
  },
  μαργαρίτην: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3135",
    gloss: "pearl",
  },
  μαργαρίτου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3135",
    gloss: "pearl",
  },
  μαργαριτῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G3135",
    gloss: "pearl",
  },
  Μάρθα: {
    parse: "N-VSF-P｜Noun, Vocative or nominative, singular, feminine, Person",
    GN: "G3136",
    gloss: "Martha",
  },
  Μάρθαν: {
    parse: "N-ASF-P｜Noun, accusative, singular, feminine, Person",
    GN: "G3136",
    gloss: "Martha",
  },
  Μάρθας: {
    parse: "N-GSF-P｜Noun, genitive, singular, feminine, Person",
    GN: "G3136",
    gloss: "Martha",
  },
  Μαρίᾳ: {
    parse: "N-DSF-P｜Noun, dative, singular, feminine, Person",
    GN: "G3137",
    gloss: "Mary",
  },
  Μαρία: {
    parse: "N-NSF-P｜Noun, nominative, singular, feminine, Person",
    GN: "G3137",
    gloss: "Mary",
  },
  Μαριὰμ: {
    parse: "N-NSF-P｜Noun, nominative, singular, feminine, Person",
    GN: "G3137",
    gloss: "Mary",
  },
  Μαριάμ: {
    parse: "N-VSF-P｜Noun, Vocative or nominative, singular, feminine, Person",
    GN: "G3137",
    gloss: "Mary",
  },
  Μαρίαν: {
    parse: "N-ASF-P｜Noun, accusative, singular, feminine, Person",
    GN: "G3137",
    gloss: "Mary",
  },
  Μαρίας: {
    parse: "N-GSF-P｜Noun, genitive, singular, feminine, Person",
    GN: "G3137",
    gloss: "Mary",
  },
  Μᾶρκον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G3138",
    gloss: "Mark",
  },
  Μᾶρκος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G3138",
    gloss: "Mark",
  },
  Μάρκου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G3138",
    gloss: "Mark",
  },
  μαρμάρου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3139",
    gloss: "marble",
  },
  ἐμαρτύρει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G3140",
    gloss: "to testify",
  },
  ἐμαρτυρεῖτο: {
    parse: "V-IPI-3S｜Verb, imperfect, passive, indicative, third, singular",
    GN: "G3140",
    gloss: "to testify",
  },
  ἐμαρτυρήθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G3140",
    gloss: "to testify",
  },
  ἐμαρτυρήθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G3140",
    gloss: "to testify",
  },
  ἐμαρτυρήσαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G3140",
    gloss: "to testify",
  },
  ἐμαρτύρησάν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G3140",
    gloss: "to testify",
  },
  ἐμαρτύρησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3140",
    gloss: "to testify",
  },
  ἐμαρτύρουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G3140",
    gloss: "to testify",
  },
  μαρτυρεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3140",
    gloss: "to testify",
  },
  μαρτυρεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3140",
    gloss: "to testify",
  },
  μαρτυρεῖς: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G3140",
    gloss: "to testify",
  },
  μαρτυρεῖται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G3140",
    gloss: "to testify",
  },
  μαρτυρεῖτε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G3140",
    gloss: "to testify",
  },
  μαρτυρηθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G3140",
    gloss: "to testify",
  },
  μαρτυρῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G3140",
    gloss: "to testify",
  },
  μαρτυρήσαντος: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G3140",
    gloss: "to testify",
  },
  μαρτυρήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G3140",
    gloss: "to testify",
  },
  μαρτυρήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G3140",
    gloss: "to testify",
  },
  μαρτυρήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G3140",
    gloss: "to testify",
  },
  μαρτύρησον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G3140",
    gloss: "to testify",
  },
  μαρτυρήσω: {
    parse: "V-AAS-1S｜Verb, aorist, active, subjunctive, first, singular",
    GN: "G3140",
    gloss: "to testify",
  },
  μαρτυροῦμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G3140",
    gloss: "to testify",
  },
  μαρτυρουμένη: {
    parse:
      "V-PPP-NSF｜Verb, present, passive, Participle, nominative, singular, feminine",
    GN: "G3140",
    gloss: "to testify",
  },
  μαρτυρούμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G3140",
    gloss: "to testify",
  },
  μαρτυρούμενός: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G3140",
    gloss: "to testify",
  },
  μαρτυρουμένους: {
    parse:
      "V-PPP-APM｜Verb, present, passive, Participle, accusative, plural, masculine",
    GN: "G3140",
    gloss: "to testify",
  },
  μαρτυροῦν: {
    parse:
      "V-PAP-NSN｜Verb, present, active, Participle, nominative, singular, neuter",
    GN: "G3140",
    gloss: "to testify",
  },
  μαρτυροῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G3140",
    gloss: "to testify",
  },
  μαρτυροῦντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G3140",
    gloss: "to testify",
  },
  μαρτυροῦντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G3140",
    gloss: "to testify",
  },
  μαρτυρούντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G3140",
    gloss: "to testify",
  },
  μαρτυροῦσαι: {
    parse:
      "V-PAP-NPF｜Verb, present, active, Participle, nominative, plural, feminine",
    GN: "G3140",
    gloss: "to testify",
  },
  μαρτυρούσης: {
    parse:
      "V-PAP-GSF｜Verb, present, active, Participle, genitive, singular, feminine",
    GN: "G3140",
    gloss: "to testify",
  },
  μαρτυροῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G3140",
    gloss: "to testify",
  },
  μαρτυρῶ: {
    parse: "V-PAS-1S｜Verb, present, active, subjunctive, first, singular",
    GN: "G3140",
    gloss: "to testify",
  },
  Μαρτυρῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G3140",
    gloss: "to testify",
  },
  μαρτυρῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3140",
    gloss: "to testify",
  },
  μεμαρτύρηκα: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G3140",
    gloss: "to testify",
  },
  μεμαρτύρηκας: {
    parse: "V-RAI-2S｜Verb, perfect, active, indicative, second, singular",
    GN: "G3140",
    gloss: "to testify",
  },
  μεμαρτύρηκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G3140",
    gloss: "to testify",
  },
  μεμαρτύρηται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G3140",
    gloss: "to testify",
  },
  μαρτυρία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3141",
    gloss: "testimony",
  },
  μαρτυρίαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G3141",
    gloss: "testimony",
  },
  μαρτυρίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3141",
    gloss: "testimony",
  },
  μαρτυρίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3141",
    gloss: "testimony",
  },
  μαρτύριον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G3142",
    gloss: "testimony",
  },
  μαρτυρίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G3142",
    gloss: "testimony",
  },
  μαρτύρομαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G3143",
    gloss: "to testify",
  },
  μαρτυρόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G3143",
    gloss: "to testify",
  },
  μαρτυρόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G3143",
    gloss: "to testify",
  },
  μάρτυρα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3144",
    gloss: "witness",
  },
  μάρτυρας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3144",
    gloss: "witness",
  },
  μάρτυρες: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3144",
    gloss: "witness",
  },
  μάρτυρές: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3144",
    gloss: "witness",
  },
  μάρτυρός: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3144",
    gloss: "witness",
  },
  μαρτύρων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G3144",
    gloss: "witness",
  },
  μάρτυς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3144",
    gloss: "witness",
  },
  μάρτυσι: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G3144",
    gloss: "witness",
  },
  μάρτυσιν: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G3144",
    gloss: "witness",
  },
  μάρτυσίν: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G3144",
    gloss: "witness",
  },
  ἐμασῶντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G3145",
    gloss: "to gnaw",
  },
  ἐμαστίγωσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3146",
    gloss: "to whip",
  },
  μαστιγοῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3146",
    gloss: "to whip",
  },
  μαστιγῶσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G3146",
    gloss: "to whip",
  },
  μαστιγώσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G3146",
    gloss: "to whip",
  },
  μαστιγώσετε: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G3146",
    gloss: "to whip",
  },
  μαστιγώσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G3146",
    gloss: "to whip",
  },
  μαστίζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3147",
    gloss: "to whip",
  },
  μάστιγας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G3148",
    gloss: "whip",
  },
  μάστιγος: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3148",
    gloss: "whip",
  },
  μάστιγός: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3148",
    gloss: "whip",
  },
  μαστίγων: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G3148",
    gloss: "whip",
  },
  μάστιξιν: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G3148",
    gloss: "whip",
  },
  μαστοὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3149",
    gloss: "breast",
  },
  μαστοῖς: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G3149",
    gloss: "breast",
  },
  ματαιολογίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3150",
    gloss: "babble",
  },
  ματαιολόγοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3151",
    gloss: "babbler",
  },
  ματαία: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G3152",
    gloss: "futile",
  },
  ματαίας: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G3152",
    gloss: "futile",
  },
  μάταιοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3152",
    gloss: "futile",
  },
  μάταιος: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G3152",
    gloss: "futile",
  },
  ματαίων: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G3152",
    gloss: "futile",
  },
  ματαιότητι: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3153",
    gloss: "futility",
  },
  ματαιότητος: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3153",
    gloss: "futility",
  },
  ἐματαιώθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G3154",
    gloss: "to make futile",
  },
  μάτην: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3155",
    gloss: "in vain",
  },
  Μαθθαῖον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G3156",
    gloss: "Matthew",
  },
  Μαθθαῖος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G3156",
    gloss: "Matthew",
  },
  Ματθάν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G3157",
    gloss: "Matthan",
  },
  Ματθὰν: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G3157",
    gloss: "Matthan",
  },
  Μαθθὰτ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G3158",
    gloss: "Matthat",
  },
  Μαθθίαν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G3159",
    gloss: "Matthias",
  },
  Ματταθὰ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G3160",
    gloss: "Mattatha",
  },
  Ματταθίου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G3161",
    gloss: "Mattathias",
  },
  μάχαιρα: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3162",
    gloss: "sword",
  },
  μάχαιραι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G3162",
    gloss: "sword",
  },
  μάχαιραν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3162",
    gloss: "sword",
  },
  μάχαιράν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3162",
    gloss: "sword",
  },
  μαχαίρῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3162",
    gloss: "sword",
  },
  μαχαίρης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3162",
    gloss: "sword",
  },
  μαχαιρῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G3162",
    gloss: "sword",
  },
  μάχαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G3163",
    gloss: "quarrel",
  },
  μάχας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G3163",
    gloss: "quarrel",
  },
  Ἐμάχοντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G3164",
    gloss: "to quarrel",
  },
  μάχεσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G3164",
    gloss: "to quarrel",
  },
  μάχεσθε: {
    parse:
      "V-PNI-2P｜Verb, present, Middle or Passive deponent, indicative, second, plural",
    GN: "G3164",
    gloss: "to quarrel",
  },
  μαχομένοις: {
    parse:
      "V-PNP-DPM｜Verb, present, Middle or Passive deponent, Participle, dative, plural, masculine",
    GN: "G3164",
    gloss: "to quarrel",
  },
  μεγαλεῖα: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G3167",
    gloss: "mighty",
  },
  μεγαλειότητι: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3168",
    gloss: "majesty",
  },
  μεγαλειότητος: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3168",
    gloss: "majesty",
  },
  μεγαλοπρεποῦς: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G3169",
    gloss: "majestic",
  },
  ἐμεγάλυνεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G3170",
    gloss: "to magnify",
  },
  ἐμεγαλύνετο: {
    parse: "V-IPI-3S｜Verb, imperfect, passive, indicative, third, singular",
    GN: "G3170",
    gloss: "to magnify",
  },
  Μεγαλύνει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3170",
    gloss: "to magnify",
  },
  μεγαλυνθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G3170",
    gloss: "to magnify",
  },
  μεγαλυνθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G3170",
    gloss: "to magnify",
  },
  μεγαλυνόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G3170",
    gloss: "to magnify",
  },
  μεγαλύνουσι: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G3170",
    gloss: "to magnify",
  },
  μεγαλύνουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G3170",
    gloss: "to magnify",
  },
  μεγάλως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3171",
    gloss: "greatly",
  },
  μεγαλωσύνη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3172",
    gloss: "majesty",
  },
  μεγαλωσύνης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3172",
    gloss: "majesty",
  },
  μέγα: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G3173",
    gloss: "great",
  },
  μεγάλα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G3173",
    gloss: "great",
  },
  Μεγάλα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G3173",
    gloss: "great",
  },
  μεγάλαι: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G3173",
    gloss: "great",
  },
  μεγάλαις: {
    parse: "A-DPF｜Adjective, dative, plural, feminine",
    GN: "G3173",
    gloss: "great",
  },
  μεγάλας: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G3173",
    gloss: "great",
  },
  μεγάλῃ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G3173",
    gloss: "great",
  },
  μεγάλη: {
    parse: "A-VSF｜Adjective, Vocative or nominative, singular, feminine",
    GN: "G3173",
    gloss: "great",
  },
  Μεγάλη: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G3173",
    gloss: "great",
  },
  μεγάλην: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G3173",
    gloss: "great",
  },
  μεγάλης: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G3173",
    gloss: "great",
  },
  μεγάλοι: {
    parse: "A-VPM｜Adjective, Vocative or nominative, plural, masculine",
    GN: "G3173",
    gloss: "great",
  },
  μεγάλου: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G3173",
    gloss: "great",
  },
  μεγάλους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G3173",
    gloss: "great",
  },
  μεγάλῳ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G3173",
    gloss: "great",
  },
  μεγάλων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G3173",
    gloss: "great",
  },
  μέγαν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G3173",
    gloss: "great",
  },
  μέγας: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3173",
    gloss: "great",
  },
  μέγιστα: {
    parse: "A-NPN-S｜Adjective, nominative, plural, neuter, Superlative",
    GN: "G3173",
    gloss: "great",
  },
  μεῖζον: {
    parse: "A-NSN-C｜Adjective, nominative, singular, neuter, Comparative",
    GN: "G3173",
    gloss: "great",
  },
  μεῖζόν: {
    parse: "A-NSN-C｜Adjective, nominative, singular, neuter, Comparative",
    GN: "G3173",
    gloss: "great",
  },
  μείζονα: {
    parse: "A-ASM-C｜Adjective, accusative, singular, masculine, Comparative",
    GN: "G3173",
    gloss: "great",
  },
  μείζονας: {
    parse: "A-APF-C｜Adjective, accusative, plural, feminine, Comparative",
    GN: "G3173",
    gloss: "great",
  },
  μείζονες: {
    parse: "A-NPM-C｜Adjective, nominative, plural, masculine, Comparative",
    GN: "G3173",
    gloss: "great",
  },
  μείζονος: {
    parse: "A-GSM-C｜Adjective, genitive, singular, masculine, Comparative",
    GN: "G3173",
    gloss: "great",
  },
  μειζοτέραν: {
    parse: "A-ASF-C｜Adjective, accusative, singular, feminine, Comparative",
    GN: "G3173",
    gloss: "great",
  },
  μείζω: {
    parse: "A-ASF-C｜Adjective, accusative, singular, feminine, Comparative",
    GN: "G3173",
    gloss: "great",
  },
  μείζων: {
    parse: "A-NSM-C｜Adjective, nominative, singular, masculine, Comparative",
    GN: "G3173",
    gloss: "great",
  },
  μέγεθος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G3174",
    gloss: "greatness",
  },
  μεγιστᾶνες: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3175",
    gloss: "great man",
  },
  μεγιστᾶσιν: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G3175",
    gloss: "great man",
  },
  μεθερμηνεύεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G3177",
    gloss: "to mean",
  },
  μεθερμηνευόμενον: {
    parse:
      "V-PPP-NSN｜Verb, present, passive, Participle, nominative, singular, neuter",
    GN: "G3177",
    gloss: "to mean",
  },
  μέθαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G3178",
    gloss: "drunkenness",
  },
  μέθαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G3178",
    gloss: "drunkenness",
  },
  μέθῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3178",
    gloss: "drunkenness",
  },
  μεθιστάναι: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3179",
    gloss: "to move",
  },
  μετασταθῶ: {
    parse: "V-APS-1S｜Verb, aorist, passive, subjunctive, first, singular",
    GN: "G3179",
    gloss: "to move",
  },
  μεταστήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G3179",
    gloss: "to move",
  },
  μετέστησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3179",
    gloss: "to move",
  },
  μεθοδείαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3180",
    gloss: "scheme",
  },
  μεθοδείας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G3180",
    gloss: "scheme",
  },
  ἐμεθύσθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G3182",
    gloss: "to get drunk",
  },
  μεθύσκεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G3182",
    gloss: "to get drunk",
  },
  μεθύσκεσθε: {
    parse: "V-PPM-2P｜Verb, present, passive, imperative, second, plural",
    GN: "G3182",
    gloss: "to get drunk",
  },
  μεθυσκόμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G3182",
    gloss: "to get drunk",
  },
  μέθυσοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3183",
    gloss: "drunkard",
  },
  μέθυσος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3183",
    gloss: "drunkard",
  },
  μεθύει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3184",
    gloss: "to get drunk",
  },
  μεθυόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G3184",
    gloss: "to get drunk",
  },
  μεθύουσαν: {
    parse:
      "V-PAP-ASF｜Verb, present, active, Participle, accusative, singular, feminine",
    GN: "G3184",
    gloss: "to get drunk",
  },
  μεθύουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G3184",
    gloss: "to get drunk",
  },
  μεθυσθῶσιν: {
    parse: "V-APS-3P｜Verb, aorist, passive, subjunctive, third, plural",
    GN: "G3184",
    gloss: "to get drunk",
  },
  μέλανι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G3188",
    gloss: "ink",
  },
  μέλανος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G3188",
    gloss: "ink",
  },
  μέλαιναν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G3189",
    gloss: "black",
  },
  μέλας: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3189",
    gloss: "black",
  },
  Μελεὰ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G3190",
    gloss: "Melea",
  },
  ἐμελέτησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G3191",
    gloss: "to meditate/plot",
  },
  μελέτα: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G3191",
    gloss: "to meditate/plot",
  },
  μέλι: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G3192",
    gloss: "honey",
  },
  Μελίτη: {
    parse: "N-NSF-L｜Noun, nominative, singular, feminine, Location",
    GN: "G3194",
    gloss: "Malta",
  },
  ἔμελλεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G3195",
    gloss: "to ensue",
  },
  ἔμελλον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G3195",
    gloss: "to ensue",
  },
  ἤμελλεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G3195",
    gloss: "to ensue",
  },
  ἤμελλον: {
    parse: "V-IAI-1S｜Verb, imperfect, active, indicative, first, singular",
    GN: "G3195",
    gloss: "to ensue",
  },
  μέλλει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3195",
    gloss: "to ensue",
  },
  Μέλλει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3195",
    gloss: "to ensue",
  },
  μέλλειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3195",
    gloss: "to ensue",
  },
  μέλλεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G3195",
    gloss: "to ensue",
  },
  μέλλετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G3195",
    gloss: "to ensue",
  },
  μέλλῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G3195",
    gloss: "to ensue",
  },
  μελλήσετε: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G3195",
    gloss: "to ensue",
  },
  μελλήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G3195",
    gloss: "to ensue",
  },
  μέλλομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G3195",
    gloss: "to ensue",
  },
  μέλλον: {
    parse:
      "V-PAP-ASN｜Verb, present, active, Participle, accusative, singular, neuter",
    GN: "G3195",
    gloss: "to ensue",
  },
  μέλλοντα: {
    parse:
      "V-PAP-NPN｜Verb, present, active, Participle, nominative, plural, neuter",
    GN: "G3195",
    gloss: "to ensue",
  },
  μέλλοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G3195",
    gloss: "to ensue",
  },
  μέλλοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G3195",
    gloss: "to ensue",
  },
  μέλλοντι: {
    parse:
      "V-PAP-DSN｜Verb, present, active, Participle, dative, singular, neuter",
    GN: "G3195",
    gloss: "to ensue",
  },
  μέλλοντος: {
    parse:
      "V-PAP-GSN｜Verb, present, active, Participle, genitive, singular, neuter",
    GN: "G3195",
    gloss: "to ensue",
  },
  μελλόντων: {
    parse:
      "V-PAP-GPN｜Verb, present, active, Participle, genitive, plural, neuter",
    GN: "G3195",
    gloss: "to ensue",
  },
  μέλλουσαν: {
    parse:
      "V-PAP-ASF｜Verb, present, active, Participle, accusative, singular, feminine",
    GN: "G3195",
    gloss: "to ensue",
  },
  μελλούσης: {
    parse:
      "V-PAP-GSF｜Verb, present, active, Participle, genitive, singular, feminine",
    GN: "G3195",
    gloss: "to ensue",
  },
  μέλλουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G3195",
    gloss: "to ensue",
  },
  μέλλω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G3195",
    gloss: "to ensue",
  },
  μέλλων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3195",
    gloss: "to ensue",
  },
  Μέλλων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3195",
    gloss: "to ensue",
  },
  μέλεσιν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G3196",
    gloss: "member",
  },
  μέλεσίν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G3196",
    gloss: "member",
  },
  μέλη: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G3196",
    gloss: "member",
  },
  μέλος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G3196",
    gloss: "member",
  },
  μελῶν: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G3196",
    gloss: "member",
  },
  Μελχὶ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G3197",
    gloss: "Melchi",
  },
  Μελχισέδεκ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G3198",
    gloss: "Melchizedek",
  },
  ἔμελεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G3199",
    gloss: "to concern",
  },
  μέλει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3199",
    gloss: "to concern",
  },
  μελέτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G3199",
    gloss: "to concern",
  },
  μεμβράνας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G3200",
    gloss: "parchment",
  },
  μέμφεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G3201",
    gloss: "to blame",
  },
  μεμφόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G3201",
    gloss: "to blame",
  },
  μεμψίμοιροι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3202",
    gloss: "malcontent",
  },
  μέν: {
    parse: "PRT｜Particle, disjunctive particle",
    GN: "G3303",
    gloss: "on the other hand",
  },
  μὲν: {
    parse: "PRT｜Particle, disjunctive particle",
    GN: "G3303",
    gloss: "on the other hand",
  },
  Μενοῦν: {
    parse: "PRT｜Particle, disjunctive particle",
    GN: "G3304",
    gloss: "rather",
  },
  μενοῦνγε: {
    parse: "PRT｜Particle, disjunctive particle",
    GN: "G3304",
    gloss: "rather",
  },
  μέντοι: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G3305",
    gloss: "yet",
  },
  ἐμείναμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G3306",
    gloss: "to stay",
  },
  ἔμειναν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G3306",
    gloss: "to stay",
  },
  ἔμεινεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3306",
    gloss: "to stay",
  },
  Ἔμεινεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3306",
    gloss: "to stay",
  },
  ἔμενεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G3306",
    gloss: "to stay",
  },
  ἔμενον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G3306",
    gloss: "to stay",
  },
  μεῖναι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G3306",
    gloss: "to stay",
  },
  μείνατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G3306",
    gloss: "to stay",
  },
  μείνῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G3306",
    gloss: "to stay",
  },
  μείνητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G3306",
    gloss: "to stay",
  },
  Μεῖνον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G3306",
    gloss: "to stay",
  },
  μείνωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G3306",
    gloss: "to stay",
  },
  μεμενήκεισαν: {
    parse: "V-LAI-3P｜Verb, pluperfect, active, indicative, third, plural",
    GN: "G3306",
    gloss: "to stay",
  },
  μένε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G3306",
    gloss: "to stay",
  },
  μενεῖ: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G3306",
    gloss: "to stay",
  },
  μένει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3306",
    gloss: "to stay",
  },
  μένειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3306",
    gloss: "to stay",
  },
  μένεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G3306",
    gloss: "to stay",
  },
  μενεῖτε: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G3306",
    gloss: "to stay",
  },
  μένετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G3306",
    gloss: "to stay",
  },
  μενέτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G3306",
    gloss: "to stay",
  },
  μένῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G3306",
    gloss: "to stay",
  },
  μένητε: {
    parse: "V-PAS-2P｜Verb, present, active, subjunctive, second, plural",
    GN: "G3306",
    gloss: "to stay",
  },
  μένομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G3306",
    gloss: "to stay",
  },
  μένον: {
    parse:
      "V-PAP-NSN｜Verb, present, active, Participle, nominative, singular, neuter",
    GN: "G3306",
    gloss: "to stay",
  },
  μένοντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G3306",
    gloss: "to stay",
  },
  μένοντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G3306",
    gloss: "to stay",
  },
  μένουσαν: {
    parse:
      "V-PAP-ASF｜Verb, present, active, Participle, accusative, singular, feminine",
    GN: "G3306",
    gloss: "to stay",
  },
  μένουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G3306",
    gloss: "to stay",
  },
  μενῶ: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G3306",
    gloss: "to stay",
  },
  μένω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G3306",
    gloss: "to stay",
  },
  μένων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3306",
    gloss: "to stay",
  },
  ἐμέρισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3307",
    gloss: "to divide",
  },
  ἐμερίσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G3307",
    gloss: "to divide",
  },
  μεμέρισται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G3307",
    gloss: "to divide",
  },
  μερίσασθαι: {
    parse: "V-AMN｜Verb, aorist, middle, Infinitive",
    GN: "G3307",
    gloss: "to divide",
  },
  μερισθεῖσα: {
    parse:
      "V-APP-NSF｜Verb, aorist, passive, Participle, nominative, singular, feminine",
    GN: "G3307",
    gloss: "to divide",
  },
  μερισθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G3307",
    gloss: "to divide",
  },
  μέριμνα: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3308",
    gloss: "concern",
  },
  μέριμναι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G3308",
    gloss: "concern",
  },
  μερίμναις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G3308",
    gloss: "concern",
  },
  μέριμναν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3308",
    gloss: "concern",
  },
  μεριμνῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3309",
    gloss: "to worry",
  },
  μεριμνᾷ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3309",
    gloss: "to worry",
  },
  μεριμνᾷς: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G3309",
    gloss: "to worry",
  },
  μεριμνᾶτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G3309",
    gloss: "to worry",
  },
  μεριμνήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G3309",
    gloss: "to worry",
  },
  μεριμνήσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G3309",
    gloss: "to worry",
  },
  μεριμνῶσι: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G3309",
    gloss: "to worry",
  },
  μεριμνῶσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G3309",
    gloss: "to worry",
  },
  μερίδα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3310",
    gloss: "part",
  },
  μερίδος: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3310",
    gloss: "part",
  },
  μερὶς: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3310",
    gloss: "part",
  },
  μερισμοῖς: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G3311",
    gloss: "division",
  },
  μερισμοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3311",
    gloss: "division",
  },
  μεριστὴν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3312",
    gloss: "arbiter",
  },
  μέρει: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G3313",
    gloss: "part",
  },
  μέρη: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G3313",
    gloss: "part",
  },
  μέρος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G3313",
    gloss: "part",
  },
  μέρους: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G3313",
    gloss: "part",
  },
  μεσημβρίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3314",
    gloss: "noon/south",
  },
  ἐμεσίτευσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3315",
    gloss: "to guarantee",
  },
  μεσίτῃ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3316",
    gloss: "mediator",
  },
  μεσίτης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3316",
    gloss: "mediator",
  },
  μεσίτου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3316",
    gloss: "mediator",
  },
  μεσονύκτιον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G3317",
    gloss: "midnight",
  },
  μεσονυκτίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G3317",
    gloss: "midnight",
  },
  Μεσοποταμίᾳ: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G3318",
    gloss: "Mesopotamia",
  },
  Μεσοποταμίαν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G3318",
    gloss: "Mesopotamia",
  },
  μέσης: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G3319",
    gloss: "midst",
  },
  μέσον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G3319",
    gloss: "midst",
  },
  μέσος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3319",
    gloss: "midst",
  },
  μέσου: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G3319",
    gloss: "midst",
  },
  μέσῳ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G3319",
    gloss: "midst",
  },
  μεσότοιχον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G3320",
    gloss: "wall",
  },
  μεσουρανήματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G3321",
    gloss: "midair",
  },
  μεσούσης: {
    parse:
      "V-PAP-GSF｜Verb, present, active, Participle, genitive, singular, feminine",
    GN: "G3322",
    gloss: "be in the middle",
  },
  Μεσσίαν: {
    parse: "N-ASM-T｜Noun, accusative, singular, masculine, Title",
    GN: "G3323",
    gloss: "Messiah",
  },
  Μεσσίας: {
    parse: "N-NSM-T｜Noun, nominative, singular, masculine, Title",
    GN: "G3323",
    gloss: "Messiah",
  },
  μεστὴ: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G3324",
    gloss: "full",
  },
  μεστοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3324",
    gloss: "full",
  },
  μεστοὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3324",
    gloss: "full",
  },
  μεστὸν: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G3324",
    gloss: "full",
  },
  μεστόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G3324",
    gloss: "full",
  },
  μεστοὺς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G3324",
    gloss: "full",
  },
  μεμεστωμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G3325",
    gloss: "to fill",
  },
  μεθʼ: { parse: "PREP｜Preposition", GN: "G3326", gloss: "with/after" },
  "μεθ᾿": { parse: "PREP｜Preposition", GN: "G3326", gloss: "with/after" },
  Μεθʼ: { parse: "PREP｜Preposition", GN: "G3326", gloss: "with/after" },
  Μεθ: { parse: "PREP｜Preposition", GN: "G3326", gloss: "with/after" },
  μετʼ: { parse: "PREP｜Preposition", GN: "G3326", gloss: "with/after" },
  μετὰ: { parse: "PREP｜Preposition", GN: "G3326", gloss: "with/after" },
  Μετὰ: { parse: "PREP｜Preposition", GN: "G3326", gloss: "with/after" },
  Μετάβα: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G3327",
    gloss: "to depart",
  },
  μεταβαίνετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G3327",
    gloss: "to depart",
  },
  μεταβὰς: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G3327",
    gloss: "to depart",
  },
  μεταβεβήκαμεν: {
    parse: "V-RAI-1P｜Verb, perfect, active, indicative, first, plural",
    GN: "G3327",
    gloss: "to depart",
  },
  μεταβέβηκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G3327",
    gloss: "to depart",
  },
  μεταβῇ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G3327",
    gloss: "to depart",
  },
  Μετάβηθι: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G3327",
    gloss: "to depart",
  },
  μεταβήσεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G3327",
    gloss: "to depart",
  },
  μετέβη: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G3327",
    gloss: "to depart",
  },
  μεταβαλόμενοι: {
    parse:
      "V-2AMP-NPM｜Verb, 2nd aorist, middle, Participle, nominative, plural, masculine",
    GN: "G3328",
    gloss: "to change mind",
  },
  μετάγεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G3329",
    gloss: "to turn",
  },
  μετάγομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G3329",
    gloss: "to turn",
  },
  μεταδιδόναι: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3330",
    gloss: "to share",
  },
  μεταδιδοὺς: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3330",
    gloss: "to share",
  },
  μεταδότω: {
    parse: "V-2AAM-3S｜Verb, 2nd aorist, active, imperative, third, singular",
    GN: "G3330",
    gloss: "to share",
  },
  μεταδοῦναι: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G3330",
    gloss: "to share",
  },
  μεταδῶ: {
    parse: "V-2AAS-1S｜Verb, 2nd aorist, active, subjunctive, first, singular",
    GN: "G3330",
    gloss: "to share",
  },
  μεταθέσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3331",
    gloss: "removal/change",
  },
  μετάθεσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3331",
    gloss: "removal/change",
  },
  μετάθεσις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3331",
    gloss: "removal/change",
  },
  μετῆρεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3332",
    gloss: "to leave",
  },
  μετακάλεσαι: {
    parse: "V-AMM-2S｜Verb, aorist, middle, imperative, second, singular",
    GN: "G3333",
    gloss: "to summon",
  },
  μετακαλέσομαί: {
    parse: "V-FMI-1S｜Verb, future, middle, indicative, first, singular",
    GN: "G3333",
    gloss: "to summon",
  },
  μετεκαλέσατο: {
    parse: "V-AMI-3S｜Verb, aorist, middle, indicative, third, singular",
    GN: "G3333",
    gloss: "to summon",
  },
  μετακινούμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G3334",
    gloss: "to shift",
  },
  μεταλαβεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G3335",
    gloss: "to partake",
  },
  μεταλαβὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G3335",
    gloss: "to partake",
  },
  μεταλαμβάνει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3335",
    gloss: "to partake",
  },
  μεταλαμβάνειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3335",
    gloss: "to partake",
  },
  μετελάμβανον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G3335",
    gloss: "to partake",
  },
  μετάλημψιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3336",
    gloss: "partaking of",
  },
  μετήλλαξαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G3337",
    gloss: "to exchange",
  },
  μεταμεληθεὶς: {
    parse:
      "V-AOP-NSM｜Verb, aorist, Passive deponent, Participle, nominative, singular, masculine",
    GN: "G3338",
    gloss: "to repent",
  },
  μεταμεληθήσεται: {
    parse:
      "V-FOI-3S｜Verb, future, Passive deponent, indicative, third, singular",
    GN: "G3338",
    gloss: "to repent",
  },
  μεταμέλομαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G3338",
    gloss: "to repent",
  },
  μετεμελήθητε: {
    parse:
      "V-AOI-2P｜Verb, aorist, Passive deponent, indicative, second, plural",
    GN: "G3338",
    gloss: "to repent",
  },
  μετεμελόμην: {
    parse:
      "V-INI-1S｜Verb, imperfect, Middle or Passive deponent, indicative, first, singular",
    GN: "G3338",
    gloss: "to repent",
  },
  μεταμορφούμεθα: {
    parse: "V-PPI-1P｜Verb, present, passive, indicative, first, plural",
    GN: "G3339",
    gloss: "to transform",
  },
  μεταμορφοῦσθε: {
    parse: "V-PPM-2P｜Verb, present, passive, imperative, second, plural",
    GN: "G3339",
    gloss: "to transform",
  },
  μετεμορφώθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G3339",
    gloss: "to transform",
  },
  μετανοεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3340",
    gloss: "to repent",
  },
  μετανοεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G3340",
    gloss: "to repent",
  },
  Μετανοεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G3340",
    gloss: "to repent",
  },
  μετανοῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G3340",
    gloss: "to repent",
  },
  μετανοησάντων: {
    parse:
      "V-AAP-GPM｜Verb, aorist, active, Participle, genitive, plural, masculine",
    GN: "G3340",
    gloss: "to repent",
  },
  μετανοήσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G3340",
    gloss: "to repent",
  },
  Μετανοήσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G3340",
    gloss: "to repent",
  },
  μετανοήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G3340",
    gloss: "to repent",
  },
  μετανοήσῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G3340",
    gloss: "to repent",
  },
  μετανόησον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G3340",
    gloss: "to repent",
  },
  μετανοήσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G3340",
    gloss: "to repent",
  },
  μετανοήσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G3340",
    gloss: "to repent",
  },
  μετανοῆτε: {
    parse: "V-PAS-2P｜Verb, present, active, subjunctive, second, plural",
    GN: "G3340",
    gloss: "to repent",
  },
  μετανοοῦντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G3340",
    gloss: "to repent",
  },
  Μετανοῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G3340",
    gloss: "to repent",
  },
  μετανοῶσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G3340",
    gloss: "to repent",
  },
  μετενόησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G3340",
    gloss: "to repent",
  },
  μετάνοιαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3341",
    gloss: "repentance",
  },
  μετάνοιάν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3341",
    gloss: "repentance",
  },
  μετανοίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3341",
    gloss: "repentance",
  },
  μεταξὺ: {
    parse: "PREP｜Preposition",
    GN: "G3342",
    gloss: "between/meanwhile",
  },
  μεταπεμπόμενος: {
    parse:
      "V-PMP-NSM｜Verb, present, middle, Participle, nominative, singular, masculine",
    GN: "G3343",
    gloss: "to summon",
  },
  μεταπεμφθείς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G3343",
    gloss: "to summon",
  },
  μετάπεμψαι: {
    parse: "V-AMM-2S｜Verb, aorist, middle, imperative, second, singular",
    GN: "G3343",
    gloss: "to summon",
  },
  μεταπεμψάμενος: {
    parse:
      "V-AMP-NSM｜Verb, aorist, middle, Participle, nominative, singular, masculine",
    GN: "G3343",
    gloss: "to summon",
  },
  μεταπέμψασθαί: {
    parse: "V-AMN｜Verb, aorist, middle, Infinitive",
    GN: "G3343",
    gloss: "to summon",
  },
  μεταπέμψηται: {
    parse: "V-AMS-3S｜Verb, aorist, middle, subjunctive, third, singular",
    GN: "G3343",
    gloss: "to summon",
  },
  μετεπέμψασθέ: {
    parse: "V-AMI-2P｜Verb, aorist, middle, indicative, second, plural",
    GN: "G3343",
    gloss: "to summon",
  },
  μετεπέμψατο: {
    parse: "V-AMI-3S｜Verb, aorist, middle, indicative, third, singular",
    GN: "G3343",
    gloss: "to summon",
  },
  μεταστραφήσεται: {
    parse: "V-2FPI-3S｜Verb, 2nd future, passive, indicative, third, singular",
    GN: "G3344",
    gloss: "to change",
  },
  μεταστρέψαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G3344",
    gloss: "to change",
  },
  μετασχηματίζεται: {
    parse: "V-PMI-3S｜Verb, present, middle, indicative, third, singular",
    GN: "G3345",
    gloss: "to transform",
  },
  μετασχηματιζόμενοι: {
    parse:
      "V-PMP-NPM｜Verb, present, middle, Participle, nominative, plural, masculine",
    GN: "G3345",
    gloss: "to transform",
  },
  μετασχηματίζονται: {
    parse: "V-PMI-3P｜Verb, present, middle, indicative, third, plural",
    GN: "G3345",
    gloss: "to transform",
  },
  μετασχηματίσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G3345",
    gloss: "to transform",
  },
  μετεσχημάτισα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G3345",
    gloss: "to transform",
  },
  μετατιθεμένης: {
    parse:
      "V-PPP-GSF｜Verb, present, passive, Participle, genitive, singular, feminine",
    GN: "G3346",
    gloss: "to transport",
  },
  μετατιθέντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G3346",
    gloss: "to transport",
  },
  μετατίθεσθε: {
    parse:
      "V-PEI-2P｜Verb, present, Either middle or passive, indicative, second, plural",
    GN: "G3346",
    gloss: "to transport",
  },
  μετέθηκεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3346",
    gloss: "to transport",
  },
  μετετέθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G3346",
    gloss: "to transport",
  },
  μετετέθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G3346",
    gloss: "to transport",
  },
  μετέπειτα: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3347",
    gloss: "afterward",
  },
  μετέσχεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G3348",
    gloss: "to share",
  },
  μετέσχηκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G3348",
    gloss: "to share",
  },
  μετέχειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3348",
    gloss: "to share",
  },
  μετέχομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G3348",
    gloss: "to share",
  },
  μετέχουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G3348",
    gloss: "to share",
  },
  μετέχω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G3348",
    gloss: "to share",
  },
  μετέχων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3348",
    gloss: "to share",
  },
  μετεωρίζεσθε: {
    parse: "V-PMM-2P｜Verb, present, middle, imperative, second, plural",
    GN: "G3349",
    gloss: "to worry",
  },
  μετοικεσίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3350",
    gloss: "deportation",
  },
  μετοικεσίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3350",
    gloss: "deportation",
  },
  μετοικιῶ: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G3351",
    gloss: "to deport",
  },
  μετῴκισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3351",
    gloss: "to deport",
  },
  μετοχὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3352",
    gloss: "participation",
  },
  μέτοχοι: {
    parse: "A-VPM｜Adjective, Vocative or nominative, plural, masculine",
    GN: "G3353",
    gloss: "partaker",
  },
  μετόχοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G3353",
    gloss: "partaker",
  },
  μετόχους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G3353",
    gloss: "partaker",
  },
  ἐμέτρησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3354",
    gloss: "to measure",
  },
  μετρεῖτε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G3354",
    gloss: "to measure",
  },
  μετρηθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G3354",
    gloss: "to measure",
  },
  μετρήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G3354",
    gloss: "to measure",
  },
  μετρήσῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G3354",
    gloss: "to measure",
  },
  μέτρησον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G3354",
    gloss: "to measure",
  },
  μετροῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G3354",
    gloss: "to measure",
  },
  μετρητὰς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3355",
    gloss: "measure",
  },
  μετριοπαθεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3356",
    gloss: "be gentle",
  },
  μετρίως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3357",
    gloss: "moderately",
  },
  μέτρον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G3358",
    gloss: "measure",
  },
  μέτρου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G3358",
    gloss: "measure",
  },
  μέτρῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G3358",
    gloss: "measure",
  },
  μέτωπον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G3359",
    gloss: "forehead",
  },
  μετώπου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G3359",
    gloss: "forehead",
  },
  μετώπων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G3359",
    gloss: "forehead",
  },
  μέχρι: { parse: "PREP｜Preposition", GN: "G3360", gloss: "until" },
  μέχρις: { parse: "PREP｜Preposition", GN: "G3360", gloss: "until" },
  μή: { parse: "PRT-N｜Particle, Negative", GN: "G3361", gloss: "not" },
  Μή: { parse: "PRT-N｜Particle, Negative", GN: "G3361", gloss: "not" },
  μὴ: { parse: "PRT-N｜Particle, Negative", GN: "G3361", gloss: "not" },
  Μὴ: { parse: "PRT-N｜Particle, Negative", GN: "G3361", gloss: "not" },
  Μηδαμῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3365",
    gloss: "surely not",
  },
  μηδʼ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G3366",
    gloss: "nor",
  },
  μηδὲ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G3366",
    gloss: "nor",
  },
  Μηδὲ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3366",
    gloss: "nor",
  },
  μηδείς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3367",
    gloss: "nothing",
  },
  μηδεὶς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3367",
    gloss: "nothing",
  },
  Μηδεὶς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3367",
    gloss: "nothing",
  },
  μηδεμίαν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G3367",
    gloss: "nothing",
  },
  μηδέν: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G3367",
    gloss: "nothing",
  },
  μηδὲν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G3367",
    gloss: "nothing",
  },
  Μηδὲν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G3367",
    gloss: "nothing",
  },
  μηδένα: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G3367",
    gloss: "nothing",
  },
  Μηδένα: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G3367",
    gloss: "nothing",
  },
  μηδενὶ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G3367",
    gloss: "nothing",
  },
  Μηδενὶ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G3367",
    gloss: "nothing",
  },
  μηδενὸς: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G3367",
    gloss: "nothing",
  },
  μηθὲν: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G3367",
    gloss: "nothing",
  },
  μηδέποτε: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3368",
    gloss: "never",
  },
  μηδέπω: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3369",
    gloss: "not yet",
  },
  Μῆδοι: {
    parse: "N-NPM-LG｜Noun, nominative, plural, masculine, Location Gentilic",
    GN: "G3370",
    gloss: "Medes",
  },
  μηκέτι: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3371",
    gloss: "never again",
  },
  Μηκέτι: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3371",
    gloss: "never again",
  },
  μῆκος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G3372",
    gloss: "length",
  },
  μηκύνηται: {
    parse: "V-PMS-3S｜Verb, present, middle, subjunctive, third, singular",
    GN: "G3373",
    gloss: "to grow",
  },
  μηλωταῖς: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G3374",
    gloss: "sheepskin",
  },
  μὴν: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3376",
    gloss: "month",
  },
  μῆνα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3376",
    gloss: "month",
  },
  μῆνας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3376",
    gloss: "month",
  },
  μηνὶ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3376",
    gloss: "month",
  },
  ἐμήνυσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3377",
    gloss: "to disclose",
  },
  μηνυθείσης: {
    parse:
      "V-APP-GSF｜Verb, aorist, passive, Participle, genitive, singular, feminine",
    GN: "G3377",
    gloss: "to disclose",
  },
  μηνύσαντα: {
    parse:
      "V-AAP-ASM｜Verb, aorist, active, Participle, accusative, singular, masculine",
    GN: "G3377",
    gloss: "to disclose",
  },
  μηνύσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G3377",
    gloss: "to disclose",
  },
  μήποτε: {
    parse: "PRT-I｜Particle, Interrogative",
    GN: "G3379",
    gloss: "lest",
  },
  Μήποτε: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G3379",
    gloss: "lest",
  },
  μήποτέ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G3379",
    gloss: "lest",
  },
  μήπω: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3380",
    gloss: "not yet",
  },
  "μή πως": {
    parse: "CONJ-N｜Conjunction, Negative",
    GN: "G3381",
    gloss: "so that",
  },
  μηρὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3382",
    gloss: "thigh",
  },
  μήτε: {
    parse: "CONJ-N｜Conjunction, Negative",
    GN: "G3383",
    gloss: "neither",
  },
  μητέρα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3384",
    gloss: "mother",
  },
  μητέρας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G3384",
    gloss: "mother",
  },
  μήτηρ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3384",
    gloss: "mother",
  },
  Μήτηρ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3384",
    gloss: "mother",
  },
  μητρί: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3384",
    gloss: "mother",
  },
  μητρὶ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3384",
    gloss: "mother",
  },
  μητρός: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3384",
    gloss: "mother",
  },
  μητρὸς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3384",
    gloss: "mother",
  },
  μήτι: {
    parse: "PRT｜Particle, disjunctive particle",
    GN: "G3385",
    gloss: "surely not",
  },
  Μήτι: {
    parse: "PRT｜Particle, disjunctive particle",
    GN: "G3385",
    gloss: "surely not",
  },
  "μήτι γε": {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3386",
    gloss: "how much more",
  },
  μήτραν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3388",
    gloss: "womb",
  },
  μήτρας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3388",
    gloss: "womb",
  },
  μητρολῴαις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G3389",
    gloss: "matricide",
  },
  μεμιαμμένοις: {
    parse:
      "V-RPP-DPM｜Verb, perfect, passive, Participle, dative, plural, masculine",
    GN: "G3392",
    gloss: "to stain",
  },
  μεμίανται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G3392",
    gloss: "to stain",
  },
  μιαίνουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G3392",
    gloss: "to stain",
  },
  μιανθῶσιν: {
    parse: "V-APS-3P｜Verb, aorist, passive, subjunctive, third, plural",
    GN: "G3392",
    gloss: "to stain",
  },
  μιάσματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G3393",
    gloss: "defilement",
  },
  μιασμοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3394",
    gloss: "defilement",
  },
  μίγμα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G3395",
    gloss: "mixture",
  },
  ἔμιξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3396",
    gloss: "to mix",
  },
  μεμιγμένα: {
    parse:
      "V-RPP-NPN｜Verb, perfect, passive, Participle, nominative, plural, neuter",
    GN: "G3396",
    gloss: "to mix",
  },
  μεμιγμένην: {
    parse:
      "V-RPP-ASF｜Verb, perfect, passive, Participle, accusative, singular, feminine",
    GN: "G3396",
    gloss: "to mix",
  },
  μεμιγμένον: {
    parse:
      "V-RPP-ASM｜Verb, perfect, passive, Participle, accusative, singular, masculine",
    GN: "G3396",
    gloss: "to mix",
  },
  μικρὰ: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G3398",
    gloss: "small",
  },
  μικρὰν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G3398",
    gloss: "small",
  },
  μικροὶ: {
    parse: "A-VPM｜Adjective, Vocative or nominative, plural, masculine",
    GN: "G3398",
    gloss: "small",
  },
  μικρόν: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G3398",
    gloss: "small",
  },
  μικρὸν: {
    parse: "A-VSN｜Adjective, Vocative or nominative, singular, neuter",
    GN: "G3398",
    gloss: "small",
  },
  Μικρὸν: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G3398",
    gloss: "small",
  },
  μικρὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3398",
    gloss: "small",
  },
  μικρότερον: {
    parse: "A-NSN-C｜Adjective, nominative, singular, neuter, Comparative",
    GN: "G3398",
    gloss: "small",
  },
  μικρότερος: {
    parse: "A-NSM-C｜Adjective, nominative, singular, masculine, Comparative",
    GN: "G3398",
    gloss: "small",
  },
  μικροῦ: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G3398",
    gloss: "small",
  },
  μικρούς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G3398",
    gloss: "small",
  },
  μικροὺς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G3398",
    gloss: "small",
  },
  μικρῷ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G3398",
    gloss: "small",
  },
  μικρῶν: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G3398",
    gloss: "small",
  },
  Μίλητον: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G3399",
    gloss: "Miletus",
  },
  Μιλήτου: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G3399",
    gloss: "Miletus",
  },
  Μιλήτῳ: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G3399",
    gloss: "Miletus",
  },
  μίλιον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G3400",
    gloss: "mile",
  },
  μιμεῖσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G3401",
    gloss: "to imitate",
  },
  μιμεῖσθε: {
    parse:
      "V-PNM-2P｜Verb, present, Middle or Passive deponent, imperative, second, plural",
    GN: "G3401",
    gloss: "to imitate",
  },
  μιμοῦ: {
    parse:
      "V-PNM-2S｜Verb, present, Middle or Passive deponent, imperative, second, singular",
    GN: "G3401",
    gloss: "to imitate",
  },
  μιμηταί: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3402",
    gloss: "imitator",
  },
  μιμηταὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3402",
    gloss: "imitator",
  },
  ἐμνήσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G3403",
    gloss: "to remember",
  },
  ἐμνήσθημεν: {
    parse: "V-API-1P｜Verb, aorist, passive, indicative, first, plural",
    GN: "G3403",
    gloss: "to remember",
  },
  ἐμνήσθην: {
    parse: "V-API-1S｜Verb, aorist, passive, indicative, first, singular",
    GN: "G3403",
    gloss: "to remember",
  },
  ἐμνήσθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G3403",
    gloss: "to remember",
  },
  μεμνημένος: {
    parse:
      "V-RMP-NSM｜Verb, perfect, middle, Participle, nominative, singular, masculine",
    GN: "G3403",
    gloss: "to remember",
  },
  μέμνησθε: {
    parse: "V-RMI-2P｜Verb, perfect, middle, indicative, second, plural",
    GN: "G3403",
    gloss: "to remember",
  },
  μιμνῄσκεσθε: {
    parse:
      "V-PNM-2P｜Verb, present, Middle or Passive deponent, imperative, second, plural",
    GN: "G3403",
    gloss: "to remember",
  },
  μιμνῄσκῃ: {
    parse:
      "V-PNI-2S｜Verb, present, Middle or Passive deponent, indicative, second, singular",
    GN: "G3403",
    gloss: "to remember",
  },
  μνησθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G3403",
    gloss: "to remember",
  },
  μνησθῇς: {
    parse: "V-APS-2S｜Verb, aorist, passive, subjunctive, second, singular",
    GN: "G3403",
    gloss: "to remember",
  },
  μνησθήσομαι: {
    parse: "V-FPI-1S｜Verb, future, passive, indicative, first, singular",
    GN: "G3403",
    gloss: "to remember",
  },
  μνήσθητε: {
    parse: "V-APM-2P｜Verb, aorist, passive, imperative, second, plural",
    GN: "G3403",
    gloss: "to remember",
  },
  μνήσθητι: {
    parse: "V-APM-2S｜Verb, aorist, passive, imperative, second, singular",
    GN: "G3403",
    gloss: "to remember",
  },
  μνήσθητί: {
    parse: "V-APM-2S｜Verb, aorist, passive, imperative, second, singular",
    GN: "G3403",
    gloss: "to remember",
  },
  μνησθῶ: {
    parse: "V-APS-1S｜Verb, aorist, passive, subjunctive, first, singular",
    GN: "G3403",
    gloss: "to remember",
  },
  ἐμίσησα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G3404",
    gloss: "to hate",
  },
  Ἐμίσησάν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G3404",
    gloss: "to hate",
  },
  ἐμίσησας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G3404",
    gloss: "to hate",
  },
  ἐμίσησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3404",
    gloss: "to hate",
  },
  ἐμίσουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G3404",
    gloss: "to hate",
  },
  μεμισήκασιν: {
    parse: "V-RAI-3P｜Verb, perfect, active, indicative, third, plural",
    GN: "G3404",
    gloss: "to hate",
  },
  μεμίσηκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G3404",
    gloss: "to hate",
  },
  μεμισημένου: {
    parse:
      "V-RPP-GSN｜Verb, perfect, passive, Participle, genitive, singular, neuter",
    GN: "G3404",
    gloss: "to hate",
  },
  μισεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3404",
    gloss: "to hate",
  },
  μισεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3404",
    gloss: "to hate",
  },
  μισεῖς: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G3404",
    gloss: "to hate",
  },
  μισῇ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G3404",
    gloss: "to hate",
  },
  μισήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G3404",
    gloss: "to hate",
  },
  μισήσεις: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G3404",
    gloss: "to hate",
  },
  μισήσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G3404",
    gloss: "to hate",
  },
  μισήσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G3404",
    gloss: "to hate",
  },
  μισούμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G3404",
    gloss: "to hate",
  },
  μισοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G3404",
    gloss: "to hate",
  },
  μισούντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G3404",
    gloss: "to hate",
  },
  μισοῦσιν: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G3404",
    gloss: "to hate",
  },
  μισῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G3404",
    gloss: "to hate",
  },
  μισῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3404",
    gloss: "to hate",
  },
  μισθαποδοσίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3405",
    gloss: "recompense",
  },
  μισθαποδότης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3406",
    gloss: "rewarder",
  },
  μίσθιοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3407",
    gloss: "hired worker",
  },
  μισθίων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G3407",
    gloss: "hired worker",
  },
  μισθὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3408",
    gloss: "wage",
  },
  μισθός: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3408",
    gloss: "wage",
  },
  μισθὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3408",
    gloss: "wage",
  },
  μισθοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3408",
    gloss: "wage",
  },
  ἐμισθώσατο: {
    parse: "V-AMI-3S｜Verb, aorist, middle, indicative, third, singular",
    GN: "G3409",
    gloss: "to hire",
  },
  μισθώσασθαι: {
    parse: "V-AMN｜Verb, aorist, middle, Infinitive",
    GN: "G3409",
    gloss: "to hire",
  },
  μισθώματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G3410",
    gloss: "rented home",
  },
  μισθωτός: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3411",
    gloss: "hired worker",
  },
  μισθωτὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3411",
    gloss: "hired worker",
  },
  μισθωτῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G3411",
    gloss: "hired worker",
  },
  Μιτυλήνην: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G3412",
    gloss: "Mitylene",
  },
  Μιχαὴλ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G3413",
    gloss: "Michael",
  },
  μνᾶ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3414",
    gloss: "mina",
  },
  μνᾶν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3414",
    gloss: "mina",
  },
  μνᾶς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G3414",
    gloss: "mina",
  },
  Μνάσωνί: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G3416",
    gloss: "Mnason",
  },
  μνείᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3417",
    gloss: "remembrance",
  },
  μνείαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3417",
    gloss: "remembrance",
  },
  μνῆμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G3418",
    gloss: "tomb",
  },
  μνήμασιν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G3418",
    gloss: "tomb",
  },
  μνήματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G3418",
    gloss: "tomb",
  },
  μνημεῖα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G3419",
    gloss: "grave",
  },
  μνημείοις: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G3419",
    gloss: "grave",
  },
  μνημεῖον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G3419",
    gloss: "grave",
  },
  μνημείου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G3419",
    gloss: "grave",
  },
  μνημείῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G3419",
    gloss: "grave",
  },
  μνημείων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G3419",
    gloss: "grave",
  },
  μνήμην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3420",
    gloss: "remembrance",
  },
  ἐμνημόνευον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G3421",
    gloss: "to remember",
  },
  ἐμνημόνευσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3421",
    gloss: "to remember",
  },
  μνημόνευε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G3421",
    gloss: "to remember",
  },
  Μνημόνευε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G3421",
    gloss: "to remember",
  },
  μνημονεύει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3421",
    gloss: "to remember",
  },
  μνημονεύειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3421",
    gloss: "to remember",
  },
  μνημονεύετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G3421",
    gloss: "to remember",
  },
  Μνημονεύετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G3421",
    gloss: "to remember",
  },
  μνημονεύετέ: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G3421",
    gloss: "to remember",
  },
  μνημονεύητε: {
    parse: "V-PAS-2P｜Verb, present, active, subjunctive, second, plural",
    GN: "G3421",
    gloss: "to remember",
  },
  μνημονεύοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G3421",
    gloss: "to remember",
  },
  μνημονεύωμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G3421",
    gloss: "to remember",
  },
  μνημόσυνον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G3422",
    gloss: "memorial",
  },
  ἐμνηστευμένῃ: {
    parse:
      "V-RPP-DSF｜Verb, perfect, passive, Participle, dative, singular, feminine",
    GN: "G3423",
    gloss: "to betroth",
  },
  ἐμνηστευμένην: {
    parse:
      "V-RPP-ASF｜Verb, perfect, passive, Participle, accusative, singular, feminine",
    GN: "G3423",
    gloss: "to betroth",
  },
  μνηστευθείσης: {
    parse:
      "V-APP-GSF｜Verb, aorist, passive, Participle, genitive, singular, feminine",
    GN: "G3423",
    gloss: "to betroth",
  },
  μογιλάλον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G3424",
    gloss: "hardly talking",
  },
  μόγις: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3425",
    gloss: "hardly",
  },
  μόδιον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3426",
    gloss: "bucket",
  },
  μοιχαλίδα: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G3428",
    gloss: "adulterous",
  },
  μοιχαλίδες: {
    // or nominative
    parse: "A-VPF｜Adjective, Vocative, plural, feminine",
    GN: "G3428",
    gloss: "adulterous",
  },
  μοιχαλίδι: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G3428",
    gloss: "adulterous",
  },
  μοιχαλίδος: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G3428",
    gloss: "adulterous",
  },
  μοιχαλὶς: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G3428",
    gloss: "adulterous",
  },
  μοιχᾶται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G3429",
    gloss: "to commit adultery",
  },
  μοιχείᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3430",
    gloss: "adultery",
  },
  μοιχεῖαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G3430",
    gloss: "adultery",
  },
  ἐμοίχευσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3431",
    gloss: "to commit adultery",
  },
  μοιχεύει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3431",
    gloss: "to commit adultery",
  },
  μοιχεύειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3431",
    gloss: "to commit adultery",
  },
  μοιχεύεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G3431",
    gloss: "to commit adultery",
  },
  μοιχευθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G3431",
    gloss: "to commit adultery",
  },
  μοιχευομένη: {
    parse:
      "V-PPP-NSF｜Verb, present, passive, Participle, nominative, singular, feminine",
    GN: "G3431",
    gloss: "to commit adultery",
  },
  μοιχεύοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G3431",
    gloss: "to commit adultery",
  },
  μοιχεύσεις: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G3431",
    gloss: "to commit adultery",
  },
  μοιχεύσῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G3431",
    gloss: "to commit adultery",
  },
  μοιχοί: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3432",
    gloss: "adulterer",
  },
  μοιχοὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3432",
    gloss: "adulterer",
  },
  μοιχοὺς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3432",
    gloss: "adulterer",
  },
  μόλις: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3433",
    gloss: "hardly",
  },
  Μολὸχ: {
    parse: "N-GSM-T｜Noun, genitive, singular, masculine, Title",
    GN: "G3434",
    gloss: "Moloch",
  },
  ἐμόλυναν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G3435",
    gloss: "to defile",
  },
  ἐμολύνθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G3435",
    gloss: "to defile",
  },
  μολύνεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G3435",
    gloss: "to defile",
  },
  μολυσμοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3436",
    gloss: "defilement",
  },
  μομφήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3437",
    gloss: "complaint",
  },
  μοναὶ: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G3438",
    gloss: "abode",
  },
  μονὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3438",
    gloss: "abode",
  },
  μονογενῆ: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G3439",
    gloss: "unique",
  },
  μονογενὴς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3439",
    gloss: "unique",
  },
  μονογενής: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3439",
    gloss: "unique",
  },
  μονογενοῦς: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G3439",
    gloss: "unique",
  },
  μόνον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G3441",
    gloss: "alone",
  },
  μόνα: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G3441",
    gloss: "alone",
  },
  μόνας: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G3441",
    gloss: "alone",
  },
  μόνην: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G3441",
    gloss: "alone",
  },
  μόνοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3441",
    gloss: "alone",
  },
  μόνοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G3441",
    gloss: "alone",
  },
  Μόνον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G3441",
    gloss: "alone",
  },
  μόνος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3441",
    gloss: "alone",
  },
  μόνου: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G3441",
    gloss: "alone",
  },
  μόνους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G3441",
    gloss: "alone",
  },
  μόνῳ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G3441",
    gloss: "alone",
  },
  μονόφθαλμον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G3442",
    gloss: "one-eyed",
  },
  μεμονωμένη: {
    parse:
      "V-RPP-NSF｜Verb, perfect, passive, Participle, nominative, singular, feminine",
    GN: "G3443",
    gloss: "to leave alone",
  },
  μορφῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3444",
    gloss: "form",
  },
  μορφὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3444",
    gloss: "form",
  },
  μορφωθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G3445",
    gloss: "to form",
  },
  μόρφωσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3446",
    gloss: "form",
  },
  ἐμοσχοποίησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G3447",
    gloss: "to make a calf",
  },
  μόσχον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3448",
    gloss: "calf",
  },
  μόσχῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3448",
    gloss: "calf",
  },
  μόσχων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G3448",
    gloss: "calf",
  },
  μόχθον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3449",
    gloss: "toil",
  },
  μόχθῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3449",
    gloss: "toil",
  },
  μουσικῶν: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G3451",
    gloss: "musician",
  },
  μυελῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G3452",
    gloss: "marrow",
  },
  μεμύημαι: {
    parse: "V-RPI-1S｜Verb, perfect, passive, indicative, first, singular",
    GN: "G3453",
    gloss: "to initiate",
  },
  μύθοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G3454",
    gloss: "myth",
  },
  μύθους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3454",
    gloss: "myth",
  },
  μυκᾶται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G3455",
    gloss: "to roar",
  },
  μυκτηρίζεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G3456",
    gloss: "to mock",
  },
  μυλικὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3457",
    gloss: "millstone",
  },
  μύλος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3458",
    gloss: "millstone",
  },
  μύλου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3458",
    gloss: "millstone",
  },
  μύλῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3458",
    gloss: "millstone",
  },
  Μύρα: {
    parse: "N-APN-L｜Noun, accusative, plural, neuter, Location",
    GN: "G3460",
    gloss: "Myra",
  },
  μυριάδας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G3461",
    gloss: "myriad",
  },
  μυριάδες: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G3461",
    gloss: "myriad",
  },
  μυριάδων: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G3461",
    gloss: "myriad",
  },
  μυριάσιν: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G3461",
    gloss: "myriad",
  },
  μυρίσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G3462",
    gloss: "to anoint",
  },
  μυρίους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G3463",
    gloss: "myriad",
  },
  μυρίων: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G3463",
    gloss: "myriad",
  },
  μύρα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G3464",
    gloss: "ointment",
  },
  μύρον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G3464",
    gloss: "ointment",
  },
  μύρου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G3464",
    gloss: "ointment",
  },
  μύρῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G3464",
    gloss: "ointment",
  },
  Μυσίαν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G3465",
    gloss: "Mysia",
  },
  μυστήρια: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G3466",
    gloss: "mystery",
  },
  μυστήριον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G3466",
    gloss: "mystery",
  },
  μυστηρίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G3466",
    gloss: "mystery",
  },
  μυστηρίῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G3466",
    gloss: "mystery",
  },
  μυστηρίων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G3466",
    gloss: "mystery",
  },
  μυωπάζων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3467",
    gloss: "be nearsighted",
  },
  μώλωπι: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3468",
    gloss: "wound",
  },
  μωμηθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G3469",
    gloss: "to criticize",
  },
  μωμήσηται: {
    parse:
      "V-ADS-3S｜Verb, aorist, Middle deponent, subjunctive, third, singular",
    GN: "G3469",
    gloss: "to criticize",
  },
  μῶμοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3470",
    gloss: "blemish",
  },
  ἐμώρανεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3471",
    gloss: "be foolish",
  },
  ἐμωράνθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G3471",
    gloss: "be foolish",
  },
  μωρανθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G3471",
    gloss: "be foolish",
  },
  μωρία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3472",
    gloss: "foolishness",
  },
  μωρίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3472",
    gloss: "foolishness",
  },
  μωρίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3472",
    gloss: "foolishness",
  },
  μωρολογία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3473",
    gloss: "foolish talk",
  },
  μωρὰ: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G3474",
    gloss: "foolish",
  },
  μωραὶ: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G3474",
    gloss: "foolish",
  },
  μωρὰς: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G3474",
    gloss: "foolish",
  },
  Μωρέ: {
    parse: "A-VSM｜Adjective, Vocative or nominative, singular, masculine",
    GN: "G3474",
    gloss: "foolish",
  },
  μωροὶ: {
    parse: "A-VPM｜Adjective, Vocative or nominative, plural, masculine",
    GN: "G3474",
    gloss: "foolish",
  },
  μωρὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G3474",
    gloss: "foolish",
  },
  μωρὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3474",
    gloss: "foolish",
  },
  μωρῷ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G3474",
    gloss: "foolish",
  },
  Μωϋσέα: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G3475",
    gloss: "Moses",
  },
  Μωϋσεῖ: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G3475",
    gloss: "Moses",
  },
  Μωϋσέως: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G3475",
    gloss: "Moses",
  },
  Μωϋσῇ: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G3475",
    gloss: "Moses",
  },
  Μωϋσῆν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G3475",
    gloss: "Moses",
  },
  Μωϋσῆς: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G3475",
    gloss: "Moses",
  },
  Ναασσών: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G3476",
    gloss: "Nahshon",
  },
  Ναασσὼν: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G3476",
    gloss: "Nahshon",
  },
  Ναγγαὶ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G3477",
    gloss: "Naggai",
  },
  Ναζαρά: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G3478",
    gloss: "Nazareth",
  },
  Ναζαρὰ: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G3478",
    gloss: "Nazareth",
  },
  Ναζαρέθ: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G3478",
    gloss: "Nazareth",
  },
  Ναζαρὲθ: {
    parse: "N-NSF-L｜Noun, nominative, singular, feminine, Location",
    GN: "G3478",
    gloss: "Nazareth",
  },
  Ναζαρέτ: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G3478",
    gloss: "Nazareth",
  },
  Ναζαρὲτ: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G3478",
    gloss: "Nazareth",
  },
  Ναζαρηνέ: {
    parse: "A-VSM｜Adjective, Vocative or nominative, singular, masculine",
    GN: "G3479",
    gloss: "Nazareth",
  },
  Ναζαρηνὸν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G3479",
    gloss: "Nazareth",
  },
  Ναζαρηνός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3479",
    gloss: "Nazareth",
  },
  Ναζαρηνοῦ: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G3479",
    gloss: "Nazareth",
  },
  Ναζωραῖον: {
    parse: "N-ASM-LG｜Noun, accusative, singular, masculine, Location Gentilic",
    GN: "G3480",
    gloss: "Nazareth",
  },
  Ναζωραῖος: {
    parse: "N-NSM-LG｜Noun, nominative, singular, masculine, Location Gentilic",
    GN: "G3480",
    gloss: "Nazareth",
  },
  Ναζωραίου: {
    parse: "N-GSM-LG｜Noun, genitive, singular, masculine, Location Gentilic",
    GN: "G3480",
    gloss: "Nazareth",
  },
  Ναζωραίων: {
    parse: "N-GPM-LG｜Noun, genitive, plural, masculine, Location Gentilic",
    GN: "G3480",
    gloss: "Nazareth",
  },
  Ναθὰμ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G3481",
    gloss: "Nathan",
  },
  Ναθαναὴλ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G3482",
    gloss: "Nathanael",
  },
  Ναθαναήλ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G3482",
    gloss: "Nathanael",
  },
  ναί: {
    parse: "PRT｜Particle, disjunctive particle",
    GN: "G3483",
    gloss: "yes",
  },
  Ναί: {
    parse: "PRT｜Particle, disjunctive particle",
    GN: "G3483",
    gloss: "yes",
  },
  ναὶ: {
    parse: "PRT｜Particle, disjunctive particle",
    GN: "G3483",
    gloss: "yes",
  },
  Ναὶ: {
    parse: "PRT｜Particle, disjunctive particle",
    GN: "G3483",
    gloss: "yes",
  },
  Ναΐν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G3484",
    gloss: "Nain",
  },
  ναοῖς: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G3485",
    gloss: "temple",
  },
  ναὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3485",
    gloss: "temple",
  },
  ναὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3485",
    gloss: "temple",
  },
  ναοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3485",
    gloss: "temple",
  },
  ναοὺς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3485",
    gloss: "temple",
  },
  ναῷ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3485",
    gloss: "temple",
  },
  Ναοὺμ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G3486",
    gloss: "Nahum",
  },
  νάρδου: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3487",
    gloss: "nard",
  },
  Ναρκίσσου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G3488",
    gloss: "Narcissus",
  },
  ἐναυάγησα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G3489",
    gloss: "be shipwrecked",
  },
  ἐναυάγησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G3489",
    gloss: "be shipwrecked",
  },
  ναυκλήρῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3490",
    gloss: "captain",
  },
  ναῦν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3491",
    gloss: "ship",
  },
  ναῦται: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3492",
    gloss: "sailor",
  },
  ναυτῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G3492",
    gloss: "sailor",
  },
  Ναχὼρ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G3493",
    gloss: "Nahor",
  },
  νεανίαν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3494",
    gloss: "young man",
  },
  νεανίας: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3494",
    gloss: "young man",
  },
  νεανίου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3494",
    gloss: "young man",
  },
  Νεανίσκε: {
    // or nominative
    parse: "N-VSM｜Noun, Vocative, singular, masculine",
    GN: "G3495",
    gloss: "young man",
  },
  νεανίσκοι: {
    parse: "N-VPM｜Noun, Vocative or nominative, plural, masculine",
    GN: "G3495",
    gloss: "young man",
  },
  νεανίσκον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3495",
    gloss: "young man",
  },
  νεανίσκος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3495",
    gloss: "young man",
  },
  Ναιμὰν: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G3497",
    gloss: "Naaman",
  },
  νεκρά: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G3498",
    gloss: "dead",
  },
  νεκράν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G3498",
    gloss: "dead",
  },
  νεκροί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3498",
    gloss: "dead",
  },
  νεκροὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3498",
    gloss: "dead",
  },
  νεκροῖς: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G3498",
    gloss: "dead",
  },
  νεκρόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G3498",
    gloss: "dead",
  },
  νεκρὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G3498",
    gloss: "dead",
  },
  νεκρός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3498",
    gloss: "dead",
  },
  νεκρὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3498",
    gloss: "dead",
  },
  νεκροῦ: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G3498",
    gloss: "dead",
  },
  νεκρούς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G3498",
    gloss: "dead",
  },
  νεκροὺς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G3498",
    gloss: "dead",
  },
  νεκρῶν: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G3498",
    gloss: "dead",
  },
  Νεκρώσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G3499",
    gloss: "to put to death",
  },
  νενεκρωμένον: {
    parse:
      "V-RPP-ASN｜Verb, perfect, passive, Participle, accusative, singular, neuter",
    GN: "G3499",
    gloss: "to put to death",
  },
  νενεκρωμένου: {
    parse:
      "V-RPP-GSM｜Verb, perfect, passive, Participle, genitive, singular, masculine",
    GN: "G3499",
    gloss: "to put to death",
  },
  νέκρωσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3500",
    gloss: "death",
  },
  Νέαν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G3501",
    gloss: "new",
  },
  νέας: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G3501",
    gloss: "new",
  },
  νέον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G3501",
    gloss: "new",
  },
  νέος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3501",
    gloss: "new",
  },
  νεωτέρας: {
    parse: "A-APF-C｜Adjective, accusative, plural, feminine, Comparative",
    GN: "G3501",
    gloss: "new",
  },
  νεώτεροι: {
    parse:
      "A-VPM-C｜Adjective, Vocative or nominative, plural, masculine, Comparative",
    GN: "G3501",
    gloss: "new",
  },
  νεώτερος: {
    parse: "A-NSM-C｜Adjective, nominative, singular, masculine, Comparative",
    GN: "G3501",
    gloss: "new",
  },
  νεωτέρους: {
    parse: "A-APM-C｜Adjective, accusative, plural, masculine, Comparative",
    GN: "G3501",
    gloss: "new",
  },
  νοσσοὺς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3502",
    gloss: "nestling",
  },
  νεότητος: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3503",
    gloss: "youth",
  },
  νεότητός: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3503",
    gloss: "youth",
  },
  νεόφυτον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G3504",
    gloss: "new convert",
  },
  νεύει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3506",
    gloss: "to motion",
  },
  νεύσαντος: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G3506",
    gloss: "to motion",
  },
  νεφέλαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G3507",
    gloss: "cloud",
  },
  νεφέλαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G3507",
    gloss: "cloud",
  },
  νεφέλῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3507",
    gloss: "cloud",
  },
  νεφέλη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3507",
    gloss: "cloud",
  },
  νεφέλην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3507",
    gloss: "cloud",
  },
  νεφέλης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3507",
    gloss: "cloud",
  },
  νεφελῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G3507",
    gloss: "cloud",
  },
  Νεφθαλείμ: {
    parse: "N-GSM-PG｜Noun, genitive, singular, masculine, Person Gentilic",
    GN: "G3508",
    gloss: "Naphtali",
  },
  Νεφθαλίμ: {
    parse: "N-GSM-PG｜Noun, genitive, singular, masculine, Person Gentilic",
    GN: "G3508",
    gloss: "Naphtali",
  },
  Νεφθαλὶμ: {
    parse: "N-GSM-PG｜Noun, genitive, singular, masculine, Person Gentilic",
    GN: "G3508",
    gloss: "Naphtali",
  },
  νέφος: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G3509",
    gloss: "cloud",
  },
  νεφροὺς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3510",
    gloss: "mind",
  },
  νεωκόρον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3511",
    gloss: "temple guard",
  },
  νεωτερικὰς: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G3512",
    gloss: "youthful",
  },
  νὴ: {
    parse: "PRT｜Particle, disjunctive particle",
    GN: "G3513",
    gloss: "as surely as",
  },
  νήθει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3514",
    gloss: "to spin",
  },
  νήθουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G3514",
    gloss: "to spin",
  },
  νηπιάζετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G3515",
    gloss: "be childlike",
  },
  νήπιοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3516",
    gloss: "child",
  },
  νηπίοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G3516",
    gloss: "child",
  },
  νήπιος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3516",
    gloss: "child",
  },
  νήπιός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3516",
    gloss: "child",
  },
  νηπίου: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G3516",
    gloss: "child",
  },
  νηπίων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G3516",
    gloss: "child",
  },
  Νηρέα: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G3517",
    gloss: "Nereus",
  },
  Νηρὶ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G3518",
    gloss: "Neri",
  },
  νησίον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G3519",
    gloss: "small island",
  },
  νῆσον: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3520",
    gloss: "island",
  },
  νῆσος: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3520",
    gloss: "island",
  },
  νήσου: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3520",
    gloss: "island",
  },
  νήσῳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3520",
    gloss: "island",
  },
  νηστείαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G3521",
    gloss: "fasting",
  },
  νηστείαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3521",
    gloss: "fasting",
  },
  νηστειῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G3521",
    gloss: "fasting",
  },
  νηστείᾳ: {
    parse: "N-GPF｜Noun, dative, singular, feminine",
    GN: "G3521",
    gloss: "fasting",
  },
  νηστεύειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3522",
    gloss: "to fast",
  },
  νηστεύητε: {
    parse: "V-PAS-2P｜Verb, present, active, subjunctive, second, plural",
    GN: "G3522",
    gloss: "to fast",
  },
  νηστεύομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G3522",
    gloss: "to fast",
  },
  νηστεύοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G3522",
    gloss: "to fast",
  },
  νηστευόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G3522",
    gloss: "to fast",
  },
  νηστεύουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G3522",
    gloss: "to fast",
  },
  νηστεῦσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G3522",
    gloss: "to fast",
  },
  νηστεύσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G3522",
    gloss: "to fast",
  },
  νηστεύσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G3522",
    gloss: "to fast",
  },
  νηστεύσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G3522",
    gloss: "to fast",
  },
  νηστεύω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G3522",
    gloss: "to fast",
  },
  νηστεύων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3522",
    gloss: "to fast",
  },
  νήστεις: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G3523",
    gloss: "fasting",
  },
  νηφάλιον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G3524",
    gloss: "sober",
  },
  νηφαλίους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G3524",
    gloss: "sober",
  },
  νῆφε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G3525",
    gloss: "be sober",
  },
  νήφοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G3525",
    gloss: "be sober",
  },
  νήφωμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G3525",
    gloss: "be sober",
  },
  νήψατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G3525",
    gloss: "be sober",
  },
  Νίγερ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G3526",
    gloss: "Niger",
  },
  Νικάνορα: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G3527",
    gloss: "Nicanor",
  },
  ἐνίκησα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G3528",
    gloss: "to conquer",
  },
  ἐνίκησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G3528",
    gloss: "to conquer",
  },
  ἐνίκησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3528",
    gloss: "to conquer",
  },
  νενίκηκα: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G3528",
    gloss: "to conquer",
  },
  νενικήκατε: {
    parse: "V-RAI-2P｜Verb, perfect, active, indicative, second, plural",
    GN: "G3528",
    gloss: "to conquer",
  },
  νικᾷ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3528",
    gloss: "to conquer",
  },
  νίκα: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G3528",
    gloss: "to conquer",
  },
  νικῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G3528",
    gloss: "to conquer",
  },
  νικήσασα: {
    parse:
      "V-AAP-NSF｜Verb, aorist, active, Participle, nominative, singular, feminine",
    GN: "G3528",
    gloss: "to conquer",
  },
  νικήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G3528",
    gloss: "to conquer",
  },
  νικήσεις: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G3528",
    gloss: "to conquer",
  },
  νικήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G3528",
    gloss: "to conquer",
  },
  νικῶ: {
    parse: "V-PPM-2S｜Verb, present, passive, imperative, second, singular",
    GN: "G3528",
    gloss: "to conquer",
  },
  νικῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3528",
    gloss: "to conquer",
  },
  νικῶντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G3528",
    gloss: "to conquer",
  },
  νικῶντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G3528",
    gloss: "to conquer",
  },
  νίκη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3529",
    gloss: "victory",
  },
  Νικόδημος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G3530",
    gloss: "Nicodemus",
  },
  Νικολαϊτῶν: {
    parse: "N-GPM-LG｜Noun, genitive, plural, masculine, Location Gentilic",
    GN: "G3531",
    gloss: "Nicolaitan",
  },
  Νικόλαον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G3532",
    gloss: "Nicolaus",
  },
  Νικόπολιν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G3533",
    gloss: "Nicopolis",
  },
  νῖκος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G3534",
    gloss: "victory",
  },
  Νινευῖται: {
    parse: "N-NPM-LG｜Noun, nominative, plural, masculine, Location Gentilic",
    GN: "G3536",
    gloss: "Nineveh",
  },
  Νινευίταις: {
    parse: "N-DPM-LG｜Noun, dative, plural, masculine, Location Gentilic",
    GN: "G3536",
    gloss: "Nineveh",
  },
  νιπτῆρα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3537",
    gloss: "wash basin",
  },
  ἔνιψα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G3538",
    gloss: "to wash",
  },
  ἐνιψάμην: {
    parse: "V-AMI-1S｜Verb, aorist, middle, indicative, first, singular",
    GN: "G3538",
    gloss: "to wash",
  },
  ἐνίψατο: {
    parse: "V-AMI-3S｜Verb, aorist, middle, indicative, third, singular",
    GN: "G3538",
    gloss: "to wash",
  },
  ἔνιψεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3538",
    gloss: "to wash",
  },
  νίπτειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3538",
    gloss: "to wash",
  },
  νίπτεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G3538",
    gloss: "to wash",
  },
  νίπτονται: {
    parse: "V-PMI-3P｜Verb, present, middle, indicative, third, plural",
    GN: "G3538",
    gloss: "to wash",
  },
  νίψαι: {
    parse: "V-AMM-2S｜Verb, aorist, middle, imperative, second, singular",
    GN: "G3538",
    gloss: "to wash",
  },
  νιψάμενος: {
    parse:
      "V-AMP-NSM｜Verb, aorist, middle, Participle, nominative, singular, masculine",
    GN: "G3538",
    gloss: "to wash",
  },
  νίψασθαι: {
    parse: "V-AMN｜Verb, aorist, middle, Infinitive",
    GN: "G3538",
    gloss: "to wash",
  },
  νίψῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G3538",
    gloss: "to wash",
  },
  νίψω: {
    parse: "V-AAS-1S｜Verb, aorist, active, subjunctive, first, singular",
    GN: "G3538",
    gloss: "to wash",
  },
  νίψωνται: {
    parse: "V-AMS-3P｜Verb, aorist, middle, subjunctive, third, plural",
    GN: "G3538",
    gloss: "to wash",
  },
  νόει: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G3539",
    gloss: "to understand",
  },
  νοεῖτε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G3539",
    gloss: "to understand",
  },
  νοείτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G3539",
    gloss: "to understand",
  },
  νοῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G3539",
    gloss: "to understand",
  },
  νοήσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G3539",
    gloss: "to understand",
  },
  νοοῦμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G3539",
    gloss: "to understand",
  },
  νοούμενα: {
    parse:
      "V-PPP-NPN｜Verb, present, passive, Participle, nominative, plural, neuter",
    GN: "G3539",
    gloss: "to understand",
  },
  νοοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G3539",
    gloss: "to understand",
  },
  νόημα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G3540",
    gloss: "mind/thought",
  },
  νοήματα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G3540",
    gloss: "mind/thought",
  },
  νόθοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3541",
    gloss: "illegitimate",
  },
  νομὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3542",
    gloss: "pasture",
  },
  ἐνόμιζεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G3543",
    gloss: "to think",
  },
  ἐνομίζετο: {
    parse: "V-IPI-3S｜Verb, imperfect, passive, indicative, third, singular",
    GN: "G3543",
    gloss: "to think",
  },
  ἐνομίζομεν: {
    parse: "V-IAI-1P｜Verb, imperfect, active, indicative, first, plural",
    GN: "G3543",
    gloss: "to think",
  },
  ἐνόμιζον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G3543",
    gloss: "to think",
  },
  ἐνόμισαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G3543",
    gloss: "to think",
  },
  ἐνόμισας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G3543",
    gloss: "to think",
  },
  νομίζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3543",
    gloss: "to think",
  },
  νομίζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3543",
    gloss: "to think",
  },
  νομίζοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G3543",
    gloss: "to think",
  },
  νομιζόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G3543",
    gloss: "to think",
  },
  νομίζω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G3543",
    gloss: "to think",
  },
  νομίζων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3543",
    gloss: "to think",
  },
  νομίσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G3543",
    gloss: "to think",
  },
  νομίσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G3543",
    gloss: "to think",
  },
  νομικὰς: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G3544",
    gloss: "lawyer",
  },
  νομικοὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3544",
    gloss: "lawyer",
  },
  νομικοῖς: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G3544",
    gloss: "lawyer",
  },
  νομικὸν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G3544",
    gloss: "lawyer",
  },
  νομικός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3544",
    gloss: "lawyer",
  },
  νομικὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3544",
    gloss: "lawyer",
  },
  νομικοὺς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G3544",
    gloss: "lawyer",
  },
  νομικῶν: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G3544",
    gloss: "lawyer",
  },
  νομίμως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3545",
    gloss: "lawfully",
  },
  νόμισμα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G3546",
    gloss: "coin",
  },
  νομοδιδάσκαλοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3547",
    gloss: "teacher of the law",
  },
  νομοδιδάσκαλος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3547",
    gloss: "teacher of the law",
  },
  νομοθεσία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3548",
    gloss: "law",
  },
  νενομοθέτηται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G3549",
    gloss: "to give laws",
  },
  νομοθέτης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3550",
    gloss: "lawgiver",
  },
  νόμον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3551",
    gloss: "law",
  },
  νόμος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3551",
    gloss: "law",
  },
  νόμου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3551",
    gloss: "law",
  },
  νόμους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3551",
    gloss: "law",
  },
  νόμῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3551",
    gloss: "law",
  },
  νοσῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3552",
    gloss: "be sick",
  },
  νόσοις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G3554",
    gloss: "illness",
  },
  νόσον: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3554",
    gloss: "illness",
  },
  νόσους: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G3554",
    gloss: "illness",
  },
  νόσων: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G3554",
    gloss: "illness",
  },
  νοσσιὰν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3555",
    gloss: "chick",
  },
  νοσσία: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G3556",
    gloss: "chick",
  },
  ἐνοσφίσατο: {
    parse: "V-AMI-3S｜Verb, aorist, middle, indicative, third, singular",
    GN: "G3557",
    gloss: "to embezzle",
  },
  νοσφιζομένους: {
    parse:
      "V-PMP-APM｜Verb, present, middle, Participle, accusative, plural, masculine",
    GN: "G3557",
    gloss: "to embezzle",
  },
  νοσφίσασθαι: {
    parse: "V-AMN｜Verb, aorist, middle, Infinitive",
    GN: "G3557",
    gloss: "to embezzle",
  },
  νότον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3558",
    gloss: "south",
  },
  νότου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3558",
    gloss: "south",
  },
  νουθεσίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3559",
    gloss: "admonition",
  },
  νουθεσίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3559",
    gloss: "admonition",
  },
  νουθετεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3560",
    gloss: "to admonish",
  },
  νουθετεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G3560",
    gloss: "to admonish",
  },
  νουθετοῦντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G3560",
    gloss: "to admonish",
  },
  νουθετοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G3560",
    gloss: "to admonish",
  },
  νουθετῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3560",
    gloss: "to admonish",
  },
  νεομηνίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3561",
    gloss: "New Moon",
  },
  νουνεχῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3562",
    gloss: "wisely",
  },
  νοΐ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3563",
    gloss: "mind",
  },
  νοῒ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3563",
    gloss: "mind",
  },
  νοός: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3563",
    gloss: "mind",
  },
  νοὸς: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3563",
    gloss: "mind",
  },
  νοῦν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3563",
    gloss: "mind",
  },
  νοῦς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3563",
    gloss: "mind",
  },
  Νύμφαν: {
    parse: "N-ASF-P｜Noun, accusative, singular, feminine, Person",
    GN: "G3564",
    gloss: "Nympha",
  },
  νύμφη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3565",
    gloss: "bride",
  },
  νύμφην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3565",
    gloss: "bride",
  },
  νύμφης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3565",
    gloss: "bride",
  },
  νυμφίον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3566",
    gloss: "bridegroom",
  },
  νυμφίος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3566",
    gloss: "bridegroom",
  },
  νυμφίου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3566",
    gloss: "bridegroom",
  },
  νυμφῶνος: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3567",
    gloss: "bridegroom",
  },
  νῦν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3568",
    gloss: "now",
  },
  Νῦν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3568",
    gloss: "now",
  },
  νυνὶ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3570",
    gloss: "now",
  },
  Νυνὶ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3570",
    gloss: "now",
  },
  νύκτα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3571",
    gloss: "night",
  },
  νύκτας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G3571",
    gloss: "night",
  },
  νυκτί: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3571",
    gloss: "night",
  },
  νυκτὶ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3571",
    gloss: "night",
  },
  νυκτός: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3571",
    gloss: "night",
  },
  νυκτὸς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3571",
    gloss: "night",
  },
  νύξ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3571",
    gloss: "night",
  },
  νὺξ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3571",
    gloss: "night",
  },
  ἔνυξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3572",
    gloss: "to pierce",
  },
  ἐνύσταξαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G3573",
    gloss: "to doze",
  },
  νυστάζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3573",
    gloss: "to doze",
  },
  νυχθήμερον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G3574",
    gloss: "a night and a day",
  },
  Νῶε: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G3575",
    gloss: "Noah",
  },
  νωθροὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3576",
    gloss: "dull",
  },
  νῶτον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3577",
    gloss: "back",
  },
  ξενίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3578",
    gloss: "lodging",
  },
  ἐξένισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3579",
    gloss: "to host",
  },
  ξενίζεσθε: {
    parse: "V-PPM-2P｜Verb, present, passive, imperative, second, plural",
    GN: "G3579",
    gloss: "to host",
  },
  ξενίζεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G3579",
    gloss: "to host",
  },
  ξενίζοντα: {
    parse:
      "V-PAP-APN｜Verb, present, active, Participle, accusative, plural, neuter",
    GN: "G3579",
    gloss: "to host",
  },
  ξενίζονται: {
    parse: "V-PPI-3P｜Verb, present, passive, indicative, third, plural",
    GN: "G3579",
    gloss: "to host",
  },
  ξενίσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G3579",
    gloss: "to host",
  },
  ξενισθῶμεν: {
    parse: "V-APS-1P｜Verb, aorist, passive, subjunctive, first, plural",
    GN: "G3579",
    gloss: "to host",
  },
  ἐξενοδόχησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3580",
    gloss: "to show hospitality",
  },
  ξέναις: {
    parse: "A-DPF｜Adjective, dative, plural, feminine",
    GN: "G3581",
    gloss: "foreign",
  },
  ξένοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3581",
    gloss: "foreign",
  },
  ξένοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G3581",
    gloss: "foreign",
  },
  ξένον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G3581",
    gloss: "foreign",
  },
  ξένος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3581",
    gloss: "foreign",
  },
  ξένου: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G3581",
    gloss: "foreign",
  },
  ξένους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G3581",
    gloss: "foreign",
  },
  Ξένων: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G3581",
    gloss: "foreign",
  },
  ξεστῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G3582",
    gloss: "pitcher",
  },
  ἐξηραμμένην: {
    parse:
      "V-RPP-ASF｜Verb, perfect, passive, Participle, accusative, singular, feminine",
    GN: "G3583",
    gloss: "to dry",
  },
  ἐξήρανεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3583",
    gloss: "to dry",
  },
  ἐξηράνθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G3583",
    gloss: "to dry",
  },
  ἐξήρανται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G3583",
    gloss: "to dry",
  },
  ξηραίνεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G3583",
    gloss: "to dry",
  },
  ξηρά: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G3584",
    gloss: "dried up/withered",
  },
  ξηράν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G3584",
    gloss: "dried up/withered",
  },
  ξηρὰν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G3584",
    gloss: "dried up/withered",
  },
  ξηρᾶς: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G3584",
    gloss: "dried up/withered",
  },
  ξηρῷ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G3584",
    gloss: "dried up/withered",
  },
  ξηρῶν: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G3584",
    gloss: "dried up/withered",
  },
  ξύλινα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G3585",
    gloss: "wooden",
  },
  ξύλα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G3586",
    gloss: "wood",
  },
  ξύλον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G3586",
    gloss: "wood",
  },
  ξύλου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G3586",
    gloss: "wood",
  },
  ξύλῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G3586",
    gloss: "wood",
  },
  ξύλων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G3586",
    gloss: "wood",
  },
  ἐξυρημένῃ: {
    parse:
      "V-RPP-DSF｜Verb, perfect, passive, Participle, dative, singular, feminine",
    GN: "G3587",
    gloss: "to shave",
  },
  ξυρᾶσθαι: {
    parse: "V-AMN｜Verb, aorist, middle, Infinitive",
    GN: "G3587",
    gloss: "to shave",
  },
  ξυρήσονται: {
    parse: "V-FDI-3P｜Verb, future, Middle deponent, indicative, third, plural",
    GN: "G3587",
    gloss: "to shave",
  },
  αἱ: {
    parse: "R-NPF｜Relative pronoun, nominative, plural, feminine",
    GN: "G3739",
    gloss: "which",
  },
  Αἱ: {
    parse: "T-VPF｜definite article, Vocative or nominative, plural, feminine",
    GN: "G3588",
    gloss: "the/this/who",
  },
  αἵ: {
    parse: "R-NPF｜Relative pronoun, nominative, plural, feminine",
    GN: "G3739",
    gloss: "which",
  },
  ἡ: {
    parse:
      "T-VSF｜definite article, Vocative or nominative, singular, feminine",
    GN: "G3588",
    gloss: "the/this/who",
  },
  Ἡ: {
    parse:
      "T-VSF｜definite article, Vocative or nominative, singular, feminine",
    GN: "G3588",
    gloss: "the/this/who",
  },
  ἥ: {
    parse: "T-NSF｜definite article, nominative, singular, feminine",
    GN: "G3588",
    gloss: "the/this/who",
  },
  ὁ: {
    parse:
      "T-VSM｜definite article, Vocative or nominative, singular, masculine",
    GN: "G3588",
    gloss: "the/this/who",
  },
  Ὁ: {
    parse:
      "T-VSM｜definite article, Vocative or nominative, singular, masculine",
    GN: "G3588",
    gloss: "the/this/who",
  },
  ὅ: {
    parse: "R-NSN｜Relative pronoun, nominative, singular, neuter",
    GN: "G3739",
    gloss: "which",
  },
  οἱ: {
    parse: "T-VPM｜definite article, Vocative or nominative, plural, masculine",
    GN: "G3588",
    gloss: "the/this/who",
  },
  Οἱ: {
    parse: "T-VPM｜definite article, Vocative or nominative, plural, masculine",
    GN: "G3588",
    gloss: "the/this/who",
  },
  οἵ: {
    parse: "R-NPM｜Relative pronoun, nominative, plural, masculine",
    GN: "G3739",
    gloss: "which",
  },
  τά: {
    parse: "T-APN｜definite article, accusative, plural, neuter",
    GN: "G3588",
    gloss: "the/this/who",
  },
  τὰ: {
    parse: "T-VPN｜definite article, Vocative or nominative, plural, neuter",
    GN: "G3588",
    gloss: "the/this/who",
  },
  Τὰ: {
    parse: "T-VPN｜definite article, Vocative or nominative, plural, neuter",
    GN: "G3588",
    gloss: "the/this/who",
  },
  ταῖς: {
    parse: "T-DPF｜definite article, dative, plural, feminine",
    GN: "G3588",
    gloss: "the/this/who",
  },
  τὰς: {
    parse: "T-APF｜definite article, accusative, plural, feminine",
    GN: "G3588",
    gloss: "the/this/who",
  },
  Τὰς: {
    parse: "T-APF｜definite article, accusative, plural, feminine",
    GN: "G3588",
    gloss: "the/this/who",
  },
  τῇ: {
    parse: "T-DSF｜definite article, dative, singular, feminine",
    GN: "G3588",
    gloss: "the/this/who",
  },
  Τῇ: {
    parse: "T-DSF｜definite article, dative, singular, feminine",
    GN: "G3588",
    gloss: "the/this/who",
  },
  τήν: {
    parse: "T-ASF｜definite article, accusative, singular, feminine",
    GN: "G3588",
    gloss: "the/this/who",
  },
  τὴν: {
    parse: "T-ASF｜definite article, accusative, singular, feminine",
    GN: "G3588",
    gloss: "the/this/who",
  },
  Τὴν: {
    parse: "T-ASF｜definite article, accusative, singular, feminine",
    GN: "G3588",
    gloss: "the/this/who",
  },
  τῆς: {
    parse: "T-GSF｜definite article, genitive, singular, feminine",
    GN: "G3588",
    gloss: "the/this/who",
  },
  τό: {
    parse: "T-ASN｜definite article, accusative, singular, neuter",
    GN: "G3588",
    gloss: "the/this/who",
  },
  τὸ: {
    parse: "T-VSN｜definite article, Vocative or nominative, singular, neuter",
    GN: "G3588",
    gloss: "the/this/who",
  },
  Τὸ: {
    parse: "T-VSN｜definite article, Vocative or nominative, singular, neuter",
    GN: "G3588",
    gloss: "the/this/who",
  },
  τοῖς: {
    parse: "T-DPN｜definite article, dative, plural, neuter",
    GN: "G3588",
    gloss: "the/this/who",
  },
  Τοῖς: {
    parse: "T-DPM｜definite article, dative, plural, masculine",
    GN: "G3588",
    gloss: "the/this/who",
  },
  τόν: {
    parse: "T-ASM｜definite article, accusative, singular, masculine",
    GN: "G3588",
    gloss: "the/this/who",
  },
  τὸν: {
    parse: "T-ASM｜definite article, accusative, singular, masculine",
    GN: "G3588",
    gloss: "the/this/who",
  },
  Τὸν: {
    parse: "T-ASM｜definite article, accusative, singular, masculine",
    GN: "G3588",
    gloss: "the/this/who",
  },
  τοῦ: {
    parse: "T-GSN｜definite article, genitive, singular, neuter",
    GN: "G3588",
    gloss: "the/this/who",
  },
  Τοῦ: {
    parse: "T-GSN｜definite article, genitive, singular, neuter",
    GN: "G3588",
    gloss: "the/this/who",
  },
  τοὺς: {
    parse: "T-APM｜definite article, accusative, plural, masculine",
    GN: "G3588",
    gloss: "the/this/who",
  },
  Τοὺς: {
    parse: "T-APM｜definite article, accusative, plural, masculine",
    GN: "G3588",
    gloss: "the/this/who",
  },
  τῷ: {
    parse: "T-DSN｜definite article, dative, singular, neuter",
    GN: "G3588",
    gloss: "the/this/who",
  },
  Τῷ: {
    parse: "T-DSN｜definite article, dative, singular, neuter",
    GN: "G3588",
    gloss: "the/this/who",
  },
  τῶν: {
    parse: "T-GPN｜definite article, genitive, plural, neuter",
    GN: "G3588",
    gloss: "the/this/who",
  },
  Τῶν: {
    parse: "T-GPM｜definite article, genitive, plural, masculine",
    GN: "G3588",
    gloss: "the/this/who",
  },
  ὀγδοήκοντα: {
    parse:
      "A-GPN-NUI｜Adjective, genitive, plural, neuter, NUmerical Indiclinable (A)",
    GN: "G3589",
    gloss: "eighty",
  },
  ὀγδόῃ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G3590",
    gloss: "eighth",
  },
  ὄγδοον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G3590",
    gloss: "eighth",
  },
  ὄγδοος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3590",
    gloss: "eighth",
  },
  ὄγδοός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3590",
    gloss: "eighth",
  },
  ὄγκον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3591",
    gloss: "impediment",
  },
  Τάδε: {
    parse: "D-APN｜Demonstrative pronoun, accusative, plural, neuter",
    GN: "G3592",
    gloss: "this",
  },
  τῇδε: {
    parse: "D-DSF｜Demonstrative pronoun, dative, singular, feminine",
    GN: "G3592",
    gloss: "this",
  },
  τήνδε: {
    parse: "D-ASF｜Demonstrative pronoun, accusative, singular, feminine",
    GN: "G3592",
    gloss: "this",
  },
  ὁδεύων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3593",
    gloss: "to journey",
  },
  ὁδηγεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3594",
    gloss: "to guide",
  },
  ὁδηγῇ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G3594",
    gloss: "to guide",
  },
  ὁδηγήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G3594",
    gloss: "to guide",
  },
  ὁδηγοί: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3595",
    gloss: "guide/leader",
  },
  ὁδηγοὶ: {
    parse: "N-VPM｜Noun, Vocative or nominative, plural, masculine",
    GN: "G3595",
    gloss: "guide/leader",
  },
  ὁδηγὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3595",
    gloss: "guide/leader",
  },
  ὁδηγοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3595",
    gloss: "guide/leader",
  },
  ὁδοιπορούντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G3596",
    gloss: "to journey",
  },
  ὁδοιπορίαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G3597",
    gloss: "journey",
  },
  ὁδοιπορίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3597",
    gloss: "journey",
  },
  ὁδοί: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G3598",
    gloss: "road",
  },
  ὁδοὶ: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G3598",
    gloss: "road",
  },
  ὁδοῖς: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G3598",
    gloss: "road",
  },
  ὁδόν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3598",
    gloss: "road",
  },
  ὁδὸν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3598",
    gloss: "road",
  },
  ὁδὸς: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3598",
    gloss: "road",
  },
  ὁδοῦ: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3598",
    gloss: "road",
  },
  ὁδούς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G3598",
    gloss: "road",
  },
  ὁδοὺς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G3598",
    gloss: "road",
  },
  ὁδῷ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3598",
    gloss: "road",
  },
  ὁδῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G3598",
    gloss: "road",
  },
  ὀδόντα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3599",
    gloss: "tooth",
  },
  ὀδόντας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3599",
    gloss: "tooth",
  },
  ὀδόντες: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3599",
    gloss: "tooth",
  },
  ὀδόντος: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3599",
    gloss: "tooth",
  },
  ὀδόντων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G3599",
    gloss: "tooth",
  },
  ὀδυνᾶσαι: {
    parse: "V-PMI-2S｜Verb, present, middle, indicative, second, singular",
    GN: "G3600",
    gloss: "be anguished",
  },
  ὀδυνῶμαι: {
    parse: "V-PMI-1S｜Verb, present, middle, indicative, first, singular",
    GN: "G3600",
    gloss: "be anguished",
  },
  ὀδυνώμενοι: {
    parse:
      "V-PMP-NPM｜Verb, present, middle, Participle, nominative, plural, masculine",
    GN: "G3600",
    gloss: "be anguished",
  },
  ὀδύναις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G3601",
    gloss: "anguish",
  },
  ὀδύνη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3601",
    gloss: "anguish",
  },
  ὀδυρμόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3602",
    gloss: "mourning",
  },
  ὀδυρμὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3602",
    gloss: "mourning",
  },
  Ὀζίαν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G3604",
    gloss: "Uzziah",
  },
  Ὀζίας: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G3604",
    gloss: "Uzziah",
  },
  ὄζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3605",
    gloss: "to stink",
  },
  ὅθεν: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G3606",
    gloss: "whence",
  },
  Ὅθεν: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G3606",
    gloss: "whence",
  },
  ὀθόνην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3607",
    gloss: "sheet",
  },
  ὀθόνια: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G3608",
    gloss: "bandages",
  },
  ὀθονίοις: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G3608",
    gloss: "bandages",
  },
  ὀθονίων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G3608",
    gloss: "bandages",
  },
  οἰκεῖοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3609",
    gloss: "of one’s household",
  },
  οἰκείους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G3609",
    gloss: "of one’s household",
  },
  οἰκείων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G3609",
    gloss: "of one’s household",
  },
  οἰκέται: {
    // or nominative
    parse: "N-VPM｜Noun, Vocative, plural, masculine",
    GN: "G3610",
    gloss: "slave",
  },
  οἰκέτην: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3610",
    gloss: "slave",
  },
  οἰκέτης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3610",
    gloss: "slave",
  },
  οἰκετῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G3610",
    gloss: "slave",
  },
  οἰκεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3611",
    gloss: "to dwell",
  },
  οἰκεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3611",
    gloss: "to dwell",
  },
  οἰκοῦσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G3611",
    gloss: "to dwell",
  },
  οἰκῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3611",
    gloss: "to dwell",
  },
  οἰκήματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G3612",
    gloss: "cell",
  },
  οἰκητήριον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G3613",
    gloss: "dwelling",
  },
  οἰκίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3614",
    gloss: "household",
  },
  οἰκία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3614",
    gloss: "household",
  },
  οἰκίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3614",
    gloss: "household",
  },
  οἰκίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3614",
    gloss: "household",
  },
  οἰκιῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G3614",
    gloss: "household",
  },
  οἰκιακοὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3615",
    gloss: "member of a house",
  },
  οἰκιακοὺς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3615",
    gloss: "member of a house",
  },
  οἰκοδεσποτεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3616",
    gloss: "to manage a house",
  },
  οἰκοδεσπότῃ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3617",
    gloss: "householder",
  },
  οἰκοδεσπότην: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3617",
    gloss: "householder",
  },
  οἰκοδεσπότης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3617",
    gloss: "householder",
  },
  οἰκοδεσπότου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3617",
    gloss: "householder",
  },
  οἰκοδομεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3618",
    gloss: "to build",
  },
  οἰκοδομεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3618",
    gloss: "to build",
  },
  οἰκοδομεῖσθε: {
    parse: "V-PPI-2P｜Verb, present, passive, indicative, second, plural",
    GN: "G3618",
    gloss: "to build",
  },
  οἰκοδομεῖται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G3618",
    gloss: "to build",
  },
  οἰκοδομεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G3618",
    gloss: "to build",
  },
  οἰκοδομήθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G3618",
    gloss: "to build",
  },
  οἰκοδομηθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G3618",
    gloss: "to build",
  },
  οἰκοδομῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G3618",
    gloss: "to build",
  },
  οἰκοδομήσαντι: {
    parse:
      "V-AAP-DSM｜Verb, aorist, active, Participle, dative, singular, masculine",
    GN: "G3618",
    gloss: "to build",
  },
  οἰκοδόμησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3618",
    gloss: "to build",
  },
  οἰκοδομήσετέ: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G3618",
    gloss: "to build",
  },
  οἰκοδομῆσθαι: {
    parse: "V-RPN｜Verb, perfect, passive, Infinitive",
    GN: "G3618",
    gloss: "to build",
  },
  οἰκοδομήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G3618",
    gloss: "to build",
  },
  οἰκοδομουμένη: {
    parse:
      "V-PPP-NSF｜Verb, present, passive, Participle, nominative, singular, feminine",
    GN: "G3618",
    gloss: "to build",
  },
  οἰκοδομοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G3618",
    gloss: "to build",
  },
  οἰκοδομοῦντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G3618",
    gloss: "to build",
  },
  οἰκοδομῶ: {
    parse: "V-PAS-1S｜Verb, present, active, subjunctive, first, singular",
    GN: "G3618",
    gloss: "to build",
  },
  οἰκοδομῶν: {
    parse:
      "V-PAP-VSM｜Verb, present, active, Participle, Vocative or nominative, singular, masculine",
    GN: "G3618",
    gloss: "to build",
  },
  ᾠκοδόμησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3618",
    gloss: "to build",
  },
  ᾠκοδόμητο: {
    parse: "V-LPI-3S｜Verb, pluperfect, passive, indicative, third, singular",
    GN: "G3618",
    gloss: "to build",
  },
  ᾠκοδόμουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G3618",
    gloss: "to build",
  },
  οἰκοδομαί: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G3619",
    gloss: "building",
  },
  οἰκοδομάς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G3619",
    gloss: "building",
  },
  οἰκοδομὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G3619",
    gloss: "building",
  },
  οἰκοδομή: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3619",
    gloss: "building",
  },
  οἰκοδομὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3619",
    gloss: "building",
  },
  οἰκοδομήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3619",
    gloss: "building",
  },
  οἰκοδομὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3619",
    gloss: "building",
  },
  οἰκοδομῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3619",
    gloss: "building",
  },
  οἰκονομεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3621",
    gloss: "to manage",
  },
  οἰκονομία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3622",
    gloss: "management",
  },
  οἰκονομίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3622",
    gloss: "management",
  },
  οἰκονομίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3622",
    gloss: "management",
  },
  οἰκονόμοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3623",
    gloss: "manager",
  },
  οἰκονόμοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G3623",
    gloss: "manager",
  },
  οἰκονόμον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3623",
    gloss: "manager",
  },
  οἰκονόμος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3623",
    gloss: "manager",
  },
  οἰκονόμους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3623",
    gloss: "manager",
  },
  οἴκοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G3624",
    gloss: "household",
  },
  οἶκον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3624",
    gloss: "household",
  },
  οἶκόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3624",
    gloss: "household",
  },
  οἶκος: {
    parse: "N-VSM｜Noun, Vocative or nominative, singular, masculine",
    GN: "G3624",
    gloss: "household",
  },
  οἶκός: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3624",
    gloss: "household",
  },
  οἴκου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3624",
    gloss: "household",
  },
  οἴκους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3624",
    gloss: "household",
  },
  οἴκῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3624",
    gloss: "household",
  },
  οἴκων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G3624",
    gloss: "household",
  },
  οἰκουμένῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3625",
    gloss: "world",
  },
  οἰκουμένη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3625",
    gloss: "world",
  },
  οἰκουμένην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3625",
    gloss: "world",
  },
  οἰκουμένης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3625",
    gloss: "world",
  },
  οἰκουργούς: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G3626",
    gloss: "busy at home",
  },
  οἰκτιρήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G3627",
    gloss: "to have compassion",
  },
  οἰκτίρω: {
    parse: "V-PAS-1S｜Verb, present, active, subjunctive, first, singular",
    GN: "G3627",
    gloss: "to have compassion",
  },
  οἰκτιρμοί: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3628",
    gloss: "compassion",
  },
  οἰκτιρμοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3628",
    gloss: "compassion",
  },
  οἰκτιρμῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G3628",
    gloss: "compassion",
  },
  οἰκτίρμονες: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3629",
    gloss: "compassionate",
  },
  οἰκτίρμων: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3629",
    gloss: "compassionate",
  },
  οἰνοπότης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3630",
    gloss: "drunkard",
  },
  οἶνον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3631",
    gloss: "wine",
  },
  Οἶνον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3631",
    gloss: "wine",
  },
  οἶνος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3631",
    gloss: "wine",
  },
  οἴνου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3631",
    gloss: "wine",
  },
  οἴνῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3631",
    gloss: "wine",
  },
  οἰνοφλυγίαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G3632",
    gloss: "drunkenness",
  },
  οἰέσθω: {
    parse:
      "V-PNM-3S｜Verb, present, Middle or Passive deponent, imperative, third, singular",
    GN: "G3633",
    gloss: "to suppose",
  },
  οἶμαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G3633",
    gloss: "to suppose",
  },
  οἰόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G3633",
    gloss: "to suppose",
  },
  οἷα: {
    parse: "K-APN｜Correlative pronoun, accusative, plural, neuter",
    GN: "G3634",
    gloss: "such as",
  },
  οἷά: {
    parse: "K-NPN｜Correlative pronoun, nominative, plural, neuter",
    GN: "G3634",
    gloss: "such as",
  },
  οἵα: {
    parse: "K-NSF｜Correlative pronoun, nominative, singular, feminine",
    GN: "G3634",
    gloss: "such as",
  },
  οἷοι: {
    parse: "K-NPM｜Correlative pronoun, nominative, plural, masculine",
    GN: "G3634",
    gloss: "such as",
  },
  οἷοί: {
    parse: "K-NPM｜Correlative pronoun, nominative, plural, masculine",
    GN: "G3634",
    gloss: "such as",
  },
  οἷον: {
    parse: "K-NSN｜Correlative pronoun, nominative, singular, neuter",
    GN: "G3634",
    gloss: "such as",
  },
  οἷος: {
    parse: "K-NSM｜Correlative pronoun, nominative, singular, masculine",
    GN: "G3634",
    gloss: "such as",
  },
  οἵους: {
    parse: "K-APM｜Correlative pronoun, accusative, plural, masculine",
    GN: "G3634",
    gloss: "such as",
  },
  ὀκνήσῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G3635",
    gloss: "to delay",
  },
  ὀκνηρέ: {
    // or nominative
    parse: "A-VSM｜Adjective, Vocative, singular, masculine",
    GN: "G3636",
    gloss: "lazy",
  },
  ὀκνηροί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3636",
    gloss: "lazy",
  },
  ὀκνηρόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G3636",
    gloss: "lazy",
  },
  ὀκταήμερος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3637",
    gloss: "eighth day",
  },
  ὀκτὼ: {
    parse:
      "A-NPF-NUI｜Adjective, nominative, plural, feminine, NUmerical Indiclinable (A)",
    GN: "G3638",
    gloss: "eight",
  },
  ὄλεθρον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3639",
    gloss: "destructive",
  },
  ὄλεθρος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3639",
    gloss: "destructive",
  },
  Ὀλιγόπιστε: {
    // or nominative
    parse: "A-VSM｜Adjective, Vocative, singular, masculine",
    GN: "G3640",
    gloss: "of little faith",
  },
  ὀλιγόπιστοι: {
    // or nominative
    parse: "A-VPM｜Adjective, Vocative, plural, masculine",
    GN: "G3640",
    gloss: "of little faith",
  },
  ὀλίγα: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G3641",
    gloss: "little/few",
  },
  ὀλίγαι: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G3641",
    gloss: "little/few",
  },
  ὀλίγας: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G3641",
    gloss: "little/few",
  },
  ὀλίγην: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G3641",
    gloss: "little/few",
  },
  ὀλίγης: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G3641",
    gloss: "little/few",
  },
  ὀλίγοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3641",
    gloss: "little/few",
  },
  ὀλίγοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G3641",
    gloss: "little/few",
  },
  ὀλίγον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G3641",
    gloss: "little/few",
  },
  ὀλίγος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3641",
    gloss: "little/few",
  },
  ὀλίγου: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G3641",
    gloss: "little/few",
  },
  ὀλίγῳ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G3641",
    gloss: "little/few",
  },
  ὀλίγων: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G3641",
    gloss: "little/few",
  },
  ὀλιγοψύχους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G3642",
    gloss: "fainthearted",
  },
  ὀλιγώρει: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G3643",
    gloss: "to despise",
  },
  ὀλοθρευτοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3644",
    gloss: "destroyer",
  },
  ὀλοθρεύων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3645",
    gloss: "to destroy",
  },
  ὁλοκαυτώματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G3646",
    gloss: "burnt offering",
  },
  ὁλοκαυτωμάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G3646",
    gloss: "burnt offering",
  },
  ὁλοκληρίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3647",
    gloss: "wholeness",
  },
  ὁλόκληροι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3648",
    gloss: "whole",
  },
  ὁλόκληρον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G3648",
    gloss: "whole",
  },
  ὀλολύζοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G3649",
    gloss: "to wail",
  },
  ὅλῃ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G3650",
    gloss: "all",
  },
  ὅλη: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G3650",
    gloss: "all",
  },
  ὅλην: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G3650",
    gloss: "all",
  },
  Ὅλην: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G3650",
    gloss: "all",
  },
  ὅλης: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G3650",
    gloss: "all",
  },
  ὅλον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G3650",
    gloss: "all",
  },
  ὅλος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3650",
    gloss: "all",
  },
  ὅλου: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G3650",
    gloss: "all",
  },
  ὅλους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G3650",
    gloss: "all",
  },
  ὅλῳ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G3650",
    gloss: "all",
  },
  ὁλοτελεῖς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G3651",
    gloss: "wholly",
  },
  Ὀλυμπᾶν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G3652",
    gloss: "Olympas",
  },
  ὀλύνθους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3653",
    gloss: "late fig",
  },
  ὅλως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3654",
    gloss: "at all",
  },
  Ὅλως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3654",
    gloss: "at all",
  },
  Ὄμβρος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3655",
    gloss: "rainstorm",
  },
  ὁμιλεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3656",
    gloss: "to talk",
  },
  ὁμιλήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G3656",
    gloss: "to talk",
  },
  ὡμίλει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G3656",
    gloss: "to talk",
  },
  ὡμίλουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G3656",
    gloss: "to talk",
  },
  ὁμιλίαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G3657",
    gloss: "association",
  },
  ὄμματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G3659",
    gloss: "eye",
  },
  ὀμμάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G3659",
    gloss: "eye",
  },
  ὀμνύει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3660",
    gloss: "to swear",
  },
  ὀμνύειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3660",
    gloss: "to swear",
  },
  ὀμνύετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G3660",
    gloss: "to swear",
  },
  ὀμνύναι: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3660",
    gloss: "to swear",
  },
  ὀμνύουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G3660",
    gloss: "to swear",
  },
  ὀμόσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G3660",
    gloss: "to swear",
  },
  ὀμόσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G3660",
    gloss: "to swear",
  },
  ὀμόσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G3660",
    gloss: "to swear",
  },
  ὀμόσῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G3660",
    gloss: "to swear",
  },
  ὤμοσα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G3660",
    gloss: "to swear",
  },
  ὤμοσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3660",
    gloss: "to swear",
  },
  Ὤμοσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3660",
    gloss: "to swear",
  },
  ὁμοθυμαδὸν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3661",
    gloss: "united",
  },
  ὁμοιοπαθεῖς: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3663",
    gloss: "like",
  },
  ὁμοιοπαθὴς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3663",
    gloss: "like",
  },
  ὅμοια: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G3664",
    gloss: "like",
  },
  ὁμοία: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G3664",
    gloss: "like",
  },
  Ὁμοία: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G3664",
    gloss: "like",
  },
  ὅμοιαι: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G3664",
    gloss: "like",
  },
  ὁμοίας: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G3664",
    gloss: "like",
  },
  ὅμοιοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3664",
    gloss: "like",
  },
  ὅμοιοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3664",
    gloss: "like",
  },
  ὅμοιον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G3664",
    gloss: "like",
  },
  ὅμοιος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3664",
    gloss: "like",
  },
  ὅμοιός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3664",
    gloss: "like",
  },
  ὁμοιότητα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3665",
    gloss: "likeness",
  },
  ὁμοιωθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G3666",
    gloss: "to liken",
  },
  ὁμοιωθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G3666",
    gloss: "to liken",
  },
  ὁμοιωθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G3666",
    gloss: "to liken",
  },
  ὁμοιωθῆτε: {
    parse: "V-APS-2P｜Verb, aorist, passive, subjunctive, second, plural",
    GN: "G3666",
    gloss: "to liken",
  },
  ὁμοιώσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G3666",
    gloss: "to liken",
  },
  ὁμοιώσωμεν: {
    parse: "V-AAS-1P｜Verb, aorist, active, subjunctive, first, plural",
    GN: "G3666",
    gloss: "to liken",
  },
  ὡμοιώθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G3666",
    gloss: "to liken",
  },
  Ὡμοιώθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G3666",
    gloss: "to liken",
  },
  ὡμοιώθημεν: {
    parse: "V-API-1P｜Verb, aorist, passive, indicative, first, plural",
    GN: "G3666",
    gloss: "to liken",
  },
  ὁμοιώματα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G3667",
    gloss: "likeness",
  },
  ὁμοιώματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G3667",
    gloss: "likeness",
  },
  ὁμοίως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3668",
    gloss: "similarly",
  },
  Ὁμοίως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3668",
    gloss: "similarly",
  },
  ὁμοίωσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3669",
    gloss: "likeness",
  },
  ὁμολογεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3670",
    gloss: "to confess/profess",
  },
  ὁμολογεῖται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G3670",
    gloss: "to confess/profess",
  },
  ὁμολογήσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G3670",
    gloss: "to confess/profess",
  },
  ὁμολογήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G3670",
    gloss: "to confess/profess",
  },
  ὁμολογήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G3670",
    gloss: "to confess/profess",
  },
  ὁμολογήσῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G3670",
    gloss: "to confess/profess",
  },
  ὁμολογήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G3670",
    gloss: "to confess/profess",
  },
  ὁμολογοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G3670",
    gloss: "to confess/profess",
  },
  ὁμολογούντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G3670",
    gloss: "to confess/profess",
  },
  ὁμολογοῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G3670",
    gloss: "to confess/profess",
  },
  ὁμολογῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G3670",
    gloss: "to confess/profess",
  },
  ὁμολογῶμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G3670",
    gloss: "to confess/profess",
  },
  ὁμολογῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3670",
    gloss: "to confess/profess",
  },
  ὡμολόγησας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G3670",
    gloss: "to confess/profess",
  },
  ὡμολόγησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3670",
    gloss: "to confess/profess",
  },
  ὡμολόγουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G3670",
    gloss: "to confess/profess",
  },
  ὁμολογίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3671",
    gloss: "confession",
  },
  ὁμολογίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3671",
    gloss: "confession",
  },
  ὁμολογουμένως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3672",
    gloss: "undeniably",
  },
  ὁμότεχνον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G3673",
    gloss: "of the same trade",
  },
  ὁμοῦ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3674",
    gloss: "together",
  },
  ὁμόφρονες: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3675",
    gloss: "like-minded",
  },
  ὅμως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3676",
    gloss: "just as",
  },
  ὄναρ: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G3677",
    gloss: "dream",
  },
  ὀνάριον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G3678",
    gloss: "young donkey",
  },
  ὀνειδίζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3679",
    gloss: "to revile",
  },
  ὀνειδίζεσθε: {
    parse: "V-PPI-2P｜Verb, present, passive, indicative, second, plural",
    GN: "G3679",
    gloss: "to revile",
  },
  ὀνειδίζοντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G3679",
    gloss: "to revile",
  },
  ὀνειδιζόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G3679",
    gloss: "to revile",
  },
  ὀνειδίσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G3679",
    gloss: "to revile",
  },
  ὠνείδιζον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G3679",
    gloss: "to revile",
  },
  ὠνείδισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3679",
    gloss: "to revile",
  },
  ὀνειδισμοὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3680",
    gloss: "reproach",
  },
  ὀνειδισμοῖς: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G3680",
    gloss: "reproach",
  },
  ὀνειδισμὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3680",
    gloss: "reproach",
  },
  ὄνειδός: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G3681",
    gloss: "disgrace",
  },
  Ὀνήσιμον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G3682",
    gloss: "Onesimus",
  },
  Ὀνησίμῳ: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G3682",
    gloss: "Onesimus",
  },
  Ὀνησιφόρου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G3683",
    gloss: "Onesiphorus",
  },
  ὀνικὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3684",
    gloss: "huge millstone",
  },
  ὀναίμην: {
    parse:
      "V-2ADO-1S｜Verb, 2nd aorist, Middle deponent, Optative, first, singular",
    GN: "G3685",
    gloss: "to have joy",
  },
  ὄνομα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G3686",
    gloss: "name",
  },
  ὄνομά: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G3686",
    gloss: "name",
  },
  ὀνόματα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G3686",
    gloss: "name",
  },
  ὀνόματά: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G3686",
    gloss: "name",
  },
  ὀνόματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G3686",
    gloss: "name",
  },
  ὀνόματί: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G3686",
    gloss: "name",
  },
  ὀνόματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G3686",
    gloss: "name",
  },
  ὀνόματός: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G3686",
    gloss: "name",
  },
  ὀνομάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G3686",
    gloss: "name",
  },
  ὀνομάζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3687",
    gloss: "to name",
  },
  ὀνομαζέσθω: {
    parse: "V-PPM-3S｜Verb, present, passive, imperative, third, singular",
    GN: "G3687",
    gloss: "to name",
  },
  ὀνομάζεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G3687",
    gloss: "to name",
  },
  ὀνομαζόμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G3687",
    gloss: "to name",
  },
  ὀνομαζομένου: {
    parse:
      "V-PPP-GSN｜Verb, present, passive, Participle, genitive, singular, neuter",
    GN: "G3687",
    gloss: "to name",
  },
  ὀνομάζων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3687",
    gloss: "to name",
  },
  ὠνόμασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3687",
    gloss: "to name",
  },
  ὠνομάσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G3687",
    gloss: "to name",
  },
  ὄνον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3688",
    gloss: "donkey",
  },
  ὄνου: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3688",
    gloss: "donkey",
  },
  ὄντως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3689",
    gloss: "really",
  },
  Ὄντως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3689",
    gloss: "really",
  },
  ὄξος: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G3690",
    gloss: "vinegar",
  },
  ὄξους: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G3690",
    gloss: "vinegar",
  },
  ὀξεῖα: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G3691",
    gloss: "sharp/swift",
  },
  ὀξεῖαν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G3691",
    gloss: "sharp/swift",
  },
  ὀξεῖς: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3691",
    gloss: "sharp/swift",
  },
  ὀξύ: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G3691",
    gloss: "sharp/swift",
  },
  ὀξὺ: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G3691",
    gloss: "sharp/swift",
  },
  ὀπαῖς: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G3692",
    gloss: "hole",
  },
  ὀπῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3692",
    gloss: "hole",
  },
  ὄπισθεν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3693",
    gloss: "after",
  },
  ὀπίσω: { parse: "PREP｜Preposition", GN: "G3694", gloss: "after" },
  Ὀπίσω: { parse: "PREP｜Preposition", GN: "G3694", gloss: "after" },
  ὁπλίσασθε: {
    parse: "V-AMM-2P｜Verb, aorist, middle, imperative, second, plural",
    GN: "G3695",
    gloss: "to arm",
  },
  ὅπλα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G3696",
    gloss: "weapon",
  },
  ὅπλων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G3696",
    gloss: "weapon",
  },
  ὁποίαν: {
    parse: "I-ASF｜Interrogative pronoun, accusative, singular, feminine",
    GN: "G3697",
    gloss: "what sort",
  },
  ὁποῖοί: {
    parse: "I-NPM｜Interrogative pronoun, nominative, plural, masculine",
    GN: "G3697",
    gloss: "what sort",
  },
  ὁποῖόν: {
    parse: "I-NSN｜Interrogative pronoun, nominative, singular, neuter",
    GN: "G3697",
    gloss: "what sort",
  },
  ὁποῖος: {
    parse: "I-NSM｜Interrogative pronoun, nominative, singular, masculine",
    GN: "G3697",
    gloss: "what sort",
  },
  ὅπου: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G3699",
    gloss: "where(-ever)",
  },
  Ὅπου: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G3699",
    gloss: "where(-ever)",
  },
  ὀπτανόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G3700",
    gloss: "to appear",
  },
  ὀπτασίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3701",
    gloss: "vision",
  },
  ὀπτασίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3701",
    gloss: "vision",
  },
  ὀπτασίας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G3701",
    gloss: "vision",
  },
  ὀπτοῦ: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G3702",
    gloss: "broiled",
  },
  ὀπώρα: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3703",
    gloss: "fruit",
  },
  ὅπως: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G3704",
    gloss: "that",
  },
  Ὅπως: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G3704",
    gloss: "that",
  },
  ὅραμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G3705",
    gloss: "vision",
  },
  ὁράματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G3705",
    gloss: "vision",
  },
  ὁράματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G3705",
    gloss: "vision",
  },
  ὁράσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3706",
    gloss: "appearance/vision",
  },
  ὁράσεις: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G3706",
    gloss: "appearance/vision",
  },
  ὁρατὰ: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G3707",
    gloss: "visible",
  },
  ἑόρακα: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G3708",
    gloss: "appear",
  },
  ἑόρακαν: {
    parse: "V-RAI-3P｜Verb, perfect, active, indicative, third, plural",
    GN: "G3708",
    gloss: "appear",
  },
  ἑόρακεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G3708",
    gloss: "appear",
  },
  ἑώρακα: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G3708",
    gloss: "appear",
  },
  Ἑώρακα: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G3708",
    gloss: "appear",
  },
  ἑωράκαμεν: {
    parse: "V-RAI-1P｜Verb, perfect, active, indicative, first, plural",
    GN: "G3708",
    gloss: "appear",
  },
  Ἑωράκαμεν: {
    parse: "V-RAI-1P｜Verb, perfect, active, indicative, first, plural",
    GN: "G3708",
    gloss: "appear",
  },
  ἑώρακαν: {
    parse: "V-RAI-3P｜Verb, perfect, active, indicative, third, plural",
    GN: "G3708",
    gloss: "appear",
  },
  ἑώρακας: {
    parse: "V-RAI-2S｜Verb, perfect, active, indicative, second, singular",
    GN: "G3708",
    gloss: "appear",
  },
  ἑώρακάς: {
    parse: "V-RAI-2S｜Verb, perfect, active, indicative, second, singular",
    GN: "G3708",
    gloss: "appear",
  },
  ἑωράκασιν: {
    parse: "V-RAI-3P｜Verb, perfect, active, indicative, third, plural",
    GN: "G3708",
    gloss: "appear",
  },
  ἑωράκατε: {
    parse: "V-RAI-2P｜Verb, perfect, active, indicative, second, plural",
    GN: "G3708",
    gloss: "appear",
  },
  ἑωράκατέ: {
    parse: "V-RAI-2P｜Verb, perfect, active, indicative, second, plural",
    GN: "G3708",
    gloss: "appear",
  },
  ἑωράκει: {
    parse: "V-LAI-3S｜Verb, pluperfect, active, indicative, third, singular",
    GN: "G3708",
    gloss: "appear",
  },
  ἑώρακεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G3708",
    gloss: "appear",
  },
  ἑώρακέν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G3708",
    gloss: "appear",
  },
  ἑωρακέναι: {
    parse: "V-RAN｜Verb, perfect, active, Infinitive",
    GN: "G3708",
    gloss: "appear",
  },
  ἑωρακότες: {
    parse:
      "V-RAP-NPM｜Verb, perfect, active, Participle, nominative, plural, masculine",
    GN: "G3708",
    gloss: "appear",
  },
  ἑωρακὼς: {
    parse:
      "V-RAP-NSM｜Verb, perfect, active, Participle, nominative, singular, masculine",
    GN: "G3708",
    gloss: "appear",
  },
  ὁρᾷ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3708",
    gloss: "appear",
  },
  Ὅρα: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G3708",
    gloss: "appear",
  },
  ὁρᾶτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G3708",
    gloss: "appear",
  },
  Ὁρᾶτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G3708",
    gloss: "appear",
  },
  ὁρῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G3708",
    gloss: "appear",
  },
  ὁρῶμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G3708",
    gloss: "appear",
  },
  ὁρῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3708",
    gloss: "appear",
  },
  ὁρῶντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G3708",
    gloss: "appear",
  },
  ὁρῶσαι: {
    parse:
      "V-PAP-NPF｜Verb, present, active, Participle, nominative, plural, feminine",
    GN: "G3708",
    gloss: "appear",
  },
  ὀφθείς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G3708",
    gloss: "appear",
  },
  ὀφθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G3708",
    gloss: "appear",
  },
  ὀφθέντος: {
    parse:
      "V-APP-GSM｜Verb, aorist, passive, Participle, genitive, singular, masculine",
    GN: "G3708",
    gloss: "appear",
  },
  ὀφθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G3708",
    gloss: "appear",
  },
  ὀφθήσομαί: {
    parse: "V-FPI-1S｜Verb, future, passive, indicative, first, singular",
    GN: "G3708",
    gloss: "appear",
  },
  ὄψεσθε: {
    parse:
      "V-FDI-2P｜Verb, future, Middle deponent, indicative, second, plural",
    GN: "G3708",
    gloss: "appear",
  },
  ὄψεσθέ: {
    parse:
      "V-FDI-2P｜Verb, future, Middle deponent, indicative, second, plural",
    GN: "G3708",
    gloss: "appear",
  },
  ὄψεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G3708",
    gloss: "appear",
  },
  ὄψῃ: {
    parse:
      "V-FDI-2S｜Verb, future, Middle deponent, indicative, second, singular",
    GN: "G3708",
    gloss: "appear",
  },
  ὄψησθε: {
    parse:
      "V-2ADS-2P｜Verb, 2nd aorist, Middle deponent, subjunctive, second, plural",
    GN: "G3708",
    gloss: "appear",
  },
  ὄψομαι: {
    parse:
      "V-FDI-1S｜Verb, future, Middle deponent, indicative, first, singular",
    GN: "G3708",
    gloss: "appear",
  },
  ὀψόμεθα: {
    parse: "V-FDI-1P｜Verb, future, Middle deponent, indicative, first, plural",
    GN: "G3708",
    gloss: "appear",
  },
  ὄψονται: {
    parse: "V-FDI-3P｜Verb, future, Middle deponent, indicative, third, plural",
    GN: "G3708",
    gloss: "appear",
  },
  Ὄψονται: {
    parse: "V-FDI-3P｜Verb, future, Middle deponent, indicative, third, plural",
    GN: "G3708",
    gloss: "appear",
  },
  ὤφθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G3708",
    gloss: "appear",
  },
  ὤφθην: {
    parse: "V-API-1S｜Verb, aorist, passive, indicative, first, singular",
    GN: "G3708",
    gloss: "appear",
  },
  ὤφθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G3708",
    gloss: "appear",
  },
  ὀργῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3709",
    gloss: "wrath",
  },
  ὀργή: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3709",
    gloss: "wrath",
  },
  ὀργὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3709",
    gloss: "wrath",
  },
  ὀργήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3709",
    gloss: "wrath",
  },
  ὀργὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3709",
    gloss: "wrath",
  },
  ὀργῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3709",
    gloss: "wrath",
  },
  ὀργίζεσθε: {
    parse: "V-PMM-2P｜Verb, present, middle, imperative, second, plural",
    GN: "G3710",
    gloss: "to anger",
  },
  ὀργιζόμενος: {
    parse:
      "V-PMP-NSM｜Verb, present, middle, Participle, nominative, singular, masculine",
    GN: "G3710",
    gloss: "to anger",
  },
  ὀργισθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G3710",
    gloss: "to anger",
  },
  ὠργίσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G3710",
    gloss: "to anger",
  },
  ὠργίσθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G3710",
    gloss: "to anger",
  },
  ὀργίλον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G3711",
    gloss: "quick-tempered",
  },
  ὀργυιὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G3712",
    gloss: "a fathom",
  },
  ὀρέγεται: {
    parse: "V-PMI-3S｜Verb, present, middle, indicative, third, singular",
    GN: "G3713",
    gloss: "to aspire",
  },
  ὀρεγόμενοι: {
    parse:
      "V-PMP-NPM｜Verb, present, middle, Participle, nominative, plural, masculine",
    GN: "G3713",
    gloss: "to aspire",
  },
  ὀρέγονται: {
    parse: "V-PMI-3P｜Verb, present, middle, indicative, third, plural",
    GN: "G3713",
    gloss: "to aspire",
  },
  ὀρεινῇ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G3714",
    gloss: "hilly",
  },
  ὀρεινὴν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G3714",
    gloss: "hilly",
  },
  ὀρέξει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3715",
    gloss: "lust",
  },
  ὀρθοποδοῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G3716",
    gloss: "be upright",
  },
  ὀρθὰς: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G3717",
    gloss: "upright",
  },
  ὀρθός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3717",
    gloss: "upright",
  },
  ὀρθοτομοῦντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G3718",
    gloss: "to cut straight",
  },
  ὤρθριζεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G3719",
    gloss: "to rise at dawn",
  },
  ὀρθριναὶ: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G3720",
    gloss: "dawn",
  },
  ὄρθρον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3722",
    gloss: "dawn",
  },
  ὄρθρου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3722",
    gloss: "dawn",
  },
  Ὄρθρου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3722",
    gloss: "dawn",
  },
  ὀρθῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3723",
    gloss: "correctly",
  },
  Ὀρθῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3723",
    gloss: "correctly",
  },
  ὁρίζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3724",
    gloss: "to determine",
  },
  ὁρίσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G3724",
    gloss: "to determine",
  },
  ὁρισθέντος: {
    parse:
      "V-APP-GSM｜Verb, aorist, passive, Participle, genitive, singular, masculine",
    GN: "G3724",
    gloss: "to determine",
  },
  ὥρισαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G3724",
    gloss: "to determine",
  },
  ὥρισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3724",
    gloss: "to determine",
  },
  ὡρισμένῃ: {
    parse:
      "V-RPP-DSF｜Verb, perfect, passive, Participle, dative, singular, feminine",
    GN: "G3724",
    gloss: "to determine",
  },
  ὡρισμένον: {
    parse:
      "V-RPP-ASN｜Verb, perfect, passive, Participle, accusative, singular, neuter",
    GN: "G3724",
    gloss: "to determine",
  },
  ὡρισμένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G3724",
    gloss: "to determine",
  },
  ὅρια: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G3725",
    gloss: "region",
  },
  ὁρίοις: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G3725",
    gloss: "region",
  },
  ὁρίων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G3725",
    gloss: "region",
  },
  ὁρκίζω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G3726",
    gloss: "to adjure",
  },
  Ὁρκίζω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G3726",
    gloss: "to adjure",
  },
  ὅρκον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3727",
    gloss: "oath",
  },
  ὅρκος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3727",
    gloss: "oath",
  },
  ὅρκου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3727",
    gloss: "oath",
  },
  ὅρκους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3727",
    gloss: "oath",
  },
  ὅρκῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3727",
    gloss: "oath",
  },
  ὁρκωμοσίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3728",
    gloss: "oath",
  },
  ὥρμησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G3729",
    gloss: "to stampede",
  },
  ὥρμησάν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G3729",
    gloss: "to stampede",
  },
  ὥρμησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3729",
    gloss: "to stampede",
  },
  ὁρμὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3730",
    gloss: "impulse",
  },
  ὁρμήματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G3731",
    gloss: "sudden violence",
  },
  ὄρνεα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G3732",
    gloss: "bird",
  },
  ὀρνέοις: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G3732",
    gloss: "bird",
  },
  ὀρνέου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G3732",
    gloss: "bird",
  },
  ὄρνις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3733",
    gloss: "hen",
  },
  ὁροθεσίας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G3734",
    gloss: "fixed boundary",
  },
  ὄρει: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G3735",
    gloss: "mountain",
  },
  ὄρεσιν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G3735",
    gloss: "mountain",
  },
  ὀρέων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G3735",
    gloss: "mountain",
  },
  ὄρη: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G3735",
    gloss: "mountain",
  },
  ὄρος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G3735",
    gloss: "mountain",
  },
  Ὄρος: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G3735",
    gloss: "mountain",
  },
  ὄρους: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G3735",
    gloss: "mountain",
  },
  Ὄρους: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G3735",
    gloss: "mountain",
  },
  ὤρυξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3736",
    gloss: "to dig",
  },
  ὀρφανούς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G3737",
    gloss: "orphan",
  },
  ὀρφανοὺς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G3737",
    gloss: "orphan",
  },
  ὀρχησαμένης: {
    parse:
      "V-ADP-GSF｜Verb, aorist, Middle deponent, Participle, genitive, singular, feminine",
    GN: "G3738",
    gloss: "to dance",
  },
  ὠρχήσασθε: {
    parse:
      "V-ADI-2P｜Verb, aorist, Middle deponent, indicative, second, plural",
    GN: "G3738",
    gloss: "to dance",
  },
  ὠρχήσατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G3738",
    gloss: "to dance",
  },
  ἅ: {
    parse: "R-NPN｜Relative pronoun, nominative, plural, neuter",
    GN: "G3739",
    gloss: "which",
  },
  ἃ: {
    parse: "R-NPN｜Relative pronoun, nominative, plural, neuter",
    GN: "G3739",
    gloss: "which",
  },
  Ἃ: {
    parse: "R-APN｜Relative pronoun, accusative, plural, neuter",
    GN: "G3739",
    gloss: "which",
  },
  αἳ: {
    parse: "R-NPF｜Relative pronoun, nominative, plural, feminine",
    GN: "G3739",
    gloss: "which",
  },
  αἷς: {
    parse: "R-DPF｜Relative pronoun, dative, plural, feminine",
    GN: "G3739",
    gloss: "which",
  },
  ἃς: {
    parse: "R-APF｜Relative pronoun, accusative, plural, feminine",
    GN: "G3739",
    gloss: "which",
  },
  ᾗ: {
    parse: "R-DSF｜Relative pronoun, dative, singular, feminine",
    GN: "G3739",
    gloss: "which",
  },
  ἣ: {
    parse: "R-NSF｜Relative pronoun, nominative, singular, feminine",
    GN: "G3739",
    gloss: "which",
  },
  ἥν: {
    parse: "R-ASF｜Relative pronoun, accusative, singular, feminine",
    GN: "G3739",
    gloss: "which",
  },
  ἣν: {
    parse: "R-ASF｜Relative pronoun, accusative, singular, feminine",
    GN: "G3739",
    gloss: "which",
  },
  ἧς: {
    parse: "R-GSF｜Relative pronoun, genitive, singular, feminine",
    GN: "G3739",
    gloss: "which",
  },
  Ὅ: {
    parse: "R-ASN｜Relative pronoun, accusative, singular, neuter",
    GN: "G3739",
    gloss: "which",
  },
  ὃ: {
    parse: "R-NSN｜Relative pronoun, nominative, singular, neuter",
    GN: "G3739",
    gloss: "which",
  },
  Ὃ: {
    parse: "R-NSN｜Relative pronoun, nominative, singular, neuter",
    GN: "G3739",
    gloss: "which",
  },
  οἳ: {
    parse: "R-NPM｜Relative pronoun, nominative, plural, masculine",
    GN: "G3739",
    gloss: "which",
  },
  οἷς: {
    parse: "R-DPN｜Relative pronoun, dative, plural, neuter",
    GN: "G3739",
    gloss: "which",
  },
  Οἷς: {
    parse: "R-DPM｜Relative pronoun, dative, plural, masculine",
    GN: "G3739",
    gloss: "which",
  },
  ὃν: {
    parse: "R-ASM｜Relative pronoun, accusative, singular, masculine",
    GN: "G3739",
    gloss: "which",
  },
  Ὃν: {
    parse: "R-ASM｜Relative pronoun, accusative, singular, masculine",
    GN: "G3739",
    gloss: "which",
  },
  ὅς: {
    parse: "R-NSM｜Relative pronoun, nominative, singular, masculine",
    GN: "G3739",
    gloss: "which",
  },
  ὃς: {
    parse: "R-NSM｜Relative pronoun, nominative, singular, masculine",
    GN: "G3739",
    gloss: "which",
  },
  Ὃς: {
    parse: "R-NSM｜Relative pronoun, nominative, singular, masculine",
    GN: "G3739",
    gloss: "which",
  },
  οὗ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3757",
    gloss: "whither",
  },
  οὓς: {
    parse: "R-APM｜Relative pronoun, accusative, plural, masculine",
    GN: "G3739",
    gloss: "which",
  },
  Οὓς: {
    parse: "R-APM｜Relative pronoun, accusative, plural, masculine",
    GN: "G3739",
    gloss: "which",
  },
  ᾧ: {
    parse: "R-DSN｜Relative pronoun, dative, singular, neuter",
    GN: "G3739",
    gloss: "which",
  },
  ὧν: {
    parse: "R-GPN｜Relative pronoun, genitive, plural, neuter",
    GN: "G3739",
    gloss: "which",
  },
  ὁσάκις: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G3740",
    gloss: "whenever",
  },
  ὅσια: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G3741",
    gloss: "sacred",
  },
  ὅσιον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G3741",
    gloss: "sacred",
  },
  ὅσιόν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G3741",
    gloss: "sacred",
  },
  ὅσιος: {
    parse: "A-VSM｜Adjective, Vocative or nominative, singular, masculine",
    GN: "G3741",
    gloss: "sacred",
  },
  ὁσίους: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G3741",
    gloss: "sacred",
  },
  ὁσιότητι: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3742",
    gloss: "holiness",
  },
  ὁσίως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3743",
    gloss: "devoutly",
  },
  ὀσμὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3744",
    gloss: "aroma",
  },
  ὀσμὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3744",
    gloss: "aroma",
  },
  ὀσμῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3744",
    gloss: "aroma",
  },
  ὅσα: {
    parse: "K-NPN｜Correlative pronoun, nominative, plural, neuter",
    GN: "G3745",
    gloss: "just as/how much",
  },
  ὅσαι: {
    parse: "K-NPF｜Correlative pronoun, nominative, plural, feminine",
    GN: "G3745",
    gloss: "just as/how much",
  },
  ὅσοι: {
    parse: "K-NPM｜Correlative pronoun, nominative, plural, masculine",
    GN: "G3745",
    gloss: "just as/how much",
  },
  Ὅσοι: {
    parse: "K-NPM｜Correlative pronoun, nominative, plural, masculine",
    GN: "G3745",
    gloss: "just as/how much",
  },
  ὅσον: {
    parse: "K-NSN｜Correlative pronoun, nominative, singular, neuter",
    GN: "G3745",
    gloss: "just as/how much",
  },
  ὅσους: {
    parse: "K-APM｜Correlative pronoun, accusative, plural, masculine",
    GN: "G3745",
    gloss: "just as/how much",
  },
  ὅσῳ: {
    parse: "K-DSN｜Correlative pronoun, dative, singular, neuter",
    GN: "G3745",
    gloss: "just as/how much",
  },
  ὅσων: {
    parse: "K-GPM｜Correlative pronoun, genitive, plural, masculine",
    GN: "G3745",
    gloss: "just as/how much",
  },
  ὀστέα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G3747",
    gloss: "bone",
  },
  ὀστέων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G3747",
    gloss: "bone",
  },
  Ὀστοῦν: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G3747",
    gloss: "bone",
  },
  αἵτινες: {
    parse: "R-NPF｜Relative pronoun, nominative, plural, feminine",
    GN: "G3748",
    gloss: "who/which",
  },
  ἅτινα: {
    parse: "R-NPN｜Relative pronoun, nominative, plural, neuter",
    GN: "G3748",
    gloss: "who/which",
  },
  ἅτινά: {
    parse: "R-NPN｜Relative pronoun, nominative, plural, neuter",
    GN: "G3748",
    gloss: "who/which",
  },
  ἥτις: {
    parse: "R-NSF｜Relative pronoun, nominative, singular, feminine",
    GN: "G3748",
    gloss: "who/which",
  },
  οἵτινες: {
    parse: "R-NPM｜Relative pronoun, nominative, plural, masculine",
    GN: "G3748",
    gloss: "who/which",
  },
  οἵτινές: {
    parse: "R-NPM｜Relative pronoun, nominative, plural, masculine",
    GN: "G3748",
    gloss: "who/which",
  },
  ὅστις: {
    parse: "R-NSM｜Relative pronoun, nominative, singular, masculine",
    GN: "G3748",
    gloss: "who/which",
  },
  ὅτου: {
    parse: "R-GSN｜Relative pronoun, genitive, singular, neuter",
    GN: "G3748",
    gloss: "who/which",
  },
  ὀστράκινα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G3749",
    gloss: "clay",
  },
  ὀστρακίνοις: {
    parse: "A-DPN｜Adjective, dative, plural, neuter",
    GN: "G3749",
    gloss: "clay",
  },
  ὄσφρησις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3750",
    gloss: "sense of smell",
  },
  ὀσφύας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G3751",
    gloss: "loins",
  },
  ὀσφύες: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G3751",
    gloss: "loins",
  },
  ὀσφύϊ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3751",
    gloss: "loins",
  },
  ὀσφὺν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3751",
    gloss: "loins",
  },
  ὀσφύος: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3751",
    gloss: "loins",
  },
  ὅταν: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G3752",
    gloss: "when(-ever)",
  },
  Ὅταν: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G3752",
    gloss: "when(-ever)",
  },
  ὅτε: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G3753",
    gloss: "when",
  },
  Ὅτε: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G3753",
    gloss: "when",
  },
  ὅτι: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G3754",
    gloss: "that/because/since",
  },
  Ὅτι: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G3754",
    gloss: "that/because/since",
  },
  οὐ: { parse: "PRT-N｜Particle, Negative", GN: "G3756", gloss: "no" },
  Οὐ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3757",
    gloss: "whither",
  },
  οὔ: { parse: "PRT-N｜Particle, Negative", GN: "G3756", gloss: "no" },
  Οὔ: { parse: "PRT-N｜Particle, Negative", GN: "G3756", gloss: "no" },
  οὒ: { parse: "PRT-N｜Particle, Negative", GN: "G3756", gloss: "no" },
  Οὒ: { parse: "PRT-N｜Particle, Negative", GN: "G3756", gloss: "no" },
  οὐκ: { parse: "PRT-N｜Particle, Negative", GN: "G3756", gloss: "no" },
  Οὐκ: { parse: "PRT-N｜Particle, Negative", GN: "G3756", gloss: "no" },
  οὐχ: { parse: "PRT-N｜Particle, Negative", GN: "G3780", gloss: "not" },
  Οὐχ: { parse: "PRT-N｜Particle, Negative", GN: "G3780", gloss: "not" },
  Οὐὰ: {
    parse: "PRT｜Particle, disjunctive particle",
    GN: "G3758",
    gloss: "aha!",
  },
  οὐαί: { parse: "INJ｜Interjection", GN: "G3759", gloss: "woe!" },
  Οὐαί: { parse: "INJ｜Interjection", GN: "G3759", gloss: "woe!" },
  οὐαὶ: { parse: "INJ｜Interjection", GN: "G3759", gloss: "woe!" },
  Οὐαὶ: { parse: "INJ｜Interjection", GN: "G3759", gloss: "woe!" },
  οὐδαμῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3760",
    gloss: "by no means",
  },
  οὐδʼ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G3761",
    gloss: "and not",
  },
  οὐδέ: {
    parse: "CONJ-N｜Conjunction, Negative",
    GN: "G3761",
    gloss: "and not",
  },
  οὐδὲ: {
    parse: "CONJ-N｜Conjunction, Negative",
    GN: "G3761",
    gloss: "and not",
  },
  Οὐδὲ: {
    parse: "CONJ-N｜Conjunction, Negative",
    GN: "G3761",
    gloss: "and not",
  },
  οὐδείς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3762",
    gloss: "none",
  },
  Οὐδείς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3762",
    gloss: "none",
  },
  οὐδεὶς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3762",
    gloss: "none",
  },
  Οὐδεὶς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3762",
    gloss: "none",
  },
  οὐδεμία: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G3762",
    gloss: "none",
  },
  οὐδεμίαν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G3762",
    gloss: "none",
  },
  οὐδέν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G3762",
    gloss: "none",
  },
  οὐδὲν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G3762",
    gloss: "none",
  },
  Οὐδὲν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G3762",
    gloss: "none",
  },
  οὐδένα: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G3762",
    gloss: "none",
  },
  οὐδενὶ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G3762",
    gloss: "none",
  },
  οὐδενός: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G3762",
    gloss: "none",
  },
  οὐδενὸς: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G3762",
    gloss: "none",
  },
  οὐθέν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G3762",
    gloss: "none",
  },
  οὐθὲν: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G3762",
    gloss: "none",
  },
  οὐθενός: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G3762",
    gloss: "none",
  },
  Οὐθενός: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G3762",
    gloss: "none",
  },
  οὐδέποτε: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3763",
    gloss: "never",
  },
  Οὐδέποτε: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3763",
    gloss: "never",
  },
  οὐδέπω: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3764",
    gloss: "not yet",
  },
  οὐκέτι: {
    parse: "ADV-N｜Adverb, Negative",
    GN: "G3765",
    gloss: "not any more",
  },
  Οὐκέτι: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3765",
    gloss: "not any more",
  },
  Οὐκοῦν: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G3766",
    gloss: "so",
  },
  οὖν: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G3767",
    gloss: "therefore/then",
  },
  οὔπω: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3768",
    gloss: "not yet",
  },
  Οὔπω: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3768",
    gloss: "not yet",
  },
  οὐρὰ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3769",
    gloss: "tail",
  },
  οὐραὶ: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G3769",
    gloss: "tail",
  },
  οὐραῖς: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G3769",
    gloss: "tail",
  },
  οὐρὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G3769",
    gloss: "tail",
  },
  οὐράνιος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3770",
    gloss: "heavenly",
  },
  οὐρανίου: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G3770",
    gloss: "heavenly",
  },
  οὐρανίῳ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G3770",
    gloss: "heavenly",
  },
  οὐρανόθεν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3771",
    gloss: "from heaven",
  },
  οὐρανέ: {
    // or nominative
    parse: "N-VSM｜Noun, Vocative, singular, masculine",
    GN: "G3772",
    gloss: "heaven",
  },
  οὐρανοί: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3772",
    gloss: "heaven",
  },
  οὐρανοὶ: {
    parse: "N-VPM｜Noun, Vocative or nominative, plural, masculine",
    GN: "G3772",
    gloss: "heaven",
  },
  οὐρανοῖς: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G3772",
    gloss: "heaven",
  },
  οὐρανόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3772",
    gloss: "heaven",
  },
  οὐρανὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3772",
    gloss: "heaven",
  },
  οὐρανός: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3772",
    gloss: "heaven",
  },
  οὐρανὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3772",
    gloss: "heaven",
  },
  οὐρανοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3772",
    gloss: "heaven",
  },
  οὐρανούς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3772",
    gloss: "heaven",
  },
  οὐρανοὺς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3772",
    gloss: "heaven",
  },
  οὐρανῷ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3772",
    gloss: "heaven",
  },
  οὐρανῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G3772",
    gloss: "heaven",
  },
  Οὐρβανὸν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G3773",
    gloss: "Urbanus",
  },
  Οὐρίου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G3774",
    gloss: "Uriah",
  },
  οὖς: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G3775",
    gloss: "ear",
  },
  ὠσίν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G3775",
    gloss: "ear",
  },
  ὠσὶν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G3775",
    gloss: "ear",
  },
  ὦτα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G3775",
    gloss: "ear",
  },
  ὦτά: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G3775",
    gloss: "ear",
  },
  οὐσίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3776",
    gloss: "estate",
  },
  οὐσίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3776",
    gloss: "estate",
  },
  οὔτε: {
    parse: "CONJ-N｜Conjunction, Negative",
    GN: "G3777",
    gloss: "neither",
  },
  Οὔτε: {
    parse: "CONJ-N｜Conjunction, Negative",
    GN: "G3777",
    gloss: "neither",
  },
  αὗται: {
    parse: "D-NPF｜Demonstrative pronoun, nominative, plural, feminine",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  αὗταί: {
    parse: "D-NPF｜Demonstrative pronoun, nominative, plural, feminine",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  αὕτη: {
    parse: "D-NSF｜Demonstrative pronoun, nominative, singular, feminine",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  Αὕτη: {
    parse: "D-NSF｜Demonstrative pronoun, nominative, singular, feminine",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  οὗτοι: {
    parse: "D-NPM｜Demonstrative pronoun, nominative, plural, masculine",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  Οὗτοι: {
    parse: "D-NPM｜Demonstrative pronoun, nominative, plural, masculine",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  οὗτοί: {
    parse: "D-NPM｜Demonstrative pronoun, nominative, plural, masculine",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  Οὗτοί: {
    parse: "D-NPM｜Demonstrative pronoun, nominative, plural, masculine",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  οὗτος: {
    parse: "D-NSM｜Demonstrative pronoun, nominative, singular, masculine",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  Οὗτος: {
    parse: "D-NSM｜Demonstrative pronoun, nominative, singular, masculine",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  οὗτός: {
    parse: "D-NSM｜Demonstrative pronoun, nominative, singular, masculine",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  Οὗτός: {
    parse: "D-NSM｜Demonstrative pronoun, nominative, singular, masculine",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  ταῦτα: {
    parse: "D-NPN｜Demonstrative pronoun, nominative, plural, neuter",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  Ταῦτα: {
    parse: "D-NPN｜Demonstrative pronoun, nominative, plural, neuter",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  Ταῦτά: {
    parse: "D-APN｜Demonstrative pronoun, accusative, plural, neuter",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  ταῦτά: {
    parse: "D-NPN｜Demonstrative pronoun, nominative, plural, neuter",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  ταύταις: {
    parse: "D-DPF｜Demonstrative pronoun, dative, plural, feminine",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  ταύτας: {
    parse: "D-APF｜Demonstrative pronoun, accusative, plural, feminine",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  ταύτῃ: {
    parse: "D-DSF｜Demonstrative pronoun, dative, singular, feminine",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  ταύτην: {
    parse: "D-ASF｜Demonstrative pronoun, accusative, singular, feminine",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  Ταύτην: {
    parse: "D-ASF｜Demonstrative pronoun, accusative, singular, feminine",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  ταύτης: {
    parse: "D-GSF｜Demonstrative pronoun, genitive, singular, feminine",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  τοῦτʼ: {
    parse: "D-NSN｜Demonstrative pronoun, nominative, singular, neuter",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  τοῦτο: {
    parse: "D-NSN｜Demonstrative pronoun, nominative, singular, neuter",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  Τοῦτο: {
    parse: "D-NSN｜Demonstrative pronoun, nominative, singular, neuter",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  τοῦτό: {
    parse: "D-NSN｜Demonstrative pronoun, nominative, singular, neuter",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  Τοῦτό: {
    parse: "D-NSN｜Demonstrative pronoun, nominative, singular, neuter",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  τούτοις: {
    parse: "D-DPN｜Demonstrative pronoun, dative, plural, neuter",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  τοῦτον: {
    parse: "D-ASM｜Demonstrative pronoun, accusative, singular, masculine",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  Τοῦτον: {
    parse: "D-ASM｜Demonstrative pronoun, accusative, singular, masculine",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  τούτου: {
    parse: "D-GSN｜Demonstrative pronoun, genitive, singular, neuter",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  Τούτου: {
    parse: "D-GSN｜Demonstrative pronoun, genitive, singular, neuter",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  τούτους: {
    parse: "D-APM｜Demonstrative pronoun, accusative, plural, masculine",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  Τούτους: {
    parse: "D-APM｜Demonstrative pronoun, accusative, plural, masculine",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  τούτῳ: {
    parse: "D-DSN｜Demonstrative pronoun, dative, singular, neuter",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  τούτων: {
    parse: "D-GPN｜Demonstrative pronoun, genitive, plural, neuter",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  Τούτων: {
    parse: "D-GPN｜Demonstrative pronoun, genitive, plural, neuter",
    GN: "G3778",
    gloss: "this/he/she/it",
  },
  οὕτω: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3779",
    gloss: "thus(-ly)",
  },
  οὕτως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3779",
    gloss: "thus(-ly)",
  },
  Οὕτως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3779",
    gloss: "thus(-ly)",
  },
  οὐχί: { parse: "PRT-N｜Particle, Negative", GN: "G3780", gloss: "not" },
  Οὐχί: { parse: "PRT-N｜Particle, Negative", GN: "G3780", gloss: "not" },
  οὐχὶ: { parse: "PRT-N｜Particle, Negative", GN: "G3780", gloss: "not" },
  Οὐχὶ: { parse: "PRT-N｜Particle, Negative", GN: "G3780", gloss: "not" },
  ὀφειλέται: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3781",
    gloss: "debtor",
  },
  ὀφειλέταις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G3781",
    gloss: "debtor",
  },
  ὀφειλέτης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3781",
    gloss: "debtor",
  },
  ὀφειλάς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G3782",
    gloss: "debt",
  },
  ὀφειλὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3782",
    gloss: "debt",
  },
  ὀφείλημα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G3783",
    gloss: "debt",
  },
  ὀφειλήματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G3783",
    gloss: "debt",
  },
  ὀφείλει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3784",
    gloss: "to owe",
  },
  ὀφείλεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G3784",
    gloss: "to owe",
  },
  ὀφείλετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G3784",
    gloss: "to owe",
  },
  ὀφείλομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G3784",
    gloss: "to owe",
  },
  Ὀφείλομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G3784",
    gloss: "to owe",
  },
  ὀφειλόμενον: {
    parse:
      "V-PPP-ASN｜Verb, present, passive, Participle, accusative, singular, neuter",
    GN: "G3784",
    gloss: "to owe",
  },
  ὀφείλοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G3784",
    gloss: "to owe",
  },
  ὀφείλοντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G3784",
    gloss: "to owe",
  },
  ὀφείλουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G3784",
    gloss: "to owe",
  },
  ὤφειλεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G3784",
    gloss: "to owe",
  },
  ὠφείλετε: {
    parse: "V-IAI-2P｜Verb, imperfect, active, indicative, second, plural",
    GN: "G3784",
    gloss: "to owe",
  },
  ὠφείλομεν: {
    parse: "V-IAI-1P｜Verb, imperfect, active, indicative, first, plural",
    GN: "G3784",
    gloss: "to owe",
  },
  ὤφειλον: {
    parse: "V-IAI-1S｜Verb, imperfect, active, indicative, first, singular",
    GN: "G3784",
    gloss: "to owe",
  },
  ὄφελον: {
    parse: "PRT｜Particle, disjunctive particle",
    GN: "G3785",
    gloss: "I wish!",
  },
  Ὄφελον: {
    parse: "PRT｜Particle, disjunctive particle",
    GN: "G3785",
    gloss: "I wish!",
  },
  ὄφελόν: {
    parse: "PRT｜Particle, disjunctive particle",
    GN: "G3785",
    gloss: "I wish!",
  },
  ὄφελος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G3786",
    gloss: "gain",
  },
  ὀφθαλμοδουλίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3787",
    gloss: "eye-service",
  },
  ὀφθαλμοδουλίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3787",
    gloss: "eye-service",
  },
  ὀφθαλμοί: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3788",
    gloss: "eye",
  },
  ὀφθαλμοὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3788",
    gloss: "eye",
  },
  ὀφθαλμοῖς: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G3788",
    gloss: "eye",
  },
  Ὀφθαλμὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3788",
    gloss: "eye",
  },
  ὀφθαλμός: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3788",
    gloss: "eye",
  },
  ὀφθαλμὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3788",
    gloss: "eye",
  },
  ὀφθαλμοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3788",
    gloss: "eye",
  },
  ὀφθαλμούς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3788",
    gloss: "eye",
  },
  ὀφθαλμοὺς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3788",
    gloss: "eye",
  },
  ὀφθαλμῷ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3788",
    gloss: "eye",
  },
  ὀφθαλμῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G3788",
    gloss: "eye",
  },
  ὄφεις: {
    parse: "N-VPM｜Noun, Vocative or nominative, plural, masculine",
    GN: "G3789",
    gloss: "snake",
  },
  ὄφεσιν: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G3789",
    gloss: "snake",
  },
  ὄφεων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G3789",
    gloss: "snake",
  },
  ὄφεως: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3789",
    gloss: "snake",
  },
  ὄφιν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3789",
    gloss: "snake",
  },
  ὄφις: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3789",
    gloss: "snake",
  },
  ὀφρύος: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3790",
    gloss: "brow",
  },
  ὀχλουμένους: {
    parse:
      "V-PPP-APM｜Verb, present, passive, Participle, accusative, plural, masculine",
    GN: "G3791",
    gloss: "to disturb",
  },
  ὀχλοποιήσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G3792",
    gloss: "to riot",
  },
  ὄχλοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3793",
    gloss: "crowd",
  },
  ὄχλοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G3793",
    gloss: "crowd",
  },
  ὄχλον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3793",
    gloss: "crowd",
  },
  ὄχλος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3793",
    gloss: "crowd",
  },
  ὄχλου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3793",
    gloss: "crowd",
  },
  ὄχλους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3793",
    gloss: "crowd",
  },
  ὄχλῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3793",
    gloss: "crowd",
  },
  ὄχλων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G3793",
    gloss: "crowd",
  },
  ὀχυρωμάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G3794",
    gloss: "stronghold",
  },
  ὀψάρια: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G3795",
    gloss: "fish",
  },
  ὀψάριον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G3795",
    gloss: "fish",
  },
  ὀψαρίων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G3795",
    gloss: "fish",
  },
  ὀψὲ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3796",
    gloss: "late",
  },
  Ὀψὲ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3796",
    gloss: "late",
  },
  ὄψιμον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G3797",
    gloss: "late (rain)",
  },
  ὀψία: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G3798",
    gloss: "evening",
  },
  ὀψίας: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G3798",
    gloss: "evening",
  },
  Ὀψίας: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G3798",
    gloss: "evening",
  },
  ὄψιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3799",
    gloss: "face",
  },
  ὄψις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3799",
    gloss: "face",
  },
  ὀψώνια: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G3800",
    gloss: "compensation",
  },
  ὀψωνίοις: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G3800",
    gloss: "compensation",
  },
  ὀψώνιον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G3800",
    gloss: "compensation",
  },
  παγιδεύσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G3802",
    gloss: "to trap",
  },
  παγίδα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3803",
    gloss: "trap",
  },
  παγίδος: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3803",
    gloss: "trap",
  },
  παγίς: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3803",
    gloss: "trap",
  },
  πάθημα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G3804",
    gloss: "suffering",
  },
  παθήμασιν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G3804",
    gloss: "suffering",
  },
  παθήματα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G3804",
    gloss: "suffering",
  },
  παθημάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G3804",
    gloss: "suffering",
  },
  παθητὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3805",
    gloss: "suffering",
  },
  πάθει: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G3806",
    gloss: "passion",
  },
  πάθη: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G3806",
    gloss: "passion",
  },
  πάθος: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G3806",
    gloss: "passion",
  },
  παιδαγωγόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3807",
    gloss: "guardian",
  },
  παιδαγωγὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3807",
    gloss: "guardian",
  },
  παιδαγωγοὺς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3807",
    gloss: "guardian",
  },
  παιδάριον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G3808",
    gloss: "boy",
  },
  παιδείᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3809",
    gloss: "discipline",
  },
  παιδεία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3809",
    gloss: "discipline",
  },
  παιδείαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3809",
    gloss: "discipline",
  },
  παιδείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3809",
    gloss: "discipline",
  },
  παιδευτὰς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3810",
    gloss: "instructor",
  },
  παιδευτὴν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3810",
    gloss: "instructor",
  },
  ἐπαιδεύθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G3811",
    gloss: "to instruct",
  },
  ἐπαίδευον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G3811",
    gloss: "to instruct",
  },
  παιδεύει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3811",
    gloss: "to instruct",
  },
  παιδευθῶσι: {
    parse: "V-APS-3P｜Verb, aorist, passive, subjunctive, third, plural",
    GN: "G3811",
    gloss: "to instruct",
  },
  παιδευθῶσιν: {
    parse: "V-APS-3P｜Verb, aorist, passive, subjunctive, third, plural",
    GN: "G3811",
    gloss: "to instruct",
  },
  παιδευόμεθα: {
    parse: "V-PPI-1P｜Verb, present, passive, indicative, first, plural",
    GN: "G3811",
    gloss: "to instruct",
  },
  παιδευόμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G3811",
    gloss: "to instruct",
  },
  παιδεύοντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G3811",
    gloss: "to instruct",
  },
  παιδεύουσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G3811",
    gloss: "to instruct",
  },
  παιδεύσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G3811",
    gloss: "to instruct",
  },
  παιδεύω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G3811",
    gloss: "to instruct",
  },
  πεπαιδευμένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G3811",
    gloss: "to instruct",
  },
  παιδιόθεν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3812",
    gloss: "from childhood",
  },
  παιδία: {
    parse: "N-VPN｜Noun, Vocative or nominative, plural, neuter",
    GN: "G3813",
    gloss: "child",
  },
  Παιδία: {
    parse: "N-VPN｜Noun, Vocative or nominative, plural, neuter",
    GN: "G3813",
    gloss: "child",
  },
  παιδίοις: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G3813",
    gloss: "child",
  },
  παιδίον: {
    parse: "N-VSN｜Noun, Vocative or nominative, singular, neuter",
    GN: "G3813",
    gloss: "child",
  },
  παιδίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G3813",
    gloss: "child",
  },
  παιδίων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G3813",
    gloss: "child",
  },
  παιδίσκας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G3814",
    gloss: "maidservant",
  },
  παιδίσκη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3814",
    gloss: "maidservant",
  },
  παιδίσκην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3814",
    gloss: "maidservant",
  },
  παιδίσκης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3814",
    gloss: "maidservant",
  },
  παιδισκῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G3814",
    gloss: "maidservant",
  },
  παίζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3815",
    gloss: "to play",
  },
  παῖδα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3816",
    gloss: "child",
  },
  παῖδά: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3816",
    gloss: "child",
  },
  παῖδας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3816",
    gloss: "child",
  },
  παιδὸς: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3816",
    gloss: "child",
  },
  παιδός: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3816",
    gloss: "child",
  },
  παίδων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G3816",
    gloss: "child",
  },
  παῖς: {
    parse: "N-VSF｜Noun, Vocative or nominative, singular, feminine",
    GN: "G3816",
    gloss: "child",
  },
  παισὶν: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G3816",
    gloss: "child",
  },
  ἔπαισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3817",
    gloss: "to strike",
  },
  παίσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G3817",
    gloss: "to strike",
  },
  παίσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G3817",
    gloss: "to strike",
  },
  πάλαι: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3819",
    gloss: "of old",
  },
  Πάλαι: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3819",
    gloss: "of old",
  },
  παλαιά: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G3820",
    gloss: "old",
  },
  παλαιᾷ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G3820",
    gloss: "old",
  },
  παλαιὰν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G3820",
    gloss: "old",
  },
  παλαιᾶς: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G3820",
    gloss: "old",
  },
  παλαιὸν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G3820",
    gloss: "old",
  },
  παλαιόν: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G3820",
    gloss: "old",
  },
  παλαιὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3820",
    gloss: "old",
  },
  παλαιοῦ: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G3820",
    gloss: "old",
  },
  παλαιούς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G3820",
    gloss: "old",
  },
  παλαιῷ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G3820",
    gloss: "old",
  },
  παλαιότητι: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3821",
    gloss: "oldness",
  },
  παλαιούμενα: {
    parse:
      "V-PPP-APN｜Verb, present, passive, Participle, accusative, plural, neuter",
    GN: "G3822",
    gloss: "to make old",
  },
  παλαιούμενον: {
    parse:
      "V-PPP-NSN｜Verb, present, passive, Participle, nominative, singular, neuter",
    GN: "G3822",
    gloss: "to make old",
  },
  παλαιωθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G3822",
    gloss: "to make old",
  },
  πεπαλαίωκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G3822",
    gloss: "to make old",
  },
  πάλη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3823",
    gloss: "struggle",
  },
  παλιγγενεσίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3824",
    gloss: "regeneration",
  },
  παλιγγενεσίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3824",
    gloss: "regeneration",
  },
  πάλιν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3825",
    gloss: "again",
  },
  Πάλιν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3825",
    gloss: "again",
  },
  παμπληθεὶ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3826",
    gloss: "together",
  },
  Παμφυλίαν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G3828",
    gloss: "Pamphylia",
  },
  Παμφυλίας: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G3828",
    gloss: "Pamphylia",
  },
  πανδοχεῖον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G3829",
    gloss: "inn",
  },
  πανδοχεῖ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3830",
    gloss: "innkeeper",
  },
  πανηγύρει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3831",
    gloss: "assembly",
  },
  πανοικεὶ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3832",
    gloss: "with all the house",
  },
  πανοπλίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3833",
    gloss: "complete armor",
  },
  πανουργίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3834",
    gloss: "craftiness",
  },
  πανουργίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3834",
    gloss: "craftiness",
  },
  πανοῦργος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3835",
    gloss: "crafty",
  },
  πανταχοῦ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3837",
    gloss: "everywhere",
  },
  παντελές: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G3838",
    gloss: "completely",
  },
  παντελὲς: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G3838",
    gloss: "completely",
  },
  πάντῃ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3839",
    gloss: "always",
  },
  πάντοθεν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3840",
    gloss: "from all sides",
  },
  παντοκράτορος: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3841",
    gloss: "almighty",
  },
  παντοκράτωρ: {
    parse: "N-VSM｜Noun, Vocative or nominative, singular, masculine",
    GN: "G3841",
    gloss: "almighty",
  },
  πάντοτε: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3842",
    gloss: "always",
  },
  πάντοτέ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3842",
    gloss: "always",
  },
  πάντως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3843",
    gloss: "surely",
  },
  Πάντως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3843",
    gloss: "surely",
  },
  παρʼ: { parse: "PREP｜Preposition", GN: "G3844", gloss: "from/with/beside" },
  παρ: { parse: "PREP｜Preposition", GN: "G3844", gloss: "from/with/beside" },
  παρά: { parse: "PREP｜Preposition", GN: "G3844", gloss: "from/with/beside" },
  παρὰ: { parse: "PREP｜Preposition", GN: "G3844", gloss: "from/with/beside" },
  Παρὰ: { parse: "PREP｜Preposition", GN: "G3844", gloss: "from/with/beside" },
  παραβαίνετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G3845",
    gloss: "to transgress",
  },
  παραβαίνουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G3845",
    gloss: "to transgress",
  },
  παρέβη: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G3845",
    gloss: "to transgress",
  },
  παρεβάλομεν: {
    parse: "V-2AAI-1P｜Verb, 2nd aorist, active, indicative, first, plural",
    GN: "G3846",
    gloss: "to arrive",
  },
  παραβάσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3847",
    gloss: "transgression",
  },
  παραβάσεων: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G3847",
    gloss: "transgression",
  },
  παραβάσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3847",
    gloss: "transgression",
  },
  παράβασις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3847",
    gloss: "transgression",
  },
  παραβάται: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3848",
    gloss: "transgresor",
  },
  παραβάτην: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3848",
    gloss: "transgresor",
  },
  παραβάτης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3848",
    gloss: "transgresor",
  },
  παρεβιάσαντο: {
    parse: "V-ADI-3P｜Verb, aorist, Middle deponent, indicative, third, plural",
    GN: "G3849",
    gloss: "to urge",
  },
  παρεβιάσατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G3849",
    gloss: "to urge",
  },
  παραβολαῖς: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G3850",
    gloss: "parable",
  },
  παραβολάς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G3850",
    gloss: "parable",
  },
  παραβολὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G3850",
    gloss: "parable",
  },
  παραβολῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3850",
    gloss: "parable",
  },
  παραβολή: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3850",
    gloss: "parable",
  },
  παραβολὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3850",
    gloss: "parable",
  },
  παραβολήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3850",
    gloss: "parable",
  },
  παραβολὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3850",
    gloss: "parable",
  },
  παραβολῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3850",
    gloss: "parable",
  },
  παραβολευσάμενος: {
    parse:
      "V-ADP-NSM｜Verb, aorist, Middle deponent, Participle, nominative, singular, masculine",
    GN: "G3851",
    gloss: "to risk",
  },
  Παραγγελίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3852",
    gloss: "order",
  },
  παραγγελίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3852",
    gloss: "order",
  },
  παραγγελίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3852",
    gloss: "order",
  },
  παραγγείλαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G3853",
    gloss: "to order",
  },
  παραγγείλας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G3853",
    gloss: "to order",
  },
  παραγγείλῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G3853",
    gloss: "to order",
  },
  παράγγελλε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G3853",
    gloss: "to order",
  },
  Παράγγελλε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G3853",
    gloss: "to order",
  },
  παραγγέλλει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3853",
    gloss: "to order",
  },
  παραγγέλλειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3853",
    gloss: "to order",
  },
  παραγγέλλομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G3853",
    gloss: "to order",
  },
  Παραγγέλλομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G3853",
    gloss: "to order",
  },
  παραγγέλλω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G3853",
    gloss: "to order",
  },
  Παραγγέλλω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G3853",
    gloss: "to order",
  },
  παραγγέλλων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3853",
    gloss: "to order",
  },
  παρηγγείλαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G3853",
    gloss: "to order",
  },
  παρήγγειλαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G3853",
    gloss: "to order",
  },
  παρήγγειλεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3853",
    gloss: "to order",
  },
  παρηγγέλλομεν: {
    parse: "V-IAI-1P｜Verb, imperfect, active, indicative, first, plural",
    GN: "G3853",
    gloss: "to order",
  },
  παρηγγελμένα: {
    parse:
      "V-RPP-APN｜Verb, perfect, passive, Participle, accusative, plural, neuter",
    GN: "G3853",
    gloss: "to order",
  },
  παραγενόμενοι: {
    parse:
      "V-2ADP-NPM｜Verb, 2nd aorist, Middle deponent, Participle, nominative, plural, masculine",
    GN: "G3854",
    gloss: "to come",
  },
  παραγενόμενον: {
    parse:
      "V-2ADP-ASM｜Verb, 2nd aorist, Middle deponent, Participle, accusative, singular, masculine",
    GN: "G3854",
    gloss: "to come",
  },
  παραγενόμενος: {
    parse:
      "V-2ADP-NSM｜Verb, 2nd aorist, Middle deponent, Participle, nominative, singular, masculine",
    GN: "G3854",
    gloss: "to come",
  },
  Παραγενόμενος: {
    parse:
      "V-2ADP-NSM｜Verb, 2nd aorist, Middle deponent, Participle, nominative, singular, masculine",
    GN: "G3854",
    gloss: "to come",
  },
  παραγενόμενός: {
    parse:
      "V-AMP-NSM｜Verb, aorist, middle, Participle, nominative, singular, masculine",
    GN: "G3854",
    gloss: "to come",
  },
  παραγενομένου: {
    parse:
      "V-2ADP-GSM｜Verb, 2nd aorist, Middle deponent, Participle, genitive, singular, masculine",
    GN: "G3854",
    gloss: "to come",
  },
  παραγενομένους: {
    parse:
      "V-2ADP-APM｜Verb, 2nd aorist, Middle deponent, Participle, accusative, plural, masculine",
    GN: "G3854",
    gloss: "to come",
  },
  παραγένωμαι: {
    parse:
      "V-2ADS-1S｜Verb, 2nd aorist, Middle deponent, subjunctive, first, singular",
    GN: "G3854",
    gloss: "to come",
  },
  παραγένωνται: {
    parse:
      "V-2ADS-3P｜Verb, 2nd aorist, Middle deponent, subjunctive, third, plural",
    GN: "G3854",
    gloss: "to come",
  },
  παραγίνεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G3854",
    gloss: "to come",
  },
  παρεγένετο: {
    parse:
      "V-2ADI-3S｜Verb, 2nd aorist, Middle deponent, indicative, third, singular",
    GN: "G3854",
    gloss: "to come",
  },
  Παρεγένετο: {
    parse:
      "V-2ADI-3S｜Verb, 2nd aorist, Middle deponent, indicative, third, singular",
    GN: "G3854",
    gloss: "to come",
  },
  παρεγενόμην: {
    parse:
      "V-2ADI-1S｜Verb, 2nd aorist, Middle deponent, indicative, first, singular",
    GN: "G3854",
    gloss: "to come",
  },
  παρεγένοντο: {
    parse:
      "V-2ADI-3P｜Verb, 2nd aorist, Middle deponent, indicative, third, plural",
    GN: "G3854",
    gloss: "to come",
  },
  παρεγίνοντο: {
    parse:
      "V-IDI-3P｜Verb, imperfect, Middle deponent, indicative, third, plural",
    GN: "G3854",
    gloss: "to come",
  },
  παράγει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3855",
    gloss: "to pass",
  },
  παράγεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G3855",
    gloss: "to pass",
  },
  παράγοντά: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G3855",
    gloss: "to pass",
  },
  παράγοντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G3855",
    gloss: "to pass",
  },
  παράγων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3855",
    gloss: "to pass",
  },
  παραδειγματίζοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G3856",
    gloss: "to disgrace",
  },
  παράδεισον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3857",
    gloss: "paradise",
  },
  παραδείσῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3857",
    gloss: "paradise",
  },
  παραδέξονταί: {
    parse:
      "V-FNI-3P｜Verb, future, Middle or Passive deponent, indicative, third, plural",
    GN: "G3858",
    gloss: "to welcome",
  },
  παραδέχεσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G3858",
    gloss: "to welcome",
  },
  παραδέχεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G3858",
    gloss: "to welcome",
  },
  παραδέχονται: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G3858",
    gloss: "to welcome",
  },
  παραδέχου: {
    parse:
      "V-PNM-2S｜Verb, present, Middle or Passive deponent, imperative, second, singular",
    GN: "G3858",
    gloss: "to welcome",
  },
  παρεδέχθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G3858",
    gloss: "to welcome",
  },
  διαπαρατριβαὶ: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G3859",
    gloss: "contention",
  },
  παραδεδομένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδέδοται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδεδώκεισαν: {
    parse: "V-LAI-3P｜Verb, pluperfect, active, indicative, third, plural",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδεδωκόσι: {
    parse:
      "V-RAP-DPM｜Verb, perfect, active, Participle, dative, plural, masculine",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδεδωκόσιν: {
    parse:
      "V-RAP-DPM｜Verb, perfect, active, Participle, dative, plural, masculine",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδιδόμεθα: {
    parse: "V-PPI-1P｜Verb, present, passive, indicative, first, plural",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδιδόναι: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδιδόντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδιδόντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδιδόντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδίδοσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδίδοται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδιδούς: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδιδοὺς: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδιδῷ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδίδως: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδοθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδοθείσῃ: {
    parse:
      "V-APP-DSF｜Verb, aorist, passive, Participle, dative, singular, feminine",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδοθείσης: {
    parse:
      "V-APP-GSF｜Verb, aorist, passive, Participle, genitive, singular, feminine",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδοθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδοθήσεσθε: {
    parse: "V-FPI-2P｜Verb, future, passive, indicative, second, plural",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδοθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδοθῶ: {
    parse: "V-APS-1S｜Verb, aorist, passive, subjunctive, first, singular",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδοῖ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδόντος: {
    parse:
      "V-2AAP-GSM｜Verb, 2nd aorist, active, Participle, genitive, singular, masculine",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδοῦναι: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδούς: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδοὺς: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδῶ: {
    parse: "V-2AAS-1S｜Verb, 2nd aorist, active, subjunctive, first, singular",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδῷ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδώσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδῶσιν: {
    parse: "V-2AAS-3P｜Verb, 2nd aorist, active, subjunctive, third, plural",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδώσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδώσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παραδώσων: {
    parse:
      "V-FAP-NSM｜Verb, future, active, Participle, nominative, singular, masculine",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παρεδίδετο: {
    parse: "V-IPI-3S｜Verb, imperfect, passive, indicative, third, singular",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παρεδίδοσαν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παρεδίδου: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παρεδίδουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παρεδόθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παρεδόθην: {
    parse: "V-API-1S｜Verb, aorist, passive, indicative, first, singular",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παρεδόθητε: {
    parse: "V-API-2P｜Verb, aorist, passive, indicative, second, plural",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παρέδοσαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παρέδωκα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  Παρέδωκα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παρεδώκαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παρέδωκαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παρέδωκάν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παρέδωκας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παρεδώκατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παρέδωκεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3860",
    gloss: "to deliver/handover/betray",
  },
  παράδοξα: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G3861",
    gloss: "remarkable",
  },
  παραδόσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3862",
    gloss: "tradition",
  },
  παραδόσεις: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G3862",
    gloss: "tradition",
  },
  παραδόσεων: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G3862",
    gloss: "tradition",
  },
  παράδοσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3862",
    gloss: "tradition",
  },
  παραζηλοῦμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G3863",
    gloss: "to make envious",
  },
  παραζηλῶσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G3863",
    gloss: "to make envious",
  },
  παραζηλώσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G3863",
    gloss: "to make envious",
  },
  παραθαλασσίαν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G3864",
    gloss: "seaside",
  },
  παρεθεωροῦντο: {
    parse: "V-IPI-3P｜Verb, imperfect, passive, indicative, third, plural",
    GN: "G3865",
    gloss: "to neglect",
  },
  παραθήκην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3866",
    gloss: "deposit",
  },
  παραινῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G3867",
    gloss: "to urge",
  },
  παρῄνει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G3867",
    gloss: "to urge",
  },
  παραιτεῖσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G3868",
    gloss: "to refuse/excuse",
  },
  παραιτησάμενοι: {
    parse:
      "V-ADP-NPM｜Verb, aorist, Middle deponent, Participle, nominative, plural, masculine",
    GN: "G3868",
    gloss: "to refuse/excuse",
  },
  παραιτήσησθε: {
    parse:
      "V-ADS-2P｜Verb, aorist, Middle deponent, subjunctive, second, plural",
    GN: "G3868",
    gloss: "to refuse/excuse",
  },
  παραιτοῦ: {
    parse:
      "V-PNM-2S｜Verb, present, Middle or Passive deponent, imperative, second, singular",
    GN: "G3868",
    gloss: "to refuse/excuse",
  },
  παραιτοῦμαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G3868",
    gloss: "to refuse/excuse",
  },
  παρῃτημένον: {
    parse:
      "V-RPP-ASM｜Verb, perfect, passive, Participle, accusative, singular, masculine",
    GN: "G3868",
    gloss: "to refuse/excuse",
  },
  παρῃτήσαντο: {
    parse: "V-ADI-3P｜Verb, aorist, Middle deponent, indicative, third, plural",
    GN: "G3868",
    gloss: "to refuse/excuse",
  },
  παρῃτοῦντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G3868",
    gloss: "to refuse/excuse",
  },
  παρακαθεσθεῖσα: {
    parse:
      "V-AOP-NSF｜Verb, aorist, Passive deponent, Participle, nominative, singular, feminine",
    GN: "G3869",
    gloss: "to sit by",
  },
  παρακαλεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρακάλει: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρακαλεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρακαλεῖσθε: {
    parse: "V-PPM-2P｜Verb, present, passive, imperative, second, plural",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρακαλεῖται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρακαλεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρακαλέσαι: {
    parse: "V-AAO-3S｜Verb, aorist, active, Optative, third, singular",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρακαλέσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρακαλέσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρακάλεσον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρακαλούμεθα: {
    parse: "V-PPI-1P｜Verb, present, passive, indicative, first, plural",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρακαλοῦμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρακαλοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρακαλοῦντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρακαλοῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρακαλῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  Παρακαλῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρακαλῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρακαλῶνται: {
    parse: "V-PPS-3P｜Verb, present, passive, subjunctive, third, plural",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρακεκλήμεθα: {
    parse: "V-RPI-1P｜Verb, perfect, passive, indicative, first, plural",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρακληθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρακληθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρακληθῶσιν: {
    parse: "V-APS-3P｜Verb, aorist, passive, subjunctive, third, plural",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρεκάλει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρεκάλεσα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρεκάλεσά: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρεκάλεσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρεκάλεσάς: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρεκάλεσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρεκάλεσέν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρεκαλοῦμεν: {
    parse: "V-IAI-1P｜Verb, imperfect, active, indicative, first, plural",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρεκάλουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρεκλήθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρεκλήθημεν: {
    parse: "V-API-1P｜Verb, aorist, passive, indicative, first, plural",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρεκλήθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G3870",
    gloss: "to plead/comfort",
  },
  παρακεκαλυμμένον: {
    parse:
      "V-RPP-NSN｜Verb, perfect, passive, Participle, nominative, singular, neuter",
    GN: "G3871",
    gloss: "to hide",
  },
  παράκειται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G3873",
    gloss: "be present",
  },
  παράκειταί: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G3873",
    gloss: "be present",
  },
  παρακλήσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3874",
    gloss: "encouragement",
  },
  παρακλήσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3874",
    gloss: "encouragement",
  },
  παράκλησιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3874",
    gloss: "encouragement",
  },
  παράκλησις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3874",
    gloss: "encouragement",
  },
  παράκλητον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3875",
    gloss: "counsellor",
  },
  παράκλητος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3875",
    gloss: "counsellor",
  },
  παρακοὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3876",
    gloss: "disobedience",
  },
  παρακοήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3876",
    gloss: "disobedience",
  },
  παρακοῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3876",
    gloss: "disobedience",
  },
  παρακολουθήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G3877",
    gloss: "to follow",
  },
  παρηκολούθηκας: {
    parse: "V-RAI-2S｜Verb, perfect, active, indicative, second, singular",
    GN: "G3877",
    gloss: "to follow",
  },
  παρηκολουθηκότι: {
    parse:
      "V-RAP-DSM｜Verb, perfect, active, Participle, dative, singular, masculine",
    GN: "G3877",
    gloss: "to follow",
  },
  παρηκολούθησάς: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G3877",
    gloss: "to follow",
  },
  παρακούσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G3878",
    gloss: "to ignore",
  },
  παρακούσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G3878",
    gloss: "to ignore",
  },
  παρακύψαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G3879",
    gloss: "to stoop",
  },
  παρακύψας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G3879",
    gloss: "to stoop",
  },
  παρέκυψεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3879",
    gloss: "to stoop",
  },
  παράλαβε: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G3880",
    gloss: "to take",
  },
  παραλαβεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G3880",
    gloss: "to take",
  },
  παραλαβόντα: {
    parse:
      "V-2AAP-ASM｜Verb, 2nd aorist, active, Participle, accusative, singular, masculine",
    GN: "G3880",
    gloss: "to take",
  },
  παραλαβόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G3880",
    gloss: "to take",
  },
  παραλαβὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G3880",
    gloss: "to take",
  },
  Παραλαβὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G3880",
    gloss: "to take",
  },
  παραλαμβάνει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3880",
    gloss: "to take",
  },
  παραλαμβάνεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G3880",
    gloss: "to take",
  },
  παραλαμβάνοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G3880",
    gloss: "to take",
  },
  παραλαμβάνουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G3880",
    gloss: "to take",
  },
  παραλημφθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G3880",
    gloss: "to take",
  },
  παραλήμψομαι: {
    parse:
      "V-FDI-1S｜Verb, future, Middle deponent, indicative, first, singular",
    GN: "G3880",
    gloss: "to take",
  },
  παρέλαβεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G3880",
    gloss: "to take",
  },
  παρέλαβε: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G3880",
    gloss: "to take",
  },
  παρέλαβες: {
    parse: "V-2AAI-2S｜Verb, 2nd aorist, active, indicative, second, singular",
    GN: "G3880",
    gloss: "to take",
  },
  παρελάβετε: {
    parse: "V-2AAI-2P｜Verb, 2nd aorist, active, indicative, second, plural",
    GN: "G3880",
    gloss: "to take",
  },
  παρέλαβον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G3880",
    gloss: "to take",
  },
  Παρέλαβον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G3880",
    gloss: "to take",
  },
  παρελάβοσαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G3880",
    gloss: "to take",
  },
  παραλεγόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G3881",
    gloss: "to sail past",
  },
  παρελέγοντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G3881",
    gloss: "to sail past",
  },
  παραλίου: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G3882",
    gloss: "seaside",
  },
  παραλλαγὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3883",
    gloss: "variation",
  },
  παραλογίζηται: {
    parse:
      "V-PNS-3S｜Verb, present, Middle or Passive deponent, subjunctive, third, singular",
    GN: "G3884",
    gloss: "to deceive",
  },
  παραλογιζόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G3884",
    gloss: "to deceive",
  },
  παραλυτικὸν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G3885",
    gloss: "paralytic",
  },
  παραλυτικός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3885",
    gloss: "paralytic",
  },
  παραλυτικὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3885",
    gloss: "paralytic",
  },
  παραλυτικούς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G3885",
    gloss: "paralytic",
  },
  παραλυτικῷ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G3885",
    gloss: "paralytic",
  },
  παραλελυμένα: {
    parse:
      "V-RPP-APN｜Verb, perfect, passive, Participle, accusative, plural, neuter",
    GN: "G3886",
    gloss: "to paralyze",
  },
  παραλελυμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G3886",
    gloss: "to paralyze",
  },
  παραλελυμένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G3886",
    gloss: "to paralyze",
  },
  παραλελυμένῳ: {
    parse:
      "V-RPP-DSM｜Verb, perfect, passive, Participle, dative, singular, masculine",
    GN: "G3886",
    gloss: "to paralyze",
  },
  παραμείνας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G3887",
    gloss: "to continue",
  },
  παραμένειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3887",
    gloss: "to continue",
  },
  παραμενῶ: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G3887",
    gloss: "to continue",
  },
  παραμυθεῖσθε: {
    parse:
      "V-PNM-2P｜Verb, present, Middle or Passive deponent, imperative, second, plural",
    GN: "G3888",
    gloss: "to encourage",
  },
  παραμυθήσωνται: {
    parse:
      "V-ADS-3P｜Verb, aorist, Middle deponent, subjunctive, third, plural",
    GN: "G3888",
    gloss: "to encourage",
  },
  παραμυθούμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G3888",
    gloss: "to encourage",
  },
  παραμυθίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3889",
    gloss: "comfort",
  },
  παραμύθιον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G3890",
    gloss: "comfort",
  },
  παρανομῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3891",
    gloss: "to break the law",
  },
  παρανομίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3892",
    gloss: "lawlessness",
  },
  παρεπίκραναν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G3893",
    gloss: "to rebel",
  },
  παραπικρασμῷ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3894",
    gloss: "rebellion",
  },
  παραπεσόντας: {
    parse:
      "V-2AAP-APM｜Verb, 2nd aorist, active, Participle, accusative, plural, masculine",
    GN: "G3895",
    gloss: "to defect",
  },
  παραπλεῦσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G3896",
    gloss: "to sail past",
  },
  παραπλήσιον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G3897",
    gloss: "almost",
  },
  παραπλησίως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3898",
    gloss: "similarly",
  },
  παραπορεύεσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G3899",
    gloss: "to pass by/through",
  },
  παραπορευόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G3899",
    gloss: "to pass by/through",
  },
  παρεπορεύοντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G3899",
    gloss: "to pass by/through",
  },
  παράπτωμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G3900",
    gloss: "trespass",
  },
  παραπτώμασιν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G3900",
    gloss: "trespass",
  },
  παραπτώματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G3900",
    gloss: "trespass",
  },
  παραπτώματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G3900",
    gloss: "trespass",
  },
  παραπτώματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G3900",
    gloss: "trespass",
  },
  παραπτωμάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G3900",
    gloss: "trespass",
  },
  παραρυῶμεν: {
    parse: "V-2AAS-1P｜Verb, 2nd aorist, active, subjunctive, first, plural",
    GN: "G3901",
    gloss: "to drift away",
  },
  παρασήμῳ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G3902",
    gloss: "figurehead",
  },
  παρασκευαζόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G3903",
    gloss: "to prepare",
  },
  παρασκευάσεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G3903",
    gloss: "to prepare",
  },
  παρεσκευασμένοι: {
    parse:
      "V-RMP-NPM｜Verb, perfect, middle, Participle, nominative, plural, masculine",
    GN: "G3903",
    gloss: "to prepare",
  },
  παρεσκεύασται: {
    parse: "V-RMI-3S｜Verb, perfect, middle, indicative, third, singular",
    GN: "G3903",
    gloss: "to prepare",
  },
  παρασκευή: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3904",
    gloss: "Preparation",
  },
  παρασκευὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3904",
    gloss: "Preparation",
  },
  παρασκευήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3904",
    gloss: "Preparation",
  },
  παρασκευὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3904",
    gloss: "Preparation",
  },
  παρασκευῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3904",
    gloss: "Preparation",
  },
  παρέτεινέν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G3905",
    gloss: "to prolong",
  },
  παρατηρεῖσθε: {
    parse: "V-PMI-2P｜Verb, present, middle, indicative, second, plural",
    GN: "G3906",
    gloss: "to observe",
  },
  παρατηρήσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G3906",
    gloss: "to observe",
  },
  παρατηρούμενοι: {
    parse:
      "V-PMP-NPM｜Verb, present, middle, Participle, nominative, plural, masculine",
    GN: "G3906",
    gloss: "to observe",
  },
  παρετήρουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G3906",
    gloss: "to observe",
  },
  παρετηροῦντο: {
    parse: "V-IMI-3P｜Verb, imperfect, middle, indicative, third, plural",
    GN: "G3906",
    gloss: "to observe",
  },
  παρατηρήσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3907",
    gloss: "observation",
  },
  παραθεῖναι: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G3908",
    gloss: "to set before",
  },
  παραθήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G3908",
    gloss: "to set before",
  },
  παράθου: {
    parse: "V-2AMM-2S｜Verb, 2nd aorist, middle, imperative, second, singular",
    GN: "G3908",
    gloss: "to set before",
  },
  παρατίθεμαι: {
    parse: "V-PMI-1S｜Verb, present, middle, indicative, first, singular",
    GN: "G3908",
    gloss: "to set before",
  },
  παρατίθεμαί: {
    parse: "V-PMI-1S｜Verb, present, middle, indicative, first, singular",
    GN: "G3908",
    gloss: "to set before",
  },
  παρατιθέμενα: {
    parse:
      "V-PPP-APN｜Verb, present, passive, Participle, accusative, plural, neuter",
    GN: "G3908",
    gloss: "to set before",
  },
  παρατιθέμενον: {
    parse:
      "V-PPP-ASN｜Verb, present, passive, Participle, accusative, singular, neuter",
    GN: "G3908",
    gloss: "to set before",
  },
  παρατιθέμενος: {
    parse:
      "V-PMP-NSM｜Verb, present, middle, Participle, nominative, singular, masculine",
    GN: "G3908",
    gloss: "to set before",
  },
  παρατιθέναι: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3908",
    gloss: "to set before",
  },
  παρατιθέσθωσαν: {
    parse: "V-PMM-3P｜Verb, present, middle, imperative, third, plural",
    GN: "G3908",
    gloss: "to set before",
  },
  παρατιθῶσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G3908",
    gloss: "to set before",
  },
  παρέθεντο: {
    parse: "V-2AMI-3P｜Verb, 2nd aorist, middle, indicative, third, plural",
    GN: "G3908",
    gloss: "to set before",
  },
  παρέθηκαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G3908",
    gloss: "to set before",
  },
  παρέθηκεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3908",
    gloss: "to set before",
  },
  παρατυγχάνοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G3909",
    gloss: "be there",
  },
  παραυτίκα: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3910",
    gloss: "momentary",
  },
  παραφέρεσθε: {
    parse: "V-PPM-2P｜Verb, present, passive, imperative, second, plural",
    GN: "G3911",
    gloss: "to take away",
  },
  παραφερόμεναι: {
    parse:
      "V-PPP-NPF｜Verb, present, passive, Participle, nominative, plural, feminine",
    GN: "G3911",
    gloss: "to take away",
  },
  παρένεγκε: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G3911",
    gloss: "to take away",
  },
  παραφρονῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3912",
    gloss: "be insane",
  },
  παραφρονίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3913",
    gloss: "insanity",
  },
  παρακεχειμακότι: {
    parse:
      "V-RAP-DSN｜Verb, perfect, active, Participle, dative, singular, neuter",
    GN: "G3914",
    gloss: "to winter",
  },
  παραχειμάσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G3914",
    gloss: "to winter",
  },
  παραχειμάσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G3914",
    gloss: "to winter",
  },
  παραχειμασίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3915",
    gloss: "wintering",
  },
  παραχρῆμα: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3916",
    gloss: "instantly",
  },
  Παραχρῆμά: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3916",
    gloss: "instantly",
  },
  παρδάλει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3917",
    gloss: "leopard",
  },
  πάρει: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G3918",
    gloss: "be present",
  },
  παρεῖναι: {
    parse: "V-2PAN｜Verb, 2nd present, active, Infinitive",
    GN: "G3918",
    gloss: "be present",
  },
  παρεῖναί: {
    parse: "V-2PAN｜Verb, 2nd present, active, Infinitive",
    GN: "G3918",
    gloss: "be present",
  },
  πάρεισιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G3918",
    gloss: "be present",
  },
  πάρεσμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G3918",
    gloss: "be present",
  },
  παρέσται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G3918",
    gloss: "be present",
  },
  πάρεστε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G3918",
    gloss: "be present",
  },
  πάρεστιν: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3918",
    gloss: "be present",
  },
  παρῆσαν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G3918",
    gloss: "be present",
  },
  Παρῆσαν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G3918",
    gloss: "be present",
  },
  παρὸν: {
    parse:
      "V-PAP-ASN｜Verb, present, active, Participle, accusative, singular, neuter",
    GN: "G3918",
    gloss: "be present",
  },
  παρόντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G3918",
    gloss: "be present",
  },
  παρόντος: {
    parse:
      "V-PAP-GSN｜Verb, present, active, Participle, genitive, singular, neuter",
    GN: "G3918",
    gloss: "be present",
  },
  παρούσῃ: {
    parse:
      "V-PAP-DSF｜Verb, present, active, Participle, dative, singular, feminine",
    GN: "G3918",
    gloss: "be present",
  },
  παροῦσιν: {
    parse:
      "V-PAP-DPN｜Verb, present, active, Participle, dative, plural, neuter",
    GN: "G3918",
    gloss: "be present",
  },
  παρὼν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3918",
    gloss: "be present",
  },
  παρεισάξουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G3919",
    gloss: "to introduce",
  },
  παρεισάκτους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G3920",
    gloss: "infiltrated",
  },
  παρεισέδυσαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G3921",
    gloss: "to infiltrate",
  },
  παρεισῆλθεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G3922",
    gloss: "to come in",
  },
  παρεισῆλθον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G3922",
    gloss: "to come in",
  },
  παρεισενέγκαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G3923",
    gloss: "to supply",
  },
  παρεκτὸς: { parse: "PREP｜Preposition", GN: "G3924", gloss: "except" },
  παρεμβολὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G3925",
    gloss: "barracks",
  },
  παρεμβολήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3925",
    gloss: "barracks",
  },
  παρεμβολὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3925",
    gloss: "barracks",
  },
  παρεμβολῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3925",
    gloss: "barracks",
  },
  παρενοχλεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3926",
    gloss: "to trouble",
  },
  παρεπίδημοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3927",
    gloss: "stranger",
  },
  παρεπιδήμοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G3927",
    gloss: "stranger",
  },
  παρεπιδήμους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G3927",
    gloss: "stranger",
  },
  παρελεύσεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G3928",
    gloss: "to pass by",
  },
  παρελεύσονται: {
    parse: "V-FDI-3P｜Verb, future, Middle deponent, indicative, third, plural",
    GN: "G3928",
    gloss: "to pass by",
  },
  παρεληλυθέναι: {
    parse: "V-2RAN｜Verb, 2nd perfect, active, Infinitive",
    GN: "G3928",
    gloss: "to pass by",
  },
  παρεληλυθὼς: {
    parse:
      "V-2RAP-NSM｜Verb, 2nd perfect, active, Participle, nominative, singular, masculine",
    GN: "G3928",
    gloss: "to pass by",
  },
  παρελθάτω: {
    parse: "V-2AAM-3S｜Verb, 2nd aorist, active, imperative, third, singular",
    GN: "G3928",
    gloss: "to pass by",
  },
  παρελθεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G3928",
    gloss: "to pass by",
  },
  παρέλθῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G3928",
    gloss: "to pass by",
  },
  παρελθόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G3928",
    gloss: "to pass by",
  },
  παρελθὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G3928",
    gloss: "to pass by",
  },
  παρέλθωσιν: {
    parse: "V-2AAS-3P｜Verb, 2nd aorist, active, subjunctive, third, plural",
    GN: "G3928",
    gloss: "to pass by",
  },
  παρέρχεσθε: {
    parse:
      "V-PNI-2P｜Verb, present, Middle or Passive deponent, indicative, second, plural",
    GN: "G3928",
    gloss: "to pass by",
  },
  παρέρχεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G3928",
    gloss: "to pass by",
  },
  παρῆλθεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G3928",
    gloss: "to pass by",
  },
  παρῆλθον: {
    parse:
      "V-2AAI-1S｜Verb, 2nd aorist, active, indicative, first, singular OR third, plural",
    GN: "G3928",
    gloss: "to pass by",
  },
  πάρεσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3929",
    gloss: "passing over",
  },
  παρασχὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G3930",
    gloss: "to furnish occasion",
  },
  παρεῖχεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G3930",
    gloss: "to furnish occasion",
  },
  παρείχετο: {
    parse: "V-IMI-3S｜Verb, imperfect, middle, indicative, third, singular",
    GN: "G3930",
    gloss: "to furnish occasion",
  },
  παρεῖχον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G3930",
    gloss: "to furnish occasion",
  },
  παρέξῃ: {
    parse:
      "V-2FDI-2S｜Verb, 2nd future, Middle deponent, indicative, second, singular",
    GN: "G3930",
    gloss: "to furnish occasion",
  },
  παρέσχον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G3930",
    gloss: "to furnish occasion",
  },
  πάρεχε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G3930",
    gloss: "to furnish occasion",
  },
  παρέχειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3930",
    gloss: "to furnish occasion",
  },
  παρέχεσθε: {
    parse: "V-PMM-2P｜Verb, present, middle, imperative, second, plural",
    GN: "G3930",
    gloss: "to furnish occasion",
  },
  παρέχετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G3930",
    gloss: "to furnish occasion",
  },
  παρεχέτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G3930",
    gloss: "to furnish occasion",
  },
  παρεχόμενος: {
    parse:
      "V-PMP-NSM｜Verb, present, middle, Participle, nominative, singular, masculine",
    GN: "G3930",
    gloss: "to furnish occasion",
  },
  παρέχοντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G3930",
    gloss: "to furnish occasion",
  },
  παρέχουσι: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G3930",
    gloss: "to furnish occasion",
  },
  παρέχουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G3930",
    gloss: "to furnish occasion",
  },
  παρηγορία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3931",
    gloss: "comfort",
  },
  παρθενίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3932",
    gloss: "virginity",
  },
  παρθένοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3933",
    gloss: "virgin",
  },
  παρθένοις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G3933",
    gloss: "virgin",
  },
  παρθένον: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3933",
    gloss: "virgin",
  },
  παρθένος: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3933",
    gloss: "virgin",
  },
  παρθένου: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3933",
    gloss: "virgin",
  },
  παρθένων: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G3933",
    gloss: "virgin",
  },
  Πάρθοι: {
    parse: "N-NPM-LG｜Noun, nominative, plural, masculine, Location Gentilic",
    GN: "G3934",
    gloss: "Parthian",
  },
  παρειμένας: {
    parse:
      "V-RPP-APF｜Verb, perfect, passive, Participle, accusative, plural, feminine",
    GN: "G3935",
    gloss: "to neglect",
  },
  παραστῆναι: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G3936",
    gloss: "to stand by",
  },
  παραστῆσαι: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G3936",
    gloss: "to stand by",
  },
  παραστήσατε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G3936",
    gloss: "to stand by",
  },
  παραστήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G3936",
    gloss: "to stand by",
  },
  παραστήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G3936",
    gloss: "to stand by",
  },
  παραστησόμεθα: {
    parse: "V-FDI-1P｜Verb, future, Middle deponent, indicative, first, plural",
    GN: "G3936",
    gloss: "to stand by",
  },
  παραστήσωμεν: {
    parse: "V-AAS-1P｜Verb, aorist, active, subjunctive, first, plural",
    GN: "G3936",
    gloss: "to stand by",
  },
  παραστῆτε: {
    parse: "V-2AAS-2P｜Verb, 2nd aorist, active, subjunctive, second, plural",
    GN: "G3936",
    gloss: "to stand by",
  },
  παρειστήκεισαν: {
    parse: "V-LAI-3P｜Verb, pluperfect, active, indicative, third, plural",
    GN: "G3936",
    gloss: "to stand by",
  },
  παρέστη: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G3936",
    gloss: "to stand by",
  },
  παρέστηκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G3936",
    gloss: "to stand by",
  },
  παρεστηκότων: {
    parse:
      "V-RAP-GPM｜Verb, perfect, active, Participle, genitive, plural, masculine",
    GN: "G3936",
    gloss: "to stand by",
  },
  παρεστηκὼς: {
    parse:
      "V-RAP-NSM｜Verb, perfect, active, Participle, nominative, singular, masculine",
    GN: "G3936",
    gloss: "to stand by",
  },
  παρέστησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G3936",
    gloss: "to stand by",
  },
  παρεστήσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G3936",
    gloss: "to stand by",
  },
  παρέστησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3936",
    gloss: "to stand by",
  },
  παρεστῶσιν: {
    parse:
      "V-RAP-DPM｜Verb, perfect, active, Participle, dative, plural, masculine",
    GN: "G3936",
    gloss: "to stand by",
  },
  παρεστῶτα: {
    parse:
      "V-RAP-ASM｜Verb, perfect, active, Participle, accusative, singular, masculine",
    GN: "G3936",
    gloss: "to stand by",
  },
  παρεστῶτες: {
    parse:
      "V-RAP-NPM｜Verb, perfect, active, Participle, nominative, plural, masculine",
    GN: "G3936",
    gloss: "to stand by",
  },
  παριστάνετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G3936",
    gloss: "to stand by",
  },
  Παρμενᾶν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G3937",
    gloss: "Parmenas",
  },
  παρόδῳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3938",
    gloss: "passing by",
  },
  παροικεῖς: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G3939",
    gloss: "be a stranger",
  },
  παρῴκησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3939",
    gloss: "be a stranger",
  },
  παροικίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3940",
    gloss: "sojourning",
  },
  παροικίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3940",
    gloss: "sojourning",
  },
  πάροικοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3941",
    gloss: "foreigner",
  },
  πάροικον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G3941",
    gloss: "foreigner",
  },
  πάροικος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3941",
    gloss: "foreigner",
  },
  παροίκους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G3941",
    gloss: "foreigner",
  },
  παροιμίαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G3942",
    gloss: "proverb",
  },
  παροιμίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3942",
    gloss: "proverb",
  },
  παροιμίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3942",
    gloss: "proverb",
  },
  πάροινον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G3943",
    gloss: "drunken",
  },
  παρῳχημέναις: {
    parse:
      "V-RNP-DPF｜Verb, perfect, Middle or Passive deponent, Participle, dative, plural, feminine",
    GN: "G3944",
    gloss: "to pass by",
  },
  παρομοιάζετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G3945",
    gloss: "to resemble",
  },
  παρόμοια: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G3946",
    gloss: "like",
  },
  παροξύνεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G3947",
    gloss: "to provoke",
  },
  παρωξύνετο: {
    parse: "V-IPI-3S｜Verb, imperfect, passive, indicative, third, singular",
    GN: "G3947",
    gloss: "to provoke",
  },
  παροξυσμὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3948",
    gloss: "stirring up",
  },
  παροξυσμὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3948",
    gloss: "stirring up",
  },
  παροργίζετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G3949",
    gloss: "to anger",
  },
  παροργιῶ: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G3949",
    gloss: "to anger",
  },
  παροργισμῷ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3950",
    gloss: "anger",
  },
  παρώτρυναν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G3951",
    gloss: "to incite",
  },
  παρουσίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3952",
    gloss: "coming",
  },
  παρουσία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3952",
    gloss: "coming",
  },
  παρουσίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3952",
    gloss: "coming",
  },
  παρουσίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3952",
    gloss: "coming",
  },
  παροψίδος: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3953",
    gloss: "dish",
  },
  παρρησίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3954",
    gloss: "boldness",
  },
  παρρησία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3954",
    gloss: "boldness",
  },
  παρρησίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3954",
    gloss: "boldness",
  },
  παρρησίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3954",
    gloss: "boldness",
  },
  ἐπαρρησιάζετο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G3955",
    gloss: "to preach boldly",
  },
  ἐπαρρησιασάμεθα: {
    parse: "V-ADI-1P｜Verb, aorist, Middle deponent, indicative, first, plural",
    GN: "G3955",
    gloss: "to preach boldly",
  },
  ἐπαρρησιάσατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G3955",
    gloss: "to preach boldly",
  },
  παρρησιάζεσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G3955",
    gloss: "to preach boldly",
  },
  παρρησιαζόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G3955",
    gloss: "to preach boldly",
  },
  παρρησιαζόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G3955",
    gloss: "to preach boldly",
  },
  παρρησιασάμενοί: {
    parse:
      "V-ADP-NPM｜Verb, aorist, Middle deponent, Participle, nominative, plural, masculine",
    GN: "G3955",
    gloss: "to preach boldly",
  },
  παρρησιάσωμαι: {
    parse:
      "V-ADS-1S｜Verb, aorist, Middle deponent, subjunctive, first, singular",
    GN: "G3955",
    gloss: "to preach boldly",
  },
  πᾶν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G3956",
    gloss: "all",
  },
  Πᾶν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G3956",
    gloss: "all",
  },
  πάντα: {
    parse: "A-VPN｜Adjective, Vocative or nominative, plural, neuter",
    GN: "G3956",
    gloss: "all",
  },
  Πάντα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G3956",
    gloss: "all",
  },
  πάντας: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G3956",
    gloss: "all",
  },
  πάντες: {
    parse: "A-VPM｜Adjective, Vocative or nominative, plural, masculine",
    GN: "G3956",
    gloss: "all",
  },
  Πάντες: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G3956",
    gloss: "all",
  },
  παντὶ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G3956",
    gloss: "all",
  },
  παντί: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G3956",
    gloss: "all",
  },
  παντός: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G3956",
    gloss: "all",
  },
  παντὸς: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G3956",
    gloss: "all",
  },
  πάντων: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G3956",
    gloss: "all",
  },
  Πάντων: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G3956",
    gloss: "all",
  },
  πᾶς: {
    parse: "A-VSM｜Adjective, Vocative or nominative, singular, masculine",
    GN: "G3956",
    gloss: "all",
  },
  Πᾶς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3956",
    gloss: "all",
  },
  πᾶσα: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G3956",
    gloss: "all",
  },
  Πᾶσα: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G3956",
    gloss: "all",
  },
  πᾶσαι: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G3956",
    gloss: "all",
  },
  Πᾶσαι: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G3956",
    gloss: "all",
  },
  πάσαις: {
    parse: "A-DPF｜Adjective, dative, plural, feminine",
    GN: "G3956",
    gloss: "all",
  },
  πᾶσαν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G3956",
    gloss: "all",
  },
  Πᾶσαν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G3956",
    gloss: "all",
  },
  πᾶσάν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G3956",
    gloss: "all",
  },
  πάσας: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G3956",
    gloss: "all",
  },
  πάσῃ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G3956",
    gloss: "all",
  },
  πάσης: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G3956",
    gloss: "all",
  },
  πᾶσι: {
    parse: "A-DPN｜Adjective, dative, plural, neuter",
    GN: "G3956",
    gloss: "all",
  },
  πᾶσιν: {
    parse: "A-DPN｜Adjective, dative, plural, neuter",
    GN: "G3956",
    gloss: "all",
  },
  πασῶν: {
    parse: "A-GPF｜Adjective, genitive, plural, feminine",
    GN: "G3956",
    gloss: "all",
  },
  πάσχα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G3957",
    gloss: "Passover lamb",
  },
  Πάσχα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G3957",
    gloss: "Passover lamb",
  },
  ἔπαθεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G3958",
    gloss: "to suffer",
  },
  ἐπάθετε: {
    parse: "V-2AAI-2P｜Verb, 2nd aorist, active, indicative, second, plural",
    GN: "G3958",
    gloss: "to suffer",
  },
  ἔπαθον: {
    parse:
      "V-2AAI-1S｜Verb, 2nd aorist, active, indicative, first, singular OR third, plural",
    GN: "G3958",
    gloss: "to suffer",
  },
  παθεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G3958",
    gloss: "to suffer",
  },
  πάθῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G3958",
    gloss: "to suffer",
  },
  παθόντας: {
    parse:
      "V-2AAP-APM｜Verb, 2nd aorist, active, Participle, accusative, plural, masculine",
    GN: "G3958",
    gloss: "to suffer",
  },
  παθόντος: {
    parse:
      "V-2AAP-GSM｜Verb, 2nd aorist, active, Participle, genitive, singular, masculine",
    GN: "G3958",
    gloss: "to suffer",
  },
  παθοῦσα: {
    parse:
      "V-2AAP-NSF｜Verb, 2nd aorist, active, Participle, nominative, singular, feminine",
    GN: "G3958",
    gloss: "to suffer",
  },
  παθὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G3958",
    gloss: "to suffer",
  },
  πάσχει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3958",
    gloss: "to suffer",
  },
  πάσχειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3958",
    gloss: "to suffer",
  },
  πάσχετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G3958",
    gloss: "to suffer",
  },
  πασχέτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G3958",
    gloss: "to suffer",
  },
  πάσχοιτε: {
    parse: "V-PAO-2P｜Verb, present, active, Optative, second, plural",
    GN: "G3958",
    gloss: "to suffer",
  },
  πάσχομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G3958",
    gloss: "to suffer",
  },
  πάσχοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G3958",
    gloss: "to suffer",
  },
  πάσχω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G3958",
    gloss: "to suffer",
  },
  πάσχων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3958",
    gloss: "to suffer",
  },
  πεπόνθασιν: {
    parse: "V-2RAI-3P｜Verb, 2nd perfect, active, indicative, third, plural",
    GN: "G3958",
    gloss: "to suffer",
  },
  πέπονθεν: {
    parse: "V-2RAI-3S｜Verb, 2nd perfect, active, indicative, third, singular",
    GN: "G3958",
    gloss: "to suffer",
  },
  Πάταρα: {
    parse: "N-APN-L｜Noun, accusative, plural, neuter, Location",
    GN: "G3959",
    gloss: "Patara",
  },
  ἐπάταξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3960",
    gloss: "to strike",
  },
  πατάξαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G3960",
    gloss: "to strike",
  },
  πατάξας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G3960",
    gloss: "to strike",
  },
  πατάξῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G3960",
    gloss: "to strike",
  },
  πατάξομεν: {
    parse: "V-FAI-1P｜Verb, future, active, indicative, first, plural",
    GN: "G3960",
    gloss: "to strike",
  },
  Πατάξω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G3960",
    gloss: "to strike",
  },
  ἐπατήθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G3961",
    gloss: "to trample",
  },
  πατεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3961",
    gloss: "to trample",
  },
  πατεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3961",
    gloss: "to trample",
  },
  πατήσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G3961",
    gloss: "to trample",
  },
  πατουμένη: {
    parse:
      "V-PPP-NSF｜Verb, present, passive, Participle, nominative, singular, feminine",
    GN: "G3961",
    gloss: "to trample",
  },
  πάτερ: {
    // most often a vocative
    parse: "N-VSM｜Noun, Vocative or nominative, singular, masculine",
    GN: "G3962",
    gloss: "father",
  },
  Πάτερ: {
    // most often a vocative
    parse: "N-VSM｜Noun, Vocative or nominative, singular, masculine",
    GN: "G3962",
    gloss: "father",
  },
  πατέρα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3962",
    gloss: "father",
  },
  Πατέρα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3962",
    gloss: "father",
  },
  πατέρας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3962",
    gloss: "father",
  },
  πατέρες: {
    parse: "N-VPM｜Noun, Vocative or nominative, plural, masculine",
    GN: "G3962",
    gloss: "father",
  },
  πατέρων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G3962",
    gloss: "father",
  },
  πατήρ: {
    parse: "N-VSM｜Noun, Vocative or nominative, singular, masculine",
    GN: "G3962",
    gloss: "father",
  },
  πατὴρ: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3962",
    gloss: "father",
  },
  πατράσιν: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G3962",
    gloss: "father",
  },
  πατρί: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3962",
    gloss: "father",
  },
  πατρὶ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3962",
    gloss: "father",
  },
  πατρός: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3962",
    gloss: "father",
  },
  πατρὸς: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3962",
    gloss: "father",
  },
  Πάτμῳ: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G3963",
    gloss: "Patmos",
  },
  πατρολῴαις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G3964",
    gloss: "patricide",
  },
  πατριὰ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3965",
    gloss: "family line",
  },
  πατριαὶ: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G3965",
    gloss: "family line",
  },
  πατριᾶς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3965",
    gloss: "family line",
  },
  πατριάρχαι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G3966",
    gloss: "patriarch",
  },
  πατριάρχας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G3966",
    gloss: "patriarch",
  },
  πατριάρχης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3966",
    gloss: "patriarch",
  },
  πατριάρχου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3966",
    gloss: "patriarch",
  },
  πατρικῶν: {
    parse: "A-GPF｜Adjective, genitive, plural, feminine",
    GN: "G3967",
    gloss: "paternal",
  },
  πατρίδα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3968",
    gloss: "fatherland",
  },
  πατρίδι: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G3968",
    gloss: "fatherland",
  },
  Πατρόβαν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G3969",
    gloss: "Patrobas",
  },
  πατροπαραδότου: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G3970",
    gloss: "from forefathers",
  },
  πατρῴοις: {
    parse: "A-DPN｜Adjective, dative, plural, neuter",
    GN: "G3971",
    gloss: "ancestral",
  },
  πατρῴου: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G3971",
    gloss: "ancestral",
  },
  πατρῴῳ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G3971",
    gloss: "ancestral",
  },
  Παῦλε: {
    parse: "N-VSM-P｜Noun, Vocative or nominative, singular, masculine, Person",
    GN: "G3972",
    gloss: " Paulus",
  },
  Παῦλον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G3972",
    gloss: " Paulus",
  },
  Παῦλος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G3972",
    gloss: " Paulus",
  },
  Παύλου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G3972",
    gloss: " Paulus",
  },
  Παύλῳ: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G3972",
    gloss: " Paulus",
  },
  ἐπαύοντο: {
    parse: "V-IMI-3P｜Verb, imperfect, middle, indicative, third, plural",
    GN: "G3973",
    gloss: "to cease",
  },
  ἐπαυσάμην: {
    parse: "V-AMI-1S｜Verb, aorist, middle, indicative, first, singular",
    GN: "G3973",
    gloss: "to cease",
  },
  ἐπαύσαντο: {
    parse: "V-AMI-3P｜Verb, aorist, middle, indicative, third, plural",
    GN: "G3973",
    gloss: "to cease",
  },
  ἐπαύσατο: {
    parse: "V-AMI-3S｜Verb, aorist, middle, indicative, third, singular",
    GN: "G3973",
    gloss: "to cease",
  },
  παύεται: {
    parse: "V-PMI-3S｜Verb, present, middle, indicative, third, singular",
    GN: "G3973",
    gloss: "to cease",
  },
  παύομαι: {
    parse: "V-PMI-1S｜Verb, present, middle, indicative, first, singular",
    GN: "G3973",
    gloss: "to cease",
  },
  παυόμεθα: {
    parse: "V-PMI-1P｜Verb, present, middle, indicative, first, plural",
    GN: "G3973",
    gloss: "to cease",
  },
  παύσασθαι: {
    parse: "V-AMN｜Verb, aorist, middle, Infinitive",
    GN: "G3973",
    gloss: "to cease",
  },
  παυσάτω: {
    parse: "V-AAM-3S｜Verb, aorist, active, imperative, third, singular",
    GN: "G3973",
    gloss: "to cease",
  },
  παύσῃ: {
    parse:
      "V-FDI-2S｜Verb, future, Middle deponent, indicative, second, singular",
    GN: "G3973",
    gloss: "to cease",
  },
  παύσονται: {
    parse: "V-FDI-3P｜Verb, future, Middle deponent, indicative, third, plural",
    GN: "G3973",
    gloss: "to cease",
  },
  πέπαυται: {
    parse: "V-RMI-3S｜Verb, perfect, middle, indicative, third, singular",
    GN: "G3973",
    gloss: "to cease",
  },
  Πάφου: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G3974",
    gloss: "Paphos",
  },
  ἐπαχύνθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G3975",
    gloss: "to thicken",
  },
  πέδαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G3976",
    gloss: "fetter",
  },
  πέδας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G3976",
    gloss: "fetter",
  },
  πεδινοῦ: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G3977",
    gloss: "level",
  },
  πεζεύειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3978",
    gloss: "to walk",
  },
  πεζῇ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3979",
    gloss: "on foot",
  },
  πειθαρχεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3980",
    gloss: "to obey",
  },
  Πειθαρχεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3980",
    gloss: "to obey",
  },
  πειθαρχήσαντάς: {
    parse:
      "V-AAP-APM｜Verb, aorist, active, Participle, accusative, plural, masculine",
    GN: "G3980",
    gloss: "to obey",
  },
  πειθαρχοῦσιν: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G3980",
    gloss: "to obey",
  },
  πειθοῖς: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G3981",
    gloss: "persuasive",
  },
  ἔπειθέν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G3982",
    gloss: "to persuade",
  },
  ἐπείθετο: {
    parse: "V-IPI-3S｜Verb, imperfect, passive, indicative, third, singular",
    GN: "G3982",
    gloss: "to persuade",
  },
  ἔπειθον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G3982",
    gloss: "to persuade",
  },
  ἐπείθοντο: {
    parse: "V-IPI-3P｜Verb, imperfect, passive, indicative, third, plural",
    GN: "G3982",
    gloss: "to persuade",
  },
  ἔπεισαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G3982",
    gloss: "to persuade",
  },
  ἐπείσθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G3982",
    gloss: "to persuade",
  },
  ἐπεποίθει: {
    parse:
      "V-2LAI-3S｜Verb, 2nd pluperfect, active, indicative, third, singular",
    GN: "G3982",
    gloss: "to persuade",
  },
  πείθεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G3982",
    gloss: "to persuade",
  },
  πείθεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G3982",
    gloss: "to persuade",
  },
  Πείθεσθε: {
    parse: "V-PPM-2P｜Verb, present, passive, imperative, second, plural",
    GN: "G3982",
    gloss: "to persuade",
  },
  πείθομαι: {
    parse: "V-PPI-1S｜Verb, present, passive, indicative, first, singular",
    GN: "G3982",
    gloss: "to persuade",
  },
  πειθόμεθα: {
    parse: "V-PPI-1P｜Verb, present, passive, indicative, first, plural",
    GN: "G3982",
    gloss: "to persuade",
  },
  πείθομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G3982",
    gloss: "to persuade",
  },
  πειθομένοις: {
    parse:
      "V-PPP-DPM｜Verb, present, passive, Participle, dative, plural, masculine",
    GN: "G3982",
    gloss: "to persuade",
  },
  πειθομένου: {
    parse:
      "V-PPP-GSM｜Verb, present, passive, Participle, genitive, singular, masculine",
    GN: "G3982",
    gloss: "to persuade",
  },
  πείθω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G3982",
    gloss: "to persuade",
  },
  πείθων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3982",
    gloss: "to persuade",
  },
  πείσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G3982",
    gloss: "to persuade",
  },
  πείσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G3982",
    gloss: "to persuade",
  },
  πεισθῇς: {
    parse: "V-APS-2S｜Verb, aorist, passive, subjunctive, second, singular",
    GN: "G3982",
    gloss: "to persuade",
  },
  πεισθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G3982",
    gloss: "to persuade",
  },
  πείσομεν: {
    parse: "V-FAI-1P｜Verb, future, active, indicative, first, plural",
    GN: "G3982",
    gloss: "to persuade",
  },
  πέπεισμαι: {
    parse: "V-RPI-1S｜Verb, perfect, passive, indicative, first, singular",
    GN: "G3982",
    gloss: "to persuade",
  },
  Πέπεισμαι: {
    parse: "V-RPI-1S｜Verb, perfect, passive, indicative, first, singular",
    GN: "G3982",
    gloss: "to persuade",
  },
  Πεπείσμεθα: {
    parse: "V-RPI-1P｜Verb, perfect, passive, indicative, first, plural",
    GN: "G3982",
    gloss: "to persuade",
  },
  πεπεισμένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G3982",
    gloss: "to persuade",
  },
  πέποιθα: {
    parse: "V-2RAI-1S｜Verb, 2nd perfect, active, indicative, first, singular",
    GN: "G3982",
    gloss: "to persuade",
  },
  πεποίθαμεν: {
    parse: "V-2RAI-1P｜Verb, 2nd perfect, active, indicative, first, plural",
    GN: "G3982",
    gloss: "to persuade",
  },
  πέποιθάς: {
    parse: "V-2RAI-2S｜Verb, 2nd perfect, active, indicative, second, singular",
    GN: "G3982",
    gloss: "to persuade",
  },
  πέποιθεν: {
    parse: "V-2RAI-3S｜Verb, 2nd perfect, active, indicative, third, singular",
    GN: "G3982",
    gloss: "to persuade",
  },
  πεποιθέναι: {
    parse: "V-2RAN｜Verb, 2nd perfect, active, Infinitive",
    GN: "G3982",
    gloss: "to persuade",
  },
  πεποιθότας: {
    parse:
      "V-2RAP-APM｜Verb, 2nd perfect, active, Participle, accusative, plural, masculine",
    GN: "G3982",
    gloss: "to persuade",
  },
  πεποιθότες: {
    parse:
      "V-2RAP-NPM｜Verb, 2nd perfect, active, Participle, nominative, plural, masculine",
    GN: "G3982",
    gloss: "to persuade",
  },
  πεποιθὼς: {
    parse:
      "V-2RAP-NSM｜Verb, 2nd perfect, active, Participle, nominative, singular, masculine",
    GN: "G3982",
    gloss: "to persuade",
  },
  ἐπείνασα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G3983",
    gloss: "to hunger",
  },
  ἐπείνασαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G3983",
    gloss: "to hunger",
  },
  ἐπείνασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3983",
    gloss: "to hunger",
  },
  πεινᾷ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G3983",
    gloss: "to hunger",
  },
  πεινᾶν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3983",
    gloss: "to hunger",
  },
  πεινάσετε: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G3983",
    gloss: "to hunger",
  },
  πεινάσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G3983",
    gloss: "to hunger",
  },
  πεινάσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G3983",
    gloss: "to hunger",
  },
  πεινῶμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G3983",
    gloss: "to hunger",
  },
  πεινῶντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G3983",
    gloss: "to hunger",
  },
  πεινῶντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G3983",
    gloss: "to hunger",
  },
  πεινῶντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G3983",
    gloss: "to hunger",
  },
  πεῖραν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3984",
    gloss: "test",
  },
  ἐπείραζεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G3985",
    gloss: "try",
  },
  ἐπείραζον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G3985",
    gloss: "try",
  },
  ἐπείρασαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G3985",
    gloss: "try",
  },
  ἐπείρασας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G3985",
    gloss: "try",
  },
  ἐπείρασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3985",
    gloss: "try",
  },
  πειράζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3985",
    gloss: "try",
  },
  πειράζεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G3985",
    gloss: "try",
  },
  πειράζετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G3985",
    gloss: "try",
  },
  πειράζῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G3985",
    gloss: "try",
  },
  πειράζομαι: {
    parse: "V-PPI-1S｜Verb, present, passive, indicative, first, singular",
    GN: "G3985",
    gloss: "try",
  },
  πειραζομένοις: {
    parse:
      "V-PPP-DPM｜Verb, present, passive, Participle, dative, plural, masculine",
    GN: "G3985",
    gloss: "try",
  },
  πειραζόμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G3985",
    gloss: "try",
  },
  πειράζοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G3985",
    gloss: "try",
  },
  πειράζων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G3985",
    gloss: "try",
  },
  πειράσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G3985",
    gloss: "try",
  },
  πειρασθείς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G3985",
    gloss: "try",
  },
  πειρασθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G3985",
    gloss: "try",
  },
  πειρασθῇς: {
    parse: "V-APS-2S｜Verb, aorist, passive, subjunctive, second, singular",
    GN: "G3985",
    gloss: "try",
  },
  πειρασθῆτε: {
    parse: "V-APS-2P｜Verb, aorist, passive, subjunctive, second, plural",
    GN: "G3985",
    gloss: "try",
  },
  πεπειρασμένον: {
    parse:
      "V-RPP-ASM｜Verb, perfect, passive, Participle, accusative, singular, masculine",
    GN: "G3985",
    gloss: "try",
  },
  πειρασμοῖς: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G3986",
    gloss: "testing",
  },
  πειρασμόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3986",
    gloss: "testing",
  },
  πειρασμὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G3986",
    gloss: "testing",
  },
  πειρασμὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3986",
    gloss: "testing",
  },
  πειρασμοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G3986",
    gloss: "testing",
  },
  πειρασμῷ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G3986",
    gloss: "testing",
  },
  πειρασμῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G3986",
    gloss: "testing",
  },
  ἐπειρῶντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G3987",
    gloss: "to try",
  },
  πεισμονὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3988",
    gloss: "persuasion",
  },
  πελάγει: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G3989",
    gloss: "sea",
  },
  πέλαγος: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G3989",
    gloss: "sea",
  },
  πεπελεκισμένων: {
    parse:
      "V-RPP-GPM｜Verb, perfect, passive, Participle, genitive, plural, masculine",
    GN: "G3990",
    gloss: "to behead",
  },
  πέμπτην: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G3991",
    gloss: "fifth",
  },
  πέμπτος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G3991",
    gloss: "fifth",
  },
  ἐπέμφθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G3992",
    gloss: "to send",
  },
  ἔπεμψα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G3992",
    gloss: "to send",
  },
  ἐπέμψαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G3992",
    gloss: "to send",
  },
  ἐπέμψατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G3992",
    gloss: "to send",
  },
  ἔπεμψεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G3992",
    gloss: "to send",
  },
  πέμπει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G3992",
    gloss: "to send",
  },
  πέμπειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3992",
    gloss: "to send",
  },
  πεμπομένοις: {
    parse:
      "V-PPP-DPM｜Verb, present, passive, Participle, dative, plural, masculine",
    GN: "G3992",
    gloss: "to send",
  },
  πέμποντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G3992",
    gloss: "to send",
  },
  πέμπω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G3992",
    gloss: "to send",
  },
  πεμφθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G3992",
    gloss: "to send",
  },
  πέμψαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G3992",
    gloss: "to send",
  },
  πέμψαντα: {
    parse:
      "V-AAP-ASM｜Verb, aorist, active, Participle, accusative, singular, masculine",
    GN: "G3992",
    gloss: "to send",
  },
  πέμψαντά: {
    parse:
      "V-AAP-ASM｜Verb, aorist, active, Participle, accusative, singular, masculine",
    GN: "G3992",
    gloss: "to send",
  },
  πέμψαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G3992",
    gloss: "to send",
  },
  πέμψαντί: {
    parse:
      "V-AAP-DSM｜Verb, aorist, active, Participle, dative, singular, masculine",
    GN: "G3992",
    gloss: "to send",
  },
  πέμψαντος: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G3992",
    gloss: "to send",
  },
  πέμψαντός: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G3992",
    gloss: "to send",
  },
  πέμψας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G3992",
    gloss: "to send",
  },
  πέμψασιν: {
    parse:
      "V-AAP-DPM｜Verb, aorist, active, Participle, dative, plural, masculine",
    GN: "G3992",
    gloss: "to send",
  },
  πέμψει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G3992",
    gloss: "to send",
  },
  πέμψῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G3992",
    gloss: "to send",
  },
  πέμψον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G3992",
    gloss: "to send",
  },
  Πέμψον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G3992",
    gloss: "to send",
  },
  πέμψουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G3992",
    gloss: "to send",
  },
  πέμψω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G3992",
    gloss: "to send",
  },
  πένησιν: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G3993",
    gloss: "poor",
  },
  πενθερὰ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G3994",
    gloss: "mother-in-law",
  },
  πενθεράν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3994",
    gloss: "mother-in-law",
  },
  πενθερὰν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G3994",
    gloss: "mother-in-law",
  },
  πενθερᾶς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G3994",
    gloss: "mother-in-law",
  },
  πενθερὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G3995",
    gloss: "father-in-law",
  },
  ἐπενθήσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G3996",
    gloss: "to mourn",
  },
  πενθεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G3996",
    gloss: "to mourn",
  },
  πενθήσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G3996",
    gloss: "to mourn",
  },
  πενθήσετε: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G3996",
    gloss: "to mourn",
  },
  πενθήσω: {
    parse: "V-AAS-1S｜Verb, aorist, active, subjunctive, first, singular",
    GN: "G3996",
    gloss: "to mourn",
  },
  πενθοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G3996",
    gloss: "to mourn",
  },
  πενθοῦσιν: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G3996",
    gloss: "to mourn",
  },
  πένθος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G3997",
    gloss: "grief",
  },
  πενιχρὰν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G3998",
    gloss: "poor",
  },
  πεντάκις: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G3999",
    gloss: "five times",
  },
  πεντακισχίλιοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4000",
    gloss: "five thousand",
  },
  πεντακισχιλίους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G4000",
    gloss: "five thousand",
  },
  πεντακισχιλίων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G4000",
    gloss: "five thousand",
  },
  πεντακόσια: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G4001",
    gloss: "five hundred",
  },
  πεντακοσίοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G4001",
    gloss: "five hundred",
  },
  πέντε: {
    parse:
      "A-NPN-NUI｜Adjective, nominative, plural, neuter, NUmerical Indiclinable (A)",
    GN: "G4002",
    gloss: "five",
  },
  Πέντε: {
    parse:
      "A-APM-NUI｜Adjective, accusative, plural, masculine, NUmerical Indiclinable (A)",
    GN: "G4002",
    gloss: "five",
  },
  πεντεκαιδεκάτῳ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G4003",
    gloss: "fifteenth",
  },
  πεντήκοντα: {
    parse:
      "A-GPM-NUI｜Adjective, genitive, plural, masculine, NUmerical Indiclinable (A)",
    GN: "G4004",
    gloss: "fifty",
  },
  Πεντήκοντα: {
    parse:
      "A-APN-NUI｜Adjective, accusative, plural, neuter, NUmerical Indiclinable (A)",
    GN: "G4004",
    gloss: "fifty",
  },
  πεντηκοστῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4005",
    gloss: "Pentecost",
  },
  πεποιθήσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4006",
    gloss: "confidence",
  },
  πεποίθησιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4006",
    gloss: "confidence",
  },
  Πεποίθησιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4006",
    gloss: "confidence",
  },
  πέραν: { parse: "PREP｜Preposition", GN: "G4008", gloss: "other side" },
  πέρας: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4009",
    gloss: "end",
  },
  πέρατα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G4009",
    gloss: "end",
  },
  περάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G4009",
    gloss: "end",
  },
  Πέργαμον: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G4010",
    gloss: "Pergamum",
  },
  Περγάμῳ: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G4010",
    gloss: "Pergamum",
  },
  Πέργῃ: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G4011",
    gloss: "Perga",
  },
  Πέργην: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G4011",
    gloss: "Perga",
  },
  Πέργης: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G4011",
    gloss: "Perga",
  },
  περί: { parse: "PREP｜Preposition", GN: "G4012", gloss: "about/concerning" },
  περὶ: { parse: "PREP｜Preposition", GN: "G4012", gloss: "about/concerning" },
  Περὶ: { parse: "PREP｜Preposition", GN: "G4012", gloss: "about/concerning" },
  περιάγειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4013",
    gloss: "to take/go around",
  },
  περιάγετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G4013",
    gloss: "to take/go around",
  },
  περιάγων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4013",
    gloss: "to take/go around",
  },
  περιῆγεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G4013",
    gloss: "to take/go around",
  },
  περιαιρεῖται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G4014",
    gloss: "to take away",
  },
  περιελεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G4014",
    gloss: "to take away",
  },
  περιελόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G4014",
    gloss: "to take away",
  },
  περιῃρεῖτο: {
    parse: "V-IPI-3S｜Verb, imperfect, passive, indicative, third, singular",
    GN: "G4014",
    gloss: "to take away",
  },
  περιαστράψαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G4015",
    gloss: "to shine",
  },
  περιήστραψεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4015",
    gloss: "to shine",
  },
  περιβαλεῖται: {
    parse: "V-FMI-3S｜Verb, future, middle, indicative, third, singular",
    GN: "G4016",
    gloss: "to clothe",
  },
  περιβάλῃ: {
    parse: "V-2AMS-2S｜Verb, 2nd aorist, middle, subjunctive, second, singular",
    GN: "G4016",
    gloss: "to clothe",
  },
  περιβάληται: {
    parse: "V-2AMS-3S｜Verb, 2nd aorist, middle, subjunctive, third, singular",
    GN: "G4016",
    gloss: "to clothe",
  },
  Περιβαλοῦ: {
    parse: "V-2AMM-2S｜Verb, 2nd aorist, middle, imperative, second, singular",
    GN: "G4016",
    gloss: "to clothe",
  },
  περιβαλώμεθα: {
    parse: "V-2AMS-1P｜Verb, 2nd aorist, middle, subjunctive, first, plural",
    GN: "G4016",
    gloss: "to clothe",
  },
  περιβαλὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G4016",
    gloss: "to clothe",
  },
  περιβεβλημένη: {
    parse:
      "V-REP-VSF｜Verb, perfect, Either middle or passive, Participle, Vocative or nominative, singular, feminine",
    GN: "G4016",
    gloss: "to clothe",
  },
  περιβεβλημένοι: {
    parse:
      "V-RMP-NPM｜Verb, perfect, middle, Participle, nominative, plural, masculine",
    GN: "G4016",
    gloss: "to clothe",
  },
  περιβεβλημένον: {
    parse:
      "V-RMP-ASM｜Verb, perfect, middle, Participle, accusative, singular, masculine",
    GN: "G4016",
    gloss: "to clothe",
  },
  περιβεβλημένος: {
    parse:
      "V-RMP-NSM｜Verb, perfect, middle, Participle, nominative, singular, masculine",
    GN: "G4016",
    gloss: "to clothe",
  },
  περιβεβλημένους: {
    parse:
      "V-RMP-APM｜Verb, perfect, middle, Participle, accusative, plural, masculine",
    GN: "G4016",
    gloss: "to clothe",
  },
  περιεβάλετέ: {
    parse: "V-2AAI-2P｜Verb, 2nd aorist, active, indicative, second, plural",
    GN: "G4016",
    gloss: "to clothe",
  },
  περιεβάλετο: {
    parse: "V-2AMI-3S｜Verb, 2nd aorist, middle, indicative, third, singular",
    GN: "G4016",
    gloss: "to clothe",
  },
  περιεβάλομεν: {
    parse: "V-2AAI-1P｜Verb, 2nd aorist, active, indicative, first, plural",
    GN: "G4016",
    gloss: "to clothe",
  },
  περιέβαλον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G4016",
    gloss: "to clothe",
  },
  περιβλεψάμενοι: {
    parse:
      "V-AMP-NPM｜Verb, aorist, middle, Participle, nominative, plural, masculine",
    GN: "G4017",
    gloss: "to look around",
  },
  περιβλεψάμενος: {
    parse:
      "V-AMP-NSM｜Verb, aorist, middle, Participle, nominative, singular, masculine",
    GN: "G4017",
    gloss: "to look around",
  },
  περιεβλέπετο: {
    parse: "V-IMI-3S｜Verb, imperfect, middle, indicative, third, singular",
    GN: "G4017",
    gloss: "to look around",
  },
  περιβόλαιον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G4018",
    gloss: "covering",
  },
  περιβολαίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4018",
    gloss: "covering",
  },
  περιεδέδετο: {
    parse: "V-LPI-3S｜Verb, pluperfect, passive, indicative, third, singular",
    GN: "G4019",
    gloss: "to wrap around",
  },
  περιεργαζομένους: {
    parse:
      "V-PNP-APM｜Verb, present, Middle or Passive deponent, Participle, accusative, plural, masculine",
    GN: "G4020",
    gloss: "be a busybody",
  },
  περίεργα: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G4021",
    gloss: "meddlesome/magic",
  },
  περίεργοι: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G4021",
    gloss: "meddlesome/magic",
  },
  περιερχόμεναι: {
    parse:
      "V-PNP-NPF｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, feminine",
    GN: "G4022",
    gloss: "to go around",
  },
  περιερχομένων: {
    parse:
      "V-PNP-GPM｜Verb, present, Middle or Passive deponent, Participle, genitive, plural, masculine",
    GN: "G4022",
    gloss: "to go around",
  },
  περιῆλθον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G4022",
    gloss: "to go around",
  },
  περιέσχεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G4023",
    gloss: "to contain",
  },
  περιέχει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4023",
    gloss: "to contain",
  },
  περιεζωσμέναι: {
    parse:
      "V-RPP-NPF｜Verb, perfect, passive, Participle, nominative, plural, feminine",
    GN: "G4024",
    gloss: "to gird",
  },
  περιεζωσμένοι: {
    parse:
      "V-RMP-NPM｜Verb, perfect, middle, Participle, nominative, plural, masculine",
    GN: "G4024",
    gloss: "to gird",
  },
  περιεζωσμένον: {
    parse:
      "V-RMP-ASM｜Verb, perfect, middle, Participle, accusative, singular, masculine",
    GN: "G4024",
    gloss: "to gird",
  },
  περιζωσάμενοι: {
    parse:
      "V-AMP-NPM｜Verb, aorist, middle, Participle, nominative, plural, masculine",
    GN: "G4024",
    gloss: "to gird",
  },
  περιζωσάμενος: {
    parse:
      "V-AMP-NSM｜Verb, aorist, middle, Participle, nominative, singular, masculine",
    GN: "G4024",
    gloss: "to gird",
  },
  περιζώσεται: {
    parse: "V-FMI-3S｜Verb, future, middle, indicative, third, singular",
    GN: "G4024",
    gloss: "to gird",
  },
  περιθέσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4025",
    gloss: "wearing",
  },
  περιέστησαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G4026",
    gloss: "to stand around",
  },
  περιεστῶτα: {
    parse:
      "V-RAP-ASM｜Verb, perfect, active, Participle, accusative, singular, masculine",
    GN: "G4026",
    gloss: "to stand around",
  },
  περιΐστασο: {
    parse: "V-PMM-2S｜Verb, present, middle, imperative, second, singular",
    GN: "G4026",
    gloss: "to stand around",
  },
  περικαθάρματα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G4027",
    gloss: "garbage",
  },
  περικαλύπτειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4028",
    gloss: "to cover",
  },
  περικαλύψαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G4028",
    gloss: "to cover",
  },
  περικεκαλυμμένην: {
    parse:
      "V-RPP-ASF｜Verb, perfect, passive, Participle, accusative, singular, feminine",
    GN: "G4028",
    gloss: "to cover",
  },
  περίκειμαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G4029",
    gloss: "to surround",
  },
  περικείμενον: {
    parse:
      "V-PNP-ASN｜Verb, present, Middle or Passive deponent, Participle, accusative, singular, neuter",
    GN: "G4029",
    gloss: "to surround",
  },
  περίκειται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G4029",
    gloss: "to surround",
  },
  περικεφαλαίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4030",
    gloss: "helmet",
  },
  περικρατεῖς: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4031",
    gloss: "in control of",
  },
  περιέκρυβεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G4032",
    gloss: "to hide",
  },
  περικυκλώσουσίν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G4033",
    gloss: "to surround",
  },
  περιέλαμψεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4034",
    gloss: "to shine around",
  },
  περιλάμψαν: {
    parse:
      "V-AAP-ASN｜Verb, aorist, active, Participle, accusative, singular, neuter",
    GN: "G4034",
    gloss: "to shine around",
  },
  περιλειπόμενοι: {
    parse:
      "V-POP-NPM｜Verb, present, Passive deponent, Participle, nominative, plural, masculine",
    GN: "G4035",
    gloss: "to remain",
  },
  περίλυπον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G4036",
    gloss: "sorrowful",
  },
  Περίλυπός: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G4036",
    gloss: "sorrowful",
  },
  περίλυπος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4036",
    gloss: "sorrowful",
  },
  περιμένειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4037",
    gloss: "to await",
  },
  πέριξ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4038",
    gloss: "around",
  },
  περιοικοῦντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G4039",
    gloss: "to dwell around",
  },
  περίοικοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4040",
    gloss: "neighboring",
  },
  περιούσιον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G4041",
    gloss: "special",
  },
  περιοχὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4042",
    gloss: "passage",
  },
  περιεπάτει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G4043",
    gloss: "to walk",
  },
  περιεπάτεις: {
    parse: "V-IAI-2S｜Verb, imperfect, active, indicative, second, singular",
    GN: "G4043",
    gloss: "to walk",
  },
  περιεπατήσαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G4043",
    gloss: "to walk",
  },
  περιεπατήσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G4043",
    gloss: "to walk",
  },
  περιεπατήσατέ: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G4043",
    gloss: "to walk",
  },
  περιεπάτησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4043",
    gloss: "to walk",
  },
  περιεπάτουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G4043",
    gloss: "to walk",
  },
  περιπατεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4043",
    gloss: "to walk",
  },
  περιπάτει: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G4043",
    gloss: "to walk",
  },
  περιπατεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4043",
    gloss: "to walk",
  },
  περιπατεῖς: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G4043",
    gloss: "to walk",
  },
  περιπατεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G4043",
    gloss: "to walk",
  },
  περιπατείτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G4043",
    gloss: "to walk",
  },
  περιπατῇ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G4043",
    gloss: "to walk",
  },
  περιπατῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G4043",
    gloss: "to walk",
  },
  περιπατήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G4043",
    gloss: "to walk",
  },
  περιπατήσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G4043",
    gloss: "to walk",
  },
  περιπατήσωμεν: {
    parse: "V-AAS-1P｜Verb, aorist, active, subjunctive, first, plural",
    GN: "G4043",
    gloss: "to walk",
  },
  περιπατῆτε: {
    parse: "V-PAS-2P｜Verb, present, active, subjunctive, second, plural",
    GN: "G4043",
    gloss: "to walk",
  },
  περιπατοῦμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G4043",
    gloss: "to walk",
  },
  περιπατοῦντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G4043",
    gloss: "to walk",
  },
  περιπατοῦντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G4043",
    gloss: "to walk",
  },
  περιπατοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4043",
    gloss: "to walk",
  },
  περιπατοῦντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G4043",
    gloss: "to walk",
  },
  περιπατοῦντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G4043",
    gloss: "to walk",
  },
  περιπατοῦσιν: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G4043",
    gloss: "to walk",
  },
  περιπατῶμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G4043",
    gloss: "to walk",
  },
  περιπατῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4043",
    gloss: "to walk",
  },
  Περιπατῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4043",
    gloss: "to walk",
  },
  περιέπειραν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4044",
    gloss: "to pierce",
  },
  περιέπεσεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G4045",
    gloss: "to fall upon",
  },
  περιπέσητε: {
    parse: "V-2AAS-2P｜Verb, 2nd aorist, active, subjunctive, second, plural",
    GN: "G4045",
    gloss: "to fall upon",
  },
  περιπεσόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G4045",
    gloss: "to fall upon",
  },
  περιεποιήσατο: {
    parse: "V-AMI-3S｜Verb, aorist, middle, indicative, third, singular",
    GN: "G4046",
    gloss: "to gain",
  },
  περιποιήσασθαι: {
    parse: "V-AMN｜Verb, aorist, middle, Infinitive",
    GN: "G4046",
    gloss: "to gain",
  },
  περιποιοῦνται: {
    parse: "V-PMI-3P｜Verb, present, middle, indicative, third, plural",
    GN: "G4046",
    gloss: "to gain",
  },
  περιποιήσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4047",
    gloss: "acquiring",
  },
  περιποίησιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4047",
    gloss: "acquiring",
  },
  περιρήξαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G4048",
    gloss: "to strip off",
  },
  περιεσπᾶτο: {
    parse: "V-IPI-3S｜Verb, imperfect, passive, indicative, third, singular",
    GN: "G4049",
    gloss: "to distract",
  },
  περισσεία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4050",
    gloss: "abundance",
  },
  περισσείαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4050",
    gloss: "abundance",
  },
  περίσσευμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4051",
    gloss: "overflow",
  },
  περισσεύματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G4051",
    gloss: "overflow",
  },
  περισσεύματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4051",
    gloss: "overflow",
  },
  ἐπερίσσευον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G4052",
    gloss: "to abound/exceed",
  },
  ἐπερίσσευσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4052",
    gloss: "to abound/exceed",
  },
  ἐπερίσσευσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4052",
    gloss: "to abound/exceed",
  },
  περισσεύει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4052",
    gloss: "to abound/exceed",
  },
  περισσεύειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4052",
    gloss: "to abound/exceed",
  },
  περισσεύετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G4052",
    gloss: "to abound/exceed",
  },
  περισσεύῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G4052",
    gloss: "to abound/exceed",
  },
  περισσεύητε: {
    parse: "V-PAS-2P｜Verb, present, active, subjunctive, second, plural",
    GN: "G4052",
    gloss: "to abound/exceed",
  },
  περισσευθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G4052",
    gloss: "to abound/exceed",
  },
  περισσεύομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G4052",
    gloss: "to abound/exceed",
  },
  περισσεῦον: {
    parse:
      "V-PAP-ASN｜Verb, present, active, Participle, accusative, singular, neuter",
    GN: "G4052",
    gloss: "to abound/exceed",
  },
  περισσεύονται: {
    parse: "V-PMI-3P｜Verb, present, middle, indicative, third, plural",
    GN: "G4052",
    gloss: "to abound/exceed",
  },
  περισσεύοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4052",
    gloss: "to abound/exceed",
  },
  περισσεύοντος: {
    parse:
      "V-PAP-GSN｜Verb, present, active, Participle, genitive, singular, neuter",
    GN: "G4052",
    gloss: "to abound/exceed",
  },
  περισσεύουσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G4052",
    gloss: "to abound/exceed",
  },
  περισσεῦσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G4052",
    gloss: "to abound/exceed",
  },
  περισσεύσαι: {
    parse: "V-AAO-3S｜Verb, aorist, active, Optative, third, singular",
    GN: "G4052",
    gloss: "to abound/exceed",
  },
  περισσεῦσαν: {
    parse:
      "V-AAP-NSN｜Verb, aorist, active, Participle, nominative, singular, neuter",
    GN: "G4052",
    gloss: "to abound/exceed",
  },
  περισσεύσαντα: {
    parse:
      "V-AAP-APN｜Verb, aorist, active, Participle, accusative, plural, neuter",
    GN: "G4052",
    gloss: "to abound/exceed",
  },
  περισσεύσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G4052",
    gloss: "to abound/exceed",
  },
  περισσεύω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G4052",
    gloss: "to abound/exceed",
  },
  περισσὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G4053",
    gloss: "excessive/abundant",
  },
  περισσόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G4053",
    gloss: "excessive/abundant",
  },
  περισσοῦ: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G4053",
    gloss: "excessive/abundant",
  },
  περισσοτέρᾳ: {
    parse: "A-DSF-C｜Adjective, dative, singular, feminine, Comparative",
    GN: "G4055",
    gloss: "superabundant",
  },
  περισσοτέραν: {
    parse: "A-ASF-C｜Adjective, accusative, singular, feminine, Comparative",
    GN: "G4055",
    gloss: "superabundant",
  },
  περισσότερον: {
    parse: "A-ASN-C｜Adjective, accusative, singular, neuter, Comparative",
    GN: "G4055",
    gloss: "superabundant",
  },
  περισσότερόν: {
    parse: "A-NSN-C｜Adjective, nominative, singular, neuter, Comparative",
    GN: "G4055",
    gloss: "superabundant",
  },
  περισσοτέρως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4056",
    gloss: "superabundantly",
  },
  περισσῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4057",
    gloss: "superabundantly",
  },
  περιστεραί: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G4058",
    gloss: "dove",
  },
  περιστερὰν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4058",
    gloss: "dove",
  },
  περιστεράς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G4058",
    gloss: "dove",
  },
  περιστερὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G4058",
    gloss: "dove",
  },
  περιστερῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G4058",
    gloss: "dove",
  },
  περιέτεμεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G4059",
    gloss: "to circumcise",
  },
  περιετμήθητε: {
    parse: "V-2API-2P｜Verb, 2nd aorist, passive, indicative, second, plural",
    GN: "G4059",
    gloss: "to circumcise",
  },
  περιτεμεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G4059",
    gloss: "to circumcise",
  },
  περιτέμνειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4059",
    gloss: "to circumcise",
  },
  περιτέμνεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G4059",
    gloss: "to circumcise",
  },
  περιτεμνέσθω: {
    parse: "V-PPM-3S｜Verb, present, passive, imperative, third, singular",
    GN: "G4059",
    gloss: "to circumcise",
  },
  περιτέμνετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G4059",
    gloss: "to circumcise",
  },
  περιτέμνησθε: {
    parse: "V-PPS-2P｜Verb, present, passive, subjunctive, second, plural",
    GN: "G4059",
    gloss: "to circumcise",
  },
  περιτεμνόμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G4059",
    gloss: "to circumcise",
  },
  περιτεμνομένῳ: {
    parse:
      "V-PPP-DSM｜Verb, present, passive, Participle, dative, singular, masculine",
    GN: "G4059",
    gloss: "to circumcise",
  },
  περιτετμημένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G4059",
    gloss: "to circumcise",
  },
  περιτμηθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G4059",
    gloss: "to circumcise",
  },
  περιτμηθῆτε: {
    parse: "V-APS-2P｜Verb, aorist, passive, subjunctive, second, plural",
    GN: "G4059",
    gloss: "to circumcise",
  },
  περιέθηκαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4060",
    gloss: "to put on",
  },
  περιέθηκεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4060",
    gloss: "to put on",
  },
  περιθεὶς: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G4060",
    gloss: "to put on",
  },
  περιθέντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G4060",
    gloss: "to put on",
  },
  περιτιθέασιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G4060",
    gloss: "to put on",
  },
  περιτίθεμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G4060",
    gloss: "to put on",
  },
  περιτομῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4061",
    gloss: "circumcision",
  },
  περιτομή: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4061",
    gloss: "circumcision",
  },
  περιτομὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4061",
    gloss: "circumcision",
  },
  Περιτομὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4061",
    gloss: "circumcision",
  },
  περιτομήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4061",
    gloss: "circumcision",
  },
  περιτομὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4061",
    gloss: "circumcision",
  },
  περιτομῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4061",
    gloss: "circumcision",
  },
  περιτρέπει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4062",
    gloss: "to drive insane",
  },
  περιέδραμον: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4063",
    gloss: "to run around",
  },
  περιφέρειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4064",
    gloss: "to carry (around)",
  },
  περιφερόμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G4064",
    gloss: "to carry (around)",
  },
  περιφέροντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4064",
    gloss: "to carry (around)",
  },
  περιφρονείτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G4065",
    gloss: "to despise",
  },
  περίχωρον: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G4066",
    gloss: "surrounding region",
  },
  περίχωρος: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G4066",
    gloss: "surrounding region",
  },
  περιχώρου: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G4066",
    gloss: "surrounding region",
  },
  περιχώρῳ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G4066",
    gloss: "surrounding region",
  },
  περίψημα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4067",
    gloss: "scum",
  },
  περπερεύεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G4068",
    gloss: "to boast",
  },
  Περσίδα: {
    parse: "N-ASF-P｜Noun, accusative, singular, feminine, Person",
    GN: "G4069",
    gloss: "Persis",
  },
  πέρυσι: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4070",
    gloss: "last year",
  },
  πετεινὰ: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G4071",
    gloss: "bird",
  },
  πετεινῶν: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G4071",
    gloss: "bird",
  },
  πέτηται: {
    parse:
      "V-PNS-3S｜Verb, present, Middle or Passive deponent, subjunctive, third, singular",
    GN: "G4072",
    gloss: "to fly",
  },
  πετομένοις: {
    parse:
      "V-PNP-DPN｜Verb, present, Middle or Passive deponent, Participle, dative, plural, neuter",
    GN: "G4072",
    gloss: "to fly",
  },
  πετόμενον: {
    parse:
      "V-PNP-ASM｜Verb, present, Middle or Passive deponent, Participle, accusative, singular, masculine",
    GN: "G4072",
    gloss: "to fly",
  },
  πετομένου: {
    parse:
      "V-PNP-GSM｜Verb, present, Middle or Passive deponent, Participle, genitive, singular, masculine",
    GN: "G4072",
    gloss: "to fly",
  },
  πετομένῳ: {
    parse:
      "V-PNP-DSM｜Verb, present, Middle or Passive deponent, Participle, dative, singular, masculine",
    GN: "G4072",
    gloss: "to fly",
  },
  πέτρᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4073",
    gloss: "rock",
  },
  πέτρα: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4073",
    gloss: "rock",
  },
  πέτραι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G4073",
    gloss: "rock",
  },
  πέτραις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G4073",
    gloss: "rock",
  },
  πέτραν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4073",
    gloss: "rock",
  },
  πέτρας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4073",
    gloss: "rock",
  },
  Πέτρε: {
    parse: "N-VSM-P｜Noun, Vocative or nominative, singular, masculine, Person",
    GN: "G4074",
    gloss: "[wife of Peter]",
  },
  Πέτρον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G4074",
    gloss: "[wife of Peter]",
  },
  Πέτρος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G4074",
    gloss: "[wife of Peter]",
  },
  Πέτρου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G4074",
    gloss: "[wife of Peter]",
  },
  Πέτρῳ: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G4074",
    gloss: "[wife of Peter]",
  },
  πετρῶδες: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G4075",
    gloss: "rocky",
  },
  πετρώδη: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G4075",
    gloss: "rocky",
  },
  πήγανον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G4076",
    gloss: "rue",
  },
  πηγαὶ: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G4077",
    gloss: "flow",
  },
  πηγὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G4077",
    gloss: "flow",
  },
  πηγῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4077",
    gloss: "flow",
  },
  πηγὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4077",
    gloss: "flow",
  },
  πηγῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4077",
    gloss: "flow",
  },
  ἔπηξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4078",
    gloss: "to set up",
  },
  πηδαλίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4079",
    gloss: "rudder",
  },
  πηδαλίων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G4079",
    gloss: "rudder",
  },
  πηλίκοις: {
    parse: "I-DPN｜Interrogative pronoun, dative, plural, neuter",
    GN: "G4080",
    gloss: "how great",
  },
  πηλίκος: {
    parse: "I-NSM｜Interrogative pronoun, nominative, singular, masculine",
    GN: "G4080",
    gloss: "how great",
  },
  πηλὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4081",
    gloss: "clay",
  },
  Πηλὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4081",
    gloss: "clay",
  },
  πηλοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G4081",
    gloss: "clay",
  },
  πήραν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4082",
    gloss: "bag",
  },
  πήρας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4082",
    gloss: "bag",
  },
  πῆχυν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4083",
    gloss: "cubit/hour",
  },
  πηχῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G4083",
    gloss: "cubit/hour",
  },
  ἐπίασαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4084",
    gloss: "to arrest/catch",
  },
  ἐπιάσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G4084",
    gloss: "to arrest/catch",
  },
  ἐπίασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4084",
    gloss: "to arrest/catch",
  },
  ἐπιάσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G4084",
    gloss: "to arrest/catch",
  },
  πιάσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G4084",
    gloss: "to arrest/catch",
  },
  πιάσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G4084",
    gloss: "to arrest/catch",
  },
  πιάσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G4084",
    gloss: "to arrest/catch",
  },
  πεπιεσμένον: {
    parse:
      "V-RPP-ASN｜Verb, perfect, passive, Participle, accusative, singular, neuter",
    GN: "G4085",
    gloss: "to press down",
  },
  πιθανολογίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4086",
    gloss: "persuasive speech",
  },
  ἐπικράνθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G4087",
    gloss: "to embitter",
  },
  ἐπικράνθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G4087",
    gloss: "to embitter",
  },
  πικραίνεσθε: {
    parse: "V-PPM-2P｜Verb, present, passive, imperative, second, plural",
    GN: "G4087",
    gloss: "to embitter",
  },
  πικρανεῖ: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G4087",
    gloss: "to embitter",
  },
  πικρία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4088",
    gloss: "bitterness",
  },
  πικρίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4088",
    gloss: "bitterness",
  },
  πικρὸν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G4089",
    gloss: "bitter",
  },
  πικρόν: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G4089",
    gloss: "bitter",
  },
  πικρῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4090",
    gloss: "bitterly",
  },
  Πιλᾶτον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G4091",
    gloss: "Pilate",
  },
  Πιλᾶτος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G4091",
    gloss: "Pilate",
  },
  Πιλάτου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G4091",
    gloss: "Pilate",
  },
  Πιλάτῳ: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G4091",
    gloss: "Pilate",
  },
  πίμπρασθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G4092",
    gloss: "to swell up",
  },
  πινακίδιον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G4093",
    gloss: "little tablet",
  },
  πίνακι: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G4094",
    gloss: "platter",
  },
  πίνακος: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G4094",
    gloss: "platter",
  },
  ἔπιεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G4095",
    gloss: "to drink",
  },
  ἔπινον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G4095",
    gloss: "to drink",
  },
  ἐπίομεν: {
    parse: "V-2AAI-1P｜Verb, 2nd aorist, active, indicative, first, plural",
    GN: "G4095",
    gloss: "to drink",
  },
  ἔπιον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G4095",
    gloss: "to drink",
  },
  πεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G4095",
    gloss: "to drink",
  },
  πέπωκαν: {
    parse: "V-RAI-3P｜Verb, perfect, active, indicative, third, plural",
    GN: "G4095",
    gloss: "to drink",
  },
  πίε: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G4095",
    gloss: "to drink",
  },
  πιεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G4095",
    gloss: "to drink",
  },
  πίεσαι: {
    parse:
      "V-FDI-2S｜Verb, future, Middle deponent, indicative, second, singular",
    GN: "G4095",
    gloss: "to drink",
  },
  πίεσθε: {
    parse:
      "V-FDI-2P｜Verb, future, Middle deponent, indicative, second, plural",
    GN: "G4095",
    gloss: "to drink",
  },
  πίεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G4095",
    gloss: "to drink",
  },
  Πίετε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G4095",
    gloss: "to drink",
  },
  πίῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G4095",
    gloss: "to drink",
  },
  πίητε: {
    parse: "V-2AAS-2P｜Verb, 2nd aorist, active, subjunctive, second, plural",
    GN: "G4095",
    gloss: "to drink",
  },
  πίνει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4095",
    gloss: "to drink",
  },
  πίνειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4095",
    gloss: "to drink",
  },
  πίνετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G4095",
    gloss: "to drink",
  },
  πινέτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G4095",
    gloss: "to drink",
  },
  πίνῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G4095",
    gloss: "to drink",
  },
  πίνητε: {
    parse: "V-PAS-2P｜Verb, present, active, subjunctive, second, plural",
    GN: "G4095",
    gloss: "to drink",
  },
  πίνοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4095",
    gloss: "to drink",
  },
  πίνουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G4095",
    gloss: "to drink",
  },
  πίνω: {
    parse: "V-PAS-1S｜Verb, present, active, subjunctive, first, singular",
    GN: "G4095",
    gloss: "to drink",
  },
  πίνων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4095",
    gloss: "to drink",
  },
  πιοῦσα: {
    parse:
      "V-2AAP-NSF｜Verb, 2nd aorist, active, Participle, nominative, singular, feminine",
    GN: "G4095",
    gloss: "to drink",
  },
  πίω: {
    parse: "V-2AAS-1S｜Verb, 2nd aorist, active, subjunctive, first, singular",
    GN: "G4095",
    gloss: "to drink",
  },
  πίωμεν: {
    parse: "V-2AAS-1P｜Verb, 2nd aorist, active, subjunctive, first, plural",
    GN: "G4095",
    gloss: "to drink",
  },
  πιὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G4095",
    gloss: "to drink",
  },
  πίωσιν: {
    parse: "V-2AAS-3P｜Verb, 2nd aorist, active, subjunctive, third, plural",
    GN: "G4095",
    gloss: "to drink",
  },
  πιότητος: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4096",
    gloss: "richness",
  },
  ἐπίπρασκον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G4097",
    gloss: "to sell",
  },
  ἐπράθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G4097",
    gloss: "to sell",
  },
  πέπρακεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G4097",
    gloss: "to sell",
  },
  πεπραμένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G4097",
    gloss: "to sell",
  },
  πιπρασκομένων: {
    parse:
      "V-PPP-GPN｜Verb, present, passive, Participle, genitive, plural, neuter",
    GN: "G4097",
    gloss: "to sell",
  },
  πραθὲν: {
    parse:
      "V-APP-NSN｜Verb, aorist, passive, Participle, nominative, singular, neuter",
    GN: "G4097",
    gloss: "to sell",
  },
  πραθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G4097",
    gloss: "to sell",
  },
  ἔπεσα: {
    parse: "V-2AAI-1S｜Verb, 2nd aorist, active, indicative, first, singular",
    GN: "G4098",
    gloss: "to collapse",
  },
  ἔπεσά: {
    parse: "V-2AAI-1S｜Verb, 2nd aorist, active, indicative, first, singular",
    GN: "G4098",
    gloss: "to collapse",
  },
  ἔπεσαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G4098",
    gloss: "to collapse",
  },
  ἔπεσεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G4098",
    gloss: "to collapse",
  },
  Ἔπεσεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G4098",
    gloss: "to collapse",
  },
  ἔπιπτεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G4098",
    gloss: "to collapse",
  },
  πέπτωκας: {
    parse: "V-RAI-2S｜Verb, perfect, active, indicative, second, singular",
    GN: "G4098",
    gloss: "to collapse",
  },
  πεπτωκότα: {
    parse:
      "V-RAP-ASM｜Verb, perfect, active, Participle, accusative, singular, masculine",
    GN: "G4098",
    gloss: "to collapse",
  },
  πεπτωκυῖαν: {
    parse:
      "V-RAP-ASF｜Verb, perfect, active, Participle, accusative, singular, feminine",
    GN: "G4098",
    gloss: "to collapse",
  },
  πεσεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G4098",
    gloss: "to collapse",
  },
  πεσεῖται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G4098",
    gloss: "to collapse",
  },
  Πέσετε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G4098",
    gloss: "to collapse",
  },
  πέσῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G4098",
    gloss: "to collapse",
  },
  πέσητε: {
    parse: "V-2AAS-2P｜Verb, 2nd aorist, active, subjunctive, second, plural",
    GN: "G4098",
    gloss: "to collapse",
  },
  πεσόν: {
    parse:
      "V-2AAP-NSN｜Verb, 2nd aorist, active, Participle, nominative, singular, neuter",
    GN: "G4098",
    gloss: "to collapse",
  },
  πεσόντα: {
    parse:
      "V-2AAP-ASM｜Verb, 2nd aorist, active, Participle, accusative, singular, masculine",
    GN: "G4098",
    gloss: "to collapse",
  },
  πεσόντας: {
    parse:
      "V-2AAP-APM｜Verb, 2nd aorist, active, Participle, accusative, plural, masculine",
    GN: "G4098",
    gloss: "to collapse",
  },
  πεσόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G4098",
    gloss: "to collapse",
  },
  πεσοῦνται: {
    parse:
      "V-FNI-3P｜Verb, future, Middle or Passive deponent, indicative, third, plural",
    GN: "G4098",
    gloss: "to collapse",
  },
  πεσὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G4098",
    gloss: "to collapse",
  },
  πέσωσιν: {
    parse: "V-2AAS-3P｜Verb, 2nd aorist, active, subjunctive, third, plural",
    GN: "G4098",
    gloss: "to collapse",
  },
  πίπτει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4098",
    gloss: "to collapse",
  },
  πίπτοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4098",
    gloss: "to collapse",
  },
  πιπτόντων: {
    parse:
      "V-PAP-GPN｜Verb, present, active, Participle, genitive, plural, neuter",
    GN: "G4098",
    gloss: "to collapse",
  },
  Πισιδίαν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G4099",
    gloss: "Pisidia",
  },
  ἐπίστευεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  ἐπιστεύετε: {
    parse: "V-IAI-2P｜Verb, imperfect, active, indicative, second, plural",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  ἐπιστεύθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  ἐπιστεύθην: {
    parse: "V-API-1S｜Verb, aorist, passive, indicative, first, singular",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  ἐπιστεύθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  ἐπίστευον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  Ἐπίστευσα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  ἐπιστεύσαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  ἐπίστευσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  ἐπίστευσας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  ἐπιστεύσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  ἐπίστευσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  Ἐπίστευσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πεπίστευκα: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πεπιστεύκαμεν: {
    parse: "V-RAI-1P｜Verb, perfect, active, indicative, first, plural",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πεπίστευκας: {
    parse: "V-RAI-2S｜Verb, perfect, active, indicative, second, singular",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πεπιστεύκατε: {
    parse: "V-RAI-2P｜Verb, perfect, active, indicative, second, plural",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πεπιστεύκεισαν: {
    parse: "V-LAI-3P｜Verb, pluperfect, active, indicative, third, plural",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πεπίστευκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πεπιστευκόσιν: {
    parse:
      "V-RAP-DPM｜Verb, perfect, active, Participle, dative, plural, masculine",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πεπιστευκότας: {
    parse:
      "V-RAP-APM｜Verb, perfect, active, Participle, accusative, plural, masculine",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πεπιστευκότες: {
    parse:
      "V-RAP-NPM｜Verb, perfect, active, Participle, nominative, plural, masculine",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πεπιστευκότων: {
    parse:
      "V-RAP-GPN｜Verb, perfect, active, Participle, genitive, plural, neuter",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πεπιστευκὼς: {
    parse:
      "V-RAP-NSM｜Verb, perfect, active, Participle, nominative, singular, masculine",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πεπίστευμαι: {
    parse: "V-RPI-1S｜Verb, perfect, passive, indicative, first, singular",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πίστευε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  Πίστευέ: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστεύει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστεύειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστεύεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστεύεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστεύετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  Πιστεύετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστεύετέ: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστεύῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστεύητε: {
    parse: "V-PAS-2P｜Verb, present, active, subjunctive, second, plural",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστευθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστεύομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστεύοντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστεύοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστεύοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστεύοντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστευόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστεύουσιν: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστεῦσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστεύσαντας: {
    parse:
      "V-AAP-APM｜Verb, aorist, active, Participle, accusative, plural, masculine",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστεύσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστευσάντων: {
    parse:
      "V-AAP-GPM｜Verb, aorist, active, Participle, genitive, plural, masculine",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστεύσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστεύσασα: {
    parse:
      "V-AAP-NSF｜Verb, aorist, active, Participle, nominative, singular, feminine",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστεύσασιν: {
    parse:
      "V-AAP-DPM｜Verb, aorist, active, Participle, dative, plural, masculine",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστεύσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστεύσετε: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστεύσῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστεύσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστεύσομεν: {
    parse: "V-FAI-1P｜Verb, future, active, indicative, first, plural",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πίστευσον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  Πίστευσον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστεύσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστεύσω: {
    parse: "V-AAS-1S｜Verb, aorist, active, subjunctive, first, singular",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστεύσωμεν: {
    parse: "V-AAS-1P｜Verb, aorist, active, subjunctive, first, plural",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστεύσωμέν: {
    parse: "V-AAS-1P｜Verb, aorist, active, subjunctive, first, plural",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστεύσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστεύω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  Πιστεύω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστεύων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4100",
    gloss: "to trust (in)",
  },
  πιστικῆς: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G4101",
    gloss: "pure",
  },
  πίστει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4102",
    gloss: "faith",
  },
  Πίστει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4102",
    gloss: "faith",
  },
  πίστεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4102",
    gloss: "faith",
  },
  πίστεώς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4102",
    gloss: "faith",
  },
  πίστιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4102",
    gloss: "faith",
  },
  πίστις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4102",
    gloss: "faith",
  },
  πιστά: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G4103",
    gloss: "faithful",
  },
  πιστὰς: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G4103",
    gloss: "faithful",
  },
  πιστέ: {
    parse: "A-VSM｜Adjective, Vocative or nominative, singular, masculine",
    GN: "G4103",
    gloss: "faithful",
  },
  πιστὴ: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G4103",
    gloss: "faithful",
  },
  πιστὴν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G4103",
    gloss: "faithful",
  },
  πιστῆς: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G4103",
    gloss: "faithful",
  },
  πιστοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4103",
    gloss: "faithful",
  },
  πιστοὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4103",
    gloss: "faithful",
  },
  πιστοῖς: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G4103",
    gloss: "faithful",
  },
  πιστόν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G4103",
    gloss: "faithful",
  },
  πιστὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G4103",
    gloss: "faithful",
  },
  πιστός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4103",
    gloss: "faithful",
  },
  πιστὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4103",
    gloss: "faithful",
  },
  Πιστὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4103",
    gloss: "faithful",
  },
  πιστοῦ: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G4103",
    gloss: "faithful",
  },
  πιστοὺς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G4103",
    gloss: "faithful",
  },
  πιστῷ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G4103",
    gloss: "faithful",
  },
  πιστῶν: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G4103",
    gloss: "faithful",
  },
  ἐπιστώθης: {
    parse: "V-API-2S｜Verb, aorist, passive, indicative, second, singular",
    GN: "G4104",
    gloss: "be convinced",
  },
  ἐπλανήθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G4105",
    gloss: "to lead astray",
  },
  ἐπλάνησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4105",
    gloss: "to lead astray",
  },
  πεπλανημένοις: {
    parse:
      "V-RPP-DPN｜Verb, perfect, passive, Participle, dative, plural, neuter",
    GN: "G4105",
    gloss: "to lead astray",
  },
  πεπλάνησθε: {
    parse: "V-RPI-2P｜Verb, perfect, passive, indicative, second, plural",
    GN: "G4105",
    gloss: "to lead astray",
  },
  πλανᾷ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4105",
    gloss: "to lead astray",
  },
  πλανᾶσθε: {
    parse: "V-PPM-2P｜Verb, present, passive, imperative, second, plural",
    GN: "G4105",
    gloss: "to lead astray",
  },
  Πλανᾶσθε: {
    parse: "V-PPI-2P｜Verb, present, passive, indicative, second, plural",
    GN: "G4105",
    gloss: "to lead astray",
  },
  πλανάτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G4105",
    gloss: "to lead astray",
  },
  πλανηθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G4105",
    gloss: "to lead astray",
  },
  πλανηθῆτε: {
    parse: "V-APS-2P｜Verb, aorist, passive, subjunctive, second, plural",
    GN: "G4105",
    gloss: "to lead astray",
  },
  πλανῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G4105",
    gloss: "to lead astray",
  },
  πλανήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G4105",
    gloss: "to lead astray",
  },
  πλανήσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G4105",
    gloss: "to lead astray",
  },
  πλανῶμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G4105",
    gloss: "to lead astray",
  },
  πλανώμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G4105",
    gloss: "to lead astray",
  },
  πλανωμένοις: {
    parse:
      "V-PPP-DPM｜Verb, present, passive, Participle, dative, plural, masculine",
    GN: "G4105",
    gloss: "to lead astray",
  },
  πλανώμενον: {
    parse:
      "V-PPP-ASN｜Verb, present, passive, Participle, accusative, singular, neuter",
    GN: "G4105",
    gloss: "to lead astray",
  },
  πλανῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4105",
    gloss: "to lead astray",
  },
  πλανῶνται: {
    parse: "V-PPI-3P｜Verb, present, passive, indicative, third, plural",
    GN: "G4105",
    gloss: "to lead astray",
  },
  πλανῶντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4105",
    gloss: "to lead astray",
  },
  πλανώντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G4105",
    gloss: "to lead astray",
  },
  πλάνῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4106",
    gloss: "error",
  },
  πλάνη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4106",
    gloss: "error",
  },
  πλάνης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4106",
    gloss: "error",
  },
  πλανῆται: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4107",
    gloss: "wandering",
  },
  πλάνοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4108",
    gloss: "deceiving",
  },
  πλάνοις: {
    parse: "A-DPN｜Adjective, dative, plural, neuter",
    GN: "G4108",
    gloss: "deceiving",
  },
  πλάνος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4108",
    gloss: "deceiving",
  },
  πλάκες: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G4109",
    gloss: "tablet",
  },
  πλαξὶν: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G4109",
    gloss: "tablet",
  },
  πλάσμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4110",
    gloss: "formed thing",
  },
  ἐπλάσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G4111",
    gloss: "to mold",
  },
  πλάσαντι: {
    parse:
      "V-AAP-DSM｜Verb, aorist, active, Participle, dative, singular, masculine",
    GN: "G4111",
    gloss: "to mold",
  },
  πλαστοῖς: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G4112",
    gloss: "made-up",
  },
  πλάτος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4114",
    gloss: "width",
  },
  πεπλάτυνται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G4115",
    gloss: "to widen",
  },
  πλατύνθητε: {
    parse: "V-APM-2P｜Verb, aorist, passive, imperative, second, plural",
    GN: "G4115",
    gloss: "to widen",
  },
  πλατύνουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G4115",
    gloss: "to widen",
  },
  πλατύνουσι: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G4115",
    gloss: "to widen",
  },
  πλατεῖα: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G4116",
    gloss: "wide",
  },
  πλατείαις: {
    parse: "A-DPF｜Adjective, dative, plural, feminine",
    GN: "G4116",
    gloss: "wide",
  },
  πλατείας: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G4116",
    gloss: "wide",
  },
  πλατειῶν: {
    parse: "A-GPF｜Adjective, genitive, plural, feminine",
    GN: "G4116",
    gloss: "wide",
  },
  πλέγμασιν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G4117",
    gloss: "braided",
  },
  πλεῖσται: {
    parse: "A-NPF-S｜Adjective, nominative, plural, feminine, Superlative",
    GN: "G4118",
    gloss: "most",
  },
  πλεῖστον: {
    parse: "A-ASN-S｜Adjective, accusative, singular, neuter, Superlative",
    GN: "G4118",
    gloss: "most",
  },
  πλεῖστος: {
    parse: "A-NSM-S｜Adjective, nominative, singular, masculine, Superlative",
    GN: "G4118",
    gloss: "most",
  },
  πλεῖον: {
    parse: "A-NSN-C｜Adjective, nominative, singular, neuter, Comparative",
    GN: "G4119",
    gloss: "greater",
  },
  πλεῖόν: {
    parse: "A-NSN-C｜Adjective, nominative, singular, neuter, Comparative",
    GN: "G4119",
    gloss: "greater",
  },
  πλείονα: {
    parse: "A-ASM-C｜Adjective, accusative, singular, masculine, Comparative",
    GN: "G4119",
    gloss: "greater",
  },
  πλείονας: {
    parse: "A-APM-C｜Adjective, accusative, plural, masculine, Comparative",
    GN: "G4119",
    gloss: "greater",
  },
  πλείονες: {
    parse: "A-NPM-C｜Adjective, nominative, plural, masculine, Comparative",
    GN: "G4119",
    gloss: "greater",
  },
  πλείονές: {
    parse: "A-NPM-C｜Adjective, nominative, plural, masculine, Comparative",
    GN: "G4119",
    gloss: "greater",
  },
  πλείονος: {
    parse: "A-GSF-C｜Adjective, genitive, singular, feminine, Comparative",
    GN: "G4119",
    gloss: "greater",
  },
  πλειόνων: {
    parse: "A-GPN-C｜Adjective, genitive, plural, neuter, Comparative",
    GN: "G4119",
    gloss: "greater",
  },
  πλείοσιν: {
    parse: "A-DPM-C｜Adjective, dative, plural, masculine, Comparative",
    GN: "G4119",
    gloss: "greater",
  },
  πλέον: {
    parse:
      "V-PAP-ASN｜Verb, present, active, Participle, accusative, singular, neuter",
    GN: "G4126",
    gloss: "to sail",
  },
  πλέξαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G4120",
    gloss: "to weave",
  },
  ἐπλεόνασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4121",
    gloss: "to increase",
  },
  πλεονάζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4121",
    gloss: "to increase",
  },
  πλεονάζοντα: {
    parse:
      "V-PAP-NPN｜Verb, present, active, Participle, nominative, plural, neuter",
    GN: "G4121",
    gloss: "to increase",
  },
  πλεονάσαι: {
    parse: "V-AAO-3S｜Verb, aorist, active, Optative, third, singular",
    GN: "G4121",
    gloss: "to increase",
  },
  πλεονάσασα: {
    parse:
      "V-AAP-NSF｜Verb, aorist, active, Participle, nominative, singular, feminine",
    GN: "G4121",
    gloss: "to increase",
  },
  πλεονάσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G4121",
    gloss: "to increase",
  },
  ἐπλεονέκτησα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G4122",
    gloss: "to exploit",
  },
  ἐπλεονεκτήσαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G4122",
    gloss: "to exploit",
  },
  ἐπλεονέκτησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4122",
    gloss: "to exploit",
  },
  πλεονεκτεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4122",
    gloss: "to exploit",
  },
  πλεονεκτηθῶμεν: {
    parse: "V-APS-1P｜Verb, aorist, passive, subjunctive, first, plural",
    GN: "G4122",
    gloss: "to exploit",
  },
  πλεονέκται: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G4123",
    gloss: "greedy",
  },
  πλεονέκταις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G4123",
    gloss: "greedy",
  },
  πλεονέκτης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4123",
    gloss: "greedy",
  },
  πλεονεξίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4124",
    gloss: "greediness",
  },
  πλεονεξία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4124",
    gloss: "greediness",
  },
  πλεονεξίαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G4124",
    gloss: "greediness",
  },
  πλεονεξίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4124",
    gloss: "greediness",
  },
  πλεονεξίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4124",
    gloss: "greediness",
  },
  πλευράν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4125",
    gloss: "side",
  },
  πλευρὰν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4125",
    gloss: "side",
  },
  ἐπλέομεν: {
    parse: "V-IAI-1P｜Verb, imperfect, active, indicative, first, plural",
    GN: "G4126",
    gloss: "to sail",
  },
  πλεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4126",
    gloss: "to sail",
  },
  πλέοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G4126",
    gloss: "to sail",
  },
  πλεόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G4126",
    gloss: "to sail",
  },
  πλέων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4126",
    gloss: "to sail",
  },
  πληγαὶ: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G4127",
    gloss: "plague/blow/wound",
  },
  πληγαῖς: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G4127",
    gloss: "plague/blow/wound",
  },
  πληγὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G4127",
    gloss: "plague/blow/wound",
  },
  πληγῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4127",
    gloss: "plague/blow/wound",
  },
  πληγὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4127",
    gloss: "plague/blow/wound",
  },
  πληγὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4127",
    gloss: "plague/blow/wound",
  },
  πληγῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4127",
    gloss: "plague/blow/wound",
  },
  πληγῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G4127",
    gloss: "plague/blow/wound",
  },
  πλήθει: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G4128",
    gloss: "multitude",
  },
  πλήθη: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G4128",
    gloss: "multitude",
  },
  πλῆθος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4128",
    gloss: "multitude",
  },
  πλήθους: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4128",
    gloss: "multitude",
  },
  ἐπληθύνετο: {
    parse: "V-IPI-3S｜Verb, imperfect, passive, indicative, third, singular",
    GN: "G4129",
    gloss: "to multiply",
  },
  ἐπληθύνθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G4129",
    gloss: "to multiply",
  },
  πληθυνεῖ: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G4129",
    gloss: "to multiply",
  },
  πληθυνθείη: {
    parse: "V-APO-3S｜Verb, aorist, passive, Optative, third, singular",
    GN: "G4129",
    gloss: "to multiply",
  },
  πληθυνθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G4129",
    gloss: "to multiply",
  },
  πληθυνόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G4129",
    gloss: "to multiply",
  },
  πληθυνῶ: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G4129",
    gloss: "to multiply",
  },
  πληθύνων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4129",
    gloss: "to multiply",
  },
  ἔπλησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4130",
    gloss: "to fill",
  },
  ἐπλήσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G4130",
    gloss: "to fill",
  },
  ἐπλήσθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G4130",
    gloss: "to fill",
  },
  πλήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G4130",
    gloss: "to fill",
  },
  πλησθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G4130",
    gloss: "to fill",
  },
  πλησθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G4130",
    gloss: "to fill",
  },
  πλησθῇς: {
    parse: "V-APS-2S｜Verb, aorist, passive, subjunctive, second, singular",
    GN: "G4130",
    gloss: "to fill",
  },
  πλησθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G4130",
    gloss: "to fill",
  },
  πλήκτην: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4131",
    gloss: "bully",
  },
  πλημμύρης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4132",
    gloss: "flood",
  },
  πλὴν: { parse: "PREP｜Preposition", GN: "G4133", gloss: "but/however" },
  πλήρεις: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4134",
    gloss: "full",
  },
  πλήρη: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G4134",
    gloss: "full",
  },
  πλήρης: {
    parse: "A-VSM｜Adjective, Vocative or nominative, singular, masculine",
    GN: "G4134",
    gloss: "full",
  },
  πεπληροφορημένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G4135",
    gloss: "to fulfill",
  },
  πεπληροφορημένων: {
    parse:
      "V-RPP-GPN｜Verb, perfect, passive, Participle, genitive, plural, neuter",
    GN: "G4135",
    gloss: "to fulfill",
  },
  πληροφορείσθω: {
    parse: "V-PPM-3S｜Verb, present, passive, imperative, third, singular",
    GN: "G4135",
    gloss: "to fulfill",
  },
  πληροφορηθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G4135",
    gloss: "to fulfill",
  },
  πληροφορηθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G4135",
    gloss: "to fulfill",
  },
  πληροφόρησον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G4135",
    gloss: "to fulfill",
  },
  πληροφορίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4136",
    gloss: "assurance",
  },
  πληροφορίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4136",
    gloss: "assurance",
  },
  πληροφορίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4136",
    gloss: "assurance",
  },
  ἐπλήρου: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G4137",
    gloss: "to fulfill",
  },
  ἐπληροῦντο: {
    parse: "V-IPI-3P｜Verb, imperfect, passive, indicative, third, plural",
    GN: "G4137",
    gloss: "to fulfill",
  },
  ἐπληροῦτο: {
    parse: "V-IPI-3S｜Verb, imperfect, passive, indicative, third, singular",
    GN: "G4137",
    gloss: "to fulfill",
  },
  ἐπληρώθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G4137",
    gloss: "to fulfill",
  },
  ἐπλήρωσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4137",
    gloss: "to fulfill",
  },
  ἐπλήρωσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πεπληρώκατε: {
    parse: "V-RAI-2P｜Verb, perfect, active, indicative, second, plural",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πεπλήρωκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πεπληρωκέναι: {
    parse: "V-RAN｜Verb, perfect, active, Infinitive",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πεπλήρωμαι: {
    parse: "V-RPI-1S｜Verb, perfect, passive, indicative, first, singular",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πεπληρωμένα: {
    parse:
      "V-RPP-APN｜Verb, perfect, passive, Participle, accusative, plural, neuter",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πεπληρωμένη: {
    parse:
      "V-RPP-NSF｜Verb, perfect, passive, Participle, nominative, singular, feminine",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πεπληρωμένην: {
    parse:
      "V-RPP-ASF｜Verb, perfect, passive, Participle, accusative, singular, feminine",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πεπληρωμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πεπληρωμένους: {
    parse:
      "V-RPP-APM｜Verb, perfect, passive, Participle, accusative, plural, masculine",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πεπλήρωται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G4137",
    gloss: "to fulfill",
  },
  Πεπλήρωται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πληροῖς: {
    parse: "V-PAS-2S｜Verb, present, active, subjunctive, second, singular",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πληρούμενον: {
    parse:
      "V-PPP-NSN｜Verb, present, passive, Participle, nominative, singular, neuter",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πληρουμένου: {
    parse:
      "V-PMP-GSM｜Verb, present, middle, Participle, genitive, singular, masculine",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πληροῦν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πληροῦσθε: {
    parse: "V-PPM-2P｜Verb, present, passive, imperative, second, plural",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πληρωθείσης: {
    parse:
      "V-APP-GSF｜Verb, aorist, passive, Participle, genitive, singular, feminine",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πληρωθέντων: {
    parse:
      "V-APP-GPN｜Verb, aorist, passive, Participle, genitive, plural, neuter",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πληρωθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πληρωθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πληρωθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πληρωθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πληρωθῆτε: {
    parse: "V-APS-2P｜Verb, aorist, passive, subjunctive, second, plural",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πληρωθῶ: {
    parse: "V-APS-1S｜Verb, aorist, passive, subjunctive, first, singular",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πληρωθῶσιν: {
    parse: "V-APS-3P｜Verb, aorist, passive, subjunctive, third, plural",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πληρῶσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πληρώσαι: {
    parse: "V-AAO-3S｜Verb, aorist, active, Optative, third, singular",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πληρώσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πληρώσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πληρώσατέ: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πληρώσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πληρώσεις: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πληρώσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G4137",
    gloss: "to fulfill",
  },
  πλήρωμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4138",
    gloss: "fulfillment",
  },
  πληρώματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G4138",
    gloss: "fulfillment",
  },
  πληρώματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G4138",
    gloss: "fulfillment",
  },
  πληρώματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4138",
    gloss: "fulfillment",
  },
  πλησίον: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4139",
    gloss: "near/neighbor",
  },
  πλησμονὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4140",
    gloss: "gratification",
  },
  ἐπλήγη: {
    parse: "V-2API-3S｜Verb, 2nd aorist, passive, indicative, third, singular",
    GN: "G4141",
    gloss: "to strike",
  },
  πλοιάρια: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G4142",
    gloss: "small boat",
  },
  πλοιάριον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4142",
    gloss: "small boat",
  },
  πλοιαρίῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G4142",
    gloss: "small boat",
  },
  πλοῖα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G4143",
    gloss: "boat",
  },
  πλοῖον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4143",
    gloss: "boat",
  },
  πλοίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4143",
    gloss: "boat",
  },
  πλοίῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G4143",
    gloss: "boat",
  },
  πλοίων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G4143",
    gloss: "boat",
  },
  πλοὸς: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G4144",
    gloss: "voyage",
  },
  πλοῦν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4144",
    gloss: "voyage",
  },
  πλούσιοι: {
    parse: "A-VPM｜Adjective, Vocative or nominative, plural, masculine",
    GN: "G4145",
    gloss: "rich",
  },
  πλουσίοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G4145",
    gloss: "rich",
  },
  πλούσιον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G4145",
    gloss: "rich",
  },
  πλούσιος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4145",
    gloss: "rich",
  },
  Πλούσιός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4145",
    gloss: "rich",
  },
  πλουσίου: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G4145",
    gloss: "rich",
  },
  πλουσίους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G4145",
    gloss: "rich",
  },
  πλουσίως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4146",
    gloss: "richly",
  },
  ἐπλούτησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4147",
    gloss: "be rich",
  },
  ἐπλουτήσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G4147",
    gloss: "be rich",
  },
  πεπλούτηκα: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G4147",
    gloss: "be rich",
  },
  πλουτεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4147",
    gloss: "be rich",
  },
  πλουτήσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G4147",
    gloss: "be rich",
  },
  πλουτήσῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G4147",
    gloss: "be rich",
  },
  πλουτήσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G4147",
    gloss: "be rich",
  },
  πλουτοῦντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G4147",
    gloss: "be rich",
  },
  πλουτῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4147",
    gloss: "be rich",
  },
  ἐπλουτίσθητε: {
    parse: "V-API-2P｜Verb, aorist, passive, indicative, second, plural",
    GN: "G4148",
    gloss: "to enrich",
  },
  πλουτιζόμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G4148",
    gloss: "to enrich",
  },
  πλουτίζοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4148",
    gloss: "to enrich",
  },
  πλοῦτον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4149",
    gloss: "riches",
  },
  πλοῦτος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4149",
    gloss: "riches",
  },
  πλούτου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G4149",
    gloss: "riches",
  },
  ἔπλυναν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4150",
    gloss: "to wash",
  },
  ἔπλυνον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G4150",
    gloss: "to wash",
  },
  πλύνοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4150",
    gloss: "to wash",
  },
  πνεῦμα: {
    parse: "N-VSN｜Noun, Vocative or nominative, singular, neuter",
    GN: "G4151",
    gloss: "spirit (or breath)",
  },
  Πνεῦμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4151",
    gloss: "spirit (or breath)",
  },
  πνεῦμά: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4151",
    gloss: "spirit (or breath)",
  },
  πνεύμασι: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G4151",
    gloss: "spirit (or breath)",
  },
  πνεύμασιν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G4151",
    gloss: "spirit (or breath)",
  },
  πνεύματα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G4151",
    gloss: "spirit (or breath)",
  },
  πνεύματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G4151",
    gloss: "spirit (or breath)",
  },
  πνεύματί: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G4151",
    gloss: "spirit (or breath)",
  },
  πνεύματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4151",
    gloss: "spirit (or breath)",
  },
  Πνεύματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4151",
    gloss: "spirit (or breath)",
  },
  Πνεύματός: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4151",
    gloss: "spirit (or breath)",
  },
  πνεύματός: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4151",
    gloss: "spirit (or breath)",
  },
  πνευμάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G4151",
    gloss: "spirit (or breath)",
  },
  πνευματικά: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G4152",
    gloss: "spiritual",
  },
  πνευματικὰ: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G4152",
    gloss: "spiritual",
  },
  πνευματικαῖς: {
    parse: "A-DPF｜Adjective, dative, plural, feminine",
    GN: "G4152",
    gloss: "spiritual",
  },
  πνευματικὰς: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G4152",
    gloss: "spiritual",
  },
  πνευματικῇ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G4152",
    gloss: "spiritual",
  },
  πνευματικῆς: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G4152",
    gloss: "spiritual",
  },
  πνευματικοὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4152",
    gloss: "spiritual",
  },
  πνευματικοῖς: {
    parse: "A-DPN｜Adjective, dative, plural, neuter",
    GN: "G4152",
    gloss: "spiritual",
  },
  πνευματικὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G4152",
    gloss: "spiritual",
  },
  πνευματικόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G4152",
    gloss: "spiritual",
  },
  πνευματικός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4152",
    gloss: "spiritual",
  },
  πνευματικὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4152",
    gloss: "spiritual",
  },
  πνευματικῶν: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G4152",
    gloss: "spiritual",
  },
  πνευματικῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4153",
    gloss: "spiritually",
  },
  ἔπνευσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4154",
    gloss: "to blow",
  },
  πνέῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G4154",
    gloss: "to blow",
  },
  πνεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4154",
    gloss: "to blow",
  },
  πνέοντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G4154",
    gloss: "to blow",
  },
  πνέοντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G4154",
    gloss: "to blow",
  },
  πνεούσῃ: {
    parse:
      "V-PAP-DSF｜Verb, present, active, Participle, dative, singular, feminine",
    GN: "G4154",
    gloss: "to blow",
  },
  ἔπνιγεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G4155",
    gloss: "to choke",
  },
  ἐπνίγοντο: {
    parse: "V-IPI-3P｜Verb, imperfect, passive, indicative, third, plural",
    GN: "G4155",
    gloss: "to choke",
  },
  ἔπνιξαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4155",
    gloss: "to choke",
  },
  πνικτὸν: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G4156",
    gloss: "strangled",
  },
  πνικτοῦ: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G4156",
    gloss: "strangled",
  },
  πνικτῶν: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G4156",
    gloss: "strangled",
  },
  πνοὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4157",
    gloss: "wind/breath",
  },
  πνοῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4157",
    gloss: "wind/breath",
  },
  ποδήρη: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G4158",
    gloss: "floorlength",
  },
  πόθεν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4159",
    gloss: "whence",
  },
  Πόθεν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4159",
    gloss: "whence",
  },
  ἐποίει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G4160",
    gloss: "to make/do",
  },
  ἐποιεῖτε: {
    parse: "V-IAI-2P｜Verb, imperfect, active, indicative, second, plural",
    GN: "G4160",
    gloss: "to make/do",
  },
  ἐποίησα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G4160",
    gloss: "to make/do",
  },
  ἐποιήσαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G4160",
    gloss: "to make/do",
  },
  ἐποιησάμην: {
    parse: "V-AMI-1S｜Verb, aorist, middle, indicative, first, singular",
    GN: "G4160",
    gloss: "to make/do",
  },
  ἐποίησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4160",
    gloss: "to make/do",
  },
  ἐποίησας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G4160",
    gloss: "to make/do",
  },
  ἐποιήσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G4160",
    gloss: "to make/do",
  },
  ἐποίησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4160",
    gloss: "to make/do",
  },
  Ἐποίησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4160",
    gloss: "to make/do",
  },
  ἐποίησέν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4160",
    gloss: "to make/do",
  },
  ἐποίουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G4160",
    gloss: "to make/do",
  },
  ἐποιοῦντο: {
    parse: "V-IMI-3P｜Verb, imperfect, middle, indicative, third, plural",
    GN: "G4160",
    gloss: "to make/do",
  },
  πεποίηκα: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G4160",
    gloss: "to make/do",
  },
  πεποιήκαμεν: {
    parse: "V-RAI-1P｜Verb, perfect, active, indicative, first, plural",
    GN: "G4160",
    gloss: "to make/do",
  },
  πεποιήκατε: {
    parse: "V-RAI-2P｜Verb, perfect, active, indicative, second, plural",
    GN: "G4160",
    gloss: "to make/do",
  },
  πεποιήκεισαν: {
    parse: "V-LAI-3P｜Verb, pluperfect, active, indicative, third, plural",
    GN: "G4160",
    gloss: "to make/do",
  },
  πεποίηκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G4160",
    gloss: "to make/do",
  },
  πεποιηκέναι: {
    parse: "V-RAN｜Verb, perfect, active, Infinitive",
    GN: "G4160",
    gloss: "to make/do",
  },
  πεποιηκόσιν: {
    parse:
      "V-RAP-DPM｜Verb, perfect, active, Participle, dative, plural, masculine",
    GN: "G4160",
    gloss: "to make/do",
  },
  πεποιηκότες: {
    parse:
      "V-RAP-NPM｜Verb, perfect, active, Participle, nominative, plural, masculine",
    GN: "G4160",
    gloss: "to make/do",
  },
  πεποιηκότος: {
    parse:
      "V-RAP-GSM｜Verb, perfect, active, Participle, genitive, singular, masculine",
    GN: "G4160",
    gloss: "to make/do",
  },
  πεποιηκώς: {
    parse:
      "V-RAP-NSM｜Verb, perfect, active, Participle, nominative, singular, masculine",
    GN: "G4160",
    gloss: "to make/do",
  },
  πεποιημένων: {
    parse:
      "V-RPP-GPN｜Verb, perfect, passive, Participle, genitive, plural, neuter",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποίει: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιεῖς: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιεῖσθαι: {
    parse: "V-PEN｜Verb, present, Either middle or passive, Infinitive",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιεῖσθε: {
    parse: "V-PMM-2P｜Verb, present, middle, imperative, second, plural",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιεῖται: {
    parse: "V-PMI-3S｜Verb, present, middle, indicative, third, singular",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιείτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιῇ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιῇς: {
    parse: "V-PAS-2S｜Verb, present, active, subjunctive, second, singular",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιήσαιεν: {
    parse: "V-AAO-3P｜Verb, aorist, active, Optative, third, plural",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιησάμενοι: {
    parse:
      "V-AMP-NPM｜Verb, aorist, middle, Participle, nominative, plural, masculine",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιησάμενος: {
    parse:
      "V-AMP-NSM｜Verb, aorist, middle, Participle, nominative, singular, masculine",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιήσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιήσαντι: {
    parse:
      "V-AAP-DSM｜Verb, aorist, active, Participle, dative, singular, masculine",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιήσασαν: {
    parse:
      "V-AAP-ASF｜Verb, aorist, active, Participle, accusative, singular, feminine",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιήσασθαι: {
    parse: "V-AMN｜Verb, aorist, middle, Infinitive",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιήσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G4160",
    gloss: "to make/do",
  },
  Ποιήσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιησάτω: {
    parse: "V-AAM-3S｜Verb, aorist, active, imperative, third, singular",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιήσεις: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιήσετε: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιήσῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιήσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιησόμεθα: {
    parse: "V-FDI-1P｜Verb, future, Middle deponent, indicative, first, plural",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιήσομεν: {
    parse: "V-FAI-1P｜Verb, future, active, indicative, first, plural",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποίησον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G4160",
    gloss: "to make/do",
  },
  Ποίησον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποίησόν: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιήσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιήσωμεν: {
    parse: "V-AAS-1P｜Verb, aorist, active, subjunctive, first, plural",
    GN: "G4160",
    gloss: "to make/do",
  },
  Ποιήσωμεν: {
    parse: "V-AAS-1P｜Verb, aorist, active, subjunctive, first, plural",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιήσων: {
    parse:
      "V-FAP-NSM｜Verb, future, active, Participle, nominative, singular, masculine",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιήσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιῆτε: {
    parse: "V-PAS-2P｜Verb, present, active, subjunctive, second, plural",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιοῦμαι: {
    parse: "V-PMI-1S｜Verb, present, middle, indicative, first, singular",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιοῦμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιούμενοι: {
    parse:
      "V-PMP-NPM｜Verb, present, middle, Participle, nominative, plural, masculine",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιούμενος: {
    parse:
      "V-PMP-NSM｜Verb, present, middle, Participle, nominative, singular, masculine",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιοῦν: {
    parse:
      "V-PAP-NSN｜Verb, present, active, Participle, nominative, singular, neuter",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιοῦντα: {
    parse:
      "V-PAP-NPN｜Verb, present, active, Participle, nominative, plural, neuter",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιοῦνται: {
    parse: "V-PMI-3P｜Verb, present, middle, indicative, third, plural",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιοῦντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιοῦντι: {
    parse:
      "V-PAP-DSN｜Verb, present, active, Participle, dative, singular, neuter",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιοῦντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιοῦσιν: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιῶ: {
    parse: "V-PAS-1S｜Verb, present, active, subjunctive, first, singular",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιῶμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιῶν: {
    parse:
      "V-PAP-VSM｜Verb, present, active, Participle, Vocative or nominative, singular, masculine",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποιῶσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G4160",
    gloss: "to make/do",
  },
  ποίημα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4161",
    gloss: "workmanship",
  },
  ποιήμασιν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G4161",
    gloss: "workmanship",
  },
  ποιηταὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G4163",
    gloss: "doer",
  },
  ποιητής: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4163",
    gloss: "doer",
  },
  ποιητὴς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4163",
    gloss: "doer",
  },
  ποιητῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G4163",
    gloss: "doer",
  },
  ποικίλαις: {
    parse: "A-DPF｜Adjective, dative, plural, feminine",
    GN: "G4164",
    gloss: "various",
  },
  ποικίλης: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G4164",
    gloss: "various",
  },
  ποικίλοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G4164",
    gloss: "various",
  },
  Ποίμαινε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G4165",
    gloss: "to shepherd",
  },
  ποιμαίνει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4165",
    gloss: "to shepherd",
  },
  ποιμαίνειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4165",
    gloss: "to shepherd",
  },
  ποιμαίνοντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G4165",
    gloss: "to shepherd",
  },
  ποιμαίνοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4165",
    gloss: "to shepherd",
  },
  ποιμάνατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G4165",
    gloss: "to shepherd",
  },
  ποιμανεῖ: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G4165",
    gloss: "to shepherd",
  },
  ποιμένα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4166",
    gloss: "shepherd",
  },
  ποιμένας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G4166",
    gloss: "shepherd",
  },
  ποιμένες: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G4166",
    gloss: "shepherd",
  },
  ποιμένων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G4166",
    gloss: "shepherd",
  },
  ποιμήν: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4166",
    gloss: "shepherd",
  },
  ποιμὴν: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4166",
    gloss: "shepherd",
  },
  ποίμνη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4167",
    gloss: "flock",
  },
  ποίμνην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4167",
    gloss: "flock",
  },
  ποίμνης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4167",
    gloss: "flock",
  },
  ποίμνιον: {
    parse: "N-VSN｜Noun, Vocative or nominative, singular, neuter",
    GN: "G4168",
    gloss: "flock",
  },
  ποιμνίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4168",
    gloss: "flock",
  },
  ποιμνίῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G4168",
    gloss: "flock",
  },
  Ποῖα: {
    parse: "I-APN｜Interrogative pronoun, accusative, plural, neuter",
    GN: "G4169",
    gloss: "what?",
  },
  ποίᾳ: {
    parse: "I-DSF｜Interrogative pronoun, dative, singular, feminine",
    GN: "G4169",
    gloss: "what?",
  },
  ποία: {
    parse: "I-NSF｜Interrogative pronoun, nominative, singular, feminine",
    GN: "G4169",
    gloss: "what?",
  },
  Ποία: {
    parse: "I-NSF｜Interrogative pronoun, nominative, singular, feminine",
    GN: "G4169",
    gloss: "what?",
  },
  ποίαν: {
    parse: "I-ASF｜Interrogative pronoun, accusative, singular, feminine",
    GN: "G4169",
    gloss: "what?",
  },
  Ποίας: {
    parse: "I-APF｜Interrogative pronoun, accusative, plural, feminine",
    GN: "G4169",
    gloss: "what?",
  },
  ποίας: {
    parse: "I-GSF｜Interrogative pronoun, genitive, singular, feminine",
    GN: "G4169",
    gloss: "what?",
  },
  ποῖον: {
    parse: "I-NSN｜Interrogative pronoun, nominative, singular, neuter",
    GN: "G4169",
    gloss: "what?",
  },
  ποίου: {
    parse: "I-GSM｜Interrogative pronoun, genitive, singular, masculine",
    GN: "G4169",
    gloss: "what?",
  },
  ποίῳ: {
    parse: "I-DSN｜Interrogative pronoun, dative, singular, neuter",
    GN: "G4169",
    gloss: "what?",
  },
  ἐπολέμησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4170",
    gloss: "to fight",
  },
  πολεμεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4170",
    gloss: "to fight",
  },
  πολεμεῖτε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G4170",
    gloss: "to fight",
  },
  πολεμῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G4170",
    gloss: "to fight",
  },
  πολεμήσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G4170",
    gloss: "to fight",
  },
  πολεμήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G4170",
    gloss: "to fight",
  },
  πόλεμοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G4171",
    gloss: "war",
  },
  πόλεμον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4171",
    gloss: "war",
  },
  πόλεμος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4171",
    gloss: "war",
  },
  πολέμους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G4171",
    gloss: "war",
  },
  πολέμῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G4171",
    gloss: "war",
  },
  πολέμων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G4171",
    gloss: "war",
  },
  πόλει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4172",
    gloss: "city",
  },
  πόλεις: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G4172",
    gloss: "city",
  },
  πόλεσιν: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G4172",
    gloss: "city",
  },
  πόλεων: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G4172",
    gloss: "city",
  },
  πόλεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4172",
    gloss: "city",
  },
  πόλιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4172",
    gloss: "city",
  },
  Πόλιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4172",
    gloss: "city",
  },
  πόλις: {
    parse: "N-VSF｜Noun, Vocative or nominative, singular, feminine",
    GN: "G4172",
    gloss: "city",
  },
  πολιτάρχας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G4173",
    gloss: "city authority",
  },
  πολιτείαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4174",
    gloss: "citizenship",
  },
  πολιτείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4174",
    gloss: "citizenship",
  },
  πολίτευμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4175",
    gloss: "citizenship",
  },
  πεπολίτευμαι: {
    parse: "V-RMI-1S｜Verb, perfect, middle, indicative, first, singular",
    GN: "G4176",
    gloss: "to conduct",
  },
  πολιτεύεσθε: {
    parse:
      "V-PNM-2P｜Verb, present, Middle or Passive deponent, imperative, second, plural",
    GN: "G4176",
    gloss: "to conduct",
  },
  πολῖται: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G4177",
    gloss: "citizen",
  },
  πολίτην: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4177",
    gloss: "citizen",
  },
  πολίτης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4177",
    gloss: "citizen",
  },
  πολιτῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G4177",
    gloss: "citizen",
  },
  πολλάκις: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4178",
    gloss: "often",
  },
  πολλαπλασίονα: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G4179",
    gloss: "many times more",
  },
  πολυλογίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4180",
    gloss: "wordiness",
  },
  Πολυμερῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4181",
    gloss: "little by little",
  },
  πολυποίκιλος: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G4182",
    gloss: "manifold",
  },
  πλείους: {
    parse: "A-NPM-C｜Adjective, nominative, plural, masculine, Comparative",
    GN: "G4183",
    gloss: "much",
  },
  πλείω: {
    parse: "A-APN-C｜Adjective, accusative, plural, neuter, Comparative",
    GN: "G4183",
    gloss: "much",
  },
  πολλά: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G4183",
    gloss: "much",
  },
  πολλὰ: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G4183",
    gloss: "much",
  },
  Πολλὰ: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G4183",
    gloss: "much",
  },
  πολλαί: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G4183",
    gloss: "much",
  },
  πολλαὶ: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G4183",
    gloss: "much",
  },
  πολλαῖς: {
    parse: "A-DPF｜Adjective, dative, plural, feminine",
    GN: "G4183",
    gloss: "much",
  },
  πολλάς: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G4183",
    gloss: "much",
  },
  πολλὰς: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G4183",
    gloss: "much",
  },
  πολλῇ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G4183",
    gloss: "much",
  },
  πολλή: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G4183",
    gloss: "much",
  },
  πολλὴ: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G4183",
    gloss: "much",
  },
  πολλήν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G4183",
    gloss: "much",
  },
  πολλὴν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G4183",
    gloss: "much",
  },
  πολλῆς: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G4183",
    gloss: "much",
  },
  Πολλῆς: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G4183",
    gloss: "much",
  },
  πολλοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4183",
    gloss: "much",
  },
  πολλοὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4183",
    gloss: "much",
  },
  Πολλοὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4183",
    gloss: "much",
  },
  πολλοῖς: {
    parse: "A-DPN｜Adjective, dative, plural, neuter",
    GN: "G4183",
    gloss: "much",
  },
  πολλοῦ: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G4183",
    gloss: "much",
  },
  πολλούς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G4183",
    gloss: "much",
  },
  πολλοὺς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G4183",
    gloss: "much",
  },
  πολλῷ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G4183",
    gloss: "much",
  },
  πολλῶν: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G4183",
    gloss: "much",
  },
  πολύ: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G4183",
    gloss: "much",
  },
  πολὺ: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G4183",
    gloss: "much",
  },
  πολύν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G4183",
    gloss: "much",
  },
  πολὺν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G4183",
    gloss: "much",
  },
  πολύς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4183",
    gloss: "much",
  },
  πολὺς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4183",
    gloss: "much",
  },
  πολύσπλαγχνός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4184",
    gloss: "very compassionate",
  },
  πολυτελεῖ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G4185",
    gloss: "valuable",
  },
  πολυτελές: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G4185",
    gloss: "valuable",
  },
  πολυτελοῦς: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G4185",
    gloss: "valuable",
  },
  πολύτιμον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G4186",
    gloss: "valuable",
  },
  πολυτιμότερον: {
    parse: "A-NSN-C｜Adjective, nominative, singular, neuter, Comparative",
    GN: "G4186",
    gloss: "valuable",
  },
  πολυτίμου: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G4186",
    gloss: "valuable",
  },
  πολυτρόπως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4187",
    gloss: "in many ways",
  },
  πόμα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G4188",
    gloss: "drink",
  },
  πόμασιν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G4188",
    gloss: "drink",
  },
  πονηρίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4189",
    gloss: "evil",
  },
  πονηρίαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G4189",
    gloss: "evil",
  },
  πονηρίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4189",
    gloss: "evil",
  },
  πονηρίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4189",
    gloss: "evil",
  },
  πονηριῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G4189",
    gloss: "evil",
  },
  πονηρά: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G4190",
    gloss: "evil/bad",
  },
  πονηρὰ: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G4190",
    gloss: "evil/bad",
  },
  πονηρᾷ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G4190",
    gloss: "evil/bad",
  },
  πονηραί: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G4190",
    gloss: "evil/bad",
  },
  πονηρᾶς: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G4190",
    gloss: "evil/bad",
  },
  πονηρέ: {
    // or nominative
    parse: "A-VSM｜Adjective, Vocative, singular, masculine",
    GN: "G4190",
    gloss: "evil/bad",
  },
  πονηρὲ: {
    // or nominative
    parse: "A-VSM｜Adjective, Vocative, singular, masculine",
    GN: "G4190",
    gloss: "evil/bad",
  },
  Πονηρὲ: {
    // or Nominative
    parse: "A-VSM｜Adjective, Vocative, singular, masculine",
    GN: "G4190",
    gloss: "evil/bad",
  },
  πονηροί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4190",
    gloss: "evil/bad",
  },
  πονηροὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4190",
    gloss: "evil/bad",
  },
  πονηροῖς: {
    parse: "A-DPN｜Adjective, dative, plural, neuter",
    GN: "G4190",
    gloss: "evil/bad",
  },
  πονηρόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G4190",
    gloss: "evil/bad",
  },
  πονηρὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G4190",
    gloss: "evil/bad",
  },
  πονηρός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4190",
    gloss: "evil/bad",
  },
  πονηρὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4190",
    gloss: "evil/bad",
  },
  πονηρότερα: {
    parse: "A-APN-C｜Adjective, accusative, plural, neuter, Comparative",
    GN: "G4190",
    gloss: "evil/bad",
  },
  πονηροῦ: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G4190",
    gloss: "evil/bad",
  },
  πονηρούς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G4190",
    gloss: "evil/bad",
  },
  πονηροὺς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G4190",
    gloss: "evil/bad",
  },
  πονηρῷ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G4190",
    gloss: "evil/bad",
  },
  πονηρῶν: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G4190",
    gloss: "evil/bad",
  },
  πόνον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4192",
    gloss: "travail",
  },
  πόνος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4192",
    gloss: "travail",
  },
  πόνου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G4192",
    gloss: "travail",
  },
  πόνων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G4192",
    gloss: "travail",
  },
  Ποντικὸν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G4193",
    gloss: "Pontus",
  },
  Πόντιος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G4194",
    gloss: "Pontius",
  },
  Ποντίου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G4194",
    gloss: "Pontius",
  },
  Πόντον: {
    parse: "N-ASM-L｜Noun, accusative, singular, masculine, Location",
    GN: "G4195",
    gloss: "Pontus",
  },
  Πόντου: {
    parse: "N-GSM-L｜Noun, genitive, singular, masculine, Location",
    GN: "G4195",
    gloss: "Pontus",
  },
  Ποπλίου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G4196",
    gloss: "Publius",
  },
  Ποπλίῳ: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G4196",
    gloss: "Publius",
  },
  πορείαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G4197",
    gloss: "journey",
  },
  πορείαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4197",
    gloss: "journey",
  },
  ἐπορεύετο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G4198",
    gloss: "to go/travel",
  },
  ἐπορεύθη: {
    parse:
      "V-AOI-3S｜Verb, aorist, Passive deponent, indicative, third, singular",
    GN: "G4198",
    gloss: "to go/travel",
  },
  ἐπορεύθησαν: {
    parse:
      "V-AOI-3P｜Verb, aorist, Passive deponent, indicative, third, plural",
    GN: "G4198",
    gloss: "to go/travel",
  },
  ἐπορευόμεθα: {
    parse:
      "V-INI-1P｜Verb, imperfect, Middle or Passive deponent, indicative, first, plural",
    GN: "G4198",
    gloss: "to go/travel",
  },
  ἐπορευόμην: {
    parse:
      "V-INI-1S｜Verb, imperfect, Middle or Passive deponent, indicative, first, singular",
    GN: "G4198",
    gloss: "to go/travel",
  },
  ἐπορεύοντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G4198",
    gloss: "to go/travel",
  },
  πεπορευμένους: {
    parse:
      "V-RNP-APM｜Verb, perfect, Middle or Passive deponent, Participle, accusative, plural, masculine",
    GN: "G4198",
    gloss: "to go/travel",
  },
  πορεύεσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G4198",
    gloss: "to go/travel",
  },
  πορεύεσθε: {
    parse:
      "V-PNM-2P｜Verb, present, Middle or Passive deponent, imperative, second, plural",
    GN: "G4198",
    gloss: "to go/travel",
  },
  Πορεύεσθε: {
    parse:
      "V-PNM-2P｜Verb, present, Middle or Passive deponent, imperative, second, plural",
    GN: "G4198",
    gloss: "to go/travel",
  },
  πορεύεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G4198",
    gloss: "to go/travel",
  },
  πορευθεὶς: {
    parse:
      "V-AOP-NSM｜Verb, aorist, Passive deponent, Participle, nominative, singular, masculine",
    GN: "G4198",
    gloss: "to go/travel",
  },
  πορευθεῖσα: {
    parse:
      "V-AOP-NSF｜Verb, aorist, Passive deponent, Participle, nominative, singular, feminine",
    GN: "G4198",
    gloss: "to go/travel",
  },
  πορευθεῖσαι: {
    parse:
      "V-AOP-NPF｜Verb, aorist, Passive deponent, Participle, nominative, plural, feminine",
    GN: "G4198",
    gloss: "to go/travel",
  },
  πορευθέντες: {
    parse:
      "V-AOP-NPM｜Verb, aorist, Passive deponent, Participle, nominative, plural, masculine",
    GN: "G4198",
    gloss: "to go/travel",
  },
  Πορευθέντες: {
    parse:
      "V-AOP-NPM｜Verb, aorist, Passive deponent, Participle, nominative, plural, masculine",
    GN: "G4198",
    gloss: "to go/travel",
  },
  πορευθέντι: {
    parse:
      "V-AOP-DSM｜Verb, aorist, Passive deponent, Participle, dative, singular, masculine",
    GN: "G4198",
    gloss: "to go/travel",
  },
  πορευθῇ: {
    parse:
      "V-AOS-3S｜Verb, aorist, Passive deponent, subjunctive, third, singular",
    GN: "G4198",
    gloss: "to go/travel",
  },
  πορευθῆναι: {
    parse: "V-AON｜Verb, aorist, Passive deponent, Infinitive",
    GN: "G4198",
    gloss: "to go/travel",
  },
  πορευθῆτε: {
    parse:
      "V-AOS-2P｜Verb, aorist, Passive deponent, subjunctive, second, plural",
    GN: "G4198",
    gloss: "to go/travel",
  },
  πορεύθητι: {
    parse:
      "V-AOM-2S｜Verb, aorist, Passive deponent, imperative, second, singular",
    GN: "G4198",
    gloss: "to go/travel",
  },
  Πορεύθητι: {
    parse:
      "V-AOM-2S｜Verb, aorist, Passive deponent, imperative, second, singular",
    GN: "G4198",
    gloss: "to go/travel",
  },
  πορευθῶ: {
    parse:
      "V-AOS-1S｜Verb, aorist, Passive deponent, subjunctive, first, singular",
    GN: "G4198",
    gloss: "to go/travel",
  },
  πορευθῶσιν: {
    parse:
      "V-AOS-3P｜Verb, aorist, Passive deponent, subjunctive, third, plural",
    GN: "G4198",
    gloss: "to go/travel",
  },
  πορεύομαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G4198",
    gloss: "to go/travel",
  },
  πορευομένη: {
    parse:
      "V-PNP-NSF｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, feminine",
    GN: "G4198",
    gloss: "to go/travel",
  },
  πορευόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G4198",
    gloss: "to go/travel",
  },
  πορευομένοις: {
    parse:
      "V-PNP-DPM｜Verb, present, Middle or Passive deponent, Participle, dative, plural, masculine",
    GN: "G4198",
    gloss: "to go/travel",
  },
  πορευόμενον: {
    parse:
      "V-PNP-NSN｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, neuter",
    GN: "G4198",
    gloss: "to go/travel",
  },
  πορευόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G4198",
    gloss: "to go/travel",
  },
  πορευομένου: {
    parse:
      "V-PNP-GSM｜Verb, present, Middle or Passive deponent, Participle, genitive, singular, masculine",
    GN: "G4198",
    gloss: "to go/travel",
  },
  πορευομένους: {
    parse:
      "V-PNP-APM｜Verb, present, Middle or Passive deponent, Participle, accusative, plural, masculine",
    GN: "G4198",
    gloss: "to go/travel",
  },
  πορευομένῳ: {
    parse:
      "V-PNP-DSM｜Verb, present, Middle or Passive deponent, Participle, dative, singular, masculine",
    GN: "G4198",
    gloss: "to go/travel",
  },
  Πορευομένων: {
    parse:
      "V-PNP-GPF｜Verb, present, Middle or Passive deponent, Participle, genitive, plural, feminine",
    GN: "G4198",
    gloss: "to go/travel",
  },
  πορευομένων: {
    parse:
      "V-PNP-GPM｜Verb, present, Middle or Passive deponent, Participle, genitive, plural, masculine",
    GN: "G4198",
    gloss: "to go/travel",
  },
  πορεύου: {
    parse:
      "V-PNM-2S｜Verb, present, Middle or Passive deponent, imperative, second, singular",
    GN: "G4198",
    gloss: "to go/travel",
  },
  Πορεύου: {
    parse:
      "V-PNM-2S｜Verb, present, Middle or Passive deponent, imperative, second, singular",
    GN: "G4198",
    gloss: "to go/travel",
  },
  πορεύσεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G4198",
    gloss: "to go/travel",
  },
  πορεύσῃ: {
    parse:
      "V-FDI-2S｜Verb, future, Middle deponent, indicative, second, singular",
    GN: "G4198",
    gloss: "to go/travel",
  },
  πορεύσομαι: {
    parse:
      "V-FDI-1S｜Verb, future, Middle deponent, indicative, first, singular",
    GN: "G4198",
    gloss: "to go/travel",
  },
  πορευσόμεθα: {
    parse: "V-FDI-1P｜Verb, future, Middle deponent, indicative, first, plural",
    GN: "G4198",
    gloss: "to go/travel",
  },
  πορεύσονται: {
    parse: "V-FDI-3P｜Verb, future, Middle deponent, indicative, third, plural",
    GN: "G4198",
    gloss: "to go/travel",
  },
  πορεύωμαι: {
    parse:
      "V-PNS-1S｜Verb, present, Middle or Passive deponent, subjunctive, first, singular",
    GN: "G4198",
    gloss: "to go/travel",
  },
  ἐπόρθει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G4199",
    gloss: "to lay waste",
  },
  ἐπόρθουν: {
    parse: "V-IAI-1S｜Verb, imperfect, active, indicative, first, singular",
    GN: "G4199",
    gloss: "to lay waste",
  },
  πορθήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G4199",
    gloss: "to lay waste",
  },
  πορισμὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4200",
    gloss: "gain",
  },
  πορισμὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4200",
    gloss: "gain",
  },
  Πόρκιον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G4201",
    gloss: "Porcius",
  },
  πορνείᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4202",
    gloss: "sexual sin",
  },
  πορνεία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4202",
    gloss: "sexual sin",
  },
  Πορνεία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4202",
    gloss: "sexual sin",
  },
  πορνεῖαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G4202",
    gloss: "sexual sin",
  },
  πορνείαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4202",
    gloss: "sexual sin",
  },
  πορνείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4202",
    gloss: "sexual sin",
  },
  ἐπόρνευσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4203",
    gloss: "to sin sexually",
  },
  πορνεῦσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G4203",
    gloss: "to sin sexually",
  },
  πορνεύσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G4203",
    gloss: "to sin sexually",
  },
  πορνεύωμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G4203",
    gloss: "to sin sexually",
  },
  πορνεύων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4203",
    gloss: "to sin sexually",
  },
  πόρναι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G4204",
    gloss: "prostitute",
  },
  πόρνῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4204",
    gloss: "prostitute",
  },
  πόρνη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4204",
    gloss: "prostitute",
  },
  πόρνην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4204",
    gloss: "prostitute",
  },
  πόρνης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4204",
    gloss: "prostitute",
  },
  πορνῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G4204",
    gloss: "prostitute",
  },
  πόρνοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G4205",
    gloss: "sexual sinner",
  },
  πόρνοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G4205",
    gloss: "sexual sinner",
  },
  πόρνος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4205",
    gloss: "sexual sinner",
  },
  πόρνους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G4205",
    gloss: "sexual sinner",
  },
  πόρρω: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4206",
    gloss: "far (away)",
  },
  πορρώτερον: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4206",
    gloss: "far (away)",
  },
  πόρρωθεν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4207",
    gloss: "afar off",
  },
  πορφύραν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4209",
    gloss: "purple cloth",
  },
  πορφύρας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4209",
    gloss: "purple cloth",
  },
  πορφυροῦν: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G4210",
    gloss: "purple",
  },
  πορφυρόπωλις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4211",
    gloss: "dealer in purple",
  },
  ποσάκις: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4212",
    gloss: "how often!",
  },
  πόσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4213",
    gloss: "drink",
  },
  πόσις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4213",
    gloss: "drink",
  },
  πόσα: {
    parse:
      "Q-APN｜Correlative or interrogative pronoun, accusative, plural, neuter",
    GN: "G4214",
    gloss: "how much/many",
  },
  πόσαι: {
    parse:
      "Q-NPF｜Correlative or interrogative pronoun, nominative, plural, feminine",
    GN: "G4214",
    gloss: "how much/many",
  },
  πόσας: {
    parse:
      "Q-APF｜Correlative or interrogative pronoun, accusative, plural, feminine",
    GN: "G4214",
    gloss: "how much/many",
  },
  πόσην: {
    parse:
      "Q-ASF｜Correlative or interrogative pronoun, accusative, singular, feminine",
    GN: "G4214",
    gloss: "how much/many",
  },
  Πόσοι: {
    parse:
      "Q-NPM｜Correlative or interrogative pronoun, nominative, plural, masculine",
    GN: "G4214",
    gloss: "how much/many",
  },
  πόσον: {
    parse:
      "Q-NSN｜Correlative or interrogative pronoun, nominative, singular, neuter",
    GN: "G4214",
    gloss: "how much/many",
  },
  Πόσον: {
    parse:
      "Q-ASN｜Correlative or interrogative pronoun, accusative, singular, neuter",
    GN: "G4214",
    gloss: "how much/many",
  },
  Πόσος: {
    parse:
      "Q-NSM｜Correlative or interrogative pronoun, nominative, singular, masculine",
    GN: "G4214",
    gloss: "how much/many",
  },
  πόσους: {
    parse:
      "Q-APM｜Correlative or interrogative pronoun, accusative, plural, masculine",
    GN: "G4214",
    gloss: "how much/many",
  },
  Πόσους: {
    parse:
      "Q-APM｜Correlative or interrogative pronoun, accusative, plural, masculine",
    GN: "G4214",
    gloss: "how much/many",
  },
  πόσῳ: {
    parse:
      "Q-DSN｜Correlative or interrogative pronoun, dative, singular, neuter",
    GN: "G4214",
    gloss: "how much/many",
  },
  πόσων: {
    parse:
      "Q-GPF｜Correlative or interrogative pronoun, genitive, plural, feminine",
    GN: "G4214",
    gloss: "how much/many",
  },
  ποταμοὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G4215",
    gloss: "river",
  },
  ποταμόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4215",
    gloss: "river",
  },
  ποταμὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4215",
    gloss: "river",
  },
  ποταμός: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4215",
    gloss: "river",
  },
  ποταμὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4215",
    gloss: "river",
  },
  ποταμοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G4215",
    gloss: "river",
  },
  ποταμοὺς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G4215",
    gloss: "river",
  },
  ποταμῷ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G4215",
    gloss: "river",
  },
  ποταμῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G4215",
    gloss: "river",
  },
  ποταμοφόρητον: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G4216",
    gloss: "flooded",
  },
  ποταπαὶ: {
    parse: "I-NPF｜Interrogative pronoun, nominative, plural, feminine",
    GN: "G4217",
    gloss: "of what kind?",
  },
  ποταπὴ: {
    parse: "I-NSF｜Interrogative pronoun, nominative, singular, feminine",
    GN: "G4217",
    gloss: "of what kind?",
  },
  ποταπὴν: {
    parse: "I-ASF｜Interrogative pronoun, accusative, singular, feminine",
    GN: "G4217",
    gloss: "of what kind?",
  },
  ποταποὶ: {
    parse: "I-NPM｜Interrogative pronoun, nominative, plural, masculine",
    GN: "G4217",
    gloss: "of what kind?",
  },
  Ποταπός: {
    parse: "I-NSM｜Interrogative pronoun, nominative, singular, masculine",
    GN: "G4217",
    gloss: "of what kind?",
  },
  ποταπὸς: {
    parse: "I-NSM｜Interrogative pronoun, nominative, singular, masculine",
    GN: "G4217",
    gloss: "of what kind?",
  },
  ποταποὺς: {
    parse: "I-APM｜Interrogative pronoun, accusative, plural, masculine",
    GN: "G4217",
    gloss: "of what kind?",
  },
  ποτε: {
    parse: "PRT｜Particle, disjunctive particle",
    GN: "G4218",
    gloss: "once/when",
  },
  ποτέ: {
    parse: "PRT｜Particle, disjunctive particle",
    GN: "G4218",
    gloss: "once/when",
  },
  ποτὲ: {
    parse: "PRT｜Particle, disjunctive particle",
    GN: "G4218",
    gloss: "once/when",
  },
  πότε: {
    parse: "PRT-I｜Particle, Interrogative",
    GN: "G4219",
    gloss: "when?",
  },
  πότερον: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4220",
    gloss: "whether",
  },
  ποτήριον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4221",
    gloss: "cup",
  },
  ποτήριόν: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G4221",
    gloss: "cup",
  },
  ποτηρίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4221",
    gloss: "cup",
  },
  ποτηρίῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G4221",
    gloss: "cup",
  },
  ποτηρίων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G4221",
    gloss: "cup",
  },
  ἐπότιζεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G4222",
    gloss: "to water",
  },
  ἐπότισα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G4222",
    gloss: "to water",
  },
  ἐποτίσαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G4222",
    gloss: "to water",
  },
  ἐποτίσατέ: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G4222",
    gloss: "to water",
  },
  ἐπότισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4222",
    gloss: "to water",
  },
  ἐποτίσθημεν: {
    parse: "V-API-1P｜Verb, aorist, passive, indicative, first, plural",
    GN: "G4222",
    gloss: "to water",
  },
  πεπότικεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G4222",
    gloss: "to water",
  },
  πότιζε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G4222",
    gloss: "to water",
  },
  ποτίζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4222",
    gloss: "to water",
  },
  ποτίζων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4222",
    gloss: "to water",
  },
  ποτίσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G4222",
    gloss: "to water",
  },
  Ποτιόλους: {
    parse: "N-APM-L｜Noun, accusative, plural, masculine, Location",
    GN: "G4223",
    gloss: "Puteoli",
  },
  πότοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G4224",
    gloss: "carousing",
  },
  που: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4225",
    gloss: "somewhere",
  },
  πού: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4225",
    gloss: "somewhere",
  },
  ποῦ: { parse: "ADV-I｜Adverb, Interrogative", GN: "G4226", gloss: "where?" },
  Ποῦ: { parse: "ADV-I｜Adverb, Interrogative", GN: "G4226", gloss: "where?" },
  Πούδης: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G4227",
    gloss: "Pudens",
  },
  πόδα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4228",
    gloss: "foot",
  },
  πόδας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G4228",
    gloss: "foot",
  },
  πόδες: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G4228",
    gloss: "foot",
  },
  ποδός: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G4228",
    gloss: "foot",
  },
  ποδῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G4228",
    gloss: "foot",
  },
  ποσίν: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G4228",
    gloss: "foot",
  },
  ποσὶν: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G4228",
    gloss: "foot",
  },
  πούς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4228",
    gloss: "foot",
  },
  πρᾶγμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4229",
    gloss: "thing",
  },
  πράγματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G4229",
    gloss: "thing",
  },
  πράγματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4229",
    gloss: "thing",
  },
  πραγμάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G4229",
    gloss: "thing",
  },
  πραγματείαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G4230",
    gloss: "affairs",
  },
  Πραγματεύσασθε: {
    parse:
      "V-ADM-2P｜Verb, aorist, Middle deponent, imperative, second, plural",
    GN: "G4231",
    gloss: "to trade",
  },
  πραιτώριον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4232",
    gloss: "praetorium",
  },
  πραιτωρίῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G4232",
    gloss: "praetorium",
  },
  πράκτορι: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G4233",
    gloss: "bailiff",
  },
  πράκτωρ: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4233",
    gloss: "bailiff",
  },
  πράξει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4234",
    gloss: "action",
  },
  πράξεις: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G4234",
    gloss: "action",
  },
  πράξεσιν: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G4234",
    gloss: "action",
  },
  πρᾶξιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4234",
    gloss: "action",
  },
  πρασιαὶ: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G4237",
    gloss: "group",
  },
  ἔπραξα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G4238",
    gloss: "to do/require",
  },
  ἐπράξαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G4238",
    gloss: "to do/require",
  },
  ἔπραξαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4238",
    gloss: "to do/require",
  },
  ἐπράξατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G4238",
    gloss: "to do/require",
  },
  ἔπραξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4238",
    gloss: "to do/require",
  },
  πεπραγμένον: {
    parse:
      "V-RPP-NSN｜Verb, perfect, passive, Participle, nominative, singular, neuter",
    GN: "G4238",
    gloss: "to do/require",
  },
  πέπραχά: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G4238",
    gloss: "to do/require",
  },
  πεπραχέναι: {
    parse: "V-RAN｜Verb, perfect, active, Infinitive",
    GN: "G4238",
    gloss: "to do/require",
  },
  πρᾶξαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G4238",
    gloss: "to do/require",
  },
  πράξαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G4238",
    gloss: "to do/require",
  },
  πραξάντων: {
    parse:
      "V-AAP-GPM｜Verb, aorist, active, Participle, genitive, plural, masculine",
    GN: "G4238",
    gloss: "to do/require",
  },
  πράξας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G4238",
    gloss: "to do/require",
  },
  πράξετε: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G4238",
    gloss: "to do/require",
  },
  πράξῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G4238",
    gloss: "to do/require",
  },
  πράσσει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4238",
    gloss: "to do/require",
  },
  πράσσειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4238",
    gloss: "to do/require",
  },
  πράσσεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G4238",
    gloss: "to do/require",
  },
  πράσσετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G4238",
    gloss: "to do/require",
  },
  πράσσῃς: {
    parse: "V-PAS-2S｜Verb, present, active, subjunctive, second, singular",
    GN: "G4238",
    gloss: "to do/require",
  },
  πράσσοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G4238",
    gloss: "to do/require",
  },
  πράσσοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4238",
    gloss: "to do/require",
  },
  πράσσοντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G4238",
    gloss: "to do/require",
  },
  πράσσουσι: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G4238",
    gloss: "to do/require",
  },
  πράσσουσιν: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G4238",
    gloss: "to do/require",
  },
  πράσσω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G4238",
    gloss: "to do/require",
  },
  πράσσων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4238",
    gloss: "to do/require",
  },
  πραεῖς: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4239",
    gloss: "gentle",
  },
  πραέως: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G4239",
    gloss: "gentle",
  },
  πραΰς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4239",
    gloss: "gentle",
  },
  πραῢς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4239",
    gloss: "gentle",
  },
  πραΰτης: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4240",
    gloss: "gentleness",
  },
  πραΰτητα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4240",
    gloss: "gentleness",
  },
  πραΰτητι: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4240",
    gloss: "gentleness",
  },
  πραΰτητος: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4240",
    gloss: "gentleness",
  },
  ἔπρεπεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G4241",
    gloss: "be proper",
  },
  Ἔπρεπεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G4241",
    gloss: "be proper",
  },
  πρέπει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4241",
    gloss: "be proper",
  },
  πρέπον: {
    parse:
      "V-PAP-NSN｜Verb, present, active, Participle, nominative, singular, neuter",
    GN: "G4241",
    gloss: "be proper",
  },
  πρεσβείαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4242",
    gloss: "delegation",
  },
  πρεσβεύομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G4243",
    gloss: "be ambassador",
  },
  πρεσβεύω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G4243",
    gloss: "be ambassador",
  },
  πρεσβυτέριον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4244",
    gloss: "council of elders",
  },
  πρεσβυτερίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4244",
    gloss: "council of elders",
  },
  πρεσβυτέρας: {
    parse: "A-APF-C｜Adjective, accusative, plural, feminine, Comparative",
    GN: "G4245",
    gloss: "old",
  },
  πρεσβύτεροι: {
    parse: "A-VPM｜Adjective, Vocative or nominative, plural, masculine",
    GN: "G4245",
    gloss: "old",
  },
  πρεσβυτέροις: {
    parse: "A-DPM-C｜Adjective, dative, plural, masculine, Comparative",
    GN: "G4245",
    gloss: "old",
  },
  πρεσβύτερος: {
    parse: "A-NSM-C｜Adjective, nominative, singular, masculine, Comparative",
    GN: "G4245",
    gloss: "old",
  },
  πρεσβυτέρου: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G4245",
    gloss: "old",
  },
  πρεσβυτέρους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G4245",
    gloss: "old",
  },
  Πρεσβυτέρους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G4245",
    gloss: "old",
  },
  Πρεσβυτέρῳ: {
    parse: "A-DSM-C｜Adjective, dative, singular, masculine, Comparative",
    GN: "G4245",
    gloss: "old",
  },
  πρεσβυτέρων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G4245",
    gloss: "old",
  },
  πρεσβύτας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G4246",
    gloss: "old man",
  },
  πρεσβύτης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4246",
    gloss: "old man",
  },
  Πρεσβύτιδας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G4247",
    gloss: "old woman",
  },
  πρηνὴς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4248",
    gloss: "headlong",
  },
  ἐπρίσθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G4249",
    gloss: "to saw (in two)",
  },
  πρὶν: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G4250",
    gloss: "before",
  },
  Πρὶν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4250",
    gloss: "before",
  },
  Πρίσκα: {
    parse: "N-NSF-P｜Noun, nominative, singular, feminine, Person",
    GN: "G4251",
    gloss: "Prisca",
  },
  Πρίσκαν: {
    parse: "N-ASF-P｜Noun, accusative, singular, feminine, Person",
    GN: "G4251",
    gloss: "Prisca",
  },
  Πρίσκιλλα: {
    parse: "N-NSF-P｜Noun, nominative, singular, feminine, Person",
    GN: "G4252",
    gloss: "Priscilla",
  },
  Πρίσκιλλαν: {
    parse: "N-ASF-P｜Noun, accusative, singular, feminine, Person",
    GN: "G4252",
    gloss: "Priscilla",
  },
  πρὸ: { parse: "PREP｜Preposition", GN: "G4253", gloss: "before" },
  Πρὸ: { parse: "PREP｜Preposition", GN: "G4253", gloss: "before" },
  προαγαγεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G4254",
    gloss: "to go/bring before",
  },
  προαγαγὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G4254",
    gloss: "to go/bring before",
  },
  προάγει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4254",
    gloss: "to go/bring before",
  },
  Προάγει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4254",
    gloss: "to go/bring before",
  },
  προάγειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4254",
    gloss: "to go/bring before",
  },
  προάγοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4254",
    gloss: "to go/bring before",
  },
  προάγουσαι: {
    parse:
      "V-PAP-NPF｜Verb, present, active, Participle, nominative, plural, feminine",
    GN: "G4254",
    gloss: "to go/bring before",
  },
  προαγούσας: {
    parse:
      "V-PAP-APF｜Verb, present, active, Participle, accusative, plural, feminine",
    GN: "G4254",
    gloss: "to go/bring before",
  },
  προαγούσης: {
    parse:
      "V-PAP-GSF｜Verb, present, active, Participle, genitive, singular, feminine",
    GN: "G4254",
    gloss: "to go/bring before",
  },
  προάγουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G4254",
    gloss: "to go/bring before",
  },
  προάγων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4254",
    gloss: "to go/bring before",
  },
  προάξω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G4254",
    gloss: "to go/bring before",
  },
  προήγαγον: {
    parse:
      "V-2AAI-1S｜Verb, 2nd aorist, active, indicative, first, singular OR third, plural",
    GN: "G4254",
    gloss: "to go/bring before",
  },
  προῆγεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G4254",
    gloss: "to go/bring before",
  },
  προῄρηται: {
    parse: "V-RMI-3S｜Verb, perfect, middle, indicative, third, singular",
    GN: "G4255",
    gloss: "to predetermine",
  },
  προῃτιασάμεθα: {
    parse: "V-ADI-1P｜Verb, aorist, Middle deponent, indicative, first, plural",
    GN: "G4256",
    gloss: "to accuse",
  },
  προηκούσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G4257",
    gloss: "to hear beforehand",
  },
  προημαρτηκόσιν: {
    parse:
      "V-RAP-DPM｜Verb, perfect, active, Participle, dative, plural, masculine",
    GN: "G4258",
    gloss: "to sin beforehand",
  },
  προημαρτηκότων: {
    parse:
      "V-RAP-GPM｜Verb, perfect, active, Participle, genitive, plural, masculine",
    GN: "G4258",
    gloss: "to sin beforehand",
  },
  προαύλιον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G4259",
    gloss: "entryway",
  },
  προβὰς: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G4260",
    gloss: "to advance",
  },
  προβεβηκότες: {
    parse:
      "V-RAP-NPM｜Verb, perfect, active, Participle, nominative, plural, masculine",
    GN: "G4260",
    gloss: "to advance",
  },
  προβεβηκυῖα: {
    parse:
      "V-RAP-NSF｜Verb, perfect, active, Participle, nominative, singular, feminine",
    GN: "G4260",
    gloss: "to advance",
  },
  προβαλόντων: {
    parse:
      "V-2AAP-GPM｜Verb, 2nd aorist, active, Participle, genitive, plural, masculine",
    GN: "G4261",
    gloss: "to put forth",
  },
  προβάλωσιν: {
    parse: "V-2AAS-3P｜Verb, 2nd aorist, active, subjunctive, third, plural",
    GN: "G4261",
    gloss: "to put forth",
  },
  προβατικῇ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G4262",
    gloss: "Sheep Gate",
  },
  πρόβατα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G4263",
    gloss: "sheep",
  },
  πρόβατά: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G4263",
    gloss: "sheep",
  },
  πρόβατον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4263",
    gloss: "sheep",
  },
  πρόβατόν: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G4263",
    gloss: "sheep",
  },
  προβάτου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4263",
    gloss: "sheep",
  },
  προβάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G4263",
    gloss: "sheep",
  },
  προβιβασθεῖσα: {
    parse:
      "V-APP-NSF｜Verb, aorist, passive, Participle, nominative, singular, feminine",
    GN: "G4264",
    gloss: "to prompt",
  },
  προβλεψαμένου: {
    parse:
      "V-AMP-GSM｜Verb, aorist, middle, Participle, genitive, singular, masculine",
    GN: "G4265",
    gloss: "to foresee/plan",
  },
  προγεγονότων: {
    parse:
      "V-2RAP-GPN｜Verb, 2nd perfect, active, Participle, genitive, plural, neuter",
    GN: "G4266",
    gloss: "to do beforehand",
  },
  προγινώσκοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4267",
    gloss: "to know/choose",
  },
  προγινώσκοντές: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4267",
    gloss: "to know/choose",
  },
  προέγνω: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G4267",
    gloss: "to know/choose",
  },
  προεγνωσμένου: {
    parse:
      "V-RPP-GSM｜Verb, perfect, passive, Participle, genitive, singular, masculine",
    GN: "G4267",
    gloss: "to know/choose",
  },
  προγνώσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4268",
    gloss: "foreknowledge",
  },
  πρόγνωσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4268",
    gloss: "foreknowledge",
  },
  προγόνοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G4269",
    gloss: "parent/ancestor",
  },
  προγόνων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G4269",
    gloss: "parent/ancestor",
  },
  προγεγραμμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G4270",
    gloss: "to write/designate",
  },
  προεγράφη: {
    parse: "V-2API-3S｜Verb, 2nd aorist, passive, indicative, third, singular",
    GN: "G4270",
    gloss: "to write/designate",
  },
  προέγραψα: {
    parse: "V-2AAI-1S｜Verb, 2nd aorist, active, indicative, first, singular",
    GN: "G4270",
    gloss: "to write/designate",
  },
  πρόδηλα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G4271",
    gloss: "obvious",
  },
  πρόδηλοί: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G4271",
    gloss: "obvious",
  },
  πρόδηλον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G4271",
    gloss: "obvious",
  },
  προέδωκεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4272",
    gloss: "to give in advance",
  },
  προδόται: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G4273",
    gloss: "traitor",
  },
  προδότης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4273",
    gloss: "traitor",
  },
  πρόδρομος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4274",
    gloss: "forerunner",
  },
  προηλπικότας: {
    parse:
      "V-RAP-APM｜Verb, perfect, active, Participle, accusative, plural, masculine",
    GN: "G4276",
    gloss: "to hope beforehand",
  },
  προείπαμεν: {
    parse: "V-2AAI-1P｜Verb, 2nd aorist, active, indicative, first, plural",
    GN: "G4277",
    gloss: "to predict",
  },
  προεῖπεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G4277",
    gloss: "to predict",
  },
  προεῖπον: {
    parse:
      "V-2AAI-1S｜Verb, 2nd aorist, active, indicative, first, singular OR third, plural",
    GN: "G4277",
    gloss: "to predict",
  },
  προενήρξασθε: {
    parse:
      "V-ADI-2P｜Verb, aorist, Middle deponent, indicative, second, plural",
    GN: "G4278",
    gloss: "to begin beforehand",
  },
  προενήρξατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G4278",
    gloss: "to begin beforehand",
  },
  προεπηγγείλατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G4279",
    gloss: "to promise",
  },
  προεπηγγελμένην: {
    parse:
      "V-RNP-ASF｜Verb, perfect, Middle or Passive deponent, Participle, accusative, singular, feminine",
    GN: "G4279",
    gloss: "to promise",
  },
  προείρηκα: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G4280",
    gloss: "to predict",
  },
  προειρήκαμεν: {
    parse: "V-RAI-1P｜Verb, perfect, active, indicative, first, plural",
    GN: "G4280",
    gloss: "to predict",
  },
  προείρηκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G4280",
    gloss: "to predict",
  },
  προειρημένων: {
    parse:
      "V-RPP-GPN｜Verb, perfect, passive, Participle, genitive, plural, neuter",
    GN: "G4280",
    gloss: "to predict",
  },
  προείρηται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G4280",
    gloss: "to predict",
  },
  προελεύσεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G4281",
    gloss: "to go before",
  },
  προελθόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G4281",
    gloss: "to go before",
  },
  προελθὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G4281",
    gloss: "to go before",
  },
  προέλθωσιν: {
    parse: "V-2AAS-3P｜Verb, 2nd aorist, active, subjunctive, third, plural",
    GN: "G4281",
    gloss: "to go before",
  },
  προῆλθον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G4281",
    gloss: "to go before",
  },
  προήρχετο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G4281",
    gloss: "to go before",
  },
  προητοίμασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4282",
    gloss: "to prepare",
  },
  προευηγγελίσατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G4283",
    gloss: "to speak good news",
  },
  προεχόμεθα: {
    parse:
      "V-PNI-1P｜Verb, present, Middle or Passive deponent, indicative, first, plural",
    GN: "G4284",
    gloss: "to excel",
  },
  προηγούμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G4285",
    gloss: "to prefer",
  },
  προθέσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4286",
    gloss: "purpose",
  },
  προθέσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4286",
    gloss: "purpose",
  },
  πρόθεσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4286",
    gloss: "purpose",
  },
  πρόθεσις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4286",
    gloss: "purpose",
  },
  προθεσμίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4287",
    gloss: "set time",
  },
  προθυμία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4288",
    gloss: "eagerness",
  },
  προθυμίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4288",
    gloss: "eagerness",
  },
  προθυμίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4288",
    gloss: "eagerness",
  },
  πρόθυμον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G4289",
    gloss: "eager",
  },
  προθύμως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4290",
    gloss: "eagerly",
  },
  προεστῶτες: {
    parse:
      "V-RAP-NPM｜Verb, perfect, active, Participle, nominative, plural, masculine",
    GN: "G4291",
    gloss: "to set before",
  },
  προϊστάμενοι: {
    parse:
      "V-PMP-NPM｜Verb, present, middle, Participle, nominative, plural, masculine",
    GN: "G4291",
    gloss: "to set before",
  },
  προϊστάμενον: {
    parse:
      "V-PMP-ASM｜Verb, present, middle, Participle, accusative, singular, masculine",
    GN: "G4291",
    gloss: "to set before",
  },
  προϊστάμενος: {
    parse:
      "V-PMP-NSM｜Verb, present, middle, Participle, nominative, singular, masculine",
    GN: "G4291",
    gloss: "to set before",
  },
  προϊσταμένους: {
    parse:
      "V-PMP-APM｜Verb, present, middle, Participle, accusative, plural, masculine",
    GN: "G4291",
    gloss: "to set before",
  },
  προΐστασθαι: {
    parse: "V-PMN｜Verb, present, middle, Infinitive",
    GN: "G4291",
    gloss: "to set before",
  },
  προστῆναι: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G4291",
    gloss: "to set before",
  },
  προκαλούμενοι: {
    parse:
      "V-PMP-NPM｜Verb, present, middle, Participle, nominative, plural, masculine",
    GN: "G4292",
    gloss: "to provoke",
  },
  προκαταγγείλαντας: {
    parse:
      "V-AAP-APM｜Verb, aorist, active, Participle, accusative, plural, masculine",
    GN: "G4293",
    gloss: "to foretell",
  },
  προκατήγγειλεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4293",
    gloss: "to foretell",
  },
  προκαταρτίσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G4294",
    gloss: "to arrange",
  },
  προκειμένης: {
    parse:
      "V-PNP-GSF｜Verb, present, Middle or Passive deponent, Participle, genitive, singular, feminine",
    GN: "G4295",
    gloss: "to set before",
  },
  προκείμενον: {
    parse:
      "V-PNP-ASM｜Verb, present, Middle or Passive deponent, Participle, accusative, singular, masculine",
    GN: "G4295",
    gloss: "to set before",
  },
  πρόκεινται: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G4295",
    gloss: "to set before",
  },
  πρόκειται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G4295",
    gloss: "to set before",
  },
  προκηρύξαντος: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G4296",
    gloss: "to announce",
  },
  προκοπὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4297",
    gloss: "progress",
  },
  προκοπὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4297",
    gloss: "progress",
  },
  προέκοπτεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G4298",
    gloss: "to advance",
  },
  προέκοπτον: {
    parse: "V-IAI-1S｜Verb, imperfect, active, indicative, first, singular",
    GN: "G4298",
    gloss: "to advance",
  },
  προέκοψεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4298",
    gloss: "to advance",
  },
  προκόψουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G4298",
    gloss: "to advance",
  },
  προκρίματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4299",
    gloss: "prejudice",
  },
  προκεκυρωμένην: {
    parse:
      "V-RPP-ASF｜Verb, perfect, passive, Participle, accusative, singular, feminine",
    GN: "G4300",
    gloss: "to establish",
  },
  προέλαβεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G4301",
    gloss: "to take beforehand",
  },
  προλαμβάνει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4301",
    gloss: "to take beforehand",
  },
  προλημφθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G4301",
    gloss: "to take beforehand",
  },
  προελέγομεν: {
    parse: "V-IAI-1P｜Verb, imperfect, active, indicative, first, plural",
    GN: "G4302",
    gloss: "to foretell",
  },
  προλέγω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G4302",
    gloss: "to foretell",
  },
  προμαρτυρόμενον: {
    parse:
      "V-PNP-NSN｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, neuter",
    GN: "G4303",
    gloss: "to predict",
  },
  προμελετᾶν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4304",
    gloss: "to premeditate",
  },
  προμεριμνᾶτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G4305",
    gloss: "to worry beforehand",
  },
  προνοεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4306",
    gloss: "to care for",
  },
  προνοοῦμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G4306",
    gloss: "to care for",
  },
  προνοούμενοι: {
    parse:
      "V-PMP-NPM｜Verb, present, middle, Participle, nominative, plural, masculine",
    GN: "G4306",
    gloss: "to care for",
  },
  πρόνοιαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4307",
    gloss: "foresight",
  },
  προνοίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4307",
    gloss: "foresight",
  },
  προεωρακότες: {
    parse:
      "V-RAP-NPM｜Verb, perfect, active, Participle, nominative, plural, masculine",
    GN: "G4308",
    gloss: "to foresee",
  },
  προϊδοῦσα: {
    parse:
      "V-2AAP-NSF｜Verb, 2nd aorist, active, Participle, nominative, singular, feminine",
    GN: "G4308",
    gloss: "to foresee",
  },
  προϊδὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G4308",
    gloss: "to foresee",
  },
  Προορώμην: {
    parse: "V-IMI-1S｜Verb, imperfect, middle, indicative, first, singular",
    GN: "G4308",
    gloss: "to foresee",
  },
  προορίσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G4309",
    gloss: "to predestine",
  },
  προορισθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G4309",
    gloss: "to predestine",
  },
  προώρισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4309",
    gloss: "to predestine",
  },
  προπαθόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G4310",
    gloss: "to suffer before",
  },
  προέπεμπον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G4311",
    gloss: "to help on the way",
  },
  προπεμπόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G4311",
    gloss: "to help on the way",
  },
  προπεμφθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G4311",
    gloss: "to help on the way",
  },
  προπεμφθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G4311",
    gloss: "to help on the way",
  },
  προπέμψας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G4311",
    gloss: "to help on the way",
  },
  προπέμψατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G4311",
    gloss: "to help on the way",
  },
  προπέμψητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G4311",
    gloss: "to help on the way",
  },
  πρόπεμψον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G4311",
    gloss: "to help on the way",
  },
  προπετεῖς: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4312",
    gloss: "reckless",
  },
  προπετὲς: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G4312",
    gloss: "reckless",
  },
  προπορεύσῃ: {
    parse:
      "V-FDI-2S｜Verb, future, Middle deponent, indicative, second, singular",
    GN: "G4313",
    gloss: "to go before",
  },
  προπορεύσονται: {
    parse: "V-FDI-3P｜Verb, future, Middle deponent, indicative, third, plural",
    GN: "G4313",
    gloss: "to go before",
  },
  πρός: { parse: "PREP｜Preposition", GN: "G4314", gloss: "to/with" },
  πρὸς: { parse: "PREP｜Preposition", GN: "G4314", gloss: "to/with" },
  Πρὸς: { parse: "PREP｜Preposition", GN: "G4314", gloss: "to/with" },
  προσάββατον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4315",
    gloss: "Friday",
  },
  προσαγορευθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G4316",
    gloss: "to designate",
  },
  προσάγαγε: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G4317",
    gloss: "to bring near",
  },
  προσαγάγῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G4317",
    gloss: "to bring near",
  },
  προσαγαγόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G4317",
    gloss: "to bring near",
  },
  προσάγειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4317",
    gloss: "to bring near",
  },
  προσαγωγὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4318",
    gloss: "access",
  },
  προσαιτῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4319",
    gloss: "to beg",
  },
  προσανάβηθι: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G4320",
    gloss: "to go up",
  },
  προσαναλώσασα: {
    parse:
      "V-AAP-NSF｜Verb, aorist, active, Participle, nominative, singular, feminine",
    GN: "G4321",
    gloss: "to expend",
  },
  προσαναπληροῦσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G4322",
    gloss: "to supply",
  },
  προσανεπλήρωσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4322",
    gloss: "to supply",
  },
  προσανεθέμην: {
    parse: "V-2AMI-1S｜Verb, 2nd aorist, middle, indicative, first, singular",
    GN: "G4323",
    gloss: "to confer",
  },
  προσανέθεντο: {
    parse: "V-2AMI-3P｜Verb, 2nd aorist, middle, indicative, third, plural",
    GN: "G4323",
    gloss: "to confer",
  },
  προσαπειλησάμενοι: {
    parse:
      "V-AMP-NPM｜Verb, aorist, middle, Participle, nominative, plural, masculine",
    GN: "G4324",
    gloss: "to threaten further",
  },
  προσδαπανήσῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G4325",
    gloss: "to spend extra",
  },
  προσδεόμενός: {
    parse:
      "V-PMP-NSM｜Verb, present, middle, Participle, nominative, singular, masculine",
    GN: "G4326",
    gloss: "to need",
  },
  προσδεξάμενοι: {
    parse:
      "V-ADP-NPM｜Verb, aorist, Middle deponent, Participle, nominative, plural, masculine",
    GN: "G4327",
    gloss: "to wait for/welcome",
  },
  προσδέξησθε: {
    parse:
      "V-ADS-2P｜Verb, aorist, Middle deponent, subjunctive, second, plural",
    GN: "G4327",
    gloss: "to wait for/welcome",
  },
  προσδέχεσθε: {
    parse:
      "V-PNM-2P｜Verb, present, Middle or Passive deponent, imperative, second, plural",
    GN: "G4327",
    gloss: "to wait for/welcome",
  },
  προσδέχεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G4327",
    gloss: "to wait for/welcome",
  },
  προσδεχόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G4327",
    gloss: "to wait for/welcome",
  },
  προσδεχομένοις: {
    parse:
      "V-PNP-DPM｜Verb, present, Middle or Passive deponent, Participle, dative, plural, masculine",
    GN: "G4327",
    gloss: "to wait for/welcome",
  },
  προσδεχόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G4327",
    gloss: "to wait for/welcome",
  },
  προσδέχονται: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G4327",
    gloss: "to wait for/welcome",
  },
  προσεδέξασθε: {
    parse:
      "V-ADI-2P｜Verb, aorist, Middle deponent, indicative, second, plural",
    GN: "G4327",
    gloss: "to wait for/welcome",
  },
  προσεδέχετο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G4327",
    gloss: "to wait for/welcome",
  },
  προσδοκᾷ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4328",
    gloss: "to look for",
  },
  προσδοκῶμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G4328",
    gloss: "to look for",
  },
  προσδοκῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4328",
    gloss: "to look for",
  },
  προσδοκῶντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G4328",
    gloss: "to look for",
  },
  προσδοκῶντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4328",
    gloss: "to look for",
  },
  Προσδοκῶντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G4328",
    gloss: "to look for",
  },
  προσδοκώντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G4328",
    gloss: "to look for",
  },
  προσεδόκων: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G4328",
    gloss: "to look for",
  },
  προσδοκίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4329",
    gloss: "expectation",
  },
  προσεῶντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G4330",
    gloss: "to permit",
  },
  παρεδρεύοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4332",
    gloss: "to sit near/serve",
  },
  προσηργάσατο: {
    parse: "V-AMI-3S｜Verb, aorist, middle, indicative, third, singular",
    GN: "G4333",
    gloss: "to earn more",
  },
  προσεληλύθατε: {
    parse: "V-2RAI-2P｜Verb, 2nd perfect, active, indicative, second, plural",
    GN: "G4334",
    gloss: "to come near/agree",
  },
  Πρόσελθε: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G4334",
    gloss: "to come near/agree",
  },
  προσελθόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G4334",
    gloss: "to come near/agree",
  },
  Προσελθόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G4334",
    gloss: "to come near/agree",
  },
  προσελθόντων: {
    parse:
      "V-2AAP-GPM｜Verb, 2nd aorist, active, Participle, genitive, plural, masculine",
    GN: "G4334",
    gloss: "to come near/agree",
  },
  προσελθοῦσα: {
    parse:
      "V-2AAP-NSF｜Verb, 2nd aorist, active, Participle, nominative, singular, feminine",
    GN: "G4334",
    gloss: "to come near/agree",
  },
  προσελθοῦσαι: {
    parse:
      "V-2AAP-NPF｜Verb, 2nd aorist, active, Participle, nominative, plural, feminine",
    GN: "G4334",
    gloss: "to come near/agree",
  },
  προσελθὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G4334",
    gloss: "to come near/agree",
  },
  προσέρχεσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G4334",
    gloss: "to come near/agree",
  },
  προσέρχεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G4334",
    gloss: "to come near/agree",
  },
  προσερχόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G4334",
    gloss: "to come near/agree",
  },
  προσερχόμενον: {
    parse:
      "V-PNP-ASM｜Verb, present, Middle or Passive deponent, Participle, accusative, singular, masculine",
    GN: "G4334",
    gloss: "to come near/agree",
  },
  προσερχομένου: {
    parse:
      "V-PNP-GSM｜Verb, present, Middle or Passive deponent, Participle, genitive, singular, masculine",
    GN: "G4334",
    gloss: "to come near/agree",
  },
  προσερχομένους: {
    parse:
      "V-PNP-APM｜Verb, present, Middle or Passive deponent, Participle, accusative, plural, masculine",
    GN: "G4334",
    gloss: "to come near/agree",
  },
  προσέρχονται: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G4334",
    gloss: "to come near/agree",
  },
  προσερχώμεθα: {
    parse:
      "V-PNS-1P｜Verb, present, Middle or Passive deponent, subjunctive, first, plural",
    GN: "G4334",
    gloss: "to come near/agree",
  },
  προσῆλθαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G4334",
    gloss: "to come near/agree",
  },
  προσῆλθάν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G4334",
    gloss: "to come near/agree",
  },
  προσῆλθεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G4334",
    gloss: "to come near/agree",
  },
  προσῆλθον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G4334",
    gloss: "to come near/agree",
  },
  προσήρχοντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G4334",
    gloss: "to come near/agree",
  },
  προσευχαί: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G4335",
    gloss: "prayer",
  },
  προσευχαὶ: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G4335",
    gloss: "prayer",
  },
  προσευχαῖς: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G4335",
    gloss: "prayer",
  },
  προσευχάς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G4335",
    gloss: "prayer",
  },
  προσευχὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G4335",
    gloss: "prayer",
  },
  προσευχῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4335",
    gloss: "prayer",
  },
  προσευχὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4335",
    gloss: "prayer",
  },
  προσευχὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4335",
    gloss: "prayer",
  },
  προσευχῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4335",
    gloss: "prayer",
  },
  προσευχῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G4335",
    gloss: "prayer",
  },
  πρόσευξαι: {
    parse:
      "V-ADM-2S｜Verb, aorist, Middle deponent, imperative, second, singular",
    GN: "G4336",
    gloss: "to pray",
  },
  προσευξάμενοι: {
    parse:
      "V-ADP-NPM｜Verb, aorist, Middle deponent, Participle, nominative, plural, masculine",
    GN: "G4336",
    gloss: "to pray",
  },
  προσευξάμενος: {
    parse:
      "V-ADP-NSM｜Verb, aorist, Middle deponent, Participle, nominative, singular, masculine",
    GN: "G4336",
    gloss: "to pray",
  },
  προσεύξασθαι: {
    parse: "V-ADN｜Verb, aorist, Middle deponent, Infinitive",
    GN: "G4336",
    gloss: "to pray",
  },
  προσευξάσθωσαν: {
    parse: "V-ADM-3P｜Verb, aorist, Middle deponent, imperative, third, plural",
    GN: "G4336",
    gloss: "to pray",
  },
  προσεύξηται: {
    parse:
      "V-ADS-3S｜Verb, aorist, Middle deponent, subjunctive, third, singular",
    GN: "G4336",
    gloss: "to pray",
  },
  προσεύξομαι: {
    parse:
      "V-FDI-1S｜Verb, future, Middle deponent, indicative, first, singular",
    GN: "G4336",
    gloss: "to pray",
  },
  προσεύξωμαι: {
    parse:
      "V-ADS-1S｜Verb, aorist, Middle deponent, subjunctive, first, singular",
    GN: "G4336",
    gloss: "to pray",
  },
  προσευξώμεθα: {
    parse:
      "V-ADS-1P｜Verb, aorist, Middle deponent, subjunctive, first, plural",
    GN: "G4336",
    gloss: "to pray",
  },
  προσεύχεσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G4336",
    gloss: "to pray",
  },
  προσεύχεσθε: {
    parse:
      "V-PNM-2P｜Verb, present, Middle or Passive deponent, imperative, second, plural",
    GN: "G4336",
    gloss: "to pray",
  },
  Προσεύχεσθε: {
    parse:
      "V-PNM-2P｜Verb, present, Middle or Passive deponent, imperative, second, plural",
    GN: "G4336",
    gloss: "to pray",
  },
  προσευχέσθω: {
    parse:
      "V-PNM-3S｜Verb, present, Middle or Passive deponent, imperative, third, singular",
    GN: "G4336",
    gloss: "to pray",
  },
  προσεύχεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G4336",
    gloss: "to pray",
  },
  προσεύχῃ: {
    parse:
      "V-PNS-2S｜Verb, present, Middle or Passive deponent, subjunctive, second, singular",
    GN: "G4336",
    gloss: "to pray",
  },
  προσεύχησθε: {
    parse:
      "V-PNS-2P｜Verb, present, Middle or Passive deponent, subjunctive, second, plural",
    GN: "G4336",
    gloss: "to pray",
  },
  προσεύχομαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G4336",
    gloss: "to pray",
  },
  προσευχόμεθα: {
    parse:
      "V-PNI-1P｜Verb, present, Middle or Passive deponent, indicative, first, plural",
    GN: "G4336",
    gloss: "to pray",
  },
  προσευχομένη: {
    parse:
      "V-PNP-NSF｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, feminine",
    GN: "G4336",
    gloss: "to pray",
  },
  προσευχόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G4336",
    gloss: "to pray",
  },
  Προσευχόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G4336",
    gloss: "to pray",
  },
  προσευχόμενον: {
    parse:
      "V-PNP-NSN｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, neuter",
    GN: "G4336",
    gloss: "to pray",
  },
  προσευχόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G4336",
    gloss: "to pray",
  },
  προσευχομένου: {
    parse:
      "V-PNP-GSM｜Verb, present, Middle or Passive deponent, Participle, genitive, singular, masculine",
    GN: "G4336",
    gloss: "to pray",
  },
  προσεύχονται: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G4336",
    gloss: "to pray",
  },
  προσεύχωμαι: {
    parse:
      "V-PNS-1S｜Verb, present, Middle or Passive deponent, subjunctive, first, singular",
    GN: "G4336",
    gloss: "to pray",
  },
  προσηύξαντο: {
    parse: "V-ADI-3P｜Verb, aorist, Middle deponent, indicative, third, plural",
    GN: "G4336",
    gloss: "to pray",
  },
  προσηύξατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G4336",
    gloss: "to pray",
  },
  προσηύχετο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G4336",
    gloss: "to pray",
  },
  προσεῖχον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G4337",
    gloss: "to watch out",
  },
  προσέσχηκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G4337",
    gloss: "to watch out",
  },
  πρόσεχε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G4337",
    gloss: "to watch out",
  },
  προσέχειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4337",
    gloss: "to watch out",
  },
  προσέχετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G4337",
    gloss: "to watch out",
  },
  Προσέχετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G4337",
    gloss: "to watch out",
  },
  προσέχοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G4337",
    gloss: "to watch out",
  },
  προσέχοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4337",
    gloss: "to watch out",
  },
  προσηλώσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G4338",
    gloss: "to nail",
  },
  προσήλυτοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G4339",
    gloss: "proselyte",
  },
  προσήλυτον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4339",
    gloss: "proselyte",
  },
  προσηλύτων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G4339",
    gloss: "proselyte",
  },
  πρόσκαιρα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G4340",
    gloss: "temporary",
  },
  πρόσκαιροί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4340",
    gloss: "temporary",
  },
  πρόσκαιρον: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G4340",
    gloss: "temporary",
  },
  πρόσκαιρός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4340",
    gloss: "temporary",
  },
  προσεκαλέσατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G4341",
    gloss: "to call to/summon",
  },
  προσκαλεῖται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G4341",
    gloss: "to call to/summon",
  },
  προσκαλεσάμενοι: {
    parse:
      "V-ADP-NPM｜Verb, aorist, Middle deponent, Participle, nominative, plural, masculine",
    GN: "G4341",
    gloss: "to call to/summon",
  },
  προσκαλεσάμενος: {
    parse:
      "V-ADP-NSM｜Verb, aorist, Middle deponent, Participle, nominative, singular, masculine",
    GN: "G4341",
    gloss: "to call to/summon",
  },
  προσκαλεσάμενός: {
    parse:
      "V-ADP-NSM｜Verb, aorist, Middle deponent, Participle, nominative, singular, masculine",
    GN: "G4341",
    gloss: "to call to/summon",
  },
  προσκαλεσάσθω: {
    parse:
      "V-ADM-3S｜Verb, aorist, Middle deponent, imperative, third, singular",
    GN: "G4341",
    gloss: "to call to/summon",
  },
  προσκαλέσηται: {
    parse:
      "V-ADS-3S｜Verb, aorist, Middle deponent, subjunctive, third, singular",
    GN: "G4341",
    gloss: "to call to/summon",
  },
  προσκέκλημαι: {
    parse:
      "V-RNI-1S｜Verb, perfect, Middle or Passive deponent, indicative, first, singular",
    GN: "G4341",
    gloss: "to call to/summon",
  },
  προσκέκληται: {
    parse:
      "V-RNI-3S｜Verb, perfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G4341",
    gloss: "to call to/summon",
  },
  προσκαρτερεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G4342",
    gloss: "to continue in/with",
  },
  προσκαρτερῇ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G4342",
    gloss: "to continue in/with",
  },
  προσκαρτερήσομεν: {
    parse: "V-FAI-1P｜Verb, future, active, indicative, first, plural",
    GN: "G4342",
    gloss: "to continue in/with",
  },
  προσκαρτεροῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4342",
    gloss: "to continue in/with",
  },
  προσκαρτερούντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G4342",
    gloss: "to continue in/with",
  },
  προσκαρτερῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4342",
    gloss: "to continue in/with",
  },
  προσκαρτερήσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4343",
    gloss: "perseverance",
  },
  προσκεφάλαιον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G4344",
    gloss: "cushion",
  },
  προσεκληρώθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G4345",
    gloss: "to join",
  },
  πρόσκλισιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4346",
    gloss: "partiality",
  },
  προσκολληθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G4347",
    gloss: "to join",
  },
  πρόσκομμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4348",
    gloss: "stumbling block",
  },
  προσκόμματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4348",
    gloss: "stumbling block",
  },
  προσκοπήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4349",
    gloss: "stumbling",
  },
  προσέκοψαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4350",
    gloss: "to strike",
  },
  προσκόπτει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4350",
    gloss: "to strike",
  },
  προσκόπτουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G4350",
    gloss: "to strike",
  },
  προσκόψῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G4350",
    gloss: "to strike",
  },
  προσεκύλισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4351",
    gloss: "to roll before",
  },
  προσκυλίσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G4351",
    gloss: "to roll before",
  },
  προσεκύνει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G4352",
    gloss: "to worship",
  },
  προσεκύνησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4352",
    gloss: "to worship",
  },
  προσεκύνησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4352",
    gloss: "to worship",
  },
  προσεκύνουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G4352",
    gloss: "to worship",
  },
  προσκυνεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4352",
    gloss: "to worship",
  },
  προσκυνεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4352",
    gloss: "to worship",
  },
  προσκυνεῖτε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G4352",
    gloss: "to worship",
  },
  προσκυνῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G4352",
    gloss: "to worship",
  },
  προσκυνήσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G4352",
    gloss: "to worship",
  },
  προσκυνήσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G4352",
    gloss: "to worship",
  },
  προσκυνησάτωσαν: {
    parse: "V-AAM-3P｜Verb, aorist, active, imperative, third, plural",
    GN: "G4352",
    gloss: "to worship",
  },
  προσκυνήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G4352",
    gloss: "to worship",
  },
  προσκυνήσεις: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G4352",
    gloss: "to worship",
  },
  προσκυνήσετε: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G4352",
    gloss: "to worship",
  },
  προσκυνήσῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G4352",
    gloss: "to worship",
  },
  προσκύνησον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G4352",
    gloss: "to worship",
  },
  προσκυνήσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G4352",
    gloss: "to worship",
  },
  προσκυνήσω: {
    parse: "V-AAS-1S｜Verb, aorist, active, subjunctive, first, singular",
    GN: "G4352",
    gloss: "to worship",
  },
  προσκυνήσων: {
    parse:
      "V-FAP-NSM｜Verb, future, active, Participle, nominative, singular, masculine",
    GN: "G4352",
    gloss: "to worship",
  },
  προσκυνήσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G4352",
    gloss: "to worship",
  },
  προσκυνοῦμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G4352",
    gloss: "to worship",
  },
  προσκυνοῦντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G4352",
    gloss: "to worship",
  },
  προσκυνοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4352",
    gloss: "to worship",
  },
  προσκυνοῦσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G4352",
    gloss: "to worship",
  },
  προσκυνηταὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G4353",
    gloss: "worshiper",
  },
  προσλαλῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G4354",
    gloss: "to talk to/with",
  },
  προσλαλοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4354",
    gloss: "to talk to/with",
  },
  προσελάβετο: {
    parse: "V-2AMI-3S｜Verb, 2nd aorist, middle, indicative, third, singular",
    GN: "G4355",
    gloss: "to take",
  },
  προσελάβοντο: {
    parse: "V-2AMI-3P｜Verb, 2nd aorist, middle, indicative, third, plural",
    GN: "G4355",
    gloss: "to take",
  },
  προσλαβόμενοι: {
    parse:
      "V-2AMP-NPM｜Verb, 2nd aorist, middle, Participle, nominative, plural, masculine",
    GN: "G4355",
    gloss: "to take",
  },
  προσλαβόμενος: {
    parse:
      "V-2AMP-NSM｜Verb, 2nd aorist, middle, Participle, nominative, singular, masculine",
    GN: "G4355",
    gloss: "to take",
  },
  προσλαβοῦ: {
    parse: "V-2AMM-2S｜Verb, 2nd aorist, middle, imperative, second, singular",
    GN: "G4355",
    gloss: "to take",
  },
  προσλαμβάνεσθε: {
    parse: "V-PMM-2P｜Verb, present, middle, imperative, second, plural",
    GN: "G4355",
    gloss: "to take",
  },
  πρόσλημψις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4356",
    gloss: "acceptance",
  },
  προσμεῖναι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G4357",
    gloss: "to remain/persist",
  },
  προσμείνας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G4357",
    gloss: "to remain/persist",
  },
  προσμένει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4357",
    gloss: "to remain/persist",
  },
  προσμένειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4357",
    gloss: "to remain/persist",
  },
  προσμένουσίν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G4357",
    gloss: "to remain/persist",
  },
  προσωρμίσθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G4358",
    gloss: "to moor/anchor",
  },
  προσοφείλεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G4359",
    gloss: "to owe besides",
  },
  προσώχθισα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G4360",
    gloss: "be angry",
  },
  προσώχθισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4360",
    gloss: "be angry",
  },
  πρόσπεινος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4361",
    gloss: "very hungry",
  },
  προσπήξαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G4362",
    gloss: "to nail to",
  },
  προσέπεσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4363",
    gloss: "to fall/beat",
  },
  προσέπεσεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G4363",
    gloss: "to fall/beat",
  },
  προσέπιπτον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G4363",
    gloss: "to fall/beat",
  },
  προσπεσοῦσα: {
    parse:
      "V-2AAP-NSF｜Verb, 2nd aorist, active, Participle, nominative, singular, feminine",
    GN: "G4363",
    gloss: "to fall/beat",
  },
  προσεποιήσατο: {
    parse: "V-AMI-3S｜Verb, aorist, middle, indicative, third, singular",
    GN: "G4364",
    gloss: "to pretend",
  },
  προσπορεύονται: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G4365",
    gloss: "to approach",
  },
  προσέρηξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4366",
    gloss: "to strike",
  },
  προσέταξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4367",
    gloss: "to order",
  },
  προστεταγμένα: {
    parse:
      "V-RPP-APN｜Verb, perfect, passive, Participle, accusative, plural, neuter",
    GN: "G4367",
    gloss: "to order",
  },
  προστεταγμένους: {
    parse:
      "V-RPP-APM｜Verb, perfect, passive, Participle, accusative, plural, masculine",
    GN: "G4367",
    gloss: "to order",
  },
  προστάτις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4368",
    gloss: "patroness",
  },
  προσέθετο: {
    parse: "V-2AMI-3S｜Verb, 2nd aorist, middle, indicative, third, singular",
    GN: "G4369",
    gloss: "to add (to)",
  },
  προσέθηκεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G4369",
    gloss: "to add (to)",
  },
  προσετέθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G4369",
    gloss: "to add (to)",
  },
  προσετέθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G4369",
    gloss: "to add (to)",
  },
  προσετίθει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G4369",
    gloss: "to add (to)",
  },
  προσετίθεντο: {
    parse: "V-IPI-3P｜Verb, imperfect, passive, indicative, third, plural",
    GN: "G4369",
    gloss: "to add (to)",
  },
  προσθεῖναι: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G4369",
    gloss: "to add (to)",
  },
  προσθεὶς: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G4369",
    gloss: "to add (to)",
  },
  Πρόσθες: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G4369",
    gloss: "to add (to)",
  },
  προστεθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G4369",
    gloss: "to add (to)",
  },
  προστεθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G4369",
    gloss: "to add (to)",
  },
  προσδραμὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G4370",
    gloss: "to hasten",
  },
  προστρέχοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4370",
    gloss: "to hasten",
  },
  προσφάγιον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G4371",
    gloss: "fish",
  },
  πρόσφατον: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G4372",
    gloss: "new",
  },
  προσφάτως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4373",
    gloss: "recently",
  },
  προσενέγκαι: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G4374",
    gloss: "to bring to",
  },
  προσενέγκας: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G4374",
    gloss: "to bring to",
  },
  προσένεγκε: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G4374",
    gloss: "to bring to",
  },
  προσενέγκῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G4374",
    gloss: "to bring to",
  },
  προσένεγκον: {
    parse: "V-2AAM-2S｜Verb, 2nd aorist, active, imperative, second, singular",
    GN: "G4374",
    gloss: "to bring to",
  },
  προσενεχθεὶς: {
    parse:
      "V-2APP-NSM｜Verb, 2nd aorist, passive, Participle, nominative, singular, masculine",
    GN: "G4374",
    gloss: "to bring to",
  },
  προσενήνοχεν: {
    parse: "V-2RAI-3S｜Verb, 2nd perfect, active, indicative, third, singular",
    GN: "G4374",
    gloss: "to bring to",
  },
  προσέφερεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G4374",
    gloss: "to bring to",
  },
  προσέφερον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G4374",
    gloss: "to bring to",
  },
  Προσέφερον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G4374",
    gloss: "to bring to",
  },
  προσήνεγκα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G4374",
    gloss: "to bring to",
  },
  προσήνεγκαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4374",
    gloss: "to bring to",
  },
  προσηνέγκατέ: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G4374",
    gloss: "to bring to",
  },
  Προσηνέγκατέ: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G4374",
    gloss: "to bring to",
  },
  προσήνεγκεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4374",
    gloss: "to bring to",
  },
  προσηνέχθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G4374",
    gloss: "to bring to",
  },
  προσήχθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G4374",
    gloss: "to bring to",
  },
  προσηνέχθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G4374",
    gloss: "to bring to",
  },
  πρόσφερε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G4374",
    gloss: "to bring to",
  },
  προσφέρει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4374",
    gloss: "to bring to",
  },
  προσφέρειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4374",
    gloss: "to bring to",
  },
  προσφέρεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G4374",
    gloss: "to bring to",
  },
  προσφέρῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G4374",
    gloss: "to bring to",
  },
  προσφέρῃς: {
    parse: "V-PAS-2S｜Verb, present, active, subjunctive, second, singular",
    GN: "G4374",
    gloss: "to bring to",
  },
  προσφερόμεναι: {
    parse:
      "V-PPP-NPF｜Verb, present, passive, Participle, nominative, plural, feminine",
    GN: "G4374",
    gloss: "to bring to",
  },
  προσφέρονται: {
    parse: "V-PPI-3P｜Verb, present, passive, indicative, third, plural",
    GN: "G4374",
    gloss: "to bring to",
  },
  προσφέροντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4374",
    gloss: "to bring to",
  },
  προσφερόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G4374",
    gloss: "to bring to",
  },
  προσφέρουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G4374",
    gloss: "to bring to",
  },
  προσφέρων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4374",
    gloss: "to bring to",
  },
  προσφιλῆ: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G4375",
    gloss: "lovely",
  },
  προσφορᾷ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4376",
    gloss: "offering",
  },
  προσφορά: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4376",
    gloss: "offering",
  },
  προσφορὰ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4376",
    gloss: "offering",
  },
  προσφορὰν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4376",
    gloss: "offering",
  },
  προσφοράς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G4376",
    gloss: "offering",
  },
  προσφορὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G4376",
    gloss: "offering",
  },
  προσφορᾶς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4376",
    gloss: "offering",
  },
  προσεφώνει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G4377",
    gloss: "to call to/summon",
  },
  προσεφώνησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4377",
    gloss: "to call to/summon",
  },
  προσφωνοῦντα: {
    parse:
      "V-PAP-NPN｜Verb, present, active, Participle, nominative, plural, neuter",
    GN: "G4377",
    gloss: "to call to/summon",
  },
  προσφωνοῦσιν: {
    parse:
      "V-PAP-DPN｜Verb, present, active, Participle, dative, plural, neuter",
    GN: "G4377",
    gloss: "to call to/summon",
  },
  πρόσχυσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4378",
    gloss: "sprinkling",
  },
  προσψαύετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G4379",
    gloss: "to touch",
  },
  προσωπολημπτεῖτε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G4380",
    gloss: "to favor",
  },
  προσωπολήμπτης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4381",
    gloss: "prejudiced person",
  },
  προσωπολημψία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4382",
    gloss: "favoritism",
  },
  προσωπολημψίαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G4382",
    gloss: "favoritism",
  },
  πρόσωπα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G4383",
    gloss: "face",
  },
  πρόσωπον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4383",
    gloss: "face",
  },
  πρόσωπόν: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G4383",
    gloss: "face",
  },
  προσώπου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4383",
    gloss: "face",
  },
  προσώπῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G4383",
    gloss: "face",
  },
  προσώπων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G4383",
    gloss: "face",
  },
  προέτειναν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4385",
    gloss: "to stretch out",
  },
  προτέραν: {
    parse: "A-ASF-C｜Adjective, accusative, singular, feminine, Comparative",
    GN: "G4387",
    gloss: "before",
  },
  πρότερον: {
    parse: "A-ASN-C｜Adjective, accusative, singular, neuter, Comparative",
    GN: "G4387",
    gloss: "before",
  },
  προεθέμην: {
    parse: "V-2AMI-1S｜Verb, 2nd aorist, middle, indicative, first, singular",
    GN: "G4388",
    gloss: "to plan/present",
  },
  προέθετο: {
    parse: "V-2AMI-3S｜Verb, 2nd aorist, middle, indicative, third, singular",
    GN: "G4388",
    gloss: "to plan/present",
  },
  προτρεψάμενοι: {
    parse:
      "V-AMP-NPM｜Verb, aorist, middle, Participle, nominative, plural, masculine",
    GN: "G4389",
    gloss: "to encourage",
  },
  προδραμὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G4390",
    gloss: "to outrun",
  },
  προέδραμεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G4390",
    gloss: "to outrun",
  },
  προϋπῆρχεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G4391",
    gloss: "be formerly",
  },
  προϋπῆρχον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G4391",
    gloss: "be formerly",
  },
  προφάσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4392",
    gloss: "pretense",
  },
  πρόφασιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4392",
    gloss: "pretense",
  },
  προφέρει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4393",
    gloss: "to bring out",
  },
  προφητείᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4394",
    gloss: "prophecy",
  },
  προφητεία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4394",
    gloss: "prophecy",
  },
  προφητεῖαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G4394",
    gloss: "prophecy",
  },
  προφητείαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4394",
    gloss: "prophecy",
  },
  προφητείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4394",
    gloss: "prophecy",
  },
  ἐπροφήτευον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G4395",
    gloss: "to prophesy",
  },
  ἐπροφητεύσαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G4395",
    gloss: "to prophesy",
  },
  ἐπροφήτευσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4395",
    gloss: "to prophesy",
  },
  ἐπροφήτευσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4395",
    gloss: "to prophesy",
  },
  Προεφήτευσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4395",
    gloss: "to prophesy",
  },
  προφητεύειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4395",
    gloss: "to prophesy",
  },
  προφητεύητε: {
    parse: "V-PAS-2P｜Verb, present, active, subjunctive, second, plural",
    GN: "G4395",
    gloss: "to prophesy",
  },
  προφητεύομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G4395",
    gloss: "to prophesy",
  },
  προφητεύουσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G4395",
    gloss: "to prophesy",
  },
  προφητεύουσαι: {
    parse:
      "V-PAP-NPF｜Verb, present, active, Participle, nominative, plural, feminine",
    GN: "G4395",
    gloss: "to prophesy",
  },
  προφητεῦσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G4395",
    gloss: "to prophesy",
  },
  προφητεύσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G4395",
    gloss: "to prophesy",
  },
  Προφήτευσον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G4395",
    gloss: "to prophesy",
  },
  προφητεύσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G4395",
    gloss: "to prophesy",
  },
  προφητεύων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4395",
    gloss: "to prophesy",
  },
  προφητεύωσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G4395",
    gloss: "to prophesy",
  },
  προφῆται: {
    parse: "N-VPM｜Noun, Vocative or nominative, plural, masculine",
    GN: "G4396",
    gloss: "prophet",
  },
  προφήταις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G4396",
    gloss: "prophet",
  },
  προφήτας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G4396",
    gloss: "prophet",
  },
  προφήτῃ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G4396",
    gloss: "prophet",
  },
  προφήτην: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4396",
    gloss: "prophet",
  },
  Προφήτην: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4396",
    gloss: "prophet",
  },
  προφήτης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4396",
    gloss: "prophet",
  },
  Προφήτης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4396",
    gloss: "prophet",
  },
  προφήτου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G4396",
    gloss: "prophet",
  },
  προφητῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G4396",
    gloss: "prophet",
  },
  προφητικὸν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G4397",
    gloss: "prophetic",
  },
  προφητικῶν: {
    parse: "A-GPF｜Adjective, genitive, plural, feminine",
    GN: "G4397",
    gloss: "prophetic",
  },
  προφῆτιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4398",
    gloss: "prophetess",
  },
  προφῆτις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4398",
    gloss: "prophetess",
  },
  προέφθασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4399",
    gloss: "to come before",
  },
  προεχειρίσατό: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G4400",
    gloss: "to appoint",
  },
  προκεχειρισμένον: {
    parse:
      "V-RPP-ASM｜Verb, perfect, passive, Participle, accusative, singular, masculine",
    GN: "G4400",
    gloss: "to appoint",
  },
  προχειρίσασθαί: {
    parse: "V-AMN｜Verb, aorist, middle, Infinitive",
    GN: "G4400",
    gloss: "to appoint",
  },
  προκεχειροτονημένοις: {
    parse:
      "V-RPP-DPM｜Verb, perfect, passive, Participle, dative, plural, masculine",
    GN: "G4401",
    gloss: "to choose",
  },
  Πρόχορον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G4402",
    gloss: "Prochorus",
  },
  πρύμνα: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4403",
    gloss: "stern",
  },
  πρύμνῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4403",
    gloss: "stern",
  },
  πρύμνης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4403",
    gloss: "stern",
  },
  πρωΐ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4404",
    gloss: "early",
  },
  πρωῒ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4404",
    gloss: "early",
  },
  Πρωῒ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4404",
    gloss: "early",
  },
  Πρωΐας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4405",
    gloss: "early morning",
  },
  πρόϊμον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G4406",
    gloss: "early rain",
  },
  πρωϊνόν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G4407",
    gloss: "morning",
  },
  πρωϊνός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4407",
    gloss: "morning",
  },
  πρῷρα: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4408",
    gloss: "bow",
  },
  πρῴρης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4408",
    gloss: "bow",
  },
  πρωτεύων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4409",
    gloss: "be first",
  },
  πρωτοκαθεδρίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4410",
    gloss: "seat of honor",
  },
  πρωτοκαθεδρίας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G4410",
    gloss: "seat of honor",
  },
  πρωτοκλισίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4411",
    gloss: "place of honor",
  },
  πρωτοκλισίας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G4411",
    gloss: "place of honor",
  },
  πρῶτα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G4413",
    gloss: "best",
  },
  πρώτῃ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G4413",
    gloss: "best",
  },
  πρώτη: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G4413",
    gloss: "best",
  },
  Πρώτη: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G4413",
    gloss: "best",
  },
  πρώτην: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G4413",
    gloss: "best",
  },
  πρώτης: {
    parse: "A-GSF-S｜Adjective, genitive, singular, feminine, Superlative",
    GN: "G4413",
    gloss: "best",
  },
  πρῶτοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4413",
    gloss: "best",
  },
  πρώτοις: {
    parse: "A-DPN｜Adjective, dative, plural, neuter",
    GN: "G4413",
    gloss: "best",
  },
  πρῶτον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G4413",
    gloss: "best",
  },
  Πρῶτον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G4413",
    gloss: "best",
  },
  πρῶτόν: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G4413",
    gloss: "best",
  },
  πρῶτος: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G4413",
    gloss: "best",
  },
  πρῶτός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4413",
    gloss: "best",
  },
  πρώτου: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G4413",
    gloss: "best",
  },
  πρώτους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G4413",
    gloss: "best",
  },
  πρώτῳ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G4413",
    gloss: "best",
  },
  πρώτων: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G4413",
    gloss: "best",
  },
  πρωτοστάτην: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4414",
    gloss: "ringleader",
  },
  πρωτοτόκια: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G4415",
    gloss: "birthright",
  },
  πρωτότοκα: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G4416",
    gloss: "firstborn",
  },
  πρωτότοκον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G4416",
    gloss: "firstborn",
  },
  πρωτότοκος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4416",
    gloss: "firstborn",
  },
  πρωτοτόκων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G4416",
    gloss: "firstborn",
  },
  ἔπταισαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4417",
    gloss: "to stumble",
  },
  πταίει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4417",
    gloss: "to stumble",
  },
  πταίομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G4417",
    gloss: "to stumble",
  },
  πταίσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G4417",
    gloss: "to stumble",
  },
  πταίσητέ: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G4417",
    gloss: "to stumble",
  },
  πτέρναν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4418",
    gloss: "heel",
  },
  πτερύγιον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G4419",
    gloss: "pinnacle",
  },
  πτέρυγας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G4420",
    gloss: "wing",
  },
  πτέρυγες: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G4420",
    gloss: "wing",
  },
  πτερύγων: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G4420",
    gloss: "wing",
  },
  πτηνῶν: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G4421",
    gloss: "bird",
  },
  πτοηθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G4422",
    gloss: "to frighten",
  },
  πτοηθῆτε: {
    parse: "V-APS-2P｜Verb, aorist, passive, subjunctive, second, plural",
    GN: "G4422",
    gloss: "to frighten",
  },
  πτόησιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4423",
    gloss: "fear",
  },
  Πτολεμαΐδα: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G4424",
    gloss: "Ptolemais",
  },
  πτύον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4425",
    gloss: "winnowing fork",
  },
  πτυρόμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G4426",
    gloss: "to frighten",
  },
  πτύσματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4427",
    gloss: "saliva",
  },
  πτύξας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G4428",
    gloss: "to roll up",
  },
  ἔπτυσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4429",
    gloss: "to spit",
  },
  πτύσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G4429",
    gloss: "to spit",
  },
  πτῶμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4430",
    gloss: "corpse",
  },
  πτώματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G4430",
    gloss: "corpse",
  },
  πτῶσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4431",
    gloss: "fall",
  },
  πτῶσις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4431",
    gloss: "fall",
  },
  πτωχείᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4432",
    gloss: "poverty",
  },
  πτωχεία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4432",
    gloss: "poverty",
  },
  πτωχείαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4432",
    gloss: "poverty",
  },
  ἐπτώχευσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4433",
    gloss: "be poor",
  },
  πτωχὰ: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G4434",
    gloss: "poor",
  },
  πτωχὴ: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G4434",
    gloss: "poor",
  },
  πτωχοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4434",
    gloss: "poor",
  },
  πτωχοὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4434",
    gloss: "poor",
  },
  πτωχοῖς: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G4434",
    gloss: "poor",
  },
  πτωχόν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G4434",
    gloss: "poor",
  },
  πτωχὸν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G4434",
    gloss: "poor",
  },
  πτωχὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4434",
    gloss: "poor",
  },
  πτωχούς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G4434",
    gloss: "poor",
  },
  πτωχοὺς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G4434",
    gloss: "poor",
  },
  πτωχῷ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G4434",
    gloss: "poor",
  },
  πτωχῶν: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G4434",
    gloss: "poor",
  },
  πυγμῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4435",
    gloss: "fist",
  },
  πύθωνα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4436",
    gloss: "divining spirit",
  },
  πυκνὰ: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G4437",
    gloss: "often",
  },
  πυκνάς: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G4437",
    gloss: "often",
  },
  πυκνότερον: {
    parse: "A-ASN-C｜Adjective, accusative, singular, neuter, Comparative",
    GN: "G4437",
    gloss: "often",
  },
  πυκτεύω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G4438",
    gloss: "to box",
  },
  πύλαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G4439",
    gloss: "gate",
  },
  πύλας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G4439",
    gloss: "gate",
  },
  πύλῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4439",
    gloss: "gate",
  },
  Πύλῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4439",
    gloss: "gate",
  },
  πύλη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4439",
    gloss: "gate",
  },
  πύλην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4439",
    gloss: "gate",
  },
  πύλης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4439",
    gloss: "gate",
  },
  πυλῶνα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4440",
    gloss: "gate",
  },
  πυλῶνας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G4440",
    gloss: "gate",
  },
  πυλῶνες: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G4440",
    gloss: "gate",
  },
  πυλῶνος: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G4440",
    gloss: "gate",
  },
  πυλώνων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G4440",
    gloss: "gate",
  },
  πυλῶσιν: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G4440",
    gloss: "gate",
  },
  ἐπύθετο: {
    parse:
      "V-2ADI-3S｜Verb, 2nd aorist, Middle deponent, indicative, third, singular",
    GN: "G4441",
    gloss: "to inquire",
  },
  ἐπυνθάνετο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G4441",
    gloss: "to inquire",
  },
  ἐπυνθάνοντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G4441",
    gloss: "to inquire",
  },
  πυθέσθαι: {
    parse: "V-2ADN｜Verb, 2nd aorist, Middle deponent, Infinitive",
    GN: "G4441",
    gloss: "to inquire",
  },
  πυθόμενος: {
    parse:
      "V-2ADP-NSM｜Verb, 2nd aorist, Middle deponent, Participle, nominative, singular, masculine",
    GN: "G4441",
    gloss: "to inquire",
  },
  πυνθάνεσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G4441",
    gloss: "to inquire",
  },
  πυνθάνομαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G4441",
    gloss: "to inquire",
  },
  πῦρ: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4442",
    gloss: "fire",
  },
  Πῦρ: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G4442",
    gloss: "fire",
  },
  πυρί: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G4442",
    gloss: "fire",
  },
  πυρὶ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G4442",
    gloss: "fire",
  },
  πυρός: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4442",
    gloss: "fire",
  },
  πυρὸς: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4442",
    gloss: "fire",
  },
  πυράν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4443",
    gloss: "fire",
  },
  πυρὰν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4443",
    gloss: "fire",
  },
  πύργον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4444",
    gloss: "tower",
  },
  πύργος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4444",
    gloss: "tower",
  },
  πυρέσσουσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G4445",
    gloss: "be feverish",
  },
  πυρέσσουσαν: {
    parse:
      "V-PAP-ASF｜Verb, present, active, Participle, accusative, singular, feminine",
    GN: "G4445",
    gloss: "be feverish",
  },
  πυρετοῖς: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G4446",
    gloss: "fever",
  },
  πυρετός: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4446",
    gloss: "fever",
  },
  πυρετῷ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G4446",
    gloss: "fever",
  },
  πυρίνους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G4447",
    gloss: "fiery",
  },
  πεπυρωμένα: {
    parse:
      "V-RPP-APN｜Verb, perfect, passive, Participle, accusative, plural, neuter",
    GN: "G4448",
    gloss: "to burn",
  },
  πεπυρωμένης: {
    parse:
      "V-RPP-GSF｜Verb, perfect, passive, Participle, genitive, singular, feminine",
    GN: "G4448",
    gloss: "to burn",
  },
  πεπυρωμένον: {
    parse:
      "V-RPP-ASN｜Verb, perfect, passive, Participle, accusative, singular, neuter",
    GN: "G4448",
    gloss: "to burn",
  },
  πυροῦμαι: {
    parse: "V-PPI-1S｜Verb, present, passive, indicative, first, singular",
    GN: "G4448",
    gloss: "to burn",
  },
  πυρούμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G4448",
    gloss: "to burn",
  },
  πυροῦσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G4448",
    gloss: "to burn",
  },
  πυρράζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4449",
    gloss: "be (fiery) red",
  },
  πυρρός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4450",
    gloss: "fiery red",
  },
  πυρώσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4451",
    gloss: "burning",
  },
  πυρώσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4451",
    gloss: "burning",
  },
  ἐπώλησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4453",
    gloss: "to sell",
  },
  ἐπώλουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G4453",
    gloss: "to sell",
  },
  πωλεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4453",
    gloss: "to sell",
  },
  πωλεῖται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G4453",
    gloss: "to sell",
  },
  πωλῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G4453",
    gloss: "to sell",
  },
  πωλήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G4453",
    gloss: "to sell",
  },
  πωλήσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G4453",
    gloss: "to sell",
  },
  πωλησάτω: {
    parse: "V-AAM-3S｜Verb, aorist, active, imperative, third, singular",
    GN: "G4453",
    gloss: "to sell",
  },
  πώλησον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G4453",
    gloss: "to sell",
  },
  πώλησόν: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G4453",
    gloss: "to sell",
  },
  πωλούμενον: {
    parse:
      "V-PPP-ASN｜Verb, present, passive, Participle, accusative, singular, neuter",
    GN: "G4453",
    gloss: "to sell",
  },
  πωλοῦνται: {
    parse: "V-PPI-3P｜Verb, present, passive, indicative, third, plural",
    GN: "G4453",
    gloss: "to sell",
  },
  πωλοῦντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G4453",
    gloss: "to sell",
  },
  πωλοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4453",
    gloss: "to sell",
  },
  πωλούντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G4453",
    gloss: "to sell",
  },
  πωλοῦσιν: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G4453",
    gloss: "to sell",
  },
  πῶλον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4454",
    gloss: "colt",
  },
  πώποτε: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4455",
    gloss: "ever",
  },
  ἐπωρώθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G4456",
    gloss: "to harden",
  },
  ἐπωρώθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G4456",
    gloss: "to harden",
  },
  ἐπώρωσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4456",
    gloss: "to harden",
  },
  πεπωρωμένη: {
    parse:
      "V-RPP-NSF｜Verb, perfect, passive, Participle, nominative, singular, feminine",
    GN: "G4456",
    gloss: "to harden",
  },
  πεπωρωμένην: {
    parse:
      "V-RPP-ASF｜Verb, perfect, passive, Participle, accusative, singular, feminine",
    GN: "G4456",
    gloss: "to harden",
  },
  πωρώσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4457",
    gloss: "hardening",
  },
  πώρωσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4457",
    gloss: "hardening",
  },
  πώρωσις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4457",
    gloss: "hardening",
  },
  πως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4459",
    gloss: "how?!",
  },
  πῶς: { parse: "ADV-I｜Adverb, Interrogative", GN: "G4459", gloss: "how?!" },
  Πῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4459",
    gloss: "how?!",
  },
  Ῥαὰβ: {
    parse: "N-NSF-P｜Noun, nominative, singular, feminine, Person",
    GN: "G4460",
    gloss: "Rahab",
  },
  ῥαββί: { parse: "HEB-T｜Hebrew, Title", GN: "G4461", gloss: "Rabbi" },
  Ῥαββί: { parse: "HEB-T｜Hebrew, Title", GN: "G4461", gloss: "Rabbi" },
  Ῥαββὶ: { parse: "HEB-T｜Hebrew, Title", GN: "G4461", gloss: "Rabbi" },
  Ῥαββουνί: {
    parse: "ARAM｜Aramaic transliterated word (indeclinable)",
    GN: "G4462",
    gloss: "Rabboni",
  },
  ἐραβδίσθην: {
    parse: "V-API-1S｜Verb, aorist, passive, indicative, first, singular",
    GN: "G4463",
    gloss: "to beat with a rod",
  },
  ῥαβδίζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4463",
    gloss: "to beat with a rod",
  },
  ῥάβδον: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4464",
    gloss: "rod",
  },
  ῥάβδος: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4464",
    gloss: "rod",
  },
  ῥάβδου: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4464",
    gloss: "rod",
  },
  ῥάβδῳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4464",
    gloss: "rod",
  },
  ῥαβδοῦχοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G4465",
    gloss: "police",
  },
  ῥαβδούχους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G4465",
    gloss: "police",
  },
  Ῥαγαὺ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G4466",
    gloss: "Reu",
  },
  ῥᾳδιούργημα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4467",
    gloss: "crime",
  },
  ῥᾳδιουργίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4468",
    gloss: "trickery",
  },
  Ῥακά: {
    parse: "ARAM｜Aramaic transliterated word (indeclinable)",
    GN: "G4469",
    gloss: "fool",
  },
  ῥάκους: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4470",
    gloss: "a cloth",
  },
  Ῥαμὰ: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G4471",
    gloss: "Ramah",
  },
  ἐρράντισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4472",
    gloss: "to sprinkle",
  },
  ῥαντίζουσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G4472",
    gloss: "to sprinkle",
  },
  ῥεραντισμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G4472",
    gloss: "to sprinkle",
  },
  ῥαντισμὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4473",
    gloss: "sprinkling",
  },
  ῥαντισμοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G4473",
    gloss: "sprinkling",
  },
  ἐράπισαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4474",
    gloss: "to slap",
  },
  ῥαπίζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4474",
    gloss: "to slap",
  },
  ῥάπισμα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G4475",
    gloss: "slap",
  },
  ῥαπίσμασιν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G4475",
    gloss: "slap",
  },
  ῥαπίσματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G4475",
    gloss: "slap",
  },
  ῥαφίδος: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4476",
    gloss: "needle",
  },
  Ῥαχάβ: {
    parse: "N-GSF-P｜Noun, genitive, singular, feminine, Person",
    GN: "G4477",
    gloss: "Rahab",
  },
  Ῥαχὴλ: {
    parse: "N-NSF-P｜Noun, nominative, singular, feminine, Person",
    GN: "G4478",
    gloss: "Rachel",
  },
  Ῥεβέκκα: {
    parse: "N-NSF-P｜Noun, nominative, singular, feminine, Person",
    GN: "G4479",
    gloss: "Rebekah",
  },
  ῥεδῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G4480",
    gloss: "carriage",
  },
  Ῥαιφάν: {
    parse: "N-GSM-T｜Noun, genitive, singular, masculine, Title",
    GN: "G4481",
    gloss: "Rephan",
  },
  ῥεύσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G4482",
    gloss: "to flow",
  },
  Ῥήγιον: {
    parse: "N-ASN-L｜Noun, accusative, singular, neuter, Location",
    GN: "G4484",
    gloss: "Rhegium",
  },
  ῥῆγμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4485",
    gloss: "destruction",
  },
  ἔρρηξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4486",
    gloss: "to throw violently",
  },
  ῥήγνυνται: {
    parse: "V-PPI-3P｜Verb, present, passive, indicative, third, plural",
    GN: "G4486",
    gloss: "to throw violently",
  },
  ῥήξει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G4486",
    gloss: "to throw violently",
  },
  ῥῆξον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G4486",
    gloss: "to throw violently",
  },
  ῥήξωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G4486",
    gloss: "to throw violently",
  },
  ῥήσσει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4486",
    gloss: "to throw violently",
  },
  ῥῆμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4487",
    gloss: "declaration",
  },
  ῥῆμά: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4487",
    gloss: "declaration",
  },
  ῥήμασιν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G4487",
    gloss: "declaration",
  },
  ῥήματα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G4487",
    gloss: "declaration",
  },
  ῥήματά: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G4487",
    gloss: "declaration",
  },
  ῥήματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G4487",
    gloss: "declaration",
  },
  ῥήματί: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G4487",
    gloss: "declaration",
  },
  ῥήματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4487",
    gloss: "declaration",
  },
  ῥημάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G4487",
    gloss: "declaration",
  },
  Ῥησὰ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G4488",
    gloss: "Rhesa",
  },
  ῥήτορος: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G4489",
    gloss: "spokesman",
  },
  ῥητῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4490",
    gloss: "expressly",
  },
  ῥίζα: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4491",
    gloss: "root",
  },
  ῥίζαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4491",
    gloss: "root",
  },
  ῥίζης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4491",
    gloss: "root",
  },
  ῥιζῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G4491",
    gloss: "root",
  },
  ἐρριζωμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G4492",
    gloss: "to root",
  },
  ῥιπῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4493",
    gloss: "twinkling",
  },
  ῥιπιζομένῳ: {
    parse:
      "V-PPP-DSM｜Verb, present, passive, Participle, dative, singular, masculine",
    GN: "G4494",
    gloss: "to toss about",
  },
  ἐρριμμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G4496",
    gloss: "to throw/lay down",
  },
  ἔρριπται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G4496",
    gloss: "to throw/lay down",
  },
  ἔρριψαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4496",
    gloss: "to throw/lay down",
  },
  ῥιπτούντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G4496",
    gloss: "to throw/lay down",
  },
  ῥίψαν: {
    parse:
      "V-AAP-NSN｜Verb, aorist, active, Participle, nominative, singular, neuter",
    GN: "G4496",
    gloss: "to throw/lay down",
  },
  ῥίψαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G4496",
    gloss: "to throw/lay down",
  },
  ῥίψας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G4496",
    gloss: "to throw/lay down",
  },
  Ῥοβοάμ: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G4497",
    gloss: "Rehoboam",
  },
  Ῥοβοὰμ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G4497",
    gloss: "Rehoboam",
  },
  Ῥόδη: {
    parse: "N-NSF-P｜Noun, nominative, singular, feminine, Person",
    GN: "G4498",
    gloss: "Rhoda",
  },
  Ῥόδον: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G4499",
    gloss: "Rhodes",
  },
  ῥοιζηδὸν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4500",
    gloss: "with a roar",
  },
  ῥομφαίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4501",
    gloss: "sword",
  },
  ῥομφαία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4501",
    gloss: "sword",
  },
  ῥομφαίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4501",
    gloss: "sword",
  },
  Ῥουβὴν: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G4502",
    gloss: "Reuben",
  },
  Ῥούθ: {
    parse: "N-GSF-P｜Noun, genitive, singular, feminine, Person",
    GN: "G4503",
    gloss: "Ruth",
  },
  Ῥοῦφον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G4504",
    gloss: "Rufus",
  },
  Ῥούφου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G4504",
    gloss: "Rufus",
  },
  ῥύμαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G4505",
    gloss: "lane",
  },
  ῥύμας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G4505",
    gloss: "lane",
  },
  ῥύμην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4505",
    gloss: "lane",
  },
  ἐρρύσατο: {
    parse:
      "V-ANI-3S｜Verb, aorist, Middle or Passive deponent, indicative, third, singular",
    GN: "G4506",
    gloss: "to rescue",
  },
  ἐρρύσθην: {
    parse: "V-API-1S｜Verb, aorist, passive, indicative, first, singular",
    GN: "G4506",
    gloss: "to rescue",
  },
  ῥύεσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G4506",
    gloss: "to rescue",
  },
  ῥυόμενον: {
    parse:
      "V-PNP-ASM｜Verb, present, Middle or Passive deponent, Participle, accusative, singular, masculine",
    GN: "G4506",
    gloss: "to rescue",
  },
  ῥυόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G4506",
    gloss: "to rescue",
  },
  ῥῦσαι: {
    parse:
      "V-ADM-2S｜Verb, aorist, Middle deponent, imperative, second, singular",
    GN: "G4506",
    gloss: "to rescue",
  },
  ῥυσάσθω: {
    parse:
      "V-ADM-3S｜Verb, aorist, Middle deponent, imperative, third, singular",
    GN: "G4506",
    gloss: "to rescue",
  },
  ῥύσεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G4506",
    gloss: "to rescue",
  },
  ῥύσεταί: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G4506",
    gloss: "to rescue",
  },
  ῥυσθέντας: {
    parse:
      "V-APP-APM｜Verb, aorist, passive, Participle, accusative, plural, masculine",
    GN: "G4506",
    gloss: "to rescue",
  },
  ῥυσθῶ: {
    parse: "V-APS-1S｜Verb, aorist, passive, subjunctive, first, singular",
    GN: "G4506",
    gloss: "to rescue",
  },
  ῥυσθῶμεν: {
    parse: "V-APS-1P｜Verb, aorist, passive, subjunctive, first, plural",
    GN: "G4506",
    gloss: "to rescue",
  },
  ῥυπαρίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4507",
    gloss: "filth",
  },
  ῥυπαρᾷ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G4508",
    gloss: "filthy",
  },
  ῥυπαρὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4508",
    gloss: "filthy",
  },
  ῥύπου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G4509",
    gloss: "filth",
  },
  ῥυπανθήτω: {
    parse: "V-APM-3S｜Verb, aorist, passive, imperative, third, singular",
    GN: "G4510",
    gloss: "to defile",
  },
  ῥύσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4511",
    gloss: "discharge",
  },
  ῥύσις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4511",
    gloss: "discharge",
  },
  ῥυτίδα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4512",
    gloss: "wrinkle",
  },
  Ῥωμαῖοι: {
    parse:
      "A-NPM-LG｜Adjective, nominative, plural, masculine, Location Gentilic",
    GN: "G4514",
    gloss: "Roman",
  },
  Ῥωμαῖοί: {
    parse:
      "A-NPM-LG｜Adjective, nominative, plural, masculine, Location Gentilic",
    GN: "G4514",
    gloss: "Roman",
  },
  Ῥωμαίοις: {
    parse: "A-DPM-LG｜Adjective, dative, plural, masculine, Location Gentilic",
    GN: "G4514",
    gloss: "Roman",
  },
  Ῥωμαῖον: {
    parse:
      "A-ASM-LG｜Adjective, accusative, singular, masculine, Location Gentilic",
    GN: "G4514",
    gloss: "Roman",
  },
  Ῥωμαῖος: {
    parse:
      "A-NSM-LG｜Adjective, nominative, singular, masculine, Location Gentilic",
    GN: "G4514",
    gloss: "Roman",
  },
  Ῥωμαῖός: {
    parse:
      "A-NSM-LG｜Adjective, nominative, singular, masculine, Location Gentilic",
    GN: "G4514",
    gloss: "Roman",
  },
  Ῥωμαίους: {
    parse:
      "A-APM-LG｜Adjective, accusative, plural, masculine, Location Gentilic",
    GN: "G4514",
    gloss: "Roman",
  },
  Ῥωμαίων: {
    parse:
      "A-GPM-LG｜Adjective, genitive, plural, masculine, Location Gentilic",
    GN: "G4514",
    gloss: "Roman",
  },
  Ῥωμαϊστί: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4515",
    gloss: "in Latin",
  },
  Ῥώμῃ: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G4516",
    gloss: "Rome",
  },
  Ῥώμην: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G4516",
    gloss: "Rome",
  },
  Ῥώμης: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G4516",
    gloss: "Rome",
  },
  ἔρρωσθε: {
    parse: "V-RMM-2P｜Verb, perfect, middle, imperative, second, plural",
    GN: "G4517",
    gloss: "farewell",
  },
  σαβαχθάνι: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G4518",
    gloss: "sabachthani",
  },
  Σαβαὼθ: {
    parse: "N-GPM-T｜Noun, genitive, plural, masculine, Title",
    GN: "G4519",
    gloss: "hosts",
  },
  σαββατισμὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4520",
    gloss: "Sabbath rest",
  },
  σάββασιν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G4521",
    gloss: "Sabbath",
  },
  σάββατα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G4521",
    gloss: "Sabbath",
  },
  σάββατον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4521",
    gloss: "Sabbath",
  },
  Σάββατόν: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4521",
    gloss: "Sabbath",
  },
  σαββάτου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4521",
    gloss: "Sabbath",
  },
  σαββάτῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G4521",
    gloss: "Sabbath",
  },
  σαββάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G4521",
    gloss: "Sabbath",
  },
  σαγήνῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4522",
    gloss: "dragnet",
  },
  Σαδδουκαῖοι: {
    parse: "N-NPM-T｜Noun, nominative, plural, masculine, Title",
    GN: "G4523",
    gloss: "Sadducee",
  },
  Σαδδουκαίους: {
    parse: "N-APM-T｜Noun, accusative, plural, masculine, Title",
    GN: "G4523",
    gloss: "Sadducee",
  },
  Σαδδουκαίων: {
    parse: "N-GPM-T｜Noun, genitive, plural, masculine, Title",
    GN: "G4523",
    gloss: "Sadducee",
  },
  Σαδώκ: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G4524",
    gloss: "Zadok",
  },
  Σαδὼκ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G4524",
    gloss: "Zadok",
  },
  σαίνεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G4525",
    gloss: "to shake",
  },
  σάκκος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4526",
    gloss: "sackcloth",
  },
  σάκκους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G4526",
    gloss: "sackcloth",
  },
  σάκκῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G4526",
    gloss: "sackcloth",
  },
  Σαλὰ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G4527",
    gloss: "Shelah",
  },
  Σαλαθιήλ: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G4528",
    gloss: "Shealtiel",
  },
  Σαλαθιὴλ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G4528",
    gloss: "Shealtiel",
  },
  Σαλαμῖνι: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G4529",
    gloss: "Salamis",
  },
  Σαλείμ: {
    parse: "N-GSN-L｜Noun, genitive, singular, neuter, Location",
    GN: "G4530",
    gloss: "Salim",
  },
  ἐσαλεύθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G4531",
    gloss: "to shake",
  },
  ἐσάλευσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4531",
    gloss: "to shake",
  },
  σαλευθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G4531",
    gloss: "to shake",
  },
  σαλευθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G4531",
    gloss: "to shake",
  },
  σαλευθῶ: {
    parse: "V-APS-1S｜Verb, aorist, passive, subjunctive, first, singular",
    GN: "G4531",
    gloss: "to shake",
  },
  σαλευόμενα: {
    parse:
      "V-PPP-NPN｜Verb, present, passive, Participle, nominative, plural, neuter",
    GN: "G4531",
    gloss: "to shake",
  },
  σαλευόμενον: {
    parse:
      "V-PPP-ASM｜Verb, present, passive, Participle, accusative, singular, masculine",
    GN: "G4531",
    gloss: "to shake",
  },
  σαλευομένων: {
    parse:
      "V-PPP-GPN｜Verb, present, passive, Participle, genitive, plural, neuter",
    GN: "G4531",
    gloss: "to shake",
  },
  σαλεύοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4531",
    gloss: "to shake",
  },
  σαλεῦσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G4531",
    gloss: "to shake",
  },
  σεσαλευμένον: {
    parse:
      "V-RPP-ASN｜Verb, perfect, passive, Participle, accusative, singular, neuter",
    GN: "G4531",
    gloss: "to shake",
  },
  Σαλήμ: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G4532",
    gloss: "Salem",
  },
  Σαλμών: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G4533",
    gloss: "Salmon",
  },
  Σαλμὼν: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G4533",
    gloss: "Salmon",
  },
  Σαλμώνην: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G4534",
    gloss: "Salmone",
  },
  σάλου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G4535",
    gloss: "tossing",
  },
  σάλπιγγα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4536",
    gloss: "trumpet",
  },
  σάλπιγγας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G4536",
    gloss: "trumpet",
  },
  σάλπιγγες: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G4536",
    gloss: "trumpet",
  },
  σάλπιγγι: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4536",
    gloss: "trumpet",
  },
  σάλπιγγος: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4536",
    gloss: "trumpet",
  },
  σάλπιγξ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4536",
    gloss: "trumpet",
  },
  ἐσάλπισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4537",
    gloss: "to sound a trumpet",
  },
  σαλπίζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4537",
    gloss: "to sound a trumpet",
  },
  σαλπίσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G4537",
    gloss: "to sound a trumpet",
  },
  σαλπίσῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G4537",
    gloss: "to sound a trumpet",
  },
  σαλπίσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G4537",
    gloss: "to sound a trumpet",
  },
  σαλπιστῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G4538",
    gloss: "trumpeter",
  },
  Σαλώμη: {
    parse: "N-NSF-P｜Noun, nominative, singular, feminine, Person",
    GN: "G4539",
    gloss: "Salome",
  },
  Σαμαρείᾳ: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G4540",
    gloss: "Samaria",
  },
  Σαμάρεια: {
    parse: "N-NSF-L｜Noun, nominative, singular, feminine, Location",
    GN: "G4540",
    gloss: "Samaria",
  },
  Σαμάρειαν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G4540",
    gloss: "Samaria",
  },
  Σαμαρείας: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G4540",
    gloss: "Samaria",
  },
  Σαμαρῖται: {
    parse: "N-NPM-LG｜Noun, nominative, plural, masculine, Location Gentilic",
    GN: "G4541",
    gloss: "Samaritan",
  },
  Σαμαρίταις: {
    parse: "N-DPM-LG｜Noun, dative, plural, masculine, Location Gentilic",
    GN: "G4541",
    gloss: "Samaritan",
  },
  Σαμαρείτης: {
    parse: "N-NSM-LG｜Noun, nominative, singular, masculine, Location Gentilic",
    GN: "G4541",
    gloss: "Samaritan",
  },
  Σαμαριτῶν: {
    parse: "N-GPM-LG｜Noun, genitive, plural, masculine, Location Gentilic",
    GN: "G4541",
    gloss: "Samaritan",
  },
  Σαμαρίτιδος: {
    parse: "N-GSF-LG｜Noun, genitive, singular, feminine, Location Gentilic",
    GN: "G4542",
    gloss: "Samaria",
  },
  Σαμαρῖτις: {
    parse: "N-NSF-LG｜Noun, nominative, singular, feminine, Location Gentilic",
    GN: "G4542",
    gloss: "Samaria",
  },
  Σαμοθρᾴκην: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G4543",
    gloss: "Samothrace",
  },
  Σάμον: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G4544",
    gloss: "Samos",
  },
  Σαμουὴλ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G4545",
    gloss: "Samuel",
  },
  Σαμψών: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G4546",
    gloss: "Samson",
  },
  σανδάλια: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G4547",
    gloss: "sandal",
  },
  σανδάλιά: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G4547",
    gloss: "sandal",
  },
  σανίσιν: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G4548",
    gloss: "plank",
  },
  Σαοὺλ: {
    parse: "N-VSM-P｜Noun, Vocative or nominative, singular, masculine, Person",
    GN: "G4549",
    gloss: "Saul",
  },
  Σαούλ: {
    parse: "N-VSM-P｜Noun, Vocative or nominative, singular, masculine, Person",
    GN: "G4549",
    gloss: "Saul",
  },
  σαπρὰ: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G4550",
    gloss: "rotten",
  },
  σαπρόν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G4550",
    gloss: "rotten",
  },
  σαπρὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G4550",
    gloss: "rotten",
  },
  σαπρὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4550",
    gloss: "rotten",
  },
  σαπφίρῃ: {
    parse: "N-DSF-P｜Noun, dative, singular, feminine, Person",
    GN: "G4551",
    gloss: "Sapphira",
  },
  σάπφιρος: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4552",
    gloss: "sapphire",
  },
  σαργάνῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4553",
    gloss: "basket",
  },
  Σάρδεις: {
    parse: "N-APF-L｜Noun, accusative, plural, feminine, Location",
    GN: "G4554",
    gloss: "Sardis",
  },
  Σάρδεσιν: {
    parse: "N-DPF-L｜Noun, dative, plural, feminine, Location",
    GN: "G4554",
    gloss: "Sardis",
  },
  σάρδιον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4556",
    gloss: "gem",
  },
  σαρδίῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G4556",
    gloss: "gem",
  },
  σαρδόνυξ: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4557",
    gloss: "sardonyx",
  },
  Σάρεπτα: {
    parse: "N-APN-L｜Noun, accusative, plural, neuter, Location",
    GN: "G4558",
    gloss: "Zarephath",
  },
  σαρκικὰ: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G4559",
    gloss: "fleshly",
  },
  σαρκικῇ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G4559",
    gloss: "fleshly",
  },
  σαρκικοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4559",
    gloss: "fleshly",
  },
  σαρκικοῖς: {
    parse: "A-DPN｜Adjective, dative, plural, neuter",
    GN: "G4559",
    gloss: "fleshly",
  },
  σαρκικῶν: {
    parse: "A-GPF｜Adjective, genitive, plural, feminine",
    GN: "G4559",
    gloss: "fleshly",
  },
  σαρκίναις: {
    parse: "A-DPF｜Adjective, dative, plural, feminine",
    GN: "G4560",
    gloss: "fleshly",
  },
  σαρκίνης: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G4560",
    gloss: "fleshly",
  },
  σαρκίνοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G4560",
    gloss: "fleshly",
  },
  σάρκινός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4560",
    gloss: "fleshly",
  },
  σάρκα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4561",
    gloss: "flesh",
  },
  σάρκας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G4561",
    gloss: "flesh",
  },
  σαρκί: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4561",
    gloss: "flesh",
  },
  σαρκὶ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4561",
    gloss: "flesh",
  },
  σαρκός: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4561",
    gloss: "flesh",
  },
  σαρκὸς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4561",
    gloss: "flesh",
  },
  σαρκῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G4561",
    gloss: "flesh",
  },
  σάρξ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4561",
    gloss: "flesh",
  },
  σὰρξ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4561",
    gloss: "flesh",
  },
  Σεροὺχ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G4562",
    gloss: "Serug",
  },
  σαροῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4563",
    gloss: "to sweep",
  },
  σεσαρωμένον: {
    parse:
      "V-RPP-ASM｜Verb, perfect, passive, Participle, accusative, singular, masculine",
    GN: "G4563",
    gloss: "to sweep",
  },
  Σάρρᾳ: {
    parse: "N-DSF-P｜Noun, dative, singular, feminine, Person",
    GN: "G4564",
    gloss: "Sarah",
  },
  Σάρρα: {
    parse: "N-NSF-P｜Noun, nominative, singular, feminine, Person",
    GN: "G4564",
    gloss: "Sarah",
  },
  Σάρρας: {
    parse: "N-GSF-P｜Noun, genitive, singular, feminine, Person",
    GN: "G4564",
    gloss: "Sarah",
  },
  Σαρῶνα: {
    parse: "N-ASM-L｜Noun, accusative, singular, masculine, Location",
    GN: "G4565",
    gloss: "Sharon",
  },
  Σατανᾷ: {
    parse: "N-DSM-T｜Noun, dative, singular, masculine, Title",
    GN: "G4567",
    gloss: "Satan",
  },
  Σατανᾶ: {
    parse: "N-VSM-T｜Noun, Vocative or nominative, singular, masculine, Title",
    GN: "G4567",
    gloss: "Satan",
  },
  Σατανᾶν: {
    parse: "N-ASM-T｜Noun, accusative, singular, masculine, Title",
    GN: "G4567",
    gloss: "Satan",
  },
  Σατανᾶς: {
    parse: "N-NSM-T｜Noun, nominative, singular, masculine, Title",
    GN: "G4567",
    gloss: "Satan",
  },
  σάτα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G4568",
    gloss: "seah",
  },
  Σαῦλον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G4569",
    gloss: "Saul",
  },
  Σαῦλος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G4569",
    gloss: "Saul",
  },
  Σαύλου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G4569",
    gloss: "Saul",
  },
  Σαύλῳ: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G4569",
    gloss: "Saul",
  },
  ἔσβεσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4570",
    gloss: "to extinguish",
  },
  σβέννυνται: {
    parse: "V-PPI-3P｜Verb, present, passive, indicative, third, plural",
    GN: "G4570",
    gloss: "to extinguish",
  },
  σβέννυται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G4570",
    gloss: "to extinguish",
  },
  σβέννυτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G4570",
    gloss: "to extinguish",
  },
  σβέσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G4570",
    gloss: "to extinguish",
  },
  σβέσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G4570",
    gloss: "to extinguish",
  },
  σεαυτόν: {
    parse: "F-2ASM｜Reflexive pronoun, second, accusative, singular, masculine",
    GN: "G4572",
    gloss: "yourself",
  },
  σεαυτὸν: {
    parse: "F-2ASM｜Reflexive pronoun, second, accusative, singular, masculine",
    GN: "G4572",
    gloss: "yourself",
  },
  σεαυτοῦ: {
    parse: "F-2GSM｜Reflexive pronoun, second, genitive, singular, masculine",
    GN: "G4572",
    gloss: "yourself",
  },
  σεαυτῷ: {
    parse: "F-2DSM｜Reflexive pronoun, second, dative, singular, masculine",
    GN: "G4572",
    gloss: "yourself",
  },
  ἐσεβάσθησαν: {
    parse: "V-ADI-3P｜Verb, aorist, Middle deponent, indicative, third, plural",
    GN: "G4573",
    gloss: "to worship",
  },
  σέβασμα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G4574",
    gloss: "object of worship",
  },
  σεβάσματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G4574",
    gloss: "object of worship",
  },
  Σεβαστῆς: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G4575",
    gloss: "Augustan",
  },
  Σεβαστὸν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G4575",
    gloss: "Augustan",
  },
  Σεβαστοῦ: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G4575",
    gloss: "Augustan",
  },
  σέβεσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G4576",
    gloss: "be devout",
  },
  σέβεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G4576",
    gloss: "be devout",
  },
  σεβομένας: {
    parse:
      "V-PNP-APF｜Verb, present, Middle or Passive deponent, Participle, accusative, plural, feminine",
    GN: "G4576",
    gloss: "be devout",
  },
  σεβομένη: {
    parse:
      "V-PNP-NSF｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, feminine",
    GN: "G4576",
    gloss: "be devout",
  },
  σεβομένοις: {
    parse:
      "V-PNP-DPM｜Verb, present, Middle or Passive deponent, Participle, dative, plural, masculine",
    GN: "G4576",
    gloss: "be devout",
  },
  σεβομένου: {
    parse:
      "V-PNP-GSM｜Verb, present, Middle or Passive deponent, Participle, genitive, singular, masculine",
    GN: "G4576",
    gloss: "be devout",
  },
  σεβομένων: {
    parse:
      "V-PNP-GPM｜Verb, present, Middle or Passive deponent, Participle, genitive, plural, masculine",
    GN: "G4576",
    gloss: "be devout",
  },
  σέβονταί: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G4576",
    gloss: "be devout",
  },
  σειραῖς: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G4577",
    gloss: "chain",
  },
  σεισμοί: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G4578",
    gloss: "earthquake",
  },
  σεισμοὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G4578",
    gloss: "earthquake",
  },
  σεισμὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4578",
    gloss: "earthquake",
  },
  σεισμός: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4578",
    gloss: "earthquake",
  },
  σεισμὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4578",
    gloss: "earthquake",
  },
  σεισμῷ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G4578",
    gloss: "earthquake",
  },
  ἐσείσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G4579",
    gloss: "to shake",
  },
  ἐσείσθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G4579",
    gloss: "to shake",
  },
  σειομένη: {
    parse:
      "V-PPP-NSF｜Verb, present, passive, Participle, nominative, singular, feminine",
    GN: "G4579",
    gloss: "to shake",
  },
  σείσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G4579",
    gloss: "to shake",
  },
  Σεκοῦνδος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G4580",
    gloss: "Secundus",
  },
  Σελεύκειαν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G4581",
    gloss: "Seleucia",
  },
  σελήνῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4582",
    gloss: "moon",
  },
  σελήνη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4582",
    gloss: "moon",
  },
  σελήνης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4582",
    gloss: "moon",
  },
  σεληνιάζεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G4583",
    gloss: "be epileptic",
  },
  σεληνιαζομένους: {
    parse:
      "V-PNP-APM｜Verb, present, Middle or Passive deponent, Participle, accusative, plural, masculine",
    GN: "G4583",
    gloss: "be epileptic",
  },
  Σεμεῒν: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G4584",
    gloss: "Semein",
  },
  σεμίδαλιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4585",
    gloss: "fine flour",
  },
  σεμνά: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G4586",
    gloss: "noble",
  },
  σεμνάς: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G4586",
    gloss: "noble",
  },
  σεμνούς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G4586",
    gloss: "noble",
  },
  σεμνότητα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4587",
    gloss: "dignity",
  },
  σεμνότητι: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4587",
    gloss: "dignity",
  },
  σεμνότητος: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4587",
    gloss: "dignity",
  },
  Σεργίῳ: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G4588",
    gloss: "Sergius",
  },
  Σὴθ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G4589",
    gloss: "Seth",
  },
  Σὴμ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G4590",
    gloss: "Shem",
  },
  ἐσήμανεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4591",
    gloss: "to signify",
  },
  σημαίνων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4591",
    gloss: "to signify",
  },
  σημᾶναι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G4591",
    gloss: "to signify",
  },
  σημεῖα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G4592",
    gloss: "sign",
  },
  σημείοις: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G4592",
    gloss: "sign",
  },
  σημεῖον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4592",
    gloss: "sign",
  },
  σημεῖόν: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G4592",
    gloss: "sign",
  },
  σημείων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G4592",
    gloss: "sign",
  },
  σημειοῦσθε: {
    parse: "V-PMM-2P｜Verb, present, middle, imperative, second, plural",
    GN: "G4593",
    gloss: "to note",
  },
  σήμερον: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4594",
    gloss: "today",
  },
  Σήμερον: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4594",
    gloss: "today",
  },
  σέσηπεν: {
    parse: "V-2RAI-3S｜Verb, 2nd perfect, active, indicative, third, singular",
    GN: "G4595",
    gloss: "to rot",
  },
  σιρικοῦ: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G4596",
    gloss: "silk",
  },
  σὴς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4597",
    gloss: "moth",
  },
  σητόβρωτα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G4598",
    gloss: "moth-eaten",
  },
  σθενώσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G4599",
    gloss: "to strengthen",
  },
  σιαγόνα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4600",
    gloss: "cheek",
  },
  ἐσίγησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4601",
    gloss: "be silent",
  },
  Ἐσίγησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4601",
    gloss: "be silent",
  },
  σεσιγημένου: {
    parse:
      "V-RPP-GSN｜Verb, perfect, passive, Participle, genitive, singular, neuter",
    GN: "G4601",
    gloss: "be silent",
  },
  σιγᾶν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4601",
    gloss: "be silent",
  },
  σιγάτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G4601",
    gloss: "be silent",
  },
  σιγάτωσαν: {
    parse: "V-PAM-3P｜Verb, present, active, imperative, third, plural",
    GN: "G4601",
    gloss: "be silent",
  },
  σιγῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G4601",
    gloss: "be silent",
  },
  σιγήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G4601",
    gloss: "be silent",
  },
  σιγὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4602",
    gloss: "silence",
  },
  σιγῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4602",
    gloss: "silence",
  },
  σιδηρᾷ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G4603",
    gloss: "iron",
  },
  σιδηρᾶν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G4603",
    gloss: "iron",
  },
  σιδηροῦς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G4603",
    gloss: "iron",
  },
  σιδήρου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G4604",
    gloss: "iron",
  },
  Σιδῶνα: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G4605",
    gloss: "Sidon",
  },
  Σιδῶνι: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G4605",
    gloss: "Sidon",
  },
  Σιδῶνος: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G4605",
    gloss: "Sidon",
  },
  Σιδωνίας: {
    parse:
      "A-GSF-LG｜Adjective, genitive, singular, feminine, Location Gentilic",
    GN: "G4606",
    gloss: "Sidonian",
  },
  Σιδωνίοις: {
    parse: "A-DPM-LG｜Adjective, dative, plural, masculine, Location Gentilic",
    GN: "G4606",
    gloss: "Sidonian",
  },
  σικαρίων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G4607",
    gloss: "assassin",
  },
  σίκερα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G4608",
    gloss: "alcoholic drink",
  },
  Σιλᾷ: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G4609",
    gloss: "Silas",
  },
  Σιλᾶν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G4609",
    gloss: "Silas",
  },
  Σιλᾶς: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G4609",
    gloss: "Silas",
  },
  Σιλουανὸς: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G4610",
    gloss: "Silvanus",
  },
  Σιλουανοῦ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G4610",
    gloss: "Silvanus",
  },
  Σιλωὰμ: {
    parse: "N-DSM-L｜Noun, dative, singular, masculine, Location",
    GN: "G4611",
    gloss: "Siloam",
  },
  Σιλωάμ: {
    parse: "N-GSM-L｜Noun, genitive, singular, masculine, Location",
    GN: "G4611",
    gloss: "Siloam",
  },
  σιμικίνθια: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G4612",
    gloss: "apron",
  },
  Σίμων: {
    parse: "N-VSM-P｜Noun, Vocative or nominative, singular, masculine, Person",
    GN: "G4613",
    gloss: "Simon",
  },
  Σίμωνα: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G4613",
    gloss: "Simon",
  },
  Σίμωνά: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G4613",
    gloss: "Simon",
  },
  Σίμωνι: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G4613",
    gloss: "Simon",
  },
  Σίμωνος: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G4613",
    gloss: "Simon",
  },
  Σινᾶ: {
    parse: "N-NSN-L｜Noun, nominative, singular, neuter, Location",
    GN: "G4614",
    gloss: "(Mount )Sinai",
  },
  σινάπεως: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4615",
    gloss: "mustard",
  },
  σινδόνα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4616",
    gloss: "linen",
  },
  σινδόνι: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4616",
    gloss: "linen",
  },
  σινιάσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G4617",
    gloss: "to sift",
  },
  σιτευτόν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G4618",
    gloss: "fattened",
  },
  σιτευτὸν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G4618",
    gloss: "fattened",
  },
  σιτιστὰ: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G4619",
    gloss: "fattened",
  },
  σιτομέτριον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G4620",
    gloss: "grain ration",
  },
  σῖτον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4621",
    gloss: "grain",
  },
  σίτου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G4621",
    gloss: "grain",
  },
  Σιὼν: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G4622",
    gloss: "Zion",
  },
  Σιών: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G4622",
    gloss: "Zion",
  },
  ἐσιώπα: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G4623",
    gloss: "be quiet",
  },
  ἐσιώπων: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G4623",
    gloss: "be quiet",
  },
  Σιώπα: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G4623",
    gloss: "be quiet",
  },
  σιωπήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G4623",
    gloss: "be quiet",
  },
  σιωπήσῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G4623",
    gloss: "be quiet",
  },
  σιωπήσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G4623",
    gloss: "be quiet",
  },
  σιωπήσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G4623",
    gloss: "be quiet",
  },
  σιωπῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4623",
    gloss: "be quiet",
  },
  ἐσκανδαλίζοντο: {
    parse: "V-IPI-3P｜Verb, imperfect, passive, indicative, third, plural",
    GN: "G4624",
    gloss: "to cause to stumble",
  },
  ἐσκανδαλίσθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G4624",
    gloss: "to cause to stumble",
  },
  σκανδαλίζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4624",
    gloss: "to cause to stumble",
  },
  σκανδαλίζεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G4624",
    gloss: "to cause to stumble",
  },
  σκανδαλίζῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G4624",
    gloss: "to cause to stumble",
  },
  σκανδαλίζονται: {
    parse: "V-PPI-3P｜Verb, present, passive, indicative, third, plural",
    GN: "G4624",
    gloss: "to cause to stumble",
  },
  σκανδαλίσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G4624",
    gloss: "to cause to stumble",
  },
  σκανδαλισθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G4624",
    gloss: "to cause to stumble",
  },
  σκανδαλισθήσεσθε: {
    parse: "V-FPI-2P｜Verb, future, passive, indicative, second, plural",
    GN: "G4624",
    gloss: "to cause to stumble",
  },
  σκανδαλισθήσομαι: {
    parse: "V-FPI-1S｜Verb, future, passive, indicative, first, singular",
    GN: "G4624",
    gloss: "to cause to stumble",
  },
  σκανδαλισθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G4624",
    gloss: "to cause to stumble",
  },
  σκανδαλισθῆτε: {
    parse: "V-APS-2P｜Verb, aorist, passive, subjunctive, second, plural",
    GN: "G4624",
    gloss: "to cause to stumble",
  },
  σκανδαλίσω: {
    parse: "V-AAS-1S｜Verb, aorist, active, subjunctive, first, singular",
    GN: "G4624",
    gloss: "to cause to stumble",
  },
  σκανδαλίσωμεν: {
    parse: "V-AAS-1P｜Verb, aorist, active, subjunctive, first, plural",
    GN: "G4624",
    gloss: "to cause to stumble",
  },
  σκάνδαλα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G4625",
    gloss: "stumbling block",
  },
  σκάνδαλον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4625",
    gloss: "stumbling block",
  },
  σκανδάλου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4625",
    gloss: "stumbling block",
  },
  σκανδάλων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G4625",
    gloss: "stumbling block",
  },
  ἔσκαψεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4626",
    gloss: "to dig",
  },
  σκάπτειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4626",
    gloss: "to dig",
  },
  σκάψω: {
    parse: "V-AAS-1S｜Verb, aorist, active, subjunctive, first, singular",
    GN: "G4626",
    gloss: "to dig",
  },
  σκάφην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4627",
    gloss: "lifeboat",
  },
  σκάφης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4627",
    gloss: "lifeboat",
  },
  σκέλη: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G4628",
    gloss: "leg",
  },
  σκεπάσματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G4629",
    gloss: "clothing",
  },
  Σκευᾶ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G4630",
    gloss: "Sceva",
  },
  σκευὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4631",
    gloss: "gear",
  },
  σκεύει: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G4632",
    gloss: "vessel",
  },
  σκεύεσιν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G4632",
    gloss: "vessel",
  },
  σκεύη: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G4632",
    gloss: "vessel",
  },
  σκεῦος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4632",
    gloss: "vessel",
  },
  σκεῦός: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G4632",
    gloss: "vessel",
  },
  σκηναῖς: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G4633",
    gloss: "tent",
  },
  σκηνάς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G4633",
    gloss: "tent",
  },
  σκηνὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G4633",
    gloss: "tent",
  },
  σκηνῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4633",
    gloss: "tent",
  },
  σκηνὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4633",
    gloss: "tent",
  },
  σκηνήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4633",
    gloss: "tent",
  },
  σκηνὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4633",
    gloss: "tent",
  },
  σκηνῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4633",
    gloss: "tent",
  },
  σκηνοπηγία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4634",
    gloss: "Feast|Festival of Booths",
  },
  σκηνοποιοὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G4635",
    gloss: "tentmaker",
  },
  σκήνει: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G4636",
    gloss: "tent",
  },
  σκήνους: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4636",
    gloss: "tent",
  },
  ἐσκήνωσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4637",
    gloss: "to dwell",
  },
  σκηνοῦντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G4637",
    gloss: "to dwell",
  },
  σκηνοῦντες: {
    parse:
      "V-PAP-VPM｜Verb, present, active, Participle, Vocative or nominative, plural, masculine",
    GN: "G4637",
    gloss: "to dwell",
  },
  σκηνώσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G4637",
    gloss: "to dwell",
  },
  σκήνωμα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G4638",
    gloss: "tent",
  },
  σκηνώματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G4638",
    gloss: "tent",
  },
  σκηνώματός: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4638",
    gloss: "tent",
  },
  σκιᾷ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4639",
    gloss: "shadow",
  },
  σκιὰ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4639",
    gloss: "shadow",
  },
  σκιὰν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4639",
    gloss: "shadow",
  },
  Σκιὰν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4639",
    gloss: "shadow",
  },
  ἐσκίρτησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4640",
    gloss: "to leap",
  },
  σκιρτήσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G4640",
    gloss: "to leap",
  },
  σκληροκαρδίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4641",
    gloss: "hardness of heart",
  },
  σκληρόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G4642",
    gloss: "hard",
  },
  Σκληρός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4642",
    gloss: "hard",
  },
  σκληρὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4642",
    gloss: "hard",
  },
  σκληρῶν: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G4642",
    gloss: "hard",
  },
  σκληρότητά: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4643",
    gloss: "hardness",
  },
  Σκληροτράχηλοι: {
    // or nominative
    parse: "A-VPM｜Adjective, Vocative, plural, masculine",
    GN: "G4644",
    gloss: "stiff-necked",
  },
  ἐσκληρύνοντο: {
    parse: "V-IPI-3P｜Verb, imperfect, passive, indicative, third, plural",
    GN: "G4645",
    gloss: "to harden",
  },
  σκληρύνει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4645",
    gloss: "to harden",
  },
  σκληρύνητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G4645",
    gloss: "to harden",
  },
  σκληρυνθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G4645",
    gloss: "to harden",
  },
  σκολιὰ: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G4646",
    gloss: "crooked",
  },
  σκολιᾶς: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G4646",
    gloss: "crooked",
  },
  σκολιοῖς: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G4646",
    gloss: "crooked",
  },
  σκόλοψ: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4647",
    gloss: "thorn",
  },
  σκόπει: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G4648",
    gloss: "to watch out",
  },
  σκοπεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4648",
    gloss: "to watch out",
  },
  σκοπεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G4648",
    gloss: "to watch out",
  },
  σκοποῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4648",
    gloss: "to watch out",
  },
  σκοπούντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G4648",
    gloss: "to watch out",
  },
  σκοπῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4648",
    gloss: "to watch out",
  },
  σκοπὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4649",
    gloss: "goal",
  },
  Ἐσκόρπισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4650",
    gloss: "to scatter",
  },
  σκορπίζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4650",
    gloss: "to scatter",
  },
  σκορπισθῆτε: {
    parse: "V-APS-2P｜Verb, aorist, passive, subjunctive, second, plural",
    GN: "G4650",
    gloss: "to scatter",
  },
  σκορπίοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G4651",
    gloss: "scorpion",
  },
  σκορπίοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G4651",
    gloss: "scorpion",
  },
  σκορπίον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4651",
    gloss: "scorpion",
  },
  σκορπίου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G4651",
    gloss: "scorpion",
  },
  σκορπίων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G4651",
    gloss: "scorpion",
  },
  σκοτεινόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G4652",
    gloss: "dark",
  },
  σκοτεινὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G4652",
    gloss: "dark",
  },
  σκοτίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4653",
    gloss: "darkness",
  },
  σκοτία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4653",
    gloss: "darkness",
  },
  σκοτίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4653",
    gloss: "darkness",
  },
  ἐσκοτίσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G4654",
    gloss: "to darken",
  },
  σκοτισθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G4654",
    gloss: "to darken",
  },
  σκοτισθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G4654",
    gloss: "to darken",
  },
  σκοτισθήτωσαν: {
    parse: "V-APM-3P｜Verb, aorist, passive, imperative, third, plural",
    GN: "G4654",
    gloss: "to darken",
  },
  σκότει: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G4655",
    gloss: "darkness",
  },
  σκότος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4655",
    gloss: "darkness",
  },
  σκότους: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4655",
    gloss: "darkness",
  },
  ἐσκοτώθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G4656",
    gloss: "to darken",
  },
  ἐσκοτωμένη: {
    parse:
      "V-RPP-NSF｜Verb, perfect, passive, Participle, nominative, singular, feminine",
    GN: "G4656",
    gloss: "to darken",
  },
  ἐσκοτωμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G4656",
    gloss: "to darken",
  },
  σκύβαλα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G4657",
    gloss: "garbage",
  },
  Σκύθης: {
    parse: "N-NSM-LG｜Noun, nominative, singular, masculine, Location Gentilic",
    GN: "G4658",
    gloss: "Scythian",
  },
  σκυθρωποί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4659",
    gloss: "sad",
  },
  ἐσκυλμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G4660",
    gloss: "to trouble",
  },
  σκύλλε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G4660",
    gloss: "to trouble",
  },
  σκύλλεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G4660",
    gloss: "to trouble",
  },
  σκύλλου: {
    parse: "V-PPM-2S｜Verb, present, passive, imperative, second, singular",
    GN: "G4660",
    gloss: "to trouble",
  },
  σκῦλα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G4661",
    gloss: "plunder",
  },
  σκωληκόβρωτος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4662",
    gloss: "worm-eated",
  },
  σκώληξ: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4663",
    gloss: "worm",
  },
  σμαραγδίνῳ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G4664",
    gloss: "emerald",
  },
  σμάραγδος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4665",
    gloss: "emerald",
  },
  σμύρναν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4666",
    gloss: "myrrh",
  },
  σμύρνης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4666",
    gloss: "myrrh",
  },
  Σμύρναν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G4667",
    gloss: "Smyrna",
  },
  Σμύρνῃ: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G4667",
    gloss: "Smyrna",
  },
  ἐσμυρνισμένον: {
    parse:
      "V-RPP-ASM｜Verb, perfect, passive, Participle, accusative, singular, masculine",
    GN: "G4669",
    gloss: "to mix with myrrh",
  },
  Σόδομα: {
    parse: "N-NPN-L｜Noun, nominative, plural, neuter, Location",
    GN: "G4670",
    gloss: "Sodom",
  },
  Σοδόμοις: {
    parse: "N-DPN-L｜Noun, dative, plural, neuter, Location",
    GN: "G4670",
    gloss: "Sodom",
  },
  Σοδόμων: {
    parse: "N-GPN-L｜Noun, genitive, plural, neuter, Location",
    GN: "G4670",
    gloss: "Sodom",
  },
  Σολομὼν: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G4672",
    gloss: "Solomon's",
  },
  Σολομῶν: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G4672",
    gloss: "Solomon's",
  },
  Σολομῶνα: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G4672",
    gloss: "Solomon's",
  },
  Σολομῶνος: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G4672",
    gloss: "Solomon's",
  },
  Σολομῶντος: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G4672",
    gloss: "Solomon's",
  },
  σοροῦ: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4673",
    gloss: "bier",
  },
  σὰ: {
    parse:
      "S-2SNPN｜Possessive pronoun, second, singular, nominative, plural, neuter",
    GN: "G4674",
    gloss: "your",
  },
  σά: {
    parse:
      "S-2SNPN｜Possessive pronoun, second, singular, nominative, plural, neuter",
    GN: "G4674",
    gloss: "your",
  },
  σῇ: {
    parse:
      "S-2SDSF｜Possessive pronoun, second, singular, dative, singular, feminine",
    GN: "G4674",
    gloss: "your",
  },
  σὴν: {
    parse:
      "S-2SASF｜Possessive pronoun, second, singular, accusative, singular, feminine",
    GN: "G4674",
    gloss: "your",
  },
  σῆς: {
    parse:
      "S-2SGSF｜Possessive pronoun, second, singular, genitive, singular, feminine",
    GN: "G4674",
    gloss: "your",
  },
  σοὶ: {
    parse: "P-2DS｜Personal pronoun, second, dative, singular",
    GN: "G4771",
    gloss: "you",
  },
  σόν: {
    parse:
      "S-2SASN｜Possessive pronoun, second, singular, accusative, singular, neuter",
    GN: "G4674",
    gloss: "your",
  },
  σὸν: {
    parse:
      "S-2SNSN｜Possessive pronoun, second, singular, nominative, singular, neuter",
    GN: "G4674",
    gloss: "your",
  },
  σὸς: {
    parse:
      "S-2SNSM｜Possessive pronoun, second, singular, nominative, singular, masculine",
    GN: "G4674",
    gloss: "your",
  },
  σούς: {
    parse:
      "S-2SAPM｜Possessive pronoun, second, singular, accusative, plural, masculine",
    GN: "G4674",
    gloss: "your",
  },
  σῷ: {
    parse:
      "S-2SDSN｜Possessive pronoun, second, singular, dative, singular, neuter",
    GN: "G4674",
    gloss: "your",
  },
  σουδάρια: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G4676",
    gloss: "handkerchief",
  },
  σουδάριον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G4676",
    gloss: "handkerchief",
  },
  σουδαρίῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G4676",
    gloss: "handkerchief",
  },
  Σουσάννα: {
    parse: "N-NSF-P｜Noun, nominative, singular, feminine, Person",
    GN: "G4677",
    gloss: "Susanna",
  },
  σοφίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4678",
    gloss: "wisdom",
  },
  σοφία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4678",
    gloss: "wisdom",
  },
  σοφίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4678",
    gloss: "wisdom",
  },
  Σοφίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4678",
    gloss: "wisdom",
  },
  σοφίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4678",
    gloss: "wisdom",
  },
  σεσοφισμένοις: {
    parse:
      "V-RPP-DPM｜Verb, perfect, passive, Participle, dative, plural, masculine",
    GN: "G4679",
    gloss: "to make wise",
  },
  σοφίσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G4679",
    gloss: "to make wise",
  },
  σοφοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4680",
    gloss: "wise",
  },
  σοφοὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4680",
    gloss: "wise",
  },
  σοφοῖς: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G4680",
    gloss: "wise",
  },
  σοφός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4680",
    gloss: "wise",
  },
  σοφὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4680",
    gloss: "wise",
  },
  σοφούς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G4680",
    gloss: "wise",
  },
  σοφοὺς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G4680",
    gloss: "wise",
  },
  σοφῷ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G4680",
    gloss: "wise",
  },
  σοφῶν: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G4680",
    gloss: "wise",
  },
  σοφώτερον: {
    parse: "A-NSN-C｜Adjective, nominative, singular, neuter, Comparative",
    GN: "G4680",
    gloss: "wise",
  },
  Σπανίαν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G4681",
    gloss: "Spain",
  },
  σπαράξαν: {
    parse:
      "V-AAP-NSN｜Verb, aorist, active, Participle, nominative, singular, neuter",
    GN: "G4682",
    gloss: "to convulse",
  },
  σπαράξας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G4682",
    gloss: "to convulse",
  },
  σπαράσσει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4682",
    gloss: "to convulse",
  },
  ἐσπαργανωμένον: {
    parse:
      "V-RPP-ASN｜Verb, perfect, passive, Participle, accusative, singular, neuter",
    GN: "G4683",
    gloss: "to wrap",
  },
  ἐσπαργάνωσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4683",
    gloss: "to wrap",
  },
  ἐσπαταλήσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G4684",
    gloss: "to indulge",
  },
  σπαταλῶσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G4684",
    gloss: "to indulge",
  },
  σπασάμενος: {
    parse:
      "V-AMP-NSM｜Verb, aorist, middle, Participle, nominative, singular, masculine",
    GN: "G4685",
    gloss: "to draw",
  },
  σπεῖρα: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4686",
    gloss: "band",
  },
  σπεῖραν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4686",
    gloss: "band",
  },
  σπείρης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4686",
    gloss: "band",
  },
  ἐσπαρμένον: {
    parse:
      "V-2RPP-ASN｜Verb, 2nd perfect, passive, Participle, accusative, singular, neuter",
    GN: "G4687",
    gloss: "to sow",
  },
  ἔσπειρα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G4687",
    gloss: "to sow",
  },
  ἐσπείραμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G4687",
    gloss: "to sow",
  },
  ἔσπειρας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G4687",
    gloss: "to sow",
  },
  ἔσπειρεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4687",
    gloss: "to sow",
  },
  σπαρείς: {
    parse:
      "V-2APP-NSM｜Verb, 2nd aorist, passive, Participle, nominative, singular, masculine",
    GN: "G4687",
    gloss: "to sow",
  },
  σπαρέντες: {
    parse:
      "V-2APP-NPM｜Verb, 2nd aorist, passive, Participle, nominative, plural, masculine",
    GN: "G4687",
    gloss: "to sow",
  },
  σπαρῇ: {
    parse: "V-2APS-3S｜Verb, 2nd aorist, passive, subjunctive, third, singular",
    GN: "G4687",
    gloss: "to sow",
  },
  σπεῖραι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G4687",
    gloss: "to sow",
  },
  σπείραντι: {
    parse:
      "V-AAP-DSM｜Verb, aorist, active, Participle, dative, singular, masculine",
    GN: "G4687",
    gloss: "to sow",
  },
  σπείραντος: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G4687",
    gloss: "to sow",
  },
  σπείρας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G4687",
    gloss: "to sow",
  },
  σπείρει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4687",
    gloss: "to sow",
  },
  σπείρειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4687",
    gloss: "to sow",
  },
  σπείρεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G4687",
    gloss: "to sow",
  },
  σπείρεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G4687",
    gloss: "to sow",
  },
  σπείρῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G4687",
    gloss: "to sow",
  },
  σπειρόμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G4687",
    gloss: "to sow",
  },
  σπείροντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G4687",
    gloss: "to sow",
  },
  σπείρουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G4687",
    gloss: "to sow",
  },
  σπείρων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4687",
    gloss: "to sow",
  },
  σπεκουλάτορα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4688",
    gloss: "executioner",
  },
  σπένδομαι: {
    parse: "V-PPI-1S｜Verb, present, passive, indicative, first, singular",
    GN: "G4689",
    gloss: "to pour a libation",
  },
  σπέρμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4690",
    gloss: "seeds",
  },
  Σπέρμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4690",
    gloss: "seeds",
  },
  σπέρμασιν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G4690",
    gloss: "seeds",
  },
  σπέρματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G4690",
    gloss: "seeds",
  },
  σπέρματί: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G4690",
    gloss: "seeds",
  },
  σπέρματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4690",
    gloss: "seeds",
  },
  σπερμάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G4690",
    gloss: "seeds",
  },
  σπερμολόγος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4691",
    gloss: "babbler",
  },
  ἔσπευδεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G4692",
    gloss: "to hasten",
  },
  σπεύδοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G4692",
    gloss: "to hasten",
  },
  σπεύσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G4692",
    gloss: "to hasten",
  },
  σπεύσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G4692",
    gloss: "to hasten",
  },
  Σπεῦσον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G4692",
    gloss: "to hasten",
  },
  σπήλαια: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G4693",
    gloss: "cave",
  },
  σπηλαίοις: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G4693",
    gloss: "cave",
  },
  σπήλαιον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4693",
    gloss: "cave",
  },
  σπιλάδες: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G4694",
    gloss: "reef",
  },
  ἐσπιλωμένον: {
    parse:
      "V-RPP-ASM｜Verb, perfect, passive, Participle, accusative, singular, masculine",
    GN: "G4695",
    gloss: "to stain",
  },
  σπιλοῦσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G4695",
    gloss: "to stain",
  },
  σπίλοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G4696",
    gloss: "stain",
  },
  σπίλον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4696",
    gloss: "stain",
  },
  ἐσπλαγχνίσθη: {
    parse:
      "V-AOI-3S｜Verb, aorist, Passive deponent, indicative, third, singular",
    GN: "G4697",
    gloss: "to pity",
  },
  Σπλαγχνίζομαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G4697",
    gloss: "to pity",
  },
  σπλαγχνισθεὶς: {
    parse:
      "V-AOP-NSM｜Verb, aorist, Passive deponent, Participle, nominative, singular, masculine",
    GN: "G4697",
    gloss: "to pity",
  },
  σπλάγχνα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G4698",
    gloss: "affection/entrails",
  },
  σπλάγχνοις: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G4698",
    gloss: "affection/entrails",
  },
  σπόγγον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4699",
    gloss: "sponge",
  },
  σποδὸς: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4700",
    gloss: "ashes",
  },
  σποδῷ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4700",
    gloss: "ashes",
  },
  σπορᾶς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4701",
    gloss: "seed",
  },
  σπορίμων: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G4702",
    gloss: "grainfield",
  },
  σπόρον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4703",
    gloss: "seed",
  },
  σπόρος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4703",
    gloss: "seed",
  },
  ἐσπούδασα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G4704",
    gloss: "be eager",
  },
  ἐσπουδάσαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G4704",
    gloss: "be eager",
  },
  σπουδάζοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4704",
    gloss: "be eager",
  },
  σπουδάσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G4704",
    gloss: "be eager",
  },
  σπούδασον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G4704",
    gloss: "be eager",
  },
  Σπούδασον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G4704",
    gloss: "be eager",
  },
  σπουδάσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G4704",
    gloss: "be eager",
  },
  σπουδάσωμεν: {
    parse: "V-AAS-1P｜Verb, aorist, active, subjunctive, first, plural",
    GN: "G4704",
    gloss: "be eager",
  },
  σπουδαῖον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G4705",
    gloss: "eager",
  },
  σπουδαιότερον: {
    parse: "A-ASM-C｜Adjective, accusative, singular, masculine, Comparative",
    GN: "G4705",
    gloss: "eager",
  },
  σπουδαιότερος: {
    parse: "A-NSM-C｜Adjective, nominative, singular, masculine, Comparative",
    GN: "G4705",
    gloss: "eager",
  },
  σπουδαιοτέρως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4708",
    gloss: "more eagerly",
  },
  σπουδαίως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4709",
    gloss: "diligently",
  },
  σπουδῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4710",
    gloss: "diligence",
  },
  σπουδήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4710",
    gloss: "diligence",
  },
  σπουδὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4710",
    gloss: "diligence",
  },
  σπουδῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4710",
    gloss: "diligence",
  },
  σπυρίδας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G4711",
    gloss: "basket",
  },
  σπυρίδι: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4711",
    gloss: "basket",
  },
  σπυρίδων: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G4711",
    gloss: "basket",
  },
  σταδίους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G4712",
    gloss: "stadium",
  },
  σταδίῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G4712",
    gloss: "stadium",
  },
  σταδίων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G4712",
    gloss: "stadium",
  },
  στάμνος: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4713",
    gloss: "jar",
  },
  στάσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4714",
    gloss: "uprising",
  },
  στάσεις: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G4714",
    gloss: "uprising",
  },
  στάσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4714",
    gloss: "uprising",
  },
  στάσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4714",
    gloss: "uprising",
  },
  στάσις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4714",
    gloss: "uprising",
  },
  στατῆρα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4715",
    gloss: "coin",
  },
  σταυρὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4716",
    gloss: "cross",
  },
  σταυρὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4716",
    gloss: "cross",
  },
  σταυροῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G4716",
    gloss: "cross",
  },
  σταυρῷ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G4716",
    gloss: "cross",
  },
  ἐσταυρώθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G4717",
    gloss: "to crucify",
  },
  ἐσταυρωμένον: {
    parse:
      "V-RPP-ASM｜Verb, perfect, passive, Participle, accusative, singular, masculine",
    GN: "G4717",
    gloss: "to crucify",
  },
  ἐσταυρωμένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G4717",
    gloss: "to crucify",
  },
  ἐσταύρωσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4717",
    gloss: "to crucify",
  },
  ἐσταυρώσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G4717",
    gloss: "to crucify",
  },
  ἐσταύρωται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G4717",
    gloss: "to crucify",
  },
  σταύρου: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G4717",
    gloss: "to crucify",
  },
  Σταύρου: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G4717",
    gloss: "to crucify",
  },
  σταυροῦνται: {
    parse: "V-PPI-3P｜Verb, present, passive, indicative, third, plural",
    GN: "G4717",
    gloss: "to crucify",
  },
  σταυροῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G4717",
    gloss: "to crucify",
  },
  σταυρωθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G4717",
    gloss: "to crucify",
  },
  σταυρωθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G4717",
    gloss: "to crucify",
  },
  Σταυρωθήτω: {
    parse: "V-APM-3S｜Verb, aorist, passive, imperative, third, singular",
    GN: "G4717",
    gloss: "to crucify",
  },
  σταυρῶσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G4717",
    gloss: "to crucify",
  },
  σταυρῶσαί: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G4717",
    gloss: "to crucify",
  },
  σταυρώσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G4717",
    gloss: "to crucify",
  },
  σταυρώσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G4717",
    gloss: "to crucify",
  },
  σταυρώσετε: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G4717",
    gloss: "to crucify",
  },
  σταύρωσον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G4717",
    gloss: "to crucify",
  },
  Σταύρωσον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G4717",
    gloss: "to crucify",
  },
  σταυρώσω: {
    parse: "V-AAS-1S｜Verb, aorist, active, subjunctive, first, singular",
    GN: "G4717",
    gloss: "to crucify",
  },
  σταυρώσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G4717",
    gloss: "to crucify",
  },
  σταφυλαὶ: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G4718",
    gloss: "grapes",
  },
  σταφυλὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G4718",
    gloss: "grapes",
  },
  σταφυλὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4718",
    gloss: "grapes",
  },
  στάχυας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G4719",
    gloss: "head of grain",
  },
  στάχυϊ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G4719",
    gloss: "head of grain",
  },
  στάχυν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4719",
    gloss: "head of grain",
  },
  Στάχυν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G4720",
    gloss: "Stachys",
  },
  στέγην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4721",
    gloss: "roof",
  },
  στέγει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4722",
    gloss: "to endure",
  },
  στέγομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G4722",
    gloss: "to endure",
  },
  στέγοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4722",
    gloss: "to endure",
  },
  στέγων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4722",
    gloss: "to endure",
  },
  στείρᾳ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G4723",
    gloss: "barren",
  },
  στεῖρα: {
    parse: "A-VSF｜Adjective, Vocative or nominative, singular, feminine",
    GN: "G4723",
    gloss: "barren",
  },
  στεῖραι: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G4723",
    gloss: "barren",
  },
  στέλλεσθαι: {
    parse: "V-PMN｜Verb, present, middle, Infinitive",
    GN: "G4724",
    gloss: "to avoid",
  },
  στελλόμενοι: {
    parse:
      "V-PMP-NPM｜Verb, present, middle, Participle, nominative, plural, masculine",
    GN: "G4724",
    gloss: "to avoid",
  },
  στέμματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G4725",
    gloss: "wreath",
  },
  στεναγμοῖς: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G4726",
    gloss: "groan",
  },
  στεναγμοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G4726",
    gloss: "groan",
  },
  ἐστέναξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4727",
    gloss: "to groan",
  },
  στενάζετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G4727",
    gloss: "to groan",
  },
  στενάζομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G4727",
    gloss: "to groan",
  },
  στενάζοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4727",
    gloss: "to groan",
  },
  στενὴ: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G4728",
    gloss: "narrow",
  },
  στενῆς: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G4728",
    gloss: "narrow",
  },
  στενοχωρεῖσθε: {
    parse: "V-PPI-2P｜Verb, present, passive, indicative, second, plural",
    GN: "G4729",
    gloss: "to press upon",
  },
  στενοχωρούμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G4729",
    gloss: "to press upon",
  },
  στενοχωρία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4730",
    gloss: "hardship",
  },
  στενοχωρίαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G4730",
    gloss: "hardship",
  },
  στερεὰ: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G4731",
    gloss: "strong",
  },
  στερεᾶς: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G4731",
    gloss: "strong",
  },
  στερεοὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4731",
    gloss: "strong",
  },
  στερεὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4731",
    gloss: "strong",
  },
  ἐστερεοῦντο: {
    parse: "V-IPI-3P｜Verb, imperfect, passive, indicative, third, plural",
    GN: "G4732",
    gloss: "to strengthen",
  },
  ἐστερεώθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G4732",
    gloss: "to strengthen",
  },
  ἐστερέωσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4732",
    gloss: "to strengthen",
  },
  στερέωμα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G4733",
    gloss: "firmness",
  },
  Στεφανᾶ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G4734",
    gloss: "Stephanas",
  },
  στέφανοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G4735",
    gloss: "crown",
  },
  στέφανον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4735",
    gloss: "crown",
  },
  Στέφανον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G4736",
    gloss: "Stephen",
  },
  στέφανόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4735",
    gloss: "crown",
  },
  στέφανος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4735",
    gloss: "crown",
  },
  στέφανός: {
    parse: "N-VSM｜Noun, Vocative or nominative, singular, masculine",
    GN: "G4735",
    gloss: "crown",
  },
  στεφάνους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G4735",
    gloss: "crown",
  },
  Στέφανος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G4736",
    gloss: "Stephen",
  },
  Στεφάνου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G4736",
    gloss: "Stephen",
  },
  Στεφάνῳ: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G4736",
    gloss: "Stephen",
  },
  ἐστεφανωμένον: {
    parse:
      "V-RPP-ASM｜Verb, perfect, passive, Participle, accusative, singular, masculine",
    GN: "G4737",
    gloss: "to crown",
  },
  ἐστεφάνωσας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G4737",
    gloss: "to crown",
  },
  στεφανοῦται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G4737",
    gloss: "to crown",
  },
  στήθη: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G4738",
    gloss: "chest",
  },
  στῆθος: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G4738",
    gloss: "chest",
  },
  στήκει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4739",
    gloss: "to stand",
  },
  στήκετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G4739",
    gloss: "to stand",
  },
  στήκοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4739",
    gloss: "to stand",
  },
  στηριγμοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G4740",
    gloss: "security",
  },
  ἐστηριγμένους: {
    parse:
      "V-RPP-APM｜Verb, perfect, passive, Participle, accusative, plural, masculine",
    GN: "G4741",
    gloss: "to establish",
  },
  ἐστήρικται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G4741",
    gloss: "to establish",
  },
  ἐστήρισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4741",
    gloss: "to establish",
  },
  στηρίξαι: {
    parse: "V-AAO-3S｜Verb, aorist, active, Optative, third, singular",
    GN: "G4741",
    gloss: "to establish",
  },
  στηρίξατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G4741",
    gloss: "to establish",
  },
  στηρίξει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G4741",
    gloss: "to establish",
  },
  στήρισον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G4741",
    gloss: "to establish",
  },
  στηριχθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G4741",
    gloss: "to establish",
  },
  στίγματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G4742",
    gloss: "mark",
  },
  στιγμῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4743",
    gloss: "instant",
  },
  στίλβοντα: {
    parse:
      "V-PAP-NPN｜Verb, present, active, Participle, nominative, plural, neuter",
    GN: "G4744",
    gloss: "to shine",
  },
  στοᾷ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4745",
    gloss: " Portico",
  },
  Στοᾷ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4745",
    gloss: " Portico",
  },
  στοὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G4745",
    gloss: " Portico",
  },
  στιβάδας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G4746",
    gloss: "leafy branch",
  },
  στοιχεῖα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G4747",
    gloss: "principle",
  },
  στοιχείων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G4747",
    gloss: "principle",
  },
  στοιχεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4748",
    gloss: "to follow",
  },
  στοιχεῖς: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G4748",
    gloss: "to follow",
  },
  στοιχήσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G4748",
    gloss: "to follow",
  },
  στοιχοῦσιν: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G4748",
    gloss: "to follow",
  },
  στοιχῶμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G4748",
    gloss: "to follow",
  },
  στολαῖς: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G4749",
    gloss: "robe",
  },
  στολὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G4749",
    gloss: "robe",
  },
  στολὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4749",
    gloss: "robe",
  },
  στολὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4749",
    gloss: "robe",
  },
  στόμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4750",
    gloss: "mouth",
  },
  στόματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G4750",
    gloss: "mouth",
  },
  στόματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G4750",
    gloss: "mouth",
  },
  στόματί: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G4750",
    gloss: "mouth",
  },
  στόματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4750",
    gloss: "mouth",
  },
  στόματός: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4750",
    gloss: "mouth",
  },
  στομάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G4750",
    gloss: "mouth",
  },
  στόμαχον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4751",
    gloss: "stomach",
  },
  στρατείαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4752",
    gloss: "warfare",
  },
  στρατείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4752",
    gloss: "warfare",
  },
  στράτευμα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G4753",
    gloss: "troops",
  },
  στρατεύμασιν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G4753",
    gloss: "troops",
  },
  στρατεύματα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G4753",
    gloss: "troops",
  },
  στρατεύματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G4753",
    gloss: "troops",
  },
  στρατεύματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4753",
    gloss: "troops",
  },
  στρατευμάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G4753",
    gloss: "troops",
  },
  στρατεύεται: {
    parse: "V-PMI-3S｜Verb, present, middle, indicative, third, singular",
    GN: "G4754",
    gloss: "to battle",
  },
  στρατεύῃ: {
    parse: "V-PMS-2S｜Verb, present, middle, subjunctive, second, singular",
    GN: "G4754",
    gloss: "to battle",
  },
  στρατευόμεθα: {
    parse: "V-PMI-1P｜Verb, present, middle, indicative, first, plural",
    GN: "G4754",
    gloss: "to battle",
  },
  στρατευόμενοι: {
    parse:
      "V-PMP-NPM｜Verb, present, middle, Participle, nominative, plural, masculine",
    GN: "G4754",
    gloss: "to battle",
  },
  στρατευόμενος: {
    parse:
      "V-PMP-NSM｜Verb, present, middle, Participle, nominative, singular, masculine",
    GN: "G4754",
    gloss: "to battle",
  },
  στρατευομένων: {
    parse:
      "V-PMP-GPF｜Verb, present, middle, Participle, genitive, plural, feminine",
    GN: "G4754",
    gloss: "to battle",
  },
  στρατεύονται: {
    parse: "V-PMI-3P｜Verb, present, middle, indicative, third, plural",
    GN: "G4754",
    gloss: "to battle",
  },
  στρατηγοὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G4755",
    gloss: "officer/magistrate",
  },
  στρατηγοῖς: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G4755",
    gloss: "officer/magistrate",
  },
  στρατηγὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4755",
    gloss: "officer/magistrate",
  },
  στρατηγοὺς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G4755",
    gloss: "officer/magistrate",
  },
  στρατιᾷ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4756",
    gloss: "army",
  },
  στρατιᾶς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4756",
    gloss: "army",
  },
  στρατιῶται: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G4757",
    gloss: "soldier",
  },
  στρατιώταις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G4757",
    gloss: "soldier",
  },
  στρατιώτας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G4757",
    gloss: "soldier",
  },
  στρατιώτῃ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G4757",
    gloss: "soldier",
  },
  στρατιώτην: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4757",
    gloss: "soldier",
  },
  στρατιώτης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4757",
    gloss: "soldier",
  },
  στρατιωτῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G4757",
    gloss: "soldier",
  },
  στρατολογήσαντι: {
    parse:
      "V-AAP-DSM｜Verb, aorist, active, Participle, dative, singular, masculine",
    GN: "G4758",
    gloss: "to enlist",
  },
  στρατοπέδων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G4760",
    gloss: "army camp",
  },
  στρεβλοῦσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G4761",
    gloss: "to distort",
  },
  ἐστράφη: {
    parse: "V-2API-3S｜Verb, 2nd aorist, passive, indicative, third, singular",
    GN: "G4762",
    gloss: "to turn",
  },
  ἐστράφησαν: {
    parse: "V-2API-3P｜Verb, 2nd aorist, passive, indicative, third, plural",
    GN: "G4762",
    gloss: "to turn",
  },
  ἔστρεψεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G4762",
    gloss: "to turn",
  },
  στραφεὶς: {
    parse:
      "V-2APP-NSM｜Verb, 2nd aorist, passive, Participle, nominative, singular, masculine",
    GN: "G4762",
    gloss: "to turn",
  },
  στραφεῖσα: {
    parse:
      "V-2APP-NSF｜Verb, 2nd aorist, passive, Participle, nominative, singular, feminine",
    GN: "G4762",
    gloss: "to turn",
  },
  στραφέντες: {
    parse:
      "V-2APP-NPM｜Verb, 2nd aorist, passive, Participle, nominative, plural, masculine",
    GN: "G4762",
    gloss: "to turn",
  },
  στραφῆτε: {
    parse: "V-2APS-2P｜Verb, 2nd aorist, passive, subjunctive, second, plural",
    GN: "G4762",
    gloss: "to turn",
  },
  στραφῶσιν: {
    parse: "V-2APS-3P｜Verb, 2nd aorist, passive, subjunctive, third, plural",
    GN: "G4762",
    gloss: "to turn",
  },
  στρέφειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4762",
    gloss: "to turn",
  },
  στρεφόμεθα: {
    parse: "V-PPI-1P｜Verb, present, passive, indicative, first, plural",
    GN: "G4762",
    gloss: "to turn",
  },
  στρέψον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G4762",
    gloss: "to turn",
  },
  ἐστρηνίασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4763",
    gloss: "to revel",
  },
  στρηνιάσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G4763",
    gloss: "to revel",
  },
  στρήνους: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4764",
    gloss: "luxury",
  },
  στρουθία: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G4765",
    gloss: "sparrow",
  },
  στρουθίων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G4765",
    gloss: "sparrow",
  },
  ἐστρωμένον: {
    parse:
      "V-RPP-ASN｜Verb, perfect, passive, Participle, accusative, singular, neuter",
    GN: "G4766",
    gloss: "to spread",
  },
  ἐστρώννυον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G4766",
    gloss: "to spread",
  },
  ἔστρωσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4766",
    gloss: "to spread",
  },
  στρῶσον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G4766",
    gloss: "to spread",
  },
  στυγητοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4767",
    gloss: "hated",
  },
  στυγνάζων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4768",
    gloss: "be gloomy",
  },
  στυγνάσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G4768",
    gloss: "be gloomy",
  },
  στῦλοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G4769",
    gloss: "pillar",
  },
  στῦλον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4769",
    gloss: "pillar",
  },
  στῦλος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4769",
    gloss: "pillar",
  },
  Στοϊκῶν: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G4770",
    gloss: "Stoic",
  },
  σε: {
    parse: "P-2AS｜Personal pronoun, second, accusative, singular",
    GN: "G4771",
    gloss: "you",
  },
  σέ: {
    parse: "P-2AS｜Personal pronoun, second, accusative, singular",
    GN: "G4771",
    gloss: "you",
  },
  σὲ: {
    parse: "P-2AS｜Personal pronoun, second, accusative, singular",
    GN: "G4771",
    gloss: "you",
  },
  σοι: {
    parse: "P-2DS｜Personal pronoun, second, dative, singular",
    GN: "G4771",
    gloss: "you",
  },
  σοί: {
    parse: "P-2DS｜Personal pronoun, second, dative, singular",
    GN: "G4771",
    gloss: "you",
  },
  Σοὶ: {
    parse: "P-2DS｜Personal pronoun, second, dative, singular",
    GN: "G4771",
    gloss: "you",
  },
  σου: {
    parse: "P-2GS｜Personal pronoun, second, genitive, singular",
    GN: "G4771",
    gloss: "you",
  },
  σού: {
    parse: "P-2GS｜Personal pronoun, second, genitive, singular",
    GN: "G4771",
    gloss: "you",
  },
  σοῦ: {
    parse: "P-2GS｜Personal pronoun, second, genitive, singular",
    GN: "G4771",
    gloss: "you",
  },
  σύ: {
    parse: "P-2NS｜Personal pronoun, second, nominative, singular",
    GN: "G4771",
    gloss: "you",
  },
  σὺ: {
    parse: "P-2NS｜Personal pronoun, second, nominative, singular",
    GN: "G4771",
    gloss: "you",
  },
  Σὺ: {
    parse: "P-2NS｜Personal pronoun, second, nominative, singular",
    GN: "G4771",
    gloss: "you",
  },
  ὑμᾶς: {
    parse: "P-2AP｜Personal pronoun, second, accusative, plural",
    GN: "G4771",
    gloss: "you",
  },
  ὑμεῖς: {
    parse: "P-2NP｜Personal pronoun, second, nominative, plural",
    GN: "G4771",
    gloss: "you",
  },
  Ὑμεῖς: {
    parse: "P-2NP｜Personal pronoun, second, nominative, plural",
    GN: "G4771",
    gloss: "you",
  },
  ὑμῖν: {
    parse: "P-2DP｜Personal pronoun, second, dative, plural",
    GN: "G4771",
    gloss: "you",
  },
  Ὑμῖν: {
    parse: "P-2DP｜Personal pronoun, second, dative, plural",
    GN: "G4771",
    gloss: "you",
  },
  ὑμῶν: {
    parse: "P-2GP｜Personal pronoun, second, genitive, plural",
    GN: "G4771",
    gloss: "you",
  },
  συγγένειαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4772",
    gloss: "kindred",
  },
  συγγενείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4772",
    gloss: "kindred",
  },
  συγγενεῖς: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4773",
    gloss: "kindred",
  },
  συγγενεῦσιν: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G4773",
    gloss: "kindred",
  },
  συγγενῆ: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G4773",
    gloss: "kindred",
  },
  συγγενὴς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4773",
    gloss: "kindred",
  },
  συγγενῶν: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G4773",
    gloss: "kindred",
  },
  συγγνώμην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4774",
    gloss: "concession",
  },
  συγκαθήμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G4775",
    gloss: "to sit with",
  },
  συγκαθήμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G4775",
    gloss: "to sit with",
  },
  συγκαθισάντων: {
    parse:
      "V-AAP-GPM｜Verb, aorist, active, Participle, genitive, plural, masculine",
    GN: "G4776",
    gloss: "to sit down with",
  },
  συνεκάθισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4776",
    gloss: "to sit down with",
  },
  συγκακοπάθησον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G4777",
    gloss: "to suffer with",
  },
  συγκακουχεῖσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G4778",
    gloss: "to suffer with",
  },
  συγκαλεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4779",
    gloss: "to call together",
  },
  συγκαλεσάμενος: {
    parse:
      "V-AMP-NSM｜Verb, aorist, middle, Participle, nominative, singular, masculine",
    GN: "G4779",
    gloss: "to call together",
  },
  Συγκαλεσάμενος: {
    parse:
      "V-AMP-NSM｜Verb, aorist, middle, Participle, nominative, singular, masculine",
    GN: "G4779",
    gloss: "to call together",
  },
  συγκαλέσασθαι: {
    parse: "V-AMN｜Verb, aorist, middle, Infinitive",
    GN: "G4779",
    gloss: "to call together",
  },
  συγκαλοῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G4779",
    gloss: "to call together",
  },
  συνεκάλεσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4779",
    gloss: "to call together",
  },
  συγκεκαλυμμένον: {
    parse:
      "V-RPP-NSN｜Verb, perfect, passive, Participle, nominative, singular, neuter",
    GN: "G4780",
    gloss: "to conceal",
  },
  σύγκαμψον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G4781",
    gloss: "to bend",
  },
  συγκαταβάντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G4782",
    gloss: "to descend with",
  },
  συγκατάθεσις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4783",
    gloss: "agreement",
  },
  συγκατατεθειμένος: {
    parse:
      "V-RNP-NSM｜Verb, perfect, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G4784",
    gloss: "to consent",
  },
  συγκατεψηφίσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G4785",
    gloss: "to numbered with",
  },
  συγκεκερασμένους: {
    parse:
      "V-RPP-APM｜Verb, perfect, passive, Participle, accusative, plural, masculine",
    GN: "G4786",
    gloss: "to unite",
  },
  συνεκέρασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4786",
    gloss: "to unite",
  },
  συνεκίνησάν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4787",
    gloss: "to stir up",
  },
  συγκλειόμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G4788",
    gloss: "to confine",
  },
  συνέκλεισαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4788",
    gloss: "to confine",
  },
  συνέκλεισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4788",
    gloss: "to confine",
  },
  συγκληρονόμα: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G4789",
    gloss: "co-heir",
  },
  συγκληρονόμοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4789",
    gloss: "co-heir",
  },
  συγκληρονόμοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G4789",
    gloss: "co-heir",
  },
  συγκληρονόμων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G4789",
    gloss: "co-heir",
  },
  συγκοινωνεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G4790",
    gloss: "to share with",
  },
  συγκοινωνήσαντές: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G4790",
    gloss: "to share with",
  },
  συγκοινωνήσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G4790",
    gloss: "to share with",
  },
  συγκοινωνὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4791",
    gloss: "sharer",
  },
  συγκοινωνούς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G4791",
    gloss: "sharer",
  },
  συνεκόμισαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4792",
    gloss: "to entomb",
  },
  συγκρῖναι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G4793",
    gloss: "to compare",
  },
  συγκρίνοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4793",
    gloss: "to compare",
  },
  συγκύπτουσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G4794",
    gloss: "to bend",
  },
  συγκυρίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4795",
    gloss: "coincidence",
  },
  συγχαίρει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4796",
    gloss: "to rejoice with",
  },
  συγχαίρετέ: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G4796",
    gloss: "to rejoice with",
  },
  συγχαίρω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G4796",
    gloss: "to rejoice with",
  },
  Συγχάρητέ: {
    parse:
      "V-2AOM-2P｜Verb, 2nd aorist, Passive deponent, imperative, second, plural",
    GN: "G4796",
    gloss: "to rejoice with",
  },
  συνέχαιρον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G4796",
    gloss: "to rejoice with",
  },
  συγκεχυμένη: {
    parse:
      "V-RPP-NSF｜Verb, perfect, passive, Participle, nominative, singular, feminine",
    GN: "G4797",
    gloss: "to confound",
  },
  συγχύννεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G4797",
    gloss: "to confound",
  },
  συνέχεον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G4797",
    gloss: "to confound",
  },
  συνεχύθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G4797",
    gloss: "to confound",
  },
  συνέχυννεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G4797",
    gloss: "to confound",
  },
  συγχρῶνται: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G4798",
    gloss: "to associate with",
  },
  συγχύσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4799",
    gloss: "confusion",
  },
  συζῆν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4800",
    gloss: "to live together",
  },
  συζήσομεν: {
    parse: "V-FAI-1P｜Verb, future, active, indicative, first, plural",
    GN: "G4800",
    gloss: "to live together",
  },
  συνέζευξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4801",
    gloss: "to join",
  },
  συζητεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4802",
    gloss: "to debate",
  },
  συζητεῖτε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G4802",
    gloss: "to debate",
  },
  συζητοῦντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G4802",
    gloss: "to debate",
  },
  συζητοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4802",
    gloss: "to debate",
  },
  συζητούντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G4802",
    gloss: "to debate",
  },
  συνεζήτει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G4802",
    gloss: "to debate",
  },
  συζητητὴς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4804",
    gloss: "debater",
  },
  σύζυγε: {
    // or nominative
    parse: "A-VSM｜Adjective, Vocative, singular, masculine",
    GN: "G4805",
    gloss: "yokefellow",
  },
  συνεζωοποίησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4806",
    gloss: "to make alive with",
  },
  συκαμίνῳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4807",
    gloss: "mulberry tree",
  },
  συκῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4808",
    gloss: "fig tree",
  },
  συκῆ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4808",
    gloss: "fig tree",
  },
  συκῆν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4808",
    gloss: "fig tree",
  },
  Συκῆν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4808",
    gloss: "fig tree",
  },
  συκῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4808",
    gloss: "fig tree",
  },
  συκομορέαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4809",
    gloss: "sycamore tree",
  },
  σῦκα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G4810",
    gloss: "fig",
  },
  σύκων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G4810",
    gloss: "fig",
  },
  ἐσυκοφάντησα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G4811",
    gloss: "to extort",
  },
  συκοφαντήσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G4811",
    gloss: "to extort",
  },
  συλαγωγῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4812",
    gloss: "to capture",
  },
  ἐσύλησα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G4813",
    gloss: "to rob",
  },
  συλλαλήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G4814",
    gloss: "to talk with",
  },
  συλλαλοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4814",
    gloss: "to talk with",
  },
  συνελάλησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4814",
    gloss: "to talk with",
  },
  συνελάλουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G4814",
    gloss: "to talk with",
  },
  συλλαβεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G4815",
    gloss: "to seize/conceive/help",
  },
  συλλαβέσθαι: {
    parse: "V-2AMN｜Verb, 2nd aorist, middle, Infinitive",
    GN: "G4815",
    gloss: "to seize/conceive/help",
  },
  συλλαβόμενοι: {
    parse:
      "V-2AMP-NPM｜Verb, 2nd aorist, middle, Participle, nominative, plural, masculine",
    GN: "G4815",
    gloss: "to seize/conceive/help",
  },
  Συλλαβόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G4815",
    gloss: "to seize/conceive/help",
  },
  συλλαβοῦσα: {
    parse:
      "V-2AAP-NSF｜Verb, 2nd aorist, active, Participle, nominative, singular, feminine",
    GN: "G4815",
    gloss: "to seize/conceive/help",
  },
  συλλαβοῦσιν: {
    parse:
      "V-2AAP-DPM｜Verb, 2nd aorist, active, Participle, dative, plural, masculine",
    GN: "G4815",
    gloss: "to seize/conceive/help",
  },
  συλλαμβάνου: {
    parse: "V-PMM-2S｜Verb, present, middle, imperative, second, singular",
    GN: "G4815",
    gloss: "to seize/conceive/help",
  },
  συλλημφθέντα: {
    parse:
      "V-APP-ASM｜Verb, aorist, passive, Participle, accusative, singular, masculine",
    GN: "G4815",
    gloss: "to seize/conceive/help",
  },
  συλλημφθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G4815",
    gloss: "to seize/conceive/help",
  },
  συλλήμψῃ: {
    parse:
      "V-FDI-2S｜Verb, future, Middle deponent, indicative, second, singular",
    GN: "G4815",
    gloss: "to seize/conceive/help",
  },
  συνείληφεν: {
    parse: "V-2RAI-3S｜Verb, 2nd perfect, active, indicative, third, singular",
    GN: "G4815",
    gloss: "to seize/conceive/help",
  },
  συνέλαβεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G4815",
    gloss: "to seize/conceive/help",
  },
  συνέλαβον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G4815",
    gloss: "to seize/conceive/help",
  },
  συλλέγεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G4816",
    gloss: "to collect",
  },
  συλλέγοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4816",
    gloss: "to collect",
  },
  συλλέγουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G4816",
    gloss: "to collect",
  },
  Συλλέξατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G4816",
    gloss: "to collect",
  },
  συλλέξουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G4816",
    gloss: "to collect",
  },
  συλλέξωμεν: {
    parse: "V-AAS-1P｜Verb, aorist, active, subjunctive, first, plural",
    GN: "G4816",
    gloss: "to collect",
  },
  συνέλεξαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4816",
    gloss: "to collect",
  },
  συνελογίσαντο: {
    parse: "V-ADI-3P｜Verb, aorist, Middle deponent, indicative, third, plural",
    GN: "G4817",
    gloss: "to discuss",
  },
  συλλυπούμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G4818",
    gloss: "be grieved",
  },
  συμβαίνειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4819",
    gloss: "to happen",
  },
  συμβαίνοντος: {
    parse:
      "V-PAP-GSN｜Verb, present, active, Participle, genitive, singular, neuter",
    GN: "G4819",
    gloss: "to happen",
  },
  συμβάντων: {
    parse:
      "V-2AAP-GPM｜Verb, 2nd aorist, active, Participle, genitive, plural, masculine",
    GN: "G4819",
    gloss: "to happen",
  },
  συμβέβηκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G4819",
    gloss: "to happen",
  },
  συμβεβηκότι: {
    parse:
      "V-RAP-DSN｜Verb, perfect, active, Participle, dative, singular, neuter",
    GN: "G4819",
    gloss: "to happen",
  },
  συμβεβηκότων: {
    parse:
      "V-RAP-GPN｜Verb, perfect, active, Participle, genitive, plural, neuter",
    GN: "G4819",
    gloss: "to happen",
  },
  συνέβαινεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G4819",
    gloss: "to happen",
  },
  συνέβη: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G4819",
    gloss: "to happen",
  },
  συμβαλεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G4820",
    gloss: "to ponder/confer",
  },
  συμβάλλουσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G4820",
    gloss: "to ponder/confer",
  },
  συνεβάλετο: {
    parse: "V-2AMI-3S｜Verb, 2nd aorist, middle, indicative, third, singular",
    GN: "G4820",
    gloss: "to ponder/confer",
  },
  συνέβαλλεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G4820",
    gloss: "to ponder/confer",
  },
  συνέβαλλον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G4820",
    gloss: "to ponder/confer",
  },
  συμβασιλεύσομεν: {
    parse: "V-FAI-1P｜Verb, future, active, indicative, first, plural",
    GN: "G4821",
    gloss: "to reign with",
  },
  συμβασιλεύσωμεν: {
    parse: "V-AAS-1P｜Verb, aorist, active, subjunctive, first, plural",
    GN: "G4821",
    gloss: "to reign with",
  },
  συμβιβαζόμενον: {
    parse:
      "V-PPP-NSN｜Verb, present, passive, Participle, nominative, singular, neuter",
    GN: "G4822",
    gloss: "to join with",
  },
  συμβιβάζοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4822",
    gloss: "to join with",
  },
  συμβιβάζων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4822",
    gloss: "to join with",
  },
  συμβιβάσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G4822",
    gloss: "to join with",
  },
  συμβιβασθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G4822",
    gloss: "to join with",
  },
  συνεβίβασαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4822",
    gloss: "to join with",
  },
  συμβουλεύσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G4823",
    gloss: "to consult",
  },
  συμβουλεύω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G4823",
    gloss: "to consult",
  },
  συνεβουλεύσαντο: {
    parse: "V-AMI-3P｜Verb, aorist, middle, indicative, third, plural",
    GN: "G4823",
    gloss: "to consult",
  },
  συμβούλιον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G4824",
    gloss: "counsel/council",
  },
  συμβούλιόν: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G4824",
    gloss: "counsel/council",
  },
  συμβουλίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4824",
    gloss: "counsel/council",
  },
  σύμβουλος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4825",
    gloss: "counselor",
  },
  Συμεὼν: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G4826",
    gloss: "Simeon",
  },
  Συμεών: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G4826",
    gloss: "Simeon",
  },
  συμμαθηταῖς: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G4827",
    gloss: "fellow disciple",
  },
  συμμαρτυρεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4828",
    gloss: "to testify with",
  },
  συμμαρτυρούσης: {
    parse:
      "V-PAP-GSF｜Verb, present, active, Participle, genitive, singular, feminine",
    GN: "G4828",
    gloss: "to testify with",
  },
  συμμερίζονται: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G4829",
    gloss: "to share",
  },
  συμμέτοχα: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G4830",
    gloss: "sharer",
  },
  συμμέτοχοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4830",
    gloss: "sharer",
  },
  Συμμιμηταί: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G4831",
    gloss: "co-imitator",
  },
  σύμμορφον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G4832",
    gloss: "conformed",
  },
  συμμόρφους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G4832",
    gloss: "conformed",
  },
  συμμορφιζόμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G4833",
    gloss: "to make like",
  },
  συμπαθῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G4834",
    gloss: "to sympathize",
  },
  συνεπαθήσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G4834",
    gloss: "to sympathize",
  },
  συμπαθεῖς: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4835",
    gloss: "sympathetic",
  },
  συμπαραγενόμενοι: {
    parse:
      "V-2ADP-NPM｜Verb, 2nd aorist, Middle deponent, Participle, nominative, plural, masculine",
    GN: "G4836",
    gloss: "to gather/gather together/assemble",
  },
  συμπαρακληθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G4837",
    gloss: "to encourage",
  },
  συμπαραλαβεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G4838",
    gloss: "to take along with",
  },
  συμπαραλαβόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G4838",
    gloss: "to take along with",
  },
  συμπαραλαβὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G4838",
    gloss: "to take along with",
  },
  συμπαραλαμβάνειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4838",
    gloss: "to take along with",
  },
  συμπαρόντες: {
    parse:
      "V-PAP-VPM｜Verb, present, active, Participle, Vocative or nominative, plural, masculine",
    GN: "G4840",
    gloss: "be present with",
  },
  συμπάσχει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4841",
    gloss: "to suffer with",
  },
  συμπάσχομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G4841",
    gloss: "to suffer with",
  },
  συνεπέμψαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G4842",
    gloss: "to send with",
  },
  συμπεριλαβὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G4843",
    gloss: "to embrace",
  },
  συνεπίομεν: {
    parse: "V-2AAI-1P｜Verb, 2nd aorist, active, indicative, first, plural",
    GN: "G4844",
    gloss: "to drink with",
  },
  συμπληροῦσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G4845",
    gloss: "to (ful)fill",
  },
  συνεπληροῦντο: {
    parse: "V-IPI-3P｜Verb, imperfect, passive, indicative, third, plural",
    GN: "G4845",
    gloss: "to (ful)fill",
  },
  συμπνίγει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4846",
    gloss: "to choke",
  },
  συμπνίγονται: {
    parse: "V-PPI-3P｜Verb, present, passive, indicative, third, plural",
    GN: "G4846",
    gloss: "to choke",
  },
  συμπνίγουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G4846",
    gloss: "to choke",
  },
  συνέπνιγον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G4846",
    gloss: "to choke",
  },
  συνέπνιξαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4846",
    gloss: "to choke",
  },
  συμπολῖται: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G4847",
    gloss: "fellow citizen",
  },
  συμπορεύονται: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G4848",
    gloss: "to go with",
  },
  συνεπορεύετο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G4848",
    gloss: "to go with",
  },
  συνεπορεύοντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G4848",
    gloss: "to go with",
  },
  Συνεπορεύοντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G4848",
    gloss: "to go with",
  },
  συμπόσια: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G4849",
    gloss: "common meal",
  },
  συμπρεσβύτερος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4850",
    gloss: "fellow elder",
  },
  συμφέρει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4851",
    gloss: "be profitable",
  },
  συμφέρον: {
    parse:
      "V-PAP-NSN｜Verb, present, active, Participle, nominative, singular, neuter",
    GN: "G4851",
    gloss: "be profitable",
  },
  συμφερόντων: {
    parse:
      "V-PAP-GPN｜Verb, present, active, Participle, genitive, plural, neuter",
    GN: "G4851",
    gloss: "be profitable",
  },
  σύμφορον: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G4851",
    gloss: "be profitable",
  },
  συνενέγκαντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G4851",
    gloss: "be profitable",
  },
  σύμφημι: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G4852",
    gloss: "to agree",
  },
  συμφυλετῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G4853",
    gloss: "fellow countryman",
  },
  σύμφυτοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4854",
    gloss: "united",
  },
  συμφυεῖσαι: {
    parse:
      "V-2APP-NPF｜Verb, 2nd aorist, passive, Participle, nominative, plural, feminine",
    GN: "G4855",
    gloss: "to grow with",
  },
  συμφωνήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G4856",
    gloss: "to agree with",
  },
  συμφωνήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G4856",
    gloss: "to agree with",
  },
  συμφωνήσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G4856",
    gloss: "to agree with",
  },
  συμφωνοῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G4856",
    gloss: "to agree with",
  },
  συνεφωνήθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G4856",
    gloss: "to agree with",
  },
  συνεφώνησάς: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G4856",
    gloss: "to agree with",
  },
  συμφώνησις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4857",
    gloss: "harmony",
  },
  συμφωνίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4858",
    gloss: "music",
  },
  συμφώνου: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G4859",
    gloss: "mutual consent",
  },
  συνεψήφισαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4860",
    gloss: "to calculate",
  },
  σύμψυχοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4861",
    gloss: "harmonious",
  },
  σύν: { parse: "PREP｜Preposition", GN: "G4862", gloss: "with" },
  σὺν: { parse: "PREP｜Preposition", GN: "G4862", gloss: "with" },
  συναγαγεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G4863",
    gloss: "to gather/gather together/assemble",
  },
  συναγάγετε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G4863",
    gloss: "to gather/gather together/assemble",
  },
  Συναγάγετε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G4863",
    gloss: "to gather/gather together/assemble",
  },
  συναγάγῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G4863",
    gloss: "to gather/gather together/assemble",
  },
  συναγαγόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G4863",
    gloss: "to gather/gather together/assemble",
  },
  συναγαγούσῃ: {
    parse:
      "V-2AAP-DSF｜Verb, 2nd aorist, active, Participle, dative, singular, feminine",
    GN: "G4863",
    gloss: "to gather/gather together/assemble",
  },
  συναγαγὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G4863",
    gloss: "to gather/gather together/assemble",
  },
  συνάγει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4863",
    gloss: "to gather/gather together/assemble",
  },
  συνάγεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G4863",
    gloss: "to gather/gather together/assemble",
  },
  συνάγονται: {
    parse: "V-PPI-3P｜Verb, present, passive, indicative, third, plural",
    GN: "G4863",
    gloss: "to gather/gather together/assemble",
  },
  συνάγουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G4863",
    gloss: "to gather/gather together/assemble",
  },
  συνάγω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G4863",
    gloss: "to gather/gather together/assemble",
  },
  συνάγων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4863",
    gloss: "to gather/gather together/assemble",
  },
  συνάξει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G4863",
    gloss: "to gather/gather together/assemble",
  },
  συνάξω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G4863",
    gloss: "to gather/gather together/assemble",
  },
  συναχθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G4863",
    gloss: "to gather/gather together/assemble",
  },
  συναχθέντων: {
    parse:
      "V-APP-GPM｜Verb, aorist, passive, Participle, genitive, plural, masculine",
    GN: "G4863",
    gloss: "to gather/gather together/assemble",
  },
  συναχθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G4863",
    gloss: "to gather/gather together/assemble",
  },
  συναχθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G4863",
    gloss: "to gather/gather together/assemble",
  },
  συνάχθητε: {
    parse: "V-APM-2P｜Verb, aorist, passive, imperative, second, plural",
    GN: "G4863",
    gloss: "to gather/gather together/assemble",
  },
  συνήγαγεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G4863",
    gloss: "to gather/gather together/assemble",
  },
  συνηγάγετέ: {
    parse: "V-2AAI-2P｜Verb, 2nd aorist, active, indicative, second, plural",
    GN: "G4863",
    gloss: "to gather/gather together/assemble",
  },
  συνηγάγομεν: {
    parse: "V-2AAI-1P｜Verb, 2nd aorist, active, indicative, first, plural",
    GN: "G4863",
    gloss: "to gather/gather together/assemble",
  },
  συνήγαγον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G4863",
    gloss: "to gather/gather together/assemble",
  },
  συνηγμένα: {
    parse:
      "V-RPP-APN｜Verb, perfect, passive, Participle, accusative, plural, neuter",
    GN: "G4863",
    gloss: "to gather/gather together/assemble",
  },
  συνηγμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G4863",
    gloss: "to gather/gather together/assemble",
  },
  συνηγμένων: {
    parse:
      "V-RPP-GPM｜Verb, perfect, passive, Participle, genitive, plural, masculine",
    GN: "G4863",
    gloss: "to gather/gather together/assemble",
  },
  Συνηγμένων: {
    parse:
      "V-RPP-GPM｜Verb, perfect, passive, Participle, genitive, plural, masculine",
    GN: "G4863",
    gloss: "to gather/gather together/assemble",
  },
  συνήχθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G4863",
    gloss: "to gather/gather together/assemble",
  },
  συνήχθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G4863",
    gloss: "to gather/gather together/assemble",
  },
  Συνήχθησάν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G4863",
    gloss: "to gather/gather together/assemble",
  },
  συναγωγαῖς: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G4864",
    gloss: "synagogue",
  },
  συναγωγάς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G4864",
    gloss: "synagogue",
  },
  συναγωγὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G4864",
    gloss: "synagogue",
  },
  συναγωγῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4864",
    gloss: "synagogue",
  },
  συναγωγὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4864",
    gloss: "synagogue",
  },
  συναγωγήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4864",
    gloss: "synagogue",
  },
  συναγωγὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4864",
    gloss: "synagogue",
  },
  συναγωγῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4864",
    gloss: "synagogue",
  },
  συναγωγῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G4864",
    gloss: "synagogue",
  },
  συναγωνίσασθαί: {
    parse: "V-AMN｜Verb, aorist, middle, Infinitive",
    GN: "G4865",
    gloss: "to struggle",
  },
  συναθλοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4866",
    gloss: "to contend",
  },
  συνήθλησάν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4866",
    gloss: "to contend",
  },
  συναθροίσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G4867",
    gloss: "to assemble",
  },
  συνηθροισμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G4867",
    gloss: "to assemble",
  },
  συναίρει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4868",
    gloss: "to settle accounts",
  },
  συναίρειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4868",
    gloss: "to settle accounts",
  },
  συνᾶραι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G4868",
    gloss: "to settle accounts",
  },
  συναιχμάλωτός: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4869",
    gloss: "fellow prisoner",
  },
  συναιχμαλώτους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G4869",
    gloss: "fellow prisoner",
  },
  συνακολουθῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G4870",
    gloss: "to accompany",
  },
  συνακολουθοῦσαι: {
    parse:
      "V-PAP-NPF｜Verb, present, active, Participle, nominative, plural, feminine",
    GN: "G4870",
    gloss: "to accompany",
  },
  συνηκολούθει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G4870",
    gloss: "to accompany",
  },
  συναλιζόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G4871",
    gloss: "to assemble",
  },
  συναναβᾶσαι: {
    parse:
      "V-2AAP-NPF｜Verb, 2nd aorist, active, Participle, nominative, plural, feminine",
    GN: "G4872",
    gloss: "to ascend with",
  },
  συναναβᾶσιν: {
    parse:
      "V-2AAP-DPM｜Verb, 2nd aorist, active, Participle, dative, plural, masculine",
    GN: "G4872",
    gloss: "to ascend with",
  },
  συνανακείμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G4873",
    gloss: "to dine with",
  },
  συνανακειμένοις: {
    parse:
      "V-PNP-DPM｜Verb, present, Middle or Passive deponent, Participle, dative, plural, masculine",
    GN: "G4873",
    gloss: "to dine with",
  },
  συνανακειμένους: {
    parse:
      "V-PNP-APM｜Verb, present, Middle or Passive deponent, Participle, accusative, plural, masculine",
    GN: "G4873",
    gloss: "to dine with",
  },
  συνανακειμένων: {
    parse:
      "V-PNP-GPM｜Verb, present, Middle or Passive deponent, Participle, genitive, plural, masculine",
    GN: "G4873",
    gloss: "to dine with",
  },
  συνανέκειντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G4873",
    gloss: "to dine with",
  },
  συναναμίγνυσθαι: {
    parse: "V-PMN｜Verb, present, middle, Infinitive",
    GN: "G4874",
    gloss: "to associate with",
  },
  συναναπαύσωμαι: {
    parse:
      "V-ADS-1S｜Verb, aorist, Middle deponent, subjunctive, first, singular",
    GN: "G4875",
    gloss: "to rest with",
  },
  συναντήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G4876",
    gloss: "to meet",
  },
  συναντήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G4876",
    gloss: "to meet",
  },
  συναντήσοντά: {
    parse:
      "V-FAP-APN｜Verb, future, active, Participle, accusative, plural, neuter",
    GN: "G4876",
    gloss: "to meet",
  },
  συνήντησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4876",
    gloss: "to meet",
  },
  συναντιλάβηται: {
    parse:
      "V-2ADS-3S｜Verb, 2nd aorist, Middle deponent, subjunctive, third, singular",
    GN: "G4878",
    gloss: "to help",
  },
  συναντιλαμβάνεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G4878",
    gloss: "to help",
  },
  συναπαγόμενοι: {
    parse:
      "V-PMP-NPM｜Verb, present, middle, Participle, nominative, plural, masculine",
    GN: "G4879",
    gloss: "to lead away with",
  },
  συναπαχθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G4879",
    gloss: "to lead away with",
  },
  συναπήχθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G4879",
    gloss: "to lead away with",
  },
  συναπεθάνομεν: {
    parse: "V-2AAI-1P｜Verb, 2nd aorist, active, indicative, first, plural",
    GN: "G4880",
    gloss: "to die with",
  },
  συναποθανεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G4880",
    gloss: "to die with",
  },
  συναπώλετο: {
    parse: "V-2AMI-3S｜Verb, 2nd aorist, middle, indicative, third, singular",
    GN: "G4881",
    gloss: "to die/destroy with",
  },
  συναπέστειλα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G4882",
    gloss: "to send with",
  },
  συναρμολογουμένη: {
    parse:
      "V-PPP-NSF｜Verb, present, passive, Participle, nominative, singular, feminine",
    GN: "G4883",
    gloss: "to join",
  },
  συναρμολογούμενον: {
    parse:
      "V-PPP-NSN｜Verb, present, passive, Participle, nominative, singular, neuter",
    GN: "G4883",
    gloss: "to join",
  },
  συναρπάσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G4884",
    gloss: "to seize",
  },
  συναρπασθέντος: {
    parse:
      "V-APP-GSN｜Verb, aorist, passive, Participle, genitive, singular, neuter",
    GN: "G4884",
    gloss: "to seize",
  },
  συνηρπάκει: {
    parse: "V-LAI-3S｜Verb, pluperfect, active, indicative, third, singular",
    GN: "G4884",
    gloss: "to seize",
  },
  συνήρπασαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4884",
    gloss: "to seize",
  },
  συναυξάνεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G4885",
    gloss: "to grow together",
  },
  σύνδεσμον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4886",
    gloss: "bond(age)",
  },
  σύνδεσμος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4886",
    gloss: "bond(age)",
  },
  συνδέσμῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G4886",
    gloss: "bond(age)",
  },
  συνδέσμων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G4886",
    gloss: "bond(age)",
  },
  συνδεδεμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G4887",
    gloss: "to bind with",
  },
  συνδοξασθῶμεν: {
    parse: "V-APS-1P｜Verb, aorist, passive, subjunctive, first, plural",
    GN: "G4888",
    gloss: "to glory with",
  },
  σύνδουλοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G4889",
    gloss: "fellow slave",
  },
  σύνδουλόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4889",
    gloss: "fellow slave",
  },
  σύνδουλος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4889",
    gloss: "fellow slave",
  },
  σύνδουλός: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4889",
    gloss: "fellow slave",
  },
  συνδούλου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G4889",
    gloss: "fellow slave",
  },
  συνδούλους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G4889",
    gloss: "fellow slave",
  },
  συνδούλων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G4889",
    gloss: "fellow slave",
  },
  συνδρομὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4890",
    gloss: "swarming",
  },
  συνήγειρεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4891",
    gloss: "to raise up with",
  },
  συνηγέρθητε: {
    parse: "V-API-2P｜Verb, aorist, passive, indicative, second, plural",
    GN: "G4891",
    gloss: "to raise up with",
  },
  συνέδρια: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G4892",
    gloss: "council",
  },
  συνέδριον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4892",
    gloss: "council",
  },
  συνεδρίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4892",
    gloss: "council",
  },
  συνεδρίῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G4892",
    gloss: "council",
  },
  συνειδήσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4893",
    gloss: "conscience",
  },
  συνειδήσεσιν: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G4893",
    gloss: "conscience",
  },
  συνειδήσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4893",
    gloss: "conscience",
  },
  συνειδήσεώς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4893",
    gloss: "conscience",
  },
  συνείδησιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4893",
    gloss: "conscience",
  },
  συνείδησις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4893",
    gloss: "conscience",
  },
  συνιδόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G4894",
    gloss: "be aware",
  },
  Συνιδών: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G4894",
    gloss: "be aware",
  },
  συνῆσαν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G4895",
    gloss: "be with",
  },
  συνόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G4895",
    gloss: "be with",
  },
  Συνιόντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G4896",
    gloss: "to gather",
  },
  συνεισῆλθεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G4897",
    gloss: "to enter with",
  },
  συνέκδημος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4898",
    gloss: "companion",
  },
  συνεκδήμους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G4898",
    gloss: "companion",
  },
  συνεκλεκτὴ: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G4899",
    gloss: "chosen with",
  },
  συνήλλασσεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G4900",
    gloss: "to urge",
  },
  συνεπιμαρτυροῦντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G4901",
    gloss: "to testify with",
  },
  συνείπετο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G4902",
    gloss: "to accompany",
  },
  συνεργεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4903",
    gloss: "to work with",
  },
  Συνεργοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4903",
    gloss: "to work with",
  },
  συνεργοῦντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G4903",
    gloss: "to work with",
  },
  συνεργοῦντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G4903",
    gloss: "to work with",
  },
  συνήργει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G4903",
    gloss: "to work with",
  },
  συνεργοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4904",
    gloss: "co-worker",
  },
  συνεργοὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G4904",
    gloss: "co-worker",
  },
  συνεργὸν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G4904",
    gloss: "co-worker",
  },
  συνεργός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4904",
    gloss: "co-worker",
  },
  συνεργούς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G4904",
    gloss: "co-worker",
  },
  συνεργῷ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G4904",
    gloss: "co-worker",
  },
  συνεργῶν: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G4904",
    gloss: "co-worker",
  },
  συνεληλύθεισαν: {
    parse: "V-2LAI-3P｜Verb, 2nd pluperfect, active, indicative, third, plural",
    GN: "G4905",
    gloss: "to assemble",
  },
  συνεληλυθότας: {
    parse:
      "V-2RAP-APM｜Verb, 2nd perfect, active, Participle, accusative, plural, masculine",
    GN: "G4905",
    gloss: "to assemble",
  },
  συνεληλυθυῖαι: {
    parse:
      "V-2RAP-NPF｜Verb, 2nd perfect, active, Participle, nominative, plural, feminine",
    GN: "G4905",
    gloss: "to assemble",
  },
  συνελθεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G4905",
    gloss: "to assemble",
  },
  συνέλθῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G4905",
    gloss: "to assemble",
  },
  συνελθόντα: {
    parse:
      "V-2AAP-ASM｜Verb, 2nd aorist, active, Participle, accusative, singular, masculine",
    GN: "G4905",
    gloss: "to assemble",
  },
  συνελθόντας: {
    parse:
      "V-2AAP-APM｜Verb, 2nd aorist, active, Participle, accusative, plural, masculine",
    GN: "G4905",
    gloss: "to assemble",
  },
  συνελθόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G4905",
    gloss: "to assemble",
  },
  συνελθόντων: {
    parse:
      "V-2AAP-GPM｜Verb, 2nd aorist, active, Participle, genitive, plural, masculine",
    GN: "G4905",
    gloss: "to assemble",
  },
  συνελθούσαις: {
    parse:
      "V-2AAP-DPF｜Verb, 2nd aorist, active, Participle, dative, plural, feminine",
    GN: "G4905",
    gloss: "to assemble",
  },
  συνέρχεσθε: {
    parse:
      "V-PNI-2P｜Verb, present, Middle or Passive deponent, indicative, second, plural",
    GN: "G4905",
    gloss: "to assemble",
  },
  συνέρχεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G4905",
    gloss: "to assemble",
  },
  συνέρχησθε: {
    parse:
      "V-PNS-2P｜Verb, present, Middle or Passive deponent, subjunctive, second, plural",
    GN: "G4905",
    gloss: "to assemble",
  },
  συνερχόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G4905",
    gloss: "to assemble",
  },
  συνερχομένων: {
    parse:
      "V-PNP-GPM｜Verb, present, Middle or Passive deponent, Participle, genitive, plural, masculine",
    GN: "G4905",
    gloss: "to assemble",
  },
  συνέρχονται: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G4905",
    gloss: "to assemble",
  },
  συνῆλθαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G4905",
    gloss: "to assemble",
  },
  συνῆλθε: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G4905",
    gloss: "to assemble",
  },
  συνῆλθεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G4905",
    gloss: "to assemble",
  },
  συνῆλθον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G4905",
    gloss: "to assemble",
  },
  συνήρχετο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G4905",
    gloss: "to assemble",
  },
  συνήρχοντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G4905",
    gloss: "to assemble",
  },
  συνεσθίει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4906",
    gloss: "to eat with",
  },
  συνεσθίειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4906",
    gloss: "to eat with",
  },
  συνέφαγες: {
    parse: "V-2AAI-2S｜Verb, 2nd aorist, active, indicative, second, singular",
    GN: "G4906",
    gloss: "to eat with",
  },
  συνεφάγομεν: {
    parse: "V-2AAI-1P｜Verb, 2nd aorist, active, indicative, first, plural",
    GN: "G4906",
    gloss: "to eat with",
  },
  συνήσθιεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G4906",
    gloss: "to eat with",
  },
  συνέσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4907",
    gloss: "understanding",
  },
  συνέσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4907",
    gloss: "understanding",
  },
  σύνεσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4907",
    gloss: "understanding",
  },
  σύνεσίν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4907",
    gloss: "understanding",
  },
  συνετῷ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G4908",
    gloss: "intelligent",
  },
  συνετῶν: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G4908",
    gloss: "intelligent",
  },
  συνευδοκεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4909",
    gloss: "to agree to",
  },
  συνευδοκεῖτε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G4909",
    gloss: "to agree to",
  },
  συνευδοκοῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G4909",
    gloss: "to agree to",
  },
  συνευδοκῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4909",
    gloss: "to agree to",
  },
  συνευωχούμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G4910",
    gloss: "to feast with",
  },
  συνεπέστη: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G4911",
    gloss: "to attack",
  },
  συνείχετο: {
    parse: "V-IPI-3S｜Verb, imperfect, passive, indicative, third, singular",
    GN: "G4912",
    gloss: "to hold/oppress",
  },
  συνείχοντο: {
    parse: "V-IPI-3P｜Verb, imperfect, passive, indicative, third, plural",
    GN: "G4912",
    gloss: "to hold/oppress",
  },
  συνέξουσίν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G4912",
    gloss: "to hold/oppress",
  },
  συνέσχον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G4912",
    gloss: "to hold/oppress",
  },
  συνέχει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4912",
    gloss: "to hold/oppress",
  },
  συνέχομαι: {
    parse: "V-PPI-1S｜Verb, present, passive, indicative, first, singular",
    GN: "G4912",
    gloss: "to hold/oppress",
  },
  συνεχομένη: {
    parse:
      "V-PPP-NSF｜Verb, present, passive, Participle, nominative, singular, feminine",
    GN: "G4912",
    gloss: "to hold/oppress",
  },
  συνεχόμενον: {
    parse:
      "V-PPP-ASM｜Verb, present, passive, Participle, accusative, singular, masculine",
    GN: "G4912",
    gloss: "to hold/oppress",
  },
  συνεχομένους: {
    parse:
      "V-PPP-APM｜Verb, present, passive, Participle, accusative, plural, masculine",
    GN: "G4912",
    gloss: "to hold/oppress",
  },
  συνέχοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4912",
    gloss: "to hold/oppress",
  },
  συνέχουσίν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G4912",
    gloss: "to hold/oppress",
  },
  συνήδομαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G4913",
    gloss: "to delight",
  },
  συνηθείᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4914",
    gloss: "custom",
  },
  συνήθεια: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4914",
    gloss: "custom",
  },
  συνήθειαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4914",
    gloss: "custom",
  },
  συνηλικιώτας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G4915",
    gloss: "contemporary",
  },
  συνετάφημεν: {
    parse: "V-2API-1P｜Verb, 2nd aorist, passive, indicative, first, plural",
    GN: "G4916",
    gloss: "be buried with",
  },
  συνταφέντες: {
    parse:
      "V-2APP-NPM｜Verb, 2nd aorist, passive, Participle, nominative, plural, masculine",
    GN: "G4916",
    gloss: "be buried with",
  },
  συνθλασθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G4917",
    gloss: "to shatter",
  },
  συνέθλιβον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G4918",
    gloss: "to push against",
  },
  συνθλίβοντά: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G4918",
    gloss: "to push against",
  },
  συνθρύπτοντές: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4919",
    gloss: "to break",
  },
  σύνετε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G4920",
    gloss: "to understand",
  },
  συνῆκαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4920",
    gloss: "to understand",
  },
  Συνήκατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G4920",
    gloss: "to understand",
  },
  συνήσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G4920",
    gloss: "to understand",
  },
  συνῆτε: {
    parse: "V-2AAS-2P｜Verb, 2nd aorist, active, subjunctive, second, plural",
    GN: "G4920",
    gloss: "to understand",
  },
  συνιᾶσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G4920",
    gloss: "to understand",
  },
  συνιείς: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4920",
    gloss: "to understand",
  },
  συνιέναι: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4920",
    gloss: "to understand",
  },
  συνιέντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G4920",
    gloss: "to understand",
  },
  συνίετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G4920",
    gloss: "to understand",
  },
  συνίουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G4920",
    gloss: "to understand",
  },
  συνίων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4920",
    gloss: "to understand",
  },
  συνιῶσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G4920",
    gloss: "to understand",
  },
  συνῶσιν: {
    parse: "V-2AAS-3P｜Verb, 2nd aorist, active, subjunctive, third, plural",
    GN: "G4920",
    gloss: "to understand",
  },
  συνέστηκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G4921",
    gloss: "to commend",
  },
  συνεστήσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G4921",
    gloss: "to commend",
  },
  συνεστῶσα: {
    parse:
      "V-RAP-NSF｜Verb, perfect, active, Participle, nominative, singular, feminine",
    GN: "G4921",
    gloss: "to commend",
  },
  συνεστῶτας: {
    parse:
      "V-RAP-APM｜Verb, perfect, active, Participle, accusative, plural, masculine",
    GN: "G4921",
    gloss: "to commend",
  },
  συνιστάνειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4921",
    gloss: "to commend",
  },
  συνιστάνομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G4921",
    gloss: "to commend",
  },
  συνιστάνοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4921",
    gloss: "to commend",
  },
  συνιστανόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G4921",
    gloss: "to commend",
  },
  συνιστάντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4921",
    gloss: "to commend",
  },
  συνιστάνω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G4921",
    gloss: "to commend",
  },
  συνιστάνων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4921",
    gloss: "to commend",
  },
  συνίστασθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G4921",
    gloss: "to commend",
  },
  Συνίστημι: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G4921",
    gloss: "to commend",
  },
  συνίστησιν: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4921",
    gloss: "to commend",
  },
  συνοδεύοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4922",
    gloss: "to go/travel with",
  },
  συνοδίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4923",
    gloss: "caravan",
  },
  συνοικοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4924",
    gloss: "to live with",
  },
  συνοικοδομεῖσθε: {
    parse: "V-PPI-2P｜Verb, present, passive, indicative, second, plural",
    GN: "G4925",
    gloss: "be built up with",
  },
  συνομιλῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4926",
    gloss: "to converse",
  },
  συνομοροῦσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G4927",
    gloss: "be next to",
  },
  συνοχὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4928",
    gloss: "anguish",
  },
  συνοχῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4928",
    gloss: "anguish",
  },
  συνέταξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4929",
    gloss: "to direct",
  },
  συνέταξέν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4929",
    gloss: "to direct",
  },
  συντελείᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4930",
    gloss: "consummation",
  },
  συντέλεια: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4930",
    gloss: "consummation",
  },
  συντελείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4930",
    gloss: "consummation",
  },
  συντελεῖσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G4931",
    gloss: "to complete",
  },
  συντελέσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G4931",
    gloss: "to complete",
  },
  συντελεσθεισῶν: {
    parse:
      "V-APP-GPF｜Verb, aorist, passive, Participle, genitive, plural, feminine",
    GN: "G4931",
    gloss: "to complete",
  },
  συντελέσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G4931",
    gloss: "to complete",
  },
  συντελῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4931",
    gloss: "to complete",
  },
  συντέμνων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4932",
    gloss: "to cut short",
  },
  συνετήρει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G4933",
    gloss: "to preserve",
  },
  συντηροῦνται: {
    parse: "V-PPI-3P｜Verb, present, passive, indicative, third, plural",
    GN: "G4933",
    gloss: "to preserve",
  },
  συνέθεντο: {
    parse: "V-2AMI-3P｜Verb, 2nd aorist, middle, indicative, third, plural",
    GN: "G4934",
    gloss: "to agree",
  },
  συνεπέθεντο: {
    parse: "V-2AMI-3P｜Verb, 2nd aorist, middle, indicative, third, plural",
    GN: "G4934",
    gloss: "to agree",
  },
  συνετέθειντο: {
    parse: "V-LMI-3P｜Verb, pluperfect, middle, indicative, third, plural",
    GN: "G4934",
    gloss: "to agree",
  },
  συντόμως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4935",
    gloss: "concisely",
  },
  συνέδραμεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G4936",
    gloss: "to flock",
  },
  συνέδραμον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G4936",
    gloss: "to flock",
  },
  συντρεχόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G4936",
    gloss: "to flock",
  },
  συντετριμμένον: {
    parse:
      "V-RPP-ASM｜Verb, perfect, passive, Participle, accusative, singular, masculine",
    GN: "G4937",
    gloss: "to break",
  },
  συντετρῖφθαι: {
    parse: "V-RPN｜Verb, perfect, passive, Infinitive",
    GN: "G4937",
    gloss: "to break",
  },
  συντρίβεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G4937",
    gloss: "to break",
  },
  συντριβήσεται: {
    parse: "V-2FPI-3S｜Verb, 2nd future, passive, indicative, third, singular",
    GN: "G4937",
    gloss: "to break",
  },
  συντρῖβον: {
    parse:
      "V-PAP-NSN｜Verb, present, active, Participle, nominative, singular, neuter",
    GN: "G4937",
    gloss: "to break",
  },
  συντρίψασα: {
    parse:
      "V-AAP-NSF｜Verb, aorist, active, Participle, nominative, singular, feminine",
    GN: "G4937",
    gloss: "to break",
  },
  συντρίψει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G4937",
    gloss: "to break",
  },
  σύντριμμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4938",
    gloss: "ruin",
  },
  σύντροφος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G4939",
    gloss: "brought up with",
  },
  συντυχεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G4940",
    gloss: "to meet with",
  },
  Συντύχην: {
    parse: "N-ASF-P｜Noun, accusative, singular, feminine, Person",
    GN: "G4941",
    gloss: "Syntyche",
  },
  συνυπεκρίθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G4942",
    gloss: "to join hypocrisy",
  },
  συνυπουργούντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G4943",
    gloss: "to join in helping",
  },
  συνωδίνει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4944",
    gloss: "to labor together",
  },
  συνωμοσίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4945",
    gloss: "plot",
  },
  Συρακούσας: {
    parse: "N-APF-L｜Noun, accusative, plural, feminine, Location",
    GN: "G4946",
    gloss: "Syracuse",
  },
  Συρίαν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G4947",
    gloss: "Syria",
  },
  Συρίας: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G4947",
    gloss: "Syria",
  },
  Σύρος: {
    parse: "N-NSM-LG｜Noun, nominative, singular, masculine, Location Gentilic",
    GN: "G4948",
    gloss: "Syrian",
  },
  Συροφοινίκισσα: {
    parse: "N-NSF-LG｜Noun, nominative, singular, feminine, Location Gentilic",
    GN: "G4949",
    gloss: "Syrophoenician",
  },
  Σύρτιν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G4950",
    gloss: "Syrtis",
  },
  ἔσυρον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G4951",
    gloss: "to drag",
  },
  σύρει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4951",
    gloss: "to drag",
  },
  σύροντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G4951",
    gloss: "to drag",
  },
  σύρων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G4951",
    gloss: "to drag",
  },
  συνεσπάραξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4952",
    gloss: "to convulse",
  },
  σύσσημον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G4953",
    gloss: "an agreed signal",
  },
  σύσσωμα: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G4954",
    gloss: "of the same body",
  },
  στασιαστῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G4955",
    gloss: "insurrectionist",
  },
  συστατικῶν: {
    parse: "A-GPF｜Adjective, genitive, plural, feminine",
    GN: "G4956",
    gloss: "recommended",
  },
  συνεσταυρώθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G4957",
    gloss: "to crucify with",
  },
  συνεσταύρωμαι: {
    parse: "V-RPI-1S｜Verb, perfect, passive, indicative, first, singular",
    GN: "G4957",
    gloss: "to crucify with",
  },
  συνεσταυρωμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G4957",
    gloss: "to crucify with",
  },
  συσταυρωθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G4957",
    gloss: "to crucify with",
  },
  συσταυρωθέντος: {
    parse:
      "V-APP-GSM｜Verb, aorist, passive, Participle, genitive, singular, masculine",
    GN: "G4957",
    gloss: "to crucify with",
  },
  συνεσταλμένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G4958",
    gloss: "to wrap up",
  },
  συνέστειλαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G4958",
    gloss: "to wrap up",
  },
  συστενάζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4959",
    gloss: "to groan with",
  },
  συστοιχεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4960",
    gloss: "to correspond to",
  },
  συστρατιώτῃ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G4961",
    gloss: "fellow soldier",
  },
  συστρατιώτην: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4961",
    gloss: "fellow soldier",
  },
  Συστρεφομένων: {
    parse:
      "V-PPP-GPM｜Verb, present, passive, Participle, genitive, plural, masculine",
    GN: "G4962",
    gloss: "to gather",
  },
  συστρέψαντος: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G4962",
    gloss: "to gather",
  },
  συστροφὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4963",
    gloss: "commotion/plot",
  },
  συστροφῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4963",
    gloss: "commotion/plot",
  },
  συσχηματίζεσθε: {
    parse:
      "V-PEM-2P｜Verb, present, Either middle or passive, imperative, second, plural",
    GN: "G4964",
    gloss: "to conform to",
  },
  συσχηματιζόμενοι: {
    parse:
      "V-PEP-NPM｜Verb, present, Either middle or passive, Participle, nominative, plural, masculine",
    GN: "G4964",
    gloss: "to conform to",
  },
  Συχὰρ: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G4965",
    gloss: "Sychar",
  },
  Συχὲμ: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G4966",
    gloss: "Shechem",
  },
  Συχέμ: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G4966",
    gloss: "Shechem",
  },
  σφαγὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4967",
    gloss: "slaughter",
  },
  σφαγῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4967",
    gloss: "slaughter",
  },
  σφάγια: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G4968",
    gloss: "sacrificial victim",
  },
  ἐσφάγης: {
    parse: "V-2API-2S｜Verb, 2nd aorist, passive, indicative, second, singular",
    GN: "G4969",
    gloss: "to slaughter",
  },
  ἐσφαγμένην: {
    parse:
      "V-RPP-ASF｜Verb, perfect, passive, Participle, accusative, singular, feminine",
    GN: "G4969",
    gloss: "to slaughter",
  },
  ἐσφαγμένον: {
    parse:
      "V-RPP-NSN｜Verb, perfect, passive, Participle, nominative, singular, neuter",
    GN: "G4969",
    gloss: "to slaughter",
  },
  ἐσφαγμένου: {
    parse:
      "V-RPP-GSN｜Verb, perfect, passive, Participle, genitive, singular, neuter",
    GN: "G4969",
    gloss: "to slaughter",
  },
  ἐσφαγμένων: {
    parse:
      "V-RPP-GPM｜Verb, perfect, passive, Participle, genitive, plural, masculine",
    GN: "G4969",
    gloss: "to slaughter",
  },
  ἔσφαξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4969",
    gloss: "to slaughter",
  },
  σφάξουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G4969",
    gloss: "to slaughter",
  },
  σφόδρα: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4970",
    gloss: "very",
  },
  σφοδρῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4971",
    gloss: "violently",
  },
  ἐσφράγισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4972",
    gloss: "to seal",
  },
  ἐσφραγίσθητε: {
    parse: "V-API-2P｜Verb, aorist, passive, indicative, second, plural",
    GN: "G4972",
    gloss: "to seal",
  },
  ἐσφραγισμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G4972",
    gloss: "to seal",
  },
  ἐσφραγισμένων: {
    parse:
      "V-RPP-GPM｜Verb, perfect, passive, Participle, genitive, plural, masculine",
    GN: "G4972",
    gloss: "to seal",
  },
  σφραγισάμενος: {
    parse:
      "V-AMP-NSM｜Verb, aorist, middle, Participle, nominative, singular, masculine",
    GN: "G4972",
    gloss: "to seal",
  },
  σφραγίσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G4972",
    gloss: "to seal",
  },
  σφραγίσῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G4972",
    gloss: "to seal",
  },
  Σφράγισον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G4972",
    gloss: "to seal",
  },
  σφραγίσωμεν: {
    parse: "V-AAS-1P｜Verb, aorist, active, subjunctive, first, plural",
    GN: "G4972",
    gloss: "to seal",
  },
  σφραγῖδα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4973",
    gloss: "seal",
  },
  σφραγῖδας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G4973",
    gloss: "seal",
  },
  σφραγίδων: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G4973",
    gloss: "seal",
  },
  σφραγίς: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4973",
    gloss: "seal",
  },
  σφραγῖσιν: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G4973",
    gloss: "seal",
  },
  σφυδρά: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G4974",
    gloss: "ankle",
  },
  σχεδὸν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4975",
    gloss: "nearly",
  },
  σχῆμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4976",
    gloss: "form",
  },
  σχήματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G4976",
    gloss: "form",
  },
  ἐσχίσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G4977",
    gloss: "to split",
  },
  ἐσχίσθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G4977",
    gloss: "to split",
  },
  σχιζομένους: {
    parse:
      "V-PPP-APM｜Verb, present, passive, Participle, accusative, plural, masculine",
    GN: "G4977",
    gloss: "to split",
  },
  σχίσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G4977",
    gloss: "to split",
  },
  σχίσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G4977",
    gloss: "to split",
  },
  σχίσωμεν: {
    parse: "V-AAS-1P｜Verb, aorist, active, subjunctive, first, plural",
    GN: "G4977",
    gloss: "to split",
  },
  σχίσμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4978",
    gloss: "split",
  },
  Σχίσμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4978",
    gloss: "split",
  },
  σχίσματα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G4978",
    gloss: "split",
  },
  σχοινία: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G4979",
    gloss: "rope",
  },
  σχοινίων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G4979",
    gloss: "rope",
  },
  σχολάζοντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G4980",
    gloss: "be devoted/empty",
  },
  σχολάσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G4980",
    gloss: "be devoted/empty",
  },
  σχολῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G4981",
    gloss: "lecture hall",
  },
  ἐσῴζοντο: {
    parse: "V-IPI-3P｜Verb, imperfect, passive, indicative, third, plural",
    GN: "G4982",
    gloss: "to save",
  },
  ἐσώθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G4982",
    gloss: "to save",
  },
  ἐσώθημεν: {
    parse: "V-API-1P｜Verb, aorist, passive, indicative, first, plural",
    GN: "G4982",
    gloss: "to save",
  },
  ἔσωσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G4982",
    gloss: "to save",
  },
  σέσωκέν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G4982",
    gloss: "to save",
  },
  σεσῳσμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G4982",
    gloss: "to save",
  },
  σέσωται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G4982",
    gloss: "to save",
  },
  σῴζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G4982",
    gloss: "to save",
  },
  σῴζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4982",
    gloss: "to save",
  },
  σῴζεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G4982",
    gloss: "to save",
  },
  σῴζεσθε: {
    parse: "V-PPI-2P｜Verb, present, passive, indicative, second, plural",
    GN: "G4982",
    gloss: "to save",
  },
  σῴζεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G4982",
    gloss: "to save",
  },
  σῴζετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G4982",
    gloss: "to save",
  },
  σῳζόμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G4982",
    gloss: "to save",
  },
  σῳζομένοις: {
    parse:
      "V-PPP-DPM｜Verb, present, passive, Participle, dative, plural, masculine",
    GN: "G4982",
    gloss: "to save",
  },
  σῳζομένους: {
    parse:
      "V-PPP-APM｜Verb, present, passive, Participle, accusative, plural, masculine",
    GN: "G4982",
    gloss: "to save",
  },
  σωθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G4982",
    gloss: "to save",
  },
  σωθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G4982",
    gloss: "to save",
  },
  σωθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G4982",
    gloss: "to save",
  },
  σωθήσῃ: {
    parse: "V-FPI-2S｜Verb, future, passive, indicative, second, singular",
    GN: "G4982",
    gloss: "to save",
  },
  σωθήσομαι: {
    parse: "V-FPI-1S｜Verb, future, passive, indicative, first, singular",
    GN: "G4982",
    gloss: "to save",
  },
  σωθησόμεθα: {
    parse: "V-FPI-1P｜Verb, future, passive, indicative, first, plural",
    GN: "G4982",
    gloss: "to save",
  },
  Σώθητε: {
    parse: "V-APM-2P｜Verb, aorist, passive, imperative, second, plural",
    GN: "G4982",
    gloss: "to save",
  },
  σωθῆτε: {
    parse: "V-APS-2P｜Verb, aorist, passive, subjunctive, second, plural",
    GN: "G4982",
    gloss: "to save",
  },
  σωθῶ: {
    parse: "V-APS-1S｜Verb, aorist, passive, subjunctive, first, singular",
    GN: "G4982",
    gloss: "to save",
  },
  σωθῶσιν: {
    parse: "V-APS-3P｜Verb, aorist, passive, subjunctive, third, plural",
    GN: "G4982",
    gloss: "to save",
  },
  σῶσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G4982",
    gloss: "to save",
  },
  σώσαντος: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G4982",
    gloss: "to save",
  },
  σώσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G4982",
    gloss: "to save",
  },
  σωσάτω: {
    parse: "V-AAM-3S｜Verb, aorist, active, imperative, third, singular",
    GN: "G4982",
    gloss: "to save",
  },
  σώσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G4982",
    gloss: "to save",
  },
  σώσεις: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G4982",
    gloss: "to save",
  },
  σῶσον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G4982",
    gloss: "to save",
  },
  σῶσόν: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G4982",
    gloss: "to save",
  },
  σώσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G4982",
    gloss: "to save",
  },
  σώσων: {
    parse:
      "V-FAP-NSM｜Verb, future, active, Participle, nominative, singular, masculine",
    GN: "G4982",
    gloss: "to save",
  },
  σῶμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4983",
    gloss: "body",
  },
  σῶμά: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G4983",
    gloss: "body",
  },
  σώματα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G4983",
    gloss: "body",
  },
  σώματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G4983",
    gloss: "body",
  },
  σώματί: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G4983",
    gloss: "body",
  },
  σώματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4983",
    gloss: "body",
  },
  σώματός: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G4983",
    gloss: "body",
  },
  σωμάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G4983",
    gloss: "body",
  },
  σωματικὴ: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G4984",
    gloss: "bodily",
  },
  σωματικῷ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G4984",
    gloss: "bodily",
  },
  σωματικῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4985",
    gloss: "bodily",
  },
  Σώπατρος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G4986",
    gloss: "Sopater",
  },
  σεσωρευμένα: {
    parse:
      "V-RPP-APN｜Verb, perfect, passive, Participle, accusative, plural, neuter",
    GN: "G4987",
    gloss: "to pile up",
  },
  σωρεύσεις: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G4987",
    gloss: "to pile up",
  },
  Σωσθένην: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G4988",
    gloss: "Sosthenes",
  },
  Σωσθένης: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G4988",
    gloss: "Sosthenes",
  },
  Σωσίπατρος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G4989",
    gloss: "Sosipater",
  },
  σωτὴρ: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G4990",
    gloss: "savior",
  },
  σωτῆρα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G4990",
    gloss: "savior",
  },
  σωτῆρι: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G4990",
    gloss: "savior",
  },
  σωτῆρί: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G4990",
    gloss: "savior",
  },
  σωτῆρος: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G4990",
    gloss: "savior",
  },
  σωτηρία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G4991",
    gloss: "salvation",
  },
  σωτηρίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G4991",
    gloss: "salvation",
  },
  σωτηρίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4991",
    gloss: "salvation",
  },
  σωτήριον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G4992",
    gloss: "saving",
  },
  σωτήριόν: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G4992",
    gloss: "saving",
  },
  σωτήριος: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G4992",
    gloss: "saving",
  },
  σωτηρίου: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G4992",
    gloss: "saving",
  },
  σωφρονεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G4993",
    gloss: "be of sound mind",
  },
  σωφρονήσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G4993",
    gloss: "be of sound mind",
  },
  σωφρονοῦμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G4993",
    gloss: "be of sound mind",
  },
  σωφρονοῦντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G4993",
    gloss: "be of sound mind",
  },
  σωφρονίζωσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G4994",
    gloss: "to train",
  },
  σωφρονισμοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G4995",
    gloss: "self-discipline",
  },
  σωφρόνως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G4996",
    gloss: "in self-control",
  },
  σωφροσύνης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G4997",
    gloss: "mental soundness",
  },
  σώφρονα: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G4998",
    gloss: "self-controlled",
  },
  σώφρονας: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G4998",
    gloss: "self-controlled",
  },
  Ταβερνῶν: {
    parse: "N-GPF-L｜Noun, genitive, plural, feminine, Location",
    GN: "G4999",
    gloss: " Taverns",
  },
  Ταβιθά: {
    parse: "N-VSF-P｜Noun, Vocative or nominative, singular, feminine, Person",
    GN: "G5000",
    gloss: "Tabitha",
  },
  τάγματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G5001",
    gloss: "order",
  },
  τακτῇ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G5002",
    gloss: "appointed",
  },
  ταλαιπωρήσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G5003",
    gloss: "to grieve",
  },
  ταλαιπωρία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5004",
    gloss: "misery",
  },
  ταλαιπωρίαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G5004",
    gloss: "misery",
  },
  ταλαίπωρος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5005",
    gloss: "wretched",
  },
  ταλαντιαία: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G5006",
    gloss: "weighing a talent",
  },
  τάλαντα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G5007",
    gloss: "talent",
  },
  τάλαντά: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G5007",
    gloss: "talent",
  },
  τάλαντον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G5007",
    gloss: "talent",
  },
  τάλαντόν: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G5007",
    gloss: "talent",
  },
  ταλάντων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G5007",
    gloss: "talent",
  },
  Ταλιθά: {
    parse: "N-VSF｜Noun, Vocative or nominative, singular, feminine",
    GN: "G5008",
    gloss: "girl",
  },
  ταμείοις: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G5009",
    gloss: "inner room",
  },
  ταμεῖόν: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G5009",
    gloss: "inner room",
  },
  ταμεῖον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G5009",
    gloss: "inner room",
  },
  τάξει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5010",
    gloss: "order",
  },
  τάξιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5010",
    gloss: "order",
  },
  ταπεινοῖς: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G5011",
    gloss: "lowly",
  },
  ταπεινὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5011",
    gloss: "lowly",
  },
  ταπεινούς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G5011",
    gloss: "lowly",
  },
  ταπεινοὺς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G5011",
    gloss: "lowly",
  },
  ταπεινόφρονες: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G5012",
    gloss: "humility",
  },
  ταπεινοφροσύνῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5012",
    gloss: "humility",
  },
  ταπεινοφροσύνην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5012",
    gloss: "humility",
  },
  ταπεινοφροσύνης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5012",
    gloss: "humility",
  },
  ἐταπείνωσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5013",
    gloss: "to humble",
  },
  ταπεινοῦσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G5013",
    gloss: "to humble",
  },
  ταπεινωθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G5013",
    gloss: "to humble",
  },
  ταπεινώθητε: {
    parse: "V-APM-2P｜Verb, aorist, passive, imperative, second, plural",
    GN: "G5013",
    gloss: "to humble",
  },
  Ταπεινώθητε: {
    parse: "V-APM-2P｜Verb, aorist, passive, imperative, second, plural",
    GN: "G5013",
    gloss: "to humble",
  },
  ταπεινῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G5013",
    gloss: "to humble",
  },
  ταπεινώσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5014",
    gloss: "lowliness",
  },
  ταπεινώσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G5013",
    gloss: "to humble",
  },
  ταπεινώσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5014",
    gloss: "lowliness",
  },
  ταπείνωσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5014",
    gloss: "lowliness",
  },
  ἐτάραξαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G5015",
    gloss: "to trouble",
  },
  ἐτάραξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5015",
    gloss: "to trouble",
  },
  ἐταράχθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G5015",
    gloss: "to trouble",
  },
  ἐταράχθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G5015",
    gloss: "to trouble",
  },
  ταρασσέσθω: {
    parse: "V-PPM-3S｜Verb, present, passive, imperative, third, singular",
    GN: "G5015",
    gloss: "to trouble",
  },
  ταράσσοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G5015",
    gloss: "to trouble",
  },
  ταράσσων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G5015",
    gloss: "to trouble",
  },
  ταραχθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G5015",
    gloss: "to trouble",
  },
  ταραχθῆτε: {
    parse: "V-APS-2P｜Verb, aorist, passive, subjunctive, second, plural",
    GN: "G5015",
    gloss: "to trouble",
  },
  τεταραγμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G5015",
    gloss: "to trouble",
  },
  τετάρακται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G5015",
    gloss: "to trouble",
  },
  τάραχος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5017",
    gloss: "disturbance",
  },
  Ταρσέα: {
    parse: "N-ASM-L｜Noun, accusative, singular, masculine, Location",
    GN: "G5018",
    gloss: "Tarsus",
  },
  Ταρσεὺς: {
    parse: "N-NSM-L｜Noun, nominative, singular, masculine, Location",
    GN: "G5018",
    gloss: "Tarsus",
  },
  Ταρσόν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G5019",
    gloss: "Tarsus",
  },
  Ταρσὸν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G5019",
    gloss: "Tarsus",
  },
  Ταρσῷ: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G5019",
    gloss: "Tarsus",
  },
  ταρταρώσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G5020",
    gloss: "Tartarus",
  },
  ἔταξαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G5021",
    gloss: "to appoint",
  },
  ἐτάξατο: {
    parse: "V-AMI-3S｜Verb, aorist, middle, indicative, third, singular",
    GN: "G5021",
    gloss: "to appoint",
  },
  Ταξάμενοι: {
    parse:
      "V-AMP-NPM｜Verb, aorist, middle, Participle, nominative, plural, masculine",
    GN: "G5021",
    gloss: "to appoint",
  },
  τασσόμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G5021",
    gloss: "to appoint",
  },
  τεταγμέναι: {
    parse:
      "V-RPP-NPF｜Verb, perfect, passive, Participle, nominative, plural, feminine",
    GN: "G5021",
    gloss: "to appoint",
  },
  τεταγμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G5021",
    gloss: "to appoint",
  },
  τέτακταί: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G5021",
    gloss: "to appoint",
  },
  ταῦροί: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G5022",
    gloss: "bull",
  },
  ταύρους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G5022",
    gloss: "bull",
  },
  ταύρων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G5022",
    gloss: "bull",
  },
  ταφὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5027",
    gloss: "burial (place)",
  },
  τάφοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G5028",
    gloss: "grave",
  },
  τάφον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5028",
    gloss: "grave",
  },
  τάφος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5028",
    gloss: "grave",
  },
  τάφου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G5028",
    gloss: "grave",
  },
  τάφους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G5028",
    gloss: "grave",
  },
  τάχα: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G5029",
    gloss: "perhaps",
  },
  ταχέως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G5030",
    gloss: "quickly",
  },
  ταχινή: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G5031",
    gloss: "quick",
  },
  ταχινὴν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G5031",
    gloss: "quick",
  },
  τάχιον: {
    parse: "A-ASN-C｜Adjective, accusative, singular, neuter, Comparative",
    GN: "G5032",
    gloss: "more quickly",
  },
  τάχει: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G5034",
    gloss: "quickness",
  },
  ταχύ: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G5036",
    gloss: "quick",
  },
  ταχὺ: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G5036",
    gloss: "quick",
  },
  τάχιστα: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G5036",
    gloss: "quick",
  },
  Ταχὺ: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G5036",
    gloss: "quick",
  },
  ταχὺς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5036",
    gloss: "quick",
  },
  τε: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G5037",
    gloss: "and/both",
  },
  τείχη: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G5038",
    gloss: "wall",
  },
  τεῖχος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G5038",
    gloss: "wall",
  },
  τείχους: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G5038",
    gloss: "wall",
  },
  τεκμηρίοις: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G5039",
    gloss: "clear proof",
  },
  τεκνία: {
    // or nominative
    parse: "N-VPN｜Noun, Vocative, plural, neuter",
    GN: "G5040",
    gloss: "children",
  },
  Τεκνία: {
    // or nominative
    parse: "N-VPN｜Noun, Vocative, plural, neuter",
    GN: "G5040",
    gloss: "children",
  },
  τεκνογονεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G5041",
    gloss: "to have children",
  },
  τεκνογονίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5042",
    gloss: "childbearing",
  },
  τέκνα: {
    parse: "N-VPN｜Noun, Vocative or nominative, plural, neuter",
    GN: "G5043",
    gloss: "child",
  },
  Τέκνα: {
    parse: "N-VPN｜Noun, Vocative or nominative, plural, neuter",
    GN: "G5043",
    gloss: "child",
  },
  τέκνοις: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G5043",
    gloss: "child",
  },
  τέκνον: {
    parse: "N-VSN｜Noun, Vocative or nominative, singular, neuter",
    GN: "G5043",
    gloss: "child",
  },
  Τέκνον: {
    parse: "N-VSN｜Noun, Vocative or nominative, singular, neuter",
    GN: "G5043",
    gloss: "child",
  },
  τέκνου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G5043",
    gloss: "child",
  },
  τέκνῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G5043",
    gloss: "child",
  },
  τέκνων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G5043",
    gloss: "child",
  },
  ἐτεκνοτρόφησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5044",
    gloss: "to raise children",
  },
  τέκτονος: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G5045",
    gloss: "craftsman",
  },
  τέκτων: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5045",
    gloss: "craftsman",
  },
  τελεία: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G5046",
    gloss: "perfect",
  },
  τέλειοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G5046",
    gloss: "perfect",
  },
  τελείοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G5046",
    gloss: "perfect",
  },
  τέλειον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G5046",
    gloss: "perfect",
  },
  τέλειος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5046",
    gloss: "perfect",
  },
  τέλειός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5046",
    gloss: "perfect",
  },
  τελειοτέρας: {
    parse: "A-GSF-C｜Adjective, genitive, singular, feminine, Comparative",
    GN: "G5046",
    gloss: "perfect",
  },
  τελείων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G5046",
    gloss: "perfect",
  },
  τελειότητα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5047",
    gloss: "perfection",
  },
  τελειότητος: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5047",
    gloss: "perfection",
  },
  ἐτελειώθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G5048",
    gloss: "to perfect",
  },
  ἐτελείωσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5048",
    gloss: "to perfect",
  },
  τελειοῦμαι: {
    parse: "V-PPI-1S｜Verb, present, passive, indicative, first, singular",
    GN: "G5048",
    gloss: "to perfect",
  },
  τελειωθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G5048",
    gloss: "to perfect",
  },
  τελειωθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G5048",
    gloss: "to perfect",
  },
  τελειωθῶσιν: {
    parse: "V-APS-3P｜Verb, aorist, passive, subjunctive, third, plural",
    GN: "G5048",
    gloss: "to perfect",
  },
  τελειῶσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G5048",
    gloss: "to perfect",
  },
  τελειωσάντων: {
    parse:
      "V-AAP-GPM｜Verb, aorist, active, Participle, genitive, plural, masculine",
    GN: "G5048",
    gloss: "to perfect",
  },
  τελειώσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G5048",
    gloss: "to perfect",
  },
  τελειώσω: {
    parse: "V-AAS-1S｜Verb, aorist, active, subjunctive, first, singular",
    GN: "G5048",
    gloss: "to perfect",
  },
  τετελείωκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G5048",
    gloss: "to perfect",
  },
  τετελείωμαι: {
    parse: "V-RPI-1S｜Verb, perfect, passive, indicative, first, singular",
    GN: "G5048",
    gloss: "to perfect",
  },
  τετελειωμένη: {
    parse:
      "V-RPP-NSF｜Verb, perfect, passive, Participle, nominative, singular, feminine",
    GN: "G5048",
    gloss: "to perfect",
  },
  τετελειωμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G5048",
    gloss: "to perfect",
  },
  τετελειωμένον: {
    parse:
      "V-RPP-ASM｜Verb, perfect, passive, Participle, accusative, singular, masculine",
    GN: "G5048",
    gloss: "to perfect",
  },
  τετελειωμένων: {
    parse:
      "V-RPP-GPM｜Verb, perfect, passive, Participle, genitive, plural, masculine",
    GN: "G5048",
    gloss: "to perfect",
  },
  τετελείωται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G5048",
    gloss: "to perfect",
  },
  τελείως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G5049",
    gloss: "completely",
  },
  τελείωσις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5050",
    gloss: "perfection",
  },
  τελειωτὴν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5051",
    gloss: "finisher",
  },
  τελεσφοροῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G5052",
    gloss: "to mature",
  },
  ἐτελεύτησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5053",
    gloss: "to decease",
  },
  τελευτᾷ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5053",
    gloss: "to decease",
  },
  τελευτᾶν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G5053",
    gloss: "to decease",
  },
  τελευτάτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G5053",
    gloss: "to decease",
  },
  Τελευτήσαντος: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G5053",
    gloss: "to decease",
  },
  τελευτῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G5053",
    gloss: "to decease",
  },
  τετελευτηκότος: {
    parse:
      "V-RAP-GSM｜Verb, perfect, active, Participle, genitive, singular, masculine",
    GN: "G5053",
    gloss: "to decease",
  },
  τελευτῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5054",
    gloss: "death",
  },
  ἐτέλεσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G5055",
    gloss: "to finish",
  },
  ἐτέλεσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5055",
    gloss: "to finish",
  },
  ἐτελέσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G5055",
    gloss: "to finish",
  },
  τελεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5055",
    gloss: "to finish",
  },
  τελεῖται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G5055",
    gloss: "to finish",
  },
  τελεῖτε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G5055",
    gloss: "to finish",
  },
  τελέσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G5055",
    gloss: "to finish",
  },
  τελεσθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G5055",
    gloss: "to finish",
  },
  τελεσθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G5055",
    gloss: "to finish",
  },
  τελεσθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G5055",
    gloss: "to finish",
  },
  τελεσθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G5055",
    gloss: "to finish",
  },
  τελεσθῶσιν: {
    parse: "V-APS-3P｜Verb, aorist, passive, subjunctive, third, plural",
    GN: "G5055",
    gloss: "to finish",
  },
  τελέσωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G5055",
    gloss: "to finish",
  },
  τελοῦσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G5055",
    gloss: "to finish",
  },
  τετέλεκα: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G5055",
    gloss: "to finish",
  },
  τετέλεσται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G5055",
    gloss: "to finish",
  },
  Τετέλεσται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G5055",
    gloss: "to finish",
  },
  τέλη: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G5056",
    gloss: "goal/tax",
  },
  τέλος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G5056",
    gloss: "goal/tax",
  },
  τέλους: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G5056",
    gloss: "goal/tax",
  },
  τελῶναι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G5057",
    gloss: "tax collector",
  },
  τελώνην: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5057",
    gloss: "tax collector",
  },
  τελώνης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5057",
    gloss: "tax collector",
  },
  τελωνῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G5057",
    gloss: "tax collector",
  },
  τελώνιον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G5058",
    gloss: "tax booth",
  },
  τέρασι: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G5059",
    gloss: "wonders",
  },
  τέρασιν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G5059",
    gloss: "wonders",
  },
  τέρατα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G5059",
    gloss: "wonders",
  },
  τεράτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G5059",
    gloss: "wonders",
  },
  Τέρτιος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G5060",
    gloss: "Tertius",
  },
  Τέρτυλλος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G5061",
    gloss: "Tertullus",
  },
  Τερτύλλου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G5061",
    gloss: "Tertullus",
  },
  τεσσεράκοντα: {
    parse:
      "A-NPF-NUI｜Adjective, nominative, plural, feminine, NUmerical Indiclinable (A)",
    GN: "G5062",
    gloss: "forty",
  },
  Τεσσεράκοντα: {
    parse:
      "A-DPN-NUI｜Adjective, dative, plural, neuter, NUmerical Indiclinable (A)",
    GN: "G5062",
    gloss: "forty",
  },
  τεσσερακονταετῆ: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G5063",
    gloss: "forty years",
  },
  τεσσερακονταετὴς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5063",
    gloss: "forty years",
  },
  τέσσαρα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G5064",
    gloss: "four",
  },
  τέσσαρας: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G5064",
    gloss: "four",
  },
  τέσσαρες: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G5064",
    gloss: "four",
  },
  τέσσαρσιν: {
    parse: "A-DPN｜Adjective, dative, plural, neuter",
    GN: "G5064",
    gloss: "four",
  },
  τεσσάρων: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G5064",
    gloss: "four",
  },
  τεσσαρεσκαιδεκάτη: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G5065",
    gloss: "fourteenth",
  },
  Τεσσαρεσκαιδεκάτην: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G5065",
    gloss: "fourteenth",
  },
  τεταρταῖος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5066",
    gloss: "fourth",
  },
  τετάρτῃ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G5067",
    gloss: "fourth",
  },
  τετάρτην: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G5067",
    gloss: "fourth",
  },
  τετάρτης: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G5067",
    gloss: "fourth",
  },
  τέταρτον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G5067",
    gloss: "fourth",
  },
  τέταρτος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5067",
    gloss: "fourth",
  },
  τετάρτου: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G5067",
    gloss: "fourth",
  },
  τετράγωνος: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G5068",
    gloss: "square",
  },
  τετραδίοις: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G5069",
    gloss: "squad of four",
  },
  τετρακισχίλιοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G5070",
    gloss: "four thousand",
  },
  τετρακισχιλίους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G5070",
    gloss: "four thousand",
  },
  τετρακισχιλίων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G5070",
    gloss: "four thousand",
  },
  τετρακόσια: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G5071",
    gloss: "four hundred",
  },
  τετρακοσίοις: {
    parse: "A-DPN｜Adjective, dative, plural, neuter",
    GN: "G5071",
    gloss: "four hundred",
  },
  τετρακοσίων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G5071",
    gloss: "four hundred",
  },
  τετράμηνός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5072",
    gloss: "four months",
  },
  τετραπλοῦν: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G5073",
    gloss: "fourfold",
  },
  τετράποδα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G5074",
    gloss: "four-footed",
  },
  τετραπόδων: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G5074",
    gloss: "four-footed",
  },
  τετρααρχοῦντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G5075",
    gloss: "be a tetrarch",
  },
  τετραάρχης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5076",
    gloss: "tetrarch",
  },
  τετραάρχου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G5076",
    gloss: "tetrarch",
  },
  τεφρώσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G5077",
    gloss: "to turn to ashes",
  },
  τέχνῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5078",
    gloss: "skill",
  },
  τέχνης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5078",
    gloss: "skill",
  },
  τεχνῖται: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G5079",
    gloss: "craftsman",
  },
  τεχνίταις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G5079",
    gloss: "craftsman",
  },
  τεχνίτης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5079",
    gloss: "craftsman",
  },
  τήκεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G5080",
    gloss: "to melt",
  },
  τηλαυγῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G5081",
    gloss: "clearly",
  },
  τηλικαῦτα: {
    parse: "D-NPN｜Demonstrative pronoun, nominative, plural, neuter",
    GN: "G5082",
    gloss: "so great",
  },
  τηλικαύτης: {
    parse: "D-GSF｜Demonstrative pronoun, genitive, singular, feminine",
    GN: "G5082",
    gloss: "so great",
  },
  τηλικοῦτος: {
    parse: "D-NSM｜Demonstrative pronoun, nominative, singular, masculine",
    GN: "G5082",
    gloss: "so great",
  },
  τηλικούτου: {
    parse: "D-GSM｜Demonstrative pronoun, genitive, singular, masculine",
    GN: "G5082",
    gloss: "so great",
  },
  ἐτηρεῖτο: {
    parse: "V-IPI-3S｜Verb, imperfect, passive, indicative, third, singular",
    GN: "G5083",
    gloss: "protect",
  },
  ἐτήρησα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G5083",
    gloss: "protect",
  },
  ἐτήρησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G5083",
    gloss: "protect",
  },
  ἐτήρησας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G5083",
    gloss: "protect",
  },
  ἐτήρησάς: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G5083",
    gloss: "protect",
  },
  ἐτήρουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G5083",
    gloss: "protect",
  },
  τετήρηκα: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G5083",
    gloss: "protect",
  },
  τετήρηκαν: {
    parse: "V-RAI-3P｜Verb, perfect, active, indicative, third, plural",
    GN: "G5083",
    gloss: "protect",
  },
  τετήρηκας: {
    parse: "V-RAI-2S｜Verb, perfect, active, indicative, second, singular",
    GN: "G5083",
    gloss: "protect",
  },
  τετήρηκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G5083",
    gloss: "protect",
  },
  τετηρημένην: {
    parse:
      "V-RPP-ASF｜Verb, perfect, passive, Participle, accusative, singular, feminine",
    GN: "G5083",
    gloss: "protect",
  },
  τετηρημένοις: {
    parse:
      "V-RPP-DPM｜Verb, perfect, passive, Participle, dative, plural, masculine",
    GN: "G5083",
    gloss: "protect",
  },
  τετήρηται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G5083",
    gloss: "protect",
  },
  τηρεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5083",
    gloss: "protect",
  },
  τήρει: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G5083",
    gloss: "protect",
  },
  τηρεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G5083",
    gloss: "protect",
  },
  τηρεῖσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G5083",
    gloss: "protect",
  },
  τηρεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G5083",
    gloss: "protect",
  },
  τηρῇ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G5083",
    gloss: "protect",
  },
  τηρηθείη: {
    parse: "V-APO-3S｜Verb, aorist, passive, Optative, third, singular",
    GN: "G5083",
    gloss: "protect",
  },
  τηρηθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G5083",
    gloss: "protect",
  },
  τηρῆσαί: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G5083",
    gloss: "protect",
  },
  τηρήσαντας: {
    parse:
      "V-AAP-APM｜Verb, aorist, active, Participle, accusative, plural, masculine",
    GN: "G5083",
    gloss: "protect",
  },
  τηρήσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G5083",
    gloss: "protect",
  },
  τηρήσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5084",
    gloss: "jail/keeping",
  },
  τηρήσετε: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G5083",
    gloss: "protect",
  },
  τηρήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G5083",
    gloss: "protect",
  },
  τηρήσῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G5083",
    gloss: "protect",
  },
  τηρήσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G5083",
    gloss: "protect",
  },
  τήρησον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G5083",
    gloss: "protect",
  },
  τηρήσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G5083",
    gloss: "protect",
  },
  τηρήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G5083",
    gloss: "protect",
  },
  τηροῦμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G5083",
    gloss: "protect",
  },
  τηρούμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G5083",
    gloss: "protect",
  },
  τηρουμένους: {
    parse:
      "V-PPP-APM｜Verb, present, passive, Participle, accusative, plural, masculine",
    GN: "G5083",
    gloss: "protect",
  },
  τηροῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G5083",
    gloss: "protect",
  },
  τηρούντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G5083",
    gloss: "protect",
  },
  τηρῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G5083",
    gloss: "protect",
  },
  τηρῶμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G5083",
    gloss: "protect",
  },
  τηρῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G5083",
    gloss: "protect",
  },
  τήρησιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5084",
    gloss: "jail/keeping",
  },
  τήρησις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5084",
    gloss: "jail/keeping",
  },
  Τιβεριάδος: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G5085",
    gloss: "Tiberias",
  },
  Τιβερίου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G5086",
    gloss: "Tiberius",
  },
  ἔθεντο: {
    parse: "V-2AMI-3P｜Verb, 2nd aorist, middle, indicative, third, plural",
    GN: "G5087",
    gloss: "to place",
  },
  ἔθεσθε: {
    parse: "V-2AMI-2P｜Verb, 2nd aorist, middle, indicative, second, plural",
    GN: "G5087",
    gloss: "to place",
  },
  ἔθετο: {
    parse: "V-2AMI-3S｜Verb, 2nd aorist, middle, indicative, third, singular",
    GN: "G5087",
    gloss: "to place",
  },
  ἔθηκα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G5087",
    gloss: "to place",
  },
  ἔθηκαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G5087",
    gloss: "to place",
  },
  ἔθηκας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G5087",
    gloss: "to place",
  },
  ἔθηκεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5087",
    gloss: "to place",
  },
  ἔθου: {
    parse: "V-2AMI-2S｜Verb, 2nd aorist, middle, indicative, second, singular",
    GN: "G5087",
    gloss: "to place",
  },
  ἐτέθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G5087",
    gloss: "to place",
  },
  ἐτέθην: {
    parse: "V-API-1S｜Verb, aorist, passive, indicative, first, singular",
    GN: "G5087",
    gloss: "to place",
  },
  ἐτέθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G5087",
    gloss: "to place",
  },
  ἐτίθει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G5087",
    gloss: "to place",
  },
  ἐτίθεσαν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G5087",
    gloss: "to place",
  },
  ἐτίθουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G5087",
    gloss: "to place",
  },
  θεῖναι: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G5087",
    gloss: "to place",
  },
  θεὶς: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G5087",
    gloss: "to place",
  },
  θέμενος: {
    parse:
      "V-2AMP-NSM｜Verb, 2nd aorist, middle, Participle, nominative, singular, masculine",
    GN: "G5087",
    gloss: "to place",
  },
  θέντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G5087",
    gloss: "to place",
  },
  θέντος: {
    parse:
      "V-2AAP-GSM｜Verb, 2nd aorist, active, Participle, genitive, singular, masculine",
    GN: "G5087",
    gloss: "to place",
  },
  Θέσθε: {
    parse: "V-2AMM-2P｜Verb, 2nd aorist, middle, imperative, second, plural",
    GN: "G5087",
    gloss: "to place",
  },
  θέτε: {
    parse: "V-2AAM-2P｜Verb, 2nd aorist, active, imperative, second, plural",
    GN: "G5087",
    gloss: "to place",
  },
  θῇ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G5087",
    gloss: "to place",
  },
  θήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G5087",
    gloss: "to place",
  },
  θήσεις: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G5087",
    gloss: "to place",
  },
  θήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G5087",
    gloss: "to place",
  },
  θῶ: {
    parse: "V-2AAS-1S｜Verb, 2nd aorist, active, subjunctive, first, singular",
    GN: "G5087",
    gloss: "to place",
  },
  θῶμεν: {
    parse: "V-2AAS-1P｜Verb, 2nd aorist, active, subjunctive, first, plural",
    GN: "G5087",
    gloss: "to place",
  },
  τέθεικά: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G5087",
    gloss: "to place",
  },
  Τέθεικά: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G5087",
    gloss: "to place",
  },
  τεθείκατε: {
    parse: "V-RAI-2P｜Verb, perfect, active, indicative, second, plural",
    GN: "G5087",
    gloss: "to place",
  },
  τεθεικώς: {
    parse:
      "V-RAP-NSM｜Verb, perfect, active, Participle, nominative, singular, masculine",
    GN: "G5087",
    gloss: "to place",
  },
  τεθειμένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G5087",
    gloss: "to place",
  },
  τέθειται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G5087",
    gloss: "to place",
  },
  τεθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G5087",
    gloss: "to place",
  },
  τεθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G5087",
    gloss: "to place",
  },
  τεθῶσιν: {
    parse: "V-APS-3P｜Verb, aorist, passive, subjunctive, third, plural",
    GN: "G5087",
    gloss: "to place",
  },
  τιθέασιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G5087",
    gloss: "to place",
  },
  τιθεὶς: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G5087",
    gloss: "to place",
  },
  τιθέναι: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G5087",
    gloss: "to place",
  },
  τιθέντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G5087",
    gloss: "to place",
  },
  τιθέτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G5087",
    gloss: "to place",
  },
  τίθημι: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G5087",
    gloss: "to place",
  },
  τίθησιν: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5087",
    gloss: "to place",
  },
  ἔτεκεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G5088",
    gloss: "to give birth to",
  },
  ἐτέχθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G5088",
    gloss: "to give birth to",
  },
  τεκεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G5088",
    gloss: "to give birth to",
  },
  τέκῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G5088",
    gloss: "to give birth to",
  },
  τέξεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G5088",
    gloss: "to give birth to",
  },
  Τέξεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G5088",
    gloss: "to give birth to",
  },
  τέξῃ: {
    parse:
      "V-FDI-2S｜Verb, future, Middle deponent, indicative, second, singular",
    GN: "G5088",
    gloss: "to give birth to",
  },
  τεχθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G5088",
    gloss: "to give birth to",
  },
  τίκτει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5088",
    gloss: "to give birth to",
  },
  τίκτῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G5088",
    gloss: "to give birth to",
  },
  τίκτουσα: {
    parse:
      "V-PAP-VSF｜Verb, present, active, Participle, Vocative or nominative, singular, feminine",
    GN: "G5088",
    gloss: "to give birth to",
  },
  ἔτιλλον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G5089",
    gloss: "to pluck",
  },
  τίλλειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G5089",
    gloss: "to pluck",
  },
  τίλλοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G5089",
    gloss: "to pluck",
  },
  Τιμαίου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G5090",
    gloss: "Timaeus",
  },
  ἐτίμησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G5091",
    gloss: "to honor",
  },
  ἐτιμήσαντο: {
    parse: "V-AMI-3P｜Verb, aorist, middle, indicative, third, plural",
    GN: "G5091",
    gloss: "to honor",
  },
  τετιμημένου: {
    parse:
      "V-RPP-GSM｜Verb, perfect, passive, Participle, genitive, singular, masculine",
    GN: "G5091",
    gloss: "to honor",
  },
  τιμᾷ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5091",
    gloss: "to honor",
  },
  τίμα: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G5091",
    gloss: "to honor",
  },
  Τίμα: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G5091",
    gloss: "to honor",
  },
  τιμᾶτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G5091",
    gloss: "to honor",
  },
  τιμήσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G5091",
    gloss: "to honor",
  },
  τιμήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G5091",
    gloss: "to honor",
  },
  τιμῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G5091",
    gloss: "to honor",
  },
  τιμῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G5091",
    gloss: "to honor",
  },
  τιμῶσι: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G5091",
    gloss: "to honor",
  },
  τιμῶσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G5091",
    gloss: "to honor",
  },
  τιμαῖς: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G5092",
    gloss: "honor",
  },
  τιμὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G5092",
    gloss: "honor",
  },
  τιμῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5092",
    gloss: "honor",
  },
  τιμὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5092",
    gloss: "honor",
  },
  τιμήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5092",
    gloss: "honor",
  },
  τιμὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5092",
    gloss: "honor",
  },
  τιμῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5092",
    gloss: "honor",
  },
  τίμια: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G5093",
    gloss: "precious",
  },
  τιμίαν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G5093",
    gloss: "precious",
  },
  τίμιον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G5093",
    gloss: "precious",
  },
  τίμιος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5093",
    gloss: "precious",
  },
  τιμίου: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G5093",
    gloss: "precious",
  },
  τιμίους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G5093",
    gloss: "precious",
  },
  τιμίῳ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G5093",
    gloss: "precious",
  },
  τιμιωτάτου: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G5093",
    gloss: "precious",
  },
  τιμιωτάτῳ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G5093",
    gloss: "precious",
  },
  τιμιότητος: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5094",
    gloss: "wealth",
  },
  Τιμόθεε: {
    parse: "N-VSM-P｜Noun, Vocative or nominative, singular, masculine, Person",
    GN: "G5095",
    gloss: "Timothy",
  },
  Τιμόθεον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G5095",
    gloss: "Timothy",
  },
  Τιμόθεος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G5095",
    gloss: "Timothy",
  },
  Τιμοθέου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G5095",
    gloss: "Timothy",
  },
  Τιμοθέῳ: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G5095",
    gloss: "Timothy",
  },
  Τίμωνα: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G5096",
    gloss: "Timon",
  },
  τιμωρηθῶσιν: {
    parse: "V-APS-3P｜Verb, aorist, passive, subjunctive, third, plural",
    GN: "G5097",
    gloss: "to punish",
  },
  τιμωρῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G5097",
    gloss: "to punish",
  },
  τιμωρίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5098",
    gloss: "punishment",
  },
  τίσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G5099",
    gloss: "to pay",
  },
  τι: {
    parse: "X-NSN｜indefinite pronoun, nominative, singular, neuter",
    GN: "G5100",
    gloss: "one",
  },
  τί: {
    parse: "I-NSN｜Interrogative pronoun, nominative, singular, neuter",
    GN: "G5101",
    gloss: "which?",
  },
  τινα: {
    parse: "X-NPN｜indefinite pronoun, nominative, plural, neuter",
    GN: "G5100",
    gloss: "one",
  },
  τινά: {
    parse: "X-ASF｜indefinite pronoun, accusative, singular, feminine",
    GN: "G5100",
    gloss: "one",
  },
  τινὰ: {
    parse: "X-ASM｜indefinite pronoun, accusative, singular, masculine",
    GN: "G5100",
    gloss: "one",
  },
  τινας: {
    parse: "X-APM｜indefinite pronoun, accusative, plural, masculine",
    GN: "G5100",
    gloss: "one",
  },
  τινάς: {
    parse: "X-APF｜indefinite pronoun, accusative, plural, feminine",
    GN: "G5100",
    gloss: "one",
  },
  τινὰς: {
    parse: "X-APM｜indefinite pronoun, accusative, plural, masculine",
    GN: "G5100",
    gloss: "one",
  },
  τινες: {
    parse: "X-NPM｜indefinite pronoun, nominative, plural, masculine",
    GN: "G5100",
    gloss: "one",
  },
  τινές: {
    parse: "X-NPM｜indefinite pronoun, nominative, plural, masculine",
    GN: "G5100",
    gloss: "one",
  },
  τινὲς: {
    parse: "X-NPM｜indefinite pronoun, nominative, plural, masculine",
    GN: "G5100",
    gloss: "one",
  },
  Τινὲς: {
    parse: "X-NPM｜indefinite pronoun, nominative, plural, masculine",
    GN: "G5100",
    gloss: "one",
  },
  τινι: {
    parse: "X-DSN｜indefinite pronoun, dative, singular, neuter",
    GN: "G5100",
    gloss: "one",
  },
  τινὶ: {
    parse: "X-DSM｜indefinite pronoun, dative, singular, masculine",
    GN: "G5100",
    gloss: "one",
  },
  τινος: {
    parse: "X-GSN｜indefinite pronoun, genitive, singular, neuter",
    GN: "G5100",
    gloss: "one",
  },
  τινός: {
    parse: "X-GSM｜indefinite pronoun, genitive, singular, masculine",
    GN: "G5100",
    gloss: "one",
  },
  τινὸς: {
    parse: "X-GSM｜indefinite pronoun, genitive, singular, masculine",
    GN: "G5100",
    gloss: "one",
  },
  τινῶν: {
    parse: "X-GPM｜indefinite pronoun, genitive, plural, masculine",
    GN: "G5100",
    gloss: "one",
  },
  τινων: {
    parse: "X-GPN｜indefinite pronoun, genitive, plural, neuter",
    GN: "G5100",
    gloss: "one",
  },
  Τινῶν: {
    parse: "X-GPM｜indefinite pronoun, genitive, plural, masculine",
    GN: "G5100",
    gloss: "one",
  },
  τις: {
    parse: "X-NSM｜indefinite pronoun, nominative, singular, masculine",
    GN: "G5100",
    gloss: "one",
  },
  τίς: {
    parse: "I-NSM｜Interrogative pronoun, nominative, singular, masculine",
    GN: "G5101",
    gloss: "which?",
  },
  τισιν: {
    parse: "X-DPM｜indefinite pronoun, dative, plural, masculine",
    GN: "G5100",
    gloss: "one",
  },
  τισίν: {
    parse: "X-DPM｜indefinite pronoun, dative, plural, masculine",
    GN: "G5100",
    gloss: "one",
  },
  τισὶν: {
    parse: "X-DPM｜indefinite pronoun, dative, plural, masculine",
    GN: "G5100",
    gloss: "one",
  },
  Τί: {
    parse: "I-NSN｜Interrogative pronoun, nominative, singular, neuter",
    GN: "G5101",
    gloss: "which?",
  },
  τίνα: {
    parse: "I-NPN｜Interrogative pronoun, nominative, plural, neuter",
    GN: "G5101",
    gloss: "which?",
  },
  Τίνα: {
    parse: "I-ASM｜Interrogative pronoun, accusative, singular, masculine",
    GN: "G5101",
    gloss: "which?",
  },
  τίνας: {
    parse: "I-APM｜Interrogative pronoun, accusative, plural, masculine",
    GN: "G5101",
    gloss: "which?",
  },
  τίνες: {
    parse: "I-NPM｜Interrogative pronoun, nominative, plural, masculine",
    GN: "G5101",
    gloss: "which?",
  },
  Τίνες: {
    parse: "I-NPM｜Interrogative pronoun, nominative, plural, masculine",
    GN: "G5101",
    gloss: "which?",
  },
  τίνι: {
    parse: "I-DSN｜Interrogative pronoun, dative, singular, neuter",
    GN: "G5101",
    gloss: "which?",
  },
  Τίνι: {
    parse: "I-DSN｜Interrogative pronoun, dative, singular, neuter",
    GN: "G5101",
    gloss: "which?",
  },
  τίνος: {
    parse: "I-GSN｜Interrogative pronoun, genitive, singular, neuter",
    GN: "G5101",
    gloss: "which?",
  },
  Τίνος: {
    parse: "I-GSM｜Interrogative pronoun, genitive, singular, masculine",
    GN: "G5101",
    gloss: "which?",
  },
  τίνων: {
    parse: "I-GPN｜Interrogative pronoun, genitive, plural, neuter",
    GN: "G5101",
    gloss: "which?",
  },
  Τίς: {
    parse: "I-NSM｜Interrogative pronoun, nominative, singular, masculine",
    GN: "G5101",
    gloss: "which?",
  },
  τίσιν: {
    parse: "I-DPM｜Interrogative pronoun, dative, plural, masculine",
    GN: "G5101",
    gloss: "which?",
  },
  τίτλον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5102",
    gloss: "title",
  },
  Τίτον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G5103",
    gloss: "Titus",
  },
  Τίτος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G5103",
    gloss: "Titus",
  },
  Τίτου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G5103",
    gloss: "Titus",
  },
  Τίτῳ: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G5103",
    gloss: "Titus",
  },
  τοιγαροῦν: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G5105",
    gloss: "therefore",
  },
  Τοιγαροῦν: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G5105",
    gloss: "therefore",
  },
  τοίνυν: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G5106",
    gloss: "then",
  },
  Τοίνυν: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G5106",
    gloss: "then",
  },
  τοιᾶσδε: {
    parse: "D-GSF｜Demonstrative pronoun, genitive, singular, feminine",
    GN: "G5107",
    gloss: "such as this",
  },
  τοιαῦτα: {
    parse: "D-APN｜Demonstrative pronoun, accusative, plural, neuter",
    GN: "G5108",
    gloss: "such as this",
  },
  τοιαῦται: {
    parse: "D-NPF｜Demonstrative pronoun, nominative, plural, feminine",
    GN: "G5108",
    gloss: "such as this",
  },
  τοιαύταις: {
    parse: "D-DPF｜Demonstrative pronoun, dative, plural, feminine",
    GN: "G5108",
    gloss: "such as this",
  },
  τοιαύτας: {
    parse: "D-APF｜Demonstrative pronoun, accusative, plural, feminine",
    GN: "G5108",
    gloss: "such as this",
  },
  τοιαύτη: {
    parse: "D-NSF｜Demonstrative pronoun, nominative, singular, feminine",
    GN: "G5108",
    gloss: "such as this",
  },
  τοιαύτην: {
    parse: "D-ASF｜Demonstrative pronoun, accusative, singular, feminine",
    GN: "G5108",
    gloss: "such as this",
  },
  τοιοῦτο: {
    parse: "D-ASN｜Demonstrative pronoun, accusative, singular, neuter",
    GN: "G5108",
    gloss: "such as this",
  },
  τοιοῦτοι: {
    parse: "D-NPM｜Demonstrative pronoun, nominative, plural, masculine",
    GN: "G5108",
    gloss: "such as this",
  },
  τοιούτοις: {
    parse: "D-DPN｜Demonstrative pronoun, dative, plural, neuter",
    GN: "G5108",
    gloss: "such as this",
  },
  τοιοῦτον: {
    parse: "D-ASM｜Demonstrative pronoun, accusative, singular, masculine",
    GN: "G5108",
    gloss: "such as this",
  },
  τοιοῦτος: {
    parse: "D-NSM｜Demonstrative pronoun, nominative, singular, masculine",
    GN: "G5108",
    gloss: "such as this",
  },
  Τοιοῦτος: {
    parse: "D-NSM｜Demonstrative pronoun, nominative, singular, masculine",
    GN: "G5108",
    gloss: "such as this",
  },
  τοιούτου: {
    parse: "D-GSM｜Demonstrative pronoun, genitive, singular, masculine",
    GN: "G5108",
    gloss: "such as this",
  },
  τοιούτους: {
    parse: "D-APM｜Demonstrative pronoun, accusative, plural, masculine",
    GN: "G5108",
    gloss: "such as this",
  },
  τοιούτῳ: {
    parse: "D-DSM｜Demonstrative pronoun, dative, singular, masculine",
    GN: "G5108",
    gloss: "such as this",
  },
  τοιούτων: {
    parse: "D-GPN｜Demonstrative pronoun, genitive, plural, neuter",
    GN: "G5108",
    gloss: "such as this",
  },
  τοῖχε: {
    // or nominative
    parse: "N-VSM｜Noun, Vocative, singular, masculine",
    GN: "G5109",
    gloss: "wall",
  },
  τόκῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G5110",
    gloss: "interest",
  },
  ἐτόλμα: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G5111",
    gloss: "be bold",
  },
  ἐτόλμησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5111",
    gloss: "be bold",
  },
  ἐτόλμησέν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5111",
    gloss: "be bold",
  },
  ἐτόλμων: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G5111",
    gloss: "be bold",
  },
  τολμᾷ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G5111",
    gloss: "be bold",
  },
  Τολμᾷ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5111",
    gloss: "be bold",
  },
  τολμᾶν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G5111",
    gloss: "be bold",
  },
  τολμῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G5111",
    gloss: "be bold",
  },
  τολμήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G5111",
    gloss: "be bold",
  },
  τολμήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G5111",
    gloss: "be bold",
  },
  τολμῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G5111",
    gloss: "be bold",
  },
  τολμῶμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G5111",
    gloss: "be bold",
  },
  τολμηρότερον: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G5112",
    gloss: "more boldly",
  },
  Τολμηταὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G5113",
    gloss: "bold man",
  },
  τομώτερος: {
    parse: "A-NSM-C｜Adjective, nominative, singular, masculine, Comparative",
    GN: "G5114",
    gloss: "sharper",
  },
  τόξον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G5115",
    gloss: "bow",
  },
  τοπάζιον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G5116",
    gloss: "topaz",
  },
  τόποις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G5117",
    gloss: "place",
  },
  τόπον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5117",
    gloss: "place",
  },
  Τόπον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5117",
    gloss: "place",
  },
  τόπος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5117",
    gloss: "place",
  },
  Τόπος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5117",
    gloss: "place",
  },
  τόπου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G5117",
    gloss: "place",
  },
  τόπους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G5117",
    gloss: "place",
  },
  τόπῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G5117",
    gloss: "place",
  },
  τόπων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G5117",
    gloss: "place",
  },
  τοσαῦτα: {
    parse: "D-NPN｜Demonstrative pronoun, nominative, plural, neuter",
    GN: "G5118",
    gloss: "so great",
  },
  τοσαύτην: {
    parse: "D-ASF｜Demonstrative pronoun, accusative, singular, feminine",
    GN: "G5118",
    gloss: "so great",
  },
  τοσοῦτο: {
    parse: "D-ASN｜Demonstrative pronoun, accusative, singular, neuter",
    GN: "G5118",
    gloss: "so great",
  },
  τοσοῦτοι: {
    parse: "D-NPM｜Demonstrative pronoun, nominative, plural, masculine",
    GN: "G5118",
    gloss: "so great",
  },
  τοσοῦτον: {
    parse: "D-ASN｜Demonstrative pronoun, accusative, singular, neuter",
    GN: "G5118",
    gloss: "so great",
  },
  τοσοῦτος: {
    parse: "D-NSM｜Demonstrative pronoun, nominative, singular, masculine",
    GN: "G5118",
    gloss: "so great",
  },
  τοσούτου: {
    parse: "D-GSN｜Demonstrative pronoun, genitive, singular, neuter",
    GN: "G5118",
    gloss: "so great",
  },
  τοσούτους: {
    parse: "D-APM｜Demonstrative pronoun, accusative, plural, masculine",
    GN: "G5118",
    gloss: "so great",
  },
  Τοσούτῳ: {
    parse: "D-DSM｜Demonstrative pronoun, dative, singular, masculine",
    GN: "G5118",
    gloss: "so great",
  },
  τοσούτῳ: {
    parse: "D-DSN｜Demonstrative pronoun, dative, singular, neuter",
    GN: "G5118",
    gloss: "so great",
  },
  τοσούτων: {
    parse: "D-GPM｜Demonstrative pronoun, genitive, plural, masculine",
    GN: "G5118",
    gloss: "so great",
  },
  τότε: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G5119",
    gloss: "then",
  },
  Τότε: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G5119",
    gloss: "then",
  },
  τοὐναντίον: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G5121",
    gloss: "instead",
  },
  τοὔνομα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G5122",
    gloss: "by name",
  },
  τράγων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G5131",
    gloss: "he-goat",
  },
  τράπεζα: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5132",
    gloss: "table",
  },
  τραπέζαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G5132",
    gloss: "table",
  },
  τράπεζαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5132",
    gloss: "table",
  },
  τραπέζας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G5132",
    gloss: "table",
  },
  τραπέζης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5132",
    gloss: "table",
  },
  τραπεζίταις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G5133",
    gloss: "money lender",
  },
  τραύματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G5134",
    gloss: "wound",
  },
  τετραυματισμένους: {
    parse:
      "V-RPP-APM｜Verb, perfect, passive, Participle, accusative, plural, masculine",
    GN: "G5135",
    gloss: "to wound",
  },
  τραυματίσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G5135",
    gloss: "to wound",
  },
  τετραχηλισμένα: {
    parse:
      "V-RPP-NPN｜Verb, perfect, passive, Participle, nominative, plural, neuter",
    GN: "G5136",
    gloss: "to lay bare",
  },
  τράχηλον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5137",
    gloss: "neck",
  },
  τραχεῖαι: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G5138",
    gloss: "rough",
  },
  τραχεῖς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G5138",
    gloss: "rough",
  },
  Τραχωνίτιδος: {
    parse: "A-GSF-L｜Adjective, genitive, singular, feminine, Location",
    GN: "G5139",
    gloss: "Trachonitis",
  },
  τρεῖς: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G5140",
    gloss: "Three",
  },
  τρία: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G5140",
    gloss: "Three",
  },
  τρισὶν: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G5140",
    gloss: "Three",
  },
  τρισίν: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G5140",
    gloss: "Three",
  },
  τριῶν: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G5140",
    gloss: "Three",
  },
  Τριῶν: {
    parse: "A-GPF-L｜Adjective, genitive, plural, feminine, Location",
    GN: "G5140",
    gloss: "Three",
  },
  τρέμουσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G5141",
    gloss: "to tremble",
  },
  τρέμουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G5141",
    gloss: "to tremble",
  },
  ἐθρέψαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G5142",
    gloss: "to feed",
  },
  ἔθρεψαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G5142",
    gloss: "to feed",
  },
  ἐθρέψατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G5142",
    gloss: "to feed",
  },
  τεθραμμένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G5142",
    gloss: "to feed",
  },
  τρέφει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5142",
    gloss: "to feed",
  },
  τρέφεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G5142",
    gloss: "to feed",
  },
  τρέφεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G5142",
    gloss: "to feed",
  },
  τρέφωσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G5142",
    gloss: "to feed",
  },
  δραμὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G5143",
    gloss: "to run",
  },
  ἔδραμεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G5143",
    gloss: "to run",
  },
  ἔδραμον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G5143",
    gloss: "to run",
  },
  Ἐτρέχετε: {
    parse: "V-IAI-2P｜Verb, imperfect, active, indicative, second, plural",
    GN: "G5143",
    gloss: "to run",
  },
  ἔτρεχον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G5143",
    gloss: "to run",
  },
  τρέχει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5143",
    gloss: "to run",
  },
  τρέχετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G5143",
    gloss: "to run",
  },
  τρέχῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G5143",
    gloss: "to run",
  },
  τρέχοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G5143",
    gloss: "to run",
  },
  τρέχοντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G5143",
    gloss: "to run",
  },
  τρεχόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G5143",
    gloss: "to run",
  },
  τρέχουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G5143",
    gloss: "to run",
  },
  τρέχω: {
    parse: "V-PAS-1S｜Verb, present, active, subjunctive, first, singular",
    GN: "G5143",
    gloss: "to run",
  },
  τρέχωμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G5143",
    gloss: "to run",
  },
  τριάκοντα: {
    parse:
      "A-GPN-NUI｜Adjective, genitive, plural, neuter, NUmerical Indiclinable (A)",
    GN: "G5144",
    gloss: "thirty",
  },
  τριακοσίων: {
    parse: "A-GPN｜Adjective, genitive, plural, neuter",
    GN: "G5145",
    gloss: "three hundred",
  },
  τριβόλους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G5146",
    gloss: "thistle",
  },
  τριβόλων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G5146",
    gloss: "thistle",
  },
  τρίβους: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G5147",
    gloss: "path",
  },
  τριετίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5148",
    gloss: "three years",
  },
  τρίζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5149",
    gloss: "to gnash",
  },
  τρίμηνον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G5150",
    gloss: "three months",
  },
  τρίς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G5151",
    gloss: "three times",
  },
  τρὶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G5151",
    gloss: "three times",
  },
  τριστέγου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G5152",
    gloss: "third story",
  },
  τρισχίλιαι: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G5153",
    gloss: "three thousand",
  },
  τρίτῃ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G5154",
    gloss: "third",
  },
  τρίτη: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G5154",
    gloss: "third",
  },
  τρίτην: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G5154",
    gloss: "third",
  },
  τρίτης: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G5154",
    gloss: "third",
  },
  τρίτον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G5154",
    gloss: "third",
  },
  Τρίτον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G5154",
    gloss: "third",
  },
  τρίτος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5154",
    gloss: "third",
  },
  τρίτου: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G5154",
    gloss: "third",
  },
  τρίχινος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5155",
    gloss: "of hair",
  },
  τρόμος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5156",
    gloss: "trembling",
  },
  τρόμου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G5156",
    gloss: "trembling",
  },
  τρόμῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G5156",
    gloss: "trembling",
  },
  τροπῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5157",
    gloss: "turning",
  },
  τρόπον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5158",
    gloss: "way",
  },
  τρόπος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5158",
    gloss: "way",
  },
  τρόπῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G5158",
    gloss: "way",
  },
  ἐτροποφόρησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5159",
    gloss: "to put up with",
  },
  τροφὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G5160",
    gloss: "food",
  },
  τροφή: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5160",
    gloss: "food",
  },
  τροφὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5160",
    gloss: "food",
  },
  τροφὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5160",
    gloss: "food",
  },
  τροφῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5160",
    gloss: "food",
  },
  Τρόφιμον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G5161",
    gloss: "Trophimus",
  },
  Τρόφιμος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G5161",
    gloss: "Trophimus",
  },
  τροφὸς: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5162",
    gloss: "nursing mother",
  },
  τροχιὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G5163",
    gloss: "track",
  },
  τροχὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5164",
    gloss: "course/wheel",
  },
  τρύβλιον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G5165",
    gloss: "bowl",
  },
  τρυβλίῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G5165",
    gloss: "bowl",
  },
  ἐτρύγησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5166",
    gloss: "to harvest",
  },
  τρύγησον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G5166",
    gloss: "to harvest",
  },
  τρυγῶσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G5166",
    gloss: "to harvest",
  },
  τρυγόνων: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G5167",
    gloss: "dove",
  },
  τρυμαλιᾶς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5168",
    gloss: "hole",
  },
  τρυπήματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G5169",
    gloss: "hole",
  },
  Τρύφαιναν: {
    parse: "N-ASF-P｜Noun, accusative, singular, feminine, Person",
    GN: "G5170",
    gloss: "Tryphaena",
  },
  ἐτρυφήσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G5171",
    gloss: "to self-indulge",
  },
  τρυφῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5172",
    gloss: "self-indulgence",
  },
  τρυφήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5172",
    gloss: "self-indulgence",
  },
  Τρυφῶσαν: {
    parse: "N-ASF-P｜Noun, accusative, singular, feminine, Person",
    GN: "G5173",
    gloss: "Tryphosa",
  },
  Τρῳάδα: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G5174",
    gloss: "Troas",
  },
  Τρῳάδι: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G5174",
    gloss: "Troas",
  },
  Τρῳάδος: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G5174",
    gloss: "Troas",
  },
  τρώγοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G5176",
    gloss: "to eat",
  },
  τρώγων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G5176",
    gloss: "to eat",
  },
  τέτυχεν: {
    parse: "V-2RAI-3S｜Verb, 2nd perfect, active, indicative, third, singular",
    GN: "G5177",
    gloss: "to obtain/happen",
  },
  τυγχάνοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G5177",
    gloss: "to obtain/happen",
  },
  τυχεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G5177",
    gloss: "to obtain/happen",
  },
  τύχοι: {
    parse: "V-2AAO-3S｜Verb, 2nd aorist, active, Optative, third, singular",
    GN: "G5177",
    gloss: "to obtain/happen",
  },
  τυχὸν: {
    parse:
      "V-2AAP-ASN｜Verb, 2nd aorist, active, Participle, accusative, singular, neuter",
    GN: "G5177",
    gloss: "to obtain/happen",
  },
  τυχοῦσαν: {
    parse:
      "V-2AAP-ASF｜Verb, 2nd aorist, active, Participle, accusative, singular, feminine",
    GN: "G5177",
    gloss: "to obtain/happen",
  },
  τυχούσας: {
    parse:
      "V-2AAP-APF｜Verb, 2nd aorist, active, Participle, accusative, plural, feminine",
    GN: "G5177",
    gloss: "to obtain/happen",
  },
  τυχὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G5177",
    gloss: "to obtain/happen",
  },
  τύχωσιν: {
    parse: "V-2AAS-3P｜Verb, 2nd aorist, active, subjunctive, third, plural",
    GN: "G5177",
    gloss: "to obtain/happen",
  },
  ἐτυμπανίσθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G5178",
    gloss: "to torture",
  },
  τύποι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G5179",
    gloss: "mark/example",
  },
  τύπον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5179",
    gloss: "mark/example",
  },
  τύπος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5179",
    gloss: "mark/example",
  },
  τύπους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G5179",
    gloss: "mark/example",
  },
  ἔτυπτεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G5180",
    gloss: "to strike",
  },
  ἔτυπτον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G5180",
    gloss: "to strike",
  },
  τύπτειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G5180",
    gloss: "to strike",
  },
  Τύπτειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G5180",
    gloss: "to strike",
  },
  τύπτεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G5180",
    gloss: "to strike",
  },
  τύπτοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G5180",
    gloss: "to strike",
  },
  τύπτοντί: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G5180",
    gloss: "to strike",
  },
  Τυράννου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G5181",
    gloss: "Tyrannus",
  },
  Τυρίοις: {
    parse: "N-DPM-LG｜Noun, dative, plural, masculine, Location Gentilic",
    GN: "G5183",
    gloss: "Tyre",
  },
  Τύρον: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G5184",
    gloss: "Tyre",
  },
  Τύρου: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G5184",
    gloss: "Tyre",
  },
  Τύρῳ: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G5184",
    gloss: "Tyre",
  },
  τυφλέ: {
    // or nominative
    parse: "A-VSM｜Adjective, Vocative, singular, masculine",
    GN: "G5185",
    gloss: "blind",
  },
  τυφλοί: {
    parse: "A-VPM｜Adjective, Vocative or nominative, plural, masculine",
    GN: "G5185",
    gloss: "blind",
  },
  τυφλοὶ: {
    parse: "A-VPM｜Adjective, Vocative or nominative, plural, masculine",
    GN: "G5185",
    gloss: "blind",
  },
  τυφλοῖς: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G5185",
    gloss: "blind",
  },
  τυφλόν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G5185",
    gloss: "blind",
  },
  τυφλὸν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G5185",
    gloss: "blind",
  },
  τυφλός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5185",
    gloss: "blind",
  },
  τυφλὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5185",
    gloss: "blind",
  },
  τυφλοῦ: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G5185",
    gloss: "blind",
  },
  τυφλούς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G5185",
    gloss: "blind",
  },
  τυφλοὺς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G5185",
    gloss: "blind",
  },
  τυφλῷ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G5185",
    gloss: "blind",
  },
  τυφλῶν: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G5185",
    gloss: "blind",
  },
  ἐτύφλωσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5186",
    gloss: "to blind",
  },
  Τετύφλωκεν: {
    parse: "V-RAI-3S｜Verb, perfect, active, indicative, third, singular",
    GN: "G5186",
    gloss: "to blind",
  },
  τετυφωμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G5187",
    gloss: "be conceited",
  },
  τετύφωται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G5187",
    gloss: "be conceited",
  },
  τυφωθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G5187",
    gloss: "be conceited",
  },
  τυφόμενον: {
    parse:
      "V-PPP-ASN｜Verb, present, passive, Participle, accusative, singular, neuter",
    GN: "G5188",
    gloss: "to smoulder",
  },
  τυφωνικὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5189",
    gloss: "tempestuous",
  },
  Τυχικόν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G5190",
    gloss: "Tychicus",
  },
  Τυχικὸν: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G5190",
    gloss: "Tychicus",
  },
  Τυχικὸς: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G5190",
    gloss: "Tychicus",
  },
  ὑακινθίνους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G5191",
    gloss: "dark blue",
  },
  ὑάκινθος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5192",
    gloss: "hyacinth",
  },
  ὑαλίνη: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G5193",
    gloss: "glass",
  },
  ὑαλίνην: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G5193",
    gloss: "glass",
  },
  ὕαλος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5194",
    gloss: "glass",
  },
  ὑάλῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G5194",
    gloss: "glass",
  },
  ὑβρίζεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G5195",
    gloss: "to mistreat",
  },
  ὑβρίσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G5195",
    gloss: "to mistreat",
  },
  ὕβρισαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G5195",
    gloss: "to mistreat",
  },
  ὑβρισθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G5195",
    gloss: "to mistreat",
  },
  ὑβρισθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G5195",
    gloss: "to mistreat",
  },
  ὕβρεσιν: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G5196",
    gloss: "mistreatment",
  },
  ὕβρεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5196",
    gloss: "mistreatment",
  },
  ὕβριν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5196",
    gloss: "mistreatment",
  },
  ὑβριστάς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G5197",
    gloss: "insolent man",
  },
  ὑβριστήν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5197",
    gloss: "insolent man",
  },
  ὑγιαίνειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G5198",
    gloss: "be healthy",
  },
  ὑγιαίνοντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G5198",
    gloss: "be healthy",
  },
  ὑγιαίνοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G5198",
    gloss: "be healthy",
  },
  ὑγιαίνοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G5198",
    gloss: "be healthy",
  },
  ὑγιαινόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G5198",
    gloss: "be healthy",
  },
  ὑγιαινούσῃ: {
    parse:
      "V-PAP-DSF｜Verb, present, active, Participle, dative, singular, feminine",
    GN: "G5198",
    gloss: "be healthy",
  },
  ὑγιαινούσης: {
    parse:
      "V-PAP-GSF｜Verb, present, active, Participle, genitive, singular, feminine",
    GN: "G5198",
    gloss: "be healthy",
  },
  ὑγιαίνουσι: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G5198",
    gloss: "be healthy",
  },
  ὑγιαίνουσιν: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G5198",
    gloss: "be healthy",
  },
  ὑγιαίνωσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G5198",
    gloss: "be healthy",
  },
  ὑγιεῖς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G5199",
    gloss: "healthy",
  },
  ὑγιῆ: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G5199",
    gloss: "healthy",
  },
  ὑγιὴς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5199",
    gloss: "healthy",
  },
  ὑγιής: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5199",
    gloss: "healthy",
  },
  ὑγρῷ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G5200",
    gloss: "moist/green",
  },
  ὑδρίαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G5201",
    gloss: "jar",
  },
  ὑδρίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5201",
    gloss: "jar",
  },
  ὑδρίας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G5201",
    gloss: "jar",
  },
  ὑδροπότει: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G5202",
    gloss: "to drink water",
  },
  ὑδρωπικὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5203",
    gloss: "with edema",
  },
  ὕδασιν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G5204",
    gloss: "water",
  },
  ὕδατα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G5204",
    gloss: "water",
  },
  ὕδατι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G5204",
    gloss: "water",
  },
  ὕδατος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G5204",
    gloss: "water",
  },
  ὑδάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G5204",
    gloss: "water",
  },
  ὕδωρ: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G5204",
    gloss: "water",
  },
  ὑετόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5205",
    gloss: "rain",
  },
  ὑετὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5205",
    gloss: "rain",
  },
  ὑετὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5205",
    gloss: "rain",
  },
  ὑετοὺς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G5205",
    gloss: "rain",
  },
  υἱοθεσία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5206",
    gloss: "adoption (as son)",
  },
  υἱοθεσίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5206",
    gloss: "adoption (as son)",
  },
  υἱοθεσίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5206",
    gloss: "adoption (as son)",
  },
  Υἱέ: {
    // or nominative
    parse: "N-VSM｜Noun, Vocative, singular, masculine",
    GN: "G5207",
    gloss: "son",
  },
  υἱὲ: {
    // or nominative
    parse: "N-VSM｜Noun, Vocative, singular, masculine",
    GN: "G5207",
    gloss: "son",
  },
  Υἱὲ: {
    // or nominative
    parse: "N-VSM｜Noun, Vocative, singular, masculine",
    GN: "G5207",
    gloss: "son",
  },
  υἱοί: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G5207",
    gloss: "son",
  },
  υἱοὶ: {
    parse: "N-VPM｜Noun, Vocative or nominative, plural, masculine",
    GN: "G5207",
    gloss: "son",
  },
  Υἱοὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G5207",
    gloss: "son",
  },
  υἱοῖς: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G5207",
    gloss: "son",
  },
  υἱόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5207",
    gloss: "son",
  },
  Υἱόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5207",
    gloss: "son",
  },
  υἱὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5207",
    gloss: "son",
  },
  υἱός: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5207",
    gloss: "son",
  },
  Υἱός: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5207",
    gloss: "son",
  },
  υἱὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5207",
    gloss: "son",
  },
  Υἱὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5207",
    gloss: "son",
  },
  υἱοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G5207",
    gloss: "son",
  },
  υἱούς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G5207",
    gloss: "son",
  },
  υἱοὺς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G5207",
    gloss: "son",
  },
  υἱῷ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G5207",
    gloss: "son",
  },
  υἱῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G5207",
    gloss: "son",
  },
  ὕλην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5208",
    gloss: "forest",
  },
  Ὑμέναιος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G5211",
    gloss: "Hymenaeus",
  },
  ὑμετέρᾳ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G5212",
    gloss: "your",
  },
  ὑμετέρα: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G5212",
    gloss: "your",
  },
  ὑμετέραν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G5212",
    gloss: "your",
  },
  ὑμετέρας: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G5212",
    gloss: "your",
  },
  ὑμέτερον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G5212",
    gloss: "your",
  },
  ὑμέτερος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5212",
    gloss: "your",
  },
  ὑμετέρῳ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G5212",
    gloss: "your",
  },
  ὑμνήσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G5214",
    gloss: "to praise",
  },
  ὑμνήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G5214",
    gloss: "to praise",
  },
  ὕμνουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G5214",
    gloss: "to praise",
  },
  ὕμνοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G5215",
    gloss: "hymn",
  },
  ὕπαγε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G5217",
    gloss: "to go",
  },
  Ὕπαγε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G5217",
    gloss: "to go",
  },
  ὑπάγει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5217",
    gloss: "to go",
  },
  ὑπάγειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G5217",
    gloss: "to go",
  },
  ὑπάγεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G5217",
    gloss: "to go",
  },
  ὑπάγετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G5217",
    gloss: "to go",
  },
  Ὑπάγετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G5217",
    gloss: "to go",
  },
  ὑπάγῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G5217",
    gloss: "to go",
  },
  ὑπάγητε: {
    parse: "V-PAS-2P｜Verb, present, active, subjunctive, second, plural",
    GN: "G5217",
    gloss: "to go",
  },
  ὑπάγοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G5217",
    gloss: "to go",
  },
  ὑπάγοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G5217",
    gloss: "to go",
  },
  ὑπάγω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G5217",
    gloss: "to go",
  },
  Ὑπάγω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G5217",
    gloss: "to go",
  },
  ὑπῆγον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G5217",
    gloss: "to go",
  },
  ὑπακοῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5218",
    gloss: "obedience",
  },
  ὑπακοή: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5218",
    gloss: "obedience",
  },
  ὑπακοὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5218",
    gloss: "obedience",
  },
  ὑπακοήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5218",
    gloss: "obedience",
  },
  ὑπακοὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5218",
    gloss: "obedience",
  },
  ὑπακοῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5218",
    gloss: "obedience",
  },
  ὑπακούει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5219",
    gloss: "to obey",
  },
  ὑπακούειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G5219",
    gloss: "to obey",
  },
  ὑπακούετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G5219",
    gloss: "to obey",
  },
  ὑπακούουσιν: {
    parse:
      "V-PAP-DPM｜Verb, present, active, Participle, dative, plural, masculine",
    GN: "G5219",
    gloss: "to obey",
  },
  ὑπακοῦσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G5219",
    gloss: "to obey",
  },
  ὑπήκουον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G5219",
    gloss: "to obey",
  },
  ὑπήκουσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G5219",
    gloss: "to obey",
  },
  ὑπηκούσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G5219",
    gloss: "to obey",
  },
  ὑπήκουσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5219",
    gloss: "to obey",
  },
  ὕπανδρος: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G5220",
    gloss: "married",
  },
  ὑπαντῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G5221",
    gloss: "to go meet",
  },
  ὑπήντησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G5221",
    gloss: "to go meet",
  },
  ὑπήντησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5221",
    gloss: "to go meet",
  },
  ὑπάντησιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5222",
    gloss: "meeting",
  },
  ὑπάρξεις: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G5223",
    gloss: "property",
  },
  ὕπαρξιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5223",
    gloss: "property",
  },
  ὑπάρχει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5225",
    gloss: "to be",
  },
  ὑπάρχειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G5225",
    gloss: "to be",
  },
  ὑπάρχοντα: {
    parse:
      "V-PAP-NPN｜Verb, present, active, Participle, nominative, plural, neuter",
    GN: "G5225",
    gloss: "to be",
  },
  ὑπάρχοντά: {
    parse:
      "V-PAP-APN｜Verb, present, active, Participle, accusative, plural, neuter",
    GN: "G5225",
    gloss: "to be",
  },
  ὑπάρχοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G5225",
    gloss: "to be",
  },
  ὑπάρχοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G5225",
    gloss: "to be",
  },
  ὑπάρχοντος: {
    parse:
      "V-PAP-GSN｜Verb, present, active, Participle, genitive, singular, neuter",
    GN: "G5225",
    gloss: "to be",
  },
  ὑπαρχόντων: {
    parse:
      "V-PAP-GPN｜Verb, present, active, Participle, genitive, plural, neuter",
    GN: "G5225",
    gloss: "to be",
  },
  ὑπαρχούσης: {
    parse:
      "V-PAP-GSF｜Verb, present, active, Participle, genitive, singular, feminine",
    GN: "G5225",
    gloss: "to be",
  },
  ὑπάρχουσιν: {
    parse:
      "V-PAP-DPN｜Verb, present, active, Participle, dative, plural, neuter",
    GN: "G5225",
    gloss: "to be",
  },
  ὑπάρχων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G5225",
    gloss: "to be",
  },
  ὑπάρχωσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G5225",
    gloss: "to be",
  },
  ὑπῆρχεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G5225",
    gloss: "to be",
  },
  ὑπῆρχον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G5225",
    gloss: "to be",
  },
  ὑπείκετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G5226",
    gloss: "to submit",
  },
  ὑπεναντίον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G5227",
    gloss: "opposed",
  },
  ὑπεναντίους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G5227",
    gloss: "opposed",
  },
  ὑπὲρ: { parse: "PREP｜Preposition", GN: "G5228", gloss: "above/for" },
  ὑπεραιρόμενος: {
    parse:
      "V-PMP-NSM｜Verb, present, middle, Participle, nominative, singular, masculine",
    GN: "G5229",
    gloss: "be haughty",
  },
  ὑπεραίρωμαι: {
    parse: "V-PPS-1S｜Verb, present, passive, subjunctive, first, singular",
    GN: "G5229",
    gloss: "be haughty",
  },
  ὑπέρακμος: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G5230",
    gloss: "past one’s prime",
  },
  ὑπεράνω: { parse: "PREP｜Preposition", GN: "G5231", gloss: "above" },
  ὑπεραυξάνει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5232",
    gloss: "to increase",
  },
  ὑπερβαίνειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G5233",
    gloss: "to wrong",
  },
  ὑπερβαλλόντως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G5234",
    gloss: "severely",
  },
  ὑπερβάλλον: {
    parse:
      "V-PAP-NSN｜Verb, present, active, Participle, nominative, singular, neuter",
    GN: "G5235",
    gloss: "to surpass",
  },
  ὑπερβάλλουσαν: {
    parse:
      "V-PAP-ASF｜Verb, present, active, Participle, accusative, singular, feminine",
    GN: "G5235",
    gloss: "to surpass",
  },
  ὑπερβαλλούσης: {
    parse:
      "V-PAP-GSF｜Verb, present, active, Participle, genitive, singular, feminine",
    GN: "G5235",
    gloss: "to surpass",
  },
  ὑπερβολῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5236",
    gloss: "surpassing",
  },
  ὑπερβολὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5236",
    gloss: "surpassing",
  },
  ὑπερβολὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5236",
    gloss: "surpassing",
  },
  ὑπεριδὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G5237",
    gloss: "to overlook",
  },
  ὑπερέκεινα: { parse: "PREP｜Preposition", GN: "G5238", gloss: "beyond" },
  ὑπερεκτείνομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G5239",
    gloss: "to overextend",
  },
  ὑπερεκχυννόμενον: {
    parse:
      "V-PPP-ASN｜Verb, present, passive, Participle, accusative, singular, neuter",
    GN: "G5240",
    gloss: "to overflow",
  },
  ὑπερεντυγχάνει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5241",
    gloss: "to intercede",
  },
  ὑπερέχον: {
    parse:
      "V-PAP-ASN｜Verb, present, active, Participle, accusative, singular, neuter",
    GN: "G5242",
    gloss: "be higher",
  },
  ὑπερέχοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G5242",
    gloss: "be higher",
  },
  ὑπερέχοντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G5242",
    gloss: "be higher",
  },
  ὑπερέχουσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G5242",
    gloss: "be higher",
  },
  ὑπερεχούσαις: {
    parse:
      "V-PAP-DPF｜Verb, present, active, Participle, dative, plural, feminine",
    GN: "G5242",
    gloss: "be higher",
  },
  ὑπερηφανία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5243",
    gloss: "pride",
  },
  ὑπερήφανοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G5244",
    gloss: "arrogant",
  },
  ὑπερηφάνοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G5244",
    gloss: "arrogant",
  },
  ὑπερηφάνους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G5244",
    gloss: "arrogant",
  },
  ὑπερνικῶμεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G5245",
    gloss: "to conquer",
  },
  ὑπέρογκα: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G5246",
    gloss: "boastful",
  },
  ὑπεροχῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5247",
    gloss: "authority",
  },
  ὑπεροχὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5247",
    gloss: "authority",
  },
  ὑπερεπερίσσευσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5248",
    gloss: "to abound",
  },
  ὑπερπερισσεύομαι: {
    parse: "V-PPI-1S｜Verb, present, passive, indicative, first, singular",
    GN: "G5248",
    gloss: "to abound",
  },
  ὑπερπερισσῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G5249",
    gloss: "exceedingly",
  },
  ὑπερεπλεόνασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5250",
    gloss: "to abound",
  },
  ὑπερύψωσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5251",
    gloss: "to exalt",
  },
  ὑπερφρονεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G5252",
    gloss: "be haughty",
  },
  ὑπερῷον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G5253",
    gloss: "upper room",
  },
  ὑπερῴῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G5253",
    gloss: "upper room",
  },
  ὑπέχουσαι: {
    parse:
      "V-PAP-NPF｜Verb, present, active, Participle, nominative, plural, feminine",
    GN: "G5254",
    gloss: "to undergo",
  },
  ὑπήκοοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G5255",
    gloss: "obedient",
  },
  ὑπήκοοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G5255",
    gloss: "obedient",
  },
  ὑπήκοος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5255",
    gloss: "obedient",
  },
  ὑπηρετεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G5256",
    gloss: "to serve",
  },
  ὑπηρέτησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G5256",
    gloss: "to serve",
  },
  ὑπηρετήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G5256",
    gloss: "to serve",
  },
  ὑπηρέται: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G5257",
    gloss: "servant",
  },
  ὑπηρέταις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G5257",
    gloss: "servant",
  },
  ὑπηρέτας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G5257",
    gloss: "servant",
  },
  ὑπηρέτῃ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G5257",
    gloss: "servant",
  },
  ὑπηρέτην: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5257",
    gloss: "servant",
  },
  ὑπηρετῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G5257",
    gloss: "servant",
  },
  ὕπνου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G5258",
    gloss: "sleep",
  },
  ὕπνῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G5258",
    gloss: "sleep",
  },
  ὑπʼ: { parse: "PREP｜Preposition", GN: "G5259", gloss: "under" },
  ὑπό: { parse: "PREP｜Preposition", GN: "G5259", gloss: "under" },
  ὑπὸ: { parse: "PREP｜Preposition", GN: "G5259", gloss: "under" },
  ὑφʼ: { parse: "PREP｜Preposition", GN: "G5259", gloss: "under" },
  ὑπέβαλον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G5260",
    gloss: "to instigate",
  },
  ὑπογραμμὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5261",
    gloss: "example",
  },
  ὑπόδειγμα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G5262",
    gloss: "example",
  },
  ὑποδείγματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G5262",
    gloss: "example",
  },
  ὑποδείγματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G5262",
    gloss: "example",
  },
  ὑπέδειξα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G5263",
    gloss: "to show",
  },
  ὑπέδειξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5263",
    gloss: "to show",
  },
  ὑποδείξω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G5263",
    gloss: "to show",
  },
  ὑπεδέξατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G5264",
    gloss: "to receive",
  },
  ὑποδέδεκται: {
    parse:
      "V-RNI-3S｜Verb, perfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G5264",
    gloss: "to receive",
  },
  ὑποδεξαμένη: {
    parse:
      "V-ADP-NSF｜Verb, aorist, Middle deponent, Participle, nominative, singular, feminine",
    GN: "G5264",
    gloss: "to receive",
  },
  ὑποδεδεμένους: {
    parse:
      "V-RMP-APM｜Verb, perfect, middle, Participle, accusative, plural, masculine",
    GN: "G5265",
    gloss: "to put on",
  },
  ὑπόδησαι: {
    parse: "V-AMM-2S｜Verb, aorist, middle, imperative, second, singular",
    GN: "G5265",
    gloss: "to put on",
  },
  ὑποδησάμενοι: {
    parse:
      "V-AMP-NPM｜Verb, aorist, middle, Participle, nominative, plural, masculine",
    GN: "G5265",
    gloss: "to put on",
  },
  ὑπόδημα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G5266",
    gloss: "sandal",
  },
  ὑποδήματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G5266",
    gloss: "sandal",
  },
  ὑποδήματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G5266",
    gloss: "sandal",
  },
  ὑποδημάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G5266",
    gloss: "sandal",
  },
  ὑπόδικος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5267",
    gloss: "accountable",
  },
  ὑποζύγιον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G5268",
    gloss: "donkey",
  },
  ὑποζυγίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G5268",
    gloss: "donkey",
  },
  ὑποζωννύντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G5269",
    gloss: "to undergird",
  },
  ὑποκάτω: { parse: "PREP｜Preposition", GN: "G5270", gloss: "under" },
  ὑποκρινομένους: {
    parse:
      "V-PNP-APM｜Verb, present, Middle or Passive deponent, Participle, accusative, plural, masculine",
    GN: "G5271",
    gloss: "to pretend",
  },
  ὑποκρίσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5272",
    gloss: "hypocrisy",
  },
  ὑποκρίσεις: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G5272",
    gloss: "hypocrisy",
  },
  ὑποκρίσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5272",
    gloss: "hypocrisy",
  },
  ὑπόκρισιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5272",
    gloss: "hypocrisy",
  },
  ὑπόκρισις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5272",
    gloss: "hypocrisy",
  },
  ὑποκριτά: {
    // or nominative
    parse: "N-VSM｜Noun, Vocative, singular, masculine",
    GN: "G5273",
    gloss: "hypocrite",
  },
  ὑποκριταί: {
    // or nominative
    parse: "N-VPM｜Noun, Vocative, plural, masculine",
    GN: "G5273",
    gloss: "hypocrite",
  },
  ὑποκριταὶ: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G5273",
    gloss: "hypocrite",
  },
  Ὑποκριταί: {
    // or nominative
    parse: "N-VPM｜Noun, Vocative, plural, masculine",
    GN: "G5273",
    gloss: "hypocrite",
  },
  ὑποκριτῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G5273",
    gloss: "hypocrite",
  },
  ὑπέλαβεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G5274",
    gloss: "to take up/suppose",
  },
  ὑπολαβὼν: {
    parse:
      "V-2AAP-NSM｜Verb, 2nd aorist, active, Participle, nominative, singular, masculine",
    GN: "G5274",
    gloss: "to take up/suppose",
  },
  ὑπολαμβάνειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G5274",
    gloss: "to take up/suppose",
  },
  ὑπολαμβάνετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G5274",
    gloss: "to take up/suppose",
  },
  Ὑπολαμβάνω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G5274",
    gloss: "to take up/suppose",
  },
  ὑπελείφθην: {
    parse: "V-API-1S｜Verb, aorist, passive, indicative, first, singular",
    GN: "G5275",
    gloss: "to leave",
  },
  ὑπολήνιον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G5276",
    gloss: "trough",
  },
  ὑπολιμπάνων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G5277",
    gloss: "to leave behind",
  },
  ὑπέμεινάν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G5278",
    gloss: "to remain/endure",
  },
  ὑπεμείνατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G5278",
    gloss: "to remain/endure",
  },
  ὑπέμεινεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5278",
    gloss: "to remain/endure",
  },
  ὑπομείναντας: {
    parse:
      "V-AAP-APM｜Verb, aorist, active, Participle, accusative, plural, masculine",
    GN: "G5278",
    gloss: "to remain/endure",
  },
  ὑπομείνας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G5278",
    gloss: "to remain/endure",
  },
  ὑπομεμενηκότα: {
    parse:
      "V-RAP-ASM｜Verb, perfect, active, Participle, accusative, singular, masculine",
    GN: "G5278",
    gloss: "to remain/endure",
  },
  ὑπομένει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5278",
    gloss: "to remain/endure",
  },
  ὑπομενεῖτε: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G5278",
    gloss: "to remain/endure",
  },
  ὑπομένετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G5278",
    gloss: "to remain/endure",
  },
  ὑπομένομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G5278",
    gloss: "to remain/endure",
  },
  ὑπομένοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G5278",
    gloss: "to remain/endure",
  },
  ὑπομένω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G5278",
    gloss: "to remain/endure",
  },
  ὑπεμνήσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G5279",
    gloss: "to remind",
  },
  ὑπομίμνῃσκε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G5279",
    gloss: "to remind",
  },
  Ὑπομίμνῃσκε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G5279",
    gloss: "to remind",
  },
  ὑπομιμνῄσκειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G5279",
    gloss: "to remind",
  },
  Ὑπομνῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G5279",
    gloss: "to remind",
  },
  ὑπομνήσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5280",
    gloss: "remembrance",
  },
  ὑπομνήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G5279",
    gloss: "to remind",
  },
  ὑπόμνησιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5280",
    gloss: "remembrance",
  },
  ὑπομονῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5281",
    gloss: "perseverance",
  },
  ὑπομονὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5281",
    gloss: "perseverance",
  },
  ὑπομονήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5281",
    gloss: "perseverance",
  },
  ὑπομονὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5281",
    gloss: "perseverance",
  },
  ὑπομονῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5281",
    gloss: "perseverance",
  },
  ὑπενόουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G5282",
    gloss: "to suppose",
  },
  ὑπονοεῖτε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G5282",
    gloss: "to suppose",
  },
  ὑπόνοιαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G5283",
    gloss: "suspicion",
  },
  ὑπεπλεύσαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G5284",
    gloss: "to sail the lee",
  },
  Ὑποπνεύσαντος: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G5285",
    gloss: "to blow gently",
  },
  ὑποπόδιον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G5286",
    gloss: "footstool",
  },
  ὑποπόδιόν: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G5286",
    gloss: "footstool",
  },
  ὑποστάσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5287",
    gloss: "confidence/essence",
  },
  ὑποστάσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5287",
    gloss: "confidence/essence",
  },
  ὑπόστασις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5287",
    gloss: "confidence/essence",
  },
  ὑπεστειλάμην: {
    parse: "V-AMI-1S｜Verb, aorist, middle, indicative, first, singular",
    GN: "G5288",
    gloss: "to withdraw",
  },
  ὑπέστελλεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G5288",
    gloss: "to withdraw",
  },
  ὑποστείληται: {
    parse: "V-AMS-3S｜Verb, aorist, middle, subjunctive, third, singular",
    GN: "G5288",
    gloss: "to withdraw",
  },
  ὑποστολῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5289",
    gloss: "shrinking",
  },
  ὑπέστρεφον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G5290",
    gloss: "to return",
  },
  ὑπέστρεψα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G5290",
    gloss: "to return",
  },
  ὑπέστρεψαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G5290",
    gloss: "to return",
  },
  Ὑπέστρεψαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G5290",
    gloss: "to return",
  },
  ὑπέστρεψεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5290",
    gloss: "to return",
  },
  Ὑπόστρεφε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G5290",
    gloss: "to return",
  },
  ὑποστρέφειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G5290",
    gloss: "to return",
  },
  ὑποστρέφοντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G5290",
    gloss: "to return",
  },
  ὑποστρέφων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G5290",
    gloss: "to return",
  },
  ὑποστρέψαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G5290",
    gloss: "to return",
  },
  ὑποστρέψαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G5290",
    gloss: "to return",
  },
  ὑποστρέψαντι: {
    parse:
      "V-AAP-DSM｜Verb, aorist, active, Participle, dative, singular, masculine",
    GN: "G5290",
    gloss: "to return",
  },
  ὑποστρέψασαι: {
    parse:
      "V-AAP-NPF｜Verb, aorist, active, Participle, nominative, plural, feminine",
    GN: "G5290",
    gloss: "to return",
  },
  Ὑποστρέψω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G5290",
    gloss: "to return",
  },
  ὑπεστρώννυον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G5291",
    gloss: "to spread",
  },
  ὑποταγῇ: {
    parse: "V-2APS-3S｜Verb, 2nd aorist, passive, subjunctive, third, singular",
    GN: "G5293",
    gloss: "to subject",
  },
  ὑπετάγη: {
    parse: "V-2API-3S｜Verb, 2nd aorist, passive, indicative, third, singular",
    GN: "G5293",
    gloss: "to subject",
  },
  ὑπετάγησαν: {
    parse: "V-2API-3P｜Verb, 2nd aorist, passive, indicative, third, plural",
    GN: "G5293",
    gloss: "to subject",
  },
  ὑπέταξας: {
    parse: "V-2AAI-2S｜Verb, 2nd aorist, active, indicative, second, singular",
    GN: "G5293",
    gloss: "to subject",
  },
  ὑπέταξεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G5293",
    gloss: "to subject",
  },
  ὑποταγέντων: {
    parse:
      "V-2APP-GPM｜Verb, 2nd aorist, passive, Participle, genitive, plural, masculine",
    GN: "G5293",
    gloss: "to subject",
  },
  ὑποταγήσεται: {
    parse: "V-2FPI-3S｜Verb, 2nd future, passive, indicative, third, singular",
    GN: "G5293",
    gloss: "to subject",
  },
  ὑποταγησόμεθα: {
    parse: "V-2FPI-1P｜Verb, 2nd future, passive, indicative, first, plural",
    GN: "G5293",
    gloss: "to subject",
  },
  ὑποτάγητε: {
    parse: "V-2APM-2P｜Verb, 2nd aorist, passive, imperative, second, plural",
    GN: "G5293",
    gloss: "to subject",
  },
  Ὑποτάγητε: {
    parse: "V-2APM-2P｜Verb, 2nd aorist, passive, imperative, second, plural",
    GN: "G5293",
    gloss: "to subject",
  },
  ὑποτάξαι: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G5293",
    gloss: "to subject",
  },
  ὑποτάξαντα: {
    parse:
      "V-2AAP-ASM｜Verb, 2nd aorist, active, Participle, accusative, singular, masculine",
    GN: "G5293",
    gloss: "to subject",
  },
  ὑποτάξαντι: {
    parse:
      "V-2AAP-DSM｜Verb, 2nd aorist, active, Participle, dative, singular, masculine",
    GN: "G5293",
    gloss: "to subject",
  },
  ὑποτάξαντος: {
    parse:
      "V-2AAP-GSM｜Verb, 2nd aorist, active, Participle, genitive, singular, masculine",
    GN: "G5293",
    gloss: "to subject",
  },
  ὑποτάσσεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G5293",
    gloss: "to subject",
  },
  ὑποτάσσεσθε: {
    parse: "V-PPM-2P｜Verb, present, passive, imperative, second, plural",
    GN: "G5293",
    gloss: "to subject",
  },
  ὑποτασσέσθω: {
    parse: "V-PPM-3S｜Verb, present, passive, imperative, third, singular",
    GN: "G5293",
    gloss: "to subject",
  },
  ὑποτασσέσθωσαν: {
    parse: "V-PPM-3P｜Verb, present, passive, imperative, third, plural",
    GN: "G5293",
    gloss: "to subject",
  },
  ὑποτάσσεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G5293",
    gloss: "to subject",
  },
  ὑποτάσσησθε: {
    parse: "V-PPS-2P｜Verb, present, passive, subjunctive, second, plural",
    GN: "G5293",
    gloss: "to subject",
  },
  ὑποτασσόμεναι: {
    parse:
      "V-PPP-NPF｜Verb, present, passive, Participle, nominative, plural, feminine",
    GN: "G5293",
    gloss: "to subject",
  },
  ὑποτασσομένας: {
    parse:
      "V-PPP-APF｜Verb, present, passive, Participle, accusative, plural, feminine",
    GN: "G5293",
    gloss: "to subject",
  },
  ὑποτασσόμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G5293",
    gloss: "to subject",
  },
  ὑποτασσόμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G5293",
    gloss: "to subject",
  },
  ὑποτεταγμένα: {
    parse:
      "V-RPP-APN｜Verb, perfect, passive, Participle, accusative, plural, neuter",
    GN: "G5293",
    gloss: "to subject",
  },
  ὑποτέτακται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G5293",
    gloss: "to subject",
  },
  ὑπέθηκαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G5294",
    gloss: "to lay",
  },
  ὑποτιθέμενος: {
    parse:
      "V-PMP-NSM｜Verb, present, middle, Participle, nominative, singular, masculine",
    GN: "G5294",
    gloss: "to lay",
  },
  ὑποδραμόντες: {
    parse:
      "V-2AAP-NPM｜Verb, 2nd aorist, active, Participle, nominative, plural, masculine",
    GN: "G5295",
    gloss: "to sail under",
  },
  ὑποτύπωσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5296",
    gloss: "example",
  },
  ὑπενεγκεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G5297",
    gloss: "to endure",
  },
  ὑπήνεγκα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G5297",
    gloss: "to endure",
  },
  ὑποφέρει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5297",
    gloss: "to endure",
  },
  ὑπεχώρησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5298",
    gloss: "to withdraw",
  },
  ὑποχωρῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G5298",
    gloss: "to withdraw",
  },
  ὑπωπιάζῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G5299",
    gloss: "to wear out",
  },
  ὑπωπιάζω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G5299",
    gloss: "to wear out",
  },
  Ὗς: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5300",
    gloss: "sow",
  },
  ὑσσώπου: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5301",
    gloss: "hyssop",
  },
  ὑσσώπῳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5301",
    gloss: "hyssop",
  },
  ὑστερεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5302",
    gloss: "to lack",
  },
  ὑστερεῖσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G5302",
    gloss: "to lack",
  },
  ὑστερηθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G5302",
    gloss: "to lack",
  },
  ὑστερηκέναι: {
    parse: "V-RAN｜Verb, perfect, active, Infinitive",
    GN: "G5302",
    gloss: "to lack",
  },
  ὑστέρησα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G5302",
    gloss: "to lack",
  },
  ὑστερήσαντος: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G5302",
    gloss: "to lack",
  },
  ὑστερήσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G5302",
    gloss: "to lack",
  },
  ὑστερούμεθα: {
    parse: "V-PPI-1P｜Verb, present, passive, indicative, first, plural",
    GN: "G5302",
    gloss: "to lack",
  },
  ὑστερούμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G5302",
    gloss: "to lack",
  },
  ὑστερουμένῳ: {
    parse:
      "V-PPP-DSN｜Verb, present, passive, Participle, dative, singular, neuter",
    GN: "G5302",
    gloss: "to lack",
  },
  ὑστεροῦνται: {
    parse: "V-PPI-3P｜Verb, present, passive, indicative, third, plural",
    GN: "G5302",
    gloss: "to lack",
  },
  ὑστερῶ: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G5302",
    gloss: "to lack",
  },
  ὑστερῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G5302",
    gloss: "to lack",
  },
  ὑστέρημα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G5303",
    gloss: "deficiency",
  },
  ὑστέρημά: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G5303",
    gloss: "deficiency",
  },
  ὑστερήματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G5303",
    gloss: "deficiency",
  },
  ὑστερήματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G5303",
    gloss: "deficiency",
  },
  ὑστερήσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5304",
    gloss: "poverty",
  },
  ὑστέρησιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5304",
    gloss: "poverty",
  },
  ὑστέροις: {
    parse: "A-DPM-C｜Adjective, dative, plural, masculine, Comparative",
    GN: "G5306",
  },
  ὕστερον: {
    parse: "A-ASN-C｜Adjective, accusative, singular, neuter, Comparative",
    GN: "G5306",
  },
  Ὕστερον: {
    parse: "A-ASN-C｜Adjective, accusative, singular, neuter, Comparative",
    GN: "G5306",
  },
  ὑφαντὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5307",
    gloss: "woven",
  },
  ὑψηλὰ: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G5308",
    gloss: "high",
  },
  ὑψηλοῖς: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G5308",
    gloss: "high",
  },
  ὑψηλόν: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G5308",
    gloss: "high",
  },
  ὑψηλὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G5308",
    gloss: "high",
  },
  ὑψηλότερος: {
    parse: "A-NSM-C｜Adjective, nominative, singular, masculine, Comparative",
    GN: "G5308",
    gloss: "high",
  },
  ὑψηλοῦ: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G5308",
    gloss: "high",
  },
  ὑψηλοφρονεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G5309",
    gloss: "be arrogant",
  },
  ὑψίστοις: {
    parse: "A-DPN｜Adjective, dative, plural, neuter",
    GN: "G5310",
    gloss: "Highest",
  },
  ὕψιστος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5310",
    gloss: "Highest",
  },
  ὑψίστου: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G5310",
    gloss: "Highest",
  },
  Ὑψίστου: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G5310",
    gloss: "Highest",
  },
  ὕψει: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G5311",
    gloss: "height",
  },
  ὕψος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G5311",
    gloss: "height",
  },
  ὕψους: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G5311",
    gloss: "height",
  },
  ὑψωθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G5312",
    gloss: "to lift up",
  },
  ὑψωθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G5312",
    gloss: "to lift up",
  },
  ὑψωθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G5312",
    gloss: "to lift up",
  },
  ὑψωθήσῃ: {
    parse: "V-FPI-2S｜Verb, future, passive, indicative, second, singular",
    GN: "G5312",
    gloss: "to lift up",
  },
  ὑψωθῆτε: {
    parse: "V-APS-2P｜Verb, aorist, passive, subjunctive, second, plural",
    GN: "G5312",
    gloss: "to lift up",
  },
  ὑψωθῶ: {
    parse: "V-APS-1S｜Verb, aorist, passive, subjunctive, first, singular",
    GN: "G5312",
    gloss: "to lift up",
  },
  ὑψῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G5312",
    gloss: "to lift up",
  },
  ὑψώσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G5312",
    gloss: "to lift up",
  },
  ὕψωσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5312",
    gloss: "to lift up",
  },
  ὑψώσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G5312",
    gloss: "to lift up",
  },
  ὑψώσητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G5312",
    gloss: "to lift up",
  },
  ὕψωμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G5313",
    gloss: "height",
  },
  φάγος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5314",
    gloss: "glutton",
  },
  ἔφαγεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5315",
    gloss: "to eat",
  },
  ἐφάγετε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G5315",
    gloss: "to eat",
  },
  ἐφάγομεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G5315",
    gloss: "to eat",
  },
  Ἐφάγομεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G5315",
    gloss: "to eat",
  },
  ἔφαγον: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G5315",
    gloss: "to eat",
  },
  φάγε: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G5315",
    gloss: "to eat",
  },
  φαγεῖν: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G5315",
    gloss: "to eat",
  },
  φάγεσαι: {
    parse: "V-FMI-2S｜Verb, future, middle, indicative, second, singular",
    GN: "G5315",
    gloss: "to eat",
  },
  φάγεται: {
    parse: "V-FMI-3S｜Verb, future, middle, indicative, third, singular",
    GN: "G5315",
    gloss: "to eat",
  },
  φάγετε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G5315",
    gloss: "to eat",
  },
  φάγῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G5315",
    gloss: "to eat",
  },
  φάγῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G5315",
    gloss: "to eat",
  },
  φάγητε: {
    parse: "V-AAS-2P｜Verb, aorist, active, subjunctive, second, plural",
    GN: "G5315",
    gloss: "to eat",
  },
  φάγοι: {
    parse: "V-AAO-3S｜Verb, aorist, active, Optative, third, singular",
    GN: "G5315",
    gloss: "to eat",
  },
  φάγονται: {
    parse: "V-FMI-3P｜Verb, future, middle, indicative, third, plural",
    GN: "G5315",
    gloss: "to eat",
  },
  φαγόντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G5315",
    gloss: "to eat",
  },
  φάγω: {
    parse: "V-AAS-1S｜Verb, aorist, active, subjunctive, first, singular",
    GN: "G5315",
    gloss: "to eat",
  },
  φάγωμεν: {
    parse: "V-AAS-1P｜Verb, aorist, active, subjunctive, first, plural",
    GN: "G5315",
    gloss: "to eat",
  },
  Φάγωμεν: {
    parse: "V-AAS-1P｜Verb, aorist, active, subjunctive, first, plural",
    GN: "G5315",
    gloss: "to eat",
  },
  φάγωσιν: {
    parse: "V-AAS-3P｜Verb, aorist, active, subjunctive, third, plural",
    GN: "G5315",
    gloss: "to eat",
  },
  ἐφάνη: {
    parse: "V-2API-3S｜Verb, 2nd aorist, passive, indicative, third, singular",
    GN: "G5316",
    gloss: "to shine/appear",
  },
  ἐφάνησαν: {
    parse: "V-2API-3P｜Verb, 2nd aorist, passive, indicative, third, plural",
    GN: "G5316",
    gloss: "to shine/appear",
  },
  φαίνει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5316",
    gloss: "to shine/appear",
  },
  φαίνεσθε: {
    parse:
      "V-PEI-2P｜Verb, present, Either middle or passive, indicative, second, plural",
    GN: "G5316",
    gloss: "to shine/appear",
  },
  φαίνεται: {
    parse:
      "V-PEI-3S｜Verb, present, Either middle or passive, indicative, third, singular",
    GN: "G5316",
    gloss: "to shine/appear",
  },
  φαινομένη: {
    parse:
      "V-PEP-NSF｜Verb, present, Either middle or passive, Participle, nominative, singular, feminine",
    GN: "G5316",
    gloss: "to shine/appear",
  },
  φαινομένου: {
    parse:
      "V-PEP-GSM｜Verb, present, Either middle or passive, Participle, genitive, singular, masculine",
    GN: "G5316",
    gloss: "to shine/appear",
  },
  φαινομένων: {
    parse:
      "V-PEP-GPN｜Verb, present, Either middle or passive, Participle, genitive, plural, neuter",
    GN: "G5316",
    gloss: "to shine/appear",
  },
  φαίνονται: {
    parse:
      "V-PEI-3P｜Verb, present, Either middle or passive, indicative, third, plural",
    GN: "G5316",
    gloss: "to shine/appear",
  },
  φαίνοντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G5316",
    gloss: "to shine/appear",
  },
  φαίνων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G5316",
    gloss: "to shine/appear",
  },
  φαίνωσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G5316",
    gloss: "to shine/appear",
  },
  φανεῖται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G5316",
    gloss: "to shine/appear",
  },
  φάνῃ: {
    parse: "V-2AAS-3S｜Verb, 2nd aorist, active, subjunctive, third, singular",
    GN: "G5316",
    gloss: "to shine/appear",
  },
  φανῇ: {
    parse: "V-2APS-3S｜Verb, 2nd aorist, passive, subjunctive, third, singular",
    GN: "G5316",
    gloss: "to shine/appear",
  },
  φανῇς: {
    parse:
      "V-2APS-2S｜Verb, 2nd aorist, passive, subjunctive, second, singular",
    GN: "G5316",
    gloss: "to shine/appear",
  },
  φανήσεται: {
    parse: "V-2FPI-3S｜Verb, 2nd future, passive, indicative, third, singular",
    GN: "G5316",
    gloss: "to shine/appear",
  },
  φανῶμεν: {
    parse: "V-2APS-1P｜Verb, 2nd aorist, passive, subjunctive, first, plural",
    GN: "G5316",
    gloss: "to shine/appear",
  },
  φανῶσιν: {
    parse: "V-2APS-3P｜Verb, 2nd aorist, passive, subjunctive, third, plural",
    GN: "G5316",
    gloss: "to shine/appear",
  },
  Φάλεκ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G5317",
    gloss: "Peleg",
  },
  φανερά: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G5318",
    gloss: "clear",
  },
  φανερὰ: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G5318",
    gloss: "clear",
  },
  φανεροὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G5318",
    gloss: "clear",
  },
  φανερόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G5318",
    gloss: "clear",
  },
  φανερὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G5318",
    gloss: "clear",
  },
  φανεροὺς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G5318",
    gloss: "clear",
  },
  φανερῷ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G5318",
    gloss: "clear",
  },
  ἐφανερώθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G5319",
    gloss: "to reveal",
  },
  ἐφανερώθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G5319",
    gloss: "to reveal",
  },
  Ἐφανέρωσά: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G5319",
    gloss: "to reveal",
  },
  ἐφανέρωσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5319",
    gloss: "to reveal",
  },
  πεφανερώμεθα: {
    parse: "V-RPI-1P｜Verb, perfect, passive, indicative, first, plural",
    GN: "G5319",
    gloss: "to reveal",
  },
  πεφανερῶσθαι: {
    parse: "V-RPN｜Verb, perfect, passive, Infinitive",
    GN: "G5319",
    gloss: "to reveal",
  },
  πεφανέρωται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G5319",
    gloss: "to reveal",
  },
  φανερούμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G5319",
    gloss: "to reveal",
  },
  φανερούμενον: {
    parse:
      "V-PPP-NSN｜Verb, present, passive, Participle, nominative, singular, neuter",
    GN: "G5319",
    gloss: "to reveal",
  },
  φανεροῦντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G5319",
    gloss: "to reveal",
  },
  φανεροῦται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G5319",
    gloss: "to reveal",
  },
  φανερωθεῖσαν: {
    parse:
      "V-APP-ASF｜Verb, aorist, passive, Participle, accusative, singular, feminine",
    GN: "G5319",
    gloss: "to reveal",
  },
  φανερωθέντος: {
    parse:
      "V-APP-GSM｜Verb, aorist, passive, Participle, genitive, singular, masculine",
    GN: "G5319",
    gloss: "to reveal",
  },
  φανερωθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G5319",
    gloss: "to reveal",
  },
  φανερωθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G5319",
    gloss: "to reveal",
  },
  φανερωθήσεσθε: {
    parse: "V-FPI-2P｜Verb, future, passive, indicative, second, plural",
    GN: "G5319",
    gloss: "to reveal",
  },
  φανερωθῶσιν: {
    parse: "V-APS-3P｜Verb, aorist, passive, subjunctive, third, plural",
    GN: "G5319",
    gloss: "to reveal",
  },
  φανερώσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G5319",
    gloss: "to reveal",
  },
  φανερώσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5321",
    gloss: "revelation",
  },
  φανέρωσον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G5319",
    gloss: "to reveal",
  },
  φανερώσω: {
    parse: "V-AAS-1S｜Verb, aorist, active, subjunctive, first, singular",
    GN: "G5319",
    gloss: "to reveal",
  },
  φανερῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G5320",
    gloss: "plainly",
  },
  φανέρωσις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5321",
    gloss: "revelation",
  },
  φανῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G5322",
    gloss: "torch",
  },
  Φανουήλ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G5323",
    gloss: "Phanuel",
  },
  φανταζόμενον: {
    parse:
      "V-PPP-NSN｜Verb, present, passive, Participle, nominative, singular, neuter",
    GN: "G5324",
    gloss: "to make visible",
  },
  φαντασίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5325",
    gloss: "pageantry",
  },
  φάντασμά: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G5326",
    gloss: "ghost",
  },
  Φάντασμά: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G5326",
    gloss: "ghost",
  },
  φάραγξ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5327",
    gloss: "valley",
  },
  Φαραὼ: {
    parse: "N-GSM-T｜Noun, genitive, singular, masculine, Title",
    GN: "G5328",
    gloss: "Pharaoh",
  },
  Φαραώ: {
    parse: "N-GSM-T｜Noun, genitive, singular, masculine, Title",
    GN: "G5328",
    gloss: "Pharaoh",
  },
  Φαρὲς: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G5329",
    gloss: "Perez",
  },
  Φαρισαῖε: {
    parse: "N-VSM-T｜Noun, Vocative or nominative, singular, masculine, Title",
    GN: "G5330",
    gloss: "Pharisee",
  },
  Φαρισαῖοι: {
    parse: "N-VPM-T｜Noun, Vocative or nominative, plural, masculine, Title",
    GN: "G5330",
    gloss: "Pharisee",
  },
  Φαρισαίοις: {
    parse: "N-DPM-T｜Noun, dative, plural, masculine, Title",
    GN: "G5330",
    gloss: "Pharisee",
  },
  Φαρισαῖος: {
    parse: "N-NSM-T｜Noun, nominative, singular, masculine, Title",
    GN: "G5330",
    gloss: "Pharisee",
  },
  Φαρισαίου: {
    parse: "N-GSM-T｜Noun, genitive, singular, masculine, Title",
    GN: "G5330",
    gloss: "Pharisee",
  },
  Φαρισαίους: {
    parse: "N-APM-T｜Noun, accusative, plural, masculine, Title",
    GN: "G5330",
    gloss: "Pharisee",
  },
  Φαρισαίων: {
    parse: "N-GPM-T｜Noun, genitive, plural, masculine, Title",
    GN: "G5330",
    gloss: "Pharisee",
  },
  Φαρισαῖός: {
    parse: "N-GPM-T｜Noun, genitive, plural, masculine, Title",
    GN: "G5330",
    gloss: "Pharisee",
  },
  φαρμακείᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5331",
    gloss: "sorcery",
  },
  φαρμακεία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5331",
    gloss: "sorcery",
  },
  φάρμακοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G5333",
    gloss: "sorcerer",
  },
  φαρμάκοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G5333",
    gloss: "sorcerer",
  },
  φαρμάκων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G5333",
    gloss: "sorcerer",
  },
  φάσις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5334",
    gloss: "news",
  },
  ἔφασκεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G5335",
    gloss: "to claim",
  },
  φάσκοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G5335",
    gloss: "to claim",
  },
  φάτνῃ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5336",
    gloss: "manger",
  },
  φάτνης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5336",
    gloss: "manger",
  },
  φαῦλα: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G5337",
    gloss: "evil",
  },
  φαῦλον: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G5337",
    gloss: "evil",
  },
  φέγγος: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G5338",
    gloss: "brightness",
  },
  ἐφείσατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G5339",
    gloss: "to spare",
  },
  φείδομαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G5339",
    gloss: "to spare",
  },
  φειδόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G5339",
    gloss: "to spare",
  },
  φειδόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G5339",
    gloss: "to spare",
  },
  φείσεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G5339",
    gloss: "to spare",
  },
  φείσομαι: {
    parse:
      "V-FDI-1S｜Verb, future, Middle deponent, indicative, first, singular",
    GN: "G5339",
    gloss: "to spare",
  },
  φειδομένως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G5340",
    gloss: "sparingly",
  },
  φαιλόνην: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5341",
    gloss: "cloak",
  },
  ἐνέγκαι: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  ἐνέγκας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  Ἐνέγκατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  ἐνεχθεῖσαν: {
    parse:
      "V-APP-ASF｜Verb, aorist, passive, Participle, accusative, singular, feminine",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  ἐνεχθείσης: {
    parse:
      "V-APP-GSF｜Verb, aorist, passive, Participle, genitive, singular, feminine",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  ἔφερεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  ἐφερόμεθα: {
    parse: "V-IPI-1P｜Verb, imperfect, passive, indicative, first, plural",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  ἔφερον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  ἐφέροντο: {
    parse: "V-IPI-3P｜Verb, imperfect, passive, indicative, third, plural",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  ἤνεγκα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  ἤνεγκαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  ἤνεγκεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  ἠνέχθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  οἴσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  οἴσουσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  φέρε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  Φέρε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  φέρει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  φέρειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  φέρεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  φέρετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  φέρετέ: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  Φέρετέ: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  φέρῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  φέρητε: {
    parse: "V-PAS-2P｜Verb, present, active, subjunctive, second, plural",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  φερομένην: {
    parse:
      "V-PPP-ASF｜Verb, present, passive, Participle, accusative, singular, feminine",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  φερομένης: {
    parse:
      "V-PPP-GSF｜Verb, present, passive, Participle, genitive, singular, feminine",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  φερόμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  φέρον: {
    parse:
      "V-PAP-ASN｜Verb, present, active, Participle, accusative, singular, neuter",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  φέροντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  φέρουσαι: {
    parse:
      "V-PAP-NPF｜Verb, present, active, Participle, nominative, plural, feminine",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  φέρουσαν: {
    parse:
      "V-PAP-ASF｜Verb, present, active, Participle, accusative, singular, feminine",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  φέρουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  φερώμεθα: {
    parse: "V-PPS-1P｜Verb, present, passive, subjunctive, first, plural",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  φέρων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G5342",
    gloss: "to bear/lead",
  },
  ἔφυγεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G5343",
    gloss: "to flee",
  },
  ἔφυγον: {
    parse:
      "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural OR first, singular",
    GN: "G5343",
    gloss: "to flee",
  },
  φεῦγε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G5343",
    gloss: "to flee",
  },
  φεύγει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5343",
    gloss: "to flee",
  },
  φεύγετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G5343",
    gloss: "to flee",
  },
  φευγέτωσαν: {
    parse: "V-PAM-3P｜Verb, present, active, imperative, third, plural",
    GN: "G5343",
    gloss: "to flee",
  },
  φεύξεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G5343",
    gloss: "to flee",
  },
  φεύξονται: {
    parse: "V-FDI-3P｜Verb, future, Middle deponent, indicative, third, plural",
    GN: "G5343",
    gloss: "to flee",
  },
  φυγεῖν: {
    parse: "V-2AAN｜Verb, 2nd aorist, active, Infinitive",
    GN: "G5343",
    gloss: "to flee",
  },
  φύγητε: {
    parse: "V-2AAS-2P｜Verb, 2nd aorist, active, subjunctive, second, plural",
    GN: "G5343",
    gloss: "to flee",
  },
  Φήλικα: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G5344",
    gloss: "Felix",
  },
  Φήλικι: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G5344",
    gloss: "Felix",
  },
  Φήλικος: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G5344",
    gloss: "Felix",
  },
  Φῆλιξ: {
    parse: "N-VSM-P｜Noun, Vocative or nominative, singular, masculine, Person",
    GN: "G5344",
    gloss: "Felix",
  },
  φήμη: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5345",
    gloss: "news",
  },
  ἔφη: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G5346",
    gloss: "to say",
  },
  Ἔφη: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G5346",
    gloss: "to say",
  },
  φασίν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G5346",
    gloss: "to say",
  },
  φημι: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G5346",
    gloss: "to say",
  },
  φησιν: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5346",
    gloss: "to say",
  },
  φησίν: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5346",
    gloss: "to say",
  },
  Φῆστε: {
    parse: "N-VSM-P｜Noun, Vocative or nominative, singular, masculine, Person",
    GN: "G5347",
    gloss: "Festus",
  },
  Φῆστον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G5347",
    gloss: "Festus",
  },
  Φῆστος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G5347",
    gloss: "Festus",
  },
  Φήστου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G5347",
    gloss: "Festus",
  },
  Φήστῳ: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G5347",
    gloss: "Festus",
  },
  ἐφθάσαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G5348",
    gloss: "to precede/arrive",
  },
  ἔφθασεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5348",
    gloss: "to precede/arrive",
  },
  φθάσωμεν: {
    parse: "V-AAS-1P｜Verb, aorist, active, subjunctive, first, plural",
    GN: "G5348",
    gloss: "to precede/arrive",
  },
  φθαρτῆς: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G5349",
    gloss: "perishable",
  },
  φθαρτοῖς: {
    parse: "A-DPN｜Adjective, dative, plural, neuter",
    GN: "G5349",
    gloss: "perishable",
  },
  φθαρτὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G5349",
    gloss: "perishable",
  },
  φθαρτοῦ: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G5349",
    gloss: "perishable",
  },
  φθέγγεσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G5350",
    gloss: "to speak",
  },
  φθεγγόμενοι: {
    parse:
      "V-PDP-NPM｜Verb, present, Middle deponent, Participle, nominative, plural, masculine",
    GN: "G5350",
    gloss: "to speak",
  },
  φθεγξάμενον: {
    parse:
      "V-ANP-NSN｜Verb, aorist, Middle or Passive deponent, Participle, nominative, singular, neuter",
    GN: "G5350",
    gloss: "to speak",
  },
  ἐφθείραμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G5351",
    gloss: "to destroy",
  },
  ἔφθειρεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G5351",
    gloss: "to destroy",
  },
  φθαρῇ: {
    parse: "V-2APS-3S｜Verb, 2nd aorist, passive, subjunctive, third, singular",
    GN: "G5351",
    gloss: "to destroy",
  },
  φθαρήσονται: {
    parse: "V-2FPI-3P｜Verb, 2nd future, passive, indicative, third, plural",
    GN: "G5351",
    gloss: "to destroy",
  },
  φθείρει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5351",
    gloss: "to destroy",
  },
  φθειρόμενον: {
    parse:
      "V-PPP-ASM｜Verb, present, passive, Participle, accusative, singular, masculine",
    GN: "G5351",
    gloss: "to destroy",
  },
  φθείρονται: {
    parse: "V-PPI-3P｜Verb, present, passive, indicative, third, plural",
    GN: "G5351",
    gloss: "to destroy",
  },
  φθείρουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G5351",
    gloss: "to destroy",
  },
  φθερεῖ: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G5351",
    gloss: "to destroy",
  },
  φθινοπωρινὰ: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G5352",
    gloss: "autumnal",
  },
  φθόγγοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G5353",
    gloss: "sound",
  },
  φθόγγος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5353",
    gloss: "sound",
  },
  φθονοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G5354",
    gloss: "to envy",
  },
  φθόνοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G5355",
    gloss: "envy",
  },
  φθόνον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5355",
    gloss: "envy",
  },
  φθόνος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5355",
    gloss: "envy",
  },
  φθόνου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G5355",
    gloss: "envy",
  },
  φθόνους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G5355",
    gloss: "envy",
  },
  φθόνῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G5355",
    gloss: "envy",
  },
  φθορᾷ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5356",
    gloss: "corruption",
  },
  φθορὰ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5356",
    gloss: "corruption",
  },
  φθοράν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5356",
    gloss: "corruption",
  },
  φθορὰν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5356",
    gloss: "corruption",
  },
  φθορᾶς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5356",
    gloss: "corruption",
  },
  φιάλας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G5357",
    gloss: "bowl",
  },
  φιάλην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5357",
    gloss: "bowl",
  },
  φιλάγαθον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G5358",
    gloss: "lover of good",
  },
  Φιλαδελφείᾳ: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G5359",
    gloss: "Philadelphia",
  },
  Φιλαδέλφειαν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G5359",
    gloss: "Philadelphia",
  },
  φιλαδελφίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5360",
    gloss: "brotherly love",
  },
  φιλαδελφία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5360",
    gloss: "brotherly love",
  },
  φιλαδελφίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5360",
    gloss: "brotherly love",
  },
  φιλαδελφίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5360",
    gloss: "brotherly love",
  },
  φιλάδελφοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G5361",
    gloss: "loving the brothers",
  },
  φιλάνδρους: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G5362",
    gloss: "husband-loving",
  },
  φιλανθρωπία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5363",
    gloss: "benevolence",
  },
  φιλανθρωπίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5363",
    gloss: "benevolence",
  },
  φιλανθρώπως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G5364",
    gloss: "benevolently",
  },
  φιλαργυρία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5365",
    gloss: "love of money",
  },
  φιλάργυροι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G5366",
    gloss: "money-loving",
  },
  φίλαυτοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G5367",
    gloss: "selfish",
  },
  ἐφίλει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G5368",
    gloss: "to love",
  },
  πεφιλήκατε: {
    parse: "V-RAI-2P｜Verb, perfect, active, indicative, second, plural",
    GN: "G5368",
    gloss: "to love",
  },
  φιλεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5368",
    gloss: "to love",
  },
  φιλεῖς: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G5368",
    gloss: "to love",
  },
  Φιλεῖς: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G5368",
    gloss: "to love",
  },
  φιλῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G5368",
    gloss: "to love",
  },
  φιλήσω: {
    parse: "V-AAS-1S｜Verb, aorist, active, subjunctive, first, singular",
    GN: "G5368",
    gloss: "to love",
  },
  φιλοῦντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G5368",
    gloss: "to love",
  },
  φιλούντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G5368",
    gloss: "to love",
  },
  φιλοῦσι: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G5368",
    gloss: "to love",
  },
  φιλοῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G5368",
    gloss: "to love",
  },
  φιλῶ: {
    parse: "V-PAS-1S｜Verb, present, active, subjunctive, first, singular",
    GN: "G5368",
    gloss: "to love",
  },
  φιλῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G5368",
    gloss: "to love",
  },
  φιλήδονοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G5369",
    gloss: "pleasure-loving",
  },
  φίλημά: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G5370",
    gloss: "kiss",
  },
  φιλήματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G5370",
    gloss: "kiss",
  },
  Φιλήμονι: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G5371",
    gloss: "Philemon",
  },
  Φίλητος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G5372",
    gloss: "Philetus",
  },
  φιλία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5373",
    gloss: "friendship",
  },
  Φιλιππήσιοι: {
    parse:
      "N-VPM-LG｜Noun, Vocative or nominative, plural, masculine, Location Gentilic",
    GN: "G5374",
    gloss: "Philippian",
  },
  Φιλίπποις: {
    parse: "N-DPM-L｜Noun, dative, plural, masculine, Location",
    GN: "G5375",
    gloss: "Philippi",
  },
  Φιλίππους: {
    parse: "N-APM-L｜Noun, accusative, plural, masculine, Location",
    GN: "G5375",
    gloss: "Philippi",
  },
  Φιλίππων: {
    parse: "N-GPM-L｜Noun, genitive, plural, masculine, Location",
    GN: "G5375",
    gloss: "Philippi",
  },
  Φίλιππε: {
    parse: "N-VSM-P｜Noun, Vocative or nominative, singular, masculine, Person",
    GN: "G5376",
    gloss: "Philippi",
  },
  Φίλιππον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G5376",
    gloss: "Philippi",
  },
  Φίλιππος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G5376",
    gloss: "Philippi",
  },
  Φιλίππου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G5376",
    gloss: "Philippi",
  },
  Φιλίππῳ: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G5376",
    gloss: "Philippi",
  },
  φιλόθεοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G5377",
    gloss: "God-loving",
  },
  Φιλόλογον: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G5378",
    gloss: "Philologus",
  },
  φιλονεικία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5379",
    gloss: "love of dispute",
  },
  φιλόνεικος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5380",
    gloss: "dispute-loving",
  },
  φιλοξενίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5381",
    gloss: "hospitality",
  },
  φιλοξενίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5381",
    gloss: "hospitality",
  },
  φιλόξενοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G5382",
    gloss: "hospitable",
  },
  φιλόξενον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G5382",
    gloss: "hospitable",
  },
  φιλοπρωτεύων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G5383",
    gloss: "to love to be first",
  },
  φίλας: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G5384",
    gloss: "friendly/friend",
  },
  Φίλε: {
    // or nominative
    parse: "A-VSM｜Adjective, Vocative, singular, masculine",
    GN: "G5384",
    gloss: "friendly/friend",
  },
  φίλοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G5384",
    gloss: "friendly/friend",
  },
  φίλοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G5384",
    gloss: "friendly/friend",
  },
  φίλον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G5384",
    gloss: "friendly/friend",
  },
  φίλος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5384",
    gloss: "friendly/friend",
  },
  φίλους: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G5384",
    gloss: "friendly/friend",
  },
  φίλων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G5384",
    gloss: "friendly/friend",
  },
  φιλοσοφίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5385",
    gloss: "philosophy",
  },
  φιλοσόφων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G5386",
    gloss: "philosopher",
  },
  φιλόστοργοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G5387",
    gloss: "affectionate",
  },
  φιλοτέκνους: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G5388",
    gloss: "child loving",
  },
  φιλοτιμεῖσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G5389",
    gloss: "to aspire",
  },
  φιλοτιμούμεθα: {
    parse:
      "V-PNI-1P｜Verb, present, Middle or Passive deponent, indicative, first, plural",
    GN: "G5389",
    gloss: "to aspire",
  },
  φιλοτιμούμενον: {
    parse:
      "V-PNP-ASM｜Verb, present, Middle or Passive deponent, Participle, accusative, singular, masculine",
    GN: "G5389",
    gloss: "to aspire",
  },
  φιλοφρόνως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G5390",
    gloss: "hospitably",
  },
  ἐφιμώθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G5392",
    gloss: "to muzzle",
  },
  ἐφίμωσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5392",
    gloss: "to muzzle",
  },
  κημώσεις: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G5392",
    gloss: "to muzzle",
  },
  πεφίμωσο: {
    parse: "V-RPM-2S｜Verb, perfect, passive, imperative, second, singular",
    GN: "G5392",
    gloss: "to muzzle",
  },
  φιμοῦν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G5392",
    gloss: "to muzzle",
  },
  Φιμώθητι: {
    parse: "V-APM-2S｜Verb, aorist, passive, imperative, second, singular",
    GN: "G5392",
    gloss: "to muzzle",
  },
  φιμώσεις: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G5392",
    gloss: "to muzzle",
  },
  Φλέγοντα: {
    parse: "N-ASM-P｜Noun, accusative, singular, masculine, Person",
    GN: "G5393",
    gloss: "Phlegon",
  },
  φλογιζομένη: {
    parse:
      "V-PPP-NSF｜Verb, present, passive, Participle, nominative, singular, feminine",
    GN: "G5394",
    gloss: "to kindle",
  },
  φλογίζουσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G5394",
    gloss: "to kindle",
  },
  φλόγα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5395",
    gloss: "flame",
  },
  φλογὶ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5395",
    gloss: "flame",
  },
  φλογός: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5395",
    gloss: "flame",
  },
  φλὸξ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5395",
    gloss: "flame",
  },
  φλυαρῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G5396",
    gloss: "to talk nonsense",
  },
  φλύαροι: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G5397",
    gloss: "gossipy",
  },
  φοβερὰ: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G5398",
    gloss: "fearful",
  },
  φοβερὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G5398",
    gloss: "fearful",
  },
  ἐφοβεῖτο: {
    parse:
      "V-INI-3S｜Verb, imperfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G5399",
    gloss: "to fear",
  },
  ἐφοβήθη: {
    parse:
      "V-AOI-3S｜Verb, aorist, Passive deponent, indicative, third, singular",
    GN: "G5399",
    gloss: "to fear",
  },
  ἐφοβήθησαν: {
    parse:
      "V-AOI-3P｜Verb, aorist, Passive deponent, indicative, third, plural",
    GN: "G5399",
    gloss: "to fear",
  },
  ἐφοβούμην: {
    parse:
      "V-INI-1S｜Verb, imperfect, Middle or Passive deponent, indicative, first, singular",
    GN: "G5399",
    gloss: "to fear",
  },
  ἐφοβοῦντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G5399",
    gloss: "to fear",
  },
  φοβεῖσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G5399",
    gloss: "to fear",
  },
  φοβεῖσθε: {
    parse:
      "V-PNM-2P｜Verb, present, Middle or Passive deponent, imperative, second, plural",
    GN: "G5399",
    gloss: "to fear",
  },
  φοβῇ: {
    parse:
      "V-PNI-2S｜Verb, present, Middle or Passive deponent, indicative, second, singular",
    GN: "G5399",
    gloss: "to fear",
  },
  φοβηθεὶς: {
    parse:
      "V-AOP-NSM｜Verb, aorist, Passive deponent, Participle, nominative, singular, masculine",
    GN: "G5399",
    gloss: "to fear",
  },
  φοβηθεῖσα: {
    parse:
      "V-AOP-NSF｜Verb, aorist, Passive deponent, Participle, nominative, singular, feminine",
    GN: "G5399",
    gloss: "to fear",
  },
  φοβηθέντες: {
    parse:
      "V-AOP-NPM｜Verb, aorist, Passive deponent, Participle, nominative, plural, masculine",
    GN: "G5399",
    gloss: "to fear",
  },
  φοβηθῇ: {
    parse:
      "V-AOS-3S｜Verb, aorist, Passive deponent, subjunctive, third, singular",
    GN: "G5399",
    gloss: "to fear",
  },
  φοβηθῇς: {
    parse:
      "V-AOS-2S｜Verb, aorist, Passive deponent, subjunctive, second, singular",
    GN: "G5399",
    gloss: "to fear",
  },
  φοβηθήσομαι: {
    parse:
      "V-FOI-1S｜Verb, future, Passive deponent, indicative, first, singular",
    GN: "G5399",
    gloss: "to fear",
  },
  φοβηθῆτε: {
    parse:
      "V-AOS-2P｜Verb, aorist, Passive deponent, subjunctive, second, plural",
    GN: "G5399",
    gloss: "to fear",
  },
  φοβήθητε: {
    parse:
      "V-AOM-2P｜Verb, aorist, Passive deponent, imperative, second, plural",
    GN: "G5399",
    gloss: "to fear",
  },
  Φοβήθητε: {
    parse:
      "V-AOM-2P｜Verb, aorist, Passive deponent, imperative, second, plural",
    GN: "G5399",
    gloss: "to fear",
  },
  Φοβηθῶμεν: {
    parse:
      "V-AOS-1P｜Verb, aorist, Passive deponent, subjunctive, first, plural",
    GN: "G5399",
    gloss: "to fear",
  },
  φοβῆται: {
    parse:
      "V-PNS-3S｜Verb, present, Middle or Passive deponent, subjunctive, third, singular",
    GN: "G5399",
    gloss: "to fear",
  },
  φοβοῦ: {
    parse:
      "V-PNM-2S｜Verb, present, Middle or Passive deponent, imperative, second, singular",
    GN: "G5399",
    gloss: "to fear",
  },
  φοβοῦμαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G5399",
    gloss: "to fear",
  },
  φοβούμεθα: {
    parse:
      "V-PNI-1P｜Verb, present, Middle or Passive deponent, indicative, first, plural",
    GN: "G5399",
    gloss: "to fear",
  },
  φοβούμεναι: {
    parse:
      "V-PNP-NPF｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, feminine",
    GN: "G5399",
    gloss: "to fear",
  },
  φοβούμενοι: {
    parse:
      "V-PNP-VPM｜Verb, present, Middle or Passive deponent, Participle, Vocative or nominative, plural, masculine",
    GN: "G5399",
    gloss: "to fear",
  },
  φοβούμενοί: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G5399",
    gloss: "to fear",
  },
  φοβουμένοις: {
    parse:
      "V-PNP-DPM｜Verb, present, Middle or Passive deponent, Participle, dative, plural, masculine",
    GN: "G5399",
    gloss: "to fear",
  },
  φοβούμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G5399",
    gloss: "to fear",
  },
  φόβητρά: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G5400",
    gloss: "fearful thing",
  },
  φόβοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G5401",
    gloss: "fear",
  },
  φόβον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5401",
    gloss: "fear",
  },
  φόβος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5401",
    gloss: "fear",
  },
  φόβου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G5401",
    gloss: "fear",
  },
  φόβῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G5401",
    gloss: "fear",
  },
  Φοίβην: {
    parse: "N-ASF-P｜Noun, accusative, singular, feminine, Person",
    GN: "G5402",
    gloss: "Phoebe",
  },
  Φοινίκην: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G5403",
    gloss: "Phoenicia",
  },
  Φοινίκης: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G5403",
    gloss: "Phoenicia",
  },
  φοίνικες: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G5404",
    gloss: "palm",
  },
  φοινίκων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G5404",
    gloss: "palm",
  },
  Φοίνικα: {
    parse: "N-ASM-L｜Noun, accusative, singular, masculine, Location",
    GN: "G5405",
    gloss: "Phoenix",
  },
  φονέα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5406",
    gloss: "murderer",
  },
  φονεῖς: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G5406",
    gloss: "murderer",
  },
  φονεύς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5406",
    gloss: "murderer",
  },
  φονεὺς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5406",
    gloss: "murderer",
  },
  φονεῦσι: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G5406",
    gloss: "murderer",
  },
  φονεῦσιν: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G5406",
    gloss: "murderer",
  },
  ἐφονεύσατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G5407",
    gloss: "to murder",
  },
  φονεύεις: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G5407",
    gloss: "to murder",
  },
  φονεύετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G5407",
    gloss: "to murder",
  },
  φονευσάντων: {
    parse:
      "V-AAP-GPM｜Verb, aorist, active, Participle, genitive, plural, masculine",
    GN: "G5407",
    gloss: "to murder",
  },
  φονεύσεις: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G5407",
    gloss: "to murder",
  },
  φονεύσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G5407",
    gloss: "to murder",
  },
  φονεύσῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G5407",
    gloss: "to murder",
  },
  φόνοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G5408",
    gloss: "murder",
  },
  φόνον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5408",
    gloss: "murder",
  },
  φόνου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G5408",
    gloss: "murder",
  },
  φόνῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G5408",
    gloss: "murder",
  },
  φόνων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G5408",
    gloss: "murder",
  },
  ἐφορέσαμεν: {
    parse: "V-AAI-1P｜Verb, aorist, active, indicative, first, plural",
    GN: "G5409",
    gloss: "to wear",
  },
  φορεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5409",
    gloss: "to wear",
  },
  φορέσομεν: {
    parse: "V-FAI-1P｜Verb, future, active, indicative, first, plural",
    GN: "G5409",
    gloss: "to wear",
  },
  φοροῦντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G5409",
    gloss: "to wear",
  },
  φοροῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G5409",
    gloss: "to wear",
  },
  φορῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G5409",
    gloss: "to wear",
  },
  Φόρου: {
    parse: "N-GSN-L｜Noun, genitive, singular, neuter, Location",
    GN: "G5410",
    gloss: "Forum",
  },
  φόρον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5411",
    gloss: "tax",
  },
  φόρους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G5411",
    gloss: "tax",
  },
  πεφορτισμένοι: {
    parse:
      "V-RPP-VPM｜Verb, perfect, passive, Participle, Vocative or nominative, plural, masculine",
    GN: "G5412",
    gloss: "to burden",
  },
  φορτίζετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G5412",
    gloss: "to burden",
  },
  φορτία: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G5413",
    gloss: "burden",
  },
  φορτίοις: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G5413",
    gloss: "burden",
  },
  φορτίον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G5413",
    gloss: "burden",
  },
  φορτίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G5413",
    gloss: "burden",
  },
  Φορτουνάτου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G5415",
    gloss: "Fortunatus",
  },
  φραγέλλιον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G5416",
    gloss: "whip",
  },
  φραγελλώσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G5417",
    gloss: "to whip",
  },
  φραγμὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5418",
    gloss: "fence",
  },
  φραγμοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G5418",
    gloss: "fence",
  },
  φραγμοὺς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G5418",
    gloss: "fence",
  },
  Φράσον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G5419",
    gloss: "to explain",
  },
  ἔφραξαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G5420",
    gloss: "to stop",
  },
  φραγῇ: {
    parse: "V-2APS-3S｜Verb, 2nd aorist, passive, subjunctive, third, singular",
    GN: "G5420",
    gloss: "to stop",
  },
  φραγήσεται: {
    parse: "V-2FPI-3S｜Verb, 2nd future, passive, indicative, third, singular",
    GN: "G5420",
    gloss: "to stop",
  },
  φρέαρ: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G5421",
    gloss: "well/abyss",
  },
  φρέατος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G5421",
    gloss: "well/abyss",
  },
  φρεναπατᾷ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5422",
    gloss: "to deceive",
  },
  φρεναπάται: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G5423",
    gloss: "deceiver",
  },
  φρεσίν: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G5424",
    gloss: "thinking",
  },
  φρεσὶν: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G5424",
    gloss: "thinking",
  },
  φρίσσουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G5425",
    gloss: "to shudder",
  },
  ἐφρονεῖτε: {
    parse: "V-IAI-2P｜Verb, imperfect, active, indicative, second, plural",
    GN: "G5426",
    gloss: "to reason",
  },
  ἐφρόνουν: {
    parse: "V-IAI-1S｜Verb, imperfect, active, indicative, first, singular",
    GN: "G5426",
    gloss: "to reason",
  },
  φρονεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5426",
    gloss: "to reason",
  },
  φρόνει: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G5426",
    gloss: "to reason",
  },
  φρονεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G5426",
    gloss: "to reason",
  },
  φρονεῖς: {
    parse: "V-PAI-2S｜Verb, present, active, indicative, second, singular",
    GN: "G5426",
    gloss: "to reason",
  },
  φρονεῖτε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G5426",
    gloss: "to reason",
  },
  φρονήσετε: {
    parse: "V-FAI-2P｜Verb, future, active, indicative, second, plural",
    GN: "G5426",
    gloss: "to reason",
  },
  φρονῆτε: {
    parse: "V-PAS-2P｜Verb, present, active, subjunctive, second, plural",
    GN: "G5426",
    gloss: "to reason",
  },
  φρονοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G5426",
    gloss: "to reason",
  },
  φρονοῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G5426",
    gloss: "to reason",
  },
  φρονῶμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G5426",
    gloss: "to reason",
  },
  φρονῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G5426",
    gloss: "to reason",
  },
  φρόνημα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G5427",
    gloss: "mind set, purpose",
  },
  φρονήσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5428",
    gloss: "understanding",
  },
  φρόνιμοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G5429",
    gloss: "thoughtful",
  },
  φρονίμοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G5429",
    gloss: "thoughtful",
  },
  φρόνιμος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5429",
    gloss: "thoughtful",
  },
  φρονίμῳ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G5429",
    gloss: "thoughtful",
  },
  φρονιμώτεροι: {
    parse: "A-NPM-C｜Adjective, nominative, plural, masculine, Comparative",
    GN: "G5429",
    gloss: "thoughtful",
  },
  φρονίμως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G5430",
    gloss: "shrewdly",
  },
  φροντίζωσιν: {
    parse: "V-PAS-3P｜Verb, present, active, subjunctive, third, plural",
    GN: "G5431",
    gloss: "be careful",
  },
  ἐφρούρει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G5432",
    gloss: "to guard",
  },
  ἐφρουρούμεθα: {
    parse: "V-IPI-1P｜Verb, imperfect, passive, indicative, first, plural",
    GN: "G5432",
    gloss: "to guard",
  },
  φρουρήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G5432",
    gloss: "to guard",
  },
  φρουρουμένους: {
    parse:
      "V-PPP-APM｜Verb, present, passive, Participle, accusative, plural, masculine",
    GN: "G5432",
    gloss: "to guard",
  },
  ἐφρύαξαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G5433",
    gloss: "to rage",
  },
  φρυγάνων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G5434",
    gloss: "brushwood",
  },
  Φρυγίαν: {
    parse: "N-ASF-L｜Noun, accusative, singular, feminine, Location",
    GN: "G5435",
    gloss: "Phrygia",
  },
  Φύγελος: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G5436",
    gloss: "Phygelus",
  },
  φυγὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5437",
    gloss: "flight",
  },
  φυλακαῖς: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G5438",
    gloss: "watch",
  },
  φυλακάς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G5438",
    gloss: "watch",
  },
  φυλακὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G5438",
    gloss: "watch",
  },
  φυλακῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5438",
    gloss: "watch",
  },
  φυλακὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5438",
    gloss: "watch",
  },
  φυλακήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5438",
    gloss: "watch",
  },
  φυλακὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5438",
    gloss: "watch",
  },
  φυλακῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5438",
    gloss: "watch",
  },
  φυλακίζων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G5439",
    gloss: "to imprison",
  },
  φυλακτήρια: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G5440",
    gloss: "phylactery",
  },
  φύλακας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G5441",
    gloss: "guard",
  },
  φύλακές: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G5441",
    gloss: "guard",
  },
  ἐφύλαξα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G5442",
    gloss: "protect",
  },
  ἐφυλαξάμην: {
    parse: "V-AMI-1S｜Verb, aorist, middle, indicative, first, singular",
    GN: "G5442",
    gloss: "protect",
  },
  ἐφυλάξατε: {
    parse: "V-AAI-2P｜Verb, aorist, active, indicative, second, plural",
    GN: "G5442",
    gloss: "protect",
  },
  ἐφύλαξεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5442",
    gloss: "protect",
  },
  φυλάξαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G5442",
    gloss: "protect",
  },
  φυλάξατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G5442",
    gloss: "protect",
  },
  φυλάξει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G5442",
    gloss: "protect",
  },
  φυλάξῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G5442",
    gloss: "protect",
  },
  φυλάξῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G5442",
    gloss: "protect",
  },
  φύλαξον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G5442",
    gloss: "protect",
  },
  φυλάσσειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G5442",
    gloss: "protect",
  },
  φυλάσσεσθαι: {
    parse: "V-PEN｜Verb, present, Either middle or passive, Infinitive",
    GN: "G5442",
    gloss: "protect",
  },
  φυλάσσεσθε: {
    parse: "V-PMM-2P｜Verb, present, middle, imperative, second, plural",
    GN: "G5442",
    gloss: "protect",
  },
  φυλάσσῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G5442",
    gloss: "protect",
  },
  φυλασσόμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G5442",
    gloss: "protect",
  },
  φυλάσσοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G5442",
    gloss: "protect",
  },
  φυλάσσοντι: {
    parse:
      "V-PAP-DSM｜Verb, present, active, Participle, dative, singular, masculine",
    GN: "G5442",
    gloss: "protect",
  },
  φυλάσσου: {
    parse: "V-PMM-2S｜Verb, present, middle, imperative, second, singular",
    GN: "G5442",
    gloss: "protect",
  },
  φυλάσσουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G5442",
    gloss: "protect",
  },
  φυλάσσων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G5442",
    gloss: "protect",
  },
  φυλαὶ: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G5443",
    gloss: "tribe",
  },
  φυλαῖς: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G5443",
    gloss: "tribe",
  },
  φυλὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G5443",
    gloss: "tribe",
  },
  φυλὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5443",
    gloss: "tribe",
  },
  φυλῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5443",
    gloss: "tribe",
  },
  φυλῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G5443",
    gloss: "tribe",
  },
  φύλλα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G5444",
    gloss: "leaf",
  },
  φύραμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G5445",
    gloss: "lump",
  },
  φυράματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G5445",
    gloss: "lump",
  },
  φυσικὰ: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G5446",
    gloss: "natural",
  },
  φυσικὴν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G5446",
    gloss: "natural",
  },
  φυσικῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G5447",
    gloss: "physically",
  },
  ἐφυσιώθησάν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G5448",
    gloss: "to inflate",
  },
  πεφυσιωμένοι: {
    parse:
      "V-RPP-NPM｜Verb, perfect, passive, Participle, nominative, plural, masculine",
    GN: "G5448",
    gloss: "to inflate",
  },
  πεφυσιωμένων: {
    parse:
      "V-RPP-GPM｜Verb, perfect, passive, Participle, genitive, plural, masculine",
    GN: "G5448",
    gloss: "to inflate",
  },
  φυσιοῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5448",
    gloss: "to inflate",
  },
  φυσιούμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G5448",
    gloss: "to inflate",
  },
  φυσιοῦσθε: {
    parse: "V-PPI-2P｜Verb, present, passive, indicative, second, plural",
    GN: "G5448",
    gloss: "to inflate",
  },
  φυσιοῦται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G5448",
    gloss: "to inflate",
  },
  φύσει: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5449",
    gloss: "nature",
  },
  φύσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5449",
    gloss: "nature",
  },
  φύσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5449",
    gloss: "nature",
  },
  φύσις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5449",
    gloss: "nature",
  },
  φυσιώσεις: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G5450",
    gloss: "arrogance",
  },
  φυτεία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5451",
    gloss: "plant",
  },
  ἐφύτευον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G5452",
    gloss: "to plant",
  },
  ἐφύτευσα: {
    parse: "V-AAI-1S｜Verb, aorist, active, indicative, first, singular",
    GN: "G5452",
    gloss: "to plant",
  },
  ἐφύτευσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5452",
    gloss: "to plant",
  },
  πεφυτευμένην: {
    parse:
      "V-RPP-ASF｜Verb, perfect, passive, Participle, accusative, singular, feminine",
    GN: "G5452",
    gloss: "to plant",
  },
  φυτεύει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5452",
    gloss: "to plant",
  },
  φυτεύθητι: {
    parse: "V-APM-2S｜Verb, aorist, passive, imperative, second, singular",
    GN: "G5452",
    gloss: "to plant",
  },
  φυτεύων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G5452",
    gloss: "to plant",
  },
  φυὲν: {
    parse:
      "V-2APP-NSN｜Verb, 2nd aorist, passive, Participle, nominative, singular, neuter",
    GN: "G5453",
    gloss: "to grow",
  },
  φύουσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G5453",
    gloss: "to grow",
  },
  φωλεοὺς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G5454",
    gloss: "den",
  },
  ἐφώνει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G5455",
    gloss: "to call",
  },
  ἐφώνησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G5455",
    gloss: "to call",
  },
  Ἐφώνησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G5455",
    gloss: "to call",
  },
  ἐφώνησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5455",
    gloss: "to call",
  },
  φωνεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5455",
    gloss: "to call",
  },
  φώνει: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G5455",
    gloss: "to call",
  },
  φωνεῖτέ: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G5455",
    gloss: "to call",
  },
  φωνηθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G5455",
    gloss: "to call",
  },
  φωνῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G5455",
    gloss: "to call",
  },
  φωνῆσαν: {
    parse:
      "V-AAP-NSN｜Verb, aorist, active, Participle, nominative, singular, neuter",
    GN: "G5455",
    gloss: "to call",
  },
  φωνήσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G5455",
    gloss: "to call",
  },
  φωνήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G5455",
    gloss: "to call",
  },
  Φωνήσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G5455",
    gloss: "to call",
  },
  φωνήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G5455",
    gloss: "to call",
  },
  φωνήσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G5455",
    gloss: "to call",
  },
  φώνησον: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G5455",
    gloss: "to call",
  },
  φωνοῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G5455",
    gloss: "to call",
  },
  φωνοῦσι: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G5455",
    gloss: "to call",
  },
  φωναὶ: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G5456",
    gloss: "voice",
  },
  φωναῖς: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G5456",
    gloss: "voice",
  },
  φωνάς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G5456",
    gloss: "voice",
  },
  φωνὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G5456",
    gloss: "voice",
  },
  φωνῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5456",
    gloss: "voice",
  },
  φωνὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5456",
    gloss: "voice",
  },
  Φωνὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5456",
    gloss: "voice",
  },
  φωνήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5456",
    gloss: "voice",
  },
  φωνὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5456",
    gloss: "voice",
  },
  φωνῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5456",
    gloss: "voice",
  },
  φωνῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G5456",
    gloss: "voice",
  },
  φῶς: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G5457",
    gloss: "light",
  },
  φῶτα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G5457",
    gloss: "light",
  },
  φωτί: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G5457",
    gloss: "light",
  },
  φωτὶ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G5457",
    gloss: "light",
  },
  φωτός: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G5457",
    gloss: "light",
  },
  φωτὸς: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G5457",
    gloss: "light",
  },
  φώτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G5457",
    gloss: "light",
  },
  φωστὴρ: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5458",
    gloss: "light",
  },
  φωστῆρες: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G5458",
    gloss: "light",
  },
  φωσφόρος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5459",
    gloss: "light-bearing",
  },
  φωτεινὴ: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G5460",
    gloss: "bright",
  },
  φωτεινόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G5460",
    gloss: "bright",
  },
  φωτεινὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G5460",
    gloss: "bright",
  },
  ἐφώτισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5461",
    gloss: "to illuminate",
  },
  ἐφωτίσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G5461",
    gloss: "to illuminate",
  },
  πεφωτισμένους: {
    parse:
      "V-RPP-APM｜Verb, perfect, passive, Participle, accusative, plural, masculine",
    GN: "G5461",
    gloss: "to illuminate",
  },
  φωτίζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5461",
    gloss: "to illuminate",
  },
  φωτίζῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G5461",
    gloss: "to illuminate",
  },
  φωτίσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G5461",
    gloss: "to illuminate",
  },
  φωτίσαντος: {
    parse:
      "V-AAP-GSM｜Verb, aorist, active, Participle, genitive, singular, masculine",
    GN: "G5461",
    gloss: "to illuminate",
  },
  φωτίσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G5461",
    gloss: "to illuminate",
  },
  φωτισθέντας: {
    parse:
      "V-APP-APM｜Verb, aorist, passive, Participle, accusative, plural, masculine",
    GN: "G5461",
    gloss: "to illuminate",
  },
  φωτισθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G5461",
    gloss: "to illuminate",
  },
  φωτισμὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5462",
    gloss: "light",
  },
  ἔχαιρεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G5463",
    gloss: "to rejoice",
  },
  ἔχαιρον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G5463",
    gloss: "to rejoice",
  },
  ἐχάρη: {
    parse:
      "V-2AOI-3S｜Verb, 2nd aorist, Passive deponent, indicative, third, singular",
    GN: "G5463",
    gloss: "to rejoice",
  },
  ἐχάρημεν: {
    parse:
      "V-2AOI-1P｜Verb, 2nd aorist, Passive deponent, indicative, first, plural",
    GN: "G5463",
    gloss: "to rejoice",
  },
  ἐχάρην: {
    parse:
      "V-2AOI-1S｜Verb, 2nd aorist, Passive deponent, indicative, first, singular",
    GN: "G5463",
    gloss: "to rejoice",
  },
  Ἐχάρην: {
    parse:
      "V-2AOI-1S｜Verb, 2nd aorist, Passive deponent, indicative, first, singular",
    GN: "G5463",
    gloss: "to rejoice",
  },
  ἐχάρησαν: {
    parse:
      "V-2AOI-3P｜Verb, 2nd aorist, Passive deponent, indicative, third, plural",
    GN: "G5463",
    gloss: "to rejoice",
  },
  ἐχάρητε: {
    parse:
      "V-2AOI-2P｜Verb, 2nd aorist, Passive deponent, indicative, second, plural",
    GN: "G5463",
    gloss: "to rejoice",
  },
  Χαῖρε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G5463",
    gloss: "to rejoice",
  },
  χαίρει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5463",
    gloss: "to rejoice",
  },
  χαίρειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G5463",
    gloss: "to rejoice",
  },
  χαίρετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G5463",
    gloss: "to rejoice",
  },
  Χαίρετε: {
    parse: "V-PAM-2P｜Verb, present, active, imperative, second, plural",
    GN: "G5463",
    gloss: "to rejoice",
  },
  χαίρῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G5463",
    gloss: "to rejoice",
  },
  χαίρομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G5463",
    gloss: "to rejoice",
  },
  χαίροντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G5463",
    gloss: "to rejoice",
  },
  χαιρόντων: {
    parse:
      "V-PAP-GPM｜Verb, present, active, Participle, genitive, plural, masculine",
    GN: "G5463",
    gloss: "to rejoice",
  },
  χαίρουσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G5463",
    gloss: "to rejoice",
  },
  χαίρω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G5463",
    gloss: "to rejoice",
  },
  χαίρωμεν: {
    parse: "V-PAS-1P｜Verb, present, active, subjunctive, first, plural",
    GN: "G5463",
    gloss: "to rejoice",
  },
  χαίρων: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G5463",
    gloss: "to rejoice",
  },
  χαρῆναι: {
    parse: "V-2AON｜Verb, 2nd aorist, Passive deponent, Infinitive",
    GN: "G5463",
    gloss: "to rejoice",
  },
  χαρήσεται: {
    parse:
      "V-2FOI-3S｜Verb, 2nd future, Passive deponent, indicative, third, singular",
    GN: "G5463",
    gloss: "to rejoice",
  },
  χαρήσομαι: {
    parse:
      "V-2FOI-1S｜Verb, 2nd future, Passive deponent, indicative, first, singular",
    GN: "G5463",
    gloss: "to rejoice",
  },
  χαρήσονται: {
    parse:
      "V-2FOI-3P｜Verb, 2nd future, Passive deponent, indicative, third, plural",
    GN: "G5463",
    gloss: "to rejoice",
  },
  χάρητε: {
    parse:
      "V-2AOM-2P｜Verb, 2nd aorist, Passive deponent, imperative, second, plural",
    GN: "G5463",
    gloss: "to rejoice",
  },
  χαρῆτε: {
    parse:
      "V-2AOS-2P｜Verb, 2nd aorist, Passive deponent, subjunctive, second, plural",
    GN: "G5463",
    gloss: "to rejoice",
  },
  χάλαζα: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5464",
    gloss: "hail",
  },
  χαλάζης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5464",
    gloss: "hail",
  },
  ἐχαλάσθην: {
    parse: "V-API-1S｜Verb, aorist, passive, indicative, first, singular",
    GN: "G5465",
    gloss: "to lower",
  },
  χαλάσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G5465",
    gloss: "to lower",
  },
  χαλασάντων: {
    parse:
      "V-AAP-GPM｜Verb, aorist, active, Participle, genitive, plural, masculine",
    GN: "G5465",
    gloss: "to lower",
  },
  χαλάσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G5465",
    gloss: "to lower",
  },
  χαλάσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G5465",
    gloss: "to lower",
  },
  χαλῶσι: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G5465",
    gloss: "to lower",
  },
  Χαλδαίων: {
    parse: "N-GPM-LG｜Noun, genitive, plural, masculine, Location Gentilic",
    GN: "G5466",
    gloss: "Chaldean",
  },
  χαλεποί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G5467",
    gloss: "harsh",
  },
  χαλεποὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G5467",
    gloss: "harsh",
  },
  χαλιναγωγῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G5468",
    gloss: "to bridle",
  },
  χαλιναγωγῶν: {
    parse:
      "V-PAP-NSM｜Verb, present, active, Participle, nominative, singular, masculine",
    GN: "G5468",
    gloss: "to bridle",
  },
  χαλινοὺς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G5469",
    gloss: "bridle",
  },
  χαλινῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G5469",
    gloss: "bridle",
  },
  χαλκᾶ: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G5470",
    gloss: "bronze",
  },
  χαλκεὺς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5471",
    gloss: "coppersmith",
  },
  χαλκηδών: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5472",
    gloss: "chalcedony",
  },
  χαλκίων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G5473",
    gloss: "kettle",
  },
  χαλκολιβάνῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G5474",
    gloss: "bronze",
  },
  χαλκόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5475",
    gloss: "copper/bronze/coin",
  },
  χαλκὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5475",
    gloss: "copper/bronze/coin",
  },
  χαλκὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5475",
    gloss: "copper/bronze/coin",
  },
  χαλκοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G5475",
    gloss: "copper/bronze/coin",
  },
  χαμαί: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G5476",
    gloss: "on the ground",
  },
  χαμαὶ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G5476",
    gloss: "on the ground",
  },
  Χανάαν: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G5477",
    gloss: "Canaan",
  },
  Χαναναία: {
    parse:
      "A-NSF-LG｜Adjective, nominative, singular, feminine, Location Gentilic",
    GN: "G5478",
    gloss: "Canaanite",
  },
  χαρᾷ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5479",
    gloss: "joy",
  },
  χαρά: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5479",
    gloss: "joy",
  },
  χαρὰ: {
    parse: "N-VSF｜Noun, Vocative or nominative, singular, feminine",
    GN: "G5479",
    gloss: "joy",
  },
  χαράν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5479",
    gloss: "joy",
  },
  χαρὰν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5479",
    gloss: "joy",
  },
  χαρᾶς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5479",
    gloss: "joy",
  },
  χάραγμα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G5480",
    gloss: "image/mark",
  },
  χαράγματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G5480",
    gloss: "image/mark",
  },
  χαρακτὴρ: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5481",
    gloss: "reproduction",
  },
  χάρακά: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5482",
    gloss: "barricade",
  },
  ἐχαρίσατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G5483",
    gloss: "to give grace",
  },
  ἐχαρίσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G5483",
    gloss: "to give grace",
  },
  κεχάρισμαι: {
    parse:
      "V-RNI-1S｜Verb, perfect, Middle or Passive deponent, indicative, first, singular",
    GN: "G5483",
    gloss: "to give grace",
  },
  κεχάρισται: {
    parse:
      "V-RNI-3S｜Verb, perfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G5483",
    gloss: "to give grace",
  },
  κεχάρισταί: {
    parse:
      "V-RNI-3S｜Verb, perfect, Middle or Passive deponent, indicative, third, singular",
    GN: "G5483",
    gloss: "to give grace",
  },
  χαρίζεσθαί: {
    parse: "V-PMN｜Verb, present, middle, Infinitive",
    GN: "G5483",
    gloss: "to give grace",
  },
  χαρίζεσθε: {
    parse:
      "V-PNI-2P｜Verb, present, Middle or Passive deponent, indicative, second, plural",
    GN: "G5483",
    gloss: "to give grace",
  },
  χαριζόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G5483",
    gloss: "to give grace",
  },
  χαρισάμενος: {
    parse:
      "V-ADP-NSM｜Verb, aorist, Middle deponent, Participle, nominative, singular, masculine",
    GN: "G5483",
    gloss: "to give grace",
  },
  χαρίσασθαι: {
    parse: "V-ADN｜Verb, aorist, Middle deponent, Infinitive",
    GN: "G5483",
    gloss: "to give grace",
  },
  χαρίσασθέ: {
    parse:
      "V-ADM-2P｜Verb, aorist, Middle deponent, imperative, second, plural",
    GN: "G5483",
    gloss: "to give grace",
  },
  χαρίσεται: {
    parse:
      "V-FDI-3S｜Verb, future, Middle deponent, indicative, third, singular",
    GN: "G5483",
    gloss: "to give grace",
  },
  χαρισθέντα: {
    parse:
      "V-APP-APN｜Verb, aorist, passive, Participle, accusative, plural, neuter",
    GN: "G5483",
    gloss: "to give grace",
  },
  χαρισθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G5483",
    gloss: "to give grace",
  },
  χαρισθήσομαι: {
    parse: "V-FPI-1S｜Verb, future, passive, indicative, first, singular",
    GN: "G5483",
    gloss: "to give grace",
  },
  χάριν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5485",
    gloss: "grace",
  },
  Χάριν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5485",
    gloss: "grace",
  },
  χάρις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5485",
    gloss: "grace",
  },
  Χάρις: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5485",
    gloss: "grace",
  },
  χάριτα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5485",
    gloss: "grace",
  },
  χάριτι: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5485",
    gloss: "grace",
  },
  χάριτί: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5485",
    gloss: "grace",
  },
  χάριτος: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5485",
    gloss: "grace",
  },
  χάρισμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G5486",
    gloss: "gift",
  },
  χαρίσματα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G5486",
    gloss: "gift",
  },
  χαρίσματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G5486",
    gloss: "gift",
  },
  χαρίσματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G5486",
    gloss: "gift",
  },
  χαρισμάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G5486",
    gloss: "gift",
  },
  ἐχαρίτωσεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5487",
    gloss: "to favor",
  },
  κεχαριτωμένη: {
    parse:
      "V-RPP-VSF｜Verb, perfect, passive, Participle, Vocative or nominative, singular, feminine",
    GN: "G5487",
    gloss: "to favor",
  },
  Χαρράν: {
    parse: "N-DSF-L｜Noun, dative, singular, feminine, Location",
    GN: "G5488",
    gloss: "Haran",
  },
  χάρτου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G5489",
    gloss: "paper",
  },
  χάσμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G5490",
    gloss: "chasm",
  },
  χείλεσιν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G5491",
    gloss: "lip",
  },
  χείλεσίν: {
    parse: "N-DPN｜Noun, dative, plural, neuter",
    GN: "G5491",
    gloss: "lip",
  },
  χειλέων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G5491",
    gloss: "lip",
  },
  χείλη: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G5491",
    gloss: "lip",
  },
  χεῖλος: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G5491",
    gloss: "lip",
  },
  χειμαζομένων: {
    parse:
      "V-PPP-GPM｜Verb, present, passive, Participle, genitive, plural, masculine",
    GN: "G5492",
    gloss: "be storm-tossed",
  },
  χειμάρρου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G5493",
    gloss: "brook",
  },
  χειμών: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5494",
    gloss: "winter",
  },
  χειμὼν: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5494",
    gloss: "winter",
  },
  χειμῶνος: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G5494",
    gloss: "winter",
  },
  χειμῶνός: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G5494",
    gloss: "winter",
  },
  χείρ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5495",
    gloss: "hand",
  },
  χεὶρ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5495",
    gloss: "hand",
  },
  χεῖρα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5495",
    gloss: "hand",
  },
  χεῖρά: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5495",
    gloss: "hand",
  },
  χεῖρας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G5495",
    gloss: "hand",
  },
  χεῖράς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G5495",
    gloss: "hand",
  },
  χεῖρες: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G5495",
    gloss: "hand",
  },
  χειρί: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5495",
    gloss: "hand",
  },
  χειρὶ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5495",
    gloss: "hand",
  },
  χειρός: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5495",
    gloss: "hand",
  },
  χειρὸς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5495",
    gloss: "hand",
  },
  χειρῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G5495",
    gloss: "hand",
  },
  χερσίν: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G5495",
    gloss: "hand",
  },
  χερσὶν: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G5495",
    gloss: "hand",
  },
  χειραγωγούμενος: {
    parse:
      "V-PPP-NSM｜Verb, present, passive, Participle, nominative, singular, masculine",
    GN: "G5496",
    gloss: "to guide",
  },
  χειραγωγοῦντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G5496",
    gloss: "to guide",
  },
  χειραγωγούς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G5497",
    gloss: "guide",
  },
  χειρόγραφον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G5498",
    gloss: "writen record",
  },
  χειροποίητα: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G5499",
    gloss: "hand-made",
  },
  χειροποιήτοις: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G5499",
    gloss: "hand-made",
  },
  χειροποίητον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G5499",
    gloss: "hand-made",
  },
  χειροποιήτου: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G5499",
    gloss: "hand-made",
  },
  χειροτονηθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G5500",
    gloss: "to appoint",
  },
  χειροτονήσαντες: {
    parse:
      "V-AAP-NPM｜Verb, aorist, active, Participle, nominative, plural, masculine",
    GN: "G5500",
    gloss: "to appoint",
  },
  χεῖρον: {
    parse: "A-NSN-C｜Adjective, nominative, singular, neuter, Comparative",
    GN: "G5501",
    gloss: "worse than",
  },
  χεῖρόν: {
    parse: "A-NSN-C｜Adjective, nominative, singular, neuter, Comparative",
    GN: "G5501",
    gloss: "worse than",
  },
  χείρονα: {
    parse: "A-NPN-C｜Adjective, nominative, plural, neuter, Comparative",
    GN: "G5501",
    gloss: "worse than",
  },
  χείρονος: {
    parse: "A-GSF-C｜Adjective, genitive, singular, feminine, Comparative",
    GN: "G5501",
    gloss: "worse than",
  },
  χείρων: {
    parse: "A-NSM-C｜Adjective, nominative, singular, masculine, Comparative",
    GN: "G5501",
    gloss: "worse than",
  },
  Χερουβὶν: {
    parse: "N-NPN-T｜Noun, nominative, plural, neuter, Title",
    GN: "G5502",
    gloss: "Cherub",
  },
  χήρα: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G5503",
    gloss: "widow",
  },
  Χήρα: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G5503",
    gloss: "widow",
  },
  χῆραι: {
    parse: "A-NPF｜Adjective, nominative, plural, feminine",
    GN: "G5503",
    gloss: "widow",
  },
  χήραις: {
    parse: "A-DPF｜Adjective, dative, plural, feminine",
    GN: "G5503",
    gloss: "widow",
  },
  χήραν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G5503",
    gloss: "widow",
  },
  χήρας: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G5503",
    gloss: "widow",
  },
  Χήρας: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G5503",
    gloss: "widow",
  },
  χηρῶν: {
    parse: "A-GPF｜Adjective, genitive, plural, feminine",
    GN: "G5503",
    gloss: "widow",
  },
  ἐχθὲς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G5504",
    gloss: "yesterday",
  },
  Ἐχθὲς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G5504",
    gloss: "yesterday",
  },
  χιλιάδες: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G5505",
    gloss: "thousand",
  },
  χιλιάδων: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G5505",
    gloss: "thousand",
  },
  χιλιάσιν: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G5505",
    gloss: "thousand",
  },
  χιλίαρχοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G5506",
    gloss: "military officer",
  },
  χιλιάρχοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G5506",
    gloss: "military officer",
  },
  χιλίαρχον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5506",
    gloss: "military officer",
  },
  χιλίαρχος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5506",
    gloss: "military officer",
  },
  χιλιάρχῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G5506",
    gloss: "military officer",
  },
  χιλιάρχων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G5506",
    gloss: "military officer",
  },
  χίλια: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G5507",
    gloss: "thousand",
  },
  χιλίας: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G5507",
    gloss: "thousand",
  },
  χιλίων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G5507",
    gloss: "thousand",
  },
  Χίου: {
    parse: "N-GSF-L｜Noun, genitive, singular, feminine, Location",
    GN: "G5508",
    gloss: "Chios",
  },
  χιτὼν: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5509",
    gloss: "tunic",
  },
  χιτῶνα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5509",
    gloss: "tunic",
  },
  χιτῶνά: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5509",
    gloss: "tunic",
  },
  χιτῶνας: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G5509",
    gloss: "tunic",
  },
  χιών: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5510",
    gloss: "snow",
  },
  χλαμύδα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5511",
    gloss: "robe",
  },
  ἐχλεύαζον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G5512",
    gloss: "to sneer",
  },
  χλιαρὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5513",
    gloss: "lukewarm",
  },
  Χλόης: {
    parse: "N-GSF-P｜Noun, genitive, singular, feminine, Person",
    GN: "G5514",
    gloss: "Chloe",
  },
  χλωρὸν: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G5515",
    gloss: "green",
  },
  χλωρός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5515",
    gloss: "green",
  },
  χλωρὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5515",
    gloss: "green",
  },
  χλωρῷ: {
    parse: "A-DSM｜Adjective, dative, singular, masculine",
    GN: "G5515",
    gloss: "green",
  },
  χοϊκοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G5517",
    gloss: "made of dust",
  },
  χοϊκός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5517",
    gloss: "made of dust",
  },
  χοϊκοῦ: {
    parse: "A-GSM｜Adjective, genitive, singular, masculine",
    GN: "G5517",
    gloss: "made of dust",
  },
  χοίνικες: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G5518",
    gloss: "quart",
  },
  Χοῖνιξ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5518",
    gloss: "quart",
  },
  χοῖροι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G5519",
    gloss: "pig",
  },
  χοίρους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G5519",
    gloss: "pig",
  },
  χοίρων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G5519",
    gloss: "pig",
  },
  χολᾶτε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G5520",
    gloss: "be angry",
  },
  χολὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5521",
    gloss: "gall",
  },
  χολῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5521",
    gloss: "gall",
  },
  χοῦν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5522",
    gloss: "dust",
  },
  Χοραζίν: {
    parse:
      "N-VSF-L｜Noun, Vocative or nominative, singular, feminine, Location",
    GN: "G5523",
    gloss: "Chorazin",
  },
  χορηγεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5524",
    gloss: "to provide",
  },
  χορηγήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G5524",
    gloss: "to provide",
  },
  χορῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G5525",
    gloss: "a dance",
  },
  ἐχορτάσθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G5526",
    gloss: "to feed",
  },
  ἐχορτάσθητε: {
    parse: "V-API-2P｜Verb, aorist, passive, indicative, second, plural",
    GN: "G5526",
    gloss: "to feed",
  },
  χορτάζεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G5526",
    gloss: "to feed",
  },
  χορτάζεσθε: {
    parse: "V-PMM-2P｜Verb, present, middle, imperative, second, plural",
    GN: "G5526",
    gloss: "to feed",
  },
  χορτάσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G5526",
    gloss: "to feed",
  },
  χορτασθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G5526",
    gloss: "to feed",
  },
  χορτασθήσεσθε: {
    parse: "V-FPI-2P｜Verb, future, passive, indicative, second, plural",
    GN: "G5526",
    gloss: "to feed",
  },
  χορτασθήσονται: {
    parse: "V-FPI-3P｜Verb, future, passive, indicative, third, plural",
    GN: "G5526",
    gloss: "to feed",
  },
  χορτάσματα: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G5527",
    gloss: "food",
  },
  χόρτον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5528",
    gloss: "grass",
  },
  χόρτος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5528",
    gloss: "grass",
  },
  χόρτου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G5528",
    gloss: "grass",
  },
  χόρτῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G5528",
    gloss: "grass",
  },
  Χουζᾶ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G5529",
    gloss: "Chuza",
  },
  ἐχρησάμεθα: {
    parse: "V-ADI-1P｜Verb, aorist, Middle deponent, indicative, first, plural",
    GN: "G5530",
    gloss: "to use",
  },
  ἐχρησάμην: {
    parse:
      "V-ADI-1S｜Verb, aorist, Middle deponent, indicative, first, singular",
    GN: "G5530",
    gloss: "to use",
  },
  ἐχρῶντο: {
    parse:
      "V-INI-3P｜Verb, imperfect, Middle or Passive deponent, indicative, third, plural",
    GN: "G5530",
    gloss: "to use",
  },
  κέχρημαι: {
    parse:
      "V-RNI-1S｜Verb, perfect, Middle or Passive deponent, indicative, first, singular",
    GN: "G5530",
    gloss: "to use",
  },
  χρῆσαι: {
    parse:
      "V-ADM-2S｜Verb, aorist, Middle deponent, imperative, second, singular",
    GN: "G5530",
    gloss: "to use",
  },
  χρησάμενος: {
    parse:
      "V-ADP-NSM｜Verb, aorist, Middle deponent, Participle, nominative, singular, masculine",
    GN: "G5530",
    gloss: "to use",
  },
  χρήσωμαι: {
    parse:
      "V-ADS-1S｜Verb, aorist, Middle deponent, subjunctive, first, singular",
    GN: "G5530",
    gloss: "to use",
  },
  χρῆται: {
    parse:
      "V-PNS-3S｜Verb, present, Middle or Passive deponent, subjunctive, third, singular",
    GN: "G5530",
    gloss: "to use",
  },
  χρῶ: {
    parse:
      "V-PNM-2S｜Verb, present, Middle or Passive deponent, imperative, second, singular",
    GN: "G5530",
    gloss: "to use",
  },
  χρώμεθα: {
    parse:
      "V-PNI-1P｜Verb, present, Middle or Passive deponent, indicative, first, plural",
    GN: "G5530",
    gloss: "to use",
  },
  χρώμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G5530",
    gloss: "to use",
  },
  χρῆσόν: {
    parse: "V-AAM-2S｜Verb, aorist, active, imperative, second, singular",
    GN: "G5531",
    gloss: "to lend",
  },
  χρεία: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5532",
    gloss: "need",
  },
  χρείαις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G5532",
    gloss: "need",
  },
  χρείαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5532",
    gloss: "need",
  },
  Χρείαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5532",
    gloss: "need",
  },
  χρείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5532",
    gloss: "need",
  },
  χρεοφειλέται: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G5533",
    gloss: "debtor",
  },
  χρεοφειλετῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G5533",
    gloss: "debtor",
  },
  χρή: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5534",
    gloss: "to ought/need",
  },
  χρῄζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5535",
    gloss: "to need",
  },
  χρῄζετε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G5535",
    gloss: "to need",
  },
  χρῄζῃ: {
    parse: "V-PAS-3S｜Verb, present, active, subjunctive, third, singular",
    GN: "G5535",
    gloss: "to need",
  },
  χρῄζομεν: {
    parse: "V-PAI-1P｜Verb, present, active, indicative, first, plural",
    GN: "G5535",
    gloss: "to need",
  },
  χρῆμα: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G5536",
    gloss: "money",
  },
  χρήματα: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G5536",
    gloss: "money",
  },
  χρημάτων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G5536",
    gloss: "money",
  },
  ἐχρηματίσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G5537",
    gloss: "to announce",
  },
  κεχρηματισμένον: {
    parse:
      "V-RPP-NSN｜Verb, perfect, passive, Participle, nominative, singular, neuter",
    GN: "G5537",
    gloss: "to announce",
  },
  κεχρημάτισται: {
    parse: "V-RPI-3S｜Verb, perfect, passive, indicative, third, singular",
    GN: "G5537",
    gloss: "to announce",
  },
  χρηματίζοντα: {
    parse:
      "V-PAP-ASM｜Verb, present, active, Participle, accusative, singular, masculine",
    GN: "G5537",
    gloss: "to announce",
  },
  χρηματίσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G5537",
    gloss: "to announce",
  },
  χρηματίσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G5537",
    gloss: "to announce",
  },
  χρηματισθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G5537",
    gloss: "to announce",
  },
  χρηματισθέντες: {
    parse:
      "V-APP-NPM｜Verb, aorist, passive, Participle, nominative, plural, masculine",
    GN: "G5537",
    gloss: "to announce",
  },
  χρηματισμός: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5538",
    gloss: "proclamation",
  },
  χρήσιμον: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G5539",
    gloss: "profitable",
  },
  χρῆσιν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5540",
    gloss: "use",
  },
  χρηστεύεται: {
    parse:
      "V-PNI-3S｜Verb, present, Middle or Passive deponent, indicative, third, singular",
    GN: "G5541",
    gloss: "be kind",
  },
  χρηστολογίας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5542",
    gloss: "smooth talk",
  },
  χρηστὰ: {
    parse: "A-APN｜Adjective, accusative, plural, neuter",
    GN: "G5543",
    gloss: "good/kind",
  },
  χρηστοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G5543",
    gloss: "good/kind",
  },
  χρηστὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G5543",
    gloss: "good/kind",
  },
  χρηστός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5543",
    gloss: "good/kind",
  },
  χρηστὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5543",
    gloss: "good/kind",
  },
  χρηστότης: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5544",
    gloss: "kindness",
  },
  χρηστότητα: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5544",
    gloss: "kindness",
  },
  χρηστότητι: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5544",
    gloss: "kindness",
  },
  χρηστότητος: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5544",
    gloss: "kindness",
  },
  χρῖσμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G5545",
    gloss: "anointing",
  },
  Χριστιανὸν: {
    parse: "N-ASM-T｜Noun, accusative, singular, masculine, Title",
    GN: "G5546",
    gloss: "Christian",
  },
  Χριστιανός: {
    parse: "N-NSM-T｜Noun, nominative, singular, masculine, Title",
    GN: "G5546",
    gloss: "Christian",
  },
  Χριστιανούς: {
    parse: "N-APM-T｜Noun, accusative, plural, masculine, Title",
    GN: "G5546",
    gloss: "Christian",
  },
  χριστέ: {
    parse: "N-VSM-T｜Noun, Vocative or nominative, singular, masculine, Title",
    GN: "G5547",
    gloss: "Christ",
  },
  χριστόν: {
    parse: "N-ASM-T｜Noun, accusative, singular, masculine, Title",
    GN: "G5547",
    gloss: "Christ",
  },
  Χριστόν: {
    parse: "N-ASM-T｜Noun, accusative, singular, masculine, Title",
    GN: "G5547",
    gloss: "Christ",
  },
  χριστὸν: {
    parse: "N-ASM-T｜Noun, accusative, singular, masculine, Title",
    GN: "G5547",
    gloss: "Christ",
  },
  Χριστὸν: {
    parse: "N-ASM-T｜Noun, accusative, singular, masculine, Title",
    GN: "G5547",
    gloss: "Christ",
  },
  χριστός: {
    parse: "N-NSM-T｜Noun, nominative, singular, masculine, Title",
    GN: "G5547",
    gloss: "Christ",
  },
  Χριστός: {
    parse: "N-NSM-T｜Noun, nominative, singular, masculine, Title",
    GN: "G5547",
    gloss: "Christ",
  },
  χριστὸς: {
    parse: "N-NSM-T｜Noun, nominative, singular, masculine, Title",
    GN: "G5547",
    gloss: "Christ",
  },
  Χριστὸς: {
    parse: "N-NSM-T｜Noun, nominative, singular, masculine, Title",
    GN: "G5547",
    gloss: "Christ",
  },
  χριστοῦ: {
    parse: "N-GSM-T｜Noun, genitive, singular, masculine, Title",
    GN: "G5547",
    gloss: "Christ",
  },
  Χριστοῦ: {
    parse: "N-GSM-T｜Noun, genitive, singular, masculine, Title",
    GN: "G5547",
    gloss: "Christ",
  },
  Χριστῷ: {
    parse: "N-DSM-T｜Noun, dative, singular, masculine, Title",
    GN: "G5547",
    gloss: "Christ",
  },
  ἔχρισας: {
    parse: "V-AAI-2S｜Verb, aorist, active, indicative, second, singular",
    GN: "G5548",
    gloss: "to anoint",
  },
  ἔχρισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5548",
    gloss: "to anoint",
  },
  ἔχρισέν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5548",
    gloss: "to anoint",
  },
  χρίσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G5548",
    gloss: "to anoint",
  },
  Χρονίζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5549",
    gloss: "to delay",
  },
  χρονίζειν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G5549",
    gloss: "to delay",
  },
  χρονίζοντος: {
    parse:
      "V-PAP-GSM｜Verb, present, active, Participle, genitive, singular, masculine",
    GN: "G5549",
    gloss: "to delay",
  },
  χρονίσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G5549",
    gloss: "to delay",
  },
  χρόνοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G5550",
    gloss: "time",
  },
  χρόνον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5550",
    gloss: "time",
  },
  χρόνος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5550",
    gloss: "time",
  },
  χρόνου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G5550",
    gloss: "time",
  },
  χρόνους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G5550",
    gloss: "time",
  },
  χρόνῳ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G5550",
    gloss: "time",
  },
  χρόνων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G5550",
    gloss: "time",
  },
  χρονοτριβῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G5551",
    gloss: "to spend time",
  },
  χρυσᾶ: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G5552",
    gloss: "golden",
  },
  χρυσᾶν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G5552",
    gloss: "golden",
  },
  χρυσᾶς: {
    parse: "A-APF｜Adjective, accusative, plural, feminine",
    GN: "G5552",
    gloss: "golden",
  },
  χρυσῆ: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G5552",
    gloss: "golden",
  },
  χρυσοῦ: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G5557",
    gloss: "gold",
  },
  χρυσοῦν: {
    parse: "A-ASN｜Adjective, accusative, singular, neuter",
    GN: "G5552",
    gloss: "golden",
  },
  χρυσοῦς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G5552",
    gloss: "golden",
  },
  χρυσῶν: {
    parse: "A-GPF｜Adjective, genitive, plural, feminine",
    GN: "G5552",
    gloss: "golden",
  },
  χρυσίον: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G5553",
    gloss: "gold",
  },
  χρυσίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G5553",
    gloss: "gold",
  },
  χρυσίῳ: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G5553",
    gloss: "gold",
  },
  χρυσίων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G5553",
    gloss: "gold",
  },
  χρυσοδακτύλιος: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5554",
    gloss: "gold-ringed",
  },
  χρυσόλιθος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5555",
    gloss: "chrysolite",
  },
  χρυσόπρασος: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5556",
    gloss: "chrysoprase",
  },
  χρυσόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5557",
    gloss: "gold",
  },
  χρυσὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5557",
    gloss: "gold",
  },
  χρυσὸς: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5557",
    gloss: "gold",
  },
  χρυσῷ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G5557",
    gloss: "gold",
  },
  κεχρυσωμένη: {
    parse:
      "V-RPP-VSF｜Verb, perfect, passive, Participle, Vocative or nominative, singular, feminine",
    GN: "G5558",
    gloss: "to gild",
  },
  χρωτὸς: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G5559",
    gloss: "skin",
  },
  χωλοὶ: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G5560",
    gloss: "lame",
  },
  χωλόν: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G5560",
    gloss: "lame",
  },
  χωλὸν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G5560",
    gloss: "lame",
  },
  χωλὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5560",
    gloss: "lame",
  },
  χωλούς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G5560",
    gloss: "lame",
  },
  χωλοὺς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G5560",
    gloss: "lame",
  },
  χωλῶν: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G5560",
    gloss: "lame",
  },
  χώρᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5561",
    gloss: "country",
  },
  χώρα: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5561",
    gloss: "country",
  },
  χώραις: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G5561",
    gloss: "country",
  },
  χώραν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5561",
    gloss: "country",
  },
  χώρας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5561",
    gloss: "country",
  },
  χωρεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5562",
    gloss: "to make room for",
  },
  χωρήσειν: {
    parse: "V-PAN｜Verb, future, active, Infinitive",
    GN: "G5562",
    gloss: "to make room for",
  },
  χωρεῖν: {
    parse: "V-PAN｜Verb, present, active, Infinitive",
    GN: "G5562",
    gloss: "to make room for",
  },
  χωρείτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G5562",
    gloss: "to make room for",
  },
  χωρῆσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G5562",
    gloss: "to make room for",
  },
  Χωρήσατε: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G5562",
    gloss: "to make room for",
  },
  χωροῦσαι: {
    parse:
      "V-PAP-NPF｜Verb, present, active, Participle, nominative, plural, feminine",
    GN: "G5562",
    gloss: "to make room for",
  },
  χωροῦσιν: {
    parse: "V-PAI-3P｜Verb, present, active, indicative, third, plural",
    GN: "G5562",
    gloss: "to make room for",
  },
  ἐχωρίσθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G5563",
    gloss: "to separate/leave",
  },
  κεχωρισμένος: {
    parse:
      "V-RPP-NSM｜Verb, perfect, passive, Participle, nominative, singular, masculine",
    GN: "G5563",
    gloss: "to separate/leave",
  },
  χωρίζεσθαι: {
    parse: "V-PPN｜Verb, present, passive, Infinitive",
    GN: "G5563",
    gloss: "to separate/leave",
  },
  χωριζέσθω: {
    parse: "V-PPM-3S｜Verb, present, passive, imperative, third, singular",
    GN: "G5563",
    gloss: "to separate/leave",
  },
  χωρίζεται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G5563",
    gloss: "to separate/leave",
  },
  χωριζέτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G5563",
    gloss: "to separate/leave",
  },
  χωρίσαι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G5563",
    gloss: "to separate/leave",
  },
  χωρίσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G5563",
    gloss: "to separate/leave",
  },
  χωρισθεὶς: {
    parse:
      "V-APP-NSM｜Verb, aorist, passive, Participle, nominative, singular, masculine",
    GN: "G5563",
    gloss: "to separate/leave",
  },
  χωρισθῇ: {
    parse: "V-APS-3S｜Verb, aorist, passive, subjunctive, third, singular",
    GN: "G5563",
    gloss: "to separate/leave",
  },
  χωρισθῆναι: {
    parse: "V-APN｜Verb, aorist, passive, Infinitive",
    GN: "G5563",
    gloss: "to separate/leave",
  },
  χωρία: {
    parse: "N-NPN｜Noun, nominative, plural, neuter",
    GN: "G5564",
    gloss: "Field_of",
  },
  χωρίον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G5564",
    gloss: "Field_of",
  },
  Χωρίον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G5564",
    gloss: "Field_of",
  },
  χωρίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G5564",
    gloss: "Field_of",
  },
  χωρίων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G5564",
    gloss: "Field_of",
  },
  χωρίς: { parse: "PREP｜Preposition", GN: "G5565", gloss: "without" },
  χωρὶς: { parse: "PREP｜Preposition", GN: "G5565", gloss: "without" },
  χῶρον: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5566",
    gloss: "northwest",
  },
  ψαλλέτω: {
    parse: "V-PAM-3S｜Verb, present, active, imperative, third, singular",
    GN: "G5567",
    gloss: "to sing praise",
  },
  ψάλλοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G5567",
    gloss: "to sing praise",
  },
  ψαλῶ: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G5567",
    gloss: "to sing praise",
  },
  ψαλμοῖς: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G5568",
    gloss: "psalm",
  },
  ψαλμὸν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5568",
    gloss: "psalm",
  },
  ψαλμῷ: {
    parse: "N-DSM｜Noun, dative, singular, masculine",
    GN: "G5568",
    gloss: "psalm",
  },
  ψαλμῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G5568",
    gloss: "psalm",
  },
  ψευδαδέλφοις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G5569",
    gloss: "false brother",
  },
  ψευδαδέλφους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G5569",
    gloss: "false brother",
  },
  ψευδαπόστολοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G5570",
    gloss: "false apostle",
  },
  ψευδεῖς: {
    parse: "A-APM｜Adjective, accusative, plural, masculine",
    GN: "G5571",
    gloss: "false",
  },
  ψευδέσιν: {
    parse: "A-DPM｜Adjective, dative, plural, masculine",
    GN: "G5571",
    gloss: "false",
  },
  ψευδοδιδάσκαλοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G5572",
    gloss: "false teacher",
  },
  ψευδολόγων: {
    parse: "A-GPM｜Adjective, genitive, plural, masculine",
    GN: "G5573",
    gloss: "lying",
  },
  ἐψεύσω: {
    parse:
      "V-ADI-2S｜Verb, aorist, Middle deponent, indicative, second, singular",
    GN: "G5574",
    gloss: "to lie",
  },
  ψεύδεσθε: {
    parse:
      "V-PNM-2P｜Verb, present, Middle or Passive deponent, imperative, second, plural",
    GN: "G5574",
    gloss: "to lie",
  },
  ψεύδομαι: {
    parse:
      "V-PNI-1S｜Verb, present, Middle or Passive deponent, indicative, first, singular",
    GN: "G5574",
    gloss: "to lie",
  },
  ψευδόμεθα: {
    parse:
      "V-PNI-1P｜Verb, present, Middle or Passive deponent, indicative, first, plural",
    GN: "G5574",
    gloss: "to lie",
  },
  ψευδόμενοι: {
    parse:
      "V-PNP-NPM｜Verb, present, Middle or Passive deponent, Participle, nominative, plural, masculine",
    GN: "G5574",
    gloss: "to lie",
  },
  ψεύδονται: {
    parse:
      "V-PNI-3P｜Verb, present, Middle or Passive deponent, indicative, third, plural",
    GN: "G5574",
    gloss: "to lie",
  },
  ψεύσασθαι: {
    parse: "V-ADN｜Verb, aorist, Middle deponent, Infinitive",
    GN: "G5574",
    gloss: "to lie",
  },
  ψεύσασθαί: {
    parse: "V-ADN｜Verb, aorist, Middle deponent, Infinitive",
    GN: "G5574",
    gloss: "to lie",
  },
  ψευδομάρτυρες: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G5575",
    gloss: "false witness",
  },
  ψευδομαρτύρων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G5575",
    gloss: "false witness",
  },
  ἐψευδομαρτύρουν: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G5576",
    gloss: "to perjure",
  },
  ψευδομαρτυρήσεις: {
    parse: "V-FAI-2S｜Verb, future, active, indicative, second, singular",
    GN: "G5576",
    gloss: "to perjure",
  },
  ψευδομαρτυρήσῃς: {
    parse: "V-AAS-2S｜Verb, aorist, active, subjunctive, second, singular",
    GN: "G5576",
    gloss: "to perjure",
  },
  ψευδομαρτυρίαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G5577",
    gloss: "false testimony",
  },
  ψευδομαρτυρίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5577",
    gloss: "false testimony",
  },
  ψευδοπροφῆται: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G5578",
    gloss: "false prophet",
  },
  ψευδοπροφήταις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G5578",
    gloss: "false prophet",
  },
  ψευδοπροφήτην: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5578",
    gloss: "false prophet",
  },
  ψευδοπροφήτης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5578",
    gloss: "false prophet",
  },
  ψευδοπροφήτου: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G5578",
    gloss: "false prophet",
  },
  ψευδοπροφητῶν: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G5578",
    gloss: "false prophet",
  },
  ψεύδει: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G5579",
    gloss: "lie",
  },
  ψεῦδος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G5579",
    gloss: "lie",
  },
  ψεύδους: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G5579",
    gloss: "lie",
  },
  ψευδόχριστοι: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G5580",
    gloss: "false Christ",
  },
  ψευδωνύμου: {
    parse: "A-GSF｜Adjective, genitive, singular, feminine",
    GN: "G5581",
    gloss: "falsely called",
  },
  ψεύσματι: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G5582",
    gloss: "falsehood",
  },
  ψεῦσται: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G5583",
    gloss: "liar",
  },
  ψεύσταις: {
    parse: "N-DPM｜Noun, dative, plural, masculine",
    GN: "G5583",
    gloss: "liar",
  },
  ψεύστην: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G5583",
    gloss: "liar",
  },
  ψεύστης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G5583",
    gloss: "liar",
  },
  ἐψηλάφησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G5584",
    gloss: "to touch",
  },
  ψηλαφήσατέ: {
    parse: "V-AAM-2P｜Verb, aorist, active, imperative, second, plural",
    GN: "G5584",
    gloss: "to touch",
  },
  ψηλαφήσειαν: {
    parse: "V-AAO-3P｜Verb, aorist, active, Optative, third, plural",
    GN: "G5584",
    gloss: "to touch",
  },
  ψηλαφωμένῳ: {
    parse:
      "V-PPP-DSN｜Verb, present, passive, Participle, dative, singular, neuter",
    GN: "G5584",
    gloss: "to touch",
  },
  ψηφίζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5585",
    gloss: "to calculate",
  },
  ψηφισάτω: {
    parse: "V-AAM-3S｜Verb, aorist, active, imperative, third, singular",
    GN: "G5585",
    gloss: "to calculate",
  },
  ψῆφον: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5586",
    gloss: "vote",
  },
  ψιθυρισμοί: {
    parse: "N-NPM｜Noun, nominative, plural, masculine",
    GN: "G5587",
    gloss: "gossip",
  },
  ψιθυριστάς: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G5588",
    gloss: "gossip",
  },
  ψιχίων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G5589",
    gloss: "crumb",
  },
  ψυχαί: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G5590",
    gloss: "mind/soul/life/animal",
  },
  ψυχαὶ: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G5590",
    gloss: "mind/soul/life/animal",
  },
  ψυχαῖς: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G5590",
    gloss: "mind/soul/life/animal",
  },
  ψυχάς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G5590",
    gloss: "mind/soul/life/animal",
  },
  ψυχὰς: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G5590",
    gloss: "mind/soul/life/animal",
  },
  ψυχῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5590",
    gloss: "mind/soul/life/animal",
  },
  ψυχή: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5590",
    gloss: "mind/soul/life/animal",
  },
  ψυχὴ: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5590",
    gloss: "mind/soul/life/animal",
  },
  Ψυχή: {
    parse: "N-VSF｜Noun, Vocative or nominative, singular, feminine",
    GN: "G5590",
    gloss: "mind/soul/life/animal",
  },
  ψυχήν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5590",
    gloss: "mind/soul/life/animal",
  },
  ψυχὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5590",
    gloss: "mind/soul/life/animal",
  },
  ψυχῆς: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5590",
    gloss: "mind/soul/life/animal",
  },
  ψυχῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G5590",
    gloss: "mind/soul/life/animal",
  },
  ψυχική: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G5591",
    gloss: "natural",
  },
  ψυχικοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G5591",
    gloss: "natural",
  },
  ψυχικόν: {
    parse: "A-NSN｜Adjective, nominative, singular, neuter",
    GN: "G5591",
    gloss: "natural",
  },
  Ψυχικὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5591",
    gloss: "natural",
  },
  ψύχει: {
    parse: "N-DSN｜Noun, dative, singular, neuter",
    GN: "G5592",
    gloss: "cold",
  },
  ψῦχος: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G5592",
    gloss: "cold",
  },
  ψυχρός: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5593",
    gloss: "cold",
  },
  ψυχρὸς: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G5593",
    gloss: "cold",
  },
  ψυχροῦ: {
    parse: "A-GSN｜Adjective, genitive, singular, neuter",
    GN: "G5593",
    gloss: "cold",
  },
  ψυγήσεται: {
    parse: "V-2FPI-3S｜Verb, 2nd future, passive, indicative, third, singular",
    GN: "G5594",
    gloss: "to cool",
  },
  ψώμιζε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G5595",
    gloss: "to feed/dole out",
  },
  ψωμίσω: {
    parse: "V-AAS-1S｜Verb, aorist, active, subjunctive, first, singular",
    GN: "G5595",
    gloss: "to feed/dole out",
  },
  ψωμίον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G5596",
    gloss: "piece",
  },
  ψώχοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G5597",
    gloss: "to rub",
  },
  Ὦ: { parse: "INJ｜Interjection", GN: "G5599", gloss: "oh!" },
  Ἰωβὴδ: {
    parse: "N-NSM-P｜Noun, nominative, singular, masculine, Person",
    GN: "G5601",
    gloss: "Obed",
  },
  ὧδε: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G5602",
    gloss: "here",
  },
  Ὧδε: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G5602",
    gloss: "here",
  },
  ὧδέ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G5602",
    gloss: "here",
  },
  ᾠδαῖς: {
    parse: "N-DPF｜Noun, dative, plural, feminine",
    GN: "G5603",
    gloss: "song",
  },
  ᾠδὴν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5603",
    gloss: "song",
  },
  ὠδὶν: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5604",
    gloss: "labor",
  },
  ὠδῖνας: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G5604",
    gloss: "labor",
  },
  ὠδίνων: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G5604",
    gloss: "labor",
  },
  ὠδίνουσα: {
    parse:
      "V-PAP-VSF｜Verb, present, active, Participle, Vocative or nominative, singular, feminine",
    GN: "G5605",
    gloss: "be in labor",
  },
  ὠδίνω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G5605",
    gloss: "be in labor",
  },
  ὤμους: {
    parse: "N-APM｜Noun, accusative, plural, masculine",
    GN: "G5606",
    gloss: "shoulder",
  },
  ὠνήσατο: {
    parse:
      "V-ADI-3S｜Verb, aorist, Middle deponent, indicative, third, singular",
    GN: "G5608",
    gloss: "to buy",
  },
  ᾠόν: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G5609",
    gloss: "an egg",
  },
  ὥρᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G5610",
    gloss: "hour",
  },
  ὥρα: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5610",
    gloss: "hour",
  },
  ὧραί: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G5610",
    gloss: "hour",
  },
  ὥραν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G5610",
    gloss: "hour",
  },
  ὥρας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5610",
    gloss: "hour",
  },
  ὡρῶν: {
    parse: "N-GPF｜Noun, genitive, plural, feminine",
    GN: "G5610",
    gloss: "hour",
  },
  Ὡραίᾳ: {
    parse: "A-DSF｜Adjective, dative, singular, feminine",
    GN: "G5611",
    gloss: "Beautiful",
  },
  Ὡραίαν: {
    parse: "A-ASF｜Adjective, accusative, singular, feminine",
    GN: "G5611",
    gloss: "Beautiful",
  },
  ὡραῖοι: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G5611",
    gloss: "Beautiful",
  },
  ὠρυόμενος: {
    parse:
      "V-PNP-NSM｜Verb, present, Middle or Passive deponent, Participle, nominative, singular, masculine",
    GN: "G5612",
    gloss: "to roar",
  },
  ὡς: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G5613",
    gloss: "as/when",
  },
  Ὡς: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G5613",
    gloss: "as/when",
  },
  ὥς: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G5613",
    gloss: "as/when",
  },
  Ὡσαννά: {
    parse: "PRT｜Particle, disjunctive particle",
    GN: "G5614",
    gloss: "Hosanna",
  },
  Ὡσαννὰ: {
    parse: "PRT｜Particle, disjunctive particle",
    GN: "G5614",
    gloss: "Hosanna",
  },
  ὡσαύτως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G5615",
    gloss: "likewise",
  },
  Ὡσαύτως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G5615",
    gloss: "likewise",
  },
  ὡσεὶ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G5616",
    gloss: "like/as/about",
  },
  Ὡσηὲ: {
    parse: "N-DSM-P｜Noun, dative, singular, masculine, Person",
    GN: "G5617",
    gloss: "Hosea",
  },
  ὥσπερ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G5618",
    gloss: "just as",
  },
  Ὥσπερ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G5618",
    gloss: "just as",
  },
  ὡσπερεὶ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G5619",
    gloss: "just as if",
  },
  ὥστε: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G5620",
    gloss: "so",
  },
  Ὥστε: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G5620",
    gloss: "so",
  },
  ὠτίον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G5621",
    gloss: "ear",
  },
  ὠτίου: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G5621",
    gloss: "ear",
  },
  ὠφέλεια: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G5622",
    gloss: "advantage",
  },
  ὠφελείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G5622",
    gloss: "advantage",
  },
  ὠφελεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G5623",
    gloss: "to help",
  },
  ὠφελεῖται: {
    parse: "V-PPI-3S｜Verb, present, passive, indicative, third, singular",
    GN: "G5623",
    gloss: "to help",
  },
  ὠφελεῖτε: {
    parse: "V-PAI-2P｜Verb, present, active, indicative, second, plural",
    GN: "G5623",
    gloss: "to help",
  },
  ὠφεληθεῖσα: {
    parse:
      "V-APP-NSF｜Verb, aorist, passive, Participle, nominative, singular, feminine",
    GN: "G5623",
    gloss: "to help",
  },
  ὠφεληθῇς: {
    parse: "V-APS-2S｜Verb, aorist, passive, subjunctive, second, singular",
    GN: "G5623",
    gloss: "to help",
  },
  ὠφελήθησαν: {
    parse: "V-API-3P｜Verb, aorist, passive, indicative, third, plural",
    GN: "G5623",
    gloss: "to help",
  },
  ὠφεληθήσεται: {
    parse: "V-FPI-3S｜Verb, future, passive, indicative, third, singular",
    GN: "G5623",
    gloss: "to help",
  },
  ὠφελήσει: {
    parse: "V-FAI-3S｜Verb, future, active, indicative, third, singular",
    GN: "G5623",
    gloss: "to help",
  },
  ὠφέλησεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G5623",
    gloss: "to help",
  },
  ὠφελήσω: {
    parse: "V-FAI-1S｜Verb, future, active, indicative, first, singular",
    GN: "G5623",
    gloss: "to help",
  },
  ὠφελοῦμαι: {
    parse: "V-PPI-1S｜Verb, present, passive, indicative, first, singular",
    GN: "G5623",
    gloss: "to help",
  },
  ὠφέλιμα: {
    parse: "A-NPN｜Adjective, nominative, plural, neuter",
    GN: "G5624",
    gloss: "valuable",
  },
  ὠφέλιμος: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G5624",
    gloss: "valuable",
  },
  ὠφέλιμός: {
    parse: "A-NSF｜Adjective, nominative, singular, feminine",
    GN: "G5624",
    gloss: "valuable",
  },
  ἀγγέλλουσα: {
    parse:
      "V-PAP-NSF｜Verb, present, active, Participle, nominative, singular, feminine",
    GN: "G6000",
    gloss: "to report",
  },
  ἄγγη: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G6001",
    gloss: "vessel",
  },
  Ἀδμὶν: {
    parse: "N-GSM｜Noun, genitive, singular, masculine",
    GN: "G6002",
    gloss: "Admin",
  },
  ἠθροισμένους: {
    parse:
      "V-RPP-APM｜Verb, perfect, passive, Participle, accusative, plural, masculine",
    GN: "G6003",
    gloss: "to gather",
  },
  ἀμφιέζει: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G6005",
    gloss: "to dress",
  },
  ἀμφιβάλλοντας: {
    parse:
      "V-PAP-APM｜Verb, present, active, Participle, accusative, plural, masculine",
    GN: "G6006",
    gloss: "to cast",
  },
  ἀναπηδήσας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G6008",
    gloss: "to jump up",
  },
  βελόνης: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G6011",
    gloss: "needle",
  },
  δέους: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G6015",
    gloss: "fear",
  },
  διακαθᾶραι: {
    parse: "V-AAN｜Verb, aorist, active, Infinitive",
    GN: "G6016",
    gloss: "to scour",
  },
  διαχλευάζοντες: {
    parse:
      "V-PAP-NPM｜Verb, present, active, Participle, nominative, plural, masculine",
    GN: "G6017",
    gloss: "to deride",
  },
  διενθυμουμένου: {
    parse:
      "V-PNP-GSM｜Verb, present, Middle or Passive deponent, Participle, genitive, singular, masculine",
    GN: "G6018",
    gloss: "to ponder",
  },
  δισμυριάδες: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G6019",
    gloss: "two-myriad",
  },
  δοκιμασίᾳ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G6020",
    gloss: "examination",
  },
  δυσφημούμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G6022",
    gloss: "to defame",
  },
  ἐκζητήσεις: {
    parse: "N-APF｜Noun, accusative, plural, feminine",
    GN: "G6027",
    gloss: "speculation",
  },
  ἐξεθαύμαζον: {
    parse: "V-IAI-3P｜Verb, imperfect, active, indicative, third, plural",
    GN: "G6028",
    gloss: "be amazed",
  },
  ἐκπερισσῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G6029",
    gloss: "exceedingly",
  },
  ἐξεπήδησαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G6030",
    gloss: "to rush out",
  },
  ἐλεγμόν: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G6031",
    gloss: "rebuke",
  },
  ἐμπαιγμονῇ: {
    parse: "N-DSF｜Noun, dative, singular, feminine",
    GN: "G6032",
    gloss: "derision",
  },
  ἔνθεν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G6033",
    gloss: "hence",
  },
  ἐνορκίζω: {
    parse: "V-PAI-1S｜Verb, present, active, indicative, first, singular",
    GN: "G6034",
    gloss: "to adjure",
  },
  ἐπάρατοί: {
    parse: "A-NPM｜Adjective, nominative, plural, masculine",
    GN: "G6035",
    gloss: "accursed",
  },
  ἐπεισελεύσεται: {
    parse: "V-FMI-3S｜Verb, future, middle, indicative, third, singular",
    GN: "G6036",
    gloss: "to enter",
  },
  ἐπέσπειρεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G6037",
    gloss: "to sow above",
  },
  ζήλευε: {
    parse: "V-PAM-2S｜Verb, present, active, imperative, second, singular",
    GN: "G6041",
    gloss: "to envy",
  },
  Ἰωδὰ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G6043",
    gloss: "Joda",
  },
  Ἰωσὴχ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G6044",
    gloss: "Josech",
  },
  καθώσπερ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G6045",
    gloss: "as",
  },
  καταβαρυνόμενοι: {
    parse:
      "V-PPP-NPM｜Verb, present, passive, Participle, nominative, plural, masculine",
    GN: "G6046",
    gloss: "to burden",
  },
  κατέγραφεν: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G6047",
    gloss: "to delineate",
  },
  καταδίκην: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G6048",
    gloss: "judgment",
  },
  κατακύψας: {
    parse:
      "V-AAP-NSM｜Verb, aorist, active, Participle, nominative, singular, masculine",
    GN: "G6049",
    gloss: "to stoop",
  },
  κατευλόγει: {
    parse: "V-IAI-3S｜Verb, imperfect, active, indicative, third, singular",
    GN: "G6050",
    gloss: "to bless",
  },
  κατήγωρ: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G6051",
    gloss: "an accuser",
  },
  κατῴκισεν: {
    parse: "V-AAI-3S｜Verb, aorist, active, indicative, third, singular",
    GN: "G6052",
    gloss: "to settle",
  },
  κατωτέρω: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G6053",
    gloss: "lower",
  },
  κλιναρίων: {
    parse: "N-GPN｜Noun, genitive, plural, neuter",
    GN: "G6055",
    gloss: "a cot",
  },
  κρυφαίῳ: {
    parse: "A-DSN｜Adjective, dative, singular, neuter",
    GN: "G6058",
    gloss: "hidden",
  },
  ἐκύκλευσαν: {
    parse: "V-AAI-3P｜Verb, aorist, active, indicative, third, plural",
    GN: "G6059",
    gloss: "to encompass",
  },
  μετατραπήτω: {
    parse: "V-2APM-3S｜Verb, 2nd aorist, passive, imperative, third, singular",
    GN: "G6060",
    gloss: "to turn around",
  },
  μύλινον: {
    parse: "A-ASM｜Adjective, accusative, singular, masculine",
    GN: "G6061",
    gloss: "mill",
  },
  οἰκετείας: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G6064",
    gloss: "household",
  },
  οἰκοδόμων: {
    parse: "N-GPM｜Noun, genitive, plural, masculine",
    GN: "G6065",
    gloss: "builder",
  },
  ὀλιγοπιστίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G6066",
    gloss: "little faith",
  },
  ὁμίχλαι: {
    parse: "N-NPF｜Noun, nominative, plural, feminine",
    GN: "G6068",
    gloss: "fog",
  },
  πανταχῇ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G6069",
    gloss: "everywhere",
  },
  παρεμβαλοῦσιν: {
    parse: "V-FAI-3P｜Verb, future, active, indicative, third, plural",
    GN: "G6070",
    gloss: "to encamp",
  },
  περαιτέρω: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G6071",
    gloss: "further",
  },
  περιαψάντων: {
    parse:
      "V-AAP-GPM｜Verb, aorist, active, Participle, genitive, plural, masculine",
    GN: "G6072",
    gloss: "to touch",
  },
  πραϋπαθίαν: {
    parse: "N-ASF｜Noun, accusative, singular, feminine",
    GN: "G6073",
    gloss: "gentleness",
  },
  προπάτορα: {
    parse: "N-ASM｜Noun, accusative, singular, masculine",
    GN: "G6074",
    gloss: "ancestor",
  },
  προσαίτης: {
    parse: "N-NSM｜Noun, nominative, singular, masculine",
    GN: "G6075",
    gloss: "a beggar",
  },
  προσεκλίθη: {
    parse: "V-API-3S｜Verb, aorist, passive, indicative, third, singular",
    GN: "G6076",
    gloss: "to lean toward",
  },
  Πύρρου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G6077",
    gloss: "Pyrrhus",
  },
  πρώτως: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G6078",
    gloss: "originally",
  },
  σιτία: {
    parse: "N-APN｜Noun, accusative, plural, neuter",
    GN: "G6079",
    gloss: "food",
  },
  συγγενίς: {
    parse: "N-NSF｜Noun, nominative, singular, feminine",
    GN: "G6080",
    gloss: "relative",
  },
  συνέπεσεν: {
    parse: "V-2AAI-3S｜Verb, 2nd aorist, active, indicative, third, singular",
    GN: "G6081",
    gloss: "be downcast",
  },
  συνειδυίης: {
    parse:
      "V-RAP-GSF｜Verb, perfect, active, Participle, genitive, singular, feminine",
    GN: "G6083",
    gloss: "be conscious of",
  },
  σύνοιδα: {
    parse: "V-RAI-1S｜Verb, perfect, active, indicative, first, singular",
    GN: "G6083",
    gloss: "be conscious of",
  },
  Τιτίου: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G6084",
    gloss: "Titius",
  },
  τρήματος: {
    parse: "N-GSN｜Noun, genitive, singular, neuter",
    GN: "G6085",
    gloss: "hole",
  },
  ὑπερεκπερισσοῦ: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G6087",
    gloss: "superabundantly",
  },
  ὑπερλίαν: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G6088",
    gloss: "greatly",
  },
  ὠτάριον: {
    parse: "N-ASN｜Noun, accusative, singular, neuter",
    GN: "G6090",
    gloss: "ear",
  },
  δύσεως: {
    parse: "N-GSF｜Noun, genitive, singular, feminine",
    GN: "G6091",
    gloss: "sunset",
  },
  ἐξέβησαν: {
    parse: "V-2AAI-3P｜Verb, 2nd aorist, active, indicative, third, plural",
    GN: "G6092",
    gloss: "to go out",
  },
  αὐχεῖ: {
    parse: "V-PAI-3S｜Verb, present, active, indicative, third, singular",
    GN: "G6094",
    gloss: "to to_boast",
  },
  Ἀρνὶ: {
    parse: "N-GSM-P｜Noun, genitive, singular, masculine, Person",
    GN: "G6095",
    gloss: "Arni",
  },
  διαυγάσῃ: {
    parse: "V-AAS-3S｜Verb, aorist, active, subjunctive, third, singular",
    GN: "G6897",
    gloss: "transparent",
  },
  διαυγής: {
    parse: "A-NSM｜Adjective, nominative, singular, masculine",
    GN: "G6897",
    gloss: "transparent",
  },
  ἐάνπερ: {
    parse: "CONJ｜Conjunction or conjunctive particle",
    GN: "G6986",
    gloss: "if indeed",
  },
  ἐγκαυχᾶσθαι: {
    parse: "V-PNN｜Verb, present, Middle or Passive deponent, Infinitive",
    GN: "G7013",
    gloss: "to boast of",
  },
  Εὖγε: {
    parse: "PRT｜Particle, disjunctive particle",
    GN: "G7530",
    gloss: "well done",
  },
  ὑπόλειμμα: {
    parse: "N-NSN｜Noun, nominative, singular, neuter",
    GN: "G9577",
    gloss: "a vestige",
  },
  τυπικῶς: {
    parse: "ADV｜Adverb or Adverb and particle combined",
    GN: "G9992",
    gloss: "figuratively",
  },
};

// const array = [];
// const parttern = /ον$/;
// const keys = Object.keys(wordUsages);

// for (let word in wordUsages) {
//   if (
//     wordUsages[word].parse.includes("deponent") &&
//     !array.includes(wordUsages[word].GN)
//   ) {
//     array.push(wordUsages[word].GN);
//   }
// }

// console.log(array);

// let gnumberObject = {};

// let gnumCount = [];

// for (const word in wordUsages) {
//   let gnum = wordUsages[word].GN;
//   if (!gnumberObject[gnum]) {
//     gnumberObject[gnum] = 1;
//   } else {
//     gnumberObject[gnum]++;
//   }
// }

// for (const num in gnumberObject) {
//   if (gnumberObject[num] > 25 && gnumberObject[num] < 45) {
//     gnumCount.push(num);
//   }
// }

// console.log(gnumCount);

//continue backwards at G3992

// χωρίς: { parse: "PREP｜Preposition", GN: "G5565", gloss: "without" },

// let parseOptions = [];

// for (let word in wordUsages) {
//   if (parseOptions.includes([wordUsages[word].parse])) {
//     return;
//   } else {
//     parseOptions.push(wordUsages[word].parse);
//   }
// }

// console.log(parseOptions.length);

// let particles = {};

// for (let word in wordUsages) {
//   let wordParse = wordUsages[word].parse;
//   if (wordParse.includes(P)

//   ) {
//     continue;
//   } else {
//     parseOptions.push(parseOption);
//   }
// }

// console.log(parseOptions.length);

//   if (wordUsages[word].parse.includes("Interjection")) {
//     interjections[word] = {
//       parse: wordUsages[word].parse,
//       gloss: wordUsages[word].gloss,
//       GN: wordUsages[word].GN,
//     };
//   }
// }

// console.log(interjections);

// let prepString = JSON.stringify(parseOptions);

// fs.writeFile("./src/greek_text/parseOptions.js", prepString, (err) => {
//   if (err) throw err;
// });
