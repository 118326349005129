
export const greekArticles = {
  ὁ: { case: ["nominative"], number: "singular", gender: ["masculine"] },
  Ὁ: { case: ["nominative"], number: "singular", gender: ["masculine"] },
  οἱ: { case: ["nominative"], number: "plural", gender: ["masculine"] },
  οἳ: { case: ["nominative"], number: "plural", gender: ["masculine"] },
  Οἱ: { case: ["nominative"], number: "plural", gender: ["masculine"] },
  ἡ: { case: ["nominative"], number: "singular", gender: ["feminine"] },
  Ή: { case: ["nominative"], number: "singular", gender: ["feminine"] },
  Ἡ: { case: ["nominative"], number: "singular", gender: ["feminine"] },
  τό: { case: ["nominative", "accusative"], number: "singular", gender: ["neuter"] },
  τὸ: { case: ["nominative", "accusative"], number: "singular", gender: ["neuter"] },
  Τὸ: { case: ["nominative", "accusative"], number: "singular", gender: ["neuter"] },
  Τό: { case: ["nominative", "accusative"], number: "singular", gender: ["neuter"] },
  τόν: { case: ["accusative"], number: "singular", gender: ["masculine"] },
  Τὸν: { case: ["accusative"], number: "singular", gender: ["masculine"] },
  Τόν: { case: ["accusative"], number: "singular", gender: ["masculine"] },
  τοῦ: {
    case: ["genitive"],
    number: "singular",
    gender: ["masculine", "neuter"],
  },
  Τοῦ: {
    case: ["genitive"],
    number: "singular",
    gender: ["masculine", "neuter"],
  },
  τῆς: { case: ["genitive"], number: "singular", gender: ["feminine"] },
  τῷ: { case: ["dative"], number: "singular", gender: ["masculine", "neuter"] },
  Τῷ: { case: ["dative"], number: "singular", gender: ["masculine", "neuter"] },
  τούς: { case: ["accusative"], number: "plural", gender: ["masculine"] },
  Τούς: { case: ["accusative"], number: "plural", gender: ["masculine"] },
  τῶν: {
    case: ["genitive"],
    number: "plural",
    gender: ["masculine", "feminine", "neuter"],
  },
  Τῶν: {
    case: ["genitive"],
    number: "plural",
    gender: ["masculine", "feminine", "neuter"],
  },
  τοῖς: { case: ["dative"], number: "plural", gender: ["masculine", "neuter"] },
  Τοῖς: { case: ["dative"], number: "plural", gender: ["masculine", "neuter"] },
  τοὺς: { case: ["accusative"], number: "plural", gender: ["masculine"] },
  Τοὺς: { case: ["accusative"], number: "plural", gender: ["masculine"] },
  τήν: { case: ["accusative"], number: "singular", gender: ["feminine"] },
  Τήν: { case: ["accusative"], number: "singular", gender: ["feminine"] },
  τῇ: { case: ["dative"], number: "singular", gender: ["feminine"] },
  Τῇ: { case: ["dative"], number: "singular", gender: ["feminine"] },
  τάς: { case: ["accusative"], number: "plural", gender: ["feminine"] },
  Τάς: { case: ["accusative"], number: "plural", gender: ["feminine"] },
  ταῖς: { case: ["dative"], number: "plural", gender: ["feminine"] },
  Ταῖς: { case: ["dative"], number: "plural", gender: ["feminine"] },
  τά: {
    case: ["nominative", "accusative"],
    number: "plural",
    gender: ["neuter"],
  },
  Τά: {
    case: ["nominative", "accusative"],
    number: "plural",
    gender: ["neuter"],
  },
  τὴν: { case: ["accusative"], number: "singular", gender: ["feminine"] },
  Τὴν: { case: ["accusative"], number: "singular", gender: ["feminine"] },
  τὸν: { case: ["accusative"], number: "singular", gender: ["masculine"] },
  τὰ: {
    case: ["nominative", "accusative"],
    number: "plural",
    gender: ["neuter"],
  },
  Τὰ: {
    case: ["nominative", "accusative"],
    number: "plural",
    gender: ["neuter"],
  },
  τὰς: { case: ["accusative"], number: "plural", gender: ["feminine"] },
  Τὰς: { case: ["accusative"], number: "plural", gender: ["feminine"] },
  αἱ: { case: ["nominative"], number: "plural", gender: ["feminine"] },
  Αἱ: { case: ["nominative"], number: "plural", gender: ["feminine"] },
};
